import Swal, {SweetAlertOptions} from "sweetalert2";

const CustomSweetalert = (options: SweetAlertOptions) => {
    return Swal.fire({
        ...options,
        customClass: {
            container: 'swal-container',
            confirmButton: 'swal-confirm-btn',
            cancelButton: 'swal-cancel-btn',
        }
    });
}

export default CustomSweetalert;