import {IconButton, Tooltip} from "@mui/material";
import {AddCircle, CancelPresentation, NotificationsActive} from "@mui/icons-material";
import React from "react";
import TableToolbar from "../../common/table/TableToolbar";
import discussionSortFields from "../../../model/table/sort/DiscussionSortFields";
import DiscussionsFiltersModal from "./DiscussionsFiltersModal";
import DiscussionsFilters from "../../../model/filters/DiscussionsFilters";
import LoggedUser from "../../../model/auth/LoggedUser";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchProduct from "../../../model/database/ResearchProduct";
import ResearchService from "../../../model/database/ResearchService";
import Challenge from "../../../model/database/Challenge";
import Call from "../../../model/database/Call";

interface DiscussionsTableToolbarProps {
    numSelected: number,
    loggedUser?: LoggedUser,
    producers: ResearchProducer[],
    consumers: ResearchConsumer[],
    handleNew: () => void,
    applyFilters: (filters: DiscussionsFilters) => void
    applySort: (sort: string) => void
    products: ResearchProduct[],
    services: ResearchService[],
    challenges: Challenge[],
    calls: Call[],
}

const DiscussionsTableToolbar = ({
                                     numSelected,
                                     loggedUser,
                                     consumers,
                                     producers,
                                     products,
                                     services,
                                     challenges,
                                     calls,
                                     handleNew,
                                     applyFilters,
                                     applySort
                                 }: DiscussionsTableToolbarProps) => {

    const [filters, setFilters] = React.useState<DiscussionsFilters>({});

    const handleConfirm = (newStateFilters: DiscussionsFilters) => {
        setFilters(newStateFilters);
        applyFilters(newStateFilters);
    }

    const handleClean = () => {
        setFilters({});
        applyFilters({});
    }

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <TableToolbar
                numSelected={numSelected}
                title="Tavoli di discussione"
                fields={discussionSortFields}
                filters={Object.values(filters)}
                defaultField={discussionSortFields[0].value}
                defaultOrder={'asc'}
                handleOpenFiltersModal={handleOpenModal}
                handleCleanFilters={handleClean}
                handleSort={applySort}
                handleExport={() => alert('export selection')}
                handleDelete={loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') ? () => alert('delete selection') : undefined}
                extendedActions={
                    <>
                        {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') &&
                            <Tooltip title="Crea nuovo tavolo">
                                <IconButton color="secondary" onClick={handleNew}>
                                    <AddCircle/>
                                </IconButton>
                            </Tooltip>}
                    </>
                }
                extendedActionsOnSelection={
                    loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') && <>
                        <Tooltip title="Chiudi">
                            <IconButton color="secondary">
                                <CancelPresentation/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Invia reminder">
                            <IconButton color="secondary">
                                <NotificationsActive/>
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <DiscussionsFiltersModal
                open={openModal}
                filters={filters}
                loggedUser={loggedUser}
                handleClean={handleClean}
                handleClose={handleCloseModal}
                handleConfirm={handleConfirm}
                producers={producers}
                consumers={consumers}
                products={products}
                services={services}
                challenges={challenges}
                calls={calls}
            />
        </>
    );
}

export default DiscussionsTableToolbar;
