import { Autocomplete, Button, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { HttpStatusCode } from "axios";
import { Formik } from "formik";
import React, { ReactNode } from "react";
import * as Yup from "yup";
import cnr from "../../../__data__/cnr-departments";
import unisalento from "../../../__data__/unisalento-departments";
import researchProducerType from "../../../constants/researchProducerType";
import roles from "../../../constants/roles";
import ResearchInstitution from "../../../model/database/ResearchInstitution";
import User from "../../../model/database/User";
import UserProducerRegistrationDTO from "../../../model/dto/UserProducerRegistrationDTO";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import RegexUtil from "../../../utils/RegexUtil";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import StyledRating from "../../common/StyledRating";
import UploadFileField from "../../common/UploadFileField";
import CityAutocomplete from "../../common/select/CityAutocomplete";
import DepartmentAutocomplete from "../../common/select/DepartmentAutocomplete";
import ProvinceAutocomplete from "../../common/select/ProvinceAutocomplete";
import RegionAutocomplete from "../../common/select/RegionAutocomplete";

interface ResearchProducerModalProps {
    open: boolean,
    user?: User,
    institutions: ResearchInstitution[],
    editEnable?: boolean,
    handleClose: () => void,
    handleSave: (userToSave: UserProducerRegistrationDTO) => Promise<boolean>
}

const ResearchProducerModal = ({
    open,
    editEnable,
    user,
    institutions,
    handleSave,
    handleClose
}: ResearchProducerModalProps) => {

    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>('Crea nuovo utente');

    React.useEffect(() => {
        if (editMode && user)
            setTitle('Modifica utente');
        else if (user)
            setTitle('Dettagli utente');
        else
            setTitle('Crea nuovo utente');

        if (user?.researchProducer?.type === 'RESEARCHER') {
            loadLaboratoryAsync(user.researchProducer.researchInstitution?.id ?? '');
        }
    }, [editMode, user]);

    const resetAndClose = () => {
        setEditMode(false);
        handleClose();
    }

    const [laboratories, setLaboratories] = React.useState<any>([]);

    const loadLaboratoryAsync = async (id: string) => {
        if (id !== '') {
            const [status, response] = await UserService.getAllLaboratoryByInstitution(id);

            if (status && response && status === HttpStatusCode.Ok) {
                setLaboratories(response);
            } else {
                CustomSweetalert({
                    title: 'Attenzione',
                    text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                    icon: 'error'
                });
            }
        }
    }

    return <Formik
        enableReinitialize
        initialValues={{
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            username: user?.username ?? '',
            email: user?.email ?? '',
            role: user?.role?.id ?? '',
            state: user?.state ?? 'INACTIVE',
            password: '',
            confirmPassword: '',
            producerName: user?.researchProducer?.name ?? '',
            producerInstitution: user?.researchProducer?.researchInstitution?.id ?? '',
            producerDepartment: user?.researchProducer?.department ?? '',
            producerWebsite: user?.researchProducer?.website ?? '',
            producerDescription: user?.researchProducer?.description ?? '',
            producerVatNumber: user?.researchProducer?.vatNumber ?? '',
            producerAddress: user?.researchProducer?.address ?? '',
            producerCity: user?.researchProducer?.city ?? '',
            producerProvince: user?.researchProducer?.province ?? '',
            producerRegion: user?.researchProducer?.region ?? '',
            producerPostalCode: user?.researchProducer?.postalCode ?? '',
            producerPhoneNumber: user?.researchProducer?.phoneNumber ?? '',
            producerLogo: user?.researchProducer?.logo ?? '',
            producerType: user?.researchProducer?.type ?? '',
            producerScore: user?.score ?? 0,
            parentProducer: user?.researchProducer?.parentResearchProducer?.id ?? '',
        }}
        validationSchema={Yup.object().shape({
            username: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Lo username è obbligatorio e non può essere vuoto.'),
            email: Yup.string().max(255).email('Il valore inserito non è un formato corretto.')
                .required('L\'email è obbligatoria e non può essere vuota.'),
            role: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatoria e non può essere vuota.'),
            state: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Lo stato è obbligatorio e non può essere vuota.'),
            password: Yup.string().min(8, 'La password deve essere di almeno 8 caratteri')
                .max(255, 'Il valore massimo consentito è di 255 caratteri'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono corrispondere'),
            // validazione producer
            producerName: Yup.string().when('producerType', {
                is: 'LABORATORY' || 'RESEARCH_CENTER' || 'SPINOFF',
                then: Yup.string().nullable().max(255, 'Il valore massimo consentito è di 255 caratteri')
                    .required('Il nome è obbligatorio e non può essere vuoto.')
            }),
            producerInstitution: Yup.string().when('producerType', {
                is: 'RESEARCHER' || 'LABORATORY',
                then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                    .required('L\'istituto di appartenenza è obbligatorio e non può essere vuoto.')
            }),
            producerDepartment: Yup.string().when('producerType', {
                is: 'RESEARCHER' || 'LABORATORY' || 'RESEARCH_CENTER',
                then: Yup.string().nullable().max(255, 'Il valore massimo consentito è di 255 caratteri')
                    .required('Il dipartimento è obbligatorio e non può essere vuoto.')
            }),
            producerWebsite: Yup.string().max(1024, 'Il valore massimo consentito è di 1024 caratteri')
                .matches(RegexUtil.webSiteRegex,
                    'Sito web non valido'),
            producerDescription: Yup.string().max(4096, 'Il valore massimo consentito è di 4096 caratteri'),
            producerVatNumber: Yup.string().when('producerType', {
                is: 'SPINOFF' || 'UNIVERSITY',
                then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                    .matches(/^[0-9]{11}$/, 'Partita IVA non valida')
                    .required('La partita IVA è obbligatorio e non può essere vuoto.')
            }),
            producerAddress: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('L\'indirizzo è obbligatorio e non può essere vuoto.'),
            producerCity: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La città è obbligatorio e non può essere vuoto.'),
            producerProvince: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La provincia è obbligatorio e non può essere vuoto.'),
            producerRegion: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La regione è obbligatorio e non può essere vuoto.'),
            producerPostalCode: Yup.string()
                .matches(/^[0-9]{5}$/, 'Il formato del codice postale non è valido.')
                .max(5, 'Il valore massimo consentito è di 5 caratteri')
                .required('Il codice postale è obbligatorio e non può essere vuoto.'),
            producerPhoneNumber: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .matches(/^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[5-90]|36[680]|33[1-90]|32[7890])\d{7}|(0[0-9]{1,4}[-.\s]?[0-9]{5,10})$/, 'Il recapito di telefono non è valido'),
            producerType: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia di produttore è obbligatorio e non può essere vuoto.'),
        })}
        onSubmit={async (values, { resetForm }) => {
            let result = await handleSave({
                name: values?.name,
                surname: values?.surname,
                username: values?.username,
                email: values?.email,
                state: values?.state,
                password: values?.password,
                score: values?.producerScore,
                researchProducerDTO: {
                    name: values?.producerName,
                    institution: values?.producerInstitution,
                    department: values?.producerDepartment,
                    website: values?.producerWebsite,
                    description: values?.producerDescription,
                    vatNumber: values?.producerVatNumber,
                    address: values?.producerAddress,
                    city: values?.producerCity,
                    province: values?.producerProvince,
                    region: values?.producerRegion,
                    postalCode: values?.producerPostalCode,
                    phoneNumber: values?.producerPhoneNumber,
                    logo: values?.producerLogo,
                    type: values?.producerType,
                    parent: values?.parentProducer
                }
            })
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
            submitForm,
            values
        }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    Informazioni utente
                                </Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.username && errors.username)}
                                    fullWidth
                                    helperText={touched.username && errors.username}
                                    label="Username"
                                    margin="normal"
                                    name="username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting || (editMode && user !== undefined)}
                                    value={values.username}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.email}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={5}>
                                <TextField
                                    error={Boolean(touched.role && errors.role)}
                                    fullWidth
                                    helperText={touched.role && errors.role}
                                    label="Ruolo"
                                    margin="normal"
                                    name="role"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    select
                                    disabled={isSubmitting || (editMode && user !== undefined)}
                                    value={values.role}
                                    required
                                    variant="outlined"
                                >
                                    {roles.map(value => <MenuItem key={value.id}
                                        value={value.id}>{value.name}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={5}>
                                <TextField
                                    error={Boolean(touched.producerType && errors.producerType)}
                                    fullWidth
                                    helperText={(touched.producerType && errors.producerType) as React.ReactNode}
                                    label="Tipologia"
                                    margin="normal"
                                    name="producerType"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={editMode}
                                    value={values.producerType}
                                    required
                                    variant="outlined"
                                >
                                    {researchProducerType.map(value => <MenuItem key={value.id}
                                        value={value.id}>{value.name}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={2}>
                                <TextField
                                    error={Boolean(touched.state && errors.state)}
                                    fullWidth
                                    helperText={touched.state && errors.state}
                                    label="Stato"
                                    margin="normal"
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.state}
                                    required
                                    variant="outlined"
                                >
                                    <MenuItem value="INACTIVE">Non attivo</MenuItem>
                                    <MenuItem value="ACTIVE">Attivo</MenuItem>
                                </TextField>
                            </ResponsiveGridItem>
                            {(editMode || !user) && <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    disabled={isSubmitting}
                                    value={values.password}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>}
                            {(editMode || !user) && <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    fullWidth
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    label="Conferma password"
                                    margin="normal"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    disabled={isSubmitting}
                                    value={values.confirmPassword}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    {values.producerType !== 'RESEARCHER' ? 'Informazioni responsabile' : 'Informazioni ricercatore'}
                                </Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={(touched.name && errors.name) as React.ReactNode}
                                    label="Nome"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.surname && errors.surname)}
                                    fullWidth
                                    helperText={(touched.surname && errors.surname) as React.ReactNode}
                                    label="Cognome"
                                    margin="normal"
                                    name="surname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.surname}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            {values.producerType !== 'RESEARCHER' && <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    Informazioni di dettaglio
                                </Typography>
                            </ResponsiveGridItem>}
                            {
                                values.producerType === 'RESEARCH_CENTER' && <ResponsiveGridItem>
                                    <DepartmentAutocomplete
                                        value={values.producerDepartment}
                                        label={"Istituto di appartenenza"}
                                        values={cnr.departments}
                                        fieldName="producerDepartment"
                                        readOnly={!editMode} />
                                </ResponsiveGridItem>
                            }
                            {values.producerType !== 'RESEARCHER' && <ResponsiveGridItem
                                md={values.producerType === 'LABORATORY' ? 12 : 6}>
                                <TextField
                                    error={Boolean(touched.producerName && errors.producerName)}
                                    fullWidth
                                    helperText={(touched.producerName && errors.producerName) as React.ReactNode}
                                    label={"Nome " + researchProducerType.find(value => value.id === values.producerType)?.label}
                                    margin="normal"
                                    name="producerName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.producerName}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>}
                            {
                                (values.producerType === 'RESEARCHER' || values.producerType === 'LABORATORY') &&
                                <>
                                    <ResponsiveGridItem md={6}>
                                        <Autocomplete
                                            options={institutions?.map(value => ({
                                                label: value.name,
                                                id: value.id
                                            })) ?? []}
                                            fullWidth
                                            onBlur={handleBlur}
                                            disabled={isSubmitting}
                                            value={{
                                                label: institutions?.find(v => v.id === values.producerInstitution)?.name ?? '',
                                                id: values.producerInstitution
                                            }}
                                            readOnly={!editMode}
                                            onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                                                setFieldValue('producerInstitution', newValue.id);
                                                if (values.producerType === 'RESEARCHER')
                                                    loadLaboratoryAsync(newValue.id);
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                required
                                                variant="outlined"
                                                name="producerInstitution"
                                                margin="normal"
                                                label="Università"
                                                error={Boolean(touched.producerInstitution && errors.producerInstitution)}
                                                helperText={(touched.producerInstitution && errors.producerInstitution) as React.ReactNode}
                                            />
                                            }
                                        />
                                    </ResponsiveGridItem>
                                    <ResponsiveGridItem md={6}>
                                        {
                                            values.producerInstitution === unisalento.parentId ?
                                                <DepartmentAutocomplete
                                                    value={values.producerDepartment}
                                                    label={"Dipartimento"}
                                                    values={unisalento.departments}
                                                    fieldName="producerDepartment"
                                                    readOnly={!editMode} /> :
                                                <TextField
                                                    error={Boolean(touched.producerDepartment && errors.producerDepartment)}
                                                    fullWidth
                                                    helperText={(touched.producerDepartment && errors.producerDepartment) as React.ReactNode}
                                                    label="Dipartimento"
                                                    margin="normal"
                                                    name="producerDepartment"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    disabled={isSubmitting}
                                                    value={values.producerDepartment}
                                                    required
                                                    variant="outlined"
                                                    inputProps={{
                                                        readOnly: !editMode
                                                    }}
                                                />
                                        }
                                    </ResponsiveGridItem>
                                </>
                            }
                            {values.producerType === 'RESEARCHER' && <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.parentProducer && errors.parentProducer)}
                                    fullWidth
                                    helperText={(touched.parentProducer && errors.parentProducer) as React.ReactNode}
                                    label="Laboratorio di appartenenza"
                                    margin="normal"
                                    name="parentProducer"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.parentProducer}
                                    select
                                    variant="outlined"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                >
                                    <MenuItem value="">Nessuna selezione</MenuItem>
                                    {laboratories?.map((value: any) => <MenuItem key={value.id}
                                        value={value.id}>{value.name}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>}
                            {(values.producerType !== 'RESEARCHER' && values.producerType !== 'LABORATORY') &&
                                <ResponsiveGridItem md={6}>
                                    <TextField
                                        error={Boolean(touched.producerVatNumber && errors.producerVatNumber)}
                                        fullWidth
                                        helperText={(touched.producerVatNumber && errors.producerVatNumber) as ReactNode}
                                        label="Partita IVA"
                                        margin="normal"
                                        name="producerVatNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        inputProps={{
                                            readOnly: !editMode
                                        }}
                                        disabled={isSubmitting}
                                        value={values.producerVatNumber}
                                        required
                                        variant="outlined"
                                    />
                                </ResponsiveGridItem>}
                            <ResponsiveGridItem md={6}>
                                <RegionAutocomplete value={values.producerRegion} fieldName="producerRegion"
                                    readOnly={!editMode} />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <ProvinceAutocomplete value={values.producerProvince} fieldName="producerProvince"
                                    regionSelected={values.producerRegion} readOnly={!editMode} />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={values.producerType !== 'RESEARCHER' ? 5 : 6}>
                                <CityAutocomplete value={values.producerCity} fieldName="producerCity"
                                    provinceSelected={values.producerProvince} readOnly={!editMode} />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={values.producerType !== 'RESEARCHER' ? 5 : 10}>
                                <TextField
                                    error={Boolean(touched.producerAddress && errors.producerAddress)}
                                    fullWidth
                                    helperText={(touched.producerAddress && errors.producerAddress) as ReactNode}
                                    label="Indirizzo"
                                    margin="normal"
                                    name="producerAddress"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.producerAddress}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={2}>
                                <TextField
                                    error={Boolean(touched.producerPostalCode && errors.producerPostalCode)}
                                    fullWidth
                                    helperText={(touched.producerPostalCode && errors.producerPostalCode) as ReactNode}
                                    label="Codice postale"
                                    margin="normal"
                                    name="producerPostalCode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.producerPostalCode}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    Informazioni aggiuntive
                                </Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Stack direction={{ sm: 'column', md: 'row' }}>
                                    <Typography component="legend">Punteggio di affidabilità: </Typography>
                                    <StyledRating
                                        name="producerScore"
                                        value={values.producerScore}
                                        readOnly={!editMode}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.producerPhoneNumber && errors.producerPhoneNumber)}
                                    fullWidth
                                    helperText={(touched.producerPhoneNumber && errors.producerPhoneNumber) as ReactNode}
                                    label="Recapito di telefono"
                                    margin="normal"
                                    name="producerPhoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.producerPhoneNumber}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.producerWebsite && errors.producerWebsite)}
                                    fullWidth
                                    helperText={(touched.producerWebsite && errors.producerWebsite) as ReactNode}
                                    label="Sito web"
                                    margin="normal"
                                    name="producerWebsite"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.producerWebsite}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            {values.producerType !== 'RESEARCHER' && <ResponsiveGridItem>
                                <UploadFileField propertyId="producerLogo" editMode={editMode} />
                            </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.producerDescription && errors.producerDescription)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                    helperText={(touched.producerDescription && errors.producerDescription) as ReactNode}
                                    label="Descrizione"
                                    margin="normal"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    name="producerDescription"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.producerDescription}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            Chiudi
                        </Button>
                        {editEnable && (!editMode && user !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                Modifica
                            </Button> :
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                Conferma
                                {isSubmitting && <CircularLoading />}
                            </Button>)}
                    </>
                }
            />
        )}
    </Formik>;
}

export default ResearchProducerModal;
