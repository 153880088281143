import { Box, Stack } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from 'react-router-dom';

export default function Footer() {
    return (
        <Stack
            direction="row"
            sx={{
                backgroundColor: '#213740',
                p: 2,
                width: '100%'
            }}
            component="footer"
            justifyContent="space-between"
        >
            <Typography variant="body2" color="#f2f2f2" style={{ display: 'flex' }}>
                <Box>
                    <span style={{ fontWeight: 'bold' }}>{"Contattaci: "}</span>
                    <Link color="inherit" href="mailto:info@innovationresearch.it">
                        info@innovationresearch.it
                    </Link>{" | "}
                    <Link color="inherit" href="mailto:innovation.research@unisalento.it">
                        innovation.research@unisalento.it
                    </Link>
                </Box>
            </Typography>
            <Typography variant="body2" color="#f2f2f2" style={{ display: 'flex', alignContent: 'end' }}>
                <Box style={{ marginLeft: 'auto' }}>
                    {"© 2024 Innovation Research | "}
                    <RouterLink to="/privacy-policy" style={{ color: "white" }}>
                        Privacy policy
                    </RouterLink>
                </Box>
            </Typography>
        </Stack>
    );
}