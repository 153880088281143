import {LabelValue} from "../../filters/LabelValue";

const userSortFields: LabelValue[] = [
  {
    label: 'Stato',
    value: 'state'
  },
  {
    label: 'Nome',
    value: 'name'
  },
  {
    label: 'Cognome',
    value: 'surname'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Ruolo',
    value: 'role.id'
  },
]

export default userSortFields;
