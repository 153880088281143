import {Rating, RatingProps, styled} from "@mui/material";

const CustomRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty': {
        color: theme.palette.secondary.main,
    },
    '& .MuiRating-iconFilled': {
        color: theme.palette.secondary.main,
    },
}));

const StyledRating = (rest: RatingProps) => <CustomRating precision={0.5} {...rest} />

export default StyledRating;