import React from "react";
import AdminLayoutContainer from "./AdminLayoutContainer";
import AdminLayoutContent from "./AdminLayoutContent";
import AdminLayoutWrapper from "./AdminLayoutWrapper";
import AdminLayoutRoot from "./AdminLayoutRoot";
import AdminSidebar from "./AdminSidebar";
import AdminNavbar from "./AdminNavbar";
import Breadcrumb from "../../components/common/Breadcrumb";
import {Helmet} from "react-helmet";
import AuthContext from "../../route/AuthContext";

interface AdminLayoutProps {
    children?: React.ReactNode,
    breadcrumb: string[],
    title: string
}

const AdminLayout = ({children, breadcrumb, title}: AdminLayoutProps) => {
    const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
    const navbarWidth = 240;

    const authContext = React.useContext(AuthContext);
    React.useEffect(() => {
        authContext.setNeedRefreshSidebar?.(true)
      }, []);

    return (
        <AdminLayoutRoot>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <AdminNavbar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                sx={{
                    width: {
                        lg: `calc(100% - ${navbarWidth}px)`
                    },
                    ml: {
                        lg: `${navbarWidth}px`
                    }
                }}
            />
            <AdminSidebar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={mobileNavOpen}
                navbarWidth={navbarWidth}
            />
            <AdminLayoutWrapper>
                <AdminLayoutContainer>
                    <AdminLayoutContent sx={{p: 2}}>
                        <Breadcrumb items={breadcrumb}/>
                        {children}
                    </AdminLayoutContent>
                </AdminLayoutContainer>
            </AdminLayoutWrapper>
        </AdminLayoutRoot>
    );
};

export default AdminLayout;
