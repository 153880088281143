import React from 'react';
import AdminLayout from "../../layouts/admin/AdminLayout";
import {Box} from "@mui/material";
import ChallengesTable from "../../components/admin/challenges/ChallengesTable";
import AuthContext from '../../route/AuthContext';
import {useLocation} from 'react-router-dom';
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';

const Challenges = () => {

  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);

  return (
    <AdminLayout
      breadcrumb={contentBreadcrumb}
      title="Sfide | Piattaforma Innovation ReSearch"
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1
      }}>
        <ChallengesTable />
      </Box>
    </AdminLayout>
  );
};

export default Challenges;
