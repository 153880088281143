import { HttpStatusCode } from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import KeywordDTO from "../model/dto/KeywordDTO";
import KeywordFilters from "../model/filters/KeywordFilters";
import http from '../utils/HttpCommon';

const getAllSdgs = async (context: LoggedUserContext) => {
	try {
		const response = await http.privateClient(context).get<any>('/sdgs', { params: {page: 0, size: 1000, sort: 'name,asc'} });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/keywords/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllActive = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/keywords/search/findAllNotDeletedAndActive', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllPublic = async () => {
	try {
		const response = await http.publicClient.get<any>('/keywords/search/findAllNotDeletedAndActive', { params:  {page: 0, size: -1, sort: 'name,asc'} } );

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: KeywordFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/keywords/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const save = async (context: LoggedUserContext, keyword: KeywordDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/keywords', keyword);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const update = async (context: LoggedUserContext, id: string, keyword: KeywordDTO) => {
	try {
		const response = await http.privateClient(context).put<any>(`/keywords/${id}`, keyword);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/keywords/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const changeStateById = async (context: LoggedUserContext, id: string, state: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/keywords/${id}/state/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteAll = async (context: LoggedUserContext) => {}
const exportSelected = async (context: LoggedUserContext) => {}

const KeywordService = {
	getAllSdgs,
	getAll,
	getAllActive,
	getAllPublic,
	getAllFiltered,
	save,
	update,
	deleteById,
	deleteAll,
	exportSelected,
	changeStateById
}

export default KeywordService;