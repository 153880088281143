import LoggedUser from "../model/auth/LoggedUser";
import http from '../utils/HttpCommon';
import {HttpStatusCode} from "axios";


const logout = (setUser: (user: LoggedUser | undefined) => void) => {
	localStorage.removeItem("user");
	setUser(undefined);
};

const getCurrentUser = () => {
	const userStr = localStorage.getItem("user");
	if (userStr) return JSON.parse(userStr);

	return null;
};

const login = async (username: string, password: string) => {
	try {
		const response = await http.publicClient.post<any>('/login', {
			username,
			password
		});

		if(response && response.status === HttpStatusCode.Ok){
			return [response.status, response.data];
		}
	
		return [response ? response.status : undefined, undefined];
	
	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const restorePassword = async (username: string) => {
	try {
		const response = await http.publicClient.get<any>('/users/resetPassword', {
			params: {
				username
			}
		});

		if(response && response.status === HttpStatusCode.Ok){
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const resetPassword = async (param: { password: any; token: string }) => {
	try {
		const response = await http.publicClient.post<any>('/users/savePassword', param);

		if(response && response.status === HttpStatusCode.Ok){
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const AuthService = {
	getCurrentUser,
	logout,
	login,
    restorePassword,
    resetPassword
}

export default AuthService;