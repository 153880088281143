const researchConsumerType = [
  {
    id: "PUBLIC",
    name: "Pubblica amministrazione (Extra universitaria)"
  },
  {
    id: "PRIVATE",
    name: "Azienda privata"
  },
  {
    id: "COOPERATION",
    name: "Cooperativa"
  },
  {
    id: "ASSOCIATION",
    name: "Associazione"
  },
];

export default researchConsumerType;
