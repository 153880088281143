import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material";
import React from "react";
import LoggedUser from "../../../model/auth/LoggedUser";
import Keyword from "../../../model/database/Keyword";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import DemandsFilters from "../../../model/filters/DemandsFilters";
import ProductsFilters from "../../../model/filters/ProductsFilters";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";

interface DemandsFiltersModalProps {
    open: boolean,
    loggedUser?: LoggedUser,
    filters: ProductsFilters,
    consumers: ResearchConsumer[],
    keywords: Keyword[],
    handleClose: () => any,
    handleClean: () => any,
    handleConfirm: (newStateFilters: ProductsFilters) => any,
}

const DemandsFiltersModal = ({
    open,
    loggedUser,
    consumers,
    keywords,
    filters,
    handleClean,
    handleClose,
    handleConfirm
}: DemandsFiltersModalProps) => {
    const [state, setState] = React.useState<DemandsFilters>(filters);

    const handleChange = (name: string, value: string) => {
        const newFilters = { ...state };
        switch (name) {
            case 'name':
                newFilters.name = { label: '', value: '' };
                newFilters.name.label = "Nome";
                newFilters.name.value = value;
                break;
            case 'consumer':
                newFilters.consumer = { label: '', value: '' };
                newFilters.consumer.label = "Ente proponente";
                newFilters.consumer.value = value;
                const rc = consumers.find(value1 => value1.user?.id === value);
                newFilters.consumer.valueDisplay = StringGeneratorUtil.generateConsumerLabel(rc!);
                break;
            case 'keyword':
                newFilters.keyword = { label: '', value: '' };
                newFilters.keyword.label = "Parola chiave";
                newFilters.keyword.value = value;

                const k = keywords.find(value1 => value1.id === value);
                newFilters.keyword.valueDisplay = k?.name;
                break;
            case 'approved':
                newFilters.approved = { label: '', value: '' };
                newFilters.approved.label = "Stato approvazione";
                newFilters.approved.value = value;
                switch (value) {
                    case 'APPROVED':
                        newFilters.approved.valueDisplay = 'Approvato';
                        break;
                    case 'NOT_APPROVED':
                        newFilters.approved.valueDisplay = 'Non approvato';
                        break;
                }
                break;
            case 'maxPublishDate':
                newFilters.maxPublishDate = { label: '', value: '' };
                newFilters.maxPublishDate.label = "Data minima pubblicazione";
                newFilters.maxPublishDate.value = value;
                newFilters.maxPublishDate.valueDisplay = `Data pubblicazione fino a ${value}`;
                break;
            case 'minPublishDate':
                newFilters.minPublishDate = { label: '', value: '' };
                newFilters.minPublishDate.label = "Data massima pubblicazione";
                newFilters.minPublishDate.value = value;
                newFilters.minPublishDate.valueDisplay = `Data pubblicazione a partire da ${value}`;
                break;
            case 'likes':
                newFilters.likes = { label: '', value: '' };
                newFilters.likes.label = "Numero di interessati";
                newFilters.likes.value = value;
                newFilters.likes.valueDisplay = `Numero minimo di interessati almeno di ${value}`;
                break;
        }
        setState(newFilters);
    }

    React.useEffect(() => {
        setState(filters);
    }, [filters]);

    return <CustomDialog
        open={open ? open : false}
        handleClose={handleClose}
        title="Filtri"
        content={
            <Grid container spacing={2}>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        onChange={(event) => handleChange('name', event.target.value)}
                        value={state && state.name ? state.name.value : ''}
                        label="Nome fabbisogno"
                        margin="dense"
                        name="name"
                        variant="outlined"
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={keywords ?? []}
                        fullWidth
                        getOptionLabel={(option) => option.name!}
                        value={keywords.find(value => value.id === state.keyword?.value) ?? null}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('keyword', newValue.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            required
                            variant="outlined"
                            name="keyword"
                            margin="dense"
                            label="Parola chiave"
                        />
                        }
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Stato approvazione"
                        margin="dense"
                        name="approved"
                        onChange={(event) => handleChange('approved', event.target.value)}
                        value={state && state.approved ? state.approved.value : ''}
                        select
                        variant="outlined"
                    >
                        {[['Approvato', 'APPROVED'], ['Non approvato', 'NOT_APPROVED']].map(value => <MenuItem
                            key={value[1]} value={value[1]}>{value[0]}</MenuItem>)}
                    </TextField>
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={consumers?.map(value => ({
                            label: StringGeneratorUtil.generateConsumerLabel(value),
                            id: value.user?.id
                        })) ?? []}
                        fullWidth
                        value={consumers?.map(value => ({
                            label: StringGeneratorUtil.generateConsumerLabel(value),
                            id: value.user?.id
                        })).find(v => v.id === state.consumer?.value)}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('consumer', newValue.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            label="Ente proponente"
                            margin="dense"
                            name="consumer"
                        />
                        }
                    />
                </ResponsiveGridItem>
                
            </Grid>
        }
        actions={
            <>
                <Button color="inherit" onClick={() => {
                    handleClose();
                }}>
                    Chiudi
                </Button>
                <Button color="inherit" onClick={() => {
                    handleClean();
                    handleClose();
                }}>
                    Reset
                </Button>
                <Button color="primary" variant="contained" autoFocus onClick={() => {
                    handleConfirm(state);
                    handleClose();
                }}>
                    Applica
                </Button>
            </>
        }
    />
}

export default DemandsFiltersModal;
