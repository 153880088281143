import AdminLayout from "../../layouts/admin/AdminLayout";
import React from "react";
import AuthContext from "../../route/AuthContext";
import {useLocation} from "react-router-dom";
import BreadcrumbGenerator from "../../components/common/BreadcrumbGenerator";
import CategoriesTable from "../../components/admin/categories/CategoriesTable";

const Categories = () => {
  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);

  return (
    <AdminLayout
      breadcrumb={contentBreadcrumb}
      title="Gestione parole chiave | Piattaforma Innovation ReSearch"
    >
      <CategoriesTable />
    </AdminLayout>
  );
};

export default Categories;
