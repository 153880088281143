import {LabelValue} from "../../filters/LabelValue";

const categorySortFields: LabelValue[] = [
  {
    label: 'Nome',
    value: 'name'
  }
]

export default categorySortFields;
