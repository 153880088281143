import * as React from 'react';
import {ChangeEvent} from 'react';
import {Button, Paper, Stack, TextField, Typography} from "@mui/material";
import {useFormikContext} from "formik";

interface UploadFileFieldProps {
    propertyId: string,
    editMode?: boolean
}

const UploadFileField = ({propertyId, editMode}: UploadFileFieldProps) => {

    const {handleBlur, isSubmitting, values, setFieldValue} = useFormikContext<any>();
    const inputRef = React.useRef<any>();

    const [file, setFile] = React.useState<string>(values[propertyId] === '' ? '/static/no-image.png' : values[propertyId]);
    React.useEffect(() => {
        setFile(values[propertyId] === '' ? '/static/no-image.png' : values[propertyId]);
    }, [propertyId, values]);

    const handleSelection = (e: ChangeEvent) => {
        const fileInput = e.target as HTMLInputElement;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            var fr = new FileReader();
            fr.readAsDataURL(fileInput.files[0]);
            fr.onload = function (event) {
                let newValues = {...values};
                newValues.logo = fr.result as string;
                setFile(fr.result as string);
                setFieldValue(propertyId, fr.result as string);
            }
        }
    }

    const handleClean = () => {
        setFile('');
        setFieldValue(propertyId, '');
        if(inputRef.current){
          inputRef.current.value = '';
        }
    }

    return <>
        <Stack direction="row" alignItems="center" spacing={1}>
            <Paper sx={{p: 2, flex: 1}}>
                <Typography gutterBottom variant="body1" mb={1}>Logo</Typography>
                <img
                    src={file}
                    alt="nessun contenuto selezionato"
                    width="100%"
                    height="auto"
                />
            </Paper>
            {editMode && <TextField
                inputRef={inputRef}
                fullWidth
                label="Seleziona immagine"
                margin="normal"
                name={propertyId}
                onBlur={handleBlur}
                onChange={handleSelection}
                type="file"
                disabled={isSubmitting}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                    flex: 2
                }}
            />}
            {editMode && file !== '/static/no-image.png' && <Button color="inherit" onClick={handleClean}>
                Pulisci
            </Button>}
        </Stack>
    </>
}

export default UploadFileField;
