import {Bar} from 'react-chartjs-2';
import Item from "../../common/Item";
import {Typography} from "@mui/material";
import React from "react";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false
    }
  },
};

interface VerticalBarChartProps {
  title: string,
  labels: string[],
  datasets: any[]
}

const VerticalBarChart = ({title, labels, datasets}: VerticalBarChartProps) => {
  const [data, setData] = React.useState({
    labels: [] as any[],
    datasets: [] as any[]
  });

  React.useEffect(() => {
    setData({
      labels: labels,
      datasets: datasets
    });
  }, [labels, datasets]);

  return <Item>
    <Typography variant="h6" gutterBottom align="left" mb={2}>{title}</Typography>
    {data.labels && data.labels.length > 0 && data.datasets && data.datasets.length > 0 && <Bar options={options} data={{
      labels: data.labels,
      datasets: data.datasets
    }}/>}
  </Item>
}

export default VerticalBarChart;
