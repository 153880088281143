const italyRegions = {
  "countryName": "Italy",
  "countryShortCode": "IT",
  "regions": [
    {
      "name": "Abruzzo",
      "shortCode": "65"
    },
    {
      "name": "Basilicata",
      "shortCode": "77"
    },
    {
      "name": "Calabria",
      "shortCode": "78"
    },
    {
      "name": "Campania",
      "shortCode": "72"
    },
    {
      "name": "Emilia Romagna",
      "shortCode": "45"
    },
    {
      "name": "Friuli Venezia Giulia",
      "shortCode": "36"
    },
    {
      "name": "Lazio",
      "shortCode": "62"
    },
    {
      "name": "Liguria",
      "shortCode": "42"
    },
    {
      "name": "Lombardia",
      "shortCode": "25"
    },
    {
      "name": "Marche",
      "shortCode": "57"
    },
    {
      "name": "Molise",
      "shortCode": "67"
    },
    {
      "name": "Piemonte",
      "shortCode": "21"
    },
    {
      "name": "Puglia",
      "shortCode": "75"
    },
    {
      "name": "Sardegna",
      "shortCode": "88"
    },
    {
      "name": "Sicilia",
      "shortCode": "82"
    },
    {
      "name": "Toscana",
      "shortCode": "52"
    },
    {
      "name": "Trentino Alto Adige",
      "shortCode": "32"
    },
    {
      "name": "Umbria",
      "shortCode": "55"
    },
    {
      "name": "Valle d'Aosta",
      "shortCode": "23"
    },
    {
      "name": "Veneto",
      "shortCode": "34"
    }
  ]
};

export default italyRegions;
