import {
    alpha,
    ButtonGroup,
    Chip,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {ArrowCircleDown, ArrowCircleUp, Delete, Download, FilterList, FilterListOff} from "@mui/icons-material";
import React from "react";
import {LabelValue} from "../../../model/filters/LabelValue";

interface TableToolbarProps {
  numSelected: number,
  fields?: LabelValue[],
  defaultField?: string,
  defaultOrder?: string,
  filters?: LabelValue[],
  title?: string,
  extendedActionsOnSelection?: React.ReactNode,
  extendedActions?: React.ReactNode,
  handleExport?: () => void,
  handleDelete?: () => void,
  handleOpenFiltersModal?: () => void
  handleCleanFilters?: () => void
  handleSort?: (value: string) => void
}

const TableToolbar = ({
                        title,
                        fields,
                        numSelected,
                        filters,
                        defaultField,
                        defaultOrder,
                        extendedActions,
                        extendedActionsOnSelection,
                        handleExport,
                        handleDelete,
                        handleOpenFiltersModal,
                        handleCleanFilters,
                        handleSort
                      }: TableToolbarProps) => {

  const [orderField, setOrderField] = React.useState<string>(defaultField ?? '');
  const [direction, setDirection] = React.useState<string>(defaultOrder ?? '');

  React.useEffect(() => {
    if(handleSort)
      handleSort(`${orderField},${direction}`);
  }, [orderField, direction]);

  return (
    <>
      <Toolbar
        sx={{
          pl: {sm: 2},
          pr: {xs: 1, sm: 1},
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{flex: '1 1 100%'}}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} elementi selezionati
          </Typography>
        ) : (
          <Typography
            sx={{flex: '1 1 100%'}}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        )}
        {numSelected > 0 ? (
          <>
            {extendedActionsOnSelection ?? ''}
            {handleExport && < Tooltip title="Esporta">
                <IconButton color="secondary" onClick={handleExport}>
                    <Download/>
                </IconButton>
            </Tooltip>}
            {handleDelete && <Tooltip title="Rimuovi">
                <IconButton color="error" onClick={handleDelete}>
                    <Delete/>
                </IconButton>
            </Tooltip>}
          </>
        ) : (
          <>
            <Tooltip title="Mostra filtri">
              <IconButton color="secondary" onClick={handleOpenFiltersModal}>
                <FilterList/>
              </IconButton>
            </Tooltip>
            {filters && filters.length > 0 && <Tooltip title="Pulisci filtri">
                <IconButton color="secondary" onClick={handleCleanFilters}>
                    <FilterListOff/>
                </IconButton>
            </Tooltip>}
            {extendedActions ?? ''}
          </>
        )}
      </Toolbar>
      {filters && filters.length > 0 && <Toolbar>
          <Stack direction={{md: 'row', sm: 'column', xs: 'column'}} spacing={1} alignItems="center" width="100%" >
              <Typography>Filtri impostati: </Typography>
            {filters.map(value => <Chip key={value.label} color="secondary" label={`${value.label}="${value.valueDisplay ?? value.value}"`} sx={{ml: 1}}/>)}
          </Stack>
      </Toolbar>}
      {fields &&
          <Toolbar>
              <Stack
                  direction={{
                    md: 'row',
                    sm: 'column',
                    xs: 'column'
                  }}
                  spacing={1}
                  alignItems="center"
                  width="100%"
              >
                  <Typography>Ordina per: </Typography>
                  <RadioGroup row>
                    {fields.map(value => <FormControlLabel key={value.label} checked={orderField === value.value} value={value.value}
                                                           control={<Radio color="secondary"/>} label={value.label}
                                                           onChange={event => setOrderField((event.target as HTMLInputElement).value)}/>)}
                  </RadioGroup>
                  <ButtonGroup variant="outlined">
                      <Tooltip title="Ascendente">
                          <IconButton onClick={() => setDirection('asc')}
                                      color={direction === 'asc' ? "secondary" : 'default'}><ArrowCircleUp/></IconButton>
                      </Tooltip>
                      <Tooltip title="Discendente">
                          <IconButton onClick={() => setDirection('desc')}
                                      color={direction === 'desc' ? "secondary" : 'default'}><ArrowCircleDown/></IconButton>
                      </Tooltip>
                  </ButtonGroup>
              </Stack>
          </Toolbar>
      }
    </>
  );
}

export default TableToolbar;
