import {LabelValue} from "../../filters/LabelValue";

const discussionSortFields: LabelValue[] = [
  {
    label: 'Stato',
    value: 'state'
  },
  {
    label: 'Nome',
    value: 'name'
  },
  {
    label: 'Argomento',
    value: 'product.name',
  },
]

export default discussionSortFields;
