import React from 'react';
import MainLayout from "../../layouts/public/MainLayout";
import {Helmet} from "react-helmet";
import RegistrationStepper from "../../components/public/registration/RegistrationStepper";
import {Container} from "@mui/material";

const Registration = () => {
  return (
    <MainLayout showLogo>
      <Helmet>
        <title>Registrazione | Piattaforma Innovation ReSearch</title>
      </Helmet>
      <Container sx={{mt: 4}}>
        <RegistrationStepper />
      </Container>
    </MainLayout>
  );
};

export default Registration;
