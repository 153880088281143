import {LabelValue} from "../model/filters/LabelValue";

const targetTypes: LabelValue[] = [
    {
        label: 'Aziende PMI',
        value: 'PMI',
    },
    {
        label: 'Aziende Medio/Grandi',
        value: 'MGI',
    },
    {
        label: 'Aziende multinazionali',
        value: 'MULTINATIONAL',
    },
    {
        label: 'Enti di ricerca',
        value: 'RESEARCH_INSTITUTE',
    },
    {
        label: 'Università',
        value: 'UNIVERSITY',
    },
];

export default targetTypes;