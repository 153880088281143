import Item from "../../common/Item";
import {Box, Icon, Stack, Typography} from "@mui/material";
import React from "react";

interface SummaryBadgeProps {
  src: string,
  label: string,
  value: number,
  elevation?: number
}

const SummaryBadge = ({value, src, label, elevation}: SummaryBadgeProps) => (
  <Item elevation={elevation ?? 1} style={{ height: '110px', width: '150px' }}>
    <Stack direction="column" textAlign="center" gap="0px">
        <Box textAlign="center">
          <Icon style={{fontSize:'36px'}}>
            <img src={src} alt="" width="100%"/>
          </Icon>
        </Box>
        <Typography variant="h5" mb={0} fontWeight="bold">{value}</Typography>
      <Typography variant="subtitle2" mb={0}>{label}</Typography>
    </Stack>
  </Item>
);

export default SummaryBadge;
