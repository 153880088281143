import {Button, Paper, Stack, Typography} from "@mui/material";
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Buffer} from 'buffer';
import FileDTO from '../../model/dto/FileDTO';
import researchProducerType from "../../constants/researchProducerType";
import DateUtil from "../../utils/DateUtil";
import StringGeneratorUtil from "./StringGeneratorUtil";

interface UploadedDocumentProps {
    documentInfo?: any
    handleMakeVisible?: () => void,
    visible?: boolean
}

const UploadedDocument = ({documentInfo, handleMakeVisible, visible}: UploadedDocumentProps) => {

    const {t} = useTranslation();

    const [file, setFile] = React.useState<FileDTO | null>(documentInfo.proposal);
    const [info, setInfo] = React.useState<any>(documentInfo);

    React.useEffect(() => {
        setFile(documentInfo.proposal);
        setInfo(documentInfo);
    }, [documentInfo]);

    const handleDownload = async () => {
        const b64 = file?.file ?? '';
        var b64Buffer = Buffer.from(b64.split(',')[1], 'base64');
        const b64Blob = new Blob([b64Buffer], {type: file?.type});
        const url = window.URL.createObjectURL(b64Blob);
        // const w = window.open('', "_blank");
        // w?.document.write(`<iframe src=${url} width='100%' height='100%'/>`);
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = url;
        a.download = file?.name ?? '';
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    return <>
        <Stack direction={{md: "row", xs: "column", sm: "column"}} alignItems="stretch" spacing={1} mb={1}>
            <Paper sx={{p: 2, flex: 1}} elevation={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Stack direction="column" alignItems="start" justifyContent="space-between">
                        <Typography gutterBottom variant="h6">{StringGeneratorUtil.generateProducerProposedLabel(info)[0]}</Typography>
                        <Typography gutterBottom variant="subtitle1" mb={1}>{StringGeneratorUtil.generateProducerProposedLabel(info)[1]}</Typography>
                        <Typography gutterBottom variant="body1">Sottomessa
                            il: {DateUtil.formatDate4View(info.submissionDate)}</Typography>
                        {file !== null && file?.name !== null &&
                            <Typography gutterBottom variant="body1" mb={1}><strong>{file?.name}</strong></Typography>}
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        {file !== null && file?.file !== null &&
                            <Button variant="contained" onClick={() => handleDownload()} autoFocus>
                                {t('file.download')}
                            </Button>}
                        {handleMakeVisible && <Button variant="contained" onClick={() => handleMakeVisible()} autoFocus>
                            {visible ? 'Disabilita al proponente' : 'Abilita al proponente'}
                        </Button>}
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    </>
}

export default UploadedDocument;
