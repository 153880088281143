import {GridColDef} from "@mui/x-data-grid";
import {CommonColumnProps} from "../CommonColumnProps";
import {Box, IconButton, Stack, Tooltip} from "@mui/material";
import React from "react";
import {CheckCircle, Delete, Unpublished, Visibility} from "@mui/icons-material";
import UserCard from "../../../components/admin/users/UserCard";
import {UserRow} from "../row/UserRow";
import LoggedUser from "../../auth/LoggedUser";

interface UsersTableColumnsProps {
  loggedUser?: LoggedUser,
  showCallback: (row: UserRow) => any,
  activateCallback: (id: string) => any,
  deactivateCallback: (id: string) => any,
  deleteCallback: (id: string) => any,
}

const UsersTableColumns = ({loggedUser, showCallback, deleteCallback, deactivateCallback, activateCallback }: UsersTableColumnsProps) : GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    ...CommonColumnProps
  },
  {
    field: 'data',
    headerName: 'Utente',
    renderCell: (params) => (
      <Box my={2} mx={1} width="100%">
        <UserCard key={params.row.id} userRow={params.row}/>
      </Box>
    ),
    ...CommonColumnProps,
    minWidth: 100,
    align: 'center',
    flex: 10,
  },
  {
    field: "action",
    headerName: "Operazioni",
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => {
      return <Stack direction="row" spacing={1}>
        <Tooltip title="Visualizza">
          <IconButton color="secondary" onClick={() => showCallback(params.row)}>
            <Visibility />
          </IconButton>
        </Tooltip>
        <Tooltip title={params.row.state === 'INACTIVE' ? 'Attiva' : 'Disattiva'}>
          {params.row.state === 'INACTIVE' ? <IconButton color="secondary" onClick={() => activateCallback(params.id as string)}>
            <CheckCircle/>
          </IconButton> : <IconButton color="secondary" onClick={() => deactivateCallback(params.id as string)}>
            <Unpublished />
          </IconButton>}
        </Tooltip>
        {loggedUser && loggedUser.role === 'ADMINISTRATOR' && <Tooltip title="Rimuovi">
          <IconButton color="error" onClick={() => deleteCallback(params.id as string)} >
            <Delete/>
          </IconButton>
        </Tooltip>}
      </Stack>;
    },
    ...CommonColumnProps
  },
];

export default UsersTableColumns;
