import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import PrivacyPolicyText from "../../components/common/PrivacyPolicyText";
import Logos from "../../components/public/home/Logos";
import MainLayout from "../../layouts/public/MainLayout";

const PrivacyPolicy = () => {

  return (
    <MainLayout
      elevation={0}
    >
      <Helmet>
        <title>Piattaforma Innovation ReSearch - Privacy Policy</title>
      </Helmet>
      <Stack direction="row" gap={0}>
        <Logos />
        <PrivacyPolicyText showTitle={true}/>
      </Stack>
    </MainLayout>
  );
};

export default PrivacyPolicy;
