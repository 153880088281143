import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import ChallengesFilters from "../model/filters/ChallengesFilters";
import http from '../utils/HttpCommon';
import ChallengeDTO from "../model/dto/ChallengeDTO";
import FileDTO from "../model/dto/FileDTO";

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/challenges/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: ChallengesFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/challenges/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const getFormat = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/challenges/${id}/format`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const submit = async (context: LoggedUserContext, fileDTO: FileDTO, idChallenge: string, idResearchProducer: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/challenges/${idChallenge}/researchProducers/${idResearchProducer}/submit`, fileDTO);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const save = async (context: LoggedUserContext, challengeDTO: ChallengeDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/challenges', challengeDTO);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const update = async (context: LoggedUserContext, challengeDTO: ChallengeDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/challenges/${id}`, challengeDTO);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const changeApprovedById = async (context: LoggedUserContext, id: string, state: boolean) => {
	try {
		const response = await http.privateClient(context).put<any>(`/challenges/${id}/approve/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/products/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const getSubmission = async (context: LoggedUserContext, id: string, userId: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/challenges/${id}/researchProducers/${userId}/document`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const getAllSubmission = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/challenges/${id}/researchProducers/documents`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const changeVisibleStatus = async (context: LoggedUserContext, idChallenge: string, idProducer: string, visible: boolean) => {
	try {
		const response = await http.privateClient(context).put<any>(`/challenges/${idChallenge}/researchProducers/${idProducer}/visible/${visible}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const sendReminder = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/challenges/${id}/reminder`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const getSuggestedProducers = async (authContext: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(authContext).get<any>(`/challenges/${id}/suggestedproducers`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const deleteAll = async (context: LoggedUserContext) => {}
const exportSelected = async (context: LoggedUserContext) => {}

const ChallengeService = {
	getAll,
	getAllFiltered,
	changeApprovedById,
	getFormat,
	submit,
	save,
	update,
	deleteById,
	deleteAll,
	exportSelected,
    getAllSubmission,
	getSubmission,
	changeVisibleStatus,
	sendReminder,
	getSuggestedProducers
}

export default ChallengeService;