import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

const filter = createFilterOptions<string>();

interface DepartmentAutocompleteProps {
    value: string,
    label: string,
    values: string[],
    fieldName: string,
    readOnly?: boolean
}

const DepartmentAutocomplete = ({ value, label, values, readOnly, fieldName }: DepartmentAutocompleteProps) => {
    const {
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        touched
    } = useFormikContext<any>();

    return (
        <Autocomplete
            options={values}
            fullWidth
            freeSolo
            onBlur={handleBlur}
            disabled={isSubmitting}
            readOnly={readOnly}
            value={value}
            onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                if(newValue && newValue.includes('Utilizza: ')){
                    setFieldValue(fieldName, newValue.replace('Utilizza: ', ''));
                }else{
                    setFieldValue(fieldName, newValue);    
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                    filtered.push(`Utilizza: ${inputValue}`);
                }

                return filtered;
            }}
            renderInput={(params) => <TextField
                {...params}
                required
                variant="outlined"
                name={fieldName}
                margin="normal"
                label={label}
                error={Boolean(touched[fieldName] && errors[fieldName])}
                helperText={(touched[fieldName] && errors[fieldName]) as React.ReactNode}
            />
            }
        />
    )
}

export default DepartmentAutocomplete;
