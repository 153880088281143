import {Autocomplete, Button, Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import LoggedUser from "../../../model/auth/LoggedUser";
import CallsFilters from "../../../model/filters/CallsFilters";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import Keyword from "../../../model/database/Keyword";
import callTypes from "../../../constants/callTypes";
import targetTypes from "../../../constants/targetTypes";

interface CallsFiltersModalProps {
  open: boolean,
  filters: CallsFilters,
  loggedUser?: LoggedUser,
  keywords: Keyword[],
  handleClose: () => any,
  handleClean: () => any,
  handleConfirm: (newStateFilters: CallsFilters) => any,
}

const CallsFiltersModal = ({open, filters, loggedUser, keywords, handleClose, handleClean, handleConfirm}: CallsFiltersModalProps) => {
  const [state, setState] = React.useState<CallsFilters>(filters);

  const handleChange = (name: string, value: string) => {
    const newFilters = {...state};
    switch (name) {
      case 'name':
        newFilters.name = {label: '', value: ''};
        newFilters.name.label = "Titolo";
        newFilters.name.value = value;
        break;
      case 'keyword':
        newFilters.keyword = {label: '', value: ''};
        newFilters.keyword.label = "Parola chiave";
        newFilters.keyword.value = value;

        const k = keywords.find(value1 => value1.id === value);
        newFilters.keyword.valueDisplay = k?.name;
        break;
      case 'date':
        newFilters.date = {label: '', value: ''};
        newFilters.date.label = "Data di scadenza";
        newFilters.date.value = value;
        break;
      case 'state':
        newFilters.state = {label: '', value: ''};
        newFilters.state.label = "Stato";
        newFilters.state.value = value;

        switch(value){
          case 'ACTIVE':
            newFilters.state.valueDisplay = 'Attivo';
            break;
          case 'EXPIRED':
            newFilters.state.valueDisplay = 'Scaduto';
            break;
        }
        break;
      case 'callType':
        newFilters.type = {label: '', value: ''};
        newFilters.type.label = "Tipologia bando";
        newFilters.type.value = value;
        const callType = callTypes.find(value1 => value1.value === value);
        newFilters.type.valueDisplay = callType?.label;
        break;
      case 'targetType':
        newFilters.target = {label: '', value: ''};
        newFilters.target.label = "Tipologia destinatario";
        newFilters.target.value = value;
        const targetType = targetTypes.find(value1 => value1.value === value);
        newFilters.target.valueDisplay = targetType?.label;
        break;
    }
    setState(newFilters);
    handleConfirm(newFilters);
  }

  React.useEffect(() => {
    setState(filters);
  }, [filters]);

  return <CustomDialog
    open={open ? open : false}
    handleClose={handleClose}
    title="Filtri"
    content={
      <Grid container spacing={2}>
        <ResponsiveGridItem md={6}>
          <TextField
            fullWidth
            onChange={(event) => handleChange('name', event.target.value)}
            value={state && state.name ? state.name.value : ''}
            label="Identificativo bando"
            margin="dense"
            name="name"
            variant="outlined"
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
          <Autocomplete
              options={keywords ?? []}
              fullWidth
              getOptionLabel={(option) => option.name!}
              value={keywords.find(value => value.id === state.keyword?.value) ?? null}
              onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                handleChange('keyword', newValue.id);
              }}
              renderInput={(params) => <TextField
                  {...params}
                  required
                  variant="outlined"
                  name="keyword"
                  margin="dense"
                  label="Parola chiave"
              />
              }
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
          <TextField
            fullWidth
            label="Data scadenza maggiore/uguale di"
            margin="dense"
            name="state"
            onChange={(event) => handleChange('date', event.target.value)}
            value={state && state.date ? state.date.value : ''}
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
          <TextField
              fullWidth
              label="Stato"
              margin="dense"
              name="state"
              onChange={(event) => handleChange('state', event.target.value)}
              value={state && state.state ? state.state.value : ''}
              select
              variant="outlined"
          >
            <MenuItem value="ACTIVE">Attivo</MenuItem>
            <MenuItem value="EXPIRED">Scaduto</MenuItem>
          </TextField>
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Tipologia bando"
                        margin="dense"
                        name="callType"
                        onChange={(event) => handleChange('callType', event.target.value)}
                        value={state && state.type ? state.type.value : ''}
                        variant="outlined"
                        select
                    >
                        {callTypes.map(value => <MenuItem key={value.value}
                                                                     value={value.value}>{value.label}</MenuItem>)}
                    </TextField>
          </ResponsiveGridItem>
          <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Tipologia destinatari"
                        margin="dense"
                        name="targetType"
                        onChange={(event) => handleChange('targetType', event.target.value)}
                        value={state && state.target ? state.target.value : ''}
                        variant="outlined"
                        select
                    >
                        {targetTypes.map(value => <MenuItem key={value.value}
                                                                     value={value.value}>{value.label}</MenuItem>)}
                    </TextField>
          </ResponsiveGridItem>
      </Grid>
    }
    actions={
      <>
        <Button color="inherit" onClick={() => {
          handleClose();
        }}>
          Chiudi
        </Button>
        <Button color="inherit" onClick={() => {
          handleClean();
          handleClose();
        }}>
          Reset
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={() => {
          handleConfirm(state);
          handleClose();
        }}>
          Applica
        </Button>
      </>
    }
  />
}

export default CallsFiltersModal;
