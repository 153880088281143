import {Autocomplete, Button, Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import PaymentsFilters from "../../../model/filters/PaymentsFilters";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import researchProducerType from "../../../constants/researchProducerType";
import researchConsumerType from "../../../constants/researchConsumerType";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";

interface PaymentsFiltersModalProps {
  open: boolean,
  filters: PaymentsFilters,
  researchConsumers: ResearchConsumer[],
  handleClose: () => any,
  handleClean: () => any,
  handleConfirm: (newStateFilters: PaymentsFilters) => any,
}

const PaymentsFiltersModal = ({open, filters, researchConsumers, handleClose, handleClean, handleConfirm}: PaymentsFiltersModalProps) => {
  const [state, setState] = React.useState<PaymentsFilters>(filters);

  const handleChange = (name: string, value: string) => {
    const newFilters = {...state};
    switch (name) {
      case 'year':
        newFilters.year = {label: '', value: ''};
        newFilters.year.label = "Anno di riferimento";
        newFilters.year.value = value;
        break;
      case 'paid':
        newFilters.paid = {label: '', value: ''};
        newFilters.paid.label = "Stato del pagamento";
        newFilters.paid.value = value;
        switch(value){
          case 'PAID':
            newFilters.paid.valueDisplay = 'Pagato';
            break;
          case 'NOT_PAID':
            newFilters.paid.valueDisplay = 'Non pagato';
            break;
        }
        break;
      case 'user':
        newFilters.user = {label: '', value: ''};
        newFilters.user.label = "Utente pagatore";
        newFilters.user.value = value;

        const rc = researchConsumers.find(value1 => value1.user?.id === value);
        newFilters.user.valueDisplay = StringGeneratorUtil.generateConsumerLabel(rc!);
        break;
    }
    setState(newFilters);
  }

  React.useEffect(() => {
    setState(filters);
  }, [filters]);

  return <CustomDialog
    open={open ? open : false}
    handleClose={handleClose}
    title="Filtri"
    content={
      <Grid container spacing={2}>
        <ResponsiveGridItem>
          <Autocomplete
              options={researchConsumers ?? []}
              fullWidth
              getOptionLabel={option => StringGeneratorUtil.generateConsumerLabel(option)}
              value={researchConsumers.find(value => value.user?.id === state.user?.value) ?? null}
              onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                handleChange('user', newValue.user?.id);
              }}
              renderInput={(params) => <TextField
                  {...params}
                  required
                  variant="outlined"
                  name="user"
                  margin="dense"
                  label="Utente"
              />
              }
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
          <TextField
            fullWidth
            onChange={(event) => handleChange('year', event.target.value)}
            value={state && state.year ? state.year.value : ''}
            label="Anno di riferimento"
            margin="dense"
            name="year"
            variant="outlined"
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
          <TextField
            fullWidth
            label="Stato del pagamento"
            margin="dense"
            name="state"
            onChange={(event) => handleChange('paid', event.target.value)}
            value={state && state.paid ? state.paid.value : ''}
            select
            variant="outlined"
          >
            <MenuItem value="PAID">Pagato</MenuItem>
            <MenuItem value="NOT_PAID">Non pagato</MenuItem>
          </TextField>
        </ResponsiveGridItem>
      </Grid>
    }
    actions={
      <>
        <Button color="inherit" onClick={() => {
          handleClose();
        }}>
          Chiudi
        </Button>
        <Button color="inherit" onClick={() => {
          handleClean();
          handleClose();
        }}>
          Reset
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={() => {
          handleConfirm(state);
          handleClose();
        }}>
          Applica
        </Button>
      </>
    }
  />
}

export default PaymentsFiltersModal;
