import React from 'react';
import AdminLayout from "../../layouts/admin/AdminLayout";
import {Box} from "@mui/material";
import CallsTable from "../../components/admin/calls/CallsTable";
import AuthContext from '../../route/AuthContext';
import {useLocation} from 'react-router-dom';
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';

const Calls = () => {
  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);

  return (
    <AdminLayout
      title="Bandi | Piattaforma Innovation ReSearch"
      breadcrumb={contentBreadcrumb}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1
      }}>
        <CallsTable />
      </Box>
    </AdminLayout>
  );
};

export default Calls;
