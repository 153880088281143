import { Button, Grid, MenuItem, TextField } from "@mui/material";
import React from "react";
import researchConsumerType from "../../../constants/researchConsumerType";
import researchProducerType from "../../../constants/researchProducerType";
import roles from "../../../constants/roles";
import UsersFilters from "../../../model/filters/UsersFilters";
import CustomDialog from "../../common/CustomDialog";

interface UsersFiltersModalProps {
  open: boolean,
  filters: UsersFilters,
  handleClose: () => any,
  handleClean: () => any,
  handleConfirm: (newStateFilters: UsersFilters) => any,
}

const UsersFiltersModal = ({open, filters, handleClose, handleClean, handleConfirm}: UsersFiltersModalProps) => {
  const [state, setState] = React.useState<UsersFilters>(filters);

  const handleChange = (name: string, value: string) => {
    const newFilters = {...state};
    switch (name) {
      case 'name':
        newFilters.name = {label: '', value: ''};
        newFilters.name.label = "Nominativo";
        newFilters.name.value = value;
        break;
      case 'email':
        newFilters.email = {label: '', value: ''};
        newFilters.email.label = "Email";
        newFilters.email.value = value;
        break;
      case 'role':
        newFilters.role = {label: '', value: ''};
        newFilters.role.label = "Tipologia";
        newFilters.role.value = value;
        const r = roles.find(value1 => value1.id === value);
        newFilters.role.valueDisplay = r?.name;
        break;
      case 'state':
        newFilters.state = {label: '', value: ''};
        newFilters.state.label = "Stato attivazione";
        newFilters.state.value = value;
        switch(value){
          case 'ACTIVE':
            newFilters.state.valueDisplay = 'Attivo';
            break;
          case 'INACTIVE':
            newFilters.state.valueDisplay = 'Non attivo';
            break;
        }
        break;
        break;
      case 'producerType':
        newFilters.producerType = {label: '', value: ''};
        newFilters.producerType.label = "Tipologia produttore di ricerca";
        newFilters.producerType.value = value;
        const pt = researchProducerType.find(value1 => value1.id === value);
        newFilters.producerType.valueDisplay = pt?.name;
        break;
      case 'consumerType':
        newFilters.consumerType = {label: '', value: ''};
        newFilters.consumerType.label = "Tipologia consumatore di ricerca";
        newFilters.consumerType.value = value;
        const ct = researchConsumerType.find(value1 => value1.id === value);
        newFilters.consumerType.valueDisplay = ct?.name;
        break;
      case 'region':
        newFilters.region = {label: '', value: ''};
        newFilters.region.label = "Regione";
        newFilters.region.value = value;
        break;
      case 'province':
        newFilters.province = {label: '', value: ''};
        newFilters.province.label = "Provincia";
        newFilters.province.value = value;
        break;
      case 'city':
        newFilters.city = {label: '', value: ''};
        newFilters.city.label = "Citta'";
        newFilters.city.value = value;
        break;
    }
    setState(newFilters);
  }

  React.useEffect(() => {
    setState(filters);
  }, [filters]);

  return <CustomDialog
    open={open ? open : false}
    handleClose={handleClose}
    title="Filtri"
    content={<Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          onChange={(event) => handleChange('name', event.target.value)}
          value={state && state.name ? state.name.value : ''}
          label="Nominativo"
          margin="dense"
          name="name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          onChange={(event) => handleChange('email', event.target.value)}
          value={state && state.email ? state.email.value : ''}
          label="Email"
          margin="dense"
          name="email"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          label="Tipologia"
          margin="dense"
          name="role"
          onChange={(event) => handleChange('role', event.target.value)}
          value={state && state.role ? state.role.value : ''}
          select
          variant="outlined"
        >
          {roles.map(value => <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          label="Stato"
          margin="dense"
          name="state"
          onChange={(event) => handleChange('state', event.target.value)}
          value={state && state.state ? state.state.value : ''}
          select
          variant="outlined"
        >
          <MenuItem value="ACTIVE">Attivo</MenuItem>
          <MenuItem value="INACTIVE">Non attivo</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          label="Tipologia produttore di ricerca"
          margin="dense"
          name="producerType"
          onChange={(event) => handleChange('producerType', event.target.value)}
          select
          value={state && state.producerType ? state.producerType.value : ''}
          variant="outlined"
        >
          {researchProducerType.map(value => <MenuItem key={value.id} value={value.id}>{value.label}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          label="Tipologia consumatore di ricerca"
          margin="dense"
          name="consumerType"
          onChange={(event) => handleChange('consumerType', event.target.value)}
          select
          value={state && state.consumerType ? state.consumerType.value : ''}
          variant="outlined"
        >
          {researchConsumerType.map(value => <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          onChange={(event) => handleChange('region', event.target.value)}
          value={state && state.region ? state.region.value : ''}
          label="Regione"
          margin="dense"
          name="region"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          onChange={(event) => handleChange('province', event.target.value)}
          value={state && state.province ? state.province.value : ''}
          label="Provincia"
          margin="dense"
          name="province"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          onChange={(event) => handleChange('city', event.target.value)}
          value={state && state.city ? state.city.value : ''}
          label="Citta'"
          margin="dense"
          name="city"
          variant="outlined"
        />
      </Grid>
    </Grid>
    }
    actions={
      <>
        <Button color="inherit" onClick={() => {
          handleClose();
        }}>
          Chiudi
        </Button>
        <Button color="inherit" onClick={() => {
          handleClean();
          handleClose();
        }}>
          Reset
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={() => {
          handleConfirm(state);
          handleClose();
        }}>
          Applica
        </Button>
      </>
    }
  />
}

export default UsersFiltersModal;
