import LoginLayoutRoot from "./LoginLayoutRoot";
import LoginLayoutWrapper from "./LoginLayoutWrapper";
import LoginLayoutContainer from "./LoginLayoutContainer";
import LoginLayoutContent from "./LoginLayoutContent";
import {Helmet} from "react-helmet";
import React from "react";

interface LoginLayoutProps {
    children?: React.ReactNode
}

const LoginLayout = ({ children }: LoginLayoutProps) => (
  <LoginLayoutRoot>
    <Helmet>
      <title>Login | Piattaforma Innovation ReSearch</title>
    </Helmet>
    {/*<LoginLayoutSideWrapper>*/}
    {/*  <LoginLayoutContainer>*/}
    {/*    <LoginLayoutContent>*/}
    {/*        {loginRegister}*/}
    {/*    </LoginLayoutContent>*/}
    {/*  </LoginLayoutContainer>*/}
    {/*</LoginLayoutSideWrapper>*/}
    <LoginLayoutWrapper>
      <LoginLayoutContainer>
        <LoginLayoutContent>
          {children}
        </LoginLayoutContent>
      </LoginLayoutContainer>
    </LoginLayoutWrapper>
  </LoginLayoutRoot>
);

export default LoginLayout;
