import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import {Formik} from "formik";
import React, {ReactNode} from "react";
import * as Yup from "yup";
import Keyword from "../../../model/database/Keyword";
import SustainableDevelopmentGoal from "../../../model/database/SustainableDevelopmentGoal";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import KeywordDTO from "../../../model/dto/KeywordDTO";

interface KeywordModalProps {
  open: boolean,
  keyword?: Keyword,
  sdgs: SustainableDevelopmentGoal[],
  handleClose: () => void,
  handleSave: (keywordToSave: KeywordDTO) => Promise<boolean>
}

const KeywordModal = ({ open, keyword, sdgs, handleSave, handleClose }: KeywordModalProps) => {

  const formikRef = React.useRef<any>(undefined);

  const handleResetAndClose = () => {
    if (formikRef.current)
      formikRef.current.resetForm();
    handleClose();
  }

  return <Formik
    innerRef={formikRef}
    enableReinitialize
    initialValues={{
      name: keyword ? keyword.name : '',
      description: keyword ? keyword.description : '',
      categories: keyword && keyword.sdgs ? keyword?.sdgs?.map(value => value.id) : []
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Il nome è obbligatorio e non può essere vuoto.'),
      description: Yup.string().max(4096, 'Il valore massimo consentito è di 4096 caratteri')
    })}
    onSubmit={async (values, { resetForm }) => {
      let result = await handleSave({
        name: values.name,
        description: values.description,
        sdgs: values.categories
      });

      if (result)
        resetForm();
    }}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      touched,
      submitForm,
      setFieldValue,
      values
    }) => (
      <CustomDialog
        open={open ? open : false}
        handleClose={() => {
          if (!isSubmitting) {
            handleResetAndClose();
          }
        }}
        title={keyword ? 'Modifica parola chiave' : 'Aggiungi parola chiave'}
        content={
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <ResponsiveGridItem>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nome"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  disabled={isSubmitting}
                  value={values.name}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem>
                <Autocomplete
                    options={sdgs ?? []}
                    fullWidth
                    multiple
                    getOptionLabel={(option) => option.name ?? ''}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={sdgs.filter(value => values.categories.includes(value.id)) ?? []}
                    onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                      setFieldValue('categories', newValue.map((v: any) => v.id));
                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        required
                        variant="outlined"
                        name="categories"
                        margin="normal"
                        label="Categoria"
                        error={Boolean(touched.categories && errors.categories)}
                        helperText={(touched.categories && errors.categories) as React.ReactNode}
                    />
                    }
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  multiline
                  rows={4}
                  helperText={(touched.description && errors.description) as ReactNode}
                  label="Descrizione"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  value={values.description}
                  variant="outlined"
                />
              </ResponsiveGridItem>
            </Grid>
          </form>
        }
        actions={
          <>
            <Button color="inherit" disabled={isSubmitting} onClick={handleResetAndClose}>
              Chiudi
            </Button>
            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
              Conferma
              {isSubmitting && <CircularLoading />}
            </Button>
          </>
        }
      />
    )}
  </Formik>;
}

export default KeywordModal;
