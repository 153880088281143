import React from 'react';
import MainLayout from "../../layouts/public/MainLayout";
import {Helmet} from "react-helmet";
import {Stack} from "@mui/material";
import SearchForm from "../../components/public/home/SearchForm";
import PublicStatistics from "../../components/public/home/PublicStatistics";
import Logos from '../../components/public/home/Logos';
import ProjectDescription from '../../components/public/home/ProjectDescription';
import Footer from '../../components/public/home/Footer';

const HomePage = () => {

  return (
    <MainLayout
      elevation={0}
    >
      <Helmet>
        <title>Piattaforma Innovation ReSearch</title>
      </Helmet>
      {/* <Container maxWidth="md"> */}
      <Stack direction="row" gap={0}>
          <Logos />
          <SearchForm />
          <ProjectDescription />
          <PublicStatistics />
          <Footer />
      </Stack>
        
        
      {/* </Container> */}
    </MainLayout>
  );
};

export default HomePage;
