import { ListSubheader, MenuItem, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import * as React from 'react';
import researchConsumerType from "../../../../constants/researchConsumerType";
import researchProducerType from "../../../../constants/researchProducerType";
import ResponsiveGridItem from "../../../common/ResponsiveGridItem";
import SectionProps from "./SectionProps";

const RegistrationUserSection = ({ readOnly }: SectionProps) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    touched,
    values
  } = useFormikContext<any>();

  return (
    <ResponsiveGridItem container spacing={1}>
      <ResponsiveGridItem>
        <Typography variant="h5">Informazioni utente</Typography>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <TextField
          error={Boolean(touched.type && errors.type)}
          fullWidth
          helperText={(touched.type && errors.type) as React.ReactNode}
          label="Tipologia"
          margin="normal"
          name="type"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          disabled={isSubmitting}
          value={values.type}
          required
          variant="outlined"
          inputProps={{
            readOnly: readOnly
          }}
        >
          <ListSubheader>Produttori di ricerca</ListSubheader>
          {[...researchProducerType].map(value => (
            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
          ))}
          <ListSubheader>Consumatori di ricerca</ListSubheader>
          {[...researchConsumerType].map(value => (
            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
          ))}
        </TextField>
      </ResponsiveGridItem>
      <ResponsiveGridItem md={6}>
        <TextField
          error={Boolean(touched.username && errors.username)}
          fullWidth
          helperText={(touched.username && errors.username) as React.ReactNode}
          label="Username"
          margin="normal"
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          disabled={isSubmitting}
          value={values.username}
          required
          variant="outlined"
          inputProps={{
            readOnly: readOnly
          }}
        />
      </ResponsiveGridItem>
      <ResponsiveGridItem md={6}>
        <TextField
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={(touched.email && errors.email) as React.ReactNode}
          label="Email"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          disabled={isSubmitting}
          value={values.email}
          required
          variant="outlined"
          inputProps={{
            readOnly: readOnly
          }}
        />
      </ResponsiveGridItem>
      {!readOnly && <ResponsiveGridItem md={6}>
        <TextField
          error={Boolean(touched.password && errors.password)}
          fullWidth
          helperText={(touched.password && errors.password) as React.ReactNode}
          label="Password"
          margin="normal"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          disabled={isSubmitting}
          value={values.password}
          required
          variant="outlined"
          inputProps={{
            readOnly: readOnly
          }}
        />
      </ResponsiveGridItem>}
      {!readOnly && <ResponsiveGridItem md={6}>
        <TextField
          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          fullWidth
          helperText={(touched.confirmPassword && errors.confirmPassword) as React.ReactNode}
          label="Conferma password"
          margin="normal"
          name="confirmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          disabled={isSubmitting}
          value={values.confirmPassword}
          required
          variant="outlined"
          inputProps={{
            readOnly: readOnly
          }}
        />
      </ResponsiveGridItem>}
    </ResponsiveGridItem>
  )
}

export default RegistrationUserSection;
