import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import http from '../utils/HttpCommon';
import CategoryFilters from "../model/filters/CategoryFilters";
import SustainableDevelopmentGoal from "../model/database/SustainableDevelopmentGoal";

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/sdgs/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: CategoryFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/sdgs/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const save = async (context: LoggedUserContext, sdg: SustainableDevelopmentGoal) => {
	try {
		const response = await http.privateClient(context).post<any>('/sdgs', {
			name: sdg.name,
			description: sdg.description
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const update = async (context: LoggedUserContext, sdg: SustainableDevelopmentGoal) => {
	try {
		const response = await http.privateClient(context).put<any>(`/sdgs/${sdg.id}`, {
			name: sdg.name,
			description: sdg.description
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/sdgs/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteAll = async (context: LoggedUserContext) => {}
const exportSelected = async (context: LoggedUserContext) => {}

const CategoryService = {
	getAll,
	getAllFiltered,
	save,
	update,
	deleteById,
	deleteAll,
	exportSelected
}

export default CategoryService;