import {Autocomplete, Button, Grid, MenuItem, TextField} from "@mui/material";
import {Formik} from "formik";
import React from "react";
import * as Yup from "yup";
import Payment from "../../../model/database/Payment";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import PaymentDTO from "../../../model/dto/PaymentDTO";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import researchProducerType from "../../../constants/researchProducerType";
import researchConsumerType from "../../../constants/researchConsumerType";
import StyledRating from "../../common/StyledRating";

interface PaymentModalProps {
  open: boolean,
  payment?: Payment,
  researchConsumers: ResearchConsumer[],
  handleClose: () => void,
  handleSave: (paymentToSave: PaymentDTO) => Promise<boolean>
}

const PaymentModal = ({ open, payment, researchConsumers, handleSave, handleClose }: PaymentModalProps) => {

  const formikRef = React.useRef<any>(undefined);
  const [title, setTitle] = React.useState<string>('Crea nuovo pagamento');

  const handleResetAndClose = () => {
    if (formikRef.current)
      formikRef.current.resetForm();
    handleClose();
  }

  React.useEffect(() => {
    if (payment)
      setTitle('Modifica pagamento');
    else
      setTitle('Crea nuovo pagamento');
  }, [payment]);

  return <Formik
    innerRef={formikRef}
    enableReinitialize
    initialValues={{
      user: payment?.researchConsumer?.user?.id ?? '',
      year: payment?.year ?? '',
      paid: payment?.paid ? 'PAID' : 'NOT_PAID',
      paymentDate: payment?.paymentDate ?? ''
    }}
    validationSchema={Yup.object().shape({
      user: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('L\'utente pagatore è obbligatorio e non può essere vuoto.'),
      year: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('L\'anno di riferimento è obbligatorio e non può essere vuoto.'),
      paid: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Lo stato di pagamento è obbligatorio e non può essere vuoto.'),
      paymentDate: Yup.string().when('paid', {
        is: (paid: string) => paid === 'PAID',
        then: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          .required('La data di pagamento è obbligatoria e non può essere vuota.')
      })
    })}
    onSubmit={async (values) => {
      let result = await handleSave({
        paid: values.paid === 'PAID',
        year: values.year,
        paymentDate: values.paymentDate,
        user: values.user
      });
      if (result) {
        handleResetAndClose();
      }
    }}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      touched,
      submitForm,
      setFieldValue,
      values
    }) => (
      <CustomDialog
        open={open ? open : false}
        handleClose={() => {
          if (!isSubmitting) handleResetAndClose()
        }}
        title={title}
        content={
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <ResponsiveGridItem>
                <Autocomplete
                    options={researchConsumers ?? []}
                    fullWidth
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={researchConsumers.find(value => values.user === value.user?.id)}
                    getOptionLabel={option =>
                        `[${[...researchProducerType, ...researchConsumerType].find(value1 => value1.id === option.type)?.name}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}`
                    }
                    onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                      setFieldValue('user', newValue.user.id);
                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        required
                        variant="outlined"
                        name="user"
                        margin="normal"
                        label="Utente pagatore"
                        error={Boolean(touched.user && errors.user)}
                        helperText={(touched.user && errors.user) as React.ReactNode}
                    />
                    }
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem md={4}>
                <TextField
                  error={Boolean(touched.year && errors.year)}
                  fullWidth
                  helperText={touched.year && errors.year}
                  label="Anno di riferimento"
                  margin="normal"
                  name="year"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  disabled={isSubmitting}
                  value={values.year}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem md={4}>
                <TextField
                  error={Boolean(touched.paid && errors.paid)}
                  fullWidth
                  helperText={touched.paid && errors.paid}
                  label="Stato del pagamento"
                  margin="normal"
                  name="paid"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  disabled={isSubmitting}
                  value={values.paid}
                  required
                  variant="outlined"
                >
                  <MenuItem value="PAID">Pagato</MenuItem>
                  <MenuItem value="NOT_PAID">Non pagato</MenuItem>
                </TextField>
              </ResponsiveGridItem>
              <ResponsiveGridItem md={4}>
                <TextField
                  error={Boolean(touched.paymentDate && errors.paymentDate)}
                  fullWidth
                  helperText={touched.paymentDate && errors.paymentDate}
                  label="Data di pagamento"
                  margin="normal"
                  name="paymentDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={isSubmitting}
                  value={values.paymentDate}
                  required={values.paid === 'PAID'}
                  variant="outlined"
                />
              </ResponsiveGridItem>
            </Grid>
          </form>
        }
        actions={
          <>
            <Button color="inherit" disabled={isSubmitting} onClick={handleResetAndClose}>
              Chiudi
            </Button>
            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
              Conferma
              {isSubmitting && <CircularLoading />}
            </Button>
          </>
        }
      />
    )}
  </Formik>
    ;
}

export default PaymentModal;
