import * as React from 'react';
import {TextField, Typography} from "@mui/material";
import {useFormikContext} from "formik";
import ResponsiveGridItem from "../../../common/ResponsiveGridItem";
import SectionProps from "./SectionProps";
import RegionAutocomplete from "../../../common/select/RegionAutocomplete";
import ProvinceAutocomplete from "../../../common/select/ProvinceAutocomplete";
import CityAutocomplete from "../../../common/select/CityAutocomplete";
import researchConsumerType from "../../../../constants/researchConsumerType";

const RegistrationConsumerSection = ({readOnly}: SectionProps) => {

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    touched,
    values
  } = useFormikContext<any>();

  return <ResponsiveGridItem container spacing={1}>
    <ResponsiveGridItem mt={2}>
      <Typography variant="h5">Informazioni responsabile</Typography>
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <TextField
        error={Boolean(touched.name && errors.name)}
        fullWidth
        helperText={(touched.name && errors.name) as React.ReactNode}
        label="Nome"
        margin="normal"
        name="name"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.name}
        required
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <TextField
        error={Boolean(touched.surname && errors.surname)}
        fullWidth
        helperText={(touched.surname && errors.surname) as React.ReactNode}
        label="Cognome"
        margin="normal"
        name="surname"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.surname}
        required
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    <ResponsiveGridItem mt={2}>
      <Typography variant="h5">Informazioni di dettaglio</Typography>
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <TextField
        error={Boolean(touched.consumerName && errors.consumerName)}
        fullWidth
        helperText={(touched.consumerName && errors.consumerName) as React.ReactNode}
        label={"Nome " + researchConsumerType.find(value => value.id === values.type)?.name.toLowerCase()}
        margin="normal"
        name="consumerName"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.consumerName}
        required
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <TextField
        error={Boolean(touched.consumerVatNumber && errors.consumerVatNumber)}
        fullWidth
        helperText={(touched.consumerVatNumber && errors.consumerVatNumber) as React.ReactNode}
        label="Partita IVA"
        margin="normal"
        name="consumerVatNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.consumerVatNumber}
        required
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <RegionAutocomplete  value={values.consumerRegion} fieldName="consumerRegion" readOnly={readOnly} />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <ProvinceAutocomplete value={values.consumerProvince} fieldName="consumerProvince" regionSelected={values.consumerRegion} readOnly={readOnly} />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={5}>
      <CityAutocomplete value={values.consumerCity} fieldName="consumerCity" provinceSelected={values.consumerProvince} readOnly={readOnly} />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={5}>
      <TextField
        error={Boolean(touched.consumerAddress && errors.consumerAddress)}
        fullWidth
        helperText={(touched.consumerAddress && errors.consumerAddress) as React.ReactNode}
        label="Indirizzo"
        margin="normal"
        name="consumerAddress"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.consumerAddress}
        required
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={2}>
      <TextField
        error={Boolean(touched.consumerPostalCode && errors.consumerPostalCode)}
        fullWidth
        helperText={(touched.consumerPostalCode && errors.consumerPostalCode) as React.ReactNode}
        label="Codice postale"
        margin="normal"
        name="consumerPostalCode"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.consumerPostalCode}
        required
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
  </ResponsiveGridItem>

};

export default RegistrationConsumerSection;
