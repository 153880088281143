import { LabelValue } from "../../filters/LabelValue";

const productSortFields: LabelValue[] = [
  {
    label: 'Approvato',
    value: 'product.approved'
  },
  {
    label: 'Nome',
    value: 'product.name'
  },
  {
    label: 'Produttore',
    value: 'producer.name'
  },  
]

export default productSortFields;
