import React from 'react';
import AdminLayout from "../../layouts/admin/AdminLayout";
import {Box} from "@mui/material";
import DiscussionsTable from "../../components/admin/discussions/DiscussionsTable";
import AuthContext from '../../route/AuthContext';
import {useLocation} from 'react-router-dom';
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';

const Discussions = () => {

  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);
  
  return (
    <AdminLayout
      breadcrumb={contentBreadcrumb}
      title="Tavoli | Piattaforma Innovation ReSearch"
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1
      }}>
        <DiscussionsTable />
      </Box>
    </AdminLayout>
  );
};

export default Discussions;
