import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";

interface SearchCardProps {
  searchText?: string
  handleSearch?: (value: string) => void
}
const SearchCard = ({searchText, handleSearch}: SearchCardProps) => {
  const [searchValue, setSearchValue] = React.useState<string>(searchText ?? '');
  return (
    <Card sx={{width: '100%'}}>
      <CardContent>
        <Typography variant="h6">Ricerca</Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <Grid
            item
            md={10}
            sm={12}
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              onChange={event => setSearchValue(event.currentTarget.value)}
              value={searchValue}
              size="small"
              margin="normal"
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={12}
            xs={12}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleSearch?.(searchValue)}
            >
              <Search/> Ricerca
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SearchCard;
