import React from 'react';
import {Container} from "@mui/material";
import MainLayout from "../../layouts/public/MainLayout";
import {Helmet} from "react-helmet";
import EmailForm from "../../components/public/login/EmailForm";

const ForgotPassword = () => {
    return (
        <MainLayout showLogo>
            <Helmet>
                <title>Password dimenticata | Piattaforma Innovation ReSearch</title>
            </Helmet>
            <Container sx={{mt: 4}}>
                <EmailForm />
            </Container>
        </MainLayout>
    );
};

export default ForgotPassword;
