import {ThemeProvider} from "@mui/material";
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import './App.css';
import LoggedUser from './model/auth/LoggedUser';
import AuthContext from './route/AuthContext';
import routes from "./route/routes";
import theme from "./theme";
import AuthService from "./services/AuthService";


ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function App() {

    let parsedUser = AuthService.getCurrentUser();

    const [user, setUser] = React.useState<LoggedUser | undefined>(parsedUser);
    const [needRefreshSidebar, setNeedRefreshSidebar] = React.useState<boolean>(true);

    return (
        <ThemeProvider theme={theme}>
            <AuthContext.Provider value={{
                user,
                setUser,
                needRefreshSidebar,
                setNeedRefreshSidebar
            }}
            >
                <BrowserRouter>
                    <Routes>
                      {routes.map((obj) => {
                        if (obj.authenticaed) {
                          if (user && user.token !== undefined && obj?.roles?.indexOf(user?.role ?? '') !== -1) {
                            return <Route key={obj.path} path={obj.path} element={obj.element} />
                          } else {
                            return <Route key={obj.path} path={obj.path} element={<Navigate to="/login" />} />
                          }
                        } else {
                          return <Route key={obj.path} path={obj.path} element={obj.element} />;
                        }
                      })}
                    </Routes>
                </BrowserRouter>
            </AuthContext.Provider>
        </ThemeProvider>
    );
}

export default App;
