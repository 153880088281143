import React from 'react';
import LoginLayout from "../../layouts/login/LoginLayout";
import LoginForm from "../../components/public/login/LoginForm";
import LoginRegister from "../../components/public/login/LoginRegister";
import {Container, Grid, Hidden, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import LogoUnisalento from "../../components/common/logo/LogoUnisalento";
import LogoInnovationResearch from "../../components/common/logo/LogoInnovationResearch";

const Login = () => {
  return (
    <LoginLayout>
      <Hidden lgUp>
        <Container sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          height: '100%'
        }}>
          <NavLink to="/" style={{
            width: '50%'
          }}>
            <LogoUnisalento style={{
              width: '100%'
            }}/>
          </NavLink>
          <LogoInnovationResearch variant="h4" sx={{mb: 2}} width="auto" height={64}/>
          <LoginForm mt={2}/>
          <Typography variant="subtitle1" mt={4}>Non sei un utente registrato?</Typography>
          <NavLink
            to="/registration"
          >
            Registrati
          </NavLink>
        </Container>
      </Hidden>
      <Hidden lgDown>
        <Grid container height="100%">
          <Grid item lg={8}>
            <LoginRegister/>
          </Grid>
          <Grid item lg={4}>
            <LoginForm />
          </Grid>
        </Grid>
      </Hidden>
    </LoginLayout>
  );
};

export default Login;
