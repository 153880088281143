import { Departments } from "./departments";

const cnr: Departments = {
    parentId: '',
    departments: [
        'ISTITUTO PER LA SCIENZA E TECNOLOGIA DEI PLASMI (241120 - Bari)',
        'IST SCIENZE ALIMENTAZIONE - URT BARI (225503 - Bari)',
        'ISTITUTO DI BIOMEMBRANE, BIOENERGETICA E BIOTECNOLOGIE MOLECOLARI (221100 - Bari)',
        'ISTITUTO DI BIOSCIENZE E BIORISORSE (233000 - Bari)',
        'ISTITUTO DI CHIMICA DEI COMPOSTI ORGANO METALLICI (221610 - Bari)',
        'ISTITUTO DI CRISTALLOGRAFIA (222100 - Bari)',
        'ISTITUTO DI FOTONICA E NANOTECNOLOGIE (231550 - Bari)',
        'ISTITUTO DI NANOTECNOLOGIA (234120 - Bari)',
        'ISTITUTO DI RICERCA PER LA PROTEZIONE IDROGEOLOGICA (224510 - Bari)',
        'ISTITUTO DI RICERCA SULLE ACQUE (224810 - Bari)',
        'ISTITUTO DI SCIENZE DELLE PRODUZIONI ALIMENTARI (225400 - Bari)',
        'ISTITUTO DI SISTEMI E TECNOLOGIE INDUSTRIALI INTELLIGENTI PER IL MANIFATTURIERO AVANZATO (234820 - Bari)',
        'ISTITUTO DI SISTEMI E TECNOLOGIE INDUSTRIALI INTELLIGENTI PER IL MANIFATTURIERO AVANZATO (234840 - Bari)',
        'ISTITUTO DI TECNOLOGIE BIOMEDICHE (230310 - Bari)',
        'ISTITUTO PER I PROCESSI CHIMICO-FISICI (231930 - Bari)',
        'ISTITUTO PER IL RILEVAMENTO ELETTROMAGNETICO DELL\'AMBIENTE (230220 - Bari)',
        'ISTITUTO PER LA PROTEZIONE SOSTENIBILE DELLE PIANTE (233440 - Bari)',
        'ISTITUTO PER LE APPLICAZIONI DEL CALCOLO "MAURO PICONE" (228910 - Bari)',
        'ISTITUTO PER LE TECNOLOGIE DELLA COSTRUZIONE (229210 - Bari)',
        'ISTITUTO SULL\'INQUINAMENTO ATMOSFERICO (229840 - Bari)',
        'ISTITUTO DI RICERCHE SULLA POPOLAZIONE E LE POLITICHE SOCIALI (224701 - Brindisi)',
        'ISTITUTO DI SCIENZE DELLE PRODUZIONI ALIMENTARI (225450 - Foggia)',
        'ISTITUTO DI FISIOLOGIA CLINICA (222610 - Lecce)',
        'ISTITUTO DI NANOTECNOLOGIA (234100 - Lecce)',
        'ISTITUTO DI RICERCA SUGLI ECOSISTEMI TERRESTRI URT LECCE (235003 - Lecce)',
        'ISTITUTO DI SCIENZE APPLICATE E SISTEMI INTELLIGENTI "EDUARDO CAIANIELLO" (222010 - Lecce)',
        'ISTITUTO DI SCIENZE DEL PATRIMONIO CULTURALE (239050 - Lecce)',
        'ISTITUTO DI SCIENZE DELL\'ATMOSFERA E DEL CLIMA (225310 - Lecce)',
        'ISTITUTO DI SCIENZE DELLE PRODUZIONI ALIMENTARI (225410 - Lecce)',
        'ISTITUTO PER LA MICROELETTRONICA E MICROSISTEMI (228320 - Lecce)',
        'ISTITUTO DI RICERCA SULLE ACQUE (224840 - Taranto) '
    ]
}
export default cnr;