const YoutubeEmbed = () => {
  const aspectRatioWrapperStyle = {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%', // This is the aspect ratio (16:9), adjust as needed
    height: 0,
    overflow: 'hidden',
  } as React.CSSProperties;

  const iframeStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
  } as React.CSSProperties;
  return <div style={aspectRatioWrapperStyle}>
      <iframe style={iframeStyle}
      src="https://www.youtube.com/embed/cHTtaNbOOxM?si=FOZt9XDffXnqYCi8?controls=0"
      width='100%'
      height='auto'
      allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      />
    </div>
};

export default YoutubeEmbed;