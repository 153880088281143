import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";

const RegistrationCompletedStep = () => (
  <React.Fragment>
    <Card sx={{ minWidth: 275, mt: 2 }}>
      <CardContent>
        <Box mt={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography variant="h5">Registrazione Completata</Typography>
          <CheckCircle fontSize="large" color="success" />
          <Typography variant="body1">La tua richiesta di registrazione è stata sottoposta al sistema.</Typography>
          <Typography variant="body1">Non appena verrà convalidata verrai avvisato tramite email sull'indirizzo indicato.</Typography>
        </Box>
      </CardContent>
    </Card>
  </React.Fragment>
)

export default RegistrationCompletedStep;
