import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import packageJson from '../../../package.json'

interface InfoModalProps {
    open: boolean,
    handleClose: () => void
}

const InfoModal = ({open, handleClose}: InfoModalProps) => (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Informazioni</DialogTitle>
        <DialogContent>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography variant="h5" gutterBottom>Piattaforma</Typography>
                <Typography variant="h4" gutterBottom>Innovation ReSearch</Typography>
                <Typography variant="h6" gutterBottom>Versione {packageJson.version}</Typography>

                <Typography variant="subtitle1" gutterBottom mt={5}>Sviluppata da</Typography>
                <img src="/static/infotech-logo.jpg" alt="infotech logo" width="50%" height="auto"/>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant="plain" onClick={handleClose}>Chiudi</Button>
        </DialogActions>
    </Dialog>
);

export default InfoModal;
