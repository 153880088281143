import {Delete, Edit} from "@mui/icons-material";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {CommonColumnProps} from "../CommonColumnProps";

interface CategoriesTableColumnsProps {
  editCallback: (id: string) => any,
  deleteCallback: (id: string) => any,
}

const CategoriesTableColumns = ({editCallback, deleteCallback }: CategoriesTableColumnsProps) : GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    ...CommonColumnProps
  },
  {
    field: 'name',
    headerName: 'Nome',
    ...CommonColumnProps
  },
  {
    field: 'description',
    headerName: 'Descrizione',
    ...CommonColumnProps,
    flex: 2
  },
  {
    field: "action",
    headerName: "Operazioni",
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => {
      return <Stack direction="row" spacing={1}>
        <Tooltip title="Modifica">
          <IconButton color="secondary" onClick={() => editCallback(params.id as string)} >
            <Edit/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Rimuovi">
          <IconButton color="error" onClick={() => deleteCallback(params.id as string)} >
            <Delete/>
          </IconButton>
        </Tooltip>
      </Stack>;
    },
    ...CommonColumnProps
  },
];

export default CategoriesTableColumns;
