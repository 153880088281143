import Item from "../../common/Item";
import {Avatar, Divider, Stack, Typography} from "@mui/material";
import React from "react";

interface SummaryBadgeProps {
  icon: React.ReactNode,
  title: string,
  labels: [string, string],
  values: [number, number],
  elevation?: number,
  color?: string
}

const SummaryWidget = ({title, values, icon, labels, elevation, color}: SummaryBadgeProps) => (
  <Item elevation={elevation ?? 1}>
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Avatar sx={{ bgcolor: color ? `${color}.main` : 'primary.main', color: color ? `${color}.contrastText` : 'primary.contrastText' }}>
          {icon}
        </Avatar>
        <Typography variant="body1" gutterBottom textAlign="right">{title}</Typography>
      </Stack>
      <Divider variant="middle" />
      <Stack direction="row" spacing={1} justifyContent="space-evenly" alignItems="center">
        {labels.map((value, index) => (
          <Stack direction="column" key={value}>
            <Typography variant="h6" fontWeight="bold">{values[index]}</Typography>
            <Typography variant="subtitle1" fontWeight="bold">{value}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  </Item>
);

export default SummaryWidget;
