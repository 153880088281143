import {Button, Grid, TextField} from "@mui/material";
import React from "react";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import KeywordFilters from "../../../model/filters/KeywordFilters";
import CustomDialog from "../../common/CustomDialog";
import CategoryFilters from "../../../model/filters/CategoryFilters";

interface CategoriesFiltersModalProps {
  open: boolean,
  filters: CategoryFilters,
  handleClose: () => any,
  handleClean: () => any,
  handleConfirm: (newStateFilters: KeywordFilters) => any,
}

const CategoriesFiltersModal = ({open, filters, handleClose, handleClean, handleConfirm}: CategoriesFiltersModalProps) => {
  const [state, setState] = React.useState<CategoryFilters>(filters);

  const handleChange = (name: string, value: string) => {
    const newFilters = {...state};
    switch (name) {
      case 'name':
        newFilters.name = {label: '', value: ''};
        newFilters.name.label = "Nome";
        newFilters.name.value = value;
        break;
    }
    setState(newFilters);
  }

  React.useEffect(() => {
    setState(filters);
  }, [filters]);

  return <CustomDialog
    open={open ? open : false}
    handleClose={handleClose}
    title="Filtri"
    content={
      <Grid container spacing={2}>
        <ResponsiveGridItem>
          <TextField
            fullWidth
            onChange={(event) => handleChange('name', event.target.value)}
            value={state && state.name ? state.name.value : '' }
            label="Nome"
            margin="dense"
            name="name"
            variant="outlined"
          />
        </ResponsiveGridItem>
      </Grid>
    }
    actions={
      <>
        <Button color="inherit" onClick={() => {
          handleClose();
        }}>
          Chiudi
        </Button>
        <Button color="inherit" onClick={() => {
          handleClean();
          handleClose();
        }}>
          Reset
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={() => {
          handleConfirm(state);
          handleClose();
        }}>
          Applica
        </Button>
      </>
    }
  />
}

export default CategoriesFiltersModal;
