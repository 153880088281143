import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {en} from "./en";
import {it} from "./it";

const resources = {
    en: en,
    it: it
};

i18n.use(initReactI18next).init({
    lng: 'it',
    resources,
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;