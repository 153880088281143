import {AddCircle} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";
import React from "react";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import PaymentsFilters from "../../../model/filters/PaymentsFilters";
import paymentSortFields from "../../../model/table/sort/PaymentSortFields";
import TableToolbar from "../../common/table/TableToolbar";
import PaymentsFiltersModal from "./PaymentsFiltersModal";

interface PaymentsTableToolbarProps {
  numSelected: number,
  handleNew: () => void,
  researchConsumers: ResearchConsumer[],
  applyFilters: (filters: PaymentsFilters) => void
  applySort: (sort: string) => void
}

const PaymentsTableToolbar = ({handleNew, numSelected, researchConsumers, applyFilters, applySort}: PaymentsTableToolbarProps) => {

  const [filters, setFilters] = React.useState<PaymentsFilters>({});

  const handleConfirm = (newStateFilters: PaymentsFilters) => {
    setFilters(newStateFilters);
    applyFilters(newStateFilters);
  }

  const handleClean = () => {
    setFilters({});
    applyFilters({});
  }

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <>
      <TableToolbar
        numSelected={numSelected}
        title="Pagamenti"
        fields={paymentSortFields}
        filters={Object.values(filters)}
        defaultField={paymentSortFields[0].value}
        defaultOrder={'asc'}
        handleOpenFiltersModal={handleOpenModal}
        handleCleanFilters={handleClean}
        handleSort={applySort}
        handleExport={() => alert('export selection')}
        handleDelete={() => alert('delete selection')}
        extendedActions={
          <>
            <Tooltip title="Crea nuovo pagamento">
              <IconButton color="secondary" onClick={handleNew}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <PaymentsFiltersModal
        open={openModal}
        filters={filters}
        researchConsumers={researchConsumers}
        handleClose={handleCloseModal}
        handleClean={handleClean}
        handleConfirm={handleConfirm}
      />
    </>
  );
}

export default PaymentsTableToolbar;
