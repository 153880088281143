const italyProvinces: any = {
    "Sicilia": [
        {"codice": "084", "nome": "Agrigento", "sigla": "AG", "regione": "Sicilia"}, {
            "codice": "085",
            "nome": "Caltanissetta",
            "sigla": "CL",
            "regione": "Sicilia"
        }, {"codice": "087", "nome": "Catania", "sigla": "CT", "regione": "Sicilia"}, {
            "codice": "086",
            "nome": "Enna",
            "sigla": "EN",
            "regione": "Sicilia"
        }, {"codice": "083", "nome": "Messina", "sigla": "ME", "regione": "Sicilia"}, {
            "codice": "082",
            "nome": "Palermo",
            "sigla": "PA",
            "regione": "Sicilia"
        }, {"codice": "088", "nome": "Ragusa", "sigla": "RG", "regione": "Sicilia"}, {
            "codice": "089",
            "nome": "Siracusa",
            "sigla": "SR",
            "regione": "Sicilia"
        }, {"codice": "081", "nome": "Trapani", "sigla": "TP", "regione": "Sicilia"}],
    "Piemonte": [{"codice": "006", "nome": "Alessandria", "sigla": "AL", "regione": "Piemonte"}, {
        "codice": "005",
        "nome": "Asti",
        "sigla": "AT",
        "regione": "Piemonte"
    }, {"codice": "096", "nome": "Biella", "sigla": "BI", "regione": "Piemonte"}, {
        "codice": "004",
        "nome": "Cuneo",
        "sigla": "CN",
        "regione": "Piemonte"
    }, {"codice": "003", "nome": "Novara", "sigla": "NO", "regione": "Piemonte"}, {
        "codice": "001",
        "nome": "Torino",
        "sigla": "TO",
        "regione": "Piemonte"
    }, {"codice": "103", "nome": "Verbano-Cusio-Ossola", "sigla": "VB", "regione": "Piemonte"}, {
        "codice": "002",
        "nome": "Vercelli",
        "sigla": "VC",
        "regione": "Piemonte"
    }],
    "Marche": [{"codice": "042", "nome": "Ancona", "sigla": "AN", "regione": "Marche"}, {
        "codice": "044",
        "nome": "Ascoli Piceno",
        "sigla": "AP",
        "regione": "Marche"
    }, {"codice": "109", "nome": "Fermo", "sigla": "FM", "regione": "Marche"}, {
        "codice": "043",
        "nome": "Macerata",
        "sigla": "MC",
        "regione": "Marche"
    }, {"codice": "041", "nome": "Pesaro e Urbino", "sigla": "PU", "regione": "Marche"}],
    "Toscana": [{"codice": "051", "nome": "Arezzo", "sigla": "AR", "regione": "Toscana"}, {
        "codice": "048",
        "nome": "Firenze",
        "sigla": "FI",
        "regione": "Toscana"
    }, {"codice": "053", "nome": "Grosseto", "sigla": "GR", "regione": "Toscana"}, {
        "codice": "049",
        "nome": "Livorno",
        "sigla": "LI",
        "regione": "Toscana"
    }, {"codice": "046", "nome": "Lucca", "sigla": "LU", "regione": "Toscana"}, {
        "codice": "045",
        "nome": "Massa-Carrara",
        "sigla": "MS",
        "regione": "Toscana"
    }, {"codice": "050", "nome": "Pisa", "sigla": "PI", "regione": "Toscana"}, {
        "codice": "047",
        "nome": "Pistoia",
        "sigla": "PT",
        "regione": "Toscana"
    }, {"codice": "100", "nome": "Prato", "sigla": "PO", "regione": "Toscana"}, {
        "codice": "052",
        "nome": "Siena",
        "sigla": "SI",
        "regione": "Toscana"
    }],
    "Campania": [{"codice": "064", "nome": "Avellino", "sigla": "AV", "regione": "Campania"}, {
        "codice": "062",
        "nome": "Benevento",
        "sigla": "BN",
        "regione": "Campania"
    }, {"codice": "061", "nome": "Caserta", "sigla": "CE", "regione": "Campania"}, {
        "codice": "063",
        "nome": "Napoli",
        "sigla": "NA",
        "regione": "Campania"
    }, {"codice": "065", "nome": "Salerno", "sigla": "SA", "regione": "Campania"}],
    "Puglia": [{"codice": "072", "nome": "Bari", "sigla": "BA", "regione": "Puglia"}, {
        "codice": "110",
        "nome": "Barletta-Andria-Trani",
        "sigla": "BAT",
        "regione": "Puglia"
    }, {"codice": "074", "nome": "Brindisi", "sigla": "BR", "regione": "Puglia"}, {
        "codice": "071",
        "nome": "Foggia",
        "sigla": "FG",
        "regione": "Puglia"
    }, {"codice": "075", "nome": "Lecce", "sigla": "LE", "regione": "Puglia"}, {
        "codice": "073",
        "nome": "Taranto",
        "sigla": "TA",
        "regione": "Puglia"
    }],
    "Veneto": [{"codice": "025", "nome": "Belluno", "sigla": "BL", "regione": "Veneto"}, {
        "codice": "028",
        "nome": "Padova",
        "sigla": "PD",
        "regione": "Veneto"
    }, {"codice": "029", "nome": "Rovigo", "sigla": "RO", "regione": "Veneto"}, {
        "codice": "026",
        "nome": "Treviso",
        "sigla": "TV",
        "regione": "Veneto"
    }, {"codice": "027", "nome": "Venezia", "sigla": "VE", "regione": "Veneto"}, {
        "codice": "023",
        "nome": "Verona",
        "sigla": "VR",
        "regione": "Veneto"
    }, {"codice": "024", "nome": "Vicenza", "sigla": "VI", "regione": "Veneto"}],
    "Lombardia": [{"codice": "016", "nome": "Bergamo", "sigla": "BG", "regione": "Lombardia"}, {
        "codice": "017",
        "nome": "Brescia",
        "sigla": "BS",
        "regione": "Lombardia"
    }, {"codice": "013", "nome": "Como", "sigla": "CO", "regione": "Lombardia"}, {
        "codice": "019",
        "nome": "Cremona",
        "sigla": "CR",
        "regione": "Lombardia"
    }, {"codice": "097", "nome": "Lecco", "sigla": "LC", "regione": "Lombardia"}, {
        "codice": "098",
        "nome": "Lodi",
        "sigla": "LO",
        "regione": "Lombardia"
    }, {"codice": "020", "nome": "Mantova", "sigla": "MN", "regione": "Lombardia"}, {
        "codice": "015",
        "nome": "Milano",
        "sigla": "MI",
        "regione": "Lombardia"
    }, {"codice": "108", "nome": "Monza e della Brianza", "sigla": "MB", "regione": "Lombardia"}, {
        "codice": "018",
        "nome": "Pavia",
        "sigla": "PV",
        "regione": "Lombardia"
    }, {"codice": "014", "nome": "Sondrio", "sigla": "SO", "regione": "Lombardia"}, {
        "codice": "012",
        "nome": "Varese",
        "sigla": "VA",
        "regione": "Lombardia"
    }],
    "Emilia Romagna": [{
        "codice": "037",
        "nome": "Bologna",
        "sigla": "BO",
        "regione": "Emilia Romagna"
    }, {"codice": "038", "nome": "Ferrara", "sigla": "FE", "regione": "Emilia Romagna"}, {
        "codice": "040",
        "nome": "Forlì-Cesena",
        "sigla": "FC",
        "regione": "Emilia Romagna"
    }, {"codice": "036", "nome": "Modena", "sigla": "MO", "regione": "Emilia Romagna"}, {
        "codice": "034",
        "nome": "Parma",
        "sigla": "PR",
        "regione": "Emilia Romagna"
    }, {"codice": "033", "nome": "Piacenza", "sigla": "PC", "regione": "Emilia Romagna"}, {
        "codice": "039",
        "nome": "Ravenna",
        "sigla": "RA",
        "regione": "Emilia Romagna"
    }, {"codice": "035", "nome": "Reggio nell'Emilia", "sigla": "RE", "regione": "Emilia Romagna"}, {
        "codice": "099",
        "nome": "Rimini",
        "sigla": "RN",
        "regione": "Emilia Romagna"
    }],
    "Trentino Alto Adige": [{
        "codice": "021",
        "nome": "Bolzano",
        "sigla": "BZ",
        "regione": "Trentino Alto Adige"
    }, {"codice": "022", "nome": "Trento", "sigla": "TN", "regione": "Trentino Alto Adige"}],
    "Sardegna": [{"codice": "092", "nome": "Cagliari", "sigla": "CA", "regione": "Sardegna"}, {
        "codice": "091",
        "nome": "Nuoro",
        "sigla": "NU",
        "regione": "Sardegna"
    }, {"codice": "095", "nome": "Oristano", "sigla": "OR", "regione": "Sardegna"}, {
        "codice": "090",
        "nome": "Sassari",
        "sigla": "SS",
        "regione": "Sardegna"
    }, {"codice": "111", "nome": "Sud Sardegna", "sigla": "SU", "regione": "Sardegna"}],
    "Molise": [{"codice": "070", "nome": "Campobasso", "sigla": "CB", "regione": "Molise"}, {
        "codice": "094",
        "nome": "Isernia",
        "sigla": "IS",
        "regione": "Molise"
    }],
    "Calabria": [{"codice": "079", "nome": "Catanzaro", "sigla": "CZ", "regione": "Calabria"}, {
        "codice": "078",
        "nome": "Cosenza",
        "sigla": "CS",
        "regione": "Calabria"
    }, {"codice": "101", "nome": "Crotone", "sigla": "KR", "regione": "Calabria"}, {
        "codice": "080",
        "nome": "Reggio Calabria",
        "sigla": "RC",
        "regione": "Calabria"
    }, {"codice": "102", "nome": "Vibo Valentia", "sigla": "VV", "regione": "Calabria"}],
    "Abruzzo": [{"codice": "069", "nome": "Chieti", "sigla": "CH", "regione": "Abruzzo"}, {
        "codice": "066",
        "nome": "L'Aquila",
        "sigla": "AQ",
        "regione": "Abruzzo"
    }, {"codice": "068", "nome": "Pescara", "sigla": "PE", "regione": "Abruzzo"}, {
        "codice": "067",
        "nome": "Teramo",
        "sigla": "TE",
        "regione": "Abruzzo"
    }],
    "Lazio": [{"codice": "060", "nome": "Frosinone", "sigla": "FR", "regione": "Lazio"}, {
        "codice": "059",
        "nome": "Latina",
        "sigla": "LT",
        "regione": "Lazio"
    }, {"codice": "057", "nome": "Rieti", "sigla": "RI", "regione": "Lazio"}, {
        "codice": "058",
        "nome": "Roma",
        "sigla": "RM",
        "regione": "Lazio"
    }, {"codice": "056", "nome": "Viterbo", "sigla": "VT", "regione": "Lazio"}],
    "Liguria": [{"codice": "010", "nome": "Genova", "sigla": "GE", "regione": "Liguria"}, {
        "codice": "008",
        "nome": "Imperia",
        "sigla": "IM",
        "regione": "Liguria"
    }, {"codice": "011", "nome": "La Spezia", "sigla": "SP", "regione": "Liguria"}, {
        "codice": "009",
        "nome": "Savona",
        "sigla": "SV",
        "regione": "Liguria"
    }],
    "Friuli Venezia Giulia": [{
        "codice": "031",
        "nome": "Gorizia",
        "sigla": "GO",
        "regione": "Friuli Venezia Giulia"
    }, {"codice": "093", "nome": "Pordenone", "sigla": "PN", "regione": "Friuli Venezia Giulia"}, {
        "codice": "032",
        "nome": "Trieste",
        "sigla": "TS",
        "regione": "Friuli Venezia Giulia"
    }, {"codice": "030", "nome": "Udine", "sigla": "UD", "regione": "Friuli Venezia Giulia"}],
    "Basilicata": [{"codice": "077", "nome": "Matera", "sigla": "MT", "regione": "Basilicata"}, {
        "codice": "076",
        "nome": "Potenza",
        "sigla": "PZ",
        "regione": "Basilicata"
    }],
    "Umbria": [{"codice": "054", "nome": "Perugia", "sigla": "PG", "regione": "Umbria"}, {
        "codice": "055",
        "nome": "Terni",
        "sigla": "TR",
        "regione": "Umbria"
    }],
    "Valle d'Aosta": [{"codice": "007", "nome": "Valle d'Aosta", "sigla": "AO", "regione": "Valle d'Aosta"}]
}

export default italyProvinces;
