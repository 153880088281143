import { HttpStatusCode } from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import http from '../utils/HttpCommon';

const download = async (context: LoggedUserContext, type?: string) => {
	try {
		const response = await http.privateClient(context).get<any>('/utility/download', { params: { type }, responseType: 'arraybuffer' });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}



const UtilityService = {
	download
}

export default UtilityService;