import { Autocomplete, Chip, createFilterOptions, TextField, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { v4 } from "uuid";
import Keyword from "../../../model/database/Keyword";

interface ProducersAutocompleteProps {
    keywords: Keyword[],
    fieldName: string,
    readOnly: boolean,
    label: string,
    suggestedFieldName: string
}

const filter = createFilterOptions<any>();

const KeywordsAutocompleteFreesolo = ({keywords, readOnly, fieldName, label, suggestedFieldName}: ProducersAutocompleteProps) => {
    const {
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        touched,
        values
    } = useFormikContext<any>();
    const theme = useTheme();

    const [customKeywords, setCustomKeywords] = React.useState<Keyword[]>();
    React.useEffect(() => {
        setCustomKeywords([...keywords]);
        return () => {
            setCustomKeywords([]);
        }
    }, []);

    return (
        <Autocomplete
            options={customKeywords ? customKeywords : []}
            fullWidth
            onBlur={handleBlur}
            disabled={isSubmitting}
            multiple
            freeSolo
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '' && filtered.length === 0) {
                    filtered.push({
                        id: 'tmp_' + v4(),
                        inputValue: params.inputValue,
                        name: `Aggiungi "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            getOptionLabel={option => typeof option === 'string' ? option : option.name!}
            value={customKeywords?.filter((value: any) => values.keywords?.includes(value.id)) ?? []}
            readOnly={readOnly ? readOnly : false}
            onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                const foundString = newValue.find((v: any) => typeof v === 'string');
                const foundIV = newValue.find((v: any) => v.inputValue);
                if (foundString) {
                    const el = {
                        name: foundString,
                        state: 'INACTIVE'
                    };
                    customKeywords?.push(el);
                    const idx = newValue.indexOf(foundString);
                    newValue[idx] = el;
                    setFieldValue(suggestedFieldName, [...values[suggestedFieldName], el]);
                } else if (foundIV) {
                    const el = {
                        id: 'tmp_' + v4(),
                        name: foundIV.inputValue,
                        state: 'INACTIVE'
                    };
                    customKeywords?.push(el);
                    const idx = newValue.indexOf(foundIV);
                    newValue[idx] = el;
                    setFieldValue(suggestedFieldName, [...values[suggestedFieldName], el]);
                }

                setFieldValue(fieldName, newValue.map((v: any) => v.id));
            }}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                    return <Chip {...getTagProps({index})}
                          label={option.name}
                          variant="filled" color={option.state === 'INACTIVE' ? "primary" : "default"}  />
                });
            }}
            renderInput={(params) => <TextField
                {...params}
                required
                variant="outlined"
                name={fieldName}
                margin="normal"
                label={label}
                error={Boolean(touched[fieldName] && errors[fieldName])}
                helperText={(touched[fieldName] && errors[fieldName]) as React.ReactNode}
            />
            }
        />
    )

};
export default KeywordsAutocompleteFreesolo;