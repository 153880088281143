import * as React from 'react';
import {Chip, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import Discussion from "../../../model/database/Discussion";
import ResponsiveGridItem from "../ResponsiveGridItem";

interface DiscussionCardProps {
  discussion?: Discussion
  showState?: boolean
}

const DiscussionCard = ({discussion, showState}: DiscussionCardProps) => {

  const theme = useTheme();
  const matchMedia = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <ResponsiveGridItem>
        <Stack direction={matchMedia ? 'column' : 'row'} spacing={1}>
          {showState && <Chip color={discussion?.state === 'ACTIVE' ? 'success' : 'error'}
                              label={discussion?.state === 'ACTIVE' ? 'Attivo' : 'Chiuso'}/>}
          <Typography variant="h6">{discussion?.name}</Typography>
        </Stack>
        <Typography variant="subtitle1">Argomento: {discussion?.product?.name}</Typography>
        <Typography variant="subtitle1">Numero di partecipanti: {discussion?.users?.length}</Typography>
        <Typography variant="body2" textAlign="justify">{discussion?.description}</Typography>
      </ResponsiveGridItem>
    </Grid>
  );
}

export default DiscussionCard;
