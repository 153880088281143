import {Chip, Stack} from "@mui/material";
import Keyword from "../../../model/database/Keyword";

interface KeywordsBadgesProps {
    keywords?: Keyword[],
    matchMedia:  boolean
}

const KeywordsBadges = ({keywords, matchMedia}: KeywordsBadgesProps) => <Stack direction={matchMedia ? 'column': 'row'} spacing={1}>
    {keywords?.sort((a,b) => (a.name! > b.name!) ? 1 : ((b.name! > a.name!) ? -1 : 0))
        .map(value => <Chip key={value.id} size="small" color={value.state === 'INACTIVE' ? "primary" : "secondary"} label={value.name} /> )}
</Stack>

export default KeywordsBadges;