import {
    CheckCircle,
    Delete, Download,
    ListAlt,
    NotificationsActive,
    QuestionAnswer,
    Unpublished,
    Visibility
} from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import MenuButton from "../../../components/common/MenuButton";
import MenuButtonItem from "../../../components/common/MenuButtonItem";
import ChallengeCard from "../../../components/common/table/ChallengeCard";
import i18n from "../../../i18n/i18n";
import LoggedUser from "../../auth/LoggedUser";
import { CommonColumnProps } from "../CommonColumnProps";

interface ChallengesTableColumnsProps {
    loggedUser?: LoggedUser,
    showCallback?: (id: string) => any,
    showSubmittedCallback?: (id: string) => any,
    deleteCallback?: (id: string) => any,
    submissionCallback?: (id: string) => any,
    approveCallback?: (id: string) => any,
    disapproveCallback?: (id: string) => any,
    reminderCallback?: (id: string) => any,
    downloadCallback?: (id: string) => any,
    showApproved?: boolean,
}

const ChallengesTableColumns = ({
    loggedUser,
    showApproved,
    showSubmittedCallback,
    showCallback,
    submissionCallback,
    deleteCallback,
    approveCallback,
    disapproveCallback,
    reminderCallback,
    downloadCallback
}: ChallengesTableColumnsProps): GridColDef[] => [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
            ...CommonColumnProps
        },
        {
            field: 'data',
            headerName: i18n.t('table.challenges') as string,
            renderCell: (params) => (
                <Box my={2} mx={1} width="100%">
                    <ChallengeCard challenge={params.row.data} state={params.row.state} user={loggedUser}
                        showState={showApproved ?? true} />
                </Box>
            ),
            ...CommonColumnProps,
            minWidth: 100,
            align: 'center',
            flex: 10,
        },
        {
            field: "action",
            headerName: "Operazioni",
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => {

                let menuButtonItems: MenuButtonItem[] = [];
                const hasSubmitted = loggedUser?.role === 'RESEARCH_PRODUCER' && params.row.data.researchProducerChallenges.find((value: any) => value?.researchProducer?.user?.id === loggedUser?.id && value?.state === 'SUBMITTED') !== undefined;

                if (loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT')) {
                    menuButtonItems = [
                        {
                            icon: <Visibility color="secondary" />,
                            text: "Visualizza",
                            handleClick: showCallback
                        },
                        {
                            icon: <ListAlt color="secondary" />,
                            disabled: params.row.data.researchProducerChallenges?.filter((val: any) => val.state === 'SUBMITTED').length === 0,
                            text: 'Visualizza proposte',
                            handleClick: showSubmittedCallback
                        },
                        {
                            icon: params.row.approved ? <Unpublished color="secondary" /> :
                                <CheckCircle color="secondary" />,
                            disabled: params.row.data.researchProducerChallenges.filter((val: any) => val.state === 'SUBMITTED').length > 0,
                            text: params.row.approved ? 'Disapprova' : 'Approva',
                            handleClick: params.row.approved ? disapproveCallback : approveCallback
                        },
                        {
                            icon: <NotificationsActive color="secondary" />,
                            text: 'Invia reminder',
                            disabled: params.row.state === 'EXPIRED' || !params.row.approved,
                            handleClick: reminderCallback
                        },
                        {
                            icon: <Delete color="error" />,
                            text: 'Elimina',
                            handleClick: deleteCallback
                        },
                    ];
                } else if (loggedUser && loggedUser.role === 'RESEARCH_PRODUCER') {
                    menuButtonItems = [
                        {
                            icon: <Visibility color="secondary" />,
                            text: "Visualizza",
                            handleClick: showCallback
                        },
                        {
                            icon: <QuestionAnswer color="secondary" />,
                            disabled: params.row.state === 'EXPIRED' || !params.row.approved,
                            text: 'Invia proposta',
                            handleClick: submissionCallback
                        },
                        {
                            icon: <Download color="secondary" />,
                            disabled: !hasSubmitted,
                            text: 'Scarica proposta',
                            handleClick: downloadCallback
                        },
                    ];
                } else if (loggedUser && loggedUser.role === 'RESEARCH_CONSUMER') {
                    menuButtonItems = [
                        {
                            icon: <Visibility color="secondary" />,
                            text: "Visualizza",
                            handleClick: showCallback
                        },
                        {
                            icon: <ListAlt color="secondary" />,
                            disabled: params.row.data.researchProducerChallenges?.filter((val: any) => val.state === 'SUBMITTED' && val.visible).length === 0,
                            text: 'Visualizza proposte',
                            handleClick: showSubmittedCallback
                        },
                        {
                            icon: <Delete color="error" />,
                            disabled: params.row.approved,
                            text: 'Elimina',
                            handleClick: deleteCallback
                        },
                    ];
                }

                return <Stack direction="row" spacing={0} alignItems="flex-end">
                    {menuButtonItems.length === 0 ? <Tooltip title="Visualizza">
                        <IconButton color="secondary" onClick={() => showCallback ? showCallback(params.id as string) : ''}>
                            <Visibility />
                        </IconButton>
                    </Tooltip> :
                        <MenuButton
                            rowId={params.id as string}
                            title="Azioni"
                            children={menuButtonItems}
                        />}
                </Stack>;
            },
            ...CommonColumnProps
        },
    ];

export default ChallengesTableColumns;
