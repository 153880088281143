import {Box, Button} from "@mui/material";
import * as React from "react";
import RegistrationStepProps from "./props/RegistrationStepProps";

const RegistrationStepButtons = ({handleNext, handleBack, activeStep, disabled}: RegistrationStepProps) => (
  <Box sx={{display: 'flex', flexDirection: 'row', py: 2 }}>
    <Button
      color="inherit"
      onClick={handleBack}
      disabled={activeStep === 0}
      sx={{mr: 1}}
    >
      Indietro
    </Button>
    <Box sx={{flex: '1 1 auto'}}/>
    <Button onClick={handleNext} disabled={disabled} variant="contained">
      {activeStep === 3 ? 'Concludi' : 'Successivo'}
    </Button>
  </Box>
)

export default RegistrationStepButtons;
