import {Button} from "@mui/material";
import React from "react";
import CustomDialog from "../../common/CustomDialog";
import Call from "../../../model/database/Call";
import InterestedUser from "../../common/InterestedUser";

interface CallInterestedModalProps {
    open: boolean,
    call?: Call,
    handleClose: () => void
}

const CallInterestedModal = ({open, call, handleClose}: CallInterestedModalProps) => {

    const resetAndClose = () => {
        handleClose();
    }

    return <CustomDialog
        open={open ? open : false}
        handleClose={resetAndClose}
        title="Utenti interessati al bando"
        content={
            <>
                {call?.userCalls?.filter((userCall: any) => userCall.state === 'INTERESTED').map((userCall: any) => <InterestedUser
                    key={userCall.user?.id}
                    userInfo={userCall} />)}
            </>
        }
        actions={
            <>
                <Button color="inherit" onClick={resetAndClose}>
                    Chiudi
                </Button>
            </>
        }
    />;
}

export default CallInterestedModal;
