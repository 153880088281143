import { Chip, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Demand from "../../../model/database/Demand";
import ResponsiveGridItem from "../ResponsiveGridItem";
import StringGeneratorUtil from "../StringGeneratorUtil";
import KeywordsBadges from "./KeywordsBadges";

interface DemandCardProps {
  useCard?: boolean,
  demand?: Demand
  showState?: boolean
}

const DemandCard = ({useCard, demand, showState}: DemandCardProps) => {

  const theme = useTheme();
  const matchMedia = useMediaQuery(theme.breakpoints.down('md'))

  const content = (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <ResponsiveGridItem>
        <Stack direction={matchMedia ? 'column': 'row'} spacing={1}>
          {showState && <Chip color={demand?.product?.approved ? 'success' : 'error'}
                              label={demand?.product?.approved ? 'Approvato' : 'Non approvato'}/>}
          <Typography variant="h6">{demand?.product?.name}</Typography>
        </Stack>
        <Typography
          variant="subtitle1">{StringGeneratorUtil.generateConsumerLabel({...demand?.product?.user?.researchConsumer!, user: demand?.product?.user})}</Typography>
        <Typography variant="body2" textAlign="justify" sx={{whiteSpace: 'pre-line'}}>{demand?.product?.description?.length! > 200 ? demand?.product?.description?.substring(0, 200) + "..." : (demand?.product?.description ?? '')}</Typography>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <KeywordsBadges keywords={demand?.product?.keywords!} matchMedia={matchMedia} />
      </ResponsiveGridItem>
    </Grid>
  );

  return (
    useCard ? <Card sx={{
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText'
      }
    }}>
      <CardContent>
        {content}
      </CardContent>
    </Card> : content
  );
}

export default DemandCard;
