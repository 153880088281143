import {IconButton, Tooltip} from "@mui/material";
import {AddCircle, CheckCircle, Unpublished} from "@mui/icons-material";
import UsersFiltersModal from "./UsersFiltersModal";
import React from "react";
import UsersFilters from "../../../model/filters/UsersFilters";
import TableToolbar from "../../common/table/TableToolbar";
import userSortFields from "../../../model/table/sort/UserSortFields";
import LoggedUser from "../../../model/auth/LoggedUser";

interface UsersTableToolbarProps {
  loggedUser?: LoggedUser,
  numSelected: number,
  handleNew: () => void,
  applyFilters: (filters: UsersFilters) => void
  applySort: (sort: string) => void
}

const UsersTableToolbar = ({numSelected, loggedUser, handleNew, applyFilters, applySort}: UsersTableToolbarProps) => {

  const [filters, setFilters] = React.useState<UsersFilters>({});

  const handleConfirm = (newStateFilters: UsersFilters) => {
    setFilters(newStateFilters);
    applyFilters(newStateFilters);
  }

  const handleClean = () => {
    setFilters({});
    applyFilters({});
  }

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <TableToolbar
        numSelected={numSelected}
        title="Utenti"
        fields={userSortFields}
        filters={Object.values(filters)}
        defaultField={userSortFields[0].value}
        defaultOrder={'desc'}
        handleOpenFiltersModal={handleOpenModal}
        handleCleanFilters={handleClean}
        handleSort={applySort}
        handleExport={() => alert('export selection')}
        handleDelete={loggedUser && loggedUser.role === 'ADMINISTRATOR' ? () => alert('delete selection') : undefined}
        extendedActions={
          <>
            {loggedUser && loggedUser.role === 'ADMINISTRATOR' && <Tooltip title="Crea nuovo utente">
              <IconButton color="secondary" onClick={handleNew}>
                <AddCircle />
              </IconButton>
            </Tooltip>}
          </>
        }
        extendedActionsOnSelection={
          <>
            <Tooltip title="Attiva">
              <IconButton color="secondary">
                <CheckCircle />
              </IconButton>
            </Tooltip>
            <Tooltip title="Disattiva">
              <IconButton color="secondary">
                <Unpublished />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <UsersFiltersModal
        open={openModal}
        filters={filters}
        handleClean={handleClean}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
      />
    </>
  );
}

export default UsersTableToolbar;
