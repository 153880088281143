import {Box, Card, CardContent} from "@mui/material";
import {GridSelectionModel, itIT} from "@mui/x-data-grid";
import {HttpStatusCode} from "axios";
import React from "react";
import Swal, {SweetAlertOptions} from 'sweetalert2';
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Challenge from "../../../model/database/Challenge";
import Discussion from "../../../model/database/Discussion";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchProduct from "../../../model/database/ResearchProduct";
import ResearchService from "../../../model/database/ResearchService";
import User from "../../../model/database/User";
import DiscussionTableDTO from "../../../model/dto/DiscussionTableDTO";
import DiscussionsFilters from "../../../model/filters/DiscussionsFilters";
import DiscussionsTableColumns from "../../../model/table/column/DiscussionsTableColumns";
import {DiscussionRow} from "../../../model/table/row/DiscussionRow";
import discussionSortFields from "../../../model/table/sort/DiscussionSortFields";
import AuthContext from "../../../route/AuthContext";
import ChallengeService from "../../../services/ChallengeService";
import DiscussionService from "../../../services/DiscussionService";
import ProductService from "../../../services/ProductService";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import ModalState from "../../common/table/ModalState";
import {StyledTable} from "../../common/table/StyledTable";
import DiscussionModal from "./DiscussionModal";
import DiscussionsTableToolbar from "./DiscussionsTableToolbar";
import CallService from "../../../services/CallService";
import Call from "../../../model/database/Call";


const DiscussionsTable = () => {

  const authContext = React.useContext(AuthContext);

  const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
  const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  });
  const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
    page: 0,
    size: 10,
    sort: discussionSortFields[0].value + ',asc'
  });
  const [filterRequest, setFilterRequest] = React.useState<DiscussionsFilters>({});
  
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<DiscussionRow[]>([]);
  const [discussionTables, setDiscussionTables] = React.useState<Discussion[]>([]);
  const [researchProducers, setResearchProducers] = React.useState<ResearchProducer[]>([]);
  const [researchConsumers, setResearchConsumers] = React.useState<ResearchConsumer[]>([]);
  const [researchProducts, setResearchProducts] = React.useState<ResearchProduct[]>([]);
  const [researchServices, setResearchServices] = React.useState<ResearchService[]>([]);
  const [challenges, setChallenges] = React.useState<Challenge[]>([]);
  const [calls, setCalls] = React.useState<Call[]>([]);

  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
    data: undefined,
    edit: false
  });
  const handleCloseModal = () => setModalState(() => ({data:undefined, open: false, edit: false}));

  const handleSave = (discussionToSave: DiscussionTableDTO) => {
    return new Promise<boolean>(async resolve => {
      let [status, response] = [undefined, undefined];
      if (!modalState.data) {
        [status, response] = await DiscussionService.save(authContext, discussionToSave);
      } else {
        [status, response] = await DiscussionService.update(authContext, discussionToSave, modalState.data.id);
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Tavolo di discussione aggiunto correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Tavolo di discussione aggiornato correttamente.';
            handleCloseModal();
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      }
    });
  }

  const handleShow = (id: string) => {
    setModalState({
      open: true,
      data: discussionTables.filter(value => value.id === id)[0],
      edit: false
    });
  }

  const handleDelete = (id: string) => {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await DiscussionService.deleteById(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Tavolo di discussione eliminato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'DELETED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile eliminare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    })
  };

  const handleCloseDiscussion = (id: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: "Confermi di voler chiudere questo tavolo? L'operazione sarà irreversibile.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await DiscussionService.changeStateById(authContext, id, 'closed');
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Tavolo di discussione chiuso correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'UPDATED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile approvare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const handleReminder = (id: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: "Vuoi inviare una email di reminder a tutti gli utenti coinvolti?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#2e7d32',
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await DiscussionService.sendReminder(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Email inviate correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'SENT':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile disapprovare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile disapprovare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const applyFilters = (filters: DiscussionsFilters) => {
    setFilterRequest(filters);
  }

  const applySort = (value: string) => {
    if (pageInfo.sort !== value) {
      setPageInfo((prevState) => ({ ...prevState, sort: value }));
    }
  }

  const loadResearchConsumers = async () => {
    const [_, response] = await UserService.getAllConsumers(authContext, { page: 0, size: -1, sort: 'id,asc' });
    return response ? response._embedded.researchConsumers.map((value: any) => ({
      ...value,
      name: value.businessName
    })) : [];
  }

  const loadResearchProducers = async () => {
    const [_, response] = await UserService.getAllProducers(authContext, { page: 0, size: -1, sort: 'id,asc' });
    return (response && response._embedded) ? response._embedded.researchProducers : [];
  }

  const loadResearchProducts = async () => {
    const [_, response] = await ProductService.getAllResearchProduct(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setResearchProducts((response && response._embedded)  ? response._embedded.researchProducts : []);
  }

  const loadResearchServices = async () => {
    const [_, response] = await ProductService.getAllResearchServices(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setResearchServices((response && response._embedded) ? response._embedded.researchServices : []);
  }

  const loadChallenges = async () => {
    const [_, response] = await ChallengeService.getAll(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setChallenges((response && response._embedded) ? response._embedded.challenges : []);
  }

  const loadCalls = async () => {
    const [_, response] = await CallService.getAll(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setCalls((response && response._embedded)  ? response._embedded.calls : []);
  }

  const mapResponseToRows = (discussions: Discussion[]) => {
    return discussions.map(value => ({
      id: value.id,
      state: value.state,
      data: value
    }));
  }

  const reloadData= async () => {
    setLoading(true);
    let [status, response] = [undefined, undefined];
    if (Object.keys(filterRequest).length === 0)
      [status, response] = await DiscussionService.getAll(authContext, pageInfo);
    else
      [status, response] = await DiscussionService.getAllFiltered(authContext, pageInfo, filterRequest);

    const producers = await loadResearchProducers();
    const consumers = await loadResearchConsumers();
    
    await loadResearchProducts();
    await loadCalls();
    await loadResearchServices();
    await loadChallenges();

    if (status && response && status === HttpStatusCode.Ok) {
      setPageInfoResponse((response as any).page);
      const discussions: Discussion[] = (response as any)._embedded ? (response as any)._embedded.docTables.map((value: any) => ({
        ...value,
        users: value.users.map((user: User) => {
          if(user.role?.id === 'RESEARCH_PRODUCER')
            return producers.find((rp: any) => rp.user?.id === user.id)
          
          if(user.role?.id === 'RESEARCH_CONSUMER')
            return consumers.find((rc: any) => rc.user?.id === user.id)

          return;
        })
      })) : []; 
      setDiscussionTables(discussions);
      setResearchProducers(producers);
      setResearchConsumers(consumers);
      const tableRows: DiscussionRow[] = mapResponseToRows(discussions);
      setRows(tableRows);
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    reloadData();
  }, [pageInfo, filterRequest]);

  return (
    <Card sx={{minWidth: 275, mt: 2, mb: 2}}>
      <CardContent>
        <DiscussionsTableToolbar
          numSelected={idSelected.length}
          loggedUser={authContext.user}
          handleNew={() => setModalState({open: true, data: undefined, edit: false})}
          applyFilters={applyFilters}
          applySort={applySort}
          producers={researchProducers}
          consumers={researchConsumers}
          products={researchProducts}
          services={researchServices}
          challenges={challenges}
          calls={calls}
        />
        <Box sx={{height: 'auto', width: '100%'}}>
          <StyledTable
            loading={loading}
            paginationMode="server"
            rows={rows}
            columns={DiscussionsTableColumns({
              loggedUser: authContext.user,
              showCallback: handleShow,
              deleteCallback: handleDelete,
              closeCallback: handleCloseDiscussion,
              reminderCallback: handleReminder
            })}
            rowsPerPageOptions={[10, 15, 20]}
            pageSize={pageInfo.size}
            onPageSizeChange={newPageSize => setPageInfo((prevState) => ({ ...prevState, size: newPageSize }))}
            onPageChange={newPage => setPageInfo((prevState) => ({ ...prevState, page: newPage }))}
            rowCount={pageInfoResponse.totalElements}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
            // checkboxSelection
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
            autoHeight
            experimentalFeatures={{newEditingApi: false}}
          />
        </Box>
      </CardContent>
      <DiscussionModal
        open={modalState.open}
        discussion={modalState.data}
        loggedUser={authContext.user}
        handleClose={handleCloseModal}
        handleSave={handleSave}
        producers={researchProducers}
        consumers={researchConsumers}
        products={researchProducts}
        services={researchServices}
        challenges={challenges}
        calls={calls}
      />
    </Card>
  )
}

export default DiscussionsTable;
