const LogoUnisalento = (props: React.ComponentPropsWithoutRef<any>) => (
  <img
    alt="Logo Unisalento"
    width="70%"
    height="auto"
    src={props.src ?? "/static/logo.png" }
    {...props}
  />
);

export default LogoUnisalento;
