import {IconButton, Tooltip} from "@mui/material";
import {AddCircle, NotificationsActive} from "@mui/icons-material";
import React from "react";
import TableToolbar from "../../common/table/TableToolbar";
import callSortFields from "../../../model/table/sort/CallSortFields";
import CallsFiltersModal from "./CallsFiltersModal";
import CallsFilters from "../../../model/filters/CallsFilters";
import LoggedUser from "../../../model/auth/LoggedUser";
import ChallengesFilters from "../../../model/filters/ChallengesFilters";
import Keyword from "../../../model/database/Keyword";

interface CallsTableToolbarProps {
  numSelected: number,
  loggedUser?: LoggedUser,
  keywords: Keyword[],
  handleNew: () => void,
  applyFilters: (filters: ChallengesFilters) => void
  applySort: (sort: string) => void
}

const CallsTableToolbar = ({handleNew, keywords, applySort, applyFilters, loggedUser, numSelected}: CallsTableToolbarProps) => {

  const [filters, setFilters] = React.useState<CallsFilters>({
  });

  const handleConfirm = (newStateFilters: CallsFilters) => {
    setFilters(newStateFilters);
    applyFilters(newStateFilters);
  }

  const handleClean = () => {
    setFilters({});
    applyFilters({});
  }

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <>
      <TableToolbar
        numSelected={numSelected}
        title="Bandi"
        fields={callSortFields}
        filters={Object.values(filters)}
        defaultField={loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT' ? callSortFields[0].value : callSortFields[1].value}
        defaultOrder={'asc'}
        handleSort={applySort}
        handleOpenFiltersModal={handleOpenModal}
        handleCleanFilters={handleClean}
        handleExport={() => alert('export selection')}
        handleDelete={loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') ? () => alert('delete selection') : undefined}
        extendedActions={
          <>
            <Tooltip title="Crea nuovo bando">
              <IconButton color="secondary" onClick={handleNew}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </>
        }
        extendedActionsOnSelection={
          <>
            {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') && <Tooltip title="Invia reminder">
              <IconButton color="secondary">
                <NotificationsActive />
              </IconButton>
            </Tooltip>}
          </>
        }
      />
      <CallsFiltersModal
        open={openModal}
        filters={filters}
        loggedUser={loggedUser}
        handleClose={handleCloseModal}
        handleClean={handleClean}
        handleConfirm={handleConfirm}
        keywords={keywords}
      />
    </>
  );
}

export default CallsTableToolbar;
