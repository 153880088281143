import {Autocomplete, Button, Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import LoggedUser from "../../../model/auth/LoggedUser";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ProductsFilters from "../../../model/filters/ProductsFilters";
import researchProductTypes from "../../../constants/researchProductTypes";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";
import Keyword from "../../../model/database/Keyword";

interface ProductsFiltersModalProps {
    open: boolean,
    loggedUser?: LoggedUser,
    filters: ProductsFilters,
    producers: ResearchProducer[],
    keywords: Keyword[],
    handleClose: () => any,
    handleClean: () => any,
    handleConfirm: (newStateFilters: ProductsFilters) => any,
}

const ProductsFiltersModal = ({
                                  open,
                                  loggedUser,
                                  producers,
                                  keywords,
                                  filters,
                                  handleClean,
                                  handleClose,
                                  handleConfirm
                              }: ProductsFiltersModalProps) => {
    const [state, setState] = React.useState<ProductsFilters>(filters);

    const handleChange = (name: string, value: string) => {
        const newFilters = {...state};
        switch (name) {
            case 'name':
                newFilters.name = {label: '', value: ''};
                newFilters.name.label = "Nome";
                newFilters.name.value = value;
                break;
            case 'producer':
                newFilters.producer = {label: '', value: ''};
                newFilters.producer.label = "Produttore";
                newFilters.producer.value = value;
                const rp = producers.find(value1 => value1.user?.id === value);
                newFilters.producer.valueDisplay = StringGeneratorUtil.generateProducerLabel(rp!);
                break;
            case 'keyword':
                newFilters.keyword = {label: '', value: ''};
                newFilters.keyword.label = "Parola chiave";
                newFilters.keyword.value = value;
        
                const k = keywords.find(value1 => value1.id === value);
                newFilters.keyword.valueDisplay = k?.name;
                break;
            case 'approved':
                newFilters.approved = {label: '', value: ''};
                newFilters.approved.label = "Stato approvazione";
                newFilters.approved.value = value;
                switch(value){
                    case 'APPROVED':
                        newFilters.approved.valueDisplay = 'Approvato';
                        break;
                    case 'NOT_APPROVED':
                        newFilters.approved.valueDisplay = 'Non approvato';
                        break;
                }
                break;
            case 'trl':
                newFilters.trl = {label: '', value: ''};
                newFilters.trl.label = "TRL";
                newFilters.trl.value = value;
                newFilters.trl.valueDisplay = `Livello ${value}`;
                break;
            case 'type':
                newFilters.type = {label: '', value: ''};
                newFilters.type.label = "Tipologia";
                newFilters.type.value = value;
                const type = researchProductTypes.find(value1 => value1.value === value);
                newFilters.type.valueDisplay = type?.label;
                break;
        }
        setState(newFilters);
    }

    React.useEffect(() => {
        setState(filters);
    }, [filters]);

    return <CustomDialog
        open={open ? open : false}
        handleClose={handleClose}
        title="Filtri"
        content={
            <Grid container spacing={2}>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        onChange={(event) => handleChange('name', event.target.value)}
                        value={state && state.name ? state.name.value : ''}
                        label="Nome prodotto"
                        margin="dense"
                        name="name"
                        variant="outlined"
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={keywords ?? []}
                        fullWidth
                        getOptionLabel={(option) => option.name!}
                        value={keywords.find(value => value.id === state.keyword?.value) ?? null}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('keyword', newValue.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            required
                            variant="outlined"
                            name="keyword"
                            margin="dense"
                            label="Parola chiave"
                        />
                        }
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Stato approvazione"
                        margin="dense"
                        name="approved"
                        onChange={(event) => handleChange('approved', event.target.value)}
                        value={state && state.approved ? state.approved.value : ''}
                        select
                        variant="outlined"
                    >
                        {[['Approvato', 'APPROVED'], ['Non approvato', 'NOT_APPROVED']].map(value => <MenuItem
                            key={value[1]} value={value[1]}>{value[0]}</MenuItem>)}
                    </TextField>
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={producers?.map(value => ({
                            label: StringGeneratorUtil.generateProducerLabel(value),
                            id: value.user?.id
                        })) ?? []}
                        fullWidth
                        value={producers?.map(value => ({
                            label: StringGeneratorUtil.generateProducerLabel(value),
                            id: value.user?.id
                        })).find(v => v.id === state.producer?.value)}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('producer', newValue.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            label="Produttore"
                            margin="dense"
                            name="producer"
                        />
                        }
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="TRL"
                        margin="dense"
                        name="trl"
                        select
                        onChange={(event) => handleChange('trl', event.target.value)}
                        value={state && state.trl ? state.trl.value : ''}
                        variant="outlined"
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => <MenuItem key={value}
                                                                              value={value}>Livello {value}</MenuItem>)}
                    </TextField>
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Tipologia"
                        margin="dense"
                        name="type"
                        onChange={(event) => handleChange('type', event.target.value)}
                        value={state && state.type ? state.type.value : ''}
                        variant="outlined"
                        select
                    >
                        {researchProductTypes.map(value => <MenuItem key={value.value}
                                                                     value={value.value}>{value.label}</MenuItem>)}
                    </TextField>
                </ResponsiveGridItem>
            </Grid>
        }
        actions={
            <>
                <Button color="inherit" onClick={() => {
                    handleClose();
                }}>
                    Chiudi
                </Button>
                <Button color="inherit" onClick={() => {
                    handleClean();
                    handleClose();
                }}>
                    Reset
                </Button>
                <Button color="primary" variant="contained" autoFocus onClick={() => {
                    handleConfirm(state);
                    handleClose();
                }}>
                    Applica
                </Button>
            </>
        }
    />
}

export default ProductsFiltersModal;
