import { LabelValue } from "../../filters/LabelValue";

const demandSortFields: LabelValue[] = [
  {
    label: 'Approvato',
    value: 'product.approved'
  },
  {
    label: 'Nome',
    value: 'product.name'
  },
  {
    label: 'Ente proponente',
    value: 'product.user.researchConsumer.businessName'
  },  
]

export default demandSortFields;
