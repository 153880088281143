import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Button, CardActions, Stack, TextField, Typography} from "@mui/material";
import * as React from "react";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import * as Yup from "yup";
import {Formik, FormikValues} from "formik";
import CustomSweetalert from "../../../theme/sweetalert";
import {HttpStatusCode} from "axios";
import ParameterReminderDTO from "../../../model/dto/ParameterReminderDTO";
import ParameterService from "../../../services/ParameterService";
import AuthContext from "../../../route/AuthContext";
import {SweetAlertOptions} from "sweetalert2";

const ReminderSetting = () => {

  const authContext = React.useContext(AuthContext);
  const [parameters, setParameters] = React.useState<ParameterReminderDTO | undefined>(undefined);

  const reloadData = async () => {
    let [status, response] = await ParameterService.getReminderParameters(authContext);
    if (status && response && status === HttpStatusCode.Ok) {
      setParameters(response);
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }
  };

  React.useEffect(() => {
    reloadData();
  }, []);


  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: parameters && parameters.email ? parameters.email : '',
          password: parameters && parameters.password ? parameters.password : '',
          days: parameters && parameters.days ? parameters.days : '',
          protocol: parameters && parameters.protocol ? parameters.protocol : '',
          host: parameters && parameters.host ? parameters.host : '',
          port: parameters && parameters.port ? parameters.port : '',
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          // email: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri').email('Il valore inserito non è un formato corretto.')
          //   .required('L\'email è obbligatoria e non può essere vuota.'),
          // password: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          //   .required('La password è obbligatoria e non può essere vuota'),
          // protocol: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          //   .required('L\'email è obbligatoria e non può essere vuota.'),
          // host: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          //   .required('L\'email è obbligatoria e non può essere vuota.'),
          // port: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
          //   .required('L\'email è obbligatoria e non può essere vuota.'),
          days: Yup.number().integer().min(0, 'Il valore minimo è 0')
            .max(365, 'Il valore massimo è 365')
            .required('Il numero di giorni è obbligatorio e non può essere vuota.')
        })}
        onSubmit={ async (values: FormikValues) => {
          let [status, response] = await ParameterService.saveReminderParameters(authContext, values as ParameterReminderDTO);

          if (status && response && status === HttpStatusCode.Ok) {
            let swOptions: SweetAlertOptions = {
              title: 'Completato',
              text: 'Impostazioni reminder salvate correttamente.',
              icon: 'success'
            };

            switch (response) {
              case 'SAVED':
                break;
              default:
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                break;
            }
            CustomSweetalert(swOptions).then(() => {
              reloadData();
            });
          }
        }}
      >
      {({
        handleSubmit,
        touched,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        values,
        submitForm
      }) => (
        <form onSubmit={handleSubmit}>
          <Card sx={{ width: '100%', mt: 2, mb: 2 }}>
            <CardContent>
              <ResponsiveGridItem container spacing={1}>
                <ResponsiveGridItem>
                  <Typography variant="h5">Impostazioni reminder</Typography>
                </ResponsiveGridItem>
                {/*<ResponsiveGridItem>*/}
                {/*  <TextField*/}
                {/*    error={Boolean(touched.email && errors.email)}*/}
                {/*    fullWidth*/}
                {/*    helperText={(touched.email && errors.email) as React.ReactNode}*/}
                {/*    label="Email"*/}
                {/*    name="email"*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    type="email"*/}
                {/*    disabled={isSubmitting}*/}
                {/*    value={values.email}*/}
                {/*    required*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</ResponsiveGridItem>*/}
                {/*<ResponsiveGridItem>*/}
                {/*  <TextField*/}
                {/*    error={Boolean(touched.password && errors.password)}*/}
                {/*    fullWidth*/}
                {/*    helperText={(touched.password && errors.password) as React.ReactNode}*/}
                {/*    label="Password"*/}
                {/*    name="password"*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    type="password"*/}
                {/*    disabled={isSubmitting}*/}
                {/*    value={values.password}*/}
                {/*    required*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</ResponsiveGridItem>*/}
                <ResponsiveGridItem>
                  <TextField
                    error={Boolean(touched.days && errors.days)}
                    fullWidth
                    helperText={(touched.days && errors.days) as React.ReactNode}
                    label="Tempo, espresso in giorni, per inviare email di reminder"
                    name="days"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    disabled={isSubmitting}
                    value={values.days}
                    required
                    variant="outlined"
                  />
                </ResponsiveGridItem>
                {/*<ResponsiveGridItem>*/}
                {/*  <TextField*/}
                {/*    error={Boolean(touched.protocol && errors.protocol)}*/}
                {/*    fullWidth*/}
                {/*    helperText={(touched.protocol && errors.protocol) as React.ReactNode}*/}
                {/*    label="Protocollo per invio mail"*/}
                {/*    name="protocol"*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    type="text"*/}
                {/*    disabled={isSubmitting}*/}
                {/*    value={values.protocol}*/}
                {/*    required*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</ResponsiveGridItem>*/}
                {/*<ResponsiveGridItem>*/}
                {/*  <TextField*/}
                {/*    error={Boolean(touched.host && errors.host)}*/}
                {/*    fullWidth*/}
                {/*    helperText={(touched.host && errors.host) as React.ReactNode}*/}
                {/*    label="Host server"*/}
                {/*    name="host"*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    type="text"*/}
                {/*    disabled={isSubmitting}*/}
                {/*    value={values.host}*/}
                {/*    required*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</ResponsiveGridItem>*/}
                {/*<ResponsiveGridItem>*/}
                {/*  <TextField*/}
                {/*    error={Boolean(touched.port && errors.port)}*/}
                {/*    fullWidth*/}
                {/*    helperText={(touched.port && errors.port) as React.ReactNode}*/}
                {/*    label="Porta server"*/}
                {/*    name="port"*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    type="text"*/}
                {/*    disabled={isSubmitting}*/}
                {/*    value={values.port}*/}
                {/*    required*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</ResponsiveGridItem>*/}
              </ResponsiveGridItem>
            </CardContent>
            <CardActions>
              <Stack alignItems="flex-end" display="flex" flex={1}>
                <Button variant="contained" onClick={() => submitForm()}>Salva</Button>
              </Stack>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
    </React.Fragment >
  );
}

export default ReminderSetting;
