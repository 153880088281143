import * as React from 'react';
import {Chip, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import ResponsiveGridItem from "../ResponsiveGridItem";
import Call from "../../../model/database/Call";
import targetTypes from "../../../constants/targetTypes";
import callTypes from "../../../constants/callTypes";
import LoggedUser from "../../../model/auth/LoggedUser";
import Roles from "../../../constants/roles";
import KeywordsBadges from "./KeywordsBadges";

interface CallCardProps {
    call?: Call,
    user?: LoggedUser,
    state?: string,
    showState?: boolean
}

const CallCard = ({call, user, state, showState}: CallCardProps) => {

    const theme = useTheme();
    const matchMedia = useMediaQuery(theme.breakpoints.down('md'))

    let labelChip = '';
    switch (state) {
        case 'ACTIVE':
            labelChip = 'Attivo';
            break;
        case 'EXPIRED':
            labelChip = 'Scaduto';
            break;
        case 'CLOSED':
            labelChip = 'Chiuso';
            break;
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}>
            <ResponsiveGridItem>
                <Stack direction={matchMedia ? 'column' : 'row'} spacing={1} sx={{ my: 0.5 }}>
                    {showState && <Chip color={call?.product?.approved ? 'success' : 'error'} label={call?.product?.approved ? 'Approvato' : 'Non approvato'}/>}
                    {call?.product?.approved && <Chip color={state === 'ACTIVE' ? 'success' : 'error'} label={labelChip}/>}
                    <Typography variant="h6">{call?.product?.name}</Typography>
                </Stack>
                <Typography variant="subtitle1" sx={{ my: 0.5 }}>Tipologia
                    bando: {callTypes.find(type => type.value === call?.type)!.label}</Typography>
                <Typography variant="subtitle1" sx={{ my: 0.5 }}>Scadenza
                    bando: {convertDateFormat(call?.product?.endDate)}</Typography>
                {(user?.role === Roles[2].id || user?.role === Roles[3].id) && <Typography variant="subtitle1">Numero di utenti interessati: {call?.userCalls?.filter((value: any) => value.state === 'INTERESTED').length}</Typography>}
                <Stack direction={matchMedia ? 'column' : 'row'} spacing={1} sx={{ my: 0.5 }}>
                    <Typography variant="subtitle1">Tipologia destinatari: </Typography>
                    {call?.targets?.map(value => {
                        const valFil = targetTypes.filter((val) => val.value === value)[0];
                        if (valFil)
                            return <Chip key={value}
                                         size="small"
                                         label={`${valFil.label}`}
                                         sx={{m: '2px'}}/>;
                        else
                            return '';
                    })}
                </Stack>
                <Typography variant="body2" textAlign="justify" sx={{whiteSpace: 'pre-line'}}>{call?.product?.description?.length! > 200 ? call?.product?.description?.substring(0, 200) : call?.product?.description}</Typography>
            </ResponsiveGridItem>
            <ResponsiveGridItem>
                <KeywordsBadges keywords={call?.product?.keywords!} matchMedia={matchMedia} />
            </ResponsiveGridItem>
        </Grid>
    );
}

function convertDateFormat(dateString: any) {
    const parts = dateString.split('-');
    if (parts.length !== 3) {
      return "Invalid date format";
    }
  
    const [year, month, day] = parts;
    return `${day}/${month}/${year}`;
  }

export default CallCard;
