import { Chip, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { UserRow } from "../../../model/table/row/UserRow";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import StyledRating from "../../common/StyledRating";

interface ProductCardProps {
  userRow?: UserRow
}

const UserCard = ({userRow}: ProductCardProps) => {

  const theme = useTheme();
  const matchMedia = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <ResponsiveGridItem>
        <Stack direction={matchMedia ? 'column': 'row'} spacing={1}>
          <Chip color={userRow?.state === 'ACTIVE' ? 'success' : 'error'}
                              label={userRow?.state === 'ACTIVE' ? 'Attivo' : 'Non attivo'}/>
          <Typography variant="h6">{userRow?.roleId === 'RESEARCH_PRODUCER' && userRow?.data.researchProducer?.type !== 'RESEARCHER' ? `${userRow?.data?.researchProducer?.name} (${userRow?.name} ${userRow?.surname})` : `${userRow?.name} ${userRow?.surname}`}</Typography>
        </Stack>
        <Typography variant="body2" textAlign="justify" >{userRow?.type ? userRow?.role + ' - ' + userRow?.type : userRow?.role}</Typography>
        {userRow?.roleId === 'RESEARCH_PRODUCER' && (userRow?.data.researchProducer?.type === 'LABORATORY' || userRow?.data.researchProducer?.type === 'RESEARCHER') && <Typography variant="subtitle1">{userRow?.data.researchProducer.researchInstitution?.name} - {userRow?.data.researchProducer.department}</Typography>}
        {userRow?.roleId === 'RESEARCH_PRODUCER' && userRow?.data.researchProducer?.type === 'RESEARCH_CENTER' && <Typography variant="subtitle1">{userRow?.data.researchProducer.department !== null && userRow?.data.researchProducer.department ? userRow?.data.researchProducer.department : '-'}</Typography>}
        <Typography variant="subtitle1">{userRow?.email}</Typography>
        {(userRow?.roleId === 'RESEARCH_PRODUCER' || userRow?.roleId === 'RESEARCH_CONSUMER') && <Stack direction={matchMedia ? 'column' : 'row'}>
          <Typography component="legend">Punteggio di affidabilità: </Typography>
          <StyledRating value={userRow?.data.score} name="read-only" readOnly/>
        </Stack>}
      </ResponsiveGridItem>
    </Grid>
  );

}

export default UserCard;
