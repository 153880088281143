import { Badge, Chip, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import React from "react";
import { Link, matchPath, useLocation } from 'react-router-dom';

interface NavItemProps {
    href: string
    icon: React.ReactNode
    title: string,
    id?: string,
    isBeta?: boolean,
    badgeContent?: number

    [x: string]: any
}

const NavItem = ({
    id,
    href,
    icon,
    title,
    badgeContent,
    isBeta,
    ...rest
}: NavItemProps) => {

    const location = useLocation();
    const badgeRef = React.useRef<HTMLLIElement>(null);
    const [number, setNumber] = React.useState(0);
    const [position, setPosition] = React.useState(0);

    const theme = useTheme();

    React.useEffect(() => {
        setPosition(badgeRef.current ? (badgeRef.current.getBoundingClientRect().height / 2) : 0);
    }, [badgeRef]);

    React.useEffect(() => {
        setNumber(badgeContent ?? 0);
    }, [badgeContent]);

    const active = href ? !!matchPath({
        path: href,
        end: false
    }, location.pathname) : false;

    const item = <ListItem
        ref={badgeRef}
        disableGutters
        sx={{
            display: 'flex',
            py: 0,
            ...(active && {
                backgroundColor: 'primary.main',
                borderRadius: 2
            }),
        }}
        {...rest}
    >
        <Link to={href} style={{ width: '100%', color: theme.palette.secondary.main }}>
            <ListItemButton
                sx={{
                    color: 'secondary.contrastText',
                    fontWeight: 'medium',
                    justifyContent: 'flex-start',
                    letterSpacing: 0,
                    py: 0.5,
                    textTransform: 'none',
                    width: '100%',
                    ...(active && {
                        color: 'primary.contrastText'
                    }),
                    '& svg': {
                        mr: 1
                    }
                }}>
                {isBeta && <Chip label="BETA" size="small" sx={{ mr: 1 }} color={active ? 'secondary' : 'primary'}></Chip>}
                <ListItemIcon sx={{
                    minWidth: 0,
                    ...(active ? {
                        color: 'primary.contrastText'
                    } : {
                        color: 'secondary.contrastText',
                    })
                }}>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    sx={{ ...(active ? { fontWeight: 'strong' } : { color: 'secondary.contrastText' }) }}>
                    {title}
                </ListItemText>
            </ListItemButton>
        </Link>
    </ListItem>

    return (
        id ? <Badge
            sx={{
                display: 'flex',
                "& .MuiBadge-badge": {
                    top: `${position}px`,
                    right: theme.spacing(2)
                }
            }}
            badgeContent={number}
            color={active ? 'secondary' : 'primary'}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {item}
        </Badge> : item
    );
};

export default NavItem;
