import {Box, Card, CardContent} from "@mui/material";
import {GridSelectionModel, itIT} from "@mui/x-data-grid";
import {HttpStatusCode} from "axios";
import React from "react";
import Swal, {SweetAlertOptions} from 'sweetalert2';
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Call from "../../../model/database/Call";
import CallDTO from "../../../model/dto/CallDTO";
import CallsFilters from "../../../model/filters/CallsFilters";
import CallsTableColumns from "../../../model/table/column/CallsTableColumns";
import {CallRow} from "../../../model/table/row/CallRow";
import callSortFields from "../../../model/table/sort/CallSortFields";
import AuthContext from "../../../route/AuthContext";
import CallService from "../../../services/CallService";
import CustomSweetalert from "../../../theme/sweetalert";
import ModalState from "../../common/table/ModalState";
import {StyledTable} from "../../common/table/StyledTable";
import CallModal from "./CallModal";
import CallsTableToolbar from "./CallsTableToolbar";
import KeywordService from "../../../services/KeywordService";
import Keyword from "../../../model/database/Keyword";
import ProductService from "../../../services/ProductService";
import Roles from "../../../constants/roles";
import CallInterestedModal from "./CallInterestedModal";


const CallsTable = () => {

    const authContext = React.useContext(AuthContext);

    const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
    const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
    });
    const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
        page: 0,
        size: 10,
        sort: (authContext.user?.role === 'ADMINISTRATOR' || authContext.user?.role === 'COMMITTEE_MANAGEMENT' ? callSortFields[0].value : callSortFields[1].value) + ',asc'
    });
    const [filterRequest, setFilterRequest] = React.useState<CallsFilters>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = React.useState<CallRow[]>([]);
    const [keywords, setKeywords] = React.useState<Keyword[]>([]);
    const [calls, setCalls] = React.useState<Call[]>([]);


    const [modalState, setModalState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModal = () => setModalState(() => ({data: undefined, open: false, edit: false}));

    const [modalInterestedState, setModalInterestState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModalInterested = () => setModalInterestState(() => ({data: undefined, open: false, edit: false}));

    const handleShow = (id: string) => {
        setModalState({
            open: true,
            data: calls.filter(value => value.product?.id === id)[0],
            edit: false
        });
    };

    const handleShowInterested = (id: string) => {
        setModalInterestState({
            open: true,
            data: calls.filter(value => value.product?.id === id)[0],
            edit: false
        });
    };

    const handleSave = (callDTO: CallDTO) => {
        return new Promise<boolean>(async resolve => {
            let [status, response] = [undefined, undefined];
            if (!modalState.data) {
                [status, response] = await CallService.save(authContext, callDTO);
            } else {
                [status, response] = await CallService.update(authContext, callDTO, modalState.data.id);
            }

            let swOptions: SweetAlertOptions = {
                title: 'Completato',
                text: 'Bando aggiunto correttamente.',
                icon: 'success'
            };

            if (status && response && status === HttpStatusCode.Ok) {

                switch (response) {
                    case 'SAVED':
                        resolve(true);
                        handleCloseModal();
                        break;
                    case 'UPDATED':
                        resolve(true);
                        swOptions.text = 'Bando aggiornato correttamente.';
                        handleCloseModal();
                        break;
                    default:
                        resolve(false);
                        swOptions.title = 'Errore';
                        swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                        swOptions.icon = 'error';
                        break;
                }
                CustomSweetalert(swOptions).then(() => {
                    reloadData();
                });
            } else {
                resolve(false);
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                await CustomSweetalert(swOptions);
            }
        });
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Elimina',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await CallService.deleteById(authContext, id);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Bando eliminato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'DELETED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    await CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile eliminare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        })
    };

    const handleCloseCall = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler chiudere questo bando? L'operazione sarà irreversibile.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await CallService.changeStateById(authContext, id, 'CLOSED');
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Bando chiuso correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    await CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile approvare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleInterest = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di essere interessato al bando?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await CallService.changeInterestById(authContext, id, true);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Interesse salvato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'SAVED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile salvare l\'operazione.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile salvare l\'operazione.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleRemoveInterest = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler rimuovere l\'interesse per il bando?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Rimuovi',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await CallService.changeInterestById(authContext, id, false);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Interesse per il bando rimosso correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'SAVED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile salvare l\'operazione.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile salvare l\'operazione.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleApprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler approvare il bando?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Attiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.changeApprovedById(authContext, id, true);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Bando approvato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        case 'NOT_UPDATED':
                            swOptions.title = 'Errore';
                            swOptions.text = 'L\'elemento selezionato ha parole chiave associate non approvate.';
                            swOptions.icon = 'error';
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile approvare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleDisapprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler disapprovare il bando?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Disattiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.changeApprovedById(authContext, id, false);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Bando disapprovato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile disapprovare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile disapprovare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const applyFilters = (filters: CallsFilters) => {
        setFilterRequest(filters);
    }

    const applySort = (value: string) => {
        if (pageInfo.sort !== value) {
            setPageInfo((prevState) => ({...prevState, sort: value}));
        }
    }

    const loadKeywords = async () => {
        const [status, response] = await KeywordService.getAllActive(authContext, {page: 0, size: -1, sort: 'id,asc'});
        setKeywords(response ? response._embedded.keywords : []);
    }

    const mapResponseToRows = (calls: Call[]) => {
        return calls.map(value => ({
            id: value.product?.id,
            approved: value.product?.approved,
            state: value.product?.state,
            userInterest: !!value.userCalls?.find((userCall: any) => userCall.state === 'INTERESTED' && userCall.user.id === authContext.user?.id),
            data: value
        }));
    }

    const reloadData = async () => {
        setLoading(true);
        let [status, response] = [undefined, undefined];
        if (Object.keys(filterRequest).length === 0)
            [status, response] = await CallService.getAll(authContext, pageInfo);
        else
            [status, response] = await CallService.getAllFiltered(authContext, pageInfo, filterRequest);

        await loadKeywords();

        if (status && response && status === HttpStatusCode.Ok) {
            setPageInfoResponse((response as any).page);
            const callsTmp: Call[] = (response as any)._embedded ? (response as any)._embedded.calls.map((value: any) => ({
                ...value,
                targets: JSON.parse(value.targets)
            })) : [];
            setCalls(callsTmp);
            const tableRows: CallRow[] = mapResponseToRows(callsTmp);
            setRows(tableRows);
        } else {
            await CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }

        setLoading(false);
    };

    React.useEffect(() => {
        reloadData();
    }, [pageInfo, filterRequest]);

    return (
        <Card sx={{minWidth: 275, mt: 2, mb: 2}}>
            <CardContent>
                <CallsTableToolbar
                    numSelected={idSelected.length}
                    loggedUser={authContext.user}
                    handleNew={() => setModalState({open: true, data: undefined, edit: false})}
                    applyFilters={applyFilters}
                    applySort={applySort}
                    keywords={keywords}
                />
                <Box sx={{height: 'auto', width: '100%'}}>
                    <StyledTable
                        loading={loading}
                        paginationMode="server"
                        rows={rows}
                        columns={CallsTableColumns({
                            loggedUser: authContext.user,
                            closeCallback: handleCloseCall,
                            showCallback: handleShow,
                            deleteCallback: handleDelete,
                            approveCallback: handleApprove,
                            disapproveCallback: handleDisapprove,
                            interestCallback: handleInterest,
                            removeInterestCallback: handleRemoveInterest,
                            showInterestedCallback: handleShowInterested
                        })}
                        rowsPerPageOptions={[10, 15, 20]}
                        pageSize={pageInfo.size}
                        onPageSizeChange={newPageSize => setPageInfo((prevState) => ({
                            ...prevState,
                            size: newPageSize
                        }))}
                        onPageChange={newPage => setPageInfo((prevState) => ({...prevState, page: newPage}))}
                        rowCount={pageInfoResponse.totalElements}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
                        // checkboxSelection
                        getRowHeight={() => 'auto'}
                        disableSelectionOnClick
                        autoHeight
                        experimentalFeatures={{newEditingApi: false}}
                    />
                </Box>
            </CardContent>
            <CallModal
                open={modalState.open}
                call={modalState.data}
                keywords={keywords}
                loggedUser={authContext.user}
                handleClose={handleCloseModal}
                handleSave={handleSave}
            />
            {(authContext.user?.role === Roles[2].id || authContext.user?.role === Roles[3].id) &&
                <CallInterestedModal
                    open={modalInterestedState.open}
                    call={modalInterestedState.data}
                    handleClose={handleCloseModalInterested}
                />}
        </Card>
    )
}

export default CallsTable;
