import {Box, Card, CardContent} from "@mui/material";
import {enUS, GridSelectionModel, itIT} from "@mui/x-data-grid";
import React from "react";
import {useTranslation} from "react-i18next";
import Swal, {SweetAlertOptions} from 'sweetalert2';
import ChallengesTableColumns from "../../../model/table/column/ChallengesTableColumns";
import {ChallengeRow} from "../../../model/table/row/ChallengeRow";
import AuthContext from "../../../route/AuthContext";
import ModalState from "../../common/table/ModalState";
import {StyledTable} from "../../common/table/StyledTable";
import ChallengeModal from "./ChallengeModal";
import ChallengesTableToolbar from "./ChallengesTableToolbar";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import ChallengesFilters from "../../../model/filters/ChallengesFilters";
import Challenge from "../../../model/database/Challenge";
import ChallengeService from "../../../services/ChallengeService";
import {HttpStatusCode} from "axios";
import CustomSweetalert from "../../../theme/sweetalert";
import ResearchProducer from "../../../model/database/ResearchProducer";
import Keyword from "../../../model/database/Keyword";
import UserService from "../../../services/UserService";
import KeywordService from "../../../services/KeywordService";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import ChallengeDTO from "../../../model/dto/ChallengeDTO";
import {Buffer} from "buffer";
import FileDTO from "../../../model/dto/FileDTO";
import ProductService from "../../../services/ProductService";
import ChallengeSubmissionModal from "./ChallengeSubmissionModal";
import Roles from "../../../constants/roles";
import challengeSortFields from "../../../model/table/sort/ChallengeSortFields";


const ChallengesTable = () => {

    const {i18n} = useTranslation();

    const authContext = React.useContext(AuthContext);

    const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
    const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
    });
    const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
        page: 0,
        size: 10,
        sort: challengeSortFields[0].value + ',asc'
    });
    const [filterRequest, setFilterRequest] = React.useState<ChallengesFilters>({});

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = React.useState<ChallengeRow[]>([]);
    const [challenges, setChallenges] = React.useState<Challenge[]>([]);
    const [researchProducers, setResearchProducers] = React.useState<ResearchProducer[]>([]);
    const [suggestedProducers, setSuggestedProducers] = React.useState<string[]>([]);
    const [researchConsumers, setResearchConsumers] = React.useState<ResearchConsumer[]>([]);
    const [keywords, setKeywords] = React.useState<Keyword[]>([]);

    const [modalState, setModalState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModal = () => setModalState(() => ({data: undefined, open: false, edit: false}));

    const [modalSubmissionState, setModalSubmissionState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModalSubmission = () => setModalSubmissionState(() => ({
        data: undefined,
        open: false,
        edit: false
    }));

    const loadSuggested = async (id: string) => {
        const [status, response] = await ChallengeService.getSuggestedProducers(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
            setSuggestedProducers(response);
        }
    }

    const handleShow = async (id: string) => {
        const item = challenges.find(value => value.product.id === id);
        if (!item?.researchProducerChallenges || item?.researchProducerChallenges?.length === 0)
            await loadSuggested(id);
        setModalState({
            open: true,
            data: item,
            edit: false
        });
    };

    const handleShowSubmitted = (id: string) => {
        setModalSubmissionState({
            open: true,
            data: challenges.find(value => value.product.id === id),
            edit: false
        });
    };

    const handleSave = (challengeToSave: ChallengeDTO) => {
        return new Promise<boolean>(async resolve => {
            let [status, response] = [undefined, undefined];
            if (!modalState.data) {
                [status, response] = await ChallengeService.save(authContext, challengeToSave);
            } else {
                [status, response] = await ChallengeService.update(authContext, challengeToSave, modalState.data.id);
            }
            let swOptions: SweetAlertOptions = {
                title: 'Completato',
                text: 'Sfida creata correttamente.',
                icon: 'success'
            };

            if (status && response && status === HttpStatusCode.Ok) {

                switch (response) {
                    case 'SAVED':
                        resolve(true);
                        handleCloseModal();
                        break;
                    case 'UPDATED':
                        resolve(true);
                        swOptions.text = 'Sfida aggiornata correttamente.';
                        handleCloseModal();
                        break;
                    case 'DATE_ERROR':
                        resolve(false);
                        swOptions.title = 'Attenzione';
                        swOptions.text = 'Data di inizio deve essere minore/uguale alla data di scadenza.';
                        swOptions.icon = 'warning';
                        break;
                    default:
                        resolve(false);
                        swOptions.title = 'Errore';
                        swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                        swOptions.icon = 'error';
                        break;
                }
                CustomSweetalert(swOptions).then(() => {
                    reloadData();
                });
            } else {
                resolve(false);
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                await CustomSweetalert(swOptions);
            }
        });
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Elimina',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ChallengeService.deleteById(authContext, id);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Sfida eliminata correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'DELETED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile eliminare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        })
    };

    const handleDownloadFormat = async (id: string) => {
        const [status, response] = await ChallengeService.getFormat(authContext, id);
        download(status, response, 'Modello per la proposta scaricato con successo.');
    }

    const handleDownloadDocument = async (id: string) => {
        const [status, response] = await ChallengeService.getSubmission(authContext, id, authContext.user?.id!);
        download(status, response, 'Proposta sottomessa scaricata con successo.');
    }

    const download = (status: any, response: any, message: string) => {
        if (status && response && status === HttpStatusCode.Ok) {
            const b64 = response?.file ?? '';
            var b64Buffer = Buffer.from(b64.split(',')[1], 'base64');
            const b64Blob = new Blob([b64Buffer], {type: response?.type});
            const url = window.URL.createObjectURL(b64Blob);
            // const w = window.open('', "_blank");
            // w?.document.write(`<iframe src=${url} width='100%' height='100%'/>`);
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.href = url;
            a.download = response?.name ?? '';
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            handleCloseModal();
            CustomSweetalert({
                title: 'Completato',
                text: message,
                icon: 'success'
            });
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Non è stato possibile scaricare il modello.',
                icon: 'error'
            })
        }
    }

    const handleApprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler approvare la sfida?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Attiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.changeApprovedById(authContext, id, true);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Sfida approvata correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        case 'NOT_UPDATED':
                            swOptions.title = 'Errore';
                            swOptions.text = 'L\'elemento selezionato ha parole chiave associate non approvate.';
                            swOptions.icon = 'error';
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile approvare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleDisapprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler disapprovare la sfida?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Disattiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.changeApprovedById(authContext, id, false);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Sfida disapprovata correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile disapprovare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile disapprovare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleReminder = (id: string) => {
        CustomSweetalert({
            title: 'Attenzione',
            text: "Vuoi inviare una email di reminder a tutti gli utenti coinvolti?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ChallengeService.sendReminder(authContext, id);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Email inviate correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'SENT':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile disapprovare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile disapprovare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleSubmission = (id: string) => {
        let clickedCount = 0;
        CustomSweetalert({
            title: 'Invia proposta sfida',
            text: 'Seleziona la proposta da sottomettere (Max 5MB)',
            input: 'file',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            cancelButtonText: 'Annulla',
            reverseButtons: true,
            inputAttributes: {
                'accept': '.pdf, .doc, .docx',
                'aria-label': 'Carica la tua proposta'
            },
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage("E' necessario caricare un file per proseguire.")
                } else if (clickedCount === 0) {
                    Swal.showValidationMessage("Premi nuovamente il pulsante per confermare il caricamento della proposta.");
                    clickedCount++;
                }
            },
        }).then(({value: file}) => {
            if (file) { // 5MB
                if (file.size > 5242880) {
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Il file selezionato supera la dimensione massima consentita di 5 MB.',
                        icon: 'warning'
                    });
                    return;
                }

                Swal.showLoading();
                const reader = new FileReader()
                let fileDTO: FileDTO = {
                    name: file.name,
                    type: file.type,
                    file: ''
                }
                reader.onload = async (e) => {
                    fileDTO.file = reader.result as string;

                    const [status, response] = await ChallengeService.submit(authContext, fileDTO, id, authContext.user?.id ?? '');
                    if (status && response && status === HttpStatusCode.Ok) {
                        let swOptions: SweetAlertOptions = {
                            title: 'Completato',
                            text: 'Proposta sottomessa correttamente.',
                            icon: 'success'
                        };

                        switch (response) {
                            case 'UPDATED':
                                break;
                            default:
                                swOptions.title = 'Errore';
                                swOptions.text = 'Non è stato possibile sottomettere la proposta.';
                                swOptions.icon = 'error';
                                break;
                        }
                        CustomSweetalert(swOptions);
                        reloadData();
                    } else
                        CustomSweetalert({
                            title: 'Attenzione',
                            text: 'Non è stato possibile sottomettere la proposta.',
                            icon: 'error'
                        })
                }
                reader.readAsDataURL(file);
            }
        });
    };

    const applyFilters = (filters: ChallengesFilters) => {
        setFilterRequest(filters);
    }

    const applySort = (value: string) => {
        if (pageInfo.sort !== value) {
            setPageInfo((prevState) => ({...prevState, sort: value}));
        }
    }

    const mapResponseToRows = (challenges: Challenge[]) => {
        return challenges.map(value => ({
            id: value.product?.id,
            approved: value.product?.approved,
            state: value.product?.state,
            data: value
        }));
    }

    const loadResearchProducers = async () => {
        const [status, response] = await UserService.getAllProducers(authContext, {page: 0, size: -1, sort: 'id,asc'});
        setResearchProducers(response ? response._embedded.researchProducers : []);
    }

    const loadResearchConsumers = async () => {
        const [status, response] = await UserService.getAllConsumers(authContext, {page: 0, size: -1, sort: 'id,asc'});
        setResearchConsumers(response ? response._embedded.researchConsumers.map((value: any) => ({
            ...value,
            name: value.businessName
        })) : []);
    }

    const loadKeywords = async () => {
        const [status, response] = await KeywordService.getAllActive(authContext, {page: 0, size: -1, sort: 'id,asc'});
        setKeywords(response ? response._embedded.keywords : []);
    }

    const reloadData = async () => {
        setLoading(true);
        let [status, response] = [undefined, undefined];
        if (Object.keys(filterRequest).length === 0)
            [status, response] = await ChallengeService.getAll(authContext, pageInfo);
        else
            [status, response] = await ChallengeService.getAllFiltered(authContext, pageInfo, filterRequest);

        await loadResearchProducers();
        await loadResearchConsumers();
        await loadKeywords();

        if (status && response && status === HttpStatusCode.Ok) {
            setPageInfoResponse((response as any).page);
            const newChallenges: Challenge[] = (response as any)._embedded ? (response as any)._embedded.challenges.map((value: any) => ({
                ...value,
                producers: value.researchProducerChallenges.map((value1: any) => value1.researchProducer)
            })) : [];
            const tableRows: ChallengeRow[] = mapResponseToRows(newChallenges);
            setChallenges(newChallenges);
            setRows(tableRows);
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }

        setLoading(false);
    };

    React.useEffect(() => {
        reloadData();
    }, [pageInfo, filterRequest]);

    return (
        <Card sx={{minWidth: 275, mt: 2, mb: 2}}>
            <CardContent>
                <ChallengesTableToolbar
                    numSelected={idSelected.length}
                    loggedUser={authContext.user}
                    producers={researchProducers}
                    consumers={researchConsumers}
                    keywords={keywords}
                    handleNew={() => setModalState({open: true, data: undefined, edit: false})}
                    applyFilters={applyFilters}
                    applySort={applySort}
                />
                <Box sx={{height: 'auto', width: '100%'}}>
                    <StyledTable
                        loading={loading}
                        paginationMode="server"
                        rows={rows}
                        columns={ChallengesTableColumns({
                            loggedUser: authContext.user,
                            showCallback: handleShow,
                            showSubmittedCallback: handleShowSubmitted,
                            submissionCallback: handleSubmission,
                            deleteCallback: handleDelete,
                            approveCallback: handleApprove,
                            disapproveCallback: handleDisapprove,
                            reminderCallback: handleReminder,
                            downloadCallback: handleDownloadDocument,
                            showApproved: authContext.user?.role !== 'RESEARCH_PRODUCER'
                        })}
                        rowsPerPageOptions={[10, 15, 20]}
                        pageSize={pageInfo.size}
                        onPageSizeChange={newPageSize => setPageInfo((prevState) => ({
                            ...prevState,
                            size: newPageSize
                        }))}
                        onPageChange={newPage => setPageInfo((prevState) => ({...prevState, page: newPage}))}
                        rowCount={pageInfoResponse.totalElements}
                        localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : itIT.components.MuiDataGrid.defaultProps.localeText}
                        // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
                        // checkboxSelection
                        getRowHeight={() => 'auto'}
                        disableSelectionOnClick
                        autoHeight
                        experimentalFeatures={{newEditingApi: false}}
                    />
                </Box>
            </CardContent>
            <ChallengeModal
                open={modalState.open}
                challenge={modalState.data}
                loggedUser={authContext.user}
                suggestedProducers={suggestedProducers}
                producers={researchProducers}
                consumers={researchConsumers}
                keywords={keywords}
                handleDownload={handleDownloadFormat}
                handleClose={handleCloseModal}
                handleSave={handleSave}
                handleSendReminder={handleReminder}
            />
            {authContext.user?.role !== Roles[0].id &&
                <ChallengeSubmissionModal
                    open={modalSubmissionState.open}
                    challenge={modalSubmissionState.data}
                    handleClose={handleCloseModalSubmission}
                />}
        </Card>
    )
}

export default ChallengesTable;
