import { Link, Stack, Typography } from "@mui/material";

interface PrivacyPolicyTextProps {
    showTitle?: boolean;
}

const PrivacyPolicyText = ({ showTitle }: PrivacyPolicyTextProps) => (
    <Stack direction="column" p={4}>
        {showTitle && <Typography variant="h5" gutterBottom textAlign="justify" >
            <strong>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI DEGLI UTENTI DELLA PIATTAFORMA INNOVATION RESEARCH</strong>
        </Typography>}
        <Typography variant="body1" textAlign="justify" gutterBottom>
            Gentile interessato,
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            l’Università del Salento ti invita a prendere visione della seguente informativa redatta ai sensi dell’articolo 13 del Regolamento UE 2016/679 “relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati”, noto anche con l’acronimo GDPR (General Data Protection Regulation).
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            <strong>Definizioni</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            <strong>Titolare del trattamento:</strong> persona giuridica o fisica che determina le finalità, i modi e i mezzi del trattamento dei dati personali di una specifica Organizzazione a cui afferiscono gli interessati.
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            <strong>Responsabile del trattamento: </strong> persona giuridica o fisica che tratta i dati per conto del Titolare nei limiti di quanto con esso concordato. Il Responsabile del trattamento esegue le istruzioni del Titolare e ne accetta i controlli, in particolare sull’effettiva adozione di adeguate misure di protezione dei dati personali.
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            <strong>Interessato:</strong> persona fisica i cui dati personali sono oggetto di trattamento da parte del Titolare e di eventuali terzi.
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>1. Titolare del trattamento</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            Titolare del trattamento dei dati è l’Università del Salento con sede legale in Piazza Tancredi 7, 73100 Lecce, rappresentata dal Magnifico Rettore.  I dati di contatto del titolare sono: Tel. <Link color="inherit" href="phone:0832292333">0832/292333</Link>, e-mail: <Link color="inherit" href="mailto:rettore@unisalento.it">rettore@unisalento.it</Link> , PEC (posta elettronica certificata): <Link color="inherit" href="mailto:amministrazione.centrale@cert-unile.it">amministrazione.centrale@cert-unile.it</Link>
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>2. Responsabile della protezione dei dati</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            Il Responsabile della protezione dati (“DPO”) dell’Università del Salento, al quale gli interessati possono rivolgersi per questioni relative al trattamento dei loro dati personali e all’esercizio dei loro diritti, è contattabile ai seguenti indirizzi: <Link color="inherit" href="mailto:dpo@unisalento.it">dpo@unisalento.it</Link>, PEC: <Link color="inherit" href="mailto:amministrazione.centrale@cert-unile.it">amministrazione.centrale@cert-unile.it</Link>
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>3. Tipologia dei dati trattati, base giuridica e finalita' del trattamento</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            I dati personali trattati sono tutti quelli necessari al corretto utilizzo dei servizi offerti dalla piattaforma INNOVATION RESEARCH, gestito dal Centro Interdipartimentale di Ateneo I-STORE, ossia i dati inerenti ai prodotti della ricerca dei quali il singolo utente è autore o coautore, ai produttori della ricerca ovvero gruppi/laboratori di ricerca o singolo ricercatore e agli utenti (ad es. aziende o PA) che svolgono il ruolo di fruitori della ricerca. Si precisa che la piattaforma Innovation Research non utilizza i cookies e non esegue nessuna profilazione degli utenti durante la navigazione.
            Il Titolare del trattamento raccoglie e tratta i seguenti dati personali:
            <ul>
                <li>dati identificativi dell’interessato (nome, cognome);</li>
                <li>dati di contatto (indirizzo, e-mail, telefono);</li>
                <li>dati relativi all’attività di impresa (ragione sociale, partita iva)</li>
            </ul>
            Il conferimento dei dati è necessario per fruire dei servizi erogati dalla piattaforma. Tali dati vengono trattati esclusivamente per finalità connesse allo svolgimento di attività istituzionali dell’Ateneo, in particolare le attività di terza missione finalizzate alla divulgazione dei prodotti della ricerca e alla possibilità di stimolare il networking tra mondo della ricerca e i diversi attori che si configurano come fruitori della stessa ricerca (ad es. aziende, PA, altri centri di ricerca).
            Il trattamento è finalizzato nello specifico a mantenere esclusivamente i dati relativi ai prodotti della ricerca e ai produttori della ricerca volontariamente caricati dai diretti interessati nella stessa piattaforma.  Sono fatti salvi gli obblighi e l’esercizio dei diritti del Titolare previsti dalla legge. I dati, ospitati nella piattaforma saranno trattati anche in relazione alle necessarie attività di conservazione, backup e ripristino. I dati saranno altresì trattati, in forma anonima e aggregata, a fini statistici.
            Le basi giuridiche del trattamento sono quindi l’ottemperanza a obblighi di legge e contrattuali e il trattamento dei dati connesso alla gestione di eventuali reclami o contenziosi e per la prevenzione e repressione di frodi e di qualsiasi attività illecita.
            Il conferimento dei dati è requisito necessario per usufruire dei suddetti servizi erogati dalla piattaforma; il mancato conferimento comporta l’impossibilità di usufruire di tali servizi.
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>4. Soggetti ai quali possono essere comunicati i dati e trasferimento dei dati</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            I dati potranno essere trattati dagli operatori che operano a vario titolo (ad es. manutenzione, mantenimento, inserimento dati, amministratore, ecc..) sulla piattaforma gestita dal centro I-STORE ciascuno secondo i rispettivi ruoli e permessi, nonché da soggetti terzi nei casi di seguito specificati:
            <ul>
                <li>in quanto comunicati o condivisi con soggetti terzi cui è stata affidata per conto del Titolare o del delegato del Titolare, nella loro qualità di Responsabili del trattamento, la fornitura di servizi strumentali all’erogazione dei suddetti servizi (es. gestore del sistema di indicizzazione dei prodotti; gestore banche dati, gestore addetto al mantenimento in Cloud e alla manutenzione della stessa piattaforma);</li>
                <li>quando la comunicazione o condivisione dei dati con uno o più soggetti terzi è indispensabile per l’esecuzione di un servizio erogato su richiesta dell’utente (ad es. condivisione dei prodotti e dei produttori della ricerca censita nella piattaforma con tutti gli utenti regolarmente autorizzati ad accedere alla stessa piattaforma per le finalità sopra riportate).</li>
            </ul>
            È fatta salva, in ogni caso, la comunicazione o diffusione di dati richiesti, in conformità alla legge, dall’Autorità di Pubblica Sicurezza, dall’Autorità Giudiziaria o da altri soggetti pubblici per finalità di difesa, sicurezza dello Stato ed accertamento dei reati, nonché la comunicazione all’Autorità Giudiziaria in ottemperanza ad obblighi di legge, laddove si ravvisino ipotesi di reato.
            Al di fuori dei predetti casi, i dati personali non vengono in nessun modo e per alcun motivo comunicati o diffusi a terzi.
            I dati personali degli interessati, infine, non sono oggetto di trasferimento verso Paesi terzi.
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>5. Modalita' di trattamento e periodo di conservazione dei dati</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            La raccolta dei dati avviene nel rispetto dei principi di pertinenza, completezza e non eccedenza in relazione ai fini per i quali sono trattati.
            I dati personali conferiti sono trattati in osservanza dei principi di liceità, correttezza e trasparenza, previsti dall’articolo 5 del GDPR, anche con l’ausilio di strumenti informatici e telematici atti a memorizzare e gestire i dati stessi e, comunque, in modo tale da garantirne la sicurezza e tutelare la massima riservatezza dell’interessato.
            I dati possono essere oggetto di trattamento in forma anonima per lo svolgimento di attività statistiche finalizzate al miglioramento dei servizi offerti.
            I dati personali raccolti saranno conservati dal Titolare per la durata dell’attivazione dell’account relativo ai servizi della piattaforma (es. connessa alla durata del rapporto istituzionale) e fino alla decorrenza di un anno dalla disattivazione dell’account, salvo eventuali pendenze.
            Allo scadere del termine sopra indicato, i dati personali saranno conservati esclusivamente a fini di archiviazione nel pubblico interesse o a fini statistici, previa applicazione delle garanzie previste dall'art. 89 del GDPR che assicurano l’anonimato.
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>6. Diritti dell'interessato</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            L’interessato ha il diritto di chiedere al Titolare del trattamento, conformemente agli artt. 15, 17, 18, 19 e 21 del GDPR,
            <ul>
                <li>
                    <strong>Diritto di accesso: </strong> diritto ad avere conferma dell'esistenza o meno del trattamento dei suoi dati personali e ad ottenere l'accesso a tali dati e alle informazioni relative al trattamento, alle sue finalità, al periodo di conservazione previsto o qualora non sia possibile determinarlo, ai criteri utilizzati per quantificarlo, ai destinatari o categorie di destinatari a cui i dati personali saranno comunicati. L’accesso ai propri dati personali ed a tutte le informazioni di cui all’art. 15 del GDPR;
                </li>
                <li>
                    <strong>Diritto di rettifica: </strong> diritto ad ottenere la rettifica dei dati personali inesatti, l'aggiornamento o l'integrazione dei dati personali già resi;
                </li>
                <li>
                    <strong>Diritto di cancellazione: </strong> la cancellazione dei propri dati, fatta eccezione che sussista un motivo legittimo prevalente per procedere al trattamento;
                </li>
                <li>
                    <strong>Diritto di limitazione di trattamento: </strong> la limitazione del trattamento nelle ipotesi di cui all’art. 18 del GDPR;
                </li>
                <li>
                    <strong>Diritto alla portabilità dei dati: </strong> diritto di ricevere in un formato strutturato di uso comune e leggibile i dati personali che la riguardano e a trasmettere tali dati ad altro titolare del trattamento senza impedimenti purché sia tecnicamente possibile;
                </li>
            </ul>
            Hai, altresì, il diritto:
            <ul>
                <li>
                    <strong>Diritto di opposizione: </strong> diritto di opporsi in qualsiasi momento al trattamento dei dati personali che la riguardano ai sensi dell'art. 6 paragrafo 1 lettere e) e f) del Regolamento UE 2016/679;
                </li>
                <li>
                    <strong>Diritto sui processi decisionali automatici-profilazione: </strong> diritto di non essere sottoposto a processo decisionale basato unicamente sul trattamento auto-matizzato compresa la profilazione, tenuto conto che essa è lecita se autorizzata dalla normativa vigente;
                </li>
            </ul>
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>7. Reclamo</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            Hai il diritto di rivolgerti al Garante per la protezione dei dati personali secondo le modalità indicate al seguente link: <Link color="inherit" href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docwebdisplay/docweb/4535524">https://www.garanteprivacy.it/web/guest/home/docweb/-/docwebdisplay/docweb/4535524</Link>
        </Typography>
        <Typography variant="h6" textAlign="justify" gutterBottom textTransform="uppercase">
            <strong>8. Come visionare l'informativa sul trattamento dei dati personali</strong>
        </Typography>
        <Typography variant="body1" textAlign="justify" gutterBottom>
            L'informativa sul trattamento dei dati personali relativa all'erogazione dei servizi della piattaforma INNOVATION RESEARCH è possibile visionarla alla seguente pagina web: <Link color="inherit" href="https://www.innovationresearch.it/privacy-policy">https://www.innovationresearch.it/privacy-policy </Link>
        </Typography>
    </Stack>
)

export default PrivacyPolicyText;