import { AddCircle, CheckCircle, Unpublished } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import LoggedUser from "../../../model/auth/LoggedUser";
import Keyword from "../../../model/database/Keyword";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import DemandsFilters from "../../../model/filters/DemandsFilters";
import demandSortFields from "../../../model/table/sort/DemandSortFields";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";
import TableToolbar from "../../common/table/TableToolbar";
import DemandsFiltersModal from "./DemandsFiltersModal";

interface DemandsTableToolbarProps {
    loggedUser?: LoggedUser,
    numSelected: number,
    consumers: ResearchConsumer[],
    keywords: Keyword[],
    handleNew: () => void,
    applyFilters: (filters: DemandsFilters) => void
    applySort: (sort: string) => void
}

const DemandsTableToolbar = ({
    loggedUser,
    numSelected,
    consumers,
    keywords,
    handleNew,
    applyFilters,
    applySort
}: DemandsTableToolbarProps) => {

    const [updated, setUpdated] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState<DemandsFilters>(
        (loggedUser?.role === 'RESEARCH_CONSUMER') ? {
            consumer: {
                label: 'Consumatore',
                value: loggedUser.id,
                valueDisplay: StringGeneratorUtil.generateConsumerLabel(consumers.find(value1 => value1.user?.id === loggedUser.id)!)
            }
        } : {}
    );

    const handleConfirm = (newStateFilters: DemandsFilters) => {
        setFilters(newStateFilters);
        applyFilters(newStateFilters);
        setUpdated(true);
    }

    const handleClean = () => {
        setFilters({});
        applyFilters({});
        setUpdated(true);
    }

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    React.useEffect(() => {
        if (!updated)
            setFilters(loggedUser?.role === 'RESEARCH_CONSUMER' ? {
                consumer: {
                    label: 'Consumatore',
                    value: loggedUser.id,
                    valueDisplay: StringGeneratorUtil.generateConsumerLabel(consumers.find(value1 => value1.user?.id === loggedUser.id)!)
                }
            } : {});
    }, [consumers])

    return (
        <>
            <TableToolbar
                numSelected={numSelected}
                title="Fabbisogni"
                fields={demandSortFields}
                filters={Object.values(filters)}
                defaultField={demandSortFields[0].value}
                defaultOrder={'asc'}
                handleOpenFiltersModal={handleOpenModal}
                handleCleanFilters={handleClean}
                handleSort={applySort}
                handleExport={() => alert('export selection')}
                handleDelete={loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'RESEARCH_PRODUCER') ? () => alert('delete selection') : undefined}
                extendedActions={
                    <>
                        {loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT' || loggedUser?.role === 'RESEARCH_CONSUMER') &&
                            <Tooltip title="Crea nuovo fabbisogno">
                                <IconButton color="secondary" onClick={handleNew}>
                                    <AddCircle />
                                </IconButton>
                            </Tooltip>}
                    </>
                }
                extendedActionsOnSelection={
                    loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT') && <>
                        <Tooltip title="Approva">
                            <IconButton color="secondary">
                                <CheckCircle />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Disapprova">
                            <IconButton color="secondary">
                                <Unpublished />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <DemandsFiltersModal
                open={openModal}
                filters={filters}
                consumers={consumers}
                loggedUser={loggedUser}
                keywords={keywords}
                handleClose={handleCloseModal}
                handleClean={handleClean}
                handleConfirm={handleConfirm}
            />
        </>
    );
}

export default DemandsTableToolbar;
