import researchConsumerType from "../../constants/researchConsumerType";
import researchProducerType from "../../constants/researchProducerType";
import ResearchConsumer from "../../model/database/ResearchConsumer";
import ResearchProducer from "../../model/database/ResearchProducer";

const generateConsumerLabel = (option: ResearchConsumer) => {
    if (!option)
        return '';
    return `[${[...researchConsumerType].find(value1 => value1.id === option.type)?.name}] ${option.name ?? option.businessName} (${option.user?.name} ${option.user?.surname})`
}

const generateProducerLabel = (option: ResearchProducer, extended = false) => {
    if (!option)
        return '';
    let baseString = `[${[...researchProducerType].find(value1 => value1.id === option.type)?.name}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}`;
    if(extended){
        baseString = `[${[...researchProducerType].find(value1 => value1.id === option.type)?.name}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name}`}`;
        return `${baseString}${option.department ? `, ${option.department}` : ''}${option.researchInstitution ? `, ${option.researchInstitution.name}` : ''}`;
    }
    return baseString;
}

const generateUserLabel = (option: ResearchProducer|ResearchConsumer) => {
    return option ? `[${[...researchProducerType, ...researchConsumerType].find(value1 => value1.id === option.type)?.name}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}` : '';
}

const generateProducerInterestedLabel = (option: any) => {
    const firstLabel = `[Produttore di ricerca - ${[...researchProducerType].find(value1 => value1.id === option.researchProducer?.type)?.name}] ${option.researchProducer?.type === 'RESEARCHER' ? `${option.name} ${option.surname}` : `${option.researchProducer?.name} (${option.name} ${option.surname})`}`;
    const secondLabel = `${option.researchProducer?.region}, ${option.researchProducer?.province}, ${option.researchProducer?.city} ${option.researchProducer?.researchInstitution ? ` - ${option.researchProducer?.researchInstitution?.name}${option.researchProducer?.department? `, ${option.researchProducer?.department}`: ''}` : ''}`;
    return [firstLabel, secondLabel];
}

const generateConsumerInterestedLabel = (option: any) => {
    const firstLabel = `[Consumatore di ricerca - ${[...researchConsumerType].find(value1 => value1.id === option.researchConsumer?.type)?.name}] ${option.researchConsumer?.businessName} (${option.name} ${option.surname})`;
    const secondLabel = `${option.researchConsumer?.region}, ${option.researchConsumer?.province}, ${option.researchConsumer?.city}`;
    return [firstLabel, secondLabel];
}

const generateProducerProposedLabel = (option: any) => {
    const firstLabel = `[${[...researchProducerType].find(value1 => value1.id === option.type)?.name}] ${option.type === 'RESEARCHER' ? `${option.name} ${option.surname}` : `${option.groupName} (${option.name} ${option.surname})`}`;
    const secondLabel = `${option.region}, ${option.province}, ${option.city} ${option.institutionName ? ` - ${option.institutionName}${option.department? `, ${option.department}`: ''}` : ''}`;
    return [firstLabel, secondLabel];
}

export default {
    generateConsumerLabel,
    generateProducerLabel,
    generateUserLabel,
    generateProducerInterestedLabel,
    generateConsumerInterestedLabel,
    generateProducerProposedLabel
}