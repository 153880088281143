import { CancelPresentation, Delete, NotificationsActive, Visibility } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import MenuButton from "../../../components/common/MenuButton";
import MenuButtonItem from "../../../components/common/MenuButtonItem";
import DiscussionCard from "../../../components/common/table/DiscussionCard";
import LoggedUser from "../../auth/LoggedUser";
import { CommonColumnProps } from "../CommonColumnProps";

interface DiscussionsTableColumnsProps {
  loggedUser?: LoggedUser,
  showCallback?: (id: string) => any,
  deleteCallback?: (id: string) => any,
  closeCallback?: (id: string) => any,
  reminderCallback?: (id: string) => any
}

const DiscussionsTableColumns = ({ loggedUser, showCallback, deleteCallback, closeCallback, reminderCallback }: DiscussionsTableColumnsProps): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    ...CommonColumnProps
  },
  {
    field: 'data',
    headerName: 'Tavoli di discussione',
    renderCell: (params) => (
      <Box my={2} mx={1} width="100%">
        <DiscussionCard discussion={params.row.data} showState />
      </Box>
    ),
    ...CommonColumnProps,
    minWidth: 100,
    align: 'center',
    flex: 10,
  },
  {
    field: "action",
    headerName: "Operazioni",
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => {

      let menuButtonItems: MenuButtonItem[] = [];

      if (loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT')) {
        menuButtonItems = [
          {
            icon: <Visibility color="secondary" />,
            text: "Visualizza",
            handleClick: showCallback
          },
          {
            icon: <NotificationsActive color="secondary" />,
            disabled: params.row.state === 'closed',
            text: 'Invia reminder',
            handleClick: reminderCallback
          },
          {
            icon: <CancelPresentation color="secondary" />,
            disabled: params.row.state.toUpperCase() === 'CLOSED',
            text: 'Chiudi',
            handleClick: closeCallback
          },
          {
            icon: <Delete color="error" />,
            text: 'Elimina',
            handleClick: deleteCallback
          }
        ];
      }

      return <Stack direction="row" spacing={0} alignItems="flex-end">
        {menuButtonItems.length === 0 ? <Tooltip title="Visualizza">
          <IconButton color="secondary" onClick={() => showCallback ? showCallback(params.id as string) : ''}>
            <Visibility />
          </IconButton>
        </Tooltip> :
          <MenuButton
            rowId={params.id as string}
            title="Azioni"
            children={menuButtonItems}
          />}
      </Stack>;
    },
    ...CommonColumnProps
  },
];

export default DiscussionsTableColumns;
