import {styled} from "@mui/material";

const AdminLayoutWrapper = styled('div')(
    ({ theme }) => ({
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            paddingTop: 72,
            [theme.breakpoints.up('lg')]: {
                    paddingLeft: 256
            }
    })
);

export default AdminLayoutWrapper;
