import { Button, Card, CardActions, CardContent, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Formik, FormikValues } from "formik";
import React, { ReactNode } from "react";
import * as Yup from "yup";
import researchConsumerType from "../../../constants/researchConsumerType";
import roles from "../../../constants/roles";
import User from "../../../model/database/User";
import RegexUtil from "../../../utils/RegexUtil";
import CircularLoading from "../../common/CircularLoading";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import UploadFileField from "../../common/UploadFileField";
import CityAutocomplete from "../../common/select/CityAutocomplete";
import ProvinceAutocomplete from "../../common/select/ProvinceAutocomplete";
import RegionAutocomplete from "../../common/select/RegionAutocomplete";

interface ResearchConsumerCardProps {
    user?: User,
    handleSave: (userToSave: FormikValues) => Promise<boolean>
}

const ResearchConsumerCard = ({user, handleSave}: ResearchConsumerCardProps) => {

    const [editMode, setEditMode] = React.useState<boolean>(false);

    return <Formik
        enableReinitialize
        initialValues={{
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            username: user?.username ?? '',
            email: user?.email ?? '',
            role: user?.role?.id ?? '',
            state: user?.state ?? 'INACTIVE',
            password: '',
            confirmPassword: '',
            consumerName: user?.researchConsumer?.name ?? '',
            consumerWebsite: user?.researchConsumer?.website ?? '',
            consumerDescription: user?.researchConsumer?.description ?? '',
            consumerVatNumber: user?.researchConsumer?.vatNumber ?? '',
            consumerAddress: user?.researchConsumer?.address ?? '',
            consumerCity: user?.researchConsumer?.city ?? '',
            consumerProvince: user?.researchConsumer?.province ?? '',
            consumerRegion: user?.researchConsumer?.region ?? '',
            consumerPostalCode: user?.researchConsumer?.postalCode ?? '',
            consumerPhoneNumber: user?.researchConsumer?.phoneNumber ?? '',
            consumerLogo: user?.researchConsumer?.logo ?? '',
            consumerType: user?.researchConsumer?.type ?? '',
            consumerScore: user?.score ?? 0
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il nome è obbligatorio e non può essere vuoto.'),
            surname: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il cognome è obbligatorio e non può essere vuoto.'),
            username: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Lo username è obbligatorio e non può essere vuoto.'),
            email: Yup.string().max(255).email('Il valore inserito non è un formato corretto.')
                .required('L\'email è obbligatoria e non può essere vuota.'),
            role: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatoria e non può essere vuota.'),
            state: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Lo stato è obbligatorio e non può essere vuota.'),
            password: Yup.string().min(8, 'La password deve essere di almeno 8 caratteri')
                .max(255, 'Il valore massimo consentito è di 255 caratteri'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono corrispondere'),
            // validazione consumer
            consumerName: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il nome è obbligatorio e non può essere vuoto.'),
            consumerWebsite: Yup.string().max(1024, 'Il valore massimo consentito è di 255 caratteri')
                .matches(RegexUtil.webSiteRegex,
                    'Sito web non valido'),
            consumerDescription: Yup.string().max(4096, 'Il valore massimo consentito è di 4096 caratteri'),
            consumerVatNumber: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .matches(/^[0-9]{11}$/, 'Partita IVA non valida')
                .required('La partita IVA è obbligatorio e non può essere vuoto.'),
            consumerAddress: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('L\'indirizzo è obbligatorio e non può essere vuoto.'),
            consumerCity: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La città è obbligatorio e non può essere vuoto.'),
            consumerProvince: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La provincia è obbligatorio e non può essere vuoto.'),
            consumerRegion: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La regione è obbligatorio e non può essere vuoto.'),
            consumerPostalCode: Yup.string()
                .matches(/^[0-9]{5}$/, 'Il formato del codice postale non è valido.')
                .max(5, 'Il valore massimo consentito è di 5 caratteri')
                .required('Il codice postale è obbligatorio e non può essere vuoto.'),
            consumerPhoneNumber: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .matches(/^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[5-90]|36[680]|33[1-90]|32[7890])\d{7}|(0[0-9]{1,4}[-.\s]?[0-9]{5,10})$/, 'Il recapito di telefono non è valido'),
            consumerType: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatorio e non può essere vuoto.'),
        })}
        onSubmit={async (values, {resetForm}) => {
            let result = await handleSave({
                name: values?.name,
                surname: values?.surname,
                username: values?.username,
                email: values?.email,
                state: values?.state,
                password: values?.password,
                score: values?.consumerScore,
                researchConsumerDTO: {
                    name: values?.consumerName,
                    website: values?.consumerWebsite,
                    description: values?.consumerDescription,
                    vatNumber: values?.consumerVatNumber,
                    address: values?.consumerAddress,
                    city: values?.consumerCity,
                    province: values?.consumerProvince,
                    region: values?.consumerRegion,
                    postalCode: values?.consumerPostalCode,
                    phoneNumber: values?.consumerPhoneNumber,
                    logo: values?.consumerLogo,
                    type: values?.consumerType
                }
            })
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              values,
              resetForm
          }) => (
            <form onSubmit={handleSubmit}>
                <Card sx={{
                    width: '100%',
                    my: 3
                }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.username && errors.username)}
                                    fullWidth
                                    helperText={touched.username && errors.username}
                                    label="Username"
                                    margin="normal"
                                    name="username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting || (editMode && user !== undefined)}
                                    value={values.username}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.email}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={5}>
                                <TextField
                                    error={Boolean(touched.role && errors.role)}
                                    fullWidth
                                    helperText={touched.role && errors.role}
                                    label="Ruolo"
                                    margin="normal"
                                    name="role"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    select
                                    disabled={isSubmitting || (editMode && user !== undefined)}
                                    value={values.role}
                                    required
                                    variant="outlined"
                                >
                                    {roles.map(value => <MenuItem key={value.id}
                                                                  value={value.id}>{value.name}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={5}>
                                <TextField
                                    error={Boolean(touched.consumerType && errors.consumerType)}
                                    fullWidth
                                    helperText={(touched.consumerType && errors.consumerType) as React.ReactNode}
                                    label="Tipologia"
                                    margin="normal"
                                    name="consumerType"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    disabled={editMode}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    value={values.consumerType}
                                    required
                                    variant="outlined"
                                >
                                    {researchConsumerType.map(value => <MenuItem key={value.id}
                                                                                 value={value.id}>{value.name}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={2}>
                                <TextField
                                    error={Boolean(touched.state && errors.state)}
                                    fullWidth
                                    helperText={touched.state && errors.state}
                                    label="Stato"
                                    margin="normal"
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={editMode}
                                    value={values.state}
                                    required
                                    variant="outlined"
                                >
                                    <MenuItem value="INACTIVE">Non attivo</MenuItem>
                                    <MenuItem value="ACTIVE">Attivo</MenuItem>
                                </TextField>
                            </ResponsiveGridItem>
                            {(editMode || !user) && <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    disabled={isSubmitting}
                                    value={values.password}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>}
                            {(editMode || !user) && <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    fullWidth
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    label="Conferma password"
                                    margin="normal"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    disabled={isSubmitting}
                                    value={values.confirmPassword}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    Informazioni responsabile
                                </Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={(touched.name && errors.name) as React.ReactNode}
                                    label="Nome"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.surname && errors.surname)}
                                    fullWidth
                                    helperText={(touched.surname && errors.surname) as React.ReactNode}
                                    label="Cognome"
                                    margin="normal"
                                    name="surname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.surname}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    Informazioni di dettaglio
                                </Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.consumerName && errors.consumerName)}
                                    fullWidth
                                    helperText={(touched.consumerName && errors.consumerName) as React.ReactNode}
                                    label="Nominativo"
                                    margin="normal"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    name="consumerName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    disabled={isSubmitting}
                                    value={values.consumerName}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.consumerVatNumber && errors.consumerVatNumber)}
                                    fullWidth
                                    helperText={(touched.consumerVatNumber && errors.consumerVatNumber) as ReactNode}
                                    label="Partita IVA"
                                    margin="normal"
                                    name="consumerVatNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.consumerVatNumber}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <RegionAutocomplete value={values.consumerRegion} fieldName="consumerRegion"
                                                    readOnly={!editMode}/>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <ProvinceAutocomplete value={values.consumerProvince} fieldName="consumerProvince"
                                                      regionSelected={values.consumerRegion} readOnly={!editMode}/>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={5}>
                                <CityAutocomplete value={values.consumerCity} fieldName="consumerCity"
                                                  provinceSelected={values.consumerProvince} readOnly={!editMode}/>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={5}>
                                <TextField
                                    error={Boolean(touched.consumerAddress && errors.consumerAddress)}
                                    fullWidth
                                    helperText={(touched.consumerAddress && errors.consumerAddress) as ReactNode}
                                    label="Indirizzo"
                                    margin="normal"
                                    name="consumerAddress"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.consumerAddress}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={2}>
                                <TextField
                                    error={Boolean(touched.consumerPostalCode && errors.consumerPostalCode)}
                                    fullWidth
                                    helperText={(touched.consumerPostalCode && errors.consumerPostalCode) as ReactNode}
                                    label="Codice postale"
                                    margin="normal"
                                    name="consumerPostalCode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.consumerPostalCode}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Typography gutterBottom variant="h5" component="div">
                                    Informazioni aggiuntive
                                </Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.consumerPhoneNumber && errors.consumerPhoneNumber)}
                                    fullWidth
                                    helperText={(touched.consumerPhoneNumber && errors.consumerPhoneNumber) as ReactNode}
                                    label="Recapito di telefono"
                                    margin="normal"
                                    name="consumerPhoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.consumerPhoneNumber}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.consumerWebsite && errors.consumerWebsite)}
                                    fullWidth
                                    helperText={(touched.consumerWebsite && errors.consumerWebsite) as ReactNode}
                                    label="Sito web"
                                    margin="normal"
                                    name="consumerWebsite"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    disabled={isSubmitting}
                                    value={values.consumerWebsite}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <UploadFileField propertyId="consumerLogo" editMode={editMode}/>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.consumerDescription && errors.consumerDescription)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                    helperText={(touched.consumerDescription && errors.consumerDescription) as ReactNode}
                                    label="Descrizione"
                                    margin="normal"
                                    name="consumerDescription"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.consumerDescription}
                                    inputProps={{
                                        readOnly: !editMode
                                    }}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{float: 'right', mr: 2, mt: 1, mb: 1}}>
                        {editMode && user !== undefined &&
                            <Button color="inherit" disabled={isSubmitting} onClick={() => {
                                setEditMode(false);
                                resetForm();
                            }}>
                                Annulla
                            </Button>}
                        {!editMode && user !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                Modifica
                            </Button> :
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                Conferma
                                {isSubmitting && <CircularLoading/>}
                            </Button>}
                    </CardActions>
                </Card>
            </form>
        )}
    </Formik>;
}

export default ResearchConsumerCard;
