import { Autocomplete, MenuItem, TextField, Typography } from "@mui/material";
import { HttpStatusCode } from "axios";
import { useFormikContext } from "formik";
import * as React from 'react';
import cnr from "../../../../__data__/cnr-departments";
import unisalento from "../../../../__data__/unisalento-departments";
import researchProducerType from "../../../../constants/researchProducerType";
import UserService from "../../../../services/UserService";
import CustomSweetalert from "../../../../theme/sweetalert";
import ResponsiveGridItem from "../../../common/ResponsiveGridItem";
import CityAutocomplete from "../../../common/select/CityAutocomplete";
import DepartmentAutocomplete from "../../../common/select/DepartmentAutocomplete";
import ProvinceAutocomplete from "../../../common/select/ProvinceAutocomplete";
import RegionAutocomplete from "../../../common/select/RegionAutocomplete";
import SectionProps from "./SectionProps";

const RegistrationProducerSection = ({ readOnly, institutions }: SectionProps) => {

    const {
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        isSubmitting,
        touched,
        values
    } = useFormikContext<any>();

    const [laboratories, setLaboratories] = React.useState<any>([]);

    const loadLaboratoryAsync = async (id: string) => {
        if (id !== '') {
            const [status, response] = await UserService.getAllLaboratoryByInstitution(id);

            if (status && response && status === HttpStatusCode.Ok) {
                setLaboratories(response);
            } else {
                CustomSweetalert({
                    title: 'Attenzione',
                    text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                    icon: 'error'
                });
            }
        }
    }

    React.useEffect(() => {
        if (readOnly && values.type === 'RESEARCHER') {
            loadLaboratoryAsync(values.producerInstitution);
        }
    }, [readOnly]);

    return <ResponsiveGridItem container spacing={1}>
        <ResponsiveGridItem mt={2}>
            <Typography
                variant="h5">{values.type !== 'RESEARCHER' ? 'Informazioni responsabile' : 'Informazioni ricercatore'}</Typography>
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
            <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={(touched.name && errors.name) as React.ReactNode}
                label="Nome"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.name}
                required
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
            <TextField
                error={Boolean(touched.surname && errors.surname)}
                fullWidth
                helperText={(touched.surname && errors.surname) as React.ReactNode}
                label="Cognome"
                margin="normal"
                name="surname"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.surname}
                required
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            />
        </ResponsiveGridItem>
        {values.type !== 'RESEARCHER' && <ResponsiveGridItem mt={2}>
            <Typography variant="h5">Informazioni di dettaglio</Typography>
        </ResponsiveGridItem>}
        {(values.type === 'LABORATORY' || values.type === 'RESEARCHER') && <ResponsiveGridItem>
            <Autocomplete
                options={institutions?.map(value => ({ label: value.name, id: value.id })) ?? []}
                fullWidth
                onBlur={handleBlur}
                disabled={isSubmitting}
                value={{ label: institutions?.find(v => v.id === values.producerInstitution)?.name ?? '', id: values.producerInstitution }}
                readOnly={readOnly}
                onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                    setFieldValue('producerInstitution', newValue.id);
                    if (values.type === 'RESEARCHER')
                        loadLaboratoryAsync(newValue.id);
                }}
                renderInput={(params) => <TextField
                    {...params}
                    required
                    variant="outlined"
                    name="producerInstitution"
                    margin="normal"
                    label="Università"
                    error={Boolean(touched.producerInstitution && errors.producerInstitution)}
                    helperText={(touched.producerInstitution && errors.producerInstitution) as React.ReactNode}
                />
                }
            />
        </ResponsiveGridItem>}
        {(values.type === 'LABORATORY' || values.type === 'RESEARCHER') && <ResponsiveGridItem md={6}>
            {
                values.producerInstitution === unisalento.parentId ?
                    <DepartmentAutocomplete
                        value={values.producerDepartment}
                        label={"Dipartimento"}
                        values={unisalento.departments}
                        fieldName="producerDepartment"
                        readOnly={readOnly} /> :
                    <TextField
                        error={Boolean(touched.producerDepartment && errors.producerDepartment)}
                        fullWidth
                        helperText={(touched.producerDepartment && errors.producerDepartment) as React.ReactNode}
                        label="Dipartimento"
                        margin="normal"
                        name="producerDepartment"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        disabled={isSubmitting}
                        value={values.producerDepartment}
                        required
                        variant="outlined"
                        inputProps={{
                            readOnly: readOnly
                        }}
                    />
            }
        </ResponsiveGridItem>}
        {
            values.type === 'RESEARCH_CENTER' && <ResponsiveGridItem>
                <DepartmentAutocomplete
                    value={values.producerDepartment}
                    label={"Istituto di appartenenza"}
                    values={cnr.departments}
                    fieldName="producerDepartment"
                    readOnly={readOnly} />
            </ResponsiveGridItem>
        }
        {values.type !== 'RESEARCHER' && <ResponsiveGridItem md={6}>
            <TextField
                error={Boolean(touched.producerName && errors.producerName)}
                fullWidth
                helperText={(touched.producerName && errors.producerName) as React.ReactNode}
                label={"Nome " + researchProducerType.find(value => value.id === values.type)?.label}
                margin="normal"
                name="producerName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.producerName}
                required
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            />
        </ResponsiveGridItem>}
        {values.type === 'RESEARCHER' && <ResponsiveGridItem md={6}>
            <TextField
                error={Boolean(touched.producerLab && errors.producerLab)}
                fullWidth
                helperText={(touched.producerLab && errors.producerLab) as React.ReactNode}
                label="Laboratorio di appartenenza"
                margin="normal"
                name="producerLab"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.producerLab}
                select
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            >
                <MenuItem value="">Nessuna selezione</MenuItem>
                {laboratories?.map((value: any) => <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>)}
            </TextField>
        </ResponsiveGridItem>}
        {(values.type === 'RESEARCH_CENTER' || values.type === 'SPINOFF') && <ResponsiveGridItem md={6}>
            <TextField
                error={Boolean(touched.producerVatNumber && errors.producerVatNumber)}
                fullWidth
                helperText={(touched.producerVatNumber && errors.producerVatNumber) as React.ReactNode}
                label="Partita IVA"
                margin="normal"
                name="producerVatNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.producerVatNumber}
                required
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            />
        </ResponsiveGridItem>}
        <ResponsiveGridItem md={6}>
            <RegionAutocomplete value={values.producerRegion} fieldName="producerRegion" readOnly={readOnly} />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={6}>
            <ProvinceAutocomplete value={values.producerProvince} fieldName="producerProvince" regionSelected={values.producerRegion} readOnly={readOnly} />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={5}>
            <CityAutocomplete value={values.producerCity} fieldName="producerCity" provinceSelected={values.producerProvince} readOnly={readOnly} />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={5}>
            <TextField
                error={Boolean(touched.producerAddress && errors.producerAddress)}
                fullWidth
                helperText={(touched.producerAddress && errors.producerAddress) as React.ReactNode}
                label="Indirizzo"
                margin="normal"
                name="producerAddress"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.producerAddress}
                required
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            />
        </ResponsiveGridItem>
        <ResponsiveGridItem md={2}>
            <TextField
                error={Boolean(touched.producerPostalCode && errors.producerPostalCode)}
                fullWidth
                helperText={(touched.producerPostalCode && errors.producerPostalCode) as React.ReactNode}
                label="Codice postale"
                margin="normal"
                name="producerPostalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.producerPostalCode}
                required
                variant="outlined"
                inputProps={{
                    readOnly: readOnly
                }}
            />
        </ResponsiveGridItem>
    </ResponsiveGridItem>

};

export default RegistrationProducerSection;
