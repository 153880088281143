import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import DiscussionTableDTO from "../model/dto/DiscussionTableDTO";
import DiscussionsFilters from "../model/filters/DiscussionsFilters";
import http from '../utils/HttpCommon';

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/docTables/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: DiscussionsFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/docTables/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const save = async (context: LoggedUserContext, discussion: DiscussionTableDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/docTables', {
			name: discussion.name,
			description: discussion.description,
			link: discussion.link,
			state: discussion.state,
			product: discussion.product,
			users: discussion.users
		});

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const update = async (context: LoggedUserContext, discussion: DiscussionTableDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/docTables/${id}`, {
			name: discussion.name,
			description: discussion.description,
			link: discussion.link,
			state: discussion.state,
			product: discussion.product,
			users: discussion.users
		});

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/docTables/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const changeStateById = async (context: LoggedUserContext, id: string, state: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/docTables/${id}/state/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const sendReminder = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/docTables/${id}/reminder`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const deleteAll = async (context: LoggedUserContext) => { }
const exportSelected = async (context: LoggedUserContext) => { }

const DiscussionService = {
	getAll,
	getAllFiltered,
	save,
	update,
	changeStateById,
	deleteById,
	deleteAll,
	exportSelected,
	sendReminder
}

export default DiscussionService;