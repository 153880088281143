import {Box, Button, CircularProgress} from "@mui/material";
import React from "react";
import Challenge from "../../../model/database/Challenge";
import CustomDialog from "../../common/CustomDialog";
import ChallengeService from "../../../services/ChallengeService";
import AuthContext from "../../../route/AuthContext";
import {HttpStatusCode} from "axios";
import CustomSweetalert from "../../../theme/sweetalert";
import UploadedDocument from "../../common/UploadedDocument";
import Roles from "../../../constants/roles";

interface ChallengeSubmissionModalProps {
    open: boolean,
    challenge?: Challenge,
    handleClose: () => void
}

const ChallengeSubmissionModal = ({open, challenge, handleClose}: ChallengeSubmissionModalProps) => {

    const authContext = React.useContext(AuthContext);

    const [submissions, setSubmissions] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const resetAndClose = () => {
        setLoading(false);
        handleClose();
    }

    const loadData = async () => {
        setLoading(true);
        let [status, response] = await ChallengeService.getAllSubmission(authContext, challenge?.product.id ?? '');
        if (status && response && status === HttpStatusCode.Ok) {
            setSubmissions(response);
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (open)
            loadData();
    }, [open]);

    const handleMakeVisible = async  (submission: any) => {
        setLoading(true);
        let [status, response] = await ChallengeService.changeVisibleStatus(authContext, challenge?.product.id ?? '', submission.producerId, !submission.visible);
        setLoading(false);
        if (status && response && status === HttpStatusCode.Ok) {
            loadData();
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }
    }

    return <CustomDialog
        open={open ? open : false}
        handleClose={resetAndClose}
        title="Proposte sottomesse"
        content={
            <>

                {loading ? <Box display="flex" alignItems="center" justifyContent="center" m={5}>
                    <CircularProgress color="secondary"/>
                </Box> : <>
                    {submissions.map((submission: any) => (
                        authContext.user?.role === Roles[2].id || authContext.user?.role === Roles[3].id ?
                            <UploadedDocument documentInfo={submission} visible={submission.visible} handleMakeVisible={ () => handleMakeVisible(submission)}/> :
                        <UploadedDocument documentInfo={submission} />)
                    )}
                </>}
            </>
        }
        actions={
            <>
                <Button color="inherit" onClick={resetAndClose}>
                    Chiudi
                </Button>
            </>
        }
    />;
}

export default ChallengeSubmissionModal;
