import {styled, Theme} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

const customCheckbox = (theme: Theme) => {
  return {
    '& .MuiCheckbox-root.Mui-checked svg': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderRadius: 4
    },
    '& .MuiDataGrid-cell': {
      padding: theme.spacing(1)
    },
    '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    }
  };
}

export const StyledTable = styled(DataGrid)(({ theme }) => ({
  ...customCheckbox(theme),
}));
