import {
  Abc,
  Assignment,
  BarChart,
  CardTravel, Category,
  Explore,
  Extension,
  Gavel,
  Group,
  Payment,
  Settings,
  Sms,
} from '@mui/icons-material';
import { Box, Divider, Drawer, Hidden, List, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NavItem from "../../components/common/NavItem";
import LogoInnovationResearch from "../../components/common/logo/LogoInnovationResearch";
import LogoUnisalento from "../../components/common/logo/LogoUnisalento";
import i18n from '../../i18n/i18n';
import AuthContext from '../../route/AuthContext';
import StatisticService from '../../services/StatisticService';

interface DashboardSidebarProps {
  onMobileClose: () => any,
  openMobile: boolean,
  navbarWidth: number
}

const items = [
  {
    href: '/app/statistics',
    icon: <BarChart />,
    title: i18n.t('sidebar.statistics'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT']
  },
  {
    id: 'products',
    href: '/app/products',
    icon: <CardTravel />,
    title: i18n.t('sidebar.products'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    badgeContent: null,
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER'] 
  },
  {
    id: 'services',
    href: '/app/services',
    icon: <Sms />,
    title: i18n.t('sidebar.services'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    badgeContent: null,
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER'] 
  },
  {
    id: 'demands',
    href: '/app/demands',
    icon: <Explore />,
    isBeta: true,
    title: i18n.t('sidebar.demands'),
    // roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    badgeContent: null,
    // showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_CONSUMER'] 
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'] 
  },
  {
    id: 'users',
    href: '/app/users',
    icon: <Group />,
    title: i18n.t('sidebar.users'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    badgeContent: null,
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'] 
  },
  {
    href: '/app/discussions',
    icon: <Gavel/>,
    title: i18n.t('sidebar.discussionTables'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    badgeContent: null,
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER']
  },
  {
    id: 'calls',
    href: '/app/calls',
    icon: <Assignment/>,
    title: i18n.t('sidebar.calls'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    badgeContent: null,
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER']
  },
  {
    id: 'challenges',
    href: '/app/challenges',
    icon: <Extension />,
    title: i18n.t('sidebar.challenges'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    badgeContent: null,
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_CONSUMER']
  },
  {
    id: 'payments',
    href: '/app/payments',
    icon: <Payment />,
    title: i18n.t('sidebar.payments'),
    badgeContent: null,
    roles: ['ADMINISTRATOR'],
    showBadge: ['ADMINISTRATOR']
  },
  {
    id: 'keywords',
    href: '/app/keywords',
    icon: <Abc />,
    title: i18n.t('sidebar.keywords'),
    badgeContent: null,
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT']
  },
  {
    href: '/app/categories',
    icon: <Category />,
    title: i18n.t('sidebar.categories'),
    roles: ['ADMINISTRATOR'],
    showBadge: ['ADMINISTRATOR']
  },
  {
    href: '/app/settings',
    icon: <Settings />,
    title: i18n.t('sidebar.settings'),
    roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    showBadge: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT']
  }
];

const AdminSidebar = ({onMobileClose, openMobile, navbarWidth}: DashboardSidebarProps) => {

  const authContext = React.useContext(AuthContext);
  const { t } = useTranslation(); 
  const [sideItems, setSideItems] = React.useState<any>(items);

  const loadPendent = async () => {
    const [status, response] = await StatisticService.getPendent(authContext);
    if (status && response) {
      let newItems = [...sideItems];
      newItems[1].badgeContent = response.researchProducts;
      newItems[2].badgeContent = response.researchServices;
      newItems[3].badgeContent = response.demands;
      newItems[4].badgeContent = response.users;
      newItems[5].badgeContent = response.tables;
      newItems[6].badgeContent = response.calls;
      newItems[7].badgeContent = response.challenges;
      newItems[8].badgeContent = response.payments;
      newItems[9].badgeContent = response.keywords;

      setSideItems(newItems);
      authContext.setNeedRefreshSidebar?.(false);
    }
  }

  React.useEffect(()=>{
    if(authContext.needRefreshSidebar)
      loadPendent();
  }, [authContext.needRefreshSidebar!]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          backgroundColor: 'primary.main',
        }}
      >
        <LogoUnisalento height={56} width="auto" style={{paddingBottom: 8}}/>
        <LogoInnovationResearch height={36} width="auto"/>
      </Box>
      <Divider sx={{backgroundColor: 'white'}}/>
      <Box sx={{p: 2}}>
        <Typography variant="subtitle1" color='secondary.contrastText'>
          {authContext.user?.role === 'ADMINISTRATOR' && <strong>{t('sidebar.role.admin')}</strong>}
          {authContext.user?.role === 'COMMITTEE_MANAGEMENT' && <strong>{t('sidebar.role.management')}</strong>}
          {authContext.user?.role === 'RESEARCH_PRODUCER' && <strong>{t('sidebar.role.producer')}</strong>}
          {authContext.user?.role === 'RESEARCH_CONSUMER' && <strong>{t('sidebar.role.consumer')}</strong>}
        </Typography>
        <List>
          {sideItems.map((item: any) => (item.roles.includes(authContext.user?.role ?? '') && (
            <NavItem
              id={item.showBadge?.includes(authContext.user?.role ?? '') ? item.id : undefined}
              href={item.href}
              key={item.title}
              title={item.title}
              isBeta={item.isBeta}
              badgeContent={item.badgeContent}
              icon={item.icon}
            />
          )))}
        </List>
      </Box>
      <Box sx={{flexGrow: 1}}/>
      <Box sx={{marginBottom: 2}}>
        <Typography
          align="center"
          variant="body2"
          color="secondary.contrastText"
        >
          {`© Copyright | ${new Date().getFullYear()}`}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              borderRadius: 4,
              ml: 2,
              backgroundColor: 'secondary.main',
              width: navbarWidth,
              top: 16,
              height: 'calc(100% - 32px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            elevation: 2,
            sx: {
              borderRadius: 4,
              ml: 2,
              backgroundColor: 'secondary.main',
              width: navbarWidth,
              top: 16,
              height: 'calc(100% - 32px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default AdminSidebar;
