import {Container, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import YoutubeEmbed from "./YoutubeEmbed";

const ProjectDescription = () => {

    const theme = useTheme();
    const isMdOrMore = useMediaQuery(theme.breakpoints.up('md'));

    return <Container maxWidth='lg' sx={{backgroundColor: 'white', mt: 2}}>
        <Typography variant="h2" sx={{fontWeight: 'bold'}} gutterBottom textAlign="justify">
            <span style={{color: '#f0cb00'}}>Progetto</span> Innovation Research
        </Typography>
        <Grid container>
            {!isMdOrMore &&
                (<ResponsiveGridItem md={12} paddingBottom={3}>
                    <YoutubeEmbed/>
                </ResponsiveGridItem>)
            }
            <ResponsiveGridItem md={6}>
                <Typography variant="body1" fontSize='1.2rem' paddingBottom={3} textAlign="justify">
                    <span style={{fontWeight: 'bold'}}>Innovation research è una piattaforma software </span>
                    che mira a mettere in contatto il mondo della ricerca con il mondo delle aziende
                    e delle PA per favorire da una parte l'industrializzazione dei prodotti della ricerca e la loro
                    commercializzazione sul mercato,
                    dall'altra l'elaborazione di proposte progettuali del mondo produttivo con la collaborazione di enti
                    e laboratori di ricerca per lo
                    sviluppo di soluzioni sempre innovative in un contesto competitivo.
                </Typography>
                <Typography variant="body1" fontSize='1.2rem' paddingBottom={3} textAlign="justify">
                    Innovation research raccoglie, memorizza e categorizza in un catalogo navigabile con
                    l'aiuto di parole chiave i prodotti della ricerca e i servizi offerti dai laboratori di ricerca e
                    spin-off
                    accreditati alla piattaforma.
                </Typography>
            </ResponsiveGridItem>
            {isMdOrMore &&
                (<ResponsiveGridItem md={6} display="flex" alignItems="center" p={2}>
                    <YoutubeEmbed/>
                </ResponsiveGridItem>)
            }

            <ResponsiveGridItem md={12}>
                <Typography variant="body1" fontSize='1.2rem' paddingBottom={5} textAlign="justify">
                    L'utente accreditato alla piattaforma come Azienda o PA, il cosiddetto Utilizzatore della ricerca,
                    può consultare il catalogo dei prodotti e dei servizi disponibili ed eseguire ricerche mirate al
                    fine,
                    per esempio, di individuare un prodotto di suo interesse e proporre un partenariato pubblico-privato
                    finalizzato
                    alla sottomissione di proposte progettuali ó richiedere formazione specialistica ad uno specifico
                    gruppo che mostra
                    uno specifico know-how su un tema di interesse.
                </Typography>
            </ResponsiveGridItem>
        </Grid>
    </Container>;
}

export default ProjectDescription