const italyCities: any = {
  "Padova": [
    {
      "codice": "028001",
      "nome": "Abano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A001",
      "cap": "35031",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@abanoterme.net",
      "pec": "abanoterme.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8245111",
      "fax": "+39 049 8600499",
      "coordinate": {
        "lat": 45.361900329589844,
        "lng": 11.792400360107422
      }
    },
    {
      "codice": "028002",
      "nome": "Agna",
      "nomeStraniero": null,
      "codiceCatastale": "A075",
      "cap": "35021",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.agna.pd.it",
      "pec": "agna.pd@cert.ip-veneto.net",
      "telefono": "+39 0495381148",
      "fax": "+39 0495381179",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 11.966666221618652
      }
    },
    {
      "codice": "028003",
      "nome": "Albignasego",
      "nomeStraniero": null,
      "codiceCatastale": "A161",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.albignasego.pd.it",
      "pec": "albignasego.pd@cert.ip-veneto.net",
      "telefono": "+39 049-8042232",
      "fax": "+39 049-8625188",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "028004",
      "nome": "Anguillara Veneta",
      "nomeStraniero": null,
      "codiceCatastale": "A296",
      "cap": "35022",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.anguillaraveneta.pd.it",
      "pec": "anguillaraveneta.pd@cert.ip-veneto.net",
      "telefono": "+39 049 5387001",
      "fax": "+39 049 5387999",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "028005",
      "nome": "Arquà Petrarca",
      "nomeStraniero": null,
      "codiceCatastale": "A434",
      "cap": "35032",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "arqua.petrarca@tin.it",
      "pec": "arquapetrarca.pd@cert.ip-veneto.net",
      "telefono": "+39 0429 777100",
      "fax": "+39 0429 718001",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.716666221618652
      }
    },
    {
      "codice": "028006",
      "nome": "Arre",
      "nomeStraniero": null,
      "codiceCatastale": "A438",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.arre.pd.it",
      "pec": "arre.pd@legalmailpa.it",
      "telefono": "+39 049-5389008",
      "fax": "+39 049-5389088",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "028007",
      "nome": "Arzergrande",
      "nomeStraniero": null,
      "codiceCatastale": "A458",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "marzano.antonio@comune.arzergrande.pd.it",
      "pec": "arzergrande@postemailcertificata.it",
      "telefono": "04958000303",
      "fax": "+39 049 9720048",
      "coordinate": {
        "lat": 45.27305603027344,
        "lng": 12.046667098999023
      }
    },
    {
      "codice": "028008",
      "nome": "Bagnoli di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "A568",
      "cap": "35023",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "comune@comune.bagnoli.pd.it",
      "pec": "comune@pec.comune.bagnoli.pd.it",
      "telefono": "+39 049 9579111",
      "fax": "+39 049 9535139",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "028009",
      "nome": "Baone",
      "nomeStraniero": null,
      "codiceCatastale": "A613",
      "cap": "35030",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "info@comune.baone.pd.it",
      "pec": "baone.pd@cert.ip-veneto.net",
      "telefono": "+39 042 92931",
      "fax": "+39 042 9603472",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.683333396911621
      }
    },
    {
      "codice": "028010",
      "nome": "Barbona",
      "nomeStraniero": null,
      "codiceCatastale": "A637",
      "cap": "35040",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "barbonacomune@libero.it",
      "pec": "comune.barbona.pd@pecveneto.it",
      "telefono": "+39 0425 607093",
      "fax": "+39 0425 607291",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 11.699999809265137
      }
    },
    {
      "codice": "028011",
      "nome": "Battaglia Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A714",
      "cap": "35041",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.battaglia-terme.pd.it",
      "pec": "battagliaterme.pd@cert.ip-veneto.net",
      "telefono": "+39 049 525162",
      "fax": "+39 049 9100347",
      "coordinate": {
        "lat": 45.289371490478516,
        "lng": 11.779589653015137
      }
    },
    {
      "codice": "028012",
      "nome": "Boara Pisani",
      "nomeStraniero": null,
      "codiceCatastale": "A906",
      "cap": "35040",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "servizi.demografici@comune.boarapisani.pd.it",
      "pec": "boarapisani.pd@cert.ip-veneto.net",
      "telefono": "+39 0425 484008",
      "fax": "+39 0425 48221",
      "coordinate": {
        "lat": 45.10770034790039,
        "lng": 11.782549858093262
      }
    },
    {
      "codice": "028107",
      "nome": "Borgo Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "M402",
      "cap": "35046",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "protocollo@comune.borgoveneto.pd.it",
      "pec": "protocollo@pec.comune.borgoveneto.pd.it",
      "telefono": "0429/89152",
      "fax": null,
      "coordinate": {
        "lat": 45.22910690307617,
        "lng": 11.53842544555664
      }
    },
    {
      "codice": "028013",
      "nome": "Borgoricco",
      "nomeStraniero": null,
      "codiceCatastale": "B031",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.borgoricco.pd.it",
      "pec": "comune.borgoricco.pd@pecveneto.it",
      "telefono": "0499337911",
      "fax": null,
      "coordinate": {
        "lat": 45.53356170654297,
        "lng": 11.9658784866333
      }
    },
    {
      "codice": "028014",
      "nome": "Bovolenta",
      "nomeStraniero": null,
      "codiceCatastale": "B106",
      "cap": "35024",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.bovolenta.pd.it",
      "pec": "comunebovolenta.pd@legalmailpa.it",
      "telefono": "+39 049 5386166",
      "fax": "+39 049 5386252",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "028015",
      "nome": "Brugine",
      "nomeStraniero": null,
      "codiceCatastale": "B213",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.brugine.pd.it",
      "pec": "brugine.pd@legalmailpa.it",
      "telefono": "+39 049 5806002",
      "fax": "+39 049 5806287",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 11.992500305175781
      }
    },
    {
      "codice": "028016",
      "nome": "Cadoneghe",
      "nomeStraniero": null,
      "codiceCatastale": "B345",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "comune@comune.cadoneghe.pd.it",
      "pec": "cadoneghe.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8881911",
      "fax": "+39 049 - 8872508",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "028020",
      "nome": "Campo San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "B564",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.camposanmartino.pd.it",
      "pec": "camposanmartino.pd@cert.ip-veneto.net",
      "telefono": "+39 0499698970",
      "fax": "+39 0499698990",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 11.816666603088379
      }
    },
    {
      "codice": "028017",
      "nome": "Campodarsego",
      "nomeStraniero": null,
      "codiceCatastale": "B524",
      "cap": "35011",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "campodarsego.pd@cert.ip-veneto.net",
      "pec": "campodarsego.pd@cert.ip-veneto.net",
      "telefono": "+39 0499299811",
      "fax": "+39 0499299800",
      "coordinate": {
        "lat": 45.5,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "028018",
      "nome": "Campodoro",
      "nomeStraniero": null,
      "codiceCatastale": "B531",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.campodoro.pd.it",
      "pec": "campodoro.pd@legalmailpa.it",
      "telefono": "+39 049 9065011",
      "fax": "+39 049 9065578",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.75
      }
    },
    {
      "codice": "028019",
      "nome": "Camposampiero",
      "nomeStraniero": null,
      "codiceCatastale": "B563",
      "cap": "35012",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.camposampiero.pd.it",
      "pec": "comune.camposampiero.pd@pecveneto.it",
      "telefono": "+39 049.9315211",
      "fax": "+39 049.7370200",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "028021",
      "nome": "Candiana",
      "nomeStraniero": null,
      "codiceCatastale": "B589",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.candiana.pd.it",
      "pec": "protocollo.comune.candiana.pd@pecveneto.it",
      "telefono": "+39 049 5349603",
      "fax": "+39 049 5349614",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 11.966666221618652
      }
    },
    {
      "codice": "028022",
      "nome": "Carceri",
      "nomeStraniero": null,
      "codiceCatastale": "B749",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "tecnico@comune.carceri.pd.it",
      "pec": "carceri.pd@cert.ip-veneto.net",
      "telefono": "+39 0429619835",
      "fax": "+39 0429619787",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "028023",
      "nome": "Carmignano di Brenta",
      "nomeStraniero": null,
      "codiceCatastale": "B795",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.carmignanodibrenta.pd.it",
      "pec": "carmignanodibrenta@cert.ip-veneto.net",
      "telefono": "+39 0499430355",
      "fax": "+39 0499430335",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 11.699999809265137
      }
    },
    {
      "codice": "028026",
      "nome": "Cartura",
      "nomeStraniero": null,
      "codiceCatastale": "B848",
      "cap": "35025",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.cartura.pd.it",
      "pec": "protocollo@cert.comune.cartura.pd.it",
      "telefono": "+39 049.9555556",
      "fax": "+39 049.9555571",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "028027",
      "nome": "Casale di Scodosia",
      "nomeStraniero": null,
      "codiceCatastale": "B877",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "demografici@comune.casale-di-scodosia.pd.it",
      "pec": "casalediscodosia.pd@cert.ip-veneto.net",
      "telefono": "+39 0429879044",
      "fax": "+39 0429847048",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "028028",
      "nome": "Casalserugo",
      "nomeStraniero": null,
      "codiceCatastale": "B912",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.casalserugo.pd.it",
      "pec": "casalserugo.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8742811",
      "fax": "+39 049 8740015",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "028029",
      "nome": "Castelbaldo",
      "nomeStraniero": null,
      "codiceCatastale": "C057",
      "cap": "35040",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "info@comune.castelbaldo.pd.it",
      "pec": "castelbaldo.pd@cert.ip-veneto.net",
      "telefono": "+39 0425 546286",
      "fax": "+39 0425 546015",
      "coordinate": {
        "lat": 45.121944427490234,
        "lng": 11.45138931274414
      }
    },
    {
      "codice": "028030",
      "nome": "Cervarese Santa Croce",
      "nomeStraniero": null,
      "codiceCatastale": "C544",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "c.maritan@comune.cervarese.pd.it",
      "pec": "cervaresesantacroce.pd@cert.ip-veneto.net",
      "telefono": "+39 0499915100",
      "fax": "+39 0499915638",
      "coordinate": {
        "lat": 45.40305709838867,
        "lng": 11.692222595214844
      }
    },
    {
      "codice": "028031",
      "nome": "Cinto Euganeo",
      "nomeStraniero": null,
      "codiceCatastale": "C713",
      "cap": "35030",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "cinto@provincia.padova.it",
      "pec": "cintoeuganeo.pd@cert.ip-veneto.net",
      "telefono": "+39 042 994083",
      "fax": "+39 042 994084",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "028032",
      "nome": "Cittadella",
      "nomeStraniero": null,
      "codiceCatastale": "C743",
      "cap": "35013",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.cittadella.pd.it",
      "pec": "cittadella.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9413411",
      "fax": "+39 049 9413419",
      "coordinate": {
        "lat": 45.64861297607422,
        "lng": 11.783611297607422
      }
    },
    {
      "codice": "028033",
      "nome": "Codevigo",
      "nomeStraniero": null,
      "codiceCatastale": "C812",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.codevigo.pd.it",
      "pec": "codevigo.pd@cert.ip-veneto.net",
      "telefono": "+39 049 5817006",
      "fax": "+39 049 5817304",
      "coordinate": {
        "lat": 45.267250061035156,
        "lng": 12.101420402526855
      }
    },
    {
      "codice": "028034",
      "nome": "Conselve",
      "nomeStraniero": null,
      "codiceCatastale": "C964",
      "cap": "35026",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.conselve.pd.it",
      "pec": "affari.generali@pec.comune.conselve.pd.it",
      "telefono": "+39 049 959 6511",
      "fax": "+39 049 950 0129",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "028035",
      "nome": "Correzzola",
      "nomeStraniero": null,
      "codiceCatastale": "D040",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "anagrafe@comune.correzzola.pd.it",
      "pec": "correzzola.pd@cert.ip-veneto.net",
      "telefono": "+39 0495807007",
      "fax": "+39 0495807013",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 12.066666603088379
      }
    },
    {
      "codice": "028036",
      "nome": "Curtarolo",
      "nomeStraniero": null,
      "codiceCatastale": "D226",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "urp@comune.curtarolo.pd.it",
      "pec": "curtarolo.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9699911",
      "fax": "+39 049 557880",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "028106",
      "nome": "Due Carrare",
      "nomeStraniero": null,
      "codiceCatastale": "M300",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "amministrazione@comune.duecarrare.pd.it",
      "pec": "duecarrare.pd@cert.ip-veneto.net",
      "telefono": "+39 049.9124411",
      "fax": "+39 049.9115710",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "028037",
      "nome": "Este",
      "nomeStraniero": null,
      "codiceCatastale": "D442",
      "cap": "35042",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "anagrafe@comune.este.pd.it",
      "pec": "este.pd@legalmailpa.it",
      "telefono": "+39 0429 617511",
      "fax": "+39 0429 600632",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "028038",
      "nome": "Fontaniva",
      "nomeStraniero": null,
      "codiceCatastale": "D679",
      "cap": "35014",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.fontaniva.pd.it",
      "pec": "fontaniva.pd@cert.ip-veneto.net",
      "telefono": "+39 049 5949900",
      "fax": "+39 049 5940040",
      "coordinate": {
        "lat": 45.637630462646484,
        "lng": 11.752197265625
      }
    },
    {
      "codice": "028039",
      "nome": "Galliera Veneta",
      "nomeStraniero": null,
      "codiceCatastale": "D879",
      "cap": "35015",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.gallieraveneta.pd.it",
      "pec": "comune.gallieraveneta@halleycert.it",
      "telefono": "+39 049 5969153",
      "fax": "+39 049.9470577",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "028040",
      "nome": "Galzignano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "D889",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "anagrafe@galzignanoterme.org",
      "pec": "galzignanoterme.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9130041",
      "fax": "+39 049 9130060",
      "coordinate": {
        "lat": 45.307979583740234,
        "lng": 11.731340408325195
      }
    },
    {
      "codice": "028041",
      "nome": "Gazzo",
      "nomeStraniero": null,
      "codiceCatastale": "D956",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.gazzo.pd.it",
      "pec": "protocollo@pec.comune.gazzo.pd.it",
      "telefono": "+39 049 9426155",
      "fax": "+39 049 9425603",
      "coordinate": {
        "lat": 45.58222198486328,
        "lng": 11.706388473510742
      }
    },
    {
      "codice": "028042",
      "nome": "Grantorto",
      "nomeStraniero": null,
      "codiceCatastale": "E145",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.grantorto.pd.it",
      "pec": "grantorto.pd@legalmailpa.it",
      "telefono": "+39 049 5960003",
      "fax": "+39 049 9490072",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.733333587646484
      }
    },
    {
      "codice": "028043",
      "nome": "Granze",
      "nomeStraniero": null,
      "codiceCatastale": "E146",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "info@comune.granze.pd.it",
      "pec": "granze.pd@cert.ip-veneto.net",
      "telefono": "+39 0429 98734",
      "fax": "+39 0429 690202",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.716666221618652
      }
    },
    {
      "codice": "028044",
      "nome": "Legnaro",
      "nomeStraniero": null,
      "codiceCatastale": "E515",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.legnaro.pd.it",
      "pec": "legnaro.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8838901",
      "fax": "+39 049 790940",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 11.966666221618652
      }
    },
    {
      "codice": "028045",
      "nome": "Limena",
      "nomeStraniero": null,
      "codiceCatastale": "E592",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria.generale@comune.limena.pd.it",
      "pec": "limena.pd@cert.ip-veneto.net",
      "telefono": "+39 0498844338",
      "fax": "+39 0498841277",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 11.850000381469727
      }
    },
    {
      "codice": "028046",
      "nome": "Loreggia",
      "nomeStraniero": null,
      "codiceCatastale": "E684",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.loreggia.pd.it",
      "pec": "comune.loreggia.pd@pecveneto.it",
      "telefono": "+39 049 9304100",
      "fax": "+39 049 9304101",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.949999809265137
      }
    },
    {
      "codice": "028047",
      "nome": "Lozzo Atestino",
      "nomeStraniero": null,
      "codiceCatastale": "E709",
      "cap": "35034",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria.protocollo@comune.lozzoatestino.pd.it",
      "pec": "comunelozzo.pd@legalmailpa.it",
      "telefono": "+39 0429 94025",
      "fax": "+39 0429 94856",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 11.633333206176758
      }
    },
    {
      "codice": "028048",
      "nome": "Maserà di Padova",
      "nomeStraniero": null,
      "codiceCatastale": "F011",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.masera.pd.it",
      "pec": "comunemaseradipadova@legalmailpa.it",
      "telefono": "+39 049 8864111",
      "fax": "+39 049 8862693",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "028049",
      "nome": "Masi",
      "nomeStraniero": null,
      "codiceCatastale": "F013",
      "cap": "35040",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "ufficio.protocollo@comune.masi.pd.it",
      "pec": "masi.pd@cert.ip-veneto.net",
      "telefono": "+39 0425 591130",
      "fax": "+39 0425 591140",
      "coordinate": {
        "lat": 45.116668701171875,
        "lng": 11.5
      }
    },
    {
      "codice": "028050",
      "nome": "Massanzago",
      "nomeStraniero": null,
      "codiceCatastale": "F033",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "anagrafe@comune.massanzago.pd.it",
      "pec": "comune.massanzago.pd@pecveneto.it",
      "telefono": "+39 049 5797001",
      "fax": "+39 049 9360020",
      "coordinate": {
        "lat": 45.55638885498047,
        "lng": 12.007222175598145
      }
    },
    {
      "codice": "028052",
      "nome": "Megliadino San Vitale",
      "nomeStraniero": null,
      "codiceCatastale": "F092",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria@comune.megliadinosvitale.pd.it",
      "pec": "protocollo.comune.megliadinosv.pd@pecveneto.it",
      "telefono": "+39 0429 88003",
      "fax": "+39 0429 88593",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "028053",
      "nome": "Merlara",
      "nomeStraniero": null,
      "codiceCatastale": "F148",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "stato.civile@comune.merlara.pd.it",
      "pec": "merlara.pd@cert.ip-veneto.net",
      "telefono": "+39 042985015",
      "fax": "+39 0429844279",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "028054",
      "nome": "Mestrino",
      "nomeStraniero": null,
      "codiceCatastale": "F161",
      "cap": "35035",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "elettorale@comune.mestrino.pd.it",
      "pec": "protocollo.comune.mestrino.pd@pecveneto.it",
      "telefono": "+39 0499000042",
      "fax": "+39 0499000177",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "028055",
      "nome": "Monselice",
      "nomeStraniero": null,
      "codiceCatastale": "F382",
      "cap": "35043",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "urp@comune.monselice.padova.it",
      "pec": "monselice.pd@cert.ip-veneto.net",
      "telefono": "+39 0429 786911",
      "fax": "+39 0429 73092",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 11.75
      }
    },
    {
      "codice": "028056",
      "nome": "Montagnana",
      "nomeStraniero": null,
      "codiceCatastale": "F394",
      "cap": "35044",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "protocollo@comune.montagnana.pd.it",
      "pec": "montagnana.pd@cert.ip-veneto.net",
      "telefono": "+39 0429 81247",
      "fax": "+39 0429 800737",
      "coordinate": {
        "lat": 45.23332214355469,
        "lng": 11.465778350830078
      }
    },
    {
      "codice": "028057",
      "nome": "Montegrotto Terme",
      "nomeStraniero": null,
      "codiceCatastale": "F529",
      "cap": "35036",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@montegrotto.org",
      "pec": "protocollo@pec.montegrotto.org",
      "telefono": "+39 049 8928711",
      "fax": "+39 049 8928718",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "028058",
      "nome": "Noventa Padovana",
      "nomeStraniero": null,
      "codiceCatastale": "F962",
      "cap": "35027",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.noventa.pd.it",
      "pec": "noventapadovana.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8952102",
      "fax": "+39 049 8930280",
      "coordinate": {
        "lat": 45.414302825927734,
        "lng": 11.951004028320312
      }
    },
    {
      "codice": "028059",
      "nome": "Ospedaletto Euganeo",
      "nomeStraniero": null,
      "codiceCatastale": "G167",
      "cap": "35045",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria@comune.ospedalettoeuganeo.pd.it",
      "pec": "comune.ospedalettoeuganeo@certificata.com",
      "telefono": "+39 0429 90683",
      "fax": "+39 0429 90786",
      "coordinate": {
        "lat": 45.22305679321289,
        "lng": 11.61111068725586
      }
    },
    {
      "codice": "028060",
      "nome": "Padova",
      "nomeStraniero": null,
      "codiceCatastale": "G224",
      "cap": "35121",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "alberto.coro@comune.padova.it",
      "pec": "comune.padova@cert.legalmail.it",
      "telefono": "+39 049 8205111",
      "fax": "+39 049 8205268",
      "coordinate": {
        "lat": 45.40638732910156,
        "lng": 11.877778053283691
      }
    },
    {
      "codice": "028061",
      "nome": "Pernumia",
      "nomeStraniero": null,
      "codiceCatastale": "G461",
      "cap": "35020",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "comune@comune-pernumia.it",
      "pec": "pernumia.pd@cert.ip-veneto.net",
      "telefono": "+39 0429779040",
      "fax": "+39 0429778138",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "028062",
      "nome": "Piacenza d'Adige",
      "nomeStraniero": null,
      "codiceCatastale": "G534",
      "cap": "35040",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "anagrafe@comune.piacenza-d-adige.pd.it",
      "pec": "anagrafe@pec.comune.piacenza-d-adige.pd.it",
      "telefono": "+39 0425 55012",
      "fax": "+39 0425 55076",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "028063",
      "nome": "Piazzola sul Brenta",
      "nomeStraniero": null,
      "codiceCatastale": "G587",
      "cap": "35016",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.piazzola.pd.it",
      "pec": "piazzolasulbrenta.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9697911",
      "fax": "+39 049 9697930",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "028064",
      "nome": "Piombino Dese",
      "nomeStraniero": null,
      "codiceCatastale": "G688",
      "cap": "35017",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "municipio@comune.piombinodese.pd.it",
      "pec": "protocollo.comune.piombinodese.pd@pecveneto.it",
      "telefono": "0499369411",
      "fax": "0499366727",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "028065",
      "nome": "Piove di Sacco",
      "nomeStraniero": null,
      "codiceCatastale": "G693",
      "cap": "35028",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.piove.pd.it",
      "pec": "polisportello@pec.comune.piove.pd.it",
      "telefono": "+39 049 9709111",
      "fax": "+39 049 9702193",
      "coordinate": {
        "lat": 45.297691345214844,
        "lng": 12.036813735961914
      }
    },
    {
      "codice": "028066",
      "nome": "Polverara",
      "nomeStraniero": null,
      "codiceCatastale": "G802",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.polverara.pd.it",
      "pec": "polverara.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9774811",
      "fax": "+39 049 5855275",
      "coordinate": {
        "lat": 45.309391021728516,
        "lng": 11.956011772155762
      }
    },
    {
      "codice": "028067",
      "nome": "Ponso",
      "nomeStraniero": null,
      "codiceCatastale": "G823",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "comune@comune.ponso.pd.it",
      "pec": "ponso.pd@cert.ip-veneto.net",
      "telefono": "+39 0429 656108",
      "fax": "+39 0429 95014",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "028069",
      "nome": "Ponte San Nicolò",
      "nomeStraniero": null,
      "codiceCatastale": "G855",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.pontesannicolo.pd.it.it",
      "pec": "pontesannicolo.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8968611",
      "fax": "+39 049.8960785",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "028068",
      "nome": "Pontelongo",
      "nomeStraniero": null,
      "codiceCatastale": "G850",
      "cap": "35029",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.pontelongo.pd.it",
      "pec": "comunepontelongo.pd@legalmailpa.it",
      "telefono": "+39 049 9775265",
      "fax": "+39 049 9775565",
      "coordinate": {
        "lat": 45.25,
        "lng": 12.016666412353516
      }
    },
    {
      "codice": "028070",
      "nome": "Pozzonovo",
      "nomeStraniero": null,
      "codiceCatastale": "G963",
      "cap": "35020",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria@comune.pozzonovo.pd.it",
      "pec": "protocollo@pec.comune.pozzonovo.pd.it",
      "telefono": "+39 0429 773222",
      "fax": "+39 0429 773154",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "028071",
      "nome": "Rovolon",
      "nomeStraniero": null,
      "codiceCatastale": "H622",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.rovolon.pd.it",
      "pec": "rovolon.pd@cert.ip-veneto.net",
      "telefono": "+39 049-9910017",
      "fax": "+39 049-9910161",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "028072",
      "nome": "Rubano",
      "nomeStraniero": null,
      "codiceCatastale": "H625",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@rubano.it",
      "pec": "rubano.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8739201",
      "fax": "+39 049 8739245",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "028073",
      "nome": "Saccolongo",
      "nomeStraniero": null,
      "codiceCatastale": "H655",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "web@comune.saccolongo.pd.it",
      "pec": "comunesaccolongo.pd@legalmailpa.it",
      "telefono": "+39 049 8739811",
      "fax": "+39 049 8016132",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 11.75
      }
    },
    {
      "codice": "028075",
      "nome": "San Giorgio delle Pertiche",
      "nomeStraniero": null,
      "codiceCatastale": "H893",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.sangiorgiodellepertiche.pd.it",
      "pec": "comune.sangiorgiodellepertiche.pd@pecveneto.it",
      "telefono": "+39 049 9374711",
      "fax": null,
      "coordinate": {
        "lat": 45.541900634765625,
        "lng": 11.895000457763672
      }
    },
    {
      "codice": "028076",
      "nome": "San Giorgio in Bosco",
      "nomeStraniero": null,
      "codiceCatastale": "H897",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "demografici@comune.sangiorgioinbosco.pd.it",
      "pec": "sangiorgioinbosco.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9453211",
      "fax": "+39 049 9450438",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "028077",
      "nome": "San Martino di Lupari",
      "nomeStraniero": null,
      "codiceCatastale": "I008",
      "cap": "35018",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "ragioneria@comune.sanmartinodilupari.pd.it",
      "pec": "sanmartinodilupari.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9460408",
      "fax": "+39 049 9461066",
      "coordinate": {
        "lat": 45.65570068359375,
        "lng": 11.859399795532227
      }
    },
    {
      "codice": "028078",
      "nome": "San Pietro in Gu",
      "nomeStraniero": null,
      "codiceCatastale": "I107",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.sanpietroingu.pd.it",
      "pec": "protocollo@pec.comune.sanpietroingu.pd.it",
      "telefono": "+39 049 9458111",
      "fax": "+39 049 9455184",
      "coordinate": {
        "lat": 45.610198974609375,
        "lng": 11.677900314331055
      }
    },
    {
      "codice": "028079",
      "nome": "San Pietro Viminario",
      "nomeStraniero": null,
      "codiceCatastale": "I120",
      "cap": "35020",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "protocollo@comune.sanpietroviminario.pd.it",
      "pec": "sanpietroviminario.pd@cert.ip-veneto.net",
      "telefono": "0429760111",
      "fax": "+39 0429762329",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.816666603088379
      }
    },
    {
      "codice": "028082",
      "nome": "Sant'Angelo di Piove di Sacco",
      "nomeStraniero": null,
      "codiceCatastale": "I275",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@santangelopiove.net",
      "pec": "santangelodipiovedisacco.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9793911",
      "fax": "+39 049 9793999",
      "coordinate": {
        "lat": 45.34555435180664,
        "lng": 12.007222175598145
      }
    },
    {
      "codice": "028083",
      "nome": "Sant'Elena",
      "nomeStraniero": null,
      "codiceCatastale": "I319",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria@comune.santelena.pd.it",
      "pec": "santelena.pd@cert.ip-veneto.net",
      "telefono": "+39 0429-98695",
      "fax": "+39 0429-690704",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.716667175292969
      }
    },
    {
      "codice": "028084",
      "nome": "Sant'Urbano",
      "nomeStraniero": null,
      "codiceCatastale": "I375",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "info@comune.santurbano.pd.it",
      "pec": "comune.santurbano.pd@legalmail.it",
      "telefono": "+39 0429 96023",
      "fax": "+39 0429 96272",
      "coordinate": {
        "lat": 45.133331298828125,
        "lng": 11.649999618530273
      }
    },
    {
      "codice": "028080",
      "nome": "Santa Giustina in Colle",
      "nomeStraniero": null,
      "codiceCatastale": "I207",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comunesgcolle.pd.it",
      "pec": "comune.sgcolle.pd@pecveneto.it",
      "telefono": "+39 0499304430",
      "fax": "+39 0499304431",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "028085",
      "nome": "Saonara",
      "nomeStraniero": null,
      "codiceCatastale": "I418",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "sindaco@comune.saonara.pd.it",
      "pec": "protocollo@pec.comune.saonara.pd.it",
      "telefono": "+39 049 0996400",
      "fax": "+39 049 644662",
      "coordinate": {
        "lat": 45.370269775390625,
        "lng": 11.983988761901855
      }
    },
    {
      "codice": "028086",
      "nome": "Selvazzano Dentro",
      "nomeStraniero": null,
      "codiceCatastale": "I595",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "ragioneria@comune.selvazzano-dentro.pd.it",
      "pec": "selvazzanodentro.pd@cert.ip-veneto.net",
      "telefono": "+39 049/8733851",
      "fax": "+39 049/8733990",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "028087",
      "nome": "Solesino",
      "nomeStraniero": null,
      "codiceCatastale": "I799",
      "cap": "35047",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "info@comunesolesino.it",
      "pec": "solesino.pd@cert.ip-veneto.net",
      "telefono": "+39 0429 771611",
      "fax": "+39 0429 709564",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 11.75
      }
    },
    {
      "codice": "028088",
      "nome": "Stanghella",
      "nomeStraniero": null,
      "codiceCatastale": "I938",
      "cap": "35048",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "info@comune.stanghella.pd.it",
      "pec": "stanghella.pd@cert.ip-veneto.net",
      "telefono": "+39 0425 458 111",
      "fax": "+39 0425 458 114",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 11.75
      }
    },
    {
      "codice": "028089",
      "nome": "Teolo",
      "nomeStraniero": null,
      "codiceCatastale": "L100",
      "cap": "35037",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.teolo.pd.it",
      "pec": "teolo.pd@cert.ip-veneto.net",
      "telefono": "+39 0499998511",
      "fax": "+39 0499900264",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "028090",
      "nome": "Terrassa Padovana",
      "nomeStraniero": null,
      "codiceCatastale": "L132",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.terrassa.pd.it",
      "pec": "terrassapadovana.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9500464",
      "fax": "+39 049 5384958",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "028091",
      "nome": "Tombolo",
      "nomeStraniero": null,
      "codiceCatastale": "L199",
      "cap": "35019",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "info@comune.tombolo.pd.it",
      "pec": "comunetombolo.pd@legalmailpa.it",
      "telefono": "+39 049.5968322",
      "fax": "+39 049.5968098",
      "coordinate": {
        "lat": 45.64694595336914,
        "lng": 11.828055381774902
      }
    },
    {
      "codice": "028092",
      "nome": "Torreglia",
      "nomeStraniero": null,
      "codiceCatastale": "L270",
      "cap": "35038",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@comune.torreglia.pd.it",
      "pec": "comunetorreglia.pd@legalmail.it",
      "telefono": "+39 049 9930128",
      "fax": "+39 049 5212620",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 11.733333587646484
      }
    },
    {
      "codice": "028093",
      "nome": "Trebaseleghe",
      "nomeStraniero": null,
      "codiceCatastale": "L349",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "comune@comune.trebaseleghe.pd.it",
      "pec": "comune.trebaseleghe@postecert.it",
      "telefono": "+39 049 9319500",
      "fax": "+39 049 9386455",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 12.050000190734863
      }
    },
    {
      "codice": "028094",
      "nome": "Tribano",
      "nomeStraniero": null,
      "codiceCatastale": "L414",
      "cap": "35020",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.tribano.pd.it",
      "pec": "tribano.pd@cert.ip-veneto.net",
      "telefono": "+39 049 5342004",
      "fax": "+39 049 9585160",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "028095",
      "nome": "Urbana",
      "nomeStraniero": null,
      "codiceCatastale": "L497",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria@comuneurbana.net",
      "pec": "urbana.pd@cert.ip-veneto.net",
      "telefono": "+39 0429.879010",
      "fax": "+39 0429 878997",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "028096",
      "nome": "Veggiano",
      "nomeStraniero": null,
      "codiceCatastale": "L710",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.veggiano.pd.it",
      "pec": "veggiano.pd@cert.ip-veneto.net",
      "telefono": "+39 049 5089005",
      "fax": "+39 049 5089025",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.716666221618652
      }
    },
    {
      "codice": "028097",
      "nome": "Vescovana",
      "nomeStraniero": null,
      "codiceCatastale": "L805",
      "cap": "35040",
      "prefisso": "0425",
      "provincia": "Padova",
      "email": "segreteria@comune.vescovana.pd.it",
      "pec": "vescovana.pd@cert.ip-veneto.net",
      "telefono": "+39 0425-450018",
      "fax": "+39 0425 -920275",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 11.716666221618652
      }
    },
    {
      "codice": "028098",
      "nome": "Vighizzolo d'Este",
      "nomeStraniero": null,
      "codiceCatastale": "L878",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "protocollo@comune.vighizzolo.pd.it",
      "pec": "vighizzolo.pd@legalmailpa.it",
      "telefono": "+39 0429 99044",
      "fax": "+39 0429 99219",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.633333206176758
      }
    },
    {
      "codice": "028099",
      "nome": "Vigodarzere",
      "nomeStraniero": null,
      "codiceCatastale": "L892",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "protocollo@vigodarzerenet.it",
      "pec": "vigodarzere.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8888311",
      "fax": "+39 049 8871459",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "028100",
      "nome": "Vigonza",
      "nomeStraniero": null,
      "codiceCatastale": "L900",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "uff.elettorale@comune.vigonza.pd.it",
      "pec": "vigonza.pd@cert.ip-veneto.net",
      "telefono": "+39 049 8090211",
      "fax": "+39 049 8090200",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.983333587646484
      }
    },
    {
      "codice": "028101",
      "nome": "Villa del Conte",
      "nomeStraniero": null,
      "codiceCatastale": "L934",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segretario@comune.villa-del-conte.pd.it",
      "pec": "protocollo@comune.villa-del-conte.pd.it",
      "telefono": "+39 049.9394853",
      "fax": "+39 049.9394883",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "028102",
      "nome": "Villa Estense",
      "nomeStraniero": null,
      "codiceCatastale": "L937",
      "cap": "35040",
      "prefisso": "0429",
      "provincia": "Padova",
      "email": "segreteria@comune.villaestense.pd.it",
      "pec": "villaestense.pd@legalmailpa.it",
      "telefono": "+39 0429 91154",
      "fax": "+39 0429 91155",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "028103",
      "nome": "Villafranca Padovana",
      "nomeStraniero": null,
      "codiceCatastale": "L947",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "segreteria@comune.villafranca.pd.it",
      "pec": "villafrancapadovana.pd@cert.ip-veneto.net",
      "telefono": "+39 049 9098911",
      "fax": "+39 049 9098943",
      "coordinate": {
        "lat": 45.5,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "028104",
      "nome": "Villanova di Camposampiero",
      "nomeStraniero": null,
      "codiceCatastale": "L979",
      "cap": "35010",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "urp@comune.villanova.pd.it",
      "pec": "comune.villanovadicamposampiero.pd@pecveneto.it",
      "telefono": "+39 049.9222111",
      "fax": "+39 049.9222105",
      "coordinate": {
        "lat": 45.48889923095703,
        "lng": 11.974710464477539
      }
    },
    {
      "codice": "028105",
      "nome": "Vo'",
      "nomeStraniero": null,
      "codiceCatastale": "M103",
      "cap": "35030",
      "prefisso": "049",
      "provincia": "Padova",
      "email": "anagrafe@comune.vo.pd.it",
      "pec": "comune.vo.pd@pecveneto.it",
      "telefono": "+39 049 9940027",
      "fax": "+39 049 9940666",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 11.633333206176758
      }
    }
  ],
  "Lodi": [
    {
      "codice": "098001",
      "nome": "Abbadia Cerreto",
      "nomeStraniero": null,
      "codiceCatastale": "A004",
      "cap": "26834",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "abbadiacerreto@gmail.com",
      "pec": "comune.abbadiacerreto@pec.regione.lombardia.it",
      "telefono": "+39 0371/72235",
      "fax": "+39 0371/471705",
      "coordinate": {
        "lat": 45.31305694580078,
        "lng": 9.595277786254883
      }
    },
    {
      "codice": "098002",
      "nome": "Bertonico",
      "nomeStraniero": null,
      "codiceCatastale": "A811",
      "cap": "26821",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "ragioneria@comune.bertonico.lo.it",
      "pec": "comune.bertonico@pec.regione.lombardia.it",
      "telefono": "+39 0377/85002",
      "fax": "+39 0377/85084",
      "coordinate": {
        "lat": 45.233890533447266,
        "lng": 9.669166564941406
      }
    },
    {
      "codice": "098003",
      "nome": "Boffalora d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "A919",
      "cap": "26811",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.boffaloradadda.lo.it",
      "pec": "comune.boffaloradadda@pec.regione.lombardia.it",
      "telefono": "+39 0371/60013",
      "fax": "+39 0371/609607",
      "coordinate": {
        "lat": 45.35965347290039,
        "lng": 9.49438190460205
      }
    },
    {
      "codice": "098004",
      "nome": "Borghetto Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "A995",
      "cap": "26812",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "demografici@comuneborghetto.lo.it",
      "pec": "borghettolodigiano@cert.elaus2002.net",
      "telefono": "+39 0371/26011",
      "fax": "+39 0371/269016",
      "coordinate": {
        "lat": 45.2158317565918,
        "lng": 9.500277519226074
      }
    },
    {
      "codice": "098005",
      "nome": "Borgo San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "B017",
      "cap": "26851",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "comunediborgosangiovanni@tin.it",
      "pec": "comune.borgosangiovanni@pec.regione.lombardia.it",
      "telefono": "+39 0371/97004",
      "fax": "+39 0371/97191",
      "coordinate": {
        "lat": 45.27888870239258,
        "lng": 9.436111450195312
      }
    },
    {
      "codice": "098006",
      "nome": "Brembio",
      "nomeStraniero": null,
      "codiceCatastale": "B141",
      "cap": "26822",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.brembio.lo.it",
      "pec": "brembio@cert.elaus2002.net",
      "telefono": "+39 0377/98901",
      "fax": null,
      "coordinate": {
        "lat": 45.21555709838867,
        "lng": 9.572500228881836
      }
    },
    {
      "codice": "098008",
      "nome": "Casaletto Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "B887",
      "cap": "26852",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "sindaco@comune.casalettolodigiano.lo.it",
      "pec": "comune.casalettolodigiano@pec.regione.lombardia.it",
      "telefono": "+39 0371/71542",
      "fax": "+39 0371/71795",
      "coordinate": {
        "lat": 45.2944450378418,
        "lng": 9.363055229187012
      }
    },
    {
      "codice": "098009",
      "nome": "Casalmaiocco",
      "nomeStraniero": null,
      "codiceCatastale": "B899",
      "cap": "26831",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "protocollo@comune.casalmaiocco.lo.it",
      "pec": "comune.casalmaiocco@pec.it",
      "telefono": "+39 02/9810092",
      "fax": "+39 02/9810667",
      "coordinate": {
        "lat": 45.35472106933594,
        "lng": 9.375277519226074
      }
    },
    {
      "codice": "098010",
      "nome": "Casalpusterlengo",
      "nomeStraniero": null,
      "codiceCatastale": "B910",
      "cap": "26841",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.casalpusterlengo.lo.it",
      "pec": "casalpusterlengo@cert.elaus2002.net",
      "telefono": "+39 0377/92331",
      "fax": "+39 0377/84027",
      "coordinate": {
        "lat": 45.17777633666992,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "098011",
      "nome": "Caselle Landi",
      "nomeStraniero": null,
      "codiceCatastale": "B961",
      "cap": "26842",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comunedicasellelandi.it",
      "pec": "info@pec.comune.casellelandi.lo.it",
      "telefono": "+39 0377/69901",
      "fax": "+39 0377/69832",
      "coordinate": {
        "lat": 45.10361099243164,
        "lng": 9.796667098999023
      }
    },
    {
      "codice": "098012",
      "nome": "Caselle Lurani",
      "nomeStraniero": null,
      "codiceCatastale": "B958",
      "cap": "26853",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "demografici@comune.casellelurani.lo.it",
      "pec": "info@pec.comune.casellelurani.lo.it",
      "telefono": "+39 0371/96019",
      "fax": "+39 0371/96079",
      "coordinate": {
        "lat": 45.28138732910156,
        "lng": 9.360555648803711
      }
    },
    {
      "codice": "098062",
      "nome": "Castelgerundo",
      "nomeStraniero": null,
      "codiceCatastale": "M393",
      "cap": "26844",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "demografici@comune.castelgerundo.lo.it",
      "pec": "castelgerundo@pec.it",
      "telefono": "0377778027",
      "fax": "",
      "coordinate": {
        "lat": 45.20163345336914,
        "lng": 9.741095542907715
      }
    },
    {
      "codice": "098013",
      "nome": "Castelnuovo Bocca d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "C228",
      "cap": "26843",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "elettorale@comune.castelnuovoboccadadda.lo.it",
      "pec": "casella@pec.comune.castelnuovoboccadadda.lo.it",
      "telefono": "0377700015",
      "fax": "0377702098",
      "coordinate": {
        "lat": 45.11166763305664,
        "lng": 9.86472225189209
      }
    },
    {
      "codice": "098014",
      "nome": "Castiglione d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "C304",
      "cap": "26823",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "demografici@comune.castiglionedadda.lo.it",
      "pec": "castiglionedadda@cert.elaus2002.net",
      "telefono": "+39 0377/900403",
      "fax": "+39 0377/901493",
      "coordinate": {
        "lat": 45.219722747802734,
        "lng": 9.69527816772461
      }
    },
    {
      "codice": "098015",
      "nome": "Castiraga Vidardo",
      "nomeStraniero": null,
      "codiceCatastale": "C329",
      "cap": "26866",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "amministrazione@comune.castiragavidardo.lo.it",
      "pec": "comune.castiragavidardo@pec.regione.lombardia.it",
      "telefono": "+39 0371/90404",
      "fax": "+39 0371/210283",
      "coordinate": {
        "lat": 45.26256561279297,
        "lng": 9.399490356445312
      }
    },
    {
      "codice": "098017",
      "nome": "Cavenago d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "C394",
      "cap": "26824",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.cavenagodadda.lo.it",
      "pec": "comune.cavenagodadda@pec.regione.lombardia.it",
      "telefono": "+39 0371/70031",
      "fax": "+39 0371/70469",
      "coordinate": {
        "lat": 45.2852783203125,
        "lng": 9.602222442626953
      }
    },
    {
      "codice": "098018",
      "nome": "Cervignano d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "C555",
      "cap": "26832",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "anagrafe@comune.cervignanodadda.lo.it",
      "pec": "cervignanodadda@cert.elaus2002.net",
      "telefono": "+39 029813002",
      "fax": "+39 0298850125",
      "coordinate": {
        "lat": 45.37472152709961,
        "lng": 9.425000190734863
      }
    },
    {
      "codice": "098019",
      "nome": "Codogno",
      "nomeStraniero": null,
      "codiceCatastale": "C816",
      "cap": "26845",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@pec.comune.codogno.lo.it",
      "pec": "protocollo@pec.comune.codogno.lo.it",
      "telefono": "+39 0377 3141",
      "fax": "+39 0377/35646",
      "coordinate": {
        "lat": 45.15999984741211,
        "lng": 9.704999923706055
      }
    },
    {
      "codice": "098020",
      "nome": "Comazzo",
      "nomeStraniero": null,
      "codiceCatastale": "C917",
      "cap": "26833",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "segreteria@comunedicomazzo.it",
      "pec": "comune.comazzo@pec.regione.lombardia.it",
      "telefono": "+39 02/9061015",
      "fax": "+39 02/9061280",
      "coordinate": {
        "lat": 45.44194412231445,
        "lng": 9.46583366394043
      }
    },
    {
      "codice": "098021",
      "nome": "Cornegliano Laudense",
      "nomeStraniero": null,
      "codiceCatastale": "D021",
      "cap": "26854",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "servizi-demografici@comune.cornegliano-laudense.lo.it",
      "pec": "corneglianolaudense@cert.elaus2002.net",
      "telefono": "+39 0371/69079",
      "fax": "+39 0371/483075",
      "coordinate": {
        "lat": 45.28722381591797,
        "lng": 9.48888874053955
      }
    },
    {
      "codice": "098022",
      "nome": "Corno Giovine",
      "nomeStraniero": null,
      "codiceCatastale": "D028",
      "cap": "26846",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comunecornogiovine.it",
      "pec": "comune.cornogiovine@pec.regione.lombardia.it",
      "telefono": "+39 0377/67715",
      "fax": "+39 0377/67798",
      "coordinate": {
        "lat": 45.13444519042969,
        "lng": 9.760833740234375
      }
    },
    {
      "codice": "098023",
      "nome": "Cornovecchio",
      "nomeStraniero": null,
      "codiceCatastale": "D029",
      "cap": "26842",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.cornovecchio.lo.it",
      "pec": "comune.cornovecchio@pec.regione.lombardia.it",
      "telefono": "+39 0377/709910",
      "fax": "+39 0377/709851",
      "coordinate": {
        "lat": 45.13750076293945,
        "lng": 9.800277709960938
      }
    },
    {
      "codice": "098024",
      "nome": "Corte Palasio",
      "nomeStraniero": null,
      "codiceCatastale": "D068",
      "cap": "26834",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "info@comune.cortepalasio.it",
      "pec": "comune.cortepalasio@pec.regione.lombardia.it",
      "telefono": "+39 0371/72231",
      "fax": "+39 0371/472000",
      "coordinate": {
        "lat": 45.309165954589844,
        "lng": 9.563333511352539
      }
    },
    {
      "codice": "098025",
      "nome": "Crespiatica",
      "nomeStraniero": null,
      "codiceCatastale": "D159",
      "cap": "26835",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "demografici@comune.crespiatica.lo.it",
      "pec": "comune.crespiatica@pec.regione.lombardia.it",
      "telefono": "+39 0371/484109",
      "fax": "+39 0371/484591",
      "coordinate": {
        "lat": 45.35749816894531,
        "lng": 9.57611083984375
      }
    },
    {
      "codice": "098026",
      "nome": "Fombio",
      "nomeStraniero": null,
      "codiceCatastale": "D660",
      "cap": "26861",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "ufficiodemografico@comune.fombio.lo.it",
      "pec": "comune.fombio@pec.regione.lombardia.it",
      "telefono": "+39 037732362",
      "fax": "+39 0377/430422",
      "coordinate": {
        "lat": 45.141387939453125,
        "lng": 9.688888549804688
      }
    },
    {
      "codice": "098027",
      "nome": "Galgagnano",
      "nomeStraniero": null,
      "codiceCatastale": "D868",
      "cap": "26832",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "galgagnano@comune.galgagnano.lo.it",
      "pec": "comune.galgagnano@pec.regione.lombardia.it",
      "telefono": "+39 0371/68261",
      "fax": "+39 0371/68322",
      "coordinate": {
        "lat": 45.358890533447266,
        "lng": 9.446389198303223
      }
    },
    {
      "codice": "098028",
      "nome": "Graffignana",
      "nomeStraniero": null,
      "codiceCatastale": "E127",
      "cap": "26813",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "anagrafestatocivile@comunedigraffignana.it",
      "pec": "comune.graffignana@pec.regione.lombardia.it",
      "telefono": "+39 0371/88828",
      "fax": "+39 0371/88690",
      "coordinate": {
        "lat": 45.20750045776367,
        "lng": 9.455277442932129
      }
    },
    {
      "codice": "098029",
      "nome": "Guardamiglio",
      "nomeStraniero": null,
      "codiceCatastale": "E238",
      "cap": "26862",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "demograficiprotocollo@virgilio.it",
      "pec": "info@pec.comune.guardamiglio.lo.it",
      "telefono": "+39 0377/51002",
      "fax": "+39 0377/51338",
      "coordinate": {
        "lat": 45.108612060546875,
        "lng": 9.682499885559082
      }
    },
    {
      "codice": "098030",
      "nome": "Livraga",
      "nomeStraniero": null,
      "codiceCatastale": "E627",
      "cap": "26814",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.livraga.lo.it",
      "pec": "comune.livraga@pec.it",
      "telefono": "+39 0377/87702",
      "fax": "+39 0377/87691",
      "coordinate": {
        "lat": 45.19333267211914,
        "lng": 9.547499656677246
      }
    },
    {
      "codice": "098031",
      "nome": "Lodi",
      "nomeStraniero": null,
      "codiceCatastale": "E648",
      "cap": "26900",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.lodi.it",
      "pec": "comunedilodi@legalmail.it",
      "telefono": "+39 0371 4091",
      "fax": "+39 0371/409448",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.5
      }
    },
    {
      "codice": "098032",
      "nome": "Lodi Vecchio",
      "nomeStraniero": null,
      "codiceCatastale": "E651",
      "cap": "26855",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "info@comune.lodivecchio.lo.it",
      "pec": "comune.lodivecchio@pec.regione.lombardia.it",
      "telefono": "+39 0371/40501",
      "fax": "+39 0371/754351",
      "coordinate": {
        "lat": 45.3033332824707,
        "lng": 9.418611526489258
      }
    },
    {
      "codice": "098033",
      "nome": "Maccastorna",
      "nomeStraniero": null,
      "codiceCatastale": "E777",
      "cap": "26943",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comune.maccastorna.lo.it",
      "pec": "comune.maccastorna@pec.regione.lombardia.it",
      "telefono": "+39 0377/60634",
      "fax": "+39 0377/60634",
      "coordinate": {
        "lat": 45.147499084472656,
        "lng": 9.855555534362793
      }
    },
    {
      "codice": "098034",
      "nome": "Mairago",
      "nomeStraniero": null,
      "codiceCatastale": "E840",
      "cap": "26825",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.mairago.lo.it",
      "pec": "mairago@cert.elaus2002.net",
      "telefono": "+39 0371/487001",
      "fax": "+39 0371/487474",
      "coordinate": {
        "lat": 45.252777099609375,
        "lng": 9.579444885253906
      }
    },
    {
      "codice": "098035",
      "nome": "Maleo",
      "nomeStraniero": null,
      "codiceCatastale": "E852",
      "cap": "26847",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.maleo.lo.it",
      "pec": "comune.maleo@pec.regione.lombardia.it",
      "telefono": "+39 0377/58001",
      "fax": "+39 0377/458113",
      "coordinate": {
        "lat": 45.1683349609375,
        "lng": 9.762499809265137
      }
    },
    {
      "codice": "098036",
      "nome": "Marudo",
      "nomeStraniero": null,
      "codiceCatastale": "E994",
      "cap": "26866",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "segreteria@comune.marudo.lo.it",
      "pec": "comune.marudo@pec.regione.lombardia.it",
      "telefono": "+39 0371-218089",
      "fax": "+39 0371-90291",
      "coordinate": {
        "lat": 45.25388717651367,
        "lng": 9.37833309173584
      }
    },
    {
      "codice": "098037",
      "nome": "Massalengo",
      "nomeStraniero": null,
      "codiceCatastale": "F028",
      "cap": "26815",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "demografici@comune.massalengo.lo.it",
      "pec": "massalengo@cert.elaus2002.net",
      "telefono": "+39 0371/480775",
      "fax": "+39 0371/480046",
      "coordinate": {
        "lat": 45.26583480834961,
        "lng": 9.491110801696777
      }
    },
    {
      "codice": "098038",
      "nome": "Meleti",
      "nomeStraniero": null,
      "codiceCatastale": "F102",
      "cap": "26843",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "anagrafe@comune.meleti.lo.it",
      "pec": "comune.meleti@pec.regione.lombardia.it",
      "telefono": "+39 0377/700412",
      "fax": "+39 0377/700412",
      "coordinate": {
        "lat": 45.119720458984375,
        "lng": 9.838055610656738
      }
    },
    {
      "codice": "098039",
      "nome": "Merlino",
      "nomeStraniero": null,
      "codiceCatastale": "F149",
      "cap": "26833",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "protocollo@comune.merlino.lo.it",
      "pec": "comune.merlino@pec.regione.lombardia.it",
      "telefono": "+39 02/90658283",
      "fax": "+39 02/90665294",
      "coordinate": {
        "lat": 45.434165954589844,
        "lng": 9.430832862854004
      }
    },
    {
      "codice": "098040",
      "nome": "Montanaso Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "F423",
      "cap": "26836",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "demografici@comune.montanaso.lo.it",
      "pec": "comune.montanasolombardo@pec.regione.lombardia.it",
      "telefono": "+39 0371/48681",
      "fax": "+39 0371/4868222",
      "coordinate": {
        "lat": 45.336666107177734,
        "lng": 9.469721794128418
      }
    },
    {
      "codice": "098041",
      "nome": "Mulazzano",
      "nomeStraniero": null,
      "codiceCatastale": "F801",
      "cap": "26837",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "amministrativo@comune.mulazzano.lo.it",
      "pec": "mulazzanocomune@postemailcertificata.it",
      "telefono": "+39 02/9888901",
      "fax": "+39 02/98889030",
      "coordinate": {
        "lat": 45.37349319458008,
        "lng": 9.397993087768555
      }
    },
    {
      "codice": "098042",
      "nome": "Orio Litta",
      "nomeStraniero": null,
      "codiceCatastale": "G107",
      "cap": "26863",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "segreteria@comune.oriolitta.lo.it",
      "pec": "sindaco.comune.oriolitta@pec.regione.lombardia.it",
      "telefono": "+39 0377/944425",
      "fax": "+39 0377/804370",
      "coordinate": {
        "lat": 45.160831451416016,
        "lng": 9.55555534362793
      }
    },
    {
      "codice": "098043",
      "nome": "Ospedaletto Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "G166",
      "cap": "26864",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "anagrafe@comune.ospedalettolodigiano.lo.it",
      "pec": "comune.ospedalettolodigiano@pec.regione.lombardia.it",
      "telefono": "+39 0377/86601",
      "fax": "+39 0377/86833",
      "coordinate": {
        "lat": 45.16972351074219,
        "lng": 9.57972240447998
      }
    },
    {
      "codice": "098044",
      "nome": "Ossago Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "G171",
      "cap": "26816",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.ossagolodigiano.lo.it",
      "pec": "comune.ossagolodigiano@pec.regione.lombardia.it",
      "telefono": "+39 0371 28681",
      "fax": "+39 0371/871035",
      "coordinate": {
        "lat": 45.246665954589844,
        "lng": 9.538055419921875
      }
    },
    {
      "codice": "098045",
      "nome": "Pieve Fissiraga",
      "nomeStraniero": null,
      "codiceCatastale": "G096",
      "cap": "26854",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.pieve-fissiraga.lo.it",
      "pec": "pievefissiraga@cert.elaus2002.net",
      "telefono": "+39 037198000",
      "fax": "+39 037198355",
      "coordinate": {
        "lat": 45.26416778564453,
        "lng": 9.459444046020508
      }
    },
    {
      "codice": "098046",
      "nome": "Salerano sul Lambro",
      "nomeStraniero": null,
      "codiceCatastale": "H701",
      "cap": "26857",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "info@comune.saleranosullambro.lo.it",
      "pec": "comune.saleranosullambro@pec.regione.lombardia.it",
      "telefono": "+39 0371/71391",
      "fax": "+39 0371/71184",
      "coordinate": {
        "lat": 45.29750061035156,
        "lng": 9.38611125946045
      }
    },
    {
      "codice": "098047",
      "nome": "San Fiorano",
      "nomeStraniero": null,
      "codiceCatastale": "H844",
      "cap": "26848",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comune.sanfiorano.lo.it",
      "pec": "comune.sanfiorano@pec.regione.lombardia.it",
      "telefono": "+39 0377/53720",
      "fax": "+39 0377/53480",
      "coordinate": {
        "lat": 45.13722229003906,
        "lng": 9.723333358764648
      }
    },
    {
      "codice": "098048",
      "nome": "San Martino in Strada",
      "nomeStraniero": null,
      "codiceCatastale": "I012",
      "cap": "26817",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "protocollo@comune.sanmartinoinstrada.lo.it",
      "pec": "sanmartinoinstrada@cert.elaus2002.net",
      "telefono": "+39 0371/449820",
      "fax": "+39 0371/475077",
      "coordinate": {
        "lat": 45.27583312988281,
        "lng": 9.52750015258789
      }
    },
    {
      "codice": "098049",
      "nome": "San Rocco al Porto",
      "nomeStraniero": null,
      "codiceCatastale": "I140",
      "cap": "26865",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comune.san-rocco-al-porto.lo.it",
      "pec": "comune.sanroccoalporto@pec.regione.lombardia.it",
      "telefono": "+39 0377/454511",
      "fax": "+39 0377/569519",
      "coordinate": {
        "lat": 45.08290100097656,
        "lng": 9.698200225830078
      }
    },
    {
      "codice": "098050",
      "nome": "Sant'Angelo Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "I274",
      "cap": "26866",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "demografici@comune.santangelolodigiano.lo.it",
      "pec": "comune.santangelolodigiano@pec.regione.lombardia.it",
      "telefono": "+39 037125011",
      "fax": "+39 0371/250152",
      "coordinate": {
        "lat": 45.238887786865234,
        "lng": 9.409722328186035
      }
    },
    {
      "codice": "098051",
      "nome": "Santo Stefano Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "I362",
      "cap": "26849",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "demografici@comune.santostefanolodigiano.lo.it",
      "pec": "info@pec.comune.santostefanolodigiano.lo.it",
      "telefono": "+39 0377/66001",
      "fax": "+39 0377/66277",
      "coordinate": {
        "lat": 45.11944580078125,
        "lng": 9.73638916015625
      }
    },
    {
      "codice": "098052",
      "nome": "Secugnago",
      "nomeStraniero": null,
      "codiceCatastale": "I561",
      "cap": "26826",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comune.secugnago.lo.it",
      "pec": "comune.secugnago@pec.regione.lombardia.it",
      "telefono": "+39 0377/807005",
      "fax": "+39 0377/807606",
      "coordinate": {
        "lat": 45.233890533447266,
        "lng": 9.594444274902344
      }
    },
    {
      "codice": "098053",
      "nome": "Senna Lodigiana",
      "nomeStraniero": null,
      "codiceCatastale": "I612",
      "cap": "26856",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.sennalodigiana.lo.it",
      "pec": "comune.sennalodigiana@pec.regione.lombardia.it",
      "telefono": "+39 0377/802900",
      "fax": "+39 0377/802253",
      "coordinate": {
        "lat": 45.15250015258789,
        "lng": 9.595000267028809
      }
    },
    {
      "codice": "098054",
      "nome": "Somaglia",
      "nomeStraniero": null,
      "codiceCatastale": "I815",
      "cap": "26867",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "info@comune.somaglia.lo.it",
      "pec": "sindaco.comune.somaglia@pec.regione.lombardia.it",
      "telefono": "+39 0377/57901",
      "fax": "+39 0377/5790215",
      "coordinate": {
        "lat": 45.15027618408203,
        "lng": 9.634444236755371
      }
    },
    {
      "codice": "098055",
      "nome": "Sordio",
      "nomeStraniero": null,
      "codiceCatastale": "I848",
      "cap": "26858",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "info@comune.sordio.lo.it",
      "pec": "comune.sordio@pec.regione.lombardia.it",
      "telefono": "+39 02/9810008",
      "fax": "+39 02/98260173",
      "coordinate": {
        "lat": 45.343055725097656,
        "lng": 9.364999771118164
      }
    },
    {
      "codice": "098056",
      "nome": "Tavazzano con Villavesco",
      "nomeStraniero": null,
      "codiceCatastale": "F260",
      "cap": "26838",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "urp@comune.tavazzano.lo.it",
      "pec": "tavazzano@cert.elaus2002.net",
      "telefono": "+39 0371 40461",
      "fax": "+39 0371 470076",
      "coordinate": {
        "lat": 45.32833480834961,
        "lng": 9.407500267028809
      }
    },
    {
      "codice": "098057",
      "nome": "Terranova dei Passerini",
      "nomeStraniero": null,
      "codiceCatastale": "L125",
      "cap": "26827",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "protocollo@comune.terranovadeipasserini.lo.it",
      "pec": "protocollo@pec.comune.terranovadeipasserini.lo.it",
      "telefono": "+39 0377.833587",
      "fax": "+39 0377.84213",
      "coordinate": {
        "lat": 45.19950866699219,
        "lng": 9.675980567932129
      }
    },
    {
      "codice": "098058",
      "nome": "Turano Lodigiano",
      "nomeStraniero": null,
      "codiceCatastale": "L469",
      "cap": "26828",
      "prefisso": "0377",
      "provincia": "Lodi",
      "email": "anagrafe@comune.turanolodigiano.lo.it",
      "pec": "comune.turanolodigiano@pec.regione.lombardia.it",
      "telefono": "+39 0377 948302",
      "fax": "+39 0377 948005",
      "coordinate": {
        "lat": 45.24972152709961,
        "lng": 9.6225004196167
      }
    },
    {
      "codice": "098059",
      "nome": "Valera Fratta",
      "nomeStraniero": null,
      "codiceCatastale": "L572",
      "cap": "26859",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "comunevalerafratta@virgilio.it",
      "pec": "valerafratta@cert.elaus2002.net",
      "telefono": "+39 0371/99000",
      "fax": "+39 0371/99348",
      "coordinate": {
        "lat": 45.258056640625,
        "lng": 9.3372220993042
      }
    },
    {
      "codice": "098060",
      "nome": "Villanova del Sillaro",
      "nomeStraniero": null,
      "codiceCatastale": "L977",
      "cap": "26818",
      "prefisso": "0371",
      "provincia": "Lodi",
      "email": "info@comune.villanova.lo.it",
      "pec": "comune.villanovadelsillaro@pec.regione.lombardia.it",
      "telefono": "+39 0371 230021",
      "fax": "+39 0371 230002",
      "coordinate": {
        "lat": 45.238887786865234,
        "lng": 9.48277759552002
      }
    },
    {
      "codice": "098061",
      "nome": "Zelo Buon Persico",
      "nomeStraniero": null,
      "codiceCatastale": "M158",
      "cap": "26839",
      "prefisso": "02",
      "provincia": "Lodi",
      "email": "protocollo@comune.zelo.lo.it",
      "pec": "comune@pec.comune.zelo.lo.it",
      "telefono": "+39 02/9062671",
      "fax": "+39 02/90658930",
      "coordinate": {
        "lat": 45.413333892822266,
        "lng": 9.433610916137695
      }
    }
  ],
  "Lecco": [
    {
      "codice": "097001",
      "nome": "Abbadia Lariana",
      "nomeStraniero": null,
      "codiceCatastale": "A005",
      "cap": "23821",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@abbadialariana.com",
      "pec": "comune.abbadialariana@legalmail.it",
      "telefono": "+39 0341-731241",
      "fax": "+39 0341-1881038",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "097002",
      "nome": "Airuno",
      "nomeStraniero": null,
      "codiceCatastale": "A112",
      "cap": "23881",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@comune.airuno.lc.it",
      "pec": "airuno@cert.legalmail.it",
      "telefono": "+39 0399943222",
      "fax": "+39 0399943429",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "097003",
      "nome": "Annone di Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "A301",
      "cap": "23841",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.annone-di-brianza.lc.it",
      "pec": "comune.annonedibrianza@pec.regione.lombardia.it",
      "telefono": "+39 0341576063",
      "fax": "+39 0341260361",
      "coordinate": {
        "lat": 45.803890228271484,
        "lng": 9.331666946411133
      }
    },
    {
      "codice": "097004",
      "nome": "Ballabio",
      "nomeStraniero": null,
      "codiceCatastale": "A594",
      "cap": "23811",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.ballabio.lc.it",
      "pec": "comune.ballabio@legalmail.it",
      "telefono": "+39 0341/530111",
      "fax": "+39 0341/531057",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "097005",
      "nome": "Barzago",
      "nomeStraniero": null,
      "codiceCatastale": "A683",
      "cap": "23890",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "uffici@comune.barzago.lc.it",
      "pec": "barzago@cert.legalmail.it",
      "telefono": "+39 031/860227",
      "fax": "+39 031/874215",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097006",
      "nome": "Barzanò",
      "nomeStraniero": null,
      "codiceCatastale": "A686",
      "cap": "23891",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@comune.barzano.lc.it",
      "pec": "protocollo@cert.comune.barzano.lc.it",
      "telefono": "+39 039/921301",
      "fax": "+39 039/9211125",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097007",
      "nome": "Barzio",
      "nomeStraniero": null,
      "codiceCatastale": "A687",
      "cap": "23816",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.barzio.lc.it",
      "pec": "comune.barzio.lc@halleycert.it",
      "telefono": "+39 0341 996125",
      "fax": "+39 0341 999161",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "097008",
      "nome": "Bellano",
      "nomeStraniero": null,
      "codiceCatastale": "A745",
      "cap": "23822",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.bellano.lc.it",
      "pec": "comune-bellano@legalmail.it",
      "telefono": "+39 0341 821124",
      "fax": "+39 0341 820850",
      "coordinate": {
        "lat": 46.0432014465332,
        "lng": 9.306099891662598
      }
    },
    {
      "codice": "097009",
      "nome": "Bosisio Parini",
      "nomeStraniero": null,
      "codiceCatastale": "B081",
      "cap": "23842",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "segreteria@comune.bosisioparini.lc.it",
      "pec": "protocollo_bosisioparini@halleypec.it",
      "telefono": "+39 031/3580511",
      "fax": "+39 031/3580522",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "097010",
      "nome": "Brivio",
      "nomeStraniero": null,
      "codiceCatastale": "B194",
      "cap": "23883",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "segreteria@comune.brivio.lc.it",
      "pec": "comune.brivio.lc@legalmail.it",
      "telefono": "+39 039 5320114",
      "fax": "+39 039 5321385",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "097011",
      "nome": "Bulciago",
      "nomeStraniero": null,
      "codiceCatastale": "B261",
      "cap": "23892",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "info@comune.bulciago.lc.it",
      "pec": "comune.bulciago@legalmail.it",
      "telefono": "+39 031/860121",
      "fax": "+39 031/874100",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "097012",
      "nome": "Calco",
      "nomeStraniero": null,
      "codiceCatastale": "B396",
      "cap": "23885",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@comune.calco.lc.it",
      "pec": "calco@legalmail.it",
      "telefono": "+39 039/9910017",
      "fax": "+39 039/9288232",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "097013",
      "nome": "Calolziocorte",
      "nomeStraniero": null,
      "codiceCatastale": "B423",
      "cap": "23801",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "demografici@comune.calolziocorte.lc.it",
      "pec": "calolziocorte@legalmail.it",
      "telefono": "0341639111",
      "fax": "0341639259",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.433333396911621
      }
    },
    {
      "codice": "097014",
      "nome": "Carenno",
      "nomeStraniero": null,
      "codiceCatastale": "B763",
      "cap": "23802",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "carenno@tin.it",
      "pec": "comune.carenno@pec.regione.lombardia.it",
      "telefono": "+39 0341 610220",
      "fax": "+39 0341 610165",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "097015",
      "nome": "Casargo",
      "nomeStraniero": null,
      "codiceCatastale": "B937",
      "cap": "23831",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "anagrafe@comune.casargo.lc.it",
      "pec": "comune.casargo@pec.regione.lombardia.it",
      "telefono": "+39 0341/840123",
      "fax": "+39 0341/840673",
      "coordinate": {
        "lat": 46.040279388427734,
        "lng": 9.388686180114746
      }
    },
    {
      "codice": "097016",
      "nome": "Casatenovo",
      "nomeStraniero": null,
      "codiceCatastale": "B943",
      "cap": "23880",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "ufficio.protocollo@comune.casatenovo.lc.it",
      "pec": "protocollo.casatenovo@legalmail.it",
      "telefono": "+39 039/92351",
      "fax": "+39 039/9207098",
      "coordinate": {
        "lat": 45.698333740234375,
        "lng": 9.311666488647461
      }
    },
    {
      "codice": "097017",
      "nome": "Cassago Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "B996",
      "cap": "23893",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@comune.cassago.lc.it",
      "pec": "comune.cassagobrianza@pec.regione.lombardia.it",
      "telefono": "+39 039-921321",
      "fax": "+39 039-9211009",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "097018",
      "nome": "Cassina Valsassina",
      "nomeStraniero": null,
      "codiceCatastale": "C024",
      "cap": "22040",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.cassinavalsassina.lc.it",
      "pec": "comune.cassinavalsassina@pec.regione.lombardia.it",
      "telefono": "+39 0341996181",
      "fax": "+39 0341910190",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "097019",
      "nome": "Castello di Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "C187",
      "cap": "23884",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@comune.castellobrianza.lc.it",
      "pec": "ufficioprotocollo@comune.castellodibrianza.legalmail.it",
      "telefono": "+39 039 5310312",
      "fax": "+39 039 5311341",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "097020",
      "nome": "Cernusco Lombardone",
      "nomeStraniero": null,
      "codiceCatastale": "C521",
      "cap": "23870",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "manuela.sala@cernuscolombardone.lc.it",
      "pec": "comune.cernuscolombardone@legalmail.it",
      "telefono": "+39 039 9902314",
      "fax": "+39 039 9902585",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "097021",
      "nome": "Cesana Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "C563",
      "cap": "23861",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": null,
      "pec": "comune.cesanabrianza@pec.regione.lombardia.it",
      "telefono": "+39 031/655673",
      "fax": "+39 031/655829",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "097022",
      "nome": "Civate",
      "nomeStraniero": null,
      "codiceCatastale": "C752",
      "cap": "23862",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "segreteria@comune.civate.lc.it",
      "pec": "comune.civate.lc@halleycert.it",
      "telefono": "+39 0341/213111",
      "fax": "+39 0341/213350",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "097023",
      "nome": "Colico",
      "nomeStraniero": null,
      "codiceCatastale": "C839",
      "cap": "23823",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "protocollo@comune.colico.lc.it",
      "pec": "colico@cert.legalmail.it",
      "telefono": "+39 0341 934711",
      "fax": "+39 0341 934716",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "097024",
      "nome": "Colle Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "C851",
      "cap": "23886",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@comune.collebrianza.lc.it",
      "pec": "protocollo@comune.collebrianza.legalmail.it",
      "telefono": "+39 9260014",
      "fax": "+39 0399260584",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "097025",
      "nome": "Cortenova",
      "nomeStraniero": null,
      "codiceCatastale": "D065",
      "cap": "23813",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "nucci62ac@gmail.com",
      "pec": "comune.cortenova@legalmail.it",
      "telefono": "3472105842",
      "fax": "+39 0341-901372",
      "coordinate": {
        "lat": 46,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "097026",
      "nome": "Costa Masnaga",
      "nomeStraniero": null,
      "codiceCatastale": "D112",
      "cap": "23845",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "sindaco@comune.costamasnaga.lc.it",
      "pec": "comune.costamasnaga@pec.regione.lombardia.it",
      "telefono": "+39 031358981",
      "fax": "+39 031879022",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "097027",
      "nome": "Crandola Valsassina",
      "nomeStraniero": null,
      "codiceCatastale": "D131",
      "cap": "23832",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "comune.crandola@comune.crandola.lc.it",
      "pec": "comune.crandolavalsassina@pec.regione.lombardia.it",
      "telefono": "+39 0341 840119",
      "fax": "+39 0341 801647",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "097028",
      "nome": "Cremella",
      "nomeStraniero": null,
      "codiceCatastale": "D143",
      "cap": "23894",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@comune.cremella.lc.it",
      "pec": "comune.cremella.lc@halleycert.it",
      "telefono": "+39 039 9211049",
      "fax": "+39 039 955216",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "097029",
      "nome": "Cremeno",
      "nomeStraniero": null,
      "codiceCatastale": "D145",
      "cap": "23814",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.cremeno.lc.it",
      "pec": "comune.cremeno@legalmail.it",
      "telefono": "+39 0341 996113",
      "fax": "+39 0341 910142",
      "coordinate": {
        "lat": 45.93484878540039,
        "lng": 9.473729133605957
      }
    },
    {
      "codice": "097030",
      "nome": "Dervio",
      "nomeStraniero": null,
      "codiceCatastale": "D280",
      "cap": "23824",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.dervio.lc.it",
      "pec": "comune.dervio@pec.regione.lombardia.it",
      "telefono": "+39 0341806411",
      "fax": "+39 0341804111",
      "coordinate": {
        "lat": 46.07638931274414,
        "lng": 9.306666374206543
      }
    },
    {
      "codice": "097031",
      "nome": "Dolzago",
      "nomeStraniero": null,
      "codiceCatastale": "D327",
      "cap": "23843",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "anagrafe@comune.dolzago.lc.it",
      "pec": "dolzago@legalmail.it",
      "telefono": "+39 0341 451263",
      "fax": "+39 0341 451277",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "097032",
      "nome": "Dorio",
      "nomeStraniero": null,
      "codiceCatastale": "D346",
      "cap": "23824",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.dorio.lc.it",
      "pec": "comune.dorio@pec.regione.lombardia.it",
      "telefono": "+39 0341807780",
      "fax": "+39 0341 807809",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097033",
      "nome": "Ello",
      "nomeStraniero": null,
      "codiceCatastale": "D398",
      "cap": "23848",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.ello.lc.it",
      "pec": "ello@postemailcertificata.it",
      "telefono": "+39 0341/576164",
      "fax": "+39 0341/260651",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "097034",
      "nome": "Erve",
      "nomeStraniero": null,
      "codiceCatastale": "D428",
      "cap": "23805",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "erve@comune.erve.lc.it",
      "pec": "comune.erve@pec.regione.lombardia.it",
      "telefono": "+39 0341/607777",
      "fax": "+39 0341/607937",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "097035",
      "nome": "Esino Lario",
      "nomeStraniero": null,
      "codiceCatastale": "D436",
      "cap": "23825",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "esinolar@comune.esinolaio.lc.it",
      "pec": "comune.esinolario@pec.regione.lombardia.it",
      "telefono": "+39 0341 860111",
      "fax": "+39 0341 860374",
      "coordinate": {
        "lat": 45.99388885498047,
        "lng": 9.33305549621582
      }
    },
    {
      "codice": "097036",
      "nome": "Galbiate",
      "nomeStraniero": null,
      "codiceCatastale": "D865",
      "cap": "23851",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "comunegalbiate@comune.galbiate.lc.it",
      "pec": "galbiate@cert.legalmail.it",
      "telefono": "+39 0341 241411",
      "fax": "+39 0341 240377",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "097037",
      "nome": "Garbagnate Monastero",
      "nomeStraniero": null,
      "codiceCatastale": "D913",
      "cap": "23846",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "comune@comune.garbagnate-monastero.lc.it",
      "pec": "comune.garbagnatemonastero.lc@halleypec.it",
      "telefono": "+39 031850032",
      "fax": null,
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "097038",
      "nome": "Garlate",
      "nomeStraniero": null,
      "codiceCatastale": "D926",
      "cap": "23852",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.garlate.lc.it",
      "pec": "comune.garlate.lc@legalmail.it",
      "telefono": "+39 0341-681306",
      "fax": "+39 0341-650222",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "097039",
      "nome": "Imbersago",
      "nomeStraniero": null,
      "codiceCatastale": "E287",
      "cap": "23898",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@comune.imbersago.lc.it",
      "pec": "comune.imbersago.lc@halleypec.it",
      "telefono": "+39 9920198",
      "fax": "+39 9920920",
      "coordinate": {
        "lat": 45.7066650390625,
        "lng": 9.44527816772461
      }
    },
    {
      "codice": "097040",
      "nome": "Introbio",
      "nomeStraniero": null,
      "codiceCatastale": "E305",
      "cap": "23815",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.introbio.lc.it",
      "pec": "introbio@postemailcertificata.it",
      "telefono": "+39 0341980219",
      "fax": "+39 0341981374",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "097092",
      "nome": "La Valletta Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "M348",
      "cap": "23888",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@unionevalletta.it",
      "pec": "comune.lavallettabrianza@pec.regione.lombardia.it",
      "telefono": "+39 5311174",
      "fax": "+39 039 5310005",
      "coordinate": {
        "lat": 45.738609313964844,
        "lng": 9.362222671508789
      }
    },
    {
      "codice": "097042",
      "nome": "Lecco",
      "nomeStraniero": null,
      "codiceCatastale": "E507",
      "cap": "23900",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "protocollo@comune.lecco.it",
      "pec": "comune@pec.comune.lecco.it",
      "telefono": "+39 0341 481111",
      "fax": "+39 0341 286874",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "097043",
      "nome": "Lierna",
      "nomeStraniero": null,
      "codiceCatastale": "E581",
      "cap": "23827",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "segreteria@comune.dilierna.it",
      "pec": "comune.lierna@pec.comune.lierna.lc.it",
      "telefono": "+39 0341/740108",
      "fax": "+39 0341/710093",
      "coordinate": {
        "lat": 45.958099365234375,
        "lng": 9.305100440979004
      }
    },
    {
      "codice": "097044",
      "nome": "Lomagna",
      "nomeStraniero": null,
      "codiceCatastale": "E656",
      "cap": "23871",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "comune@comune.lomagna.lc.it",
      "pec": "comune.lomagna.lc@halleycert.it",
      "telefono": "+39 922511",
      "fax": "+39 9225135",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "097045",
      "nome": "Malgrate",
      "nomeStraniero": null,
      "codiceCatastale": "E858",
      "cap": "23864",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "elettorale@comune.malgrate.lc.it",
      "pec": "comune.malgrate@pec.regione.lombardia.it",
      "telefono": "+39 0341202000",
      "fax": null,
      "coordinate": {
        "lat": 45.84891128540039,
        "lng": 9.376299858093262
      }
    },
    {
      "codice": "097046",
      "nome": "Mandello del Lario",
      "nomeStraniero": null,
      "codiceCatastale": "E879",
      "cap": "23826",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@mandellolario.it",
      "pec": "comune.mandellolario@pec.regione.lombardia.it",
      "telefono": "+39 0341-708111",
      "fax": "+39 0341-700337",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097047",
      "nome": "Margno",
      "nomeStraniero": null,
      "codiceCatastale": "E947",
      "cap": "23832",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "comune.margno@comune.margno.lc.it",
      "pec": "comune.margno@pec.regione.lombardia.it",
      "telefono": "+39 0341 840049",
      "fax": "+39 0341 840791",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "097048",
      "nome": "Merate",
      "nomeStraniero": null,
      "codiceCatastale": "F133",
      "cap": "23807",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@comune.merate.lc.it",
      "pec": "comune.merate@halleypec.it",
      "telefono": "+39 03959151",
      "fax": "+39 0399900683",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.433333396911621
      }
    },
    {
      "codice": "097049",
      "nome": "Missaglia",
      "nomeStraniero": null,
      "codiceCatastale": "F248",
      "cap": "23873",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "comune@comune.missaglia.lc.it",
      "pec": "comune.missaglia@pec.regione.lombardia.it",
      "telefono": "+39 9241232",
      "fax": "+39 9201494",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "097050",
      "nome": "Moggio",
      "nomeStraniero": null,
      "codiceCatastale": "F265",
      "cap": "23817",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.moggio.lc.it",
      "pec": "comune.moggio.lc@halleycert.it",
      "telefono": "+39 0341 996114",
      "fax": "+39 0341 911906",
      "coordinate": {
        "lat": 45.93226623535156,
        "lng": 9.487907409667969
      }
    },
    {
      "codice": "097051",
      "nome": "Molteno",
      "nomeStraniero": null,
      "codiceCatastale": "F304",
      "cap": "23847",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "protocollo@comune.molteno.lc.it",
      "pec": "comunemolteno@halleypec.it",
      "telefono": "+39 0313573811",
      "fax": null,
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "097052",
      "nome": "Monte Marenzo",
      "nomeStraniero": null,
      "codiceCatastale": "F561",
      "cap": "23804",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "uff_segreteria@comune.monte-marenzo.lc.it",
      "pec": "comune.montemarenzo@pec.regione.lombardia.it",
      "telefono": "+39 0341/602211",
      "fax": "+39 0341/603390",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "097053",
      "nome": "Montevecchia",
      "nomeStraniero": null,
      "codiceCatastale": "F657",
      "cap": "23874",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@comune.montevecchia.lc.it",
      "pec": "comune.montevecchia.lc@legalmail.it",
      "telefono": "+39 9930060",
      "fax": "+39 9930935",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "097054",
      "nome": "Monticello Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "F674",
      "cap": "23876",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@monticellobrianza.com",
      "pec": "comune.monticellobrianza@pec.regione.lombardia.it",
      "telefono": "+39 9231611",
      "fax": "+39 0399207058",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097055",
      "nome": "Morterone",
      "nomeStraniero": null,
      "codiceCatastale": "F758",
      "cap": "23811",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "comunemorterone@comune.morterone.lc.it",
      "pec": "comune.morterone@pec.regione.lombardia.it",
      "telefono": "+39 0341/531191",
      "fax": "+39 0341/531191",
      "coordinate": {
        "lat": 45.8738899230957,
        "lng": 9.48277759552002
      }
    },
    {
      "codice": "097056",
      "nome": "Nibionno",
      "nomeStraniero": null,
      "codiceCatastale": "F887",
      "cap": "23895",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "anagrafe@comune.nibionno.lc.it",
      "pec": "anagrafe@pec.comune.nibionno.lc.it",
      "telefono": "+39 031/690626",
      "fax": "+39 031/690100",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.25
      }
    },
    {
      "codice": "097057",
      "nome": "Oggiono",
      "nomeStraniero": null,
      "codiceCatastale": "G009",
      "cap": "23848",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "segreteria@comune.oggiono.lc.it",
      "pec": "protocollo@pec.comune.oggiono.lc.it",
      "telefono": "+39 0341/266411",
      "fax": "+39 0341/579251",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "097058",
      "nome": "Olgiate Molgora",
      "nomeStraniero": null,
      "codiceCatastale": "G026",
      "cap": "23887",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "segreteria@comune.olgiatemolgora.lc.it",
      "pec": "comune.olgiatemolgora@cert.saga.it",
      "telefono": "+39 9911211",
      "fax": "+39 508609",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "097059",
      "nome": "Olginate",
      "nomeStraniero": null,
      "codiceCatastale": "G030",
      "cap": "23854",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "comune.olginate@comune.olginate.lc.it",
      "pec": "comune.olginate@pec.regione.lombardia.it",
      "telefono": "+39 0341/655611",
      "fax": "+39 0341/682995",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "097060",
      "nome": "Oliveto Lario",
      "nomeStraniero": null,
      "codiceCatastale": "G040",
      "cap": "23865",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "anagrafe@comune.olivetolario.lc.it",
      "pec": "comune.olivetolario@pec.regione.lombardia.it",
      "telefono": "+39 031 969778",
      "fax": "+39 031 968097",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "097061",
      "nome": "Osnago",
      "nomeStraniero": null,
      "codiceCatastale": "G161",
      "cap": "23875",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "comune@osnago.net",
      "pec": "comune.osnago@legalmail.it",
      "telefono": "+39 039952991",
      "fax": "+39 0399529926",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "097062",
      "nome": "Paderno d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "G218",
      "cap": "23877",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "ragiosegre@comune.padernodadda.lc.it",
      "pec": "paderno.dadda@legalmail.it",
      "telefono": "+39 513615",
      "fax": "+39 039513473",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "097063",
      "nome": "Pagnona",
      "nomeStraniero": null,
      "codiceCatastale": "G241",
      "cap": "23833",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.pagnona.lc.it",
      "pec": "comunepagnona@halleypec.it",
      "telefono": "+39 0341890420",
      "fax": "+39 0341819026",
      "coordinate": {
        "lat": 46.06010055541992,
        "lng": 9.401900291442871
      }
    },
    {
      "codice": "097064",
      "nome": "Parlasco",
      "nomeStraniero": null,
      "codiceCatastale": "G336",
      "cap": "23837",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.parlasco.lc.it",
      "pec": "pec.comune.parlasco@pec.regione.lombardia.it",
      "telefono": "+39 0341/880202",
      "fax": "+39 0341/880202",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "097065",
      "nome": "Pasturo",
      "nomeStraniero": null,
      "codiceCatastale": "G368",
      "cap": "23818",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.pasturo.lc.it",
      "pec": "comune.pasturo@pec.regione.lombardia.it",
      "telefono": "+39 0341 919705",
      "fax": "+39 0341 955591",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.433333396911621
      }
    },
    {
      "codice": "097067",
      "nome": "Perledo",
      "nomeStraniero": null,
      "codiceCatastale": "G456",
      "cap": "23828",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.perledo.lc.it",
      "pec": "comune.perledo@pec.regione.lombardia.it",
      "telefono": "+39 0341 830229",
      "fax": "+39 0341 831012",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "097068",
      "nome": "Pescate",
      "nomeStraniero": null,
      "codiceCatastale": "G485",
      "cap": "23855",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.pescate.lc.it",
      "pec": "comunedipescate@pec.comune.pescate.lc.it",
      "telefono": "+39 0341365169",
      "fax": "+39 0341285139",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "097069",
      "nome": "Premana",
      "nomeStraniero": null,
      "codiceCatastale": "H028",
      "cap": "23834",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.premana.lc.it",
      "pec": "comune.premana@halleypec.it",
      "telefono": "+39 0341/890127",
      "fax": "+39 0341890437",
      "coordinate": {
        "lat": 46.051998138427734,
        "lng": 9.42300033569336
      }
    },
    {
      "codice": "097070",
      "nome": "Primaluna",
      "nomeStraniero": null,
      "codiceCatastale": "H063",
      "cap": "23819",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.primaluna.lc.it",
      "pec": "comune.primaluna@pec.regione.lombardia.it",
      "telefono": "+39 0341/980253",
      "fax": "+39 0341/982097",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.433333396911621
      }
    },
    {
      "codice": "097071",
      "nome": "Robbiate",
      "nomeStraniero": null,
      "codiceCatastale": "G223",
      "cap": "23899",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "elettorale@comune.robbiate.lc.it",
      "pec": "protocollo@pec.comune.robbiate.lc.it",
      "telefono": "+39 039 513221",
      "fax": "+39 039 513918",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.433333396911621
      }
    },
    {
      "codice": "097072",
      "nome": "Rogeno",
      "nomeStraniero": null,
      "codiceCatastale": "H486",
      "cap": "23849",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "info@comune.rogeno.lc.it",
      "pec": "comune.rogeno@pec.regione.lombardia.it",
      "telefono": "+39 031/865539",
      "fax": "+39 031/876068",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "097074",
      "nome": "Santa Maria Hoè",
      "nomeStraniero": null,
      "codiceCatastale": "I243",
      "cap": "23889",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "protocollo@comune.santamariahoe.lc.it",
      "pec": "comunesantamariahoe@legalmail.it",
      "telefono": "+39 0395311174",
      "fax": "+39 039/5310005",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "097075",
      "nome": "Sirone",
      "nomeStraniero": null,
      "codiceCatastale": "I759",
      "cap": "23844",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "info@comune.sirone.lc.it",
      "pec": "sirone@legalmail.it",
      "telefono": "+39 031/850174",
      "fax": "+39 031/870090",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097076",
      "nome": "Sirtori",
      "nomeStraniero": null,
      "codiceCatastale": "I761",
      "cap": "23896",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "demografici@comune.sirtori.lc.it",
      "pec": "comune.sirtori@pec.regione.lombardia.it",
      "telefono": "+39 039/9215011",
      "fax": "+39 039/9215034",
      "coordinate": {
        "lat": 45.737220764160156,
        "lng": 9.337499618530273
      }
    },
    {
      "codice": "097077",
      "nome": "Sueglio",
      "nomeStraniero": null,
      "codiceCatastale": "I994",
      "cap": "23835",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "sueglio@comune.sueglio.lc.it",
      "pec": "comune.sueglio@pec.regione.lombardia.it",
      "telefono": "+39 0341808025",
      "fax": null,
      "coordinate": {
        "lat": 46.085330963134766,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "097078",
      "nome": "Suello",
      "nomeStraniero": null,
      "codiceCatastale": "I996",
      "cap": "23867",
      "prefisso": "031",
      "provincia": "Lecco",
      "email": "suello@comune.suello.lc.it",
      "pec": "comune.suello@pec.regione.lombardia.it",
      "telefono": "+39 031/655715",
      "fax": null,
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "097079",
      "nome": "Taceno",
      "nomeStraniero": null,
      "codiceCatastale": "L022",
      "cap": "23837",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "info@comune.taceno.lc.it",
      "pec": "comune.taceno@pec.regione.lombardia.it",
      "telefono": "+39 0341-880112",
      "fax": "+39 0341-880112",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "097082",
      "nome": "Valgreghentino",
      "nomeStraniero": null,
      "codiceCatastale": "L581",
      "cap": "23857",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "anagrafe@comune.valgreghentino.lc.it",
      "pec": "comune.valgreghentino@pec.regione.lombardia.it",
      "telefono": "+39 0341604507",
      "fax": "+39 0341660063",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "097083",
      "nome": "Valmadrera",
      "nomeStraniero": null,
      "codiceCatastale": "L634",
      "cap": "23868",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "protocollo@comune.valmadrera.lc.it",
      "pec": "info@pec.comune.valmadrera.lc.it",
      "telefono": "+39 0341/205111",
      "fax": "+39 0341/201051",
      "coordinate": {
        "lat": 45.84627914428711,
        "lng": 9.358240127563477
      }
    },
    {
      "codice": "097093",
      "nome": "Valvarrone",
      "nomeStraniero": null,
      "codiceCatastale": "M395",
      "cap": "23836",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": null,
      "pec": "comune.valvarrone@pec.regione.lombardia.it",
      "telefono": "0341/875040",
      "fax": null,
      "coordinate": {
        "lat": 46.080501556396484,
        "lng": 9.342300415039062
      }
    },
    {
      "codice": "097084",
      "nome": "Varenna",
      "nomeStraniero": null,
      "codiceCatastale": "L680",
      "cap": "23829",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": null,
      "pec": "varenna@comune.varenna.legalmailpa.it",
      "telefono": "+39 0341/830119",
      "fax": null,
      "coordinate": {
        "lat": 46.010799407958984,
        "lng": 9.28380012512207
      }
    },
    {
      "codice": "097086",
      "nome": "Vercurago",
      "nomeStraniero": null,
      "codiceCatastale": "L751",
      "cap": "23808",
      "prefisso": "0341",
      "provincia": "Lecco",
      "email": "anagrafe@comune.vercurago.lc.it",
      "pec": "comune.vercurago@legalmail.it",
      "telefono": "+39 0341 420525",
      "fax": "+39 0341 421623",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "097091",
      "nome": "Verderio",
      "nomeStraniero": null,
      "codiceCatastale": "M337",
      "cap": "23878",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "info@comune.verderio.lc.it",
      "pec": "infoverderio@halleypec.it",
      "telefono": "+39 039 590591",
      "fax": "+39 039 513845",
      "coordinate": {
        "lat": 45.66669845581055,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "097090",
      "nome": "Viganò",
      "nomeStraniero": null,
      "codiceCatastale": "L866",
      "cap": "23897",
      "prefisso": "039",
      "provincia": "Lecco",
      "email": "segreteria@comune.vigano.lc.it",
      "pec": "comune.vigano@pec.regione.lombardia.it",
      "telefono": "+39 039 921391",
      "fax": "+39 039 92139315",
      "coordinate": {
        "lat": 45.72468185424805,
        "lng": 9.324939727783203
      }
    }
  ],
  "Siena": [
    {
      "codice": "052001",
      "nome": "Abbadia San Salvatore",
      "nomeStraniero": null,
      "codiceCatastale": "A006",
      "cap": "53021",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "comune@comune.abbadia.siena.it",
      "pec": "comune.abbadia@postacert.toscana.it",
      "telefono": "+39 0577/770001",
      "fax": "+39 0577/770325",
      "coordinate": {
        "lat": 42.88312911987305,
        "lng": 11.670647621154785
      }
    },
    {
      "codice": "052002",
      "nome": "Asciano",
      "nomeStraniero": null,
      "codiceCatastale": "A461",
      "cap": "53041",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "urp@comune.asciano.siena.it",
      "pec": "comune.asciano@postacert.toscana.it",
      "telefono": "+39 057771441",
      "fax": "+39 0577714524",
      "coordinate": {
        "lat": 43.23416519165039,
        "lng": 11.560832977294922
      }
    },
    {
      "codice": "052003",
      "nome": "Buonconvento",
      "nomeStraniero": null,
      "codiceCatastale": "B269",
      "cap": "53022",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info@comune.buonconvento.si.it",
      "pec": "comune.buonconvento@postacert.toscana.it",
      "telefono": "+39 0577/80971",
      "fax": "+39 0577/807212",
      "coordinate": {
        "lat": 43.13333511352539,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "052004",
      "nome": "Casole d'Elsa",
      "nomeStraniero": null,
      "codiceCatastale": "B984",
      "cap": "53031",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "comune@casole.it",
      "pec": "comune.casoledelsa@postacert.toscana.it",
      "telefono": "+39 0577/949711",
      "fax": "+39 0577/949740",
      "coordinate": {
        "lat": 43.34111022949219,
        "lng": 11.048500061035156
      }
    },
    {
      "codice": "052005",
      "nome": "Castellina in Chianti",
      "nomeStraniero": null,
      "codiceCatastale": "C172",
      "cap": "53011",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "amministrazione@comune.castellina.si.it",
      "pec": "comune.castellinainchianti@postacert.toscana.it",
      "telefono": "+39 0577/742311",
      "fax": "+39 0577/742355",
      "coordinate": {
        "lat": 43.46916580200195,
        "lng": 11.28861141204834
      }
    },
    {
      "codice": "052006",
      "nome": "Castelnuovo Berardenga",
      "nomeStraniero": null,
      "codiceCatastale": "C227",
      "cap": "53019",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "messaggi@comune.castelnuovo.si.it",
      "pec": "castelnuovo-berardenga@postacert.toscana.it",
      "telefono": "+39 0577 3511",
      "fax": "+39 0577/355273",
      "coordinate": {
        "lat": 43.34722137451172,
        "lng": 11.504166603088379
      }
    },
    {
      "codice": "052007",
      "nome": "Castiglione d'Orcia",
      "nomeStraniero": null,
      "codiceCatastale": "C313",
      "cap": "53023",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "sindaco@comune.castiglionedorcia.siena.it",
      "pec": "comune.castiglionedorcia@postacert.toscana.it",
      "telefono": "+39 057788401",
      "fax": "+39 0577887490",
      "coordinate": {
        "lat": 43.016666412353516,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "052008",
      "nome": "Cetona",
      "nomeStraniero": null,
      "codiceCatastale": "C587",
      "cap": "53040",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "segreteria@comune.cetona.si.it",
      "pec": "comune.cetona@pec.consorzioterrecablate.it",
      "telefono": "+39 0578/269402",
      "fax": "+39 0578/238616",
      "coordinate": {
        "lat": 42.96666717529297,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "052009",
      "nome": "Chianciano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "C608",
      "cap": "53042",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "protocollo@comune.chianciano-terme.si.it",
      "pec": "comune.chianciano-terme@pec.consorzioterrecablate.it",
      "telefono": "+39 0578 6521",
      "fax": "+39 0578/31607",
      "coordinate": {
        "lat": 43.06666564941406,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "052010",
      "nome": "Chiusdino",
      "nomeStraniero": null,
      "codiceCatastale": "C661",
      "cap": "53012",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "servizidemografici@comune.chiusdino.siena.it",
      "pec": "comune.chiusdino@pec.consorzioterrecablate.it",
      "telefono": "+39 0577/751055",
      "fax": "+39 0577/750221",
      "coordinate": {
        "lat": 43.15460968017578,
        "lng": 11.084250450134277
      }
    },
    {
      "codice": "052011",
      "nome": "Chiusi",
      "nomeStraniero": null,
      "codiceCatastale": "C662",
      "cap": "53043",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "ced@comune.chiusi.siena.it",
      "pec": "protocollo.chiusi@legalmail.it",
      "telefono": "+39 057822361",
      "fax": "+39 0578/227864",
      "coordinate": {
        "lat": 43.016666412353516,
        "lng": 11.949999809265137
      }
    },
    {
      "codice": "052012",
      "nome": "Colle di Val d'Elsa",
      "nomeStraniero": null,
      "codiceCatastale": "C847",
      "cap": "53034",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info@comune.collevaldelsa.it",
      "pec": "comune.collevaldelsa@postecert.it",
      "telefono": "+39 0577/912111",
      "fax": "+39 0577912270",
      "coordinate": {
        "lat": 43.42250061035156,
        "lng": 11.126667022705078
      }
    },
    {
      "codice": "052013",
      "nome": "Gaiole in Chianti",
      "nomeStraniero": null,
      "codiceCatastale": "D858",
      "cap": "53013",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "urp@comune.gaiole.si.it",
      "pec": "comune.gaioleinchianti@postacert.toscana.it",
      "telefono": "+39 0577/74471",
      "fax": "+39 0577/744740",
      "coordinate": {
        "lat": 43.46666717529297,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "052037",
      "nome": "Montalcino",
      "nomeStraniero": null,
      "codiceCatastale": "M378",
      "cap": "53024",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "segretario@comunemontalcino.com",
      "pec": "info@pec.comunedimontalcino.it",
      "telefono": "+39 057780441",
      "fax": "+39 0577849343",
      "coordinate": {
        "lat": 43.059165954589844,
        "lng": 11.489166259765625
      }
    },
    {
      "codice": "052015",
      "nome": "Montepulciano",
      "nomeStraniero": null,
      "codiceCatastale": "F592",
      "cap": "53045",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "protocollo@comune.montepulciano.si.it",
      "pec": "comune.montepulciano@pec.consorzioterrecablate.it",
      "telefono": "+39 0578 7121",
      "fax": "+39 0578/757355",
      "coordinate": {
        "lat": 43.099998474121094,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "052016",
      "nome": "Monteriggioni",
      "nomeStraniero": null,
      "codiceCatastale": "F598",
      "cap": "53035",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "monteriggioni@comune.monteriggioni.si.it",
      "pec": "comunemonteriggioni@postacert.toscana.it",
      "telefono": "+39 0577/306697",
      "fax": "+39 0577/304550",
      "coordinate": {
        "lat": 43.400001525878906,
        "lng": 11.216666221618652
      }
    },
    {
      "codice": "052017",
      "nome": "Monteroni d'Arbia",
      "nomeStraniero": null,
      "codiceCatastale": "F605",
      "cap": "53014",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info.@comune.monteronidarbia.si.it",
      "pec": "comune.monteronidarbia@postacert.toscana.it",
      "telefono": "+39 0577 2511",
      "fax": "+39 0577/373070",
      "coordinate": {
        "lat": 43.233333587646484,
        "lng": 11.416666984558105
      }
    },
    {
      "codice": "052018",
      "nome": "Monticiano",
      "nomeStraniero": null,
      "codiceCatastale": "F676",
      "cap": "53015",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "segreteria@comune.monticiano.si.it",
      "pec": "comune.monticiano@pec.consorzioterrecablate.it",
      "telefono": "+39 0577/049340",
      "fax": null,
      "coordinate": {
        "lat": 43.13333511352539,
        "lng": 11.183333396911621
      }
    },
    {
      "codice": "052019",
      "nome": "Murlo",
      "nomeStraniero": null,
      "codiceCatastale": "F815",
      "cap": "53016",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "sindaco@comune.murlo.siena.it",
      "pec": "comune.murlo@postacert.toscana.it",
      "telefono": "+39 0577/814213",
      "fax": "+39 0577/814205",
      "coordinate": {
        "lat": 43.171112060546875,
        "lng": 11.39222240447998
      }
    },
    {
      "codice": "052020",
      "nome": "Piancastagnaio",
      "nomeStraniero": null,
      "codiceCatastale": "G547",
      "cap": "53025",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "segretario@comune.piancastagnaio.si.it",
      "pec": "comune.piancastagnaio@postacert.toscana.it",
      "telefono": "+39 0577/786024",
      "fax": "+39 0577/786711",
      "coordinate": {
        "lat": 42.849998474121094,
        "lng": 11.683333396911621
      }
    },
    {
      "codice": "052021",
      "nome": "Pienza",
      "nomeStraniero": null,
      "codiceCatastale": "G602",
      "cap": "53026",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "comune.pienza@pec.consorzioterrecablate.it",
      "pec": "comune.pienza@pec.consorzioterrecablate.it",
      "telefono": "+39 0578/748502",
      "fax": "+39 0578/748361",
      "coordinate": {
        "lat": 43.078609466552734,
        "lng": 11.678889274597168
      }
    },
    {
      "codice": "052022",
      "nome": "Poggibonsi",
      "nomeStraniero": null,
      "codiceCatastale": "G752",
      "cap": "53036",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info@comune.poggibonsi.si.it",
      "pec": "comune.poggibonsi@postacert.toscana.it",
      "telefono": "+39 0577 9861",
      "fax": "+39 0577/986229",
      "coordinate": {
        "lat": 43.46666717529297,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "052023",
      "nome": "Radda in Chianti",
      "nomeStraniero": null,
      "codiceCatastale": "H153",
      "cap": "53017",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "anagrafe@comune.raddainchianti.si.it",
      "pec": "comune.radda@postacert.toscana.it",
      "telefono": "+39 0577/739629",
      "fax": "+39 0577/738062",
      "coordinate": {
        "lat": 43.483333587646484,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "052024",
      "nome": "Radicofani",
      "nomeStraniero": null,
      "codiceCatastale": "H156",
      "cap": "53040",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "comunediradicofani@inwind.it",
      "pec": "comune.radicofani@postacert.toscana.it",
      "telefono": "+39 0578/55905",
      "fax": "+39 0578/55930",
      "coordinate": {
        "lat": 42.900001525878906,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "052025",
      "nome": "Radicondoli",
      "nomeStraniero": null,
      "codiceCatastale": "H157",
      "cap": "53030",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info@comune.radicondoli.siena.it",
      "pec": "comune.radicondoli@postacert.toscana.it",
      "telefono": "+39 0577 790903 - 790910",
      "fax": "+39 0577 790577",
      "coordinate": {
        "lat": 43.266666412353516,
        "lng": 11.050000190734863
      }
    },
    {
      "codice": "052026",
      "nome": "Rapolano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "H185",
      "cap": "53040",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "m.anselmi@comune.rapolanoterme.si.it",
      "pec": "comune.rapolanoterme@postacert.toscana.it",
      "telefono": "+39 0577/723222",
      "fax": "+39 0577/723222",
      "coordinate": {
        "lat": 43.28333282470703,
        "lng": 11.600000381469727
      }
    },
    {
      "codice": "052027",
      "nome": "San Casciano dei Bagni",
      "nomeStraniero": null,
      "codiceCatastale": "H790",
      "cap": "53040",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "sindaco@comune.sancascianodeibagni.siena.it",
      "pec": "comune.sancascianodeibagni@pec.consorzioterrecablate.it",
      "telefono": "+39 0578/58911",
      "fax": "+39 0578/58178",
      "coordinate": {
        "lat": 42.86666488647461,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "052028",
      "nome": "San Gimignano",
      "nomeStraniero": null,
      "codiceCatastale": "H875",
      "cap": "53030",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "servizidemografici@comune.sangimignano.si.it",
      "pec": "comune.sangimignano@postcert.toscana.it",
      "telefono": "+39 0577 9901",
      "fax": null,
      "coordinate": {
        "lat": 43.46772003173828,
        "lng": 11.043221473693848
      }
    },
    {
      "codice": "052030",
      "nome": "San Quirico d'Orcia",
      "nomeStraniero": null,
      "codiceCatastale": "I135",
      "cap": "53027",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "protocollo@comunesanquirico.it",
      "pec": "comune.sanquiricodorcia@postacert.toscana.it",
      "telefono": "+39 0577899720",
      "fax": "+39 0577/899721",
      "coordinate": {
        "lat": 43.06666564941406,
        "lng": 11.600000381469727
      }
    },
    {
      "codice": "052031",
      "nome": "Sarteano",
      "nomeStraniero": null,
      "codiceCatastale": "I445",
      "cap": "53047",
      "prefisso": "0578",
      "provincia": "Siena",
      "email": "comune@comune.sarteano.si.it",
      "pec": "comune.sarteano@pec.consorzioterrecablate.it",
      "telefono": "+39 0578/269200",
      "fax": "+39 0578/269208",
      "coordinate": {
        "lat": 42.983333587646484,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "052032",
      "nome": "Siena",
      "nomeStraniero": null,
      "codiceCatastale": "I726",
      "cap": "53100",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "archivio@comune.siena.it",
      "pec": "comune.siena@postacert.toscana.it",
      "telefono": "+39 0577/292111",
      "fax": "+39 0577/292346",
      "coordinate": {
        "lat": 43.31833267211914,
        "lng": 11.331388473510742
      }
    },
    {
      "codice": "052033",
      "nome": "Sinalunga",
      "nomeStraniero": null,
      "codiceCatastale": "A468",
      "cap": "53048",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "protocollo@comune.sinalunga.si.it",
      "pec": "comune.sinalunga@postacert.toscana.it",
      "telefono": "+39 0577-63511",
      "fax": "+39 0577-630001",
      "coordinate": {
        "lat": 43.21666717529297,
        "lng": 11.733333587646484
      }
    },
    {
      "codice": "052034",
      "nome": "Sovicille",
      "nomeStraniero": null,
      "codiceCatastale": "I877",
      "cap": "53018",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info@comune.sovicille.si.it",
      "pec": "comune.sovicille@postacert.toscana.it",
      "telefono": "+39 0577/049236",
      "fax": "+39 0577/314616",
      "coordinate": {
        "lat": 43.28333282470703,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "052035",
      "nome": "Torrita di Siena",
      "nomeStraniero": null,
      "codiceCatastale": "L303",
      "cap": "53049",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "info@comune.torrita.siena.it",
      "pec": "comune.torritadisiena@postacert.toscana.it",
      "telefono": "+39 0577 6881",
      "fax": "+39 0577/685620",
      "coordinate": {
        "lat": 43.16666793823242,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "052036",
      "nome": "Trequanda",
      "nomeStraniero": null,
      "codiceCatastale": "L384",
      "cap": "53020",
      "prefisso": "0577",
      "provincia": "Siena",
      "email": "anag@comune.trequanda.siena.it",
      "pec": "comune.trequanda@postacert.toscana.it",
      "telefono": "+39 0577/662092",
      "fax": "+39 0577/662029",
      "coordinate": {
        "lat": 43.18333435058594,
        "lng": 11.666666984558105
      }
    }
  ],
  "Oristano": [
    {
      "codice": "095001",
      "nome": "Abbasanta",
      "nomeStraniero": null,
      "codiceCatastale": "A007",
      "cap": "09071",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "comune.abbasanta@tiscali.it",
      "pec": "protocollo@pec.comune.abbasanta.or.it",
      "telefono": "+39 0785 561634",
      "fax": "+39 0785 561650",
      "coordinate": {
        "lat": 40.125831604003906,
        "lng": 8.81944465637207
      }
    },
    {
      "codice": "095002",
      "nome": "Aidomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "A097",
      "cap": "09070",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "aidomaggiore@tiscali.it",
      "pec": "protocollo@pec.comuneaidomaggiore.it",
      "telefono": "+39 0785/57723",
      "fax": "+39 0785/57860",
      "coordinate": {
        "lat": 40.17194366455078,
        "lng": 8.85694408416748
      }
    },
    {
      "codice": "095003",
      "nome": "Albagiara",
      "nomeStraniero": null,
      "codiceCatastale": "A126",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunedialbagiara@gmail.com",
      "pec": "amministrativo@pec.comune.albagiara.or.it",
      "telefono": "+39 0783/938002",
      "fax": "+39 0783/938420",
      "coordinate": {
        "lat": 39.78720474243164,
        "lng": 8.861761093139648
      }
    },
    {
      "codice": "095004",
      "nome": "Ales",
      "nomeStraniero": null,
      "codiceCatastale": "A180",
      "cap": "09091",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.ales@tiscali.it",
      "pec": "protocollo@pec.comune.ales.or.it",
      "telefono": "+39 0783/91131",
      "fax": "+39 0783/911336",
      "coordinate": {
        "lat": 39.766666412353516,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "095005",
      "nome": "Allai",
      "nomeStraniero": null,
      "codiceCatastale": "A204",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunediallai@tiscali.it",
      "pec": "protocollo@pec.comune.allai.or.it",
      "telefono": "+39 0783 6813",
      "fax": "+39 0783/609034",
      "coordinate": {
        "lat": 39.957000732421875,
        "lng": 8.8639497756958
      }
    },
    {
      "codice": "095006",
      "nome": "Arborea",
      "nomeStraniero": null,
      "codiceCatastale": "A357",
      "cap": "09092",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comunearborea.it",
      "pec": "protocollo@pec.comunearborea.it",
      "telefono": "+39 0783/80331",
      "fax": "+39 0783/8033223",
      "coordinate": {
        "lat": 39.77275848388672,
        "lng": 8.581290245056152
      }
    },
    {
      "codice": "095007",
      "nome": "Ardauli",
      "nomeStraniero": null,
      "codiceCatastale": "A380",
      "cap": "09081",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "demografici@comune.ardauli.or.it",
      "pec": "protocollo@pec.comuneardauli.gov.it",
      "telefono": "+39 0783/651223",
      "fax": "+39 0783/651573",
      "coordinate": {
        "lat": 40.084720611572266,
        "lng": 8.9127779006958
      }
    },
    {
      "codice": "095008",
      "nome": "Assolo",
      "nomeStraniero": null,
      "codiceCatastale": "A477",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comuneassolo.rag@tiscali.t",
      "pec": "protocollo@pec.comune.assolo.or.it",
      "telefono": "+39 0783/969050",
      "fax": "+39 0783/969266",
      "coordinate": {
        "lat": 39.80958938598633,
        "lng": 8.919010162353516
      }
    },
    {
      "codice": "095009",
      "nome": "Asuni",
      "nomeStraniero": null,
      "codiceCatastale": "A480",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.asuni@tiscali.it",
      "pec": "protocollo.asuni@pec.comunas.it",
      "telefono": "+39 0783 960134",
      "fax": "+39 0783 960133",
      "coordinate": {
        "lat": 39.871803283691406,
        "lng": 8.946475982666016
      }
    },
    {
      "codice": "095010",
      "nome": "Baradili",
      "nomeStraniero": null,
      "codiceCatastale": "A614",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune@comunebaradili.gov.it",
      "pec": "protocollo@pec.comune.baradili.or.it",
      "telefono": "+39 0783 95095",
      "fax": "+39 0783 95355",
      "coordinate": {
        "lat": 39.72216796875,
        "lng": 8.897639274597168
      }
    },
    {
      "codice": "095011",
      "nome": "Baratili San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "A621",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "baratili@tiscali.it",
      "pec": "protocollo@pec.comune.baratilisanpietro.or.it",
      "telefono": "+39 0783 4139",
      "fax": "+39 0783 411511",
      "coordinate": {
        "lat": 39.99277877807617,
        "lng": 8.55555534362793
      }
    },
    {
      "codice": "095012",
      "nome": "Baressa",
      "nomeStraniero": null,
      "codiceCatastale": "A655",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "amministrativo@comune.baressa.or.it",
      "pec": "protocollo@pec.comune.baressa.or.it",
      "telefono": "+39 0783 930049",
      "fax": "+39 0783 930118",
      "coordinate": {
        "lat": 39.71331024169922,
        "lng": 8.875215530395508
      }
    },
    {
      "codice": "095013",
      "nome": "Bauladu",
      "nomeStraniero": null,
      "codiceCatastale": "A721",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "amministrativo@comune.bauladu.or.it",
      "pec": "comune.bauladu@anutel.it",
      "telefono": "+39 0783 51677",
      "fax": "+39 0783 51592",
      "coordinate": {
        "lat": 40.020416259765625,
        "lng": 8.671860694885254
      }
    },
    {
      "codice": "095014",
      "nome": "Bidonì",
      "nomeStraniero": null,
      "codiceCatastale": "A856",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ufficio.protocollo@comune.bidoni.or.it",
      "pec": "protocollo@pec.comune.bidoni.or.it",
      "telefono": "+39 0783 69044",
      "fax": "+39 0783 69407",
      "coordinate": {
        "lat": 40.11277770996094,
        "lng": 8.936388969421387
      }
    },
    {
      "codice": "095015",
      "nome": "Bonarcado",
      "nomeStraniero": null,
      "codiceCatastale": "A960",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "pinnantonella@gmail.com",
      "pec": "protocollo@pec.comune.bonarcado.or.it",
      "telefono": "+39 0783 569017",
      "fax": "+39 0783 569009",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 8.649999618530273
      }
    },
    {
      "codice": "095016",
      "nome": "Boroneddu",
      "nomeStraniero": null,
      "codiceCatastale": "B055",
      "cap": "09080",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "boroneddu@tiscali.it",
      "pec": "protocollo@pec.comune.boroneddu.or.it",
      "telefono": "+39 0785 50007",
      "fax": "+39 0785 50061",
      "coordinate": {
        "lat": 40.11249923706055,
        "lng": 8.870555877685547
      }
    },
    {
      "codice": "095079",
      "nome": "Bosa",
      "nomeStraniero": null,
      "codiceCatastale": "B068",
      "cap": "09089",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "info@comune.bosa.or.it",
      "pec": "protocollo@pec.comune.bosa.or.it",
      "telefono": "+39 0785 368000",
      "fax": "+39 0785 373949",
      "coordinate": {
        "lat": 40.29895782470703,
        "lng": 8.497791290283203
      }
    },
    {
      "codice": "095017",
      "nome": "Busachi",
      "nomeStraniero": null,
      "codiceCatastale": "B281",
      "cap": "09082",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.busachi.or.it",
      "pec": "info.busachi@legalmail.it",
      "telefono": "+39 0783 62010",
      "fax": "+39 0783 62433",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "095018",
      "nome": "Cabras",
      "nomeStraniero": null,
      "codiceCatastale": "B314",
      "cap": "09072",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ced.cabras@tiscali.it",
      "pec": "comunedicabras@pec.it",
      "telefono": "+39 0783 397214",
      "fax": "+39 0783 391646",
      "coordinate": {
        "lat": 39.93055725097656,
        "lng": 8.530278205871582
      }
    },
    {
      "codice": "095019",
      "nome": "Cuglieri",
      "nomeStraniero": null,
      "codiceCatastale": "D200",
      "cap": "09073",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "area.amministrativa@comune.cuglieri.or.it",
      "pec": "protocollo@pec.comune.cuglieri.or.it",
      "telefono": "+39 0785 368200",
      "fax": "+39 0785 368214",
      "coordinate": {
        "lat": 40.18888854980469,
        "lng": 8.567222595214844
      }
    },
    {
      "codice": "095077",
      "nome": "Curcuris",
      "nomeStraniero": null,
      "codiceCatastale": "D214",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunecurcuris@tiscali.it",
      "pec": "protocollo.curcuris@legalmail.it",
      "telefono": "+39 0783 91751",
      "fax": "+39 0783 91751",
      "coordinate": {
        "lat": 39.74666213989258,
        "lng": 8.830819129943848
      }
    },
    {
      "codice": "095080",
      "nome": "Flussio",
      "nomeStraniero": null,
      "codiceCatastale": "D640",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "com.fluss@tiscali.it",
      "pec": "protocollo@pec.comune.flussio.nu.it",
      "telefono": "+39 0785 34805",
      "fax": "+39 0785 34814",
      "coordinate": {
        "lat": 40.26711654663086,
        "lng": 8.539209365844727
      }
    },
    {
      "codice": "095020",
      "nome": "Fordongianus",
      "nomeStraniero": null,
      "codiceCatastale": "D695",
      "cap": "09083",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "fordongianus@tiscali.it",
      "pec": "protocollo.fordongianus@pec.comunas.it",
      "telefono": "+39 0783 605172",
      "fax": "+39 0783 60323",
      "coordinate": {
        "lat": 39.994876861572266,
        "lng": 8.809490203857422
      }
    },
    {
      "codice": "095021",
      "nome": "Ghilarza",
      "nomeStraniero": null,
      "codiceCatastale": "E004",
      "cap": "09074",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "info@comune.ghilarza.or.it",
      "pec": "protocollo@pec.comune.ghilarza.or.it",
      "telefono": "+39 0785 5610",
      "fax": "+39 0785 561069",
      "coordinate": {
        "lat": 40.119998931884766,
        "lng": 8.835000038146973
      }
    },
    {
      "codice": "095022",
      "nome": "Gonnoscodina",
      "nomeStraniero": null,
      "codiceCatastale": "E087",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.gonnoscodina.or.it",
      "pec": "protocollo@pec.comune.gonnoscodina.or.it",
      "telefono": "+39 0783 92000",
      "fax": "+39 0783 92054",
      "coordinate": {
        "lat": 39.69860076904297,
        "lng": 8.835067749023438
      }
    },
    {
      "codice": "095023",
      "nome": "Gonnosnò",
      "nomeStraniero": null,
      "codiceCatastale": "D585",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.gonnosno.or.it",
      "pec": "protocollo@pec.comune.gonnosno.or.it",
      "telefono": "+39 0783 931678",
      "fax": "+39 0783 931679",
      "coordinate": {
        "lat": 39.76055145263672,
        "lng": 8.872404098510742
      }
    },
    {
      "codice": "095024",
      "nome": "Gonnostramatza",
      "nomeStraniero": null,
      "codiceCatastale": "E088",
      "cap": "09093",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "anagrafe@comune.gonnostramatza.or.it",
      "pec": "protocollo@pec.comune.gonnostramatza.or.it",
      "telefono": "+39 0783 92015",
      "fax": null,
      "coordinate": {
        "lat": 39.68169403076172,
        "lng": 8.832836151123047
      }
    },
    {
      "codice": "095082",
      "nome": "Laconi",
      "nomeStraniero": null,
      "codiceCatastale": "E400",
      "cap": "09090",
      "prefisso": "0782",
      "provincia": "Oristano",
      "email": "info@comune.laconi.or.it",
      "pec": "protocollo@pec.comune.laconi.or.it",
      "telefono": "+39 0782 866200",
      "fax": "+39 0782 869579",
      "coordinate": {
        "lat": 39.852699279785156,
        "lng": 9.052047729492188
      }
    },
    {
      "codice": "095083",
      "nome": "Magomadas",
      "nomeStraniero": null,
      "codiceCatastale": "E825",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "protocollo@comune.magomadas.or.it",
      "pec": "protocollo.magomadas@digitalpec.com",
      "telefono": "+39 0785 35323",
      "fax": "+39 0785 35250",
      "coordinate": {
        "lat": 40.26549530029297,
        "lng": 8.520776748657227
      }
    },
    {
      "codice": "095025",
      "nome": "Marrubiu",
      "nomeStraniero": null,
      "codiceCatastale": "E972",
      "cap": "09094",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "mariagrazia.pilloni@comunemarrubiu.it",
      "pec": "protocollo@pec.comunemarrubiu.it",
      "telefono": "+39 0783 85531",
      "fax": "+39 0783 858209",
      "coordinate": {
        "lat": 39.75101852416992,
        "lng": 8.638343811035156
      }
    },
    {
      "codice": "095026",
      "nome": "Masullas",
      "nomeStraniero": null,
      "codiceCatastale": "F050",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunemasullas@tiscali.it",
      "pec": "protocollo@pec.comune.masullas.or.it",
      "telefono": "+39 0783 990251",
      "fax": "+39 0783 991596",
      "coordinate": {
        "lat": 39.70005416870117,
        "lng": 8.784255981445312
      }
    },
    {
      "codice": "095027",
      "nome": "Milis",
      "nomeStraniero": null,
      "codiceCatastale": "F208",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comunemilis.gov.it",
      "pec": "protocollo.milis@pec.comunas.it",
      "telefono": "+39 0783 51665",
      "fax": "+39 0783 51680",
      "coordinate": {
        "lat": 40.051387786865234,
        "lng": 8.638055801391602
      }
    },
    {
      "codice": "095084",
      "nome": "Modolo",
      "nomeStraniero": null,
      "codiceCatastale": "F261",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "info@comune.modolo.nu.it",
      "pec": "info@pec.comune.modolo.nu.it",
      "telefono": "+39 0785 35666",
      "fax": "+39 0785 35378",
      "coordinate": {
        "lat": 40.27534866333008,
        "lng": 8.530019760131836
      }
    },
    {
      "codice": "095028",
      "nome": "Mogorella",
      "nomeStraniero": null,
      "codiceCatastale": "F270",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.mogorella.or.it",
      "pec": "comune.mogorella.or@legalmail.it",
      "telefono": "+39078345423",
      "fax": "+39078345383",
      "coordinate": {
        "lat": 39.86469268798828,
        "lng": 8.85866641998291
      }
    },
    {
      "codice": "095029",
      "nome": "Mogoro",
      "nomeStraniero": null,
      "codiceCatastale": "F272",
      "cap": "09095",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ced@comune.mogoro.or.it",
      "pec": "protocollo@pec.comune.mogoro.or.it",
      "telefono": "+39 0783 993043",
      "fax": "+39 0783 990131",
      "coordinate": {
        "lat": 39.68499755859375,
        "lng": 8.777046203613281
      }
    },
    {
      "codice": "095085",
      "nome": "Montresta",
      "nomeStraniero": null,
      "codiceCatastale": "F698",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "protocollo@comune.montresta.or.it",
      "pec": "protocollo.montresta@pec.it",
      "telefono": "+39 0785 30003",
      "fax": "+39 0785 30240",
      "coordinate": {
        "lat": 40.37424087524414,
        "lng": 8.499641418457031
      }
    },
    {
      "codice": "095030",
      "nome": "Morgongiori",
      "nomeStraniero": null,
      "codiceCatastale": "F727",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.morgongiori.or.it",
      "pec": "protocollo@pec.comune.morgongiori.or.it",
      "telefono": "+39 0783 932112",
      "fax": "+39 0783 932276",
      "coordinate": {
        "lat": 39.74606704711914,
        "lng": 8.770459175109863
      }
    },
    {
      "codice": "095031",
      "nome": "Narbolia",
      "nomeStraniero": null,
      "codiceCatastale": "F840",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "anagrafe@comune.narbolia.or.it",
      "pec": "protocollo.narbolia@pec.comunas.it",
      "telefono": "+39 0783 57513",
      "fax": "+39 0783 57171",
      "coordinate": {
        "lat": 40.04805374145508,
        "lng": 8.57638931274414
      }
    },
    {
      "codice": "095032",
      "nome": "Neoneli",
      "nomeStraniero": null,
      "codiceCatastale": "F867",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.neoneli.or.it",
      "pec": "protocollo@pec.comune.neoneli.or.it",
      "telefono": "+39 0783 67747",
      "fax": "+39 0783 67764",
      "coordinate": {
        "lat": 40.065277099609375,
        "lng": 8.946944236755371
      }
    },
    {
      "codice": "095033",
      "nome": "Norbello",
      "nomeStraniero": null,
      "codiceCatastale": "F934",
      "cap": "09070",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "com.norbello@tiscali.it",
      "pec": "protocollo@pec.comune.norbello.or.it",
      "telefono": "+39 0785 51991",
      "fax": "+39 0785 899105",
      "coordinate": {
        "lat": 40.135379791259766,
        "lng": 8.832664489746094
      }
    },
    {
      "codice": "095034",
      "nome": "Nughedu Santa Vittoria",
      "nomeStraniero": null,
      "codiceCatastale": "F974",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "nughedsv@tin.it",
      "pec": "protocollo.nughedusv@pec.comunas.it",
      "telefono": "+39 0783 69026",
      "fax": "+39 0783 69384",
      "coordinate": {
        "lat": 40.101112365722656,
        "lng": 8.95138931274414
      }
    },
    {
      "codice": "095035",
      "nome": "Nurachi",
      "nomeStraniero": null,
      "codiceCatastale": "F980",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "rosagian@gmail.com",
      "pec": "comunedinurachi@pec.it",
      "telefono": "+39 0783 412085",
      "fax": "+39 0783 412086",
      "coordinate": {
        "lat": 39.97444534301758,
        "lng": 8.539166450500488
      }
    },
    {
      "codice": "095036",
      "nome": "Nureci",
      "nomeStraniero": null,
      "codiceCatastale": "F985",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.nureci.or.it",
      "pec": "protocollo@pec.comune.nureci.or.it",
      "telefono": "+39 0783 96600",
      "fax": "+39 0783 96646",
      "coordinate": {
        "lat": 39.82328796386719,
        "lng": 8.974735260009766
      }
    },
    {
      "codice": "095037",
      "nome": "Ollastra",
      "nomeStraniero": null,
      "codiceCatastale": "G043",
      "cap": "09084",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.ollastra.it",
      "pec": "protocollo@pec.comunediollastra.it",
      "telefono": "+39 0783 409000",
      "fax": "+39 0783 409315",
      "coordinate": {
        "lat": 39.95138931274414,
        "lng": 8.734722137451172
      }
    },
    {
      "codice": "095038",
      "nome": "Oristano",
      "nomeStraniero": null,
      "codiceCatastale": "G113",
      "cap": "09170",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ced@comune.oristano.it",
      "pec": "istituzionale@pec.comune.oristano.it",
      "telefono": "+39 0783 791495",
      "fax": "+39 0783 300707",
      "coordinate": {
        "lat": 39.905818939208984,
        "lng": 8.591609001159668
      }
    },
    {
      "codice": "095039",
      "nome": "Palmas Arborea",
      "nomeStraniero": null,
      "codiceCatastale": "G286",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comunedipalmasarborea.it",
      "pec": "protocollo@pec.comunedipalmasarborea.it",
      "telefono": "+39 0783 28028",
      "fax": "+39 0783 28098",
      "coordinate": {
        "lat": 39.87654495239258,
        "lng": 8.64400863647461
      }
    },
    {
      "codice": "095040",
      "nome": "Pau",
      "nomeStraniero": null,
      "codiceCatastale": "G379",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.pau@tiscali.it",
      "pec": "comune.pau@legalmail.it",
      "telefono": "+39 0783 939002",
      "fax": "+39 0783 939282",
      "coordinate": {
        "lat": 39.791656494140625,
        "lng": 8.801679611206055
      }
    },
    {
      "codice": "095041",
      "nome": "Paulilatino",
      "nomeStraniero": null,
      "codiceCatastale": "G384",
      "cap": "09070",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "comunepa@tiscali.it",
      "pec": "protocollo@pec.comune.paulilatino.or.it",
      "telefono": "+39 0785 55179  -  55623",
      "fax": "+39 0785 55245",
      "coordinate": {
        "lat": 40.08333206176758,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "095042",
      "nome": "Pompu",
      "nomeStraniero": null,
      "codiceCatastale": "G817",
      "cap": "09095",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunedipompu@tiscali.it",
      "pec": "comune.pompu@legalmail.it",
      "telefono": "+39 0783 999111",
      "fax": "+39 0783 990035",
      "coordinate": {
        "lat": 39.724796295166016,
        "lng": 8.796196937561035
      }
    },
    {
      "codice": "095043",
      "nome": "Riola Sardo",
      "nomeStraniero": null,
      "codiceCatastale": "H301",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.riolasardo.or.it",
      "pec": "protocollo@pec.comune.riolasardo.or.it",
      "telefono": "+39 0783 410219",
      "fax": null,
      "coordinate": {
        "lat": 39.99388885498047,
        "lng": 8.541666984558105
      }
    },
    {
      "codice": "095044",
      "nome": "Ruinas",
      "nomeStraniero": null,
      "codiceCatastale": "F271",
      "cap": "09085",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comuneruinas@gmail.com",
      "pec": "protocollo.ruinas@legalmail.it",
      "telefono": "+39 0783 459023",
      "fax": "+39 0783 459291",
      "coordinate": {
        "lat": 39.90631103515625,
        "lng": 8.897049903869629
      }
    },
    {
      "codice": "095086",
      "nome": "Sagama",
      "nomeStraniero": null,
      "codiceCatastale": "H661",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "info@comune.sagama.or.it",
      "pec": "protocollo@pec.comune.sagama.or.it",
      "telefono": "+39 0785 34806",
      "fax": "+39 0785 34692",
      "coordinate": {
        "lat": 40.26122283935547,
        "lng": 8.577618598937988
      }
    },
    {
      "codice": "095045",
      "nome": "Samugheo",
      "nomeStraniero": null,
      "codiceCatastale": "H756",
      "cap": "09086",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "segreteria.samugheo@tiscali.it",
      "pec": "protocollo@pec.comune.samugheo.or.it",
      "telefono": "+39 0783 64023",
      "fax": "+39 0783 64697",
      "coordinate": {
        "lat": 39.94761657714844,
        "lng": 8.940768241882324
      }
    },
    {
      "codice": "095046",
      "nome": "San Nicolò d'Arcidano",
      "nomeStraniero": null,
      "codiceCatastale": "A368",
      "cap": "09097",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.sannicolodarcidano.or.it",
      "pec": "protocollo@pec.comune.sannicolodarcidano.or.it",
      "telefono": "+39 0783.88051",
      "fax": "+39 0783.88741",
      "coordinate": {
        "lat": 39.68354415893555,
        "lng": 8.646583557128906
      }
    },
    {
      "codice": "095050",
      "nome": "San Vero Milis",
      "nomeStraniero": null,
      "codiceCatastale": "I384",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.sanveromilis.or.it",
      "pec": "protocollo@pec.comune.sanveromilis.or.it",
      "telefono": "+39 0783 460110",
      "fax": "+39 0783 460130",
      "coordinate": {
        "lat": 40.01305389404297,
        "lng": 8.598610877990723
      }
    },
    {
      "codice": "095047",
      "nome": "Santa Giusta",
      "nomeStraniero": null,
      "codiceCatastale": "I205",
      "cap": "09096",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "segreteria@comune.santagiusta.or.it",
      "pec": "segretario@pec.comune.santagiusta.or.it",
      "telefono": "+39 0783 354521",
      "fax": "+39 0783 354535",
      "coordinate": {
        "lat": 39.880699157714844,
        "lng": 8.609160423278809
      }
    },
    {
      "codice": "095049",
      "nome": "Santu Lussurgiu",
      "nomeStraniero": null,
      "codiceCatastale": "I374",
      "cap": "09075",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comunesantulussurgiu.it",
      "pec": "protocollo.santulussurgiu@pec.comunas.it",
      "telefono": "+39 0783 5519210",
      "fax": "+39 0783 5519227",
      "coordinate": {
        "lat": 40.141944885253906,
        "lng": 8.651944160461426
      }
    },
    {
      "codice": "095051",
      "nome": "Scano di Montiferro",
      "nomeStraniero": null,
      "codiceCatastale": "I503",
      "cap": "09078",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "ufficiodemograficosc@tiscali.it",
      "pec": "protocollo.scanodimontiferro@pec.comunas.it",
      "telefono": "+39 0785 329174",
      "fax": "+39 0785 32666",
      "coordinate": {
        "lat": 40.21512985229492,
        "lng": 8.58731746673584
      }
    },
    {
      "codice": "095052",
      "nome": "Sedilo",
      "nomeStraniero": null,
      "codiceCatastale": "I564",
      "cap": "09076",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "info@comune.sedilo.or.it",
      "pec": "amministrativo@pec.comune.sedilo.or.it",
      "telefono": "+39 0785 56001",
      "fax": "+39 0785 59366",
      "coordinate": {
        "lat": 40.17416763305664,
        "lng": 8.919166564941406
      }
    },
    {
      "codice": "095053",
      "nome": "Seneghe",
      "nomeStraniero": null,
      "codiceCatastale": "I605",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.seneghe.or.it",
      "pec": "protocollo.seneghe@legalmail.it",
      "telefono": "+39 0783548012",
      "fax": "+39 0783 51652",
      "coordinate": {
        "lat": 40.08027648925781,
        "lng": 8.613055229187012
      }
    },
    {
      "codice": "095054",
      "nome": "Senis",
      "nomeStraniero": null,
      "codiceCatastale": "I609",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.senis@libero.it",
      "pec": "comune.senis@pec.it",
      "telefono": "+39 0783 969031",
      "fax": "+39 0783 969315",
      "coordinate": {
        "lat": 39.82356643676758,
        "lng": 8.938751220703125
      }
    },
    {
      "codice": "095055",
      "nome": "Sennariolo",
      "nomeStraniero": null,
      "codiceCatastale": "I613",
      "cap": "09078",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "comune.sennariolo@tiscali.it",
      "pec": "protocollo@pec.comune.sennariolo.or.it",
      "telefono": "+39 0785 32276",
      "fax": "+39 0785 32066",
      "coordinate": {
        "lat": 40.21224594116211,
        "lng": 8.556418418884277
      }
    },
    {
      "codice": "095056",
      "nome": "Siamaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "I717",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.siamaggiore.or.it",
      "pec": "protocollo@pec.comune.siamaggiore.or.it",
      "telefono": "+39 0783 3441",
      "fax": "+39 0783 3441220",
      "coordinate": {
        "lat": 39.949554443359375,
        "lng": 8.63426685333252
      }
    },
    {
      "codice": "095057",
      "nome": "Siamanna",
      "nomeStraniero": null,
      "codiceCatastale": "I718",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "amministrativo@comune.siamanna.or.it",
      "pec": "comune.siamanna.or@legalmail.it",
      "telefono": "+39 0783 449000",
      "fax": null,
      "coordinate": {
        "lat": 39.919349670410156,
        "lng": 8.762497901916504
      }
    },
    {
      "codice": "095076",
      "nome": "Siapiccia",
      "nomeStraniero": null,
      "codiceCatastale": "I721",
      "cap": "09084",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "amministrativo@comune.siapiccia.or.it",
      "pec": "comune.siapiccia.or@legalmail.it",
      "telefono": "+39 0783 449017",
      "fax": "+39 0783 449123",
      "coordinate": {
        "lat": 39.92744445800781,
        "lng": 8.762283325195312
      }
    },
    {
      "codice": "095058",
      "nome": "Simala",
      "nomeStraniero": null,
      "codiceCatastale": "I742",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.simala.or.it",
      "pec": "protocollo@pec.comune.simala.or.it",
      "telefono": "+39 0783 97208",
      "fax": "+39 0783 97216",
      "coordinate": {
        "lat": 39.72085189819336,
        "lng": 8.828115463256836
      }
    },
    {
      "codice": "095059",
      "nome": "Simaxis",
      "nomeStraniero": null,
      "codiceCatastale": "I743",
      "cap": "09088",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.simaxis.or.it",
      "pec": "protocollo@pec.comune.simaxis.or.it",
      "telefono": "+39 0783 4069220",
      "fax": "+39 0783 406928",
      "coordinate": {
        "lat": 39.72085189819336,
        "lng": 8.828115463256836
      }
    },
    {
      "codice": "095060",
      "nome": "Sini",
      "nomeStraniero": null,
      "codiceCatastale": "I749",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunesini@tiscali.it",
      "pec": "protocollo.sini@pec.comunas.it",
      "telefono": "+39 0783 936000",
      "fax": "+39 0783 936181",
      "coordinate": {
        "lat": 39.75395202636719,
        "lng": 8.906135559082031
      }
    },
    {
      "codice": "095061",
      "nome": "Siris",
      "nomeStraniero": null,
      "codiceCatastale": "I757",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comunedisiris@tiscali.it",
      "pec": "protocollo@pec.comune-siris-or.it",
      "telefono": "+39 0783 990208",
      "fax": "+39 0783 991800",
      "coordinate": {
        "lat": 39.71236801147461,
        "lng": 8.774642944335938
      }
    },
    {
      "codice": "095078",
      "nome": "Soddì",
      "nomeStraniero": null,
      "codiceCatastale": "I778",
      "cap": "09080",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "sindaco@comune.soddi.or.it",
      "pec": "protocollo@pec.comune.soddi.or.it",
      "telefono": "+39 0785 50024",
      "fax": "+39 0785 50175",
      "coordinate": {
        "lat": 40.129722595214844,
        "lng": 8.878055572509766
      }
    },
    {
      "codice": "095062",
      "nome": "Solarussa",
      "nomeStraniero": null,
      "codiceCatastale": "I791",
      "cap": "09077",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.solarussa@comune.solarussa.or.it",
      "pec": "protocollo@pec.comune.solarussa.or.it",
      "telefono": "+39 0783 37821",
      "fax": "+39 0783 374706",
      "coordinate": {
        "lat": 39.954444885253906,
        "lng": 8.671667098999023
      }
    },
    {
      "codice": "095063",
      "nome": "Sorradile",
      "nomeStraniero": null,
      "codiceCatastale": "I861",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.sorradile@tiscali.it",
      "pec": "comunesorradile@actaliscertymail.it",
      "telefono": "+39 0783 69023",
      "fax": "+39 0783 69404",
      "coordinate": {
        "lat": 40.10555648803711,
        "lng": 8.932777404785156
      }
    },
    {
      "codice": "095087",
      "nome": "Suni",
      "nomeStraniero": null,
      "codiceCatastale": "L006",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "tributi.suni@virgilio.it",
      "pec": "protocollo@pec.comune.suni.or.it",
      "telefono": "+39 0785 34273",
      "fax": "+39 0785 34170",
      "coordinate": {
        "lat": 40.28083419799805,
        "lng": 8.550195693969727
      }
    },
    {
      "codice": "095064",
      "nome": "Tadasuni",
      "nomeStraniero": null,
      "codiceCatastale": "L023",
      "cap": "09080",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "tadasuni@tiscali.it",
      "pec": "protocollo@pec.comune.tadasuni.or.it",
      "telefono": "+39 0785 50047",
      "fax": "+39 0785 50176",
      "coordinate": {
        "lat": 40.11027908325195,
        "lng": 8.883610725402832
      }
    },
    {
      "codice": "095065",
      "nome": "Terralba",
      "nomeStraniero": null,
      "codiceCatastale": "L122",
      "cap": "09098",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.terralba@tiscali.it",
      "pec": "ufficioelettorale@pec.comuneterralba.it",
      "telefono": "+39 0783 85301",
      "fax": "+39 0783 83341",
      "coordinate": {
        "lat": 39.719730377197266,
        "lng": 8.636283874511719
      }
    },
    {
      "codice": "095088",
      "nome": "Tinnura",
      "nomeStraniero": null,
      "codiceCatastale": "L172",
      "cap": "09090",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "protocollo@comune.tinnura.or.it",
      "pec": "protocollo.tinnura@pec.comunas.it",
      "telefono": "+39 0785 34839",
      "fax": "+39 0785 34139",
      "coordinate": {
        "lat": 40.269065856933594,
        "lng": 8.548178672790527
      }
    },
    {
      "codice": "095066",
      "nome": "Tramatza",
      "nomeStraniero": null,
      "codiceCatastale": "L321",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ufficioanagrafe@comune.tramatza.or.it",
      "pec": "protocollo@pec.comune.tramatza.or.it",
      "telefono": "+39 0783 508012",
      "fax": "+39 0783 508030",
      "coordinate": {
        "lat": 40.0029296875,
        "lng": 8.64950180053711
      }
    },
    {
      "codice": "095067",
      "nome": "Tresnuraghes",
      "nomeStraniero": null,
      "codiceCatastale": "L393",
      "cap": "09079",
      "prefisso": "0785",
      "provincia": "Oristano",
      "email": "anagrafe@comune.tresnuraghes.or.it",
      "pec": "protocollo@pec.comune.tresnuraghes.or.it",
      "telefono": "+39 0785 314020",
      "fax": "+39 0785 35720",
      "coordinate": {
        "lat": 40.25288772583008,
        "lng": 8.520755767822266
      }
    },
    {
      "codice": "095068",
      "nome": "Ulà Tirso",
      "nomeStraniero": null,
      "codiceCatastale": "L488",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.ulatirso.or.it",
      "pec": "comune.ulatirso@legalmail.it",
      "telefono": "+39 0783 61000",
      "fax": "+39 0783 61207",
      "coordinate": {
        "lat": 40.04522705078125,
        "lng": 8.903603553771973
      }
    },
    {
      "codice": "095069",
      "nome": "Uras",
      "nomeStraniero": null,
      "codiceCatastale": "L496",
      "cap": "09099",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "protocollo@comune.uras.or.it",
      "pec": "protocollo@pec.comune.uras.or.it",
      "telefono": "+39 0783 87891",
      "fax": "+39 0783 89423",
      "coordinate": {
        "lat": 39.698204040527344,
        "lng": 8.701515197753906
      }
    },
    {
      "codice": "095070",
      "nome": "Usellus",
      "nomeStraniero": null,
      "codiceCatastale": "L508",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune@comune.usellus.or.it",
      "pec": "protocollo@pec.comune.usellus.or.it",
      "telefono": "+39 0783 938001",
      "fax": "+39 0783 938407",
      "coordinate": {
        "lat": 39.807613372802734,
        "lng": 8.852405548095703
      }
    },
    {
      "codice": "095048",
      "nome": "Villa Sant'Antonio",
      "nomeStraniero": null,
      "codiceCatastale": "I298",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ufficio.protocollo@comune.villasantantonio.or.it",
      "pec": "protocollo@pec.comune.villasantantonio.or.it",
      "telefono": "+39 0783 964017",
      "fax": "+39 0783 964138",
      "coordinate": {
        "lat": 39.85886001586914,
        "lng": 8.90141487121582
      }
    },
    {
      "codice": "095073",
      "nome": "Villa Verde",
      "nomeStraniero": null,
      "codiceCatastale": "A609",
      "cap": "09090",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "comune.villaverde@tiscali.it",
      "pec": "comune.villaverde@legalmail.it",
      "telefono": "+39 0783 939000",
      "fax": "+39 0783 090356",
      "coordinate": {
        "lat": 39.795677185058594,
        "lng": 8.820219039916992
      }
    },
    {
      "codice": "095071",
      "nome": "Villanova Truschedu",
      "nomeStraniero": null,
      "codiceCatastale": "L991",
      "cap": "09084",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "villanova.truschedu@tin.it",
      "pec": "protocollo@pec.comune.villanovatruschedu.or.it",
      "telefono": "+39 0783 607603",
      "fax": "+39 0783 607722",
      "coordinate": {
        "lat": 39.988433837890625,
        "lng": 8.752026557922363
      }
    },
    {
      "codice": "095072",
      "nome": "Villaurbana",
      "nomeStraniero": null,
      "codiceCatastale": "M030",
      "cap": "09080",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "info@comune.villaurbana.or.it",
      "pec": "protocollo@pec.comune.villaurbana.or.it",
      "telefono": "+39 0783 44104",
      "fax": "+39 0783 44030",
      "coordinate": {
        "lat": 39.885372161865234,
        "lng": 8.77859115600586
      }
    },
    {
      "codice": "095074",
      "nome": "Zeddiani",
      "nomeStraniero": null,
      "codiceCatastale": "M153",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "robi.fais@comune.zeddiani.or.it",
      "pec": "ufficio.demografico@pec.comune.zeddiani.or.it",
      "telefono": "+39 0783 418000",
      "fax": "+39 0783 418267",
      "coordinate": {
        "lat": 39.989444732666016,
        "lng": 8.595555305480957
      }
    },
    {
      "codice": "095075",
      "nome": "Zerfaliu",
      "nomeStraniero": null,
      "codiceCatastale": "M168",
      "cap": "09070",
      "prefisso": "0783",
      "provincia": "Oristano",
      "email": "ufficioprotocollo@comune.zerfaliu.or.it",
      "pec": "protocollo@pec.comune.zerfaliu.or.it",
      "telefono": "+39 0783 27000",
      "fax": "+39 0783 27361",
      "coordinate": {
        "lat": 39.959999084472656,
        "lng": 8.710000038146973
      }
    }
  ],
  "Pescara": [
    {
      "codice": "068001",
      "nome": "Abbateggio",
      "nomeStraniero": null,
      "codiceCatastale": "A008",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comune@comune.abbateggio.pe.it",
      "pec": "abbateggio@pec.pescarainnova.it",
      "telefono": "+39 0858574223",
      "fax": "+39 0858574043",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 14.016666412353516
      }
    },
    {
      "codice": "068002",
      "nome": "Alanno",
      "nomeStraniero": null,
      "codiceCatastale": "A120",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "elettorale@comune.alanno.pe.it",
      "pec": "comune.alanno@pec.it",
      "telefono": "+39 085 8573101",
      "fax": "+39 0858573692",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 13.966666221618652
      }
    },
    {
      "codice": "068003",
      "nome": "Bolognano",
      "nomeStraniero": null,
      "codiceCatastale": "A945",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "ufficiodemografico@hotmail.com",
      "pec": "protocollo@pec.comunebolognano.it",
      "telefono": "+39 0858880132",
      "fax": "+39 0858880285",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 13.966666221618652
      }
    },
    {
      "codice": "068004",
      "nome": "Brittoli",
      "nomeStraniero": null,
      "codiceCatastale": "B193",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "brittoli@tiscali.it",
      "pec": "brittoli@pec.pescarainnova.it",
      "telefono": "+39 085 849189",
      "fax": "+39 085 849139",
      "coordinate": {
        "lat": 42.317047119140625,
        "lng": 13.861440658569336
      }
    },
    {
      "codice": "068005",
      "nome": "Bussi sul Tirino",
      "nomeStraniero": null,
      "codiceCatastale": "B294",
      "cap": "65022",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "ufficiostatocivile@comune.bussi.pe.it",
      "pec": "info.comunebussi@pec.it",
      "telefono": "+39 0859809931",
      "fax": "+39 0859809345",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "068006",
      "nome": "Cappelle sul Tavo",
      "nomeStraniero": null,
      "codiceCatastale": "B681",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "cappelle.tavo@libero.it",
      "pec": "cappellesultavo@pec.it",
      "telefono": "+39 085 4470135",
      "fax": "+39 085 4471172",
      "coordinate": {
        "lat": 42.46666717529297,
        "lng": 14.100000381469727
      }
    },
    {
      "codice": "068007",
      "nome": "Caramanico Terme",
      "nomeStraniero": null,
      "codiceCatastale": "B722",
      "cap": "65023",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anag.caramanico@so.net",
      "pec": "info@pec.comune.caramanicoterme.pe.it",
      "telefono": "+39 0859231051",
      "fax": "+39 085 9290202",
      "coordinate": {
        "lat": 42.150001525878906,
        "lng": 14.016666412353516
      }
    },
    {
      "codice": "068008",
      "nome": "Carpineto della Nora",
      "nomeStraniero": null,
      "codiceCatastale": "B827",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "info@omune.carpinetodellanora.pe.it",
      "pec": "carpinetodellanora@pec.pescarainnova.it",
      "telefono": "+39 085849138",
      "fax": "+39 085 849560",
      "coordinate": {
        "lat": 42.33333206176758,
        "lng": 13.866666793823242
      }
    },
    {
      "codice": "068009",
      "nome": "Castiglione a Casauria",
      "nomeStraniero": null,
      "codiceCatastale": "C308",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "ufficio.protocollo@comune.castiglioneacasauria.pe.it",
      "pec": "comunecastiglioneacasauria@legalmail.it",
      "telefono": "+39 0858884301",
      "fax": "+39 0858883104",
      "coordinate": {
        "lat": 42.23694610595703,
        "lng": 13.898333549499512
      }
    },
    {
      "codice": "068010",
      "nome": "Catignano",
      "nomeStraniero": null,
      "codiceCatastale": "C354",
      "cap": "65011",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe.catignano@tiscali.it",
      "pec": "comune.catignano@pec.it",
      "telefono": "+39 085841403",
      "fax": "+39 085841623",
      "coordinate": {
        "lat": 42.349998474121094,
        "lng": 13.949999809265137
      }
    },
    {
      "codice": "068011",
      "nome": "Cepagatti",
      "nomeStraniero": null,
      "codiceCatastale": "C474",
      "cap": "65012",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe.statocivile@comunecepagatti.it",
      "pec": "protocolloaffarigenerali@pec.comune.cepagatti.pe.it",
      "telefono": "+39 08597401",
      "fax": "+39 085974100",
      "coordinate": {
        "lat": 42.3658332824707,
        "lng": 14.076666831970215
      }
    },
    {
      "codice": "068012",
      "nome": "Città Sant'Angelo",
      "nomeStraniero": null,
      "codiceCatastale": "C750",
      "cap": "65013",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "elena.verrigni@comune.cittasantangelo.pe.it",
      "pec": "comune.cittasantangelo@pec.it",
      "telefono": "+39 0859696215",
      "fax": "+39 085969480",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "068013",
      "nome": "Civitaquana",
      "nomeStraniero": null,
      "codiceCatastale": "C771",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "protocollo@comune.civitaquana.pe.it",
      "pec": "info@pec.comune.civitaquana.pe.it",
      "telefono": "+39 085 848219",
      "fax": "+39 085 848540",
      "coordinate": {
        "lat": 42.33333206176758,
        "lng": 13.899999618530273
      }
    },
    {
      "codice": "068014",
      "nome": "Civitella Casanova",
      "nomeStraniero": null,
      "codiceCatastale": "C779",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comunecivitella@comune.civitellacasanova.pe.it",
      "pec": "civitellacas.segreteria@pec-comuni.it",
      "telefono": "+39 085845115",
      "fax": "+39 0858459958",
      "coordinate": {
        "lat": 42.36666488647461,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "068015",
      "nome": "Collecorvino",
      "nomeStraniero": null,
      "codiceCatastale": "C853",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "amministrativo.corvino@tin.it",
      "pec": "protocollo.collecorvino@raccomandata.eu",
      "telefono": "+39 085820511",
      "fax": "+39 0858205132",
      "coordinate": {
        "lat": 42.46666717529297,
        "lng": 14.016666412353516
      }
    },
    {
      "codice": "068016",
      "nome": "Corvara",
      "nomeStraniero": null,
      "codiceCatastale": "D078",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comunecorvara@libero.it",
      "pec": "info@pec.comune.corvara.pe.it",
      "telefono": "+39 0858889104",
      "fax": "+39 0858889104",
      "coordinate": {
        "lat": 42.275169372558594,
        "lng": 13.87353515625
      }
    },
    {
      "codice": "068017",
      "nome": "Cugnoli",
      "nomeStraniero": null,
      "codiceCatastale": "D201",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "info@comune.cugnoli.pe.it",
      "pec": "segreteria@pec.comune.cugnoli.pe.it",
      "telefono": "+39 085 8576131",
      "fax": "+39 085 8576722",
      "coordinate": {
        "lat": 42.30833435058594,
        "lng": 13.933111190795898
      }
    },
    {
      "codice": "068018",
      "nome": "Elice",
      "nomeStraniero": null,
      "codiceCatastale": "D394",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "demografici@comunedielice.gov.it",
      "pec": "postacert@pec.comunedielice.gov.it",
      "telefono": "+39 0859609138",
      "fax": "+39 0859609521",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 13.966666221618652
      }
    },
    {
      "codice": "068019",
      "nome": "Farindola",
      "nomeStraniero": null,
      "codiceCatastale": "D501",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafefarindola@micso.net",
      "pec": "segretario.farindola@pec.it",
      "telefono": "+39 085823131",
      "fax": "+39 085823384",
      "coordinate": {
        "lat": 42.441795349121094,
        "lng": 13.822381973266602
      }
    },
    {
      "codice": "068020",
      "nome": "Lettomanoppello",
      "nomeStraniero": null,
      "codiceCatastale": "E558",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "info@comune.lettomanoppello.pe.it",
      "pec": "comune.lettomanoppello@pec.it",
      "telefono": "+39 085 8570755",
      "fax": "+39 085 8570134",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "068021",
      "nome": "Loreto Aprutino",
      "nomeStraniero": null,
      "codiceCatastale": "E691",
      "cap": "65014",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe@comune.loretoaprutino.pe.it",
      "pec": "comune.loretoaprutino@pec.it",
      "telefono": "+39 085 829401",
      "fax": "+39 085 8291392",
      "coordinate": {
        "lat": 42.434722900390625,
        "lng": 13.983611106872559
      }
    },
    {
      "codice": "068022",
      "nome": "Manoppello",
      "nomeStraniero": null,
      "codiceCatastale": "E892",
      "cap": "65024",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comunemanoppello@tin.it",
      "pec": "comunemanoppelloprotocollo@legalmail.it",
      "telefono": "+39 0858590003",
      "fax": "+39 0858590895",
      "coordinate": {
        "lat": 42.25777816772461,
        "lng": 14.059721946716309
      }
    },
    {
      "codice": "068023",
      "nome": "Montebello di Bertona",
      "nomeStraniero": null,
      "codiceCatastale": "F441",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe@comune.montebellodibertona.pe.it",
      "pec": "protocollomontebello@pec.it",
      "telefono": "+39 0858286130",
      "fax": "+39 0858286463",
      "coordinate": {
        "lat": 42.41666793823242,
        "lng": 13.866666793823242
      }
    },
    {
      "codice": "068024",
      "nome": "Montesilvano",
      "nomeStraniero": null,
      "codiceCatastale": "F646",
      "cap": "65015",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "sindaco@comune.montesilvano.pe.it",
      "pec": "protocollo@comunemontesilvano.legalmail.it",
      "telefono": "+39 085 44811",
      "fax": "+39 085 834408",
      "coordinate": {
        "lat": 42.51184844970703,
        "lng": 14.137290000915527
      }
    },
    {
      "codice": "068025",
      "nome": "Moscufo",
      "nomeStraniero": null,
      "codiceCatastale": "F765",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "rosarita.rosetti@comunemoscufo.com",
      "pec": "protocollo@pec.comunedimoscufo.it",
      "telefono": "+39 085979131",
      "fax": "+39 085979485",
      "coordinate": {
        "lat": 42.43333435058594,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "068026",
      "nome": "Nocciano",
      "nomeStraniero": null,
      "codiceCatastale": "F908",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe@comune.nocciano.pe.it",
      "pec": "anagrafe@pec.comune.nocciano.pe.it",
      "telefono": "+39 085847135",
      "fax": "+39 085847602",
      "coordinate": {
        "lat": 42.33333206176758,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "068027",
      "nome": "Penne",
      "nomeStraniero": null,
      "codiceCatastale": "G438",
      "cap": "65017",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "info@comune.penne.pe.it",
      "pec": "comunepenne.segretariogenerale@servercertificato.it",
      "telefono": "+39 085821671",
      "fax": "+39 0858279045",
      "coordinate": {
        "lat": 42.45000076293945,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "068028",
      "nome": "Pescara",
      "nomeStraniero": null,
      "codiceCatastale": "G482",
      "cap": "65121",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "servizio.informatica@comune.pescara.it",
      "pec": "protocollo@pec.comune.pescara.it",
      "telefono": "+39 08542831",
      "fax": "+39 0854283466",
      "coordinate": {
        "lat": 42.46427917480469,
        "lng": 14.214188575744629
      }
    },
    {
      "codice": "068029",
      "nome": "Pescosansonesco",
      "nomeStraniero": null,
      "codiceCatastale": "G499",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe@pesconsansonesco.eu",
      "pec": "sindaco@pec.comune.pescosansonesco.pe.it",
      "telefono": "+39 0858889135",
      "fax": "+39 0858889776",
      "coordinate": {
        "lat": 42.25,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "068030",
      "nome": "Pianella",
      "nomeStraniero": null,
      "codiceCatastale": "G555",
      "cap": "65019",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "sindaco@comune.pianella.pe.it",
      "pec": "protocollo@pec.comune.pianella.pe.it",
      "telefono": "+39 085 97301",
      "fax": "+39 085 9730226",
      "coordinate": {
        "lat": 42.400001525878906,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "068031",
      "nome": "Picciano",
      "nomeStraniero": null,
      "codiceCatastale": "G589",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe.picciano@virgilio.it",
      "pec": "comunepicciano@legalmail.it",
      "telefono": "+39 0858285132",
      "fax": "+39 0858285430",
      "coordinate": {
        "lat": 42.475555419921875,
        "lng": 13.989444732666016
      }
    },
    {
      "codice": "068032",
      "nome": "Pietranico",
      "nomeStraniero": null,
      "codiceCatastale": "G621",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comune.pietranico@virgilio.it",
      "pec": "comune.pietranico@pec.it",
      "telefono": "+39 085 8884795",
      "fax": "+39 085 8883391",
      "coordinate": {
        "lat": 42.28333282470703,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "068033",
      "nome": "Popoli",
      "nomeStraniero": null,
      "codiceCatastale": "G878",
      "cap": "65026",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "info@comune.popoli.pe.it",
      "pec": "segreteria.popoli@viapec.net",
      "telefono": "+39 085 9870505",
      "fax": "+39 085 9870534",
      "coordinate": {
        "lat": 42.170833587646484,
        "lng": 13.831666946411133
      }
    },
    {
      "codice": "068034",
      "nome": "Roccamorice",
      "nomeStraniero": null,
      "codiceCatastale": "H425",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comune.roccamorice@tin.it",
      "pec": "comune.roccamorice@pec.buffetti.it",
      "telefono": "+39 0858572132",
      "fax": "+39 0858572358",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "068035",
      "nome": "Rosciano",
      "nomeStraniero": null,
      "codiceCatastale": "H562",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "sindaco@comunedirosciano.it",
      "pec": "affarigenerali.comunedirosciano@pec.it",
      "telefono": "+39 0858505432",
      "fax": "+39 0858505552",
      "coordinate": {
        "lat": 42.322269439697266,
        "lng": 14.045550346374512
      }
    },
    {
      "codice": "068036",
      "nome": "Salle",
      "nomeStraniero": null,
      "codiceCatastale": "H715",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe@comune.salle.pe.it",
      "pec": "anagrafe@pec.comune.salle.te.it",
      "telefono": "+39 085 928138",
      "fax": "+39 085 928149",
      "coordinate": {
        "lat": 42.177040100097656,
        "lng": 13.959589958190918
      }
    },
    {
      "codice": "068038",
      "nome": "San Valentino in Abruzzo Citeriore",
      "nomeStraniero": null,
      "codiceCatastale": "I376",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comune@sanvalentinoac.it",
      "pec": "info@pec.comune.sanvalentino.pe.it",
      "telefono": "+39 0858574131",
      "fax": "+39 0858574332",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "068037",
      "nome": "Sant'Eufemia a Maiella",
      "nomeStraniero": null,
      "codiceCatastale": "I332",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe.eufemia@libero.it",
      "pec": "info@pec.comune.santeufemiaamaiella.pe.it",
      "telefono": "+39 085 920116",
      "fax": "+39 085 920951",
      "coordinate": {
        "lat": 42.11666488647461,
        "lng": 14.016666412353516
      }
    },
    {
      "codice": "068039",
      "nome": "Scafa",
      "nomeStraniero": null,
      "codiceCatastale": "I482",
      "cap": "65027",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "elettorale@comune.scafa.pe.it",
      "pec": "elettorale@pec.comune.scafa.pe.it",
      "telefono": "+39 085/8541226",
      "fax": "+39 085/8543155",
      "coordinate": {
        "lat": 42.265750885009766,
        "lng": 13.996649742126465
      }
    },
    {
      "codice": "068040",
      "nome": "Serramonacesca",
      "nomeStraniero": null,
      "codiceCatastale": "I649",
      "cap": "65025",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comune@comune.serramonacesca.pe.it",
      "pec": "serramonacesca@pec.pescarainnova.it",
      "telefono": "+39 085859126",
      "fax": "+39 085859761",
      "coordinate": {
        "lat": 42.24849319458008,
        "lng": 14.093270301818848
      }
    },
    {
      "codice": "068041",
      "nome": "Spoltore",
      "nomeStraniero": null,
      "codiceCatastale": "I922",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "protocollo@comune.spoltore.pe.it",
      "pec": "protocollo@pec.comune.spoltore.pe.it",
      "telefono": "+39 085 49641",
      "fax": "+39 085 4962923",
      "coordinate": {
        "lat": 42.45500946044922,
        "lng": 14.139880180358887
      }
    },
    {
      "codice": "068042",
      "nome": "Tocco da Casauria",
      "nomeStraniero": null,
      "codiceCatastale": "L186",
      "cap": "65028",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "elettorale@comune.toccodacasauria.pe.it",
      "pec": "tributi.toccodacasauria@anutel.it",
      "telefono": "+39 085880533",
      "fax": "+39 085 8809118",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "068043",
      "nome": "Torre de' Passeri",
      "nomeStraniero": null,
      "codiceCatastale": "L263",
      "cap": "65029",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "anagrafe@comune.torredepasseri.pe.it",
      "pec": "comunetorredepasseri@omnibuspec.net",
      "telefono": "+39 0858884321",
      "fax": "+39 0858885196",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "068044",
      "nome": "Turrivalignani",
      "nomeStraniero": null,
      "codiceCatastale": "L475",
      "cap": "65020",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "com.turri@tiscali.it",
      "pec": "comuneturrivalignani.suap@legalmail.it",
      "telefono": "+39 085 8541277",
      "fax": "+39 085 8542148",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "068045",
      "nome": "Vicoli",
      "nomeStraniero": null,
      "codiceCatastale": "L846",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "comvicoli@micso.net",
      "pec": "info@pec.comune.vicoli.pe.it",
      "telefono": "+39 085 848140",
      "fax": "+39 085 848958",
      "coordinate": {
        "lat": 42.33333206176758,
        "lng": 13.899999618530273
      }
    },
    {
      "codice": "068046",
      "nome": "Villa Celiera",
      "nomeStraniero": null,
      "codiceCatastale": "L922",
      "cap": "65010",
      "prefisso": "085",
      "provincia": "Pescara",
      "email": "info@comune.villaceliera.pe.it",
      "pec": "protocollo.villaceliera@pec.it",
      "telefono": "+39 085846106",
      "fax": "+39 085846708",
      "coordinate": {
        "lat": 42.38016891479492,
        "lng": 13.859155654907227
      }
    }
  ],
  "Milano": [
    {
      "codice": "015002",
      "nome": "Abbiategrasso",
      "nomeStraniero": null,
      "codiceCatastale": "A010",
      "cap": "20081",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.abbiategrasso.mi.it",
      "pec": "comune.abbiategrasso@legalpec.it",
      "telefono": "+39 02 946921",
      "fax": "+39 02 94692207",
      "coordinate": {
        "lat": 45.400901794433594,
        "lng": 8.918499946594238
      }
    },
    {
      "codice": "015005",
      "nome": "Albairate",
      "nomeStraniero": null,
      "codiceCatastale": "A127",
      "cap": "20080",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "silvana.gualazzini@comune.albairate.mi.it",
      "pec": "comune.albairate.mi@pec.it",
      "telefono": "+39 02 9498131",
      "fax": "+39 02 94981324",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "015007",
      "nome": "Arconate",
      "nomeStraniero": null,
      "codiceCatastale": "A375",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@arconate.org",
      "pec": "comune.arconate@postecert.it",
      "telefono": "+39 0331 460461",
      "fax": "+39 0331 461448",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 8.850000381469727
      }
    },
    {
      "codice": "015009",
      "nome": "Arese",
      "nomeStraniero": null,
      "codiceCatastale": "A389",
      "cap": "20044",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.arese.mi.it",
      "pec": "protocollo@cert.comune.arese.mi.it",
      "telefono": "+39 02 935271",
      "fax": "+39 02 93580465",
      "coordinate": {
        "lat": 45.55305480957031,
        "lng": 9.077500343322754
      }
    },
    {
      "codice": "015010",
      "nome": "Arluno",
      "nomeStraniero": null,
      "codiceCatastale": "A413",
      "cap": "20004",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria.sindaco@comune.arluno.mi.it",
      "pec": "protocollo@pec.comune.arluno.mi.it",
      "telefono": "+39 02 9039921",
      "fax": "+39 02 90376645",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "015011",
      "nome": "Assago",
      "nomeStraniero": null,
      "codiceCatastale": "A473",
      "cap": "20057",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.assago.mi.it",
      "pec": "segreteria.generale@assago.legalmail.it",
      "telefono": "+39 02 457821",
      "fax": "+39 02 48843216",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "015250",
      "nome": "Baranzate",
      "nomeStraniero": null,
      "codiceCatastale": "A618",
      "cap": "20021",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.baranzate.mi.it",
      "pec": "protocollo@pec.comune.baranzate.mi.it",
      "telefono": "+39 02 39547529",
      "fax": "+39 02 38202365",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "015012",
      "nome": "Bareggio",
      "nomeStraniero": null,
      "codiceCatastale": "A652",
      "cap": "20008",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.bareggio.mi.it",
      "pec": "comune.bareggio@pec.regione.lombardia.it",
      "telefono": "+39 02.902.58.1",
      "fax": "+39 02.903.61.596",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "015014",
      "nome": "Basiano",
      "nomeStraniero": null,
      "codiceCatastale": "A697",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "carla.meroni@unione.basianomasate.mi.it",
      "pec": "basiano@pec.it",
      "telefono": "+39 02 950045207",
      "fax": "+39 02 95761764",
      "coordinate": {
        "lat": 45.57500076293945,
        "lng": 9.469444274902344
      }
    },
    {
      "codice": "015015",
      "nome": "Basiglio",
      "nomeStraniero": null,
      "codiceCatastale": "A699",
      "cap": "20079",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "anagrafe@comune.basiglio.mi.it",
      "pec": "basiglio@postacert.comune.basiglio.mi.it",
      "telefono": "+39 02 904522-25/28",
      "fax": null,
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "015016",
      "nome": "Bellinzago Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "A751",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sportellopolifunzionale.bellinzago@unioneaddamartesana.it",
      "pec": "protocollo.bellinzagolombardo@pec.it",
      "telefono": "+39 02 9538501",
      "fax": "+39 02 95382876",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "015019",
      "nome": "Bernate Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "A804",
      "cap": "20010",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.bernateticino.mi.it",
      "pec": "bernateticino@legalmail.it",
      "telefono": "+39 02.9754002",
      "fax": "+39 02.9754940",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "015022",
      "nome": "Besate",
      "nomeStraniero": null,
      "codiceCatastale": "A820",
      "cap": "20080",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.besate.mi.it",
      "pec": "protocollo.besate@legalpec.it",
      "telefono": "+39 02 9050906",
      "fax": "+39 02 90098006",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "015024",
      "nome": "Binasco",
      "nomeStraniero": null,
      "codiceCatastale": "A872",
      "cap": "20082",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.binasco.mi.it",
      "pec": "comune.binasco@legalmail.it",
      "telefono": "+39 02 9057811",
      "fax": "+39 02 90091725",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "015026",
      "nome": "Boffalora sopra Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "A920",
      "cap": "20010",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune@boffaloraticino.it",
      "pec": "comune.boffaloraticino@pec.it",
      "telefono": "+39 02 9723811",
      "fax": "+39 02 9755490",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "015027",
      "nome": "Bollate",
      "nomeStraniero": null,
      "codiceCatastale": "A940",
      "cap": "20021",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.bollate.mi.it",
      "pec": "comune.bollate@legalmail.it",
      "telefono": "+39 02.350051",
      "fax": "+39 02 35005395",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "015032",
      "nome": "Bresso",
      "nomeStraniero": null,
      "codiceCatastale": "B162",
      "cap": "20091",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "ufficio.protocollo@bresso.net",
      "pec": "comune.bresso@legalmail.it",
      "telefono": "+39 02614551",
      "fax": "+39 026100886",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "015035",
      "nome": "Bubbiano",
      "nomeStraniero": null,
      "codiceCatastale": "B235",
      "cap": "20088",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segretario@comune.bubbiano.mi.it",
      "pec": "comune.bubbiano@pec.regione.lombardia.it",
      "telefono": "+39 02 90848838",
      "fax": "+39 02 90849018",
      "coordinate": {
        "lat": 45.3272590637207,
        "lng": 9.015096664428711
      }
    },
    {
      "codice": "015036",
      "nome": "Buccinasco",
      "nomeStraniero": null,
      "codiceCatastale": "B240",
      "cap": "20090",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sindaco@comune.buccinasco.mi.it",
      "pec": "protocollo@cert.legalmail.it",
      "telefono": "+39 02 457971",
      "fax": "+39 02 48841184",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "015038",
      "nome": "Buscate",
      "nomeStraniero": null,
      "codiceCatastale": "B286",
      "cap": "20010",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "urpbuscate@comune.buscate.mi.it",
      "pec": "protocollo.buscate@legalmail.it",
      "telefono": "+39 0331 800161",
      "fax": "+39 0331 800590",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "015040",
      "nome": "Bussero",
      "nomeStraniero": null,
      "codiceCatastale": "B292",
      "cap": "20041",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "affari.generali@comune.bussero.mi.it",
      "pec": "protocollo.bussero@pec.it",
      "telefono": "+39 02 953331",
      "fax": "+39 02 9533337",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "015041",
      "nome": "Busto Garolfo",
      "nomeStraniero": null,
      "codiceCatastale": "B301",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "protocollo@comune.bustogarolfo.mi.it",
      "pec": "protocollo.bustogarolfo@sicurezzapostale.it",
      "telefono": "+39 0331 562011",
      "fax": "+39 0331 568703",
      "coordinate": {
        "lat": 45.54777908325195,
        "lng": 8.886666297912598
      }
    },
    {
      "codice": "015042",
      "nome": "Calvignasco",
      "nomeStraniero": null,
      "codiceCatastale": "B448",
      "cap": "20088",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.calvignasco.mi.it",
      "pec": "segreteria.calvignasco@pec.it",
      "telefono": "+39 02 90848862",
      "fax": "+39 02 90870241",
      "coordinate": {
        "lat": 45.32640075683594,
        "lng": 9.02649974822998
      }
    },
    {
      "codice": "015044",
      "nome": "Cambiago",
      "nomeStraniero": null,
      "codiceCatastale": "B461",
      "cap": "20040",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.cambiago.mi.it",
      "pec": "protocollo@pec.comunecambiago.com",
      "telefono": "+39 02 950821",
      "fax": "+39 02 9508211",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "015046",
      "nome": "Canegrate",
      "nomeStraniero": null,
      "codiceCatastale": "B593",
      "cap": "20010",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "segreteria@comune.canegrate.mi.it",
      "pec": "comune.canegrate@pec.regione.lombardia.it",
      "telefono": "+39 0331 463811",
      "fax": "+39 0331 401535",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "015050",
      "nome": "Carpiano",
      "nomeStraniero": null,
      "codiceCatastale": "B820",
      "cap": "20074",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.carpiano.mi.it",
      "pec": "comune.carpiano@pec.regione.lombardia.it",
      "telefono": "+39 02 9850941",
      "fax": "+39 02 985094102",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "015051",
      "nome": "Carugate",
      "nomeStraniero": null,
      "codiceCatastale": "B850",
      "cap": "20061",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.carugate.mi.it",
      "pec": "comune.carugate@legalmail.it",
      "telefono": "02921581",
      "fax": "0292158243",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "015055",
      "nome": "Casarile",
      "nomeStraniero": null,
      "codiceCatastale": "B938",
      "cap": "20059",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.casarile.mi.it",
      "pec": "protocollo@pec.comune.casarile.mi.it",
      "telefono": "+39 02 9057931",
      "fax": "+39 02 90092021",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "015058",
      "nome": "Casorezzo",
      "nomeStraniero": null,
      "codiceCatastale": "B989",
      "cap": "20003",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.casorezzo.mi.it",
      "pec": "comune.casorezzo@postecert.it",
      "telefono": "+39 02 9010012",
      "fax": "+39 02 90296960",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "015059",
      "nome": "Cassano d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "C003",
      "cap": "20062",
      "prefisso": "0363",
      "provincia": "Milano",
      "email": "info@comune.cassanodadda.mi.it",
      "pec": "protocollo@comune.cassanodadda.mi.legalmail.it",
      "telefono": "+39 0363366011",
      "fax": "+39 0363366239",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "015060",
      "nome": "Cassina de' Pecchi",
      "nomeStraniero": null,
      "codiceCatastale": "C014",
      "cap": "20051",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@cassinadepecchi.gov.it",
      "pec": "protocollo@pec.cassinadepecchi.gov.it",
      "telefono": "+39 02 954401",
      "fax": "+39 02 95343248",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "015061",
      "nome": "Cassinetta di Lugagnano",
      "nomeStraniero": null,
      "codiceCatastale": "C033",
      "cap": "20081",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.cassinettadilugagnano.mi.it",
      "pec": "comune.cassinettadilugagnano@pec.regione.lombardia.it",
      "telefono": "+39 02-86894035",
      "fax": "+39 02-86894036",
      "coordinate": {
        "lat": 45.42416763305664,
        "lng": 8.908611297607422
      }
    },
    {
      "codice": "015062",
      "nome": "Castano Primo",
      "nomeStraniero": null,
      "codiceCatastale": "C052",
      "cap": "20022",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "demografici@comune.castanoprimo.mi.it",
      "pec": "protocollo@cert.comune.castanoprimo.mi.it",
      "telefono": "+39 0331 888030",
      "fax": "+39 0331 877082",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "015070",
      "nome": "Cernusco sul Naviglio",
      "nomeStraniero": null,
      "codiceCatastale": "C523",
      "cap": "20063",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "demografici@comune.cernuscosulnaviglio.mi.it",
      "pec": "comune.cernuscosulnaviglio@pec.regione.lombardia.it",
      "telefono": "+39 02 92781",
      "fax": "+39 02 9278235",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "015071",
      "nome": "Cerro al Lambro",
      "nomeStraniero": null,
      "codiceCatastale": "C536",
      "cap": "20070",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.cerroallambro.mi.it",
      "pec": "cerroallambro@pacertificata.it",
      "telefono": "+39 02 982040334/46",
      "fax": "+39 02 98233347",
      "coordinate": {
        "lat": 45.33002853393555,
        "lng": 9.3385591506958
      }
    },
    {
      "codice": "015072",
      "nome": "Cerro Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C537",
      "cap": "20023",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "segreteria@cerromaggiore.org",
      "pec": "protocollo.cerromaggiore@legalmail.it",
      "telefono": "+39 0331 423 611",
      "fax": "+39 0331 423 750",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "015074",
      "nome": "Cesano Boscone",
      "nomeStraniero": null,
      "codiceCatastale": "C565",
      "cap": "20090",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "urp@comune.cesano-boscone.mi.it",
      "pec": "protocollo@pec.comune.cesano-boscone.mi.it",
      "telefono": "+39 02 486941",
      "fax": "+39 02 48694576",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "015076",
      "nome": "Cesate",
      "nomeStraniero": null,
      "codiceCatastale": "C569",
      "cap": "20031",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune.cesate@comune.cesate.mi.it",
      "pec": "protocollo@comune.cesate.mi.legalmail.it",
      "telefono": "+39 02 994711",
      "fax": "+39 02 99069910",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "015077",
      "nome": "Cinisello Balsamo",
      "nomeStraniero": null,
      "codiceCatastale": "C707",
      "cap": "20092",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria.sindaco@comune.cinisello-balsamo.mi.it",
      "pec": "comune.cinisellobalsamo@pec.regione.lombardia.it",
      "telefono": "+39 02660231",
      "fax": "+39 0266011464",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "015078",
      "nome": "Cisliano",
      "nomeStraniero": null,
      "codiceCatastale": "C733",
      "cap": "20046",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.cisliano.mi.it",
      "pec": "protocollo@pec.comune.cisliano.mi.it",
      "telefono": "+39 02 9038701",
      "fax": "+39 02 9018088",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "015081",
      "nome": "Cologno Monzese",
      "nomeStraniero": null,
      "codiceCatastale": "C895",
      "cap": "20093",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.colognomonzese.mi.it",
      "pec": "protocollo.comunecolognomonzese@legalmail.it",
      "telefono": "+39 02 253081",
      "fax": "+39 02 25308294",
      "coordinate": {
        "lat": 45.52860641479492,
        "lng": 9.27827262878418
      }
    },
    {
      "codice": "015082",
      "nome": "Colturano",
      "nomeStraniero": null,
      "codiceCatastale": "C908",
      "cap": "20075",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sindaco@comune.colturano.mi.it",
      "pec": "comune.colturano@legalmail.it",
      "telefono": "+39 02 98170118",
      "fax": "+39 02 98170119",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "015085",
      "nome": "Corbetta",
      "nomeStraniero": null,
      "codiceCatastale": "C986",
      "cap": "20011",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "servizio.protocollo@comune.corbetta.mi.it",
      "pec": "comune.corbetta@postemailcertificata.it",
      "telefono": "+39 02 972041",
      "fax": "+39 02 9770429",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "015086",
      "nome": "Cormano",
      "nomeStraniero": null,
      "codiceCatastale": "D013",
      "cap": "20032",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune.cormano@comune.cormano.mi.it",
      "pec": "comune.cormano@comune.cormano.mi.legalmailpa.it",
      "telefono": "+39 02 663241",
      "fax": "+39 02 66301773",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "015087",
      "nome": "Cornaredo",
      "nomeStraniero": null,
      "codiceCatastale": "D018",
      "cap": "20007",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.cornaredo.mi.it",
      "pec": "protocollo@pec.comune.cornaredo.mi.it",
      "telefono": "+39 02 932631",
      "fax": "+39 02 93263213",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "015093",
      "nome": "Corsico",
      "nomeStraniero": null,
      "codiceCatastale": "D045",
      "cap": "20094",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "ufficio.protocollo@comune.corsico.mi.it",
      "pec": "ufficioprotocollo@cert.comune.corsico.mi.it",
      "telefono": "+39 02.44801",
      "fax": "+39 02 4409906",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "015096",
      "nome": "Cuggiono",
      "nomeStraniero": null,
      "codiceCatastale": "D198",
      "cap": "20012",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.cuggiono.mi.it",
      "pec": "comune.cuggiono@postecert.it",
      "telefono": "+39 02 972631",
      "fax": "+39 02 97240569",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "015097",
      "nome": "Cusago",
      "nomeStraniero": null,
      "codiceCatastale": "D229",
      "cap": "20047",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.cusago.mi.it",
      "pec": "protocollo.cusago.pec@legalmail.it",
      "telefono": "+39 02 901661",
      "fax": "+39 02 90119890",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "015098",
      "nome": "Cusano Milanino",
      "nomeStraniero": null,
      "codiceCatastale": "D231",
      "cap": "20095",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.cusano-milanino.mi.it",
      "pec": "comune.cusano-milanino@pec.regione.lombardia.it",
      "telefono": "+39 02 619031",
      "fax": "+39 02 6197271",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "015099",
      "nome": "Dairago",
      "nomeStraniero": null,
      "codiceCatastale": "D244",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "amministrazione@comune.dairago.mi.it",
      "pec": "comune.dairago@postecert.it",
      "telefono": "+39 0331/431517",
      "fax": "+39 0331/430001",
      "coordinate": {
        "lat": 45.56999969482422,
        "lng": 8.866389274597168
      }
    },
    {
      "codice": "015101",
      "nome": "Dresano",
      "nomeStraniero": null,
      "codiceCatastale": "D367",
      "cap": "20070",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "anagrafe@comune.dresano.mi.it",
      "pec": "comune.dresano@pec.regione.lombardia.it",
      "telefono": "+39 02 9827851",
      "fax": "+39 02 98274041",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "015103",
      "nome": "Gaggiano",
      "nomeStraniero": null,
      "codiceCatastale": "D845",
      "cap": "20083",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune@comune.gaggiano.mi.it",
      "pec": "comune.gaggiano@pec.regione.lombardia.it",
      "telefono": "+39 02 9089921",
      "fax": "+39 02 90899289",
      "coordinate": {
        "lat": 45.40483856201172,
        "lng": 9.034879684448242
      }
    },
    {
      "codice": "015105",
      "nome": "Garbagnate Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "D912",
      "cap": "20024",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.garbagnate-milanese.mi.it",
      "pec": "comune@garbagnate-milanese.legalmail.it",
      "telefono": "+39 02.99073200",
      "fax": "+39 02.9952515",
      "coordinate": {
        "lat": 45.57712936401367,
        "lng": 9.081399917602539
      }
    },
    {
      "codice": "015106",
      "nome": "Gessate",
      "nomeStraniero": null,
      "codiceCatastale": "D995",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.gessate.mi.it",
      "pec": "comune.gessate@legalmail.it",
      "telefono": "+39 02-9592991",
      "fax": "+39 02-95382853",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.433333396911621
      }
    },
    {
      "codice": "015108",
      "nome": "Gorgonzola",
      "nomeStraniero": null,
      "codiceCatastale": "E094",
      "cap": "20064",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.gorgonzola.mi.it",
      "pec": "comune.gorgonzola@cert.legalmail.it",
      "telefono": "+39 02 957011",
      "fax": "+39 02 95701231",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "015110",
      "nome": "Grezzago",
      "nomeStraniero": null,
      "codiceCatastale": "E170",
      "cap": "20056",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sindaco@comune.grezzago.mi.it",
      "pec": "comune.grezzago@pec.regione.lombardia.it",
      "telefono": "+39 02/9093691",
      "fax": "+39 02/90967015",
      "coordinate": {
        "lat": 45.59111022949219,
        "lng": 9.494999885559082
      }
    },
    {
      "codice": "015112",
      "nome": "Gudo Visconti",
      "nomeStraniero": null,
      "codiceCatastale": "E258",
      "cap": "20088",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@gudo.it",
      "pec": "protocollo@pec.gudo.it",
      "telefono": "+39 02 94961764",
      "fax": "+39 02 000000",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9
      }
    },
    {
      "codice": "015113",
      "nome": "Inveruno",
      "nomeStraniero": null,
      "codiceCatastale": "E313",
      "cap": "20010",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sito@comune.inveruno.mi.it",
      "pec": "comune.inveruno@legalmail.it",
      "telefono": "+39 0297 288137",
      "fax": "+39 0297 289483",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8.850000381469727
      }
    },
    {
      "codice": "015114",
      "nome": "Inzago",
      "nomeStraniero": null,
      "codiceCatastale": "E317",
      "cap": "20065",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comuneaperto@comune.inzago.mi.it",
      "pec": "comuneinzago@legalmail.it",
      "telefono": "+39 02 954398",
      "fax": "+39 02 95310447",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "015115",
      "nome": "Lacchiarella",
      "nomeStraniero": null,
      "codiceCatastale": "E395",
      "cap": "20084",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "posta@comune.lacchiarella.mi.it",
      "pec": "protocollo@pec.comune.lacchiarella.mi.it",
      "telefono": "+39 02 9057831",
      "fax": "+39 02 90076622",
      "coordinate": {
        "lat": 45.32500076293945,
        "lng": 9.140000343322754
      }
    },
    {
      "codice": "015116",
      "nome": "Lainate",
      "nomeStraniero": null,
      "codiceCatastale": "E415",
      "cap": "20045",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.lainate.mi.it",
      "pec": "comune.lainate@pec.regione.lombardia.it",
      "telefono": "+39 02 935981",
      "fax": "+39 02 93572036",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "015118",
      "nome": "Legnano",
      "nomeStraniero": null,
      "codiceCatastale": "E514",
      "cap": "20025",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "uff.protocollo@legnano.org",
      "pec": "comune.legnano@cert.legalmail.it",
      "telefono": "+39 0331/471111",
      "fax": "+39 0331 471355",
      "coordinate": {
        "lat": 45.57805633544922,
        "lng": 8.918333053588867
      }
    },
    {
      "codice": "015122",
      "nome": "Liscate",
      "nomeStraniero": null,
      "codiceCatastale": "E610",
      "cap": "20050",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sportellopolifunzionale.liscate@unioneaddamartesana.it",
      "pec": "demografici.comune.liscate@pec.regione.lombardia.it",
      "telefono": "+39 02 95051523",
      "fax": "+39 02 95350200",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "015125",
      "nome": "Locate di Triulzi",
      "nomeStraniero": null,
      "codiceCatastale": "E639",
      "cap": "20085",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune@comune.locateditriulzi.mi.it",
      "pec": "comune@pec.comune.locateditriulzi.mi.it",
      "telefono": "+39 02 904841",
      "fax": "+39 02 90731200",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "015130",
      "nome": "Magenta",
      "nomeStraniero": null,
      "codiceCatastale": "E801",
      "cap": "20013",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comunedimagenta.it",
      "pec": "egov.magenta@cert.poliscomuneamico.net",
      "telefono": "+39 02-97351",
      "fax": "+39 02-9735211",
      "coordinate": {
        "lat": 45.46025085449219,
        "lng": 8.87661075592041
      }
    },
    {
      "codice": "015131",
      "nome": "Magnago",
      "nomeStraniero": null,
      "codiceCatastale": "E819",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@comune.magnago.mi.it",
      "pec": "info@pec.comune.magnago.mi.it",
      "telefono": "+39 0331 658305",
      "fax": "+39 0331 306205",
      "coordinate": {
        "lat": 45.579200744628906,
        "lng": 8.802450180053711
      }
    },
    {
      "codice": "015134",
      "nome": "Marcallo con Casone",
      "nomeStraniero": null,
      "codiceCatastale": "E921",
      "cap": "20010",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "anagrafe@marcallo.it",
      "pec": "comune.marcalloconcasone@pec.regione.lombardia.it",
      "telefono": "+39 02 9796137",
      "fax": "+39 02 9796134",
      "coordinate": {
        "lat": 45.4981803894043,
        "lng": 8.876350402832031
      }
    },
    {
      "codice": "015136",
      "nome": "Masate",
      "nomeStraniero": null,
      "codiceCatastale": "F003",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "Alessandra.Tresoldi@unione.basianomasate.mi.it",
      "pec": "masate@pec.it",
      "telefono": "+39 02 950045206",
      "fax": "+39 02 95761764",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "015139",
      "nome": "Mediglia",
      "nomeStraniero": null,
      "codiceCatastale": "F084",
      "cap": "20076",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "ced@comune.mediglia.mi.it",
      "pec": "comune.mediglia@pec.regione.lombardia.it",
      "telefono": "+39 02 9066201",
      "fax": "+39 02 90661414",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "015140",
      "nome": "Melegnano",
      "nomeStraniero": null,
      "codiceCatastale": "F100",
      "cap": "20077",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sindaco.bertoli@comune.melegnano.mi.it",
      "pec": "protocollo.melegnano@legalpec.it",
      "telefono": "+39 02 982081",
      "fax": "+39 02 9837669",
      "coordinate": {
        "lat": 45.35874938964844,
        "lng": 9.323769569396973
      }
    },
    {
      "codice": "015142",
      "nome": "Melzo",
      "nomeStraniero": null,
      "codiceCatastale": "F119",
      "cap": "20066",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "spaziocitta@comune.melzo.mi.it",
      "pec": "comunemelzo@pec.it",
      "telefono": "+39 02 951201",
      "fax": "+39 02 95120276",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "015144",
      "nome": "Mesero",
      "nomeStraniero": null,
      "codiceCatastale": "F155",
      "cap": "20010",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.mesero.mi.it",
      "pec": "comune.mesero@pec.regione.lombardia.it",
      "telefono": "+39 02.97285013",
      "fax": "+39 02.97289674",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.850000381469727
      }
    },
    {
      "codice": "015146",
      "nome": "Milano",
      "nomeStraniero": null,
      "codiceCatastale": "F205",
      "cap": "20141",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "stl.informagiovani@comune.milano.it",
      "pec": "protocollo@postacert.comune.milano.it",
      "telefono": "+39 02 0202",
      "fax": "+39 02 88452170",
      "coordinate": {
        "lat": 45.46694564819336,
        "lng": 9.1899995803833
      }
    },
    {
      "codice": "015150",
      "nome": "Morimondo",
      "nomeStraniero": null,
      "codiceCatastale": "D033",
      "cap": "20081",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "elettorale@comune.morimondo.mi.it",
      "pec": "comune.morimondo@pec.regione.lombardia.it",
      "telefono": "+39 02 94961941",
      "fax": "+39 02 94961950",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "015151",
      "nome": "Motta Visconti",
      "nomeStraniero": null,
      "codiceCatastale": "F783",
      "cap": "20086",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.mottavisconti.mi.it",
      "pec": "segreteria@pec.comune.mottavisconti.mi.it",
      "telefono": "+39 02 90008111",
      "fax": "+39 02 90009071",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9
      }
    },
    {
      "codice": "015154",
      "nome": "Nerviano",
      "nomeStraniero": null,
      "codiceCatastale": "F874",
      "cap": "20014",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@comune.nerviano.mi.it",
      "pec": "urp@pec.comune.nerviano.mi.it",
      "telefono": "+39 0331 43891",
      "fax": "+39 0331 438906",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "015155",
      "nome": "Nosate",
      "nomeStraniero": null,
      "codiceCatastale": "F939",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "segreteria@comune.nosate.mi.it",
      "pec": "comune.nosate@pec.regione.lombardia.it",
      "telefono": "+39 0331 899530",
      "fax": "+39 0331 871753",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "015157",
      "nome": "Novate Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "F955",
      "cap": "20026",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.novate-milanese.mi.it",
      "pec": "comune.novatemilanese@legalmail.it",
      "telefono": "+39 02 354731",
      "fax": "+39 02 33240000",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "015158",
      "nome": "Noviglio",
      "nomeStraniero": null,
      "codiceCatastale": "F968",
      "cap": "20082",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.noviglio.mi.it",
      "pec": "protocollo.noviglio@legalpec.it",
      "telefono": "+39 02.9006066",
      "fax": "+39 02.9006282",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "015159",
      "nome": "Opera",
      "nomeStraniero": null,
      "codiceCatastale": "G078",
      "cap": "20073",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.opera.mi.it",
      "pec": "protocollo.opera@cert.legalmail.it",
      "telefono": "+39 02 53007",
      "fax": "+39 02 57604952",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "015164",
      "nome": "Ossona",
      "nomeStraniero": null,
      "codiceCatastale": "G181",
      "cap": "20002",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "municipio@comunediossona.it",
      "pec": "posta.certificata@pec.comunediossona.it",
      "telefono": "+39 02 9010003",
      "fax": "+39 02 90296934",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "015165",
      "nome": "Ozzero",
      "nomeStraniero": null,
      "codiceCatastale": "G206",
      "cap": "20080",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "demografici@comune.ozzero.mi.it",
      "pec": "protocollo.ozzero@legalpec.it",
      "telefono": "+39 02 9400401",
      "fax": "+39 02 9407510",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "015166",
      "nome": "Paderno Dugnano",
      "nomeStraniero": null,
      "codiceCatastale": "G220",
      "cap": "20037",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria.affarigenerali@comune.paderno-dugnano.mi.it",
      "pec": "comune.paderno-dugnano@pec.regione.lombardia.it",
      "telefono": "+39 02.910041",
      "fax": "+39 02.91004406",
      "coordinate": {
        "lat": 45.57194519042969,
        "lng": 9.169166564941406
      }
    },
    {
      "codice": "015167",
      "nome": "Pantigliate",
      "nomeStraniero": null,
      "codiceCatastale": "G316",
      "cap": "20048",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.pantigliate.mi.it",
      "pec": "comune.pantigliate@legalmail.it",
      "telefono": "+39 02 9068861",
      "fax": "+39 02 906886210",
      "coordinate": {
        "lat": 45.43526077270508,
        "lng": 9.35219955444336
      }
    },
    {
      "codice": "015168",
      "nome": "Parabiago",
      "nomeStraniero": null,
      "codiceCatastale": "G324",
      "cap": "20015",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "comune@comune.parabiago.mi.it",
      "pec": "comune@cert.comune.parabiago.mi.it",
      "telefono": "+39 0331 406011",
      "fax": "+39 0331 552750",
      "coordinate": {
        "lat": 45.558319091796875,
        "lng": 8.947689056396484
      }
    },
    {
      "codice": "015169",
      "nome": "Paullo",
      "nomeStraniero": null,
      "codiceCatastale": "G385",
      "cap": "20067",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.paullo.mi.it",
      "pec": "protocollo@pec.comune.paullo.mi.it",
      "telefono": "+39 02.9062691",
      "fax": "+39 02.90633292",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "015170",
      "nome": "Pero",
      "nomeStraniero": null,
      "codiceCatastale": "C013",
      "cap": "20016",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune@comune.pero.mi.it",
      "pec": "protocollo@comune.pero.mi.legalmail.it",
      "telefono": "+39 02 3537111",
      "fax": "+39 02 3390575",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "015171",
      "nome": "Peschiera Borromeo",
      "nomeStraniero": null,
      "codiceCatastale": "G488",
      "cap": "20068",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sindaco@comune.peschieraborromeo.mi.it",
      "pec": "comune.peschieraborromeo@pec.regione.lombardia.it",
      "telefono": "+39 0251690274",
      "fax": "+39 0255301462",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "015172",
      "nome": "Pessano con Bornago",
      "nomeStraniero": null,
      "codiceCatastale": "G502",
      "cap": "20042",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.pessanoconbornago.mi.it",
      "pec": "comune.pessanoconbornago@legalmail.it",
      "telefono": "+39 02 9596971",
      "fax": "+39 02 959697226",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "015173",
      "nome": "Pieve Emanuele",
      "nomeStraniero": null,
      "codiceCatastale": "G634",
      "cap": "20072",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "urp.pieve@comune.pieveemanuele.mi.it",
      "pec": "protocollo.pieveemanuele@legalmail.it",
      "telefono": "+39 02 907881",
      "fax": "+39 02 90788208",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "015175",
      "nome": "Pioltello",
      "nomeStraniero": null,
      "codiceCatastale": "G686",
      "cap": "20096",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "urp@comune.pioltello.mi.it",
      "pec": "protocollo@pec.comune.pioltello.mi.it",
      "telefono": "+39 02 923661",
      "fax": "+39 02 92161258",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "015176",
      "nome": "Pogliano Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "G772",
      "cap": "20005",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@poglianomilanese.org",
      "pec": "comune.poglianomilanese@cert.legalmail.it",
      "telefono": "+39 02 9396441",
      "fax": "+39 02 93549220",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9
      }
    },
    {
      "codice": "015177",
      "nome": "Pozzo d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "G955",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segretario@comune.pozzodadda.mi.it",
      "pec": "comune.pozzodadda@legalmail.it",
      "telefono": "+39 02 909901",
      "fax": "+39 02 90968602",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.5
      }
    },
    {
      "codice": "015178",
      "nome": "Pozzuolo Martesana",
      "nomeStraniero": null,
      "codiceCatastale": "G965",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.pozzuolomartesana.mi.it",
      "pec": "comune.pozzuolomartesana.mi@legalmail.it",
      "telefono": "+39 02 9509081",
      "fax": "+39 02 95357307",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "015179",
      "nome": "Pregnana Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "H026",
      "cap": "20006",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.pregnana.mi.it",
      "pec": "protocollo@pec.comune.pregnana.mi.it",
      "telefono": "+39 02 939671",
      "fax": "+39 02 93590747",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "015181",
      "nome": "Rescaldina",
      "nomeStraniero": null,
      "codiceCatastale": "H240",
      "cap": "20027",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@comune.rescaldina.mi.it",
      "pec": "comune.rescaldina@pec.regione.lombardia.it",
      "telefono": "+39 0331 467811",
      "fax": "+39 0331 464755",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "015182",
      "nome": "Rho",
      "nomeStraniero": null,
      "codiceCatastale": "H264",
      "cap": "20017",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.rho.mi.it",
      "pec": "pec.protocollo.comunerho@legalmail.it",
      "telefono": "+39 02 933321",
      "fax": "+39 02 93332505",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "015183",
      "nome": "Robecchetto con Induno",
      "nomeStraniero": null,
      "codiceCatastale": "H371",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@comune.robecchetto-con-induno.mi.it",
      "pec": "comune.robecchetto@postecert.it",
      "telefono": "+39 0331 875600",
      "fax": "+39 0331 876249",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "015184",
      "nome": "Robecco sul Naviglio",
      "nomeStraniero": null,
      "codiceCatastale": "H373",
      "cap": "20087",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.robeccosulnaviglio.mi.it",
      "pec": "robeccosulnaviglio@halleycert.it",
      "telefono": "+39 02 9497801",
      "fax": "+39 02 9471785",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "015185",
      "nome": "Rodano",
      "nomeStraniero": null,
      "codiceCatastale": "H470",
      "cap": "20053",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.rodano.mi.it",
      "pec": "comune.rodano@pec.regione.lombardia.it",
      "telefono": "+39 02 9595951",
      "fax": "+39 02 959595225",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "015188",
      "nome": "Rosate",
      "nomeStraniero": null,
      "codiceCatastale": "H560",
      "cap": "20088",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.rosate.mi.it",
      "pec": "comune.rosate@pec.regione.lombardia.it",
      "telefono": "+39 02 908301",
      "fax": "+39 02 90848046",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "015189",
      "nome": "Rozzano",
      "nomeStraniero": null,
      "codiceCatastale": "H623",
      "cap": "20089",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "archivio.protocollo@comune.rozzano.mi.it",
      "pec": "protocollo@pec.comune.rozzano.mi.it",
      "telefono": "+39 02 8226284  800313232",
      "fax": "+39 02 89200788",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "015191",
      "nome": "San Colombano al Lambro",
      "nomeStraniero": null,
      "codiceCatastale": "H803",
      "cap": "20078",
      "prefisso": "0371",
      "provincia": "Milano",
      "email": "segreteria@comune.sancolombanoallambro.mi.it",
      "pec": "comune.sancolombano@cert.saga.it",
      "telefono": "+39 0371 2931",
      "fax": "+39 0371 897965",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "015192",
      "nome": "San Donato Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "H827",
      "cap": "20097",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune@comune.sandonatomilanese.mi.it",
      "pec": "protocollo@cert.comune.sandonatomilanese.mi.it",
      "telefono": "+39 02 527721",
      "fax": "+39 02 5275096",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "015194",
      "nome": "San Giorgio su Legnano",
      "nomeStraniero": null,
      "codiceCatastale": "H884",
      "cap": "20010",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@sangiorgiosl.org",
      "pec": "comune.sangiorgiosulegnano@cert.legalmail.it",
      "telefono": "+39 0331 401564",
      "fax": "+39 0331 403837",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "015195",
      "nome": "San Giuliano Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "H930",
      "cap": "20098",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@sangiulianonline.it",
      "pec": "comune.sangiulianomilanese@cert.legalmail.it",
      "telefono": "+39 02 982071",
      "fax": "+39 02 98241110",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "015201",
      "nome": "San Vittore Olona",
      "nomeStraniero": null,
      "codiceCatastale": "I409",
      "cap": "20028",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "anagrafe@sanvittoreolona.org",
      "pec": "comune.sanvittoreolona@pec.regione.lombardia.it",
      "telefono": "+39 0331 488905",
      "fax": "+39 0331 519428",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "015202",
      "nome": "San Zenone al Lambro",
      "nomeStraniero": null,
      "codiceCatastale": "I415",
      "cap": "20070",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.sanzenoneallambro.mi.it",
      "pec": "sindaco.comune.sanzenone@pec.regione.lombardia.it",
      "telefono": "+39 02 98870024",
      "fax": "+39 02 987736",
      "coordinate": {
        "lat": 45.325679779052734,
        "lng": 9.355046272277832
      }
    },
    {
      "codice": "015200",
      "nome": "Santo Stefano Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "I361",
      "cap": "20010",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "comune@comune.santostefanoticino.mi.it",
      "pec": "comune.santostefanoticino@pec.regione.lombardia.it",
      "telefono": "+39 02.9723861",
      "fax": "+39 02.97238624",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "015204",
      "nome": "Sedriano",
      "nomeStraniero": null,
      "codiceCatastale": "I566",
      "cap": "20018",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.sedriano.mi.it",
      "pec": "comune.sedriano@postemailcertificata.it",
      "telefono": "+39 02.903981",
      "fax": "+39 02.90111099",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "015205",
      "nome": "Segrate",
      "nomeStraniero": null,
      "codiceCatastale": "I577",
      "cap": "20054",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "urp@comune.segrate.mi.it",
      "pec": "segrate@postemailcertificata.it",
      "telefono": "+39 02/26.90.21",
      "fax": "+39 02/2133751",
      "coordinate": {
        "lat": 45.48997116088867,
        "lng": 9.295331001281738
      }
    },
    {
      "codice": "015206",
      "nome": "Senago",
      "nomeStraniero": null,
      "codiceCatastale": "I602",
      "cap": "20030",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.senago.mi.it",
      "pec": "postacertificata@comune.senago.legalmail.it",
      "telefono": "+39 02 990831",
      "fax": "+39 02 99010967",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "015209",
      "nome": "Sesto San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "I690",
      "cap": "20099",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "urp@sestosg.net",
      "pec": "comune.sestosg@legalmail.it",
      "telefono": "+39 02.24961",
      "fax": "+39 02 2496541",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "015210",
      "nome": "Settala",
      "nomeStraniero": null,
      "codiceCatastale": "I696",
      "cap": "20049",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "ragioneria@comune.settala.mi.it",
      "pec": "postacertificata@cert.comune.settala.mi.it",
      "telefono": "+39 02 9507591",
      "fax": "+39 02 950759240",
      "coordinate": {
        "lat": 45.455833435058594,
        "lng": 9.391944885253906
      }
    },
    {
      "codice": "015211",
      "nome": "Settimo Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "I700",
      "cap": "20019",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.settimomilanese.mi.it",
      "pec": "protocollo@postacert.comune.settimomilanese.mi.it",
      "telefono": "+39 02 335091",
      "fax": "+39 02 33509630",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "015213",
      "nome": "Solaro",
      "nomeStraniero": null,
      "codiceCatastale": "I786",
      "cap": "20033",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.solaro.mi.it",
      "pec": "comune.solaro@pec.regione.lombardia.it",
      "telefono": "+39 02 96984460",
      "fax": "+39 02 96799201",
      "coordinate": {
        "lat": 45.6150016784668,
        "lng": 9.08388900756836
      }
    },
    {
      "codice": "015219",
      "nome": "Trezzano Rosa",
      "nomeStraniero": null,
      "codiceCatastale": "L408",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.trezzanorosa.mi.it",
      "pec": "info@pec.comune.trezzanorosa.mi.it",
      "telefono": "+39 029201991",
      "fax": "+39 02 92019959",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "015220",
      "nome": "Trezzano sul Naviglio",
      "nomeStraniero": null,
      "codiceCatastale": "L409",
      "cap": "20090",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.trezzano-sul-naviglio.mi.it",
      "pec": "comune.trezzanosulnaviglio@legalmail.it",
      "telefono": "+39 02 484181",
      "fax": "+39 02 48402057",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 9.066666603088379
      }
    },
    {
      "codice": "015221",
      "nome": "Trezzo sull'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "L411",
      "cap": "20056",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.trezzosulladda.mi.it",
      "pec": "protocollo@pec.comune.trezzosulladda.mi.it",
      "telefono": "+39 02 909331",
      "fax": "+39 02 90933270",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "015222",
      "nome": "Tribiano",
      "nomeStraniero": null,
      "codiceCatastale": "L415",
      "cap": "20067",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "demografici@comune.tribiano.mi.it",
      "pec": "postacertificata@pec.comune.tribiano.mi.it",
      "telefono": "+39 02 906290202",
      "fax": "+39 02 90638091",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "015224",
      "nome": "Truccazzano",
      "nomeStraniero": null,
      "codiceCatastale": "L454",
      "cap": "20060",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "sportellopolifunzionale.truccazzano@unioneaddamartesana.it",
      "pec": "comune.truccazzano.mi@legalmail.it",
      "telefono": "+39 02.95997722",
      "fax": "+39 02.95997750",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "015226",
      "nome": "Turbigo",
      "nomeStraniero": null,
      "codiceCatastale": "L471",
      "cap": "20029",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "protocollo@comune.turbigo.mi.it",
      "pec": "comune.turbigo@postecert.it",
      "telefono": "+39 0331 899143",
      "fax": "+39 0331 871016",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "015249",
      "nome": "Vanzaghello",
      "nomeStraniero": null,
      "codiceCatastale": "L664",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@comune.vanzaghello.mi.it",
      "pec": "comune.vanzaghello@postecert.it",
      "telefono": "+39 0331 308911",
      "fax": "+39 0331 658355",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "015229",
      "nome": "Vanzago",
      "nomeStraniero": null,
      "codiceCatastale": "L665",
      "cap": "20043",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "affari.generali@comune.vanzago.mi.it",
      "pec": "comune.vanzago@pec.regione.lombardia.it",
      "telefono": "+39 02.939621",
      "fax": "+39 02.9341885",
      "coordinate": {
        "lat": 45.52680206298828,
        "lng": 8.994722366333008
      }
    },
    {
      "codice": "015230",
      "nome": "Vaprio d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "L667",
      "cap": "20069",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.vapriodadda.mi.it",
      "pec": "comune.vapriodadda@legalmail.it",
      "telefono": "+39 02 9094004",
      "fax": "+39 02 90966436",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "015251",
      "nome": "Vermezzo con Zelo",
      "nomeStraniero": null,
      "codiceCatastale": "M424",
      "cap": "20071",
      "prefisso": "02",
      "provincia": "Milano",
      "email": null,
      "pec": "vermezzoconzelo@pec.it",
      "telefono": "+39 029440301",
      "fax": "+39 029449281",
      "coordinate": {
        "lat": 45.392723083496094,
        "lng": 8.982009887695312
      }
    },
    {
      "codice": "015236",
      "nome": "Vernate",
      "nomeStraniero": null,
      "codiceCatastale": "L773",
      "cap": "20080",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "info@comune.vernate.mi.it",
      "pec": "comune.vernate@pec.regione.lombardia.it",
      "telefono": "+39 02 9001321",
      "fax": "+39 02 90013240",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.066666603088379
      }
    },
    {
      "codice": "015237",
      "nome": "Vignate",
      "nomeStraniero": null,
      "codiceCatastale": "L883",
      "cap": "20052",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.vignate.mi.it",
      "pec": "protocollo@pec.comune.vignate.mi.it",
      "telefono": "+39 02 95080811",
      "fax": "+39 02 9560538",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "015248",
      "nome": "Villa Cortese",
      "nomeStraniero": null,
      "codiceCatastale": "L928",
      "cap": "20020",
      "prefisso": "0331",
      "provincia": "Milano",
      "email": "info@comune.villacortese.mi.it",
      "pec": "comune.villacortese@cert.legalmail.it",
      "telefono": "+39 0331.434411",
      "fax": "+39 0331.432955",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "015242",
      "nome": "Vimodrone",
      "nomeStraniero": null,
      "codiceCatastale": "M053",
      "cap": "20055",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@comune.vimodrone.milano.it",
      "pec": "comune.vimodrone@pec.regione.lombardia.it",
      "telefono": "+39 02 250771",
      "fax": "+39 02 2500316",
      "coordinate": {
        "lat": 45.51388931274414,
        "lng": 9.284444808959961
      }
    },
    {
      "codice": "015243",
      "nome": "Vittuone",
      "nomeStraniero": null,
      "codiceCatastale": "M091",
      "cap": "20009",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "uff.protocollo@comune.vittuone.mi.it",
      "pec": "ufficio.protocollo@pec.comune.vittuone.mi.it",
      "telefono": "+39 02.90.320.1",
      "fax": "+39 02.901.109.27",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "015244",
      "nome": "Vizzolo Predabissi",
      "nomeStraniero": null,
      "codiceCatastale": "M102",
      "cap": "20070",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "segreteria@comune.vizzolopredabissi.mi.it",
      "pec": "comune.vizzolopredabissi@pec.regione.lombardia.it",
      "telefono": "0298202208",
      "fax": "+39 02 98231392",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "015247",
      "nome": "Zibido San Giacomo",
      "nomeStraniero": null,
      "codiceCatastale": "M176",
      "cap": "20058",
      "prefisso": "02",
      "provincia": "Milano",
      "email": "protocollo@pec.comune.zibidosangiacomo.mi.it",
      "pec": "protocollo@pec.comune.zibidosangiacomo.mi.it",
      "telefono": "+39 02.900201",
      "fax": "+39 02.90003204",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 9.116666793823242
      }
    }
  ],
  "Pistoia": [
    {
      "codice": "047023",
      "nome": "Abetone Cutigliano",
      "nomeStraniero": null,
      "codiceCatastale": "M376",
      "cap": "51024",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "comune@comune.cutigliano.pt.it",
      "pec": "abetonecutigliano@postacert.toscana.it",
      "telefono": "+39 057368881",
      "fax": "+39 057368386",
      "coordinate": {
        "lat": 44.100276947021484,
        "lng": 10.756388664245605
      }
    },
    {
      "codice": "047002",
      "nome": "Agliana",
      "nomeStraniero": null,
      "codiceCatastale": "A071",
      "cap": "51031",
      "prefisso": "0574",
      "provincia": "Pistoia",
      "email": "urp@comune.agliana.pt.it",
      "pec": "comune.agliana.pt@legalmail.it",
      "telefono": "+39 05746781",
      "fax": "+39 0574678200",
      "coordinate": {
        "lat": 43.900001525878906,
        "lng": 11
      }
    },
    {
      "codice": "047003",
      "nome": "Buggiano",
      "nomeStraniero": null,
      "codiceCatastale": "B251",
      "cap": "51011",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "info@comune.buggiano.pt.it",
      "pec": "certificata@pec.comune.buggiano.pt.it",
      "telefono": "+39 057231711",
      "fax": "+39 057232029",
      "coordinate": {
        "lat": 43.87638854980469,
        "lng": 10.734444618225098
      }
    },
    {
      "codice": "047022",
      "nome": "Chiesina Uzzanese",
      "nomeStraniero": null,
      "codiceCatastale": "C631",
      "cap": "51013",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "segreteria@comune.chiesinauzzanese.pt.it",
      "pec": "comune.chiesinauzzanese@postacert.toscana.it",
      "telefono": "+39 057241801",
      "fax": "+39 0572411034",
      "coordinate": {
        "lat": 43.83333206176758,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "047005",
      "nome": "Lamporecchio",
      "nomeStraniero": null,
      "codiceCatastale": "E432",
      "cap": "51035",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "info@comune.lamporecchio.pt.it",
      "pec": "comune.lamporecchio@postacert.toscana.it",
      "telefono": "+39 057380061",
      "fax": "+39 057381427",
      "coordinate": {
        "lat": 43.81666564941406,
        "lng": 10.899999618530273
      }
    },
    {
      "codice": "047006",
      "nome": "Larciano",
      "nomeStraniero": null,
      "codiceCatastale": "E451",
      "cap": "51036",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "info@comune.larciano.pt.it",
      "pec": "comune.larciano@postacert.toscana.it",
      "telefono": "+39 057385811",
      "fax": "+39 0573838430",
      "coordinate": {
        "lat": 43.81666564941406,
        "lng": 10.883333206176758
      }
    },
    {
      "codice": "047007",
      "nome": "Marliana",
      "nomeStraniero": null,
      "codiceCatastale": "E960",
      "cap": "51010",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "anagrafe@comune.marliana.pt.it",
      "pec": "comune.marliana@postacert.toscana.it",
      "telefono": "+39 0572698525",
      "fax": "+39 057266109",
      "coordinate": {
        "lat": 43.93333435058594,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "047008",
      "nome": "Massa e Cozzile",
      "nomeStraniero": null,
      "codiceCatastale": "F025",
      "cap": "51010",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "info@comune.massa-e-cozzile.pt.it",
      "pec": "comune.massaecozzile@postacert.toscana.it",
      "telefono": "+39 0572928305",
      "fax": "+39 057260357",
      "coordinate": {
        "lat": 43.91666793823242,
        "lng": 10.75
      }
    },
    {
      "codice": "047009",
      "nome": "Monsummano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "F384",
      "cap": "51015",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "info@comune.monsummano-terme.pt.it",
      "pec": "comune.monsummano-terme@postacert.toscana.it",
      "telefono": "+39 0572 9590",
      "fax": "+39 0572 52283",
      "coordinate": {
        "lat": 43.86666488647461,
        "lng": 10.816666603088379
      }
    },
    {
      "codice": "047010",
      "nome": "Montale",
      "nomeStraniero": null,
      "codiceCatastale": "F410",
      "cap": "51037",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "urp@comune.montale.pt.it",
      "pec": "comune.montale@postacert.toscana.it",
      "telefono": "+39 0573952236",
      "fax": "+39 057355001",
      "coordinate": {
        "lat": 43.93333435058594,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "047011",
      "nome": "Montecatini-Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A561",
      "cap": "51016",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "comune@comune.montecatini-terme.pt.it",
      "pec": "comune.montecatiniterme@postacert.toscana.it",
      "telefono": "+39 05729181",
      "fax": "+39 0572918264",
      "coordinate": {
        "lat": 43.88277816772461,
        "lng": 10.771111488342285
      }
    },
    {
      "codice": "047012",
      "nome": "Pescia",
      "nomeStraniero": null,
      "codiceCatastale": "G491",
      "cap": "51012",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "info@comune.pescia.pt.it",
      "pec": "comune.pescia@legalmail.it",
      "telefono": "+39 05724920",
      "fax": "+39 0572492253",
      "coordinate": {
        "lat": 43.901710510253906,
        "lng": 10.689781188964844
      }
    },
    {
      "codice": "047013",
      "nome": "Pieve a Nievole",
      "nomeStraniero": null,
      "codiceCatastale": "G636",
      "cap": "51018",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "ufficiourp@comune.pieve-a-nievole.pt.it",
      "pec": "comune.pieve-a-nievole@postacert.toscana.it",
      "telefono": "+39 057295631",
      "fax": "+39 0572952150",
      "coordinate": {
        "lat": 43.87888717651367,
        "lng": 10.795000076293945
      }
    },
    {
      "codice": "047014",
      "nome": "Pistoia",
      "nomeStraniero": null,
      "codiceCatastale": "G713",
      "cap": "51100",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "info@comune.pistoia.it",
      "pec": "comune.pistoia@postacert.toscana.it",
      "telefono": "+39 05733711",
      "fax": "+39 0573371289",
      "coordinate": {
        "lat": 43.93333435058594,
        "lng": 10.916666984558105
      }
    },
    {
      "codice": "047016",
      "nome": "Ponte Buggianese",
      "nomeStraniero": null,
      "codiceCatastale": "G833",
      "cap": "51019",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "info@comune.ponte-buggianese.pt.it",
      "pec": "comune.pontebuggianese@postacert.toscana.it",
      "telefono": "+39 057293211",
      "fax": "+39 0572636467",
      "coordinate": {
        "lat": 43.8408317565918,
        "lng": 10.7475004196167
      }
    },
    {
      "codice": "047017",
      "nome": "Quarrata",
      "nomeStraniero": null,
      "codiceCatastale": "H109",
      "cap": "51039",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "l.stefanini@comune.quarrata.pistoia.it",
      "pec": "comune.quarrata@postacert.toscana.it",
      "telefono": "+39 05737710",
      "fax": "+39 0573775053",
      "coordinate": {
        "lat": 43.84749984741211,
        "lng": 10.983333587646484
      }
    },
    {
      "codice": "047018",
      "nome": "Sambuca Pistoiese",
      "nomeStraniero": null,
      "codiceCatastale": "H744",
      "cap": "51020",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "franca.cecchini@comune.sambuca.pt.it",
      "pec": "comune.sambucapistoiese@postacert.toscana.it",
      "telefono": "+39 0573893716",
      "fax": "+39 0573893737",
      "coordinate": {
        "lat": 44.1054801940918,
        "lng": 10.995389938354492
      }
    },
    {
      "codice": "047024",
      "nome": "San Marcello Piteglio",
      "nomeStraniero": null,
      "codiceCatastale": "M377",
      "cap": "51028",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "segreteria@comunesanmarcellopiteglio.it",
      "pec": "comunedisanmarcellopiteglio@pec.it",
      "telefono": "+39 0573.62121",
      "fax": "+39 0573630623",
      "coordinate": {
        "lat": 44.05555725097656,
        "lng": 10.790833473205566
      }
    },
    {
      "codice": "047020",
      "nome": "Serravalle Pistoiese",
      "nomeStraniero": null,
      "codiceCatastale": "I660",
      "cap": "51030",
      "prefisso": "0573",
      "provincia": "Pistoia",
      "email": "info@comune.serravalle-pistoiese.pt.it",
      "pec": "amministrazione@pec.comune.serravalle-pistoiese.pt.it",
      "telefono": "+39 05739170",
      "fax": "+39 057351064",
      "coordinate": {
        "lat": 43.900001525878906,
        "lng": 10.833333015441895
      }
    },
    {
      "codice": "047021",
      "nome": "Uzzano",
      "nomeStraniero": null,
      "codiceCatastale": "L522",
      "cap": "51010",
      "prefisso": "0572",
      "provincia": "Pistoia",
      "email": "comune@uzzano.it",
      "pec": "comuneuzzano@postecert.it",
      "telefono": "+39 057244771",
      "fax": "+39 0572452116",
      "coordinate": {
        "lat": 43.88333511352539,
        "lng": 10.716666221618652
      }
    }
  ],
  "Potenza": [
    {
      "codice": "076001",
      "nome": "Abriola",
      "nomeStraniero": null,
      "codiceCatastale": "A013",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "lucia.larocca@comune.abriola.pz.it",
      "pec": "comune.abriola@cert.ruparbasilicata.it",
      "telefono": "+39 0971/923230",
      "fax": "+39 0971/923001",
      "coordinate": {
        "lat": 40.516666412353516,
        "lng": 15.783333778381348
      }
    },
    {
      "codice": "076002",
      "nome": "Acerenza",
      "nomeStraniero": null,
      "codiceCatastale": "A020",
      "cap": "85011",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "anagrafe@comune.acerenza.pz.it",
      "pec": "protocollo@pec.acerenza.pz.it",
      "telefono": "+39 0971/741129",
      "fax": "+39 0971/749061",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 15.949999809265137
      }
    },
    {
      "codice": "076003",
      "nome": "Albano di Lucania",
      "nomeStraniero": null,
      "codiceCatastale": "A131",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "amministrazione@comune.albanodilucania.pz.it",
      "pec": "comune.albanodilucania@cert.ruparbasilicata.it",
      "telefono": "+39 0971/984006",
      "fax": "+39 0971/984182",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "076004",
      "nome": "Anzi",
      "nomeStraniero": null,
      "codiceCatastale": "A321",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comuneanzi@rete.basilicata.it",
      "pec": "comune.anzi@cert.ruparbasilicata.it",
      "telefono": "+39 0971/962052",
      "fax": "+39 0971/962247",
      "coordinate": {
        "lat": 40.516666412353516,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "076005",
      "nome": "Armento",
      "nomeStraniero": null,
      "codiceCatastale": "A415",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "anacom@virgilio.it",
      "pec": "comune.armento@cert.ruparbasilicata.it",
      "telefono": "+39 0971/751271",
      "fax": "+39 0971/751271",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "076006",
      "nome": "Atella",
      "nomeStraniero": null,
      "codiceCatastale": "A482",
      "cap": "85020",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "anagratella@tiscali.it",
      "pec": "comune.atella.ag@pec.it",
      "telefono": "+39 0972/716611",
      "fax": "+39 0972/715889",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 15.649999618530273
      }
    },
    {
      "codice": "076007",
      "nome": "Avigliano",
      "nomeStraniero": null,
      "codiceCatastale": "A519",
      "cap": "85021",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "olga.esposito@rete.basilicata.it",
      "pec": "comune.avigliano@cert.ruparbasilicata.it",
      "telefono": "+39 0971/701827",
      "fax": "+39 0971/701861",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 15.716666221618652
      }
    },
    {
      "codice": "076008",
      "nome": "Balvano",
      "nomeStraniero": null,
      "codiceCatastale": "A604",
      "cap": "85050",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "anagrafebalvano@libero.it",
      "pec": "demografici.balvano@cert.ruparbasilicata.it",
      "telefono": "+39 0971/992014",
      "fax": "+39 0971/992163",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 15.516666412353516
      }
    },
    {
      "codice": "076009",
      "nome": "Banzi",
      "nomeStraniero": null,
      "codiceCatastale": "A612",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comune.banzi@cert.ruparbasilicata.it",
      "pec": "comune.banzi@cert.ruparbasilicata.it",
      "telefono": "+39 0971/947812",
      "fax": "+39 0971/947816",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 16.016666412353516
      }
    },
    {
      "codice": "076010",
      "nome": "Baragiano",
      "nomeStraniero": null,
      "codiceCatastale": "A615",
      "cap": "85050",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunebaragiano@rete.basilicata.it",
      "pec": "comune.baragiano@cert.ruparbasilicata.it",
      "telefono": "+39 0971/997071",
      "fax": "+39 0971/997301",
      "coordinate": {
        "lat": 40.67976379394531,
        "lng": 15.593819618225098
      }
    },
    {
      "codice": "076011",
      "nome": "Barile",
      "nomeStraniero": null,
      "codiceCatastale": "A666",
      "cap": "85022",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "demografici@comune.barile.pz.it",
      "pec": "sindaco.comunebarile@pec.it",
      "telefono": "+39 0972/770396",
      "fax": "+39 0972/770726",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 15.666666984558105
      }
    },
    {
      "codice": "076012",
      "nome": "Bella",
      "nomeStraniero": null,
      "codiceCatastale": "A743",
      "cap": "85051",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "anagrafe@comune.bella.pz.it",
      "pec": "protocollo@pec.comune.bella.pz.it",
      "telefono": "+39 0976/803216",
      "fax": "+39 0976/803230",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 15.533333778381348
      }
    },
    {
      "codice": "076013",
      "nome": "Brienza",
      "nomeStraniero": null,
      "codiceCatastale": "B173",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "raffaefe.distefano@rete.basilicata.it",
      "pec": "comune.brienza@cert.ruparbasilicata.it",
      "telefono": "+39 0975/381003",
      "fax": "+39 0975/381988",
      "coordinate": {
        "lat": 40.47861099243164,
        "lng": 15.630000114440918
      }
    },
    {
      "codice": "076014",
      "nome": "Brindisi Montagna",
      "nomeStraniero": null,
      "codiceCatastale": "B181",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunebrindisimontagna@rete.basilicata.it",
      "pec": "comune.brindisimontagna@pec.it",
      "telefono": "+39 0971/985002",
      "fax": "+39 0971/985242",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 15.933333396911621
      }
    },
    {
      "codice": "076015",
      "nome": "Calvello",
      "nomeStraniero": null,
      "codiceCatastale": "B440",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": null,
      "pec": "comunecalvello@cert.ruparbasilicata.it",
      "telefono": "+39 0971/921911",
      "fax": "+39 0971/921921",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 15.850000381469727
      }
    },
    {
      "codice": "076016",
      "nome": "Calvera",
      "nomeStraniero": null,
      "codiceCatastale": "B443",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunedicalvera@gmail.com",
      "pec": "comune.calvera@cert.ruparbasilicata.it",
      "telefono": "+39 0973/573061",
      "fax": "+39 0973/573187",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "076017",
      "nome": "Campomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "B549",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "giuseppe.destefano@rete.basilicata.it",
      "pec": "comune.campomaggiore@cert.ruparbasilicata.it",
      "telefono": "+39 0971/982261",
      "fax": "+39 0971/982255",
      "coordinate": {
        "lat": 40.56589889526367,
        "lng": 16.072500228881836
      }
    },
    {
      "codice": "076018",
      "nome": "Cancellara",
      "nomeStraniero": null,
      "codiceCatastale": "B580",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "giuseppina.rita@rete.basilicata.it",
      "pec": "comune.cancellara@cert.ruparbasilicata.it",
      "telefono": "+39 0971/942014",
      "fax": "+39 0971/942928",
      "coordinate": {
        "lat": 40.73067855834961,
        "lng": 15.924699783325195
      }
    },
    {
      "codice": "076019",
      "nome": "Carbone",
      "nomeStraniero": null,
      "codiceCatastale": "B743",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "elettorale@comune.carbone.pz.it",
      "pec": "comune.carbone@cert.ruparbasilicata.it",
      "telefono": "+39 0973/578023",
      "fax": "+39 0973/573907",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "076021",
      "nome": "Castelgrande",
      "nomeStraniero": null,
      "codiceCatastale": "C120",
      "cap": "85050",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "comune.castelgrande@cert.ruparbasilicata.it",
      "pec": "comune.castelgrande@cert.ruparbasilicata.it",
      "telefono": "+39 0976 4481",
      "fax": "+39 0976/4464",
      "coordinate": {
        "lat": 40.78333282470703,
        "lng": 15.433333396911621
      }
    },
    {
      "codice": "076022",
      "nome": "Castelluccio Inferiore",
      "nomeStraniero": null,
      "codiceCatastale": "C199",
      "cap": "85040",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "anagrafecastelluccio@tiscali.it",
      "pec": "comune.castelluccioinferiore@pec.it",
      "telefono": "+39 0973/663993",
      "fax": "+39 0973/663231",
      "coordinate": {
        "lat": 40,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "076023",
      "nome": "Castelluccio Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "C201",
      "cap": "85040",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "egidiocatalano@comune.castellucciosuperiore.pz.it",
      "pec": "comune.castellucciosup@cert.ruparbasilicata.it",
      "telefono": "+39 0973/662145",
      "fax": "+39 0973/662083",
      "coordinate": {
        "lat": 40.016666412353516,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "076024",
      "nome": "Castelmezzano",
      "nomeStraniero": null,
      "codiceCatastale": "C209",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunecastelmezzano@rete.basilicata.it",
      "pec": "comune.castelmezzano.pz@legalmail.it",
      "telefono": "+39 0971/986166",
      "fax": "+39 0971/986166",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "076025",
      "nome": "Castelsaraceno",
      "nomeStraniero": null,
      "codiceCatastale": "C271",
      "cap": "85031",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "giacomodelia@comune.castelsaraceno.pz.it",
      "pec": "protocollo@pec.comune.castelsarceno.pz.it",
      "telefono": "+39 0973/832013",
      "fax": "+39 0973/832573",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "076026",
      "nome": "Castronuovo di Sant'Andrea",
      "nomeStraniero": null,
      "codiceCatastale": "C345",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "anagrafecastronuovo@yahoo.it",
      "pec": "comune.castronuovo@cert.ruparbasilicata.it",
      "telefono": "+39 0973/835045",
      "fax": "+39 0973/835900",
      "coordinate": {
        "lat": 40.18333435058594,
        "lng": 16.183332443237305
      }
    },
    {
      "codice": "076027",
      "nome": "Cersosimo",
      "nomeStraniero": null,
      "codiceCatastale": "C539",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "tfabiano.cersosimo@tiscali.it",
      "pec": "comunecersosimo.pz@pec.it",
      "telefono": "+39 0973/94023",
      "fax": "+39 0973/94251",
      "coordinate": {
        "lat": 40.04999923706055,
        "lng": 16.350000381469727
      }
    },
    {
      "codice": "076028",
      "nome": "Chiaromonte",
      "nomeStraniero": null,
      "codiceCatastale": "C619",
      "cap": "85032",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunechiaromonte@rete.basilicata.it",
      "pec": "comune.chiaromonte@cert.ruparbasilicata.it",
      "telefono": "+39 0973/571002-571034",
      "fax": "+39 0973/642222",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 16.21666717529297
      }
    },
    {
      "codice": "076029",
      "nome": "Corleto Perticara",
      "nomeStraniero": null,
      "codiceCatastale": "D010",
      "cap": "85012",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "maria.sagaria@rete.basilicata.it",
      "pec": "comunecorleto@cert.ruparbasilicata.it",
      "telefono": "+39 0971/965718",
      "fax": "+39 0971/965717",
      "coordinate": {
        "lat": 40.38333511352539,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "076030",
      "nome": "Episcopia",
      "nomeStraniero": null,
      "codiceCatastale": "D414",
      "cap": "85033",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "ufficioanagrafe@comune.episcopia.pz.it",
      "pec": "protocollocomunepiscopia@pec.it",
      "telefono": "+39 0973/655013",
      "fax": "+39 0973/655300",
      "coordinate": {
        "lat": 40.07481002807617,
        "lng": 16.098180770874023
      }
    },
    {
      "codice": "076031",
      "nome": "Fardella",
      "nomeStraniero": null,
      "codiceCatastale": "D497",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunefardella@rete.basilicata.it",
      "pec": "protocollo@peccomunefardella.it",
      "telefono": "+39 0973/572051",
      "fax": "+39 0973/572000",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "076032",
      "nome": "Filiano",
      "nomeStraniero": null,
      "codiceCatastale": "D593",
      "cap": "85020",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "amministrativo@filiano.com",
      "pec": "comune.filiano@pec.it",
      "telefono": "+39 0971/836010",
      "fax": "+39 0971/836009",
      "coordinate": {
        "lat": 40.81666564941406,
        "lng": 15.699999809265137
      }
    },
    {
      "codice": "076033",
      "nome": "Forenza",
      "nomeStraniero": null,
      "codiceCatastale": "D696",
      "cap": "85023",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comforen@tiscali.it",
      "pec": "comune.forenza@cert.ruparbasilicata.it",
      "telefono": "+39 0971/772211",
      "fax": "+39 0971/772229",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 15.850000381469727
      }
    },
    {
      "codice": "076034",
      "nome": "Francavilla in Sinni",
      "nomeStraniero": null,
      "codiceCatastale": "D766",
      "cap": "85034",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comune.francavilla@comune.francavillainsinni.pz.it",
      "pec": "settoreamministrativo@francavillainsinni.gov.it",
      "telefono": "+39 0973/577105",
      "fax": "+39 0973577473",
      "coordinate": {
        "lat": 40.08333206176758,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "076035",
      "nome": "Gallicchio",
      "nomeStraniero": null,
      "codiceCatastale": "D876",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunegallicchio@rete.basilicata.it",
      "pec": "protocollocomunegallicchio@pec.it",
      "telefono": "+39 0971/752389",
      "fax": "+39 0971/752390",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "076036",
      "nome": "Genzano di Lucania",
      "nomeStraniero": null,
      "codiceCatastale": "D971",
      "cap": "85013",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunegenzano@rete.basilicata.it",
      "pec": "comune.genzano@cert.ruparbasilicata.it",
      "telefono": "+39 0971/933046",
      "fax": "+39 0971/933051",
      "coordinate": {
        "lat": 40.849998474121094,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "076099",
      "nome": "Ginestra",
      "nomeStraniero": null,
      "codiceCatastale": "E033",
      "cap": "85020",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "info@comune.ginestra.pz.it",
      "pec": "comune.ginestra@cert.ruparbasilicata.it",
      "telefono": "+39 0972646002",
      "fax": "+39 0972646900",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 15.733333587646484
      }
    },
    {
      "codice": "076037",
      "nome": "Grumento Nova",
      "nomeStraniero": null,
      "codiceCatastale": "E221",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "antonella.dimaria@rete.basilicata.it",
      "pec": "statocivilegrumento@pec.it",
      "telefono": "+39 0975/65044",
      "fax": "+39 0975/65073",
      "coordinate": {
        "lat": 40.28388977050781,
        "lng": 15.889721870422363
      }
    },
    {
      "codice": "076038",
      "nome": "Guardia Perticara",
      "nomeStraniero": null,
      "codiceCatastale": "E246",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "vinc.rossi@tiscali.it",
      "pec": "comune.guardiaperticara.pz@cert.ruparbasilicata.it",
      "telefono": "+39 0971/964004",
      "fax": "+39 0971/964003",
      "coordinate": {
        "lat": 40.36666488647461,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "076039",
      "nome": "Lagonegro",
      "nomeStraniero": null,
      "codiceCatastale": "E409",
      "cap": "85042",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "mariagiuseppa.gallotti@comune.lagonegro.pz.it",
      "pec": "comune.lagonegro.pz@pec.it",
      "telefono": "+39 0973/41330",
      "fax": "+39 0973/21930",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "076040",
      "nome": "Latronico",
      "nomeStraniero": null,
      "codiceCatastale": "E474",
      "cap": "85043",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "anagrafestatocivile@comune.latronico.pz.it",
      "pec": "anagrafe@pec.latronico.eu",
      "telefono": "+39 0973/853228",
      "fax": "+39 0973/853232",
      "coordinate": {
        "lat": 40.08333206176758,
        "lng": 16.016666412353516
      }
    },
    {
      "codice": "076041",
      "nome": "Laurenzana",
      "nomeStraniero": null,
      "codiceCatastale": "E482",
      "cap": "85014",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunelaurenzana@rete.basilicata.it",
      "pec": "comunelaurenzana@cert.ruparbasilicata.it",
      "telefono": "+39 0971/960327",
      "fax": "+39 0971/960325",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "076042",
      "nome": "Lauria",
      "nomeStraniero": null,
      "codiceCatastale": "E483",
      "cap": "85044",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "protocollo@comune.lauria.pz.it",
      "pec": "comune.lauria@cert.ruparbasilicata.it",
      "telefono": "+39 0973/627111",
      "fax": "+39 0973/823793",
      "coordinate": {
        "lat": 40.04722213745117,
        "lng": 15.835833549499512
      }
    },
    {
      "codice": "076043",
      "nome": "Lavello",
      "nomeStraniero": null,
      "codiceCatastale": "E493",
      "cap": "85024",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "statocivile@comune.lavello.pz.it",
      "pec": "demograficilavello@pec.comune.lavello.pz.it",
      "telefono": "+39 0972/80213",
      "fax": "+39 0972/80277",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 15.800000190734863
      }
    },
    {
      "codice": "076044",
      "nome": "Maratea",
      "nomeStraniero": null,
      "codiceCatastale": "E919",
      "cap": "85046",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "m.dambrosio@comune.maratea.pz.it",
      "pec": "comune.maratea@cert.ruparbasilicata.it",
      "telefono": "+39 0973/874281",
      "fax": "+39 0973/874240",
      "coordinate": {
        "lat": 39.99277877807617,
        "lng": 15.716666221618652
      }
    },
    {
      "codice": "076045",
      "nome": "Marsico Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "E976",
      "cap": "85052",
      "prefisso": "80052",
      "provincia": "Potenza",
      "email": "comunemarsiconuovo@rete.basilicata.it",
      "pec": "comune.marsiconuovo@cert.ruparbasilicata.it",
      "telefono": "+39 0975/345111",
      "fax": "+39 0975/345211",
      "coordinate": {
        "lat": 40.41666793823242,
        "lng": 15.733333587646484
      }
    },
    {
      "codice": "076046",
      "nome": "Marsicovetere",
      "nomeStraniero": null,
      "codiceCatastale": "E977",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "mariacaterinamontano@virgilio.it",
      "pec": "comune.marsicovetere@cert.ruparbasilicata.it",
      "telefono": "+39 0975/69033",
      "fax": "+39 0975/69271",
      "coordinate": {
        "lat": 40.37444305419922,
        "lng": 15.82611083984375
      }
    },
    {
      "codice": "076047",
      "nome": "Maschito",
      "nomeStraniero": null,
      "codiceCatastale": "F006",
      "cap": "85020",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "info@comune.maschito.pz.it",
      "pec": "anagrafe.maschito@cert.ruparbasilicata.it",
      "telefono": "+39 0972/33047",
      "fax": "+39 0972/33075",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 15.833333015441895
      }
    },
    {
      "codice": "076048",
      "nome": "Melfi",
      "nomeStraniero": null,
      "codiceCatastale": "F104",
      "cap": "85025",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "responsabileit@comunemelfi.it",
      "pec": "anagrafe@pec.comunemelfi.it",
      "telefono": "+39 0972 251273",
      "fax": "+39 0972 251217",
      "coordinate": {
        "lat": 40.99638748168945,
        "lng": 15.65583324432373
      }
    },
    {
      "codice": "076049",
      "nome": "Missanello",
      "nomeStraniero": null,
      "codiceCatastale": "F249",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "ufficiotecnico@comunemissanello.pz.it",
      "pec": "protocollomissanello@ebaspec.it",
      "telefono": "+39 0971/955076",
      "fax": "+39 0971/955235",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "076050",
      "nome": "Moliterno",
      "nomeStraniero": null,
      "codiceCatastale": "F295",
      "cap": "85047",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "elettoralemoliterno@rete.basilicata.it",
      "pec": "protocollomoliterno@ebaspec.it",
      "telefono": "+39 0975/668511",
      "fax": "+39 0975/668537",
      "coordinate": {
        "lat": 40.2400016784668,
        "lng": 15.859999656677246
      }
    },
    {
      "codice": "076051",
      "nome": "Montemilone",
      "nomeStraniero": null,
      "codiceCatastale": "F568",
      "cap": "85020",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "comunemontemilone@rete.basilicata.it",
      "pec": "comunemontemilone@cert.ruparbasilicata.it",
      "telefono": "+39 0972/99190",
      "fax": "+39 0972/99025",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "076052",
      "nome": "Montemurro",
      "nomeStraniero": null,
      "codiceCatastale": "F573",
      "cap": "85053",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "anagrafemontemurro@rete.basilicata.it",
      "pec": "anagrafe.montemurro@pec.it",
      "telefono": "+39 0971/753010",
      "fax": "+39 0971/753411",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "076053",
      "nome": "Muro Lucano",
      "nomeStraniero": null,
      "codiceCatastale": "F817",
      "cap": "85054",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "servizi.demografici@municipiomurolucano.it",
      "pec": "comune@pec.murolucano.eu",
      "telefono": "+39 0976/75233",
      "fax": "+39 0976/75226",
      "coordinate": {
        "lat": 40.75,
        "lng": 15.483333587646484
      }
    },
    {
      "codice": "076054",
      "nome": "Nemoli",
      "nomeStraniero": null,
      "codiceCatastale": "F866",
      "cap": "85040",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunenemoli@rete.basilicata.it",
      "pec": "protocollonemoli@ebaspec.it",
      "telefono": "+39 0973/40001",
      "fax": "+39 0973/40475",
      "coordinate": {
        "lat": 40.06666564941406,
        "lng": 15.800000190734863
      }
    },
    {
      "codice": "076055",
      "nome": "Noepoli",
      "nomeStraniero": null,
      "codiceCatastale": "F917",
      "cap": "85035",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "culturastefano@gmail.com",
      "pec": "comune.noepoli@cert.ruparbasilicata.it",
      "telefono": "+39 0973/92034",
      "fax": "+39 0973/92033",
      "coordinate": {
        "lat": 40.08333206176758,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "076056",
      "nome": "Oppido Lucano",
      "nomeStraniero": null,
      "codiceCatastale": "G081",
      "cap": "85015",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "maria.gioiello@rete.basilicata.it",
      "pec": "comuneoppidolucano.protocollo@pec.it",
      "telefono": "+39 0971/945002",
      "fax": "+39 0971/945682",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "076057",
      "nome": "Palazzo San Gervasio",
      "nomeStraniero": null,
      "codiceCatastale": "G261",
      "cap": "85026",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "comunepalazzo@rete.basilicata.it",
      "pec": "comune.palazzo@cert.ruparbasilicata.it",
      "telefono": "+39 0972/44246",
      "fax": "+39 0972/44879",
      "coordinate": {
        "lat": 40.932220458984375,
        "lng": 15.981389045715332
      }
    },
    {
      "codice": "076100",
      "nome": "Paterno",
      "nomeStraniero": null,
      "codiceCatastale": "M269",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "comunepaterno@rete.basilicata.it",
      "pec": "comune.paterno@cert.ruparbasilicata.it",
      "telefono": "+39 0975/3403201",
      "fax": "+39 0975/341554",
      "coordinate": {
        "lat": 40.38333511352539,
        "lng": 15.733333587646484
      }
    },
    {
      "codice": "076058",
      "nome": "Pescopagano",
      "nomeStraniero": null,
      "codiceCatastale": "G496",
      "cap": "85020",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "comunepescopagano@rete.basilicata.it",
      "pec": "comune.pescopagano@cert.ruparbasilicata.it",
      "telefono": "+39 0976/500210",
      "fax": "+39 0976/5636",
      "coordinate": {
        "lat": 40.83611297607422,
        "lng": 15.398805618286133
      }
    },
    {
      "codice": "076059",
      "nome": "Picerno",
      "nomeStraniero": null,
      "codiceCatastale": "G590",
      "cap": "85055",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunepicerno@rete.basilicata.it",
      "pec": "protocollo@pec.comune.picerno.pz.it",
      "telefono": "+39 0971/990211",
      "fax": "+39 0971/990212",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 15.633333206176758
      }
    },
    {
      "codice": "076060",
      "nome": "Pietragalla",
      "nomeStraniero": null,
      "codiceCatastale": "G616",
      "cap": "85016",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "anagrafe@comune.pietragalla.pz.it",
      "pec": "protocollo@pec.comune.pietragalla.pz.it",
      "telefono": "+39 0971/944312",
      "fax": "+39 0971/944309",
      "coordinate": {
        "lat": 40.75,
        "lng": 15.883333206176758
      }
    },
    {
      "codice": "076061",
      "nome": "Pietrapertosa",
      "nomeStraniero": null,
      "codiceCatastale": "G623",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "elenagarramone@libero.it",
      "pec": "comunepietrapertosa.protocollo@pec.it",
      "telefono": "+39 0971/983002",
      "fax": "+39 0971/983160",
      "coordinate": {
        "lat": 40.516666412353516,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "076062",
      "nome": "Pignola",
      "nomeStraniero": null,
      "codiceCatastale": "G663",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "anagrafe.pignola@tiscali.it",
      "pec": "comune.pignola@anutel.it",
      "telefono": "+39 0971/620211",
      "fax": "+39 0971620213",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 15.783333778381348
      }
    },
    {
      "codice": "076063",
      "nome": "Potenza",
      "nomeStraniero": null,
      "codiceCatastale": "G942",
      "cap": "85100",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "roberto.travagliante@comune.potenza.it",
      "pec": "comune.potenza@cert.ruparbasilicata.it",
      "telefono": "+39 0971 415111",
      "fax": "+39 0971 415315",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 15.800000190734863
      }
    },
    {
      "codice": "076064",
      "nome": "Rapolla",
      "nomeStraniero": null,
      "codiceCatastale": "H186",
      "cap": "85027",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "m.pianta@comune.rapolla.pz.it",
      "pec": "protocollo.comune.rapolla@pec.it",
      "telefono": "+39 0972/647211",
      "fax": "+39 0972/647211",
      "coordinate": {
        "lat": 40.983333587646484,
        "lng": 15.666666984558105
      }
    },
    {
      "codice": "076065",
      "nome": "Rapone",
      "nomeStraniero": null,
      "codiceCatastale": "H187",
      "cap": "85020",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "anagrafe.rapone@libero.it",
      "pec": "anagrafe.comune.rapone@pec.it",
      "telefono": "+39 0976/96023",
      "fax": "+39 0976/96411",
      "coordinate": {
        "lat": 40.849998474121094,
        "lng": 15.5
      }
    },
    {
      "codice": "076066",
      "nome": "Rionero in Vulture",
      "nomeStraniero": null,
      "codiceCatastale": "H307",
      "cap": "85028",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "elettorale@comune.rioneroinvulture.pz.it",
      "pec": "patrimonio.comune.rionero@cert.ruparbasilicata.it",
      "telefono": "+39 0972/729214",
      "fax": "+39 0972/729214",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 15.666666984558105
      }
    },
    {
      "codice": "076067",
      "nome": "Ripacandida",
      "nomeStraniero": null,
      "codiceCatastale": "H312",
      "cap": "85020",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "comunediripacandida@yahoo.it",
      "pec": "servizidemografici.ripacandida@pec.it",
      "telefono": "+39 0972/644132",
      "fax": "+39 0972/644142",
      "coordinate": {
        "lat": 40.912776947021484,
        "lng": 15.725555419921875
      }
    },
    {
      "codice": "076068",
      "nome": "Rivello",
      "nomeStraniero": null,
      "codiceCatastale": "H348",
      "cap": "85040",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "giuseppe.defranco@comune.rivello.pz.it",
      "pec": "comune.rivello.pz@cert.ruparbasilicata.it",
      "telefono": "+39 0973/46004",
      "fax": "+39 0973/428949",
      "coordinate": {
        "lat": 40.078208923339844,
        "lng": 15.757980346679688
      }
    },
    {
      "codice": "076069",
      "nome": "Roccanova",
      "nomeStraniero": null,
      "codiceCatastale": "H426",
      "cap": "85036",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "racioppisal@libero.it",
      "pec": "serv.aagg-demografici@pec.comuneroccanova.it",
      "telefono": "+39 0973/833003",
      "fax": "+39 0973/833048",
      "coordinate": {
        "lat": 40.21666717529297,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "076070",
      "nome": "Rotonda",
      "nomeStraniero": null,
      "codiceCatastale": "H590",
      "cap": "85048",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comune@comune.rotonda.pz.it",
      "pec": "comune.rotonda@cert.ruparbasilicata.it",
      "telefono": "+39 0973/661005",
      "fax": "+39 0973/661006",
      "coordinate": {
        "lat": 39.95427322387695,
        "lng": 16.039764404296875
      }
    },
    {
      "codice": "076071",
      "nome": "Ruoti",
      "nomeStraniero": null,
      "codiceCatastale": "H641",
      "cap": "85056",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "domenico.nardiello@comune.ruoti.pz.it",
      "pec": "comune.ruoti@ruoti.gov.it",
      "telefono": "+39 0971/704225",
      "fax": "+39 0971/704227",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 15.683333396911621
      }
    },
    {
      "codice": "076072",
      "nome": "Ruvo del Monte",
      "nomeStraniero": null,
      "codiceCatastale": "H646",
      "cap": "85020",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "comuneruvodelmonte@tin.it",
      "pec": "demografici.ruvodelmonte@cert.ruparbasilicata.it",
      "telefono": "+39 0976/97035",
      "fax": "+39 0976/97031",
      "coordinate": {
        "lat": 40.84722137451172,
        "lng": 15.539999961853027
      }
    },
    {
      "codice": "076073",
      "nome": "San Chirico Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "H795",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "segreteria@comune.sanchiriconuovo.pz.it",
      "pec": "comune.sanchiriconuovo@cert.ruparbasilicata.it",
      "telefono": "+39 0971/731001",
      "fax": "+39 0971/731387",
      "coordinate": {
        "lat": 40.68333435058594,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "076074",
      "nome": "San Chirico Raparo",
      "nomeStraniero": null,
      "codiceCatastale": "H796",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "ufficioanagrafe@comune.sanchiricoraparo.pz.it",
      "pec": "comunesanchiricoraparo@cert.ruparbasilicata.it",
      "telefono": "+39 0973/631003",
      "fax": "+39 0973/631089",
      "coordinate": {
        "lat": 40.18333435058594,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "076075",
      "nome": "San Costantino Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "H808",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "anagrafe.sancostalb@tiscali.it",
      "pec": "comunesancostantinoalbanese@pec.it",
      "telefono": "+39 0973/91126",
      "fax": "+39 0973/92526",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "076076",
      "nome": "San Fele",
      "nomeStraniero": null,
      "codiceCatastale": "H831",
      "cap": "85020",
      "prefisso": "0976",
      "provincia": "Potenza",
      "email": "comune.sanfele@tiscali.it",
      "pec": "comune.sanfele@cert.ruparbasilicata.it",
      "telefono": "+39 0976/94611",
      "fax": "+39 0976/94411",
      "coordinate": {
        "lat": 40.816471099853516,
        "lng": 15.540430068969727
      }
    },
    {
      "codice": "076077",
      "nome": "San Martino d'Agri",
      "nomeStraniero": null,
      "codiceCatastale": "H994",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunesanmartino@rete.basilicata.it",
      "pec": "comune.sanmartinodagri@cert.ruparbasilicata.it",
      "telefono": "+39 0973/834020",
      "fax": "+39 0973/834020",
      "coordinate": {
        "lat": 40.233333587646484,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "076020",
      "nome": "San Paolo Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "B906",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunesanpaoloalb@tiscali.it",
      "pec": "comune.sanpaoloalbanese@pec.it",
      "telefono": "+39 0973/94367",
      "fax": "+39 097394368",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "076078",
      "nome": "San Severino Lucano",
      "nomeStraniero": null,
      "codiceCatastale": "I157",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunesseverino@rete.basilicata.it",
      "pec": "comune.sanseverinolucano.pz@pec.it",
      "telefono": "+39 0973/576132",
      "fax": "+39 0973/576061",
      "coordinate": {
        "lat": 40.016666412353516,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "076079",
      "nome": "Sant'Angelo Le Fratte",
      "nomeStraniero": null,
      "codiceCatastale": "I288",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "mariamonaco@comune.santangelolefrate.pz.it",
      "pec": "comune.santangelolefratte@cert.ruparbasilicata.it",
      "telefono": "+39 0975/386502",
      "fax": "+39 0975/386786",
      "coordinate": {
        "lat": 40.54694366455078,
        "lng": 15.557499885559082
      }
    },
    {
      "codice": "076080",
      "nome": "Sant'Arcangelo",
      "nomeStraniero": null,
      "codiceCatastale": "I305",
      "cap": "85037",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "salvatore.briamonte@rete.basilicata.it",
      "pec": "protocollosantarcangelo@ebaspec.it",
      "telefono": "+39 0973/618308",
      "fax": "+39 0973/619286",
      "coordinate": {
        "lat": 40.24851989746094,
        "lng": 16.27046012878418
      }
    },
    {
      "codice": "076081",
      "nome": "Sarconi",
      "nomeStraniero": null,
      "codiceCatastale": "I426",
      "cap": "85040",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "comunesarconi@rete.basilicata.it",
      "pec": "comune.sarconi@cert.ruparbasilicata.it",
      "telefono": "+39 0975/66016",
      "fax": "+39 0975/66917",
      "coordinate": {
        "lat": 40.25,
        "lng": 15.883333206176758
      }
    },
    {
      "codice": "076082",
      "nome": "Sasso di Castalda",
      "nomeStraniero": null,
      "codiceCatastale": "I457",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "protocollo@comune.sassodicastalda.pz.it",
      "pec": "protocollo@comunesassodicastalda.gov.it",
      "telefono": "+39 0975/385016",
      "fax": "+39 0975/385052",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 15.683333396911621
      }
    },
    {
      "codice": "076083",
      "nome": "Satriano di Lucania",
      "nomeStraniero": null,
      "codiceCatastale": "G614",
      "cap": "85050",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "rocco.giannotti@rete.basilicata.it",
      "pec": "comune.satriano.pz@pec.it",
      "telefono": "+39 0975/383121",
      "fax": "+39 0975/383122",
      "coordinate": {
        "lat": 40.54499816894531,
        "lng": 15.639166831970215
      }
    },
    {
      "codice": "076084",
      "nome": "Savoia di Lucania",
      "nomeStraniero": null,
      "codiceCatastale": "H730",
      "cap": "85050",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "info@comune.savoia.pz.it",
      "pec": "comune.savoia@cert.ruparbasilicata.it",
      "telefono": "+39 0971/711000",
      "fax": "+39 0971/711009",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 15.550000190734863
      }
    },
    {
      "codice": "076085",
      "nome": "Senise",
      "nomeStraniero": null,
      "codiceCatastale": "I610",
      "cap": "85038",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comune.senise@comunesenise.pz.it",
      "pec": "protocollo@comune.senise.postecert.it",
      "telefono": "+39 0973/686200",
      "fax": "+39 0973/686393",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "076086",
      "nome": "Spinoso",
      "nomeStraniero": null,
      "codiceCatastale": "I917",
      "cap": "85039",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "urp@comune.spinoso.pz.it",
      "pec": "comune.spinoso@cert.ruparbasilicata.it",
      "telefono": "+39 0971/954715",
      "fax": "+39 0971/954221",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "076087",
      "nome": "Teana",
      "nomeStraniero": null,
      "codiceCatastale": "L082",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "anagrafeteana@tiscali.it",
      "pec": "comune.teana@cert.ruparbasilicata.it",
      "telefono": "+39 0973/572001",
      "fax": "+39 0973/572393",
      "coordinate": {
        "lat": 40.127777099609375,
        "lng": 16.155277252197266
      }
    },
    {
      "codice": "076088",
      "nome": "Terranova di Pollino",
      "nomeStraniero": null,
      "codiceCatastale": "L126",
      "cap": "85030",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comuneterranova@rete.basilicata.it",
      "pec": "mariagiuseppa.genovese@pec.comune.terranovadipollino.pz.it",
      "telefono": "+39 0973/93009",
      "fax": "+39 0973/93247",
      "coordinate": {
        "lat": 39.983333587646484,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "076089",
      "nome": "Tito",
      "nomeStraniero": null,
      "codiceCatastale": "L181",
      "cap": "85050",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "ufficidemografici@comune.tito.pz.it",
      "pec": "comune.tito.pz@cert.ruparbasilicata.it",
      "telefono": "+39 0971/796220",
      "fax": "+39 0971/794489",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 15.683333396911621
      }
    },
    {
      "codice": "076090",
      "nome": "Tolve",
      "nomeStraniero": null,
      "codiceCatastale": "L197",
      "cap": "85017",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunetolve@rete.basilicata.it",
      "pec": "comunetolve@cert.ruparbasilicata.it",
      "telefono": "+39 0971/737002",
      "fax": "+39 0971/738143",
      "coordinate": {
        "lat": 40.70000076293945,
        "lng": 16.016666412353516
      }
    },
    {
      "codice": "076091",
      "nome": "Tramutola",
      "nomeStraniero": null,
      "codiceCatastale": "L326",
      "cap": "85057",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "umberto.tedesco@comune.tramutola.pz.it",
      "pec": "comune.tramutola@cert.ruparbasilicata.it",
      "telefono": "+39 0975/353002",
      "fax": "+39 0975/353009",
      "coordinate": {
        "lat": 40.31666564941406,
        "lng": 15.783333778381348
      }
    },
    {
      "codice": "076092",
      "nome": "Trecchina",
      "nomeStraniero": null,
      "codiceCatastale": "L357",
      "cap": "85049",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "comunetrecchina@rete.basilicata.it",
      "pec": "trecchina@pec.it",
      "telefono": "+39 0973/826002",
      "fax": "+39 0973/826777",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "076093",
      "nome": "Trivigno",
      "nomeStraniero": null,
      "codiceCatastale": "L439",
      "cap": "85018",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comunetrivigno@rete.basilicata.it",
      "pec": "ufficio.anagrafe.comunetrivigno@pec.it",
      "telefono": "+39 0971/981002",
      "fax": "+39 0971/981036",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "076094",
      "nome": "Vaglio Basilicata",
      "nomeStraniero": null,
      "codiceCatastale": "L532",
      "cap": "85010",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "tributielettorale@comune.vagliobasilicata.pz.it",
      "pec": "comune.vaglio@cert.ruparbasilicata.it",
      "telefono": "+39 0971/305011",
      "fax": "+39 0971/487003",
      "coordinate": {
        "lat": 40.675148010253906,
        "lng": 15.896060943603516
      }
    },
    {
      "codice": "076095",
      "nome": "Venosa",
      "nomeStraniero": null,
      "codiceCatastale": "L738",
      "cap": "85029",
      "prefisso": "0972",
      "provincia": "Potenza",
      "email": "protocollo@comune.venosa.pz.it",
      "pec": "protocollo@pec.comune.venosa.pz.it",
      "telefono": "+39 0972/308615",
      "fax": "+39 0972/308637",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 15.816666603088379
      }
    },
    {
      "codice": "076096",
      "nome": "Vietri di Potenza",
      "nomeStraniero": null,
      "codiceCatastale": "L859",
      "cap": "85058",
      "prefisso": "0971",
      "provincia": "Potenza",
      "email": "comune@comune.vietridipotenza.pz.it",
      "pec": "protocollo@pec.comune.vietridipotenza.pz.it",
      "telefono": "+39 0971/718002",
      "fax": "+39 0971/718402",
      "coordinate": {
        "lat": 40.599998474121094,
        "lng": 15.5
      }
    },
    {
      "codice": "076097",
      "nome": "Viggianello",
      "nomeStraniero": null,
      "codiceCatastale": "L873",
      "cap": "85040",
      "prefisso": "0973",
      "provincia": "Potenza",
      "email": "protocollo@comune.viggianello.pz.it",
      "pec": "comune.viggianello.pz@pec.it",
      "telefono": "+39 0973/664311",
      "fax": "+39 0973/664313",
      "coordinate": {
        "lat": 39.96666717529297,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "076098",
      "nome": "Viggiano",
      "nomeStraniero": null,
      "codiceCatastale": "L874",
      "cap": "85059",
      "prefisso": "0975",
      "provincia": "Potenza",
      "email": "maria.stella@rete.basilicata.it",
      "pec": "protocolloviggiano@pec.it",
      "telefono": "+39 0975/61142",
      "fax": "+39 0975/61137",
      "coordinate": {
        "lat": 40.33333206176758,
        "lng": 15.899999618530273
      }
    }
  ],
  "Ragusa": [
    {
      "codice": "088001",
      "nome": "Acate",
      "nomeStraniero": null,
      "codiceCatastale": "A014",
      "cap": "97011",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "segreteria@comune.acate.rg.it",
      "pec": "protocollo@pec.comune.acate.rg.it",
      "telefono": "+39 0932 877011",
      "fax": "+39 0932 990788",
      "coordinate": {
        "lat": 37.02528762817383,
        "lng": 14.492464065551758
      }
    },
    {
      "codice": "088002",
      "nome": "Chiaramonte Gulfi",
      "nomeStraniero": null,
      "codiceCatastale": "C612",
      "cap": "97012",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "sindaco@comune.chiaramonte.rg.it",
      "pec": "protocollo@pec.comune.chiaramonte-gulfi.rg.it",
      "telefono": "+39 0932/928219",
      "fax": "+39 0932/928219",
      "coordinate": {
        "lat": 37.03333282470703,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "088003",
      "nome": "Comiso",
      "nomeStraniero": null,
      "codiceCatastale": "C927",
      "cap": "97013",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "protocollo@comune.comiso.rg.it",
      "pec": "protocollo@pec.comune.comiso.it",
      "telefono": "+39 0932 748111",
      "fax": "+39 0932 965576",
      "coordinate": {
        "lat": 36.95000076293945,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "088004",
      "nome": "Giarratana",
      "nomeStraniero": null,
      "codiceCatastale": "E016",
      "cap": "97010",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "segreteria@pec.comunegiarratana.gov.it",
      "pec": "protocollo@pec.comune.giarratana.rg.it",
      "telefono": "+39 0932-974301",
      "fax": "+39 0932 974321",
      "coordinate": {
        "lat": 37.04999923706055,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "088005",
      "nome": "Ispica",
      "nomeStraniero": null,
      "codiceCatastale": "E366",
      "cap": "97014",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "info@comune.ispica.rg.it",
      "pec": "info@cert.comune.ispica.rg.it",
      "telefono": "+39 0932 701111",
      "fax": "+39 0932 950450",
      "coordinate": {
        "lat": 36.7852783203125,
        "lng": 14.906944274902344
      }
    },
    {
      "codice": "088006",
      "nome": "Modica",
      "nomeStraniero": null,
      "codiceCatastale": "F258",
      "cap": "97015",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "info@comune.modica.rg.it",
      "pec": "protocollo.comune.modica@pec.it",
      "telefono": "+39 0932 759111",
      "fax": "+39 0932 759251",
      "coordinate": {
        "lat": 36.86722183227539,
        "lng": 14.761388778686523
      }
    },
    {
      "codice": "088007",
      "nome": "Monterosso Almo",
      "nomeStraniero": null,
      "codiceCatastale": "F610",
      "cap": "97010",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "segreteria@comune.monterosso-almo.rg.it",
      "pec": "protocollo@pec.comune.monterosso-almo.rg.it",
      "telefono": "+39 0932970261",
      "fax": "+39 0932977239",
      "coordinate": {
        "lat": 37.099998474121094,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "088008",
      "nome": "Pozzallo",
      "nomeStraniero": null,
      "codiceCatastale": "G953",
      "cap": "97016",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "sindaco@comune.pozzallo.rg.it",
      "pec": "protocollo.comune.pozzallo.rg@pec.it",
      "telefono": "+39 09321839111",
      "fax": "+39 0932/1839220",
      "coordinate": {
        "lat": 36.730281829833984,
        "lng": 14.846719741821289
      }
    },
    {
      "codice": "088009",
      "nome": "Ragusa",
      "nomeStraniero": null,
      "codiceCatastale": "H163",
      "cap": "97100",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "sindaco@comune.ragusa.it",
      "pec": "protocollo@pec.comune.ragusa.gov.it",
      "telefono": "+39 0932 676111",
      "fax": "+39 0932 624804",
      "coordinate": {
        "lat": 36.92499923706055,
        "lng": 14.730555534362793
      }
    },
    {
      "codice": "088010",
      "nome": "Santa Croce Camerina",
      "nomeStraniero": null,
      "codiceCatastale": "I178",
      "cap": "97017",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "sindaco@comune.santa-croce-camerina.rg.it",
      "pec": "comune.santacrocecamerina@anutel.it",
      "telefono": "+39 0932/914111",
      "fax": "+39 0932/914160",
      "coordinate": {
        "lat": 36.82722091674805,
        "lng": 14.52388858795166
      }
    },
    {
      "codice": "088011",
      "nome": "Scicli",
      "nomeStraniero": null,
      "codiceCatastale": "I535",
      "cap": "97018",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "info@comune.scicli.rg.it",
      "pec": "protocollo@pec.comune.scicli.rg.it",
      "telefono": "+39 0932 839111",
      "fax": "+39 0932 841687",
      "coordinate": {
        "lat": 36.791378021240234,
        "lng": 14.702486038208008
      }
    },
    {
      "codice": "088012",
      "nome": "Vittoria",
      "nomeStraniero": null,
      "codiceCatastale": "M088",
      "cap": "97019",
      "prefisso": "0932",
      "provincia": "Ragusa",
      "email": "sindaco@comune.vittoria.rg.it",
      "pec": "dirigente.affarigenerali@pec.comunevittoria.gov.it",
      "telefono": "+39 0932 514292",
      "fax": "+39 0932 862545",
      "coordinate": {
        "lat": 36.95000076293945,
        "lng": 14.533333778381348
      }
    }
  ],
  "Foggia": [
    {
      "codice": "071001",
      "nome": "Accadia",
      "nomeStraniero": null,
      "codiceCatastale": "A015",
      "cap": "71021",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "segreteria.accadia@tiscali.it",
      "pec": "segreteria@pec.comune.accadia.fg.it",
      "telefono": "+39 0881.981012",
      "fax": "+39 0881.987126",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 15.333333015441895
      }
    },
    {
      "codice": "071002",
      "nome": "Alberona",
      "nomeStraniero": null,
      "codiceCatastale": "A150",
      "cap": "71031",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "sindaco@comune.alberona.fg.it",
      "pec": "affarigenerali.alberona@pec.dauniavalley.it",
      "telefono": "+39 0881.592022",
      "fax": "+39 0881.592151",
      "coordinate": {
        "lat": 41.43333435058594,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "071003",
      "nome": "Anzano di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "A320",
      "cap": "71020",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "comuneanzanodipuglia@libero.it",
      "pec": "affarigenerali@pec.comune.anzanodipuglia.fg.it",
      "telefono": "+39 0881.982037",
      "fax": "+39 0881.982038",
      "coordinate": {
        "lat": 41.11666488647461,
        "lng": 15.283333778381348
      }
    },
    {
      "codice": "071004",
      "nome": "Apricena",
      "nomeStraniero": null,
      "codiceCatastale": "A339",
      "cap": "71011",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "segretario.generale@comune.apricena.fg.it",
      "pec": "protocollo@pec-apricena.com",
      "telefono": "+39 0882.646742",
      "fax": "+39 0882.646779",
      "coordinate": {
        "lat": 41.784584045410156,
        "lng": 15.443578720092773
      }
    },
    {
      "codice": "071005",
      "nome": "Ascoli Satriano",
      "nomeStraniero": null,
      "codiceCatastale": "A463",
      "cap": "71022",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "sindaco@comune.ascolisatriano.fg.it",
      "pec": "segreteria.ascolisatriano@pec.leonet.it",
      "telefono": "+39 0885.652811",
      "fax": "+39 0885.652823",
      "coordinate": {
        "lat": 41.21555709838867,
        "lng": 15.557777404785156
      }
    },
    {
      "codice": "071006",
      "nome": "Biccari",
      "nomeStraniero": null,
      "codiceCatastale": "A854",
      "cap": "71032",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "municipio@comune.biccari.fg.it",
      "pec": "comune@pec.comune.biccari.fg.it",
      "telefono": "+39 0881.591007",
      "fax": "+39 0881.591173",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "071007",
      "nome": "Bovino",
      "nomeStraniero": null,
      "codiceCatastale": "B104",
      "cap": "71023",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "segreteria@comune.bovino.fg.it",
      "pec": "comune.bovino.fg@halleycert.it",
      "telefono": "+39 0881.966729",
      "fax": "+39 0881.961093",
      "coordinate": {
        "lat": 41.25,
        "lng": 15.350000381469727
      }
    },
    {
      "codice": "071008",
      "nome": "Cagnano Varano",
      "nomeStraniero": null,
      "codiceCatastale": "B357",
      "cap": "71010",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "elettorale@cagnanovarano.gov.it",
      "pec": "statocivile.comunecagnanovarano@pec.it",
      "telefono": "+39 0884.853281",
      "fax": "+39 0884.853281",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "071009",
      "nome": "Candela",
      "nomeStraniero": null,
      "codiceCatastale": "B584",
      "cap": "71024",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "elettorale@comune.candela.fg.it",
      "pec": "comune.candela.fg@halleycert.it",
      "telefono": "+39 0885656312",
      "fax": "+39 0885653995",
      "coordinate": {
        "lat": 41.13333511352539,
        "lng": 15.516666412353516
      }
    },
    {
      "codice": "071010",
      "nome": "Carapelle",
      "nomeStraniero": null,
      "codiceCatastale": "B724",
      "cap": "71041",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "sindaco@comune.carapelle.fg.it",
      "pec": "segreteria@pec.comune.carapelle.fg.it",
      "telefono": "+39 0885.795094",
      "fax": "+39 0885.795579",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 15.699999809265137
      }
    },
    {
      "codice": "071011",
      "nome": "Carlantino",
      "nomeStraniero": null,
      "codiceCatastale": "B784",
      "cap": "71030",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "anagrafe@comune.carlantino.fg.it",
      "pec": "segreteria@pec.comune.carlantino.fg.it",
      "telefono": "+39 0881.552224",
      "fax": "+39 0881.552446",
      "coordinate": {
        "lat": 41.59458541870117,
        "lng": 14.975414276123047
      }
    },
    {
      "codice": "071012",
      "nome": "Carpino",
      "nomeStraniero": null,
      "codiceCatastale": "B829",
      "cap": "71010",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "comunecarpino@tiscali.it",
      "pec": "info@pec.comunecarpino.it",
      "telefono": "+39 0884900811",
      "fax": "+39 0884900834",
      "coordinate": {
        "lat": 41.75,
        "lng": 15.841666221618652
      }
    },
    {
      "codice": "071013",
      "nome": "Casalnuovo Monterotaro",
      "nomeStraniero": null,
      "codiceCatastale": "B904",
      "cap": "71033",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "urp@comune.casalnuovomonterotaro.fg.it",
      "pec": "ufficioprotocollo.casalnuovomonterotaro@pec.leonet.it",
      "telefono": "+39 0881.558183",
      "fax": "+39 0881.558145",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "071014",
      "nome": "Casalvecchio di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "B917",
      "cap": "71030",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "segreteria@comune.casalvecchiodipuglia.fg.it",
      "pec": "protocollo@pec.comune.casalvecchiodipuglia.fg.it",
      "telefono": "+39 0881.553018",
      "fax": "+39 0881.553571",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "071015",
      "nome": "Castelluccio dei Sauri",
      "nomeStraniero": null,
      "codiceCatastale": "C198",
      "cap": "71025",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "info@comune.castellucciodeisauri.fg.it",
      "pec": "comune@pec.comune.castellucciodeisauri.fg.it",
      "telefono": "+39 0881.962021",
      "fax": "+39 0881.962263",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 15.483333587646484
      }
    },
    {
      "codice": "071016",
      "nome": "Castelluccio Valmaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C202",
      "cap": "71020",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "affarigenerali@comune.castellucciovalmaggiore.fg.it",
      "pec": "affarigenerali.castellucciovm@cittaconnessa.it",
      "telefono": "+39 0881.972015",
      "fax": "+39 0881.972015",
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "071017",
      "nome": "Castelnuovo della Daunia",
      "nomeStraniero": null,
      "codiceCatastale": "C222",
      "cap": "71034",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "sindaco@comune.castelnuovodelladaunia.fg.it",
      "pec": "comune.castelnuovodelladaunia.fg@halleycert.it",
      "telefono": "+39 0881.511211",
      "fax": "+39 0881.511222",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "071018",
      "nome": "Celenza Valfortore",
      "nomeStraniero": null,
      "codiceCatastale": "C429",
      "cap": "71035",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "protocollo@comune.celenzavalfortore.fg.it",
      "pec": "affgen@pec.comune.celenzavalfortore.fg.it",
      "telefono": "+39 0881.554016",
      "fax": "+39 0881.554748",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "071019",
      "nome": "Celle di San Vito",
      "nomeStraniero": null,
      "codiceCatastale": "C442",
      "cap": "71020",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "servizidemografici@comune.celledisanvito.fg.it",
      "pec": "protocollo@pec.comune.celledisanvito.fg.it",
      "telefono": "+39 0881.972031",
      "fax": "+39 0881.972714",
      "coordinate": {
        "lat": 41.32555389404297,
        "lng": 15.180832862854004
      }
    },
    {
      "codice": "071020",
      "nome": "Cerignola",
      "nomeStraniero": null,
      "codiceCatastale": "C514",
      "cap": "71042",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "sindaco@comune.cerignola.fg.it",
      "pec": "protocollo.comune.cerignola@pec.rupar.puglia.it",
      "telefono": "+39 0885 410111",
      "fax": "+39 0885 410400",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "071021",
      "nome": "Chieuti",
      "nomeStraniero": null,
      "codiceCatastale": "C633",
      "cap": "71010",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "segreteria.chieuti@virgilio.it",
      "pec": "segreteria.chieuti@pec.it",
      "telefono": "+39 0882.689495",
      "fax": "+39 0882.689113",
      "coordinate": {
        "lat": 41.849998474121094,
        "lng": 15.149999618530273
      }
    },
    {
      "codice": "071022",
      "nome": "Deliceto",
      "nomeStraniero": null,
      "codiceCatastale": "D269",
      "cap": "71026",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "info@comune.deliceto.fg.it",
      "pec": "protocollo.comune.deliceto@cittaconnessa.it",
      "telefono": "+39 0881.914311",
      "fax": "+39 0881.914333",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 15.383333206176758
      }
    },
    {
      "codice": "071023",
      "nome": "Faeto",
      "nomeStraniero": null,
      "codiceCatastale": "D459",
      "cap": "71020",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "sindaco@comune.faeto.fg.it",
      "pec": "comune@pec.comune.faeto.fg.it",
      "telefono": "+39 0881.974511",
      "fax": "+39 0881.974516",
      "coordinate": {
        "lat": 41.32500076293945,
        "lng": 15.160833358764648
      }
    },
    {
      "codice": "071024",
      "nome": "Foggia",
      "nomeStraniero": null,
      "codiceCatastale": "D643",
      "cap": "71121",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "protocollo@comune.foggia.it",
      "pec": "protocollo.generale@cert.comune.foggia.it",
      "telefono": "+39 0881.792111",
      "fax": "+39 0881.721615",
      "coordinate": {
        "lat": 41.45845031738281,
        "lng": 15.5518798828125
      }
    },
    {
      "codice": "071025",
      "nome": "Ischitella",
      "nomeStraniero": null,
      "codiceCatastale": "E332",
      "cap": "71010",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "aagg@comune.ischitella.fg.it",
      "pec": "protocollo@pec.comune.ischitella.fg.it",
      "telefono": "+39 0884.918411",
      "fax": "+39 0884.918426",
      "coordinate": {
        "lat": 41.900001525878906,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "071026",
      "nome": "Isole Tremiti",
      "nomeStraniero": null,
      "codiceCatastale": "E363",
      "cap": "71040",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "anagrafetremiti@libero.it",
      "pec": "anagrafetremiti@pec.it",
      "telefono": "+39 0882.463063",
      "fax": "+39 0882.463003",
      "coordinate": {
        "lat": 42.11666488647461,
        "lng": 15.5
      }
    },
    {
      "codice": "071027",
      "nome": "Lesina",
      "nomeStraniero": null,
      "codiceCatastale": "E549",
      "cap": "71010",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "segreteria@comunelesina.it",
      "pec": "comunelesina@pec.it",
      "telefono": "+39 0882.707420",
      "fax": "+39 0882.707495",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 15.350000381469727
      }
    },
    {
      "codice": "071028",
      "nome": "Lucera",
      "nomeStraniero": null,
      "codiceCatastale": "E716",
      "cap": "71036",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "info@comune.lucera.fg.it",
      "pec": "comune.lucera@anutel.it",
      "telefono": "+39 0881.541111",
      "fax": "+39 0881.540029",
      "coordinate": {
        "lat": 41.5,
        "lng": 15.333333015441895
      }
    },
    {
      "codice": "071029",
      "nome": "Manfredonia",
      "nomeStraniero": null,
      "codiceCatastale": "E885",
      "cap": "71043",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "sindaco@comune.manfredonia.fg.it",
      "pec": "elettorale@comunemanfredonia.legalmail.it",
      "telefono": "+39 0884/519604",
      "fax": "+39 0884/519607",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "071031",
      "nome": "Mattinata",
      "nomeStraniero": null,
      "codiceCatastale": "F059",
      "cap": "71030",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "affarigenerali@comune.mattinata.fg.it",
      "pec": "comunemattinata@pec.it",
      "telefono": "+39 0884.552422",
      "fax": "+39 0884.552426",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "071033",
      "nome": "Monte Sant'Angelo",
      "nomeStraniero": null,
      "codiceCatastale": "F631",
      "cap": "71037",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "info@montesantangelo.it",
      "pec": "protocollo@montesantangelo.it",
      "telefono": "+39 0884.566214",
      "fax": null,
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "071032",
      "nome": "Monteleone di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "F538",
      "cap": "71020",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "comunemonteleonefg@libero.it",
      "pec": "comune.monteleonedipuglia.fg@anutel.it",
      "telefono": "+39 0881.983397",
      "fax": "+39 0881.983016",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "071034",
      "nome": "Motta Montecorvino",
      "nomeStraniero": null,
      "codiceCatastale": "F777",
      "cap": "71030",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "sindaco@comune.mottamontecorvino.fg.it",
      "pec": "protocollo@pec.comune.mottamontecorvino.fg.it",
      "telefono": "+39 0881.551007",
      "fax": "+39 0881.551231",
      "coordinate": {
        "lat": 41.5,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "071063",
      "nome": "Ordona",
      "nomeStraniero": null,
      "codiceCatastale": "M266",
      "cap": "71040",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "servizidemografici@comune.ordona.fg.it",
      "pec": "demografico@pec.comune.ordona.fg.it",
      "telefono": "+39 0885.796221",
      "fax": "+39 0885.796221",
      "coordinate": {
        "lat": 41.31666564941406,
        "lng": 15.633333206176758
      }
    },
    {
      "codice": "071035",
      "nome": "Orsara di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "G125",
      "cap": "71027",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "orsara.ragioneria@virgilio.it",
      "pec": "comune@pec.comune.orsaradipuglia.fg.it",
      "telefono": "+39 0881.964013",
      "fax": "+39 0881.964015",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "071036",
      "nome": "Orta Nova",
      "nomeStraniero": null,
      "codiceCatastale": "G131",
      "cap": "71045",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "demografico@cittaortanova-fg.it",
      "pec": "demografico.ortanova@pec.it",
      "telefono": "+39 0885.780230",
      "fax": "+39 0885.780254",
      "coordinate": {
        "lat": 41.330833435058594,
        "lng": 15.71138858795166
      }
    },
    {
      "codice": "071037",
      "nome": "Panni",
      "nomeStraniero": null,
      "codiceCatastale": "G312",
      "cap": "71020",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "demografici@comune.panni.fg.it",
      "pec": "demografici@pec.comune.panni.fg.it",
      "telefono": "+39 0881 965039",
      "fax": "+39 0881 965505",
      "coordinate": {
        "lat": 41.220821380615234,
        "lng": 15.27560043334961
      }
    },
    {
      "codice": "071038",
      "nome": "Peschici",
      "nomeStraniero": null,
      "codiceCatastale": "G487",
      "cap": "71010",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "sindaco@comune.peschici.fg.it",
      "pec": "protocollo.comunepeschici@pec.it",
      "telefono": "+39 0884 964018",
      "fax": "+39 0884 962655",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 16.016666412353516
      }
    },
    {
      "codice": "071039",
      "nome": "Pietramontecorvino",
      "nomeStraniero": null,
      "codiceCatastale": "G604",
      "cap": "71038",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "settore.demografico@pietramontecorvino.fg.it",
      "pec": "protocollo@pec.comune.pietramontecorvino.fg.it",
      "telefono": "+39 0881.555020",
      "fax": "+39 0881.555189",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "071040",
      "nome": "Poggio Imperiale",
      "nomeStraniero": null,
      "codiceCatastale": "G761",
      "cap": "71010",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "comune.poggioimperiale@fg.nettuno.it",
      "pec": "segreteria.poggioimperiale@pec.it",
      "telefono": "+39 0882.994090",
      "fax": "+39 0882.999000",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 15.366666793823242
      }
    },
    {
      "codice": "071041",
      "nome": "Rignano Garganico",
      "nomeStraniero": null,
      "codiceCatastale": "H287",
      "cap": "71010",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "comunerignano@tin.it",
      "pec": "comune.rignanogarganico.fg@pec.rupar.puglia.it",
      "telefono": "+39 0882.820018",
      "fax": "+39 0882.228357",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 15.583333015441895
      }
    },
    {
      "codice": "071042",
      "nome": "Rocchetta Sant'Antonio",
      "nomeStraniero": null,
      "codiceCatastale": "H467",
      "cap": "71020",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "segreteria@comune.rocchettasantantonio.fg.it",
      "pec": "protocollocomune.rocchettasantantonio.fg@pec.leonet.it",
      "telefono": "+39 0885.654007",
      "fax": "+39 0885.654360",
      "coordinate": {
        "lat": 41.099998474121094,
        "lng": 15.466666221618652
      }
    },
    {
      "codice": "071043",
      "nome": "Rodi Garganico",
      "nomeStraniero": null,
      "codiceCatastale": "H480",
      "cap": "71012",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "protocollo@pec.comune.rodigarganico.fg.it",
      "pec": "protocollo@pec.comune.rodigarganico.fg.it",
      "telefono": "0884919411",
      "fax": "+39 0884-966263",
      "coordinate": {
        "lat": 41.89611053466797,
        "lng": 15.865278244018555
      }
    },
    {
      "codice": "071044",
      "nome": "Roseto Valfortore",
      "nomeStraniero": null,
      "codiceCatastale": "H568",
      "cap": "71039",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "roseto@comune.rosetovalfortore.fg.it",
      "pec": "ufficioanagrafe@pec.roseto-valfortore.it",
      "telefono": "+39 0881.594711",
      "fax": "+39 0881.594480",
      "coordinate": {
        "lat": 41.37543869018555,
        "lng": 15.09823989868164
      }
    },
    {
      "codice": "071046",
      "nome": "San Giovanni Rotondo",
      "nomeStraniero": null,
      "codiceCatastale": "H926",
      "cap": "71013",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "ufficiosegreteria@comune.sangiovannirotondo.fg.it",
      "pec": "comune.sangiovannirotondo.protocollo@pec.rupar.puglia.it",
      "telefono": "+39 0882.415111",
      "fax": "+39 0882.415150",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 15.733333587646484
      }
    },
    {
      "codice": "071047",
      "nome": "San Marco in Lamis",
      "nomeStraniero": null,
      "codiceCatastale": "H985",
      "cap": "71014",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "paolo.schiena@libero.it",
      "pec": "protocollo@pec.comune.sanmarcoinlamis.fg.it",
      "telefono": "+39 0882.813252",
      "fax": "+39 0882.813209",
      "coordinate": {
        "lat": 41.711666107177734,
        "lng": 15.635000228881836
      }
    },
    {
      "codice": "071048",
      "nome": "San Marco la Catola",
      "nomeStraniero": null,
      "codiceCatastale": "H986",
      "cap": "71030",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "info@comune.sanmarcolacatola.fg.it",
      "pec": "comune@pec.comune.sanmarcolacatola.fg.it",
      "telefono": "+39 0881.556016",
      "fax": "+39 0881.556132",
      "coordinate": {
        "lat": 41.525001525878906,
        "lng": 15.006667137145996
      }
    },
    {
      "codice": "071049",
      "nome": "San Nicandro Garganico",
      "nomeStraniero": null,
      "codiceCatastale": "I054",
      "cap": "71015",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "info@comune.sannicandrogarganico.fg.it",
      "pec": "protocollo.sannicandrog@cittaconnessa.it",
      "telefono": "+39 0882 477111",
      "fax": "+39 0882 477316",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 15.566666603088379
      }
    },
    {
      "codice": "071050",
      "nome": "San Paolo di Civitate",
      "nomeStraniero": null,
      "codiceCatastale": "I072",
      "cap": "71010",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "comune.statocivile@libero.it",
      "pec": "sindaco@pec.comune.sanpaolodicivitate.fg.it",
      "telefono": "+39 0882 556229",
      "fax": "+39 0882 556244",
      "coordinate": {
        "lat": 41.733333587646484,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "071051",
      "nome": "San Severo",
      "nomeStraniero": null,
      "codiceCatastale": "I158",
      "cap": "71016",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "gab.comune.sansevero@tiscali.it",
      "pec": "protocollo@pec.comune.san-severo.fg.it",
      "telefono": "+39 0882/339111",
      "fax": "+39 0882/339259",
      "coordinate": {
        "lat": 41.69511032104492,
        "lng": 15.379278182983398
      }
    },
    {
      "codice": "071052",
      "nome": "Sant'Agata di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "I193",
      "cap": "71028",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "info@comune.santagatadipuglia.fg.it",
      "pec": "comune.santagatadipuglia.fg@halleycert.it",
      "telefono": "+39 0881984007",
      "fax": "+39 0881984084",
      "coordinate": {
        "lat": 41.15277862548828,
        "lng": 15.380833625793457
      }
    },
    {
      "codice": "071053",
      "nome": "Serracapriola",
      "nomeStraniero": null,
      "codiceCatastale": "I641",
      "cap": "71010",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "anagrafeserracapriola@gmail.com",
      "pec": "segreteria_protocollo.serracapriola@pec.it",
      "telefono": "+39 0882680211",
      "fax": "+39 0882.681013",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "071054",
      "nome": "Stornara",
      "nomeStraniero": null,
      "codiceCatastale": "I962",
      "cap": "71047",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "anagrafe@comune.stornara.fg.it",
      "pec": "protocollo@pec.comune.stornara.fg.it",
      "telefono": "+39 0885.331203",
      "fax": "+39 0885.331224",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "071055",
      "nome": "Stornarella",
      "nomeStraniero": null,
      "codiceCatastale": "I963",
      "cap": "71048",
      "prefisso": "0885",
      "provincia": "Foggia",
      "email": "comunedistornarella@libero.it",
      "pec": "comunedistornarella@legpec.it",
      "telefono": "+39 0885.437220",
      "fax": "+39 0885.437227",
      "coordinate": {
        "lat": 41.25,
        "lng": 15.733333587646484
      }
    },
    {
      "codice": "071056",
      "nome": "Torremaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "L273",
      "cap": "71017",
      "prefisso": "0882",
      "provincia": "Foggia",
      "email": "uffcom.torremaggiore@legalmail.it",
      "pec": "uffcom.torremaggiore@legalmail.it",
      "telefono": "+39 0882.391111",
      "fax": "+39 0882.382273",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 15.283332824707031
      }
    },
    {
      "codice": "071058",
      "nome": "Troia",
      "nomeStraniero": null,
      "codiceCatastale": "L447",
      "cap": "71029",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "sindaco@comune.troia.fg.it",
      "pec": "protocollo@pec.comune.troia.fg.it",
      "telefono": "+39 0881.978417",
      "fax": "+39 0881.978420",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "071059",
      "nome": "Vico del Gargano",
      "nomeStraniero": null,
      "codiceCatastale": "L842",
      "cap": "71018",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "info@comune.vicodelgargano.fg.it",
      "pec": "vicodelgargano@postecert.it",
      "telefono": "+39 0884.998338",
      "fax": "+39 0884.998323",
      "coordinate": {
        "lat": 41.849998474121094,
        "lng": 15.956388473510742
      }
    },
    {
      "codice": "071060",
      "nome": "Vieste",
      "nomeStraniero": null,
      "codiceCatastale": "L858",
      "cap": "71019",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "segreteriasindaco@comunedivieste.it",
      "pec": "segreteriagenerale.comune.vieste@pec.rupar.puglia.it",
      "telefono": "+39 0884.712211",
      "fax": "+39 0884.712253",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "071061",
      "nome": "Volturara Appula",
      "nomeStraniero": null,
      "codiceCatastale": "M131",
      "cap": "71030",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "affarigenerali@comune.volturaraappula.fg.it",
      "pec": "affarigenerali@pec.comune.volturaraappula.fg.it",
      "telefono": "+39 0881.557005",
      "fax": "+39 0881.557098",
      "coordinate": {
        "lat": 41.5,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "071062",
      "nome": "Volturino",
      "nomeStraniero": null,
      "codiceCatastale": "M132",
      "cap": "71030",
      "prefisso": "0881",
      "provincia": "Foggia",
      "email": "volturino@libero.it",
      "pec": "protocollo@pec.comune.volturino.fg.it",
      "telefono": "+39 0881 550107",
      "fax": "+39 0881 550510",
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "071064",
      "nome": "Zapponeta",
      "nomeStraniero": null,
      "codiceCatastale": "M267",
      "cap": "71030",
      "prefisso": "0884",
      "provincia": "Foggia",
      "email": "comunedizapponeta@tiscali.it",
      "pec": "info.comune.zapponeta@pec.rupar.puglia.it",
      "telefono": "+39 0884.521211",
      "fax": "+39 0884.529160",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 15.966666221618652
      }
    }
  ],
  "Cuneo": [
    {
      "codice": "004001",
      "nome": "Acceglio",
      "nomeStraniero": null,
      "codiceCatastale": "A016",
      "cap": "12021",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.acceglio.cn.it",
      "pec": "comuneacceglio@pcert.postecert.it",
      "telefono": "+39 0171/99013",
      "fax": "+39 0171/99436",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 6.983333110809326
      }
    },
    {
      "codice": "004002",
      "nome": "Aisone",
      "nomeStraniero": null,
      "codiceCatastale": "A113",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.aisone@vallestura.cn.it",
      "pec": "aisone@cert.ruparpiemonte.it",
      "telefono": "+39 0171/95751",
      "fax": "+39 0171/956607",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.2166666984558105
      }
    },
    {
      "codice": "004003",
      "nome": "Alba",
      "nomeStraniero": null,
      "codiceCatastale": "A124",
      "cap": "12051",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "m.toppino@comune.alba.cn.it",
      "pec": "comune.alba@cert.legalmail.it",
      "telefono": "+39 0173/292400",
      "fax": "+39 0173/35896",
      "coordinate": {
        "lat": 44.69154357910156,
        "lng": 8.025635719299316
      }
    },
    {
      "codice": "004004",
      "nome": "Albaretto della Torre",
      "nomeStraniero": null,
      "codiceCatastale": "A139",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "albarettotorre@tiscali.it",
      "pec": "comune.albarettotorre.cn@cert.legalmail.it",
      "telefono": "+39 0173/520137",
      "fax": "+39 0173/521963",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "004005",
      "nome": "Alto",
      "nomeStraniero": null,
      "codiceCatastale": "A238",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "alto@ruparpiemonte.it",
      "pec": "alto@cert.ruparpiemonte.it",
      "telefono": "+39 0174/391821",
      "fax": "+39 0174/ 91860",
      "coordinate": {
        "lat": 44.11666488647461,
        "lng": 8
      }
    },
    {
      "codice": "004006",
      "nome": "Argentera",
      "nomeStraniero": null,
      "codiceCatastale": "A394",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.argentera@libero.it",
      "pec": "argentera@cert.ruparpiemonte.it",
      "telefono": "+39 0171/96710",
      "fax": "+39 0171/968900",
      "coordinate": {
        "lat": 44.39630126953125,
        "lng": 6.937829971313477
      }
    },
    {
      "codice": "004007",
      "nome": "Arguello",
      "nomeStraniero": null,
      "codiceCatastale": "A396",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "segreteria@comune.arguello.cn.it",
      "pec": "comune.arguello.cn@cert.legalmail.it",
      "telefono": "+39 0173/520016",
      "fax": "+39 0173/520016",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "004008",
      "nome": "Bagnasco",
      "nomeStraniero": null,
      "codiceCatastale": "A555",
      "cap": "12071",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.bagnasco.cn.it",
      "pec": "comune.bagnasco.cn@legalmail.it",
      "telefono": "+39 0174/76047",
      "fax": "+39 0174/76331",
      "coordinate": {
        "lat": 44.30411911010742,
        "lng": 8.045220375061035
      }
    },
    {
      "codice": "004009",
      "nome": "Bagnolo Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "A571",
      "cap": "12031",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "rinella@comune.bagnolo.cn.it",
      "pec": "comune.bagnolo.cn@legalmail.it",
      "telefono": "+39 0175/391121",
      "fax": "+39 0175/392790",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "004010",
      "nome": "Baldissero d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "A589",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "comunebaldisseroalba@tiscali.it",
      "pec": "baldissero.dalba@cert.ruparpiemonte.it",
      "telefono": "+39 0172/40032",
      "fax": "+39 0172/40567",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004011",
      "nome": "Barbaresco",
      "nomeStraniero": null,
      "codiceCatastale": "A629",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "barbaresco@comune.barbaresco.cn.it",
      "pec": "pec@pec.comune.barbaresco.cn.it",
      "telefono": "+39 0173/635135",
      "fax": "+39 0173/635234",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "004012",
      "nome": "Barge",
      "nomeStraniero": null,
      "codiceCatastale": "A660",
      "cap": "12032",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "barge@ruparpiemonte.it",
      "pec": "barge@cert.ruparpiemonte.it",
      "telefono": "+39 0175/347620",
      "fax": "+39 0175/343623",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "004013",
      "nome": "Barolo",
      "nomeStraniero": null,
      "codiceCatastale": "A671",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@comune.barolo.cn.it",
      "pec": "barolo@pec.langabarolo.it",
      "telefono": "+39 0173/56106",
      "fax": "+39 0173/56381",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004014",
      "nome": "Bastia Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "A709",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "info@bastiamondovi.info",
      "pec": "bastia.mondovi@cert.ruparpiemonte.it",
      "telefono": "+39 0174/60112",
      "fax": "+39 0174/60393",
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 7.900000095367432
      }
    },
    {
      "codice": "004015",
      "nome": "Battifollo",
      "nomeStraniero": null,
      "codiceCatastale": "A716",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "battifollo@ruparpiemonte.it",
      "pec": "battifollo@cert.ruparpiemonte.it",
      "telefono": "+39 0174/783318",
      "fax": "+39 0174/783707",
      "coordinate": {
        "lat": 44.31983184814453,
        "lng": 8.010939598083496
      }
    },
    {
      "codice": "004016",
      "nome": "Beinette",
      "nomeStraniero": null,
      "codiceCatastale": "A735",
      "cap": "12081",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "protocollo@comune.beinette.cn.it",
      "pec": "protocollo@pec.comune.beinette.cn.it",
      "telefono": "+39 0171/384004",
      "fax": "+39 0171/384795",
      "coordinate": {
        "lat": 44.36666488647461,
        "lng": 7.650000095367432
      }
    },
    {
      "codice": "004017",
      "nome": "Bellino",
      "nomeStraniero": null,
      "codiceCatastale": "A750",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "info@comune.bellino.cn.it",
      "pec": "bellino@postemailcertificata.it",
      "telefono": "+39 0175/95110",
      "fax": "+39 0175/956900",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.0333333015441895
      }
    },
    {
      "codice": "004018",
      "nome": "Belvedere Langhe",
      "nomeStraniero": null,
      "codiceCatastale": "A774",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "belvedere.langhe@reteunitaria.piemonte.it",
      "pec": "comune.belvederelanghe.cn@legalmail.it",
      "telefono": "+39 0173/743005",
      "fax": "+39 0173/743907",
      "coordinate": {
        "lat": 44.5,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "004019",
      "nome": "Bene Vagienna",
      "nomeStraniero": null,
      "codiceCatastale": "A779",
      "cap": "12041",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "ufficioprotocollo@benevagienna.it",
      "pec": "bene.vagienna@cert.ruparpiemonte.it",
      "telefono": "+39 0172/654152",
      "fax": "+39 0172/654588",
      "coordinate": {
        "lat": 44.5452766418457,
        "lng": 7.83305549621582
      }
    },
    {
      "codice": "004020",
      "nome": "Benevello",
      "nomeStraniero": null,
      "codiceCatastale": "A782",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune@comune.benevello.cn.it",
      "pec": "comune.benevello.cn@cert.legalmail.it",
      "telefono": "+39 0173529500",
      "fax": "+39 0173529907",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "004021",
      "nome": "Bergolo",
      "nomeStraniero": null,
      "codiceCatastale": "A798",
      "cap": "12074",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "bergolo@reteunitaria.piemonte.it",
      "pec": "comune.bergolo.cn@cert.legalmail.it",
      "telefono": "+39 0173/87016",
      "fax": "+39 0173/827949",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "004022",
      "nome": "Bernezzo",
      "nomeStraniero": null,
      "codiceCatastale": "A805",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.bernezzo.cn.it",
      "pec": "bernezzo@cert.legalmail.it",
      "telefono": "+39 0171/82044",
      "fax": "+39 0171/683826",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 7.433333396911621
      }
    },
    {
      "codice": "004023",
      "nome": "Bonvicino",
      "nomeStraniero": null,
      "codiceCatastale": "A979",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune.bonvicino@tin.it",
      "pec": "comune.bonvicino@legalmail.it",
      "telefono": "+39 0173/791400",
      "fax": "+39 0173/798950",
      "coordinate": {
        "lat": 44.5,
        "lng": 8.016666412353516
      }
    },
    {
      "codice": "004025",
      "nome": "Borgo San Dalmazzo",
      "nomeStraniero": null,
      "codiceCatastale": "B033",
      "cap": "12011",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "protocollo@comune.borgosandalmazzo.cn.it",
      "pec": "protocollo.borgosandalmazzo@cert.ruparpiemonte.it",
      "telefono": "+39 0171/754111",
      "fax": "+39 0171/754110",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.483333110809326
      }
    },
    {
      "codice": "004024",
      "nome": "Borgomale",
      "nomeStraniero": null,
      "codiceCatastale": "B018",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "borgomale.com@cnnet.it",
      "pec": "comune.borgomale.cn@cert.legalmail.it",
      "telefono": "+39 0173/529031",
      "fax": "+39 0173/529914",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "004026",
      "nome": "Bosia",
      "nomeStraniero": null,
      "codiceCatastale": "B079",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.bosia.cn.it",
      "pec": "comune.bosia.cn@cert.legalmail.it",
      "telefono": "+39 0173/854125",
      "fax": "+39 0173/854900",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 8.149999618530273
      }
    },
    {
      "codice": "004027",
      "nome": "Bossolasco",
      "nomeStraniero": null,
      "codiceCatastale": "B084",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "ufficiopubblico@comune.bossolasco.cn.it",
      "pec": "comune.bossolasco.cn@cert.legalmail.it",
      "telefono": "+39 0173/799009",
      "fax": "+39 0173/793464",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "004028",
      "nome": "Boves",
      "nomeStraniero": null,
      "codiceCatastale": "B101",
      "cap": "12012",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "segreteriaboves@comune.boves.cn.it",
      "pec": "comune.boves.cn@cert.legalmail.it",
      "telefono": "+39 0171/391811",
      "fax": "+39 0171/380091",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "004029",
      "nome": "Bra",
      "nomeStraniero": null,
      "codiceCatastale": "B111",
      "cap": "12042",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "elettorale@comune.bra.cn.it",
      "pec": "comunebra@postecert.it",
      "telefono": "+39 0172/438286",
      "fax": "+39 0172/438240",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 7.849999904632568
      }
    },
    {
      "codice": "004030",
      "nome": "Briaglia",
      "nomeStraniero": null,
      "codiceCatastale": "B167",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "briaglia@ruparpiemonte.it",
      "pec": "briaglia@cert.ruparpiemonte.it",
      "telefono": "+39 0174/563697",
      "fax": "+39 0174/569952",
      "coordinate": {
        "lat": 44.400001525878906,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "004031",
      "nome": "Briga Alta",
      "nomeStraniero": null,
      "codiceCatastale": "B175",
      "cap": "18025",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "briga.alta@ruparpiemonte.it",
      "pec": "briga.alta@cert.ruparpiemonte.it",
      "telefono": "+39 0174/393829",
      "fax": "+39 0174/393829",
      "coordinate": {
        "lat": 44.081298828125,
        "lng": 7.740888595581055
      }
    },
    {
      "codice": "004032",
      "nome": "Brondello",
      "nomeStraniero": null,
      "codiceCatastale": "B200",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "protocollo@comune.brondello.cn.it",
      "pec": "protocollo@pec.comune.brondello.cn.it",
      "telefono": "+39 0175/76125",
      "fax": "+39 0175/76125",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "004033",
      "nome": "Brossasco",
      "nomeStraniero": null,
      "codiceCatastale": "B204",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.brossasco.cn.it",
      "pec": "brossasco@cert.ruparpiemonte.it",
      "telefono": "+39 0175/68103",
      "fax": "+39 0175/68388",
      "coordinate": {
        "lat": 44.56972122192383,
        "lng": 7.361111164093018
      }
    },
    {
      "codice": "004034",
      "nome": "Busca",
      "nomeStraniero": null,
      "codiceCatastale": "B285",
      "cap": "12022",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "segreteria@comune.busca.cn.it",
      "pec": "segreteria@cert.busca.gov.it",
      "telefono": "+39 0171/948611",
      "fax": "+39 0171/948646",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 7.4666666984558105
      }
    },
    {
      "codice": "004035",
      "nome": "Camerana",
      "nomeStraniero": null,
      "codiceCatastale": "B467",
      "cap": "12072",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "comunecamerana@tiscalinet.it",
      "pec": "comune.camerana@cert.legalmail.it",
      "telefono": "+39 0174/96021",
      "fax": "+39 0174/96022",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "004037",
      "nome": "Canale",
      "nomeStraniero": null,
      "codiceCatastale": "B573",
      "cap": "12043",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "ufficioelettorale@comune.canale.cn.it",
      "pec": "ufficioprotocollo@certcomunecanale.it",
      "telefono": "+39 0173. 979129",
      "fax": "+39 0173.979044",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 8
      }
    },
    {
      "codice": "004038",
      "nome": "Canosio",
      "nomeStraniero": null,
      "codiceCatastale": "B621",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.canosio.cn.it",
      "pec": "canosio@cert.ruparpiemonte.it",
      "telefono": "+39 0171/998122",
      "fax": "+39 0171/998122",
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 7.083333492279053
      }
    },
    {
      "codice": "004039",
      "nome": "Caprauna",
      "nomeStraniero": null,
      "codiceCatastale": "B692",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "caprauna@ruparpiemonte.it",
      "pec": "caprauna@cert.ruparpiemonte.it",
      "telefono": "+39 0174/391812",
      "fax": "+39 0174/391812",
      "coordinate": {
        "lat": 44.11666488647461,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "004040",
      "nome": "Caraglio",
      "nomeStraniero": null,
      "codiceCatastale": "B719",
      "cap": "12023",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "v.chiapello@comune.caraglio.cn.it",
      "pec": "protocollo.caraglio@legalmail.it",
      "telefono": "+39 0171/617707",
      "fax": "//",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.433333396911621
      }
    },
    {
      "codice": "004041",
      "nome": "Caramagna Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "B720",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "caramagna.piemonte@ruparpiemonte.it",
      "pec": "comune.caramagnapiemonte.cn@legalmail.it",
      "telefono": "+39 0172/89004",
      "fax": "+39 0172/89710",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "004042",
      "nome": "Cardè",
      "nomeStraniero": null,
      "codiceCatastale": "B755",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "comune.carde@isiline.it",
      "pec": "carde@pec.comune.carde.cn.it",
      "telefono": "+39 0172 90101",
      "fax": "+39 0172 90320",
      "coordinate": {
        "lat": 44.744998931884766,
        "lng": 7.47777795791626
      }
    },
    {
      "codice": "004043",
      "nome": "Carrù",
      "nomeStraniero": null,
      "codiceCatastale": "B841",
      "cap": "12061",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo.segreteria@comune.carru.cn.it",
      "pec": "comune.carru.cn@cert.legalmail.it",
      "telefono": "+39 0173/757711",
      "fax": "+39 0173/757730",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "004044",
      "nome": "Cartignano",
      "nomeStraniero": null,
      "codiceCatastale": "B845",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "cartignano@ruparpiemonte.it",
      "pec": "segretariocomunale.cartignano@cert.ruparpiemonte.it",
      "telefono": "+39 0171/900259",
      "fax": "+39 0171/900800",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 7.2833333015441895
      }
    },
    {
      "codice": "004045",
      "nome": "Casalgrasso",
      "nomeStraniero": null,
      "codiceCatastale": "B894",
      "cap": "12030",
      "prefisso": "011",
      "provincia": "Cuneo",
      "email": "anagrafe.casalgrasso@ruparpiemonte.it",
      "pec": "comune.casalgrasso.cn@legalmail.it",
      "telefono": "+39 011/975650",
      "fax": "+39 011/975150",
      "coordinate": {
        "lat": 44.81666564941406,
        "lng": 7.633333206176758
      }
    },
    {
      "codice": "004046",
      "nome": "Castagnito",
      "nomeStraniero": null,
      "codiceCatastale": "C046",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.castagnito.cn.it",
      "pec": "comune.castagnito.cn@legalmail.it",
      "telefono": "+39 0173  213139",
      "fax": "+39 0173  213392",
      "coordinate": {
        "lat": 44.75,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004047",
      "nome": "Casteldelfino",
      "nomeStraniero": null,
      "codiceCatastale": "C081",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "comune.casteldelfino@mtrade.com",
      "pec": "casteldelfino@cert.ruparpiemonte.it",
      "telefono": "+39 0175/95126",
      "fax": "+39 0175/956921",
      "coordinate": {
        "lat": 44.59055709838867,
        "lng": 7.070833206176758
      }
    },
    {
      "codice": "004049",
      "nome": "Castelletto Stura",
      "nomeStraniero": null,
      "codiceCatastale": "C165",
      "cap": "12040",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "ragio@comune.castellettostura.cn.it",
      "pec": "info@pec.comune.castellettostura.cn.it",
      "telefono": "+39 0171/791148",
      "fax": "+39 0171/791979",
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 7.633333206176758
      }
    },
    {
      "codice": "004050",
      "nome": "Castelletto Uzzone",
      "nomeStraniero": null,
      "codiceCatastale": "C167",
      "cap": "12070",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "castelletto.uzzone@reteunitaria.piemonte.it",
      "pec": "castelletto.uzzone@cert.ruparpiemonte.it",
      "telefono": "+39 0173/89129",
      "fax": "+39 0173/839907",
      "coordinate": {
        "lat": 44.5,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "004051",
      "nome": "Castellinaldo d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "C173",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "anagrafe@castellinaldo.com",
      "pec": "comune.castellinaldo@mvmail.it",
      "telefono": "+39 0173/213072",
      "fax": "+39 0173/213353",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004052",
      "nome": "Castellino Tanaro",
      "nomeStraniero": null,
      "codiceCatastale": "C176",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "castellino.tanaro@reteunitaria.piemonte.it",
      "pec": "castellino.tanaro@cert.ruparpiemonte.it",
      "telefono": "+39 0174/785213",
      "fax": "+39 0174/710914",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "004053",
      "nome": "Castelmagno",
      "nomeStraniero": null,
      "codiceCatastale": "C205",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "castelmagno@ruparpiemonte.it",
      "pec": "castelmagno@cert.ruparpiemonte.it",
      "telefono": "+39 0171/986110",
      "fax": "+39 0171/986600",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.2166666984558105
      }
    },
    {
      "codice": "004054",
      "nome": "Castelnuovo di Ceva",
      "nomeStraniero": null,
      "codiceCatastale": "C214",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "zuninomaura.castelnuovo.di.ceva@reteunitaria.piemonte.it",
      "pec": "castelnuovo.di.ceva@cert.ruparpiemonte.it",
      "telefono": "+39 0174/79039",
      "fax": "+39 0174/719835",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "004055",
      "nome": "Castiglione Falletto",
      "nomeStraniero": null,
      "codiceCatastale": "C314",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "castiglionefalletto@comune.castiglionefalletto.cn.it",
      "pec": "castiglionefalletto@pec.langabarolo.it",
      "telefono": "+39 0173/62824",
      "fax": "+39 0173/62907",
      "coordinate": {
        "lat": 44.62397384643555,
        "lng": 7.97866678237915
      }
    },
    {
      "codice": "004056",
      "nome": "Castiglione Tinella",
      "nomeStraniero": null,
      "codiceCatastale": "C317",
      "cap": "12053",
      "prefisso": "0141",
      "provincia": "Cuneo",
      "email": "castiglione.tinella@reteunitaria.piemonte.it",
      "pec": "castiglionetinella@postemailcertificata.it",
      "telefono": "+39 0141/855102",
      "fax": "+39 0141/855935",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "004057",
      "nome": "Castino",
      "nomeStraniero": null,
      "codiceCatastale": "C323",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "castino@reteunitaria.piemonte.it",
      "pec": "castino@cert.ruparpiemonte.it",
      "telefono": "+39 0173/84012",
      "fax": "+39 0173/824907",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "004058",
      "nome": "Cavallerleone",
      "nomeStraniero": null,
      "codiceCatastale": "C375",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "comune.cavallerleone@multiwire.net",
      "pec": "cavallerleone@cert.ruparpiemonte.it",
      "telefono": "+39 0172/88003",
      "fax": "+39 0172/88174",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 7.666666507720947
      }
    },
    {
      "codice": "004059",
      "nome": "Cavallermaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C376",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.cavallermaggiore.cn.it",
      "pec": "protocollocavallermaggiore@actaliscertymail.it",
      "telefono": "+39 0172/381055",
      "fax": "+39 0172/382638",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 7.683333396911621
      }
    },
    {
      "codice": "004060",
      "nome": "Celle di Macra",
      "nomeStraniero": null,
      "codiceCatastale": "C441",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "segreteria@comune.celledimacra.cn.it",
      "pec": "celle.di.macra@cert.ruparpiemonte.it",
      "telefono": "+39 0171/999190",
      "fax": "+39 0171/999835",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 7.183333396911621
      }
    },
    {
      "codice": "004061",
      "nome": "Centallo",
      "nomeStraniero": null,
      "codiceCatastale": "C466",
      "cap": "12044",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "elettorale@comune.centallo.cn.it",
      "pec": "comunecentallo.cn@legalmail.it",
      "telefono": "+39 0171/211221",
      "fax": "+39 0171/211608",
      "coordinate": {
        "lat": 44.5,
        "lng": 7.583333492279053
      }
    },
    {
      "codice": "004062",
      "nome": "Ceresole Alba",
      "nomeStraniero": null,
      "codiceCatastale": "C504",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "ceresole.alba@ruparpiemonte.it",
      "pec": "ceresole.dalba@cert.ruparpiemonte.it",
      "telefono": "+39 017/574135",
      "fax": "+39 0172/574496",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 7.818333148956299
      }
    },
    {
      "codice": "004063",
      "nome": "Cerretto Langhe",
      "nomeStraniero": null,
      "codiceCatastale": "C530",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.cerrettolanghe.cn.it",
      "pec": "comune.cerrettolanghe.cn@legalmail.it",
      "telefono": "+39 0173 520264",
      "fax": "+39 0173 520368",
      "coordinate": {
        "lat": 44.5748405456543,
        "lng": 8.097940444946289
      }
    },
    {
      "codice": "004064",
      "nome": "Cervasca",
      "nomeStraniero": null,
      "codiceCatastale": "C547",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.cervasca.cn.it",
      "pec": "cervasca@postemailcertificata.it",
      "telefono": "+39 0171/684811",
      "fax": "+39 0171/684830",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 7.4666666984558105
      }
    },
    {
      "codice": "004065",
      "nome": "Cervere",
      "nomeStraniero": null,
      "codiceCatastale": "C550",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.cervere.cn.it",
      "pec": "cervere@postemailcertificata.it",
      "telefono": "+39 0172/471000",
      "fax": "+39 0172/474833",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 7.7833333015441895
      }
    },
    {
      "codice": "004066",
      "nome": "Ceva",
      "nomeStraniero": null,
      "codiceCatastale": "C589",
      "cap": "12073",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.ceva.cn.it",
      "pec": "comune.ceva.cn@cert.legalmail.it",
      "telefono": "+39 0174/704177",
      "fax": "+39 0174/701845",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004067",
      "nome": "Cherasco",
      "nomeStraniero": null,
      "codiceCatastale": "C599",
      "cap": "12062",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "protocollo@comune.cherasco.cn.it",
      "pec": "cherasco@postemailcertificata.it",
      "telefono": "+39 0172/427020",
      "fax": "+39 0172/427023",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 7.866666793823242
      }
    },
    {
      "codice": "004068",
      "nome": "Chiusa di Pesio",
      "nomeStraniero": null,
      "codiceCatastale": "C653",
      "cap": "12013",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "anagrafe@comunechiusapesio.it",
      "pec": "chiusa.di.pesio@cert.ruparpiemonte.it",
      "telefono": "+39 0171/735914",
      "fax": "+39 0171/735339",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.683333396911621
      }
    },
    {
      "codice": "004069",
      "nome": "Cigliè",
      "nomeStraniero": null,
      "codiceCatastale": "C681",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "cigle@ruparpiemonte.it",
      "pec": "ciglie@cert.ruparpiemonte.it",
      "telefono": "+39 0174/60132",
      "fax": "+39 0174/60510",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004070",
      "nome": "Cissone",
      "nomeStraniero": null,
      "codiceCatastale": "C738",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.cissone.cn.it",
      "pec": "cissone@cert.ruparpiemonte.it",
      "telefono": "+39 0173/748142",
      "fax": "+39 0173/748721",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004071",
      "nome": "Clavesana",
      "nomeStraniero": null,
      "codiceCatastale": "C792",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@clavesana.info",
      "pec": "postmaster@pec.clavesana.info",
      "telefono": "+39 0173/790103",
      "fax": "+39 0173/790389",
      "coordinate": {
        "lat": 44.48416519165039,
        "lng": 7.9116668701171875
      }
    },
    {
      "codice": "004072",
      "nome": "Corneliano d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "D022",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "corneliano.dalba@ruparpiemonte.it",
      "pec": "corneliano.dalba@cert.ruparpiemonte.it",
      "telefono": "+39 0173/619964",
      "fax": "+39 0173/619954",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "004073",
      "nome": "Cortemilia",
      "nomeStraniero": null,
      "codiceCatastale": "D062",
      "cap": "12074",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comunecortemilia.it",
      "pec": "comune.cortemilia.cn@cert.legalmail.it",
      "telefono": "+39 0173/81027",
      "fax": "+39 0173/81154",
      "coordinate": {
        "lat": 44.57500076293945,
        "lng": 8.191666603088379
      }
    },
    {
      "codice": "004074",
      "nome": "Cossano Belbo",
      "nomeStraniero": null,
      "codiceCatastale": "D093",
      "cap": "12054",
      "prefisso": "0141",
      "provincia": "Cuneo",
      "email": "info@comune.cossanobelbo.cn.it",
      "pec": "comune.cossanobelbo@pec.comune.cossanobelbo.cn.it",
      "telefono": "+39 0141/88125",
      "fax": "+39 0141/88540",
      "coordinate": {
        "lat": 44.66666793823242,
        "lng": 8.199999809265137
      }
    },
    {
      "codice": "004075",
      "nome": "Costigliole Saluzzo",
      "nomeStraniero": null,
      "codiceCatastale": "D120",
      "cap": "12024",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.costigliolesaluzzo.cn.it",
      "pec": "comune.costigliolesaluzzo.cn@certlegalmail.it",
      "telefono": "+39 0175/230121",
      "fax": "+39 0175/239003",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.483333110809326
      }
    },
    {
      "codice": "004076",
      "nome": "Cravanzana",
      "nomeStraniero": null,
      "codiceCatastale": "D133",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "cravanzana@ruparpiemonte.it",
      "pec": "comune.cravanzana.cn@cert.legalmail.it",
      "telefono": "+39 0173/855015",
      "fax": "+39 0173/855015",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "004077",
      "nome": "Crissolo",
      "nomeStraniero": null,
      "codiceCatastale": "D172",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "municipio@comune.crissolo.cn.it",
      "pec": "crissolo@cert.ruparpiemonte.it",
      "telefono": "+39 0175/94902",
      "fax": "+39 0175/94958",
      "coordinate": {
        "lat": 44.699684143066406,
        "lng": 7.155109882354736
      }
    },
    {
      "codice": "004078",
      "nome": "Cuneo",
      "nomeStraniero": null,
      "codiceCatastale": "D205",
      "cap": "12100",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "ufficio.protocollo@comune.cuneo.it",
      "pec": "protocollo.comune.cuneo@legalmail.it",
      "telefono": "+39 0171 4441",
      "fax": "+39 0171/444211",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "004079",
      "nome": "Demonte",
      "nomeStraniero": null,
      "codiceCatastale": "D271",
      "cap": "12014",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.demonte.@vallestura.cn.it",
      "pec": "demonte@cert.legalmail.it",
      "telefono": "+39 0171/65122",
      "fax": "+39 0171/95416",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.300000190734863
      }
    },
    {
      "codice": "004080",
      "nome": "Diano d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "D291",
      "cap": "12055",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune@diano.it",
      "pec": "comune.diano@legalmail.it",
      "telefono": "+39 0173/69101",
      "fax": "+39 0173/69312",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004081",
      "nome": "Dogliani",
      "nomeStraniero": null,
      "codiceCatastale": "D314",
      "cap": "12063",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@pec.comune.dogliani.cn.it",
      "pec": "anagrafe@pec.comune.dogliani.cn.it",
      "telefono": "+39 017370107",
      "fax": "+39 0173721405",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "004082",
      "nome": "Dronero",
      "nomeStraniero": null,
      "codiceCatastale": "D372",
      "cap": "12025",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.dronero.cn.it",
      "pec": "comunedronero@postecert.it",
      "telefono": "+39 0171/908700",
      "fax": "+39 0171/908709",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 7.366666793823242
      }
    },
    {
      "codice": "004083",
      "nome": "Elva",
      "nomeStraniero": null,
      "codiceCatastale": "D401",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comunedielva@libero.it",
      "pec": "elva@cert.ruparpiemonte.it",
      "telefono": "+39 0171/997993",
      "fax": "+39 0171/997971",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 7.083333492279053
      }
    },
    {
      "codice": "004084",
      "nome": "Entracque",
      "nomeStraniero": null,
      "codiceCatastale": "D410",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.entracque@tiscalinet.it",
      "pec": "comune.entracque.cn@legalmail.it",
      "telefono": "+39 0171/978108",
      "fax": "+39 0171/978637",
      "coordinate": {
        "lat": 44.242401123046875,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "004085",
      "nome": "Envie",
      "nomeStraniero": null,
      "codiceCatastale": "D412",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "protocollo@comune.envie.cn.it",
      "pec": "protocollo@pec.comune.envie.cn.it",
      "telefono": "+39 0175/278101",
      "fax": null,
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 7.366666793823242
      }
    },
    {
      "codice": "004086",
      "nome": "Farigliano",
      "nomeStraniero": null,
      "codiceCatastale": "D499",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@comune.farigliano.cn.gov.it",
      "pec": "protocollo@pec.comune.farigliano.cn.gov.it",
      "telefono": "+39 0173/76109",
      "fax": "+39 0173/76733",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004087",
      "nome": "Faule",
      "nomeStraniero": null,
      "codiceCatastale": "D511",
      "cap": "12030",
      "prefisso": "011",
      "provincia": "Cuneo",
      "email": "faule@ruparpiemonte.it",
      "pec": "faule@cert.ruparpiemonte.it",
      "telefono": "+39 011/974113",
      "fax": "+39 011/9748956",
      "coordinate": {
        "lat": 44.80624008178711,
        "lng": 7.585124969482422
      }
    },
    {
      "codice": "004088",
      "nome": "Feisoglio",
      "nomeStraniero": null,
      "codiceCatastale": "D523",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "feisoglio@ruparpiemonte.it",
      "pec": "comune.feisoglio.cn@cert.legalmail.it",
      "telefono": "+39 0173/831114",
      "fax": "+39 0173/831900",
      "coordinate": {
        "lat": 44.54999923706055,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "004089",
      "nome": "Fossano",
      "nomeStraniero": null,
      "codiceCatastale": "D742",
      "cap": "12045",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "demografia@comune.fossano.cn.it",
      "pec": "fossano@cert.ruparpiemonte.it",
      "telefono": "+39 0172/699627",
      "fax": "+39 0172/699673",
      "coordinate": {
        "lat": 44.54999923706055,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "004090",
      "nome": "Frabosa Soprana",
      "nomeStraniero": null,
      "codiceCatastale": "D751",
      "cap": "12082",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "frabosa.soprana@ruparpiemonte.it",
      "pec": "frabosa.soprana@cert.ruparpiemonte.it",
      "telefono": "+39 0174/244024",
      "fax": "+39 0174/244163",
      "coordinate": {
        "lat": 44.2872314453125,
        "lng": 7.80696964263916
      }
    },
    {
      "codice": "004091",
      "nome": "Frabosa Sottana",
      "nomeStraniero": null,
      "codiceCatastale": "D752",
      "cap": "12083",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "comune@comune.frabosa-sottana.cn.it",
      "pec": "frabosa.sottana@cert.ruparpiemonte.it",
      "telefono": "+39 0174/244481",
      "fax": "+39 0174/4244730",
      "coordinate": {
        "lat": 44.29999923706055,
        "lng": 7.800000190734863
      }
    },
    {
      "codice": "004092",
      "nome": "Frassino",
      "nomeStraniero": null,
      "codiceCatastale": "D782",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "anagrafe@comunefrassino.cn.it",
      "pec": "frassino@cert.ruparpiemonte.it",
      "telefono": "+39 0175/976926",
      "fax": "+39 0175/976910",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.2833333015441895
      }
    },
    {
      "codice": "004093",
      "nome": "Gaiola",
      "nomeStraniero": null,
      "codiceCatastale": "D856",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "gaiola@vallestura.cn.it",
      "pec": "gaiola@cert.ruparpiemonte.it",
      "telefono": "+39 0171/72922",
      "fax": "+39 0171/74252",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "004094",
      "nome": "Gambasca",
      "nomeStraniero": null,
      "codiceCatastale": "D894",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "gambasca@reteunitaria.piemonte.it",
      "pec": "gambasca@cert.ruparpiemonte.it",
      "telefono": "+39 0175/265121",
      "fax": "+39 0175/265956",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 7.349999904632568
      }
    },
    {
      "codice": "004095",
      "nome": "Garessio",
      "nomeStraniero": null,
      "codiceCatastale": "D920",
      "cap": "12075",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "segreteria@comune.garessio.cn.it",
      "pec": "garessio@cert.ruparpiemonte.it",
      "telefono": "+39 0174/805611",
      "fax": "+39 0174/805623",
      "coordinate": {
        "lat": 44.20000076293945,
        "lng": 8.016666412353516
      }
    },
    {
      "codice": "004096",
      "nome": "Genola",
      "nomeStraniero": null,
      "codiceCatastale": "D967",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.genola.cn.it",
      "pec": "genola@cert.ruparpiemonte.it",
      "telefono": "+39 0172/68144",
      "fax": "+39 0172/68858",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.666666507720947
      }
    },
    {
      "codice": "004097",
      "nome": "Gorzegno",
      "nomeStraniero": null,
      "codiceCatastale": "E111",
      "cap": "12070",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "gorzegno@reteunitaria",
      "pec": "gorzegno@cert.ruparpiemonte.it",
      "telefono": "+39 0173/86012",
      "fax": "+39 0173/826907",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "004098",
      "nome": "Gottasecca",
      "nomeStraniero": null,
      "codiceCatastale": "E115",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "gottasecca@reteunitaria.piemonte.it",
      "pec": "gottasecca@cert.ruparpiemonte.it",
      "telefono": "+39 0174/906500",
      "fax": "+39 0174/906942",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 8.166666984558105
      }
    },
    {
      "codice": "004099",
      "nome": "Govone",
      "nomeStraniero": null,
      "codiceCatastale": "E118",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.govone.cn.it",
      "pec": "info@pec.comune.govone.cn.it",
      "telefono": "+39 0173/58103",
      "fax": "+39 0173/58558",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "004100",
      "nome": "Grinzane Cavour",
      "nomeStraniero": null,
      "codiceCatastale": "E182",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "segreteria.grinzane.cavour@ruparpiemonte.it",
      "pec": "comune.grinzanecavour.cn@legalmail.it",
      "telefono": "+39 0173/262016",
      "fax": "+39 0173/262598",
      "coordinate": {
        "lat": 44.66666793823242,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "004101",
      "nome": "Guarene",
      "nomeStraniero": null,
      "codiceCatastale": "E251",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "segreteria@guarene.it",
      "pec": "guarene@cert.legalmail.it",
      "telefono": "+39 0173/611103",
      "fax": "+39 0173/611127",
      "coordinate": {
        "lat": 44.73948669433594,
        "lng": 8.034835815429688
      }
    },
    {
      "codice": "004102",
      "nome": "Igliano",
      "nomeStraniero": null,
      "codiceCatastale": "E282",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "igliano@interfree.it",
      "pec": "comune.igliano.cn@legalmail.it",
      "telefono": "+39 0174/785147",
      "fax": "+39 0174/785147",
      "coordinate": {
        "lat": 44.443599700927734,
        "lng": 8.012499809265137
      }
    },
    {
      "codice": "004103",
      "nome": "Isasca",
      "nomeStraniero": null,
      "codiceCatastale": "E327",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "isasca@ruparpiemonte.it",
      "pec": "isasca@postemailcertificata.it",
      "telefono": "+39 0175/567240",
      "fax": "+39 0175/567240",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "004105",
      "nome": "La Morra",
      "nomeStraniero": null,
      "codiceCatastale": "E430",
      "cap": "12064",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@comune.lamorra.cn.it",
      "pec": "comune.lamorra@legalmail.it",
      "telefono": "+39 0173/50105",
      "fax": "+39 0173/509389",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004104",
      "nome": "Lagnasco",
      "nomeStraniero": null,
      "codiceCatastale": "E406",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "segreteria@comune.lagnasco.cn.it",
      "pec": "segreteria@pec.comune.lagnasco.cn.it",
      "telefono": "+39 0175/72101",
      "fax": "+39 0175/72630",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "004106",
      "nome": "Lequio Berria",
      "nomeStraniero": null,
      "codiceCatastale": "E540",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "lequio.berria@reteunitaria.piemonte.it",
      "pec": "lequio.berria@cert.ruparpiemonte.it",
      "telefono": "+39 0173/52402",
      "fax": "+39 0173/52586",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "004107",
      "nome": "Lequio Tanaro",
      "nomeStraniero": null,
      "codiceCatastale": "E539",
      "cap": "12060",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "lequio.tanaro@ruparpiemonte.it",
      "pec": "comune.lequiotanaro.cn@legalmail.it",
      "telefono": "+39 0172/696136",
      "fax": "+39 0172/696914",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "004108",
      "nome": "Lesegno",
      "nomeStraniero": null,
      "codiceCatastale": "E546",
      "cap": "12076",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "info@comune.lesegno.cn.it",
      "pec": "comune.lesegno.cn@legalmail.it",
      "telefono": "+39 0174/77034",
      "fax": "+39 0174/77368",
      "coordinate": {
        "lat": 44.40305709838867,
        "lng": 7.9716668128967285
      }
    },
    {
      "codice": "004109",
      "nome": "Levice",
      "nomeStraniero": null,
      "codiceCatastale": "E564",
      "cap": "12070",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "levice@reteunitaria.piemonte.it",
      "pec": "levice@cert.ruparpiemonte.it",
      "telefono": "+39 0173/833113",
      "fax": "+39 0173/833907",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 8.149999618530273
      }
    },
    {
      "codice": "004110",
      "nome": "Limone Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "E597",
      "cap": "12015",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "segretario@comune.limonepiemonte.it",
      "pec": "comune.limonepiemonte.cn@legalmail.it",
      "telefono": "+39 0171 925211",
      "fax": "+39 0171 925249",
      "coordinate": {
        "lat": 44.20000076293945,
        "lng": 7.566666603088379
      }
    },
    {
      "codice": "004111",
      "nome": "Lisio",
      "nomeStraniero": null,
      "codiceCatastale": "E615",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "lisio@ruparpiemonte.it",
      "pec": "lisio@cert.ruparpiemonte.it",
      "telefono": "+39 0174/73152",
      "fax": "+39 0174/73152",
      "coordinate": {
        "lat": 44.29999923706055,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "004112",
      "nome": "Macra",
      "nomeStraniero": null,
      "codiceCatastale": "E789",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "macra@ruparpiemonte.it",
      "pec": "macra@cert.ruparpiemonte.it",
      "telefono": "+39 0171/999161",
      "fax": "+39 0171/999161",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 7.150000095367432
      }
    },
    {
      "codice": "004113",
      "nome": "Magliano Alfieri",
      "nomeStraniero": null,
      "codiceCatastale": "E809",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune.maglianoalfieri@gmail.com",
      "pec": "comune.maglianoalfieri@pec.it",
      "telefono": "+39 0173/66117",
      "fax": "+39 0173/66574",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "004114",
      "nome": "Magliano Alpi",
      "nomeStraniero": null,
      "codiceCatastale": "E808",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "protocollo@comune.maglianoalpi.cn.it",
      "pec": "comune.maglianoalpi@legalmail.it",
      "telefono": "+39 0174/66121",
      "fax": null,
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 7.800000190734863
      }
    },
    {
      "codice": "004115",
      "nome": "Mango",
      "nomeStraniero": null,
      "codiceCatastale": "E887",
      "cap": "12056",
      "prefisso": "0141",
      "provincia": "Cuneo",
      "email": "mango@ruparpiemonte.it",
      "pec": "mango@cert.ruparpiemonte.it",
      "telefono": "+39 0141/89127",
      "fax": "+39 0141/839809",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 8.149999618530273
      }
    },
    {
      "codice": "004116",
      "nome": "Manta",
      "nomeStraniero": null,
      "codiceCatastale": "E894",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "protocollo@comunemanta.it",
      "pec": "protocollo@pec.comune.manta.cn.it",
      "telefono": "+39 0175/750456",
      "fax": "+39 0175/87652",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 7.483333110809326
      }
    },
    {
      "codice": "004117",
      "nome": "Marene",
      "nomeStraniero": null,
      "codiceCatastale": "E939",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.marene.cn.it",
      "pec": "marene@cert.ruparpiemonte.it",
      "telefono": "+39 0172/742029",
      "fax": "+39 0172742455",
      "coordinate": {
        "lat": 44.66666793823242,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "004118",
      "nome": "Margarita",
      "nomeStraniero": null,
      "codiceCatastale": "E945",
      "cap": "12040",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "margarita@ruparpiemonte.it",
      "pec": "margarita@cert.ruparpiemonte.it",
      "telefono": "+39 0171/792014",
      "fax": "+39 0171/792254",
      "coordinate": {
        "lat": 44.400001525878906,
        "lng": 7.683333396911621
      }
    },
    {
      "codice": "004119",
      "nome": "Marmora",
      "nomeStraniero": null,
      "codiceCatastale": "E963",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "marmora@ruparpiemonte.it",
      "pec": "marmora@cert.ruparpiemonte.it",
      "telefono": "+39 0171/998113",
      "fax": "+39 0171/998113",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 7.099999904632568
      }
    },
    {
      "codice": "004120",
      "nome": "Marsaglia",
      "nomeStraniero": null,
      "codiceCatastale": "E973",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "masaglia@infosys.it",
      "pec": "marsaglia@cert.ruparpiemonte.it",
      "telefono": "+39 0174/787112",
      "fax": "+39 0174/787900",
      "coordinate": {
        "lat": 44.453857421875,
        "lng": 7.981246471405029
      }
    },
    {
      "codice": "004121",
      "nome": "Martiniana Po",
      "nomeStraniero": null,
      "codiceCatastale": "E988",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "martiniana.po@reteunitaria.piemonte.it",
      "pec": "martiniana.po@cert.ruparpiemonte.it",
      "telefono": "+39 0175/265102",
      "fax": "+39 0175/265494",
      "coordinate": {
        "lat": 44.62666702270508,
        "lng": 7.36638879776001
      }
    },
    {
      "codice": "004122",
      "nome": "Melle",
      "nomeStraniero": null,
      "codiceCatastale": "F114",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "uffici@comune.melle.cn.it",
      "pec": "melle@cert.ruparpiemonte.it",
      "telefono": "+39 0175/978031",
      "fax": "+39 0175/978111",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "004123",
      "nome": "Moiola",
      "nomeStraniero": null,
      "codiceCatastale": "F279",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "moiola@vallestura.cn.it",
      "pec": "moiola@cert.ruparpiemonte.it",
      "telefono": "+39 0171/717012",
      "fax": "+39 0171/717914",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "004124",
      "nome": "Mombarcaro",
      "nomeStraniero": null,
      "codiceCatastale": "F309",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "mombarcaro@tiscali.it",
      "pec": "comune.mombarcaro.cn@cert.legalmail.it",
      "telefono": "+39 017497133",
      "fax": "+39 0174907900",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "004125",
      "nome": "Mombasiglio",
      "nomeStraniero": null,
      "codiceCatastale": "F312",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "mombasiglio@ruparpiemonte.it",
      "pec": "mombasiglio@cert.ruparpiemonte.it",
      "telefono": "+39 0174/780015",
      "fax": "+39 0174/780219",
      "coordinate": {
        "lat": 44.36666488647461,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "004126",
      "nome": "Monastero di Vasco",
      "nomeStraniero": null,
      "codiceCatastale": "F326",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "monastero.di.vasco@ruparpiemonte.it",
      "pec": "monastero.di.vasco@cert.ruparpiemonte.it",
      "telefono": "+39 0174/689104",
      "fax": "+39 0174/689546",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "004127",
      "nome": "Monasterolo Casotto",
      "nomeStraniero": null,
      "codiceCatastale": "F329",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "monasterolo.casotto@ruparpiemonte.it",
      "pec": "monasterolo.casotto@cert.ruparpiemonte.it",
      "telefono": "+39 0174/329125",
      "fax": null,
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004128",
      "nome": "Monasterolo di Savigliano",
      "nomeStraniero": null,
      "codiceCatastale": "F330",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comunemonasterolodisavigliano.cn.it",
      "pec": "monasterolodisavigliano@postemailcertificata.it",
      "telefono": "+39 0172/373026",
      "fax": "+39 0172/373174",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "004129",
      "nome": "Monchiero",
      "nomeStraniero": null,
      "codiceCatastale": "F338",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@comune.monchiero.cn.it",
      "pec": "monchiero@pec.langabarolo.it",
      "telefono": "+39 0173/792105",
      "fax": "+39 0173/792422",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004130",
      "nome": "Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "F351",
      "cap": "12084",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "info@comune.mondovi.cn.it",
      "pec": "comune.mondovi@postecert.it",
      "telefono": "+39 0174/559211",
      "fax": "+39 0174/559305",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "004131",
      "nome": "Monesiglio",
      "nomeStraniero": null,
      "codiceCatastale": "F355",
      "cap": "12077",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "monesiglio@reteunitaria.piemonte.it",
      "pec": "comune.monesiglio.cn@cert.legalmail.it",
      "telefono": "+39 0174/92108",
      "fax": "+39 0174/92308",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "004132",
      "nome": "Monforte d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "F358",
      "cap": "12065",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@comune.monforte.cn.it",
      "pec": "monfortedalba@pec.langabarolo.it",
      "telefono": "+39 0173/78202",
      "fax": "+39 0173/78282",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "004133",
      "nome": "Montà",
      "nomeStraniero": null,
      "codiceCatastale": "F385",
      "cap": "12046",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "evisca@comune.monta.cn.it",
      "pec": "comune.monta@pec.comune.monta.cn.it",
      "telefono": "+39 0173/977423",
      "fax": "+39 0173/977429",
      "coordinate": {
        "lat": 44.81473922729492,
        "lng": 7.954770088195801
      }
    },
    {
      "codice": "004134",
      "nome": "Montaldo di Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "F405",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.montaldodimondovi.cn.it",
      "pec": "comune.montaldodimondovi.cn@legalmail.it",
      "telefono": "+39 0174 323105",
      "fax": "+39 0174 323275",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.866666793823242
      }
    },
    {
      "codice": "004135",
      "nome": "Montaldo Roero",
      "nomeStraniero": null,
      "codiceCatastale": "F408",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "montaldo.roero@ruparpiemonte.it",
      "pec": "montaldo.roero@cert.ruparpiemonte.it",
      "telefono": "+39 0172/40053",
      "fax": "+39 0172/40013",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004136",
      "nome": "Montanera",
      "nomeStraniero": null,
      "codiceCatastale": "F424",
      "cap": "12040",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "montanera@ruparpiemonte.it",
      "pec": "montanera@cert.ruparpiemonte.it",
      "telefono": "+39 0171/798280",
      "fax": "+39 0171/798104",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 7.666666507720947
      }
    },
    {
      "codice": "004137",
      "nome": "Montelupo Albese",
      "nomeStraniero": null,
      "codiceCatastale": "F550",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "montelupo.albese@ruparpiemonte.it",
      "pec": "montelupoalbese@pec.langabarolo.it",
      "telefono": "+39 0173/617117",
      "fax": "+39 0173/617514",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "004138",
      "nome": "Montemale di Cuneo",
      "nomeStraniero": null,
      "codiceCatastale": "F558",
      "cap": "12025",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.montemale@tiscali.it",
      "pec": "montemale.di.cuneo@cert.ruparpiemonte.it",
      "telefono": "+39 0171/917654",
      "fax": "+39 0171/ 909679",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "004139",
      "nome": "Monterosso Grana",
      "nomeStraniero": null,
      "codiceCatastale": "F608",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "monterosso.grana@ruparpiemonte.it",
      "pec": "monterosso.grana@cert.ruparpiemonte.it",
      "telefono": "+39 0171/98713",
      "fax": "+39 0171/988900",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "004140",
      "nome": "Monteu Roero",
      "nomeStraniero": null,
      "codiceCatastale": "F654",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "anagrafe@comunemonteuroero.cn.it",
      "pec": "monteu.roero@cert.ruparpiemonte.it",
      "telefono": "+39 0173/90131",
      "fax": "+39 0173/960914",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004141",
      "nome": "Montezemolo",
      "nomeStraniero": null,
      "codiceCatastale": "F666",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "com.montezemolo@vallinrete.org",
      "pec": "comune.montezemolo.cn@legalmail.it",
      "telefono": "+39 0174/781306",
      "fax": "+39 0174/781307",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "004142",
      "nome": "Monticello d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "F669",
      "cap": "12066",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.monticellodalba.cn.it",
      "pec": "comune.monticellodalba.cn.it@pec.it",
      "telefono": "+39 0173/64113",
      "fax": "+39 0173/64449",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "004143",
      "nome": "Moretta",
      "nomeStraniero": null,
      "codiceCatastale": "F723",
      "cap": "12033",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.moretta.cn.it",
      "pec": "comune.moretta@businesspec.it",
      "telefono": "+39 0172/911035",
      "fax": "+39 0172/94907",
      "coordinate": {
        "lat": 44.76137161254883,
        "lng": 7.536320209503174
      }
    },
    {
      "codice": "004144",
      "nome": "Morozzo",
      "nomeStraniero": null,
      "codiceCatastale": "F743",
      "cap": "12040",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "protocollo@comune.morozzo.cn.it",
      "pec": "comune.morozzo@multipec.it",
      "telefono": "+39 0171/772001",
      "fax": "+39 0171/772477",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.7166666984558105
      }
    },
    {
      "codice": "004145",
      "nome": "Murazzano",
      "nomeStraniero": null,
      "codiceCatastale": "F809",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune@comune.murazzano.cn.it",
      "pec": "comune.murazzano.cn@cert.legalmail.it",
      "telefono": "+39 0173/791201",
      "fax": "+39 0173/791623",
      "coordinate": {
        "lat": 44.473331451416016,
        "lng": 8.019444465637207
      }
    },
    {
      "codice": "004146",
      "nome": "Murello",
      "nomeStraniero": null,
      "codiceCatastale": "F811",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "murello@ruparpiemonte.it",
      "pec": "protocollo@pec.comune.murello.cn.it",
      "telefono": "+39 0172/98102",
      "fax": "+39 0172/98124",
      "coordinate": {
        "lat": 44.753055572509766,
        "lng": 7.601944446563721
      }
    },
    {
      "codice": "004147",
      "nome": "Narzole",
      "nomeStraniero": null,
      "codiceCatastale": "F846",
      "cap": "12068",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune.segretaeria@narzole.net",
      "pec": "narzole@cert.ruparpiemonte.it",
      "telefono": "+39 0173/77003",
      "fax": "+39 0173/776255",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 7.866666793823242
      }
    },
    {
      "codice": "004148",
      "nome": "Neive",
      "nomeStraniero": null,
      "codiceCatastale": "F863",
      "cap": "12052",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "neive@comune.neive.cn.it",
      "pec": "neive@pec.comune.neive.cn.it",
      "telefono": "+39 0173/67110",
      "fax": "+39 0173/677781",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "004149",
      "nome": "Neviglie",
      "nomeStraniero": null,
      "codiceCatastale": "F883",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comunedineviglie@libero.it",
      "pec": "comune.neviglie.cn@legalmail.it",
      "telefono": "+39 0173/630181",
      "fax": "+39 0173/630237",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "004150",
      "nome": "Niella Belbo",
      "nomeStraniero": null,
      "codiceCatastale": "F894",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "niella.belbo@reteunitaria.piemonte.it",
      "pec": "comune.niellabelbo.cn@cert.legalmail.it",
      "telefono": "+39 0173/796117",
      "fax": "+39 0173/796117",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "004151",
      "nome": "Niella Tanaro",
      "nomeStraniero": null,
      "codiceCatastale": "F895",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "niella.tanaro@provincia.cuneo.it",
      "pec": "comune.niellatanaro@legalmail.it",
      "telefono": "+39 0174/226102",
      "fax": "+39 0174/241807",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004152",
      "nome": "Novello",
      "nomeStraniero": null,
      "codiceCatastale": "F961",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "protocollo@comune.novello.cn.it",
      "pec": "novello@pec.langabarolo.it",
      "telefono": "+39 0173/731147",
      "fax": "+39 0173/731441",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004153",
      "nome": "Nucetto",
      "nomeStraniero": null,
      "codiceCatastale": "F972",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "nucetto@ruparpiemonte.it",
      "pec": "nucetto@cert.ruparpiemonte.it",
      "telefono": "+39 0174/74112",
      "fax": "+39 0174/74092",
      "coordinate": {
        "lat": 44.34130096435547,
        "lng": 8.061388969421387
      }
    },
    {
      "codice": "004154",
      "nome": "Oncino",
      "nomeStraniero": null,
      "codiceCatastale": "G066",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "oncino@vallipo.cn.it",
      "pec": "comune.oncino@pec.it",
      "telefono": "+39 0175/946116",
      "fax": "+39 0175/946116",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 7.183333396911621
      }
    },
    {
      "codice": "004155",
      "nome": "Ormea",
      "nomeStraniero": null,
      "codiceCatastale": "G114",
      "cap": "12078",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "procollo@comune.ormea.cn.it",
      "pec": "protocollo.ormea@cert.ruparpiemonte.it",
      "telefono": "+39 0174/391101",
      "fax": "+39 0174/392234",
      "coordinate": {
        "lat": 44.150001525878906,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004156",
      "nome": "Ostana",
      "nomeStraniero": null,
      "codiceCatastale": "G183",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "ostana@vallipo.cn.it",
      "pec": "ostana@cert.ruparpiemonte.it",
      "telefono": "+39 0175/94915",
      "fax": "+39 0175/94915",
      "coordinate": {
        "lat": 44.69259262084961,
        "lng": 7.187224388122559
      }
    },
    {
      "codice": "004157",
      "nome": "Paesana",
      "nomeStraniero": null,
      "codiceCatastale": "G228",
      "cap": "12034",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "servizidemografici.comune@paesana.it",
      "pec": "serviziamministrativi.paesana@legalmail.it",
      "telefono": "+39 0175/94105",
      "fax": "+39 0175/987206",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 7.2833333015441895
      }
    },
    {
      "codice": "004158",
      "nome": "Pagno",
      "nomeStraniero": null,
      "codiceCatastale": "G240",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.pagno.cn.it",
      "pec": "certificata@pec.comune.pagno.cn.it",
      "telefono": "+39 0175/76101",
      "fax": "+39 0175/76101",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 7.433333396911621
      }
    },
    {
      "codice": "004159",
      "nome": "Pamparato",
      "nomeStraniero": null,
      "codiceCatastale": "G302",
      "cap": "12087",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "pamparato@ruparpiemonte.it",
      "pec": "pamparato@cert.ruparpiemonte.it",
      "telefono": "+39 0174/351113",
      "fax": "+39 0174/351532",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 7.916666507720947
      }
    },
    {
      "codice": "004160",
      "nome": "Paroldo",
      "nomeStraniero": null,
      "codiceCatastale": "G339",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "paroldo@reteunitaria.piemonte.it",
      "pec": "paroldo@cert.ruparpiemonte.it",
      "telefono": "+39 0174/789040",
      "fax": "+39 0174/789914",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "004161",
      "nome": "Perletto",
      "nomeStraniero": null,
      "codiceCatastale": "G457",
      "cap": "12070",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "perletto@ruparpiemonte.it",
      "pec": "perletto@cert.ruparpiemonte.it",
      "telefono": "+39 0173/832122",
      "fax": "+39 0173/832122",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 8.216666221618652
      }
    },
    {
      "codice": "004162",
      "nome": "Perlo",
      "nomeStraniero": null,
      "codiceCatastale": "G458",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "perlo@ruparpiemonte.it",
      "pec": "perlo@cert.ruparpiemonte.it",
      "telefono": "+39 0174/74150",
      "fax": "+39 0174/74150",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "004163",
      "nome": "Peveragno",
      "nomeStraniero": null,
      "codiceCatastale": "G526",
      "cap": "12016",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.peveragno.cn.it",
      "pec": "comune.peveragno@legalmail.it",
      "telefono": "+39 0171/337716",
      "fax": "+39 0171/339360",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "004164",
      "nome": "Pezzolo Valle Uzzone",
      "nomeStraniero": null,
      "codiceCatastale": "G532",
      "cap": "12070",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.pezzolovalleuzzone.cn.it",
      "pec": "comune.pezzolo.cn@legalmail.it",
      "telefono": "+39 0173/87025",
      "fax": "+39 0173/827956",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 8.199999809265137
      }
    },
    {
      "codice": "004165",
      "nome": "Pianfei",
      "nomeStraniero": null,
      "codiceCatastale": "G561",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "demografico@comune.pianfei.cn.it",
      "pec": "pianfei@cert.ruparpiemonte.it",
      "telefono": "+39 0174/585128",
      "fax": "+39 0174/585732",
      "coordinate": {
        "lat": 44.37277603149414,
        "lng": 7.712500095367432
      }
    },
    {
      "codice": "004166",
      "nome": "Piasco",
      "nomeStraniero": null,
      "codiceCatastale": "G575",
      "cap": "12026",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "ufficioanagrafe@comune.piasco.cn.it",
      "pec": "piasco@cert.ruparpiemonte.it",
      "telefono": "+39 0175/79124",
      "fax": "+39 0175/79276",
      "coordinate": {
        "lat": 44.56388854980469,
        "lng": 7.4547224044799805
      }
    },
    {
      "codice": "004167",
      "nome": "Pietraporzio",
      "nomeStraniero": null,
      "codiceCatastale": "G625",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "pietraporzio@vallestura.cn.it",
      "pec": "pietraporzio@cert.legalmail.it",
      "telefono": "+39 0171/96631",
      "fax": "+39 0171/964928",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 7.0333333015441895
      }
    },
    {
      "codice": "004168",
      "nome": "Piobesi d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "G683",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "piobesi.dalaba@ruparpiemonte.it",
      "pec": "piobesi.dalba@cert.ruparpiemonte.it",
      "telefono": "+39 0173/619448",
      "fax": "+39 0173/610129",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "004169",
      "nome": "Piozzo",
      "nomeStraniero": null,
      "codiceCatastale": "G697",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "piozzo@reteunitaria.piemonte.it",
      "pec": "piozzo@cert.ruparpiemonte.it",
      "telefono": "+39 0173/795101",
      "fax": "+39 0173/795518",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 7.900000095367432
      }
    },
    {
      "codice": "004170",
      "nome": "Pocapaglia",
      "nomeStraniero": null,
      "codiceCatastale": "G742",
      "cap": "12060",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.pocapaglia.cn.it",
      "pec": "comune.pocapaglia.cn@legalmail.it",
      "telefono": "+39 0172/493131",
      "fax": "+39 0172/493830",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "004171",
      "nome": "Polonghera",
      "nomeStraniero": null,
      "codiceCatastale": "G800",
      "cap": "12030",
      "prefisso": "011",
      "provincia": "Cuneo",
      "email": "polonghera@ruparpiemonte.it",
      "pec": "polonghera@cert.ruparpiemonte.it",
      "telefono": "+39 011/974104",
      "fax": "+39 011/974348",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 7.599999904632568
      }
    },
    {
      "codice": "004172",
      "nome": "Pontechianale",
      "nomeStraniero": null,
      "codiceCatastale": "G837",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "info@comune.pontechianale.cn.it",
      "pec": "pontechianale@cert.ruparpiemonte.it",
      "telefono": "+39 0175/950174",
      "fax": "+39 0175/950340",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 7.0333333015441895
      }
    },
    {
      "codice": "004173",
      "nome": "Pradleves",
      "nomeStraniero": null,
      "codiceCatastale": "G970",
      "cap": "12027",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.pradleves.cn.it",
      "pec": "pradleves@cert.ruparpiemonte.it",
      "telefono": "+39 0171/986122",
      "fax": "+39 0171/986900",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.2833333015441895
      }
    },
    {
      "codice": "004174",
      "nome": "Prazzo",
      "nomeStraniero": null,
      "codiceCatastale": "H011",
      "cap": "12028",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.prazzo.cn.it",
      "pec": "prazzo@postemailcertificata.it",
      "telefono": "+39 0171/99123",
      "fax": "+39 017/999900",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 7.050000190734863
      }
    },
    {
      "codice": "004175",
      "nome": "Priero",
      "nomeStraniero": null,
      "codiceCatastale": "H059",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "com.priero@vallinrete.org",
      "pec": "priero@cert.ruparpiemonte.it",
      "telefono": "+39 0174/79024",
      "fax": "+39 0174/79140",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "004176",
      "nome": "Priocca",
      "nomeStraniero": null,
      "codiceCatastale": "H068",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "anagrafe.priocca@ruparpiemonte.it",
      "pec": "priocca@cert.ruparpiemonte.it",
      "telefono": "+39 0173/616122",
      "fax": "+39 0173/616767",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "004177",
      "nome": "Priola",
      "nomeStraniero": null,
      "codiceCatastale": "H069",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "priolac@libero.it",
      "pec": "priola@cert.ruparpiemonte.it",
      "telefono": "+39 0174/88018",
      "fax": "+39 0174/88356",
      "coordinate": {
        "lat": 44.244781494140625,
        "lng": 8.021490097045898
      }
    },
    {
      "codice": "004178",
      "nome": "Prunetto",
      "nomeStraniero": null,
      "codiceCatastale": "H085",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "prunetto@ruparpiemonte.it",
      "pec": "prunetto@cert.ruparpiemonte.it",
      "telefono": "+39 0174  99113",
      "fax": "+39 0174  99051",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 8.149999618530273
      }
    },
    {
      "codice": "004179",
      "nome": "Racconigi",
      "nomeStraniero": null,
      "codiceCatastale": "H150",
      "cap": "12035",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "ufficio.protocollo@comune.racconigi.cn.it",
      "pec": "comune.racconigi@cert.ruparpiemonte.it",
      "telefono": "+39 0172/821611",
      "fax": "+39 0172/821620",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 7.683333396911621
      }
    },
    {
      "codice": "004180",
      "nome": "Revello",
      "nomeStraniero": null,
      "codiceCatastale": "H247",
      "cap": "12036",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "info@comune.revello.cn.it",
      "pec": "revello@cert.ruparpiemonte.it",
      "telefono": "+39 0175/257171",
      "fax": "+39 0175/759477",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "004181",
      "nome": "Rifreddo",
      "nomeStraniero": null,
      "codiceCatastale": "H285",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "info@comune.rifreddo.cn.it",
      "pec": "rifreddo@cert.ruparpiemonte.it",
      "telefono": "+39 0175/260022",
      "fax": "+39 0175/260331",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 7.349999904632568
      }
    },
    {
      "codice": "004182",
      "nome": "Rittana",
      "nomeStraniero": null,
      "codiceCatastale": "H326",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "rittana@vallestura.cn.it",
      "pec": "rittana@cert.legalmail.it",
      "telefono": "+39 0171/72991",
      "fax": "+39 0171/72991",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "004183",
      "nome": "Roaschia",
      "nomeStraniero": null,
      "codiceCatastale": "H362",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "roaschia@ruparpiemonte.it",
      "pec": "comune.roaschia.cn@cert.legalmail.it",
      "telefono": "+39 0171/758119",
      "fax": "+39 0171/758188",
      "coordinate": {
        "lat": 44.266666412353516,
        "lng": 7.449999809265137
      }
    },
    {
      "codice": "004184",
      "nome": "Roascio",
      "nomeStraniero": null,
      "codiceCatastale": "H363",
      "cap": "12073",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "roascio@intrfree.it",
      "pec": "roascio@cert.ruparpiemonte.it",
      "telefono": "+39 0174/789081",
      "fax": "+39 0174/789081",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004185",
      "nome": "Robilante",
      "nomeStraniero": null,
      "codiceCatastale": "H377",
      "cap": "12017",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comunerobilante.it",
      "pec": "comune.robilante@cert.legalmail.it",
      "telefono": "+39 0171/78101",
      "fax": "+39 0171/789103",
      "coordinate": {
        "lat": 44.29999923706055,
        "lng": 7.516666889190674
      }
    },
    {
      "codice": "004186",
      "nome": "Roburent",
      "nomeStraniero": null,
      "codiceCatastale": "H378",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "daniela.caramello@comune.roburent.cn.it",
      "pec": "daniela.caramello@pec.comune.roburent.cn.it",
      "telefono": "+39 0174/228105",
      "fax": "+39 0174/228253",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "004188",
      "nome": "Rocca Cigliè",
      "nomeStraniero": null,
      "codiceCatastale": "H391",
      "cap": "12060",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "rocca.ciglie@ruparpiemonte.it",
      "pec": "rocca.ciglie@cert.ruparpiemonte.it",
      "telefono": "+39 0174/785219",
      "fax": "+39 0174/785135",
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "004189",
      "nome": "Rocca de' Baldi",
      "nomeStraniero": null,
      "codiceCatastale": "H395",
      "cap": "12047",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "rocca.de.baldi@ruparpiemonte.it",
      "pec": "rocca.de.baldi@cert.ruparpiemonte.it",
      "telefono": "+390174587103",
      "fax": null,
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 7.75
      }
    },
    {
      "codice": "004187",
      "nome": "Roccabruna",
      "nomeStraniero": null,
      "codiceCatastale": "H385",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.roccabruna.cn.it",
      "pec": "roccabruna@postemailcertificata.it",
      "telefono": "+39 0171/917201",
      "fax": "+39 0171/905278",
      "coordinate": {
        "lat": 44.5,
        "lng": 7.333333492279053
      }
    },
    {
      "codice": "004190",
      "nome": "Roccaforte Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "H407",
      "cap": "12088",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "roccaforte.mondovi@ruparpiemonte.it",
      "pec": "roccaforte.mondovi@cert.ruparpiemonte.it",
      "telefono": "+39 0174/65139",
      "fax": "+39 0174/65671",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.75
      }
    },
    {
      "codice": "004191",
      "nome": "Roccasparvera",
      "nomeStraniero": null,
      "codiceCatastale": "H447",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.roccasparvera@libero.it",
      "pec": "roccasparvera@cert.ruparpiemonte.it",
      "telefono": "+39 0171/72933",
      "fax": "+39 0171/717013",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.449999809265137
      }
    },
    {
      "codice": "004192",
      "nome": "Roccavione",
      "nomeStraniero": null,
      "codiceCatastale": "H453",
      "cap": "12018",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "protocollo@comune.roccavione.cn.it",
      "pec": "comune.roccavione.cn@legalmail.it",
      "telefono": "+39 0171/767108",
      "fax": "+39 0171/757857",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 7.483333110809326
      }
    },
    {
      "codice": "004193",
      "nome": "Rocchetta Belbo",
      "nomeStraniero": null,
      "codiceCatastale": "H462",
      "cap": "12050",
      "prefisso": "0141",
      "provincia": "Cuneo",
      "email": "rocchetta.belbo@reteunitaria.piemonte.it",
      "pec": "comune.rocchettabelbo.cn@cert.legalmail.it",
      "telefono": "+39 0141/880114",
      "fax": "+39 0141/880900",
      "coordinate": {
        "lat": 44.636112213134766,
        "lng": 8.175000190734863
      }
    },
    {
      "codice": "004194",
      "nome": "Roddi",
      "nomeStraniero": null,
      "codiceCatastale": "H472",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "sindaco@comunediroddi.it",
      "pec": "comunediroddi@professionalpec.it",
      "telefono": "+39 0173/615106",
      "fax": "+39 0173/620984",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "004195",
      "nome": "Roddino",
      "nomeStraniero": null,
      "codiceCatastale": "H473",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "roddino@ruparpiemonte.it",
      "pec": "roddino@pec.langabarolo.it",
      "telefono": "+39 0173/794133",
      "fax": "+39 0173/794928",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 8.016666412353516
      }
    },
    {
      "codice": "004196",
      "nome": "Rodello",
      "nomeStraniero": null,
      "codiceCatastale": "H474",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "rodello@comune.rodello.cn.it",
      "pec": "rodello@legalmail.it",
      "telefono": "+39 0173/617107",
      "fax": "+39 0173/617564",
      "coordinate": {
        "lat": 44.6285400390625,
        "lng": 8.056838035583496
      }
    },
    {
      "codice": "004197",
      "nome": "Rossana",
      "nomeStraniero": null,
      "codiceCatastale": "H578",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "info@comune.rossana.it",
      "pec": "rossana@cert.ruparpiemonte.it",
      "telefono": "+39 0175/64101",
      "fax": "+39 0175/64101",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 7.433333396911621
      }
    },
    {
      "codice": "004198",
      "nome": "Ruffia",
      "nomeStraniero": null,
      "codiceCatastale": "H633",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "ruffia@reteunitaria.piemonte.it",
      "pec": "ruffia@postemailcertificata.it",
      "telefono": "+39 0172/373207",
      "fax": "+39 0172/388900",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 7.599999904632568
      }
    },
    {
      "codice": "004199",
      "nome": "Sale delle Langhe",
      "nomeStraniero": null,
      "codiceCatastale": "H695",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "comsal@tiscali.it",
      "pec": "sale.delle.langhe@cert.ruparpiemonte.it",
      "telefono": "+39 0174/75015",
      "fax": "+39 0174/75346",
      "coordinate": {
        "lat": 44.400001525878906,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "004200",
      "nome": "Sale San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "H704",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "sale.san.giovanni@ruparpiemonte.it",
      "pec": "sale.san.giovanni@cert.ruparpiemonte.it",
      "telefono": "+39 0174/75000",
      "fax": "+39 0174/715928",
      "coordinate": {
        "lat": 44.400001525878906,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "004201",
      "nome": "Saliceto",
      "nomeStraniero": null,
      "codiceCatastale": "H710",
      "cap": "12079",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "saliceto@reteunitaria.piemonte.it",
      "pec": "saliceto@cert.ruparpiemonte.it",
      "telefono": "+39 0174/98021",
      "fax": "+39 0174/98586",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 8.166666984558105
      }
    },
    {
      "codice": "004202",
      "nome": "Salmour",
      "nomeStraniero": null,
      "codiceCatastale": "H716",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "salmour@ruparpiemonte.it",
      "pec": "salmour@cert.ruparpiemonte.it",
      "telefono": "+39 0172/649182",
      "fax": "+39 0172/649127",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.800000190734863
      }
    },
    {
      "codice": "004203",
      "nome": "Saluzzo",
      "nomeStraniero": null,
      "codiceCatastale": "H727",
      "cap": "12037",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "luca.martino@comune.saluzzo.cn.it",
      "pec": "protocollo@pec.comune.saluzzo.cn.it",
      "telefono": "+39 0175/211303",
      "fax": "+39 0175/211309",
      "coordinate": {
        "lat": 44.64527893066406,
        "lng": 7.490555763244629
      }
    },
    {
      "codice": "004204",
      "nome": "Sambuco",
      "nomeStraniero": null,
      "codiceCatastale": "H746",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.sambuco.cn.it",
      "pec": "sambuco@cert.ruparpiemonte.it",
      "telefono": "+39 0171/96633",
      "fax": "+39 0171/96633",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.083333492279053
      }
    },
    {
      "codice": "004205",
      "nome": "Sampeyre",
      "nomeStraniero": null,
      "codiceCatastale": "H755",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "sindaco.sampeyre@ruparpiemonte.it",
      "pec": "sampeyre@cert.ruparpiemonte.it",
      "telefono": "+39 0175/977148",
      "fax": "+39 0175/977824",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 7.183333396911621
      }
    },
    {
      "codice": "004206",
      "nome": "San Benedetto Belbo",
      "nomeStraniero": null,
      "codiceCatastale": "H770",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "ufficio@comune.sanbenedettobelbo.cn.it",
      "pec": "comune.sanbenedettobelbo.cn@cert.legalmail.it",
      "telefono": "+39 0173/796177",
      "fax": "+39 0173/796177",
      "coordinate": {
        "lat": 44.5,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "004207",
      "nome": "San Damiano Macra",
      "nomeStraniero": null,
      "codiceCatastale": "H812",
      "cap": "12029",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "san.damiano.macra@ruparpiemonte.it",
      "pec": "segretario.san.damiano.macra@cert.ruparpiemonte.it",
      "telefono": "+39 0171/900203",
      "fax": "+39 0171/900935",
      "coordinate": {
        "lat": 44.48833465576172,
        "lng": 7.257500171661377
      }
    },
    {
      "codice": "004210",
      "nome": "San Michele Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "I037",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "info@comunesanmichelemondovi.it",
      "pec": "info@pec.comunesanmichelemondovi.it",
      "telefono": "+39 0174/222041",
      "fax": "+39 0174/222084",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 7.900000095367432
      }
    },
    {
      "codice": "004208",
      "nome": "Sanfrè",
      "nomeStraniero": null,
      "codiceCatastale": "H851",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "anagrafe.sanfre@ruparpiemonte.it",
      "pec": "sanfre@cert.ruparpiemonte.it",
      "telefono": "+39 0172/58101",
      "fax": "+39 0172/58495",
      "coordinate": {
        "lat": 44.75,
        "lng": 7.800000190734863
      }
    },
    {
      "codice": "004209",
      "nome": "Sanfront",
      "nomeStraniero": null,
      "codiceCatastale": "H852",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "info@comune.sanfront.cn.it",
      "pec": "sanfront@cert.ruparpiemonte.it",
      "telefono": "+39 0175/948119",
      "fax": "+39 0175/948867",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "004211",
      "nome": "Sant'Albano Stura",
      "nomeStraniero": null,
      "codiceCatastale": "I210",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "segreteria@comune.santalbanostura.cn.it",
      "pec": "santalbano.stura@cert.ruparpiemonte.it",
      "telefono": "+39 0172/67142",
      "fax": "+39 0172/67587",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 7.7166666984558105
      }
    },
    {
      "codice": "004212",
      "nome": "Santa Vittoria d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "I316",
      "cap": "12069",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comunedisantavittoriadalba.it",
      "pec": "comune.santavittoriadalba@legalmail.it",
      "telefono": "+39 0172/478023",
      "fax": "+39 0172/478744",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004213",
      "nome": "Santo Stefano Belbo",
      "nomeStraniero": null,
      "codiceCatastale": "I367",
      "cap": "12058",
      "prefisso": "0141",
      "provincia": "Cuneo",
      "email": "info@santostefanobelbo.it",
      "pec": "comune@pec.santostefanobelbo.it",
      "telefono": "+39 0141/841818",
      "fax": "+39 0141/841825",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 8.233333587646484
      }
    },
    {
      "codice": "004214",
      "nome": "Santo Stefano Roero",
      "nomeStraniero": null,
      "codiceCatastale": "I372",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.santo-stefano-roero.cn.it",
      "pec": "santo.stefano.roero@cert.ruparpiemonte.it",
      "telefono": "+39 0173/90112",
      "fax": "+39 0173/ 90198",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "004215",
      "nome": "Savigliano",
      "nomeStraniero": null,
      "codiceCatastale": "I470",
      "cap": "12038",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.savigliano.cn.it",
      "pec": "savigliano@cert.ruparpiemonte.it",
      "telefono": "+39 0172/710221",
      "fax": "+39 0172/710322",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 7.633333206176758
      }
    },
    {
      "codice": "004216",
      "nome": "Scagnello",
      "nomeStraniero": null,
      "codiceCatastale": "I484",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "com.scagnello@vallinrete.org",
      "pec": "scagnello@cert.ruparpiemonte.it",
      "telefono": "+39 0174/783310",
      "fax": "+39 0174/783311",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "004217",
      "nome": "Scarnafigi",
      "nomeStraniero": null,
      "codiceCatastale": "I512",
      "cap": "12030",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.scarnafigi.cn.it",
      "pec": "scarnafigi@pec.comune.scarnafigi.cn.it",
      "telefono": "+39 0175/74101",
      "fax": "+39 0175/74619",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 7.566666603088379
      }
    },
    {
      "codice": "004218",
      "nome": "Serralunga d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "I646",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "ragioneria@comune.serralungadalba.cn.it",
      "pec": "serralungadalba@pec.langabarolo.it",
      "telefono": "+39 0173/613101",
      "fax": "+39 0173/613849",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 8
      }
    },
    {
      "codice": "004219",
      "nome": "Serravalle Langhe",
      "nomeStraniero": null,
      "codiceCatastale": "I659",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune@serravalle-langhe.com",
      "pec": "serravalle.langhe@cert.ruparpiemonte.it",
      "telefono": "+39 0173/748102",
      "fax": "+39 0173/748707",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "004220",
      "nome": "Sinio",
      "nomeStraniero": null,
      "codiceCatastale": "I750",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comune.sinio.cn.it",
      "pec": "sinio@pec.langabarolo.it",
      "telefono": "+39 0173/263990",
      "fax": "+39 0173/263837",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 8.016666412353516
      }
    },
    {
      "codice": "004221",
      "nome": "Somano",
      "nomeStraniero": null,
      "codiceCatastale": "I817",
      "cap": "12060",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "somano@ruparpiemonte.it",
      "pec": "somano@cert.ruparpiemonte.it",
      "telefono": "+39 0173/730101",
      "fax": "+39 0173/730900",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 8
      }
    },
    {
      "codice": "004222",
      "nome": "Sommariva del Bosco",
      "nomeStraniero": null,
      "codiceCatastale": "I822",
      "cap": "12048",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "demografici@comune.sommarivadelbosco.cn.it",
      "pec": "info@pec.sommarivabosco.it",
      "telefono": "+39 0172/566224",
      "fax": "+39 0172/54002",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 7.7833333015441895
      }
    },
    {
      "codice": "004223",
      "nome": "Sommariva Perno",
      "nomeStraniero": null,
      "codiceCatastale": "I823",
      "cap": "12040",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "sommariva.perno@ruparpiemonte.it",
      "pec": "sommariva.perno@cert.ruparpiemonte.it",
      "telefono": "+39 0172/46021",
      "fax": "+39 0172/46658",
      "coordinate": {
        "lat": 44.75,
        "lng": 7.900000095367432
      }
    },
    {
      "codice": "004224",
      "nome": "Stroppo",
      "nomeStraniero": null,
      "codiceCatastale": "I985",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.stroppo.cn.it",
      "pec": "stroppo@cert.ruparpiemonte.it",
      "telefono": "+39 0171999112",
      "fax": "+39 0071/999821",
      "coordinate": {
        "lat": 44.5,
        "lng": 7.133333206176758
      }
    },
    {
      "codice": "004225",
      "nome": "Tarantasca",
      "nomeStraniero": null,
      "codiceCatastale": "L048",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "demografici@comune.tarantasca.cn.it",
      "pec": "comunetarantasca@eticert.it",
      "telefono": "+39 0171/931921",
      "fax": "+39 0171/931676",
      "coordinate": {
        "lat": 44.5,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "004226",
      "nome": "Torre Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "L252",
      "cap": "12070",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "torre.bormida@reteunitaria.piemonte.it",
      "pec": "torre.bormida@cert.ruparpiemonte.it",
      "telefono": "+39 0173/88015",
      "fax": "+39 0173/828928",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 8.149999618530273
      }
    },
    {
      "codice": "004227",
      "nome": "Torre Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "L241",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "info@comune.torremondovi.cn.it",
      "pec": "comune.torremondovi.cn@legalmail.it",
      "telefono": "+39 0174/329102",
      "fax": "+39 0174/ 327921",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 7.900000095367432
      }
    },
    {
      "codice": "004228",
      "nome": "Torre San Giorgio",
      "nomeStraniero": null,
      "codiceCatastale": "L278",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "info@comune.torresangiorgio.cn.it",
      "pec": "comune.torresangiorgio.cn@legalmail.it",
      "telefono": "+39 0172/96012",
      "fax": "+39 0172/96263",
      "coordinate": {
        "lat": 44.73666763305664,
        "lng": 7.517777919769287
      }
    },
    {
      "codice": "004229",
      "nome": "Torresina",
      "nomeStraniero": null,
      "codiceCatastale": "L281",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "torresina@interfree.it",
      "pec": "comune.torresina.cn@legalmail.it",
      "telefono": "+39 0174/789048",
      "fax": "+39 0174/789048",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "004230",
      "nome": "Treiso",
      "nomeStraniero": null,
      "codiceCatastale": "L367",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.treiso.cn.it",
      "pec": "pec@pec.comune.treiso.cn.it",
      "telefono": "+39 0173/638116",
      "fax": "+39 0173/ 638900",
      "coordinate": {
        "lat": 44.6892204284668,
        "lng": 8.0869722366333
      }
    },
    {
      "codice": "004231",
      "nome": "Trezzo Tinella",
      "nomeStraniero": null,
      "codiceCatastale": "L410",
      "cap": "12050",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "comune.trezzotinella@gmail.com",
      "pec": "trezzo.tinella@cert.ruparpiemonte.it",
      "telefono": "+39 0173/630102",
      "fax": "+39 0173/630102",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "004232",
      "nome": "Trinità",
      "nomeStraniero": null,
      "codiceCatastale": "L427",
      "cap": "12049",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "segreteria@comune.trinita.cn.it",
      "pec": "trinita@cert.ruparpiemonte.it",
      "telefono": "+39 0172/66131",
      "fax": "+39 0172/1730548",
      "coordinate": {
        "lat": 44.508277893066406,
        "lng": 7.757896900177002
      }
    },
    {
      "codice": "004233",
      "nome": "Valdieri",
      "nomeStraniero": null,
      "codiceCatastale": "L558",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "protocollo@comune.valdieri.cn.it",
      "pec": "comune.valdieri@legalmail.it",
      "telefono": "+39 0171/97109",
      "fax": "+39 0171/97149",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "004234",
      "nome": "Valgrana",
      "nomeStraniero": null,
      "codiceCatastale": "L580",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.valgrana@tiscali.it",
      "pec": "valgrana@postemailcertificata.it",
      "telefono": "+39 0171/98101",
      "fax": "+39 0171/98370",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "004235",
      "nome": "Valloriate",
      "nomeStraniero": null,
      "codiceCatastale": "L631",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "valloriate@vallestura.cn.it",
      "pec": "valloriate@cert.legalmail.it",
      "telefono": "+39 0171/72977",
      "fax": "+39 0171/717015",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 7.366666793823242
      }
    },
    {
      "codice": "004237",
      "nome": "Venasca",
      "nomeStraniero": null,
      "codiceCatastale": "L729",
      "cap": "12020",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "anagrafe.venasca@ruparpiemonte.it",
      "pec": "venasca@cert.ruparpiemonte.it",
      "telefono": "+39 0175/567353",
      "fax": "+39 0175/567006",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "004238",
      "nome": "Verduno",
      "nomeStraniero": null,
      "codiceCatastale": "L758",
      "cap": "12060",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.verduno.cn.it",
      "pec": "verduno@pec.langabarolo.it",
      "telefono": "+39 0172/470121",
      "fax": "+39 0172/470333",
      "coordinate": {
        "lat": 44.66666793823242,
        "lng": 7.933333396911621
      }
    },
    {
      "codice": "004239",
      "nome": "Vernante",
      "nomeStraniero": null,
      "codiceCatastale": "L771",
      "cap": "12019",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "vernante@ruparpiemonte.it",
      "pec": "comune.vernante.cn@cert.legalmail.it",
      "telefono": "+39 0171/920104",
      "fax": "+39 0171/920698",
      "coordinate": {
        "lat": 44.25,
        "lng": 7.5333333015441895
      }
    },
    {
      "codice": "004240",
      "nome": "Verzuolo",
      "nomeStraniero": null,
      "codiceCatastale": "L804",
      "cap": "12039",
      "prefisso": "0175",
      "provincia": "Cuneo",
      "email": "demografici@comune.verzuolo.cn.it",
      "pec": "verzuolo@cert.ruparpiemonte.it",
      "telefono": "+39 0175/255152",
      "fax": "+39 0175/255154",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 7.483333110809326
      }
    },
    {
      "codice": "004241",
      "nome": "Vezza d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "L817",
      "cap": "12040",
      "prefisso": "0173",
      "provincia": "Cuneo",
      "email": "info@comunevezzadalba.it",
      "pec": "vezza.dalba@cert.ruparpiemonte.it",
      "telefono": "+39 0173/65022",
      "fax": "+39 0173/65184",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 8
      }
    },
    {
      "codice": "004242",
      "nome": "Vicoforte",
      "nomeStraniero": null,
      "codiceCatastale": "L841",
      "cap": "12080",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "segretario.vicoforte@ruparpiemonte.it",
      "pec": "vicoforte@cert.ruparpiemonte.it",
      "telefono": "+39 0174/563010",
      "fax": "+39 0174/563967",
      "coordinate": {
        "lat": 44.36666488647461,
        "lng": 7.849999904632568
      }
    },
    {
      "codice": "004243",
      "nome": "Vignolo",
      "nomeStraniero": null,
      "codiceCatastale": "L888",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.vignolo.cn.it",
      "pec": "comune.vignolo.cn@legalmail.it",
      "telefono": "+39 0171/48173",
      "fax": "+39 0171/48294",
      "coordinate": {
        "lat": 44.36363983154297,
        "lng": 7.472080230712891
      }
    },
    {
      "codice": "004244",
      "nome": "Villafalletto",
      "nomeStraniero": null,
      "codiceCatastale": "L942",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "segreteria@comune.villafalletto.cn.it",
      "pec": "info@pec.comune.villafalletto.cn.it",
      "telefono": "+39 0171/935310",
      "fax": "+39 0171/935380",
      "coordinate": {
        "lat": 44.54999923706055,
        "lng": 7.5333333015441895
      }
    },
    {
      "codice": "004245",
      "nome": "Villanova Mondovì",
      "nomeStraniero": null,
      "codiceCatastale": "L974",
      "cap": "12089",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "anagrafe@comune.villanova-mondovi.cn.it",
      "pec": "villanova.mondovi@cert.ruparpiemonte.it",
      "telefono": "+39 0174/698151",
      "fax": "+39 0174/698191",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "004246",
      "nome": "Villanova Solaro",
      "nomeStraniero": null,
      "codiceCatastale": "L990",
      "cap": "12030",
      "prefisso": "0172",
      "provincia": "Cuneo",
      "email": "villanova.solaro@ruparpiemonte.it",
      "pec": "villanova.solaro@cert.ruparpiemonte.it",
      "telefono": "+39 0172/99333",
      "fax": "+39 0172/99340",
      "coordinate": {
        "lat": 44.7308349609375,
        "lng": 7.575277805328369
      }
    },
    {
      "codice": "004247",
      "nome": "Villar San Costanzo",
      "nomeStraniero": null,
      "codiceCatastale": "M015",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "comune.villarsc@afpdronero.it",
      "pec": "villarsancostanzo@cert.ruparpiemonte.it",
      "telefono": "+39 0171/902087",
      "fax": "+39 0171/902117",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "004248",
      "nome": "Vinadio",
      "nomeStraniero": null,
      "codiceCatastale": "M055",
      "cap": "12010",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "anagrafe.vinadio@vallestura.cn.it",
      "pec": "vinadio@cert.ruparpiemonte.it",
      "telefono": "+39 0171/959143",
      "fax": "+39 0171/959432",
      "coordinate": {
        "lat": 44.29999923706055,
        "lng": 7.166666507720947
      }
    },
    {
      "codice": "004249",
      "nome": "Viola",
      "nomeStraniero": null,
      "codiceCatastale": "M063",
      "cap": "12070",
      "prefisso": "0174",
      "provincia": "Cuneo",
      "email": "viola@reteunitaria.piemonte.it",
      "pec": "comune.viola.cn@legalmail.it",
      "telefono": "+39 0174/73121",
      "fax": "+39 0174/73351",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "004250",
      "nome": "Vottignasco",
      "nomeStraniero": null,
      "codiceCatastale": "M136",
      "cap": "12020",
      "prefisso": "0171",
      "provincia": "Cuneo",
      "email": "info@comune.vottignasco.cn.it",
      "pec": "vottignasco@postemailcertificata.it",
      "telefono": "+39 0171/941023",
      "fax": "+39 0171/941228",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 7.583333492279053
      }
    }
  ],
  "Matera": [
    {
      "codice": "077001",
      "nome": "Accettura",
      "nomeStraniero": null,
      "codiceCatastale": "A017",
      "cap": "75011",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comuneaccettura@rete.basilicata.it",
      "pec": "comune.accettura@cert.ruparbasilicata.it",
      "telefono": "+39 0835/675005",
      "fax": "+39 0835/675942",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "077002",
      "nome": "Aliano",
      "nomeStraniero": null,
      "codiceCatastale": "A196",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "aliano@aliano.it",
      "pec": "protocolloaliano@pec.it",
      "telefono": "+39 0835/568038",
      "fax": "+39 0835/568196",
      "coordinate": {
        "lat": 40.31666564941406,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "077003",
      "nome": "Bernalda",
      "nomeStraniero": null,
      "codiceCatastale": "A801",
      "cap": "75012",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "elettorale@comune.bernalda.matera.it",
      "pec": "comunebernalda@pcert.postecert.it",
      "telefono": "+39 0835/540213",
      "fax": "+39 0835/542214",
      "coordinate": {
        "lat": 40.41666793823242,
        "lng": 16.683332443237305
      }
    },
    {
      "codice": "077004",
      "nome": "Calciano",
      "nomeStraniero": null,
      "codiceCatastale": "B391",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "info@comune.calciano.mt.it",
      "pec": "segreteria@pec.comune.calciano.mt.it",
      "telefono": "+39 0835 672016",
      "fax": "+39 0835/672039",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "077005",
      "nome": "Cirigliano",
      "nomeStraniero": null,
      "codiceCatastale": "C723",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunecirigliano@rete.basilicata.it",
      "pec": "comune.cirigliano@cert.ruparbasilicata.it",
      "telefono": "+39 0835/563081",
      "fax": "+39 08357563209",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "077006",
      "nome": "Colobraro",
      "nomeStraniero": null,
      "codiceCatastale": "C888",
      "cap": "75021",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunecolobraro@rete.basilicata.it",
      "pec": "comune.colobraro@cert.ruparbasilicata.it",
      "telefono": "+39 0835/841935",
      "fax": "+39 0835/841141",
      "coordinate": {
        "lat": 40.18333435058594,
        "lng": 16.433332443237305
      }
    },
    {
      "codice": "077007",
      "nome": "Craco",
      "nomeStraniero": null,
      "codiceCatastale": "D128",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "ufficioelttorale@comune.craco.mt.it",
      "pec": "info@pec.comune.craco.mt.it",
      "telefono": "+39 0835/459005",
      "fax": "+39 0835/459243",
      "coordinate": {
        "lat": 40.37861251831055,
        "lng": 16.440000534057617
      }
    },
    {
      "codice": "077008",
      "nome": "Ferrandina",
      "nomeStraniero": null,
      "codiceCatastale": "D547",
      "cap": "75013",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "municipioferrandina@comune.ferrandina.mt.it",
      "pec": "comune.ferrandina.mt@cert.ruparbasilicata.it",
      "telefono": "+39 0835/5756220",
      "fax": "+39 0835/756220",
      "coordinate": {
        "lat": 40.5,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "077009",
      "nome": "Garaguso",
      "nomeStraniero": null,
      "codiceCatastale": "D909",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunegaraguso@rete.basilicata.it",
      "pec": "comune.garaguso@cert.ruparbasilicata.it",
      "telefono": "+39 0835/671005",
      "fax": "+39 0835/671373",
      "coordinate": {
        "lat": 40.54999923706055,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "077010",
      "nome": "Gorgoglione",
      "nomeStraniero": null,
      "codiceCatastale": "E093",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunegorgoglione@rete.basilicata.it",
      "pec": "comunegorgoglione@cert.ruparbasilicata.it",
      "telefono": "+39 0835/560078",
      "fax": "+39 0835/560257",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "077011",
      "nome": "Grassano",
      "nomeStraniero": null,
      "codiceCatastale": "E147",
      "cap": "75014",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "affari.generali@comune.grassano.mt.it",
      "pec": "comune.grassano@cert.ruparbasilicata.it",
      "telefono": "+39 0835/527818",
      "fax": "+39 0835/527824",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "077012",
      "nome": "Grottole",
      "nomeStraniero": null,
      "codiceCatastale": "E213",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comune.grottole@cert.ruparbasilicata.it",
      "pec": "comune.grottole@cert.ruparbasilicata.it",
      "telefono": "+39 0835/558247",
      "fax": "+39 0835/758330",
      "coordinate": {
        "lat": 40.599998474121094,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "077013",
      "nome": "Irsina",
      "nomeStraniero": null,
      "codiceCatastale": "E326",
      "cap": "75022",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comune.irsina@comune.irsina.mt.it",
      "pec": "comune.irsina@pec.comune.irsina.mt.it",
      "telefono": "+39 0835/5628711",
      "fax": "+39 0835/628736",
      "coordinate": {
        "lat": 40.75,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "077014",
      "nome": "Matera",
      "nomeStraniero": null,
      "codiceCatastale": "F052",
      "cap": "75100",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "elettorale@comune.mt.it",
      "pec": "comune.matera@cert.ruparbasilicata.it",
      "telefono": "+39 0835 2411",
      "fax": "+39 0835 241471",
      "coordinate": {
        "lat": 40.66666793823242,
        "lng": 16.600000381469727
      }
    },
    {
      "codice": "077015",
      "nome": "Miglionico",
      "nomeStraniero": null,
      "codiceCatastale": "F201",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "servizidemografici@pec.miglionico.gov.it",
      "pec": "segreteria@miglionico.gov.it",
      "telefono": "+39 0835/559005",
      "fax": "+39 0835/559316",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 16.5
      }
    },
    {
      "codice": "077016",
      "nome": "Montalbano Jonico",
      "nomeStraniero": null,
      "codiceCatastale": "F399",
      "cap": "75023",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "elettorale@comune.montalbano.mt.it",
      "pec": "comune.montalbano@cert.ruparbasilicata.it",
      "telefono": "+39 0835/593811",
      "fax": "+39 0835/593840",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 16.566667556762695
      }
    },
    {
      "codice": "077017",
      "nome": "Montescaglioso",
      "nomeStraniero": null,
      "codiceCatastale": "F637",
      "cap": "75024",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "info@montescaglioso.eu",
      "pec": "aaggdemografici.montescaglioso@cert.ruparbasilicata.it",
      "telefono": "+39 0835 2091",
      "fax": "+39 0835/209239",
      "coordinate": {
        "lat": 40.54999923706055,
        "lng": 16.66666603088379
      }
    },
    {
      "codice": "077018",
      "nome": "Nova Siri",
      "nomeStraniero": null,
      "codiceCatastale": "A942",
      "cap": "75020",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunenovasiri@rete.basilicata.it",
      "pec": "comune.novasiri@cert.ruparbasilicata.it",
      "telefono": "+39 0835 5061",
      "fax": "+39 0835/506226",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 16.53333282470703
      }
    },
    {
      "codice": "077019",
      "nome": "Oliveto Lucano",
      "nomeStraniero": null,
      "codiceCatastale": "G037",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "olivetolucano@libero.it",
      "pec": "protocollo@pec.comune.olivetolucano.mt.it",
      "telefono": "+39 0835/677028",
      "fax": "+39 0835/677028",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 16.183332443237305
      }
    },
    {
      "codice": "077020",
      "nome": "Pisticci",
      "nomeStraniero": null,
      "codiceCatastale": "G712",
      "cap": "75020",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "demograficimarconia@hotmail.com",
      "pec": "comune.pisticci@cert.ruparbasilicata.it",
      "telefono": "+39 0835 44430",
      "fax": "+39 0835 582379",
      "coordinate": {
        "lat": 40.38333511352539,
        "lng": 16.549999237060547
      }
    },
    {
      "codice": "077021",
      "nome": "Policoro",
      "nomeStraniero": null,
      "codiceCatastale": "G786",
      "cap": "75025",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "anagrafe@pec.policoro.gov.it",
      "pec": "protocollo@pec.policoro.gov.it",
      "telefono": "+39 0835 9019111",
      "fax": "+39 0835 972964",
      "coordinate": {
        "lat": 40.20000076293945,
        "lng": 16.66666603088379
      }
    },
    {
      "codice": "077022",
      "nome": "Pomarico",
      "nomeStraniero": null,
      "codiceCatastale": "G806",
      "cap": "75016",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "anagrafe@comune.pomarico.mt.it",
      "pec": "comune.pomarico@cert.ruparbasilicata.it",
      "telefono": "+39 0835/751920",
      "fax": "+39 0835/741932",
      "coordinate": {
        "lat": 40.516666412353516,
        "lng": 16.549999237060547
      }
    },
    {
      "codice": "077023",
      "nome": "Rotondella",
      "nomeStraniero": null,
      "codiceCatastale": "H591",
      "cap": "75026",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "protocollo@comune.rotondella.mt.it",
      "pec": "protocollo@pec.comune.rotondella.mt.it",
      "telefono": "+39 0835 844111",
      "fax": "+39 0835 844274-75",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "077024",
      "nome": "Salandra",
      "nomeStraniero": null,
      "codiceCatastale": "H687",
      "cap": "75017",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "ufficio.demografici@comune.salandra.mt.it",
      "pec": "comune.salandra@cert.ruparbasilicata.it",
      "telefono": "+39 0835/673961",
      "fax": "+39 0835/673013",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "077025",
      "nome": "San Giorgio Lucano",
      "nomeStraniero": null,
      "codiceCatastale": "H888",
      "cap": "75027",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "anagrafesgiorgioluc@tiscali.it",
      "pec": "comune.sangiorgiolucano@cert.ruparbasilicata.it",
      "telefono": "+39 0835/846032",
      "fax": "+39 0835/846355",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "077026",
      "nome": "San Mauro Forte",
      "nomeStraniero": null,
      "codiceCatastale": "I029",
      "cap": "75010",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunesanmauroforte@rete.basilicata.it",
      "pec": "comune.sanmauroforte.mt@pec.it",
      "telefono": "+39 0835/674015",
      "fax": "+39 0835/674288",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 16.25
      }
    },
    {
      "codice": "077031",
      "nome": "Scanzano Jonico",
      "nomeStraniero": null,
      "codiceCatastale": "M256",
      "cap": "75020",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "amministrativi.a@libero.it",
      "pec": "protocollo@pec.comune.scanzanojonico.mt.it",
      "telefono": "+39 0835/952911",
      "fax": "+39 0835/952938",
      "coordinate": {
        "lat": 40.25,
        "lng": 16.700000762939453
      }
    },
    {
      "codice": "077027",
      "nome": "Stigliano",
      "nomeStraniero": null,
      "codiceCatastale": "I954",
      "cap": "75018",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comunestigliano@rete.basilicata.it",
      "pec": "comunestigliano@ebaspec.it",
      "telefono": "+39 0835 5671",
      "fax": "+39 0835/562025",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "077028",
      "nome": "Tricarico",
      "nomeStraniero": null,
      "codiceCatastale": "L418",
      "cap": "75019",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "demograficitricarico@tiscali.it",
      "pec": "comune.tricarico.statocivile@cert.ruparbasilicata.it",
      "telefono": "+39 0835 526111",
      "fax": "+39 0835 724035",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "077029",
      "nome": "Tursi",
      "nomeStraniero": null,
      "codiceCatastale": "L477",
      "cap": "75028",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "elettorale@comune.tursi.mt.it",
      "pec": "comune.tursi@cert.ruparbasilicata.it",
      "telefono": "+39 0835/531201",
      "fax": "+39 0835/1820362",
      "coordinate": {
        "lat": 40.25,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "077030",
      "nome": "Valsinni",
      "nomeStraniero": null,
      "codiceCatastale": "D513",
      "cap": "75029",
      "prefisso": "0835",
      "provincia": "Matera",
      "email": "comune.valsinni@libero.it",
      "pec": "comune.valsinni@cert.ruparbasilicata.it",
      "telefono": "+39 0835818038",
      "fax": "+39 0835817714",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 16.450000762939453
      }
    }
  ],
  "L'Aquila": [
    {
      "codice": "066001",
      "nome": "Acciano",
      "nomeStraniero": null,
      "codiceCatastale": "A018",
      "cap": "67020",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "acciano@katamail.com",
      "pec": "accianoaq@pec.it",
      "telefono": "+39 0864/799132",
      "fax": "+39 0864/799144",
      "coordinate": {
        "lat": 42.1767692565918,
        "lng": 13.717829704284668
      }
    },
    {
      "codice": "066002",
      "nome": "Aielli",
      "nomeStraniero": null,
      "codiceCatastale": "A100",
      "cap": "67041",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "protocollo@comunediaielli.it",
      "pec": "comunediaielli@pec.it",
      "telefono": "+39 0863/789654",
      "fax": "+39 0863/788072",
      "coordinate": {
        "lat": 42.081459045410156,
        "lng": 13.591130256652832
      }
    },
    {
      "codice": "066003",
      "nome": "Alfedena",
      "nomeStraniero": null,
      "codiceCatastale": "A187",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comune@comune.alfedena.aq.it",
      "pec": "comune@pec.comune.alfedena.aq.it",
      "telefono": "+39 0864/87114",
      "fax": "+39 0864/87432",
      "coordinate": {
        "lat": 41.735450744628906,
        "lng": 14.03499984741211
      }
    },
    {
      "codice": "066004",
      "nome": "Anversa degli Abruzzi",
      "nomeStraniero": null,
      "codiceCatastale": "A318",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "info@comunedianversa.it",
      "pec": "comunedianversa@pec.it",
      "telefono": "+39 0864/49115",
      "fax": "+39 0864/490930",
      "coordinate": {
        "lat": 41.994720458984375,
        "lng": 13.80472183227539
      }
    },
    {
      "codice": "066005",
      "nome": "Ateleta",
      "nomeStraniero": null,
      "codiceCatastale": "A481",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comune.ateleta@tiscalinet.it",
      "pec": "comune.ateleta.aq@pec.it",
      "telefono": "+39 0864/65030",
      "fax": "+39 0864/655952",
      "coordinate": {
        "lat": 41.85457229614258,
        "lng": 14.199007034301758
      }
    },
    {
      "codice": "066006",
      "nome": "Avezzano",
      "nomeStraniero": null,
      "codiceCatastale": "A515",
      "cap": "67051",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "comune.avezzano.aq@postecert.it",
      "pec": "comune.avezzano.aq@postecert.it",
      "telefono": "+39 0863 5011",
      "fax": "+39 0863/501227",
      "coordinate": {
        "lat": 42.030975341796875,
        "lng": 13.426372528076172
      }
    },
    {
      "codice": "066007",
      "nome": "Balsorano",
      "nomeStraniero": null,
      "codiceCatastale": "A603",
      "cap": "67052",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.balsorano.aq.it",
      "pec": "info@pec.comune.balsorano.aq.it",
      "telefono": "+39 0863/950741",
      "fax": "+39 0863/951249",
      "coordinate": {
        "lat": 41.809818267822266,
        "lng": 13.559800148010254
      }
    },
    {
      "codice": "066008",
      "nome": "Barete",
      "nomeStraniero": null,
      "codiceCatastale": "A656",
      "cap": "67010",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comune.barete.aq@legalmail.it",
      "pec": "anagrafe.barete@legalmail.it",
      "telefono": "+39 0862/976235",
      "fax": "+39 0862/975041",
      "coordinate": {
        "lat": 42.45138931274414,
        "lng": 13.283055305480957
      }
    },
    {
      "codice": "066009",
      "nome": "Barisciano",
      "nomeStraniero": null,
      "codiceCatastale": "A667",
      "cap": "67021",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "protocollo@comune.barisciano.aq.it",
      "pec": "amministrazione@pec.comunebarisciano.it",
      "telefono": "+39 086289565",
      "fax": "+39 086289225",
      "coordinate": {
        "lat": 42.325279235839844,
        "lng": 13.59333324432373
      }
    },
    {
      "codice": "066010",
      "nome": "Barrea",
      "nomeStraniero": null,
      "codiceCatastale": "A678",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "anagrafe@comune.barrea.aq.it",
      "pec": "comune.barrea@pec.it",
      "telefono": "+39 0864/88114",
      "fax": "+39 0864/88204",
      "coordinate": {
        "lat": 41.75694274902344,
        "lng": 13.993332862854004
      }
    },
    {
      "codice": "066011",
      "nome": "Bisegna",
      "nomeStraniero": null,
      "codiceCatastale": "A884",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "bisegnacomune@tiscali.it",
      "pec": "comunebisegna@pec.it",
      "telefono": "+39 0863/85139",
      "fax": "+39 0863/85259",
      "coordinate": {
        "lat": 41.92222213745117,
        "lng": 13.758610725402832
      }
    },
    {
      "codice": "066012",
      "nome": "Bugnara",
      "nomeStraniero": null,
      "codiceCatastale": "B256",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "bugnara@comunedibugnara.it",
      "pec": "comune.bugnara.aq@pec.comnet-ra.it",
      "telefono": "+39 0864/46114",
      "fax": "+39 0864/46301",
      "coordinate": {
        "lat": 42.024723052978516,
        "lng": 13.862222671508789
      }
    },
    {
      "codice": "066013",
      "nome": "Cagnano Amiterno",
      "nomeStraniero": null,
      "codiceCatastale": "B358",
      "cap": "67012",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "cagnanoamiterno@virgilio.it",
      "pec": "segretario@pec.comunecagnanoamiterno.it",
      "telefono": "+39 0862/978122",
      "fax": "+39 0862/978846",
      "coordinate": {
        "lat": 42.45833206176758,
        "lng": 13.228888511657715
      }
    },
    {
      "codice": "066014",
      "nome": "Calascio",
      "nomeStraniero": null,
      "codiceCatastale": "B382",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comune.calascio@comune.calascio.aq.it",
      "pec": "comune.calascio@pec.comune.calascio.aq.it",
      "telefono": "+39 0862/930132",
      "fax": "+39 0862/930174",
      "coordinate": {
        "lat": 42.32749938964844,
        "lng": 13.698055267333984
      }
    },
    {
      "codice": "066015",
      "nome": "Campo di Giove",
      "nomeStraniero": null,
      "codiceCatastale": "B526",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "sindaco@comunecampodigiove.it",
      "pec": "comune.campodigiove.aq@pec.comnet-ra.it",
      "telefono": "+39 0864/40116",
      "fax": "+39 0864/408040",
      "coordinate": {
        "lat": 42.011112213134766,
        "lng": 14.040277481079102
      }
    },
    {
      "codice": "066016",
      "nome": "Campotosto",
      "nomeStraniero": null,
      "codiceCatastale": "B569",
      "cap": "67013",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "demografici@comune.campotosto.aq.it",
      "pec": "protocollo@pec.comune.campotosto.aq.it",
      "telefono": "+39 0862/900142",
      "fax": "+39 0862/900320",
      "coordinate": {
        "lat": 42.558841705322266,
        "lng": 13.367989540100098
      }
    },
    {
      "codice": "066017",
      "nome": "Canistro",
      "nomeStraniero": null,
      "codiceCatastale": "B606",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comunecanistro.it",
      "pec": "comune.canistro.aq@pec.comnet-ra.it",
      "telefono": "+39 0863/97142",
      "fax": "+39 0863/977641",
      "coordinate": {
        "lat": 41.94166564941406,
        "lng": 13.41333293914795
      }
    },
    {
      "codice": "066018",
      "nome": "Cansano",
      "nomeStraniero": null,
      "codiceCatastale": "B624",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "info@comune.cansano.aq.it",
      "pec": "comunedicansano@pec.it",
      "telefono": "0864/40131 -40521",
      "fax": "0864/408790",
      "coordinate": {
        "lat": 42.005279541015625,
        "lng": 14.012777328491211
      }
    },
    {
      "codice": "066019",
      "nome": "Capestrano",
      "nomeStraniero": null,
      "codiceCatastale": "B651",
      "cap": "67022",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "cristina.dalfonso@comunedicapestrano.it",
      "pec": "demografici.capestrano@legalmail.it",
      "telefono": "+39 0862/95227",
      "fax": "+39 0862/954251",
      "coordinate": {
        "lat": 42.26944351196289,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "066020",
      "nome": "Capistrello",
      "nomeStraniero": null,
      "codiceCatastale": "B656",
      "cap": "67053",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@pec.comune.capistrello.aq.it",
      "pec": "info@pec.comune.capistrello.aq.it",
      "telefono": "+39 086345841",
      "fax": "+39 08634584222",
      "coordinate": {
        "lat": 41.973609924316406,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "066021",
      "nome": "Capitignano",
      "nomeStraniero": null,
      "codiceCatastale": "B658",
      "cap": "67014",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunedicapitignano3@virgilio.it",
      "pec": "comune.capitignano@postecert.it",
      "telefono": "+39 0862/905463",
      "fax": "+39 0862/905158",
      "coordinate": {
        "lat": 42.520408630371094,
        "lng": 13.301219940185547
      }
    },
    {
      "codice": "066022",
      "nome": "Caporciano",
      "nomeStraniero": null,
      "codiceCatastale": "B672",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "info@comunecaporciano.aq.it",
      "pec": "posta@pec.comunecaporciano.aq.it",
      "telefono": "+39 0862/937331",
      "fax": "+39 0862/93475",
      "coordinate": {
        "lat": 42.25166702270508,
        "lng": 13.67388916015625
      }
    },
    {
      "codice": "066023",
      "nome": "Cappadocia",
      "nomeStraniero": null,
      "codiceCatastale": "B677",
      "cap": "67060",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.cappadocia.aq.it",
      "pec": "segreteriacappadocia@postecert.it",
      "telefono": "+39 0863/670117",
      "fax": "+39 0863/670232",
      "coordinate": {
        "lat": 42.00749969482422,
        "lng": 13.28083324432373
      }
    },
    {
      "codice": "066024",
      "nome": "Carapelle Calvisio",
      "nomeStraniero": null,
      "codiceCatastale": "B725",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "carapelle.calvisio@tiscali.it",
      "pec": "comune.carapelle.aq@legalmail.it",
      "telefono": "+39 0862/930146",
      "fax": "+39 0862/930910",
      "coordinate": {
        "lat": 42.30027770996094,
        "lng": 13.685555458068848
      }
    },
    {
      "codice": "066025",
      "nome": "Carsoli",
      "nomeStraniero": null,
      "codiceCatastale": "B842",
      "cap": "67061",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "servizigenerali@comune.carsoli.aq.it",
      "pec": "comune.carsoli@pec.it",
      "telefono": "+39 0863 9081",
      "fax": "+39 0863/995412",
      "coordinate": {
        "lat": 42.098819732666016,
        "lng": 13.088560104370117
      }
    },
    {
      "codice": "066026",
      "nome": "Castel del Monte",
      "nomeStraniero": null,
      "codiceCatastale": "C083",
      "cap": "67023",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "casteldelmonte@tin.it",
      "pec": "protocollo.comune.casteldelmonte@legalmail.it",
      "telefono": "+39 0862/938137",
      "fax": "+39 0862/938180",
      "coordinate": {
        "lat": 42.365440368652344,
        "lng": 13.725919723510742
      }
    },
    {
      "codice": "066027",
      "nome": "Castel di Ieri",
      "nomeStraniero": null,
      "codiceCatastale": "C090",
      "cap": "67020",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "municipiodicasteldiieri@virgilio.it",
      "pec": "casteldiieriaq@pec.it",
      "telefono": "+39 0864/79168",
      "fax": "+39 0864/797634",
      "coordinate": {
        "lat": 42.116111755371094,
        "lng": 13.743611335754395
      }
    },
    {
      "codice": "066028",
      "nome": "Castel di Sangro",
      "nomeStraniero": null,
      "codiceCatastale": "C096",
      "cap": "67031",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "affari.generali@comune.casteldisangro.aq.it",
      "pec": "comune.casteldisangro.aq@pec.comnet-ra.it",
      "telefono": "+39 0864/8242200",
      "fax": "+39 0864/8242216",
      "coordinate": {
        "lat": 41.78422164916992,
        "lng": 14.108524322509766
      }
    },
    {
      "codice": "066029",
      "nome": "Castellafiume",
      "nomeStraniero": null,
      "codiceCatastale": "C126",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.castellafiume.aq.it",
      "pec": "comunedicastellafiume@pec.it",
      "telefono": "+39 0863/544142",
      "fax": "+39 0863/54440",
      "coordinate": {
        "lat": 41.9900016784668,
        "lng": 13.335277557373047
      }
    },
    {
      "codice": "066030",
      "nome": "Castelvecchio Calvisio",
      "nomeStraniero": null,
      "codiceCatastale": "C278",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "castelvecchiocalvisio@gmail.com",
      "pec": "castelvecchiocalvisio@pec.it",
      "telefono": "+39 0862/930777",
      "fax": "+39 0862/930144",
      "coordinate": {
        "lat": 42.31222152709961,
        "lng": 13.688055992126465
      }
    },
    {
      "codice": "066031",
      "nome": "Castelvecchio Subequo",
      "nomeStraniero": null,
      "codiceCatastale": "C279",
      "cap": "67024",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "castelvecchio@katamail.com",
      "pec": "servizi.castelvecchiosubequo@pec.it",
      "telefono": "+39 0864 79117",
      "fax": "+39 0864 797223",
      "coordinate": {
        "lat": 42.13111114501953,
        "lng": 13.72861099243164
      }
    },
    {
      "codice": "066032",
      "nome": "Celano",
      "nomeStraniero": null,
      "codiceCatastale": "C426",
      "cap": "67043",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "loreto.guerra@comune.celano.aq.it",
      "pec": "comune.celano@pec.it",
      "telefono": "+39 0863/79541",
      "fax": "+39 0863/792335",
      "coordinate": {
        "lat": 42.08430480957031,
        "lng": 13.547750473022461
      }
    },
    {
      "codice": "066033",
      "nome": "Cerchio",
      "nomeStraniero": null,
      "codiceCatastale": "C492",
      "cap": "67044",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comunedicerchio.it",
      "pec": "comunedicerchio@pec.it",
      "telefono": "+39 0863/78116",
      "fax": "+39 0863/788033",
      "coordinate": {
        "lat": 42.06194305419922,
        "lng": 13.602499961853027
      }
    },
    {
      "codice": "066034",
      "nome": "Civita d'Antino",
      "nomeStraniero": null,
      "codiceCatastale": "C766",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.civitadantino.aq.it",
      "pec": "info@pec.comune.civitadantino.aq.it",
      "telefono": "+39 0863/978122",
      "fax": "+39 0863/978564",
      "coordinate": {
        "lat": 41.88666534423828,
        "lng": 13.47249984741211
      }
    },
    {
      "codice": "066035",
      "nome": "Civitella Alfedena",
      "nomeStraniero": null,
      "codiceCatastale": "C778",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "info@comune.civitellaalfedena.aq.it",
      "pec": "comune.civitellaalfedena@pec.it",
      "telefono": "+39 0864/890444",
      "fax": "+39 0862/890445",
      "coordinate": {
        "lat": 41.766387939453125,
        "lng": 13.943611145019531
      }
    },
    {
      "codice": "066036",
      "nome": "Civitella Roveto",
      "nomeStraniero": null,
      "codiceCatastale": "C783",
      "cap": "67054",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "sd@comune.civitella-roveto.aq.it",
      "pec": "info@pec.comune.civitella-roveto.aq.it",
      "telefono": "+39 0863/979527",
      "fax": "+39 0863/979586",
      "coordinate": {
        "lat": 41.91694259643555,
        "lng": 13.427778244018555
      }
    },
    {
      "codice": "066037",
      "nome": "Cocullo",
      "nomeStraniero": null,
      "codiceCatastale": "C811",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "protocollo.cocullo@gmail.com",
      "pec": "comune.cocullo@pec.it",
      "telefono": "+39 0864/49117",
      "fax": "+39 0864/49449",
      "coordinate": {
        "lat": 42.03388977050781,
        "lng": 13.775833129882812
      }
    },
    {
      "codice": "066038",
      "nome": "Collarmele",
      "nomeStraniero": null,
      "codiceCatastale": "C844",
      "cap": "67040",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comunedicollarmele.it",
      "pec": "comunedicollarmele@pec.it",
      "telefono": "+39 0863/78126",
      "fax": "+39 0863/789331",
      "coordinate": {
        "lat": 42.060001373291016,
        "lng": 13.626667022705078
      }
    },
    {
      "codice": "066039",
      "nome": "Collelongo",
      "nomeStraniero": null,
      "codiceCatastale": "C862",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "ufficio-amministrativo@comune.collelongo.aq.it",
      "pec": "protocollo.comunedicollelongo@pec.it",
      "telefono": "+39 0863/948113",
      "fax": "+39 0863948317",
      "coordinate": {
        "lat": 41.887779235839844,
        "lng": 13.585833549499512
      }
    },
    {
      "codice": "066040",
      "nome": "Collepietro",
      "nomeStraniero": null,
      "codiceCatastale": "C866",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "rag.collepietro@libero.it",
      "pec": "comune.collepietro.aq@legalmail.it",
      "telefono": "+39 0862/955136",
      "fax": "+39 0862/955783",
      "coordinate": {
        "lat": 42.2227783203125,
        "lng": 13.780278205871582
      }
    },
    {
      "codice": "066041",
      "nome": "Corfinio",
      "nomeStraniero": null,
      "codiceCatastale": "C999",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comune@comunedicorfinio.it",
      "pec": "amministrativo.comunedicorfinio@pec.it",
      "telefono": "+39 0864728350",
      "fax": "+39 0864728177",
      "coordinate": {
        "lat": 42.12472152709961,
        "lng": 13.842499732971191
      }
    },
    {
      "codice": "066042",
      "nome": "Fagnano Alto",
      "nomeStraniero": null,
      "codiceCatastale": "D465",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunefagnanoalto@gmail.com",
      "pec": "comunefagnanoalto@pec.comunefagnanoalto.it",
      "telefono": "+39 0862/86145",
      "fax": "+39 0862/86161",
      "coordinate": {
        "lat": 42.25444412231445,
        "lng": 13.575277328491211
      }
    },
    {
      "codice": "066043",
      "nome": "Fontecchio",
      "nomeStraniero": null,
      "codiceCatastale": "D681",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "anagrafe@comune.fontecchio.aq.it",
      "pec": "comune.fontecchio@pec.it",
      "telefono": "+39 0862/85131",
      "fax": "+39 0862/85134",
      "coordinate": {
        "lat": 42.23055648803711,
        "lng": 13.606666564941406
      }
    },
    {
      "codice": "066044",
      "nome": "Fossa",
      "nomeStraniero": null,
      "codiceCatastale": "D736",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunefossa@tin.it",
      "pec": "comunefossa@pec.it",
      "telefono": "+39 0862/751120",
      "fax": "+39 0862/751390",
      "coordinate": {
        "lat": 42.292728424072266,
        "lng": 13.48779010772705
      }
    },
    {
      "codice": "066045",
      "nome": "Gagliano Aterno",
      "nomeStraniero": null,
      "codiceCatastale": "D850",
      "cap": "67020",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "info@comunegaglianoaterno.it",
      "pec": "comunegaglianoaterno@pec.it",
      "telefono": "+39 0864/797401",
      "fax": "+39 0864/797401",
      "coordinate": {
        "lat": 42.127498626708984,
        "lng": 13.700833320617676
      }
    },
    {
      "codice": "066046",
      "nome": "Gioia dei Marsi",
      "nomeStraniero": null,
      "codiceCatastale": "E040",
      "cap": "67055",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "gioiadeimarsi@tiscali.it",
      "pec": "protocollocomunedigioiadeimarsi@pec.it",
      "telefono": "+39 086388168",
      "fax": "+39 086388488",
      "coordinate": {
        "lat": 41.95888900756836,
        "lng": 13.69083309173584
      }
    },
    {
      "codice": "066047",
      "nome": "Goriano Sicoli",
      "nomeStraniero": null,
      "codiceCatastale": "E096",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "anagrafe@jumpy.it",
      "pec": "gorianosicoli@pec.it",
      "telefono": "+39 0864/720003",
      "fax": "+39 0864/720068",
      "coordinate": {
        "lat": 42.0816650390625,
        "lng": 13.775833129882812
      }
    },
    {
      "codice": "066048",
      "nome": "Introdacqua",
      "nomeStraniero": null,
      "codiceCatastale": "E307",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "demografici@introdacqua.gov.it",
      "pec": "comune@pec.introdacqua.gov.it",
      "telefono": "086447116",
      "fax": "0864470046",
      "coordinate": {
        "lat": 42.00833511352539,
        "lng": 13.89916706085205
      }
    },
    {
      "codice": "066049",
      "nome": "L'Aquila",
      "nomeStraniero": null,
      "codiceCatastale": "A345",
      "cap": "67100",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "sindaco@comune.laquila.it",
      "pec": "protocollo@comune.laquila.postecert.it",
      "telefono": "+39 0862 414550",
      "fax": "+39 0862 64031",
      "coordinate": {
        "lat": 42.354007720947266,
        "lng": 13.39199161529541
      }
    },
    {
      "codice": "066050",
      "nome": "Lecce nei Marsi",
      "nomeStraniero": null,
      "codiceCatastale": "E505",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "comunelecceneimarsi@tiscali.it",
      "pec": "comune.lecceneimarsi.aq@pec.comnet-ra.it",
      "telefono": "+39 0863/88129",
      "fax": "+39 0863/88186",
      "coordinate": {
        "lat": 41.934722900390625,
        "lng": 13.687777519226074
      }
    },
    {
      "codice": "066051",
      "nome": "Luco dei Marsi",
      "nomeStraniero": null,
      "codiceCatastale": "E723",
      "cap": "67056",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "municipio@luco.it",
      "pec": "info@pec.comune.lucodeimarsi.aq.it",
      "telefono": "+39 0863/1855142",
      "fax": "+39 0863/1858358",
      "coordinate": {
        "lat": 41.96131134033203,
        "lng": 13.46850872039795
      }
    },
    {
      "codice": "066052",
      "nome": "Lucoli",
      "nomeStraniero": null,
      "codiceCatastale": "E724",
      "cap": "67045",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "sindaco@comune.lucoli.aq.it",
      "pec": "protocollo.lucoli.aq@legalmail.it",
      "telefono": "+39 0862/73160",
      "fax": "+39 0862/730790",
      "coordinate": {
        "lat": 42.29194259643555,
        "lng": 13.338889122009277
      }
    },
    {
      "codice": "066053",
      "nome": "Magliano de' Marsi",
      "nomeStraniero": null,
      "codiceCatastale": "E811",
      "cap": "67062",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "amministrativo@comune.maglianodemarsi.aq.it",
      "pec": "protocollo.maglianodemarsi@pec.it",
      "telefono": "+39 0863 5161",
      "fax": "+39 0863/515018",
      "coordinate": {
        "lat": 42.092498779296875,
        "lng": 13.36472225189209
      }
    },
    {
      "codice": "066054",
      "nome": "Massa d'Albe",
      "nomeStraniero": null,
      "codiceCatastale": "F022",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "demografici@comune.massadalbe.aq.it",
      "pec": "info@pec.comune.massadalbe.aq.it",
      "telefono": "+39 0863/519144",
      "fax": "+39 0863/519439",
      "coordinate": {
        "lat": 42.108333587646484,
        "lng": 13.395277976989746
      }
    },
    {
      "codice": "066055",
      "nome": "Molina Aterno",
      "nomeStraniero": null,
      "codiceCatastale": "M255",
      "cap": "67020",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "molina.aterno@tin.it",
      "pec": "molinaaterno@pec.it",
      "telefono": "+39 0864/797461",
      "fax": "+39 0864/79141",
      "coordinate": {
        "lat": 42.150001525878906,
        "lng": 13.73638916015625
      }
    },
    {
      "codice": "066056",
      "nome": "Montereale",
      "nomeStraniero": null,
      "codiceCatastale": "F595",
      "cap": "67015",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "anagrafe@montereale.it",
      "pec": "anagrafe.montereale@legalmail.it",
      "telefono": "+39 0862/901217",
      "fax": "+39 0862/902393",
      "coordinate": {
        "lat": 42.52277755737305,
        "lng": 13.248611450195312
      }
    },
    {
      "codice": "066057",
      "nome": "Morino",
      "nomeStraniero": null,
      "codiceCatastale": "F732",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.morino.aq.it",
      "pec": "comune.morino.aq@pec.comnet-ra.it",
      "telefono": "+39 0863/978133",
      "fax": "+39 0863/970027",
      "coordinate": {
        "lat": 41.86527633666992,
        "lng": 13.457777976989746
      }
    },
    {
      "codice": "066058",
      "nome": "Navelli",
      "nomeStraniero": null,
      "codiceCatastale": "F852",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunenavelli@gmail.com",
      "pec": "comune.navelli@pec.mailcert.info",
      "telefono": "+39 0862/959119",
      "fax": "+39 0862/959323",
      "coordinate": {
        "lat": 42.23720932006836,
        "lng": 13.729610443115234
      }
    },
    {
      "codice": "066059",
      "nome": "Ocre",
      "nomeStraniero": null,
      "codiceCatastale": "F996",
      "cap": "67040",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "info@comunediocre.it",
      "pec": "demografici.ocre.aq@legalmail.it",
      "telefono": "+39 0862/751413",
      "fax": "+39 0862/751722",
      "coordinate": {
        "lat": 42.28615951538086,
        "lng": 13.475179672241211
      }
    },
    {
      "codice": "066060",
      "nome": "Ofena",
      "nomeStraniero": null,
      "codiceCatastale": "G002",
      "cap": "67025",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "anagrafe@comune.ofena.aq.it",
      "pec": "anagrafe.ofena@pec.it",
      "telefono": "+39 0862/956133",
      "fax": "+39 0862/956189",
      "coordinate": {
        "lat": 42.32666778564453,
        "lng": 13.759721755981445
      }
    },
    {
      "codice": "066061",
      "nome": "Opi",
      "nomeStraniero": null,
      "codiceCatastale": "G079",
      "cap": "67030",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.opi.aq.it",
      "pec": "comuneopi@pec.comune.opi.aq.it",
      "telefono": "+39 0863/910606",
      "fax": "+39 0863/916078",
      "coordinate": {
        "lat": 41.77777862548828,
        "lng": 13.829999923706055
      }
    },
    {
      "codice": "066062",
      "nome": "Oricola",
      "nomeStraniero": null,
      "codiceCatastale": "G102",
      "cap": "67063",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.oricola.aq.it",
      "pec": "info@pec.comune.oricola.aq.it",
      "telefono": "+390863996121",
      "fax": "+390863996529",
      "coordinate": {
        "lat": 42.05027770996094,
        "lng": 13.039527893066406
      }
    },
    {
      "codice": "066063",
      "nome": "Ortona dei Marsi",
      "nomeStraniero": null,
      "codiceCatastale": "G142",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.ortona.aq.it",
      "pec": "comune.ortona.aq@pec.comnet-ra.it",
      "telefono": "+39 0863/87113",
      "fax": "+39 0863/87134",
      "coordinate": {
        "lat": 41.99833297729492,
        "lng": 13.728888511657715
      }
    },
    {
      "codice": "066064",
      "nome": "Ortucchio",
      "nomeStraniero": null,
      "codiceCatastale": "G145",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "ufficioanagrafe@comunediortucchio.it",
      "pec": "sdcomunediortucchioaq@pec.it",
      "telefono": "+39 0863/839117",
      "fax": "+39 0863/83208",
      "coordinate": {
        "lat": 41.956111907958984,
        "lng": 13.646666526794434
      }
    },
    {
      "codice": "066065",
      "nome": "Ovindoli",
      "nomeStraniero": null,
      "codiceCatastale": "G200",
      "cap": "67046",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "comune.ovindoli@comuneovindoli.191.it",
      "pec": "affarigenerali.ovindoli@legalmail.it",
      "telefono": "+39 0863/706100",
      "fax": "+39 0863/710183",
      "coordinate": {
        "lat": 42.13805389404297,
        "lng": 13.516944885253906
      }
    },
    {
      "codice": "066066",
      "nome": "Pacentro",
      "nomeStraniero": null,
      "codiceCatastale": "G210",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comune.pacentro@tin.it",
      "pec": "comune.pacentro@pec.it",
      "telefono": "+39 0864/41114",
      "fax": "+39 0864/41529",
      "coordinate": {
        "lat": 42.05043029785156,
        "lng": 13.991510391235352
      }
    },
    {
      "codice": "066067",
      "nome": "Pereto",
      "nomeStraniero": null,
      "codiceCatastale": "G449",
      "cap": "67064",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "comunepereto@tiscali.it",
      "pec": "info@pec.comune.pereto.aq.it",
      "telefono": "+39 0863/997440",
      "fax": "+39 0863/907476",
      "coordinate": {
        "lat": 42.06055450439453,
        "lng": 13.097444534301758
      }
    },
    {
      "codice": "066068",
      "nome": "Pescasseroli",
      "nomeStraniero": null,
      "codiceCatastale": "G484",
      "cap": "67032",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "affarigenerali@comune.pescasseroli.aq.it",
      "pec": "posta@pec.comune.pescasseroli.aq.it",
      "telefono": "+39 0863/91141",
      "fax": null,
      "coordinate": {
        "lat": 41.80500030517578,
        "lng": 13.789722442626953
      }
    },
    {
      "codice": "066069",
      "nome": "Pescina",
      "nomeStraniero": null,
      "codiceCatastale": "G492",
      "cap": "67057",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "sindaco@comune.pescina.aq.it",
      "pec": "demograficipescina@postecert.it",
      "telefono": "+39 0863/84281",
      "fax": "+39 0863/841067",
      "coordinate": {
        "lat": 42.026390075683594,
        "lng": 13.658888816833496
      }
    },
    {
      "codice": "066070",
      "nome": "Pescocostanzo",
      "nomeStraniero": null,
      "codiceCatastale": "G493",
      "cap": "67033",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "amministrativo@comune.pescocostanzo.aq.it",
      "pec": "comune.pescocostanzo@pec.it",
      "telefono": "+39 0864/640003",
      "fax": "+39 0864/640005",
      "coordinate": {
        "lat": 41.88722229003906,
        "lng": 14.066389083862305
      }
    },
    {
      "codice": "066071",
      "nome": "Pettorano sul Gizio",
      "nomeStraniero": null,
      "codiceCatastale": "G524",
      "cap": "67034",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "info@comune.pettorano.aq.it",
      "pec": "comunepettoranosulgizio@pec.it",
      "telefono": "+39 0864/48115",
      "fax": "+39 0864/487965",
      "coordinate": {
        "lat": 41.975555419921875,
        "lng": 13.959722518920898
      }
    },
    {
      "codice": "066072",
      "nome": "Pizzoli",
      "nomeStraniero": null,
      "codiceCatastale": "G726",
      "cap": "67017",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "protocollo@comune.pizzoli.aq.it",
      "pec": "protocollo@pec.comune.pizzoli.aq.it",
      "telefono": "+39 0862.975591",
      "fax": "+39 0862.977997",
      "coordinate": {
        "lat": 42.436031341552734,
        "lng": 13.298859596252441
      }
    },
    {
      "codice": "066073",
      "nome": "Poggio Picenze",
      "nomeStraniero": null,
      "codiceCatastale": "G766",
      "cap": "67026",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "anagrafecomunepoggiopicenze.aq@gmail.com",
      "pec": "demografici.poggiopicenze.aq@legalmail.it",
      "telefono": "+39 0862/80142",
      "fax": "+39 0862/80440",
      "coordinate": {
        "lat": 42.32061004638672,
        "lng": 13.540360450744629
      }
    },
    {
      "codice": "066074",
      "nome": "Prata d'Ansidonia",
      "nomeStraniero": null,
      "codiceCatastale": "G992",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comuneprata@yahoo.it",
      "pec": "pratadansidonia@legalmail.it",
      "telefono": "+39 0862/931214",
      "fax": "+39 0862/931125",
      "coordinate": {
        "lat": 42.27888870239258,
        "lng": 13.609167098999023
      }
    },
    {
      "codice": "066075",
      "nome": "Pratola Peligna",
      "nomeStraniero": null,
      "codiceCatastale": "H007",
      "cap": "67035",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "sindaco@comunedipratolapeligna.aq.it",
      "pec": "protocollo@pec.comune.pratolapeligna.aq.it",
      "telefono": "+39 0864/274141",
      "fax": "+39 0864/273280",
      "coordinate": {
        "lat": 42.09916687011719,
        "lng": 13.874722480773926
      }
    },
    {
      "codice": "066076",
      "nome": "Prezza",
      "nomeStraniero": null,
      "codiceCatastale": "H056",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "demografici.prezza@libero.it",
      "pec": "protocollo.comunediprezza@pec.it",
      "telefono": "+39 0864/45138",
      "fax": null,
      "coordinate": {
        "lat": 42.06027603149414,
        "lng": 13.835556030273438
      }
    },
    {
      "codice": "066077",
      "nome": "Raiano",
      "nomeStraniero": null,
      "codiceCatastale": "H166",
      "cap": "67027",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comuneraiano@postecert.it",
      "pec": "comuneraiano@postecert.it",
      "telefono": "+39 0864/72314",
      "fax": "+39 0864726133",
      "coordinate": {
        "lat": 42.10333251953125,
        "lng": 13.815555572509766
      }
    },
    {
      "codice": "066078",
      "nome": "Rivisondoli",
      "nomeStraniero": null,
      "codiceCatastale": "H353",
      "cap": "67036",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comunerivisondoli@libero.it",
      "pec": "postacert@pec.comune.rivisondoli.aq.it",
      "telefono": "+39 0864/69114",
      "fax": "+39 0864/69204",
      "coordinate": {
        "lat": 41.87111282348633,
        "lng": 14.067500114440918
      }
    },
    {
      "codice": "066080",
      "nome": "Rocca di Botte",
      "nomeStraniero": null,
      "codiceCatastale": "H399",
      "cap": "67066",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.roccadibotte.aq.it",
      "pec": "info@pec.comune.roccadibotte.aq.it",
      "telefono": "+39 0863/998131",
      "fax": "+39 0863/998017",
      "coordinate": {
        "lat": 42.0272216796875,
        "lng": 13.068611145019531
      }
    },
    {
      "codice": "066081",
      "nome": "Rocca di Cambio",
      "nomeStraniero": null,
      "codiceCatastale": "H400",
      "cap": "67047",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comuneroccadicambio@virgilio.it",
      "pec": "anagrafe.roccadicambio@legalmail.it",
      "telefono": "+39 0862 9191",
      "fax": "+39 0862/918107",
      "coordinate": {
        "lat": 42.237220764160156,
        "lng": 13.489999771118164
      }
    },
    {
      "codice": "066082",
      "nome": "Rocca di Mezzo",
      "nomeStraniero": null,
      "codiceCatastale": "H402",
      "cap": "67048",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunediroccadimezzo@virgilio.it",
      "pec": "protocollo.roccadimezzo.aq@legalmail.it",
      "telefono": "+39 0862/91121",
      "fax": "+39 0862/917364",
      "coordinate": {
        "lat": 42.205833435058594,
        "lng": 13.520277976989746
      }
    },
    {
      "codice": "066083",
      "nome": "Rocca Pia",
      "nomeStraniero": null,
      "codiceCatastale": "H429",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comune.roccapia@tin.it",
      "pec": "comune.roccapia@pec.arc.it",
      "telefono": "+39 0864/203005",
      "fax": "+39 0864/203006",
      "coordinate": {
        "lat": 41.934444427490234,
        "lng": 13.977777481079102
      }
    },
    {
      "codice": "066079",
      "nome": "Roccacasale",
      "nomeStraniero": null,
      "codiceCatastale": "H389",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "comune@roccacasale.it",
      "pec": "comuneroccacasale@pec.it",
      "telefono": "+39 0864/271878",
      "fax": "+39 0864/272987",
      "coordinate": {
        "lat": 42.1238899230957,
        "lng": 13.88888931274414
      }
    },
    {
      "codice": "066084",
      "nome": "Roccaraso",
      "nomeStraniero": null,
      "codiceCatastale": "H434",
      "cap": "67037",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "protocollo@comune.roccaraso.aq.it",
      "pec": "protocollo.roccaraso@pec.it",
      "telefono": "+39 0864 6192",
      "fax": "+39 0864/6192222",
      "coordinate": {
        "lat": 41.84664535522461,
        "lng": 14.078527450561523
      }
    },
    {
      "codice": "066085",
      "nome": "San Benedetto dei Marsi",
      "nomeStraniero": null,
      "codiceCatastale": "H772",
      "cap": "67058",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.sanbenedettodeimarsi.aq.it",
      "pec": "protocollo.sbmarsi@postecert.it",
      "telefono": "+39 0863/86336",
      "fax": "+39 0863/867955",
      "coordinate": {
        "lat": 42.007598876953125,
        "lng": 13.623809814453125
      }
    },
    {
      "codice": "066086",
      "nome": "San Benedetto in Perillis",
      "nomeStraniero": null,
      "codiceCatastale": "H773",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunedisanbenedettoinpe@virgilio.it",
      "pec": "sanbenedettoinperillis@pec.it",
      "telefono": "+39 0862/955148",
      "fax": "+39 0862/955776",
      "coordinate": {
        "lat": 42.18527603149414,
        "lng": 13.771666526794434
      }
    },
    {
      "codice": "066087",
      "nome": "San Demetrio ne' Vestini",
      "nomeStraniero": null,
      "codiceCatastale": "H819",
      "cap": "67028",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "s.demetrio@katamail.com",
      "pec": "protocollo.sandemetrionv@legalmail.it",
      "telefono": "+39 0862/810834",
      "fax": "+39 0862/810017",
      "coordinate": {
        "lat": 42.29055404663086,
        "lng": 13.556111335754395
      }
    },
    {
      "codice": "066088",
      "nome": "San Pio delle Camere",
      "nomeStraniero": null,
      "codiceCatastale": "I121",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunesanpiodc@2bite.it",
      "pec": "demografici.sanpiodellecamere.aq@legalmail.it",
      "telefono": "+39 0862/931219",
      "fax": "+39 0862/931024",
      "coordinate": {
        "lat": 42.28722381591797,
        "lng": 13.658611297607422
      }
    },
    {
      "codice": "066092",
      "nome": "San Vincenzo Valle Roveto",
      "nomeStraniero": null,
      "codiceCatastale": "I389",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.sanvincenzovalleroveto.aq.it",
      "pec": "info@pec.comune.sanvincenzovalleroveto.aq.it",
      "telefono": "+39 0863/958114",
      "fax": "+39 0863/958369",
      "coordinate": {
        "lat": 41.844520568847656,
        "lng": 13.535659790039062
      }
    },
    {
      "codice": "066090",
      "nome": "Sant'Eusanio Forconese",
      "nomeStraniero": null,
      "codiceCatastale": "I336",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comunesef@tiscalinet.it",
      "pec": "santeusaniof.aq@legalmail.it",
      "telefono": "+39 0862/810850",
      "fax": "+39 0862/810874",
      "coordinate": {
        "lat": 42.288387298583984,
        "lng": 13.52443790435791
      }
    },
    {
      "codice": "066089",
      "nome": "Sante Marie",
      "nomeStraniero": null,
      "codiceCatastale": "I326",
      "cap": "67067",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "comunesantemarie@comunesantemarie.it",
      "pec": "comunedisantemarie@pec.it",
      "telefono": "+39 0863/679132",
      "fax": "+39 0863/679722",
      "coordinate": {
        "lat": 42.10444259643555,
        "lng": 13.20138931274414
      }
    },
    {
      "codice": "066091",
      "nome": "Santo Stefano di Sessanio",
      "nomeStraniero": null,
      "codiceCatastale": "I360",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "anagrafe@comunesantostefanodisessanio.aq.it",
      "pec": "amministrativosessanio@legalmail.it",
      "telefono": "+39 0862/89203",
      "fax": "+39 0862/89662",
      "coordinate": {
        "lat": 42.3426628112793,
        "lng": 13.645966529846191
      }
    },
    {
      "codice": "066093",
      "nome": "Scanno",
      "nomeStraniero": null,
      "codiceCatastale": "I501",
      "cap": "67038",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "scanno.protocollo@tiscali.it",
      "pec": "scannoprotocollo@pec.it",
      "telefono": "+39 0864/74545",
      "fax": "+39 0864/747371",
      "coordinate": {
        "lat": 41.90264129638672,
        "lng": 13.879894256591797
      }
    },
    {
      "codice": "066094",
      "nome": "Scontrone",
      "nomeStraniero": null,
      "codiceCatastale": "I543",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "protocollo@comune.scontrone.aq.it",
      "pec": "comunescontrone@pec.it",
      "telefono": "+39 0864/87149",
      "fax": "+39 0864/870012",
      "coordinate": {
        "lat": 41.74833297729492,
        "lng": 14.040277481079102
      }
    },
    {
      "codice": "066095",
      "nome": "Scoppito",
      "nomeStraniero": null,
      "codiceCatastale": "I546",
      "cap": "67019",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "municipio@comune.scoppito.aq.it",
      "pec": "comunediscoppito@pecpa.it",
      "telefono": "+39 0862 7131",
      "fax": "+39 0862/713220",
      "coordinate": {
        "lat": 42.3738899230957,
        "lng": 13.255833625793457
      }
    },
    {
      "codice": "066096",
      "nome": "Scurcola Marsicana",
      "nomeStraniero": null,
      "codiceCatastale": "I553",
      "cap": "67068",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "urp@comune.scurcolamarsicana.aq.it",
      "pec": "mail@pec.comune.scurcolamarsicana.aq.it",
      "telefono": "+39 0863/562333",
      "fax": "+39 0863/561689",
      "coordinate": {
        "lat": 42.06416702270508,
        "lng": 13.34000015258789
      }
    },
    {
      "codice": "066097",
      "nome": "Secinaro",
      "nomeStraniero": null,
      "codiceCatastale": "I558",
      "cap": "67029",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "sindaco@comunesecinaro.it",
      "pec": "comunedisecinaro@pec.it",
      "telefono": "+39 0864/79302",
      "fax": "+39 0864/797297",
      "coordinate": {
        "lat": 42.15388870239258,
        "lng": 13.681666374206543
      }
    },
    {
      "codice": "066098",
      "nome": "Sulmona",
      "nomeStraniero": null,
      "codiceCatastale": "I804",
      "cap": "67039",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "segreteriadelsindaco@comune.sulmona.aq.it",
      "pec": "comune.sulmona.aq@pec.comnet-ra.it",
      "telefono": "+39 0864/242200",
      "fax": "+39 0864/242298",
      "coordinate": {
        "lat": 42.04802322387695,
        "lng": 13.92619800567627
      }
    },
    {
      "codice": "066099",
      "nome": "Tagliacozzo",
      "nomeStraniero": null,
      "codiceCatastale": "L025",
      "cap": "67069",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "anagrafe@comune.tagliacozzo.aq.it",
      "pec": "comune.tagliacozzo@mailcertificata.it",
      "telefono": "+39 0863/614220-218",
      "fax": "+39 0863/614218",
      "coordinate": {
        "lat": 42.06944274902344,
        "lng": 13.254722595214844
      }
    },
    {
      "codice": "066100",
      "nome": "Tione degli Abruzzi",
      "nomeStraniero": null,
      "codiceCatastale": "L173",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "protocollo@comune.tionedegliabruzzi.aq.it",
      "pec": "demografici.tionedegliabruzzi.aq@legalmail.it",
      "telefono": "+39 0862/88107",
      "fax": "+39 0862/880007",
      "coordinate": {
        "lat": 42.20500183105469,
        "lng": 13.636666297912598
      }
    },
    {
      "codice": "066101",
      "nome": "Tornimparte",
      "nomeStraniero": null,
      "codiceCatastale": "L227",
      "cap": "67049",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "protocollo@comune.tornimparte.aq.it",
      "pec": "segreteria@pec.comune.tornimparte.aq.it",
      "telefono": "+39 0862/72372",
      "fax": "+39 0862/728445",
      "coordinate": {
        "lat": 42.290069580078125,
        "lng": 13.300919532775879
      }
    },
    {
      "codice": "066102",
      "nome": "Trasacco",
      "nomeStraniero": null,
      "codiceCatastale": "L334",
      "cap": "67059",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": null,
      "pec": "comune.trasacco@pec.it",
      "telefono": "+39 0863/93121",
      "fax": "+39 0863/937014",
      "coordinate": {
        "lat": 41.95777893066406,
        "lng": 13.535277366638184
      }
    },
    {
      "codice": "066105",
      "nome": "Villa Sant'Angelo",
      "nomeStraniero": null,
      "codiceCatastale": "M023",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "vsangelo@tiscali.it",
      "pec": "comunevillasantangelo@pec.it",
      "telefono": "+39 0862/810969",
      "fax": "+39 0862/020537",
      "coordinate": {
        "lat": 42.27138900756836,
        "lng": 13.538055419921875
      }
    },
    {
      "codice": "066104",
      "nome": "Villa Santa Lucia degli Abruzzi",
      "nomeStraniero": null,
      "codiceCatastale": "M021",
      "cap": "67020",
      "prefisso": "0862",
      "provincia": "L'Aquila",
      "email": "comune.villasantaluciaabruzzi@inwind.it",
      "pec": "sindaco.villasantalucia.aq@legalmail.it",
      "telefono": "+39 0862/956110",
      "fax": "+39 0862/956720",
      "coordinate": {
        "lat": 42.33416748046875,
        "lng": 13.777777671813965
      }
    },
    {
      "codice": "066103",
      "nome": "Villalago",
      "nomeStraniero": null,
      "codiceCatastale": "L958",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "sindaco@comunevillalago.it",
      "pec": "anagrafecomunevillalago@pec.it",
      "telefono": "+39 0864/740134",
      "fax": "+39 0864/740154",
      "coordinate": {
        "lat": 41.93583297729492,
        "lng": 13.842778205871582
      }
    },
    {
      "codice": "066106",
      "nome": "Villavallelonga",
      "nomeStraniero": null,
      "codiceCatastale": "M031",
      "cap": "67050",
      "prefisso": "0863",
      "provincia": "L'Aquila",
      "email": "info@comune.villavallelonga.aq.it",
      "pec": "protocollo.comunedivillavallelonga@pec.it",
      "telefono": "+39 0863/949117",
      "fax": "+39 0863/949600",
      "coordinate": {
        "lat": 41.872501373291016,
        "lng": 13.622221946716309
      }
    },
    {
      "codice": "066107",
      "nome": "Villetta Barrea",
      "nomeStraniero": null,
      "codiceCatastale": "M041",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "info@comune.villettabarrea.aq.it",
      "pec": "comune.villettabarrea.aq@pec.comnet-ra.it",
      "telefono": "+39 0864/89134",
      "fax": "+39 0864/89245",
      "coordinate": {
        "lat": 41.776668548583984,
        "lng": 13.939444541931152
      }
    },
    {
      "codice": "066108",
      "nome": "Vittorito",
      "nomeStraniero": null,
      "codiceCatastale": "M090",
      "cap": "67030",
      "prefisso": "0864",
      "provincia": "L'Aquila",
      "email": "servizidemograficiamministrativi@comune.vittorito.aq.it",
      "pec": "demografici.comunedivittorito@pec.it",
      "telefono": "+39 0864/727100",
      "fax": "+39 0864/727366",
      "coordinate": {
        "lat": 42.128055572509766,
        "lng": 13.817500114440918
      }
    }
  ],
  "Rieti": [
    {
      "codice": "057001",
      "nome": "Accumoli",
      "nomeStraniero": null,
      "codiceCatastale": "A019",
      "cap": "02011",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "segretariocomunale@accumoli.ri.it",
      "pec": "comune.accumoli@pec.it",
      "telefono": "+39 0746/80936",
      "fax": "+39 0746/80411",
      "coordinate": {
        "lat": 42.69444274902344,
        "lng": 13.2475004196167
      }
    },
    {
      "codice": "057002",
      "nome": "Amatrice",
      "nomeStraniero": null,
      "codiceCatastale": "A258",
      "cap": "02012",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "uff.protocollo@comune.amatrice.rieti.it",
      "pec": "protocollo@pec.comune.amatrice.rieti.it",
      "telefono": "+39 07468308207",
      "fax": null,
      "coordinate": {
        "lat": 42.62937927246094,
        "lng": 13.288372039794922
      }
    },
    {
      "codice": "057003",
      "nome": "Antrodoco",
      "nomeStraniero": null,
      "codiceCatastale": "A315",
      "cap": "02013",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "areademografica@comunediantrodoco.it",
      "pec": "comunediantrodoco@legalmail.it",
      "telefono": "+39 0746578185",
      "fax": "+39 0746578623",
      "coordinate": {
        "lat": 42.41666793823242,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "057004",
      "nome": "Ascrea",
      "nomeStraniero": null,
      "codiceCatastale": "A464",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "com.ascrea@libero.it",
      "pec": "comune.ascrea.ri@legalmail.it",
      "telefono": "+39 0765723112",
      "fax": "+39 0765723242",
      "coordinate": {
        "lat": 42.19694519042969,
        "lng": 12.9975004196167
      }
    },
    {
      "codice": "057005",
      "nome": "Belmonte in Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "A765",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "belmonteinsabina@tiscali.it",
      "pec": "comune.belmonteinsabina.ri@pec.it",
      "telefono": "+39 076577000",
      "fax": "+39 076577277",
      "coordinate": {
        "lat": 42.31666564941406,
        "lng": 12.899999618530273
      }
    },
    {
      "codice": "057006",
      "nome": "Borbona",
      "nomeStraniero": null,
      "codiceCatastale": "A981",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "borbona@comune.borbona.rieti.it",
      "pec": "tributi.borbona@anutel.it",
      "telefono": "+39 0746940037",
      "fax": "+39 0746940285",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 13.133333206176758
      }
    },
    {
      "codice": "057008",
      "nome": "Borgo Velino",
      "nomeStraniero": null,
      "codiceCatastale": "A996",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comune.borgovelino@libero.it",
      "pec": "comune.borgovelino.ri@pec.it",
      "telefono": "+39 0746 578896-99",
      "fax": "+39 0746 578525",
      "coordinate": {
        "lat": 42.400001525878906,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "057007",
      "nome": "Borgorose",
      "nomeStraniero": null,
      "codiceCatastale": "B008",
      "cap": "02021",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "p.tanzi@comune.borgorose.ri.it",
      "pec": "comuneborgorose@pec.it",
      "telefono": "+39 0746314801",
      "fax": "+39 0746314935",
      "coordinate": {
        "lat": 42.20000076293945,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "057009",
      "nome": "Cantalice",
      "nomeStraniero": null,
      "codiceCatastale": "B627",
      "cap": "02014",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comdicanta@libero.it",
      "pec": "protocollo@pec.comune.cantalice.ri.it",
      "telefono": "+39 0746653119",
      "fax": "+39 0746653951",
      "coordinate": {
        "lat": 42.46666717529297,
        "lng": 12.899999618530273
      }
    },
    {
      "codice": "057010",
      "nome": "Cantalupo in Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "B631",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "ragioneria@comune.cantalupoinsabina.ri.it",
      "pec": "comune.cantalupoinsabina@anutel.it",
      "telefono": "+39 0765514031",
      "fax": "+39 0765514667",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 12.649999618530273
      }
    },
    {
      "codice": "057011",
      "nome": "Casaprota",
      "nomeStraniero": null,
      "codiceCatastale": "B934",
      "cap": "02030",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.casaprota@tiscali.it",
      "pec": "comunecasaprota@pec.it",
      "telefono": "+39 076585005",
      "fax": "+39 076585253",
      "coordinate": {
        "lat": 42.25,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "057012",
      "nome": "Casperia",
      "nomeStraniero": null,
      "codiceCatastale": "A472",
      "cap": "02041",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comunedicasperia@libero.it",
      "pec": "comune.casperia.ri@legalmail.it",
      "telefono": "+39 0765.63026",
      "fax": "+39 0765.63710",
      "coordinate": {
        "lat": 42.33333206176758,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "057013",
      "nome": "Castel di Tora",
      "nomeStraniero": null,
      "codiceCatastale": "C098",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "info@comune.castelditora.ri.it",
      "pec": "protocollo@pec.comune.castelditora.ri.it",
      "telefono": "+39 0765716313",
      "fax": "+39 0765716266",
      "coordinate": {
        "lat": 42.21492385864258,
        "lng": 12.963589668273926
      }
    },
    {
      "codice": "057015",
      "nome": "Castel Sant'Angelo",
      "nomeStraniero": null,
      "codiceCatastale": "C268",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "castel.santangelo@libero.it",
      "pec": "comune.castelsantangelo.ri@legalmail.it",
      "telefono": "+39 0746 698043",
      "fax": "+39 0746 698187",
      "coordinate": {
        "lat": 42.400001525878906,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "057014",
      "nome": "Castelnuovo di Farfa",
      "nomeStraniero": null,
      "codiceCatastale": "C224",
      "cap": "02031",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "castelnuovodifarfa@libero.it",
      "pec": "comune.castelnuovodifarfa@pcert.it",
      "telefono": "+39 076536131",
      "fax": "+39 076536233",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 12.75
      }
    },
    {
      "codice": "057016",
      "nome": "Cittaducale",
      "nomeStraniero": null,
      "codiceCatastale": "C746",
      "cap": "02015",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "ufficioprotocollo@comune.cittaducale.ri.it",
      "pec": "comunecittaducale@viapec.net",
      "telefono": "+39 0746.60801",
      "fax": "+39 0746.602800",
      "coordinate": {
        "lat": 42.38333511352539,
        "lng": 12.949999809265137
      }
    },
    {
      "codice": "057017",
      "nome": "Cittareale",
      "nomeStraniero": null,
      "codiceCatastale": "C749",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comune.cittareale@libero.it",
      "pec": "comune.cittareale@pec.it",
      "telefono": "+39 0746/947032",
      "fax": "+39 0746/947033",
      "coordinate": {
        "lat": 42.61666488647461,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "057018",
      "nome": "Collalto Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "C841",
      "cap": "02022",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "com.collalto.sabino@libero.it",
      "pec": "comunedicollalto@pec.it",
      "telefono": "+39 076598166",
      "fax": "+39 076598192",
      "coordinate": {
        "lat": 42.13669967651367,
        "lng": 13.048399925231934
      }
    },
    {
      "codice": "057019",
      "nome": "Colle di Tora",
      "nomeStraniero": null,
      "codiceCatastale": "C857",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "colleditora@tiscali.it",
      "pec": "comunecolleditora@arubapec.it",
      "telefono": "+39 0765/716139",
      "fax": "+39 0765/716065",
      "coordinate": {
        "lat": 42.21174240112305,
        "lng": 12.947669982910156
      }
    },
    {
      "codice": "057020",
      "nome": "Collegiove",
      "nomeStraniero": null,
      "codiceCatastale": "C859",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "segreteria@comunecollegiove.it",
      "pec": "comune.collegiove.ri@legalmail.it",
      "telefono": "+39 076599049",
      "fax": null,
      "coordinate": {
        "lat": 42.18333435058594,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "057021",
      "nome": "Collevecchio",
      "nomeStraniero": null,
      "codiceCatastale": "C876",
      "cap": "02042",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "protocollo@comune.collevecchio.ri.it",
      "pec": "info@pec.comune.collevecchio.ri.it",
      "telefono": "+39 0765578018",
      "fax": null,
      "coordinate": {
        "lat": 42.33367919921875,
        "lng": 12.553290367126465
      }
    },
    {
      "codice": "057022",
      "nome": "Colli sul Velino",
      "nomeStraniero": null,
      "codiceCatastale": "C880",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comunecollisulvelino@libero.it",
      "pec": "amministrazione@pec.collisulvelino.org",
      "telefono": "+39 0746636101",
      "fax": "+39 0746644156",
      "coordinate": {
        "lat": 42.5,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "057023",
      "nome": "Concerviano",
      "nomeStraniero": null,
      "codiceCatastale": "C946",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.concerviano@libero.it",
      "pec": "comune.concerviano@registerpec.it",
      "telefono": "+39 0765/714020",
      "fax": "+39 0765/714020",
      "coordinate": {
        "lat": 42.31666564941406,
        "lng": 12.983333587646484
      }
    },
    {
      "codice": "057024",
      "nome": "Configni",
      "nomeStraniero": null,
      "codiceCatastale": "C959",
      "cap": "02040",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "configni@tin.it",
      "pec": "anagrafe.configni@legalmail.it",
      "telefono": "+39 0746672208",
      "fax": "+39 0746672208",
      "coordinate": {
        "lat": 42.42543029785156,
        "lng": 12.644309997558594
      }
    },
    {
      "codice": "057025",
      "nome": "Contigliano",
      "nomeStraniero": null,
      "codiceCatastale": "C969",
      "cap": "02043",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "sedebassa@comune.contigliano.ri.it",
      "pec": "sedebassa@legalmail.it",
      "telefono": "+39 0746707362",
      "fax": "+39 0746706023",
      "coordinate": {
        "lat": 42.41666793823242,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "057026",
      "nome": "Cottanello",
      "nomeStraniero": null,
      "codiceCatastale": "D124",
      "cap": "02040",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comune.cottanello@tiscali.it",
      "pec": "suap.cottanello.ri@legalmail.it",
      "telefono": "+39 0746/66122",
      "fax": "+39 0746/66280",
      "coordinate": {
        "lat": 42.40583419799805,
        "lng": 12.686111450195312
      }
    },
    {
      "codice": "057027",
      "nome": "Fara in Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "D493",
      "cap": "02032",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "segreteria@comunefarainsabina.rieti.it",
      "pec": "ufficioprotocollo@comunefarainsabina.pecpa.it",
      "telefono": "+39 0765/2779240",
      "fax": null,
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "057028",
      "nome": "Fiamignano",
      "nomeStraniero": null,
      "codiceCatastale": "D560",
      "cap": "02023",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "info@comune.fiamignano.ri.it",
      "pec": "comunedifiamignano@pec.it",
      "telefono": "+39 074653016 / 17",
      "fax": "+39 074653029",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 13.133333206176758
      }
    },
    {
      "codice": "057029",
      "nome": "Forano",
      "nomeStraniero": null,
      "codiceCatastale": "D689",
      "cap": "02044",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.forano@i2000net.it",
      "pec": "comune.forano.ri@pec.i2000net.it",
      "telefono": "+39 0765570020",
      "fax": "+39 0765570684",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 12.600000381469727
      }
    },
    {
      "codice": "057030",
      "nome": "Frasso Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "D785",
      "cap": "02030",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "servizidemografici@comune.frassosabino.ri.it",
      "pec": "protocollo@pec.comune.frassosabino.ri.it",
      "telefono": "+39 0765872004",
      "fax": "+39 0765873114",
      "coordinate": {
        "lat": 42.2297248840332,
        "lng": 12.80496883392334
      }
    },
    {
      "codice": "057031",
      "nome": "Greccio",
      "nomeStraniero": null,
      "codiceCatastale": "E160",
      "cap": "02040",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "info@comune.greccio.ri.it",
      "pec": "segreteria.greccio.ri@legalmail.it",
      "telefono": "+39 0746 750591",
      "fax": "+39 0746 750587",
      "coordinate": {
        "lat": 42.45000076293945,
        "lng": 12.75
      }
    },
    {
      "codice": "057032",
      "nome": "Labro",
      "nomeStraniero": null,
      "codiceCatastale": "E393",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comune.labro@libero.it",
      "pec": "comune@pec.labro.gov.it",
      "telefono": "+39 0746636134",
      "fax": "+39 0746636300",
      "coordinate": {
        "lat": 42.52603530883789,
        "lng": 12.800278663635254
      }
    },
    {
      "codice": "057033",
      "nome": "Leonessa",
      "nomeStraniero": null,
      "codiceCatastale": "E535",
      "cap": "02016",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "ufficio.anagrafelettorale@comunedileonessa.it",
      "pec": "comune.leonessa@pec.it",
      "telefono": "+39 0746923212",
      "fax": "+39 0746923219",
      "coordinate": {
        "lat": 42.56855010986328,
        "lng": 12.959959983825684
      }
    },
    {
      "codice": "057034",
      "nome": "Longone Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "E681",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.longone@libero.it",
      "pec": "comunelongonesabino@pec.it",
      "telefono": "+39 0765718901",
      "fax": "+39 0765718840",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "057035",
      "nome": "Magliano Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "E812",
      "cap": "02046",
      "prefisso": "0744",
      "provincia": "Rieti",
      "email": "protocollo@comune.maglianosabina.ri.it",
      "pec": "info@pec.comune.maglianosabina.ri.it",
      "telefono": "+39 0744910336",
      "fax": null,
      "coordinate": {
        "lat": 42.36666488647461,
        "lng": 12.483333587646484
      }
    },
    {
      "codice": "057036",
      "nome": "Marcetelli",
      "nomeStraniero": null,
      "codiceCatastale": "E927",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.marcetelli@tiscali.it",
      "pec": "comune@pec.marcetelli.gov.it",
      "telefono": "+39 0765798072",
      "fax": "+39 0765798082",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 13.050000190734863
      }
    },
    {
      "codice": "057037",
      "nome": "Micigliano",
      "nomeStraniero": null,
      "codiceCatastale": "F193",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comunedimicigliano@libero.it",
      "pec": "comune.micigliano@legalmail.it",
      "telefono": "+39 0746577893",
      "fax": "+39 0746577893",
      "coordinate": {
        "lat": 42.45000076293945,
        "lng": 13.050000190734863
      }
    },
    {
      "codice": "057038",
      "nome": "Mompeo",
      "nomeStraniero": null,
      "codiceCatastale": "F319",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.mompeo@libero.it",
      "pec": "comunemompeo@pec.it",
      "telefono": "+39 0765.469028",
      "fax": "+39 0765.469052",
      "coordinate": {
        "lat": 42.249000549316406,
        "lng": 12.751799583435059
      }
    },
    {
      "codice": "057039",
      "nome": "Montasola",
      "nomeStraniero": null,
      "codiceCatastale": "F430",
      "cap": "02040",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comunemontasola@libero.it",
      "pec": "segreteria.montasola.ri@legalmail.it",
      "telefono": "+39 0746675177",
      "fax": "+39 0746675188",
      "coordinate": {
        "lat": 42.38333511352539,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "057043",
      "nome": "Monte San Giovanni in Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "F619",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comunemsg@tiscalinet.it",
      "pec": "amministrazione.comunemontesangiovanniinsabina@pec.it",
      "telefono": "+39 0765333312",
      "fax": "+39 0765333013",
      "coordinate": {
        "lat": 42.33333206176758,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "057040",
      "nome": "Montebuono",
      "nomeStraniero": null,
      "codiceCatastale": "F446",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.montebuono@libero.it",
      "pec": "info@pec.comune.montebuono.ri.it",
      "telefono": "+39 0765607631",
      "fax": "+39 0765607131",
      "coordinate": {
        "lat": 42.367698669433594,
        "lng": 12.597209930419922
      }
    },
    {
      "codice": "057041",
      "nome": "Monteleone Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "F541",
      "cap": "02033",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.leo@tiscali.it",
      "pec": "ragioneria@pec.comune.monteleonesabino.ri.it",
      "telefono": "+39 0765884014",
      "fax": "+39 0765884340",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "057042",
      "nome": "Montenero Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "F579",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.montenero@libero.it",
      "pec": "info@pec.comune.montenerosabino.ri.it",
      "telefono": "+39 0765324012",
      "fax": "+39 0765324143",
      "coordinate": {
        "lat": 42.28333282470703,
        "lng": 12.816666603088379
      }
    },
    {
      "codice": "057044",
      "nome": "Montopoli di Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "F687",
      "cap": "02034",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "ufficio.protocollo@comune.montopolidisabina.ri.it",
      "pec": "uffprotocollo@comunemontopolidisabina.pecpa.it",
      "telefono": "+39 076527611",
      "fax": "+39 0765276127",
      "coordinate": {
        "lat": 42.25,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "057045",
      "nome": "Morro Reatino",
      "nomeStraniero": null,
      "codiceCatastale": "F746",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "seg.morroreatino@libero.it",
      "pec": "comune@pec.comune.morroreatino.ri.it",
      "telefono": "+39 0746638031",
      "fax": "+39 0746638089",
      "coordinate": {
        "lat": 42.53333282470703,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "057046",
      "nome": "Nespolo",
      "nomeStraniero": null,
      "codiceCatastale": "F876",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "segreteria@comunedinespolo.it",
      "pec": "comunedinespolo@pec.it",
      "telefono": "+39 076598026",
      "fax": "+39 0765988811",
      "coordinate": {
        "lat": 42.150001525878906,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "057047",
      "nome": "Orvinio",
      "nomeStraniero": null,
      "codiceCatastale": "B595",
      "cap": "02035",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comuneorvinio@libero.it",
      "pec": "segreteria.orvinio.ri@legalmail.it",
      "telefono": "+39 076592007",
      "fax": "+39 07659430885",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "057048",
      "nome": "Paganico Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "G232",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comunepaganicosabino@tiscali.it",
      "pec": "comunepaganicosabino@pec.it",
      "telefono": "+39 0765 723032",
      "fax": "+39 0765 723033",
      "coordinate": {
        "lat": 42.190555572509766,
        "lng": 12.997777938842773
      }
    },
    {
      "codice": "057049",
      "nome": "Pescorocchiano",
      "nomeStraniero": null,
      "codiceCatastale": "G498",
      "cap": "02024",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comunepescorocchiano@libero.it",
      "pec": "info@pec.comune.pescorocchiano.rieti.it",
      "telefono": "+39 0746.338270",
      "fax": "+39 0746.338047",
      "coordinate": {
        "lat": 42.20663070678711,
        "lng": 13.147846221923828
      }
    },
    {
      "codice": "057050",
      "nome": "Petrella Salto",
      "nomeStraniero": null,
      "codiceCatastale": "G513",
      "cap": "02025",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "info@comune.petrellasalto.ri.it",
      "pec": "info@pec.comune.petrellasalto.ri.it",
      "telefono": "+39 0746521021 - 521022",
      "fax": "+39 0746521993",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "057051",
      "nome": "Poggio Bustone",
      "nomeStraniero": null,
      "codiceCatastale": "G756",
      "cap": "02018",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "info@comune.poggiobustone.ri.it",
      "pec": "comune.poggiobustone@legalmail.it",
      "telefono": "+39 0746688913",
      "fax": "+39 074668252",
      "coordinate": {
        "lat": 42.5,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "057052",
      "nome": "Poggio Catino",
      "nomeStraniero": null,
      "codiceCatastale": "G757",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "anagrafe@comune.poggiocatino.ri.it",
      "pec": "protocollo.poggiocatino@pec.it",
      "telefono": "+39 0765411021",
      "fax": "+39 0765411279",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 12.699999809265137
      }
    },
    {
      "codice": "057053",
      "nome": "Poggio Mirteto",
      "nomeStraniero": null,
      "codiceCatastale": "G763",
      "cap": "02047",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "segreteriapm@i2000net.it",
      "pec": "posta@pec.comune.poggiomirteto.ri.it",
      "telefono": "+39 0765405203",
      "fax": "+39 076522350",
      "coordinate": {
        "lat": 42.2672233581543,
        "lng": 12.688611030578613
      }
    },
    {
      "codice": "057054",
      "nome": "Poggio Moiano",
      "nomeStraniero": null,
      "codiceCatastale": "G764",
      "cap": "02037",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.protocollo@poggiomoiano.com",
      "pec": "comunepoggiomoiano@pec.aruba.it",
      "telefono": "+39 0765876023",
      "fax": "+39 0765876759",
      "coordinate": {
        "lat": 42.20000076293945,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "057055",
      "nome": "Poggio Nativo",
      "nomeStraniero": null,
      "codiceCatastale": "G765",
      "cap": "02030",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "demografici@comune.poggionativo.ri.it",
      "pec": "comune@pec.comune.poggionativo.ri.it",
      "telefono": "+39 0765872025",
      "fax": "+39 0765872764",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "057056",
      "nome": "Poggio San Lorenzo",
      "nomeStraniero": null,
      "codiceCatastale": "G770",
      "cap": "02030",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "a.aureli@comune.poggiosanlorenzo.ri.it",
      "pec": "demografico@pec.comune.poggiosanlorenzo.ri.it",
      "telefono": "+39 0765880312",
      "fax": "+39 07651930100",
      "coordinate": {
        "lat": 42.25,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "057057",
      "nome": "Posta",
      "nomeStraniero": null,
      "codiceCatastale": "G934",
      "cap": "02019",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comuneposta@libero.it",
      "pec": "segreteria.posta.ri@legalmail.it",
      "telefono": "+39 0746951313",
      "fax": "+39 0746951258",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "057058",
      "nome": "Pozzaglia Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "G951",
      "cap": "02030",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.pozzaglia@virgilio.it",
      "pec": "segreteria.pozzagliasabina.ri@legalmail.it",
      "telefono": "+39 0765934016",
      "fax": "+39 0765934155",
      "coordinate": {
        "lat": 42.16666793823242,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "057059",
      "nome": "Rieti",
      "nomeStraniero": null,
      "codiceCatastale": "H282",
      "cap": "02100",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "padula@comune.rieti.it",
      "pec": "protocollo@pec.comune.rieti.it",
      "telefono": "+39 0746287248",
      "fax": "+39 0746252595",
      "coordinate": {
        "lat": 42.4044303894043,
        "lng": 12.856701850891113
      }
    },
    {
      "codice": "057060",
      "nome": "Rivodutri",
      "nomeStraniero": null,
      "codiceCatastale": "H354",
      "cap": "02010",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "sindaco@comune.rivodutri.ri.it",
      "pec": "comune.rivodutri@legalmail.it",
      "telefono": "+39 0746685612",
      "fax": "+39 0746685485",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "057062",
      "nome": "Rocca Sinibalda",
      "nomeStraniero": null,
      "codiceCatastale": "H446",
      "cap": "02026",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "roccasinibalda@tiscali.it",
      "pec": "segreteria.roccasinibalda.ri@legalmail.it",
      "telefono": "+39 0765708001",
      "fax": "+39 0765709149",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "057061",
      "nome": "Roccantica",
      "nomeStraniero": null,
      "codiceCatastale": "H427",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "segreteria@comunediroccantica.it",
      "pec": "comunediroccantica@pec.it",
      "telefono": "+39 0765 63020",
      "fax": "+39 0765 63809",
      "coordinate": {
        "lat": 42.31666564941406,
        "lng": 12.699999809265137
      }
    },
    {
      "codice": "057063",
      "nome": "Salisano",
      "nomeStraniero": null,
      "codiceCatastale": "H713",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comunesalisano@libero.it",
      "pec": "comune.salisano@pec.it",
      "telefono": "+39 0765465029",
      "fax": "+39 0765465233",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 12.75
      }
    },
    {
      "codice": "057064",
      "nome": "Scandriglia",
      "nomeStraniero": null,
      "codiceCatastale": "I499",
      "cap": "02038",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "scandrigliacomune@tiscali.it",
      "pec": "protocollo@pec.comune.scandriglia.ri.it",
      "telefono": "+39 0765878037",
      "fax": "+39 0765878467",
      "coordinate": {
        "lat": 42.16666793823242,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "057065",
      "nome": "Selci",
      "nomeStraniero": null,
      "codiceCatastale": "I581",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.selci@libero.it",
      "pec": "comune.selci.ri@legalmail.it",
      "telefono": "+39 0765519138",
      "fax": "+39 0765519247",
      "coordinate": {
        "lat": 42.31666564941406,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "057066",
      "nome": "Stimigliano",
      "nomeStraniero": null,
      "codiceCatastale": "I959",
      "cap": "02048",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "amministrativo@comune.stimigliano.ri.it",
      "pec": "com.stim@pec.it",
      "telefono": "+39 0765576038",
      "fax": "+39 0765576078",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 12.566666603088379
      }
    },
    {
      "codice": "057067",
      "nome": "Tarano",
      "nomeStraniero": null,
      "codiceCatastale": "L046",
      "cap": "02040",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "infoweb@comune.tarano.ri.it",
      "pec": "comunetarano@pec.it",
      "telefono": "+39 0765607331",
      "fax": "+39 0765607099",
      "coordinate": {
        "lat": 42.349998474121094,
        "lng": 12.600000381469727
      }
    },
    {
      "codice": "057068",
      "nome": "Toffia",
      "nomeStraniero": null,
      "codiceCatastale": "L189",
      "cap": "02039",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comuneditoffia@libero.it",
      "pec": "comunicazione@pec.comune.toffia.ri.it",
      "telefono": "+39 0765326032",
      "fax": "+39 0765326375",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 12.75
      }
    },
    {
      "codice": "057070",
      "nome": "Torri in Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "L286",
      "cap": "02049",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "torriinsabina@tiscali.it",
      "pec": "segreteria.torriinsabina.ri@legalmail.it",
      "telefono": "+39 076562004",
      "fax": "+39 076562012",
      "coordinate": {
        "lat": 42.349998474121094,
        "lng": 12.633333206176758
      }
    },
    {
      "codice": "057069",
      "nome": "Torricella in Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "L293",
      "cap": "02030",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "anagrafe@comune.torricellainsabina.ri.it",
      "pec": "torricella@pec.comune.torricellainsabina.ri.it",
      "telefono": "+39 0765735021",
      "fax": null,
      "coordinate": {
        "lat": 42.26194381713867,
        "lng": 12.869722366333008
      }
    },
    {
      "codice": "057071",
      "nome": "Turania",
      "nomeStraniero": null,
      "codiceCatastale": "G507",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comuneturania@tin.it",
      "pec": "comunturania@pec.it",
      "telefono": "+39 0765 935021",
      "fax": "+39 0765 935621",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "057072",
      "nome": "Vacone",
      "nomeStraniero": null,
      "codiceCatastale": "L525",
      "cap": "02040",
      "prefisso": "0746",
      "provincia": "Rieti",
      "email": "comunevacone@virgilio.it",
      "pec": "segreteria.vacone.ri@legalmail.it",
      "telefono": "+39 0746676833",
      "fax": "+39 0746676930",
      "coordinate": {
        "lat": 42.38333511352539,
        "lng": 12.649999618530273
      }
    },
    {
      "codice": "057073",
      "nome": "Varco Sabino",
      "nomeStraniero": null,
      "codiceCatastale": "L676",
      "cap": "02020",
      "prefisso": "0765",
      "provincia": "Rieti",
      "email": "comune.varcosabino@libero.it",
      "pec": "comune.varcosabino.ri@legalmail.it",
      "telefono": "+39 0765790043",
      "fax": "+39 0765790025",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 13.016666412353516
      }
    }
  ],
  "Salerno": [
    {
      "codice": "065001",
      "nome": "Acerno",
      "nomeStraniero": null,
      "codiceCatastale": "A023",
      "cap": "84042",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "protocollo@comune.acerno.sa.it",
      "pec": "protocollo@pec.comune.acerno.sa.it",
      "telefono": "+39 089/9821211",
      "fax": "+39 089/9821227",
      "coordinate": {
        "lat": 40.75,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "065002",
      "nome": "Agropoli",
      "nomeStraniero": null,
      "codiceCatastale": "A091",
      "cap": "84043",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "anagrafe@comune.agropoli.sa.it",
      "pec": "demografici@pec.comune.agropoli.sa.it",
      "telefono": "+39 0974/827411",
      "fax": "+39 0974/827145",
      "coordinate": {
        "lat": 40.358333587646484,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "065003",
      "nome": "Albanella",
      "nomeStraniero": null,
      "codiceCatastale": "A128",
      "cap": "84044",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "amministrativo@comune.albanella.sa.it",
      "pec": "protocollo@pec.comune.albanella.sa.it",
      "telefono": "+39 0828/781126",
      "fax": "+39 0828/781602",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "065004",
      "nome": "Alfano",
      "nomeStraniero": null,
      "codiceCatastale": "A186",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.alfano.sa.it",
      "pec": "affarigenerali.alfano@asmepec.it",
      "telefono": "+39 0974/956095",
      "fax": "+39 0974/956095",
      "coordinate": {
        "lat": 40.177459716796875,
        "lng": 15.424596786499023
      }
    },
    {
      "codice": "065005",
      "nome": "Altavilla Silentina",
      "nomeStraniero": null,
      "codiceCatastale": "A230",
      "cap": "84045",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "anagrafe.altavilla@tiscali.it",
      "pec": "elettorale.altavillasilentina@asmepec.it",
      "telefono": "+39 0828/983811",
      "fax": "+39 0828/983021",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "065006",
      "nome": "Amalfi",
      "nomeStraniero": null,
      "codiceCatastale": "A251",
      "cap": "84011",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "ufficidemografici@comune.amalfi.sa.it",
      "pec": "ufficidemografici.amalfi@asmepec.it",
      "telefono": "+39 0898736201",
      "fax": "+39 089/8736216",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 14.602777481079102
      }
    },
    {
      "codice": "065007",
      "nome": "Angri",
      "nomeStraniero": null,
      "codiceCatastale": "A294",
      "cap": "84012",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "info@comune.angri.sa.it",
      "pec": "comune.angri@legalmail.it",
      "telefono": "+39 081 5168111",
      "fax": "+39 081 5168222",
      "coordinate": {
        "lat": 40.74305725097656,
        "lng": 14.56944465637207
      }
    },
    {
      "codice": "065008",
      "nome": "Aquara",
      "nomeStraniero": null,
      "codiceCatastale": "A343",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.aquara.sa.it",
      "pec": "protocollo.amministrazione@pec.comune.aquara.sa.it",
      "telefono": "+39 0828/962003",
      "fax": "+39 0828/962110",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 15.25
      }
    },
    {
      "codice": "065009",
      "nome": "Ascea",
      "nomeStraniero": null,
      "codiceCatastale": "A460",
      "cap": "84046",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "demograficoascea@libero.it",
      "pec": "protocollo@pec.comune.ascea.sa.it",
      "telefono": "+39 0974/972727",
      "fax": "+39 0974/977308",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 15.183333396911621
      }
    },
    {
      "codice": "065010",
      "nome": "Atena Lucana",
      "nomeStraniero": null,
      "codiceCatastale": "A484",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "ufficioelettorale@comune.atenalucana.sa.it",
      "pec": "prot.atena@asmepec.it",
      "telefono": "+39 0975/76001",
      "fax": "+39 0975/76022",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 15.550000190734863
      }
    },
    {
      "codice": "065011",
      "nome": "Atrani",
      "nomeStraniero": null,
      "codiceCatastale": "A487",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "protocollo@comune.atrani.sa.it",
      "pec": "protocollo.atrani@asmepec.it",
      "telefono": "+39 089/871185",
      "fax": "+39 089/871484",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "065012",
      "nome": "Auletta",
      "nomeStraniero": null,
      "codiceCatastale": "A495",
      "cap": "84031",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "comuneauletta@tiscali.it",
      "pec": "uffsegreteria.auletta@asmepec.it",
      "telefono": "+39 0975/392256",
      "fax": "+39 0975/392963",
      "coordinate": {
        "lat": 40.56111145019531,
        "lng": 15.424444198608398
      }
    },
    {
      "codice": "065013",
      "nome": "Baronissi",
      "nomeStraniero": null,
      "codiceCatastale": "A674",
      "cap": "84081",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "servizidemografici@comune.baronissi.sa.it",
      "pec": "affarilegali@pec.comune.baronissi.sa.it",
      "telefono": "+39 089/828211",
      "fax": "+39 089/828252",
      "coordinate": {
        "lat": 40.75,
        "lng": 14.779999732971191
      }
    },
    {
      "codice": "065014",
      "nome": "Battipaglia",
      "nomeStraniero": null,
      "codiceCatastale": "A717",
      "cap": "84091",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "protocollo@pec.comune.battipaglia.sa.it",
      "pec": "protocollo@pec.comune.battipaglia.sa.it",
      "telefono": "+39 0828/677111",
      "fax": "+39 0828/346310",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "065158",
      "nome": "Bellizzi",
      "nomeStraniero": null,
      "codiceCatastale": "M294",
      "cap": "84092",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.bellizzi.sa.it",
      "pec": "protocollo@pec.comune.bellizzi.sa.it",
      "telefono": "+39 0828/358011",
      "fax": "+39 0828/355849",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "065015",
      "nome": "Bellosguardo",
      "nomeStraniero": null,
      "codiceCatastale": "A756",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "protocollo@comune.bellosguardo.sa.it",
      "pec": "comune.bellosguardo@asmepec.it",
      "telefono": "+39 0828/965026",
      "fax": "+39 0828/965501",
      "coordinate": {
        "lat": 40.422218322753906,
        "lng": 15.312190055847168
      }
    },
    {
      "codice": "065016",
      "nome": "Bracigliano",
      "nomeStraniero": null,
      "codiceCatastale": "B115",
      "cap": "84082",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "affarigenerali@comune.bracigliano.sa.it",
      "pec": "protocollo@pec.comune.bracigliano.sa.it",
      "telefono": "+39 081/5184216",
      "fax": "+39 0815184205",
      "coordinate": {
        "lat": 40.81666564941406,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "065017",
      "nome": "Buccino",
      "nomeStraniero": null,
      "codiceCatastale": "B242",
      "cap": "84021",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.buccino.sa.it",
      "pec": "affarigenerali@pec.comune.buccino.sa.it",
      "telefono": "+39 0828/751214",
      "fax": "+39 0828/951008",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 15.383333206176758
      }
    },
    {
      "codice": "065018",
      "nome": "Buonabitacolo",
      "nomeStraniero": null,
      "codiceCatastale": "B266",
      "cap": "84032",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "protocollo@comune.buonabitacolo.sa.it",
      "pec": "affarigenerali.buonabitacolo@pec.it",
      "telefono": "+39 0975321211",
      "fax": "+39 097591580",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 15.616666793823242
      }
    },
    {
      "codice": "065019",
      "nome": "Caggiano",
      "nomeStraniero": null,
      "codiceCatastale": "B351",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "comune.cag@tiscali.it",
      "pec": "segreteria.caggiano@asmepec.it",
      "telefono": "+39 0975/393020",
      "fax": "+39 0975/393920",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 15.5
      }
    },
    {
      "codice": "065020",
      "nome": "Calvanico",
      "nomeStraniero": null,
      "codiceCatastale": "B437",
      "cap": "84080",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "municipio@comune.calvanico.sa.it",
      "pec": "prot.seg.calvanico@asmepec.it",
      "telefono": "+39 089 957256",
      "fax": "+39 089.957296",
      "coordinate": {
        "lat": 40.78333282470703,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "065021",
      "nome": "Camerota",
      "nomeStraniero": null,
      "codiceCatastale": "B476",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "statocivile@comune.camerota.sa.it",
      "pec": "protocollo.camerota@asmepec.it",
      "telefono": "+39 0974/92023303",
      "fax": "+39 0974/9202322",
      "coordinate": {
        "lat": 40.03303146362305,
        "lng": 15.369927406311035
      }
    },
    {
      "codice": "065022",
      "nome": "Campagna",
      "nomeStraniero": null,
      "codiceCatastale": "B492",
      "cap": "84022",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "elettorale@comune.campagna.sa.it",
      "pec": "lavoripubblici@pec.comune.campagna.sa.it",
      "telefono": "+39 0828/241200",
      "fax": "+39 0828/46988",
      "coordinate": {
        "lat": 40.66661071777344,
        "lng": 15.106380462646484
      }
    },
    {
      "codice": "065023",
      "nome": "Campora",
      "nomeStraniero": null,
      "codiceCatastale": "B555",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.campora.sa.it",
      "pec": "protocollo.campora@asmepec.it",
      "telefono": "+39 0974/944004",
      "fax": "+39 0974/944098",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "065024",
      "nome": "Cannalonga",
      "nomeStraniero": null,
      "codiceCatastale": "B608",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comune.cannalonga@libero.it",
      "pec": "comune.cannalonga@asmepec.it",
      "telefono": "+39 0974 4375",
      "fax": "+39 0974/4907",
      "coordinate": {
        "lat": 40.25,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "065025",
      "nome": "Capaccio Paestum",
      "nomeStraniero": null,
      "codiceCatastale": "B644",
      "cap": "84047",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.capaccio.sa.it",
      "pec": "protocollo@pec.comune.capaccio.sa.it",
      "telefono": "+39 0828/812201",
      "fax": "+39 0828/812239",
      "coordinate": {
        "lat": 40.41666793823242,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "065028",
      "nome": "Casal Velino",
      "nomeStraniero": null,
      "codiceCatastale": "B895",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "sindaco@comune.casalvelino.sa.it",
      "pec": "protocollo@pec.comune.casalvelino.sa.it",
      "telefono": "+39 0974/908836",
      "fax": "+39 0974/9028838",
      "coordinate": {
        "lat": 40.18333435058594,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "065026",
      "nome": "Casalbuono",
      "nomeStraniero": null,
      "codiceCatastale": "B868",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "comunedicasalbuono@comune.casalbuono.sa.it",
      "pec": "comune.casalbuono@asmepec.it",
      "telefono": "+39 0975/862025",
      "fax": "+39 0975/862245",
      "coordinate": {
        "lat": 40.21527862548828,
        "lng": 15.688799858093262
      }
    },
    {
      "codice": "065027",
      "nome": "Casaletto Spartano",
      "nomeStraniero": null,
      "codiceCatastale": "B888",
      "cap": "84030",
      "prefisso": "0973",
      "provincia": "Salerno",
      "email": "protocollo@comune.casalettospartano.sa.it",
      "pec": "protocollo@pec.comune.casalettospartano.sa.it",
      "telefono": "+39 0973/374285",
      "fax": "",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 15.616666793823242
      }
    },
    {
      "codice": "065029",
      "nome": "Caselle in Pittari",
      "nomeStraniero": null,
      "codiceCatastale": "B959",
      "cap": "84030",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "affarigenerali@comune.caselleinpittari.sa.it",
      "pec": "protocollo.caselleinpittari@asmepec.it",
      "telefono": "+39 0974/988009",
      "fax": "+39 0974/988497",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 15.550000190734863
      }
    },
    {
      "codice": "065034",
      "nome": "Castel San Giorgio",
      "nomeStraniero": null,
      "codiceCatastale": "C259",
      "cap": "84083",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "ced@comune.castelsangiorgio.sa.it",
      "pec": "amministrazione@pec.comune.castelsangiorgio.sa.it",
      "telefono": "+39 081/5163219",
      "fax": "+39 081/5161900",
      "coordinate": {
        "lat": 40.78333282470703,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "065035",
      "nome": "Castel San Lorenzo",
      "nomeStraniero": null,
      "codiceCatastale": "C262",
      "cap": "84049",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.castelsanlorenzo.sa.it",
      "pec": "castelsanlorenzo@postecert.it",
      "telefono": "+39 0828/944066",
      "fax": "+39 0828/944059",
      "coordinate": {
        "lat": 40.41666793823242,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "065030",
      "nome": "Castelcivita",
      "nomeStraniero": null,
      "codiceCatastale": "C069",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "vincenzoteresa@libero.it",
      "pec": "demografici.castelcivita@asmepec.it",
      "telefono": "+39 0828/8975009",
      "fax": "+39 0828/8975486",
      "coordinate": {
        "lat": 40.5,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "065031",
      "nome": "Castellabate",
      "nomeStraniero": null,
      "codiceCatastale": "C125",
      "cap": "84048",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.castellabate.sa.it",
      "pec": "comune.castellabate@pec.it",
      "telefono": "+39 0974/962311",
      "fax": "+39 0974/961188",
      "coordinate": {
        "lat": 40.27888870239258,
        "lng": 14.952777862548828
      }
    },
    {
      "codice": "065032",
      "nome": "Castelnuovo Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "C231",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "elettoralecastelnuovo@virgilio.it",
      "pec": "protocollo@pec.comune.castelnuovocilento.sa.it",
      "telefono": "+39 0974/62128",
      "fax": "+39 0974/62850",
      "coordinate": {
        "lat": 40.21788787841797,
        "lng": 15.17711067199707
      }
    },
    {
      "codice": "065033",
      "nome": "Castelnuovo di Conza",
      "nomeStraniero": null,
      "codiceCatastale": "C235",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "demog.castelnuovo@tiscali.it",
      "pec": "comune.castelnuovodiconza@asmepec.it",
      "telefono": "+39 0828/911003",
      "fax": "+39 0828/911427",
      "coordinate": {
        "lat": 40.81666564941406,
        "lng": 15.316666603088379
      }
    },
    {
      "codice": "065036",
      "nome": "Castiglione del Genovesi",
      "nomeStraniero": null,
      "codiceCatastale": "C306",
      "cap": "84090",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "info@comune.castiglionedelgenovesi.sa.it",
      "pec": "protocollocastiglionedelgenovesi@asmepec.it",
      "telefono": "+39 089/881618",
      "fax": "+39 089/881648",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "065037",
      "nome": "Cava de' Tirreni",
      "nomeStraniero": null,
      "codiceCatastale": "C361",
      "cap": "84013",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "amministrazione@comune.cavadetirreni.sa.it",
      "pec": "amministrazione@pec.comune.cavadetirreni.sa.it",
      "telefono": "+39 089/682111",
      "fax": "+39 089/443629",
      "coordinate": {
        "lat": 40.70083236694336,
        "lng": 14.70555591583252
      }
    },
    {
      "codice": "065038",
      "nome": "Celle di Bulgheria",
      "nomeStraniero": null,
      "codiceCatastale": "C444",
      "cap": "84040",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "protocollo@comunecelledibulgheria.it",
      "pec": "protocollo@pec.comunecelledibulgheria.it",
      "telefono": "+39 0974/987014",
      "fax": "+39 0974/987520",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 15.399999618530273
      }
    },
    {
      "codice": "065039",
      "nome": "Centola",
      "nomeStraniero": null,
      "codiceCatastale": "C470",
      "cap": "84051",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "anagrafe.centola@libero.it",
      "pec": "centola@pec.comune.centola.sa.it",
      "telefono": "+39 0974/370711",
      "fax": "+39 0974/370741",
      "coordinate": {
        "lat": 40.06666564941406,
        "lng": 15.316666603088379
      }
    },
    {
      "codice": "065040",
      "nome": "Ceraso",
      "nomeStraniero": null,
      "codiceCatastale": "C485",
      "cap": "84052",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comuneceraso@libero.it",
      "pec": "segreteria.ceraso@asmepec.it",
      "telefono": "+39 0974/61078",
      "fax": "+39 0974/61475",
      "coordinate": {
        "lat": 40.20000076293945,
        "lng": 15.25
      }
    },
    {
      "codice": "065041",
      "nome": "Cetara",
      "nomeStraniero": null,
      "codiceCatastale": "C584",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "segreteria@comune.cetara.sa.it",
      "pec": "segretario.cetara@asmepec.it",
      "telefono": "+39 089/262914",
      "fax": "+39 089/262912",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "065042",
      "nome": "Cicerale",
      "nomeStraniero": null,
      "codiceCatastale": "C676",
      "cap": "84053",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "segreteria@comune.cicerale.sa.it",
      "pec": "protocollo.cicerale@asmepec.it",
      "telefono": "+39 0974/834021",
      "fax": "+39 0974/834455",
      "coordinate": {
        "lat": 40.349998474121094,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "065043",
      "nome": "Colliano",
      "nomeStraniero": null,
      "codiceCatastale": "C879",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "segreteria.colliano@gmail.com",
      "pec": "protocollo.colliano@asmepec.it",
      "telefono": "+39 0828/992018",
      "fax": "+39 0974/792002",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 15.283333778381348
      }
    },
    {
      "codice": "065044",
      "nome": "Conca dei Marini",
      "nomeStraniero": null,
      "codiceCatastale": "C940",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "comune.concadeimarini@asmepec.it",
      "pec": "protocollo.concadeimarini@asmepec.it",
      "telefono": "+39 089/831301",
      "fax": "+39 089/831516",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "065045",
      "nome": "Controne",
      "nomeStraniero": null,
      "codiceCatastale": "C973",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "anagrafecontron_2@libero.it",
      "pec": "anagrafe.controne@asmepec.it",
      "telefono": "+39 0828/772023",
      "fax": "+39 0828/772084",
      "coordinate": {
        "lat": 40.516666412353516,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "065046",
      "nome": "Contursi Terme",
      "nomeStraniero": null,
      "codiceCatastale": "C974",
      "cap": "84024",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comune.contursi@gmail.com",
      "pec": "comune.contursiterme@asmepec.it",
      "telefono": "+39 0828/991013",
      "fax": "+39 0828/991069",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "065047",
      "nome": "Corbara",
      "nomeStraniero": null,
      "codiceCatastale": "C984",
      "cap": "84010",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "protocollo@comune.corbara.sa.it",
      "pec": "segreteria.corbara@asmepec.it",
      "telefono": "+39 081/913801",
      "fax": "+39 081/930056",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "065048",
      "nome": "Corleto Monforte",
      "nomeStraniero": null,
      "codiceCatastale": "D011",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.corletomonforte.sa.it",
      "pec": "protocollo@pec.comune.corletomonforte.sa.it",
      "telefono": "+39 0828/964001",
      "fax": "+39 0828/964272",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 15.383333206176758
      }
    },
    {
      "codice": "065049",
      "nome": "Cuccaro Vetere",
      "nomeStraniero": null,
      "codiceCatastale": "D195",
      "cap": "84050",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "ufficidemografici@comune.cuccarovetere.sa.it",
      "pec": "comune.cuccarovetere@asmepec.it",
      "telefono": "+39 0974/950770",
      "fax": "+39 0974/953050",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 15.316666603088379
      }
    },
    {
      "codice": "065050",
      "nome": "Eboli",
      "nomeStraniero": null,
      "codiceCatastale": "D390",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "a.desiderio@comune.eboli.sa.it",
      "pec": "sindaco.eboli@asmepec.it",
      "telefono": "+39 08283282211",
      "fax": "+39 0828328200",
      "coordinate": {
        "lat": 40.616943359375,
        "lng": 15.056388854980469
      }
    },
    {
      "codice": "065051",
      "nome": "Felitto",
      "nomeStraniero": null,
      "codiceCatastale": "D527",
      "cap": "84055",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "ufficio.anagrafe@comune.felittio.sa.it",
      "pec": "protocollo@pec.comune.felitto.sa.it",
      "telefono": "+39 0828/945028",
      "fax": "+39 0828945638",
      "coordinate": {
        "lat": 40.36666488647461,
        "lng": 15.25
      }
    },
    {
      "codice": "065052",
      "nome": "Fisciano",
      "nomeStraniero": null,
      "codiceCatastale": "D615",
      "cap": "84084",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "anagrafe@comune.fisciano.sa.it",
      "pec": "protocollo@comunefisciano.legalmailpa.it",
      "telefono": "+39 089 9501511",
      "fax": "+39 089 891734",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "065053",
      "nome": "Furore",
      "nomeStraniero": null,
      "codiceCatastale": "D826",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "sindaco@comunefurore.it",
      "pec": "sindaco.furore@asmepec.it",
      "telefono": "+39 089/874100",
      "fax": "+39 089/874491",
      "coordinate": {
        "lat": 40.62138748168945,
        "lng": 14.549444198608398
      }
    },
    {
      "codice": "065054",
      "nome": "Futani",
      "nomeStraniero": null,
      "codiceCatastale": "D832",
      "cap": "84050",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "anagrafe@comune.futani.sa.it",
      "pec": "protocollo@pec.comune.futani.sa.it",
      "telefono": "+39 0974/953012",
      "fax": "+39 0974/953411",
      "coordinate": {
        "lat": 40.151668548583984,
        "lng": 15.322221755981445
      }
    },
    {
      "codice": "065055",
      "nome": "Giffoni Sei Casali",
      "nomeStraniero": null,
      "codiceCatastale": "E026",
      "cap": "84090",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "segreteria@comune.giffoniseicasali.sa.it",
      "pec": "protocollo@pec.comune.giffoniseicasali.sa.it",
      "telefono": "+39 089/883210",
      "fax": "+39 089/883515",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 14.899999618530273
      }
    },
    {
      "codice": "065056",
      "nome": "Giffoni Valle Piana",
      "nomeStraniero": null,
      "codiceCatastale": "E027",
      "cap": "84095",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "info@comune.giffonivallepiana.sa.it",
      "pec": "comunegiffonivallepiana@pec.sa.it",
      "telefono": "+39 089/828711",
      "fax": "+39 089/865422",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 14.933333396911621
      }
    },
    {
      "codice": "065057",
      "nome": "Gioi",
      "nomeStraniero": null,
      "codiceCatastale": "E037",
      "cap": "84056",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.gioi.sa.it",
      "pec": "anagrafe@pec.comune.gioi.sa.it",
      "telefono": "+39 0974/991026",
      "fax": "+39 0974/991503",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 15.216666221618652
      }
    },
    {
      "codice": "065058",
      "nome": "Giungano",
      "nomeStraniero": null,
      "codiceCatastale": "E060",
      "cap": "84050",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comune.giungano@gmail.com",
      "pec": "protocollo.comune.giungano@pec.it",
      "telefono": "+39 0828/880285",
      "fax": "+39 0828/880220",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "065059",
      "nome": "Ispani",
      "nomeStraniero": null,
      "codiceCatastale": "E365",
      "cap": "84040",
      "prefisso": "0973",
      "provincia": "Salerno",
      "email": "comuneispani@tiscali.it",
      "pec": "protocollo.ispani@asmepec.it",
      "telefono": "+39 0973/381201",
      "fax": "+39 0973/381201",
      "coordinate": {
        "lat": 40.08333206176758,
        "lng": 15.566666603088379
      }
    },
    {
      "codice": "065060",
      "nome": "Laureana Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "E480",
      "cap": "84050",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comunelaureanacto@tiscali.it",
      "pec": "protocollo@pec.comune.laureanacilentto.sa.it",
      "telefono": "+39 0974/832022",
      "fax": "+39 0974/832345",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 15.033333778381348
      }
    },
    {
      "codice": "065061",
      "nome": "Laurino",
      "nomeStraniero": null,
      "codiceCatastale": "E485",
      "cap": "84057",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.laurino.sa.it",
      "pec": "comune.laurino@asmepec.it",
      "telefono": "+39 0974.941014",
      "fax": "+39 0974.941622",
      "coordinate": {
        "lat": 40.33333206176758,
        "lng": 15.333333015441895
      }
    },
    {
      "codice": "065062",
      "nome": "Laurito",
      "nomeStraniero": null,
      "codiceCatastale": "E486",
      "cap": "84050",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comunelaurito@tiscali.it",
      "pec": "comune.laurito@asmepec.it",
      "telefono": "+39 0974.954114",
      "fax": "+39 0974.954287",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 15.399999618530273
      }
    },
    {
      "codice": "065063",
      "nome": "Laviano",
      "nomeStraniero": null,
      "codiceCatastale": "E498",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comunedilaviano@tiscali.it",
      "pec": "anagrafe.laviano@asmepec.it",
      "telefono": "+39 0828/915001",
      "fax": "+39 0828/915400",
      "coordinate": {
        "lat": 40.786190032958984,
        "lng": 15.307909965515137
      }
    },
    {
      "codice": "065064",
      "nome": "Lustra",
      "nomeStraniero": null,
      "codiceCatastale": "E767",
      "cap": "84050",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comunedilustra@libero.it",
      "pec": "protocollo@pec.comune.lustra.sa.it",
      "telefono": "+39 0974/830074",
      "fax": "+39 0974/830033",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "065065",
      "nome": "Magliano Vetere",
      "nomeStraniero": null,
      "codiceCatastale": "E814",
      "cap": "84050",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comunemaglianovetere@tiscali.it",
      "pec": "protocollo@pec.comune.maglianovetere.sa.it",
      "telefono": "+39 0974/992032",
      "fax": "+39 0974/992076",
      "coordinate": {
        "lat": 40.349998474121094,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "065066",
      "nome": "Maiori",
      "nomeStraniero": null,
      "codiceCatastale": "E839",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "areaamministrativa@comune.maiori.sa.it",
      "pec": "areaamministrativa@pec.comune.maiori.sa.it",
      "telefono": "+39 089/814201",
      "fax": "+39 089/853133",
      "coordinate": {
        "lat": 40.64861297607422,
        "lng": 14.63888931274414
      }
    },
    {
      "codice": "065067",
      "nome": "Mercato San Severino",
      "nomeStraniero": null,
      "codiceCatastale": "F138",
      "cap": "84085",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "info@comunemss.gov.it",
      "pec": "protocollo.mercatosanseverino@legalmail.it",
      "telefono": "+39 089/826811",
      "fax": "+39 089/821634",
      "coordinate": {
        "lat": 40.78333282470703,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "065068",
      "nome": "Minori",
      "nomeStraniero": null,
      "codiceCatastale": "F223",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "info@comune.minori.sa.it",
      "pec": "comune.minori@asmepec.it",
      "telefono": "+39 089/8542401",
      "fax": "+39 089/852875",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 14.626388549804688
      }
    },
    {
      "codice": "065069",
      "nome": "Moio della Civitella",
      "nomeStraniero": null,
      "codiceCatastale": "F278",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "moiocivitella@tiscali.it",
      "pec": "sindaco.moio@asmepec.it",
      "telefono": "+39 0974/66118",
      "fax": "+39 0974/66036",
      "coordinate": {
        "lat": 40.247100830078125,
        "lng": 15.26885986328125
      }
    },
    {
      "codice": "065070",
      "nome": "Montano Antilia",
      "nomeStraniero": null,
      "codiceCatastale": "F426",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "servizi.demografici@comune.montano-antilia.sa.it",
      "pec": "protocollo.antilia@asmepec.it",
      "telefono": "+39 0974/951053",
      "fax": "+39 0974/951458",
      "coordinate": {
        "lat": 40.162010192871094,
        "lng": 15.366666793823242
      }
    },
    {
      "codice": "065075",
      "nome": "Monte San Giacomo",
      "nomeStraniero": null,
      "codiceCatastale": "F618",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "comune.msgiacomo@tiscali.it",
      "pec": "prot.msgiacomo@asmepec.it",
      "telefono": "+39 0975/75006",
      "fax": "+39 0975/75250",
      "coordinate": {
        "lat": 40.349998474121094,
        "lng": 15.533333778381348
      }
    },
    {
      "codice": "065071",
      "nome": "Montecorice",
      "nomeStraniero": null,
      "codiceCatastale": "F479",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "demografici.montecorice@gmail.com",
      "pec": "demografici.montecorice@asmpec.it",
      "telefono": "+39 0974/964340",
      "fax": "+39 0974/968931",
      "coordinate": {
        "lat": 40.23416519165039,
        "lng": 14.983888626098633
      }
    },
    {
      "codice": "065072",
      "nome": "Montecorvino Pugliano",
      "nomeStraniero": null,
      "codiceCatastale": "F480",
      "cap": "84090",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "protocollo@comune.montecorvinopugliano.sa.it",
      "pec": "protocollo.montecorvinopugliano@asmepec.it",
      "telefono": "+39 089/8022211",
      "fax": "+39 089/801660",
      "coordinate": {
        "lat": 40.68333435058594,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "065073",
      "nome": "Montecorvino Rovella",
      "nomeStraniero": null,
      "codiceCatastale": "F481",
      "cap": "84096",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "protocollo@comune.montecorvinorovella.sa.it",
      "pec": "protocollo@pec.comune.montecorvinorovella.sa.it",
      "telefono": "+39 089/8021611",
      "fax": "+39 089808233",
      "coordinate": {
        "lat": 40.70000076293945,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "065074",
      "nome": "Monteforte Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "F507",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "commonteforte@tiscali.it",
      "pec": "protocollo@pec.comune.montefortecilento.sa.it",
      "telefono": "+39 0974/996006",
      "fax": "+39 0974/336210",
      "coordinate": {
        "lat": 40.36666488647461,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "065076",
      "nome": "Montesano sulla Marcellana",
      "nomeStraniero": null,
      "codiceCatastale": "F625",
      "cap": "84033",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "comune.montesanosm@comune.montesano.sa.it",
      "pec": "protocollo@pec.comune.montesano.sa.it",
      "telefono": "+39 0975865221",
      "fax": "+39 0975865189",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 15.699999809265137
      }
    },
    {
      "codice": "065077",
      "nome": "Morigerati",
      "nomeStraniero": null,
      "codiceCatastale": "F731",
      "cap": "84030",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "morcom@tiscali.it",
      "pec": "sindaco.morigerati@asmepec.it",
      "telefono": "+39 0974/982016",
      "fax": "+39 0974/982169",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 15.550000190734863
      }
    },
    {
      "codice": "065078",
      "nome": "Nocera Inferiore",
      "nomeStraniero": null,
      "codiceCatastale": "F912",
      "cap": "84014",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "servizi.affarigenerali@comune.nocera-inferiore.sa.it",
      "pec": "protocollo@pec.comune.nocera-inferiore.sa.it",
      "telefono": "+39 081 3235205",
      "fax": "+39 081 5176360",
      "coordinate": {
        "lat": 40.75,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "065079",
      "nome": "Nocera Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "F913",
      "cap": "84015",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "protocollo@comune.nocera-superiore.sa.it",
      "pec": "protocollo@pec.comune.nocera-superiore.sa.it",
      "telefono": "+39 0815169111",
      "fax": "+39 0815143532",
      "coordinate": {
        "lat": 40.74166488647461,
        "lng": 14.672222137451172
      }
    },
    {
      "codice": "065080",
      "nome": "Novi Velia",
      "nomeStraniero": null,
      "codiceCatastale": "F967",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.novivelia.sa.it",
      "pec": "segrenovi@asmepec.it",
      "telefono": "+39 0974.65031",
      "fax": "+39 0974.65078",
      "coordinate": {
        "lat": 40.21666717529297,
        "lng": 15.283333778381348
      }
    },
    {
      "codice": "065081",
      "nome": "Ogliastro Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "G011",
      "cap": "84061",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.ogliastrocilento.sa.it",
      "pec": "segretario.ogliastrocilento@asmepec.it",
      "telefono": "+39 0974833004",
      "fax": "+39 0974833054",
      "coordinate": {
        "lat": 40.349998474121094,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "065082",
      "nome": "Olevano sul Tusciano",
      "nomeStraniero": null,
      "codiceCatastale": "G023",
      "cap": "84062",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "anagrafe@comune.olevanosultusciano.sa.it",
      "pec": "anagrafestatocivile@pec.olevanosultusciano.eu",
      "telefono": "+39 0828/621626",
      "fax": "+39 0828/621645",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "065083",
      "nome": "Oliveto Citra",
      "nomeStraniero": null,
      "codiceCatastale": "G039",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "protocollo@comune.oliveto-citra.sa.it",
      "pec": "protocollo.oliveto-citra@asmepec.it",
      "telefono": "+39 0828/799111",
      "fax": "+39 0828/793766",
      "coordinate": {
        "lat": 40.68944549560547,
        "lng": 15.231111526489258
      }
    },
    {
      "codice": "065084",
      "nome": "Omignano",
      "nomeStraniero": null,
      "codiceCatastale": "G063",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "anagrafe.omignano@virgilio.it",
      "pec": "comune.omignano@pec.it",
      "telefono": "+39 0974/836020",
      "fax": "+39 0974854006",
      "coordinate": {
        "lat": 40.25,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "065085",
      "nome": "Orria",
      "nomeStraniero": null,
      "codiceCatastale": "G121",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "ufficiodemografico@libero.it",
      "pec": "orriaanagrafe@legalmail.it",
      "telefono": "+39 0974.993006",
      "fax": "+39 0974.993274",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "065086",
      "nome": "Ottati",
      "nomeStraniero": null,
      "codiceCatastale": "G192",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "anagrafe.comuneottati@virgilio.it",
      "pec": "protocollo.ottati@asmepec.it",
      "telefono": "+39 0828 966002",
      "fax": "+39 0828 966244",
      "coordinate": {
        "lat": 40.46666717529297,
        "lng": 15.316666603088379
      }
    },
    {
      "codice": "065087",
      "nome": "Padula",
      "nomeStraniero": null,
      "codiceCatastale": "G226",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "info@comune.padula.sa.it",
      "pec": "comune.padula@asmepec.it",
      "telefono": "+39 0975778711",
      "fax": "+39 097577553",
      "coordinate": {
        "lat": 40.33900833129883,
        "lng": 15.656339645385742
      }
    },
    {
      "codice": "065088",
      "nome": "Pagani",
      "nomeStraniero": null,
      "codiceCatastale": "G230",
      "cap": "84016",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "elettorale@comunedipagani.it",
      "pec": "protocollo@comunedipagani.legalmail.it",
      "telefono": "+39 081 3240380",
      "fax": null,
      "coordinate": {
        "lat": 40.74166488647461,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "065089",
      "nome": "Palomonte",
      "nomeStraniero": null,
      "codiceCatastale": "G292",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "palomonte@tiscalinet.it",
      "pec": "protocollo@pec.comune.palomonte.sa.it",
      "telefono": "+39 0828/994089",
      "fax": "+39 0828/994008",
      "coordinate": {
        "lat": 40.66666793823242,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "065090",
      "nome": "Pellezzano",
      "nomeStraniero": null,
      "codiceCatastale": "G426",
      "cap": "84080",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "ufficio.segreteria@comune.pellezzano.sa.it",
      "pec": "protocollo@pec.comune.pellezzano.sa.it",
      "telefono": "+39 089 568717",
      "fax": "+39 089 567960",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "065091",
      "nome": "Perdifumo",
      "nomeStraniero": null,
      "codiceCatastale": "G447",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.perdifumo.sa.it",
      "pec": "protocollo.perdifumo@asmepec.it",
      "telefono": "+39 0974/845024",
      "fax": "+39 0974/845034",
      "coordinate": {
        "lat": 40.25,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "065092",
      "nome": "Perito",
      "nomeStraniero": null,
      "codiceCatastale": "G455",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "uff.segr-perito@libero.it",
      "pec": "sociali.perito@asmepec.it",
      "telefono": "+39 0974/998003",
      "fax": "+39 0974/998067",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 15.149999618530273
      }
    },
    {
      "codice": "065093",
      "nome": "Pertosa",
      "nomeStraniero": null,
      "codiceCatastale": "G476",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "anagrafe@comune.pertosa.sa.it",
      "pec": "anagrafe.pertosa@asmepec.it",
      "telefono": "+39 0975 397028",
      "fax": "+39 0975 397067",
      "coordinate": {
        "lat": 40.54999923706055,
        "lng": 15.449999809265137
      }
    },
    {
      "codice": "065094",
      "nome": "Petina",
      "nomeStraniero": null,
      "codiceCatastale": "G509",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "protocollo@petina.gov.it",
      "pec": "prot.petina@asmepec.it",
      "telefono": "+39 0828 976003",
      "fax": "+39 0828 976148",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 15.366666793823242
      }
    },
    {
      "codice": "065095",
      "nome": "Piaggine",
      "nomeStraniero": null,
      "codiceCatastale": "G538",
      "cap": "84065",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "massimobruno@comune.piaggine.sa.it",
      "pec": "protocollo@pec.comune.piaggine.sa.it",
      "telefono": "+39 0974.942014",
      "fax": "+39 0974.942721",
      "coordinate": {
        "lat": 40.349998474121094,
        "lng": 15.383333206176758
      }
    },
    {
      "codice": "065096",
      "nome": "Pisciotta",
      "nomeStraniero": null,
      "codiceCatastale": "G707",
      "cap": "84066",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "unopisciotta@tiscali.it",
      "pec": "comune.pisciotta@pec.it",
      "telefono": "+39 0974 973035",
      "fax": "+39 0974 970900",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "065097",
      "nome": "Polla",
      "nomeStraniero": null,
      "codiceCatastale": "G793",
      "cap": "84035",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "info@comune.polla.sa.it",
      "pec": "protocollo.polla@asmepec.it",
      "telefono": "+39 0975 376111",
      "fax": "+39 0975 376235",
      "coordinate": {
        "lat": 40.516666412353516,
        "lng": 15.5
      }
    },
    {
      "codice": "065098",
      "nome": "Pollica",
      "nomeStraniero": null,
      "codiceCatastale": "G796",
      "cap": "84068",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "protocollo@comune.pollica.sa.it",
      "pec": "protocollo@pec.comune.pollica.sa.it",
      "telefono": "+39 0974 901004",
      "fax": "+39 0974 901422",
      "coordinate": {
        "lat": 40.18333435058594,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "065099",
      "nome": "Pontecagnano Faiano",
      "nomeStraniero": null,
      "codiceCatastale": "G834",
      "cap": "84098",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "elettorale@comune.pontecagnanofaiano.sa.it",
      "pec": "protocollo@pec.comune.pontecagnanofaiano.sa.it",
      "telefono": "+39 089 386311",
      "fax": "+39 089 849935",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "065100",
      "nome": "Positano",
      "nomeStraniero": null,
      "codiceCatastale": "G932",
      "cap": "84017",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "segreteria.sindaco@comune.positano.sa.it",
      "pec": "protocollo@pec.comunedipositano.it",
      "telefono": "+39 089 8122511",
      "fax": "+39 089 811122",
      "coordinate": {
        "lat": 40.627777099609375,
        "lng": 14.48194408416748
      }
    },
    {
      "codice": "065101",
      "nome": "Postiglione",
      "nomeStraniero": null,
      "codiceCatastale": "G939",
      "cap": "84026",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "anagrafe@postiglione.gov.it",
      "pec": "anagrafe@pec.comunepostiglione.sa.it",
      "telefono": "+39 0828 770111",
      "fax": "+39 0828 770214",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "065102",
      "nome": "Praiano",
      "nomeStraniero": null,
      "codiceCatastale": "G976",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "anagrafe@comune.praiano.sa.it",
      "pec": "protocollo.praiano@asmepec.it",
      "telefono": "+39 089 8131911",
      "fax": "+39 089 8131912",
      "coordinate": {
        "lat": 40.61111068725586,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "065103",
      "nome": "Prignano Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "H062",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.prignanocilento.sa.it",
      "pec": "protocollo@pec.comune.prignanocilento.sa.it",
      "telefono": "+39 0974 831039",
      "fax": "+39 0974 831444",
      "coordinate": {
        "lat": 40.33333206176758,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "065104",
      "nome": "Ravello",
      "nomeStraniero": null,
      "codiceCatastale": "H198",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "protocollo@comune.ravello.sa.it",
      "pec": "protocollo@pec.comune.ravello.sa.it",
      "telefono": "+39 089 857122",
      "fax": "+39 089 857185",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "065105",
      "nome": "Ricigliano",
      "nomeStraniero": null,
      "codiceCatastale": "H277",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "zarrillo.pasquale@tiscali.it",
      "pec": "affarigenerali.ricigliano@asmepec.it",
      "telefono": "+39 0828.953016",
      "fax": "+39 0828 953220",
      "coordinate": {
        "lat": 40.66666793823242,
        "lng": 15.483333587646484
      }
    },
    {
      "codice": "065106",
      "nome": "Roccadaspide",
      "nomeStraniero": null,
      "codiceCatastale": "H394",
      "cap": "84069",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "demografici@comune.roccadaspide.sa.it",
      "pec": "protocollo@pec.comune.roccadaspide.sa.it",
      "telefono": "+39 0828 948229",
      "fax": "+39 0828 948237",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "065107",
      "nome": "Roccagloriosa",
      "nomeStraniero": null,
      "codiceCatastale": "H412",
      "cap": "84060",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "protocollo@comune.roccagloriosa.sa.it",
      "pec": "protocollo.roccagloriosa@asmepec.it",
      "telefono": "+39 0974-981113",
      "fax": "+39 0974-981393",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 15.433333396911621
      }
    },
    {
      "codice": "065108",
      "nome": "Roccapiemonte",
      "nomeStraniero": null,
      "codiceCatastale": "H431",
      "cap": "84086",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "segreteria@comune.roccapiemonte.sa.it",
      "pec": "protocollo.roccapiemonte@asmepec.it",
      "telefono": "+39 081 936352",
      "fax": "+39 081 5144488",
      "coordinate": {
        "lat": 40.76166534423828,
        "lng": 14.693333625793457
      }
    },
    {
      "codice": "065109",
      "nome": "Rofrano",
      "nomeStraniero": null,
      "codiceCatastale": "H485",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "segretario@comune.rofrano.sa.it",
      "pec": "protocollo.rofrano@asmepec.it",
      "telefono": "+39 0974 952031",
      "fax": "+39 0974 952462",
      "coordinate": {
        "lat": 40.21666717529297,
        "lng": 15.433333396911621
      }
    },
    {
      "codice": "065110",
      "nome": "Romagnano al Monte",
      "nomeStraniero": null,
      "codiceCatastale": "H503",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comunediromagnano@libero.it",
      "pec": "comunediromagnano@asmepec.it",
      "telefono": "+39 0828 751012",
      "fax": "+39 0828 752800",
      "coordinate": {
        "lat": 40.628055572509766,
        "lng": 15.45805549621582
      }
    },
    {
      "codice": "065111",
      "nome": "Roscigno",
      "nomeStraniero": null,
      "codiceCatastale": "H564",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comune.roscigno@tiscali.it",
      "pec": "ufficioprotocollo@asmepec.it",
      "telefono": "+39 0828/963043",
      "fax": "+39 0828/963188",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 15.350000381469727
      }
    },
    {
      "codice": "065112",
      "nome": "Rutino",
      "nomeStraniero": null,
      "codiceCatastale": "H644",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comunerutino@tiscali.it",
      "pec": "segreteria.rutino@asmepec.it",
      "telefono": "+39 0974 830020",
      "fax": "+39 0974 830137",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "065113",
      "nome": "Sacco",
      "nomeStraniero": null,
      "codiceCatastale": "H654",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "tributi.sacco@libero.it",
      "pec": "tributisacco@legalmail.it",
      "telefono": "+39 0974.943001",
      "fax": "+39 0974 943180",
      "coordinate": {
        "lat": 40.37888717651367,
        "lng": 15.37833309173584
      }
    },
    {
      "codice": "065114",
      "nome": "Sala Consilina",
      "nomeStraniero": null,
      "codiceCatastale": "H683",
      "cap": "84036",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "sindaco@salaconsilina.gov.it",
      "pec": "protocollo.salaconsilina@asmepec.it",
      "telefono": "+39 0975 525211",
      "fax": "+39 0975 525268",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 15.600000381469727
      }
    },
    {
      "codice": "065115",
      "nome": "Salento",
      "nomeStraniero": null,
      "codiceCatastale": "H686",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "ragioneria.salento@gmail.com",
      "pec": "ragioneria.salento@legalmail.it",
      "telefono": "+39 0974.62018",
      "fax": "+39 0974 62642",
      "coordinate": {
        "lat": 40.24959945678711,
        "lng": 15.190899848937988
      }
    },
    {
      "codice": "065116",
      "nome": "Salerno",
      "nomeStraniero": null,
      "codiceCatastale": "H703",
      "cap": "84121",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "comune.salerno@comune.salerno.it",
      "pec": "protocollo@pec.comune.salerno.it",
      "telefono": "+39 089 661111",
      "fax": "+39 089 662127",
      "coordinate": {
        "lat": 40.68055725097656,
        "lng": 14.759721755981445
      }
    },
    {
      "codice": "065117",
      "nome": "Salvitelle",
      "nomeStraniero": null,
      "codiceCatastale": "H732",
      "cap": "84020",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "comunedisalvitelle@virgilio.it",
      "pec": "protocollo.salvitelle@asmepec.it",
      "telefono": "+39 0975 394004",
      "fax": "+39 0975 394233",
      "coordinate": {
        "lat": 40.599998474121094,
        "lng": 15.466666221618652
      }
    },
    {
      "codice": "065118",
      "nome": "San Cipriano Picentino",
      "nomeStraniero": null,
      "codiceCatastale": "H800",
      "cap": "84099",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "comunesancipriano@hotmail.it",
      "pec": "protocollo@pec.comune.sanciprianopicentino.sa.it",
      "telefono": "+39 089 8628001",
      "fax": "+39 1782731842",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "065119",
      "nome": "San Giovanni a Piro",
      "nomeStraniero": null,
      "codiceCatastale": "H907",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "info@comune.sangiovanniapiro.sa.it",
      "pec": "segreteria.sangiovanniapiro@asmepec.it",
      "telefono": "+39 0974 983007",
      "fax": "+39 0974 983710",
      "coordinate": {
        "lat": 40.04999923706055,
        "lng": 15.433333396911621
      }
    },
    {
      "codice": "065120",
      "nome": "San Gregorio Magno",
      "nomeStraniero": null,
      "codiceCatastale": "H943",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "affarigenerali@comune.sangregoriomagno.sa.it",
      "pec": "protocollo.sangregoriomagno@asmepec.it",
      "telefono": "+39 0828/955244",
      "fax": "+39 0828/955332",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 15.399999618530273
      }
    },
    {
      "codice": "065121",
      "nome": "San Mango Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "H977",
      "cap": "84090",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "servizidemografici@comune.sanmangopiemonte.sa.it",
      "pec": "prot.sanmangopiemonte@asmepec.it",
      "telefono": "+39 089 631031",
      "fax": "+39 089 631744",
      "coordinate": {
        "lat": 40.70000076293945,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "065122",
      "nome": "San Marzano sul Sarno",
      "nomeStraniero": null,
      "codiceCatastale": "I019",
      "cap": "84010",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "urp@sanmarzanosulsarno.gov.it",
      "pec": "comune.smarzanosulsarno@cert.irpinianet.eu",
      "telefono": "+39 081 5188111",
      "fax": "+39 081 5188224",
      "coordinate": {
        "lat": 40.76970291137695,
        "lng": 14.594660758972168
      }
    },
    {
      "codice": "065123",
      "nome": "San Mauro Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "I031",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "giovanna.martucci@sanmaurocilento.gov.it",
      "pec": "protocollo@pec.sanmaurocilento.gov.it",
      "telefono": "+39 0974 903161",
      "fax": "+39 0974 903303",
      "coordinate": {
        "lat": 40.233333587646484,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "065124",
      "nome": "San Mauro la Bruca",
      "nomeStraniero": null,
      "codiceCatastale": "I032",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "sanmaurolabruca@libero.it",
      "pec": "comune.sanmaurolabruca@asmepec.it",
      "telefono": "+39 0974 974010",
      "fax": "+39 0974 974164",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 15.283333778381348
      }
    },
    {
      "codice": "065125",
      "nome": "San Pietro al Tanagro",
      "nomeStraniero": null,
      "codiceCatastale": "I089",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "info@comune.sanpietroaltanagro.sa.it",
      "pec": "postacert@pec.comune.sanpietroaltanagro.sa.it",
      "telefono": "+39 0975 399326",
      "fax": "+39 0975 396047",
      "coordinate": {
        "lat": 40.456390380859375,
        "lng": 15.483055114746094
      }
    },
    {
      "codice": "065126",
      "nome": "San Rufo",
      "nomeStraniero": null,
      "codiceCatastale": "I143",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "servdem.sanrufo@libero.it",
      "pec": "prot.sanrufo@asmepec.it",
      "telefono": "+39 0975 395013",
      "fax": "+39 0975 395243",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 15.466666221618652
      }
    },
    {
      "codice": "065132",
      "nome": "San Valentino Torio",
      "nomeStraniero": null,
      "codiceCatastale": "I377",
      "cap": "84010",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "protocollo@comune.sanvalentinotorio.sa.it",
      "pec": "protocollo@pec.comune.sanvalentinotorio.sa.it",
      "telefono": "+39 081 5187811",
      "fax": "+39 081 5186672",
      "coordinate": {
        "lat": 40.79108810424805,
        "lng": 14.6033296585083
      }
    },
    {
      "codice": "065128",
      "nome": "Sant'Angelo a Fasanella",
      "nomeStraniero": null,
      "codiceCatastale": "I278",
      "cap": "84027",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comune.santangeloaf@libero.it",
      "pec": "protocollo.santangeloaf@asmepec.it",
      "telefono": "+39 0828.961006",
      "fax": "+39 0828 961346",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 15.350000381469727
      }
    },
    {
      "codice": "065129",
      "nome": "Sant'Arsenio",
      "nomeStraniero": null,
      "codiceCatastale": "I307",
      "cap": "84037",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "anagrafe@comune.santarsenio.sa.it",
      "pec": "protocollo@pec.santarsenio.sa.it",
      "telefono": "+39 0975.398033",
      "fax": "+39 0975.398214",
      "coordinate": {
        "lat": 40.46666717529297,
        "lng": 15.483332633972168
      }
    },
    {
      "codice": "065130",
      "nome": "Sant'Egidio del Monte Albino",
      "nomeStraniero": null,
      "codiceCatastale": "I317",
      "cap": "84010",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "affarigenerali@comune.santegidiodelmontealbino.sa.it",
      "pec": "comune.segidioma@asmepec.it",
      "telefono": "+39 081.915655",
      "fax": "+39 081.915520",
      "coordinate": {
        "lat": 40.74027633666992,
        "lng": 14.594444274902344
      }
    },
    {
      "codice": "065127",
      "nome": "Santa Marina",
      "nomeStraniero": null,
      "codiceCatastale": "I253",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "anagrafe@comune.santamarina.sa.it",
      "pec": "comune.santamarina@pec.it",
      "telefono": "+39 0974 989005",
      "fax": null,
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 15.550000190734863
      }
    },
    {
      "codice": "065131",
      "nome": "Santomenna",
      "nomeStraniero": null,
      "codiceCatastale": "I260",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comune.santomenna@gmail.com",
      "pec": "protocollo.santomenna@asmepec.it",
      "telefono": "+39 0828 911004",
      "fax": "+39 0828 911058",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 15.316666603088379
      }
    },
    {
      "codice": "065133",
      "nome": "Sanza",
      "nomeStraniero": null,
      "codiceCatastale": "I410",
      "cap": "84030",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "info@comune.sanza.sa.it",
      "pec": "affarigenerali@pec.comune.sanza.sa.it",
      "telefono": "+39 0975.322536",
      "fax": "+39 0975 322626",
      "coordinate": {
        "lat": 40.25,
        "lng": 15.550000190734863
      }
    },
    {
      "codice": "065134",
      "nome": "Sapri",
      "nomeStraniero": null,
      "codiceCatastale": "I422",
      "cap": "84073",
      "prefisso": "0973",
      "provincia": "Salerno",
      "email": "protocollo@comune.sapri.sa.it",
      "pec": "protocollo.sapri@asmepec.it",
      "telefono": "+39 0973.605511",
      "fax": "+39 0973 605541",
      "coordinate": {
        "lat": 40.06666564941406,
        "lng": 15.633333206176758
      }
    },
    {
      "codice": "065135",
      "nome": "Sarno",
      "nomeStraniero": null,
      "codiceCatastale": "I438",
      "cap": "84087",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "urp@comune.sarno.it",
      "pec": "protocollo.generale@pec.comune.sarno.sa.it",
      "telefono": "+39 081 8007111",
      "fax": "+39 081 945722",
      "coordinate": {
        "lat": 40.81666564941406,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "065136",
      "nome": "Sassano",
      "nomeStraniero": null,
      "codiceCatastale": "I451",
      "cap": "84038",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "info@comune.sassano.sa.it",
      "pec": "protocollo.sassano@asmepec.it",
      "telefono": "+39 0975 78809",
      "fax": "+39 0975 18946",
      "coordinate": {
        "lat": 40.340301513671875,
        "lng": 15.565549850463867
      }
    },
    {
      "codice": "065137",
      "nome": "Scafati",
      "nomeStraniero": null,
      "codiceCatastale": "I483",
      "cap": "84018",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "info@comune.scafati.sa.it",
      "pec": "protocollo.scafati@asmepec.it",
      "telefono": "+39 081 8571111",
      "fax": "+39 081 8561905",
      "coordinate": {
        "lat": 40.75361251831055,
        "lng": 14.525278091430664
      }
    },
    {
      "codice": "065138",
      "nome": "Scala",
      "nomeStraniero": null,
      "codiceCatastale": "I486",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "anagrafe@comune.scala.sa.it",
      "pec": "protocollo@pec.comune.scala.sa.it",
      "telefono": "+39 089 857115",
      "fax": "+39 089 858977",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "065139",
      "nome": "Serramezzana",
      "nomeStraniero": null,
      "codiceCatastale": "I648",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "comser@oneonline.it",
      "pec": "protocollo.serramezzana@asmepec.it",
      "telefono": "+39 0974.845040",
      "fax": "+39 0974.845800",
      "coordinate": {
        "lat": 40.25,
        "lng": 15.016667366027832
      }
    },
    {
      "codice": "065140",
      "nome": "Serre",
      "nomeStraniero": null,
      "codiceCatastale": "I666",
      "cap": "84028",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.serre.sa.it",
      "pec": "segretario.serre@asmepec.it",
      "telefono": "+39 0828/974900",
      "fax": "+39 0828/974798",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 15.183333396911621
      }
    },
    {
      "codice": "065141",
      "nome": "Sessa Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "I677",
      "cap": "84074",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "protsessa@comune.sessacilento.sa.it",
      "pec": "protsessa@pec.it",
      "telefono": "+39 0974 836055",
      "fax": "+39 0974 836599",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "065142",
      "nome": "Siano",
      "nomeStraniero": null,
      "codiceCatastale": "I720",
      "cap": "84088",
      "prefisso": "081",
      "provincia": "Salerno",
      "email": "affarigenerali@comune.siano.sa.it",
      "pec": "protocollo@pec.comune.siano.sa.it",
      "telefono": "+39 081 5183811",
      "fax": "+39 081 5181133",
      "coordinate": {
        "lat": 40.8025016784668,
        "lng": 14.69444465637207
      }
    },
    {
      "codice": "065143",
      "nome": "Sicignano degli Alburni",
      "nomeStraniero": null,
      "codiceCatastale": "M253",
      "cap": "84029",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "protocollo@sicignanodeglialburni.gov.it",
      "pec": "protocollo.sicignanodeglialburni@asmepec.it",
      "telefono": "+39 0828 973002",
      "fax": "+39 0828 973500",
      "coordinate": {
        "lat": 40.55966567993164,
        "lng": 15.307000160217285
      }
    },
    {
      "codice": "065144",
      "nome": "Stella Cilento",
      "nomeStraniero": null,
      "codiceCatastale": "G887",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "stellacto3@tiscali.it",
      "pec": "ragioneria.stellacilento@asmepec.it",
      "telefono": "+39 0974.909078",
      "fax": "+39 0974.909900",
      "coordinate": {
        "lat": 40.233333587646484,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "065145",
      "nome": "Stio",
      "nomeStraniero": null,
      "codiceCatastale": "I960",
      "cap": "84075",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "affarigenerali@comune.stio.sa.it",
      "pec": "protocollo@pec.comune.stio.sa.it",
      "telefono": "+39 0974 990034",
      "fax": "+39 0974 990046",
      "coordinate": {
        "lat": 40.31019973754883,
        "lng": 15.251529693603516
      }
    },
    {
      "codice": "065146",
      "nome": "Teggiano",
      "nomeStraniero": null,
      "codiceCatastale": "D292",
      "cap": "84039",
      "prefisso": "0975",
      "provincia": "Salerno",
      "email": "amministrazione@comune.teggiano.sa.it",
      "pec": "protocollo-notifiche.teggiano@asmepec.it",
      "telefono": "+39 0975 587811",
      "fax": "+39 0975 587833",
      "coordinate": {
        "lat": 40.38333511352539,
        "lng": 15.533333778381348
      }
    },
    {
      "codice": "065147",
      "nome": "Torchiara",
      "nomeStraniero": null,
      "codiceCatastale": "L212",
      "cap": "84076",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "airetorchiara@virgilio.it",
      "pec": "info@pec.comunetorchiara.it",
      "telefono": "+39 0974 831102",
      "fax": "+39 0974 831921",
      "coordinate": {
        "lat": 40.31666564941406,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "065148",
      "nome": "Torraca",
      "nomeStraniero": null,
      "codiceCatastale": "L233",
      "cap": "84030",
      "prefisso": "0973",
      "provincia": "Salerno",
      "email": "domenicoleone@comune.torraca.sa.it",
      "pec": "affarigenerali@asmepec.it",
      "telefono": "+39 0973 398127",
      "fax": "+39 0973 398255",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 15.633333206176758
      }
    },
    {
      "codice": "065149",
      "nome": "Torre Orsaia",
      "nomeStraniero": null,
      "codiceCatastale": "L274",
      "cap": "84077",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "ufficioragioneria@comune.torre-orsaia.sa.it",
      "pec": "ragioneria.torreorsaia@asmepec.it",
      "telefono": "+39 0974 985336",
      "fax": "+39 0974 985156",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 15.466666221618652
      }
    },
    {
      "codice": "065150",
      "nome": "Tortorella",
      "nomeStraniero": null,
      "codiceCatastale": "L306",
      "cap": "84030",
      "prefisso": "0973",
      "provincia": "Salerno",
      "email": "mail@comune.tortorella.sa.it",
      "pec": "servizidemografici.tortorella@asmepec.it",
      "telefono": "+39 0973 374366",
      "fax": "+39 0973 374474",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 15.600000381469727
      }
    },
    {
      "codice": "065151",
      "nome": "Tramonti",
      "nomeStraniero": null,
      "codiceCatastale": "L323",
      "cap": "84010",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "info@comunetramonti.it",
      "pec": "protocollo.tramonti@asmepec.it",
      "telefono": "+39 089 856811",
      "fax": "+39 089 876522",
      "coordinate": {
        "lat": 40.70000076293945,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "065152",
      "nome": "Trentinara",
      "nomeStraniero": null,
      "codiceCatastale": "L377",
      "cap": "84070",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "info@comune.trentinara.sa.it",
      "pec": "protocollo@pec.comune.trentinara.sa.it",
      "telefono": "+39 0828 831032",
      "fax": "+39 0828 831040",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "065153",
      "nome": "Valle dell'Angelo",
      "nomeStraniero": null,
      "codiceCatastale": "G540",
      "cap": "84070",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "protocollo@comune.valledellangelo.sa.it",
      "pec": "affarigenerali@pec.comune.valledellangelo.sa.it",
      "telefono": "+39 0974.942016",
      "fax": "+39 0974.942016",
      "coordinate": {
        "lat": 40.344722747802734,
        "lng": 15.369166374206543
      }
    },
    {
      "codice": "065154",
      "nome": "Vallo della Lucania",
      "nomeStraniero": null,
      "codiceCatastale": "L628",
      "cap": "84078",
      "prefisso": "0974",
      "provincia": "Salerno",
      "email": "mariantonietta.dimotta@vallodellalucania.gov.it",
      "pec": "prot.vallodellalucania@legalmail.it",
      "telefono": "+39 0974.714111",
      "fax": "+39 0974 718064",
      "coordinate": {
        "lat": 40.233333587646484,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "065155",
      "nome": "Valva",
      "nomeStraniero": null,
      "codiceCatastale": "L656",
      "cap": "84020",
      "prefisso": "0828",
      "provincia": "Salerno",
      "email": "comunedivalva@tiscali.it",
      "pec": "anagrafe@pec.comune.valva.sa.it",
      "telefono": "+39 0828 796001",
      "fax": "+39 0828 796001",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 15.266667366027832
      }
    },
    {
      "codice": "065156",
      "nome": "Vibonati",
      "nomeStraniero": null,
      "codiceCatastale": "L835",
      "cap": "84079",
      "prefisso": "0973",
      "provincia": "Salerno",
      "email": "info@comune.vibonati.sa.it",
      "pec": "comunevibonati@legalmail.it",
      "telefono": "+39 0973 301611",
      "fax": "+39 0973 301415",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 15.583333015441895
      }
    },
    {
      "codice": "065157",
      "nome": "Vietri sul Mare",
      "nomeStraniero": null,
      "codiceCatastale": "L860",
      "cap": "84019",
      "prefisso": "089",
      "provincia": "Salerno",
      "email": "aa.gg.legale@comune.vietri-sul-mare.sa.it",
      "pec": "protocollo@pec.comune.vietri-sul-mare.sa.it",
      "telefono": "+39 089-763801",
      "fax": "+39 089-763838",
      "coordinate": {
        "lat": 40.67222213745117,
        "lng": 14.727777481079102
      }
    }
  ],
  "Napoli": [
    {
      "codice": "063001",
      "nome": "Acerra",
      "nomeStraniero": null,
      "codiceCatastale": "A024",
      "cap": "80011",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.acerra.na.it",
      "pec": "protocollo@pec.comuneacerra.it",
      "telefono": "+39 0815219232",
      "fax": "+39 0815219002",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "063002",
      "nome": "Afragola",
      "nomeStraniero": null,
      "codiceCatastale": "A064",
      "cap": "80021",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.afragola.na.it",
      "pec": "protocollo@pec.comune.afragola.na.it",
      "telefono": "+39 081 8529111",
      "fax": "+39 081 8603189",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.316666603088379
      }
    },
    {
      "codice": "063003",
      "nome": "Agerola",
      "nomeStraniero": null,
      "codiceCatastale": "A068",
      "cap": "80051",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.agerola.na.it",
      "pec": "protocollo.agerola@asmepec.it",
      "telefono": "+39 081 8740211",
      "fax": "+39 081 8740214",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063004",
      "nome": "Anacapri",
      "nomeStraniero": null,
      "codiceCatastale": "A268",
      "cap": "80071",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "demografico@comunedianacapri.it",
      "pec": "demografico.comunedianacapri@pec.it",
      "telefono": "+39 081 8387212",
      "fax": "+39 081 8373445",
      "coordinate": {
        "lat": 40.551387786865234,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "063005",
      "nome": "Arzano",
      "nomeStraniero": null,
      "codiceCatastale": "A455",
      "cap": "80022",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segretariogenerale@comune.arzano.na.it",
      "pec": "protocollo@pec.comune.arzano.na.it",
      "telefono": "+39 081 5850111",
      "fax": "+39 081 7311567",
      "coordinate": {
        "lat": 40.915279388427734,
        "lng": 14.26805591583252
      }
    },
    {
      "codice": "063006",
      "nome": "Bacoli",
      "nomeStraniero": null,
      "codiceCatastale": "A535",
      "cap": "80070",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ufficioprotocollo@comune.bacoli.na.it",
      "pec": "ufficio.protocollo@pec.comune.bacoli.na.it",
      "telefono": "+39 081 8553111",
      "fax": "+39 081 5234583",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "063007",
      "nome": "Barano d'Ischia",
      "nomeStraniero": null,
      "codiceCatastale": "A617",
      "cap": "80070",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocolllo@comunebarano.it",
      "pec": "protocollo@pec.comunebarano.it",
      "telefono": "+39 081906711",
      "fax": "+39 081906736",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "063008",
      "nome": "Boscoreale",
      "nomeStraniero": null,
      "codiceCatastale": "B076",
      "cap": "80041",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ufficio.protocollo@boscoreale.gov.it",
      "pec": "ufficioelettorale@pec.comune.boscoreale.na.it",
      "telefono": "+39 081 8581664",
      "fax": "+39 081 8581664",
      "coordinate": {
        "lat": 40.775001525878906,
        "lng": 14.475000381469727
      }
    },
    {
      "codice": "063009",
      "nome": "Boscotrecase",
      "nomeStraniero": null,
      "codiceCatastale": "B077",
      "cap": "80042",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comune.boscotrecase.na.it",
      "pec": "protocollo.boscotrecase@pec.it",
      "telefono": "+39 0815373838",
      "fax": "+39 0818587201",
      "coordinate": {
        "lat": 40.78333282470703,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "063010",
      "nome": "Brusciano",
      "nomeStraniero": null,
      "codiceCatastale": "B227",
      "cap": "80031",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "anagrafe@comune.brusciano.na.it",
      "pec": "protocollo@pec.comune.brusciano.na.it",
      "telefono": "+39 081 5218111",
      "fax": "+39 081 5190191",
      "coordinate": {
        "lat": 40.92237091064453,
        "lng": 14.423859596252441
      }
    },
    {
      "codice": "063011",
      "nome": "Caivano",
      "nomeStraniero": null,
      "codiceCatastale": "B371",
      "cap": "80023",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "i.carrara@comune.caivano.na.it",
      "pec": "anagelett.caivano@asmepec.it",
      "telefono": "+39 081 8323216",
      "fax": null,
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "063012",
      "nome": "Calvizzano",
      "nomeStraniero": null,
      "codiceCatastale": "B452",
      "cap": "80012",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "servizisociali@calvizzano.telecompost.it",
      "pec": "comune@calvizzano.telecompost.it",
      "telefono": "+39 081 7120701",
      "fax": "+39 081 7121875",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "063013",
      "nome": "Camposano",
      "nomeStraniero": null,
      "codiceCatastale": "B565",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.camposano.na.it",
      "pec": "affarigenerali.camposano@pec.it",
      "telefono": "+39 081.315.02.00",
      "fax": "+39 081.315.02.01",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "063014",
      "nome": "Capri",
      "nomeStraniero": null,
      "codiceCatastale": "B696",
      "cap": "80073",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteriasindaco@cittadicapri.it",
      "pec": "affarigenerali.cittadicapri@legalmail.it",
      "telefono": "+39 081 8386111",
      "fax": "+39 081 8386246",
      "coordinate": {
        "lat": 40.55555725097656,
        "lng": 14.240278244018555
      }
    },
    {
      "codice": "063015",
      "nome": "Carbonara di Nola",
      "nomeStraniero": null,
      "codiceCatastale": "B740",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteria2carbonara@alice.it",
      "pec": "carbonaradinola.prot@pec.it",
      "telefono": "+39 081 8253402",
      "fax": "+39 081 8253114",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 14.583333015441895
      }
    },
    {
      "codice": "063016",
      "nome": "Cardito",
      "nomeStraniero": null,
      "codiceCatastale": "B759",
      "cap": "80024",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "stipendi.cardito@gmail.com",
      "pec": "protocollo.cardito@asmepec.it",
      "telefono": "+39 081.19728438",
      "fax": "+39 081 19728439",
      "coordinate": {
        "lat": 40.93619155883789,
        "lng": 14.299249649047852
      }
    },
    {
      "codice": "063017",
      "nome": "Casalnuovo di Napoli",
      "nomeStraniero": null,
      "codiceCatastale": "B905",
      "cap": "80013",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "affarigener@comune.casalnuovo.na.it",
      "pec": "protocollogen.casalnuovo.na@pec.actalis.it",
      "telefono": "+39 081 5214111",
      "fax": "+39 081 8425567",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "063018",
      "nome": "Casamarciano",
      "nomeStraniero": null,
      "codiceCatastale": "B922",
      "cap": "80032",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "urp@comune.casamarciano.na.it",
      "pec": "casamarciano@pec.comune.casamarciano.na.it",
      "telefono": "+39 0818231825",
      "fax": "+39 0815124198",
      "coordinate": {
        "lat": 40.93191146850586,
        "lng": 14.553239822387695
      }
    },
    {
      "codice": "063019",
      "nome": "Casamicciola Terme",
      "nomeStraniero": null,
      "codiceCatastale": "B924",
      "cap": "80074",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "anagrafe@comunecasamicciola.it",
      "pec": "protocollo@pec.comunecasamicciola.it",
      "telefono": "+39 081-5072582",
      "fax": null,
      "coordinate": {
        "lat": 40.75,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "063020",
      "nome": "Casandrino",
      "nomeStraniero": null,
      "codiceCatastale": "B925",
      "cap": "80025",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": null,
      "pec": "protocollo@pec.comunecasandrino.it",
      "telefono": "+39 08119523631",
      "fax": null,
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.25
      }
    },
    {
      "codice": "063021",
      "nome": "Casavatore",
      "nomeStraniero": null,
      "codiceCatastale": "B946",
      "cap": "80020",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "antonietta.fen@libero.it",
      "pec": "elettorale.casavatore@asmepec.it",
      "telefono": "+39 081 2360303",
      "fax": null,
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "063022",
      "nome": "Casola di Napoli",
      "nomeStraniero": null,
      "codiceCatastale": "B980",
      "cap": "80050",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "demografici@comune.casoladinapoli.na.it",
      "pec": "comune@pec.comune.casoladinapoli.na.it",
      "telefono": "+39 0818012890",
      "fax": "+39 08119308265",
      "coordinate": {
        "lat": 40.69444274902344,
        "lng": 14.527777671813965
      }
    },
    {
      "codice": "063023",
      "nome": "Casoria",
      "nomeStraniero": null,
      "codiceCatastale": "B990",
      "cap": "80026",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo.generale@comune.casoria.na.it",
      "pec": "protocollo@pec.comune.casoria.na.it",
      "telefono": "+39 0817053111",
      "fax": "+39 0817572610",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "063024",
      "nome": "Castellammare di Stabia",
      "nomeStraniero": null,
      "codiceCatastale": "C129",
      "cap": "80053",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comunestabia.it",
      "pec": "protocollo.stabia@asmepec.it",
      "telefono": "+39 081 3900111",
      "fax": "+39 081 8726284",
      "coordinate": {
        "lat": 40.69472122192383,
        "lng": 14.480278015136719
      }
    },
    {
      "codice": "063025",
      "nome": "Castello di Cisterna",
      "nomeStraniero": null,
      "codiceCatastale": "C188",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ufficioanagrafe@comune.castellodicisterna.na.it",
      "pec": "anagrafe@pec.comune.castellodicisterna.na.it",
      "telefono": "+39 081 8033810",
      "fax": "+39 081 3177439",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "063026",
      "nome": "Cercola",
      "nomeStraniero": null,
      "codiceCatastale": "C495",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segretario@comune.cercola.na.it",
      "pec": "comune.cercola@asmepec.it",
      "telefono": "+39 081.2581111",
      "fax": "+39 081.7331694",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "063027",
      "nome": "Cicciano",
      "nomeStraniero": null,
      "codiceCatastale": "C675",
      "cap": "80033",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "elettorale@comune.cicciano.na.it",
      "pec": "elettorale.cicciano@asmepec.it",
      "telefono": "+39 0813150443",
      "fax": "+39 0818248560",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "063028",
      "nome": "Cimitile",
      "nomeStraniero": null,
      "codiceCatastale": "C697",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.cimitile.na.it",
      "pec": "protocollo.cimitile@asmepec.it",
      "telefono": "+39 081 3110311",
      "fax": "+39 081 5122061",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "063029",
      "nome": "Comiziano",
      "nomeStraniero": null,
      "codiceCatastale": "C929",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.comiziano.na.it",
      "pec": "protocollo.comiziano@asmepec.it",
      "telefono": "+39 081.8297412",
      "fax": "+39 081.8298464",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063030",
      "nome": "Crispano",
      "nomeStraniero": null,
      "codiceCatastale": "D170",
      "cap": "80020",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "elettorale@comune.crispano.na.it",
      "pec": "elettorale@pec.comune.crispano.na.it",
      "telefono": "+39 081 8362025",
      "fax": "+39 081 8363599",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "063064",
      "nome": "Ercolano",
      "nomeStraniero": null,
      "codiceCatastale": "H243",
      "cap": "80056",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteriasindaco@comune.ercolano.na.it",
      "pec": "protocollo.ercolano@legalmail.it",
      "telefono": "+39 081 7881111",
      "fax": "+39 081 7881261",
      "coordinate": {
        "lat": 40.806793212890625,
        "lng": 14.352608680725098
      }
    },
    {
      "codice": "063031",
      "nome": "Forio",
      "nomeStraniero": null,
      "codiceCatastale": "D702",
      "cap": "80075",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "elettorale@comune.forio.na.it",
      "pec": "elettorale@pec.comune.forio.na.it",
      "telefono": "+39 0813332969",
      "fax": "+39 0813332952",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 13.850000381469727
      }
    },
    {
      "codice": "063032",
      "nome": "Frattamaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "D789",
      "cap": "80027",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "elettorale@comune.frattamaggiore.na.it",
      "pec": "protocollo.frattamaggiore@asmepec.it",
      "telefono": "+39 0818890206/203",
      "fax": "+39 0818315622  +39 0818346616",
      "coordinate": {
        "lat": 40.94166564941406,
        "lng": 14.272222518920898
      }
    },
    {
      "codice": "063033",
      "nome": "Frattaminore",
      "nomeStraniero": null,
      "codiceCatastale": "D790",
      "cap": "80020",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo.frattaminore@asmepec.it",
      "pec": "protocollo.frattaminore@asmepec.it",
      "telefono": "+39 0815058268",
      "fax": null,
      "coordinate": {
        "lat": 40.9555549621582,
        "lng": 14.270833015441895
      }
    },
    {
      "codice": "063034",
      "nome": "Giugliano in Campania",
      "nomeStraniero": null,
      "codiceCatastale": "E054",
      "cap": "80014",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "servizi.demografici@comune.giugliano.na.it",
      "pec": "protocollo@pec.comune.giugliano.na.it",
      "telefono": "+39 081 8956111",
      "fax": "+39 081 3301542",
      "coordinate": {
        "lat": 40.931880950927734,
        "lng": 14.19556999206543
      }
    },
    {
      "codice": "063035",
      "nome": "Gragnano",
      "nomeStraniero": null,
      "codiceCatastale": "E131",
      "cap": "80054",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "comune@comune.gragnano.na.it",
      "pec": "affarigenerali.gragnano@asmepec.it",
      "telefono": "+39 081 8732111",
      "fax": "+39 081 8012836",
      "coordinate": {
        "lat": 40.69572067260742,
        "lng": 14.515449523925781
      }
    },
    {
      "codice": "063036",
      "nome": "Grumo Nevano",
      "nomeStraniero": null,
      "codiceCatastale": "E224",
      "cap": "80028",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "comunegrumonevano@comune.grumo-nevano.na.it",
      "pec": "comunegrumonevano@comune.grumo-nevano.na.it",
      "telefono": "+39 081 8327111",
      "fax": null,
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "063037",
      "nome": "Ischia",
      "nomeStraniero": null,
      "codiceCatastale": "E329",
      "cap": "80077",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteria.direzione@comuneischia.it",
      "pec": "protocollo@pec.comuneischia.it",
      "telefono": "+39 081 3333111",
      "fax": "+39 081 3333201",
      "coordinate": {
        "lat": 40.75,
        "lng": 13.949999809265137
      }
    },
    {
      "codice": "063038",
      "nome": "Lacco Ameno",
      "nomeStraniero": null,
      "codiceCatastale": "E396",
      "cap": "80076",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comunelaccoameno.it",
      "pec": "demografici@pec.comunelaccoameno.it",
      "telefono": "+39 081 3330817",
      "fax": null,
      "coordinate": {
        "lat": 40.75,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "063039",
      "nome": "Lettere",
      "nomeStraniero": null,
      "codiceCatastale": "E557",
      "cap": "80050",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comune.lettere.na.it",
      "pec": "affarigenerali.comunedilettere@pec.it",
      "telefono": "+39 081 8022720-32-40",
      "fax": "+39 081 8021681",
      "coordinate": {
        "lat": 40.70000076293945,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063040",
      "nome": "Liveri",
      "nomeStraniero": null,
      "codiceCatastale": "E620",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "personale@comune.liveri.na.it",
      "pec": "segreteria@pec.comune.liveri.na.it",
      "telefono": "+39 081 8255890",
      "fax": "+39 081 8255467",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "063041",
      "nome": "Marano di Napoli",
      "nomeStraniero": null,
      "codiceCatastale": "E906",
      "cap": "80016",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sabatinodelprete@comune.marano.na.it",
      "pec": "elettorale@pec.comune.marano.na.it",
      "telefono": "+39 081 5769324",
      "fax": null,
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "063042",
      "nome": "Mariglianella",
      "nomeStraniero": null,
      "codiceCatastale": "E954",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "aagg@comune.mariglianella.na.it",
      "pec": "comune.mariglianella@asmepec.it",
      "telefono": "+39 081 18331289",
      "fax": "+39 081 8855009",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.433333396911621
      }
    },
    {
      "codice": "063043",
      "nome": "Marigliano",
      "nomeStraniero": null,
      "codiceCatastale": "E955",
      "cap": "80034",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "comune.marigliano@tin.it",
      "pec": "protocollo@pec.comunemarigliano.it",
      "telefono": "+39 081 8858111",
      "fax": "+39 081 8855383",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "063092",
      "nome": "Massa di Somma",
      "nomeStraniero": null,
      "codiceCatastale": "M289",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo.massadisomma@cstnapoli.legalmail.it",
      "pec": "protocollo.massadisomma@cstnapoli.legalmail.it",
      "telefono": "+39 081 7883205",
      "fax": "+39 081 7883223",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "063044",
      "nome": "Massa Lubrense",
      "nomeStraniero": null,
      "codiceCatastale": "F030",
      "cap": "80061",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "aconero@comune.massalubrense.na.it",
      "pec": "protocollo.massalubrense@pec.it",
      "telefono": "+39 0815339401",
      "fax": "+39 0818789576",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "063045",
      "nome": "Melito di Napoli",
      "nomeStraniero": null,
      "codiceCatastale": "F111",
      "cap": "80017",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.melito.na.it",
      "pec": "protocollogenerale@pec.comune.melito.na.it",
      "telefono": "+39 081 2386111",
      "fax": "+39 081 7112748",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "063046",
      "nome": "Meta",
      "nomeStraniero": null,
      "codiceCatastale": "F162",
      "cap": "80062",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteria@comune.meta.na.it",
      "pec": "comune.meta@asmepec.it",
      "telefono": "+39 081 5321498",
      "fax": "+39 081 5322885",
      "coordinate": {
        "lat": 40.641666412353516,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "063047",
      "nome": "Monte di Procida",
      "nomeStraniero": null,
      "codiceCatastale": "F488",
      "cap": "80070",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteria@comune.montediprocida.na.it",
      "pec": "protocollo@pec.comune.montediprocida.na.it",
      "telefono": "+39 081 8684211",
      "fax": "+39 081 8682579",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "063048",
      "nome": "Mugnano di Napoli",
      "nomeStraniero": null,
      "codiceCatastale": "F799",
      "cap": "80018",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.mugnano.na.it",
      "pec": "protocollomugnano@pec.it",
      "telefono": "+39 081/5710111",
      "fax": "+39 081/5712262",
      "coordinate": {
        "lat": 40.909358978271484,
        "lng": 14.209839820861816
      }
    },
    {
      "codice": "063049",
      "nome": "Napoli",
      "nomeStraniero": null,
      "codiceCatastale": "F839",
      "cap": "80127",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.napoli.it",
      "pec": "protocollo@pec.comune.napoli.it",
      "telefono": "+39 081 7951111",
      "fax": "+39 081 7955509",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 14.25
      }
    },
    {
      "codice": "063050",
      "nome": "Nola",
      "nomeStraniero": null,
      "codiceCatastale": "F924",
      "cap": "80035",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "gabsindaco@comune.nola.na.it",
      "pec": "comunenola@pec.comune.nola.na.it",
      "telefono": "+39 081 8226111",
      "fax": "+39 081 8226289",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "063051",
      "nome": "Ottaviano",
      "nomeStraniero": null,
      "codiceCatastale": "G190",
      "cap": "80044",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ced@comune.ottaviano.na.it",
      "pec": "ced.ottaviano@pec.it",
      "telefono": "+39 0818280285",
      "fax": "+39 0818280254",
      "coordinate": {
        "lat": 40.849998474121094,
        "lng": 14.477499961853027
      }
    },
    {
      "codice": "063052",
      "nome": "Palma Campania",
      "nomeStraniero": null,
      "codiceCatastale": "G283",
      "cap": "80036",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.palmacampania.na.it",
      "pec": "protocollo@pec.comunepalmacampania.it",
      "telefono": "+39 081 8207411",
      "fax": "+39 081 8246065",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063053",
      "nome": "Piano di Sorrento",
      "nomeStraniero": null,
      "codiceCatastale": "G568",
      "cap": "80063",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "settore1@comune.pianodisorrento.na.it",
      "pec": "settore1@pec.comune.pianodisorrento.na.it",
      "telefono": "+39 081 534 4412",
      "fax": "+39 081 5321484",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 14.411110877990723
      }
    },
    {
      "codice": "063054",
      "nome": "Pimonte",
      "nomeStraniero": null,
      "codiceCatastale": "G670",
      "cap": "80050",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.pimonte.na.it",
      "pec": "protocollo.pimonte@asmepec.it",
      "telefono": "+39 081 3909923",
      "fax": "+39",
      "coordinate": {
        "lat": 40.67361068725586,
        "lng": 14.512499809265137
      }
    },
    {
      "codice": "063055",
      "nome": "Poggiomarino",
      "nomeStraniero": null,
      "codiceCatastale": "G762",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "rosa.finaldi@comune.poggiomarino.na.it",
      "pec": "elettorale@pec.comune.poggiomarino.na.it",
      "telefono": "0818658202",
      "fax": "0818658249",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063056",
      "nome": "Pollena Trocchia",
      "nomeStraniero": null,
      "codiceCatastale": "G795",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comune.pollenatrocchia.na.it",
      "pec": "protocollo@pec.comune.pollenatrocchia.na.it",
      "telefono": "+39 081 8936742",
      "fax": "+39 081 8936711",
      "coordinate": {
        "lat": 40.8558349609375,
        "lng": 14.38277816772461
      }
    },
    {
      "codice": "063057",
      "nome": "Pomigliano d'Arco",
      "nomeStraniero": null,
      "codiceCatastale": "G812",
      "cap": "80038",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.pomiglianodarco.gov.it",
      "pec": "comune.pomiglianodarco@legalmail.it",
      "telefono": "+39 081 5217111",
      "fax": "+39 081 5217206",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "063058",
      "nome": "Pompei",
      "nomeStraniero": null,
      "codiceCatastale": "G813",
      "cap": "80045",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteriasindaco@comune.pompei.na.it",
      "pec": "protocollo@pec.comune.pompei.na.it",
      "telefono": "+39 081 8576111",
      "fax": "+39 081 8506015",
      "coordinate": {
        "lat": 40.75,
        "lng": 14.5
      }
    },
    {
      "codice": "063059",
      "nome": "Portici",
      "nomeStraniero": null,
      "codiceCatastale": "G902",
      "cap": "80055",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.portici.na.it",
      "pec": "protocollo@pec.comuneportici.it",
      "telefono": "+39 081 7862111",
      "fax": "+39 081 7862211",
      "coordinate": {
        "lat": 40.81972122192383,
        "lng": 14.341111183166504
      }
    },
    {
      "codice": "063060",
      "nome": "Pozzuoli",
      "nomeStraniero": null,
      "codiceCatastale": "G964",
      "cap": "80078",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco.pozzuoli@comune.pozzuoli.na.it",
      "pec": "info@pec2.comune.pozzuoli.na.it",
      "telefono": "+39 081 8551111",
      "fax": "+39 081 8046012",
      "coordinate": {
        "lat": 40.823055267333984,
        "lng": 14.122221946716309
      }
    },
    {
      "codice": "063061",
      "nome": "Procida",
      "nomeStraniero": null,
      "codiceCatastale": "H072",
      "cap": "80079",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "comunediprocida2003@libero.it",
      "pec": "comune.procida@asmepec.it",
      "telefono": "+39 081 8109203",
      "fax": "+39 081 8968803",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "063062",
      "nome": "Qualiano",
      "nomeStraniero": null,
      "codiceCatastale": "H101",
      "cap": "80019",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segretario@comune.qualiano.na.it",
      "pec": "protocollo.qualiano@asmepec.it",
      "telefono": "+39 081 8192001",
      "fax": "+39 081 8192252",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.149999618530273
      }
    },
    {
      "codice": "063063",
      "nome": "Quarto",
      "nomeStraniero": null,
      "codiceCatastale": "H114",
      "cap": "80010",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo.generale@comune.quarto.na.it",
      "pec": "protocollogenerale@pec.comune.quarto.na.it",
      "telefono": "+39 081 8069111",
      "fax": "+39 081 8768656",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "063065",
      "nome": "Roccarainola",
      "nomeStraniero": null,
      "codiceCatastale": "H433",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segretario@comune.roccarainola.na.it",
      "pec": "protocollo.roccarainola@pec.it",
      "telefono": "+39 081 8293449",
      "fax": "+39 081 5118414",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "063066",
      "nome": "San Gennaro Vesuviano",
      "nomeStraniero": null,
      "codiceCatastale": "H860",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "elettorale@comune.sangennarovesuviano.na.it",
      "pec": "elettorale.sgv@asmepec.it",
      "telefono": "+39 081-8286918-38-15",
      "fax": "+39 081-8286918-15",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "063067",
      "nome": "San Giorgio a Cremano",
      "nomeStraniero": null,
      "codiceCatastale": "H892",
      "cap": "80046",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@e-cremano.it",
      "pec": "protocollo.generale@cremano.telecompost.it",
      "telefono": "+39 081 5654111",
      "fax": "+39 081 5654240",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "063068",
      "nome": "San Giuseppe Vesuviano",
      "nomeStraniero": null,
      "codiceCatastale": "H931",
      "cap": "80047",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ufficioelettorale@comune.sangiuseppevesuviano.na.it",
      "pec": "protocollocomunesangiuseppevesuviano@postecert.it",
      "telefono": "+39 081.8285111",
      "fax": "+39 081.5295682",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 14.5
      }
    },
    {
      "codice": "063069",
      "nome": "San Paolo Bel Sito",
      "nomeStraniero": null,
      "codiceCatastale": "I073",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "comune.sanpaolona@libero.it",
      "pec": "protocollo.sanpaolobelsito@pec.it",
      "telefono": "+39 081-8295676",
      "fax": "+39 081-5105359",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063070",
      "nome": "San Sebastiano al Vesuvio",
      "nomeStraniero": null,
      "codiceCatastale": "I151",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comunesansebastianoalvesuvio.legalmailpa.it",
      "pec": "protocollo@comunesansebastianoalvesuvio.legalmailpa.it",
      "telefono": "+39 081/7867109",
      "fax": "+39 0817867136",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "063075",
      "nome": "San Vitaliano",
      "nomeStraniero": null,
      "codiceCatastale": "I391",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comune.sanvitaliano.na.it",
      "pec": "protocollo.comunesanvitaliano@pec.it",
      "telefono": "+39 0818445540",
      "fax": "+39 0818445543",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "063071",
      "nome": "Sant'Agnello",
      "nomeStraniero": null,
      "codiceCatastale": "I208",
      "cap": "80065",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "urp@comune.sant-agnello.na.it",
      "pec": "segreteria.santagnello@asmepec.it",
      "telefono": "+39 081.5332200",
      "fax": "+39 081 8771226",
      "coordinate": {
        "lat": 40.62942123413086,
        "lng": 14.39957046508789
      }
    },
    {
      "codice": "063072",
      "nome": "Sant'Anastasia",
      "nomeStraniero": null,
      "codiceCatastale": "I262",
      "cap": "80048",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "pasqualeperna@comune.santanastasia.na.it",
      "pec": "protocollo@pec.comunesantanastasia.it",
      "telefono": "+39 081 8930111",
      "fax": "+39 081 8982890",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "063073",
      "nome": "Sant'Antimo",
      "nomeStraniero": null,
      "codiceCatastale": "I293",
      "cap": "80029",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.santantimo.na.it",
      "pec": "protocollo@pec.comune.santantimo.na.it",
      "telefono": "+39 0818329111",
      "fax": "+39 0818337110",
      "coordinate": {
        "lat": 40.942230224609375,
        "lng": 14.234760284423828
      }
    },
    {
      "codice": "063074",
      "nome": "Sant'Antonio Abate",
      "nomeStraniero": null,
      "codiceCatastale": "I300",
      "cap": "80057",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "vice.segretario@comunesantantonioabate.it",
      "pec": "protocollo.comunesantantonioabate@pec.it",
      "telefono": "+39 081 3911211",
      "fax": "+39 081 8797793",
      "coordinate": {
        "lat": 40.733333587646484,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "063090",
      "nome": "Santa Maria la Carità",
      "nomeStraniero": null,
      "codiceCatastale": "M273",
      "cap": "80050",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ragioneria@comune.santa-maria-la-carita-na.it",
      "pec": "protocollo.santamarialacarita@asmepec.it",
      "telefono": "+39 081 3910111",
      "fax": "+39 081 3910215",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 14.516667366027832
      }
    },
    {
      "codice": "063076",
      "nome": "Saviano",
      "nomeStraniero": null,
      "codiceCatastale": "I469",
      "cap": "80039",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.saviano.na.it",
      "pec": "protocollo.saviano@asmepec.it",
      "telefono": "+39 081.8203811",
      "fax": null,
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "063077",
      "nome": "Scisciano",
      "nomeStraniero": null,
      "codiceCatastale": "I540",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "comunescisciano@libero.it",
      "pec": "comune.scisciano@pec.it",
      "telefono": "+39 0815198998",
      "fax": "+39 0815198639",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "063078",
      "nome": "Serrara Fontana",
      "nomeStraniero": null,
      "codiceCatastale": "I652",
      "cap": "80070",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "serrara.fontana@libero.it",
      "pec": "protocollo.serrarafontana@asmepec.it",
      "telefono": "+39 081 9048811",
      "fax": "+39 081 999626",
      "coordinate": {
        "lat": 40.71666717529297,
        "lng": 13.899999618530273
      }
    },
    {
      "codice": "063079",
      "nome": "Somma Vesuviana",
      "nomeStraniero": null,
      "codiceCatastale": "I820",
      "cap": "80049",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segretario@sommavesuviana.info",
      "pec": "segretario@pec.sommavesuviana.info",
      "telefono": "+39 081 8939111",
      "fax": "+39 081 8931104",
      "coordinate": {
        "lat": 40.872501373291016,
        "lng": 14.436944007873535
      }
    },
    {
      "codice": "063080",
      "nome": "Sorrento",
      "nomeStraniero": null,
      "codiceCatastale": "I862",
      "cap": "80067",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comune.sorrento.na.it",
      "pec": "protocollo@pec.comune.sorrento.na.it",
      "telefono": "+39 081 5335111",
      "fax": "+39 081 8771980",
      "coordinate": {
        "lat": 40.627777099609375,
        "lng": 14.373611450195312
      }
    },
    {
      "codice": "063081",
      "nome": "Striano",
      "nomeStraniero": null,
      "codiceCatastale": "I978",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "u.dardo@striano.gov.it",
      "pec": "protocollo@pec.striano.gov.it",
      "telefono": "+39 0818276202",
      "fax": "+39 0818276103",
      "coordinate": {
        "lat": 40.81666564941406,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "063082",
      "nome": "Terzigno",
      "nomeStraniero": null,
      "codiceCatastale": "L142",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteria1@comune.terzigno.na.it",
      "pec": "protocollo@pec.comune.terzigno.na.it",
      "telefono": "+39 081.3389511",
      "fax": "+39 081.3389577",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 14.5
      }
    },
    {
      "codice": "063083",
      "nome": "Torre Annunziata",
      "nomeStraniero": null,
      "codiceCatastale": "L245",
      "cap": "80058",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "sindaco@comune.torreannunziata.na.it",
      "pec": "dirigenteariano@pec.comune.torreannunziata.na.it",
      "telefono": "+39 0815358203",
      "fax": "+39 0815368041",
      "coordinate": {
        "lat": 40.75694274902344,
        "lng": 14.44444465637207
      }
    },
    {
      "codice": "063084",
      "nome": "Torre del Greco",
      "nomeStraniero": null,
      "codiceCatastale": "L259",
      "cap": "80059",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "protocollo@comune.torredelgreco.na.it",
      "pec": "protocollo.torredelgreco@asmepec.it",
      "telefono": "+39 081 8491655",
      "fax": "+39 081 8493630",
      "coordinate": {
        "lat": 40.7852783203125,
        "lng": 14.395277976989746
      }
    },
    {
      "codice": "063091",
      "nome": "Trecase",
      "nomeStraniero": null,
      "codiceCatastale": "M280",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "ufficiosegreteria@comunetrecase.it",
      "pec": "segretariogenerale.trecase@pec.it",
      "telefono": "+39 0815365761-0815366081",
      "fax": "+39 0815369680",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 14.433333396911621
      }
    },
    {
      "codice": "063085",
      "nome": "Tufino",
      "nomeStraniero": null,
      "codiceCatastale": "L460",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "demografici@comune.tufino.na.it",
      "pec": "demografici@pec.comune.tufino.na.it",
      "telefono": "08108298105",
      "fax": "+39 081 8297196",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "063086",
      "nome": "Vico Equense",
      "nomeStraniero": null,
      "codiceCatastale": "L845",
      "cap": "80069",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "segreteria@comunevicoequense.it",
      "pec": "protocollo@pec.comunevicoequense.it",
      "telefono": "+390818019001",
      "fax": "+390818019393",
      "coordinate": {
        "lat": 40.66666793823242,
        "lng": 14.433333396911621
      }
    },
    {
      "codice": "063087",
      "nome": "Villaricca",
      "nomeStraniero": null,
      "codiceCatastale": "G309",
      "cap": "80010",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comune.villaricca.na.it",
      "pec": "protocollo.villaricca@asmepec.it",
      "telefono": "+39 081 8191111",
      "fax": "+39 081 5064147",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "063088",
      "nome": "Visciano",
      "nomeStraniero": null,
      "codiceCatastale": "M072",
      "cap": "80030",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "info@comunevisciano.na.it",
      "pec": "protocollo@pec.comunevisciano.na.it",
      "telefono": "+39 081 8299208",
      "fax": "+39 081 8299898",
      "coordinate": {
        "lat": 40.92380142211914,
        "lng": 14.582369804382324
      }
    },
    {
      "codice": "063089",
      "nome": "Volla",
      "nomeStraniero": null,
      "codiceCatastale": "M115",
      "cap": "80040",
      "prefisso": "081",
      "provincia": "Napoli",
      "email": "elettorale@comune.volla.na.it",
      "pec": "protocollo.pec.volla@pec.it",
      "telefono": "+39 081 2585243",
      "fax": "+39 081 2585243",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 14.333333015441895
      }
    }
  ],
  "Catania": [
    {
      "codice": "087001",
      "nome": "Aci Bonaccorsi",
      "nomeStraniero": null,
      "codiceCatastale": "A025",
      "cap": "95020",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune.acibonaccorsi.ct.it",
      "pec": "protocollo@pec.comune.acibonaccorsi.ct.it",
      "telefono": "+39 095 7899008",
      "fax": "+39 095 7899001",
      "coordinate": {
        "lat": 37.59552001953125,
        "lng": 15.109514236450195
      }
    },
    {
      "codice": "087002",
      "nome": "Aci Castello",
      "nomeStraniero": null,
      "codiceCatastale": "A026",
      "cap": "95021",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "urp@comune.acicastello.ct.it",
      "pec": "acicastello@eologov.org",
      "telefono": "+39 095 7371111",
      "fax": "+39 095 7373225",
      "coordinate": {
        "lat": 37.54999923706055,
        "lng": 15.149999618530273
      }
    },
    {
      "codice": "087003",
      "nome": "Aci Catena",
      "nomeStraniero": null,
      "codiceCatastale": "A027",
      "cap": "95022",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "elettorale@comune.acicatena.ct.it",
      "pec": "comune.acicatena@pec.it",
      "telefono": "+39 095 7684221",
      "fax": "+39 095 7684219",
      "coordinate": {
        "lat": 37.599998474121094,
        "lng": 15.149999618530273
      }
    },
    {
      "codice": "087005",
      "nome": "Aci Sant'Antonio",
      "nomeStraniero": null,
      "codiceCatastale": "A029",
      "cap": "95025",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@comuneacisantantonio.ct.it",
      "pec": "comune-acisantantonio@legalmail.it",
      "telefono": "+39 095.7010011",
      "fax": "+39 095 7010042",
      "coordinate": {
        "lat": 37.605892181396484,
        "lng": 15.12604808807373
      }
    },
    {
      "codice": "087004",
      "nome": "Acireale",
      "nomeStraniero": null,
      "codiceCatastale": "A028",
      "cap": "95024",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune.acireale.ct.it",
      "pec": "protocollo@pec.comune.acireale.ct.it",
      "telefono": "+39 095/895111",
      "fax": "+39 095 606048",
      "coordinate": {
        "lat": 37.61249923706055,
        "lng": 15.165555953979492
      }
    },
    {
      "codice": "087006",
      "nome": "Adrano",
      "nomeStraniero": null,
      "codiceCatastale": "A056",
      "cap": "95031",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune.adrano.ct.it",
      "pec": "protocollo@pec.comune.adrano.ct.it",
      "telefono": "+39 0957606111",
      "fax": "+39 095 7602356",
      "coordinate": {
        "lat": 37.662498474121094,
        "lng": 14.835556030273438
      }
    },
    {
      "codice": "087007",
      "nome": "Belpasso",
      "nomeStraniero": null,
      "codiceCatastale": "A766",
      "cap": "95032",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@pec.comune.belpasso.ct.it",
      "pec": "protocollo@pec.comune.belpasso.ct.it",
      "telefono": "+39 095 7051229",
      "fax": "+39 095 7051227",
      "coordinate": {
        "lat": 37.58333206176758,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "087008",
      "nome": "Biancavilla",
      "nomeStraniero": null,
      "codiceCatastale": "A841",
      "cap": "95033",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune.biancavilla.ct.it",
      "pec": "protocollo@pec.comune.biancavilla.ct.it",
      "telefono": "+39 095 7600111",
      "fax": "+39 095 7600428",
      "coordinate": {
        "lat": 37.64527893066406,
        "lng": 14.863611221313477
      }
    },
    {
      "codice": "087009",
      "nome": "Bronte",
      "nomeStraniero": null,
      "codiceCatastale": "B202",
      "cap": "95034",
      "prefisso": "095",
      "provincia": "Catania",
      "email": null,
      "pec": "protocollo.generale@brontepec.e-etna.it",
      "telefono": "+39 095 7747111",
      "fax": null,
      "coordinate": {
        "lat": 37.79999923706055,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "087010",
      "nome": "Calatabiano",
      "nomeStraniero": null,
      "codiceCatastale": "B384",
      "cap": "95011",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "protocollo@comune.calatabiano.ct.it",
      "pec": "protocollo_generale@calatabianopec.e-etna.it",
      "telefono": "+39 0957771039",
      "fax": "+39 0957771080",
      "coordinate": {
        "lat": 37.822776794433594,
        "lng": 15.227777481079102
      }
    },
    {
      "codice": "087011",
      "nome": "Caltagirone",
      "nomeStraniero": null,
      "codiceCatastale": "B428",
      "cap": "95041",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "dirigente-area1@comune.caltagirone.ct.it",
      "pec": "sindaco.caltagirone@pec.anci.it",
      "telefono": "+39 0933 41111",
      "fax": "+39 0933 41338",
      "coordinate": {
        "lat": 37.233333587646484,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "087012",
      "nome": "Camporotondo Etneo",
      "nomeStraniero": null,
      "codiceCatastale": "B561",
      "cap": "95040",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "uff.anagrafe@comune.camporotondoetneo.ct.it",
      "pec": "comunecamporotondoetneo.ct.sindaco@pa.postacertificata.gov.it",
      "telefono": "+39 095 7548017",
      "fax": "+39 095 7548034",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 15
      }
    },
    {
      "codice": "087013",
      "nome": "Castel di Iudica",
      "nomeStraniero": null,
      "codiceCatastale": "C091",
      "cap": "95040",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "segreteria@comune.casteldiiudica.ct.it",
      "pec": "affarigenerali@pec.comunecasteldiiudica.it",
      "telefono": "+39 095.661288",
      "fax": "+39 095.661005",
      "coordinate": {
        "lat": 37.5,
        "lng": 14.649999618530273
      }
    },
    {
      "codice": "087014",
      "nome": "Castiglione di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "C297",
      "cap": "95012",
      "prefisso": "0942",
      "provincia": "Catania",
      "email": "info@comune.castiglionedisicilia.ct.it",
      "pec": "urp.castiglionedisicilia@legalmail.it",
      "telefono": "+39 0942 980211",
      "fax": "+39 0942 984505",
      "coordinate": {
        "lat": 37.88277816772461,
        "lng": 15.1225004196167
      }
    },
    {
      "codice": "087015",
      "nome": "Catania",
      "nomeStraniero": null,
      "codiceCatastale": "C351",
      "cap": "95121",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "segretario.generale@comune.catania.it",
      "pec": "comune.catania@pec.it",
      "telefono": "+39 095742 3325 - 3326",
      "fax": "+39 095 7424409",
      "coordinate": {
        "lat": 37.50267028808594,
        "lng": 15.08726978302002
      }
    },
    {
      "codice": "087016",
      "nome": "Fiumefreddo di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "D623",
      "cap": "95013",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "comune.fiumefreddodisicilia.ct@legalmail.it",
      "pec": "comune.fiumefreddodisicilia.ct@legalmail.it",
      "telefono": "+39 095 7769011",
      "fax": "+39 095 7769022",
      "coordinate": {
        "lat": 37.79145812988281,
        "lng": 15.209190368652344
      }
    },
    {
      "codice": "087017",
      "nome": "Giarre",
      "nomeStraniero": null,
      "codiceCatastale": "E017",
      "cap": "95014",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "ufficio.urp@comune.giarre.ct.it",
      "pec": "ufficio_urp@giarrepec.e-etna.it",
      "telefono": "+39 095 963111",
      "fax": "+39 095 7794849",
      "coordinate": {
        "lat": 37.72972106933594,
        "lng": 15.184444427490234
      }
    },
    {
      "codice": "087018",
      "nome": "Grammichele",
      "nomeStraniero": null,
      "codiceCatastale": "E133",
      "cap": "95042",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "tornello.caterina@comune.grammichele.ct.it",
      "pec": "protocollogenerale@pec.comune-grammichele.it",
      "telefono": "+39 0933 859507",
      "fax": "+39 0933 859501",
      "coordinate": {
        "lat": 37.21666717529297,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "087019",
      "nome": "Gravina di Catania",
      "nomeStraniero": null,
      "codiceCatastale": "E156",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "urp@comune.gravina-di-catania.ct",
      "pec": "comune.gravina-di-catania@legalmail.it",
      "telefono": "+39 095 7199111",
      "fax": "+39 095 422693",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "087020",
      "nome": "Licodia Eubea",
      "nomeStraniero": null,
      "codiceCatastale": "E578",
      "cap": "95040",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "licodiaeubea@virgilio.it",
      "pec": "comune.licodiaeubea.ct@pec.it",
      "telefono": "+39 0933/801900",
      "fax": "+39 0933/801937",
      "coordinate": {
        "lat": 37.150001525878906,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "087021",
      "nome": "Linguaglossa",
      "nomeStraniero": null,
      "codiceCatastale": "E602",
      "cap": "95015",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "linguademo@virgilio.it",
      "pec": "comune.linguaglossa@pec.it",
      "telefono": "+39 095 7777111",
      "fax": "+39 095 643322",
      "coordinate": {
        "lat": 37.842777252197266,
        "lng": 15.141944885253906
      }
    },
    {
      "codice": "087022",
      "nome": "Maletto",
      "nomeStraniero": null,
      "codiceCatastale": "E854",
      "cap": "95035",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "ss.dd@comune.maletto.ct.it",
      "pec": "protocollo_generale@malettopec.e-etna.it",
      "telefono": "+39 095 7720633",
      "fax": "+39 095 7720641",
      "coordinate": {
        "lat": 37.83333206176758,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "087057",
      "nome": "Maniace",
      "nomeStraniero": null,
      "codiceCatastale": "M283",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "servizio.elettorale@comune.maniace.ct.it",
      "pec": "comunedimaniacect@legalmail.it",
      "telefono": "+39 095 690139",
      "fax": "+39 095 690174",
      "coordinate": {
        "lat": 37.86666488647461,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "087023",
      "nome": "Mascali",
      "nomeStraniero": null,
      "codiceCatastale": "F004",
      "cap": "95016",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "ufficiosegreteria@comune.mascali.ct.it",
      "pec": "protocollomascali@pec.it",
      "telefono": "+39 0957709172",
      "fax": "+39 095 967387",
      "coordinate": {
        "lat": 37.75777816772461,
        "lng": 15.195833206176758
      }
    },
    {
      "codice": "087024",
      "nome": "Mascalucia",
      "nomeStraniero": null,
      "codiceCatastale": "F005",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "ced@comunemascalucia.it",
      "pec": "ced@pec.comunemascalucia.it",
      "telefono": "+39 095 7542217",
      "fax": "+39 095 7542219",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "087056",
      "nome": "Mazzarrone",
      "nomeStraniero": null,
      "codiceCatastale": "M271",
      "cap": "95040",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "anagrafe@comune.mazzarrone.ct.it",
      "pec": "info@pec.comune.mazzarrone.ct.it",
      "telefono": "+39 0933 33115",
      "fax": "+39 0933 33115",
      "coordinate": {
        "lat": 37.08333206176758,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "087025",
      "nome": "Militello in Val di Catania",
      "nomeStraniero": null,
      "codiceCatastale": "F209",
      "cap": "95043",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "saegreteria@comunemilitello.it",
      "pec": "protocollo@pec.comunemilitello.it",
      "telefono": "+39 095 7941285",
      "fax": "+39 095 9707085",
      "coordinate": {
        "lat": 37.274234771728516,
        "lng": 14.793333053588867
      }
    },
    {
      "codice": "087026",
      "nome": "Milo",
      "nomeStraniero": null,
      "codiceCatastale": "F214",
      "cap": "95010",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@comune.milo.ct.it",
      "pec": "protocollo@pec.comune.milo.ct.it",
      "telefono": "+39 095 955423",
      "fax": "+39 095 955160",
      "coordinate": {
        "lat": 37.71666717529297,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "087027",
      "nome": "Mineo",
      "nomeStraniero": null,
      "codiceCatastale": "F217",
      "cap": "95044",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "sindaco@comune.mineo.ct.it",
      "pec": "protocollo@pec.comunemineo.telecompost.it",
      "telefono": "+39 0933/989028",
      "fax": "+39 0933 989029",
      "coordinate": {
        "lat": 37.266357421875,
        "lng": 14.691061019897461
      }
    },
    {
      "codice": "087028",
      "nome": "Mirabella Imbaccari",
      "nomeStraniero": null,
      "codiceCatastale": "F231",
      "cap": "95040",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "sindaco@comune.mirabella-imbaccar.ct.it",
      "pec": "comunedimirabellaimbaccari.ct.protgen@pa.postacertificata.gov.it",
      "telefono": "+39 0933 990011",
      "fax": "+39 0933 990048",
      "coordinate": {
        "lat": 37.33333206176758,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "087029",
      "nome": "Misterbianco",
      "nomeStraniero": null,
      "codiceCatastale": "F250",
      "cap": "95045",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@misterbianco.gov.it",
      "pec": "protocollo.misterbianco@pec.it",
      "telefono": "+39 095 7556200",
      "fax": "+39 095 7556643",
      "coordinate": {
        "lat": 37.518333435058594,
        "lng": 15.00694465637207
      }
    },
    {
      "codice": "087030",
      "nome": "Motta Sant'Anastasia",
      "nomeStraniero": null,
      "codiceCatastale": "F781",
      "cap": "95040",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune-mottasantanastasia.ct.it",
      "pec": "infopost@pec.comune.mottasantanastasia.ct.it",
      "telefono": "+39 095 7554211",
      "fax": "+39 095 306324",
      "coordinate": {
        "lat": 37.5,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "087031",
      "nome": "Nicolosi",
      "nomeStraniero": null,
      "codiceCatastale": "F890",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "servizi.demografici@comune.nicolosi.ct.it",
      "pec": "protocollo@pec.comunenicolosi.it",
      "telefono": "+39 095 7917011",
      "fax": "+39 095 7917024",
      "coordinate": {
        "lat": 37.61666488647461,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "087032",
      "nome": "Palagonia",
      "nomeStraniero": null,
      "codiceCatastale": "G253",
      "cap": "95046",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "elettoralepalagonia@virgilio.it",
      "pec": "elettorale.palagonia@legalmail.it",
      "telefono": "+39 095 7958655",
      "fax": "+39 095 7958611",
      "coordinate": {
        "lat": 37.33333206176758,
        "lng": 14.75
      }
    },
    {
      "codice": "087033",
      "nome": "Paternò",
      "nomeStraniero": null,
      "codiceCatastale": "G371",
      "cap": "95047",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune.paterno.ct.it",
      "pec": "segretario@cert.comune.paterno.ct.it",
      "telefono": "+39 095.7970.111",
      "fax": "+39 095.856.659",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 14.899999618530273
      }
    },
    {
      "codice": "087034",
      "nome": "Pedara",
      "nomeStraniero": null,
      "codiceCatastale": "G402",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "protocollo.generale@comune.pedara.ct.it",
      "pec": "protocollo@pec.comune.pedara.ct.it",
      "telefono": "+39 095 7028111",
      "fax": "+39 095 7028112",
      "coordinate": {
        "lat": 37.61666488647461,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "087035",
      "nome": "Piedimonte Etneo",
      "nomeStraniero": null,
      "codiceCatastale": "G597",
      "cap": "95010",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "piedimonte.comune@tin.it",
      "pec": "protocollo.generale@piedimontepec.e-etna.it",
      "telefono": "+39 095/644500",
      "fax": "+39 095/644344",
      "coordinate": {
        "lat": 37.80666732788086,
        "lng": 15.179166793823242
      }
    },
    {
      "codice": "087036",
      "nome": "Raddusa",
      "nomeStraniero": null,
      "codiceCatastale": "H154",
      "cap": "95040",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "servizidemografici@comuneraddusa.gov.it",
      "pec": "comuneraddusa@postecert.it",
      "telefono": "+39 095662844",
      "fax": "+39 095667235",
      "coordinate": {
        "lat": 37.46666717529297,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "087058",
      "nome": "Ragalna",
      "nomeStraniero": null,
      "codiceCatastale": "M287",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "segreteria.tempera@email.it",
      "pec": "ufficiotecnicoragalna@arubapec.it",
      "telefono": "+39 095 7985111",
      "fax": "+39 095 7985102",
      "coordinate": {
        "lat": 37.63333511352539,
        "lng": 14.933333396911621
      }
    },
    {
      "codice": "087037",
      "nome": "Ramacca",
      "nomeStraniero": null,
      "codiceCatastale": "H168",
      "cap": "95040",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@comune.ramacca.ct.it",
      "pec": "postmaster@pec.comunediramacca.it",
      "telefono": "+39 095 7930111",
      "fax": "+39 095 7932122",
      "coordinate": {
        "lat": 37.38333511352539,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "087038",
      "nome": "Randazzo",
      "nomeStraniero": null,
      "codiceCatastale": "H175",
      "cap": "95036",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "elettorale@comune.randazzo.ct.it",
      "pec": "protocollo_generale@randazzopec.e-etna.it",
      "telefono": "+39 095 921224",
      "fax": "+39 095 921224",
      "coordinate": {
        "lat": 37.88333511352539,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "087039",
      "nome": "Riposto",
      "nomeStraniero": null,
      "codiceCatastale": "H325",
      "cap": "95018",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@comune.riposto.ct.it",
      "pec": "protocollo@pec.comune.riposto.ct.it",
      "telefono": "+39 095 962111",
      "fax": "+39 095 962233",
      "coordinate": {
        "lat": 37.733333587646484,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "087040",
      "nome": "San Cono",
      "nomeStraniero": null,
      "codiceCatastale": "H805",
      "cap": "95040",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "protocollo@comunedisancono.it",
      "pec": "protocollo@pec.comunedisancono.it",
      "telefono": "+39 0933.970100",
      "fax": "+39 0933 970803",
      "coordinate": {
        "lat": 37.289920806884766,
        "lng": 14.367090225219727
      }
    },
    {
      "codice": "087041",
      "nome": "San Giovanni la Punta",
      "nomeStraniero": null,
      "codiceCatastale": "H922",
      "cap": "95037",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "info@sglapunta.it",
      "pec": "sangiovannilapunta@pec.it",
      "telefono": "+39 095 6278",
      "fax": null,
      "coordinate": {
        "lat": 37.58333206176758,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "087042",
      "nome": "San Gregorio di Catania",
      "nomeStraniero": null,
      "codiceCatastale": "H940",
      "cap": "95027",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "elettorale@comune.sangregoriodicatania.ct.it",
      "pec": "comune.sangregorio.ct@anutel.it",
      "telefono": "+39-0957219185",
      "fax": "+39 095 7213271",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "087043",
      "nome": "San Michele di Ganzaria",
      "nomeStraniero": null,
      "codiceCatastale": "I035",
      "cap": "95040",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "sindaco@sanmichelediganzaria.ct.it",
      "pec": "prot.gen@pec.comune.sanmichelediganzaria.ct.it",
      "telefono": "+39 0933 971011",
      "fax": "+39 0933 976922",
      "coordinate": {
        "lat": 37.28333282470703,
        "lng": 14.433333396911621
      }
    },
    {
      "codice": "087044",
      "nome": "San Pietro Clarenza",
      "nomeStraniero": null,
      "codiceCatastale": "I098",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "angesorg@tin.it",
      "pec": "giuseppa.balsamo@comunesanpietroclarenza.telecompost.it",
      "telefono": "+39 095 529039",
      "fax": "+39 095 522291",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "087045",
      "nome": "Sant'Agata li Battiati",
      "nomeStraniero": null,
      "codiceCatastale": "I202",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "ced@comune.sant-agata-li-battiati.ct.it",
      "pec": "protocollo@pec.comune.sant-agata-li-battiati.ct.it",
      "telefono": "+39 095 6095275",
      "fax": "+39 095 6095275",
      "coordinate": {
        "lat": 37.55833435058594,
        "lng": 15.08055591583252
      }
    },
    {
      "codice": "087046",
      "nome": "Sant'Alfio",
      "nomeStraniero": null,
      "codiceCatastale": "I216",
      "cap": "95010",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@comunesantalfio.gov.it",
      "pec": "protocollosantalfio@pec.it",
      "telefono": "+39 095/968017",
      "fax": "+39 095/7824149",
      "coordinate": {
        "lat": 37.75,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "087047",
      "nome": "Santa Maria di Licodia",
      "nomeStraniero": null,
      "codiceCatastale": "I240",
      "cap": "95038",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "segreteriagenerale@comune.santamariadilicodia.ct.it",
      "pec": "protocollo@pec.comune.santamariadilicodia.ct.it",
      "telefono": "+39 095 7980011",
      "fax": "+39 095 628040",
      "coordinate": {
        "lat": 37.61666488647461,
        "lng": 14.899999618530273
      }
    },
    {
      "codice": "087048",
      "nome": "Santa Venerina",
      "nomeStraniero": null,
      "codiceCatastale": "I314",
      "cap": "95010",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "segreteria@comune.santavenerina.ct.it",
      "pec": "protocollo.generale@santavenerinapec.e-etna.it",
      "telefono": "+39 095 7001111",
      "fax": "+39 095 950123",
      "coordinate": {
        "lat": 37.68333435058594,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "087049",
      "nome": "Scordia",
      "nomeStraniero": null,
      "codiceCatastale": "I548",
      "cap": "95048",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "sindaco@comune.scordia.ct.it",
      "pec": "comune.scordia@legalmail.it",
      "telefono": "+39 095 651111",
      "fax": "+39 095 7936000",
      "coordinate": {
        "lat": 37.29999923706055,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "087050",
      "nome": "Trecastagni",
      "nomeStraniero": null,
      "codiceCatastale": "L355",
      "cap": "95039",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "trecastagni.bonanno@alice.it",
      "pec": "comune.trecastagni@pec.halleyconsulting.it",
      "telefono": "+39 095/7020011",
      "fax": "+39 095/702004",
      "coordinate": {
        "lat": 37.61666488647461,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "087051",
      "nome": "Tremestieri Etneo",
      "nomeStraniero": null,
      "codiceCatastale": "L369",
      "cap": "95030",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "urp@comune.tremestieri.ct.it",
      "pec": "comune.tremestierietneo@legalmail.it",
      "telefono": "+39 095 7419222",
      "fax": "+39 095 7251607",
      "coordinate": {
        "lat": 37.57569885253906,
        "lng": 15.073200225830078
      }
    },
    {
      "codice": "087052",
      "nome": "Valverde",
      "nomeStraniero": null,
      "codiceCatastale": "L658",
      "cap": "95028",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "michele.cosentino@comune.valverde.ct.it",
      "pec": "comune.valverde.ct@pec.it",
      "telefono": "+39 0957218111",
      "fax": "+39 095525161",
      "coordinate": {
        "lat": 37.578975677490234,
        "lng": 15.124799728393555
      }
    },
    {
      "codice": "087053",
      "nome": "Viagrande",
      "nomeStraniero": null,
      "codiceCatastale": "L828",
      "cap": "95029",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "comune@comune.viagrande.ct.it",
      "pec": "protocollo@pec.comune.viagrande.ct.it",
      "telefono": "+39 095 7890382",
      "fax": "+39 095 7894029",
      "coordinate": {
        "lat": 37.61666488647461,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "087054",
      "nome": "Vizzini",
      "nomeStraniero": null,
      "codiceCatastale": "M100",
      "cap": "95049",
      "prefisso": "0933",
      "provincia": "Catania",
      "email": "sindaco@comune.vizzini.ct.it",
      "pec": "segreteria@pec.comune.vizzini.ct.it",
      "telefono": "+39 0933.1937251",
      "fax": "+39 0933 965896",
      "coordinate": {
        "lat": 37.16666793823242,
        "lng": 14.75
      }
    },
    {
      "codice": "087055",
      "nome": "Zafferana Etnea",
      "nomeStraniero": null,
      "codiceCatastale": "M139",
      "cap": "95019",
      "prefisso": "095",
      "provincia": "Catania",
      "email": "demografici@comunezafferanaetnea.it",
      "pec": "protocollo@pec.comune.zafferana-etnea.ct.it",
      "telefono": "+39 095 7083970",
      "fax": "+39 095 7084324",
      "coordinate": {
        "lat": 37.68333435058594,
        "lng": 15.100000381469727
      }
    }
  ],
  "Frosinone": [
    {
      "codice": "060001",
      "nome": "Acquafondata",
      "nomeStraniero": null,
      "codiceCatastale": "A032",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "acquafondata.anagrafe@virgilio.it",
      "pec": "comuneacquafondata@cert-posta.it",
      "telefono": "+39 0776/584432",
      "fax": "+39 0776/584097",
      "coordinate": {
        "lat": 41.54277801513672,
        "lng": 13.952221870422363
      }
    },
    {
      "codice": "060002",
      "nome": "Acuto",
      "nomeStraniero": null,
      "codiceCatastale": "A054",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "segreteria@comune.acuto.fr.it",
      "pec": "info@pec.comune.acuto.fr.it",
      "telefono": "+39 0775 56001",
      "fax": "+39 0775 56595",
      "coordinate": {
        "lat": 41.791927337646484,
        "lng": 13.173316955566406
      }
    },
    {
      "codice": "060003",
      "nome": "Alatri",
      "nomeStraniero": null,
      "codiceCatastale": "A123",
      "cap": "03011",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "giuseppa.santoro@comune.alatri.fr.it",
      "pec": "protocollo.comunealatri@pec.it",
      "telefono": "+39 0775/448360",
      "fax": null,
      "coordinate": {
        "lat": 41.72638702392578,
        "lng": 13.342222213745117
      }
    },
    {
      "codice": "060004",
      "nome": "Alvito",
      "nomeStraniero": null,
      "codiceCatastale": "A244",
      "cap": "03041",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.alvito@libero.it",
      "pec": "comunealvito@arcmediapec.it",
      "telefono": "+39 0776/510101",
      "fax": "+39 0776/510690",
      "coordinate": {
        "lat": 41.68888854980469,
        "lng": 13.744444847106934
      }
    },
    {
      "codice": "060005",
      "nome": "Amaseno",
      "nomeStraniero": null,
      "codiceCatastale": "A256",
      "cap": "03021",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "tributi@amaseno.net",
      "pec": "comune.amaseno@pec-cert.it",
      "telefono": "+39 0775 65021",
      "fax": "+39 0775/658188",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "060006",
      "nome": "Anagni",
      "nomeStraniero": null,
      "codiceCatastale": "A269",
      "cap": "03012",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "elettorale@comune.anagni.fr.it",
      "pec": "comune.anagni@postecert.it",
      "telefono": "+39 07757301",
      "fax": "+39 0775/730433",
      "coordinate": {
        "lat": 41.75,
        "lng": 13.149999618530273
      }
    },
    {
      "codice": "060007",
      "nome": "Aquino",
      "nomeStraniero": null,
      "codiceCatastale": "A348",
      "cap": "03031",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "gennaro1968@gmail.com",
      "pec": "protocollo.aquino@pec.it",
      "telefono": "+39 0776/728003",
      "fax": "+39 0776/728603",
      "coordinate": {
        "lat": 41.5,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "060008",
      "nome": "Arce",
      "nomeStraniero": null,
      "codiceCatastale": "A363",
      "cap": "03032",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "annamaria.difolco@comune.arce.fr.it",
      "pec": "protocollo.comune.arce@pec.it",
      "telefono": "+39 0776/524103",
      "fax": "+39 0776/524659",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 13.583333015441895
      }
    },
    {
      "codice": "060009",
      "nome": "Arnara",
      "nomeStraniero": null,
      "codiceCatastale": "A421",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "comunearnara@virgilio.it",
      "pec": "comunediarnara@postecert.it",
      "telefono": "+39 0775/231079",
      "fax": "+39 0775/231233",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 13.383333206176758
      }
    },
    {
      "codice": "060010",
      "nome": "Arpino",
      "nomeStraniero": null,
      "codiceCatastale": "A433",
      "cap": "03033",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafe@comune.arpino.fr.it",
      "pec": "pec@comunearpinopec.it",
      "telefono": "+39 0776/85211",
      "fax": null,
      "coordinate": {
        "lat": 41.64704895019531,
        "lng": 13.611550331115723
      }
    },
    {
      "codice": "060011",
      "nome": "Atina",
      "nomeStraniero": null,
      "codiceCatastale": "A486",
      "cap": "03042",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafeatina@libero.it",
      "pec": "comuneatina@viapec.net",
      "telefono": "+39 0776/6009203",
      "fax": "+39 0776/609191",
      "coordinate": {
        "lat": 41.62049865722656,
        "lng": 13.798439025878906
      }
    },
    {
      "codice": "060012",
      "nome": "Ausonia",
      "nomeStraniero": null,
      "codiceCatastale": "A502",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "demografici.ausonia@libero.it",
      "pec": "demografici.ausonia@postecert.it",
      "telefono": "+39 0776/952021",
      "fax": "+39 0776/953014",
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 13.75
      }
    },
    {
      "codice": "060013",
      "nome": "Belmonte Castello",
      "nomeStraniero": null,
      "codiceCatastale": "A763",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "belmontecastello@officine.it",
      "pec": "comunedibelmontecastello@postecert.it",
      "telefono": "+39 0776/692202",
      "fax": "+39 0776/692394",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "060014",
      "nome": "Boville Ernica",
      "nomeStraniero": null,
      "codiceCatastale": "A720",
      "cap": "03022",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "boville@libero.it",
      "pec": "boville.ernica.fr@pec.it",
      "telefono": "+39 0775/379004",
      "fax": "+39 0775/379660",
      "coordinate": {
        "lat": 41.64250183105469,
        "lng": 13.47398853302002
      }
    },
    {
      "codice": "060015",
      "nome": "Broccostella",
      "nomeStraniero": null,
      "codiceCatastale": "B195",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@comune.broccostella.fr.it",
      "pec": "comunebroccostella@interfreepec.it",
      "telefono": "+39 0776/892871",
      "fax": "+39 0776/891629",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 13.633333206176758
      }
    },
    {
      "codice": "060016",
      "nome": "Campoli Appennino",
      "nomeStraniero": null,
      "codiceCatastale": "B543",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.campoli@libero.it",
      "pec": "comune.campoliapp@pec.it",
      "telefono": "+39 0776/1790070",
      "fax": null,
      "coordinate": {
        "lat": 41.733333587646484,
        "lng": 13.683333396911621
      }
    },
    {
      "codice": "060017",
      "nome": "Casalattico",
      "nomeStraniero": null,
      "codiceCatastale": "B862",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafecasalattico@libero.it",
      "pec": "protocollocasalattico@arubapec.it",
      "telefono": "+39 0776/690012",
      "fax": "+39 0776/690184",
      "coordinate": {
        "lat": 41.621665954589844,
        "lng": 13.725000381469727
      }
    },
    {
      "codice": "060018",
      "nome": "Casalvieri",
      "nomeStraniero": null,
      "codiceCatastale": "B919",
      "cap": "03034",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "elettorale@comune.casalvieri.fr.it",
      "pec": "elettorale@pec.comune.casalvieri.fr.it",
      "telefono": "+39 0776/639327",
      "fax": "+39 0776/639015",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 13.716666221618652
      }
    },
    {
      "codice": "060019",
      "nome": "Cassino",
      "nomeStraniero": null,
      "codiceCatastale": "C034",
      "cap": "03043",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "servizio.elettorale@comunecassino.it",
      "pec": "servizio.protocollo@comunecassino.telecompost.it",
      "telefono": "+39 0776/298488",
      "fax": null,
      "coordinate": {
        "lat": 41.49166488647461,
        "lng": 13.833333015441895
      }
    },
    {
      "codice": "060020",
      "nome": "Castelliri",
      "nomeStraniero": null,
      "codiceCatastale": "C177",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comunedi",
      "pec": "comunedicastelliri@postecert.it",
      "telefono": "+39 0776/807456",
      "fax": "+39 0776/807480",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 13.550000190734863
      }
    },
    {
      "codice": "060021",
      "nome": "Castelnuovo Parano",
      "nomeStraniero": null,
      "codiceCatastale": "C223",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "castelnuovo.parano@iscali.it",
      "pec": "suap@pec.comune.castelnuovoparano.fr.it",
      "telefono": "+39 0776/952092",
      "fax": "+39 0776/952686",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 13.75
      }
    },
    {
      "codice": "060023",
      "nome": "Castro dei Volsci",
      "nomeStraniero": null,
      "codiceCatastale": "C338",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "info@comunecastrodeivolsci.it",
      "pec": "pec@pec.comune.castrodeivolsci.fr.it",
      "telefono": "+39 0775/662008",
      "fax": "+39 0775/662175",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "060022",
      "nome": "Castrocielo",
      "nomeStraniero": null,
      "codiceCatastale": "C340",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "elettorale.castrocielo@tin.it",
      "pec": "comune.castrocielo@legalmail.it",
      "telefono": "+39 077679001",
      "fax": "+39 077679822",
      "coordinate": {
        "lat": 41.53333282470703,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "060024",
      "nome": "Ceccano",
      "nomeStraniero": null,
      "codiceCatastale": "C413",
      "cap": "03023",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "sindaco@comune.ceccano.fr.it",
      "pec": "protocollo.generale@comunececcano.telecompost.it",
      "telefono": "+39 0775 6221",
      "fax": "+39 0775 622326",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "060025",
      "nome": "Ceprano",
      "nomeStraniero": null,
      "codiceCatastale": "C479",
      "cap": "03024",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "tributiceprano@virgilio.it",
      "pec": "comune.ceprano@pec.leonet.it",
      "telefono": "+39 0775/9174229",
      "fax": "+39 0775/912754",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.516666412353516
      }
    },
    {
      "codice": "060026",
      "nome": "Cervaro",
      "nomeStraniero": null,
      "codiceCatastale": "C545",
      "cap": "03044",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "demografici@comune.cervaro.fr.it",
      "pec": "comune.cervaro.fr@cet-posta.it",
      "telefono": "+39 0776/36491",
      "fax": null,
      "coordinate": {
        "lat": 41.48278045654297,
        "lng": 13.902179718017578
      }
    },
    {
      "codice": "060027",
      "nome": "Colfelice",
      "nomeStraniero": null,
      "codiceCatastale": "C836",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "uffanagrafe@comune.colfelice.fr.it",
      "pec": "comunecolfelice@arcmediapec.it",
      "telefono": "+39 0776/527613",
      "fax": "+39 0776/527169",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.600000381469727
      }
    },
    {
      "codice": "060029",
      "nome": "Colle San Magno",
      "nomeStraniero": null,
      "codiceCatastale": "C870",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.collesanmagno@libero.it",
      "pec": "comunecollesanmagno@postecert.it",
      "telefono": "+39 0776/560051",
      "fax": "+39 0776/560290",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "060028",
      "nome": "Collepardo",
      "nomeStraniero": null,
      "codiceCatastale": "C864",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "ragioneria@collepardo.it",
      "pec": "comunedicollepardo@pec.it",
      "telefono": "+39 0775/47021",
      "fax": "+39 0775/47325",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 13.366666793823242
      }
    },
    {
      "codice": "060030",
      "nome": "Coreno Ausonio",
      "nomeStraniero": null,
      "codiceCatastale": "C998",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.corenoausoniofr@gmail.com",
      "pec": "comune.corenoausonio.fr@messaggipec.it",
      "telefono": "+39 0776/996024",
      "fax": "+39 0776/996358",
      "coordinate": {
        "lat": 41.343055725097656,
        "lng": 13.776666641235352
      }
    },
    {
      "codice": "060031",
      "nome": "Esperia",
      "nomeStraniero": null,
      "codiceCatastale": "D440",
      "cap": "03045",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.esperia@tin.it",
      "pec": "comune.esperia@anutel.it",
      "telefono": "+39 0776 937612",
      "fax": "+39 0776/937544",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 13.683333396911621
      }
    },
    {
      "codice": "060032",
      "nome": "Falvaterra",
      "nomeStraniero": null,
      "codiceCatastale": "D483",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "comunefalvaterra@libero.it",
      "pec": "demograficifalvaterra@postecert.it",
      "telefono": "+39 0775/90015",
      "fax": "+39 0775/90043",
      "coordinate": {
        "lat": 41.504722595214844,
        "lng": 13.52388858795166
      }
    },
    {
      "codice": "060033",
      "nome": "Ferentino",
      "nomeStraniero": null,
      "codiceCatastale": "D539",
      "cap": "03013",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "elettorale@comune.ferentino.fr.it",
      "pec": "protocollo.ferentino@pec-cap.it",
      "telefono": "+39 0775 2481",
      "fax": "+39 0775/248232",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 13.25
      }
    },
    {
      "codice": "060034",
      "nome": "Filettino",
      "nomeStraniero": null,
      "codiceCatastale": "D591",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "protocollo@comunefilettino.it",
      "pec": "protocollofilettino@pec.it",
      "telefono": "+39 0775581832",
      "fax": null,
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "060035",
      "nome": "Fiuggi",
      "nomeStraniero": null,
      "codiceCatastale": "A310",
      "cap": "03014",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "elettorale@comune.fiuggi.fr.it",
      "pec": "stato.civile.elettorale@comunedifiuggi.postecert.it",
      "telefono": "+39 0775/5461324",
      "fax": "+39 0775/5461324",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "060036",
      "nome": "Fontana Liri",
      "nomeStraniero": null,
      "codiceCatastale": "D667",
      "cap": "03035",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "sindacofontanaliri@gmail.com",
      "pec": "anagrafe@pec.comunefontanaliri.fr.it",
      "telefono": "+39 0776/525707",
      "fax": "+39 0776/539149",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 13.550000190734863
      }
    },
    {
      "codice": "060037",
      "nome": "Fontechiari",
      "nomeStraniero": null,
      "codiceCatastale": "D682",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "amministrazione@comune.fontechiari.fr.it",
      "pec": "fontechiari@interfreepec.it",
      "telefono": "+39 0776/889015",
      "fax": "+39 0776/889012",
      "coordinate": {
        "lat": 41.66749954223633,
        "lng": 13.675000190734863
      }
    },
    {
      "codice": "060038",
      "nome": "Frosinone",
      "nomeStraniero": null,
      "codiceCatastale": "D810",
      "cap": "03100",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "info@comune.frosinone.it",
      "pec": "pec@pec.comune.frosinone.it",
      "telefono": "+39 0775 2651",
      "fax": "+39 0775/2658888",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 13.350000381469727
      }
    },
    {
      "codice": "060039",
      "nome": "Fumone",
      "nomeStraniero": null,
      "codiceCatastale": "D819",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "comunedifumone@tin.it",
      "pec": "comunedifumone@pec.it",
      "telefono": "+39 0775/49496",
      "fax": "+39 0775/49022",
      "coordinate": {
        "lat": 41.724998474121094,
        "lng": 13.29111099243164
      }
    },
    {
      "codice": "060040",
      "nome": "Gallinaro",
      "nomeStraniero": null,
      "codiceCatastale": "D881",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.gallinaro@libero.it",
      "pec": "comune.gallinaro@pec.it",
      "telefono": "+39 0776/696012",
      "fax": "+39 0776/696222",
      "coordinate": {
        "lat": 41.65502166748047,
        "lng": 13.797989845275879
      }
    },
    {
      "codice": "060041",
      "nome": "Giuliano di Roma",
      "nomeStraniero": null,
      "codiceCatastale": "E057",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "t.buraglia@libero.it",
      "pec": "comunegiulianodiroma.fr.protocollo@pa.postacertificata.gov.it",
      "telefono": "+39 0775/699016",
      "fax": "+39 0775/699689",
      "coordinate": {
        "lat": 41.53333282470703,
        "lng": 13.283333778381348
      }
    },
    {
      "codice": "060042",
      "nome": "Guarcino",
      "nomeStraniero": null,
      "codiceCatastale": "E236",
      "cap": "03016",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "comuneguarcino@libero.it",
      "pec": "comuneguarcino@pec.it",
      "telefono": "+39 0775 46007",
      "fax": "+39 0775 469163",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 13.316666603088379
      }
    },
    {
      "codice": "060043",
      "nome": "Isola del Liri",
      "nomeStraniero": null,
      "codiceCatastale": "E340",
      "cap": "03036",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "urp@comune.isoladelliri.fr.it",
      "pec": "protocolloisoladelliri@pec.it",
      "telefono": "+39 0776/8008342",
      "fax": "+39 0776/8008355",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 13.566666603088379
      }
    },
    {
      "codice": "060044",
      "nome": "Monte San Giovanni Campano",
      "nomeStraniero": null,
      "codiceCatastale": "F620",
      "cap": "03025",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "demografico@comune.montesangiovannicampano.fr.it",
      "pec": "comunemontesgc.demografico@pec.it",
      "telefono": "+39 0775 289901",
      "fax": "+39 0775 289175",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 13.516666412353516
      }
    },
    {
      "codice": "060045",
      "nome": "Morolo",
      "nomeStraniero": null,
      "codiceCatastale": "F740",
      "cap": "03017",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "segreteria@comune.morolo.fr.it",
      "pec": "comune.morolo@pec.it",
      "telefono": "+39 0775806002",
      "fax": "+39 0775228043",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 13.199999809265137
      }
    },
    {
      "codice": "060046",
      "nome": "Paliano",
      "nomeStraniero": null,
      "codiceCatastale": "G276",
      "cap": "03018",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "segretario@comune.paliano.fr.it",
      "pec": "protocollo.paliano@actalispec.it",
      "telefono": "+39 0775/570820",
      "fax": "+39 0775/579961",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 13.050000190734863
      }
    },
    {
      "codice": "060047",
      "nome": "Pastena",
      "nomeStraniero": null,
      "codiceCatastale": "G362",
      "cap": "03020",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comunedipastena@libero.it",
      "pec": "comunedipastena@postecert.it",
      "telefono": "+39 0776546531",
      "fax": "+39 0776546261",
      "coordinate": {
        "lat": 41.468055725097656,
        "lng": 13.491110801696777
      }
    },
    {
      "codice": "060048",
      "nome": "Patrica",
      "nomeStraniero": null,
      "codiceCatastale": "G374",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "demo.patrica@comune.patrica.fr.it",
      "pec": "affarigenerali@pec.comune.patrica.fr.it",
      "telefono": "+39 0775/807820",
      "fax": "+39 0775/222022",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 13.25
      }
    },
    {
      "codice": "060049",
      "nome": "Pescosolido",
      "nomeStraniero": null,
      "codiceCatastale": "G500",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "silvia.m@comune.pescosolido.fr.it",
      "pec": "comunepescosolido@arcmediapec.it",
      "telefono": "+39 0776/886020",
      "fax": "+39 0776/1801023",
      "coordinate": {
        "lat": 41.749168395996094,
        "lng": 13.657500267028809
      }
    },
    {
      "codice": "060050",
      "nome": "Picinisco",
      "nomeStraniero": null,
      "codiceCatastale": "G591",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "amministrativo@comune.picinisco.fr.it",
      "pec": "info@comunepiciniscopec.it",
      "telefono": "+39 0776/66014",
      "fax": "+39 0776/66204",
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 13.866666793823242
      }
    },
    {
      "codice": "060051",
      "nome": "Pico",
      "nomeStraniero": null,
      "codiceCatastale": "G592",
      "cap": "03020",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comunedipico@libero.it",
      "pec": "protocollo@pec.comunedipico.it",
      "telefono": "+39 0776/544012",
      "fax": "+39 0776/543034",
      "coordinate": {
        "lat": 41.45138931274414,
        "lng": 13.561666488647461
      }
    },
    {
      "codice": "060052",
      "nome": "Piedimonte San Germano",
      "nomeStraniero": null,
      "codiceCatastale": "G598",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@comune.piedimontesangermano.fr.it",
      "pec": "protocollopiedimontesg@pec.it",
      "telefono": "+39 0776/4029223",
      "fax": "+39 0776/404081",
      "coordinate": {
        "lat": 41.5,
        "lng": 13.733333587646484
      }
    },
    {
      "codice": "060053",
      "nome": "Piglio",
      "nomeStraniero": null,
      "codiceCatastale": "G659",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "comunepiglio@libero.it",
      "pec": "info@pec.comune.piglio.fr.it",
      "telefono": "+39 0775/502328",
      "fax": "+39 0775/501954",
      "coordinate": {
        "lat": 41.82805633544922,
        "lng": 13.141666412353516
      }
    },
    {
      "codice": "060054",
      "nome": "Pignataro Interamna",
      "nomeStraniero": null,
      "codiceCatastale": "G662",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafe.pignataro@email.it",
      "pec": "int.servizigenerali@certipec.it",
      "telefono": "+39 0776/939013",
      "fax": "+39 0776/939013",
      "coordinate": {
        "lat": 41.43333435058594,
        "lng": 13.783333778381348
      }
    },
    {
      "codice": "060055",
      "nome": "Pofi",
      "nomeStraniero": null,
      "codiceCatastale": "G749",
      "cap": "03026",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "anagrafe@comune.pofi.fr.it",
      "pec": "anagrafecomunepofi@pec.it",
      "telefono": "+39 0775380013",
      "fax": null,
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 13.416666984558105
      }
    },
    {
      "codice": "060056",
      "nome": "Pontecorvo",
      "nomeStraniero": null,
      "codiceCatastale": "G838",
      "cap": "03037",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "sindaco@comunepontecorvo.it",
      "pec": "protocollo@pec.comunepontecorvo.it",
      "telefono": "+39 0776 76211",
      "fax": "+39 0776 760201",
      "coordinate": {
        "lat": 41.46260070800781,
        "lng": 13.66759967803955
      }
    },
    {
      "codice": "060057",
      "nome": "Posta Fibreno",
      "nomeStraniero": null,
      "codiceCatastale": "G935",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@comune.postafibreno.fr.it",
      "pec": "comune@postafibrenopec.it",
      "telefono": "+39 0776 887282",
      "fax": "+39 0776 887309",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "060058",
      "nome": "Ripi",
      "nomeStraniero": null,
      "codiceCatastale": "H324",
      "cap": "03027",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "anagrafe@comune.ripi.fr.it",
      "pec": "servizidemografici.ripi@pec.it",
      "telefono": "+39 0775/284010",
      "fax": "+39 0775/254156",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 13.433333396911621
      }
    },
    {
      "codice": "060059",
      "nome": "Rocca d'Arce",
      "nomeStraniero": null,
      "codiceCatastale": "H393",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "roccadarce59@libero.it",
      "pec": "suap.roccadarce@pec.it",
      "telefono": "+39 0776 536263",
      "fax": "+39 0776 536420",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 13.583333015441895
      }
    },
    {
      "codice": "060060",
      "nome": "Roccasecca",
      "nomeStraniero": null,
      "codiceCatastale": "H443",
      "cap": "03038",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafe@comune.roccasecca.fr.it",
      "pec": "comune.roccasecca@legalmail.it",
      "telefono": "+39 0776/569810",
      "fax": "+39 0776/567554",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.666666984558105
      }
    },
    {
      "codice": "060061",
      "nome": "San Biagio Saracinisco",
      "nomeStraniero": null,
      "codiceCatastale": "H779",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comunesbiagios@libero.it",
      "pec": "comune.sanbiagiosaracinisco@legalmail.it",
      "telefono": "+39 0776/67018",
      "fax": "+39 0776/67140",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "060062",
      "nome": "San Donato Val di Comino",
      "nomeStraniero": null,
      "codiceCatastale": "H824",
      "cap": "03046",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@omune.sandonatovaldicomino.fr.it",
      "pec": "statocivile.sandonatovaldicomino@legalmail.it",
      "telefono": "+39 0776/508701",
      "fax": "+39 0776/508918",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "060063",
      "nome": "San Giorgio a Liri",
      "nomeStraniero": null,
      "codiceCatastale": "H880",
      "cap": "03047",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafe@comunedisangiorgioaliri.it",
      "pec": "posta@pec.comunedisangiorgioaliri.it",
      "telefono": "+39 0776/914811",
      "fax": "+39 0776/911017",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "060064",
      "nome": "San Giovanni Incarico",
      "nomeStraniero": null,
      "codiceCatastale": "H917",
      "cap": "03028",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "ufficioanagrafe@comunesangiovanniincarico.fr",
      "pec": "segreteriacomunesgi@pec.it",
      "telefono": "+39 0776/549801",
      "fax": "+39 0776/547060",
      "coordinate": {
        "lat": 41.5,
        "lng": 13.550000190734863
      }
    },
    {
      "codice": "060070",
      "nome": "San Vittore del Lazio",
      "nomeStraniero": null,
      "codiceCatastale": "I408",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@comune.sanvittoredellazio.fr.it",
      "pec": "info@pec.comune.sanvittoredellazio.fr.it",
      "telefono": "+39 0776/335037",
      "fax": "+39 0776/335453",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "060065",
      "nome": "Sant'Ambrogio sul Garigliano",
      "nomeStraniero": null,
      "codiceCatastale": "I256",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@comune.santambrogiosulgarigliano.fr.it",
      "pec": "servizidemografici@pec.comune.santambrogiosulgarigliano.fr.it",
      "telefono": "+39 0776/98073",
      "fax": "+39 0776/98293",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 13.866666793823242
      }
    },
    {
      "codice": "060066",
      "nome": "Sant'Andrea del Garigliano",
      "nomeStraniero": null,
      "codiceCatastale": "I265",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "anagrafe@comune.santandreadelgarigliano.fr.it",
      "pec": "anagrafesantandrea@pec.wmail.it",
      "telefono": "+39 0776/956021",
      "fax": "+39 0776/956501",
      "coordinate": {
        "lat": 41.36805725097656,
        "lng": 13.841666221618652
      }
    },
    {
      "codice": "060067",
      "nome": "Sant'Apollinare",
      "nomeStraniero": null,
      "codiceCatastale": "I302",
      "cap": "03048",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "servizidemografici@comune.santapollinare.fr.it",
      "pec": "protocollo.santapollinare@pec.it",
      "telefono": "+39 0776/915036",
      "fax": "+39 0776/915604",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 13.833333015441895
      }
    },
    {
      "codice": "060068",
      "nome": "Sant'Elia Fiumerapido",
      "nomeStraniero": null,
      "codiceCatastale": "I321",
      "cap": "03049",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "info@comune.santeliafiumerapido.fr.it",
      "pec": "protocollo@pec.comune.santeliafiumerapido.fr.it",
      "telefono": "+39 0776/351862",
      "fax": "+39 0776/350020",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.866666793823242
      }
    },
    {
      "codice": "060069",
      "nome": "Santopadre",
      "nomeStraniero": null,
      "codiceCatastale": "I351",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.santopadre@libero.it",
      "pec": "comunedisantopadre.fr@pec.it",
      "telefono": "+39 0776/531814",
      "fax": "+39 0776/531557",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 13.633333206176758
      }
    },
    {
      "codice": "060071",
      "nome": "Serrone",
      "nomeStraniero": null,
      "codiceCatastale": "I669",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "servizidemografici.serrone@gmail.com",
      "pec": "info@pec.comune.serrone.fr.it",
      "telefono": "+39 0775/523064",
      "fax": "+39 0775/523277",
      "coordinate": {
        "lat": 41.84212112426758,
        "lng": 13.095190048217773
      }
    },
    {
      "codice": "060072",
      "nome": "Settefrati",
      "nomeStraniero": null,
      "codiceCatastale": "I697",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune.settefrati@libero.it",
      "pec": "comune.settefrati@legalmail.it",
      "telefono": "+39 0776/695001",
      "fax": "+39 0776/690492",
      "coordinate": {
        "lat": 41.66962432861328,
        "lng": 13.850010871887207
      }
    },
    {
      "codice": "060073",
      "nome": "Sgurgola",
      "nomeStraniero": null,
      "codiceCatastale": "I716",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "tecno.informatica@libero.it",
      "pec": "protocollo@pec.comune.sgurgola.fr.it",
      "telefono": "+39 0775/767181",
      "fax": "+39 0775/769472",
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 13.149999618530273
      }
    },
    {
      "codice": "060074",
      "nome": "Sora",
      "nomeStraniero": null,
      "codiceCatastale": "I838",
      "cap": "03039",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "ufficiopaghe@comune.sora.fr.it",
      "pec": "comunesora@pec.it",
      "telefono": "+39 0776/828237",
      "fax": "+39 0776/825056",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 13.616666793823242
      }
    },
    {
      "codice": "060075",
      "nome": "Strangolagalli",
      "nomeStraniero": null,
      "codiceCatastale": "I973",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "ced@comunedistrangolagalli.it",
      "pec": "comune.strangolagalli.fr@pec.it",
      "telefono": "0775978738",
      "fax": "+39 0775/978447",
      "coordinate": {
        "lat": 41.60074996948242,
        "lng": 13.491800308227539
      }
    },
    {
      "codice": "060076",
      "nome": "Supino",
      "nomeStraniero": null,
      "codiceCatastale": "L009",
      "cap": "03019",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "info@comunesupino.it",
      "pec": "demograficicomunesupino@legalmail.it",
      "telefono": "+39 0775 22601",
      "fax": "+39 0775226713",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "060077",
      "nome": "Terelle",
      "nomeStraniero": null,
      "codiceCatastale": "L105",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comuneterelle@libero.it",
      "pec": "comune@pec.comuneterelle.it",
      "telefono": "+39 0776/336013",
      "fax": "+39 0776/336180",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.783333778381348
      }
    },
    {
      "codice": "060078",
      "nome": "Torre Cajetani",
      "nomeStraniero": null,
      "codiceCatastale": "L243",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "info@comune.torrecajetani.fr.it",
      "pec": "sindaco.torrecajetani@pec.it",
      "telefono": "+39 0775/596017",
      "fax": "+39 0775/596146",
      "coordinate": {
        "lat": 41.786720275878906,
        "lng": 13.265199661254883
      }
    },
    {
      "codice": "060079",
      "nome": "Torrice",
      "nomeStraniero": null,
      "codiceCatastale": "L290",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "servizidemografici@comune.torrice.fr.it",
      "pec": "demografici.torrice@pec.it",
      "telefono": "+39 0775/300014",
      "fax": "+39 0775/301604",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "060080",
      "nome": "Trevi nel Lazio",
      "nomeStraniero": null,
      "codiceCatastale": "L398",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "anagrafe@comune.trevinellazio.fr.it",
      "pec": "demograficotrevi@pec.it",
      "telefono": "+39 0775/527001",
      "fax": "+39 0775/527097",
      "coordinate": {
        "lat": 41.863887786865234,
        "lng": 13.251111030578613
      }
    },
    {
      "codice": "060081",
      "nome": "Trivigliano",
      "nomeStraniero": null,
      "codiceCatastale": "L437",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "anag.trivigliano@libero.it",
      "pec": "comune.trivigliano@legalmail.it",
      "telefono": "+39 0775/520213",
      "fax": "+39 0775520582",
      "coordinate": {
        "lat": 41.78333282470703,
        "lng": 13.266666412353516
      }
    },
    {
      "codice": "060082",
      "nome": "Vallecorsa",
      "nomeStraniero": null,
      "codiceCatastale": "L598",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "demografico@comune.vallecorsa.fr.it",
      "pec": "demografico@pec.comune.vallecorsa.fr.it",
      "telefono": "+39 0775/679017",
      "fax": "+39 0775/679867",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "060083",
      "nome": "Vallemaio",
      "nomeStraniero": null,
      "codiceCatastale": "L605",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "protocollo@comune.vallemaio.fr.it",
      "pec": "suap@pec.comune.vallemaio.fr.it",
      "telefono": "+39 0776957123",
      "fax": "+39 0776/957314",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "060084",
      "nome": "Vallerotonda",
      "nomeStraniero": null,
      "codiceCatastale": "L614",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comunevallerotonda@libero.it",
      "pec": "comune.vallerotonda.fr@pec.it",
      "telefono": "+39 0776/587001",
      "fax": "+39 0776/587445",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "060085",
      "nome": "Veroli",
      "nomeStraniero": null,
      "codiceCatastale": "L780",
      "cap": "03029",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "elettorale@comune.veroli.fr.it",
      "pec": "servizidemograficiveroli@pec.it",
      "telefono": "+39 0775/885268",
      "fax": "+39 0775/230914",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 13.416666984558105
      }
    },
    {
      "codice": "060086",
      "nome": "Vicalvi",
      "nomeStraniero": null,
      "codiceCatastale": "L836",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comune_vicalvi@libero.it",
      "pec": "uff.amministrativo@pec.comune.vicalvi.fr.it",
      "telefono": "+39 0776/506476",
      "fax": "+39 0776/506512",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 13.716666221618652
      }
    },
    {
      "codice": "060087",
      "nome": "Vico nel Lazio",
      "nomeStraniero": null,
      "codiceCatastale": "L843",
      "cap": "03010",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "anagrafevico@libero.it",
      "pec": "comuneviconellazio@arcmediapec.it",
      "telefono": "+39 0775/41151",
      "fax": "+39 0775/418932",
      "coordinate": {
        "lat": 41.78333282470703,
        "lng": 13.350000381469727
      }
    },
    {
      "codice": "060088",
      "nome": "Villa Latina",
      "nomeStraniero": null,
      "codiceCatastale": "A081",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comunevillalatina@virgilio.it",
      "pec": "comunevillalatinaanagrafe@pec.it",
      "telefono": "+39 0776/688015",
      "fax": "+39 0776/688566",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 13.833333015441895
      }
    },
    {
      "codice": "060089",
      "nome": "Villa Santa Lucia",
      "nomeStraniero": null,
      "codiceCatastale": "L905",
      "cap": "03030",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "servizisociali@comune.villasantalucia.fr.it",
      "pec": "protocollo@pec.comune.villasantalucia.fr.it",
      "telefono": "+39 0776/463366",
      "fax": "+39 0776/463324",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "060090",
      "nome": "Villa Santo Stefano",
      "nomeStraniero": null,
      "codiceCatastale": "I364",
      "cap": "03020",
      "prefisso": "0775",
      "provincia": "Frosinone",
      "email": "sindaco@comune.villasantostefano.fr.it",
      "pec": "info@pec.comune.villasantostefano.fr.it",
      "telefono": "+39 0775/632125",
      "fax": "+39 0775/632594",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 13.316666603088379
      }
    },
    {
      "codice": "060091",
      "nome": "Viticuso",
      "nomeStraniero": null,
      "codiceCatastale": "M083",
      "cap": "03040",
      "prefisso": "0776",
      "provincia": "Frosinone",
      "email": "comuneviticuso@libero.it",
      "pec": "comuneviticuso@cert-posta.it",
      "telefono": "+39 0776/577767",
      "fax": "+39 0776/577137",
      "coordinate": {
        "lat": 41.52527618408203,
        "lng": 13.96916675567627
      }
    }
  ],
  "Cosenza": [
    {
      "codice": "078001",
      "nome": "Acquaformosa",
      "nomeStraniero": null,
      "codiceCatastale": "A033",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "protocollo@comune.acquaformosa.cs.it",
      "pec": "protocollo.acquaformosa@asmepec.it",
      "telefono": "+39 0981 94 91 21",
      "fax": "+39 0981 94 98 81",
      "coordinate": {
        "lat": 39.71666717529297,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "078002",
      "nome": "Acquappesa",
      "nomeStraniero": null,
      "codiceCatastale": "A041",
      "cap": "87020",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "servdemografici@libero.it",
      "pec": "servizidemografici.acquappesa@asmepec.it",
      "telefono": "+39 098291163",
      "fax": "+39 0982971402",
      "coordinate": {
        "lat": 39.49342346191406,
        "lng": 15.954122543334961
      }
    },
    {
      "codice": "078003",
      "nome": "Acri",
      "nomeStraniero": null,
      "codiceCatastale": "A053",
      "cap": "87041",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "segreteria@comuneacri.gov.it",
      "pec": "protocollo.acri@pec.it",
      "telefono": "+39 0984 952023",
      "fax": "+39 0984 921420",
      "coordinate": {
        "lat": 39.5,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "078004",
      "nome": "Aiello Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "A102",
      "cap": "87031",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "aiellocalabro@libero.it",
      "pec": "sindaco@pec.comune.aiellocalabro.cs.it",
      "telefono": "+39 0982 43318",
      "fax": "+39 0982 43900",
      "coordinate": {
        "lat": 39.11666488647461,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "078005",
      "nome": "Aieta",
      "nomeStraniero": null,
      "codiceCatastale": "A105",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "anagrafeaieta@tiscali.it",
      "pec": "anagrafeaieta@legalmail.it",
      "telefono": "+39 0985 71016",
      "fax": "+39 0985 71174",
      "coordinate": {
        "lat": 39.93333435058594,
        "lng": 15.816666603088379
      }
    },
    {
      "codice": "078006",
      "nome": "Albidona",
      "nomeStraniero": null,
      "codiceCatastale": "A160",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comune.albidona@libero.it",
      "pec": "comune.albidona.cs@legalmail.it",
      "telefono": "+39 0981 52001",
      "fax": "+39 0981 502868",
      "coordinate": {
        "lat": 39.924198150634766,
        "lng": 16.469999313354492
      }
    },
    {
      "codice": "078007",
      "nome": "Alessandria del Carretto",
      "nomeStraniero": null,
      "codiceCatastale": "A183",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "alessandria.carretto@libero.it",
      "pec": "alessandriadelcarretto@postecert.it",
      "telefono": "+39 0981 53006",
      "fax": "+39 0981 53006",
      "coordinate": {
        "lat": 39.96666717529297,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "078008",
      "nome": "Altilia",
      "nomeStraniero": null,
      "codiceCatastale": "A234",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comunealtilia@libero.it",
      "pec": "comunedialtilia@pec.netps.eu",
      "telefono": "+39 0984  964094",
      "fax": "+39 0984  964328",
      "coordinate": {
        "lat": 39.130489349365234,
        "lng": 16.252700805664062
      }
    },
    {
      "codice": "078009",
      "nome": "Altomonte",
      "nomeStraniero": null,
      "codiceCatastale": "A240",
      "cap": "87042",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "protocollo@comune.altomonte.cs.it",
      "pec": "comune.altomonte@anutel.it",
      "telefono": "+39 0981948041",
      "fax": "+39 0981948261",
      "coordinate": {
        "lat": 39.70000076293945,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "078010",
      "nome": "Amantea",
      "nomeStraniero": null,
      "codiceCatastale": "A253",
      "cap": "87032",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.amantea.cs.it",
      "pec": "segreteriagenerale@pec.comune.amantea.cs.it",
      "telefono": "+39 0982 4291",
      "fax": null,
      "coordinate": {
        "lat": 39.13333511352539,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "078011",
      "nome": "Amendolara",
      "nomeStraniero": null,
      "codiceCatastale": "A263",
      "cap": "87071",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "segretario@comune.amendolara.cs.it",
      "pec": "dileonicolasegretariocom@pec.dirittoitalia.it",
      "telefono": "+39 0981 911050",
      "fax": "+39 0981 911900",
      "coordinate": {
        "lat": 39.95000076293945,
        "lng": 16.58333396911621
      }
    },
    {
      "codice": "078012",
      "nome": "Aprigliano",
      "nomeStraniero": null,
      "codiceCatastale": "A340",
      "cap": "87051",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": null,
      "pec": "segreteria@pec.comuneaprigliano.it",
      "telefono": "+39 0984421409",
      "fax": null,
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.350000381469727
      }
    },
    {
      "codice": "078013",
      "nome": "Belmonte Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "A762",
      "cap": "87033",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "belmonte_calabro@tiscali.it",
      "pec": "comune.belmontecalabrocs@anutel.it",
      "telefono": "+39 0982- 400207",
      "fax": "+39 0982 - 400608",
      "coordinate": {
        "lat": 39.16666793823242,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "078014",
      "nome": "Belsito",
      "nomeStraniero": null,
      "codiceCatastale": "A768",
      "cap": "87030",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comune.belsito@libero.it",
      "pec": "sindaco.belsito@asmepec.it",
      "telefono": "+39 0984/983959",
      "fax": "+39 0984/983995",
      "coordinate": {
        "lat": 39.176849365234375,
        "lng": 16.287450790405273
      }
    },
    {
      "codice": "078015",
      "nome": "Belvedere Marittimo",
      "nomeStraniero": null,
      "codiceCatastale": "A773",
      "cap": "87021",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "segretario@comune.belvedere-marittimo.cs.it",
      "pec": "comunedibelvedere@pec.it",
      "telefono": "+39 0985.887411",
      "fax": "+39 0985.8874.147",
      "coordinate": {
        "lat": 39.61666488647461,
        "lng": 15.866666793823242
      }
    },
    {
      "codice": "078016",
      "nome": "Bianchi",
      "nomeStraniero": null,
      "codiceCatastale": "A842",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.bianchi.cs.it",
      "pec": "anagrafe.bianchi@asmepec.it",
      "telefono": "+39 0984 96 70 58",
      "fax": "+39 0984 96 70 94",
      "coordinate": {
        "lat": 39.099998474121094,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "078017",
      "nome": "Bisignano",
      "nomeStraniero": null,
      "codiceCatastale": "A887",
      "cap": "87043",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "amministrazione@comune.bisignano.cs.it",
      "pec": "comune.bisignano@mailcertificata.biz",
      "telefono": "+39 0984 951002",
      "fax": "+39 0984 951002",
      "coordinate": {
        "lat": 39.50727081298828,
        "lng": 16.28105926513672
      }
    },
    {
      "codice": "078018",
      "nome": "Bocchigliero",
      "nomeStraniero": null,
      "codiceCatastale": "A912",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "sindacobocchigliero@libero.it",
      "pec": "elettorale.bocchigliero@asmepec.it",
      "telefono": "+39 0983 92001",
      "fax": "+39 0983 92242",
      "coordinate": {
        "lat": 39.41666793823242,
        "lng": 16.75
      }
    },
    {
      "codice": "078019",
      "nome": "Bonifati",
      "nomeStraniero": null,
      "codiceCatastale": "A973",
      "cap": "87020",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": null,
      "pec": "affarigenerali.bonifati@asmepec.it",
      "telefono": "+39 0982 93338",
      "fax": "+39 0982 93392",
      "coordinate": {
        "lat": 39.58333206176758,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "078020",
      "nome": "Buonvicino",
      "nomeStraniero": null,
      "codiceCatastale": "B270",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "as.combuonvicino@tiscali.it",
      "pec": "gennaro.marsiglia@legalmail.it",
      "telefono": "+39 098585873",
      "fax": "+39 098585003",
      "coordinate": {
        "lat": 39.68333435058594,
        "lng": 15.883333206176758
      }
    },
    {
      "codice": "078021",
      "nome": "Calopezzati",
      "nomeStraniero": null,
      "codiceCatastale": "B424",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "comune-calopezzati@libero.it",
      "pec": "demografici.calopezzati@asmepec.it",
      "telefono": "+39 0983 47245 - 47314",
      "fax": "+39 0983 47868",
      "coordinate": {
        "lat": 39.56666564941406,
        "lng": 16.799999237060547
      }
    },
    {
      "codice": "078022",
      "nome": "Caloveto",
      "nomeStraniero": null,
      "codiceCatastale": "B426",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "comune.caloveto@asmepec.it",
      "pec": "comune.caloveto@asmepec.it",
      "telefono": "+39 098363005",
      "fax": "+39 0983 363900",
      "coordinate": {
        "lat": 39.5,
        "lng": 16.766666412353516
      }
    },
    {
      "codice": "078023",
      "nome": "Campana",
      "nomeStraniero": null,
      "codiceCatastale": "B500",
      "cap": "87061",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "sindaco.campana@tiscali.it",
      "pec": "segreteria.campana@asmepec.it",
      "telefono": "+39 0983 93191",
      "fax": "+39 0983 937898",
      "coordinate": {
        "lat": 39.41666793823242,
        "lng": 16.816667556762695
      }
    },
    {
      "codice": "078024",
      "nome": "Canna",
      "nomeStraniero": null,
      "codiceCatastale": "B607",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "info@comune.canna.cs.it",
      "pec": "protocollo.canna@asmepec.it",
      "telefono": "+39 0981 934000",
      "fax": "+39 0981 934291",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 16.5
      }
    },
    {
      "codice": "078025",
      "nome": "Cariati",
      "nomeStraniero": null,
      "codiceCatastale": "B774",
      "cap": "87063",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "segreteria.cariati@asmecert.cs.it",
      "pec": "segreteria.cariati@asmecert.it",
      "telefono": "+39 0983 94021",
      "fax": "+39 0983 968248",
      "coordinate": {
        "lat": 39.49626922607422,
        "lng": 16.9549503326416
      }
    },
    {
      "codice": "078026",
      "nome": "Carolei",
      "nomeStraniero": null,
      "codiceCatastale": "B802",
      "cap": "87030",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.carolei.cs.it",
      "pec": "amministrativo.carolei@asmepec.it",
      "telefono": "+39 0984634003",
      "fax": "+39 0984634656",
      "coordinate": {
        "lat": 39.25,
        "lng": 16.21666717529297
      }
    },
    {
      "codice": "078027",
      "nome": "Carpanzano",
      "nomeStraniero": null,
      "codiceCatastale": "B813",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comunecarpanzano@libero.it",
      "pec": "sindaco.carpanzano@asmepec.it",
      "telefono": "+39 0984 960003",
      "fax": "+39 0984 960166",
      "coordinate": {
        "lat": 39.150001525878906,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "078156",
      "nome": "Casali del Manco",
      "nomeStraniero": null,
      "codiceCatastale": "M385",
      "cap": "87059",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": null,
      "pec": "comune.casalidelmanco@asmepec.it",
      "telefono": "+39 0984 432000",
      "fax": "+39 0984 432033",
      "coordinate": {
        "lat": 39.29155731201172,
        "lng": 16.408611297607422
      }
    },
    {
      "codice": "078029",
      "nome": "Cassano all'Ionio",
      "nomeStraniero": null,
      "codiceCatastale": "C002",
      "cap": "87011",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "affari.generali@comune.cassanoalloionio.cs.it",
      "pec": "sindaco.comune.cassanoalloionio.cs@asmepec.it",
      "telefono": "+39 0981.780201",
      "fax": "+39 0981.780288",
      "coordinate": {
        "lat": 39.78388977050781,
        "lng": 16.318889617919922
      }
    },
    {
      "codice": "078030",
      "nome": "Castiglione Cosentino",
      "nomeStraniero": null,
      "codiceCatastale": "C301",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "castiglionecosentino@libero.it",
      "pec": "castiglionecosentino@postecert.it",
      "telefono": "+39 0984/442007",
      "fax": "+39 0984/442185",
      "coordinate": {
        "lat": 39.349998474121094,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "078031",
      "nome": "Castrolibero",
      "nomeStraniero": null,
      "codiceCatastale": "C108",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "demografici@comune.castrolibero.cs.it",
      "pec": "protocollo.comunecastrolibero@pec.it",
      "telefono": "+39 0984/858011",
      "fax": "+39 0984/858014",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "078032",
      "nome": "Castroregio",
      "nomeStraniero": null,
      "codiceCatastale": "C348",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "info@comune.castroregio.cs.it",
      "pec": "comune.castroregio.cs@legalmail.it",
      "telefono": "+39 0981 912015",
      "fax": "+39 0981 912015",
      "coordinate": {
        "lat": 39.99166488647461,
        "lng": 16.483333587646484
      }
    },
    {
      "codice": "078033",
      "nome": "Castrovillari",
      "nomeStraniero": null,
      "codiceCatastale": "C349",
      "cap": "87012",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "segretario@comune.castrovillari.cs.it",
      "pec": "protocollo@pec.comune.castrovillari.cs.it",
      "telefono": "+39 09812511",
      "fax": "+39 0981 21007",
      "coordinate": {
        "lat": 39.81666564941406,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "078034",
      "nome": "Celico",
      "nomeStraniero": null,
      "codiceCatastale": "C430",
      "cap": "87053",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "segreteria@comunedicelico.it",
      "pec": "amministrativo@pec.comunedicelico.it",
      "telefono": "+39 0984 435004",
      "fax": "+39 0984 434414",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078035",
      "nome": "Cellara",
      "nomeStraniero": null,
      "codiceCatastale": "C437",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comune.cellara@tiscali.it",
      "pec": "comune.cellara@pec.it",
      "telefono": "+39 0984 423889",
      "fax": "+39 0984 423889",
      "coordinate": {
        "lat": 39.21666717529297,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078036",
      "nome": "Cerchiara di Calabria",
      "nomeStraniero": null,
      "codiceCatastale": "C489",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "segretario@comune.cerchiara.cs.it",
      "pec": "demografici@pec.comune.cerchiara.cs.it",
      "telefono": "+39 0981 991007",
      "fax": "+39 0981 991535",
      "coordinate": {
        "lat": 39.86666488647461,
        "lng": 16.366666793823242
      }
    },
    {
      "codice": "078037",
      "nome": "Cerisano",
      "nomeStraniero": null,
      "codiceCatastale": "C515",
      "cap": "87044",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "protocollo@comune.cerisano.cs.it",
      "pec": "comune.cerisano.cs@legalmail.it",
      "telefono": "+39 0984 473005",
      "fax": "+39 0984 474746",
      "coordinate": {
        "lat": 39.28333282470703,
        "lng": 16.183332443237305
      }
    },
    {
      "codice": "078038",
      "nome": "Cervicati",
      "nomeStraniero": null,
      "codiceCatastale": "C554",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "protocollo@comune.cervicati.cs.it",
      "pec": "sindaco.cervicati@asmepec.it",
      "telefono": "+39 0984-512202",
      "fax": "+39 0984-511123",
      "coordinate": {
        "lat": 39.54999923706055,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "078039",
      "nome": "Cerzeto",
      "nomeStraniero": null,
      "codiceCatastale": "C560",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.cerzeto.cs.it",
      "pec": "comune.cerzeto@pec.comune.cerzeto.cs.it",
      "telefono": "+39 0984/523878",
      "fax": "+39 0984/523914",
      "coordinate": {
        "lat": 39.516666412353516,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078040",
      "nome": "Cetraro",
      "nomeStraniero": null,
      "codiceCatastale": "C588",
      "cap": "87022",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "sindaco@comune.cetraro.cs.it",
      "pec": "protocollo.cetraro@asmepec.it",
      "telefono": "+39 0982 978211/978267",
      "fax": "+39 0982 971392",
      "coordinate": {
        "lat": 39.5,
        "lng": 15.949999809265137
      }
    },
    {
      "codice": "078041",
      "nome": "Civita",
      "nomeStraniero": null,
      "codiceCatastale": "C763",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "demografico@comune.civita.cs.it",
      "pec": "demografico.civita@asmepec.it",
      "telefono": "+39 0981.73012",
      "fax": "+39 0981.73039",
      "coordinate": {
        "lat": 39.83333206176758,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078042",
      "nome": "Cleto",
      "nomeStraniero": null,
      "codiceCatastale": "C795",
      "cap": "87030",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "amministrativoservdemografici@comune.cleto.cs.it",
      "pec": "ufficioamministrativo@pec.comune.cleto.cs.it",
      "telefono": "+39 0982.44051",
      "fax": "+39 0982.494655",
      "coordinate": {
        "lat": 39.08333206176758,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "078043",
      "nome": "Colosimi",
      "nomeStraniero": null,
      "codiceCatastale": "C905",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "amministrativo@comune.colosimi.cs.it",
      "pec": "amministrativo.colosimi@asmepec.it",
      "telefono": "+39 0984/963003",
      "fax": "+39 0984/1780448",
      "coordinate": {
        "lat": 39.11666488647461,
        "lng": 16.399999618530273
      }
    },
    {
      "codice": "078157",
      "nome": "Corigliano-Rossano",
      "nomeStraniero": null,
      "codiceCatastale": "M403",
      "cap": "87064",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": null,
      "pec": "protocollo.coriglianorossano@asmepec.it",
      "telefono": "0983891511",
      "fax": null,
      "coordinate": {
        "lat": 39.583797454833984,
        "lng": 16.576480865478516
      }
    },
    {
      "codice": "078045",
      "nome": "Cosenza",
      "nomeStraniero": null,
      "codiceCatastale": "D086",
      "cap": "87100",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "segretariatogenerale@comune.cosenza.it",
      "pec": "comunedicosenza@superpec.eu",
      "telefono": "+39 0984 8131",
      "fax": "+39 0984 25583",
      "coordinate": {
        "lat": 39.29999923706055,
        "lng": 16.25
      }
    },
    {
      "codice": "078046",
      "nome": "Cropalati",
      "nomeStraniero": null,
      "codiceCatastale": "D180",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "comunecropalati@libero.it",
      "pec": "comune.cropalati.cs@asmepec.it",
      "telefono": "+39 0983  61064",
      "fax": "+39 0983  61877",
      "coordinate": {
        "lat": 39.51673126220703,
        "lng": 16.72673988342285
      }
    },
    {
      "codice": "078047",
      "nome": "Crosia",
      "nomeStraniero": null,
      "codiceCatastale": "D184",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "simona.curia@comunedicrosia.it",
      "pec": "elettorale.comunecrosia@asmepec.it",
      "telefono": "+39 0983-485016",
      "fax": "+39 0983-485033",
      "coordinate": {
        "lat": 39.56666564941406,
        "lng": 16.766666412353516
      }
    },
    {
      "codice": "078048",
      "nome": "Diamante",
      "nomeStraniero": null,
      "codiceCatastale": "D289",
      "cap": "87023",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "settembre.antonietta@comune-diamante.it",
      "pec": "protocollodiamante@pec.it",
      "telefono": "+39 0985 042339",
      "fax": "+39 0985 81021",
      "coordinate": {
        "lat": 39.68333435058594,
        "lng": 15.816666603088379
      }
    },
    {
      "codice": "078049",
      "nome": "Dipignano",
      "nomeStraniero": null,
      "codiceCatastale": "D304",
      "cap": "87045",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "fulviaciardullo@comune.dipignano.cs.it",
      "pec": "delegazionemunic.dipignano@asmepec.it",
      "telefono": "+39 0984621006 - +39 0984445221",
      "fax": "+39 0984621348",
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.25
      }
    },
    {
      "codice": "078050",
      "nome": "Domanico",
      "nomeStraniero": null,
      "codiceCatastale": "D328",
      "cap": "87030",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comunedomanico@libero.it",
      "pec": "comunedomanico@pec.it",
      "telefono": "+39 0984 633005",
      "fax": "+39 0984 633330",
      "coordinate": {
        "lat": 39.21666717529297,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "078051",
      "nome": "Fagnano Castello",
      "nomeStraniero": null,
      "codiceCatastale": "D464",
      "cap": "87013",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "affari.generali@comune.fagnanocastello.cs.it",
      "pec": "comunefagnanocastelloaffarigenerali@postecert.it",
      "telefono": "+39 0984 525105",
      "fax": "+39 0984525105",
      "coordinate": {
        "lat": 39.56666564941406,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "078052",
      "nome": "Falconara Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "D473",
      "cap": "87030",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "anagrafefalconara@libero.it",
      "pec": "toccigiovanni.comunefalconaraalb.uff.demografico@legalmail.it",
      "telefono": "+39 0982.82018",
      "fax": "+39 0982.82289",
      "coordinate": {
        "lat": 39.28333282470703,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "078053",
      "nome": "Figline Vegliaturo",
      "nomeStraniero": null,
      "codiceCatastale": "D582",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "ufficiotecnico@comune.figlinevegliaturo.cs.it",
      "pec": "comune.figlinevegliaturo.cs@pec.it",
      "telefono": "+39 0984 422089",
      "fax": "+39 0984 422361",
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078054",
      "nome": "Firmo",
      "nomeStraniero": null,
      "codiceCatastale": "D614",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "info@comune.firmo.cs.it",
      "pec": "protocollo@pec.comune.firmo.cs.it",
      "telefono": "+39 0981 940007",
      "fax": "+39 0981 940509",
      "coordinate": {
        "lat": 39.71666717529297,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "078055",
      "nome": "Fiumefreddo Bruzio",
      "nomeStraniero": null,
      "codiceCatastale": "D624",
      "cap": "87030",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.fiumefreddobruzio.cs.it",
      "pec": "anagrafe.fiumefreddobruzio@asmepec.it",
      "telefono": "+39 098277003",
      "fax": null,
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "078056",
      "nome": "Francavilla Marittima",
      "nomeStraniero": null,
      "codiceCatastale": "D764",
      "cap": "87072",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comune",
      "pec": "comune.francavillamarittima.cs@legalmail.it",
      "telefono": "+39 0981 994873",
      "fax": "+39 0981992300",
      "coordinate": {
        "lat": 39.81694412231445,
        "lng": 16.39555549621582
      }
    },
    {
      "codice": "078057",
      "nome": "Frascineto",
      "nomeStraniero": null,
      "codiceCatastale": "D774",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "demografico@pec.comume.frascineto.cs.it",
      "pec": "area-amministrativa@pec.comune.frascineto.cs.it",
      "telefono": "+39 0981 32013",
      "fax": "+39 0981 32488",
      "coordinate": {
        "lat": 39.83333206176758,
        "lng": 16.266666412353516
      }
    },
    {
      "codice": "078058",
      "nome": "Fuscaldo",
      "nomeStraniero": null,
      "codiceCatastale": "D828",
      "cap": "87024",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.fuscaldo.cs.it",
      "pec": "protocollo.fuscaldo@pec.it",
      "telefono": "+39 0982 859872",
      "fax": "+39 0982 89592",
      "coordinate": {
        "lat": 39.41666793823242,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "078059",
      "nome": "Grimaldi",
      "nomeStraniero": null,
      "codiceCatastale": "E180",
      "cap": "87034",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "anagrafe@comunedigrimaldi.it",
      "pec": "protocollo.grimaldi@asmepec.it",
      "telefono": "+39 0984/964067",
      "fax": "+39 0984/964848",
      "coordinate": {
        "lat": 39.150001525878906,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "078060",
      "nome": "Grisolia",
      "nomeStraniero": null,
      "codiceCatastale": "E185",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "comunegrisolia@libero.it",
      "pec": "segretario.grisolia@asmepec.it",
      "telefono": "+39 0985 - 83645",
      "fax": "+39 0985 - 83069",
      "coordinate": {
        "lat": 39.71666717529297,
        "lng": 15.850000381469727
      }
    },
    {
      "codice": "078061",
      "nome": "Guardia Piemontese",
      "nomeStraniero": null,
      "codiceCatastale": "E242",
      "cap": "87020",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "amministrativo@comune.guardiapiemontese.cs.it",
      "pec": "protocollo.amministrativoguardiapiemontese@asmepec.it",
      "telefono": "+39 0982 608010",
      "fax": null,
      "coordinate": {
        "lat": 39.46666717529297,
        "lng": 16
      }
    },
    {
      "codice": "078062",
      "nome": "Lago",
      "nomeStraniero": null,
      "codiceCatastale": "E407",
      "cap": "87035",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "amministrativo@comune.lago.cs.it",
      "pec": "amministrativo@pec.comune.lago.cs.it",
      "telefono": "+39 0982 454071",
      "fax": "+39 0982 454172",
      "coordinate": {
        "lat": 39.16666793823242,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "078063",
      "nome": "Laino Borgo",
      "nomeStraniero": null,
      "codiceCatastale": "E417",
      "cap": "87014",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "ufficio.protocollo@comune.lainoborgo.cs.it",
      "pec": "comune.lainoborgo.cs.it@pec.it",
      "telefono": "+39 98182002",
      "fax": "+39 98182712",
      "coordinate": {
        "lat": 39.95000076293945,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "078064",
      "nome": "Laino Castello",
      "nomeStraniero": null,
      "codiceCatastale": "E419",
      "cap": "87015",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.lainocastello.cs.it",
      "pec": "segretario.lainocastello@asmepec.it",
      "telefono": "+39 981.82004",
      "fax": "+39 981.82249",
      "coordinate": {
        "lat": 39.93611145019531,
        "lng": 15.977777481079102
      }
    },
    {
      "codice": "078065",
      "nome": "Lappano",
      "nomeStraniero": null,
      "codiceCatastale": "E450",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.lappano.cs.it",
      "pec": "protocollo.lappano@asmepec.it",
      "telefono": "+39 0984 651003",
      "fax": "+39 0984 651218",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078066",
      "nome": "Lattarico",
      "nomeStraniero": null,
      "codiceCatastale": "E475",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "protocollo@comune.lattarico.cs.it",
      "pec": "protocollo.lattarico@asmepec.it",
      "telefono": "+39 0984 933391",
      "fax": "+39 0984 928889",
      "coordinate": {
        "lat": 39.46666717529297,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "078067",
      "nome": "Longobardi",
      "nomeStraniero": null,
      "codiceCatastale": "E677",
      "cap": "87030",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "segretario@comune.longobardi.cs.it",
      "pec": "segreteriaelettorale.longobardi@asmepec.it",
      "telefono": "+39 0982 75895",
      "fax": "+39 0982 75238",
      "coordinate": {
        "lat": 39.20000076293945,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "078068",
      "nome": "Longobucco",
      "nomeStraniero": null,
      "codiceCatastale": "E678",
      "cap": "87066",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "demografici@comunelongobucco.eu",
      "pec": "demografici@pec.comunelongobucco.eu",
      "telefono": "+39 0983 72505",
      "fax": "+39 0983 71071",
      "coordinate": {
        "lat": 39.45000076293945,
        "lng": 16.616666793823242
      }
    },
    {
      "codice": "078069",
      "nome": "Lungro",
      "nomeStraniero": null,
      "codiceCatastale": "E745",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "segreteria.lungro@tiscali.it",
      "pec": "segreteria.lungro@asmepec.it",
      "telefono": "+39 0981 945155",
      "fax": "+39 0981 947197",
      "coordinate": {
        "lat": 39.75,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078070",
      "nome": "Luzzi",
      "nomeStraniero": null,
      "codiceCatastale": "E773",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "luzzi@comune.luzzi.cs.it",
      "pec": "protocollogenerale@comunediluzzi.legalmail.it",
      "telefono": "+39 0984 549030",
      "fax": "+39 0984 549061",
      "coordinate": {
        "lat": 39.45000076293945,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "078071",
      "nome": "Maierà",
      "nomeStraniero": null,
      "codiceCatastale": "E835",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "comune.maiera@libero.it",
      "pec": "amministrativo.maiera@asmepec.it",
      "telefono": "+39 0985 889102",
      "fax": "+39 0985 889155",
      "coordinate": {
        "lat": 39.71666717529297,
        "lng": 15.850000381469727
      }
    },
    {
      "codice": "078072",
      "nome": "Malito",
      "nomeStraniero": null,
      "codiceCatastale": "E859",
      "cap": "87030",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "amministrativo@comune.malito.cs.it",
      "pec": "protocollo.malito@asmepec.it",
      "telefono": "+39 0984 968005",
      "fax": "+39 0984 968006",
      "coordinate": {
        "lat": 39.150001525878906,
        "lng": 16.25
      }
    },
    {
      "codice": "078073",
      "nome": "Malvito",
      "nomeStraniero": null,
      "codiceCatastale": "E872",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.malvito.cs.it",
      "pec": "protocollo.malvito@asmepec.it",
      "telefono": "+39 0984/509007",
      "fax": "+39 0984/509566",
      "coordinate": {
        "lat": 39.599998474121094,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "078074",
      "nome": "Mandatoriccio",
      "nomeStraniero": null,
      "codiceCatastale": "E878",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "demomandatoriccio@libero.it",
      "pec": "protocollo.mandatoriccio@asmepec.it",
      "telefono": "+39 0983 994009",
      "fax": "+39 0983 994626",
      "coordinate": {
        "lat": 39.46666717529297,
        "lng": 16.83333396911621
      }
    },
    {
      "codice": "078075",
      "nome": "Mangone",
      "nomeStraniero": null,
      "codiceCatastale": "E888",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.mangone.cs.it",
      "pec": "amministrazione@pec.comune.mangone.cs.it",
      "telefono": "+39 0984 987469",
      "fax": "+39 0984 987470",
      "coordinate": {
        "lat": 39.20000076293945,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078076",
      "nome": "Marano Marchesato",
      "nomeStraniero": null,
      "codiceCatastale": "E914",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.maranomarchesato.cs.it",
      "pec": "amministrativo.maranom@pcert.postecert.it",
      "telefono": "+39 0984.641048",
      "fax": "+39 0984.641914",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "078077",
      "nome": "Marano Principato",
      "nomeStraniero": null,
      "codiceCatastale": "E915",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "ufficiodemografico@comune.maranoprincipato.cs.it",
      "pec": "protocollo.maranoprincipato@pec.it",
      "telefono": "+39 0984 858311",
      "fax": "+39 0984 858330",
      "coordinate": {
        "lat": 39.29999923706055,
        "lng": 16.183332443237305
      }
    },
    {
      "codice": "078078",
      "nome": "Marzi",
      "nomeStraniero": null,
      "codiceCatastale": "F001",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "marzi2@libero.it",
      "pec": "protoc.marzi@asmepec.it",
      "telefono": "+39 0984/961208",
      "fax": "+39 0984/981777",
      "coordinate": {
        "lat": 39.170631408691406,
        "lng": 16.307050704956055
      }
    },
    {
      "codice": "078079",
      "nome": "Mendicino",
      "nomeStraniero": null,
      "codiceCatastale": "F125",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.mendicino",
      "pec": "comunemendicino@mailcertificata.biz",
      "telefono": "+39 0984 638911",
      "fax": "+39 0984 630847",
      "coordinate": {
        "lat": 39.262821197509766,
        "lng": 16.194520950317383
      }
    },
    {
      "codice": "078080",
      "nome": "Mongrassano",
      "nomeStraniero": null,
      "codiceCatastale": "F370",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.mongrassano.cs.it",
      "pec": "sindaco.mongrassano@asmepec.it",
      "telefono": "+39 0984.527209",
      "fax": "+39 0984.527061",
      "coordinate": {
        "lat": 39.53333282470703,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078081",
      "nome": "Montalto Uffugo",
      "nomeStraniero": null,
      "codiceCatastale": "F416",
      "cap": "87046",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "web@comune.montaltouffugo.cs.it",
      "pec": "segreteria.montaltouffugo@certificatamail.it",
      "telefono": "+39 0984 931074",
      "fax": "+39 0984 932490",
      "coordinate": {
        "lat": 39.400001525878906,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "078082",
      "nome": "Montegiordano",
      "nomeStraniero": null,
      "codiceCatastale": "F519",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "sindaco@comune.montegiordano.cs.it",
      "pec": "segretario.montegiordano.cs@legalmail.it",
      "telefono": "+39 0981 932002",
      "fax": "+39 0981 932003",
      "coordinate": {
        "lat": 40.04999923706055,
        "lng": 16.53333282470703
      }
    },
    {
      "codice": "078083",
      "nome": "Morano Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "F708",
      "cap": "87016",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "anagrafe@comunemoranocalabro.it",
      "pec": "info@pec.comunemoranocalabro.it",
      "telefono": "+39 0981 31021",
      "fax": "+39 0981/31036",
      "coordinate": {
        "lat": 39.849998474121094,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "078084",
      "nome": "Mormanno",
      "nomeStraniero": null,
      "codiceCatastale": "F735",
      "cap": "87026",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comunemormanno@tiscali.it",
      "pec": "protocollo.mormanno@asmepec.it",
      "telefono": "+39 981.81008",
      "fax": "+39 981.841116",
      "coordinate": {
        "lat": 39.88333511352539,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "078085",
      "nome": "Mottafollone",
      "nomeStraniero": null,
      "codiceCatastale": "F775",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "com.mottafollone@tiscali.it",
      "pec": "comunemottafollone.uffprotocollo@postecert.it",
      "telefono": "+39 0981 68088",
      "fax": "+39 0981 68089",
      "coordinate": {
        "lat": 39.650001525878906,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "078086",
      "nome": "Nocara",
      "nomeStraniero": null,
      "codiceCatastale": "F907",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "info@comune.nocara.cs.it",
      "pec": "info.comune.nocara.cs@asmepec.it",
      "telefono": "+39 0981 934442",
      "fax": "+39 0981 934443",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 16.483333587646484
      }
    },
    {
      "codice": "078087",
      "nome": "Oriolo",
      "nomeStraniero": null,
      "codiceCatastale": "G110",
      "cap": "87073",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "info@comune.oriolo.cs.it",
      "pec": "info.comune.oriolo.cs@legalmail.it",
      "telefono": "+39 0981 930871",
      "fax": "+39 0981 930870",
      "coordinate": {
        "lat": 40.053810119628906,
        "lng": 16.446088790893555
      }
    },
    {
      "codice": "078088",
      "nome": "Orsomarso",
      "nomeStraniero": null,
      "codiceCatastale": "G129",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "comuneorsomarso@tiscali.it",
      "pec": "protocollo.comune.orsomarso@asmepec.it",
      "telefono": "+39 098524104",
      "fax": "+39 0985 24104",
      "coordinate": {
        "lat": 39.79999923706055,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "078089",
      "nome": "Paludi",
      "nomeStraniero": null,
      "codiceCatastale": "G298",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "paludics@tiscalinet.it",
      "pec": "ufficidemografici.paludi@asmepec.it",
      "telefono": "+39 0983 62029",
      "fax": "+39 0983 62873",
      "coordinate": {
        "lat": 39.5294303894043,
        "lng": 16.679630279541016
      }
    },
    {
      "codice": "078090",
      "nome": "Panettieri",
      "nomeStraniero": null,
      "codiceCatastale": "G307",
      "cap": "87050",
      "prefisso": "0968",
      "provincia": "Cosenza",
      "email": "info@comune.panettieri.cs.it",
      "pec": "comune.panettieri@asmepec.it",
      "telefono": "+39 0968 82018",
      "fax": "+39 0968 82018",
      "coordinate": {
        "lat": 39.06666564941406,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "078091",
      "nome": "Paola",
      "nomeStraniero": null,
      "codiceCatastale": "G317",
      "cap": "87027",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "ugravina@comune.paola.cs.it",
      "pec": "info@pec.comune.paola.cs.it",
      "telefono": "+39 0982 5800218",
      "fax": "+39 0982 583628",
      "coordinate": {
        "lat": 39.36666488647461,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "078092",
      "nome": "Papasidero",
      "nomeStraniero": null,
      "codiceCatastale": "G320",
      "cap": "87020",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "papasiderocomune@libero.it",
      "pec": "comune.papasidero@pec.it",
      "telefono": "+39 0981/83078",
      "fax": "+39 0981/83107",
      "coordinate": {
        "lat": 39.86666488647461,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "078093",
      "nome": "Parenti",
      "nomeStraniero": null,
      "codiceCatastale": "G331",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comuneparenti@tiscali.it",
      "pec": "affarigenerali.parenti@asmepec.it",
      "telefono": "+39 0984 965007",
      "fax": "+39 0984 1800500",
      "coordinate": {
        "lat": 39.16666793823242,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "078094",
      "nome": "Paterno Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "G372",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "segretario@comunedipaternocalabro.it",
      "pec": "anagrafe.paternocalabro@asmepec.it",
      "telefono": "+39 0984/476031",
      "fax": "+39 0984/476030",
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.266666412353516
      }
    },
    {
      "codice": "078096",
      "nome": "Pedivigliano",
      "nomeStraniero": null,
      "codiceCatastale": "G411",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.pedivigliano.cs.it",
      "pec": "protocollo.pedivigliano@asmepec.it",
      "telefono": "+39 0984 966105",
      "fax": "+39 0984 986049",
      "coordinate": {
        "lat": 39.11666488647461,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "078097",
      "nome": "Piane Crati",
      "nomeStraniero": null,
      "codiceCatastale": "G553",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.pianecrati.cs.it",
      "pec": "protocollo@pec.comunepianecrati.it",
      "telefono": "+39 0984 422002",
      "fax": "+39 0984 422766",
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078098",
      "nome": "Pietrafitta",
      "nomeStraniero": null,
      "codiceCatastale": "G615",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "ufficioamministrativo@comune.pietrafitta.cs.it",
      "pec": "comune.pietrafitta.cs@pec.it",
      "telefono": "+39 0984 424021",
      "fax": "+39 0984 424900",
      "coordinate": {
        "lat": 39.26102828979492,
        "lng": 16.339399337768555
      }
    },
    {
      "codice": "078099",
      "nome": "Pietrapaola",
      "nomeStraniero": null,
      "codiceCatastale": "G622",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "ufficidemografici.pietrapaola@asmepec.it",
      "pec": "protocollogenerale.pietrapaola@asmepec.it",
      "telefono": "+39 0983994013",
      "fax": "+39 0983995873",
      "coordinate": {
        "lat": 39.483333587646484,
        "lng": 16.816667556762695
      }
    },
    {
      "codice": "078100",
      "nome": "Plataci",
      "nomeStraniero": null,
      "codiceCatastale": "G733",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comunediplataci@libero.it",
      "pec": "anagrafe.plataci.cs@legalmail.it",
      "telefono": "+39 0981.54011",
      "fax": "+39 0981.54100",
      "coordinate": {
        "lat": 39.900001525878906,
        "lng": 16.433332443237305
      }
    },
    {
      "codice": "078101",
      "nome": "Praia a Mare",
      "nomeStraniero": null,
      "codiceCatastale": "G975",
      "cap": "87028",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "info@comune.praiaamare.cs.it",
      "pec": "protocollo.praia@asmepec.it",
      "telefono": "+39 0985 72353",
      "fax": "+39 0985 72555",
      "coordinate": {
        "lat": 39.91666793823242,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "078102",
      "nome": "Rende",
      "nomeStraniero": null,
      "codiceCatastale": "H235",
      "cap": "87036",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "staffsindaco@comune.rende.cs.it",
      "pec": "protocollo.rende@asmepec.it",
      "telefono": "+39 0984 1659200",
      "fax": "+39 0984 1712030",
      "coordinate": {
        "lat": 39.33333206176758,
        "lng": 16.183332443237305
      }
    },
    {
      "codice": "078103",
      "nome": "Rocca Imperiale",
      "nomeStraniero": null,
      "codiceCatastale": "H416",
      "cap": "87074",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "segretario@comune.roccaimperiale.cs.it",
      "pec": "comuneroccaimperiale.segreteria@postacert.it",
      "telefono": "+39 0981 936391",
      "fax": "+39 0981 936365",
      "coordinate": {
        "lat": 40.106407165527344,
        "lng": 16.594898223876953
      }
    },
    {
      "codice": "078104",
      "nome": "Roggiano Gravina",
      "nomeStraniero": null,
      "codiceCatastale": "H488",
      "cap": "87017",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.roggianogravina.cs.it",
      "pec": "protocollo@pec.comune.roggianogravina.cs.it",
      "telefono": "+39 0984 501515",
      "fax": "+39 0984 507389",
      "coordinate": {
        "lat": 39.61666488647461,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "078105",
      "nome": "Rogliano",
      "nomeStraniero": null,
      "codiceCatastale": "H490",
      "cap": "87054",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "protocollo@comune.rogliano.cs.it",
      "pec": "protocollo.rogliano@asmepec.it",
      "telefono": "+39 0984 961001",
      "fax": "+39 0984 961178",
      "coordinate": {
        "lat": 39.18333435058594,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078106",
      "nome": "Rose",
      "nomeStraniero": null,
      "codiceCatastale": "H565",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "amministrativa@comune.rose.cs.it",
      "pec": "protocollo.comune.rose@pec.it",
      "telefono": "+39 0984 901141",
      "fax": "+39 0984 901002",
      "coordinate": {
        "lat": 39.400001525878906,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "078107",
      "nome": "Roseto Capo Spulico",
      "nomeStraniero": null,
      "codiceCatastale": "H572",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "trib.roseto@libero.it",
      "pec": "segreteria.comunerosetocs@asmepec.it",
      "telefono": "+39 0981 913341",
      "fax": "+39 0981 913005",
      "coordinate": {
        "lat": 39.983333587646484,
        "lng": 16.600000381469727
      }
    },
    {
      "codice": "078109",
      "nome": "Rota Greca",
      "nomeStraniero": null,
      "codiceCatastale": "H585",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "com.rotagreca@libero.it",
      "pec": "ufficioservizidemograficirotagreca@asmepec.it",
      "telefono": "+39 0984 928879",
      "fax": "+39 0984 920791",
      "coordinate": {
        "lat": 39.46666717529297,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078110",
      "nome": "Rovito",
      "nomeStraniero": null,
      "codiceCatastale": "H621",
      "cap": "87050",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "anagrafecomunerovito@scsitalianet.it",
      "pec": "protocollo.rovito@asmepec.it",
      "telefono": "+39 0984 433003",
      "fax": "+39 0984 433528",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078111",
      "nome": "San Basile",
      "nomeStraniero": null,
      "codiceCatastale": "H765",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comunesanbasile@tiscali.it",
      "pec": "anagrafe.sanbasile@asmepec.it",
      "telefono": "+39 0981.35005",
      "fax": "+39 0981.35443",
      "coordinate": {
        "lat": 39.809600830078125,
        "lng": 16.163280487060547
      }
    },
    {
      "codice": "078112",
      "nome": "San Benedetto Ullano",
      "nomeStraniero": null,
      "codiceCatastale": "H774",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "servizioanagrafe@comune.sanbenedettoullano.cs.it",
      "pec": "anagrafe.sanbenedettoullano@asmepec.it",
      "telefono": "+39 0984.935003",
      "fax": "+39 0984.935873",
      "coordinate": {
        "lat": 39.43333435058594,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078113",
      "nome": "San Cosmo Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "H806",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "sancosmo@libero.it",
      "pec": "urp@pec.comune.sancosmoalbanese.cs.it",
      "telefono": "+39 0983 84060",
      "fax": "+39 098384078",
      "coordinate": {
        "lat": 39.58333206176758,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "078114",
      "nome": "San Demetrio Corone",
      "nomeStraniero": null,
      "codiceCatastale": "H818",
      "cap": "87069",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "posta@comune.sandemetriocorone.cs.it",
      "pec": "mail.sandemetriocorone@asmepec.it",
      "telefono": "+39 0984 956003",
      "fax": "+39 0984 956966",
      "coordinate": {
        "lat": 39.56666564941406,
        "lng": 16.366666793823242
      }
    },
    {
      "codice": "078115",
      "nome": "San Donato di Ninea",
      "nomeStraniero": null,
      "codiceCatastale": "H825",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "municipio@comune.sandonatodininea.cs.it",
      "pec": "protocollo.sandonatodininea@asmepec.it",
      "telefono": "+39 0981.63011",
      "fax": "+39 0981.63051",
      "coordinate": {
        "lat": 39.71666717529297,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "078116",
      "nome": "San Fili",
      "nomeStraniero": null,
      "codiceCatastale": "H841",
      "cap": "87037",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "anagrafesanfili@libero.it",
      "pec": "demografici.comunesanfili@pec.it",
      "telefono": "+39 0984643000",
      "fax": "+39 0984/642905",
      "coordinate": {
        "lat": 39.338050842285156,
        "lng": 16.143207550048828
      }
    },
    {
      "codice": "078118",
      "nome": "San Giorgio Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "H881",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "anagrafe@comune.sangiorgioalbanese.cs.it",
      "pec": "protocollo.sangiorgioalbanese@asmepec.it",
      "telefono": "+39 0983 86396",
      "fax": "+39 0983 86025",
      "coordinate": {
        "lat": 39.56666564941406,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "078119",
      "nome": "San Giovanni in Fiore",
      "nomeStraniero": null,
      "codiceCatastale": "H919",
      "cap": "87055",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "airesgfiore@virgilio.it",
      "pec": "anagrafesgfiore@asmepec.it",
      "telefono": "+39 0984/977111",
      "fax": "+39 0984-971040",
      "coordinate": {
        "lat": 39.26416778564453,
        "lng": 16.70027732849121
      }
    },
    {
      "codice": "078120",
      "nome": "San Lorenzo Bellizzi",
      "nomeStraniero": null,
      "codiceCatastale": "H961",
      "cap": "87070",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "protocollo@comune.sanlorenzobellizzi.cs.it",
      "pec": "sanlorenzobellizzicomune@pec.it",
      "telefono": "+39 0981.993013",
      "fax": "+39 0981.433113",
      "coordinate": {
        "lat": 39.88333511352539,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078121",
      "nome": "San Lorenzo del Vallo",
      "nomeStraniero": null,
      "codiceCatastale": "H962",
      "cap": "87040",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comune@sanlorenzodelvallo.eu",
      "pec": "sanlorenzodelvallo@asmepec.it",
      "telefono": "+39 0981 953103",
      "fax": "+39 0981 950703",
      "coordinate": {
        "lat": 39.66666793823242,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "078122",
      "nome": "San Lucido",
      "nomeStraniero": null,
      "codiceCatastale": "H971",
      "cap": "87038",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "temesa@libero.it",
      "pec": "protocollosanlucido@legalmail.it",
      "telefono": "+39 0982 81081",
      "fax": "+39 0982 84703",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "078123",
      "nome": "San Marco Argentano",
      "nomeStraniero": null,
      "codiceCatastale": "H981",
      "cap": "87018",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "demografici@comune.sanmarcoargentano.cs.it",
      "pec": "anagrafesanmarcoargentano@pec.it",
      "telefono": "+39 0984.512089",
      "fax": "+39 0984 512088",
      "coordinate": {
        "lat": 39.54999923706055,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078124",
      "nome": "San Martino di Finita",
      "nomeStraniero": null,
      "codiceCatastale": "H992",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "protocollo@comune.sanmartinodifinita.cs.it",
      "pec": "protocollo.sanmartinodifinita@asmepec.it",
      "telefono": "+39 0984 514047",
      "fax": "+39 0984 514047",
      "coordinate": {
        "lat": 39.483333587646484,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "078125",
      "nome": "San Nicola Arcella",
      "nomeStraniero": null,
      "codiceCatastale": "I060",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "comunesannicola@libero.it",
      "pec": "protocollo.sannicolaarcella@asmepec.it",
      "telefono": "+39 0985 3218",
      "fax": "+39 0985 3963",
      "coordinate": {
        "lat": 39.849998474121094,
        "lng": 15.800000190734863
      }
    },
    {
      "codice": "078126",
      "nome": "San Pietro in Amantea",
      "nomeStraniero": null,
      "codiceCatastale": "I108",
      "cap": "87030",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "servizidemografici_elettorale@comune.sanpietroinamantea.cs.it",
      "pec": "anagrafe.sanpietroinamantea@asmepec.it",
      "telefono": "+39 0982 425703",
      "fax": "+39 0982 427947",
      "coordinate": {
        "lat": 39.13333511352539,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "078127",
      "nome": "San Pietro in Guarano",
      "nomeStraniero": null,
      "codiceCatastale": "I114",
      "cap": "87047",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "admin@comune.sanpietroinguarano.cs.it",
      "pec": "comunesanpietroinguarano@pec.it",
      "telefono": "+39 0984 472538",
      "fax": "+39 0984 472147",
      "coordinate": {
        "lat": 39.33333206176758,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078128",
      "nome": "San Sosti",
      "nomeStraniero": null,
      "codiceCatastale": "I165",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comunedisansosti@tiscali.it",
      "pec": "protocollo.sansosti@asmepec.it",
      "telefono": "+39 098160151",
      "fax": "+39 0981 61631",
      "coordinate": {
        "lat": 39.66666793823242,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "078135",
      "nome": "San Vincenzo La Costa",
      "nomeStraniero": null,
      "codiceCatastale": "I388",
      "cap": "87030",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "giancarlolobianco@comune.sanvincenzolacosta.cs.it",
      "pec": "protocollo.sanvincenzolacosta.cs@asmepec.it",
      "telefono": "+39 0984 936616",
      "fax": "+39 0984 936497",
      "coordinate": {
        "lat": 39.36666488647461,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "078117",
      "nome": "Sangineto",
      "nomeStraniero": null,
      "codiceCatastale": "H877",
      "cap": "87020",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "segreteria.sangineto@tiscali.it",
      "pec": "protocollo.sangineto@pec.it",
      "telefono": "+39 0982 970709",
      "fax": "+39 0982 970810",
      "coordinate": {
        "lat": 39.60416793823242,
        "lng": 15.914166450500488
      }
    },
    {
      "codice": "078131",
      "nome": "Sant'Agata di Esaro",
      "nomeStraniero": null,
      "codiceCatastale": "I192",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "comunesagatadesaro@alice.it",
      "pec": "ufficioprotocollo.santagatadiesaro@pec.it",
      "telefono": "+39 0981 62890",
      "fax": "+39 0981 62900",
      "coordinate": {
        "lat": 39.61666488647461,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "078129",
      "nome": "Santa Caterina Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "I171",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "demografico@comune.santacaterinaalbanese.cs.it",
      "pec": "ufficiodemografico.scaterinaa@asmepec.it",
      "telefono": "+39 0984 500029",
      "fax": "+39 0984 500213",
      "coordinate": {
        "lat": 39.58333206176758,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "078130",
      "nome": "Santa Domenica Talao",
      "nomeStraniero": null,
      "codiceCatastale": "I183",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "ammininistrativo@comune.santadomenicatalao.cs.it",
      "pec": "segreteria.santadomenicatalao@asmepec.it",
      "telefono": "+39 0985 29151",
      "fax": "+39 0985 29544",
      "coordinate": {
        "lat": 39.81666564941406,
        "lng": 15.850000381469727
      }
    },
    {
      "codice": "078132",
      "nome": "Santa Maria del Cedro",
      "nomeStraniero": null,
      "codiceCatastale": "C717",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "rag.smariadelcedro@tiscali.it",
      "pec": "affarigenerali.santamariadelcedro@asmepec.it",
      "telefono": "+39 0985.5733",
      "fax": "+39 0985.5510",
      "coordinate": {
        "lat": 39.75,
        "lng": 15.833333015441895
      }
    },
    {
      "codice": "078133",
      "nome": "Santa Sofia d'Epiro",
      "nomeStraniero": null,
      "codiceCatastale": "I309",
      "cap": "87048",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "ufficioanagrafe@comunesantasofiadepiro.it",
      "pec": "ufficioprotocollo.santasofiadepiro@asmepec.it",
      "telefono": "+39 0984957005",
      "fax": "+39 0984957956",
      "coordinate": {
        "lat": 39.54999923706055,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078134",
      "nome": "Santo Stefano di Rogliano",
      "nomeStraniero": null,
      "codiceCatastale": "I359",
      "cap": "87056",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "santostefanodir@libero.it",
      "pec": "amministrativo.sstefano@asmepec.it",
      "telefono": "+39 0984 969141",
      "fax": "+39 0984 969363",
      "coordinate": {
        "lat": 39.20000076293945,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078136",
      "nome": "Saracena",
      "nomeStraniero": null,
      "codiceCatastale": "I423",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "info@comune.saracena.cs.it",
      "pec": "segreteria.saracena@asmepec.it",
      "telefono": "+39 0981 34160",
      "fax": null,
      "coordinate": {
        "lat": 39.766666412353516,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "078137",
      "nome": "Scala Coeli",
      "nomeStraniero": null,
      "codiceCatastale": "I485",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "dloiacono@comune.scalacoeli.cs.it",
      "pec": "demografici.scalacoeli@asmepec.it",
      "telefono": "+39 0983 95013",
      "fax": "+39 0983 95336",
      "coordinate": {
        "lat": 39.45000076293945,
        "lng": 16.883333206176758
      }
    },
    {
      "codice": "078138",
      "nome": "Scalea",
      "nomeStraniero": null,
      "codiceCatastale": "I489",
      "cap": "87029",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "gabinettosindaco@comune.scalea.cosenza.it",
      "pec": "anagrafe.scalea@asmepec.it",
      "telefono": "+39 0985 282212",
      "fax": "+39 0985 21804",
      "coordinate": {
        "lat": 39.81666564941406,
        "lng": 15.800000190734863
      }
    },
    {
      "codice": "078139",
      "nome": "Scigliano",
      "nomeStraniero": null,
      "codiceCatastale": "D290",
      "cap": "87057",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "municipio@sciglianocomune.it",
      "pec": "protocollo.scigliano@asmepec.it",
      "telefono": "+39 0984  966002",
      "fax": "+39 0984 966038",
      "coordinate": {
        "lat": 39.13333511352539,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "078140",
      "nome": "Serra d'Aiello",
      "nomeStraniero": null,
      "codiceCatastale": "I642",
      "cap": "87030",
      "prefisso": "0982",
      "provincia": "Cosenza",
      "email": "comuneserra@libero.it",
      "pec": "amministrativo.serraaiello@asmepec.it",
      "telefono": "+39 0982 44054",
      "fax": "+39 0982 44316",
      "coordinate": {
        "lat": 39.08333206176758,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "078142",
      "nome": "Spezzano Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "I895",
      "cap": "87019",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "ragioneria@comune.spezzano-albanese.cs.it",
      "pec": "servizioamministrativo.spezzanoalbanese@pec.it",
      "telefono": "+39 0981 953075",
      "fax": "+39 0981 953171",
      "coordinate": {
        "lat": 39.66666793823242,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "078143",
      "nome": "Spezzano della Sila",
      "nomeStraniero": null,
      "codiceCatastale": "I896",
      "cap": "87058",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "comunespezzanosila@gmail.com",
      "pec": "protocollo.spezzanosila@asmepec.it",
      "telefono": "+39 0984 435021",
      "fax": "+39 0984 431328",
      "coordinate": {
        "lat": 39.29999923706055,
        "lng": 16.350000381469727
      }
    },
    {
      "codice": "078145",
      "nome": "Tarsia",
      "nomeStraniero": null,
      "codiceCatastale": "L055",
      "cap": "87040",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "sindaco@comune.tarsia.cs.it",
      "pec": "statocivile.tarsia@asmepec.it",
      "telefono": "+39 0981 952015",
      "fax": "+39 0981 952693",
      "coordinate": {
        "lat": 39.61666488647461,
        "lng": 16.266666412353516
      }
    },
    {
      "codice": "078146",
      "nome": "Terranova da Sibari",
      "nomeStraniero": null,
      "codiceCatastale": "L124",
      "cap": "87010",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "affgen@comune.terranova-da-sibari.cs.it",
      "pec": "affgen.terranovadasibari@asmepec.it",
      "telefono": "+39 0981.955004",
      "fax": "+39 0981 956303",
      "coordinate": {
        "lat": 39.650001525878906,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "078147",
      "nome": "Terravecchia",
      "nomeStraniero": null,
      "codiceCatastale": "L134",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "comunediterravecchiacom@tin.it",
      "pec": "protocollo.terravecchia@asmepec.it",
      "telefono": "+39 0983 97013",
      "fax": "+39 0983 97197",
      "coordinate": {
        "lat": 39.465938568115234,
        "lng": 16.946229934692383
      }
    },
    {
      "codice": "078148",
      "nome": "Torano Castello",
      "nomeStraniero": null,
      "codiceCatastale": "L206",
      "cap": "87010",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "info@comune.toranocastello.cs.it",
      "pec": "segreteria.torano@asmepec.it",
      "telefono": "+39 0984 504007",
      "fax": "+39 0984 504875",
      "coordinate": {
        "lat": 39.5,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "078149",
      "nome": "Tortora",
      "nomeStraniero": null,
      "codiceCatastale": "L305",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "amministrativo@comune.tortora.cs.it",
      "pec": "comuneditortora@pec.it",
      "telefono": "+39 0985 764008",
      "fax": "+39 0985 765226",
      "coordinate": {
        "lat": 39.94130325317383,
        "lng": 15.80517578125
      }
    },
    {
      "codice": "078150",
      "nome": "Trebisacce",
      "nomeStraniero": null,
      "codiceCatastale": "L353",
      "cap": "87075",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "protocollo@comune.trebisacce.cs.it",
      "pec": "protocollo.comune.trebisacce@pcert.postecert.it",
      "telefono": "+39 0981 550211",
      "fax": null,
      "coordinate": {
        "lat": 39.86666488647461,
        "lng": 16.53333282470703
      }
    },
    {
      "codice": "078152",
      "nome": "Vaccarizzo Albanese",
      "nomeStraniero": null,
      "codiceCatastale": "L524",
      "cap": "87060",
      "prefisso": "0983",
      "provincia": "Cosenza",
      "email": "sindaco@comune.vaccarizzoalbanese.cs.it",
      "pec": "anagrafe.vaccarizzoalbanese@asmepec.it",
      "telefono": "+39 0983 84001",
      "fax": "+39 0983 84010",
      "coordinate": {
        "lat": 39.58333206176758,
        "lng": 16.433332443237305
      }
    },
    {
      "codice": "078153",
      "nome": "Verbicaro",
      "nomeStraniero": null,
      "codiceCatastale": "L747",
      "cap": "87020",
      "prefisso": "0985",
      "provincia": "Cosenza",
      "email": "comune.verbicaro.cs@libero.it",
      "pec": "segreteria.verbicaro@asmepec.it",
      "telefono": "+39 0985 6139",
      "fax": "+39 0985/6164",
      "coordinate": {
        "lat": 39.75,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "078154",
      "nome": "Villapiana",
      "nomeStraniero": null,
      "codiceCatastale": "B903",
      "cap": "87076",
      "prefisso": "0981",
      "provincia": "Cosenza",
      "email": "f.falcone@asmecert.it",
      "pec": "protocollo.villapiana@asmepec.it",
      "telefono": "+39 0981 505004",
      "fax": "+39 0981 505677",
      "coordinate": {
        "lat": 39.849998474121094,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "078155",
      "nome": "Zumpano",
      "nomeStraniero": null,
      "codiceCatastale": "M202",
      "cap": "87040",
      "prefisso": "0984",
      "provincia": "Cosenza",
      "email": "tributi@comune.zumpano.cs.it",
      "pec": "tributi.comune.zumpano@asmepec.it",
      "telefono": "+39 0984 788333/4",
      "fax": "+39 0984 788251",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.299999237060547
      }
    }
  ],
  "Brescia": [
    {
      "codice": "017001",
      "nome": "Acquafredda",
      "nomeStraniero": null,
      "codiceCatastale": "A034",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.acquafredda.bs.it",
      "pec": "protocollo@pec.comune.acquafredda.bs.it",
      "telefono": "+39 030 9967912",
      "fax": "+39 0309967474",
      "coordinate": {
        "lat": 45.306968688964844,
        "lng": 10.413430213928223
      }
    },
    {
      "codice": "017002",
      "nome": "Adro",
      "nomeStraniero": null,
      "codiceCatastale": "A060",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comunediadro.net",
      "pec": "comunediadro@cert.legalmail.it",
      "telefono": "+39 030/7454323",
      "fax": "+39 030/7450312",
      "coordinate": {
        "lat": 45.61790084838867,
        "lng": 9.962499618530273
      }
    },
    {
      "codice": "017003",
      "nome": "Agnosine",
      "nomeStraniero": null,
      "codiceCatastale": "A082",
      "cap": "25071",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "ragioneria@comune.agnosine.be.it",
      "pec": "protocollo@pec.comune.agnosine.bs.it",
      "telefono": "+39 0365/896141",
      "fax": "+39 0365/896990",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "017004",
      "nome": "Alfianello",
      "nomeStraniero": null,
      "codiceCatastale": "A188",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.alfianello.bs.it",
      "pec": "protocollo@pec.comune.alfianello.bs.it",
      "telefono": "+39 030/9305661",
      "fax": "+39 030/9936814",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "017005",
      "nome": "Anfo",
      "nomeStraniero": null,
      "codiceCatastale": "A288",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "Info@comune.anfo.bs.it",
      "pec": "protocollo@pec.comune.anfo.bs.it",
      "telefono": "+39 0365/809022",
      "fax": "+39 0365/809224",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.5
      }
    },
    {
      "codice": "017006",
      "nome": "Angolo Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A293",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.angolo-terme.bs.it",
      "pec": "protocollo@pec.comune.angolo-terme.bs.it",
      "telefono": "+39 0364/548012",
      "fax": "+39 0364/548967",
      "coordinate": {
        "lat": 45.8922233581543,
        "lng": 10.148611068725586
      }
    },
    {
      "codice": "017007",
      "nome": "Artogne",
      "nomeStraniero": null,
      "codiceCatastale": "A451",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "sindaco@comune.artogne.bs.it",
      "pec": "protocollo@pec.comune.artogne.bs.it",
      "telefono": "+39 0364/598280",
      "fax": "+39 0364/598408",
      "coordinate": {
        "lat": 45.850555419921875,
        "lng": 10.161944389343262
      }
    },
    {
      "codice": "017008",
      "nome": "Azzano Mella",
      "nomeStraniero": null,
      "codiceCatastale": "A529",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.azzanomella.bs.it",
      "pec": "protocollo@pec.comune.azzanomella.bs.it",
      "telefono": "+39 030/9748449",
      "fax": "+39 030/9747585",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "017009",
      "nome": "Bagnolo Mella",
      "nomeStraniero": null,
      "codiceCatastale": "A569",
      "cap": "25021",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segretario@comune.bagnolomella.bs.it",
      "pec": "protocollo@pec.comune.bagnolomella.bs.it",
      "telefono": "+39 030/6829435",
      "fax": "+39 030/6829436",
      "coordinate": {
        "lat": 45.43000030517578,
        "lng": 10.185400009155273
      }
    },
    {
      "codice": "017010",
      "nome": "Bagolino",
      "nomeStraniero": null,
      "codiceCatastale": "A578",
      "cap": "25072",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.bagolino.bs.it",
      "pec": "protocollo@pec.comune.bagolino.bs.it",
      "telefono": "+39 0365/904011",
      "fax": "+39 0365/903117",
      "coordinate": {
        "lat": 45.82310104370117,
        "lng": 10.463959693908691
      }
    },
    {
      "codice": "017011",
      "nome": "Barbariga",
      "nomeStraniero": null,
      "codiceCatastale": "A630",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.barbariga.bs.it",
      "pec": "uffprotocollo@pec.comune.barbariga.bs.it",
      "telefono": "+39 030/9718104",
      "fax": "+39 030/9770962",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "017012",
      "nome": "Barghe",
      "nomeStraniero": null,
      "codiceCatastale": "A661",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.barghe.bs.it",
      "pec": "protocollo@pec.comune.barghe.bs.it",
      "telefono": "+39 0365/84123",
      "fax": "+39 0365/84055",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "017013",
      "nome": "Bassano Bresciano",
      "nomeStraniero": null,
      "codiceCatastale": "A702",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "monica.cremaschini@comune.bassano-bresciano.bs.it",
      "pec": "protocollo@pec.comune.bassano-bresciano.bs.it",
      "telefono": "+39 030 9935112",
      "fax": "+39 0309935176",
      "coordinate": {
        "lat": 45.3305549621582,
        "lng": 10.127778053283691
      }
    },
    {
      "codice": "017014",
      "nome": "Bedizzole",
      "nomeStraniero": null,
      "codiceCatastale": "A729",
      "cap": "25081",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.bedizzole.bs.it",
      "pec": "comune.bedizzole@legalmail.it",
      "telefono": "+39 030 6872920",
      "fax": "+39 030 676041",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 10.416666984558105
      }
    },
    {
      "codice": "017015",
      "nome": "Berlingo",
      "nomeStraniero": null,
      "codiceCatastale": "A799",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.berlingo.bs.it",
      "pec": "protocollo@pec.comune.berlingo.bs.it",
      "telefono": "+39 030 9787247",
      "fax": "+39 030978182",
      "coordinate": {
        "lat": 45.50222396850586,
        "lng": 10.041388511657715
      }
    },
    {
      "codice": "017016",
      "nome": "Berzo Demo",
      "nomeStraniero": null,
      "codiceCatastale": "A816",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.berzo-demo.bs.it",
      "pec": "protocollo@pec.comune.berzo-demo.bs.it",
      "telefono": "+39 0364/630305",
      "fax": "+39 0364/62061",
      "coordinate": {
        "lat": 46.09388732910156,
        "lng": 10.334444046020508
      }
    },
    {
      "codice": "017017",
      "nome": "Berzo Inferiore",
      "nomeStraniero": null,
      "codiceCatastale": "A817",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.berzo-inferiore.bs.it",
      "pec": "protocollo@pec.comune.berzo-inferiore.bs.it",
      "telefono": "+39 0364/40100",
      "fax": "+39 0364/406321",
      "coordinate": {
        "lat": 45.93166732788086,
        "lng": 10.280555725097656
      }
    },
    {
      "codice": "017018",
      "nome": "Bienno",
      "nomeStraniero": null,
      "codiceCatastale": "A861",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.bienno.bs.it",
      "pec": "protocollo@pec.comune.bienno.bs.it",
      "telefono": "+39 036440001",
      "fax": "+39 0364406610",
      "coordinate": {
        "lat": 45.936668395996094,
        "lng": 10.294166564941406
      }
    },
    {
      "codice": "017019",
      "nome": "Bione",
      "nomeStraniero": null,
      "codiceCatastale": "A878",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "protocollo@comune.bione.bs.it",
      "pec": "protocollo@pec.comune.bione.bs.it",
      "telefono": "+39 0365/897707",
      "fax": "+39 0365/897738",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 10.333333015441895
      }
    },
    {
      "codice": "017020",
      "nome": "Borgo San Giacomo",
      "nomeStraniero": null,
      "codiceCatastale": "B035",
      "cap": "25022",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.borgosangiacomo.bs.it",
      "pec": "comune.borgosangiacomo@pec.regione.lombardia.it",
      "telefono": "+39 030/948140",
      "fax": "+39 030/9487501",
      "coordinate": {
        "lat": 45.34798049926758,
        "lng": 9.968170166015625
      }
    },
    {
      "codice": "017021",
      "nome": "Borgosatollo",
      "nomeStraniero": null,
      "codiceCatastale": "B040",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.borgosatollo.bs.it",
      "pec": "segreteria@pec.comune.borgosatollo.bs.it",
      "telefono": "+39 030/2507200",
      "fax": "+39 030/2701213",
      "coordinate": {
        "lat": 45.476112365722656,
        "lng": 10.239999771118164
      }
    },
    {
      "codice": "017022",
      "nome": "Borno",
      "nomeStraniero": null,
      "codiceCatastale": "B054",
      "cap": "25042",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.borno.bs.it",
      "pec": "protocollo@pec.comune.borno.bs.it",
      "telefono": "+39 0364/41000",
      "fax": "+39 0364/310615",
      "coordinate": {
        "lat": 45.946109771728516,
        "lng": 10.198888778686523
      }
    },
    {
      "codice": "017023",
      "nome": "Botticino",
      "nomeStraniero": null,
      "codiceCatastale": "B091",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.botticino.bs.it",
      "pec": "protocollo@pec.comune.botticino.bs.it",
      "telefono": "+39 030/2197200",
      "fax": "+39 030/2691040",
      "coordinate": {
        "lat": 45.53388977050781,
        "lng": 10.307777404785156
      }
    },
    {
      "codice": "017024",
      "nome": "Bovegno",
      "nomeStraniero": null,
      "codiceCatastale": "B100",
      "cap": "25061",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.bovegno.bs.it",
      "pec": "protocollo@pec.comune.bovegno.bs.it",
      "telefono": "+39 030926148",
      "fax": "+39 030926774",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 10.266666412353516
      }
    },
    {
      "codice": "017025",
      "nome": "Bovezzo",
      "nomeStraniero": null,
      "codiceCatastale": "B102",
      "cap": "25073",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "luisa.ferretti@comune.bovezzo.bs.it",
      "pec": "protocollo@pec.comune.bovezzo.bs.it",
      "telefono": "+39 030/2111217",
      "fax": "+39 030/2711554",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 10.25
      }
    },
    {
      "codice": "017026",
      "nome": "Brandico",
      "nomeStraniero": null,
      "codiceCatastale": "B120",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.brandico.bs.it",
      "pec": "comune.brandico@legalmail.it",
      "telefono": "+39 030/975112",
      "fax": "+39 030/9759088",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "017027",
      "nome": "Braone",
      "nomeStraniero": null,
      "codiceCatastale": "B124",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.braone.bs.it",
      "pec": "protocollo@pec.comune.braone.bs.it",
      "telefono": "+39 0364/434043",
      "fax": "+39 0364/433791",
      "coordinate": {
        "lat": 45.989444732666016,
        "lng": 10.342778205871582
      }
    },
    {
      "codice": "017028",
      "nome": "Breno",
      "nomeStraniero": null,
      "codiceCatastale": "B149",
      "cap": "25043",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "urp@comune.breno.bs.it",
      "pec": "protocollo@pec.comune.breno.bs.it",
      "telefono": "+39 0364/322624",
      "fax": "+39 0364/32619",
      "coordinate": {
        "lat": 45.95861053466797,
        "lng": 10.30555534362793
      }
    },
    {
      "codice": "017029",
      "nome": "Brescia",
      "nomeStraniero": null,
      "codiceCatastale": "B157",
      "cap": "25121",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "uffgab@comune.brescia.it",
      "pec": "protocollogenerale@pec.comune.brescia.it",
      "telefono": "+39 030 29771",
      "fax": "+39 030 2977303",
      "coordinate": {
        "lat": 45.53888702392578,
        "lng": 10.220280647277832
      }
    },
    {
      "codice": "017030",
      "nome": "Brione",
      "nomeStraniero": null,
      "codiceCatastale": "B184",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comunebrione@libero.it",
      "pec": "comune@pec.comune.brione.tn.it",
      "telefono": "+39 030/84013",
      "fax": "+39 030/84876",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.133333206176758
      }
    },
    {
      "codice": "017031",
      "nome": "Caino",
      "nomeStraniero": null,
      "codiceCatastale": "B365",
      "cap": "25070",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "ufficio.ragioneria@comune.caino.bs.it",
      "pec": "protocollo@pec.comune.caino.bs.it",
      "telefono": "+39 030/6830016",
      "fax": "+39 030/6830490",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "017032",
      "nome": "Calcinato",
      "nomeStraniero": null,
      "codiceCatastale": "B394",
      "cap": "25011",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.calcinato.bs.it",
      "pec": "protocollo@pec.comune.calcinato.bs.it",
      "telefono": "+39 03099891",
      "fax": "+39 0309636033",
      "coordinate": {
        "lat": 45.458099365234375,
        "lng": 10.414600372314453
      }
    },
    {
      "codice": "017033",
      "nome": "Calvagese della Riviera",
      "nomeStraniero": null,
      "codiceCatastale": "B436",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.calvagesedellariviera.bs.it",
      "pec": "protocollo@pec.comune.calvagesedellariviera.bs.it",
      "telefono": "+39 030/601025",
      "fax": "+39 030/601578",
      "coordinate": {
        "lat": 45.5341682434082,
        "lng": 10.442500114440918
      }
    },
    {
      "codice": "017034",
      "nome": "Calvisano",
      "nomeStraniero": null,
      "codiceCatastale": "B450",
      "cap": "25012",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comunedicalvisano.it",
      "pec": "tecnico@pec.comune.calvisano.bs.it",
      "telefono": "+39 030/96897",
      "fax": "+39 030/968228",
      "coordinate": {
        "lat": 45.3488883972168,
        "lng": 10.345833778381348
      }
    },
    {
      "codice": "017035",
      "nome": "Capo di Ponte",
      "nomeStraniero": null,
      "codiceCatastale": "B664",
      "cap": "25044",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "francesco.manella@comune.capo-di-ponte.bs.it",
      "pec": "protocollo@pec.comune.capo-di-ponte.bs.it",
      "telefono": "+39 0364/42001",
      "fax": "+39 0364/42571",
      "coordinate": {
        "lat": 46.03166580200195,
        "lng": 10.346111297607422
      }
    },
    {
      "codice": "017036",
      "nome": "Capovalle",
      "nomeStraniero": null,
      "codiceCatastale": "B676",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "anagrafe@comune.capovalle.bs.it",
      "pec": "protocollo@pec.comune.barghe.bs.it",
      "telefono": "+39 0365/750021",
      "fax": "+39 0365/750143",
      "coordinate": {
        "lat": 45.75443649291992,
        "lng": 10.544690132141113
      }
    },
    {
      "codice": "017037",
      "nome": "Capriano del Colle",
      "nomeStraniero": null,
      "codiceCatastale": "B698",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.bs.it",
      "pec": "comune.caprianodelcolle@pec.regione.lombardia.it",
      "telefono": "+39 030/9748683",
      "fax": "+39 030/9747384",
      "coordinate": {
        "lat": 45.45500183105469,
        "lng": 10.12861156463623
      }
    },
    {
      "codice": "017038",
      "nome": "Capriolo",
      "nomeStraniero": null,
      "codiceCatastale": "B711",
      "cap": "25031",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@capriolo.org",
      "pec": "comune.capriolo@pec.capriolo.org",
      "telefono": "+39 03074641",
      "fax": "+39 0307460460",
      "coordinate": {
        "lat": 45.63725662231445,
        "lng": 9.93360424041748
      }
    },
    {
      "codice": "017039",
      "nome": "Carpenedolo",
      "nomeStraniero": null,
      "codiceCatastale": "B817",
      "cap": "25013",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.carpenedolo.bs.it",
      "pec": "protocollo@pec.comune.carpenedolo.bs.it",
      "telefono": "+39 030/9697961",
      "fax": "+39 030/9698617",
      "coordinate": {
        "lat": 45.36539840698242,
        "lng": 10.432299613952637
      }
    },
    {
      "codice": "017040",
      "nome": "Castegnato",
      "nomeStraniero": null,
      "codiceCatastale": "C055",
      "cap": "25045",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comunecastegnato.org",
      "pec": "protocollo@pec.comune.castegnato.bs.it",
      "telefono": "+39 030/2146842",
      "fax": "+39 030/2147098",
      "coordinate": {
        "lat": 45.56305694580078,
        "lng": 10.114999771118164
      }
    },
    {
      "codice": "017042",
      "nome": "Castel Mella",
      "nomeStraniero": null,
      "codiceCatastale": "C208",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.castelemella.bs.it",
      "pec": "protocollo@pec.comune.castelmella.bs.it",
      "telefono": "+39 030/2550811",
      "fax": "+39 030/2550892",
      "coordinate": {
        "lat": 45.5,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "017041",
      "nome": "Castelcovati",
      "nomeStraniero": null,
      "codiceCatastale": "C072",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.castelcovati.bs.it",
      "pec": "protocollo@pec.comune.castelcovati.bs.it",
      "telefono": "+39 030/7080319",
      "fax": "+39 030/7080304",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "017043",
      "nome": "Castenedolo",
      "nomeStraniero": null,
      "codiceCatastale": "C293",
      "cap": "25014",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.castenedolo.bs.it",
      "pec": "protocollo@pec.comune.castenedolo.bs.it",
      "telefono": "+39 030/2134011",
      "fax": "+39 030/2134017",
      "coordinate": {
        "lat": 45.470401763916016,
        "lng": 10.296699523925781
      }
    },
    {
      "codice": "017044",
      "nome": "Casto",
      "nomeStraniero": null,
      "codiceCatastale": "C330",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "anagrafe@comune.casto.bs.it",
      "pec": "protocollo@pec.comune.casto.bs.it",
      "telefono": "+39 036588122",
      "fax": "+39 036588596",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "017045",
      "nome": "Castrezzato",
      "nomeStraniero": null,
      "codiceCatastale": "C332",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comunecastrezzato.it",
      "pec": "protocollo@comunecastrezzato.legalmail.it",
      "telefono": "+39 030/7041112",
      "fax": "+39 030/7040437",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.983333587646484
      }
    },
    {
      "codice": "017046",
      "nome": "Cazzago San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "C408",
      "cap": "25046",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "municipio@comune.cazzago.bs.it",
      "pec": "protocollo@pec.comune.cazzago.bs.it",
      "telefono": "+39 030/7750750",
      "fax": "+39 030/725008",
      "coordinate": {
        "lat": 45.5816650390625,
        "lng": 10.025833129882812
      }
    },
    {
      "codice": "017047",
      "nome": "Cedegolo",
      "nomeStraniero": null,
      "codiceCatastale": "C417",
      "cap": "25051",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "uff.servizidemografici@comune.cedegolo.bs.it",
      "pec": "protocollo@pec.comune.cedegolo.bs.it",
      "telefono": "+39 0364/630331",
      "fax": "+39 0364/630471",
      "coordinate": {
        "lat": 46.07749938964844,
        "lng": 10.350555419921875
      }
    },
    {
      "codice": "017048",
      "nome": "Cellatica",
      "nomeStraniero": null,
      "codiceCatastale": "C439",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.cellatica.bs.it",
      "pec": "protocollo@pec.comune.cellatica.bs.it",
      "telefono": "+39 030/2526867",
      "fax": "+39 030/2521506",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "017049",
      "nome": "Cerveno",
      "nomeStraniero": null,
      "codiceCatastale": "C549",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.cerveno.bs.it",
      "pec": "protocollo@pec.comune.cerveno.bs.it",
      "telefono": "+39 0364/434012",
      "fax": "+39 0364/434644",
      "coordinate": {
        "lat": 46.003334045410156,
        "lng": 10.326666831970215
      }
    },
    {
      "codice": "017050",
      "nome": "Ceto",
      "nomeStraniero": null,
      "codiceCatastale": "C585",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "margherita.bazzana@comune.ceto.bs.it",
      "pec": "info@pec.comuneceto.eu",
      "telefono": "0364434018",
      "fax": "0364434418",
      "coordinate": {
        "lat": 46.003334045410156,
        "lng": 10.352778434753418
      }
    },
    {
      "codice": "017051",
      "nome": "Cevo",
      "nomeStraniero": null,
      "codiceCatastale": "C591",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "sindaco@comune.cevo.bs.it",
      "pec": "protocollo@pec.comune.cevo.bs.it",
      "telefono": "+39 0364/634104",
      "fax": "+39 0364/634367",
      "coordinate": {
        "lat": 46.08222198486328,
        "lng": 10.369444847106934
      }
    },
    {
      "codice": "017052",
      "nome": "Chiari",
      "nomeStraniero": null,
      "codiceCatastale": "C618",
      "cap": "25032",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.chiari.brescia.it",
      "pec": "comunedichiari@legalmail.it",
      "telefono": "+39 030/7008265",
      "fax": "+39 030/712011",
      "coordinate": {
        "lat": 45.51972198486328,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "017053",
      "nome": "Cigole",
      "nomeStraniero": null,
      "codiceCatastale": "C685",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "municipio@comune.cigole.bs.it",
      "pec": "protocollo@cert.comune.cigole.bs.it",
      "telefono": "+39 030959126",
      "fax": "+39 0307772152",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "017054",
      "nome": "Cimbergo",
      "nomeStraniero": null,
      "codiceCatastale": "C691",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "giacomo.bella@comune.cimbergo.bs.it",
      "pec": "info@pec.comunecimbergo.eu",
      "telefono": "+39 0364/48021",
      "fax": "+39 0364/48023",
      "coordinate": {
        "lat": 46.025001525878906,
        "lng": 10.366666793823242
      }
    },
    {
      "codice": "017055",
      "nome": "Cividate Camuno",
      "nomeStraniero": null,
      "codiceCatastale": "C760",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "maria.dellanoce@comune.cividate-camuno.bs.it",
      "pec": "protocollo@pec.comune.cividate-camuno.bs.it",
      "telefono": "+39 0364/347804",
      "fax": "+39 0364/347824",
      "coordinate": {
        "lat": 45.94333267211914,
        "lng": 10.27833366394043
      }
    },
    {
      "codice": "017056",
      "nome": "Coccaglio",
      "nomeStraniero": null,
      "codiceCatastale": "C806",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.coccaglio.bs.it",
      "pec": "protocollo@pec.comune.coccaglio.bs.it",
      "telefono": "+39 030/7725711",
      "fax": "+39 030/7721800",
      "coordinate": {
        "lat": 45.563331604003906,
        "lng": 9.978333473205566
      }
    },
    {
      "codice": "017057",
      "nome": "Collebeato",
      "nomeStraniero": null,
      "codiceCatastale": "C850",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "alessandra.frassine@comune.collebeato.bs.it",
      "pec": "protocollo@pec.comune.collebeato.bs.it",
      "telefono": "+39 030/2511120",
      "fax": "+39 030/2510292",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "017058",
      "nome": "Collio",
      "nomeStraniero": null,
      "codiceCatastale": "C883",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "nicola@comune.collio.bs.it",
      "pec": "protocollo@pec.comune.collio.bs.it",
      "telefono": "+39 030/9225052",
      "fax": "+39 030/9280791",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 10.333333015441895
      }
    },
    {
      "codice": "017059",
      "nome": "Cologne",
      "nomeStraniero": null,
      "codiceCatastale": "C893",
      "cap": "25033",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.cologne.bs.it",
      "pec": "comune.cologne@pec.regione.lombardia.it",
      "telefono": "+39 030/7058101",
      "fax": "+39 030/7058130",
      "coordinate": {
        "lat": 45.58488082885742,
        "lng": 9.943400382995605
      }
    },
    {
      "codice": "017060",
      "nome": "Comezzano-Cizzago",
      "nomeStraniero": null,
      "codiceCatastale": "C925",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.comezzanocizzago.bs.it",
      "pec": "protocollo@pec.comune.comezzanocizzago.bs.it",
      "telefono": "+39 030/972021",
      "fax": null,
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "017061",
      "nome": "Concesio",
      "nomeStraniero": null,
      "codiceCatastale": "C948",
      "cap": "25062",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.concesio.brescia.it",
      "pec": "protocollo@pec.comune.concesio.brescia.it",
      "telefono": "+39 030/2184120",
      "fax": "+39 030/2180000",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "017062",
      "nome": "Corte Franca",
      "nomeStraniero": null,
      "codiceCatastale": "D058",
      "cap": "25040",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune@comune.cortefranca.bs.it",
      "pec": "protocollo.comune.cortefranca@pec.regione.lombardia.it",
      "telefono": "+39 030/984488",
      "fax": "+39 030/984535",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.983333587646484
      }
    },
    {
      "codice": "017063",
      "nome": "Corteno Golgi",
      "nomeStraniero": null,
      "codiceCatastale": "D064",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.corteno-golgi.bs.it",
      "pec": "protocollo@pec.comune.corteno-golgi.bs.it",
      "telefono": "+39 0364/740410",
      "fax": "+39 0364/740460",
      "coordinate": {
        "lat": 46.16694259643555,
        "lng": 10.244444847106934
      }
    },
    {
      "codice": "017064",
      "nome": "Corzano",
      "nomeStraniero": null,
      "codiceCatastale": "D082",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.corzano.bs.it",
      "pec": "comune.corzano@legalmail.it",
      "telefono": "+39 030/9971760",
      "fax": "+39 030/9971790",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "017065",
      "nome": "Darfo Boario Terme",
      "nomeStraniero": null,
      "codiceCatastale": "D251",
      "cap": "25047",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "segretario@darfoboarioterme.net",
      "pec": "comune.darfoboarioterme@pec.regione.lombardia.it",
      "telefono": "+39 0364/541100",
      "fax": "+39 0364/541199",
      "coordinate": {
        "lat": 45.89444351196289,
        "lng": 10.186666488647461
      }
    },
    {
      "codice": "017066",
      "nome": "Dello",
      "nomeStraniero": null,
      "codiceCatastale": "D270",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comunedidello.it",
      "pec": "protocollo@pec.comune.dello.bs.it",
      "telefono": "+39 030/9718593",
      "fax": "+39 030/9718728",
      "coordinate": {
        "lat": 45.4194450378418,
        "lng": 10.074999809265137
      }
    },
    {
      "codice": "017067",
      "nome": "Desenzano del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "D284",
      "cap": "25015",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.desenzano.brescia.it",
      "pec": "protocollo@pec.comune.desenzano.brescia.it",
      "telefono": "+39 030/9994211",
      "fax": "+39 030/9143700",
      "coordinate": {
        "lat": 45.46888732910156,
        "lng": 10.53499984741211
      }
    },
    {
      "codice": "017068",
      "nome": "Edolo",
      "nomeStraniero": null,
      "codiceCatastale": "D391",
      "cap": "25048",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "uff.servizidemografici@comune.edolo.bs.it",
      "pec": "protocollo@pec.comune.edolo.bs.it",
      "telefono": "+39 0364/773028",
      "fax": "+39 0364/71162",
      "coordinate": {
        "lat": 46.178890228271484,
        "lng": 10.329999923706055
      }
    },
    {
      "codice": "017069",
      "nome": "Erbusco",
      "nomeStraniero": null,
      "codiceCatastale": "D421",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.erbusco.bs.it",
      "pec": "comune.erbusco@pec.regione.lombardia.it",
      "telefono": "+39 030/7767323",
      "fax": "+39 030/7760141",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "017070",
      "nome": "Esine",
      "nomeStraniero": null,
      "codiceCatastale": "D434",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.esine.bs.it",
      "pec": "protocollo@pec.comune.esine.bs.it",
      "telefono": "+39 0364/367811",
      "fax": "+39 0364/466036",
      "coordinate": {
        "lat": 45.926387786865234,
        "lng": 10.251667022705078
      }
    },
    {
      "codice": "017071",
      "nome": "Fiesse",
      "nomeStraniero": null,
      "codiceCatastale": "D576",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.fiesse.bs.it",
      "pec": "protocollo.fiesse@legalmail.it",
      "telefono": "+39 030/950051",
      "fax": "+39 030/950516",
      "coordinate": {
        "lat": 45.232398986816406,
        "lng": 10.325200080871582
      }
    },
    {
      "codice": "017072",
      "nome": "Flero",
      "nomeStraniero": null,
      "codiceCatastale": "D634",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.flero.bs.it",
      "pec": "protocollo@pec.comune.flero.bs.it",
      "telefono": "+39 030/2563173",
      "fax": "+39 030/2761200",
      "coordinate": {
        "lat": 45.48350143432617,
        "lng": 10.174500465393066
      }
    },
    {
      "codice": "017073",
      "nome": "Gambara",
      "nomeStraniero": null,
      "codiceCatastale": "D891",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "elettorale@comune.gambara.bs.it",
      "pec": "protocollo@pec.comune.gambara.bs.it",
      "telefono": "+39 030/9528001",
      "fax": "+39 030/9528021",
      "coordinate": {
        "lat": 45.25619888305664,
        "lng": 10.294500350952148
      }
    },
    {
      "codice": "017074",
      "nome": "Gardone Riviera",
      "nomeStraniero": null,
      "codiceCatastale": "D917",
      "cap": "25083",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.gardoneriviera.bs.it",
      "pec": "tributi.gardoneriviera@anutel.it",
      "telefono": "+39 0365/294600",
      "fax": "+39 0365/22122",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 10.566666603088379
      }
    },
    {
      "codice": "017075",
      "nome": "Gardone Val Trompia",
      "nomeStraniero": null,
      "codiceCatastale": "D918",
      "cap": "25063",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segret@comune.gardonevaltrompia.bs.it",
      "pec": "protocollo@pec.comune.gardonevaltrompia.bs.it",
      "telefono": "+39 030/8911583",
      "fax": "+39 030/832706",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "017076",
      "nome": "Gargnano",
      "nomeStraniero": null,
      "codiceCatastale": "D924",
      "cap": "25084",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.gargnano.brescia.it",
      "pec": "protocollo@pec.comune.gargnano.bs.it",
      "telefono": "+39 0365/7988300",
      "fax": "+39 0365/71354",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 10.666666984558105
      }
    },
    {
      "codice": "017077",
      "nome": "Gavardo",
      "nomeStraniero": null,
      "codiceCatastale": "D940",
      "cap": "25085",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "protocollo@pec.comune.gavardo.bs.it",
      "pec": "protocollo@pec.comune.gavardo.bs.it",
      "telefono": "+39 0365/377411",
      "fax": "+39 0365/377417",
      "coordinate": {
        "lat": 45.587501525878906,
        "lng": 10.438888549804688
      }
    },
    {
      "codice": "017078",
      "nome": "Ghedi",
      "nomeStraniero": null,
      "codiceCatastale": "D999",
      "cap": "25016",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.ghedi.brescia.it",
      "pec": "pecghedi@cert.comune.ghedi.brescia.it",
      "telefono": "+39 030/90581",
      "fax": "+39 030/9031575",
      "coordinate": {
        "lat": 45.402000427246094,
        "lng": 10.28030014038086
      }
    },
    {
      "codice": "017079",
      "nome": "Gianico",
      "nomeStraniero": null,
      "codiceCatastale": "E010",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.gianico.bs.it",
      "pec": "protocollo@pec.comune.gianico.bs.it",
      "telefono": "+39 0364/531570",
      "fax": "+39 0364/535261",
      "coordinate": {
        "lat": 45.866390228271484,
        "lng": 10.175833702087402
      }
    },
    {
      "codice": "017080",
      "nome": "Gottolengo",
      "nomeStraniero": null,
      "codiceCatastale": "E116",
      "cap": "25023",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@gottolengo.com",
      "pec": "protocollo.gottolengo@cert.saga.it",
      "telefono": "+39 030/9518712",
      "fax": "+39 030/9517109",
      "coordinate": {
        "lat": 45.29280090332031,
        "lng": 10.27340030670166
      }
    },
    {
      "codice": "017081",
      "nome": "Gussago",
      "nomeStraniero": null,
      "codiceCatastale": "E271",
      "cap": "25064",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.gussago.bs.it",
      "pec": "protocollo@pec.comune.gussago.bs.it",
      "telefono": "+39 030/2522919",
      "fax": "+39 030/2520911",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "017082",
      "nome": "Idro",
      "nomeStraniero": null,
      "codiceCatastale": "E280",
      "cap": "25074",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "protocollo@pec.comune.idro.bs.it",
      "pec": "protocollo@pec.comune.idro.bs.it",
      "telefono": "+39 0365/83136",
      "fax": "+39 0365/823035",
      "coordinate": {
        "lat": 45.738887786865234,
        "lng": 10.477777481079102
      }
    },
    {
      "codice": "017083",
      "nome": "Incudine",
      "nomeStraniero": null,
      "codiceCatastale": "E297",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "renato.armanaschi@comune.incudine.bs.it",
      "pec": "protocollo@pec.comune.incudine.bs.it",
      "telefono": "+39 0364/71368",
      "fax": "+39 0364/73003",
      "coordinate": {
        "lat": 46.22222137451172,
        "lng": 10.358332633972168
      }
    },
    {
      "codice": "017084",
      "nome": "Irma",
      "nomeStraniero": null,
      "codiceCatastale": "E325",
      "cap": "25061",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.irma.bs.it",
      "pec": "protocollo@pec.comune.irma.bs.it",
      "telefono": "+39 030/9220157",
      "fax": "+39 030/9220974",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.283332824707031
      }
    },
    {
      "codice": "017085",
      "nome": "Iseo",
      "nomeStraniero": null,
      "codiceCatastale": "E333",
      "cap": "25049",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.iseo.bs.it",
      "pec": "protocollo@pec.comune.iseo.bs.it",
      "telefono": "+39 030980161",
      "fax": null,
      "coordinate": {
        "lat": 45.65861129760742,
        "lng": 10.053610801696777
      }
    },
    {
      "codice": "017086",
      "nome": "Isorella",
      "nomeStraniero": null,
      "codiceCatastale": "E364",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.isorella.bs.it",
      "pec": "protocollo@pec.comune.isorella.bs.it",
      "telefono": "+39 030/9958117",
      "fax": "+39 030/9952261",
      "coordinate": {
        "lat": 45.309200286865234,
        "lng": 10.3233003616333
      }
    },
    {
      "codice": "017087",
      "nome": "Lavenone",
      "nomeStraniero": null,
      "codiceCatastale": "E497",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.lavenone.bs.it",
      "pec": "protocollo@pec.comune.lavenone.bs.it",
      "telefono": "+39 0365/83154",
      "fax": "+39 0365/83214",
      "coordinate": {
        "lat": 45.738887786865234,
        "lng": 10.4375
      }
    },
    {
      "codice": "017088",
      "nome": "Leno",
      "nomeStraniero": null,
      "codiceCatastale": "E526",
      "cap": "25024",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "rossella.guerreschi@comune.leno.bs.it",
      "pec": "protocollo@pec.comune.leno.bs.it",
      "telefono": "+39 030/904644",
      "fax": "+39 030/9046283",
      "coordinate": {
        "lat": 45.370277404785156,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "017089",
      "nome": "Limone sul Garda",
      "nomeStraniero": null,
      "codiceCatastale": "E596",
      "cap": "25010",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "protocollo@pec.comune.limonesulgarda.bs.it",
      "pec": "protocollo@pec.comune.limonesulgarda.bs.it",
      "telefono": "+39 0365/954008",
      "fax": "+39 0365/954366",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 10.783333778381348
      }
    },
    {
      "codice": "017090",
      "nome": "Lodrino",
      "nomeStraniero": null,
      "codiceCatastale": "E652",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "demografici@comune.lodrino.bs.it",
      "pec": "protocollo@pec.comune.lodrino.bs.it",
      "telefono": "+39 030/8950160",
      "fax": "+39 030/850376",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "017091",
      "nome": "Lograto",
      "nomeStraniero": null,
      "codiceCatastale": "E654",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.lograto.bs.it",
      "pec": "protocollo@pec.comune.lograto.bs.it",
      "telefono": "+39 030/9973614",
      "fax": "+39 030/9787221",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "017092",
      "nome": "Lonato del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "M312",
      "cap": "25017",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "areaamministrativa@comune.lonato.bs.it",
      "pec": "protocollo@pec.comune.lonato.bs.it",
      "telefono": "+39 030/91392217",
      "fax": "+39 030/91392229",
      "coordinate": {
        "lat": 45.46111297607422,
        "lng": 10.484444618225098
      }
    },
    {
      "codice": "017093",
      "nome": "Longhena",
      "nomeStraniero": null,
      "codiceCatastale": "E673",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune.longhena@libero.it",
      "pec": "protocollo@pec.comune.longhena.bs.it",
      "telefono": "+39 030/975116",
      "fax": "+39 030/9975088",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 10.066666603088379
      }
    },
    {
      "codice": "017094",
      "nome": "Losine",
      "nomeStraniero": null,
      "codiceCatastale": "E698",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.losine.bs.it",
      "pec": "comune.losine@pec.it",
      "telefono": "+39 0364/330223",
      "fax": "+39 0364/339343",
      "coordinate": {
        "lat": 45.98416519165039,
        "lng": 10.316944122314453
      }
    },
    {
      "codice": "017095",
      "nome": "Lozio",
      "nomeStraniero": null,
      "codiceCatastale": "E706",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.lozio.bs.it",
      "pec": "protocollo@pec.comune.lozio.bs.it",
      "telefono": "+39 0364/494010",
      "fax": "+39 0364/49500",
      "coordinate": {
        "lat": 45.98638916015625,
        "lng": 10.261944770812988
      }
    },
    {
      "codice": "017096",
      "nome": "Lumezzane",
      "nomeStraniero": null,
      "codiceCatastale": "E738",
      "cap": "25065",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "urp@comune.lumezzane.bs.it",
      "pec": "comune.lumezzane@cert.legalmail.it",
      "telefono": "+39 030/89291",
      "fax": "+39 030/8921510",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.266666412353516
      }
    },
    {
      "codice": "017097",
      "nome": "Maclodio",
      "nomeStraniero": null,
      "codiceCatastale": "E787",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": null,
      "pec": "protocollo@pec.comune.maclodio.bs.it",
      "telefono": "+39 0309787241",
      "fax": "+39 0309973904",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "017098",
      "nome": "Magasa",
      "nomeStraniero": null,
      "codiceCatastale": "E800",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.magasa.bs.it",
      "pec": "protocollo@pec.comune.magasa.bs.it",
      "telefono": "+39 0365.745010",
      "fax": "+39 0365/74049",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.616666793823242
      }
    },
    {
      "codice": "017099",
      "nome": "Mairano",
      "nomeStraniero": null,
      "codiceCatastale": "E841",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "tiziana.peritore@comune.mairano.bs.it",
      "pec": "protocollo@pec.comune.mairano.bs.it",
      "telefono": "+39 030/975122",
      "fax": "+39 030/9975032",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "017100",
      "nome": "Malegno",
      "nomeStraniero": null,
      "codiceCatastale": "E851",
      "cap": "25053",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.malegno.bs.it",
      "pec": "protocollo@pec.comune.malegno.bs.it",
      "telefono": "+39 0364/340500",
      "fax": "+39 0364/344463",
      "coordinate": {
        "lat": 45.95166778564453,
        "lng": 10.274999618530273
      }
    },
    {
      "codice": "017101",
      "nome": "Malonno",
      "nomeStraniero": null,
      "codiceCatastale": "E865",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "uff.segreteria@comune.malonno.bs.it",
      "pec": "protocollo@pec.comune.malonno.bs.it",
      "telefono": "+39 0364/635576",
      "fax": "+39 0364/65184",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "017102",
      "nome": "Manerba del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "E883",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.manerbadelgarda.bs.it",
      "pec": "protocollo@pec.comune.manerbadelgarda.bs.it",
      "telefono": "+39 0365/659801",
      "fax": "+39 0365/659802",
      "coordinate": {
        "lat": 45.549720764160156,
        "lng": 10.55138874053955
      }
    },
    {
      "codice": "017103",
      "nome": "Manerbio",
      "nomeStraniero": null,
      "codiceCatastale": "E884",
      "cap": "25025",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segrdir@comune.manerbio.bs.it",
      "pec": "protocollo@pec.comune.manerbio.bs.it",
      "telefono": "+39 030/9387236",
      "fax": "+39 030/9387225",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 10.133333206176758
      }
    },
    {
      "codice": "017104",
      "nome": "Marcheno",
      "nomeStraniero": null,
      "codiceCatastale": "E928",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune@comune.marcheno.bs.it",
      "pec": "protocollo@pec.comune.marcheno.bs.it",
      "telefono": "+39 030/8960033",
      "fax": "+39 030/8960305",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "017105",
      "nome": "Marmentino",
      "nomeStraniero": null,
      "codiceCatastale": "E961",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@.comune.marmentino.bs.it",
      "pec": "protocollo@pec.comune.marmentino.bs.it",
      "telefono": "+39 030/9228202",
      "fax": "+39 030/9228010",
      "coordinate": {
        "lat": 45.75,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "017106",
      "nome": "Marone",
      "nomeStraniero": null,
      "codiceCatastale": "E967",
      "cap": "25054",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@pec.comune.marone.bs.it",
      "pec": "protocollo@pec.comune.marone.bs.it",
      "telefono": "+39 030987104",
      "fax": "+39 030/9877067",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "017107",
      "nome": "Mazzano",
      "nomeStraniero": null,
      "codiceCatastale": "F063",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.mazzano.bs.it",
      "pec": "comune.mazzano@pec.it",
      "telefono": "+39 030/2121960",
      "fax": "+39 030/2120054",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "017108",
      "nome": "Milzano",
      "nomeStraniero": null,
      "codiceCatastale": "F216",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.milzano.bs.it",
      "pec": "info@cert.comune.milzano.bs.it",
      "telefono": "+39 030/954654",
      "fax": "+39   030/954428",
      "coordinate": {
        "lat": 45.274723052978516,
        "lng": 10.199722290039062
      }
    },
    {
      "codice": "017109",
      "nome": "Moniga del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "F373",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "demografici@comune.monigadelgarda.bs.it",
      "pec": "protocollo@pec.comune.monigadelgarda.bs.it",
      "telefono": "+39 0365/500811",
      "fax": "+39 0365/500817",
      "coordinate": {
        "lat": 45.52694320678711,
        "lng": 10.538888931274414
      }
    },
    {
      "codice": "017110",
      "nome": "Monno",
      "nomeStraniero": null,
      "codiceCatastale": "F375",
      "cap": "25040",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.monno.bs.it",
      "pec": "protocollo@pec.comune.monno.bs.it",
      "telefono": "+39 0364/779400",
      "fax": "+39 0364/779214",
      "coordinate": {
        "lat": 46.21333312988281,
        "lng": 10.340555191040039
      }
    },
    {
      "codice": "017111",
      "nome": "Monte Isola",
      "nomeStraniero": null,
      "codiceCatastale": "F532",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.monteisola.bs.it",
      "pec": "protocollo@cert.comune.monteisola.bs.it",
      "telefono": "+39 030/9825226",
      "fax": "+39 030/9825078",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "017112",
      "nome": "Monticelli Brusati",
      "nomeStraniero": null,
      "codiceCatastale": "F672",
      "cap": "25040",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.monticellibrusati.bs.it",
      "pec": "protocollo@pec.comune.monticellibrusati.bs.it",
      "telefono": "+39 030/652423",
      "fax": "+39 030/6852048",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "017113",
      "nome": "Montichiari",
      "nomeStraniero": null,
      "codiceCatastale": "F471",
      "cap": "25018",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@montichiari.it",
      "pec": "ufficio.protocollo@cert.montichiari.it",
      "telefono": "+39 030/9656286",
      "fax": "+39 030/9656382",
      "coordinate": {
        "lat": 45.416099548339844,
        "lng": 10.39169979095459
      }
    },
    {
      "codice": "017114",
      "nome": "Montirone",
      "nomeStraniero": null,
      "codiceCatastale": "F680",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune.montirone@comune.montirone.bs.it",
      "pec": "protocollo@pec.comune.montirone.bs.it",
      "telefono": "+39 030/2677097",
      "fax": "+39 030/2677340",
      "coordinate": {
        "lat": 45.44722366333008,
        "lng": 10.229722023010254
      }
    },
    {
      "codice": "017115",
      "nome": "Mura",
      "nomeStraniero": null,
      "codiceCatastale": "F806",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "comune.mura@libero.it",
      "pec": "protocollo@pec.comune.mura.bs.it",
      "telefono": "+39 0365/899020",
      "fax": "+39 0365/899090",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "017116",
      "nome": "Muscoline",
      "nomeStraniero": null,
      "codiceCatastale": "F820",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "anagrafe@comune.muscoline.bs.it",
      "pec": "protocollocomunedimuscoline@legal.intred.it",
      "telefono": "+39 0365/371440",
      "fax": "+39 0365/34903",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 10.466666221618652
      }
    },
    {
      "codice": "017117",
      "nome": "Nave",
      "nomeStraniero": null,
      "codiceCatastale": "F851",
      "cap": "25075",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.nave.bs.it",
      "pec": "protocollo@pec.comune.nave.bs.it",
      "telefono": "+39 030/2537411",
      "fax": "+39 030/2533416",
      "coordinate": {
        "lat": 45.584999084472656,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "017118",
      "nome": "Niardo",
      "nomeStraniero": null,
      "codiceCatastale": "F884",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.niardo.bs.it",
      "pec": "comune.niardo@pec.regione.lombardia.it",
      "telefono": "+39 0364/330123",
      "fax": "+39 0364/330254",
      "coordinate": {
        "lat": 45.97666549682617,
        "lng": 10.334166526794434
      }
    },
    {
      "codice": "017119",
      "nome": "Nuvolento",
      "nomeStraniero": null,
      "codiceCatastale": "F989",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segretario@comune.nuvolento.bs.it",
      "pec": "protocollo@pec.comune.nuvolento.bs.it",
      "telefono": "+39 030/69008216",
      "fax": "+39 030/7772687",
      "coordinate": {
        "lat": 45.5444450378418,
        "lng": 10.385000228881836
      }
    },
    {
      "codice": "017120",
      "nome": "Nuvolera",
      "nomeStraniero": null,
      "codiceCatastale": "F990",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.nuvolera.bs.it",
      "pec": "nuvolera.protocollo@legalmailpa.it",
      "telefono": "+39 030/6916771",
      "fax": "+39 030/6897563",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 10.372221946716309
      }
    },
    {
      "codice": "017121",
      "nome": "Odolo",
      "nomeStraniero": null,
      "codiceCatastale": "G001",
      "cap": "25076",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "ufficio.segreteria@comune.odolo.bs.it",
      "pec": "protocollo@pec.comune.odolo.bs.it",
      "telefono": "+39 0365/826321",
      "fax": "+39 0365/826324",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.383333206176758
      }
    },
    {
      "codice": "017122",
      "nome": "Offlaga",
      "nomeStraniero": null,
      "codiceCatastale": "G006",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "ufficio.statocivile@comune.offlaga.bs.it",
      "pec": "protocollo@cert.comune.offlaga.bs.it",
      "telefono": "+39 030/979123",
      "fax": "+39 030/979901",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "017123",
      "nome": "Ome",
      "nomeStraniero": null,
      "codiceCatastale": "G061",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "ome@comune.ome.bs.it",
      "pec": "protocollo@pec.comune.ome.bs.it",
      "telefono": "+39 030/652025",
      "fax": "+39 030/652283",
      "coordinate": {
        "lat": 45.627281188964844,
        "lng": 10.12136459350586
      }
    },
    {
      "codice": "017124",
      "nome": "Ono San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "G074",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.ono-san-pietro.bs.it",
      "pec": "protocollo@pec.comune.ono-san-pietro.bs.it",
      "telefono": "+39 0364/434490",
      "fax": "+39 0364/434030",
      "coordinate": {
        "lat": 46.01777648925781,
        "lng": 10.329166412353516
      }
    },
    {
      "codice": "017125",
      "nome": "Orzinuovi",
      "nomeStraniero": null,
      "codiceCatastale": "G149",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "respsegreteria@comune.orzinuovi.bs.it",
      "pec": "protocollo@pec.comune.orzinuovi.bs.it",
      "telefono": "+39 030/9942211",
      "fax": "+39 030/941163",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "017126",
      "nome": "Orzivecchi",
      "nomeStraniero": null,
      "codiceCatastale": "G150",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.orzivecchi.bs.it",
      "pec": "protocollo@pec.comune.orzivecchi.bs.it",
      "telefono": "+39 030/9461130",
      "fax": "+39 030/9461935",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "017127",
      "nome": "Ospitaletto",
      "nomeStraniero": null,
      "codiceCatastale": "G170",
      "cap": "25035",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.ospitaletto.bs.it",
      "pec": "comune.ospitaletto@legalmail.it",
      "telefono": "+39 030/6841223",
      "fax": "+39 030/643374",
      "coordinate": {
        "lat": 45.55527877807617,
        "lng": 10.073333740234375
      }
    },
    {
      "codice": "017128",
      "nome": "Ossimo",
      "nomeStraniero": null,
      "codiceCatastale": "G179",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.ossimo.bs.it",
      "pec": "info.comune.ossimo@pec.regione.lombardia.it",
      "telefono": "+39 0364/41100",
      "fax": "+39 0364/311856",
      "coordinate": {
        "lat": 45.94694519042969,
        "lng": 10.231389045715332
      }
    },
    {
      "codice": "017129",
      "nome": "Padenghe sul Garda",
      "nomeStraniero": null,
      "codiceCatastale": "G213",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.padenghe.brescia.it",
      "pec": "comune.padenghesulgarda@legalmail.it",
      "telefono": "+39 030 9995611",
      "fax": "+39 030 9907246",
      "coordinate": {
        "lat": 45.50833511352539,
        "lng": 10.516388893127441
      }
    },
    {
      "codice": "017130",
      "nome": "Paderno Franciacorta",
      "nomeStraniero": null,
      "codiceCatastale": "G217",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.padernofranciacorta.bs.it",
      "pec": "protocollo@pec.comune.padernofranciacorta.bs.it",
      "telefono": "+39 030/6853911",
      "fax": "+39 030/6857274",
      "coordinate": {
        "lat": 45.588890075683594,
        "lng": 10.077500343322754
      }
    },
    {
      "codice": "017131",
      "nome": "Paisco Loveno",
      "nomeStraniero": null,
      "codiceCatastale": "G247",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.paisco-loveno.bs.it",
      "pec": "protocollo@pec.comune.paisco-loveno.bs.it",
      "telefono": "+39 0364/636010",
      "fax": "+39 0364/636500",
      "coordinate": {
        "lat": 46.08000183105469,
        "lng": 10.293333053588867
      }
    },
    {
      "codice": "017132",
      "nome": "Paitone",
      "nomeStraniero": null,
      "codiceCatastale": "G248",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteriaprotocollo@comune.paitone.bs.it",
      "pec": "protocollo@pec.comune.paitone.bs.it",
      "telefono": "+39 030/691155",
      "fax": "+39 030/6916406",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "017133",
      "nome": "Palazzolo sull'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "G264",
      "cap": "25036",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.palazzolosulloglio.bs.it",
      "pec": "protocollo@palazzolo.viapec.it",
      "telefono": "+39 030/7405503",
      "fax": "+39 030/7405566",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "017134",
      "nome": "Paratico",
      "nomeStraniero": null,
      "codiceCatastale": "G327",
      "cap": "25030",
      "prefisso": "035",
      "provincia": "Brescia",
      "email": "protocollo@comune.paratico.bs.it",
      "pec": "segreteria@pec.comune.paratico.bs.it",
      "telefono": "+39 035/924311",
      "fax": "+39 035/924351",
      "coordinate": {
        "lat": 45.661109924316406,
        "lng": 9.953332901000977
      }
    },
    {
      "codice": "017135",
      "nome": "Paspardo",
      "nomeStraniero": null,
      "codiceCatastale": "G354",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.paspardo.bs.it",
      "pec": "info@pec.comunepaspardo.eu",
      "telefono": "+39 0364/48025",
      "fax": "+39 0364/48025",
      "coordinate": {
        "lat": 46.03166580200195,
        "lng": 10.371944427490234
      }
    },
    {
      "codice": "017136",
      "nome": "Passirano",
      "nomeStraniero": null,
      "codiceCatastale": "G361",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.passirano.bs.it",
      "pec": "protocollo@pec.comune.passirano.bs.it",
      "telefono": "+39 030/6850557",
      "fax": "+39 030/653454",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.066666603088379
      }
    },
    {
      "codice": "017137",
      "nome": "Pavone del Mella",
      "nomeStraniero": null,
      "codiceCatastale": "G391",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune.pavone-mella@libero.it",
      "pec": "protocollo@pec.comune.pavone-del-mella.bs.it",
      "telefono": "+39 030/959338",
      "fax": "+39 030/959493",
      "coordinate": {
        "lat": 45.30083465576172,
        "lng": 10.208611488342285
      }
    },
    {
      "codice": "017139",
      "nome": "Pertica Alta",
      "nomeStraniero": null,
      "codiceCatastale": "G474",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.perticaalta.bs.it",
      "pec": "protocollo@pec.comune.perticaalta.bs.it",
      "telefono": "+39 0365/821490",
      "fax": "+39 0365821597",
      "coordinate": {
        "lat": 45.75,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "017140",
      "nome": "Pertica Bassa",
      "nomeStraniero": null,
      "codiceCatastale": "G475",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.perticabassa.bs.it",
      "pec": "protocollo@pec.comune.perticabassa.bs.it",
      "telefono": "+39 0365/821131",
      "fax": "+39 0365/821195",
      "coordinate": {
        "lat": 45.75,
        "lng": 10.366666793823242
      }
    },
    {
      "codice": "017141",
      "nome": "Pezzaze",
      "nomeStraniero": null,
      "codiceCatastale": "G529",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "marzio.maffina@comune.pezzaze.bs.it",
      "pec": "protocollo@pec.comune.pezzaze.bs.it",
      "telefono": "+39 0309220100",
      "fax": "+39 030/9220139",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "017142",
      "nome": "Pian Camuno",
      "nomeStraniero": null,
      "codiceCatastale": "G546",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "uff.ragioneria@comune.piancamuno.bs.it",
      "pec": "protocollo@pec.comune.piancamuno.bs.it",
      "telefono": "+39 0364/593820",
      "fax": "+39 0364/593899",
      "coordinate": {
        "lat": 45.844722747802734,
        "lng": 10.151944160461426
      }
    },
    {
      "codice": "017206",
      "nome": "Piancogno",
      "nomeStraniero": null,
      "codiceCatastale": "G549",
      "cap": "25052",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.piancogno.bs.it",
      "pec": "protocollo@pec.comune.piancogno.bs.it",
      "telefono": "+39 0364/364800",
      "fax": "+39 0364/364899",
      "coordinate": {
        "lat": 45.9196891784668,
        "lng": 10.225259780883789
      }
    },
    {
      "codice": "017143",
      "nome": "Pisogne",
      "nomeStraniero": null,
      "codiceCatastale": "G710",
      "cap": "25055",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.pisogne.bs.it",
      "pec": "protocollo@pec.comune.pisogne.bs.it",
      "telefono": "+39 0364/883011",
      "fax": "+39 0364/87446",
      "coordinate": {
        "lat": 45.81083297729492,
        "lng": 10.108055114746094
      }
    },
    {
      "codice": "017144",
      "nome": "Polaveno",
      "nomeStraniero": null,
      "codiceCatastale": "G779",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.polaveno.bs.it",
      "pec": "protocollo.polaveno@legal.intred.it",
      "telefono": "+39 030/8940955",
      "fax": "+39 030/84109",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "017145",
      "nome": "Polpenazze del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "G801",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "anagrafe@comune.polpenazzedelgarda.bs.it",
      "pec": "protocollo@pec.comune.polpenazzedelgarda.bs.it",
      "telefono": "0365674012",
      "fax": "0365674639",
      "coordinate": {
        "lat": 45.552223205566406,
        "lng": 10.507499694824219
      }
    },
    {
      "codice": "017146",
      "nome": "Pompiano",
      "nomeStraniero": null,
      "codiceCatastale": "G815",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "affari.generali@comune.pompiano.brescia.it",
      "pec": "protocollo@pec.comune.pompiano.brescia.it",
      "telefono": "+39 030/9462017",
      "fax": "+39 030/9460940",
      "coordinate": {
        "lat": 45.431976318359375,
        "lng": 9.989265441894531
      }
    },
    {
      "codice": "017147",
      "nome": "Poncarale",
      "nomeStraniero": null,
      "codiceCatastale": "G818",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.poncarale.brescia.it",
      "pec": "protocollo@pec.comune.poncarale.brescia.it",
      "telefono": "+39 030/2640184",
      "fax": "+39 030/2540202",
      "coordinate": {
        "lat": 45.46189880371094,
        "lng": 10.177800178527832
      }
    },
    {
      "codice": "017148",
      "nome": "Ponte di Legno",
      "nomeStraniero": null,
      "codiceCatastale": "G844",
      "cap": "25056",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.pontedilegno.bs.it",
      "pec": "protocollo@pec.comune.ponte-di-legno.bs.it",
      "telefono": "+39 0364 9298",
      "fax": "+39 0364/91658",
      "coordinate": {
        "lat": 46.25944519042969,
        "lng": 10.509444236755371
      }
    },
    {
      "codice": "017149",
      "nome": "Pontevico",
      "nomeStraniero": null,
      "codiceCatastale": "G859",
      "cap": "25026",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune@pontevico.it",
      "pec": "amministrazione@pec.pontevico.it",
      "telefono": "+39 030/993111",
      "fax": "+39 030/9930312",
      "coordinate": {
        "lat": 45.272220611572266,
        "lng": 10.091666221618652
      }
    },
    {
      "codice": "017150",
      "nome": "Pontoglio",
      "nomeStraniero": null,
      "codiceCatastale": "G869",
      "cap": "25037",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.pontoglio.bs.it",
      "pec": "comune.pontoglio@pec.regione.lombardia.it",
      "telefono": "+39 030.7479200",
      "fax": "+39 030.7479228",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "017151",
      "nome": "Pozzolengo",
      "nomeStraniero": null,
      "codiceCatastale": "G959",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@pec.comune.pozzolengo.bs.it",
      "pec": "protocollo@pec.comune.pozzolengo.bs.it",
      "telefono": "+39 030/918131",
      "fax": "+39 030/918358",
      "coordinate": {
        "lat": 45.406944274902344,
        "lng": 10.633889198303223
      }
    },
    {
      "codice": "017152",
      "nome": "Pralboino",
      "nomeStraniero": null,
      "codiceCatastale": "G977",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.pralboino.bs.it",
      "pec": "protocollo@pec.comune.pralboino.bs.it",
      "telefono": "+39 030/954129",
      "fax": "+39 030/9547397",
      "coordinate": {
        "lat": 45.268333435058594,
        "lng": 10.21833324432373
      }
    },
    {
      "codice": "017153",
      "nome": "Preseglie",
      "nomeStraniero": null,
      "codiceCatastale": "H043",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.preseglie.bs.it",
      "pec": "protocollo@pec.comune.preseglie.bs.it",
      "telefono": "+39 0365/84221",
      "fax": "+39 0365/824351",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "017155",
      "nome": "Prevalle",
      "nomeStraniero": null,
      "codiceCatastale": "H055",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.prevalle.bs.it",
      "pec": "protocollo_prevalle@pec.it",
      "telefono": "+39 030/603142",
      "fax": "+39 030/6801272",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 10.416666984558105
      }
    },
    {
      "codice": "017156",
      "nome": "Provaglio d'Iseo",
      "nomeStraniero": null,
      "codiceCatastale": "H078",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "urp@comune.provagliodiseo.bs.it",
      "pec": "protocollo@pec.comune.provagliodiseo.bs.it",
      "telefono": "+39 030/9291011",
      "fax": "+39 030/9291235",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "017157",
      "nome": "Provaglio Val Sabbia",
      "nomeStraniero": null,
      "codiceCatastale": "H077",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "demografici@comune.provagliovalsabbia.bs.it",
      "pec": "protocollo@pec.comune.provagliovalsabbia.bs.it",
      "telefono": "+39 0365/84112",
      "fax": "+39 0365/894421",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "017158",
      "nome": "Puegnago del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "H086",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.puegnagodelgarda.bs.it",
      "pec": "protocollo@pec.comune.puegnagodelgarda.bs.it",
      "telefono": "+39 0365555311",
      "fax": "+39 0365555315",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 10.510000228881836
      }
    },
    {
      "codice": "017159",
      "nome": "Quinzano d'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "H140",
      "cap": "25027",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "demografici@quinzano.it",
      "pec": "protocollo@pec.comune.quinzanodoglio.bs.it",
      "telefono": "+39 030/933232",
      "fax": "+39 030/9924384",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.983333587646484
      }
    },
    {
      "codice": "017160",
      "nome": "Remedello",
      "nomeStraniero": null,
      "codiceCatastale": "H230",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.remedello.bs.it",
      "pec": "protocollo@pec.comune.remedello.bs.it",
      "telefono": "+39 030/9953970",
      "fax": "+39 030/9953929",
      "coordinate": {
        "lat": 45.27916717529297,
        "lng": 10.372777938842773
      }
    },
    {
      "codice": "017161",
      "nome": "Rezzato",
      "nomeStraniero": null,
      "codiceCatastale": "H256",
      "cap": "25086",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.rezzato.bs.it",
      "pec": "protocollo@pec.comune.rezzato.bs.it",
      "telefono": "+39 030/249774",
      "fax": "+39 030/2590535",
      "coordinate": {
        "lat": 45.51499938964844,
        "lng": 10.317500114440918
      }
    },
    {
      "codice": "017162",
      "nome": "Roccafranca",
      "nomeStraniero": null,
      "codiceCatastale": "H410",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.roccafranca.bs.it",
      "pec": "protocollo@pec.comune.roccafranca.bs.it",
      "telefono": "+39 030/7092005",
      "fax": "+39 030/7090235",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.916666984558105
      }
    },
    {
      "codice": "017163",
      "nome": "Rodengo Saiano",
      "nomeStraniero": null,
      "codiceCatastale": "H477",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@rodengosaiano.net",
      "pec": "protocollo@pec.comune.rodengo-saiano.bs.it",
      "telefono": "+39 030 6817711",
      "fax": "+39 030 6817736",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "017164",
      "nome": "Roè Volciano",
      "nomeStraniero": null,
      "codiceCatastale": "H484",
      "cap": "25077",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.roevolciano.bs.it",
      "pec": "protocollo@pec.comune.roevolciano.bs.it",
      "telefono": "+39 0365/563611",
      "fax": "+39 0365/563690",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.5
      }
    },
    {
      "codice": "017165",
      "nome": "Roncadelle",
      "nomeStraniero": null,
      "codiceCatastale": "H525",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.roncadelle.bs.it",
      "pec": "protocollo@pec.comune.roncadelle.bs.it",
      "telefono": "+39 030/2589651",
      "fax": "+39 030/2589659",
      "coordinate": {
        "lat": 45.52717971801758,
        "lng": 10.154069900512695
      }
    },
    {
      "codice": "017166",
      "nome": "Rovato",
      "nomeStraniero": null,
      "codiceCatastale": "H598",
      "cap": "25038",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "r.spadavecchia@comune.rovato.bs.it",
      "pec": "protocollo@pec.comune.rovato.bs.it",
      "telefono": "+39 030/7713232",
      "fax": "+39 030/7713217",
      "coordinate": {
        "lat": 45.567039489746094,
        "lng": 9.998600959777832
      }
    },
    {
      "codice": "017167",
      "nome": "Rudiano",
      "nomeStraniero": null,
      "codiceCatastale": "H630",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.rudiano.bs.it",
      "pec": "protocollo@pec.comune.rudiano.bs.it",
      "telefono": "+39 030/7060737",
      "fax": "+39 030/7167539",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "017168",
      "nome": "Sabbio Chiese",
      "nomeStraniero": null,
      "codiceCatastale": "H650",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.sabbio.bs.it",
      "pec": "protocollo@pec.comune.sabbio.bs.it",
      "telefono": "+39 0365/85119",
      "fax": "+39 0365/85555",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "017169",
      "nome": "Sale Marasino",
      "nomeStraniero": null,
      "codiceCatastale": "H699",
      "cap": "25057",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.sale-marasino.bs.it",
      "pec": "protocollo@pec.comune.sale-marasino.bs.it",
      "telefono": "+39 0309820921",
      "fax": "+39 0309824104",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "017170",
      "nome": "Salò",
      "nomeStraniero": null,
      "codiceCatastale": "H717",
      "cap": "25087",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "affari.generali@comune.salo.bs.it",
      "pec": "protocollo@pec.comune.salo.bs.it",
      "telefono": "+39 0365/296814",
      "fax": "+39 0365/21071",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.533333778381348
      }
    },
    {
      "codice": "017171",
      "nome": "San Felice del Benaco",
      "nomeStraniero": null,
      "codiceCatastale": "H838",
      "cap": "25010",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.sanfelicedelbenaco.bs.it",
      "pec": "protocollo@pec.comune.sanfelicedelbenaco.bs.it",
      "telefono": "+39 0365/558611",
      "fax": "+39 0365/558623",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 10.550000190734863
      }
    },
    {
      "codice": "017172",
      "nome": "San Gervasio Bresciano",
      "nomeStraniero": null,
      "codiceCatastale": "H865",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "amministrativo@comunesangervasio.it",
      "pec": "protocollo@pec.comune.sangervasiobresciano.bs.it",
      "telefono": "+39 030/9934912",
      "fax": "+39 030/9934003",
      "coordinate": {
        "lat": 45.307220458984375,
        "lng": 10.149444580078125
      }
    },
    {
      "codice": "017138",
      "nome": "San Paolo",
      "nomeStraniero": null,
      "codiceCatastale": "G407",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "servizidemografici@comune.sanpaolo.bs.it",
      "pec": "comune.sanpaolo@pec.regione.lombardia.it",
      "telefono": "+39 030/9970001",
      "fax": "+39 030/9970871",
      "coordinate": {
        "lat": 45.371665954589844,
        "lng": 10.023611068725586
      }
    },
    {
      "codice": "017173",
      "nome": "San Zeno Naviglio",
      "nomeStraniero": null,
      "codiceCatastale": "I412",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe_commercio@comune.sanzenonaviglio.bs.it",
      "pec": "sanzenonaviglio@legalmail.it",
      "telefono": "+39 030/2160555",
      "fax": "+39 030/2168224",
      "coordinate": {
        "lat": 45.491111755371094,
        "lng": 10.217499732971191
      }
    },
    {
      "codice": "017174",
      "nome": "Sarezzo",
      "nomeStraniero": null,
      "codiceCatastale": "I433",
      "cap": "25068",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "comune.sarezzo@comune.sarezzo.bs.it",
      "pec": "protocollosarezzo@cert.legalmail.it",
      "telefono": "+39 030/8936211",
      "fax": "+39 030/800155",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.199999809265137
      }
    },
    {
      "codice": "017175",
      "nome": "Saviore dell'Adamello",
      "nomeStraniero": null,
      "codiceCatastale": "I476",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.saviore-delladamello.bs.it",
      "pec": "protocollo@pec.comune.saviore-delladamello.bs.it",
      "telefono": "+39 0364/634131",
      "fax": "+39 0364/634669",
      "coordinate": {
        "lat": 46.081390380859375,
        "lng": 10.400555610656738
      }
    },
    {
      "codice": "017176",
      "nome": "Sellero",
      "nomeStraniero": null,
      "codiceCatastale": "I588",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.sellero.bs.it",
      "pec": "protocollo@pec.comune.sellero.bs.it",
      "telefono": "+39 0364/637009",
      "fax": "+39 0364/637207",
      "coordinate": {
        "lat": 46.05583190917969,
        "lng": 10.347777366638184
      }
    },
    {
      "codice": "017177",
      "nome": "Seniga",
      "nomeStraniero": null,
      "codiceCatastale": "I607",
      "cap": "25020",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "anagrafe@comune.seniga.bs.it",
      "pec": "protocollo@cert.comune.seniga.bs.it",
      "telefono": "+39 030/9955027",
      "fax": "+39 030/9955509",
      "coordinate": {
        "lat": 45.24333190917969,
        "lng": 10.178055763244629
      }
    },
    {
      "codice": "017178",
      "nome": "Serle",
      "nomeStraniero": null,
      "codiceCatastale": "I631",
      "cap": "25080",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.serle.bs.it",
      "pec": "protocollo@pec.comune.serle.bs.it",
      "telefono": "+39 030/6910000",
      "fax": "+39 030/6910821",
      "coordinate": {
        "lat": 45.57222366333008,
        "lng": 10.366666793823242
      }
    },
    {
      "codice": "017179",
      "nome": "Sirmione",
      "nomeStraniero": null,
      "codiceCatastale": "I633",
      "cap": "25019",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "m.scardeoni@sirmione.bs.it",
      "pec": "comune.sirmione@pec.regione.lombardia.it",
      "telefono": "+39 030/9909106",
      "fax": "+39 030/9909101",
      "coordinate": {
        "lat": 45.469444274902344,
        "lng": 10.606111526489258
      }
    },
    {
      "codice": "017180",
      "nome": "Soiano del Lago",
      "nomeStraniero": null,
      "codiceCatastale": "I782",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "protocollo@comune.soiano-del-lago.bs.it",
      "pec": "protocollo@pec.comune.soianodellago.bs.it",
      "telefono": "+39 0365/676102",
      "fax": "+39 0365/675414",
      "coordinate": {
        "lat": 45.52777862548828,
        "lng": 10.512680053710938
      }
    },
    {
      "codice": "017181",
      "nome": "Sonico",
      "nomeStraniero": null,
      "codiceCatastale": "I831",
      "cap": "25048",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.sonico.bs.it",
      "pec": "protocollo@pec.comune.sonico.bs.it",
      "telefono": "+39 0364/75030",
      "fax": "+39 0364/75391",
      "coordinate": {
        "lat": 46.16638946533203,
        "lng": 10.353888511657715
      }
    },
    {
      "codice": "017182",
      "nome": "Sulzano",
      "nomeStraniero": null,
      "codiceCatastale": "L002",
      "cap": "25058",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.sulzano.bs.it",
      "pec": "protocollo@pec.comune.sulzano.bs.it",
      "telefono": "+39 030/985141",
      "fax": "+39 030/985473",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "017183",
      "nome": "Tavernole sul Mella",
      "nomeStraniero": null,
      "codiceCatastale": "C698",
      "cap": "25060",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@comune.tavernolesulmella.bs.it",
      "pec": "protocollo@pec.comune.tavernolesulmella.bs.it",
      "telefono": "+39 030/920108",
      "fax": "+39 030/9220030",
      "coordinate": {
        "lat": 45.74618148803711,
        "lng": 10.239520072937012
      }
    },
    {
      "codice": "017184",
      "nome": "Temù",
      "nomeStraniero": null,
      "codiceCatastale": "L094",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.temu.bs.it",
      "pec": "protocollo@pec.comune.temu.bs.it",
      "telefono": "+39 0364/906420",
      "fax": "+39 0364/94365",
      "coordinate": {
        "lat": 46.24944305419922,
        "lng": 10.468889236450195
      }
    },
    {
      "codice": "017185",
      "nome": "Tignale",
      "nomeStraniero": null,
      "codiceCatastale": "L169",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.tignale.bs.it",
      "pec": "protocollo@pec.comune.tignale.bs.it",
      "telefono": "+39 0365/73017",
      "fax": "+39 0365/760137",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "017186",
      "nome": "Torbole Casaglia",
      "nomeStraniero": null,
      "codiceCatastale": "L210",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "sindaco@comune.torbole-casaglia.bs.it",
      "pec": "info@cert.comune.torbole-casaglia.bs.it",
      "telefono": "+39 030/2650015",
      "fax": "+39 030/2157535",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "017187",
      "nome": "Toscolano-Maderno",
      "nomeStraniero": null,
      "codiceCatastale": "L312",
      "cap": "25088",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@pec.comune.toscolanomaderno.bs.it",
      "pec": "segreteria@pec.comune.toscolanomaderno.bs.it",
      "telefono": "+39 0365 546011",
      "fax": "+39 0365 540808",
      "coordinate": {
        "lat": 45.6397705078125,
        "lng": 10.607600212097168
      }
    },
    {
      "codice": "017188",
      "nome": "Travagliato",
      "nomeStraniero": null,
      "codiceCatastale": "L339",
      "cap": "25039",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.travagliato.bs.it",
      "pec": "protocollo@pec.comune.travagliato.bs.it",
      "telefono": "+39 030/661961",
      "fax": "+39 030/661965",
      "coordinate": {
        "lat": 45.52397155761719,
        "lng": 10.07972240447998
      }
    },
    {
      "codice": "017189",
      "nome": "Tremosine sul Garda",
      "nomeStraniero": null,
      "codiceCatastale": "L372",
      "cap": "25010",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comunetremosine.it",
      "pec": "protocollo@pec.comune.tremosine.bs.it",
      "telefono": "+39 0365/915811",
      "fax": "+39 0365/953117",
      "coordinate": {
        "lat": 45.772220611572266,
        "lng": 10.758333206176758
      }
    },
    {
      "codice": "017190",
      "nome": "Trenzano",
      "nomeStraniero": null,
      "codiceCatastale": "L380",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.trenzano.bs.it",
      "pec": "protocollo@pec.comune.trenzano.bs.it",
      "telefono": "+39 030/9709300",
      "fax": "+39 030/9977531",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "017191",
      "nome": "Treviso Bresciano",
      "nomeStraniero": null,
      "codiceCatastale": "L406",
      "cap": "25070",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.trevisobresciano.bs.it",
      "pec": "protocollo@pec.comune.trevisobresciano.bs.it",
      "telefono": "+39 0365/821610",
      "fax": "+39 0365/821699",
      "coordinate": {
        "lat": 45.71238708496094,
        "lng": 10.4613676071167
      }
    },
    {
      "codice": "017192",
      "nome": "Urago d'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "L494",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "protocollo@uragodoglio.gov.it",
      "pec": "protocollo@pec.comune.uragodoglio.bs.it",
      "telefono": "+39 030717114",
      "fax": "+39 030717016",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "017193",
      "nome": "Vallio Terme",
      "nomeStraniero": null,
      "codiceCatastale": "L626",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "anagrafe@comune.vallioterme.bs.it",
      "pec": "protocollo@pec.comune.vallioterme.bs.it",
      "telefono": "+39 0365/370023",
      "fax": "+39 0365/370291",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "017194",
      "nome": "Valvestino",
      "nomeStraniero": null,
      "codiceCatastale": "L468",
      "cap": "25080",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.valvestino.bs.it",
      "pec": "protocollo@pec.comune.valvestino.bs.it",
      "telefono": "+39 0365/74012",
      "fax": "+39 0365/74005",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "017195",
      "nome": "Verolanuova",
      "nomeStraniero": null,
      "codiceCatastale": "L777",
      "cap": "25028",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "direttore@comune.verolanuova.bs.it",
      "pec": "protocollo@pec.comune.verolanuova.bs.it",
      "telefono": "+39 030/9365010",
      "fax": "+39 030/9361821",
      "coordinate": {
        "lat": 45.32583236694336,
        "lng": 10.075833320617676
      }
    },
    {
      "codice": "017196",
      "nome": "Verolavecchia",
      "nomeStraniero": null,
      "codiceCatastale": "L778",
      "cap": "25029",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.verolavecchia.bs.it",
      "pec": "protocollo@pec.comune.verolavecchia.bs.it",
      "telefono": "+39 030/9360407",
      "fax": "+39 030/931777",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "017197",
      "nome": "Vestone",
      "nomeStraniero": null,
      "codiceCatastale": "L812",
      "cap": "25078",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.vestone.bs.it",
      "pec": "protocollo@pec.comune.vestone.bs.it",
      "telefono": "+39 0365/81241",
      "fax": "+39 0365/820510",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "017198",
      "nome": "Vezza d'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "L816",
      "cap": "25059",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "info@comune.vezza-d-oglio.bs.it",
      "pec": "protocollo@pec.comune.vezza-d-oglio.bs.it",
      "telefono": "+39 0364/779610",
      "fax": "+39 0364/76492",
      "coordinate": {
        "lat": 46.238887786865234,
        "lng": 10.398056030273438
      }
    },
    {
      "codice": "017199",
      "nome": "Villa Carcina",
      "nomeStraniero": null,
      "codiceCatastale": "L919",
      "cap": "25069",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "pedersoli.anna@comune.villacarcina.bs.it",
      "pec": "comunevillacarcina@legal.intred.it",
      "telefono": "+39 0308984339",
      "fax": "+39 0308984306",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.199999809265137
      }
    },
    {
      "codice": "017200",
      "nome": "Villachiara",
      "nomeStraniero": null,
      "codiceCatastale": "L923",
      "cap": "25030",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "segreteria@comune.villachiara.bs.it",
      "pec": "protocollo@pec.comune.villachiara.bs.it",
      "telefono": "+39 030/9947112",
      "fax": "+39 030/9947312",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "017201",
      "nome": "Villanuova sul Clisi",
      "nomeStraniero": null,
      "codiceCatastale": "L995",
      "cap": "25089",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "segreteria@comune.villanuova-sul-clisi.bs.it",
      "pec": "comunevillanuova@pec.it",
      "telefono": "+39 0365 31161",
      "fax": "+39 0365/373591",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.449999809265137
      }
    },
    {
      "codice": "017202",
      "nome": "Vione",
      "nomeStraniero": null,
      "codiceCatastale": "M065",
      "cap": "25050",
      "prefisso": "0364",
      "provincia": "Brescia",
      "email": "sindaco@comune.vione.bs.it",
      "pec": "protocollo@pec.comune.vione.bs.it",
      "telefono": "+39 0364/94131",
      "fax": "+39 0364/948528",
      "coordinate": {
        "lat": 46.24808120727539,
        "lng": 10.448419570922852
      }
    },
    {
      "codice": "017203",
      "nome": "Visano",
      "nomeStraniero": null,
      "codiceCatastale": "M070",
      "cap": "25010",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "sindaco@comune.visano.bs.it",
      "pec": "protocollo@pec.comune.visano.bs.it",
      "telefono": "+39 030/9958812",
      "fax": "+39 030/9958715",
      "coordinate": {
        "lat": 45.31930160522461,
        "lng": 10.37339973449707
      }
    },
    {
      "codice": "017204",
      "nome": "Vobarno",
      "nomeStraniero": null,
      "codiceCatastale": "M104",
      "cap": "25079",
      "prefisso": "0365",
      "provincia": "Brescia",
      "email": "info@comune.vobarno.bs.it",
      "pec": "protocollo@pec.comune.vobarno.bs.it",
      "telefono": "+39 0365/596011",
      "fax": "+39 0365/596036",
      "coordinate": {
        "lat": 45.64099884033203,
        "lng": 10.504500389099121
      }
    },
    {
      "codice": "017205",
      "nome": "Zone",
      "nomeStraniero": null,
      "codiceCatastale": "M188",
      "cap": "25050",
      "prefisso": "030",
      "provincia": "Brescia",
      "email": "info@comune.zone.bs.it",
      "pec": "protocollo@pec.comune.zone.bs.it",
      "telefono": "+39 030/9870913",
      "fax": "+39 030/9880167",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.116666793823242
      }
    }
  ],
  "Pesaro e Urbino": [
    {
      "codice": "041001",
      "nome": "Acqualagna",
      "nomeStraniero": null,
      "codiceCatastale": "A035",
      "cap": "61041",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.acqualagna@provincia.ps.it",
      "pec": "comune.acqualagna@emarche.it",
      "telefono": "+39 0721/79671",
      "fax": "+39 0721/799044",
      "coordinate": {
        "lat": 43.61669921875,
        "lng": 12.671199798583984
      }
    },
    {
      "codice": "041002",
      "nome": "Apecchio",
      "nomeStraniero": null,
      "codiceCatastale": "A327",
      "cap": "61042",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "anagrafe@comune.apecchio.ps.it",
      "pec": "comune.apecchio.anagrafe@emarche.it",
      "telefono": "+39 0722/989004",
      "fax": "+39 0722/989006",
      "coordinate": {
        "lat": 43.559051513671875,
        "lng": 12.418299674987793
      }
    },
    {
      "codice": "041005",
      "nome": "Belforte all'Isauro",
      "nomeStraniero": null,
      "codiceCatastale": "A740",
      "cap": "61020",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "urp@comune.belforte.pu.it",
      "pec": "comune.belforteisauro@emarche.it",
      "telefono": "+39 0722/721597",
      "fax": null,
      "coordinate": {
        "lat": 43.71699905395508,
        "lng": 12.375730514526367
      }
    },
    {
      "codice": "041006",
      "nome": "Borgo Pace",
      "nomeStraniero": null,
      "codiceCatastale": "B026",
      "cap": "61040",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "serv.demografici@comune.borgo-pace.ps.it",
      "pec": "comune.borgo-pace@emarche.it",
      "telefono": "+39 0722/816048",
      "fax": "+39 0722/816049",
      "coordinate": {
        "lat": 43.657901763916016,
        "lng": 12.294150352478027
      }
    },
    {
      "codice": "041007",
      "nome": "Cagli",
      "nomeStraniero": null,
      "codiceCatastale": "B352",
      "cap": "61043",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "municipio@comune.cagli.ps.it",
      "pec": "comune.cagli@emarche.it",
      "telefono": "+39 0721/78071",
      "fax": "+39 0721/780792",
      "coordinate": {
        "lat": 43.547000885009766,
        "lng": 12.647299766540527
      }
    },
    {
      "codice": "041008",
      "nome": "Cantiano",
      "nomeStraniero": null,
      "codiceCatastale": "B636",
      "cap": "61044",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.cantiano@provincia.ps.it",
      "pec": "comune.cantiano@emarche.it",
      "telefono": "0721789911",
      "fax": "0721789106",
      "coordinate": {
        "lat": 43.47257995605469,
        "lng": 12.628530502319336
      }
    },
    {
      "codice": "041009",
      "nome": "Carpegna",
      "nomeStraniero": null,
      "codiceCatastale": "B816",
      "cap": "61021",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.carpegna@provincia.ps.it",
      "pec": "comune.carpegna@emarche.it",
      "telefono": "+39 0722/727065",
      "fax": "+39 0722/727007",
      "coordinate": {
        "lat": 43.781700134277344,
        "lng": 12.336649894714355
      }
    },
    {
      "codice": "041010",
      "nome": "Cartoceto",
      "nomeStraniero": null,
      "codiceCatastale": "B846",
      "cap": "61030",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.cartoceto@provincia.ps.it",
      "pec": "comune.cartoceto@emarche.it",
      "telefono": "+39 0721/898123",
      "fax": "+39 0721/898473",
      "coordinate": {
        "lat": 43.76430130004883,
        "lng": 12.883199691772461
      }
    },
    {
      "codice": "041069",
      "nome": "Colli al Metauro",
      "nomeStraniero": null,
      "codiceCatastale": "M380",
      "cap": "61036",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.collialmetauro@provincia.ps.it",
      "pec": "comune.collialmetauro@pecitaly.it",
      "telefono": "+39 0721892901",
      "fax": "+39 07211892963",
      "coordinate": {
        "lat": 43.7534294128418,
        "lng": 12.897600173950195
      }
    },
    {
      "codice": "041013",
      "nome": "Fano",
      "nomeStraniero": null,
      "codiceCatastale": "D488",
      "cap": "61032",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "sindaco@comune.fano.ps.it",
      "pec": "comune.fano@emarche.it",
      "telefono": "+39 0721 8871",
      "fax": "+39 0721/887368",
      "coordinate": {
        "lat": 43.843528747558594,
        "lng": 13.019755363464355
      }
    },
    {
      "codice": "041014",
      "nome": "Fermignano",
      "nomeStraniero": null,
      "codiceCatastale": "D541",
      "cap": "61033",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune@comune.fermignano.pu.it",
      "pec": "comune.fermignano@emarche.it",
      "telefono": "+39 0722/332142",
      "fax": "+39 0722/332336",
      "coordinate": {
        "lat": 43.67661666870117,
        "lng": 12.645038604736328
      }
    },
    {
      "codice": "041015",
      "nome": "Fossombrone",
      "nomeStraniero": null,
      "codiceCatastale": "D749",
      "cap": "61034",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.fossombrone@provincia.ps.it",
      "pec": "comune.fossombrone@emarche.it",
      "telefono": "+39 07217231",
      "fax": "+39 0721723205",
      "coordinate": {
        "lat": 43.68895721435547,
        "lng": 12.806119918823242
      }
    },
    {
      "codice": "041016",
      "nome": "Fratte Rosa",
      "nomeStraniero": null,
      "codiceCatastale": "D791",
      "cap": "61040",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.fratte-rosa@provincia.ps.it",
      "pec": "comune.fratte-rosa@emarche.it",
      "telefono": "+39 0721/777200",
      "fax": "---",
      "coordinate": {
        "lat": 43.63209915161133,
        "lng": 12.901169776916504
      }
    },
    {
      "codice": "041017",
      "nome": "Frontino",
      "nomeStraniero": null,
      "codiceCatastale": "D807",
      "cap": "61020",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.frontino@provincia.ps.it",
      "pec": "comune.frontino@emarche.it",
      "telefono": "+39 0722/71131",
      "fax": "+39 0722/71342",
      "coordinate": {
        "lat": 43.764530181884766,
        "lng": 12.377930641174316
      }
    },
    {
      "codice": "041018",
      "nome": "Frontone",
      "nomeStraniero": null,
      "codiceCatastale": "D808",
      "cap": "61040",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.frontone@provincia.ps.it",
      "pec": "comune.frontone@emarche.it",
      "telefono": "+39 0721/786107",
      "fax": "+39 0721/786107",
      "coordinate": {
        "lat": 43.51443862915039,
        "lng": 12.738450050354004
      }
    },
    {
      "codice": "041019",
      "nome": "Gabicce Mare",
      "nomeStraniero": null,
      "codiceCatastale": "D836",
      "cap": "61011",
      "prefisso": "0541",
      "provincia": "Pesaro e Urbino",
      "email": "urp@comune.gabicce-mare.pu.it",
      "pec": "comune.gabiccemare@emarche.it",
      "telefono": "+39 0541820611",
      "fax": "+39 0541953741",
      "coordinate": {
        "lat": 43.96683883666992,
        "lng": 12.756260871887207
      }
    },
    {
      "codice": "041020",
      "nome": "Gradara",
      "nomeStraniero": null,
      "codiceCatastale": "E122",
      "cap": "61012",
      "prefisso": "0541",
      "provincia": "Pesaro e Urbino",
      "email": "protocollo@comune.gradara.pu.it",
      "pec": "comune.gradara@emarche.it",
      "telefono": "+39 0541/823905",
      "fax": "+39 0541/964490",
      "coordinate": {
        "lat": 43.938865661621094,
        "lng": 12.771252632141113
      }
    },
    {
      "codice": "041021",
      "nome": "Isola del Piano",
      "nomeStraniero": null,
      "codiceCatastale": "E351",
      "cap": "61030",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.isola-piano@provincia.ps.it",
      "pec": "comune.isoladelpiano@emarche.it",
      "telefono": "0721720127",
      "fax": "0721720972",
      "coordinate": {
        "lat": 43.737239837646484,
        "lng": 12.783100128173828
      }
    },
    {
      "codice": "041022",
      "nome": "Lunano",
      "nomeStraniero": null,
      "codiceCatastale": "E743",
      "cap": "61026",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.lunano@provincia.ps.it",
      "pec": "comune.lunano@emarche.it",
      "telefono": "+39 0722/70126",
      "fax": "+39 0722/70271",
      "coordinate": {
        "lat": 43.730350494384766,
        "lng": 12.44064998626709
      }
    },
    {
      "codice": "041023",
      "nome": "Macerata Feltria",
      "nomeStraniero": null,
      "codiceCatastale": "E785",
      "cap": "61023",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.macerataf@provincia.ps.it",
      "pec": "comune.maceratafeltria@emarche.it",
      "telefono": "+39 0722/74244",
      "fax": "+39 0722/74709",
      "coordinate": {
        "lat": 43.80332946777344,
        "lng": 12.441849708557129
      }
    },
    {
      "codice": "041025",
      "nome": "Mercatello sul Metauro",
      "nomeStraniero": null,
      "codiceCatastale": "F135",
      "cap": "61040",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "rag.patarchi@comune.mercatellosulmetauro.pu.it",
      "pec": "comune.mercatello@emarche.it",
      "telefono": "+39 0722/89114",
      "fax": "+39 0722/89840",
      "coordinate": {
        "lat": 43.64739990234375,
        "lng": 12.336830139160156
      }
    },
    {
      "codice": "041026",
      "nome": "Mercatino Conca",
      "nomeStraniero": null,
      "codiceCatastale": "F136",
      "cap": "61013",
      "prefisso": "0541",
      "provincia": "Pesaro e Urbino",
      "email": "comune.mercatino@provincia.ps.it",
      "pec": "comune.mercatinoconca@emarche.it",
      "telefono": "+39 0541/970145",
      "fax": "+39 0541/970430",
      "coordinate": {
        "lat": 43.87049865722656,
        "lng": 12.491600036621094
      }
    },
    {
      "codice": "041027",
      "nome": "Mombaroccio",
      "nomeStraniero": null,
      "codiceCatastale": "F310",
      "cap": "61024",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "anagrafe@comune.mombaroccio.pu.it",
      "pec": "protocollo@pec.comunemombaroccio.it",
      "telefono": "+39 0721/471103",
      "fax": "+39 0721/471170",
      "coordinate": {
        "lat": 43.796932220458984,
        "lng": 12.855130195617676
      }
    },
    {
      "codice": "041028",
      "nome": "Mondavio",
      "nomeStraniero": null,
      "codiceCatastale": "F347",
      "cap": "61040",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.mondavio@provincia.ps.it",
      "pec": "comune.mondavio@emarche.it",
      "telefono": "+39 0721/97101",
      "fax": "+39 0721/97123",
      "coordinate": {
        "lat": 43.674049377441406,
        "lng": 12.968280792236328
      }
    },
    {
      "codice": "041029",
      "nome": "Mondolfo",
      "nomeStraniero": null,
      "codiceCatastale": "F348",
      "cap": "61037",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.mondolfo@provincia.ps.it",
      "pec": "protocollo.comune.mondolfo@emarche.it",
      "telefono": "+39 07219391",
      "fax": "+39 0721959455",
      "coordinate": {
        "lat": 43.75170135498047,
        "lng": 13.095569610595703
      }
    },
    {
      "codice": "041031",
      "nome": "Monte Cerignone",
      "nomeStraniero": null,
      "codiceCatastale": "F467",
      "cap": "61010",
      "prefisso": "0541",
      "provincia": "Pesaro e Urbino",
      "email": "comune.montecerignone@provincia.ps.it",
      "pec": "comune.montecerignone@emache.it",
      "telefono": "+39 0541/978522",
      "fax": "+39 0541/978501",
      "coordinate": {
        "lat": 43.84111022949219,
        "lng": 12.4127779006958
      }
    },
    {
      "codice": "041035",
      "nome": "Monte Grimano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "F524",
      "cap": "61010",
      "prefisso": "0541",
      "provincia": "Pesaro e Urbino",
      "email": "comune.montegrimanoterme@provincia.ps.it",
      "pec": "comune.montegrimanoterme@emarche.it",
      "telefono": "+39 0541/970125",
      "fax": "+39 0541/970014",
      "coordinate": {
        "lat": 43.86542892456055,
        "lng": 12.473061561584473
      }
    },
    {
      "codice": "041038",
      "nome": "Monte Porzio",
      "nomeStraniero": null,
      "codiceCatastale": "F589",
      "cap": "61040",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.monte-porzio@provincia.ps.it",
      "pec": "servizidemografici.monteporzio@emarche.it",
      "telefono": "+39 0721/956000",
      "fax": "+39 0721/956027",
      "coordinate": {
        "lat": 43.68988800048828,
        "lng": 13.045361518859863
      }
    },
    {
      "codice": "041030",
      "nome": "Montecalvo in Foglia",
      "nomeStraniero": null,
      "codiceCatastale": "F450",
      "cap": "61020",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.montacalvo@provincia.ps.it",
      "pec": "comune.montecalvo@emarche.it",
      "telefono": "+39 0722/58113",
      "fax": "+39 0722/58315",
      "coordinate": {
        "lat": 43.81159973144531,
        "lng": 12.631638526916504
      }
    },
    {
      "codice": "041034",
      "nome": "Montefelcino",
      "nomeStraniero": null,
      "codiceCatastale": "F497",
      "cap": "61030",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.montefelcino@provincia.ps.it",
      "pec": "comunemontefelcino@pecitaly.it",
      "telefono": "+39 0721/743039",
      "fax": "+39 0721/729165",
      "coordinate": {
        "lat": 43.73445129394531,
        "lng": 12.833100318908691
      }
    },
    {
      "codice": "041036",
      "nome": "Montelabbate",
      "nomeStraniero": null,
      "codiceCatastale": "F533",
      "cap": "61025",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "e.cavalli@montelabbate.net",
      "pec": "comune@pec.montelabbate.net",
      "telefono": "+39 0721 473216",
      "fax": "+39 0721/473229",
      "coordinate": {
        "lat": 43.84893035888672,
        "lng": 12.790900230407715
      }
    },
    {
      "codice": "041041",
      "nome": "Peglio",
      "nomeStraniero": null,
      "codiceCatastale": "G416",
      "cap": "61049",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.peglio@provincia.ps.it",
      "pec": "comune.peglio@pec.regione.lombardia.it",
      "telefono": "+39 0722/310100",
      "fax": "+39 0722/310157",
      "coordinate": {
        "lat": 43.695987701416016,
        "lng": 12.496030807495117
      }
    },
    {
      "codice": "041043",
      "nome": "Pergola",
      "nomeStraniero": null,
      "codiceCatastale": "G453",
      "cap": "61045",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "anagrafe@comune.pergola.pu.it",
      "pec": "protocollo@pec.comune.pergola.pu.it",
      "telefono": "+39 0721/7373265",
      "fax": "+39 0721/736450",
      "coordinate": {
        "lat": 43.56425094604492,
        "lng": 12.834650039672852
      }
    },
    {
      "codice": "041044",
      "nome": "Pesaro",
      "nomeStraniero": null,
      "codiceCatastale": "G479",
      "cap": "61121",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "urp@comune.pesaro.ps.it",
      "pec": "comune.pesaro@emarche.it",
      "telefono": "+39 0721 387111",
      "fax": "+39 0721 31846",
      "coordinate": {
        "lat": 43.91014862060547,
        "lng": 12.913299560546875
      }
    },
    {
      "codice": "041045",
      "nome": "Petriano",
      "nomeStraniero": null,
      "codiceCatastale": "G514",
      "cap": "61020",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.petriano@provincia.ps.it",
      "pec": "comune.petriano@emarche.it",
      "telefono": "+39 0722/52130",
      "fax": "+39 0722/52901",
      "coordinate": {
        "lat": 43.78059005737305,
        "lng": 12.733550071716309
      }
    },
    {
      "codice": "041047",
      "nome": "Piandimeleto",
      "nomeStraniero": null,
      "codiceCatastale": "G551",
      "cap": "61026",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.piandimeleto@provincia.ps.it",
      "pec": "comune.piandimeleto@emarche.it",
      "telefono": "+39 0722/721121",
      "fax": "+39 0722/721835",
      "coordinate": {
        "lat": 43.72420120239258,
        "lng": 12.4127836227417
      }
    },
    {
      "codice": "041048",
      "nome": "Pietrarubbia",
      "nomeStraniero": null,
      "codiceCatastale": "G627",
      "cap": "61020",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.pietrarubbia@provincia.ps.it",
      "pec": "comune.pietrarubbia@emarche.it",
      "telefono": "+39 0722/75110",
      "fax": "+39 0722/75240",
      "coordinate": {
        "lat": 43.80434036254883,
        "lng": 12.377099990844727
      }
    },
    {
      "codice": "041049",
      "nome": "Piobbico",
      "nomeStraniero": null,
      "codiceCatastale": "G682",
      "cap": "61046",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.piobbico@provincia.ps.it",
      "pec": "comune.piobbico@emarche.it",
      "telefono": "+39 0722/986225",
      "fax": "+39 0722/986427",
      "coordinate": {
        "lat": 43.58797073364258,
        "lng": 12.50979995727539
      }
    },
    {
      "codice": "041051",
      "nome": "San Costanzo",
      "nomeStraniero": null,
      "codiceCatastale": "H809",
      "cap": "61039",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "protocollo@comune.san-costanzo.pu.it",
      "pec": "comune.sancostanzo@emarche.it",
      "telefono": "+39 0721/951211",
      "fax": "+39 0721/950056",
      "coordinate": {
        "lat": 43.7672004699707,
        "lng": 13.073060035705566
      }
    },
    {
      "codice": "041054",
      "nome": "San Lorenzo in Campo",
      "nomeStraniero": null,
      "codiceCatastale": "H958",
      "cap": "61047",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.san-lorenzo@provincia.ps.it",
      "pec": "comune.sanlorenzoincampo.areacontabile@pec.it",
      "telefono": "+39 0721/774211",
      "fax": "+39 0721/779987",
      "coordinate": {
        "lat": 43.60493087768555,
        "lng": 12.946200370788574
      }
    },
    {
      "codice": "041057",
      "nome": "Sant'Angelo in Vado",
      "nomeStraniero": null,
      "codiceCatastale": "I287",
      "cap": "61048",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.sangelo-vado@provincia.ps.it",
      "pec": "comune.santangeloinvado@emarche.it",
      "telefono": "+39 0722/819901",
      "fax": "+39 0722/819923",
      "coordinate": {
        "lat": 43.66471862792969,
        "lng": 12.413000106811523
      }
    },
    {
      "codice": "041058",
      "nome": "Sant'Ippolito",
      "nomeStraniero": null,
      "codiceCatastale": "I344",
      "cap": "61040",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.santippolito@provincia.ps.it",
      "pec": "comune.santippolito@emarche.it",
      "telefono": "+39 0721/728144",
      "fax": "+39 0721/728148",
      "coordinate": {
        "lat": 43.684776306152344,
        "lng": 12.870969772338867
      }
    },
    {
      "codice": "041071",
      "nome": "Sassocorvaro Auditore",
      "nomeStraniero": null,
      "codiceCatastale": "M413",
      "cap": "61028",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.sassocorvaroauditore@provincia.ps.it",
      "pec": "comune.sassocorvaroauditore@legalmail.it",
      "telefono": "+39 0722 769011",
      "fax": "+39 0722 769258",
      "coordinate": {
        "lat": 43.78013229370117,
        "lng": 12.49704647064209
      }
    },
    {
      "codice": "041061",
      "nome": "Serra Sant'Abbondio",
      "nomeStraniero": null,
      "codiceCatastale": "I654",
      "cap": "61040",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.serra@provincia.ps.it",
      "pec": "comune.serrasantabbondio@emarche.it",
      "telefono": "+39 0721/730120",
      "fax": "+39 0721/730230",
      "coordinate": {
        "lat": 43.49081802368164,
        "lng": 12.77061939239502
      }
    },
    {
      "codice": "041064",
      "nome": "Tavoleto",
      "nomeStraniero": null,
      "codiceCatastale": "L078",
      "cap": "61020",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.tavoleto@provincia.ps.it",
      "pec": "comune.tavoleto@emarche.it",
      "telefono": "+39 0722/629128",
      "fax": "+39 0722/629426",
      "coordinate": {
        "lat": 43.84587097167969,
        "lng": 12.592499732971191
      }
    },
    {
      "codice": "041065",
      "nome": "Tavullia",
      "nomeStraniero": null,
      "codiceCatastale": "L081",
      "cap": "61010",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.tavullia@provincia.ps.it",
      "pec": "comune@pec.comuneditavullia.it",
      "telefono": "+39 0721 4779",
      "fax": "+39 0721/476296",
      "coordinate": {
        "lat": 43.89799880981445,
        "lng": 12.753194808959961
      }
    },
    {
      "codice": "041070",
      "nome": "Terre Roveresche",
      "nomeStraniero": null,
      "codiceCatastale": "M379",
      "cap": "61038",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "comune.terreroveresche@provincia.ps.it",
      "pec": "comune.terreroveresche@pecitaly.it",
      "telefono": "+39 072197424",
      "fax": "+39 072197425",
      "coordinate": {
        "lat": 43.68886947631836,
        "lng": 12.965499877929688
      }
    },
    {
      "codice": "041066",
      "nome": "Urbania",
      "nomeStraniero": null,
      "codiceCatastale": "L498",
      "cap": "61049",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "comune.urbania@provincia.ps.it",
      "pec": "comune.urbania@emarche.it",
      "telefono": "+39 0722/313111",
      "fax": "+39 0722/317246",
      "coordinate": {
        "lat": 43.66777420043945,
        "lng": 12.522088050842285
      }
    },
    {
      "codice": "041067",
      "nome": "Urbino",
      "nomeStraniero": null,
      "codiceCatastale": "L500",
      "cap": "61029",
      "prefisso": "0722",
      "provincia": "Pesaro e Urbino",
      "email": "elettorale@comune.urbino.ps.it",
      "pec": "comune.urbino@emarche.it",
      "telefono": "+39 0722 3091",
      "fax": "+39 0722/309266",
      "coordinate": {
        "lat": 43.72523880004883,
        "lng": 12.637200355529785
      }
    },
    {
      "codice": "041068",
      "nome": "Vallefoglia",
      "nomeStraniero": null,
      "codiceCatastale": "M331",
      "cap": "61022",
      "prefisso": "0721",
      "provincia": "Pesaro e Urbino",
      "email": "servizidemografici@comune.vallefoglia.pu.it",
      "pec": "comune.vallefoglia@emarche.it",
      "telefono": "+39 0721 489711",
      "fax": "+39 0721 910247",
      "coordinate": {
        "lat": 43.82780075073242,
        "lng": 12.8016996383667
      }
    }
  ],
  "Cremona": [
    {
      "codice": "019001",
      "nome": "Acquanegra Cremonese",
      "nomeStraniero": null,
      "codiceCatastale": "A039",
      "cap": "26020",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "comune.acquanegra.cr@libero.it",
      "pec": "comune.acquanegracremonese@pec.regione.lombardia.it",
      "telefono": "+39 0372/70003",
      "fax": "+39 0372/729675",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "019002",
      "nome": "Agnadello",
      "nomeStraniero": null,
      "codiceCatastale": "A076",
      "cap": "26020",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.agnadello.cr.it",
      "pec": "anagrafe.comune.agnadello@pec.regione.lombardia.it",
      "telefono": "+39 0373/976192",
      "fax": "+39 0373/976283",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "019003",
      "nome": "Annicco",
      "nomeStraniero": null,
      "codiceCatastale": "A299",
      "cap": "26021",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comuneannicco.it",
      "pec": "comune.annicco@pec.regione.lombardia.it",
      "telefono": "+39 037479064",
      "fax": "+39 0374369047",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "019004",
      "nome": "Azzanello",
      "nomeStraniero": null,
      "codiceCatastale": "A526",
      "cap": "26010",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comune.azzanello.cr.it",
      "pec": "comune.azzanello@pec.regione.lombardia.it",
      "telefono": "+39 0374/66624",
      "fax": "+39 0374/66632",
      "coordinate": {
        "lat": 45.312870025634766,
        "lng": 9.921597480773926
      }
    },
    {
      "codice": "019005",
      "nome": "Bagnolo Cremasco",
      "nomeStraniero": null,
      "codiceCatastale": "A570",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.bagnolocremasco.cr.it",
      "pec": "comune.bagnolocremasco@mailcert.cremasconline.it",
      "telefono": "+39 0373/237811",
      "fax": "+39 0373/237812",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "019006",
      "nome": "Bonemerse",
      "nomeStraniero": null,
      "codiceCatastale": "A972",
      "cap": "26040",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@unionecenturiati.cr.it",
      "pec": "comune.bonemerse@pec.regione.lombardia.it",
      "telefono": "+39 0372/496020",
      "fax": "+39 0372/496157",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "019007",
      "nome": "Bordolano",
      "nomeStraniero": null,
      "codiceCatastale": "A986",
      "cap": "26020",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@comunebordolano.it",
      "pec": "bordolano@postemailcertificata.it",
      "telefono": "+39 0372/95926",
      "fax": "+39 0372/95736",
      "coordinate": {
        "lat": 45.293983459472656,
        "lng": 9.986709594726562
      }
    },
    {
      "codice": "019009",
      "nome": "Calvatone",
      "nomeStraniero": null,
      "codiceCatastale": "B439",
      "cap": "26030",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.calvatone.cr.it",
      "pec": "comune.calvatone@pec.regione.lombardia.it",
      "telefono": "+39 0375/97031",
      "fax": "+39 0375/97347",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 10.449999809265137
      }
    },
    {
      "codice": "019010",
      "nome": "Camisano",
      "nomeStraniero": null,
      "codiceCatastale": "B484",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "segreteria@comune.camisano.cr.it",
      "pec": "comune.camisano@pec.regione.lombardia.it",
      "telefono": "+39 0373/77212",
      "fax": "+39 0373/77181",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 9.75
      }
    },
    {
      "codice": "019011",
      "nome": "Campagnola Cremasca",
      "nomeStraniero": null,
      "codiceCatastale": "B498",
      "cap": "26013",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.campagnolacremasca.cr.it",
      "pec": "pec@pec.comune.campagnolacremasca.cr.it",
      "telefono": "+39 0373/74325",
      "fax": "+39 0373/74036",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.666666984558105
      }
    },
    {
      "codice": "019012",
      "nome": "Capergnanica",
      "nomeStraniero": null,
      "codiceCatastale": "B650",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.capergnanica.cr.it",
      "pec": "comune.capergnanica@pec.regione.lombardia.it",
      "telefono": "+39 0373/76021",
      "fax": "+39 0373/76212",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "019013",
      "nome": "Cappella Cantone",
      "nomeStraniero": null,
      "codiceCatastale": "B679",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comune.cappellacantone.cr.it",
      "pec": "contratti.comune.cappellacantone@pec.regione.lombardia.it",
      "telefono": "+39 0374/373154",
      "fax": "+39 0374/372001",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "019014",
      "nome": "Cappella de' Picenardi",
      "nomeStraniero": null,
      "codiceCatastale": "B680",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "servizidemografici@comune.cappelladepicenardi.cr.it",
      "pec": "comune.cappelladepicenardi@pec.regione.lombardia.it",
      "telefono": "+39 0372/835455",
      "fax": "+39 0372/835442",
      "coordinate": {
        "lat": 45.15944290161133,
        "lng": 10.231111526489258
      }
    },
    {
      "codice": "019015",
      "nome": "Capralba",
      "nomeStraniero": null,
      "codiceCatastale": "B686",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "segreteria@comune.capralba.cr.it",
      "pec": "pec@pec.comune.capralba.cr.it",
      "telefono": "+39 0373/452120",
      "fax": "+39 0373/450495",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "019016",
      "nome": "Casalbuttano ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "B869",
      "cap": "26011",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comune.casalbuttanoeduniti.cr.it",
      "pec": "egov.casalbuttano@cert.poliscomuneamico.net",
      "telefono": "+39 0374/364411",
      "fax": "+39 0374/364444",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "019017",
      "nome": "Casale Cremasco-Vidolasco",
      "nomeStraniero": null,
      "codiceCatastale": "B881",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "demografici@comune.casalecremascovidolasco.cr.it",
      "pec": "comune.casalecrvidolasco@pec.regione.lombardia.it",
      "telefono": "+39 0373 456711",
      "fax": "+39 0373 380229",
      "coordinate": {
        "lat": 45.431880950927734,
        "lng": 9.714159965515137
      }
    },
    {
      "codice": "019018",
      "nome": "Casaletto Ceredano",
      "nomeStraniero": null,
      "codiceCatastale": "B889",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.casalettoceredano.cr.it",
      "pec": "comune.casalettoceredano@pec.regione.lombardia.it",
      "telefono": "+39 0373/262305",
      "fax": "+39 0373/262112",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "019019",
      "nome": "Casaletto di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "B890",
      "cap": "26014",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "casaletto_di_sopra@liberot.it",
      "pec": "comune.casalettodisopra@pec.regione.lombardia.it",
      "telefono": "+39 0373/72277",
      "fax": "+39 0373/270224",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "019020",
      "nome": "Casaletto Vaprio",
      "nomeStraniero": null,
      "codiceCatastale": "B891",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "segreteria@comune.casalettovaprio.cr.it",
      "pec": "comune.casalettovaprio@mailcert.cremasconline.it",
      "telefono": "+39 0373/273100",
      "fax": "+39 0373/290286",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "019021",
      "nome": "Casalmaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "B898",
      "cap": "26041",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.casalmaggiore.cr.it",
      "pec": "protocollo.comune.casalmaggiore@pec.regione.lombardia.it",
      "telefono": "+39 0375/284460",
      "fax": "+39 0375/200251",
      "coordinate": {
        "lat": 44.98583221435547,
        "lng": 10.414722442626953
      }
    },
    {
      "codice": "019022",
      "nome": "Casalmorano",
      "nomeStraniero": null,
      "codiceCatastale": "B900",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "demografici@comune.casalmorano.cr.it",
      "pec": "comune.casalmorano@pec.regione.lombardia.it",
      "telefono": "+39 0374/374080",
      "fax": "+39 0374/374037",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "019024",
      "nome": "Castel Gabbiano",
      "nomeStraniero": null,
      "codiceCatastale": "C115",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "c.trobbiani@comune.castelgabbiano.cr.it",
      "pec": "comune.castelgabbiano@pec.regione.lombardia.it",
      "telefono": "+39 0373/258820",
      "fax": "+39 0373/751900",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "019023",
      "nome": "Casteldidone",
      "nomeStraniero": null,
      "codiceCatastale": "C089",
      "cap": "26030",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "demografici@comune.casteldidone.cr.it",
      "pec": "segretario.comune.casteldidone@pec.regione.lombardia.it",
      "telefono": "+39 0375/91102",
      "fax": "+39 0375/310222",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "019025",
      "nome": "Castelleone",
      "nomeStraniero": null,
      "codiceCatastale": "C153",
      "cap": "26012",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comune.castelleone.cr.it",
      "pec": "comune.castelleone@pec.regione.lombardia.it",
      "telefono": "+39 0374/356311",
      "fax": null,
      "coordinate": {
        "lat": 45.29579162597656,
        "lng": 9.760910034179688
      }
    },
    {
      "codice": "019026",
      "nome": "Castelverde",
      "nomeStraniero": null,
      "codiceCatastale": "B129",
      "cap": "26022",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "e.lodigiani@comune.castelverde.cr.it",
      "pec": "comune.castelverde@pec.regione.lombardia.it",
      "telefono": "+39 0372/42432",
      "fax": "+39 0372/428083",
      "coordinate": {
        "lat": 45.18877029418945,
        "lng": 9.995570182800293
      }
    },
    {
      "codice": "019027",
      "nome": "Castelvisconti",
      "nomeStraniero": null,
      "codiceCatastale": "C290",
      "cap": "26010",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "demografico@comune.castelvisconti.cr.it",
      "pec": "comune.castelvisconti@pec.regione.lombardia.it",
      "telefono": "+39 0374/66643",
      "fax": "+39 037466080",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "019028",
      "nome": "Cella Dati",
      "nomeStraniero": null,
      "codiceCatastale": "C435",
      "cap": "26040",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.celladati.it",
      "pec": "comune.celladati@pec.regione.lombardia.it",
      "telefono": "+39 0372/67821",
      "fax": "+39 0372/67159",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "019029",
      "nome": "Chieve",
      "nomeStraniero": null,
      "codiceCatastale": "C634",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "segreteria@comune.chieve.cr.it",
      "pec": "comune.chieve@mailcert.cremasconline.it",
      "telefono": "+39 0373/234323",
      "fax": "+39 0373/234086",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "019030",
      "nome": "Cicognolo",
      "nomeStraniero": null,
      "codiceCatastale": "C678",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "segreteria@comune.cicognolo.cr.it",
      "pec": "protocollo.comune.cicognolo@pec.regione.lombardia.it",
      "telefono": "+39 0372/835923",
      "fax": "+39 0372/835663",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 10.199999809265137
      }
    },
    {
      "codice": "019031",
      "nome": "Cingia de' Botti",
      "nomeStraniero": null,
      "codiceCatastale": "C703",
      "cap": "26042",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.cingiadebotti.cr.it",
      "pec": "anagrafe.comune.cingiadebotti@pec.regione.lombardia.it",
      "telefono": "+39 0375/96141",
      "fax": "+39 0375/96162",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "019032",
      "nome": "Corte de' Cortesi con Cignone",
      "nomeStraniero": null,
      "codiceCatastale": "D056",
      "cap": "26020",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "segreteria@comune.cortedecortesiconcignone.cr.it",
      "pec": "comune.cortedecortesiconcignone@pec.regione.lombardia.it",
      "telefono": "+39 0372 95107",
      "fax": "+39 0372 95155",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 10
      }
    },
    {
      "codice": "019033",
      "nome": "Corte de' Frati",
      "nomeStraniero": null,
      "codiceCatastale": "D057",
      "cap": "26010",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@comune.cortedefrati.cr.it",
      "pec": "comune.cortedefrati.cr@pec.it",
      "telefono": "+39 0372 93121",
      "fax": "+39 0372 93570",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "019034",
      "nome": "Credera Rubbiano",
      "nomeStraniero": null,
      "codiceCatastale": "D141",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.crederarubbiano.cr.it",
      "pec": "comune.crederarubbiano@pec.regione.lombardia.it",
      "telefono": "+39 0373/61913",
      "fax": "+39 0373/61060",
      "coordinate": {
        "lat": 45.303009033203125,
        "lng": 9.654979705810547
      }
    },
    {
      "codice": "019035",
      "nome": "Crema",
      "nomeStraniero": null,
      "codiceCatastale": "D142",
      "cap": "26013",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "e.bianchessi@comune.crema.cr.it",
      "pec": "protocollo@comunecrema.telecompost.it",
      "telefono": "+39 0373/894315",
      "fax": "+39 0373/894237",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 9.683333396911621
      }
    },
    {
      "codice": "019036",
      "nome": "Cremona",
      "nomeStraniero": null,
      "codiceCatastale": "D150",
      "cap": "26100",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "sindaco@comune.cremona.it",
      "pec": "protocollo@comunedicremona.legalmail.it",
      "telefono": "+39 0372 4071",
      "fax": "+39 0372/407260",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 10.033333778381348
      }
    },
    {
      "codice": "019037",
      "nome": "Cremosano",
      "nomeStraniero": null,
      "codiceCatastale": "D151",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.cremosano.cr.it",
      "pec": "comune.cremosano@mailcert.cremasconline.it",
      "telefono": "+39 0373/273053",
      "fax": "+39 0373/274687",
      "coordinate": {
        "lat": 45.39500045776367,
        "lng": 9.63888931274414
      }
    },
    {
      "codice": "019038",
      "nome": "Crotta d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "D186",
      "cap": "26020",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.crottadadda.cr.it",
      "pec": "comune.crottadadda@pec.regione.lombardia.it",
      "telefono": "+39 0372/722880",
      "fax": "+39 0372/722671",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "019039",
      "nome": "Cumignano sul Naviglio",
      "nomeStraniero": null,
      "codiceCatastale": "D203",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "comune.cumignano@libero.it",
      "pec": "pec@pec.comune.cumignano.cr.it",
      "telefono": "+39 0374/71133",
      "fax": "+39 0374/71203",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "019040",
      "nome": "Derovere",
      "nomeStraniero": null,
      "codiceCatastale": "D278",
      "cap": "26040",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comunederovere.it",
      "pec": "comune.derovere@pec.regione.lombardia.it",
      "telefono": "+39 0372/624883",
      "fax": "+39 0372/67164",
      "coordinate": {
        "lat": 45.11083221435547,
        "lng": 10.248888969421387
      }
    },
    {
      "codice": "019041",
      "nome": "Dovera",
      "nomeStraniero": null,
      "codiceCatastale": "D358",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "ragioneria@comune.dovera.cr.it",
      "pec": "demografico.comune.dovera@pec.regione.lombardia.it",
      "telefono": "+39 0373/978282",
      "fax": "+39 0373/978242",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "019043",
      "nome": "Fiesco",
      "nomeStraniero": null,
      "codiceCatastale": "D574",
      "cap": "26010",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "protocollo@comune.fiesco.cr.it",
      "pec": "comune.fiesco@pec.regione.lombardia.it",
      "telefono": "+39 0374/370014",
      "fax": "+39 0374/370862",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "019044",
      "nome": "Formigara",
      "nomeStraniero": null,
      "codiceCatastale": "D710",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "comune.formigara@libero.it",
      "pec": "comune.formigara@pec.regione.lombardia.it",
      "telefono": "+39 037478022",
      "fax": "+39 0374378515",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "019045",
      "nome": "Gabbioneta-Binanuova",
      "nomeStraniero": null,
      "codiceCatastale": "D834",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.gabbionetabinanuova.it",
      "pec": "comune.gabbioneta-binanuova@pec.regione.lombardia.it",
      "telefono": "+39 0372 844314",
      "fax": "+39 0372 844620",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "019046",
      "nome": "Gadesco-Pieve Delmona",
      "nomeStraniero": null,
      "codiceCatastale": "D841",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "segreteria@comunegadesco.it",
      "pec": "comune.gadescopd@legalmail.it",
      "telefono": "+39 0372 838463",
      "fax": "+39 0372 838464",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "019047",
      "nome": "Genivolta",
      "nomeStraniero": null,
      "codiceCatastale": "D966",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comune.genivolta.cr.it",
      "pec": "comune.genivolta@pec.regione.lombardia.it",
      "telefono": "+39 0374/68522",
      "fax": "+39 0374/68862",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "019048",
      "nome": "Gerre de' Caprioli",
      "nomeStraniero": null,
      "codiceCatastale": "D993",
      "cap": "26040",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@comune.gerredecaprioli.cr.it",
      "pec": "comune.gerredecaprioli@pec.regione.lombardia.it",
      "telefono": "+39 0372/452322",
      "fax": "+39 0372/450283",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "019049",
      "nome": "Gombito",
      "nomeStraniero": null,
      "codiceCatastale": "E082",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "demografici@comune.gombito.cr.it",
      "pec": "gombito@postemailcertificata.it",
      "telefono": "+39 0374/350535",
      "fax": "+39 0374/350413",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "019050",
      "nome": "Grontardo",
      "nomeStraniero": null,
      "codiceCatastale": "E193",
      "cap": "26044",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe.grontardo@libero.it",
      "pec": "comune.grontardo@pec.regione.lombardia.it",
      "telefono": "+39 0372/89760",
      "fax": "+39 0372/890007",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "019051",
      "nome": "Grumello Cremonese ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "E217",
      "cap": "26023",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "protocollo@grumellocremonese.it",
      "pec": "comunegrumello@pec.it",
      "telefono": "+39 0372/70148",
      "fax": "+39 0372/729548",
      "coordinate": {
        "lat": 45.19413757324219,
        "lng": 9.866334915161133
      }
    },
    {
      "codice": "019052",
      "nome": "Gussola",
      "nomeStraniero": null,
      "codiceCatastale": "E272",
      "cap": "26040",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "comune.gussola@unh.net",
      "pec": "comune.gussola@pec.regione.lombardia.it",
      "telefono": "+39 0375/263311",
      "fax": "+39 0375/260923",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "019053",
      "nome": "Isola Dovarese",
      "nomeStraniero": null,
      "codiceCatastale": "E356",
      "cap": "26031",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.isoladovarese.cr.it",
      "pec": "comune.isoladovarese@pec.regione.lombardia.it",
      "telefono": "+39 0375/946042",
      "fax": "+39 0375/946354",
      "coordinate": {
        "lat": 45.17583465576172,
        "lng": 10.312222480773926
      }
    },
    {
      "codice": "019054",
      "nome": "Izano",
      "nomeStraniero": null,
      "codiceCatastale": "E380",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.izano.cr.it",
      "pec": "comune.izano@pec.regione.lombardia.it",
      "telefono": "+39 0373/244100",
      "fax": "+39 0373/244580",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.75
      }
    },
    {
      "codice": "019055",
      "nome": "Madignano",
      "nomeStraniero": null,
      "codiceCatastale": "E793",
      "cap": "26020",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.madignano.cr.it",
      "pec": "pec@pec.comune.madignano.cr.it",
      "telefono": "+39 0373/65139",
      "fax": "+39 0373/65632",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.716666221618652
      }
    },
    {
      "codice": "019056",
      "nome": "Malagnino",
      "nomeStraniero": null,
      "codiceCatastale": "E843",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "demografici.malagnino@unionecenturiati.cr.it",
      "pec": "comune.malagnino@pec.regione.lombardia.it",
      "telefono": "037258047",
      "fax": "+39 0372/58358",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "019057",
      "nome": "Martignana di Po",
      "nomeStraniero": null,
      "codiceCatastale": "E983",
      "cap": "26040",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "demografici@comune.martignanadipo.cr.it",
      "pec": "comune.martignanadipo@pec.regione.lombardia.it",
      "telefono": "+39 0375/260062",
      "fax": "+39 0375/260015",
      "coordinate": {
        "lat": 45,
        "lng": 10.366666793823242
      }
    },
    {
      "codice": "019058",
      "nome": "Monte Cremasco",
      "nomeStraniero": null,
      "codiceCatastale": "F434",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.montecremasco.cr.it",
      "pec": "segreteria.comune.montecremasco@pec.regione.lombardia.it",
      "telefono": "+39 0373/791121",
      "fax": "+39 0373/791635",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "019059",
      "nome": "Montodine",
      "nomeStraniero": null,
      "codiceCatastale": "F681",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.montodine.cr.it",
      "pec": "comune.montodine@pec.regione.lombardia.it",
      "telefono": "+39 0373/66104",
      "fax": null,
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9.716666221618652
      }
    },
    {
      "codice": "019060",
      "nome": "Moscazzano",
      "nomeStraniero": null,
      "codiceCatastale": "F761",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.moscazzano.cr.it",
      "pec": "comune.moscazzano@pec.regione.lombardia.it",
      "telefono": "+39 0373/66121",
      "fax": "+39 0373/668077",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9.683333396911621
      }
    },
    {
      "codice": "019061",
      "nome": "Motta Baluffi",
      "nomeStraniero": null,
      "codiceCatastale": "F771",
      "cap": "26045",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe.motta@unionemunicipia.it",
      "pec": "comune.mottabaluffi@pec.regione.lombardia.it",
      "telefono": "+39 0375/969021",
      "fax": "+39 0375/969347",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 10.266666412353516
      }
    },
    {
      "codice": "019062",
      "nome": "Offanengo",
      "nomeStraniero": null,
      "codiceCatastale": "G004",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.offanengo.cr.it",
      "pec": "comune.offanengo@cert.legalmail.it",
      "telefono": "+39 0373 24731",
      "fax": "+39 0373/244571",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "019063",
      "nome": "Olmeneta",
      "nomeStraniero": null,
      "codiceCatastale": "G047",
      "cap": "26010",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@comune.olmeneta.cr.it",
      "pec": "comune.olmeneta.cr@pec.it",
      "telefono": "+39 0372/924121",
      "fax": "+39 0372/924349",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "019064",
      "nome": "Ostiano",
      "nomeStraniero": null,
      "codiceCatastale": "G185",
      "cap": "26032",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "segreteria@comuneostiano.it",
      "pec": "comune.ostiano@pec.regione.lombardia.it",
      "telefono": "+39 0372/856911",
      "fax": "+39 0372/840049",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.25
      }
    },
    {
      "codice": "019065",
      "nome": "Paderno Ponchielli",
      "nomeStraniero": null,
      "codiceCatastale": "G222",
      "cap": "26024",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "info@comunedipadernoponchielli.gov.it",
      "pec": "comune.padernoponchielli@pec.regione.lombardia.it",
      "telefono": "+39 0374/367200",
      "fax": "+39 0374/367147",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "019066",
      "nome": "Palazzo Pignano",
      "nomeStraniero": null,
      "codiceCatastale": "G260",
      "cap": "26020",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.palazzopignano.cr.it",
      "pec": "comune.palazzopignano@mailcert.cremasconline.it",
      "telefono": "+39 0373/975513",
      "fax": "+39 0373/975599",
      "coordinate": {
        "lat": 45.389610290527344,
        "lng": 9.572696685791016
      }
    },
    {
      "codice": "019067",
      "nome": "Pandino",
      "nomeStraniero": null,
      "codiceCatastale": "G306",
      "cap": "26025",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "segreteria@comune.pandino.cr.it",
      "pec": "protocollo.comune.pandino@pec.it",
      "telefono": "+39 0373 9731",
      "fax": "+39 0373/970056",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "019068",
      "nome": "Persico Dosimo",
      "nomeStraniero": null,
      "codiceCatastale": "G469",
      "cap": "26043",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "ced@comune.persicodosimo.cr.it",
      "pec": "comune.persicodosimo@pec.regione.lombardia.it",
      "telefono": "+39 0372/539236",
      "fax": "+39 0372/539234",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "019069",
      "nome": "Pescarolo ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "G483",
      "cap": "26033",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "protocollo@comune.pescaroloeduniti.cr.it",
      "pec": "comune.pescaroloeduniti@pec.regione.lombardia.it",
      "telefono": "+39 0372/836012",
      "fax": "+39 0372/836343",
      "coordinate": {
        "lat": 45.194950103759766,
        "lng": 10.186629295349121
      }
    },
    {
      "codice": "019070",
      "nome": "Pessina Cremonese",
      "nomeStraniero": null,
      "codiceCatastale": "G504",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "ragioneria@comune.pessinacremonese.cr.it",
      "pec": "comune.pessinacremonese@pec.regione.lombardia.it",
      "telefono": "+39 0372/87922",
      "fax": "+39 0372/87928",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 10.25
      }
    },
    {
      "codice": "019116",
      "nome": "Piadena Drizzona",
      "nomeStraniero": null,
      "codiceCatastale": "M418",
      "cap": "26034",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": null,
      "pec": "comunepiadenadrizzona@pec.it",
      "telefono": "+39 0375 98125",
      "fax": null,
      "coordinate": {
        "lat": 45.1363410949707,
        "lng": 10.360514640808105
      }
    },
    {
      "codice": "019072",
      "nome": "Pianengo",
      "nomeStraniero": null,
      "codiceCatastale": "G558",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.pianengo.cr.it",
      "pec": "comune.pianengo@mailcert.cremasconline.it",
      "telefono": "+39 0373/752211",
      "fax": "+39 0373/74315",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "019073",
      "nome": "Pieranica",
      "nomeStraniero": null,
      "codiceCatastale": "G603",
      "cap": "26017",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.pieranica.cr.it",
      "pec": "anagrafe.comune.pieranica@pec.regione.lombardia.it",
      "telefono": "+39 0373/71016",
      "fax": "+39 0373/71596",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "019074",
      "nome": "Pieve d'Olmi",
      "nomeStraniero": null,
      "codiceCatastale": "G647",
      "cap": "26040",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "pdodem@dinet.it",
      "pec": "comune.pievedolmi@pec.regione.lombardia.it",
      "telefono": "+39 0372/626131",
      "fax": "+39 0372/626349",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "019075",
      "nome": "Pieve San Giacomo",
      "nomeStraniero": null,
      "codiceCatastale": "G651",
      "cap": "26035",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@comune.pievesangiacomo.cr.it",
      "pec": "comune.pievesangiacomo@pec.regione.lombardia.it",
      "telefono": "037264331",
      "fax": "037264613",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "019076",
      "nome": "Pizzighettone",
      "nomeStraniero": null,
      "codiceCatastale": "G721",
      "cap": "26026",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.pizzighettone.cr.it",
      "pec": "anagrafe.comune.pizzighettone@pec.regione.lombardia.it",
      "telefono": "+39 037273822052",
      "fax": "+39 0372/745209",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "019077",
      "nome": "Pozzaglio ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "B914",
      "cap": "26010",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "info@comune.pozzaglio.cr.it",
      "pec": "comune.pozzaglio@pec.regione.lombardia.it",
      "telefono": "+39 0372/55125",
      "fax": "+39 0372/55266",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "019078",
      "nome": "Quintano",
      "nomeStraniero": null,
      "codiceCatastale": "H130",
      "cap": "26017",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.quintano.cr.it",
      "pec": "comune.quintano@pec.it",
      "telefono": "+39 0373/71013",
      "fax": "+39 0373/71039",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "019079",
      "nome": "Ricengo",
      "nomeStraniero": null,
      "codiceCatastale": "H276",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.ricengo.cr.it",
      "pec": "comune.ricengo@pec.regione.lombardia.it",
      "telefono": "+39 0373 267708",
      "fax": "+39 0373 267780",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "019080",
      "nome": "Ripalta Arpina",
      "nomeStraniero": null,
      "codiceCatastale": "H314",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "info@comune.ripaltaarpina.cr.it",
      "pec": "comune.ripaltaarpina@pec.regione.lombardia.it",
      "telefono": "+39 0373/242544",
      "fax": "+39 0373/668000",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "019081",
      "nome": "Ripalta Cremasca",
      "nomeStraniero": null,
      "codiceCatastale": "H315",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "demografici@comune.ripaltacremasca.cr.it",
      "pec": "comune.ripaltacremasca@pec.it",
      "telefono": "+39 0373/68131",
      "fax": "+39 0373/268050",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "019082",
      "nome": "Ripalta Guerina",
      "nomeStraniero": null,
      "codiceCatastale": "H316",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.ripaltaguerina.cr.it",
      "pec": "anagrafe.comune.ripaltaguerina@pec.regione.lombardia.it",
      "telefono": "+39 0373/66138",
      "fax": "+39 0373/242104",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "019083",
      "nome": "Rivarolo del Re ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "H341",
      "cap": "26036",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "demografici@comune.rivarolodelreeduniti.cr.it",
      "pec": "comune.rivarolodelre@pec.regione.lombardia.it",
      "telefono": "+39 0375/534032",
      "fax": "+39 0375/534061",
      "coordinate": {
        "lat": 45.031158447265625,
        "lng": 10.472530364990234
      }
    },
    {
      "codice": "019084",
      "nome": "Rivolta d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "H357",
      "cap": "26027",
      "prefisso": "0363",
      "provincia": "Cremona",
      "email": "andrea.deponti@comune.rivoltadadda.cr.it",
      "pec": "comune.rivoltadadda@mailcert.cremasconline.it",
      "telefono": "+39 0363/377029",
      "fax": "+39 0363/377031",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "019085",
      "nome": "Robecco d'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "H372",
      "cap": "26010",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.robeccodoglio.cr.it",
      "pec": "comune.robeccodoglio@pec.regione.lombardia.it",
      "telefono": "+39 0372/981011",
      "fax": "+39 0372/921765",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "019086",
      "nome": "Romanengo",
      "nomeStraniero": null,
      "codiceCatastale": "H508",
      "cap": "26014",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "protocollo@comune.romanengo.cr.it",
      "pec": "comune.romanengo@pec.regione.lombardia.it",
      "telefono": "+39 0373/72117",
      "fax": "+39 0373/72358",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "019087",
      "nome": "Salvirola",
      "nomeStraniero": null,
      "codiceCatastale": "H731",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "segreteria@comune.salvirola.cr.it",
      "pec": "anagrafe.comune.salvirola@pec.it",
      "telefono": "+39 0373 72146",
      "fax": "+39 0373 270171",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "019088",
      "nome": "San Bassano",
      "nomeStraniero": null,
      "codiceCatastale": "H767",
      "cap": "26020",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "segreteria@comune.sanbassano.cr.it",
      "pec": "info@pec.comune.sanbassano.cr.it",
      "telefono": "+39 0374373163",
      "fax": "+39 0374373234",
      "coordinate": {
        "lat": 45.24388885498047,
        "lng": 9.80888843536377
      }
    },
    {
      "codice": "019089",
      "nome": "San Daniele Po",
      "nomeStraniero": null,
      "codiceCatastale": "H815",
      "cap": "26046",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "demografici@comune.sandanielepo.cr.it",
      "pec": "comune@comune.sandanielepo.cr.it",
      "telefono": "+39 0372/65536",
      "fax": "+39 0372/65079",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "019090",
      "nome": "San Giovanni in Croce",
      "nomeStraniero": null,
      "codiceCatastale": "H918",
      "cap": "26037",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "protocollo@comune.sangiovanniincroce.cr.it",
      "pec": "comune.sangiovannincroce@pec.regione.lombardia.it",
      "telefono": "+39 0375/91001",
      "fax": "+39 0375311016",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.366666793823242
      }
    },
    {
      "codice": "019091",
      "nome": "San Martino del Lago",
      "nomeStraniero": null,
      "codiceCatastale": "I007",
      "cap": "26040",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.sanmartinodellago.cr.it",
      "pec": "comune.sanmartinodellago@pec.regione.lombardia.it",
      "telefono": "+39 0375/95144",
      "fax": "+39 0375/350194",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "019092",
      "nome": "Scandolara Ravara",
      "nomeStraniero": null,
      "codiceCatastale": "I497",
      "cap": "26040",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe.scandolara@unionemunicipia.it",
      "pec": "comune.scandolara-ravara@pec.regione.lombardia.it",
      "telefono": "+39 0375/95101",
      "fax": "+39 0375/95511",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "019093",
      "nome": "Scandolara Ripa d'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "I498",
      "cap": "26047",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.scandolararipadoglio.cr.it",
      "pec": "comune.scandolararipadoglio@pec.regione.lombardia.it",
      "telefono": "+39 0372 89142",
      "fax": "+39 0372 89842",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "019094",
      "nome": "Sergnano",
      "nomeStraniero": null,
      "codiceCatastale": "I627",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "servizio.demografico@comune.sergnano.cr.it",
      "pec": "comune.sergnano@pec.regione.lombardia.it",
      "telefono": "+39 0373/456612",
      "fax": "+39 0373/41814",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "019095",
      "nome": "Sesto ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "I683",
      "cap": "26028",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "comune@comune.sestoeduniti.cr.it",
      "pec": "comune.sestoeduniti@pec.regione.lombardia.it",
      "telefono": "+39 0372/76043",
      "fax": "+39 0372/76560",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 9.916666984558105
      }
    },
    {
      "codice": "019096",
      "nome": "Solarolo Rainerio",
      "nomeStraniero": null,
      "codiceCatastale": "I790",
      "cap": "26030",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.solarolorainerio.cr.it",
      "pec": "comune.solarolorainerio@pec.regione.lombardia.it",
      "telefono": "+39 0375/91017",
      "fax": "+39 0375/310164",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "019097",
      "nome": "Soncino",
      "nomeStraniero": null,
      "codiceCatastale": "I827",
      "cap": "26029",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "anagrafe@comune.soncino.cr.it",
      "pec": "soncino@postemailcertificata.it",
      "telefono": "+39 0374/837833",
      "fax": "+39 0374/837860",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "019098",
      "nome": "Soresina",
      "nomeStraniero": null,
      "codiceCatastale": "I849",
      "cap": "26015",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "protocollo@comune.soresina.cr.it",
      "pec": "comune.soresina@pec.regione.lombardia.it",
      "telefono": "+39 0374/349411",
      "fax": "+39 0374/340448",
      "coordinate": {
        "lat": 45.28647232055664,
        "lng": 9.856965065002441
      }
    },
    {
      "codice": "019099",
      "nome": "Sospiro",
      "nomeStraniero": null,
      "codiceCatastale": "I865",
      "cap": "26048",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "segreteria@comune.sospiro.cr.it",
      "pec": "comune.sospiro@pec.regione.lombardia.it",
      "telefono": "+39 0372/623101",
      "fax": "+39 0372/621275",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "019100",
      "nome": "Spinadesco",
      "nomeStraniero": null,
      "codiceCatastale": "I906",
      "cap": "26020",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.spinadesco.cr.it",
      "pec": "comune.spinadesco@pec.regione.lombardia.it",
      "telefono": "+39 0372/491925",
      "fax": "+39 0372/491759",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "019101",
      "nome": "Spineda",
      "nomeStraniero": null,
      "codiceCatastale": "I909",
      "cap": "26030",
      "prefisso": "0376",
      "provincia": "Cremona",
      "email": "demografici@comune.spineda.cr.it",
      "pec": "comune.spineda@pec.regione.lombardia.it",
      "telefono": "+39 0376/93071",
      "fax": "+39 0376/93369",
      "coordinate": {
        "lat": 45.0614013671875,
        "lng": 10.511650085449219
      }
    },
    {
      "codice": "019102",
      "nome": "Spino d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "I914",
      "cap": "26016",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "ufficiotecnico@comune.spinodadda.cr.it",
      "pec": "comune.spinodadda@pec.regione.lombardia.it",
      "telefono": "+39 0373/988135",
      "fax": "+39 0373/980634",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.5
      }
    },
    {
      "codice": "019103",
      "nome": "Stagno Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "I935",
      "cap": "26049",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.stagnolombardo.cr.it",
      "pec": "demografici@pec.comune.stagnolombardo.cr.it",
      "telefono": "+39 0372/57032",
      "fax": "+39 0372/57232",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "019104",
      "nome": "Ticengo",
      "nomeStraniero": null,
      "codiceCatastale": "L164",
      "cap": "26010",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "segreteria@comune.ticengo.cr.it",
      "pec": "segreteria.comune.ticengo@pec.regione.lombardia.it",
      "telefono": "+39 0374/71118",
      "fax": "+39 0374/71128",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "019105",
      "nome": "Torlino Vimercati",
      "nomeStraniero": null,
      "codiceCatastale": "L221",
      "cap": "26017",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "torlino@comune.torlinovimercati.cr.it",
      "pec": "pec@pec.comune.torlinovimercati.cr.it",
      "telefono": "+39 0373/288886",
      "fax": "+39 0373/718084",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "019106",
      "nome": "Tornata",
      "nomeStraniero": null,
      "codiceCatastale": "L225",
      "cap": "26030",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "comuneditornata@tin.it",
      "pec": "comune.tornata@pec.regione.lombardia.it",
      "telefono": "+39 0375/97051",
      "fax": "+39 0375/977056",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "019107",
      "nome": "Torre de' Picenardi",
      "nomeStraniero": null,
      "codiceCatastale": "L258",
      "cap": "26038",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "info@comune.torredepicenardi.cr.it",
      "pec": "comune.torredepicenardi@pec.regione.lombardia.it",
      "telefono": "+39 0375/94102",
      "fax": "+39 0375/945064",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "019108",
      "nome": "Torricella del Pizzo",
      "nomeStraniero": null,
      "codiceCatastale": "L296",
      "cap": "26040",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "uffici.comunali@comune.torricella.cr.it",
      "pec": "comune.torricelladelpizzo@pec.regione.lombardia.it",
      "telefono": "+39 0375/99821",
      "fax": "+39 0375/99839",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 10.300000190734863
      }
    },
    {
      "codice": "019109",
      "nome": "Trescore Cremasco",
      "nomeStraniero": null,
      "codiceCatastale": "L389",
      "cap": "26017",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.trescorecremasco.cr.it",
      "pec": "protocollo@pec.comune.trescorecremasco.cr.it",
      "telefono": "+39 0373/272239",
      "fax": "+39 0373/272245",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "019110",
      "nome": "Trigolo",
      "nomeStraniero": null,
      "codiceCatastale": "L426",
      "cap": "26018",
      "prefisso": "0374",
      "provincia": "Cremona",
      "email": "protocollo@comune.trigolo.cr.it",
      "pec": "comune.trigolo@pec.regione.lombardia.it",
      "telefono": "+39 0374/370122",
      "fax": "+39 0374/370537",
      "coordinate": {
        "lat": 45.32740020751953,
        "lng": 9.817299842834473
      }
    },
    {
      "codice": "019111",
      "nome": "Vaiano Cremasco",
      "nomeStraniero": null,
      "codiceCatastale": "L535",
      "cap": "26010",
      "prefisso": "0373",
      "provincia": "Cremona",
      "email": "anagrafe@comune.vaianocremasco.cr.it",
      "pec": "comune.vaiano@mailcert.cremasconline.it",
      "telefono": "+39 0373/278015",
      "fax": "+39 0373/278041",
      "coordinate": {
        "lat": 45.37305450439453,
        "lng": 9.588333129882812
      }
    },
    {
      "codice": "019112",
      "nome": "Vailate",
      "nomeStraniero": null,
      "codiceCatastale": "L539",
      "cap": "26019",
      "prefisso": "0363",
      "provincia": "Cremona",
      "email": "anagrafe@comune.vailate.cr.it",
      "pec": "anagrafe.comune.vailate@pec.regione.lombardia.it",
      "telefono": "+39 0363/849108",
      "fax": "+39 0363/84731",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "019113",
      "nome": "Vescovato",
      "nomeStraniero": null,
      "codiceCatastale": "L806",
      "cap": "26039",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comunedivescovato.191.it",
      "pec": "comune.vescovato@pec.regione.lombardia.it",
      "telefono": "+39 0372/830492",
      "fax": "+39 0372/830038",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 10.166666984558105
      }
    },
    {
      "codice": "019114",
      "nome": "Volongo",
      "nomeStraniero": null,
      "codiceCatastale": "M116",
      "cap": "26030",
      "prefisso": "0372",
      "provincia": "Cremona",
      "email": "anagrafe@comune.volongo.cr.it",
      "pec": "volongo@postemailcertificata.it",
      "telefono": "+39 0372/845914",
      "fax": "+39 0372/845756",
      "coordinate": {
        "lat": 45.21224594116211,
        "lng": 10.301874160766602
      }
    },
    {
      "codice": "019115",
      "nome": "Voltido",
      "nomeStraniero": null,
      "codiceCatastale": "M127",
      "cap": "26030",
      "prefisso": "0375",
      "provincia": "Cremona",
      "email": "anagrafe@comune.voltido.cr.it",
      "pec": "comune.voltido@pec.regione.lombardia.it",
      "telefono": "+39 0375/389762",
      "fax": "+39 0375/389782",
      "coordinate": {
        "lat": 45.11227035522461,
        "lng": 10.334380149841309
      }
    }
  ],
  "Mantova": [
    {
      "codice": "020001",
      "nome": "Acquanegra sul Chiese",
      "nomeStraniero": null,
      "codiceCatastale": "A038",
      "cap": "46011",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "protocollo@comune.acquanegra.mn.it",
      "pec": "acquanegrasulchiese.mn@legalmail.it",
      "telefono": "+39 037679101",
      "fax": "+39 0376790049",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "020002",
      "nome": "Asola",
      "nomeStraniero": null,
      "codiceCatastale": "A470",
      "cap": "46041",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "email@comune.asola.mn.it",
      "pec": "comuneasola@legalmail.it",
      "telefono": "+39 0376733011",
      "fax": "+39 0376710415",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.416666984558105
      }
    },
    {
      "codice": "020003",
      "nome": "Bagnolo San Vito",
      "nomeStraniero": null,
      "codiceCatastale": "A575",
      "cap": "46031",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.bagnolosanvito.mn.it",
      "pec": "bagnolosanvito.mn@legalmail.it",
      "telefono": "+39 0376253100",
      "fax": "+39 0376415387",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 10.883333206176758
      }
    },
    {
      "codice": "020072",
      "nome": "Borgo Mantovano",
      "nomeStraniero": null,
      "codiceCatastale": "M396",
      "cap": "46036",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "protocollo@comune.borgomantovano.mn.it",
      "pec": "protocollo@pec.comune.borgomantovano.mn.it",
      "telefono": "038646001",
      "fax": "0386467274",
      "coordinate": {
        "lat": 45.057491302490234,
        "lng": 11.125470161437988
      }
    },
    {
      "codice": "020071",
      "nome": "Borgo Virgilio",
      "nomeStraniero": null,
      "codiceCatastale": "M340",
      "cap": "46034",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "affarigen@comune.borgovirgilio.mn.it",
      "pec": "protocollo@pec.comune.borgovirgilio.mn.it",
      "telefono": "+39 0376 283011",
      "fax": "+39 0376 280368",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 10.75
      }
    },
    {
      "codice": "020073",
      "nome": "Borgocarbonara",
      "nomeStraniero": null,
      "codiceCatastale": "M406",
      "cap": "46021",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": null,
      "pec": "protocollo@pec.comune.borgocarbonara.mn.it",
      "telefono": "0368/41549",
      "fax": "386/41694",
      "coordinate": {
        "lat": 45.038055419921875,
        "lng": 11.223472595214844
      }
    },
    {
      "codice": "020007",
      "nome": "Bozzolo",
      "nomeStraniero": null,
      "codiceCatastale": "B110",
      "cap": "46012",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.bozzolo.mn.it",
      "pec": "comune.bozzolo@pec.regione.lombardia.it",
      "telefono": "+39 0376910826",
      "fax": "+39 037691105",
      "coordinate": {
        "lat": 45.102474212646484,
        "lng": 10.48398208618164
      }
    },
    {
      "codice": "020008",
      "nome": "Canneto sull'Oglio",
      "nomeStraniero": null,
      "codiceCatastale": "B612",
      "cap": "46013",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "protocollo@comune.canneto.mn.it",
      "pec": "cannetosulloglio.mn@legalmail.it",
      "telefono": "+39 037671700",
      "fax": "+39 0376724010",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 10.383333206176758
      }
    },
    {
      "codice": "020010",
      "nome": "Casalmoro",
      "nomeStraniero": null,
      "codiceCatastale": "B901",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "comunedicasalmoro@tin.it",
      "pec": "casalmoro.mn@legalmail.it",
      "telefono": "+39 0376736312",
      "fax": "+39 0376737485",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "020011",
      "nome": "Casaloldo",
      "nomeStraniero": null,
      "codiceCatastale": "B907",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "info@comune.casaloldo.mn.it",
      "pec": "comune.casaloldo@actaliscertymail.it",
      "telefono": "+39 037673211",
      "fax": "+39 0376748839",
      "coordinate": {
        "lat": 45.25,
        "lng": 10.483333587646484
      }
    },
    {
      "codice": "020012",
      "nome": "Casalromano",
      "nomeStraniero": null,
      "codiceCatastale": "B911",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segretario@comune.casalromano.mn.it",
      "pec": "casalromano.mn@legalmail.it",
      "telefono": "+39 0376/714311",
      "fax": "+39 0376/76460",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 10.366666793823242
      }
    },
    {
      "codice": "020014",
      "nome": "Castel d'Ario",
      "nomeStraniero": null,
      "codiceCatastale": "C076",
      "cap": "46033",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "demografici@comune.casteldario.mn.it",
      "pec": "comune.casteldario@pec.regione.lombardia.it",
      "telefono": "+39 0376660140",
      "fax": "+39 0376/661036",
      "coordinate": {
        "lat": 45.18888854980469,
        "lng": 10.975555419921875
      }
    },
    {
      "codice": "020015",
      "nome": "Castel Goffredo",
      "nomeStraniero": null,
      "codiceCatastale": "C118",
      "cap": "46042",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "giusy.mastroianni@comune.castelgoffredo.mn.it",
      "pec": "comunedicastelgoffredo.mn@legalmail.it",
      "telefono": "0376 7771",
      "fax": null,
      "coordinate": {
        "lat": 45.29805374145508,
        "lng": 10.475000381469727
      }
    },
    {
      "codice": "020013",
      "nome": "Castelbelforte",
      "nomeStraniero": null,
      "codiceCatastale": "C059",
      "cap": "46032",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "info@comunecastelbelforte.it",
      "pec": "protocollo.comune.castelbelforte@pec.regione.lombardia.it",
      "telefono": "+39 0376259211",
      "fax": "+39 0376258123",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.883333206176758
      }
    },
    {
      "codice": "020016",
      "nome": "Castellucchio",
      "nomeStraniero": null,
      "codiceCatastale": "C195",
      "cap": "46014",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "ufficio.segreteria@comunecastellucchio.it",
      "pec": "comune.castellucchio@pec.regione.lombardia.it",
      "telefono": "+39 03764343.217",
      "fax": "+39 03764343.225",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 10.649999618530273
      }
    },
    {
      "codice": "020017",
      "nome": "Castiglione delle Stiviere",
      "nomeStraniero": null,
      "codiceCatastale": "C312",
      "cap": "46043",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.castiglione.mn.it",
      "pec": "comune.castiglionedellestiviere@pec.regione.lombardia.it",
      "telefono": "+39 0376679257",
      "fax": "+39 0376670466",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 10.5
      }
    },
    {
      "codice": "020018",
      "nome": "Cavriana",
      "nomeStraniero": null,
      "codiceCatastale": "C406",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "comune@comune.cavriana.mn.it",
      "pec": "cavriana.mn@legalmail.it",
      "telefono": "+39 0376 811411",
      "fax": "+39 0376 82028",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "020019",
      "nome": "Ceresara",
      "nomeStraniero": null,
      "codiceCatastale": "C502",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.ceresara.mn.it",
      "pec": "ceresara.mn@legalmail.it",
      "telefono": "+39 0376814011",
      "fax": "+39 0376814029",
      "coordinate": {
        "lat": 45.26227951049805,
        "lng": 10.569580078125
      }
    },
    {
      "codice": "020020",
      "nome": "Commessaggio",
      "nomeStraniero": null,
      "codiceCatastale": "C930",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.commessaggio.mn.it",
      "pec": "comune.commessaggio@pec.regione.lombardia.it",
      "telefono": "+39 037698121",
      "fax": "+39 037698475",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 10.550000190734863
      }
    },
    {
      "codice": "020021",
      "nome": "Curtatone",
      "nomeStraniero": null,
      "codiceCatastale": "D227",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@curtatone.it",
      "pec": "comune.curtatone@legalmail.it",
      "telefono": "+39 0376/ 358112",
      "fax": "+39 0376 358169",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "020022",
      "nome": "Dosolo",
      "nomeStraniero": null,
      "codiceCatastale": "D351",
      "cap": "46030",
      "prefisso": "0375",
      "provincia": "Mantova",
      "email": "segreteria@comune.dosolo.mn.it",
      "pec": "segreteria.comune.dosolo@pec.regione.lombardia.it",
      "telefono": "+39 037589573 int.104",
      "fax": "+39 0375899027",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "020024",
      "nome": "Gazoldo degli Ippoliti",
      "nomeStraniero": null,
      "codiceCatastale": "D949",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.gazoldo.mn.it",
      "pec": "comune.gazoldoippoliti@pec.regione.lombardia.it",
      "telefono": "+39 0376 657141",
      "fax": "+39 0376 657488",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 10.583333015441895
      }
    },
    {
      "codice": "020025",
      "nome": "Gazzuolo",
      "nomeStraniero": null,
      "codiceCatastale": "D959",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.gazzuolo.mn.it",
      "pec": "comune.gazzuolo@pec.regione.lombardia.it",
      "telefono": "+39 0376924920",
      "fax": "+39 0376 97381",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 10.583333015441895
      }
    },
    {
      "codice": "020026",
      "nome": "Goito",
      "nomeStraniero": null,
      "codiceCatastale": "E078",
      "cap": "46044",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "info@comune.goito.mn.it",
      "pec": "comune.goito@pec.regione.lombardia.it",
      "telefono": "+39 0376/683311",
      "fax": "+39 0376/689014",
      "coordinate": {
        "lat": 45.25,
        "lng": 10.666666984558105
      }
    },
    {
      "codice": "020027",
      "nome": "Gonzaga",
      "nomeStraniero": null,
      "codiceCatastale": "E089",
      "cap": "46023",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "info@comune.gonzaga.mn.it",
      "pec": "gonzaga.mn@legalmail.it",
      "telefono": "0376526311",
      "fax": "0376528280",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 10.816666603088379
      }
    },
    {
      "codice": "020028",
      "nome": "Guidizzolo",
      "nomeStraniero": null,
      "codiceCatastale": "E261",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "sportello.unico@comune.guidizzolo.mn.it",
      "pec": "guidizzolo.mn@legalmail.it",
      "telefono": "+39 0376819201",
      "fax": "+39 0376819114",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 10.583333015441895
      }
    },
    {
      "codice": "020029",
      "nome": "Magnacavallo",
      "nomeStraniero": null,
      "codiceCatastale": "E818",
      "cap": "46020",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "ufficio.anagrafe@comune.magnacavallo.mn.it",
      "pec": "comune.magnacavallo@pec.regione.lombardia.it",
      "telefono": "+39 038655151",
      "fax": "+39 038655522",
      "coordinate": {
        "lat": 45.0059700012207,
        "lng": 11.182299613952637
      }
    },
    {
      "codice": "020030",
      "nome": "Mantova",
      "nomeStraniero": null,
      "codiceCatastale": "E897",
      "cap": "46100",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "ilenia.zerbini@comune.mantova.gov.it",
      "pec": "servizi.demografici@cert.comune.mantova.it",
      "telefono": "+39 0376338620",
      "fax": "+39 0376338232",
      "coordinate": {
        "lat": 45.15638732910156,
        "lng": 10.79111099243164
      }
    },
    {
      "codice": "020031",
      "nome": "Marcaria",
      "nomeStraniero": null,
      "codiceCatastale": "E922",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "marcaria.mn@legalmail.it",
      "pec": "marcaria.mn@legalmail.it",
      "telefono": "+39 0376/953010",
      "fax": "+39 0376/951011",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 10.533333778381348
      }
    },
    {
      "codice": "020032",
      "nome": "Mariana Mantovana",
      "nomeStraniero": null,
      "codiceCatastale": "E949",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "comunedimariana@tin.it",
      "pec": "comune.mariana-mantovana@pec.regione.lombardia.it",
      "telefono": "+39 0376735005",
      "fax": "+39 0376735313",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 10.483333587646484
      }
    },
    {
      "codice": "020033",
      "nome": "Marmirolo",
      "nomeStraniero": null,
      "codiceCatastale": "E962",
      "cap": "46045",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "protocollo@comune.marmirolo.mn.it",
      "pec": "protocollo@comunemarmirolo.legalmail.it",
      "telefono": "+39 0376/298531",
      "fax": "+39 0376294045",
      "coordinate": {
        "lat": 45.21928024291992,
        "lng": 10.75609016418457
      }
    },
    {
      "codice": "020034",
      "nome": "Medole",
      "nomeStraniero": null,
      "codiceCatastale": "F086",
      "cap": "46046",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "demografici@comune.medole.mn.it",
      "pec": "medole.mn@legalmail.it",
      "telefono": "+39 03761621818",
      "fax": "+39 0376868002",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 10.516666412353516
      }
    },
    {
      "codice": "020035",
      "nome": "Moglia",
      "nomeStraniero": null,
      "codiceCatastale": "F267",
      "cap": "46024",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "infocomune@comune.moglia.mn.it",
      "pec": "comune.moglia@pec.regione.lombardia.it",
      "telefono": "+39 0376/511411",
      "fax": "+39 0376/557230",
      "coordinate": {
        "lat": 44.93333435058594,
        "lng": 10.916666984558105
      }
    },
    {
      "codice": "020036",
      "nome": "Monzambano",
      "nomeStraniero": null,
      "codiceCatastale": "F705",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@monzambano.gov.it",
      "pec": "monzambano.mn@legalmail.it",
      "telefono": "+39 0376800502",
      "fax": "+39 0376809348",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 10.699999809265137
      }
    },
    {
      "codice": "020037",
      "nome": "Motteggiana",
      "nomeStraniero": null,
      "codiceCatastale": "B012",
      "cap": "46020",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "servizi.amministrativi@pec.comune.motteggiana.mn.it",
      "pec": "servizi.amministrativi@pec.comune.motteggiana.mn.it",
      "telefono": "+39 0376 527043",
      "fax": "+39 0376 510949",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "020038",
      "nome": "Ostiglia",
      "nomeStraniero": null,
      "codiceCatastale": "G186",
      "cap": "46035",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "comune.ostiglia@pec.regione.lombardia.it",
      "pec": "comune.ostiglia@pec.regione.lombardia.it",
      "telefono": "+39 0386300211",
      "fax": "+39 0386300220",
      "coordinate": {
        "lat": 45.07038879394531,
        "lng": 11.136409759521484
      }
    },
    {
      "codice": "020039",
      "nome": "Pegognaga",
      "nomeStraniero": null,
      "codiceCatastale": "G417",
      "cap": "46020",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "elettorale@comune.pegognaga.mn.it",
      "pec": "pegognaga.mn@legalmail.it",
      "telefono": "+39 03765546202",
      "fax": "+39 03765546901",
      "coordinate": {
        "lat": 45,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "020041",
      "nome": "Piubega",
      "nomeStraniero": null,
      "codiceCatastale": "G717",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "affarigenerali@comune.piubega.mn.it",
      "pec": "protocollo@pec.comune.piubega.mn.it",
      "telefono": "+39 0376655131",
      "fax": "+39 0376655042",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 10.533333778381348
      }
    },
    {
      "codice": "020042",
      "nome": "Poggio Rusco",
      "nomeStraniero": null,
      "codiceCatastale": "G753",
      "cap": "46025",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "elettorale@comune.poggiorusco.mn.it",
      "pec": "comune.poggiorusco@pec.regione.lombardia.it",
      "telefono": "+39 038651001",
      "fax": "+39 0386733009",
      "coordinate": {
        "lat": 44.977500915527344,
        "lng": 11.119166374206543
      }
    },
    {
      "codice": "020043",
      "nome": "Pomponesco",
      "nomeStraniero": null,
      "codiceCatastale": "G816",
      "cap": "46030",
      "prefisso": "0375",
      "provincia": "Mantova",
      "email": "segreteria@comune.pomponesco.mn.it",
      "pec": "comune.pomponesco@pec.regione.lombardia.it",
      "telefono": "+39 037586021",
      "fax": "+39 037586325",
      "coordinate": {
        "lat": 44.92972183227539,
        "lng": 10.597222328186035
      }
    },
    {
      "codice": "020044",
      "nome": "Ponti sul Mincio",
      "nomeStraniero": null,
      "codiceCatastale": "G862",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "anagrafe@comune.pontisulmincio.mn.it",
      "pec": "anagrafe@pec.comune.pontisulmincio.mn.it",
      "telefono": "+39 0376813052",
      "fax": "+39 037688296",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 10.699999809265137
      }
    },
    {
      "codice": "020045",
      "nome": "Porto Mantovano",
      "nomeStraniero": null,
      "codiceCatastale": "G917",
      "cap": "46047",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "protocollo@comune.porto-mantovano.mn.it",
      "pec": "comuneportomantovano@legalmail.it",
      "telefono": "+39 0376389028",
      "fax": "+39 0376397847",
      "coordinate": {
        "lat": 45.1966667175293,
        "lng": 10.793889045715332
      }
    },
    {
      "codice": "020046",
      "nome": "Quingentole",
      "nomeStraniero": null,
      "codiceCatastale": "H129",
      "cap": "46020",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "segreteria@comune.quingentole.mn.it",
      "pec": "demografici.comune.quingentole@pec.regione.lombardia.it",
      "telefono": "+39 038642141",
      "fax": "+39 038642558",
      "coordinate": {
        "lat": 45.0383186340332,
        "lng": 11.043582916259766
      }
    },
    {
      "codice": "020047",
      "nome": "Quistello",
      "nomeStraniero": null,
      "codiceCatastale": "H143",
      "cap": "46026",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "protocollo@comune.quistello.mn.it",
      "pec": "comune.quistello@pec.regione.lombardia.it",
      "telefono": "+39 0376627235",
      "fax": "+39 0376619884",
      "coordinate": {
        "lat": 45.0065803527832,
        "lng": 10.980460166931152
      }
    },
    {
      "codice": "020048",
      "nome": "Redondesco",
      "nomeStraniero": null,
      "codiceCatastale": "H218",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "anagrafe@comune.redondesco.mn.it",
      "pec": "redondesco@postemailcertificata.it",
      "telefono": "+39 0376954141",
      "fax": "+39 0376954510",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 10.516666412353516
      }
    },
    {
      "codice": "020050",
      "nome": "Rivarolo Mantovano",
      "nomeStraniero": null,
      "codiceCatastale": "H342",
      "cap": "46017",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria.rivarolo@infinito.it",
      "pec": "comune.rivarolo.mn@legalmail.it",
      "telefono": "+39 037699101",
      "fax": "+39 037699102",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "020051",
      "nome": "Rodigo",
      "nomeStraniero": null,
      "codiceCatastale": "H481",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "info@comune.rodigo.mn.it",
      "pec": "comune.rodigo@pec.regione.lombardia.it",
      "telefono": "+39 0376 684211",
      "fax": "+39 0376 650683",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "020052",
      "nome": "Roncoferraro",
      "nomeStraniero": null,
      "codiceCatastale": "H541",
      "cap": "46037",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "demografici@comune.roncoferraro.mn.it",
      "pec": "protocollo.comune.roncoferraro@pec.regione.lombardia.it",
      "telefono": "+39 03766629242",
      "fax": "+39 03766629207",
      "coordinate": {
        "lat": 45.13420104980469,
        "lng": 10.95241928100586
      }
    },
    {
      "codice": "020053",
      "nome": "Roverbella",
      "nomeStraniero": null,
      "codiceCatastale": "H604",
      "cap": "46048",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.roverbella.mn.it",
      "pec": "roverbella.mn@legalmail.it",
      "telefono": "+39 03766918200",
      "fax": "+39 0376/694515",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "020054",
      "nome": "Sabbioneta",
      "nomeStraniero": null,
      "codiceCatastale": "H652",
      "cap": "46018",
      "prefisso": "0375",
      "provincia": "Mantova",
      "email": "protocollo@comune.sabbioneta.mn.it",
      "pec": "comune.sabbioneta@pec.regione.lombardia.it",
      "telefono": "+39 0375 223011",
      "fax": null,
      "coordinate": {
        "lat": 44.999168395996094,
        "lng": 10.48972225189209
      }
    },
    {
      "codice": "020055",
      "nome": "San Benedetto Po",
      "nomeStraniero": null,
      "codiceCatastale": "H771",
      "cap": "46027",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "demografici@comune.san-benedetto-po.mn.it",
      "pec": "protocollo.sanbenedetto@legalmailpa.it",
      "telefono": "+39 0376623030",
      "fax": "+39 0376623021",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 10.916666984558105
      }
    },
    {
      "codice": "020056",
      "nome": "San Giacomo delle Segnate",
      "nomeStraniero": null,
      "codiceCatastale": "H870",
      "cap": "46020",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "sindaco@comunesangiacomodellesegnate.it",
      "pec": "comune.sangiacomodellesegnate@legalmail.it",
      "telefono": "+39 0376616101",
      "fax": "+39 0376616588",
      "coordinate": {
        "lat": 44.97142028808594,
        "lng": 11.033659934997559
      }
    },
    {
      "codice": "020057",
      "nome": "San Giorgio Bigarello",
      "nomeStraniero": null,
      "codiceCatastale": "H883",
      "cap": "46051",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "f.mazzieri@comune.sangiorgiobigarello.mn.it",
      "pec": "comune.sangiorgio-bigarello@pec.regione.lombardia.it",
      "telefono": "+39 0376273130",
      "fax": null,
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 10.833333015441895
      }
    },
    {
      "codice": "020058",
      "nome": "San Giovanni del Dosso",
      "nomeStraniero": null,
      "codiceCatastale": "H912",
      "cap": "46020",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "info@comune.sangiovannideldosso.mn.it",
      "pec": "comune.sangiovannideldosso@pec.regione.lombardia.it",
      "telefono": "+39 0386757131",
      "fax": "+39 0386757613",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "020059",
      "nome": "San Martino dall'Argine",
      "nomeStraniero": null,
      "codiceCatastale": "I005",
      "cap": "46010",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "segreteria@comune.sanmartinodallargine.mn.it",
      "pec": "comune.sanmartinodallargine@pec.regione.lombardia.it",
      "telefono": "+39 0376 922011",
      "fax": "+39 0376 920996",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 10.5
      }
    },
    {
      "codice": "020060",
      "nome": "Schivenoglia",
      "nomeStraniero": null,
      "codiceCatastale": "I532",
      "cap": "46020",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "segreteria@comune.schivenoglia.mn.it",
      "pec": "comune.schivenoglia@pec.regione.lombardia.it",
      "telefono": "+39 038658141",
      "fax": "+39 038658124",
      "coordinate": {
        "lat": 45,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "020061",
      "nome": "Sermide e Felonica",
      "nomeStraniero": null,
      "codiceCatastale": "I632",
      "cap": "46028",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "protocollo@comune.sermide.mn.it",
      "pec": "segreteria@pec.comune.sermide.mn.it",
      "telefono": "+39 0386967083",
      "fax": "+39 0386960261",
      "coordinate": {
        "lat": 44.98919677734375,
        "lng": 11.318453788757324
      }
    },
    {
      "codice": "020062",
      "nome": "Serravalle a Po",
      "nomeStraniero": null,
      "codiceCatastale": "I662",
      "cap": "46030",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "ufficiosegreteria@comune.serravalleapo.mn.it",
      "pec": "comune.serravalleapo@pec.regione.lombardia.it",
      "telefono": "+39 0386840111",
      "fax": "+39 0386840055",
      "coordinate": {
        "lat": 45.07222366333008,
        "lng": 11.099721908569336
      }
    },
    {
      "codice": "020063",
      "nome": "Solferino",
      "nomeStraniero": null,
      "codiceCatastale": "I801",
      "cap": "46040",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "lavoripubblici@comune.solferino.mn.it",
      "pec": "protocollo.comune.solferino@pec.regione.lombardia.it",
      "telefono": "+39 0376854001",
      "fax": "+39 0376855547",
      "coordinate": {
        "lat": 45.37236022949219,
        "lng": 10.566479682922363
      }
    },
    {
      "codice": "020064",
      "nome": "Sustinente",
      "nomeStraniero": null,
      "codiceCatastale": "L015",
      "cap": "46030",
      "prefisso": "0386",
      "provincia": "Mantova",
      "email": "segreteria@comune.sustinente.mn.it",
      "pec": "comunesustinente@pec.it",
      "telefono": "+39 038643161",
      "fax": "+39 0386437179",
      "coordinate": {
        "lat": 45.068687438964844,
        "lng": 11.021746635437012
      }
    },
    {
      "codice": "020065",
      "nome": "Suzzara",
      "nomeStraniero": null,
      "codiceCatastale": "L020",
      "cap": "46029",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "info@comune.suzzara.mn.it",
      "pec": "protocollo@comune.suzzara.mn.legalmail.it",
      "telefono": "+39 0376 5131",
      "fax": "+39 0376/522138",
      "coordinate": {
        "lat": 44.99266052246094,
        "lng": 10.749409675598145
      }
    },
    {
      "codice": "020066",
      "nome": "Viadana",
      "nomeStraniero": null,
      "codiceCatastale": "L826",
      "cap": "46019",
      "prefisso": "0375",
      "provincia": "Mantova",
      "email": "urp@comune.viadana.mn.it",
      "pec": "urp@pec.comune.viadana.mn.it",
      "telefono": "+39 03757861",
      "fax": "+39 037582854",
      "coordinate": {
        "lat": 44.926666259765625,
        "lng": 10.520000457763672
      }
    },
    {
      "codice": "020068",
      "nome": "Villimpenta",
      "nomeStraniero": null,
      "codiceCatastale": "M044",
      "cap": "46039",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "sindaco@comune.villimpenta.mn.it",
      "pec": "comune.villimpenta@pec.regione.lombardia.it",
      "telefono": "+39 0376 - 667508",
      "fax": "+39 0376 - 667509",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "020070",
      "nome": "Volta Mantovana",
      "nomeStraniero": null,
      "codiceCatastale": "M125",
      "cap": "46049",
      "prefisso": "0376",
      "provincia": "Mantova",
      "email": "sportello.unico@comune.volta.mn.it",
      "pec": "voltamantovana.mn@legalmail.it",
      "telefono": "+39 0376839411",
      "fax": "+39 0376839419",
      "coordinate": {
        "lat": 45.31944274902344,
        "lng": 10.659444808959961
      }
    }
  ],
  "Viterbo": [
    {
      "codice": "056001",
      "nome": "Acquapendente",
      "nomeStraniero": null,
      "codiceCatastale": "A040",
      "cap": "01021",
      "prefisso": "0763",
      "provincia": "Viterbo",
      "email": "anagrafe@comuneacquapendente.it",
      "pec": "comuneacquapendente@legalmail.it",
      "telefono": "+39 0763/73091",
      "fax": "+39 0763/711219",
      "coordinate": {
        "lat": 42.744720458984375,
        "lng": 11.864999771118164
      }
    },
    {
      "codice": "056002",
      "nome": "Arlena di Castro",
      "nomeStraniero": null,
      "codiceCatastale": "A412",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "comune.arlena@virigilio.it",
      "pec": "amministrazione@pec.comune.arlenadicastro.vt.it",
      "telefono": "+39 0761/430951",
      "fax": "+39 0761430609",
      "coordinate": {
        "lat": 42.4636116027832,
        "lng": 11.821389198303223
      }
    },
    {
      "codice": "056003",
      "nome": "Bagnoregio",
      "nomeStraniero": null,
      "codiceCatastale": "A577",
      "cap": "01022",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "servizidemografici@comunebagnoregio.it",
      "pec": "comune.bagnoregio.vt@legalmail.it",
      "telefono": "+39 0761/780815",
      "fax": "+39 0761/780837",
      "coordinate": {
        "lat": 42.630001068115234,
        "lng": 12.089166641235352
      }
    },
    {
      "codice": "056004",
      "nome": "Barbarano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "A628",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comunebarbaranoromano.it",
      "pec": "comune.barbaranoromano@pec.it",
      "telefono": "+39 0761/414601",
      "fax": "+39 0761/414340",
      "coordinate": {
        "lat": 42.25027847290039,
        "lng": 12.067500114440918
      }
    },
    {
      "codice": "056006",
      "nome": "Bassano in Teverina",
      "nomeStraniero": null,
      "codiceCatastale": "A706",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.bassanointeverina.vt.it",
      "pec": "uffici@pec.comune.bassanointeverina.vt.it",
      "telefono": "+39 0761/407012",
      "fax": "+39 0761/407242",
      "coordinate": {
        "lat": 42.46666717529297,
        "lng": 12.3125
      }
    },
    {
      "codice": "056005",
      "nome": "Bassano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "A704",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.bassanoromano.vt.it",
      "pec": "bassanoromano@legalmail.it",
      "telefono": "+39 0761/636910",
      "fax": "+39 0761/634016",
      "coordinate": {
        "lat": 42.225276947021484,
        "lng": 12.1899995803833
      }
    },
    {
      "codice": "056007",
      "nome": "Blera",
      "nomeStraniero": null,
      "codiceCatastale": "A857",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "ufficioamministrazione@comuneblera.gov.it",
      "pec": "uffici@pec.comuneblera.gov.it",
      "telefono": "+39 0761/470093",
      "fax": "+39 0761470566",
      "coordinate": {
        "lat": 42.27333450317383,
        "lng": 12.032500267028809
      }
    },
    {
      "codice": "056008",
      "nome": "Bolsena",
      "nomeStraniero": null,
      "codiceCatastale": "A949",
      "cap": "01023",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "ufficiodemografico@comunebolsena.it",
      "pec": "postacertificata@pec.comune.bolsena.vt.it",
      "telefono": "+39 0761/795412",
      "fax": "+39 0761/795555",
      "coordinate": {
        "lat": 42.64472198486328,
        "lng": 11.985833168029785
      }
    },
    {
      "codice": "056009",
      "nome": "Bomarzo",
      "nomeStraniero": null,
      "codiceCatastale": "A955",
      "cap": "01020",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "amministrativo@comunebomarzo.it",
      "pec": "comunebomarzo@pec.it",
      "telefono": "+39 0761/924021",
      "fax": "+39 0761/924637",
      "coordinate": {
        "lat": 42.4827766418457,
        "lng": 12.249444007873535
      }
    },
    {
      "codice": "056010",
      "nome": "Calcata",
      "nomeStraniero": null,
      "codiceCatastale": "B388",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafetributi@comunecalcata.it",
      "pec": "protocollo@pec.comunecalcata.it",
      "telefono": "+39 0761/587021",
      "fax": "+39 0761/587771",
      "coordinate": {
        "lat": 42.219520568847656,
        "lng": 12.426170349121094
      }
    },
    {
      "codice": "056011",
      "nome": "Canepina",
      "nomeStraniero": null,
      "codiceCatastale": "B597",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "comunedicanepina@libero.it",
      "pec": "comunedicanepina@legalmail.it",
      "telefono": "+39 0761.750990",
      "fax": "+39 0761.750960",
      "coordinate": {
        "lat": 42.383056640625,
        "lng": 12.23277759552002
      }
    },
    {
      "codice": "056012",
      "nome": "Canino",
      "nomeStraniero": null,
      "codiceCatastale": "B604",
      "cap": "01011",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.canino.vt.it",
      "pec": "comune.canino.vt@legalmail.it",
      "telefono": "+39 0761.433923",
      "fax": "+39 0761.43485",
      "coordinate": {
        "lat": 42.46500015258789,
        "lng": 11.751944541931152
      }
    },
    {
      "codice": "056013",
      "nome": "Capodimonte",
      "nomeStraniero": null,
      "codiceCatastale": "B663",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.capodimonte.vt.it",
      "pec": "comunecapodimonte@itpec.it",
      "telefono": "+39 0761/870043",
      "fax": "+39 0761/871206",
      "coordinate": {
        "lat": 42.54750061035156,
        "lng": 11.905555725097656
      }
    },
    {
      "codice": "056014",
      "nome": "Capranica",
      "nomeStraniero": null,
      "codiceCatastale": "B688",
      "cap": "01012",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "protocollo@comune.capranica.vt.it",
      "pec": "comunedicapranica@legalmail.it",
      "telefono": "+39 0761667926 - 28",
      "fax": "+39 0761667932",
      "coordinate": {
        "lat": 42.25849151611328,
        "lng": 12.17278003692627
      }
    },
    {
      "codice": "056015",
      "nome": "Caprarola",
      "nomeStraniero": null,
      "codiceCatastale": "B691",
      "cap": "01032",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "settore6@comune.caprarola.vt.it",
      "pec": "comune.caprarola@anutel.it",
      "telefono": "+39 0761/649024",
      "fax": "+39 0761/647865",
      "coordinate": {
        "lat": 42.32749938964844,
        "lng": 12.236666679382324
      }
    },
    {
      "codice": "056016",
      "nome": "Carbognano",
      "nomeStraniero": null,
      "codiceCatastale": "B735",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.carbognano.vt.it",
      "pec": "comune.carbognano@pec.it",
      "telefono": "+39 0761/6140200",
      "fax": "+39 0761/613716",
      "coordinate": {
        "lat": 42.33250045776367,
        "lng": 12.265277862548828
      }
    },
    {
      "codice": "056017",
      "nome": "Castel Sant'Elia",
      "nomeStraniero": null,
      "codiceCatastale": "C269",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comunecastelsantelia.it",
      "pec": "protocollo@pec.comunecastelsantelia.it",
      "telefono": "+39 0761/556425",
      "fax": "+39 0761/570152",
      "coordinate": {
        "lat": 42.25138854980469,
        "lng": 12.375
      }
    },
    {
      "codice": "056018",
      "nome": "Castiglione in Teverina",
      "nomeStraniero": null,
      "codiceCatastale": "C315",
      "cap": "01024",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "segreteria@teverina.it",
      "pec": "protocollo@pec.comune.castiglioneinteverina.vt.it",
      "telefono": "+39 0761/948301",
      "fax": "+39 0761/947094",
      "coordinate": {
        "lat": 42.648887634277344,
        "lng": 12.204166412353516
      }
    },
    {
      "codice": "056019",
      "nome": "Celleno",
      "nomeStraniero": null,
      "codiceCatastale": "C446",
      "cap": "01020",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "segreteria@celleno.it",
      "pec": "comune.celleno@pec.it",
      "telefono": "+39 0761.912002",
      "fax": "+39 0761.912456",
      "coordinate": {
        "lat": 42.56083297729492,
        "lng": 12.137222290039062
      }
    },
    {
      "codice": "056020",
      "nome": "Cellere",
      "nomeStraniero": null,
      "codiceCatastale": "C447",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.cellere.vt.it",
      "pec": "comunedicellere@postecert.it",
      "telefono": "+39 0761/451791",
      "fax": "+39 0761/451763",
      "coordinate": {
        "lat": 42.511390686035156,
        "lng": 11.772500038146973
      }
    },
    {
      "codice": "056021",
      "nome": "Civita Castellana",
      "nomeStraniero": null,
      "codiceCatastale": "C765",
      "cap": "01033",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "uff.anagrafe@comune.civitacastellana.vt.it",
      "pec": "comune.civitacastellana@legalmail.it",
      "telefono": "+39 0761 5901",
      "fax": "+39 0761/517079",
      "coordinate": {
        "lat": 42.296112060546875,
        "lng": 12.40999984741211
      }
    },
    {
      "codice": "056022",
      "nome": "Civitella d'Agliano",
      "nomeStraniero": null,
      "codiceCatastale": "C780",
      "cap": "01020",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "demografici@comunecivitella.it",
      "pec": "comune.civitella.vt@legalmail.it",
      "telefono": "+39 0761/914884",
      "fax": "+39 0761/914918",
      "coordinate": {
        "lat": 42.60416793823242,
        "lng": 12.187777519226074
      }
    },
    {
      "codice": "056023",
      "nome": "Corchiano",
      "nomeStraniero": null,
      "codiceCatastale": "C988",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "ufficioanagrafe@corchiano.vt.it",
      "pec": "comunedicorchiano@legalmail.it",
      "telefono": "+39 0761/572002",
      "fax": "+39 0761/572544",
      "coordinate": {
        "lat": 42.34583282470703,
        "lng": 12.356389045715332
      }
    },
    {
      "codice": "056024",
      "nome": "Fabrica di Roma",
      "nomeStraniero": null,
      "codiceCatastale": "D452",
      "cap": "01034",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "segreteria@comune.fabricadiroma.vt.it",
      "pec": "comunedifabricadiroma@legalmail.it",
      "telefono": "+39 0761 569001",
      "fax": "+39 0761 569935",
      "coordinate": {
        "lat": 42.334720611572266,
        "lng": 12.300277709960938
      }
    },
    {
      "codice": "056025",
      "nome": "Faleria",
      "nomeStraniero": null,
      "codiceCatastale": "D475",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comunedifaleria.it",
      "pec": "protocollo@pec.comunedifaleria.it",
      "telefono": "+39 0761/589826",
      "fax": "+39 0761/589826",
      "coordinate": {
        "lat": 42.227500915527344,
        "lng": 12.446110725402832
      }
    },
    {
      "codice": "056026",
      "nome": "Farnese",
      "nomeStraniero": null,
      "codiceCatastale": "D503",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.farnese.vt.it",
      "pec": "comunedifarnese@legalmail.it",
      "telefono": "+39 0761/458381",
      "fax": "+39 0761/458646",
      "coordinate": {
        "lat": 42.55027770996094,
        "lng": 11.726388931274414
      }
    },
    {
      "codice": "056027",
      "nome": "Gallese",
      "nomeStraniero": null,
      "codiceCatastale": "D870",
      "cap": "01035",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "annagrazia.casali@comune.gallese.vt.it",
      "pec": "comunedigallese@legalmail.it",
      "telefono": "+39 0761.497923",
      "fax": null,
      "coordinate": {
        "lat": 42.37444305419922,
        "lng": 12.3997220993042
      }
    },
    {
      "codice": "056028",
      "nome": "Gradoli",
      "nomeStraniero": null,
      "codiceCatastale": "E126",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "servizidemografici@gradoli.vt.it",
      "pec": "comunedigradoli@legalmail.it",
      "telefono": "+39 0761/456082",
      "fax": "+39 0761/456052",
      "coordinate": {
        "lat": 42.64444351196289,
        "lng": 11.855555534362793
      }
    },
    {
      "codice": "056029",
      "nome": "Graffignano",
      "nomeStraniero": null,
      "codiceCatastale": "E128",
      "cap": "01020",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "segreteria@comune.graffignano.vt.it",
      "pec": "comunedigraffignano@pec.it",
      "telefono": "+39 0761/901001",
      "fax": "+39 0761/901109",
      "coordinate": {
        "lat": 42.573333740234375,
        "lng": 12.202500343322754
      }
    },
    {
      "codice": "056030",
      "nome": "Grotte di Castro",
      "nomeStraniero": null,
      "codiceCatastale": "E210",
      "cap": "01025",
      "prefisso": "0763",
      "provincia": "Viterbo",
      "email": "mspadaccia@comune.grottedicastro.vt.it",
      "pec": "comunegrottedicastro@legalmail.it",
      "telefono": "+39 0763.798002",
      "fax": "+39 0763.797172",
      "coordinate": {
        "lat": 42.67527770996094,
        "lng": 11.873055458068848
      }
    },
    {
      "codice": "056031",
      "nome": "Ischia di Castro",
      "nomeStraniero": null,
      "codiceCatastale": "E330",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.ischiadicastro.vt.it",
      "pec": "protocollo@pec.comune.ischiadicastro.vt.it",
      "telefono": "+39 0761/425455",
      "fax": "+39 0761/425456",
      "coordinate": {
        "lat": 42.54499816894531,
        "lng": 11.75694465637207
      }
    },
    {
      "codice": "056032",
      "nome": "Latera",
      "nomeStraniero": null,
      "codiceCatastale": "E467",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.latera.vt.it",
      "pec": "comune.latera.vt@legalmail.it",
      "telefono": "+39 0761/459784",
      "fax": "+39 0761/459476",
      "coordinate": {
        "lat": 42.630001068115234,
        "lng": 11.828332901000977
      }
    },
    {
      "codice": "056033",
      "nome": "Lubriano",
      "nomeStraniero": null,
      "codiceCatastale": "E713",
      "cap": "01020",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "amministrativo@comune.lubriano.vt.it",
      "pec": "comunelubriano@legalmail.it",
      "telefono": "+39 0761780459",
      "fax": "+39 0761780391",
      "coordinate": {
        "lat": 42.63694381713867,
        "lng": 12.109444618225098
      }
    },
    {
      "codice": "056034",
      "nome": "Marta",
      "nomeStraniero": null,
      "codiceCatastale": "E978",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.marta.vt.it",
      "pec": "comunemartavt@pec.it",
      "telefono": "+39 0761.8738",
      "fax": "+39 0761.873828",
      "coordinate": {
        "lat": 42.53444290161133,
        "lng": 11.925833702087402
      }
    },
    {
      "codice": "056035",
      "nome": "Montalto di Castro",
      "nomeStraniero": null,
      "codiceCatastale": "F419",
      "cap": "01014",
      "prefisso": "0766",
      "provincia": "Viterbo",
      "email": "elettorale@comune.montaltodicastro.vt.it",
      "pec": "comune.montaltodicastro.vt@legalmail.it",
      "telefono": "+39 0766 870102",
      "fax": "+39 0766 89475",
      "coordinate": {
        "lat": 42.35138702392578,
        "lng": 11.60777759552002
      }
    },
    {
      "codice": "056037",
      "nome": "Monte Romano",
      "nomeStraniero": null,
      "codiceCatastale": "F603",
      "cap": "01010",
      "prefisso": "0766",
      "provincia": "Viterbo",
      "email": "iole.valeri@comune.monteromano.vt.it",
      "pec": "segreteria@pec.comune.monteromano.vt.it",
      "telefono": "+39 0766 860021",
      "fax": "+39 0766 860777",
      "coordinate": {
        "lat": 42.2680549621582,
        "lng": 11.898611068725586
      }
    },
    {
      "codice": "056036",
      "nome": "Montefiascone",
      "nomeStraniero": null,
      "codiceCatastale": "F499",
      "cap": "01027",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "s.angeli@comune.montefiascone.vt.it",
      "pec": "protocollo@pec.comune.montefiascone.vt.it",
      "telefono": "+39 0761/83201",
      "fax": "+39 0761/832074",
      "coordinate": {
        "lat": 42.53333282470703,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "056038",
      "nome": "Monterosi",
      "nomeStraniero": null,
      "codiceCatastale": "F606",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "demografici@comune.monterosi.vt.it",
      "pec": "comunedimonterosi@pec.it",
      "telefono": "+39 0761/699444",
      "fax": "+39 0761/699664",
      "coordinate": {
        "lat": 42.19697952270508,
        "lng": 12.308420181274414
      }
    },
    {
      "codice": "056039",
      "nome": "Nepi",
      "nomeStraniero": null,
      "codiceCatastale": "F868",
      "cap": "01036",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.nepi.vt.it",
      "pec": "comune.nepi.vt@legalmailpa.it",
      "telefono": "+39 0761/5581300",
      "fax": "+39 0761/557960",
      "coordinate": {
        "lat": 42.24361038208008,
        "lng": 12.346388816833496
      }
    },
    {
      "codice": "056040",
      "nome": "Onano",
      "nomeStraniero": null,
      "codiceCatastale": "G065",
      "cap": "01010",
      "prefisso": "0763",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.onano.vt.it",
      "pec": "comuneonano@pec.it",
      "telefono": "+39 0763/78021",
      "fax": "+39 0763/78391",
      "coordinate": {
        "lat": 42.692222595214844,
        "lng": 11.816944122314453
      }
    },
    {
      "codice": "056041",
      "nome": "Oriolo Romano",
      "nomeStraniero": null,
      "codiceCatastale": "G111",
      "cap": "01010",
      "prefisso": "06",
      "provincia": "Viterbo",
      "email": "comuneorioloromano@tiscali.it",
      "pec": "comuneorioloromano@postemailcertificata.pec.it",
      "telefono": "+39 06/699837144",
      "fax": "+39 06/99837482",
      "coordinate": {
        "lat": 42.157798767089844,
        "lng": 12.137800216674805
      }
    },
    {
      "codice": "056042",
      "nome": "Orte",
      "nomeStraniero": null,
      "codiceCatastale": "G135",
      "cap": "01028",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "diberardino.n.@comune.orte.vt.it",
      "pec": "comuneorte@pec.it",
      "telefono": "+39 0761/404329",
      "fax": "+39 0761/404325",
      "coordinate": {
        "lat": 42.46027755737305,
        "lng": 12.38611125946045
      }
    },
    {
      "codice": "056043",
      "nome": "Piansano",
      "nomeStraniero": null,
      "codiceCatastale": "G571",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "comune@piansano.org",
      "pec": "comunepiansano@legalmail.it",
      "telefono": "+39 0761 450832",
      "fax": "+39 0761 450118",
      "coordinate": {
        "lat": 42.52305603027344,
        "lng": 11.829999923706055
      }
    },
    {
      "codice": "056044",
      "nome": "Proceno",
      "nomeStraniero": null,
      "codiceCatastale": "H071",
      "cap": "01020",
      "prefisso": "0763",
      "provincia": "Viterbo",
      "email": "info@comunediproceno.vt.it",
      "pec": "postacertificata@pec.comunediproceno.vt.it",
      "telefono": "+39 0763/710092",
      "fax": "+39 0763/710161",
      "coordinate": {
        "lat": 42.758056640625,
        "lng": 11.83055591583252
      }
    },
    {
      "codice": "056045",
      "nome": "Ronciglione",
      "nomeStraniero": null,
      "codiceCatastale": "H534",
      "cap": "01037",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "anagrafe@comune.ronciglione.vt.it",
      "pec": "anagrafe.ronciglione@legalmail.it",
      "telefono": "+39 0761/629021",
      "fax": "+39 0761/629062",
      "coordinate": {
        "lat": 42.28944396972656,
        "lng": 12.214722633361816
      }
    },
    {
      "codice": "056047",
      "nome": "San Lorenzo Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "H969",
      "cap": "01020",
      "prefisso": "0763",
      "provincia": "Viterbo",
      "email": "anagrafe@comunesanlorenzonuovo.it",
      "pec": "comunesanlorenzonuovo@pec.it",
      "telefono": "+39 0763/72681",
      "fax": "+39 0763/7268223",
      "coordinate": {
        "lat": 42.6875,
        "lng": 11.907500267028809
      }
    },
    {
      "codice": "056048",
      "nome": "Soriano nel Cimino",
      "nomeStraniero": null,
      "codiceCatastale": "I855",
      "cap": "01038",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "maurizio.capponi@comune.sorianonelcimino.vt.it",
      "pec": "puntocomune.soriano@pec.it",
      "telefono": "+39 0761/722210",
      "fax": "+39 0761742252",
      "coordinate": {
        "lat": 42.4194450378418,
        "lng": 12.234167098999023
      }
    },
    {
      "codice": "056049",
      "nome": "Sutri",
      "nomeStraniero": null,
      "codiceCatastale": "L017",
      "cap": "01015",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "elettorale@comune.sutri.vt.it",
      "pec": "comunesutri@postecert.it",
      "telefono": "+39 0761 6011",
      "fax": null,
      "coordinate": {
        "lat": 42.24777603149414,
        "lng": 12.21583366394043
      }
    },
    {
      "codice": "056050",
      "nome": "Tarquinia",
      "nomeStraniero": null,
      "codiceCatastale": "D024",
      "cap": "01016",
      "prefisso": "0766",
      "provincia": "Viterbo",
      "email": "comune.tarquinia@comune.tarquinia.vt.it",
      "pec": "pec@pec.comune.tarquinia.vt.it",
      "telefono": "+39 07668491",
      "fax": "+39 0766849270",
      "coordinate": {
        "lat": 42.249168395996094,
        "lng": 11.756111145019531
      }
    },
    {
      "codice": "056051",
      "nome": "Tessennano",
      "nomeStraniero": null,
      "codiceCatastale": "L150",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "servizidemografici@comune.tessennano.vt.it",
      "pec": "comune.tessennano@pec.it",
      "telefono": "+39 0761/431001",
      "fax": "+39 0761/431175",
      "coordinate": {
        "lat": 42.47916793823242,
        "lng": 11.79111099243164
      }
    },
    {
      "codice": "056052",
      "nome": "Tuscania",
      "nomeStraniero": null,
      "codiceCatastale": "L310",
      "cap": "01017",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "info@comune.tuscania.vt.it",
      "pec": "protocollo@pec.comune.tuscania.vt.it",
      "telefono": "+39 0761.44541",
      "fax": "+39 0761 443664",
      "coordinate": {
        "lat": 42.421390533447266,
        "lng": 11.871944427490234
      }
    },
    {
      "codice": "056053",
      "nome": "Valentano",
      "nomeStraniero": null,
      "codiceCatastale": "L569",
      "cap": "01018",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "info@comune.valentano.vt.it",
      "pec": "comune.valentano.vt@legalmail.it",
      "telefono": "+39 0761/453001",
      "fax": "+39 0761/453702",
      "coordinate": {
        "lat": 42.565277099609375,
        "lng": 11.826944351196289
      }
    },
    {
      "codice": "056054",
      "nome": "Vallerano",
      "nomeStraniero": null,
      "codiceCatastale": "L612",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "segreteria@vallerano.net",
      "pec": "pec@pec.vallerano.net",
      "telefono": "+39 0761 751001",
      "fax": "+39 0761 751771",
      "coordinate": {
        "lat": 42.3849983215332,
        "lng": 12.264166831970215
      }
    },
    {
      "codice": "056055",
      "nome": "Vasanello",
      "nomeStraniero": null,
      "codiceCatastale": "A701",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "statocivile@comune.vasanello.vt.it",
      "pec": "comune.vasanello.vt@pec.it",
      "telefono": "+39 0761/4089305",
      "fax": "+39 0761/4089389",
      "coordinate": {
        "lat": 42.41444396972656,
        "lng": 12.34749984741211
      }
    },
    {
      "codice": "056056",
      "nome": "Vejano",
      "nomeStraniero": null,
      "codiceCatastale": "L713",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "segreteria@comune.vejano.vt.it",
      "pec": "comunevejano@legalmail.it",
      "telefono": "+39 0761/463051",
      "fax": "+39 0761/463244",
      "coordinate": {
        "lat": 42.218055725097656,
        "lng": 12.096388816833496
      }
    },
    {
      "codice": "056057",
      "nome": "Vetralla",
      "nomeStraniero": null,
      "codiceCatastale": "L814",
      "cap": "01019",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "fstasi@comune.vetralla.vt.it",
      "pec": "comune.vetralla@legalmail.it",
      "telefono": "+39 0761/466938",
      "fax": "+39 0761/477796",
      "coordinate": {
        "lat": 42.31055450439453,
        "lng": 12.079166412353516
      }
    },
    {
      "codice": "056058",
      "nome": "Vignanello",
      "nomeStraniero": null,
      "codiceCatastale": "L882",
      "cap": "01039",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "uffici.demografici@comunevignanello.eu",
      "pec": "comunevignanello@legalmail.it",
      "telefono": "+39 0761.7563200",
      "fax": "+39 0761.7563204",
      "coordinate": {
        "lat": 42.38472366333008,
        "lng": 12.27750015258789
      }
    },
    {
      "codice": "056046",
      "nome": "Villa San Giovanni in Tuscia",
      "nomeStraniero": null,
      "codiceCatastale": "H913",
      "cap": "01010",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "comune@comunevsgtuscia.it",
      "pec": "comune.villasangiovanniintuscia@legalmail.it",
      "telefono": "+39 0761 476476",
      "fax": "+39 0761 476338",
      "coordinate": {
        "lat": 42.27972412109375,
        "lng": 12.054166793823242
      }
    },
    {
      "codice": "056059",
      "nome": "Viterbo",
      "nomeStraniero": null,
      "codiceCatastale": "M082",
      "cap": "01100",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "protocollo@comune.viterbo.it",
      "pec": "protocollo@pec.comuneviterbo.it",
      "telefono": "+39 0761 3481",
      "fax": "+39 0761/348271",
      "coordinate": {
        "lat": 42.418609619140625,
        "lng": 12.104166984558105
      }
    },
    {
      "codice": "056060",
      "nome": "Vitorchiano",
      "nomeStraniero": null,
      "codiceCatastale": "M086",
      "cap": "01030",
      "prefisso": "0761",
      "provincia": "Viterbo",
      "email": "d.cristofori@comune.vitorchiano.vt.it",
      "pec": "comune.vitorchiano.vt@legalmail.it",
      "telefono": "+39 0761/373701",
      "fax": "+39 0761/373729",
      "coordinate": {
        "lat": 42.46638870239258,
        "lng": 12.173333168029785
      }
    }
  ],
  "Vibo Valentia": [
    {
      "codice": "102001",
      "nome": "Acquaro",
      "nomeStraniero": null,
      "codiceCatastale": "A043",
      "cap": "89832",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunediacquaro@tiscali.it",
      "pec": "sindacodiacquaro@asmepec.it",
      "telefono": "+39 0963/353071",
      "fax": "+39 0963/354240",
      "coordinate": {
        "lat": 38.55583190917969,
        "lng": 16.18805503845215
      }
    },
    {
      "codice": "102002",
      "nome": "Arena",
      "nomeStraniero": null,
      "codiceCatastale": "A386",
      "cap": "89832",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "demografici.arena@asmepec.it",
      "pec": "demografico@comune.arena.vv.it",
      "telefono": "+39 0963/355602",
      "fax": "+39 0963/341107",
      "coordinate": {
        "lat": 38.56380081176758,
        "lng": 16.2106990814209
      }
    },
    {
      "codice": "102003",
      "nome": "Briatico",
      "nomeStraniero": null,
      "codiceCatastale": "B169",
      "cap": "89817",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "anagcomunebriatico@tiscali.it",
      "pec": "serv.amm.comunebriatico@asmepec.it",
      "telefono": "+39 0963/374242",
      "fax": "+39 0963/395888",
      "coordinate": {
        "lat": 38.733333587646484,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "102004",
      "nome": "Brognaturo",
      "nomeStraniero": null,
      "codiceCatastale": "B197",
      "cap": "89822",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "info@comune.brognaturo.vv.it",
      "pec": "protocollo.brognaturo@asmepec.it",
      "telefono": "+39 0963/74080",
      "fax": "+39 0963/775956",
      "coordinate": {
        "lat": 38.599998474121094,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "102005",
      "nome": "Capistrano",
      "nomeStraniero": null,
      "codiceCatastale": "B655",
      "cap": "89818",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "info@comune.capistrano.vv.it",
      "pec": "segreteria.capistrano@asmepec.it",
      "telefono": "+39 0963 325085",
      "fax": "+39 0963 321914",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "102006",
      "nome": "Cessaniti",
      "nomeStraniero": null,
      "codiceCatastale": "C581",
      "cap": "89816",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunecessaniti@libero.it",
      "pec": "demografici@pec.comunecessaniti.it",
      "telefono": "+39 0963/501022",
      "fax": "+39 0963/501533",
      "coordinate": {
        "lat": 38.66666793823242,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "102007",
      "nome": "Dasà",
      "nomeStraniero": null,
      "codiceCatastale": "D253",
      "cap": "89832",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "mimmosmith@libero.it",
      "pec": "demografico.comune@asmepec.it",
      "telefono": "+39 0963/353057",
      "fax": "+39 0963/354137",
      "coordinate": {
        "lat": 38.56666564941406,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "102008",
      "nome": "Dinami",
      "nomeStraniero": null,
      "codiceCatastale": "D303",
      "cap": "89833",
      "prefisso": "0966",
      "provincia": "Vibo Valentia",
      "email": "comune.dinami@tiscali.it",
      "pec": "ufficiamministrativi.dinami@asmepec.it",
      "telefono": "+39 0966 904073",
      "fax": "+39 0966 990018",
      "coordinate": {
        "lat": 38.53333282470703,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "102009",
      "nome": "Drapia",
      "nomeStraniero": null,
      "codiceCatastale": "D364",
      "cap": "89862",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "sindaco@comune.drapia.vv.it",
      "pec": "protocollo.drapia@asmepec.it",
      "telefono": "+39 0963.67094",
      "fax": "+39 0963.67293",
      "coordinate": {
        "lat": 38.66666793823242,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "102010",
      "nome": "Fabrizia",
      "nomeStraniero": null,
      "codiceCatastale": "D453",
      "cap": "89823",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunefabrizia@libero.it",
      "pec": "protocollo.fabrizia@asmepec.it",
      "telefono": "+39 0963-314001",
      "fax": "+39 0963-314172",
      "coordinate": {
        "lat": 38.48809814453125,
        "lng": 16.301799774169922
      }
    },
    {
      "codice": "102011",
      "nome": "Filadelfia",
      "nomeStraniero": null,
      "codiceCatastale": "D587",
      "cap": "89814",
      "prefisso": "0968",
      "provincia": "Vibo Valentia",
      "email": "filadelfia.utc@tiscali.it",
      "pec": "anagrafe.filadelfia@asmepec.it",
      "telefono": "+39 0968/724122",
      "fax": "+39 0968/725338",
      "coordinate": {
        "lat": 38.78388977050781,
        "lng": 16.29111099243164
      }
    },
    {
      "codice": "102012",
      "nome": "Filandari",
      "nomeStraniero": null,
      "codiceCatastale": "D589",
      "cap": "89841",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "segretario@comune.filandari.vv.it",
      "pec": "protocollo.filandari@asmepec.it",
      "telefono": "+39 0963 363004",
      "fax": "+39 0963 363007",
      "coordinate": {
        "lat": 38.61666488647461,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "102013",
      "nome": "Filogaso",
      "nomeStraniero": null,
      "codiceCatastale": "D596",
      "cap": "89843",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunefilogaso@libero.it",
      "pec": "comunefilogaso@legalmail.it",
      "telefono": "+39 0963/254020",
      "fax": "+39 0963/254116",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "102014",
      "nome": "Francavilla Angitola",
      "nomeStraniero": null,
      "codiceCatastale": "D762",
      "cap": "89815",
      "prefisso": "0968",
      "provincia": "Vibo Valentia",
      "email": "comunefrancavilla@tin.it",
      "pec": "protocollo.francavillaangitola@asmepec.it",
      "telefono": "+39 0968/722068",
      "fax": "+39 0968/722668",
      "coordinate": {
        "lat": 38.78333282470703,
        "lng": 16.266666412353516
      }
    },
    {
      "codice": "102015",
      "nome": "Francica",
      "nomeStraniero": null,
      "codiceCatastale": "D767",
      "cap": "89851",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "segreteria@comune.francica.vv.it",
      "pec": "protocollo.francica@asmepec.it",
      "telefono": "+39 0963 502530",
      "fax": "+39 0963 502373",
      "coordinate": {
        "lat": 38.61666488647461,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "102016",
      "nome": "Gerocarne",
      "nomeStraniero": null,
      "codiceCatastale": "D988",
      "cap": "89831",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunedigerocarne@libero.it",
      "pec": "amministrazionegerocarne@pec.personalpec.eu",
      "telefono": "+39 0963/356100",
      "fax": "+39 0963/356487",
      "coordinate": {
        "lat": 38.58333206176758,
        "lng": 16.21666717529297
      }
    },
    {
      "codice": "102017",
      "nome": "Ionadi",
      "nomeStraniero": null,
      "codiceCatastale": "E321",
      "cap": "89851",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "segreteria@comune.ionadi.vv.it",
      "pec": "segreteria.ionadi@asmepec.it",
      "telefono": "+39 0963/260684",
      "fax": "+39 0963/260669",
      "coordinate": {
        "lat": 38.63333511352539,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "102018",
      "nome": "Joppolo",
      "nomeStraniero": null,
      "codiceCatastale": "E389",
      "cap": "89863",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "ragioneriajoppolo@virgilio.it",
      "pec": "protocollo.joppolo@asmepec.it",
      "telefono": "+39 0963 883010",
      "fax": "+39 0963 883251",
      "coordinate": {
        "lat": 38.58333206176758,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "102019",
      "nome": "Limbadi",
      "nomeStraniero": null,
      "codiceCatastale": "E590",
      "cap": "89844",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "ufficio.segreteria@comune.limbadi.vv.it",
      "pec": "comune.limbadi@asmepec.it",
      "telefono": "+39 0963.85006",
      "fax": "+39 0963.85005",
      "coordinate": {
        "lat": 38.56666564941406,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "102020",
      "nome": "Maierato",
      "nomeStraniero": null,
      "codiceCatastale": "E836",
      "cap": "89843",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comune@comune.maierato.vv.it",
      "pec": "sindaco.maierato@asmepec.it",
      "telefono": "+39 0963/253017",
      "fax": "+39 0963/253645",
      "coordinate": {
        "lat": 38.70000076293945,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "102021",
      "nome": "Mileto",
      "nomeStraniero": null,
      "codiceCatastale": "F207",
      "cap": "89852",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "segretario@comune.mileto.vv.it",
      "pec": "comunemileto@asmepec.it",
      "telefono": "+39 0963-338015",
      "fax": "+39 0963-336499",
      "coordinate": {
        "lat": 38.6077995300293,
        "lng": 16.0675106048584
      }
    },
    {
      "codice": "102022",
      "nome": "Mongiana",
      "nomeStraniero": null,
      "codiceCatastale": "F364",
      "cap": "89823",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "ufficio.ragioneria@comune.mongiana.vv.it",
      "pec": "comune.mongiana@asmepec.it",
      "telefono": "+39 0963/311087",
      "fax": "+39 0963/311130",
      "coordinate": {
        "lat": 38.51388931274414,
        "lng": 16.32027816772461
      }
    },
    {
      "codice": "102023",
      "nome": "Monterosso Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "F607",
      "cap": "89819",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "ufficioanagrafe2@virgilio.it",
      "pec": "anagrafe.monterossocalabro@asmepec.it",
      "telefono": "+39 0963 325002",
      "fax": null,
      "coordinate": {
        "lat": 38.71666717529297,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "102024",
      "nome": "Nardodipace",
      "nomeStraniero": null,
      "codiceCatastale": "F843",
      "cap": "88020",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunenardodipace@gmail.it",
      "pec": "amministrativo.comune.nardodipace@asmepec.it",
      "telefono": "+39 0963/313092",
      "fax": "+39 0963/313370",
      "coordinate": {
        "lat": 38.46666717529297,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "102025",
      "nome": "Nicotera",
      "nomeStraniero": null,
      "codiceCatastale": "F893",
      "cap": "89844",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "area.amministrativa.nicotera@gmail.com",
      "pec": "protocollo.nicotera@asmepec.it",
      "telefono": "+39 0963/81420",
      "fax": "+39 0963/81272",
      "coordinate": {
        "lat": 38.54999923706055,
        "lng": 15.933333396911621
      }
    },
    {
      "codice": "102026",
      "nome": "Parghelia",
      "nomeStraniero": null,
      "codiceCatastale": "G335",
      "cap": "89861",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "brunomolina@libero.it",
      "pec": "protocollo.parghelia@asmepec.it",
      "telefono": "+39 0963/600388",
      "fax": "+39 0963/601941",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "102027",
      "nome": "Pizzo",
      "nomeStraniero": null,
      "codiceCatastale": "G722",
      "cap": "89812",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "archiviopizzo@libero.it",
      "pec": "protocollo.pizzo@asmepec.it",
      "telefono": "+39 0963/534289",
      "fax": "+39 0963/531166",
      "coordinate": {
        "lat": 38.733333587646484,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "102028",
      "nome": "Pizzoni",
      "nomeStraniero": null,
      "codiceCatastale": "G728",
      "cap": "89834",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "info@comune.pizzoni.vv.it",
      "pec": "ssddpizzoni@postecert.it",
      "telefono": "+39 0963/340016",
      "fax": "+39 0963/340000",
      "coordinate": {
        "lat": 38.622859954833984,
        "lng": 16.248760223388672
      }
    },
    {
      "codice": "102029",
      "nome": "Polia",
      "nomeStraniero": null,
      "codiceCatastale": "G785",
      "cap": "89813",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "protocollo@comune.polia.vv.it",
      "pec": "protocollo.polia@asmepec.it",
      "telefono": "+39 0963 321091",
      "fax": "+39 0963 321091",
      "coordinate": {
        "lat": 38.75,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "102030",
      "nome": "Ricadi",
      "nomeStraniero": null,
      "codiceCatastale": "H271",
      "cap": "89865",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "protocollo@comunericadi.gov.it",
      "pec": "comunericadi@pec.it",
      "telefono": "+39 0963/663001",
      "fax": "+39 0963/665800",
      "coordinate": {
        "lat": 38.6261100769043,
        "lng": 15.865555763244629
      }
    },
    {
      "codice": "102031",
      "nome": "Rombiolo",
      "nomeStraniero": null,
      "codiceCatastale": "H516",
      "cap": "89841",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "elettorale@comune.rombiolo.vv.it",
      "pec": "protocollo.rombiolo@asmepec.it",
      "telefono": "+39 0963/367058",
      "fax": "+39 0963/366293",
      "coordinate": {
        "lat": 38.59194564819336,
        "lng": 16.001667022705078
      }
    },
    {
      "codice": "102032",
      "nome": "San Calogero",
      "nomeStraniero": null,
      "codiceCatastale": "H785",
      "cap": "89842",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "sancalogero.anagrafe@libero.it",
      "pec": "demografici.sancalogero@asmepec.it",
      "telefono": "+39 0963/361501",
      "fax": "+39 0963/361498",
      "coordinate": {
        "lat": 38.56666564941406,
        "lng": 16.016666412353516
      }
    },
    {
      "codice": "102033",
      "nome": "San Costantino Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "H807",
      "cap": "89851",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comune.scostantino@libero.it",
      "pec": "comune.sancostantino@pec.it",
      "telefono": "+39 0963/331071",
      "fax": "+39 0963/331962",
      "coordinate": {
        "lat": 38.63333511352539,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "102034",
      "nome": "San Gregorio d'Ippona",
      "nomeStraniero": null,
      "codiceCatastale": "H941",
      "cap": "89853",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "ufficiosegreteria@comune.sangregoriodippona.vv.it",
      "pec": "prot.sangregoriodippona@asmepec.it",
      "telefono": "+39 0963.261021",
      "fax": "+39 0963.261455",
      "coordinate": {
        "lat": 38.650001525878906,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "102035",
      "nome": "San Nicola da Crissa",
      "nomeStraniero": null,
      "codiceCatastale": "I058",
      "cap": "89821",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "anagrafe@comune.sannicoladacrissa.vv.it",
      "pec": "protocollo.sannicoladacrissa@asmepec.it",
      "telefono": "+39 0963-73013",
      "fax": "+39 0963-73413",
      "coordinate": {
        "lat": 38.66666793823242,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "102036",
      "nome": "Sant'Onofrio",
      "nomeStraniero": null,
      "codiceCatastale": "I350",
      "cap": "89843",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "municipiosantonofrio@libero.it",
      "pec": "affarigenerali.santonofrio@asmepec.it",
      "telefono": "+39 0963/262088",
      "fax": "+39 0963/262330",
      "coordinate": {
        "lat": 38.70000076293945,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "102037",
      "nome": "Serra San Bruno",
      "nomeStraniero": null,
      "codiceCatastale": "I639",
      "cap": "89822",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "info@comune.serrasanbruno.vv.it",
      "pec": "info@pec.comune.serrasanbruno.vv.it",
      "telefono": "+39 0963/779421",
      "fax": "+39 0963960995",
      "coordinate": {
        "lat": 38.58333206176758,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "102038",
      "nome": "Simbario",
      "nomeStraniero": null,
      "codiceCatastale": "I744",
      "cap": "89822",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "info@.comune.simbario.vv.it",
      "pec": "ragioneria.simbario@asmepec.it",
      "telefono": "+39 0963/74045",
      "fax": "+39 0963/74786",
      "coordinate": {
        "lat": 38.61666488647461,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "102039",
      "nome": "Sorianello",
      "nomeStraniero": null,
      "codiceCatastale": "I853",
      "cap": "89831",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunesorianello@libero.it",
      "pec": "demografici.sorianello@asmepec.it",
      "telefono": "+39 0963/351186",
      "fax": "+39 0963/351860",
      "coordinate": {
        "lat": 38.599998474121094,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "102040",
      "nome": "Soriano Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "I854",
      "cap": "89831",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunesoriano@cccal.it",
      "pec": "segreteria.soriano@asmepec.it",
      "telefono": "+39 0963 - 351002",
      "fax": "+39 0963 - 351028",
      "coordinate": {
        "lat": 38.599998474121094,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "102041",
      "nome": "Spadola",
      "nomeStraniero": null,
      "codiceCatastale": "I884",
      "cap": "89822",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "anagrafe@comune.spadola.vv.it",
      "pec": "anagrafe.spadola@asmepec.it",
      "telefono": "+39 0963/74007",
      "fax": "+39 0963/775801",
      "coordinate": {
        "lat": 38.599998474121094,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "102042",
      "nome": "Spilinga",
      "nomeStraniero": null,
      "codiceCatastale": "I905",
      "cap": "89864",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "iolandabarbalace@",
      "pec": "anagrafe.spilinga@asmepec.it",
      "telefono": "+39 0963/65035",
      "fax": "+39 0963/605802",
      "coordinate": {
        "lat": 38.61666488647461,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "102043",
      "nome": "Stefanaconi",
      "nomeStraniero": null,
      "codiceCatastale": "I945",
      "cap": "89843",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "codasin@libero.it",
      "pec": "sindacocomunestefanaconi@asmepec.it",
      "telefono": "+39 0963/508046",
      "fax": "+39 0963/507808",
      "coordinate": {
        "lat": 38.66666793823242,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "102044",
      "nome": "Tropea",
      "nomeStraniero": null,
      "codiceCatastale": "L452",
      "cap": "89861",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "amministrazione@comune.tropea.vv.it",
      "pec": "info.tropea@asmepec.it",
      "telefono": "+39 0963 61022",
      "fax": "+39 0963-6041202",
      "coordinate": {
        "lat": 38.67805480957031,
        "lng": 15.896944046020508
      }
    },
    {
      "codice": "102045",
      "nome": "Vallelonga",
      "nomeStraniero": null,
      "codiceCatastale": "L607",
      "cap": "89821",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunedivallelonga.comun@tin.it",
      "pec": "servizidemografici.vallelonga@asmepec.it",
      "telefono": "+39 0963/76065",
      "fax": "+39 0963/776805",
      "coordinate": {
        "lat": 38.650001525878906,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "102046",
      "nome": "Vazzano",
      "nomeStraniero": null,
      "codiceCatastale": "L699",
      "cap": "89834",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "segreteriavazzano@libero.it",
      "pec": "prot.vazzano@asmepec.it",
      "telefono": "+39 0963 358089",
      "fax": "+39 0963 358732",
      "coordinate": {
        "lat": 38.63333511352539,
        "lng": 16.25
      }
    },
    {
      "codice": "102047",
      "nome": "Vibo Valentia",
      "nomeStraniero": null,
      "codiceCatastale": "F537",
      "cap": "89900",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "segreteriasindaco@comune.vibovalentia.vv.it",
      "pec": "protocollo@comunevibovalentia.postecert.it",
      "telefono": "+39 0963 599111",
      "fax": "+39 0963 599214",
      "coordinate": {
        "lat": 38.67527770996094,
        "lng": 16.09589958190918
      }
    },
    {
      "codice": "102048",
      "nome": "Zaccanopoli",
      "nomeStraniero": null,
      "codiceCatastale": "M138",
      "cap": "89867",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "anagrafe@comune.zaccanopoli.vv.it",
      "pec": "prot.zaccanopoli@asmepec.it",
      "telefono": "+39 0963600251",
      "fax": "+39 0963601956",
      "coordinate": {
        "lat": 38.66666793823242,
        "lng": 15.933333396911621
      }
    },
    {
      "codice": "102049",
      "nome": "Zambrone",
      "nomeStraniero": null,
      "codiceCatastale": "M143",
      "cap": "89868",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "comunedizambrone@virgilio.it",
      "pec": "amministrativozambrone@asmepec.it",
      "telefono": "+39 0963/392022",
      "fax": "+39 0963/392023",
      "coordinate": {
        "lat": 38.70000076293945,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "102050",
      "nome": "Zungri",
      "nomeStraniero": null,
      "codiceCatastale": "M204",
      "cap": "89867",
      "prefisso": "0963",
      "provincia": "Vibo Valentia",
      "email": "ragioneriazungri@libero.it",
      "pec": "segreteria.zungri@asmepec.it",
      "telefono": "+39 0963/664015",
      "fax": "+39 0963/664454",
      "coordinate": {
        "lat": 38.650001525878906,
        "lng": 15.983333587646484
      }
    }
  ],
  "Ascoli Piceno": [
    {
      "codice": "044001",
      "nome": "Acquasanta Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A044",
      "cap": "63095",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "anagrafeacquasanta@libero.it",
      "pec": "comune.acquasantaterme@anutel.it",
      "telefono": "+39 0736 801262",
      "fax": "+39 0736/801407",
      "coordinate": {
        "lat": 42.76919937133789,
        "lng": 13.409449577331543
      }
    },
    {
      "codice": "044002",
      "nome": "Acquaviva Picena",
      "nomeStraniero": null,
      "codiceCatastale": "A047",
      "cap": "63075",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "anagrafe@comuneacquavivapicena.it",
      "pec": "protocollo@pec.comuneacquavivapicena.it",
      "telefono": "+39 0735/764005",
      "fax": "+39 0735/764643",
      "coordinate": {
        "lat": 42.94470977783203,
        "lng": 13.813549995422363
      }
    },
    {
      "codice": "044005",
      "nome": "Appignano del Tronto",
      "nomeStraniero": null,
      "codiceCatastale": "A335",
      "cap": "63083",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "comuneappignanoseg@libero.it",
      "pec": "comuneappignanodeltronto@pec.it",
      "telefono": "+39 0736/817725",
      "fax": "+39 0736/817731",
      "coordinate": {
        "lat": 42.8990592956543,
        "lng": 13.66598892211914
      }
    },
    {
      "codice": "044006",
      "nome": "Arquata del Tronto",
      "nomeStraniero": null,
      "codiceCatastale": "A437",
      "cap": "63096",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "segreteria@comune.arquatadeltronto.ap.it",
      "pec": "comune.arquatadeltronto@emarche.it",
      "telefono": "+39 0736/809122",
      "fax": "+39 0736/809255",
      "coordinate": {
        "lat": 42.77256393432617,
        "lng": 13.296643257141113
      }
    },
    {
      "codice": "044007",
      "nome": "Ascoli Piceno",
      "nomeStraniero": null,
      "codiceCatastale": "A462",
      "cap": "63100",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "protocollo@comune.ascolipiceno.it",
      "pec": "comune.ascolipiceno@actaliscertymail.it",
      "telefono": "+39 0736/298563",
      "fax": "+39 0736/250167",
      "coordinate": {
        "lat": 42.85472106933594,
        "lng": 13.575277328491211
      }
    },
    {
      "codice": "044010",
      "nome": "Carassai",
      "nomeStraniero": null,
      "codiceCatastale": "B727",
      "cap": "63063",
      "prefisso": "0734",
      "provincia": "Ascoli Piceno",
      "email": "anagrafe@comune.carassai.ap.it",
      "pec": "protocollo@pec.comune.carassai.ap.it",
      "telefono": "+39 0734/919002",
      "fax": "+39 0734/919003",
      "coordinate": {
        "lat": 43.03218460083008,
        "lng": 13.683730125427246
      }
    },
    {
      "codice": "044011",
      "nome": "Castel di Lama",
      "nomeStraniero": null,
      "codiceCatastale": "C093",
      "cap": "63082",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "rita.cherubini@comune.casteldima.ap.it",
      "pec": "servizi.demografici@pec.comune.casteldilama.ap.it",
      "telefono": "+39 073681871",
      "fax": "+39 0736/818734",
      "coordinate": {
        "lat": 42.86955261230469,
        "lng": 13.713299751281738
      }
    },
    {
      "codice": "044012",
      "nome": "Castignano",
      "nomeStraniero": null,
      "codiceCatastale": "C321",
      "cap": "63072",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "com.castignano@provincia.ap.it",
      "pec": "comunecastignano.ap@pec.it",
      "telefono": "+39 0736-822128",
      "fax": "+39 0736/822086",
      "coordinate": {
        "lat": 42.93813705444336,
        "lng": 13.621944427490234
      }
    },
    {
      "codice": "044013",
      "nome": "Castorano",
      "nomeStraniero": null,
      "codiceCatastale": "C331",
      "cap": "63081",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "ufficio.anagrafe@comune.castorano.ap.it",
      "pec": "protocollo@pec.comune.castorano.ap.it",
      "telefono": "+39 0736/87132",
      "fax": "+39 0736/87227",
      "coordinate": {
        "lat": 42.898345947265625,
        "lng": 13.727569580078125
      }
    },
    {
      "codice": "044014",
      "nome": "Colli del Tronto",
      "nomeStraniero": null,
      "codiceCatastale": "C877",
      "cap": "63079",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "comune@comune.collideltronto.ap.it",
      "pec": "comune.collideltronto@postcert.it",
      "telefono": "+39 0736 890626",
      "fax": "+39 0736 890831",
      "coordinate": {
        "lat": 42.8780517578125,
        "lng": 13.748200416564941
      }
    },
    {
      "codice": "044015",
      "nome": "Comunanza",
      "nomeStraniero": null,
      "codiceCatastale": "C935",
      "cap": "63087",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "anagrafe@comune.comunanza.ap.it",
      "pec": "protocollo@pec.comune.comunanza.ap.it",
      "telefono": "+39 0736/843825",
      "fax": "+39 0736/843835",
      "coordinate": {
        "lat": 42.957244873046875,
        "lng": 13.414569854736328
      }
    },
    {
      "codice": "044016",
      "nome": "Cossignano",
      "nomeStraniero": null,
      "codiceCatastale": "D096",
      "cap": "63067",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "demografici@comune.cossignano.ap.it",
      "pec": "protocollo@pec.comune.cossignano.ap.it",
      "telefono": "+39 0735/98130",
      "fax": "+39 0735/987228",
      "coordinate": {
        "lat": 42.98398971557617,
        "lng": 13.688080787658691
      }
    },
    {
      "codice": "044017",
      "nome": "Cupra Marittima",
      "nomeStraniero": null,
      "codiceCatastale": "D210",
      "cap": "63064",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "info@comune.cupra-marittima.ap.it",
      "pec": "comune.cupramarittima@emarche.it",
      "telefono": "+39 0735/77671",
      "fax": "+39 0735/777970",
      "coordinate": {
        "lat": 43.02491760253906,
        "lng": 13.858888626098633
      }
    },
    {
      "codice": "044020",
      "nome": "Folignano",
      "nomeStraniero": null,
      "codiceCatastale": "D652",
      "cap": "63084",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "segretario@comune.folignano.ap.it",
      "pec": "comune.folignano@emarche.it",
      "telefono": "+39 073639971",
      "fax": "+39 0736492086",
      "coordinate": {
        "lat": 42.82099914550781,
        "lng": 13.632949829101562
      }
    },
    {
      "codice": "044021",
      "nome": "Force",
      "nomeStraniero": null,
      "codiceCatastale": "D691",
      "cap": "63086",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "segreteria@comune.force.ap.it",
      "pec": "segreteria@pec.comune.force.ap.it",
      "telefono": "+39 0736 373132",
      "fax": "+39 0736 373120",
      "coordinate": {
        "lat": 42.96332931518555,
        "lng": 13.4910306930542
      }
    },
    {
      "codice": "044023",
      "nome": "Grottammare",
      "nomeStraniero": null,
      "codiceCatastale": "E207",
      "cap": "63066",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "protocollo@comune.grottammare.ap.it",
      "pec": "comune.grottammare.protocollo@emarche.it",
      "telefono": "+39 0735.7391",
      "fax": "+39 0735 739207",
      "coordinate": {
        "lat": 42.98910140991211,
        "lng": 13.868069648742676
      }
    },
    {
      "codice": "044027",
      "nome": "Maltignano",
      "nomeStraniero": null,
      "codiceCatastale": "E868",
      "cap": "63085",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "paola.demidio@comune.maltignano.ap.it",
      "pec": "anagrafe.comune.maltignano@emarche.it",
      "telefono": "+39 0736/304122",
      "fax": "+39 0736/304463",
      "coordinate": {
        "lat": 42.831600189208984,
        "lng": 13.687138557434082
      }
    },
    {
      "codice": "044029",
      "nome": "Massignano",
      "nomeStraniero": null,
      "codiceCatastale": "F044",
      "cap": "63061",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "commass.giorgio@regione.marche.it",
      "pec": "comune.massignano@emarche.it",
      "telefono": "+39 0735/72312",
      "fax": "+39 0735/72312",
      "coordinate": {
        "lat": 43.05088806152344,
        "lng": 13.797310829162598
      }
    },
    {
      "codice": "044031",
      "nome": "Monsampolo del Tronto",
      "nomeStraniero": null,
      "codiceCatastale": "F380",
      "cap": "63077",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "protocollo@comune.monsampolodeltronto.ap.it",
      "pec": "comune.monsampolodeltronto@pec.it",
      "telefono": "+39 0735/704116",
      "fax": "+39 0735/706004",
      "coordinate": {
        "lat": 42.89786911010742,
        "lng": 13.793980598449707
      }
    },
    {
      "codice": "044032",
      "nome": "Montalto delle Marche",
      "nomeStraniero": null,
      "codiceCatastale": "F415",
      "cap": "63068",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "comune@comune.montaltodellemarche.ap.it",
      "pec": "com.montalto.ap@emarche.it",
      "telefono": "+39 0736/828015",
      "fax": "+39 0736/828002",
      "coordinate": {
        "lat": 42.98725128173828,
        "lng": 13.606852531433105
      }
    },
    {
      "codice": "044034",
      "nome": "Montedinove",
      "nomeStraniero": null,
      "codiceCatastale": "F487",
      "cap": "63069",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "comune.montedinove@gmail.com",
      "pec": "comune.montedinove@emarche.it",
      "telefono": "+39 0736/829410",
      "fax": "+39 0736/610934",
      "coordinate": {
        "lat": 42.97066879272461,
        "lng": 13.586999893188477
      }
    },
    {
      "codice": "044036",
      "nome": "Montefiore dell'Aso",
      "nomeStraniero": null,
      "codiceCatastale": "F501",
      "cap": "63062",
      "prefisso": "0734",
      "provincia": "Ascoli Piceno",
      "email": "demografici@comune.montefioredellaso.ap.it",
      "pec": "segreteriamfa@emarche.it",
      "telefono": "+39 0734/933019",
      "fax": "+39 0734/939074",
      "coordinate": {
        "lat": 43.05166244506836,
        "lng": 13.751411437988281
      }
    },
    {
      "codice": "044038",
      "nome": "Montegallo",
      "nomeStraniero": null,
      "codiceCatastale": "F516",
      "cap": "63094",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "infoposta@comune.montegallo.ap.it",
      "pec": "comune.montegallo@emarche.it",
      "telefono": "+39 0736 806122",
      "fax": "+39 0736 806102",
      "coordinate": {
        "lat": 42.84210968017578,
        "lng": 13.332783699035645
      }
    },
    {
      "codice": "044044",
      "nome": "Montemonaco",
      "nomeStraniero": null,
      "codiceCatastale": "F570",
      "cap": "63088",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "protocollo@comunemontemonaco.it",
      "pec": "comunemontemonaco.protocollo@pec.it",
      "telefono": "+39 0736/856141",
      "fax": "+39 0736/856370",
      "coordinate": {
        "lat": 42.899166107177734,
        "lng": 13.33388900756836
      }
    },
    {
      "codice": "044045",
      "nome": "Monteprandone",
      "nomeStraniero": null,
      "codiceCatastale": "F591",
      "cap": "63076",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "direzione@comune.monteprandone.ap.it",
      "pec": "comune.monteprandone@emarche.it",
      "telefono": "+39 0735-71091",
      "fax": "+39 0735-62541",
      "coordinate": {
        "lat": 42.92025375366211,
        "lng": 13.835519790649414
      }
    },
    {
      "codice": "044054",
      "nome": "Offida",
      "nomeStraniero": null,
      "codiceCatastale": "G005",
      "cap": "63073",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "elettorale@comune.offida.ap.it",
      "pec": "protocollo@pec.comune.offida.ap.it",
      "telefono": "+39 0736/888709",
      "fax": "+39 0736/889648",
      "coordinate": {
        "lat": 42.93534851074219,
        "lng": 13.692000389099121
      }
    },
    {
      "codice": "044056",
      "nome": "Palmiano",
      "nomeStraniero": null,
      "codiceCatastale": "G289",
      "cap": "63092",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "ufficio.anagrafe@comune.palmiano.ap.it",
      "pec": "comune.palmiano@emarche.it",
      "telefono": "+39 0736/362142",
      "fax": "+39 0736/363228",
      "coordinate": {
        "lat": 42.899898529052734,
        "lng": 13.458700180053711
      }
    },
    {
      "codice": "044063",
      "nome": "Ripatransone",
      "nomeStraniero": null,
      "codiceCatastale": "H321",
      "cap": "63065",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "elettorale@comune.ripatransone.ap.it",
      "pec": "protocollo@pec.comune.ripatransone.ap.it",
      "telefono": "+39 0735/917318",
      "fax": "+39 0735/917331",
      "coordinate": {
        "lat": 43.00021743774414,
        "lng": 13.762491226196289
      }
    },
    {
      "codice": "044064",
      "nome": "Roccafluvione",
      "nomeStraniero": null,
      "codiceCatastale": "H390",
      "cap": "63093",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "anag.roccafluvione@tiscali.it",
      "pec": "comune.roccafluvione@emarche.it",
      "telefono": "+39 0736/365131",
      "fax": "+39 0736/365202",
      "coordinate": {
        "lat": 42.84779739379883,
        "lng": 13.483360290527344
      }
    },
    {
      "codice": "044065",
      "nome": "Rotella",
      "nomeStraniero": null,
      "codiceCatastale": "H588",
      "cap": "63071",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "com.rotella@tiscali.it",
      "pec": "protocollo.comune.rotella@pec.it",
      "telefono": "+39 0736/374122",
      "fax": "+39 0736/374594",
      "coordinate": {
        "lat": 42.95457077026367,
        "lng": 13.56095027923584
      }
    },
    {
      "codice": "044066",
      "nome": "San Benedetto del Tronto",
      "nomeStraniero": null,
      "codiceCatastale": "H769",
      "cap": "63074",
      "prefisso": "0735",
      "provincia": "Ascoli Piceno",
      "email": "comunesbt@comunesbt.it",
      "pec": "protocollo@cert-sbt.it",
      "telefono": "+39 07357941",
      "fax": "+39 0735794542",
      "coordinate": {
        "lat": 42.94377899169922,
        "lng": 13.883330345153809
      }
    },
    {
      "codice": "044071",
      "nome": "Spinetoli",
      "nomeStraniero": null,
      "codiceCatastale": "I912",
      "cap": "63078",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "filippo.galletti@comune.spinetoli.ap.it",
      "pec": "protocollo@pec.comune.spinetoli.ap.it",
      "telefono": "+39 0736/890298",
      "fax": "+39 0736/890349",
      "coordinate": {
        "lat": 42.88865661621094,
        "lng": 13.773069381713867
      }
    },
    {
      "codice": "044073",
      "nome": "Venarotta",
      "nomeStraniero": null,
      "codiceCatastale": "L728",
      "cap": "63091",
      "prefisso": "0736",
      "provincia": "Ascoli Piceno",
      "email": "anagrafe@comune.venarotta.ap.it",
      "pec": "comune.venarotta@emarche.it",
      "telefono": "+39 0736/362132",
      "fax": "+39 0736/362896",
      "coordinate": {
        "lat": 42.881919860839844,
        "lng": 13.49436092376709
      }
    }
  ],
  "Terni": [
    {
      "codice": "055001",
      "nome": "Acquasparta",
      "nomeStraniero": null,
      "codiceCatastale": "A045",
      "cap": "05021",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "segreteria@comune.acquasparta.tr.it",
      "pec": "comune.acquasparta@postacert.umbria.it",
      "telefono": "+39 0744-944811",
      "fax": "+39 0744-944420",
      "coordinate": {
        "lat": 42.690670013427734,
        "lng": 12.546326637268066
      }
    },
    {
      "codice": "055002",
      "nome": "Allerona",
      "nomeStraniero": null,
      "codiceCatastale": "A207",
      "cap": "05011",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "allerona.segretario@virgilio.it",
      "pec": "comune.allerona@postacert.umbria.it",
      "telefono": "+39 0763/628312",
      "fax": "+39 0763/628117",
      "coordinate": {
        "lat": 42.81666564941406,
        "lng": 11.966666221618652
      }
    },
    {
      "codice": "055003",
      "nome": "Alviano",
      "nomeStraniero": null,
      "codiceCatastale": "A242",
      "cap": "05020",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comunedialviano.it",
      "pec": "comune.alviano@postacert.umbria.it",
      "telefono": "+39 3289039702",
      "fax": "+39 0744/904678",
      "coordinate": {
        "lat": 42.58333206176758,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "055004",
      "nome": "Amelia",
      "nomeStraniero": null,
      "codiceCatastale": "A262",
      "cap": "05022",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "segretario@comune.amelia.tr.it",
      "pec": "comune.amelia@postacert.umbria.it",
      "telefono": "+39 0744 9761",
      "fax": "+39 0744 982237",
      "coordinate": {
        "lat": 42.55352783203125,
        "lng": 12.416775703430176
      }
    },
    {
      "codice": "055005",
      "nome": "Arrone",
      "nomeStraniero": null,
      "codiceCatastale": "A439",
      "cap": "05031",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "costanza.fioretti@comune.arrone.terni.it",
      "pec": "comune.arrone@postacert.umbria.it",
      "telefono": "+39 0744/387625",
      "fax": "+39 0744/387140",
      "coordinate": {
        "lat": 42.583683013916016,
        "lng": 12.767999649047852
      }
    },
    {
      "codice": "055006",
      "nome": "Attigliano",
      "nomeStraniero": null,
      "codiceCatastale": "A490",
      "cap": "05012",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "comune.attigl@tiscali.it",
      "pec": "comune.attigliano@postacert.umbria.it",
      "telefono": "+39 0744/994224",
      "fax": "+39 0744/994014",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "055033",
      "nome": "Avigliano Umbro",
      "nomeStraniero": null,
      "codiceCatastale": "M258",
      "cap": "05020",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "sindaco@comune.aviglianoumbro.tr.it",
      "pec": "comune.aviglianoumbro@postacert.umbria.it",
      "telefono": "+39 0744/933521",
      "fax": "+39 0744/933387",
      "coordinate": {
        "lat": 42.650001525878906,
        "lng": 12.433333396911621
      }
    },
    {
      "codice": "055007",
      "nome": "Baschi",
      "nomeStraniero": null,
      "codiceCatastale": "A691",
      "cap": "05023",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.baschi.tr.it",
      "pec": "comune.baschi@postacert.umbria.it",
      "telefono": "+39 0744/957225",
      "fax": "+39 0744/957333",
      "coordinate": {
        "lat": 42.66666793823242,
        "lng": 12.216666221618652
      }
    },
    {
      "codice": "055008",
      "nome": "Calvi dell'Umbria",
      "nomeStraniero": null,
      "codiceCatastale": "B446",
      "cap": "05032",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.calvidellumbria.tr.it",
      "pec": "comune.calvidellumbria@postacert.umbria.it",
      "telefono": "+39 0744 710158",
      "fax": "+39 0744 710159",
      "coordinate": {
        "lat": 42.40409851074219,
        "lng": 12.568699836730957
      }
    },
    {
      "codice": "055009",
      "nome": "Castel Giorgio",
      "nomeStraniero": null,
      "codiceCatastale": "C117",
      "cap": "05013",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "comune.castelgiorgio@virgilio.it",
      "pec": "comune.castelgiorgio@postacert.umbria.it",
      "telefono": "+39 0763 627013-627093",
      "fax": "+39 0763 627157",
      "coordinate": {
        "lat": 42.70000076293945,
        "lng": 11.983333587646484
      }
    },
    {
      "codice": "055010",
      "nome": "Castel Viscardo",
      "nomeStraniero": null,
      "codiceCatastale": "C289",
      "cap": "05014",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "castelviscardo.comune@virgilio.it",
      "pec": "comune.castelviscardo@postacert.umbria.it",
      "telefono": "+39 0763/361010",
      "fax": "+39 0763/361621",
      "coordinate": {
        "lat": 42.755279541015625,
        "lng": 12.001669883728027
      }
    },
    {
      "codice": "055011",
      "nome": "Fabro",
      "nomeStraniero": null,
      "codiceCatastale": "D454",
      "cap": "05015",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "sociale@comune.fabro.tr.it",
      "pec": "comune.fabro@postacert.umbria.it",
      "telefono": "+39 0763/831020",
      "fax": "+39 0763/831120",
      "coordinate": {
        "lat": 42.86666488647461,
        "lng": 12.016666412353516
      }
    },
    {
      "codice": "055012",
      "nome": "Ferentillo",
      "nomeStraniero": null,
      "codiceCatastale": "D538",
      "cap": "05034",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "ufficio.anagrafe@comune.ferentillo.tr.it",
      "pec": "comune.ferentillo@postacert.umbria.it",
      "telefono": "+39 0744.780234",
      "fax": "+39 0744.780234",
      "coordinate": {
        "lat": 42.62138748168945,
        "lng": 12.790277481079102
      }
    },
    {
      "codice": "055013",
      "nome": "Ficulle",
      "nomeStraniero": null,
      "codiceCatastale": "D570",
      "cap": "05016",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "tecnico@comune.ficulle.tr.it",
      "pec": "comune.ficulle@postacert.umbria.it",
      "telefono": "+39 0763/86031",
      "fax": "+39 0763/86311",
      "coordinate": {
        "lat": 42.83333206176758,
        "lng": 12.066666603088379
      }
    },
    {
      "codice": "055014",
      "nome": "Giove",
      "nomeStraniero": null,
      "codiceCatastale": "E045",
      "cap": "05024",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.giove.tr.it",
      "pec": "comune.giove@postacert.umbria.it",
      "telefono": "+39 0744/992928",
      "fax": "+39 0744/992357",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 12.333333015441895
      }
    },
    {
      "codice": "055015",
      "nome": "Guardea",
      "nomeStraniero": null,
      "codiceCatastale": "E241",
      "cap": "05025",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comunediguardea.it",
      "pec": "comune.guardea@postacert.umbria.it",
      "telefono": "+39 0744/903521",
      "fax": "+39 0744/903529",
      "coordinate": {
        "lat": 42.61666488647461,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "055016",
      "nome": "Lugnano in Teverina",
      "nomeStraniero": null,
      "codiceCatastale": "E729",
      "cap": "05020",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "oriana.fantauzzi@comune.lugnanointeverina.tr.it",
      "pec": "comune.lugnanointeverina@postacert.umbria.it",
      "telefono": "+39 0744/902321",
      "fax": "+39 0744/902322",
      "coordinate": {
        "lat": 42.56666564941406,
        "lng": 12.333333015441895
      }
    },
    {
      "codice": "055017",
      "nome": "Montecastrilli",
      "nomeStraniero": null,
      "codiceCatastale": "F457",
      "cap": "05026",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "segreteria@comune.montecastrilli.tr.it",
      "pec": "comune.montecastrilli@postacert.umbria.it",
      "telefono": "+39 0744 9479204",
      "fax": "+39 0744 940155",
      "coordinate": {
        "lat": 42.650001525878906,
        "lng": 12.483333587646484
      }
    },
    {
      "codice": "055018",
      "nome": "Montecchio",
      "nomeStraniero": null,
      "codiceCatastale": "F462",
      "cap": "05020",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.montecchio.tr.it",
      "pec": "comune.montecchio@postacert.umbria.it",
      "telefono": "+39 0744/955715",
      "fax": "+39 0744/951766",
      "coordinate": {
        "lat": 42.66233825683594,
        "lng": 12.288029670715332
      }
    },
    {
      "codice": "055019",
      "nome": "Montefranco",
      "nomeStraniero": null,
      "codiceCatastale": "F510",
      "cap": "05030",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "comune.montefranco@tin.it",
      "pec": "comune.montefranco@postacert.umbria.it",
      "telefono": "+39 0744/388523",
      "fax": "+39 0744/387231",
      "coordinate": {
        "lat": 42.599998474121094,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "055020",
      "nome": "Montegabbione",
      "nomeStraniero": null,
      "codiceCatastale": "F513",
      "cap": "05010",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "protocollo@comune.montegabbione.tr.it",
      "pec": "comune.montegabbione@postacert.umbria.it",
      "telefono": "+39 0763 837521 837222",
      "fax": "+39 0763 /837497",
      "coordinate": {
        "lat": 42.91666793823242,
        "lng": 12.083333015441895
      }
    },
    {
      "codice": "055021",
      "nome": "Monteleone d'Orvieto",
      "nomeStraniero": null,
      "codiceCatastale": "F543",
      "cap": "05017",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "comune@comune.monteleone.tr.it",
      "pec": "comune.monteleonedorvieto@postacert.umbria.it",
      "telefono": "+39 0763/834021",
      "fax": "+39 0763/834280",
      "coordinate": {
        "lat": 42.91666793823242,
        "lng": 12.050000190734863
      }
    },
    {
      "codice": "055022",
      "nome": "Narni",
      "nomeStraniero": null,
      "codiceCatastale": "F844",
      "cap": "05035",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.narni.tr.it",
      "pec": "comune.narni@postacert.umbria.it",
      "telefono": "+39 0744/747211",
      "fax": "+39 0744/715270",
      "coordinate": {
        "lat": 42.516666412353516,
        "lng": 12.516666412353516
      }
    },
    {
      "codice": "055023",
      "nome": "Orvieto",
      "nomeStraniero": null,
      "codiceCatastale": "G148",
      "cap": "05018",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "info@comune.orvieto.tr.it",
      "pec": "comune.orvieto@postacert.umbria.it",
      "telefono": "+39 0763/306214",
      "fax": "+39 0763/306321",
      "coordinate": {
        "lat": 42.71666717529297,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "055024",
      "nome": "Otricoli",
      "nomeStraniero": null,
      "codiceCatastale": "G189",
      "cap": "05030",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.otricoli.tr.it",
      "pec": "comune.otricoli@postacert.umbria.it",
      "telefono": "+39 0744/719628",
      "fax": "+39 0744/719641",
      "coordinate": {
        "lat": 42.41666793823242,
        "lng": 12.483333587646484
      }
    },
    {
      "codice": "055025",
      "nome": "Parrano",
      "nomeStraniero": null,
      "codiceCatastale": "G344",
      "cap": "05010",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "info@comune.parrano.tr.it",
      "pec": "comune.parrano@postacert.umbria.it",
      "telefono": "+39 0763 838751",
      "fax": "+39 0763 838520",
      "coordinate": {
        "lat": 42.86666488647461,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "055026",
      "nome": "Penna in Teverina",
      "nomeStraniero": null,
      "codiceCatastale": "G432",
      "cap": "05028",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "comune@pennainteverina.com",
      "pec": "comune.pennainteverina@postacert.umbria.it",
      "telefono": "+39 0744/993326",
      "fax": "+39 0744/993327",
      "coordinate": {
        "lat": 42.5,
        "lng": 12.366666793823242
      }
    },
    {
      "codice": "055027",
      "nome": "Polino",
      "nomeStraniero": null,
      "codiceCatastale": "G790",
      "cap": "05030",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "comune.polino@virgilio.it",
      "pec": "comune.polino@postacert.umbria.it",
      "telefono": "+39 0744 789121",
      "fax": "+39 0744 789423",
      "coordinate": {
        "lat": 42.56666564941406,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "055028",
      "nome": "Porano",
      "nomeStraniero": null,
      "codiceCatastale": "G881",
      "cap": "05010",
      "prefisso": "0763",
      "provincia": "Terni",
      "email": "segretario@comune.porano.tr.it",
      "pec": "comune.porano@postacert.umbria.it",
      "telefono": "+39 0763 374462",
      "fax": "+39 0763 374108",
      "coordinate": {
        "lat": 42.68333435058594,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "055029",
      "nome": "San Gemini",
      "nomeStraniero": null,
      "codiceCatastale": "H857",
      "cap": "05029",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "comune.sangemini@comune.sangemini.tr.it",
      "pec": "comune.sangemini@postacert.umbria.it",
      "telefono": "+39 0744/334911",
      "fax": "+39 0744/630324",
      "coordinate": {
        "lat": 42.61307907104492,
        "lng": 12.547028541564941
      }
    },
    {
      "codice": "055030",
      "nome": "San Venanzo",
      "nomeStraniero": null,
      "codiceCatastale": "I381",
      "cap": "05010",
      "prefisso": "075",
      "provincia": "Terni",
      "email": "info@comune.sanvenanzo.tr.it",
      "pec": "comune.sanvenanzo@postacert.umbria.it",
      "telefono": "+39 075/875123",
      "fax": "+39 075/875407",
      "coordinate": {
        "lat": 42.86666488647461,
        "lng": 12.266666412353516
      }
    },
    {
      "codice": "055031",
      "nome": "Stroncone",
      "nomeStraniero": null,
      "codiceCatastale": "I981",
      "cap": "05039",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "info@comune.stroncone.tr.it",
      "pec": "comune.stroncone@postacert.umbria.it",
      "telefono": "+39 0744 6098",
      "fax": "+39 0744/609500",
      "coordinate": {
        "lat": 42.5,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "055032",
      "nome": "Terni",
      "nomeStraniero": null,
      "codiceCatastale": "L117",
      "cap": "05100",
      "prefisso": "0744",
      "provincia": "Terni",
      "email": "sportello.cittadino@comune.terni.it",
      "pec": "comune.terni@postacert.umbria.it",
      "telefono": "+39 0744 5491",
      "fax": "+39 0744 549616",
      "coordinate": {
        "lat": 42.56666564941406,
        "lng": 12.649999618530273
      }
    }
  ],
  "Campobasso": [
    {
      "codice": "070001",
      "nome": "Acquaviva Collecroce",
      "nomeStraniero": null,
      "codiceCatastale": "A050",
      "cap": "86030",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "collecroce@kruc.it",
      "pec": "comune.acquavivacollecrocecb@legalmail.it",
      "telefono": "+39 0875/970128",
      "fax": "+39 0875/970270",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 14.75
      }
    },
    {
      "codice": "070002",
      "nome": "Baranello",
      "nomeStraniero": null,
      "codiceCatastale": "A616",
      "cap": "86011",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunedibaranello.cb@virgilio.it",
      "pec": "comune.baranellocb@legalmail.it",
      "telefono": "+39 0874 460406",
      "fax": "+39 0874 460828",
      "coordinate": {
        "lat": 41.53333282470703,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "070003",
      "nome": "Bojano",
      "nomeStraniero": null,
      "codiceCatastale": "A930",
      "cap": "86021",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "anagrafe@comune.bojano.cb.it",
      "pec": "protocollo@pec.comune.bojano.cb.it",
      "telefono": "+39 087477281",
      "fax": "+39 0874/773396",
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "070004",
      "nome": "Bonefro",
      "nomeStraniero": null,
      "codiceCatastale": "A971",
      "cap": "86041",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunedibonefro@tin.it",
      "pec": "comune.bonefrocb@legalmail.it",
      "telefono": "+39 0874/732712",
      "fax": "+39 0874/732232",
      "coordinate": {
        "lat": 41.704166412353516,
        "lng": 14.935832977294922
      }
    },
    {
      "codice": "070005",
      "nome": "Busso",
      "nomeStraniero": null,
      "codiceCatastale": "B295",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.busso@virgilio.it",
      "pec": "comune.bussocb@legalmail.it",
      "telefono": "+39 0874/447133",
      "fax": null,
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "070006",
      "nome": "Campobasso",
      "nomeStraniero": null,
      "codiceCatastale": "B519",
      "cap": "86100",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "stelvio.bagnoli@comune.campobasso.it",
      "pec": "comune.campobasso.protocollo@pec.it",
      "telefono": "+39 0874/405478",
      "fax": null,
      "coordinate": {
        "lat": 41.56100082397461,
        "lng": 14.668399810791016
      }
    },
    {
      "codice": "070007",
      "nome": "Campochiaro",
      "nomeStraniero": null,
      "codiceCatastale": "B522",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.campochiaro@virgilio.it",
      "pec": "comune.campochiarocb@legalmail.it",
      "telefono": "+39 0874/789131",
      "fax": "+39 0874/789270",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "070008",
      "nome": "Campodipietra",
      "nomeStraniero": null,
      "codiceCatastale": "B528",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sindaco@comune.campodipietra.cb.it",
      "pec": "comune.campodipietracb@legalmail.it",
      "telefono": "+39 0874/441100",
      "fax": "+39 09874/441353",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 14.75
      }
    },
    {
      "codice": "070009",
      "nome": "Campolieto",
      "nomeStraniero": null,
      "codiceCatastale": "B544",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunecampolieto@virgilio.it",
      "pec": "comune.campolietocb@legalmail.it",
      "telefono": "+39 0874/52132",
      "fax": "+39 0874/528006",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "070010",
      "nome": "Campomarino",
      "nomeStraniero": null,
      "codiceCatastale": "B550",
      "cap": "86042",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "urp@comunecampomarino.it",
      "pec": "urp@pec.comunecampomarino.it",
      "telefono": "+39 0875/5311",
      "fax": "+39 0875/530004",
      "coordinate": {
        "lat": 41.9566650390625,
        "lng": 15.034444808959961
      }
    },
    {
      "codice": "070011",
      "nome": "Casacalenda",
      "nomeStraniero": null,
      "codiceCatastale": "B858",
      "cap": "86043",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunecasacalenda@libero.it",
      "pec": "info.casacalenda@pec.it",
      "telefono": "+39 0874/841237",
      "fax": "+39 0874/844010",
      "coordinate": {
        "lat": 41.741390228271484,
        "lng": 14.848889350891113
      }
    },
    {
      "codice": "070012",
      "nome": "Casalciprano",
      "nomeStraniero": null,
      "codiceCatastale": "B871",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunecasalciprano@libero.it",
      "pec": "comune.casalciprano@pec.it",
      "telefono": "0874504131",
      "fax": "0874504944",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "070013",
      "nome": "Castelbottaccio",
      "nomeStraniero": null,
      "codiceCatastale": "C066",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "castelbot@tiscali.it",
      "pec": "comune.castelbottacciocb@legalmail.it",
      "telefono": "+39 0874/746382",
      "fax": "+39 0874/747210",
      "coordinate": {
        "lat": 41.75,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "070014",
      "nome": "Castellino del Biferno",
      "nomeStraniero": null,
      "codiceCatastale": "C175",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "info@comune.castellinodelbiferno.cb.it",
      "pec": "comune.castellinodelbifernocb@legalmail.it",
      "telefono": "+39 0874 745148",
      "fax": "+39 0874 749027",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 14.733333587646484
      }
    },
    {
      "codice": "070015",
      "nome": "Castelmauro",
      "nomeStraniero": null,
      "codiceCatastale": "C197",
      "cap": "86031",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.castelmauro.cb.it",
      "pec": "comune.castelmauro@pec.leonet.it",
      "telefono": "+39 0874/744106",
      "fax": "+39 0874/744592",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 14.716666221618652
      }
    },
    {
      "codice": "070016",
      "nome": "Castropignano",
      "nomeStraniero": null,
      "codiceCatastale": "C346",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.castropignano@libero.it",
      "pec": "comune.castropignano@pec.it",
      "telefono": "+39 0874/503132",
      "fax": "+39 0874/503522",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "070017",
      "nome": "Cercemaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C486",
      "cap": "86012",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "riccardo.zappone@comune.cercemaggiore.cb.it",
      "pec": "comune.cercemaggiorecb@legalmail.it",
      "telefono": "+39 0874/799134",
      "fax": "+39 0874/799626",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 14.716666221618652
      }
    },
    {
      "codice": "070018",
      "nome": "Cercepiccola",
      "nomeStraniero": null,
      "codiceCatastale": "C488",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.cercepiccola.cb.it",
      "pec": "comune.cercepiccolacb@legalmail.it",
      "telefono": "+39 0874/79222",
      "fax": "+39 0874/797143",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "070019",
      "nome": "Civitacampomarano",
      "nomeStraniero": null,
      "codiceCatastale": "C764",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.civitacampomarano.cb.it",
      "pec": "comune.civitacampomaranocb@legalmail.it",
      "telefono": "+39 0874/748103",
      "fax": "+39 0874/748335",
      "coordinate": {
        "lat": 41.78333282470703,
        "lng": 14.683333396911621
      }
    },
    {
      "codice": "070020",
      "nome": "Colle d'Anchise",
      "nomeStraniero": null,
      "codiceCatastale": "C854",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "colle.danchise@tiscali.it",
      "pec": "comune.danchisecb@legalmail.it",
      "telefono": "+39 0874/786131",
      "fax": "+39 0874/786334",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "070021",
      "nome": "Colletorto",
      "nomeStraniero": null,
      "codiceCatastale": "C875",
      "cap": "86044",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sismacolletorto@libero.it",
      "pec": "comunecolletorto@postecert.it",
      "telefono": "+39 0874/73121",
      "fax": "+39 0874/730125",
      "coordinate": {
        "lat": 41.66388702392578,
        "lng": 14.974166870117188
      }
    },
    {
      "codice": "070022",
      "nome": "Duronia",
      "nomeStraniero": null,
      "codiceCatastale": "C772",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "duroniacomune@tiscali.it",
      "pec": "duroniacomune@postecert.it",
      "telefono": "+39 0874/769134",
      "fax": "+39 0874/761015",
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "070023",
      "nome": "Ferrazzano",
      "nomeStraniero": null,
      "codiceCatastale": "D550",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "anagrafe@comune.ferrazzano.cb.it",
      "pec": "comune.ferrazzanocb@legalmail.it",
      "telefono": "+39 0874/438926",
      "fax": "+39 0874/412722",
      "coordinate": {
        "lat": 41.53333282470703,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "070024",
      "nome": "Fossalto",
      "nomeStraniero": null,
      "codiceCatastale": "D737",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "fossalto@tiscali.it",
      "pec": "comune.fossaltocb@legalmail.it",
      "telefono": "+39 0874/768134",
      "fax": "+39 0874/768836",
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "070025",
      "nome": "Gambatesa",
      "nomeStraniero": null,
      "codiceCatastale": "D896",
      "cap": "86013",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunegambatesa@virgilio.it",
      "pec": "comunegambatesa@pec.leonet.it",
      "telefono": "+39 0874/719134",
      "fax": "+39 0874/719706",
      "coordinate": {
        "lat": 41.5,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "070026",
      "nome": "Gildone",
      "nomeStraniero": null,
      "codiceCatastale": "E030",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "anagrafegildone@virgilio.it",
      "pec": "comune.gildonecb@legalmail.it",
      "telefono": "+39 0874 457132",
      "fax": null,
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.733332633972168
      }
    },
    {
      "codice": "070027",
      "nome": "Guardialfiera",
      "nomeStraniero": null,
      "codiceCatastale": "E244",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comuneguardialfiera@virgilio.it",
      "pec": "comune.guardialfieracb@legalmail.it",
      "telefono": "+39 0874/840131",
      "fax": "+39 0874/840535",
      "coordinate": {
        "lat": 41.803611755371094,
        "lng": 14.793333053588867
      }
    },
    {
      "codice": "070028",
      "nome": "Guardiaregia",
      "nomeStraniero": null,
      "codiceCatastale": "E248",
      "cap": "86014",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.guardiaregia@virgilio.it",
      "pec": "comune.guardiaregiacb@legalmail.it",
      "telefono": "+39 0874/785131",
      "fax": "+39 0874/785279",
      "coordinate": {
        "lat": 41.43333435058594,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "070029",
      "nome": "Guglionesi",
      "nomeStraniero": null,
      "codiceCatastale": "E259",
      "cap": "86034",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "comunediguglionesi.comun@tin.it",
      "pec": "comune.guglionesi@pec.leonet.it",
      "telefono": "+39 0875/680521",
      "fax": "+39 0875/689391",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "070030",
      "nome": "Jelsi",
      "nomeStraniero": null,
      "codiceCatastale": "E381",
      "cap": "86015",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "segretario@comune.jelsi.cb.it",
      "pec": "comune.jelsicb@leqalmail.it",
      "telefono": "+39 0874/710134",
      "fax": "+39 0874/710539",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "070031",
      "nome": "Larino",
      "nomeStraniero": null,
      "codiceCatastale": "E456",
      "cap": "86035",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "larino.cb@tiscali.it",
      "pec": "comune.larinocb@legalmail.it",
      "telefono": "+39 0874 8281",
      "fax": "+39 0874/825093",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "070032",
      "nome": "Limosano",
      "nomeStraniero": null,
      "codiceCatastale": "E599",
      "cap": "86022",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunelimosano@hotmail.com",
      "pec": "comunelimosano.cb@postecert.it",
      "telefono": "+39 0874/701235",
      "fax": "+39 0874/702958",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "070033",
      "nome": "Lucito",
      "nomeStraniero": null,
      "codiceCatastale": "E722",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "lucito@tiscali.it",
      "pec": "comunelucito.utc@legalmail.it",
      "telefono": "+39 0874/747204",
      "fax": "+39 0874/746081",
      "coordinate": {
        "lat": 41.733333587646484,
        "lng": 14.683333396911621
      }
    },
    {
      "codice": "070034",
      "nome": "Lupara",
      "nomeStraniero": null,
      "codiceCatastale": "E748",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.lupara.cb@virgilio.it",
      "pec": "comune.luparacb@legalmail.it",
      "telefono": "+39 0874/741134",
      "fax": "+39 0874/741347",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 14.733333587646484
      }
    },
    {
      "codice": "070035",
      "nome": "Macchia Valfortore",
      "nomeStraniero": null,
      "codiceCatastale": "E780",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "macclav@tin.it",
      "pec": "comune.macchiavalfortorecb@legalmail.it",
      "telefono": "+39 0874/810131",
      "fax": "+39 0874/810531",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "070036",
      "nome": "Mafalda",
      "nomeStraniero": null,
      "codiceCatastale": "E799",
      "cap": "86030",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "info@comune.mafalda.cb.it",
      "pec": "comune.mafaldacb@legalmail.it",
      "telefono": "+39 0875/978135",
      "fax": "+39 0875/978193",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.716667175292969
      }
    },
    {
      "codice": "070037",
      "nome": "Matrice",
      "nomeStraniero": null,
      "codiceCatastale": "F055",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunedimatrice@gmail.com",
      "pec": "comune.matricecb@legalmail.it",
      "telefono": "+39 0874/453001",
      "fax": "+39 0874/453131",
      "coordinate": {
        "lat": 41.616668701171875,
        "lng": 14.716667175292969
      }
    },
    {
      "codice": "070038",
      "nome": "Mirabello Sannitico",
      "nomeStraniero": null,
      "codiceCatastale": "F233",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "info@comune.mirabellosannitico.cb.it",
      "pec": "comune.mirabellosanniticocb@legalmail.it",
      "telefono": "+39 087430132",
      "fax": "+39 0874/30103",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "070039",
      "nome": "Molise",
      "nomeStraniero": null,
      "codiceCatastale": "F294",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "urp@comune.molise.cb.it",
      "pec": "comune.molisecb@legalmail.it",
      "telefono": "+39 0874/76214",
      "fax": "+39 0874/76214",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 14.5
      }
    },
    {
      "codice": "070040",
      "nome": "Monacilioni",
      "nomeStraniero": null,
      "codiceCatastale": "F322",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "monacilioni@virgilio.it",
      "pec": "comunedimonacilioni@pec-leonet.it",
      "telefono": "+39 0874/520000",
      "fax": "+39 0874/520200",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "070041",
      "nome": "Montagano",
      "nomeStraniero": null,
      "codiceCatastale": "F391",
      "cap": "86023",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.montagano.cb.it",
      "pec": "comune.montaganocb@lpec.it",
      "telefono": "+39 0874/451130",
      "fax": "+39 0874/451130",
      "coordinate": {
        "lat": 41.646934509277344,
        "lng": 14.673059463500977
      }
    },
    {
      "codice": "070042",
      "nome": "Montecilfone",
      "nomeStraniero": null,
      "codiceCatastale": "F475",
      "cap": "86032",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "comune.montecilfone@tiscali.it",
      "pec": "comune.montecilfonecb@legalmail.it",
      "telefono": "+39 08745/979130",
      "fax": "+39 0875/979243",
      "coordinate": {
        "lat": 41.900001525878906,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "070043",
      "nome": "Montefalcone nel Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "F495",
      "cap": "86033",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "info@comune.montefalconenelsannio.cb.it",
      "pec": "comune.montefalconenelsannio@pec.leonet.it",
      "telefono": "+39 0874/877530",
      "fax": "+39 0874/877103",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "070044",
      "nome": "Montelongo",
      "nomeStraniero": null,
      "codiceCatastale": "F548",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunemontelongo@interfree.it",
      "pec": "comune.montelongocb@legalmail.it",
      "telefono": "+39 0874 838130",
      "fax": "+39 0874 838305",
      "coordinate": {
        "lat": 41.7370491027832,
        "lng": 14.950340270996094
      }
    },
    {
      "codice": "070045",
      "nome": "Montemitro",
      "nomeStraniero": null,
      "codiceCatastale": "F569",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.montemitro.cb.it",
      "pec": "comune.montemitro@pec.it",
      "telefono": "+39 0874 877259",
      "fax": "+39 0874 877259",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 14.649999618530273
      }
    },
    {
      "codice": "070046",
      "nome": "Montenero di Bisaccia",
      "nomeStraniero": null,
      "codiceCatastale": "F576",
      "cap": "86036",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "settoreaffarigeneralimontenero@gmail.com",
      "pec": "comune.montenerodibisacciacb@legalmail.it",
      "telefono": "+39 0875/959201",
      "fax": "+39 0875/959235",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.783333778381348
      }
    },
    {
      "codice": "070047",
      "nome": "Montorio nei Frentani",
      "nomeStraniero": null,
      "codiceCatastale": "F689",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.montoriofrent@tiscali.it",
      "pec": "comune.montorioneifrentanicb@legalmail.it",
      "telefono": "+39 0874/826132",
      "fax": "+39 0874/826132",
      "coordinate": {
        "lat": 41.75944519042969,
        "lng": 14.932777404785156
      }
    },
    {
      "codice": "070048",
      "nome": "Morrone del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "F748",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.morrone@libero.it",
      "pec": "comune.morronedelsanniocb@legalmail.it",
      "telefono": "+39 0874/848139",
      "fax": "+39 0874/848139",
      "coordinate": {
        "lat": 41.711387634277344,
        "lng": 14.779444694519043
      }
    },
    {
      "codice": "070049",
      "nome": "Oratino",
      "nomeStraniero": null,
      "codiceCatastale": "G086",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@oratino.it",
      "pec": "comune.oratinocb@legalmail.it",
      "telefono": "+39 0874/38418",
      "fax": null,
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 14.583333015441895
      }
    },
    {
      "codice": "070050",
      "nome": "Palata",
      "nomeStraniero": null,
      "codiceCatastale": "G257",
      "cap": "86037",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "anagrafe@comunedipalata.it",
      "pec": "comunedipalata@pec-leonet.it",
      "telefono": "+39 0875/969213",
      "fax": "+39 0875/969239",
      "coordinate": {
        "lat": 41.887901306152344,
        "lng": 14.787779808044434
      }
    },
    {
      "codice": "070051",
      "nome": "Petacciato",
      "nomeStraniero": null,
      "codiceCatastale": "G506",
      "cap": "86038",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "comunepetacciato@libero.it",
      "pec": "comunedipetacciato@pec-leonet.it",
      "telefono": "+39 0875/67337",
      "fax": "+39 0875/67341",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "070052",
      "nome": "Petrella Tifernina",
      "nomeStraniero": null,
      "codiceCatastale": "G512",
      "cap": "86024",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.petrellatifernina.cb.it",
      "pec": "comune.petrellatifernina@pec.leonet.it",
      "telefono": "+39 0874/745144",
      "fax": "+39 0874/745808",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "070053",
      "nome": "Pietracatella",
      "nomeStraniero": null,
      "codiceCatastale": "G609",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunedipietracatella@virgilio.it",
      "pec": "comune.pietracatella@pec.it",
      "telefono": "+39 0874817131",
      "fax": "+39 0874/817691",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "070054",
      "nome": "Pietracupa",
      "nomeStraniero": null,
      "codiceCatastale": "G610",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunedipietracupa@virgilio.it",
      "pec": "comunedipietracupa@postecert.it",
      "telefono": "+39 0874/768149",
      "fax": "+39 0874/768136",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "070055",
      "nome": "Portocannone",
      "nomeStraniero": null,
      "codiceCatastale": "G910",
      "cap": "86045",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "comune.portocannone@gmail.com",
      "pec": "comune.portocannonecb@legalmail.it",
      "telefono": "+39 0875/59884",
      "fax": "+39 0875/590021",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "070056",
      "nome": "Provvidenti",
      "nomeStraniero": null,
      "codiceCatastale": "H083",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comuneprovvidenti@alice.it",
      "pec": "comune.provvidenticb@legalmail.it",
      "telefono": "+39 0874/841495",
      "fax": "+39 0874/841495",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "070057",
      "nome": "Riccia",
      "nomeStraniero": null,
      "codiceCatastale": "H273",
      "cap": "86016",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "ragioneria@comunediriccia.it",
      "pec": "comune.ricciacb@legalmail.it",
      "telefono": "+39 0874/716631",
      "fax": "+39 0874/716513",
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "070058",
      "nome": "Ripabottoni",
      "nomeStraniero": null,
      "codiceCatastale": "H311",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "amministrazione@comuneripabottoni.it",
      "pec": "amministrazione@pec.comuneripabottoni.it",
      "telefono": "+39 0874/847373",
      "fax": "+39 0874/847103",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "070059",
      "nome": "Ripalimosani",
      "nomeStraniero": null,
      "codiceCatastale": "H313",
      "cap": "86025",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "ripalimosani@inwind.it",
      "pec": "comune.ripalimosanicb@legalmail.it",
      "telefono": "+39 0874 39132",
      "fax": "+39 0874 39736",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "070060",
      "nome": "Roccavivara",
      "nomeStraniero": null,
      "codiceCatastale": "H454",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "amministrazione@comune.roccavivara.cb.it",
      "pec": "comune.roccavivaracb@legalmail.it",
      "telefono": "+39 0874/875087",
      "fax": "+39 0874/875361",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "070061",
      "nome": "Rotello",
      "nomeStraniero": null,
      "codiceCatastale": "H589",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sinrotel@tin.it",
      "pec": "comunerotello-cb@pec.leonet.it",
      "telefono": "+39 0874/839131",
      "fax": "+39 0874/839640",
      "coordinate": {
        "lat": 41.747501373291016,
        "lng": 15.004166603088379
      }
    },
    {
      "codice": "070062",
      "nome": "Salcito",
      "nomeStraniero": null,
      "codiceCatastale": "H693",
      "cap": "86026",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "info@comunedisalcito.it",
      "pec": "postmaster@pec.comunedisalcito.it",
      "telefono": "+39 0874/878131",
      "fax": "+39 0874/878131",
      "coordinate": {
        "lat": 41.75,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "070063",
      "nome": "San Biase",
      "nomeStraniero": null,
      "codiceCatastale": "H782",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sanbiase070063@tiscali.it",
      "pec": "comunesanbiase.cb@postecert.it",
      "telefono": "+39 0874/701330",
      "fax": "+39 0874/702986",
      "coordinate": {
        "lat": 41.71525955200195,
        "lng": 14.590490341186523
      }
    },
    {
      "codice": "070064",
      "nome": "San Felice del Molise",
      "nomeStraniero": null,
      "codiceCatastale": "H833",
      "cap": "86030",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@sanfelicedelmolise.gov.it",
      "pec": "protocollo@pec.sanfelicedelmolise.gov.it",
      "telefono": "+39 0874/879113",
      "fax": "+39 0874/874533",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "070065",
      "nome": "San Giacomo degli Schiavoni",
      "nomeStraniero": null,
      "codiceCatastale": "H867",
      "cap": "86030",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "d.ausiello@comune.sangiacomo.cb.it",
      "pec": "comune.sangiacomo@pec.it",
      "telefono": "+39 0875 51130",
      "fax": "+39 0875 51130",
      "coordinate": {
        "lat": 41.96666717529297,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "070066",
      "nome": "San Giovanni in Galdo",
      "nomeStraniero": null,
      "codiceCatastale": "H920",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sangiovanniingaldo@tiscali.it",
      "pec": "comunesangiovanniingaldo@pec.leonet.it",
      "telefono": "+39 0874 461308",
      "fax": "+39 0874 461772",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 14.75
      }
    },
    {
      "codice": "070067",
      "nome": "San Giuliano del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "H928",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.sangiulianodelsannio.cb.it",
      "pec": "ufficioprotocollo@pec.comune.sangiulianodelsannio.cb.it",
      "telefono": "+39 0874/79204",
      "fax": "+39 0874/79566",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "070068",
      "nome": "San Giuliano di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "H929",
      "cap": "86040",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sangiulianodipuglia.cb@virgilio.it",
      "pec": "sangiulianodipuglia@pec.leonet.it",
      "telefono": "+39 0874/737517",
      "fax": "+39 0874/737813",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "070069",
      "nome": "San Martino in Pensilis",
      "nomeStraniero": null,
      "codiceCatastale": "H990",
      "cap": "86046",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "comune@comune.sanmartinoinpensilis.cb.it",
      "pec": "comune.sanmartinoinpensiliscb@legalmail.it",
      "telefono": "+39 0875 604243",
      "fax": "+39 0875 605507",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "070070",
      "nome": "San Massimo",
      "nomeStraniero": null,
      "codiceCatastale": "I023",
      "cap": "86027",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.sanmassimo@gmail.com",
      "pec": "comune.sanmassimo@pec.it",
      "telefono": "+39 0874/780132",
      "fax": null,
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "070071",
      "nome": "San Polo Matese",
      "nomeStraniero": null,
      "codiceCatastale": "I122",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "sanpolomatese@tiscali.it",
      "pec": "comune.sanpolomatesecb@legalmail.it",
      "telefono": "+39 0874 775135",
      "fax": "+39 0874 775075",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 14.5
      }
    },
    {
      "codice": "070073",
      "nome": "Sant'Angelo Limosano",
      "nomeStraniero": null,
      "codiceCatastale": "I289",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "santangelolimosano@alice.it",
      "pec": "comune.santangelolimosano@pec.leonet.it",
      "telefono": "+39 0874/701246",
      "fax": "+39 0874/701236",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "070074",
      "nome": "Sant'Elia a Pianisi",
      "nomeStraniero": null,
      "codiceCatastale": "I320",
      "cap": "86048",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.santeliaapianisi.cb.it",
      "pec": "comune.santeliaapianisi@legalmail.it",
      "telefono": "+39 0874 81205",
      "fax": "+39 0874 816400",
      "coordinate": {
        "lat": 41.62138748168945,
        "lng": 14.876111030578613
      }
    },
    {
      "codice": "070072",
      "nome": "Santa Croce di Magliano",
      "nomeStraniero": null,
      "codiceCatastale": "I181",
      "cap": "86047",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.santacrocedimagliano.cb.it",
      "pec": "santacrocedimagliano@halleycert.it",
      "telefono": "+39 0874/72521",
      "fax": "+39 0874/729773",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "070075",
      "nome": "Sepino",
      "nomeStraniero": null,
      "codiceCatastale": "I618",
      "cap": "86017",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "info@comune.sepino.it",
      "pec": "statocivileanagrafe@pec.comune.sepino.cb.it",
      "telefono": "+39 0874 790132",
      "fax": "+39 0874 790502",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "070076",
      "nome": "Spinete",
      "nomeStraniero": null,
      "codiceCatastale": "I910",
      "cap": "86020",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune.spinete@tiscali.it",
      "pec": "comune.spinetecb@legalmail.it",
      "telefono": "+39 0874/787131",
      "fax": "+39 0874/787515",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "070077",
      "nome": "Tavenna",
      "nomeStraniero": null,
      "codiceCatastale": "L069",
      "cap": "86030",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "amministrazione.tavenna@gmail.com",
      "pec": "comune.tavennacb@legalmail.it",
      "telefono": "+39 0875 97244",
      "fax": "+39 0875 97497",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "070078",
      "nome": "Termoli",
      "nomeStraniero": null,
      "codiceCatastale": "L113",
      "cap": "86039",
      "prefisso": "0875",
      "provincia": "Campobasso",
      "email": "termoli@comune.termoli.cb.it",
      "pec": "protocollo@pec.comune.termoli.cb.it",
      "telefono": "+39 0875/712203",
      "fax": "+39 0875/712261",
      "coordinate": {
        "lat": 42.002777099609375,
        "lng": 14.994722366333008
      }
    },
    {
      "codice": "070079",
      "nome": "Torella del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "L215",
      "cap": "86028",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "anagrafe@comune.torelladelsannio.cb.it",
      "pec": "comune.torelladelsanniocb@legalmail.it",
      "telefono": "+39 0874 76204",
      "fax": "+39 0874 76725",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "070080",
      "nome": "Toro",
      "nomeStraniero": null,
      "codiceCatastale": "L230",
      "cap": "86018",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "info@comune.toro.cb.it",
      "pec": "comune.torocb@legalmail.it",
      "telefono": "+39 0874/461101",
      "fax": "+39 0874/461730",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.766667366027832
      }
    },
    {
      "codice": "070081",
      "nome": "Trivento",
      "nomeStraniero": null,
      "codiceCatastale": "L435",
      "cap": "86029",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comunetrivento@tin.it",
      "pec": "protocollo.trivento@pec.it",
      "telefono": "+39 0874 87341",
      "fax": "+39 0874 871506",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "070082",
      "nome": "Tufara",
      "nomeStraniero": null,
      "codiceCatastale": "L458",
      "cap": "86010",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comuneditufara@virgilio.it",
      "pec": "comunetufara@pec.leonet.it",
      "telefono": "+39 0874 718121",
      "fax": "+39 0874 713595",
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "070083",
      "nome": "Ururi",
      "nomeStraniero": null,
      "codiceCatastale": "L505",
      "cap": "86049",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.ururi.cb.it",
      "pec": "comunediururi@comunediururi.postecert.it",
      "telefono": "+39 0874/830130",
      "fax": "+39 0874/830946",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "070084",
      "nome": "Vinchiaturo",
      "nomeStraniero": null,
      "codiceCatastale": "M057",
      "cap": "86019",
      "prefisso": "0874",
      "provincia": "Campobasso",
      "email": "comune@comune.vinchiaturo.cb.it",
      "pec": "comune.vinchiaturo@pec.it",
      "telefono": "+39 0874/34132",
      "fax": "+39 0874/34765",
      "coordinate": {
        "lat": 41.5,
        "lng": 14.583333015441895
      }
    }
  ],
  "Isernia": [
    {
      "codice": "094001",
      "nome": "Acquaviva d'Isernia",
      "nomeStraniero": null,
      "codiceCatastale": "A051",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "acquavivsfidrtnis@libero.it",
      "pec": "comuneacquavivadisernia@pec.it",
      "telefono": "+39 0865/84260",
      "fax": "+39 0865/847776",
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 14.149999618530273
      }
    },
    {
      "codice": "094002",
      "nome": "Agnone",
      "nomeStraniero": null,
      "codiceCatastale": "A080",
      "cap": "86081",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "affarigenerali@comune.agnone.is.it",
      "pec": "comune.agnone@legalmail.it",
      "telefono": "+39 0865/723208",
      "fax": "+39 0865/77512",
      "coordinate": {
        "lat": 41.81039810180664,
        "lng": 14.378499984741211
      }
    },
    {
      "codice": "094003",
      "nome": "Bagnoli del Trigno",
      "nomeStraniero": null,
      "codiceCatastale": "A567",
      "cap": "86091",
      "prefisso": "0874",
      "provincia": "Isernia",
      "email": "comunebagnolideltrignoanag.@virgilio.it",
      "pec": "bagnolideltrigno@pec.it",
      "telefono": "+39 0874/870107",
      "fax": "+39 0874/870930",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "094004",
      "nome": "Belmonte del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "A761",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "belmontedelsannio@alice.it",
      "pec": "belmontedelsannio@pec.leonet.it",
      "telefono": "+39 0865/70101",
      "fax": "+39 0865/70401",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "094005",
      "nome": "Cantalupo nel Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "B630",
      "cap": "86092",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "amministrazione@comune.cantalupo.is.it",
      "pec": "comunecantaluponelsannio@pec.it",
      "telefono": "+39 0865/814206",
      "fax": "+39 0865/814418",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "094006",
      "nome": "Capracotta",
      "nomeStraniero": null,
      "codiceCatastale": "B682",
      "cap": "86082",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune@capracotta.com",
      "pec": "tributi.capracotta@anutel.it",
      "telefono": "+39 0865/949210",
      "fax": null,
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "094007",
      "nome": "Carovilli",
      "nomeStraniero": null,
      "codiceCatastale": "B810",
      "cap": "86083",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "ufficioanagrafe@carovilli.info",
      "pec": "comunecarovilli@pec.it",
      "telefono": "+39 0865/838400",
      "fax": "+39 0865/838405",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "094008",
      "nome": "Carpinone",
      "nomeStraniero": null,
      "codiceCatastale": "B830",
      "cap": "86093",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune@carpinone.is.it",
      "pec": "comunecarpinone@pec.leonet.it",
      "telefono": "+39 0865/93499",
      "fax": "+39 0865/93205",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 14.316666603088379
      }
    },
    {
      "codice": "094009",
      "nome": "Castel del Giudice",
      "nomeStraniero": null,
      "codiceCatastale": "C082",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "info@comune.casteldelgiudice.is.it",
      "pec": "casteldelgiudice@pec.it",
      "telefono": "+39 0865/946130",
      "fax": "+39 0865/946783",
      "coordinate": {
        "lat": 41.849998474121094,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "094012",
      "nome": "Castel San Vincenzo",
      "nomeStraniero": null,
      "codiceCatastale": "C270",
      "cap": "86071",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "saia.csvincenzo@tiscali.it",
      "pec": "comunesanvincenzo@postacert.toscana.it",
      "telefono": "+39 0865/951131",
      "fax": "+39 0865/951951",
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 14.066666603088379
      }
    },
    {
      "codice": "094010",
      "nome": "Castelpetroso",
      "nomeStraniero": null,
      "codiceCatastale": "C246",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "anagrafecastelpetroso@virgilio.it",
      "pec": "anagrafecastelpetroso@pec.leonet.it",
      "telefono": "+39 0865/937138",
      "fax": "+39 0865/937676",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "094011",
      "nome": "Castelpizzuto",
      "nomeStraniero": null,
      "codiceCatastale": "C247",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "castelpizzuto@virgilio.it",
      "pec": "comune.castelpizzuto@pec.it",
      "telefono": "+39 0865/576003",
      "fax": "+39 0865/576965",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "094013",
      "nome": "Castelverrino",
      "nomeStraniero": null,
      "codiceCatastale": "C200",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune_castelverrino@libero.it",
      "pec": "comune.castelverrino@pec.leonet.it",
      "telefono": "+39 0865/770127",
      "fax": "+39 0865/770007",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "094014",
      "nome": "Cerro al Volturno",
      "nomeStraniero": null,
      "codiceCatastale": "C534",
      "cap": "86072",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "cerroalvolturno@tiscalinet.it",
      "pec": "amministrativo@pec.comune.cerroalvolturno.is.it",
      "telefono": "+39 0865/953104",
      "fax": "+39 0865/7883616",
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "094015",
      "nome": "Chiauci",
      "nomeStraniero": null,
      "codiceCatastale": "C620",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune@comune.chiauci.is.it",
      "pec": "comunedichiauci@pec.it",
      "telefono": "+39 0865/830100",
      "fax": "+39 0865/833776",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "094016",
      "nome": "Civitanova del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "C769",
      "cap": "86094",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "civitanova_sannio_is@libero.it",
      "pec": "giuseppe.cardarelli-2837@postacertificata.gov.it",
      "telefono": "+39 0865/830112",
      "fax": "+39 0865/833965",
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "094017",
      "nome": "Colli a Volturno",
      "nomeStraniero": null,
      "codiceCatastale": "C878",
      "cap": "86073",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "colliavolturno@libero.it",
      "pec": "colliavolturno@pec.it",
      "telefono": "+39 0865/957936",
      "fax": "+39 0865/957714",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 14.100000381469727
      }
    },
    {
      "codice": "094018",
      "nome": "Conca Casale",
      "nomeStraniero": null,
      "codiceCatastale": "C941",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comuneconcacasale@gmail.com",
      "pec": "comune.concacasale@cert.regione.molise.it",
      "telefono": "+39 0865/908522",
      "fax": "+39 0865/908522",
      "coordinate": {
        "lat": 41.49638748168945,
        "lng": 14.005555152893066
      }
    },
    {
      "codice": "094019",
      "nome": "Filignano",
      "nomeStraniero": null,
      "codiceCatastale": "D595",
      "cap": "86074",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "filignano@tiscali.it",
      "pec": "comune.filignano@pec.leonet.it",
      "telefono": "+39 0865/926138",
      "fax": "+39 0865/926366",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "094020",
      "nome": "Forlì del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "D703",
      "cap": "86084",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "forlidelsannio@gmail.com",
      "pec": "comune.forlidelsannio@pec.leonet.it",
      "telefono": "+39 0865/84301",
      "fax": "+39 0865/84203",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "094021",
      "nome": "Fornelli",
      "nomeStraniero": null,
      "codiceCatastale": "D715",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune.fornelli@libero.it",
      "pec": "comune.fornelli@pec.it",
      "telefono": "+39 0865 8956375",
      "fax": "+39 0865 956691",
      "coordinate": {
        "lat": 41.606781005859375,
        "lng": 14.140410423278809
      }
    },
    {
      "codice": "094022",
      "nome": "Frosolone",
      "nomeStraniero": null,
      "codiceCatastale": "D811",
      "cap": "86095",
      "prefisso": "0874",
      "provincia": "Isernia",
      "email": "info@comune.frosolone.is.it",
      "pec": "comunedifrosolone@postemailcertificata.it",
      "telefono": "+39 0874/890435",
      "fax": "+39 0874/890544",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "094023",
      "nome": "Isernia",
      "nomeStraniero": null,
      "codiceCatastale": "E335",
      "cap": "86170",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "ginaricci@comune.isernia.it",
      "pec": "comuneisernia@pec.it",
      "telefono": "+39 0865/449249",
      "fax": "+39 0865/415324",
      "coordinate": {
        "lat": 41.602779388427734,
        "lng": 14.23972225189209
      }
    },
    {
      "codice": "094024",
      "nome": "Longano",
      "nomeStraniero": null,
      "codiceCatastale": "E669",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "info@comune.longano.is.it",
      "pec": "comune.longano@pec.it",
      "telefono": "+39 0865/57135",
      "fax": "+39 0865/57135",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.25
      }
    },
    {
      "codice": "094025",
      "nome": "Macchia d'Isernia",
      "nomeStraniero": null,
      "codiceCatastale": "E778",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune.macchia@virgilio.it",
      "pec": "comune.macchiadisernia@pec.it",
      "telefono": "+39 0865/55130",
      "fax": "+39 0865/55220",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "094026",
      "nome": "Macchiagodena",
      "nomeStraniero": null,
      "codiceCatastale": "E779",
      "cap": "86096",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comunedimacchiagodena@tin.it",
      "pec": "comunedimacchiagodena@legalmail.it",
      "telefono": "+39 0865/810131",
      "fax": "+39 0865/810287",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "094027",
      "nome": "Miranda",
      "nomeStraniero": null,
      "codiceCatastale": "F239",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comunedimiranda@libero.it",
      "pec": "comunedimiranda@pec.it",
      "telefono": "+39 0865/497131",
      "fax": "+39 0865/497401",
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 14.25
      }
    },
    {
      "codice": "094028",
      "nome": "Montaquila",
      "nomeStraniero": null,
      "codiceCatastale": "F429",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "amministrativo@comune.montaquila.is.it",
      "pec": "comune.montaquila@anutel.it",
      "telefono": "+39 0865/960131",
      "fax": "+39 0865/960367",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.116666793823242
      }
    },
    {
      "codice": "094029",
      "nome": "Montenero Val Cocchiara",
      "nomeStraniero": null,
      "codiceCatastale": "F580",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "anagrafe@comune.montenerovalcocchiara.is.it",
      "pec": "ufficioanagrafe@pec.comune.montenerovalcocchiara.is.it",
      "telefono": "+39 0865/840121",
      "fax": "+39 0865/840338",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 14.066666603088379
      }
    },
    {
      "codice": "094030",
      "nome": "Monteroduni",
      "nomeStraniero": null,
      "codiceCatastale": "F601",
      "cap": "86075",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comunemonteroduni@virgilio.it",
      "pec": "demografici.monteroduni@pec.it",
      "telefono": "+39 0865/491586",
      "fax": "+39 0865491391",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "094031",
      "nome": "Pesche",
      "nomeStraniero": null,
      "codiceCatastale": "G486",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "pesche@comune.pesche.is.it",
      "pec": "comunedipesche@pec.comune.pesche.is.it",
      "telefono": "+39 0865/460130",
      "fax": "+39 0865/460363",
      "coordinate": {
        "lat": 41.60551834106445,
        "lng": 14.276200294494629
      }
    },
    {
      "codice": "094032",
      "nome": "Pescolanciano",
      "nomeStraniero": null,
      "codiceCatastale": "G495",
      "cap": "86097",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune@comune.pescolanciano.is.it",
      "pec": "comunepescolanciano@pec.it",
      "telefono": "+39 0865/832172",
      "fax": "+39 0865/832103",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "094033",
      "nome": "Pescopennataro",
      "nomeStraniero": null,
      "codiceCatastale": "G497",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comunepescopennataro@gmail.com",
      "pec": "comune.pescopennataro@pec.leonet.it",
      "telefono": "+39 0865/941131",
      "fax": "+39 0865/941365",
      "coordinate": {
        "lat": 41.8779182434082,
        "lng": 14.294195175170898
      }
    },
    {
      "codice": "094034",
      "nome": "Pettoranello del Molise",
      "nomeStraniero": null,
      "codiceCatastale": "G523",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "pettoranello@tin.it",
      "pec": "comune.pettoranello.is@pec.it",
      "telefono": "+39 0865/58104",
      "fax": "+39 0865/589001",
      "coordinate": {
        "lat": 41.56666564941406,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "094035",
      "nome": "Pietrabbondante",
      "nomeStraniero": null,
      "codiceCatastale": "G606",
      "cap": "86085",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "municipio@pietrabbondante.com",
      "pec": "pietrabbondante@pec.it",
      "telefono": "086576130",
      "fax": "0865769079",
      "coordinate": {
        "lat": 41.75,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "094036",
      "nome": "Pizzone",
      "nomeStraniero": null,
      "codiceCatastale": "G727",
      "cap": "86071",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune.pizzone@cert.regione.molise.it",
      "pec": "comune.pizzone@cert.regione.molise.it",
      "telefono": "+39 0865 951144",
      "fax": "+39 0865 951965",
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "094037",
      "nome": "Poggio Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "B317",
      "cap": "86086",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "servizidemografici@comune.poggiosannita.is.it",
      "pec": "comune.poggiosannita@pec.leonet.it",
      "telefono": "+39 0865 770135",
      "fax": "+39 0865 770330",
      "coordinate": {
        "lat": 41.77777862548828,
        "lng": 14.4127779006958
      }
    },
    {
      "codice": "094038",
      "nome": "Pozzilli",
      "nomeStraniero": null,
      "codiceCatastale": "G954",
      "cap": "86077",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "areademografica@comune.pozzilli.is.it",
      "pec": "pec@pec.comune.pozzilli.is.it",
      "telefono": "+39 0865/927180",
      "fax": "+39 0865/925543",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 14.066666603088379
      }
    },
    {
      "codice": "094039",
      "nome": "Rionero Sannitico",
      "nomeStraniero": null,
      "codiceCatastale": "H308",
      "cap": "86087",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune.rionero@gmail.com",
      "pec": "comune.rionerosannitico@pec.leonet.it",
      "telefono": "+390865848141",
      "fax": "+390865848480",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "094040",
      "nome": "Roccamandolfi",
      "nomeStraniero": null,
      "codiceCatastale": "H420",
      "cap": "86092",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comuneroccamandolfi@tiscali.it",
      "pec": "comuneroccamandolfi@postecert.it",
      "telefono": "+39 0865/816133",
      "fax": "+39 0865/816473",
      "coordinate": {
        "lat": 41.5,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "094041",
      "nome": "Roccasicura",
      "nomeStraniero": null,
      "codiceCatastale": "H445",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "coroccasicura@virgilio.it",
      "pec": "comune.roccasicura@postecert.it",
      "telefono": "+39 0865 837131",
      "fax": "+39 0865 837131",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "094042",
      "nome": "Rocchetta a Volturno",
      "nomeStraniero": null,
      "codiceCatastale": "H458",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune.rocchetta@virgilio.it",
      "pec": "protocollo.rocchettaavolturno@pec.it",
      "telefono": "+39 0865/955200",
      "fax": "+39 0865/955100",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "094043",
      "nome": "San Pietro Avellana",
      "nomeStraniero": null,
      "codiceCatastale": "I096",
      "cap": "86088",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "sanpietroavellana@tin.it",
      "pec": "comunedisanpietroavellana@pec-leonet.it",
      "telefono": "+39 0865/940131",
      "fax": "+39 0865/940291",
      "coordinate": {
        "lat": 41.79194259643555,
        "lng": 14.181388854980469
      }
    },
    {
      "codice": "094044",
      "nome": "Sant'Agapito",
      "nomeStraniero": null,
      "codiceCatastale": "I189",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "anagrafe@comune.sant-agapito.is.it",
      "pec": "sindaco.santagapito@pec.it",
      "telefono": "+39 0865/427040",
      "fax": "+39 0865/234429",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "094046",
      "nome": "Sant'Angelo del Pesco",
      "nomeStraniero": null,
      "codiceCatastale": "I282",
      "cap": "86080",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "info@comune.santnagelodelpesco.is.it",
      "pec": "santangelodelpesco@pec.it",
      "telefono": "+39 0865 948131",
      "fax": "+39 0865 948267",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 14.25
      }
    },
    {
      "codice": "094047",
      "nome": "Sant'Elena Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "B466",
      "cap": "86099",
      "prefisso": "0874",
      "provincia": "Isernia",
      "email": "comsantelena@tiscali.it",
      "pec": "comsantelena@pec.it",
      "telefono": "+39 0874/890059",
      "fax": null,
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "094045",
      "nome": "Santa Maria del Molise",
      "nomeStraniero": null,
      "codiceCatastale": "I238",
      "cap": "86090",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comune@comune.santamariadelmolise.is.it",
      "pec": "comune.santamariadelmolise.is@pec-leonet.it",
      "telefono": "+39 0865 817134",
      "fax": "+39 0865 817177",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "094048",
      "nome": "Scapoli",
      "nomeStraniero": null,
      "codiceCatastale": "I507",
      "cap": "86070",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "comunescapoli@libero.it",
      "pec": "postacert@pec.comunescapoli.is.it",
      "telefono": "+39 0865/954143",
      "fax": "+39 0865954505",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "094049",
      "nome": "Sessano del Molise",
      "nomeStraniero": null,
      "codiceCatastale": "I679",
      "cap": "86097",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "sessano2@tiscali.it",
      "pec": "servizidemografici.sessanodelmolise@pec.leonet.it",
      "telefono": "+39 0865 930131",
      "fax": "+39 0865 930164",
      "coordinate": {
        "lat": 41.63333511352539,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "094050",
      "nome": "Sesto Campano",
      "nomeStraniero": null,
      "codiceCatastale": "I682",
      "cap": "86078",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "info@comunesestocampano.is.it",
      "pec": "sestocampano@legalmail.it",
      "telefono": "+39 0865/928120",
      "fax": "+39 0865/928659",
      "coordinate": {
        "lat": 41.41666793823242,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "094051",
      "nome": "Vastogirardi",
      "nomeStraniero": null,
      "codiceCatastale": "L696",
      "cap": "86089",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "info@comunevastogirardi.is.it",
      "pec": "info@pec.comune.vastogirardi.is.it",
      "telefono": "+39 0865/836131",
      "fax": "+39 0865/836356",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "094052",
      "nome": "Venafro",
      "nomeStraniero": null,
      "codiceCatastale": "L725",
      "cap": "86079",
      "prefisso": "0865",
      "provincia": "Isernia",
      "email": "protocollo@comune.venafro.is.it",
      "pec": "protocollo@pec.comune.venafro.is.it",
      "telefono": "+39 0865/906202",
      "fax": "+39 0865/906304",
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 14.050000190734863
      }
    }
  ],
  "Bari": [
    {
      "codice": "072001",
      "nome": "Acquaviva delle Fonti",
      "nomeStraniero": null,
      "codiceCatastale": "A048",
      "cap": "70021",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "ufficio.protocollo@comune.acquaviva.ba.it",
      "pec": "ufficio.protocollo@pec.comune.acquaviva.ba.it",
      "telefono": "+39 080/3065111",
      "fax": "+39 080/761551",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 16.850000381469727
      }
    },
    {
      "codice": "072002",
      "nome": "Adelfia",
      "nomeStraniero": null,
      "codiceCatastale": "A055",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "capozzie@libero.it",
      "pec": "affarigenerali.adelfia@pec.rupar.puglia.it",
      "telefono": "+39 080/4598216",
      "fax": "+39 080/4597066",
      "coordinate": {
        "lat": 41,
        "lng": 16.866666793823242
      }
    },
    {
      "codice": "072003",
      "nome": "Alberobello",
      "nomeStraniero": null,
      "codiceCatastale": "A149",
      "cap": "70011",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "infoelettorale@comune.alberobello.ba.it",
      "pec": "protocollo@mailcert.comune.alberobello.ba.it",
      "telefono": "+39 080/4036253",
      "fax": "+39 080/4325706",
      "coordinate": {
        "lat": 40.784061431884766,
        "lng": 17.23746109008789
      }
    },
    {
      "codice": "072004",
      "nome": "Altamura",
      "nomeStraniero": null,
      "codiceCatastale": "A225",
      "cap": "70022",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "sindaco@comune.altamura.ba.it",
      "pec": "protocollo.generale@cert.comune.altamura.ba.it",
      "telefono": "+39 0803107111",
      "fax": "+39 0803141755",
      "coordinate": {
        "lat": 40.81666564941406,
        "lng": 16.549999237060547
      }
    },
    {
      "codice": "072006",
      "nome": "Bari",
      "nomeStraniero": null,
      "codiceCatastale": "A662",
      "cap": "70121",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "urp@comune.bari.it",
      "pec": "archiviogenerale.comunebari@pec.rupar.puglia.it",
      "telefono": "+39 080.5771111",
      "fax": "+39 080 5772254",
      "coordinate": {
        "lat": 41.12527847290039,
        "lng": 16.866666793823242
      }
    },
    {
      "codice": "072008",
      "nome": "Binetto",
      "nomeStraniero": null,
      "codiceCatastale": "A874",
      "cap": "70020",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segreteria@comune.binetto.ba.it",
      "pec": "comunedibinetto@pec.it",
      "telefono": "+39 080/7835003",
      "fax": "+39 080/623214",
      "coordinate": {
        "lat": 41.024166107177734,
        "lng": 16.710556030273438
      }
    },
    {
      "codice": "072010",
      "nome": "Bitetto",
      "nomeStraniero": null,
      "codiceCatastale": "A892",
      "cap": "70020",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "affarigenerali@comune.bitetto.ba.it",
      "pec": "affarigenerali@pec.comune.bitetto.ba.it",
      "telefono": "+39 080/3829209",
      "fax": null,
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 16.75
      }
    },
    {
      "codice": "072011",
      "nome": "Bitonto",
      "nomeStraniero": null,
      "codiceCatastale": "A893",
      "cap": "70032",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretario@comune.bitonto.ba.it",
      "pec": "protocollo.comunebitonto@pec.rupar.puglia.it",
      "telefono": "+39 0803716111",
      "fax": "+39 0803744558",
      "coordinate": {
        "lat": 41.108333587646484,
        "lng": 16.691667556762695
      }
    },
    {
      "codice": "072012",
      "nome": "Bitritto",
      "nomeStraniero": null,
      "codiceCatastale": "A894",
      "cap": "70020",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "urp@comune.bitritto.ba.it",
      "pec": "demografici@pec.comune.bitritto.ba.it",
      "telefono": "+39 0803858111",
      "fax": "+39 0803858226",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 16.83333396911621
      }
    },
    {
      "codice": "072014",
      "nome": "Capurso",
      "nomeStraniero": null,
      "codiceCatastale": "B716",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "sindaco@comune.capurso.bari.it",
      "pec": "comunecapurso@pec.rupar.puglia.it",
      "telefono": "+39 080/4551124",
      "fax": "+39 080/4552119",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 16.91666603088379
      }
    },
    {
      "codice": "072015",
      "nome": "Casamassima",
      "nomeStraniero": null,
      "codiceCatastale": "B923",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretariogenerale@comune.casamassima.ba.it",
      "pec": "comune.casamassima@pec.it",
      "telefono": "+39 080/6530822",
      "fax": "+39 080/6530822",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 16.91666603088379
      }
    },
    {
      "codice": "072016",
      "nome": "Cassano delle Murge",
      "nomeStraniero": null,
      "codiceCatastale": "B998",
      "cap": "70020",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segreteria@comune.cassanodellemurge.ba.it",
      "pec": "comunecassanodellemurge.ba@pec.rupar.puglia.it",
      "telefono": "+39 0803211508",
      "fax": "+39 0803211320",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 16.766666412353516
      }
    },
    {
      "codice": "072017",
      "nome": "Castellana Grotte",
      "nomeStraniero": null,
      "codiceCatastale": "C134",
      "cap": "70013",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "elettorale@comune.castellanagrotte.ba.it",
      "pec": "elettorale@mailcert.comune.castellanagrotte.ba.it",
      "telefono": "080/4900214",
      "fax": null,
      "coordinate": {
        "lat": 40.88377380371094,
        "lng": 17.167858123779297
      }
    },
    {
      "codice": "072018",
      "nome": "Cellamare",
      "nomeStraniero": null,
      "codiceCatastale": "C436",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "rafronchi@comune.cellamare.ba.it",
      "pec": "demografici.comune.cellamare.ba@pec.rupar.puglia.it",
      "telefono": "+39 080/4657911",
      "fax": "+39 080/4657930",
      "coordinate": {
        "lat": 41.02027893066406,
        "lng": 16.929166793823242
      }
    },
    {
      "codice": "072019",
      "nome": "Conversano",
      "nomeStraniero": null,
      "codiceCatastale": "C975",
      "cap": "70014",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "dir.affarigenerali@comune.conversano.ba.it",
      "pec": "protocollogenerale.comune.conversano.ba@pec.rupar.puglia.it",
      "telefono": "+39 0804094110-1",
      "fax": "+39 0804094116",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 17.116666793823242
      }
    },
    {
      "codice": "072020",
      "nome": "Corato",
      "nomeStraniero": null,
      "codiceCatastale": "C983",
      "cap": "70033",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "ced.corato@tiscali.it",
      "pec": "egov.corato@cert.poliscomuneamico.net",
      "telefono": "+39 080/9592292",
      "fax": "+39 080/8725777",
      "coordinate": {
        "lat": 41.150001525878906,
        "lng": 16.399999618530273
      }
    },
    {
      "codice": "072021",
      "nome": "Gioia del Colle",
      "nomeStraniero": null,
      "codiceCatastale": "E038",
      "cap": "70023",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "sistemiinformativi@comune.gioiadelcolle.ba.it",
      "pec": "protocollogenerale.gioiadelcolle@pec.rupar.puglia.it",
      "telefono": "+39 080/3494238",
      "fax": "+39 080/3494250",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 16.933332443237305
      }
    },
    {
      "codice": "072022",
      "nome": "Giovinazzo",
      "nomeStraniero": null,
      "codiceCatastale": "E047",
      "cap": "70054",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "protocollo@comune.giovinazzo.ba.it",
      "pec": "elettorale@pec.comune.giovinazzo.ba.it",
      "telefono": "+39 080/3902346 - 080/3902328",
      "fax": "+39 080/3943131",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 16.66666603088379
      }
    },
    {
      "codice": "072023",
      "nome": "Gravina in Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "E155",
      "cap": "70024",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "demografico@comune.gravina.ba.it",
      "pec": "urp.gravinainpuglia@pec.rupar.puglia.it",
      "telefono": "+39 0803259111",
      "fax": "+39 0803259201",
      "coordinate": {
        "lat": 40.820556640625,
        "lng": 16.4233341217041
      }
    },
    {
      "codice": "072024",
      "nome": "Grumo Appula",
      "nomeStraniero": null,
      "codiceCatastale": "E223",
      "cap": "70025",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segreteria.grumo@tiscali.it",
      "pec": "areaamministrativa@pec.comune.grumoappula.ba.it",
      "telefono": "+39 080/3831218",
      "fax": "+39 080/623267",
      "coordinate": {
        "lat": 41.016666412353516,
        "lng": 16.700000762939453
      }
    },
    {
      "codice": "072025",
      "nome": "Locorotondo",
      "nomeStraniero": null,
      "codiceCatastale": "E645",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "ced@comune.locorotondo.ba.it",
      "pec": "protocollo.comune.locorotondo@pec.rupar.puglia.it",
      "telefono": "+39 0804356251",
      "fax": "+39 0804315800",
      "coordinate": {
        "lat": 40.75,
        "lng": 17.316667556762695
      }
    },
    {
      "codice": "072027",
      "nome": "Modugno",
      "nomeStraniero": null,
      "codiceCatastale": "F262",
      "cap": "70026",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "urp@comune.modugno.ba.it",
      "pec": "comunemodugno@pec.rupar.puglia.it",
      "telefono": "+39 080 5865111",
      "fax": "+39 080 5325740",
      "coordinate": {
        "lat": 41.08333206176758,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "072028",
      "nome": "Mola di Bari",
      "nomeStraniero": null,
      "codiceCatastale": "F280",
      "cap": "70042",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segreteria@comune.moladibari.ba.it",
      "pec": "comune.moladibari@pec.rupar.puglia.it",
      "telefono": "+39 080 4738200",
      "fax": "+39 080 4738313",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 17.08333396911621
      }
    },
    {
      "codice": "072029",
      "nome": "Molfetta",
      "nomeStraniero": null,
      "codiceCatastale": "F284",
      "cap": "70056",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "ufficio.elettorale@comune.molfetta.ba.it",
      "pec": "elettorale@cert.comune.molfetta.ba.it",
      "telefono": "+39 080/3359430",
      "fax": null,
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 16.600000381469727
      }
    },
    {
      "codice": "072030",
      "nome": "Monopoli",
      "nomeStraniero": null,
      "codiceCatastale": "F376",
      "cap": "70043",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "portavoce@comune.monopoli.ba.it",
      "pec": "comune@pec.comune.monopoli.ba.it",
      "telefono": "+39 080 4140200",
      "fax": "+39 080 4140273",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 17.299999237060547
      }
    },
    {
      "codice": "072031",
      "nome": "Noci",
      "nomeStraniero": null,
      "codiceCatastale": "F915",
      "cap": "70015",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "vincenzo.daversa@comune.noci.ba.it",
      "pec": "ufficioaffarigenerali.comune.noci@pec.rupar.puglia.it",
      "telefono": "+39 080/4948239",
      "fax": null,
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 17.133333206176758
      }
    },
    {
      "codice": "072032",
      "nome": "Noicattaro",
      "nomeStraniero": null,
      "codiceCatastale": "F923",
      "cap": "70016",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretario.gen@comune.noicattaro.bari.it",
      "pec": "comune.noicattaro@pec.rupar.puglia.it",
      "telefono": "+39 080/4784251",
      "fax": "+39 080/4784250",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 16.983333587646484
      }
    },
    {
      "codice": "072033",
      "nome": "Palo del Colle",
      "nomeStraniero": null,
      "codiceCatastale": "G291",
      "cap": "70027",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "sertec.palo@libero.it",
      "pec": "protocollo.palodelcolle@pec.rupar.puglia.it",
      "telefono": "+39 080/9914228",
      "fax": "+39 080/9914266",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 16.700000762939453
      }
    },
    {
      "codice": "072034",
      "nome": "Poggiorsini",
      "nomeStraniero": null,
      "codiceCatastale": "G769",
      "cap": "70020",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretario@comune.poggiorsini.ba.it",
      "pec": "poggiorsini@pec.it",
      "telefono": "+39 080/3237127",
      "fax": "+39 080/3237192",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 16.25
      }
    },
    {
      "codice": "072035",
      "nome": "Polignano a Mare",
      "nomeStraniero": null,
      "codiceCatastale": "G787",
      "cap": "70044",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "sindaco@comune.polignanoamare.ba.it",
      "pec": "comune.polignano@anutel.it",
      "telefono": "+39 080 4252300",
      "fax": "+39 080 4252309",
      "coordinate": {
        "lat": 40.99611282348633,
        "lng": 17.220277786254883
      }
    },
    {
      "codice": "072036",
      "nome": "Putignano",
      "nomeStraniero": null,
      "codiceCatastale": "H096",
      "cap": "70017",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "info@comune.putignano.ba.it",
      "pec": "protocollo@cert.comune.putignano.ba.it",
      "telefono": "+39 080/4056302",
      "fax": "+39 080/4934688",
      "coordinate": {
        "lat": 40.84922790527344,
        "lng": 17.12254524230957
      }
    },
    {
      "codice": "072037",
      "nome": "Rutigliano",
      "nomeStraniero": null,
      "codiceCatastale": "H643",
      "cap": "70018",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "personale@comune.rutigliano.ba.it",
      "pec": "segretario@cert.comune.rutigliano.ba.it",
      "telefono": "+39 080/4763773",
      "fax": "+39 080/4763792",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 16.899999618530273
      }
    },
    {
      "codice": "072038",
      "nome": "Ruvo di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "H645",
      "cap": "70037",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "stato.civile@comune.ruvodipuglia.ba.it",
      "pec": "statocivile.comuneruvodipuglia@postecert.it",
      "telefono": "+39 080 9507118",
      "fax": "+39 080 3601605",
      "coordinate": {
        "lat": 41.11729049682617,
        "lng": 16.48372459411621
      }
    },
    {
      "codice": "072039",
      "nome": "Sammichele di Bari",
      "nomeStraniero": null,
      "codiceCatastale": "H749",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretario@comune.sammicheledibari.ba.it",
      "pec": "demografici.comunesammichele@pec.rupar.puglia.it",
      "telefono": "+39 080/8917368",
      "fax": "+39 080/8910270",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 16.950000762939453
      }
    },
    {
      "codice": "072040",
      "nome": "Sannicandro di Bari",
      "nomeStraniero": null,
      "codiceCatastale": "I053",
      "cap": "70028",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "llpp@omune.sannicandro.bari.it",
      "pec": "aagg.comunesannicandrodibari@pec.rupar.puglia.it",
      "telefono": "+39 080/9936111",
      "fax": "+39 080/9936333",
      "coordinate": {
        "lat": 41,
        "lng": 16.799999237060547
      }
    },
    {
      "codice": "072041",
      "nome": "Santeramo in Colle",
      "nomeStraniero": null,
      "codiceCatastale": "I330",
      "cap": "70029",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "info@comune.santeramo.ba.it",
      "pec": "protocollo@pec.comune.santeramo.ba.it",
      "telefono": "+39 080/3028324",
      "fax": "+39 080/3023710",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 16.766666412353516
      }
    },
    {
      "codice": "072043",
      "nome": "Terlizzi",
      "nomeStraniero": null,
      "codiceCatastale": "L109",
      "cap": "70038",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "demografici@comune.terlizzi.ba.it",
      "pec": "demografici@pec.comune.terlizzi.ba.it",
      "telefono": "+39 080/3510211",
      "fax": "+39 080/3514650",
      "coordinate": {
        "lat": 41.13333511352539,
        "lng": 16.549999237060547
      }
    },
    {
      "codice": "072044",
      "nome": "Toritto",
      "nomeStraniero": null,
      "codiceCatastale": "L220",
      "cap": "70020",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "anagrafe@comune.toritto.ba.it",
      "pec": "aagg.comune.toritto@pec.rupar.puglia.it",
      "telefono": "+39 080 3805323",
      "fax": "+39 080603729",
      "coordinate": {
        "lat": 41,
        "lng": 16.683332443237305
      }
    },
    {
      "codice": "072046",
      "nome": "Triggiano",
      "nomeStraniero": null,
      "codiceCatastale": "L425",
      "cap": "70019",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretariogenerale@comune.triggiano.bari.it",
      "pec": "segretariogenerale@pec.comune.triggiano.ba.it",
      "telefono": "+39 080/4628298",
      "fax": "+39 080/4621213",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 16.91666603088379
      }
    },
    {
      "codice": "072047",
      "nome": "Turi",
      "nomeStraniero": null,
      "codiceCatastale": "L472",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "segretario@comune.turi.ba.it",
      "pec": "segretario.comuneturi@pec.rupar.puglia.it",
      "telefono": "+39 080 4517511",
      "fax": "+39 0808915009",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 17.016666412353516
      }
    },
    {
      "codice": "072048",
      "nome": "Valenzano",
      "nomeStraniero": null,
      "codiceCatastale": "L571",
      "cap": "70010",
      "prefisso": "080",
      "provincia": "Bari",
      "email": "elettorale@comune.valenzano.ba.it",
      "pec": "elettorale.comunevalenzano@pec.rupar.puglia.it",
      "telefono": "+39 080/4607234",
      "fax": "+39 080/4673649",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 16.883333206176758
      }
    }
  ],
  "Caltanissetta": [
    {
      "codice": "085001",
      "nome": "Acquaviva Platani",
      "nomeStraniero": null,
      "codiceCatastale": "A049",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "segreteriaacquaviva@yahoo.it",
      "pec": "protocollo.acquavivaplatani@legalmail.it",
      "telefono": "+39 0934 953053",
      "fax": "+39 0934 953490",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "085002",
      "nome": "Bompensiere",
      "nomeStraniero": null,
      "codiceCatastale": "A957",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "anagrafebompensiere@tiscali.it",
      "pec": "anagrafe@pec.comunebompensiere.it",
      "telefono": "+39 0934938008",
      "fax": "+39 0934938388",
      "coordinate": {
        "lat": 37.46666717529297,
        "lng": 13.783333778381348
      }
    },
    {
      "codice": "085003",
      "nome": "Butera",
      "nomeStraniero": null,
      "codiceCatastale": "B302",
      "cap": "93011",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "sindaco@comunedibutera.org",
      "pec": "protocollo@pec.comunedibutera.org",
      "telefono": "+39 0934682464",
      "fax": null,
      "coordinate": {
        "lat": 37.18333435058594,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "085004",
      "nome": "Caltanissetta",
      "nomeStraniero": null,
      "codiceCatastale": "B429",
      "cap": "93100",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "segreteriasindaco@comune.caltanissetta.it",
      "pec": "protocollo@pec.comune.caltanissetta.it",
      "telefono": "+39 0934 74111",
      "fax": "+39 0934 582485",
      "coordinate": {
        "lat": 37.49147033691406,
        "lng": 14.062444686889648
      }
    },
    {
      "codice": "085005",
      "nome": "Campofranco",
      "nomeStraniero": null,
      "codiceCatastale": "B537",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "comunecampofranco.cl.protocollo@pa.postacertificata.gov.it",
      "pec": "comune.campofranco.cl@legalmail.it",
      "telefono": "+39 0934959270",
      "fax": "+39 0934959607",
      "coordinate": {
        "lat": 37.516666412353516,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "085006",
      "nome": "Delia",
      "nomeStraniero": null,
      "codiceCatastale": "D267",
      "cap": "93010",
      "prefisso": "0922",
      "provincia": "Caltanissetta",
      "email": "protocollo@comune.delia.cl.it",
      "pec": "protocollo.delia@pec.it",
      "telefono": "+39 0922 820355",
      "fax": "+39 0922 826163",
      "coordinate": {
        "lat": 37.349998474121094,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "085007",
      "nome": "Gela",
      "nomeStraniero": null,
      "codiceCatastale": "D960",
      "cap": "93012",
      "prefisso": "0933",
      "provincia": "Caltanissetta",
      "email": "affarigenerali@comune.gela.cl.it",
      "pec": "comune.gela@pec.comune.gela.cl.it",
      "telefono": "+39 0933 906211",
      "fax": "+39 0933 917042",
      "coordinate": {
        "lat": 37.06666564941406,
        "lng": 14.25
      }
    },
    {
      "codice": "085008",
      "nome": "Marianopoli",
      "nomeStraniero": null,
      "codiceCatastale": "E953",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "protocollo@comune.marianopoli.cl.it",
      "pec": "protocollo@pec.comune.marianopoli.cl.it",
      "telefono": "+39 0934 677005",
      "fax": "+39 0934 677006",
      "coordinate": {
        "lat": 37.599998474121094,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "085009",
      "nome": "Mazzarino",
      "nomeStraniero": null,
      "codiceCatastale": "F065",
      "cap": "93013",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "anagrafe@comune.mazzarino.cl.it",
      "pec": "protocollo@pec.comune.caltanissetta.it",
      "telefono": "+39 0934300111",
      "fax": "+39 0934381195",
      "coordinate": {
        "lat": 37.29999923706055,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "085010",
      "nome": "Milena",
      "nomeStraniero": null,
      "codiceCatastale": "E618",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "protocollo@comune.milena.cl.it",
      "pec": "protocollo@pec.comune.milena.cl.it",
      "telefono": "+39 0934933021",
      "fax": "+39 0934936023",
      "coordinate": {
        "lat": 37.46666717529297,
        "lng": 13.733332633972168
      }
    },
    {
      "codice": "085011",
      "nome": "Montedoro",
      "nomeStraniero": null,
      "codiceCatastale": "F489",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "uff.dem@alice.it",
      "pec": "ufficiosegreteria@pec.comune.montedoro.cl.it",
      "telefono": "+39 0934 934404",
      "fax": "+39 0934 934295",
      "coordinate": {
        "lat": 37.45431137084961,
        "lng": 13.816840171813965
      }
    },
    {
      "codice": "085012",
      "nome": "Mussomeli",
      "nomeStraniero": null,
      "codiceCatastale": "F830",
      "cap": "93014",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "info@comune.mussomeli.cl.it",
      "pec": "comunemussomeli@legalmail.it",
      "telefono": "+39 0934 961111",
      "fax": "+39 0934 991227",
      "coordinate": {
        "lat": 37.579444885253906,
        "lng": 13.7524995803833
      }
    },
    {
      "codice": "085013",
      "nome": "Niscemi",
      "nomeStraniero": null,
      "codiceCatastale": "F899",
      "cap": "93015",
      "prefisso": "0933",
      "provincia": "Caltanissetta",
      "email": "affarigenerali@comune.niscemi.cl.it",
      "pec": "ufficioprotocollo@comuneniscemipec.it",
      "telefono": "+39 0933 881111",
      "fax": "+39 0933 951022",
      "coordinate": {
        "lat": 37.150001525878906,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "085014",
      "nome": "Resuttano",
      "nomeStraniero": null,
      "codiceCatastale": "H245",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "protocollo@comune.resuttano.cl.it",
      "pec": "protocollo@pec.comune.resuttano.cl.it",
      "telefono": "+39 0934-676320",
      "fax": "+39 0934-673869",
      "coordinate": {
        "lat": 37.68333435058594,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "085015",
      "nome": "Riesi",
      "nomeStraniero": null,
      "codiceCatastale": "H281",
      "cap": "93016",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "segretario@comune.riesi.cl.it",
      "pec": "protocollo@pec.comune.riesi.cl.it",
      "telefono": "+39 0934923109",
      "fax": "+39 0934 921467",
      "coordinate": {
        "lat": 37.28333282470703,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "085016",
      "nome": "San Cataldo",
      "nomeStraniero": null,
      "codiceCatastale": "H792",
      "cap": "93017",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "segreteria@comune.san-cataldo.cl.it",
      "pec": "segreteria@pec.comune.san-cataldo.cl.it",
      "telefono": "+39 0934 511200",
      "fax": "+39 0934 571588",
      "coordinate": {
        "lat": 37.483333587646484,
        "lng": 13.983332633972168
      }
    },
    {
      "codice": "085017",
      "nome": "Santa Caterina Villarmosa",
      "nomeStraniero": null,
      "codiceCatastale": "I169",
      "cap": "93018",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": null,
      "pec": "amministrazione@pec.comune.santacaterinavillarmosa.cl.it",
      "telefono": "+39 0934 601011",
      "fax": "+39 0934 671859",
      "coordinate": {
        "lat": 37.599998474121094,
        "lng": 14.033333778381348
      }
    },
    {
      "codice": "085018",
      "nome": "Serradifalco",
      "nomeStraniero": null,
      "codiceCatastale": "I644",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "segretariogenerale@comune.serradifalco.cl.it",
      "pec": "comune.serradifalco@pec.it",
      "telefono": "+39 0934 935111",
      "fax": "+39 0934 930545",
      "coordinate": {
        "lat": 37.45383834838867,
        "lng": 13.880459785461426
      }
    },
    {
      "codice": "085019",
      "nome": "Sommatino",
      "nomeStraniero": null,
      "codiceCatastale": "I824",
      "cap": "93019",
      "prefisso": "0922",
      "provincia": "Caltanissetta",
      "email": "anagrafe@comune.sommatino.cl.it",
      "pec": "comune.sommatino@postacertificata.org",
      "telefono": "+39 0922888216",
      "fax": "+39 0922871117",
      "coordinate": {
        "lat": 37.336944580078125,
        "lng": 13.9975004196167
      }
    },
    {
      "codice": "085020",
      "nome": "Sutera",
      "nomeStraniero": null,
      "codiceCatastale": "L016",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "segreteria@comune.sutera.cl.it",
      "pec": "comune.sutera.cl@pec.it",
      "telefono": "+39 0934954300",
      "fax": "+39 0934954769",
      "coordinate": {
        "lat": 37.52439880371094,
        "lng": 13.735278129577637
      }
    },
    {
      "codice": "085021",
      "nome": "Vallelunga Pratameno",
      "nomeStraniero": null,
      "codiceCatastale": "L609",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "info@comune.vallelunga.cl.it",
      "pec": "comune.vallelunga.cl.it@pec.it",
      "telefono": "+39 0934 810011",
      "fax": null,
      "coordinate": {
        "lat": 37.68333435058594,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "085022",
      "nome": "Villalba",
      "nomeStraniero": null,
      "codiceCatastale": "L959",
      "cap": "93010",
      "prefisso": "0934",
      "provincia": "Caltanissetta",
      "email": "protocollo@comune.villalba.cl.it",
      "pec": "protocollo@pec.comune.villalba.cl.it",
      "telefono": "+39 0934 811911",
      "fax": "+39 0934 811943",
      "coordinate": {
        "lat": 37.650001525878906,
        "lng": 13.833333015441895
      }
    }
  ],
  "Messina": [
    {
      "codice": "083107",
      "nome": "Acquedolci",
      "nomeStraniero": null,
      "codiceCatastale": "M211",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "anagrafe@comunediacquedolci.it",
      "pec": "protocollo@pec.comunediacquedolci.it",
      "telefono": "0941/731162",
      "fax": "+39 9941/731102",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 14.583333015441895
      }
    },
    {
      "codice": "083001",
      "nome": "Alcara li Fusi",
      "nomeStraniero": null,
      "codiceCatastale": "A177",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "comune@comune.alcaralifusi.me.it",
      "pec": "comune@pec.comune.alcaralifusi.me.it",
      "telefono": "+39 0941 793010",
      "fax": "+39 0941 793406",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "083002",
      "nome": "Alì",
      "nomeStraniero": null,
      "codiceCatastale": "A194",
      "cap": "98020",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "sindaco@comunediali.it",
      "pec": "protocollo@pec.comune.ali.me.it",
      "telefono": "+39 0942/700301",
      "fax": "+39 0942/700217",
      "coordinate": {
        "lat": 38.02694320678711,
        "lng": 15.41694450378418
      }
    },
    {
      "codice": "083003",
      "nome": "Alì Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A201",
      "cap": "98021",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "segreteria@comune.aliterme.me.it",
      "pec": "protocollo@pec.comune.aliterme.me.it",
      "telefono": "+39 0942/710101",
      "fax": "+39 0942/710132",
      "coordinate": {
        "lat": 38.00722122192383,
        "lng": 15.42638874053955
      }
    },
    {
      "codice": "083004",
      "nome": "Antillo",
      "nomeStraniero": null,
      "codiceCatastale": "A313",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "comuneantillo@tiscali.it",
      "pec": "comunediantillo@primapec.com",
      "telefono": "+39 0942/723031",
      "fax": "+39 0942/723271",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 15.25
      }
    },
    {
      "codice": "083005",
      "nome": "Barcellona Pozzo di Gotto",
      "nomeStraniero": null,
      "codiceCatastale": "A638",
      "cap": "98051",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "ced@comune.barcellona-pozzo-di-gotto.me.it",
      "pec": "comunebarcellonapdg@postecert.it",
      "telefono": "+39 090/9790246",
      "fax": "+39 090/9797417",
      "coordinate": {
        "lat": 38.150001525878906,
        "lng": 15.216666221618652
      }
    },
    {
      "codice": "083006",
      "nome": "Basicò",
      "nomeStraniero": null,
      "codiceCatastale": "A698",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "protocollo@comune.basico.me.it",
      "pec": "protocollo@pec.comune.basico.me.it",
      "telefono": "+39 0941/85000",
      "fax": "+39 0941/85179",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "083007",
      "nome": "Brolo",
      "nomeStraniero": null,
      "codiceCatastale": "B198",
      "cap": "98061",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "segretariocomunale@comune.brolo.me.it",
      "pec": "protocollo@pec.comune.brolo.me.it",
      "telefono": "+39 0941/565371",
      "fax": "+39 0941/536034",
      "coordinate": {
        "lat": 38.15623092651367,
        "lng": 14.82798957824707
      }
    },
    {
      "codice": "083008",
      "nome": "Capizzi",
      "nomeStraniero": null,
      "codiceCatastale": "B660",
      "cap": "98031",
      "prefisso": "0935",
      "provincia": "Messina",
      "email": "anagrafe@comunecapizzi.me.it",
      "pec": "sindaco@pec.comunecapizzi.me.it",
      "telefono": "+39 0935/933315",
      "fax": "+39 0935/934311",
      "coordinate": {
        "lat": 37.849998474121094,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "083009",
      "nome": "Capo d'Orlando",
      "nomeStraniero": null,
      "codiceCatastale": "B666",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "protocollo@comune.capodorlando.me.it",
      "pec": "protocollo@pec.comune.capodorlando.me.it",
      "telefono": "+39 0941915111",
      "fax": "+39 0941912459",
      "coordinate": {
        "lat": 38.150001525878906,
        "lng": 14.733333587646484
      }
    },
    {
      "codice": "083010",
      "nome": "Capri Leone",
      "nomeStraniero": null,
      "codiceCatastale": "B695",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "segreteria@comune.caprileone.me.it",
      "pec": "caprileone@pec.intradata.it",
      "telefono": "+39 0941/950816",
      "fax": "+39 0941/950387",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 14.733333587646484
      }
    },
    {
      "codice": "083011",
      "nome": "Caronia",
      "nomeStraniero": null,
      "codiceCatastale": "B804",
      "cap": "98072",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": null,
      "pec": "caronia@pec.intradata.it",
      "telefono": "+39 0921/332064",
      "fax": "+39 0921/332473",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 14.433333396911621
      }
    },
    {
      "codice": "083012",
      "nome": "Casalvecchio Siculo",
      "nomeStraniero": null,
      "codiceCatastale": "B918",
      "cap": "98032",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "anagrafe@comune.casalvecchiosiculo.me.it",
      "pec": "segreteria.comunecasalvecchiosiculo@dgpec.it",
      "telefono": "+39 0942/761008",
      "fax": "+39 0942/761091",
      "coordinate": {
        "lat": 37.95750045776367,
        "lng": 15.321944236755371
      }
    },
    {
      "codice": "083013",
      "nome": "Castel di Lucio",
      "nomeStraniero": null,
      "codiceCatastale": "C094",
      "cap": "98070",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "amministrativo@casteldilucio.eu",
      "pec": "amministrativo.casteldilucio@postacertificata.org",
      "telefono": "+39 0921/384032",
      "fax": "+39 0921/384222",
      "coordinate": {
        "lat": 37.88333511352539,
        "lng": 14.316666603088379
      }
    },
    {
      "codice": "083014",
      "nome": "Castell'Umberto",
      "nomeStraniero": null,
      "codiceCatastale": "C051",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "castellumberto@tiscali.it",
      "pec": "comune.castellumberto@pec.it",
      "telefono": "+39 0941/438359",
      "fax": "+39 0941/438657",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "083015",
      "nome": "Castelmola",
      "nomeStraniero": null,
      "codiceCatastale": "C210",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "protocollo@comunecastelmola.it",
      "pec": "protocollo@pec.comunecastelmola.it",
      "telefono": "+39 094228195",
      "fax": "+39 094228238",
      "coordinate": {
        "lat": 37.85900115966797,
        "lng": 15.277816772460938
      }
    },
    {
      "codice": "083016",
      "nome": "Castroreale",
      "nomeStraniero": null,
      "codiceCatastale": "C347",
      "cap": "98053",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "segreteria@castroreale.it",
      "pec": "protocollo.castroreale@pec.it",
      "telefono": "+39 090/9746087",
      "fax": "+39 090/9746446",
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 15.216666221618652
      }
    },
    {
      "codice": "083017",
      "nome": "Cesarò",
      "nomeStraniero": null,
      "codiceCatastale": "C568",
      "cap": "98033",
      "prefisso": "095",
      "provincia": "Messina",
      "email": "servizidemografici@comune.cesaro.me.it",
      "pec": "cesaro@pec.intradata.it",
      "telefono": "+39 095/7739011",
      "fax": "+39 095/7739023",
      "coordinate": {
        "lat": 37.849998474121094,
        "lng": 14.716666221618652
      }
    },
    {
      "codice": "083018",
      "nome": "Condrò",
      "nomeStraniero": null,
      "codiceCatastale": "C956",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "condrones@tiscali.it",
      "pec": "protocollo.condro@pec.it",
      "telefono": "+39 090/937000",
      "fax": "+39 090/937174",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.333333015441895
      }
    },
    {
      "codice": "083019",
      "nome": "Falcone",
      "nomeStraniero": null,
      "codiceCatastale": "D474",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "elettorale@comune.falcone.me.it",
      "pec": "elettorale@pec.comune.falcone.me.it",
      "telefono": "+39 0941/34231",
      "fax": "+39 0941/34001",
      "coordinate": {
        "lat": 38.11666488647461,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "083020",
      "nome": "Ficarra",
      "nomeStraniero": null,
      "codiceCatastale": "D569",
      "cap": "98062",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "protocollocomune@ficarra.it",
      "pec": "protocollo@pec.comuneficarra.it",
      "telefono": "+39 0941/582010",
      "fax": "+39 0941/582037",
      "coordinate": {
        "lat": 38.11000061035156,
        "lng": 14.82972240447998
      }
    },
    {
      "codice": "083021",
      "nome": "Fiumedinisi",
      "nomeStraniero": null,
      "codiceCatastale": "D622",
      "cap": "98022",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "info@comune.fiumedinisi.me.it",
      "pec": "comune.fiumedinisi@legalmail.it",
      "telefono": "+39 0942/771001",
      "fax": "+39 0942/771292",
      "coordinate": {
        "lat": 38.02583312988281,
        "lng": 15.38194465637207
      }
    },
    {
      "codice": "083022",
      "nome": "Floresta",
      "nomeStraniero": null,
      "codiceCatastale": "D635",
      "cap": "98030",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "sindaco@floresta.gov.it",
      "pec": "comunedifloresta@pec.it",
      "telefono": "+39 0941 662036",
      "fax": "+39 0941 662266",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "083023",
      "nome": "Fondachelli-Fantina",
      "nomeStraniero": null,
      "codiceCatastale": "D661",
      "cap": "98050",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "info@comune.fondachellifantina.me.it",
      "pec": "servizisociali.fondachellifantina@pec.it",
      "telefono": "+39 0941/651001",
      "fax": "+39 0941/651239",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "083024",
      "nome": "Forza d'Agrò",
      "nomeStraniero": null,
      "codiceCatastale": "D733",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "info@comune.forzadagro.me.it",
      "pec": "protocollo.comuneforzadagro@dgpec.it",
      "telefono": "+39 0942 721016",
      "fax": "+39 0942 721015",
      "coordinate": {
        "lat": 37.91472244262695,
        "lng": 15.334166526794434
      }
    },
    {
      "codice": "083025",
      "nome": "Francavilla di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "D765",
      "cap": "98034",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "comunefdsanagrafe@virgilio.it.it",
      "pec": "pec@pec.comune.francavilladisicilia.me.it",
      "telefono": "+39 0942/98801",
      "fax": "+39 0942/982523",
      "coordinate": {
        "lat": 37.90147018432617,
        "lng": 15.135919570922852
      }
    },
    {
      "codice": "083026",
      "nome": "Frazzanò",
      "nomeStraniero": null,
      "codiceCatastale": "D793",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "uffsegreteria@comunefrazzano.it",
      "pec": "segreteria@pec.comunefrazzano.it",
      "telefono": "+39 0941/959037",
      "fax": "+39 0941/959165",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 14.75
      }
    },
    {
      "codice": "083027",
      "nome": "Furci Siculo",
      "nomeStraniero": null,
      "codiceCatastale": "D824",
      "cap": "98023",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "comune.furcisiculo@tiscali.it",
      "pec": "segreteria.furcisiculo@pec.it",
      "telefono": "+39 0942/794122",
      "fax": "+39 0942/794122",
      "coordinate": {
        "lat": 37.961387634277344,
        "lng": 15.38194465637207
      }
    },
    {
      "codice": "083028",
      "nome": "Furnari",
      "nomeStraniero": null,
      "codiceCatastale": "D825",
      "cap": "98054",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "areaamministrativa@comune.furnari.me.it",
      "pec": "protocollo@pec.comune.furnari.me.it",
      "telefono": "+39 0941802210",
      "fax": "+39 094181860",
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "083029",
      "nome": "Gaggi",
      "nomeStraniero": null,
      "codiceCatastale": "D844",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "servizidemografici@comunegaggi.it",
      "pec": "protocollo@pec.comunegaggi.it",
      "telefono": "+39 094247160",
      "fax": null,
      "coordinate": {
        "lat": 37.860137939453125,
        "lng": 15.22153091430664
      }
    },
    {
      "codice": "083030",
      "nome": "Galati Mamertino",
      "nomeStraniero": null,
      "codiceCatastale": "D861",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "comunegalati.uffrag@tiscali.it",
      "pec": "info@pec.comune.galatimamertino.me.it",
      "telefono": "+39 0941/434956",
      "fax": "+39 0941/436185",
      "coordinate": {
        "lat": 38.03333282470703,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "083031",
      "nome": "Gallodoro",
      "nomeStraniero": null,
      "codiceCatastale": "D885",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "info@comune.gallodoro.me.it",
      "pec": "protocollogallodoro@pec.it",
      "telefono": "+39 094236105",
      "fax": "+39 094237101",
      "coordinate": {
        "lat": 37.901668548583984,
        "lng": 15.293333053588867
      }
    },
    {
      "codice": "083032",
      "nome": "Giardini-Naxos",
      "nomeStraniero": null,
      "codiceCatastale": "E014",
      "cap": "98035",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "protocolloarnese@comune.giardini-naxos.me.it",
      "pec": "protocollogiardininaxos@pec.it",
      "telefono": "+39 0942 5780200",
      "fax": "+39 0942 5780266",
      "coordinate": {
        "lat": 37.83333206176758,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "083033",
      "nome": "Gioiosa Marea",
      "nomeStraniero": null,
      "codiceCatastale": "E043",
      "cap": "98063",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "segreteria@comunegioiosamarea.it",
      "pec": "protocollo@pec.comunegioiosamarea.it",
      "telefono": "+39 0941/363301",
      "fax": "+39 0941/302645",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 14.899999618530273
      }
    },
    {
      "codice": "083034",
      "nome": "Graniti",
      "nomeStraniero": null,
      "codiceCatastale": "E142",
      "cap": "98036",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "areaamministrativa@comunegraniti.gov.it",
      "pec": "comunegraniti@pec.it",
      "telefono": "+39 094229005",
      "fax": "+39 0942/297638",
      "coordinate": {
        "lat": 37.88333511352539,
        "lng": 15.216666221618652
      }
    },
    {
      "codice": "083035",
      "nome": "Gualtieri Sicaminò",
      "nomeStraniero": null,
      "codiceCatastale": "E233",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comune.gualtieri.me.it",
      "pec": "info@pec.gualtierisicamino.gov.it",
      "telefono": "+39 090 936112",
      "fax": "+39 090 936784",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.316666603088379
      }
    },
    {
      "codice": "083036",
      "nome": "Itala",
      "nomeStraniero": null,
      "codiceCatastale": "E374",
      "cap": "98025",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "sindaco@comuneitala.it",
      "pec": "info@pec.comuneitala.it",
      "telefono": "+39 090/952155",
      "fax": "+39 090/952116",
      "coordinate": {
        "lat": 38.051109313964844,
        "lng": 15.436944007873535
      }
    },
    {
      "codice": "083037",
      "nome": "Leni",
      "nomeStraniero": null,
      "codiceCatastale": "E523",
      "cap": "98050",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "comuneleni@tiscali.it",
      "pec": "arcoraci@comuneleni.telecompost.it",
      "telefono": "+39 090/9809125",
      "fax": "+39 090/9899225",
      "coordinate": {
        "lat": 38.54999923706055,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "083038",
      "nome": "Letojanni",
      "nomeStraniero": null,
      "codiceCatastale": "E555",
      "cap": "98037",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "anagrafe@comune.letojanni.me.it",
      "pec": "protocollo@pec.comune.letojanni.me.it",
      "telefono": "+39 0942/657328",
      "fax": "+39 0942/657328",
      "coordinate": {
        "lat": 37.88333511352539,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "083039",
      "nome": "Librizzi",
      "nomeStraniero": null,
      "codiceCatastale": "E571",
      "cap": "98064",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "antonella.campochiaro@comune.librizzi.me.it",
      "pec": "comune.librizzi@pec.it",
      "telefono": "+39 0941/32281.1.1",
      "fax": null,
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "083040",
      "nome": "Limina",
      "nomeStraniero": null,
      "codiceCatastale": "E594",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "comunelimina@tao.it",
      "pec": "segreteria.elettorale@pec.comune.limina.me.it",
      "telefono": "+39 0942/726055",
      "fax": "+39 0942/726023",
      "coordinate": {
        "lat": 37.95000076293945,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "083041",
      "nome": "Lipari",
      "nomeStraniero": null,
      "codiceCatastale": "E606",
      "cap": "98050",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "urp@comunelipari.it",
      "pec": "info@pec.comunelipari.it",
      "telefono": "+39 0909887111",
      "fax": "+39 0909880196",
      "coordinate": {
        "lat": 38.467281341552734,
        "lng": 14.955442428588867
      }
    },
    {
      "codice": "083042",
      "nome": "Longi",
      "nomeStraniero": null,
      "codiceCatastale": "E674",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "protocollo@comunelongi.it",
      "pec": "protocollo@pec.comunelongi.it",
      "telefono": "+39 3346141172  0941485040",
      "fax": "+39 0941/485401",
      "coordinate": {
        "lat": 38.03333282470703,
        "lng": 14.75
      }
    },
    {
      "codice": "083043",
      "nome": "Malfa",
      "nomeStraniero": null,
      "codiceCatastale": "E855",
      "cap": "98050",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "comunemalfa@yahoo.it",
      "pec": "ANTONELLO@comunemalfa.telecompost.it",
      "telefono": "+39 090/9844008",
      "fax": "+39 090/9844152",
      "coordinate": {
        "lat": 38.58333206176758,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "083044",
      "nome": "Malvagna",
      "nomeStraniero": null,
      "codiceCatastale": "E869",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "protocollo@comune.malvagna.me.it",
      "pec": "protocollo@pec.comune.malvagna.me.it",
      "telefono": "+39 0942388030",
      "fax": "+39 0942964172",
      "coordinate": {
        "lat": 37.9183349609375,
        "lng": 15.05555534362793
      }
    },
    {
      "codice": "083045",
      "nome": "Mandanici",
      "nomeStraniero": null,
      "codiceCatastale": "E876",
      "cap": "98020",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "comune.mandanici@infinito.it",
      "pec": "comunedimandanici@pec.it",
      "telefono": "+39 0942781010",
      "fax": "+39 0942781153",
      "coordinate": {
        "lat": 38.003334045410156,
        "lng": 15.316944122314453
      }
    },
    {
      "codice": "083046",
      "nome": "Mazzarrà Sant'Andrea",
      "nomeStraniero": null,
      "codiceCatastale": "F066",
      "cap": "98056",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "ufficioprotocollo@comunedimazzarrasantandrea.me.it",
      "pec": "areaamministrativaaffarigenerali@pec.comunedimazzarrasantandrea.me.it",
      "telefono": "+39 0941 83048",
      "fax": "+39 0941 83568",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "083047",
      "nome": "Merì",
      "nomeStraniero": null,
      "codiceCatastale": "F147",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "affarigenerali@comune.meri.me.it",
      "pec": "protocollo@pec.comune.meri.me.it",
      "telefono": "+39 090/9763777",
      "fax": "+39 090/9763945",
      "coordinate": {
        "lat": 38.16649627685547,
        "lng": 15.249927520751953
      }
    },
    {
      "codice": "083048",
      "nome": "Messina",
      "nomeStraniero": null,
      "codiceCatastale": "F158",
      "cap": "98121",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comune.me.it",
      "pec": "protocollo@pec.comune.messina.it",
      "telefono": "+39 090 7721",
      "fax": "+39 090/718201",
      "coordinate": {
        "lat": 38.19361114501953,
        "lng": 15.554166793823242
      }
    },
    {
      "codice": "083049",
      "nome": "Milazzo",
      "nomeStraniero": null,
      "codiceCatastale": "F206",
      "cap": "98057",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "sindaco@comune.milazzo.me.it",
      "pec": "affarigenerali@pec.comune.milazzo.me.it",
      "telefono": "+39 090/9231218",
      "fax": "+39 090/9231208",
      "coordinate": {
        "lat": 38.21699905395508,
        "lng": 15.237000465393066
      }
    },
    {
      "codice": "083050",
      "nome": "Militello Rosmarino",
      "nomeStraniero": null,
      "codiceCatastale": "F210",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "militellorosmarino@virgilio.it",
      "pec": "militello@pec.intradata.it",
      "telefono": "+39 0941/728288",
      "fax": "+39 0941/728358",
      "coordinate": {
        "lat": 38.046112060546875,
        "lng": 14.676153182983398
      }
    },
    {
      "codice": "083051",
      "nome": "Mirto",
      "nomeStraniero": null,
      "codiceCatastale": "F242",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "comunemirto@comune.mirto.me.it",
      "pec": "protocollo@pec.comune.mirto.me.it",
      "telefono": "+39 0941/919226",
      "fax": "+39 0941/919404",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 14.75
      }
    },
    {
      "codice": "083052",
      "nome": "Mistretta",
      "nomeStraniero": null,
      "codiceCatastale": "F251",
      "cap": "98073",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "sindaco@comune.mistretta.me.it",
      "pec": "info@pec.comune.mistretta.me.it",
      "telefono": "+39 0921/382468",
      "fax": "+39 0921/381035",
      "coordinate": {
        "lat": 37.93333435058594,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "083053",
      "nome": "Moio Alcantara",
      "nomeStraniero": null,
      "codiceCatastale": "F277",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "sindaco@comune.moioalcantara.me.it",
      "pec": "comune.moioalcantara.me@pec.it",
      "telefono": "+39 0942/963014",
      "fax": "+39 0942/963003",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 15.050000190734863
      }
    },
    {
      "codice": "083054",
      "nome": "Monforte San Giorgio",
      "nomeStraniero": null,
      "codiceCatastale": "F359",
      "cap": "98041",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comune.monfortesangiorgio.me.it",
      "pec": "protocollo@pec.comune.monfortesangiorgio.me.it",
      "telefono": "+39 090 9931000",
      "fax": "+39 090 9931318",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.366666793823242
      }
    },
    {
      "codice": "083055",
      "nome": "Mongiuffi Melia",
      "nomeStraniero": null,
      "codiceCatastale": "F368",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "protocollo@comune.mongiuffimelia.me.it",
      "pec": "comunemongiuffimelia@legpec.it",
      "telefono": "+39 094220006",
      "fax": "+39 0942/20062",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "083056",
      "nome": "Montagnareale",
      "nomeStraniero": null,
      "codiceCatastale": "F395",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "sindaco@comunedimomtagnareale.it",
      "pec": "protocollo@pec.comunedimontagnareale.it",
      "telefono": "+39 0941/315252",
      "fax": "+39 0941/315235",
      "coordinate": {
        "lat": 38.13333511352539,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "083057",
      "nome": "Montalbano Elicona",
      "nomeStraniero": null,
      "codiceCatastale": "F400",
      "cap": "98065",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "ced@comune.montalbanoelicona.me.it",
      "pec": "comunemontalbanoelicona.me.protocollo@pa.postacertificata.gov.it",
      "telefono": "+39 0941/679012",
      "fax": "+39 0941/679597",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "083058",
      "nome": "Motta Camastra",
      "nomeStraniero": null,
      "codiceCatastale": "F772",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "protocollo@comunemottacamastra.gov.it",
      "pec": "protocollo@pec.comunemottacamastra.gov.it",
      "telefono": "+39 0942/985007",
      "fax": "+39 0942/985363",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "083059",
      "nome": "Motta d'Affermo",
      "nomeStraniero": null,
      "codiceCatastale": "F773",
      "cap": "98070",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "urp-mottadaffermo@tiscali.it",
      "pec": "servdemmottadaffermo@pec.it",
      "telefono": "+39 0921/336086",
      "fax": "+39 0921/336851",
      "coordinate": {
        "lat": 37.9819450378418,
        "lng": 14.304166793823242
      }
    },
    {
      "codice": "083060",
      "nome": "Naso",
      "nomeStraniero": null,
      "codiceCatastale": "F848",
      "cap": "98074",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "comunedinaso@tiscali.it",
      "pec": "naso@pec.comune.naso.me.it",
      "telefono": "+39 0941961439",
      "fax": "+39 0941961118",
      "coordinate": {
        "lat": 38.124168395996094,
        "lng": 14.7877779006958
      }
    },
    {
      "codice": "083061",
      "nome": "Nizza di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "F901",
      "cap": "98026",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "uffsegreterianizza@tiscali.it",
      "pec": "comune.nizzadisicilia@pec.it",
      "telefono": "+39 0942/71001",
      "fax": "+39 0942/7100330",
      "coordinate": {
        "lat": 37.994720458984375,
        "lng": 15.4127779006958
      }
    },
    {
      "codice": "083062",
      "nome": "Novara di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "F951",
      "cap": "98058",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "protocollo@comunedinovaradisicilia.me.it",
      "pec": "protocollocomunenovaradisicilia@pec.it",
      "telefono": "0941/650954",
      "fax": "0941/650030",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "083063",
      "nome": "Oliveri",
      "nomeStraniero": null,
      "codiceCatastale": "G036",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "info@comune.oliveri.me.it",
      "pec": "info@pec.comune.oliveri.me.it",
      "telefono": "+39 0941/313201",
      "fax": "+39 0941/313072",
      "coordinate": {
        "lat": 38.11666488647461,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "083064",
      "nome": "Pace del Mela",
      "nomeStraniero": null,
      "codiceCatastale": "G209",
      "cap": "98042",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "protocollo@pec.comune.pacedelmela.it",
      "pec": "protocollo@pec.comune.pacedelmela.it",
      "telefono": "090/9560768",
      "fax": "090/9560813",
      "coordinate": {
        "lat": 38.18333435058594,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "083065",
      "nome": "Pagliara",
      "nomeStraniero": null,
      "codiceCatastale": "G234",
      "cap": "98020",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "segreteria@comune.pagliara.me.it",
      "pec": "comunepagliara@pec.it",
      "telefono": "+39 0942 737168",
      "fax": "+39 0942 737203",
      "coordinate": {
        "lat": 37.98545837402344,
        "lng": 15.359689712524414
      }
    },
    {
      "codice": "083066",
      "nome": "Patti",
      "nomeStraniero": null,
      "codiceCatastale": "G377",
      "cap": "98066",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "affarigenerali@comune.patti.me.it",
      "pec": "comune.patti.me@pec.itgo.it",
      "telefono": "+39 0941246222",
      "fax": "+39 0941240623",
      "coordinate": {
        "lat": 38.13333511352539,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "083067",
      "nome": "Pettineo",
      "nomeStraniero": null,
      "codiceCatastale": "G522",
      "cap": "98070",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "info@comune.pettineo.me.it",
      "pec": "posta@pec.comune.pettineo.me.it",
      "telefono": "+39 0921/336080",
      "fax": "+39 0921/336684",
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "083068",
      "nome": "Piraino",
      "nomeStraniero": null,
      "codiceCatastale": "G699",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "info@comune.piraino.me.it",
      "pec": "urp@pec.comune.piraino.me.it",
      "telefono": "+39 0941/586305",
      "fax": "+39 0941/585121",
      "coordinate": {
        "lat": 38.16166687011719,
        "lng": 14.86111068725586
      }
    },
    {
      "codice": "083069",
      "nome": "Raccuja",
      "nomeStraniero": null,
      "codiceCatastale": "H151",
      "cap": "98067",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "comuneraccuija@virgilio.it",
      "pec": "comuneraccuja@pec.it",
      "telefono": "+39 0941/660376",
      "fax": "+39 0941/663507",
      "coordinate": {
        "lat": 38.05558776855469,
        "lng": 14.910608291625977
      }
    },
    {
      "codice": "083070",
      "nome": "Reitano",
      "nomeStraniero": null,
      "codiceCatastale": "H228",
      "cap": "98070",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "info@comunereitano.com",
      "pec": "reitano@pec.intradata.it",
      "telefono": "+39 0921/338054",
      "fax": "+39 0921/338076",
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "083071",
      "nome": "Roccafiorita",
      "nomeStraniero": null,
      "codiceCatastale": "H405",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "info@comuneroccafiorita.gov.it",
      "pec": "comunediroccafiorita@primapec.com",
      "telefono": "+39 0942/726039",
      "fax": "+39 0942/726039",
      "coordinate": {
        "lat": 37.9305419921875,
        "lng": 15.268580436706543
      }
    },
    {
      "codice": "083072",
      "nome": "Roccalumera",
      "nomeStraniero": null,
      "codiceCatastale": "H418",
      "cap": "98027",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "serv.demografico@comune.roccalumera.me.it",
      "pec": "servizi.demografici@pec.comune.roccalumera.me.it",
      "telefono": "+39 0942/744064",
      "fax": "0942745104",
      "coordinate": {
        "lat": 37.97055435180664,
        "lng": 15.390000343322754
      }
    },
    {
      "codice": "083073",
      "nome": "Roccavaldina",
      "nomeStraniero": null,
      "codiceCatastale": "H380",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "protocollo@roccavaldina.gov.it",
      "pec": "comuneroccavaldina@pec.it",
      "telefono": "+39 090/9977741",
      "fax": "+39 090/9977542",
      "coordinate": {
        "lat": 38.18333435058594,
        "lng": 15.366666793823242
      }
    },
    {
      "codice": "083074",
      "nome": "Roccella Valdemone",
      "nomeStraniero": null,
      "codiceCatastale": "H455",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "segreteria@comune.roccellavaldemone.me.it",
      "pec": "segreteria@pec.comune.roccellavaldemone.me.it",
      "telefono": "+39 0942/965007",
      "fax": "+39 0942/965335",
      "coordinate": {
        "lat": 37.93333435058594,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "083075",
      "nome": "Rodì Milici",
      "nomeStraniero": null,
      "codiceCatastale": "H479",
      "cap": "98059",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "comunerodimilici@virgilio.it",
      "pec": "comunerodimilici@pec.it",
      "telefono": "+39 090/9741010",
      "fax": "+39 090/9741657",
      "coordinate": {
        "lat": 38.10823059082031,
        "lng": 15.169329643249512
      }
    },
    {
      "codice": "083076",
      "nome": "Rometta",
      "nomeStraniero": null,
      "codiceCatastale": "H519",
      "cap": "98043",
      "prefisso": "090",
      "provincia": "Messina",
      "email": null,
      "pec": "protocollo@pec.comunerometta.com",
      "telefono": "+39 090/9925111",
      "fax": "+39 090/9924582",
      "coordinate": {
        "lat": 38.17250061035156,
        "lng": 15.413888931274414
      }
    },
    {
      "codice": "083077",
      "nome": "San Filippo del Mela",
      "nomeStraniero": null,
      "codiceCatastale": "H842",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "segreteria@comune.sanfilippodelmela.me.it",
      "pec": "protocollo@pec.comune.sanfilippodelmela.me.it",
      "telefono": "+39 090/930530",
      "fax": "+39 090/9391847",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.266666412353516
      }
    },
    {
      "codice": "083078",
      "nome": "San Fratello",
      "nomeStraniero": null,
      "codiceCatastale": "H850",
      "cap": "98075",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "area.amministrativa@comunedisanfratello.it",
      "pec": "comunesanfratello@pec.it",
      "telefono": "+39 0941/794030",
      "fax": "+39 0941/799714",
      "coordinate": {
        "lat": 38.012786865234375,
        "lng": 14.59743881225586
      }
    },
    {
      "codice": "083079",
      "nome": "San Marco d'Alunzio",
      "nomeStraniero": null,
      "codiceCatastale": "H982",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "sanmarcodemos@libero.it",
      "pec": "comune.sanmarcodalunzio@pec.it",
      "telefono": "+39 0941/797007",
      "fax": "+39 0941/797391",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "083080",
      "nome": "San Pier Niceto",
      "nomeStraniero": null,
      "codiceCatastale": "I084",
      "cap": "98045",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "comunesanpierniceto@comune.sanpierniceto.me.it",
      "pec": "comunesanpierniceto@pec.comune.sanpierniceto.me.it",
      "telefono": "+39 090/9975382",
      "fax": "+39 090/9975374",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.350000381469727
      }
    },
    {
      "codice": "083081",
      "nome": "San Piero Patti",
      "nomeStraniero": null,
      "codiceCatastale": "I086",
      "cap": "98068",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "sindaco@comune.sanpieropatti.me.it",
      "pec": "protocollo@pec.comune.sanpieropatti.me.it",
      "telefono": "+39 0941/661388",
      "fax": "+39 0941/669114",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "083082",
      "nome": "San Salvatore di Fitalia",
      "nomeStraniero": null,
      "codiceCatastale": "I147",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "comunedisansalvatore@virgilio.it",
      "pec": "comune.sansalvatoredifitalia@pec.it",
      "telefono": "+39 0941/486027",
      "fax": "+39 0941/486594",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 14.783333778381348
      }
    },
    {
      "codice": "083090",
      "nome": "San Teodoro",
      "nomeStraniero": null,
      "codiceCatastale": "I328",
      "cap": "98030",
      "prefisso": "095",
      "provincia": "Messina",
      "email": "comune@comune.santeodoro.me.it",
      "pec": "santeodoro@pec.intradata.it",
      "telefono": "+39 095/7739113",
      "fax": "+39 095/7739129",
      "coordinate": {
        "lat": 37.849998474121094,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "083084",
      "nome": "Sant'Agata di Militello",
      "nomeStraniero": null,
      "codiceCatastale": "I199",
      "cap": "98076",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "segreteria@comune.santagatadimilitello.me.it",
      "pec": "protocollocomune.santagatadimilitello@pec.it",
      "telefono": "+39 0941/701451/701992",
      "fax": "+39 0941/702754",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "083085",
      "nome": "Sant'Alessio Siculo",
      "nomeStraniero": null,
      "codiceCatastale": "I215",
      "cap": "98030",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "segreteria@comune.santalessiosiculo.me.it",
      "pec": "segreteria.comunesantalessiosiculo@dgpec.it",
      "telefono": "+39 0942/751036",
      "fax": "+39 0942/684676",
      "coordinate": {
        "lat": 37.92166519165039,
        "lng": 15.346944808959961
      }
    },
    {
      "codice": "083088",
      "nome": "Sant'Angelo di Brolo",
      "nomeStraniero": null,
      "codiceCatastale": "I283",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "anagrafe@comune.santangelodibrolo.me.it",
      "pec": "comunedisantangelodibrolo@pec.it",
      "telefono": "+39 0941/533361",
      "fax": "+39 0941/533010",
      "coordinate": {
        "lat": 38.11666488647461,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "083083",
      "nome": "Santa Domenica Vittoria",
      "nomeStraniero": null,
      "codiceCatastale": "I184",
      "cap": "98030",
      "prefisso": "095",
      "provincia": "Messina",
      "email": "ufficioanagrafe@comunesdv.it",
      "pec": "comunesantadomenicavittoria@pec.it",
      "telefono": "+39 095/925385",
      "fax": "+39 095/925386",
      "coordinate": {
        "lat": 37.91666793823242,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "083086",
      "nome": "Santa Lucia del Mela",
      "nomeStraniero": null,
      "codiceCatastale": "I220",
      "cap": "98046",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "areamministrativa@comune.santaluciadelmela.me.it",
      "pec": "protocollogenerale@comune.santaluciadelmela.pec.telecompost.it",
      "telefono": "+39 090/934001",
      "fax": "+39 090/935736",
      "coordinate": {
        "lat": 38.150001525878906,
        "lng": 15.283333778381348
      }
    },
    {
      "codice": "083087",
      "nome": "Santa Marina Salina",
      "nomeStraniero": null,
      "codiceCatastale": "I254",
      "cap": "98050",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "protocollo@comune.santa-marina-salina.me.it",
      "pec": "comune@pec.comune.santa-marina-salina.me.it",
      "telefono": "+39 090/9843128  - 090/9843251",
      "fax": "+39 090/9843400",
      "coordinate": {
        "lat": 38.56666564941406,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "083089",
      "nome": "Santa Teresa di Riva",
      "nomeStraniero": null,
      "codiceCatastale": "I311",
      "cap": "98028",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "info@comune.santateresadiriva.me.it",
      "pec": "comunesantateresadiriva@pec.it",
      "telefono": "+390942786111",
      "fax": "+390942792070",
      "coordinate": {
        "lat": 37.939998626708984,
        "lng": 15.362500190734863
      }
    },
    {
      "codice": "083091",
      "nome": "Santo Stefano di Camastra",
      "nomeStraniero": null,
      "codiceCatastale": "I370",
      "cap": "98077",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "segr.santostefanocam@libero.it",
      "pec": "comune.santostefanodicamastra@pec.it",
      "telefono": "+39 0921/331110",
      "fax": "+39 0921/331566",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "083092",
      "nome": "Saponara",
      "nomeStraniero": null,
      "codiceCatastale": "I420",
      "cap": "98047",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "area_1@tiscali.it",
      "pec": "comune.saponara@pec.it",
      "telefono": "+39 090/3381208",
      "fax": "+39 090/3381208",
      "coordinate": {
        "lat": 38.20000076293945,
        "lng": 15.433333396911621
      }
    },
    {
      "codice": "083093",
      "nome": "Savoca",
      "nomeStraniero": null,
      "codiceCatastale": "I477",
      "cap": "98038",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "info@comunesavoca.gov.it",
      "pec": "comunesavoca@dgpec.it",
      "telefono": "+39 0942/761006",
      "fax": "+39 0942/761014",
      "coordinate": {
        "lat": 37.955753326416016,
        "lng": 15.339624404907227
      }
    },
    {
      "codice": "083094",
      "nome": "Scaletta Zanclea",
      "nomeStraniero": null,
      "codiceCatastale": "I492",
      "cap": "98029",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comunescalettazanclea.it",
      "pec": "info@pec.comunescalettazanclea.it",
      "telefono": "+39 090/9596711",
      "fax": "+39 090/951239",
      "coordinate": {
        "lat": 38.04472351074219,
        "lng": 15.462778091430664
      }
    },
    {
      "codice": "083095",
      "nome": "Sinagra",
      "nomeStraniero": null,
      "codiceCatastale": "I747",
      "cap": "98069",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "anagrafesinagra@aruba.it",
      "pec": "sinagra.uffdem@pec.it",
      "telefono": "+39 0941/594016",
      "fax": "+39 0941/594372",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "083096",
      "nome": "Spadafora",
      "nomeStraniero": null,
      "codiceCatastale": "I881",
      "cap": "98048",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "protocollo@comune.spadafora.me.it",
      "pec": "protocollo@pec.comune.spadafora.me.it",
      "telefono": "+39 090/9945109",
      "fax": "+39 090/9941495",
      "coordinate": {
        "lat": 38.233333587646484,
        "lng": 15.383333206176758
      }
    },
    {
      "codice": "083097",
      "nome": "Taormina",
      "nomeStraniero": null,
      "codiceCatastale": "L042",
      "cap": "98039",
      "prefisso": "0942",
      "provincia": "Messina",
      "email": "servizi.informatici@comune.taormina.it",
      "pec": "protocollo@pec.comune.taormina.me.it",
      "telefono": "+39 0942/620014",
      "fax": "+39 0942/620014",
      "coordinate": {
        "lat": 37.849998474121094,
        "lng": 15.300000190734863
      }
    },
    {
      "codice": "083106",
      "nome": "Terme Vigliatore",
      "nomeStraniero": null,
      "codiceCatastale": "M210",
      "cap": "98050",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comune.termevigliatore.me.it",
      "pec": "comunetermevigliatore@pec.it",
      "telefono": "+39 090/9700300",
      "fax": "+39 090/9700396",
      "coordinate": {
        "lat": 38.13333511352539,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "083098",
      "nome": "Torregrotta",
      "nomeStraniero": null,
      "codiceCatastale": "L271",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "elettorale@comune.torregrotta.me.it",
      "pec": "protocollo@pec.comune.torregrotta.me.it",
      "telefono": "+39 090/9981018",
      "fax": "+39 090/9910496",
      "coordinate": {
        "lat": 38.20000076293945,
        "lng": 15.350000381469727
      }
    },
    {
      "codice": "083108",
      "nome": "Torrenova",
      "nomeStraniero": null,
      "codiceCatastale": "M286",
      "cap": "98070",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "protocollo@comune.torrenova.me.it",
      "pec": "protocollo@pec.comune.torrenova.me.it",
      "telefono": "+39 0941/785060",
      "fax": "+39 0941/785314",
      "coordinate": {
        "lat": 38.09246063232422,
        "lng": 14.679169654846191
      }
    },
    {
      "codice": "083099",
      "nome": "Tortorici",
      "nomeStraniero": null,
      "codiceCatastale": "L308",
      "cap": "98078",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "informatica@comunetortorici.me.it",
      "pec": "comune.tortorici@pec.tortorici.eu",
      "telefono": "+39 0941 4231215",
      "fax": null,
      "coordinate": {
        "lat": 38.03083419799805,
        "lng": 14.824166297912598
      }
    },
    {
      "codice": "083100",
      "nome": "Tripi",
      "nomeStraniero": null,
      "codiceCatastale": "L431",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "sindaco@pec.comune.tripi.me.it",
      "pec": "protocollo@pec.comune.tripi.me.it",
      "telefono": "+39 0941/82014",
      "fax": "+39 0941/82380",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "083101",
      "nome": "Tusa",
      "nomeStraniero": null,
      "codiceCatastale": "L478",
      "cap": "98079",
      "prefisso": "0921",
      "provincia": "Messina",
      "email": "ufficioprotocollo@comunetusa.gov.it",
      "pec": "comuneditusa@pec.it",
      "telefono": "+39 0921/330405",
      "fax": "+39 0921/330886",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "083102",
      "nome": "Ucria",
      "nomeStraniero": null,
      "codiceCatastale": "L482",
      "cap": "98060",
      "prefisso": "0941",
      "provincia": "Messina",
      "email": "ufficiosindacoucria@tiscali.it",
      "pec": "ucria.protocollo@pec.it",
      "telefono": "+39 0941/664021",
      "fax": "+39 0941/664530",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "083103",
      "nome": "Valdina",
      "nomeStraniero": null,
      "codiceCatastale": "L561",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comune.valdina.me.it",
      "pec": "protocollo@pec.comune.valdina.me.it",
      "telefono": "+39 090/9977008",
      "fax": "+39 090/9977553",
      "coordinate": {
        "lat": 38.193626403808594,
        "lng": 15.368842124938965
      }
    },
    {
      "codice": "083104",
      "nome": "Venetico",
      "nomeStraniero": null,
      "codiceCatastale": "L735",
      "cap": "98040",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "info@comunevenetico.me.it",
      "pec": "info@pec.comunevenetico.me.it",
      "telefono": "+39 090/9919042",
      "fax": "+39 090/9920332",
      "coordinate": {
        "lat": 38.21994400024414,
        "lng": 15.366706848144531
      }
    },
    {
      "codice": "083105",
      "nome": "Villafranca Tirrena",
      "nomeStraniero": null,
      "codiceCatastale": "L950",
      "cap": "98049",
      "prefisso": "090",
      "provincia": "Messina",
      "email": "a.puglisi@comunevillafrancatirrena.gov.it",
      "pec": "protocollo@pec.comune.villafrancatirrena.me.it",
      "telefono": "+39 090/3310202",
      "fax": "+39 090/336391",
      "coordinate": {
        "lat": 38.233333587646484,
        "lng": 15.433333396911621
      }
    }
  ],
  "Alessandria": [
    {
      "codice": "006001",
      "nome": "Acqui Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A052",
      "cap": "15011",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "protocollo@comune.acquiterme.al.it",
      "pec": "acqui.terme@cert.ruparpiemonte.it",
      "telefono": "+39 0144 7701",
      "fax": "+39 0144/57627",
      "coordinate": {
        "lat": 44.676109313964844,
        "lng": 8.468610763549805
      }
    },
    {
      "codice": "006002",
      "nome": "Albera Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "A146",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "albera.ligure@reteunitaria.piemonte.it",
      "pec": "albera.ligure@cert.ruparpiemonte.it",
      "telefono": "+39 0143/90051",
      "fax": "+39 0143/90159",
      "coordinate": {
        "lat": 44.70333480834961,
        "lng": 9.067222595214844
      }
    },
    {
      "codice": "006003",
      "nome": "Alessandria",
      "nomeStraniero": null,
      "codiceCatastale": "A182",
      "cap": "15121",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria.sindaco@comune.alessandria.it",
      "pec": "comunedialessandria@legalmail.it",
      "telefono": "+39 0131/515111",
      "fax": "+39 0131/515313",
      "coordinate": {
        "lat": 44.913330078125,
        "lng": 8.619999885559082
      }
    },
    {
      "codice": "006004",
      "nome": "Alfiano Natta",
      "nomeStraniero": null,
      "codiceCatastale": "A189",
      "cap": "15021",
      "prefisso": "0141",
      "provincia": "Alessandria",
      "email": "demografici@comune.alfianonatta.al.it",
      "pec": "alfianonatta@pec.comune.alfianonatta.al.it",
      "telefono": "+39 0141/922123",
      "fax": "0141922123",
      "coordinate": {
        "lat": 45.04944610595703,
        "lng": 8.209444046020508
      }
    },
    {
      "codice": "006005",
      "nome": "Alice Bel Colle",
      "nomeStraniero": null,
      "codiceCatastale": "A197",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.alicebelcolle.al.it",
      "pec": "comune.alicebelcolle.al@pec.it",
      "telefono": "+39 0144/74104",
      "fax": "+39 0144/745942",
      "coordinate": {
        "lat": 44.72694396972656,
        "lng": 8.451666831970215
      }
    },
    {
      "codice": "006192",
      "nome": "Alluvioni Piovera",
      "nomeStraniero": null,
      "codiceCatastale": "M397",
      "cap": "15040",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.alluvionipiovera.al.it",
      "pec": "alluvionipiovera@pcert.it",
      "telefono": "0131848121",
      "fax": "0131848237",
      "coordinate": {
        "lat": 45,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "006007",
      "nome": "Altavilla Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "A227",
      "cap": "15041",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.altavillamonferrato.al.it",
      "pec": "altavilla@pec.comune.altavillamonferrato.al.it",
      "telefono": "+39 0142/926141",
      "fax": "+39 0142/936956",
      "coordinate": {
        "lat": 44.99444580078125,
        "lng": 8.376944541931152
      }
    },
    {
      "codice": "006008",
      "nome": "Alzano Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "A245",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.alzanoscrivia.al.it",
      "pec": "alzanoscrivia@pec.it",
      "telefono": "+39 0131/854382",
      "fax": "+39 0131/854382",
      "coordinate": {
        "lat": 45.018890380859375,
        "lng": 8.881667137145996
      }
    },
    {
      "codice": "006009",
      "nome": "Arquata Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "A436",
      "cap": "15061",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "segreteria@comune.arquatascrivia.al.it",
      "pec": "comune.arquatascrivia.al@legalmail.it",
      "telefono": "+39 0143/600411",
      "fax": "+39 0143/600417",
      "coordinate": {
        "lat": 44.692501068115234,
        "lng": 8.881667137145996
      }
    },
    {
      "codice": "006010",
      "nome": "Avolasca",
      "nomeStraniero": null,
      "codiceCatastale": "A523",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.avolasca.al.it",
      "pec": "protocollo@pec.comune.avolasca.al.it",
      "telefono": "+39 0131/876502",
      "fax": "+39 0131/876503",
      "coordinate": {
        "lat": 44.80416488647461,
        "lng": 8.966388702392578
      }
    },
    {
      "codice": "006011",
      "nome": "Balzola",
      "nomeStraniero": null,
      "codiceCatastale": "A605",
      "cap": "15031",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.balzola.al.it",
      "pec": "balzola@pcert.it",
      "telefono": "+39 0142/804131",
      "fax": "+39 0142/804106",
      "coordinate": {
        "lat": 45.184444427490234,
        "lng": 8.404444694519043
      }
    },
    {
      "codice": "006012",
      "nome": "Basaluzzo",
      "nomeStraniero": null,
      "codiceCatastale": "A689",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "demografici@comune.basaluzzo.al.it",
      "pec": "amministrazione-basaluzzo@pec.it",
      "telefono": "+39 0143/489107",
      "fax": "+39 0143/489370",
      "coordinate": {
        "lat": 44.76972198486328,
        "lng": 8.705833435058594
      }
    },
    {
      "codice": "006013",
      "nome": "Bassignana",
      "nomeStraniero": null,
      "codiceCatastale": "A708",
      "cap": "15042",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.bassignana.al.it",
      "pec": "comunebassignana@postecert.it",
      "telefono": "+39 0131/926112",
      "fax": "+39 0131/926619",
      "coordinate": {
        "lat": 45.00222396850586,
        "lng": 8.73194408416748
      }
    },
    {
      "codice": "006014",
      "nome": "Belforte Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "A738",
      "cap": "15070",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.belfortemonferrato.al.it",
      "pec": "belfortemonferrato@pecpro.it",
      "telefono": "+39 0143 86501",
      "fax": "+39 0143 824896",
      "coordinate": {
        "lat": 44.6261100769043,
        "lng": 8.662221908569336
      }
    },
    {
      "codice": "006015",
      "nome": "Bergamasco",
      "nomeStraniero": null,
      "codiceCatastale": "A793",
      "cap": "15022",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.bergamasco.al.it",
      "pec": "bergamasco@cert.ruparpiemonte.it",
      "telefono": "+39 0131/777101",
      "fax": "+39 0131/777518",
      "coordinate": {
        "lat": 44.82833480834961,
        "lng": 8.455833435058594
      }
    },
    {
      "codice": "006016",
      "nome": "Berzano di Tortona",
      "nomeStraniero": null,
      "codiceCatastale": "A813",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.berzanoditortona.al.it",
      "pec": "berzano.di.tortona@cert.ruparpiemonte.it",
      "telefono": "+39 0131/80179",
      "fax": "+39 0131/807977",
      "coordinate": {
        "lat": 44.878334045410156,
        "lng": 8.952500343322754
      }
    },
    {
      "codice": "006017",
      "nome": "Bistagno",
      "nomeStraniero": null,
      "codiceCatastale": "A889",
      "cap": "15012",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "ragioneria.bistagno@ruparpiemonte.it",
      "pec": "bistagno@cert.ruparpiemonte.it",
      "telefono": "+39 0144/79106",
      "fax": "+39 0144/79675",
      "coordinate": {
        "lat": 44.661109924316406,
        "lng": 8.37138843536377
      }
    },
    {
      "codice": "006018",
      "nome": "Borghetto di Borbera",
      "nomeStraniero": null,
      "codiceCatastale": "A998",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "demografici@comune.borghettodiborbera.al.it",
      "pec": "anagrafe@cert.comune.borghettodiborbera.al.it",
      "telefono": "+39 0143/69101",
      "fax": "+39 0143/697298",
      "coordinate": {
        "lat": 44.73027801513672,
        "lng": 8.94444465637207
      }
    },
    {
      "codice": "006020",
      "nome": "Borgo San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "B037",
      "cap": "15032",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.borgosanmartino.al.it",
      "pec": "protocollo@pec.comune.borgosanmartino.al.it",
      "telefono": "+39 0142/428003",
      "fax": "+39 0142/428008",
      "coordinate": {
        "lat": 45.09259033203125,
        "lng": 8.523730278015137
      }
    },
    {
      "codice": "006019",
      "nome": "Borgoratto Alessandrino",
      "nomeStraniero": null,
      "codiceCatastale": "B029",
      "cap": "15013",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.borgoratto.al.it",
      "pec": "comune.borgoratto@pec.it",
      "telefono": "+39 0131/278138",
      "fax": "+39 0131/278141",
      "coordinate": {
        "lat": 44.837223052978516,
        "lng": 8.539722442626953
      }
    },
    {
      "codice": "006021",
      "nome": "Bosco Marengo",
      "nomeStraniero": null,
      "codiceCatastale": "B071",
      "cap": "15062",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.boscomarengo.al.it",
      "pec": "protocollo@pec.comune.boscomarengo.al.it",
      "telefono": "+39 0131/299342",
      "fax": "+39 0131/299686",
      "coordinate": {
        "lat": 44.82638931274414,
        "lng": 8.678333282470703
      }
    },
    {
      "codice": "006022",
      "nome": "Bosio",
      "nomeStraniero": null,
      "codiceCatastale": "B080",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.bosio.al.it",
      "pec": "comune.bosio.al@legalmail.it",
      "telefono": "+39 0143/684131",
      "fax": "+39 0143/684665",
      "coordinate": {
        "lat": 44.65083312988281,
        "lng": 8.793889045715332
      }
    },
    {
      "codice": "006023",
      "nome": "Bozzole",
      "nomeStraniero": null,
      "codiceCatastale": "B109",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "segreteria@comune.bozzole.al.it",
      "pec": "protocollo@pec.comune.bozzole.al.it",
      "telefono": "+39 0142/60314",
      "fax": "+39 0142/60814",
      "coordinate": {
        "lat": 45.07083511352539,
        "lng": 8.607500076293945
      }
    },
    {
      "codice": "006024",
      "nome": "Brignano-Frascata",
      "nomeStraniero": null,
      "codiceCatastale": "B179",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.brignanofrascata.al.it",
      "pec": "segreteria@pec.comune.brignanofrascata.al.it",
      "telefono": "+39 0131-784623",
      "fax": "+39 0131784618",
      "coordinate": {
        "lat": 44.81388854980469,
        "lng": 9.041388511657715
      }
    },
    {
      "codice": "006025",
      "nome": "Cabella Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "B311",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "cabella.ligure@reteunitaria.piemonte.it",
      "pec": "cabella.ligure@cert.ruparpimonte.it",
      "telefono": "+39 0143/99430",
      "fax": "+39 0143/919700",
      "coordinate": {
        "lat": 44.674720764160156,
        "lng": 9.09666633605957
      }
    },
    {
      "codice": "006026",
      "nome": "Camagna Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "B453",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.camagnamonferrato.al.it",
      "pec": "protocollo@pec.comune.camagnamonferrato.al.it",
      "telefono": "+39 0142/925121",
      "fax": "+39 0142/925775",
      "coordinate": {
        "lat": 45.018611907958984,
        "lng": 8.430832862854004
      }
    },
    {
      "codice": "006027",
      "nome": "Camino",
      "nomeStraniero": null,
      "codiceCatastale": "B482",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "amministrativo@comune.camino.al.it",
      "pec": "camino@pec.comune.camino.al.it",
      "telefono": "+39 0142/469131",
      "fax": "+39 0142/469132",
      "coordinate": {
        "lat": 45.161109924316406,
        "lng": 8.291666984558105
      }
    },
    {
      "codice": "006028",
      "nome": "Cantalupo Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "B629",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "comune.cantalupoligure@gmail.com",
      "pec": "comunecantalupo@pec.it",
      "telefono": "+39 0143/90946",
      "fax": "+39 0143/90880",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 9.046111106872559
      }
    },
    {
      "codice": "006029",
      "nome": "Capriata d'Orba",
      "nomeStraniero": null,
      "codiceCatastale": "B701",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "segreteria@comune.capriatadorba.al.it",
      "pec": "protocollo@pec.comune.capriatadorba.al.it",
      "telefono": "+39 0143/46102",
      "fax": "+39 0143/467248",
      "coordinate": {
        "lat": 44.7288703918457,
        "lng": 8.69035530090332
      }
    },
    {
      "codice": "006030",
      "nome": "Carbonara Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "B736",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.carbonarascrivia.al.it",
      "pec": "protocollo@pec.comunecarbonarascrivia.al.it",
      "telefono": "+39 0131/892122",
      "fax": "+39 0131/892913",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 8.870833396911621
      }
    },
    {
      "codice": "006031",
      "nome": "Carentino",
      "nomeStraniero": null,
      "codiceCatastale": "B765",
      "cap": "15022",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.carentino.al.it",
      "pec": "carentino@pec.it",
      "telefono": "+39 0131/777104",
      "fax": "+39 0131/777240",
      "coordinate": {
        "lat": 44.8297233581543,
        "lng": 8.47249984741211
      }
    },
    {
      "codice": "006032",
      "nome": "Carezzano",
      "nomeStraniero": null,
      "codiceCatastale": "B769",
      "cap": "15051",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.carezzano.al.it",
      "pec": "protocollo@pec.comune.carezzano.al.it",
      "telefono": "+39 0131/839901",
      "fax": "+39 0131/839902",
      "coordinate": {
        "lat": 44.80805587768555,
        "lng": 8.901666641235352
      }
    },
    {
      "codice": "006033",
      "nome": "Carpeneto",
      "nomeStraniero": null,
      "codiceCatastale": "B818",
      "cap": "15071",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "carpeneto@ruparpiemonte.it",
      "pec": "carpeneto@cert.ruparpiemonte.it",
      "telefono": "+39 0143/85123",
      "fax": "+39 0143/85266",
      "coordinate": {
        "lat": 44.67720031738281,
        "lng": 8.606409072875977
      }
    },
    {
      "codice": "006034",
      "nome": "Carrega Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "B836",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "comune.carrega@alice.it",
      "pec": "postmaster@pec.comune.carregaligure.al.it",
      "telefono": "+39 0143/97115",
      "fax": "+39 0143/97115",
      "coordinate": {
        "lat": 44.62083435058594,
        "lng": 9.175833702087402
      }
    },
    {
      "codice": "006035",
      "nome": "Carrosio",
      "nomeStraniero": null,
      "codiceCatastale": "B840",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.carrosio.al.it",
      "pec": "carrosio@cert.ruparpiemonte.it",
      "telefono": "+39 0143/648891",
      "fax": "+39 0143/683360",
      "coordinate": {
        "lat": 44.6591682434082,
        "lng": 8.832500457763672
      }
    },
    {
      "codice": "006036",
      "nome": "Cartosio",
      "nomeStraniero": null,
      "codiceCatastale": "B847",
      "cap": "15015",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.cartosio.al.it",
      "pec": "comune.cartosio.al@pec.it",
      "telefono": "+39 0144/40126",
      "fax": "+39 0144/40189",
      "coordinate": {
        "lat": 44.59111022949219,
        "lng": 8.421667098999023
      }
    },
    {
      "codice": "006037",
      "nome": "Casal Cermelli",
      "nomeStraniero": null,
      "codiceCatastale": "B870",
      "cap": "15072",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comunedicasalcermelli.co@tin.it",
      "pec": "comune.casalcermelli@pec.it",
      "telefono": "+39 0131/279142",
      "fax": "+39 0131/279556",
      "coordinate": {
        "lat": 44.83583450317383,
        "lng": 8.626111030578613
      }
    },
    {
      "codice": "006039",
      "nome": "Casale Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "B885",
      "cap": "15033",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "centroel@comune.casale-monferrato.al.it",
      "pec": "protocollo@pec.comune.casale-monferrato.al.it",
      "telefono": "+39 0142/444411",
      "fax": "+39 0142/444312",
      "coordinate": {
        "lat": 45.1341667175293,
        "lng": 8.458333015441895
      }
    },
    {
      "codice": "006038",
      "nome": "Casaleggio Boiro",
      "nomeStraniero": null,
      "codiceCatastale": "B882",
      "cap": "15070",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.casaleggioboiro.al.it",
      "pec": "protocollo@pec.comune.casaleggioboiro.al.it",
      "telefono": "+39 0143/877134",
      "fax": "+39 0143/877998",
      "coordinate": {
        "lat": 44.63444519042969,
        "lng": 8.731389045715332
      }
    },
    {
      "codice": "006040",
      "nome": "Casalnoceto",
      "nomeStraniero": null,
      "codiceCatastale": "B902",
      "cap": "15052",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "amministratori@comune.casalnoceto.al.it",
      "pec": "protocollo@pec.comune.casalnoceto.al.it",
      "telefono": "+39 0131/809172",
      "fax": "+39 0131/809000",
      "coordinate": {
        "lat": 44.91388702392578,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "006041",
      "nome": "Casasco",
      "nomeStraniero": null,
      "codiceCatastale": "B941",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.casasco.al.it",
      "pec": "comune.casasco.al@legalmail.it",
      "telefono": "+39 0131/876126",
      "fax": "+39 0131/876303",
      "coordinate": {
        "lat": 44.828346252441406,
        "lng": 9.005810737609863
      }
    },
    {
      "codice": "006191",
      "nome": "Cassano Spinola",
      "nomeStraniero": null,
      "codiceCatastale": "M388",
      "cap": "15063",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "elettorale@comune.cassanospinola.al.it",
      "pec": "protocollo.cassano.spinola@cert.ruparpiemonte.it",
      "telefono": "014347117",
      "fax": null,
      "coordinate": {
        "lat": 44.76777648925781,
        "lng": 8.866110801696777
      }
    },
    {
      "codice": "006043",
      "nome": "Cassine",
      "nomeStraniero": null,
      "codiceCatastale": "C027",
      "cap": "15016",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "comune.cassine@tin.it",
      "pec": "protocollo.cassine@cert.ruparpiemonte.it",
      "telefono": "+39 0144/715151",
      "fax": "+39 0144/714258",
      "coordinate": {
        "lat": 44.750831604003906,
        "lng": 8.528888702392578
      }
    },
    {
      "codice": "006044",
      "nome": "Cassinelle",
      "nomeStraniero": null,
      "codiceCatastale": "C030",
      "cap": "15070",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.cassinelle.al.it",
      "pec": "cassinelle@pec.ruparpiemonte.it",
      "telefono": "+39 0143 848129",
      "fax": "+39 0143 848139",
      "coordinate": {
        "lat": 44.602500915527344,
        "lng": 8.564444541931152
      }
    },
    {
      "codice": "006045",
      "nome": "Castellania Coppi",
      "nomeStraniero": null,
      "codiceCatastale": "C137",
      "cap": "15051",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.castellania.al.it",
      "pec": "castellania@cert.ruparpiemonte.it",
      "telefono": "+39 0131.837127",
      "fax": "+39 0131/837127",
      "coordinate": {
        "lat": 44.79944610595703,
        "lng": 8.931388854980469
      }
    },
    {
      "codice": "006046",
      "nome": "Castellar Guidobono",
      "nomeStraniero": null,
      "codiceCatastale": "C142",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.castellarguidobono.al.it",
      "pec": "protocollo@pec.comune.castellarguidobono.al.it",
      "telefono": "+39 0131/898053",
      "fax": "+39 0131/897663",
      "coordinate": {
        "lat": 44.906944274902344,
        "lng": 8.948888778686523
      }
    },
    {
      "codice": "006047",
      "nome": "Castellazzo Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "C148",
      "cap": "15073",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comunecastellazzobormida.it",
      "pec": "castellazzobormida@legalmail.it",
      "telefono": "+39 0131/272820",
      "fax": "+39 0131/270337",
      "coordinate": {
        "lat": 44.84611129760742,
        "lng": 8.578332901000977
      }
    },
    {
      "codice": "006048",
      "nome": "Castelletto d'Erro",
      "nomeStraniero": null,
      "codiceCatastale": "C156",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "comune.castellettoderro@gmail.com",
      "pec": "castelletto.derro@pec.it",
      "telefono": "+39 0144/342002",
      "fax": "+39 0144/41377",
      "coordinate": {
        "lat": 44.62722396850586,
        "lng": 8.394721984863281
      }
    },
    {
      "codice": "006049",
      "nome": "Castelletto d'Orba",
      "nomeStraniero": null,
      "codiceCatastale": "C158",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "protocollo@comune.castellettodorba.al.it",
      "pec": "castorba@actaliscertymail.it",
      "telefono": "+39 0143/830032",
      "fax": "+39 0143/830623",
      "coordinate": {
        "lat": 44.68555450439453,
        "lng": 8.704999923706055
      }
    },
    {
      "codice": "006050",
      "nome": "Castelletto Merli",
      "nomeStraniero": null,
      "codiceCatastale": "C160",
      "cap": "15020",
      "prefisso": "0141",
      "provincia": "Alessandria",
      "email": "castelletto.merli@ruparpiemonte.it",
      "pec": "protocollo.castelletto.merli@cert.ruparpiemonte.it",
      "telefono": "+39 0141/918123",
      "fax": "+39 0141/918921",
      "coordinate": {
        "lat": 45.07500076293945,
        "lng": 8.241110801696777
      }
    },
    {
      "codice": "006051",
      "nome": "Castelletto Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "C162",
      "cap": "15040",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "sindaco@castellettomonferrato.piemonte.it",
      "pec": "castellettomonferrato@pec.it",
      "telefono": "+39 0131/233165",
      "fax": "+39 0131/212098",
      "coordinate": {
        "lat": 44.98222351074219,
        "lng": 8.565555572509766
      }
    },
    {
      "codice": "006052",
      "nome": "Castelnuovo Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "C229",
      "cap": "15017",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.castelnuovobormida.al.it",
      "pec": "comune.castelnuovobormida@pec.it",
      "telefono": "+39 0144/714535",
      "fax": "+39 0144/715835",
      "coordinate": {
        "lat": 44.74361038208008,
        "lng": 8.549722671508789
      }
    },
    {
      "codice": "006053",
      "nome": "Castelnuovo Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "C243",
      "cap": "15053",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.castelnuovoscrivia.al.it",
      "pec": "protocollo@pec.comune.castelnuovoscrivia.al.it",
      "telefono": "+39 0131/826125",
      "fax": "+39 0131/823088",
      "coordinate": {
        "lat": 44.981388092041016,
        "lng": 8.882222175598145
      }
    },
    {
      "codice": "006054",
      "nome": "Castelspina",
      "nomeStraniero": null,
      "codiceCatastale": "C274",
      "cap": "15070",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.castelspina.al.it",
      "pec": "castelspina@cert.ruparpiemonte.it",
      "telefono": "+39 0131/700144",
      "fax": "+39 0131/700144",
      "coordinate": {
        "lat": 44.807220458984375,
        "lng": 8.584166526794434
      }
    },
    {
      "codice": "006055",
      "nome": "Cavatore",
      "nomeStraniero": null,
      "codiceCatastale": "C387",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.cavatore.al.it",
      "pec": "comune.cavatore@pec.it",
      "telefono": "+39 0144/320753",
      "fax": null,
      "coordinate": {
        "lat": 44.63138961791992,
        "lng": 8.455277442932129
      }
    },
    {
      "codice": "006056",
      "nome": "Cella Monte",
      "nomeStraniero": null,
      "codiceCatastale": "C432",
      "cap": "15034",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "segretario@comune.cellamonte.al.it",
      "pec": "cellamonte@pec.comune.cellamonte.al.it",
      "telefono": "+39 0142/488161",
      "fax": "+39 0142/489962",
      "coordinate": {
        "lat": 45.07500076293945,
        "lng": 8.392499923706055
      }
    },
    {
      "codice": "006057",
      "nome": "Cereseto",
      "nomeStraniero": null,
      "codiceCatastale": "C503",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "demografici@comune.cereseto.it",
      "pec": "cereseto@pec.comune.cereseto.al.it",
      "telefono": "+39 0142/940185",
      "fax": "+39 0142/940020",
      "coordinate": {
        "lat": 45.086849212646484,
        "lng": 8.318169593811035
      }
    },
    {
      "codice": "006058",
      "nome": "Cerreto Grue",
      "nomeStraniero": null,
      "codiceCatastale": "C507",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "cerretogrue@tor.it",
      "pec": "cerretogrue@cert.ruparpiemonte.it",
      "telefono": "+39 0131/883175",
      "fax": "+39 0131/883907",
      "coordinate": {
        "lat": 44.84361267089844,
        "lng": 8.931388854980469
      }
    },
    {
      "codice": "006059",
      "nome": "Cerrina Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "C531",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "protocollo@comune.cerrina.al.it",
      "pec": "comune.cerrina@pec.it",
      "telefono": "+39 0142-943421",
      "fax": "+39 0142943817",
      "coordinate": {
        "lat": 45.122222900390625,
        "lng": 8.21500015258789
      }
    },
    {
      "codice": "006060",
      "nome": "Coniolo",
      "nomeStraniero": null,
      "codiceCatastale": "C962",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.coniolo.al.it",
      "pec": "coniolo@pcert.it",
      "telefono": "+39 0142408423",
      "fax": "+39 0142408383",
      "coordinate": {
        "lat": 45.149166107177734,
        "lng": 8.371944427490234
      }
    },
    {
      "codice": "006061",
      "nome": "Conzano",
      "nomeStraniero": null,
      "codiceCatastale": "C977",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "comune@comune.conzano.al.it",
      "pec": "protocollo@pec.comune.conzano.al.it",
      "telefono": "+39 0142925132",
      "fax": "+39 0142925734",
      "coordinate": {
        "lat": 45.02138900756836,
        "lng": 8.454999923706055
      }
    },
    {
      "codice": "006062",
      "nome": "Costa Vescovato",
      "nomeStraniero": null,
      "codiceCatastale": "D102",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.costavescovato.al.it",
      "pec": "amministrazione@pec.comune.costavescovato.al.it",
      "telefono": "+39 0131838128",
      "fax": null,
      "coordinate": {
        "lat": 44.816429138183594,
        "lng": 8.926872253417969
      }
    },
    {
      "codice": "006063",
      "nome": "Cremolino",
      "nomeStraniero": null,
      "codiceCatastale": "D149",
      "cap": "15010",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.cremolino.al.it",
      "pec": "comune.cremolino@pec.it",
      "telefono": "+39 0143879037",
      "fax": "+39 0143879425",
      "coordinate": {
        "lat": 44.63694381713867,
        "lng": 8.58638858795166
      }
    },
    {
      "codice": "006065",
      "nome": "Denice",
      "nomeStraniero": null,
      "codiceCatastale": "D272",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "posta@comunedenice.it",
      "pec": "posta@pec.comunedenice.it",
      "telefono": "+39 0144952530",
      "fax": "+39 0144952530",
      "coordinate": {
        "lat": 44.59972381591797,
        "lng": 8.334166526794434
      }
    },
    {
      "codice": "006066",
      "nome": "Dernice",
      "nomeStraniero": null,
      "codiceCatastale": "D277",
      "cap": "15056",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.dernice.al.it",
      "pec": "protocollo@pec.comune.dernice.al.it",
      "telefono": "+39 0131786261",
      "fax": "+39 0131788942",
      "coordinate": {
        "lat": 44.76750183105469,
        "lng": 9.05138874053955
      }
    },
    {
      "codice": "006067",
      "nome": "Fabbrica Curone",
      "nomeStraniero": null,
      "codiceCatastale": "D447",
      "cap": "15054",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comune@fabbricacurone.it",
      "pec": "comune@pec.fabbricacurone.it",
      "telefono": "+39 013178213",
      "fax": "+39 0131782203",
      "coordinate": {
        "lat": 44.755279541015625,
        "lng": 9.154999732971191
      }
    },
    {
      "codice": "006068",
      "nome": "Felizzano",
      "nomeStraniero": null,
      "codiceCatastale": "D528",
      "cap": "15023",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.felizzano.al.it",
      "pec": "protocollo@pec.comune.felizzano.al.it",
      "telefono": "+39 0131791122",
      "fax": "+39 0131772629",
      "coordinate": {
        "lat": 44.90027618408203,
        "lng": 8.436944007873535
      }
    },
    {
      "codice": "006069",
      "nome": "Fraconalto",
      "nomeStraniero": null,
      "codiceCatastale": "D559",
      "cap": "15060",
      "prefisso": "010",
      "provincia": "Alessandria",
      "email": "info@comune.fraconalto.al.it",
      "pec": "fraconalto@cert.ruparpiemonte.it",
      "telefono": "+39 0109693920",
      "fax": "+39 010930074",
      "coordinate": {
        "lat": 44.59194564819336,
        "lng": 8.879444122314453
      }
    },
    {
      "codice": "006070",
      "nome": "Francavilla Bisio",
      "nomeStraniero": null,
      "codiceCatastale": "D759",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "protocollo@comune.francavillabisio.al.it",
      "pec": "protocollo.francavilla.bisio@cert.ruparpiemonte.it",
      "telefono": "+39 014350122",
      "fax": "+39 0143350900",
      "coordinate": {
        "lat": 44.73555374145508,
        "lng": 8.732222557067871
      }
    },
    {
      "codice": "006071",
      "nome": "Frascaro",
      "nomeStraniero": null,
      "codiceCatastale": "D770",
      "cap": "15010",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.frascaro.al.it",
      "pec": "comune.frascaro@legalmail.it",
      "telefono": "+39 0131278227",
      "fax": "+39 0131278227",
      "coordinate": {
        "lat": 44.82645034790039,
        "lng": 8.532244682312012
      }
    },
    {
      "codice": "006072",
      "nome": "Frassinello Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "D777",
      "cap": "15035",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.frassinellomonferrato.al.it",
      "pec": "frassinello@pec.comune.frassinellomonferrato.al.it",
      "telefono": "+39 0142928121",
      "fax": "+39 0142928254",
      "coordinate": {
        "lat": 45.03277587890625,
        "lng": 8.38861083984375
      }
    },
    {
      "codice": "006073",
      "nome": "Frassineto Po",
      "nomeStraniero": null,
      "codiceCatastale": "D780",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.frassinetopo.al.it",
      "pec": "protocollo@pec.comune.frassinetopo.al.it",
      "telefono": "+39 0142482413",
      "fax": "+39 0142482593",
      "coordinate": {
        "lat": 45.13472366333008,
        "lng": 8.535277366638184
      }
    },
    {
      "codice": "006074",
      "nome": "Fresonara",
      "nomeStraniero": null,
      "codiceCatastale": "D797",
      "cap": "15064",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.fresonara.al.it",
      "pec": "protocollo@pec.comune.fresonara.al.it",
      "telefono": "+39 0143480166",
      "fax": "+39 0143480385",
      "coordinate": {
        "lat": 44.78388977050781,
        "lng": 8.687222480773926
      }
    },
    {
      "codice": "006075",
      "nome": "Frugarolo",
      "nomeStraniero": null,
      "codiceCatastale": "D813",
      "cap": "15065",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.frugarolo.al.it",
      "pec": "protocollo.frugarolo@cert.ruparpiemonte.it",
      "telefono": "+39 0131296022",
      "fax": "+39 0131296244",
      "coordinate": {
        "lat": 44.83805465698242,
        "lng": 8.682499885559082
      }
    },
    {
      "codice": "006076",
      "nome": "Fubine Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "D814",
      "cap": "15043",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comunedifubine.it",
      "pec": "area.segreteria@pec.comunedifubine.it",
      "telefono": "+39 0131/778121",
      "fax": "+39 0131/778700",
      "coordinate": {
        "lat": 44.9636116027832,
        "lng": 8.431111335754395
      }
    },
    {
      "codice": "006077",
      "nome": "Gabiano",
      "nomeStraniero": null,
      "codiceCatastale": "D835",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "ufficioanagrafe@comune.gabiano.al.it",
      "pec": "pec.anagrafe@comunegabiano.it",
      "telefono": "+39 0142945001",
      "fax": "+39 0142945622",
      "coordinate": {
        "lat": 45.15777587890625,
        "lng": 8.196389198303223
      }
    },
    {
      "codice": "006078",
      "nome": "Gamalero",
      "nomeStraniero": null,
      "codiceCatastale": "D890",
      "cap": "15010",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.gamalero.al.it",
      "pec": "comune.gamalero@legalmail.it",
      "telefono": "+39 0131709153",
      "fax": "+39 0131709424",
      "coordinate": {
        "lat": 44.810001373291016,
        "lng": 8.542778015136719
      }
    },
    {
      "codice": "006079",
      "nome": "Garbagna",
      "nomeStraniero": null,
      "codiceCatastale": "D910",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.garbagna.al.it",
      "pec": "protocollo@pec.comune.garbagna.al.it",
      "telefono": "+39 0131877645",
      "fax": "+39 0131877307",
      "coordinate": {
        "lat": 44.78138732910156,
        "lng": 8.998888969421387
      }
    },
    {
      "codice": "006081",
      "nome": "Gavi",
      "nomeStraniero": null,
      "codiceCatastale": "D944",
      "cap": "15066",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comunegavi.it",
      "pec": "segreteriasindaco@pec.comunegavi.it",
      "telefono": "+39 0143642712",
      "fax": "+39 0143643280",
      "coordinate": {
        "lat": 44.6886100769043,
        "lng": 8.810277938842773
      }
    },
    {
      "codice": "006082",
      "nome": "Giarole",
      "nomeStraniero": null,
      "codiceCatastale": "E015",
      "cap": "15036",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.giarole.al.it",
      "pec": "protocollo@pec.comune.giarole.al.it",
      "telefono": "+39 0142/68123",
      "fax": "+39 0142/68388",
      "coordinate": {
        "lat": 45.0613899230957,
        "lng": 8.567500114440918
      }
    },
    {
      "codice": "006083",
      "nome": "Gremiasco",
      "nomeStraniero": null,
      "codiceCatastale": "E164",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comune.gremiasco@tor.it",
      "pec": "protocollo@pec.comune.gremiasco.al.it",
      "telefono": "+39 0131787153",
      "fax": "+39 0131787260",
      "coordinate": {
        "lat": 44.79722213745117,
        "lng": 9.10777759552002
      }
    },
    {
      "codice": "006084",
      "nome": "Grognardo",
      "nomeStraniero": null,
      "codiceCatastale": "E188",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.grognardo.al.it",
      "pec": "grognardo@cert.ruparpiemonte.it",
      "telefono": "+39 0144 762103",
      "fax": "+39 0144 762900",
      "coordinate": {
        "lat": 44.63138961791992,
        "lng": 8.493611335754395
      }
    },
    {
      "codice": "006085",
      "nome": "Grondona",
      "nomeStraniero": null,
      "codiceCatastale": "E191",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.grondona.al.it",
      "pec": "protocollo@pec.comune.grondona.al.it",
      "telefono": "+39 0143632821",
      "fax": "+39 0143680003",
      "coordinate": {
        "lat": 44.6974983215332,
        "lng": 8.966388702392578
      }
    },
    {
      "codice": "006086",
      "nome": "Guazzora",
      "nomeStraniero": null,
      "codiceCatastale": "E255",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.guazzora.al.it",
      "pec": "protocollo@pec.comune.guazzora.al.it",
      "telefono": "+39 0131/857149",
      "fax": "+39 0131/857329",
      "coordinate": {
        "lat": 45.01472091674805,
        "lng": 8.852499961853027
      }
    },
    {
      "codice": "006087",
      "nome": "Isola Sant'Antonio",
      "nomeStraniero": null,
      "codiceCatastale": "E360",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "demografici@comuneisolasantantonio.al.it",
      "pec": "segreteria@pec.comuneisolasantantonio.al.it",
      "telefono": "+39 0131857121",
      "fax": "+39 0131857475",
      "coordinate": {
        "lat": 45.03111267089844,
        "lng": 8.85111141204834
      }
    },
    {
      "codice": "006088",
      "nome": "Lerma",
      "nomeStraniero": null,
      "codiceCatastale": "E543",
      "cap": "15070",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.lerma.al.it",
      "pec": "protocollo@pec.comune.lerma.al.it",
      "telefono": "+39 0143877337",
      "fax": "+39 0143877636",
      "coordinate": {
        "lat": 44.63694381713867,
        "lng": 8.716388702392578
      }
    },
    {
      "codice": "006193",
      "nome": "Lu e Cuccaro Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "M420",
      "cap": "15037",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.luecuccaromonferrato.al.it",
      "pec": "comune.luecuccaromonferrato.al@legalmail.it",
      "telefono": "0131/741121",
      "fax": "0131/741737",
      "coordinate": {
        "lat": 44.997222900390625,
        "lng": 8.472222328186035
      }
    },
    {
      "codice": "006090",
      "nome": "Malvicino",
      "nomeStraniero": null,
      "codiceCatastale": "E870",
      "cap": "15015",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.malvicino.al.it",
      "pec": "malvicino@pcert.it",
      "telefono": "+39 0144 340882",
      "fax": "+39 0144 340882",
      "coordinate": {
        "lat": 44.560298919677734,
        "lng": 8.414199829101562
      }
    },
    {
      "codice": "006091",
      "nome": "Masio",
      "nomeStraniero": null,
      "codiceCatastale": "F015",
      "cap": "15024",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.masio.al.it",
      "pec": "masio@pec.comune.masio.al.it",
      "telefono": "+39 0131799131",
      "fax": "+39 0131799082",
      "coordinate": {
        "lat": 44.86964797973633,
        "lng": 8.407740592956543
      }
    },
    {
      "codice": "006092",
      "nome": "Melazzo",
      "nomeStraniero": null,
      "codiceCatastale": "F096",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.melazzo.al.it",
      "pec": "comune.melazzo.al@pec.it",
      "telefono": "+39 014441101",
      "fax": "+39 014441577",
      "coordinate": {
        "lat": 44.64500045776367,
        "lng": 8.426111221313477
      }
    },
    {
      "codice": "006093",
      "nome": "Merana",
      "nomeStraniero": null,
      "codiceCatastale": "F131",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.merana.al.it",
      "pec": "merana@pcert.it",
      "telefono": "+39 014499100",
      "fax": "+39 0144993900",
      "coordinate": {
        "lat": 44.51972198486328,
        "lng": 8.299166679382324
      }
    },
    {
      "codice": "006094",
      "nome": "Mirabello Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "F232",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.mirabellomonferrato.al.it",
      "pec": "protocollo@pec.comune.mirabellomonferrato.al.it",
      "telefono": "+39 0142/63121",
      "fax": "+39 0142/64720",
      "coordinate": {
        "lat": 45.0363883972168,
        "lng": 8.52416706085205
      }
    },
    {
      "codice": "006095",
      "nome": "Molare",
      "nomeStraniero": null,
      "codiceCatastale": "F281",
      "cap": "15074",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.molare.al.it",
      "pec": "info@cert.comune.molare.al.it",
      "telefono": "+39 0143888121",
      "fax": "+39 0143888117",
      "coordinate": {
        "lat": 44.61864471435547,
        "lng": 8.600549697875977
      }
    },
    {
      "codice": "006096",
      "nome": "Molino dei Torti",
      "nomeStraniero": null,
      "codiceCatastale": "F293",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.molinodeitorti.al.it",
      "pec": "molinodeitorti@pec.it",
      "telefono": "+39 0131/85436",
      "fax": "+39 0131/854379",
      "coordinate": {
        "lat": 45.02527618408203,
        "lng": 8.894721984863281
      }
    },
    {
      "codice": "006097",
      "nome": "Mombello Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "F313",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.mombellomonferrato.al.it",
      "pec": "mombellomonferrato@pec.comune.mombellomonferrato.it",
      "telefono": "+39 0142/944101",
      "fax": "+39 0142/944698",
      "coordinate": {
        "lat": 45.133888244628906,
        "lng": 8.251388549804688
      }
    },
    {
      "codice": "006098",
      "nome": "Momperone",
      "nomeStraniero": null,
      "codiceCatastale": "F320",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comunemomperone.it",
      "pec": "segreteria@pec.comunemomperone.it",
      "telefono": "+39 0131/784675",
      "fax": "+39 0131/784477",
      "coordinate": {
        "lat": 44.83944320678711,
        "lng": 9.035277366638184
      }
    },
    {
      "codice": "006099",
      "nome": "Moncestino",
      "nomeStraniero": null,
      "codiceCatastale": "F337",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "moncestino@ruparpiemonte.it",
      "pec": "protocollo.moncestino@cert.ruparpiemonte.it",
      "telefono": "+39 0142/945061",
      "fax": "+39 0142/945620",
      "coordinate": {
        "lat": 45.15475845336914,
        "lng": 8.161421775817871
      }
    },
    {
      "codice": "006100",
      "nome": "Mongiardino Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "F365",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "mongiardino.ligure@ruparpiemonte.it",
      "pec": "mongiardino.ligure@cert.ruparpiemonte.it",
      "telefono": "+39 0143/98110",
      "fax": "+39 0143/98110",
      "coordinate": {
        "lat": 44.63999938964844,
        "lng": 9.061666488647461
      }
    },
    {
      "codice": "006101",
      "nome": "Monleale",
      "nomeStraniero": null,
      "codiceCatastale": "F374",
      "cap": "15059",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "monleale@libero.it",
      "pec": "monleale@cert.ruparpiemonte.it",
      "telefono": "+39 0131/80191",
      "fax": "+39 0131/80191",
      "coordinate": {
        "lat": 44.883331298828125,
        "lng": 8.966667175292969
      }
    },
    {
      "codice": "006102",
      "nome": "Montacuto",
      "nomeStraniero": null,
      "codiceCatastale": "F387",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.montacuto.al.it",
      "pec": "protocollo@pec.comune.montacuto.al.it",
      "telefono": "+39 0131/785110",
      "fax": "+39 0131/785110",
      "coordinate": {
        "lat": 44.766448974609375,
        "lng": 9.104960441589355
      }
    },
    {
      "codice": "006103",
      "nome": "Montaldeo",
      "nomeStraniero": null,
      "codiceCatastale": "F403",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "comunemontaldeo@libero.it",
      "pec": "montaldeo@cert.ruparpiemonte.it",
      "telefono": "+39 0143849121",
      "fax": "+39 0143849144",
      "coordinate": {
        "lat": 44.66805648803711,
        "lng": 8.731111526489258
      }
    },
    {
      "codice": "006104",
      "nome": "Montaldo Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "F404",
      "cap": "15010",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "montaldo.bormida@ruparpiemonte.it",
      "pec": "montaldo.bormida@cert.ruparpiemonte.it",
      "telefono": "+39 0143/85141",
      "fax": "+39 0143/85201",
      "coordinate": {
        "lat": 44.68361282348633,
        "lng": 8.588889122009277
      }
    },
    {
      "codice": "006105",
      "nome": "Montecastello",
      "nomeStraniero": null,
      "codiceCatastale": "F455",
      "cap": "15040",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.montecastello.al.it",
      "pec": "segreteria@pec.comune.montecastello.al.it",
      "telefono": "+39 0131/355136",
      "fax": "+39 0131/355136",
      "coordinate": {
        "lat": 44.9494514465332,
        "lng": 8.68574047088623
      }
    },
    {
      "codice": "006106",
      "nome": "Montechiaro d'Acqui",
      "nomeStraniero": null,
      "codiceCatastale": "F469",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.montechiarodacqui.al.it",
      "pec": "montechiaro.dacqui@cert.ruparpiemonte.it",
      "telefono": "+39 0144/92058",
      "fax": "+39 0144/952949",
      "coordinate": {
        "lat": 44.59527587890625,
        "lng": 8.379722595214844
      }
    },
    {
      "codice": "006107",
      "nome": "Montegioco",
      "nomeStraniero": null,
      "codiceCatastale": "F518",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.montegioco.al.it",
      "pec": "protocollo@pec.comune.montegioco.al.it",
      "telefono": "+39 0131/875132",
      "fax": "+39 0131/875132",
      "coordinate": {
        "lat": 44.8386116027832,
        "lng": 8.956110954284668
      }
    },
    {
      "codice": "006108",
      "nome": "Montemarzino",
      "nomeStraniero": null,
      "codiceCatastale": "F562",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.montemarzino.al.it",
      "pec": "montemarzino@cert.ruparpiemonte.it",
      "telefono": "+39 0131/878124",
      "fax": "+39 0131/878298",
      "coordinate": {
        "lat": 44.84916687011719,
        "lng": 8.994722366333008
      }
    },
    {
      "codice": "006109",
      "nome": "Morano sul Po",
      "nomeStraniero": null,
      "codiceCatastale": "F707",
      "cap": "15025",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.moranosulpo.al.it",
      "pec": "moranosulpo@pcert.it",
      "telefono": "+39 0142/85123",
      "fax": "+39 0142/85601",
      "coordinate": {
        "lat": 45.1683349609375,
        "lng": 8.36722183227539
      }
    },
    {
      "codice": "006110",
      "nome": "Morbello",
      "nomeStraniero": null,
      "codiceCatastale": "F713",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.morbello.al.it",
      "pec": "comune.morbello@pec.it",
      "telefono": "+39 0144/768146",
      "fax": "+39 0144/768277",
      "coordinate": {
        "lat": 44.606109619140625,
        "lng": 8.511666297912598
      }
    },
    {
      "codice": "006111",
      "nome": "Mornese",
      "nomeStraniero": null,
      "codiceCatastale": "F737",
      "cap": "15075",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.mornese.al.it",
      "pec": "mornese@cert.ruparpiemonte.it",
      "telefono": "+39 0143/887858",
      "fax": "+39 0143/887601",
      "coordinate": {
        "lat": 44.64027786254883,
        "lng": 8.757222175598145
      }
    },
    {
      "codice": "006112",
      "nome": "Morsasco",
      "nomeStraniero": null,
      "codiceCatastale": "F751",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "demografici@comunemorsasco.it",
      "pec": "comunemorsasco@arubapec.it",
      "telefono": "+39 0144/73022",
      "fax": "+39 0144/73140",
      "coordinate": {
        "lat": 44.66666793823242,
        "lng": 8.551944732666016
      }
    },
    {
      "codice": "006113",
      "nome": "Murisengo",
      "nomeStraniero": null,
      "codiceCatastale": "F814",
      "cap": "15020",
      "prefisso": "0141",
      "provincia": "Alessandria",
      "email": "protocollo@murisengo.com",
      "pec": "murisengo@cert.ruparpiemonte.it",
      "telefono": "+39 0141/993041",
      "fax": "+39 0141/993759",
      "coordinate": {
        "lat": 45.08277893066406,
        "lng": 8.13611125946045
      }
    },
    {
      "codice": "006114",
      "nome": "Novi Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "F965",
      "cap": "15067",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "segreteria@comune.noviligure.al.it",
      "pec": "protocollo.novi.ligure@cert.ruparpiemonte.it",
      "telefono": "+39 0143 77221",
      "fax": "+39 0143/772200",
      "coordinate": {
        "lat": 44.7591667175293,
        "lng": 8.785555839538574
      }
    },
    {
      "codice": "006115",
      "nome": "Occimiano",
      "nomeStraniero": null,
      "codiceCatastale": "F995",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.occimiano.al.it",
      "pec": "protocollo@pec.comune.occimiano.al.it",
      "telefono": "+39 0142/809131",
      "fax": "+39 0142/809100",
      "coordinate": {
        "lat": 45.06111145019531,
        "lng": 8.508610725402832
      }
    },
    {
      "codice": "006116",
      "nome": "Odalengo Grande",
      "nomeStraniero": null,
      "codiceCatastale": "F997",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "odalengo.grande@ruparpiemonte.it",
      "pec": "odalengo.grande@cert.ruparpiemonte.it",
      "telefono": "+39 0142/949021",
      "fax": "+39 0142/949229",
      "coordinate": {
        "lat": 45.11000061035156,
        "lng": 8.168055534362793
      }
    },
    {
      "codice": "006117",
      "nome": "Odalengo Piccolo",
      "nomeStraniero": null,
      "codiceCatastale": "F998",
      "cap": "15020",
      "prefisso": "0141",
      "provincia": "Alessandria",
      "email": "info@comune.odalengopiccolo.al.it",
      "pec": "odalengopiccolo@pec.comune.odalengopiccolo.al.it",
      "telefono": "+39 0141/919126",
      "fax": "+39 0141/919900",
      "coordinate": {
        "lat": 45.0724983215332,
        "lng": 8.207221984863281
      }
    },
    {
      "codice": "006118",
      "nome": "Olivola",
      "nomeStraniero": null,
      "codiceCatastale": "G042",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "segreteria@comune.olivola.al.it",
      "pec": "olivola@pec.comune.olivola.al.it",
      "telefono": "+39 0142/928141",
      "fax": "+39 0142/928907",
      "coordinate": {
        "lat": 45.03738784790039,
        "lng": 8.36752700805664
      }
    },
    {
      "codice": "006119",
      "nome": "Orsara Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "G124",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.orsara.al.it",
      "pec": "comune.orsarabormida@pec.it",
      "telefono": "+39 0144/367021",
      "fax": "+39 0144/367020",
      "coordinate": {
        "lat": 44.690277099609375,
        "lng": 8.5625
      }
    },
    {
      "codice": "006120",
      "nome": "Ottiglio",
      "nomeStraniero": null,
      "codiceCatastale": "G193",
      "cap": "15038",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.ottiglio.al.it",
      "pec": "ottiglio@pec.comune.ottiglio.al.it",
      "telefono": "+39 0142/921129",
      "fax": "+39 0142/921120",
      "coordinate": {
        "lat": 45.05527877807617,
        "lng": 8.339722633361816
      }
    },
    {
      "codice": "006121",
      "nome": "Ovada",
      "nomeStraniero": null,
      "codiceCatastale": "G197",
      "cap": "15076",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "segreteria@comune.ovada.al.it",
      "pec": "posta@pec.comune.ovada.al.it",
      "telefono": "+39 0143 8361",
      "fax": "+39 0143/836222",
      "coordinate": {
        "lat": 44.63916778564453,
        "lng": 8.64638900756836
      }
    },
    {
      "codice": "006122",
      "nome": "Oviglio",
      "nomeStraniero": null,
      "codiceCatastale": "G199",
      "cap": "15026",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "ragioneria@comune.oviglio.al.it",
      "pec": "comune.oviglio@pec.it",
      "telefono": "+39 0131/776123",
      "fax": "+39 0131/776123",
      "coordinate": {
        "lat": 44.862220764160156,
        "lng": 8.48888874053955
      }
    },
    {
      "codice": "006123",
      "nome": "Ozzano Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "G204",
      "cap": "15039",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "segreteria@comune.ozzanomonferrato.al.it",
      "pec": "ozzanomonferrato@pec.comune.ozzanomenferrato.al.it",
      "telefono": "+39 0142/487153",
      "fax": "+39 0142/487038",
      "coordinate": {
        "lat": 45.10916519165039,
        "lng": 8.373055458068848
      }
    },
    {
      "codice": "006124",
      "nome": "Paderna",
      "nomeStraniero": null,
      "codiceCatastale": "G215",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comunedipaderna@libero.it",
      "pec": "comunedipaderna@pec.it",
      "telefono": "+39 0131/830128",
      "fax": "+39 0131/830002",
      "coordinate": {
        "lat": 44.821388244628906,
        "lng": 8.89222240447998
      }
    },
    {
      "codice": "006125",
      "nome": "Pareto",
      "nomeStraniero": null,
      "codiceCatastale": "G334",
      "cap": "15010",
      "prefisso": "019",
      "provincia": "Alessandria",
      "email": "segreteria@comune.pareto.al.it",
      "pec": "pareto@cert.ruparpiemonte.it",
      "telefono": "+39 019/721044",
      "fax": "+39 019/721290",
      "coordinate": {
        "lat": 44.51750183105469,
        "lng": 8.38277816772461
      }
    },
    {
      "codice": "006126",
      "nome": "Parodi Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "G338",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.parodiligure.al.it",
      "pec": "parodi.ligure@cert.ruparpiemonte.it",
      "telefono": "+39 0143/681105",
      "fax": "+39 0143/681481",
      "coordinate": {
        "lat": 44.670833587646484,
        "lng": 8.759721755981445
      }
    },
    {
      "codice": "006127",
      "nome": "Pasturana",
      "nomeStraniero": null,
      "codiceCatastale": "G367",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "pasturana@comune.pasturana.al.it",
      "pec": "protocollo@pec.comune.pasturana.al.it",
      "telefono": "+39 0143/58171",
      "fax": "+39 0143/58520",
      "coordinate": {
        "lat": 44.75166702270508,
        "lng": 8.750277519226074
      }
    },
    {
      "codice": "006128",
      "nome": "Pecetto di Valenza",
      "nomeStraniero": null,
      "codiceCatastale": "G397",
      "cap": "15040",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.pecetto.al.it",
      "pec": "protocollo@pec.comune.pecetto.al.it",
      "telefono": "+39 0131/940121",
      "fax": "+39 0131/940707",
      "coordinate": {
        "lat": 44.9908332824707,
        "lng": 8.671944618225098
      }
    },
    {
      "codice": "006129",
      "nome": "Pietra Marazzi",
      "nomeStraniero": null,
      "codiceCatastale": "G619",
      "cap": "15040",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "demografici@comune.pietramarazzi.al.it",
      "pec": "info@pec.comune.pietramarazzi.al.it",
      "telefono": "+39 0131/355124",
      "fax": "+39 0131/356914",
      "coordinate": {
        "lat": 44.94361114501953,
        "lng": 8.669722557067871
      }
    },
    {
      "codice": "006131",
      "nome": "Pomaro Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "G807",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "pomaro.monferrato@reteunitaria.piemonte.it",
      "pec": "pomaro.monferrato@cert.ruparpiemonte.it",
      "telefono": "+39 0142/60301",
      "fax": "+39 0141/60631",
      "coordinate": {
        "lat": 45.063331604003906,
        "lng": 8.597222328186035
      }
    },
    {
      "codice": "006132",
      "nome": "Pontecurone",
      "nomeStraniero": null,
      "codiceCatastale": "G839",
      "cap": "15055",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.pontecurone.al.it",
      "pec": "protocollo@pec.comune.pontecurone.al.it",
      "telefono": "+39 0131/885211",
      "fax": "+39 0131/885217",
      "coordinate": {
        "lat": 44.96111297607422,
        "lng": 8.9350004196167
      }
    },
    {
      "codice": "006133",
      "nome": "Pontestura",
      "nomeStraniero": null,
      "codiceCatastale": "G858",
      "cap": "15027",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.pontestura.al.it",
      "pec": "pontestura@pcert.it",
      "telefono": "+39 0142/466134",
      "fax": "+39 0142/466867",
      "coordinate": {
        "lat": 45.143333435058594,
        "lng": 8.334166526794434
      }
    },
    {
      "codice": "006134",
      "nome": "Ponti",
      "nomeStraniero": null,
      "codiceCatastale": "G861",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "segreteria@comuneponti.gov.it",
      "pec": "ponti@cert.ruparpiemonte.it",
      "telefono": "+39 0144/596142",
      "fax": "+39 0144/596273",
      "coordinate": {
        "lat": 44.62888717651367,
        "lng": 8.366389274597168
      }
    },
    {
      "codice": "006135",
      "nome": "Ponzano Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "G872",
      "cap": "15020",
      "prefisso": "0141",
      "provincia": "Alessandria",
      "email": "info@comune.ponzanomonferrato.al.it",
      "pec": "ponzanomonferrato@pec.comune.ponzanomonferrato.al.it",
      "telefono": "+39 0141/927135",
      "fax": "+39 0141/927307",
      "coordinate": {
        "lat": 45.08583450317383,
        "lng": 8.266666412353516
      }
    },
    {
      "codice": "006136",
      "nome": "Ponzone",
      "nomeStraniero": null,
      "codiceCatastale": "G877",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.ponzone.al.it",
      "pec": "anagrafe@pec.comuneponzone.it",
      "telefono": "+39 0144/78103",
      "fax": "+39 0144/78362",
      "coordinate": {
        "lat": 44.589168548583984,
        "lng": 8.460277557373047
      }
    },
    {
      "codice": "006137",
      "nome": "Pozzol Groppo",
      "nomeStraniero": null,
      "codiceCatastale": "G960",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.pozzolgroppo.al.it",
      "pec": "protocollo.pozzol.groppo@cert.ruparpiemonte.it",
      "telefono": "+39 0131/800501",
      "fax": "+39 0131/800103",
      "coordinate": {
        "lat": 44.87888717651367,
        "lng": 9.03083324432373
      }
    },
    {
      "codice": "006138",
      "nome": "Pozzolo Formigaro",
      "nomeStraniero": null,
      "codiceCatastale": "G961",
      "cap": "15068",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "segreteria@comune.pozzoloformigaro.al.it",
      "pec": "infopozzolo@legalmail.it",
      "telefono": "+39 0143/417054",
      "fax": "+39 0143/418872",
      "coordinate": {
        "lat": 44.796112060546875,
        "lng": 8.785833358764648
      }
    },
    {
      "codice": "006139",
      "nome": "Prasco",
      "nomeStraniero": null,
      "codiceCatastale": "G987",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.prasco.al.it",
      "pec": "prasco@pcert.it",
      "telefono": "+39 0144/375703",
      "fax": "+39 0144/375628",
      "coordinate": {
        "lat": 44.64027786254883,
        "lng": 8.553055763244629
      }
    },
    {
      "codice": "006140",
      "nome": "Predosa",
      "nomeStraniero": null,
      "codiceCatastale": "H021",
      "cap": "15077",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "demografici@comune.predosa.al.it",
      "pec": "protocollo@pec.comune.predosa.al.it",
      "telefono": "+39 0131/71224",
      "fax": "+39 0131/719889",
      "coordinate": {
        "lat": 44.75222396850586,
        "lng": 8.656389236450195
      }
    },
    {
      "codice": "006141",
      "nome": "Quargnento",
      "nomeStraniero": null,
      "codiceCatastale": "H104",
      "cap": "15044",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.quargnento.al.it",
      "pec": "protocollo@pec.comune.quargnento.al.it",
      "telefono": "+39 0131/219133",
      "fax": "+39 0131/219157",
      "coordinate": {
        "lat": 44.94583511352539,
        "lng": 8.488055229187012
      }
    },
    {
      "codice": "006142",
      "nome": "Quattordio",
      "nomeStraniero": null,
      "codiceCatastale": "H121",
      "cap": "15028",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.quattordio.al.it",
      "pec": "protocollo@pec.comune.quattordio.al.it",
      "telefono": "+39 0131/773581",
      "fax": "+39 0131/773861",
      "coordinate": {
        "lat": 44.897220611572266,
        "lng": 8.406110763549805
      }
    },
    {
      "codice": "006143",
      "nome": "Ricaldone",
      "nomeStraniero": null,
      "codiceCatastale": "H272",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comunericaldone.it",
      "pec": "comune.ricaldone@pec.it",
      "telefono": "+39 0144/74120",
      "fax": "+39 0144/745450",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 8.46916675567627
      }
    },
    {
      "codice": "006144",
      "nome": "Rivalta Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "H334",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.rivalta.al.it",
      "pec": "comune.rivalta.al@pec.it",
      "telefono": "+39 0144/372163",
      "fax": "+39 0144/372644",
      "coordinate": {
        "lat": 44.71083450317383,
        "lng": 8.552778244018555
      }
    },
    {
      "codice": "006145",
      "nome": "Rivarone",
      "nomeStraniero": null,
      "codiceCatastale": "H343",
      "cap": "15040",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "segreteria@comune.rivarone.al.it",
      "pec": "segreteria@pec.comune.rivarone.al.it",
      "telefono": "+39 0131/976127",
      "fax": null,
      "coordinate": {
        "lat": 44.977779388427734,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "006147",
      "nome": "Rocca Grimalda",
      "nomeStraniero": null,
      "codiceCatastale": "H414",
      "cap": "15078",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "affarigenerali@comuneroccagrimalda.it",
      "pec": "comuneroccagrimalda@legalmail.it",
      "telefono": "+39 0143/873121",
      "fax": "+39 0143/873494",
      "coordinate": {
        "lat": 44.67250061035156,
        "lng": 8.649444580078125
      }
    },
    {
      "codice": "006146",
      "nome": "Roccaforte Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "H406",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "roccaforteligure@gmail.com",
      "pec": "roccaforte.ligure@cert.ruparpiemonte.it",
      "telefono": "+39 0143/94162",
      "fax": "+39 0143/94162",
      "coordinate": {
        "lat": 44.6783332824707,
        "lng": 9.029722213745117
      }
    },
    {
      "codice": "006148",
      "nome": "Rocchetta Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "H465",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "rocchetta.ligure@ruparpiemonte.it",
      "pec": "rocchetta.ligure@cert.ruparpiemonte.it",
      "telefono": "+39 0143/90004",
      "fax": "+39 0143/90478",
      "coordinate": {
        "lat": 44.70750045776367,
        "lng": 9.05138874053955
      }
    },
    {
      "codice": "006149",
      "nome": "Rosignano Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "H569",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.rosignanomonferrato.al.it",
      "pec": "municipio@pec.comune.rosignanomonferrato.al.it",
      "telefono": "+39 0142/489009",
      "fax": "+39 0142/488959",
      "coordinate": {
        "lat": 45.081390380859375,
        "lng": 8.400833129882812
      }
    },
    {
      "codice": "006150",
      "nome": "Sala Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "H677",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.salamonferrato.al.it",
      "pec": "salamonferrato@pec.comune.salamonferrato.al.it",
      "telefono": "+39 0142/486721",
      "fax": "+39 0142/486773",
      "coordinate": {
        "lat": 45.07611083984375,
        "lng": 8.359722137451172
      }
    },
    {
      "codice": "006151",
      "nome": "Sale",
      "nomeStraniero": null,
      "codiceCatastale": "H694",
      "cap": "15045",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "protocollo@comune.sale.al.it",
      "pec": "sale@cert.ruparpiemonte.it",
      "telefono": "+39 0131/84178",
      "fax": "+39 0131/828288",
      "coordinate": {
        "lat": 44.981666564941406,
        "lng": 8.810277938842773
      }
    },
    {
      "codice": "006152",
      "nome": "San Cristoforo",
      "nomeStraniero": null,
      "codiceCatastale": "H810",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "comune.sancristoforo@libero.it",
      "pec": "protocollo.san.cristoforo@cert.ruparpiemonte.it",
      "telefono": "+39 0143/682120",
      "fax": "+39 0143/682260",
      "coordinate": {
        "lat": 44.69333267211914,
        "lng": 8.751111030578613
      }
    },
    {
      "codice": "006153",
      "nome": "San Giorgio Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "H878",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "amministrativo@comune.sangiorgiomonferrato.al.it",
      "pec": "sangiorgiomonferrato@pec.comune.sangiorgiomonferrato.al.it",
      "telefono": "+39 0142/806121",
      "fax": "+39 0142/806184",
      "coordinate": {
        "lat": 45.10768508911133,
        "lng": 8.413054466247559
      }
    },
    {
      "codice": "006154",
      "nome": "San Salvatore Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "I144",
      "cap": "15046",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "anagrafe@comune.sansalvatoremonferrato.al.it",
      "pec": "anagrafesansalvatoremonferrato@postecert.it",
      "telefono": "+39 0131/233122",
      "fax": "+39 0131/238208",
      "coordinate": {
        "lat": 44.994998931884766,
        "lng": 8.566944122314453
      }
    },
    {
      "codice": "006155",
      "nome": "San Sebastiano Curone",
      "nomeStraniero": null,
      "codiceCatastale": "I150",
      "cap": "15056",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "sansebastianonline@libero.it",
      "pec": "responsabilesscurone@postecert.it",
      "telefono": "+39 0131/786205",
      "fax": "+39 0131/786205",
      "coordinate": {
        "lat": 44.787498474121094,
        "lng": 9.06583309173584
      }
    },
    {
      "codice": "006156",
      "nome": "Sant'Agata Fossili",
      "nomeStraniero": null,
      "codiceCatastale": "I190",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.santagatafossili.al.it",
      "pec": "info@pec.comune.santagatafossili.al.it",
      "telefono": "+39 0131/837236",
      "fax": "+39 0131/837935",
      "coordinate": {
        "lat": 44.785831451416016,
        "lng": 8.922222137451172
      }
    },
    {
      "codice": "006157",
      "nome": "Sardigliano",
      "nomeStraniero": null,
      "codiceCatastale": "I429",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.sardigliano.al.it",
      "pec": "segretariocomunale.sardigliano@cert.ruparpiemonte.it",
      "telefono": "+39 0143/49000",
      "fax": "+39 0143/49240",
      "coordinate": {
        "lat": 44.75388717651367,
        "lng": 8.906389236450195
      }
    },
    {
      "codice": "006158",
      "nome": "Sarezzano",
      "nomeStraniero": null,
      "codiceCatastale": "I432",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.sarezzano.al.it",
      "pec": "protocollo@pec.comune.sarezzano.al.it",
      "telefono": "+39 0131/884440",
      "fax": "+39 0131/884121",
      "coordinate": {
        "lat": 44.86888885498047,
        "lng": 8.914166450500488
      }
    },
    {
      "codice": "006159",
      "nome": "Serralunga di Crea",
      "nomeStraniero": null,
      "codiceCatastale": "I645",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "serralunga.di.crea@ruparpiemonte.it",
      "pec": "serralunga.di.crea@cert.ruparpiemonte.it",
      "telefono": "+39 0142/940101",
      "fax": "+39 0142/940660",
      "coordinate": {
        "lat": 45.101112365722656,
        "lng": 8.283055305480957
      }
    },
    {
      "codice": "006160",
      "nome": "Serravalle Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "I657",
      "cap": "15069",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "segreteria@comune.serravalle-scrivia.al.it",
      "pec": "info@pec.comune.serravalle-scrivia.al.it",
      "telefono": "+39 0143/609411",
      "fax": "+39 0143/609499",
      "coordinate": {
        "lat": 44.72249984741211,
        "lng": 8.860278129577637
      }
    },
    {
      "codice": "006161",
      "nome": "Sezzadio",
      "nomeStraniero": null,
      "codiceCatastale": "I711",
      "cap": "15079",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comsez@tin.it",
      "pec": "comunesezzadio@pec.it",
      "telefono": "+39 0131/703119",
      "fax": "+39 0131/703240",
      "coordinate": {
        "lat": 44.785831451416016,
        "lng": 8.57361125946045
      }
    },
    {
      "codice": "006162",
      "nome": "Silvano d'Orba",
      "nomeStraniero": null,
      "codiceCatastale": "I738",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.silvanodorba.al.it",
      "pec": "sildor@pec.it",
      "telefono": "+39 0143841491",
      "fax": "+39 0143841154",
      "coordinate": {
        "lat": 44.68777847290039,
        "lng": 8.676666259765625
      }
    },
    {
      "codice": "006163",
      "nome": "Solero",
      "nomeStraniero": null,
      "codiceCatastale": "I798",
      "cap": "15029",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "solero@ruparpiemonte.it",
      "pec": "solero@cert.ruparpiemonte.it",
      "telefono": "+39 0131/217213",
      "fax": "+39 0131/217720",
      "coordinate": {
        "lat": 44.919166564941406,
        "lng": 8.508610725402832
      }
    },
    {
      "codice": "006164",
      "nome": "Solonghello",
      "nomeStraniero": null,
      "codiceCatastale": "I808",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.solonghello.al.it",
      "pec": "info.comune.solonghello.al@legalmail.it",
      "telefono": "+39 0142/944141",
      "fax": "+39 0142/944079",
      "coordinate": {
        "lat": 45.130001068115234,
        "lng": 8.28416633605957
      }
    },
    {
      "codice": "006165",
      "nome": "Spigno Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "I901",
      "cap": "15018",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.spignomonferrato.al.it",
      "pec": "spigno.monferrato@cert.ruparpiemonte.it",
      "telefono": "+39 0144/91155",
      "fax": "+39 0144/91244",
      "coordinate": {
        "lat": 44.544166564941406,
        "lng": 8.335000038146973
      }
    },
    {
      "codice": "006166",
      "nome": "Spineto Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "I911",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.spinetoscrivia.al.it",
      "pec": "spineto.scrivia@cert.ruparpiemonte.it",
      "telefono": "+39 0131/892136",
      "fax": "+39 0131/892136",
      "coordinate": {
        "lat": 44.83833312988281,
        "lng": 8.874722480773926
      }
    },
    {
      "codice": "006167",
      "nome": "Stazzano",
      "nomeStraniero": null,
      "codiceCatastale": "I941",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.stazzano.al.it",
      "pec": "protocollo@pec.comune.stazzano.al.it",
      "telefono": "+39 0143/65303",
      "fax": "+39 0143/62890",
      "coordinate": {
        "lat": 44.727394104003906,
        "lng": 8.867728233337402
      }
    },
    {
      "codice": "006168",
      "nome": "Strevi",
      "nomeStraniero": null,
      "codiceCatastale": "I977",
      "cap": "15019",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "sindaco@comune.strevi.al.it",
      "pec": "comune.strevi.al@pec.it",
      "telefono": "+39 0144/363124",
      "fax": "+39 0144/372741",
      "coordinate": {
        "lat": 44.69916534423828,
        "lng": 8.522777557373047
      }
    },
    {
      "codice": "006169",
      "nome": "Tagliolo Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "L027",
      "cap": "15070",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.tagliolomonferrato.al.it",
      "pec": "sindacotagliolo@actaliscertymail.it",
      "telefono": "+39 0143/89171",
      "fax": "+39 0143/896133",
      "coordinate": {
        "lat": 44.63916778564453,
        "lng": 8.66694450378418
      }
    },
    {
      "codice": "006170",
      "nome": "Tassarolo",
      "nomeStraniero": null,
      "codiceCatastale": "L059",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "demografici@comune.tassarolo.al.it",
      "pec": "protocollo@pec.comune.tassarolo.al.it",
      "telefono": "+39 0143/342003",
      "fax": "+39 0143/342956",
      "coordinate": {
        "lat": 44.72944259643555,
        "lng": 8.772500038146973
      }
    },
    {
      "codice": "006171",
      "nome": "Terruggia",
      "nomeStraniero": null,
      "codiceCatastale": "L139",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.terruggia.al.it",
      "pec": "terruggia@pec.comune.terruggia.al.it",
      "telefono": "+39 0142/401400",
      "fax": "+39 0142/401471",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 8.444167137145996
      }
    },
    {
      "codice": "006172",
      "nome": "Terzo",
      "nomeStraniero": null,
      "codiceCatastale": "L143",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "comuneterzo@comuneterzo.it",
      "pec": "comune.terzo.al@pec.it",
      "telefono": "+39 0144/594264",
      "fax": "+39 0144/594461",
      "coordinate": {
        "lat": 44.671390533447266,
        "lng": 8.422778129577637
      }
    },
    {
      "codice": "006173",
      "nome": "Ticineto",
      "nomeStraniero": null,
      "codiceCatastale": "L165",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "demografici@comune.ticineto.al.it",
      "pec": "protocollo@pec.comune.ticineto.al.it",
      "telefono": "+39 0142/411117",
      "fax": "+39 0142/411600",
      "coordinate": {
        "lat": 45.09722137451172,
        "lng": 8.552778244018555
      }
    },
    {
      "codice": "006174",
      "nome": "Tortona",
      "nomeStraniero": null,
      "codiceCatastale": "L304",
      "cap": "15057",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "webenginer@comune.tortona.al.it",
      "pec": "comune.tortona@pec.it",
      "telefono": "+39 0131/864220",
      "fax": "+39 0131/811411",
      "coordinate": {
        "lat": 44.8941650390625,
        "lng": 8.865555763244629
      }
    },
    {
      "codice": "006175",
      "nome": "Treville",
      "nomeStraniero": null,
      "codiceCatastale": "L403",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.treville.al.it",
      "pec": "treville@pec.comune.treville.al.it",
      "telefono": "+39 0142/497006",
      "fax": "+39 0142/497907",
      "coordinate": {
        "lat": 45.0977783203125,
        "lng": 8.360555648803711
      }
    },
    {
      "codice": "006176",
      "nome": "Trisobbio",
      "nomeStraniero": null,
      "codiceCatastale": "L432",
      "cap": "15070",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "trisobbio@ruparpiemonte.it",
      "pec": "trisobbio@cert.ruparpiemonte.it",
      "telefono": "+39 0143/871104",
      "fax": "+39 0143/871360",
      "coordinate": {
        "lat": 44.662498474121094,
        "lng": 8.5872220993042
      }
    },
    {
      "codice": "006177",
      "nome": "Valenza",
      "nomeStraniero": null,
      "codiceCatastale": "L570",
      "cap": "15048",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comune.valenza@comune.valenza.al.it",
      "pec": "comune@cert.comune.valenza.al.it",
      "telefono": "+39 0131/949111",
      "fax": "+39 0131/949275",
      "coordinate": {
        "lat": 45.01402282714844,
        "lng": 8.645821571350098
      }
    },
    {
      "codice": "006178",
      "nome": "Valmacca",
      "nomeStraniero": null,
      "codiceCatastale": "L633",
      "cap": "15040",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.valmacca.al.it",
      "pec": "protocollo@pec.comune.valmacca.al.it",
      "telefono": "+39 0142410154",
      "fax": "+39 0142410576",
      "coordinate": {
        "lat": 45.101112365722656,
        "lng": 8.584166526794434
      }
    },
    {
      "codice": "006179",
      "nome": "Vignale Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "L881",
      "cap": "15049",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.vignalemonferrato.al.it",
      "pec": "protocollo@pec.comune.vignalemonferrato.al.it",
      "telefono": "+39 0142/933001",
      "fax": "+39 0142/930956",
      "coordinate": {
        "lat": 45.01222229003906,
        "lng": 8.397222518920898
      }
    },
    {
      "codice": "006180",
      "nome": "Vignole Borbera",
      "nomeStraniero": null,
      "codiceCatastale": "L887",
      "cap": "15060",
      "prefisso": "0143",
      "provincia": "Alessandria",
      "email": "info@comune.vignoleborbera.al.it",
      "pec": "protocollo@pec.vignoleborbera.al.it",
      "telefono": "+39 0143/67301",
      "fax": "+39 0143/677980",
      "coordinate": {
        "lat": 44.71083450317383,
        "lng": 8.89222240447998
      }
    },
    {
      "codice": "006181",
      "nome": "Viguzzolo",
      "nomeStraniero": null,
      "codiceCatastale": "L904",
      "cap": "15058",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.viguzzolo.al.it",
      "pec": "protocollo@pec.comune.viguzzolo.al.it",
      "telefono": "+39 0131/898749",
      "fax": "+39 0131/898611",
      "coordinate": {
        "lat": 44.905277252197266,
        "lng": 8.92361068725586
      }
    },
    {
      "codice": "006182",
      "nome": "Villadeati",
      "nomeStraniero": null,
      "codiceCatastale": "L931",
      "cap": "15020",
      "prefisso": "0141",
      "provincia": "Alessandria",
      "email": "info@comune.villadeati.al.it",
      "pec": "villadeati@pec.comune.villadeati.al.it",
      "telefono": "+39 0141/902020",
      "fax": "+39 0141/902900",
      "coordinate": {
        "lat": 45.073333740234375,
        "lng": 8.168889045715332
      }
    },
    {
      "codice": "006183",
      "nome": "Villalvernia",
      "nomeStraniero": null,
      "codiceCatastale": "L963",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.villalvernia.al.it",
      "pec": "villalvernia@pcert.it",
      "telefono": "+39 0131/83152",
      "fax": "+39 0131/83509",
      "coordinate": {
        "lat": 44.81638717651367,
        "lng": 8.858333587646484
      }
    },
    {
      "codice": "006184",
      "nome": "Villamiroglio",
      "nomeStraniero": null,
      "codiceCatastale": "L970",
      "cap": "15020",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "protocollo@comune.villamiroglio.al.it",
      "pec": "villamiroglio@cert.ruparpiemonte.it",
      "telefono": "+39 0142/947101",
      "fax": "+39 0142/947907",
      "coordinate": {
        "lat": 45.135555267333984,
        "lng": 8.172222137451172
      }
    },
    {
      "codice": "006185",
      "nome": "Villanova Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "L972",
      "cap": "15030",
      "prefisso": "0142",
      "provincia": "Alessandria",
      "email": "info@comune.villanovamonferrato.al.it",
      "pec": "villanovamonferrato@pcert.it",
      "telefono": "+39 0142/483121",
      "fax": "+39 0142/483705",
      "coordinate": {
        "lat": 45.18388748168945,
        "lng": 8.480278015136719
      }
    },
    {
      "codice": "006186",
      "nome": "Villaromagnano",
      "nomeStraniero": null,
      "codiceCatastale": "M009",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "villaromagnano@tiscali.it",
      "pec": "protocollo@pec.comune.villaromagnano.al.it",
      "telefono": "+39 0131/892132",
      "fax": "+39 0131/892202",
      "coordinate": {
        "lat": 44.850555419921875,
        "lng": 8.88888931274414
      }
    },
    {
      "codice": "006187",
      "nome": "Visone",
      "nomeStraniero": null,
      "codiceCatastale": "M077",
      "cap": "15010",
      "prefisso": "0144",
      "provincia": "Alessandria",
      "email": "info@comune.visone.al.it",
      "pec": "visone@pcert.it",
      "telefono": "+39 0144.395297",
      "fax": "+39 0144.395800",
      "coordinate": {
        "lat": 44.662776947021484,
        "lng": 8.501667022705078
      }
    },
    {
      "codice": "006188",
      "nome": "Volpedo",
      "nomeStraniero": null,
      "codiceCatastale": "M120",
      "cap": "15059",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "info@comune.volpedo.al.it",
      "pec": "volpedo@pcert.it",
      "telefono": "+39 0131/80141",
      "fax": "+39 0131/806577",
      "coordinate": {
        "lat": 44.891109466552734,
        "lng": 8.98277759552002
      }
    },
    {
      "codice": "006189",
      "nome": "Volpeglino",
      "nomeStraniero": null,
      "codiceCatastale": "M121",
      "cap": "15050",
      "prefisso": "0131",
      "provincia": "Alessandria",
      "email": "comune.volpeglino@tiscalinet.it",
      "pec": "info@pec.comune.volpeglino.al.it",
      "telefono": "+39 0131/80182",
      "fax": "+39 0131/806035",
      "coordinate": {
        "lat": 44.893890380859375,
        "lng": 8.960556030273438
      }
    },
    {
      "codice": "006190",
      "nome": "Voltaggio",
      "nomeStraniero": null,
      "codiceCatastale": "M123",
      "cap": "15060",
      "prefisso": "010",
      "provincia": "Alessandria",
      "email": "info@comune.voltaggio.al.it",
      "pec": "protocollo@pec.comune.voltaggio.al.it",
      "telefono": "+39 010/9601214",
      "fax": "+39 0109600721",
      "coordinate": {
        "lat": 44.62277603149414,
        "lng": 8.84416675567627
      }
    }
  ],
  "Bergamo": [
    {
      "codice": "016001",
      "nome": "Adrara San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "A057",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria.protocollo@comune.adrarasm.bg.it",
      "pec": "protocollo.comune.adrarasanmartino@pec.regione.lombardia.it",
      "telefono": "+39 035933003",
      "fax": "+39 035934354",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "016002",
      "nome": "Adrara San Rocco",
      "nomeStraniero": null,
      "codiceCatastale": "A058",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.adrarasanrocco.bg.it",
      "pec": "comune.adrarasanrocco.bg@pec.it",
      "telefono": "+39 035933053",
      "fax": null,
      "coordinate": {
        "lat": 45.714168548583984,
        "lng": 9.958333015441895
      }
    },
    {
      "codice": "016003",
      "nome": "Albano Sant'Alessandro",
      "nomeStraniero": null,
      "codiceCatastale": "A129",
      "cap": "24061",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.albano.bg.it",
      "pec": "postacert@pec.comune.albano.bg.it",
      "telefono": "+39 0354239211",
      "fax": "+39 0354239208",
      "coordinate": {
        "lat": 45.68583297729492,
        "lng": 9.767777442932129
      }
    },
    {
      "codice": "016004",
      "nome": "Albino",
      "nomeStraniero": null,
      "codiceCatastale": "A163",
      "cap": "24021",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ced@albino.it",
      "pec": "protocollo.albino@cert.saga.it",
      "telefono": "+39 035759926",
      "fax": "+39 035754718",
      "coordinate": {
        "lat": 45.760555267333984,
        "lng": 9.796944618225098
      }
    },
    {
      "codice": "016248",
      "nome": "Algua",
      "nomeStraniero": null,
      "codiceCatastale": "A193",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.algua.bg.it",
      "pec": "comune.algua@pec.regione.lombardia.it",
      "telefono": "+39 034597009",
      "fax": "+39 034597426",
      "coordinate": {
        "lat": 45.8261833190918,
        "lng": 9.722151756286621
      }
    },
    {
      "codice": "016005",
      "nome": "Almè",
      "nomeStraniero": null,
      "codiceCatastale": "A214",
      "cap": "24011",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "pierangelo.sonzogni@comune.alme.bg.it",
      "pec": "comune.alme@postemailcertificata.it",
      "telefono": "+39 035541037",
      "fax": "+39 035639709",
      "coordinate": {
        "lat": 45.73694610595703,
        "lng": 9.615278244018555
      }
    },
    {
      "codice": "016006",
      "nome": "Almenno San Bartolomeo",
      "nomeStraniero": null,
      "codiceCatastale": "A216",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.almennosanbartolomeo.bergamo.it",
      "pec": "comune@pec.comune.almennosanbartolomeo.bergamo.it",
      "telefono": "+39 0356320111",
      "fax": "+39 0356320128",
      "coordinate": {
        "lat": 45.7488899230957,
        "lng": 9.57972240447998
      }
    },
    {
      "codice": "016007",
      "nome": "Almenno San Salvatore",
      "nomeStraniero": null,
      "codiceCatastale": "A217",
      "cap": "24031",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "urp@comune.almenno-san-salvatore.bg.it",
      "pec": "comunealmennoss@pec.it",
      "telefono": "+39 0356320211",
      "fax": "+39 035643041",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.587499618530273
      }
    },
    {
      "codice": "016008",
      "nome": "Alzano Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "A246",
      "cap": "24022",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.alzano.bg.it",
      "pec": "protocollo@pec.comune.alzano.bg.it",
      "telefono": "+39 0354289000",
      "fax": "+39 0354289034",
      "coordinate": {
        "lat": 45.731666564941406,
        "lng": 9.728333473205566
      }
    },
    {
      "codice": "016009",
      "nome": "Ambivere",
      "nomeStraniero": null,
      "codiceCatastale": "A259",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "urp@comune.ambivere.bg.it",
      "pec": "comune.ambivere@pec.regione.lombardia.it",
      "telefono": "+39 035908024",
      "fax": "+39 0354945905",
      "coordinate": {
        "lat": 45.717777252197266,
        "lng": 9.54861068725586
      }
    },
    {
      "codice": "016010",
      "nome": "Antegnate",
      "nomeStraniero": null,
      "codiceCatastale": "A304",
      "cap": "24051",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "demografici@comune.antegnate.bg.it",
      "pec": "info@pec.comune.antegnate.bg.it",
      "telefono": "+39 0363914043",
      "fax": "+39 0363905430",
      "coordinate": {
        "lat": 45.48527908325195,
        "lng": 9.788888931274414
      }
    },
    {
      "codice": "016011",
      "nome": "Arcene",
      "nomeStraniero": null,
      "codiceCatastale": "A365",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.arcene.bg.it",
      "pec": "protocollo@pec.comune.arcene.bg.it",
      "telefono": "+39 0354199214",
      "fax": "+39 0354199219",
      "coordinate": {
        "lat": 45.57611083984375,
        "lng": 9.614166259765625
      }
    },
    {
      "codice": "016012",
      "nome": "Ardesio",
      "nomeStraniero": null,
      "codiceCatastale": "A383",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "tecnico3@comune.ardesio.bg.it",
      "pec": "comune.ardesio@pec.regione.lombardia.it",
      "telefono": "+39 034633026",
      "fax": "+39 034634280",
      "coordinate": {
        "lat": 45.93805694580078,
        "lng": 9.930000305175781
      }
    },
    {
      "codice": "016013",
      "nome": "Arzago d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "A440",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "segreteria@comune.arzago.bg.it",
      "pec": "comune.arzagodadda@legalmail.it",
      "telefono": "+39 036387822",
      "fax": "+39 0363326885",
      "coordinate": {
        "lat": 45.4819450378418,
        "lng": 9.563333511352539
      }
    },
    {
      "codice": "016014",
      "nome": "Averara",
      "nomeStraniero": null,
      "codiceCatastale": "A511",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.averara.bg.it",
      "pec": "comune.averara@pec.regione.lombardia.it",
      "telefono": "+39 034580313",
      "fax": "+39 034580313",
      "coordinate": {
        "lat": 45.988887786865234,
        "lng": 9.631388664245605
      }
    },
    {
      "codice": "016015",
      "nome": "Aviatico",
      "nomeStraniero": null,
      "codiceCatastale": "A517",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comunediaviatico.it",
      "pec": "comune.aviatico@pec.regione.lombardia.it",
      "telefono": "+39 035763250",
      "fax": "+39 035765145",
      "coordinate": {
        "lat": 45.80055618286133,
        "lng": 9.771111488342285
      }
    },
    {
      "codice": "016016",
      "nome": "Azzano San Paolo",
      "nomeStraniero": null,
      "codiceCatastale": "A528",
      "cap": "24052",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "servizio.demografico@comune.azzanosanpaolo.bg.it",
      "pec": "servizidemograficiazzanosp@pec.it",
      "telefono": "+39 035532287",
      "fax": "+39 0354511105",
      "coordinate": {
        "lat": 45.66055679321289,
        "lng": 9.675555229187012
      }
    },
    {
      "codice": "016017",
      "nome": "Azzone",
      "nomeStraniero": null,
      "codiceCatastale": "A533",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.azzone.bg.it",
      "pec": "comune.azzone@pec.it",
      "telefono": "+39 034654001",
      "fax": "+39 034653777",
      "coordinate": {
        "lat": 45.97861099243164,
        "lng": 10.111944198608398
      }
    },
    {
      "codice": "016018",
      "nome": "Bagnatica",
      "nomeStraniero": null,
      "codiceCatastale": "A557",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comunedibagnatica.it",
      "pec": "info@pec.comune.bagnatica.bg.it",
      "telefono": "+39 035689511",
      "fax": "+39 035689335",
      "coordinate": {
        "lat": 45.66194534301758,
        "lng": 9.781110763549805
      }
    },
    {
      "codice": "016019",
      "nome": "Barbata",
      "nomeStraniero": null,
      "codiceCatastale": "A631",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "barbata@comune.barbata.bg.it",
      "pec": "comune.barbata@pec.regione.lombardia.it",
      "telefono": "+39 0363 914071",
      "fax": "+39 0363 905333",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 9.783332824707031
      }
    },
    {
      "codice": "016020",
      "nome": "Bariano",
      "nomeStraniero": null,
      "codiceCatastale": "A664",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "giuliana.consolandi@comune.bariano.bg.it",
      "pec": "comune.bariano@legalmail.it",
      "telefono": "+39 036395022",
      "fax": "+39 0363957941",
      "coordinate": {
        "lat": 45.51258087158203,
        "lng": 9.703660011291504
      }
    },
    {
      "codice": "016021",
      "nome": "Barzana",
      "nomeStraniero": null,
      "codiceCatastale": "A684",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.barzana.bg.it",
      "pec": "comune@pec.comune.barzana.bg.it",
      "telefono": "+39 0355788501",
      "fax": "+39 035540189",
      "coordinate": {
        "lat": 45.733890533447266,
        "lng": 9.567500114440918
      }
    },
    {
      "codice": "016022",
      "nome": "Bedulita",
      "nomeStraniero": null,
      "codiceCatastale": "A732",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.bedulita.bg.it",
      "pec": "bedulitacomune@pec.it",
      "telefono": "+39 035863031",
      "fax": "+39 035864735",
      "coordinate": {
        "lat": 45.79138946533203,
        "lng": 9.551111221313477
      }
    },
    {
      "codice": "016023",
      "nome": "Berbenno",
      "nomeStraniero": null,
      "codiceCatastale": "A786",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.berbenno.bg.it",
      "pec": "comuneberbenno@legalmail.it",
      "telefono": "+39 035861002",
      "fax": "+39 035860282",
      "coordinate": {
        "lat": 45.8136100769043,
        "lng": 9.568333625793457
      }
    },
    {
      "codice": "016024",
      "nome": "Bergamo",
      "nomeStraniero": null,
      "codiceCatastale": "A794",
      "cap": "24121",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "rbenaglia@comune.bg.it",
      "pec": "protocollo@cert.comune.bergamo.it",
      "telefono": "+39 035399607",
      "fax": "+39 035399000",
      "coordinate": {
        "lat": 45.69499969482422,
        "lng": 9.670000076293945
      }
    },
    {
      "codice": "016025",
      "nome": "Berzo San Fermo",
      "nomeStraniero": null,
      "codiceCatastale": "A815",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.berzo-san-fermo.bg.it",
      "pec": "protocollo@comuneberzosanfermo.legalmail.it",
      "telefono": "+39 035821122",
      "fax": "+39 035821442",
      "coordinate": {
        "lat": 45.71916580200195,
        "lng": 9.902777671813965
      }
    },
    {
      "codice": "016026",
      "nome": "Bianzano",
      "nomeStraniero": null,
      "codiceCatastale": "A846",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.bianzano.bg.it",
      "pec": "protocollo@comunebianzano.legalmail.it",
      "telefono": "+39 035814001",
      "fax": "+39 035816556",
      "coordinate": {
        "lat": 45.773887634277344,
        "lng": 9.91944408416748
      }
    },
    {
      "codice": "016027",
      "nome": "Blello",
      "nomeStraniero": null,
      "codiceCatastale": "A903",
      "cap": "24012",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.blello.bg.it",
      "pec": "comune.blello@pec.regione.lombardia.it",
      "telefono": "+39 034599309",
      "fax": "+39 034599309",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.583333015441895
      }
    },
    {
      "codice": "016028",
      "nome": "Bolgare",
      "nomeStraniero": null,
      "codiceCatastale": "A937",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.bolgare.bg.it",
      "pec": "comune.bolgare@cert.legalmail.it",
      "telefono": "+39 0354493911",
      "fax": "+39 0354493920",
      "coordinate": {
        "lat": 45.6341667175293,
        "lng": 9.816944122314453
      }
    },
    {
      "codice": "016029",
      "nome": "Boltiere",
      "nomeStraniero": null,
      "codiceCatastale": "A950",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.boltiere.bg.it",
      "pec": "comune.boltiere@postecert.it",
      "telefono": "+39 035806161",
      "fax": "+39 035806488",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.583333015441895
      }
    },
    {
      "codice": "016030",
      "nome": "Bonate Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "A963",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "urp@comune.bonatesopra.bg.it",
      "pec": "comune.bonatesopra.bg@halleycert.it",
      "telefono": "+39 0354996111",
      "fax": "+39 035994374",
      "coordinate": {
        "lat": 45.68194580078125,
        "lng": 9.558610916137695
      }
    },
    {
      "codice": "016031",
      "nome": "Bonate Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "A962",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "urp@comune.bonate-sotto.bg.it",
      "pec": "comune.bonatesotto@pec.regione.lombardia.it",
      "telefono": "+39 0354996011",
      "fax": "+39 0354996036",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.561388969421387
      }
    },
    {
      "codice": "016032",
      "nome": "Borgo di Terzo",
      "nomeStraniero": null,
      "codiceCatastale": "B010",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.borgo-di-terzo.bg.it",
      "pec": "comune.borgoditerzo@pec.regione.lombardia.it",
      "telefono": "+39 035821046",
      "fax": "+39 035820861",
      "coordinate": {
        "lat": 45.72083282470703,
        "lng": 9.893888473510742
      }
    },
    {
      "codice": "016033",
      "nome": "Bossico",
      "nomeStraniero": null,
      "codiceCatastale": "B083",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.bossico.bg.it",
      "pec": "comune.bossico@pec.regione.lombardia.it",
      "telefono": "+39 035968020",
      "fax": "+39 035968214",
      "coordinate": {
        "lat": 45.82749938964844,
        "lng": 10.044722557067871
      }
    },
    {
      "codice": "016034",
      "nome": "Bottanuco",
      "nomeStraniero": null,
      "codiceCatastale": "B088",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comune.bottanuco@tin.it",
      "pec": "comune.bottanuco@postecert.it",
      "telefono": "+39 035907191",
      "fax": "+39 035906192",
      "coordinate": {
        "lat": 45.63972091674805,
        "lng": 9.506388664245605
      }
    },
    {
      "codice": "016035",
      "nome": "Bracca",
      "nomeStraniero": null,
      "codiceCatastale": "B112",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.bracca.bg.it",
      "pec": "comune.bracca@pec.regione.lombardia.it",
      "telefono": "+39 034597123",
      "fax": "+39 034597641",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.716666221618652
      }
    },
    {
      "codice": "016036",
      "nome": "Branzi",
      "nomeStraniero": null,
      "codiceCatastale": "B123",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.branzi.bg.it",
      "pec": "comune.branzi@legalmail.it",
      "telefono": "+39 034571006",
      "fax": "+39 034571363",
      "coordinate": {
        "lat": 46,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "016037",
      "nome": "Brembate",
      "nomeStraniero": null,
      "codiceCatastale": "B137",
      "cap": "24041",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "carlo_paris@comune.brembate.bg.it",
      "pec": "protocollo_brembate@legalmail.it",
      "telefono": "+39 0354816020",
      "fax": "+39 0354816033",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "016038",
      "nome": "Brembate di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "B138",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.brembatedisopra.bg.it",
      "pec": "pec@pec.comune.brembatedisopra.bg.it",
      "telefono": "+39 035623300",
      "fax": "+39 035623353",
      "coordinate": {
        "lat": 45.71766662597656,
        "lng": 9.580647468566895
      }
    },
    {
      "codice": "016040",
      "nome": "Brignano Gera d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "B178",
      "cap": "24053",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "info@comune.brignano.bg.it",
      "pec": "comune.brignano@pec.regione.lombardia.it",
      "telefono": "+39 0363815011",
      "fax": "+39 0363382263",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "016041",
      "nome": "Brumano",
      "nomeStraniero": null,
      "codiceCatastale": "B217",
      "cap": "24037",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demografici@comune.brumano.bg.it",
      "pec": "comune.brumano@pec.regione.lombardia.it",
      "telefono": "+39 035868426",
      "fax": "+39 035868426",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.5
      }
    },
    {
      "codice": "016042",
      "nome": "Brusaporto",
      "nomeStraniero": null,
      "codiceCatastale": "B223",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.brusaporto.bg.it",
      "pec": "comune.brusaporto@pec.regione.lombardia.it",
      "telefono": "+39 0356667715",
      "fax": "+39 0356667730",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "016043",
      "nome": "Calcinate",
      "nomeStraniero": null,
      "codiceCatastale": "B393",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.calcinate.bg.it",
      "pec": "comune.calcinate.bg@pec.it",
      "telefono": "+39 0354493311",
      "fax": "+39 0354493390",
      "coordinate": {
        "lat": 45.62120056152344,
        "lng": 9.800299644470215
      }
    },
    {
      "codice": "016044",
      "nome": "Calcio",
      "nomeStraniero": null,
      "codiceCatastale": "B395",
      "cap": "24054",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "fabio.tilotta@comune.calcio.bg.it",
      "pec": "info@cert.comune.calcio.bg.it",
      "telefono": "+39 0363968444",
      "fax": "+39 0363906246",
      "coordinate": {
        "lat": 45.50833511352539,
        "lng": 9.85083293914795
      }
    },
    {
      "codice": "016046",
      "nome": "Calusco d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "B434",
      "cap": "24033",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ufficiotecnico@comune.caluscodadda.bg.it",
      "pec": "protocollo@pec.comune.caluscodadda.bg.it",
      "telefono": "+39 0354389039",
      "fax": "+39 0354389076",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "016047",
      "nome": "Calvenzano",
      "nomeStraniero": null,
      "codiceCatastale": "B442",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "segreteria@comunecalvenzano.net",
      "pec": "comune.calvenzano@pec.regione.lombardia.it",
      "telefono": "+39 0363860733",
      "fax": "+39 0363860799",
      "coordinate": {
        "lat": 45.494998931884766,
        "lng": 9.599444389343262
      }
    },
    {
      "codice": "016048",
      "nome": "Camerata Cornello",
      "nomeStraniero": null,
      "codiceCatastale": "B471",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.cameratacornello.bg.it",
      "pec": "comune.cameratacornello@pec.regione.lombardia.it",
      "telefono": "+39 034543543",
      "fax": "+39 034542556",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "016049",
      "nome": "Canonica d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "B618",
      "cap": "24040",
      "prefisso": "02",
      "provincia": "Bergamo",
      "email": "affarigenerali@comune.canonicadadda.bg.it",
      "pec": "comune.canonicadadda.bg@pec.regione.lombardia.it",
      "telefono": "+39 029094016.2",
      "fax": "+39 029095023",
      "coordinate": {
        "lat": 45.575279235839844,
        "lng": 9.538888931274414
      }
    },
    {
      "codice": "016050",
      "nome": "Capizzone",
      "nomeStraniero": null,
      "codiceCatastale": "B661",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demografici@comune.capizzone.bg.it",
      "pec": "comune.capizzone@pec.regione.lombardia.it",
      "telefono": "+39 035863045",
      "fax": "+39 035864721",
      "coordinate": {
        "lat": 45.78555679321289,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "016051",
      "nome": "Capriate San Gervasio",
      "nomeStraniero": null,
      "codiceCatastale": "B703",
      "cap": "24042",
      "prefisso": "02",
      "provincia": "Bergamo",
      "email": "segreteria@comune.capriate-san-gervasio.bg.it",
      "pec": "posta@pec.comune.capriate-san-gervasio.bg.it",
      "telefono": "+39 02920991280",
      "fax": "+39 02920991209",
      "coordinate": {
        "lat": 45.608890533447266,
        "lng": 9.528055191040039
      }
    },
    {
      "codice": "016052",
      "nome": "Caprino Bergamasco",
      "nomeStraniero": null,
      "codiceCatastale": "B710",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.caprinobergamasco.bg.it",
      "pec": "segreteria@pec.comune.caprinobergamasco.bg.it",
      "telefono": "+39 035781024",
      "fax": "+39 035787510",
      "coordinate": {
        "lat": 45.74528121948242,
        "lng": 9.4817476272583
      }
    },
    {
      "codice": "016053",
      "nome": "Caravaggio",
      "nomeStraniero": null,
      "codiceCatastale": "B731",
      "cap": "24043",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "protocollo@comune.caravaggio.bg.it",
      "pec": "urp@pec.comune.caravaggio.bg.it",
      "telefono": "+39 03633561",
      "fax": "+39 0363350164",
      "coordinate": {
        "lat": 45.49777603149414,
        "lng": 9.64305591583252
      }
    },
    {
      "codice": "016055",
      "nome": "Carobbio degli Angeli",
      "nomeStraniero": null,
      "codiceCatastale": "B801",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "uff.protocollo@comune.carobbio.bg.it",
      "pec": "comune.carobbio@postecert.it",
      "telefono": "+39 0354276201",
      "fax": "+39 035953518",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "016056",
      "nome": "Carona",
      "nomeStraniero": null,
      "codiceCatastale": "B803",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.carona.bg.it",
      "pec": "comune.carona@legalmail.it",
      "telefono": "+39 034577006",
      "fax": "+39 034577048",
      "coordinate": {
        "lat": 46.022220611572266,
        "lng": 9.783888816833496
      }
    },
    {
      "codice": "016057",
      "nome": "Carvico",
      "nomeStraniero": null,
      "codiceCatastale": "B854",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.carvico.bg.it",
      "pec": "comune.carvico@pec.regione.lombardia.it",
      "telefono": "+39 035791127",
      "fax": "+39 035797080",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "016058",
      "nome": "Casazza",
      "nomeStraniero": null,
      "codiceCatastale": "B947",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.casazza.bg.it",
      "pec": "uff.protocollo@pec.comune.casazza.bg.it",
      "telefono": "+39 035816060",
      "fax": "+39 0350662448",
      "coordinate": {
        "lat": 45.74855041503906,
        "lng": 9.906270027160645
      }
    },
    {
      "codice": "016059",
      "nome": "Casirate d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "B971",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "sindaco@comune.casirate.bg.it",
      "pec": "comune.casirate@halleycert.it",
      "telefono": "+39 0363326688",
      "fax": "+39 0363326460",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "016060",
      "nome": "Casnigo",
      "nomeStraniero": null,
      "codiceCatastale": "B978",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.casnigo.bg.it",
      "pec": "protocollo@cert.casnigo.it",
      "telefono": "+39 035740001",
      "fax": "+39 035740069",
      "coordinate": {
        "lat": 45.81638717651367,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "016061",
      "nome": "Cassiglio",
      "nomeStraniero": null,
      "codiceCatastale": "C007",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.cassiglio.bg.it",
      "pec": "comune.cassiglio@pec.regione.lombardia.it",
      "telefono": "+39 034587081",
      "fax": "+39 034587081",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.612222671508789
      }
    },
    {
      "codice": "016063",
      "nome": "Castel Rozzone",
      "nomeStraniero": null,
      "codiceCatastale": "C255",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "segreteria@comune.castelrozzone.bg.it",
      "pec": "egov.castelrozzone@cert.poliscomuneamico.net",
      "telefono": "+39 0363381490",
      "fax": "+39 0363382176",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "016062",
      "nome": "Castelli Calepio",
      "nomeStraniero": null,
      "codiceCatastale": "C079",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.castellicalepio.bg.it",
      "pec": "comune.castellicalepio@legalmail.it",
      "telefono": "+39 0354494111",
      "fax": "+39 0354425000",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "016064",
      "nome": "Castione della Presolana",
      "nomeStraniero": null,
      "codiceCatastale": "C324",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.castione.bg.it",
      "pec": "comune.castionedellapresolana@cert.legalmail.it",
      "telefono": "+39 034660017",
      "fax": "+39 034660045",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 10.033333778381348
      }
    },
    {
      "codice": "016065",
      "nome": "Castro",
      "nomeStraniero": null,
      "codiceCatastale": "C337",
      "cap": "24063",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.castro.bg.it",
      "pec": "comune.castro@pec.regione.lombardia.it",
      "telefono": "+39 035960666",
      "fax": null,
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 10.066666603088379
      }
    },
    {
      "codice": "016066",
      "nome": "Cavernago",
      "nomeStraniero": null,
      "codiceCatastale": "C396",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.cavernago.bg.it",
      "pec": "comune.cavernago@pec.regione.lombardia.it",
      "telefono": "+39 035840513",
      "fax": "+39 035840575",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "016067",
      "nome": "Cazzano Sant'Andrea",
      "nomeStraniero": null,
      "codiceCatastale": "C410",
      "cap": "24026",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demografici@comune.cazzano.bg.it",
      "pec": "comune.cazzanosantandrea@pec.regione.lombardia.it",
      "telefono": "+39 035724033",
      "fax": "+39 035724125",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "016068",
      "nome": "Cenate Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "C456",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.cenatesopra.bg.it",
      "pec": "comunecenatesopra@halleycert.it",
      "telefono": "+39 035956003",
      "fax": "+39 035956548",
      "coordinate": {
        "lat": 45.711666107177734,
        "lng": 9.822221755981445
      }
    },
    {
      "codice": "016069",
      "nome": "Cenate Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "C457",
      "cap": "24069",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demo@comune.cenatesotto.bg.it",
      "pec": "protocollo@pec.comune.cenatesotto.b.git",
      "telefono": "+39 035940151",
      "fax": "+39 035944647",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.816666603088379
      }
    },
    {
      "codice": "016070",
      "nome": "Cene",
      "nomeStraniero": null,
      "codiceCatastale": "C459",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ragioneria@comune.cene.bg.it",
      "pec": "protocollo.cene@legalmail.it",
      "telefono": "+39 035718111",
      "fax": "+39 035718087",
      "coordinate": {
        "lat": 45.77944564819336,
        "lng": 9.825833320617676
      }
    },
    {
      "codice": "016071",
      "nome": "Cerete",
      "nomeStraniero": null,
      "codiceCatastale": "C506",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.cerete.bg.it",
      "pec": "comune.cerete@pec.regione.lombardia.it",
      "telefono": "+39 034663300",
      "fax": "+39 034663177",
      "coordinate": {
        "lat": 45.86762237548828,
        "lng": 9.994128227233887
      }
    },
    {
      "codice": "016072",
      "nome": "Chignolo d'Isola",
      "nomeStraniero": null,
      "codiceCatastale": "C635",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demografici@comune.chignolodisola.bg.it",
      "pec": "protocollo@pec.comune.chignolodisola.bg.it",
      "telefono": "+39 0354949327",
      "fax": "+39 0354940905",
      "coordinate": {
        "lat": 45.66638946533203,
        "lng": 9.52750015258789
      }
    },
    {
      "codice": "016073",
      "nome": "Chiuduno",
      "nomeStraniero": null,
      "codiceCatastale": "C649",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.chiuduno.bg.it",
      "pec": "chiuduno@pec.comune.chiuduno.bg.it",
      "telefono": "+39 035838397",
      "fax": null,
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "016074",
      "nome": "Cisano Bergamasco",
      "nomeStraniero": null,
      "codiceCatastale": "C728",
      "cap": "24034",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.cisano.bg.it",
      "pec": "comune.cisano.bg@postecert.it",
      "telefono": "+39 0354387811",
      "fax": "+39 035782350",
      "coordinate": {
        "lat": 45.74305725097656,
        "lng": 9.470833778381348
      }
    },
    {
      "codice": "016075",
      "nome": "Ciserano",
      "nomeStraniero": null,
      "codiceCatastale": "C730",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ufficiosegreteria@comune.ciserano.bg.it",
      "pec": "comune.ciserano@pec.regione.lombardia.it",
      "telefono": "+39 035883108",
      "fax": "+39 035882616",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "016076",
      "nome": "Cividate al Piano",
      "nomeStraniero": null,
      "codiceCatastale": "C759",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "segreteria@comune.cividatealpiano.bg.it",
      "pec": "posta@pec.comune.cividatealpiano.bg.it",
      "telefono": "+39 0363946411",
      "fax": "+39 0363976100",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.816666603088379
      }
    },
    {
      "codice": "016077",
      "nome": "Clusone",
      "nomeStraniero": null,
      "codiceCatastale": "C800",
      "cap": "24023",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "protocollo@comune.clusone.bg.it",
      "pec": "protocollo@pec.comune.clusone.bg.it",
      "telefono": "+39 034689600",
      "fax": "+39 034623014",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "016078",
      "nome": "Colere",
      "nomeStraniero": null,
      "codiceCatastale": "C835",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.colere.bg.it",
      "pec": "protocollo@pec.comune.colere.bg.it",
      "telefono": "+39 034654061",
      "fax": "+39 034654061",
      "coordinate": {
        "lat": 45.973331451416016,
        "lng": 10.08055591583252
      }
    },
    {
      "codice": "016079",
      "nome": "Cologno al Serio",
      "nomeStraniero": null,
      "codiceCatastale": "C894",
      "cap": "24055",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.colognoalserio.bg.it",
      "pec": "comune.colognoalserio@postecert.it",
      "telefono": "+39 0354183501",
      "fax": "+39 035890445",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "016080",
      "nome": "Colzate",
      "nomeStraniero": null,
      "codiceCatastale": "C910",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.colzate.bg.it",
      "pec": "segreteria@pec.comune.colzate.bg.it",
      "telefono": "+39 035711361",
      "fax": "+39 035720591",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "016081",
      "nome": "Comun Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "C937",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ufficioprotocollo@comune.comunnuovo.bg.it",
      "pec": "comune.comun_nuovo@pec.regione.lombardia.it",
      "telefono": "+39 035595043",
      "fax": "+39 035595575",
      "coordinate": {
        "lat": 45.62277603149414,
        "lng": 9.66361141204834
      }
    },
    {
      "codice": "016082",
      "nome": "Corna Imagna",
      "nomeStraniero": null,
      "codiceCatastale": "D015",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comune.cornaimagna@virgilio.it",
      "pec": "comune.cornaimagna@pec.regione.lombardia.it",
      "telefono": "+39 035856277",
      "fax": null,
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "016249",
      "nome": "Cornalba",
      "nomeStraniero": null,
      "codiceCatastale": "D016",
      "cap": "24017",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.cornalba.bg.it",
      "pec": "comune.cornalba@pec.regione.lombardia.it",
      "telefono": "+39 034556082",
      "fax": "+39 034556060",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.75
      }
    },
    {
      "codice": "016083",
      "nome": "Cortenuova",
      "nomeStraniero": null,
      "codiceCatastale": "D066",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "demografici@comune.cortenuova.bg.it",
      "pec": "segreteria.comunecortenuova.bg@legalmail.it",
      "telefono": "+39 0363992444",
      "fax": "+39 0363909064",
      "coordinate": {
        "lat": 45.538978576660156,
        "lng": 9.787859916687012
      }
    },
    {
      "codice": "016084",
      "nome": "Costa di Mezzate",
      "nomeStraniero": null,
      "codiceCatastale": "D110",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "amministrazione@comune.costadimezzate.bg.it",
      "pec": "comunecostadimezzate@pec.it",
      "telefono": "+39 035681004",
      "fax": "+39 035684092",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.800000190734863
      }
    },
    {
      "codice": "016247",
      "nome": "Costa Serina",
      "nomeStraniero": null,
      "codiceCatastale": "D111",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "segreteria@comune.costaserina.bg.it",
      "pec": "comune.costaserina@legalmail.it",
      "telefono": "+39 034597027",
      "fax": "+39 034597766",
      "coordinate": {
        "lat": 45.832420349121094,
        "lng": 9.741937637329102
      }
    },
    {
      "codice": "016085",
      "nome": "Costa Valle Imagna",
      "nomeStraniero": null,
      "codiceCatastale": "D103",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.costavalleimagna.bg.it",
      "pec": "protocollo.comune.costavalleimagna.bg@pec.it",
      "telefono": "+39 035865001",
      "fax": "+39 035865054",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.5
      }
    },
    {
      "codice": "016086",
      "nome": "Costa Volpino",
      "nomeStraniero": null,
      "codiceCatastale": "D117",
      "cap": "24062",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.costavolpino.bg.it",
      "pec": "protocollo@pec.costavolpino.bg.it",
      "telefono": "+39 035970290",
      "fax": "+39 035970891",
      "coordinate": {
        "lat": 45.8305549621582,
        "lng": 10.099166870117188
      }
    },
    {
      "codice": "016087",
      "nome": "Covo",
      "nomeStraniero": null,
      "codiceCatastale": "D126",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "ufficio.statocivile@comune.covo.bg.it",
      "pec": "comune.covo@pec.regione.lombardia.it",
      "telefono": "+39 0363930212",
      "fax": "+39 0363938099",
      "coordinate": {
        "lat": 45.5,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "016088",
      "nome": "Credaro",
      "nomeStraniero": null,
      "codiceCatastale": "D139",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.credaro.bg.it",
      "pec": "segreteria@pec.comune.credaro.bg.it",
      "telefono": "+39 035935067",
      "fax": "+39 035935045",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "016089",
      "nome": "Curno",
      "nomeStraniero": null,
      "codiceCatastale": "D221",
      "cap": "24035",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.curno.bg.it",
      "pec": "comunecurno.certificata@halleycert.it",
      "telefono": "+39 035603000",
      "fax": "+39 035603005",
      "coordinate": {
        "lat": 45.69111251831055,
        "lng": 9.612500190734863
      }
    },
    {
      "codice": "016090",
      "nome": "Cusio",
      "nomeStraniero": null,
      "codiceCatastale": "D233",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.cusio.bg.it",
      "pec": "comune.cusio@pec.regione.lombardia.it",
      "telefono": "+39 034588021",
      "fax": "+39 034588021",
      "coordinate": {
        "lat": 45.9908332824707,
        "lng": 9.602499961853027
      }
    },
    {
      "codice": "016091",
      "nome": "Dalmine",
      "nomeStraniero": null,
      "codiceCatastale": "D245",
      "cap": "24044",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ced@comune.dalmine.bg.it",
      "pec": "protocollo@cert.comune.dalmine.bg.it",
      "telefono": "+39 0356224883",
      "fax": "+39 0356224738",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "016092",
      "nome": "Dossena",
      "nomeStraniero": null,
      "codiceCatastale": "D352",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.dossena.bg.it",
      "pec": "comune.dossena@legalmail.it",
      "telefono": "+39 034549413",
      "fax": "+39 034549453",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "016093",
      "nome": "Endine Gaiano",
      "nomeStraniero": null,
      "codiceCatastale": "D406",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "simone.lanfranchi@comune.endine-gaiano.bg.it",
      "pec": "protocollo.comune.endinegaiano@pec.regione.lombardia.it",
      "telefono": "+39 035825005",
      "fax": "+39 035827221",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "016094",
      "nome": "Entratico",
      "nomeStraniero": null,
      "codiceCatastale": "D411",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.entratico.bg.it",
      "pec": "comune.entratico@halleycert.it",
      "telefono": "+39 035942021",
      "fax": "+39 035940715",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "016096",
      "nome": "Fara Gera d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "D490",
      "cap": "24045",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "info@comune.farageradadda.bg.it",
      "pec": "info@pec.comune.farageradadda.bg.it",
      "telefono": "+39 0363688601",
      "fax": "+39 0363398774",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "016097",
      "nome": "Fara Olivana con Sola",
      "nomeStraniero": null,
      "codiceCatastale": "D491",
      "cap": "24058",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "prot@comune.faraolivanaconsola.bg.it",
      "pec": "info@cert.comune.faraolivanaconsola.bg.it",
      "telefono": "+39 036393130",
      "fax": "+39 0363938674",
      "coordinate": {
        "lat": 45.494842529296875,
        "lng": 9.749061584472656
      }
    },
    {
      "codice": "016098",
      "nome": "Filago",
      "nomeStraniero": null,
      "codiceCatastale": "D588",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.filago.bg.it",
      "pec": "protocollo@pec.comune.filago.bg.it",
      "telefono": "+39 0354995311",
      "fax": "+39 0354995327",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "016099",
      "nome": "Fino del Monte",
      "nomeStraniero": null,
      "codiceCatastale": "D604",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.finodelmonte.bg.it",
      "pec": "comune.finodelmonte@pec.regione.lombardia.it",
      "telefono": "+39 034672018",
      "fax": "+39 034674601",
      "coordinate": {
        "lat": 45.8903694152832,
        "lng": 9.992618560791016
      }
    },
    {
      "codice": "016100",
      "nome": "Fiorano al Serio",
      "nomeStraniero": null,
      "codiceCatastale": "D606",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "sindaco@comune.fioranoalserio.bg.it",
      "pec": "comune@pec.comune.fioranoalserio.bg.it",
      "telefono": "+39 035711479",
      "fax": "+39 035720340",
      "coordinate": {
        "lat": 45.801944732666016,
        "lng": 9.843055725097656
      }
    },
    {
      "codice": "016101",
      "nome": "Fontanella",
      "nomeStraniero": null,
      "codiceCatastale": "D672",
      "cap": "24056",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "info@comune.fontanella.bg.it",
      "pec": "pec@pec.comune.fontanella.bg.it",
      "telefono": "+39 0363997555",
      "fax": "+39 0363907303",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.800000190734863
      }
    },
    {
      "codice": "016102",
      "nome": "Fonteno",
      "nomeStraniero": null,
      "codiceCatastale": "D684",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.fonteno.bg.it",
      "pec": "comune.fonteno@pec.regione.lombardia.it",
      "telefono": "+39 035969022",
      "fax": "+39 035969471",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "016103",
      "nome": "Foppolo",
      "nomeStraniero": null,
      "codiceCatastale": "D688",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.foppolo.bg.it",
      "pec": "comune.foppolo@pec.regione.lombardia.it",
      "telefono": "+39 034574002",
      "fax": "+39 034574068",
      "coordinate": {
        "lat": 46.04277801513672,
        "lng": 9.758610725402832
      }
    },
    {
      "codice": "016104",
      "nome": "Foresto Sparso",
      "nomeStraniero": null,
      "codiceCatastale": "D697",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.forestosparso.bg.it",
      "pec": "comuneforestosparso@halleycert.it",
      "telefono": "+39 035930028",
      "fax": "+39 035930666",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "016105",
      "nome": "Fornovo San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "D727",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "ufficioprotocollo@comune.fornovosangiovanni.bg.it",
      "pec": "comune.fornovosangiovanni@pec.regione.lombardia.it",
      "telefono": "+39 036357666",
      "fax": "+39 0363337077",
      "coordinate": {
        "lat": 45.49679946899414,
        "lng": 9.677300453186035
      }
    },
    {
      "codice": "016106",
      "nome": "Fuipiano Valle Imagna",
      "nomeStraniero": null,
      "codiceCatastale": "D817",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ragioneria@comune.fuipianovalleimagna.bg.it",
      "pec": "comune.fuipianovalleimagna@pec.regione.lombardia.it",
      "telefono": "+39 035856134",
      "fax": null,
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "016107",
      "nome": "Gandellino",
      "nomeStraniero": null,
      "codiceCatastale": "D903",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "segreteria@comune.gandellino.bg.it",
      "pec": "comune.gandellino@legalmail.it",
      "telefono": "+39 034648495",
      "fax": "+39 034648433",
      "coordinate": {
        "lat": 45.9900016784668,
        "lng": 9.946389198303223
      }
    },
    {
      "codice": "016108",
      "nome": "Gandino",
      "nomeStraniero": null,
      "codiceCatastale": "D905",
      "cap": "24024",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.gandino.bg.it",
      "pec": "comune.gandino@legalmail.it",
      "telefono": "+39 035745567",
      "fax": "+39 035745646",
      "coordinate": {
        "lat": 45.811668395996094,
        "lng": 9.903055191040039
      }
    },
    {
      "codice": "016109",
      "nome": "Gandosso",
      "nomeStraniero": null,
      "codiceCatastale": "D906",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "servizi.finanziari@comune.gandosso.bg.it",
      "pec": "comunedigandosso@legalmail.it",
      "telefono": "+39 035834005",
      "fax": "+39 0354420901",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "016110",
      "nome": "Gaverina Terme",
      "nomeStraniero": null,
      "codiceCatastale": "D943",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.gaverina-terme.bg.it",
      "pec": "comune.gaverina_terme@pec.regione.lombardia.it",
      "telefono": "+39 035810053",
      "fax": "+39 035812678",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "016111",
      "nome": "Gazzaniga",
      "nomeStraniero": null,
      "codiceCatastale": "D952",
      "cap": "24025",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.gazzaniga.bg.it",
      "pec": "protocollo@pec.comune.gazzaniga.bg.it",
      "telefono": "+39 035737011",
      "fax": "+39 035720292",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "016113",
      "nome": "Ghisalba",
      "nomeStraniero": null,
      "codiceCatastale": "E006",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "info@comuneghisalba.it",
      "pec": "comune@pec.comuneghisalba.it",
      "telefono": "+39 0363944510",
      "fax": "+39 0363944524",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.75
      }
    },
    {
      "codice": "016114",
      "nome": "Gorlago",
      "nomeStraniero": null,
      "codiceCatastale": "E100",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.gorlago.bg.it",
      "pec": "comune.gorlago.bg@halleycert.it",
      "telefono": "+39 0354252612",
      "fax": "+39 0354252640",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.816666603088379
      }
    },
    {
      "codice": "016115",
      "nome": "Gorle",
      "nomeStraniero": null,
      "codiceCatastale": "E103",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "maddalenamorotti@comune.gorle.bg.it",
      "pec": "comune.gorle@pec.regione.lombardia.it",
      "telefono": "+39 0356592199",
      "fax": "+39 035656555",
      "coordinate": {
        "lat": 45.703887939453125,
        "lng": 9.71916675567627
      }
    },
    {
      "codice": "016116",
      "nome": "Gorno",
      "nomeStraniero": null,
      "codiceCatastale": "E106",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.gorno.bg.it",
      "pec": "protocollo.gorno@pec.it",
      "telefono": "+39 035707145",
      "fax": "+39 035708019",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "016117",
      "nome": "Grassobbio",
      "nomeStraniero": null,
      "codiceCatastale": "E148",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@grassobbio.eu",
      "pec": "comune.grassobbio@pec.regione.lombardia.it",
      "telefono": "+39 0353843401",
      "fax": "+39 0353843444",
      "coordinate": {
        "lat": 45.65666580200195,
        "lng": 9.723889350891113
      }
    },
    {
      "codice": "016118",
      "nome": "Gromo",
      "nomeStraniero": null,
      "codiceCatastale": "E189",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "segreteria@comune.gromo.bg.it",
      "pec": "comune.gromo@legalmail.it",
      "telefono": "+39 034641128",
      "fax": "+39 03461942012",
      "coordinate": {
        "lat": 45.96444320678711,
        "lng": 9.927499771118164
      }
    },
    {
      "codice": "016119",
      "nome": "Grone",
      "nomeStraniero": null,
      "codiceCatastale": "E192",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.grone.bg.it",
      "pec": "comune.grone@pec.regione.lombardia.it",
      "telefono": "+39 035820448",
      "fax": "+39 035822196",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.916666984558105
      }
    },
    {
      "codice": "016120",
      "nome": "Grumello del Monte",
      "nomeStraniero": null,
      "codiceCatastale": "E219",
      "cap": "24064",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.grumellodelmonte.bg.it",
      "pec": "protocollo.grumello@pec.it",
      "telefono": "+39 0354492923",
      "fax": "+39 0354492939",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "016121",
      "nome": "Isola di Fondra",
      "nomeStraniero": null,
      "codiceCatastale": "E353",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.isoladifondra.bg.it",
      "pec": "comune.isoladifondra@pec.regione.lombardia.it",
      "telefono": "+39 034571588",
      "fax": "+39 034571588",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 9.75
      }
    },
    {
      "codice": "016122",
      "nome": "Isso",
      "nomeStraniero": null,
      "codiceCatastale": "E370",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "info@comune.isso.bg.it",
      "pec": "info@cert.comune.isso.bg.it",
      "telefono": "+39 0363914234",
      "fax": "+39 0363915133",
      "coordinate": {
        "lat": 45.47666549682617,
        "lng": 9.759444236755371
      }
    },
    {
      "codice": "016123",
      "nome": "Lallio",
      "nomeStraniero": null,
      "codiceCatastale": "E422",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.lallio.bg.it",
      "pec": "comune.lallio@pec.regione.lombardia.it",
      "telefono": "+39 0352059011",
      "fax": "+39 035200729",
      "coordinate": {
        "lat": 45.66611099243164,
        "lng": 9.631388664245605
      }
    },
    {
      "codice": "016124",
      "nome": "Leffe",
      "nomeStraniero": null,
      "codiceCatastale": "E509",
      "cap": "24026",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.leffe.bg.it",
      "pec": "comune@pec.comune.leffe.bg.it",
      "telefono": "+39 0357170700",
      "fax": "+39 0357170711",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "016125",
      "nome": "Lenna",
      "nomeStraniero": null,
      "codiceCatastale": "E524",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.lenna.bg.it",
      "pec": "comune.lenna@legalmail.it",
      "telefono": "+39 034581051",
      "fax": "+39 034581122",
      "coordinate": {
        "lat": 45.94333267211914,
        "lng": 9.677499771118164
      }
    },
    {
      "codice": "016126",
      "nome": "Levate",
      "nomeStraniero": null,
      "codiceCatastale": "E562",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.levate.bg.it",
      "pec": "comune.levate@legalmail.it",
      "telefono": "+39 035594143",
      "fax": "+39 035337011",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "016127",
      "nome": "Locatello",
      "nomeStraniero": null,
      "codiceCatastale": "E640",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comunedilocatello@gmail.com",
      "pec": "comune.locatello@pec.regione.lombardia.it",
      "telefono": "+39 035866028",
      "fax": "+39 035866330",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "016128",
      "nome": "Lovere",
      "nomeStraniero": null,
      "codiceCatastale": "E704",
      "cap": "24065",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.lovere.bg.it",
      "pec": "comune.lovere@pec.regione.lombardia.it",
      "telefono": "+39 035983623",
      "fax": "+39 035983008",
      "coordinate": {
        "lat": 45.8125,
        "lng": 10.069999694824219
      }
    },
    {
      "codice": "016129",
      "nome": "Lurano",
      "nomeStraniero": null,
      "codiceCatastale": "E751",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe.lurano@comune.lurano.bg.it",
      "pec": "comune.lurano@pec.regione.lombardia.it",
      "telefono": "+39 035800024",
      "fax": "+39 0354877770",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "016130",
      "nome": "Luzzana",
      "nomeStraniero": null,
      "codiceCatastale": "E770",
      "cap": "24069",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.luzzana.bg.it",
      "pec": "comune.luzzana@pec.regione.lombardia.it",
      "telefono": "+39 035822829",
      "fax": "+39 035820912",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "016131",
      "nome": "Madone",
      "nomeStraniero": null,
      "codiceCatastale": "E794",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.madone.bg.it",
      "pec": "comunemadone@pec.it",
      "telefono": "+39 035991174",
      "fax": "+39 0354942441",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "016132",
      "nome": "Mapello",
      "nomeStraniero": null,
      "codiceCatastale": "E901",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.mapello.bg.it",
      "pec": "comunemapello@halleycert.it",
      "telefono": "+39 0354652500",
      "fax": "+39 0354652501",
      "coordinate": {
        "lat": 45.70888900756836,
        "lng": 9.547778129577637
      }
    },
    {
      "codice": "016133",
      "nome": "Martinengo",
      "nomeStraniero": null,
      "codiceCatastale": "E987",
      "cap": "24057",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "protocollo@comune.martinengo.bg.it",
      "pec": "protocollo@pec.comune.martinengo.bg.it",
      "telefono": "+39 0363986011",
      "fax": "+39 0363986032",
      "coordinate": {
        "lat": 45.570369720458984,
        "lng": 9.767375946044922
      }
    },
    {
      "codice": "016250",
      "nome": "Medolago",
      "nomeStraniero": null,
      "codiceCatastale": "F085",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.medolago.bg.it",
      "pec": "comune.medolago.bg@halleycert.it",
      "telefono": "+39 0354948810",
      "fax": "+39 0354948855",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.5
      }
    },
    {
      "codice": "016134",
      "nome": "Mezzoldo",
      "nomeStraniero": null,
      "codiceCatastale": "F186",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.mezzoldo.bg.it",
      "pec": "comune.mezzoldo@pec.regione.lombardia.it",
      "telefono": "+39 034586041",
      "fax": "+39 034586500",
      "coordinate": {
        "lat": 46.01305389404297,
        "lng": 9.664722442626953
      }
    },
    {
      "codice": "016135",
      "nome": "Misano di Gera d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "F243",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "angelo.messaggio@comune.misano.bg.it",
      "pec": "comune.misanogd@legalmail.it",
      "telefono": "+39 0363849250",
      "fax": "+39 0363849293",
      "coordinate": {
        "lat": 45.469444274902344,
        "lng": 9.620833396911621
      }
    },
    {
      "codice": "016136",
      "nome": "Moio de' Calvi",
      "nomeStraniero": null,
      "codiceCatastale": "F276",
      "cap": "24070",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.moiodecalvi.bg.it",
      "pec": "comune.moiodecalvi@pec.regione.lombardia.it",
      "telefono": "+39 034581155",
      "fax": "+39 034582749",
      "coordinate": {
        "lat": 45.95166778564453,
        "lng": 9.700833320617676
      }
    },
    {
      "codice": "016137",
      "nome": "Monasterolo del Castello",
      "nomeStraniero": null,
      "codiceCatastale": "F328",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.monasterolo-del-castello.bg.it",
      "pec": "comune.monasterolo-del-castello@pec.regione.lombardia.it",
      "telefono": "+39 035814515",
      "fax": "+39 035814526",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "016139",
      "nome": "Montello",
      "nomeStraniero": null,
      "codiceCatastale": "F547",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.montello.bg.it",
      "pec": "comunemontello@halleycert.it",
      "telefono": "+39 035681028",
      "fax": "+39 035683503",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.800000190734863
      }
    },
    {
      "codice": "016140",
      "nome": "Morengo",
      "nomeStraniero": null,
      "codiceCatastale": "F720",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "protocollo@comune.morengo.bg.it",
      "pec": "protocollo@pec.comune.morengo.bg.it",
      "telefono": "+39 036395052",
      "fax": "+39 0363941030",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "016141",
      "nome": "Mornico al Serio",
      "nomeStraniero": null,
      "codiceCatastale": "F738",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.mornicoalserio.bg.it",
      "pec": "info@pec.comune.mornicoalserio.bg.it",
      "telefono": "+39 035844030",
      "fax": "+39 035844682",
      "coordinate": {
        "lat": 45.592498779296875,
        "lng": 9.80888843536377
      }
    },
    {
      "codice": "016142",
      "nome": "Mozzanica",
      "nomeStraniero": null,
      "codiceCatastale": "F786",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "ufficio.segreteria@comune.mozzanica.bg.it",
      "pec": "info@pec.comune.mozzanica.bg.it",
      "telefono": "+39 0363324811",
      "fax": "+39 0363828122",
      "coordinate": {
        "lat": 45.47607421875,
        "lng": 9.689257621765137
      }
    },
    {
      "codice": "016143",
      "nome": "Mozzo",
      "nomeStraniero": null,
      "codiceCatastale": "F791",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.mozzo.bg.it",
      "pec": "comune.mozzo@pec.regione.lombardia.it",
      "telefono": "+39 0354556652",
      "fax": "+39 0354556613",
      "coordinate": {
        "lat": 45.69868087768555,
        "lng": 9.608652114868164
      }
    },
    {
      "codice": "016144",
      "nome": "Nembro",
      "nomeStraniero": null,
      "codiceCatastale": "F864",
      "cap": "24027",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "laura.dordi@nembro.net",
      "pec": "comunenembro@legalmail.it",
      "telefono": "+39 035471311",
      "fax": "+39 035471343",
      "coordinate": {
        "lat": 45.74388885498047,
        "lng": 9.759444236755371
      }
    },
    {
      "codice": "016145",
      "nome": "Olmo al Brembo",
      "nomeStraniero": null,
      "codiceCatastale": "G049",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.olmoalbrembo.bg.it",
      "pec": "comune.olmoalbrembo@pec.regione.lombardia.it",
      "telefono": "+39 034587021",
      "fax": "+39 034587311",
      "coordinate": {
        "lat": 45.97222137451172,
        "lng": 9.64916706085205
      }
    },
    {
      "codice": "016146",
      "nome": "Oltre il Colle",
      "nomeStraniero": null,
      "codiceCatastale": "G050",
      "cap": "24013",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.oltreilcolle.bg.it",
      "pec": "comune.oltreilcolle@legalmail.it",
      "telefono": "+39 034595015",
      "fax": "+39 034595661",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "016147",
      "nome": "Oltressenda Alta",
      "nomeStraniero": null,
      "codiceCatastale": "G054",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "demografici@comune.oltressendaalta.bg.it",
      "pec": "comune.oltressendaalta@pec.regione.lombardia.it",
      "telefono": "+39 034621524",
      "fax": "+39 034621524",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "016148",
      "nome": "Oneta",
      "nomeStraniero": null,
      "codiceCatastale": "G068",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.oneta.bg.it",
      "pec": "comune.oneta@legalmail.it",
      "telefono": "+39 035707117",
      "fax": "+39 035708125",
      "coordinate": {
        "lat": 45.87148666381836,
        "lng": 9.819353103637695
      }
    },
    {
      "codice": "016149",
      "nome": "Onore",
      "nomeStraniero": null,
      "codiceCatastale": "G075",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.onore.bg.it",
      "pec": "comune.onore@pec.regione.lombardia.it",
      "telefono": "+39 034671191",
      "fax": "+39 034674456",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "016150",
      "nome": "Orio al Serio",
      "nomeStraniero": null,
      "codiceCatastale": "G108",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ragioneria@comune.orioalserio.bg.it",
      "pec": "egov.orioalserio@cert.poliscomuneamico.net",
      "telefono": "+39 0354203210",
      "fax": "+39 0354203250",
      "coordinate": {
        "lat": 45.68000030517578,
        "lng": 9.690555572509766
      }
    },
    {
      "codice": "016151",
      "nome": "Ornica",
      "nomeStraniero": null,
      "codiceCatastale": "G118",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.ornica.bg.it",
      "pec": "comune.ornica@pec.regione.lombardia.it",
      "telefono": "+39 034589021",
      "fax": "+39 034589600",
      "coordinate": {
        "lat": 45.98833465576172,
        "lng": 9.579166412353516
      }
    },
    {
      "codice": "016152",
      "nome": "Osio Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "G159",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "affarigenerali@comune.osiosopra.bg.it",
      "pec": "comune.osiosopra@pec.regione.lombardia.it",
      "telefono": "+39 035500121",
      "fax": "+39 035501628",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.583333015441895
      }
    },
    {
      "codice": "016153",
      "nome": "Osio Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "G160",
      "cap": "24046",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.osiosotto.bg.it",
      "pec": "comune.osiosotto@pec.regione.lombardia.it",
      "telefono": "+39 0354185901",
      "fax": "+39 0354185918",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "016154",
      "nome": "Pagazzano",
      "nomeStraniero": null,
      "codiceCatastale": "G233",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "info.pagazzano@comune.pagazzano.bg.it",
      "pec": "comunepagazzano@legalmail.it",
      "telefono": "+39 0363814629",
      "fax": "+39 0363814765",
      "coordinate": {
        "lat": 45.534278869628906,
        "lng": 9.671156883239746
      }
    },
    {
      "codice": "016155",
      "nome": "Paladina",
      "nomeStraniero": null,
      "codiceCatastale": "G249",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.paladina.bg.it",
      "pec": "comunepaladina@legalmail.it",
      "telefono": "+39 0356313041",
      "fax": "+39 035637578",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "016156",
      "nome": "Palazzago",
      "nomeStraniero": null,
      "codiceCatastale": "G259",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "utc@comune.palazzago.bg.it",
      "pec": "comune.palazzago@pec.regione.lombardia.it",
      "telefono": "+39 035551261",
      "fax": "+39 035550197",
      "coordinate": {
        "lat": 45.75194549560547,
        "lng": 9.535277366638184
      }
    },
    {
      "codice": "016157",
      "nome": "Palosco",
      "nomeStraniero": null,
      "codiceCatastale": "G295",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.palosco.bg.it",
      "pec": "protocollo@pec.comune.palosco.bg.it",
      "telefono": "+39 035845046",
      "fax": "+39 035846639",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "016158",
      "nome": "Parre",
      "nomeStraniero": null,
      "codiceCatastale": "G346",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ragioneria@comune.parre.bg.it",
      "pec": "comune.parre.bg@halleycert.it",
      "telefono": "+39 035701002",
      "fax": "+39 035703545",
      "coordinate": {
        "lat": 45.87458801269531,
        "lng": 9.89085578918457
      }
    },
    {
      "codice": "016159",
      "nome": "Parzanica",
      "nomeStraniero": null,
      "codiceCatastale": "G350",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.parzanica.bg.it",
      "pec": "comune.parzanica@pec.regione.lombardia.it",
      "telefono": "+39 035917001",
      "fax": "+39 035931772",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 10.033333778381348
      }
    },
    {
      "codice": "016160",
      "nome": "Pedrengo",
      "nomeStraniero": null,
      "codiceCatastale": "G412",
      "cap": "24066",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "marta.barcella@comune.pedrengo.bg.it",
      "pec": "protocollo@peccomunepedrengo.it",
      "telefono": "+39 035662595",
      "fax": "+39 035657622",
      "coordinate": {
        "lat": 45.698333740234375,
        "lng": 9.733888626098633
      }
    },
    {
      "codice": "016161",
      "nome": "Peia",
      "nomeStraniero": null,
      "codiceCatastale": "G418",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.peia.bg.it",
      "pec": "comune.peia@pec.regione.lombardia.it",
      "telefono": "+39 035731108",
      "fax": "+39 035727059",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "016162",
      "nome": "Pianico",
      "nomeStraniero": null,
      "codiceCatastale": "G564",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.pianico.bg.it",
      "pec": "comune.pianico@pec.regione.lombardia.it",
      "telefono": "+39 035979082",
      "fax": "+39 035979222",
      "coordinate": {
        "lat": 45.81055450439453,
        "lng": 10.043889045715332
      }
    },
    {
      "codice": "016163",
      "nome": "Piario",
      "nomeStraniero": null,
      "codiceCatastale": "G574",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.piario.bg.it",
      "pec": "comune.piario@pec.regione.lombardia.it",
      "telefono": "+39 034621422",
      "fax": "+39 034623854",
      "coordinate": {
        "lat": 45.89611053466797,
        "lng": 9.92388916015625
      }
    },
    {
      "codice": "016164",
      "nome": "Piazza Brembana",
      "nomeStraniero": null,
      "codiceCatastale": "G579",
      "cap": "24014",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "comune.piazzabrembana@tin.it",
      "pec": "comune.piazzabrembana@pec.regione.lombardia.it",
      "telefono": "+39 034581035",
      "fax": "+39 034581714",
      "coordinate": {
        "lat": 45.94722366333008,
        "lng": 9.675000190734863
      }
    },
    {
      "codice": "016165",
      "nome": "Piazzatorre",
      "nomeStraniero": null,
      "codiceCatastale": "G583",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.piazzatorre.bg.it",
      "pec": "comune.piazzatorre@pec.regione.lombardia.it",
      "telefono": "+39 034585023",
      "fax": "+39 034585659",
      "coordinate": {
        "lat": 46,
        "lng": 9.683333396911621
      }
    },
    {
      "codice": "016166",
      "nome": "Piazzolo",
      "nomeStraniero": null,
      "codiceCatastale": "G588",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.piazzolo.bg.it",
      "pec": "comune.piazzolo@pec.regione.lombardia.it",
      "telefono": "+39 034587188",
      "fax": "+39 034587172",
      "coordinate": {
        "lat": 45.97978973388672,
        "lng": 9.670548439025879
      }
    },
    {
      "codice": "016167",
      "nome": "Pognano",
      "nomeStraniero": null,
      "codiceCatastale": "G774",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.pognano.bg.it",
      "pec": "comune.pognano@pec.regione.lombardia.it",
      "telefono": "+39 0354829003",
      "fax": "+39 0354818749",
      "coordinate": {
        "lat": 45.586944580078125,
        "lng": 9.640832901000977
      }
    },
    {
      "codice": "016168",
      "nome": "Ponte Nossa",
      "nomeStraniero": null,
      "codiceCatastale": "F941",
      "cap": "24028",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.pontenossa.bg.it",
      "pec": "comune.pontenossa@legalmail.it",
      "telefono": "+39 035701054",
      "fax": "+39 035703631",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "016170",
      "nome": "Ponte San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "G856",
      "cap": "24036",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.pontesanpietro.bg.it",
      "pec": "comunepontesanpietro@legalmail.it",
      "telefono": "+39 0356228411",
      "fax": "+39 0356228499",
      "coordinate": {
        "lat": 45.697776794433594,
        "lng": 9.588055610656738
      }
    },
    {
      "codice": "016169",
      "nome": "Ponteranica",
      "nomeStraniero": null,
      "codiceCatastale": "G853",
      "cap": "24010",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.ponteranica.bg.it",
      "pec": "info@pec.comune.ponteranica.bg.it",
      "telefono": "+39 035571026",
      "fax": "+39 035573575",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "016171",
      "nome": "Pontida",
      "nomeStraniero": null,
      "codiceCatastale": "G864",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comunepontida@postecert.it",
      "pec": "comunepontida@postecert.it",
      "telefono": "+39 035796607",
      "fax": "+39 035796619",
      "coordinate": {
        "lat": 45.734004974365234,
        "lng": 9.506439208984375
      }
    },
    {
      "codice": "016172",
      "nome": "Pontirolo Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "G867",
      "cap": "24040",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "protocollo@comune.pontirolonuovo.bg.it",
      "pec": "comune.pontirolonuovo@registerpec.it",
      "telefono": "+39 0363332810",
      "fax": "+39 0363332839",
      "coordinate": {
        "lat": 45.56916809082031,
        "lng": 9.567222595214844
      }
    },
    {
      "codice": "016173",
      "nome": "Pradalunga",
      "nomeStraniero": null,
      "codiceCatastale": "G968",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demografici@pec.comunepradalunga.it",
      "pec": "comune.pradalunga@pec.regione.lombardia.it",
      "telefono": "+39 035768077",
      "fax": "+39 035768518",
      "coordinate": {
        "lat": 45.746665954589844,
        "lng": 9.783055305480957
      }
    },
    {
      "codice": "016174",
      "nome": "Predore",
      "nomeStraniero": null,
      "codiceCatastale": "H020",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.predore.bg.it",
      "pec": "protocollo@pec.comune.predore.bg.it",
      "telefono": "+39 035938032",
      "fax": "+39 035938715",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "016175",
      "nome": "Premolo",
      "nomeStraniero": null,
      "codiceCatastale": "H036",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.premolo.bg.it",
      "pec": "comune.premolo@legalmail.it",
      "telefono": "+39 035701059",
      "fax": "+39 035704024",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "016176",
      "nome": "Presezzo",
      "nomeStraniero": null,
      "codiceCatastale": "H046",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "presezzo@comunepresezzo.it",
      "pec": "comune.presezzo@pec.regione.lombardia.it",
      "telefono": "+39 035464611",
      "fax": "+39 035464699",
      "coordinate": {
        "lat": 45.69138717651367,
        "lng": 9.566389083862305
      }
    },
    {
      "codice": "016177",
      "nome": "Pumenengo",
      "nomeStraniero": null,
      "codiceCatastale": "H091",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "segreteria@comune.pumenengo.bg.it",
      "pec": "comunepumenengo@pec.it",
      "telefono": "+39 0363994021",
      "fax": "+39 0363994439",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "016178",
      "nome": "Ranica",
      "nomeStraniero": null,
      "codiceCatastale": "H176",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.ranica.bg.it",
      "pec": "comune.ranica@pec.regione.lombardia.it",
      "telefono": "+39 035479011",
      "fax": "+39 035511214",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.716666221618652
      }
    },
    {
      "codice": "016179",
      "nome": "Ranzanico",
      "nomeStraniero": null,
      "codiceCatastale": "H177",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.ranzanico.bg.it",
      "pec": "protocollo@comuneranzanico.legalmail.it",
      "telefono": "+39 035829022",
      "fax": "+39 035829268",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "016180",
      "nome": "Riva di Solto",
      "nomeStraniero": null,
      "codiceCatastale": "H331",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.rivadisolto.bg.it",
      "pec": "comune.rivadisolto@pec.regione.lombardia.it",
      "telefono": "+39 035985100",
      "fax": "+39 035980763",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.033333778381348
      }
    },
    {
      "codice": "016182",
      "nome": "Rogno",
      "nomeStraniero": null,
      "codiceCatastale": "H492",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.rogno.bg.it",
      "pec": "comune.rogno@pec.comune.rogno.bg.it",
      "telefono": "+39 035967013",
      "fax": "+39 035967243",
      "coordinate": {
        "lat": 45.85749816894531,
        "lng": 10.133055686950684
      }
    },
    {
      "codice": "016183",
      "nome": "Romano di Lombardia",
      "nomeStraniero": null,
      "codiceCatastale": "H509",
      "cap": "24058",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "affari.generali@comune.romano.bg.it",
      "pec": "segreteria.comune.romano@pec.regione.lombardia.it",
      "telefono": "+39 0363982311",
      "fax": "+39 0363982408",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 9.75
      }
    },
    {
      "codice": "016184",
      "nome": "Roncobello",
      "nomeStraniero": null,
      "codiceCatastale": "H535",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "ragioneria@comune.roncobello.bg.it",
      "pec": "comune.roncobello@pec.regione.lombardia.it",
      "telefono": "+39 034584047",
      "fax": "+39 034584453",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.75
      }
    },
    {
      "codice": "016185",
      "nome": "Roncola",
      "nomeStraniero": null,
      "codiceCatastale": "H544",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.roncola.bg.it",
      "pec": "comune.roncola@pec.regione.lombardia.it",
      "telefono": "+39 035645525",
      "fax": "+39 035645493",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "016186",
      "nome": "Rota d'Imagna",
      "nomeStraniero": null,
      "codiceCatastale": "H584",
      "cap": "24037",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.rotadimagna.bg.it",
      "pec": "comune.rotadimagna@pec.regione.lombardia.it",
      "telefono": "+39 035868068",
      "fax": "+39 035868068",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "016187",
      "nome": "Rovetta",
      "nomeStraniero": null,
      "codiceCatastale": "H615",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.rovetta.bg.it",
      "pec": "comune.rovetta@pec.regione.lombardia.it",
      "telefono": "+39 034672004",
      "fax": "+39 034674238",
      "coordinate": {
        "lat": 45.89299011230469,
        "lng": 9.9834566116333
      }
    },
    {
      "codice": "016188",
      "nome": "San Giovanni Bianco",
      "nomeStraniero": null,
      "codiceCatastale": "H910",
      "cap": "24015",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "servizi-demografici@sangiovannibianco.org",
      "pec": "comune.sangiovannibianco@pec.regione.lombardia.it",
      "telefono": "+39 034543911",
      "fax": "+39 034543495",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "016189",
      "nome": "San Paolo d'Argon",
      "nomeStraniero": null,
      "codiceCatastale": "B310",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comune@comune.sanpaolodargon.bg.it",
      "pec": "web@pec.comune.sanpaolodargon.bg.it",
      "telefono": "+39 0354253011",
      "fax": "+39 0354253051",
      "coordinate": {
        "lat": 45.68916702270508,
        "lng": 9.803055763244629
      }
    },
    {
      "codice": "016190",
      "nome": "San Pellegrino Terme",
      "nomeStraniero": null,
      "codiceCatastale": "I079",
      "cap": "24016",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "demografici@comune.sanpellegrinoterme.bg.it",
      "pec": "comune.sanpellegrinoterme@pec.regione.lombardia.it",
      "telefono": "+39 034525011",
      "fax": "+39 034522755",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.666666984558105
      }
    },
    {
      "codice": "016252",
      "nome": "Sant'Omobono Terme",
      "nomeStraniero": null,
      "codiceCatastale": "M333",
      "cap": "24038",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.santomobonoterme.bg.it",
      "pec": "s.omobono.terme@legalmail.it",
      "telefono": "+39 035 851113",
      "fax": "+39 035852543",
      "coordinate": {
        "lat": 45.80929946899414,
        "lng": 9.537300109863281
      }
    },
    {
      "codice": "016191",
      "nome": "Santa Brigida",
      "nomeStraniero": null,
      "codiceCatastale": "I168",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.santabrigida.bg.it",
      "pec": "comune.santabrigida@pec.regione.lombardia.it",
      "telefono": "+39 034588031",
      "fax": "+39 034588695",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "016193",
      "nome": "Sarnico",
      "nomeStraniero": null,
      "codiceCatastale": "I437",
      "cap": "24067",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ragioneria@comune.sarnico.bg.it",
      "pec": "protocollo@pec.comune.sarnico.bg.it",
      "telefono": "+39 035924111",
      "fax": "+39 035910577",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "016194",
      "nome": "Scanzorosciate",
      "nomeStraniero": null,
      "codiceCatastale": "I506",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "urp@comune.scanzorosciate.bg.it",
      "pec": "protocollo@pec.comune.scanzorosciate.bg.it",
      "telefono": "+39 035654700",
      "fax": "+39 035656228",
      "coordinate": {
        "lat": 45.711387634277344,
        "lng": 9.735833168029785
      }
    },
    {
      "codice": "016195",
      "nome": "Schilpario",
      "nomeStraniero": null,
      "codiceCatastale": "I530",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.schilpario.bg.it",
      "pec": "protocollo@schilpario.legalmail.it",
      "telefono": "+39 034655056",
      "fax": "+39 034656164",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 10.149999618530273
      }
    },
    {
      "codice": "016196",
      "nome": "Sedrina",
      "nomeStraniero": null,
      "codiceCatastale": "I567",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "segreteria@comune.sedrina.bg.it",
      "pec": "segreteria.comune.sedrina@pec.regione.lombardia.it",
      "telefono": "+39 034557011",
      "fax": "+39 034557037",
      "coordinate": {
        "lat": 45.781944274902344,
        "lng": 9.623611450195312
      }
    },
    {
      "codice": "016197",
      "nome": "Selvino",
      "nomeStraniero": null,
      "codiceCatastale": "I597",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comunediselvino.it",
      "pec": "protocollo@pec.comunediselvino.it",
      "telefono": "+39 035764250",
      "fax": "+39 035763362",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.75
      }
    },
    {
      "codice": "016198",
      "nome": "Seriate",
      "nomeStraniero": null,
      "codiceCatastale": "I628",
      "cap": "24068",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "mary.levato@comune.seriate.bg.it",
      "pec": "comune.seriate@pec.it",
      "telefono": "+39 035304220",
      "fax": "+39 035304259",
      "coordinate": {
        "lat": 45.684722900390625,
        "lng": 9.717778205871582
      }
    },
    {
      "codice": "016199",
      "nome": "Serina",
      "nomeStraniero": null,
      "codiceCatastale": "I629",
      "cap": "24017",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.serina.bg.it",
      "pec": "comune.serina@pec.regione.lombardia.it",
      "telefono": "+39 034566213",
      "fax": "+39 034556012",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "016200",
      "nome": "Solto Collina",
      "nomeStraniero": null,
      "codiceCatastale": "I812",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "giancarlo.zana@comune.solto-collina.bg.it",
      "pec": "comune.soltocollina@pec.regione.lombardia.it",
      "telefono": "+39 035986012",
      "fax": "+39 035980121",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "016251",
      "nome": "Solza",
      "nomeStraniero": null,
      "codiceCatastale": "I813",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comune@comune.solza.bg.it",
      "pec": "protocollocomunesolza@pec.comunesolzacert.eu",
      "telefono": "+39 035901213",
      "fax": "+39 0354948365",
      "coordinate": {
        "lat": 45.67714309692383,
        "lng": 9.488936424255371
      }
    },
    {
      "codice": "016201",
      "nome": "Songavazzo",
      "nomeStraniero": null,
      "codiceCatastale": "I830",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.songavazzo.bg.it",
      "pec": "comune.songavazzo@pec.regione.lombardia.it",
      "telefono": "+39 034672067",
      "fax": "+39 034671555",
      "coordinate": {
        "lat": 45.880149841308594,
        "lng": 9.989791870117188
      }
    },
    {
      "codice": "016202",
      "nome": "Sorisole",
      "nomeStraniero": null,
      "codiceCatastale": "I858",
      "cap": "24010",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.sorisole.bg.it",
      "pec": "comune.sorisole@legalmail.it",
      "telefono": "+39 0354287225",
      "fax": "+39 00354125245",
      "coordinate": {
        "lat": 45.73749923706055,
        "lng": 9.656389236450195
      }
    },
    {
      "codice": "016203",
      "nome": "Sotto il Monte Giovanni XXIII",
      "nomeStraniero": null,
      "codiceCatastale": "I869",
      "cap": "24039",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "affarigenerali@comune.sottoilmontegiovannixxiii.bg.it",
      "pec": "comunesottoilmontegiovannixxiii@halleycert.it",
      "telefono": "+39 035791343",
      "fax": "+39 035790204",
      "coordinate": {
        "lat": 45.70722198486328,
        "lng": 9.499166488647461
      }
    },
    {
      "codice": "016204",
      "nome": "Sovere",
      "nomeStraniero": null,
      "codiceCatastale": "I873",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.sovere.bg.it",
      "pec": "anagrafe.comune.sovere@pec.regione.lombardia.bg.it",
      "telefono": "+39 035981107",
      "fax": "+39 035981762",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 10.033333778381348
      }
    },
    {
      "codice": "016205",
      "nome": "Spinone al Lago",
      "nomeStraniero": null,
      "codiceCatastale": "I916",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.spinone-al-lago.bg.it",
      "pec": "protocollo@comunespinone.legalmail.it",
      "telefono": "+39 035810051",
      "fax": "+39 035811345",
      "coordinate": {
        "lat": 45.763999938964844,
        "lng": 9.919300079345703
      }
    },
    {
      "codice": "016206",
      "nome": "Spirano",
      "nomeStraniero": null,
      "codiceCatastale": "I919",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.spirano.bg.it",
      "pec": "posta@pec.comune.spirano.bg.it",
      "telefono": "+39 0354879911",
      "fax": "+39 035876529",
      "coordinate": {
        "lat": 45.58277893066406,
        "lng": 9.669166564941406
      }
    },
    {
      "codice": "016207",
      "nome": "Stezzano",
      "nomeStraniero": null,
      "codiceCatastale": "I951",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.stezzano.bg.it",
      "pec": "protocollostezzano@propec.it",
      "telefono": "+39 0354545311",
      "fax": "+39 0354545326",
      "coordinate": {
        "lat": 45.65083312988281,
        "lng": 9.652777671813965
      }
    },
    {
      "codice": "016208",
      "nome": "Strozza",
      "nomeStraniero": null,
      "codiceCatastale": "I986",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.strozza.bg.it",
      "pec": "comune.strozza@pec.regione.lombardia.it",
      "telefono": "+39 035833180",
      "fax": "+39 035863334",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.583333015441895
      }
    },
    {
      "codice": "016209",
      "nome": "Suisio",
      "nomeStraniero": null,
      "codiceCatastale": "I997",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.suisio.bg.it",
      "pec": "protocollo@pec.comune.suisio.bg.it",
      "telefono": "+39 035901123",
      "fax": "+39 0354948422",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.5
      }
    },
    {
      "codice": "016210",
      "nome": "Taleggio",
      "nomeStraniero": null,
      "codiceCatastale": "L037",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.taleggio.bg.it",
      "pec": "comune.taleggio@pec.regione.lombardia.it",
      "telefono": "+39 034547021",
      "fax": "+39 034547454",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.566670417785645
      }
    },
    {
      "codice": "016211",
      "nome": "Tavernola Bergamasca",
      "nomeStraniero": null,
      "codiceCatastale": "L073",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.tavernola-bergamasca.bg.it",
      "pec": "comune.tavernola-bergamasca@pec.regione.lombardia.it",
      "telefono": "+39 035931004",
      "fax": "+39 035932611",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 10.050000190734863
      }
    },
    {
      "codice": "016212",
      "nome": "Telgate",
      "nomeStraniero": null,
      "codiceCatastale": "L087",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.telgate.bg.it",
      "pec": "comune.telgate@pec.it",
      "telefono": "+39 035830121",
      "fax": "+39 0354421101",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "016213",
      "nome": "Terno d'Isola",
      "nomeStraniero": null,
      "codiceCatastale": "L118",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.ternodisola.bg.it",
      "pec": "pec@pec.comune.ternodisola.bg.it",
      "telefono": "+39 03454940001",
      "fax": "+39 035904498",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "016214",
      "nome": "Torre Boldone",
      "nomeStraniero": null,
      "codiceCatastale": "L251",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "comune@comune.torreboldone.bg.it",
      "pec": "comune.torreboldone@pec.regione.lombardia.it",
      "telefono": "+39 0354169400",
      "fax": "+39 0354169470",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "016215",
      "nome": "Torre de' Busi",
      "nomeStraniero": null,
      "codiceCatastale": "L257",
      "cap": "24032",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.torre-debusi.bg.it",
      "pec": "segreteria@pec.comune.torre-debusi.bg.it",
      "telefono": "+39 035 785004",
      "fax": "+39 035 785327",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "016216",
      "nome": "Torre de' Roveri",
      "nomeStraniero": null,
      "codiceCatastale": "L265",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.torrederoveri.bg.it",
      "pec": "comune.torrederoveri@pec.regione.lombardia.it",
      "telefono": "+39 035581026",
      "fax": "+39 035583138",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.772222518920898
      }
    },
    {
      "codice": "016217",
      "nome": "Torre Pallavicina",
      "nomeStraniero": null,
      "codiceCatastale": "L276",
      "cap": "24050",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "segreteria@comune.torrepallavicina.bg.it",
      "pec": "segreteria@comune.torrepallavicina.legalmailpa.it",
      "telefono": "+39 0363996522",
      "fax": "+39 0363996779",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "016218",
      "nome": "Trescore Balneario",
      "nomeStraniero": null,
      "codiceCatastale": "L388",
      "cap": "24069",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.trescore-balneario.bg.it",
      "pec": "affarigenerali@comunetrescorebalneario.postecert.it",
      "telefono": "+39 035955603",
      "fax": "+39 035955605",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "016219",
      "nome": "Treviglio",
      "nomeStraniero": null,
      "codiceCatastale": "L400",
      "cap": "24047",
      "prefisso": "0363",
      "provincia": "Bergamo",
      "email": "gabriella.mornati@comune.treviglio.bg.it",
      "pec": "comune.treviglio@legalmail.it",
      "telefono": "+39 0363317392",
      "fax": "+39 0363317309",
      "coordinate": {
        "lat": 45.52138900756836,
        "lng": 9.592778205871582
      }
    },
    {
      "codice": "016220",
      "nome": "Treviolo",
      "nomeStraniero": null,
      "codiceCatastale": "L404",
      "cap": "24048",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "ufficio.segreteria@comune.treviolo.bg.it",
      "pec": "comune.treviolo@legalmail.it",
      "telefono": "+39 0352059116",
      "fax": "+39 0352059119",
      "coordinate": {
        "lat": 45.67277908325195,
        "lng": 9.615555763244629
      }
    },
    {
      "codice": "016221",
      "nome": "Ubiale Clanezzo",
      "nomeStraniero": null,
      "codiceCatastale": "C789",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.ubialeclanezzo.bg.it",
      "pec": "segreteria.comune.ubialeclanezzo@pec.regione.lombardia.it",
      "telefono": "+39 034561001",
      "fax": "+39 034562702",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "016222",
      "nome": "Urgnano",
      "nomeStraniero": null,
      "codiceCatastale": "L502",
      "cap": "24059",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@urgnano.eu",
      "pec": "pec@pec.urgnano.eu",
      "telefono": "+39 0354871520",
      "fax": "+39 035896746",
      "coordinate": {
        "lat": 45.59722137451172,
        "lng": 9.694999694824219
      }
    },
    {
      "codice": "016253",
      "nome": "Val Brembilla",
      "nomeStraniero": null,
      "codiceCatastale": "M334",
      "cap": "24012",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "protocollo@comune.valbrembilla.bg.it",
      "pec": "comune.valbrembilla@pec.regione.lombardia.it",
      "telefono": "+39 0345 330011",
      "fax": "+39 0345330023",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "016223",
      "nome": "Valbondione",
      "nomeStraniero": null,
      "codiceCatastale": "L544",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.valbondione.bg.it",
      "pec": "comune.valbondione@legalmail.it",
      "telefono": "+39 034644004",
      "fax": "+39 034644038",
      "coordinate": {
        "lat": 46.037776947021484,
        "lng": 10.013055801391602
      }
    },
    {
      "codice": "016224",
      "nome": "Valbrembo",
      "nomeStraniero": null,
      "codiceCatastale": "L545",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria@comune.valbrembo.bg.it",
      "pec": "comune.valbrembo@cert.legalmail.it",
      "telefono": "+39 035527068",
      "fax": "+39 035528508",
      "coordinate": {
        "lat": 45.71888732910156,
        "lng": 9.609444618225098
      }
    },
    {
      "codice": "016225",
      "nome": "Valgoglio",
      "nomeStraniero": null,
      "codiceCatastale": "L579",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.valgoglio.bg.it",
      "pec": "comune.valgoglio@legalmail.it",
      "telefono": "+39 034641053",
      "fax": "+39 034641182",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.916666984558105
      }
    },
    {
      "codice": "016226",
      "nome": "Valleve",
      "nomeStraniero": null,
      "codiceCatastale": "L623",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.valleve.bg.it",
      "pec": "comune.valleve@pec.regione.lombardia.it",
      "telefono": "+39 034578005",
      "fax": "+39 034578141",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 9.75
      }
    },
    {
      "codice": "016227",
      "nome": "Valnegra",
      "nomeStraniero": null,
      "codiceCatastale": "L642",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.valnegra.bg.it",
      "pec": "comune.valnegra@pec.regione.lombardia.it",
      "telefono": "+39 034581084",
      "fax": null,
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.690555572509766
      }
    },
    {
      "codice": "016229",
      "nome": "Valtorta",
      "nomeStraniero": null,
      "codiceCatastale": "L655",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.valtorta.bg.it",
      "pec": "comune.valtorta@pec.regione.lombardia.it",
      "telefono": "+39 034587713",
      "fax": "+39 034587713",
      "coordinate": {
        "lat": 45.977500915527344,
        "lng": 9.534722328186035
      }
    },
    {
      "codice": "016230",
      "nome": "Vedeseta",
      "nomeStraniero": null,
      "codiceCatastale": "L707",
      "cap": "24010",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "comunedivedeseta@tin.it",
      "pec": "comune.vedeseta@pec.regione.lombardia.it",
      "telefono": "+39 034547036",
      "fax": "+39 034547409",
      "coordinate": {
        "lat": 45.89083480834961,
        "lng": 9.539722442626953
      }
    },
    {
      "codice": "016232",
      "nome": "Verdellino",
      "nomeStraniero": null,
      "codiceCatastale": "L752",
      "cap": "24040",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.verdellino.bg.it",
      "pec": "info@pec.comuneverdellino.it",
      "telefono": "+39 0354182811",
      "fax": "+39 0354182899",
      "coordinate": {
        "lat": 45.60194396972656,
        "lng": 9.608888626098633
      }
    },
    {
      "codice": "016233",
      "nome": "Verdello",
      "nomeStraniero": null,
      "codiceCatastale": "L753",
      "cap": "24049",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.verdello.bg.it",
      "pec": "comune.verdello@pec.regione.lombardia.it",
      "telefono": "+39 0350690511",
      "fax": "+39 0350690519",
      "coordinate": {
        "lat": 45.60499954223633,
        "lng": 9.629722595214844
      }
    },
    {
      "codice": "016234",
      "nome": "Vertova",
      "nomeStraniero": null,
      "codiceCatastale": "L795",
      "cap": "24029",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "segreteria2@comune.vertova.bg.it",
      "pec": "protocollo.vertova@pec.it",
      "telefono": "+39 035711562",
      "fax": "+39 035720496",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "016235",
      "nome": "Viadanica",
      "nomeStraniero": null,
      "codiceCatastale": "L827",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.viadanica.bg.it",
      "pec": "protocollo@pec.comune.viadanica.bg.it",
      "telefono": "+39 035936109",
      "fax": "+39 035936120",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "016236",
      "nome": "Vigano San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "L865",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.vigano-san-martino.bg.it",
      "pec": "comune.viganosanmartino@pec.regione.lombardia.it",
      "telefono": "+39 035821047",
      "fax": "+39 035820917",
      "coordinate": {
        "lat": 45.72638702392578,
        "lng": 9.894444465637207
      }
    },
    {
      "codice": "016237",
      "nome": "Vigolo",
      "nomeStraniero": null,
      "codiceCatastale": "L894",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "protocollo@comune.vigolo.bg.it",
      "pec": "comune.vigolo@pec.regione.lombardia.it",
      "telefono": "+39 035931054",
      "fax": "+39 035932784",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "016238",
      "nome": "Villa d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "L929",
      "cap": "24030",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "demografici@comune.villadadda.bg.it",
      "pec": "protocollo@pec.comune.villadadda.bg.it",
      "telefono": "+39 0354389711",
      "fax": "+39 0354389718",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "016239",
      "nome": "Villa d'Almè",
      "nomeStraniero": null,
      "codiceCatastale": "A215",
      "cap": "24018",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.villadalme.bg.it",
      "pec": "protocollo@pec.comune.villadalme.bg.it",
      "telefono": "+39 0356321111",
      "fax": "+39 0356321199",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "016241",
      "nome": "Villa d'Ogna",
      "nomeStraniero": null,
      "codiceCatastale": "L938",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.villadogna.bg.it",
      "pec": "protocollo.villadogna@pec.it",
      "telefono": "+39 034621531",
      "fax": "+39 034623300",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "016240",
      "nome": "Villa di Serio",
      "nomeStraniero": null,
      "codiceCatastale": "L936",
      "cap": "24020",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.villadiserio.bg.it",
      "pec": "protocollo.villadiserio@pec.it",
      "telefono": "+39 035654600",
      "fax": "+39 035654699",
      "coordinate": {
        "lat": 45.7216682434082,
        "lng": 9.734999656677246
      }
    },
    {
      "codice": "016242",
      "nome": "Villongo",
      "nomeStraniero": null,
      "codiceCatastale": "M045",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "anagrafe@comune.villongo.bg.it",
      "pec": "protocollo@pec.comune.villongo.bg.it",
      "telefono": "+39 035927222",
      "fax": "+39 035929521",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "016243",
      "nome": "Vilminore di Scalve",
      "nomeStraniero": null,
      "codiceCatastale": "M050",
      "cap": "24020",
      "prefisso": "0346",
      "provincia": "Bergamo",
      "email": "info@comune.vilminore.bg.it",
      "pec": "comune.vilminore.bg@legalmail.it",
      "telefono": "+39 034651014",
      "fax": "+39 034651694",
      "coordinate": {
        "lat": 46,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "016244",
      "nome": "Zandobbio",
      "nomeStraniero": null,
      "codiceCatastale": "M144",
      "cap": "24060",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "info@comune.zandobbio.bg.it",
      "pec": "protocollo@comunezandobbio.legalmail.it",
      "telefono": "+39 0354256911",
      "fax": "+39 0354256917",
      "coordinate": {
        "lat": 45.688331604003906,
        "lng": 9.852499961853027
      }
    },
    {
      "codice": "016245",
      "nome": "Zanica",
      "nomeStraniero": null,
      "codiceCatastale": "M147",
      "cap": "24050",
      "prefisso": "035",
      "provincia": "Bergamo",
      "email": "personale@comune.zanica.bg.it",
      "pec": "comune.zanica@pec.regione.lombardia.it",
      "telefono": "+39 0354245852",
      "fax": "+39 035671867",
      "coordinate": {
        "lat": 45.63944625854492,
        "lng": 9.68416690826416
      }
    },
    {
      "codice": "016246",
      "nome": "Zogno",
      "nomeStraniero": null,
      "codiceCatastale": "M184",
      "cap": "24019",
      "prefisso": "0345",
      "provincia": "Bergamo",
      "email": "info@comune.zogno.bg.it",
      "pec": "info@pec.comune.zogno.bg.it",
      "telefono": "+39 0345 55011",
      "fax": "+39 0345 93406",
      "coordinate": {
        "lat": 45.793888092041016,
        "lng": 9.665555953979492
      }
    }
  ],
  "Rovigo": [
    {
      "codice": "029001",
      "nome": "Adria",
      "nomeStraniero": null,
      "codiceCatastale": "A059",
      "cap": "45011",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "protocollo@comune.adria.ro.it",
      "pec": "protocollo.comune.adria.ro@pecveneto.it",
      "telefono": "+39 04269411",
      "fax": "+39 0426900380",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 12.050000190734863
      }
    },
    {
      "codice": "029002",
      "nome": "Ariano nel Polesine",
      "nomeStraniero": null,
      "codiceCatastale": "A400",
      "cap": "45012",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "segreteria@comune.arianonelpolesine.ro.it",
      "pec": "protocollo.comune.arianonelpolesine.ro@pecveneto.it",
      "telefono": "+39 0426/71131",
      "fax": "+39 0426/71170",
      "coordinate": {
        "lat": 44.946083068847656,
        "lng": 12.124921798706055
      }
    },
    {
      "codice": "029003",
      "nome": "Arquà Polesine",
      "nomeStraniero": null,
      "codiceCatastale": "A435",
      "cap": "45031",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "info@comune.arqua.ro.it",
      "pec": "comune.arqua.ro@legalmail.it",
      "telefono": "+39 0425/91051",
      "fax": "+39 0425/91766",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.733333587646484
      }
    },
    {
      "codice": "029004",
      "nome": "Badia Polesine",
      "nomeStraniero": null,
      "codiceCatastale": "A539",
      "cap": "45021",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "segreteria@comune.badiapolesine.ro.it",
      "pec": "segreteria.comune.badiapolesine.ro@pecveneto.it",
      "telefono": "+39 0425/53671",
      "fax": "+39 0425/53678",
      "coordinate": {
        "lat": 45.09403991699219,
        "lng": 11.493439674377441
      }
    },
    {
      "codice": "029005",
      "nome": "Bagnolo di Po",
      "nomeStraniero": null,
      "codiceCatastale": "A574",
      "cap": "45022",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.bagnolodipo.ro.it",
      "pec": "protocollo@pec.comune.bagnolodipo.ro.it",
      "telefono": "+39 0425/704002",
      "fax": "+39 0425/704424",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.5
      }
    },
    {
      "codice": "029006",
      "nome": "Bergantino",
      "nomeStraniero": null,
      "codiceCatastale": "A795",
      "cap": "45032",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "demografici@comune.bergantino.ro.it",
      "pec": "comune.bergantino.ro@pecveneto.it",
      "telefono": "+39 0425/805251",
      "fax": "+39 0425/805230",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 11.25
      }
    },
    {
      "codice": "029007",
      "nome": "Bosaro",
      "nomeStraniero": null,
      "codiceCatastale": "B069",
      "cap": "45033",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "segreteria-protocollo@comune.bosaro.ro.it",
      "pec": "protocollo.comune.bosaro.ro@legalmail.it",
      "telefono": "+39 0425/932029",
      "fax": "+39 0425/465140",
      "coordinate": {
        "lat": 45,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "029008",
      "nome": "Calto",
      "nomeStraniero": null,
      "codiceCatastale": "B432",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "comune@calto.info",
      "pec": "comune.calto.ro@pecveneto.it",
      "telefono": "+39 0425/86003",
      "fax": "+39 0425/804035",
      "coordinate": {
        "lat": 45,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "029009",
      "nome": "Canaro",
      "nomeStraniero": null,
      "codiceCatastale": "B578",
      "cap": "45034",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "demografici@comune.canaro.ro.it",
      "pec": "canaro.ro@cert.ip-veneto.net",
      "telefono": "+39 0425/940016 int.2",
      "fax": "+39 0425/440000",
      "coordinate": {
        "lat": 44.93333435058594,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "029010",
      "nome": "Canda",
      "nomeStraniero": null,
      "codiceCatastale": "B582",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "ufficiosegreteria@comune.canda.ro.it",
      "pec": "comune.canda.ro@pecveneto.it",
      "telefono": "0425/702012",
      "fax": "0425/702018",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 11.516666412353516
      }
    },
    {
      "codice": "029011",
      "nome": "Castelguglielmo",
      "nomeStraniero": null,
      "codiceCatastale": "C122",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "info@comune.castelguglielmo.ro.it",
      "pec": "protocollo.comune.castelguglielmo.ro@pecveneto.it",
      "telefono": "+39 0425/707075",
      "fax": "+39 0425/707117",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "029012",
      "nome": "Castelmassa",
      "nomeStraniero": null,
      "codiceCatastale": "C207",
      "cap": "45035",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.castelmassa.ro.it",
      "pec": "comune.castelmassa@pec.it",
      "telefono": "+39 0425/846716",
      "fax": "+39 0425/846799",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "029013",
      "nome": "Castelnovo Bariano",
      "nomeStraniero": null,
      "codiceCatastale": "C215",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.castelnovobariano.ro.it",
      "pec": "protocollo.comune.castelnovobariano.ro@pecveneto.it",
      "telefono": "+39 0425/81283",
      "fax": "+39 0425/81532",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "029014",
      "nome": "Ceneselli",
      "nomeStraniero": null,
      "codiceCatastale": "C461",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "statocivile@comuneceneselli.ro.it",
      "pec": "segreteria.comune.ceneselli.ro@pecveneto.it",
      "telefono": "+39 0425/88022",
      "fax": "+39 0425/849057",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "029015",
      "nome": "Ceregnano",
      "nomeStraniero": null,
      "codiceCatastale": "C500",
      "cap": "45010",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "info@comune.ceregnano.ro.it",
      "pec": "protocollo.comune.ceregnano.ro@pecveneto.it",
      "telefono": "+39 0425/476050",
      "fax": "+39 0425/476337",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "029017",
      "nome": "Corbola",
      "nomeStraniero": null,
      "codiceCatastale": "C987",
      "cap": "45015",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "info@comune.corbola.ro.it",
      "pec": "comune.corbola.ro@legalmail.it",
      "telefono": "+39 0426/45450",
      "fax": "+39 0426/953112",
      "coordinate": {
        "lat": 45,
        "lng": 12.083333015441895
      }
    },
    {
      "codice": "029018",
      "nome": "Costa di Rovigo",
      "nomeStraniero": null,
      "codiceCatastale": "D105",
      "cap": "45023",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.costadirovigo.ro.it",
      "pec": "protocollo.comune.costadirovigo.ro@pecveneto.it",
      "telefono": "+39 0425/497272",
      "fax": "+39 0425/497149",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 11.699999809265137
      }
    },
    {
      "codice": "029019",
      "nome": "Crespino",
      "nomeStraniero": null,
      "codiceCatastale": "D161",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.crespino.ro.it",
      "pec": "segreteria.comune.crespino.ro@pecveneto.it",
      "telefono": "+39 0425/785041",
      "fax": "+39 0425785043",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "029021",
      "nome": "Ficarolo",
      "nomeStraniero": null,
      "codiceCatastale": "D568",
      "cap": "45036",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "info@comune.ficarolo.ro.it",
      "pec": "protocollo.comune.ficarolo.ro@pecveneto.it",
      "telefono": "+39 0425 708072",
      "fax": "+39 0425 708393",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "029022",
      "nome": "Fiesso Umbertiano",
      "nomeStraniero": null,
      "codiceCatastale": "D577",
      "cap": "45024",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comunefiessoro.it",
      "pec": "info@pec.comunefiessoro.it",
      "telefono": "+39 0425/741300",
      "fax": "+39 0425/741310",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 11.600000381469727
      }
    },
    {
      "codice": "029023",
      "nome": "Frassinelle Polesine",
      "nomeStraniero": null,
      "codiceCatastale": "D776",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "protocollo@comune.frassinelle-polesine.ro.it",
      "pec": "comune.frassinellepolesine@interfreepec.it",
      "telefono": "+39 0425/933013",
      "fax": "+39 0425/933560",
      "coordinate": {
        "lat": 45,
        "lng": 11.699999809265137
      }
    },
    {
      "codice": "029024",
      "nome": "Fratta Polesine",
      "nomeStraniero": null,
      "codiceCatastale": "D788",
      "cap": "45025",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "demografici@comune.frattapolesine.ro.it",
      "pec": "comune.frattapolesine@legalmail.it",
      "telefono": "+39 0425/668030",
      "fax": "+39 0425/668607",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 11.649999618530273
      }
    },
    {
      "codice": "029025",
      "nome": "Gaiba",
      "nomeStraniero": null,
      "codiceCatastale": "D855",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "info@comune.gaiba.ro.it",
      "pec": "info@pec.comune.gaiba.ro.it",
      "telefono": "+39 0425/709926",
      "fax": "+39 0425/709725",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "029026",
      "nome": "Gavello",
      "nomeStraniero": null,
      "codiceCatastale": "D942",
      "cap": "45010",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "ufficiodemografico@comune.gavello.ro.it",
      "pec": "protocollogavello@pcert.postecert.it",
      "telefono": "+39 0425/778020",
      "fax": "+39 0425/778452",
      "coordinate": {
        "lat": 45.021846771240234,
        "lng": 11.914426803588867
      }
    },
    {
      "codice": "029027",
      "nome": "Giacciano con Baruchella",
      "nomeStraniero": null,
      "codiceCatastale": "E008",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "protocollo@comune.giacciano.ro.it",
      "pec": "comunegiacciano-anagrafe@pec.it",
      "telefono": "+39 0425/50369",
      "fax": "+39 0425/50560",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "029028",
      "nome": "Guarda Veneta",
      "nomeStraniero": null,
      "codiceCatastale": "E240",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "comune.guardaveneta@comune.guardaveneta.ro.it",
      "pec": "comune.guardaveneta.ro@pecveneto.it",
      "telefono": "+39 0425487090",
      "fax": "+39 0425487015",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "029029",
      "nome": "Lendinara",
      "nomeStraniero": null,
      "codiceCatastale": "E522",
      "cap": "45026",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "elettorale@comune.lendinara.ro.it",
      "pec": "segreteria@pec.comune.lendinara.ro.it",
      "telefono": "+39 0425605611",
      "fax": "+39 0425600977",
      "coordinate": {
        "lat": 45.084999084472656,
        "lng": 11.600555419921875
      }
    },
    {
      "codice": "029030",
      "nome": "Loreo",
      "nomeStraniero": null,
      "codiceCatastale": "E689",
      "cap": "45017",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "demografici@comune.loreo.ro.it",
      "pec": "comune.loreo@anutel.it",
      "telefono": "+39 0426/336801",
      "fax": "+39 0426/669186",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 12.199999809265137
      }
    },
    {
      "codice": "029031",
      "nome": "Lusia",
      "nomeStraniero": null,
      "codiceCatastale": "E761",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "protocollo@comune.lusia.ro.it",
      "pec": "protocollo.commercio.comune.lusia.ro@legalmail.it",
      "telefono": "+39 0425/607026",
      "fax": "+39 0425/667621",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "029032",
      "nome": "Melara",
      "nomeStraniero": null,
      "codiceCatastale": "F095",
      "cap": "45037",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "info@comune.melara.ro.it",
      "pec": "info.comune.melara.ro@pec.it",
      "telefono": "+39 0425/89077",
      "fax": "+39 0425/89078",
      "coordinate": {
        "lat": 45.06318283081055,
        "lng": 11.198457717895508
      }
    },
    {
      "codice": "029033",
      "nome": "Occhiobello",
      "nomeStraniero": null,
      "codiceCatastale": "F994",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "enrico.baldoni@comune.occhiobello.ro.it",
      "pec": "occhiobello@postemailcertificata.it",
      "telefono": "+39 0425/766134",
      "fax": "+39 0425/750443",
      "coordinate": {
        "lat": 44.921600341796875,
        "lng": 11.581199645996094
      }
    },
    {
      "codice": "029034",
      "nome": "Papozze",
      "nomeStraniero": null,
      "codiceCatastale": "G323",
      "cap": "45010",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.papozze.ro.it",
      "pec": "anagrafe.comune.papozze.ro@pecveneto.it",
      "telefono": "+39 0426/44230",
      "fax": "+39 0426/44640",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "029035",
      "nome": "Pettorazza Grimani",
      "nomeStraniero": null,
      "codiceCatastale": "G525",
      "cap": "45010",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.pettorazza.ro.it",
      "pec": "protocollo.comune.pettorazza.ro.it@pecveneto.it",
      "telefono": "+39 0426/500006",
      "fax": "+39 0426/500005",
      "coordinate": {
        "lat": 45.135250091552734,
        "lng": 11.989360809326172
      }
    },
    {
      "codice": "029036",
      "nome": "Pincara",
      "nomeStraniero": null,
      "codiceCatastale": "G673",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.pincara.ro.it",
      "pec": "comune.pincara.ro@pecveneto.it",
      "telefono": "+39 0425/745100",
      "fax": "+39 0425/745058",
      "coordinate": {
        "lat": 45,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "029037",
      "nome": "Polesella",
      "nomeStraniero": null,
      "codiceCatastale": "G782",
      "cap": "45038",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "segreteria@comune.polesella.ro.it",
      "pec": "protocollo.comune.polesella.ro@pecveneto.it",
      "telefono": "+39 0425/447114",
      "fax": "+39 0425/446077",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 11.75
      }
    },
    {
      "codice": "029038",
      "nome": "Pontecchio Polesine",
      "nomeStraniero": null,
      "codiceCatastale": "G836",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "segreteria@comune.pontecchio.ro.it",
      "pec": "segreteria.comune.pontecchio.ro@pecveneto.it",
      "telefono": "+39 0425/492017",
      "fax": "+39 0425/492384",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.816666603088379
      }
    },
    {
      "codice": "029039",
      "nome": "Porto Tolle",
      "nomeStraniero": null,
      "codiceCatastale": "G923",
      "cap": "45018",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "chiara.stoppa@comune.portotolle.ro.it",
      "pec": "affarigenerali.comune.portotolle.ro@pecveneto.it",
      "telefono": "+39 0426394439",
      "fax": "+39 0426 394475",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 12.316666603088379
      }
    },
    {
      "codice": "029052",
      "nome": "Porto Viro",
      "nomeStraniero": null,
      "codiceCatastale": "G926",
      "cap": "45014",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "segreteria@comune.portoviro.ro.it",
      "pec": "comune.portoviro@cert.legalmail.it",
      "telefono": "+39 0426/325711",
      "fax": "+39 0426/633342",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 12.216666221618652
      }
    },
    {
      "codice": "029040",
      "nome": "Rosolina",
      "nomeStraniero": null,
      "codiceCatastale": "H573",
      "cap": "45010",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "protocollo@comune.rosolina.ro.it",
      "pec": "protocollo@pec.comune.rosolina.ro.it",
      "telefono": "+39 0426/340211",
      "fax": "+39 0426/664285",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 12.233333587646484
      }
    },
    {
      "codice": "029041",
      "nome": "Rovigo",
      "nomeStraniero": null,
      "codiceCatastale": "H620",
      "cap": "45100",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "elettorale@comune.rovigo.it",
      "pec": "comunerovigo@legalmail.it",
      "telefono": "+39 0425/206280",
      "fax": "+39 0425/206355",
      "coordinate": {
        "lat": 45.08089828491211,
        "lng": 11.793999671936035
      }
    },
    {
      "codice": "029042",
      "nome": "Salara",
      "nomeStraniero": null,
      "codiceCatastale": "H689",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "comune@salara.info",
      "pec": "info.comune.salara@pec.it",
      "telefono": "+39 0425/705116",
      "fax": "+39 0425/705279",
      "coordinate": {
        "lat": 44.98331069946289,
        "lng": 11.426835060119629
      }
    },
    {
      "codice": "029043",
      "nome": "San Bellino",
      "nomeStraniero": null,
      "codiceCatastale": "H768",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.sanbellino.ro.it",
      "pec": "anagrafe.comune.sanbellino.ro@pecveneto.it",
      "telefono": "+39 0425/703009",
      "fax": "+39 0425/703259",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "029044",
      "nome": "San Martino di Venezze",
      "nomeStraniero": null,
      "codiceCatastale": "H996",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@venezze.it",
      "pec": "segreteria@pec.comune.sanmartinodivenezze.ro.it",
      "telefono": "+39 0425/99048",
      "fax": "+39 0425/99988",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "029045",
      "nome": "Stienta",
      "nomeStraniero": null,
      "codiceCatastale": "I953",
      "cap": "45039",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "demografici1@comune.stienta.ro.it",
      "pec": "info@pec.comune.stienta.ro.it",
      "telefono": "+39 0425/746111",
      "fax": "+39 0425/746161",
      "coordinate": {
        "lat": 44.93333435058594,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "029046",
      "nome": "Taglio di Po",
      "nomeStraniero": null,
      "codiceCatastale": "L026",
      "cap": "45019",
      "prefisso": "0426",
      "provincia": "Rovigo",
      "email": "protocollo@comune.tagliodipo.ro.it",
      "pec": "up.comune.tagliodipo.ro@pecveneto.it",
      "telefono": "+39 0426 347111",
      "fax": "+39 0426 347187",
      "coordinate": {
        "lat": 45,
        "lng": 12.216666221618652
      }
    },
    {
      "codice": "029047",
      "nome": "Trecenta",
      "nomeStraniero": null,
      "codiceCatastale": "L359",
      "cap": "45027",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "trecenta@comune.trecenta.ro.it",
      "pec": "protocollo.comune.trecenta.ro@pecveneto.it",
      "telefono": "+39 0425/700300",
      "fax": "+39 0425/700265",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "029048",
      "nome": "Villadose",
      "nomeStraniero": null,
      "codiceCatastale": "L939",
      "cap": "45010",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "demografici@comune.villadose.ro.it",
      "pec": "comune.villadose.ro@pecveneto.it",
      "telefono": "+39 0425/90220",
      "fax": "+39 0425/90322",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "029049",
      "nome": "Villamarzana",
      "nomeStraniero": null,
      "codiceCatastale": "L967",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.villamarzana.ro.it",
      "pec": "segretario.comune.villamarzana.ro@pecveneto.it",
      "telefono": "+39 0425/938018",
      "fax": "+39 0425/938174",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 11.683333396911621
      }
    },
    {
      "codice": "029050",
      "nome": "Villanova del Ghebbo",
      "nomeStraniero": null,
      "codiceCatastale": "L985",
      "cap": "45020",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "sociale@comune.villanovadelghebbo.ro.it",
      "pec": "info-comunevillanova@legalmail.it",
      "telefono": "+39 0425/648085",
      "fax": "+39 0425/650315",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 11.649999618530273
      }
    },
    {
      "codice": "029051",
      "nome": "Villanova Marchesana",
      "nomeStraniero": null,
      "codiceCatastale": "L988",
      "cap": "45030",
      "prefisso": "0425",
      "provincia": "Rovigo",
      "email": "anagrafe@comune.villanovamarchesana.ro.it",
      "pec": "protocollovillanovamarchesana@pcert.postecert.it",
      "telefono": "+39 0425/770934",
      "fax": "+39 0425/770663",
      "coordinate": {
        "lat": 45,
        "lng": 11.966666221618652
      }
    }
  ],
  "Verona": [
    {
      "codice": "023001",
      "nome": "Affi",
      "nomeStraniero": null,
      "codiceCatastale": "A061",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "demografici@comune.affi.vr.it",
      "pec": "protocollo.affi@stpec.it",
      "telefono": "+39 045 7235238",
      "fax": "+39 045 6260473",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "023002",
      "nome": "Albaredo d'Adige",
      "nomeStraniero": null,
      "codiceCatastale": "A137",
      "cap": "37040",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "urp@comune.albaredodadige.vr.it",
      "pec": "protocollo.albaredodadige@pec.it",
      "telefono": "+39 045/6608030",
      "fax": "+39 045/7000956",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "023003",
      "nome": "Angiari",
      "nomeStraniero": null,
      "codiceCatastale": "A292",
      "cap": "37050",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "segreteria@unionedxadige.it",
      "pec": "segreteria.unionedxadige.vr@pecveneto.it",
      "telefono": "+39 0442/97007",
      "fax": "+39 0442/98210",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "023004",
      "nome": "Arcole",
      "nomeStraniero": null,
      "codiceCatastale": "A374",
      "cap": "37040",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.arcole.vr.it",
      "pec": "arcole.vr@cert.ip-veneto.net",
      "telefono": "0457639613",
      "fax": "+39 045/7635532",
      "coordinate": {
        "lat": 45.358333587646484,
        "lng": 11.286110877990723
      }
    },
    {
      "codice": "023005",
      "nome": "Badia Calavena",
      "nomeStraniero": null,
      "codiceCatastale": "A540",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocollo@comune.badiacalavena.vr.it",
      "pec": "comune.badiacalavena@pec.it",
      "telefono": "+39 0457810503",
      "fax": "+39 0457810266",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "023006",
      "nome": "Bardolino",
      "nomeStraniero": null,
      "codiceCatastale": "A650",
      "cap": "37011",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "giuditta.tabarelli@comune.bardolino.vr.it",
      "pec": "comune.bardolino@legalmail.it",
      "telefono": "+39 045/6213228",
      "fax": "+39 045/6213230",
      "coordinate": {
        "lat": 45.551666259765625,
        "lng": 10.721388816833496
      }
    },
    {
      "codice": "023007",
      "nome": "Belfiore",
      "nomeStraniero": null,
      "codiceCatastale": "A737",
      "cap": "37050",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.belfiore.vr.it",
      "pec": "belfiore.vr@pec.wmail.it",
      "telefono": "+39 045/6145011",
      "fax": "+39 045/6149016",
      "coordinate": {
        "lat": 45.37962341308594,
        "lng": 11.208975791931152
      }
    },
    {
      "codice": "023008",
      "nome": "Bevilacqua",
      "nomeStraniero": null,
      "codiceCatastale": "A837",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "segreteria@comune.bevilacqua.vr.it",
      "pec": "demografici.bevilacqua@pec.comune.bevilacqua.vr.it",
      "telefono": "+39 0442/647215",
      "fax": "+39 0442/647212",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "023009",
      "nome": "Bonavigo",
      "nomeStraniero": null,
      "codiceCatastale": "A964",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.bonavigo.vr.it",
      "pec": "info.bonavigo@stpec.it",
      "telefono": "+39 0442/73010",
      "fax": "+39 0442/670010",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "023010",
      "nome": "Boschi Sant'Anna",
      "nomeStraniero": null,
      "codiceCatastale": "B070",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.boschisantanna.vr.it",
      "pec": "protocollo@pec.comune.boschisantanna.vr.it",
      "telefono": "+39 0442/99133",
      "fax": "+39 0442/99268",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "023011",
      "nome": "Bosco Chiesanuova",
      "nomeStraniero": null,
      "codiceCatastale": "B073",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.boscochiesanuova.vr.it",
      "pec": "boscochiesanuova@pec.aruba.it",
      "telefono": "+39 045/6780383",
      "fax": "+39 045/7050389",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "023012",
      "nome": "Bovolone",
      "nomeStraniero": null,
      "codiceCatastale": "B107",
      "cap": "37051",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "amministrazione@comune.bovolone.vr.it",
      "pec": "protocollo@pec.comune.bovolone.vr.it",
      "telefono": "+39 045/6995111",
      "fax": "+39 0456995264",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "023013",
      "nome": "Brentino Belluno",
      "nomeStraniero": null,
      "codiceCatastale": "B152",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.brentino.vr.it",
      "pec": "comune.brentinobelluno@pec.it",
      "telefono": "+39 045/6284062",
      "fax": "+39 045/6284087",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 10.899999618530273
      }
    },
    {
      "codice": "023014",
      "nome": "Brenzone sul Garda",
      "nomeStraniero": null,
      "codiceCatastale": "B154",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocollo@comune.brenzone.vr.it",
      "pec": "brenzone.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6589500",
      "fax": "+39 045/6589553",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "023015",
      "nome": "Bussolengo",
      "nomeStraniero": null,
      "codiceCatastale": "B296",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.bussolengo.vr.it",
      "pec": "comune.bussolengo@cert.legalmail.it",
      "telefono": "+39 045/6769947",
      "fax": "+39 045/6754508",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "023016",
      "nome": "Buttapietra",
      "nomeStraniero": null,
      "codiceCatastale": "B304",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.buttapietra.vr.it",
      "pec": "comune.buttapietra@halleycert.it",
      "telefono": "+39 045/6660002",
      "fax": "+39 045/6660485",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 10.933333396911621
      }
    },
    {
      "codice": "023017",
      "nome": "Caldiero",
      "nomeStraniero": null,
      "codiceCatastale": "B402",
      "cap": "37040",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.caldiero.vr.it",
      "pec": "protocollogenerale@pec.comune.caldiero.vr.it",
      "telefono": "+39 045/7650023",
      "fax": "+39 045/6172364",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 11.183333396911621
      }
    },
    {
      "codice": "023018",
      "nome": "Caprino Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "B709",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "demografici@comune.caprinoveronese.vr.it",
      "pec": "comune.caprinoveronese.vr@pecveneto.it",
      "telefono": "+39 045/6209930",
      "fax": "+39 045/6230120",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "023019",
      "nome": "Casaleone",
      "nomeStraniero": null,
      "codiceCatastale": "B886",
      "cap": "37052",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "amministrazione@comunecasaleone.it",
      "pec": "segreteria.comune.casaleone.vr@pecveneto.it",
      "telefono": "+39 0442/328703",
      "fax": "+39 0442/339897",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "023020",
      "nome": "Castagnaro",
      "nomeStraniero": null,
      "codiceCatastale": "C041",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "elettorale@comunedicastagnaro.it",
      "pec": "castagnaro.vr@cert.ip-veneto.net",
      "telefono": "+39 0442/682816",
      "fax": "+39 0442/682818",
      "coordinate": {
        "lat": 45.12075424194336,
        "lng": 11.410750389099121
      }
    },
    {
      "codice": "023021",
      "nome": "Castel d'Azzano",
      "nomeStraniero": null,
      "codiceCatastale": "C078",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.castel-d-azzano.vr.it",
      "pec": "casteldazzano@legalmail.it",
      "telefono": "+39 0459215911",
      "fax": "+39 0459215913",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "023022",
      "nome": "Castelnuovo del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "C225",
      "cap": "37014",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocollo@castelnuovodg.it",
      "pec": "castelnuovodg@legalmail.it",
      "telefono": "+39 045/6459920",
      "fax": "+39 045/6459921",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "023023",
      "nome": "Cavaion Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "C370",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comunecavaion.it",
      "pec": "segreteria@pec.comunecavaion.it",
      "telefono": "+39 045/6265711",
      "fax": "+39 045/6265730",
      "coordinate": {
        "lat": 45.54008865356445,
        "lng": 10.770480155944824
      }
    },
    {
      "codice": "023024",
      "nome": "Cazzano di Tramigna",
      "nomeStraniero": null,
      "codiceCatastale": "C412",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.cazzanoditramigna.vr.it",
      "pec": "comune.cazzanoditramigna.vr@pecveneto.it",
      "telefono": "+39 045/7820506",
      "fax": "+39 045/7820411",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "023025",
      "nome": "Cerea",
      "nomeStraniero": null,
      "codiceCatastale": "C498",
      "cap": "37053",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "walter.pace@comune.cerea.vr.it",
      "pec": "comunecerea@halleypec.it",
      "telefono": "+39 0442/80055",
      "fax": "+39 0442/320342",
      "coordinate": {
        "lat": 45.189361572265625,
        "lng": 11.216609954833984
      }
    },
    {
      "codice": "023026",
      "nome": "Cerro Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "C538",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comune@comune.cerroveronese.vr.it",
      "pec": "comune.cerroveronese@pec.it",
      "telefono": "+39 045/7080005",
      "fax": "+39 045/7080422",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "023027",
      "nome": "Cologna Veneta",
      "nomeStraniero": null,
      "codiceCatastale": "C890",
      "cap": "37044",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "segr@comune.cologna-veneta.vr.it",
      "pec": "colognaveneta.vr@cert.ip-veneto.net",
      "telefono": "+39 0442413511",
      "fax": "+39 0442410811",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "023028",
      "nome": "Colognola ai Colli",
      "nomeStraniero": null,
      "codiceCatastale": "C897",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comunecolognola.it",
      "pec": "colognolaaicolli.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6159611",
      "fax": "+39 045/6159619",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "023029",
      "nome": "Concamarise",
      "nomeStraniero": null,
      "codiceCatastale": "C943",
      "cap": "37050",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "anagrafe@comune.concamarise.vr.it",
      "pec": "comune.concamarise@pec.it",
      "telefono": "+39 0442/374055",
      "fax": "+39 0442/374066",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "023030",
      "nome": "Costermano sul Garda",
      "nomeStraniero": null,
      "codiceCatastale": "D118",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segretario@comunecostermano.it",
      "pec": "comunecostermano@cert.ip-veneto.net",
      "telefono": "+39 045/6208122",
      "fax": "+39 045/7200822",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "023031",
      "nome": "Dolcè",
      "nomeStraniero": null,
      "codiceCatastale": "D317",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "areaanagrafe@comunedolce.it",
      "pec": "info@pec.comunedolce.it",
      "telefono": "+39 045/7290070",
      "fax": "+39 045/7290230",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "023032",
      "nome": "Erbè",
      "nomeStraniero": null,
      "codiceCatastale": "D419",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.erbe.vr.it",
      "pec": "erbe.vr@cert.ip-veneto.net",
      "telefono": "+39 045/7325005",
      "fax": "+39 045/7325125",
      "coordinate": {
        "lat": 45.25,
        "lng": 10.966666221618652
      }
    },
    {
      "codice": "023033",
      "nome": "Erbezzo",
      "nomeStraniero": null,
      "codiceCatastale": "D420",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comune.erbezzo@tiscali.it",
      "pec": "comune.erbezzo.vr@pecveneto.it",
      "telefono": "+39 045/7075013",
      "fax": "+39 045/7075218",
      "coordinate": {
        "lat": 45.63959884643555,
        "lng": 11.000570297241211
      }
    },
    {
      "codice": "023034",
      "nome": "Ferrara di Monte Baldo",
      "nomeStraniero": null,
      "codiceCatastale": "D549",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.ferraradimontebaldo.vr.it",
      "pec": "protocollo@comune.ferraradimontebaldo.legalmail.it",
      "telefono": "+39 045/6247002",
      "fax": "+39 045/6247055",
      "coordinate": {
        "lat": 45.67361068725586,
        "lng": 10.860833168029785
      }
    },
    {
      "codice": "023035",
      "nome": "Fumane",
      "nomeStraniero": null,
      "codiceCatastale": "D818",
      "cap": "37022",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comunedifumane.it",
      "pec": "fumane.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6832819",
      "fax": "+39 045/6832888",
      "coordinate": {
        "lat": 45.54316711425781,
        "lng": 10.8817777633667
      }
    },
    {
      "codice": "023036",
      "nome": "Garda",
      "nomeStraniero": null,
      "codiceCatastale": "D915",
      "cap": "37016",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comunedigarda.it",
      "pec": "comune.garda@pec.it",
      "telefono": "+39 045/6208444",
      "fax": "+39 045/6208426",
      "coordinate": {
        "lat": 45.5724983215332,
        "lng": 10.671667098999023
      }
    },
    {
      "codice": "023037",
      "nome": "Gazzo Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "D957",
      "cap": "37060",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "segreteria@comune.gazzo.vr.it",
      "pec": "segreteria@pec.comune.gazzo.vr.it",
      "telefono": "+39 0442/579000",
      "fax": "+39 0442/579036",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "023038",
      "nome": "Grezzana",
      "nomeStraniero": null,
      "codiceCatastale": "E171",
      "cap": "37023",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.grezzana.vr.it",
      "pec": "grezzana.vr@cert.ip-veneto.net",
      "telefono": "+39 045/8872511",
      "fax": "+39 045/8872510",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "023039",
      "nome": "Illasi",
      "nomeStraniero": null,
      "codiceCatastale": "E284",
      "cap": "37031",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comuneillasi.it",
      "pec": "protocollo.comune.illasi.vr@pecveneto.it",
      "telefono": "+39 045/7830413",
      "fax": "+39 045/6520390",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 11.183333396911621
      }
    },
    {
      "codice": "023040",
      "nome": "Isola della Scala",
      "nomeStraniero": null,
      "codiceCatastale": "E349",
      "cap": "37063",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune-isola.it",
      "pec": "isoladellascala@cert.ip-veneto.net",
      "telefono": "+39 045/6631911",
      "fax": "+39 045/6630170",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "023041",
      "nome": "Isola Rizza",
      "nomeStraniero": null,
      "codiceCatastale": "E358",
      "cap": "37050",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "demografici.isolarizza@unionedxadige.it",
      "pec": "servizidemografici.isolarizza@legalmail.it",
      "telefono": "+39 045/7135022",
      "fax": "+39 045/7135640",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "023042",
      "nome": "Lavagno",
      "nomeStraniero": null,
      "codiceCatastale": "E489",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.lavagno.vr.it",
      "pec": "comune.lavagno.vr@pecveneto.it",
      "telefono": "+39 045/8989311",
      "fax": "+39 045/982546",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "023043",
      "nome": "Lazise",
      "nomeStraniero": null,
      "codiceCatastale": "E502",
      "cap": "37017",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.lazise.vr.it",
      "pec": "comune.lazise.urp@halleypec.it",
      "telefono": "+39 045/6445120",
      "fax": "+39 045/7580722",
      "coordinate": {
        "lat": 45.505279541015625,
        "lng": 10.732500076293945
      }
    },
    {
      "codice": "023044",
      "nome": "Legnago",
      "nomeStraniero": null,
      "codiceCatastale": "E512",
      "cap": "37045",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.legnago.vr.it",
      "pec": "legnago.vr@cert.ip-veneto.net",
      "telefono": "+39 0442/634825",
      "fax": "+39 0442/634803",
      "coordinate": {
        "lat": 45.19287109375,
        "lng": 11.311479568481445
      }
    },
    {
      "codice": "023045",
      "nome": "Malcesine",
      "nomeStraniero": null,
      "codiceCatastale": "E848",
      "cap": "37018",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "saglia.elena@comunemalcesine.it",
      "pec": "protocollo@pec.comunemalcesine.it",
      "telefono": "+39 0456589941",
      "fax": null,
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 10.816666603088379
      }
    },
    {
      "codice": "023046",
      "nome": "Marano di Valpolicella",
      "nomeStraniero": null,
      "codiceCatastale": "E911",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comunemaranovalp.it",
      "pec": "info@pec.comunemaranovalpolicella.vr.it",
      "telefono": "+39 045/7755126",
      "fax": "+39 045/7755203",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 10.916666984558105
      }
    },
    {
      "codice": "023047",
      "nome": "Mezzane di Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "F172",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.mezzane.vr.it",
      "pec": "segreteria.mezzane@stpec.it",
      "telefono": "+39 045/8880100",
      "fax": "+39 045/8880084",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "023048",
      "nome": "Minerbe",
      "nomeStraniero": null,
      "codiceCatastale": "F218",
      "cap": "37046",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "municipio@comune.minerbe.vr.it",
      "pec": "comune.minerbe@halleypec.it",
      "telefono": "+39 0442/633411",
      "fax": "+39 0442/633436",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "023049",
      "nome": "Montecchia di Crosara",
      "nomeStraniero": null,
      "codiceCatastale": "F461",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comune.montecchia@tiscali.it",
      "pec": "protocollo.comune.montecchiadicrosara.vr@pecveneto.it",
      "telefono": "+39 045/7450038",
      "fax": "+39 045/7450366",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.25
      }
    },
    {
      "codice": "023050",
      "nome": "Monteforte d'Alpone",
      "nomeStraniero": null,
      "codiceCatastale": "F508",
      "cap": "37032",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.montefortedalpone.vr.it",
      "pec": "pec@pec.comune.montefortedalpone.vr.it",
      "telefono": "+39 045/6137311",
      "fax": "+39 045/6102595",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "023051",
      "nome": "Mozzecane",
      "nomeStraniero": null,
      "codiceCatastale": "F789",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.mozzecane.vr.it",
      "pec": "segreteria.comune.mozzecane.vr@pecveneto.it",
      "telefono": "+39 045/6335807",
      "fax": "+39 045/6335833",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 10.816666603088379
      }
    },
    {
      "codice": "023052",
      "nome": "Negrar di Valpolicella",
      "nomeStraniero": null,
      "codiceCatastale": "F861",
      "cap": "37024",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comunenegrar.it",
      "pec": "protocollo@pec.comunenegrar.it",
      "telefono": "+39 045/6011611",
      "fax": "+39 045/6011619",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 10.933333396911621
      }
    },
    {
      "codice": "023053",
      "nome": "Nogara",
      "nomeStraniero": null,
      "codiceCatastale": "F918",
      "cap": "37050",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.nogara.vr.it",
      "pec": "comune.nogara@certificata.com",
      "telefono": "+39 0442/513301",
      "fax": "+39 0442/513333",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "023054",
      "nome": "Nogarole Rocca",
      "nomeStraniero": null,
      "codiceCatastale": "F921",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria-ced@comune.nogarolerocca.vr.it",
      "pec": "urp.nogarolerocca@legalmail.it",
      "telefono": "+39 045/7925384",
      "fax": "+39 045/7925021",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 10.883333206176758
      }
    },
    {
      "codice": "023055",
      "nome": "Oppeano",
      "nomeStraniero": null,
      "codiceCatastale": "G080",
      "cap": "37050",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.oppeano.vr.it",
      "pec": "oppeano.vr@cert.ip-veneto.net",
      "telefono": "+39 045/7139211",
      "fax": "+39 045/6970586",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 11.183333396911621
      }
    },
    {
      "codice": "023056",
      "nome": "Palù",
      "nomeStraniero": null,
      "codiceCatastale": "G297",
      "cap": "37050",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comunepalu.it",
      "pec": "palu@cert.ip-veneto.net",
      "telefono": "+39 045/6070021",
      "fax": "+39 045/6070012",
      "coordinate": {
        "lat": 45.324729919433594,
        "lng": 11.156350135803223
      }
    },
    {
      "codice": "023057",
      "nome": "Pastrengo",
      "nomeStraniero": null,
      "codiceCatastale": "G365",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comunepastrengo.it",
      "pec": "protocollo.comune.pastrengo.vr@pecveneto.it",
      "telefono": "+39 045/6778801",
      "fax": "+39 045/6770053",
      "coordinate": {
        "lat": 45.5,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "023058",
      "nome": "Pescantina",
      "nomeStraniero": null,
      "codiceCatastale": "G481",
      "cap": "37026",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.pescantina.vr.it",
      "pec": "pescantina.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6764211",
      "fax": "+39 045/6764222",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 10.866666793823242
      }
    },
    {
      "codice": "023059",
      "nome": "Peschiera del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "G489",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.peschieradelgarda.vr.it",
      "pec": "comunepeschieradelgarda@pec.it",
      "telefono": "0456444706",
      "fax": null,
      "coordinate": {
        "lat": 45.4386100769043,
        "lng": 10.688611030578613
      }
    },
    {
      "codice": "023060",
      "nome": "Povegliano Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "G945",
      "cap": "37064",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "infocomune@comune.poveglianoveronese.vr.it",
      "pec": "poveglianoveronese.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6334111",
      "fax": "+39 045/633119",
      "coordinate": {
        "lat": 45.3473014831543,
        "lng": 10.880559921264648
      }
    },
    {
      "codice": "023061",
      "nome": "Pressana",
      "nomeStraniero": null,
      "codiceCatastale": "H048",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "protocollo@comune.pressana.vr.it",
      "pec": "protocollo@pec.comune.pressana.vr.it",
      "telefono": "+39 0442/84333",
      "fax": "+39 0442/84485",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "023062",
      "nome": "Rivoli Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "H356",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocollo@comune.rivoli.vr.it",
      "pec": "protocollo.comune.rivoli.vr@pecveneto.it",
      "telefono": "+39 045/7281166",
      "fax": "+39 045/7281114",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 10.816666603088379
      }
    },
    {
      "codice": "023063",
      "nome": "Roncà",
      "nomeStraniero": null,
      "codiceCatastale": "H522",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comune.ronca@tiscali.it",
      "pec": "comune.ronca@stpec.it",
      "telefono": "+39 045/7460411",
      "fax": "+39 045/461099",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "023064",
      "nome": "Ronco all'Adige",
      "nomeStraniero": null,
      "codiceCatastale": "H540",
      "cap": "37055",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comunedironco@tiscli.it",
      "pec": "protocollo.roncoalladige@stpec.it",
      "telefono": "+39 045/6608211",
      "fax": "+39 045/6615914",
      "coordinate": {
        "lat": 45.33649826049805,
        "lng": 11.246610641479492
      }
    },
    {
      "codice": "023065",
      "nome": "Roverchiara",
      "nomeStraniero": null,
      "codiceCatastale": "H606",
      "cap": "37050",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "servizidemografici@comune.roverchiara.vr.it",
      "pec": "protocollo.comune.roverchiara.vr@legalmail.it",
      "telefono": "+39 0442/689014",
      "fax": "+39 0442/689027",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.25
      }
    },
    {
      "codice": "023067",
      "nome": "Roverè Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "H608",
      "cap": "37028",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@rovereveronese.net",
      "pec": "segreteria.rovereveronese@stpec.it",
      "telefono": "+39 045/6518005",
      "fax": "+39 045/6518004",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "023066",
      "nome": "Roveredo di Guà",
      "nomeStraniero": null,
      "codiceCatastale": "H610",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.roveredodigua.vr.it",
      "pec": "protocollo@pec.comune.roveredodigua.vr.it",
      "telefono": "+39 0442/86014",
      "fax": "+39 0442/460110",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "023068",
      "nome": "Salizzole",
      "nomeStraniero": null,
      "codiceCatastale": "H714",
      "cap": "37056",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.salizzole.vr.it",
      "pec": "salizzole.vr@cert.ip-veneto.net",
      "telefono": "+39 045/7100013",
      "fax": "+39 045/7103111",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "023069",
      "nome": "San Bonifacio",
      "nomeStraniero": null,
      "codiceCatastale": "H783",
      "cap": "37040",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.sanbonifacio.vr.it",
      "pec": "protocollo-messi.comune.sanbonifacio.vr@pecveneto.it",
      "telefono": "+39 045/6132723",
      "fax": "+39 045/6132770",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "023070",
      "nome": "San Giovanni Ilarione",
      "nomeStraniero": null,
      "codiceCatastale": "H916",
      "cap": "37035",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocollo@comune.sangiovanniilarione.vr.it",
      "pec": "sangiovanniilarione@cert.ip-veneto.net",
      "telefono": "0456550444",
      "fax": "+39 045/6550533",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "023071",
      "nome": "San Giovanni Lupatoto",
      "nomeStraniero": null,
      "codiceCatastale": "H924",
      "cap": "37050",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "mirko.turco@comunelupatoto.it",
      "pec": "protocol.comune.sangiovannilupatoto.vr@pecveneto.it",
      "telefono": "+39 045/8290255",
      "fax": "+39 045/8290204",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "023073",
      "nome": "San Martino Buon Albergo",
      "nomeStraniero": null,
      "codiceCatastale": "I003",
      "cap": "37036",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "posta@comunesanmartinobuonalbergo.it",
      "pec": "comune.sanmartinobuonalbergo.vr@pecveneto.it",
      "telefono": "+39 045/8874216",
      "fax": "+39 045/8874253",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "023074",
      "nome": "San Mauro di Saline",
      "nomeStraniero": null,
      "codiceCatastale": "H712",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.sanmaurodisaline.vr.it",
      "pec": "comune.sanmaurodisaline.vr@pecveneto.it",
      "telefono": "+39 045/7840009",
      "fax": "+39 045/7840146",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "023075",
      "nome": "San Pietro di Morubio",
      "nomeStraniero": null,
      "codiceCatastale": "I105",
      "cap": "37050",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@unionedxadige.it",
      "pec": "segreteria.unionedxadige.vr@pecveneto.it",
      "telefono": "+39 045/6969000",
      "fax": "+39 045/6969016",
      "coordinate": {
        "lat": 45.25,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "023076",
      "nome": "San Pietro in Cariano",
      "nomeStraniero": null,
      "codiceCatastale": "I109",
      "cap": "37029",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.sanpietroincariano.vr.it",
      "pec": "sanpietrincariano.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6832114",
      "fax": "+39 045/6801268",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 10.883333206176758
      }
    },
    {
      "codice": "023079",
      "nome": "San Zeno di Montagna",
      "nomeStraniero": null,
      "codiceCatastale": "I414",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.sanzenodimontagna.vr.it",
      "pec": "sanzenodimontagna.vr@cert.ip-veneto.net",
      "telefono": "+39 045/7285950",
      "fax": "+39 045/7285222",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "023072",
      "nome": "Sanguinetto",
      "nomeStraniero": null,
      "codiceCatastale": "H944",
      "cap": "37058",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.sanguinetto.vr.it",
      "pec": "comune.sanguinetto.vr@pecveneto.it",
      "telefono": "044281036",
      "fax": null,
      "coordinate": {
        "lat": 45.182945251464844,
        "lng": 11.151527404785156
      }
    },
    {
      "codice": "023077",
      "nome": "Sant'Ambrogio di Valpolicella",
      "nomeStraniero": null,
      "codiceCatastale": "I259",
      "cap": "37015",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.santambrogio.vr.it",
      "pec": "s.ambrogiovalp@postemailcertificata.it",
      "telefono": "+39 045/6832621",
      "fax": "+39 045/6832647",
      "coordinate": {
        "lat": 45.52088928222656,
        "lng": 10.836179733276367
      }
    },
    {
      "codice": "023078",
      "nome": "Sant'Anna d'Alfaedo",
      "nomeStraniero": null,
      "codiceCatastale": "I292",
      "cap": "37020",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comune.santannadalfaedo.verona.it",
      "pec": "comune.santannadalfaedo.vr@pecveneto.it",
      "telefono": "+39 045/7532502",
      "fax": "+39 045/7532600",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "023080",
      "nome": "Selva di Progno",
      "nomeStraniero": null,
      "codiceCatastale": "I594",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comuneselvadiprogno.it",
      "pec": "segreteria.selva@stpec.it",
      "telefono": "+39 045/7847010",
      "fax": "+39 045/7847133",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "023081",
      "nome": "Soave",
      "nomeStraniero": null,
      "codiceCatastale": "I775",
      "cap": "37038",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "segreteria@comunesoave.it",
      "pec": "segreteria@pec.comunesoave.it",
      "telefono": "+39 045/7680777",
      "fax": "+39 045/6190200",
      "coordinate": {
        "lat": 45.419551849365234,
        "lng": 11.245940208435059
      }
    },
    {
      "codice": "023082",
      "nome": "Sommacampagna",
      "nomeStraniero": null,
      "codiceCatastale": "I821",
      "cap": "37066",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "affari.generali@comune.sommacampagna.vr.it",
      "pec": "sommacampagna.vr@cert.ip-veneto.net",
      "telefono": "+39 045/8971399",
      "fax": "+39 045/8971300",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "023083",
      "nome": "Sona",
      "nomeStraniero": null,
      "codiceCatastale": "I826",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.sona.vr.it",
      "pec": "sona.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6091211",
      "fax": "+39 045/6091260",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 10.833333015441895
      }
    },
    {
      "codice": "023084",
      "nome": "Sorgà",
      "nomeStraniero": null,
      "codiceCatastale": "I850",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "affari.generali@comune.sorga.vr.it",
      "pec": "sorga.vr@cert.ip-veneto.net",
      "telefono": "+39 045/3370000",
      "fax": "+39 045/7370165",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 10.983333587646484
      }
    },
    {
      "codice": "023085",
      "nome": "Terrazzo",
      "nomeStraniero": null,
      "codiceCatastale": "L136",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.terrazzo.vr.it",
      "pec": "comune.terrazzo@legalmail.it",
      "telefono": "+39 0442/94013",
      "fax": "+39 0442/95640",
      "coordinate": {
        "lat": 45.173831939697266,
        "lng": 11.398611068725586
      }
    },
    {
      "codice": "023086",
      "nome": "Torri del Benaco",
      "nomeStraniero": null,
      "codiceCatastale": "L287",
      "cap": "37010",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "armando.lavanda@comune.torridelbenaco.vr.it",
      "pec": "statocivile@pec.comune.torridelbenaco.vr.it",
      "telefono": "+39 045/6205808",
      "fax": "+39 045/6205832",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 10.683333396911621
      }
    },
    {
      "codice": "023087",
      "nome": "Tregnago",
      "nomeStraniero": null,
      "codiceCatastale": "L364",
      "cap": "37039",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocollo@comune.tregnago.vr.it",
      "pec": "comune.tregnago.vr@pecveneto.it",
      "telefono": "+39 045/7808452",
      "fax": null,
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.166666984558105
      }
    },
    {
      "codice": "023088",
      "nome": "Trevenzuolo",
      "nomeStraniero": null,
      "codiceCatastale": "L396",
      "cap": "37060",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "anagrafe@comune.trevenzuolo.vr.it",
      "pec": "protocollo@pec.comune.trevenzuolo.vr.it",
      "telefono": "+39 045/7350088",
      "fax": "+39 045/7350348",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 10.916666984558105
      }
    },
    {
      "codice": "023089",
      "nome": "Valeggio sul Mincio",
      "nomeStraniero": null,
      "codiceCatastale": "L567",
      "cap": "37067",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "info@comune.valeggiosulmincio.vr.it",
      "pec": "protocollo.comune.valeggiosulmincio.vr@pecveneto.it",
      "telefono": "+39 045/6339800",
      "fax": "+39 045/6370290",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "023090",
      "nome": "Velo Veronese",
      "nomeStraniero": null,
      "codiceCatastale": "L722",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comune.velo@libero.it",
      "pec": "comune.velo@pec.it",
      "telefono": "+39 045/6518016",
      "fax": "+39 045/6518009",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "023091",
      "nome": "Verona",
      "nomeStraniero": null,
      "codiceCatastale": "L781",
      "cap": "37100",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "protocolloinformatico@comune.verona.it",
      "pec": "protocollo.informatico@pec.comune.verona.it",
      "telefono": "+39 045/8077111",
      "fax": "+39 045/8066483",
      "coordinate": {
        "lat": 45.4386100769043,
        "lng": 10.992777824401855
      }
    },
    {
      "codice": "023092",
      "nome": "Veronella",
      "nomeStraniero": null,
      "codiceCatastale": "D193",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "protocollo@pec.comune.veronella.vr.it",
      "pec": "protocollo@pec.comune.veronella.vr.it",
      "telefono": "+39 0442/450611",
      "fax": "+39 0442450631",
      "coordinate": {
        "lat": 45.323055267333984,
        "lng": 11.324444770812988
      }
    },
    {
      "codice": "023093",
      "nome": "Vestenanova",
      "nomeStraniero": null,
      "codiceCatastale": "L810",
      "cap": "37030",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "elettorale@comune.vestenanova.vr.it",
      "pec": "segreteria.vestenanova@stpec.it",
      "telefono": "+39 045/6564017",
      "fax": "+39 045/7470566",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "023094",
      "nome": "Vigasio",
      "nomeStraniero": null,
      "codiceCatastale": "L869",
      "cap": "37068",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "ced@comune.vigasio.vr.it",
      "pec": "vigasio.segreteria@halleycert.it",
      "telefono": "+39 045/7363700",
      "fax": "+39 045/7364473",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 10.933333396911621
      }
    },
    {
      "codice": "023095",
      "nome": "Villa Bartolomea",
      "nomeStraniero": null,
      "codiceCatastale": "L912",
      "cap": "37049",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@comune.villa-bartolomea.vr.it",
      "pec": "segreteria.villa-bartolomea@stpec.it",
      "telefono": "+39 0442/659911",
      "fax": "+39 0442/659033",
      "coordinate": {
        "lat": 45.15834045410156,
        "lng": 11.353110313415527
      }
    },
    {
      "codice": "023096",
      "nome": "Villafranca di Verona",
      "nomeStraniero": null,
      "codiceCatastale": "L949",
      "cap": "37069",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "giuliano.cordioli@comune.villafranca.vr.it",
      "pec": "protocollo.comune.villafranca.vr@pecveneto.it",
      "telefono": "+39 045/6339166",
      "fax": "+39 045/6339211",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "023097",
      "nome": "Zevio",
      "nomeStraniero": null,
      "codiceCatastale": "M172",
      "cap": "37059",
      "prefisso": "045",
      "provincia": "Verona",
      "email": "comunedizevio@comune.zevio.vr.it",
      "pec": "zevio.vr@cert.ip-veneto.net",
      "telefono": "+39 045/6068407",
      "fax": "+39 045/6068404",
      "coordinate": {
        "lat": 45.37277603149414,
        "lng": 11.130277633666992
      }
    },
    {
      "codice": "023098",
      "nome": "Zimella",
      "nomeStraniero": null,
      "codiceCatastale": "M178",
      "cap": "37040",
      "prefisso": "0442",
      "provincia": "Verona",
      "email": "info@zimella.com",
      "pec": "zimella.vr@cert.ip-veneto.net",
      "telefono": "+39 0442/490011",
      "fax": "+39 0442/490144",
      "coordinate": {
        "lat": 45.3436279296875,
        "lng": 11.352304458618164
      }
    }
  ],
  "Roma": [
    {
      "codice": "058001",
      "nome": "Affile",
      "nomeStraniero": null,
      "codiceCatastale": "A062",
      "cap": "00021",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "protocollo@affile.org",
      "pec": "anagrafe-protocollo@pec.comune.affile.rm.it",
      "telefono": "+39 0774 804400",
      "fax": "+39 0774 808983",
      "coordinate": {
        "lat": 41.8849983215332,
        "lng": 13.096111297607422
      }
    },
    {
      "codice": "058002",
      "nome": "Agosta",
      "nomeStraniero": null,
      "codiceCatastale": "A084",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "protocollo@comunediagosta.it",
      "pec": "protocolloagosta@pec.it",
      "telefono": "+39 0774/800024",
      "fax": "+39 0774/800700",
      "coordinate": {
        "lat": 41.98249816894531,
        "lng": 13.033611297607422
      }
    },
    {
      "codice": "058003",
      "nome": "Albano Laziale",
      "nomeStraniero": null,
      "codiceCatastale": "A132",
      "cap": "00041",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "protocollo@comune.albanolaziale.rm.it",
      "pec": "protocollo@cert.comune.albanolaziale.rm.it",
      "telefono": "+39 06/93295215",
      "fax": "+39 06/9321124",
      "coordinate": {
        "lat": 41.733333587646484,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "058004",
      "nome": "Allumiere",
      "nomeStraniero": null,
      "codiceCatastale": "A210",
      "cap": "00051",
      "prefisso": "0766",
      "provincia": "Roma",
      "email": "info@comune.allumiere.rm.it",
      "pec": "segreteriaallumiere@pec.provincia.roma.it",
      "telefono": "+39 0766 96010",
      "fax": "+39 0766 96106",
      "coordinate": {
        "lat": 42.156944274902344,
        "lng": 11.904722213745117
      }
    },
    {
      "codice": "058005",
      "nome": "Anguillara Sabazia",
      "nomeStraniero": null,
      "codiceCatastale": "A297",
      "cap": "00061",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "g.flenghi@comune.anguillara-sabazia.roma.it",
      "pec": "comune.anguillara@pec.it",
      "telefono": "+39 06/99600051",
      "fax": "+39 06/9968343",
      "coordinate": {
        "lat": 42.08833312988281,
        "lng": 12.27750015258789
      }
    },
    {
      "codice": "058006",
      "nome": "Anticoli Corrado",
      "nomeStraniero": null,
      "codiceCatastale": "A309",
      "cap": "00022",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comune_anticoli@tiscali.it",
      "pec": "comune.anticolicorrado@pec.it",
      "telefono": "+39 0774 936318",
      "fax": "+39 0774 936379",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 12.983333587646484
      }
    },
    {
      "codice": "058007",
      "nome": "Anzio",
      "nomeStraniero": null,
      "codiceCatastale": "A323",
      "cap": "00042",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "nicola.corrado@comune.anzio.roma.it",
      "pec": "protocollo.comuneanzio@pec.it",
      "telefono": "+39 06/98499236",
      "fax": "+39 06/98499424",
      "coordinate": {
        "lat": 41.44722366333008,
        "lng": 12.62833309173584
      }
    },
    {
      "codice": "058008",
      "nome": "Arcinazzo Romano",
      "nomeStraniero": null,
      "codiceCatastale": "A370",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "anagrafe@comunearcinazzoromano.it",
      "pec": "anagrafe@pec.comunearcinazzoromano.it",
      "telefono": "+39 0774/808006",
      "fax": "+39 0774/808263",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 13.116666793823242
      }
    },
    {
      "codice": "058117",
      "nome": "Ardea",
      "nomeStraniero": null,
      "codiceCatastale": "M213",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "crocomuneardea@virgilio.it",
      "pec": "comunediardea.affarigenerali.rm@pa.postacertificata.gov.it",
      "telefono": "+39 06/913800305",
      "fax": "+39 06/9135082",
      "coordinate": {
        "lat": 41.61666488647461,
        "lng": 12.550000190734863
      }
    },
    {
      "codice": "058009",
      "nome": "Ariccia",
      "nomeStraniero": null,
      "codiceCatastale": "A401",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "vquarta@comunediariccia.it",
      "pec": "protocollo@pec.comunediariccia.it",
      "telefono": "+39 06/93485409",
      "fax": "+39 06/93485350",
      "coordinate": {
        "lat": 41.71666717529297,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "058010",
      "nome": "Arsoli",
      "nomeStraniero": null,
      "codiceCatastale": "A446",
      "cap": "00023",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "anagrafe@comunediarsoli.rm.it",
      "pec": "ana.comunearsoli@cittametropolitanaroma.it",
      "telefono": "+39 0774/920006-5",
      "fax": "+39 0774/920313",
      "coordinate": {
        "lat": 42.04045867919922,
        "lng": 13.017809867858887
      }
    },
    {
      "codice": "058011",
      "nome": "Artena",
      "nomeStraniero": null,
      "codiceCatastale": "A449",
      "cap": "00031",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "demografico@comune.artena.rm.it",
      "pec": "comune.artena@pec.it",
      "telefono": "+39 0695191020",
      "fax": "+39 06/95191085",
      "coordinate": {
        "lat": 41.733333587646484,
        "lng": 12.916666984558105
      }
    },
    {
      "codice": "058012",
      "nome": "Bellegra",
      "nomeStraniero": null,
      "codiceCatastale": "A749",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "info@comune.bellegra.rm.gov.it",
      "pec": "segreteriabellegra@pec.provincia.roma.it",
      "telefono": "+39 06 9561801",
      "fax": "+39 06 95617163",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "058013",
      "nome": "Bracciano",
      "nomeStraniero": null,
      "codiceCatastale": "B114",
      "cap": "00062",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "carlo.scarpari@comune.bracciano.rm.it",
      "pec": "bracciano.protocollo@pec.it",
      "telefono": "+39 06/99816272",
      "fax": "+39 06/99816236",
      "coordinate": {
        "lat": 42.099998474121094,
        "lng": 12.183333396911621
      }
    },
    {
      "codice": "058014",
      "nome": "Camerata Nuova",
      "nomeStraniero": null,
      "codiceCatastale": "B472",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comunecameratanuova.anagrafe@virgilio.it",
      "pec": "info@pec.comune.cameratanuova.it",
      "telefono": "+39 0774/935024",
      "fax": "+39 0774/924240",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "058015",
      "nome": "Campagnano di Roma",
      "nomeStraniero": null,
      "codiceCatastale": "B496",
      "cap": "00063",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "protocollo@comunecampagnano.it",
      "pec": "protocollo@pec.comunecampagnano.it",
      "telefono": "+39 06/9015601",
      "fax": "+39 06/9041991",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 12.383333206176758
      }
    },
    {
      "codice": "058016",
      "nome": "Canale Monterano",
      "nomeStraniero": null,
      "codiceCatastale": "B576",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "info@comune.canalemonterano.rm.it",
      "pec": "info@pec.comune.canalemonterano.rm.it",
      "telefono": "+39 06/9962401",
      "fax": "+39 06/99679280",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "058017",
      "nome": "Canterano",
      "nomeStraniero": null,
      "codiceCatastale": "B635",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "canterano.comune@tiscali.it",
      "pec": "canterano.comune@pec.it",
      "telefono": "+39 0774/803023",
      "fax": "+39 0774/803237",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "058018",
      "nome": "Capena",
      "nomeStraniero": null,
      "codiceCatastale": "B649",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "segreteria@comune.capena.rm.it",
      "pec": "protocollo.comunedicapena@legalmail.it",
      "telefono": "+39 06 9037601",
      "fax": "+39 06 9074301",
      "coordinate": {
        "lat": 42.14027786254883,
        "lng": 12.540277481079102
      }
    },
    {
      "codice": "058019",
      "nome": "Capranica Prenestina",
      "nomeStraniero": null,
      "codiceCatastale": "B687",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ufficiotecnico@comunecapranicaprenestina.it",
      "pec": "comunecapranicaprenestina@pec.it",
      "telefono": "+39 06/9584031",
      "fax": "+39 06/9584337",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 12.949999809265137
      }
    },
    {
      "codice": "058020",
      "nome": "Carpineto Romano",
      "nomeStraniero": null,
      "codiceCatastale": "B828",
      "cap": "00032",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "info@carpinetoromano.it",
      "pec": "comunedicarpinetoromano.sindaco.rm@pa.postacertificata.gov.it",
      "telefono": "+39 06/97180046",
      "fax": "+39 06/97180035",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "058021",
      "nome": "Casape",
      "nomeStraniero": null,
      "codiceCatastale": "B932",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "info@comune.casape.rm.it",
      "pec": "protocollo@pec.comune.casape.rm.it",
      "telefono": "+39 0774.489000",
      "fax": "+39 0774.489004",
      "coordinate": {
        "lat": 41.906944274902344,
        "lng": 12.885555267333984
      }
    },
    {
      "codice": "058022",
      "nome": "Castel Gandolfo",
      "nomeStraniero": null,
      "codiceCatastale": "C116",
      "cap": "00073",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "giovanni.meconi@comune.castelgandolfo.rm.it",
      "pec": "protocollosede.comunecastelgandolfo@sicurezzapostale.it",
      "telefono": "+39 06/9359181",
      "fax": "+39 06/935918211",
      "coordinate": {
        "lat": 41.746944427490234,
        "lng": 12.651944160461426
      }
    },
    {
      "codice": "058023",
      "nome": "Castel Madama",
      "nomeStraniero": null,
      "codiceCatastale": "C203",
      "cap": "00024",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "massimiliano.scardala@comunedicastelmadama.it",
      "pec": "protocollocmadama.rm@actaliscertymail.it",
      "telefono": "+39 0774/45001",
      "fax": "+39 0774/449400",
      "coordinate": {
        "lat": 41.97472381591797,
        "lng": 12.86722183227539
      }
    },
    {
      "codice": "058025",
      "nome": "Castel San Pietro Romano",
      "nomeStraniero": null,
      "codiceCatastale": "C266",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "f.gambini@castelsanpietroromano.connet.roma.it",
      "pec": "segreteriacastelsanpietro@pec.provincia.roma.it",
      "telefono": "+39 06/9538481",
      "fax": "+39 06/9538481",
      "coordinate": {
        "lat": 41.84583282470703,
        "lng": 12.895000457763672
      }
    },
    {
      "codice": "058024",
      "nome": "Castelnuovo di Porto",
      "nomeStraniero": null,
      "codiceCatastale": "C237",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "anagrafe@comune.castelnuovodiporto.rm.it",
      "pec": "anagrafe-elettorale@pec.comune.castelnuovodiporto.rm.it",
      "telefono": "+39 06/901740262",
      "fax": null,
      "coordinate": {
        "lat": 42.127777099609375,
        "lng": 12.5
      }
    },
    {
      "codice": "058026",
      "nome": "Cave",
      "nomeStraniero": null,
      "codiceCatastale": "C390",
      "cap": "00033",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "segreteria@comune.cave.rm.it",
      "pec": "protocollocave@pec.actalis.it",
      "telefono": "+39 06 9500081",
      "fax": "+39 06 9581363",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "058027",
      "nome": "Cerreto Laziale",
      "nomeStraniero": null,
      "codiceCatastale": "C518",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "info@comunecerretolaziale.rm.it",
      "pec": "comunecerretolaziale@pec.it",
      "telefono": "+39 0774/798032",
      "fax": "+39 0774/798562",
      "coordinate": {
        "lat": 41.94499969482422,
        "lng": 12.98277759552002
      }
    },
    {
      "codice": "058028",
      "nome": "Cervara di Roma",
      "nomeStraniero": null,
      "codiceCatastale": "C543",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "cervaradiroma.comune@gmail.com",
      "pec": "cervaradiroma.comune@legalmail.it",
      "telefono": "+39 0774 828715",
      "fax": "+39 0774 828762",
      "coordinate": {
        "lat": 41.98805618286133,
        "lng": 13.067222595214844
      }
    },
    {
      "codice": "058029",
      "nome": "Cerveteri",
      "nomeStraniero": null,
      "codiceCatastale": "C552",
      "cap": "00052",
      "prefisso": "0699",
      "provincia": "Roma",
      "email": "claudia.clementi@comune.cerveteri.rm.it",
      "pec": "comunecerveteri@pec.it",
      "telefono": "+39 06/89630225",
      "fax": "+39 06/9943008",
      "coordinate": {
        "lat": 42,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "058118",
      "nome": "Ciampino",
      "nomeStraniero": null,
      "codiceCatastale": "M272",
      "cap": "00043",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "urp@comune.ciampino.roma.it",
      "pec": "comunediciampino@pec.comune.ciampino.roma.it",
      "telefono": "+39 06/790971",
      "fax": "+39 06/7922356",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 12.600000381469727
      }
    },
    {
      "codice": "058030",
      "nome": "Ciciliano",
      "nomeStraniero": null,
      "codiceCatastale": "C677",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "alessandro.elmisi@comune.ciciliano.rm.it",
      "pec": "protocollo@pec.comune.ciciliano.rm.it",
      "telefono": "+39 0774/790006",
      "fax": "+39 0774/790793",
      "coordinate": {
        "lat": 41.961666107177734,
        "lng": 12.941389083862305
      }
    },
    {
      "codice": "058031",
      "nome": "Cineto Romano",
      "nomeStraniero": null,
      "codiceCatastale": "C702",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "protocollo@cineto.it",
      "pec": "comune.cinetoromano@pec.it",
      "telefono": "+39 0774/928016",
      "fax": "+39 0774/928015",
      "coordinate": {
        "lat": 42.05027770996094,
        "lng": 12.96500015258789
      }
    },
    {
      "codice": "058032",
      "nome": "Civitavecchia",
      "nomeStraniero": null,
      "codiceCatastale": "C773",
      "cap": "00053",
      "prefisso": "0766",
      "provincia": "Roma",
      "email": "ced@comune.civitavecchia.rm.it",
      "pec": "comune.civitavecchia@legalmail.it",
      "telefono": "+39 329/4646597",
      "fax": "+39 0766/20286",
      "coordinate": {
        "lat": 42.099998474121094,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "058033",
      "nome": "Civitella San Paolo",
      "nomeStraniero": null,
      "codiceCatastale": "C784",
      "cap": "00060",
      "prefisso": "0765",
      "provincia": "Roma",
      "email": "civitellasanpaolo@libero.it",
      "pec": "comune.civitellasanpaolo@pcert.it",
      "telefono": "+39 0765335121",
      "fax": "+39 0765330140",
      "coordinate": {
        "lat": 42.19583511352539,
        "lng": 12.582221984863281
      }
    },
    {
      "codice": "058034",
      "nome": "Colleferro",
      "nomeStraniero": null,
      "codiceCatastale": "C858",
      "cap": "00034",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "rinaldi@comune.colleferro.rm.it",
      "pec": "comune.colleferro@legalmail.it",
      "telefono": "+39 06/97207300",
      "fax": "+39 06/97207333",
      "coordinate": {
        "lat": 41.72867965698242,
        "lng": 13.003140449523926
      }
    },
    {
      "codice": "058035",
      "nome": "Colonna",
      "nomeStraniero": null,
      "codiceCatastale": "C900",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ragioneria@comune.colonna.roma.it",
      "pec": "info@pec.comunedicolonna.it",
      "telefono": "+39 06/94731027",
      "fax": "+39 06/9438662",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 12.75
      }
    },
    {
      "codice": "058036",
      "nome": "Fiano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "D561",
      "cap": "00065",
      "prefisso": "0765",
      "provincia": "Roma",
      "email": "s.amministrativo@comune.fianoromano.rm.it",
      "pec": "protocollo@pec.comune.fianoromano.rm.it",
      "telefono": "+39 0765407233",
      "fax": "+39 0765480385",
      "coordinate": {
        "lat": 42.16666793823242,
        "lng": 12.600000381469727
      }
    },
    {
      "codice": "058037",
      "nome": "Filacciano",
      "nomeStraniero": null,
      "codiceCatastale": "D586",
      "cap": "00060",
      "prefisso": "0765",
      "provincia": "Roma",
      "email": "comune.filacciano@micso.net",
      "pec": "comunedifilacciano@pcert.it",
      "telefono": "+39 0765/332113",
      "fax": "+39 0765/332715",
      "coordinate": {
        "lat": 42.25583267211914,
        "lng": 12.60083293914795
      }
    },
    {
      "codice": "058120",
      "nome": "Fiumicino",
      "nomeStraniero": null,
      "codiceCatastale": "M297",
      "cap": "00054",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "dirigente.personale@fiumicino.net",
      "pec": "protocollo.generale@comune.fiumicino.rm.gov.it",
      "telefono": "+39 06/65210245",
      "fax": "+39 06/65210318",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 12.233333587646484
      }
    },
    {
      "codice": "058122",
      "nome": "Fonte Nuova",
      "nomeStraniero": null,
      "codiceCatastale": "M309",
      "cap": "00013",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "urp@comune.fonte-nuova.rm.it",
      "pec": "protocollo@cert.fonte-nuova.it",
      "telefono": "+39 06 905522300",
      "fax": "+39 06 9056023",
      "coordinate": {
        "lat": 42.00166702270508,
        "lng": 12.621944427490234
      }
    },
    {
      "codice": "058038",
      "nome": "Formello",
      "nomeStraniero": null,
      "codiceCatastale": "D707",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "segretariogenerale@comunediformello.it",
      "pec": "comuneformello@poste.it",
      "telefono": "+39 06/90194249",
      "fax": "+39 06/9089577",
      "coordinate": {
        "lat": 42.08333206176758,
        "lng": 12.399999618530273
      }
    },
    {
      "codice": "058039",
      "nome": "Frascati",
      "nomeStraniero": null,
      "codiceCatastale": "D773",
      "cap": "00044",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ced@comune.frascati.rm.it",
      "pec": "protocollofrascati@legalmail.it",
      "telefono": "+39 06/94184285",
      "fax": "+39 06/9422532",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "058040",
      "nome": "Gallicano nel Lazio",
      "nomeStraniero": null,
      "codiceCatastale": "D875",
      "cap": "00010",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "sindaco@comune.gallicanonellazio.roma.it",
      "pec": "gallicanonellazio@pec.it",
      "telefono": "+39 06.9546.0093",
      "fax": "+39 06.9546.0043",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 12.816666603088379
      }
    },
    {
      "codice": "058041",
      "nome": "Gavignano",
      "nomeStraniero": null,
      "codiceCatastale": "D945",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "comune@gavignano.rm.gov.it",
      "pec": "comunegavignano@interfreepec.it",
      "telefono": "+39 069703033",
      "fax": "+39 069703364",
      "coordinate": {
        "lat": 41.70000076293945,
        "lng": 13.050000190734863
      }
    },
    {
      "codice": "058042",
      "nome": "Genazzano",
      "nomeStraniero": null,
      "codiceCatastale": "D964",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "sindaco@genazzano.org",
      "pec": "comune.genazzano@pec.genazzano.org",
      "telefono": "+39 06 955791",
      "fax": "+39 06 9579027",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "058043",
      "nome": "Genzano di Roma",
      "nomeStraniero": null,
      "codiceCatastale": "D972",
      "cap": "00045",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "annesi.s@comunedigenzanodiroma.roma.it",
      "pec": "protocollo@comunegenzanodiromapec.it",
      "telefono": "+39 06/93711380",
      "fax": "+39 06/9364816",
      "coordinate": {
        "lat": 41.70222091674805,
        "lng": 12.692500114440918
      }
    },
    {
      "codice": "058044",
      "nome": "Gerano",
      "nomeStraniero": null,
      "codiceCatastale": "D978",
      "cap": "00025",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comune@comune.gerano.rm.it",
      "pec": "segreteriagerano@pec.cittametropolitanaroma.it",
      "telefono": "+39 0774/798002",
      "fax": "+39 0774/798875",
      "coordinate": {
        "lat": 41.93333435058594,
        "lng": 12.993611335754395
      }
    },
    {
      "codice": "058045",
      "nome": "Gorga",
      "nomeStraniero": null,
      "codiceCatastale": "E091",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "comune@comune.gorga.rm.it",
      "pec": "comunegorga@pec.it",
      "telefono": "+39 06 9775101",
      "fax": null,
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 13.116666793823242
      }
    },
    {
      "codice": "058046",
      "nome": "Grottaferrata",
      "nomeStraniero": null,
      "codiceCatastale": "E204",
      "cap": "00046",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "resp.ced@comune.grottaferrata.roma.it",
      "pec": "seg.generale@pec.comune.grottaferrata.roma.it",
      "telefono": "+39 06/945401650",
      "fax": "+39 06/94315355",
      "coordinate": {
        "lat": 41.79999923706055,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "058047",
      "nome": "Guidonia Montecelio",
      "nomeStraniero": null,
      "codiceCatastale": "E263",
      "cap": "00012",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "tullio@guidonia.org",
      "pec": "comuneguidoniamontecelio.rm.protocollo@pa.postacertificata.gov.it",
      "telefono": "+39 0774/301304",
      "fax": "+39 0774/300692",
      "coordinate": {
        "lat": 41.99681854248047,
        "lng": 12.726140022277832
      }
    },
    {
      "codice": "058048",
      "nome": "Jenne",
      "nomeStraniero": null,
      "codiceCatastale": "E382",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comunejenne@libero.it",
      "pec": "comunejenne@pec.it",
      "telefono": "+39 0774 827601",
      "fax": "+39 0774 827602",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "058049",
      "nome": "Labico",
      "nomeStraniero": null,
      "codiceCatastale": "E392",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "comune@labico.com",
      "pec": "demografici@comune.labico.legalmail.it",
      "telefono": "+39 06/9510260",
      "fax": "+39 06/9510260",
      "coordinate": {
        "lat": 41.78333282470703,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "058116",
      "nome": "Ladispoli",
      "nomeStraniero": null,
      "codiceCatastale": "M212",
      "cap": "00055",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "santo.fabiano@comuneladispoli.it",
      "pec": "comunediladispoli@certificazioneposta.it",
      "telefono": "+39 06/992311",
      "fax": "+39 06/99231280",
      "coordinate": {
        "lat": 41.954444885253906,
        "lng": 12.074166297912598
      }
    },
    {
      "codice": "058050",
      "nome": "Lanuvio",
      "nomeStraniero": null,
      "codiceCatastale": "C767",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "a.galieti@lanuvio.comnet.roma.it",
      "pec": "segreterialanuvio@pec.provincia.roma.it",
      "telefono": "+39 06/93789201",
      "fax": "+39 06/93789229",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 12.699999809265137
      }
    },
    {
      "codice": "058115",
      "nome": "Lariano",
      "nomeStraniero": null,
      "codiceCatastale": "M207",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "info@comune.lariano.rm.it",
      "pec": "protocollo@pec.comune.lariano.rm.it",
      "telefono": "+39 06 964991",
      "fax": "+39 06 9647977",
      "coordinate": {
        "lat": 41.733333587646484,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "058051",
      "nome": "Licenza",
      "nomeStraniero": null,
      "codiceCatastale": "E576",
      "cap": "00026",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "anagrafe.licenza@email.it",
      "pec": "servizidemografici.licenza@legalmail.it",
      "telefono": "+39 0774/46031",
      "fax": null,
      "coordinate": {
        "lat": 42.07424545288086,
        "lng": 12.901569366455078
      }
    },
    {
      "codice": "058052",
      "nome": "Magliano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "E813",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "settore1@comunedimaglianoromano.it",
      "pec": "settore1@pec.comunedimaglianoromano.it",
      "telefono": "+39 06/9048005",
      "fax": null,
      "coordinate": {
        "lat": 42.15999984741211,
        "lng": 12.435832977294922
      }
    },
    {
      "codice": "058053",
      "nome": "Mandela",
      "nomeStraniero": null,
      "codiceCatastale": "B632",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "cmandela@tiscali.it",
      "pec": "demograficimandela@legalmail.it",
      "telefono": "+39 0774/492003",
      "fax": "+39 0774/492020",
      "coordinate": {
        "lat": 42.029998779296875,
        "lng": 12.923055648803711
      }
    },
    {
      "codice": "058054",
      "nome": "Manziana",
      "nomeStraniero": null,
      "codiceCatastale": "E900",
      "cap": "00066",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "cgiorgi@comune.manziana.rm.it",
      "pec": "info.comunemanziana@pec.it",
      "telefono": "+39 06/9963672",
      "fax": "+39 06/99674021",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 12.133333206176758
      }
    },
    {
      "codice": "058055",
      "nome": "Marano Equo",
      "nomeStraniero": null,
      "codiceCatastale": "E908",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "demografici@comunemaranoequo.it",
      "pec": "comune.maranoequo@pec.comunemaranoequo.it",
      "telefono": "+39 0774/820213",
      "fax": "+39 0774/820056",
      "coordinate": {
        "lat": 41.99388885498047,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "058056",
      "nome": "Marcellina",
      "nomeStraniero": null,
      "codiceCatastale": "E924",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "demografici@comune.marcellina.rm.it",
      "pec": "amministrazione@pec.comune.marcellina.rm.it",
      "telefono": "+39 0774/427031",
      "fax": "+39 0774/424207",
      "coordinate": {
        "lat": 42.02361297607422,
        "lng": 12.806944847106934
      }
    },
    {
      "codice": "058057",
      "nome": "Marino",
      "nomeStraniero": null,
      "codiceCatastale": "E958",
      "cap": "00047",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "elettorale@comune.marino.rm.it",
      "pec": "protocollo@pec.comune.marino.rm.it",
      "telefono": "+39 06/93662243",
      "fax": "+39 06/93662361",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "058058",
      "nome": "Mazzano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "F064",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "protocollo@comune.mazzanoromano.rm.it",
      "pec": "protocollo.mazzanoromano@pec.it",
      "telefono": "+39 06/9049490",
      "fax": null,
      "coordinate": {
        "lat": 42.2055549621582,
        "lng": 12.397500038146973
      }
    },
    {
      "codice": "058059",
      "nome": "Mentana",
      "nomeStraniero": null,
      "codiceCatastale": "F127",
      "cap": "00013",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "t.alivernini@comune.mentana.rm.it",
      "pec": "comune.mentana@anutel.it",
      "telefono": "+39 06/90969246",
      "fax": "+39 06/90969290",
      "coordinate": {
        "lat": 42.033729553222656,
        "lng": 12.644448280334473
      }
    },
    {
      "codice": "058060",
      "nome": "Monte Compatri",
      "nomeStraniero": null,
      "codiceCatastale": "F477",
      "cap": "00077",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "sindaco@comune.montecompatri.rm.gov.it",
      "pec": "protocollo@pec.comune.montecompatri.roma.it",
      "telefono": "+39 06/94780311",
      "fax": "+39 06/9487136",
      "coordinate": {
        "lat": 41.80805587768555,
        "lng": 12.737222671508789
      }
    },
    {
      "codice": "058064",
      "nome": "Monte Porzio Catone",
      "nomeStraniero": null,
      "codiceCatastale": "F590",
      "cap": "00078",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "informatica@comune.monteporziocatone.rm.it",
      "pec": "comune.monteporziocatone@legalmail.it",
      "telefono": "+39 06942831",
      "fax": "+39 069447471",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "058061",
      "nome": "Monteflavio",
      "nomeStraniero": null,
      "codiceCatastale": "F504",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "vgranati@comune.monteflavio.rm.it",
      "pec": "protocollo.comune.monteflavio@pec.it",
      "telefono": "+39 0774/69576",
      "fax": "+39 0774/602707",
      "coordinate": {
        "lat": 42.108333587646484,
        "lng": 12.830833435058594
      }
    },
    {
      "codice": "058062",
      "nome": "Montelanico",
      "nomeStraniero": null,
      "codiceCatastale": "F534",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ufficio.demografico@comune.montelanico.roma.it",
      "pec": "ufficio.demografico@pec.comune.montelanico.roma.it",
      "telefono": "+39 06 97050003",
      "fax": "+39 06 97050004",
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 13.039722442626953
      }
    },
    {
      "codice": "058063",
      "nome": "Montelibretti",
      "nomeStraniero": null,
      "codiceCatastale": "F545",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "c.renzi@comune.montelibretti.roma.it",
      "pec": "segreteria@pec.montelibretti.info",
      "telefono": "+39 0774/6068206",
      "fax": "+39 0774/6068223",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "058065",
      "nome": "Monterotondo",
      "nomeStraniero": null,
      "codiceCatastale": "F611",
      "cap": "00015",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "pubblico.informatica@comune.monterotondo.rm.it",
      "pec": "comune.monterotondo.rm@pec.it",
      "telefono": "+39 06/90964253",
      "fax": "+39 06/90964426",
      "coordinate": {
        "lat": 42.04999923706055,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "058066",
      "nome": "Montorio Romano",
      "nomeStraniero": null,
      "codiceCatastale": "F692",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "segreteria@comunemontorioromano.it",
      "pec": "amministrazione@pec.comunemontorioromano.it",
      "telefono": "+39 0774 62223",
      "fax": "+39 0774 62286",
      "coordinate": {
        "lat": 42.137779235839844,
        "lng": 12.807777404785156
      }
    },
    {
      "codice": "058067",
      "nome": "Moricone",
      "nomeStraniero": null,
      "codiceCatastale": "F730",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comune@moricone.net",
      "pec": "protocollomoricone@pec.it",
      "telefono": "+39 0774/605168",
      "fax": null,
      "coordinate": {
        "lat": 42.11661148071289,
        "lng": 12.772339820861816
      }
    },
    {
      "codice": "058068",
      "nome": "Morlupo",
      "nomeStraniero": null,
      "codiceCatastale": "F734",
      "cap": "00067",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "personale@comune.morlupo.roma.it",
      "pec": "info@pec.morlupo.eu",
      "telefono": "+39 06/90195337",
      "fax": "+39 06/90195353",
      "coordinate": {
        "lat": 42.14345932006836,
        "lng": 12.504672050476074
      }
    },
    {
      "codice": "058069",
      "nome": "Nazzano",
      "nomeStraniero": null,
      "codiceCatastale": "F857",
      "cap": "00060",
      "prefisso": "0765",
      "provincia": "Roma",
      "email": "protocollodinazzano@virgilio.it",
      "pec": "comune.nazzano@pcert.it",
      "telefono": "+39 0765/332002",
      "fax": "+39 0765/332710",
      "coordinate": {
        "lat": 42.23017883300781,
        "lng": 12.594400405883789
      }
    },
    {
      "codice": "058070",
      "nome": "Nemi",
      "nomeStraniero": null,
      "codiceCatastale": "F865",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "anagrafe@comunedinemi.rm.it",
      "pec": "servizi.demografici.nemi@pec.it",
      "telefono": "+39 06/936501219 - 06936501222",
      "fax": null,
      "coordinate": {
        "lat": 41.72185134887695,
        "lng": 12.718090057373047
      }
    },
    {
      "codice": "058071",
      "nome": "Nerola",
      "nomeStraniero": null,
      "codiceCatastale": "F871",
      "cap": "00017",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "protocollo@comunenerola.it",
      "pec": "segreterianerola@pec.provincia.roma.it",
      "telefono": "+39 0774/683003",
      "fax": "+39 0774/683127",
      "coordinate": {
        "lat": 42.160831451416016,
        "lng": 12.786944389343262
      }
    },
    {
      "codice": "058072",
      "nome": "Nettuno",
      "nomeStraniero": null,
      "codiceCatastale": "F880",
      "cap": "00048",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "supporto.ced@comune.nettuno.roma.it",
      "pec": "protocollogenerale@pec.comune.nettuno.roma.it",
      "telefono": "+39 06/98889262",
      "fax": "+39 06/98889251",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "058073",
      "nome": "Olevano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "G022",
      "cap": "00035",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "marco.callori@comune.olevanoromano.rm.it",
      "pec": "affarigenerali@pec.comune.olevanoromano.rm.it",
      "telefono": "+39 06/95600201",
      "fax": "+39 06/95600240",
      "coordinate": {
        "lat": 41.86055374145508,
        "lng": 13.031944274902344
      }
    },
    {
      "codice": "058074",
      "nome": "Palestrina",
      "nomeStraniero": null,
      "codiceCatastale": "G274",
      "cap": "00036",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "info@comune.palestrina.rm.it",
      "pec": "protocollo@comune.palestrina.legalmail.it",
      "telefono": "+39 06/953021",
      "fax": "+39 06/95302266",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 12.899999618530273
      }
    },
    {
      "codice": "058075",
      "nome": "Palombara Sabina",
      "nomeStraniero": null,
      "codiceCatastale": "G293",
      "cap": "00018",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "demografici@comune.palombarasabina.rm.it",
      "pec": "comune.palombarasabina.rm@halleycert.it",
      "telefono": "+39 0774/636441",
      "fax": "+39 0774/636467",
      "coordinate": {
        "lat": 42.06666564941406,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "058076",
      "nome": "Percile",
      "nomeStraniero": null,
      "codiceCatastale": "G444",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "segreteria@percilecomune.it",
      "pec": "comune@pec.percilecomune.it",
      "telefono": "+39 0774/469019",
      "fax": "+39 0774/46057",
      "coordinate": {
        "lat": 42.094722747802734,
        "lng": 12.908611297607422
      }
    },
    {
      "codice": "058077",
      "nome": "Pisoniano",
      "nomeStraniero": null,
      "codiceCatastale": "G704",
      "cap": "00020",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "sindaco@comunedipisoniano.it",
      "pec": "comunedipisoniano.rm.anagrafe@pa.postacertificata.gov.it",
      "telefono": "+39 06 9577001",
      "fax": "+39 06 9577430",
      "coordinate": {
        "lat": 41.90777587890625,
        "lng": 12.958333015441895
      }
    },
    {
      "codice": "058078",
      "nome": "Poli",
      "nomeStraniero": null,
      "codiceCatastale": "G784",
      "cap": "00010",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ragioneriacomunepoli@tiscalinet.it",
      "pec": "amministrativo@pec.comunepoli.it",
      "telefono": "+39 06/9551002",
      "fax": "+39 06/9551613",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "058079",
      "nome": "Pomezia",
      "nomeStraniero": null,
      "codiceCatastale": "G811",
      "cap": "00071",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "informatico@comune.pomezia.rm.it",
      "pec": "protocollo@pec.comune.pomezia.rm.it",
      "telefono": "+39 06/91146403",
      "fax": "+39 06/91146435",
      "coordinate": {
        "lat": 41.66930389404297,
        "lng": 12.502083778381348
      }
    },
    {
      "codice": "058080",
      "nome": "Ponzano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "G874",
      "cap": "00060",
      "prefisso": "0765",
      "provincia": "Roma",
      "email": "ponzanoromano@libero.it",
      "pec": "comune.ponzanoromano@pcert.it",
      "telefono": "+39 0765 338175",
      "fax": "+39 0765 338013",
      "coordinate": {
        "lat": 42.258056640625,
        "lng": 12.571666717529297
      }
    },
    {
      "codice": "058081",
      "nome": "Riano",
      "nomeStraniero": null,
      "codiceCatastale": "H267",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "segretaria@comune.riano.rm.it",
      "pec": "comunediriano.rm.segreteria@pa.postacertificata.gov.it",
      "telefono": "+39 06/901373302",
      "fax": "+39 06/9031500",
      "coordinate": {
        "lat": 42.09111022949219,
        "lng": 12.522777557373047
      }
    },
    {
      "codice": "058082",
      "nome": "Rignano Flaminio",
      "nomeStraniero": null,
      "codiceCatastale": "H288",
      "cap": "00068",
      "prefisso": "0761",
      "provincia": "Roma",
      "email": "sindaco@comunerignanoflaminio.it",
      "pec": "protocollo@pec.comune.rignanoflaminio.rm.it",
      "telefono": "+39 0761 59791",
      "fax": "+39 0761 597952",
      "coordinate": {
        "lat": 42.20000076293945,
        "lng": 12.483333587646484
      }
    },
    {
      "codice": "058083",
      "nome": "Riofreddo",
      "nomeStraniero": null,
      "codiceCatastale": "H300",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "riofreddocomune@libero.it",
      "pec": "sindaco.comunediriofreddo@pec.it",
      "telefono": "+39 0774/929116",
      "fax": "+39 0774/929501",
      "coordinate": {
        "lat": 42.05875015258789,
        "lng": 12.996609687805176
      }
    },
    {
      "codice": "058084",
      "nome": "Rocca Canterano",
      "nomeStraniero": null,
      "codiceCatastale": "H387",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "roccacanterano@libero.it",
      "pec": "segretarioroccacanterano@pec.cittametropolitanaroma.it",
      "telefono": "+39 0774 803400",
      "fax": "+39 0774 803391",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "058085",
      "nome": "Rocca di Cave",
      "nomeStraniero": null,
      "codiceCatastale": "H401",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "roccadicave@provincia.roma.it",
      "pec": "segreteriaroccadicave@pec.provincia.roma.it",
      "telefono": "+39 06/9584098",
      "fax": "+39 06/9584025",
      "coordinate": {
        "lat": 41.849998474121094,
        "lng": 12.949999809265137
      }
    },
    {
      "codice": "058086",
      "nome": "Rocca di Papa",
      "nomeStraniero": null,
      "codiceCatastale": "H404",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "tito.fei@comune.roccadipapa.rm.it",
      "pec": "demografici@pec-comuneroccadipapa.it",
      "telefono": "+39 06/94286152",
      "fax": "+39 06/94286150",
      "coordinate": {
        "lat": 41.766666412353516,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "058088",
      "nome": "Rocca Priora",
      "nomeStraniero": null,
      "codiceCatastale": "H432",
      "cap": "00040",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "annamaria.raponi@comune.roccapriora.roma.it",
      "pec": "comune.roccapriora@pec.it",
      "telefono": "0699180087",
      "fax": null,
      "coordinate": {
        "lat": 41.78333282470703,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "058089",
      "nome": "Rocca Santo Stefano",
      "nomeStraniero": null,
      "codiceCatastale": "H441",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "info@comuneroccasantostefano.it",
      "pec": "comune.roccasantostefano.rm@pec.it",
      "telefono": "+39 06/9567304",
      "fax": "+39 06/9567373",
      "coordinate": {
        "lat": 41.910518646240234,
        "lng": 13.02379035949707
      }
    },
    {
      "codice": "058087",
      "nome": "Roccagiovine",
      "nomeStraniero": null,
      "codiceCatastale": "H411",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "amministrativo@comunediroccagiovine.it",
      "pec": "protocollo@pec.comunediroccagiovine.it",
      "telefono": "+39 0774/498831",
      "fax": "+39 0774/498979",
      "coordinate": {
        "lat": 42.04999923706055,
        "lng": 12.899999618530273
      }
    },
    {
      "codice": "058090",
      "nome": "Roiate",
      "nomeStraniero": null,
      "codiceCatastale": "H494",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "comune.roiate@tin.it",
      "pec": "info@pec.comune.roiate.rm.it",
      "telefono": "+39 06 9569002",
      "fax": "+39 06 25496124",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "058091",
      "nome": "Roma",
      "nomeStraniero": null,
      "codiceCatastale": "H501",
      "cap": "00186",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ld.gabinetto@comune.roma.it",
      "pec": "protocollo.segretariato@pec.comune.roma.it",
      "telefono": "+39 06/67102001",
      "fax": "+39 06/67103590",
      "coordinate": {
        "lat": 41.8930549621582,
        "lng": 12.48277759552002
      }
    },
    {
      "codice": "058092",
      "nome": "Roviano",
      "nomeStraniero": null,
      "codiceCatastale": "H618",
      "cap": "00027",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "rovianocomune@tiscali.it",
      "pec": "comuneroviano@pec.it",
      "telefono": "+39 0774 903143",
      "fax": "+39 0774 903008",
      "coordinate": {
        "lat": 42.02574920654297,
        "lng": 12.994359970092773
      }
    },
    {
      "codice": "058093",
      "nome": "Sacrofano",
      "nomeStraniero": null,
      "codiceCatastale": "H658",
      "cap": "00060",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "finanziario@comunedisacrofano.it",
      "pec": "comune@pec.comunedisacrofano.it",
      "telefono": "+39 06/90117018",
      "fax": "+39 06/9086143",
      "coordinate": {
        "lat": 42.099998474121094,
        "lng": 12.449999809265137
      }
    },
    {
      "codice": "058094",
      "nome": "Sambuci",
      "nomeStraniero": null,
      "codiceCatastale": "H745",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "luigi.colarossi@comunesambuci.rm.it",
      "pec": "protocollo@pec.comune.sambuci.rm.it",
      "telefono": "+39 0774/797006",
      "fax": "+39 0774/797143",
      "coordinate": {
        "lat": 41.983333587646484,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "058119",
      "nome": "San Cesareo",
      "nomeStraniero": null,
      "codiceCatastale": "M295",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "servizi.ced@comune.sancesareo.rm.it",
      "pec": "segreteriasancesareo@pec.provincia.roma.it",
      "telefono": "+39 06/95898229",
      "fax": "+39 06/9589139",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "058095",
      "nome": "San Gregorio da Sassola",
      "nomeStraniero": null,
      "codiceCatastale": "H942",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "sindaco.sgds@libero.it",
      "pec": "comune.sangregorio@pec.it",
      "telefono": "+39 0774/480014",
      "fax": "+39 0774/480004",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 12.866666793823242
      }
    },
    {
      "codice": "058096",
      "nome": "San Polo dei Cavalieri",
      "nomeStraniero": null,
      "codiceCatastale": "I125",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "protocollo@comune.sanpolodeicavalieri.rm.it",
      "pec": "protocollo@pec.comune.sanpolodeicavalieri.rm.it",
      "telefono": "+39 0774/416032",
      "fax": "+39 0774/416324",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "058100",
      "nome": "San Vito Romano",
      "nomeStraniero": null,
      "codiceCatastale": "I400",
      "cap": "00030",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "e.desantis@arcinazzo.org",
      "pec": "segreteriasanvitoromano@pec.provincia.roma.it",
      "telefono": "+39 329/0186049",
      "fax": "+39 06/9571006",
      "coordinate": {
        "lat": 41.88333511352539,
        "lng": 12.983333587646484
      }
    },
    {
      "codice": "058098",
      "nome": "Sant'Angelo Romano",
      "nomeStraniero": null,
      "codiceCatastale": "I284",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comunesar@tiscali.it",
      "pec": "comune.santangeloromano@legalmail.it",
      "telefono": "+39 0774/300808",
      "fax": "+39 0774/420547",
      "coordinate": {
        "lat": 42.03333282470703,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "058099",
      "nome": "Sant'Oreste",
      "nomeStraniero": null,
      "codiceCatastale": "I352",
      "cap": "00060",
      "prefisso": "0761",
      "provincia": "Roma",
      "email": "comunesantoreste@alice.it",
      "pec": "comunesantoreste@pec.it",
      "telefono": "+39 0761 57841",
      "fax": "+39 0761 578421",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 12.516666412353516
      }
    },
    {
      "codice": "058097",
      "nome": "Santa Marinella",
      "nomeStraniero": null,
      "codiceCatastale": "I255",
      "cap": "00058",
      "prefisso": "0766",
      "provincia": "Roma",
      "email": "demografici@comune.santamarinella.rm.it",
      "pec": "protocollosantamarinella@postecert.it",
      "telefono": "+39 0766/5385512",
      "fax": null,
      "coordinate": {
        "lat": 42.03333282470703,
        "lng": 11.850000381469727
      }
    },
    {
      "codice": "058101",
      "nome": "Saracinesco",
      "nomeStraniero": null,
      "codiceCatastale": "I424",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "protocollo@comune.saracinesco.rm.it",
      "pec": "servizidemografici@pec.comune.saracinesco.rm.it",
      "telefono": "+39 0774.791004",
      "fax": "+39 0774.791056",
      "coordinate": {
        "lat": 42,
        "lng": 12.949999809265137
      }
    },
    {
      "codice": "058102",
      "nome": "Segni",
      "nomeStraniero": null,
      "codiceCatastale": "I573",
      "cap": "00037",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "demografici@comune.segni.rm.it",
      "pec": "comune.segni@pec.it",
      "telefono": "+39 06/97262234",
      "fax": "+39 06/9768106",
      "coordinate": {
        "lat": 41.68333435058594,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "058103",
      "nome": "Subiaco",
      "nomeStraniero": null,
      "codiceCatastale": "I992",
      "cap": "00028",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "sic2@comunesubiaco.com",
      "pec": "sindaco@pec.comunesubiaco.com",
      "telefono": "+39 0774/816205",
      "fax": "+39 0774/822370",
      "coordinate": {
        "lat": 41.93333435058594,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "058104",
      "nome": "Tivoli",
      "nomeStraniero": null,
      "codiceCatastale": "L182",
      "cap": "00010",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "f.conti@comune.tivoli.rm.it",
      "pec": "info@pec.comune.tivoli.rm.it",
      "telefono": "+39 0774/453291",
      "fax": "+39 0774/453421",
      "coordinate": {
        "lat": 41.96666717529297,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "058105",
      "nome": "Tolfa",
      "nomeStraniero": null,
      "codiceCatastale": "L192",
      "cap": "00059",
      "prefisso": "0766",
      "provincia": "Roma",
      "email": "assistentesociale@comuneditolfa.it",
      "pec": "protocollotolfa@legalmail.it",
      "telefono": "+39 0766-93901",
      "fax": "+39 0766-9390243",
      "coordinate": {
        "lat": 42.150001525878906,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "058106",
      "nome": "Torrita Tiberina",
      "nomeStraniero": null,
      "codiceCatastale": "L302",
      "cap": "00060",
      "prefisso": "0765",
      "provincia": "Roma",
      "email": "info@comune.torritatiberina.rm.it",
      "pec": "segreteriatorritatiberina@pec.provincia.roma.it",
      "telefono": "+39 0765 30116",
      "fax": "+39 0765/30236",
      "coordinate": {
        "lat": 42.233333587646484,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "058107",
      "nome": "Trevignano Romano",
      "nomeStraniero": null,
      "codiceCatastale": "L401",
      "cap": "00069",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "affarigenerali@trevignanoromano.gov.it",
      "pec": "protocollo@pec.trevignanoromano.it",
      "telefono": "+39 06/999120207",
      "fax": "+39 06/9999848",
      "coordinate": {
        "lat": 42.150001525878906,
        "lng": 12.25
      }
    },
    {
      "codice": "058108",
      "nome": "Vallepietra",
      "nomeStraniero": null,
      "codiceCatastale": "L611",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "info@comunevallepietra.it",
      "pec": "infovallepietra@pec.it",
      "telefono": "+39 0774/899031",
      "fax": "+39 0774/899150",
      "coordinate": {
        "lat": 41.93333435058594,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "058109",
      "nome": "Vallinfreda",
      "nomeStraniero": null,
      "codiceCatastale": "L625",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "comunevallinfreda@comunevallinfreda.rm.it",
      "pec": "anagrafe.vallinfreda@pec.it",
      "telefono": "+39 0774/925088",
      "fax": "+39 0774/925222",
      "coordinate": {
        "lat": 42.08333206176758,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "058110",
      "nome": "Valmontone",
      "nomeStraniero": null,
      "codiceCatastale": "L639",
      "cap": "00038",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "anagrafe@comunevalmontone.it",
      "pec": "protocollo@cert.comune.valmontone.rm.it",
      "telefono": "+39 06/95990286",
      "fax": "+39 06/95990287",
      "coordinate": {
        "lat": 41.78333282470703,
        "lng": 12.916666984558105
      }
    },
    {
      "codice": "058111",
      "nome": "Velletri",
      "nomeStraniero": null,
      "codiceCatastale": "L719",
      "cap": "00049",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "ufficio.elettorale@comune.velletri.rm.it",
      "pec": "ufficio.elettorale@pec.comune.velletri.rm.it",
      "telefono": "+39 06/96158257",
      "fax": null,
      "coordinate": {
        "lat": 41.66666793823242,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "058112",
      "nome": "Vicovaro",
      "nomeStraniero": null,
      "codiceCatastale": "L851",
      "cap": "00029",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "info@comunedivicovaro.it",
      "pec": "protocollo@pec.comunedivicovaro.it",
      "telefono": "+39 0774 498002",
      "fax": "+39 0774 498297",
      "coordinate": {
        "lat": 42.017818450927734,
        "lng": 12.896909713745117
      }
    },
    {
      "codice": "058113",
      "nome": "Vivaro Romano",
      "nomeStraniero": null,
      "codiceCatastale": "M095",
      "cap": "00020",
      "prefisso": "0774",
      "provincia": "Roma",
      "email": "vivaroromano@libero.it",
      "pec": "vivaroromano@pec.it",
      "telefono": "+39 0774/923002",
      "fax": "+39 0774/923135",
      "coordinate": {
        "lat": 42.100555419921875,
        "lng": 13.007499694824219
      }
    },
    {
      "codice": "058114",
      "nome": "Zagarolo",
      "nomeStraniero": null,
      "codiceCatastale": "M141",
      "cap": "00039",
      "prefisso": "06",
      "provincia": "Roma",
      "email": "antonio.pisicchio@comunedizagarolo.it",
      "pec": "protocollo@pec.comunedizagarolo.it",
      "telefono": "+39 06/95769203",
      "fax": "+39 06/95769226",
      "coordinate": {
        "lat": 41.83333206176758,
        "lng": 12.833333015441895
      }
    }
  ],
  "Reggio Calabria": [
    {
      "codice": "080001",
      "nome": "Africo",
      "nomeStraniero": null,
      "codiceCatastale": "A065",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "newafrico@tiscali.it",
      "pec": "anagrafe.africo@asmepec.it",
      "telefono": "+39 0964-991109",
      "fax": "+39 0964-991092",
      "coordinate": {
        "lat": 38.0506706237793,
        "lng": 16.13319969177246
      }
    },
    {
      "codice": "080002",
      "nome": "Agnana Calabra",
      "nomeStraniero": null,
      "codiceCatastale": "A077",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "anagrafe.agnana@tiscali.it",
      "pec": "sindaco.agnana@asmepec.it",
      "telefono": "+39 0964-323000",
      "fax": null,
      "coordinate": {
        "lat": 38.29999923706055,
        "lng": 16.21666717529297
      }
    },
    {
      "codice": "080003",
      "nome": "Anoia",
      "nomeStraniero": null,
      "codiceCatastale": "A303",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "segreteria.anoia@comune.anoia.rc.it",
      "pec": "segreteria.anoia@asmepec.it",
      "telefono": "+39 0966-1903652",
      "fax": "+39 0966-945129",
      "coordinate": {
        "lat": 38.43333435058594,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080004",
      "nome": "Antonimina",
      "nomeStraniero": null,
      "codiceCatastale": "A314",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comuneantonimina@libero.it",
      "pec": "prot.antonimina@asmepec.it",
      "telefono": "+39 0964-312000",
      "fax": "+39 0964-312366",
      "coordinate": {
        "lat": 38.266666412353516,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "080005",
      "nome": "Ardore",
      "nomeStraniero": null,
      "codiceCatastale": "A385",
      "cap": "89031",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "segreteriaardore@libero.it",
      "pec": "protocollo.ardore@asmepec.it",
      "telefono": "+39 0964-64366",
      "fax": "+39 0964-624804",
      "coordinate": {
        "lat": 38.18333435058594,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "080006",
      "nome": "Bagaladi",
      "nomeStraniero": null,
      "codiceCatastale": "A544",
      "cap": "89060",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comunebagaladi@tiscali.it",
      "pec": "comune.bagaladi@asmepec.it",
      "telefono": "+39 0965-724016",
      "fax": "+39 0965-724364",
      "coordinate": {
        "lat": 38.03333282470703,
        "lng": 15.816666603088379
      }
    },
    {
      "codice": "080007",
      "nome": "Bagnara Calabra",
      "nomeStraniero": null,
      "codiceCatastale": "A552",
      "cap": "89011",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "sindaco@comunebagnara.it",
      "pec": "protocollo@pec.comunebagnara.it",
      "telefono": "+39 0966-374011",
      "fax": "+39 0966-371454",
      "coordinate": {
        "lat": 38.28333282470703,
        "lng": 15.816666603088379
      }
    },
    {
      "codice": "080008",
      "nome": "Benestare",
      "nomeStraniero": null,
      "codiceCatastale": "A780",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "vicesindaco@comune.benestare.rc.it",
      "pec": "sindaco.benestare@asmepec.it",
      "telefono": "+39 0964 68032",
      "fax": "+39 0964 68274",
      "coordinate": {
        "lat": 38.18333435058594,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "080009",
      "nome": "Bianco",
      "nomeStraniero": null,
      "codiceCatastale": "A843",
      "cap": "89032",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunebianco@epizefiri.it",
      "pec": "comune.bianco@asmepec.it",
      "telefono": "+39 0964-911002",
      "fax": "+39 0964-913274",
      "coordinate": {
        "lat": 38.09170150756836,
        "lng": 16.15159034729004
      }
    },
    {
      "codice": "080010",
      "nome": "Bivongi",
      "nomeStraniero": null,
      "codiceCatastale": "A897",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunebivongi@inwind.it",
      "pec": "amministrativocomunebivongi@pec.it",
      "telefono": "+39 0964-731185",
      "fax": "+39 0964-731476",
      "coordinate": {
        "lat": 38.483333587646484,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "080011",
      "nome": "Bova",
      "nomeStraniero": null,
      "codiceCatastale": "B097",
      "cap": "89033",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "info@comunedibova.it",
      "pec": "comunedibova@pec-cert.it",
      "telefono": "+39 0965-762013",
      "fax": "+39 0965-762010",
      "coordinate": {
        "lat": 38,
        "lng": 15.933333396911621
      }
    },
    {
      "codice": "080013",
      "nome": "Bova Marina",
      "nomeStraniero": null,
      "codiceCatastale": "B099",
      "cap": "89035",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "ufficiodelibere@virgilio.it",
      "pec": "amministrativo.comunebovamarina@asmepec.it",
      "telefono": "+39 0965-760600",
      "fax": "+39 0965-761347",
      "coordinate": {
        "lat": 37.93333435058594,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "080012",
      "nome": "Bovalino",
      "nomeStraniero": null,
      "codiceCatastale": "B098",
      "cap": "89034",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "segretario@comune.bovalino.rc.it",
      "pec": "amministrativa.bovalino@asmepec.it",
      "telefono": "+39 0964 672311",
      "fax": null,
      "coordinate": {
        "lat": 38.150001525878906,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "080014",
      "nome": "Brancaleone",
      "nomeStraniero": null,
      "codiceCatastale": "B118",
      "cap": "89036",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "anagrafebrancaleone@libero.it",
      "pec": "ufficiotecnicobrancaleone@asmepec.it",
      "telefono": "+39 0964-933008",
      "fax": "+39 0964-934060",
      "coordinate": {
        "lat": 37.963890075683594,
        "lng": 16.101110458374023
      }
    },
    {
      "codice": "080015",
      "nome": "Bruzzano Zeffirio",
      "nomeStraniero": null,
      "codiceCatastale": "B234",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunedibruzzanozeffirio@tin.it",
      "pec": "protocollo.bruzzanozeffirio@asmepec.it",
      "telefono": "+39 0964-902807",
      "fax": "+39 0964-902808",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080016",
      "nome": "Calanna",
      "nomeStraniero": null,
      "codiceCatastale": "B379",
      "cap": "89050",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "amministrativo@comune.calanna.rc.it",
      "pec": "amministrativo.calanna@asmepec.it",
      "telefono": "+39 0965-742012",
      "fax": "+39 0965-742464",
      "coordinate": {
        "lat": 38.18333435058594,
        "lng": 15.716666221618652
      }
    },
    {
      "codice": "080017",
      "nome": "Camini",
      "nomeStraniero": null,
      "codiceCatastale": "B481",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comune.camini2@libero.it",
      "pec": "areaamm.camini@pec.i2000net.it",
      "telefono": "+39 0964-733104",
      "fax": "+39 0964-733278",
      "coordinate": {
        "lat": 38.43333435058594,
        "lng": 16.483333587646484
      }
    },
    {
      "codice": "080018",
      "nome": "Campo Calabro",
      "nomeStraniero": null,
      "codiceCatastale": "B516",
      "cap": "89052",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "settoreamministrativo@comune.campocalabro.rc.it",
      "pec": "amministrativo.campocalabro@asmepec.it",
      "telefono": "+39 0965-757600",
      "fax": "+39 0965-757334",
      "coordinate": {
        "lat": 38.21666717529297,
        "lng": 15.666666984558105
      }
    },
    {
      "codice": "080019",
      "nome": "Candidoni",
      "nomeStraniero": null,
      "codiceCatastale": "B591",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "servizidemografici@omune.candidoni.rc.it",
      "pec": "comune.candidoni@asmepec.it",
      "telefono": "+39 0966-900020",
      "fax": "+39 0966-900010",
      "coordinate": {
        "lat": 38.5,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080020",
      "nome": "Canolo",
      "nomeStraniero": null,
      "codiceCatastale": "B617",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "demografici@comunecanolo.it",
      "pec": "demograficicanolo@asmepec.it",
      "telefono": "+39 0964-323005",
      "fax": "+39 0964-323818",
      "coordinate": {
        "lat": 38.31666564941406,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "080021",
      "nome": "Caraffa del Bianco",
      "nomeStraniero": null,
      "codiceCatastale": "B718",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "caraffadelbianco.aaag@virgilio.it",
      "pec": "info@pec.comunecaraffadelbianco.it",
      "telefono": "+39 0964-956001",
      "fax": "+39 0964-956091",
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080022",
      "nome": "Cardeto",
      "nomeStraniero": null,
      "codiceCatastale": "B756",
      "cap": "89060",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "amministrativo@comune.cardeto.rc.it",
      "pec": "segreteria.cardeto@asmepec.it",
      "telefono": "+39 0965 343012",
      "fax": "+39 0965 343360",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "080023",
      "nome": "Careri",
      "nomeStraniero": null,
      "codiceCatastale": "B766",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comune.careri@tin.it",
      "pec": "demografici.careri@asmepec.it",
      "telefono": "+39 0964-63002",
      "fax": "+39 0964-63202",
      "coordinate": {
        "lat": 38.18333435058594,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "080024",
      "nome": "Casignana",
      "nomeStraniero": null,
      "codiceCatastale": "B966",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "c.comunedicasignana@tin.it",
      "pec": "protocollo.casignana@asmepec.it",
      "telefono": "+39 0964-957007",
      "fax": "+39 0964-956688",
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080025",
      "nome": "Caulonia",
      "nomeStraniero": null,
      "codiceCatastale": "C285",
      "cap": "89041",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "elettorale.caulonia@libero.it",
      "pec": "protocollo.caulonia@asmepec.it",
      "telefono": "+39 0964-861697",
      "fax": "+39 0964-860833",
      "coordinate": {
        "lat": 38.38333511352539,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "080026",
      "nome": "Ciminà",
      "nomeStraniero": null,
      "codiceCatastale": "C695",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "uffdem.cimina@tiscali.it",
      "pec": "uffdem.cimina@asmepec.it",
      "telefono": "+39 0964-334012",
      "fax": "+39 0964-334012",
      "coordinate": {
        "lat": 38.25,
        "lng": 16.133333206176758
      }
    },
    {
      "codice": "080027",
      "nome": "Cinquefrondi",
      "nomeStraniero": null,
      "codiceCatastale": "C710",
      "cap": "89021",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": null,
      "pec": "protocollo@pec.comune.cinquefrondi.rc.it",
      "telefono": "+39 0966939118",
      "fax": "+39 0966940818",
      "coordinate": {
        "lat": 38.41666793823242,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "080028",
      "nome": "Cittanova",
      "nomeStraniero": null,
      "codiceCatastale": "C747",
      "cap": "89022",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "servizidemografici@comune.cittanova.rc.it",
      "pec": "protocollo.cittanova@asmepec.it",
      "telefono": "+39 0966-656123",
      "fax": "+39 0966-656120",
      "coordinate": {
        "lat": 38.349998474121094,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080029",
      "nome": "Condofuri",
      "nomeStraniero": null,
      "codiceCatastale": "C954",
      "cap": "89030",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comune@condofuri.net",
      "pec": "protocollo.condofuri@asmepec.it",
      "telefono": "+39 0965-776000",
      "fax": "+39 0965-720632",
      "coordinate": {
        "lat": 38,
        "lng": 15.866666793823242
      }
    },
    {
      "codice": "080030",
      "nome": "Cosoleto",
      "nomeStraniero": null,
      "codiceCatastale": "D089",
      "cap": "89050",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comunecosoleto@libero.it",
      "pec": "amministrativo.cosoleto@asmepec.it",
      "telefono": "+39 0966-962003",
      "fax": "+39 0966-962154",
      "coordinate": {
        "lat": 38.266666412353516,
        "lng": 15.933333396911621
      }
    },
    {
      "codice": "080031",
      "nome": "Delianuova",
      "nomeStraniero": null,
      "codiceCatastale": "D268",
      "cap": "89012",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comunedelianuova@libero.it",
      "pec": "comune.delianuova@asmepec.it",
      "telefono": "+39 0966-963004",
      "fax": "+39 0966-963013",
      "coordinate": {
        "lat": 38.233333587646484,
        "lng": 15.916666984558105
      }
    },
    {
      "codice": "080032",
      "nome": "Feroleto della Chiesa",
      "nomeStraniero": null,
      "codiceCatastale": "D545",
      "cap": "89050",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comune12@virgilio.it",
      "pec": "amministrativo.feroletodellachiesa@asmepec.it",
      "telefono": "+39 0966-996035",
      "fax": "+39 0966-996828",
      "coordinate": {
        "lat": 38.46666717529297,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "080033",
      "nome": "Ferruzzano",
      "nomeStraniero": null,
      "codiceCatastale": "D557",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "servizidemograficiferruzzano@gmail.com",
      "pec": "anagrafe.ferruzzano@asmepec.it",
      "telefono": "+39 0964-914810",
      "fax": "+39 0964-914834",
      "coordinate": {
        "lat": 38.03333282470703,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080034",
      "nome": "Fiumara",
      "nomeStraniero": null,
      "codiceCatastale": "D619",
      "cap": "89050",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": null,
      "pec": "comune.fiumara@asmepec.it",
      "telefono": "+39 0965-750003",
      "fax": "+39 0965-750832",
      "coordinate": {
        "lat": 38.21666717529297,
        "lng": 15.699999809265137
      }
    },
    {
      "codice": "080035",
      "nome": "Galatro",
      "nomeStraniero": null,
      "codiceCatastale": "D864",
      "cap": "89054",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comgalatro.usd@tiscalinet.it",
      "pec": "comgalatro.amministrativo@pec.it",
      "telefono": "+39 0966-903041",
      "fax": "+39 0966-903149",
      "coordinate": {
        "lat": 38.46666717529297,
        "lng": 16.116666793823242
      }
    },
    {
      "codice": "080036",
      "nome": "Gerace",
      "nomeStraniero": null,
      "codiceCatastale": "D975",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunedigerace@libero.it",
      "pec": "comunedigerace@postecert.it",
      "telefono": "+39 0964-356243",
      "fax": "+39 0964-356003",
      "coordinate": {
        "lat": 38.266666412353516,
        "lng": 16.21666717529297
      }
    },
    {
      "codice": "080037",
      "nome": "Giffone",
      "nomeStraniero": null,
      "codiceCatastale": "E025",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comun.giffone@tiscali.it",
      "pec": "elettorale.giffone@asmepec.it",
      "telefono": "+39 0966-934010",
      "fax": "+39 0966-934377",
      "coordinate": {
        "lat": 38.43333435058594,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "080038",
      "nome": "Gioia Tauro",
      "nomeStraniero": null,
      "codiceCatastale": "E041",
      "cap": "89013",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "elettorale@comune.gioiatauro.rc.it",
      "pec": "protocollogioiatauro@asmepec.it",
      "telefono": "+39 0966-508295-508210",
      "fax": "+39 0966-508269",
      "coordinate": {
        "lat": 38.43333435058594,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "080039",
      "nome": "Gioiosa Ionica",
      "nomeStraniero": null,
      "codiceCatastale": "E044",
      "cap": "89042",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "affarigenerali@gioiosajonica.it",
      "pec": "ragioneria.gioiosa@asmepec.it",
      "telefono": "+39 0964-51536",
      "fax": "+39 0964-410520",
      "coordinate": {
        "lat": 38.33333206176758,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "080040",
      "nome": "Grotteria",
      "nomeStraniero": null,
      "codiceCatastale": "E212",
      "cap": "89043",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comune.grotteria@tiscali.it",
      "pec": "protocollo.grotteria@asmepec.it",
      "telefono": "+39 096453195-53242",
      "fax": "+39 0964-53365",
      "coordinate": {
        "lat": 38.35388946533203,
        "lng": 16.272499084472656
      }
    },
    {
      "codice": "080041",
      "nome": "Laganadi",
      "nomeStraniero": null,
      "codiceCatastale": "E402",
      "cap": "89050",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comune.laganadi@tiscali.it",
      "pec": "amministr.laganadi@asmepec.it",
      "telefono": "+39 0965-741033",
      "fax": "+39 0965-741033",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.75
      }
    },
    {
      "codice": "080042",
      "nome": "Laureana di Borrello",
      "nomeStraniero": null,
      "codiceCatastale": "E479",
      "cap": "89023",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "amministrativa@comune.laureanadiborrello.rc.it",
      "pec": "protocollo.laureanadiborrello@asmepec.it",
      "telefono": "+39 0966-906119",
      "fax": "+39 0966-991461",
      "coordinate": {
        "lat": 38.491943359375,
        "lng": 16.081111907958984
      }
    },
    {
      "codice": "080043",
      "nome": "Locri",
      "nomeStraniero": null,
      "codiceCatastale": "D976",
      "cap": "89044",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "elettoralelocri@alice.it",
      "pec": "comunelocri@legalmail.it",
      "telefono": "+39 0964-391485",
      "fax": "+39 0964-391494",
      "coordinate": {
        "lat": 38.233333587646484,
        "lng": 16.266666412353516
      }
    },
    {
      "codice": "080044",
      "nome": "Mammola",
      "nomeStraniero": null,
      "codiceCatastale": "E873",
      "cap": "89045",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "statocivile@comune.mammola.rc.it",
      "pec": "statocivile.mammola@asmepec.it",
      "telefono": "+39 0964-414025",
      "fax": "+39 0964-414003",
      "coordinate": {
        "lat": 38.36666488647461,
        "lng": 16.233333587646484
      }
    },
    {
      "codice": "080045",
      "nome": "Marina di Gioiosa Ionica",
      "nomeStraniero": null,
      "codiceCatastale": "E956",
      "cap": "89046",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "anagrafe.marina@libero.it",
      "pec": "protocollo.marinadigioiosa@asmepec.it",
      "telefono": "+39 0964-415178-411353",
      "fax": "+39 0964-416734",
      "coordinate": {
        "lat": 38.29999923706055,
        "lng": 16.33333396911621
      }
    },
    {
      "codice": "080046",
      "nome": "Maropati",
      "nomeStraniero": null,
      "codiceCatastale": "E968",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "demografici.maropati@virgilio.it",
      "pec": "protocollo.maropati@asmepec.it",
      "telefono": "+39 0966-945770 0966944381",
      "fax": "+39 0966-945175",
      "coordinate": {
        "lat": 38.43333435058594,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "080047",
      "nome": "Martone",
      "nomeStraniero": null,
      "codiceCatastale": "E993",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "ufficioamministrativo@comune.martone.rc.it",
      "pec": "ufficiotecnico.martone@asmepec.it",
      "telefono": "+39 0964-51356",
      "fax": "+39 0964-419128",
      "coordinate": {
        "lat": 38.349998474121094,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "080048",
      "nome": "Melicuccà",
      "nomeStraniero": null,
      "codiceCatastale": "F105",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "suapmelicucca@virgilio.it",
      "pec": "segretariomelicucca@asmepec.it",
      "telefono": "+39 0966-332046",
      "fax": "+39 0966-332019",
      "coordinate": {
        "lat": 38.30310821533203,
        "lng": 15.881529808044434
      }
    },
    {
      "codice": "080049",
      "nome": "Melicucco",
      "nomeStraniero": null,
      "codiceCatastale": "F106",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "elettorale@melicucco.com",
      "pec": "elettorale.melicucco@asmepec.it",
      "telefono": "+39 0966-937018",
      "fax": "+39 0966-937545",
      "coordinate": {
        "lat": 38.43333435058594,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "080050",
      "nome": "Melito di Porto Salvo",
      "nomeStraniero": null,
      "codiceCatastale": "F112",
      "cap": "89063",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "a.rosaci@comune.melitodiportosalvo.rc.it",
      "pec": "s.comunedimelitodiportosalvo@apogeopec.it",
      "telefono": "+39 0965-775121 - 11",
      "fax": "+39 0965-781780",
      "coordinate": {
        "lat": 37.91666793823242,
        "lng": 15.783333778381348
      }
    },
    {
      "codice": "080051",
      "nome": "Molochio",
      "nomeStraniero": null,
      "codiceCatastale": "F301",
      "cap": "89010",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "info@comune.molochio.rc.it",
      "pec": "affarigenerali.molochio@asmepec.it",
      "telefono": "+39 0966-624004",
      "fax": "+39 0966-625123",
      "coordinate": {
        "lat": 38.31666564941406,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "080052",
      "nome": "Monasterace",
      "nomeStraniero": null,
      "codiceCatastale": "F324",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunedimonasterace@libero.it",
      "pec": "segreteria.monasterace@asmepec.it",
      "telefono": "+39 0964-739025",
      "fax": "+39 0964-739272",
      "coordinate": {
        "lat": 38.453609466552734,
        "lng": 16.553054809570312
      }
    },
    {
      "codice": "080053",
      "nome": "Montebello Jonico",
      "nomeStraniero": null,
      "codiceCatastale": "D746",
      "cap": "89064",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "anagrafe@montebellojonico.it",
      "pec": "protocollo.montebello@asmepec.it",
      "telefono": "+39 0965-779005-06",
      "fax": "+39 0965-786040",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 15.766666412353516
      }
    },
    {
      "codice": "080054",
      "nome": "Motta San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "F779",
      "cap": "89065",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comune.mottasg@libero.it",
      "pec": "info@pec.comunemottasg.it",
      "telefono": "+39 0965-718218",
      "fax": "+39 0965-711678",
      "coordinate": {
        "lat": 38,
        "lng": 15.699999809265137
      }
    },
    {
      "codice": "080055",
      "nome": "Oppido Mamertina",
      "nomeStraniero": null,
      "codiceCatastale": "G082",
      "cap": "89014",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "ufficioelettorale@oppidomamertina.net",
      "pec": "protocollo@pec.oppidomamertina.net",
      "telefono": "+39 0966-879124",
      "fax": "+39 0966-879124",
      "coordinate": {
        "lat": 38.29999923706055,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "080056",
      "nome": "Palizzi",
      "nomeStraniero": null,
      "codiceCatastale": "G277",
      "cap": "89030",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "diegoroda@libero.it",
      "pec": "segreteria.palizzi@asmepec.it",
      "telefono": "+39 0965-763079",
      "fax": "+39 0965-763000",
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "080057",
      "nome": "Palmi",
      "nomeStraniero": null,
      "codiceCatastale": "G288",
      "cap": "89015",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "r.calvo@comunedipalmi.it",
      "pec": "protocollo@asmepec.it",
      "telefono": "+39 0966-479815-262259",
      "fax": "+39 0966-413689",
      "coordinate": {
        "lat": 38.36666488647461,
        "lng": 15.850000381469727
      }
    },
    {
      "codice": "080058",
      "nome": "Pazzano",
      "nomeStraniero": null,
      "codiceCatastale": "G394",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "compaz@libero.it",
      "pec": "demografici@pec.comune.pazzano.rc.it",
      "telefono": "+39 0964-731090",
      "fax": "+39 0964-731557",
      "coordinate": {
        "lat": 38.47638702392578,
        "lng": 16.4516658782959
      }
    },
    {
      "codice": "080059",
      "nome": "Placanica",
      "nomeStraniero": null,
      "codiceCatastale": "G729",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunediplacanica@virgilio.it",
      "pec": "protocollo.placanica@asmepec.it",
      "telefono": "+39 0964-738006",
      "fax": "+39 0964-738290",
      "coordinate": {
        "lat": 38.41666793823242,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "080060",
      "nome": "Platì",
      "nomeStraniero": null,
      "codiceCatastale": "G735",
      "cap": "89039",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "platisegreteria@alice.it",
      "pec": "comuneplati@legalmail.it",
      "telefono": "+39 0964-47028",
      "fax": "+39 0964-47029",
      "coordinate": {
        "lat": 38.21666717529297,
        "lng": 16.049999237060547
      }
    },
    {
      "codice": "080061",
      "nome": "Polistena",
      "nomeStraniero": null,
      "codiceCatastale": "G791",
      "cap": "89024",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "segreteria@omune.polistena.rc.it",
      "pec": "protocollo@pec.it",
      "telefono": "+39 0966-939649",
      "fax": "+39 0966-939649",
      "coordinate": {
        "lat": 38.400001525878906,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "080062",
      "nome": "Portigliola",
      "nomeStraniero": null,
      "codiceCatastale": "G905",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comune.portigliola@yahoo.it",
      "pec": "areaamministrativa.portigliola@asmepec.it",
      "telefono": "+39 0964-365002",
      "fax": "+39 0964-365594",
      "coordinate": {
        "lat": 38.233333587646484,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "080063",
      "nome": "Reggio di Calabria",
      "nomeStraniero": null,
      "codiceCatastale": "H224",
      "cap": "89121",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "elettorale@reggiocal.it",
      "pec": "elettorale@pec.reggiocal.it",
      "telefono": "+39 0965-812497",
      "fax": "+39 0965-3623825",
      "coordinate": {
        "lat": 38.114437103271484,
        "lng": 15.649999618530273
      }
    },
    {
      "codice": "080064",
      "nome": "Riace",
      "nomeStraniero": null,
      "codiceCatastale": "H265",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "elettoraleriace@libero.it",
      "pec": "protocollo.riace@asmepec.it",
      "telefono": "+39 0964-733002",
      "fax": null,
      "coordinate": {
        "lat": 38.41805648803711,
        "lng": 16.481111526489258
      }
    },
    {
      "codice": "080065",
      "nome": "Rizziconi",
      "nomeStraniero": null,
      "codiceCatastale": "H359",
      "cap": "89016",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "affarigenerali@omune.rizziconi.rc.it",
      "pec": "affgen@pecmail.comunedirizziconi.it",
      "telefono": "+39 0966-5889220-588913",
      "fax": "+39 0966-54766",
      "coordinate": {
        "lat": 38.41222381591797,
        "lng": 15.95888900756836
      }
    },
    {
      "codice": "080066",
      "nome": "Roccaforte del Greco",
      "nomeStraniero": null,
      "codiceCatastale": "H408",
      "cap": "89060",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "ismia@tiscali.it",
      "pec": "comuneroccafortedelgreco@pec.it",
      "telefono": "+39 0965-722912",
      "fax": "+39 0965-722812",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "080067",
      "nome": "Roccella Ionica",
      "nomeStraniero": null,
      "codiceCatastale": "H456",
      "cap": "89047",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunediroccellajonica@roccella.it",
      "pec": "protocollo.roccellaionica@asmepec.it",
      "telefono": "+39 0964-84228",
      "fax": "+39 0964-1940308",
      "coordinate": {
        "lat": 38.31666564941406,
        "lng": 16.399999618530273
      }
    },
    {
      "codice": "080068",
      "nome": "Roghudi",
      "nomeStraniero": null,
      "codiceCatastale": "H489",
      "cap": "89060",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "uf.amministrativoroghudi@virgilio.it",
      "pec": "comuneroghudi@pec.it",
      "telefono": "+39 0965-789140",
      "fax": "+39 0965-771327",
      "coordinate": {
        "lat": 38.04888916015625,
        "lng": 15.915833473205566
      }
    },
    {
      "codice": "080069",
      "nome": "Rosarno",
      "nomeStraniero": null,
      "codiceCatastale": "H558",
      "cap": "89025",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "ufficioelettorale@comune.rosarno.rc.it",
      "pec": "segretariogenerale@comune.rosarno.rc.it",
      "telefono": "+39 0966-710237",
      "fax": "+39 0966-780040",
      "coordinate": {
        "lat": 38.48500061035156,
        "lng": 15.979722023010254
      }
    },
    {
      "codice": "080070",
      "nome": "Samo",
      "nomeStraniero": null,
      "codiceCatastale": "H013",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "demograficisamo@libero.it",
      "pec": "protocollo.comunedisamo@asmepec.it",
      "telefono": "+39 0964-977022",
      "fax": "+39 0964-977305",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "080097",
      "nome": "San Ferdinando",
      "nomeStraniero": null,
      "codiceCatastale": "M277",
      "cap": "89026",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "protocollo.sanferdinando@asmepec.it",
      "pec": "protocollo.sanferdinando@asmepec.it",
      "telefono": "+39 0966-7614108",
      "fax": null,
      "coordinate": {
        "lat": 38.48666763305664,
        "lng": 15.919722557067871
      }
    },
    {
      "codice": "080071",
      "nome": "San Giorgio Morgeto",
      "nomeStraniero": null,
      "codiceCatastale": "H889",
      "cap": "89017",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "morgeto@tiscali.it",
      "pec": "affarigenerali.sangiorgiomorgeto@asmepec.it",
      "telefono": "+39 0966-946050",
      "fax": "+39 0966-946345",
      "coordinate": {
        "lat": 38.38333511352539,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "080072",
      "nome": "San Giovanni di Gerace",
      "nomeStraniero": null,
      "codiceCatastale": "H903",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "anag-sangiov@tiscali.it",
      "pec": "segretario-sangiov@asmepec.it",
      "telefono": "+39 0964-58023",
      "fax": "+39 0964-58065",
      "coordinate": {
        "lat": 38.36666488647461,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "080073",
      "nome": "San Lorenzo",
      "nomeStraniero": null,
      "codiceCatastale": "H959",
      "cap": "89069",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "eros.polimeni@virgilio.it",
      "pec": "protocollo.sanlorenzo@asmepec.it",
      "telefono": "+39 0965-721395",
      "fax": "+39 0965-721023",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 15.833333015441895
      }
    },
    {
      "codice": "080074",
      "nome": "San Luca",
      "nomeStraniero": null,
      "codiceCatastale": "H970",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "servizioelettorale@comune.sanluca.rc.it",
      "pec": "servizioelettorale.sanluca@asmepec.it",
      "telefono": "+39 0964-985012",
      "fax": "+39 0964-985720",
      "coordinate": {
        "lat": 38.150001525878906,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "080075",
      "nome": "San Pietro di Caridà",
      "nomeStraniero": null,
      "codiceCatastale": "I102",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comunespcarida@gmail.com",
      "pec": "comunesanpietrodicarida@asmepec.it",
      "telefono": "+39 0966-990000",
      "fax": null,
      "coordinate": {
        "lat": 38.52470016479492,
        "lng": 16.13610076904297
      }
    },
    {
      "codice": "080076",
      "nome": "San Procopio",
      "nomeStraniero": null,
      "codiceCatastale": "I132",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "demografici@comune.sanprocopio.rc.it",
      "pec": "demografici.sanprocopio@asmepec.it",
      "telefono": "+39 0966-333001",
      "fax": "+39 0966-333205",
      "coordinate": {
        "lat": 38.28333282470703,
        "lng": 15.899999618530273
      }
    },
    {
      "codice": "080077",
      "nome": "San Roberto",
      "nomeStraniero": null,
      "codiceCatastale": "I139",
      "cap": "89050",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comsanroberto3@libero.it",
      "pec": "protocollo.sanroberto@asmepec.it",
      "telefono": "+39 0965-753336",
      "fax": "+39 0965-753812",
      "coordinate": {
        "lat": 38.20000076293945,
        "lng": 15.733333587646484
      }
    },
    {
      "codice": "080079",
      "nome": "Sant'Agata del Bianco",
      "nomeStraniero": null,
      "codiceCatastale": "I198",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunesantagatademo@libero.it",
      "pec": "tecnico.santagata@asmepec.it",
      "telefono": "+39 0964-956006",
      "fax": "+39 0964-956009",
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "080080",
      "nome": "Sant'Alessio in Aspromonte",
      "nomeStraniero": null,
      "codiceCatastale": "I214",
      "cap": "89050",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comunesantalessio@libero.it",
      "pec": "segretario.santalessio@aspemec.it",
      "telefono": "+39 0965-741029",
      "fax": "+39 0965-741203",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.75
      }
    },
    {
      "codice": "080081",
      "nome": "Sant'Eufemia d'Aspromonte",
      "nomeStraniero": null,
      "codiceCatastale": "I333",
      "cap": "89027",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "info@seufemia.org",
      "pec": "protocollo@pec.seufemia.it",
      "telefono": "+39 0966-961003",
      "fax": "+39 0966-961103",
      "coordinate": {
        "lat": 38.266666412353516,
        "lng": 15.866666793823242
      }
    },
    {
      "codice": "080082",
      "nome": "Sant'Ilario dello Ionio",
      "nomeStraniero": null,
      "codiceCatastale": "I341",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "amministrativo@comune.santilariodelloionio.rc.it",
      "pec": "amministrativosantilario@asmepec.it",
      "telefono": "+39 0964-365006",
      "fax": "+39 0964-365412",
      "coordinate": {
        "lat": 38.21666717529297,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "080078",
      "nome": "Santa Cristina d'Aspromonte",
      "nomeStraniero": null,
      "codiceCatastale": "I176",
      "cap": "89056",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comuneanags.cristina@libero.it",
      "pec": "protocollo.santacristinadaspromonte@asmepec.it",
      "telefono": "+39 0966-88013",
      "fax": "+39 0966-88410",
      "coordinate": {
        "lat": 38.25,
        "lng": 15.966666221618652
      }
    },
    {
      "codice": "080083",
      "nome": "Santo Stefano in Aspromonte",
      "nomeStraniero": null,
      "codiceCatastale": "I371",
      "cap": "89057",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "comune@comune.santostefanoinaspromonte.rc.it",
      "pec": "protocollo.santostefano@asmepec.it",
      "telefono": "+39 0965-740601",
      "fax": "+39 0965-740458",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 15.783333778381348
      }
    },
    {
      "codice": "080084",
      "nome": "Scido",
      "nomeStraniero": null,
      "codiceCatastale": "I536",
      "cap": "89010",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "scido.anagrafeaire@libero.it",
      "pec": "protocollo.scido@asmepec.it",
      "telefono": "+39 0966-964048",
      "fax": "+39 0966-1906320",
      "coordinate": {
        "lat": 38.25,
        "lng": 15.933333396911621
      }
    },
    {
      "codice": "080085",
      "nome": "Scilla",
      "nomeStraniero": null,
      "codiceCatastale": "I537",
      "cap": "89058",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "segreteria@comunediscilla.org",
      "pec": "segreteria.scilla@asmepec.it",
      "telefono": "+39 0965-754003",
      "fax": "+39 0965-754704",
      "coordinate": {
        "lat": 38.25,
        "lng": 15.716666221618652
      }
    },
    {
      "codice": "080086",
      "nome": "Seminara",
      "nomeStraniero": null,
      "codiceCatastale": "I600",
      "cap": "89028",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "anagrafeseminara@virgilio.it",
      "pec": "affarigenerali.seminara@asmepec.it",
      "telefono": "+39 0966-317004",
      "fax": "+39 0966-317560",
      "coordinate": {
        "lat": 38.33333206176758,
        "lng": 15.866666793823242
      }
    },
    {
      "codice": "080087",
      "nome": "Serrata",
      "nomeStraniero": null,
      "codiceCatastale": "I656",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "comunediserrata@virgilio.it",
      "pec": "protocollo.serrata@asmepec.it",
      "telefono": "+39 0966-995002",
      "fax": "+39 0966-995285",
      "coordinate": {
        "lat": 38.51250076293945,
        "lng": 16.100000381469727
      }
    },
    {
      "codice": "080088",
      "nome": "Siderno",
      "nomeStraniero": null,
      "codiceCatastale": "I725",
      "cap": "89048",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunesidernosindaco@virgilio.it",
      "pec": "postacertificata@comunesiderno.telecompost.it",
      "telefono": "+39 0964-345232",
      "fax": "+39 0964-381076",
      "coordinate": {
        "lat": 38.266666412353516,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "080089",
      "nome": "Sinopoli",
      "nomeStraniero": null,
      "codiceCatastale": "I753",
      "cap": "89020",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "demografici@comune.sinopoli.rc.it",
      "pec": "comune.sinopoli@aspemec.it",
      "telefono": "+39 0966-961140",
      "fax": "+39 0966-965665",
      "coordinate": {
        "lat": 38.26583480834961,
        "lng": 15.878889083862305
      }
    },
    {
      "codice": "080090",
      "nome": "Staiti",
      "nomeStraniero": null,
      "codiceCatastale": "I936",
      "cap": "89030",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "rodolfoesposito@tiscali.it",
      "pec": "sindaco.staiti.rc@asmepec.it",
      "telefono": "+39 0964 941164",
      "fax": "+39 0964 941839",
      "coordinate": {
        "lat": 38,
        "lng": 16.03333282470703
      }
    },
    {
      "codice": "080091",
      "nome": "Stignano",
      "nomeStraniero": null,
      "codiceCatastale": "I955",
      "cap": "89040",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "comunestignano@tiscali.it",
      "pec": "comunestignano@pec.it",
      "telefono": "+39 0964-772121",
      "fax": "+39 0964-772478",
      "coordinate": {
        "lat": 38.41666793823242,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "080092",
      "nome": "Stilo",
      "nomeStraniero": null,
      "codiceCatastale": "I956",
      "cap": "89049",
      "prefisso": "0964",
      "provincia": "Reggio Calabria",
      "email": "servizio.amministrativo@comune.stilo.rc.it",
      "pec": "comune.stilo.rc@asmepec.it",
      "telefono": "+39 0964-776006",
      "fax": "+39 0964-775312",
      "coordinate": {
        "lat": 38.483333587646484,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "080093",
      "nome": "Taurianova",
      "nomeStraniero": null,
      "codiceCatastale": "L063",
      "cap": "89029",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "protocollo@pec.comune.taurianova.rc.it",
      "pec": "protocollo@pec.comune.taurianova.rc.it",
      "telefono": "0966618011",
      "fax": "0966618001",
      "coordinate": {
        "lat": 38.349998474121094,
        "lng": 16.016666412353516
      }
    },
    {
      "codice": "080094",
      "nome": "Terranova Sappo Minulio",
      "nomeStraniero": null,
      "codiceCatastale": "L127",
      "cap": "89010",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "sindaco@comune.terranovasappominulio.rc.it",
      "pec": "sindaco.terranovasappominulio@asmepec.it",
      "telefono": "+39 0966-619004",
      "fax": "+39 0966-619121",
      "coordinate": {
        "lat": 38.31666564941406,
        "lng": 16
      }
    },
    {
      "codice": "080095",
      "nome": "Varapodio",
      "nomeStraniero": null,
      "codiceCatastale": "L673",
      "cap": "89010",
      "prefisso": "0966",
      "provincia": "Reggio Calabria",
      "email": "anagrafe@comunevarapodio.it",
      "pec": "anagrafe@pec.comunevarapodio.it",
      "telefono": "+39 0966-81005",
      "fax": "+39 0966-874254",
      "coordinate": {
        "lat": 38.31666564941406,
        "lng": 15.983333587646484
      }
    },
    {
      "codice": "080096",
      "nome": "Villa San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "M018",
      "cap": "89018",
      "prefisso": "0965",
      "provincia": "Reggio Calabria",
      "email": "segretario@comune.villasangiovanni.rc.it",
      "pec": "protocollo.villasg@asmepec.it",
      "telefono": "+39 09657934222",
      "fax": "+39 09657934231",
      "coordinate": {
        "lat": 38.21666717529297,
        "lng": 15.633333206176758
      }
    }
  ],
  "Piacenza": [
    {
      "codice": "033001",
      "nome": "Agazzano",
      "nomeStraniero": null,
      "codiceCatastale": "A067",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "urp.@comune.agazzano.pc.it",
      "pec": "comune.agazzano@sintranet.legalmail.it",
      "telefono": "+39 0523/971723",
      "fax": "+39 0523/976871",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "033002",
      "nome": "Alseno",
      "nomeStraniero": null,
      "codiceCatastale": "A223",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "demografici@comune.alseno.pc.it",
      "pec": "comune.alseno@sintranet.legalmail.it",
      "telefono": "+39 0523/945519",
      "fax": "+39 0523/949445",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "033049",
      "nome": "Alta Val Tidone",
      "nomeStraniero": null,
      "codiceCatastale": "M386",
      "cap": "29031",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "info@comunealtavaltidone.pc.it",
      "pec": "protocollo@pec.comunealtavaltidone.pc.it",
      "telefono": "0523993711",
      "fax": "0523990041",
      "coordinate": {
        "lat": 44.904998779296875,
        "lng": 9.32638931274414
      }
    },
    {
      "codice": "033003",
      "nome": "Besenzone",
      "nomeStraniero": null,
      "codiceCatastale": "A823",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "info@comune.besenzone.pc.it",
      "pec": "comune.besenzone@sintranet.legalmail.it",
      "telefono": "+39 0523/836465",
      "fax": "+39 0523/835037",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "033004",
      "nome": "Bettola",
      "nomeStraniero": null,
      "codiceCatastale": "A831",
      "cap": "29021",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.bettola@sintranet.it",
      "pec": "comune.bettola@sintranet.legalmail.it",
      "telefono": "+39 0523/917718",
      "fax": "+39 0523/911620",
      "coordinate": {
        "lat": 44.77825927734375,
        "lng": 9.608570098876953
      }
    },
    {
      "codice": "033005",
      "nome": "Bobbio",
      "nomeStraniero": null,
      "codiceCatastale": "A909",
      "cap": "29022",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.bobbio@sintranet.it",
      "pec": "comune.bobbio@sintranet.legalmail.it",
      "telefono": "0523/962811",
      "fax": "0523/936666",
      "coordinate": {
        "lat": 44.77149963378906,
        "lng": 9.38640022277832
      }
    },
    {
      "codice": "033006",
      "nome": "Borgonovo Val Tidone",
      "nomeStraniero": null,
      "codiceCatastale": "B025",
      "cap": "29011",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "protocollo.borgonovo@sintranet.it",
      "pec": "protocollo.borgonovo@legalmail.it",
      "telefono": "+39 0253/861811",
      "fax": "+39 0523/861861",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "033007",
      "nome": "Cadeo",
      "nomeStraniero": null,
      "codiceCatastale": "B332",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "servizio.demografico@comune.cadeo.pc.it",
      "pec": "comune.cadeo@sintranet.legalmail.it",
      "telefono": "+39 0523/503311",
      "fax": "+39 0253/509997",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "033008",
      "nome": "Calendasco",
      "nomeStraniero": null,
      "codiceCatastale": "B405",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "protocollo@comunecalendascopc.it",
      "pec": "comune.calendasco@legalmail.it",
      "telefono": "+39 0523/772722",
      "fax": "+39 0523/772744",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "033010",
      "nome": "Caorso",
      "nomeStraniero": null,
      "codiceCatastale": "B643",
      "cap": "29012",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.caorso@sintranet.it",
      "pec": "comune.caorso@sintranet.legalmail.it",
      "telefono": "+39 0523/814725",
      "fax": "+39 0523/821109",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "033011",
      "nome": "Carpaneto Piacentino",
      "nomeStraniero": null,
      "codiceCatastale": "B812",
      "cap": "29013",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "elettorale@comune.carpaneto.pc.it",
      "pec": "anagrafe.carpaneto@legalmail.it",
      "telefono": "+39 0523/853701",
      "fax": "+39 0523/850090",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "033013",
      "nome": "Castel San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "C261",
      "cap": "29015",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "anagrafe.csg@sintranet.it",
      "pec": "comune.castelsangiovanni@sintranet.legalmail.it",
      "telefono": "+39 0523/889735",
      "fax": "+39 0523/843882",
      "coordinate": {
        "lat": 45.05906677246094,
        "lng": 9.434178352355957
      }
    },
    {
      "codice": "033012",
      "nome": "Castell'Arquato",
      "nomeStraniero": null,
      "codiceCatastale": "C145",
      "cap": "29014",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "anagrafe@comune.castellarquato.pc.it",
      "pec": "comune.castellarquato@sintranet.legalmail.it",
      "telefono": "+39 0523/804011",
      "fax": "+39 0523/803982",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "033014",
      "nome": "Castelvetro Piacentino",
      "nomeStraniero": null,
      "codiceCatastale": "C288",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "segreteria@comune.castelvetro.pc.it",
      "pec": "comune.castelvetro@sintranet.legalmail.it",
      "telefono": "+39 0523/257611",
      "fax": "+39 0523/824734",
      "coordinate": {
        "lat": 45.10033416748047,
        "lng": 9.986716270446777
      }
    },
    {
      "codice": "033015",
      "nome": "Cerignale",
      "nomeStraniero": null,
      "codiceCatastale": "C513",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.cerignale@sintranet.it",
      "pec": "comune.cerignale@sintranet.legalmail.it",
      "telefono": "+39 0523/939210",
      "fax": "+39 0523/939210",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "033016",
      "nome": "Coli",
      "nomeStraniero": null,
      "codiceCatastale": "C838",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "info@comunecoli.it",
      "pec": "amministrazionecoli@pecimprese.it",
      "telefono": "+39 0523/931117",
      "fax": "+39 0523/931136",
      "coordinate": {
        "lat": 44.75,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "033017",
      "nome": "Corte Brugnatella",
      "nomeStraniero": null,
      "codiceCatastale": "D054",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.cortebrugnatella@sintranet.it",
      "pec": "comune.cortebrugnatella@sintranet.legalmail.it",
      "telefono": "+39 0523/969011",
      "fax": "+39 0523/969012",
      "coordinate": {
        "lat": 44.71576690673828,
        "lng": 9.360288619995117
      }
    },
    {
      "codice": "033018",
      "nome": "Cortemaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "D061",
      "cap": "29016",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "info@comune.cortemaggiore.pc.it",
      "pec": "comune.cortemaggiore@sintranet.legalmail.it",
      "telefono": "+39 0523/832711",
      "fax": "+39 0523/836598",
      "coordinate": {
        "lat": 45,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "033019",
      "nome": "Farini",
      "nomeStraniero": null,
      "codiceCatastale": "D502",
      "cap": "29023",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comunedifarini@virgilio.it",
      "pec": "comune.farini@sintranet.legalmail.it",
      "telefono": "+39 0523/910235",
      "fax": null,
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "033020",
      "nome": "Ferriere",
      "nomeStraniero": null,
      "codiceCatastale": "D555",
      "cap": "29024",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "anagrafediferriere@gmail.com",
      "pec": "comune.ferriere@sintranet.legalmail.it",
      "telefono": "+39 0523/922885",
      "fax": "+39 0523/922818",
      "coordinate": {
        "lat": 44.64384841918945,
        "lng": 9.496569633483887
      }
    },
    {
      "codice": "033021",
      "nome": "Fiorenzuola d'Arda",
      "nomeStraniero": null,
      "codiceCatastale": "D611",
      "cap": "29017",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "elettorale@comune.fiorenzuola.pc.it",
      "pec": "protocollo@pec.comune.fiorenzuola.pc.it",
      "telefono": "+39 0523/989306",
      "fax": "+39 0523/982680",
      "coordinate": {
        "lat": 44.93333435058594,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "033022",
      "nome": "Gazzola",
      "nomeStraniero": null,
      "codiceCatastale": "D958",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "amministrativo@comune.gazzola.pc.it",
      "pec": "protocollo@pec.comune.gazzola.pc.it",
      "telefono": "+39 0523/975221",
      "fax": "+39 0523/976767",
      "coordinate": {
        "lat": 44.960060119628906,
        "lng": 9.54896354675293
      }
    },
    {
      "codice": "033023",
      "nome": "Gossolengo",
      "nomeStraniero": null,
      "codiceCatastale": "E114",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "mrosa.rossi@comune.gossolengo.pc.it",
      "pec": "comune.gossolengo@.legalmail.it",
      "telefono": "+39 0523/770731",
      "fax": "+39 0523/779291",
      "coordinate": {
        "lat": 45,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "033024",
      "nome": "Gragnano Trebbiense",
      "nomeStraniero": null,
      "codiceCatastale": "E132",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "segreteria@comune.gragnanotrebbiense.pc.it",
      "pec": "protocollo@pec.comune.gragnanotrebbiense.pc.it",
      "telefono": "+39 0523/787141",
      "fax": "+39 0523/788354",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "033025",
      "nome": "Gropparello",
      "nomeStraniero": null,
      "codiceCatastale": "E196",
      "cap": "29025",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune@comune.gropparello.pc.it",
      "pec": "comune.gropparello@sintranet.legalmail.it",
      "telefono": "+39 0523/856121/856131/856599",
      "fax": "+39 0523/856363",
      "coordinate": {
        "lat": 44.83333206176758,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "033026",
      "nome": "Lugagnano Val d'Arda",
      "nomeStraniero": null,
      "codiceCatastale": "E726",
      "cap": "29018",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "protocollo@comune.lugagnano.pc.it",
      "pec": "comune.lugagnano@sintranet.legalmail.it",
      "telefono": "+39 0523891232",
      "fax": "+39 0523801008",
      "coordinate": {
        "lat": 44.81666564941406,
        "lng": 9.816666603088379
      }
    },
    {
      "codice": "033027",
      "nome": "Monticelli d'Ongina",
      "nomeStraniero": null,
      "codiceCatastale": "F671",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "statocivile.monticelli@sintranet.it",
      "pec": "comune.monticelli@sintranet.legalmail.it",
      "telefono": "+39 0523/820441",
      "fax": "+39 0523/827682",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "033028",
      "nome": "Morfasso",
      "nomeStraniero": null,
      "codiceCatastale": "F724",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.morfasso@sintranet.it",
      "pec": "comune.morfasso@sintranet.legalmail.it",
      "telefono": "+39 0523/908180",
      "fax": "+39 0523/908187",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "033030",
      "nome": "Ottone",
      "nomeStraniero": null,
      "codiceCatastale": "G195",
      "cap": "29026",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.ottone@sintranet.it",
      "pec": "comune.ottone@sintranet.legalmail.it",
      "telefono": "+39 0523/930122",
      "fax": "+39 0523/930122",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "033032",
      "nome": "Piacenza",
      "nomeStraniero": null,
      "codiceCatastale": "G535",
      "cap": "29121",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "u.elettorale@comune.piacenza.it",
      "pec": "u.elettorale@cert.comune.piacenza.it",
      "telefono": "+39 0523 4921",
      "fax": "+39 0523/328843",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.699999809265137
      }
    },
    {
      "codice": "033033",
      "nome": "Pianello Val Tidone",
      "nomeStraniero": null,
      "codiceCatastale": "G557",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "anagrafe@comunepianellovaltidone.it",
      "pec": "comunepianellovaltidone@pec.it",
      "telefono": "+39 0523/994110",
      "fax": "+39 0523/998302",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "033034",
      "nome": "Piozzano",
      "nomeStraniero": null,
      "codiceCatastale": "G696",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.piozzano@sintranet.it",
      "pec": "comune.piozzano@sintranet.legalmail.it",
      "telefono": "+39 0523 910114",
      "fax": "+39 0523 970814",
      "coordinate": {
        "lat": 44.92527770996094,
        "lng": 9.494166374206543
      }
    },
    {
      "codice": "033035",
      "nome": "Podenzano",
      "nomeStraniero": null,
      "codiceCatastale": "G747",
      "cap": "29027",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "segreteria@comune.podenzano.pc.it",
      "pec": "comune.podenzano@sintranet.legalmail.it",
      "telefono": "+39 0523/554638",
      "fax": "+39 0523/550228",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 9.683333396911621
      }
    },
    {
      "codice": "033036",
      "nome": "Ponte dell'Olio",
      "nomeStraniero": null,
      "codiceCatastale": "G842",
      "cap": "29028",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "segretario@comune-pontedellolio.it",
      "pec": "compdo@actaliscertymail.it",
      "telefono": "+39 0523/844411",
      "fax": "+39 0523/874444",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "033037",
      "nome": "Pontenure",
      "nomeStraniero": null,
      "codiceCatastale": "G852",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.pontenure@sintranet.it",
      "pec": "comune.pontenure@sintranet.legamail.it",
      "telefono": "+39 0523/692011",
      "fax": "+39 0523/510142",
      "coordinate": {
        "lat": 45,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "033038",
      "nome": "Rivergaro",
      "nomeStraniero": null,
      "codiceCatastale": "H350",
      "cap": "29029",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "protocollo@comune.rivergaro.pc.it",
      "pec": "comune.rivergaro@sintranet.legalmail.it",
      "telefono": "+39 0523/953511",
      "fax": "+39 0523/953520",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "033039",
      "nome": "Rottofreno",
      "nomeStraniero": null,
      "codiceCatastale": "H593",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "segreteria.rottofreno@sintranet.it",
      "pec": "comune.rottofreno@sintranet.legalmail.it",
      "telefono": "0523780316",
      "fax": "0523781929",
      "coordinate": {
        "lat": 45.05792999267578,
        "lng": 9.5489501953125
      }
    },
    {
      "codice": "033040",
      "nome": "San Giorgio Piacentino",
      "nomeStraniero": null,
      "codiceCatastale": "H887",
      "cap": "29019",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "marzia.gobbi@comune.sangiorgiopiacentino.pc.it",
      "pec": "comune.sangiorgio@sintranet.legalmail.it",
      "telefono": "0523370711",
      "fax": "0523379638",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "033041",
      "nome": "San Pietro in Cerro",
      "nomeStraniero": null,
      "codiceCatastale": "G788",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune@comune.sanpietroincerro.pc.it",
      "pec": "comune.sanpietroincerro@legalmail.it",
      "telefono": "+39 0523/836479",
      "fax": "+39 0523/835322",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "033042",
      "nome": "Sarmato",
      "nomeStraniero": null,
      "codiceCatastale": "I434",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "ragioneria@comune.sarmato.pc.it",
      "pec": "comune.sarmato@sintranet.legalmail.it",
      "telefono": "+39 0523/887828",
      "fax": "+39 0523/887784",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "033043",
      "nome": "Travo",
      "nomeStraniero": null,
      "codiceCatastale": "L348",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.travo@sintranet.it",
      "pec": "comune.travo@sintranet.legalmail.it",
      "telefono": "+39 0523/950121",
      "fax": "+39 0523/959474",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "033044",
      "nome": "Vernasca",
      "nomeStraniero": null,
      "codiceCatastale": "L772",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.vernasca@sintranet.it",
      "pec": "comune.vernasca@sintranet.legalmail.it",
      "telefono": "+39 0523/891225",
      "fax": "+39 0253/891625",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "033045",
      "nome": "Vigolzone",
      "nomeStraniero": null,
      "codiceCatastale": "L897",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "stefano.favari@comune.vigolzone.pc.it",
      "pec": "comune.vigolzone@sintranet.legalmail.it",
      "telefono": "+39 0523/872711",
      "fax": "+39 0523/872789",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 9.666666984558105
      }
    },
    {
      "codice": "033046",
      "nome": "Villanova sull'Arda",
      "nomeStraniero": null,
      "codiceCatastale": "L980",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.villanova@sintranet.it",
      "pec": "comune.villanova@sintranet.legalmail.it",
      "telefono": "+39 0523/837927",
      "fax": "+39 0523/837757",
      "coordinate": {
        "lat": 45.026004791259766,
        "lng": 9.997060775756836
      }
    },
    {
      "codice": "033047",
      "nome": "Zerba",
      "nomeStraniero": null,
      "codiceCatastale": "M165",
      "cap": "29020",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "comune.zerba@sintranet.it",
      "pec": "comune.zerba@sintranet.legalmail.it",
      "telefono": "+39 0523 935102",
      "fax": "+39 0523 935102",
      "coordinate": {
        "lat": 44.66550827026367,
        "lng": 9.287949562072754
      }
    },
    {
      "codice": "033048",
      "nome": "Ziano Piacentino",
      "nomeStraniero": null,
      "codiceCatastale": "L848",
      "cap": "29010",
      "prefisso": "0523",
      "provincia": "Piacenza",
      "email": "elettorale@comune.ziano.pc.it",
      "pec": "elettorale@pec.comune.ziano.pc.it",
      "telefono": "+39 0523/863221",
      "fax": "+39 0523/865015",
      "coordinate": {
        "lat": 45,
        "lng": 9.399999618530273
      }
    }
  ],
  "Sassari": [
    {
      "codice": "090001",
      "nome": "Aggius",
      "nomeStraniero": null,
      "codiceCatastale": "A069",
      "cap": "07020",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comuneaggius.gov.it",
      "pec": "protocollo@pec.comuneaggius.gov.it",
      "telefono": "+39 079 621060",
      "fax": "+39 079 620288",
      "coordinate": {
        "lat": 40.929481506347656,
        "lng": 9.064428329467773
      }
    },
    {
      "codice": "090062",
      "nome": "Aglientu",
      "nomeStraniero": null,
      "codiceCatastale": "H848",
      "cap": "07020",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comune.aglientu.ot.it",
      "pec": "protocollo@pec.comune.aglientu.ot.it",
      "telefono": "+39 079 6579101",
      "fax": "+39 079 654340",
      "coordinate": {
        "lat": 41.078834533691406,
        "lng": 9.112944602966309
      }
    },
    {
      "codice": "090002",
      "nome": "Alà dei Sardi",
      "nomeStraniero": null,
      "codiceCatastale": "A115",
      "cap": "07020",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comune.aladeisardi.ot.it",
      "pec": "protocollo.aladeisardi@legalmail.it",
      "telefono": "+39 079 7239000",
      "fax": "+39 079 7239031",
      "coordinate": {
        "lat": 40.65137481689453,
        "lng": 9.329237937927246
      }
    },
    {
      "codice": "090003",
      "nome": "Alghero",
      "nomeStraniero": null,
      "codiceCatastale": "A192",
      "cap": "07041",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "f.loi@comune.alghero.ss.it",
      "pec": "protocollo@pec.comune.alghero.ss.it",
      "telefono": "+39 079 9978800",
      "fax": "+39 0799978699",
      "coordinate": {
        "lat": 40.560001373291016,
        "lng": 8.3149995803833
      }
    },
    {
      "codice": "090004",
      "nome": "Anela",
      "nomeStraniero": null,
      "codiceCatastale": "A287",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comune.anela.ss.it",
      "pec": "protocolloanela@legpec.it",
      "telefono": "+39 079 799046",
      "fax": "+39 079 799288",
      "coordinate": {
        "lat": 40.44444274902344,
        "lng": 9.058055877685547
      }
    },
    {
      "codice": "090005",
      "nome": "Ardara",
      "nomeStraniero": null,
      "codiceCatastale": "A379",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demorita@tiscali.it",
      "pec": "protocollo@comune.ardara.ss.it",
      "telefono": "+39 079400066",
      "fax": "+39 079400157",
      "coordinate": {
        "lat": 40.622222900390625,
        "lng": 8.8100004196167
      }
    },
    {
      "codice": "090006",
      "nome": "Arzachena",
      "nomeStraniero": null,
      "codiceCatastale": "A453",
      "cap": "07021",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "staffsindaco@comunearzachena.it",
      "pec": "protocollo@pec.comarzachena.it",
      "telefono": "+39 0789 849300",
      "fax": "+39 0789 849332",
      "coordinate": {
        "lat": 41.080142974853516,
        "lng": 9.387989044189453
      }
    },
    {
      "codice": "090081",
      "nome": "Badesi",
      "nomeStraniero": null,
      "codiceCatastale": "M214",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comunebadesi.ot.it",
      "pec": "protocollo.badesi@legalmail.it",
      "telefono": "+39 079 684025",
      "fax": "+39 079 684585",
      "coordinate": {
        "lat": 40.96555709838867,
        "lng": 8.883610725402832
      }
    },
    {
      "codice": "090007",
      "nome": "Banari",
      "nomeStraniero": null,
      "codiceCatastale": "A606",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comunebanari@tiscali.it",
      "pec": "protocollo@pec.comune.banari.ss.it",
      "telefono": "+39 079/826001",
      "fax": "+39 079/826233",
      "coordinate": {
        "lat": 40.57096862792969,
        "lng": 8.699026107788086
      }
    },
    {
      "codice": "090008",
      "nome": "Benetutti",
      "nomeStraniero": null,
      "codiceCatastale": "A781",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.benetutti.ss.it",
      "pec": "protocollo@pec.comune.benetutti.ss.it",
      "telefono": "+39 079 7979000",
      "fax": "+39 079 796323",
      "coordinate": {
        "lat": 40.4565315246582,
        "lng": 9.168283462524414
      }
    },
    {
      "codice": "090009",
      "nome": "Berchidda",
      "nomeStraniero": null,
      "codiceCatastale": "A789",
      "cap": "07022",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "amministrativo@comune.berchidda.ot.it",
      "pec": "protocollo@pec.comune.berchidda.ot.it",
      "telefono": "+39 079 7039000",
      "fax": "+39 079 7039040",
      "coordinate": {
        "lat": 40.785804748535156,
        "lng": 9.165773391723633
      }
    },
    {
      "codice": "090010",
      "nome": "Bessude",
      "nomeStraniero": null,
      "codiceCatastale": "A827",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.bessude.ss.it",
      "pec": "protocollo@pec.comune.bessude.ss.it",
      "telefono": "+39 079886157",
      "fax": "+39 079889943",
      "coordinate": {
        "lat": 40.55308532714844,
        "lng": 8.729796409606934
      }
    },
    {
      "codice": "090011",
      "nome": "Bonnanaro",
      "nomeStraniero": null,
      "codiceCatastale": "A976",
      "cap": "07043",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafestatocivile@comune.bonnanaro.ss.it",
      "pec": "protocollo@pec.comune.bonnanaro.ss.it",
      "telefono": "+39 079/845003",
      "fax": "+39 079/845403",
      "coordinate": {
        "lat": 40.533470153808594,
        "lng": 8.76211166381836
      }
    },
    {
      "codice": "090012",
      "nome": "Bono",
      "nomeStraniero": null,
      "codiceCatastale": "A977",
      "cap": "07011",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "combono@tiscali.it",
      "pec": "protocollo@pec.comune.bono.ss.it",
      "telefono": "+39 0797916900",
      "fax": "+39 079790116",
      "coordinate": {
        "lat": 40.415279388427734,
        "lng": 9.028611183166504
      }
    },
    {
      "codice": "090013",
      "nome": "Bonorva",
      "nomeStraniero": null,
      "codiceCatastale": "A978",
      "cap": "07012",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comune.bonorva.ss.it",
      "pec": "protocollo.bonorva@pec.comunas.it",
      "telefono": "+39 079/867894",
      "fax": "+39 079866630",
      "coordinate": {
        "lat": 40.41666793823242,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "090014",
      "nome": "Bortigiadas",
      "nomeStraniero": null,
      "codiceCatastale": "B063",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comunebortigiadas.it",
      "pec": "comune.bortigiadas@pec.it",
      "telefono": "+39 079 627014",
      "fax": "+39 079 627211",
      "coordinate": {
        "lat": 40.891666412353516,
        "lng": 9.042778015136719
      }
    },
    {
      "codice": "090015",
      "nome": "Borutta",
      "nomeStraniero": null,
      "codiceCatastale": "B064",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.borutta.ss.it",
      "pec": "protocollo@pec.comune.borutta.ss.it",
      "telefono": "+39 079824025",
      "fax": "+39 079 824164",
      "coordinate": {
        "lat": 40.522220611572266,
        "lng": 8.743611335754395
      }
    },
    {
      "codice": "090016",
      "nome": "Bottidda",
      "nomeStraniero": null,
      "codiceCatastale": "B094",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comune.bottidda.ss.it",
      "pec": "protocollo.bottidda@pec.comunas.it",
      "telefono": "+39 079 793512",
      "fax": "+39 079 793575",
      "coordinate": {
        "lat": 40.3922233581543,
        "lng": 9.008889198303223
      }
    },
    {
      "codice": "090017",
      "nome": "Buddusò",
      "nomeStraniero": null,
      "codiceCatastale": "B246",
      "cap": "07020",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comunedibudduso.it",
      "pec": "segreteria@pec.comune.budduso.ot.it",
      "telefono": "+39 079 7159000",
      "fax": "+39 079 714371",
      "coordinate": {
        "lat": 40.57823944091797,
        "lng": 9.25821304321289
      }
    },
    {
      "codice": "090091",
      "nome": "Budoni",
      "nomeStraniero": null,
      "codiceCatastale": "B248",
      "cap": "07051",
      "prefisso": "0784",
      "provincia": "Sassari",
      "email": "info@comune.budoni.ot.it",
      "pec": "protocollo@pec.comune.budoni.ot.it",
      "telefono": "+39 0784 844007",
      "fax": "+39 0784 844420",
      "coordinate": {
        "lat": 40.70380783081055,
        "lng": 9.707107543945312
      }
    },
    {
      "codice": "090018",
      "nome": "Bultei",
      "nomeStraniero": null,
      "codiceCatastale": "B264",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.bultei.ss.it",
      "pec": "comunebultei@legpec.it",
      "telefono": "+39 0795627100/092",
      "fax": "+39 079795852",
      "coordinate": {
        "lat": 40.45750045776367,
        "lng": 9.061111450195312
      }
    },
    {
      "codice": "090019",
      "nome": "Bulzi",
      "nomeStraniero": null,
      "codiceCatastale": "B265",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "amministrativo@comune.bulzi.ss.it",
      "pec": "protocollo@pec.comune.bulzi.ss.it",
      "telefono": "+39 079.588845",
      "fax": "+39 079-588343",
      "coordinate": {
        "lat": 40.84694290161133,
        "lng": 8.830277442932129
      }
    },
    {
      "codice": "090020",
      "nome": "Burgos",
      "nomeStraniero": null,
      "codiceCatastale": "B276",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comuneburgos.it",
      "pec": "protocollo.@pec.comuneburgos.it",
      "telefono": "+39 079/79793505",
      "fax": "+39 079/793004",
      "coordinate": {
        "lat": 40.391387939453125,
        "lng": 8.994444847106934
      }
    },
    {
      "codice": "090021",
      "nome": "Calangianus",
      "nomeStraniero": null,
      "codiceCatastale": "B378",
      "cap": "07023",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.calangianus.ot.it",
      "pec": "protocollo.comune.calangianus@pec.it",
      "telefono": "+39 0796600217",
      "fax": "+39 0796600263",
      "coordinate": {
        "lat": 40.92222213745117,
        "lng": 9.194722175598145
      }
    },
    {
      "codice": "090022",
      "nome": "Cargeghe",
      "nomeStraniero": null,
      "codiceCatastale": "B772",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comune.cargeghe@tiscali.it",
      "pec": "affarigenerali.cargeghe@pec.comunas.it",
      "telefono": "+39 079/3440110",
      "fax": "+39 079/345001",
      "coordinate": {
        "lat": 40.668888092041016,
        "lng": 8.61472225189209
      }
    },
    {
      "codice": "090023",
      "nome": "Castelsardo",
      "nomeStraniero": null,
      "codiceCatastale": "C272",
      "cap": "07031",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "servizidemografici2@comune.castelsardo.ss.it",
      "pec": "protocollo@pec.comune.castelsardo.ss.it",
      "telefono": "+39 0794780923",
      "fax": "+39 0794780921",
      "coordinate": {
        "lat": 40.91444396972656,
        "lng": 8.712778091430664
      }
    },
    {
      "codice": "090024",
      "nome": "Cheremule",
      "nomeStraniero": null,
      "codiceCatastale": "C600",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comune.cheremule@tiscali.it",
      "pec": "protocollo.cheremule@legalmail.it",
      "telefono": "+39 079 889010",
      "fax": "+39 079 889365",
      "coordinate": {
        "lat": 40.50555419921875,
        "lng": 8.724721908569336
      }
    },
    {
      "codice": "090025",
      "nome": "Chiaramonti",
      "nomeStraniero": null,
      "codiceCatastale": "C613",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.chiaramonti.ss.it",
      "pec": "protocollo@pec.comune.chiaramonti.ss.it",
      "telefono": "+39 079 569092",
      "fax": "+39 079569631",
      "coordinate": {
        "lat": 40.74944305419922,
        "lng": 8.818611145019531
      }
    },
    {
      "codice": "090026",
      "nome": "Codrongianos",
      "nomeStraniero": null,
      "codiceCatastale": "C818",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comunecodrongianos.it",
      "pec": "protocollo.codrongianos@legalmail.it",
      "telefono": "+39 079/435015",
      "fax": "+39 079/434039",
      "coordinate": {
        "lat": 40.657501220703125,
        "lng": 8.681388854980469
      }
    },
    {
      "codice": "090027",
      "nome": "Cossoine",
      "nomeStraniero": null,
      "codiceCatastale": "D100",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.cossoine.ss.it",
      "pec": "protocollo@pec.comune.cossoine.ss.it",
      "telefono": "+39 079 861050",
      "fax": "+39 079 861036",
      "coordinate": {
        "lat": 40.430419921875,
        "lng": 8.715505599975586
      }
    },
    {
      "codice": "090088",
      "nome": "Erula",
      "nomeStraniero": null,
      "codiceCatastale": "M292",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comunedierula@libero.it",
      "pec": "protocollo@pec.comunedierula.it",
      "telefono": "+39 079575363",
      "fax": "+39 079575398",
      "coordinate": {
        "lat": 40.792240142822266,
        "lng": 8.943815231323242
      }
    },
    {
      "codice": "090028",
      "nome": "Esporlatu",
      "nomeStraniero": null,
      "codiceCatastale": "D441",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.esporlatu.ss.it",
      "pec": "protocollo.esporlatu@pec.comunas.it",
      "telefono": "+39 079793538",
      "fax": "+39 079793784",
      "coordinate": {
        "lat": 40.385555267333984,
        "lng": 8.989999771118164
      }
    },
    {
      "codice": "090029",
      "nome": "Florinas",
      "nomeStraniero": null,
      "codiceCatastale": "D637",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comune.florinas.ss.it",
      "pec": "protocollo.florinas@pec.it",
      "telefono": "+39 0795621127",
      "fax": "+39 079438434",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "090030",
      "nome": "Giave",
      "nomeStraniero": null,
      "codiceCatastale": "E019",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "ssgiave@tiscali.it",
      "pec": "protocollo@pec.comune.giave.ss.it",
      "telefono": "+39 079869050",
      "fax": "+39 079869255",
      "coordinate": {
        "lat": 40.45194625854492,
        "lng": 8.752222061157227
      }
    },
    {
      "codice": "090083",
      "nome": "Golfo Aranci",
      "nomeStraniero": null,
      "codiceCatastale": "M274",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "info@comune.golfoaranci.ot.it",
      "pec": "comune.golfoaranci@legalmail.it",
      "telefono": "+39 0789 612900",
      "fax": "+39 0789 612914",
      "coordinate": {
        "lat": 41.00383758544922,
        "lng": 9.614882469177246
      }
    },
    {
      "codice": "090031",
      "nome": "Illorai",
      "nomeStraniero": null,
      "codiceCatastale": "E285",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.illorai.ss.it",
      "pec": "demografici@pec.comune.illorai.ss.it",
      "telefono": "+39 079 792407",
      "fax": "+39 079 792656",
      "coordinate": {
        "lat": 40.35361099243164,
        "lng": 8.999722480773926
      }
    },
    {
      "codice": "090032",
      "nome": "Ittireddu",
      "nomeStraniero": null,
      "codiceCatastale": "E376",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "segretaria.ittireddu@tiscali.it",
      "pec": "segreteria@pec.comune.ittireddu.ss.it",
      "telefono": "+39 079767623",
      "fax": "+39 079767669",
      "coordinate": {
        "lat": 40.54436492919922,
        "lng": 8.902144432067871
      }
    },
    {
      "codice": "090033",
      "nome": "Ittiri",
      "nomeStraniero": null,
      "codiceCatastale": "E377",
      "cap": "07044",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "francesco.carboni@comune.ittiri.ss.it",
      "pec": "servizidemografici@pec.comune.ittiri.ss.it",
      "telefono": "+39 079445211",
      "fax": null,
      "coordinate": {
        "lat": 40.59075164794922,
        "lng": 8.569507598876953
      }
    },
    {
      "codice": "090035",
      "nome": "La Maddalena",
      "nomeStraniero": null,
      "codiceCatastale": "E425",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "sindaco@comunelamaddalena.it",
      "pec": "staff@pec.comunelamaddalena.it",
      "telefono": "+39 0789 790700",
      "fax": "+39 0789 790609",
      "coordinate": {
        "lat": 41.21424102783203,
        "lng": 9.408329963684082
      }
    },
    {
      "codice": "090034",
      "nome": "Laerru",
      "nomeStraniero": null,
      "codiceCatastale": "E401",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "com.laerru@tiscali.it",
      "pec": "protocollo@pec.comunelaerru.ss.it",
      "telefono": "+39 079/570013",
      "fax": "+39 079/570300",
      "coordinate": {
        "lat": 40.817222595214844,
        "lng": 8.835277557373047
      }
    },
    {
      "codice": "090084",
      "nome": "Loiri Porto San Paolo",
      "nomeStraniero": null,
      "codiceCatastale": "M275",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "m.giagheddu@comune.loiriportosanpaolo.ss.it",
      "pec": "protocollo.loiriportosanpaolo@legalmail.it",
      "telefono": "+39 0789 481105",
      "fax": "+39 0789 41016",
      "coordinate": {
        "lat": 40.843894958496094,
        "lng": 9.495857238769531
      }
    },
    {
      "codice": "090036",
      "nome": "Luogosanto",
      "nomeStraniero": null,
      "codiceCatastale": "E747",
      "cap": "07020",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comuneluogosanto.it",
      "pec": "protocollo.luogosanto@pec.comunas.it",
      "telefono": "+39 07965790",
      "fax": "+39 079652399",
      "coordinate": {
        "lat": 41.050262451171875,
        "lng": 9.205985069274902
      }
    },
    {
      "codice": "090037",
      "nome": "Luras",
      "nomeStraniero": null,
      "codiceCatastale": "E752",
      "cap": "07025",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comune.luras@tiscali.it",
      "pec": "protocollo@pec.comune.luras.ss.it",
      "telefono": "+39 079 645200",
      "fax": "+39 079 647210",
      "coordinate": {
        "lat": 40.93583297729492,
        "lng": 9.174722671508789
      }
    },
    {
      "codice": "090038",
      "nome": "Mara",
      "nomeStraniero": null,
      "codiceCatastale": "E902",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comune.mara.ss.it",
      "pec": "protocollo@pec.comune.mara.ss.it",
      "telefono": "+39 079/805068",
      "fax": "+39 079/805320",
      "coordinate": {
        "lat": 40.4099006652832,
        "lng": 8.636970520019531
      }
    },
    {
      "codice": "090039",
      "nome": "Martis",
      "nomeStraniero": null,
      "codiceCatastale": "E992",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comune.martis.ss.it",
      "pec": "sindaco.martis@pec.it",
      "telefono": "+39 079/566129",
      "fax": "+39 079566125",
      "coordinate": {
        "lat": 40.7783317565918,
        "lng": 8.808610916137695
      }
    },
    {
      "codice": "090040",
      "nome": "Monteleone Rocca Doria",
      "nomeStraniero": null,
      "codiceCatastale": "F542",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "aagg@comune.monteleoneroccadoria.ss.it",
      "pec": "pla29@tiscali.it",
      "telefono": "3462858287",
      "fax": "+39 079 925124",
      "coordinate": {
        "lat": 40.4716682434082,
        "lng": 8.560555458068848
      }
    },
    {
      "codice": "090041",
      "nome": "Monti",
      "nomeStraniero": null,
      "codiceCatastale": "F667",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "protocollo@comune.monti.ss.it",
      "pec": "monti@pec.comune.monti.ss.it",
      "telefono": "+39 0789 478200",
      "fax": "+39 0789 44641",
      "coordinate": {
        "lat": 40.806922912597656,
        "lng": 9.326635360717773
      }
    },
    {
      "codice": "090042",
      "nome": "Mores",
      "nomeStraniero": null,
      "codiceCatastale": "F721",
      "cap": "07013",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.mores.ss.it",
      "pec": "protocollo@pec.comune.mores.ss.it",
      "telefono": "+39 079 7079000",
      "fax": "+39 079 7079045",
      "coordinate": {
        "lat": 40.54805374145508,
        "lng": 8.832777976989746
      }
    },
    {
      "codice": "090043",
      "nome": "Muros",
      "nomeStraniero": null,
      "codiceCatastale": "F818",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comune.muros.ss.it",
      "pec": "protocollo@pec.comune.muros.ss.it",
      "telefono": "+39 0793440041",
      "fax": "+39 0793440039",
      "coordinate": {
        "lat": 40.678611755371094,
        "lng": 8.617500305175781
      }
    },
    {
      "codice": "090044",
      "nome": "Nughedu San Nicolò",
      "nomeStraniero": null,
      "codiceCatastale": "F975",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comunenughedusn.it",
      "pec": "protocollo@pec.comune.nughedusannicolo.ss.it",
      "telefono": "+39 079 763042-3",
      "fax": "+39 079 763394",
      "coordinate": {
        "lat": 40.55616760253906,
        "lng": 9.021320343017578
      }
    },
    {
      "codice": "090045",
      "nome": "Nule",
      "nomeStraniero": null,
      "codiceCatastale": "F976",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "servizidemografici@comune.nule.ss.it",
      "pec": "servizigenerali@pec.comune.nule.ss.it",
      "telefono": "+39 079798025",
      "fax": "+39 079765128",
      "coordinate": {
        "lat": 40.461666107177734,
        "lng": 9.189167022705078
      }
    },
    {
      "codice": "090046",
      "nome": "Nulvi",
      "nomeStraniero": null,
      "codiceCatastale": "F977",
      "cap": "07032",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "affarigenerali@comune.nulvi.ss.it",
      "pec": "comune.nulvi@legalmail.it",
      "telefono": "+39 079/5779024",
      "fax": "+39 079/5779019",
      "coordinate": {
        "lat": 40.78388977050781,
        "lng": 8.743888854980469
      }
    },
    {
      "codice": "090047",
      "nome": "Olbia",
      "nomeStraniero": null,
      "codiceCatastale": "G015",
      "cap": "07026",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "staff.sindaco@comune.olbia.ot.it",
      "pec": "comune.olbia@actaliscertymail.it",
      "telefono": "+39 0789 52000",
      "fax": "+39 0789 52305",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 9.5
      }
    },
    {
      "codice": "090048",
      "nome": "Olmedo",
      "nomeStraniero": null,
      "codiceCatastale": "G046",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "area.amministrativa@comune.olmedo.ss.it",
      "pec": "protocollo@pec.comune.olmedo.ss.it",
      "telefono": "+39 0799019005",
      "fax": "+39 0799019008",
      "coordinate": {
        "lat": 40.6511116027832,
        "lng": 8.379722595214844
      }
    },
    {
      "codice": "090049",
      "nome": "Oschiri",
      "nomeStraniero": null,
      "codiceCatastale": "G153",
      "cap": "07027",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": null,
      "pec": "servizielettorali@pec.comune.oschiri.ss.it",
      "telefono": "+39 079 7349100",
      "fax": null,
      "coordinate": {
        "lat": 40.71902847290039,
        "lng": 9.101486206054688
      }
    },
    {
      "codice": "090050",
      "nome": "Osilo",
      "nomeStraniero": null,
      "codiceCatastale": "G156",
      "cap": "07033",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.osilo.ss.it",
      "pec": "protocollo@pec.comune.osilo.ss.it",
      "telefono": "+39 079 3242000",
      "fax": "+39 079 324245",
      "coordinate": {
        "lat": 40.74361038208008,
        "lng": 8.671111106872559
      }
    },
    {
      "codice": "090051",
      "nome": "Ossi",
      "nomeStraniero": null,
      "codiceCatastale": "G178",
      "cap": "07045",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "elettoraleossi@tiscalinet.it",
      "pec": "protocollo@pec.comuneossi.it",
      "telefono": "+39 079/3403104",
      "fax": "+39 079/3403139",
      "coordinate": {
        "lat": 40.6746826171875,
        "lng": 8.592166900634766
      }
    },
    {
      "codice": "090052",
      "nome": "Ozieri",
      "nomeStraniero": null,
      "codiceCatastale": "G203",
      "cap": "07014",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "segretario@comune.ozieri.ss.it",
      "pec": "protocollo@pec.comune.ozieri.ss.it",
      "telefono": "+39 079/781243",
      "fax": null,
      "coordinate": {
        "lat": 40.584869384765625,
        "lng": 9.00328540802002
      }
    },
    {
      "codice": "090053",
      "nome": "Padria",
      "nomeStraniero": null,
      "codiceCatastale": "G225",
      "cap": "07015",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "amministrativo@comune.padria.ss.it",
      "pec": "protocollo@pec.comune.padria.ss.it",
      "telefono": "+39 079/807018",
      "fax": "+39 079/807323",
      "coordinate": {
        "lat": 40.39545822143555,
        "lng": 8.630146980285645
      }
    },
    {
      "codice": "090090",
      "nome": "Padru",
      "nomeStraniero": null,
      "codiceCatastale": "M301",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "amministrazione@comune.padru.ot.it",
      "pec": "protocollo@pec.comune.padru.ss.it",
      "telefono": "+39 0789 454017",
      "fax": "+39 0789 45809",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "090054",
      "nome": "Palau",
      "nomeStraniero": null,
      "codiceCatastale": "G258",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "info@palau.it",
      "pec": "protocollo@pec.palau.it",
      "telefono": "+39 0789.770801",
      "fax": "+39 0789 770810",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "090055",
      "nome": "Pattada",
      "nomeStraniero": null,
      "codiceCatastale": "G376",
      "cap": "07016",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "segreteria@comune.pattada.ss",
      "pec": "protocollo.pattada@pec.comunas.it",
      "telefono": "+39 079755114",
      "fax": "+39 079754069",
      "coordinate": {
        "lat": 40.58222198486328,
        "lng": 9.109999656677246
      }
    },
    {
      "codice": "090056",
      "nome": "Perfugas",
      "nomeStraniero": null,
      "codiceCatastale": "G450",
      "cap": "07034",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comuneperfugas.it",
      "pec": "protocollo@pec.comuneperfugas.it",
      "telefono": "+39 079/5639100",
      "fax": "+39 079/5639131",
      "coordinate": {
        "lat": 40.83222198486328,
        "lng": 8.884444236755371
      }
    },
    {
      "codice": "090057",
      "nome": "Ploaghe",
      "nomeStraniero": null,
      "codiceCatastale": "G740",
      "cap": "07017",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "paola.sini@comune.ploaghe.ss.it",
      "pec": "comunediploaghe@pec.comune.ploaghe.ss.it",
      "telefono": "+39 079/4479914",
      "fax": "+39 079/4479930",
      "coordinate": {
        "lat": 40.66444396972656,
        "lng": 8.745277404785156
      }
    },
    {
      "codice": "090058",
      "nome": "Porto Torres",
      "nomeStraniero": null,
      "codiceCatastale": "G924",
      "cap": "07046",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "elettorale@comune.porto-torres.ss.it",
      "pec": "comune@pec.comune.porto-torres.ss.it",
      "telefono": "+39 079/5008000",
      "fax": "+39 079/5008067",
      "coordinate": {
        "lat": 40.836944580078125,
        "lng": 8.401389122009277
      }
    },
    {
      "codice": "090059",
      "nome": "Pozzomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "G962",
      "cap": "07018",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "elettorale@comune.pozzomaggiore.ss.it",
      "pec": "protocollo@pec.comune.pozzomaggiore.ss.it",
      "telefono": "+39 079 801123",
      "fax": "+39 079 800155",
      "coordinate": {
        "lat": 40.39781188964844,
        "lng": 8.659801483154297
      }
    },
    {
      "codice": "090060",
      "nome": "Putifigari",
      "nomeStraniero": null,
      "codiceCatastale": "H095",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comune.putifigari@tiscali.it",
      "pec": "protocollo.putifigari@legalmail.it",
      "telefono": "+39 079 905023",
      "fax": "+39 079 905010",
      "coordinate": {
        "lat": 40.56151580810547,
        "lng": 8.46020221710205
      }
    },
    {
      "codice": "090061",
      "nome": "Romana",
      "nomeStraniero": null,
      "codiceCatastale": "H507",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "amministrativo@comune.romana.ss.it",
      "pec": "protocollo@pec.comune.romana.ss.it",
      "telefono": "+39 079/92.50.13",
      "fax": "+39 079/925241",
      "coordinate": {
        "lat": 40.48403549194336,
        "lng": 8.586158752441406
      }
    },
    {
      "codice": "090092",
      "nome": "San Teodoro",
      "nomeStraniero": null,
      "codiceCatastale": "I329",
      "cap": "07052",
      "prefisso": "0784",
      "provincia": "Sassari",
      "email": "info@comunesanteodoro.gov.it",
      "pec": "protocollo@pec.comunesanteodoro.it",
      "telefono": "+39 0784 8600",
      "fax": "+39 0784 865192",
      "coordinate": {
        "lat": 40.766666412353516,
        "lng": 9.666666984558105
      }
    },
    {
      "codice": "090085",
      "nome": "Sant'Antonio di Gallura",
      "nomeStraniero": null,
      "codiceCatastale": "M276",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "sindaco@comune.santantoniodigallura.ot.it",
      "pec": "protocollo.santantoniodigallura@pec.comunas.it",
      "telefono": "+39 079 669013",
      "fax": "+39 079 669388",
      "coordinate": {
        "lat": 40.99195098876953,
        "lng": 9.301729202270508
      }
    },
    {
      "codice": "090087",
      "nome": "Santa Maria Coghinas",
      "nomeStraniero": null,
      "codiceCatastale": "M284",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demo.smcoghinas@virgilio.it",
      "pec": "protocollo@pec.comunesmcoghinas.it",
      "telefono": "+39 0795851421",
      "fax": "+39 079/5851441",
      "coordinate": {
        "lat": 40.90388870239258,
        "lng": 8.868332862854004
      }
    },
    {
      "codice": "090063",
      "nome": "Santa Teresa Gallura",
      "nomeStraniero": null,
      "codiceCatastale": "I312",
      "cap": "07028",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "info@comunestg.it",
      "pec": "protocollo@pec.comunestg.it",
      "telefono": "+39 0789 740900",
      "fax": "+39 0789 754794",
      "coordinate": {
        "lat": 41.239158630371094,
        "lng": 9.188776016235352
      }
    },
    {
      "codice": "090064",
      "nome": "Sassari",
      "nomeStraniero": null,
      "codiceCatastale": "I452",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "info@comune.sassari.it",
      "pec": "protocollo@pec.comune.sassari.it",
      "telefono": "+39 079279111",
      "fax": "+39 079279340",
      "coordinate": {
        "lat": 40.72666549682617,
        "lng": 8.55916690826416
      }
    },
    {
      "codice": "090065",
      "nome": "Sedini",
      "nomeStraniero": null,
      "codiceCatastale": "I565",
      "cap": "07035",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.sedini.ss.it",
      "pec": "anagrafe@pec.comune.sedini.ss.it",
      "telefono": "+39 079 58 92 01",
      "fax": "+39 079 58 92 50",
      "coordinate": {
        "lat": 40.85222244262695,
        "lng": 8.816389083862305
      }
    },
    {
      "codice": "090066",
      "nome": "Semestene",
      "nomeStraniero": null,
      "codiceCatastale": "I598",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.semestene.ss.it",
      "pec": "protocollo@pec.comune.semestene.ss.it",
      "telefono": "+39 079/867873",
      "fax": "+39 079/866510",
      "coordinate": {
        "lat": 40.398658752441406,
        "lng": 8.725419044494629
      }
    },
    {
      "codice": "090067",
      "nome": "Sennori",
      "nomeStraniero": null,
      "codiceCatastale": "I614",
      "cap": "07036",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo.sennori@pec.comunas.it",
      "pec": "protocollo.sennori@pec.comunas.it",
      "telefono": "+39 0793049203",
      "fax": "+39 0793049245",
      "coordinate": {
        "lat": 40.7899169921875,
        "lng": 8.595191955566406
      }
    },
    {
      "codice": "090068",
      "nome": "Siligo",
      "nomeStraniero": null,
      "codiceCatastale": "I732",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "servizigenerali@comunesiligo.it",
      "pec": "protocollo@pec.comunesiligo.it",
      "telefono": "+39 079/836003",
      "fax": "+39 079/836389",
      "coordinate": {
        "lat": 40.57524871826172,
        "lng": 8.727389335632324
      }
    },
    {
      "codice": "090069",
      "nome": "Sorso",
      "nomeStraniero": null,
      "codiceCatastale": "I863",
      "cap": "07037",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "polo.antonio@comune.sorso.ss.it",
      "pec": "elettorale@pec.comune.sorso.ss.it",
      "telefono": "+39 0793391205",
      "fax": null,
      "coordinate": {
        "lat": 40.79833221435547,
        "lng": 8.577221870422363
      }
    },
    {
      "codice": "090089",
      "nome": "Stintino",
      "nomeStraniero": null,
      "codiceCatastale": "M290",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "apilo@comunestintino.it",
      "pec": "protocollo@pec.comune.stintino.ss.it",
      "telefono": "+39 079 523508",
      "fax": "+39 079 ? 523628",
      "coordinate": {
        "lat": 40.93779754638672,
        "lng": 8.227643966674805
      }
    },
    {
      "codice": "090080",
      "nome": "Telti",
      "nomeStraniero": null,
      "codiceCatastale": "L088",
      "cap": "07020",
      "prefisso": "0789",
      "provincia": "Sassari",
      "email": "segretario@comune.telti.ot.it",
      "pec": "protocollo.telti@pec.comunas.it",
      "telefono": "+39 0789 43007",
      "fax": "+39 0789 43580",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "090070",
      "nome": "Tempio Pausania",
      "nomeStraniero": null,
      "codiceCatastale": "L093",
      "cap": "07029",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "segreteria@comunetempio.it",
      "pec": "affariistituziomnali@postatempio.net",
      "telefono": "+39 079 679999",
      "fax": "+39 079 631859",
      "coordinate": {
        "lat": 40.9014778137207,
        "lng": 9.104361534118652
      }
    },
    {
      "codice": "090086",
      "nome": "Tergu",
      "nomeStraniero": null,
      "codiceCatastale": "M282",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comuneditergu.it",
      "pec": "protocollo@pec.comuneditergu.it",
      "telefono": "+39 079 476070",
      "fax": "+39 079 476131",
      "coordinate": {
        "lat": 40.864723205566406,
        "lng": 8.712499618530273
      }
    },
    {
      "codice": "090071",
      "nome": "Thiesi",
      "nomeStraniero": null,
      "codiceCatastale": "L158",
      "cap": "07047",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "anagrafe@comunethiesi.it",
      "pec": "protocollo@pec.comunethiesi.it",
      "telefono": "+39 079 885422",
      "fax": "+39 079 889199",
      "coordinate": {
        "lat": 40.525001525878906,
        "lng": 8.716388702392578
      }
    },
    {
      "codice": "090072",
      "nome": "Tissi",
      "nomeStraniero": null,
      "codiceCatastale": "L180",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "demografici@comune.tissi.ss.it",
      "pec": "protocollo@pec.comune.tissi.ss.it",
      "telefono": "+39 079/3888010",
      "fax": null,
      "coordinate": {
        "lat": 40.67777633666992,
        "lng": 8.5625
      }
    },
    {
      "codice": "090073",
      "nome": "Torralba",
      "nomeStraniero": null,
      "codiceCatastale": "L235",
      "cap": "07048",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "amministrativo@comune.torralba.ss.it",
      "pec": "comune.torralba@actaliscertymail.it",
      "telefono": "+39 079/847010",
      "fax": "+39 079/847085",
      "coordinate": {
        "lat": 40.51347351074219,
        "lng": 8.76485824584961
      }
    },
    {
      "codice": "090074",
      "nome": "Trinità d'Agultu e Vignola",
      "nomeStraniero": null,
      "codiceCatastale": "L428",
      "cap": "07038",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comuneditrinita.it",
      "pec": "protocollo.trinitadagultu@legalmail.it",
      "telefono": "+39 079-6109990",
      "fax": "+39 079-681480",
      "coordinate": {
        "lat": 40.98574447631836,
        "lng": 8.914182662963867
      }
    },
    {
      "codice": "090075",
      "nome": "Tula",
      "nomeStraniero": null,
      "codiceCatastale": "L464",
      "cap": "07010",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.tula.ss.it",
      "pec": "protocollo@pec.comune.tula.ss.it",
      "telefono": "+39 079 7189030",
      "fax": "+39 079 7189040",
      "coordinate": {
        "lat": 40.73249816894531,
        "lng": 8.984722137451172
      }
    },
    {
      "codice": "090076",
      "nome": "Uri",
      "nomeStraniero": null,
      "codiceCatastale": "L503",
      "cap": "07040",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "protocollo@comune.uri.ss.it",
      "pec": "protocollo.uri@pec.comunas.it",
      "telefono": "+39 079 418701",
      "fax": "+39 079 4187077",
      "coordinate": {
        "lat": 40.63888931274414,
        "lng": 8.488055229187012
      }
    },
    {
      "codice": "090077",
      "nome": "Usini",
      "nomeStraniero": null,
      "codiceCatastale": "L509",
      "cap": "07049",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "comunediusini@cert.legalmail.it",
      "pec": "comunediusini@cert.legalmail.it",
      "telefono": "+39 079/3817009",
      "fax": "+39 079/380699",
      "coordinate": {
        "lat": 40.663333892822266,
        "lng": 8.540833473205566
      }
    },
    {
      "codice": "090079",
      "nome": "Valledoria",
      "nomeStraniero": null,
      "codiceCatastale": "L604",
      "cap": "07039",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "amministrativo@comune.valledoria.ss.it",
      "pec": "protocollo@pec.comune.valledoria.ss.it",
      "telefono": "+39 079 5810016",
      "fax": "+39 079 582725",
      "coordinate": {
        "lat": 40.92816925048828,
        "lng": 8.824596405029297
      }
    },
    {
      "codice": "090082",
      "nome": "Viddalba",
      "nomeStraniero": null,
      "codiceCatastale": "M259",
      "cap": "07030",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "servizidemoviddalba@tiscali.it",
      "pec": "comune.viddalba@legalmail.it",
      "telefono": "+39 0795808051",
      "fax": "+39 0795808022",
      "coordinate": {
        "lat": 40.9160270690918,
        "lng": 8.894698143005371
      }
    },
    {
      "codice": "090078",
      "nome": "Villanova Monteleone",
      "nomeStraniero": null,
      "codiceCatastale": "L989",
      "cap": "07019",
      "prefisso": "079",
      "provincia": "Sassari",
      "email": "spanua.villanovam@tiscali.it",
      "pec": "comune.villanovamonteleone@halleycert.it",
      "telefono": "+39 079 960406",
      "fax": "+39 079 960736",
      "coordinate": {
        "lat": 40.50361251831055,
        "lng": 8.470555305480957
      }
    }
  ],
  "Enna": [
    {
      "codice": "086001",
      "nome": "Agira",
      "nomeStraniero": null,
      "codiceCatastale": "A070",
      "cap": "94011",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": null,
      "pec": "protocollo_comune_agira@legalmail.it",
      "telefono": "+39 0935 961111",
      "fax": "+39 0935 961226",
      "coordinate": {
        "lat": 37.650001525878906,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "086002",
      "nome": "Aidone",
      "nomeStraniero": null,
      "codiceCatastale": "A098",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "assessorato.innovazione@comune.aidone.en.it",
      "pec": "ufficio.elettorale@pec.aidoneonline.it",
      "telefono": "+39 0935 600511",
      "fax": "+39 0935 87477",
      "coordinate": {
        "lat": 37.41666793823242,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "086003",
      "nome": "Assoro",
      "nomeStraniero": null,
      "codiceCatastale": "A478",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "elettoraleassoro@tiscali.it",
      "pec": "info@pec.comune.assoro.en.it",
      "telefono": "+39 0935/610011",
      "fax": "+39 0935/620725",
      "coordinate": {
        "lat": 37.63333511352539,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "086004",
      "nome": "Barrafranca",
      "nomeStraniero": null,
      "codiceCatastale": "A676",
      "cap": "94012",
      "prefisso": "0934",
      "provincia": "Enna",
      "email": "protocollo@comune.barrafranca.en.it",
      "pec": "protocollo@pec.comune.barrafranca.en.it",
      "telefono": "+390934467504",
      "fax": null,
      "coordinate": {
        "lat": 37.36666488647461,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "086005",
      "nome": "Calascibetta",
      "nomeStraniero": null,
      "codiceCatastale": "B381",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "sindaco@comune.calascibetta.en.it",
      "pec": "protocollo@pec.comune.calascibetta.en.it",
      "telefono": "+39 0935/569111",
      "fax": "+39 0935/33426",
      "coordinate": {
        "lat": 37.58333206176758,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "086006",
      "nome": "Catenanuova",
      "nomeStraniero": null,
      "codiceCatastale": "C353",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "affarigenerali@comunecatenanuova.gov.it",
      "pec": "info@pec.comune.catenanuova.en.it",
      "telefono": "+39 0935 78286",
      "fax": "+39 0935.75068",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 14.683333396911621
      }
    },
    {
      "codice": "086007",
      "nome": "Centuripe",
      "nomeStraniero": null,
      "codiceCatastale": "C471",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "sindaco@comunecenturipe.gov.it",
      "pec": "comunecenturipe@pec.it",
      "telefono": "+39 0935 919411",
      "fax": "+39 0935 73158",
      "coordinate": {
        "lat": 37.62327575683594,
        "lng": 14.73948860168457
      }
    },
    {
      "codice": "086008",
      "nome": "Cerami",
      "nomeStraniero": null,
      "codiceCatastale": "C480",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "sindaco@comune.cerami.en.it",
      "pec": "protocollo.cerami@pec.it",
      "telefono": "+39 0935 939011",
      "fax": "+39 0935 939040",
      "coordinate": {
        "lat": 37.81666564941406,
        "lng": 14.5
      }
    },
    {
      "codice": "086009",
      "nome": "Enna",
      "nomeStraniero": null,
      "codiceCatastale": "C342",
      "cap": "94100",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "asaro@comune.enna.it",
      "pec": "protocollo@pec.comune.enna.it",
      "telefono": "+39 0935/40446",
      "fax": "+39 0935/504472",
      "coordinate": {
        "lat": 37.56666564941406,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "086010",
      "nome": "Gagliano Castelferrato",
      "nomeStraniero": null,
      "codiceCatastale": "D849",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "elettorale@comunegaglianocastelferrato.en.it",
      "pec": "protocollo@pec.comunegaglianocastelferrato.en.it",
      "telefono": "+39 0935699204",
      "fax": "+39 0935693293",
      "coordinate": {
        "lat": 37.71666717529297,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "086011",
      "nome": "Leonforte",
      "nomeStraniero": null,
      "codiceCatastale": "E536",
      "cap": "94013",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "anagrafe@comuneleonforte.it",
      "pec": "protocollo@pec.comune.leonforte.en.it",
      "telefono": "+39 0935 665100",
      "fax": "+39 0935 902688",
      "coordinate": {
        "lat": 37.650001525878906,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "086012",
      "nome": "Nicosia",
      "nomeStraniero": null,
      "codiceCatastale": "F892",
      "cap": "94014",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "demografici.elettoralestatistica@comune.nicosia.en.it",
      "pec": "protocollo@pec.comune.nicosia.en.it",
      "telefono": "+39 0935 672513",
      "fax": "+39 0935 672515",
      "coordinate": {
        "lat": 37.75,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "086013",
      "nome": "Nissoria",
      "nomeStraniero": null,
      "codiceCatastale": "F900",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "segreteria@comune.nissoria.en.it",
      "pec": "protocollo@pec.comune.nissoria.en.it",
      "telefono": "+39 0935/615031",
      "fax": "+39 0935/669880",
      "coordinate": {
        "lat": 37.650001525878906,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "086014",
      "nome": "Piazza Armerina",
      "nomeStraniero": null,
      "codiceCatastale": "G580",
      "cap": "94015",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "elettorale@comunepiazzaarmerina.it",
      "pec": "demografici@pec.comunepiazzaarmerina.it",
      "telefono": "+39 0935/982419",
      "fax": "+39 0935/682002",
      "coordinate": {
        "lat": 37.38333511352539,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "086015",
      "nome": "Pietraperzia",
      "nomeStraniero": null,
      "codiceCatastale": "G624",
      "cap": "94016",
      "prefisso": "0934",
      "provincia": "Enna",
      "email": "ced@comune.pietraperzia.en.it",
      "pec": "pietraperzia.ced@pec.it",
      "telefono": "+39 0934/403025",
      "fax": "+39 0934/403050",
      "coordinate": {
        "lat": 37.400001525878906,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "086016",
      "nome": "Regalbuto",
      "nomeStraniero": null,
      "codiceCatastale": "H221",
      "cap": "94017",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "sindaco@comune.regalbuto.en.it",
      "pec": "protocolloregalbuto@pec.it",
      "telefono": "+39 0935 71099",
      "fax": "+39 0935 71208",
      "coordinate": {
        "lat": 37.650001525878906,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "086017",
      "nome": "Sperlinga",
      "nomeStraniero": null,
      "codiceCatastale": "I891",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "segreteria@comune.sperlinga.en.it",
      "pec": "protocollo@pec.comune.sperlinga.en.it",
      "telefono": "+39 0935 643025",
      "fax": "+39 0935 643119",
      "coordinate": {
        "lat": 37.766666412353516,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "086018",
      "nome": "Troina",
      "nomeStraniero": null,
      "codiceCatastale": "L448",
      "cap": "94018",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "po.amata@comune.troina.en.it",
      "pec": "comunetroina@legalmail.it",
      "telefono": "+39 0935/937124",
      "fax": "+39 0935/656409",
      "coordinate": {
        "lat": 37.78333282470703,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "086019",
      "nome": "Valguarnera Caropepe",
      "nomeStraniero": null,
      "codiceCatastale": "L583",
      "cap": "94019",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "serv-demografici@comune.valguarnera.en.it",
      "pec": "comunevalguarnera@pec.it",
      "telefono": "+39 0935 955111",
      "fax": "+39 0935 956955",
      "coordinate": {
        "lat": 37.5,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "086020",
      "nome": "Villarosa",
      "nomeStraniero": null,
      "codiceCatastale": "M011",
      "cap": "94010",
      "prefisso": "0935",
      "provincia": "Enna",
      "email": "comunedivillarosa@virgilio.it",
      "pec": "comunedivillarosa@pec.it",
      "telefono": "+39 0935 31955",
      "fax": "+39 0935/32790",
      "coordinate": {
        "lat": 37.58333206176758,
        "lng": 14.166666984558105
      }
    }
  ],
  "Asti": [
    {
      "codice": "005001",
      "nome": "Agliano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A072",
      "cap": "14041",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.aglianoterme.at.it",
      "pec": "comune.aglianoterme.at@cert.legalmail.it",
      "telefono": "+39 0141954023",
      "fax": "+39 0141954564",
      "coordinate": {
        "lat": 44.79111099243164,
        "lng": 8.251388549804688
      }
    },
    {
      "codice": "005002",
      "nome": "Albugnano",
      "nomeStraniero": null,
      "codiceCatastale": "A173",
      "cap": "14020",
      "prefisso": "011",
      "provincia": "Asti",
      "email": "protocollo@comune.albugnano.at.it",
      "pec": "albugnano@cert.ruparpiemonte.it",
      "telefono": "+39 0119920601",
      "fax": "+39 0119920601",
      "coordinate": {
        "lat": 45.078609466552734,
        "lng": 7.972222328186035
      }
    },
    {
      "codice": "005003",
      "nome": "Antignano",
      "nomeStraniero": null,
      "codiceCatastale": "A312",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segreteria@comune.antignano.at.it",
      "pec": "antignano@cert.ruparpiemonte.it",
      "telefono": "+39 0141205123",
      "fax": "+39 0141205275",
      "coordinate": {
        "lat": 44.84505844116211,
        "lng": 8.13484001159668
      }
    },
    {
      "codice": "005004",
      "nome": "Aramengo",
      "nomeStraniero": null,
      "codiceCatastale": "A352",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.aramengo.at.it",
      "pec": "aramengo@cert.ruparpiemonte.it",
      "telefono": "+39 0141909129",
      "fax": "+39 0141909381",
      "coordinate": {
        "lat": 45.10166549682617,
        "lng": 8.001111030578613
      }
    },
    {
      "codice": "005005",
      "nome": "Asti",
      "nomeStraniero": null,
      "codiceCatastale": "A479",
      "cap": "14100",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "sindaco@comune.asti.it",
      "pec": "protocollo.comuneasti@pec.it",
      "telefono": "+39 0141399111",
      "fax": "+39 0141399250",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 8.206944465637207
      }
    },
    {
      "codice": "005006",
      "nome": "Azzano d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "A527",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "azzano@comune.azzanodasti.at.it",
      "pec": "comune.azzano.at@cert.legalmail.it",
      "telefono": "+39 0141297114",
      "fax": "+39 0141297907",
      "coordinate": {
        "lat": 44.87444305419922,
        "lng": 8.267777442932129
      }
    },
    {
      "codice": "005007",
      "nome": "Baldichieri d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "A588",
      "cap": "14011",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "ufficioamministrativo.baldichieri.dasti@ruparpiemonte.it",
      "pec": "baldichieri.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 014166006",
      "fax": "+39 014166125",
      "coordinate": {
        "lat": 44.906944274902344,
        "lng": 8.092778205871582
      }
    },
    {
      "codice": "005008",
      "nome": "Belveglio",
      "nomeStraniero": null,
      "codiceCatastale": "A770",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segreteria@comune.belveglio.at.it",
      "pec": "comune.belveglio.at@cert.legalmail.it",
      "telefono": "+39 0141959909",
      "fax": "+39 0141959870",
      "coordinate": {
        "lat": 44.830833435058594,
        "lng": 8.329444885253906
      }
    },
    {
      "codice": "005009",
      "nome": "Berzano di San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "A812",
      "cap": "14020",
      "prefisso": "011",
      "provincia": "Asti",
      "email": "berzano.di.san.pietro@ruparpiemonte.it",
      "pec": "berzano.di.san.pietro@cert.ruparpiemonte.it",
      "telefono": "+39 0119920610",
      "fax": "+39 0119920610",
      "coordinate": {
        "lat": 45.09583282470703,
        "lng": 7.953888893127441
      }
    },
    {
      "codice": "005010",
      "nome": "Bruno",
      "nomeStraniero": null,
      "codiceCatastale": "B221",
      "cap": "14046",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segreteria@comune.bruno.at.it",
      "pec": "bruno@cert.ruparpiemonte.it",
      "telefono": "+39 0141764124",
      "fax": "+39 0141764124",
      "coordinate": {
        "lat": 44.794166564941406,
        "lng": 8.441666603088379
      }
    },
    {
      "codice": "005011",
      "nome": "Bubbio",
      "nomeStraniero": null,
      "codiceCatastale": "B236",
      "cap": "14051",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "segretariocomunalebubbio@reteunitaria.piemonte.it",
      "pec": "protocollo.bubbio@cert.ruparpiemonte.it",
      "telefono": "+39 01448114",
      "fax": "+39 01448114",
      "coordinate": {
        "lat": 44.66444396972656,
        "lng": 8.296111106872559
      }
    },
    {
      "codice": "005012",
      "nome": "Buttigliera d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "B306",
      "cap": "14021",
      "prefisso": "011",
      "provincia": "Asti",
      "email": "anagrafe@comune.buttigliera.at.it",
      "pec": "buttigliera.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 011 9921812",
      "fax": "+39 0119921300",
      "coordinate": {
        "lat": 45.02277755737305,
        "lng": 7.9522223472595215
      }
    },
    {
      "codice": "005013",
      "nome": "Calamandrana",
      "nomeStraniero": null,
      "codiceCatastale": "B376",
      "cap": "14042",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe@comunecalamandrana.it",
      "pec": "anagrafe@pec.comunecalamandrana.it",
      "telefono": "+39 014175114",
      "fax": "+39 014175570",
      "coordinate": {
        "lat": 44.73805618286133,
        "lng": 8.339722633361816
      }
    },
    {
      "codice": "005014",
      "nome": "Calliano",
      "nomeStraniero": null,
      "codiceCatastale": "B418",
      "cap": "14031",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.calliano.at@cert.legalmail.it",
      "pec": "comune.calliano.at@cert.legalmail.it",
      "telefono": "+39 0141928150",
      "fax": "+39 0141928479",
      "coordinate": {
        "lat": 45.00944519042969,
        "lng": 8.258610725402832
      }
    },
    {
      "codice": "005015",
      "nome": "Calosso",
      "nomeStraniero": null,
      "codiceCatastale": "B425",
      "cap": "14052",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "calosso@ruparpiemonte.it",
      "pec": "calosso@cert.ruparpiemonte.it",
      "telefono": "+39 0141853126",
      "fax": "+39 0141853126",
      "coordinate": {
        "lat": 44.7408332824707,
        "lng": 8.228333473205566
      }
    },
    {
      "codice": "005016",
      "nome": "Camerano Casasco",
      "nomeStraniero": null,
      "codiceCatastale": "B469",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "camerano.casasco@ruparpiemonte.it",
      "pec": "camerano.casasco@cert.ruparpiemonte.it",
      "telefono": "+39 0141992153",
      "fax": "+39 0141992153",
      "coordinate": {
        "lat": 44.99250030517578,
        "lng": 8.092222213745117
      }
    },
    {
      "codice": "005017",
      "nome": "Canelli",
      "nomeStraniero": null,
      "codiceCatastale": "B594",
      "cap": "14053",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "sindaco@comune.canelli.at.it",
      "pec": "comune.canelli@pec.it",
      "telefono": "+39 0141820111",
      "fax": "+39 0141820207",
      "coordinate": {
        "lat": 44.72084045410156,
        "lng": 8.29281997680664
      }
    },
    {
      "codice": "005018",
      "nome": "Cantarana",
      "nomeStraniero": null,
      "codiceCatastale": "B633",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.cantarana.at.it",
      "pec": "comunecantarana@pec.it",
      "telefono": "+39 0141943101",
      "fax": "+39 0141942877",
      "coordinate": {
        "lat": 44.9033317565918,
        "lng": 8.028611183166504
      }
    },
    {
      "codice": "005019",
      "nome": "Capriglio",
      "nomeStraniero": null,
      "codiceCatastale": "B707",
      "cap": "14014",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.capriglio.at.it",
      "pec": "capriglio@cert.ruparpiemonte.it",
      "telefono": "+39 0141997194",
      "fax": "+39 0141997194",
      "coordinate": {
        "lat": 45.00413513183594,
        "lng": 8.007990837097168
      }
    },
    {
      "codice": "005020",
      "nome": "Casorzo Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "B991",
      "cap": "14032",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.casorzo.at.it",
      "pec": "casorzo@cert.ruparpiemonte.it",
      "telefono": "+39 0141929225",
      "fax": "+39 0141929469",
      "coordinate": {
        "lat": 45.02333450317383,
        "lng": 8.338055610656738
      }
    },
    {
      "codice": "005021",
      "nome": "Cassinasco",
      "nomeStraniero": null,
      "codiceCatastale": "C022",
      "cap": "14050",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.cassinasco.at.it",
      "pec": "cassinasco@cert.ruparpiemonte.it",
      "telefono": "+39 0141851110",
      "fax": "+39 0141851277",
      "coordinate": {
        "lat": 44.689998626708984,
        "lng": 8.303610801696777
      }
    },
    {
      "codice": "005022",
      "nome": "Castagnole delle Lanze",
      "nomeStraniero": null,
      "codiceCatastale": "C049",
      "cap": "14054",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "castagnole.delle.lanze@reteunitaria.piemonte.it",
      "pec": "comune.castagnoledellelanze@professionalpec.it",
      "telefono": "+39 0141875600",
      "fax": "+39 0141875643",
      "coordinate": {
        "lat": 44.75222396850586,
        "lng": 8.151389122009277
      }
    },
    {
      "codice": "005023",
      "nome": "Castagnole Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "C047",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "castagnole.monferrato@reteunitaria.piemonte.it",
      "pec": "anagrafe.castagnolemonferrato@pec.it",
      "telefono": "+39 0141292123",
      "fax": "+39 0141292421",
      "coordinate": {
        "lat": 44.959999084472656,
        "lng": 8.30555534362793
      }
    },
    {
      "codice": "005024",
      "nome": "Castel Boglione",
      "nomeStraniero": null,
      "codiceCatastale": "C064",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.castelboglione.at.it",
      "pec": "protocollo.castel.boglione@cert.ruparpiemonte.it",
      "telefono": "+39 0141762368",
      "fax": null,
      "coordinate": {
        "lat": 44.7227783203125,
        "lng": 8.381111145019531
      }
    },
    {
      "codice": "005032",
      "nome": "Castel Rocchero",
      "nomeStraniero": null,
      "codiceCatastale": "C253",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "castel.rocchero@comune.castelrocchero.at.it",
      "pec": "castel.rocchero@cert.ruparpiemonte.it",
      "telefono": "+39 0141760132",
      "fax": null,
      "coordinate": {
        "lat": 44.71916580200195,
        "lng": 8.416388511657715
      }
    },
    {
      "codice": "005025",
      "nome": "Castell'Alfero",
      "nomeStraniero": null,
      "codiceCatastale": "C127",
      "cap": "14033",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "demografici@comune.castellalfero.at.it",
      "pec": "castellalfero@cert.ruparpiemonte.it",
      "telefono": "+39 0141406621",
      "fax": "+39 0141406606",
      "coordinate": {
        "lat": 44.98222351074219,
        "lng": 8.21166706085205
      }
    },
    {
      "codice": "005026",
      "nome": "Castellero",
      "nomeStraniero": null,
      "codiceCatastale": "C154",
      "cap": "14011",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "castellero@cst.asti.it",
      "pec": "castellero@cert.ruparpiemonte.it",
      "telefono": "+39 0141669180",
      "fax": "+39 0141669180",
      "coordinate": {
        "lat": 44.924339294433594,
        "lng": 8.074543952941895
      }
    },
    {
      "codice": "005027",
      "nome": "Castelletto Molina",
      "nomeStraniero": null,
      "codiceCatastale": "C161",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.castellettomolina.at.it",
      "pec": "castelletto.molina@cert.ruparpiemonte.it",
      "telefono": "+39 0141739198",
      "fax": "+39 0141739198",
      "coordinate": {
        "lat": 44.75222396850586,
        "lng": 8.43416690826416
      }
    },
    {
      "codice": "005028",
      "nome": "Castello di Annone",
      "nomeStraniero": null,
      "codiceCatastale": "A300",
      "cap": "14034",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.castellodiannone.at.it",
      "pec": "castello.di.annone@cert.ruparpiemonte.it",
      "telefono": "+39 0141401113",
      "fax": "+39 0141401895",
      "coordinate": {
        "lat": 44.87888717651367,
        "lng": 8.318333625793457
      }
    },
    {
      "codice": "005029",
      "nome": "Castelnuovo Belbo",
      "nomeStraniero": null,
      "codiceCatastale": "C226",
      "cap": "14043",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe@comune.castelnuovobelbo.at.it",
      "pec": "castelnuovobelbo@professionalpec.it",
      "telefono": "+39 0141799155",
      "fax": "+39 0141799155",
      "coordinate": {
        "lat": 44.80305480957031,
        "lng": 8.411110877990723
      }
    },
    {
      "codice": "005030",
      "nome": "Castelnuovo Calcea",
      "nomeStraniero": null,
      "codiceCatastale": "C230",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe@comune.castelnuovocalcea.at.it",
      "pec": "castelnuovo.calcea@cert.ruparpiemonte.it",
      "telefono": "+39 0141957125",
      "fax": "+39 0141957057",
      "coordinate": {
        "lat": 44.78916549682617,
        "lng": 8.286110877990723
      }
    },
    {
      "codice": "005031",
      "nome": "Castelnuovo Don Bosco",
      "nomeStraniero": null,
      "codiceCatastale": "C232",
      "cap": "14022",
      "prefisso": "011",
      "provincia": "Asti",
      "email": "elettorale@comune.castelnuovodonbosco.at.it",
      "pec": "protocollo.castelnuovo.don.bosco@cert.ruparpiemonte.it",
      "telefono": "+39 0119876165",
      "fax": "+39 0119876816",
      "coordinate": {
        "lat": 45.04166793823242,
        "lng": 7.964166641235352
      }
    },
    {
      "codice": "005033",
      "nome": "Cellarengo",
      "nomeStraniero": null,
      "codiceCatastale": "C438",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "cellarengo@comune.cellarengo.at.it",
      "pec": "cellarengo@cert.ruparpiemonte.it",
      "telefono": "+39 0141935123",
      "fax": "+39 0141935800",
      "coordinate": {
        "lat": 44.86527633666992,
        "lng": 7.9463887214660645
      }
    },
    {
      "codice": "005034",
      "nome": "Celle Enomondo",
      "nomeStraniero": null,
      "codiceCatastale": "C440",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe@comune.celleenomondo.at.it",
      "pec": "celle.enomondo@cert.ruparpiemonte.it",
      "telefono": "+39 0141205130",
      "fax": "+39 0141205130",
      "coordinate": {
        "lat": 44.8577766418457,
        "lng": 8.124722480773926
      }
    },
    {
      "codice": "005035",
      "nome": "Cerreto d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "C528",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.cerreto.asti.it",
      "pec": "protocollo.cerreto.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141996073",
      "fax": "+39 0141996073",
      "coordinate": {
        "lat": 45.05305480957031,
        "lng": 8.036110877990723
      }
    },
    {
      "codice": "005036",
      "nome": "Cerro Tanaro",
      "nomeStraniero": null,
      "codiceCatastale": "C533",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.cerrotanaro.at.it",
      "pec": "protocollo.cerro.tanaro@cert.ruparpiemonte.it",
      "telefono": "+39 0141409114",
      "fax": "+39 0141409002",
      "coordinate": {
        "lat": 44.874168395996094,
        "lng": 8.359722137451172
      }
    },
    {
      "codice": "005037",
      "nome": "Cessole",
      "nomeStraniero": null,
      "codiceCatastale": "C583",
      "cap": "14050",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "municipio@comune.cessole.at.it",
      "pec": "comune.cessole.at@legalmail.it",
      "telefono": "+39 014480142",
      "fax": "+39 0144850006",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 8.244722366333008
      }
    },
    {
      "codice": "005038",
      "nome": "Chiusano d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "C658",
      "cap": "14025",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "chiusano.dasti@ruparpiemonte.it",
      "pec": "chiusano.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141901536",
      "fax": "+39 0141901536",
      "coordinate": {
        "lat": 44.98749923706055,
        "lng": 8.119722366333008
      }
    },
    {
      "codice": "005039",
      "nome": "Cinaglio",
      "nomeStraniero": null,
      "codiceCatastale": "C701",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.cinaglio.at.it",
      "pec": "cinaglio@cert.ruparpiemonte.it",
      "telefono": "+39 0141209113",
      "fax": "+39 0141209507",
      "coordinate": {
        "lat": 44.976112365722656,
        "lng": 8.10111141204834
      }
    },
    {
      "codice": "005040",
      "nome": "Cisterna d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "C739",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe.cisterna.dasti@cst.asti.it",
      "pec": "cisterna.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141979124",
      "fax": "+39 0141979449",
      "coordinate": {
        "lat": 44.82722091674805,
        "lng": 8.001944541931152
      }
    },
    {
      "codice": "005041",
      "nome": "Coazzolo",
      "nomeStraniero": null,
      "codiceCatastale": "C804",
      "cap": "14054",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.coazzolo@virgilio.it",
      "pec": "protocollo@pec.comune.coazzolo.at.it",
      "telefono": "+39 0141870173",
      "fax": "+39 0141870173",
      "coordinate": {
        "lat": 44.72916793823242,
        "lng": 8.146111488342285
      }
    },
    {
      "codice": "005042",
      "nome": "Cocconato",
      "nomeStraniero": null,
      "codiceCatastale": "C807",
      "cap": "14023",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@cocconato.info",
      "pec": "comune.cocconato.at@cert.legalmail.it",
      "telefono": "+39 0141907007",
      "fax": "+39 0141907677",
      "coordinate": {
        "lat": 45.088890075683594,
        "lng": 8.040555953979492
      }
    },
    {
      "codice": "005044",
      "nome": "Corsione",
      "nomeStraniero": null,
      "codiceCatastale": "D046",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.corsione@libero.it",
      "pec": "corsione@cert.ruparpiemonte.it",
      "telefono": "+39 0141905075",
      "fax": "+39 0141905075",
      "coordinate": {
        "lat": 45.00194549560547,
        "lng": 8.14638900756836
      }
    },
    {
      "codice": "005045",
      "nome": "Cortandone",
      "nomeStraniero": null,
      "codiceCatastale": "D050",
      "cap": "14013",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "cortandone@ruparpiemonte.it",
      "pec": "protocollo.cortandone@cert.ruparpiemonte.it",
      "telefono": "+39 0141669219",
      "fax": "+39 0141669219",
      "coordinate": {
        "lat": 44.959999084472656,
        "lng": 8.059444427490234
      }
    },
    {
      "codice": "005046",
      "nome": "Cortanze",
      "nomeStraniero": null,
      "codiceCatastale": "D051",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.cortanze@libero.it",
      "pec": "comune.cortanze.at@legalmail.it",
      "telefono": "+39 0141901470",
      "fax": "+39 0141901468",
      "coordinate": {
        "lat": 45.01527786254883,
        "lng": 8.089166641235352
      }
    },
    {
      "codice": "005047",
      "nome": "Cortazzone",
      "nomeStraniero": null,
      "codiceCatastale": "D052",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.cortazzone@pec.it",
      "pec": "comune.cortazzone@pec.it",
      "telefono": "+39 0141995504",
      "fax": "+39 0141995907",
      "coordinate": {
        "lat": 44.97999954223633,
        "lng": 8.062222480773926
      }
    },
    {
      "codice": "005048",
      "nome": "Cortiglione",
      "nomeStraniero": null,
      "codiceCatastale": "D072",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.cortiglione@gmail.com",
      "pec": "protocollo.cortiglione@cert.ruparpiemonte.it",
      "telefono": "+39 0141765106",
      "fax": "+39 0141765365",
      "coordinate": {
        "lat": 44.82416534423828,
        "lng": 8.359444618225098
      }
    },
    {
      "codice": "005049",
      "nome": "Cossombrato",
      "nomeStraniero": null,
      "codiceCatastale": "D101",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune_cossombrato@libero.it",
      "pec": "cossombrato@cert.ruparpiemonte.it",
      "telefono": "+39 0141905206",
      "fax": "+39 0141905206",
      "coordinate": {
        "lat": 44.99388885498047,
        "lng": 8.137222290039062
      }
    },
    {
      "codice": "005050",
      "nome": "Costigliole d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "D119",
      "cap": "14055",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segreteria@costigliole.it",
      "pec": "comunedicostiglioledasti@postecert.it",
      "telefono": "+39 0141962211",
      "fax": "+39 0141966037",
      "coordinate": {
        "lat": 44.78499984741211,
        "lng": 8.181944847106934
      }
    },
    {
      "codice": "005051",
      "nome": "Cunico",
      "nomeStraniero": null,
      "codiceCatastale": "D207",
      "cap": "14026",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.cunico@gmail.com",
      "pec": "comune.cunico.at@cert.legalmail.it",
      "telefono": "+39 0141906502",
      "fax": "+39 0141906202",
      "coordinate": {
        "lat": 45.04083251953125,
        "lng": 8.097777366638184
      }
    },
    {
      "codice": "005052",
      "nome": "Dusino San Michele",
      "nomeStraniero": null,
      "codiceCatastale": "D388",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.dusinosanmichele.at.it",
      "pec": "dusino.san.michele@cert.ruparpiemonte.it",
      "telefono": "+39 0141930123",
      "fax": "+39 0141930828",
      "coordinate": {
        "lat": 44.92549133300781,
        "lng": 7.972189426422119
      }
    },
    {
      "codice": "005053",
      "nome": "Ferrere",
      "nomeStraniero": null,
      "codiceCatastale": "D554",
      "cap": "14012",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comunediferrere.segreteria@gmail.com",
      "pec": "amministrazione.ferrere@pec.it",
      "telefono": "+39 0141932008",
      "fax": "+39 0141932009",
      "coordinate": {
        "lat": 44.87604904174805,
        "lng": 7.9942097663879395
      }
    },
    {
      "codice": "005054",
      "nome": "Fontanile",
      "nomeStraniero": null,
      "codiceCatastale": "D678",
      "cap": "14044",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.fontanile.at.it",
      "pec": "fontanile@cert.ruparpiemonte.it",
      "telefono": "+39 0141739100",
      "fax": "+39 0141739371",
      "coordinate": {
        "lat": 44.75294876098633,
        "lng": 8.421339988708496
      }
    },
    {
      "codice": "005055",
      "nome": "Frinco",
      "nomeStraniero": null,
      "codiceCatastale": "D802",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.frinco.at.it",
      "pec": "frinco@pec.comune.frinco.at.it",
      "telefono": "+39 0141904066",
      "fax": "+39 0141904343",
      "coordinate": {
        "lat": 45.005001068115234,
        "lng": 8.173055648803711
      }
    },
    {
      "codice": "005056",
      "nome": "Grana",
      "nomeStraniero": null,
      "codiceCatastale": "E134",
      "cap": "14031",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comunedigrana@micso.net",
      "pec": "comunedigrana@registerpec.it",
      "telefono": "+39 014192623",
      "fax": "+39 0141924185",
      "coordinate": {
        "lat": 44.999168395996094,
        "lng": 8.300555229187012
      }
    },
    {
      "codice": "005057",
      "nome": "Grazzano Badoglio",
      "nomeStraniero": null,
      "codiceCatastale": "E159",
      "cap": "14035",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.grazzanobadoglio.at.it",
      "pec": "grazzano.badoglio@cert.ruparpiemonte.it",
      "telefono": "+39 0141925455",
      "fax": "+39 0141925456",
      "coordinate": {
        "lat": 45.04138946533203,
        "lng": 8.311666488647461
      }
    },
    {
      "codice": "005058",
      "nome": "Incisa Scapaccino",
      "nomeStraniero": null,
      "codiceCatastale": "E295",
      "cap": "14045",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "ufficioanagrafe.incisascapaccino@cst.asti.it",
      "pec": "incisa.spaccino@cert.ruparpiemonte.it",
      "telefono": "+39 014174040",
      "fax": "+39 014174661",
      "coordinate": {
        "lat": 44.80888748168945,
        "lng": 8.376944541931152
      }
    },
    {
      "codice": "005059",
      "nome": "Isola d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "E338",
      "cap": "14057",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe@comuneisoladasti.it",
      "pec": "comune.isoladasti.at@cert.legalmail.it",
      "telefono": "+39 0141958134",
      "fax": "+39 0141958866",
      "coordinate": {
        "lat": 44.83416748046875,
        "lng": 8.181388854980469
      }
    },
    {
      "codice": "005060",
      "nome": "Loazzolo",
      "nomeStraniero": null,
      "codiceCatastale": "E633",
      "cap": "14050",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "comuneloazzolo@libero.it",
      "pec": "loazzolo@cert.ruparpiemonte.it",
      "telefono": "+39 014487130",
      "fax": "+39 014487130",
      "coordinate": {
        "lat": 44.66999816894531,
        "lng": 8.259444236755371
      }
    },
    {
      "codice": "005061",
      "nome": "Maranzana",
      "nomeStraniero": null,
      "codiceCatastale": "E917",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segreteria@comune.maranzana.at.it",
      "pec": "comune.maranzana.at@legalmail.it",
      "telefono": "+39 014177931",
      "fax": "+39 0141777404",
      "coordinate": {
        "lat": 44.761390686035156,
        "lng": 8.47944450378418
      }
    },
    {
      "codice": "005062",
      "nome": "Maretto",
      "nomeStraniero": null,
      "codiceCatastale": "E944",
      "cap": "14018",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo.maretto@gmail.com",
      "pec": "maretto@cert.ruparpiemonte.it",
      "telefono": "+39 0141938170",
      "fax": null,
      "coordinate": {
        "lat": 44.94583511352539,
        "lng": 8.035277366638184
      }
    },
    {
      "codice": "005063",
      "nome": "Moasca",
      "nomeStraniero": null,
      "codiceCatastale": "F254",
      "cap": "14050",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.moasca.at.it",
      "pec": "comunedimoasca@pec.it",
      "telefono": "+39 0141856447",
      "fax": "+39 0141856447",
      "coordinate": {
        "lat": 44.76444625854492,
        "lng": 8.279444694519043
      }
    },
    {
      "codice": "005064",
      "nome": "Mombaldone",
      "nomeStraniero": null,
      "codiceCatastale": "F308",
      "cap": "14050",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "uffici@comune.mombaldone.at.it",
      "pec": "mombaldone@cert.ruparpiemonte.it",
      "telefono": "+39 0144950680",
      "fax": "+39 0144950693",
      "coordinate": {
        "lat": 44.57194519042969,
        "lng": 8.335833549499512
      }
    },
    {
      "codice": "005065",
      "nome": "Mombaruzzo",
      "nomeStraniero": null,
      "codiceCatastale": "F311",
      "cap": "14046",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "servizidemografici.mombaruzzo@reteunitaria.piemonte.it",
      "pec": "mombaruzzo@cert.ruparpiemonte.it",
      "telefono": "+39 014177002",
      "fax": "+39 0141774507",
      "coordinate": {
        "lat": 44.772499084472656,
        "lng": 8.449166297912598
      }
    },
    {
      "codice": "005066",
      "nome": "Mombercelli",
      "nomeStraniero": null,
      "codiceCatastale": "F316",
      "cap": "14047",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.mombercelli.at.it",
      "pec": "comune.mombercelli.at@cert.legalmail.it",
      "telefono": "+39 0141959610",
      "fax": "+39 0141959756",
      "coordinate": {
        "lat": 44.81694412231445,
        "lng": 8.295000076293945
      }
    },
    {
      "codice": "005067",
      "nome": "Monale",
      "nomeStraniero": null,
      "codiceCatastale": "F323",
      "cap": "14013",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.monale.at.it",
      "pec": "monale@cert.ruparpiemonte.it",
      "telefono": "+39 0141669131",
      "fax": "+39 0141650907",
      "coordinate": {
        "lat": 44.9386100769043,
        "lng": 8.07361125946045
      }
    },
    {
      "codice": "005068",
      "nome": "Monastero Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "F325",
      "cap": "14058",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "info@comunemonastero.at.it",
      "pec": "monastero.bormida@cert.ruparpiemonte.it",
      "telefono": "+39 014488012",
      "fax": "+39 014488450",
      "coordinate": {
        "lat": 44.649444580078125,
        "lng": 8.327777862548828
      }
    },
    {
      "codice": "005069",
      "nome": "Moncalvo",
      "nomeStraniero": null,
      "codiceCatastale": "F336",
      "cap": "14036",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.moncalvo.at.it",
      "pec": "protocollo.moncalvo@pec.it",
      "telefono": "+39 0141917505",
      "fax": "+39 0141917352",
      "coordinate": {
        "lat": 45.051109313964844,
        "lng": 8.266388893127441
      }
    },
    {
      "codice": "005070",
      "nome": "Moncucco Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "F343",
      "cap": "14024",
      "prefisso": "011",
      "provincia": "Asti",
      "email": "comune@comune.moncucco.asti.it",
      "pec": "comune.moncuccotorinese.at@legalmail.it",
      "telefono": "+39 0119874701",
      "fax": null,
      "coordinate": {
        "lat": 45.06916809082031,
        "lng": 7.933610916137695
      }
    },
    {
      "codice": "005071",
      "nome": "Mongardino",
      "nomeStraniero": null,
      "codiceCatastale": "F361",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "demografici@comune.mongardino.at.it",
      "pec": "comune.mongardino.at@cert.legalmail.it",
      "telefono": "+39 0141291226",
      "fax": "+39 0141291442",
      "coordinate": {
        "lat": 44.84805679321289,
        "lng": 8.21500015258789
      }
    },
    {
      "codice": "005072",
      "nome": "Montabone",
      "nomeStraniero": null,
      "codiceCatastale": "F386",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "montabone@reteunitaria.piemonte.it",
      "pec": "montabone@cert.ruparpiemonte.it",
      "telefono": "+39 0141762121",
      "fax": "+39 0141762509",
      "coordinate": {
        "lat": 44.69972229003906,
        "lng": 8.391666412353516
      }
    },
    {
      "codice": "005073",
      "nome": "Montafia",
      "nomeStraniero": null,
      "codiceCatastale": "F390",
      "cap": "14014",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.montafia.asti.it",
      "pec": "montafia@cert.ruparpiemonte.it",
      "telefono": "+39 0141997003",
      "fax": "+39 0141997517",
      "coordinate": {
        "lat": 44.99027633666992,
        "lng": 8.025833129882812
      }
    },
    {
      "codice": "005074",
      "nome": "Montaldo Scarampi",
      "nomeStraniero": null,
      "codiceCatastale": "F409",
      "cap": "14048",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.montaldoscarampi.at.it",
      "pec": "comune.montaldoscarampi.at@legalmail.it",
      "telefono": "+39 0141953040",
      "fax": "+39 0141952188",
      "coordinate": {
        "lat": 44.831390380859375,
        "lng": 8.260000228881836
      }
    },
    {
      "codice": "005075",
      "nome": "Montechiaro d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "F468",
      "cap": "14025",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "amministrativo@comune.montechiarodasti.at.it",
      "pec": "comune.montechiarodasti.at@legalmail.it",
      "telefono": "+39 0141999136",
      "fax": "+39 0141901128",
      "coordinate": {
        "lat": 45.00833511352539,
        "lng": 8.112500190734863
      }
    },
    {
      "codice": "005076",
      "nome": "Montegrosso d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "F527",
      "cap": "14048",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune@comune.montegrossodasti.at.it",
      "pec": "protocollo.montegrosso.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141953052",
      "fax": "+39 0141953739",
      "coordinate": {
        "lat": 44.8216667175293,
        "lng": 8.238611221313477
      }
    },
    {
      "codice": "005077",
      "nome": "Montemagno",
      "nomeStraniero": null,
      "codiceCatastale": "F556",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.montemagno.at.it",
      "pec": "comune.montemagno@pec.it",
      "telefono": "+39 014163575",
      "fax": "+39 014163129",
      "coordinate": {
        "lat": 44.983890533447266,
        "lng": 8.32638931274414
      }
    },
    {
      "codice": "005121",
      "nome": "Montiglio Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "M302",
      "cap": "14026",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "montigliomonferrato@gmail.com",
      "pec": "comune.montigliomonferrato.at@cert.legalmail.it",
      "telefono": "+39 0141994008",
      "fax": "+39 0141691928",
      "coordinate": {
        "lat": 45.06611251831055,
        "lng": 8.098333358764648
      }
    },
    {
      "codice": "005079",
      "nome": "Moransengo",
      "nomeStraniero": null,
      "codiceCatastale": "F709",
      "cap": "14023",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.moransengo.at.it",
      "pec": "protocollo.morasengo@cert.ruparpiemonte.it",
      "telefono": "+39 0141900223",
      "fax": "+39 0141900130",
      "coordinate": {
        "lat": 45.1158332824707,
        "lng": 8.026389122009277
      }
    },
    {
      "codice": "005080",
      "nome": "Nizza Monferrato",
      "nomeStraniero": null,
      "codiceCatastale": "F902",
      "cap": "14049",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "delibere.appalti@comune.nizza.at.it",
      "pec": "nizza.monferrato@cert.ruparpiemonte.it",
      "telefono": "+39 0141720511",
      "fax": "+39 0141720536",
      "coordinate": {
        "lat": 44.774723052978516,
        "lng": 8.354999542236328
      }
    },
    {
      "codice": "005081",
      "nome": "Olmo Gentile",
      "nomeStraniero": null,
      "codiceCatastale": "G048",
      "cap": "14050",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "uffici@comune.olmogentile.at.it",
      "pec": "olmo.gentile@cert.ruparpiemonte.it",
      "telefono": "+39 014493075",
      "fax": "+39 014493075",
      "coordinate": {
        "lat": 44.586666107177734,
        "lng": 8.248055458068848
      }
    },
    {
      "codice": "005082",
      "nome": "Passerano Marmorito",
      "nomeStraniero": null,
      "codiceCatastale": "G358",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "alfredinoturetta.passerano.marmorito@reteunitaria.piemonte.it",
      "pec": "passerano.marmorito@cert.ruparpiemonte.it",
      "telefono": "+39 0141903600",
      "fax": "+39 0141903601",
      "coordinate": {
        "lat": 45.05666732788086,
        "lng": 8.019721984863281
      }
    },
    {
      "codice": "005083",
      "nome": "Penango",
      "nomeStraniero": null,
      "codiceCatastale": "G430",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "uffici@comune.penango.at.it",
      "pec": "uffici@pec.comune.penango.at.it",
      "telefono": "+39 0141910123",
      "fax": "+39 0141910123",
      "coordinate": {
        "lat": 45.03388977050781,
        "lng": 8.252778053283691
      }
    },
    {
      "codice": "005084",
      "nome": "Piea",
      "nomeStraniero": null,
      "codiceCatastale": "G593",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "piea@comune.piea.at.it",
      "pec": "piea@cert.ruparpiemonte.it",
      "telefono": "+39 0141901650",
      "fax": "+39 0141990907",
      "coordinate": {
        "lat": 45.025001525878906,
        "lng": 8.071666717529297
      }
    },
    {
      "codice": "005085",
      "nome": "Pino d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "G676",
      "cap": "14020",
      "prefisso": "011",
      "provincia": "Asti",
      "email": "protocollo@comune.pinodasti.at.it",
      "pec": "pino.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0119925424",
      "fax": "+39 0119925424",
      "coordinate": {
        "lat": 45.05833435058594,
        "lng": 7.987222194671631
      }
    },
    {
      "codice": "005086",
      "nome": "Piovà Massaia",
      "nomeStraniero": null,
      "codiceCatastale": "G692",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.piovamassaia@libero.it",
      "pec": "comune.piovamassaia.at@cert.legalmail.it",
      "telefono": "+39 0141996036",
      "fax": "+39 0141996356",
      "coordinate": {
        "lat": 45.05611038208008,
        "lng": 8.051111221313477
      }
    },
    {
      "codice": "005087",
      "nome": "Portacomaro",
      "nomeStraniero": null,
      "codiceCatastale": "G894",
      "cap": "14037",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe@comune.portacomaro.at.it",
      "pec": "protocollo.portacomaro@cert.ruparpiemonte.it",
      "telefono": "+39 0141202128",
      "fax": "+39 0141278029",
      "coordinate": {
        "lat": 44.95750045776367,
        "lng": 8.258055686950684
      }
    },
    {
      "codice": "005088",
      "nome": "Quaranti",
      "nomeStraniero": null,
      "codiceCatastale": "H102",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.quaranti@libero.it",
      "pec": "protocollo.quaranti@cert.ruparpiemonte.it",
      "telefono": "+39 0141793939",
      "fax": "+39 0141793910",
      "coordinate": {
        "lat": 44.75194549560547,
        "lng": 8.450277328491211
      }
    },
    {
      "codice": "005089",
      "nome": "Refrancore",
      "nomeStraniero": null,
      "codiceCatastale": "H219",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "refrancore@ruparpiemonte.it",
      "pec": "refrancore@cert.ruparpiemonte.it",
      "telefono": "+39 014167126",
      "fax": "+39 014167103",
      "coordinate": {
        "lat": 44.93722152709961,
        "lng": 8.341944694519043
      }
    },
    {
      "codice": "005090",
      "nome": "Revigliasco d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "H250",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segreteria@comune.revigliasco.asti.it",
      "pec": "revigliasco.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141208191",
      "fax": "+39 0141208921",
      "coordinate": {
        "lat": 44.858612060546875,
        "lng": 8.161389350891113
      }
    },
    {
      "codice": "005091",
      "nome": "Roatto",
      "nomeStraniero": null,
      "codiceCatastale": "H366",
      "cap": "14018",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comuneroatto@libero.it",
      "pec": "roatto@cert.ruparpiemonte.it",
      "telefono": "+39 0141938114",
      "fax": "+39 0141938422",
      "coordinate": {
        "lat": 44.95305633544922,
        "lng": 8.028055191040039
      }
    },
    {
      "codice": "005092",
      "nome": "Robella",
      "nomeStraniero": null,
      "codiceCatastale": "H376",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.robella.at.it",
      "pec": "robella@cert.legalmail.it",
      "telefono": "+39 0141998123",
      "fax": "+39 0141998331",
      "coordinate": {
        "lat": 45.10165023803711,
        "lng": 8.101929664611816
      }
    },
    {
      "codice": "005093",
      "nome": "Rocca d'Arazzo",
      "nomeStraniero": null,
      "codiceCatastale": "H392",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "anagrafe.rocca.darazzo@ruparpiemonte.it",
      "pec": "comune.roccadarazzo.at@cert.legalmail.it",
      "telefono": "+39 0141408144",
      "fax": "+39 0141408144",
      "coordinate": {
        "lat": 44.87305450439453,
        "lng": 8.285833358764648
      }
    },
    {
      "codice": "005094",
      "nome": "Roccaverano",
      "nomeStraniero": null,
      "codiceCatastale": "H451",
      "cap": "14050",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "roccaverano@ruparpiemonte.it",
      "pec": "roccaverano@cert.ruparpiemonte.it",
      "telefono": "+39 014493025",
      "fax": "+39 014493356",
      "coordinate": {
        "lat": 44.59333419799805,
        "lng": 8.273333549499512
      }
    },
    {
      "codice": "005095",
      "nome": "Rocchetta Palafea",
      "nomeStraniero": null,
      "codiceCatastale": "H466",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "segretariocomunale.rocchetta.palafea@cert.ruparpiemonte.it",
      "pec": "rocchetta.palafea@cert.ruparpiemonte.it",
      "telefono": "+39 0141718280",
      "fax": "+39 0141718312",
      "coordinate": {
        "lat": 44.70833206176758,
        "lng": 8.346388816833496
      }
    },
    {
      "codice": "005096",
      "nome": "Rocchetta Tanaro",
      "nomeStraniero": null,
      "codiceCatastale": "H468",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "urp@comune.rocchettatanaro.at.it",
      "pec": "info@pec.comune.rocchettatanaro.at.it",
      "telefono": "+39 0141644123",
      "fax": "+39 0141644733",
      "coordinate": {
        "lat": 44.85944366455078,
        "lng": 8.34416675567627
      }
    },
    {
      "codice": "005097",
      "nome": "San Damiano d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "H811",
      "cap": "14015",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo.sdamiano@virgilio.it",
      "pec": "san.damiano.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141975056",
      "fax": "+39 0141982582",
      "coordinate": {
        "lat": 44.83444595336914,
        "lng": 8.064722061157227
      }
    },
    {
      "codice": "005098",
      "nome": "San Giorgio Scarampi",
      "nomeStraniero": null,
      "codiceCatastale": "H899",
      "cap": "14059",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "uffici@comune.sangiorgioscarampi.at.it",
      "pec": "san.giorgio.scarampi@cert.ruparpiemonte.it",
      "telefono": "+39 014489041",
      "fax": "+39 0144859928",
      "coordinate": {
        "lat": 44.61194610595703,
        "lng": 8.243611335754395
      }
    },
    {
      "codice": "005099",
      "nome": "San Martino Alfieri",
      "nomeStraniero": null,
      "codiceCatastale": "H987",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.sanmartinoalfieri.at.it",
      "pec": "san.martino.alfieri@cert.ruparpiemonte.it",
      "telefono": "+39 0141976123",
      "fax": "+39 0141976211",
      "coordinate": {
        "lat": 44.81888961791992,
        "lng": 8.11111068725586
      }
    },
    {
      "codice": "005100",
      "nome": "San Marzano Oliveto",
      "nomeStraniero": null,
      "codiceCatastale": "I017",
      "cap": "14050",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "san.marzano.oliveto@ruparpiemonte.it",
      "pec": "comune.sanmarzanooliveto.at@legalmail.it",
      "telefono": "+39 0141856121",
      "fax": "+39 0141856121",
      "coordinate": {
        "lat": 44.755279541015625,
        "lng": 8.296388626098633
      }
    },
    {
      "codice": "005101",
      "nome": "San Paolo Solbrito",
      "nomeStraniero": null,
      "codiceCatastale": "I076",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune@comune.sanpaolosolbrito.at.it",
      "pec": "sanpaolosolbrito@postemailcertificata.it",
      "telefono": "+39 0141936103",
      "fax": "+39 0141936103",
      "coordinate": {
        "lat": 44.95166778564453,
        "lng": 7.9716668128967285
      }
    },
    {
      "codice": "005103",
      "nome": "Scurzolengo",
      "nomeStraniero": null,
      "codiceCatastale": "I555",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "scurzolengo@ruparpiemonte.it",
      "pec": "scurzolengo@cert.ruparpiemonte.it",
      "telefono": "+39 0141203118",
      "fax": "+39 0141203410",
      "coordinate": {
        "lat": 44.9658317565918,
        "lng": 8.280278205871582
      }
    },
    {
      "codice": "005104",
      "nome": "Serole",
      "nomeStraniero": null,
      "codiceCatastale": "I637",
      "cap": "14050",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "uffici@comune.serole.at.it",
      "pec": "serole@cert.ruparpiemonte.it",
      "telefono": "+39 014494150",
      "fax": "+39 014494150",
      "coordinate": {
        "lat": 44.55500030517578,
        "lng": 8.260833740234375
      }
    },
    {
      "codice": "005105",
      "nome": "Sessame",
      "nomeStraniero": null,
      "codiceCatastale": "I678",
      "cap": "14058",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "info@comune.sessame.at.it",
      "pec": "sessame@pec.comune.sessame.at.it",
      "telefono": "+39 0144392155",
      "fax": "+39 0144392155",
      "coordinate": {
        "lat": 44.67166519165039,
        "lng": 8.337778091430664
      }
    },
    {
      "codice": "005106",
      "nome": "Settime",
      "nomeStraniero": null,
      "codiceCatastale": "I698",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.settime@comunedisettime.it",
      "pec": "protocollo.settime@cert.ruparpiemonte.it",
      "telefono": "0141209124",
      "fax": "+39 0141209373",
      "coordinate": {
        "lat": 44.96277618408203,
        "lng": 8.115278244018555
      }
    },
    {
      "codice": "005107",
      "nome": "Soglio",
      "nomeStraniero": null,
      "codiceCatastale": "I781",
      "cap": "14010",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comune.soglio@comunedisoglio.it",
      "pec": "soglio@cert.ruparpiemonte.it",
      "telefono": "+39 0141992801",
      "fax": "+39 0141992801",
      "coordinate": {
        "lat": 44.997222900390625,
        "lng": 8.079444885253906
      }
    },
    {
      "codice": "005108",
      "nome": "Tigliole",
      "nomeStraniero": null,
      "codiceCatastale": "L168",
      "cap": "14016",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "tigliole@reteunitaria.piemonte.it",
      "pec": "tigliole@cert.ruparpiemonte.it",
      "telefono": "+39 0141667124",
      "fax": "+39 0141667543",
      "coordinate": {
        "lat": 44.88722229003906,
        "lng": 8.077777862548828
      }
    },
    {
      "codice": "005109",
      "nome": "Tonco",
      "nomeStraniero": null,
      "codiceCatastale": "L203",
      "cap": "14039",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "tonco@reteunitaria.piemonte.it",
      "pec": "tonco@cert.ruparpiemonte.it",
      "telefono": "+39 0141991044",
      "fax": "+39 0141991259",
      "coordinate": {
        "lat": 45.02527618408203,
        "lng": 8.19111156463623
      }
    },
    {
      "codice": "005110",
      "nome": "Tonengo",
      "nomeStraniero": null,
      "codiceCatastale": "L204",
      "cap": "14023",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.tonengo.at.it",
      "pec": "comune.tonengo@cert.legalmail.it",
      "telefono": "+39 0141908112",
      "fax": "+39 01411702070",
      "coordinate": {
        "lat": 45.11861038208008,
        "lng": 8.00333309173584
      }
    },
    {
      "codice": "005111",
      "nome": "Vaglio Serra",
      "nomeStraniero": null,
      "codiceCatastale": "L531",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo@comune.vaglioserra.at.it",
      "pec": "comune.vaglioserra.at@cert.legalmail.it",
      "telefono": "+39 0141732024",
      "fax": "+39 0141732914",
      "coordinate": {
        "lat": 44.79722213745117,
        "lng": 8.34000015258789
      }
    },
    {
      "codice": "005112",
      "nome": "Valfenera",
      "nomeStraniero": null,
      "codiceCatastale": "L574",
      "cap": "14017",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "valfenera@reteunitaria.piemonte.it",
      "pec": "valfenera@cert.ruparpiemonte.it",
      "telefono": "+39 0141939125",
      "fax": "+39 0141939745",
      "coordinate": {
        "lat": 44.90277862548828,
        "lng": 7.965277671813965
      }
    },
    {
      "codice": "005113",
      "nome": "Vesime",
      "nomeStraniero": null,
      "codiceCatastale": "L807",
      "cap": "14059",
      "prefisso": "0144",
      "provincia": "Asti",
      "email": "sindaco@comunevesime.it",
      "pec": "vesime@professionalpec.it",
      "telefono": "+39 014489015",
      "fax": "+39 014489216",
      "coordinate": {
        "lat": 44.63666534423828,
        "lng": 8.228333473205566
      }
    },
    {
      "codice": "005114",
      "nome": "Viale",
      "nomeStraniero": null,
      "codiceCatastale": "L829",
      "cap": "14014",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "comuneviale@yahoo.it",
      "pec": "comune.viale.at@cert.legalmail.it",
      "telefono": "+39 0141 995028",
      "fax": "+39 0141 995511",
      "coordinate": {
        "lat": 45.0011100769043,
        "lng": 8.051111221313477
      }
    },
    {
      "codice": "005115",
      "nome": "Viarigi",
      "nomeStraniero": null,
      "codiceCatastale": "L834",
      "cap": "14030",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "info@comune.viarigi.at.it",
      "pec": "viarigi@postemailcertificata.it",
      "telefono": "+39 0141611050",
      "fax": "+39 0141611277",
      "coordinate": {
        "lat": 44.981388092041016,
        "lng": 8.358333587646484
      }
    },
    {
      "codice": "005116",
      "nome": "Vigliano d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "L879",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "vigliano@comune.vigliano.at.it",
      "pec": "comune.vigliano.at@cert.legalmail.it",
      "telefono": "+39 0141953140",
      "fax": "+39 0141951615",
      "coordinate": {
        "lat": 44.834999084472656,
        "lng": 8.230278015136719
      }
    },
    {
      "codice": "005119",
      "nome": "Villa San Secondo",
      "nomeStraniero": null,
      "codiceCatastale": "M019",
      "cap": "14020",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "villasansecondo@libero.it",
      "pec": "villa.san.secondo@cert.ruparpiemonte.it",
      "telefono": "+39 0141905131",
      "fax": "+39 0141905900",
      "coordinate": {
        "lat": 45.00749969482422,
        "lng": 8.134721755981445
      }
    },
    {
      "codice": "005117",
      "nome": "Villafranca d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "L945",
      "cap": "14018",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "sindaco@comune.villafrancadasti.at.it",
      "pec": "villafranca.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141943071",
      "fax": "+39 0141941235",
      "coordinate": {
        "lat": 44.912776947021484,
        "lng": 8.032777786254883
      }
    },
    {
      "codice": "005118",
      "nome": "Villanova d'Asti",
      "nomeStraniero": null,
      "codiceCatastale": "L984",
      "cap": "14019",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "protocollo_segreteria@comune.villanova.at.it",
      "pec": "villanova.dasti@cert.ruparpiemonte.it",
      "telefono": "+39 0141946085",
      "fax": "+39 0141946230",
      "coordinate": {
        "lat": 44.942779541015625,
        "lng": 7.938333511352539
      }
    },
    {
      "codice": "005120",
      "nome": "Vinchio",
      "nomeStraniero": null,
      "codiceCatastale": "M058",
      "cap": "14040",
      "prefisso": "0141",
      "provincia": "Asti",
      "email": "vinchio@reteunitaria.piemonte.it",
      "pec": "comune.vinchio.at@legalmail.it",
      "telefono": "+39 0141950120",
      "fax": "+39 0141950905",
      "coordinate": {
        "lat": 44.81194305419922,
        "lng": 8.321944236755371
      }
    }
  ],
  "Torino": [
    {
      "codice": "001001",
      "nome": "Agliè",
      "nomeStraniero": null,
      "codiceCatastale": "A074",
      "cap": "10011",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "aglie@ruparpiemonte.it",
      "pec": "aglie@cert.ruparpiemonte.it",
      "telefono": "+39 0124 330367",
      "fax": "+39 0124 330280",
      "coordinate": {
        "lat": 45.3670539855957,
        "lng": 7.766918182373047
      }
    },
    {
      "codice": "001002",
      "nome": "Airasca",
      "nomeStraniero": null,
      "codiceCatastale": "A109",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.airasca.to.it",
      "pec": "airasca@postemailcertificata.it",
      "telefono": "+39 011/9909401",
      "fax": "+39 011/9908282",
      "coordinate": {
        "lat": 44.91704177856445,
        "lng": 7.484321117401123
      }
    },
    {
      "codice": "001003",
      "nome": "Ala di Stura",
      "nomeStraniero": null,
      "codiceCatastale": "A117",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comunealadistura.it",
      "pec": "comunealadistura@pec.it",
      "telefono": "+39 0123 55102",
      "fax": "+39 0123 55528",
      "coordinate": {
        "lat": 45.31515121459961,
        "lng": 7.305909156799316
      }
    },
    {
      "codice": "001004",
      "nome": "Albiano d'Ivrea",
      "nomeStraniero": null,
      "codiceCatastale": "A157",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe.albiano.divrea@ruparpiemonte.it",
      "pec": "albiano.divrea@cert.ruparpiemonte.it",
      "telefono": "+39 0125 59603",
      "fax": "+39 0125 59819",
      "coordinate": {
        "lat": 45.432960510253906,
        "lng": 7.950621128082275
      }
    },
    {
      "codice": "001006",
      "nome": "Almese",
      "nomeStraniero": null,
      "codiceCatastale": "A218",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografico@comune.almese.to.it",
      "pec": "comune.almese@cert.legalmail.it",
      "telefono": "+39 011 9350201",
      "fax": "+39 011 9350408",
      "coordinate": {
        "lat": 45.11687469482422,
        "lng": 7.39454984664917
      }
    },
    {
      "codice": "001007",
      "nome": "Alpette",
      "nomeStraniero": null,
      "codiceCatastale": "A221",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "segreteria.sindaco@comune.alpette.to.it",
      "pec": "comune.alpette.to@legalmail.it",
      "telefono": "+39 0124 809122",
      "fax": "+39 0124 809122",
      "coordinate": {
        "lat": 45.40891647338867,
        "lng": 7.578978061676025
      }
    },
    {
      "codice": "001008",
      "nome": "Alpignano",
      "nomeStraniero": null,
      "codiceCatastale": "A222",
      "cap": "10091",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "urp@comune.alpignano.to.it",
      "pec": "protocollo.alpignano@legalmail.it",
      "telefono": "+39 011 9666611",
      "fax": "+39 011 9674772",
      "coordinate": {
        "lat": 45.09572219848633,
        "lng": 7.525403022766113
      }
    },
    {
      "codice": "001009",
      "nome": "Andezeno",
      "nomeStraniero": null,
      "codiceCatastale": "A275",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "r.bechis@comune.andezeno.to.it",
      "pec": "comune.andezeno.to@legalmail.it",
      "telefono": "+39 011 9434674",
      "fax": "+39 011 9434466",
      "coordinate": {
        "lat": 45.03774642944336,
        "lng": 7.8705267906188965
      }
    },
    {
      "codice": "001010",
      "nome": "Andrate",
      "nomeStraniero": null,
      "codiceCatastale": "A282",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "andrate@ruparpiemonte.it",
      "pec": "andrate@cert.ruparpiemonte.it",
      "telefono": "+39 0125 790184",
      "fax": "+39 0125 790371",
      "coordinate": {
        "lat": 45.52735900878906,
        "lng": 7.8803181648254395
      }
    },
    {
      "codice": "001011",
      "nome": "Angrogna",
      "nomeStraniero": null,
      "codiceCatastale": "A295",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "segreteria@comune.angrogna.to.gov.it",
      "pec": "angrogna@cert.ruparpiemonte.it",
      "telefono": "+39 0121 944153",
      "fax": "+39 0121 944423",
      "coordinate": {
        "lat": 44.84358215332031,
        "lng": 7.22381591796875
      }
    },
    {
      "codice": "001012",
      "nome": "Arignano",
      "nomeStraniero": null,
      "codiceCatastale": "A405",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "ragioneria@comune.arignano.to.it",
      "pec": "arignano.torino@legalmail.it",
      "telefono": "+39 011 9462162",
      "fax": "+39 011 9462212",
      "coordinate": {
        "lat": 45.04027557373047,
        "lng": 7.901594161987305
      }
    },
    {
      "codice": "001013",
      "nome": "Avigliana",
      "nomeStraniero": null,
      "codiceCatastale": "A518",
      "cap": "10051",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici.avigliana@ruparpiemonte.it",
      "pec": "comuneavigliana@cert.legalmail.it",
      "telefono": "+39 011/9769104",
      "fax": "+39 011/9320312",
      "coordinate": {
        "lat": 45.077945709228516,
        "lng": 7.39838981628418
      }
    },
    {
      "codice": "001014",
      "nome": "Azeglio",
      "nomeStraniero": null,
      "codiceCatastale": "A525",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "azeglio@ruparpiemonte.it",
      "pec": "protocollo.azeglio@cert.ruparpiemonte.it",
      "telefono": "+39 0125 72106",
      "fax": "+39 0125 72113",
      "coordinate": {
        "lat": 45.423458099365234,
        "lng": 7.993922233581543
      }
    },
    {
      "codice": "001015",
      "nome": "Bairo",
      "nomeStraniero": null,
      "codiceCatastale": "A584",
      "cap": "10010",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "anagrafe.bairo@libero.it",
      "pec": "bairo@postemailcertificata.it",
      "telefono": "+39 0124 501043",
      "fax": "+39 0124 501039",
      "coordinate": {
        "lat": 45.3865852355957,
        "lng": 7.755446910858154
      }
    },
    {
      "codice": "001016",
      "nome": "Balangero",
      "nomeStraniero": null,
      "codiceCatastale": "A587",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comune.balangero.to.it",
      "pec": "segreteria.balangero@pec.it",
      "telefono": "+39 0123/3456211",
      "fax": "+39 0123/347100",
      "coordinate": {
        "lat": 45.27532196044922,
        "lng": 7.520281791687012
      }
    },
    {
      "codice": "001017",
      "nome": "Baldissero Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "A590",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "responsabileanagrafe.baldissero.canavese@ruparpiemonte.it",
      "pec": "segreteria.baldisserocanavese@actaliscertymail.it",
      "telefono": "+39 0124 512401",
      "fax": "+39 0124 512900",
      "coordinate": {
        "lat": 45.410186767578125,
        "lng": 7.744157791137695
      }
    },
    {
      "codice": "001018",
      "nome": "Baldissero Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "A591",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": null,
      "pec": "anagrafe.baldissero.torinese@cert.ruparpiemonte.it",
      "telefono": "+39 011 9408008 interno 1",
      "fax": "+39 011 9408380",
      "coordinate": {
        "lat": 45.06863784790039,
        "lng": 7.816531181335449
      }
    },
    {
      "codice": "001019",
      "nome": "Balme",
      "nomeStraniero": null,
      "codiceCatastale": "A599",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comune.balme.to.it",
      "pec": "balme@pec.comune.balme.to.it",
      "telefono": "+39 0123 82902",
      "fax": "+39 0123 82902",
      "coordinate": {
        "lat": 45.30250549316406,
        "lng": 7.215432167053223
      }
    },
    {
      "codice": "001020",
      "nome": "Banchette",
      "nomeStraniero": null,
      "codiceCatastale": "A607",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe@comune.banchette.to.it",
      "pec": "banchette@cert.ruparpiemonte.it",
      "telefono": "+39 0125 612648",
      "fax": "+39 0125 611468",
      "coordinate": {
        "lat": 45.45355987548828,
        "lng": 7.85631799697876
      }
    },
    {
      "codice": "001021",
      "nome": "Barbania",
      "nomeStraniero": null,
      "codiceCatastale": "A625",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "sindaco.barbania@ruparpiemonte.it",
      "pec": "barbania@cert.ruparpiemonte.it",
      "telefono": "+39 011 9243621",
      "fax": "+39 011 9243958",
      "coordinate": {
        "lat": 45.29125213623047,
        "lng": 7.630317211151123
      }
    },
    {
      "codice": "001022",
      "nome": "Bardonecchia",
      "nomeStraniero": null,
      "codiceCatastale": "A651",
      "cap": "10052",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "segreteria@bardonecchia.it",
      "pec": "comune.bardonecchia@pec.it",
      "telefono": "+39 0122 909921",
      "fax": "+39 0122 96084",
      "coordinate": {
        "lat": 45.078338623046875,
        "lng": 6.703205108642578
      }
    },
    {
      "codice": "001023",
      "nome": "Barone Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "A673",
      "cap": "01010",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.barone.to.it",
      "pec": "barone.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 011 9898025",
      "fax": "+39 011 9898025",
      "coordinate": {
        "lat": 45.32552719116211,
        "lng": 7.873063087463379
      }
    },
    {
      "codice": "001024",
      "nome": "Beinasco",
      "nomeStraniero": null,
      "codiceCatastale": "A734",
      "cap": "10092",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.beinasco.to.it",
      "pec": "protocollo@comune.beinasco.legalmail.it",
      "telefono": "+39 011 39891",
      "fax": null,
      "coordinate": {
        "lat": 45.022098541259766,
        "lng": 7.579397201538086
      }
    },
    {
      "codice": "001025",
      "nome": "Bibiana",
      "nomeStraniero": null,
      "codiceCatastale": "A853",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "info@comune.bibiana.to.it",
      "pec": "postacert@pec.comunebibiana.it",
      "telefono": "+39 0121 55723",
      "fax": "+39 0121 55888",
      "coordinate": {
        "lat": 44.7989387512207,
        "lng": 7.288218975067139
      }
    },
    {
      "codice": "001026",
      "nome": "Bobbio Pellice",
      "nomeStraniero": null,
      "codiceCatastale": "A910",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comune.bobbiopellice@libero.it",
      "pec": "protocollo.comunebobbiopellice@pec.it",
      "telefono": "+39 0121 957882",
      "fax": "+39 0121 957734",
      "coordinate": {
        "lat": 44.807952880859375,
        "lng": 7.116765022277832
      }
    },
    {
      "codice": "001027",
      "nome": "Bollengo",
      "nomeStraniero": null,
      "codiceCatastale": "A941",
      "cap": "10012",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "bollengo@eponet.it",
      "pec": "comune.bollengo.to@legalmail.it",
      "telefono": "+39 0125 57401",
      "fax": "+39 0125 577812",
      "coordinate": {
        "lat": 45.47085952758789,
        "lng": 7.942019939422607
      }
    },
    {
      "codice": "001028",
      "nome": "Borgaro Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "A990",
      "cap": "10071",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "ufficio.elettorale@comune.borgaro-torinese.to.it",
      "pec": "comune.borgaro-torinese@cert.legalmail.it",
      "telefono": "+39 011 4211205",
      "fax": "+39 011 4211202",
      "coordinate": {
        "lat": 45.15214538574219,
        "lng": 7.657972812652588
      }
    },
    {
      "codice": "001029",
      "nome": "Borgiallo",
      "nomeStraniero": null,
      "codiceCatastale": "B003",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.borgiallo.to.it",
      "pec": "borgiallo@cert.ruparpiemonte.it",
      "telefono": "+39 0124 690001",
      "fax": "+39 0124 699436",
      "coordinate": {
        "lat": 45.41705322265625,
        "lng": 7.668515205383301
      }
    },
    {
      "codice": "001030",
      "nome": "Borgofranco d'Ivrea",
      "nomeStraniero": null,
      "codiceCatastale": "B015",
      "cap": "10013",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "protocollo@comune.borgofranco.to.it",
      "pec": "comune.borgofrancodivrea@pec.it",
      "telefono": "+39 0125 755811",
      "fax": "+39 0125 751328",
      "coordinate": {
        "lat": 45.51285934448242,
        "lng": 7.8580169677734375
      }
    },
    {
      "codice": "001031",
      "nome": "Borgomasino",
      "nomeStraniero": null,
      "codiceCatastale": "B021",
      "cap": "10031",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.borgomasino.to.it",
      "pec": "ragioneria.comune.borgomasino@pec.it",
      "telefono": "+39 0125 670700/0125 770034/0125 770360",
      "fax": "+39 0125 670828/0125 670821",
      "coordinate": {
        "lat": 45.363258361816406,
        "lng": 7.987523078918457
      }
    },
    {
      "codice": "001032",
      "nome": "Borgone Susa",
      "nomeStraniero": null,
      "codiceCatastale": "B024",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.borgonesusa.to.it",
      "pec": "protocollo@pec.comune.borgonesusa.to.it",
      "telefono": "+39 011 9646562",
      "fax": "+39 011 9645001",
      "coordinate": {
        "lat": 45.12284469604492,
        "lng": 7.239712238311768
      }
    },
    {
      "codice": "001033",
      "nome": "Bosconero",
      "nomeStraniero": null,
      "codiceCatastale": "B075",
      "cap": "10080",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "comune@bosconerocanavese.it",
      "pec": "bosconero@cert.ruparpiemonte.it",
      "telefono": "+39 011 9889372",
      "fax": "+39 011 9886148",
      "coordinate": {
        "lat": 45.26755142211914,
        "lng": 7.766600131988525
      }
    },
    {
      "codice": "001034",
      "nome": "Brandizzo",
      "nomeStraniero": null,
      "codiceCatastale": "B121",
      "cap": "10032",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "urp@comune.brandizzo.to.it",
      "pec": "protocollo.comune.brandizzo.to@legalmail.it",
      "telefono": "+39 011 9138093",
      "fax": "+39 011 9139962",
      "coordinate": {
        "lat": 45.176605224609375,
        "lng": 7.838004112243652
      }
    },
    {
      "codice": "001035",
      "nome": "Bricherasio",
      "nomeStraniero": null,
      "codiceCatastale": "B171",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "demografici.bricherasio@ruparpiemonte.it",
      "pec": "anagrafe.bricherasio@pcert.it",
      "telefono": "+39 0121 599360",
      "fax": "+39 0121 598042",
      "coordinate": {
        "lat": 44.82341003417969,
        "lng": 7.305295944213867
      }
    },
    {
      "codice": "001036",
      "nome": "Brosso",
      "nomeStraniero": null,
      "codiceCatastale": "B205",
      "cap": "10080",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "brosso@ruparpiemonte.it",
      "pec": "brosso@cert.ruparpiemonte.it",
      "telefono": "+39 0125 795158",
      "fax": "+39 0125 795248",
      "coordinate": {
        "lat": 45.49250030517578,
        "lng": 7.80328893661499
      }
    },
    {
      "codice": "001037",
      "nome": "Brozolo",
      "nomeStraniero": null,
      "codiceCatastale": "B209",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servizidemografici@comune.brozolo.to.it",
      "pec": "comunebrozolo@pec.it",
      "telefono": "+39 011 9150022",
      "fax": "+39 011 9156899",
      "coordinate": {
        "lat": 45.117435455322266,
        "lng": 8.073602676391602
      }
    },
    {
      "codice": "001038",
      "nome": "Bruino",
      "nomeStraniero": null,
      "codiceCatastale": "B216",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.bruino.to.it",
      "pec": "comune.bruino.to@cert.legalmail.it",
      "telefono": "+39 011 9094450",
      "fax": "+39 011 9048763",
      "coordinate": {
        "lat": 45.02115249633789,
        "lng": 7.468551158905029
      }
    },
    {
      "codice": "001039",
      "nome": "Brusasco",
      "nomeStraniero": null,
      "codiceCatastale": "B225",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.brusasco.to.gov.it",
      "pec": "brusasco@cert.ruparpiemonte.it",
      "telefono": "+39 011 9151101",
      "fax": "+39 011 9156150",
      "coordinate": {
        "lat": 45.15549087524414,
        "lng": 8.060103416442871
      }
    },
    {
      "codice": "001040",
      "nome": "Bruzolo",
      "nomeStraniero": null,
      "codiceCatastale": "B232",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.bruzolo.to.it",
      "pec": "comune.bruzolo@anutel.it",
      "telefono": "+39 011 9637220",
      "fax": "+39 011 9637455",
      "coordinate": {
        "lat": 45.14222717285156,
        "lng": 7.195648193359375
      }
    },
    {
      "codice": "001041",
      "nome": "Buriasco",
      "nomeStraniero": null,
      "codiceCatastale": "B278",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "anagrafe@comune.buriasco.to.it",
      "pec": "protocollo@pec.comune.buriasco.to.it",
      "telefono": "+39 0121 368100",
      "fax": "+39 0121 56599",
      "coordinate": {
        "lat": 44.87363815307617,
        "lng": 7.410319805145264
      }
    },
    {
      "codice": "001042",
      "nome": "Burolo",
      "nomeStraniero": null,
      "codiceCatastale": "B279",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.burolo.to.it",
      "pec": "protocollo@pec.comuneburolo.it",
      "telefono": "+39 0125 57136",
      "fax": "+39 0125 577301",
      "coordinate": {
        "lat": 45.48115921020508,
        "lng": 7.933919906616211
      }
    },
    {
      "codice": "001043",
      "nome": "Busano",
      "nomeStraniero": null,
      "codiceCatastale": "B284",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "busano@ruparpiemonte.it",
      "pec": "busano@cert.ruparpiemonte.it",
      "telefono": "+39 0124 48000",
      "fax": "+39 0124 48568",
      "coordinate": {
        "lat": 45.329437255859375,
        "lng": 7.655732154846191
      }
    },
    {
      "codice": "001044",
      "nome": "Bussoleno",
      "nomeStraniero": null,
      "codiceCatastale": "B297",
      "cap": "10053",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.bussoleno.to.it",
      "pec": "bussoleno@postemailcertificata.it",
      "telefono": "+39 0122 49002",
      "fax": "+39 0122 640414",
      "coordinate": {
        "lat": 45.1392707824707,
        "lng": 7.147716999053955
      }
    },
    {
      "codice": "001045",
      "nome": "Buttigliera Alta",
      "nomeStraniero": null,
      "codiceCatastale": "B305",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comune.buttiglieraalta.to.it",
      "pec": "comune@pec.comune.buttiglieraalta.to.it",
      "telefono": "+39 011 9329340",
      "fax": "+39 011 9329349",
      "coordinate": {
        "lat": 45.06880569458008,
        "lng": 7.434487819671631
      }
    },
    {
      "codice": "001046",
      "nome": "Cafasse",
      "nomeStraniero": null,
      "codiceCatastale": "B350",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "segreteria@comune.cafasse.to.it",
      "pec": "comune.cafasse@pec.it",
      "telefono": "+39 0123 417002",
      "fax": "+39 0123 417141",
      "coordinate": {
        "lat": 45.2459602355957,
        "lng": 7.517234802246094
      }
    },
    {
      "codice": "001047",
      "nome": "Caluso",
      "nomeStraniero": null,
      "codiceCatastale": "B435",
      "cap": "10014",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.caluso.to.it",
      "pec": "protocollo@pec.comune.caluso.to.it",
      "telefono": "+39 011 9894936",
      "fax": "+39 011 9831553",
      "coordinate": {
        "lat": 45.304954528808594,
        "lng": 7.895721912384033
      }
    },
    {
      "codice": "001048",
      "nome": "Cambiano",
      "nomeStraniero": null,
      "codiceCatastale": "B462",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.cambiano.to.it",
      "pec": "comune.cambiano@legalmail.it",
      "telefono": "+39 011/9440105",
      "fax": "+39 011/9441106",
      "coordinate": {
        "lat": 44.97141647338867,
        "lng": 7.778697967529297
      }
    },
    {
      "codice": "001049",
      "nome": "Campiglione Fenile",
      "nomeStraniero": null,
      "codiceCatastale": "B512",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "protocollo@comune.campiglionefenile.to.it",
      "pec": "protocollo@cert.comune.campiglionefenile.to.it",
      "telefono": "+39 0121 590590",
      "fax": "+39 0121 590534",
      "coordinate": {
        "lat": 44.80324935913086,
        "lng": 7.323897838592529
      }
    },
    {
      "codice": "001050",
      "nome": "Candia Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "B588",
      "cap": "10010",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.candia.to.it",
      "pec": "candia.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 011 9834400",
      "fax": "+39 011 9834583",
      "coordinate": {
        "lat": 45.327754974365234,
        "lng": 7.885120868682861
      }
    },
    {
      "codice": "001051",
      "nome": "Candiolo",
      "nomeStraniero": null,
      "codiceCatastale": "B592",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.candiolo.torino.it",
      "pec": "comune.candiolo.to@cert.legalmail.it",
      "telefono": "+39 0119934815",
      "fax": "+39 0119939021",
      "coordinate": {
        "lat": 44.95924377441406,
        "lng": 7.60172176361084
      }
    },
    {
      "codice": "001052",
      "nome": "Canischio",
      "nomeStraniero": null,
      "codiceCatastale": "B605",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.canischio.to.it",
      "pec": "canischio@cert.ruparpiemonte.it",
      "telefono": "+39 0124 659998",
      "fax": "+39 0124 698480",
      "coordinate": {
        "lat": 45.373985290527344,
        "lng": 7.596127033233643
      }
    },
    {
      "codice": "001053",
      "nome": "Cantalupa",
      "nomeStraniero": null,
      "codiceCatastale": "B628",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comune.cantalupa@tin.it",
      "pec": "protocollo@pec.comune.cantalupa.to.it",
      "telefono": "+39 0121 352126",
      "fax": "+39 0121 352119",
      "coordinate": {
        "lat": 44.94594192504883,
        "lng": 7.33031702041626
      }
    },
    {
      "codice": "001054",
      "nome": "Cantoira",
      "nomeStraniero": null,
      "codiceCatastale": "B637",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "municipio@comune.cantoira.to.it",
      "pec": "comune.cantoira@legalmail.it",
      "telefono": "+39 0123 585601",
      "fax": "+39 0123 585016",
      "coordinate": {
        "lat": 45.34334945678711,
        "lng": 7.380124092102051
      }
    },
    {
      "codice": "001055",
      "nome": "Caprie",
      "nomeStraniero": null,
      "codiceCatastale": "B705",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "caprie@comune.caprie.to.it",
      "pec": "comune.caprie.to@legalmail.it",
      "telefono": "+39 011 9632333",
      "fax": "+39 011 9632917",
      "coordinate": {
        "lat": 45.11884689331055,
        "lng": 7.331913948059082
      }
    },
    {
      "codice": "001056",
      "nome": "Caravino",
      "nomeStraniero": null,
      "codiceCatastale": "B733",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.caravino.to.it",
      "pec": "caravino@cert.ruparpiemonte.it",
      "telefono": "+39 0125 778107",
      "fax": "+39 0125 778159",
      "coordinate": {
        "lat": 45.398258209228516,
        "lng": 7.96032190322876
      }
    },
    {
      "codice": "001057",
      "nome": "Carema",
      "nomeStraniero": null,
      "codiceCatastale": "B762",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "demografici@comune.carema.to.it",
      "pec": "comune.carema@pec.comune.carema.to.it",
      "telefono": "+39 0125 811168",
      "fax": "+39 0125 811102",
      "coordinate": {
        "lat": 45.58330154418945,
        "lng": 7.81040620803833
      }
    },
    {
      "codice": "001058",
      "nome": "Carignano",
      "nomeStraniero": null,
      "codiceCatastale": "B777",
      "cap": "10041",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.carignano.to.it",
      "pec": "protocollo@cert.comune.carignano.to.it",
      "telefono": "+39 011 9698443",
      "fax": "+39 011 9698475",
      "coordinate": {
        "lat": 44.906944274902344,
        "lng": 7.673224925994873
      }
    },
    {
      "codice": "001059",
      "nome": "Carmagnola",
      "nomeStraniero": null,
      "codiceCatastale": "B791",
      "cap": "10022",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "urp@comune.carmagnola.to.it",
      "pec": "protocollo.carmagnola@cert.legalmail.it",
      "telefono": "+39 011.9724111",
      "fax": "+39 011.9724239",
      "coordinate": {
        "lat": 44.846946716308594,
        "lng": 7.717874050140381
      }
    },
    {
      "codice": "001060",
      "nome": "Casalborgone",
      "nomeStraniero": null,
      "codiceCatastale": "B867",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.casalborgone.to.it",
      "pec": "casalborgone@postemailcertificata.it",
      "telefono": "+39 011 9174302",
      "fax": "+39 011 9185007",
      "coordinate": {
        "lat": 45.13045120239258,
        "lng": 7.940425872802734
      }
    },
    {
      "codice": "001061",
      "nome": "Cascinette d'Ivrea",
      "nomeStraniero": null,
      "codiceCatastale": "B953",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe@comune.cascinette.to.it",
      "pec": "cascinette@pec.comune.cascinette.to.it",
      "telefono": "+39 0125 615730 0125 615731",
      "fax": "+39 0125 250212",
      "coordinate": {
        "lat": 45.480159759521484,
        "lng": 7.905619144439697
      }
    },
    {
      "codice": "001062",
      "nome": "Caselette",
      "nomeStraniero": null,
      "codiceCatastale": "B955",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.caselette.to.it",
      "pec": "comune.caselette.to@legalmail.it",
      "telefono": "+39 0119688216",
      "fax": "+39 0119688793",
      "coordinate": {
        "lat": 45.1047477722168,
        "lng": 7.480816841125488
      }
    },
    {
      "codice": "001063",
      "nome": "Caselle Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "B960",
      "cap": "10072",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.caselle-torinese.to.it",
      "pec": "comune.caselle-torinese@legalmail.it",
      "telefono": "+39 011 9964000",
      "fax": "+39 011 9964110",
      "coordinate": {
        "lat": 45.177490234375,
        "lng": 7.646367073059082
      }
    },
    {
      "codice": "001064",
      "nome": "Castagneto Po",
      "nomeStraniero": null,
      "codiceCatastale": "C045",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comunedicastagnetopo.it",
      "pec": "comune.castagnetopo.to@legalmail.it",
      "telefono": "+39 011 912921",
      "fax": "+39 011 912681",
      "coordinate": {
        "lat": 45.15925216674805,
        "lng": 7.889424800872803
      }
    },
    {
      "codice": "001065",
      "nome": "Castagnole Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "C048",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@castagnolepiemonte.net",
      "pec": "info@pec.castagnolepiemonte.net",
      "telefono": "+39 011 9862811",
      "fax": "+39 011 9862501",
      "coordinate": {
        "lat": 44.89814376831055,
        "lng": 7.566123008728027
      }
    },
    {
      "codice": "001066",
      "nome": "Castellamonte",
      "nomeStraniero": null,
      "codiceCatastale": "C133",
      "cap": "10081",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "protocollo@comune.castellamonte.to.it",
      "pec": "protocollo@pec.comune.castellamonte.to.it",
      "telefono": "+39 0124 51871",
      "fax": "+39 0124 5187250",
      "coordinate": {
        "lat": 45.381954193115234,
        "lng": 7.7121171951293945
      }
    },
    {
      "codice": "001067",
      "nome": "Castelnuovo Nigra",
      "nomeStraniero": null,
      "codiceCatastale": "C241",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "protocollo@castelnuovo-nigra.it",
      "pec": "comune.castelnuovonigra@pec.it",
      "telefono": "+39 0124 659660",
      "fax": "+39 0124 697039",
      "coordinate": {
        "lat": 45.43815612792969,
        "lng": 7.694614887237549
      }
    },
    {
      "codice": "001068",
      "nome": "Castiglione Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "C307",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.castiglionetorinese.to.it",
      "pec": "comune.castiglionetorinese.to@cert.legalmail.it",
      "telefono": "+39 011 9819111",
      "fax": "+39 011 9819143",
      "coordinate": {
        "lat": 45.121620178222656,
        "lng": 7.815946102142334
      }
    },
    {
      "codice": "001069",
      "nome": "Cavagnolo",
      "nomeStraniero": null,
      "codiceCatastale": "C369",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.cavagnolo.to.it",
      "pec": "cavagnolo@cert.ruparpiemonte.it",
      "telefono": "+39 011 9151157",
      "fax": "+39 011 9151565",
      "coordinate": {
        "lat": 45.152137756347656,
        "lng": 8.05147933959961
      }
    },
    {
      "codice": "001070",
      "nome": "Cavour",
      "nomeStraniero": null,
      "codiceCatastale": "C404",
      "cap": "10061",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "demografici@comune.cavour.to.it",
      "pec": "protocollo@pec.comune.cavour.to.it",
      "telefono": "+39 0121 6466",
      "fax": "+39 0121 68101",
      "coordinate": {
        "lat": 44.785499572753906,
        "lng": 7.373840808868408
      }
    },
    {
      "codice": "001071",
      "nome": "Cercenasco",
      "nomeStraniero": null,
      "codiceCatastale": "C487",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "cercenasco@comune.cercenasco.to.it",
      "pec": "cercenasco@cert.ruparpiemonte.it",
      "telefono": "+39 011/ 9809227",
      "fax": "+39 011 9802731",
      "coordinate": {
        "lat": 44.861289978027344,
        "lng": 7.501269817352295
      }
    },
    {
      "codice": "001072",
      "nome": "Ceres",
      "nomeStraniero": null,
      "codiceCatastale": "C497",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "anagrafe@comune.ceres.to.it",
      "pec": "comune.ceres@pec.comune.ceres.to.it",
      "telefono": "+39 0123 53316",
      "fax": "+39 0123 53501",
      "coordinate": {
        "lat": 45.313377380371094,
        "lng": 7.389688014984131
      }
    },
    {
      "codice": "001073",
      "nome": "Ceresole Reale",
      "nomeStraniero": null,
      "codiceCatastale": "C505",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.ceresolereale.to.it",
      "pec": "ceresole.reale@cert.ruparpiemonte.it",
      "telefono": "+39 0124 953200",
      "fax": "+39 0124 953121",
      "coordinate": {
        "lat": 45.43195343017578,
        "lng": 7.235705852508545
      }
    },
    {
      "codice": "001074",
      "nome": "Cesana Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "C564",
      "cap": "10054",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.cesana.to.it",
      "pec": "comunecesana@legalmail.it",
      "telefono": "+39 0122 89114",
      "fax": "+39 0122 897113",
      "coordinate": {
        "lat": 44.95244216918945,
        "lng": 6.790672779083252
      }
    },
    {
      "codice": "001075",
      "nome": "Chialamberto",
      "nomeStraniero": null,
      "codiceCatastale": "C604",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comune.chialamberto.to.it",
      "pec": "comchialamberto@pec.it",
      "telefono": "+39 0123 506701",
      "fax": "+39 0123 506369",
      "coordinate": {
        "lat": 45.363670349121094,
        "lng": 7.341471195220947
      }
    },
    {
      "codice": "001076",
      "nome": "Chianocco",
      "nomeStraniero": null,
      "codiceCatastale": "C610",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "segreteria@comune.chianocco.to.it",
      "pec": "segreteria@pec.comune.chianocco.to.it",
      "telefono": "+39 0122 49734",
      "fax": "+39 0122 643807",
      "coordinate": {
        "lat": 45.14862823486328,
        "lng": 7.169445037841797
      }
    },
    {
      "codice": "001077",
      "nome": "Chiaverano",
      "nomeStraniero": null,
      "codiceCatastale": "C624",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.chiaverano.to.it",
      "pec": "chiaverano@cert.ruparpiemonte.it",
      "telefono": "+39 0125 54805",
      "fax": "+39 0125 54144",
      "coordinate": {
        "lat": 45.49885940551758,
        "lng": 7.902618885040283
      }
    },
    {
      "codice": "001078",
      "nome": "Chieri",
      "nomeStraniero": null,
      "codiceCatastale": "C627",
      "cap": "10023",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.chieri.to.it",
      "pec": "protocollo.chieri@pcert.it",
      "telefono": "+39 011 94281",
      "fax": "+39 011 9470250",
      "coordinate": {
        "lat": 45.013885498046875,
        "lng": 7.822353839874268
      }
    },
    {
      "codice": "001079",
      "nome": "Chiesanuova",
      "nomeStraniero": null,
      "codiceCatastale": "C629",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "comchiesanuova@libero.it",
      "pec": "comchiesanuova@postecert.it",
      "telefono": "+39 0124 666014",
      "fax": "+39 0124 652371",
      "coordinate": {
        "lat": 45.417884826660156,
        "lng": 7.655795097351074
      }
    },
    {
      "codice": "001080",
      "nome": "Chiomonte",
      "nomeStraniero": null,
      "codiceCatastale": "C639",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.chiomonte.to.it",
      "pec": "segreteria.chiomonte@pec.it",
      "telefono": "+39 0122 54104",
      "fax": "+39 0122 54504",
      "coordinate": {
        "lat": 45.11954116821289,
        "lng": 6.9853057861328125
      }
    },
    {
      "codice": "001081",
      "nome": "Chiusa di San Michele",
      "nomeStraniero": null,
      "codiceCatastale": "C655",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.chiusadisanmichele.to.it",
      "pec": "protocollo.chiusa.san.michele@cert.ruparpiemonte.it",
      "telefono": "+39 011 9643140",
      "fax": "+39 011 9644457",
      "coordinate": {
        "lat": 45.103912353515625,
        "lng": 7.326972007751465
      }
    },
    {
      "codice": "001082",
      "nome": "Chivasso",
      "nomeStraniero": null,
      "codiceCatastale": "C665",
      "cap": "10034",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.chivasso.to.it",
      "pec": "protocollo@pec.comune.chivasso.to.it",
      "telefono": "+39 011 91151",
      "fax": "+39 011 9112989",
      "coordinate": {
        "lat": 45.19095993041992,
        "lng": 7.887186050415039
      }
    },
    {
      "codice": "001083",
      "nome": "Ciconio",
      "nomeStraniero": null,
      "codiceCatastale": "C679",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "anagrafe@comune.ciconio.to.it",
      "pec": "ciconio@cert.ruparpiemonte.it",
      "telefono": "+39 0124 302005",
      "fax": "+39 0124 302935",
      "coordinate": {
        "lat": 45.32991409301758,
        "lng": 7.759492874145508
      }
    },
    {
      "codice": "001084",
      "nome": "Cintano",
      "nomeStraniero": null,
      "codiceCatastale": "C711",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "cintano@ruparpiemonte.it",
      "pec": "cintano@cert.ruparpiemonte.it",
      "telefono": "+39 0124 699803",
      "fax": "+39 0124 699803",
      "coordinate": {
        "lat": 45.42855453491211,
        "lng": 7.688115119934082
      }
    },
    {
      "codice": "001085",
      "nome": "Cinzano",
      "nomeStraniero": null,
      "codiceCatastale": "C715",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografico@comune.cinzano.it",
      "pec": "comune.cinzanotorino.to@cert.legalmail.it",
      "telefono": "+39 011 9608123",
      "fax": "+39 011 9608227",
      "coordinate": {
        "lat": 45.094608306884766,
        "lng": 7.923964023590088
      }
    },
    {
      "codice": "001086",
      "nome": "Ciriè",
      "nomeStraniero": null,
      "codiceCatastale": "C722",
      "cap": "10073",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "comunecirie@comune.cirie.to.it",
      "pec": "protocollo.cirie@cert.ruparpiemonte.it",
      "telefono": "+39 011 9218111",
      "fax": "+39 011 9218600",
      "coordinate": {
        "lat": 45.23527526855469,
        "lng": 7.600307941436768
      }
    },
    {
      "codice": "001087",
      "nome": "Claviere",
      "nomeStraniero": null,
      "codiceCatastale": "C793",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "comune.claviere@tin.it",
      "pec": "comuneclaviere@legalmail.it",
      "telefono": "+39 0122 878821",
      "fax": "+39 0122 878861",
      "coordinate": {
        "lat": 44.93880081176758,
        "lng": 6.750294208526611
      }
    },
    {
      "codice": "001088",
      "nome": "Coassolo Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "C801",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comune.coassolo.to.it",
      "pec": "comune.coassolo@documentipec.com",
      "telefono": "+39 0123 45401",
      "fax": "+39 0123 45617",
      "coordinate": {
        "lat": 45.297584533691406,
        "lng": 7.460457801818848
      }
    },
    {
      "codice": "001089",
      "nome": "Coazze",
      "nomeStraniero": null,
      "codiceCatastale": "C803",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteriacomunale@comune.coazze.to.it",
      "pec": "protocollo@pec.comune.coazze.to.it",
      "telefono": "+39 011 9340056",
      "fax": "+39 011 9340429",
      "coordinate": {
        "lat": 45.05219268798828,
        "lng": 7.2979021072387695
      }
    },
    {
      "codice": "001090",
      "nome": "Collegno",
      "nomeStraniero": null,
      "codiceCatastale": "C860",
      "cap": "10093",
      "prefisso": "011",
      "provincia": "Torino",
      "email": null,
      "pec": "posta@cert.comune.collegno.to.it",
      "telefono": "+39 01140151",
      "fax": "+39 011/4015500",
      "coordinate": {
        "lat": 45.077545166015625,
        "lng": 7.572425842285156
      }
    },
    {
      "codice": "001091",
      "nome": "Colleretto Castelnuovo",
      "nomeStraniero": null,
      "codiceCatastale": "C867",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.collerettocastelnuovo.to.it",
      "pec": "colleretto.castelnuovo@cert.ruparpiemonte.it",
      "telefono": "+39 0124 699812",
      "fax": "+39 0124 690921",
      "coordinate": {
        "lat": 45.42275619506836,
        "lng": 7.680039882659912
      }
    },
    {
      "codice": "001092",
      "nome": "Colleretto Giacosa",
      "nomeStraniero": null,
      "codiceCatastale": "C868",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "colleretto.giacosa@canavese.to.it",
      "pec": "colleretto.giacosa@cert.ruparpiemonte.it",
      "telefono": "+39 0125 76125",
      "fax": "+39 0125 76282",
      "coordinate": {
        "lat": 45.431888580322266,
        "lng": 7.799592018127441
      }
    },
    {
      "codice": "001093",
      "nome": "Condove",
      "nomeStraniero": null,
      "codiceCatastale": "C955",
      "cap": "10055",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "croce.donatella@comune.condove.to.it",
      "pec": "condove@postemailcertificata.it",
      "telefono": "+39 011 9643102",
      "fax": "+39 011 9644197",
      "coordinate": {
        "lat": 45.1174201965332,
        "lng": 7.309454917907715
      }
    },
    {
      "codice": "001094",
      "nome": "Corio",
      "nomeStraniero": null,
      "codiceCatastale": "D008",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.corio.to.it",
      "pec": "info@pec.comune.corio.to.it",
      "telefono": "+39 011 9282105",
      "fax": "+39 011 928557",
      "coordinate": {
        "lat": 45.313499450683594,
        "lng": 7.5320868492126465
      }
    },
    {
      "codice": "001095",
      "nome": "Cossano Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "D092",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "cossano.canavese@ruparpiemonte.it",
      "pec": "protocollo.cossano.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0125 779947",
      "fax": "+39 0125 781800",
      "coordinate": {
        "lat": 45.38775634765625,
        "lng": 7.991522789001465
      }
    },
    {
      "codice": "001096",
      "nome": "Cuceglio",
      "nomeStraniero": null,
      "codiceCatastale": "D197",
      "cap": "10090",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "cuceglio@canavese.it",
      "pec": "cuceglio@cert.ruparpiemonte.it",
      "telefono": "+39 0124 32012",
      "fax": "+39 0124 492921",
      "coordinate": {
        "lat": 45.35943603515625,
        "lng": 7.815186977386475
      }
    },
    {
      "codice": "001097",
      "nome": "Cumiana",
      "nomeStraniero": null,
      "codiceCatastale": "D202",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comune.cumiana.to.it",
      "pec": "comune.cumiana.to@legalmail.it",
      "telefono": "+39 011/9059001",
      "fax": "+39 011/9050735",
      "coordinate": {
        "lat": 44.979740142822266,
        "lng": 7.3767170906066895
      }
    },
    {
      "codice": "001098",
      "nome": "Cuorgnè",
      "nomeStraniero": null,
      "codiceCatastale": "D208",
      "cap": "10082",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "carlo.araldi@comune.cuorgne.to.it",
      "pec": "comune.cuorgne.to.it@pec.it",
      "telefono": "+39 0124655215",
      "fax": "+39 0124650569",
      "coordinate": {
        "lat": 45.38972091674805,
        "lng": 7.649816989898682
      }
    },
    {
      "codice": "001099",
      "nome": "Druento",
      "nomeStraniero": null,
      "codiceCatastale": "D373",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": null,
      "pec": "comune.druento.to@legalmail.it",
      "telefono": "+39 011 9940730",
      "fax": "+39 011 9941019",
      "coordinate": {
        "lat": 45.13474655151367,
        "lng": 7.576618194580078
      }
    },
    {
      "codice": "001100",
      "nome": "Exilles",
      "nomeStraniero": null,
      "codiceCatastale": "D433",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.exilles.to.it",
      "pec": "comune.exilles@legalmail.it",
      "telefono": "+39 0122 58301",
      "fax": "+39 0122 58151",
      "coordinate": {
        "lat": 45.097381591796875,
        "lng": 6.929065227508545
      }
    },
    {
      "codice": "001101",
      "nome": "Favria",
      "nomeStraniero": null,
      "codiceCatastale": "D520",
      "cap": "10083",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "protocollo@comune.favria.to.it",
      "pec": "segreteria.favria@pec.it",
      "telefono": "+39 0124 34009",
      "fax": "+39 0124 349258",
      "coordinate": {
        "lat": 45.331153869628906,
        "lng": 7.690617084503174
      }
    },
    {
      "codice": "001102",
      "nome": "Feletto",
      "nomeStraniero": null,
      "codiceCatastale": "D524",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.feletto.to.it",
      "pec": "protocollo.feletto@pec.it",
      "telefono": "+39 0124 490547",
      "fax": "+39 0124 490257",
      "coordinate": {
        "lat": 45.30385208129883,
        "lng": 7.7447190284729
      }
    },
    {
      "codice": "001103",
      "nome": "Fenestrelle",
      "nomeStraniero": null,
      "codiceCatastale": "D532",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comune.fenestrelle@alpimedia.it",
      "pec": "fenestrelle@postemailcertificata.it",
      "telefono": "+39 0121 83910",
      "fax": "+39 0121 83943",
      "coordinate": {
        "lat": 45.036094665527344,
        "lng": 7.049561977386475
      }
    },
    {
      "codice": "001104",
      "nome": "Fiano",
      "nomeStraniero": null,
      "codiceCatastale": "D562",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.fiano.to.it",
      "pec": "fiano@postemailcertificata.it",
      "telefono": "+39 011 9254302",
      "fax": "+39 011 9254966",
      "coordinate": {
        "lat": 45.21686935424805,
        "lng": 7.5226850509643555
      }
    },
    {
      "codice": "001105",
      "nome": "Fiorano Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "D608",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.fioranocanavese.to.it",
      "pec": "fiorano.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0125 611930",
      "fax": "+39 0125 611885",
      "coordinate": {
        "lat": 45.46775817871094,
        "lng": 7.8337178230285645
      }
    },
    {
      "codice": "001106",
      "nome": "Foglizzo",
      "nomeStraniero": null,
      "codiceCatastale": "D646",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "sindaco@comune.foglizzo.to.it",
      "pec": "foglizzo@cert.ruparpiemonte.it",
      "telefono": "+39 011 9883404",
      "fax": "+39 011 9883312",
      "coordinate": {
        "lat": 45.271270751953125,
        "lng": 7.822265148162842
      }
    },
    {
      "codice": "001107",
      "nome": "Forno Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "D725",
      "cap": "10084",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "servizidemografici@comune.fornocanavese.to.it",
      "pec": "fornocanavese@postemailcertificata.it",
      "telefono": "+39 0124 77844",
      "fax": "+39 0124 700491",
      "coordinate": {
        "lat": 45.34535217285156,
        "lng": 7.591014862060547
      }
    },
    {
      "codice": "001108",
      "nome": "Frassinetto",
      "nomeStraniero": null,
      "codiceCatastale": "D781",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.frassinetto.to.it",
      "pec": "comune.frassinetto@pec.it",
      "telefono": "+39 0124 801007",
      "fax": "+39 0124 801033",
      "coordinate": {
        "lat": 45.43714141845703,
        "lng": 7.606742858886719
      }
    },
    {
      "codice": "001109",
      "nome": "Front",
      "nomeStraniero": null,
      "codiceCatastale": "D805",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.front.to",
      "pec": "comune.front@pec.it",
      "telefono": "+39 011 9251510",
      "fax": "+39 011 9251749",
      "coordinate": {
        "lat": 45.280853271484375,
        "lng": 7.663618087768555
      }
    },
    {
      "codice": "001110",
      "nome": "Frossasco",
      "nomeStraniero": null,
      "codiceCatastale": "D812",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "areademografici@comunefrossasco.it",
      "pec": "comune.frossasco.to@legalmail.it",
      "telefono": "+39 0121 352104",
      "fax": "+39 0121 352010",
      "coordinate": {
        "lat": 44.932945251464844,
        "lng": 7.350217819213867
      }
    },
    {
      "codice": "001111",
      "nome": "Garzigliana",
      "nomeStraniero": null,
      "codiceCatastale": "D931",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "protocollo@comune.garzigliana.to.it",
      "pec": "garzigliana@cert.ruparpiemonte.it",
      "telefono": "+39 0121 341107",
      "fax": "+39 0121 341270",
      "coordinate": {
        "lat": 44.83723831176758,
        "lng": 7.374420166015625
      }
    },
    {
      "codice": "001112",
      "nome": "Gassino Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "D933",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.gassino.to.it",
      "pec": "gassino@cert.ruparpiemonte.it",
      "telefono": "+39 011 9600777",
      "fax": "+39 011 9818380",
      "coordinate": {
        "lat": 45.127113342285156,
        "lng": 7.82479190826416
      }
    },
    {
      "codice": "001113",
      "nome": "Germagnano",
      "nomeStraniero": null,
      "codiceCatastale": "D983",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "segreteria@municipiodigermagnano.it",
      "pec": "segreteria.protocollo@pcert.postecert.it",
      "telefono": "+39 0123 27742",
      "fax": "+39 0123 29165",
      "coordinate": {
        "lat": 45.26387023925781,
        "lng": 7.469482898712158
      }
    },
    {
      "codice": "001114",
      "nome": "Giaglione",
      "nomeStraniero": null,
      "codiceCatastale": "E009",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.giaglione.to.it",
      "pec": "comune.giaglione@actaliscertymail.it",
      "telefono": "+39 0122 622386",
      "fax": "+39 0122 622920",
      "coordinate": {
        "lat": 45.14023971557617,
        "lng": 7.014756202697754
      }
    },
    {
      "codice": "001115",
      "nome": "Giaveno",
      "nomeStraniero": null,
      "codiceCatastale": "E020",
      "cap": "10094",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@giaveno.it",
      "pec": "protocollo@cert.comune.giaveno.to.it",
      "telefono": "+39 011.9326400",
      "fax": "+39 011.9364039",
      "coordinate": {
        "lat": 45.0420036315918,
        "lng": 7.351994037628174
      }
    },
    {
      "codice": "001116",
      "nome": "Givoletto",
      "nomeStraniero": null,
      "codiceCatastale": "E067",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.givoletto.to.it",
      "pec": "protocollo@pec.comune.givoletto.to.it",
      "telefono": "+39 011/9947036",
      "fax": "+39 011/9947154",
      "coordinate": {
        "lat": 45.16224670410156,
        "lng": 7.496416091918945
      }
    },
    {
      "codice": "001117",
      "nome": "Gravere",
      "nomeStraniero": null,
      "codiceCatastale": "E154",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.gravere.to.it",
      "pec": "gravere@cert.ruparpiemonte.it",
      "telefono": "+39 0122 622912",
      "fax": "+39 0122 32534",
      "coordinate": {
        "lat": 45.125343322753906,
        "lng": 7.017507076263428
      }
    },
    {
      "codice": "001118",
      "nome": "Groscavallo",
      "nomeStraniero": null,
      "codiceCatastale": "E199",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comune.groscavallo.to.it",
      "pec": "info@pec.groscavallo.to.it",
      "telefono": "+39 0123 81003",
      "fax": "+39 0123 819907",
      "coordinate": {
        "lat": 45.36849594116211,
        "lng": 7.258777141571045
      }
    },
    {
      "codice": "001119",
      "nome": "Grosso",
      "nomeStraniero": null,
      "codiceCatastale": "E203",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.grosso.to.it",
      "pec": "grosso@pcert.it",
      "telefono": "+39 011 9267989",
      "fax": "+39 011 9269626",
      "coordinate": {
        "lat": 45.257503509521484,
        "lng": 7.5579071044921875
      }
    },
    {
      "codice": "001120",
      "nome": "Grugliasco",
      "nomeStraniero": null,
      "codiceCatastale": "E216",
      "cap": "10095",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.grugliasco.to.it",
      "pec": "grugliasco@cert.ruparpiemonte.it",
      "telefono": "+39 011 40131",
      "fax": "+39 011 7805027",
      "coordinate": {
        "lat": 45.06804656982422,
        "lng": 7.577620029449463
      }
    },
    {
      "codice": "001121",
      "nome": "Ingria",
      "nomeStraniero": null,
      "codiceCatastale": "E301",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.ingria.to.it",
      "pec": "protocollo@pec.comune.ingria.to.it",
      "telefono": "+39 0124 85629",
      "fax": "+39 0124 811203",
      "coordinate": {
        "lat": 45.466468811035156,
        "lng": 7.570917129516602
      }
    },
    {
      "codice": "001122",
      "nome": "Inverso Pinasca",
      "nomeStraniero": null,
      "codiceCatastale": "E311",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comune.inverso@alpimedia.it",
      "pec": "comune.inverso@legalmail.it",
      "telefono": "+39 0121 800706",
      "fax": "+39 0121 800600",
      "coordinate": {
        "lat": 44.945640563964844,
        "lng": 7.218413829803467
      }
    },
    {
      "codice": "001123",
      "nome": "Isolabella",
      "nomeStraniero": null,
      "codiceCatastale": "E345",
      "cap": "10046",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "posta@comune.isolabella.to.it",
      "pec": "isolabella@cert.ruparpiemonte.it",
      "telefono": "+39 011 9463248",
      "fax": "+39 011 9463155",
      "coordinate": {
        "lat": 44.9067268371582,
        "lng": 7.91015100479126
      }
    },
    {
      "codice": "001124",
      "nome": "Issiglio",
      "nomeStraniero": null,
      "codiceCatastale": "E368",
      "cap": "10080",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "comune.issiglio@libero.it",
      "pec": "comune.issiglio@postecert.it",
      "telefono": "+39 0125 789079",
      "fax": "+39 0125 791984",
      "coordinate": {
        "lat": 45.44693374633789,
        "lng": 7.752121925354004
      }
    },
    {
      "codice": "001125",
      "nome": "Ivrea",
      "nomeStraniero": null,
      "codiceCatastale": "E379",
      "cap": "10015",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "protocollo-gen@comune.ivrea.to.it",
      "pec": "protocollo@pec.comune.ivrea.to.it",
      "telefono": "+39 0125 4101",
      "fax": "+39 0125 410287",
      "coordinate": {
        "lat": 45.46738052368164,
        "lng": 7.8747968673706055
      }
    },
    {
      "codice": "001126",
      "nome": "La Cassa",
      "nomeStraniero": null,
      "codiceCatastale": "E394",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteriaprotocollo.la.cassa@ruparpiemonte.it",
      "pec": "la.cassa@cert.ruparpiemonte.it",
      "telefono": "+39 011 9842918",
      "fax": "+39 011 9842965",
      "coordinate": {
        "lat": 45.18028259277344,
        "lng": 7.5162129402160645
      }
    },
    {
      "codice": "001127",
      "nome": "La Loggia",
      "nomeStraniero": null,
      "codiceCatastale": "E423",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografico.attprod@comune.laloggia.to.it",
      "pec": "protocollo.laloggia@legalmail.it",
      "telefono": "+39 011 9629082",
      "fax": "+39 011 9628816",
      "coordinate": {
        "lat": 44.95774459838867,
        "lng": 7.667324066162109
      }
    },
    {
      "codice": "001128",
      "nome": "Lanzo Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "E445",
      "cap": "10074",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "elettorale@comune.lanzotorinese.to.it",
      "pec": "comune.lanzotorinese@legalmail.it",
      "telefono": "+39 0123 300408",
      "fax": "+39 0123 27420",
      "coordinate": {
        "lat": 45.27334976196289,
        "lng": 7.477410793304443
      }
    },
    {
      "codice": "001129",
      "nome": "Lauriano",
      "nomeStraniero": null,
      "codiceCatastale": "E484",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.lauriano.to.it",
      "pec": "lauriano@cert.ruparpiemonte.it",
      "telefono": "+39 011 9187801",
      "fax": "+39 011 9187482",
      "coordinate": {
        "lat": 45.15825271606445,
        "lng": 7.991627216339111
      }
    },
    {
      "codice": "001130",
      "nome": "Leini",
      "nomeStraniero": null,
      "codiceCatastale": "E518",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "a.porcelli@comune.leini.to.it",
      "pec": "comune.leini.to@cert.legalmail.it",
      "telefono": "+39 011/9986391",
      "fax": "+39 011/9986360",
      "coordinate": {
        "lat": 45.1845817565918,
        "lng": 7.713271141052246
      }
    },
    {
      "codice": "001131",
      "nome": "Lemie",
      "nomeStraniero": null,
      "codiceCatastale": "E520",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "lemie@ruparpiemonte.it",
      "pec": "lemie@cert.ruparpiemonte.it",
      "telefono": "+39 0123 60222",
      "fax": "+39 0123 680984",
      "coordinate": {
        "lat": 45.22819900512695,
        "lng": 7.2920451164245605
      }
    },
    {
      "codice": "001132",
      "nome": "Lessolo",
      "nomeStraniero": null,
      "codiceCatastale": "E551",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "sindaco@comune.lessolo.to.it",
      "pec": "comune.lessolo.to@cert.legalmail.it",
      "telefono": "+39 0125/58103",
      "fax": "+39 0125/58115",
      "coordinate": {
        "lat": 45.47825622558594,
        "lng": 7.814716815948486
      }
    },
    {
      "codice": "001133",
      "nome": "Levone",
      "nomeStraniero": null,
      "codiceCatastale": "E566",
      "cap": "10070",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.levone.to.it",
      "pec": "levone@cert.ruparpiemonte.it",
      "telefono": "+39 0124 306000",
      "fax": "+39 0124 306206",
      "coordinate": {
        "lat": 45.31820297241211,
        "lng": 7.606186866760254
      }
    },
    {
      "codice": "001134",
      "nome": "Locana",
      "nomeStraniero": null,
      "codiceCatastale": "E635",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "anagrafe.locana@ruparpiemonte.it",
      "pec": "demografici.locana@actaliscertymail.it",
      "telefono": "+39 0124 813000",
      "fax": "+39 0124 83321",
      "coordinate": {
        "lat": 45.41765213012695,
        "lng": 7.4588141441345215
      }
    },
    {
      "codice": "001135",
      "nome": "Lombardore",
      "nomeStraniero": null,
      "codiceCatastale": "E660",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.lombardore.to.it",
      "pec": "amministrativo.lombardore@pec.it",
      "telefono": "+39 011 9956101",
      "fax": "+39 011 9956036",
      "coordinate": {
        "lat": 45.2352294921875,
        "lng": 7.736551761627197
      }
    },
    {
      "codice": "001136",
      "nome": "Lombriasco",
      "nomeStraniero": null,
      "codiceCatastale": "E661",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.lombriasco.to.it",
      "pec": "protocollo@pec.comune.lombriasco.to.it",
      "telefono": "+39 011 9790133",
      "fax": "+39 011 9790181",
      "coordinate": {
        "lat": 44.8409423828125,
        "lng": 7.635924816131592
      }
    },
    {
      "codice": "001137",
      "nome": "Loranzè",
      "nomeStraniero": null,
      "codiceCatastale": "E683",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "loranze@reteunitaria.piemonte.it",
      "pec": "tributi.loranze@anutel.it",
      "telefono": "+39 0125 53646",
      "fax": "+39 0125 53647",
      "coordinate": {
        "lat": 45.44165802001953,
        "lng": 7.812717914581299
      }
    },
    {
      "codice": "001139",
      "nome": "Luserna San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "E758",
      "cap": "10062",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "urp@comune.luserna.to.it",
      "pec": "protocollo@pec.comune.luserna.to.it",
      "telefono": "+39 0121 954114",
      "fax": "+39 0121 902021",
      "coordinate": {
        "lat": 44.81543731689453,
        "lng": 7.251317977905273
      }
    },
    {
      "codice": "001140",
      "nome": "Lusernetta",
      "nomeStraniero": null,
      "codiceCatastale": "E759",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "uf_analusernetta@virgilio.it",
      "pec": "comune.lusernetta.to@legalmail.it",
      "telefono": "+39 0121 954249",
      "fax": "+39 0121 954249",
      "coordinate": {
        "lat": 44.803138732910156,
        "lng": 7.246717929840088
      }
    },
    {
      "codice": "001141",
      "nome": "Lusigliè",
      "nomeStraniero": null,
      "codiceCatastale": "E763",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "lusiglie@ruparpiemonte.it",
      "pec": "lusiglie@cert.ruparpiemonte.it",
      "telefono": "+39 0124 300017",
      "fax": "+39 0124 243484",
      "coordinate": {
        "lat": 45.31840133666992,
        "lng": 7.7666521072387695
      }
    },
    {
      "codice": "001142",
      "nome": "Macello",
      "nomeStraniero": null,
      "codiceCatastale": "E782",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "demografici@comune.macello.to.it",
      "pec": "protocollo@pec.comune.macello.to.it",
      "telefono": "+39 0121 340301",
      "fax": "+39 0121 340126",
      "coordinate": {
        "lat": 44.851139068603516,
        "lng": 7.398019790649414
      }
    },
    {
      "codice": "001143",
      "nome": "Maglione",
      "nomeStraniero": null,
      "codiceCatastale": "E817",
      "cap": "10030",
      "prefisso": "0161",
      "provincia": "Torino",
      "email": "info@comune.maglione.to.it",
      "pec": "protocollo.maglione@cert.ruparpiemonte.it",
      "telefono": "+39 0161 400123",
      "fax": "+39 0161 400257",
      "coordinate": {
        "lat": 45.346656799316406,
        "lng": 8.013323783874512
      }
    },
    {
      "codice": "001316",
      "nome": "Mappano",
      "nomeStraniero": null,
      "codiceCatastale": "M316",
      "cap": "10079",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.mappano.to.it",
      "pec": "comune.mappano.to@legalmail.it",
      "telefono": "+39 011 9969952",
      "fax": "+39 011 9968329",
      "coordinate": {
        "lat": 45.14833450317383,
        "lng": 7.707777976989746
      }
    },
    {
      "codice": "001144",
      "nome": "Marentino",
      "nomeStraniero": null,
      "codiceCatastale": "E941",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.marentino.to.it",
      "pec": "comune.marentino@legalmail.it",
      "telefono": "+39 011 9435412",
      "fax": "+39 011 9435329",
      "coordinate": {
        "lat": 45.05803298950195,
        "lng": 7.874622821807861
      }
    },
    {
      "codice": "001145",
      "nome": "Massello",
      "nomeStraniero": null,
      "codiceCatastale": "F041",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comunemassello@alpimedia.it",
      "pec": "massello@cert.ruparpiemonte.it",
      "telefono": "+39 0121 808834",
      "fax": "+39 0121091391",
      "coordinate": {
        "lat": 44.95851516723633,
        "lng": 7.0569939613342285
      }
    },
    {
      "codice": "001146",
      "nome": "Mathi",
      "nomeStraniero": null,
      "codiceCatastale": "F053",
      "cap": "10075",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.mathi.to.it",
      "pec": "protocollo@pec.comune.mathi.to.it",
      "telefono": "+39 0119261601",
      "fax": "+39 011 9261628",
      "coordinate": {
        "lat": 45.25517272949219,
        "lng": 7.5419840812683105
      }
    },
    {
      "codice": "001147",
      "nome": "Mattie",
      "nomeStraniero": null,
      "codiceCatastale": "F058",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.mattie.to.it",
      "pec": "comune.mattie@actaliscertymail.it",
      "telefono": "+39 0122 38124",
      "fax": null,
      "coordinate": {
        "lat": 45.118141174316406,
        "lng": 7.115108966827393
      }
    },
    {
      "codice": "001148",
      "nome": "Mazzè",
      "nomeStraniero": null,
      "codiceCatastale": "F067",
      "cap": "10035",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.mazze.to.it",
      "pec": "comune.mazze@postecert.it",
      "telefono": "+39 011 9835901",
      "fax": "+39 011 9830478",
      "coordinate": {
        "lat": 45.300254821777344,
        "lng": 7.932723045349121
      }
    },
    {
      "codice": "001149",
      "nome": "Meana di Susa",
      "nomeStraniero": null,
      "codiceCatastale": "F074",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.meanadisusa.to.it",
      "pec": "meanadisusa@postemailcertificata.it",
      "telefono": "+39 0122 39161",
      "fax": null,
      "coordinate": {
        "lat": 45.12204360961914,
        "lng": 7.064708232879639
      }
    },
    {
      "codice": "001150",
      "nome": "Mercenasco",
      "nomeStraniero": null,
      "codiceCatastale": "F140",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "pm.mercenasco@ruparpiemonte.it",
      "pec": "mercenasco@cert.ruparpiemonte.it",
      "telefono": "+39 0125 710444",
      "fax": "+39 0125 710066",
      "coordinate": {
        "lat": 45.357181549072266,
        "lng": 7.882359027862549
      }
    },
    {
      "codice": "001152",
      "nome": "Mezzenile",
      "nomeStraniero": null,
      "codiceCatastale": "F182",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "anagrafe@comune.mezzenile.to.it",
      "pec": "mezzenile@cert.ruparpiemonte.it",
      "telefono": "+39 0123 581124",
      "fax": "+39 0123 581509",
      "coordinate": {
        "lat": 45.295127868652344,
        "lng": 7.394458770751953
      }
    },
    {
      "codice": "001153",
      "nome": "Mombello di Torino",
      "nomeStraniero": null,
      "codiceCatastale": "F315",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comune.mombelloditorino.to.it",
      "pec": "mombello.di.torino@cert.ruparpiemonte.it",
      "telefono": "+39 011 9925117",
      "fax": "+39 011 9925117",
      "coordinate": {
        "lat": 45.04574966430664,
        "lng": 7.9208269119262695
      }
    },
    {
      "codice": "001154",
      "nome": "Mompantero",
      "nomeStraniero": null,
      "codiceCatastale": "F318",
      "cap": "10059",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "anagrafe@comune.mompantero.to.it",
      "pec": "certificata@pec.comune.mompantero.to.it",
      "telefono": "+39 0122 622323",
      "fax": "+39 0122 629427",
      "coordinate": {
        "lat": 45.14204406738281,
        "lng": 7.064609050750732
      }
    },
    {
      "codice": "001155",
      "nome": "Monastero di Lanzo",
      "nomeStraniero": null,
      "codiceCatastale": "F327",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "comunedimonastero.to@libero.it",
      "pec": "monastero.di.lanzo@cert.ruparpiemonte.it",
      "telefono": "+39 0123 4301",
      "fax": "+39 0123 422900",
      "coordinate": {
        "lat": 45.30187225341797,
        "lng": 7.439681053161621
      }
    },
    {
      "codice": "001156",
      "nome": "Moncalieri",
      "nomeStraniero": null,
      "codiceCatastale": "F335",
      "cap": "10024",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.moncalieri.to.it",
      "pec": "protocollo@cert.comune.moncalieri.to.it",
      "telefono": "+39 011 6401325",
      "fax": "+39 011 641246",
      "coordinate": {
        "lat": 45.00046157836914,
        "lng": 7.684753894805908
      }
    },
    {
      "codice": "001157",
      "nome": "Moncenisio",
      "nomeStraniero": null,
      "codiceCatastale": "D553",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.moncenisio.to.it",
      "pec": "comune.moncenisio@legalmail.it",
      "telefono": "+39 0122 653222",
      "fax": "+39 0122 629980",
      "coordinate": {
        "lat": 45.205284118652344,
        "lng": 6.982500076293945
      }
    },
    {
      "codice": "001158",
      "nome": "Montaldo Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "F407",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "montaldotorinese@ruparpiemonte.it",
      "pec": "comune.montaldotorinese.to@cert.legalmail.it",
      "telefono": "+39 011 9408089",
      "fax": "+39 011 9406878",
      "coordinate": {
        "lat": 45.065486907958984,
        "lng": 7.849600791931152
      }
    },
    {
      "codice": "001159",
      "nome": "Montalenghe",
      "nomeStraniero": null,
      "codiceCatastale": "F411",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "ragioneria@comune.montalenghe.to.it",
      "pec": "ragioneria.comune.montalenghe@pec.it",
      "telefono": "+39 011 9839458",
      "fax": "+39 011 9839202",
      "coordinate": {
        "lat": 45.33745574951172,
        "lng": 7.838819980621338
      }
    },
    {
      "codice": "001160",
      "nome": "Montalto Dora",
      "nomeStraniero": null,
      "codiceCatastale": "F420",
      "cap": "10016",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "demografico@comune.montalto-dora.to.it",
      "pec": "protocollo.montalto.dora@cert.ruparpiemonte.it",
      "telefono": "+39 0125 650014",
      "fax": "+39 0125 651043",
      "coordinate": {
        "lat": 45.490760803222656,
        "lng": 7.862817764282227
      }
    },
    {
      "codice": "001161",
      "nome": "Montanaro",
      "nomeStraniero": null,
      "codiceCatastale": "F422",
      "cap": "10017",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comunemontanaro.it",
      "pec": "comune.montanaro.to@legalmail.it",
      "telefono": "+39 011 9160102",
      "fax": "+39 011 9193035",
      "coordinate": {
        "lat": 45.23355484008789,
        "lng": 7.853322982788086
      }
    },
    {
      "codice": "001162",
      "nome": "Monteu da Po",
      "nomeStraniero": null,
      "codiceCatastale": "F651",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.monteudapo.to.it",
      "pec": "protocollo.comune.monteudapo@pec.it",
      "telefono": "+39 011 9187813",
      "fax": "+39 011 9187128",
      "coordinate": {
        "lat": 45.150901794433594,
        "lng": 8.016846656799316
      }
    },
    {
      "codice": "001163",
      "nome": "Moriondo Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "F733",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici.protocollo@comune.moriondo.to.it",
      "pec": "comune.moriondotorinese.to@legalmail.it",
      "telefono": "+39 011 9876137",
      "fax": "+39 011 9927742",
      "coordinate": {
        "lat": 45.03764724731445,
        "lng": 7.940028190612793
      }
    },
    {
      "codice": "001164",
      "nome": "Nichelino",
      "nomeStraniero": null,
      "codiceCatastale": "F889",
      "cap": "10042",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.nichelino.to.it",
      "pec": "protocollo@cert.comune.nichelino.to.it",
      "telefono": "+39 011 6819530",
      "fax": "+39 011 6819572",
      "coordinate": {
        "lat": 44.99551773071289,
        "lng": 7.646617889404297
      }
    },
    {
      "codice": "001165",
      "nome": "Noasca",
      "nomeStraniero": null,
      "codiceCatastale": "F906",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "cnoasca@eurexnet.it",
      "pec": "comunenoasca.to@pec.it",
      "telefono": "+39 0124 901001",
      "fax": "+39 0124 901074",
      "coordinate": {
        "lat": 45.45397186279297,
        "lng": 7.314554214477539
      }
    },
    {
      "codice": "001166",
      "nome": "Nole",
      "nomeStraniero": null,
      "codiceCatastale": "F925",
      "cap": "10076",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.nole.to.it",
      "pec": "comune.nole.to@legalmail.it",
      "telefono": "+39 011 9299711",
      "fax": "+39 011 9296129",
      "coordinate": {
        "lat": 45.24394226074219,
        "lng": 7.57299280166626
      }
    },
    {
      "codice": "001167",
      "nome": "Nomaglio",
      "nomeStraniero": null,
      "codiceCatastale": "F927",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "nomaglio@ruparpiemonte.it",
      "pec": "tributi.nomaglio@anutel.it",
      "telefono": "+39 0125 790158",
      "fax": "+39 0125 790384",
      "coordinate": {
        "lat": 45.53615951538086,
        "lng": 7.860317230224609
      }
    },
    {
      "codice": "001168",
      "nome": "None",
      "nomeStraniero": null,
      "codiceCatastale": "F931",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.none.to.it",
      "pec": "protocollo@pec.comune.none.to.it",
      "telefono": "+39 0119990811",
      "fax": "+39 0119863053",
      "coordinate": {
        "lat": 44.933143615722656,
        "lng": 7.540121078491211
      }
    },
    {
      "codice": "001169",
      "nome": "Novalesa",
      "nomeStraniero": null,
      "codiceCatastale": "F948",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "novalesacomune@libero.it",
      "pec": "comune.novalesa@pec.it",
      "telefono": "+39 0122 653333",
      "fax": "+39 0122 653104",
      "coordinate": {
        "lat": 45.189640045166016,
        "lng": 7.015498161315918
      }
    },
    {
      "codice": "001170",
      "nome": "Oglianico",
      "nomeStraniero": null,
      "codiceCatastale": "G010",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "demografici@comune.oglianico.to.it",
      "pec": "demografici.oglianico@pec.it",
      "telefono": "+39 0124 34050",
      "fax": "+39 0124 470045",
      "coordinate": {
        "lat": 45.342552185058594,
        "lng": 7.693717002868652
      }
    },
    {
      "codice": "001171",
      "nome": "Orbassano",
      "nomeStraniero": null,
      "codiceCatastale": "G087",
      "cap": "10043",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.orbassano.to.it",
      "pec": "protocollo@pec.comune.orbassano.to.it",
      "telefono": "+39 011 9036224",
      "fax": "+39 011 9002676",
      "coordinate": {
        "lat": 45.00726318359375,
        "lng": 7.536919116973877
      }
    },
    {
      "codice": "001172",
      "nome": "Orio Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "G109",
      "cap": "10010",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.oriocanavese.to.it",
      "pec": "orio.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 011 9898130",
      "fax": "+39 011 9898449",
      "coordinate": {
        "lat": 45.329254150390625,
        "lng": 7.861721038818359
      }
    },
    {
      "codice": "001173",
      "nome": "Osasco",
      "nomeStraniero": null,
      "codiceCatastale": "G151",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "protocollo@comune.osasco.to.it",
      "pec": "osasco@cert.ruparpiemonte.it",
      "telefono": "+39 0121 541180",
      "fax": "+39 0121 541548",
      "coordinate": {
        "lat": 44.849369049072266,
        "lng": 7.34324312210083
      }
    },
    {
      "codice": "001174",
      "nome": "Osasio",
      "nomeStraniero": null,
      "codiceCatastale": "G152",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "osasio@ruparpiemonte.it",
      "pec": "comunediosasio@pec.it",
      "telefono": "+39 011 9793038",
      "fax": "+39 011 9728896",
      "coordinate": {
        "lat": 44.87144088745117,
        "lng": 7.6080241203308105
      }
    },
    {
      "codice": "001175",
      "nome": "Oulx",
      "nomeStraniero": null,
      "codiceCatastale": "G196",
      "cap": "10056",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "protocollo@comune.oulx.to.it",
      "pec": "oulx@postemailcertificata.it",
      "telefono": "+39 0122 831102",
      "fax": null,
      "coordinate": {
        "lat": 45.033050537109375,
        "lng": 6.832509994506836
      }
    },
    {
      "codice": "001176",
      "nome": "Ozegna",
      "nomeStraniero": null,
      "codiceCatastale": "G202",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "demografici@comune.ozegna.to.it",
      "pec": "protocollo.comune.ozegna@pec.it",
      "telefono": "+39 0124 428572",
      "fax": "+39 0124 428571",
      "coordinate": {
        "lat": 45.34928894042969,
        "lng": 7.742524147033691
      }
    },
    {
      "codice": "001177",
      "nome": "Palazzo Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "G262",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "palazzo.canavese@ruparpiemonte.it",
      "pec": "protocollo.palazzo.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0125 579021",
      "fax": "+39 0125 579039",
      "coordinate": {
        "lat": 45.459259033203125,
        "lng": 7.978920936584473
      }
    },
    {
      "codice": "001178",
      "nome": "Pancalieri",
      "nomeStraniero": null,
      "codiceCatastale": "G303",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comune.pancalieri.to.it",
      "pec": "segreteria@pec.comune.pancalieri.to.it",
      "telefono": "+39 011 9734102",
      "fax": "+39 011 9734800",
      "coordinate": {
        "lat": 44.83344268798828,
        "lng": 7.58592414855957
      }
    },
    {
      "codice": "001179",
      "nome": "Parella",
      "nomeStraniero": null,
      "codiceCatastale": "G330",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "amministrazione.parella@ruparpiemonte.it",
      "pec": "protocollo.parella@cert.ruparpiemonte.it",
      "telefono": "+39 0125 76120",
      "fax": "+39 0125 76476",
      "coordinate": {
        "lat": 45.43065643310547,
        "lng": 7.793217182159424
      }
    },
    {
      "codice": "001180",
      "nome": "Pavarolo",
      "nomeStraniero": null,
      "codiceCatastale": "G387",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "nadia@comune.pavarolo.to.it",
      "pec": "pavarolo@cert.ruparpiemonte.it",
      "telefono": "+39 011 9408001",
      "fax": "+39 011 9408629",
      "coordinate": {
        "lat": 45.07246780395508,
        "lng": 7.8346710205078125
      }
    },
    {
      "codice": "001181",
      "nome": "Pavone Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "G392",
      "cap": "10018",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "servizi.generali@comune.pavone.to.it",
      "pec": "pavone.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0125 51009",
      "fax": "+39 0125 516539",
      "coordinate": {
        "lat": 45.437557220458984,
        "lng": 7.851715087890625
      }
    },
    {
      "codice": "001183",
      "nome": "Pecetto Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "G398",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.pecetto.to.it",
      "pec": "info@pec.comune.pecetto.to.it",
      "telefono": "+39 011 8609218",
      "fax": "+39 011 8609073",
      "coordinate": {
        "lat": 45.016883850097656,
        "lng": 7.749948978424072
      }
    },
    {
      "codice": "001184",
      "nome": "Perosa Argentina",
      "nomeStraniero": null,
      "codiceCatastale": "G463",
      "cap": "10063",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "segreteria.perosa.argentina@ruparpiemonte.it",
      "pec": "protocollo.perosa.argentina@cert.ruparpiemonte.it",
      "telefono": "+39 012182000",
      "fax": "+39 0121 81509",
      "coordinate": {
        "lat": 44.95743942260742,
        "lng": 7.190914154052734
      }
    },
    {
      "codice": "001185",
      "nome": "Perosa Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "G462",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "protocollo@comune.perosa-canavese.to.it",
      "pec": "comune.perosacanavese@plionpec.it",
      "telefono": "+39 0125 739113",
      "fax": "+39 0125 640156",
      "coordinate": {
        "lat": 45.397056579589844,
        "lng": 7.830819129943848
      }
    },
    {
      "codice": "001186",
      "nome": "Perrero",
      "nomeStraniero": null,
      "codiceCatastale": "G465",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "perrero@ruparpiemonte.it",
      "pec": "perrero@cert.ruparpiemonte.it",
      "telefono": "+39 0121 808808",
      "fax": "+39 0121 808758",
      "coordinate": {
        "lat": 44.938262939453125,
        "lng": 7.1146440505981445
      }
    },
    {
      "codice": "001187",
      "nome": "Pertusio",
      "nomeStraniero": null,
      "codiceCatastale": "G477",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "anagrafe@comune.pertusio.to.it",
      "pec": "pertusio@cert.ruparpiemonte.it",
      "telefono": "+39 0124 617207",
      "fax": "+39 0124 659498",
      "coordinate": {
        "lat": 45.356788635253906,
        "lng": 7.638501167297363
      }
    },
    {
      "codice": "001188",
      "nome": "Pessinetto",
      "nomeStraniero": null,
      "codiceCatastale": "G505",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "anagrafe@comune.pessinetto.to.it",
      "pec": "pessinetto@cert.ruparpiemonte.it",
      "telefono": "+39 0123 504101",
      "fax": "+39 0123 504454",
      "coordinate": {
        "lat": 45.289093017578125,
        "lng": 7.4041619300842285
      }
    },
    {
      "codice": "001189",
      "nome": "Pianezza",
      "nomeStraniero": null,
      "codiceCatastale": "G559",
      "cap": "10044",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "urp@comune.pianezza.to.it",
      "pec": "comune.pianezza@pec.it",
      "telefono": "+39 0119670000",
      "fax": "+39 0119670295",
      "coordinate": {
        "lat": 45.1058464050293,
        "lng": 7.543417930603027
      }
    },
    {
      "codice": "001190",
      "nome": "Pinasca",
      "nomeStraniero": null,
      "codiceCatastale": "G672",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "pinasca@ruparpiemonte.it",
      "pec": "pinasca@cert.ruparpiemonte.it",
      "telefono": "+39 0121 800712",
      "fax": "+39 0121 800049",
      "coordinate": {
        "lat": 44.9426383972168,
        "lng": 7.229115009307861
      }
    },
    {
      "codice": "001191",
      "nome": "Pinerolo",
      "nomeStraniero": null,
      "codiceCatastale": "G674",
      "cap": "10064",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "elettorale@comune.pinerolo.to.it",
      "pec": "protocollo.pinerolo@cert.ruparpiemonte.it",
      "telefono": "+39 0121 361317",
      "fax": "+39 0121 397287",
      "coordinate": {
        "lat": 44.88731002807617,
        "lng": 7.331944942474365
      }
    },
    {
      "codice": "001192",
      "nome": "Pino Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "G678",
      "cap": "10025",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@cert.comune.pinotorinese.to.it",
      "pec": "protocollo@cert.comune.pinotorinese.to.it",
      "telefono": "+39 011 8117280",
      "fax": "+39 011 8117369",
      "coordinate": {
        "lat": 45.039546966552734,
        "lng": 7.777124881744385
      }
    },
    {
      "codice": "001193",
      "nome": "Piobesi Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "G684",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.piobesi.to.it",
      "pec": "protocollo.comune.piobesi.to@pec.it",
      "telefono": "+39 011 9657033",
      "fax": "+39 011 9650978",
      "coordinate": {
        "lat": 44.93294143676758,
        "lng": 7.610123157501221
      }
    },
    {
      "codice": "001194",
      "nome": "Piossasco",
      "nomeStraniero": null,
      "codiceCatastale": "G691",
      "cap": "10045",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "ppognante@comune.piossasco.to.it",
      "pec": "comune.piossasco@legalmail.it",
      "telefono": "+39 011 9027270",
      "fax": "+39 011 9027257",
      "coordinate": {
        "lat": 44.99055480957031,
        "lng": 7.4637041091918945
      }
    },
    {
      "codice": "001195",
      "nome": "Piscina",
      "nomeStraniero": null,
      "codiceCatastale": "G705",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "ufficio.demografici@comune.piscina.to.it",
      "pec": "comune.piscina.to@pec.it",
      "telefono": "+39 0121 57401",
      "fax": null,
      "coordinate": {
        "lat": 44.91944122314453,
        "lng": 7.426211833953857
      }
    },
    {
      "codice": "001196",
      "nome": "Piverone",
      "nomeStraniero": null,
      "codiceCatastale": "G719",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "comune.piverone@comune.piverone.to.it",
      "pec": "protocollo.piverone@cert.ruparpiemonte.it",
      "telefono": "+39 0125 72154",
      "fax": "+39 0125 72190",
      "coordinate": {
        "lat": 45.446258544921875,
        "lng": 8.005722045898438
      }
    },
    {
      "codice": "001197",
      "nome": "Poirino",
      "nomeStraniero": null,
      "codiceCatastale": "G777",
      "cap": "10046",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servizi.demografici@comune.poirino.to.it",
      "pec": "info@pec.comune.poirino.to.it",
      "telefono": "+39 011 9450114",
      "fax": "+39 011 9453450",
      "coordinate": {
        "lat": 44.92084503173828,
        "lng": 7.846928119659424
      }
    },
    {
      "codice": "001198",
      "nome": "Pomaretto",
      "nomeStraniero": null,
      "codiceCatastale": "G805",
      "cap": "10063",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "pomaretto@ruparpiemonte.it",
      "pec": "pomaretto@cert.ruparpiemonte.it",
      "telefono": "+39 0121 81241",
      "fax": "+39 0121 803719",
      "coordinate": {
        "lat": 44.95598220825195,
        "lng": 7.182778835296631
      }
    },
    {
      "codice": "001199",
      "nome": "Pont Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "G826",
      "cap": "10085",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.pontcanavese.to.it",
      "pec": "pont.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0124 862511",
      "fax": "+39 0124 85047",
      "coordinate": {
        "lat": 45.420528411865234,
        "lng": 7.596855163574219
      }
    },
    {
      "codice": "001200",
      "nome": "Porte",
      "nomeStraniero": null,
      "codiceCatastale": "G900",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comune@comune.porte.to.it",
      "pec": "porte@cert.alpimedia.it",
      "telefono": "+39 0121 303200",
      "fax": "+39 0121 303084",
      "coordinate": {
        "lat": 44.88729476928711,
        "lng": 7.267971038818359
      }
    },
    {
      "codice": "001201",
      "nome": "Pragelato",
      "nomeStraniero": null,
      "codiceCatastale": "G973",
      "cap": "10060",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "amministrativa@comune.pragelato.to.it",
      "pec": "comunepragelato@legalmail.it",
      "telefono": "+39 0122 78904",
      "fax": "+39 0122 78598",
      "coordinate": {
        "lat": 45.01621627807617,
        "lng": 6.942452907562256
      }
    },
    {
      "codice": "001202",
      "nome": "Prali",
      "nomeStraniero": null,
      "codiceCatastale": "G978",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "servizidemo.prali@ruparpiemonte.it",
      "pec": "prali@cert.ruparpiemonte.it",
      "telefono": "+39 0121 807513",
      "fax": "+39 0121 806998",
      "coordinate": {
        "lat": 44.889286041259766,
        "lng": 7.048861026763916
      }
    },
    {
      "codice": "001203",
      "nome": "Pralormo",
      "nomeStraniero": null,
      "codiceCatastale": "G979",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.pralormo.to.it",
      "pec": "protocollo@pec.comune.pralormo.to.it",
      "telefono": "+39 011 9481103",
      "fax": "+39 011 9481723",
      "coordinate": {
        "lat": 44.859745025634766,
        "lng": 7.901431083679199
      }
    },
    {
      "codice": "001204",
      "nome": "Pramollo",
      "nomeStraniero": null,
      "codiceCatastale": "G982",
      "cap": "10065",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "pramollo@ruparpiemonte.it",
      "pec": "pramollo@cert.ruparpiemonte.it",
      "telefono": "+39 0121 58619",
      "fax": "+39 0121 58619",
      "coordinate": {
        "lat": 44.904483795166016,
        "lng": 7.211400032043457
      }
    },
    {
      "codice": "001205",
      "nome": "Prarostino",
      "nomeStraniero": null,
      "codiceCatastale": "G986",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "prarostino@comune.prarostino.to.it",
      "pec": "prarostino@cert.ruparpiemonte.it",
      "telefono": "+39 0121 500128",
      "fax": "+39 0121 501792",
      "coordinate": {
        "lat": 44.86579132080078,
        "lng": 7.268222808837891
      }
    },
    {
      "codice": "001206",
      "nome": "Prascorsano",
      "nomeStraniero": null,
      "codiceCatastale": "G988",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "comune@comune.prascorsano.to.it",
      "pec": "prascorsano@cert.ruparpiemonte.it",
      "telefono": "+39 0124 698141",
      "fax": "+39 0124 698357",
      "coordinate": {
        "lat": 45.36775588989258,
        "lng": 7.616915225982666
      }
    },
    {
      "codice": "001207",
      "nome": "Pratiglione",
      "nomeStraniero": null,
      "codiceCatastale": "G997",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.pratiglione.to.it",
      "pec": "pratiglione@cert.ruparpiemonte.it",
      "telefono": "+39 0124 7184",
      "fax": "+39 0124 77886",
      "coordinate": {
        "lat": 45.352752685546875,
        "lng": 7.596014976501465
      }
    },
    {
      "codice": "001208",
      "nome": "Quagliuzzo",
      "nomeStraniero": null,
      "codiceCatastale": "H100",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "quagliuzzo@ruparpiemonte.it",
      "pec": "protocollo.quagliuzzo@cert.ruparpiemonte.it",
      "telefono": "+39 0125 76689",
      "fax": "+39 0125 668977",
      "coordinate": {
        "lat": 45.4267578125,
        "lng": 7.781617164611816
      }
    },
    {
      "codice": "001209",
      "nome": "Quassolo",
      "nomeStraniero": null,
      "codiceCatastale": "H120",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "quassolo@ruparpiemonte.it",
      "pec": "quassolo@postemailcertificata.it",
      "telefono": "+39 0125 750150",
      "fax": "+39 0125 750965",
      "coordinate": {
        "lat": 45.52452850341797,
        "lng": 7.833131790161133
      }
    },
    {
      "codice": "001210",
      "nome": "Quincinetto",
      "nomeStraniero": null,
      "codiceCatastale": "H127",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe.quincinetto@comune.quincinetto.to.it",
      "pec": "protocollo@pec.comune.quincinetto.to.it",
      "telefono": "+39 0125 757903",
      "fax": "+39 0125 254902",
      "coordinate": {
        "lat": 45.56296157836914,
        "lng": 7.807314872741699
      }
    },
    {
      "codice": "001211",
      "nome": "Reano",
      "nomeStraniero": null,
      "codiceCatastale": "H207",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.reano.to.it",
      "pec": "comune.reano.to@legalmail.it",
      "telefono": "+39 011 9310244",
      "fax": "+39 011 9310510",
      "coordinate": {
        "lat": 45.05205535888672,
        "lng": 7.430403232574463
      }
    },
    {
      "codice": "001212",
      "nome": "Ribordone",
      "nomeStraniero": null,
      "codiceCatastale": "H270",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "amministrazione@comune.ribordone.to.it",
      "pec": "ribordone@pec.it",
      "telefono": "+39 0124/808865",
      "fax": "+39 0124/818935",
      "coordinate": {
        "lat": 45.43204116821289,
        "lng": 7.501949787139893
      }
    },
    {
      "codice": "001215",
      "nome": "Riva presso Chieri",
      "nomeStraniero": null,
      "codiceCatastale": "H337",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.rivapressochieri.to.it",
      "pec": "comune.rivapressochieri@postecert.it",
      "telefono": "+39 011 9469103",
      "fax": "+39 011 9468449",
      "coordinate": {
        "lat": 44.98644256591797,
        "lng": 7.869471073150635
      }
    },
    {
      "codice": "001213",
      "nome": "Rivalba",
      "nomeStraniero": null,
      "codiceCatastale": "H333",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "comunerivalba@tin.it",
      "pec": "protocollo@pec.comune.rivalba.to.it",
      "telefono": "+39 011 9604527",
      "fax": "+39 011 9816900",
      "coordinate": {
        "lat": 45.118350982666016,
        "lng": 7.888124942779541
      }
    },
    {
      "codice": "001214",
      "nome": "Rivalta di Torino",
      "nomeStraniero": null,
      "codiceCatastale": "H335",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.rivalta.to.it",
      "pec": "protocollo.rivalta@cert.legalmail.it",
      "telefono": "+39 011 9045501",
      "fax": "+39 011 9091495",
      "coordinate": {
        "lat": 45.0340461730957,
        "lng": 7.518418788909912
      }
    },
    {
      "codice": "001216",
      "nome": "Rivara",
      "nomeStraniero": null,
      "codiceCatastale": "H338",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "elettorale@comune.rivara.to.it",
      "pec": "amministrativo.rivara@pec.it",
      "telefono": "+39 0124 31109",
      "fax": "+39 0124 31527",
      "coordinate": {
        "lat": 45.33255386352539,
        "lng": 7.63291597366333
      }
    },
    {
      "codice": "001217",
      "nome": "Rivarolo Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H340",
      "cap": "10086",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "comune@rivarolocanavese.it",
      "pec": "rivarolocanavese@pec.it",
      "telefono": "+39 0124 454611",
      "fax": "+39 0124 29102",
      "coordinate": {
        "lat": 45.331214904785156,
        "lng": 7.71713399887085
      }
    },
    {
      "codice": "001218",
      "nome": "Rivarossa",
      "nomeStraniero": null,
      "codiceCatastale": "H344",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servdem@comunerivarossa.gov.it",
      "pec": "servdem.rivarossa@pec.ancitel.it",
      "telefono": "+39 011 9888406-930",
      "fax": "+39 011 9888875",
      "coordinate": {
        "lat": 45.25049591064453,
        "lng": 7.717727184295654
      }
    },
    {
      "codice": "001219",
      "nome": "Rivoli",
      "nomeStraniero": null,
      "codiceCatastale": "H355",
      "cap": "10098",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.rivoli.to.it",
      "pec": "comune.rivoli.to@legalmail.it",
      "telefono": "+39 011 9513304",
      "fax": "+39 011 9513354",
      "coordinate": {
        "lat": 45.0697135925293,
        "lng": 7.517675876617432
      }
    },
    {
      "codice": "001220",
      "nome": "Robassomero",
      "nomeStraniero": null,
      "codiceCatastale": "H367",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "comune@comune.robassomero.to.it",
      "pec": "protocollo.robassomero@pec.it",
      "telefono": "+39 011 9234400",
      "fax": "+39 011 9234422",
      "coordinate": {
        "lat": 45.200050354003906,
        "lng": 7.5689167976379395
      }
    },
    {
      "codice": "001221",
      "nome": "Rocca Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H386",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comune.rocca.canavese.to.it",
      "pec": "rocca.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0119240931",
      "fax": "+39 0119239807",
      "coordinate": {
        "lat": 45.30885314941406,
        "lng": 7.575862884521484
      }
    },
    {
      "codice": "001222",
      "nome": "Roletto",
      "nomeStraniero": null,
      "codiceCatastale": "H498",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "demografici@comune.roletto.to.it",
      "pec": "protocollo@pec.comune.roletto.to.it",
      "telefono": "+39 0121.542128",
      "fax": "+39 0121.342470",
      "coordinate": {
        "lat": 44.92469024658203,
        "lng": 7.331324100494385
      }
    },
    {
      "codice": "001223",
      "nome": "Romano Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H511",
      "cap": "10090",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe@comuneromano.it",
      "pec": "romanocanavese@postemailcertificata.it",
      "telefono": "+39 0125 713045",
      "fax": "+39 0125 714107",
      "coordinate": {
        "lat": 45.38865661621094,
        "lng": 7.866519927978516
      }
    },
    {
      "codice": "001224",
      "nome": "Ronco Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H539",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "servizi.roncocanavese@hotmail.it",
      "pec": "comune.roncocanavese@postecert.it",
      "telefono": "+39 0124 817803",
      "fax": "+39 0124 817419",
      "coordinate": {
        "lat": 45.49973678588867,
        "lng": 7.5468549728393555
      }
    },
    {
      "codice": "001225",
      "nome": "Rondissone",
      "nomeStraniero": null,
      "codiceCatastale": "H547",
      "cap": "10030",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servizidemografici@comune-rondissone.to.it",
      "pec": "comune.rondissone.pec@actaliscertymail.it",
      "telefono": "+39 011 9183601",
      "fax": "+39 011 9183483",
      "coordinate": {
        "lat": 45.24677658081055,
        "lng": 7.965425968170166
      }
    },
    {
      "codice": "001226",
      "nome": "Rorà",
      "nomeStraniero": null,
      "codiceCatastale": "H554",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "crora@dag.it",
      "pec": "rora@cert.ruparpiemonte.it",
      "telefono": "+39 0121 93102",
      "fax": "+39 0121 93102",
      "coordinate": {
        "lat": 44.792137145996094,
        "lng": 7.19901704788208
      }
    },
    {
      "codice": "001228",
      "nome": "Rosta",
      "nomeStraniero": null,
      "codiceCatastale": "H583",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.rosta.to.it",
      "pec": "protocollo@pec.comune.rosta.to.it",
      "telefono": "+39 011 9568811",
      "fax": "+39 011 9540038",
      "coordinate": {
        "lat": 45.06794357299805,
        "lng": 7.46511697769165
      }
    },
    {
      "codice": "001227",
      "nome": "Roure",
      "nomeStraniero": null,
      "codiceCatastale": "H555",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "ufficioanagrafe.roure@reteunitaria.piemonte.it",
      "pec": "roure@cert.ruparpiemonte.it",
      "telefono": "+39 0121 842613",
      "fax": "+39 0121 842945",
      "coordinate": {
        "lat": 45.00141143798828,
        "lng": 7.129432201385498
      }
    },
    {
      "codice": "001229",
      "nome": "Rubiana",
      "nomeStraniero": null,
      "codiceCatastale": "H627",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "rubiana@comune.rubiana.to.it",
      "pec": "comune.rubiana.to@cert.legalmail.it",
      "telefono": "+39 011 9358923",
      "fax": "+39 011 9358680",
      "coordinate": {
        "lat": 45.13794708251953,
        "lng": 7.384829998016357
      }
    },
    {
      "codice": "001230",
      "nome": "Rueglio",
      "nomeStraniero": null,
      "codiceCatastale": "H631",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "affarigenerali@comune.rueglio.to.it",
      "pec": "comunerueglio@plionpec.it",
      "telefono": "+39 0125 780126",
      "fax": "+39 0125 780921",
      "coordinate": {
        "lat": 45.468257904052734,
        "lng": 7.754615783691406
      }
    },
    {
      "codice": "001231",
      "nome": "Salassa",
      "nomeStraniero": null,
      "codiceCatastale": "H691",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.salassa.to.it",
      "pec": "protocollo@pec.comune.salassa.to.it",
      "telefono": "+39 0124 36145",
      "fax": "+39 0124 36195",
      "coordinate": {
        "lat": 45.358524322509766,
        "lng": 7.689239025115967
      }
    },
    {
      "codice": "001232",
      "nome": "Salbertrand",
      "nomeStraniero": null,
      "codiceCatastale": "H684",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.salbertrand.to.it",
      "pec": "salbertrand@pec.it",
      "telefono": "+39 0122 854595",
      "fax": "+39 0122 854733",
      "coordinate": {
        "lat": 45.07204055786133,
        "lng": 6.883204936981201
      }
    },
    {
      "codice": "001233",
      "nome": "Salerano Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H702",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "salerano.canavese@reteunitaria.piemonte.it",
      "pec": "protocollo.salerano.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0125 53123",
      "fax": "+39 0125 538447",
      "coordinate": {
        "lat": 45.45805740356445,
        "lng": 7.851118087768555
      }
    },
    {
      "codice": "001234",
      "nome": "Salza di Pinerolo",
      "nomeStraniero": null,
      "codiceCatastale": "H734",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "comune.salza@dag.it",
      "pec": "salza.di.pinerolo@cert.ruparpiemonte.it",
      "telefono": "+39 0121 808836",
      "fax": "+39 0121 808836",
      "coordinate": {
        "lat": 44.940650939941406,
        "lng": 7.0532450675964355
      }
    },
    {
      "codice": "001235",
      "nome": "Samone",
      "nomeStraniero": null,
      "codiceCatastale": "H753",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "samone@ruparpiemonte.it",
      "pec": "anagrafe.comunesamone.to@pec.it",
      "telefono": "+39 0125 53908",
      "fax": "+39 0125 53224",
      "coordinate": {
        "lat": 45.45055389404297,
        "lng": 7.845242977142334
      }
    },
    {
      "codice": "001236",
      "nome": "San Benigno Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H775",
      "cap": "10080",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comunesanbenigno.it",
      "pec": "sanbenignocanavese@postemailcertificata.it",
      "telefono": "+39 011 9880100",
      "fax": "+39 011 9887799",
      "coordinate": {
        "lat": 45.22705078125,
        "lng": 7.78502082824707
      }
    },
    {
      "codice": "001237",
      "nome": "San Carlo Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H789",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "comune@comune.sancarlocanavese.to.it",
      "pec": "comune.sancarlo@pec.it",
      "telefono": "+39 011 9210193",
      "fax": "+39 011 9222542",
      "coordinate": {
        "lat": 45.247825622558594,
        "lng": 7.610843181610107
      }
    },
    {
      "codice": "001238",
      "nome": "San Colombano Belmonte",
      "nomeStraniero": null,
      "codiceCatastale": "H804",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "info@comune.sancolombanobelmonte.to.it",
      "pec": "sancolombanobelmonte@cert.ruparpiemonte.it",
      "telefono": "+39 0124 657509",
      "fax": "+39 0124 650707",
      "coordinate": {
        "lat": 45.38225555419922,
        "lng": 7.6210150718688965
      }
    },
    {
      "codice": "001239",
      "nome": "San Didero",
      "nomeStraniero": null,
      "codiceCatastale": "H820",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.sandidero.to.it",
      "pec": "comune.sandidero.to@pec.it",
      "telefono": "+39 011 9637837",
      "fax": "+39 011 9637341",
      "coordinate": {
        "lat": 45.13544464111328,
        "lng": 7.213010787963867
      }
    },
    {
      "codice": "001240",
      "nome": "San Francesco al Campo",
      "nomeStraniero": null,
      "codiceCatastale": "H847",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.sanfrancescoalcampo.to.it",
      "pec": "comunesanfrancescoalcampo@pec.it",
      "telefono": "+39 011 9263312",
      "fax": "+39 011 9263310",
      "coordinate": {
        "lat": 45.22646713256836,
        "lng": 7.657165050506592
      }
    },
    {
      "codice": "001242",
      "nome": "San Germano Chisone",
      "nomeStraniero": null,
      "codiceCatastale": "H862",
      "cap": "10065",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "san.germano.chisone@reteunitaria.piemonte.it",
      "pec": "comune@cert.comune.sangermanochisone.to.it",
      "telefono": "+39 0121 58601",
      "fax": "+39 0121 58607",
      "coordinate": {
        "lat": 44.8985481262207,
        "lng": 7.241175174713135
      }
    },
    {
      "codice": "001243",
      "nome": "San Gillio",
      "nomeStraniero": null,
      "codiceCatastale": "H873",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.sangillio.to.it",
      "pec": "demografici.sangillio@gigapec.it",
      "telefono": "+39 011 9840213",
      "fax": "+39 011 9840757",
      "coordinate": {
        "lat": 45.14274978637695,
        "lng": 7.5358171463012695
      }
    },
    {
      "codice": "001244",
      "nome": "San Giorgio Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H890",
      "cap": "10090",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "municipio@comunesangiorgio.it",
      "pec": "sangiorgiocanavese@pcert.it",
      "telefono": "+39 0124 32121",
      "fax": "+39 0124 325106",
      "coordinate": {
        "lat": 45.33415603637695,
        "lng": 7.79681921005249
      }
    },
    {
      "codice": "001245",
      "nome": "San Giorio di Susa",
      "nomeStraniero": null,
      "codiceCatastale": "H900",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "comune.sangiorio@libero.it",
      "pec": "sangioriodisusa@postemailcertificata.it",
      "telefono": "+39 0122 49666",
      "fax": "+39 0122 643642",
      "coordinate": {
        "lat": 45.12798309326172,
        "lng": 7.177985191345215
      }
    },
    {
      "codice": "001246",
      "nome": "San Giusto Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H936",
      "cap": "10090",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "protocollo@comune.sangiustocanavese.to.it",
      "pec": "comune.sangiusto@pec.it",
      "telefono": "+39 0124 35132",
      "fax": "+39 0124 350760",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "001247",
      "nome": "San Martino Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "H997",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "san.martino.canavese@ruparpiemonte.it",
      "pec": "protocollo.san.martino.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0125 739121",
      "fax": "+39 0125 640972",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "001248",
      "nome": "San Maurizio Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "I024",
      "cap": "10077",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.sanmauriziocanavese.to.it",
      "pec": "protocollo@pec.comune.sanmauriziocanavese.to.it",
      "telefono": "+39 011 9263211",
      "fax": "+39 011 9278171",
      "coordinate": {
        "lat": 45.21704864501953,
        "lng": 7.630519866943359
      }
    },
    {
      "codice": "001249",
      "nome": "San Mauro Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "I030",
      "cap": "10099",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "ufficio.protocollo@comune.sanmaurotorinese.to.it",
      "pec": "protocollo@cert.comune.sanmaurotorinese.to.it",
      "telefono": "+39 011 8228011",
      "fax": "+39 011 8986579",
      "coordinate": {
        "lat": 45.10388946533203,
        "lng": 7.753611087799072
      }
    },
    {
      "codice": "001250",
      "nome": "San Pietro Val Lemina",
      "nomeStraniero": null,
      "codiceCatastale": "I090",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "sanpietrovallemina@comune.sanpietrovallemina.to.it",
      "pec": "demografico.sanpietro@pec.it",
      "telefono": "+39 0121 543131",
      "fax": "+39 0121 544800",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "001251",
      "nome": "San Ponso",
      "nomeStraniero": null,
      "codiceCatastale": "I126",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "san.ponso@ruparpiemonte.it",
      "pec": "san.ponso@cert.ruparpiemonte.it",
      "telefono": "+39 0124 36522",
      "fax": "+39 0124 360535",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 7.666666507720947
      }
    },
    {
      "codice": "001252",
      "nome": "San Raffaele Cimena",
      "nomeStraniero": null,
      "codiceCatastale": "I137",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.sanraffaelecimena.to.it",
      "pec": "comune.sanraffaelecimena.to@cert.legalmail.it",
      "telefono": "+39 011 9811644",
      "fax": "+39 011 9811730",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 7.849999904632568
      }
    },
    {
      "codice": "001253",
      "nome": "San Sebastiano da Po",
      "nomeStraniero": null,
      "codiceCatastale": "I152",
      "cap": "10020",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comunesansebastianodapo.it",
      "pec": "comune.sansebastianodapo.to@pec.it",
      "telefono": "+39 011 9191221",
      "fax": "+39 011 9191303",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "001254",
      "nome": "San Secondo di Pinerolo",
      "nomeStraniero": null,
      "codiceCatastale": "I154",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "uffici@comune.sansecondodipinerolo.to.it",
      "pec": "protocollo@pec.comune.sansecondodipinerolo.to.it",
      "telefono": "+39 0121 503804",
      "fax": "+39 0121 503817",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 7.300000190734863
      }
    },
    {
      "codice": "001241",
      "nome": "Sangano",
      "nomeStraniero": null,
      "codiceCatastale": "H855",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servizidemografici@comune.sangano.to.it",
      "pec": "comune.sangano.to@halleycert.it",
      "telefono": "+39 011 9087140",
      "fax": "+39 011 9084466",
      "coordinate": {
        "lat": 45.02614212036133,
        "lng": 7.448118209838867
      }
    },
    {
      "codice": "001255",
      "nome": "Sant'Ambrogio di Torino",
      "nomeStraniero": null,
      "codiceCatastale": "I258",
      "cap": "10057",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "santambrogio.di.torino@comune.santambrogioditorino.to.it",
      "pec": "comune.santambrogio.to@legalmail.it",
      "telefono": "+39 011 9324411",
      "fax": "+39 011 939353",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 7.366666793823242
      }
    },
    {
      "codice": "001256",
      "nome": "Sant'Antonino di Susa",
      "nomeStraniero": null,
      "codiceCatastale": "I296",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.santantoninodisusa.to.it",
      "pec": "comune.santantoninodisusa.to@legalmail.it",
      "telefono": "+39 0119639911",
      "fax": "+39 011 9640406",
      "coordinate": {
        "lat": 45.10707092285156,
        "lng": 7.272266864776611
      }
    },
    {
      "codice": "001257",
      "nome": "Santena",
      "nomeStraniero": null,
      "codiceCatastale": "I327",
      "cap": "10026",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.santena.to.it",
      "pec": "santena@cert.comune.santena.to.it",
      "telefono": "+39 011/9455411",
      "fax": "+39 011/9455405  011/9456181",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 7.7833333015441895
      }
    },
    {
      "codice": "001259",
      "nome": "Sauze d'Oulx",
      "nomeStraniero": null,
      "codiceCatastale": "I466",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "ufficio.anagrafe@comune.sauzedoulx.to.it",
      "pec": "comune.sauzedoulx@legalmail.it",
      "telefono": "+39 0122 850380",
      "fax": null,
      "coordinate": {
        "lat": 45.026668548583984,
        "lng": 6.858055591583252
      }
    },
    {
      "codice": "001258",
      "nome": "Sauze di Cesana",
      "nomeStraniero": null,
      "codiceCatastale": "I465",
      "cap": "10054",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "cristina.sasu@unionevialattea.to.it",
      "pec": "comunesauzedicesana@legalmail.it",
      "telefono": "+39 0122 755955",
      "fax": "+39 0122 755955",
      "coordinate": {
        "lat": 44.93333435058594,
        "lng": 6.866666793823242
      }
    },
    {
      "codice": "001260",
      "nome": "Scalenghe",
      "nomeStraniero": null,
      "codiceCatastale": "I490",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "scalenghe@ruparpiemonte.it",
      "pec": "scalenghe@cert.ruparpiemonte.it",
      "telefono": "+39 011 9861721",
      "fax": "+39 011 9861882",
      "coordinate": {
        "lat": 44.8927001953125,
        "lng": 7.4936113357543945
      }
    },
    {
      "codice": "001261",
      "nome": "Scarmagno",
      "nomeStraniero": null,
      "codiceCatastale": "I511",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "scarmagno@comune.scarmagno.to.it",
      "pec": "scarmagno@postemailcertificata.it",
      "telefono": "+39 0125 739153",
      "fax": "+39 0125 739391",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 7.833333492279053
      }
    },
    {
      "codice": "001262",
      "nome": "Sciolze",
      "nomeStraniero": null,
      "codiceCatastale": "I539",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servizi@comunedisciolze.it",
      "pec": "protocollo@pec.comunedisciolze.it",
      "telefono": "+39 011 9603712",
      "fax": "+39 011 9603545",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "001263",
      "nome": "Sestriere",
      "nomeStraniero": null,
      "codiceCatastale": "I692",
      "cap": "10058",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.sestriere.to.it",
      "pec": "comunesestriere@legalmail.it",
      "telefono": "+39 0122 750613",
      "fax": "+39 0122 755053",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 6.883333206176758
      }
    },
    {
      "codice": "001264",
      "nome": "Settimo Rottaro",
      "nomeStraniero": null,
      "codiceCatastale": "I701",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "settimo.rottaro@ruparpiemonte.it",
      "pec": "protocollo.settimo.rottaro@cert.ruparpiemonte.it",
      "telefono": "+39 0125 720153",
      "fax": "+39 0125 799935",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8
      }
    },
    {
      "codice": "001265",
      "nome": "Settimo Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "I703",
      "cap": "10036",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.settimo-torinese.to.it",
      "pec": "settimo@cert.comune.settimo-torinese.to.it",
      "telefono": "+39 011 8028269",
      "fax": "+39 011 8028240",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "001266",
      "nome": "Settimo Vittone",
      "nomeStraniero": null,
      "codiceCatastale": "I702",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe@comune.settimovittone.to.it",
      "pec": "anagrafe.settimovittone@pec.it",
      "telefono": "+39 0125 658409",
      "fax": "+39 0125 658442",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 7.833333492279053
      }
    },
    {
      "codice": "001267",
      "nome": "Sparone",
      "nomeStraniero": null,
      "codiceCatastale": "I886",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "demografica@comune.sparone.to.it",
      "pec": "demografica.comunesparone.to@pec.it",
      "telefono": "+39 0124 808804",
      "fax": "+39 0124 808942",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "001268",
      "nome": "Strambinello",
      "nomeStraniero": null,
      "codiceCatastale": "I969",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "strambinello@ruparpiemonte.it",
      "pec": "strambinello@cert.ruparpiemonte.it",
      "telefono": "+39 0125 76691",
      "fax": "+39 0125 668956",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "001269",
      "nome": "Strambino",
      "nomeStraniero": null,
      "codiceCatastale": "I970",
      "cap": "10019",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe@comune.strambino.to.it",
      "pec": "comune.strambino@legalmail.it",
      "telefono": "+39 0125 636632",
      "fax": "+39 0125 714157",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 7.883333206176758
      }
    },
    {
      "codice": "001270",
      "nome": "Susa",
      "nomeStraniero": null,
      "codiceCatastale": "L013",
      "cap": "10059",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "amministrativo@comune.susa.to.it",
      "pec": "comune.susa.to@legalmail.it",
      "telefono": "+39 00122648301",
      "fax": "+39 0122648307",
      "coordinate": {
        "lat": 45.133331298828125,
        "lng": 7.050000190734863
      }
    },
    {
      "codice": "001271",
      "nome": "Tavagnasco",
      "nomeStraniero": null,
      "codiceCatastale": "L066",
      "cap": "10010",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "anagrafe@comune.tavagnasco.to.it",
      "pec": "comunetavagnasco@postecert.it",
      "telefono": "+39 0125 658601",
      "fax": "+39 0125 659214",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "001272",
      "nome": "Torino",
      "nomeStraniero": null,
      "codiceCatastale": "L219",
      "cap": "10121",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "urc@comune.torino.it",
      "pec": "ProtocolloGenerale@cert.comune.torino.it",
      "telefono": "+39 011 01121111",
      "fax": "+39 011 01122723",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 7.699999809265137
      }
    },
    {
      "codice": "001273",
      "nome": "Torrazza Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "L238",
      "cap": "10037",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "servizidemografici@torrazzapiemonte.eu",
      "pec": "torrazzapiemonte@postemailcertificata.it",
      "telefono": "+39 0119181014",
      "fax": "+39 011918019",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "001274",
      "nome": "Torre Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "L247",
      "cap": "10010",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "comunetorrecan@libero.it",
      "pec": "torre.canavese@cert.ruparpiemonte.it",
      "telefono": "+39 0124 501070",
      "fax": "+39 0124 501382",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "001275",
      "nome": "Torre Pellice",
      "nomeStraniero": null,
      "codiceCatastale": "L277",
      "cap": "10066",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "segreteria@comunetorrepellice.it",
      "pec": "protocollo@pec.comunetorrepellice.it",
      "telefono": "+39 0121 953221",
      "fax": "+39 0121 933344",
      "coordinate": {
        "lat": 44.82027816772461,
        "lng": 7.223333358764648
      }
    },
    {
      "codice": "001276",
      "nome": "Trana",
      "nomeStraniero": null,
      "codiceCatastale": "L327",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.trana.to.it",
      "pec": "comune.trana.to@legalmail.it",
      "telefono": "+39 011 933105",
      "fax": "+39 011 9338090",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 7.416666507720947
      }
    },
    {
      "codice": "001278",
      "nome": "Traversella",
      "nomeStraniero": null,
      "codiceCatastale": "L345",
      "cap": "10080",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "servizidemografici@comune.traversella.to.it",
      "pec": "comune.traversella@pec.it",
      "telefono": "+39 0125 794003",
      "fax": "+39 0125 794003",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 7.75
      }
    },
    {
      "codice": "001279",
      "nome": "Traves",
      "nomeStraniero": null,
      "codiceCatastale": "L340",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": null,
      "pec": "traves@cert.ruparpiemonte.it",
      "telefono": "+39 0123 40201",
      "fax": "+39 0123 40201",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 7.433333396911621
      }
    },
    {
      "codice": "001280",
      "nome": "Trofarello",
      "nomeStraniero": null,
      "codiceCatastale": "L445",
      "cap": "10028",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "segreteria@comune.trofarello.to.it",
      "pec": "protocollo.trofarello@cert.legalmail.it",
      "telefono": "+39 011 6482111",
      "fax": "+39 011 6498222",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "001281",
      "nome": "Usseaux",
      "nomeStraniero": null,
      "codiceCatastale": "L515",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "usseaux@ruparpiemonte.it",
      "pec": "usseaux@cert.ruparpiemonte.it",
      "telefono": "+39 0121 83909",
      "fax": "+39 0121 884443",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 7.0333333015441895
      }
    },
    {
      "codice": "001282",
      "nome": "Usseglio",
      "nomeStraniero": null,
      "codiceCatastale": "L516",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "info@comune.usseglio.to.it",
      "pec": "info@pec.comune.usseglio.to.it",
      "telefono": "+39 0123 83702",
      "fax": "+39 0123 83800",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 7.2166666984558105
      }
    },
    {
      "codice": "001283",
      "nome": "Vaie",
      "nomeStraniero": null,
      "codiceCatastale": "L538",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.vaie.to.it",
      "pec": "info@pec.comune.vaie.to.it",
      "telefono": "+39 011 9649020",
      "fax": "+39 011 9631651",
      "coordinate": {
        "lat": 45.10234069824219,
        "lng": 7.28941011428833
      }
    },
    {
      "codice": "001284",
      "nome": "Val della Torre",
      "nomeStraniero": null,
      "codiceCatastale": "L555",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@valdellatorre.gov.it",
      "pec": "protocollo.comunevaldellatorre.to@pec.it",
      "telefono": "+39 011 9680321",
      "fax": "+39 011 9680947",
      "coordinate": {
        "lat": 45.1551513671875,
        "lng": 7.4450201988220215
      }
    },
    {
      "codice": "001317",
      "nome": "Val di Chy",
      "nomeStraniero": null,
      "codiceCatastale": "M405",
      "cap": "10080",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "protocollo@comune.valdichy.to.it",
      "pec": "valdichy@pcert.it",
      "telefono": "+39 012578402",
      "fax": "+39 012578439",
      "coordinate": {
        "lat": 45.46160125732422,
        "lng": 7.780300140380859
      }
    },
    {
      "codice": "001318",
      "nome": "Valchiusa",
      "nomeStraniero": null,
      "codiceCatastale": "M415",
      "cap": "10089",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "protocollo@comune.valchiusa.to.it",
      "pec": "valchiusa@pcert.it",
      "telefono": "+39 0125 795810",
      "fax": "+39 0125 74301",
      "coordinate": {
        "lat": 45.520179748535156,
        "lng": 7.730770111083984
      }
    },
    {
      "codice": "001285",
      "nome": "Valgioie",
      "nomeStraniero": null,
      "codiceCatastale": "L578",
      "cap": "10094",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.valgioie.to.it",
      "pec": "valgioie@cert.ruparpiemonte.it",
      "telefono": "+39 011 9347100",
      "fax": "+39 011 9347047",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 7.333333492279053
      }
    },
    {
      "codice": "001286",
      "nome": "Vallo Torinese",
      "nomeStraniero": null,
      "codiceCatastale": "L629",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe.vallo.torinese@ruparpiemonte.it",
      "pec": "vallo.torinese@cert.ruparpiemonte.it",
      "telefono": "+39 011 9249202",
      "fax": "+39 011 9249088",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 7.5
      }
    },
    {
      "codice": "001287",
      "nome": "Valperga",
      "nomeStraniero": null,
      "codiceCatastale": "L644",
      "cap": "10087",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "segreteria@comune.valperga.to.it",
      "pec": "protocollo@pec.comune.valperga.to.it",
      "telefono": "+39 0124617146",
      "fax": "+39 0124 617627",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 7.650000095367432
      }
    },
    {
      "codice": "001288",
      "nome": "Valprato Soana",
      "nomeStraniero": null,
      "codiceCatastale": "B510",
      "cap": "10080",
      "prefisso": "0124",
      "provincia": "Torino",
      "email": "comune.valprato@libero.it",
      "pec": "comune.valprato@pec.pftech.it",
      "telefono": "+39 0124 812908",
      "fax": "+39 0124 812960",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "001289",
      "nome": "Varisella",
      "nomeStraniero": null,
      "codiceCatastale": "L685",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comunevarisella.to.it",
      "pec": "protocollo@pec.comunevarisella.to.it",
      "telefono": "+39 011 9249375",
      "fax": "+39 011 9249308",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 7.483333110809326
      }
    },
    {
      "codice": "001290",
      "nome": "Vauda Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "L698",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.vaudacanavese.to.it",
      "pec": "comune.vaudacanavese.to@cert.legalmail.it",
      "telefono": "+39 011 9243632",
      "fax": "+39 011 9243909",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "001292",
      "nome": "Venaria Reale",
      "nomeStraniero": null,
      "codiceCatastale": "L727",
      "cap": "10078",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.venariareale.to.it",
      "pec": "protocollovenariareale@pec.it",
      "telefono": "+39 011 4072111",
      "fax": "+39 011 4072261",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 7.633333206176758
      }
    },
    {
      "codice": "001291",
      "nome": "Venaus",
      "nomeStraniero": null,
      "codiceCatastale": "L726",
      "cap": "10050",
      "prefisso": "0122",
      "provincia": "Torino",
      "email": "info@comune.venaus.to.it",
      "pec": "comune.venaus@legalmail.it",
      "telefono": "+39 0122 505001",
      "fax": null,
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 7.016666889190674
      }
    },
    {
      "codice": "001293",
      "nome": "Verolengo",
      "nomeStraniero": null,
      "codiceCatastale": "L779",
      "cap": "10038",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe.verolengo@ruparpiemonte.it",
      "pec": "segreteria@comuneverolengo.legalmailpa.it",
      "telefono": "+39 011 9149102",
      "fax": "+39 011 9149397",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "001294",
      "nome": "Verrua Savoia",
      "nomeStraniero": null,
      "codiceCatastale": "L787",
      "cap": "10020",
      "prefisso": "0161",
      "provincia": "Torino",
      "email": "anagrafe@comune.verruasavoia.to.it",
      "pec": "protocollo@pec.comune.verruasavoia.to.it",
      "telefono": "+39 0161 849112",
      "fax": "+39 0161 839407",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "001295",
      "nome": "Vestignè",
      "nomeStraniero": null,
      "codiceCatastale": "L811",
      "cap": "10030",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.vestigne.to.it",
      "pec": "protocollo.comune.vestigne@pec.it",
      "telefono": "+39 0125 77103",
      "fax": "+39 0125 77465",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "001296",
      "nome": "Vialfrè",
      "nomeStraniero": null,
      "codiceCatastale": "L830",
      "cap": "10090",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "info@comune.vialfre.to.it",
      "pec": "vialfre@postemailcertificata.it",
      "telefono": "+39 0125 739101",
      "fax": "+39 0125 640354",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "001298",
      "nome": "Vidracco",
      "nomeStraniero": null,
      "codiceCatastale": "L857",
      "cap": "10080",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "ragioneria.vidracco@libero.it",
      "pec": "comune.vidracco@postecert.it",
      "telefono": "+39 0125 789037",
      "fax": "+39 0125 791974",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 7.75
      }
    },
    {
      "codice": "001299",
      "nome": "Vigone",
      "nomeStraniero": null,
      "codiceCatastale": "L898",
      "cap": "10067",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.vigone.to.it",
      "pec": "comunevigone@postecert.it",
      "telefono": "+39 011 9804269",
      "fax": "+39 011 9802724",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 7.5
      }
    },
    {
      "codice": "001300",
      "nome": "Villafranca Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "L948",
      "cap": "10068",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.villafrancapiemonte.to.it",
      "pec": "protocollo@pec.comune.villafrancapiemonte.to.it",
      "telefono": "+39 011 9807107",
      "fax": "+39 011 9807441",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 7.5
      }
    },
    {
      "codice": "001301",
      "nome": "Villanova Canavese",
      "nomeStraniero": null,
      "codiceCatastale": "L982",
      "cap": "10070",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "elettorale@comune.villanovacanavese.to.it",
      "pec": "protocollo@pec.comune.villanovacanavese.to.it",
      "telefono": "+39 011 9295577",
      "fax": null,
      "coordinate": {
        "lat": 45.25,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "001303",
      "nome": "Villar Dora",
      "nomeStraniero": null,
      "codiceCatastale": "L999",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "protocollo@comune.villardora.to.it",
      "pec": "comune.villardora.to@cert.legalmail.it",
      "telefono": "+39 011 9351218",
      "fax": "+39 011 9352575",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "001305",
      "nome": "Villar Focchiardo",
      "nomeStraniero": null,
      "codiceCatastale": "M007",
      "cap": "10050",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "anagrafe@comune.villarfocchiardo.to.it",
      "pec": "villar.focchiardo@pec.comune.villarfocchiardo.to.it",
      "telefono": "+39 011 9645025",
      "fax": "+39 011 9646283",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 7.233333110809326
      }
    },
    {
      "codice": "001306",
      "nome": "Villar Pellice",
      "nomeStraniero": null,
      "codiceCatastale": "M013",
      "cap": "10060",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "info@comunevillarpellice.it",
      "pec": "comunevillarpellice@pec.it",
      "telefono": "+39 0121 930712",
      "fax": "+39 0121 930159",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 7.166666507720947
      }
    },
    {
      "codice": "001307",
      "nome": "Villar Perosa",
      "nomeStraniero": null,
      "codiceCatastale": "M014",
      "cap": "10069",
      "prefisso": "0121",
      "provincia": "Torino",
      "email": "segreteria@comune.villarperosa.to.it",
      "pec": "villar.perosa@cert.ruparpiemonte.it",
      "telefono": "+39 0121 51001",
      "fax": "+39 0121 515322",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 7.25
      }
    },
    {
      "codice": "001302",
      "nome": "Villarbasse",
      "nomeStraniero": null,
      "codiceCatastale": "M002",
      "cap": "10090",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "demografici@comune.villarbasse.to.it",
      "pec": "comune.villarbasse.to@legalmail.it",
      "telefono": "+39 0119528485",
      "fax": "+39 011952029",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 7.4666666984558105
      }
    },
    {
      "codice": "001304",
      "nome": "Villareggia",
      "nomeStraniero": null,
      "codiceCatastale": "M004",
      "cap": "10030",
      "prefisso": "0161",
      "provincia": "Torino",
      "email": "info@comune.villareggia.to.it",
      "pec": "comune.villareggia@pec.it",
      "telefono": "+39 0161 45464",
      "fax": "+39 0161 45118",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "001308",
      "nome": "Villastellone",
      "nomeStraniero": null,
      "codiceCatastale": "M027",
      "cap": "10029",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.villastellone.to.it",
      "pec": "comune.villastellone.to@legalmail.it",
      "telefono": "+39 0119614111",
      "fax": "+39 0119614150",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 7.75
      }
    },
    {
      "codice": "001309",
      "nome": "Vinovo",
      "nomeStraniero": null,
      "codiceCatastale": "M060",
      "cap": "10048",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "area.politichesociali@comune.vinovo.to.it",
      "pec": "protocollo.vinovo@cert.ruparpiemonte.it",
      "telefono": "+39 011 9620405",
      "fax": "+39 011 9620437",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 7.633333206176758
      }
    },
    {
      "codice": "001310",
      "nome": "Virle Piemonte",
      "nomeStraniero": null,
      "codiceCatastale": "M069",
      "cap": "10060",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "virle.piemonte@ruparpiemonte.it",
      "pec": "virle.piemonte@cert.ruparpiemonte.it",
      "telefono": "+39 011 9739223",
      "fax": "+39 011 9739119",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 7.566666603088379
      }
    },
    {
      "codice": "001311",
      "nome": "Vische",
      "nomeStraniero": null,
      "codiceCatastale": "M071",
      "cap": "10030",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.vische.to.it",
      "pec": "vische@cert.ruparpiemonte.it",
      "telefono": "+39 011 9837501",
      "fax": "+39 011 9837810",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "001312",
      "nome": "Vistrorio",
      "nomeStraniero": null,
      "codiceCatastale": "M080",
      "cap": "10080",
      "prefisso": "0125",
      "provincia": "Torino",
      "email": "sindaco.vistrorio@ruparpiemonte.it",
      "pec": "vistrorio@cert.ruparpiemonte.it",
      "telefono": "+39 0125 789072",
      "fax": "+39 0125 791963",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "001313",
      "nome": "Viù",
      "nomeStraniero": null,
      "codiceCatastale": "M094",
      "cap": "10070",
      "prefisso": "0123",
      "provincia": "Torino",
      "email": "elettoralecommercio@comune.viu.to.it",
      "pec": "viu@cert.ruparpiemonte.it",
      "telefono": "+39 0123696101",
      "fax": "+39 0123696264",
      "coordinate": {
        "lat": 45.23833465576172,
        "lng": 7.375555515289307
      }
    },
    {
      "codice": "001314",
      "nome": "Volpiano",
      "nomeStraniero": null,
      "codiceCatastale": "M122",
      "cap": "10088",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "info@comune.volpiano.to.it",
      "pec": "protocollo@pec.comune.volpiano.to.it",
      "telefono": "+39 011 9954511",
      "fax": "+39 011 9954512",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 7.7833333015441895
      }
    },
    {
      "codice": "001315",
      "nome": "Volvera",
      "nomeStraniero": null,
      "codiceCatastale": "M133",
      "cap": "10040",
      "prefisso": "011",
      "provincia": "Torino",
      "email": "urp@comune.volvera.to.it",
      "pec": "comune.volvera.to@legalmail.it",
      "telefono": "+39 011 9857200",
      "fax": "+39 011 9850655",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 7.5
      }
    }
  ],
  "Belluno": [
    {
      "codice": "025001",
      "nome": "Agordo",
      "nomeStraniero": null,
      "codiceCatastale": "A083",
      "cap": "32021",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "agordo@agordino.bl.it",
      "pec": "comune.agordo.bl@pecveneto.it",
      "telefono": "+39 0437641295",
      "fax": "+39 043765019",
      "coordinate": {
        "lat": 46.282222747802734,
        "lng": 12.034444808959961
      }
    },
    {
      "codice": "025002",
      "nome": "Alano di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "A121",
      "cap": "32031",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "alano@feltrino.bl.it",
      "pec": "anagrafe.comune.alanodipiave.bl@pecveneto.it",
      "telefono": "+39 0439779020",
      "fax": "+39 0439779003",
      "coordinate": {
        "lat": 45.907222747802734,
        "lng": 11.907777786254883
      }
    },
    {
      "codice": "025003",
      "nome": "Alleghe",
      "nomeStraniero": null,
      "codiceCatastale": "A206",
      "cap": "32022",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "alleghe@agordino.bl.it",
      "pec": "serv.amm.comune.alleghe.bl@pecveneto.it",
      "telefono": "0437523300",
      "fax": "0437723920",
      "coordinate": {
        "lat": 46.40663528442383,
        "lng": 12.021464347839355
      }
    },
    {
      "codice": "025072",
      "nome": "Alpago",
      "nomeStraniero": null,
      "codiceCatastale": "M375",
      "cap": "32016",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "amministrazione.alpago@alpago.bl.it",
      "pec": "alpago.bl@cert.ip-veneto.net",
      "telefono": "+39 0437/478086",
      "fax": "+39 0437/478127",
      "coordinate": {
        "lat": 46.12630081176758,
        "lng": 12.352499961853027
      }
    },
    {
      "codice": "025004",
      "nome": "Arsiè",
      "nomeStraniero": null,
      "codiceCatastale": "A443",
      "cap": "32030",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "arsie@feltrino.bl.it",
      "pec": "arsie.bl@cert.ip-veneto.net",
      "telefono": "+39 043959115",
      "fax": "+39 0439750000",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 11.75
      }
    },
    {
      "codice": "025005",
      "nome": "Auronzo di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "A501",
      "cap": "32041",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "ragioneria@comune.auronzo.bl.it",
      "pec": "auronzo.bl@cert.ip-veneto.net",
      "telefono": "+39 0435400273",
      "fax": "+39 0435400106",
      "coordinate": {
        "lat": 46.55115509033203,
        "lng": 12.443038940429688
      }
    },
    {
      "codice": "025006",
      "nome": "Belluno",
      "nomeStraniero": null,
      "codiceCatastale": "A757",
      "cap": "32100",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "info@comune.belluno.it",
      "pec": "belluno.bl@cert.ip-veneto.net",
      "telefono": "+39 0437913158",
      "fax": "+39 0437913138",
      "coordinate": {
        "lat": 46.140830993652344,
        "lng": 12.215560913085938
      }
    },
    {
      "codice": "025007",
      "nome": "Borca di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "A982",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "comune.borca@valboite.bl.it",
      "pec": "borca.bl@cert.ip-veneto.net",
      "telefono": "+39 0435482328",
      "fax": "+39 0435482101",
      "coordinate": {
        "lat": 46.436424255371094,
        "lng": 12.219597816467285
      }
    },
    {
      "codice": "025074",
      "nome": "Borgo Valbelluna",
      "nomeStraniero": null,
      "codiceCatastale": "M421",
      "cap": "32026",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "demografico@borgovalbelluna.bl.it",
      "pec": "borgovalbelluna.bl@cert.ip-veneto.net",
      "telefono": "04375441",
      "fax": "0437544222",
      "coordinate": {
        "lat": 46.0620002746582,
        "lng": 12.079610824584961
      }
    },
    {
      "codice": "025008",
      "nome": "Calalzo di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "B375",
      "cap": "32042",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "anagrafe@comune.calalzo.bl.it",
      "pec": "comune.calalzodicadore.bl@pecveneto.it",
      "telefono": "+39 0435519752",
      "fax": "+39 0435519839",
      "coordinate": {
        "lat": 46.44671630859375,
        "lng": 12.380385398864746
      }
    },
    {
      "codice": "025023",
      "nome": "Canale d'Agordo",
      "nomeStraniero": null,
      "codiceCatastale": "B574",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "canale.agordo@agordino.bl.it",
      "pec": "comune.canale.bl@pecveneto.it",
      "telefono": "+39 0437590323",
      "fax": "+39 0437590221",
      "coordinate": {
        "lat": 46.36055374145508,
        "lng": 11.914443969726562
      }
    },
    {
      "codice": "025010",
      "nome": "Cencenighe Agordino",
      "nomeStraniero": null,
      "codiceCatastale": "C458",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "cencenighe@agordino.bl.it",
      "pec": "segreteria.comune.cencenighe.bl@pecveneto.it",
      "telefono": "+39 0437591108",
      "fax": "+39 0437591121",
      "coordinate": {
        "lat": 46.34400177001953,
        "lng": 11.974444389343262
      }
    },
    {
      "codice": "025011",
      "nome": "Cesiomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C577",
      "cap": "32030",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "cesiomaggiore@feltrino.bl.it",
      "pec": "cesiomaggiore.bl@cert.ip-veneto.net",
      "telefono": "+39 0439438434",
      "fax": "+39 0439438012",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 11.983333587646484
      }
    },
    {
      "codice": "025012",
      "nome": "Chies d'Alpago",
      "nomeStraniero": null,
      "codiceCatastale": "C630",
      "cap": "32010",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo.chies@alpago.bl.it",
      "pec": "comune.chiesdalpago.bl@pecveneto.it",
      "telefono": "+39 0437470072",
      "fax": "+39 0437440066",
      "coordinate": {
        "lat": 46.16532897949219,
        "lng": 12.392472267150879
      }
    },
    {
      "codice": "025013",
      "nome": "Cibiana di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "C672",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segreteria.cibiana@valboite.bl.it",
      "pec": "cibiana.bl@cert.ip-veneto.net",
      "telefono": "+39 043574018",
      "fax": "+39 043574261",
      "coordinate": {
        "lat": 46.3916130065918,
        "lng": 12.290800094604492
      }
    },
    {
      "codice": "025014",
      "nome": "Colle Santa Lucia",
      "nomeStraniero": null,
      "codiceCatastale": "C872",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo.slucia@agordino.bl.it",
      "pec": "protocollo.comune.collesantalucia.bl@pecveneto.it",
      "telefono": "+39 0437720004",
      "fax": "+39 0437520007",
      "coordinate": {
        "lat": 46.44704818725586,
        "lng": 12.01402473449707
      }
    },
    {
      "codice": "025015",
      "nome": "Comelico Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "C920",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segr.comelico@cmcs.it",
      "pec": "comune.comelicosuperiore@pec.it",
      "telefono": "+39 0435 68813",
      "fax": "+39 043568404",
      "coordinate": {
        "lat": 46.588829040527344,
        "lng": 12.516853332519531
      }
    },
    {
      "codice": "025016",
      "nome": "Cortina d'Ampezzo",
      "nomeStraniero": null,
      "codiceCatastale": "A266",
      "cap": "32043",
      "prefisso": "0436",
      "provincia": "Belluno",
      "email": "segreteria@comunecortinadampezzo.bl.it",
      "pec": "cortina@pec.comunecortinadampezzo.it",
      "telefono": "+39 0436/4291",
      "fax": "+39 0436/868445",
      "coordinate": {
        "lat": 46.538333892822266,
        "lng": 12.137351036071777
      }
    },
    {
      "codice": "025017",
      "nome": "Danta di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "D247",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "ut.danta@cmcs.it",
      "pec": "comune.dantadicadore@pec.it",
      "telefono": "+39 0435650072",
      "fax": "+39 0435650191",
      "coordinate": {
        "lat": 46.5660285949707,
        "lng": 12.517301559448242
      }
    },
    {
      "codice": "025018",
      "nome": "Domegge di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "D330",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "anagrafe.domegge@cmcs.it",
      "pec": "protocollo.comune.domeggedicadore.bl@pecveneto.it",
      "telefono": "+39 043572061",
      "fax": "+39 0435728170",
      "coordinate": {
        "lat": 46.46037673950195,
        "lng": 12.415824890136719
      }
    },
    {
      "codice": "025019",
      "nome": "Falcade",
      "nomeStraniero": null,
      "codiceCatastale": "D470",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "falcade@agordino.bl.it",
      "pec": "protocollo.comune.falcade.bl@pecveneto.it",
      "telefono": "+39 0437599735",
      "fax": "+39 0437599629",
      "coordinate": {
        "lat": 46.35844039916992,
        "lng": 11.872380256652832
      }
    },
    {
      "codice": "025021",
      "nome": "Feltre",
      "nomeStraniero": null,
      "codiceCatastale": "D530",
      "cap": "32032",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "protocollo@comune.feltre.bl.it",
      "pec": "comune.feltre.bl@pecveneto.it",
      "telefono": "+39 0439885317",
      "fax": "+39 0439885246",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "025022",
      "nome": "Fonzaso",
      "nomeStraniero": null,
      "codiceCatastale": "D686",
      "cap": "32030",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "segreteria.fonzaso@feltrino.bl.it",
      "pec": "fonzaso.bl@cert.ip-veneto.net",
      "telefono": "+39 04395701",
      "fax": "+39 0439570122",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "025025",
      "nome": "Gosaldo",
      "nomeStraniero": null,
      "codiceCatastale": "E113",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "gosaldo@agordino.bl.it",
      "pec": "comune.gosaldo.bl@pecveneto.it",
      "telefono": "+39 043768105",
      "fax": "+39 043768427",
      "coordinate": {
        "lat": 46.22222137451172,
        "lng": 11.956666946411133
      }
    },
    {
      "codice": "025027",
      "nome": "La Valle Agordina",
      "nomeStraniero": null,
      "codiceCatastale": "E490",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "la.valle@agordino.bl.it",
      "pec": "comune.lavalleagordina.bl@pecveneto.it",
      "telefono": "+39 043762298",
      "fax": "+39 043762027",
      "coordinate": {
        "lat": 46.28129959106445,
        "lng": 12.065999984741211
      }
    },
    {
      "codice": "025026",
      "nome": "Lamon",
      "nomeStraniero": null,
      "codiceCatastale": "E429",
      "cap": "32033",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "lamon@feltrino.bl.it",
      "pec": "lamon@postemailcertificata.it",
      "telefono": "+39 0439794201",
      "fax": "+39 0439794234",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 11.75
      }
    },
    {
      "codice": "025029",
      "nome": "Limana",
      "nomeStraniero": null,
      "codiceCatastale": "E588",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "limana@valbelluna.bl.it",
      "pec": "limana.bl@cert.ip-veneto.net",
      "telefono": "+39 0437966111",
      "fax": "+39 0437966166",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 12.183333396911621
      }
    },
    {
      "codice": "025030",
      "nome": "Livinallongo del Col di Lana",
      "nomeStraniero": null,
      "codiceCatastale": "E622",
      "cap": "32020",
      "prefisso": "0436",
      "provincia": "Belluno",
      "email": "livinallongo@agordino.bl.it",
      "pec": "sindaco.comune.livinallongo.bl@pecveneto.it",
      "telefono": "+39 04367193",
      "fax": "+39 04367413",
      "coordinate": {
        "lat": 46.4815788269043,
        "lng": 11.954264640808105
      }
    },
    {
      "codice": "025071",
      "nome": "Longarone",
      "nomeStraniero": null,
      "codiceCatastale": "M342",
      "cap": "32013",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "comune@longarone.net",
      "pec": "comune.longarone.bl@pecveneto.it",
      "telefono": "+39 0437575811",
      "fax": "+39 0437771445",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "025032",
      "nome": "Lorenzago di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "E687",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segretario.lorenzago@cmcs.it",
      "pec": "comune.lorenzagodicadore.bl@pecveneto.it",
      "telefono": "+39 043575001",
      "fax": "+39 043575329",
      "coordinate": {
        "lat": 46.4796257019043,
        "lng": 12.459403038024902
      }
    },
    {
      "codice": "025033",
      "nome": "Lozzo di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "E708",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segr.lozzo@cmcs.it",
      "pec": "comune.lozzodicadore.bl@pecveneto.it",
      "telefono": "+39 043576023 - 043576393",
      "fax": "+39 043576383",
      "coordinate": {
        "lat": 46.48457336425781,
        "lng": 12.443341255187988
      }
    },
    {
      "codice": "025035",
      "nome": "Ospitale di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "G169",
      "cap": "32010",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "ospitale@clz.bl.it",
      "pec": "comune.ospitaledicadore.bl@pecveneto.it",
      "telefono": "+39 0437779002",
      "fax": "+39 0437779048",
      "coordinate": {
        "lat": 46.329166412353516,
        "lng": 12.324166297912598
      }
    },
    {
      "codice": "025036",
      "nome": "Pedavena",
      "nomeStraniero": null,
      "codiceCatastale": "G404",
      "cap": "32034",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "pedavena@feltrino.bl.it",
      "pec": "segreteria.pedavena.bl@pecveneto.it",
      "telefono": "+39 0439319611",
      "fax": "+39 0439319777",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "025037",
      "nome": "Perarolo di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "G442",
      "cap": "32010",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "anagrafe.perarolo@cmcs.it",
      "pec": "perarolo.bl@cert.ip-veneto.net",
      "telefono": "+39 0435/71036",
      "fax": "+39 0435/71015",
      "coordinate": {
        "lat": 46.39555740356445,
        "lng": 12.356389045715332
      }
    },
    {
      "codice": "025039",
      "nome": "Pieve di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "G642",
      "cap": "32044",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "rag1.pieve@cmcs.it",
      "pec": "pievedicadore.bl@cert.ip-veneto.net",
      "telefono": "+39 0435500083",
      "fax": "+39 0435500380",
      "coordinate": {
        "lat": 46.428707122802734,
        "lng": 12.375296592712402
      }
    },
    {
      "codice": "025040",
      "nome": "Ponte nelle Alpi",
      "nomeStraniero": null,
      "codiceCatastale": "B662",
      "cap": "32014",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo@pna.bl.it",
      "pec": "comune@pec.pna.bl.it",
      "telefono": "+39 04379866",
      "fax": "+39 0437999544",
      "coordinate": {
        "lat": 46.18299865722656,
        "lng": 12.27910041809082
      }
    },
    {
      "codice": "025070",
      "nome": "Quero Vas",
      "nomeStraniero": null,
      "codiceCatastale": "M332",
      "cap": "32038",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "protocollo.setteville@feltrino.bl.it",
      "pec": "querovas.bl@cert.ip-veneto.net",
      "telefono": "+39 0439781811",
      "fax": "+39 0439787584",
      "coordinate": {
        "lat": 45.92277908325195,
        "lng": 11.932499885559082
      }
    },
    {
      "codice": "025043",
      "nome": "Rivamonte Agordino",
      "nomeStraniero": null,
      "codiceCatastale": "H327",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "rivamonte@agordino.bl.it",
      "pec": "comune.rivamonteagordino.bl@pecveneto.it",
      "telefono": "+39 0437 69128",
      "fax": "+39 0437 69143",
      "coordinate": {
        "lat": 46.25388717651367,
        "lng": 12.02388858795166
      }
    },
    {
      "codice": "025044",
      "nome": "Rocca Pietore",
      "nomeStraniero": null,
      "codiceCatastale": "H379",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo.rocca@agordino.bl.it",
      "pec": "comune.roccapietore.bl@pecveneto.it",
      "telefono": "+39 0437721178",
      "fax": "+39 0437721637",
      "coordinate": {
        "lat": 46.433868408203125,
        "lng": 11.97716236114502
      }
    },
    {
      "codice": "025045",
      "nome": "San Gregorio nelle Alpi",
      "nomeStraniero": null,
      "codiceCatastale": "H938",
      "cap": "32030",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "sangregorio@feltrino.bl.it",
      "pec": "sangregorio.bl@cert.ip-veneto.net",
      "telefono": "+39 0437800018",
      "fax": "+39 0437801455",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "025046",
      "nome": "San Nicolò di Comelico",
      "nomeStraniero": null,
      "codiceCatastale": "I063",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "anagrafe.sannicolo@cmcs.it",
      "pec": "comune.sannicolo.bl@pecveneto.it",
      "telefono": "+39 0435 62314",
      "fax": "+39 0435 428154",
      "coordinate": {
        "lat": 46.58252716064453,
        "lng": 12.527301788330078
      }
    },
    {
      "codice": "025047",
      "nome": "San Pietro di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "I088",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segr.sanpietro@cmcs.it",
      "pec": "comune.sanpietrodicadore@pec.it",
      "telefono": "+39 0435/460500",
      "fax": "+39 0435/460014",
      "coordinate": {
        "lat": 46.57072067260742,
        "lng": 12.5860013961792
      }
    },
    {
      "codice": "025049",
      "nome": "San Tomaso Agordino",
      "nomeStraniero": null,
      "codiceCatastale": "I347",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "segreteria.santomaso@agordino.bl.it",
      "pec": "protocollo.comune.santomaso.bl@pecveneto.it",
      "telefono": "+39 0437  598004",
      "fax": "+39 0437  598034",
      "coordinate": {
        "lat": 46.38333511352539,
        "lng": 11.983333587646484
      }
    },
    {
      "codice": "025051",
      "nome": "San Vito di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "I392",
      "cap": "32046",
      "prefisso": "0436",
      "provincia": "Belluno",
      "email": "anagrafe.sanvito@valboite.bl.it",
      "pec": "sanvitocadore.bl@cert.ip-veneto.net",
      "telefono": "+39 0436 897211",
      "fax": "+39 0436 890144",
      "coordinate": {
        "lat": 46.45896530151367,
        "lng": 12.205741882324219
      }
    },
    {
      "codice": "025048",
      "nome": "Santa Giustina",
      "nomeStraniero": null,
      "codiceCatastale": "I206",
      "cap": "32035",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "segreteria.santagiustina@feltrino.bl.it",
      "pec": "comune.santagiustina.bl@pecveneto.it",
      "telefono": "+39 0437 858101",
      "fax": "+39 0437 888968",
      "coordinate": {
        "lat": 46.083885192871094,
        "lng": 12.043238639831543
      }
    },
    {
      "codice": "025050",
      "nome": "Santo Stefano di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "C919",
      "cap": "32045",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segr.santostefano@cmcs.it",
      "pec": "comune.santostefanodicadore@pec.it",
      "telefono": "+39 0425/62305",
      "fax": "+39 0435/428161",
      "coordinate": {
        "lat": 46.55793762207031,
        "lng": 12.549141883850098
      }
    },
    {
      "codice": "025053",
      "nome": "Sedico",
      "nomeStraniero": null,
      "codiceCatastale": "I563",
      "cap": "32036",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo@comune.sedico.bl.it",
      "pec": "sedico.bl@cert.ip-veneto.net",
      "telefono": "+39 0437-8555",
      "fax": "+39 0437-855600",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "025054",
      "nome": "Selva di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "I592",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo.selva@agordino.bl.it",
      "pec": "comune.selva.bl@pecveneto.it",
      "telefono": "+39 0437 720100",
      "fax": "+39 0437 720401",
      "coordinate": {
        "lat": 46.451385498046875,
        "lng": 12.034524917602539
      }
    },
    {
      "codice": "025055",
      "nome": "Seren del Grappa",
      "nomeStraniero": null,
      "codiceCatastale": "I626",
      "cap": "32030",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "seren.bl@cert.ip-veneto.net",
      "pec": "seren.bl@cert.ip-veneto.net",
      "telefono": "+39 043944013",
      "fax": "+39 0439 394147",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 11.850000381469727
      }
    },
    {
      "codice": "025056",
      "nome": "Sospirolo",
      "nomeStraniero": null,
      "codiceCatastale": "I866",
      "cap": "32037",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "protocollo@comune.sospirolo.bl.it",
      "pec": "sospirolo.bl@cert.ip-veneto.net",
      "telefono": "+39 0437 8446",
      "fax": "+39 0437 844500",
      "coordinate": {
        "lat": 46.133331298828125,
        "lng": 12.083333015441895
      }
    },
    {
      "codice": "025057",
      "nome": "Soverzene",
      "nomeStraniero": null,
      "codiceCatastale": "I876",
      "cap": "32010",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "soverzene@clz.bl.it",
      "pec": "comune.soverzene.bl@pecveneto.it",
      "telefono": "+39 0437998118",
      "fax": "+39 0437998942",
      "coordinate": {
        "lat": 46.204166412353516,
        "lng": 12.303055763244629
      }
    },
    {
      "codice": "025058",
      "nome": "Sovramonte",
      "nomeStraniero": null,
      "codiceCatastale": "I673",
      "cap": "32030",
      "prefisso": "0439",
      "provincia": "Belluno",
      "email": "segreteria.sovramonte@feltrino.bl.it",
      "pec": "sovramonte.bl@cert.ip-veneto.net",
      "telefono": "+39 043998508",
      "fax": "+39 043998303",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "025059",
      "nome": "Taibon Agordino",
      "nomeStraniero": null,
      "codiceCatastale": "L030",
      "cap": "32027",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "segreteria@comune.taibonagordino.bl.it",
      "pec": "comune.taibonagordino.bl@pecveneto.it",
      "telefono": "+39 0437 660007",
      "fax": "+39 0437 661002",
      "coordinate": {
        "lat": 46.29999923706055,
        "lng": 12.016666412353516
      }
    },
    {
      "codice": "025060",
      "nome": "Tambre",
      "nomeStraniero": null,
      "codiceCatastale": "L040",
      "cap": "32010",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "segreteria.tambre@alpago.bl.it",
      "pec": "comune.tambre.bl@pecveneto.it",
      "telefono": "+39 043749422",
      "fax": "+39 043749498",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 12.416666984558105
      }
    },
    {
      "codice": "025073",
      "nome": "Val di Zoldo",
      "nomeStraniero": null,
      "codiceCatastale": "M374",
      "cap": "32012",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "valdizoldo@clz.bl.it",
      "pec": "comune.valdizoldo.bl@pecveneto.it",
      "telefono": "+39 0437789177",
      "fax": "+39 0437789101",
      "coordinate": {
        "lat": 46.34870147705078,
        "lng": 12.182999610900879
      }
    },
    {
      "codice": "025062",
      "nome": "Vallada Agordina",
      "nomeStraniero": null,
      "codiceCatastale": "L584",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "vallada@agordino.bl.it",
      "pec": "comune.vallada.bl@pecveneto.it",
      "telefono": "+39 0437 591183",
      "fax": "+39 0437 581684",
      "coordinate": {
        "lat": 46.36666488647461,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "025063",
      "nome": "Valle di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "L590",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segreteria@comune.valledicadore.bl.it",
      "pec": "comune.valledicadore.bl@pecveneto.it",
      "telefono": "+39 0435 505415",
      "fax": "+39 0435 519075",
      "coordinate": {
        "lat": 46.417354583740234,
        "lng": 12.331982612609863
      }
    },
    {
      "codice": "025065",
      "nome": "Vigo di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "L890",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "segr.vigo@cmcs.it",
      "pec": "comune.vigodicadore.bl@pecveneto.it",
      "telefono": "+39 0435 77002",
      "fax": "+39 0435 77822",
      "coordinate": {
        "lat": 46.50044631958008,
        "lng": 12.472620964050293
      }
    },
    {
      "codice": "025066",
      "nome": "Vodo Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "M108",
      "cap": "32040",
      "prefisso": "0435",
      "provincia": "Belluno",
      "email": "anagrafe.vodo@valboite.bl.it",
      "pec": "vodocadore.bl@cert.ip-veneto.net",
      "telefono": "+39 0435489019",
      "fax": "+39 0435489446",
      "coordinate": {
        "lat": 46.419620513916016,
        "lng": 12.244898796081543
      }
    },
    {
      "codice": "025067",
      "nome": "Voltago Agordino",
      "nomeStraniero": null,
      "codiceCatastale": "M124",
      "cap": "32020",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "anagrafe.voltago@agordino.bl.it",
      "pec": "comune.voltagoagordino.bl@pecveneto.it",
      "telefono": "+39 0437/669132",
      "fax": "+39 0437/669193",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "025069",
      "nome": "Zoppè di Cadore",
      "nomeStraniero": null,
      "codiceCatastale": "M189",
      "cap": "32010",
      "prefisso": "0437",
      "provincia": "Belluno",
      "email": "zoppe@clz.bl.it",
      "pec": "comune.zoppedicadore.bl@pecveneto.it",
      "telefono": "+39 0437 791000",
      "fax": "+39 0437 791900",
      "coordinate": {
        "lat": 46.38666534423828,
        "lng": 12.172778129577637
      }
    }
  ],
  "Varese": [
    {
      "codice": "012001",
      "nome": "Agra",
      "nomeStraniero": null,
      "codiceCatastale": "A085",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "comuneagra@libero.it",
      "pec": "comuneagra@legalmail.it",
      "telefono": "+39 0332517038",
      "fax": "+39 0332517515",
      "coordinate": {
        "lat": 46.0349006652832,
        "lng": 8.771599769592285
      }
    },
    {
      "codice": "012002",
      "nome": "Albizzate",
      "nomeStraniero": null,
      "codiceCatastale": "A167",
      "cap": "21041",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "affarigenerali@comune.albizzate.va.it",
      "pec": "comune.albizzate@halleypec.it",
      "telefono": "+39 0331993427",
      "fax": "+39 0331985355",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012003",
      "nome": "Angera",
      "nomeStraniero": null,
      "codiceCatastale": "A290",
      "cap": "21021",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.angera.it",
      "pec": "comune.angera@pec.regione.lombardia.it",
      "telefono": "+39 0331930168",
      "fax": "+39 0331960181",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.583333015441895
      }
    },
    {
      "codice": "012004",
      "nome": "Arcisate",
      "nomeStraniero": null,
      "codiceCatastale": "A371",
      "cap": "21051",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria@comunearcisate.va.it",
      "pec": "comune.arcisate@anutel.it",
      "telefono": "+39 0332 470124",
      "fax": "+39 0332 474396",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012005",
      "nome": "Arsago Seprio",
      "nomeStraniero": null,
      "codiceCatastale": "A441",
      "cap": "21010",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "servizidemografici@comune.arsagoseprio.va.it",
      "pec": "comunediarsagoseprio@legalmail.it",
      "telefono": "+39 0331299911",
      "fax": "+39 0331769540",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "012006",
      "nome": "Azzate",
      "nomeStraniero": null,
      "codiceCatastale": "A531",
      "cap": "21022",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "infoazzate@comune.azzate.va.it",
      "pec": "comunediazzate@postemailcertificata.it",
      "telefono": "+39 0332456311",
      "fax": "+39 0332458738",
      "coordinate": {
        "lat": 45.78030014038086,
        "lng": 8.793800354003906
      }
    },
    {
      "codice": "012007",
      "nome": "Azzio",
      "nomeStraniero": null,
      "codiceCatastale": "A532",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografici@comune.azzio.va.it",
      "pec": "comune.azzio.va@halleycert.it",
      "telefono": "+39 0332 631432",
      "fax": "+39 0332 630231",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012008",
      "nome": "Barasso",
      "nomeStraniero": null,
      "codiceCatastale": "A619",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.barasso.va.it",
      "pec": "comune.barasso@pec.regione.lombardia.it",
      "telefono": "+39 0332743386",
      "fax": "+39 0332730922",
      "coordinate": {
        "lat": 45.84040069580078,
        "lng": 8.756400108337402
      }
    },
    {
      "codice": "012009",
      "nome": "Bardello",
      "nomeStraniero": null,
      "codiceCatastale": "A645",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.bardello.va.it",
      "pec": "comune.bardello@pec.regione.lombardia.it",
      "telefono": "+39 0332743152",
      "fax": "+39 0332730753",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012010",
      "nome": "Bedero Valcuvia",
      "nomeStraniero": null,
      "codiceCatastale": "A728",
      "cap": "21039",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.bederovalcuvia.va.it",
      "pec": "bederovalcuvia@halleypec.it",
      "telefono": "+39 0332724587",
      "fax": "+39 0332724587",
      "coordinate": {
        "lat": 45.91388702392578,
        "lng": 8.79444408416748
      }
    },
    {
      "codice": "012011",
      "nome": "Besano",
      "nomeStraniero": null,
      "codiceCatastale": "A819",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe.besano@comunebesano.191.it",
      "pec": "comune.besano@pec.regione.lombardia.it",
      "telefono": "+39 0332916260",
      "fax": "+39 0332916568",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "012012",
      "nome": "Besnate",
      "nomeStraniero": null,
      "codiceCatastale": "A825",
      "cap": "21010",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demografici@comune.besnate.va.it",
      "pec": "protocollo@pec.comune.besnate.va.it",
      "telefono": "+39 0331275830",
      "fax": "+39 0331275829",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "012013",
      "nome": "Besozzo",
      "nomeStraniero": null,
      "codiceCatastale": "A826",
      "cap": "21023",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria@comune.besozzo.va.it",
      "pec": "comune.besozzo.va@halleycert.it",
      "telefono": "+39 0332970195",
      "fax": "+39 0332970178",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "012014",
      "nome": "Biandronno",
      "nomeStraniero": null,
      "codiceCatastale": "A845",
      "cap": "21024",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "ufficioanagrafe@comune.biandronno.va.it",
      "pec": "protocollo@pec.comune.biandronno.va.it",
      "telefono": "+39 0332766131",
      "fax": "+39 0332766508",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "012015",
      "nome": "Bisuschio",
      "nomeStraniero": null,
      "codiceCatastale": "A891",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "bisuschio@comune.bisuschio.va.it",
      "pec": "comune.bisuschio@pec.regione.lombardia.it",
      "telefono": "+39 0332470154",
      "fax": "+39 0332850144",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012016",
      "nome": "Bodio Lomnago",
      "nomeStraniero": null,
      "codiceCatastale": "A918",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.bodiolomnago.va.it",
      "pec": "comune.bodiolomnago@pec.regione.lombardia.it",
      "telefono": "+39 0332947136",
      "fax": "+39 0332949520",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.75
      }
    },
    {
      "codice": "012017",
      "nome": "Brebbia",
      "nomeStraniero": null,
      "codiceCatastale": "B126",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "ufficidemografici@comune.brebbia.va.it",
      "pec": "segreteria@brebbia.legalmailpa.it",
      "telefono": "+39 0332770283",
      "fax": "+39 0332771264",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.649999618530273
      }
    },
    {
      "codice": "012018",
      "nome": "Bregano",
      "nomeStraniero": null,
      "codiceCatastale": "B131",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.bregano.va.it",
      "pec": "comune.bregano.va@halleypec.it",
      "telefono": "+39 0332706691",
      "fax": "+39 0332707677",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "012019",
      "nome": "Brenta",
      "nomeStraniero": null,
      "codiceCatastale": "B150",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.brenta.va.it",
      "pec": "comune.brenta.va@halleycert.it",
      "telefono": "+39 0332601459",
      "fax": "+39 0332602214",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "012020",
      "nome": "Brezzo di Bedero",
      "nomeStraniero": null,
      "codiceCatastale": "B166",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "comune@comune.brezzo-di-bedero.va.it",
      "pec": "comune.brezzo-di-bedero@legalmail.it",
      "telefono": "+39 0332511707",
      "fax": "+39 0332500518",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "012021",
      "nome": "Brinzio",
      "nomeStraniero": null,
      "codiceCatastale": "B182",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.brinzio.va.it",
      "pec": "comune.brinzio.va@halleypec.it",
      "telefono": "+39 0332435714",
      "fax": "+39 0332435351",
      "coordinate": {
        "lat": 45.88826370239258,
        "lng": 8.79012680053711
      }
    },
    {
      "codice": "012022",
      "nome": "Brissago-Valtravaglia",
      "nomeStraniero": null,
      "codiceCatastale": "B191",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "combrissago@comune.brissago-valtravaglia.va.it",
      "pec": "comune@pec.comune.brissago-valtravaglia.va.it",
      "telefono": "+39 0332575103",
      "fax": "+39 0332576443",
      "coordinate": {
        "lat": 45.950599670410156,
        "lng": 8.74020004272461
      }
    },
    {
      "codice": "012023",
      "nome": "Brunello",
      "nomeStraniero": null,
      "codiceCatastale": "B219",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "statocivile@comune.brunello.va.it",
      "pec": "protocollo.brunello@pec.it",
      "telefono": "+39 0332455270",
      "fax": "+39 0332455654",
      "coordinate": {
        "lat": 45.76583480834961,
        "lng": 8.796388626098633
      }
    },
    {
      "codice": "012024",
      "nome": "Brusimpiano",
      "nomeStraniero": null,
      "codiceCatastale": "B228",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe.brusimpiano@demosdata.it",
      "pec": "affarigenerali@comune.brusimpiano.legalmail.it",
      "telefono": "+39 0332934119",
      "fax": "+39 0332934479",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "012025",
      "nome": "Buguggiate",
      "nomeStraniero": null,
      "codiceCatastale": "B258",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comunebuguggiate.it",
      "pec": "affarigenerali@pec.comunebuguggiate.it",
      "telefono": "+39 0332459166",
      "fax": "+39 0332459356",
      "coordinate": {
        "lat": 45.7859992980957,
        "lng": 8.805000305175781
      }
    },
    {
      "codice": "012026",
      "nome": "Busto Arsizio",
      "nomeStraniero": null,
      "codiceCatastale": "B300",
      "cap": "21052",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "info@comune.bustoarsizio.va.it",
      "pec": "protocollo@comune.bustoarsizio.va.legalmail.it",
      "telefono": "+39 0331 390111",
      "fax": "+39 0331 390291",
      "coordinate": {
        "lat": 45.61195755004883,
        "lng": 8.851799964904785
      }
    },
    {
      "codice": "012027",
      "nome": "Cadegliano-Viconago",
      "nomeStraniero": null,
      "codiceCatastale": "B326",
      "cap": "21031",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.cadeglianoviconago.va.it",
      "pec": "comune.cadeglianoviconago@pec.regione.lombardia.it",
      "telefono": "+39 0332591012",
      "fax": "+39 0332590056",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012143",
      "nome": "Cadrezzate con Osmate",
      "nomeStraniero": null,
      "codiceCatastale": "M425",
      "cap": "21062",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": null,
      "pec": "comune.cadrezzateconosmate@halleypec.it",
      "telefono": "0331/953102",
      "fax": "0331/910049",
      "coordinate": {
        "lat": 45.78947448730469,
        "lng": 8.64851188659668
      }
    },
    {
      "codice": "012029",
      "nome": "Cairate",
      "nomeStraniero": null,
      "codiceCatastale": "B368",
      "cap": "21050",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "ufficio.anagrafe@comune.cairate.va.it",
      "pec": "anagrafe.comunecairate@halleycert.it",
      "telefono": "+39 0331362201",
      "fax": null,
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012030",
      "nome": "Cantello",
      "nomeStraniero": null,
      "codiceCatastale": "B634",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.cantello.va.it",
      "pec": "comunedicantello@postecert.it",
      "telefono": "+39 0332419100",
      "fax": "+39 0332418508",
      "coordinate": {
        "lat": 45.81964874267578,
        "lng": 8.894669532775879
      }
    },
    {
      "codice": "012031",
      "nome": "Caravate",
      "nomeStraniero": null,
      "codiceCatastale": "B732",
      "cap": "21032",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.caravate.va.it",
      "pec": "comunecaravate@legalmail.it",
      "telefono": "+39 0332601355",
      "fax": "+39 0332604698",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 8.649999618530273
      }
    },
    {
      "codice": "012032",
      "nome": "Cardano al Campo",
      "nomeStraniero": null,
      "codiceCatastale": "B754",
      "cap": "21010",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "protocollo@comune.cardanoalcampo.va.it",
      "pec": "protocollo@cert.comune.cardanoalcampo.va.it",
      "telefono": "+39 0331266211",
      "fax": "+39 0331266275",
      "coordinate": {
        "lat": 45.64427947998047,
        "lng": 8.772480964660645
      }
    },
    {
      "codice": "012033",
      "nome": "Carnago",
      "nomeStraniero": null,
      "codiceCatastale": "B796",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.carnago.va.it",
      "pec": "comune.carnago@pec.it",
      "telefono": "+39 0331993593",
      "fax": "+39 0331985037",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012034",
      "nome": "Caronno Pertusella",
      "nomeStraniero": null,
      "codiceCatastale": "B805",
      "cap": "21042",
      "prefisso": "02",
      "provincia": "Varese",
      "email": "anagrafe@comune.caronnopertusella.va.it",
      "pec": "comune.caronnopertusella@pec.regione.lombardia.it",
      "telefono": "+39 0296512202",
      "fax": "+39 0296512228",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "012035",
      "nome": "Caronno Varesino",
      "nomeStraniero": null,
      "codiceCatastale": "B807",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.caronnovaresino.va.it",
      "pec": "comune.caronnovaresino@pec.regione.lombardia.it",
      "telefono": "+39 0331980405",
      "fax": "+39 0331982075",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012036",
      "nome": "Casale Litta",
      "nomeStraniero": null,
      "codiceCatastale": "B875",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.casalelitta.va.it",
      "pec": "comune.casalelitta@pec.regione.lombardia.it",
      "telefono": "+39 0332945636",
      "fax": "+39 0332945566",
      "coordinate": {
        "lat": 45.768611907958984,
        "lng": 8.74222183227539
      }
    },
    {
      "codice": "012037",
      "nome": "Casalzuigno",
      "nomeStraniero": null,
      "codiceCatastale": "B921",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografici@comune.casalzuigno.va.it",
      "pec": "comune.casalzuigno.va@halleycert.it",
      "telefono": "+39 0332624122",
      "fax": "+39 0332624203",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012038",
      "nome": "Casciago",
      "nomeStraniero": null,
      "codiceCatastale": "B949",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.casciago.va.it",
      "pec": "comune.casciago@pec.regione.lombardia.it",
      "telefono": "+39 033221100",
      "fax": "+39 0332227650",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "012039",
      "nome": "Casorate Sempione",
      "nomeStraniero": null,
      "codiceCatastale": "B987",
      "cap": "21011",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demografici@comune.casoratesempione.va.it",
      "pec": "casoratesempione@legalmail.it",
      "telefono": "+39 0331295052",
      "fax": "+39 0331295093",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "012040",
      "nome": "Cassano Magnago",
      "nomeStraniero": null,
      "codiceCatastale": "C004",
      "cap": "21012",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "protocollo@cassano-magnago.it",
      "pec": "protocollo.comune.cassanomagnago@pec.regione.lombardia.it",
      "telefono": "+39 0331283399",
      "fax": "+39 0331280411",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012041",
      "nome": "Cassano Valcuvia",
      "nomeStraniero": null,
      "codiceCatastale": "B999",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "mgrazia.casanova@comune.cassanovalcuvia.va.it",
      "pec": "comune.cassanovalcuvia.va@halleycert.it",
      "telefono": "+39 0332995519",
      "fax": "+39 0332995520",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "012042",
      "nome": "Castellanza",
      "nomeStraniero": null,
      "codiceCatastale": "C139",
      "cap": "21053",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demografici@comune.castellanza.va.it",
      "pec": "comune@pec.comune.castellanza.va.it",
      "telefono": "+39 0331526111",
      "fax": "+39 0331501049",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "012043",
      "nome": "Castello Cabiaglio",
      "nomeStraniero": null,
      "codiceCatastale": "B312",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.castellocabiaglio.va.it",
      "pec": "comune.castellocabiaglio.va@legalmail.it",
      "telefono": "+39 0332435813",
      "fax": null,
      "coordinate": {
        "lat": 45.893890380859375,
        "lng": 8.75694465637207
      }
    },
    {
      "codice": "012044",
      "nome": "Castelseprio",
      "nomeStraniero": null,
      "codiceCatastale": "C273",
      "cap": "21050",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "ufficio.anagrafe@castelseprio.net",
      "pec": "comune.castelseprio@legalmail.it",
      "telefono": "+39 0331820501",
      "fax": "+39 0331820831",
      "coordinate": {
        "lat": 45.71371841430664,
        "lng": 8.861610412597656
      }
    },
    {
      "codice": "012045",
      "nome": "Castelveccana",
      "nomeStraniero": null,
      "codiceCatastale": "C181",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografici@comune.castelveccana.va.it",
      "pec": "comune.castelveccana@pec.regione.lombardia.it",
      "telefono": "+39 0332520494",
      "fax": "+39 0332520464",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "012046",
      "nome": "Castiglione Olona",
      "nomeStraniero": null,
      "codiceCatastale": "C300",
      "cap": "21043",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "protocollo@castiglioneolona.it",
      "pec": "protocollo.comune.castiglioneolona@pec.regione.lombardia.it",
      "telefono": "+39 0331824801",
      "fax": "+39 0331824457",
      "coordinate": {
        "lat": 45.75310134887695,
        "lng": 8.87440013885498
      }
    },
    {
      "codice": "012047",
      "nome": "Castronno",
      "nomeStraniero": null,
      "codiceCatastale": "C343",
      "cap": "21040",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "servizi.demografici@comune.castronno.va.it",
      "pec": "protocollo.comune.castronno@pec.regione.lombardia.it",
      "telefono": "+39 0332896111",
      "fax": "+39 0332896229",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012048",
      "nome": "Cavaria con Premezzo",
      "nomeStraniero": null,
      "codiceCatastale": "C382",
      "cap": "21044",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": null,
      "pec": "cavariaconpremezzo@actaliscertymail.it",
      "telefono": "+39 0331217480",
      "fax": "+39 0331219786",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012049",
      "nome": "Cazzago Brabbia",
      "nomeStraniero": null,
      "codiceCatastale": "C409",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.cazzagobrabbia.va.it",
      "pec": "comune.cazzagobrabbia@legalmail.it",
      "telefono": "+39 0332964400",
      "fax": "+39 0332964498",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "012050",
      "nome": "Cislago",
      "nomeStraniero": null,
      "codiceCatastale": "C732",
      "cap": "21040",
      "prefisso": "02",
      "provincia": "Varese",
      "email": "anagrafe-elettorale@comunedicislago.it",
      "pec": "protocollocislago@legalmailpa.it",
      "telefono": "+39 029667101",
      "fax": "+39 0296671055",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "012051",
      "nome": "Cittiglio",
      "nomeStraniero": null,
      "codiceCatastale": "C751",
      "cap": "21033",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "affarigenerali@comune.cittiglio.va.it",
      "pec": "comunecittiglio@actaliscertymail.it",
      "telefono": "+39 0332601467",
      "fax": "+39 0332601141",
      "coordinate": {
        "lat": 45.896400451660156,
        "lng": 8.664899826049805
      }
    },
    {
      "codice": "012052",
      "nome": "Clivio",
      "nomeStraniero": null,
      "codiceCatastale": "C796",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria@comune.clivio.va.it",
      "pec": "comune.clivio@pec.regione.lombardia.it",
      "telefono": "+39 0332 486149",
      "fax": "+39 0332 485005",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "012053",
      "nome": "Cocquio-Trevisago",
      "nomeStraniero": null,
      "codiceCatastale": "C810",
      "cap": "21034",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografico@comune.cocquio-trevisago.va.it",
      "pec": "comune.cocquiotrevisago@pec.regione.lombardia.it",
      "telefono": "+39 0332975151",
      "fax": "+39 0332700977",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "012054",
      "nome": "Comabbio",
      "nomeStraniero": null,
      "codiceCatastale": "C911",
      "cap": "21020",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demografici@comune.comabbio.va.it",
      "pec": "comune.comabbio@legalmail.it",
      "telefono": "+39 0331968572",
      "fax": "+39 0331968808",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "012055",
      "nome": "Comerio",
      "nomeStraniero": null,
      "codiceCatastale": "C922",
      "cap": "21025",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "comune.comerio@libero.it",
      "pec": "comune.comerio@legalmail.it",
      "telefono": "+39 0332743156",
      "fax": "+39 0332744240",
      "coordinate": {
        "lat": 45.83993911743164,
        "lng": 8.746809959411621
      }
    },
    {
      "codice": "012056",
      "nome": "Cremenaga",
      "nomeStraniero": null,
      "codiceCatastale": "D144",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe.cremenaga@virgilio.it",
      "pec": "protocollo.comune.cremenaga@pec.regione.lombardia.it",
      "telefono": "+39 0332511880",
      "fax": "+39 0332579170",
      "coordinate": {
        "lat": 45.99027633666992,
        "lng": 8.804166793823242
      }
    },
    {
      "codice": "012057",
      "nome": "Crosio della Valle",
      "nomeStraniero": null,
      "codiceCatastale": "D185",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.crosiodellavalle.va.it",
      "pec": "comune.crosiodellavalle@legalmail.it",
      "telefono": "+39 0332949888",
      "fax": "+39 0332966201",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012058",
      "nome": "Cuasso al Monte",
      "nomeStraniero": null,
      "codiceCatastale": "D192",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria@comune.cuassoalmonte.va.it",
      "pec": "comune@pec.comune.cuassoalmonte.va.it",
      "telefono": "+39 0332939001",
      "fax": "+39 0332938180",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012059",
      "nome": "Cugliate-Fabiasco",
      "nomeStraniero": null,
      "codiceCatastale": "D199",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "luisi@comune.cugliatefabiasco.va.it",
      "pec": "comune.cugliatefabiasco@pec.regione.lombardia.it",
      "telefono": "+39 0332999720",
      "fax": "+39 0332999744",
      "coordinate": {
        "lat": 45.94770050048828,
        "lng": 8.821599960327148
      }
    },
    {
      "codice": "012060",
      "nome": "Cunardo",
      "nomeStraniero": null,
      "codiceCatastale": "D204",
      "cap": "21035",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.cunardo.va.it",
      "pec": "comune.cunardo.va@halleypec.it",
      "telefono": "+39 00332999210",
      "fax": "+39 0332715626",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012061",
      "nome": "Curiglia con Monteviasco",
      "nomeStraniero": null,
      "codiceCatastale": "D217",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.curigliaconmonteviasco.va.it",
      "pec": "comune.curigliaconmonteviasco@halleypec.it",
      "telefono": "+39 0332568427",
      "fax": "+39 0332568522",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012062",
      "nome": "Cuveglio",
      "nomeStraniero": null,
      "codiceCatastale": "D238",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.cuveglio.va.it",
      "pec": "comune.cuveglio.va@halleycert.it",
      "telefono": "+39 0332650107",
      "fax": "+39 0332650850",
      "coordinate": {
        "lat": 45.90589904785156,
        "lng": 8.734299659729004
      }
    },
    {
      "codice": "012063",
      "nome": "Cuvio",
      "nomeStraniero": null,
      "codiceCatastale": "D239",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.cuvio.va.it",
      "pec": "comune.cuvio.va@halleycert.it",
      "telefono": "+39 0332650143",
      "fax": null,
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "012064",
      "nome": "Daverio",
      "nomeStraniero": null,
      "codiceCatastale": "D256",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.daverio.va.it",
      "pec": "comunedaverio@legalmail.it",
      "telefono": "+39 0332947135",
      "fax": "+39 0332949697",
      "coordinate": {
        "lat": 45.778404235839844,
        "lng": 8.771827697753906
      }
    },
    {
      "codice": "012065",
      "nome": "Dumenza",
      "nomeStraniero": null,
      "codiceCatastale": "D384",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.dumenza.va.it",
      "pec": "comune.dumenza@legalmail.it",
      "telefono": "+39 0332517239",
      "fax": "+39 0332517050",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "012066",
      "nome": "Duno",
      "nomeStraniero": null,
      "codiceCatastale": "D385",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.duno.va.it",
      "pec": "comune.duno.va@halleycert.it",
      "telefono": "+39 0332629303",
      "fax": "+39 0332623259",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "012067",
      "nome": "Fagnano Olona",
      "nomeStraniero": null,
      "codiceCatastale": "D467",
      "cap": "21054",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "info.protocollo@fagnanoolona.org",
      "pec": "protocollo.fagnanoolona@legalmail.it",
      "telefono": "+39 0331616511",
      "fax": "+39 0331616559",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012068",
      "nome": "Ferno",
      "nomeStraniero": null,
      "codiceCatastale": "D543",
      "cap": "21010",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "comune@ferno.gov.it",
      "pec": "comune@ferno.legalmailpa.it",
      "telefono": "+39 0331242211",
      "fax": "+39 0331726110",
      "coordinate": {
        "lat": 45.620277404785156,
        "lng": 8.76361083984375
      }
    },
    {
      "codice": "012069",
      "nome": "Ferrera di Varese",
      "nomeStraniero": null,
      "codiceCatastale": "D551",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.ferreradivarese.va.it",
      "pec": "comune.ferrera.va@halleycert.it",
      "telefono": "+39 0332716450",
      "fax": "+39 0332716788",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "012070",
      "nome": "Gallarate",
      "nomeStraniero": null,
      "codiceCatastale": "D869",
      "cap": "21013",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "protocollo@comune.gallarate.it",
      "pec": "protocollo@pec.comune.gallarate.va.it",
      "telefono": "+39 0331754111",
      "fax": "+39 0331781869",
      "coordinate": {
        "lat": 45.66490173339844,
        "lng": 8.791399955749512
      }
    },
    {
      "codice": "012071",
      "nome": "Galliate Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "D871",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.galliatelombardo.va.it",
      "pec": "comune.galliatelombardo@legalmailpa.it",
      "telefono": "+39 0332947265",
      "fax": "+39 0332949607",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "012072",
      "nome": "Gavirate",
      "nomeStraniero": null,
      "codiceCatastale": "D946",
      "cap": "21026",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "servizialcittadino@comune.gavirate.va.it",
      "pec": "info.comune.gavirate@pec.regione.lombardia.it",
      "telefono": "+39 0332.748211",
      "fax": "+39 0332748.295",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "012073",
      "nome": "Gazzada Schianno",
      "nomeStraniero": null,
      "codiceCatastale": "D951",
      "cap": "21045",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "servizi.demografici@comune.gazzada-schianno.va.it",
      "pec": "comune.gazzadaschianno@secmail.it",
      "telefono": "+39 0332875160",
      "fax": "+39 0332875104",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "012074",
      "nome": "Gemonio",
      "nomeStraniero": null,
      "codiceCatastale": "D963",
      "cap": "21036",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.gemonio.va.it",
      "pec": "comune.gemonio.va@halleycert.it",
      "telefono": "+39 0332617065",
      "fax": "+39 0332604012",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "012075",
      "nome": "Gerenzano",
      "nomeStraniero": null,
      "codiceCatastale": "D981",
      "cap": "21040",
      "prefisso": "02",
      "provincia": "Varese",
      "email": "segreteria@comune.gerenzano.va.it",
      "pec": "comune.gerenzano@pec.regione.lombardia.it",
      "telefono": "+39 029639911",
      "fax": null,
      "coordinate": {
        "lat": 45.64055633544922,
        "lng": 9.001944541931152
      }
    },
    {
      "codice": "012076",
      "nome": "Germignaga",
      "nomeStraniero": null,
      "codiceCatastale": "D987",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.germignaga.va.it",
      "pec": "protocollo@cert.comune.germignaga.va.it",
      "telefono": "+39 0332531337",
      "fax": "+39 0332531703",
      "coordinate": {
        "lat": 45.99327850341797,
        "lng": 8.725790023803711
      }
    },
    {
      "codice": "012077",
      "nome": "Golasecca",
      "nomeStraniero": null,
      "codiceCatastale": "E079",
      "cap": "21010",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "info@comune.golasecca.va.it",
      "pec": "comune.golasecca@pec.regione.lombardia.it",
      "telefono": "+39 0331959414",
      "fax": "+39 0331959329",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.649999618530273
      }
    },
    {
      "codice": "012078",
      "nome": "Gorla Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "E101",
      "cap": "21050",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.gorlamaggiore.va.it",
      "pec": "protocollo.gorlamaggiore@legalmail.it",
      "telefono": "+39 0331617121",
      "fax": null,
      "coordinate": {
        "lat": 45.66621017456055,
        "lng": 8.904419898986816
      }
    },
    {
      "codice": "012079",
      "nome": "Gorla Minore",
      "nomeStraniero": null,
      "codiceCatastale": "E102",
      "cap": "21055",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.gorlaminore.va.it",
      "pec": "comune.gorlaminore@postecert.it",
      "telefono": "+39 0331607240",
      "fax": "+39 0331607255",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "012080",
      "nome": "Gornate Olona",
      "nomeStraniero": null,
      "codiceCatastale": "E104",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.gornateolona.va.it",
      "pec": "comune.gornate-olona@pec.regione.lombardia.it",
      "telefono": "+39 0331 863811",
      "fax": "+39 0331 863834",
      "coordinate": {
        "lat": 45.741580963134766,
        "lng": 8.859939575195312
      }
    },
    {
      "codice": "012081",
      "nome": "Grantola",
      "nomeStraniero": null,
      "codiceCatastale": "E144",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.grantola.va.it",
      "pec": "comune.grantola@pec.regione.lombardia.it",
      "telefono": "+39 0332575134",
      "fax": "+39 0332576076",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "012082",
      "nome": "Inarzo",
      "nomeStraniero": null,
      "codiceCatastale": "E292",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria@comune.inarzo.va.it",
      "pec": "comune.inarzo@pec.regione.lombardia.it",
      "telefono": "+39 0332947322",
      "fax": "+39 0332969623",
      "coordinate": {
        "lat": 45.78555679321289,
        "lng": 8.736666679382324
      }
    },
    {
      "codice": "012083",
      "nome": "Induno Olona",
      "nomeStraniero": null,
      "codiceCatastale": "E299",
      "cap": "21056",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.induno-olona.va.it",
      "pec": "comune.induno-olona@postecert.it",
      "telefono": "+39 0332273211",
      "fax": "+39 0332202319",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012084",
      "nome": "Ispra",
      "nomeStraniero": null,
      "codiceCatastale": "E367",
      "cap": "21027",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.ispra.va.it",
      "pec": "protocollo.ispra@pec.it",
      "telefono": "+39 03327833450",
      "fax": "+39 0332781213",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.616666793823242
      }
    },
    {
      "codice": "012085",
      "nome": "Jerago con Orago",
      "nomeStraniero": null,
      "codiceCatastale": "E386",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demosocioculturale@comune.jeragoconorago.va.it",
      "pec": "comune.jeragoconorago@pec.regione.lombardia.it",
      "telefono": "+39 0331217770",
      "fax": "+39 0331735952",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "012086",
      "nome": "Lavena Ponte Tresa",
      "nomeStraniero": null,
      "codiceCatastale": "E494",
      "cap": "21037",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.lavenapontetresa.va.it",
      "pec": "comune.lavenapontetresa@legalmail.it",
      "telefono": "+39 0332 524108",
      "fax": "+39 0332 551754",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 8.850000381469727
      }
    },
    {
      "codice": "012087",
      "nome": "Laveno-Mombello",
      "nomeStraniero": null,
      "codiceCatastale": "E496",
      "cap": "21014",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "comunelaveno@libero.it",
      "pec": "protocollo.lavenomombello@cert.saga.it",
      "telefono": "+39 0332625547",
      "fax": "+39 0332626042",
      "coordinate": {
        "lat": 45.908878326416016,
        "lng": 8.620360374450684
      }
    },
    {
      "codice": "012088",
      "nome": "Leggiuno",
      "nomeStraniero": null,
      "codiceCatastale": "E510",
      "cap": "21038",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.leggiuno.va.it",
      "pec": "comune.leggiuno.va@halleycert.it",
      "telefono": "+39 0332647110",
      "fax": "+39 0332647262",
      "coordinate": {
        "lat": 45.875,
        "lng": 8.621700286865234
      }
    },
    {
      "codice": "012089",
      "nome": "Lonate Ceppino",
      "nomeStraniero": null,
      "codiceCatastale": "E665",
      "cap": "21050",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@lonateceppino.com",
      "pec": "comune.lonateceppino@pec.regione.lombardia.it",
      "telefono": "+39 0331841288",
      "fax": "+39 0331843280",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012090",
      "nome": "Lonate Pozzolo",
      "nomeStraniero": null,
      "codiceCatastale": "E666",
      "cap": "21015",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "servizi.demografici@lonatepozzolo.gov.it",
      "pec": "comune@lonatepozzolo.legalmailpa.it",
      "telefono": "+39 0331303511",
      "fax": "+39 0331301296",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.75
      }
    },
    {
      "codice": "012091",
      "nome": "Lozza",
      "nomeStraniero": null,
      "codiceCatastale": "E707",
      "cap": "21040",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.lozza.va.it",
      "pec": "comune.lozza@pec.regione.lombardia.it",
      "telefono": "+39 0332263028",
      "fax": "+39 0332812087",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.850000381469727
      }
    },
    {
      "codice": "012092",
      "nome": "Luino",
      "nomeStraniero": null,
      "codiceCatastale": "E734",
      "cap": "21016",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.luino.va.it",
      "pec": "comune.luino@legalmail.it",
      "telefono": "+39 0332543511",
      "fax": "+39 0332543521",
      "coordinate": {
        "lat": 46,
        "lng": 8.75
      }
    },
    {
      "codice": "012093",
      "nome": "Luvinate",
      "nomeStraniero": null,
      "codiceCatastale": "E769",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografico@comune.luvinate.va.it",
      "pec": "protocollo@pec.comune.luvinate.va.it",
      "telefono": "+39 0332824080",
      "fax": "+39 0332824061",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "012142",
      "nome": "Maccagno con Pino e Veddasca",
      "nomeStraniero": null,
      "codiceCatastale": "M339",
      "cap": "21061",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.maccagnoconpinoeveddasca.va.it",
      "pec": "comune.maccagnoconpinoeveddasca@pec.regione.lombardia.it",
      "telefono": "+39 0332 562011 / 12",
      "fax": "+39 0332 562542",
      "coordinate": {
        "lat": 46.06822967529297,
        "lng": 8.776016235351562
      }
    },
    {
      "codice": "012095",
      "nome": "Malgesso",
      "nomeStraniero": null,
      "codiceCatastale": "E856",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografici@comune.malgesso.va.it",
      "pec": "protocollo.malgesso@legalpec.it",
      "telefono": "+39 0332706611",
      "fax": "+39 0332706749",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "012096",
      "nome": "Malnate",
      "nomeStraniero": null,
      "codiceCatastale": "E863",
      "cap": "21046",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.malnate.va.it",
      "pec": "comune.malnate@legalmail.it",
      "telefono": "+39 0332275111",
      "fax": "+39 0332429035",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "012097",
      "nome": "Marchirolo",
      "nomeStraniero": null,
      "codiceCatastale": "E929",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "ufficio_anagrafe@comune.marchirolo.varese.it",
      "pec": "protocollo.comune.marchirolo@pec.regione.lombardia.it",
      "telefono": "+39 0332997130",
      "fax": "+39 0332997100",
      "coordinate": {
        "lat": 45.95009994506836,
        "lng": 8.832200050354004
      }
    },
    {
      "codice": "012098",
      "nome": "Marnate",
      "nomeStraniero": null,
      "codiceCatastale": "E965",
      "cap": "21050",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "protocollo@comune.marnate.va.it",
      "pec": "protocollo@comunemarnate.legalmailpa.it",
      "telefono": "+39 0331368200",
      "fax": "+39 0331368240",
      "coordinate": {
        "lat": 45.62888717651367,
        "lng": 8.898056030273438
      }
    },
    {
      "codice": "012099",
      "nome": "Marzio",
      "nomeStraniero": null,
      "codiceCatastale": "F002",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comunemarzio.it",
      "pec": "comune.marzio@pec.regione.lombardia.it",
      "telefono": "+39 0332727851",
      "fax": "+39 0332727937",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "012100",
      "nome": "Masciago Primo",
      "nomeStraniero": null,
      "codiceCatastale": "F007",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.masciagoprimo.va.it",
      "pec": "comune.masciagoprimo@halleypec.it",
      "telefono": "+39 0332724597",
      "fax": "+39 0332724871",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "012101",
      "nome": "Mercallo",
      "nomeStraniero": null,
      "codiceCatastale": "F134",
      "cap": "21020",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "segreteria@comune.mercallo.va.it",
      "pec": "comune.mercallo.va@halleypec.it",
      "telefono": "+39 0331968104",
      "fax": null,
      "coordinate": {
        "lat": 45.75,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "012102",
      "nome": "Mesenzana",
      "nomeStraniero": null,
      "codiceCatastale": "F154",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@mesenzana.com",
      "pec": "comune.mesenzana.va@legalmail.it",
      "telefono": "+39 0332575116",
      "fax": "+39 0332576524",
      "coordinate": {
        "lat": 45.946998596191406,
        "lng": 8.758899688720703
      }
    },
    {
      "codice": "012103",
      "nome": "Montegrino Valtravaglia",
      "nomeStraniero": null,
      "codiceCatastale": "F526",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.montegrino-valtravaglia.va.it",
      "pec": "comune.montegrino@egalmail.it",
      "telefono": "+39 0332589732",
      "fax": "+39 0332589860",
      "coordinate": {
        "lat": 45.97472381591797,
        "lng": 8.768333435058594
      }
    },
    {
      "codice": "012104",
      "nome": "Monvalle",
      "nomeStraniero": null,
      "codiceCatastale": "F703",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "protocollo@comune.monvalle.va.it",
      "pec": "comune.monvalle.va@legalmail.it",
      "telefono": "+39 0332799507",
      "fax": null,
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "012105",
      "nome": "Morazzone",
      "nomeStraniero": null,
      "codiceCatastale": "F711",
      "cap": "21040",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "affarigeneralifinanziario@comune.morazzone.va.it",
      "pec": "affarigenerali@comune.morazzone.legalmailpa.it",
      "telefono": "+39 0332872611",
      "fax": "+39 0332872637",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012106",
      "nome": "Mornago",
      "nomeStraniero": null,
      "codiceCatastale": "F736",
      "cap": "21020",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "segreteria@comune.mornago.va.it",
      "pec": "comune.mornago.va@halleycert.it",
      "telefono": "+39 0331901008",
      "fax": "+39 0331903125",
      "coordinate": {
        "lat": 45.75,
        "lng": 8.75
      }
    },
    {
      "codice": "012107",
      "nome": "Oggiona con Santo Stefano",
      "nomeStraniero": null,
      "codiceCatastale": "G008",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "info@comune.oggionaconsantostefano.va.it",
      "pec": "protocollo.comune.oggionasstefano@actaliscertymail.it",
      "telefono": "+39 0331214900",
      "fax": "+39 0331216460",
      "coordinate": {
        "lat": 45.70426940917969,
        "lng": 8.816530227661133
      }
    },
    {
      "codice": "012108",
      "nome": "Olgiate Olona",
      "nomeStraniero": null,
      "codiceCatastale": "G028",
      "cap": "21057",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "info@comuneolgiateolona.it",
      "pec": "comune.olgiateolona@pec.regione.lombardia.it",
      "telefono": "+39 0331608711",
      "fax": "+39 0331641433",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "012109",
      "nome": "Origgio",
      "nomeStraniero": null,
      "codiceCatastale": "G103",
      "cap": "21040",
      "prefisso": "02",
      "provincia": "Varese",
      "email": "segreteria@comune.origgio.va.it",
      "pec": "segreteria@pec.comune.origgio.va.it",
      "telefono": "+39 029695111",
      "fax": "+39 0296951150",
      "coordinate": {
        "lat": 45.59694290161133,
        "lng": 9.018333435058594
      }
    },
    {
      "codice": "012110",
      "nome": "Orino",
      "nomeStraniero": null,
      "codiceCatastale": "G105",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "comune@orino.info",
      "pec": "comune.orino.va@halleypec.it",
      "telefono": "+39 0332630517",
      "fax": "+39 0332630508",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "012113",
      "nome": "Porto Ceresio",
      "nomeStraniero": null,
      "codiceCatastale": "G906",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.portoceresio.va.it",
      "pec": "segreteria@pec.comune.portoceresio.va.it",
      "telefono": "+39 0332917150",
      "fax": "+39 0332920000",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "012114",
      "nome": "Porto Valtravaglia",
      "nomeStraniero": null,
      "codiceCatastale": "G907",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "schiumacher@comune.portovaltravaglia.va.it",
      "pec": "comune.portovaltravaglia@pec.regione.lombardia.it",
      "telefono": "+39 0332543821/543811",
      "fax": "+39 0332543801",
      "coordinate": {
        "lat": 45.961299896240234,
        "lng": 8.681500434875488
      }
    },
    {
      "codice": "012115",
      "nome": "Rancio Valcuvia",
      "nomeStraniero": null,
      "codiceCatastale": "H173",
      "cap": "21030",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.ranciovalcuvia.va.it",
      "pec": "comune.ranciovalcuvia.va@halleycert.it",
      "telefono": "+39 0332995806",
      "fax": "+39 0332995864",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "012116",
      "nome": "Ranco",
      "nomeStraniero": null,
      "codiceCatastale": "H174",
      "cap": "21020",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demografici@comune.ranco.va.it",
      "pec": "comune.ranco.va@halleycert.it",
      "telefono": "+39 0331976619",
      "fax": "+39 0331975111",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "012117",
      "nome": "Saltrio",
      "nomeStraniero": null,
      "codiceCatastale": "H723",
      "cap": "21050",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografico@comune.saltrio.va.it",
      "pec": "comune.saltrio.va@legalmail.it",
      "telefono": "+39 0332486166",
      "fax": "+39 0332486178",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "012118",
      "nome": "Samarate",
      "nomeStraniero": null,
      "codiceCatastale": "H736",
      "cap": "21017",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "gabriella.scampini@samarate.net",
      "pec": "comune.samarate@legalmail.it",
      "telefono": "+39 0331220260",
      "fax": "+39 0331224240",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "012141",
      "nome": "Sangiano",
      "nomeStraniero": null,
      "codiceCatastale": "H872",
      "cap": "21038",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.sangiano.va.it",
      "pec": "comune.sangiano@pec.regione.lombardia.it",
      "telefono": "+39 0332647210",
      "fax": "+39 0332648613",
      "coordinate": {
        "lat": 45.87480163574219,
        "lng": 8.633399963378906
      }
    },
    {
      "codice": "012119",
      "nome": "Saronno",
      "nomeStraniero": null,
      "codiceCatastale": "I441",
      "cap": "21047",
      "prefisso": "02",
      "provincia": "Varese",
      "email": "protocollo@comune.saronno.va.it",
      "pec": "comunesaronno@secmail.it",
      "telefono": "+39 02967101",
      "fax": "+39 0296701389",
      "coordinate": {
        "lat": 45.6254997253418,
        "lng": 9.036999702453613
      }
    },
    {
      "codice": "012120",
      "nome": "Sesto Calende",
      "nomeStraniero": null,
      "codiceCatastale": "I688",
      "cap": "21018",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "segreteria@comune.sesto-calende.va.it",
      "pec": "sestocalende@legalmail.it",
      "telefono": "+39 0331928411",
      "fax": "+39 0331922486",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "012121",
      "nome": "Solbiate Arno",
      "nomeStraniero": null,
      "codiceCatastale": "I793",
      "cap": "21048",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "comune.solbiatearno@tin.it",
      "pec": "comune.solbiate-arno@pec.regione.lombardia.it",
      "telefono": "+39 0331993043",
      "fax": "+39 0331994597",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "012122",
      "nome": "Solbiate Olona",
      "nomeStraniero": null,
      "codiceCatastale": "I794",
      "cap": "21058",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.solbiateolona.va.it",
      "pec": "protocollo@comune.solbiateolona.va.legalmail.it",
      "telefono": "+39 0331649516",
      "fax": "+39 0331641930",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "012123",
      "nome": "Somma Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "I819",
      "cap": "21019",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "segreteria@comune.sommalombardo.va.it",
      "pec": "comunedisommalombardo@legalmail.it",
      "telefono": "+39 0331989011",
      "fax": "+39 0331251334",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012124",
      "nome": "Sumirago",
      "nomeStraniero": null,
      "codiceCatastale": "L003",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "settoreservizidemografici@comune.sumirago.va.it",
      "pec": "comune.sumirago@pec.intercom.it",
      "telefono": "+39 03311731523",
      "fax": "+39 0331909520",
      "coordinate": {
        "lat": 45.73929977416992,
        "lng": 8.780799865722656
      }
    },
    {
      "codice": "012125",
      "nome": "Taino",
      "nomeStraniero": null,
      "codiceCatastale": "L032",
      "cap": "21020",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "servizidemografici@comune.taino.va.it",
      "pec": "comune.taino@pec.it",
      "telefono": "+39 0331956405",
      "fax": "+39 0331957550",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.616666793823242
      }
    },
    {
      "codice": "012126",
      "nome": "Ternate",
      "nomeStraniero": null,
      "codiceCatastale": "L115",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "info@comune.ternate.va.it",
      "pec": "comune.ternate@legalmail.it",
      "telefono": "+39 0332942911 - 0332912909",
      "fax": null,
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012127",
      "nome": "Tradate",
      "nomeStraniero": null,
      "codiceCatastale": "L319",
      "cap": "21049",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "protocollo@comune.tradate.va.it",
      "pec": "comune.tradate@pec.regione.lombardia.it",
      "telefono": "+39 0331 826811",
      "fax": "+39 0331 826884",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "012128",
      "nome": "Travedona-Monate",
      "nomeStraniero": null,
      "codiceCatastale": "L342",
      "cap": "21028",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria@comune.travedonamonate.va.it",
      "pec": "comune.travedonamonate.va@halleycert.it",
      "telefono": "+39 0332787611",
      "fax": null,
      "coordinate": {
        "lat": 45.80390167236328,
        "lng": 8.672499656677246
      }
    },
    {
      "codice": "012129",
      "nome": "Tronzano Lago Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "A705",
      "cap": "21010",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "municipio@comune.tronzanolagomaggiore.va.it",
      "pec": "comune.tronzanolagomaggiore@pec.it",
      "telefono": "+39 0332566240",
      "fax": "+39 0332566117",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "012130",
      "nome": "Uboldo",
      "nomeStraniero": null,
      "codiceCatastale": "L480",
      "cap": "21040",
      "prefisso": "02",
      "provincia": "Varese",
      "email": "anagrafe@comune.uboldo.va.it",
      "pec": "protocollo@pec.comune.uboldo.va.it",
      "telefono": "+39 02/96992233",
      "fax": "+39 02/96992230",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9
      }
    },
    {
      "codice": "012131",
      "nome": "Valganna",
      "nomeStraniero": null,
      "codiceCatastale": "L577",
      "cap": "21039",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe.valganna@libero.it",
      "pec": "comune.valganna@pec.regione.lombardia.it",
      "telefono": "+39 0332719755",
      "fax": "+39 0332719680",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012132",
      "nome": "Varano Borghi",
      "nomeStraniero": null,
      "codiceCatastale": "L671",
      "cap": "21020",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "demografico2@comune.varano-borghi.va.it",
      "pec": "protocollo@pec.comune.varano-borghi.va.it",
      "telefono": "+39 0332960119",
      "fax": "+39 0332961749",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012133",
      "nome": "Varese",
      "nomeStraniero": null,
      "codiceCatastale": "L682",
      "cap": "21100",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "segreteria.generale@comune.varese.it",
      "pec": "protocollo@comune.varese.legalmail.it",
      "telefono": "+39 0332255111",
      "fax": "+39 0332255390",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "012134",
      "nome": "Vedano Olona",
      "nomeStraniero": null,
      "codiceCatastale": "L703",
      "cap": "21040",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.vedano-olona.va.it",
      "pec": "comune.vedano-olona@legalmail.it",
      "telefono": "+39 0332867770",
      "fax": "+39 0332402268",
      "coordinate": {
        "lat": 45.77690124511719,
        "lng": 8.886899948120117
      }
    },
    {
      "codice": "012136",
      "nome": "Venegono Inferiore",
      "nomeStraniero": null,
      "codiceCatastale": "L733",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "segreteria@comunevenegonoinferiore.it",
      "pec": "comune.venegonoinferiore@pec.regione.lombardia.it",
      "telefono": "+39 0331 856011",
      "fax": "+39 0331 827783",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "012137",
      "nome": "Venegono Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "L734",
      "cap": "21040",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "anagrafe@comune.venegonosuperiore.va.it",
      "pec": "comune.venegonosuperiore@pec.regione.lombardia.it",
      "telefono": "+39 0331828400",
      "fax": "+39 0331827314",
      "coordinate": {
        "lat": 45.75,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "012138",
      "nome": "Vergiate",
      "nomeStraniero": null,
      "codiceCatastale": "L765",
      "cap": "21029",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "comune_vergiate@logic.it",
      "pec": "comunevergiate@legalmail.it",
      "telefono": "+39 0331928711",
      "fax": "+39 0331947466",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "012139",
      "nome": "Viggiù",
      "nomeStraniero": null,
      "codiceCatastale": "L876",
      "cap": "21059",
      "prefisso": "0332",
      "provincia": "Varese",
      "email": "anagrafe@comune.viggiu.va.it",
      "pec": "protocollo@pec.comune.viggiu.va.it",
      "telefono": "+39 0332486106",
      "fax": "+39 0332488861",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.899999618530273
      }
    },
    {
      "codice": "012140",
      "nome": "Vizzola Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "M101",
      "cap": "21010",
      "prefisso": "0331",
      "provincia": "Varese",
      "email": "demografici@comune.vizzolaticino.va.it",
      "pec": "comune.vizzolaticino@halleypec.it",
      "telefono": "+39 0331230819",
      "fax": "+39 0331230996",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.699999809265137
      }
    }
  ],
  "Monza e della Brianza": [
    {
      "codice": "108001",
      "nome": "Agrate Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "A087",
      "cap": "20864",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "info@comune.agratebrianza.mb.it",
      "pec": "comune.agratebrianza@pec.regione.lombardia.it",
      "telefono": "+39 60511",
      "fax": "+39 039.6051254",
      "coordinate": {
        "lat": 45.57833480834961,
        "lng": 9.352222442626953
      }
    },
    {
      "codice": "108002",
      "nome": "Aicurzio",
      "nomeStraniero": null,
      "codiceCatastale": "A096",
      "cap": "20886",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "anagrafe@comune.aicurzio.mb.it",
      "pec": "comune.aicurzio@pec.regione.lombardia.it",
      "telefono": "+39 0396900067",
      "fax": "+39 0396093045",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "108003",
      "nome": "Albiate",
      "nomeStraniero": null,
      "codiceCatastale": "A159",
      "cap": "20847",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "anagrafe@comune.albiate.mb.it",
      "pec": "comune.albiate@legalmail.it",
      "telefono": "+39 0362934030",
      "fax": "+39 0362932306",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.25
      }
    },
    {
      "codice": "108004",
      "nome": "Arcore",
      "nomeStraniero": null,
      "codiceCatastale": "A376",
      "cap": "20862",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "urp@comune.arcore.mb.it",
      "pec": "comune.arcore@pec.regione.lombardia.it",
      "telefono": "+39 60171",
      "fax": "+39 0396017346",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "108005",
      "nome": "Barlassina",
      "nomeStraniero": null,
      "codiceCatastale": "A668",
      "cap": "20825",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "posta@comunebarlassina.it",
      "pec": "comune.barlassina@pec.regione.lombardia.it",
      "telefono": "+39 0362 57701",
      "fax": "+39 0362 561570",
      "coordinate": {
        "lat": 45.656612396240234,
        "lng": 9.130000114440918
      }
    },
    {
      "codice": "108006",
      "nome": "Bellusco",
      "nomeStraniero": null,
      "codiceCatastale": "A759",
      "cap": "20882",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "affarigenerali@comune.bellusco.mb.it",
      "pec": "comune.bellusco@pec.regione.lombardia.it",
      "telefono": "+39 03962083201",
      "fax": "+39 0396020148",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "108007",
      "nome": "Bernareggio",
      "nomeStraniero": null,
      "codiceCatastale": "A802",
      "cap": "20881",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.bernareggio.mb.it",
      "pec": "protocollo@comunebernareggio.org",
      "telefono": "+39 9452100",
      "fax": "+39 03962762311",
      "coordinate": {
        "lat": 45.64500045776367,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "108008",
      "nome": "Besana in Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "A818",
      "cap": "20842",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "servizidemografici@comune.besanainbrianza.mb.it",
      "pec": "protocollo@pec.comune.besanainbrianza.mb.it",
      "telefono": "+39 0362922021",
      "fax": "+39 0362922097",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "108009",
      "nome": "Biassono",
      "nomeStraniero": null,
      "codiceCatastale": "A849",
      "cap": "20853",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "vicesegretario@biassono.org",
      "pec": "biassono@pec.it",
      "telefono": "+39 039/220101",
      "fax": "+39 039/2201076",
      "coordinate": {
        "lat": 45.63055419921875,
        "lng": 9.274444580078125
      }
    },
    {
      "codice": "108010",
      "nome": "Bovisio-Masciago",
      "nomeStraniero": null,
      "codiceCatastale": "B105",
      "cap": "20813",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "info@comune.bovisiomasciago.mb.it",
      "pec": "comunebovisiomasciago@cert.legalmail.it",
      "telefono": "+39 0362 5111",
      "fax": "+39 0362 558720",
      "coordinate": {
        "lat": 45.611000061035156,
        "lng": 9.147749900817871
      }
    },
    {
      "codice": "108011",
      "nome": "Briosco",
      "nomeStraniero": null,
      "codiceCatastale": "B187",
      "cap": "20836",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.briosco.mi.it",
      "pec": "comune.briosco@halleycert.it",
      "telefono": "+39 0362 95002",
      "fax": "+39 0362 958620",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "108012",
      "nome": "Brugherio",
      "nomeStraniero": null,
      "codiceCatastale": "B212",
      "cap": "20861",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.brugherio.mb.it",
      "pec": "protocollo.brugherio@legalmail.it",
      "telefono": "+39 28931",
      "fax": "+39 0392871989",
      "coordinate": {
        "lat": 45.55083465576172,
        "lng": 9.301111221313477
      }
    },
    {
      "codice": "108013",
      "nome": "Burago di Molgora",
      "nomeStraniero": null,
      "codiceCatastale": "B272",
      "cap": "20875",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@buragodimolgora.gov.it",
      "pec": "comune.buragodimolgora.mb@legalmail.it",
      "telefono": "+39 699031",
      "fax": "+39 039 6080329",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "108051",
      "nome": "Busnago",
      "nomeStraniero": null,
      "codiceCatastale": "B289",
      "cap": "20874",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "anagrafe@comune.busnago.mb.it",
      "pec": "protocollo.busnago@cert.saga.it",
      "telefono": "+39 039 682501",
      "fax": "+39 039 6095041",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "108014",
      "nome": "Camparada",
      "nomeStraniero": null,
      "codiceCatastale": "B501",
      "cap": "20857",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "anagrafe@comunecamparada.it",
      "pec": "comune.camparada@pec.regione.lombardia.it",
      "telefono": "+39 698541",
      "fax": "+39 6064534",
      "coordinate": {
        "lat": 45.655975341796875,
        "lng": 9.319645881652832
      }
    },
    {
      "codice": "108052",
      "nome": "Caponago",
      "nomeStraniero": null,
      "codiceCatastale": "B671",
      "cap": "20867",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "servizidemografici@comune.caponago.mb.it",
      "pec": "comune.caponago@legalmail.it",
      "telefono": "+39 02 9596981",
      "fax": null,
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "108015",
      "nome": "Carate Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "B729",
      "cap": "20841",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.caratebrianza.mb.it",
      "pec": "segreteria.comune.caratebrianza@pec.regione.lombardia.it",
      "telefono": "+39 0362 9871",
      "fax": "+39 0362/987205",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "108016",
      "nome": "Carnate",
      "nomeStraniero": null,
      "codiceCatastale": "B798",
      "cap": "20866",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "anagrafe@comune.carnate.mb.it",
      "pec": "carnate@legalmail.it",
      "telefono": "+39 6288224",
      "fax": "+39 039670035",
      "coordinate": {
        "lat": 45.65065002441406,
        "lng": 9.378463745117188
      }
    },
    {
      "codice": "108017",
      "nome": "Cavenago di Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "C395",
      "cap": "20873",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.cavenagobrianza.mb.it",
      "pec": "protocollo@pec.comune.cavenagobrianza.mb.it",
      "telefono": "+39 02 9524141",
      "fax": "+39 02 95241455",
      "coordinate": {
        "lat": 45.584720611572266,
        "lng": 9.415833473205566
      }
    },
    {
      "codice": "108018",
      "nome": "Ceriano Laghetto",
      "nomeStraniero": null,
      "codiceCatastale": "C512",
      "cap": "20816",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "urp@ceriano-laghetto.org",
      "pec": "comune.ceriano-laghetto@legalmail.it",
      "telefono": "+39 029666131",
      "fax": "+39 0296460215",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "108019",
      "nome": "Cesano Maderno",
      "nomeStraniero": null,
      "codiceCatastale": "C566",
      "cap": "20811",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.cesano-maderno.mb.it",
      "pec": "protocollo@pec.comune.cesano-maderno.mb.it",
      "telefono": "+39 03625131",
      "fax": "+39 0362500066",
      "coordinate": {
        "lat": 45.63069534301758,
        "lng": 9.145638465881348
      }
    },
    {
      "codice": "108020",
      "nome": "Cogliate",
      "nomeStraniero": null,
      "codiceCatastale": "C820",
      "cap": "20815",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.cogliate.mb.it",
      "pec": "comune.cogliate@legalmail.it",
      "telefono": "+39 02 96666601",
      "fax": "+39 02 96460765",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "108021",
      "nome": "Concorezzo",
      "nomeStraniero": null,
      "codiceCatastale": "C952",
      "cap": "20863",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.concorezzo.mb.it",
      "pec": "protocollo@comune.concorezzo.mb.legalmail.it",
      "telefono": "+39 628001",
      "fax": "+39 0396040833",
      "coordinate": {
        "lat": 45.58967590332031,
        "lng": 9.33594799041748
      }
    },
    {
      "codice": "108053",
      "nome": "Cornate d'Adda",
      "nomeStraniero": null,
      "codiceCatastale": "D019",
      "cap": "20872",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "urp@comune.cornatedadda.mb.it",
      "pec": "comune.cornatedadda@cert.legalmail.it",
      "telefono": "+39 039 68741",
      "fax": "+39 039 6926119",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "108022",
      "nome": "Correzzana",
      "nomeStraniero": null,
      "codiceCatastale": "D038",
      "cap": "20856",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "anagrafe@comune.correzzana.mb.it",
      "pec": "comune.correzzana@pec.regione.lombardia.it",
      "telefono": "+39 6065373",
      "fax": "+39 039.6981018",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "108023",
      "nome": "Desio",
      "nomeStraniero": null,
      "codiceCatastale": "D286",
      "cap": "20832",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "segereteria@comune.desio.mb.it",
      "pec": "protocollo.comune.desio@legalmail.it",
      "telefono": "+39 03623921",
      "fax": "+39 0362392211",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "108024",
      "nome": "Giussano",
      "nomeStraniero": null,
      "codiceCatastale": "E063",
      "cap": "20833",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.giussano.mb.it",
      "pec": "protocollo@pec.comune.giussano.mb.it",
      "telefono": "+39 0362 3581",
      "fax": "+39 0362/358253",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "108025",
      "nome": "Lazzate",
      "nomeStraniero": null,
      "codiceCatastale": "E504",
      "cap": "20824",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@lazzate.com",
      "pec": "segreteria.comune.lazzate@pec.regione.lombardia.it",
      "telefono": "+39 0296720202",
      "fax": "+39 029672617",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "108054",
      "nome": "Lentate sul Seveso",
      "nomeStraniero": null,
      "codiceCatastale": "E530",
      "cap": "20823",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.lentatesulseveso.mb.it",
      "pec": "comune.lentatesulseveso@legalmail.it",
      "telefono": "+39 0362 5151",
      "fax": "+39 0362 557420",
      "coordinate": {
        "lat": 45.678443908691406,
        "lng": 9.121889114379883
      }
    },
    {
      "codice": "108026",
      "nome": "Lesmo",
      "nomeStraniero": null,
      "codiceCatastale": "E550",
      "cap": "20855",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria.lesmo@comune.lesmo.mb.it",
      "pec": "comune.lesmo@legalmail.it",
      "telefono": "+39 6985501",
      "fax": "+39 039/6981008",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "108027",
      "nome": "Limbiate",
      "nomeStraniero": null,
      "codiceCatastale": "E591",
      "cap": "20812",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "segreteria.istituzionale@comune.limbiate.mb.it",
      "pec": "comune.limbiate@pec.regione.lombardia.it",
      "telefono": "+39 02-990971",
      "fax": "+39 02-99097281",
      "coordinate": {
        "lat": 45.59722137451172,
        "lng": 9.130000114440918
      }
    },
    {
      "codice": "108028",
      "nome": "Lissone",
      "nomeStraniero": null,
      "codiceCatastale": "E617",
      "cap": "20851",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.lissone.mb.it",
      "pec": "pec@comunedilissone.it",
      "telefono": "+39 73971",
      "fax": "+39 039 7397274",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.25
      }
    },
    {
      "codice": "108029",
      "nome": "Macherio",
      "nomeStraniero": null,
      "codiceCatastale": "E786",
      "cap": "20846",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.macherio.mb.it",
      "pec": "protocollo@pec.comune.macherio.mb.it",
      "telefono": "+39 207561",
      "fax": "+39 03920756230",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "108030",
      "nome": "Meda",
      "nomeStraniero": null,
      "codiceCatastale": "F078",
      "cap": "20821",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "posta@comune.meda.mi.it",
      "pec": "posta@cert.comune.meda.mi.it",
      "telefono": "+39 0362 3961",
      "fax": "+39 0362 75252",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "108031",
      "nome": "Mezzago",
      "nomeStraniero": null,
      "codiceCatastale": "F165",
      "cap": "20883",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.mezzago.mb.it",
      "pec": "comune.mezzago@pec.regione.lombardia.it",
      "telefono": "+39 606761",
      "fax": "+39 039 6020383",
      "coordinate": {
        "lat": 45.62861251831055,
        "lng": 9.444167137145996
      }
    },
    {
      "codice": "108032",
      "nome": "Misinto",
      "nomeStraniero": null,
      "codiceCatastale": "F247",
      "cap": "20826",
      "prefisso": "02",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.misinto.mb.it",
      "pec": "comune.misinto@pec.regione.lombardia.it",
      "telefono": "+39 02.96721010",
      "fax": "+39 02.96328437",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "108033",
      "nome": "Monza",
      "nomeStraniero": null,
      "codiceCatastale": "F704",
      "cap": "20900",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "elettorale@comune.monza.it",
      "pec": "elettorale@pec.comune.monza.it",
      "telefono": "+39 039.2372.1",
      "fax": "+39 039.2372.558",
      "coordinate": {
        "lat": 45.58361053466797,
        "lng": 9.273611068725586
      }
    },
    {
      "codice": "108034",
      "nome": "Muggiò",
      "nomeStraniero": null,
      "codiceCatastale": "F797",
      "cap": "20835",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "demografici@comune.muggio.mb.it",
      "pec": "comune.muggio@pec.regione.lombardia.it",
      "telefono": "+39 039 27091",
      "fax": "+39 039 792985",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "108035",
      "nome": "Nova Milanese",
      "nomeStraniero": null,
      "codiceCatastale": "F944",
      "cap": "20834",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "comune@novamilanese.it",
      "pec": "comune.novamilanese@pec.regione.lombardia.it",
      "telefono": "+39 0362 374252",
      "fax": "+39 0362 41775",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "108036",
      "nome": "Ornago",
      "nomeStraniero": null,
      "codiceCatastale": "G116",
      "cap": "20876",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "s.brivio@comune.ornago.mb.it",
      "pec": "protocollo.comuneornago@postecert.it",
      "telefono": "+39 628631",
      "fax": "+39 0396011094",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "108037",
      "nome": "Renate",
      "nomeStraniero": null,
      "codiceCatastale": "H233",
      "cap": "20838",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "sindaco@comune.renate.mb.it",
      "pec": "comune.renate@legalmail.it",
      "telefono": "+39 0362 924423",
      "fax": null,
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "108055",
      "nome": "Roncello",
      "nomeStraniero": null,
      "codiceCatastale": "H529",
      "cap": "20877",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.roncello.mb.it",
      "pec": "comune.roncello@pec.regione.lombardia.it",
      "telefono": "+39 039 62788201",
      "fax": "+39 039.62788232",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "108038",
      "nome": "Ronco Briantino",
      "nomeStraniero": null,
      "codiceCatastale": "H537",
      "cap": "20885",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "municipio@comune.roncobriantino.mb.it",
      "pec": "comune.roncobriantino@legalmail.it",
      "telefono": "+39 6079023",
      "fax": "+39 0396079304",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "108039",
      "nome": "Seregno",
      "nomeStraniero": null,
      "codiceCatastale": "I625",
      "cap": "20831",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "seregno.protocollo@actaliscertymail.it",
      "pec": "seregno.protocollo@actaliscertymail.it",
      "telefono": "+39 03622631",
      "fax": "+39 0362263245",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "108040",
      "nome": "Seveso",
      "nomeStraniero": null,
      "codiceCatastale": "I709",
      "cap": "20822",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.seveso.mb.it",
      "pec": "comune.seveso@pec.it",
      "telefono": "+39 0362-5171",
      "fax": "+39 0362-509033",
      "coordinate": {
        "lat": 45.64341735839844,
        "lng": 9.137389183044434
      }
    },
    {
      "codice": "108041",
      "nome": "Sovico",
      "nomeStraniero": null,
      "codiceCatastale": "I878",
      "cap": "20845",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "demografici@comune.sovico.mb.it",
      "pec": "comune.sovico@pec.regione.lombardia.it",
      "telefono": "+39 2075021",
      "fax": "+39 0392075045",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "108042",
      "nome": "Sulbiate",
      "nomeStraniero": null,
      "codiceCatastale": "I998",
      "cap": "20884",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "segreteria@comune.sulbiate.mb.it",
      "pec": "comune.sulbiate@halleypec.it",
      "telefono": "+39 6020975",
      "fax": "+39 039.6020537",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "108043",
      "nome": "Triuggio",
      "nomeStraniero": null,
      "codiceCatastale": "L434",
      "cap": "20844",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "settoreamministrativo@comune.triuggio.mb.it",
      "pec": "comune.triuggio@legalmail.it",
      "telefono": "+39 0362 97411",
      "fax": "+39 0362997655",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "108044",
      "nome": "Usmate Velate",
      "nomeStraniero": null,
      "codiceCatastale": "L511",
      "cap": "20865",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.usmatevelate.mb.it",
      "pec": "protocollo.comuneusmatevelate@postecert.it",
      "telefono": "+39 6757053",
      "fax": "+39 0396076780",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "108045",
      "nome": "Varedo",
      "nomeStraniero": null,
      "codiceCatastale": "L677",
      "cap": "20814",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.varedo.mb.it",
      "pec": "postacertificata@comune.varedo.legalmail.it",
      "telefono": "+39 0362 5871",
      "fax": "+39 0362 544540",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "108046",
      "nome": "Vedano al Lambro",
      "nomeStraniero": null,
      "codiceCatastale": "L704",
      "cap": "20854",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@cmune.vedanoallambro.mb.it",
      "pec": "comune.vedanoallambro@pec.regione.lombardia.it",
      "telefono": "+39 24861",
      "fax": "+39 039 491786",
      "coordinate": {
        "lat": 45.610286712646484,
        "lng": 9.272911071777344
      }
    },
    {
      "codice": "108047",
      "nome": "Veduggio con Colzano",
      "nomeStraniero": null,
      "codiceCatastale": "L709",
      "cap": "20837",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.veduggioconcolzano.mb.it",
      "pec": "comune.veduggio@legalmail.it",
      "telefono": "+39 0362998741",
      "fax": "+39 0362910878",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "108048",
      "nome": "Verano Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "L744",
      "cap": "20843",
      "prefisso": "0362",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.veranobrianza.mb.it",
      "pec": "comune.veranobrianza@pec.regione.lombardia.it",
      "telefono": "+39 036290851",
      "fax": "+39 0362990753",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "108049",
      "nome": "Villasanta",
      "nomeStraniero": null,
      "codiceCatastale": "M017",
      "cap": "20852",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "protocollo@comune.villasanta.mb.it",
      "pec": "protocollo@pec.comune.villasanta.mb.it",
      "telefono": "+39 237541",
      "fax": "+39 039 305042",
      "coordinate": {
        "lat": 45.60527801513672,
        "lng": 9.303305625915527
      }
    },
    {
      "codice": "108050",
      "nome": "Vimercate",
      "nomeStraniero": null,
      "codiceCatastale": "M052",
      "cap": "20871",
      "prefisso": "039",
      "provincia": "Monza e della Brianza",
      "email": "spazioservizi@comune.vimercate.mb.it",
      "pec": "vimercate@pec.comune.vimercate.mb.it",
      "telefono": "+39 66591",
      "fax": "+39 0396084044",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 9.366666793823242
      }
    }
  ],
  "Novara": [
    {
      "codice": "003001",
      "nome": "Agrate Conturbia",
      "nomeStraniero": null,
      "codiceCatastale": "A088",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.agrateconturbia.no.it",
      "pec": "agrate.conturbia@cert.ruparpiemonte.it",
      "telefono": "+39 0322/832100",
      "fax": "+39 0322/832080",
      "coordinate": {
        "lat": 45.676387786865234,
        "lng": 8.55222225189209
      }
    },
    {
      "codice": "003002",
      "nome": "Ameno",
      "nomeStraniero": null,
      "codiceCatastale": "A264",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "ufficio.demografico@comune.ameno.novara.it",
      "pec": "ameno@pec.it",
      "telefono": "+39 0322/998103",
      "fax": "+39 0322/998206",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003006",
      "nome": "Armeno",
      "nomeStraniero": null,
      "codiceCatastale": "A414",
      "cap": "28011",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "demografici@comune.armeno.no.it",
      "pec": "armeno@cert.ruparpiemonte.it",
      "telefono": "+39 0322/900106",
      "fax": "+39 0322/900013",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.449999809265137
      }
    },
    {
      "codice": "003008",
      "nome": "Arona",
      "nomeStraniero": null,
      "codiceCatastale": "A429",
      "cap": "28041",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "protocollo@comune.arona.no.it",
      "pec": "protocollo@pec.comune.arona.no.it",
      "telefono": "+39 0322 231111",
      "fax": "+39 0322 231210",
      "coordinate": {
        "lat": 45.75694274902344,
        "lng": 8.5600004196167
      }
    },
    {
      "codice": "003012",
      "nome": "Barengo",
      "nomeStraniero": null,
      "codiceCatastale": "A653",
      "cap": "28010",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "segreteria@comune.barengo.no.it",
      "pec": "barengo@postemailcertificata.it",
      "telefono": "+39 0321/997134",
      "fax": "+39 0321/997375",
      "coordinate": {
        "lat": 45.57596969604492,
        "lng": 8.514030456542969
      }
    },
    {
      "codice": "003016",
      "nome": "Bellinzago Novarese",
      "nomeStraniero": null,
      "codiceCatastale": "A752",
      "cap": "28043",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "demografici@comune.bellinzago.no.it",
      "pec": "municipio@pec.comune.bellinzago.no.it",
      "telefono": "+39 0321/924717",
      "fax": "+39 0321/924719",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "003018",
      "nome": "Biandrate",
      "nomeStraniero": null,
      "codiceCatastale": "A844",
      "cap": "28061",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "anagrafe.biandrate@reteunitaria.piemonte.it",
      "pec": "biandrate@pcert.it",
      "telefono": "+39 0321/83122",
      "fax": "+39 0321/838219",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003019",
      "nome": "Boca",
      "nomeStraniero": null,
      "codiceCatastale": "A911",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.boca.no.it",
      "pec": "amministrazione_boca@pec.it",
      "telefono": "+39 0322/87129",
      "fax": "+39 0322/870096",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "003021",
      "nome": "Bogogno",
      "nomeStraniero": null,
      "codiceCatastale": "A929",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "municipio@comune.bogogno.no.it",
      "pec": "bogogno@cert.ruparpiemonte.it",
      "telefono": "+39 0322/808805",
      "fax": "+39 0322/809942",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 8.533333778381348
      }
    },
    {
      "codice": "003022",
      "nome": "Bolzano Novarese",
      "nomeStraniero": null,
      "codiceCatastale": "A953",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "servizidemografici@comune.bolzanonovarese.no.it",
      "pec": "bolzano.novarese@cert.ruparpiemonte.it",
      "telefono": "+39 0322/982080",
      "fax": "+39 0322/982502",
      "coordinate": {
        "lat": 45.76490020751953,
        "lng": 8.446000099182129
      }
    },
    {
      "codice": "003025",
      "nome": "Borgo Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "B043",
      "cap": "28040",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "elisa.sgobbi@comuneborgoticino.it",
      "pec": "comune.borgoticino@legalmail.it",
      "telefono": "+39 0321/90271",
      "fax": null,
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.600000381469727
      }
    },
    {
      "codice": "003023",
      "nome": "Borgolavezzaro",
      "nomeStraniero": null,
      "codiceCatastale": "B016",
      "cap": "28071",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "demografici@comune.borgolavezzaro.no.it",
      "pec": "borgolavezzaro@cert.ruparpiemonte.it",
      "telefono": "+39 0321/885815",
      "fax": null,
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "003024",
      "nome": "Borgomanero",
      "nomeStraniero": null,
      "codiceCatastale": "B019",
      "cap": "28021",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "protocollo@comune.borgomanero.no.it",
      "pec": "protocollo@pec.comune.borgomanero.no.it",
      "telefono": "+39 0322/837711",
      "fax": "+39 0322/845206",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003026",
      "nome": "Briga Novarese",
      "nomeStraniero": null,
      "codiceCatastale": "B176",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "protocollo@comune.briga-novarese.no.it",
      "pec": "municipio@pec.comune.briga-novarese.no.it",
      "telefono": "+39 0322/955731",
      "fax": "+39 0322/913690",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.449999809265137
      }
    },
    {
      "codice": "003027",
      "nome": "Briona",
      "nomeStraniero": null,
      "codiceCatastale": "B183",
      "cap": "28072",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.briona.no.it",
      "pec": "comune@pec.comune.briona.no.it",
      "telefono": "+39 0321/826080",
      "fax": "+39 0321/826441",
      "coordinate": {
        "lat": 45.551944732666016,
        "lng": 8.458611488342285
      }
    },
    {
      "codice": "003030",
      "nome": "Caltignaga",
      "nomeStraniero": null,
      "codiceCatastale": "B431",
      "cap": "28010",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "p.brustia@unionenovarese2000.it",
      "pec": "comune@pec.comune.caltignaga.no.it",
      "telefono": "+39 0321/652114",
      "fax": "+39 0321/652896",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8.583333015441895
      }
    },
    {
      "codice": "003032",
      "nome": "Cameri",
      "nomeStraniero": null,
      "codiceCatastale": "B473",
      "cap": "28062",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "c.borrini@comune.cameri.no.it",
      "pec": "comune.cameri.no@legalmail.it",
      "telefono": "+39 0321/511622",
      "fax": "+39 0321/511660",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.649999618530273
      }
    },
    {
      "codice": "003036",
      "nome": "Carpignano Sesia",
      "nomeStraniero": null,
      "codiceCatastale": "B823",
      "cap": "28064",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "demografici@comune.carpignanosesia.no.it",
      "pec": "carpignanosesia@pcert.it",
      "telefono": "+39 0321/824419",
      "fax": "+39 0321/824401",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003037",
      "nome": "Casalbeltrame",
      "nomeStraniero": null,
      "codiceCatastale": "B864",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "segreteria@casalbeltrameonline.it",
      "pec": "casalbeltrame@cert.ruparpiemonte.it",
      "telefono": "+39 0321/83154",
      "fax": "+39 0321/83077",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003039",
      "nome": "Casaleggio Novara",
      "nomeStraniero": null,
      "codiceCatastale": "B883",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.casaleggionovara.no.it",
      "pec": "casaleggionovara@pcert.it",
      "telefono": "+39 0321/839132",
      "fax": "+39 0321/839600",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.5
      }
    },
    {
      "codice": "003040",
      "nome": "Casalino",
      "nomeStraniero": null,
      "codiceCatastale": "B897",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "casalino@reteunitaria.piemonte.it",
      "pec": "protocollo.casalino@cert.ruparpiemonte.it",
      "telefono": "+39 0321/870112",
      "fax": "+39 0321/870247",
      "coordinate": {
        "lat": 45.358360290527344,
        "lng": 8.523935317993164
      }
    },
    {
      "codice": "003041",
      "nome": "Casalvolone",
      "nomeStraniero": null,
      "codiceCatastale": "B920",
      "cap": "28060",
      "prefisso": "0161",
      "provincia": "Novara",
      "email": "municipio@comune.casalvolone.no.it",
      "pec": "casalvolone@cert.ruparpiemonte.it",
      "telefono": "+39 0161/315157",
      "fax": "+39 0161/315197",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003042",
      "nome": "Castellazzo Novarese",
      "nomeStraniero": null,
      "codiceCatastale": "C149",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.castellazzonovarese.no.it",
      "pec": "castellazzonovarese@pcert.it",
      "telefono": "+39 0321/83718",
      "fax": "+39 0321/83700",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8.5
      }
    },
    {
      "codice": "003043",
      "nome": "Castelletto sopra Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "C166",
      "cap": "28053",
      "prefisso": "0331",
      "provincia": "Novara",
      "email": "protocollo@comune.castellettosopraticino.no.it",
      "pec": "castellettosopraticino@pec.it",
      "telefono": "+39 0331/971901",
      "fax": "+39 0331/962277",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "003044",
      "nome": "Cavaglietto",
      "nomeStraniero": null,
      "codiceCatastale": "C364",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.cavaglietto.no.it",
      "pec": "cavaglietto@pecbox.net",
      "telefono": "+39 0322/806101",
      "fax": "+39 0322/806430",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.5
      }
    },
    {
      "codice": "003045",
      "nome": "Cavaglio d'Agogna",
      "nomeStraniero": null,
      "codiceCatastale": "C365",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "cavaglio.dagogna@ruparpiemonte.it",
      "pec": "cavaglio.dagogna@cert.ruparpiemonte.it",
      "telefono": "+39 0322/806114",
      "fax": "+39 0322/806115",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.483333587646484
      }
    },
    {
      "codice": "003047",
      "nome": "Cavallirio",
      "nomeStraniero": null,
      "codiceCatastale": "C378",
      "cap": "28010",
      "prefisso": "0163",
      "provincia": "Novara",
      "email": "anagrafe.cavallirio@libero.it",
      "pec": "comune.cavallirio@pec.it",
      "telefono": "+39 0163/80140",
      "fax": "+39 0163/80148",
      "coordinate": {
        "lat": 45.66222381591797,
        "lng": 8.39555549621582
      }
    },
    {
      "codice": "003049",
      "nome": "Cerano",
      "nomeStraniero": null,
      "codiceCatastale": "C483",
      "cap": "28065",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "comune@comune.cerano.no.it",
      "pec": "comune@pec.comune.cerano.no.it",
      "telefono": "+39 0321/771411",
      "fax": "+39 0321/728005",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "003051",
      "nome": "Colazza",
      "nomeStraniero": null,
      "codiceCatastale": "C829",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "municipio@comune.colazza.no.it",
      "pec": "colazza@cert.ruparpiemonte.it",
      "telefono": "+39 0322/218102",
      "fax": "+39 0322/218604",
      "coordinate": {
        "lat": 45.790531158447266,
        "lng": 8.497369766235352
      }
    },
    {
      "codice": "003052",
      "nome": "Comignago",
      "nomeStraniero": null,
      "codiceCatastale": "C926",
      "cap": "28060",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "comunedicomignago.comune@tin.it",
      "pec": "comignago@cert.ruparpiemonte.it",
      "telefono": "+39 0322/50000",
      "fax": "+39 0322/095114",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "003055",
      "nome": "Cressa",
      "nomeStraniero": null,
      "codiceCatastale": "D162",
      "cap": "28012",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.cressa.no.it",
      "pec": "cressa@cert.ruparpiemonte.it",
      "telefono": "+39 0322/863610",
      "fax": null,
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 8.516666412353516
      }
    },
    {
      "codice": "003058",
      "nome": "Cureggio",
      "nomeStraniero": null,
      "codiceCatastale": "D216",
      "cap": "28060",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "protocollo@comune.cureggio.no.it",
      "pec": "protocollo.cureggio@cert.ruparpiemonte.it",
      "telefono": "+39 0322/839100",
      "fax": "+39 0322/839828",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003060",
      "nome": "Divignano",
      "nomeStraniero": null,
      "codiceCatastale": "D309",
      "cap": "28010",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "segreteria@comune.divignano.no.it",
      "pec": "divignano@cert.ruparpiemonte.it",
      "telefono": "+39 0321/995375",
      "fax": "+39 0321/995295",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 8.600000381469727
      }
    },
    {
      "codice": "003062",
      "nome": "Dormelletto",
      "nomeStraniero": null,
      "codiceCatastale": "D347",
      "cap": "28040",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "claudia.belluco@comune.dormelletto.no.it",
      "pec": "comune.dormelletto.no@legalmail.it",
      "telefono": "+39 0322/401413",
      "fax": "+39 0322/401410",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "003065",
      "nome": "Fara Novarese",
      "nomeStraniero": null,
      "codiceCatastale": "D492",
      "cap": "28073",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "segreteria@comune.faranovarese.no.it",
      "pec": "municipio@pec.comune.faranovarese.no.it",
      "telefono": "+39 0321.829261",
      "fax": "+39 0321.819128",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003066",
      "nome": "Fontaneto d'Agogna",
      "nomeStraniero": null,
      "codiceCatastale": "D675",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "demografici@comune.fontaneto.no.it",
      "pec": "protocollo.fontanetodagogna@pec.it",
      "telefono": "+39 0322/89164",
      "fax": "+39 0322/89122",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 8.483333587646484
      }
    },
    {
      "codice": "003068",
      "nome": "Galliate",
      "nomeStraniero": null,
      "codiceCatastale": "D872",
      "cap": "28066",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "comune@comune.galliate.no.it",
      "pec": "comunegalliate@legalmail.it",
      "telefono": "+39 0321/800700",
      "fax": "+39 0321/800725",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "003069",
      "nome": "Garbagna Novarese",
      "nomeStraniero": null,
      "codiceCatastale": "D911",
      "cap": "28070",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.garbagna.no.it",
      "pec": "garbagna.novarese@pcert.it",
      "telefono": "+39 0321/845110",
      "fax": "+39 0321/845407",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "003070",
      "nome": "Gargallo",
      "nomeStraniero": null,
      "codiceCatastale": "D921",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "comunedigargallo@tiscalinet.it",
      "pec": "gargallo@cert.ruparpiemonte.it",
      "telefono": "+39 0322/913017",
      "fax": "+39 0322/953619",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003166",
      "nome": "Gattico-Veruno",
      "nomeStraniero": null,
      "codiceCatastale": "M416",
      "cap": "28013",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "segreteria@comune.gattico-veruno.no.it",
      "pec": "comune.gattico-veruno@pcert.it",
      "telefono": "+39 0322838988",
      "fax": null,
      "coordinate": {
        "lat": 45.70204544067383,
        "lng": 8.529829978942871
      }
    },
    {
      "codice": "003073",
      "nome": "Ghemme",
      "nomeStraniero": null,
      "codiceCatastale": "E001",
      "cap": "28074",
      "prefisso": "0163",
      "provincia": "Novara",
      "email": "anagrafe@comune.ghemme.novara.it",
      "pec": "municipio@pec.comune.ghemme.novara.it",
      "telefono": "+39 0163/840101",
      "fax": "+39 0163/841551",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "003076",
      "nome": "Gozzano",
      "nomeStraniero": null,
      "codiceCatastale": "E120",
      "cap": "28024",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.gozzano.no.it",
      "pec": "comune@cert.comune.gozzano.no.it",
      "telefono": "+39 0322/955677",
      "fax": "+39 0322/917110",
      "coordinate": {
        "lat": 45.75,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003077",
      "nome": "Granozzo con Monticello",
      "nomeStraniero": null,
      "codiceCatastale": "E143",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.granozzoconmonticello.no.it",
      "pec": "segretariogranozzo@pcert.it",
      "telefono": "+39 0321/55113",
      "fax": "+39 0321/550002",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "003079",
      "nome": "Grignasco",
      "nomeStraniero": null,
      "codiceCatastale": "E177",
      "cap": "28075",
      "prefisso": "0163",
      "provincia": "Novara",
      "email": "demografici@comune.grignasco.no.it",
      "pec": "comune.grignasco@pcert.it",
      "telefono": "+39 0163/417119",
      "fax": "+39 0163/411646",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "003082",
      "nome": "Invorio",
      "nomeStraniero": null,
      "codiceCatastale": "E314",
      "cap": "28045",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "ufficioanagrafe@comune.invorio.it",
      "pec": "protocollo@pec.comune.invorio.no.it",
      "telefono": "+39 0322/259241",
      "fax": "+39 0322/255104",
      "coordinate": {
        "lat": 45.75,
        "lng": 8.483333587646484
      }
    },
    {
      "codice": "003083",
      "nome": "Landiona",
      "nomeStraniero": null,
      "codiceCatastale": "E436",
      "cap": "28064",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.landiona.no.it",
      "pec": "landiona@pcert.it",
      "telefono": "+39 0321/828121",
      "fax": "+39 0321/828350",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "003084",
      "nome": "Lesa",
      "nomeStraniero": null,
      "codiceCatastale": "E544",
      "cap": "28040",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "alfredo.guazzi@comune.lesa.no.it",
      "pec": "lesa@cert.ruparpiemonte.it",
      "telefono": "+39 0322/76421",
      "fax": "+39 0322/76972",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "003088",
      "nome": "Maggiora",
      "nomeStraniero": null,
      "codiceCatastale": "E803",
      "cap": "28014",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "demografici@comune.maggiora.no.it",
      "pec": "protocollo@pec.comune.maggiora.no.it",
      "telefono": "+39 0322/87113",
      "fax": "+39 0322/87877",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003090",
      "nome": "Mandello Vitta",
      "nomeStraniero": null,
      "codiceCatastale": "E880",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.mandellovitta.no.it",
      "pec": "mandello.vitta@cert.ruparpiemonte.it",
      "telefono": "+39 0321/835628",
      "fax": "+39 0321/835040",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003091",
      "nome": "Marano Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "E907",
      "cap": "28040",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "anagrafe@comune.maranoticino.no.it",
      "pec": "info@pec.comune.maranoticino.no.it",
      "telefono": "+39 0321/923022",
      "fax": "+39 0321/923108",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "003093",
      "nome": "Massino Visconti",
      "nomeStraniero": null,
      "codiceCatastale": "F047",
      "cap": "28040",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "massino.visconti@libero.it",
      "pec": "comune.massinovisconti@legalmail.it",
      "telefono": "+39 0322/219120",
      "fax": "+39 0322/219640",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.533333778381348
      }
    },
    {
      "codice": "003095",
      "nome": "Meina",
      "nomeStraniero": null,
      "codiceCatastale": "F093",
      "cap": "28046",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "meinaedy@libero.it",
      "pec": "meina@postemailcertificata.it",
      "telefono": "+39 0322/660220",
      "fax": "+39 0322/660612",
      "coordinate": {
        "lat": 45.78877258300781,
        "lng": 8.538826942443848
      }
    },
    {
      "codice": "003097",
      "nome": "Mezzomerico",
      "nomeStraniero": null,
      "codiceCatastale": "F188",
      "cap": "28040",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "servizidemografici@comune.mezzomerico.no.it",
      "pec": "mezzomerico@cert.ruparpiemonte.it",
      "telefono": "+39 0321/976629",
      "fax": "+39 0321/97092",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.600000381469727
      }
    },
    {
      "codice": "003098",
      "nome": "Miasino",
      "nomeStraniero": null,
      "codiceCatastale": "F191",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "protocollo@comune.miasino.no.it",
      "pec": "miasino@cert.ruparpiemonte.it",
      "telefono": "+39 0322/980012",
      "fax": "+39 0322/915778",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003100",
      "nome": "Momo",
      "nomeStraniero": null,
      "codiceCatastale": "F317",
      "cap": "28015",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "comune@comune.momo.no.it",
      "pec": "protocollo.momo@cert.ruparpiemonte.it",
      "telefono": "+39 0321/926021",
      "fax": "+39 0321/926186",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 8.550000190734863
      }
    },
    {
      "codice": "003103",
      "nome": "Nebbiuno",
      "nomeStraniero": null,
      "codiceCatastale": "F859",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "info@comune.nebbiuno.no.it",
      "pec": "nebbiuno@cert.ruparpiemonte.it",
      "telefono": "+39 0322/58001",
      "fax": "+39 0322/58139",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.533333778381348
      }
    },
    {
      "codice": "003104",
      "nome": "Nibbiola",
      "nomeStraniero": null,
      "codiceCatastale": "F886",
      "cap": "28070",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "nibbiola@comunedinibbiola.it",
      "pec": "nibbiola@cert.ruparpiemonte.it",
      "telefono": "+39 0321/84854",
      "fax": "+39 0321/884900",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "003106",
      "nome": "Novara",
      "nomeStraniero": null,
      "codiceCatastale": "F952",
      "cap": "28100",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "segreteriagenerale@comune.novara.it",
      "pec": "archivio@cert.comune.novara.it",
      "telefono": "+39 0321 3701",
      "fax": "+39 0321 3703937",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.616666793823242
      }
    },
    {
      "codice": "003108",
      "nome": "Oleggio",
      "nomeStraniero": null,
      "codiceCatastale": "G019",
      "cap": "28047",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "demografici@comune.oleggio.no.it",
      "pec": "municipio@pec.comune.oleggio.no.it",
      "telefono": "+39 0321/969829",
      "fax": "+39 0321/969837",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "003109",
      "nome": "Oleggio Castello",
      "nomeStraniero": null,
      "codiceCatastale": "G020",
      "cap": "28040",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "info@comunedioleggiocastello.it",
      "pec": "oleggio.castello@cert.ruparpiemonte.it",
      "telefono": "+39 0322/53600",
      "fax": "+39 0322/538295",
      "coordinate": {
        "lat": 45.74972152709961,
        "lng": 8.52833366394043
      }
    },
    {
      "codice": "003112",
      "nome": "Orta San Giulio",
      "nomeStraniero": null,
      "codiceCatastale": "G134",
      "cap": "28016",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.ortasangiulio.no.it",
      "pec": "protocollo.orta.san.giulio@cert.ruparpiemonte.it",
      "telefono": "+39 0322/911972",
      "fax": "+39 0322/90133",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.399999618530273
      }
    },
    {
      "codice": "003114",
      "nome": "Paruzzaro",
      "nomeStraniero": null,
      "codiceCatastale": "G349",
      "cap": "28040",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.paruzzaro.no.it",
      "pec": "paruzzaro@postemailcertificata.it",
      "telefono": "+39 0322/53100",
      "fax": "+39 0322/538066",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.516666412353516
      }
    },
    {
      "codice": "003115",
      "nome": "Pella",
      "nomeStraniero": null,
      "codiceCatastale": "G421",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.pella.no.it",
      "pec": "pella@postemailcertificata.it",
      "telefono": "+39 0322/918004",
      "fax": "+39 0322/918005",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "003116",
      "nome": "Pettenasco",
      "nomeStraniero": null,
      "codiceCatastale": "G520",
      "cap": "28028",
      "prefisso": "0323",
      "provincia": "Novara",
      "email": "pettenasco@ruparpiemonte.it",
      "pec": "pettenasco@cert.ruparpiemonte.it",
      "telefono": "+39 0323/89115",
      "fax": "+39 0323/89693",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "003119",
      "nome": "Pisano",
      "nomeStraniero": null,
      "codiceCatastale": "G703",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "uffici@comune.pisano.novara.it",
      "pec": "pisano@cert.ruparpiemonte.it",
      "telefono": "+39 032258033",
      "fax": "+39 0322280082",
      "coordinate": {
        "lat": 45.794166564941406,
        "lng": 8.512777328491211
      }
    },
    {
      "codice": "003120",
      "nome": "Pogno",
      "nomeStraniero": null,
      "codiceCatastale": "G775",
      "cap": "28076",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "municipio@comune.pogno.no.it",
      "pec": "pogno@cert.ruparpiemonte.it",
      "telefono": "+39 0322/996007",
      "fax": "+39 0322/97425",
      "coordinate": {
        "lat": 45.75944519042969,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "003121",
      "nome": "Pombia",
      "nomeStraniero": null,
      "codiceCatastale": "G809",
      "cap": "28050",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "demografici@comune.pombia.no.it",
      "pec": "ufficioprotocollo@comunedipombia.legalmail.it",
      "telefono": "+39 0321/95333",
      "fax": "+39 0321/95396",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "003122",
      "nome": "Prato Sesia",
      "nomeStraniero": null,
      "codiceCatastale": "H001",
      "cap": "28077",
      "prefisso": "0163",
      "provincia": "Novara",
      "email": "anagrafe@comune.prato-sesia.no.it",
      "pec": "segreteria@pec.comune.prato-sesia.no.it",
      "telefono": "+39 0163/850541",
      "fax": "+39 0163/851124",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "003129",
      "nome": "Recetto",
      "nomeStraniero": null,
      "codiceCatastale": "H213",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "comune@comune.recetto.no.it",
      "pec": "recetto@pcert.it",
      "telefono": "+39 0321/836119",
      "fax": "+39 0321/836942",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003130",
      "nome": "Romagnano Sesia",
      "nomeStraniero": null,
      "codiceCatastale": "H502",
      "cap": "28078",
      "prefisso": "0163",
      "provincia": "Novara",
      "email": "segreteria@comune.romagnano-sesia.no.it",
      "pec": "romagnano.sesia@cert.ruparpiemonte.it",
      "telefono": "+39 0163.826869",
      "fax": "+39 0163.826496",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "003131",
      "nome": "Romentino",
      "nomeStraniero": null,
      "codiceCatastale": "H518",
      "cap": "28068",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "protocollo@comune.romentino.no.it",
      "pec": "area.amministrativa@pec.comune.romentino.no.it",
      "telefono": "+39 0321/869811",
      "fax": "+39 0321/860643",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "003133",
      "nome": "San Maurizio d'Opaglio",
      "nomeStraniero": null,
      "codiceCatastale": "I025",
      "cap": "28017",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "municipio@comune.sanmauriziodopaglio.no.it",
      "pec": "protocollo@pec.comune.sanmauriziodopaglio.no.it",
      "telefono": "+39 0322/967222",
      "fax": "+39 0322/967247",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "003134",
      "nome": "San Nazzaro Sesia",
      "nomeStraniero": null,
      "codiceCatastale": "I052",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "san.nazzaro.sesia@ruparpiemonte.it",
      "pec": "san.nazzaro.sesia@cert.ruparpiemonte.it",
      "telefono": "+39 0321/834131",
      "fax": "+39 0321/834303",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "003135",
      "nome": "San Pietro Mosezzo",
      "nomeStraniero": null,
      "codiceCatastale": "I116",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "segreteriara@comune.sanpietromosezzo.no.it",
      "pec": "san.pietro.mosezzo@cert.ruparpiemonte.it",
      "telefono": "+39 0321/530111",
      "fax": "+39 0321/530144",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.550000190734863
      }
    },
    {
      "codice": "003138",
      "nome": "Sillavengo",
      "nomeStraniero": null,
      "codiceCatastale": "I736",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.sillavengo.no.it",
      "pec": "sillavengo@pcert.it",
      "telefono": "+39 0321/825117",
      "fax": "+39 0321/825288",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003139",
      "nome": "Sizzano",
      "nomeStraniero": null,
      "codiceCatastale": "I767",
      "cap": "28070",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "municipio@comune.sizzano.no.it",
      "pec": "comune.sizzano@pec.it",
      "telefono": "+39 0321/820214",
      "fax": "+39 0321/820598",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "003140",
      "nome": "Soriso",
      "nomeStraniero": null,
      "codiceCatastale": "I857",
      "cap": "28010",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "anagrafe@comune.soriso.no.it",
      "pec": "soriso@cert.ruparpiemonte.it",
      "telefono": "+39 0322/983202",
      "fax": "+39 0322/983956",
      "coordinate": {
        "lat": 45.741111755371094,
        "lng": 8.410555839538574
      }
    },
    {
      "codice": "003141",
      "nome": "Sozzago",
      "nomeStraniero": null,
      "codiceCatastale": "I880",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "info@comune.sozzago.no.it",
      "pec": "sozzago@cert.ruparpiemonte.it",
      "telefono": "+39 0321/70176",
      "fax": "+39 0321/70352",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "003143",
      "nome": "Suno",
      "nomeStraniero": null,
      "codiceCatastale": "L007",
      "cap": "28019",
      "prefisso": "0322",
      "provincia": "Novara",
      "email": "c.pontini@comune.suno.novara.it",
      "pec": "suno@cert.ruparpiemonte.it",
      "telefono": "+39 0322/885510",
      "fax": "+39 0322/858042",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.533333778381348
      }
    },
    {
      "codice": "003144",
      "nome": "Terdobbiate",
      "nomeStraniero": null,
      "codiceCatastale": "L104",
      "cap": "28070",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "info@comune.terdobbiate.no.it",
      "pec": "terdobbiate@cert.ruparpiemonte.it",
      "telefono": "+39 0321/84710",
      "fax": "+39 0321/84715",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "003146",
      "nome": "Tornaco",
      "nomeStraniero": null,
      "codiceCatastale": "L223",
      "cap": "28070",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "demografici.tornaco@ruparpiemonte.it",
      "pec": "tornaco@cert.ruparpiemonte.it",
      "telefono": "+39 0321/846118",
      "fax": "+39 0321/846377",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "003149",
      "nome": "Trecate",
      "nomeStraniero": null,
      "codiceCatastale": "L356",
      "cap": "28069",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "elettorale@comune.trecate.no.it",
      "pec": "trecate@postemailcertificata.it",
      "telefono": "+39 0321/776311",
      "fax": "+39 0321/777404",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "003153",
      "nome": "Vaprio d'Agogna",
      "nomeStraniero": null,
      "codiceCatastale": "L668",
      "cap": "28010",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "anagrafe@comune.vapriodagogna.no.it",
      "pec": "vaprio.dagogna@cert.ruparpiemonte.it",
      "telefono": "+39 0321/996124",
      "fax": "+39 0321/996284",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.550000190734863
      }
    },
    {
      "codice": "003154",
      "nome": "Varallo Pombia",
      "nomeStraniero": null,
      "codiceCatastale": "L670",
      "cap": "28040",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "protocollo@pec.comune.varallopombia.no.it",
      "pec": "protocollo@pec.comune.varallopombia.no.it",
      "telefono": "+39 0321/95355",
      "fax": "+39 0321/95182",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "003158",
      "nome": "Vespolate",
      "nomeStraniero": null,
      "codiceCatastale": "L808",
      "cap": "28079",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "anagrafe@comune.vespolate.no.it",
      "pec": "vespolate@cert.ruparpiemonte.it",
      "telefono": "+39 0321/882131",
      "fax": "+39 0321/882741",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "003159",
      "nome": "Vicolungo",
      "nomeStraniero": null,
      "codiceCatastale": "L847",
      "cap": "28060",
      "prefisso": "0321",
      "provincia": "Novara",
      "email": "info@comune.vicolungo.no.it",
      "pec": "vicolungo@pec.intercom.it",
      "telefono": "+39 0321.835126",
      "fax": "+39 0321.835013",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "003164",
      "nome": "Vinzaglio",
      "nomeStraniero": null,
      "codiceCatastale": "M062",
      "cap": "28060",
      "prefisso": "0161",
      "provincia": "Novara",
      "email": "municipio@comune.vinzaglio.no.it",
      "pec": "vinzaglio@postemailcertificata.it",
      "telefono": "+39 0161/317127",
      "fax": "+39 0161/317255",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 8.516666412353516
      }
    }
  ],
  "Agrigento": [
    {
      "codice": "084001",
      "nome": "Agrigento",
      "nomeStraniero": null,
      "codiceCatastale": "A089",
      "cap": "92100",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "segretario.generale@comune.agrigento.it",
      "pec": "servizio.protocollo@pec.comune.agrigento.it",
      "telefono": "+39 092220207",
      "fax": "+39 0922/595923",
      "coordinate": {
        "lat": 37.311073303222656,
        "lng": 13.576547622680664
      }
    },
    {
      "codice": "084002",
      "nome": "Alessandria della Rocca",
      "nomeStraniero": null,
      "codiceCatastale": "A181",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "comunealessandriaseg@alice.it",
      "pec": "affarigenerali.pec@comunealessandriadellarocca.it",
      "telefono": "+39 0922/980202",
      "fax": "+39 0922/980213",
      "coordinate": {
        "lat": 37.56959915161133,
        "lng": 13.453463554382324
      }
    },
    {
      "codice": "084003",
      "nome": "Aragona",
      "nomeStraniero": null,
      "codiceCatastale": "A351",
      "cap": "92021",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "calongi@comune.aragona.ag.it",
      "pec": "protocollo@pec.comune.aragona.ag.it",
      "telefono": "+39 0922/690943",
      "fax": "+39 0922/36636",
      "coordinate": {
        "lat": 37.40738296508789,
        "lng": 13.61894416809082
      }
    },
    {
      "codice": "084004",
      "nome": "Bivona",
      "nomeStraniero": null,
      "codiceCatastale": "A896",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "bivona.comune@tiscali.it",
      "pec": "bivona.utc@pec.it",
      "telefono": "+39 0922/986504",
      "fax": "+39 0922/983123",
      "coordinate": {
        "lat": 37.61834716796875,
        "lng": 13.440507888793945
      }
    },
    {
      "codice": "084005",
      "nome": "Burgio",
      "nomeStraniero": null,
      "codiceCatastale": "B275",
      "cap": "92010",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "comunediburgio@virgilio.it",
      "pec": "comunediburgio@pec.it",
      "telefono": "+39 0925/65011",
      "fax": "+39 0925/65007",
      "coordinate": {
        "lat": 37.598533630371094,
        "lng": 13.290775299072266
      }
    },
    {
      "codice": "084006",
      "nome": "Calamonaci",
      "nomeStraniero": null,
      "codiceCatastale": "B377",
      "cap": "92010",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "info@comune.calamonaci.ag.it",
      "pec": "info@pec.comune.calamonaci.ag.it",
      "telefono": "+39 0925/68377",
      "fax": "+39 0925/68904",
      "coordinate": {
        "lat": 37.52614974975586,
        "lng": 13.289349555969238
      }
    },
    {
      "codice": "084007",
      "nome": "Caltabellotta",
      "nomeStraniero": null,
      "codiceCatastale": "B427",
      "cap": "92010",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "comunecaltabellotta@tiscali.it",
      "pec": "comunecaltabellotta@pec.it",
      "telefono": "+39 0925/951013",
      "fax": "+39 0925/951987",
      "coordinate": {
        "lat": 37.57573699951172,
        "lng": 13.218607902526855
      }
    },
    {
      "codice": "084008",
      "nome": "Camastra",
      "nomeStraniero": null,
      "codiceCatastale": "B460",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "nicodemocarmelina@virgilio.it",
      "pec": "protocollo.comune.camastra@pec.it",
      "telefono": "+39 0922/954021",
      "fax": "+39 0922/950680",
      "coordinate": {
        "lat": 37.25361251831055,
        "lng": 13.795555114746094
      }
    },
    {
      "codice": "084009",
      "nome": "Cammarata",
      "nomeStraniero": null,
      "codiceCatastale": "B486",
      "cap": "92022",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "sindaco@comune.cammarata.ag.it",
      "pec": "sindaco@pec.comune.cammarata.ag.it",
      "telefono": "+39 0922907250",
      "fax": "+39 0922903996",
      "coordinate": {
        "lat": 37.63111114501953,
        "lng": 13.632222175598145
      }
    },
    {
      "codice": "084010",
      "nome": "Campobello di Licata",
      "nomeStraniero": null,
      "codiceCatastale": "B520",
      "cap": "92023",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "serviziinformatici@comune.campobellodilicata.ag.it",
      "pec": "protocollo@legal.comune.campobellodilicata.ag.it",
      "telefono": "+39 0922/889213",
      "fax": "+39 0922879805",
      "coordinate": {
        "lat": 37.25944519042969,
        "lng": 13.919166564941406
      }
    },
    {
      "codice": "084011",
      "nome": "Canicattì",
      "nomeStraniero": null,
      "codiceCatastale": "B602",
      "cap": "92024",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "gabinetto.sindaco@comune.canicatti.ag.it",
      "pec": "pec.istituzionale@pec.comune.canicatti.ag.it",
      "telefono": "+39 0922-734111",
      "fax": "+39 0922734225",
      "coordinate": {
        "lat": 37.36000061035156,
        "lng": 13.85111141204834
      }
    },
    {
      "codice": "084012",
      "nome": "Casteltermini",
      "nomeStraniero": null,
      "codiceCatastale": "C275",
      "cap": "92025",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "protocollo@comune.casteltermini.ag.it",
      "pec": "comunedicasteltermini@pec.it",
      "telefono": "+39 0922/929001",
      "fax": null,
      "coordinate": {
        "lat": 37.54166793823242,
        "lng": 13.645277976989746
      }
    },
    {
      "codice": "084013",
      "nome": "Castrofilippo",
      "nomeStraniero": null,
      "codiceCatastale": "C341",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "comunedicastrofilippo1@tin.it",
      "pec": "protocollo.comune.castrofilippo@pec.it",
      "telefono": "+39 0922/825132",
      "fax": "+39 0922/829266",
      "coordinate": {
        "lat": 37.349998474121094,
        "lng": 13.751111030578613
      }
    },
    {
      "codice": "084014",
      "nome": "Cattolica Eraclea",
      "nomeStraniero": null,
      "codiceCatastale": "C356",
      "cap": "92011",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "info@comune.cattolicaeraclea.ag.it",
      "pec": "protocollo@comunecattolicaeraclea.it",
      "telefono": "+39 0922846911",
      "fax": "+39 0922840312",
      "coordinate": {
        "lat": 37.44194412231445,
        "lng": 13.392777442932129
      }
    },
    {
      "codice": "084015",
      "nome": "Cianciana",
      "nomeStraniero": null,
      "codiceCatastale": "C668",
      "cap": "92012",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "comunecianciana@libero.it",
      "pec": "protocollocianciana@pec.it",
      "telefono": "+39 0922/987236",
      "fax": "+39 0922/984232",
      "coordinate": {
        "lat": 37.52138900756836,
        "lng": 13.434444427490234
      }
    },
    {
      "codice": "084016",
      "nome": "Comitini",
      "nomeStraniero": null,
      "codiceCatastale": "C928",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "anagrafe.comitini@virgilio.it",
      "pec": "anagrafe@pec.comune.comitini.ag.it",
      "telefono": "+39 0922 600330",
      "fax": "+39 0922 600330",
      "coordinate": {
        "lat": 37.40888977050781,
        "lng": 13.643888473510742
      }
    },
    {
      "codice": "084017",
      "nome": "Favara",
      "nomeStraniero": null,
      "codiceCatastale": "D514",
      "cap": "92026",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "giovanni.dicaro@comune.favara.ag.it",
      "pec": "comune.favara@pec.it",
      "telefono": "+39 0922/448829",
      "fax": "+39 0922/31664",
      "coordinate": {
        "lat": 37.31861114501953,
        "lng": 13.663055419921875
      }
    },
    {
      "codice": "084018",
      "nome": "Grotte",
      "nomeStraniero": null,
      "codiceCatastale": "E209",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "info@comunedigrotte.org",
      "pec": "comunedigrotte@pec.it",
      "telefono": "+39 0922 947511",
      "fax": "+39 0922 947530",
      "coordinate": {
        "lat": 37.40583419799805,
        "lng": 13.70111083984375
      }
    },
    {
      "codice": "084019",
      "nome": "Joppolo Giancaxio",
      "nomeStraniero": null,
      "codiceCatastale": "E390",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "ufficio.segreteria@comune.joppologiancaxio.ag.it",
      "pec": "anagrafe.statocivile@pec.comune.joppologiancaxio.ag.it",
      "telefono": "+39 0922 631047",
      "fax": "+39 0922 631408",
      "coordinate": {
        "lat": 37.38805389404297,
        "lng": 13.556111335754395
      }
    },
    {
      "codice": "084020",
      "nome": "Lampedusa e Linosa",
      "nomeStraniero": null,
      "codiceCatastale": "E431",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "Uff.Segreteria@comune.lampedusaelinosa.ag.it",
      "pec": "protocollo@pec.comune.lampedusaelinosa.ag.it",
      "telefono": "+39 0922/975901",
      "fax": "+39 0922/970027",
      "coordinate": {
        "lat": 35.50352478027344,
        "lng": 12.606689453125
      }
    },
    {
      "codice": "084021",
      "nome": "Licata",
      "nomeStraniero": null,
      "codiceCatastale": "E573",
      "cap": "92027",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "urp@comune.licata.ag.it",
      "pec": "protocollo@cert.comune.licata.ag.it",
      "telefono": "+39 0922/868227",
      "fax": "+39 0922/868243",
      "coordinate": {
        "lat": 37.108333587646484,
        "lng": 13.946944236755371
      }
    },
    {
      "codice": "084022",
      "nome": "Lucca Sicula",
      "nomeStraniero": null,
      "codiceCatastale": "E714",
      "cap": "92010",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "liboriaoliveri_1952@libero.it",
      "pec": "comunediluccasicula@pec.it",
      "telefono": "+39 0925/60490",
      "fax": "+39 0925/60585",
      "coordinate": {
        "lat": 37.5805549621582,
        "lng": 13.307777404785156
      }
    },
    {
      "codice": "084023",
      "nome": "Menfi",
      "nomeStraniero": null,
      "codiceCatastale": "F126",
      "cap": "92013",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "urp@comune.menfi.ag.it",
      "pec": "protocollo@pec.comune.menfi.ag.it",
      "telefono": "+39 0925 70401",
      "fax": "+39 0925 70406",
      "coordinate": {
        "lat": 37.6077766418457,
        "lng": 12.968610763549805
      }
    },
    {
      "codice": "084024",
      "nome": "Montallegro",
      "nomeStraniero": null,
      "codiceCatastale": "F414",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "salvatorechiara@libero.it",
      "pec": "comunedimontallegro@pec.it",
      "telefono": "+39 320 4303673",
      "fax": "+39 0922 847724",
      "coordinate": {
        "lat": 37.393890380859375,
        "lng": 13.351666450500488
      }
    },
    {
      "codice": "084025",
      "nome": "Montevago",
      "nomeStraniero": null,
      "codiceCatastale": "F655",
      "cap": "92010",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "anagrafe@comune.montevago.ag.it",
      "pec": "protocollo.montevago@pec.it",
      "telefono": "+39 092539647",
      "fax": "+39 092538689",
      "coordinate": {
        "lat": 37.70333480834961,
        "lng": 12.986666679382324
      }
    },
    {
      "codice": "084026",
      "nome": "Naro",
      "nomeStraniero": null,
      "codiceCatastale": "F845",
      "cap": "92028",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "info@comune.naro.ag.it",
      "pec": "protocollo.comune.naro@pec.it",
      "telefono": "+39 0922/953005",
      "fax": "+39 0922/957324",
      "coordinate": {
        "lat": 37.29248046875,
        "lng": 13.793370246887207
      }
    },
    {
      "codice": "084027",
      "nome": "Palma di Montechiaro",
      "nomeStraniero": null,
      "codiceCatastale": "G282",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "urp@comune.palmadimontechiaro.ag.it",
      "pec": "protocollo@comune.palmadimontechiaro.legalmail.it",
      "telefono": "+39 0922/799001",
      "fax": "+39 0922/799219",
      "coordinate": {
        "lat": 37.19361114501953,
        "lng": 13.765832901000977
      }
    },
    {
      "codice": "084028",
      "nome": "Porto Empedocle",
      "nomeStraniero": null,
      "codiceCatastale": "F299",
      "cap": "92014",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "anagrafeaire@virgilio.it",
      "pec": "comune.portoempedocle@pec.it",
      "telefono": "+39 0922 531710",
      "fax": "+39 0922 531711",
      "coordinate": {
        "lat": 37.2944450378418,
        "lng": 13.527222633361816
      }
    },
    {
      "codice": "084029",
      "nome": "Racalmuto",
      "nomeStraniero": null,
      "codiceCatastale": "H148",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "anagrafe@comune.racalmuto.ag.it",
      "pec": "comunediracalmuto.segretariogen.ag@pa.postacertificata.gov.it",
      "telefono": "+39 0922/940000",
      "fax": "+39 0922/948007",
      "coordinate": {
        "lat": 37.40833282470703,
        "lng": 13.734722137451172
      }
    },
    {
      "codice": "084030",
      "nome": "Raffadali",
      "nomeStraniero": null,
      "codiceCatastale": "H159",
      "cap": "92015",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "info@comune.raffadali.ag.it",
      "pec": "pec@comuneraffadali.it",
      "telefono": "+39 0922 475911",
      "fax": "+39 0922/472255",
      "coordinate": {
        "lat": 37.40472412109375,
        "lng": 13.533888816833496
      }
    },
    {
      "codice": "084031",
      "nome": "Ravanusa",
      "nomeStraniero": null,
      "codiceCatastale": "H194",
      "cap": "92029",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "sindaco@comune.ravanusa.ag.it",
      "pec": "comune.ravanusa@pec.it",
      "telefono": "+39 0922/881511",
      "fax": "+39 0922/880415",
      "coordinate": {
        "lat": 37.26777648925781,
        "lng": 13.969721794128418
      }
    },
    {
      "codice": "084032",
      "nome": "Realmonte",
      "nomeStraniero": null,
      "codiceCatastale": "H205",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "comunedirealmonteaagg@virgilio.it",
      "pec": "protocollo.realmonte@pec.it",
      "telefono": "+39 0922/810811",
      "fax": "+39 0922/810147",
      "coordinate": {
        "lat": 37.31027603149414,
        "lng": 13.462778091430664
      }
    },
    {
      "codice": "084033",
      "nome": "Ribera",
      "nomeStraniero": null,
      "codiceCatastale": "H269",
      "cap": "92016",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "info@comune.ribera.ag.it",
      "pec": "protocollogenerale@pec.comune.ribera.ag.it",
      "telefono": "+39 0925/561111",
      "fax": "+39 0925/561111",
      "coordinate": {
        "lat": 37.49944305419922,
        "lng": 13.265000343322754
      }
    },
    {
      "codice": "084034",
      "nome": "Sambuca di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "H743",
      "cap": "92017",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "uf.personale@libero.it",
      "pec": "segreteria@pec.comune.sambucadisicilia.ag.it",
      "telefono": "+39 0925/940111",
      "fax": "+39 0925/941007",
      "coordinate": {
        "lat": 37.64777755737305,
        "lng": 13.11111068725586
      }
    },
    {
      "codice": "084035",
      "nome": "San Biagio Platani",
      "nomeStraniero": null,
      "codiceCatastale": "H778",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "sindaco@comunesanbiagioplatani.it",
      "pec": "seg.comunesanbiagioplatani@pec.it",
      "telefono": "+39 0922/918900",
      "fax": "+39 0922/918543",
      "coordinate": {
        "lat": 37.5099983215332,
        "lng": 13.526389122009277
      }
    },
    {
      "codice": "084036",
      "nome": "San Giovanni Gemini",
      "nomeStraniero": null,
      "codiceCatastale": "H914",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "anagrafe@comune.sangiovannigemini.ag.it",
      "pec": "comune.sangiovannigemini@pec.it",
      "telefono": "+39 0922/903142",
      "fax": "+39 0922/903336",
      "coordinate": {
        "lat": 37.62916564941406,
        "lng": 13.641666412353516
      }
    },
    {
      "codice": "084039",
      "nome": "Sant'Angelo Muxaro",
      "nomeStraniero": null,
      "codiceCatastale": "I290",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "info@santangelomuxaro.gov.it",
      "pec": "comune@pec.santangelomuxaro.gov.it",
      "telefono": "+39 0922/919506",
      "fax": "+39 0922/919805",
      "coordinate": {
        "lat": 37.4808349609375,
        "lng": 13.546388626098633
      }
    },
    {
      "codice": "084037",
      "nome": "Santa Elisabetta",
      "nomeStraniero": null,
      "codiceCatastale": "I185",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "protocollo@comunesantaelisabetta.it",
      "pec": "santaelisabetta@pec.it",
      "telefono": "+39 0922/479296",
      "fax": "+39 0922/479296",
      "coordinate": {
        "lat": 37.43583297729492,
        "lng": 13.557777404785156
      }
    },
    {
      "codice": "084038",
      "nome": "Santa Margherita di Belice",
      "nomeStraniero": null,
      "codiceCatastale": "I224",
      "cap": "92018",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "sindaco@comune.santamargheritadibelice.ag.it",
      "pec": "santamargheritadibelice.protocollo@pec.it",
      "telefono": "+39 0925 30200",
      "fax": "+39 0925/31170",
      "coordinate": {
        "lat": 37.692779541015625,
        "lng": 13.021111488342285
      }
    },
    {
      "codice": "084040",
      "nome": "Santo Stefano Quisquina",
      "nomeStraniero": null,
      "codiceCatastale": "I356",
      "cap": "92020",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "info@comune.santostefanoquisquina.ag.it",
      "pec": "protocollo@pec.comune.santostefanoquisquina.ag.it",
      "telefono": "+39 0922982595",
      "fax": "+39 0922989341",
      "coordinate": {
        "lat": 37.624168395996094,
        "lng": 13.491389274597168
      }
    },
    {
      "codice": "084041",
      "nome": "Sciacca",
      "nomeStraniero": null,
      "codiceCatastale": "I533",
      "cap": "92019",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "urp@comunedisciacca.it",
      "pec": "protocollo@comunedisciacca.telecompost.it",
      "telefono": "+39 0925 20613",
      "fax": "+39 0925/20608",
      "coordinate": {
        "lat": 37.5091667175293,
        "lng": 13.088889122009277
      }
    },
    {
      "codice": "084042",
      "nome": "Siculiana",
      "nomeStraniero": null,
      "codiceCatastale": "I723",
      "cap": "92010",
      "prefisso": "0922",
      "provincia": "Agrigento",
      "email": "protocollo@comune.siculiana.ag.it",
      "pec": "d.indelicato@pec.comune.siculiana.ag.it",
      "telefono": "+39 0922 818011",
      "fax": "+39 0922 815075",
      "coordinate": {
        "lat": 37.33555603027344,
        "lng": 13.422499656677246
      }
    },
    {
      "codice": "084043",
      "nome": "Villafranca Sicula",
      "nomeStraniero": null,
      "codiceCatastale": "L944",
      "cap": "92020",
      "prefisso": "0925",
      "provincia": "Agrigento",
      "email": "sindacovillafranca@tiscali.it",
      "pec": "villafrancasicula@pec.it",
      "telefono": "+39 0925/550041",
      "fax": "+39 0925/550043",
      "coordinate": {
        "lat": 37.589168548583984,
        "lng": 13.289999961853027
      }
    }
  ],
  "Ancona": [
    {
      "codice": "042001",
      "nome": "Agugliano",
      "nomeStraniero": null,
      "codiceCatastale": "A092",
      "cap": "60020",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comune.agugliano@provincia.ancona.it",
      "pec": "comune.agugliano@emarche.it",
      "telefono": "+39 071/9068031",
      "fax": "+39 071/908213",
      "coordinate": {
        "lat": 43.54343795776367,
        "lng": 13.386005401611328
      }
    },
    {
      "codice": "042002",
      "nome": "Ancona",
      "nomeStraniero": null,
      "codiceCatastale": "A271",
      "cap": "60121",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "info@comune.ancona.it",
      "pec": "comune.ancona@emarche.it",
      "telefono": "+39 071 2221",
      "fax": "+39 071/2222109",
      "coordinate": {
        "lat": 43.61666488647461,
        "lng": 13.516666412353516
      }
    },
    {
      "codice": "042003",
      "nome": "Arcevia",
      "nomeStraniero": null,
      "codiceCatastale": "A366",
      "cap": "60011",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "info@arceviaweb.it",
      "pec": "comune.arcevia@emarche.it",
      "telefono": "+39 0731 9899",
      "fax": "+39 0731/9899226",
      "coordinate": {
        "lat": 43.483333587646484,
        "lng": 12.939599990844727
      }
    },
    {
      "codice": "042004",
      "nome": "Barbara",
      "nomeStraniero": null,
      "codiceCatastale": "A626",
      "cap": "60010",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "protocollo@comune.barbara.an.it",
      "pec": "comune.barbara@emarche.it",
      "telefono": "+39 071/9674212",
      "fax": "+39 071/9674065",
      "coordinate": {
        "lat": 43.58074951171875,
        "lng": 13.024619102478027
      }
    },
    {
      "codice": "042005",
      "nome": "Belvedere Ostrense",
      "nomeStraniero": null,
      "codiceCatastale": "A769",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune.belvedereostrense@regione.marche.it",
      "pec": "segreteria.belvedere@emarche.it",
      "telefono": "+39 0731/617003",
      "fax": "+39 0731/617005",
      "coordinate": {
        "lat": 43.58049011230469,
        "lng": 13.166549682617188
      }
    },
    {
      "codice": "042006",
      "nome": "Camerano",
      "nomeStraniero": null,
      "codiceCatastale": "B468",
      "cap": "60021",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "info@comune.camerano.an.it",
      "pec": "comune.camerano@halleycert.it",
      "telefono": "+39 071/730301",
      "fax": "+39 071/7303041",
      "coordinate": {
        "lat": 43.527740478515625,
        "lng": 13.552570343017578
      }
    },
    {
      "codice": "042007",
      "nome": "Camerata Picena",
      "nomeStraniero": null,
      "codiceCatastale": "B470",
      "cap": "60020",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comune@comune.cameratapicena.an.it",
      "pec": "comune@cert.comune.cameratapicena.an.it",
      "telefono": "+39 071/947031",
      "fax": "+39 071/9470334",
      "coordinate": {
        "lat": 43.57831954956055,
        "lng": 13.352761268615723
      }
    },
    {
      "codice": "042008",
      "nome": "Castelbellino",
      "nomeStraniero": null,
      "codiceCatastale": "C060",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@castelbellino.pannet.it",
      "pec": "comunedicastelbellino.segreteria.an@pa.postacertificata.gov.it",
      "telefono": "+39 0731/701606",
      "fax": "+39 0731/703564",
      "coordinate": {
        "lat": 43.48777770996094,
        "lng": 13.145833015441895
      }
    },
    {
      "codice": "042010",
      "nome": "Castelfidardo",
      "nomeStraniero": null,
      "codiceCatastale": "C100",
      "cap": "60022",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "protocollo@comune.castelfidardo.an.it",
      "pec": "comune.castelfidardo@pec.it",
      "telefono": "+39 071/7829300",
      "fax": "+39 071/7820119",
      "coordinate": {
        "lat": 43.464168548583984,
        "lng": 13.546111106872559
      }
    },
    {
      "codice": "042011",
      "nome": "Castelleone di Suasa",
      "nomeStraniero": null,
      "codiceCatastale": "C152",
      "cap": "60010",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comune@castelleonedisuasa.it",
      "pec": "comune.castelleonedisuasa@emarche.it",
      "telefono": "+39 071/966113",
      "fax": "+39 071/966010",
      "coordinate": {
        "lat": 43.60738754272461,
        "lng": 12.976369857788086
      }
    },
    {
      "codice": "042012",
      "nome": "Castelplanio",
      "nomeStraniero": null,
      "codiceCatastale": "C248",
      "cap": "60031",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comune.castelplanio.an.it",
      "pec": "comune.castelplanio@emarche.it",
      "telefono": "+39 0731/813401",
      "fax": "+39 0731/812350",
      "coordinate": {
        "lat": 43.49448013305664,
        "lng": 13.080939292907715
      }
    },
    {
      "codice": "042013",
      "nome": "Cerreto d'Esi",
      "nomeStraniero": null,
      "codiceCatastale": "C524",
      "cap": "60043",
      "prefisso": "0732",
      "provincia": "Ancona",
      "email": "info@comune.cerretodesi.an.it",
      "pec": "demografico@pec.comune.cerretodesi.an.it",
      "telefono": "+39 0732 679000",
      "fax": "+39 0732 678318",
      "coordinate": {
        "lat": 43.319740295410156,
        "lng": 12.984769821166992
      }
    },
    {
      "codice": "042014",
      "nome": "Chiaravalle",
      "nomeStraniero": null,
      "codiceCatastale": "C615",
      "cap": "60033",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "info@comune.chiaravalle.an.it",
      "pec": "info@pec.comune.chiaravalle.an.it",
      "telefono": "+39 071/9499011",
      "fax": "+39 071/742373",
      "coordinate": {
        "lat": 43.59967041015625,
        "lng": 13.32568073272705
      }
    },
    {
      "codice": "042015",
      "nome": "Corinaldo",
      "nomeStraniero": null,
      "codiceCatastale": "D007",
      "cap": "60013",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comune.corinaldo@provincia.ancona.it",
      "pec": "sindaco@mypec.eu",
      "telefono": "+39 071/6793202",
      "fax": "+39 071/7978042",
      "coordinate": {
        "lat": 43.64889907836914,
        "lng": 13.047900199890137
      }
    },
    {
      "codice": "042016",
      "nome": "Cupramontana",
      "nomeStraniero": null,
      "codiceCatastale": "D211",
      "cap": "60034",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "protocollo@comune.cupramontana.an.it",
      "pec": "comune.cupramontana@emarche.it",
      "telefono": "+39 0731/786811",
      "fax": "+39 0731/786860",
      "coordinate": {
        "lat": 43.445980072021484,
        "lng": 13.117369651794434
      }
    },
    {
      "codice": "042017",
      "nome": "Fabriano",
      "nomeStraniero": null,
      "codiceCatastale": "D451",
      "cap": "60044",
      "prefisso": "0732",
      "provincia": "Ancona",
      "email": "info@piazzalta.it",
      "pec": "protocollo@pec.comune.fabriano.an.it",
      "telefono": "+39 0732 7091",
      "fax": "+39 0732/709320",
      "coordinate": {
        "lat": 43.33856201171875,
        "lng": 12.908519744873047
      }
    },
    {
      "codice": "042018",
      "nome": "Falconara Marittima",
      "nomeStraniero": null,
      "codiceCatastale": "D472",
      "cap": "60015",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "sindaco@comune.falconara-marittima.an.it",
      "pec": "comune.falconara.protocollo@emarche.it",
      "telefono": "+39 07191771",
      "fax": "+39 0719177250",
      "coordinate": {
        "lat": 43.629600524902344,
        "lng": 13.39680004119873
      }
    },
    {
      "codice": "042019",
      "nome": "Filottrano",
      "nomeStraniero": null,
      "codiceCatastale": "D597",
      "cap": "60024",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "info@comune.filottrano.an.it",
      "pec": "comune.filottrano@emarche.it",
      "telefono": "+39 071 722781",
      "fax": "+39 071 7220089",
      "coordinate": {
        "lat": 43.434417724609375,
        "lng": 13.350250244140625
      }
    },
    {
      "codice": "042020",
      "nome": "Genga",
      "nomeStraniero": null,
      "codiceCatastale": "D965",
      "cap": "60040",
      "prefisso": "0732",
      "provincia": "Ancona",
      "email": "sindaco@comune.genga.an.it",
      "pec": "sindaco@pec.comunedigenga.it",
      "telefono": "+39 0732/973014",
      "fax": "+39 0732/973344",
      "coordinate": {
        "lat": 43.43048095703125,
        "lng": 12.935561180114746
      }
    },
    {
      "codice": "042021",
      "nome": "Jesi",
      "nomeStraniero": null,
      "codiceCatastale": "E388",
      "cap": "60035",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "protocollo@comune.jesi.an.it",
      "pec": "protocollo.comune.jesi@legalmail.it",
      "telefono": "+39 0731 5381",
      "fax": "+39 0731/538328",
      "coordinate": {
        "lat": 43.522789001464844,
        "lng": 13.243949890136719
      }
    },
    {
      "codice": "042022",
      "nome": "Loreto",
      "nomeStraniero": null,
      "codiceCatastale": "E690",
      "cap": "60025",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "loreto@comuneloreto.it",
      "pec": "comune.loreto@emarche.it",
      "telefono": "+39 071/750561",
      "fax": "+39 071/7500150",
      "coordinate": {
        "lat": 43.44029998779297,
        "lng": 13.607430458068848
      }
    },
    {
      "codice": "042023",
      "nome": "Maiolati Spontini",
      "nomeStraniero": null,
      "codiceCatastale": "E837",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "ced@maiolati.spontini.it",
      "pec": "comune.maiolatispontini@emarche.it",
      "telefono": "+39 0731/7075230",
      "fax": "+39 0731/702816",
      "coordinate": {
        "lat": 43.477176666259766,
        "lng": 13.119189262390137
      }
    },
    {
      "codice": "042024",
      "nome": "Mergo",
      "nomeStraniero": null,
      "codiceCatastale": "F145",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "protgen@comune.mergo.an.it",
      "pec": "comune.mergo@emarche.it",
      "telefono": "+39 0731/814820",
      "fax": "+39 0731/812241",
      "coordinate": {
        "lat": 43.47209930419922,
        "lng": 13.036969184875488
      }
    },
    {
      "codice": "042025",
      "nome": "Monsano",
      "nomeStraniero": null,
      "codiceCatastale": "F381",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comune.monsano.an.it",
      "pec": "comune.monsano@emarche.it",
      "telefono": "+39 0731/61931",
      "fax": "+39 0731/619339",
      "coordinate": {
        "lat": 43.56361770629883,
        "lng": 13.250238418579102
      }
    },
    {
      "codice": "042029",
      "nome": "Monte Roberto",
      "nomeStraniero": null,
      "codiceCatastale": "F600",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@monteroberto.pannet.it",
      "pec": "comune.monteroberto@emarche.it",
      "telefono": "+39 0731/702472",
      "fax": "+39 0731/703652",
      "coordinate": {
        "lat": 43.48128890991211,
        "lng": 13.138031005859375
      }
    },
    {
      "codice": "042030",
      "nome": "Monte San Vito",
      "nomeStraniero": null,
      "codiceCatastale": "F634",
      "cap": "60037",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "info@comune.montesanvito.an.it",
      "pec": "protocollo@pec.comune.montesanvito.an.it",
      "telefono": "+39 071/748931",
      "fax": "+39 071/7489334",
      "coordinate": {
        "lat": 43.60049057006836,
        "lng": 13.271088600158691
      }
    },
    {
      "codice": "042026",
      "nome": "Montecarotto",
      "nomeStraniero": null,
      "codiceCatastale": "F453",
      "cap": "60036",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "info@comune.montecarotto.an.it",
      "pec": "comune.montecarotto@emarche.it",
      "telefono": "+39 0731/89131",
      "fax": "+39 0731/899046",
      "coordinate": {
        "lat": 43.526432037353516,
        "lng": 13.064338684082031
      }
    },
    {
      "codice": "042027",
      "nome": "Montemarciano",
      "nomeStraniero": null,
      "codiceCatastale": "F560",
      "cap": "60018",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "protocollo@comune.montemarciano.ancona.it",
      "pec": "protocollo@cert.comune.montemarciano.ancona.it",
      "telefono": "+39 071/9163310",
      "fax": "+39 071/9163319",
      "coordinate": {
        "lat": 43.63992691040039,
        "lng": 13.310380935668945
      }
    },
    {
      "codice": "042031",
      "nome": "Morro d'Alba",
      "nomeStraniero": null,
      "codiceCatastale": "F745",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comune.morrodalba.an.it",
      "pec": "comune.morrodalba@legamail.it",
      "telefono": "+39 0731/63000",
      "fax": "+39 0731/63043",
      "coordinate": {
        "lat": 43.601165771484375,
        "lng": 13.213764190673828
      }
    },
    {
      "codice": "042032",
      "nome": "Numana",
      "nomeStraniero": null,
      "codiceCatastale": "F978",
      "cap": "60026",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "servizidemografici.numana@regione.marche.it",
      "pec": "comune.numana@emarche.it",
      "telefono": "+39 071/9339841",
      "fax": "+39 071/9339850",
      "coordinate": {
        "lat": 43.51359939575195,
        "lng": 13.621569633483887
      }
    },
    {
      "codice": "042033",
      "nome": "Offagna",
      "nomeStraniero": null,
      "codiceCatastale": "G003",
      "cap": "60020",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "info@offagna.org",
      "pec": "protocollo_offagna@pec.it",
      "telefono": "+39 071 7107005",
      "fax": "+39 071 7107380",
      "coordinate": {
        "lat": 43.52726745605469,
        "lng": 13.441499710083008
      }
    },
    {
      "codice": "042034",
      "nome": "Osimo",
      "nomeStraniero": null,
      "codiceCatastale": "G157",
      "cap": "60027",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "protocollo@comune.osimo.an.it",
      "pec": "comune.osimo@emarche.it",
      "telefono": "+39 071/72491",
      "fax": "+39 071/7133254",
      "coordinate": {
        "lat": 43.486080169677734,
        "lng": 13.482099533081055
      }
    },
    {
      "codice": "042035",
      "nome": "Ostra",
      "nomeStraniero": null,
      "codiceCatastale": "F401",
      "cap": "60010",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comune@comune.ostra.an.it",
      "pec": "comune.ostra@emarche.it",
      "telefono": "+39 071/7980606",
      "fax": "+39 071/7989776",
      "coordinate": {
        "lat": 43.61265182495117,
        "lng": 13.158230781555176
      }
    },
    {
      "codice": "042036",
      "nome": "Ostra Vetere",
      "nomeStraniero": null,
      "codiceCatastale": "F581",
      "cap": "60010",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "protocollo@comune.ostravetere.an.it",
      "pec": "comune.ostravetere@emarche.it",
      "telefono": "+39 071/965053",
      "fax": "+39 071/964352",
      "coordinate": {
        "lat": 43.60371017456055,
        "lng": 13.05726432800293
      }
    },
    {
      "codice": "042037",
      "nome": "Poggio San Marcello",
      "nomeStraniero": null,
      "codiceCatastale": "G771",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "s.faleschini@comune.poggiosanmarcello.an.it",
      "pec": "comune.poggiosanmarcello@pec.it",
      "telefono": "+39 0731/813446",
      "fax": "+39 0731/813505",
      "coordinate": {
        "lat": 43.51158142089844,
        "lng": 13.077199935913086
      }
    },
    {
      "codice": "042038",
      "nome": "Polverigi",
      "nomeStraniero": null,
      "codiceCatastale": "G803",
      "cap": "60020",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "demografici.polverigi@unionecastelli.it",
      "pec": "comune.polverigi@pec.it",
      "telefono": "+39 071/909041",
      "fax": "+39 071/90904222",
      "coordinate": {
        "lat": 43.52534484863281,
        "lng": 13.394088745117188
      }
    },
    {
      "codice": "042040",
      "nome": "Rosora",
      "nomeStraniero": null,
      "codiceCatastale": "H575",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comune.rosora.an.it",
      "pec": "comune@pec.comune.rosora.an.it",
      "telefono": "+39 0731/813963",
      "fax": "+39 0731/813399",
      "coordinate": {
        "lat": 43.48285675048828,
        "lng": 13.069299697875977
      }
    },
    {
      "codice": "042041",
      "nome": "San Marcello",
      "nomeStraniero": null,
      "codiceCatastale": "H979",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comune.sanmarcello.an.it",
      "pec": "comune.sanmarcello@emarche.it",
      "telefono": "+39 0731267014",
      "fax": "+39 0731267506",
      "coordinate": {
        "lat": 43.57683181762695,
        "lng": 13.206199645996094
      }
    },
    {
      "codice": "042042",
      "nome": "San Paolo di Jesi",
      "nomeStraniero": null,
      "codiceCatastale": "I071",
      "cap": "60038",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comunesanpaolodijesi.it",
      "pec": "protocollo@pec.comunesanpaolodijesi.it",
      "telefono": "+39 0731/779088",
      "fax": "+39 0731/770113",
      "coordinate": {
        "lat": 43.45624923706055,
        "lng": 13.174361228942871
      }
    },
    {
      "codice": "042043",
      "nome": "Santa Maria Nuova",
      "nomeStraniero": null,
      "codiceCatastale": "I251",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "comune@comune.santamarianuova.an.it",
      "pec": "comune.santamarianuova@emarche.it",
      "telefono": "+39 0731/249714",
      "fax": "+39 0731/249797",
      "coordinate": {
        "lat": 43.4959602355957,
        "lng": 13.31071662902832
      }
    },
    {
      "codice": "042044",
      "nome": "Sassoferrato",
      "nomeStraniero": null,
      "codiceCatastale": "I461",
      "cap": "60041",
      "prefisso": "0732",
      "provincia": "Ancona",
      "email": "info@comune.sassoferrato.an.it",
      "pec": "comune.sassoferrato@emarche.it",
      "telefono": "+39 0732 9561",
      "fax": "+39 0732/956234",
      "coordinate": {
        "lat": 43.42747116088867,
        "lng": 12.85651969909668
      }
    },
    {
      "codice": "042045",
      "nome": "Senigallia",
      "nomeStraniero": null,
      "codiceCatastale": "I608",
      "cap": "60019",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comunicazione@comune.senigallia.an.it",
      "pec": "comune.senigallia@emarche.it",
      "telefono": "+39 071/6629321",
      "fax": "+39 071/6629303",
      "coordinate": {
        "lat": 43.71305465698242,
        "lng": 13.21833324432373
      }
    },
    {
      "codice": "042046",
      "nome": "Serra de' Conti",
      "nomeStraniero": null,
      "codiceCatastale": "I643",
      "cap": "60030",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "info@comune.serradeconti.an.it",
      "pec": "comune.serradeconti@emarche.it",
      "telefono": "+39 0731-871750",
      "fax": "+39 0731879290",
      "coordinate": {
        "lat": 43.54218292236328,
        "lng": 13.037725448608398
      }
    },
    {
      "codice": "042047",
      "nome": "Serra San Quirico",
      "nomeStraniero": null,
      "codiceCatastale": "I653",
      "cap": "60048",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "protocollo@serrasanquirico.pannet.it",
      "pec": "protocollo.serrasanquirico@emarche.it",
      "telefono": "+39 0731 8181",
      "fax": "+39 0731/818230",
      "coordinate": {
        "lat": 43.44765090942383,
        "lng": 13.01521110534668
      }
    },
    {
      "codice": "042048",
      "nome": "Sirolo",
      "nomeStraniero": null,
      "codiceCatastale": "I758",
      "cap": "60020",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "segreteria.sindaco@comune.sirolo.an.it",
      "pec": "comune.sirolo@emarche.it",
      "telefono": "+39 071/9330572",
      "fax": "+39 071/9331036",
      "coordinate": {
        "lat": 43.52602005004883,
        "lng": 13.616019248962402
      }
    },
    {
      "codice": "042049",
      "nome": "Staffolo",
      "nomeStraniero": null,
      "codiceCatastale": "I932",
      "cap": "60039",
      "prefisso": "0731",
      "provincia": "Ancona",
      "email": "anag@comune.staffolo.an.it",
      "pec": "comunestaffolo.an.segreteria@pa.postacertificata.gov.it",
      "telefono": "+39 0731/779218",
      "fax": "+39 0731/770402",
      "coordinate": {
        "lat": 43.432899475097656,
        "lng": 13.186038970947266
      }
    },
    {
      "codice": "042050",
      "nome": "Trecastelli",
      "nomeStraniero": null,
      "codiceCatastale": "M318",
      "cap": "60012",
      "prefisso": "071",
      "provincia": "Ancona",
      "email": "comune@comune.trecastelli.an.it",
      "pec": "comune.trecastelli@emarche.it",
      "telefono": "+39 071/7959211",
      "fax": "+39 071 7958454",
      "coordinate": {
        "lat": 43.67190170288086,
        "lng": 13.106300354003906
      }
    }
  ],
  "Vicenza": [
    {
      "codice": "024001",
      "nome": "Agugliaro",
      "nomeStraniero": null,
      "codiceCatastale": "A093",
      "cap": "36020",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.agugliaro.vi.it",
      "pec": "segreteria@pec.comune.agugliaro.vi.it",
      "telefono": "+39 0444/891003",
      "fax": "+39 0444/891389",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "024002",
      "nome": "Albettone",
      "nomeStraniero": null,
      "codiceCatastale": "A154",
      "cap": "36020",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "albettone.vi@cert.ip-veneto.net",
      "pec": "albettone.vi@cert.ip-veneto.net",
      "telefono": "+39 0444/790001",
      "fax": "+39 0444/790073",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "024003",
      "nome": "Alonte",
      "nomeStraniero": null,
      "codiceCatastale": "A220",
      "cap": "36045",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.alonte.vi.it",
      "pec": "protocollo.comune.alonte.vi@pecveneto.it",
      "telefono": "+39 0444439203",
      "fax": "+39 0444835229",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "024004",
      "nome": "Altavilla Vicentina",
      "nomeStraniero": null,
      "codiceCatastale": "A231",
      "cap": "36077",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.altavillavicentina.vi.it",
      "pec": "altavillavicentina.vi@cert.ip-veneto.net",
      "telefono": "+39 0444220311",
      "fax": "+39 0444370150",
      "coordinate": {
        "lat": 45.51639938354492,
        "lng": 11.459699630737305
      }
    },
    {
      "codice": "024005",
      "nome": "Altissimo",
      "nomeStraniero": null,
      "codiceCatastale": "A236",
      "cap": "36070",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.altissimo.vi.it",
      "pec": "protocollo.comune.altissimo.vi@pecveneto.it",
      "telefono": "+39 0444687613",
      "fax": "+39 0444687262",
      "coordinate": {
        "lat": 45.614723205566406,
        "lng": 11.250833511352539
      }
    },
    {
      "codice": "024006",
      "nome": "Arcugnano",
      "nomeStraniero": null,
      "codiceCatastale": "A377",
      "cap": "36057",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "info@comune.arcugnano.vi.it",
      "pec": "arcugnano.vi@cert.ip-veneto.net",
      "telefono": "+39 0444530622",
      "fax": "+39 0444240450",
      "coordinate": {
        "lat": 45.50041961669922,
        "lng": 11.535340309143066
      }
    },
    {
      "codice": "024007",
      "nome": "Arsiero",
      "nomeStraniero": null,
      "codiceCatastale": "A444",
      "cap": "36011",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.arsiero.vi.it",
      "pec": "comune.arsiero@pecveneto.it",
      "telefono": "+39 0445741305",
      "fax": "+39 0445741921",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "024008",
      "nome": "Arzignano",
      "nomeStraniero": null,
      "codiceCatastale": "A459",
      "cap": "36071",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.arzignano.vi.it",
      "pec": "arzignano.vi@cert.ip-veneto.net",
      "telefono": "+39 0444476551",
      "fax": "+39 0444476513",
      "coordinate": {
        "lat": 45.52027893066406,
        "lng": 11.339722633361816
      }
    },
    {
      "codice": "024009",
      "nome": "Asiago",
      "nomeStraniero": null,
      "codiceCatastale": "A465",
      "cap": "36012",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.asiago.vi.it",
      "pec": "asiago.vi@cert.ip-veneto.net",
      "telefono": "+39 0424600222",
      "fax": "+39 0424463885",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 11.516666412353516
      }
    },
    {
      "codice": "024010",
      "nome": "Asigliano Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "A467",
      "cap": "36020",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo.asigliano@unionecomunibassovicentino.it",
      "pec": "asiglianoveneto.vi@cert.ip-veneto.net",
      "telefono": "+39 0444872014",
      "fax": "+39 0444872287",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "024124",
      "nome": "Barbarano Mossano",
      "nomeStraniero": null,
      "codiceCatastale": "M401",
      "cap": "36048",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.barbaranomossano.vi.it",
      "pec": "protocollo@pec.comune.barbaranomossano.vi.it",
      "telefono": "0444/788300",
      "fax": "0444/886760",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "024012",
      "nome": "Bassano del Grappa",
      "nomeStraniero": null,
      "codiceCatastale": "A703",
      "cap": "36061",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "m.diprima@comune.bassano.vi.it",
      "pec": "protocollo.comune.bassanodelgrappa@pecveneto.it",
      "telefono": "+39 0424519250",
      "fax": "+39 0424521786",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 11.734167098999023
      }
    },
    {
      "codice": "024013",
      "nome": "Bolzano Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "A954",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.bolzanovicentino.vi.it",
      "pec": "comunebolzanovic@legalmail.it",
      "telefono": "+39 0444219411",
      "fax": "+39 0444351010",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "024014",
      "nome": "Breganze",
      "nomeStraniero": null,
      "codiceCatastale": "B132",
      "cap": "36042",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "andreazanin@comune.breganze.vi.it",
      "pec": "breganze.vi@cert.ip-veneto.net",
      "telefono": "+39 0445 869341",
      "fax": "+39 0445 869361",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 11.566666603088379
      }
    },
    {
      "codice": "024015",
      "nome": "Brendola",
      "nomeStraniero": null,
      "codiceCatastale": "B143",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.brendola.vi.it",
      "pec": "comune.brendola.vi@pecveneto.it",
      "telefono": "+39 0444400727",
      "fax": "+39 0444401099",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "024016",
      "nome": "Bressanvido",
      "nomeStraniero": null,
      "codiceCatastale": "B161",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "massimocoletto@comune.bressanvido.vi.it",
      "pec": "comune.bressanvido.vi@pecveneto.it",
      "telefono": "+39 0444660223",
      "fax": "+39 0444660785",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 11.633333206176758
      }
    },
    {
      "codice": "024017",
      "nome": "Brogliano",
      "nomeStraniero": null,
      "codiceCatastale": "B196",
      "cap": "36070",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.brogliano.vi.it",
      "pec": "prot.demografici.comune.brogliano.vi@pecveneto.it",
      "telefono": "+39 0445445671",
      "fax": "+39 0445947682",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "024018",
      "nome": "Caldogno",
      "nomeStraniero": null,
      "codiceCatastale": "B403",
      "cap": "36030",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "giuseppe.reniero@comune.caldogno.vi.it",
      "pec": "caldogno.vi@cert.ip-veneto.net",
      "telefono": "+39 0444901506",
      "fax": "+39 0444585414",
      "coordinate": {
        "lat": 45.61183547973633,
        "lng": 11.507580757141113
      }
    },
    {
      "codice": "024019",
      "nome": "Caltrano",
      "nomeStraniero": null,
      "codiceCatastale": "B433",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.caltrano.vi.it",
      "pec": "comune.caltrano.vi@pecveneto.it",
      "telefono": "+39 0445 891043",
      "fax": "+39 0445 390043",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "024020",
      "nome": "Calvene",
      "nomeStraniero": null,
      "codiceCatastale": "B441",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "demografici@comune.calvene.vi.it",
      "pec": "calvene.vi@cert.ip-veneto.net",
      "telefono": "+39 0445860534",
      "fax": "+39 0445861670",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 11.516666412353516
      }
    },
    {
      "codice": "024021",
      "nome": "Camisano Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "B485",
      "cap": "36043",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.camisanovicentino.vi.it",
      "pec": "comune.camisanovicentino@halleycert.it",
      "telefono": "+39 0444419911",
      "fax": "+39 0444419960",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.716666221618652
      }
    },
    {
      "codice": "024022",
      "nome": "Campiglia dei Berici",
      "nomeStraniero": null,
      "codiceCatastale": "B511",
      "cap": "36020",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comunecampiglia.vi.it",
      "pec": "campigliadeiberici.vi@cert.ip-veneto.net",
      "telefono": "+39 0444767196",
      "fax": "+39 0444866038",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "024024",
      "nome": "Carrè",
      "nomeStraniero": null,
      "codiceCatastale": "B835",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.carre.vi.it",
      "pec": "carre.vi@cert.ip-veneto.net",
      "telefono": "+39 0445891002",
      "fax": "+39 0445390135",
      "coordinate": {
        "lat": 45.74879837036133,
        "lng": 11.457793235778809
      }
    },
    {
      "codice": "024025",
      "nome": "Cartigliano",
      "nomeStraniero": null,
      "codiceCatastale": "B844",
      "cap": "36050",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "cartigliano@nsoft.it",
      "pec": "cartigliano.vi@cert.ip-veneto.net",
      "telefono": "+39 0424590234",
      "fax": "+39 0424828309",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 11.699999809265137
      }
    },
    {
      "codice": "024026",
      "nome": "Cassola",
      "nomeStraniero": null,
      "codiceCatastale": "C037",
      "cap": "36022",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.cassola.vi.it",
      "pec": "protocollo@pec.comune.cassola.vi.it",
      "telefono": "+39 0424530243",
      "fax": "+39 0424533523",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "024027",
      "nome": "Castegnero",
      "nomeStraniero": null,
      "codiceCatastale": "C056",
      "cap": "36020",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.castegnero.vi.it",
      "pec": "castegnero.vi@cert.ip-veneto.net",
      "telefono": "+39 0444738763",
      "fax": "+39 0444730050",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "024028",
      "nome": "Castelgomberto",
      "nomeStraniero": null,
      "codiceCatastale": "C119",
      "cap": "36070",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.castelgomberto.vi.it",
      "pec": "castelgomberto.vi@cert.ip-veneto.net",
      "telefono": "+39 0445424415",
      "fax": "+39 0445941577",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "024029",
      "nome": "Chiampo",
      "nomeStraniero": null,
      "codiceCatastale": "C605",
      "cap": "36072",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "diego.martini@comune.chiampo.vi.it",
      "pec": "protocollo.comune.chiampo.vi@pecveneto.it",
      "telefono": "+39 0444475275",
      "fax": "+39 0444624416",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "024030",
      "nome": "Chiuppano",
      "nomeStraniero": null,
      "codiceCatastale": "C650",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.chiuppano.vi.it",
      "pec": "chiuppano.vi@cert.ip-veneto.net",
      "telefono": "+39 0445891062",
      "fax": "+39 0445390089",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "024032",
      "nome": "Cogollo del Cengio",
      "nomeStraniero": null,
      "codiceCatastale": "C824",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "protocollo@comune.cogollodelcengio.vi.it",
      "pec": "protocollo.comune.cogollodelcengio.vi@pecveneto.it",
      "telefono": "+39 0445805070",
      "fax": "+39 0445 805080",
      "coordinate": {
        "lat": 45.78559875488281,
        "lng": 11.426899909973145
      }
    },
    {
      "codice": "024126",
      "nome": "Colceresa",
      "nomeStraniero": null,
      "codiceCatastale": "M426",
      "cap": "36064",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.colceresa.vi.it",
      "pec": "comune.colceresa.vi@cert.ip-veneto.net",
      "telefono": "+39 0424 708120",
      "fax": "+39 0424 418350",
      "coordinate": {
        "lat": 45.719444274902344,
        "lng": 11.60777759552002
      }
    },
    {
      "codice": "024034",
      "nome": "Cornedo Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "D020",
      "cap": "36073",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "admins@mail.comune.cornedo-vicentino.vi.it",
      "pec": "cornedovicentino.vi@cert.ip-veneto.net",
      "telefono": "+39 0445450409",
      "fax": "+39 0445446098",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "024035",
      "nome": "Costabissara",
      "nomeStraniero": null,
      "codiceCatastale": "D107",
      "cap": "36030",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "info@comune.costabissara.vi.it",
      "pec": "costabissara.vi@cert.ip-veneto.net",
      "telefono": "+39 0444290635",
      "fax": "+39 0444970664",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "024036",
      "nome": "Creazzo",
      "nomeStraniero": null,
      "codiceCatastale": "D136",
      "cap": "36051",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": null,
      "pec": "creazzo.vi@cert.ip-veneto.net",
      "telefono": "+39 044340149",
      "fax": "+39 0444338299",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "024037",
      "nome": "Crespadoro",
      "nomeStraniero": null,
      "codiceCatastale": "D156",
      "cap": "36070",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.crespadoro.vi.it",
      "pec": "comune.crespadoro@pec.it",
      "telefono": "+39 0444429005",
      "fax": "+39 0444429297",
      "coordinate": {
        "lat": 45.61916732788086,
        "lng": 11.22611141204834
      }
    },
    {
      "codice": "024038",
      "nome": "Dueville",
      "nomeStraniero": null,
      "codiceCatastale": "D379",
      "cap": "36031",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "nnardi@comune.dueville.vi.it",
      "pec": "dueville.vi@cert.ip-veneto.net",
      "telefono": "+39 0444367265",
      "fax": "+39 0444367382",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "024039",
      "nome": "Enego",
      "nomeStraniero": null,
      "codiceCatastale": "D407",
      "cap": "36052",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.enego.vi.it",
      "pec": "enego.vi@cert.ip-veneto.net",
      "telefono": "+39 0424490131",
      "fax": "+39 0424490517",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 11.716666221618652
      }
    },
    {
      "codice": "024040",
      "nome": "Fara Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "D496",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "ragio@comune.faravicentino.vi.it",
      "pec": "faravicentino.vi@cert.ip-veneto.net",
      "telefono": "+39 0445375055",
      "fax": "+39 0445375030",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "024041",
      "nome": "Foza",
      "nomeStraniero": null,
      "codiceCatastale": "D750",
      "cap": "36010",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.foza.vi.it",
      "pec": "foza.vi@cert.ip-veneto.net",
      "telefono": "+39 0424698003",
      "fax": "+39 0424698281",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 11.633333206176758
      }
    },
    {
      "codice": "024042",
      "nome": "Gallio",
      "nomeStraniero": null,
      "codiceCatastale": "D882",
      "cap": "36032",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "comune@comune.gallio.vi.it",
      "pec": "gallio.vi@cert.ip-veneto.net",
      "telefono": "+39 0424447920",
      "fax": "+39 0424447922",
      "coordinate": {
        "lat": 45.883331298828125,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "024043",
      "nome": "Gambellara",
      "nomeStraniero": null,
      "codiceCatastale": "D897",
      "cap": "36053",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segretariocomunale@comune.gambellara.vi.it",
      "pec": "gambellara.vi@cert.ip-veneto.net",
      "telefono": "+39 0444445272",
      "fax": "+39 0444444651",
      "coordinate": {
        "lat": 45.46250915527344,
        "lng": 11.339839935302734
      }
    },
    {
      "codice": "024044",
      "nome": "Gambugliano",
      "nomeStraniero": null,
      "codiceCatastale": "D902",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "francesco.tornambe@comune.gambugliano.vi.it",
      "pec": "comune.gambugliano.vi@pecveneto.it",
      "telefono": "+39 0444951601",
      "fax": "+39 0444951630",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "024046",
      "nome": "Grisignano di Zocco",
      "nomeStraniero": null,
      "codiceCatastale": "E184",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "statocivile@comune.grisignano.vi.it",
      "pec": "grisignanodizocco.vi@cert.ip-veneto.net",
      "telefono": "+39 0444614534",
      "fax": "+39 0444414355",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.699999809265137
      }
    },
    {
      "codice": "024047",
      "nome": "Grumolo delle Abbadesse",
      "nomeStraniero": null,
      "codiceCatastale": "E226",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.grumolodelleabbadesse.vi.it",
      "pec": "grumolodelleabbadesse.vi@cert.ip-veneto.net",
      "telefono": "+39 0444265011",
      "fax": "+39 0444582570",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "024048",
      "nome": "Isola Vicentina",
      "nomeStraniero": null,
      "codiceCatastale": "E354",
      "cap": "36033",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.isola-vicentina.vi.it",
      "pec": "isolavicentina.vi@cert.ip-veneto.net",
      "telefono": "+39 0444599114",
      "fax": "+39 0444975450",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "024049",
      "nome": "Laghi",
      "nomeStraniero": null,
      "codiceCatastale": "E403",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.laghi.vi.it",
      "pec": "laghi.vi@cert.ip-veneto.net",
      "telefono": "+39 0445714174",
      "fax": "+39 0445712084",
      "coordinate": {
        "lat": 45.823612213134766,
        "lng": 11.273056030273438
      }
    },
    {
      "codice": "024050",
      "nome": "Lastebasse",
      "nomeStraniero": null,
      "codiceCatastale": "E465",
      "cap": "36040",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.lastebasse.vi.it",
      "pec": "lastebasse.vi@cert.ip-veneto.net",
      "telefono": "+39 0445746022",
      "fax": "+39 0445746200",
      "coordinate": {
        "lat": 45.91555404663086,
        "lng": 11.27138900756836
      }
    },
    {
      "codice": "024051",
      "nome": "Longare",
      "nomeStraniero": null,
      "codiceCatastale": "E671",
      "cap": "36023",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.longare.vi.it",
      "pec": "longare.vi@cert.ip-veneto.net",
      "telefono": "+39 0444555012",
      "fax": "+39 0444953441",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "024052",
      "nome": "Lonigo",
      "nomeStraniero": null,
      "codiceCatastale": "E682",
      "cap": "36045",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "ivano.chilese@comune.lonigo.vi.it",
      "pec": "lonigo.vi@cert.ip-veneto.net",
      "telefono": "+39 0444720245",
      "fax": "+39 0444834887",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "024053",
      "nome": "Lugo di Vicenza",
      "nomeStraniero": null,
      "codiceCatastale": "E731",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "paola.ranzolin@comune.lugo.vi.it",
      "pec": "lugodivicenza.vi@cert.ip-veneto.net",
      "telefono": "+39 0445327266",
      "fax": "+39 0445861622",
      "coordinate": {
        "lat": 45.75,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "024127",
      "nome": "Lusiana Conco",
      "nomeStraniero": null,
      "codiceCatastale": "M427",
      "cap": "36046",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "maddalena.maino@comune.lusianaconco.vi.it",
      "pec": "comune.lusianaconco.vi@pecveneto.it",
      "telefono": "0424406009 - 0424700301",
      "fax": "0424407349",
      "coordinate": {
        "lat": 45.800899505615234,
        "lng": 11.608099937438965
      }
    },
    {
      "codice": "024055",
      "nome": "Malo",
      "nomeStraniero": null,
      "codiceCatastale": "E864",
      "cap": "36034",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "francesca.buzzolan@comune.malo.vi.it",
      "pec": "malo.vi@cert.ip-veneto.net",
      "telefono": "+39 0445585211",
      "fax": "+39 0445585201",
      "coordinate": {
        "lat": 45.65819549560547,
        "lng": 11.404694557189941
      }
    },
    {
      "codice": "024056",
      "nome": "Marano Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "E912",
      "cap": "36035",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "ced@comune.marano.vi.it",
      "pec": "maranovicentino.vi@cert.ip-veneto.net",
      "telefono": "+39 0445598843",
      "fax": "+39 0445560101",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "024057",
      "nome": "Marostica",
      "nomeStraniero": null,
      "codiceCatastale": "E970",
      "cap": "36063",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "protocollogenerale@comune.marostica.vi.it",
      "pec": "marostica.vi@cert.ip-veneto.net",
      "telefono": "+39 0424479200",
      "fax": "+39 042473549",
      "coordinate": {
        "lat": 45.74555587768555,
        "lng": 11.655278205871582
      }
    },
    {
      "codice": "024063",
      "nome": "Monte di Malo",
      "nomeStraniero": null,
      "codiceCatastale": "F486",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "servizigenerali@comune.montedimalo.vi.it",
      "pec": "montedimalo.vi@cert.ip-veneto.net",
      "telefono": "+39 0445584064",
      "fax": "+39 0445589660",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "024060",
      "nome": "Montebello Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "F442",
      "cap": "36054",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "zoia@comune.montebello.vi.it",
      "pec": "montebellovicentino.vi@cert.ip-veneto.net",
      "telefono": "+39 0444649275",
      "fax": "+39 0444440111",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "024061",
      "nome": "Montecchio Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "F464",
      "cap": "36075",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "demografici@comune.montecchio-maggiore.vi.it",
      "pec": "montecchiomaggiore.vi@cert.ip-veneto.net",
      "telefono": "+39 0444705753",
      "fax": "-",
      "coordinate": {
        "lat": 45.50368881225586,
        "lng": 11.411999702453613
      }
    },
    {
      "codice": "024062",
      "nome": "Montecchio Precalcino",
      "nomeStraniero": null,
      "codiceCatastale": "F465",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.montecchioprecalcino.vi.it",
      "pec": "comunemontecchioprecalcino.vi@legalmail.it",
      "telefono": "+39 0445864243",
      "fax": "+39 0445334450",
      "coordinate": {
        "lat": 45.66577911376953,
        "lng": 11.563302040100098
      }
    },
    {
      "codice": "024064",
      "nome": "Montegalda",
      "nomeStraniero": null,
      "codiceCatastale": "F514",
      "cap": "36047",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.montegalda.vi.it",
      "pec": "comune.montegalda.vi@pecveneto.it",
      "telefono": "+39 0444736411",
      "fax": "+39 0444636567",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 11.683333396911621
      }
    },
    {
      "codice": "024065",
      "nome": "Montegaldella",
      "nomeStraniero": null,
      "codiceCatastale": "F515",
      "cap": "36047",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "info@comune.montegaldella.vi.it",
      "pec": "montegaldella.vi@cert.ip-veneto.net",
      "telefono": "+39 0444635151",
      "fax": "+39 0444635124",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "024066",
      "nome": "Monteviale",
      "nomeStraniero": null,
      "codiceCatastale": "F662",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.monteviale.vi.it",
      "pec": "protocollo.comune.monteviale.vi@pecveneto.it",
      "telefono": "+39 0444552013",
      "fax": "+39 0444552027",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "024067",
      "nome": "Monticello Conte Otto",
      "nomeStraniero": null,
      "codiceCatastale": "F675",
      "cap": "36010",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "rosa.giacomin@comune.monticello.vi.it",
      "pec": "monticelloconteotto.vi@cert.ip-veneto.net",
      "telefono": "+39 0444947523",
      "fax": "+39 0444947510",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "024068",
      "nome": "Montorso Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "F696",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "ufficiotecnico@comune.montorsovicentino.vi.it",
      "pec": "montorsovicentino.vi@cert.ip-veneto.net",
      "telefono": "+39 0444685969",
      "fax": "+39 0444484057",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "024070",
      "nome": "Mussolente",
      "nomeStraniero": null,
      "codiceCatastale": "F829",
      "cap": "36065",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.mussolente.vi.it",
      "pec": "protocollo@pec.comune.mussolente.vi.it",
      "telefono": "+39 0424578403",
      "fax": "+39 0424878344",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 11.816666603088379
      }
    },
    {
      "codice": "024071",
      "nome": "Nanto",
      "nomeStraniero": null,
      "codiceCatastale": "F838",
      "cap": "36024",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "tecnico@comune.nanto.vi.it",
      "pec": "nanto.vi@cert.ip-veneto.net",
      "telefono": "+39 0444738071",
      "fax": "+39 0444639065",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "024072",
      "nome": "Nogarole Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "F922",
      "cap": "36070",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@comune.nogarolevicentino.vi.it",
      "pec": "comune.nogarolevicentino@stpec.it",
      "telefono": "+39 0444427050",
      "fax": "+39 0444420959",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "024073",
      "nome": "Nove",
      "nomeStraniero": null,
      "codiceCatastale": "F957",
      "cap": "36055",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.nove.vi.it",
      "pec": "segreteria.comune.nove.vi@pecveneto.it",
      "telefono": "+39 0424597550",
      "fax": "+39 0424828300",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 11.683333396911621
      }
    },
    {
      "codice": "024074",
      "nome": "Noventa Vicentina",
      "nomeStraniero": null,
      "codiceCatastale": "F964",
      "cap": "36025",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "p.brogin@noventavi.it",
      "pec": "comunenoventavicentina@cert.legalmail.it",
      "telefono": "+39 0444788515",
      "fax": "+39 0444788540",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "024075",
      "nome": "Orgiano",
      "nomeStraniero": null,
      "codiceCatastale": "G095",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "tributi@comune.orgiano.vi.it",
      "pec": "orgiano.vi@cert.ip-veneto.net",
      "telefono": "+39 0444775126",
      "fax": "+39 0444874627",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "024076",
      "nome": "Pedemonte",
      "nomeStraniero": null,
      "codiceCatastale": "G406",
      "cap": "36040",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "u.tecnico@comune.pedemonte.vi.it",
      "pec": "pedemonte.vi@cert.ip-veneto.net",
      "telefono": "+39 0445747017",
      "fax": "+39 0445747188",
      "coordinate": {
        "lat": 45.908302307128906,
        "lng": 11.31283187866211
      }
    },
    {
      "codice": "024077",
      "nome": "Pianezze",
      "nomeStraniero": null,
      "codiceCatastale": "G560",
      "cap": "36060",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "ragioneria@comune.pianezze.vi.it",
      "pec": "protocollo.pianezze.vi@cert.ip-veneto.net",
      "telefono": "+39 042472323",
      "fax": "+39 0424470387",
      "coordinate": {
        "lat": 45.73999786376953,
        "lng": 11.62674617767334
      }
    },
    {
      "codice": "024078",
      "nome": "Piovene Rocchette",
      "nomeStraniero": null,
      "codiceCatastale": "G694",
      "cap": "36013",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "ced@comune.piovene-rocchette.vi.it",
      "pec": "piovenerocchette.vi@cert.ip-veneto.net",
      "telefono": "+39 0445696416",
      "fax": "+39 0445696444",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "024079",
      "nome": "Pojana Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "G776",
      "cap": "36026",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "comune@pojanamaggiore.it",
      "pec": "comune.pojanamaggiore.vi@pecveneto.it",
      "telefono": "+39 0444898033",
      "fax": "+39 0444898783",
      "coordinate": {
        "lat": 45.29039001464844,
        "lng": 11.5024995803833
      }
    },
    {
      "codice": "024080",
      "nome": "Posina",
      "nomeStraniero": null,
      "codiceCatastale": "G931",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.posina.vi.it",
      "pec": "posina.vi@cert.ip-veneto.net",
      "telefono": "+39 0445748013",
      "fax": "+39 0445748047",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "024081",
      "nome": "Pove del Grappa",
      "nomeStraniero": null,
      "codiceCatastale": "G943",
      "cap": "36020",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.pove.vi.it",
      "pec": "povedelgrappa.vi@cert.ip-veneto.net",
      "telefono": "+39 042480333",
      "fax": "+39 0424809088",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 11.733333587646484
      }
    },
    {
      "codice": "024082",
      "nome": "Pozzoleone",
      "nomeStraniero": null,
      "codiceCatastale": "G957",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "matteo.marcon@comune.pozzoleone.vi.it",
      "pec": "comune.pozzoleone.vi@pecveneto.it",
      "telefono": "+39 0444462203",
      "fax": "+39 0444462490",
      "coordinate": {
        "lat": 45.649444580078125,
        "lng": 11.674444198608398
      }
    },
    {
      "codice": "024083",
      "nome": "Quinto Vicentino",
      "nomeStraniero": null,
      "codiceCatastale": "H134",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "demografici@comune.quintovicentino.vi.it",
      "pec": "quintovicentino.vi@cert.ip-veneto.it",
      "telefono": "+39 0444584211",
      "fax": "+39 0444357388",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "024084",
      "nome": "Recoaro Terme",
      "nomeStraniero": null,
      "codiceCatastale": "H214",
      "cap": "36076",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.recoaroterme.vi.it",
      "pec": "segreteria.comune.recoaroterme.vi@pecveneto.it",
      "telefono": "+39 0445793332",
      "fax": "+39 0445793309",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "024085",
      "nome": "Roana",
      "nomeStraniero": null,
      "codiceCatastale": "H361",
      "cap": "36010",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "servizidemografici@comune.roana.vi.it",
      "pec": "comune.roana.vi@pecveneto.it",
      "telefono": "+39 0424692035",
      "fax": "+39 0424692019",
      "coordinate": {
        "lat": 45.87638854980469,
        "lng": 11.462778091430664
      }
    },
    {
      "codice": "024086",
      "nome": "Romano d'Ezzelino",
      "nomeStraniero": null,
      "codiceCatastale": "H512",
      "cap": "36060",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "info@comune.romano.vi.it",
      "pec": "mail@pec.comune.romano.vi.it",
      "telefono": "+39 0424818650",
      "fax": "+39 0424818644",
      "coordinate": {
        "lat": 45.77333450317383,
        "lng": 11.777777671813965
      }
    },
    {
      "codice": "024087",
      "nome": "Rosà",
      "nomeStraniero": null,
      "codiceCatastale": "H556",
      "cap": "36027",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "vito.pacillo@comune.rosa.vi.it",
      "pec": "protocollo.comune.rosa.vi@pecveneto.it",
      "telefono": "+39 0424584141",
      "fax": "+39 0424584165",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "024088",
      "nome": "Rossano Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "H580",
      "cap": "36028",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.rossano.vi.it",
      "pec": "rossanoveneto.vi@cert.ip-veneto.net",
      "telefono": "+39 0424547111",
      "fax": "+39 042484935",
      "coordinate": {
        "lat": 45.7066650390625,
        "lng": 11.806944847106934
      }
    },
    {
      "codice": "024089",
      "nome": "Rotzo",
      "nomeStraniero": null,
      "codiceCatastale": "H594",
      "cap": "36010",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "info@comune.rotzo.vi.it",
      "pec": "servizidemografici.comune.rotzo.vi@pecveneto.it",
      "telefono": "+39 0424691003",
      "fax": "+39 0424691276",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "024090",
      "nome": "Salcedo",
      "nomeStraniero": null,
      "codiceCatastale": "F810",
      "cap": "36040",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.salcedo.vi.it",
      "pec": "demografici.comune.salcedo.vi@pecveneto.it",
      "telefono": "+39 0445888241",
      "fax": "+39 0445888338",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 11.566666603088379
      }
    },
    {
      "codice": "024094",
      "nome": "San Pietro Mussolino",
      "nomeStraniero": null,
      "codiceCatastale": "I117",
      "cap": "36070",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.sanpietromussolino.vi.it",
      "pec": "elettorale.comune.sanpietromussolino.vi@pec",
      "telefono": "+39 0444489400",
      "fax": "+39 0444687025",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 11.25
      }
    },
    {
      "codice": "024096",
      "nome": "San Vito di Leguzzano",
      "nomeStraniero": null,
      "codiceCatastale": "I401",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.sanvitodileguzzano.vi.it",
      "pec": "sanvitodileguzzano.vi@cert.ip-veneto.net",
      "telefono": "+39 0445671642",
      "fax": "+39 0445512254",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "024091",
      "nome": "Sandrigo",
      "nomeStraniero": null,
      "codiceCatastale": "H829",
      "cap": "36066",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "admin@comune.sandrigo.vi.it",
      "pec": "sandrigo.vi@cert.ip-veneto.net",
      "telefono": "+39 0444461603",
      "fax": "+39 0444461610",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 11.600000381469727
      }
    },
    {
      "codice": "024095",
      "nome": "Santorso",
      "nomeStraniero": null,
      "codiceCatastale": "I353",
      "cap": "36014",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "elisa.sperotto@comune.santorso.vi.it",
      "pec": "santorso.vi@cert.ip-veneto.net",
      "telefono": "+39 0445649517",
      "fax": "+39 0445649513",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "024097",
      "nome": "Sarcedo",
      "nomeStraniero": null,
      "codiceCatastale": "I425",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.sarcedo.vi.it",
      "pec": "sarcedo.vi@cert.ip-veneto.net",
      "telefono": "+39 0445884224",
      "fax": "+39 0445885125",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "024098",
      "nome": "Sarego",
      "nomeStraniero": null,
      "codiceCatastale": "I430",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "protocollo@sarego.gov.it",
      "pec": "protocollo@pec.sarego.gov.it",
      "telefono": "+39 0444830744",
      "fax": "+39 0444835483",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "024099",
      "nome": "Schiavon",
      "nomeStraniero": null,
      "codiceCatastale": "I527",
      "cap": "36060",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "uff.tecnico@comune.schiavon.vi.it",
      "pec": "protocollo.comune.schiavon.vi@pecveneto.it",
      "telefono": "+39 0444665003",
      "fax": "+39 0444665263",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 11.649999618530273
      }
    },
    {
      "codice": "024100",
      "nome": "Schio",
      "nomeStraniero": null,
      "codiceCatastale": "I531",
      "cap": "36015",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@altovicentino.net",
      "pec": "schio.vi@cert.ip-veneto.net",
      "telefono": "+39 0445610511",
      "fax": "+39 0445610540",
      "coordinate": {
        "lat": 45.71111297607422,
        "lng": 11.355555534362793
      }
    },
    {
      "codice": "024101",
      "nome": "Solagna",
      "nomeStraniero": null,
      "codiceCatastale": "I783",
      "cap": "36020",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "segreteria@comune.solagna.vi.it",
      "pec": "solagna.vi@cert.ip-veneto.net",
      "telefono": "+39 0424816003",
      "fax": "+39 0424558045",
      "coordinate": {
        "lat": 45.81672286987305,
        "lng": 11.718170166015625
      }
    },
    {
      "codice": "024102",
      "nome": "Sossano",
      "nomeStraniero": null,
      "codiceCatastale": "I867",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "f.faltracco@comune.sossano.vi.it",
      "pec": "comune.sossano.vi@legalmail.it",
      "telefono": "+39 0444885904",
      "fax": "+39 0444888640",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 11.516666412353516
      }
    },
    {
      "codice": "024103",
      "nome": "Sovizzo",
      "nomeStraniero": null,
      "codiceCatastale": "I879",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "antonella.vitale@comune.sovizzo.vi.it",
      "pec": "sovizzo.vi@cert.ip-veneto.net",
      "telefono": "+39 0444180232",
      "fax": "+39 04441802135",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "024104",
      "nome": "Tezze sul Brenta",
      "nomeStraniero": null,
      "codiceCatastale": "L156",
      "cap": "36056",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "info@comune.tezze.vi.it",
      "pec": "tezzesulbrenta.vi@cert.ip-veneto.net",
      "telefono": "+39 0424535911",
      "fax": "+39 0424535961",
      "coordinate": {
        "lat": 45.68617630004883,
        "lng": 11.704183578491211
      }
    },
    {
      "codice": "024105",
      "nome": "Thiene",
      "nomeStraniero": null,
      "codiceCatastale": "L157",
      "cap": "36016",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.thiene.vi.it",
      "pec": "thiene.vi@cert.ip-veneto.net",
      "telefono": "+39 0445804911",
      "fax": "+39 0445804999",
      "coordinate": {
        "lat": 45.70722198486328,
        "lng": 11.47861099243164
      }
    },
    {
      "codice": "024106",
      "nome": "Tonezza del Cimone",
      "nomeStraniero": null,
      "codiceCatastale": "D717",
      "cap": "36040",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.tonezzadelcimone.vi.it",
      "pec": "tonezzadelcimone.vi@cert.ip-veneto.net",
      "telefono": "+39 0445749032",
      "fax": "+39 0445749504",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "024107",
      "nome": "Torrebelvicino",
      "nomeStraniero": null,
      "codiceCatastale": "L248",
      "cap": "36036",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.torrebelvicino.vi.it",
      "pec": "torrebelvicino.vi@cert.ip-veneto.net",
      "telefono": "+39 0445662111",
      "fax": "+39 0445661822",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "024108",
      "nome": "Torri di Quartesolo",
      "nomeStraniero": null,
      "codiceCatastale": "L297",
      "cap": "36040",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "plechero@comune.torridiquartesolo.vi.it",
      "pec": "torridiquartesolo.vi@cert.ip-veneto.net",
      "telefono": "+39 0444250201",
      "fax": "+39 0444250251",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "024110",
      "nome": "Trissino",
      "nomeStraniero": null,
      "codiceCatastale": "L433",
      "cap": "36070",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "protocollo@comune.trissino.vi.it",
      "pec": "trissino.vi@cert.ip-veneto.net",
      "telefono": "+39 0445499311",
      "fax": "+39 0445499312",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "024123",
      "nome": "Val Liona",
      "nomeStraniero": null,
      "codiceCatastale": "M384",
      "cap": "36044",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "anagrafe@comune.valliona.vi.it",
      "pec": "comune.valliona.vi@pecveneto.it",
      "telefono": "+39 0444889522",
      "fax": "+39 0444889469",
      "coordinate": {
        "lat": 45.40999984741211,
        "lng": 11.474200248718262
      }
    },
    {
      "codice": "024125",
      "nome": "Valbrenta",
      "nomeStraniero": null,
      "codiceCatastale": "M423",
      "cap": "36029",
      "prefisso": "0424",
      "provincia": "Vicenza",
      "email": "info@comune.valbrenta.vi.it",
      "pec": "comune.valbrenta@legamail.it",
      "telefono": "042499905",
      "fax": null,
      "coordinate": {
        "lat": 45.8603630065918,
        "lng": 11.660666465759277
      }
    },
    {
      "codice": "024111",
      "nome": "Valdagno",
      "nomeStraniero": null,
      "codiceCatastale": "L551",
      "cap": "36078",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.valdagno.vi.it",
      "pec": "comune.valdagno@legalmail.it",
      "telefono": "+39 0445428212",
      "fax": "+39 0445401954",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 11.300000190734863
      }
    },
    {
      "codice": "024112",
      "nome": "Valdastico",
      "nomeStraniero": null,
      "codiceCatastale": "L554",
      "cap": "36040",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "uffsegreteria@comune.valdastico.vi.it",
      "pec": "anagrafe.comune.valdastico.vi@pecveneto.it",
      "telefono": "+39 0445745003",
      "fax": "+39 0445745566",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "024113",
      "nome": "Valli del Pasubio",
      "nomeStraniero": null,
      "codiceCatastale": "L624",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.vallidelpasubio.vi.it",
      "pec": "vallidelpasubio.vi@cert.ip-veneto.net",
      "telefono": "+39 0445590400",
      "fax": "+39 0445590280",
      "coordinate": {
        "lat": 45.75,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "024115",
      "nome": "Velo d'Astico",
      "nomeStraniero": null,
      "codiceCatastale": "L723",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "velodastico.comune@pec.altovicentino.it",
      "pec": "velodastico.comune@pec.altovicentino.it",
      "telefono": "+39 0445740898",
      "fax": "+39 0445741818",
      "coordinate": {
        "lat": 45.78929901123047,
        "lng": 11.36870002746582
      }
    },
    {
      "codice": "024116",
      "nome": "Vicenza",
      "nomeStraniero": null,
      "codiceCatastale": "L840",
      "cap": "36100",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "msaugo@comune.vicenza.it",
      "pec": "vicenza@cert.comune.vicenza.it",
      "telefono": "+39 0444221374",
      "fax": "+39 0444221329",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "024117",
      "nome": "Villaga",
      "nomeStraniero": null,
      "codiceCatastale": "L952",
      "cap": "36021",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "demografici@comunevillaga.vi.it",
      "pec": "villaga.vi@cert.ip-veneto.net",
      "telefono": "+39 0444886037",
      "fax": "+39 0444886731",
      "coordinate": {
        "lat": 45.4024772644043,
        "lng": 11.533720970153809
      }
    },
    {
      "codice": "024118",
      "nome": "Villaverla",
      "nomeStraniero": null,
      "codiceCatastale": "M032",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "segreteria@comune.villaverla.vi.it",
      "pec": "villaverla.vi@cert.ip-veneto.net",
      "telefono": "+39 0445355520",
      "fax": "+39 0445355599",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 11.5
      }
    },
    {
      "codice": "024119",
      "nome": "Zanè",
      "nomeStraniero": null,
      "codiceCatastale": "M145",
      "cap": "36010",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "ufficio.anagrafe@comune.zane.vi.it",
      "pec": "comune.zane.demografici@pec.altovicentino.it",
      "telefono": "+39 0445385118",
      "fax": "+39 0445385100",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "024120",
      "nome": "Zermeghedo",
      "nomeStraniero": null,
      "codiceCatastale": "M170",
      "cap": "36050",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "segreteria@comune.zermeghedo.vi.it",
      "pec": "comune.zermeghedo.vi@pecveneto.it",
      "telefono": "+39 0444685413",
      "fax": "+39 0444686260",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "024121",
      "nome": "Zovencedo",
      "nomeStraniero": null,
      "codiceCatastale": "M194",
      "cap": "36020",
      "prefisso": "0444",
      "provincia": "Vicenza",
      "email": "info@comune.zovencedo.vi.it",
      "pec": "zovencedo.vi@cert.ip-veneto.net",
      "telefono": "+39 0444893065",
      "fax": "+39 0444893389",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 11.5
      }
    },
    {
      "codice": "024122",
      "nome": "Zugliano",
      "nomeStraniero": null,
      "codiceCatastale": "M199",
      "cap": "36030",
      "prefisso": "0445",
      "provincia": "Vicenza",
      "email": "info@comune.zugliano.vi.it",
      "pec": "zugliano.vi@cert.ip-veneto.net",
      "telefono": "+39 0445331115",
      "fax": "+39 0445330029",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 11.516666412353516
      }
    }
  ],
  "Udine": [
    {
      "codice": "030001",
      "nome": "Aiello del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "A103",
      "cap": "33041",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "protocollo@comune.aiellodelfriuli.ud.it",
      "pec": "comune.aiellodelfriuli@certgov.fvg.it",
      "telefono": "+39 0431/99021",
      "fax": "+39 0431/999934",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 13.366666793823242
      }
    },
    {
      "codice": "030002",
      "nome": "Amaro",
      "nomeStraniero": null,
      "codiceCatastale": "A254",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "sindaco@com-amaro.regione.fvg.it",
      "pec": "comune.amaro@certgov.fvg.it",
      "telefono": "+39 0433/94056",
      "fax": "+39 0433/94345",
      "coordinate": {
        "lat": 46.375,
        "lng": 13.096388816833496
      }
    },
    {
      "codice": "030003",
      "nome": "Ampezzo",
      "nomeStraniero": null,
      "codiceCatastale": "A267",
      "cap": "33021",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "amministrativo@comune.ampezzo.ud.it",
      "pec": "comune.ampezzo@certgov.fvg.it",
      "telefono": "+39 0433/80050",
      "fax": "+39 0433/80639",
      "coordinate": {
        "lat": 46.41708755493164,
        "lng": 12.790273666381836
      }
    },
    {
      "codice": "030004",
      "nome": "Aquileia",
      "nomeStraniero": null,
      "codiceCatastale": "A346",
      "cap": "33051",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segretario@com-aquileia.regione.fvg.it",
      "pec": "comune.aquileia@certgov.fvg.it",
      "telefono": "+39 0431/916911",
      "fax": "+39 0431/91044",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 13.366666793823242
      }
    },
    {
      "codice": "030005",
      "nome": "Arta Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A447",
      "cap": "33022",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "demografico@com-arta-terme.regione.fvg.it",
      "pec": "comune.artaterme@certgov.fvg.it",
      "telefono": "+39 0433/92037",
      "fax": "+39 0433/92004",
      "coordinate": {
        "lat": 46.472381591796875,
        "lng": 13.0266752243042
      }
    },
    {
      "codice": "030006",
      "nome": "Artegna",
      "nomeStraniero": null,
      "codiceCatastale": "A448",
      "cap": "33011",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "sindaco@com-artegna.regione.fvg.it",
      "pec": "comune.artegna@certgov.fvg.it",
      "telefono": "+39 0432/977811",
      "fax": "+39 0432/977895",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 13.149999618530273
      }
    },
    {
      "codice": "030007",
      "nome": "Attimis",
      "nomeStraniero": null,
      "codiceCatastale": "A491",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.attimis.ud.it",
      "pec": "comune.attimis@certgov.fvg.it",
      "telefono": "+39 0432789028",
      "fax": "+39 0432789080",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 13.300000190734863
      }
    },
    {
      "codice": "030008",
      "nome": "Bagnaria Arsa",
      "nomeStraniero": null,
      "codiceCatastale": "A553",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "comune@comune.bagnariaarsa.ud.it",
      "pec": "comune.bagnariaarsa@legalmail.it",
      "telefono": "+39 0432/927285",
      "fax": "+39 0432/920283",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 13.283333778381348
      }
    },
    {
      "codice": "030009",
      "nome": "Basiliano",
      "nomeStraniero": null,
      "codiceCatastale": "A700",
      "cap": "33031",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.basiliano.ud.it",
      "pec": "comune.basiliano@certgov.fvg.it",
      "telefono": "+39 0432/838113",
      "fax": "+39 0432/838110",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "030010",
      "nome": "Bertiolo",
      "nomeStraniero": null,
      "codiceCatastale": "A810",
      "cap": "33032",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.bertiolo.ud.it",
      "pec": "comune.bertiolo@certgov.fvg.it",
      "telefono": "+39 0432/917711",
      "fax": "+39 0432/917860",
      "coordinate": {
        "lat": 45.94499969482422,
        "lng": 13.055277824401855
      }
    },
    {
      "codice": "030011",
      "nome": "Bicinicco",
      "nomeStraniero": null,
      "codiceCatastale": "A855",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.bicinicco.ud.it",
      "pec": "comune.bicinicco@certgov.fvg.it",
      "telefono": "+39 0432/990026",
      "fax": "+39 0432/990768",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 13.25
      }
    },
    {
      "codice": "030012",
      "nome": "Bordano",
      "nomeStraniero": null,
      "codiceCatastale": "A983",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "info@comune.bordano.ud.it",
      "pec": "comune.bordano@certgov.fvg.it",
      "telefono": "+39 0432/988049",
      "fax": "+39 0432/988185",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "030013",
      "nome": "Buja",
      "nomeStraniero": null,
      "codiceCatastale": "B259",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "sindaco@comune.buia.ud.it",
      "pec": "comune.buja@certgov.fvg.it",
      "telefono": "+39 0432 960151",
      "fax": "+39 0432 960632",
      "coordinate": {
        "lat": 46.21221923828125,
        "lng": 13.116909980773926
      }
    },
    {
      "codice": "030014",
      "nome": "Buttrio",
      "nomeStraniero": null,
      "codiceCatastale": "B309",
      "cap": "33042",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.buttrio.ud.it",
      "pec": "comune.buttrio@certgov.fvg.it",
      "telefono": "+39 0432/636111",
      "fax": null,
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "030015",
      "nome": "Camino al Tagliamento",
      "nomeStraniero": null,
      "codiceCatastale": "B483",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.caminoaltagliamento.ud.it",
      "pec": "comune.caminoaltagliamento@certgov.fvg.it",
      "telefono": "+39 0432/919000",
      "fax": "+39 0432/919605",
      "coordinate": {
        "lat": 45.9275016784668,
        "lng": 12.944722175598145
      }
    },
    {
      "codice": "030016",
      "nome": "Campoformido",
      "nomeStraniero": null,
      "codiceCatastale": "B536",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.campoformido.ud.it",
      "pec": "protocollo@pec.comune.campoformido.ud.it",
      "telefono": "+39 0432653511",
      "fax": "+39 0432663581",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "030138",
      "nome": "Campolongo Tapogliano",
      "nomeStraniero": null,
      "codiceCatastale": "M311",
      "cap": "33040",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "sindaco@comune.campolongotapogliano.ud.it",
      "pec": "comune.campolongotapogliano@certgov.fvg.it",
      "telefono": "+39 0431 99092",
      "fax": "+39 0431 973707",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 13.383333206176758
      }
    },
    {
      "codice": "030018",
      "nome": "Carlino",
      "nomeStraniero": null,
      "codiceCatastale": "B788",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segreteria@comune.carlino.ud.it",
      "pec": "comune.carlino@certgov.fvg.it",
      "telefono": "+39 0431/687811",
      "fax": null,
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "030019",
      "nome": "Cassacco",
      "nomeStraniero": null,
      "codiceCatastale": "B994",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-cassacco.regione.fvg.it",
      "pec": "comune.cassacco@certgov.fvg.it",
      "telefono": "+39 0432/852811",
      "fax": "+39 0432/853412",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "030020",
      "nome": "Castions di Strada",
      "nomeStraniero": null,
      "codiceCatastale": "C327",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.castionsdistrada.ud.it",
      "pec": "comune.castionsdistrada@certgov.fvg.it",
      "telefono": "+39 0432768011",
      "fax": "+39 0432768084",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "030021",
      "nome": "Cavazzo Carnico",
      "nomeStraniero": null,
      "codiceCatastale": "C389",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-cavazzo-carnico.regione.fvg.it",
      "pec": "comune.cavazzocarnico@certgov.fvg.it",
      "telefono": "+39 0433/93003",
      "fax": "+39 0433/487412",
      "coordinate": {
        "lat": 46.36666488647461,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "030022",
      "nome": "Cercivento",
      "nomeStraniero": null,
      "codiceCatastale": "C494",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "comune@comune.cercivento.ud.it",
      "pec": "comune.cercivento@certgov.fvg.it",
      "telefono": "+39 0433/778408",
      "fax": "+39 0433/778703",
      "coordinate": {
        "lat": 46.52727127075195,
        "lng": 12.98923397064209
      }
    },
    {
      "codice": "030023",
      "nome": "Cervignano del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "C556",
      "cap": "33052",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segreteria@comune.cervignanodelfriuli.ud.it",
      "pec": "comune.cervignanodelfriuli@certgov.fvg.it",
      "telefono": "+39 0431/388501",
      "fax": "+39 0431/388431",
      "coordinate": {
        "lat": 45.823055267333984,
        "lng": 13.335000038146973
      }
    },
    {
      "codice": "030024",
      "nome": "Chiopris-Viscone",
      "nomeStraniero": null,
      "codiceCatastale": "C641",
      "cap": "33048",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "sindaco@com-chiopris-viscone.regione.fvg.it",
      "pec": "comune.chioprisviscone@certgov.fvg.it",
      "telefono": "+39 0432/991004",
      "fax": "+39 0432/991210",
      "coordinate": {
        "lat": 45.92667007446289,
        "lng": 13.404170036315918
      }
    },
    {
      "codice": "030025",
      "nome": "Chiusaforte",
      "nomeStraniero": null,
      "codiceCatastale": "C656",
      "cap": "33010",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@comune.chiusaforte.ud.it",
      "pec": "comune.chiusaforte@certgov.fvg.it",
      "telefono": "+39 0433/52030",
      "fax": "+39 0433/52243",
      "coordinate": {
        "lat": 46.40833282470703,
        "lng": 13.309024810791016
      }
    },
    {
      "codice": "030026",
      "nome": "Cividale del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "C758",
      "cap": "33043",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "comune.cividaledelfriuli@certgov.fvg.it",
      "pec": "comune.cividaledelfriuli@certgov.fvg.it",
      "telefono": "+39 0432/710100",
      "fax": "+39 0432/710103",
      "coordinate": {
        "lat": 46.09049987792969,
        "lng": 13.4350004196167
      }
    },
    {
      "codice": "030027",
      "nome": "Codroipo",
      "nomeStraniero": null,
      "codiceCatastale": "C817",
      "cap": "33033",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "servizi.demografici@comune.codroipo.ud.it",
      "pec": "comune.codroipo@certgov.fvg.it",
      "telefono": "+39 0432/824535",
      "fax": "+39 0432/824582",
      "coordinate": {
        "lat": 45.96142578125,
        "lng": 12.977386474609375
      }
    },
    {
      "codice": "030028",
      "nome": "Colloredo di Monte Albano",
      "nomeStraniero": null,
      "codiceCatastale": "C885",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.colloredodimontealbano.ud.it",
      "pec": "comune.colloredodimontealbano@certgov.fvg.it",
      "telefono": "+39 0432/889004",
      "fax": "+39 0432/889739",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 13.133333206176758
      }
    },
    {
      "codice": "030029",
      "nome": "Comeglians",
      "nomeStraniero": null,
      "codiceCatastale": "C918",
      "cap": "33023",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-comeglians.regione.fvg.it",
      "pec": "comune.comeglians@certgov.fvg.it",
      "telefono": "+39 0433/60052",
      "fax": "+39 0433/60401",
      "coordinate": {
        "lat": 46.515235900878906,
        "lng": 12.867803573608398
      }
    },
    {
      "codice": "030030",
      "nome": "Corno di Rosazzo",
      "nomeStraniero": null,
      "codiceCatastale": "D027",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segretario@com-corno-di-rosazzo.regione.fvg.it",
      "pec": "comune.cornodirosazzo@certgov.fvg.it",
      "telefono": "+39 0432/759036",
      "fax": "+39 0432/753110",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 13.449999809265137
      }
    },
    {
      "codice": "030031",
      "nome": "Coseano",
      "nomeStraniero": null,
      "codiceCatastale": "D085",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-coseano.regione.fvg.it",
      "pec": "comune.coseano@certgov.fvg.it",
      "telefono": "+39 0432/861074",
      "fax": "+39 0432/861289",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "030032",
      "nome": "Dignano",
      "nomeStraniero": null,
      "codiceCatastale": "D300",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.dignano.ud.it",
      "pec": "comune.dignano@certgov.fvg.it",
      "telefono": "+39 0432/944910",
      "fax": "+39 0432/944944",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "030033",
      "nome": "Dogna",
      "nomeStraniero": null,
      "codiceCatastale": "D316",
      "cap": "33010",
      "prefisso": "0428",
      "provincia": "Udine",
      "email": "anagrafe@comune.dogna.ud.it",
      "pec": "comune.dogna@certgov.fvg.it",
      "telefono": "+39 0428/93000",
      "fax": "+39 0428/93003",
      "coordinate": {
        "lat": 46.44704818725586,
        "lng": 12.01402473449707
      }
    },
    {
      "codice": "030034",
      "nome": "Drenchia",
      "nomeStraniero": null,
      "codiceCatastale": "D366",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-drenchia.regione.fvg.it",
      "pec": "comune.drenchia@certgov.fvg.it",
      "telefono": "+39 0432/721021",
      "fax": "+39 0432/721054",
      "coordinate": {
        "lat": 46.18388748168945,
        "lng": 13.636388778686523
      }
    },
    {
      "codice": "030035",
      "nome": "Enemonzo",
      "nomeStraniero": null,
      "codiceCatastale": "D408",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@com-enemonzo.regione.fvg.it",
      "pec": "comune.enemonzo@certgov.fvg.it",
      "telefono": "+39 043374214",
      "fax": "+39 0433748072",
      "coordinate": {
        "lat": 46.4109001159668,
        "lng": 12.878716468811035
      }
    },
    {
      "codice": "030036",
      "nome": "Faedis",
      "nomeStraniero": null,
      "codiceCatastale": "D455",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@comune.faedis.ud.it",
      "pec": "comune.faedis@certgov.fvg.it",
      "telefono": "+39 0432728007",
      "fax": "+39 0432728897",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 13.350000381469727
      }
    },
    {
      "codice": "030037",
      "nome": "Fagagna",
      "nomeStraniero": null,
      "codiceCatastale": "D461",
      "cap": "33034",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.fagagna.ud.it",
      "pec": "comune.fagagna@certgov.fvg.it",
      "telefono": "+39 0432/812111",
      "fax": "+39 0432/810065",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "030190",
      "nome": "Fiumicello Villa Vicentina",
      "nomeStraniero": null,
      "codiceCatastale": "M400",
      "cap": "33059",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "protocollo@comune.fiumicellovillavicentina.ud.it",
      "pec": "comune.fiumicellovillavicentina@certgov.fvg.it",
      "telefono": "0431-972711",
      "fax": "0431-969261",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 13.416666984558105
      }
    },
    {
      "codice": "030039",
      "nome": "Flaibano",
      "nomeStraniero": null,
      "codiceCatastale": "D630",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.flaibano.ud.it",
      "pec": "comune.flaibano@certgov.fvg.it",
      "telefono": "+39 0432/869021",
      "fax": "+39 0432/869331",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 12.983333587646484
      }
    },
    {
      "codice": "030137",
      "nome": "Forgaria nel Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "D700",
      "cap": "33030",
      "prefisso": "0427",
      "provincia": "Udine",
      "email": "anagrafe@comune.forgarianelfriuli.ud.it",
      "pec": "comune.forgarianelfriuli@certgov.fvg.it",
      "telefono": "+39 0427/808042",
      "fax": "+39 0427/808136",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "030040",
      "nome": "Forni Avoltri",
      "nomeStraniero": null,
      "codiceCatastale": "D718",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@com-forni-avoltri.regione.fvg.it",
      "pec": "comune.forniavoltri@certgov.fvg.it",
      "telefono": "+39 0433/72051",
      "fax": "+39 0433/72351",
      "coordinate": {
        "lat": 46.586830139160156,
        "lng": 12.776907920837402
      }
    },
    {
      "codice": "030041",
      "nome": "Forni di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "D719",
      "cap": "33024",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "segretario@comune.fornidisopra.ud.it",
      "pec": "comune.fornidisopra@certgov.fvg.it",
      "telefono": "+39 0433/88056",
      "fax": "+39 0433/88580",
      "coordinate": {
        "lat": 46.42022705078125,
        "lng": 12.585596084594727
      }
    },
    {
      "codice": "030042",
      "nome": "Forni di Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "D720",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@comune.fornidisotto.ud.it",
      "pec": "comune.fornidisotto@certgov.fvg.it",
      "telefono": "+39 0433/87025",
      "fax": "+39 0433/87051",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "030043",
      "nome": "Gemona del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "D962",
      "cap": "33013",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.gemona.ud.it",
      "pec": "comune.gemona-del-friuli@halleypec.it",
      "telefono": "+39 0432/973211",
      "fax": "+39 0432/971090",
      "coordinate": {
        "lat": 46.274051666259766,
        "lng": 13.122369766235352
      }
    },
    {
      "codice": "030044",
      "nome": "Gonars",
      "nomeStraniero": null,
      "codiceCatastale": "E083",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.gonars.ud.it",
      "pec": "comune.gonars@certgov.fvg.it",
      "telefono": "+39 0432/993038",
      "fax": "+39 0432/992051",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "030045",
      "nome": "Grimacco",
      "nomeStraniero": null,
      "codiceCatastale": "E179",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-grimacco.regione.fvg.it",
      "pec": "comune.grimacco@certgov.fvg.it",
      "telefono": "+39 0432/725006",
      "fax": "+39 0432/725179",
      "coordinate": {
        "lat": 46.155540466308594,
        "lng": 13.569660186767578
      }
    },
    {
      "codice": "030046",
      "nome": "Latisana",
      "nomeStraniero": null,
      "codiceCatastale": "E473",
      "cap": "33053",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segretario@comune.latisana.ud.it",
      "pec": "comune.latisana@certgov.fvg.it",
      "telefono": "+39 0431/525110",
      "fax": "+39 0431/520910",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 13
      }
    },
    {
      "codice": "030047",
      "nome": "Lauco",
      "nomeStraniero": null,
      "codiceCatastale": "E476",
      "cap": "33029",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "demografico@com-lauco.regione.fvg.it",
      "pec": "comune.lauco@certgov.fvg.it",
      "telefono": "+39 0433/74101",
      "fax": "+39 0433/750091",
      "coordinate": {
        "lat": 46.424041748046875,
        "lng": 12.933029174804688
      }
    },
    {
      "codice": "030048",
      "nome": "Lestizza",
      "nomeStraniero": null,
      "codiceCatastale": "E553",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-lestizza.regione.fvg.it",
      "pec": "comune.lestizza@certgov.fvg.it",
      "telefono": "+39 0432/760084",
      "fax": "+39 0432/761700",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 13.133333206176758
      }
    },
    {
      "codice": "030049",
      "nome": "Lignano Sabbiadoro",
      "nomeStraniero": null,
      "codiceCatastale": "E584",
      "cap": "33054",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "comune@comune.lignano-sabbiadoro.ud.it",
      "pec": "comune.lignanosabbiadoro@certgov.fvg.it",
      "telefono": "+39 0431409111",
      "fax": "+39 043173288",
      "coordinate": {
        "lat": 45.654998779296875,
        "lng": 13.093055725097656
      }
    },
    {
      "codice": "030051",
      "nome": "Lusevera",
      "nomeStraniero": null,
      "codiceCatastale": "E760",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "sindaco@com-lusevera.regione.fvg.it",
      "pec": "comune.lusevera@certgov.fvg.it",
      "telefono": "+39 0432/787032",
      "fax": "+39 0432/787014",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 13.266666412353516
      }
    },
    {
      "codice": "030052",
      "nome": "Magnano in Riviera",
      "nomeStraniero": null,
      "codiceCatastale": "E820",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.magnanoinriviera.ud.it",
      "pec": "protocollo@pec.comune.magnanoinriviera.ud.it",
      "telefono": "+39 0432/780911",
      "fax": "+39 0432/792370",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "030053",
      "nome": "Majano",
      "nomeStraniero": null,
      "codiceCatastale": "E833",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segretario@comune.majano.ud.it",
      "pec": "comune.majano@certgov.fvg.it",
      "telefono": "+39 0432/948455",
      "fax": "+39 0432/948183",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "030054",
      "nome": "Malborghetto Valbruna",
      "nomeStraniero": null,
      "codiceCatastale": "E847",
      "cap": "33010",
      "prefisso": "0428",
      "provincia": "Udine",
      "email": "segreteria@com-malborghetto-valbruna.regione.fvg.it",
      "pec": "comune.malborghettovalbruna@certgov.fvg.it",
      "telefono": "+39 0428/60023",
      "fax": "+39 0428/60318",
      "coordinate": {
        "lat": 46.50703430175781,
        "lng": 13.439125061035156
      }
    },
    {
      "codice": "030055",
      "nome": "Manzano",
      "nomeStraniero": null,
      "codiceCatastale": "E899",
      "cap": "33044",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "comune@comune.manzano.udine.it",
      "pec": "comune.manzano@certgov.fvg.it",
      "telefono": "+39 0432/938342",
      "fax": "+39 0432/938351",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 13.383333206176758
      }
    },
    {
      "codice": "030056",
      "nome": "Marano Lagunare",
      "nomeStraniero": null,
      "codiceCatastale": "E910",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "anagrafe@comune.maranolagunare.ud.it",
      "pec": "comune.maranolagunare@certgov.fvg.it",
      "telefono": "+39 0431/67005",
      "fax": "+39 0431/67930",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "030057",
      "nome": "Martignacco",
      "nomeStraniero": null,
      "codiceCatastale": "E982",
      "cap": "33035",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "affari.generali@com-martignacco.regione.fvg.it",
      "pec": "comune.martignacco@certgov.fvg.it",
      "telefono": "+39 0432/638411",
      "fax": "+39 0432/638419",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 13.133333206176758
      }
    },
    {
      "codice": "030058",
      "nome": "Mereto di Tomba",
      "nomeStraniero": null,
      "codiceCatastale": "F144",
      "cap": "33036",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-mereto-di-tomba.regione.fvg.it",
      "pec": "comune.meretoditomba@certgov.fvg.it",
      "telefono": "+39 0432/865148",
      "fax": "+39 0432/865907",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "030059",
      "nome": "Moggio Udinese",
      "nomeStraniero": null,
      "codiceCatastale": "F266",
      "cap": "33015",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "segreteria@com-moggio-udinese.regione.fvg.it",
      "pec": "comune.moggioudinese@certgov.fvg.it",
      "telefono": "+39 043351177",
      "fax": "+39 043351371",
      "coordinate": {
        "lat": 46.40970230102539,
        "lng": 13.19514274597168
      }
    },
    {
      "codice": "030060",
      "nome": "Moimacco",
      "nomeStraniero": null,
      "codiceCatastale": "F275",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@comune.moimacco.ud.it",
      "pec": "comune.moimacco@certgov.fvg.it",
      "telefono": "+39 0432/722041",
      "fax": "+39 0432/722255",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 13.383333206176758
      }
    },
    {
      "codice": "030061",
      "nome": "Montenars",
      "nomeStraniero": null,
      "codiceCatastale": "F574",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.montenars.ud.it",
      "pec": "comune.montenars@certgov.fvg.it",
      "telefono": "+39 0432/981209",
      "fax": "+39 0432/970971",
      "coordinate": {
        "lat": 46.25,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "030062",
      "nome": "Mortegliano",
      "nomeStraniero": null,
      "codiceCatastale": "F756",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-mortegliano.regione.fvg.it",
      "pec": "comune.mortegliano@certgov.fvg.it",
      "telefono": "+39 0432/826811",
      "fax": "+39 0432/761778",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "030063",
      "nome": "Moruzzo",
      "nomeStraniero": null,
      "codiceCatastale": "F760",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-moruzzo.regione.fvg.it",
      "pec": "comune.moruzzo@certgov.fvg.it",
      "telefono": "+39 0432/672004",
      "fax": "+39 0432/672724",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 13.116666793823242
      }
    },
    {
      "codice": "030064",
      "nome": "Muzzana del Turgnano",
      "nomeStraniero": null,
      "codiceCatastale": "F832",
      "cap": "33055",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segreteria@com-muzzana-del-turgnano.regione.fvg.it",
      "pec": "comune.muzzanadelturgnano@certgov.fvg.it",
      "telefono": "+39 0431/69017",
      "fax": "+39 0431/697869",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 13.133333206176758
      }
    },
    {
      "codice": "030065",
      "nome": "Nimis",
      "nomeStraniero": null,
      "codiceCatastale": "F898",
      "cap": "33045",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "elettorale@com-nimis.regione.fvg.it",
      "pec": "comune.nimis@certgov.fvg.it",
      "telefono": "+39 0432/790045",
      "fax": "+39 0432/790173",
      "coordinate": {
        "lat": 46.20000076293945,
        "lng": 13.266666412353516
      }
    },
    {
      "codice": "030066",
      "nome": "Osoppo",
      "nomeStraniero": null,
      "codiceCatastale": "G163",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": null,
      "pec": "comune.osoppo@certgov.fvg.it",
      "telefono": "+39 0432899311",
      "fax": "+39 0432899320",
      "coordinate": {
        "lat": 46.25,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "030067",
      "nome": "Ovaro",
      "nomeStraniero": null,
      "codiceCatastale": "G198",
      "cap": "33025",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@comune.ovaro.ud.it",
      "pec": "comune.ovaro@certgov.fvg.it",
      "telefono": "+39 043367202",
      "fax": null,
      "coordinate": {
        "lat": 46.48294448852539,
        "lng": 12.865388870239258
      }
    },
    {
      "codice": "030068",
      "nome": "Pagnacco",
      "nomeStraniero": null,
      "codiceCatastale": "G238",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "comune.pagnacco@certgov.fvg.it",
      "pec": "comune.pagnacco@certgov.fvg.it",
      "telefono": "+39 0432/661960",
      "fax": null,
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "030069",
      "nome": "Palazzolo dello Stella",
      "nomeStraniero": null,
      "codiceCatastale": "G268",
      "cap": "33056",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "protocollo@comune.palazzolodellostella.ud.it",
      "pec": "comune.palazzolodellostella@certgov.fvg.it",
      "telefono": "+39 0431/584911",
      "fax": "+39 0431/589461",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "030070",
      "nome": "Palmanova",
      "nomeStraniero": null,
      "codiceCatastale": "G284",
      "cap": "33057",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.palmanova.ud.it",
      "pec": "comune.palmanova@certgov.fvg.it",
      "telefono": "+39 0432/922111",
      "fax": "+39 0432/923346",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 13.316666603088379
      }
    },
    {
      "codice": "030071",
      "nome": "Paluzza",
      "nomeStraniero": null,
      "codiceCatastale": "G300",
      "cap": "33026",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-paluzza.regione.fvg.it",
      "pec": "comune.paluzza@certgov.fvg.it",
      "telefono": "+39 0433/775143",
      "fax": "+39 0433/771763",
      "coordinate": {
        "lat": 46.53081512451172,
        "lng": 13.018206596374512
      }
    },
    {
      "codice": "030072",
      "nome": "Pasian di Prato",
      "nomeStraniero": null,
      "codiceCatastale": "G352",
      "cap": "33037",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "pasian@pasian.it",
      "pec": "pasian@pec.pasian.it",
      "telefono": "+39 0432/645924",
      "fax": "+39 0432/645912",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 13.199999809265137
      }
    },
    {
      "codice": "030073",
      "nome": "Paularo",
      "nomeStraniero": null,
      "codiceCatastale": "G381",
      "cap": "33027",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@comune.paularo.ud.it",
      "pec": "comune.paularo@certgov.fvg.it",
      "telefono": "+39 0433/70026",
      "fax": "+39 0433/70844",
      "coordinate": {
        "lat": 46.53034591674805,
        "lng": 13.116560935974121
      }
    },
    {
      "codice": "030074",
      "nome": "Pavia di Udine",
      "nomeStraniero": null,
      "codiceCatastale": "G389",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@comune.paviadiudine.ud.it",
      "pec": "comune.paviadiudine@certgov.fvg.it",
      "telefono": "+39 0432646111",
      "fax": "+39 0432646119",
      "coordinate": {
        "lat": 46,
        "lng": 13.300000190734863
      }
    },
    {
      "codice": "030075",
      "nome": "Pocenia",
      "nomeStraniero": null,
      "codiceCatastale": "G743",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.pocenia.ud.it",
      "pec": "comune.pocenia@certgov.fvg.it",
      "telefono": "+39 0432/779001",
      "fax": "+39 0432/779719",
      "coordinate": {
        "lat": 45.836387634277344,
        "lng": 13.101666450500488
      }
    },
    {
      "codice": "030076",
      "nome": "Pontebba",
      "nomeStraniero": null,
      "codiceCatastale": "G831",
      "cap": "33016",
      "prefisso": "0428",
      "provincia": "Udine",
      "email": "protocollo@com-pontebba.regione.fvg.it",
      "pec": "comune.pontebba@certgov.fvg.it",
      "telefono": "+39 0428/90161",
      "fax": "+39 0428/91133",
      "coordinate": {
        "lat": 46.50653076171875,
        "lng": 13.306426048278809
      }
    },
    {
      "codice": "030077",
      "nome": "Porpetto",
      "nomeStraniero": null,
      "codiceCatastale": "G891",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "elettorale@comune.porpetto.ud.it",
      "pec": "comune.porpetto@certgov.fvg.it",
      "telefono": "+39 0431/60036",
      "fax": "+39 0431/60901",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "030078",
      "nome": "Povoletto",
      "nomeStraniero": null,
      "codiceCatastale": "G949",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@comune.povoletto.ud.it",
      "pec": "protocollo@pec.comune.povoletto.ud.it",
      "telefono": "+39 0432664082",
      "fax": "+39 0432664094",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 13.283333778381348
      }
    },
    {
      "codice": "030079",
      "nome": "Pozzuolo del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "G966",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-pozzuolo-del-friuli.regione.fvg.it",
      "pec": "comune@pec.com-pozzuolo-del-friuli.regione.fvg.it",
      "telefono": "+39 0432/669016",
      "fax": "+39 0432/669343",
      "coordinate": {
        "lat": 45.968055725097656,
        "lng": 13.196944236755371
      }
    },
    {
      "codice": "030080",
      "nome": "Pradamano",
      "nomeStraniero": null,
      "codiceCatastale": "G969",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.pradamano.ud.it",
      "pec": "comune.pradamano@certgov.fvg.it",
      "telefono": "+39 0432/670014",
      "fax": "+39 0432/670002",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 13.300000190734863
      }
    },
    {
      "codice": "030081",
      "nome": "Prato Carnico",
      "nomeStraniero": null,
      "codiceCatastale": "H002",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-prato-carnico.regione.fvg.it",
      "pec": "comune.pratocarnico@certgov.fvg.it",
      "telefono": "+39 0433/69034",
      "fax": "+39 0433/69001",
      "coordinate": {
        "lat": 46.52030563354492,
        "lng": 12.797247886657715
      }
    },
    {
      "codice": "030082",
      "nome": "Precenicco",
      "nomeStraniero": null,
      "codiceCatastale": "H014",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segreteria@comune.precenicco.ud.it",
      "pec": "comune.precenicco@certgov.fvg.it",
      "telefono": "+39 0431/58038",
      "fax": "+39 0431/589295",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "030083",
      "nome": "Premariacco",
      "nomeStraniero": null,
      "codiceCatastale": "H029",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-premariacco.regione.fvg.it",
      "pec": "comune.premariacco@certgov.fvg.it",
      "telefono": "+39 0432/729009",
      "fax": "+39 0432/729072",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "030084",
      "nome": "Preone",
      "nomeStraniero": null,
      "codiceCatastale": "H038",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@com-preone.regione.fvg.it",
      "pec": "comune.preone@certgov.fvg.it",
      "telefono": "+39 0433/749027",
      "fax": "+39 0433/749100",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 12.866666793823242
      }
    },
    {
      "codice": "030085",
      "nome": "Prepotto",
      "nomeStraniero": null,
      "codiceCatastale": "H040",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@com-prepotto.regione.fvg.it",
      "pec": "comune.prepotto@certgov.fvg.it",
      "telefono": "+39 0432/713003",
      "fax": "+39 0432/713246",
      "coordinate": {
        "lat": 46.04521942138672,
        "lng": 13.479209899902344
      }
    },
    {
      "codice": "030086",
      "nome": "Pulfero",
      "nomeStraniero": null,
      "codiceCatastale": "H089",
      "cap": "33046",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-pulfero.regione.fvg.it",
      "pec": "comune.pulfero@certgov.fvg.it",
      "telefono": "+39 0432/726017",
      "fax": "+39 0432/726033",
      "coordinate": {
        "lat": 46.17333221435547,
        "lng": 13.484444618225098
      }
    },
    {
      "codice": "030087",
      "nome": "Ragogna",
      "nomeStraniero": null,
      "codiceCatastale": "H161",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comuneragogna.it",
      "pec": "comune.ragogna@certgov.fvg.it",
      "telefono": "+39 0432/957055",
      "fax": "+39 0432/940951",
      "coordinate": {
        "lat": 46.17352294921875,
        "lng": 12.982419967651367
      }
    },
    {
      "codice": "030088",
      "nome": "Ravascletto",
      "nomeStraniero": null,
      "codiceCatastale": "H196",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-ravascletto.regione.fvg.it",
      "pec": "comune.ravascletto@certgov.fvg.it",
      "telefono": "+39 0433/66009",
      "fax": "+39 0433/66394",
      "coordinate": {
        "lat": 46.5229377746582,
        "lng": 12.922115325927734
      }
    },
    {
      "codice": "030089",
      "nome": "Raveo",
      "nomeStraniero": null,
      "codiceCatastale": "H200",
      "cap": "33029",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-raveo.regione.fvg.it",
      "pec": "comune.raveo@certgov.fvg.it",
      "telefono": "+39 0433/746305",
      "fax": "+39 0433/746204",
      "coordinate": {
        "lat": 46.43442916870117,
        "lng": 12.871112823486328
      }
    },
    {
      "codice": "030090",
      "nome": "Reana del Rojale",
      "nomeStraniero": null,
      "codiceCatastale": "H206",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.reanadelrojale.ud.it",
      "pec": "comune.reanadelrojale@certgov.fvg.it",
      "telefono": "+39 0432856211",
      "fax": "+39 0432857673",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "030091",
      "nome": "Remanzacco",
      "nomeStraniero": null,
      "codiceCatastale": "H229",
      "cap": "33047",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "demografici@comune.remanzacco.ud.it",
      "pec": "comune.remanzacco@certgov.fvg.it",
      "telefono": "+39 0432/668523",
      "fax": "+39 0432/668352",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 13.316666603088379
      }
    },
    {
      "codice": "030092",
      "nome": "Resia",
      "nomeStraniero": null,
      "codiceCatastale": "H242",
      "cap": "33010",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "info@comune.resia.ud.it",
      "pec": "comune.resia@certgov.fvg.it",
      "telefono": "+39 0433/53001",
      "fax": "+39 0433/53392",
      "coordinate": {
        "lat": 46.37388229370117,
        "lng": 13.304364204406738
      }
    },
    {
      "codice": "030093",
      "nome": "Resiutta",
      "nomeStraniero": null,
      "codiceCatastale": "H244",
      "cap": "33010",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@comune.resiutta.ud.it",
      "pec": "comune.resiutta@certgov.fvg.it",
      "telefono": "+39 0433/51215",
      "fax": "+39 0433/51347",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "030094",
      "nome": "Rigolato",
      "nomeStraniero": null,
      "codiceCatastale": "H289",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@com-rigolato.regione.fvg.it",
      "pec": "comune.rigolato@certgov.fvg.it",
      "telefono": "+39 0433/68030",
      "fax": "+39 0433/68083",
      "coordinate": {
        "lat": 46.55282974243164,
        "lng": 12.852109909057617
      }
    },
    {
      "codice": "030095",
      "nome": "Rive d'Arcano",
      "nomeStraniero": null,
      "codiceCatastale": "H347",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.rivedarcano.ud.it",
      "pec": "comune.rivedarcano@certgov.fvg.it",
      "telefono": "+39 0432/809711",
      "fax": "+39 0432/809083",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "030188",
      "nome": "Rivignano Teor",
      "nomeStraniero": null,
      "codiceCatastale": "M317",
      "cap": "33061",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.rivignanoteor.ud.it",
      "pec": "comune.rivignanoteor@certgov.fvg.it",
      "telefono": "+39 0432/775778",
      "fax": "+39 0432/775236",
      "coordinate": {
        "lat": 45.87969970703125,
        "lng": 13.042499542236328
      }
    },
    {
      "codice": "030097",
      "nome": "Ronchis",
      "nomeStraniero": null,
      "codiceCatastale": "H533",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "anagrafe@comune.ronchis.ud.it",
      "pec": "comune.ronchis@certgov.fvg.it",
      "telefono": "+39 0431/56014",
      "fax": "+39 0431/56481",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 13.116666793823242
      }
    },
    {
      "codice": "030098",
      "nome": "Ruda",
      "nomeStraniero": null,
      "codiceCatastale": "H629",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "protocollo@com-ruda.regione.fvg.it",
      "pec": "comune.ruda@certgov.fvg.it",
      "telefono": "+39 0431/99077",
      "fax": "+39 0431/973254",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "030099",
      "nome": "San Daniele del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "H816",
      "cap": "33038",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "affari.generali@comune.sandanieledelfriuli.ud.it",
      "pec": "comune.sandanieledelfriuli@certgov.fvg.it",
      "telefono": "+39 0432/946511",
      "fax": "+39 0432/946534",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "030100",
      "nome": "San Giorgio di Nogaro",
      "nomeStraniero": null,
      "codiceCatastale": "H895",
      "cap": "33058",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "segretario@comune.sangiorgiodinogaro.ud.it",
      "pec": "comune.sangiorgiodinogaro@certgov.fvg.it",
      "telefono": "+39 0431/623612",
      "fax": "+39 0431/623691",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 13.199999809265137
      }
    },
    {
      "codice": "030101",
      "nome": "San Giovanni al Natisone",
      "nomeStraniero": null,
      "codiceCatastale": "H906",
      "cap": "33048",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@comune.sangiovannialnatisone.ud.it",
      "pec": "comune.sangiovannialnatisone@certgov.fvg.it",
      "telefono": "+39 0432/939511",
      "fax": "+39 0432/939526",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "030102",
      "nome": "San Leonardo",
      "nomeStraniero": null,
      "codiceCatastale": "H951",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-san-leonardo.regione.fvg.it",
      "pec": "comune.sanleonardo@certgov.fvg.it",
      "telefono": "+39 0432/723028",
      "fax": "+39 0432/723902",
      "coordinate": {
        "lat": 46.11861038208008,
        "lng": 13.531389236450195
      }
    },
    {
      "codice": "030103",
      "nome": "San Pietro al Natisone",
      "nomeStraniero": null,
      "codiceCatastale": "I092",
      "cap": "33049",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "sindaco@comune.sanpietroalnatisone.ud.it",
      "pec": "comune.sanpietroalnatisone@certgov.fvg.it",
      "telefono": "+39 0432/727272",
      "fax": "+39 0432/717840",
      "coordinate": {
        "lat": 46.130001068115234,
        "lng": 13.487222671508789
      }
    },
    {
      "codice": "030105",
      "nome": "San Vito al Torre",
      "nomeStraniero": null,
      "codiceCatastale": "I404",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@comune.sanvitoaltorre.ud.it",
      "pec": "comune.sanvitoaltorre@certgov.fvg.it",
      "telefono": "+39 0432/997013",
      "fax": "+39 0432/997311",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 13.366666793823242
      }
    },
    {
      "codice": "030106",
      "nome": "San Vito di Fagagna",
      "nomeStraniero": null,
      "codiceCatastale": "I405",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.sanvitodifagagna.ud.it",
      "pec": "comune.sanvitodifagagna@certgov.fvg.it",
      "telefono": "+39 0432/808024",
      "fax": "+39 0432/808215",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "030104",
      "nome": "Santa Maria la Longa",
      "nomeStraniero": null,
      "codiceCatastale": "I248",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@com-santa-maria-la-longa.regione.fvg.it",
      "pec": "comune.santamarialalonga@certgov.fvg.it",
      "telefono": "+39 0432/995003",
      "fax": "+39 0432/995005",
      "coordinate": {
        "lat": 45.93361282348633,
        "lng": 13.289999961853027
      }
    },
    {
      "codice": "030189",
      "nome": "Sappada",
      "nomeStraniero": null,
      "codiceCatastale": "I421",
      "cap": "33012",
      "prefisso": "0435",
      "provincia": "Udine",
      "email": "segreteria@comune.sappada.bl.it",
      "pec": "comune.sappada.bl@pecveneto.it",
      "telefono": "+39 0435/469126",
      "fax": "+39 0435/469107",
      "coordinate": {
        "lat": 46.5655403137207,
        "lng": 12.680257797241211
      }
    },
    {
      "codice": "030107",
      "nome": "Sauris",
      "nomeStraniero": null,
      "codiceCatastale": "I464",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "protocollo@com-sauris.regione.fvg.it",
      "pec": "comune.sauris@certgov.fvg.it",
      "telefono": "+39 043386245",
      "fax": "+39 043386025",
      "coordinate": {
        "lat": 46.46536636352539,
        "lng": 12.708717346191406
      }
    },
    {
      "codice": "030108",
      "nome": "Savogna",
      "nomeStraniero": null,
      "codiceCatastale": "I478",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@comune.savogna.ud.it",
      "pec": "comune.savogna@certgov.fvg.it",
      "telefono": "+39 0432/714007",
      "fax": "+39 0432/714060",
      "coordinate": {
        "lat": 46.15944290161133,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "030109",
      "nome": "Sedegliano",
      "nomeStraniero": null,
      "codiceCatastale": "I562",
      "cap": "33039",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "stato.civile@comune.sedegliano.ud.it",
      "pec": "comune.sedegliano@certgov.fvg.it",
      "telefono": "+39 0432/915511",
      "fax": "+39 0432/915025",
      "coordinate": {
        "lat": 46.01388931274414,
        "lng": 12.974721908569336
      }
    },
    {
      "codice": "030110",
      "nome": "Socchieve",
      "nomeStraniero": null,
      "codiceCatastale": "I777",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-socchieve.regione.fvg.it",
      "pec": "comune.socchieve@certgov.fvg.it",
      "telefono": "+39 0433/80080",
      "fax": "+39 0433/80216",
      "coordinate": {
        "lat": 46.396629333496094,
        "lng": 12.84801959991455
      }
    },
    {
      "codice": "030111",
      "nome": "Stregna",
      "nomeStraniero": null,
      "codiceCatastale": "I974",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "amministrativo@com-stregna.regione.fvg.it",
      "pec": "comune.stregna@certgov.fvg.it",
      "telefono": "+39 0432/724094",
      "fax": "+39 0432/724172",
      "coordinate": {
        "lat": 46.12694549560547,
        "lng": 13.577221870422363
      }
    },
    {
      "codice": "030112",
      "nome": "Sutrio",
      "nomeStraniero": null,
      "codiceCatastale": "L018",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "statocivile@comune.sutrio.ud.it",
      "pec": "comune.sutrio@certgov.fvg.it",
      "telefono": "+39 0433/778032",
      "fax": "+39 0433/778321",
      "coordinate": {
        "lat": 46.512001037597656,
        "lng": 12.99075698852539
      }
    },
    {
      "codice": "030113",
      "nome": "Taipana",
      "nomeStraniero": null,
      "codiceCatastale": "G736",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "demografico@comune.taipana.ud.it",
      "pec": "comune.taipana@certgov.fvg.it",
      "telefono": "0432788020",
      "fax": "0432788049",
      "coordinate": {
        "lat": 46.25,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "030114",
      "nome": "Talmassons",
      "nomeStraniero": null,
      "codiceCatastale": "L039",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segretario.talmassons@eell.regione.fvg.it",
      "pec": "comune.talmassons@certgov.fvg.it",
      "telefono": "+39 0432/766020",
      "fax": "+39 0432/765235",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 13.116666793823242
      }
    },
    {
      "codice": "030116",
      "nome": "Tarcento",
      "nomeStraniero": null,
      "codiceCatastale": "L050",
      "cap": "33017",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "anagrafe@com-tarcento.regione.fvg.it",
      "pec": "comune.tarcento@certgov.fvg.it",
      "telefono": "+39 0432 780611",
      "fax": "+39 0432 780699",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "030117",
      "nome": "Tarvisio",
      "nomeStraniero": null,
      "codiceCatastale": "L057",
      "cap": "33018",
      "prefisso": "0428",
      "provincia": "Udine",
      "email": "sindaco@com-tarvisio.regione.fvg.it",
      "pec": "comune.tarvisio@certgov.fvg.it",
      "telefono": "+39 0428 2980",
      "fax": "+39 0428/40498",
      "coordinate": {
        "lat": 46.5052604675293,
        "lng": 13.578372955322266
      }
    },
    {
      "codice": "030118",
      "nome": "Tavagnacco",
      "nomeStraniero": null,
      "codiceCatastale": "L065",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segretario@comune.tavagnacco.ud.it",
      "pec": "tavagnacco@postemailcertificata.it",
      "telefono": "+39 0432/577311",
      "fax": "+39 0432570196",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "030120",
      "nome": "Terzo d'Aquileia",
      "nomeStraniero": null,
      "codiceCatastale": "L144",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "elettorale@comune.terzodiaquileia.ud.it",
      "pec": "comune.terzodaquileia@certgov.fvg.it",
      "telefono": "+39 0431/371127",
      "fax": "+39 0431/379284",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "030121",
      "nome": "Tolmezzo",
      "nomeStraniero": null,
      "codiceCatastale": "L195",
      "cap": "33028",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "comune.tolmezzo@com-tolmezzo.regione.fvg.it",
      "pec": "comune.tolmezzo@certgov.fvg.it",
      "telefono": "+39 0433/487911",
      "fax": "+39 0433/2466",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "030122",
      "nome": "Torreano",
      "nomeStraniero": null,
      "codiceCatastale": "L246",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "elettorale@comune.torreano.ud.it",
      "pec": "comune.torreano@certgov.fvg.it",
      "telefono": "+39 0432/712028",
      "fax": "+39 0432/712345",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 13.433333396911621
      }
    },
    {
      "codice": "030123",
      "nome": "Torviscosa",
      "nomeStraniero": null,
      "codiceCatastale": "L309",
      "cap": "33050",
      "prefisso": "0431",
      "provincia": "Udine",
      "email": "anagrafe@comune.torviscosa.ud.it",
      "pec": "comune.torviscosa@certgov.fvg.it",
      "telefono": "+39 0431/927916",
      "fax": "+39 0431/929043",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 13.283333778381348
      }
    },
    {
      "codice": "030124",
      "nome": "Trasaghis",
      "nomeStraniero": null,
      "codiceCatastale": "L335",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segretario.trasaghis@eell.regione.fvg.it",
      "pec": "comune.trasaghis@certgov.fvg.it",
      "telefono": "+39 0432/984070",
      "fax": "+39 0432/984440",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "030126",
      "nome": "Treppo Grande",
      "nomeStraniero": null,
      "codiceCatastale": "L382",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-treppo-grande.regione.fvg.it",
      "pec": "comune.treppogrande@certgov.fvg.it",
      "telefono": "+39 0432/960147",
      "fax": "+39 0432/961692",
      "coordinate": {
        "lat": 46.20000076293945,
        "lng": 13.149999618530273
      }
    },
    {
      "codice": "030191",
      "nome": "Treppo Ligosullo",
      "nomeStraniero": null,
      "codiceCatastale": "M399",
      "cap": "33014",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@comune.treppoligosullo.ud.it",
      "pec": "comune.treppoligosullo@certgov.fvg.it",
      "telefono": "+39 0433 777023",
      "fax": "+39 0433 777331",
      "coordinate": {
        "lat": 46.533233642578125,
        "lng": 13.042915344238281
      }
    },
    {
      "codice": "030127",
      "nome": "Tricesimo",
      "nomeStraniero": null,
      "codiceCatastale": "L421",
      "cap": "33019",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-tricesimo.regione.fvg.it",
      "pec": "comune.tricesimo@certgov.fvg.it",
      "telefono": "+39 0432/855411",
      "fax": "+39 0432/880542",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "030128",
      "nome": "Trivignano Udinese",
      "nomeStraniero": null,
      "codiceCatastale": "L438",
      "cap": "33050",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "protocollo@com-trivignano-udinese.regione.fvg.it",
      "pec": "comune.trivignanoudinese@certgov.fvg.it",
      "telefono": "+39 0432/999038",
      "fax": null,
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 13.333333015441895
      }
    },
    {
      "codice": "030129",
      "nome": "Udine",
      "nomeStraniero": null,
      "codiceCatastale": "L483",
      "cap": "33100",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "ufficio.elettorale@comune.udine.it",
      "pec": "protocollo@pec.comune.udine.it",
      "telefono": "+39 0432/1271111",
      "fax": "+39 0432/1270355",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "030130",
      "nome": "Varmo",
      "nomeStraniero": null,
      "codiceCatastale": "L686",
      "cap": "33030",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "demografici@comune.varmo.ud.it",
      "pec": "comune.varmo@certgov.fvg.it",
      "telefono": "+39 0432/778747",
      "fax": "+39 0432/778608",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 12.983333587646484
      }
    },
    {
      "codice": "030131",
      "nome": "Venzone",
      "nomeStraniero": null,
      "codiceCatastale": "L743",
      "cap": "33010",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "comune.venzone@comunedivenzone.it",
      "pec": "comune.venzone@certgov.fvg.it",
      "telefono": "+39 0432985266",
      "fax": "+39 0432/985404",
      "coordinate": {
        "lat": 46.3303108215332,
        "lng": 13.138250350952148
      }
    },
    {
      "codice": "030132",
      "nome": "Verzegnis",
      "nomeStraniero": null,
      "codiceCatastale": "L801",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "demografico@com-verzegnis.regione.fvg.it",
      "pec": "comune.verzegnis@certgov.fvg.it",
      "telefono": "+39 0433 2713",
      "fax": "+39 0433/44545",
      "coordinate": {
        "lat": 46.38514709472656,
        "lng": 12.987201690673828
      }
    },
    {
      "codice": "030133",
      "nome": "Villa Santina",
      "nomeStraniero": null,
      "codiceCatastale": "L909",
      "cap": "33029",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "segreteria@com-villa-santina.regione.fvg.it",
      "pec": "comune.villasantina@certgov.fvg.it",
      "telefono": "043374141",
      "fax": "0433750105",
      "coordinate": {
        "lat": 46.41409683227539,
        "lng": 12.920747756958008
      }
    },
    {
      "codice": "030135",
      "nome": "Visco",
      "nomeStraniero": null,
      "codiceCatastale": "M073",
      "cap": "33040",
      "prefisso": "0432",
      "provincia": "Udine",
      "email": "segreteria@comune.visco.ud.it",
      "pec": "comune.visco@certgov.fvg.it",
      "telefono": "+39 0432/997003",
      "fax": "+39 0432/998049",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 13.350000381469727
      }
    },
    {
      "codice": "030136",
      "nome": "Zuglio",
      "nomeStraniero": null,
      "codiceCatastale": "M200",
      "cap": "33020",
      "prefisso": "0433",
      "provincia": "Udine",
      "email": "anagrafe@com-zuglio.regione.fvg.it",
      "pec": "comune.zuglio@certgov.fvg.it",
      "telefono": "+39 0433/92045",
      "fax": "+39 0433/929091",
      "coordinate": {
        "lat": 46.461795806884766,
        "lng": 13.026040077209473
      }
    }
  ],
  "Avellino": [
    {
      "codice": "064001",
      "nome": "Aiello del Sabato",
      "nomeStraniero": null,
      "codiceCatastale": "A101",
      "cap": "83020",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.aiellodelsabato.av.it",
      "pec": "amministrativo.aiello@asmepec.it",
      "telefono": "+39  0825666041",
      "fax": "+39 0825666559",
      "coordinate": {
        "lat": 40.88999938964844,
        "lng": 14.821666717529297
      }
    },
    {
      "codice": "064002",
      "nome": "Altavilla Irpina",
      "nomeStraniero": null,
      "codiceCatastale": "A228",
      "cap": "83011",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "altavillairpina@libero.it",
      "pec": "comune.altavillairpina.av@pec.it",
      "telefono": "+39 0825991020",
      "fax": "+39 0825991955",
      "coordinate": {
        "lat": 41.008056640625,
        "lng": 14.782221794128418
      }
    },
    {
      "codice": "064003",
      "nome": "Andretta",
      "nomeStraniero": null,
      "codiceCatastale": "A284",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "comuneandretta@tiscali.it",
      "pec": "comune.andretta@asmepec.it",
      "telefono": "+39 082732009",
      "fax": "+39 082732579",
      "coordinate": {
        "lat": 40.938331604003906,
        "lng": 15.325833320617676
      }
    },
    {
      "codice": "064004",
      "nome": "Aquilonia",
      "nomeStraniero": null,
      "codiceCatastale": "A347",
      "cap": "83041",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "municipio@comune.aquilonia.av.it",
      "pec": "protocollo.aquilonia@asmepec.it",
      "telefono": "+39 082783004",
      "fax": "+39 082783190",
      "coordinate": {
        "lat": 40.98777770996094,
        "lng": 15.4752779006958
      }
    },
    {
      "codice": "064005",
      "nome": "Ariano Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "A399",
      "cap": "83031",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.ariano-irpino.av.it",
      "pec": "protocollo.arianoirpino@asmepec.it",
      "telefono": "+39 0825 875100",
      "fax": "+39 0825 875147",
      "coordinate": {
        "lat": 41.15277862548828,
        "lng": 15.088889122009277
      }
    },
    {
      "codice": "064006",
      "nome": "Atripalda",
      "nomeStraniero": null,
      "codiceCatastale": "A489",
      "cap": "83042",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "atripalda@comune.atripalda.av.it",
      "pec": "comune.atripalda@legalmail.it",
      "telefono": "+39 0825615301",
      "fax": "+39 0825611798",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.825555801391602
      }
    },
    {
      "codice": "064007",
      "nome": "Avella",
      "nomeStraniero": null,
      "codiceCatastale": "A508",
      "cap": "83021",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "anagrafe@comune.avella.it",
      "pec": "amministrativa.avella@cert.irpinianet.eu",
      "telefono": "+39 0818259315",
      "fax": "+39 0818259315",
      "coordinate": {
        "lat": 40.959999084472656,
        "lng": 14.601388931274414
      }
    },
    {
      "codice": "064008",
      "nome": "Avellino",
      "nomeStraniero": null,
      "codiceCatastale": "A509",
      "cap": "83100",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.avellino.it",
      "pec": "ufficioprotocollo@cert.comune.avellino.it",
      "telefono": "+39 0825.2001",
      "fax": "+39 0825.200221",
      "coordinate": {
        "lat": 40.915279388427734,
        "lng": 14.789722442626953
      }
    },
    {
      "codice": "064009",
      "nome": "Bagnoli Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "A566",
      "cap": "83043",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "anagrafe@bagnoli-laceno.it",
      "pec": "anagrafe.bagnoliirpino@cert.irpinianet.eu",
      "telefono": "+39 082762003",
      "fax": "+39 0827603005",
      "coordinate": {
        "lat": 40.83416748046875,
        "lng": 15.073333740234375
      }
    },
    {
      "codice": "064010",
      "nome": "Baiano",
      "nomeStraniero": null,
      "codiceCatastale": "A580",
      "cap": "83022",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "comune.baiano@comune.baiano.av.it",
      "pec": "d.picciocchi.baiano@asmepec.it",
      "telefono": "+39 081 8243041",
      "fax": "+39 081 8244345",
      "coordinate": {
        "lat": 40.953609466552734,
        "lng": 14.617500305175781
      }
    },
    {
      "codice": "064011",
      "nome": "Bisaccia",
      "nomeStraniero": null,
      "codiceCatastale": "A881",
      "cap": "83044",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "bisaccia.anagrafe@legalmail.itt",
      "pec": "bisaccia.anagrafe@legalmail.it",
      "telefono": "+39 082789202",
      "fax": "+39 082781036",
      "coordinate": {
        "lat": 41.01305389404297,
        "lng": 15.375555992126465
      }
    },
    {
      "codice": "064012",
      "nome": "Bonito",
      "nomeStraniero": null,
      "codiceCatastale": "A975",
      "cap": "83032",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comunedibonito.it",
      "pec": "comunebonito@pec.it",
      "telefono": "+39 0825422054",
      "fax": "+39 0825422790",
      "coordinate": {
        "lat": 41.098609924316406,
        "lng": 15.002222061157227
      }
    },
    {
      "codice": "064013",
      "nome": "Cairano",
      "nomeStraniero": null,
      "codiceCatastale": "B367",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "comunecairano@tiscali.it",
      "pec": "sindaco.cairano@asmepec.it",
      "telefono": "+39 082737034",
      "fax": "+39 082737209",
      "coordinate": {
        "lat": 40.89777755737305,
        "lng": 15.371110916137695
      }
    },
    {
      "codice": "064014",
      "nome": "Calabritto",
      "nomeStraniero": null,
      "codiceCatastale": "B374",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "comunecalabritto@comune.libero.it",
      "pec": "affarigenerali.calabritto@asmepec.it",
      "telefono": "+39 0827 52004",
      "fax": "+39 0827 52266",
      "coordinate": {
        "lat": 40.78333282470703,
        "lng": 15.224721908569336
      }
    },
    {
      "codice": "064015",
      "nome": "Calitri",
      "nomeStraniero": null,
      "codiceCatastale": "B415",
      "cap": "83045",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "protocollo@comune.calitri.av.it",
      "pec": "protocollo.calitri@pec.it",
      "telefono": "+39 0827-318711",
      "fax": "+39 0827-318717",
      "coordinate": {
        "lat": 40.90305709838867,
        "lng": 15.431388854980469
      }
    },
    {
      "codice": "064016",
      "nome": "Candida",
      "nomeStraniero": null,
      "codiceCatastale": "B590",
      "cap": "83040",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "c.comunedicandida@tin.it",
      "pec": "protocollo.candida@asmepec.it",
      "telefono": "+39 0825981024",
      "fax": "+39 0825981839",
      "coordinate": {
        "lat": 40.94333267211914,
        "lng": 14.875
      }
    },
    {
      "codice": "064017",
      "nome": "Caposele",
      "nomeStraniero": null,
      "codiceCatastale": "B674",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "segreteria@comune.caposele.av.it",
      "pec": "protocollo.caposele@asmepec.it",
      "telefono": "+39 0827 53024",
      "fax": "+39 0827 53384",
      "coordinate": {
        "lat": 40.815277099609375,
        "lng": 15.224166870117188
      }
    },
    {
      "codice": "064018",
      "nome": "Capriglia Irpina",
      "nomeStraniero": null,
      "codiceCatastale": "B706",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "segreteria@comune.caprigliairpina.av.it",
      "pec": "capriglia.anagrafe@asmepec.it",
      "telefono": "+39 0825702001",
      "fax": "+39 0825702488",
      "coordinate": {
        "lat": 40.961387634277344,
        "lng": 14.778055191040039
      }
    },
    {
      "codice": "064019",
      "nome": "Carife",
      "nomeStraniero": null,
      "codiceCatastale": "B776",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "protocollo@comune.carife.av.it",
      "pec": "comune.carife@asmepec.it",
      "telefono": "+39 082795021",
      "fax": "+39 082795476",
      "coordinate": {
        "lat": 41.0272216796875,
        "lng": 15.209722518920898
      }
    },
    {
      "codice": "064020",
      "nome": "Casalbore",
      "nomeStraniero": null,
      "codiceCatastale": "B866",
      "cap": "83034",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.casalbore.av.it",
      "pec": "protocollo.casalbore@asmepec.it",
      "telefono": "+39 0825849005",
      "fax": "+39 0825849735",
      "coordinate": {
        "lat": 41.23500061035156,
        "lng": 15.007499694824219
      }
    },
    {
      "codice": "064021",
      "nome": "Cassano Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "B997",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "olgagazerro@hotmail.it",
      "pec": "servizidemografici.cassanoirpino@cert.irpinianet.eu",
      "telefono": "+39 082766146",
      "fax": "+39 082766325",
      "coordinate": {
        "lat": 40.87138748168945,
        "lng": 15.026666641235352
      }
    },
    {
      "codice": "064022",
      "nome": "Castel Baronia",
      "nomeStraniero": null,
      "codiceCatastale": "C058",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "anagrafe@comunecastelbaronia.it",
      "pec": "anagrafe@pec.comunecastelbaronia.it",
      "telefono": "+39 082792008",
      "fax": "+39 082792601",
      "coordinate": {
        "lat": 41.04833221435547,
        "lng": 15.189722061157227
      }
    },
    {
      "codice": "064023",
      "nome": "Castelfranci",
      "nomeStraniero": null,
      "codiceCatastale": "C105",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "info@comune.castelfranci.av.it",
      "pec": "info@pec.comune.castelfranci.av.it",
      "telefono": "+39 0827/72005",
      "fax": "+39 0827/72071",
      "coordinate": {
        "lat": 40.932220458984375,
        "lng": 15.044166564941406
      }
    },
    {
      "codice": "064024",
      "nome": "Castelvetere sul Calore",
      "nomeStraniero": null,
      "codiceCatastale": "C283",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "giulia.petruzziello@comune.castelveteresulcalore.av.it",
      "pec": "amministrativo.castelveteresc@pec.it",
      "telefono": "+39  082765054",
      "fax": "+39 082765648",
      "coordinate": {
        "lat": 40.92972183227539,
        "lng": 14.986944198608398
      }
    },
    {
      "codice": "064025",
      "nome": "Cervinara",
      "nomeStraniero": null,
      "codiceCatastale": "C557",
      "cap": "83012",
      "prefisso": "0824",
      "provincia": "Avellino",
      "email": "info@comune.cervinara.av.it",
      "pec": "ufficioprotocollo.cervinara@asmepec.it",
      "telefono": "+39  0824839823",
      "fax": "+39 0824838825",
      "coordinate": {
        "lat": 41.02138900756836,
        "lng": 14.615278244018555
      }
    },
    {
      "codice": "064026",
      "nome": "Cesinali",
      "nomeStraniero": null,
      "codiceCatastale": "C576",
      "cap": "83020",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.cesinali.av.it",
      "pec": "protocollo@pec.comune.cesinali.av.it",
      "telefono": "0825666125",
      "fax": "+39 0825666289",
      "coordinate": {
        "lat": 40.89805603027344,
        "lng": 14.828611373901367
      }
    },
    {
      "codice": "064027",
      "nome": "Chianche",
      "nomeStraniero": null,
      "codiceCatastale": "C606",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunedichianche@libero.it",
      "pec": "ufficioanagrafe.chianche@asmepec.it",
      "telefono": "+39 0825996003",
      "fax": "+39 0825996172",
      "coordinate": {
        "lat": 41.04722213745117,
        "lng": 14.789999961853027
      }
    },
    {
      "codice": "064028",
      "nome": "Chiusano di San Domenico",
      "nomeStraniero": null,
      "codiceCatastale": "C659",
      "cap": "83040",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunedichiusano@tin.it",
      "pec": "segreteria.chiusano@asmepec.it",
      "telefono": "+39  0825985076",
      "fax": "+39 0825985656",
      "coordinate": {
        "lat": 40.934165954589844,
        "lng": 14.917222023010254
      }
    },
    {
      "codice": "064029",
      "nome": "Contrada",
      "nomeStraniero": null,
      "codiceCatastale": "C971",
      "cap": "83020",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "nicolino@comune.contrada.av.it",
      "pec": "sindaco.contrada@asmepec.it",
      "telefono": "+39  0825674081",
      "fax": "+39 0825660977",
      "coordinate": {
        "lat": 40.866943359375,
        "lng": 14.779722213745117
      }
    },
    {
      "codice": "064030",
      "nome": "Conza della Campania",
      "nomeStraniero": null,
      "codiceCatastale": "C976",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "postaconzacampania@libero.it",
      "pec": "area.amm@pec.comuneconzadellacampania.it",
      "telefono": "+39 0827 39013",
      "fax": "+39 0827 39380",
      "coordinate": {
        "lat": 40.85860061645508,
        "lng": 15.33644962310791
      }
    },
    {
      "codice": "064031",
      "nome": "Domicella",
      "nomeStraniero": null,
      "codiceCatastale": "D331",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "serviziodemografico@comune.domicella.av.it",
      "pec": "demografici.domicella@asmepec.it",
      "telefono": "+39  0818253236",
      "fax": "+39 0818253918",
      "coordinate": {
        "lat": 40.88111114501953,
        "lng": 14.588610649108887
      }
    },
    {
      "codice": "064032",
      "nome": "Flumeri",
      "nomeStraniero": null,
      "codiceCatastale": "D638",
      "cap": "83040",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comunediflumeri.it",
      "pec": "comune.flumeri@pec.riusoprotocollo.it",
      "telefono": "+39 0825 443013",
      "fax": "+39 0825 443482",
      "coordinate": {
        "lat": 41.078887939453125,
        "lng": 15.148611068725586
      }
    },
    {
      "codice": "064033",
      "nome": "Fontanarosa",
      "nomeStraniero": null,
      "codiceCatastale": "D671",
      "cap": "83040",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.fontanarosa.av.it",
      "pec": "anagrafe@pec.comune.fontanarosa.av.it",
      "telefono": "+39 0825/475003",
      "fax": "+39 0825/443482",
      "coordinate": {
        "lat": 41.0191650390625,
        "lng": 15.02138900756836
      }
    },
    {
      "codice": "064034",
      "nome": "Forino",
      "nomeStraniero": null,
      "codiceCatastale": "D701",
      "cap": "83020",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "RAGIONERIA@COMUNE.FORINO.AV.IT",
      "pec": "anagrafe@pec.comune.forino.av.it",
      "telefono": "+39  0825761228",
      "fax": "+39 0825762592",
      "coordinate": {
        "lat": 40.863609313964844,
        "lng": 14.736944198608398
      }
    },
    {
      "codice": "064035",
      "nome": "Frigento",
      "nomeStraniero": null,
      "codiceCatastale": "D798",
      "cap": "83040",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comune@comune.frigento.av.it",
      "pec": "protocollo.frigento@cert.irpinianet.eu",
      "telefono": "+39 0825444004",
      "fax": "+39 0825444123",
      "coordinate": {
        "lat": 41.01222229003906,
        "lng": 15.100555419921875
      }
    },
    {
      "codice": "064036",
      "nome": "Gesualdo",
      "nomeStraniero": null,
      "codiceCatastale": "D998",
      "cap": "83040",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "sindaco@comune.gesualdo.av.it",
      "pec": "segreteriagesualdo@pec.it",
      "telefono": "+39 0825401950",
      "fax": "+39 0825401358",
      "coordinate": {
        "lat": 41.00777816772461,
        "lng": 15.073333740234375
      }
    },
    {
      "codice": "064037",
      "nome": "Greci",
      "nomeStraniero": null,
      "codiceCatastale": "E161",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunedigreci@libero.it",
      "pec": "segretria.greci@asmepec.it",
      "telefono": "+39  0825861196",
      "fax": "+39 0825861042",
      "coordinate": {
        "lat": 41.252777099609375,
        "lng": 15.170000076293945
      }
    },
    {
      "codice": "064038",
      "nome": "Grottaminarda",
      "nomeStraniero": null,
      "codiceCatastale": "E206",
      "cap": "83035",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.grottaminarda.av.it",
      "pec": "protocollo.grottaminarda@asmepec.it",
      "telefono": "+39 0825.445211",
      "fax": "+39 0825 446848",
      "coordinate": {
        "lat": 41.07083511352539,
        "lng": 15.059721946716309
      }
    },
    {
      "codice": "064039",
      "nome": "Grottolella",
      "nomeStraniero": null,
      "codiceCatastale": "E214",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunedigrottolella.comu@tin.it",
      "pec": "comunegrottolella@pec.it",
      "telefono": "+39  0825671017",
      "fax": "+39 0825671942",
      "coordinate": {
        "lat": 40.973609924316406,
        "lng": 14.7877779006958
      }
    },
    {
      "codice": "064040",
      "nome": "Guardia Lombardi",
      "nomeStraniero": null,
      "codiceCatastale": "E245",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "ragioneria@comune.guardialombardi.av.it",
      "pec": "segretario@pec.comune.guardialombardi.av.it",
      "telefono": "+39 0827 41002",
      "fax": "+39 0827 41440",
      "coordinate": {
        "lat": 40.9547233581543,
        "lng": 15.209722518920898
      }
    },
    {
      "codice": "064041",
      "nome": "Lacedonia",
      "nomeStraniero": null,
      "codiceCatastale": "E397",
      "cap": "83046",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "sindaco@comune.lacedonia.av.it",
      "pec": "protocollo@pec.comunelacedonia.it",
      "telefono": "+39 0827 85051",
      "fax": "+39 082785051",
      "coordinate": {
        "lat": 41.052223205566406,
        "lng": 15.424722671508789
      }
    },
    {
      "codice": "064042",
      "nome": "Lapio",
      "nomeStraniero": null,
      "codiceCatastale": "E448",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunelapioanagrafe@virgilio.it",
      "pec": "anagrafe.lapio@asmepec.it",
      "telefono": "+39 0825 982005",
      "fax": "+39 0825 982351",
      "coordinate": {
        "lat": 40.98222351074219,
        "lng": 14.947221755981445
      }
    },
    {
      "codice": "064043",
      "nome": "Lauro",
      "nomeStraniero": null,
      "codiceCatastale": "E487",
      "cap": "83023",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "ANAGRAFE@COMUNE.LAURO.AV.IT",
      "pec": "ragioneria.lauro@asmepec.it",
      "telefono": "+39 0818240265",
      "fax": "+39 0818240296",
      "coordinate": {
        "lat": 40.87916564941406,
        "lng": 14.633055686950684
      }
    },
    {
      "codice": "064044",
      "nome": "Lioni",
      "nomeStraniero": null,
      "codiceCatastale": "E605",
      "cap": "83047",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "elettorale@comune.lioni.av.it",
      "pec": "protocollo@pec.comune.lioni.av.it",
      "telefono": "+39082742025",
      "fax": "+39082742127",
      "coordinate": {
        "lat": 40.877498626708984,
        "lng": 15.188611030578613
      }
    },
    {
      "codice": "064045",
      "nome": "Luogosano",
      "nomeStraniero": null,
      "codiceCatastale": "E746",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "utc@comune.luogosano.av.it",
      "pec": "anagrafe.luogosano@cert.irpinianet.eu",
      "telefono": "+39  082773007",
      "fax": "+39 082778028",
      "coordinate": {
        "lat": 40.98833465576172,
        "lng": 14.991666793823242
      }
    },
    {
      "codice": "064046",
      "nome": "Manocalzati",
      "nomeStraniero": null,
      "codiceCatastale": "E891",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "ragioneria@comune.manocalzati.av.it",
      "pec": "protocollo.manocalzati@asmepec.it",
      "telefono": "+39  0825675031",
      "fax": "+39 0825675581",
      "coordinate": {
        "lat": 40.942501068115234,
        "lng": 14.850555419921875
      }
    },
    {
      "codice": "064047",
      "nome": "Marzano di Nola",
      "nomeStraniero": null,
      "codiceCatastale": "E997",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "segreteriamarzano@libero.it",
      "pec": "sindaco.marzano@asmepec.it",
      "telefono": "+39 081 8255303",
      "fax": "+39 081 8255753",
      "coordinate": {
        "lat": 40.90361022949219,
        "lng": 14.584722518920898
      }
    },
    {
      "codice": "064048",
      "nome": "Melito Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "F110",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "respsegreteria@comune.melitoirpino.av.it",
      "pec": "comune.melitoirpino@pec.riusoprotocollo.it",
      "telefono": "+39  0825472085",
      "fax": "+39 0825472842",
      "coordinate": {
        "lat": 41.10416793823242,
        "lng": 15.053333282470703
      }
    },
    {
      "codice": "064049",
      "nome": "Mercogliano",
      "nomeStraniero": null,
      "codiceCatastale": "F141",
      "cap": "83013",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comunemercogliano.it",
      "pec": "protocollo.mercogliano@pec.it",
      "telefono": "+39 0825689841",
      "fax": "+39 0825787220",
      "coordinate": {
        "lat": 40.92305374145508,
        "lng": 14.742777824401855
      }
    },
    {
      "codice": "064050",
      "nome": "Mirabella Eclano",
      "nomeStraniero": null,
      "codiceCatastale": "F230",
      "cap": "83036",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "e.dipietro@comune.mirabellaeclano.av.it",
      "pec": "protocollo.mirabellaeclano@pec.it",
      "telefono": "+39  0825438077",
      "fax": "+39 0825438170",
      "coordinate": {
        "lat": 41.045833587646484,
        "lng": 14.999722480773926
      }
    },
    {
      "codice": "064051",
      "nome": "Montaguto",
      "nomeStraniero": null,
      "codiceCatastale": "F397",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunedimontagutoav@virgilio.it",
      "pec": "peccomunemontaguto@asmepec.it",
      "telefono": "+39 0825862014",
      "fax": "+39 0825862096",
      "coordinate": {
        "lat": 41.24972152709961,
        "lng": 15.249166488647461
      }
    },
    {
      "codice": "064052",
      "nome": "Montecalvo Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "F448",
      "cap": "83037",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "uffici@comune.montecalvoirpino.av.it",
      "pec": "prot.comunemontecalvoirpino@legalkosmos.com",
      "telefono": "+39 0825 818083",
      "fax": "+39 0825 819281",
      "coordinate": {
        "lat": 41.1966667175293,
        "lng": 15.03416633605957
      }
    },
    {
      "codice": "064053",
      "nome": "Montefalcione",
      "nomeStraniero": null,
      "codiceCatastale": "F491",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.montefalcione.av.it",
      "pec": "segreteriasindaco.montefalcione@cert.irpinianet.eu",
      "telefono": "+39 0825973021",
      "fax": "+39 0825973780",
      "coordinate": {
        "lat": 40.962501525878906,
        "lng": 14.884721755981445
      }
    },
    {
      "codice": "064054",
      "nome": "Monteforte Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "F506",
      "cap": "83024",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "vvecchione@comune.monteforteirpino.av.it",
      "pec": "protocollo.monteforteirpino.av@asmepec.it",
      "telefono": "+39  0825752210",
      "fax": "+39 0825752209",
      "coordinate": {
        "lat": 40.89277648925781,
        "lng": 14.719444274902344
      }
    },
    {
      "codice": "064055",
      "nome": "Montefredane",
      "nomeStraniero": null,
      "codiceCatastale": "F511",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "fredane@tin.it",
      "pec": "montefredane@asmepec.it",
      "telefono": "+39 0825 672146",
      "fax": "+39 0825 672280",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "064056",
      "nome": "Montefusco",
      "nomeStraniero": null,
      "codiceCatastale": "F512",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "panza@comune.montefusco.av.it",
      "pec": "anagrafe.montefusco@asmepec.it",
      "telefono": "+39 0825964003",
      "fax": "+39 0825964643",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "064057",
      "nome": "Montella",
      "nomeStraniero": null,
      "codiceCatastale": "F546",
      "cap": "83048",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "demografici@comunemontella.it",
      "pec": "protocollo.montella@asmepec.it",
      "telefono": "+39 0827609006",
      "fax": "+39 0827601303",
      "coordinate": {
        "lat": 40.849998474121094,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "064058",
      "nome": "Montemarano",
      "nomeStraniero": null,
      "codiceCatastale": "F559",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "protocollo@comune.montemarano.av.it",
      "pec": "protocollo.mont@pec.it",
      "telefono": "+39 0827 63012",
      "fax": "+39 0827 63252",
      "coordinate": {
        "lat": 40.91611099243164,
        "lng": 14.998332977294922
      }
    },
    {
      "codice": "064059",
      "nome": "Montemiletto",
      "nomeStraniero": null,
      "codiceCatastale": "F566",
      "cap": "83038",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "prot@comunemontemiletto.gov.it",
      "pec": "comunedimontemiletto@legalmail.it",
      "telefono": "+39 0825963003",
      "fax": "+39 0825963003",
      "coordinate": {
        "lat": 41.016666412353516,
        "lng": 14.899999618530273
      }
    },
    {
      "codice": "064060",
      "nome": "Monteverde",
      "nomeStraniero": null,
      "codiceCatastale": "F660",
      "cap": "83049",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "anagrafe@comune.monteverde.av.it",
      "pec": "comune.monteverde@asmepec.it",
      "telefono": "+39 0827 86043",
      "fax": "+39 0827 86303",
      "coordinate": {
        "lat": 40.99972152709961,
        "lng": 15.53499984741211
      }
    },
    {
      "codice": "064121",
      "nome": "Montoro",
      "nomeStraniero": null,
      "codiceCatastale": "M330",
      "cap": "83025",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "urp@mi.av.it",
      "pec": "info.montoroinferiore@asmepec.it",
      "telefono": "+39 0825 502021",
      "fax": "+39 0825 503770",
      "coordinate": {
        "lat": 40.82416534423828,
        "lng": 14.761388778686523
      }
    },
    {
      "codice": "064063",
      "nome": "Morra De Sanctis",
      "nomeStraniero": null,
      "codiceCatastale": "F744",
      "cap": "83040",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "segretario@comune.morradesanctis.av.it",
      "pec": "segretario@pec.comune.morradesanctis.av.it",
      "telefono": "+39 082743021",
      "fax": "+39 082743081",
      "coordinate": {
        "lat": 40.93000030517578,
        "lng": 15.243611335754395
      }
    },
    {
      "codice": "064064",
      "nome": "Moschiano",
      "nomeStraniero": null,
      "codiceCatastale": "F762",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "protocollo@comune.moschiano.av.it",
      "pec": "comune.moschiano@asmepec.it",
      "telefono": "0818249916",
      "fax": null,
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "064065",
      "nome": "Mugnano del Cardinale",
      "nomeStraniero": null,
      "codiceCatastale": "F798",
      "cap": "83027",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "mugnanoc@libero.it",
      "pec": "protocollo.mugnanodelcardinale@asmepec.it",
      "telefono": "+39  0815112501",
      "fax": "+39 0815112533",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "064066",
      "nome": "Nusco",
      "nomeStraniero": null,
      "codiceCatastale": "F988",
      "cap": "83051",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "segreteriappa@comune.nusco.av.it",
      "pec": "protocollo.nusco@asmepec.it",
      "telefono": "+39 0827/64942",
      "fax": "+39 0827/64496",
      "coordinate": {
        "lat": 40.88694381713867,
        "lng": 15.08388900756836
      }
    },
    {
      "codice": "064067",
      "nome": "Ospedaletto d'Alpinolo",
      "nomeStraniero": null,
      "codiceCatastale": "G165",
      "cap": "83014",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comuneospedaletto.it",
      "pec": "anagrafe.ospedaletto@asmepec.it",
      "telefono": "+39 0825691209",
      "fax": "+39 0825691095",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.75
      }
    },
    {
      "codice": "064068",
      "nome": "Pago del Vallo di Lauro",
      "nomeStraniero": null,
      "codiceCatastale": "G242",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "comunedipagodelvallod1@tin.it",
      "pec": "comune.pagovallolauro@asmepec.it",
      "telefono": "+39 0818250372",
      "fax": "+39 0818250811",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "064069",
      "nome": "Parolise",
      "nomeStraniero": null,
      "codiceCatastale": "G340",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "antacer@tin.it",
      "pec": "segretario.parolise@asmepec.it",
      "telefono": "+39  0825981031",
      "fax": "+39 0825981301",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "064070",
      "nome": "Paternopoli",
      "nomeStraniero": null,
      "codiceCatastale": "G370",
      "cap": "83052",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "info@comune.paternopoli.av.it",
      "pec": "comunepaternopoli@asmepec.it",
      "telefono": "+39 0827.71002",
      "fax": "+39 0827.71879",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 15.033333778381348
      }
    },
    {
      "codice": "064071",
      "nome": "Petruro Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "G519",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.petruroirpino.av.it",
      "pec": "comune.petruroirpino@legalmail.it",
      "telefono": "+39  0825996115",
      "fax": "+39 0825996115",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "064072",
      "nome": "Pietradefusi",
      "nomeStraniero": null,
      "codiceCatastale": "G611",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.pietradefusi.av.it",
      "pec": "comune.pietradefusi@asmepec.it",
      "telefono": "+39 0825 962090",
      "fax": "+39 0825 962445",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "064073",
      "nome": "Pietrastornina",
      "nomeStraniero": null,
      "codiceCatastale": "G629",
      "cap": "83015",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.pietrastornina.av.it",
      "pec": "comune.pietrastornina@asmepec.it",
      "telefono": "+39 0825902290",
      "fax": "+39 0825902921",
      "coordinate": {
        "lat": 41,
        "lng": 14.733333587646484
      }
    },
    {
      "codice": "064074",
      "nome": "Prata di Principato Ultra",
      "nomeStraniero": null,
      "codiceCatastale": "G990",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "segreteria2@comune.pratadiprincipatoultra.av.it",
      "pec": "comune.prata@cert.irpinianet.eu",
      "telefono": "0825952053",
      "fax": null,
      "coordinate": {
        "lat": 40.983333587646484,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "064075",
      "nome": "Pratola Serra",
      "nomeStraniero": null,
      "codiceCatastale": "H006",
      "cap": "83039",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "segreteria@pratolaserra.gov.it",
      "pec": "pratolaserra@asmepec.it",
      "telefono": "+39 0825967816",
      "fax": "+39 0825967815",
      "coordinate": {
        "lat": 40.983333587646484,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "064076",
      "nome": "Quadrelle",
      "nomeStraniero": null,
      "codiceCatastale": "H097",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "protocollo@comune.quadrelle.av.it",
      "pec": "comune.quadrelle@anutel.it",
      "telefono": "+39 081-8257305",
      "fax": "+39 081-8257305",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "064077",
      "nome": "Quindici",
      "nomeStraniero": null,
      "codiceCatastale": "H128",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "comunexv@libero.it",
      "pec": "comunexv@pec.comunequindici.it",
      "telefono": "+39 0815102499",
      "fax": "+39 0815104000",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.649999618530273
      }
    },
    {
      "codice": "064079",
      "nome": "Rocca San Felice",
      "nomeStraniero": null,
      "codiceCatastale": "H438",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "roccasanfelice@tiscalinet.it",
      "pec": "elettorato-roccas.felice@asmepec.it",
      "telefono": "+39 0827/45031",
      "fax": "+39 0827/45031",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "064078",
      "nome": "Roccabascerana",
      "nomeStraniero": null,
      "codiceCatastale": "H382",
      "cap": "83016",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comuneroccabascerana@libero.it",
      "pec": "segreteria.roccabascerana@asmepec.it",
      "telefono": "+39  0825993027",
      "fax": "+39 0825993330",
      "coordinate": {
        "lat": 41.016666412353516,
        "lng": 14.716666221618652
      }
    },
    {
      "codice": "064080",
      "nome": "Rotondi",
      "nomeStraniero": null,
      "codiceCatastale": "H592",
      "cap": "83017",
      "prefisso": "0824",
      "provincia": "Avellino",
      "email": "affarigenerali@comune.rotondi.av.it",
      "pec": "affari.generali@asmepec.it",
      "telefono": "+39  0824843264",
      "fax": "+39 0824843830",
      "coordinate": {
        "lat": 41.032501220703125,
        "lng": 14.595833778381348
      }
    },
    {
      "codice": "064081",
      "nome": "Salza Irpina",
      "nomeStraniero": null,
      "codiceCatastale": "H733",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comsalzairpina@yahoo.it",
      "pec": "anagrafe.salzairpina@asmepec.it",
      "telefono": "+39 0825981175",
      "fax": "+39 0825981175",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "064082",
      "nome": "San Mango sul Calore",
      "nomeStraniero": null,
      "codiceCatastale": "H975",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "info@comune.sanmangosulcalore.av.it",
      "pec": "sindaco.sanmangosulcalore@pec.it",
      "telefono": "+39 0827 75358",
      "fax": "+39 0827 75109",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "064083",
      "nome": "San Martino Valle Caudina",
      "nomeStraniero": null,
      "codiceCatastale": "I016",
      "cap": "83018",
      "prefisso": "0824",
      "provincia": "Avellino",
      "email": "comune@sanmartinovc.it",
      "pec": "segreteria.sanmartinovc@asmepec.it",
      "telefono": "+39 0824 841554",
      "fax": "+39 0824 841859",
      "coordinate": {
        "lat": 41.0265998840332,
        "lng": 14.665399551391602
      }
    },
    {
      "codice": "064084",
      "nome": "San Michele di Serino",
      "nomeStraniero": null,
      "codiceCatastale": "I034",
      "cap": "83020",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "protocollo.sanmichelediserino@asmepec.it",
      "pec": "protocollo.sanmichelediserino@asmepec.it",
      "telefono": "+39 0825590902",
      "fax": "+39 0825595658",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "064085",
      "nome": "San Nicola Baronia",
      "nomeStraniero": null,
      "codiceCatastale": "I061",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "ragioneria@comune.san-nicola-baronia.av.it",
      "pec": "uscsannicolabaronia@pec.it",
      "telefono": "+39  082792034",
      "fax": "+39  082792034",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 15.183333396911621
      }
    },
    {
      "codice": "064086",
      "nome": "San Potito Ultra",
      "nomeStraniero": null,
      "codiceCatastale": "I129",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.sanpotitoultra.av.it",
      "pec": "anagrafe.sanpotitoultra@asmepec.it",
      "telefono": "+39 0825981005",
      "fax": "+39 0825981072",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "064087",
      "nome": "San Sossio Baronia",
      "nomeStraniero": null,
      "codiceCatastale": "I163",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "protocollo@comune.sansossiobaronia.av.it",
      "pec": "protocollo.sansossiobaronia@cert.irpinianet.eu",
      "telefono": "+39 0827 94022",
      "fax": "+39 0827 94515",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 15.199999809265137
      }
    },
    {
      "codice": "064089",
      "nome": "Sant'Andrea di Conza",
      "nomeStraniero": null,
      "codiceCatastale": "I264",
      "cap": "83053",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "santandrea.comune@tiscali.it",
      "pec": "segretario.santandrea@asmepec.it",
      "telefono": "+39  082735010",
      "fax": "+39 082735004",
      "coordinate": {
        "lat": 40.849998474121094,
        "lng": 15.366666793823242
      }
    },
    {
      "codice": "064091",
      "nome": "Sant'Angelo a Scala",
      "nomeStraniero": null,
      "codiceCatastale": "I280",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "santangeloascala@inwind.it",
      "pec": "santangeloascala@asmepec.it",
      "telefono": "+39 0825902063",
      "fax": "+39 0825902788",
      "coordinate": {
        "lat": 40.97638702392578,
        "lng": 14.740278244018555
      }
    },
    {
      "codice": "064090",
      "nome": "Sant'Angelo all'Esca",
      "nomeStraniero": null,
      "codiceCatastale": "I279",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "info@comune.santangeloallesca.av.it",
      "pec": "demografici@pec.comune.santangeloallesca.av.it",
      "telefono": "+39 0827/73259",
      "fax": "+39 0827/73441",
      "coordinate": {
        "lat": 41.016666412353516,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "064092",
      "nome": "Sant'Angelo dei Lombardi",
      "nomeStraniero": null,
      "codiceCatastale": "I281",
      "cap": "83054",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "comune@comune.santangelodeilombardi.av.it",
      "pec": "segretario.santangelodeilombardi@asmepec.it",
      "telefono": "+39 0827 23094",
      "fax": "+39 0827 23570",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 15.183333396911621
      }
    },
    {
      "codice": "064088",
      "nome": "Santa Lucia di Serino",
      "nomeStraniero": null,
      "codiceCatastale": "I219",
      "cap": "83020",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.santaluciadiserino.av.it",
      "pec": "protocollo.santaluciadiserino@asmepec.it",
      "telefono": "+39 0825-512831",
      "fax": "+39 0825512831",
      "coordinate": {
        "lat": 40.86666488647461,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "064093",
      "nome": "Santa Paolina",
      "nomeStraniero": null,
      "codiceCatastale": "I301",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comunesantapaolina.gov.it",
      "pec": "comunesantapaolina@pec.it",
      "telefono": "+39 0825 964076",
      "fax": "+39 0825 964076",
      "coordinate": {
        "lat": 41.02277755737305,
        "lng": 14.848055839538574
      }
    },
    {
      "codice": "064095",
      "nome": "Santo Stefano del Sole",
      "nomeStraniero": null,
      "codiceCatastale": "I357",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "settoreamministrativo@comune.santostefanodelsole.av.it",
      "pec": "comunesantostefanodelsole@legalmail.it",
      "telefono": "+39 0825673053",
      "fax": "+39 0825673444",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "064096",
      "nome": "Savignano Irpino",
      "nomeStraniero": null,
      "codiceCatastale": "I471",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comune.savignano.av.it",
      "pec": "anagrafesavignanoirpino@pec.it",
      "telefono": "+39 0825867009",
      "fax": "+39 0825867361",
      "coordinate": {
        "lat": 41.233333587646484,
        "lng": 15.183333396911621
      }
    },
    {
      "codice": "064097",
      "nome": "Scampitella",
      "nomeStraniero": null,
      "codiceCatastale": "I493",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "comune-scampitella@libero.it",
      "pec": "anagrafe.scampitella@asmepec.it",
      "telefono": "+39  082793031",
      "fax": "+39  082793101",
      "coordinate": {
        "lat": 41.099998474121094,
        "lng": 15.283333778381348
      }
    },
    {
      "codice": "064098",
      "nome": "Senerchia",
      "nomeStraniero": null,
      "codiceCatastale": "I606",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "info@comune.senerchia.av.it",
      "pec": "info.senerchia@asmepec.it",
      "telefono": "+39 0827 57031",
      "fax": "+39 0827 57015",
      "coordinate": {
        "lat": 40.74166488647461,
        "lng": 15.204166412353516
      }
    },
    {
      "codice": "064099",
      "nome": "Serino",
      "nomeStraniero": null,
      "codiceCatastale": "I630",
      "cap": "83028",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.serino.av.it",
      "pec": "segreteria.serino@asmepec.it",
      "telefono": "+39 0825.594025",
      "fax": "+39 0825 592539",
      "coordinate": {
        "lat": 40.849998474121094,
        "lng": 14.875
      }
    },
    {
      "codice": "064100",
      "nome": "Sirignano",
      "nomeStraniero": null,
      "codiceCatastale": "I756",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "anagrafe@comune.sirignano.av.it",
      "pec": "anagrafe.sirignano@asmepec.it",
      "telefono": "+39 081 5111570",
      "fax": "+39 081 5111625",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "064101",
      "nome": "Solofra",
      "nomeStraniero": null,
      "codiceCatastale": "I805",
      "cap": "83029",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "ufficio_tecnico@comune.solofra.av.it",
      "pec": "ingegnerecapo.solofra@asmepec.it",
      "telefono": "+39  0825582430",
      "fax": "+39 0825532494",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "064102",
      "nome": "Sorbo Serpico",
      "nomeStraniero": null,
      "codiceCatastale": "I843",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comunedisorboserpico@virgilio.it",
      "pec": "anagrafe.sorboserpico@asmepec.it",
      "telefono": "+39  0825981053",
      "fax": "+39 0825986516",
      "coordinate": {
        "lat": 40.91666793823242,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "064103",
      "nome": "Sperone",
      "nomeStraniero": null,
      "codiceCatastale": "I893",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": null,
      "pec": "affarigenerali.sperone@pec.it",
      "telefono": "+39 0818251160",
      "fax": "+39 0815103505",
      "coordinate": {
        "lat": 40.95349884033203,
        "lng": 14.606111526489258
      }
    },
    {
      "codice": "064104",
      "nome": "Sturno",
      "nomeStraniero": null,
      "codiceCatastale": "I990",
      "cap": "83055",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "anagrafe@comune.sturno.av.it",
      "pec": "anagrafe.sturno@asmepec.it",
      "telefono": "+39 0825448003",
      "fax": "+39 0825448639",
      "coordinate": {
        "lat": 41.016666412353516,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "064105",
      "nome": "Summonte",
      "nomeStraniero": null,
      "codiceCatastale": "L004",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "segreteria@comune.summonte.av.it",
      "pec": "protocollo.summonte@pec.it",
      "telefono": "+39 0825 691191",
      "fax": "+39 0825 691828",
      "coordinate": {
        "lat": 40.95000076293945,
        "lng": 14.75
      }
    },
    {
      "codice": "064106",
      "nome": "Taurano",
      "nomeStraniero": null,
      "codiceCatastale": "L061",
      "cap": "83020",
      "prefisso": "081",
      "provincia": "Avellino",
      "email": "urtaurano@libero.it",
      "pec": "comuneditaurano@legalmail.it",
      "telefono": "+39 081 8240092",
      "fax": "+39 081 8240433",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "064107",
      "nome": "Taurasi",
      "nomeStraniero": null,
      "codiceCatastale": "L062",
      "cap": "83030",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "tofitaurasi@libero.it",
      "pec": "protocollo@pec.comune.taurasi.av.it",
      "telefono": "+39 082774004",
      "fax": "+39 082774204",
      "coordinate": {
        "lat": 41,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "064108",
      "nome": "Teora",
      "nomeStraniero": null,
      "codiceCatastale": "L102",
      "cap": "83056",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "protocollo@comune.teora.av.it",
      "pec": "comune.teora@asmepec.it",
      "telefono": "+39 082751005",
      "fax": "+39 082751431",
      "coordinate": {
        "lat": 40.85222244262695,
        "lng": 15.253889083862305
      }
    },
    {
      "codice": "064109",
      "nome": "Torella dei Lombardi",
      "nomeStraniero": null,
      "codiceCatastale": "L214",
      "cap": "83057",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "anagrafe@comune.torelladeilombardi.av.it",
      "pec": "anagrafe.torella@cert.irpinianet.eu",
      "telefono": "+39 0827 44060",
      "fax": "+39 0827 49209",
      "coordinate": {
        "lat": 40.93333435058594,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "064110",
      "nome": "Torre Le Nocelle",
      "nomeStraniero": null,
      "codiceCatastale": "L272",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "elettorale@comune.torrelenocelle.av.it",
      "pec": null,
      "telefono": "+39 0825969035",
      "fax": null,
      "coordinate": {
        "lat": 41.016666412353516,
        "lng": 14.899999618530273
      }
    },
    {
      "codice": "064111",
      "nome": "Torrioni",
      "nomeStraniero": null,
      "codiceCatastale": "L301",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "comuneditorrioni@gmail.com",
      "pec": "comune.torrioni@pec.riusoprotocollo.it",
      "telefono": "+39 0825 996109",
      "fax": "+39 0825 996109",
      "coordinate": {
        "lat": 41.03499984741211,
        "lng": 14.813333511352539
      }
    },
    {
      "codice": "064112",
      "nome": "Trevico",
      "nomeStraniero": null,
      "codiceCatastale": "L399",
      "cap": "83058",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "municipiotrevico@yahoo.it",
      "pec": "segreteria@pec.comune.trevico.av.it",
      "telefono": "+39  082796014",
      "fax": "+39  082796144",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 15.233333587646484
      }
    },
    {
      "codice": "064113",
      "nome": "Tufo",
      "nomeStraniero": null,
      "codiceCatastale": "L461",
      "cap": "83010",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "segreteria@comunetufo.it",
      "pec": "segreteria.tufo@cert.irpinianet.eu",
      "telefono": "+39  0825998071",
      "fax": "+39 0825998071",
      "coordinate": {
        "lat": 41.01261901855469,
        "lng": 14.817839622497559
      }
    },
    {
      "codice": "064114",
      "nome": "Vallata",
      "nomeStraniero": null,
      "codiceCatastale": "L589",
      "cap": "83059",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "comune.vallata@comune.vallata.av.it",
      "pec": "comune.vallata@asmepec.it",
      "telefono": "+39 082791008",
      "fax": "+39 082791870",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 15.25
      }
    },
    {
      "codice": "064115",
      "nome": "Vallesaccarda",
      "nomeStraniero": null,
      "codiceCatastale": "L616",
      "cap": "83050",
      "prefisso": "0827",
      "provincia": "Avellino",
      "email": "info@comune-vallesaccarda.it",
      "pec": "protocollo@pec.comune-vallesaccarda.it",
      "telefono": "+39  082797034",
      "fax": "+39  082797463",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 15.25
      }
    },
    {
      "codice": "064116",
      "nome": "Venticano",
      "nomeStraniero": null,
      "codiceCatastale": "L739",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "segretario@comune.venticano.av.it",
      "pec": "sindaco.venticano@cert.irpinianet.eu",
      "telefono": "+39 0825965033",
      "fax": "+39 0825965380",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "064117",
      "nome": "Villamaina",
      "nomeStraniero": null,
      "codiceCatastale": "L965",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "protocollo@pec.comunevillamaina.it",
      "pec": "protocollo@pec.comunevillamaina.it",
      "telefono": "+39 0825 442083",
      "fax": "+39 0825 442103",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "064118",
      "nome": "Villanova del Battista",
      "nomeStraniero": null,
      "codiceCatastale": "L973",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "villanovadelbattista@tin.it",
      "pec": "prot.villanovadelbattista@asmepec.it",
      "telefono": "+39  0825826015",
      "fax": "+39 0825820700",
      "coordinate": {
        "lat": 41.11666488647461,
        "lng": 15.166666984558105
      }
    },
    {
      "codice": "064119",
      "nome": "Volturara Irpina",
      "nomeStraniero": null,
      "codiceCatastale": "M130",
      "cap": "83050",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "giacomo.monzione.elettorale@comune.volturarairpina.av.it",
      "pec": "demografici.volturarairpina@pec.it",
      "telefono": "+39 0825984024",
      "fax": "+39 0825984216",
      "coordinate": {
        "lat": 40.88333511352539,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "064120",
      "nome": "Zungoli",
      "nomeStraniero": null,
      "codiceCatastale": "M203",
      "cap": "83030",
      "prefisso": "0825",
      "provincia": "Avellino",
      "email": "info@comunezungoli.it",
      "pec": "protocollo.comunezungoli@pec.it",
      "telefono": "+390825845037",
      "fax": "+390825845418",
      "coordinate": {
        "lat": 41.12638854980469,
        "lng": 15.204444885253906
      }
    }
  ],
  "Caserta": [
    {
      "codice": "061001",
      "nome": "Ailano",
      "nomeStraniero": null,
      "codiceCatastale": "A106",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comune.ailano@tin.it",
      "pec": "protocollo@pec.comune.ailano.ce.it",
      "telefono": "+39 0823943024",
      "fax": "+39 0823943055",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "061002",
      "nome": "Alife",
      "nomeStraniero": null,
      "codiceCatastale": "A200",
      "cap": "81011",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ced@comune.alife.ce.it",
      "pec": "ced@pec.comune.alife.ce.it",
      "telefono": "+39 0823 910409",
      "fax": "+39 0823787450",
      "coordinate": {
        "lat": 41.33333206176758,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "061003",
      "nome": "Alvignano",
      "nomeStraniero": null,
      "codiceCatastale": "A243",
      "cap": "81012",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioelettorale@alvignano.gov.it",
      "pec": "elettorale.alvignano@alphapec.it",
      "telefono": "+39 0823 614631-11",
      "fax": "+39 0823 614620",
      "coordinate": {
        "lat": 41.25,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "061004",
      "nome": "Arienzo",
      "nomeStraniero": null,
      "codiceCatastale": "A403",
      "cap": "81021",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": null,
      "pec": "pec.comunearienzo@asmepec.it",
      "telefono": "+39 0823 805987",
      "fax": "+39 0823 805987",
      "coordinate": {
        "lat": 41.027339935302734,
        "lng": 14.497699737548828
      }
    },
    {
      "codice": "061005",
      "nome": "Aversa",
      "nomeStraniero": null,
      "codiceCatastale": "A512",
      "cap": "81031",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "elettorale@aversa.org",
      "pec": "elettorale.pec@comuneaversa.it",
      "telefono": "+39 081 5049125",
      "fax": "+39 081 5049125",
      "coordinate": {
        "lat": 40.9730339050293,
        "lng": 14.206475257873535
      }
    },
    {
      "codice": "061006",
      "nome": "Baia e Latina",
      "nomeStraniero": null,
      "codiceCatastale": "A579",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": null,
      "pec": "protocollo.baiaelatina@asmepec.it",
      "telefono": "+39 0823 980079",
      "fax": "+39 0823 645053",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 14.25
      }
    },
    {
      "codice": "061007",
      "nome": "Bellona",
      "nomeStraniero": null,
      "codiceCatastale": "A755",
      "cap": "81041",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "demografico@comune.bellona.ce.it",
      "pec": "elettorale.bellona@asmepec.it",
      "telefono": "+39 0823 966484",
      "fax": null,
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "061008",
      "nome": "Caianello",
      "nomeStraniero": null,
      "codiceCatastale": "B361",
      "cap": "81059",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "protocollo@comune.caianello.ce.it",
      "pec": "protocollo@pec.comune.caianello.ce.it",
      "telefono": "+39 0823 922432",
      "fax": "+39 0823 922723",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "061009",
      "nome": "Caiazzo",
      "nomeStraniero": null,
      "codiceCatastale": "B362",
      "cap": "81013",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioanagrafe@comune.caiazzo.ce.it",
      "pec": "demografici-elettorale@pec.comune.caiazzo.ce.it",
      "telefono": "+39 0823 615736",
      "fax": null,
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "061010",
      "nome": "Calvi Risorta",
      "nomeStraniero": null,
      "codiceCatastale": "B445",
      "cap": "81042",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe.cales@virgilio.it",
      "pec": "protocollo.cales@pec.terradilavorocst.it",
      "telefono": "+39 0823 570835",
      "fax": "+39 0823 570835",
      "coordinate": {
        "lat": 41.21888732910156,
        "lng": 14.129166603088379
      }
    },
    {
      "codice": "061011",
      "nome": "Camigliano",
      "nomeStraniero": null,
      "codiceCatastale": "B477",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunecamigliano@libero.it",
      "pec": "protocollo.camigliano@asmepec.it",
      "telefono": "+39 0823/879520",
      "fax": "+39 0823/879391",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "061012",
      "nome": "Cancello ed Arnone",
      "nomeStraniero": null,
      "codiceCatastale": "B581",
      "cap": "81030",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe.cancelloarnone@virgilio.it",
      "pec": "anagrafe.cancelloarnone@pec.it",
      "telefono": "+39 0823/856648",
      "fax": "+39 0823/856199",
      "coordinate": {
        "lat": 41.07339859008789,
        "lng": 14.025420188903809
      }
    },
    {
      "codice": "061013",
      "nome": "Capodrise",
      "nomeStraniero": null,
      "codiceCatastale": "B667",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": null,
      "pec": "servizidemografici.capodrise@alphapec.it",
      "telefono": "+39 0823836200/208",
      "fax": null,
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "061014",
      "nome": "Capriati a Volturno",
      "nomeStraniero": null,
      "codiceCatastale": "B704",
      "cap": "81014",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comune@capriati.it",
      "pec": "protocollo.capriati@asmepec.it",
      "telefono": "+39 0823 944600",
      "fax": "+39 0823 944953",
      "coordinate": {
        "lat": 41.467777252197266,
        "lng": 14.146666526794434
      }
    },
    {
      "codice": "061015",
      "nome": "Capua",
      "nomeStraniero": null,
      "codiceCatastale": "B715",
      "cap": "81043",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunecapua@comunedicapua.it",
      "pec": "comunedicapua@pec.comunedicapua.it",
      "telefono": "+39 0823 560111",
      "fax": "+39 0823 560245",
      "coordinate": {
        "lat": 41.10555648803711,
        "lng": 14.213889122009277
      }
    },
    {
      "codice": "061016",
      "nome": "Carinaro",
      "nomeStraniero": null,
      "codiceCatastale": "B779",
      "cap": "81032",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "istruzione@comune.carinaro.ce.it",
      "pec": "protocollo.carinaro@asmepec.it",
      "telefono": "+39 081 5029244",
      "fax": "+39 081 5029240",
      "coordinate": {
        "lat": 40.983333587646484,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "061017",
      "nome": "Carinola",
      "nomeStraniero": null,
      "codiceCatastale": "B781",
      "cap": "81030",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe@comune.carinola.ce.it",
      "pec": "anagrafe@pec.comune.carinola.ce.it",
      "telefono": "+39 0823/734214",
      "fax": "+39 0823/737142",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "061018",
      "nome": "Casagiove",
      "nomeStraniero": null,
      "codiceCatastale": "B860",
      "cap": "81022",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "oreste.laperuta@gmail.com",
      "pec": "protocollogenerale@cittacasagiove.legalmail.it",
      "telefono": "+39 0823/252111",
      "fax": "+39 0823/493727",
      "coordinate": {
        "lat": 41.07569885253906,
        "lng": 14.308600425720215
      }
    },
    {
      "codice": "061019",
      "nome": "Casal di Principe",
      "nomeStraniero": null,
      "codiceCatastale": "B872",
      "cap": "81033",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "segreteria@comunecasaldiprincipe.it",
      "pec": "protocollo@pec.comunecasaldiprincipe.it",
      "telefono": "+39 081/8166011",
      "fax": "+39 081/8166010",
      "coordinate": {
        "lat": 41.010833740234375,
        "lng": 14.13194465637207
      }
    },
    {
      "codice": "061020",
      "nome": "Casaluce",
      "nomeStraniero": null,
      "codiceCatastale": "B916",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "protocollo@pec.comune.casaluce.ce.it",
      "pec": "anagrafe.elettorale@pec.coune.casaluce.ce.it",
      "telefono": "+39 081/8911028",
      "fax": "+39 081/8911033",
      "coordinate": {
        "lat": 41.00196075439453,
        "lng": 14.19834041595459
      }
    },
    {
      "codice": "061103",
      "nome": "Casapesenna",
      "nomeStraniero": null,
      "codiceCatastale": "M260",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "comune-casapesenna@libero.it",
      "pec": "comune.casapesenna@pec.it",
      "telefono": "+39 081/8165611",
      "fax": "+39 081/8165640",
      "coordinate": {
        "lat": 40.99155044555664,
        "lng": 14.142698287963867
      }
    },
    {
      "codice": "061021",
      "nome": "Casapulla",
      "nomeStraniero": null,
      "codiceCatastale": "B935",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@comune.casapulla.ce.it",
      "pec": "affarigenerali.casapulla@asmepec.it",
      "telefono": "+39 0823/495082",
      "fax": "+39 0823/493326",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "061022",
      "nome": "Caserta",
      "nomeStraniero": null,
      "codiceCatastale": "B963",
      "cap": "81100",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioelettorale@comune.caserta.it",
      "pec": "postacertificata@pec.comune.caserta.it",
      "telefono": "+39 0823/273111",
      "fax": "+39 0823/353701",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "061023",
      "nome": "Castel Campagnano",
      "nomeStraniero": null,
      "codiceCatastale": "B494",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunedicastelcampagnano@virgilio.it",
      "pec": "protocollo.castelcampagnano@asmepec.it",
      "telefono": "+39 0823/863616",
      "fax": "+39 0823/863616",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "061024",
      "nome": "Castel di Sasso",
      "nomeStraniero": null,
      "codiceCatastale": "C097",
      "cap": "81040",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe@casteldisasso.com",
      "pec": "prot.casteldisasso@asmepec.it",
      "telefono": "+39 0823/878104",
      "fax": "+39 0823/878008",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "061026",
      "nome": "Castel Morrone",
      "nomeStraniero": null,
      "codiceCatastale": "C211",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "segreteria@comune.castelmorrone.ce.it",
      "pec": "protocollo.castelmorrone@asmepec.it",
      "telefono": "+39 0823/390059",
      "fax": "+39 0823/399726",
      "coordinate": {
        "lat": 41.11666488647461,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "061027",
      "nome": "Castel Volturno",
      "nomeStraniero": null,
      "codiceCatastale": "C291",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "demografici@comune.castelvolturno.ce.it",
      "pec": "cvolturno@pec.comune.castelvolturno.ce.it",
      "telefono": "+39 0823/763415",
      "fax": "+39 0823/769303",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "061025",
      "nome": "Castello del Matese",
      "nomeStraniero": null,
      "codiceCatastale": "C178",
      "cap": "81016",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@comune.castellodelmatese.ce.it",
      "pec": "amm.castellodelmatese@asmepec.it",
      "telefono": "+39 0823/784684",
      "fax": "+39 0823/784509",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "061102",
      "nome": "Cellole",
      "nomeStraniero": null,
      "codiceCatastale": "M262",
      "cap": "81030",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "uff.commercio@comunedicellole.it",
      "pec": "comune.cellole@asmepec.it",
      "telefono": "+39 0823/604411",
      "fax": "+39 0823/703511",
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 13.850000381469727
      }
    },
    {
      "codice": "061028",
      "nome": "Cervino",
      "nomeStraniero": null,
      "codiceCatastale": "C558",
      "cap": "81023",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunedicervino@alice.it",
      "pec": "comune.cervino@asmepec.it",
      "telefono": "+39 0823/1461425 / 08231455124",
      "fax": null,
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "061029",
      "nome": "Cesa",
      "nomeStraniero": null,
      "codiceCatastale": "C561",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "elettorale@comune.cesa.ce.it",
      "pec": "elettorale@pec.comune.cesa.ce.it",
      "telefono": "+39 081/8154308",
      "fax": "+39 081/8154321",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "061030",
      "nome": "Ciorlano",
      "nomeStraniero": null,
      "codiceCatastale": "C716",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comune.ciorlano@gmail.com",
      "pec": "comune.ciorlano@asmepec.it",
      "telefono": "+39 0823/944065",
      "fax": "+39 0823/944065",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "061031",
      "nome": "Conca della Campania",
      "nomeStraniero": null,
      "codiceCatastale": "C939",
      "cap": "81044",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe.concacampania@virgilio.it",
      "pec": "sindaco.concadellacampania@asmepec.it",
      "telefono": "+39 0823/923094",
      "fax": "+39 0823/923094",
      "coordinate": {
        "lat": 41.33333206176758,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "061032",
      "nome": "Curti",
      "nomeStraniero": null,
      "codiceCatastale": "D228",
      "cap": "81040",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "segreteria@comune.curti.ce.it",
      "pec": "comune.curti@pec.riusoprotocollo.it",
      "telefono": "+39 0823/842648",
      "fax": "+39 0823/799440",
      "coordinate": {
        "lat": 41.0761604309082,
        "lng": 14.280389785766602
      }
    },
    {
      "codice": "061033",
      "nome": "Dragoni",
      "nomeStraniero": null,
      "codiceCatastale": "D361",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "demografici@comune.dragoni.ce.it",
      "pec": "demografici.comune.dragoni@pec.it",
      "telefono": "+39 0823/866221",
      "fax": "+39 0823/866238",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "061101",
      "nome": "Falciano del Massico",
      "nomeStraniero": null,
      "codiceCatastale": "D471",
      "cap": "81030",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe@comunefalcianodelmassico.it",
      "pec": "ced.sciaudone@asmepec.it",
      "telefono": "+39 0823/931242",
      "fax": "+39 0823/728907",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 13.949999809265137
      }
    },
    {
      "codice": "061034",
      "nome": "Fontegreca",
      "nomeStraniero": null,
      "codiceCatastale": "D683",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comune.fontegreca@tiscali.it",
      "pec": "comune.fontegreca.@pec.riusoprotocollo.it",
      "telefono": "+39 0823/944104",
      "fax": "+39 0823/944003",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "061035",
      "nome": "Formicola",
      "nomeStraniero": null,
      "codiceCatastale": "D709",
      "cap": "81040",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comuneformicola_info@virgilio.it",
      "pec": "demografici.formicola@asmepec.it",
      "telefono": "+39 0823/876017",
      "fax": "+39 0823876668",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "061036",
      "nome": "Francolise",
      "nomeStraniero": null,
      "codiceCatastale": "D769",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "francolise@libero.it",
      "pec": "protocollo@pec.comune.francolise.ce.it",
      "telefono": "+39 0823/882173",
      "fax": "+39 0823/877049",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "061037",
      "nome": "Frignano",
      "nomeStraniero": null,
      "codiceCatastale": "D799",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "comunefrignanoaagg@libero.it",
      "pec": "segreteria.frignano@alphapec.it",
      "telefono": "+39 081/8124502",
      "fax": "081/8124513",
      "coordinate": {
        "lat": 40.993263244628906,
        "lng": 14.177863121032715
      }
    },
    {
      "codice": "061038",
      "nome": "Gallo Matese",
      "nomeStraniero": null,
      "codiceCatastale": "D884",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "info@comune.gallomatese.ce.it",
      "pec": "segreteria.gallomatese@asmepec.it",
      "telefono": "+39 0823/945273",
      "fax": "+39 0823/945273",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "061039",
      "nome": "Galluccio",
      "nomeStraniero": null,
      "codiceCatastale": "D886",
      "cap": "81044",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe@comune.galluccio.ce.it",
      "pec": "amministrativo@pec.comune.galluccio.ce.it",
      "telefono": "+39 0823/925025",
      "fax": "+39 0823/926935",
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 13.949999809265137
      }
    },
    {
      "codice": "061040",
      "nome": "Giano Vetusto",
      "nomeStraniero": null,
      "codiceCatastale": "E011",
      "cap": "81042",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe@comune.gianovetusto.ce.it",
      "pec": "demo.giano@asmepec.it",
      "telefono": "+39 0823/871008",
      "fax": "+39 0823/653624",
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "061041",
      "nome": "Gioia Sannitica",
      "nomeStraniero": null,
      "codiceCatastale": "E039",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "gioiasannitica@virgilio.it",
      "pec": "segreteria.comunegioiasannitica@alphapec.it",
      "telefono": "+39 0823/915021",
      "fax": "+39 0823/915540",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "061042",
      "nome": "Grazzanise",
      "nomeStraniero": null,
      "codiceCatastale": "E158",
      "cap": "81046",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafegrazzanise@libero.it",
      "pec": "elettorale@comunedigrazzanisepec.it",
      "telefono": "+39 0823/563727",
      "fax": "+39 0823/563731",
      "coordinate": {
        "lat": 41.08333206176758,
        "lng": 14.100000381469727
      }
    },
    {
      "codice": "061043",
      "nome": "Gricignano di Aversa",
      "nomeStraniero": null,
      "codiceCatastale": "E173",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "anagrafe.gricignano@alice.it",
      "pec": "protocollo@pec.comune.gricignanodiaversa.ce.it",
      "telefono": "+39 081/5026511",
      "fax": "+39 081/8131373",
      "coordinate": {
        "lat": 40.983333587646484,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "061044",
      "nome": "Letino",
      "nomeStraniero": null,
      "codiceCatastale": "E554",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioamministrativo@comune.letino.ce.it",
      "pec": "prot@asmepec.it",
      "telefono": "+39 0823/945155",
      "fax": "+39 0823/945155",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 14.25
      }
    },
    {
      "codice": "061045",
      "nome": "Liberi",
      "nomeStraniero": null,
      "codiceCatastale": "E570",
      "cap": "81040",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunediliberi@virgilio.it",
      "pec": "comuneliberi@asmepec.it",
      "telefono": "+39 0823/870006",
      "fax": "+39 0823/870630",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "061046",
      "nome": "Lusciano",
      "nomeStraniero": null,
      "codiceCatastale": "E754",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "affarigenerali@comune.lusciano.ce.it",
      "pec": "affarigenerali@pec.comune.lusciano.ce.it",
      "telefono": "+39 081/8127911",
      "fax": "+39 081/8147578",
      "coordinate": {
        "lat": 40.969966888427734,
        "lng": 14.192651748657227
      }
    },
    {
      "codice": "061047",
      "nome": "Macerata Campania",
      "nomeStraniero": null,
      "codiceCatastale": "E784",
      "cap": "81047",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@comune.maceratacampania.ce.it",
      "pec": "elettorale@pec.comune.maceratacampania.ce.it",
      "telefono": "+39 0823/694012",
      "fax": "+39 0823/694030",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "061048",
      "nome": "Maddaloni",
      "nomeStraniero": null,
      "codiceCatastale": "E791",
      "cap": "81024",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@comune.maddaloni.ce.it",
      "pec": "dir.generale@pec.comune.maddaloni.ce.it",
      "telefono": "+39 0823/433332",
      "fax": "+39 0823/288001",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "061049",
      "nome": "Marcianise",
      "nomeStraniero": null,
      "codiceCatastale": "E932",
      "cap": "81025",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "info@comune.marcianise.ce.it",
      "pec": "protocollo@pec-marcianise.it",
      "telefono": "+39 0823/635208",
      "fax": "+39 0823/635362",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "061050",
      "nome": "Marzano Appio",
      "nomeStraniero": null,
      "codiceCatastale": "E998",
      "cap": "81035",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafemarzanoappio@virgilio.it",
      "pec": "comune.marzanoappio@asmepec.it",
      "telefono": "+39 0823/929219",
      "fax": "+39 0823/929220",
      "coordinate": {
        "lat": 41.31805419921875,
        "lng": 14.048055648803711
      }
    },
    {
      "codice": "061051",
      "nome": "Mignano Monte Lungo",
      "nomeStraniero": null,
      "codiceCatastale": "F203",
      "cap": "81049",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunemignano@yahoo.it",
      "pec": "aagg.mignanomontelungo@asmepec.it",
      "telefono": "+39 0823/905523",
      "fax": "+39 0823/903772",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "061052",
      "nome": "Mondragone",
      "nomeStraniero": null,
      "codiceCatastale": "F352",
      "cap": "81034",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@mondragone.net",
      "pec": "protocollo.mondragone@asmepec.it",
      "telefono": "+39 0823/979783",
      "fax": "+39 0823/971351",
      "coordinate": {
        "lat": 41.099998474121094,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "061053",
      "nome": "Orta di Atella",
      "nomeStraniero": null,
      "codiceCatastale": "G130",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "rosamaisto@comune.ortadiatella.ce.it",
      "pec": "protocollo.ortadiatella@asmepec.it",
      "telefono": "+39 081/5023711",
      "fax": "+39 081/5023703",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "061054",
      "nome": "Parete",
      "nomeStraniero": null,
      "codiceCatastale": "G333",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "comuneparete@libero.it",
      "pec": "comune.parete@asmepec.it",
      "telefono": "+39 081/5015311",
      "fax": "+39 081/5015340",
      "coordinate": {
        "lat": 40.95988082885742,
        "lng": 14.16496467590332
      }
    },
    {
      "codice": "061055",
      "nome": "Pastorano",
      "nomeStraniero": null,
      "codiceCatastale": "G364",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "segreteria.pastorano@asmepec.it",
      "pec": "protocollo.pastorano@asmepec.it",
      "telefono": "+39 0823/879021",
      "fax": "+39 0823/879335",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "061056",
      "nome": "Piana di Monte Verna",
      "nomeStraniero": null,
      "codiceCatastale": "G541",
      "cap": "81013",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "servizidemografici@monteverna.it",
      "pec": "protocollo@pec.monteverna.it",
      "telefono": "+39 0823 861224",
      "fax": "+39 0823/861620",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "061057",
      "nome": "Piedimonte Matese",
      "nomeStraniero": null,
      "codiceCatastale": "G596",
      "cap": "81016",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ced@comune.piedimonte-matese.ce.it",
      "pec": "comune.piedimontematese@asmepec.it",
      "telefono": "+39 0823/786442",
      "fax": null,
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "061058",
      "nome": "Pietramelara",
      "nomeStraniero": null,
      "codiceCatastale": "G620",
      "cap": "81051",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "demografico@comune.pietramelara.ce.it",
      "pec": "protocollo.pietramelara@pec.it",
      "telefono": "+39 0823/648228",
      "fax": "+39 0823/986332",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "061059",
      "nome": "Pietravairano",
      "nomeStraniero": null,
      "codiceCatastale": "G630",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettoralepietravairano@gmail.com",
      "pec": "servammpietravairano@asmepec.it",
      "telefono": "+39 0823/984122",
      "fax": "+39 0823/984812",
      "coordinate": {
        "lat": 41.33333206176758,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "061060",
      "nome": "Pignataro Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "G661",
      "cap": "81052",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "servizidemografici.comunepignataro@legalmail.it",
      "pec": "servizidemografici.comunepignataro@legalmail.it",
      "telefono": "0823520105",
      "fax": null,
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "061061",
      "nome": "Pontelatone",
      "nomeStraniero": null,
      "codiceCatastale": "G849",
      "cap": "81040",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "affarigenerali@comune.pontelatone.ce.it",
      "pec": "protocollo.comune.pontelatone@alphapec.it",
      "telefono": "+39 0823/876163",
      "fax": null,
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 14.25
      }
    },
    {
      "codice": "061062",
      "nome": "Portico di Caserta",
      "nomeStraniero": null,
      "codiceCatastale": "G903",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "demos-portico-ce@libero.it",
      "pec": "areaamministrativa@pec.comuneporticodicaserta.it",
      "telefono": "+39 0823/695235",
      "fax": "+39 0823/695233",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "061063",
      "nome": "Prata Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "G991",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunedipratasannita.com@tin.it",
      "pec": "amministrativo.pratasannita.ce@asmepec.it",
      "telefono": "+39 0823/941069",
      "fax": "+39 0823/946856",
      "coordinate": {
        "lat": 41.43333435058594,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "061064",
      "nome": "Pratella",
      "nomeStraniero": null,
      "codiceCatastale": "G995",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunepratella@tiscali.it",
      "pec": "comune.pratella@pec.riusoprotocollo.it",
      "telefono": "+39 0823/941045",
      "fax": "+39 0823/941222",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "061065",
      "nome": "Presenzano",
      "nomeStraniero": null,
      "codiceCatastale": "H045",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comunedipresenzano@virgilio.it",
      "pec": "segretario.presenzano@asmepec.it",
      "telefono": "+39 0823/989055",
      "fax": "+39 0823/989294",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "061066",
      "nome": "Raviscanina",
      "nomeStraniero": null,
      "codiceCatastale": "H202",
      "cap": "81017",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "giulionassa@virgilio.it",
      "pec": "protocollo.raviscanina@pec.it",
      "telefono": "+39 0823 914071",
      "fax": "+39 0823 914218",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 14.25
      }
    },
    {
      "codice": "061067",
      "nome": "Recale",
      "nomeStraniero": null,
      "codiceCatastale": "H210",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "demorecale@libero.it",
      "pec": "comunerecale@pec.it",
      "telefono": "+39 0823/495727",
      "fax": "+39 0823/495747",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "061068",
      "nome": "Riardo",
      "nomeStraniero": null,
      "codiceCatastale": "H268",
      "cap": "81053",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe.riardo@virgilio.it",
      "pec": "protocollo.riardo@asmepec.it",
      "telefono": "+39 0823/1310108",
      "fax": null,
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.149999618530273
      }
    },
    {
      "codice": "061069",
      "nome": "Rocca d'Evandro",
      "nomeStraniero": null,
      "codiceCatastale": "H398",
      "cap": "81040",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "servizidemografici@comuneroccadevandro.it",
      "pec": "comuneroccadevandro@pec.it",
      "telefono": "+39 0823/907083",
      "fax": "+39 0823/906577",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "061070",
      "nome": "Roccamonfina",
      "nomeStraniero": null,
      "codiceCatastale": "H423",
      "cap": "81035",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe.roccamonfina@virgilio.it",
      "pec": "protocollo.roccamonfina@asmepec.it",
      "telefono": "+39 0823/677202/677214",
      "fax": null,
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "061071",
      "nome": "Roccaromana",
      "nomeStraniero": null,
      "codiceCatastale": "H436",
      "cap": "81051",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "comune.roccaromana@virgilio.it",
      "pec": "comune.roccaromana@pec.it",
      "telefono": "+39 0823/986119",
      "fax": "+39 0823/987616",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "061072",
      "nome": "Rocchetta e Croce",
      "nomeStraniero": null,
      "codiceCatastale": "H459",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "info@comune.rocchettaecroce.ce.it",
      "pec": "anagrafe.rocchettaecroce@asmepec.it",
      "telefono": "+39 0823/889013",
      "fax": "+39 0823/889151",
      "coordinate": {
        "lat": 41.237159729003906,
        "lng": 14.157649993896484
      }
    },
    {
      "codice": "061073",
      "nome": "Ruviano",
      "nomeStraniero": null,
      "codiceCatastale": "H165",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioelett.ruviano@libero.it",
      "pec": "demografici@pec.comuneruviano.it",
      "telefono": "+39 0823860021",
      "fax": "+39 0823/860444",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "061074",
      "nome": "San Cipriano d'Aversa",
      "nomeStraniero": null,
      "codiceCatastale": "H798",
      "cap": "81036",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "demografici@comune.sanciprianodaversa.ce.it",
      "pec": "amministrativa.sancipriano@asmepec.it",
      "telefono": "+39 081/8165304",
      "fax": "+39 081 8165301",
      "coordinate": {
        "lat": 41.00104522705078,
        "lng": 14.131734848022461
      }
    },
    {
      "codice": "061075",
      "nome": "San Felice a Cancello",
      "nomeStraniero": null,
      "codiceCatastale": "H834",
      "cap": "81027",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "francesco.magliulo@cittasanfeliceacancello.it",
      "pec": "affarigenerali@pec.comune.sanfeliceacancello.ce.it",
      "telefono": "+39 0823/753711",
      "fax": "+39 0823/753245",
      "coordinate": {
        "lat": 40.99333190917969,
        "lng": 14.420833587646484
      }
    },
    {
      "codice": "061076",
      "nome": "San Gregorio Matese",
      "nomeStraniero": null,
      "codiceCatastale": "H939",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "sangregoriogreg@virgilio.it",
      "pec": "protocollo.sangregoriomatese@asmepec.it",
      "telefono": "+39 0823/919019",
      "fax": "+39 0823/919814",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "061077",
      "nome": "San Marcellino",
      "nomeStraniero": null,
      "codiceCatastale": "H978",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "elettorale.sanmarcellino@asmepec.it",
      "pec": "comune.sanmarcellino@asmepec.it",
      "telefono": "+39 081/8124817",
      "fax": "+39 081/8124828",
      "coordinate": {
        "lat": 40.987606048583984,
        "lng": 14.173795700073242
      }
    },
    {
      "codice": "061104",
      "nome": "San Marco Evangelista",
      "nomeStraniero": null,
      "codiceCatastale": "F043",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "midimaio@tin.it",
      "pec": "protocollo@pec.comune.sanmarcoevangelista.ce.it",
      "telefono": "+39 0823/454638",
      "fax": "+39 0823/421305",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "061078",
      "nome": "San Nicola la Strada",
      "nomeStraniero": null,
      "codiceCatastale": "I056",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@comune.sannicolalastrada.ce.it",
      "pec": "elettorale@pec.comune.sannicolalastrada.ce.it",
      "telefono": "+39 0823/224903",
      "fax": null,
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "061079",
      "nome": "San Pietro Infine",
      "nomeStraniero": null,
      "codiceCatastale": "I113",
      "cap": "81049",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe.sanpietroinfine@gmail.com",
      "pec": "prot.sanpietroinfine@asmepec.it",
      "telefono": "+39 0823/900311",
      "fax": "+39 0823/900349",
      "coordinate": {
        "lat": 41.44694519042969,
        "lng": 13.962499618530273
      }
    },
    {
      "codice": "061080",
      "nome": "San Potito Sannitico",
      "nomeStraniero": null,
      "codiceCatastale": "I130",
      "cap": "81016",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioelettorale.sanpotito@asmepec.it",
      "pec": "segreteria.sanpotito@asmepec.it",
      "telefono": "+39 0823/911526",
      "fax": "+39 0823/911629",
      "coordinate": {
        "lat": 41.33333206176758,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "061081",
      "nome": "San Prisco",
      "nomeStraniero": null,
      "codiceCatastale": "I131",
      "cap": "81054",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficioelettorale@comune.sanprisco.caserta.it",
      "pec": "demografici@pec.comune.sanprisco.caserta.it",
      "telefono": "+39 0823/790050",
      "fax": "+39 0823/790080",
      "coordinate": {
        "lat": 41.08333206176758,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "061085",
      "nome": "San Tammaro",
      "nomeStraniero": null,
      "codiceCatastale": "I261",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ufficio.protocollo@comune.santammaro.ce.it",
      "pec": "protocollo@pec.comune.santammaro.ce.it",
      "telefono": "+39 0823793449",
      "fax": null,
      "coordinate": {
        "lat": 41.07587814331055,
        "lng": 14.231049537658691
      }
    },
    {
      "codice": "061086",
      "nome": "Sant'Angelo d'Alife",
      "nomeStraniero": null,
      "codiceCatastale": "I273",
      "cap": "81017",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "santangelodalife@inwind.it",
      "pec": "protocollo.santangelodalife@asmepec.it",
      "telefono": "+39 0823/914022",
      "fax": "+39 0823/914666",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "061087",
      "nome": "Sant'Arpino",
      "nomeStraniero": null,
      "codiceCatastale": "I306",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "ufficio.elettorale@comune-santarpino.ce.it",
      "pec": "elettorale@pec.comune.santarpino.ce.it",
      "telefono": "+39 081/5014510",
      "fax": "",
      "coordinate": {
        "lat": 40.95750045776367,
        "lng": 14.249166488647461
      }
    },
    {
      "codice": "061082",
      "nome": "Santa Maria a Vico",
      "nomeStraniero": null,
      "codiceCatastale": "I233",
      "cap": "81028",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "pio.affinita@email.it",
      "pec": "ragioneria.santamariaavico@anutel.it",
      "telefono": "+39 0823/759502",
      "fax": "+39 0823/759517",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "061083",
      "nome": "Santa Maria Capua Vetere",
      "nomeStraniero": null,
      "codiceCatastale": "I234",
      "cap": "81055",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "elettorale@comune.smcv.it",
      "pec": "protocollo@santamariacv.postecert.it",
      "telefono": "+39 0823/813111",
      "fax": "+39 0823/813107",
      "coordinate": {
        "lat": 41.08333206176758,
        "lng": 14.25
      }
    },
    {
      "codice": "061084",
      "nome": "Santa Maria la Fossa",
      "nomeStraniero": null,
      "codiceCatastale": "I247",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "a.ciancio@comune.santamarialafossa.ce.it",
      "pec": "protocollo.santamarialafossa@asmepec.it",
      "telefono": "+39 0823993749",
      "fax": "+39 0823/993573",
      "coordinate": {
        "lat": 41.099998474121094,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "061088",
      "nome": "Sessa Aurunca",
      "nomeStraniero": null,
      "codiceCatastale": "I676",
      "cap": "81037",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "michele.antinucci@alice.it",
      "pec": "protocollogenerale@pec.sessaweb.it",
      "telefono": "+39 0823/602226",
      "fax": "+39 0823/936007",
      "coordinate": {
        "lat": 41.233333587646484,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "061089",
      "nome": "Sparanise",
      "nomeStraniero": null,
      "codiceCatastale": "I885",
      "cap": "81056",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "ragioneria@comunedisparanise.it",
      "pec": "prot.sparanise@asmepec.it",
      "telefono": "+39 0823/881569",
      "fax": "+39 0823/877400",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.100000381469727
      }
    },
    {
      "codice": "061090",
      "nome": "Succivo",
      "nomeStraniero": null,
      "codiceCatastale": "I993",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "comunesuccivo.servdem@virgilio.it",
      "pec": "demografici.succivo@alphapec.it",
      "telefono": "+39 081/5025703",
      "fax": "+39 081/5025757",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 14.25
      }
    },
    {
      "codice": "061091",
      "nome": "Teano",
      "nomeStraniero": null,
      "codiceCatastale": "L083",
      "cap": "81057",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "g.defusco@comune.teano.ce.it",
      "pec": "segretario.teano@asmepec.it",
      "telefono": "+39 0823/658206",
      "fax": "+39 0823/875081",
      "coordinate": {
        "lat": 41.25,
        "lng": 14.066666603088379
      }
    },
    {
      "codice": "061092",
      "nome": "Teverola",
      "nomeStraniero": null,
      "codiceCatastale": "L155",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "agostino.badile@comune.teverola.ce.it",
      "pec": "servizielettorali.teverola@pec.it",
      "telefono": "0818911916",
      "fax": "0818911903",
      "coordinate": {
        "lat": 41,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "061093",
      "nome": "Tora e Piccilli",
      "nomeStraniero": null,
      "codiceCatastale": "L205",
      "cap": "81044",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "protocollo@comune.toraepiccilli.ce.it",
      "pec": "anagrafe@pec.comune.toraepiccilli.ce.it",
      "telefono": "+39 0823924227",
      "fax": null,
      "coordinate": {
        "lat": 41.33333206176758,
        "lng": 14.016666412353516
      }
    },
    {
      "codice": "061094",
      "nome": "Trentola Ducenta",
      "nomeStraniero": null,
      "codiceCatastale": "L379",
      "cap": "81038",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "sindaco@comune.trentoladucenta.ce.it",
      "pec": "protocollo.trentoladucenta@legalmail.it",
      "telefono": "+39 081 8128111",
      "fax": "+39 081 8128206",
      "coordinate": {
        "lat": 40.976226806640625,
        "lng": 14.177286148071289
      }
    },
    {
      "codice": "061095",
      "nome": "Vairano Patenora",
      "nomeStraniero": null,
      "codiceCatastale": "L540",
      "cap": "81058",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "e.spaziano@alice.it",
      "pec": "segreteria.vairanopatenora@asmepec.it",
      "telefono": "+39 0823 643711",
      "fax": "+39 0823 985235",
      "coordinate": {
        "lat": 41.33333206176758,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "061096",
      "nome": "Valle Agricola",
      "nomeStraniero": null,
      "codiceCatastale": "L594",
      "cap": "81010",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "valleagricola.sindaco@virgilio.it",
      "pec": "comune.valleagricola@pec.riusoprotocollo.it",
      "telefono": "+39 0823 942024",
      "fax": "+39 0823 942333",
      "coordinate": {
        "lat": 41.41666793823242,
        "lng": 14.25
      }
    },
    {
      "codice": "061097",
      "nome": "Valle di Maddaloni",
      "nomeStraniero": null,
      "codiceCatastale": "L591",
      "cap": "81020",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "anagrafe@comune.valledimaddaloni.ce.it",
      "pec": "protocollo.vallemaddaloni@pcert.it",
      "telefono": "+39 0823336281",
      "fax": "+39 0823 336266",
      "coordinate": {
        "lat": 41.078887939453125,
        "lng": 14.414722442626953
      }
    },
    {
      "codice": "061098",
      "nome": "Villa di Briano",
      "nomeStraniero": null,
      "codiceCatastale": "D801",
      "cap": "81030",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "comunevilladibriano@libero.it",
      "pec": "segreteriacomunevilladibriano@postecert.it",
      "telefono": "0815042461",
      "fax": null,
      "coordinate": {
        "lat": 41,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "061099",
      "nome": "Villa Literno",
      "nomeStraniero": null,
      "codiceCatastale": "L844",
      "cap": "81039",
      "prefisso": "081",
      "provincia": "Caserta",
      "email": "immacolata.disaia@tin.it",
      "pec": "segretario.villaliterno@asmepec.it",
      "telefono": "+39 081.816.98.02",
      "fax": "+39 081.892.90.24",
      "coordinate": {
        "lat": 41.009586334228516,
        "lng": 14.074085235595703
      }
    },
    {
      "codice": "061100",
      "nome": "Vitulazio",
      "nomeStraniero": null,
      "codiceCatastale": "M092",
      "cap": "81050",
      "prefisso": "0823",
      "provincia": "Caserta",
      "email": "segreteria@comune.vitulazio.ce.it",
      "pec": "protocollo.vitulazio@asmepec.it",
      "telefono": "+39 0823967511",
      "fax": "+39 0823965005",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 14.216666221618652
      }
    }
  ],
  "Biella": [
    {
      "codice": "096001",
      "nome": "Ailoche",
      "nomeStraniero": null,
      "codiceCatastale": "A107",
      "cap": "13861",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "rag.ailoche@ptb.provincia.biella.it",
      "pec": "ailoche@pec.ptbiellese.it",
      "telefono": "+39 015.767463",
      "fax": "+39 015.767463",
      "coordinate": {
        "lat": 45.69972229003906,
        "lng": 8.222222328186035
      }
    },
    {
      "codice": "096002",
      "nome": "Andorno Micca",
      "nomeStraniero": null,
      "codiceCatastale": "A280",
      "cap": "13811",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "andorno@ptb.provincia.biella.it",
      "pec": "andorno.micca@pec.ptbiellese.it",
      "telefono": "+39 015.2478100",
      "fax": "+39 015.2478180",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096003",
      "nome": "Benna",
      "nomeStraniero": null,
      "codiceCatastale": "A784",
      "cap": "13871",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "benna@ptb.provincia.biella.it",
      "pec": "benna@pec.ptbiellese.it",
      "telefono": "+39 015.5821203",
      "fax": "+39 015.2558087",
      "coordinate": {
        "lat": 45.51472091674805,
        "lng": 8.127778053283691
      }
    },
    {
      "codice": "096004",
      "nome": "Biella",
      "nomeStraniero": null,
      "codiceCatastale": "A859",
      "cap": "13900",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "mauro.donini@comune.biella.it",
      "pec": "protocollo@cert.comune.biella.it",
      "telefono": "+39 015.3507302 - +39 015.3507504",
      "fax": "+39 015.3507505",
      "coordinate": {
        "lat": 45.56638717651367,
        "lng": 8.053333282470703
      }
    },
    {
      "codice": "096005",
      "nome": "Bioglio",
      "nomeStraniero": null,
      "codiceCatastale": "A876",
      "cap": "13841",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "bioglio@ptb.provincia.biella.it",
      "pec": "bioglio@pec.ptbiellese.it",
      "telefono": "+39 015.441115",
      "fax": "+39 015.441577",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "096006",
      "nome": "Borriana",
      "nomeStraniero": null,
      "codiceCatastale": "B058",
      "cap": "13872",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "borriana@ptb.provincia.biella.it",
      "pec": "borriana@pec.ptbiellese.it",
      "telefono": "+39 015.446104",
      "fax": "+39 015.446571",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "096007",
      "nome": "Brusnengo",
      "nomeStraniero": null,
      "codiceCatastale": "B229",
      "cap": "13862",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "brusnengo@ptb.provincia.biella.it",
      "pec": "brusnengo@pec.ptbiellese.it",
      "telefono": "+39 015.9854111",
      "fax": "+39 015.985709",
      "coordinate": {
        "lat": 45.59000015258789,
        "lng": 8.252222061157227
      }
    },
    {
      "codice": "096008",
      "nome": "Callabiana",
      "nomeStraniero": null,
      "codiceCatastale": "B417",
      "cap": "13821",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "callabiana@ptb.provincia.biella.it",
      "pec": "callabiana@pec.ptbiellese.it",
      "telefono": "+39 015.745288",
      "fax": "+39 015.745288",
      "coordinate": {
        "lat": 45.63166809082031,
        "lng": 8.099444389343262
      }
    },
    {
      "codice": "096009",
      "nome": "Camandona",
      "nomeStraniero": null,
      "codiceCatastale": "B457",
      "cap": "13821",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "camandona@ptb.provincia.biella.it",
      "pec": "camandona@pec.ptbiellese.it",
      "telefono": "+39 015.748257",
      "fax": "+39 015.7480907",
      "coordinate": {
        "lat": 45.64472198486328,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "096010",
      "nome": "Camburzano",
      "nomeStraniero": null,
      "codiceCatastale": "B465",
      "cap": "13891",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "info@comune.camburzano.bi.it",
      "pec": "camburzano@pec.ptbiellese.it",
      "telefono": "+39 015.590005",
      "fax": "+39 015.2593626",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "096086",
      "nome": "Campiglia Cervo",
      "nomeStraniero": null,
      "codiceCatastale": "M373",
      "cap": "13812",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "campiglia@ptb.provincia.biella.it",
      "pec": "campiglia.cervo@pec.ptbiellese.it",
      "telefono": "+39 015-60023",
      "fax": "+39 015-6097626",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096012",
      "nome": "Candelo",
      "nomeStraniero": null,
      "codiceCatastale": "B586",
      "cap": "13878",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "segretario.candelo@ptb.provincia.biella.it",
      "pec": "candelo@pec.ptbiellese.it",
      "telefono": "+39 015.2534111",
      "fax": "+39 015.2534112",
      "coordinate": {
        "lat": 45.54777908325195,
        "lng": 8.10694408416748
      }
    },
    {
      "codice": "096013",
      "nome": "Caprile",
      "nomeStraniero": null,
      "codiceCatastale": "B708",
      "cap": "13864",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "caprile@ptb.provincia.biella.it",
      "pec": "caprile@pec.ptbiellese.it",
      "telefono": "+39 015.767791",
      "fax": "+39 015.7655668",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.25
      }
    },
    {
      "codice": "096014",
      "nome": "Casapinta",
      "nomeStraniero": null,
      "codiceCatastale": "B933",
      "cap": "13866",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "protocollo@comunecasapinta.it",
      "pec": "casapinta@pec.ptbiellese.it",
      "telefono": "+39 015.742405",
      "fax": "+39 015.7427585",
      "coordinate": {
        "lat": 45.6150016784668,
        "lng": 8.192222595214844
      }
    },
    {
      "codice": "096015",
      "nome": "Castelletto Cervo",
      "nomeStraniero": null,
      "codiceCatastale": "C155",
      "cap": "13851",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "rag.castelletto@ptb.provincia.biella.it",
      "pec": "comune.castelletto.bi@legalmail.it",
      "telefono": "+39 0161.859116",
      "fax": "+39 0161.859022",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.266666412353516
      }
    },
    {
      "codice": "096016",
      "nome": "Cavaglià",
      "nomeStraniero": null,
      "codiceCatastale": "C363",
      "cap": "13881",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "demog.cavaglia@ptb.provincia.biella.it",
      "pec": "cavaglia@pec.ptbiellese.it",
      "telefono": "+39 0161.96039",
      "fax": "+39 0161.967724",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "096018",
      "nome": "Cerrione",
      "nomeStraniero": null,
      "codiceCatastale": "C532",
      "cap": "13882",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "cerrione@ptb.provincia.biella.it",
      "pec": "cerrione@pec.ptbiellese.it",
      "telefono": "+39 015.671341",
      "fax": "+39 015.671491",
      "coordinate": {
        "lat": 45.46666717529297,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "096019",
      "nome": "Coggiola",
      "nomeStraniero": null,
      "codiceCatastale": "C819",
      "cap": "13863",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "coggiola.sindaco@tin.it",
      "pec": "coggiola@pec.ptbiellese.it",
      "telefono": "+39 015.78512",
      "fax": "+39 015.787589",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "096020",
      "nome": "Cossato",
      "nomeStraniero": null,
      "codiceCatastale": "D094",
      "cap": "13836",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "ced@comune.cossato.bi.it",
      "pec": "cossato@pec.ptbiellese.it",
      "telefono": "+39 015.9893206",
      "fax": "+39 015.921531",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 8.166666984558105
      }
    },
    {
      "codice": "096021",
      "nome": "Crevacuore",
      "nomeStraniero": null,
      "codiceCatastale": "D165",
      "cap": "13864",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "anag.crevacuore@ptb.provincia.biella.it",
      "pec": "crevacuore@pec.ptbiellese.it",
      "telefono": "+39 015.768154",
      "fax": "+39 015.768698",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.25
      }
    },
    {
      "codice": "096023",
      "nome": "Curino",
      "nomeStraniero": null,
      "codiceCatastale": "D219",
      "cap": "13865",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "anag.curino@ptb.provincia.biella.it",
      "pec": "curino@pec.ptbiellese.it",
      "telefono": "+39 015.928107",
      "fax": "+39 015.928163",
      "coordinate": {
        "lat": 45.62722396850586,
        "lng": 8.23611068725586
      }
    },
    {
      "codice": "096024",
      "nome": "Donato",
      "nomeStraniero": null,
      "codiceCatastale": "D339",
      "cap": "13893",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "donato@ptb.provincia.biella.it",
      "pec": "donato@pec.ptbiellese.it",
      "telefono": "+39 015.641903",
      "fax": null,
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 7.866666793823242
      }
    },
    {
      "codice": "096025",
      "nome": "Dorzano",
      "nomeStraniero": null,
      "codiceCatastale": "D350",
      "cap": "13881",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "dorzano@ptb.provincia.biella.it",
      "pec": "dorzano@pec.ptbiellese.it",
      "telefono": "+39 0161.96212",
      "fax": "+39 0161.968085",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "096026",
      "nome": "Gaglianico",
      "nomeStraniero": null,
      "codiceCatastale": "D848",
      "cap": "13894",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "mussoneg@comune.gaglianico.bi.it",
      "pec": "gaglianico@pec.ptbiellese.it",
      "telefono": "+39 015.2546400",
      "fax": "+39 015.2546416",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "096027",
      "nome": "Gifflenga",
      "nomeStraniero": null,
      "codiceCatastale": "E024",
      "cap": "13874",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "gifflenga@ptb.provincia.biella.it",
      "pec": "gifflenga@pec.ptbiellese.it",
      "telefono": "+39 0161.857300",
      "fax": "+39 0161.857989",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.266666412353516
      }
    },
    {
      "codice": "096028",
      "nome": "Graglia",
      "nomeStraniero": null,
      "codiceCatastale": "E130",
      "cap": "13895",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "anagrafe.graglia@ptb.provincia.biella.it",
      "pec": "graglia@pec.ptbiellese.it",
      "telefono": "+39 015.63119",
      "fax": "+39 015.6391016",
      "coordinate": {
        "lat": 45.558563232421875,
        "lng": 7.9780192375183105
      }
    },
    {
      "codice": "096085",
      "nome": "Lessona",
      "nomeStraniero": null,
      "codiceCatastale": "M371",
      "cap": "13853",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "urp.lessona@ptb.provincia.biella.it",
      "pec": "lessona@pec.ptbiellese.it",
      "telefono": "+39 015-981412",
      "fax": "+39 015-981164",
      "coordinate": {
        "lat": 45.586265563964844,
        "lng": 8.196444511413574
      }
    },
    {
      "codice": "096030",
      "nome": "Magnano",
      "nomeStraniero": null,
      "codiceCatastale": "E821",
      "cap": "13887",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demog.magnano@ptb.provincia.biella.it",
      "pec": "magnano@pec.ptbiellese.it",
      "telefono": "+39 015.679158",
      "fax": "+39 015.679158",
      "coordinate": {
        "lat": 45.46335983276367,
        "lng": 8.003020286560059
      }
    },
    {
      "codice": "096031",
      "nome": "Massazza",
      "nomeStraniero": null,
      "codiceCatastale": "F037",
      "cap": "13873",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "anag.massazza@ptb.provincia.biella.it",
      "pec": "massazza@pec.ptbiellese.it",
      "telefono": "+39 0161.852206",
      "fax": "+39 0161.852059",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "096032",
      "nome": "Masserano",
      "nomeStraniero": null,
      "codiceCatastale": "F042",
      "cap": "13866",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "sindaco@comune.masserano.bi.it",
      "pec": "comune.masserano.bi@legalmail.it",
      "telefono": "+39 015.96878",
      "fax": "+39 015.96924",
      "coordinate": {
        "lat": 45.59527587890625,
        "lng": 8.221944808959961
      }
    },
    {
      "codice": "096033",
      "nome": "Mezzana Mortigliengo",
      "nomeStraniero": null,
      "codiceCatastale": "F167",
      "cap": "13831",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demografici@comune.mezzanamortigliengo.bi.it",
      "pec": "comune.mezzanamortigliengo@legalmail.it",
      "telefono": "+39 015.742500",
      "fax": "+39 015.742010",
      "coordinate": {
        "lat": 45.62515640258789,
        "lng": 8.18868350982666
      }
    },
    {
      "codice": "096034",
      "nome": "Miagliano",
      "nomeStraniero": null,
      "codiceCatastale": "F189",
      "cap": "13816",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "miagliano@ptb.provincia.biella.it",
      "pec": "miagliano@pec.ptbiellese.it",
      "telefono": "+39 015.2476035",
      "fax": "+39 015.2476707",
      "coordinate": {
        "lat": 45.61861038208008,
        "lng": 8.043611526489258
      }
    },
    {
      "codice": "096035",
      "nome": "Mongrando",
      "nomeStraniero": null,
      "codiceCatastale": "F369",
      "cap": "13888",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "segreteria@comune.mongrando.bi.it",
      "pec": "mongrando@pec.ptbiellese.it",
      "telefono": "+39 015.666262",
      "fax": "+39 015.667693",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8
      }
    },
    {
      "codice": "096037",
      "nome": "Mottalciata",
      "nomeStraniero": null,
      "codiceCatastale": "F776",
      "cap": "13874",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "comune.mottalciata@ptb.provincia.biella.it",
      "pec": "mottalciata@pec.ptbiellese.it",
      "telefono": "+39 0161.857112",
      "fax": "+39 0161.857612",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.266666412353516
      }
    },
    {
      "codice": "096038",
      "nome": "Muzzano",
      "nomeStraniero": null,
      "codiceCatastale": "F833",
      "cap": "13895",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "muzzano@ptb.provincia.biella.it",
      "pec": "muzzano@pec.ptbiellese.it",
      "telefono": "+39 015.63123",
      "fax": null,
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "096039",
      "nome": "Netro",
      "nomeStraniero": null,
      "codiceCatastale": "F878",
      "cap": "13896",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "netro@ptb.provincia.biella.it",
      "pec": "netro@pec.ptbiellese.it",
      "telefono": "+39 015.65113",
      "fax": "+39 015.65591",
      "coordinate": {
        "lat": 45.537498474121094,
        "lng": 7.9472222328186035
      }
    },
    {
      "codice": "096040",
      "nome": "Occhieppo Inferiore",
      "nomeStraniero": null,
      "codiceCatastale": "F992",
      "cap": "13897",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "urp@occhieppoinf.it",
      "pec": "occhieppoinferiore@pec.ptbiellese.it",
      "telefono": "+39 015.590291",
      "fax": "+39 015.2592889",
      "coordinate": {
        "lat": 45.516666412353516,
        "lng": 8
      }
    },
    {
      "codice": "096041",
      "nome": "Occhieppo Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "F993",
      "cap": "13898",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "info@comune.occhieppo-superiore.bi.it",
      "pec": "occhiepposuperiore@pec.ptbiellese.it",
      "telefono": "+390152593262",
      "fax": "+390152592594",
      "coordinate": {
        "lat": 45.5,
        "lng": 8
      }
    },
    {
      "codice": "096042",
      "nome": "Pettinengo",
      "nomeStraniero": null,
      "codiceCatastale": "G521",
      "cap": "13843",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demog.pettinengo@ptb.provincia.biella.it",
      "pec": "pettinengo@pec.ptbiellese.it",
      "telefono": "+39 015.8445035",
      "fax": null,
      "coordinate": {
        "lat": 45.61296081542969,
        "lng": 8.104069709777832
      }
    },
    {
      "codice": "096043",
      "nome": "Piatto",
      "nomeStraniero": null,
      "codiceCatastale": "G577",
      "cap": "13844",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "piatto@ptb.provincia.biella.it",
      "pec": "piatto@pec.ptbiellese.it",
      "telefono": "+39 015.883998",
      "fax": null,
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096044",
      "nome": "Piedicavallo",
      "nomeStraniero": null,
      "codiceCatastale": "G594",
      "cap": "13812",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "piedicavallo@ptb.provincia.biella.it",
      "pec": "piedicavallo@pec.ptbiellese.it",
      "telefono": "+39 015.609115",
      "fax": "+39 015.6099102",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "096046",
      "nome": "Pollone",
      "nomeStraniero": null,
      "codiceCatastale": "G798",
      "cap": "13814",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "urp@comune.pollone.bi.it",
      "pec": "pollone@pec.ptbiellese.it",
      "telefono": "+39 015.61191",
      "fax": "+39 015.610242",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "096047",
      "nome": "Ponderano",
      "nomeStraniero": null,
      "codiceCatastale": "G820",
      "cap": "13875",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demog.ponderano@ptb.provincia.biella.it",
      "pec": "ponderano@pec.ptbiellese.it",
      "telefono": "+39 015.541224",
      "fax": "+39 015.541630",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "096048",
      "nome": "Portula",
      "nomeStraniero": null,
      "codiceCatastale": "G927",
      "cap": "13833",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "portula@ptb.provincia.biella.it",
      "pec": "portula@pec.ptbiellese.it",
      "telefono": "+39 015.75005",
      "fax": "+39 015.7158761",
      "coordinate": {
        "lat": 45.67476272583008,
        "lng": 8.179458618164062
      }
    },
    {
      "codice": "096049",
      "nome": "Pralungo",
      "nomeStraniero": null,
      "codiceCatastale": "G980",
      "cap": "13899",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "pralungo@ptb.provincia.biella.it",
      "pec": "pralungo@pec.ptbiellese.it",
      "telefono": "+39 015.25744",
      "fax": "+39 015.571792",
      "coordinate": {
        "lat": 45.59214782714844,
        "lng": 8.037924766540527
      }
    },
    {
      "codice": "096050",
      "nome": "Pray",
      "nomeStraniero": null,
      "codiceCatastale": "G974",
      "cap": "13867",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "pray@ptb.provincia.biella.it",
      "pec": "pray@pec.ptbiellese.it",
      "telefono": "+39 015.767035",
      "fax": "+39 015/767283",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.216666221618652
      }
    },
    {
      "codice": "096087",
      "nome": "Quaregna Cerreto",
      "nomeStraniero": null,
      "codiceCatastale": "M414",
      "cap": "13854",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "protocollo@quaregnacerreto.it",
      "pec": "quaregnacerreto@pec.it",
      "telefono": "01593859",
      "fax": "015983103",
      "coordinate": {
        "lat": 45.57655715942383,
        "lng": 8.164833068847656
      }
    },
    {
      "codice": "096053",
      "nome": "Ronco Biellese",
      "nomeStraniero": null,
      "codiceCatastale": "H538",
      "cap": "13845",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "ronco@ptb.provincia.biella.it",
      "pec": "ronco.biellese@pec.ptbiellese.it",
      "telefono": "+39 015 461085",
      "fax": "+39 015 461556",
      "coordinate": {
        "lat": 45.5816650390625,
        "lng": 8.098610877990723
      }
    },
    {
      "codice": "096054",
      "nome": "Roppolo",
      "nomeStraniero": null,
      "codiceCatastale": "H553",
      "cap": "13883",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "anag.roppolo@ptb.provincia.biella.it",
      "pec": "roppolo@pec.ptbiellese.it",
      "telefono": "+39 0161.98424",
      "fax": "+39 0161.989427",
      "coordinate": {
        "lat": 45.421390533447266,
        "lng": 8.070555686950684
      }
    },
    {
      "codice": "096055",
      "nome": "Rosazza",
      "nomeStraniero": null,
      "codiceCatastale": "H561",
      "cap": "13815",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "rosazza@ptb.provincia.biella.it",
      "pec": "rosazza@pec.ptbiellese.it",
      "telefono": "+39 015.60092",
      "fax": "+39 015.6097921",
      "coordinate": {
        "lat": 45.676666259765625,
        "lng": 7.977222442626953
      }
    },
    {
      "codice": "096056",
      "nome": "Sagliano Micca",
      "nomeStraniero": null,
      "codiceCatastale": "H662",
      "cap": "13816",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "sagliano@ptb.provincia.biella.it",
      "pec": "sagliano.micca@pec.ptbiellese.it",
      "telefono": "+39 015.473715",
      "fax": "+39 015.473995",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096057",
      "nome": "Sala Biellese",
      "nomeStraniero": null,
      "codiceCatastale": "H681",
      "cap": "13884",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "sala.biellese@pec.ptbiellese.it",
      "pec": "sala.biellese@pec.ptbiellese.it",
      "telefono": "+39 015.2551118",
      "fax": "+39 015.2551198",
      "coordinate": {
        "lat": 45.5,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "096058",
      "nome": "Salussola",
      "nomeStraniero": null,
      "codiceCatastale": "H726",
      "cap": "13885",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "demog.salussola@ptb.provincia.biella.it",
      "pec": "salussola@pec.ptbiellese.it",
      "telefono": "+39 0161.998124",
      "fax": "+39 0161.997276",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "096059",
      "nome": "Sandigliano",
      "nomeStraniero": null,
      "codiceCatastale": "H821",
      "cap": "13876",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "sandigliano@ptb.provincia.biella.it",
      "pec": "sandigliano@pec.ptbiellese.it",
      "telefono": "+39 015. 691003",
      "fax": "+39 015. 2493238",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "096063",
      "nome": "Sordevolo",
      "nomeStraniero": null,
      "codiceCatastale": "I847",
      "cap": "13817",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demog.sordevolo@acme-biella.com",
      "pec": "sordevolo@pec.ptbiellese.it",
      "telefono": "+39 015.2568103",
      "fax": "+39 015.2562569",
      "coordinate": {
        "lat": 45.56666564941406,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "096064",
      "nome": "Sostegno",
      "nomeStraniero": null,
      "codiceCatastale": "I868",
      "cap": "13868",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "sostegno@ptb.provincia.biella.it",
      "pec": "sostegno@pec.ptbiellese.it",
      "telefono": "+39 015.762903",
      "fax": "+39 015.762968",
      "coordinate": {
        "lat": 45.652854919433594,
        "lng": 8.26970100402832
      }
    },
    {
      "codice": "096065",
      "nome": "Strona",
      "nomeStraniero": null,
      "codiceCatastale": "I980",
      "cap": "13823",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "anag.strona@ptb.provincia.biella.it",
      "pec": "strona@pec.ptbiellese.it",
      "telefono": "+39 015.742212",
      "fax": "+39 015.742532",
      "coordinate": {
        "lat": 45.61640167236328,
        "lng": 8.16819953918457
      }
    },
    {
      "codice": "096066",
      "nome": "Tavigliano",
      "nomeStraniero": null,
      "codiceCatastale": "L075",
      "cap": "13811",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "tavigliano@ptb.provincia.biella.it",
      "pec": "tavigliano@pec.ptbiellese.it",
      "telefono": "+39 015.2476036",
      "fax": "+39 015.2476721",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096067",
      "nome": "Ternengo",
      "nomeStraniero": null,
      "codiceCatastale": "L116",
      "cap": "13844",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "ternengo@ptb.provincia.biella.it",
      "pec": "ternengo@pec.ptbiellese.it",
      "telefono": "+39 015.461564",
      "fax": "+39 015.8462745",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "096068",
      "nome": "Tollegno",
      "nomeStraniero": null,
      "codiceCatastale": "L193",
      "cap": "13818",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demografici@comune.tollegno.bi.it",
      "pec": "tollegno@pec.ptbiellese.it",
      "telefono": "+39 015.422118",
      "fax": "+39 015.422261",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096069",
      "nome": "Torrazzo",
      "nomeStraniero": null,
      "codiceCatastale": "L239",
      "cap": "13884",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "torrazzo@ptb.provincia.biella.it",
      "pec": "torrazzo@pec.ptbiellese.it",
      "telefono": "+39 015.2551134",
      "fax": "+39 015.2551134",
      "coordinate": {
        "lat": 45.49837875366211,
        "lng": 7.953648090362549
      }
    },
    {
      "codice": "096071",
      "nome": "Valdengo",
      "nomeStraniero": null,
      "codiceCatastale": "L556",
      "cap": "13855",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "anagrafe@comune.valdengo.bi.it",
      "pec": "valdengo@pec.ptbiellese.it",
      "telefono": "+39 015.881852",
      "fax": "+39 015.8285600",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "096088",
      "nome": "Valdilana",
      "nomeStraniero": null,
      "codiceCatastale": "M417",
      "cap": "13835",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "valdilana@ptb.provincia.biella.it",
      "pec": "comune.valdilana.bi@legalmail.it",
      "telefono": "0157592111",
      "fax": null,
      "coordinate": {
        "lat": 45.657127380371094,
        "lng": 8.150514602661133
      }
    },
    {
      "codice": "096072",
      "nome": "Vallanzengo",
      "nomeStraniero": null,
      "codiceCatastale": "L586",
      "cap": "13847",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "vallanzengo@ptb.provincia.biella.it",
      "pec": "vallanzengo@pec.ptbiellese.it",
      "telefono": "+39 015.743262",
      "fax": "+39 015.743262",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "096074",
      "nome": "Valle San Nicolao",
      "nomeStraniero": null,
      "codiceCatastale": "L620",
      "cap": "13847",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "vallesnicolao@ptb.provincia.biella.it",
      "pec": "valle.san.nicolao@pec.ptbiellese.it",
      "telefono": "+39 015743136",
      "fax": "+39 015743553",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "096075",
      "nome": "Veglio",
      "nomeStraniero": null,
      "codiceCatastale": "L712",
      "cap": "13824",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "veglio@ptb.provincia.biella.it",
      "pec": "veglio@pec.ptbiellese.it",
      "telefono": "+39 015.748112",
      "fax": "+39 015.748112",
      "coordinate": {
        "lat": 45.64056396484375,
        "lng": 8.114119529724121
      }
    },
    {
      "codice": "096076",
      "nome": "Verrone",
      "nomeStraniero": null,
      "codiceCatastale": "L785",
      "cap": "13871",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "tecnico@comune.verrone.bi.it",
      "pec": "verrone@pec.ptbiellese.it",
      "telefono": "+39 015/5821032",
      "fax": "+39 015.5821968",
      "coordinate": {
        "lat": 45.50823974609375,
        "lng": 8.115139961242676
      }
    },
    {
      "codice": "096077",
      "nome": "Vigliano Biellese",
      "nomeStraniero": null,
      "codiceCatastale": "L880",
      "cap": "13856",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "demografico@vigliano.info",
      "pec": "certificata@pec.vigliano.info",
      "telefono": "+39 015.512041",
      "fax": null,
      "coordinate": {
        "lat": 45.561668395996094,
        "lng": 8.111666679382324
      }
    },
    {
      "codice": "096078",
      "nome": "Villa del Bosco",
      "nomeStraniero": null,
      "codiceCatastale": "L933",
      "cap": "13868",
      "prefisso": "0163",
      "provincia": "Biella",
      "email": "anag.villadelbosco@ptb.provincia.biella.it",
      "pec": "villa.del.bosco@pec.ptbiellese.it",
      "telefono": "+39 0163.860243",
      "fax": "+39 0163.861305",
      "coordinate": {
        "lat": 45.61669158935547,
        "lng": 8.277759552001953
      }
    },
    {
      "codice": "096079",
      "nome": "Villanova Biellese",
      "nomeStraniero": null,
      "codiceCatastale": "L978",
      "cap": "13877",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "anag.villanova@ptb.provincia.biella.it",
      "pec": "villanovabiellese@pec.ptbiellese.it",
      "telefono": "+39 0161.852188",
      "fax": "+39 0161.878828",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 8.199999809265137
      }
    },
    {
      "codice": "096080",
      "nome": "Viverone",
      "nomeStraniero": null,
      "codiceCatastale": "M098",
      "cap": "13886",
      "prefisso": "0161",
      "provincia": "Biella",
      "email": "viverone@ptb.provincia.biella.it",
      "pec": "tributi.viverone@pec.ptbiellese.it",
      "telefono": "+39 0161.987021",
      "fax": "+39 0161.987498",
      "coordinate": {
        "lat": 45.424720764160156,
        "lng": 8.054444313049316
      }
    },
    {
      "codice": "096081",
      "nome": "Zimone",
      "nomeStraniero": null,
      "codiceCatastale": "M179",
      "cap": "13887",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "zimone@ptb.provincia.biella.it",
      "pec": "zimone@pec.ptbiellese.it",
      "telefono": "+39 015.670208",
      "fax": "+39 015.670133",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8
      }
    },
    {
      "codice": "096082",
      "nome": "Zubiena",
      "nomeStraniero": null,
      "codiceCatastale": "M196",
      "cap": "13888",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "comunezubiena@tiscali.it",
      "pec": "zubiena@cert.ruparpiemonte.it",
      "telefono": "+39 015.660103",
      "fax": "+39 015.660290",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 7.949999809265137
      }
    },
    {
      "codice": "096083",
      "nome": "Zumaglia",
      "nomeStraniero": null,
      "codiceCatastale": "M201",
      "cap": "13848",
      "prefisso": "015",
      "provincia": "Biella",
      "email": "zumaglia@ptb.provincia.biella.it",
      "pec": "zumaglia@pec.ptbiellese.it",
      "telefono": "+39 015.461457",
      "fax": "+39 015.463167",
      "coordinate": {
        "lat": 45.593055725097656,
        "lng": 8.089444160461426
      }
    }
  ],
  "Benevento": [
    {
      "codice": "062001",
      "nome": "Airola",
      "nomeStraniero": null,
      "codiceCatastale": "A110",
      "cap": "82011",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "suap@comune.airola.bn.it",
      "pec": "airola@pec.cstsannio.it",
      "telefono": "+39 0823 711844",
      "fax": "+39 0823 711761",
      "coordinate": {
        "lat": 41.05870819091797,
        "lng": 14.559240341186523
      }
    },
    {
      "codice": "062002",
      "nome": "Amorosi",
      "nomeStraniero": null,
      "codiceCatastale": "A265",
      "cap": "82031",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "sindaco@comune.amorosi.bn.it",
      "pec": "protocollo@pec.comune.amorosi.bn.it",
      "telefono": "+39 0824 970212",
      "fax": null,
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "062003",
      "nome": "Apice",
      "nomeStraniero": null,
      "codiceCatastale": "A328",
      "cap": "82021",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segreteria@comune.apice.bn.it",
      "pec": "protocollo@pec.comune.apice.bn.it",
      "telefono": "+39 0824 921710",
      "fax": "+39 0824 921742",
      "coordinate": {
        "lat": 41.11666488647461,
        "lng": 14.933333396911621
      }
    },
    {
      "codice": "062004",
      "nome": "Apollosa",
      "nomeStraniero": null,
      "codiceCatastale": "A330",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "protocollo@comune.apollosa.bn.it",
      "pec": "apollosa@pec.cstsannio.it",
      "telefono": "+39 0824 44004",
      "fax": "+39 0824 44497",
      "coordinate": {
        "lat": 41.099998474121094,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "062005",
      "nome": "Arpaia",
      "nomeStraniero": null,
      "codiceCatastale": "A431",
      "cap": "82012",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "comune.arpaia@asmepec.it",
      "pec": "comune.arpaia@asmepec.it",
      "telefono": "+39 0823 950711",
      "fax": "+39 0823 851046",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "062006",
      "nome": "Arpaise",
      "nomeStraniero": null,
      "codiceCatastale": "A432",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunearpaise@virgilio.it",
      "pec": "comunearpaise@asmepec.it",
      "telefono": "+39 0824 46025",
      "fax": "+39 0824 46941",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.75
      }
    },
    {
      "codice": "062007",
      "nome": "Baselice",
      "nomeStraniero": null,
      "codiceCatastale": "A696",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "amministrativo@comune.baselice.bn.it",
      "pec": "comune.baselice@asmepec.it",
      "telefono": "+39 0824  968066",
      "fax": "+39 0824 968513",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 14.966666221618652
      }
    },
    {
      "codice": "062008",
      "nome": "Benevento",
      "nomeStraniero": null,
      "codiceCatastale": "A783",
      "cap": "82100",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "ced@comune.benevento.it",
      "pec": "urp@pec.comunebn.it",
      "telefono": "+39 0824 772241",
      "fax": "+39 0824 778850",
      "coordinate": {
        "lat": 41.13333511352539,
        "lng": 14.783333778381348
      }
    },
    {
      "codice": "062009",
      "nome": "Bonea",
      "nomeStraniero": null,
      "codiceCatastale": "A970",
      "cap": "82013",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "protocollo@comune.bonea.bn.it",
      "pec": "protocollo@pec.comune.bonea.bn.it",
      "telefono": "+39 0824 834271",
      "fax": "+39 0824 890028",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "062010",
      "nome": "Bucciano",
      "nomeStraniero": null,
      "codiceCatastale": "B239",
      "cap": "82010",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "anagrafe@comune.bucciano.bn.it",
      "pec": "protocollo.bucciano@asmepec.it",
      "telefono": "+39 0823 712742",
      "fax": "+39 0823 714312",
      "coordinate": {
        "lat": 41.07756042480469,
        "lng": 14.56898021697998
      }
    },
    {
      "codice": "062011",
      "nome": "Buonalbergo",
      "nomeStraniero": null,
      "codiceCatastale": "B267",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@omune.buonalbergo.bn.it",
      "pec": "info@pec.comune.buonalbergo.bn.it",
      "telefono": "+39 0824 929588",
      "fax": "+39 0824 929921",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "062012",
      "nome": "Calvi",
      "nomeStraniero": null,
      "codiceCatastale": "B444",
      "cap": "82018",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.calvi.bn.it",
      "pec": "protocollo@pec.comune.calvi.bn.it",
      "telefono": "+39 0824 338364",
      "fax": "+39 0824 40423",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "062013",
      "nome": "Campolattaro",
      "nomeStraniero": null,
      "codiceCatastale": "B541",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "sindaco@comunecampolattaro.it",
      "pec": "segretario@pec.comunecampolattaro.it",
      "telefono": "+39 0824 858030",
      "fax": "+39 0824 858363",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 14.733333587646484
      }
    },
    {
      "codice": "062014",
      "nome": "Campoli del Monte Taburno",
      "nomeStraniero": null,
      "codiceCatastale": "B542",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "demografici@comune.campolidelmontetaburno.bn.it",
      "pec": "demografici@pec.it",
      "telefono": "+39 0824 873039",
      "fax": null,
      "coordinate": {
        "lat": 41.13333511352539,
        "lng": 14.649999618530273
      }
    },
    {
      "codice": "062015",
      "nome": "Casalduni",
      "nomeStraniero": null,
      "codiceCatastale": "B873",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comune.casalduni@libero.it",
      "pec": "protocollo.casalduni@asmepec.it",
      "telefono": "+39 0824 856224",
      "fax": "+39 0824 856493",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "062016",
      "nome": "Castelfranco in Miscano",
      "nomeStraniero": null,
      "codiceCatastale": "C106",
      "cap": "82022",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.castelfrancoinmiscano.bn.it",
      "pec": "protocollo.castelfrancoinmiscano@asmepec.it",
      "telefono": "+39 0824 960066",
      "fax": "+39 0824 960297",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "062017",
      "nome": "Castelpagano",
      "nomeStraniero": null,
      "codiceCatastale": "C245",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "anagrafe@comune.castelpagano.bn.it",
      "pec": "protocollo@pec.comune.castelpagano.bn.it",
      "telefono": "+39 0824 935205",
      "fax": "+39 0824 935217",
      "coordinate": {
        "lat": 41.400001525878906,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "062018",
      "nome": "Castelpoto",
      "nomeStraniero": null,
      "codiceCatastale": "C250",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.castelpoto.bn.it",
      "pec": "castelpoto@pec.cstsannio.it",
      "telefono": "+39 0824/59008",
      "fax": "+39 0824/99031",
      "coordinate": {
        "lat": 41.150001525878906,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "062019",
      "nome": "Castelvenere",
      "nomeStraniero": null,
      "codiceCatastale": "C280",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "protocollo@comune.castelvenere.bn.it",
      "pec": "protocollo@pec.comune.castelvenere.bn.it",
      "telefono": "+39 0824/940210",
      "fax": "+39 0824/940539",
      "coordinate": {
        "lat": 41.233333587646484,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "062020",
      "nome": "Castelvetere in Val Fortore",
      "nomeStraniero": null,
      "codiceCatastale": "C284",
      "cap": "82023",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "amministrativo@castelvetereinvf.it",
      "pec": "amministrativo.castelvetereinvf@asmepec.it",
      "telefono": "+39 0824 932085",
      "fax": "+39 0824 933150",
      "coordinate": {
        "lat": 41.45000076293945,
        "lng": 14.933333396911621
      }
    },
    {
      "codice": "062021",
      "nome": "Cautano",
      "nomeStraniero": null,
      "codiceCatastale": "C359",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segreteria@comune.cautano.bn.it",
      "pec": "comune.cautano@asmepec.it",
      "telefono": "+39 0824 880700",
      "fax": "+39 0824 880621",
      "coordinate": {
        "lat": 41.150001525878906,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "062022",
      "nome": "Ceppaloni",
      "nomeStraniero": null,
      "codiceCatastale": "C476",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comuneceppaloni@iscali.it",
      "pec": "protocollo.ceppaloni@asmepec.it",
      "telefono": "+39 0824 66511",
      "fax": "+39 0824 66504",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "062023",
      "nome": "Cerreto Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "C525",
      "cap": "82032",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segreteria@comune.cerretosannita.it",
      "pec": "protocollocerretosannita@pec.it",
      "telefono": "+39 0824 815211",
      "fax": "+39 0824 860744",
      "coordinate": {
        "lat": 41.2852783203125,
        "lng": 14.559721946716309
      }
    },
    {
      "codice": "062024",
      "nome": "Circello",
      "nomeStraniero": null,
      "codiceCatastale": "C719",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "anagrafe@circello.com",
      "pec": "circello@pec.it",
      "telefono": "+39 0824 937201",
      "fax": "+39 0824 937287",
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "062025",
      "nome": "Colle Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "C846",
      "cap": "82024",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunecollesannita@gmail.com",
      "pec": "comune.collesannita@pcert.it",
      "telefono": "+39 0824 931052",
      "fax": "+39 0824 931522",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "062026",
      "nome": "Cusano Mutri",
      "nomeStraniero": null,
      "codiceCatastale": "D230",
      "cap": "82033",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunedicusanomutri@virgilio.it",
      "pec": "comune.cusanomutri@asmepec.it",
      "telefono": "+39 0824 862003",
      "fax": "+39 0824 868991",
      "coordinate": {
        "lat": 41.338890075683594,
        "lng": 14.508610725402832
      }
    },
    {
      "codice": "062027",
      "nome": "Dugenta",
      "nomeStraniero": null,
      "codiceCatastale": "D380",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segreteria@omune.dugenta.bn.it",
      "pec": "protocollo.dugenta.bn@pcert.it",
      "telefono": "+39 0824 978003",
      "fax": "+39 0824 978381",
      "coordinate": {
        "lat": 41.13333511352539,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "062028",
      "nome": "Durazzano",
      "nomeStraniero": null,
      "codiceCatastale": "D386",
      "cap": "82015",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "comune.durazzano@iscali.it",
      "pec": "anadurazzano@pec.it",
      "telefono": "+39 0823955049",
      "fax": "+39 0823 719140",
      "coordinate": {
        "lat": 41.06285858154297,
        "lng": 14.449469566345215
      }
    },
    {
      "codice": "062029",
      "nome": "Faicchio",
      "nomeStraniero": null,
      "codiceCatastale": "D469",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segreteria@comune.faicchio.bn.it",
      "pec": "segreteriafaicchio@pec.it",
      "telefono": "+39 0824 819323",
      "fax": "+39 0824 863188",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "062030",
      "nome": "Foglianise",
      "nomeStraniero": null,
      "codiceCatastale": "D644",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "postmaster@comune.foglianise.bn.it",
      "pec": "protocollo@pec.comune.foglianise.bn.it",
      "telefono": "+39 0824/878064",
      "fax": "+39 0824/878445",
      "coordinate": {
        "lat": 41.16006088256836,
        "lng": 14.67179012298584
      }
    },
    {
      "codice": "062031",
      "nome": "Foiano di Val Fortore",
      "nomeStraniero": null,
      "codiceCatastale": "D650",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comune.foianovf@virgilio.it",
      "pec": "comune.foianovf@asmepec.it",
      "telefono": "+39 0824 966004",
      "fax": "+39 0824 966247",
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 14.983333587646484
      }
    },
    {
      "codice": "062032",
      "nome": "Forchia",
      "nomeStraniero": null,
      "codiceCatastale": "D693",
      "cap": "82011",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "forchia@tin.it",
      "pec": "comunediforchia@asmepec.it",
      "telefono": "+39 0823 950316",
      "fax": "+39 0823 950857",
      "coordinate": {
        "lat": 41.03144073486328,
        "lng": 14.535730361938477
      }
    },
    {
      "codice": "062033",
      "nome": "Fragneto l'Abate",
      "nomeStraniero": null,
      "codiceCatastale": "D755",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "pietropetrone@comune.fragnetolabate.bn.it",
      "pec": "fragnetolabate@pec.cstsannio.it",
      "telefono": "+39 0824 996180",
      "fax": "+39 0824 996124",
      "coordinate": {
        "lat": 41.25583267211914,
        "lng": 14.78166675567627
      }
    },
    {
      "codice": "062034",
      "nome": "Fragneto Monforte",
      "nomeStraniero": null,
      "codiceCatastale": "D756",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "ufficio.anagrafe@comune.fragnetomonforte.bn.it",
      "pec": "anagrafe@pec.comune.fragnetomonforte.bn.it",
      "telefono": "+39 0824 986006",
      "fax": "+39 0824 993655",
      "coordinate": {
        "lat": 41.246665954589844,
        "lng": 14.763333320617676
      }
    },
    {
      "codice": "062035",
      "nome": "Frasso Telesino",
      "nomeStraniero": null,
      "codiceCatastale": "D784",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "seg@comune.frassotelesino.bn.it",
      "pec": "rag@pec.comune.frassotelesino.bn.it",
      "telefono": "+39 0824 973024",
      "fax": "+39 0824 973025",
      "coordinate": {
        "lat": 41.150001525878906,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "062036",
      "nome": "Ginestra degli Schiavoni",
      "nomeStraniero": null,
      "codiceCatastale": "E034",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunediginestra3@virgilio.it",
      "pec": "uff.amm.vo.moffa.ginestra@asmepec.it",
      "telefono": "+39 0824/961002",
      "fax": "+39 0824/961261",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 15.033333778381348
      }
    },
    {
      "codice": "062037",
      "nome": "Guardia Sanframondi",
      "nomeStraniero": null,
      "codiceCatastale": "E249",
      "cap": "82034",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segretario@comune.guardiasanframondi.bn.it",
      "pec": "guardia@pec.cstsannio.it",
      "telefono": "+39 0824 817444",
      "fax": "+39 0824 817400",
      "coordinate": {
        "lat": 41.25,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "062038",
      "nome": "Limatola",
      "nomeStraniero": null,
      "codiceCatastale": "E589",
      "cap": "82030",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "protocollo@comune.limatola.bn.it",
      "pec": "protocollolimatola@pec.it",
      "telefono": "+39 0823 481966",
      "fax": "+39 0823 481251",
      "coordinate": {
        "lat": 41.150001525878906,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "062039",
      "nome": "Melizzano",
      "nomeStraniero": null,
      "codiceCatastale": "F113",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "ufficiodemogra@virgilio.it",
      "pec": "comune.melizzano.demografici@pec.cstsannio.it",
      "telefono": "+39 0824 944023",
      "fax": "+39 0824 944460",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 14.5
      }
    },
    {
      "codice": "062040",
      "nome": "Moiano",
      "nomeStraniero": null,
      "codiceCatastale": "F274",
      "cap": "82010",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "ragioneria@comune.moiano.it",
      "pec": "comune.moiano@postecert.it",
      "telefono": "+39 0823711750",
      "fax": "+39 0823 714254",
      "coordinate": {
        "lat": 41.08333206176758,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "062041",
      "nome": "Molinara",
      "nomeStraniero": null,
      "codiceCatastale": "F287",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "virgilio@comune.molinara.bn.it",
      "pec": "info@comune.molinara.bn.it",
      "telefono": "+39 0824 994001",
      "fax": "+39 0824 994014",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "062042",
      "nome": "Montefalcone di Val Fortore",
      "nomeStraniero": null,
      "codiceCatastale": "F494",
      "cap": "82025",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "elettorale@montefalconedivalfortore.campania.it",
      "pec": "elettorale@pec.montefalconedivalfortore.campania.it",
      "telefono": "+39 0824 969003",
      "fax": "+39 0824 969426",
      "coordinate": {
        "lat": 41.31666564941406,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "062043",
      "nome": "Montesarchio",
      "nomeStraniero": null,
      "codiceCatastale": "F636",
      "cap": "82016",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "protocollo@comune.montesarchio.bn.it",
      "pec": "protocollo@pec.comune.montesarchio.bn.it",
      "telefono": "+39 0824 892230",
      "fax": null,
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "062044",
      "nome": "Morcone",
      "nomeStraniero": null,
      "codiceCatastale": "F717",
      "cap": "82026",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "responsabileamministrativo@omune.morcone.bn.it",
      "pec": "protocollo.morcone.bn@asmepec.it",
      "telefono": "+39 0824 955438",
      "fax": "+39 0824 957145",
      "coordinate": {
        "lat": 41.342689514160156,
        "lng": 14.668889999389648
      }
    },
    {
      "codice": "062045",
      "nome": "Paduli",
      "nomeStraniero": null,
      "codiceCatastale": "G227",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "ufftecnico@comune.paduli.bn.it",
      "pec": "protocollo@pec.comunepaduli.gov.it",
      "telefono": "+39 0824928019",
      "fax": "+39 0824928129",
      "coordinate": {
        "lat": 41.16666793823242,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "062046",
      "nome": "Pago Veiano",
      "nomeStraniero": null,
      "codiceCatastale": "G243",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "anagrafepagoveiano@virgilio.it",
      "pec": "comunepagoveiano@pec.it",
      "telefono": "+39 0824 987001",
      "fax": "+39 0824 987687",
      "coordinate": {
        "lat": 41.25,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "062047",
      "nome": "Pannarano",
      "nomeStraniero": null,
      "codiceCatastale": "G311",
      "cap": "82017",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.pannarano.bn.it",
      "pec": "protocollo.pannarano@asmepec.it",
      "telefono": "+39 0824 830003",
      "fax": "+39 0824 830270",
      "coordinate": {
        "lat": 41.010833740234375,
        "lng": 14.702777862548828
      }
    },
    {
      "codice": "062048",
      "nome": "Paolisi",
      "nomeStraniero": null,
      "codiceCatastale": "G318",
      "cap": "82011",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "paolisicultura@tiscali.it",
      "pec": "demografici.paolisi@asmepec.it",
      "telefono": "+39 0823 1505180",
      "fax": "+39 0823 850550",
      "coordinate": {
        "lat": 41.03333282470703,
        "lng": 14.583333015441895
      }
    },
    {
      "codice": "062049",
      "nome": "Paupisi",
      "nomeStraniero": null,
      "codiceCatastale": "G386",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "anagrafepaupisi@virgilio.it",
      "pec": "segreteria@pec.comune.paupisi.bn.it",
      "telefono": "+39 0824 872481",
      "fax": "+39 0824 872119",
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "062050",
      "nome": "Pesco Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "G494",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comune@omune.pescosannita.bn.it",
      "pec": "info@pec.comune.pescosannita.bn.it",
      "telefono": "+39 0824981037",
      "fax": "+39 0824 981200",
      "coordinate": {
        "lat": 41.233333587646484,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "062051",
      "nome": "Pietraroja",
      "nomeStraniero": null,
      "codiceCatastale": "G626",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "anagrafe.pietraroja@libero.it",
      "pec": "pietraroja@pec.cstsannio.it",
      "telefono": "+39 0824 868000",
      "fax": "+39 0824 868002",
      "coordinate": {
        "lat": 41.34749984741211,
        "lng": 14.544166564941406
      }
    },
    {
      "codice": "062052",
      "nome": "Pietrelcina",
      "nomeStraniero": null,
      "codiceCatastale": "G631",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "silpaci@libero.it",
      "pec": "pietrelcina@pec.cstsannio.it",
      "telefono": "+39 0824 990603",
      "fax": "+39 0824 990617",
      "coordinate": {
        "lat": 41.20000076293945,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "062053",
      "nome": "Ponte",
      "nomeStraniero": null,
      "codiceCatastale": "G827",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comuneponte@libero.it",
      "pec": "ponte@pec.comunediponte.it",
      "telefono": "+39 0824 874114",
      "fax": "+39 0824 1771851",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.699999809265137
      }
    },
    {
      "codice": "062054",
      "nome": "Pontelandolfo",
      "nomeStraniero": null,
      "codiceCatastale": "G848",
      "cap": "82027",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.pontelandolfo.bn.it",
      "pec": "comune.pontelandolfo@pec.it",
      "telefono": "+39 0824 851844",
      "fax": "+39 0824 851101",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 14.683333396911621
      }
    },
    {
      "codice": "062055",
      "nome": "Puglianello",
      "nomeStraniero": null,
      "codiceCatastale": "H087",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunedipuglinello@libero.it",
      "pec": "puglianello@pec.cstsannio.it",
      "telefono": "+39 0824946588",
      "fax": "+39 0824 946588",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "062056",
      "nome": "Reino",
      "nomeStraniero": null,
      "codiceCatastale": "H227",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "anagrafe@comune.reino.bn.it",
      "pec": "protocollo.reino@pec.it",
      "telefono": "+39 0824 985030",
      "fax": "+39 0824 985244",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "062057",
      "nome": "San Bartolomeo in Galdo",
      "nomeStraniero": null,
      "codiceCatastale": "H764",
      "cap": "82028",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "demosanba@virgilio.it",
      "pec": "protocollo.sanbartolomeoingaldo@asmepec.it",
      "telefono": "+39 0824 8244111",
      "fax": "+39 0824 8244252",
      "coordinate": {
        "lat": 41.41666793823242,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "062058",
      "nome": "San Giorgio del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "H894",
      "cap": "82018",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "affarigenerali2002@libero.it",
      "pec": "protocollosgs@pec.cstsannio.it",
      "telefono": "+39 0824 334922",
      "fax": "+39 0824 337270",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.850000381469727
      }
    },
    {
      "codice": "062059",
      "nome": "San Giorgio La Molara",
      "nomeStraniero": null,
      "codiceCatastale": "H898",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "protocollo@comune.sangiorgiolamolara.bn.it",
      "pec": "protocollo@pec.comunesangiorgiolamolara.bn.it",
      "telefono": "+39 0824 988348",
      "fax": "+39 0824 988351",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.916666984558105
      }
    },
    {
      "codice": "062060",
      "nome": "San Leucio del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "H953",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "dirigenteufficiotecnico@comune.sanleuciodelsannio.bn.it",
      "pec": "protocollogenerale.sanleuciodelsannio@asmepec.it",
      "telefono": "+39 0824 381948",
      "fax": "+39 0824 45095",
      "coordinate": {
        "lat": 41.08333206176758,
        "lng": 14.766666412353516
      }
    },
    {
      "codice": "062061",
      "nome": "San Lorenzello",
      "nomeStraniero": null,
      "codiceCatastale": "H955",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "umbertofraenza@comunesanlorenzello.191.it",
      "pec": "tecnico2.sanlorenzello@asmepec.it",
      "telefono": "+39 0824 815134",
      "fax": "+39 0824 815136",
      "coordinate": {
        "lat": 41.27694320678711,
        "lng": 14.542499542236328
      }
    },
    {
      "codice": "062062",
      "nome": "San Lorenzo Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "H967",
      "cap": "82034",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "pig.sanlorenzo@tiscali.it",
      "pec": "prot.slm@asmepec.it",
      "telefono": "+39 0824 813711",
      "fax": "+39 0824 813597",
      "coordinate": {
        "lat": 41.25,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "062063",
      "nome": "San Lupo",
      "nomeStraniero": null,
      "codiceCatastale": "H973",
      "cap": "82034",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.sanlupo.bn.it",
      "pec": "info@pec.comune.sanlupo.bn.it",
      "telefono": "+39 0824 811002",
      "fax": "+39 0824 811089",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "062064",
      "nome": "San Marco dei Cavoti",
      "nomeStraniero": null,
      "codiceCatastale": "H984",
      "cap": "82029",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.sanmarcodeicavoti.bn.it",
      "pec": "postmaster@pec.comune.sanmarcodeicavoti.bn.it",
      "telefono": "+39 0824 984009",
      "fax": "+39 0824 984035",
      "coordinate": {
        "lat": 41.31083297729492,
        "lng": 14.880555152893066
      }
    },
    {
      "codice": "062065",
      "nome": "San Martino Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "I002",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "demografici@comune.sanmartinosannita.bn.it",
      "pec": "sanmartinosannita@pec.cstsannio.it",
      "telefono": "+39 0824 49171",
      "fax": "+39 0824 337919",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.833333015441895
      }
    },
    {
      "codice": "062066",
      "nome": "San Nazzaro",
      "nomeStraniero": null,
      "codiceCatastale": "I049",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comune.sannazzaro@pec.it",
      "pec": "comune.sannazzaro@pec.it",
      "telefono": "+39 0824 58676",
      "fax": "+39 0824 480893",
      "coordinate": {
        "lat": 41.04999923706055,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "062067",
      "nome": "San Nicola Manfredi",
      "nomeStraniero": null,
      "codiceCatastale": "I062",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": null,
      "pec": "anagrafe.sannicolamanfredi@pec.it",
      "telefono": "+39 0824/56021",
      "fax": null,
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.816666603088379
      }
    },
    {
      "codice": "062068",
      "nome": "San Salvatore Telesino",
      "nomeStraniero": null,
      "codiceCatastale": "I145",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comunesansalvatoretelesino.eu",
      "pec": "sansalvatoretelesino@pec.cstsannio.it",
      "telefono": "+39 0824 881202",
      "fax": "+39 0824 881216",
      "coordinate": {
        "lat": 41.233333587646484,
        "lng": 14.5
      }
    },
    {
      "codice": "062070",
      "nome": "Sant'Agata de' Goti",
      "nomeStraniero": null,
      "codiceCatastale": "I197",
      "cap": "82019",
      "prefisso": "0823",
      "provincia": "Benevento",
      "email": "gda@gdaweb.net",
      "pec": "comunesantagatadegoti@pec.it",
      "telefono": "+39 0823 718247",
      "fax": "+39 0823 953445",
      "coordinate": {
        "lat": 41.08932113647461,
        "lng": 14.497429847717285
      }
    },
    {
      "codice": "062071",
      "nome": "Sant'Angelo a Cupolo",
      "nomeStraniero": null,
      "codiceCatastale": "I277",
      "cap": "82010",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "capozzi@comunesantangelo.it",
      "pec": "protocollo@pec.comunesantangelo.it",
      "telefono": "+39 0824380300",
      "fax": "+39 0824 41286",
      "coordinate": {
        "lat": 41.06666564941406,
        "lng": 14.800000190734863
      }
    },
    {
      "codice": "062078",
      "nome": "Sant'Arcangelo Trimonte",
      "nomeStraniero": null,
      "codiceCatastale": "F557",
      "cap": "82021",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "rompisan@tin.it",
      "pec": "comune.santarcangelot@asmepec.it",
      "telefono": "+39 0824 923001",
      "fax": "+39 0824 923251",
      "coordinate": {
        "lat": 41.150001525878906,
        "lng": 14.933333396911621
      }
    },
    {
      "codice": "062069",
      "nome": "Santa Croce del Sannio",
      "nomeStraniero": null,
      "codiceCatastale": "I179",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "rag.santacroce@alice.it",
      "pec": "info@pec.comune.santacrocedelsannio.bn.it",
      "telefono": "+39 0824 950005",
      "fax": "+39 0824 950242",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 14.716666221618652
      }
    },
    {
      "codice": "062072",
      "nome": "Sassinoro",
      "nomeStraniero": null,
      "codiceCatastale": "I455",
      "cap": "82020",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunesassinoro@virgilio.it",
      "pec": "ufftecnico.sassinoro@postecert.it",
      "telefono": "+39 0824 958002",
      "fax": "+39 0824 958191",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "062073",
      "nome": "Solopaca",
      "nomeStraniero": null,
      "codiceCatastale": "I809",
      "cap": "82036",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comunesolopaca@comunesolopaca.it",
      "pec": "comune.solopaca@asmepec.it",
      "telefono": "+39 0824 977477",
      "fax": "+39 0824 977752",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "062074",
      "nome": "Telese Terme",
      "nomeStraniero": null,
      "codiceCatastale": "L086",
      "cap": "82037",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "comune.teleseterme.segreteria@pec.cstsannio.it",
      "pec": "comune.teleseterme.protocollo@pec.cstsannio.it",
      "telefono": "+39 0824 974101",
      "fax": "+39 0824 975476",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "062075",
      "nome": "Tocco Caudio",
      "nomeStraniero": null,
      "codiceCatastale": "L185",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "utc@comune.toccocaudio.bn.it",
      "pec": "comune.toccocaudio@pcert.it",
      "telefono": "+39 0824 888235",
      "fax": "+39 0824 888454",
      "coordinate": {
        "lat": 41.13333511352539,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "062076",
      "nome": "Torrecuso",
      "nomeStraniero": null,
      "codiceCatastale": "L254",
      "cap": "82030",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "segretario@comune.torrecuso.bn.it",
      "pec": "torrecuso@pec.cstsannio.it",
      "telefono": "+39 0824 889713",
      "fax": "+39 0824 889732",
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.683333396911621
      }
    },
    {
      "codice": "062077",
      "nome": "Vitulano",
      "nomeStraniero": null,
      "codiceCatastale": "M093",
      "cap": "82038",
      "prefisso": "0824",
      "provincia": "Benevento",
      "email": "info@comune.vitulano.bn.it",
      "pec": "vitulano@pec.cstsannio.it",
      "telefono": "+39 0824 878622",
      "fax": null,
      "coordinate": {
        "lat": 41.18333435058594,
        "lng": 14.649999618530273
      }
    }
  ],
  "Imperia": [
    {
      "codice": "008001",
      "nome": "Airole",
      "nomeStraniero": null,
      "codiceCatastale": "A111",
      "cap": "18030",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "airolecom@libero.it",
      "pec": "comune.airole.im@legalmail.it",
      "telefono": "+39 0184/200027",
      "fax": "+39 0184/200942",
      "coordinate": {
        "lat": 43.871883392333984,
        "lng": 7.553377628326416
      }
    },
    {
      "codice": "008002",
      "nome": "Apricale",
      "nomeStraniero": null,
      "codiceCatastale": "A338",
      "cap": "18035",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "apricale@apricale.org",
      "pec": "apricale@pec.it",
      "telefono": "+39 0184/208126",
      "fax": "+39 0184/208508",
      "coordinate": {
        "lat": 43.88053512573242,
        "lng": 7.660483360290527
      }
    },
    {
      "codice": "008003",
      "nome": "Aquila d'Arroscia",
      "nomeStraniero": null,
      "codiceCatastale": "A344",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comuneaquilaarroscia@libero.it",
      "pec": "comune.aquilaarroscia@pec.cstliguria.it",
      "telefono": "+39 0183/382057",
      "fax": "+39 0183/382057",
      "coordinate": {
        "lat": 44.08354568481445,
        "lng": 8.005427360534668
      }
    },
    {
      "codice": "008004",
      "nome": "Armo",
      "nomeStraniero": null,
      "codiceCatastale": "A418",
      "cap": "18026",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comunearmo@libero.it",
      "pec": "comune.armo.im@legalmail.it",
      "telefono": "+39 0183/367835",
      "fax": "+39 0183/367846",
      "coordinate": {
        "lat": 44.08796310424805,
        "lng": 7.914782524108887
      }
    },
    {
      "codice": "008005",
      "nome": "Aurigo",
      "nomeStraniero": null,
      "codiceCatastale": "A499",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "aurigo.protocollo@comune.aurigo.im.it",
      "pec": "ragioneria.aurigo@pec.it",
      "telefono": "+39 0183/54041",
      "fax": "+39 0183/54401",
      "coordinate": {
        "lat": 43.98281478881836,
        "lng": 7.922830581665039
      }
    },
    {
      "codice": "008006",
      "nome": "Badalucco",
      "nomeStraniero": null,
      "codiceCatastale": "A536",
      "cap": "18010",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comunedibadalucco.it",
      "pec": "comunedibadalucco@legpec.it",
      "telefono": "+39 0184/407007",
      "fax": "+39 0184/408651",
      "coordinate": {
        "lat": 43.91569900512695,
        "lng": 7.846997261047363
      }
    },
    {
      "codice": "008007",
      "nome": "Bajardo",
      "nomeStraniero": null,
      "codiceCatastale": "A581",
      "cap": "18031",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "amministrazione@comunebajardo.it",
      "pec": "bajardo@pec.comunebajardo.it",
      "telefono": "+39 0184/673054",
      "fax": "+39 0184/673015",
      "coordinate": {
        "lat": 43.90349197387695,
        "lng": 7.718408107757568
      }
    },
    {
      "codice": "008008",
      "nome": "Bordighera",
      "nomeStraniero": null,
      "codiceCatastale": "A984",
      "cap": "18012",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "segreteria@bordighera.it",
      "pec": "bordighera@legalmail.it",
      "telefono": "01842721",
      "fax": null,
      "coordinate": {
        "lat": 43.77889633178711,
        "lng": 7.672127723693848
      }
    },
    {
      "codice": "008009",
      "nome": "Borghetto d'Arroscia",
      "nomeStraniero": null,
      "codiceCatastale": "A993",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "borghetto@uno.it",
      "pec": "protocollo@pec.comune.borghettodarroscia.im.it",
      "telefono": "+39 0183/31061",
      "fax": "+39 0183/31105",
      "coordinate": {
        "lat": 44.05738067626953,
        "lng": 7.981752872467041
      }
    },
    {
      "codice": "008010",
      "nome": "Borgomaro",
      "nomeStraniero": null,
      "codiceCatastale": "B020",
      "cap": "18021",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "info@comune.borgomaro.im.it",
      "pec": "protocollo@pec.comune.borgomaro.im.it",
      "telefono": "+39 0183/54571",
      "fax": "+39 0183/54572",
      "coordinate": {
        "lat": 43.97552490234375,
        "lng": 7.944802761077881
      }
    },
    {
      "codice": "008011",
      "nome": "Camporosso",
      "nomeStraniero": null,
      "codiceCatastale": "B559",
      "cap": "18033",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comunedicamporosso.it",
      "pec": "comunedicamporosso.im.comunicazione@pa.postacertificata.gov.it",
      "telefono": "+39 0184/287753",
      "fax": "+39 0184/287777",
      "coordinate": {
        "lat": 43.815128326416016,
        "lng": 7.6284332275390625
      }
    },
    {
      "codice": "008012",
      "nome": "Caravonica",
      "nomeStraniero": null,
      "codiceCatastale": "B734",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "caravonica@uno.it",
      "pec": "caravonica@pec.it",
      "telefono": "+39 0183/55050",
      "fax": "+39 0183/554914",
      "coordinate": {
        "lat": 43.99233627319336,
        "lng": 7.9587249755859375
      }
    },
    {
      "codice": "008015",
      "nome": "Castel Vittorio",
      "nomeStraniero": null,
      "codiceCatastale": "C110",
      "cap": "18030",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comunecastelvittorio@libero.it",
      "pec": "comune.castelvittorio.im@legalmail.it",
      "telefono": "+39 0184/241048",
      "fax": "+39 0184/240963",
      "coordinate": {
        "lat": 43.9278564453125,
        "lng": 7.674263954162598
      }
    },
    {
      "codice": "008014",
      "nome": "Castellaro",
      "nomeStraniero": null,
      "codiceCatastale": "C143",
      "cap": "18011",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "anagrafe@comunedicastellaro.it",
      "pec": "tributi.castellaro@anutel.it",
      "telefono": "+39 0184/479050",
      "fax": "+39 0184/479161",
      "coordinate": {
        "lat": 43.86436080932617,
        "lng": 7.868810176849365
      }
    },
    {
      "codice": "008016",
      "nome": "Ceriana",
      "nomeStraniero": null,
      "codiceCatastale": "C511",
      "cap": "18034",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "anagrafe@comune.ceriana.im.it",
      "pec": "comuneceriana.im@legalmail.it",
      "telefono": "+39 0184/551017",
      "fax": "+39 0184/551635",
      "coordinate": {
        "lat": 43.87906265258789,
        "lng": 7.774600028991699
      }
    },
    {
      "codice": "008017",
      "nome": "Cervo",
      "nomeStraniero": null,
      "codiceCatastale": "C559",
      "cap": "18010",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comunecervo@cervo.com",
      "pec": "protocollo@pec.comunecervo.com",
      "telefono": "+39 0183/406462",
      "fax": "+39 0183/404796",
      "coordinate": {
        "lat": 43.925289154052734,
        "lng": 8.114341735839844
      }
    },
    {
      "codice": "008018",
      "nome": "Cesio",
      "nomeStraniero": null,
      "codiceCatastale": "C578",
      "cap": "18022",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "demografici@comune.cesio.im.it",
      "pec": "pec@pec.comune.cesio.im.it",
      "telefono": "+39 018355920",
      "fax": "+39 0183753277",
      "coordinate": {
        "lat": 44.00795364379883,
        "lng": 7.975469589233398
      }
    },
    {
      "codice": "008019",
      "nome": "Chiusanico",
      "nomeStraniero": null,
      "codiceCatastale": "C657",
      "cap": "18027",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comunechiusanico@libero.it",
      "pec": "pec@pec.comune.chiusanico.im.it",
      "telefono": "+39 0183/52415",
      "fax": "+39 0183/52543",
      "coordinate": {
        "lat": 43.97115707397461,
        "lng": 7.993275165557861
      }
    },
    {
      "codice": "008020",
      "nome": "Chiusavecchia",
      "nomeStraniero": null,
      "codiceCatastale": "C660",
      "cap": "18027",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "contatti@comune.chiusavecchia.im.it",
      "pec": "chiusavecchia@pec.it",
      "telefono": "+39 0183/52406",
      "fax": "+39 0183/529900",
      "coordinate": {
        "lat": 43.96780776977539,
        "lng": 7.983375072479248
      }
    },
    {
      "codice": "008021",
      "nome": "Cipressa",
      "nomeStraniero": null,
      "codiceCatastale": "C718",
      "cap": "18017",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "demografici@comune.cipressa.im.it",
      "pec": "comunecipressa@pec.it",
      "telefono": "+39 0183/98005",
      "fax": null,
      "coordinate": {
        "lat": 43.85143280029297,
        "lng": 7.93054723739624
      }
    },
    {
      "codice": "008022",
      "nome": "Civezza",
      "nomeStraniero": null,
      "codiceCatastale": "C755",
      "cap": "18017",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "info@comune.civezza.im.it",
      "pec": "comune.civezza.im@legalmail.it",
      "telefono": "+39 0183/930190",
      "fax": "+39 0183/931656",
      "coordinate": {
        "lat": 43.87930679321289,
        "lng": 7.951250076293945
      }
    },
    {
      "codice": "008023",
      "nome": "Cosio d'Arroscia",
      "nomeStraniero": null,
      "codiceCatastale": "D087",
      "cap": "18023",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "protocollo@cosioim.org",
      "pec": "protocollo@pec.cosioim.org",
      "telefono": "+39 0183/327803",
      "fax": "+39 0183/327847",
      "coordinate": {
        "lat": 44.07565689086914,
        "lng": 7.83115816116333
      }
    },
    {
      "codice": "008024",
      "nome": "Costarainera",
      "nomeStraniero": null,
      "codiceCatastale": "D114",
      "cap": "18017",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "demografici.costa@libero.it",
      "pec": "comune.costarainera.im@legalmail.it",
      "telefono": "+39 0183/98009",
      "fax": "+39 0183/98008",
      "coordinate": {
        "lat": 43.854576110839844,
        "lng": 7.940761089324951
      }
    },
    {
      "codice": "008025",
      "nome": "Diano Arentino",
      "nomeStraniero": null,
      "codiceCatastale": "D293",
      "cap": "18013",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comune.arentino@libero.it",
      "pec": "comunedianoarentino@legalmail.it",
      "telefono": "+39 0183/43048",
      "fax": "+39 0183/43392",
      "coordinate": {
        "lat": 43.948631286621094,
        "lng": 8.042252540588379
      }
    },
    {
      "codice": "008026",
      "nome": "Diano Castello",
      "nomeStraniero": null,
      "codiceCatastale": "D296",
      "cap": "18013",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "protocollo@comunedianocastello.it",
      "pec": "dianocastello@legalmail.it",
      "telefono": "+39 0183/40771",
      "fax": "+39 0183/4077299",
      "coordinate": {
        "lat": 43.924041748046875,
        "lng": 8.066949844360352
      }
    },
    {
      "codice": "008027",
      "nome": "Diano Marina",
      "nomeStraniero": null,
      "codiceCatastale": "D297",
      "cap": "18013",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "segreteria@comune.diano-marina.imperia.it",
      "pec": "protocollo@pec.comune.diano-marina.imperia.it",
      "telefono": "+39 0183 4901",
      "fax": "+39 0183/493048",
      "coordinate": {
        "lat": 43.90993118286133,
        "lng": 8.082024574279785
      }
    },
    {
      "codice": "008028",
      "nome": "Diano San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "D298",
      "cap": "18013",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "dianospietro@uno.it",
      "pec": "comunedianosanpietro.im.ragioneria@pa.postacertificata.gov.it",
      "telefono": "+39 0183/49212",
      "fax": "+39 0183/49202",
      "coordinate": {
        "lat": 43.93095016479492,
        "lng": 8.06869125366211
      }
    },
    {
      "codice": "008029",
      "nome": "Dolceacqua",
      "nomeStraniero": null,
      "codiceCatastale": "D318",
      "cap": "18035",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comune@dolceacqua.it",
      "pec": "comune.dolceacqua.im@legalmail.it",
      "telefono": "+39 0184/206444",
      "fax": "+39 0184/206433",
      "coordinate": {
        "lat": 43.84797668457031,
        "lng": 7.62381649017334
      }
    },
    {
      "codice": "008030",
      "nome": "Dolcedo",
      "nomeStraniero": null,
      "codiceCatastale": "D319",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comune-dolcedo@libero.it",
      "pec": "comune.dolcedo@legalmail.it",
      "telefono": "+39 0183/280004",
      "fax": "+39 0183/280697",
      "coordinate": {
        "lat": 43.90688705444336,
        "lng": 7.951608180999756
      }
    },
    {
      "codice": "008031",
      "nome": "Imperia",
      "nomeStraniero": null,
      "codiceCatastale": "E290",
      "cap": "18100",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comune@comune.imperia.it",
      "pec": "protocollo@pec.comune.imperia.it",
      "telefono": "+39 0183701270",
      "fax": "+39 0183701382",
      "coordinate": {
        "lat": 43.88646697998047,
        "lng": 8.02965259552002
      }
    },
    {
      "codice": "008032",
      "nome": "Isolabona",
      "nomeStraniero": null,
      "codiceCatastale": "E346",
      "cap": "18035",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comune@comuneisolabona.it",
      "pec": "postacert@pec.comuneisolabona.it",
      "telefono": "+39 0184/208127",
      "fax": "+39 0184/208530",
      "coordinate": {
        "lat": 43.87944412231445,
        "lng": 7.63889741897583
      }
    },
    {
      "codice": "008033",
      "nome": "Lucinasco",
      "nomeStraniero": null,
      "codiceCatastale": "E719",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "lucinasco@libero.it",
      "pec": "comune.lucinasco.im@legalmail.it",
      "telefono": "+39 018352425",
      "fax": "+39 018352426",
      "coordinate": {
        "lat": 43.967708587646484,
        "lng": 7.96281099319458
      }
    },
    {
      "codice": "008034",
      "nome": "Mendatica",
      "nomeStraniero": null,
      "codiceCatastale": "F123",
      "cap": "18025",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "mendatica@libero.it",
      "pec": "info@pec.comune.mendatica.im.it",
      "telefono": "+39 0183/328713",
      "fax": "+39 0183/328053",
      "coordinate": {
        "lat": 44.076351165771484,
        "lng": 7.805691719055176
      }
    },
    {
      "codice": "008035",
      "nome": "Molini di Triora",
      "nomeStraniero": null,
      "codiceCatastale": "F290",
      "cap": "18010",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comune.moliniditriora.im@legalmail.it",
      "pec": "comune.triora.im@legalmail.it",
      "telefono": "+39 0184/94014",
      "fax": "+39 0184/94611",
      "coordinate": {
        "lat": 43.99031448364258,
        "lng": 7.774641513824463
      }
    },
    {
      "codice": "008068",
      "nome": "Montalto Carpasio",
      "nomeStraniero": null,
      "codiceCatastale": "M387",
      "cap": "18028",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comune.montaltocarpasio.im.it",
      "pec": "comune.montaltocarpasio@legalmail.it",
      "telefono": "0184/407004",
      "fax": "0184/408043",
      "coordinate": {
        "lat": 43.92777633666992,
        "lng": 7.844444274902344
      }
    },
    {
      "codice": "008037",
      "nome": "Montegrosso Pian Latte",
      "nomeStraniero": null,
      "codiceCatastale": "F528",
      "cap": "18025",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "montegrosso@libero.it",
      "pec": "pec@pec.comune.montegrossopianlatte.im.it",
      "telefono": "+39 0183/328731",
      "fax": "+39 0183/328907",
      "coordinate": {
        "lat": 44.066646575927734,
        "lng": 7.817783355712891
      }
    },
    {
      "codice": "008038",
      "nome": "Olivetta San Michele",
      "nomeStraniero": null,
      "codiceCatastale": "G041",
      "cap": "18030",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comunediolivettasm@libero.it",
      "pec": "comunediolivettasm@pec.it",
      "telefono": "+39 0184/222333",
      "fax": "+39 0184/222333",
      "coordinate": {
        "lat": 43.87972640991211,
        "lng": 7.515558242797852
      }
    },
    {
      "codice": "008039",
      "nome": "Ospedaletti",
      "nomeStraniero": null,
      "codiceCatastale": "G164",
      "cap": "18014",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comune@comune.ospedaletti.im.it",
      "pec": "comune@pec.comune.ospedaletti.im.it",
      "telefono": "+39 0184/68221",
      "fax": "+39 0184/688940",
      "coordinate": {
        "lat": 43.80059051513672,
        "lng": 7.717694282531738
      }
    },
    {
      "codice": "008040",
      "nome": "Perinaldo",
      "nomeStraniero": null,
      "codiceCatastale": "G454",
      "cap": "18032",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "anagrafe@perinaldo.org",
      "pec": "comune.perinaldo@pec.perinaldo.org",
      "telefono": "0184672001",
      "fax": "+39 0184/672021",
      "coordinate": {
        "lat": 43.8669319152832,
        "lng": 7.674349784851074
      }
    },
    {
      "codice": "008041",
      "nome": "Pietrabruna",
      "nomeStraniero": null,
      "codiceCatastale": "G607",
      "cap": "18010",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comune@comunepietrabruna.it",
      "pec": "comunepietrabruna@legalmail.it",
      "telefono": "+39 0183/90200",
      "fax": "+39 0183/90083",
      "coordinate": {
        "lat": 43.88981246948242,
        "lng": 7.90328311920166
      }
    },
    {
      "codice": "008042",
      "nome": "Pieve di Teco",
      "nomeStraniero": null,
      "codiceCatastale": "G632",
      "cap": "18026",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "protocollo@comune.pievediteco.im.it",
      "pec": "comune.pievediteco@postecert.it",
      "telefono": "+39 0183/36313",
      "fax": "+39 0183/36315",
      "coordinate": {
        "lat": 44.04800033569336,
        "lng": 7.915999889373779
      }
    },
    {
      "codice": "008043",
      "nome": "Pigna",
      "nomeStraniero": null,
      "codiceCatastale": "G660",
      "cap": "18037",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "demograficipigna@libero.it",
      "pec": "comune.pigna.im@legalmail.it",
      "telefono": "+39 0184/241016",
      "fax": "+39 0184/241059",
      "coordinate": {
        "lat": 43.93253707885742,
        "lng": 7.663750171661377
      }
    },
    {
      "codice": "008044",
      "nome": "Pompeiana",
      "nomeStraniero": null,
      "codiceCatastale": "G814",
      "cap": "18015",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comunedipompeiana.net",
      "pec": "comune.pompeiana.im@legalmail.it",
      "telefono": "+39 0184/486777",
      "fax": "+39 0184/485376",
      "coordinate": {
        "lat": 43.85332489013672,
        "lng": 7.888636112213135
      }
    },
    {
      "codice": "008045",
      "nome": "Pontedassio",
      "nomeStraniero": null,
      "codiceCatastale": "G840",
      "cap": "18027",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "pontedas@uno.it",
      "pec": "protocollo@pec.pontedassio.net",
      "telefono": "+39 0183 279026",
      "fax": "+39 0183 279721",
      "coordinate": {
        "lat": 43.94028091430664,
        "lng": 8.012299537658691
      }
    },
    {
      "codice": "008046",
      "nome": "Pornassio",
      "nomeStraniero": null,
      "codiceCatastale": "G890",
      "cap": "18024",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "amministrativo@pornassio.org",
      "pec": "comune@pec.pornassio.org",
      "telefono": "+39 0183/33003",
      "fax": "+39 0183/327456",
      "coordinate": {
        "lat": 44.06989288330078,
        "lng": 7.869408130645752
      }
    },
    {
      "codice": "008047",
      "nome": "Prelà",
      "nomeStraniero": null,
      "codiceCatastale": "H027",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comune.prela@libero.it",
      "pec": "comune.prela@pec.it",
      "telefono": "+39 0183282000",
      "fax": "+39 0183282487",
      "coordinate": {
        "lat": 43.9238166809082,
        "lng": 7.909296989440918
      }
    },
    {
      "codice": "008048",
      "nome": "Ranzo",
      "nomeStraniero": null,
      "codiceCatastale": "H180",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "protocollo@comune.ranzo.im.it",
      "pec": "comuneranzo@legalmail.it",
      "telefono": "+39 0183 318085",
      "fax": "+39 0183 318085",
      "coordinate": {
        "lat": 44.059417724609375,
        "lng": 8.01461124420166
      }
    },
    {
      "codice": "008049",
      "nome": "Rezzo",
      "nomeStraniero": null,
      "codiceCatastale": "H257",
      "cap": "18026",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comunerezzo@legalmail.it",
      "pec": "comunerezzo@legalmail.it",
      "telefono": "+39 0183/34015",
      "fax": "+39 0183/34152",
      "coordinate": {
        "lat": 44.02142333984375,
        "lng": 7.871445178985596
      }
    },
    {
      "codice": "008050",
      "nome": "Riva Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "H328",
      "cap": "18015",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "demografici@comunedirivaligure.it",
      "pec": "demografici.rivaligure@legalmail.it",
      "telefono": "+39 0184/482033",
      "fax": "+39 0184/487700",
      "coordinate": {
        "lat": 43.83747863769531,
        "lng": 7.881880760192871
      }
    },
    {
      "codice": "008051",
      "nome": "Rocchetta Nervina",
      "nomeStraniero": null,
      "codiceCatastale": "H460",
      "cap": "18030",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comunedirocchetta@libero.it",
      "pec": "comunedirocchettanervina@legalmail.it",
      "telefono": "+39 0184/207942",
      "fax": "+39 0184/207942",
      "coordinate": {
        "lat": 43.88999557495117,
        "lng": 7.600202560424805
      }
    },
    {
      "codice": "008052",
      "nome": "San Bartolomeo al Mare",
      "nomeStraniero": null,
      "codiceCatastale": "H763",
      "cap": "18016",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "info@sanbart.it",
      "pec": "comunesanbart@legalmail.it",
      "telefono": "+39 0183/4092223",
      "fax": "+39 0183/406479",
      "coordinate": {
        "lat": 43.92782211303711,
        "lng": 8.102238655090332
      }
    },
    {
      "codice": "008053",
      "nome": "San Biagio della Cima",
      "nomeStraniero": null,
      "codiceCatastale": "H780",
      "cap": "18036",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@sanbiagiodellacima.gov.it",
      "pec": "comune.sanbiagiodellacima.im@legalmail.it",
      "telefono": "+39 0184/289044",
      "fax": "+39 0184/289044",
      "coordinate": {
        "lat": 43.81859588623047,
        "lng": 7.64965295791626
      }
    },
    {
      "codice": "008054",
      "nome": "San Lorenzo al Mare",
      "nomeStraniero": null,
      "codiceCatastale": "H957",
      "cap": "18017",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "demografici@comune.sanlorenzoalmare.im.it",
      "pec": "sanlorenzoalamare.im@legalmail.it",
      "telefono": "+39 0183/930109",
      "fax": "+39 0183/931002",
      "coordinate": {
        "lat": 43.85337448120117,
        "lng": 7.963805675506592
      }
    },
    {
      "codice": "008055",
      "nome": "Sanremo",
      "nomeStraniero": null,
      "codiceCatastale": "I138",
      "cap": "18038",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "servizio.elettorale@comunedisanremo.it",
      "pec": "comune.sanremo@legalmail.it",
      "telefono": "+39 0184/580249",
      "fax": "+39 0184/500340",
      "coordinate": {
        "lat": 43.817501068115234,
        "lng": 7.775000095367432
      }
    },
    {
      "codice": "008056",
      "nome": "Santo Stefano al Mare",
      "nomeStraniero": null,
      "codiceCatastale": "I365",
      "cap": "18010",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comune.santostefanoalmare.im.it",
      "pec": "santostefanoalmare.im@cert.legalmail.it",
      "telefono": "+39 0184/486488",
      "fax": "+39 0184/486820",
      "coordinate": {
        "lat": 43.83723449707031,
        "lng": 7.899311065673828
      }
    },
    {
      "codice": "008057",
      "nome": "Seborga",
      "nomeStraniero": null,
      "codiceCatastale": "I556",
      "cap": "18012",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comuneseborga.it",
      "pec": "protocollo@pec.comune.seborga.it",
      "telefono": "+39 0184/223622",
      "fax": "+39 0184/223899",
      "coordinate": {
        "lat": 43.826045989990234,
        "lng": 7.693839073181152
      }
    },
    {
      "codice": "008058",
      "nome": "Soldano",
      "nomeStraniero": null,
      "codiceCatastale": "I796",
      "cap": "18036",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "protocollo@comunesoldano.it",
      "pec": "comune@pec.comunesoldano.it",
      "telefono": "+39 0184/289037",
      "fax": "+39 0184/284884",
      "coordinate": {
        "lat": 43.82839584350586,
        "lng": 7.655425071716309
      }
    },
    {
      "codice": "008059",
      "nome": "Taggia",
      "nomeStraniero": null,
      "codiceCatastale": "L024",
      "cap": "18018",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comune.taggia.im.it",
      "pec": "comune.taggia.im@certificamail.it",
      "telefono": "+39 0184/476222",
      "fax": "+39 0184/477200",
      "coordinate": {
        "lat": 43.84385681152344,
        "lng": 7.850927829742432
      }
    },
    {
      "codice": "008060",
      "nome": "Terzorio",
      "nomeStraniero": null,
      "codiceCatastale": "L146",
      "cap": "18010",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "info@comune.terzorio.im.it",
      "pec": "terzorio.comunediterzorio.im@legalmail.it",
      "telefono": "+39 0184/484220",
      "fax": "+39 0184/484005",
      "coordinate": {
        "lat": 43.852901458740234,
        "lng": 7.898102760314941
      }
    },
    {
      "codice": "008061",
      "nome": "Triora",
      "nomeStraniero": null,
      "codiceCatastale": "L430",
      "cap": "18010",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comunetriora@libero.it",
      "pec": "comune.triora.im@legalmail.it",
      "telefono": "+39 0184/94049",
      "fax": "+39 0184/94164",
      "coordinate": {
        "lat": 44,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "008062",
      "nome": "Vallebona",
      "nomeStraniero": null,
      "codiceCatastale": "L596",
      "cap": "18012",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comune@vallebona.info",
      "pec": "comune.vallebona.im@legalmail.it",
      "telefono": "+39 0184/290572",
      "fax": "+39 0184/259915",
      "coordinate": {
        "lat": 43.81401062011719,
        "lng": 7.666594505310059
      }
    },
    {
      "codice": "008063",
      "nome": "Vallecrosia",
      "nomeStraniero": null,
      "codiceCatastale": "L599",
      "cap": "18019",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "anagrafe@vallecrosia.it",
      "pec": "comune.vallecrosia@legalmail.it",
      "telefono": "+39 0184/253788",
      "fax": "+39 0184/254430",
      "coordinate": {
        "lat": 43.7930908203125,
        "lng": 7.643872261047363
      }
    },
    {
      "codice": "008064",
      "nome": "Vasia",
      "nomeStraniero": null,
      "codiceCatastale": "L693",
      "cap": "18020",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "comunedivasia@libero.it",
      "pec": "comune.vasia.im@legalmail.it",
      "telefono": "+39 0183/282053",
      "fax": "+39 0183/782579",
      "coordinate": {
        "lat": 43.933231353759766,
        "lng": 7.954341888427734
      }
    },
    {
      "codice": "008065",
      "nome": "Ventimiglia",
      "nomeStraniero": null,
      "codiceCatastale": "L741",
      "cap": "18039",
      "prefisso": "0184",
      "provincia": "Imperia",
      "email": "comune.ventimiglia@legalmail.it",
      "pec": "comune.ventimiglia@legalmail.it",
      "telefono": "+39 0184 2801",
      "fax": "+39 0184/352581",
      "coordinate": {
        "lat": 43.790279388427734,
        "lng": 7.608333110809326
      }
    },
    {
      "codice": "008066",
      "nome": "Vessalico",
      "nomeStraniero": null,
      "codiceCatastale": "L809",
      "cap": "18026",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "protocollo@comune.vessalico.im.it",
      "pec": "pec.comunedivessalico@legalmail.it",
      "telefono": "+39 0183 31000",
      "fax": "+39 0183/321714",
      "coordinate": {
        "lat": 44.04631805419922,
        "lng": 7.962063789367676
      }
    },
    {
      "codice": "008067",
      "nome": "Villa Faraldi",
      "nomeStraniero": null,
      "codiceCatastale": "L943",
      "cap": "18010",
      "prefisso": "0183",
      "provincia": "Imperia",
      "email": "villafaraldi@libero.it",
      "pec": "comunevillafaraldi@legalmail.it",
      "telefono": "+39 0183/41025",
      "fax": "+39 0183/41002",
      "coordinate": {
        "lat": 43.96710968017578,
        "lng": 8.091507911682129
      }
    }
  ],
  "Trento": [
    {
      "codice": "022001",
      "nome": "Ala",
      "nomeStraniero": null,
      "codiceCatastale": "A116",
      "cap": "38061",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "c.ala@comuni.infotn.it",
      "pec": "comuneala.tn@legalmail.it",
      "telefono": "+39 0464/678716",
      "fax": "+39 0464/672495",
      "coordinate": {
        "lat": 45.75,
        "lng": 11
      }
    },
    {
      "codice": "022002",
      "nome": "Albiano",
      "nomeStraniero": null,
      "codiceCatastale": "A158",
      "cap": "38041",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "affari.generali@comune.albiano.tn.it",
      "pec": "certificata@pec.comune.albiano.tn.it",
      "telefono": "+39 0461/689623",
      "fax": "+39 0461/689048",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "022003",
      "nome": "Aldeno",
      "nomeStraniero": null,
      "codiceCatastale": "A178",
      "cap": "38060",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.aldeno.tn.it",
      "pec": "aldeno@postemailcertificata.it",
      "telefono": "+39 0461/842711",
      "fax": "+39 0461/842140",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "022235",
      "nome": "Altavalle",
      "nomeStraniero": null,
      "codiceCatastale": "M349",
      "cap": "38092",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "protocollo@comune.altavalle.tn.it",
      "pec": "comune@pec.comune.altavalle.tn.it",
      "telefono": "+39 0461 683029",
      "fax": "+39 0461 680605",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "022236",
      "nome": "Altopiano della Vigolana",
      "nomeStraniero": null,
      "codiceCatastale": "M350",
      "cap": "38049",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.vigolana.tn.it",
      "pec": "comune@pec.comune.vigolana.tn.it",
      "telefono": "+39 0461 848812",
      "fax": "+39 0461 845002",
      "coordinate": {
        "lat": 46,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "022237",
      "nome": "Amblar-Don",
      "nomeStraniero": null,
      "codiceCatastale": "M351",
      "cap": "38011",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.amblardon.tn.it",
      "pec": "comune@pec.comune.amblardon.tn.it",
      "telefono": "+39 0463 875109",
      "fax": "0463876647",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "022005",
      "nome": "Andalo",
      "nomeStraniero": null,
      "codiceCatastale": "A274",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.andalo.tn.it",
      "pec": "segreteria@pec.comune.andalo.tn.it",
      "telefono": "+39 0461/585824",
      "fax": "+39 0461/585310",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 11.004444122314453
      }
    },
    {
      "codice": "022006",
      "nome": "Arco",
      "nomeStraniero": null,
      "codiceCatastale": "A372",
      "cap": "38062",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.arco.tn.it",
      "pec": "comune@pec.comune.arco.tn.it",
      "telefono": "+39 0464583511",
      "fax": "+39 0464518631",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 10.883333206176758
      }
    },
    {
      "codice": "022007",
      "nome": "Avio",
      "nomeStraniero": null,
      "codiceCatastale": "A520",
      "cap": "38063",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "webmaster@comune.avio.tn.it",
      "pec": "certificata@pec.comune.avio.tn.it",
      "telefono": "+39 0464/688888",
      "fax": "+39 0464/683152",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "022009",
      "nome": "Baselga di Pinè",
      "nomeStraniero": null,
      "codiceCatastale": "A694",
      "cap": "38042",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "comune@comune.baselgadipine.tn.it",
      "pec": "comunebaselgadipine@pec.it",
      "telefono": "+39 0461557024",
      "fax": "+39 0461558660",
      "coordinate": {
        "lat": 46.129764556884766,
        "lng": 11.244633674621582
      }
    },
    {
      "codice": "022011",
      "nome": "Bedollo",
      "nomeStraniero": null,
      "codiceCatastale": "A730",
      "cap": "38043",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comunebedollo.it",
      "pec": "comune@pec.comune.bedollo.tn.it",
      "telefono": "+39 0461/556624",
      "fax": "+39 0461/556050",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 11.300000190734863
      }
    },
    {
      "codice": "022013",
      "nome": "Besenello",
      "nomeStraniero": null,
      "codiceCatastale": "A821",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "demografici@comune.besenello.tn.it",
      "pec": "anagrafe.comunedibesenello@pec.it",
      "telefono": "+39 0464/820000",
      "fax": "+39 0464/820099",
      "coordinate": {
        "lat": 45.94194412231445,
        "lng": 11.108611106872559
      }
    },
    {
      "codice": "022015",
      "nome": "Bieno",
      "nomeStraniero": null,
      "codiceCatastale": "A863",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "bieno@comuni.infotn.it",
      "pec": "comune@pec.comune.bieno.tn.it",
      "telefono": "+39 0461/596166",
      "fax": "+39 0461/596292",
      "coordinate": {
        "lat": 46.08041000366211,
        "lng": 11.553959846496582
      }
    },
    {
      "codice": "022017",
      "nome": "Bleggio Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "A902",
      "cap": "38071",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "info@comune.bleggiosuperiore.tn.it",
      "pec": "comune@pec.comune.bleggiosuperiore.tn.it",
      "telefono": "+39 0465/779550",
      "fax": "+39 0465/779670",
      "coordinate": {
        "lat": 46.0225715637207,
        "lng": 10.82367992401123
      }
    },
    {
      "codice": "022018",
      "nome": "Bocenago",
      "nomeStraniero": null,
      "codiceCatastale": "A916",
      "cap": "38080",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "protocollo@comune.bocenago.tn.it",
      "pec": "comune@pec.comune.bocenago.tn.it",
      "telefono": "+39 0465/804505",
      "fax": "+39 0465/804854",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 10.75
      }
    },
    {
      "codice": "022021",
      "nome": "Bondone",
      "nomeStraniero": null,
      "codiceCatastale": "A968",
      "cap": "38080",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "comune@comune.bondone.tn.it",
      "pec": "comune@pec.comune.bondone.tn.it",
      "telefono": "+39 0465/689133",
      "fax": "+39 0465/689310",
      "coordinate": {
        "lat": 45.80649185180664,
        "lng": 10.550270080566406
      }
    },
    {
      "codice": "022238",
      "nome": "Borgo Chiese",
      "nomeStraniero": null,
      "codiceCatastale": "M352",
      "cap": "38083",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "info@comune.borgochiese.tn.it",
      "pec": "comune@pec.comune.borgochiese.tn.it",
      "telefono": "+39 0465 621001",
      "fax": "+39 0465 621799",
      "coordinate": {
        "lat": 45.89059829711914,
        "lng": 10.601300239562988
      }
    },
    {
      "codice": "022252",
      "nome": "Borgo d'Anaunia",
      "nomeStraniero": null,
      "codiceCatastale": "M429",
      "cap": "38013",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": null,
      "pec": "comune@pec.comune.borgodanaunia.tn.it",
      "telefono": "0463 831102",
      "fax": null,
      "coordinate": {
        "lat": 46.4388542175293,
        "lng": 11.137331008911133
      }
    },
    {
      "codice": "022239",
      "nome": "Borgo Lares",
      "nomeStraniero": null,
      "codiceCatastale": "M353",
      "cap": "38079",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "info@comune.borgolares.tn.it",
      "pec": "comune@pec.comune.borgolares.tn.it",
      "telefono": "+39 0465 324328",
      "fax": "+39 0465 324328",
      "coordinate": {
        "lat": 46.0352783203125,
        "lng": 10.752222061157227
      }
    },
    {
      "codice": "022022",
      "nome": "Borgo Valsugana",
      "nomeStraniero": null,
      "codiceCatastale": "B006",
      "cap": "38051",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "comune@comune.borgo-valsugana.tn.it",
      "pec": "comuneborgovalsugana.tn@cert.legalmail.it",
      "telefono": "+39 0461/758725",
      "fax": "+39 0461/753033",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "022025",
      "nome": "Brentonico",
      "nomeStraniero": null,
      "codiceCatastale": "B153",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "segreteria@comune.brentonico.tn.it",
      "pec": "comunebrentonico@legalmail.it",
      "telefono": "+39 0464/399111",
      "fax": "+39 0464/399120",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "022026",
      "nome": "Bresimo",
      "nomeStraniero": null,
      "codiceCatastale": "B158",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "c.bresimo@comuni.infotn.it",
      "pec": "comune@pec.comune.bresimo.tn.it",
      "telefono": "+39 0463/539060",
      "fax": "+39 0463/539061",
      "coordinate": {
        "lat": 46.41181564331055,
        "lng": 10.970898628234863
      }
    },
    {
      "codice": "022029",
      "nome": "Caderzone Terme",
      "nomeStraniero": null,
      "codiceCatastale": "B335",
      "cap": "38080",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "comune@caderzone.net",
      "pec": "comune@pec.caderzone.net",
      "telefono": "+39 0465804214",
      "fax": "+39 0465804848",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 10.75
      }
    },
    {
      "codice": "022032",
      "nome": "Calceranica al Lago",
      "nomeStraniero": null,
      "codiceCatastale": "B389",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.calceranica.tn.it",
      "pec": "demografici@pec.comune.calceranica.tn.it",
      "telefono": "+39 0461/723161",
      "fax": "+39 0461/724570",
      "coordinate": {
        "lat": 46.00361251831055,
        "lng": 11.242500305175781
      }
    },
    {
      "codice": "022033",
      "nome": "Caldes",
      "nomeStraniero": null,
      "codiceCatastale": "B400",
      "cap": "38022",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "segreteria@comune.caldes.tn.it",
      "pec": "comune@pec.comune.caldes.tn.it",
      "telefono": "+39 0463/901334",
      "fax": "+39 0463/901993",
      "coordinate": {
        "lat": 46.36666488647461,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "022034",
      "nome": "Caldonazzo",
      "nomeStraniero": null,
      "codiceCatastale": "B404",
      "cap": "38052",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "ufficio.segreteria@comune.caldonazzo.tn.it",
      "pec": "comune.caldonazzo@legalmail.it",
      "telefono": "+39 0461/723123",
      "fax": "+39 0461/724544",
      "coordinate": {
        "lat": 46,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "022035",
      "nome": "Calliano",
      "nomeStraniero": null,
      "codiceCatastale": "B419",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "anagrafe.calliano@comune.calliano.tn.it",
      "pec": "comunecallianotn@legalmail.it",
      "telefono": "+39 0464/830558",
      "fax": "+39 0464/834075",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "022036",
      "nome": "Campitello di Fassa",
      "nomeStraniero": null,
      "codiceCatastale": "B514",
      "cap": "38031",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "info@comune.campitellodifassa.tn.it",
      "pec": "comune@pec.comune.campitellodifassa.tn.it",
      "telefono": "+39 0462/750330",
      "fax": "+39 0462/750437",
      "coordinate": {
        "lat": 46.47616958618164,
        "lng": 11.741524696350098
      }
    },
    {
      "codice": "022037",
      "nome": "Campodenno",
      "nomeStraniero": null,
      "codiceCatastale": "B525",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria.campodenno@comuni.infotn.it",
      "pec": "comune@pec.comune.campodenno.tn.it",
      "telefono": "+39 0461/655547",
      "fax": "+39 0461/655178",
      "coordinate": {
        "lat": 46.25,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022038",
      "nome": "Canal San Bovo",
      "nomeStraniero": null,
      "codiceCatastale": "B577",
      "cap": "38050",
      "prefisso": "0439",
      "provincia": "Trento",
      "email": "canalsanbovo@comune.canalsanbovo.tn.it",
      "pec": "comune@pec.comune.canalsanbovo.tn.it",
      "telefono": "+39 0439/719900",
      "fax": "+39 0439/719999",
      "coordinate": {
        "lat": 46.15638732910156,
        "lng": 11.733611106872559
      }
    },
    {
      "codice": "022039",
      "nome": "Canazei",
      "nomeStraniero": null,
      "codiceCatastale": "B579",
      "cap": "38032",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "ufficio.tecnico@comune.canazei.tn.it",
      "pec": "protocollo.comunecanazei@pec.it",
      "telefono": "+39 0462/605637",
      "fax": "+39 0462/605640",
      "coordinate": {
        "lat": 46.47687911987305,
        "lng": 11.771075248718262
      }
    },
    {
      "codice": "022040",
      "nome": "Capriana",
      "nomeStraniero": null,
      "codiceCatastale": "B697",
      "cap": "38030",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "c.capriana@comuni.infotn.it",
      "pec": "segreteria@pec.comunecapriana.com",
      "telefono": "+39 0462/816013",
      "fax": "+39 0462/816017",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 11.333333015441895
      }
    },
    {
      "codice": "022042",
      "nome": "Carisolo",
      "nomeStraniero": null,
      "codiceCatastale": "B783",
      "cap": "38080",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "segretario@comune.carisolo.tn.it",
      "pec": "comune@pec.comune.carisolo.tn.it",
      "telefono": "+39 0465/501176",
      "fax": "+39 0465/501335",
      "coordinate": {
        "lat": 46.16910171508789,
        "lng": 10.763769149780273
      }
    },
    {
      "codice": "022043",
      "nome": "Carzano",
      "nomeStraniero": null,
      "codiceCatastale": "B856",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "comune@comune.carzano.tn.it",
      "pec": "comune@pec.comune.carzano.tn.it",
      "telefono": "+39 0461/766021",
      "fax": "+39 0461/777208",
      "coordinate": {
        "lat": 46.0709114074707,
        "lng": 11.493789672851562
      }
    },
    {
      "codice": "022045",
      "nome": "Castel Condino",
      "nomeStraniero": null,
      "codiceCatastale": "C183",
      "cap": "38082",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "c.castelcondino@comuni.infotn.it",
      "pec": "comune@castelcondino.legalmail.it",
      "telefono": "+39 0465/621083",
      "fax": "+39 0465/620669",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "022240",
      "nome": "Castel Ivano",
      "nomeStraniero": null,
      "codiceCatastale": "M354",
      "cap": "38059",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "uff.anagrafe@comune.castel-ivano.tn.it",
      "pec": "info@pec.comune.castel-ivano.tn.it",
      "telefono": "+39 0461 780010",
      "fax": "+39 0461 780010",
      "coordinate": {
        "lat": 46.06636047363281,
        "lng": 11.52357006072998
      }
    },
    {
      "codice": "022048",
      "nome": "Castello Tesino",
      "nomeStraniero": null,
      "codiceCatastale": "C194",
      "cap": "38053",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.castello-tesino.tn.it",
      "pec": "comune@pec.comune.castello-tesino.tn.it",
      "telefono": "+39 0461/594152",
      "fax": "+39 0461/593139",
      "coordinate": {
        "lat": 46.06343078613281,
        "lng": 11.633310317993164
      }
    },
    {
      "codice": "022047",
      "nome": "Castello-Molina di Fiemme",
      "nomeStraniero": null,
      "codiceCatastale": "C189",
      "cap": "38030",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "segreteria@comune.castellomolina.tn.it",
      "pec": "comune@pec.comune.castellomolina.tn.it",
      "telefono": "+39 0462/340013",
      "fax": "+39 0462/231187",
      "coordinate": {
        "lat": 46.27207946777344,
        "lng": 11.417200088500977
      }
    },
    {
      "codice": "022049",
      "nome": "Castelnuovo",
      "nomeStraniero": null,
      "codiceCatastale": "C216",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "comune.castelnuovo@comune.castelnuovo.tn.it",
      "pec": "comune@pec.comune.castelnuovo.tn.it",
      "telefono": "+39 0461/753442",
      "fax": "+39 0461/751110",
      "coordinate": {
        "lat": 46.05192184448242,
        "lng": 11.494210243225098
      }
    },
    {
      "codice": "022050",
      "nome": "Cavalese",
      "nomeStraniero": null,
      "codiceCatastale": "C372",
      "cap": "38033",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "info@comunecavalese.it",
      "pec": "comune.cavalese@certificata.com",
      "telefono": "+39 0462/237511",
      "fax": "+39 0462/237550",
      "coordinate": {
        "lat": 46.29111099243164,
        "lng": 11.460556030273438
      }
    },
    {
      "codice": "022051",
      "nome": "Cavareno",
      "nomeStraniero": null,
      "codiceCatastale": "C380",
      "cap": "38011",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "info@comune.cavareno.tn.it",
      "pec": "comune@pec.comune.cavareno.tn.it",
      "telefono": "+39 0463/850106",
      "fax": "+39 0463/850096",
      "coordinate": {
        "lat": 46.40841293334961,
        "lng": 11.138072967529297
      }
    },
    {
      "codice": "022052",
      "nome": "Cavedago",
      "nomeStraniero": null,
      "codiceCatastale": "C392",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "c.cavedago@comuni.infotn.it",
      "pec": "comune@pec.comune.cavedago.tn.it",
      "telefono": "+39 0461/654213",
      "fax": "+39 0461/654373",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022053",
      "nome": "Cavedine",
      "nomeStraniero": null,
      "codiceCatastale": "C393",
      "cap": "38073",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.cavedine.tn.it",
      "pec": "comune@pec.comune.cavedine.tn.it",
      "telefono": "+39 0461/568518",
      "fax": "+39 0461/569030",
      "coordinate": {
        "lat": 46,
        "lng": 10.966666221618652
      }
    },
    {
      "codice": "022054",
      "nome": "Cavizzana",
      "nomeStraniero": null,
      "codiceCatastale": "C400",
      "cap": "38022",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.cavizzana.tn.it",
      "pec": "comune@pec.comune.cavizzana.tn.it",
      "telefono": "+39 0463/901100",
      "fax": "+39 0463/900745",
      "coordinate": {
        "lat": 46.36750030517578,
        "lng": 10.95792293548584
      }
    },
    {
      "codice": "022241",
      "nome": "Cembra Lisignago",
      "nomeStraniero": null,
      "codiceCatastale": "M355",
      "cap": "38034",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "protocollo@comune.cembralisignago.tn.it",
      "pec": "comune@pec.comune.cembralisignago.tn.it",
      "telefono": "+39 0461 683018",
      "fax": "+39 0461 682150",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 11.216666221618652
      }
    },
    {
      "codice": "022058",
      "nome": "Cimone",
      "nomeStraniero": null,
      "codiceCatastale": "C700",
      "cap": "38060",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.cimone.tn.it",
      "pec": "comunecimone@pec.it",
      "telefono": "+39 0461/855210",
      "fax": "+39 0461/855202",
      "coordinate": {
        "lat": 45.97901916503906,
        "lng": 11.069899559020996
      }
    },
    {
      "codice": "022059",
      "nome": "Cinte Tesino",
      "nomeStraniero": null,
      "codiceCatastale": "C712",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "cintetesino@comuni.infotn.it",
      "pec": "comune@pec.comune.cintetesino.tn.it",
      "telefono": "+39 0461/594143",
      "fax": "+39 0461/594173",
      "coordinate": {
        "lat": 46.05611038208008,
        "lng": 11.615469932556152
      }
    },
    {
      "codice": "022060",
      "nome": "Cis",
      "nomeStraniero": null,
      "codiceCatastale": "C727",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.cis.tn.it",
      "pec": "comune@pec.comune.cis.tn.it",
      "telefono": "+39 0463/533132",
      "fax": "+39 0463/533083",
      "coordinate": {
        "lat": 46.39969253540039,
        "lng": 11.0015869140625
      }
    },
    {
      "codice": "022061",
      "nome": "Civezzano",
      "nomeStraniero": null,
      "codiceCatastale": "C756",
      "cap": "38045",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "mariano.carlini@comunecivezzano.it",
      "pec": "comune@pec.comune.civezzano.tn.it",
      "telefono": "+39 0461/858516",
      "fax": "+39 0461/858952",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 11.166666984558105
      }
    },
    {
      "codice": "022062",
      "nome": "Cles",
      "nomeStraniero": null,
      "codiceCatastale": "C794",
      "cap": "38023",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "info@comune.cles.tn.it",
      "pec": "info@pec.comune.cles.tn.it",
      "telefono": "+39 0463/662000",
      "fax": "+39 0463662009",
      "coordinate": {
        "lat": 46.36666488647461,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022228",
      "nome": "Comano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "M314",
      "cap": "38077",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "protocollo@comune.comanoterme.tn.it",
      "pec": "segreteria@pec.comune.comanoterme.tn.it",
      "telefono": "+39 0465701434",
      "fax": "+39 0465701725",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 10.866666793823242
      }
    },
    {
      "codice": "022064",
      "nome": "Commezzadura",
      "nomeStraniero": null,
      "codiceCatastale": "C931",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "protocollo@comune.commezzadura.tn.it",
      "pec": "comune@pec.comune.commezzadura.tn.it",
      "telefono": "+39 0463/974163",
      "fax": "+39 0463/974494",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 10.833333015441895
      }
    },
    {
      "codice": "022242",
      "nome": "Contà",
      "nomeStraniero": null,
      "codiceCatastale": "M356",
      "cap": "38093",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.conta.tn.it",
      "pec": "comune@pec.comune.conta.tn.it",
      "telefono": "+39 0461 652180",
      "fax": "+39 0461652050",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022068",
      "nome": "Croviana",
      "nomeStraniero": null,
      "codiceCatastale": "D188",
      "cap": "38027",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.croviana.tn.it",
      "pec": "comune@pec.comune.croviana.tn.it",
      "telefono": "+39 0463/901165",
      "fax": "+39 0463/969607",
      "coordinate": {
        "lat": 46.3447151184082,
        "lng": 10.904435157775879
      }
    },
    {
      "codice": "022071",
      "nome": "Dambel",
      "nomeStraniero": null,
      "codiceCatastale": "D246",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.dambel.tn.it",
      "pec": "comune@pec.comune.dambel.tn.it",
      "telefono": "+39 0463/437105",
      "fax": "+39 0463/437266",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "022074",
      "nome": "Denno",
      "nomeStraniero": null,
      "codiceCatastale": "D273",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "c.denno@comuni.infotn.it",
      "pec": "comune@pec.comune.denno.tn.it",
      "telefono": "+39 0461/655523",
      "fax": "+39 0461/655549",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 11.050000190734863
      }
    },
    {
      "codice": "022233",
      "nome": "Dimaro Folgarida",
      "nomeStraniero": null,
      "codiceCatastale": "M366",
      "cap": "38025",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.dimarofolgarida.tn.it",
      "pec": "comune@pec.comune.dimarofolgarida.tn.it",
      "telefono": "+39 0463974101",
      "fax": "+39 0463973217",
      "coordinate": {
        "lat": 46.3259391784668,
        "lng": 10.87437915802002
      }
    },
    {
      "codice": "022078",
      "nome": "Drena",
      "nomeStraniero": null,
      "codiceCatastale": "D365",
      "cap": "38074",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.drena.tn.it",
      "pec": "comune.drena@emailpec.eu",
      "telefono": "+39 0464/541170",
      "fax": "+39 0464/541277",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "022079",
      "nome": "Dro",
      "nomeStraniero": null,
      "codiceCatastale": "D371",
      "cap": "38074",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comunedro.it",
      "pec": "segreteria@pec.comunedro.it",
      "telefono": "+39 0464545511",
      "fax": "+39 0464545520",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 10.916666984558105
      }
    },
    {
      "codice": "022081",
      "nome": "Fai della Paganella",
      "nomeStraniero": null,
      "codiceCatastale": "D468",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "protocollo@comune.faidellapaganella.tn.it",
      "pec": "comune@pec.comune.faidellapaganella.tn.it",
      "telefono": "+39 0461/583122",
      "fax": "+39 0461/583407",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "022083",
      "nome": "Fiavè",
      "nomeStraniero": null,
      "codiceCatastale": "D565",
      "cap": "38075",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "segreteria@comune.fiave.tn.it",
      "pec": "comune@pec.comune.fiave.tn.it",
      "telefono": "+39 0465/735029",
      "fax": "+39 0465/735024",
      "coordinate": {
        "lat": 46.00444412231445,
        "lng": 10.842222213745117
      }
    },
    {
      "codice": "022085",
      "nome": "Fierozzo",
      "nomeStraniero": null,
      "codiceCatastale": "D573",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.fierozzo.tn.it",
      "pec": "comune@pec.comune.fierozzo.tn.it",
      "telefono": "+39 0461/551104",
      "fax": "+39 0461/551155",
      "coordinate": {
        "lat": 46.111083984375,
        "lng": 11.318277359008789
      }
    },
    {
      "codice": "022087",
      "nome": "Folgaria",
      "nomeStraniero": null,
      "codiceCatastale": "D651",
      "cap": "38064",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.folgaria.tn.it",
      "pec": "comune@pec.comune.folgaria.tn.it",
      "telefono": "+39 0464/729333",
      "fax": "+39 0464/729367",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 11.183333396911621
      }
    },
    {
      "codice": "022089",
      "nome": "Fornace",
      "nomeStraniero": null,
      "codiceCatastale": "D714",
      "cap": "38040",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "michele.broseghini@comune.fornace.tn.it",
      "pec": "comune@pec.comune.fornace.tn.it",
      "telefono": "+39 0461/849023",
      "fax": "+39 0461/849384",
      "coordinate": {
        "lat": 46.11804962158203,
        "lng": 11.207509994506836
      }
    },
    {
      "codice": "022090",
      "nome": "Frassilongo",
      "nomeStraniero": null,
      "codiceCatastale": "D775",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.frassilongo.tn.it",
      "pec": "comune@pec.comune.frassilongo.tn.it",
      "telefono": "+39 0461/549500",
      "fax": "+39 0461/549103",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 11.300000190734863
      }
    },
    {
      "codice": "022091",
      "nome": "Garniga Terme",
      "nomeStraniero": null,
      "codiceCatastale": "D928",
      "cap": "38060",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.garnigaterme.tn.it",
      "pec": "comune@pec.comune.garnigaterme.tn.it",
      "telefono": "+39 0461/842295",
      "fax": "+39 0461/842296",
      "coordinate": {
        "lat": 46.00252914428711,
        "lng": 11.08705997467041
      }
    },
    {
      "codice": "022092",
      "nome": "Giovo",
      "nomeStraniero": null,
      "codiceCatastale": "E048",
      "cap": "38030",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "protocollo@comune.giovo.tn.it",
      "pec": "comune@pec.comune.giovo.tn.it",
      "telefono": "+39 0461/684003",
      "fax": "+39 0461/684707",
      "coordinate": {
        "lat": 46.15583419799805,
        "lng": 11.152777671813965
      }
    },
    {
      "codice": "022093",
      "nome": "Giustino",
      "nomeStraniero": null,
      "codiceCatastale": "E065",
      "cap": "38086",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "c.giustino@comuni.infotn.it",
      "pec": "comune@pec.comune.giustino.tn.it",
      "telefono": "+39 0465/501074",
      "fax": "+39 0465/503670",
      "coordinate": {
        "lat": 46.149959564208984,
        "lng": 10.768010139465332
      }
    },
    {
      "codice": "022095",
      "nome": "Grigno",
      "nomeStraniero": null,
      "codiceCatastale": "E178",
      "cap": "38055",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "comune@comunegrigno.it",
      "pec": "comune.grigno@halleypec.it",
      "telefono": "+39 0461775111",
      "fax": "+39 0461775130",
      "coordinate": {
        "lat": 46.01601028442383,
        "lng": 11.633790016174316
      }
    },
    {
      "codice": "022097",
      "nome": "Imer",
      "nomeStraniero": null,
      "codiceCatastale": "E288",
      "cap": "38050",
      "prefisso": "0439",
      "provincia": "Trento",
      "email": "info@comune.imer.tn.it",
      "pec": "comune@pec.comune.imer.tn.it",
      "telefono": "+39 0439/67016",
      "fax": "+39 0439/67615",
      "coordinate": {
        "lat": 46.15203857421875,
        "lng": 11.797338485717773
      }
    },
    {
      "codice": "022098",
      "nome": "Isera",
      "nomeStraniero": null,
      "codiceCatastale": "E334",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "segreteria@comune.isera.tn.it",
      "pec": "comune.isera@legalmail.it",
      "telefono": "+39 0464/433792",
      "fax": "+39 0464/432520",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "022102",
      "nome": "Lavarone",
      "nomeStraniero": null,
      "codiceCatastale": "E492",
      "cap": "38046",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "anagrafe@comune.lavarone.tn.it",
      "pec": "protocollo@cert.comune.lavarone.tn.it",
      "telefono": "+39 0464/783179",
      "fax": "+39 0464/783665",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "022103",
      "nome": "Lavis",
      "nomeStraniero": null,
      "codiceCatastale": "E500",
      "cap": "38015",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "pezzi@comunelavis.it",
      "pec": "pec.comunelavis@legalmail.it",
      "telefono": "+39 0461/248172",
      "fax": "+39 0461/246327",
      "coordinate": {
        "lat": 46.13901138305664,
        "lng": 11.112259864807129
      }
    },
    {
      "codice": "022229",
      "nome": "Ledro",
      "nomeStraniero": null,
      "codiceCatastale": "M313",
      "cap": "38067",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "comune@comune.ledro.tn.it",
      "pec": "comune@pec.comune.ledro.tn.it",
      "telefono": "+39 0464/592720",
      "fax": "+39 0464/592721",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "022104",
      "nome": "Levico Terme",
      "nomeStraniero": null,
      "codiceCatastale": "E565",
      "cap": "38056",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "protocollo@comune.levico-terme.tn.it",
      "pec": "comunelevicoterme@cert.legalmail.it",
      "telefono": "+39 0461/710211",
      "fax": "+39 0461/710230",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 11.300000190734863
      }
    },
    {
      "codice": "022106",
      "nome": "Livo",
      "nomeStraniero": null,
      "codiceCatastale": "E624",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "sindaco@comune.livo.tn.it",
      "pec": "comune@pec.comune.livo.tn.it",
      "telefono": "+39 0463/533113",
      "fax": "+39 0463/533093",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "022108",
      "nome": "Lona-Lases",
      "nomeStraniero": null,
      "codiceCatastale": "E664",
      "cap": "38040",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "serviziosegreteria@comune.lonalases.tn.it",
      "pec": "comunelonalases@pec.it",
      "telefono": "+39 0461/689108",
      "fax": "+39 0461/689379",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 11.216666221618652
      }
    },
    {
      "codice": "022109",
      "nome": "Luserna",
      "nomeStraniero": null,
      "codiceCatastale": "E757",
      "cap": "38040",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "protocollo@kamou.lusern.it",
      "pec": "comune@pec.comune.luserna.tn.it",
      "telefono": "+39 0464/789714",
      "fax": "+39 0464/789642",
      "coordinate": {
        "lat": 45.92250061035156,
        "lng": 11.32361125946045
      }
    },
    {
      "codice": "022243",
      "nome": "Madruzzo",
      "nomeStraniero": null,
      "codiceCatastale": "M357",
      "cap": "38076",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.madruzzo.tn.it",
      "pec": "comune@pec.comune.madruzzo.tn.it",
      "telefono": "+39 0461 564141",
      "fax": "+39 0461 563040",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 10.983333587646484
      }
    },
    {
      "codice": "022110",
      "nome": "Malé",
      "nomeStraniero": null,
      "codiceCatastale": "E850",
      "cap": "38027",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "info@comunemale.it",
      "pec": "comune@pec.comune.male.tn.it",
      "telefono": "+39 0463/901103",
      "fax": "+39 0463/901116",
      "coordinate": {
        "lat": 46.35361099243164,
        "lng": 10.912500381469727
      }
    },
    {
      "codice": "022112",
      "nome": "Massimeno",
      "nomeStraniero": null,
      "codiceCatastale": "F045",
      "cap": "38086",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "c.massimeno@comuni.infotn.it",
      "pec": "comune@pec.comune.massimeno.tn.it",
      "telefono": "+39 0465/501247",
      "fax": "+39 0465/552141",
      "coordinate": {
        "lat": 46.1414909362793,
        "lng": 10.773500442504883
      }
    },
    {
      "codice": "022113",
      "nome": "Mazzin",
      "nomeStraniero": null,
      "codiceCatastale": "F068",
      "cap": "38030",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "ufficiotecnico@comunedimazzin.it",
      "pec": "mazzin@postemailcertificata.it",
      "telefono": "+39 0462/767138",
      "fax": "+39 0462/767322",
      "coordinate": {
        "lat": 46.470115661621094,
        "lng": 11.729687690734863
      }
    },
    {
      "codice": "022114",
      "nome": "Mezzana",
      "nomeStraniero": null,
      "codiceCatastale": "F168",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "protocollo@comune.mezzana.tn.it",
      "pec": "comune@pec.comune.mezzana.tn.it",
      "telefono": "+39 0463/757124",
      "fax": "+39 0463/757044",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "022115",
      "nome": "Mezzano",
      "nomeStraniero": null,
      "codiceCatastale": "F176",
      "cap": "38050",
      "prefisso": "0439",
      "provincia": "Trento",
      "email": "c.mezzano@comune.mezzano.tn.it",
      "pec": "comune@pec.comune.mezzano.tn.it",
      "telefono": "+39 0439/67019",
      "fax": "+39 0439/67461",
      "coordinate": {
        "lat": 46.1539306640625,
        "lng": 11.808759689331055
      }
    },
    {
      "codice": "022116",
      "nome": "Mezzocorona",
      "nomeStraniero": null,
      "codiceCatastale": "F183",
      "cap": "38016",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.mezzocorona.tn.it",
      "pec": "comune@pec.comune.mezzocorona.tn.it",
      "telefono": "+39 0461/608111",
      "fax": "+39 0461/608126",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "022117",
      "nome": "Mezzolombardo",
      "nomeStraniero": null,
      "codiceCatastale": "F187",
      "cap": "38017",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "s.demografici@comune.mezzolombardo.tn.it",
      "pec": "info@pec.comune.mezzolombardo.tn.it",
      "telefono": "+39 0461/608250",
      "fax": "+39 0461/1860104",
      "coordinate": {
        "lat": 46.209720611572266,
        "lng": 11.097777366638184
      }
    },
    {
      "codice": "022118",
      "nome": "Moena",
      "nomeStraniero": null,
      "codiceCatastale": "F263",
      "cap": "38035",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "segreteria@comune.moena.tn.it",
      "pec": "moena@legalmail.it",
      "telefono": "+39 0462573141",
      "fax": "+39 0462574366",
      "coordinate": {
        "lat": 46.376827239990234,
        "lng": 11.659351348876953
      }
    },
    {
      "codice": "022120",
      "nome": "Molveno",
      "nomeStraniero": null,
      "codiceCatastale": "F307",
      "cap": "38018",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "molveno@comuni.infotn.it",
      "pec": "comune@pec.comune.molveno.tn.it",
      "telefono": "+39 0461/586936",
      "fax": "+39 0461/586968",
      "coordinate": {
        "lat": 46.1430549621582,
        "lng": 10.964722633361816
      }
    },
    {
      "codice": "022123",
      "nome": "Mori",
      "nomeStraniero": null,
      "codiceCatastale": "F728",
      "cap": "38065",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.mori.tn.it",
      "pec": "protocollo.comune.mori.tn.it@legalmail.it",
      "telefono": "+39 0464/916200",
      "fax": "+39 0464/916300",
      "coordinate": {
        "lat": 45.85129928588867,
        "lng": 10.98169994354248
      }
    },
    {
      "codice": "022124",
      "nome": "Nago-Torbole",
      "nomeStraniero": null,
      "codiceCatastale": "F835",
      "cap": "38069",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "planchestainerstefano@comune.nago-torbole.tn.it",
      "pec": "comunenagotorbole.tn@pec.it",
      "telefono": "+39 0464/549539",
      "fax": "+39 0464/549540",
      "coordinate": {
        "lat": 45.875831604003906,
        "lng": 10.891111373901367
      }
    },
    {
      "codice": "022127",
      "nome": "Nogaredo",
      "nomeStraniero": null,
      "codiceCatastale": "F920",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.nogaredo.tn.it",
      "pec": "nogaredo@postemailcertificata.it",
      "telefono": "+39 0464/410257",
      "fax": "+39 0464/461810",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022128",
      "nome": "Nomi",
      "nomeStraniero": null,
      "codiceCatastale": "F929",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "nomi@comune.nomi.tn.it",
      "pec": "comune@pec.comune.nomi.tn.it",
      "telefono": "+39 0464/834107",
      "fax": "+39 0464/830514",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "022129",
      "nome": "Novaledo",
      "nomeStraniero": null,
      "codiceCatastale": "F947",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "demografici@comune.novaledo.tn.it",
      "pec": "comune@pec.comune.novaledo.tn.it",
      "telefono": "+39 0461/721014",
      "fax": "+39 0461/721360",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 11.366666793823242
      }
    },
    {
      "codice": "022253",
      "nome": "Novella",
      "nomeStraniero": null,
      "codiceCatastale": "M430",
      "cap": "38028",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": null,
      "pec": "comune@pec.comune.novella.tn.it",
      "telefono": "+39 0463 432113",
      "fax": null,
      "coordinate": {
        "lat": 46.39122009277344,
        "lng": 11.058429718017578
      }
    },
    {
      "codice": "022130",
      "nome": "Ospedaletto",
      "nomeStraniero": null,
      "codiceCatastale": "G168",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segretario@comune.ospedaletto.tn.it",
      "pec": "ospedaletto@pec.comune.ospedaletto.tn.it",
      "telefono": "+39 0461/768104",
      "fax": "+39 0461/768370",
      "coordinate": {
        "lat": 46.04349136352539,
        "lng": 11.552909851074219
      }
    },
    {
      "codice": "022131",
      "nome": "Ossana",
      "nomeStraniero": null,
      "codiceCatastale": "G173",
      "cap": "38026",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "protocollo@comune.ossana.tn.it",
      "pec": "comune@pec.comune.ossana.tn.it",
      "telefono": "+39 0463/751363",
      "fax": "+39 0463/751909",
      "coordinate": {
        "lat": 46.29999923706055,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "022133",
      "nome": "Palù del Fersina",
      "nomeStraniero": null,
      "codiceCatastale": "G296",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.paludelfersina.tn.it",
      "pec": "comune@pec.comune.paludelfersina.tn.it",
      "telefono": "+39 0461/550001",
      "fax": "+39 0461/550105",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "022134",
      "nome": "Panchià",
      "nomeStraniero": null,
      "codiceCatastale": "G305",
      "cap": "38030",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "info@comune.panchia.tn.it",
      "pec": "comune@pec.comune.panchia.tn.it",
      "telefono": "+39 0462/813075",
      "fax": "+39 0462/812434",
      "coordinate": {
        "lat": 46.285888671875,
        "lng": 11.541972160339355
      }
    },
    {
      "codice": "022136",
      "nome": "Peio",
      "nomeStraniero": null,
      "codiceCatastale": "G419",
      "cap": "38024",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "protocollo@comune.peio.tn.it",
      "pec": "comune@pec.comune.peio.tn.it",
      "telefono": "+39 0463/754059",
      "fax": "+39 0463/754465",
      "coordinate": {
        "lat": 46.35527801513672,
        "lng": 10.689167022705078
      }
    },
    {
      "codice": "022137",
      "nome": "Pellizzano",
      "nomeStraniero": null,
      "codiceCatastale": "G428",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "info@comune.pellizzano.tn.it",
      "pec": "comune@pec.comune.pellizzano.tn.it",
      "telefono": "+39 0463/751128",
      "fax": "+39 0463/751249",
      "coordinate": {
        "lat": 46.30970001220703,
        "lng": 10.759627342224121
      }
    },
    {
      "codice": "022138",
      "nome": "Pelugo",
      "nomeStraniero": null,
      "codiceCatastale": "G429",
      "cap": "38079",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "demografico@comune.pelugo.tn.it",
      "pec": "comune@pec.comune.pelugo.tn.it",
      "telefono": "+39 0465/801132",
      "fax": "+39 0465/800326",
      "coordinate": {
        "lat": 46.08797836303711,
        "lng": 10.72323989868164
      }
    },
    {
      "codice": "022139",
      "nome": "Pergine Valsugana",
      "nomeStraniero": null,
      "codiceCatastale": "G452",
      "cap": "38057",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "roberto.ferrari@comune.pergine.tn.it",
      "pec": "comune.pergine.tn.it@cert.legalmail.it",
      "telefono": "+39 0461/502340",
      "fax": "+39 0461/502300",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "022234",
      "nome": "Pieve di Bono-Prezzo",
      "nomeStraniero": null,
      "codiceCatastale": "M365",
      "cap": "38085",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "info@comune.pievedibono-prezzo.tn.it",
      "pec": "comune@pec.comune.pievedibono-prezzo.tn.it",
      "telefono": "+39 0465 674001",
      "fax": "+39 0465 670270",
      "coordinate": {
        "lat": 45.94305419921875,
        "lng": 10.641388893127441
      }
    },
    {
      "codice": "022142",
      "nome": "Pieve Tesino",
      "nomeStraniero": null,
      "codiceCatastale": "G656",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "anagrafe@comune.pievetesino.tn.it",
      "pec": "comune@pec.comune.pievetesino.tn.it",
      "telefono": "+39 0461/594122",
      "fax": "+39 0461/593122",
      "coordinate": {
        "lat": 46.069061279296875,
        "lng": 11.60908031463623
      }
    },
    {
      "codice": "022143",
      "nome": "Pinzolo",
      "nomeStraniero": null,
      "codiceCatastale": "G681",
      "cap": "38086",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "segreteria@comune.pinzolo.tn.it",
      "pec": "segreteria@pec.comune.pinzolo.tn.it",
      "telefono": "+39 0465509114",
      "fax": "+39 0465/502128",
      "coordinate": {
        "lat": 46.1599006652832,
        "lng": 10.765999794006348
      }
    },
    {
      "codice": "022144",
      "nome": "Pomarolo",
      "nomeStraniero": null,
      "codiceCatastale": "G808",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "pomarolo@comuni.infotn.it",
      "pec": "comune@pec.comune.pomarolo.tn.it",
      "telefono": "+39 0464/410041",
      "fax": "+39 0464/462146",
      "coordinate": {
        "lat": 45.9281005859375,
        "lng": 11.04308032989502
      }
    },
    {
      "codice": "022244",
      "nome": "Porte di Rendena",
      "nomeStraniero": null,
      "codiceCatastale": "M358",
      "cap": "38094",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "protocollo@comuneportedirendena.tn.it",
      "pec": "comune@pec.comuneportedirendena.tn.it",
      "telefono": "+39 0465 321370",
      "fax": "+39 0465 321818",
      "coordinate": {
        "lat": 46.07902145385742,
        "lng": 10.716130256652832
      }
    },
    {
      "codice": "022230",
      "nome": "Predaia",
      "nomeStraniero": null,
      "codiceCatastale": "M344",
      "cap": "38012",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.predaia.tn.it",
      "pec": "comune@pec.comune.predaia.tn.it",
      "telefono": "+39 0463468114",
      "fax": "+39 0463468521",
      "coordinate": {
        "lat": 46.30194091796875,
        "lng": 11.080479621887207
      }
    },
    {
      "codice": "022147",
      "nome": "Predazzo",
      "nomeStraniero": null,
      "codiceCatastale": "H018",
      "cap": "38037",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "anagrafe@comune.predazzo.tn.it",
      "pec": "anagrafe@pec.comune.predazzo.tn.it",
      "telefono": "+39 0462/508211",
      "fax": "+39 0462/507238",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 11.600000381469727
      }
    },
    {
      "codice": "022245",
      "nome": "Primiero San Martino di Castrozza",
      "nomeStraniero": null,
      "codiceCatastale": "M359",
      "cap": "38054",
      "prefisso": "0439",
      "provincia": "Trento",
      "email": "protocollo@comuneprimiero.tn.it",
      "pec": "comune@pec.comuneprimiero.tn.it",
      "telefono": "+39 0439 762161",
      "fax": "+39 0439 64258",
      "coordinate": {
        "lat": 46.177101135253906,
        "lng": 11.82979965209961
      }
    },
    {
      "codice": "022150",
      "nome": "Rabbi",
      "nomeStraniero": null,
      "codiceCatastale": "H146",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.rabbi.tn.it",
      "pec": "comune@pec.comune.rabbi.tn.it",
      "telefono": "+39 0463/984032",
      "fax": "+39 0463/984034",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "022153",
      "nome": "Riva del Garda",
      "nomeStraniero": null,
      "codiceCatastale": "H330",
      "cap": "38066",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "ced@comune.rivadelgarda.tn.it",
      "pec": "comune.rivadelgarda.tn.it@cert.legalmail.it",
      "telefono": "+39 0464/573947",
      "fax": "+39 0464/552410",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "022155",
      "nome": "Romeno",
      "nomeStraniero": null,
      "codiceCatastale": "H517",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "anagrafe@comune.romeno.tn.it",
      "pec": "comune@pec.comune.romeno.tn.it",
      "telefono": "+39 0463/875135",
      "fax": "+39 0463/875555",
      "coordinate": {
        "lat": 46.400001525878906,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "022156",
      "nome": "Roncegno Terme",
      "nomeStraniero": null,
      "codiceCatastale": "H528",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.roncegnoterme.tn.it",
      "pec": "comune.roncegnoterme.tn@pec.it",
      "telefono": "+39 0461/771704",
      "fax": "+39 0461/773101",
      "coordinate": {
        "lat": 46.05083465576172,
        "lng": 11.40999984741211
      }
    },
    {
      "codice": "022157",
      "nome": "Ronchi Valsugana",
      "nomeStraniero": null,
      "codiceCatastale": "H532",
      "cap": "38051",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "c.ronchivalsugana@comuni.infotn.it",
      "pec": "comune@pec.comune.ronchivalsugana.tn.it",
      "telefono": "+39 0461/764221",
      "fax": "+39 0461/771556",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.433333396911621
      }
    },
    {
      "codice": "022135",
      "nome": "Ronzo-Chienis",
      "nomeStraniero": null,
      "codiceCatastale": "M303",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.ronzo-chienis.tn.it",
      "pec": "comune@pec.comune.ronzo-chienis.tn.it",
      "telefono": "+39 0464/802915",
      "fax": "+39 0464/802045",
      "coordinate": {
        "lat": 45.891109466552734,
        "lng": 10.950555801391602
      }
    },
    {
      "codice": "022159",
      "nome": "Ronzone",
      "nomeStraniero": null,
      "codiceCatastale": "H552",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "ronzone@comuni.infotn.it",
      "pec": "comune@pec.comune.ronzone.tn.it",
      "telefono": "+39 0463/880551",
      "fax": "+39 0463/880554",
      "coordinate": {
        "lat": 46.423072814941406,
        "lng": 11.14889907836914
      }
    },
    {
      "codice": "022160",
      "nome": "Roverè della Luna",
      "nomeStraniero": null,
      "codiceCatastale": "H607",
      "cap": "38030",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.roveredellaluna.tn.it",
      "pec": "protocollo.comune.roveredl@legalmail.it",
      "telefono": "+39 0461/658524",
      "fax": "+39 0461/659095",
      "coordinate": {
        "lat": 46.25,
        "lng": 11.166666984558105
      }
    },
    {
      "codice": "022161",
      "nome": "Rovereto",
      "nomeStraniero": null,
      "codiceCatastale": "H612",
      "cap": "38068",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "sistemainformatico@comune.rovereto.tn.it",
      "pec": "comunerovereto.tn@legalmail.it",
      "telefono": "+39 0464/452316",
      "fax": "+39 0464/452210",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 11.03416633605957
      }
    },
    {
      "codice": "022162",
      "nome": "Ruffrè-Mendola",
      "nomeStraniero": null,
      "codiceCatastale": "H634",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "tecno.ruffre@comuni.infotn.it",
      "pec": "comune@pec.comune.ruffremendola.tn.it",
      "telefono": "+39 0463/870004",
      "fax": "+39 0463/870130",
      "coordinate": {
        "lat": 46.41481399536133,
        "lng": 11.177774429321289
      }
    },
    {
      "codice": "022163",
      "nome": "Rumo",
      "nomeStraniero": null,
      "codiceCatastale": "H639",
      "cap": "38020",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "rumo@comune.rumo.tn.it",
      "pec": "comune@pec.comune.rumo.tn.it",
      "telefono": "+39 0463/530113",
      "fax": "+39 0463/530533",
      "coordinate": {
        "lat": 46.442649841308594,
        "lng": 11.016556739807129
      }
    },
    {
      "codice": "022164",
      "nome": "Sagron Mis",
      "nomeStraniero": null,
      "codiceCatastale": "H666",
      "cap": "38050",
      "prefisso": "0439",
      "provincia": "Trento",
      "email": "sindaco@comune.sagron-mis.tn.it",
      "pec": "comune@pec.comune.sagron-mis.tn.it",
      "telefono": "+39 0439/65009",
      "fax": "+39 0439/652014",
      "coordinate": {
        "lat": 46.19559860229492,
        "lng": 11.944199562072754
      }
    },
    {
      "codice": "022165",
      "nome": "Samone",
      "nomeStraniero": null,
      "codiceCatastale": "H754",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.samone.tn.it",
      "pec": "comune@pec.comune.samone.tn.it",
      "telefono": "+39 0461/762033",
      "fax": "+39 0461/762944",
      "coordinate": {
        "lat": 46.08156967163086,
        "lng": 11.522780418395996
      }
    },
    {
      "codice": "022250",
      "nome": "San Giovanni di Fassa-Sèn Jan",
      "nomeStraniero": null,
      "codiceCatastale": "M390",
      "cap": "38036",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "ragioneria@comune.senjandifassa.tn.it",
      "pec": "protocollo@pec.comune.senjandifassa.tn.it",
      "telefono": "0462764758",
      "fax": "0462763578",
      "coordinate": {
        "lat": 46.43026351928711,
        "lng": 11.686795234680176
      }
    },
    {
      "codice": "022231",
      "nome": "San Lorenzo Dorsino",
      "nomeStraniero": null,
      "codiceCatastale": "M345",
      "cap": "38070",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "segreteria@comune.sanlorenzodorsino.tn.it",
      "pec": "comune@pec.comune.sanlorenzodorsino.tn.it",
      "telefono": "+39 0465 734023",
      "fax": "+39 0465 734638",
      "coordinate": {
        "lat": 46.07749938964844,
        "lng": 10.909500122070312
      }
    },
    {
      "codice": "022167",
      "nome": "San Michele all'Adige",
      "nomeStraniero": null,
      "codiceCatastale": "I042",
      "cap": "38098",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.sanmichelealladige.tn.it",
      "pec": "certificata@pec.comune.sanmichelealladige.tn.it",
      "telefono": "+39 0461/650117",
      "fax": "+39 0461/651099",
      "coordinate": {
        "lat": 46.19380187988281,
        "lng": 11.134599685668945
      }
    },
    {
      "codice": "022168",
      "nome": "Sant'Orsola Terme",
      "nomeStraniero": null,
      "codiceCatastale": "I354",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "anagrafe@comune.santorsolaterme.tn.it",
      "pec": "comunesantorsola@cert.legalmail.it",
      "telefono": "+39 0461/540001",
      "fax": "+39 0461/551130",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 11.300000190734863
      }
    },
    {
      "codice": "022169",
      "nome": "Sanzeno",
      "nomeStraniero": null,
      "codiceCatastale": "I411",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.sanzeno.tn.it",
      "pec": "comune@pec.comune.sanzeno.tn.it",
      "telefono": "+39 0463/434167",
      "fax": "+39 0463/434409",
      "coordinate": {
        "lat": 46.36666488647461,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "022170",
      "nome": "Sarnonico",
      "nomeStraniero": null,
      "codiceCatastale": "I439",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.sarnonico.tn.it",
      "pec": "comune@pec.comune.sarnonico.tn.it",
      "telefono": "+39 0463/831263",
      "fax": "+39 0463/831263",
      "coordinate": {
        "lat": 46.41872787475586,
        "lng": 11.140791893005371
      }
    },
    {
      "codice": "022171",
      "nome": "Scurelle",
      "nomeStraniero": null,
      "codiceCatastale": "I554",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.scurelle.tn.it",
      "pec": "comune@pec.comune.scurelle.tn.it",
      "telefono": "+39 0461/762053",
      "fax": "+39 0461/763670",
      "coordinate": {
        "lat": 46.06510925292969,
        "lng": 11.506529808044434
      }
    },
    {
      "codice": "022172",
      "nome": "Segonzano",
      "nomeStraniero": null,
      "codiceCatastale": "I576",
      "cap": "38047",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.segonzano.tn.it",
      "pec": "segreteria@pec.comune.segonzano.tn.it",
      "telefono": "+39 0461/686103",
      "fax": "+39 0461/686060",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "022246",
      "nome": "Sella Giudicarie",
      "nomeStraniero": null,
      "codiceCatastale": "M360",
      "cap": "38087",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "comune@comune.sellagiudicarie.tn.it",
      "pec": "comune@pec.comune.sellagiudicarie.tn.it",
      "telefono": "+39 0465 901023",
      "fax": "+39 0465 901881",
      "coordinate": {
        "lat": 45.98416519165039,
        "lng": 10.66944408416748
      }
    },
    {
      "codice": "022173",
      "nome": "Sfruz",
      "nomeStraniero": null,
      "codiceCatastale": "I714",
      "cap": "38010",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "info@comune.sfruz.tn.it",
      "pec": "comune.sfruz@pec.it",
      "telefono": "+39 0463/536103",
      "fax": "+39 0463/538200",
      "coordinate": {
        "lat": 46.33333206176758,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "022176",
      "nome": "Soraga di Fassa",
      "nomeStraniero": null,
      "codiceCatastale": "I839",
      "cap": "38030",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "info@comune.soraga.tn.it",
      "pec": "comune@pec.comune.soraga.tn.it",
      "telefono": "+39 0462/768179",
      "fax": "+39 0462/768379",
      "coordinate": {
        "lat": 46.395931243896484,
        "lng": 11.668219566345215
      }
    },
    {
      "codice": "022177",
      "nome": "Sover",
      "nomeStraniero": null,
      "codiceCatastale": "I871",
      "cap": "38048",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.sover.tn.it",
      "pec": "comune@pec.comune.sover.tn.it",
      "telefono": "+39 0461/698023",
      "fax": "+39 0461/698398",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "022179",
      "nome": "Spiazzo",
      "nomeStraniero": null,
      "codiceCatastale": "I899",
      "cap": "38088",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "segreteria@comune.spiazzo.tn.it",
      "pec": "comune@pec.comune.spiazzo.tn.it",
      "telefono": "+39 0465/801074",
      "fax": "+39 0465/802037",
      "coordinate": {
        "lat": 46.103599548339844,
        "lng": 10.739370346069336
      }
    },
    {
      "codice": "022180",
      "nome": "Spormaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "I924",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.spormaggiore.tn.it",
      "pec": "posta@pec.comune.spormaggiore.tn.it",
      "telefono": "+39 0461/653555",
      "fax": "+39 0461/653566",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 11.050000190734863
      }
    },
    {
      "codice": "022181",
      "nome": "Sporminore",
      "nomeStraniero": null,
      "codiceCatastale": "I925",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "anagrafe@comune.sporminore.tn.it",
      "pec": "comune@pec.comune.sporminore.tn.it",
      "telefono": "+39 0461/641118",
      "fax": "+39 0461/641110",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022182",
      "nome": "Stenico",
      "nomeStraniero": null,
      "codiceCatastale": "I949",
      "cap": "38070",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "anagrafe@comune.stenico.tn.it",
      "pec": "comune@pec.comune.stenico.tn.it",
      "telefono": "+39 0465/771024",
      "fax": "+39 0465//771100",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "022183",
      "nome": "Storo",
      "nomeStraniero": null,
      "codiceCatastale": "I964",
      "cap": "38089",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "comune@comune.storo.tn.it",
      "pec": "comune@pec.comune.storo.tn.it",
      "telefono": "+39 0465/681200",
      "fax": "+39 0465/686026",
      "coordinate": {
        "lat": 45.8488883972168,
        "lng": 10.580277442932129
      }
    },
    {
      "codice": "022184",
      "nome": "Strembo",
      "nomeStraniero": null,
      "codiceCatastale": "I975",
      "cap": "38080",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "comune.strembo@comuni.infotn.it",
      "pec": "comune@pec.comune.strembo.tn.it",
      "telefono": "+39 0465/804503",
      "fax": "+39 0465/670459",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 10.75
      }
    },
    {
      "codice": "022188",
      "nome": "Telve",
      "nomeStraniero": null,
      "codiceCatastale": "L089",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "anagrafe@comune.telve.tn.it",
      "pec": "anagrafe@pec.comune.telve.tn.it",
      "telefono": "+39 0461/766054",
      "fax": "+39 0461/767077",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "022189",
      "nome": "Telve di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "L090",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "info@comune.telvedisopra.tn.it",
      "pec": "comune@pec.comune.telvedisopra.tn.it",
      "telefono": "+39 0461/766001",
      "fax": "+39 0461/760793",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "022190",
      "nome": "Tenna",
      "nomeStraniero": null,
      "codiceCatastale": "L096",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "ufficio.demografico@comune.tenna.tn.it",
      "pec": "comune@pec.comune.tenna.tn.it",
      "telefono": "+39 0461/706444",
      "fax": "+39 0461/701840",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "022191",
      "nome": "Tenno",
      "nomeStraniero": null,
      "codiceCatastale": "L097",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.tenno.tn.it",
      "pec": "comune@pec.comune.tenno.tn.it",
      "telefono": "+39 0464/500624",
      "fax": "+39 0464/503217",
      "coordinate": {
        "lat": 45.9194450378418,
        "lng": 10.832221984863281
      }
    },
    {
      "codice": "022193",
      "nome": "Terragnolo",
      "nomeStraniero": null,
      "codiceCatastale": "L121",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "anagrafe@comune.terragnolo.tn.it",
      "pec": "posta@pec.comune.terragnolo.tn.it",
      "telefono": "+39 0464/396122",
      "fax": "+39 0464/396414",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "022251",
      "nome": "Terre d'Adige",
      "nomeStraniero": null,
      "codiceCatastale": "M407",
      "cap": "38097",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "segreteria@comune.terredadige.tn.it",
      "pec": "comune@pec.comune.terredadige.tn.it",
      "telefono": "+39 0461 246412",
      "fax": null,
      "coordinate": {
        "lat": 46.151611328125,
        "lng": 11.097220420837402
      }
    },
    {
      "codice": "022195",
      "nome": "Terzolas",
      "nomeStraniero": null,
      "codiceCatastale": "L145",
      "cap": "38027",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "anagrafe@comune.terzolas.tn.it",
      "pec": "pec@pec.comune.terzolas.tn.it",
      "telefono": "+39 0463/901309",
      "fax": "+39 0463/903850",
      "coordinate": {
        "lat": 46.36130905151367,
        "lng": 10.926108360290527
      }
    },
    {
      "codice": "022196",
      "nome": "Tesero",
      "nomeStraniero": null,
      "codiceCatastale": "L147",
      "cap": "38038",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "info@comuneditesero.it",
      "pec": "tesero@postemailcertificata.it",
      "telefono": "+39 0462/811700",
      "fax": "+39 0462/811750",
      "coordinate": {
        "lat": 46.289337158203125,
        "lng": 11.511340141296387
      }
    },
    {
      "codice": "022199",
      "nome": "Tione di Trento",
      "nomeStraniero": null,
      "codiceCatastale": "L174",
      "cap": "38079",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "demografico@comunetioneditrento.it",
      "pec": "demografico@pec.comune.tione.tn.it",
      "telefono": "+39 0465/343100",
      "fax": null,
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "022200",
      "nome": "Ton",
      "nomeStraniero": null,
      "codiceCatastale": "L200",
      "cap": "38010",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "ton@comuni.infotn.it",
      "pec": "comuneton@legalmail.it",
      "telefono": "+39 0461/657813",
      "fax": "+39 0461/657576",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "022202",
      "nome": "Torcegno",
      "nomeStraniero": null,
      "codiceCatastale": "L211",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "c.torcegno@comuni.infotn.it",
      "pec": "comune@pec.comune.torcegno.tn.it",
      "telefono": "+39 0461/760777",
      "fax": "+39 0461/760670",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.449999809265137
      }
    },
    {
      "codice": "022203",
      "nome": "Trambileno",
      "nomeStraniero": null,
      "codiceCatastale": "L322",
      "cap": "38068",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "trambileno.segreteria@comuni.infotn.it",
      "pec": "pec@comune.trambileno.tn.it",
      "telefono": "+39 0464/868028",
      "fax": "+39 0464/868290",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "022247",
      "nome": "Tre Ville",
      "nomeStraniero": null,
      "codiceCatastale": "M361",
      "cap": "38095",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "info@comunetreville.tn.it",
      "pec": "comune@pec.comunetreville.tn.it",
      "telefono": "+39 0465 321133",
      "fax": "+39 0465 324457",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 10.75
      }
    },
    {
      "codice": "022205",
      "nome": "Trento",
      "nomeStraniero": null,
      "codiceCatastale": "L378",
      "cap": "38121",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "servizio_sistemainformativo@comune.trento.it",
      "pec": "protocollo.comune.tn@cert.legalmail.it",
      "telefono": "+39 0461/884274",
      "fax": "+39 0461/884385",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "022232",
      "nome": "Valdaone",
      "nomeStraniero": null,
      "codiceCatastale": "M343",
      "cap": "38091",
      "prefisso": "0465",
      "provincia": "Trento",
      "email": "segreteria@comune.valdaone.tn.it",
      "pec": "comune@pec.comune.valdaone.tn.it",
      "telefono": "+39 0465/674064",
      "fax": "+39 0465/674957",
      "coordinate": {
        "lat": 45.94810104370117,
        "lng": 10.622099876403809
      }
    },
    {
      "codice": "022209",
      "nome": "Valfloriana",
      "nomeStraniero": null,
      "codiceCatastale": "L575",
      "cap": "38040",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "info@comune.valfloriana.tn.it",
      "pec": "comune@pec.comune.valfloriana.tn.it",
      "telefono": "+39 0462/910123",
      "fax": "+39 0462/910135",
      "coordinate": {
        "lat": 46.25,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "022210",
      "nome": "Vallarsa",
      "nomeStraniero": null,
      "codiceCatastale": "L588",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "comune@comune.vallarsa.tn.it",
      "pec": "vallarsa@legalmail.it",
      "telefono": "+39 0464/860860",
      "fax": "+39 0464/869147",
      "coordinate": {
        "lat": 45.782798767089844,
        "lng": 11.117879867553711
      }
    },
    {
      "codice": "022248",
      "nome": "Vallelaghi",
      "nomeStraniero": null,
      "codiceCatastale": "M362",
      "cap": "38096",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "demografico@comune.vallelaghi.tn.it",
      "pec": "info@pec.comune.vallelaghi.tn.it",
      "telefono": "+39 0461 864014",
      "fax": "+39 0461 864612",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 10.983333587646484
      }
    },
    {
      "codice": "022213",
      "nome": "Vermiglio",
      "nomeStraniero": null,
      "codiceCatastale": "L769",
      "cap": "38029",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.vermiglio.tn.it",
      "pec": "comunevermiglio@pec.it",
      "telefono": "+39 0463/758137",
      "fax": "+39 0463/758094",
      "coordinate": {
        "lat": 46.29999923706055,
        "lng": 10.683333396911621
      }
    },
    {
      "codice": "022216",
      "nome": "Vignola-Falesina",
      "nomeStraniero": null,
      "codiceCatastale": "L886",
      "cap": "38050",
      "prefisso": "0461",
      "provincia": "Trento",
      "email": "comunevignolafalesi@trentino.net",
      "pec": "comune@pec.comune.vignola-falesina.tn.it",
      "telefono": "+39 0461/533445",
      "fax": "+39 0461/510518",
      "coordinate": {
        "lat": 46.04436111450195,
        "lng": 11.277379989624023
      }
    },
    {
      "codice": "022222",
      "nome": "Villa Lagarina",
      "nomeStraniero": null,
      "codiceCatastale": "L957",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "anagrafe@comune.villalagarina.tn.it",
      "pec": "villalagarina@legalmail.it",
      "telefono": "+39 0464/494219",
      "fax": "+39 0464/494217",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "022249",
      "nome": "Ville d'Anaunia",
      "nomeStraniero": null,
      "codiceCatastale": "M363",
      "cap": "38019",
      "prefisso": "0463",
      "provincia": "Trento",
      "email": "comune@comune.villedanaunia.tn.it",
      "pec": "comune@pec.comune.villedanaunia.tn.it",
      "telefono": "+39 0463 451191",
      "fax": "+39 0463 451712",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 11.050000190734863
      }
    },
    {
      "codice": "022254",
      "nome": "Ville di Fiemme",
      "nomeStraniero": null,
      "codiceCatastale": "M431",
      "cap": "38099",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "demografico@comune.villedifiemme.tn.it",
      "pec": "comune@pec.comune.villedifiemme.tn.it",
      "telefono": "0462 340144",
      "fax": "0462 341457",
      "coordinate": {
        "lat": 46.30070114135742,
        "lng": 11.447799682617188
      }
    },
    {
      "codice": "022224",
      "nome": "Volano",
      "nomeStraniero": null,
      "codiceCatastale": "M113",
      "cap": "38060",
      "prefisso": "0464",
      "provincia": "Trento",
      "email": "info@comune.volano.tn.it",
      "pec": "comune@pec.comune.volano.tn.it",
      "telefono": "+39 0464/411250",
      "fax": "+39 0464/413427",
      "coordinate": {
        "lat": 45.917179107666016,
        "lng": 11.063509941101074
      }
    },
    {
      "codice": "022226",
      "nome": "Ziano di Fiemme",
      "nomeStraniero": null,
      "codiceCatastale": "M173",
      "cap": "38030",
      "prefisso": "0462",
      "provincia": "Trento",
      "email": "sindaco@comune.ziano.tn.it",
      "pec": "comune@pec.comune.ziano.tn.it",
      "telefono": "+39 0462/571122",
      "fax": "+39 0462/571650",
      "coordinate": {
        "lat": 46.28644561767578,
        "lng": 11.561278343200684
      }
    }
  ],
  "Pavia": [
    {
      "codice": "018001",
      "nome": "Alagna",
      "nomeStraniero": null,
      "codiceCatastale": "A118",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.alagna.pv.it",
      "pec": "info@pec.comune.alagna.pv.it",
      "telefono": "+39 0382818105",
      "fax": "+39 0382818141",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 8.883333206176758
      }
    },
    {
      "codice": "018002",
      "nome": "Albaredo Arnaboldi",
      "nomeStraniero": null,
      "codiceCatastale": "A134",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "albaredoa@tiscali.it",
      "pec": "comune.albaredoarnaboldi@pec.regione.lombardia.it",
      "telefono": "+39 0385.277523",
      "fax": "+39 0385.277523",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 9.25
      }
    },
    {
      "codice": "018003",
      "nome": "Albonese",
      "nomeStraniero": null,
      "codiceCatastale": "A171",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comune.albonese.pv.it",
      "pec": "comune.albonese@legalmail.it",
      "telefono": "038452012",
      "fax": "038452355",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "018004",
      "nome": "Albuzzano",
      "nomeStraniero": null,
      "codiceCatastale": "A175",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.albuzzano.pv.it",
      "pec": "comune.albuzzano@pec.regione.lombardia.it",
      "telefono": "+39 0382584001",
      "fax": "+39 03821835496",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018005",
      "nome": "Arena Po",
      "nomeStraniero": null,
      "codiceCatastale": "A387",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "segreteria@comunearenapo.it",
      "pec": "arenapo@postemailcertificata.it",
      "telefono": "+39 0385270005",
      "fax": "+39 0385270015",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "018006",
      "nome": "Badia Pavese",
      "nomeStraniero": null,
      "codiceCatastale": "A538",
      "cap": "27017",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.badiapavese.pv.it",
      "pec": "protocollo.comune.badiapavese@pec.regione.lombardia.it",
      "telefono": "+39 038278091",
      "fax": "+39 0382728333",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "018007",
      "nome": "Bagnaria",
      "nomeStraniero": null,
      "codiceCatastale": "A550",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.bagnaria.pv.it",
      "pec": "comune.bagnaria@legalpec.it",
      "telefono": "+39 0383572001",
      "fax": "+39 0383572201",
      "coordinate": {
        "lat": 44.83333206176758,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "018008",
      "nome": "Barbianello",
      "nomeStraniero": null,
      "codiceCatastale": "A634",
      "cap": "27041",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "barbianello@libero.it",
      "pec": "comune.barbianello@pec.provincia.pv.it",
      "telefono": "+39 038557510",
      "fax": "+39 038557114",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018009",
      "nome": "Bascapè",
      "nomeStraniero": null,
      "codiceCatastale": "A690",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.bascape.pv.it",
      "pec": "comune.bascape@pec.provincia.pv.it",
      "telefono": "+39 0382 66012",
      "fax": "+39 0382 66459",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018011",
      "nome": "Bastida Pancarana",
      "nomeStraniero": null,
      "codiceCatastale": "A712",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.bastidapancarana.pv.it",
      "pec": "protocollo.bastidapancarana@legalpec.it",
      "telefono": "+39 038385002",
      "fax": "+39 0383895700",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "018012",
      "nome": "Battuda",
      "nomeStraniero": null,
      "codiceCatastale": "A718",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "battuda@comune.battuda.pv.it",
      "pec": "comune.battuda@pec.provincia.pv.it",
      "telefono": "+39 0382/926031",
      "fax": "+39 0382/926033",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018013",
      "nome": "Belgioioso",
      "nomeStraniero": null,
      "codiceCatastale": "A741",
      "cap": "27011",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.belgioioso.pv.it",
      "pec": "comune.belgioioso@pec.regione.lombardia.it",
      "telefono": "+39 0382-97841",
      "fax": "+39 0382-978451",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018014",
      "nome": "Bereguardo",
      "nomeStraniero": null,
      "codiceCatastale": "A792",
      "cap": "27021",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.bereguardo.pv.it",
      "pec": "comune.bereguardo@pec.regione.lombardia.it",
      "telefono": "+39 0382930362",
      "fax": "+39 03821722041",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "018015",
      "nome": "Borgarello",
      "nomeStraniero": null,
      "codiceCatastale": "A989",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.borgarello.pv.it",
      "pec": "comune.borgarello@pec.regione.lombardia.it",
      "telefono": "+39 0382-925581",
      "fax": "+39 0382-1722022",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018016",
      "nome": "Borgo Priolo",
      "nomeStraniero": null,
      "codiceCatastale": "B028",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "demografici@comune.borgopriolo.pv.it",
      "pec": "comune.borgopriolo@legalpec.it",
      "telefono": "+39 0383872214",
      "fax": "+39 0383871371",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "018018",
      "nome": "Borgo San Siro",
      "nomeStraniero": null,
      "codiceCatastale": "B038",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.borgosansiro.pv.it",
      "pec": "comune.borgosansiro@pec.provincia.pv.it",
      "telefono": "+39 0382874091",
      "fax": "+39 038287317",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "018017",
      "nome": "Borgoratto Mormorolo",
      "nomeStraniero": null,
      "codiceCatastale": "B030",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.borgoratto-mormorolo.pv.it",
      "pec": "comune.borgorattomormorolo@pec.regione.lombardia.it",
      "telefono": "+39 0383875221",
      "fax": "+39 0383875656",
      "coordinate": {
        "lat": 44.93333435058594,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018019",
      "nome": "Bornasco",
      "nomeStraniero": null,
      "codiceCatastale": "B051",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "urp@comune.bornasco.pv.it",
      "pec": "comune.bornasco@legalpec.it",
      "telefono": "+39 0382955013",
      "fax": "+39 0382955945",
      "coordinate": {
        "lat": 45.26656723022461,
        "lng": 9.217950820922852
      }
    },
    {
      "codice": "018020",
      "nome": "Bosnasco",
      "nomeStraniero": null,
      "codiceCatastale": "B082",
      "cap": "27049",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@bosnasco.com",
      "pec": "comune.bosnasco@pec.regione.lombardia.it",
      "telefono": "+39 0385272075",
      "fax": "+39 0385272379",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "018021",
      "nome": "Brallo di Pregola",
      "nomeStraniero": null,
      "codiceCatastale": "B117",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comune.brallo@virgilio.it",
      "pec": "comune.brallodipregola@legalpec.it",
      "telefono": "+39 0383550040",
      "fax": "+39 0383550025",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "018022",
      "nome": "Breme",
      "nomeStraniero": null,
      "codiceCatastale": "B142",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comunebreme.it",
      "pec": "comune.breme@pec.regione.lombardia.it",
      "telefono": "+39 038477001",
      "fax": "+39 038477041",
      "coordinate": {
        "lat": 45.133331298828125,
        "lng": 8.633333206176758
      }
    },
    {
      "codice": "018023",
      "nome": "Bressana Bottarone",
      "nomeStraniero": null,
      "codiceCatastale": "B159",
      "cap": "27042",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.bressanabottarone.pv.it",
      "pec": "comune.bressanabottarone@legalpec.it",
      "telefono": "+39 038388101",
      "fax": null,
      "coordinate": {
        "lat": 45.07807159423828,
        "lng": 9.131190299987793
      }
    },
    {
      "codice": "018024",
      "nome": "Broni",
      "nomeStraniero": null,
      "codiceCatastale": "B201",
      "cap": "27043",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.broni.pv.it",
      "pec": "comunebroni@pec.it",
      "telefono": "+39 0385257011",
      "fax": "+39 038552106",
      "coordinate": {
        "lat": 45.06194305419922,
        "lng": 9.26111125946045
      }
    },
    {
      "codice": "018025",
      "nome": "Calvignano",
      "nomeStraniero": null,
      "codiceCatastale": "B447",
      "cap": "27045",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "uffici@comune.calvignano.pv.it",
      "pec": "comune.calvignano@pec.provincia.pv.it",
      "telefono": "+39 0383/871120",
      "fax": "+39 0383/871037",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "018026",
      "nome": "Campospinoso",
      "nomeStraniero": null,
      "codiceCatastale": "B567",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "campospinoso@libero.it",
      "pec": "comune.campospinoso@pec.regione.lombardia.it",
      "telefono": "+39 0385.277762",
      "fax": "+39 0385.277978",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 9.25
      }
    },
    {
      "codice": "018027",
      "nome": "Candia Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "B587",
      "cap": "27031",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comune.candialomellina.pv.it",
      "pec": "anagrafe.comune.candialomellina@pec.regione.lombardia.it",
      "telefono": "+39 038474003",
      "fax": null,
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 8.600000381469727
      }
    },
    {
      "codice": "018029",
      "nome": "Canneto Pavese",
      "nomeStraniero": null,
      "codiceCatastale": "B613",
      "cap": "27044",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "comune@comune.cannetopavese.pv.it",
      "pec": "comune.cannetopavese@legalpec.it",
      "telefono": "+39 038588021",
      "fax": "+39 0385241595",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "018030",
      "nome": "Carbonara al Ticino",
      "nomeStraniero": null,
      "codiceCatastale": "B741",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.carbonaraalticino.pv.it",
      "pec": "comune.carbonara-alticino@pec.regione.lombardia.it",
      "telefono": "+39 0382400037",
      "fax": "+39 0382400264",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "018031",
      "nome": "Casanova Lonati",
      "nomeStraniero": null,
      "codiceCatastale": "B929",
      "cap": "27041",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.casanovalonati.pv.it",
      "pec": "comune.casanovalonati@pec.regione.lombardia.it",
      "telefono": "+39 038557108",
      "fax": "+39 038557514",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "018032",
      "nome": "Casatisma",
      "nomeStraniero": null,
      "codiceCatastale": "B945",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.casatisma.pv.it",
      "pec": "servizio.segreteria@comunecasatisma.legalmail.it",
      "telefono": "+39 038384218",
      "fax": "+39 0383891800",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018033",
      "nome": "Casei Gerola",
      "nomeStraniero": null,
      "codiceCatastale": "B954",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.caseigerola.pv.it",
      "pec": "comune.caseigerola@legalmail.it",
      "telefono": "+39 038361301",
      "fax": "+39 0383381170",
      "coordinate": {
        "lat": 45.00614547729492,
        "lng": 8.926989555358887
      }
    },
    {
      "codice": "018034",
      "nome": "Casorate Primo",
      "nomeStraniero": null,
      "codiceCatastale": "B988",
      "cap": "27022",
      "prefisso": "02",
      "provincia": "Pavia",
      "email": "info@comune.casorateprimo.pv.it",
      "pec": "casorateprimo@postemailcertificata.it",
      "telefono": "+39 02 90519511",
      "fax": "+39 02 905195239",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "018035",
      "nome": "Cassolnovo",
      "nomeStraniero": null,
      "codiceCatastale": "C038",
      "cap": "27023",
      "prefisso": "0381",
      "provincia": "Pavia",
      "email": "anagrafe@comune.cassolnovo.pv.it",
      "pec": "comune.cassolnovo@pec.regione.lombardia.it",
      "telefono": "+39 0381928769",
      "fax": "+39 0381929175",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "018036",
      "nome": "Castana",
      "nomeStraniero": null,
      "codiceCatastale": "C050",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "amministrativo@comune.castana.pv.it",
      "pec": "comune.castana@legalpec.it",
      "telefono": "+39 0385 82006",
      "fax": "+39 0385 82023",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018037",
      "nome": "Casteggio",
      "nomeStraniero": null,
      "codiceCatastale": "C053",
      "cap": "27045",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.casteggio.pv.it",
      "pec": "casteggio@pcert.it",
      "telefono": "+39 038380781",
      "fax": "+39 038382472",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018038",
      "nome": "Castelletto di Branduzzo",
      "nomeStraniero": null,
      "codiceCatastale": "C157",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.castellettodibranduzzo.pv.it",
      "pec": "info@pec.comune.castellettodibranduzzo.pv.it",
      "telefono": "+39 0383-895627",
      "fax": "+39 0383-895565",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "018039",
      "nome": "Castello d'Agogna",
      "nomeStraniero": null,
      "codiceCatastale": "C184",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "demografico@comune.castellodagogna.pv.it",
      "pec": "comune.castellodagogna@pec.regione.lombardia.it",
      "telefono": "+39 038456017",
      "fax": "+39 0384256548",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "018040",
      "nome": "Castelnovetto",
      "nomeStraniero": null,
      "codiceCatastale": "C213",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "castelnovetto@libero.it",
      "pec": "comune.castelnovetto@pec.regione.lombardia.it",
      "telefono": "+39 0384673404",
      "fax": "+39 0384673411",
      "coordinate": {
        "lat": 45.25,
        "lng": 8.616666793823242
      }
    },
    {
      "codice": "018041",
      "nome": "Cava Manara",
      "nomeStraniero": null,
      "codiceCatastale": "C360",
      "cap": "27051",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.cavamanara.pv.it",
      "pec": "protocollo@cert.comune.cavamanara.pv.it",
      "telefono": "+39 0382.557511",
      "fax": "+39 0382.554110",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "018042",
      "nome": "Cecima",
      "nomeStraniero": null,
      "codiceCatastale": "C414",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comune.cecima@alice.it",
      "pec": "comune.cecima@legalpec.it",
      "telefono": "+39 0383 59144",
      "fax": "+39 0383 549017",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018043",
      "nome": "Ceranova",
      "nomeStraniero": null,
      "codiceCatastale": "C484",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.ceranova.pv.it",
      "pec": "info@pec.comune.ceranova.pv.it",
      "telefono": "+39 0382-954710",
      "fax": "+39 0382-953800",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "018044",
      "nome": "Ceretto Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "C508",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "info@comune.cerettolomellina.pv.it",
      "pec": "cerettolomellina@pcert.it",
      "telefono": "+39 0384.56029",
      "fax": "+39 0384.56029",
      "coordinate": {
        "lat": 45.25,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "018045",
      "nome": "Cergnago",
      "nomeStraniero": null,
      "codiceCatastale": "C509",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "protocollo@comune.cergnago.pv.it",
      "pec": "comune.cergnago@pec.regione.lombardia.it",
      "telefono": "+39 038443429",
      "fax": "+39 038443800",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "018046",
      "nome": "Certosa di Pavia",
      "nomeStraniero": null,
      "codiceCatastale": "C541",
      "cap": "27012",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@certosadipavia.gov.it",
      "pec": "sindaco.comune.certosadipavia@pec.regione.lombardia.it",
      "telefono": "+39 0382.936911",
      "fax": "+39 0382.933128",
      "coordinate": {
        "lat": 45.254356384277344,
        "lng": 9.127887725830078
      }
    },
    {
      "codice": "018047",
      "nome": "Cervesina",
      "nomeStraniero": null,
      "codiceCatastale": "C551",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "anagrafe@comune.cervesina.pv.it",
      "pec": "ragioneria.cervesina@pec.it",
      "telefono": "+39 0383375156",
      "fax": "+39 0383375220",
      "coordinate": {
        "lat": 45.06194305419922,
        "lng": 9.016944885253906
      }
    },
    {
      "codice": "018048",
      "nome": "Chignolo Po",
      "nomeStraniero": null,
      "codiceCatastale": "C637",
      "cap": "27013",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.chignolopo.pv.it",
      "pec": "comune.chignolopo@pec.regione.lombardia.it",
      "telefono": "+39 0382 76001",
      "fax": "+39 0382 766366",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "018049",
      "nome": "Cigognola",
      "nomeStraniero": null,
      "codiceCatastale": "C684",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "servizidemografici@comune.cigognola.pv.it",
      "pec": "segreteriacigognola@pec.it",
      "telefono": "+39 0385781307",
      "fax": "+39 0385284153",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "018050",
      "nome": "Cilavegna",
      "nomeStraniero": null,
      "codiceCatastale": "C686",
      "cap": "27024",
      "prefisso": "0381",
      "provincia": "Pavia",
      "email": "info@comune.cilavegna.pv.it",
      "pec": "comune.cilavegna.pv@legalmail.it",
      "telefono": "+39 0381668011",
      "fax": "+39 038196392",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 8.75
      }
    },
    {
      "codice": "018051",
      "nome": "Codevilla",
      "nomeStraniero": null,
      "codiceCatastale": "C813",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.codevilla.pv.it",
      "pec": "comune.codevilla@pec.provincia.pv.it",
      "telefono": "+39 0383373123",
      "fax": "+39 038373782",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "018193",
      "nome": "Colli Verdi",
      "nomeStraniero": null,
      "codiceCatastale": "M419",
      "cap": "27061",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": null,
      "pec": "comune.colliverdi@legalpec.it",
      "telefono": "+39 0385955898",
      "fax": "+39  0385955897",
      "coordinate": {
        "lat": 44.927581787109375,
        "lng": 9.276355743408203
      }
    },
    {
      "codice": "018052",
      "nome": "Confienza",
      "nomeStraniero": null,
      "codiceCatastale": "C958",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "municipio@comune.confienza.pv.it",
      "pec": "confienza@postemailcertificata.it",
      "telefono": "+39 0384.64032",
      "fax": "+39 0384.674566",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 8.533333778381348
      }
    },
    {
      "codice": "018053",
      "nome": "Copiano",
      "nomeStraniero": null,
      "codiceCatastale": "C979",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.copiano.pv.it",
      "pec": "comune.copiano@pec.regione.lombardia.it",
      "telefono": "+39 0382 975036",
      "fax": "+39 0382 965098",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018054",
      "nome": "Corana",
      "nomeStraniero": null,
      "codiceCatastale": "C982",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.corana.pv.it",
      "pec": "comune.corana@legalpec.it",
      "telefono": "+39 0383/78100",
      "fax": "+39 0383/378744",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "018191",
      "nome": "Cornale e Bastida",
      "nomeStraniero": null,
      "codiceCatastale": "M338",
      "cap": "27056",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.cornalebastida.pv.it",
      "pec": "info@pec.comune.cornalebastida.pv.it",
      "telefono": "+39 0383/60103",
      "fax": "+39 0383/60472",
      "coordinate": {
        "lat": 45.04389953613281,
        "lng": 8.91409969329834
      }
    },
    {
      "codice": "018192",
      "nome": "Corteolona e Genzone",
      "nomeStraniero": null,
      "codiceCatastale": "M372",
      "cap": "27014",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.corteolonaegenzone.pv.it",
      "pec": "amministrazione@pec.comune.corteolonaegenzone.pv.it",
      "telefono": "+39 0382 70024",
      "fax": "+39 0382 71519",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "018057",
      "nome": "Corvino San Quirico",
      "nomeStraniero": null,
      "codiceCatastale": "D081",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "segreteria@comune.corvino-san-quirico.pv.it",
      "pec": "comune.corvinosanquirico@pec.regione.lombardia.it",
      "telefono": "+39 0383-876121",
      "fax": "+39 0383-876163",
      "coordinate": {
        "lat": 45.010555267333984,
        "lng": 9.162500381469727
      }
    },
    {
      "codice": "018058",
      "nome": "Costa de' Nobili",
      "nomeStraniero": null,
      "codiceCatastale": "D109",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "comunecostadenobili@libero.it",
      "pec": "comune.costadenobili@pec.provincia.pv.it",
      "telefono": "+39 038270732",
      "fax": "+39 0382720022",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "018059",
      "nome": "Cozzo",
      "nomeStraniero": null,
      "codiceCatastale": "D127",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "comunedicozzo@libero.it",
      "pec": "comune.cozzo@pec.regione.lombardia.it",
      "telefono": "+39 038474102",
      "fax": "+39 038474013",
      "coordinate": {
        "lat": 45.192657470703125,
        "lng": 8.611040115356445
      }
    },
    {
      "codice": "018060",
      "nome": "Cura Carpignano",
      "nomeStraniero": null,
      "codiceCatastale": "B824",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "simona.gobetti@comune.curacarpignano.pv.it",
      "pec": "servizidemografici@pec.comune.curacarpignano.pv.it",
      "telefono": "+39 0382488021",
      "fax": "+39 0382488289",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 9.25
      }
    },
    {
      "codice": "018061",
      "nome": "Dorno",
      "nomeStraniero": null,
      "codiceCatastale": "D348",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.dorno.pv.it",
      "pec": "comune.dorno@pec.provincia.pv.it",
      "telefono": "+39 038284003",
      "fax": "+39 038284085",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "018062",
      "nome": "Ferrera Erbognone",
      "nomeStraniero": null,
      "codiceCatastale": "D552",
      "cap": "27032",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.ferreraerbognone.pv.it",
      "pec": "comune.ferrera@pec.regione.lombardia.it",
      "telefono": "+39 0382/998012",
      "fax": "+39 0382/998942",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "018063",
      "nome": "Filighera",
      "nomeStraniero": null,
      "codiceCatastale": "D594",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.filighera.pv.it",
      "pec": "comune.filighera@pec.regione.lombardia.it",
      "telefono": "+39 0382969321",
      "fax": "+39 0382960336",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018064",
      "nome": "Fortunago",
      "nomeStraniero": null,
      "codiceCatastale": "D732",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comune.fortunago@libero.it",
      "pec": "comune.fortunago@legalpec.it",
      "telefono": "+39 0383875213",
      "fax": "+39 0383875692",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "018065",
      "nome": "Frascarolo",
      "nomeStraniero": null,
      "codiceCatastale": "D771",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "demografici@comune.frascarolo.pv.it",
      "pec": "anagrafe@pec.comune.frascarolo.pv.it",
      "telefono": "+39 0384849053",
      "fax": "+39 038484482",
      "coordinate": {
        "lat": 45.04665756225586,
        "lng": 8.68204402923584
      }
    },
    {
      "codice": "018066",
      "nome": "Galliavola",
      "nomeStraniero": null,
      "codiceCatastale": "D873",
      "cap": "27034",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "comune.galliavola@libero.it",
      "pec": "comune.galliavola@pec.it",
      "telefono": "+39 038485083",
      "fax": "+39 0384858942",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 8.816666603088379
      }
    },
    {
      "codice": "018067",
      "nome": "Gambarana",
      "nomeStraniero": null,
      "codiceCatastale": "D892",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "sindaco@comune.gambarana.pv.it",
      "pec": "comune.gambarana@legalpec.it",
      "telefono": "+39 0384 89023",
      "fax": "+39 0384 89023",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "018068",
      "nome": "Gambolò",
      "nomeStraniero": null,
      "codiceCatastale": "D901",
      "cap": "27025",
      "prefisso": "0381",
      "provincia": "Pavia",
      "email": "affarigenerali@comune.gambolo.pv.it",
      "pec": "comune.gambolo@pec.regione.lombardia.it",
      "telefono": "+39 0381 937211",
      "fax": "+39 0381 939502",
      "coordinate": {
        "lat": 45.258609771728516,
        "lng": 8.86472225189209
      }
    },
    {
      "codice": "018069",
      "nome": "Garlasco",
      "nomeStraniero": null,
      "codiceCatastale": "D925",
      "cap": "27026",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.garlasco.pv.it",
      "pec": "protocollo@pec.comune.garlasco.pv.it",
      "telefono": "+39 0382/825211",
      "fax": "+39 0382/820304",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "018071",
      "nome": "Gerenzago",
      "nomeStraniero": null,
      "codiceCatastale": "D980",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "sindaco@comunegerenzago.it",
      "pec": "comune.gerenzago@pec.regione.lombardia.it",
      "telefono": "+39 0382 967051",
      "fax": "+39 0382 963321",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "018072",
      "nome": "Giussago",
      "nomeStraniero": null,
      "codiceCatastale": "E062",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.giussago.pv.it",
      "pec": "comune.giussago@pec.regione.lombardia.it",
      "telefono": "+39 0382939611",
      "fax": "+39 0382927711",
      "coordinate": {
        "lat": 45.284767150878906,
        "lng": 9.140748977661133
      }
    },
    {
      "codice": "018073",
      "nome": "Godiasco Salice Terme",
      "nomeStraniero": null,
      "codiceCatastale": "E072",
      "cap": "27052",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "anagrafe@comune.godiascosaliceterme.pv.it",
      "pec": "anagrafe.godiasco@pec..it",
      "telefono": "+39 0383941437",
      "fax": "+39 0383940606",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "018074",
      "nome": "Golferenzo",
      "nomeStraniero": null,
      "codiceCatastale": "E081",
      "cap": "27047",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "protocollo@comune.golferenzo.pv.it",
      "pec": "comune.golferenzo@pec.provincia.pv.it",
      "telefono": "+39 0385 99904",
      "fax": "+39 0385 951024",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "018075",
      "nome": "Gravellona Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "E152",
      "cap": "27020",
      "prefisso": "0381",
      "provincia": "Pavia",
      "email": "anagrafe@comune.gravellonalomellina.pv.it",
      "pec": "comunegravellonalomellina@pec.it",
      "telefono": "+39 0381650057",
      "fax": "+39 0381/650127",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "018076",
      "nome": "Gropello Cairoli",
      "nomeStraniero": null,
      "codiceCatastale": "E195",
      "cap": "27027",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.gropellocairoli.pv.it",
      "pec": "gropellocairoli@pec.comune.gropellocairoli.pv.it",
      "telefono": "+39 0382/815233",
      "fax": "+39 0382/815031",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 9
      }
    },
    {
      "codice": "018077",
      "nome": "Inverno e Monteleone",
      "nomeStraniero": null,
      "codiceCatastale": "E310",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.invernoemonteleone.pv.it",
      "pec": "info@pec.comune.invernoemonteleone.pv.it",
      "telefono": "+39 0382/73023",
      "fax": "+39 0382/73326",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "018078",
      "nome": "Landriano",
      "nomeStraniero": null,
      "codiceCatastale": "E437",
      "cap": "27015",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.landriano.pv.it",
      "pec": "info@pec.comune.landriano.pv.it",
      "telefono": "+39 0382/64001",
      "fax": "+39 0382/615581",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018079",
      "nome": "Langosco",
      "nomeStraniero": null,
      "codiceCatastale": "E439",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe.langosco@libero.it",
      "pec": "comune.langosco@pec.provincia.pv.it",
      "telefono": "+39 0384/78021",
      "fax": "+39 0384/78075",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "018080",
      "nome": "Lardirago",
      "nomeStraniero": null,
      "codiceCatastale": "E454",
      "cap": "27016",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.lardirago.pv.it",
      "pec": "info@pec.comune.lardirago.pv.it",
      "telefono": "+39 0382-944791",
      "fax": "",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "018081",
      "nome": "Linarolo",
      "nomeStraniero": null,
      "codiceCatastale": "E600",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "ufficio.demografico@comune.linarolo.pv.it",
      "pec": "comune.linarolo@pec.regione.lombardia.it",
      "telefono": "+39 0382/569104",
      "fax": "+39 0382/569106",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018082",
      "nome": "Lirio",
      "nomeStraniero": null,
      "codiceCatastale": "E608",
      "cap": "27043",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "comune.lirio@libero.it",
      "pec": "protocollo@pec.comune.lirio.pv.it",
      "telefono": "+39 0385/85290",
      "fax": "+39 0385/284942",
      "coordinate": {
        "lat": 45,
        "lng": 9.25
      }
    },
    {
      "codice": "018083",
      "nome": "Lomello",
      "nomeStraniero": null,
      "codiceCatastale": "E662",
      "cap": "27034",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "segreteria@comune.lomello.pv.it",
      "pec": "segreteria.comune.lomello@pec.regione.lombardia.it",
      "telefono": "+39 0384 85005",
      "fax": "+39 0384 85245",
      "coordinate": {
        "lat": 45.12200164794922,
        "lng": 8.797300338745117
      }
    },
    {
      "codice": "018084",
      "nome": "Lungavilla",
      "nomeStraniero": null,
      "codiceCatastale": "B387",
      "cap": "27053",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comune.lungavilla@legalpec.it",
      "pec": "comune.lungavilla@legalpec.it",
      "telefono": "+39 0383.76130",
      "fax": "+39 0383.76628",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018085",
      "nome": "Magherno",
      "nomeStraniero": null,
      "codiceCatastale": "E804",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.magherno.pv.it",
      "pec": "comune.magherno@pec.provincia.pv.it",
      "telefono": "+39 0382/966913",
      "fax": "+39 0382/973735",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "018086",
      "nome": "Marcignago",
      "nomeStraniero": null,
      "codiceCatastale": "E934",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "marcignago@comune.marcignago.pv.it",
      "pec": "comune.marcignago@pec.regione.lombardia.it",
      "telefono": "+39 0382/929021",
      "fax": "+39 0382/929688",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018087",
      "nome": "Marzano",
      "nomeStraniero": null,
      "codiceCatastale": "E999",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.marzano.pv.it",
      "pec": "comune.marzano@pec.regione.lombardia.it",
      "telefono": "+39 0382/947021",
      "fax": "+39 0382/947430",
      "coordinate": {
        "lat": 45.24796676635742,
        "lng": 9.29505443572998
      }
    },
    {
      "codice": "018088",
      "nome": "Mede",
      "nomeStraniero": null,
      "codiceCatastale": "F080",
      "cap": "27035",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "segreteria@comune.mede.pv.it",
      "pec": "comunedimede@pec.it",
      "telefono": "+39 0384/822201",
      "fax": "+39 0384/822252",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "018089",
      "nome": "Menconico",
      "nomeStraniero": null,
      "codiceCatastale": "F122",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "protocollo@comune.menconico.pv.it",
      "pec": "anagrafe.comune.menconico@pec.regione.lombardia.it",
      "telefono": "0383574001",
      "fax": null,
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "018090",
      "nome": "Mezzana Bigli",
      "nomeStraniero": null,
      "codiceCatastale": "F170",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "mezzanaanag@libero.it",
      "pec": "mezzanabigli@pec.it",
      "telefono": "+39 0384/88016",
      "fax": "+39 0384/88370",
      "coordinate": {
        "lat": 45.06027603149414,
        "lng": 8.850000381469727
      }
    },
    {
      "codice": "018091",
      "nome": "Mezzana Rabattone",
      "nomeStraniero": null,
      "codiceCatastale": "F171",
      "cap": "27030",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "uffici.comunali@comune.mezzanarabattone.pv.it",
      "pec": "comune.mezzanar@pec.provincia.pv.it",
      "telefono": "+39 0382/918862",
      "fax": "+39 0382/918910",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "018092",
      "nome": "Mezzanino",
      "nomeStraniero": null,
      "codiceCatastale": "F175",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "segreteria@comune.mezzanino.pv.it",
      "pec": "comune.mezzanino@pec.regione.lombardia.it",
      "telefono": "+39 038571013",
      "fax": "+39 038571525",
      "coordinate": {
        "lat": 45.123992919921875,
        "lng": 9.208466529846191
      }
    },
    {
      "codice": "018093",
      "nome": "Miradolo Terme",
      "nomeStraniero": null,
      "codiceCatastale": "F238",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.miradoloterme.pv.it",
      "pec": "comune.miradolo@pec.provincia.pv.it",
      "telefono": "+39 038277014",
      "fax": "+39 0382/75236",
      "coordinate": {
        "lat": 45.172393798828125,
        "lng": 9.448665618896484
      }
    },
    {
      "codice": "018094",
      "nome": "Montalto Pavese",
      "nomeStraniero": null,
      "codiceCatastale": "F417",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "uffici@comune.montaltopavese.pv.it",
      "pec": "comune.montaltopavese@legalpec.it",
      "telefono": "+39 0383870121",
      "fax": "+39 0383870222",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "018095",
      "nome": "Montebello della Battaglia",
      "nomeStraniero": null,
      "codiceCatastale": "F440",
      "cap": "27054",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "servizioamministrativo@comune.montebellodellabattaglia.pv.it",
      "pec": "comune.montebellodellabattaglia@legalpec.it",
      "telefono": "+39 0383/82648",
      "fax": "+39 0383/890607",
      "coordinate": {
        "lat": 45.00095748901367,
        "lng": 9.103453636169434
      }
    },
    {
      "codice": "018096",
      "nome": "Montecalvo Versiggia",
      "nomeStraniero": null,
      "codiceCatastale": "F449",
      "cap": "27047",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "protocollo@comune.montecalvo.pv.it",
      "pec": "protocollo@pec.comune.montecalvo.pv.it",
      "telefono": "+39 038599712",
      "fax": "+39 0385951026",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018097",
      "nome": "Montescano",
      "nomeStraniero": null,
      "codiceCatastale": "F638",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "anagrafe@comune.montescano.pv.it",
      "pec": "comune.montescano@legalpec.it",
      "telefono": "+39 0385 60033",
      "fax": "+39 0385 262870",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "018098",
      "nome": "Montesegale",
      "nomeStraniero": null,
      "codiceCatastale": "F644",
      "cap": "27052",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "montesegale@libero.it",
      "pec": "comune.montesegale@pec.regione.lombardia.it",
      "telefono": "+39 038399030",
      "fax": "+39 038399191",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018099",
      "nome": "Monticelli Pavese",
      "nomeStraniero": null,
      "codiceCatastale": "F670",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "comune.monticelli@pec.provincia.pv.it",
      "pec": "comune.monticelli@pec.provincia.pv.it",
      "telefono": "+39 0382722220",
      "fax": "+39 0382722236",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "018100",
      "nome": "Montù Beccaria",
      "nomeStraniero": null,
      "codiceCatastale": "F701",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "protocollo@comune.montubeccaria.pv.it",
      "pec": "protocollo.comune.montubeccaria@pec.regione.lombardia.it",
      "telefono": "+39 0385277160",
      "fax": "+39 0385277179",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018101",
      "nome": "Mornico Losana",
      "nomeStraniero": null,
      "codiceCatastale": "F739",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "demografici@comunemornicolosana.pv.it",
      "pec": "mornicolosana@postemailcertificata.it",
      "telefono": "+39 0383892523",
      "fax": "+39 0383892565",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018102",
      "nome": "Mortara",
      "nomeStraniero": null,
      "codiceCatastale": "F754",
      "cap": "27036",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "demografici@comune.mortara.pv.it",
      "pec": "comune.mortara@pec.provincia.pv.it",
      "telefono": "+39 0384 256411",
      "fax": "+39 0384 99993",
      "coordinate": {
        "lat": 45.25,
        "lng": 8.75
      }
    },
    {
      "codice": "018103",
      "nome": "Nicorvo",
      "nomeStraniero": null,
      "codiceCatastale": "F891",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "info@comune.nicorvo.pv.it",
      "pec": "comune.nicorvo@pec.regione.lombardia.it",
      "telefono": "+39 038452044",
      "fax": "+39 038452020",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "018104",
      "nome": "Olevano di Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "G021",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comuneolevanolomellina.it",
      "pec": "comune.olevanolomellina@pec.it",
      "telefono": "+39 0384/51015",
      "fax": "+39 0384/51297",
      "coordinate": {
        "lat": 45.212501525878906,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "018105",
      "nome": "Oliva Gessi",
      "nomeStraniero": null,
      "codiceCatastale": "G032",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "protocollo@comune.olivagessi.pv.it",
      "pec": "comune.olivagessi@pec.regione.lombardia.it",
      "telefono": "+39 0383876325",
      "fax": "+39 0383876142",
      "coordinate": {
        "lat": 45,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "018106",
      "nome": "Ottobiano",
      "nomeStraniero": null,
      "codiceCatastale": "G194",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comune.ottobiano.pv.it",
      "pec": "comune.ottobiano@pec.it",
      "telefono": "+39 0384 49581",
      "fax": "+39 0384 49012",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 8.833333015441895
      }
    },
    {
      "codice": "018107",
      "nome": "Palestro",
      "nomeStraniero": null,
      "codiceCatastale": "G275",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "protocollo@comune.palestro.pv.it",
      "pec": "protocollo@pec.comune.palestro.pv.it",
      "telefono": "+39 038465041",
      "fax": "+39 038465559",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 8.533333778381348
      }
    },
    {
      "codice": "018108",
      "nome": "Pancarana",
      "nomeStraniero": null,
      "codiceCatastale": "G304",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.pancarana.pv.it",
      "pec": "protocollo.pancarana@pec.it",
      "telefono": "+39 0383 375222",
      "fax": "+39 0383 75031",
      "coordinate": {
        "lat": 45.075279235839844,
        "lng": 9.051666259765625
      }
    },
    {
      "codice": "018109",
      "nome": "Parona",
      "nomeStraniero": null,
      "codiceCatastale": "G342",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "parona@comune.parona.pv.it",
      "pec": "parona.comune.pv@pec.it",
      "telefono": "+39 0384253015",
      "fax": "+39 0384253829",
      "coordinate": {
        "lat": 45.28260040283203,
        "lng": 8.750844955444336
      }
    },
    {
      "codice": "018110",
      "nome": "Pavia",
      "nomeStraniero": null,
      "codiceCatastale": "G388",
      "cap": "27100",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.pv.it",
      "pec": "protocollo@pec.comune.pavia.it",
      "telefono": "+39 0382 3991",
      "fax": "+39 0382 399227",
      "coordinate": {
        "lat": 45.185279846191406,
        "lng": 9.154999732971191
      }
    },
    {
      "codice": "018111",
      "nome": "Pietra de' Giorgi",
      "nomeStraniero": null,
      "codiceCatastale": "G612",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.pietradegiorgi.pv.it",
      "pec": "comune.pietradegiorgi@pec.it",
      "telefono": "+39 038585110",
      "fax": "+39 0385284114",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018112",
      "nome": "Pieve Albignola",
      "nomeStraniero": null,
      "codiceCatastale": "G635",
      "cap": "27030",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "comunepievealbignola@libero.it",
      "pec": "comune.pievealbignola@pec.provincia.pv.it",
      "telefono": "+39 0382999037",
      "fax": "+39 0382999352",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "018113",
      "nome": "Pieve del Cairo",
      "nomeStraniero": null,
      "codiceCatastale": "G639",
      "cap": "27037",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "demografici@comune.pievedelcairo.pv.it",
      "pec": "comunepievedelcairo@pec.it",
      "telefono": "+39 038487107",
      "fax": "+39 0384831055",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 8.800000190734863
      }
    },
    {
      "codice": "018114",
      "nome": "Pieve Porto Morone",
      "nomeStraniero": null,
      "codiceCatastale": "G650",
      "cap": "27017",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.pieveportomorone.pv.it",
      "pec": "comune.pieveportomorone@pec.regione.lombardia.it",
      "telefono": "+39 0382718034",
      "fax": "+39 0382728046",
      "coordinate": {
        "lat": 45.11027908325195,
        "lng": 9.436944007873535
      }
    },
    {
      "codice": "018115",
      "nome": "Pinarolo Po",
      "nomeStraniero": null,
      "codiceCatastale": "G671",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "compinar@libero.it",
      "pec": "mail@pec.comune.pinarolopo.pv.it",
      "telefono": "+39 0383878127",
      "fax": "+39 0383878624",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018116",
      "nome": "Pizzale",
      "nomeStraniero": null,
      "codiceCatastale": "G720",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.pizzale.pv.it",
      "pec": "comune.pizzale@pec.it",
      "telefono": "+39 0383/364774",
      "fax": "+39 0383/364775",
      "coordinate": {
        "lat": 45.03650665283203,
        "lng": 9.048212051391602
      }
    },
    {
      "codice": "018117",
      "nome": "Ponte Nizza",
      "nomeStraniero": null,
      "codiceCatastale": "G851",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.pontenizza.pv.it",
      "pec": "comune.pontenizza@legalpec.it",
      "telefono": "+39 038359131",
      "fax": "+39 0383549018",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "018118",
      "nome": "Portalbera",
      "nomeStraniero": null,
      "codiceCatastale": "G895",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.portalbera.pv.it",
      "pec": "comune.portalbera@legalpec.it",
      "telefono": "+39 0385266258",
      "fax": "+39 0385266090",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018119",
      "nome": "Rea",
      "nomeStraniero": null,
      "codiceCatastale": "H204",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "comune.rea@libero.it",
      "pec": "comune.rea@pec.regione.lombardia.it",
      "telefono": "+39 038596123",
      "fax": "+39 038596211",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "018120",
      "nome": "Redavalle",
      "nomeStraniero": null,
      "codiceCatastale": "H216",
      "cap": "27050",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "comureda@comune.redavalle.pv.it",
      "pec": "redavalle@legalpec.it",
      "telefono": "+39 038574122",
      "fax": "+39 038574676",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "018121",
      "nome": "Retorbido",
      "nomeStraniero": null,
      "codiceCatastale": "H246",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.retorbido.pv.it",
      "pec": "comune.retorbido@pec.regione.lombardia.it",
      "telefono": "+39 0383374502",
      "fax": "+39 0383374503",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "018122",
      "nome": "Rivanazzano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "H336",
      "cap": "27055",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "informazioni@comune.rivanazzanoterme.pv.it",
      "pec": "comune.rivanazzanoterme@pec.regione.lombardia.it",
      "telefono": "+39 0383 94511",
      "fax": "+39 0383 92285",
      "coordinate": {
        "lat": 44.93076705932617,
        "lng": 9.014159202575684
      }
    },
    {
      "codice": "018123",
      "nome": "Robbio",
      "nomeStraniero": null,
      "codiceCatastale": "H369",
      "cap": "27038",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comune.robbio.pv.it",
      "pec": "comune.robbio@pec.provincia.pv.it",
      "telefono": "+39 03846751",
      "fax": "+39 0384670415",
      "coordinate": {
        "lat": 45.29138946533203,
        "lng": 8.592222213745117
      }
    },
    {
      "codice": "018124",
      "nome": "Robecco Pavese",
      "nomeStraniero": null,
      "codiceCatastale": "H375",
      "cap": "27040",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "robecco.pavese@tiscali.it",
      "pec": "comune.robeccopavese@legalpec.it",
      "telefono": "+39 038384225",
      "fax": "+39 0383891707",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "018125",
      "nome": "Rocca de' Giorgi",
      "nomeStraniero": null,
      "codiceCatastale": "H396",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.roccadegiorgi.pv.it",
      "pec": "comune.roccadegiorgi@pec.provincia.pv.it",
      "telefono": "+39 0385241172",
      "fax": "+39 0385951835",
      "coordinate": {
        "lat": 44.95000076293945,
        "lng": 9.25
      }
    },
    {
      "codice": "018126",
      "nome": "Rocca Susella",
      "nomeStraniero": null,
      "codiceCatastale": "H450",
      "cap": "27052",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comuneroccasusella@virgilio.it",
      "pec": "comune.roccasusella@pec.regione.lombardia.it",
      "telefono": "+39 038399004",
      "fax": "+39 038399200",
      "coordinate": {
        "lat": 44.912925720214844,
        "lng": 9.095880508422852
      }
    },
    {
      "codice": "018127",
      "nome": "Rognano",
      "nomeStraniero": null,
      "codiceCatastale": "H491",
      "cap": "27012",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.rognano.pv.it",
      "pec": "postacertificata@pec.comune.rognano.pv.it",
      "telefono": "+39 0382926172",
      "fax": "+39 0382926207",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018128",
      "nome": "Romagnese",
      "nomeStraniero": null,
      "codiceCatastale": "H505",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comune.romagnese@virgilio.it",
      "pec": "comune.romagnese@pec.regione.lombardia.it",
      "telefono": "+39 0383 580001",
      "fax": "+39 0383 580588",
      "coordinate": {
        "lat": 44.83333206176758,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "018129",
      "nome": "Roncaro",
      "nomeStraniero": null,
      "codiceCatastale": "H527",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.roncaro.pv.it",
      "pec": "comune.roncaro@halleycert.it",
      "telefono": "+39 038294293",
      "fax": "+39 0382944782",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "018130",
      "nome": "Rosasco",
      "nomeStraniero": null,
      "codiceCatastale": "H559",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "comune.rosasco@lomellina.tv",
      "pec": "comune.rosasco@pec.regione.lombardia.it",
      "telefono": "+39 0384673833",
      "fax": "+39 0384673672",
      "coordinate": {
        "lat": 45.251060485839844,
        "lng": 8.578937530517578
      }
    },
    {
      "codice": "018131",
      "nome": "Rovescala",
      "nomeStraniero": null,
      "codiceCatastale": "H614",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.rovescala.pv.it",
      "pec": "protocollo@pec.comune.rovescala.pv.it",
      "telefono": "+39 0385277281",
      "fax": "+39 0382241822",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "018133",
      "nome": "San Cipriano Po",
      "nomeStraniero": null,
      "codiceCatastale": "H799",
      "cap": "27043",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.sanciprianopo.pv.it",
      "pec": "sanciprianopo@postemailcertificata.it",
      "telefono": "+39 0385 - 241708",
      "fax": "+39 0385 - 241737",
      "coordinate": {
        "lat": 45.099998474121094,
        "lng": 9.25
      }
    },
    {
      "codice": "018134",
      "nome": "San Damiano al Colle",
      "nomeStraniero": null,
      "codiceCatastale": "H814",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "protocollo@comune.sandamianoalcolle.pv.it",
      "pec": "comune.sandamiano@pec.provincia.pv.it",
      "telefono": "+39 038575014",
      "fax": "+39 038575014",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "018135",
      "nome": "San Genesio ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "H859",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.sangenesio.pv.it",
      "pec": "comune.sangenesio@pec.regione.lombardia.it",
      "telefono": "0382586023",
      "fax": "0382586163",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "018136",
      "nome": "San Giorgio di Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "H885",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "demografici@comune.sangiorgiodilomellina.pv.it",
      "pec": "comune.sangiorgiodilomellina@pec.regione.lombardia.it",
      "telefono": "+39 038443010",
      "fax": "+39 038443562",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 8.766666412353516
      }
    },
    {
      "codice": "018137",
      "nome": "San Martino Siccomario",
      "nomeStraniero": null,
      "codiceCatastale": "I014",
      "cap": "27028",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.sanmartino.pv.it",
      "pec": "protocollo@pec.comune.sanmartino.pv.it",
      "telefono": "+39 038249611",
      "fax": "+39 0382498507",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018145",
      "nome": "San Zenone al Po",
      "nomeStraniero": null,
      "codiceCatastale": "I416",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.sanzenonealpo.pv.it",
      "pec": "comune.sanzenonealpo@pec.regione.lombardia.it",
      "telefono": "+39 038279015",
      "fax": "+39 0382729733",
      "coordinate": {
        "lat": 45.06666564941406,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "018138",
      "nome": "Sannazzaro de' Burgondi",
      "nomeStraniero": null,
      "codiceCatastale": "I048",
      "cap": "27039",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.sannazzarodeburgondi.pv.it",
      "pec": "protocollo.comunesannazzarodeburgondi@pec.it",
      "telefono": "+39 0382 995611",
      "fax": "+39 0382 901264",
      "coordinate": {
        "lat": 45.10388946533203,
        "lng": 8.908888816833496
      }
    },
    {
      "codice": "018141",
      "nome": "Sant'Alessio con Vialone",
      "nomeStraniero": null,
      "codiceCatastale": "I213",
      "cap": "27016",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.santalessioconvialone.pv.it",
      "pec": "protocollo@pec.comune.santalessioconvialone.pv.it",
      "telefono": "+39 038294029",
      "fax": "+39 0382953814",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "018144",
      "nome": "Sant'Angelo Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "I276",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "municipio@comune.santangelolomellina.pv.it",
      "pec": "comune.santangelolomellina@pec.regione.lombardia.it",
      "telefono": "+39 038455012",
      "fax": "+39 038455313",
      "coordinate": {
        "lat": 45.24611282348633,
        "lng": 8.644444465637207
      }
    },
    {
      "codice": "018139",
      "nome": "Santa Cristina e Bissone",
      "nomeStraniero": null,
      "codiceCatastale": "I175",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.santacristinaebissone.pv.it",
      "pec": "comune.santacristinaebissone@pec.regione.lombardia.it",
      "telefono": "+39 038270121",
      "fax": "+39 038271351",
      "coordinate": {
        "lat": 45.15755844116211,
        "lng": 9.399760246276855
      }
    },
    {
      "codice": "018140",
      "nome": "Santa Giuletta",
      "nomeStraniero": null,
      "codiceCatastale": "I203",
      "cap": "27046",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.santagiuletta.pv.it",
      "pec": "comune.santagiuletta@pec.regione.lombardia.it",
      "telefono": "+39 0383 899141",
      "fax": "+39 0383 899796",
      "coordinate": {
        "lat": 45.06027603149414,
        "lng": 9.304166793823242
      }
    },
    {
      "codice": "018142",
      "nome": "Santa Margherita di Staffora",
      "nomeStraniero": null,
      "codiceCatastale": "I230",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "comune.smargherita@tiscali.it",
      "pec": "comune.santamargheritadistaffora@pec.regione.lombardia.it",
      "telefono": "+39 0383551100",
      "fax": "+39 0383552914",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "018143",
      "nome": "Santa Maria della Versa",
      "nomeStraniero": null,
      "codiceCatastale": "I237",
      "cap": "27047",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "info@comune.santa-maria-della-versa.pv.it",
      "pec": "santamariadellaversa@postemailcertificata.it",
      "telefono": "+39 0385278220",
      "fax": "+39 038579622",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "018146",
      "nome": "Sartirana Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "I447",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "info@comune.sartiranalomellina.pv.it",
      "pec": "comune.sartiranalomellina@pec.it",
      "telefono": "+39 0384800810",
      "fax": "+39 0384800117",
      "coordinate": {
        "lat": 45.11555480957031,
        "lng": 8.665277481079102
      }
    },
    {
      "codice": "018147",
      "nome": "Scaldasole",
      "nomeStraniero": null,
      "codiceCatastale": "I487",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "demografici@scaldasole.lombardia.it",
      "pec": "comune.scaldasole@pec.regione.lombardia.it",
      "telefono": "+39 0382997454",
      "fax": null,
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "018148",
      "nome": "Semiana",
      "nomeStraniero": null,
      "codiceCatastale": "I599",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "info@comunesemiana.pv.it",
      "pec": "semiana@postemailcertificata.it",
      "telefono": "+39 0384804615",
      "fax": "+39 0384804979",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 8.733333587646484
      }
    },
    {
      "codice": "018149",
      "nome": "Silvano Pietra",
      "nomeStraniero": null,
      "codiceCatastale": "I739",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.silvanopietra.pv.it",
      "pec": "comune.silvanopietra@legalpec.it",
      "telefono": "+39 0383 78204",
      "fax": "+39 0383 78300",
      "coordinate": {
        "lat": 45.03333282470703,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "018150",
      "nome": "Siziano",
      "nomeStraniero": null,
      "codiceCatastale": "E265",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.siziano.pv.it",
      "pec": "info@pec.comune.siziano.pv.it",
      "telefono": "+39 038267801",
      "fax": "+39 0382617660",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018151",
      "nome": "Sommo",
      "nomeStraniero": null,
      "codiceCatastale": "I825",
      "cap": "27048",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "sommo@postemailcertificata.it",
      "pec": "comune.sommo@pec.provincia.pv.it",
      "telefono": "+39 0382 402019",
      "fax": "+39 0382 402308",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018152",
      "nome": "Spessa",
      "nomeStraniero": null,
      "codiceCatastale": "I894",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.spessa.pv.it",
      "pec": "comune.spessa@pec.regione.lombardia.it",
      "telefono": "+39 0382 79032",
      "fax": "+39 0382 729702",
      "coordinate": {
        "lat": 45.11666488647461,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "018153",
      "nome": "Stradella",
      "nomeStraniero": null,
      "codiceCatastale": "I968",
      "cap": "27049",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "elettorale@comune.stradella.pv.it",
      "pec": "comune.stradella@pec.regione.lombardia.it",
      "telefono": "+39 0385/249234",
      "fax": "+39 0385/43590",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "018154",
      "nome": "Suardi",
      "nomeStraniero": null,
      "codiceCatastale": "B014",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "info@comunesuardi.it",
      "pec": "comunesuardi@pec.it",
      "telefono": "+39 0384 89031",
      "fax": "+39 0384 8927174",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 8.716666221618652
      }
    },
    {
      "codice": "018155",
      "nome": "Torrazza Coste",
      "nomeStraniero": null,
      "codiceCatastale": "L237",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "segreteria@comune.torrazzacoste.pv.it",
      "pec": "amministrativo.torrazzacoste@legalpec.it",
      "telefono": "+39 0383 77001",
      "fax": "+39 0383 77585",
      "coordinate": {
        "lat": 44.983333587646484,
        "lng": 9.066666603088379
      }
    },
    {
      "codice": "018156",
      "nome": "Torre Beretti e Castellaro",
      "nomeStraniero": null,
      "codiceCatastale": "L250",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "torreberetti@libero.it",
      "pec": "comune.torreberetti@pec.it",
      "telefono": "+39 038484132",
      "fax": "+39 0384849975",
      "coordinate": {
        "lat": 45.05995559692383,
        "lng": 8.670943260192871
      }
    },
    {
      "codice": "018157",
      "nome": "Torre d'Arese",
      "nomeStraniero": null,
      "codiceCatastale": "L256",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.torredarese.pv.it",
      "pec": "comune.torredarese@pec.regione.lombardia.it",
      "telefono": "+39 0382 947033",
      "fax": "+39 0382 947392",
      "coordinate": {
        "lat": 45.25,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018159",
      "nome": "Torre d'Isola",
      "nomeStraniero": null,
      "codiceCatastale": "L269",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.torredisola.pv.it",
      "pec": "comune@pec.comune.torredisola.pv.it",
      "telefono": "+39 0382 407021",
      "fax": "+39 0382 407544",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "018158",
      "nome": "Torre de' Negri",
      "nomeStraniero": null,
      "codiceCatastale": "L262",
      "cap": "27011",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.torredenegri.pv.it",
      "pec": "comune.torredenegri@pec.regione.lombardia.it",
      "telefono": "+39 0382969303",
      "fax": "+39 0382969398",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "018160",
      "nome": "Torrevecchia Pia",
      "nomeStraniero": null,
      "codiceCatastale": "L285",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "demografico@comune.torrevecchiapia.pv.it",
      "pec": "comune.torrevecchiapia@pec.regione.lombardia.it",
      "telefono": "+39 038268502",
      "fax": "+39 038268339",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "018161",
      "nome": "Torricella Verzate",
      "nomeStraniero": null,
      "codiceCatastale": "L292",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.torricellaverzate.pv.it",
      "pec": "protocollo@pec.comune.torricellaverzate.pv.it",
      "telefono": "+39 0383.896030",
      "fax": "+39 0383.896705",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "018162",
      "nome": "Travacò Siccomario",
      "nomeStraniero": null,
      "codiceCatastale": "I236",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.travacosiccomario.pv.it",
      "pec": "protocollo@pec.comune.travacosiccomario.pv.it",
      "telefono": "+39 0382482230",
      "fax": "+39 0382482303",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "018163",
      "nome": "Trivolzio",
      "nomeStraniero": null,
      "codiceCatastale": "L440",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.trivolzio.pv.it",
      "pec": "comune.trivolzio@pec.regione.lombardia.it",
      "telefono": "+39 0382928041",
      "fax": "+39 0382930485",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "018164",
      "nome": "Tromello",
      "nomeStraniero": null,
      "codiceCatastale": "L449",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "demografico@comune.tromello.pv.it",
      "pec": "comune.tromello@legalmail.it",
      "telefono": "+39 038286021",
      "fax": "+39 0382868370",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "018165",
      "nome": "Trovo",
      "nomeStraniero": null,
      "codiceCatastale": "L453",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.trovo.pv.it",
      "pec": "comune.trovo@pec.regione.lombardia.it",
      "telefono": "+39 0382 928051",
      "fax": "+39 0382 930584",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "018166",
      "nome": "Val di Nizza",
      "nomeStraniero": null,
      "codiceCatastale": "L562",
      "cap": "27050",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "info@comune.valdinizza.pv.it",
      "pec": "comune.valdinizza@legalpec.it",
      "telefono": "+39 0383 578018",
      "fax": "+39 0383 211091",
      "coordinate": {
        "lat": 44.88333511352539,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "018167",
      "nome": "Valeggio",
      "nomeStraniero": null,
      "codiceCatastale": "L568",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "valeggio@libero.it",
      "pec": "comune.valeggio@pec.regione.lombardia.it",
      "telefono": "+39 038449052",
      "fax": "+39 038449052",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "018168",
      "nome": "Valle Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "L593",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "anagrafe@comune.vallelomellina.pv.it",
      "pec": "anagrafe.comune.vallelomellina@pec.it",
      "telefono": "+39 0384/79001",
      "fax": "+39 0384/79017",
      "coordinate": {
        "lat": 45.150001525878906,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "018169",
      "nome": "Valle Salimbene",
      "nomeStraniero": null,
      "codiceCatastale": "L617",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "staff@vallesalimbene.org",
      "pec": "staff@pec.vallesalimbene.org",
      "telefono": "+39 0382 485036",
      "fax": "+39 0382 587081",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "018171",
      "nome": "Varzi",
      "nomeStraniero": null,
      "codiceCatastale": "L690",
      "cap": "27057",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "demografico@comune.varzi.pv.it",
      "pec": "protocollo@pec.comune.varzi.pv.it",
      "telefono": "+39 0383/52003",
      "fax": "+39 0383/52180",
      "coordinate": {
        "lat": 44.81666564941406,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018172",
      "nome": "Velezzo Lomellina",
      "nomeStraniero": null,
      "codiceCatastale": "L716",
      "cap": "27020",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "municipio@comune.velezzolomellina.pv.it",
      "pec": "comune.velezzolomellina@pec.it",
      "telefono": "+39 0384/43072",
      "fax": "+39 0384/439943",
      "coordinate": {
        "lat": 45.16222381591797,
        "lng": 8.735555648803711
      }
    },
    {
      "codice": "018173",
      "nome": "Vellezzo Bellini",
      "nomeStraniero": null,
      "codiceCatastale": "L720",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "comune@comune.vellezzobellini.pv.it",
      "pec": "comune@pec.comune.vellezzobellini.pv.it",
      "telefono": "+39 0382/926014",
      "fax": "+39 0382/926395",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "018174",
      "nome": "Verretto",
      "nomeStraniero": null,
      "codiceCatastale": "L784",
      "cap": "27053",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "bello@comune.verretto.pv.it",
      "pec": "comune.verretto@legalpec.it",
      "telefono": "+39 0383.84570",
      "fax": "+39 0383.84480",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018175",
      "nome": "Verrua Po",
      "nomeStraniero": null,
      "codiceCatastale": "L788",
      "cap": "27040",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "anagrafe.verruapo@libero.it",
      "pec": "comune.verruapo@pec.regione.lombardia.it",
      "telefono": "+39 0385/96121",
      "fax": "+39 0385/96447",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "018176",
      "nome": "Vidigulfo",
      "nomeStraniero": null,
      "codiceCatastale": "L854",
      "cap": "27018",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "protocollo@comune.vidigulfo.pv.it",
      "pec": "info@pec.comune.vidigulfo.pv.it",
      "telefono": "+39 0382-69003/4",
      "fax": "+39 0382-619125",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "018177",
      "nome": "Vigevano",
      "nomeStraniero": null,
      "codiceCatastale": "L872",
      "cap": "27029",
      "prefisso": "0381",
      "provincia": "Pavia",
      "email": "info@comune.vigevano.pv.it",
      "pec": "protocollovigevano@pec.it",
      "telefono": "+39 0381 299800 0381299801",
      "fax": "+39 0381 71101",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 8.866666793823242
      }
    },
    {
      "codice": "018178",
      "nome": "Villa Biscossi",
      "nomeStraniero": null,
      "codiceCatastale": "L917",
      "cap": "27035",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "villa.biscossi@libero.it",
      "pec": "comune.villabiscossi@pec.it",
      "telefono": "+39 0384/820584",
      "fax": "+39 0384/805708",
      "coordinate": {
        "lat": 45.08333206176758,
        "lng": 8.783333778381348
      }
    },
    {
      "codice": "018179",
      "nome": "Villanova d'Ardenghi",
      "nomeStraniero": null,
      "codiceCatastale": "L983",
      "cap": "27030",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.villanovadardenghi.pv.it",
      "pec": "comune.villanovadardenghi@pec.it",
      "telefono": "+39 0382/400022",
      "fax": "+39 0382/400646",
      "coordinate": {
        "lat": 45.16666793823242,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "018180",
      "nome": "Villanterio",
      "nomeStraniero": null,
      "codiceCatastale": "L994",
      "cap": "27019",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "anagrafe@comune.villanterio.pv.it",
      "pec": "anagrafe.comune.villanterio@pec.regione.lombardia.it",
      "telefono": "+39 0382967122",
      "fax": "+39 0382967611",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "018181",
      "nome": "Vistarino",
      "nomeStraniero": null,
      "codiceCatastale": "M079",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "segreteria@comune.vistarino.pv.it",
      "pec": "comune.vistarino@pec.regione.lombardia.it",
      "telefono": "+39 0382-968144",
      "fax": "+39 0382-968927",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 9.316666603088379
      }
    },
    {
      "codice": "018182",
      "nome": "Voghera",
      "nomeStraniero": null,
      "codiceCatastale": "M109",
      "cap": "27058",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "protocollo@comune.voghera.pv.it",
      "pec": "protocollo@cert.comune.voghera.pv.it",
      "telefono": "+39 0383-3361",
      "fax": "+39 0383-62868",
      "coordinate": {
        "lat": 44.99250030517578,
        "lng": 9.009166717529297
      }
    },
    {
      "codice": "018183",
      "nome": "Volpara",
      "nomeStraniero": null,
      "codiceCatastale": "M119",
      "cap": "27047",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "protocollo@comune.volpara.pv.it",
      "pec": "protocollo@pec.comune.volpara.pv.it",
      "telefono": "+39 038599729",
      "fax": "+39 038599729",
      "coordinate": {
        "lat": 44.953609466552734,
        "lng": 9.297499656677246
      }
    },
    {
      "codice": "018184",
      "nome": "Zavattarello",
      "nomeStraniero": null,
      "codiceCatastale": "M150",
      "cap": "27059",
      "prefisso": "0383",
      "provincia": "Pavia",
      "email": "zavattarello@libero.it",
      "pec": "comune.zavattarello@pec.regione.lombardia.it",
      "telefono": "+39 0383 589132 746",
      "fax": "+39 0383 589132 746",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "018185",
      "nome": "Zeccone",
      "nomeStraniero": null,
      "codiceCatastale": "M152",
      "cap": "27010",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.zeccone.pv.it",
      "pec": "comune.zeccone@pec.provincia.pv.it",
      "telefono": "+39 0382955021",
      "fax": "+39 0382955395",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "018186",
      "nome": "Zeme",
      "nomeStraniero": null,
      "codiceCatastale": "M161",
      "cap": "27030",
      "prefisso": "0384",
      "provincia": "Pavia",
      "email": "comunezeme@libero.it",
      "pec": "zeme@postemailcertificata.it",
      "telefono": "+39 0384 54021",
      "fax": "+39 0384 546914",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "018187",
      "nome": "Zenevredo",
      "nomeStraniero": null,
      "codiceCatastale": "M162",
      "cap": "27049",
      "prefisso": "0385",
      "provincia": "Pavia",
      "email": "protocollo@comune.zenevredo.pv.it",
      "pec": "comune.zenevredo@pec.provincia.pv.it",
      "telefono": "+39 0385 241993",
      "fax": "+39 0385 48401",
      "coordinate": {
        "lat": 45.04999923706055,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "018188",
      "nome": "Zerbo",
      "nomeStraniero": null,
      "codiceCatastale": "M166",
      "cap": "27017",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "comunezerbo@libero.it",
      "pec": "comune.zerbo@pec.provincia.pv.it",
      "telefono": "+39 0382-79017",
      "fax": "+39 0382-729693",
      "coordinate": {
        "lat": 45.1104621887207,
        "lng": 9.396058082580566
      }
    },
    {
      "codice": "018189",
      "nome": "Zerbolò",
      "nomeStraniero": null,
      "codiceCatastale": "M167",
      "cap": "27020",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "demografici@comune.zerbolo.pv.it",
      "pec": "protocollo@pec.comune.zerbolo.pv.it",
      "telefono": "+39 0382/818672",
      "fax": "+39 0382/818771",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "018190",
      "nome": "Zinasco",
      "nomeStraniero": null,
      "codiceCatastale": "M180",
      "cap": "27030",
      "prefisso": "0382",
      "provincia": "Pavia",
      "email": "info@comune.zinasco.pv.it",
      "pec": "comune.zinasco@pec.provincia.pv.it",
      "telefono": "+39 0382 91016",
      "fax": "+39 0382 915135",
      "coordinate": {
        "lat": 45.13333511352539,
        "lng": 9.050000190734863
      }
    }
  ],
  "Vercelli": [
    {
      "codice": "002002",
      "nome": "Alagna Valsesia",
      "nomeStraniero": null,
      "codiceCatastale": "A119",
      "cap": "13021",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "alagna.valsesia@ruparpiemonte.it",
      "pec": "alagnavalsesia@cert.ruparpiemonte.it",
      "telefono": "+39 0163 922944",
      "fax": "+39 0163 91420",
      "coordinate": {
        "lat": 45.85388946533203,
        "lng": 7.937222003936768
      }
    },
    {
      "codice": "002003",
      "nome": "Albano Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "A130",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "albano.vercellese@libero.it",
      "pec": "albano.vercellese@cert.ruparpiemonte.it",
      "telefono": "+39 0161 73113",
      "fax": "+39 0161 734228",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002004",
      "nome": "Alice Castello",
      "nomeStraniero": null,
      "codiceCatastale": "A198",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "segreteria.alice.castello@ruparpiemonte.it",
      "pec": "alice.castello@legalmail.it",
      "telefono": "+39 016190113",
      "fax": "+39 016190868",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "002170",
      "nome": "Alto Sermenza",
      "nomeStraniero": null,
      "codiceCatastale": "M389",
      "cap": "13026",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "protocollo@comune.altosermenza.vc.it",
      "pec": "altosermenza@pcert.it",
      "telefono": "0163/95125",
      "fax": "0163/95125",
      "coordinate": {
        "lat": 45.883331298828125,
        "lng": 8
      }
    },
    {
      "codice": "002006",
      "nome": "Arborio",
      "nomeStraniero": null,
      "codiceCatastale": "A358",
      "cap": "13031",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "arborio@mclink.it",
      "pec": "arborio@cert.ruparpiemonte.it",
      "telefono": "+39 0161 86114",
      "fax": "+39 0161 86500",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002007",
      "nome": "Asigliano Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "A466",
      "cap": "13032",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "asigliano.vercellese@reteunitaria.piemonte.it",
      "pec": "asigliano.vercellese@cert.ruparpiemonte.it",
      "telefono": "+39 0161 36121",
      "fax": "+39 0161 36489",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "002008",
      "nome": "Balmuccia",
      "nomeStraniero": null,
      "codiceCatastale": "A600",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "balmuccia@ruparpiemonte.it",
      "pec": "balmuccia@cert.ruparpiemonte.it",
      "telefono": "+39 0163/735945",
      "fax": "+39 0163/735945",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.133333206176758
      }
    },
    {
      "codice": "002009",
      "nome": "Balocco",
      "nomeStraniero": null,
      "codiceCatastale": "A601",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comunedibalocco@virgilio.it",
      "pec": "comune.balocco.vc@legalmail.it",
      "telefono": "+39 0161853144",
      "fax": "+39 0161853213",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.283333778381348
      }
    },
    {
      "codice": "002011",
      "nome": "Bianzè",
      "nomeStraniero": null,
      "codiceCatastale": "A847",
      "cap": "13041",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "bianze@ruparpiemonte.it",
      "pec": "bianze@legalmail.it",
      "telefono": "+39 0161 49133-49820",
      "fax": "+39 0161 49433",
      "coordinate": {
        "lat": 45.29999923706055,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "002014",
      "nome": "Boccioleto",
      "nomeStraniero": null,
      "codiceCatastale": "A914",
      "cap": "13022",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "boccioleto@ruparpiemonte.piemonte.it",
      "pec": "boccioleto@cert.ruparpiemonte.it",
      "telefono": "+39 0163.75127",
      "fax": "+39 0163.753900",
      "coordinate": {
        "lat": 45.83000183105469,
        "lng": 8.113333702087402
      }
    },
    {
      "codice": "002015",
      "nome": "Borgo d'Ale",
      "nomeStraniero": null,
      "codiceCatastale": "B009",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "demografici@comuneborgodale.info",
      "pec": "protocollo.borgo.dale@cert.ruparpiemonte.it",
      "telefono": "+39 0161/46132",
      "fax": "+39 0161/468030",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "002017",
      "nome": "Borgo Vercelli",
      "nomeStraniero": null,
      "codiceCatastale": "B046",
      "cap": "13012",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "protocollo@comuneborgovercelli.it",
      "pec": "protocollo.comuneborgovercelli@legalmail.it",
      "telefono": "+39 016132136",
      "fax": "+39 016132777",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "002016",
      "nome": "Borgosesia",
      "nomeStraniero": null,
      "codiceCatastale": "B041",
      "cap": "13011",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "anagrafe.borgosesia@ruparpiemonte.it",
      "pec": "protocollo.borgosesia@cert.ruparpiemonte.it",
      "telefono": "+39 0163/290140",
      "fax": "+39 0163/26693",
      "coordinate": {
        "lat": 45.71694564819336,
        "lng": 8.276389122009277
      }
    },
    {
      "codice": "002021",
      "nome": "Buronzo",
      "nomeStraniero": null,
      "codiceCatastale": "B280",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "ufficioanagrafe.buronzo@ruparpiemonte.it",
      "pec": "buronzo@cert.ruparpiemonte.it",
      "telefono": "+39 0161/851134",
      "fax": "+39 0161/851376",
      "coordinate": {
        "lat": 45.483333587646484,
        "lng": 8.266666412353516
      }
    },
    {
      "codice": "002025",
      "nome": "Campertogno",
      "nomeStraniero": null,
      "codiceCatastale": "B505",
      "cap": "13023",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "sindaco.campertogno@ruparpiemonte.it",
      "pec": "segretariocomunale.campertogno@cert.ruparpiemonte.it",
      "telefono": "+39 0163/77122",
      "fax": "+39 0163/775921",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "002029",
      "nome": "Carcoforo",
      "nomeStraniero": null,
      "codiceCatastale": "B752",
      "cap": "13026",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "sindaco.rimasco@reteunitaria.piemonte.it",
      "pec": "carcoforo@reteunitaria.piemonte.it",
      "telefono": "+39 0163/95125",
      "fax": "+39 0163/95125",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "002030",
      "nome": "Caresana",
      "nomeStraniero": null,
      "codiceCatastale": "B767",
      "cap": "13010",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "caresana@comuniubv.it",
      "pec": "caresana@pec.it",
      "telefono": "+39 0161/78112",
      "fax": "+39 0161/78453",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 8.5
      }
    },
    {
      "codice": "002031",
      "nome": "Caresanablot",
      "nomeStraniero": null,
      "codiceCatastale": "B768",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comune_caresanablot@libero.it",
      "pec": "protocollo@pec.comune.caresanablot.vc.it",
      "telefono": "+39 0161/33098",
      "fax": "+39 0161/235401",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002032",
      "nome": "Carisio",
      "nomeStraniero": null,
      "codiceCatastale": "B782",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "sindaco.carisio@reteunitaria.piemonte.it",
      "pec": "segretariocomunale.carisio@cert.ruparpiemonte.it",
      "telefono": "+39 0161/971014",
      "fax": "+39 0161/972813",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 8.199999809265137
      }
    },
    {
      "codice": "002033",
      "nome": "Casanova Elvo",
      "nomeStraniero": null,
      "codiceCatastale": "B928",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "casanova.elvo@ruparpiemonte.it",
      "pec": "casanova.elvo@cert.ruparpiemonte.it",
      "telefono": "+39 0161/274922",
      "fax": "+39 0161/274995",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.300000190734863
      }
    },
    {
      "codice": "002171",
      "nome": "Cellio con Breia",
      "nomeStraniero": null,
      "codiceCatastale": "M398",
      "cap": "13024",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "cellioconbreia@ruparpiemonte.it",
      "pec": "cellioconbreia@pcert.it",
      "telefono": "+39 016349121",
      "fax": "+39 016349632",
      "coordinate": {
        "lat": 45.75709915161133,
        "lng": 8.311699867248535
      }
    },
    {
      "codice": "002041",
      "nome": "Cervatto",
      "nomeStraniero": null,
      "codiceCatastale": "C548",
      "cap": "13025",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "cervatto@reteunitaria.piemonte.it",
      "pec": "cervatto@cert.ruparpiemonte.it",
      "telefono": "+39 0163/561900",
      "fax": "+39 0163/55124",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 8.166666984558105
      }
    },
    {
      "codice": "002042",
      "nome": "Cigliano",
      "nomeStraniero": null,
      "codiceCatastale": "C680",
      "cap": "13043",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "demografici@cigliano.net",
      "pec": "cigliano@cert.ruparpiemonte.it",
      "telefono": "+39 0161/423142",
      "fax": "+39 0161/433988",
      "coordinate": {
        "lat": 45.30835723876953,
        "lng": 8.021024703979492
      }
    },
    {
      "codice": "002043",
      "nome": "Civiasco",
      "nomeStraniero": null,
      "codiceCatastale": "C757",
      "cap": "13010",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "municipio@comune.civiasco.vc.it",
      "pec": "comune.civiasco.vc@legalmail.it",
      "telefono": "+39 0163/55700",
      "fax": "+39 0163/55700",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.300000190734863
      }
    },
    {
      "codice": "002045",
      "nome": "Collobiano",
      "nomeStraniero": null,
      "codiceCatastale": "C884",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "collobiano@reteunitaria.piemonte.it",
      "pec": "protocollo@pec.comune.collobiano.vc.it",
      "telefono": "+39 0161/275004",
      "fax": "+39 0161/274157",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.350000381469727
      }
    },
    {
      "codice": "002047",
      "nome": "Costanzana",
      "nomeStraniero": null,
      "codiceCatastale": "D113",
      "cap": "13033",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "costanzana@comuniubv.it",
      "pec": "costanzana@pec.it",
      "telefono": "+39 0161312112",
      "fax": "+39 0161312316",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 8.366666793823242
      }
    },
    {
      "codice": "002048",
      "nome": "Cravagliana",
      "nomeStraniero": null,
      "codiceCatastale": "D132",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "cravagliana@reteunitaria.piemonte.it",
      "pec": "cravagliana@cert.ruparpiemonte.it",
      "telefono": "+39 0163/55517",
      "fax": "+39 0163/55554",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 8.199999809265137
      }
    },
    {
      "codice": "002049",
      "nome": "Crescentino",
      "nomeStraniero": null,
      "codiceCatastale": "D154",
      "cap": "13044",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "crescentino@reteunitaria.piemonte.it",
      "pec": "crescentino@cert.ruparpiemonte.it",
      "telefono": "+39 0161 833111",
      "fax": "+39 0161.842183",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "002052",
      "nome": "Crova",
      "nomeStraniero": null,
      "codiceCatastale": "D187",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "crova@ruparpiemonte.it",
      "pec": "comune.crova.vc@cert.legalmail.it",
      "telefono": "+39 0161/970114",
      "fax": "+39 0161/979914",
      "coordinate": {
        "lat": 45.330657958984375,
        "lng": 8.210827827453613
      }
    },
    {
      "codice": "002054",
      "nome": "Desana",
      "nomeStraniero": null,
      "codiceCatastale": "D281",
      "cap": "13034",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comune.desana@reteunitaria.piemonte.it",
      "pec": "desana@cert.ruparpiemonte.it",
      "telefono": "+39 0161/318133",
      "fax": "+39 0161/316991",
      "coordinate": {
        "lat": 45.266666412353516,
        "lng": 8.350000381469727
      }
    },
    {
      "codice": "002057",
      "nome": "Fobello",
      "nomeStraniero": null,
      "codiceCatastale": "D641",
      "cap": "13025",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "fobello@reteunitaria.piemonte.it",
      "pec": "fobello@cert.ruparpiemonte.it",
      "telefono": "+39 0163/561900",
      "fax": "+39 0163/55124",
      "coordinate": {
        "lat": 45.889862060546875,
        "lng": 8.158361434936523
      }
    },
    {
      "codice": "002058",
      "nome": "Fontanetto Po",
      "nomeStraniero": null,
      "codiceCatastale": "D676",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "fontanetto.po@reteunitaria.piemonte.it",
      "pec": "fontanetto.po@cert.ruparpiemonte.it",
      "telefono": "+39 0161/840114",
      "fax": "+39 0161/840564",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "002059",
      "nome": "Formigliana",
      "nomeStraniero": null,
      "codiceCatastale": "D712",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "formigliana@reteunitaria.piemonte.it",
      "pec": "formigliana@cert.ruparpiemonte.it",
      "telefono": "+39 0161/855125",
      "fax": "+39 0161/855106",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.283333778381348
      }
    },
    {
      "codice": "002061",
      "nome": "Gattinara",
      "nomeStraniero": null,
      "codiceCatastale": "D938",
      "cap": "13045",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "anagrafe@comune.gattinara.vc.it",
      "pec": "anagrafe.gattinara@pec.it",
      "telefono": "+39 0163824303",
      "fax": "+39 0163833231",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.366666793823242
      }
    },
    {
      "codice": "002062",
      "nome": "Ghislarengo",
      "nomeStraniero": null,
      "codiceCatastale": "E007",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "ghislarengo@ruparpiemonte.it",
      "pec": "ghislarengo@cert.ruparpiemonte.it",
      "telefono": "+39 0161/860155",
      "fax": "+39 0161/860509",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002065",
      "nome": "Greggio",
      "nomeStraniero": null,
      "codiceCatastale": "E163",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "greggio@reteunitaria.piemonte.it",
      "pec": "greggio@cert.ruparpiemonte.it",
      "telefono": "+39 0161/730123",
      "fax": "+39 0161/720991",
      "coordinate": {
        "lat": 45.45000076293945,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002066",
      "nome": "Guardabosone",
      "nomeStraniero": null,
      "codiceCatastale": "E237",
      "cap": "13010",
      "prefisso": "015",
      "provincia": "Vercelli",
      "email": "comuneguardabosone@libero.it",
      "pec": "tributi.guardabosone@anutel.it",
      "telefono": "+39 015/761118",
      "fax": "+39 015/7613928",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.25
      }
    },
    {
      "codice": "002067",
      "nome": "Lamporo",
      "nomeStraniero": null,
      "codiceCatastale": "E433",
      "cap": "13046",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "uffici@comune.lamporo.vc.it",
      "pec": "lamporo@cert.ruparpiemonte.it",
      "telefono": "+39 0161/848101",
      "fax": "+39 0161/848144",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "002068",
      "nome": "Lenta",
      "nomeStraniero": null,
      "codiceCatastale": "E528",
      "cap": "13035",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "lenta@reteunitaria.piemonte.it",
      "pec": "lenta@cert.ruparpiemonte.it",
      "telefono": "+39 0163/88118",
      "fax": "+39 0163/88431",
      "coordinate": {
        "lat": 45.54999923706055,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002070",
      "nome": "Lignana",
      "nomeStraniero": null,
      "codiceCatastale": "E583",
      "cap": "13034",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "lignana@reteunitaria.piemonte.it",
      "pec": "lignana@cert.ruparpiemonte.it",
      "telefono": "+39 0161/314126",
      "fax": "+39 0161/314310",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 8.350000381469727
      }
    },
    {
      "codice": "002071",
      "nome": "Livorno Ferraris",
      "nomeStraniero": null,
      "codiceCatastale": "E626",
      "cap": "13046",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "anagrafe@comune.livornoferraris.vc.it",
      "pec": "info@pec.livornof.it",
      "telefono": "+39 0161/477295",
      "fax": "+39 0161/477770",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "002072",
      "nome": "Lozzolo",
      "nomeStraniero": null,
      "codiceCatastale": "E711",
      "cap": "13045",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "anagrafe@comune.lozzolo.vc.it",
      "pec": "municipio@pec.comune.lozzolo.vc.it",
      "telefono": "+39 0163/89136",
      "fax": null,
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 8.316666603088379
      }
    },
    {
      "codice": "002078",
      "nome": "Mollia",
      "nomeStraniero": null,
      "codiceCatastale": "F297",
      "cap": "13023",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "mollia@reteunitaria.piemonte.it",
      "pec": "mollia@cert.ruparpiemonte.it",
      "telefono": "+39 0163/77102",
      "fax": "+39 0163/77102",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.033333778381348
      }
    },
    {
      "codice": "002079",
      "nome": "Moncrivello",
      "nomeStraniero": null,
      "codiceCatastale": "F342",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "demografici@comune.moncrivello.vc.it",
      "pec": "moncrivello@legalmail.it",
      "telefono": "+39 0161/401177",
      "fax": "+39 0161/401204",
      "coordinate": {
        "lat": 45.31666564941406,
        "lng": 7.983333110809326
      }
    },
    {
      "codice": "002082",
      "nome": "Motta de' Conti",
      "nomeStraniero": null,
      "codiceCatastale": "F774",
      "cap": "13010",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "mottadeiconti@comuniubv.it",
      "pec": "mottadeconti@pec.it",
      "telefono": "+39 0161/780156",
      "fax": "+39 0161/780420",
      "coordinate": {
        "lat": 45.19334411621094,
        "lng": 8.520934104919434
      }
    },
    {
      "codice": "002088",
      "nome": "Olcenengo",
      "nomeStraniero": null,
      "codiceCatastale": "G016",
      "cap": "13047",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "olcenengo@reteunitaria.piemonte.it",
      "pec": "olcenengo@cert.ruparpiemonte.it",
      "telefono": "+39 0161/271112",
      "fax": "+39 0161/311322",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.316666603088379
      }
    },
    {
      "codice": "002089",
      "nome": "Oldenico",
      "nomeStraniero": null,
      "codiceCatastale": "G018",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "oldenico@libero.it",
      "pec": "oldenico@cert.ruparpiemonte.it",
      "telefono": "+39 0161/73152",
      "fax": "+39 0161/727092",
      "coordinate": {
        "lat": 45.400001525878906,
        "lng": 8.383333206176758
      }
    },
    {
      "codice": "002090",
      "nome": "Palazzolo Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "G266",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "palazzolo.vercellese@ruparpiemonte.it",
      "pec": "palazzolo.vercellese@cert.ruparpiemonte.it",
      "telefono": "+39 0161/818113",
      "fax": "+39 0161/818510",
      "coordinate": {
        "lat": 45.18333435058594,
        "lng": 8.233333587646484
      }
    },
    {
      "codice": "002091",
      "nome": "Pertengo",
      "nomeStraniero": null,
      "codiceCatastale": "G471",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "pertengo@comuniubv.it",
      "pec": "pertengo@pec.it",
      "telefono": "+39 0161779048",
      "fax": "+39 0161/779140",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "002093",
      "nome": "Pezzana",
      "nomeStraniero": null,
      "codiceCatastale": "G528",
      "cap": "13010",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "pezzana@comuniubv.it",
      "pec": "comune.pezzana@pec.it",
      "telefono": "+39 0161/319113",
      "fax": "+39 0161/319498",
      "coordinate": {
        "lat": 45.261959075927734,
        "lng": 8.482279777526855
      }
    },
    {
      "codice": "002096",
      "nome": "Pila",
      "nomeStraniero": null,
      "codiceCatastale": "G666",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "pila@ruparpiemonte.it",
      "pec": "pila@cert.ruparpiemonte.it",
      "telefono": "+39 0163/71327",
      "fax": "+39 0163/71327",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.083333015441895
      }
    },
    {
      "codice": "002097",
      "nome": "Piode",
      "nomeStraniero": null,
      "codiceCatastale": "G685",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "municipio@comune.piode.vc.it",
      "pec": "piode@postemailcertificata.it",
      "telefono": "+39 0163/71155",
      "fax": "+39 0163/732700",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.050000190734863
      }
    },
    {
      "codice": "002102",
      "nome": "Postua",
      "nomeStraniero": null,
      "codiceCatastale": "G940",
      "cap": "13010",
      "prefisso": "015",
      "provincia": "Vercelli",
      "email": "postua1@libero.it",
      "pec": "protocollo.postua@cert.ruparpiemonte.it",
      "telefono": "+39 015/7690006",
      "fax": "+39 0157690921",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.233333587646484
      }
    },
    {
      "codice": "002104",
      "nome": "Prarolo",
      "nomeStraniero": null,
      "codiceCatastale": "G985",
      "cap": "13012",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comuneprarolo@libero.it",
      "pec": "prarolo@cert.ruparpiemonte.it",
      "telefono": "+39 0161/216000",
      "fax": "+39 0161/216252",
      "coordinate": {
        "lat": 45.282291412353516,
        "lng": 8.478117942810059
      }
    },
    {
      "codice": "002107",
      "nome": "Quarona",
      "nomeStraniero": null,
      "codiceCatastale": "H108",
      "cap": "13017",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "anagrafe@comunequarona.it",
      "pec": "comune.quarona.vc@cert.legalmail.it",
      "telefono": "+39 0163/430112",
      "fax": "+39 0163/431088",
      "coordinate": {
        "lat": 45.757869720458984,
        "lng": 8.265721321105957
      }
    },
    {
      "codice": "002108",
      "nome": "Quinto Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "H132",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "quinto.vercellese@ruparpiemonte.it",
      "pec": "quinto.vercellese@cert.ruparpiemonte.it",
      "telefono": "+39 0161/274113",
      "fax": "+39 0161/274325",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 8.366666793823242
      }
    },
    {
      "codice": "002110",
      "nome": "Rassa",
      "nomeStraniero": null,
      "codiceCatastale": "H188",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "rassa@reteunitaria.piemonte.it",
      "pec": "rassa@cert.ruparpiemonte.it",
      "telefono": "+39 0163/77287",
      "fax": "+39 0163/775900",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.016666412353516
      }
    },
    {
      "codice": "002113",
      "nome": "Rimella",
      "nomeStraniero": null,
      "codiceCatastale": "H293",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "rimella@comune.rimella.vc.it",
      "pec": "comune.rimella@legalmail.it",
      "telefono": "+39 0163/55203",
      "fax": "+39 0163/55203",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.183333396911621
      }
    },
    {
      "codice": "002115",
      "nome": "Rive",
      "nomeStraniero": null,
      "codiceCatastale": "H346",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "rive@reteunitaria.piemonte.it",
      "pec": "rive@cert.ruparpiemonte.it",
      "telefono": "+39 0161/770112",
      "fax": "+39 0161/770900",
      "coordinate": {
        "lat": 45.21666717529297,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "002116",
      "nome": "Roasio",
      "nomeStraniero": null,
      "codiceCatastale": "H365",
      "cap": "13060",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "segreteria@comune.roasio.vc.it",
      "pec": "segreteria@pec.comune.roasio.vc.it",
      "telefono": "+39 0163/860151",
      "fax": "+39 0163/861021",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 8.283333778381348
      }
    },
    {
      "codice": "002118",
      "nome": "Ronsecco",
      "nomeStraniero": null,
      "codiceCatastale": "H549",
      "cap": "13036",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "amministrativo@comune.ronsecco.vc.it",
      "pec": "protocollo@pec.comune.ronsecco.vc.it",
      "telefono": "+39 0161/819116",
      "fax": "+39 0161/816002",
      "coordinate": {
        "lat": 45.25,
        "lng": 8.283333778381348
      }
    },
    {
      "codice": "002121",
      "nome": "Rossa",
      "nomeStraniero": null,
      "codiceCatastale": "H577",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "rossa@reteunitaria.piemonte.it",
      "pec": "rossa@cert.ruparpiemonte.it",
      "telefono": "+39 0163/75115",
      "fax": "+39 0163/75115",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.149999618530273
      }
    },
    {
      "codice": "002122",
      "nome": "Rovasenda",
      "nomeStraniero": null,
      "codiceCatastale": "H364",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comune@comune.rovasenda.vc.it",
      "pec": "rovasenda@cert.ruparpiemonte.it",
      "telefono": "+39 0161/856115",
      "fax": "+39 0161/856468",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 8.316666603088379
      }
    },
    {
      "codice": "002126",
      "nome": "Salasco",
      "nomeStraniero": null,
      "codiceCatastale": "H690",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comune.salasco.vc@legalmail.it",
      "pec": "comune.salasco.vc@legalmail.it",
      "telefono": "+39 0161/95913",
      "fax": "+39 0161/95913",
      "coordinate": {
        "lat": 45.33333206176758,
        "lng": 8.266666412353516
      }
    },
    {
      "codice": "002127",
      "nome": "Sali Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "H707",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "sali.vercellese@ruparpiemonte.it",
      "pec": "sali.vercellese@cert.ruparpiemonte.it",
      "telefono": "+39 0161/313116",
      "fax": "+39 0161/313400",
      "coordinate": {
        "lat": 45.28333282470703,
        "lng": 8.350000381469727
      }
    },
    {
      "codice": "002128",
      "nome": "Saluggia",
      "nomeStraniero": null,
      "codiceCatastale": "H725",
      "cap": "13040",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "anagrafe@comune.saluggia.vc.it",
      "pec": "saluggia@cert.ruparpiemonte.it",
      "telefono": "+39 0161/480112",
      "fax": "+39 0161/480202",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 8.016666412353516
      }
    },
    {
      "codice": "002131",
      "nome": "San Germano Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "H861",
      "cap": "13047",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "segreteria@comune.sangermanovercellese.vc.it",
      "pec": "comune.sangermanovercellese.vc@legalmail.it",
      "telefono": "+39 0161/95134",
      "fax": null,
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.25
      }
    },
    {
      "codice": "002035",
      "nome": "San Giacomo Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "B952",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "san.giacomo.vercellese@ruparpiemonte.it",
      "pec": "san.giacomo.vercellese@cert.ruparpiemonte.it",
      "telefono": "+39 0161/850023",
      "fax": "+39 0161/850182",
      "coordinate": {
        "lat": 45.5,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "002133",
      "nome": "Santhià",
      "nomeStraniero": null,
      "codiceCatastale": "I337",
      "cap": "13048",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "segreteria@comune.santhia.vc.it",
      "pec": "protocollo@pec.comune.santhia.vc.it",
      "telefono": "+39 0161 936111",
      "fax": "+39 0161 936231",
      "coordinate": {
        "lat": 45.36666488647461,
        "lng": 8.166666984558105
      }
    },
    {
      "codice": "002134",
      "nome": "Scopa",
      "nomeStraniero": null,
      "codiceCatastale": "I544",
      "cap": "13027",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "scopa@ruparpiemonte.it",
      "pec": "scopa@cert.ruparpiemonte.it",
      "telefono": "+39 0163/71119",
      "fax": "+39 0163/024622",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "002135",
      "nome": "Scopello",
      "nomeStraniero": null,
      "codiceCatastale": "I545",
      "cap": "13028",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "anagrafe.scopello@reteunitaria.piemonte.it",
      "pec": "scopello@cert.ruparpiemonte.it",
      "telefono": "+39 0163/731011",
      "fax": "+39 0163/71173",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.100000381469727
      }
    },
    {
      "codice": "002137",
      "nome": "Serravalle Sesia",
      "nomeStraniero": null,
      "codiceCatastale": "I663",
      "cap": "13037",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "demografico@comune.serravallesesia.vc.it",
      "pec": "protocollo.serravalle.sesia@cert.ruparpiemonte.it",
      "telefono": "+39 0163/459125",
      "fax": "+39 0163/452898",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.316666603088379
      }
    },
    {
      "codice": "002142",
      "nome": "Stroppiana",
      "nomeStraniero": null,
      "codiceCatastale": "I984",
      "cap": "13010",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "stroppiana@comuniubv.it",
      "pec": "comune.stroppiana@pec.it",
      "telefono": "+39 0161/77112",
      "fax": "+39 0161/77135",
      "coordinate": {
        "lat": 45.231109619140625,
        "lng": 8.45472240447998
      }
    },
    {
      "codice": "002147",
      "nome": "Tricerro",
      "nomeStraniero": null,
      "codiceCatastale": "L420",
      "cap": "13038",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "tricerro@reteunitaria.piemonte.it",
      "pec": "tricerro@cert.ruparpiemonte.it",
      "telefono": "+39 0161/817405",
      "fax": "+39 0161/807935",
      "coordinate": {
        "lat": 45.233333587646484,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "002148",
      "nome": "Trino",
      "nomeStraniero": null,
      "codiceCatastale": "L429",
      "cap": "13039",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "affarigenerali.trino@comune.trino.vc.it.it",
      "pec": "comune@pec.comune.trino.vc.it",
      "telefono": "+39 0161/806010",
      "fax": "+39 0161/806013",
      "coordinate": {
        "lat": 45.19527816772461,
        "lng": 8.296111106872559
      }
    },
    {
      "codice": "002150",
      "nome": "Tronzano Vercellese",
      "nomeStraniero": null,
      "codiceCatastale": "L451",
      "cap": "13049",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "comune.tronzanovercellese.vc@cert.legalmail.it",
      "pec": "comune.tronzanovercellese.vc@cert.legalmail.it",
      "telefono": "+39 0161/911235",
      "fax": "+39 0161/912295",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 8.166666984558105
      }
    },
    {
      "codice": "002152",
      "nome": "Valduggia",
      "nomeStraniero": null,
      "codiceCatastale": "L566",
      "cap": "13018",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "valduggia@reteunitaria.piemonte.it",
      "pec": "valduggia@cert.ruparpiemonte.it",
      "telefono": "+39 0163/436615",
      "fax": "+39 0163/48223",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "002156",
      "nome": "Varallo",
      "nomeStraniero": null,
      "codiceCatastale": "L669",
      "cap": "13019",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "elettorale@comune.varallo.vc.it",
      "pec": "comune.varallo.vc@legalmail.it",
      "telefono": "+39 0163/562704",
      "fax": "+39 0163/562755",
      "coordinate": {
        "lat": 45.8138313293457,
        "lng": 8.258139610290527
      }
    },
    {
      "codice": "002158",
      "nome": "Vercelli",
      "nomeStraniero": null,
      "codiceCatastale": "L750",
      "cap": "13100",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "angela.pozzati@comune.vercelli.it",
      "pec": "vercelli@cert.ruparpiemonte.it",
      "telefono": "+39 0161/596378",
      "fax": "+39 0161/596339",
      "coordinate": {
        "lat": 45.32555389404297,
        "lng": 8.423055648803711
      }
    },
    {
      "codice": "002163",
      "nome": "Villarboit",
      "nomeStraniero": null,
      "codiceCatastale": "M003",
      "cap": "13030",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "villarboit@reteunitaria.piemonte.it",
      "pec": "villarboit@cert.ruparpiemonte.it",
      "telefono": "+39 0161/854155",
      "fax": "+39 0161/854341",
      "coordinate": {
        "lat": 45.43333435058594,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "002164",
      "nome": "Villata",
      "nomeStraniero": null,
      "codiceCatastale": "M028",
      "cap": "13010",
      "prefisso": "0161",
      "provincia": "Vercelli",
      "email": "segretariocomunale.villata@reteunitaria.piemonte.it",
      "pec": "info@pec.comune.villata.vc.it",
      "telefono": "+39 0161/310113",
      "fax": "+39 0161/310631",
      "coordinate": {
        "lat": 45.387760162353516,
        "lng": 8.432632446289062
      }
    },
    {
      "codice": "002166",
      "nome": "Vocca",
      "nomeStraniero": null,
      "codiceCatastale": "M106",
      "cap": "13020",
      "prefisso": "0163",
      "provincia": "Vercelli",
      "email": "sindaco.vocca@reteunitaria.piemonte.it",
      "pec": "comune.vocca.vc@legalmail.it",
      "telefono": "+39 0163/560993",
      "fax": "+39 0163/560993",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.199999809265137
      }
    }
  ],
  "Savona": [
    {
      "codice": "009001",
      "nome": "Alassio",
      "nomeStraniero": null,
      "codiceCatastale": "A122",
      "cap": "17021",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "comune@comune.alassio.sv.it",
      "pec": "comune.alassio@legalmail.it",
      "telefono": "+39 0182 6021",
      "fax": "+39 0182/471838",
      "coordinate": {
        "lat": 44.00791549682617,
        "lng": 8.173044204711914
      }
    },
    {
      "codice": "009002",
      "nome": "Albenga",
      "nomeStraniero": null,
      "codiceCatastale": "A145",
      "cap": "17031",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "protocollo@comune.albenga.sv.it",
      "pec": "protocollo@pec.comune.albenga.sv.it",
      "telefono": "+39 0182 5621",
      "fax": "+39 0182/554617",
      "coordinate": {
        "lat": 44.04911422729492,
        "lng": 8.213016510009766
      }
    },
    {
      "codice": "009004",
      "nome": "Albisola Superiore",
      "nomeStraniero": null,
      "codiceCatastale": "A166",
      "cap": "17011",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "urp@comune.albisola-superiore.sv.it",
      "pec": "comune.albisolasuperiore@pec.liguriainrete.it",
      "telefono": "+39 019/482295",
      "fax": "+39 019/480511",
      "coordinate": {
        "lat": 44.33950424194336,
        "lng": 8.510133743286133
      }
    },
    {
      "codice": "009003",
      "nome": "Albissola Marina",
      "nomeStraniero": null,
      "codiceCatastale": "A165",
      "cap": "17012",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comune.albissolamarina.sv.it",
      "pec": "comunealbissolamarina@legalmail.it",
      "telefono": "+39 019/40029200",
      "fax": "+39 019/481528",
      "coordinate": {
        "lat": 44.32727813720703,
        "lng": 8.503101348876953
      }
    },
    {
      "codice": "009005",
      "nome": "Altare",
      "nomeStraniero": null,
      "codiceCatastale": "A226",
      "cap": "17041",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comunealtare.it",
      "pec": "comune.altare.sv@legalmail.it",
      "telefono": "+39 019/58005",
      "fax": "+39 019/58071",
      "coordinate": {
        "lat": 44.33620071411133,
        "lng": 8.343999862670898
      }
    },
    {
      "codice": "009006",
      "nome": "Andora",
      "nomeStraniero": null,
      "codiceCatastale": "A278",
      "cap": "17051",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "protocollo@comunediandora.it",
      "pec": "protocollo@cert.comunediandora.it",
      "telefono": "+39 0182/68111",
      "fax": "+39 0182/6811244",
      "coordinate": {
        "lat": 43.95855712890625,
        "lng": 8.139458656311035
      }
    },
    {
      "codice": "009007",
      "nome": "Arnasco",
      "nomeStraniero": null,
      "codiceCatastale": "A422",
      "cap": "17032",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "info@comunearnasco.it",
      "pec": "comunearnasco@pec.it",
      "telefono": "+39 0182/761020",
      "fax": "+39 0182/761207",
      "coordinate": {
        "lat": 44.077980041503906,
        "lng": 8.107202529907227
      }
    },
    {
      "codice": "009008",
      "nome": "Balestrino",
      "nomeStraniero": null,
      "codiceCatastale": "A593",
      "cap": "17020",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "comune@balestrino.it",
      "pec": "protocollo.comune.balestrino.sv@legalmail.it",
      "telefono": "+39 0182/988004",
      "fax": "+39 0182/988721",
      "coordinate": {
        "lat": 44.12512969970703,
        "lng": 8.171520233154297
      }
    },
    {
      "codice": "009009",
      "nome": "Bardineto",
      "nomeStraniero": null,
      "codiceCatastale": "A647",
      "cap": "17057",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "areaamministrativa@comunedibardineto.it",
      "pec": "milenagerini@legalmail.it",
      "telefono": "+39 019/7907013",
      "fax": "+39 019/7907293",
      "coordinate": {
        "lat": 44.19208908081055,
        "lng": 8.129141807556152
      }
    },
    {
      "codice": "009010",
      "nome": "Bergeggi",
      "nomeStraniero": null,
      "codiceCatastale": "A796",
      "cap": "17042",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "bergeggi@comune.bergeggi.sv.it",
      "pec": "protocollo@pec.comune.bergeggi.sv.it",
      "telefono": "+39 019/257901",
      "fax": "+39 019/25790220",
      "coordinate": {
        "lat": 44.24866485595703,
        "lng": 8.443650245666504
      }
    },
    {
      "codice": "009011",
      "nome": "Boissano",
      "nomeStraniero": null,
      "codiceCatastale": "A931",
      "cap": "17054",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "anagrafe@comunediboissano.it",
      "pec": "protocollo@pec.comune.boissano.sv.it",
      "telefono": "+39 0182/98010",
      "fax": "+39 0182/98487",
      "coordinate": {
        "lat": 44.13442611694336,
        "lng": 8.221280097961426
      }
    },
    {
      "codice": "009012",
      "nome": "Borghetto Santo Spirito",
      "nomeStraniero": null,
      "codiceCatastale": "A999",
      "cap": "17052",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "protocollo@comuneborghetto.it",
      "pec": "comuneborghettoss@postecert.it",
      "telefono": "+39 0182/970000",
      "fax": "+39 0182/950695",
      "coordinate": {
        "lat": 44.10905456542969,
        "lng": 8.241358757019043
      }
    },
    {
      "codice": "009013",
      "nome": "Borgio Verezzi",
      "nomeStraniero": null,
      "codiceCatastale": "B005",
      "cap": "17022",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagrafe@comuneborgioverezzi.it",
      "pec": "protocollo@pec.comuneborgioverezzi.it",
      "telefono": "+39 019/618211",
      "fax": "+39 019/618226",
      "coordinate": {
        "lat": 44.160003662109375,
        "lng": 8.304633140563965
      }
    },
    {
      "codice": "009014",
      "nome": "Bormida",
      "nomeStraniero": null,
      "codiceCatastale": "B048",
      "cap": "17040",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comunebormida@libero.it",
      "pec": "comune.bormida.sv@legalmail.it",
      "telefono": "+39 019/54718",
      "fax": "+39 019/54610",
      "coordinate": {
        "lat": 44.27882385253906,
        "lng": 8.23336124420166
      }
    },
    {
      "codice": "009015",
      "nome": "Cairo Montenotte",
      "nomeStraniero": null,
      "codiceCatastale": "B369",
      "cap": "17014",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comunecairo.it",
      "pec": "posta@comunecairo.legalmail.it",
      "telefono": "+39 019/507071",
      "fax": "+39 019/50707400",
      "coordinate": {
        "lat": 44.397891998291016,
        "lng": 8.277789115905762
      }
    },
    {
      "codice": "009016",
      "nome": "Calice Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "B409",
      "cap": "17020",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagrafe@comune.calice-ligure.sv.it",
      "pec": "protocollo.comune.calice@actaliscertymail.it",
      "telefono": "+39 019/65433",
      "fax": "+39 019/65434",
      "coordinate": {
        "lat": 44.20509338378906,
        "lng": 8.294410705566406
      }
    },
    {
      "codice": "009017",
      "nome": "Calizzano",
      "nomeStraniero": null,
      "codiceCatastale": "B416",
      "cap": "17057",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagrafe@comunedicalizzano.it",
      "pec": "calizzano@legalmail.it",
      "telefono": "+39 019/79069",
      "fax": "+39 019/7906930",
      "coordinate": {
        "lat": 44.23527526855469,
        "lng": 8.115403175354004
      }
    },
    {
      "codice": "009018",
      "nome": "Carcare",
      "nomeStraniero": null,
      "codiceCatastale": "B748",
      "cap": "17043",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@comune.carcare.sv.it",
      "pec": "protocollo@comunecarcarecert.it",
      "telefono": "+39 019/5154100",
      "fax": "+39 019/5154101",
      "coordinate": {
        "lat": 44.35789489746094,
        "lng": 8.290624618530273
      }
    },
    {
      "codice": "009019",
      "nome": "Casanova Lerrone",
      "nomeStraniero": null,
      "codiceCatastale": "B927",
      "cap": "17033",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "protocollo@comune.casanovalerrone.sv.it",
      "pec": "comune.casanovalerrone@legalmail.it",
      "telefono": "+39 018274014",
      "fax": "+39 018274010",
      "coordinate": {
        "lat": 44.031673431396484,
        "lng": 8.048418998718262
      }
    },
    {
      "codice": "009020",
      "nome": "Castelbianco",
      "nomeStraniero": null,
      "codiceCatastale": "C063",
      "cap": "17030",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "castelbianco@libero.it",
      "pec": "comune.castelbianco@legalmail.it",
      "telefono": "+39 0182/77006",
      "fax": "+39 0182/77070",
      "coordinate": {
        "lat": 44.11298751831055,
        "lng": 8.074199676513672
      }
    },
    {
      "codice": "009021",
      "nome": "Castelvecchio di Rocca Barbena",
      "nomeStraniero": null,
      "codiceCatastale": "C276",
      "cap": "17034",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "castelvecchiorb@uno.it",
      "pec": "castelvecchiorb@pec.uno.it",
      "telefono": "+39 0182/78042",
      "fax": "+39 0182/783900",
      "coordinate": {
        "lat": 44.1304817199707,
        "lng": 8.115513801574707
      }
    },
    {
      "codice": "009022",
      "nome": "Celle Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "C443",
      "cap": "17015",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@comunecelle.it",
      "pec": "comunecelle@postecert.it",
      "telefono": "+39 019/99801",
      "fax": "+39 019/993599",
      "coordinate": {
        "lat": 44.342098236083984,
        "lng": 8.545575141906738
      }
    },
    {
      "codice": "009023",
      "nome": "Cengio",
      "nomeStraniero": null,
      "codiceCatastale": "C463",
      "cap": "17056",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagrafe@comune.cengio.sv.it",
      "pec": "protocollo@pec.comune.cengio.sv.it",
      "telefono": "+39 019/554035",
      "fax": "+39 019/555651",
      "coordinate": {
        "lat": 44.38774871826172,
        "lng": 8.208457946777344
      }
    },
    {
      "codice": "009024",
      "nome": "Ceriale",
      "nomeStraniero": null,
      "codiceCatastale": "C510",
      "cap": "17023",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "info@comune.ceriale.sv.it",
      "pec": "comuneceriale@postecert.it",
      "telefono": "+39 0182/91931",
      "fax": "+39 0182/991461",
      "coordinate": {
        "lat": 44.09657669067383,
        "lng": 8.232102394104004
      }
    },
    {
      "codice": "009025",
      "nome": "Cisano sul Neva",
      "nomeStraniero": null,
      "codiceCatastale": "C729",
      "cap": "17035",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "amministrativo@cisanosulneva.it",
      "pec": "comunecisanosulneva@postacert.vodafone.it",
      "telefono": "+39 0182/595026",
      "fax": "+39 0182/595400",
      "coordinate": {
        "lat": 44.08732223510742,
        "lng": 8.14501953125
      }
    },
    {
      "codice": "009026",
      "nome": "Cosseria",
      "nomeStraniero": null,
      "codiceCatastale": "D095",
      "cap": "17017",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "demografico@comune.cosseria.sv.it",
      "pec": "comune.cossera.sv@legalmail.it",
      "telefono": "+39 019/519608",
      "fax": "+39 019/519711",
      "coordinate": {
        "lat": 44.36860656738281,
        "lng": 8.23478889465332
      }
    },
    {
      "codice": "009027",
      "nome": "Dego",
      "nomeStraniero": null,
      "codiceCatastale": "D264",
      "cap": "17058",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "demografica@comune.dego.sv.it",
      "pec": "demografica.dego@pec.it",
      "telefono": "+39 019/577792",
      "fax": "+39 019/57660",
      "coordinate": {
        "lat": 44.449317932128906,
        "lng": 8.310983657836914
      }
    },
    {
      "codice": "009028",
      "nome": "Erli",
      "nomeStraniero": null,
      "codiceCatastale": "D424",
      "cap": "17030",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "comunedierli@virgilio.it",
      "pec": "comune.erli@pec.it",
      "telefono": "+39 0182/78030",
      "fax": "+39 0182/78030",
      "coordinate": {
        "lat": 44.137271881103516,
        "lng": 8.104097366333008
      }
    },
    {
      "codice": "009029",
      "nome": "Finale Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "D600",
      "cap": "17024",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comunefinaleligure@inet.it",
      "pec": "comunefinaleligure@legalmail.it",
      "telefono": "+39 019/689011",
      "fax": "+39 019/680135",
      "coordinate": {
        "lat": 44.169071197509766,
        "lng": 8.343536376953125
      }
    },
    {
      "codice": "009030",
      "nome": "Garlenda",
      "nomeStraniero": null,
      "codiceCatastale": "D927",
      "cap": "17033",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "info@comune.garlenda.sv.it",
      "pec": "comunegarlenda@legalmail.it",
      "telefono": "+39 0182/580056",
      "fax": "+39 0182/580343",
      "coordinate": {
        "lat": 44.03350830078125,
        "lng": 8.097039222717285
      }
    },
    {
      "codice": "009031",
      "nome": "Giustenice",
      "nomeStraniero": null,
      "codiceCatastale": "E064",
      "cap": "17027",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comunegiustenice@libero.it",
      "pec": "info@pec.comune.giustenice.sv.it",
      "telefono": "+39 019/637145",
      "fax": "+39 019/648156",
      "coordinate": {
        "lat": 44.172157287597656,
        "lng": 8.246152877807617
      }
    },
    {
      "codice": "009032",
      "nome": "Giusvalla",
      "nomeStraniero": null,
      "codiceCatastale": "E066",
      "cap": "17010",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comune.giusvalla.sv.it",
      "pec": "comune.giusvalla@pec.it",
      "telefono": "+39 019 707010",
      "fax": "+39 019 707170",
      "coordinate": {
        "lat": 44.44998550415039,
        "lng": 8.393458366394043
      }
    },
    {
      "codice": "009033",
      "nome": "Laigueglia",
      "nomeStraniero": null,
      "codiceCatastale": "E414",
      "cap": "17053",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "laigueglia@comune.laigueglia.sv.it",
      "pec": "protocollo@postacertificata.comune.laigueglia.sv.it",
      "telefono": "+39 0182/69111",
      "fax": "+39 0182/6911301",
      "coordinate": {
        "lat": 43.974510192871094,
        "lng": 8.158310890197754
      }
    },
    {
      "codice": "009034",
      "nome": "Loano",
      "nomeStraniero": null,
      "codiceCatastale": "E632",
      "cap": "17025",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "loano@comuneloano.it",
      "pec": "loano@peccomuneloano.it",
      "telefono": "+39 019/675694",
      "fax": "+39 019/675698",
      "coordinate": {
        "lat": 44.12900161743164,
        "lng": 8.259821891784668
      }
    },
    {
      "codice": "009035",
      "nome": "Magliolo",
      "nomeStraniero": null,
      "codiceCatastale": "E816",
      "cap": "17020",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@comune.magliolo.sv.it",
      "pec": "protocollo@pec.comunemagliolo.it",
      "telefono": "+39 019/634004",
      "fax": "+39 019/634503",
      "coordinate": {
        "lat": 44.19156265258789,
        "lng": 8.250238418579102
      }
    },
    {
      "codice": "009036",
      "nome": "Mallare",
      "nomeStraniero": null,
      "codiceCatastale": "E860",
      "cap": "17045",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comune.mallare@alice.it",
      "pec": "comune.mallare.sv@legalmail.it",
      "telefono": "+39 019/586009",
      "fax": "+39 019/586373",
      "coordinate": {
        "lat": 44.2916145324707,
        "lng": 8.297614097595215
      }
    },
    {
      "codice": "009037",
      "nome": "Massimino",
      "nomeStraniero": null,
      "codiceCatastale": "F046",
      "cap": "12071",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "segreteria@comune.massimino.sv.it",
      "pec": "comune.massimino@pec.it",
      "telefono": "+39 019/79944",
      "fax": "+39 019/79944",
      "coordinate": {
        "lat": 44.29999923706055,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "009038",
      "nome": "Millesimo",
      "nomeStraniero": null,
      "codiceCatastale": "F213",
      "cap": "17017",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "demografici@comune.millesimo.sv.it",
      "pec": "protocollo@pec.comune.millesimo.sv.it",
      "telefono": "+39 019/564007",
      "fax": "+39 019/564368",
      "coordinate": {
        "lat": 44.36439895629883,
        "lng": 8.205936431884766
      }
    },
    {
      "codice": "009039",
      "nome": "Mioglia",
      "nomeStraniero": null,
      "codiceCatastale": "F226",
      "cap": "17040",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comunemioglia.it",
      "pec": "comune.mioglia.sv@legalmail.it",
      "telefono": "+39 019/732014",
      "fax": "+39 019/732288",
      "coordinate": {
        "lat": 44.489803314208984,
        "lng": 8.411202430725098
      }
    },
    {
      "codice": "009040",
      "nome": "Murialdo",
      "nomeStraniero": null,
      "codiceCatastale": "F813",
      "cap": "17013",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "ufficioanagrafe@comunemurialdo.it",
      "pec": "postmaster@pec.comunemurialdo.it",
      "telefono": "3386073166",
      "fax": "+39 019/53543",
      "coordinate": {
        "lat": 44.3161735534668,
        "lng": 8.161308288574219
      }
    },
    {
      "codice": "009041",
      "nome": "Nasino",
      "nomeStraniero": null,
      "codiceCatastale": "F847",
      "cap": "17030",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "com_nasino@libero.it",
      "pec": "protocollo@pec.comune.nasino.sv.it",
      "telefono": "+39 0182/77017",
      "fax": "+39 0182/77017",
      "coordinate": {
        "lat": 44.11440658569336,
        "lng": 8.02971363067627
      }
    },
    {
      "codice": "009042",
      "nome": "Noli",
      "nomeStraniero": null,
      "codiceCatastale": "F926",
      "cap": "17026",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "servizi.demografici@comune.noli.sv.it",
      "pec": "protocollo@pec.comune.noli.sv.it",
      "telefono": "+39 019/7499520",
      "fax": "+39 019/7499542",
      "coordinate": {
        "lat": 44.205692291259766,
        "lng": 8.416150093078613
      }
    },
    {
      "codice": "009043",
      "nome": "Onzo",
      "nomeStraniero": null,
      "codiceCatastale": "G076",
      "cap": "17030",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "info@comune.onzo.sv.it",
      "pec": "protocollo.onzo@legalmail.it",
      "telefono": "+39 0182/766004",
      "fax": "+39 0182/766004",
      "coordinate": {
        "lat": 44.07010269165039,
        "lng": 8.054946899414062
      }
    },
    {
      "codice": "009044",
      "nome": "Orco Feglino",
      "nomeStraniero": null,
      "codiceCatastale": "D522",
      "cap": "17024",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@comune.orcofeglino.sv.it",
      "pec": "comune.orcofeglino@legalmail.it",
      "telefono": "+39 019/699010",
      "fax": "+39 019/699178",
      "coordinate": {
        "lat": 44.21952438354492,
        "lng": 8.324994087219238
      }
    },
    {
      "codice": "009045",
      "nome": "Ortovero",
      "nomeStraniero": null,
      "codiceCatastale": "G144",
      "cap": "17037",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "anagrafe@comuneortovero.it",
      "pec": "comuneortovero@pec.it",
      "telefono": "+39 0182 547388",
      "fax": "+39 0182 547389",
      "coordinate": {
        "lat": 44.05331802368164,
        "lng": 8.087528228759766
      }
    },
    {
      "codice": "009046",
      "nome": "Osiglia",
      "nomeStraniero": null,
      "codiceCatastale": "G155",
      "cap": "17010",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comune.osiglia.sv.it",
      "pec": "protocollo@pec.comune.osiglia.sv.it",
      "telefono": "+39 019/542085",
      "fax": "+39 019/5522600",
      "coordinate": {
        "lat": 44.28117370605469,
        "lng": 8.2011137008667
      }
    },
    {
      "codice": "009047",
      "nome": "Pallare",
      "nomeStraniero": null,
      "codiceCatastale": "G281",
      "cap": "17043",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comune.pallare@libero.it",
      "pec": "demografici@pec.comunedipallare.it",
      "telefono": "+39 019/590250",
      "fax": "+39 019/590000",
      "coordinate": {
        "lat": 44.327335357666016,
        "lng": 8.275400161743164
      }
    },
    {
      "codice": "009048",
      "nome": "Piana Crixia",
      "nomeStraniero": null,
      "codiceCatastale": "G542",
      "cap": "17058",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagtrib.pianacrixia@libero.it",
      "pec": "protocollo@pec.comune.pianacrixia.sv.it",
      "telefono": "+39 019/570021",
      "fax": "+39 019/570022",
      "coordinate": {
        "lat": 44.48537063598633,
        "lng": 8.307530403137207
      }
    },
    {
      "codice": "009049",
      "nome": "Pietra Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "G605",
      "cap": "17027",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comunepietraligure.it",
      "pec": "protocollo@pec.comunepietraligure.it",
      "telefono": "+39 019/629311",
      "fax": "+39 019/626686",
      "coordinate": {
        "lat": 44.148746490478516,
        "lng": 8.282816886901855
      }
    },
    {
      "codice": "009050",
      "nome": "Plodio",
      "nomeStraniero": null,
      "codiceCatastale": "G741",
      "cap": "17043",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comuneplodio@libero.it",
      "pec": "comune.plodio.sv@pec.it",
      "telefono": "+39 019/519649",
      "fax": "+39 019/519640",
      "coordinate": {
        "lat": 44.35662841796875,
        "lng": 8.243091583251953
      }
    },
    {
      "codice": "009051",
      "nome": "Pontinvrea",
      "nomeStraniero": null,
      "codiceCatastale": "G866",
      "cap": "17042",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "pontinvrea@libero.it",
      "pec": "comune.pontinvrea@pec.it",
      "telefono": "+39 019/705001",
      "fax": "+39 019/705269",
      "coordinate": {
        "lat": 44.44476318359375,
        "lng": 8.434636116027832
      }
    },
    {
      "codice": "009052",
      "nome": "Quiliano",
      "nomeStraniero": null,
      "codiceCatastale": "H126",
      "cap": "17047",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@comune.quiliano.sv.it",
      "pec": "comune.quiliano@legalmail.it",
      "telefono": "+39 019/2000504",
      "fax": "+39 019/2000547",
      "coordinate": {
        "lat": 44.293113708496094,
        "lng": 8.410307884216309
      }
    },
    {
      "codice": "009053",
      "nome": "Rialto",
      "nomeStraniero": null,
      "codiceCatastale": "H266",
      "cap": "17020",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comune.rialto@tiscali.it",
      "pec": "comune.rialto@legalmail.it",
      "telefono": "+39 019/65114",
      "fax": "+39 019/6502058",
      "coordinate": {
        "lat": 44.233333587646484,
        "lng": 8.25
      }
    },
    {
      "codice": "009054",
      "nome": "Roccavignale",
      "nomeStraniero": null,
      "codiceCatastale": "H452",
      "cap": "17017",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comune.roccavignale.sv.it",
      "pec": "protocollo@pec.comune.roccavignale.sv.it",
      "telefono": "+39 019/564103",
      "fax": "+39 019/564730",
      "coordinate": {
        "lat": 44.361637115478516,
        "lng": 8.192825317382812
      }
    },
    {
      "codice": "009055",
      "nome": "Sassello",
      "nomeStraniero": null,
      "codiceCatastale": "I453",
      "cap": "17046",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagrafe@comune.sassello.sv.it",
      "pec": "comunesassello@legalmail.it",
      "telefono": "+39 019/724103",
      "fax": "+39 019/724664",
      "coordinate": {
        "lat": 44.47900390625,
        "lng": 8.490097045898438
      }
    },
    {
      "codice": "009056",
      "nome": "Savona",
      "nomeStraniero": null,
      "codiceCatastale": "I480",
      "cap": "17100",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "archivio.protocollo@comune.savona.it",
      "pec": "posta@pec.comune.savona.it",
      "telefono": "+39 019/83101",
      "fax": "+39 019/8310316",
      "coordinate": {
        "lat": 44.30797576904297,
        "lng": 8.481017112731934
      }
    },
    {
      "codice": "009057",
      "nome": "Spotorno",
      "nomeStraniero": null,
      "codiceCatastale": "I926",
      "cap": "17028",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "protocollo@comune.spotorno.sv.it",
      "pec": "protocollo@pec.comune.spotorno.sv.it",
      "telefono": "+39 019/746971",
      "fax": "+39 019/746651",
      "coordinate": {
        "lat": 44.227108001708984,
        "lng": 8.41920280456543
      }
    },
    {
      "codice": "009058",
      "nome": "Stella",
      "nomeStraniero": null,
      "codiceCatastale": "I946",
      "cap": "17044",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@comune.stella.sv.it",
      "pec": "info@comunestella.legalmail.it",
      "telefono": "+39 019/703002",
      "fax": "+39 019/703300",
      "coordinate": {
        "lat": 44.39689636230469,
        "lng": 8.493313789367676
      }
    },
    {
      "codice": "009059",
      "nome": "Stellanello",
      "nomeStraniero": null,
      "codiceCatastale": "I947",
      "cap": "17020",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "comunestellanello@libero.it",
      "pec": "comunedistellanello.sv.comunicazione@pa.postacertificata.gov.it",
      "telefono": "+39 0182/668000",
      "fax": "+39 0182/668476",
      "coordinate": {
        "lat": 44.001182556152344,
        "lng": 8.055052757263184
      }
    },
    {
      "codice": "009060",
      "nome": "Testico",
      "nomeStraniero": null,
      "codiceCatastale": "L152",
      "cap": "17020",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "testico@tiscalinet.it",
      "pec": "comunetestico.sv.protocollo@pa.postacertificata.gov.it",
      "telefono": "+39 0182/668091",
      "fax": "+39 0182/669928",
      "coordinate": {
        "lat": 44.00523376464844,
        "lng": 8.030316352844238
      }
    },
    {
      "codice": "009061",
      "nome": "Toirano",
      "nomeStraniero": null,
      "codiceCatastale": "L190",
      "cap": "17055",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "servizigenerali@comune.toirano.sv.it",
      "pec": "comune.toirano.sv@halleycert.it",
      "telefono": "+39 0182/989909",
      "fax": "+39 0182/989644",
      "coordinate": {
        "lat": 44.12704086303711,
        "lng": 8.207108497619629
      }
    },
    {
      "codice": "009062",
      "nome": "Tovo San Giacomo",
      "nomeStraniero": null,
      "codiceCatastale": "L315",
      "cap": "17020",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "anagrafe@comune.tovo-san-giacomo.sv.it",
      "pec": "anagrafe@comunetovosangiacomo.it",
      "telefono": "+39 019/6379022",
      "fax": "+39 019/648665",
      "coordinate": {
        "lat": 44.17655944824219,
        "lng": 8.268449783325195
      }
    },
    {
      "codice": "009063",
      "nome": "Urbe",
      "nomeStraniero": null,
      "codiceCatastale": "L499",
      "cap": "17048",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "comuneurbe@vislink.it",
      "pec": "protocollo.urbe@legalmail.it",
      "telefono": "+39 019/726005",
      "fax": "+39 019/726483",
      "coordinate": {
        "lat": 44.487579345703125,
        "lng": 8.591952323913574
      }
    },
    {
      "codice": "009064",
      "nome": "Vado Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "L528",
      "cap": "17047",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "info@cert.comune.vado-ligure.sv.it",
      "pec": "info@cert.comune.vado-ligure.sv.it",
      "telefono": "+39 019/886350",
      "fax": "+39 019/880214",
      "coordinate": {
        "lat": 44.2691764831543,
        "lng": 8.43606948852539
      }
    },
    {
      "codice": "009065",
      "nome": "Varazze",
      "nomeStraniero": null,
      "codiceCatastale": "L675",
      "cap": "17019",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "segreteria.protocollo@comune.varazze.sv.it",
      "pec": "protocollo.comune.varazze@pec.it",
      "telefono": "+39 019/93901",
      "fax": "+39 019/932655",
      "coordinate": {
        "lat": 44.36000442504883,
        "lng": 8.57663631439209
      }
    },
    {
      "codice": "009066",
      "nome": "Vendone",
      "nomeStraniero": null,
      "codiceCatastale": "L730",
      "cap": "17032",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "vendone@libero.it",
      "pec": "comunevendone@legalmail.it",
      "telefono": "+39 0182/76248",
      "fax": "+39 0182/76248",
      "coordinate": {
        "lat": 44.074275970458984,
        "lng": 8.074125289916992
      }
    },
    {
      "codice": "009067",
      "nome": "Vezzi Portio",
      "nomeStraniero": null,
      "codiceCatastale": "L823",
      "cap": "17028",
      "prefisso": "019",
      "provincia": "Savona",
      "email": "vezziportio@comune.vezzi-portio.sv.it",
      "pec": "comune.vezziportio@pec.it",
      "telefono": "+39 019/7428000",
      "fax": "+39 019/742019",
      "coordinate": {
        "lat": 44.22902297973633,
        "lng": 8.362214088439941
      }
    },
    {
      "codice": "009068",
      "nome": "Villanova d'Albenga",
      "nomeStraniero": null,
      "codiceCatastale": "L975",
      "cap": "17038",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "protocollo@comunevillanovadalbenga.it",
      "pec": "villanovadalbenga@legalmail.it",
      "telefono": "+39 0182/582913",
      "fax": "+39 0182/582514",
      "coordinate": {
        "lat": 44.0460090637207,
        "lng": 8.143133163452148
      }
    },
    {
      "codice": "009069",
      "nome": "Zuccarello",
      "nomeStraniero": null,
      "codiceCatastale": "M197",
      "cap": "17039",
      "prefisso": "0182",
      "provincia": "Savona",
      "email": "zuccarellosegreteria@gmail.com",
      "pec": "protocollo@pec.comunezuccarello.it",
      "telefono": "+39 0182/79022",
      "fax": "+39 0182/79088",
      "coordinate": {
        "lat": 44.11103057861328,
        "lng": 8.115848541259766
      }
    }
  ],
  "Teramo": [
    {
      "codice": "067001",
      "nome": "Alba Adriatica",
      "nomeStraniero": null,
      "codiceCatastale": "A125",
      "cap": "64011",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comune.alba-adriatica.te.it",
      "pec": "protocollo@pec.comune.alba-adriatica.te.it",
      "telefono": "+39 0861/ 719111",
      "fax": null,
      "coordinate": {
        "lat": 42.83333206176758,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "067002",
      "nome": "Ancarano",
      "nomeStraniero": null,
      "codiceCatastale": "A270",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "info@comune.ancarano.te.it",
      "pec": "comune.ancarano.te@pec.it",
      "telefono": "+39 0861/870913",
      "fax": "+39 0861/86356",
      "coordinate": {
        "lat": 42.836944580078125,
        "lng": 13.740278244018555
      }
    },
    {
      "codice": "067003",
      "nome": "Arsita",
      "nomeStraniero": null,
      "codiceCatastale": "A445",
      "cap": "64031",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "areademografica@comune.arsita.te.it",
      "pec": "protocollo.arsita@anutel.it",
      "telefono": "+39 0861995525",
      "fax": "+39 0861995039",
      "coordinate": {
        "lat": 42.502254486083984,
        "lng": 13.78451919555664
      }
    },
    {
      "codice": "067004",
      "nome": "Atri",
      "nomeStraniero": null,
      "codiceCatastale": "A488",
      "cap": "64032",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "sindaco@comune.atri.te.it",
      "pec": "postacert@pec.comune.atri.te.it",
      "telefono": "+39 085.8791240",
      "fax": "+39 0858791205",
      "coordinate": {
        "lat": 42.58333206176758,
        "lng": 13.983333587646484
      }
    },
    {
      "codice": "067005",
      "nome": "Basciano",
      "nomeStraniero": null,
      "codiceCatastale": "A692",
      "cap": "64030",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "affari.generali@comune.basciano.te.it",
      "pec": "postacert@pec.comune.basciano.te.it",
      "telefono": "+39 0861/650156",
      "fax": "+39 0861/650927",
      "coordinate": {
        "lat": 42.59638977050781,
        "lng": 13.735610008239746
      }
    },
    {
      "codice": "067006",
      "nome": "Bellante",
      "nomeStraniero": null,
      "codiceCatastale": "A746",
      "cap": "64020",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comune.bellante.te.it",
      "pec": "protocollo@pec.comune.bellante.te.it",
      "telefono": "+39 0861/61701",
      "fax": "+39 0861/6170330",
      "coordinate": {
        "lat": 42.75,
        "lng": 13.800000190734863
      }
    },
    {
      "codice": "067007",
      "nome": "Bisenti",
      "nomeStraniero": null,
      "codiceCatastale": "A885",
      "cap": "64033",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "renzosap@alice.it",
      "pec": "comunebisenti@pec.it",
      "telefono": "+39 0861/997156",
      "fax": "+39 0861/995733",
      "coordinate": {
        "lat": 42.53333282470703,
        "lng": 13.800000190734863
      }
    },
    {
      "codice": "067008",
      "nome": "Campli",
      "nomeStraniero": null,
      "codiceCatastale": "B515",
      "cap": "64012",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "polizia.municipale@campli.it",
      "pec": "postacert@pec.comune.campli.te.it",
      "telefono": "+39 0861/5601250",
      "fax": "+39 0861/5601212",
      "coordinate": {
        "lat": 42.726131439208984,
        "lng": 13.686088562011719
      }
    },
    {
      "codice": "067009",
      "nome": "Canzano",
      "nomeStraniero": null,
      "codiceCatastale": "B640",
      "cap": "64020",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comune.canzano.te.it",
      "pec": "protocollo@pec.comune.canzano.te.it",
      "telefono": "+39 0861/555128",
      "fax": "+39 0861/555798",
      "coordinate": {
        "lat": 42.650001525878906,
        "lng": 13.800000190734863
      }
    },
    {
      "codice": "067010",
      "nome": "Castel Castagna",
      "nomeStraniero": null,
      "codiceCatastale": "C040",
      "cap": "64030",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "comunecastelcastagna@libero.it",
      "pec": "protocollo@pec.comunedicastelcastagna.gov.it",
      "telefono": "+39 0861/697250",
      "fax": "+39 0861/697253",
      "coordinate": {
        "lat": 42.54999923706055,
        "lng": 13.716666221618652
      }
    },
    {
      "codice": "067011",
      "nome": "Castellalto",
      "nomeStraniero": null,
      "codiceCatastale": "C128",
      "cap": "64020",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "comune.castellalto@tin.it",
      "pec": "castellalto.segreteria@raccomandatacertificata.it",
      "telefono": "+39 0861/444212",
      "fax": "+39 0861/444237",
      "coordinate": {
        "lat": 42.67711639404297,
        "lng": 13.817816734313965
      }
    },
    {
      "codice": "067012",
      "nome": "Castelli",
      "nomeStraniero": null,
      "codiceCatastale": "C169",
      "cap": "64041",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "info@comune.castelli.te.it",
      "pec": "comune.castelli@pec.it",
      "telefono": "+39 0861/979142",
      "fax": null,
      "coordinate": {
        "lat": 42.488887786865234,
        "lng": 13.712499618530273
      }
    },
    {
      "codice": "067013",
      "nome": "Castiglione Messer Raimondo",
      "nomeStraniero": null,
      "codiceCatastale": "C316",
      "cap": "64034",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "desanctis.rosanna@comunedicastiglione.gov.it.it",
      "pec": "postacert@pec.comunedicastiglione.gov.it",
      "telefono": "+39 0861/991222",
      "fax": "+39 0861/990509",
      "coordinate": {
        "lat": 42.53333282470703,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "067014",
      "nome": "Castilenti",
      "nomeStraniero": null,
      "codiceCatastale": "C322",
      "cap": "64035",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comunedicastilenti.gov.it",
      "pec": "protocollo@pec.comunedicastilenti.gov.it",
      "telefono": "+39 0861/999113",
      "fax": "+39 0861/999432",
      "coordinate": {
        "lat": 42.53333282470703,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "067015",
      "nome": "Cellino Attanasio",
      "nomeStraniero": null,
      "codiceCatastale": "C449",
      "cap": "64036",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comune.cellinoattanasio.te.it",
      "pec": "postacert@pec.comune.cellinoattanasio.te.it",
      "telefono": "+39 0861/ 669830",
      "fax": null,
      "coordinate": {
        "lat": 42.58333206176758,
        "lng": 13.866666793823242
      }
    },
    {
      "codice": "067016",
      "nome": "Cermignano",
      "nomeStraniero": null,
      "codiceCatastale": "C517",
      "cap": "64037",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comune.cermignano.te.gov.it",
      "pec": "certmail@pec.comune.cermignano.te.gov.it",
      "telefono": "+39 0861/66160",
      "fax": "+39 0861/667576",
      "coordinate": {
        "lat": 42.58333206176758,
        "lng": 13.800000190734863
      }
    },
    {
      "codice": "067017",
      "nome": "Civitella del Tronto",
      "nomeStraniero": null,
      "codiceCatastale": "C781",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "anagrafe@comunecivitelladeltronto.it",
      "pec": "segreteria@pec.comunecivitelladeltronto.it",
      "telefono": "+39 0861/ 918321",
      "fax": "+39 0861/ 918324",
      "coordinate": {
        "lat": 42.77194595336914,
        "lng": 13.666666984558105
      }
    },
    {
      "codice": "067018",
      "nome": "Colledara",
      "nomeStraniero": null,
      "codiceCatastale": "C311",
      "cap": "64042",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "comunedicolledara@virgilio.it",
      "pec": "postacert@pec.comune.colledara.te.it",
      "telefono": "+39 0861/698877",
      "fax": "+39 0861/698810",
      "coordinate": {
        "lat": 42.53333282470703,
        "lng": 13.683333396911621
      }
    },
    {
      "codice": "067019",
      "nome": "Colonnella",
      "nomeStraniero": null,
      "codiceCatastale": "C901",
      "cap": "64010",
      "prefisso": "0735",
      "provincia": "Teramo",
      "email": "anagrafe@comune.colonnella.te.it",
      "pec": "comune.colonnella@pec.it",
      "telefono": "+39 0861743420",
      "fax": "+39 0861743425",
      "coordinate": {
        "lat": 42.87229919433594,
        "lng": 13.86987018585205
      }
    },
    {
      "codice": "067020",
      "nome": "Controguerra",
      "nomeStraniero": null,
      "codiceCatastale": "C972",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "uffsegr@comunecontroguerra.it",
      "pec": "uffprotcomunecontroguerra@postecert.it",
      "telefono": "+39 0861/ 809901",
      "fax": "+39 0861/809016",
      "coordinate": {
        "lat": 42.849998474121094,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "067021",
      "nome": "Corropoli",
      "nomeStraniero": null,
      "codiceCatastale": "D043",
      "cap": "64013",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comunecorropoli.it",
      "pec": "protocollo@pec.comunecorropoli.it",
      "telefono": "+39 0861/8065120",
      "fax": "+39 0861/82262",
      "coordinate": {
        "lat": 42.83333206176758,
        "lng": 13.833333015441895
      }
    },
    {
      "codice": "067022",
      "nome": "Cortino",
      "nomeStraniero": null,
      "codiceCatastale": "D076",
      "cap": "64040",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "ufficiotecnico@comune.cortino.te.it",
      "pec": "protocollo@pec.comune.cortino.te.it",
      "telefono": "+39 0861/ 64112",
      "fax": "+39 0861/ 64331",
      "coordinate": {
        "lat": 42.62186050415039,
        "lng": 13.507740020751953
      }
    },
    {
      "codice": "067023",
      "nome": "Crognaleto",
      "nomeStraniero": null,
      "codiceCatastale": "D179",
      "cap": "64043",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "anagrafe@comunedicrognaleto.it",
      "pec": "anagrafecrognaleto@pcert.postecert.it",
      "telefono": "+39 0861/950110",
      "fax": "+39 0861/ 950288",
      "coordinate": {
        "lat": 42.58333206176758,
        "lng": 13.483333587646484
      }
    },
    {
      "codice": "067024",
      "nome": "Fano Adriano",
      "nomeStraniero": null,
      "codiceCatastale": "D489",
      "cap": "64044",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "servizidemografici@comune.fanoadriano.te.it",
      "pec": "protocollo@cert.comune.fanoadriano.te.it",
      "telefono": "+39 0861/95124",
      "fax": "+39 0861/958264",
      "coordinate": {
        "lat": 42.553890228271484,
        "lng": 13.536666870117188
      }
    },
    {
      "codice": "067025",
      "nome": "Giulianova",
      "nomeStraniero": null,
      "codiceCatastale": "E058",
      "cap": "64021",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "d.simone@comune.giulianova.te.it",
      "pec": "protocollogenerale@comunedigiulianova.it",
      "telefono": "+39 085/80211",
      "fax": "+39 085/80211",
      "coordinate": {
        "lat": 42.75251770019531,
        "lng": 13.956686019897461
      }
    },
    {
      "codice": "067026",
      "nome": "Isola del Gran Sasso d'Italia",
      "nomeStraniero": null,
      "codiceCatastale": "E343",
      "cap": "64045",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "info@comune.isola.te.it",
      "pec": "info@cert.isoladelgransasso.gov.it",
      "telefono": "+39 0861/975926",
      "fax": "+39 0861/975928",
      "coordinate": {
        "lat": 42.50749969482422,
        "lng": 13.657777786254883
      }
    },
    {
      "codice": "067047",
      "nome": "Martinsicuro",
      "nomeStraniero": null,
      "codiceCatastale": "E989",
      "cap": "64014",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "segreteria@martinsicuro.gov.it",
      "pec": "protocollo.martinsicuro@pec.it",
      "telefono": "+39 0861 7681",
      "fax": "+39 0861/760990",
      "coordinate": {
        "lat": 42.8851318359375,
        "lng": 13.91599178314209
      }
    },
    {
      "codice": "067027",
      "nome": "Montefino",
      "nomeStraniero": null,
      "codiceCatastale": "F500",
      "cap": "64030",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "comunedimontefino@accessocivico.it",
      "pec": "protocollo@pec.comune.montefino.te.it",
      "telefono": "+39 0861/990650",
      "fax": "+39 0861/990905",
      "coordinate": {
        "lat": 42.54999923706055,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "067028",
      "nome": "Montorio al Vomano",
      "nomeStraniero": null,
      "codiceCatastale": "F690",
      "cap": "64046",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "urbanistica@comune.montorio.te.it",
      "pec": "postacert@pec.comune.montorio.te.it",
      "telefono": "+39 0861/502218",
      "fax": "+39 0861/592509",
      "coordinate": {
        "lat": 42.58333206176758,
        "lng": 13.633333206176758
      }
    },
    {
      "codice": "067029",
      "nome": "Morro d'Oro",
      "nomeStraniero": null,
      "codiceCatastale": "F747",
      "cap": "64020",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "fabrizio.notarini@comunemorrodoro.it",
      "pec": "protocollo@pec.comune.morrodoro.te.it",
      "telefono": "+39 085/895145",
      "fax": "+39 085/8959566",
      "coordinate": {
        "lat": 42.66666793823242,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "067030",
      "nome": "Mosciano Sant'Angelo",
      "nomeStraniero": null,
      "codiceCatastale": "F764",
      "cap": "64023",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "servizidemografici@comune.mosciano.te.it",
      "pec": "comune.mosciano.te@legalmail.it",
      "telefono": "+39 085/80631218",
      "fax": "+39 085/80631215",
      "coordinate": {
        "lat": 42.75,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "067031",
      "nome": "Nereto",
      "nomeStraniero": null,
      "codiceCatastale": "F870",
      "cap": "64015",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "urbanistica@comune.nereto.te.it",
      "pec": "protocollo@pec.comune.nereto.te.it",
      "telefono": "+39 0861/ 806933",
      "fax": "+39 0861/ 806940",
      "coordinate": {
        "lat": 42.81999969482422,
        "lng": 13.816944122314453
      }
    },
    {
      "codice": "067032",
      "nome": "Notaresco",
      "nomeStraniero": null,
      "codiceCatastale": "F942",
      "cap": "64024",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "socialeamministrativa@comune.notaresco.te.it",
      "pec": "protocollo@pec.comunedinotaresco.gov.it",
      "telefono": "+39 085/ 895021",
      "fax": "+39 085/ 8959881",
      "coordinate": {
        "lat": 42.650001525878906,
        "lng": 13.899999618530273
      }
    },
    {
      "codice": "067033",
      "nome": "Penna Sant'Andrea",
      "nomeStraniero": null,
      "codiceCatastale": "G437",
      "cap": "64039",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "segretario@comune.pennasantandrea.te.it",
      "pec": "postacert@pec.comune.pennasantandrea.te.it",
      "telefono": "+39 0861/66120",
      "fax": "+39 0861/ 66120",
      "coordinate": {
        "lat": 42.599998474121094,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "067034",
      "nome": "Pietracamela",
      "nomeStraniero": null,
      "codiceCatastale": "G608",
      "cap": "64047",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "comunepietracamela@tin.it",
      "pec": "postacert@pec.comune.pietracamela.te.it",
      "telefono": "+39 0861/ 955112",
      "fax": "+39 0861/ 955214",
      "coordinate": {
        "lat": 42.52305603027344,
        "lng": 13.553333282470703
      }
    },
    {
      "codice": "067035",
      "nome": "Pineto",
      "nomeStraniero": null,
      "codiceCatastale": "F831",
      "cap": "64025",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "anagrafe@comune.pineto.te.it",
      "pec": "protocollo@pec.comune.pineto.te.it",
      "telefono": "0859497204",
      "fax": "+39 085/ 9492230",
      "coordinate": {
        "lat": 42.61666488647461,
        "lng": 14.066666603088379
      }
    },
    {
      "codice": "067036",
      "nome": "Rocca Santa Maria",
      "nomeStraniero": null,
      "codiceCatastale": "H440",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "roccasm@roccasm.it",
      "pec": "roccasm@pec.it",
      "telefono": "+39 0861 63122",
      "fax": "+39 0861 63279",
      "coordinate": {
        "lat": 42.68333435058594,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "067037",
      "nome": "Roseto degli Abruzzi",
      "nomeStraniero": null,
      "codiceCatastale": "F585",
      "cap": "64026",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": null,
      "pec": "protocollogenerale@pec.comune.roseto.te.it",
      "telefono": "+39 085894531",
      "fax": "+39 085/89453678",
      "coordinate": {
        "lat": 42.68333435058594,
        "lng": 14.016666412353516
      }
    },
    {
      "codice": "067038",
      "nome": "Sant'Egidio alla Vibrata",
      "nomeStraniero": null,
      "codiceCatastale": "I318",
      "cap": "64016",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "ced@comune.santegidioallavibrata.te.it",
      "pec": "pcertificata@pec.comune.santegidioallavibrata.te.it",
      "telefono": "+39 0861/ 846515",
      "fax": "+39 0861/ 840203",
      "coordinate": {
        "lat": 42.83333206176758,
        "lng": 13.716666221618652
      }
    },
    {
      "codice": "067039",
      "nome": "Sant'Omero",
      "nomeStraniero": null,
      "codiceCatastale": "I348",
      "cap": "64027",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@comune.santomero.te.it",
      "pec": "protocollo@pec.comune.santomero.te.it",
      "telefono": "+39 0861/ 88098",
      "fax": "+39 0861/ 88555",
      "coordinate": {
        "lat": 42.78333282470703,
        "lng": 13.800000190734863
      }
    },
    {
      "codice": "067040",
      "nome": "Silvi",
      "nomeStraniero": null,
      "codiceCatastale": "I741",
      "cap": "64028",
      "prefisso": "085",
      "provincia": "Teramo",
      "email": "nicolaorlando@comune.silvi.te.it",
      "pec": "affari.generali@pec.comune.silvi.te.it",
      "telefono": "+39 085/ 9357262",
      "fax": "+39 085/9357222",
      "coordinate": {
        "lat": 42.54999923706055,
        "lng": 14.116666793823242
      }
    },
    {
      "codice": "067041",
      "nome": "Teramo",
      "nomeStraniero": null,
      "codiceCatastale": "L103",
      "cap": "64100",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "r.angelini@comune.teramo.it",
      "pec": "affarigenerali@comune.teramo.pecpa.it",
      "telefono": "+39 0861 324299",
      "fax": "+39 0861 246168",
      "coordinate": {
        "lat": 42.658851623535156,
        "lng": 13.703910827636719
      }
    },
    {
      "codice": "067042",
      "nome": "Torano Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "L207",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "info@torano.it",
      "pec": "protocollotorano@postecert.it",
      "telefono": "+39 0861/ 82361",
      "fax": "+39 0861/810135",
      "coordinate": {
        "lat": 42.81666564941406,
        "lng": 13.783333778381348
      }
    },
    {
      "codice": "067043",
      "nome": "Torricella Sicura",
      "nomeStraniero": null,
      "codiceCatastale": "L295",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "torricellasiccomune@virgilio.it",
      "pec": "comune.torricellasicura@legalmail.it",
      "telefono": "+39 0861/554727",
      "fax": "+39 0861/554037",
      "coordinate": {
        "lat": 42.65999984741211,
        "lng": 13.658055305480957
      }
    },
    {
      "codice": "067044",
      "nome": "Tortoreto",
      "nomeStraniero": null,
      "codiceCatastale": "L307",
      "cap": "64018",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "anagrafe@comune.tortoreto.te.it",
      "pec": "comune@tortoretopec.it",
      "telefono": "+39 0861/ 7851",
      "fax": "+39 0861/788646",
      "coordinate": {
        "lat": 42.79999923706055,
        "lng": 13.916666984558105
      }
    },
    {
      "codice": "067045",
      "nome": "Tossicia",
      "nomeStraniero": null,
      "codiceCatastale": "L314",
      "cap": "64049",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "protocollo@peccomunetossicia.te.it",
      "pec": "protocollo@peccomunetossicia.te.it",
      "telefono": "0861698014",
      "fax": "+39 0861/ 698170",
      "coordinate": {
        "lat": 42.54999923706055,
        "lng": 13.649999618530273
      }
    },
    {
      "codice": "067046",
      "nome": "Valle Castellana",
      "nomeStraniero": null,
      "codiceCatastale": "L597",
      "cap": "64010",
      "prefisso": "0861",
      "provincia": "Teramo",
      "email": "comune.vallecastellana@virgilio.it",
      "pec": "postacert@pec.comune.vallecastellana.te.it",
      "telefono": "+39 0861/93130",
      "fax": "+39 0861/93557",
      "coordinate": {
        "lat": 42.733333587646484,
        "lng": 13.5
      }
    }
  ],
  "Sondrio": [
    {
      "codice": "014001",
      "nome": "Albaredo per San Marco",
      "nomeStraniero": null,
      "codiceCatastale": "A135",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.albaredopersanmarco.so.it",
      "pec": "protocollo.albaredo@cert.provincia.so.it",
      "telefono": "+39 0342/616288",
      "fax": "+39 0342/602253",
      "coordinate": {
        "lat": 46.102928161621094,
        "lng": 9.590459823608398
      }
    },
    {
      "codice": "014002",
      "nome": "Albosaggia",
      "nomeStraniero": null,
      "codiceCatastale": "A172",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.albosaggia.so.it",
      "pec": "protocollo.albosaggia@cert.provincia.so.it",
      "telefono": "+39 0342/510376",
      "fax": "+39 0342/513485",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "014003",
      "nome": "Andalo Valtellino",
      "nomeStraniero": null,
      "codiceCatastale": "A273",
      "cap": "23009",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "acandalo@provincia.so.it",
      "pec": "protocollo.andalo@cert.provincia.so.it",
      "telefono": "+39 0342 685183",
      "fax": "+39 0342 685522",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "014004",
      "nome": "Aprica",
      "nomeStraniero": null,
      "codiceCatastale": "A337",
      "cap": "23031",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.aprica.so.it",
      "pec": "protocollo.aprica@cert.provincia.so.it",
      "telefono": "+39 0342/746116",
      "fax": "+39 0342/747706",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 10.133333206176758
      }
    },
    {
      "codice": "014005",
      "nome": "Ardenno",
      "nomeStraniero": null,
      "codiceCatastale": "A382",
      "cap": "23011",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "acardenno@provincia.so.it",
      "pec": "protocollo.ardenno@cert.provincia.so.it",
      "telefono": "+39 0342 660370",
      "fax": "+39 0342 662068",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "014006",
      "nome": "Bema",
      "nomeStraniero": null,
      "codiceCatastale": "A777",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "acbema@provincia.so.it",
      "pec": "protocollo.bema@cert.provincia.so.it",
      "telefono": "+39 0342/618000",
      "fax": "+39 0342/618000",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "014007",
      "nome": "Berbenno di Valtellina",
      "nomeStraniero": null,
      "codiceCatastale": "A787",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.berbenno.so.it",
      "pec": "comune.berbennodivaltellina@pec.regione.lombardia.it",
      "telefono": "+39 0342 492108",
      "fax": "+39 0342 590359",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.75
      }
    },
    {
      "codice": "014008",
      "nome": "Bianzone",
      "nomeStraniero": null,
      "codiceCatastale": "A848",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.bianzone.so.it",
      "pec": "protocollo.bianzone@cert.provincia.so.it",
      "telefono": "+39 0342 720107",
      "fax": "+39 0342 721563",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "014009",
      "nome": "Bormio",
      "nomeStraniero": null,
      "codiceCatastale": "B049",
      "cap": "23032",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.bormio.so.it",
      "pec": "bormio@pec.cmav.so.it",
      "telefono": "+39 0342 912211",
      "fax": "+39 0342 904645",
      "coordinate": {
        "lat": 46.4692497253418,
        "lng": 10.372171401977539
      }
    },
    {
      "codice": "014010",
      "nome": "Buglio in Monte",
      "nomeStraniero": null,
      "codiceCatastale": "B255",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "anagrafe@comune.buglioinmonte.so.it",
      "pec": "protocollo.buglioinmonte@cert.provincia.so.it",
      "telefono": "+39 0342/620020",
      "fax": "+39 0342/621016",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 9.683333396911621
      }
    },
    {
      "codice": "014011",
      "nome": "Caiolo",
      "nomeStraniero": null,
      "codiceCatastale": "B366",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.caiolo.so.it",
      "pec": "protocollo.caiolo@cert.provincia.so.it",
      "telefono": "+39 0342/355004",
      "fax": "+39 0342/355015",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.800000190734863
      }
    },
    {
      "codice": "014012",
      "nome": "Campodolcino",
      "nomeStraniero": null,
      "codiceCatastale": "B530",
      "cap": "23021",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "comune@comune.campodolcino.so.it",
      "pec": "protocollo.campodolcino@cert.provincia.so.it",
      "telefono": "+39 0343/50113",
      "fax": "+39 0343/50575",
      "coordinate": {
        "lat": 46.40510177612305,
        "lng": 9.352999687194824
      }
    },
    {
      "codice": "014013",
      "nome": "Caspoggio",
      "nomeStraniero": null,
      "codiceCatastale": "B993",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "daniela.bruseghini@unionevalmalenco.gov.it",
      "pec": "protocollo@pec.comune.caspoggio.gov.it",
      "telefono": "+39 0342/453625",
      "fax": "+39 0342/556177",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "014014",
      "nome": "Castello dell'Acqua",
      "nomeStraniero": null,
      "codiceCatastale": "C186",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.castellodell\\\\\\'acqua.so.it",
      "pec": "comune.castellodellacqua@pec.regione.lombardia.it",
      "telefono": "+39 0342/482206",
      "fax": "+39 0342/484080",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "014015",
      "nome": "Castione Andevenno",
      "nomeStraniero": null,
      "codiceCatastale": "C325",
      "cap": "23012",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "anagrafe@castione.andevenno.so.it",
      "pec": "protocollo.castioneandevenno@cert.provincia.so.it",
      "telefono": "+39 0342/358006",
      "fax": "+39 0342/358595",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.800000190734863
      }
    },
    {
      "codice": "014016",
      "nome": "Cedrasco",
      "nomeStraniero": null,
      "codiceCatastale": "C418",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comunecedrasco.gov.it",
      "pec": "protocollo.cedrasco@cert.provincia.so.it",
      "telefono": "+39 0342/492159",
      "fax": "+39 0342493577",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "014017",
      "nome": "Cercino",
      "nomeStraniero": null,
      "codiceCatastale": "C493",
      "cap": "23016",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.cercino.so.it",
      "pec": "protocollo.cercino@cert.provincia.so.it",
      "telefono": "+39 0342/651820",
      "fax": "+39 0342/651872",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.5
      }
    },
    {
      "codice": "014018",
      "nome": "Chiavenna",
      "nomeStraniero": null,
      "codiceCatastale": "C623",
      "cap": "23022",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "info@comune.chiavenna.so.it",
      "pec": "protocollo.chiavenna@cert.provincia.so.it",
      "telefono": "+39 0343/220211",
      "fax": "+39 0343/220280",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "014019",
      "nome": "Chiesa in Valmalenco",
      "nomeStraniero": null,
      "codiceCatastale": "C628",
      "cap": "23023",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amanda.negrini@unionevalmalenco.it",
      "pec": "comune.chiesainvalmalenco@pec.regione.lombardia.it",
      "telefono": "+39 0342/451114",
      "fax": null,
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "014020",
      "nome": "Chiuro",
      "nomeStraniero": null,
      "codiceCatastale": "C651",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.chiuro.so.it",
      "pec": "protocollo.chiuro@cert.provincia.so.it",
      "telefono": "+39 0342/482221",
      "fax": null,
      "coordinate": {
        "lat": 46.17082214355469,
        "lng": 9.989355087280273
      }
    },
    {
      "codice": "014021",
      "nome": "Cino",
      "nomeStraniero": null,
      "codiceCatastale": "C709",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "accino@provincia.so.it",
      "pec": "protocollo.cino@cert.provincia.so.it",
      "telefono": "+39 0342/617275",
      "fax": "+39 0342/617361",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "014022",
      "nome": "Civo",
      "nomeStraniero": null,
      "codiceCatastale": "C785",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "demografici@comune.civo.so.it",
      "pec": "protocollo.civo@cert.provincia.so.it",
      "telefono": "+39 0342/650073",
      "fax": "+39 0342/659564",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "014023",
      "nome": "Colorina",
      "nomeStraniero": null,
      "codiceCatastale": "C903",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.colorina.so.it",
      "pec": "protocollo.colorina@cert.provincia.so.it",
      "telefono": "+39 0342/492113",
      "fax": "+39 0342/590116",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.733333587646484
      }
    },
    {
      "codice": "014024",
      "nome": "Cosio Valtellino",
      "nomeStraniero": null,
      "codiceCatastale": "D088",
      "cap": "23013",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.cosiovaltellino.so.it",
      "pec": "protocollo.cosio@cert.provincia.so.it",
      "telefono": "+39 0342/634111",
      "fax": "+39 0342/634141",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.533333778381348
      }
    },
    {
      "codice": "014025",
      "nome": "Dazio",
      "nomeStraniero": null,
      "codiceCatastale": "D258",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.dazio.so.it",
      "pec": "protocollo.dazio@cert.provincia.so.it",
      "telefono": "+39 0342/650123",
      "fax": "+39 0342/650725",
      "coordinate": {
        "lat": 46.16128921508789,
        "lng": 9.600640296936035
      }
    },
    {
      "codice": "014026",
      "nome": "Delebio",
      "nomeStraniero": null,
      "codiceCatastale": "D266",
      "cap": "23014",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.delebio.so.it",
      "pec": "protocollo.delebio@cert.provincia.so.it",
      "telefono": "+39 0342/685110",
      "fax": "+39 0342/684512",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.466666221618652
      }
    },
    {
      "codice": "014027",
      "nome": "Dubino",
      "nomeStraniero": null,
      "codiceCatastale": "D377",
      "cap": "23015",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "ufficioprotocollo@comune.dubino.so.it",
      "pec": "protocollo.dubino@cert.provincia.so.it",
      "telefono": "+39 0342/680195",
      "fax": "+39 0342/681176",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "014028",
      "nome": "Faedo Valtellino",
      "nomeStraniero": null,
      "codiceCatastale": "D456",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "anagrafe@comune.faedovaltellino.so.it",
      "pec": "protocollo.faedo@cert.provincia.so.it",
      "telefono": "+39 0342/566090",
      "fax": "+39 0342/566066",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "014029",
      "nome": "Forcola",
      "nomeStraniero": null,
      "codiceCatastale": "D694",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "acforcola@provincia.so.it",
      "pec": "protocollo.forcola@cert.provincia.so.it",
      "telefono": "+39 0342/668130",
      "fax": "+39 0342/668127",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.666666984558105
      }
    },
    {
      "codice": "014030",
      "nome": "Fusine",
      "nomeStraniero": null,
      "codiceCatastale": "D830",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.fusine.so.it",
      "pec": "protocollo.fusine@cert.provincia.so.it",
      "telefono": "+39 0342/492141",
      "fax": "+39 0342/590370",
      "coordinate": {
        "lat": 46.14904022216797,
        "lng": 9.74909782409668
      }
    },
    {
      "codice": "014031",
      "nome": "Gerola Alta",
      "nomeStraniero": null,
      "codiceCatastale": "D990",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.gerolaalta.so.it",
      "pec": "protocollo.gerola@cert.provincia.so.it.it",
      "telefono": "+39 0342 690024",
      "fax": "+39 0342 690521",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "014032",
      "nome": "Gordona",
      "nomeStraniero": null,
      "codiceCatastale": "E090",
      "cap": "23020",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "info@comune.gordona.so.it",
      "pec": "comune.gordona@pec.regione.lombardia.it",
      "telefono": "+39 0343/42321",
      "fax": "+39 0343/43321",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "014033",
      "nome": "Grosio",
      "nomeStraniero": null,
      "codiceCatastale": "E200",
      "cap": "23033",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "demografici@comune.grosio.so.it",
      "pec": "protocollo.grosio@cert.provincia.so.it",
      "telefono": "+39 0342/841211",
      "fax": "+39 0342/841231",
      "coordinate": {
        "lat": 46.29999923706055,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "014034",
      "nome": "Grosotto",
      "nomeStraniero": null,
      "codiceCatastale": "E201",
      "cap": "23034",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.grosotto.so.it",
      "pec": "protocollo.grosotto@cert.provincia.so.it",
      "telefono": "+39 0342/887107",
      "fax": "+39 0342/887385",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 10.266666412353516
      }
    },
    {
      "codice": "014036",
      "nome": "Lanzada",
      "nomeStraniero": null,
      "codiceCatastale": "E443",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.lanzada.gov.it",
      "pec": "protocollo@pec.comune.lanzada.gov.it",
      "telefono": "+39 0342/453243",
      "fax": "+39 0342/556385",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "014037",
      "nome": "Livigno",
      "nomeStraniero": null,
      "codiceCatastale": "E621",
      "cap": "23041",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "segreteria@comune.livigno.so.it",
      "pec": "comune.livigno@legalmail.it",
      "telefono": "+39 0342/991111",
      "fax": "+39 0342/991100",
      "coordinate": {
        "lat": 46.53817367553711,
        "lng": 10.135993003845215
      }
    },
    {
      "codice": "014038",
      "nome": "Lovero",
      "nomeStraniero": null,
      "codiceCatastale": "E705",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.lovero.so.it",
      "pec": "protocollo.lovero@cert.provincia.so.it",
      "telefono": "+39 0342/770055",
      "fax": "+39 0342/770006",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "014035",
      "nome": "Madesimo",
      "nomeStraniero": null,
      "codiceCatastale": "E342",
      "cap": "23024",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "info@comune.madesimo.so.it",
      "pec": "protocollo.madesimo@cert.provincia.so.it",
      "telefono": "+39 0343/53257",
      "fax": "+39 0343/54057",
      "coordinate": {
        "lat": 46.42815399169922,
        "lng": 9.3438720703125
      }
    },
    {
      "codice": "014039",
      "nome": "Mantello",
      "nomeStraniero": null,
      "codiceCatastale": "E896",
      "cap": "23016",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.mantello.so.it",
      "pec": "protocollo.mantello@cert.provincia.so.it",
      "telefono": "+39 0342/680156",
      "fax": "+39 0342/681200",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "014040",
      "nome": "Mazzo di Valtellina",
      "nomeStraniero": null,
      "codiceCatastale": "F070",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.mazzo.so.it",
      "pec": "protocollo.mazzo@cert.provincia.so.it",
      "telefono": "+39 0342/861014",
      "fax": "+39 0342/861288",
      "coordinate": {
        "lat": 46.25,
        "lng": 10.25
      }
    },
    {
      "codice": "014041",
      "nome": "Mello",
      "nomeStraniero": null,
      "codiceCatastale": "F115",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.mello.so.it",
      "pec": "protocollo.mello@cert.provincia.so.it",
      "telefono": "+39 0342 654031",
      "fax": "+39 0342 619514",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "014043",
      "nome": "Mese",
      "nomeStraniero": null,
      "codiceCatastale": "F153",
      "cap": "23020",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "protocollo@comunemese.gov.it",
      "pec": "protocollo.mese@cert.provincia.so.it",
      "telefono": "+39 0343 41000",
      "fax": "+39 0343 43213",
      "coordinate": {
        "lat": 46.29999923706055,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "014044",
      "nome": "Montagna in Valtellina",
      "nomeStraniero": null,
      "codiceCatastale": "F393",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.montagnainvaltellina.so.it",
      "pec": "protocollo.montagnainvaltellina@cert.provincia.so.it",
      "telefono": "+39 0342/380077",
      "fax": "+39 0342/382040",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 9.899999618530273
      }
    },
    {
      "codice": "014045",
      "nome": "Morbegno",
      "nomeStraniero": null,
      "codiceCatastale": "F712",
      "cap": "23017",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "municipio@morbegno.it",
      "pec": "protocollo.morbegno@cert.provincia.so.it",
      "telefono": "+39 0342/606211",
      "fax": "+39 0342/614530",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.566666603088379
      }
    },
    {
      "codice": "014046",
      "nome": "Novate Mezzola",
      "nomeStraniero": null,
      "codiceCatastale": "F956",
      "cap": "23025",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "protocollo@comune.novatemezzola.so.it",
      "pec": "protocollo.novatemezzola@cert.provincia.so.it",
      "telefono": "+39 0343/63040",
      "fax": "+39 0343/63050",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "014047",
      "nome": "Pedesina",
      "nomeStraniero": null,
      "codiceCatastale": "G410",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "acpedesina@provincia.so.it",
      "pec": "protocollo.pedesina@cert.provincia.so.it",
      "telefono": "+39 0342/651767",
      "fax": "+39 0342/651767",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "014048",
      "nome": "Piantedo",
      "nomeStraniero": null,
      "codiceCatastale": "G572",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "anagrafe@comune.piantedo.so.it",
      "pec": "protocollo.piantedo@cert.provincia.so.it",
      "telefono": "+39 0342/683361",
      "fax": "+39 0342/683382",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.416666984558105
      }
    },
    {
      "codice": "014049",
      "nome": "Piateda",
      "nomeStraniero": null,
      "codiceCatastale": "G576",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.piateda.so.it",
      "pec": "protocollo.piateda@cert.provincia.so.it",
      "telefono": "+39 0342/370221",
      "fax": "+39 0342/370598",
      "coordinate": {
        "lat": 46.15959930419922,
        "lng": 9.93494987487793
      }
    },
    {
      "codice": "014050",
      "nome": "Piuro",
      "nomeStraniero": null,
      "codiceCatastale": "G718",
      "cap": "23020",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "ufficio.protocollo@comune.piuro.so.it",
      "pec": "protocollo.piuro@cert.provincia.so.it",
      "telefono": "+39 0343 32327",
      "fax": "+39 0343/33432",
      "coordinate": {
        "lat": 46.3306999206543,
        "lng": 9.421699523925781
      }
    },
    {
      "codice": "014051",
      "nome": "Poggiridenti",
      "nomeStraniero": null,
      "codiceCatastale": "G431",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comunepoggiridenti.gov.it",
      "pec": "protocollo.poggiridenti@cert.provincia.so.it",
      "telefono": "+39 0342/380842",
      "fax": "+39 0342/380610",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.916666984558105
      }
    },
    {
      "codice": "014052",
      "nome": "Ponte in Valtellina",
      "nomeStraniero": null,
      "codiceCatastale": "G829",
      "cap": "23026",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "anagrafe@comune.ponteinvaltellina.so.it",
      "pec": "comune.ponteinvaltellina@pec.regione.lombardia.it",
      "telefono": "+39 0342/482222",
      "fax": "+39 0342/482196",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 9.983333587646484
      }
    },
    {
      "codice": "014053",
      "nome": "Postalesio",
      "nomeStraniero": null,
      "codiceCatastale": "G937",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comunepostalesio.so.it",
      "pec": "protocollo.postalesio@cert.provincia.so.it",
      "telefono": "+39 0342/563370",
      "fax": "+39 0342/563256",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.783333778381348
      }
    },
    {
      "codice": "014054",
      "nome": "Prata Camportaccio",
      "nomeStraniero": null,
      "codiceCatastale": "G993",
      "cap": "23020",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "protocollo@comune.pratacamportaccio.so.it",
      "pec": "comune.pratacamportaccio@pec.regione.lombardia.it",
      "telefono": "+39 0343 32351",
      "fax": "+39 0343/33570",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 9.399999618530273
      }
    },
    {
      "codice": "014055",
      "nome": "Rasura",
      "nomeStraniero": null,
      "codiceCatastale": "H192",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.rasura.so.it",
      "pec": "protocollo.rasura@cert.provincia.so.it",
      "telefono": "+39 0342 616006",
      "fax": "+39 0342 615630",
      "coordinate": {
        "lat": 46.099998474121094,
        "lng": 9.550000190734863
      }
    },
    {
      "codice": "014056",
      "nome": "Rogolo",
      "nomeStraniero": null,
      "codiceCatastale": "H493",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "acrogolo@provincia.so.it",
      "pec": "protocollo.rogolo@cert.provincia.so.it",
      "telefono": "+39 0342/68523",
      "fax": "+39 0342/602414",
      "coordinate": {
        "lat": 46.135555267333984,
        "lng": 9.487222671508789
      }
    },
    {
      "codice": "014057",
      "nome": "Samolaco",
      "nomeStraniero": null,
      "codiceCatastale": "H752",
      "cap": "23027",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "info@comune.samolaco.so.it",
      "pec": "protocollo.samolaco@cert.provincia.so.it",
      "telefono": "+39 0343/38003",
      "fax": "+39 0343/38113",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 9.383333206176758
      }
    },
    {
      "codice": "014058",
      "nome": "San Giacomo Filippo",
      "nomeStraniero": null,
      "codiceCatastale": "H868",
      "cap": "23020",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "acsgfilippo@provincia.so.it",
      "pec": "protocollo.sangiacomofilippo@cert.provincia.so.it",
      "telefono": "+39 0343 32430",
      "fax": "+39 0343/30245",
      "coordinate": {
        "lat": 46.33333206176758,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "014059",
      "nome": "Sernio",
      "nomeStraniero": null,
      "codiceCatastale": "I636",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.sernio.so.it",
      "pec": "protocollo.sernio@cert.provincia.so.it",
      "telefono": "+39 0342/701772",
      "fax": "+39 0342/704147",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 10.199999809265137
      }
    },
    {
      "codice": "014060",
      "nome": "Sondalo",
      "nomeStraniero": null,
      "codiceCatastale": "I828",
      "cap": "23035",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.sondalo.so.it",
      "pec": "sondalo@pec.cmav.so.it",
      "telefono": "+39 0342/809011",
      "fax": "+39 0342/809010",
      "coordinate": {
        "lat": 46.33333206176758,
        "lng": 10.333333015441895
      }
    },
    {
      "codice": "014061",
      "nome": "Sondrio",
      "nomeStraniero": null,
      "codiceCatastale": "I829",
      "cap": "23100",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "elettorale@comune.sondrio.it",
      "pec": "protocollo@cert.comune.sondrio.it",
      "telefono": "+39 0342/526111",
      "fax": "+39 0342/526333",
      "coordinate": {
        "lat": 46.16971969604492,
        "lng": 9.869999885559082
      }
    },
    {
      "codice": "014062",
      "nome": "Spriana",
      "nomeStraniero": null,
      "codiceCatastale": "I928",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.spriana.so.it",
      "pec": "comune.spriana@pec.regione.lombardia.it",
      "telefono": "+39 0342/512557",
      "fax": "+39 0342/512557",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 9.866666793823242
      }
    },
    {
      "codice": "014063",
      "nome": "Talamona",
      "nomeStraniero": null,
      "codiceCatastale": "L035",
      "cap": "23018",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "demografici@comune.talamona.so.it",
      "pec": "comune.talamona@halleycert.it",
      "telefono": "+39 0342/670703",
      "fax": "+39 0342/671488",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.616666793823242
      }
    },
    {
      "codice": "014064",
      "nome": "Tartano",
      "nomeStraniero": null,
      "codiceCatastale": "L056",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.tartano.so.it",
      "pec": "protocollo.tartano@cert.provincia.so.it",
      "telefono": "+39 0342 645004",
      "fax": "+39 0342 645300",
      "coordinate": {
        "lat": 46.11666488647461,
        "lng": 9.683333396911621
      }
    },
    {
      "codice": "014065",
      "nome": "Teglio",
      "nomeStraniero": null,
      "codiceCatastale": "L084",
      "cap": "23036",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "demografico@comune.teglio.so.it",
      "pec": "protocollo.teglio@cert.provincia.so.it",
      "telefono": "+39 0342/789011",
      "fax": "+39 0342/789090",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 10.066666603088379
      }
    },
    {
      "codice": "014066",
      "nome": "Tirano",
      "nomeStraniero": null,
      "codiceCatastale": "L175",
      "cap": "23037",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "ufficio.protocollo@comune.tirano.so.it",
      "pec": "comune.tirano@legalmail.it",
      "telefono": "+39 0342/701256",
      "fax": "+39 0342/704340",
      "coordinate": {
        "lat": 46.21638870239258,
        "lng": 10.168889045715332
      }
    },
    {
      "codice": "014067",
      "nome": "Torre di Santa Maria",
      "nomeStraniero": null,
      "codiceCatastale": "L244",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.torredisantamaria.so.it",
      "pec": "comune.torredisantamaria@pec.regione.lombardia.it",
      "telefono": "+39 0342/558300",
      "fax": "+39 0342/454361",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "014068",
      "nome": "Tovo di Sant'Agata",
      "nomeStraniero": null,
      "codiceCatastale": "L316",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "demografici@comune.tovo.so.it",
      "pec": "protocollo.tovo@cert.provincia.so.it",
      "telefono": "+39 0342/770058",
      "fax": "+39 0342/770058",
      "coordinate": {
        "lat": 46.25,
        "lng": 10.25
      }
    },
    {
      "codice": "014069",
      "nome": "Traona",
      "nomeStraniero": null,
      "codiceCatastale": "L330",
      "cap": "23019",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "amministrazione@comune.traona.so.it",
      "pec": "protocollo.traona@cert.provincia.so.it",
      "telefono": "+39 0342/652341",
      "fax": "+39 0342/653262",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.516666412353516
      }
    },
    {
      "codice": "014070",
      "nome": "Tresivio",
      "nomeStraniero": null,
      "codiceCatastale": "L392",
      "cap": "23020",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.tresivio.so.it",
      "pec": "protocollo.tresivio@cert.provincia.so.it",
      "telefono": "+39 0342/430109",
      "fax": "+39 0342/431591",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "014074",
      "nome": "Val Masino",
      "nomeStraniero": null,
      "codiceCatastale": "L638",
      "cap": "23010",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "anagrafe@comune.valmasino.so.it",
      "pec": "protocollo.valmasino@cert.provincia.so.it",
      "telefono": "+39 0342/640101",
      "fax": "+39 0342/640040",
      "coordinate": {
        "lat": 46.21277618408203,
        "lng": 9.637222290039062
      }
    },
    {
      "codice": "014071",
      "nome": "Valdidentro",
      "nomeStraniero": null,
      "codiceCatastale": "L557",
      "cap": "23038",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.valdidentro.so.it",
      "pec": "valdidentro@pec.cmav.so.it",
      "telefono": "+39 0342/921901",
      "fax": "+39 0342/921950",
      "coordinate": {
        "lat": 46.489105224609375,
        "lng": 10.295148849487305
      }
    },
    {
      "codice": "014072",
      "nome": "Valdisotto",
      "nomeStraniero": null,
      "codiceCatastale": "L563",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.valdisotto.so.it",
      "pec": "valdisotto@pec.cmav.so.it",
      "telefono": "+39 0342/952011",
      "fax": "+39 0342/952023",
      "coordinate": {
        "lat": 46.42787551879883,
        "lng": 10.356164932250977
      }
    },
    {
      "codice": "014073",
      "nome": "Valfurva",
      "nomeStraniero": null,
      "codiceCatastale": "L576",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "info@comune.valfurva.so.it",
      "pec": "valfurva@pec.cmav.so.it",
      "telefono": "+39 0342/945702",
      "fax": "+39 0342/945288",
      "coordinate": {
        "lat": 46.46260070800781,
        "lng": 10.411786079406738
      }
    },
    {
      "codice": "014075",
      "nome": "Verceia",
      "nomeStraniero": null,
      "codiceCatastale": "L749",
      "cap": "23020",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "protocollo@comune.verceia.so.it",
      "pec": "protocollo.verceia@cert.provincia.so.it",
      "telefono": "+39 0343/44137",
      "fax": "+39 0343/62549",
      "coordinate": {
        "lat": 46.20000076293945,
        "lng": 9.449999809265137
      }
    },
    {
      "codice": "014076",
      "nome": "Vervio",
      "nomeStraniero": null,
      "codiceCatastale": "L799",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.vervio.so.it",
      "pec": "protocollo.vervio@cert.provincia.so.it",
      "telefono": "+39 0342/770057",
      "fax": "+39 0342/775177",
      "coordinate": {
        "lat": 46.25,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "014077",
      "nome": "Villa di Chiavenna",
      "nomeStraniero": null,
      "codiceCatastale": "L907",
      "cap": "23029",
      "prefisso": "0343",
      "provincia": "Sondrio",
      "email": "info@comune.villadichiavenna.so.it",
      "pec": "protocollo.villadichiavenna@cert.provincia.so.it",
      "telefono": "+39 0343/40507",
      "fax": "+39 0343/40649",
      "coordinate": {
        "lat": 46.33333206176758,
        "lng": 9.483333587646484
      }
    },
    {
      "codice": "014078",
      "nome": "Villa di Tirano",
      "nomeStraniero": null,
      "codiceCatastale": "L908",
      "cap": "23030",
      "prefisso": "0342",
      "provincia": "Sondrio",
      "email": "protocollo@comune.villaditirano.so.it",
      "pec": "comune.villaditirano@pec.regione.lombardia.it",
      "telefono": "+39 0342/795001",
      "fax": "+39 0342/797570",
      "coordinate": {
        "lat": 46.20000076293945,
        "lng": 10.133333206176758
      }
    }
  ],
  "Parma": [
    {
      "codice": "034001",
      "nome": "Albareto",
      "nomeStraniero": null,
      "codiceCatastale": "A138",
      "cap": "43051",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "info@comune.albareto.pr.it",
      "pec": "protocollo@postacert.comune.albareto.pr.it",
      "telefono": "+39 0525/999231",
      "fax": "+39 0525/929459",
      "coordinate": {
        "lat": 44.44691848754883,
        "lng": 9.702280044555664
      }
    },
    {
      "codice": "034002",
      "nome": "Bardi",
      "nomeStraniero": null,
      "codiceCatastale": "A646",
      "cap": "43032",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "protocollo@comune.bardi.pr.it",
      "pec": "protocollo@postacert.comune.bardi.pr.it",
      "telefono": "+39 0525/71321",
      "fax": "+39 0525/71044",
      "coordinate": {
        "lat": 44.633609771728516,
        "lng": 9.731111526489258
      }
    },
    {
      "codice": "034003",
      "nome": "Bedonia",
      "nomeStraniero": null,
      "codiceCatastale": "A731",
      "cap": "43041",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "g.bresadola@comune.bedonia.pr.it",
      "pec": "protocollo@postacert.comune.bedonia.pr.it",
      "telefono": "+39 0525/824424",
      "fax": "+39 0525/824150",
      "coordinate": {
        "lat": 44.5,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "034004",
      "nome": "Berceto",
      "nomeStraniero": null,
      "codiceCatastale": "A788",
      "cap": "43042",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "protocollo@postacert.comune.berceto.pr.it",
      "pec": "protocollo@postacert.comune.berceto.pr.it",
      "telefono": "+39 0525/629210",
      "fax": "+39 0525/60293",
      "coordinate": {
        "lat": 44.5106086730957,
        "lng": 9.989654541015625
      }
    },
    {
      "codice": "034005",
      "nome": "Bore",
      "nomeStraniero": null,
      "codiceCatastale": "A987",
      "cap": "43030",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "bore@comune.bore.pr.it",
      "pec": "protocollo@postacert.comune.bore.pr.it",
      "telefono": "+39 0525/79137",
      "fax": "+39 0525/79207",
      "coordinate": {
        "lat": 44.71769332885742,
        "lng": 9.791777610778809
      }
    },
    {
      "codice": "034006",
      "nome": "Borgo Val di Taro",
      "nomeStraniero": null,
      "codiceCatastale": "B042",
      "cap": "43043",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "gl.dellapina@comune.borgo-val-di-taro.pr.it",
      "pec": "protocollo@postacert.comune.borgo-val-di-taro.pr.it",
      "telefono": "+39 0525/921741",
      "fax": "+39 0525/96218",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 9.766666412353516
      }
    },
    {
      "codice": "034007",
      "nome": "Busseto",
      "nomeStraniero": null,
      "codiceCatastale": "B293",
      "cap": "43011",
      "prefisso": "0524",
      "provincia": "Parma",
      "email": "info@comune.busseto.pr.it",
      "pec": "protocollo@postacert.comune.busseto.pr.it",
      "telefono": "+39 0524/931711",
      "fax": "+39 0524/92360",
      "coordinate": {
        "lat": 44.979400634765625,
        "lng": 10.043310165405273
      }
    },
    {
      "codice": "034008",
      "nome": "Calestano",
      "nomeStraniero": null,
      "codiceCatastale": "B408",
      "cap": "43030",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "calestano@comune.calestano.pr.it",
      "pec": "protocollo@postacert.comune.calestano.pr.it",
      "telefono": "+39 0525/52121",
      "fax": "+39 0525/528105",
      "coordinate": {
        "lat": 44.600555419921875,
        "lng": 10.122027397155762
      }
    },
    {
      "codice": "034009",
      "nome": "Collecchio",
      "nomeStraniero": null,
      "codiceCatastale": "C852",
      "cap": "43044",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "urp@comune.collecchio.pr.it",
      "pec": "protocollo@postacert.comune.collecchio.pr.it",
      "telefono": "+39 052130111",
      "fax": "+39 0521/301120",
      "coordinate": {
        "lat": 44.75269317626953,
        "lng": 10.215694427490234
      }
    },
    {
      "codice": "034010",
      "nome": "Colorno",
      "nomeStraniero": null,
      "codiceCatastale": "C904",
      "cap": "43052",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "protocollo@comune.colorno.pr.it",
      "pec": "protocollo@postacert.comune.colorno.pr.it",
      "telefono": "+39 0521/313711",
      "fax": "+39 0521/815505",
      "coordinate": {
        "lat": 44.93000030517578,
        "lng": 10.375833511352539
      }
    },
    {
      "codice": "034011",
      "nome": "Compiano",
      "nomeStraniero": null,
      "codiceCatastale": "C934",
      "cap": "43053",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "compiano@comune.compiano.pr.it",
      "pec": "protocollo@postacert.comune.compiano.pr.it",
      "telefono": "+39 0525/825125",
      "fax": "+39 0525/825528",
      "coordinate": {
        "lat": 44.495445251464844,
        "lng": 9.662583351135254
      }
    },
    {
      "codice": "034012",
      "nome": "Corniglio",
      "nomeStraniero": null,
      "codiceCatastale": "D026",
      "cap": "43021",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "segreteria@comune.corniglio.pr.it",
      "pec": "protocollo@postacert.comune.corniglio.pr.it",
      "telefono": "+39 0521/881221",
      "fax": "+39 0521/880272",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "034013",
      "nome": "Felino",
      "nomeStraniero": null,
      "codiceCatastale": "D526",
      "cap": "43035",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "cfelino@comune.felino.pr.it",
      "pec": "protocollo@postacert.comune.felino.pr.it",
      "telefono": "+39 0521/335951",
      "fax": "+39 0521/834661",
      "coordinate": {
        "lat": 44.69533157348633,
        "lng": 10.241555213928223
      }
    },
    {
      "codice": "034014",
      "nome": "Fidenza",
      "nomeStraniero": null,
      "codiceCatastale": "B034",
      "cap": "43036",
      "prefisso": "0524",
      "provincia": "Parma",
      "email": "ced@comune.fidenza.pr.it",
      "pec": "protocollo@postacert.comune.fidenza.pr.it",
      "telefono": "+39 0524/517111",
      "fax": "+39 0524/527239",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 10.066666603088379
      }
    },
    {
      "codice": "034015",
      "nome": "Fontanellato",
      "nomeStraniero": null,
      "codiceCatastale": "D673",
      "cap": "43012",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.fontanellato.pr.it",
      "pec": "protocollo@postacert.comune.fontanellato.pr.it",
      "telefono": "+39 0521/823227",
      "fax": "+39 0521/823236",
      "coordinate": {
        "lat": 44.88249969482422,
        "lng": 10.15583324432373
      }
    },
    {
      "codice": "034016",
      "nome": "Fontevivo",
      "nomeStraniero": null,
      "codiceCatastale": "D685",
      "cap": "43010",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.fontevivo.pr.it",
      "pec": "protocollo@postacert.comune.fontevivo.pr.it",
      "telefono": "+39 0521/611911",
      "fax": "+39 0521/610331",
      "coordinate": {
        "lat": 44.85647201538086,
        "lng": 10.17622184753418
      }
    },
    {
      "codice": "034017",
      "nome": "Fornovo di Taro",
      "nomeStraniero": null,
      "codiceCatastale": "D728",
      "cap": "43045",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "ufficiolegale@comune.fornovo-di-taro.pr.it",
      "pec": "protocollo@postacert.comune.fornovo-di-taro.pr.it",
      "telefono": "+39 0525/400615",
      "fax": "+39 0525/30310",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "034018",
      "nome": "Langhirano",
      "nomeStraniero": null,
      "codiceCatastale": "E438",
      "cap": "43013",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.langhirano.pr.it",
      "pec": "protocollo@postacert.comune.langhirano.pr.it",
      "telefono": "+39 0521/351213",
      "fax": "+39 0521/858240",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 10.266666412353516
      }
    },
    {
      "codice": "034019",
      "nome": "Lesignano de' Bagni",
      "nomeStraniero": null,
      "codiceCatastale": "E547",
      "cap": "43037",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.lesignano-debagni.pr.it",
      "pec": "protocollo@postacert.comune.lesignano-debagni.pr.it",
      "telefono": "+39 0521/850211",
      "fax": "+39 0521/850845",
      "coordinate": {
        "lat": 44.643001556396484,
        "lng": 10.299444198608398
      }
    },
    {
      "codice": "034020",
      "nome": "Medesano",
      "nomeStraniero": null,
      "codiceCatastale": "F082",
      "cap": "43014",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "savi@comune.medesano.pr.it",
      "pec": "protocollo@postacert.comune.medesano.pr.it",
      "telefono": "+39 0525/422724",
      "fax": "+39 0525/422710",
      "coordinate": {
        "lat": 44.756778717041016,
        "lng": 10.140305519104004
      }
    },
    {
      "codice": "034022",
      "nome": "Monchio delle Corti",
      "nomeStraniero": null,
      "codiceCatastale": "F340",
      "cap": "43010",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.monchio-delle-corti.pr.it",
      "pec": "protocollo@postacert.comune.monchio-delle-corti.pr.it",
      "telefono": "+39 0521/896521",
      "fax": "+39 0521/896714",
      "coordinate": {
        "lat": 44.41236114501953,
        "lng": 10.123332977294922
      }
    },
    {
      "codice": "034023",
      "nome": "Montechiarugolo",
      "nomeStraniero": null,
      "codiceCatastale": "F473",
      "cap": "43022",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "urp@comune.montechiarugolo.pr.it",
      "pec": "protocollo@postacert.comune.montechiarugolo.pr.it",
      "telefono": "+39 0521/687711",
      "fax": "+39 0521/686633",
      "coordinate": {
        "lat": 44.693389892578125,
        "lng": 10.422416687011719
      }
    },
    {
      "codice": "034024",
      "nome": "Neviano degli Arduini",
      "nomeStraniero": null,
      "codiceCatastale": "F882",
      "cap": "43024",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "c.fornari@comune.neviano-degli-arduini.pr.it",
      "pec": "protocollo@postacert.comune.neviano-degli-arduini.pr.it",
      "telefono": "+39 0521/345467",
      "fax": "+39 0521/843590",
      "coordinate": {
        "lat": 44.58333206176758,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "034025",
      "nome": "Noceto",
      "nomeStraniero": null,
      "codiceCatastale": "F914",
      "cap": "43015",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.noceto.it",
      "pec": "protocollo@postacert.comune.noceto.pr.it",
      "telefono": "+39 0521/622011",
      "fax": "+39 0521/622100",
      "coordinate": {
        "lat": 44.81666564941406,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "034026",
      "nome": "Palanzano",
      "nomeStraniero": null,
      "codiceCatastale": "G255",
      "cap": "43025",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "sindaco@comune.palanzano.pr.it",
      "pec": "protocollo@postacert.comune.palanzano.pr.it",
      "telefono": "+39 0521.891321",
      "fax": "+39 0521 891547",
      "coordinate": {
        "lat": 44.435943603515625,
        "lng": 10.193055152893066
      }
    },
    {
      "codice": "034027",
      "nome": "Parma",
      "nomeStraniero": null,
      "codiceCatastale": "G337",
      "cap": "43121",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "elettorale@comune.parma.it",
      "pec": "servizidemografici@pec.comune.parma.it",
      "telefono": "+39 0521/218637",
      "fax": "+39 0521/405214",
      "coordinate": {
        "lat": 44.80147171020508,
        "lng": 10.32800006866455
      }
    },
    {
      "codice": "034028",
      "nome": "Pellegrino Parmense",
      "nomeStraniero": null,
      "codiceCatastale": "G424",
      "cap": "43047",
      "prefisso": "0524",
      "provincia": "Parma",
      "email": "ragioneria@comune.pellegrino-parmense.pr.it",
      "pec": "protocollo@postacert.comune.pellegrino-parmense.pr.it",
      "telefono": "+39 0524/594664",
      "fax": "+39 0524/64569",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 9.927027702331543
      }
    },
    {
      "codice": "034050",
      "nome": "Polesine Zibello",
      "nomeStraniero": null,
      "codiceCatastale": "M367",
      "cap": "43016",
      "prefisso": "0524",
      "provincia": "Parma",
      "email": "municipio@comune.polesine-zibello.pr.it",
      "pec": "protocollo@postacert.comune.polesine-zibello.pr.it",
      "telefono": "+39 0524939711",
      "fax": "+39 052499557",
      "coordinate": {
        "lat": 45.016666412353516,
        "lng": 10.083333015441895
      }
    },
    {
      "codice": "034030",
      "nome": "Roccabianca",
      "nomeStraniero": null,
      "codiceCatastale": "H384",
      "cap": "43010",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "segreteria@comune.roccabianca.pr.it",
      "pec": "protocollo@postacert.comune.roccabianca.pr.it",
      "telefono": "+39 0521/876165",
      "fax": "+39 0521/876790",
      "coordinate": {
        "lat": 45.00863265991211,
        "lng": 10.219839096069336
      }
    },
    {
      "codice": "034031",
      "nome": "Sala Baganza",
      "nomeStraniero": null,
      "codiceCatastale": "H682",
      "cap": "43038",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "c.conti@comune.sala-baganza.pr.it",
      "pec": "protocollo@postacert.comune.sala-baganza.pr.it",
      "telefono": "+39 0521/331315",
      "fax": "+39 0521/834812",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "034032",
      "nome": "Salsomaggiore Terme",
      "nomeStraniero": null,
      "codiceCatastale": "H720",
      "cap": "43039",
      "prefisso": "0524",
      "provincia": "Parma",
      "email": "protocollo@comune.salsomaggiore-terme.pr.it",
      "pec": "protocollo@postacert.comune.salsomaggiore-terme.pr.it",
      "telefono": "+39 0524 580111",
      "fax": "+39 0524 580199",
      "coordinate": {
        "lat": 44.814762115478516,
        "lng": 9.979576110839844
      }
    },
    {
      "codice": "034033",
      "nome": "San Secondo Parmense",
      "nomeStraniero": null,
      "codiceCatastale": "I153",
      "cap": "43017",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "giuseppebotti@gmail.com",
      "pec": "protocollo@postacert.comune.san-secondo-parmense.pr.it",
      "telefono": "+39 3342833992",
      "fax": "+39 0521/872757",
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "034049",
      "nome": "Sissa Trecasali",
      "nomeStraniero": null,
      "codiceCatastale": "M325",
      "cap": "43018",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": null,
      "pec": "protocollo@postacert.comune.sissatrecasali.pr.it",
      "telefono": "+39 0521 52700",
      "fax": "+39 0521 877160",
      "coordinate": {
        "lat": 44.945594787597656,
        "lng": 10.279946327209473
      }
    },
    {
      "codice": "034035",
      "nome": "Solignano",
      "nomeStraniero": null,
      "codiceCatastale": "I803",
      "cap": "43040",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "protocollo@postacert.comune.solignano.pr.it",
      "pec": "protocollo@postacert.comune.solignano.pr.it",
      "telefono": "+39 0525/511611",
      "fax": "+39 0525/511625",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 9.983333587646484
      }
    },
    {
      "codice": "034036",
      "nome": "Soragna",
      "nomeStraniero": null,
      "codiceCatastale": "I840",
      "cap": "43019",
      "prefisso": "0524",
      "provincia": "Parma",
      "email": "info@comune.soragna.pr.it",
      "pec": "protocollo@postacert.comune.soragna.pr.it",
      "telefono": "+39 0524 598911",
      "fax": null,
      "coordinate": {
        "lat": 44.92702865600586,
        "lng": 10.122166633605957
      }
    },
    {
      "codice": "034051",
      "nome": "Sorbolo Mezzani",
      "nomeStraniero": null,
      "codiceCatastale": "M411",
      "cap": "43058",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "info@comune.sorbolomezzani.pr.it",
      "pec": "protocollo@postacert.comune.sorbolomezzani.pr.it",
      "telefono": "+39 0521 669 611",
      "fax": "+39 0521 669 669",
      "coordinate": {
        "lat": 44.90358352661133,
        "lng": 10.468583106994629
      }
    },
    {
      "codice": "034038",
      "nome": "Terenzo",
      "nomeStraniero": null,
      "codiceCatastale": "E548",
      "cap": "43040",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "comune@comune.terenzo.pr.it",
      "pec": "protocollo@postacert.comune.terenzo.pr.it",
      "telefono": "+39 0525/527603",
      "fax": "+39 0525/527602",
      "coordinate": {
        "lat": 44.61022186279297,
        "lng": 10.090110778808594
      }
    },
    {
      "codice": "034039",
      "nome": "Tizzano Val Parma",
      "nomeStraniero": null,
      "codiceCatastale": "L183",
      "cap": "43028",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "e.iattoni@comune.tizzano-val-parma.pr.it",
      "pec": "protocollo@postacert.comune.tizzano-val-parma.pr.it",
      "telefono": "+39 0521/868531",
      "fax": "+39 0521/868937",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 10.199999809265137
      }
    },
    {
      "codice": "034040",
      "nome": "Tornolo",
      "nomeStraniero": null,
      "codiceCatastale": "L229",
      "cap": "43059",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "tornolo@comune.tornolo.pr.it",
      "pec": "protocollo@postacert.comune.tornolo.pr.it",
      "telefono": "+39 0525/824501",
      "fax": "+39 0525/825299",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 9.633333206176758
      }
    },
    {
      "codice": "034041",
      "nome": "Torrile",
      "nomeStraniero": null,
      "codiceCatastale": "L299",
      "cap": "43056",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "a.mallozzi@comune.torrile.pr.it",
      "pec": "protocollo@postacert.comune.torrile.pr.it",
      "telefono": "+39 0521/812915",
      "fax": "+39 0521/813292",
      "coordinate": {
        "lat": 44.920860290527344,
        "lng": 10.326360702514648
      }
    },
    {
      "codice": "034042",
      "nome": "Traversetolo",
      "nomeStraniero": null,
      "codiceCatastale": "L346",
      "cap": "43029",
      "prefisso": "0521",
      "provincia": "Parma",
      "email": "zanni@comune.traversetolo.pr.it",
      "pec": "protocollo@postacert.comune.traversetolo.pr.it",
      "telefono": "+39 0521/344528",
      "fax": "+39 0521/344550",
      "coordinate": {
        "lat": 44.63994598388672,
        "lng": 10.381750106811523
      }
    },
    {
      "codice": "034044",
      "nome": "Valmozzola",
      "nomeStraniero": null,
      "codiceCatastale": "L641",
      "cap": "43050",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "l.galli@comune.valmozzola.pr.it",
      "pec": "protocollo@postacert.comune.valmozzola.pr.it",
      "telefono": "+39 0525/67144",
      "fax": "+39 0525/67001",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 9.883333206176758
      }
    },
    {
      "codice": "034045",
      "nome": "Varano de' Melegari",
      "nomeStraniero": null,
      "codiceCatastale": "L672",
      "cap": "43040",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "protocollo@comune.varano-demelegari.pr.it",
      "pec": "protocollo@postacert.comune.varano-demelegari.pr.it",
      "telefono": "+39 0525/550038",
      "fax": "+39 0525/550502",
      "coordinate": {
        "lat": 44.68333435058594,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "034046",
      "nome": "Varsi",
      "nomeStraniero": null,
      "codiceCatastale": "L689",
      "cap": "43049",
      "prefisso": "0525",
      "provincia": "Parma",
      "email": "segreteria@comune.varsi.pr.it",
      "pec": "protocollo@postacert.comune.varsi.pr.it",
      "telefono": "+39 0525/74421",
      "fax": "+39 0525/74189",
      "coordinate": {
        "lat": 44.66255569458008,
        "lng": 9.844416618347168
      }
    }
  ],
  "Como": [
    {
      "codice": "013003",
      "nome": "Albavilla",
      "nomeStraniero": null,
      "codiceCatastale": "A143",
      "cap": "22031",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ced@comune.albavilla.co.it",
      "pec": "comune.albavilla@pec.provincia.como.it",
      "telefono": "+39 031/3354330",
      "fax": "+39 031/3354331",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013004",
      "nome": "Albese con Cassano",
      "nomeStraniero": null,
      "codiceCatastale": "A153",
      "cap": "22032",
      "prefisso": "031",
      "provincia": "Como",
      "email": "tributi@comune.albeseconcassano.co.it",
      "pec": "comune.albeseconcassano.co@halleycert.it",
      "telefono": "+39 031/426122",
      "fax": "+39 031/360224",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "013005",
      "nome": "Albiolo",
      "nomeStraniero": null,
      "codiceCatastale": "A164",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ufficio.elettorale@comune.albiolo.co.it",
      "pec": "comune.albiolo@pec.regione.lombardia.it",
      "telefono": "+39 0031/806428",
      "fax": "+39 031/807840",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "013006",
      "nome": "Alserio",
      "nomeStraniero": null,
      "codiceCatastale": "A224",
      "cap": "22040",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.alserio.co.it",
      "pec": "comune.alserio@halleypec.it",
      "telefono": "+39 031/630063",
      "fax": "+39 031/630917",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "013253",
      "nome": "Alta Valle Intelvi",
      "nomeStraniero": null,
      "codiceCatastale": "M383",
      "cap": "22024",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.altavalleintelvi.co.it",
      "pec": "comune.altavalleintelvi@pec.it",
      "telefono": "+39 031840132",
      "fax": "+39 031840652",
      "coordinate": {
        "lat": 45.984798431396484,
        "lng": 9.027099609375
      }
    },
    {
      "codice": "013007",
      "nome": "Alzate Brianza",
      "nomeStraniero": null,
      "codiceCatastale": "A249",
      "cap": "22040",
      "prefisso": "031",
      "provincia": "Como",
      "email": "m.sangiorgio@alzatebrianza.org",
      "pec": "comune.alzate@legalmail.it",
      "telefono": "+39 031/6349307",
      "fax": "+39 031/632785",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013009",
      "nome": "Anzano del Parco",
      "nomeStraniero": null,
      "codiceCatastale": "A319",
      "cap": "22040",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.anzano.co.it",
      "pec": "comune.anzanodelparco@pec.regione.lombardia.it",
      "telefono": "+39 031/630030",
      "fax": "+39 031/632131",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "013010",
      "nome": "Appiano Gentile",
      "nomeStraniero": null,
      "codiceCatastale": "A333",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "r.cassani@comune.appianogentile.co.it",
      "pec": "info@pec.comune.appianogentile.co.it",
      "telefono": "+39 031/930225",
      "fax": "+39 031/890323",
      "coordinate": {
        "lat": 45.73833465576172,
        "lng": 8.979722023010254
      }
    },
    {
      "codice": "013011",
      "nome": "Argegno",
      "nomeStraniero": null,
      "codiceCatastale": "A391",
      "cap": "22010",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.argegno.co.it",
      "pec": "comune.argegno@pec.provincia.como.it",
      "telefono": "+39 031/821120",
      "fax": "+39 031/821977",
      "coordinate": {
        "lat": 45.94333267211914,
        "lng": 9.12833309173584
      }
    },
    {
      "codice": "013012",
      "nome": "Arosio",
      "nomeStraniero": null,
      "codiceCatastale": "A430",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "demografici@comune.arosio.co.it",
      "pec": "comune.arosio@halleypec.it",
      "telefono": "+39 031/76041",
      "fax": "+39 031/763686",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "013013",
      "nome": "Asso",
      "nomeStraniero": null,
      "codiceCatastale": "A476",
      "cap": "22033",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comunediasso.it",
      "pec": "comune.asso@pec.provincia.como.it",
      "telefono": "+39 031/673916",
      "fax": "+39 031/673942",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013015",
      "nome": "Barni",
      "nomeStraniero": null,
      "codiceCatastale": "A670",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.barni.co.it",
      "pec": "comune.barni@pec.regione.lombardia.it",
      "telefono": "031965136",
      "fax": "031965645",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013250",
      "nome": "Bellagio",
      "nomeStraniero": null,
      "codiceCatastale": "M335",
      "cap": "22021",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segretario@comune.bellagio.co.it",
      "pec": "comune.bellagio.co@halleycert.it",
      "telefono": "+39 031 950815",
      "fax": "+39 031 950513",
      "coordinate": {
        "lat": 45.98749923706055,
        "lng": 9.261944770812988
      }
    },
    {
      "codice": "013021",
      "nome": "Bene Lario",
      "nomeStraniero": null,
      "codiceCatastale": "A778",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "benelario@tin.it",
      "pec": "comune.benelario@pec.regione.lombardia.it",
      "telefono": "+39 0344 31246",
      "fax": "+39 0344/344570",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013022",
      "nome": "Beregazzo con Figliaro",
      "nomeStraniero": null,
      "codiceCatastale": "A791",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.beregazzoconfigliaro.co.it",
      "pec": "comune.beregazzoconfigliaro@pec.provincia.como.it",
      "telefono": "+39 031/940252",
      "fax": "+39 031/940024",
      "coordinate": {
        "lat": 45.781944274902344,
        "lng": 8.943333625793457
      }
    },
    {
      "codice": "013023",
      "nome": "Binago",
      "nomeStraniero": null,
      "codiceCatastale": "A870",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.binago.co.it",
      "pec": "comune.binago@pec.regione.lombardia.it",
      "telefono": "+39 031/940142",
      "fax": "+39 031/942327",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.916666984558105
      }
    },
    {
      "codice": "013024",
      "nome": "Bizzarone",
      "nomeStraniero": null,
      "codiceCatastale": "A898",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ced@terredifrontiera.co.it",
      "pec": "comune.bizzarone@pec.provincia.como.it",
      "telefono": "+39 031/948857",
      "fax": "+39 031/949266",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "013025",
      "nome": "Blessagno",
      "nomeStraniero": null,
      "codiceCatastale": "A904",
      "cap": "22028",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.blessagno.co.it",
      "pec": "comune.blessagno@pec.provincia.como.it",
      "telefono": "+39 031/830444",
      "fax": "+39 031/833808",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "013026",
      "nome": "Blevio",
      "nomeStraniero": null,
      "codiceCatastale": "A905",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "tecnico.blevio@tiscalinet.it",
      "pec": "comune.blevio@pec.regione.lombardia.it",
      "telefono": "+39 031/419477",
      "fax": "+39 031/418277",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "013028",
      "nome": "Bregnano",
      "nomeStraniero": null,
      "codiceCatastale": "B134",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.bregnano.co.it",
      "pec": "comune.bregnano@pec.provincia.como.it",
      "telefono": "+39 031/771554",
      "fax": "+39 031/722022",
      "coordinate": {
        "lat": 45.69722366333008,
        "lng": 9.061111450195312
      }
    },
    {
      "codice": "013029",
      "nome": "Brenna",
      "nomeStraniero": null,
      "codiceCatastale": "B144",
      "cap": "22040",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.brenna.co.it",
      "pec": "comune.brenna@pec.provincia.como.it",
      "telefono": "+39 031/797070",
      "fax": "+39 031/797572",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013030",
      "nome": "Brienno",
      "nomeStraniero": null,
      "codiceCatastale": "B172",
      "cap": "22010",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comunedibrienno@tin.it",
      "pec": "comune.brienno@pec.provincia.como.it",
      "telefono": "+39 031/814016",
      "fax": "+39 031/814214",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013032",
      "nome": "Brunate",
      "nomeStraniero": null,
      "codiceCatastale": "B218",
      "cap": "22034",
      "prefisso": "031",
      "provincia": "Como",
      "email": "marco.nosedapedraglio@comune.brunate.co.it",
      "pec": "comune.brunate@pec.provincia.como.it",
      "telefono": "+39 031/220301",
      "fax": "+39 031/221357",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "013034",
      "nome": "Bulgarograsso",
      "nomeStraniero": null,
      "codiceCatastale": "B262",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "affarigenerali@comune.bulgarograsso.co.it",
      "pec": "comune.bulgarograsso@halleypec.it",
      "telefono": "+39 031/930141",
      "fax": "+39 031/890150",
      "coordinate": {
        "lat": 45.75,
        "lng": 9
      }
    },
    {
      "codice": "013035",
      "nome": "Cabiate",
      "nomeStraniero": null,
      "codiceCatastale": "B313",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "restuccia-g.carlo@comune.cabiate.co.it",
      "pec": "info@pec.comune.cabiate.co.it",
      "telefono": "+39 031/769311",
      "fax": "+39 031/756203",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "013036",
      "nome": "Cadorago",
      "nomeStraniero": null,
      "codiceCatastale": "B346",
      "cap": "22071",
      "prefisso": "031",
      "provincia": "Como",
      "email": "lavori.pubblici@comune.cadorago.co.it",
      "pec": "comune.cadorago@pec.regione.lombardia.it",
      "telefono": "+39 031/903100",
      "fax": "+39 031/904719",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "013037",
      "nome": "Caglio",
      "nomeStraniero": null,
      "codiceCatastale": "B355",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.caglio.co.it",
      "pec": "comune.caglio@pec.regione.lombardia.it",
      "telefono": "+39 031/667017",
      "fax": "+39 031/677137",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013040",
      "nome": "Campione d'Italia",
      "nomeStraniero": null,
      "codiceCatastale": "B513",
      "cap": "6911",
      "prefisso": "091",
      "provincia": "Como",
      "email": "marcof@comunecampione.ch",
      "pec": "protocollo.pec@comunecampione.it",
      "telefono": "+39 031/272463",
      "fax": "+41 916496013",
      "coordinate": {
        "lat": 45.97083282470703,
        "lng": 8.970833778381348
      }
    },
    {
      "codice": "013041",
      "nome": "Cantù",
      "nomeStraniero": null,
      "codiceCatastale": "B639",
      "cap": "22063",
      "prefisso": "031",
      "provincia": "Como",
      "email": "innovazionetecnologica@comune.cantu.co.it",
      "pec": "comune.cantu@pec.regione.lombardia.it",
      "telefono": "+39 031/717254",
      "fax": "+39 031/717265",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013042",
      "nome": "Canzo",
      "nomeStraniero": null,
      "codiceCatastale": "B641",
      "cap": "22035",
      "prefisso": "031",
      "provincia": "Como",
      "email": "commercio@comune.canzo.co.it",
      "pec": "segreteria.comune.canzo@pec.regione.lombardia.it",
      "telefono": "+39 031/674151",
      "fax": "+39 031/674141",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013043",
      "nome": "Capiago Intimiano",
      "nomeStraniero": null,
      "codiceCatastale": "B653",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "m.ronchetti@comune.capiago-intimiano.co.it",
      "pec": "comune.capiago-intimiano@legalmail.it",
      "telefono": "+39 031/4630320",
      "fax": "+39 031/462313",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013044",
      "nome": "Carate Urio",
      "nomeStraniero": null,
      "codiceCatastale": "B730",
      "cap": "22010",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.carateurio.co.it",
      "pec": "comune.carateurio@pec.provincia.como.it",
      "telefono": "+39 031/400132",
      "fax": "+39 031/400135",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013045",
      "nome": "Carbonate",
      "nomeStraniero": null,
      "codiceCatastale": "B742",
      "cap": "22070",
      "prefisso": "0331",
      "provincia": "Como",
      "email": "info@comune.carbonate.co.it",
      "pec": "comune.carbonate@pec.regione.lombardia.it",
      "telefono": "+39 0331/830302",
      "fax": "+39 0331/821310",
      "coordinate": {
        "lat": 45.68583297729492,
        "lng": 8.939167022705078
      }
    },
    {
      "codice": "013046",
      "nome": "Carimate",
      "nomeStraniero": null,
      "codiceCatastale": "B778",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ufficio.ragioneria@comunecarimate.it",
      "pec": "comune.carimate@pec.regione.lombardia.it",
      "telefono": "+39 031/7894135",
      "fax": "+39 031/7894100",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013047",
      "nome": "Carlazzo",
      "nomeStraniero": null,
      "codiceCatastale": "B785",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "protocollo@comune.carlazzo.co.it",
      "pec": "comunecarlazzo@legalmail.it",
      "telefono": "+39 0344/70123",
      "fax": "+39 0344/71009",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "013048",
      "nome": "Carugo",
      "nomeStraniero": null,
      "codiceCatastale": "B851",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.carugo.co.it",
      "pec": "protocollo@pec.comune.carugo.co.it",
      "telefono": "+39 031/758193",
      "fax": "+39 031/763667",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "013052",
      "nome": "Caslino d'Erba",
      "nomeStraniero": null,
      "codiceCatastale": "B974",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ufficiotecnico@comune.caslinoderba.co.it",
      "pec": "comune.caslinoderba@pec.como.it",
      "telefono": "+39 031/621033",
      "fax": "+39 031/622719",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013053",
      "nome": "Casnate con Bernate",
      "nomeStraniero": null,
      "codiceCatastale": "B977",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.casnateconbernate.co.it",
      "pec": "comune.casnateconbernate@pec.provincia.como.it",
      "telefono": "+39 031/457200",
      "fax": "+39 031/564094",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.066666603088379
      }
    },
    {
      "codice": "013055",
      "nome": "Cassina Rizzardi",
      "nomeStraniero": null,
      "codiceCatastale": "C020",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.cassinarizzardi.co.it",
      "pec": "comune.cassinarizzardi@pec.regione.lombardia.it",
      "telefono": "+39 031/8829301",
      "fax": "+39 031/8829390",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "013058",
      "nome": "Castelmarte",
      "nomeStraniero": null,
      "codiceCatastale": "C206",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comune@comune.castelmarte.co.it",
      "pec": "comune.castelmarte@pec.provincia.como.it",
      "telefono": "+39 031/620600",
      "fax": "+39 031/622575",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013059",
      "nome": "Castelnuovo Bozzente",
      "nomeStraniero": null,
      "codiceCatastale": "C220",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comune@comune.castelnuovobozzente.co.it",
      "pec": "comune.castelnuovobozzente@pec.it",
      "telefono": "+39 031988203",
      "fax": "+39 031988545",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "013062",
      "nome": "Cavargna",
      "nomeStraniero": null,
      "codiceCatastale": "C381",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.cavargna.co.it",
      "pec": "comune.cavargna@pec.regione.lombardia.it",
      "telefono": "+39 0344/63164",
      "fax": "+39 0344/63164",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013254",
      "nome": "Centro Valle Intelvi",
      "nomeStraniero": null,
      "codiceCatastale": "M394",
      "cap": "22023",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.centrovalleintelvi.co.it",
      "pec": "centrovalleintelvi@pec.it",
      "telefono": "031/830141",
      "fax": "031/831742",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "013063",
      "nome": "Cerano d'Intelvi",
      "nomeStraniero": null,
      "codiceCatastale": "C482",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.ceranodintelvi.co.it",
      "pec": "comune.ceranodintelvi@pec.regione.lombardia.it",
      "telefono": "+39 031/817110",
      "fax": "+39 031/817808",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "013064",
      "nome": "Cermenate",
      "nomeStraniero": null,
      "codiceCatastale": "C516",
      "cap": "22072",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ced@comune.cermenate.co.it",
      "pec": "cermenate@pec.provincia.como.it",
      "telefono": "+39 031/7776125",
      "fax": "+39 031/7776146",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "013065",
      "nome": "Cernobbio",
      "nomeStraniero": null,
      "codiceCatastale": "C520",
      "cap": "22012",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.cernobbio.co.it",
      "pec": "comune.cernobbio.co@halleycert.it",
      "telefono": "+39 031/343211",
      "fax": "+39 031/511147",
      "coordinate": {
        "lat": 45.83805465698242,
        "lng": 9.075833320617676
      }
    },
    {
      "codice": "013068",
      "nome": "Cirimido",
      "nomeStraniero": null,
      "codiceCatastale": "C724",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ragioneria@comune.cirimido.co.it",
      "pec": "comune.cirimido@pec.regione.lombardia.it",
      "telefono": "+39 031/935712",
      "fax": "+39 031/937596",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "013071",
      "nome": "Claino con Osteno",
      "nomeStraniero": null,
      "codiceCatastale": "C787",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.clainoconosteno.co.it",
      "pec": "comune.clainoconosteno@pec.regione.lombardia.it",
      "telefono": "+39 0344/65111",
      "fax": "+39 0344/73926",
      "coordinate": {
        "lat": 46,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "013074",
      "nome": "Colonno",
      "nomeStraniero": null,
      "codiceCatastale": "C902",
      "cap": "22010",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.colonno.co.it",
      "pec": "colonno@pec.comune.colonno.co.it",
      "telefono": "+39 031/821280",
      "fax": "+39 031/821912",
      "coordinate": {
        "lat": 45.95777893066406,
        "lng": 9.155555725097656
      }
    },
    {
      "codice": "013251",
      "nome": "Colverde",
      "nomeStraniero": null,
      "codiceCatastale": "M336",
      "cap": "22041",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.colverde.co.it",
      "pec": "comune.colverde@legalmail.it",
      "telefono": "+39 031 440191",
      "fax": "+39 031 440579",
      "coordinate": {
        "lat": 45.81089782714844,
        "lng": 9.007752418518066
      }
    },
    {
      "codice": "013075",
      "nome": "Como",
      "nomeStraniero": null,
      "codiceCatastale": "C933",
      "cap": "22100",
      "prefisso": "031",
      "provincia": "Como",
      "email": "fazio.giovanni@comune.como.it",
      "pec": "comune.como@comune.pec.como.it",
      "telefono": "+39 031/252274",
      "fax": "+39 031/242512",
      "coordinate": {
        "lat": 45.81024932861328,
        "lng": 9.086138725280762
      }
    },
    {
      "codice": "013077",
      "nome": "Corrido",
      "nomeStraniero": null,
      "codiceCatastale": "D041",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "anagrafe@comune.corrido.co.it",
      "pec": "protocollo@pec.comune.corrido.co.it",
      "telefono": "+39 0344/61058",
      "fax": "+39 0344/62567",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013083",
      "nome": "Cremia",
      "nomeStraniero": null,
      "codiceCatastale": "D147",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "ragioneria@comune.cremia.co.it",
      "pec": "comune.cremia.co@halleycert.it",
      "telefono": "+39 0344/87131",
      "fax": "+39 0344/86069",
      "coordinate": {
        "lat": 46.087100982666016,
        "lng": 9.271499633789062
      }
    },
    {
      "codice": "013084",
      "nome": "Cucciago",
      "nomeStraniero": null,
      "codiceCatastale": "D196",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ufficio.info@comune.cucciago.co.it",
      "pec": "comune.cucciago.co@halleycert.it",
      "telefono": "+39 031/787302",
      "fax": "+39 031/725244",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "013085",
      "nome": "Cusino",
      "nomeStraniero": null,
      "codiceCatastale": "D232",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "comunecusino@mclink.it",
      "pec": "comune.cusino@pec.regione.lombardia.it",
      "telefono": "+39 0344/66134",
      "fax": "+39 0344/66134",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "013087",
      "nome": "Dizzasco",
      "nomeStraniero": null,
      "codiceCatastale": "D310",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.dizzasco.co.it",
      "pec": "comune.dizzasco@pec.provincia.como.it",
      "telefono": "+39 031/821202",
      "fax": "+39 031/821779",
      "coordinate": {
        "lat": 45.943782806396484,
        "lng": 9.099736213684082
      }
    },
    {
      "codice": "013089",
      "nome": "Domaso",
      "nomeStraniero": null,
      "codiceCatastale": "D329",
      "cap": "22013",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "protocollo@comunedomaso.it",
      "pec": "protocollo.comune.domaso@pec.regione.lombardia.it",
      "telefono": "+39 0344/95073",
      "fax": "+39 0344/95128",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "013090",
      "nome": "Dongo",
      "nomeStraniero": null,
      "codiceCatastale": "D341",
      "cap": "22014",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info.dongo@comune.dongo.co.it",
      "pec": "comune.dongo@pec.provincia.como.it",
      "telefono": "+39 0344/81150",
      "fax": "+39 0344/82276",
      "coordinate": {
        "lat": 46.12998962402344,
        "lng": 9.281689643859863
      }
    },
    {
      "codice": "013092",
      "nome": "Dosso del Liro",
      "nomeStraniero": null,
      "codiceCatastale": "D355",
      "cap": "22015",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "comunedosso@libero.it",
      "pec": "comune.dossodelliro@pec.regione.lombardia.it",
      "telefono": "+39 0344/89941",
      "fax": "+39 0344/89989",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013095",
      "nome": "Erba",
      "nomeStraniero": null,
      "codiceCatastale": "D416",
      "cap": "22036",
      "prefisso": "031",
      "provincia": "Como",
      "email": "e.roncareggi@comune.erba.co.it",
      "pec": "comune.erba@pec.provincia.como.it",
      "telefono": "+39 031/615364",
      "fax": "+39 031/615310",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "013097",
      "nome": "Eupilio",
      "nomeStraniero": null,
      "codiceCatastale": "D445",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.eupilio.co.it",
      "pec": "comuneeupilio@pec.como.it",
      "telefono": "+39 031/655623",
      "fax": "+39 031/657445",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013098",
      "nome": "Faggeto Lario",
      "nomeStraniero": null,
      "codiceCatastale": "D462",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.faggetolario.co.it",
      "pec": "comune.faggetolario@pec.regione.lombardia.it",
      "telefono": "+39 031/378222",
      "fax": "+39 031/378120",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "013099",
      "nome": "Faloppio",
      "nomeStraniero": null,
      "codiceCatastale": "D482",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ragioneria@comune.faloppio.co.it",
      "pec": "comune.faloppio@pec.provincia.como.it",
      "telefono": "+39 031/986100",
      "fax": "+39 031/991028",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "013100",
      "nome": "Fenegrò",
      "nomeStraniero": null,
      "codiceCatastale": "D531",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ragioneria@comune.fenegro.co.it",
      "pec": "comune.fenegro@pec.regione.lombardia.it",
      "telefono": "+39 031/935722",
      "fax": "+39 031/937666",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9
      }
    },
    {
      "codice": "013101",
      "nome": "Figino Serenza",
      "nomeStraniero": null,
      "codiceCatastale": "D579",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comune@comune.figinoserenza.co.it",
      "pec": "comune.figinoserenza@pec.provincia.como.it",
      "telefono": "+39 031/780160",
      "fax": "+39 031/781936",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013102",
      "nome": "Fino Mornasco",
      "nomeStraniero": null,
      "codiceCatastale": "D605",
      "cap": "22073",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.finomornasco.co.it",
      "pec": "comune.finomornasco@halleycert.it",
      "telefono": "+39 031/883811",
      "fax": "+39 031/8838241",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "013106",
      "nome": "Garzeno",
      "nomeStraniero": null,
      "codiceCatastale": "D930",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.garzeno.co.it",
      "pec": "comune.garzeno@pec.regione.lombardia.it",
      "telefono": "+39 0344/88081",
      "fax": "+39 0344/88522",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 9.25
      }
    },
    {
      "codice": "013107",
      "nome": "Gera Lario",
      "nomeStraniero": null,
      "codiceCatastale": "D974",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.geralario.co.it",
      "pec": "comune.geralario@pec.regione.lombardia.it",
      "telefono": "+39 0344/84156",
      "fax": "+39 0344/8440",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "013110",
      "nome": "Grandate",
      "nomeStraniero": null,
      "codiceCatastale": "E139",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.grandate.co.it",
      "pec": "comune.grandate@halleycert.it",
      "telefono": "+39 031/457811",
      "fax": "+39 031/564086",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.066666603088379
      }
    },
    {
      "codice": "013111",
      "nome": "Grandola ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "E141",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "comune@comune.grandolaeduniti.co.it",
      "pec": "comune.grandolaeduniti@pec.regione.lombardia.it",
      "telefono": "+39 034432115",
      "fax": "+39 034430247",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "013249",
      "nome": "Gravedona ed Uniti",
      "nomeStraniero": null,
      "codiceCatastale": "M315",
      "cap": "22015",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "s.cattaneo@comune.gravedonaeduniti.co.it",
      "pec": "comune.gravedonaeduniti@halleypec.it",
      "telefono": "+39 0344 85291",
      "fax": "+39 0344 85453",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "013113",
      "nome": "Griante",
      "nomeStraniero": null,
      "codiceCatastale": "E172",
      "cap": "22011",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "tecnico@comune.griante.co.it",
      "pec": "griante@pec.comune.griante.co.it",
      "telefono": "+39 0344/5583725",
      "fax": "+39 0344/42316",
      "coordinate": {
        "lat": 46,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013114",
      "nome": "Guanzate",
      "nomeStraniero": null,
      "codiceCatastale": "E235",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.guanzate.co.it",
      "pec": "comune.guanzate@pec.regione.lombardia.it",
      "telefono": "+39 031/35217111",
      "fax": "+39 031/976598",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "013118",
      "nome": "Inverigo",
      "nomeStraniero": null,
      "codiceCatastale": "E309",
      "cap": "22044",
      "prefisso": "031",
      "provincia": "Como",
      "email": "affari.generali@comune.inverigo.co.it",
      "pec": "comune.inverigo@pec.provincia.como.it",
      "telefono": "+39 031/3594200",
      "fax": "+39 031/608961",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "013119",
      "nome": "Laglio",
      "nomeStraniero": null,
      "codiceCatastale": "E405",
      "cap": "22010",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.laglio.co.it",
      "pec": "laglio.segreteria@legalmail.it",
      "telefono": "+39 031400148",
      "fax": "+39 031401113",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013120",
      "nome": "Laino",
      "nomeStraniero": null,
      "codiceCatastale": "E416",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.laino.co.it",
      "pec": "comune.laino@pec.provincia.como.it",
      "telefono": "+39 031/830251",
      "fax": "+39 031/831947",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.083333015441895
      }
    },
    {
      "codice": "013121",
      "nome": "Lambrugo",
      "nomeStraniero": null,
      "codiceCatastale": "E428",
      "cap": "22045",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ufficio.anagrafe@comune.lambrugo.co.it",
      "pec": "comune.lambrugo@pec.provincia.como.it",
      "telefono": "+39 031/607212",
      "fax": "+39 031/605409",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013123",
      "nome": "Lasnigo",
      "nomeStraniero": null,
      "codiceCatastale": "E462",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.lasnigo.co.it",
      "pec": "comune.lasnigo@halleypec.it",
      "telefono": "+39 031/681725",
      "fax": "+39 031/670235",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013126",
      "nome": "Lezzeno",
      "nomeStraniero": null,
      "codiceCatastale": "E569",
      "cap": "22025",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.lezzeno.co.it",
      "pec": "comune.lezzeno@pec.provincia.como.it",
      "telefono": "+39 031/915413",
      "fax": "+39 031/915130",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.199999809265137
      }
    },
    {
      "codice": "013128",
      "nome": "Limido Comasco",
      "nomeStraniero": null,
      "codiceCatastale": "E593",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.limidocomasco.co.it",
      "pec": "info@pec.comune.limidocomasco.co.it",
      "telefono": "+39 031935705",
      "fax": "+39 031895210",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "013129",
      "nome": "Lipomo",
      "nomeStraniero": null,
      "codiceCatastale": "E607",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.lipomo.co.it",
      "pec": "comune.lipomo@pec.provincia.como.it",
      "telefono": "+39 031 558211",
      "fax": "+39 031 558380",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013130",
      "nome": "Livo",
      "nomeStraniero": null,
      "codiceCatastale": "E623",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.livo.co.it",
      "pec": "comune.livo@pec.provincia.como.it",
      "telefono": "+39 0344/89761",
      "fax": "+39 0344/90252",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "013131",
      "nome": "Locate Varesino",
      "nomeStraniero": null,
      "codiceCatastale": "E638",
      "cap": "22070",
      "prefisso": "0331",
      "provincia": "Como",
      "email": "ufficidemografici@comune.locatevaresino.co.it",
      "pec": "locatevaresino@actaliscertymail.it",
      "telefono": "+39 0331/830148",
      "fax": "+39 0331/821330",
      "coordinate": {
        "lat": 45.690555572509766,
        "lng": 8.930832862854004
      }
    },
    {
      "codice": "013133",
      "nome": "Lomazzo",
      "nomeStraniero": null,
      "codiceCatastale": "E659",
      "cap": "22074",
      "prefisso": "02",
      "provincia": "Como",
      "email": "lavoripubblici@comune.lomazzo.co.it",
      "pec": "comune.lomazzo@pec.provincia.como.it",
      "telefono": "+39 02/96941240",
      "fax": "+39 02/96779146",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "013134",
      "nome": "Longone al Segrino",
      "nomeStraniero": null,
      "codiceCatastale": "E679",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.longonealsegrino.co.it",
      "pec": "comune.longonealsegrino@pec.como.it",
      "telefono": "+39 031/641650",
      "fax": "+39 031/610602",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.25
      }
    },
    {
      "codice": "013135",
      "nome": "Luisago",
      "nomeStraniero": null,
      "codiceCatastale": "E735",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "demografici@comune.luisago.co.it",
      "pec": "comune.luisago@legalmail.it",
      "telefono": "+39 031/880601",
      "fax": "+39 031/880575",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "013136",
      "nome": "Lurago d'Erba",
      "nomeStraniero": null,
      "codiceCatastale": "E749",
      "cap": "22040",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.luragoderba.co.it",
      "pec": "comune.luragoderba@legalmail.it",
      "telefono": "+39 031/3599511",
      "fax": "+39 031/3599540",
      "coordinate": {
        "lat": 45.75,
        "lng": 9.216666221618652
      }
    },
    {
      "codice": "013137",
      "nome": "Lurago Marinone",
      "nomeStraniero": null,
      "codiceCatastale": "E750",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.luragomarinone.co.it",
      "pec": "comune.luragomarinone@pec.regione.lombardia.it",
      "telefono": "+39 031/895565",
      "fax": "+39 031/937208",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "013138",
      "nome": "Lurate Caccivio",
      "nomeStraniero": null,
      "codiceCatastale": "E753",
      "cap": "22075",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ced@comune.luratecaccivio.co.it",
      "pec": "protocollo@pec.comune.luratecaccivio.co.it",
      "telefono": "+39 031/494328",
      "fax": "+39 031/390761",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9
      }
    },
    {
      "codice": "013139",
      "nome": "Magreglio",
      "nomeStraniero": null,
      "codiceCatastale": "E830",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.magreglio.co.it",
      "pec": "comune.magreglio@legalmail.it",
      "telefono": "+39 031/965119",
      "fax": "+39 031/965544",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013143",
      "nome": "Mariano Comense",
      "nomeStraniero": null,
      "codiceCatastale": "E951",
      "cap": "22066",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ced@comune.mariano-comense.co.it",
      "pec": "comune.marianocomense@halleycert.it",
      "telefono": "+39 031757221",
      "fax": "+39 031749287",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013144",
      "nome": "Maslianico",
      "nomeStraniero": null,
      "codiceCatastale": "F017",
      "cap": "22026",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.maslianico.co.it",
      "pec": "comune.maslianico@legalmail.it",
      "telefono": "+39 031 511374",
      "fax": "+39 031 510484",
      "coordinate": {
        "lat": 45.84282684326172,
        "lng": 9.047196388244629
      }
    },
    {
      "codice": "013145",
      "nome": "Menaggio",
      "nomeStraniero": null,
      "codiceCatastale": "F120",
      "cap": "22017",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "ragioneria@comune.menaggio.co.it",
      "pec": "comune.menaggio@pec.provincia.como.it",
      "telefono": "+39 0344/36411",
      "fax": "+39 0344/31728",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013147",
      "nome": "Merone",
      "nomeStraniero": null,
      "codiceCatastale": "F151",
      "cap": "22046",
      "prefisso": "031",
      "provincia": "Como",
      "email": "affarigenerali@comune.merone.co.it",
      "pec": "comune.merone.co@halleycert.it",
      "telefono": "+39 031/650000",
      "fax": "+39 031/651549",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.25
      }
    },
    {
      "codice": "013152",
      "nome": "Moltrasio",
      "nomeStraniero": null,
      "codiceCatastale": "F305",
      "cap": "22010",
      "prefisso": "031",
      "provincia": "Como",
      "email": "statocivile@comune.moltrasio.co.it",
      "pec": "comune.moltrasio@pec.provincia.como.it",
      "telefono": "+39 031/290402",
      "fax": "+39 031/290280",
      "coordinate": {
        "lat": 45.86050033569336,
        "lng": 9.097200393676758
      }
    },
    {
      "codice": "013153",
      "nome": "Monguzzo",
      "nomeStraniero": null,
      "codiceCatastale": "F372",
      "cap": "22040",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comune.monguzzo@comune.monguzzo.co.it",
      "pec": "comune.monguzzo.co.@halleycert.it",
      "telefono": "+39 031/650211",
      "fax": "+39 031/617312",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013154",
      "nome": "Montano Lucino",
      "nomeStraniero": null,
      "codiceCatastale": "F427",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ediliziaprivata@comune.montanolucino.co.it",
      "pec": "comune.montanolucino@pec.provincia.como.it",
      "telefono": "+39 031/478031",
      "fax": "+39 031/470253",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "013155",
      "nome": "Montemezzo",
      "nomeStraniero": null,
      "codiceCatastale": "F564",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.montemezzo.co.it",
      "pec": "comunemontemezzo@pec.provincia.como.it",
      "telefono": "+39 0344/84374",
      "fax": "+39 0344/84810",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.366666793823242
      }
    },
    {
      "codice": "013157",
      "nome": "Montorfano",
      "nomeStraniero": null,
      "codiceCatastale": "F688",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.montorfano.co.it",
      "pec": "comune.montorfano@pec.provincia.como.it",
      "telefono": "+39 031/553310",
      "fax": "+39 031/553097",
      "coordinate": {
        "lat": 45.785457611083984,
        "lng": 9.146079063415527
      }
    },
    {
      "codice": "013159",
      "nome": "Mozzate",
      "nomeStraniero": null,
      "codiceCatastale": "F788",
      "cap": "22076",
      "prefisso": "0331",
      "provincia": "Como",
      "email": "protocollo@comune.mozzate.co.it",
      "pec": "comune.mozzate@pec.provincia.como.it",
      "telefono": "+39 0331/838011",
      "fax": "+39 0331/833950",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 8.949999809265137
      }
    },
    {
      "codice": "013160",
      "nome": "Musso",
      "nomeStraniero": null,
      "codiceCatastale": "F828",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "segreteria@comune.musso.co.it",
      "pec": "comune.musso@halleypec.it",
      "telefono": "+39 0344/81154",
      "fax": "+39 0344/82498",
      "coordinate": {
        "lat": 46.11249923706055,
        "lng": 9.275833129882812
      }
    },
    {
      "codice": "013161",
      "nome": "Nesso",
      "nomeStraniero": null,
      "codiceCatastale": "F877",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.nesso.co.it",
      "pec": "comune.nesso@pec.regione.lombardia.it",
      "telefono": "+39 031/910106",
      "fax": "+39 031/910005",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "013163",
      "nome": "Novedrate",
      "nomeStraniero": null,
      "codiceCatastale": "F958",
      "cap": "22060",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comune@comune.novedrate.co.it",
      "pec": "comune.novedrate@pec.provincia.como.it",
      "telefono": "+39 031/7897711",
      "fax": "+39 031/790316",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013165",
      "nome": "Olgiate Comasco",
      "nomeStraniero": null,
      "codiceCatastale": "G025",
      "cap": "22077",
      "prefisso": "031",
      "provincia": "Como",
      "email": "urp@comune.olgiate-comasco.co.it",
      "pec": "comune.olgiate-comasco@legalmail.it",
      "telefono": "+39 031/994611",
      "fax": "+39 031/944792",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.966666221618652
      }
    },
    {
      "codice": "013169",
      "nome": "Oltrona di San Mamette",
      "nomeStraniero": null,
      "codiceCatastale": "G056",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.oltronadisanmamette.co.it",
      "pec": "comune.oltronadisanmamette@pec.it",
      "telefono": "+39 031/930267",
      "fax": "+39 031/890600",
      "coordinate": {
        "lat": 45.75638961791992,
        "lng": 8.976666450500488
      }
    },
    {
      "codice": "013170",
      "nome": "Orsenigo",
      "nomeStraniero": null,
      "codiceCatastale": "G126",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "r.noseda@comune.orsenigo.co.it",
      "pec": "comune.orsenigo@pec.provincia.como.it",
      "telefono": "+39 031/630225",
      "fax": "+39 031/632628",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013178",
      "nome": "Peglio",
      "nomeStraniero": null,
      "codiceCatastale": "G415",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "peglioco@tin.it",
      "pec": "comune.peglio@emarche.it",
      "telefono": "+39 0344/89710",
      "fax": "+39 0344/89711",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "013183",
      "nome": "Pianello del Lario",
      "nomeStraniero": null,
      "codiceCatastale": "G556",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.pianellodellario.co.it",
      "pec": "comune.pianellodellario@pec.provincia.como.it",
      "telefono": "+39 0344/87121",
      "fax": "+39 0344/86339",
      "coordinate": {
        "lat": 46.102901458740234,
        "lng": 9.27649974822998
      }
    },
    {
      "codice": "013184",
      "nome": "Pigra",
      "nomeStraniero": null,
      "codiceCatastale": "G665",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.pigra.co.it",
      "pec": "comune.pigra@pec.provincia.como.it",
      "telefono": "+39 031/810990",
      "fax": "+39 031/810988",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013185",
      "nome": "Plesio",
      "nomeStraniero": null,
      "codiceCatastale": "G737",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "anagrafe@comune.plesio.co.it",
      "pec": "comune.plesio@pec.regione.lombardia.it",
      "telefono": "+39 0344/37065",
      "fax": "+39 0344/37362",
      "coordinate": {
        "lat": 46.04944610595703,
        "lng": 9.225555419921875
      }
    },
    {
      "codice": "013186",
      "nome": "Pognana Lario",
      "nomeStraniero": null,
      "codiceCatastale": "G773",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.pognanalario.co.it",
      "pec": "comune.pognanalario@pec.regione.lombardia.it",
      "telefono": "+39 031/377888",
      "fax": "+39 031/377911",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "013187",
      "nome": "Ponna",
      "nomeStraniero": null,
      "codiceCatastale": "G821",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.ponna.co.it",
      "pec": "comune.ponna.co@halleycert.it",
      "telefono": "+39 031/844937",
      "fax": "+39 031/844700",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "013188",
      "nome": "Ponte Lambro",
      "nomeStraniero": null,
      "codiceCatastale": "G847",
      "cap": "22037",
      "prefisso": "031",
      "provincia": "Como",
      "email": "gianfranco.angeletti@comunepontelambro.co.it",
      "pec": "comune.pontelambro.co@halleycert.it",
      "telefono": "+39 031/623240",
      "fax": "+39 031/622491",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "013189",
      "nome": "Porlezza",
      "nomeStraniero": null,
      "codiceCatastale": "G889",
      "cap": "22018",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "segreteria@comune.porlezza.co.it",
      "pec": "porlezza@pec.it",
      "telefono": "+39 0344/61105",
      "fax": "+39 0344/61733",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013192",
      "nome": "Proserpio",
      "nomeStraniero": null,
      "codiceCatastale": "H074",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.proserpio.co.it",
      "pec": "comune.proserpio.co@halleypec.it",
      "telefono": "+39 031/621660",
      "fax": "+39 031/621660",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.25
      }
    },
    {
      "codice": "013193",
      "nome": "Pusiano",
      "nomeStraniero": null,
      "codiceCatastale": "H094",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "servizi.demografici@comune.pusiano.co.it",
      "pec": "comune.pusiano@pec.regione.lombardia.it",
      "telefono": "+39 031/655704",
      "fax": "+39 031/657142",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 9.283333778381348
      }
    },
    {
      "codice": "013195",
      "nome": "Rezzago",
      "nomeStraniero": null,
      "codiceCatastale": "H255",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comunerezzago@libero.it",
      "pec": "comune.rezzago@pec.regione.lombardia.it",
      "telefono": "+39 031/667012",
      "fax": "+39 031/677150",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.25
      }
    },
    {
      "codice": "013197",
      "nome": "Rodero",
      "nomeStraniero": null,
      "codiceCatastale": "H478",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "segreteria@comune.rodero.co.it",
      "pec": "comune.rodero@pec.regione.lombardia.it",
      "telefono": "+39 031/806344",
      "fax": "+39 031/807010",
      "coordinate": {
        "lat": 45.82490158081055,
        "lng": 8.916299819946289
      }
    },
    {
      "codice": "013199",
      "nome": "Ronago",
      "nomeStraniero": null,
      "codiceCatastale": "H521",
      "cap": "22027",
      "prefisso": "031",
      "provincia": "Como",
      "email": "servizidemografici@comune.ronago.co.it",
      "pec": "comune.ronago@pec.regione.lombardia.it",
      "telefono": "+39 031/980043",
      "fax": "+39 031/980321",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "013201",
      "nome": "Rovellasca",
      "nomeStraniero": null,
      "codiceCatastale": "H601",
      "cap": "22069",
      "prefisso": "02",
      "provincia": "Como",
      "email": "segreteria@comune.rovellasca.co.it",
      "pec": "comune.rovellasca@pec.provincia.como.it",
      "telefono": "+39 02/96961801",
      "fax": "+39 02/96740297",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "013202",
      "nome": "Rovello Porro",
      "nomeStraniero": null,
      "codiceCatastale": "H602",
      "cap": "22070",
      "prefisso": "02",
      "provincia": "Como",
      "email": "festa@comune.rovelloporro.co.it",
      "pec": "comune.rovelloporro@pec.provincia.como.it",
      "telefono": "+39 0296755122",
      "fax": "+39 02/96750920",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 9.033333778381348
      }
    },
    {
      "codice": "013203",
      "nome": "Sala Comacina",
      "nomeStraniero": null,
      "codiceCatastale": "H679",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "anagrafe@comune.salacomacina.co.it",
      "pec": "comune.salacomacina@pec.provincia.como.it",
      "telefono": "+39 0344/55102",
      "fax": "+39 0344/56397",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 9.166666984558105
      }
    },
    {
      "codice": "013204",
      "nome": "San Bartolomeo Val Cavargna",
      "nomeStraniero": null,
      "codiceCatastale": "H760",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "anagrafe@comune.sanbartolomeovalcavargna.co.it",
      "pec": "comune.sanbartolomevc@pec.regione.lombardia.it",
      "telefono": "+39 0344/66123",
      "fax": "+39 0344/66574",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "013206",
      "nome": "San Fermo della Battaglia",
      "nomeStraniero": null,
      "codiceCatastale": "H840",
      "cap": "22042",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.sanfermodellabattaglia.co.it",
      "pec": "comune.sanfermodellabattaglia@pec.provincia.como.it",
      "telefono": "+39 031/5440029",
      "fax": "+39 031/212309",
      "coordinate": {
        "lat": 45.80849838256836,
        "lng": 9.048999786376953
      }
    },
    {
      "codice": "013207",
      "nome": "San Nazzaro Val Cavargna",
      "nomeStraniero": null,
      "codiceCatastale": "I051",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "segreteria@comune.sannazzarovalcavargna.co.it",
      "pec": "comune.snazzarovc@pec.regione.lombardia.it",
      "telefono": "+39 0344/66113",
      "fax": "+39 0344/79097",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 9.133333206176758
      }
    },
    {
      "codice": "013248",
      "nome": "San Siro",
      "nomeStraniero": null,
      "codiceCatastale": "I162",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.sansiro.co.it",
      "pec": "info.comune.sansiro@pec.regione.lombardia.it",
      "telefono": "+39 0344 50015",
      "fax": "+39 0344 50668",
      "coordinate": {
        "lat": 46.06472396850586,
        "lng": 9.266666412353516
      }
    },
    {
      "codice": "013211",
      "nome": "Schignano",
      "nomeStraniero": null,
      "codiceCatastale": "I529",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "comunedischignano.comune@tin.it",
      "pec": "comune.schignano@pec.provincia.como.it",
      "telefono": "+39 031/819603",
      "fax": "+39 031/819717",
      "coordinate": {
        "lat": 45.92624282836914,
        "lng": 9.1030912399292
      }
    },
    {
      "codice": "013212",
      "nome": "Senna Comasco",
      "nomeStraniero": null,
      "codiceCatastale": "I611",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.sennacomasco.co.it",
      "pec": "sennacomasco1@halleycert.it",
      "telefono": "+39 031/460150",
      "fax": "+39 031/462553",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 9.100000381469727
      }
    },
    {
      "codice": "013255",
      "nome": "Solbiate con Cagno",
      "nomeStraniero": null,
      "codiceCatastale": "M412",
      "cap": "22043",
      "prefisso": "031",
      "provincia": "Como",
      "email": "demografici@comune.solbiateconcagno.co.it",
      "pec": "comune.solbiateconcagno@pec.provincia.como.it",
      "telefono": "+39 031/940249",
      "fax": null,
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "013216",
      "nome": "Sorico",
      "nomeStraniero": null,
      "codiceCatastale": "I856",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.sorico.co.it",
      "pec": "comune.sorico@halleycert.it",
      "telefono": "+39 0344/84121",
      "fax": "+39 0344/84831",
      "coordinate": {
        "lat": 46.17222213745117,
        "lng": 9.381388664245605
      }
    },
    {
      "codice": "013217",
      "nome": "Sormano",
      "nomeStraniero": null,
      "codiceCatastale": "I860",
      "cap": "22030",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.sormano.co.it",
      "pec": "comune.sormano@pec.regione.lombardia.it",
      "telefono": "+39 031/667069",
      "fax": "+39 031/6730567",
      "coordinate": {
        "lat": 45.87861251831055,
        "lng": 9.246944427490234
      }
    },
    {
      "codice": "013218",
      "nome": "Stazzona",
      "nomeStraniero": null,
      "codiceCatastale": "I943",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.stazzona.co.it",
      "pec": "comune.stazzona@halleypec.it",
      "telefono": "+39 034488305",
      "fax": "+39 0344887728",
      "coordinate": {
        "lat": 46.138790130615234,
        "lng": 9.275239944458008
      }
    },
    {
      "codice": "013222",
      "nome": "Tavernerio",
      "nomeStraniero": null,
      "codiceCatastale": "L071",
      "cap": "22038",
      "prefisso": "031",
      "provincia": "Como",
      "email": "affarigenerali@comune.tavernerio.co.it",
      "pec": "comune.tavernerio@halleycert.it",
      "telefono": "+39 031/421223",
      "fax": "+39 031/427882",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 9.149999618530273
      }
    },
    {
      "codice": "013223",
      "nome": "Torno",
      "nomeStraniero": null,
      "codiceCatastale": "L228",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.torno.co.it",
      "pec": "comune.torno@halleypec.it",
      "telefono": "+39 031/419555",
      "fax": "+39 031/418396",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013252",
      "nome": "Tremezzina",
      "nomeStraniero": null,
      "codiceCatastale": "M341",
      "cap": "22016",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.tremezzina.co.it",
      "pec": "protocollo@pec.comune.tremezzina.co.it",
      "telefono": "+39 0344 5583900",
      "fax": "+39 0344 41066",
      "coordinate": {
        "lat": 45.980960845947266,
        "lng": 9.202839851379395
      }
    },
    {
      "codice": "013226",
      "nome": "Trezzone",
      "nomeStraniero": null,
      "codiceCatastale": "L413",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "comunetrezzone@libero.it",
      "pec": "comune.trezzone@pec.provincia.como.it",
      "telefono": "+39 0344/84478",
      "fax": "+39 0344910806",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.350000381469727
      }
    },
    {
      "codice": "013227",
      "nome": "Turate",
      "nomeStraniero": null,
      "codiceCatastale": "L470",
      "cap": "22078",
      "prefisso": "02",
      "provincia": "Como",
      "email": "urpturate@comune.turate.co.it",
      "pec": "comune.turate@pec.provincia.como.it",
      "telefono": "+39 02/964251",
      "fax": "+39 02/96480491",
      "coordinate": {
        "lat": 45.65833282470703,
        "lng": 9
      }
    },
    {
      "codice": "013228",
      "nome": "Uggiate-Trevano",
      "nomeStraniero": null,
      "codiceCatastale": "L487",
      "cap": "22029",
      "prefisso": "031",
      "provincia": "Como",
      "email": "ced@terredifrontiera.co.it",
      "pec": "comune.uggiatetrevano@pec.provincia.como.it",
      "telefono": "+39 031949566",
      "fax": "+39 031/948142",
      "coordinate": {
        "lat": 45.82337951660156,
        "lng": 8.959639549255371
      }
    },
    {
      "codice": "013233",
      "nome": "Val Rezzo",
      "nomeStraniero": null,
      "codiceCatastale": "H259",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "comvalre@yacc.it",
      "pec": "comune.valrezzo@pec.regione.lombardia.it",
      "telefono": "+39 0344/63151",
      "fax": "+39 0344/73968",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "013229",
      "nome": "Valbrona",
      "nomeStraniero": null,
      "codiceCatastale": "L547",
      "cap": "22039",
      "prefisso": "031",
      "provincia": "Como",
      "email": "anagrafe@comune.valbrona.co.it",
      "pec": "comunevalbrona@halleycert.it",
      "telefono": "+39 031/661176",
      "fax": "+39 031/661344",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 9.300000190734863
      }
    },
    {
      "codice": "013232",
      "nome": "Valmorea",
      "nomeStraniero": null,
      "codiceCatastale": "L640",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.valmorea.co.it",
      "pec": "comune.valmorea@pec.regione.lombardia.it",
      "telefono": "+39 031/806155",
      "fax": "+39 031/806324",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 8.933333396911621
      }
    },
    {
      "codice": "013234",
      "nome": "Valsolda",
      "nomeStraniero": null,
      "codiceCatastale": "C936",
      "cap": "22010",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.valsolda.co.it",
      "pec": "comune.valsolda@halleycert.it",
      "telefono": "+39 0344/68121",
      "fax": "+39 0344/68834",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 9.050000190734863
      }
    },
    {
      "codice": "013236",
      "nome": "Veleso",
      "nomeStraniero": null,
      "codiceCatastale": "L715",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.veleso.co.it",
      "pec": "comune.veleso@pec.provincia.como.it",
      "telefono": "+39 031/917945",
      "fax": "+39 031/917177",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.183333396911621
      }
    },
    {
      "codice": "013238",
      "nome": "Veniano",
      "nomeStraniero": null,
      "codiceCatastale": "L737",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comunediveniano.it",
      "pec": "comune.veniano@pec.provincia.como.it",
      "telefono": "+39 031/890841",
      "fax": "+39 031/890207",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 8.983333587646484
      }
    },
    {
      "codice": "013239",
      "nome": "Vercana",
      "nomeStraniero": null,
      "codiceCatastale": "L748",
      "cap": "22013",
      "prefisso": "0344",
      "provincia": "Como",
      "email": "info@comune.vercana.co.it",
      "pec": "protocollo.comune.vercana@pec.regione.lombardia.it",
      "telefono": "+39 0344/96296",
      "fax": "+39 0344/97736",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 9.333333015441895
      }
    },
    {
      "codice": "013242",
      "nome": "Vertemate con Minoprio",
      "nomeStraniero": null,
      "codiceCatastale": "L792",
      "cap": "22070",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.vertemeteconminoprio.co.it",
      "pec": "vertemateconminoprio@pec.provincia.como.it",
      "telefono": "+39 031/888811",
      "fax": "+39 031/900603",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 9.066666603088379
      }
    },
    {
      "codice": "013245",
      "nome": "Villa Guardia",
      "nomeStraniero": null,
      "codiceCatastale": "L956",
      "cap": "22079",
      "prefisso": "031",
      "provincia": "Como",
      "email": "protocollo@comune.villaguardia.co.it",
      "pec": "comune.villaguardia@pec.provincia.como.it",
      "telefono": "+39 031/485211",
      "fax": "+39 031/563184",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 9.016666412353516
      }
    },
    {
      "codice": "013246",
      "nome": "Zelbio",
      "nomeStraniero": null,
      "codiceCatastale": "M156",
      "cap": "22020",
      "prefisso": "031",
      "provincia": "Como",
      "email": "info@comune.zelbio.co.it",
      "pec": "comune.zelbio.co@halleycert.it",
      "telefono": "+39 031/917932",
      "fax": "+39 031/917100",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 9.183333396911621
      }
    }
  ],
  "Catanzaro": [
    {
      "codice": "079002",
      "nome": "Albi",
      "nomeStraniero": null,
      "codiceCatastale": "A155",
      "cap": "88055",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "comune_di_albi@hotmail.com",
      "pec": "ufficioamministrativo.albi@asmepec.it",
      "telefono": "+39 0961923303",
      "fax": "+39 0961921109",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.600000381469727
      }
    },
    {
      "codice": "079003",
      "nome": "Amaroni",
      "nomeStraniero": null,
      "codiceCatastale": "A255",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "anagrafe@amaroni.it",
      "pec": "ragioneria@pec.comunediamaroni.it",
      "telefono": "+39 0961913030",
      "fax": "+39 0961913535",
      "coordinate": {
        "lat": 38.79999923706055,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "079004",
      "nome": "Amato",
      "nomeStraniero": null,
      "codiceCatastale": "A257",
      "cap": "88040",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "anag_comune_amato@libero.it",
      "pec": "protocollo.amato@asmepec.it",
      "telefono": "+39 0961993045",
      "fax": "+39 0961993038",
      "coordinate": {
        "lat": 38.93333435058594,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079005",
      "nome": "Andali",
      "nomeStraniero": null,
      "codiceCatastale": "A272",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "protocollo@comunediandali.it",
      "pec": "protocollo@pec.comunediandali.it",
      "telefono": "+39 0961935095",
      "fax": "+39 0961935322",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.766666412353516
      }
    },
    {
      "codice": "079007",
      "nome": "Argusto",
      "nomeStraniero": null,
      "codiceCatastale": "A397",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comuneargusto@tiscali.it",
      "pec": "servizi.demografici@pec.comuneargusto.it",
      "telefono": "+39 096791081",
      "fax": "+39 096791193",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 16.433332443237305
      }
    },
    {
      "codice": "079008",
      "nome": "Badolato",
      "nomeStraniero": null,
      "codiceCatastale": "A542",
      "cap": "88061",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comunebadolato@alice.it",
      "pec": "amministrativo.comunebadolato@asmepec.it",
      "telefono": "+39 096785000",
      "fax": "+39 0967814007",
      "coordinate": {
        "lat": 38.56666564941406,
        "lng": 16.53333282470703
      }
    },
    {
      "codice": "079009",
      "nome": "Belcastro",
      "nomeStraniero": null,
      "codiceCatastale": "A736",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "comunebelcastro@virgilio.it",
      "pec": "protocollo.belcastro@asmepec.it",
      "telefono": "+39 0961932090",
      "fax": "+39 0961932189",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "079011",
      "nome": "Borgia",
      "nomeStraniero": null,
      "codiceCatastale": "B002",
      "cap": "88021",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "demografici@comune.borgia.cz.it",
      "pec": "protocollo.borgia@asmepec.it",
      "telefono": "+39 0961951357",
      "fax": "+39 0961956209",
      "coordinate": {
        "lat": 38.83333206176758,
        "lng": 16.5
      }
    },
    {
      "codice": "079012",
      "nome": "Botricello",
      "nomeStraniero": null,
      "codiceCatastale": "B085",
      "cap": "88070",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "demografica@libero.it",
      "pec": "elettorale.botricello@asmepec.it",
      "telefono": "+39 0961966835",
      "fax": "+39 0961966840",
      "coordinate": {
        "lat": 38.93333435058594,
        "lng": 16.850000381469727
      }
    },
    {
      "codice": "079017",
      "nome": "Caraffa di Catanzaro",
      "nomeStraniero": null,
      "codiceCatastale": "B717",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "ufficioelettorale@g.mail.com",
      "pec": "elettorale.caraffacz@asmepec.it",
      "telefono": "+39 0961957805",
      "fax": "+39 0961953703",
      "coordinate": {
        "lat": 38.88333511352539,
        "lng": 16.483333587646484
      }
    },
    {
      "codice": "079018",
      "nome": "Cardinale",
      "nomeStraniero": null,
      "codiceCatastale": "B758",
      "cap": "88062",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comune.cardinale@libero.it",
      "pec": "protocollo.cardinale@asmepec.it",
      "telefono": "+39 096793051",
      "fax": "+39 0967939928",
      "coordinate": {
        "lat": 38.650001525878906,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "079020",
      "nome": "Carlopoli",
      "nomeStraniero": null,
      "codiceCatastale": "B790",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "info@comune.carlopoli.cz.it",
      "pec": "segreteria@pec.comune.carlopoli.cz.it",
      "telefono": "+39 096882025",
      "fax": "+39 0968829052",
      "coordinate": {
        "lat": 39.04999923706055,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "079023",
      "nome": "Catanzaro",
      "nomeStraniero": null,
      "codiceCatastale": "C352",
      "cap": "88100",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "franco.catanzaro@comunecz.it",
      "pec": "ufficio.protocollo@certificata.comune.catanzaro.it",
      "telefono": "+39 0961881274",
      "fax": "+39 0961881274",
      "coordinate": {
        "lat": 38.90999984741211,
        "lng": 16.587499618530273
      }
    },
    {
      "codice": "079024",
      "nome": "Cenadi",
      "nomeStraniero": null,
      "codiceCatastale": "C453",
      "cap": "88067",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comunedicenadi@libero.it",
      "pec": "comune.cenadi@asmepec.it",
      "telefono": "+39 0967955105",
      "fax": "+39 0967955241",
      "coordinate": {
        "lat": 38.71666717529297,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "079025",
      "nome": "Centrache",
      "nomeStraniero": null,
      "codiceCatastale": "C472",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "cpmunecentrache@tiscali.it",
      "pec": "demografici.centrache@asmepec.it",
      "telefono": "+39 0967955126",
      "fax": "+39 0967955771",
      "coordinate": {
        "lat": 38.733333587646484,
        "lng": 16.433332443237305
      }
    },
    {
      "codice": "079027",
      "nome": "Cerva",
      "nomeStraniero": null,
      "codiceCatastale": "C542",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "com.cerva.anagrafe@abramo.it",
      "pec": "sindaco.cerva@asmepec.it",
      "telefono": "+39 0961939201",
      "fax": "+39 0961939111",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.75
      }
    },
    {
      "codice": "079029",
      "nome": "Chiaravalle Centrale",
      "nomeStraniero": null,
      "codiceCatastale": "C616",
      "cap": "88064",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "demog.chiaravallec@libero.it",
      "pec": "comunechiaravallecentrale@asmepec.it",
      "telefono": "+39 096791031",
      "fax": "+39 096792211",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 16.399999618530273
      }
    },
    {
      "codice": "079030",
      "nome": "Cicala",
      "nomeStraniero": null,
      "codiceCatastale": "C674",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "demo.cicala@tiscali.it",
      "pec": "staff.cicala@asmepec.it",
      "telefono": "+39 096885294",
      "fax": "+39 096885282",
      "coordinate": {
        "lat": 39.02219009399414,
        "lng": 16.486099243164062
      }
    },
    {
      "codice": "079033",
      "nome": "Conflenti",
      "nomeStraniero": null,
      "codiceCatastale": "C960",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "comuneconfelnti@beactiv.it",
      "pec": "comuneconflenti@asmepec.it",
      "telefono": "+39 096864054",
      "fax": "+39 096864487",
      "coordinate": {
        "lat": 39.06666564941406,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "079034",
      "nome": "Cortale",
      "nomeStraniero": null,
      "codiceCatastale": "D049",
      "cap": "88020",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "demo@comune.cortale.it",
      "pec": "segreteria.cortale@asmepec.it",
      "telefono": "+39 096876018",
      "fax": "+39 0968755805",
      "coordinate": {
        "lat": 38.83333206176758,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "079036",
      "nome": "Cropani",
      "nomeStraniero": null,
      "codiceCatastale": "D181",
      "cap": "88051",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "ufficioanagrafe@comunecropani.it",
      "pec": "demografici.cropani@asmepec.it",
      "telefono": "+39 0961965714",
      "fax": "+39 0961965742",
      "coordinate": {
        "lat": 38.96666717529297,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "079039",
      "nome": "Curinga",
      "nomeStraniero": null,
      "codiceCatastale": "D218",
      "cap": "88022",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "elettorale.curinga@libero.it",
      "pec": "protocollo.curinga@asmepec.it",
      "telefono": "+39 0968739324",
      "fax": "+39 0968739156",
      "coordinate": {
        "lat": 38.82666778564453,
        "lng": 16.313888549804688
      }
    },
    {
      "codice": "079042",
      "nome": "Davoli",
      "nomeStraniero": null,
      "codiceCatastale": "D257",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "segretario@comune.davoli.cz.it",
      "pec": "comune.davoli@pcert.postecert.it",
      "telefono": "+39 0967535674",
      "fax": "+39 0967535584",
      "coordinate": {
        "lat": 38.650001525878906,
        "lng": 16.483333587646484
      }
    },
    {
      "codice": "079043",
      "nome": "Decollatura",
      "nomeStraniero": null,
      "codiceCatastale": "D261",
      "cap": "88041",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "anagrafedecollatura@libero.it",
      "pec": "segretario.decollatura@asmepec.it",
      "telefono": "+39 096861169",
      "fax": "+39 096861247",
      "coordinate": {
        "lat": 39.04999923706055,
        "lng": 16.350000381469727
      }
    },
    {
      "codice": "079047",
      "nome": "Falerna",
      "nomeStraniero": null,
      "codiceCatastale": "D476",
      "cap": "88042",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "info@comune.falerna.ca.it",
      "pec": "comune.falerna@asmepec.it",
      "telefono": "+39 096895002",
      "fax": "+39 096895043",
      "coordinate": {
        "lat": 39,
        "lng": 16.16666603088379
      }
    },
    {
      "codice": "079048",
      "nome": "Feroleto Antico",
      "nomeStraniero": null,
      "codiceCatastale": "D544",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "demografici@comune.feroletoantico.ca.it",
      "pec": "demografici.feroletoantico@asmepec.it",
      "telefono": "+39 0968425834",
      "fax": "+39 096832002",
      "coordinate": {
        "lat": 38.96111297607422,
        "lng": 16.388334274291992
      }
    },
    {
      "codice": "079052",
      "nome": "Fossato Serralta",
      "nomeStraniero": null,
      "codiceCatastale": "D744",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "pcfossato@tiscali.it",
      "pec": "segreteria.fossato@asmepec.it",
      "telefono": "+39 0961925393",
      "fax": "+39 0961825610",
      "coordinate": {
        "lat": 39,
        "lng": 16.58333396911621
      }
    },
    {
      "codice": "079055",
      "nome": "Gagliato",
      "nomeStraniero": null,
      "codiceCatastale": "D852",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "amministrativo@comune.gagliato.cz.it",
      "pec": "comune.gagliato@asmepec.it",
      "telefono": "+39 096797049",
      "fax": "+39 096797291",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079056",
      "nome": "Gasperina",
      "nomeStraniero": null,
      "codiceCatastale": "D932",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "elettorale.gasperina@libero.it",
      "pec": "demografici.gasperina@asmepec.it",
      "telefono": "+39 0967630513",
      "fax": "+39 0967486439",
      "coordinate": {
        "lat": 38.733333587646484,
        "lng": 16.5
      }
    },
    {
      "codice": "079058",
      "nome": "Gimigliano",
      "nomeStraniero": null,
      "codiceCatastale": "E031",
      "cap": "88045",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "comgimiglianoelett@libero.it",
      "pec": "segreteria.gimigliano@asmepec.it",
      "telefono": "+39 0961995014",
      "fax": "+39 0961995120",
      "coordinate": {
        "lat": 38.96666717529297,
        "lng": 16.53333282470703
      }
    },
    {
      "codice": "079059",
      "nome": "Girifalco",
      "nomeStraniero": null,
      "codiceCatastale": "E050",
      "cap": "88024",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "elettorale@comune.girifalco.cz.it",
      "pec": "amministrativo.girifalco@asmepec.it",
      "telefono": "+39 0968749017",
      "fax": "+39 0968749037",
      "coordinate": {
        "lat": 38.81666564941406,
        "lng": 16.433332443237305
      }
    },
    {
      "codice": "079060",
      "nome": "Gizzeria",
      "nomeStraniero": null,
      "codiceCatastale": "E068",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "comune.gizzeria@libero.it",
      "pec": "anagrafe.gizzeria@asmepec.it",
      "telefono": "+39 0968403704",
      "fax": "+39 0968403749",
      "coordinate": {
        "lat": 38.983333587646484,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "079061",
      "nome": "Guardavalle",
      "nomeStraniero": null,
      "codiceCatastale": "E239",
      "cap": "88065",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comunediguardavalle@libero.it",
      "pec": "demografici.guardavalle@asmepec.it",
      "telefono": "+39 096782067",
      "fax": "+39 096782291",
      "coordinate": {
        "lat": 38.5,
        "lng": 16.5
      }
    },
    {
      "codice": "079063",
      "nome": "Isca sullo Ionio",
      "nomeStraniero": null,
      "codiceCatastale": "E328",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "elettorale.isca@gmail.com",
      "pec": "demografici.iscasulloionio@asmepec.it",
      "telefono": "+39 0967521987",
      "fax": "+39 0967546970",
      "coordinate": {
        "lat": 38.599998474121094,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "079065",
      "nome": "Jacurso",
      "nomeStraniero": null,
      "codiceCatastale": "E274",
      "cap": "88020",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "areademo@libero.it",
      "pec": "segretario.jacurso@asmepec.it",
      "telefono": "+39 096870000",
      "fax": "+39 096870161",
      "coordinate": {
        "lat": 38.849998474121094,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "079160",
      "nome": "Lamezia Terme",
      "nomeStraniero": null,
      "codiceCatastale": "M208",
      "cap": "88046",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "n.aiello@comune.lamezia-terme.it",
      "pec": "protocollo@pec.comunelameziaterme.it",
      "telefono": "+39 0968207409",
      "fax": "+39 096824347",
      "coordinate": {
        "lat": 38.96666717529297,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "079068",
      "nome": "Magisano",
      "nomeStraniero": null,
      "codiceCatastale": "E806",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "comune.magi@tiscali.it",
      "pec": "amministrazione.magisano@asmepec.it",
      "telefono": "+39 0961926015",
      "fax": "+39 0961926473",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.633333206176758
      }
    },
    {
      "codice": "079069",
      "nome": "Maida",
      "nomeStraniero": null,
      "codiceCatastale": "E834",
      "cap": "88025",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "demogmaida@tiscalinet.it",
      "pec": "areamministrativa.maida@asmepec.it",
      "telefono": "+39 0968754044",
      "fax": "+39 0968754044",
      "coordinate": {
        "lat": 38.849998474121094,
        "lng": 16.366666793823242
      }
    },
    {
      "codice": "079071",
      "nome": "Marcedusa",
      "nomeStraniero": null,
      "codiceCatastale": "E923",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "servizioelettorale@comune.marcedusa.cz.it",
      "pec": "elettorale.marcedusa@asmepec.it",
      "telefono": "+39 0961932010",
      "fax": "+39 0961932645",
      "coordinate": {
        "lat": 39.03333282470703,
        "lng": 16.83333396911621
      }
    },
    {
      "codice": "079072",
      "nome": "Marcellinara",
      "nomeStraniero": null,
      "codiceCatastale": "E925",
      "cap": "88044",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "ufficioanagrafe@comunemarcellinara.it",
      "pec": "anagrafe-elettorale@pec.comunemarcellinara.it",
      "telefono": "+39 0961996133",
      "fax": "+39 0961996209",
      "coordinate": {
        "lat": 38.93333435058594,
        "lng": 16.5
      }
    },
    {
      "codice": "079073",
      "nome": "Martirano",
      "nomeStraniero": null,
      "codiceCatastale": "E990",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "comunedimartirano@libero.it",
      "pec": "segretario.martirano@asmepec.it",
      "telefono": "+39 096899040",
      "fax": "+39 096899791",
      "coordinate": {
        "lat": 39.08333206176758,
        "lng": 16.25
      }
    },
    {
      "codice": "079074",
      "nome": "Martirano Lombardo",
      "nomeStraniero": null,
      "codiceCatastale": "E991",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "demografici@comune.martiranolombardo.cz.it",
      "pec": "protocollo.comunemartiranolombardo@asmepec.it",
      "telefono": "+39 096899034",
      "fax": "+39 096899261",
      "coordinate": {
        "lat": 39.07555389404297,
        "lng": 16.231111526489258
      }
    },
    {
      "codice": "079077",
      "nome": "Miglierina",
      "nomeStraniero": null,
      "codiceCatastale": "F200",
      "cap": "88040",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "anagrafe@comunemiglierina.it",
      "pec": "sindaco.miglierina@asmepec.it",
      "telefono": "+39 0961993041",
      "fax": "+39 0961993228",
      "coordinate": {
        "lat": 38.95000076293945,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079080",
      "nome": "Montauro",
      "nomeStraniero": null,
      "codiceCatastale": "F432",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "elettoralemontauro@tiscali.it",
      "pec": "ufficioamministrativomontauro@asmepec.it",
      "telefono": "+39 0967548123",
      "fax": "+39 0967548131",
      "coordinate": {
        "lat": 38.75,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "079081",
      "nome": "Montepaone",
      "nomeStraniero": null,
      "codiceCatastale": "F586",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "elettoralemontepaone@libero.it",
      "pec": "demografico@pec.comune.montepaone.cz.it",
      "telefono": "+39 096749294",
      "fax": "+39 0967634707",
      "coordinate": {
        "lat": 38.71666717529297,
        "lng": 16.5
      }
    },
    {
      "codice": "079083",
      "nome": "Motta Santa Lucia",
      "nomeStraniero": null,
      "codiceCatastale": "F780",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "comunemottaslucia@tiscali.it",
      "pec": "amministrativo.mottasantalucia@asmepec.it",
      "telefono": "+39 096865032",
      "fax": "+39 096865006",
      "coordinate": {
        "lat": 39.099998474121094,
        "lng": 16.299999237060547
      }
    },
    {
      "codice": "079087",
      "nome": "Nocera Terinese",
      "nomeStraniero": null,
      "codiceCatastale": "F910",
      "cap": "88047",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "elettoralenocerat@libero.it",
      "pec": "statocivile.noceraterinese@asmepec.it",
      "telefono": "+39 096891300",
      "fax": "+39 096891564",
      "coordinate": {
        "lat": 39.03333282470703,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "079088",
      "nome": "Olivadi",
      "nomeStraniero": null,
      "codiceCatastale": "G034",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "anagrafe@comune.olivadi.cz.it",
      "pec": "comune.olivadi@asmepec.it",
      "telefono": "+39 0967955874",
      "fax": "+39 0967955942",
      "coordinate": {
        "lat": 38.733333587646484,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "079089",
      "nome": "Palermiti",
      "nomeStraniero": null,
      "codiceCatastale": "G272",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "anagrpalermiti@virgilio.it",
      "pec": "protocollo.palermiti@asmepec.it",
      "telefono": "+39 0961917167",
      "fax": "+39 0961917900",
      "coordinate": {
        "lat": 38.75,
        "lng": 16.450000762939453
      }
    },
    {
      "codice": "079092",
      "nome": "Pentone",
      "nomeStraniero": null,
      "codiceCatastale": "G439",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "protocollo@comunedipentone.it",
      "pec": "protocollo.comunedipentone@pec.it",
      "telefono": "+39 0961925076",
      "fax": "+39 0961925120",
      "coordinate": {
        "lat": 38.983333587646484,
        "lng": 16.58333396911621
      }
    },
    {
      "codice": "079094",
      "nome": "Petrizzi",
      "nomeStraniero": null,
      "codiceCatastale": "G517",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comune.petrizzi@libero.it",
      "pec": "protocollo.petrizzi@asmepec.it",
      "telefono": "+39 096794004",
      "fax": "+39 096794360",
      "coordinate": {
        "lat": 38.70000076293945,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079095",
      "nome": "Petronà",
      "nomeStraniero": null,
      "codiceCatastale": "G518",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "demografici.petrona@asmepec.it",
      "pec": "segreteria.petrona@asmepec.it",
      "telefono": "+39 0961938809",
      "fax": "+39 0961938811",
      "coordinate": {
        "lat": 39.03333282470703,
        "lng": 16.75
      }
    },
    {
      "codice": "079096",
      "nome": "Pianopoli",
      "nomeStraniero": null,
      "codiceCatastale": "D546",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "servdemografico@comunepianopoli.it",
      "pec": "segretario.pianopoli@asmepec.it",
      "telefono": "+39 0968425695",
      "fax": "+39 0968425692",
      "coordinate": {
        "lat": 38.95000076293945,
        "lng": 16.383333206176758
      }
    },
    {
      "codice": "079099",
      "nome": "Platania",
      "nomeStraniero": null,
      "codiceCatastale": "G734",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "servizio.amministrativo@comuneplatania.it",
      "pec": "amministrativo.platania@asmepec.it",
      "telefono": "+39 0968205010",
      "fax": "+39 0968205910",
      "coordinate": {
        "lat": 39,
        "lng": 16.316667556762695
      }
    },
    {
      "codice": "079108",
      "nome": "San Floro",
      "nomeStraniero": null,
      "codiceCatastale": "H846",
      "cap": "88021",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "ufficioanagrafe@sanfloro.com",
      "pec": "protocollo.sanfloro@asmepec.it",
      "telefono": "+39 0961951127",
      "fax": "+39 0961956279",
      "coordinate": {
        "lat": 38.83333206176758,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "079110",
      "nome": "San Mango d'Aquino",
      "nomeStraniero": null,
      "codiceCatastale": "H976",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "protocollo@comunedisanmangodaquino.it",
      "pec": "ced@pec.comunedisanmangodaquino.it",
      "telefono": "+39 096896003",
      "fax": "+39 096896440",
      "coordinate": {
        "lat": 39.06666564941406,
        "lng": 16.200000762939453
      }
    },
    {
      "codice": "079114",
      "nome": "San Pietro a Maida",
      "nomeStraniero": null,
      "codiceCatastale": "I093",
      "cap": "88025",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": null,
      "pec": "protocollo.sanpietroamaida@asmepec.it",
      "telefono": "+39 096879111",
      "fax": null,
      "coordinate": {
        "lat": 38.849998474121094,
        "lng": 16.350000381469727
      }
    },
    {
      "codice": "079115",
      "nome": "San Pietro Apostolo",
      "nomeStraniero": null,
      "codiceCatastale": "I095",
      "cap": "88040",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "comunesanpietro@tiscali.it",
      "pec": "ufficioanagrafe@pec.comunesanpietroapostolo.org",
      "telefono": "+39 0961994035",
      "fax": "+39 0961994634",
      "coordinate": {
        "lat": 39,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079116",
      "nome": "San Sostene",
      "nomeStraniero": null,
      "codiceCatastale": "I164",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "protocollo@comune.sansostene.cz.it",
      "pec": "prot.sansostene@asmepec.it",
      "telefono": "+39 0967533254",
      "fax": "+39 0967533254",
      "coordinate": {
        "lat": 38.63333511352539,
        "lng": 16.483333587646484
      }
    },
    {
      "codice": "079122",
      "nome": "San Vito sullo Ionio",
      "nomeStraniero": null,
      "codiceCatastale": "I393",
      "cap": "88067",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comunesanvitoionio@virgilio.it",
      "pec": "elettorale.sanvitosulloionio@asmepec.it",
      "telefono": "+39 096796087",
      "fax": "+39 096796088",
      "coordinate": {
        "lat": 38.71666717529297,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "079118",
      "nome": "Sant'Andrea Apostolo dello Ionio",
      "nomeStraniero": null,
      "codiceCatastale": "I266",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "camsandrea@infinito.it",
      "pec": "amministrativo.santandreaapostolodelloionio@asmetec.it",
      "telefono": "+39 096744102",
      "fax": "+39 096747158",
      "coordinate": {
        "lat": 38.62361145019531,
        "lng": 16.52916717529297
      }
    },
    {
      "codice": "079117",
      "nome": "Santa Caterina dello Ionio",
      "nomeStraniero": null,
      "codiceCatastale": "I170",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "scaterinastatocivile@libero.it",
      "pec": "demografaire@pec.it",
      "telefono": "+39 096784306",
      "fax": "+39 096784215",
      "coordinate": {
        "lat": 38.53333282470703,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "079123",
      "nome": "Satriano",
      "nomeStraniero": null,
      "codiceCatastale": "I463",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comsatriano@libero.it",
      "pec": "comunesatriano.amministrativo@certificata.com",
      "telefono": "+39 0967543810",
      "fax": "+39 09867543300",
      "coordinate": {
        "lat": 38.66666793823242,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079126",
      "nome": "Sellia",
      "nomeStraniero": null,
      "codiceCatastale": "I589",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "elettorale.sellia@alice.it",
      "pec": "protocollosellia@asmepec.it",
      "telefono": "+39 0961483049",
      "fax": "+39 0961483900",
      "coordinate": {
        "lat": 38.983333587646484,
        "lng": 16.633333206176758
      }
    },
    {
      "codice": "079127",
      "nome": "Sellia Marina",
      "nomeStraniero": null,
      "codiceCatastale": "I590",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "elettorale.selliamarina@gmail.com",
      "pec": "elettorale.selliamarina@asmepec.it",
      "telefono": "+39 0961964125",
      "fax": "+39 0961969745",
      "coordinate": {
        "lat": 38.91666793823242,
        "lng": 16.75
      }
    },
    {
      "codice": "079129",
      "nome": "Serrastretta",
      "nomeStraniero": null,
      "codiceCatastale": "I655",
      "cap": "88040",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "protocollo@comune.serrastretta.cz.it",
      "pec": "segretario.serrastretta@asmepec.it",
      "telefono": "3476933221",
      "fax": "+39 096881143",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.399999618530273
      }
    },
    {
      "codice": "079130",
      "nome": "Sersale",
      "nomeStraniero": null,
      "codiceCatastale": "I671",
      "cap": "88054",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "sersale.demografico@tiscalinet.it",
      "pec": "serviziofinanziario.sersale@asmepec.it",
      "telefono": "+39 0961930929",
      "fax": "+39 0961936395",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.733333587646484
      }
    },
    {
      "codice": "079131",
      "nome": "Settingiano",
      "nomeStraniero": null,
      "codiceCatastale": "I704",
      "cap": "88040",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "comune.settingiano@comune.settigniano.cz.it",
      "pec": "protocollo.settingiano@asmepec.it",
      "telefono": "+39 0961997031",
      "fax": "+39 0961997422",
      "coordinate": {
        "lat": 38.91666793823242,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "079133",
      "nome": "Simeri Crichi",
      "nomeStraniero": null,
      "codiceCatastale": "I745",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "demograficisimericrichi@asmepec.it",
      "pec": "segreteriasimericrichi@asmepec.it",
      "telefono": "+39 0961481003",
      "fax": "+39 0961481244",
      "coordinate": {
        "lat": 38.95000076293945,
        "lng": 16.649999618530273
      }
    },
    {
      "codice": "079134",
      "nome": "Sorbo San Basile",
      "nomeStraniero": null,
      "codiceCatastale": "I844",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "com.sorbo@abramo.it",
      "pec": "tecnico.sorbosanbasile@asmepec.it",
      "telefono": "+39 0961921108",
      "fax": "+39 0961923098",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.566667556762695
      }
    },
    {
      "codice": "079137",
      "nome": "Soverato",
      "nomeStraniero": null,
      "codiceCatastale": "I872",
      "cap": "88068",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "elettorale@comunesoverato.it",
      "pec": "comunesoverato@asmepec.it",
      "telefono": "+39 0967538219",
      "fax": "+39 0967538242",
      "coordinate": {
        "lat": 38.68333435058594,
        "lng": 16.549999237060547
      }
    },
    {
      "codice": "079138",
      "nome": "Soveria Mannelli",
      "nomeStraniero": null,
      "codiceCatastale": "I874",
      "cap": "88049",
      "prefisso": "0968",
      "provincia": "Catanzaro",
      "email": "elettorale@soveria.it",
      "pec": "protocollo.soveria@asmepec.it",
      "telefono": "+39 0968662006",
      "fax": "+39 0968662004",
      "coordinate": {
        "lat": 39.08333206176758,
        "lng": 16.366666793823242
      }
    },
    {
      "codice": "079139",
      "nome": "Soveria Simeri",
      "nomeStraniero": null,
      "codiceCatastale": "I875",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "soveriasimeri.elettorale@virgilio.it",
      "pec": "comune.soveriasimeri@anutel.it",
      "telefono": "+39 0961798057",
      "fax": "+39 0961798572",
      "coordinate": {
        "lat": 38.95000076293945,
        "lng": 16.683332443237305
      }
    },
    {
      "codice": "079142",
      "nome": "Squillace",
      "nomeStraniero": null,
      "codiceCatastale": "I929",
      "cap": "88069",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "servanagrafe@libero.it",
      "pec": "affarigenerali@comune.squillace.cz.it",
      "telefono": "+39 0961912040",
      "fax": "+39 0961914019",
      "coordinate": {
        "lat": 38.78333282470703,
        "lng": 16.516666412353516
      }
    },
    {
      "codice": "079143",
      "nome": "Stalettì",
      "nomeStraniero": null,
      "codiceCatastale": "I937",
      "cap": "88069",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "protocollo@comune.staletti.cz.it",
      "pec": "amministrativa.staletti@asmepec.it",
      "telefono": "+39 0961 918802",
      "fax": "+39 0961 918100",
      "coordinate": {
        "lat": 38.764278411865234,
        "lng": 16.53931999206543
      }
    },
    {
      "codice": "079146",
      "nome": "Taverna",
      "nomeStraniero": null,
      "codiceCatastale": "L070",
      "cap": "88055",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "uectaverna.cz@tiscali.it",
      "pec": "protocollo.taverna@asmepec.it",
      "telefono": "+39 0961921058",
      "fax": "+39 0961923671",
      "coordinate": {
        "lat": 39.016666412353516,
        "lng": 16.58333396911621
      }
    },
    {
      "codice": "079147",
      "nome": "Tiriolo",
      "nomeStraniero": null,
      "codiceCatastale": "L177",
      "cap": "88056",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "protocollo@comune.tiriolo.cz.it",
      "pec": "protocollo.tiriolo@asmepec.it",
      "telefono": "+39 0961990836",
      "fax": "+39 0961990828",
      "coordinate": {
        "lat": 38.95000076293945,
        "lng": 16.5
      }
    },
    {
      "codice": "079148",
      "nome": "Torre di Ruggiero",
      "nomeStraniero": null,
      "codiceCatastale": "L240",
      "cap": "88060",
      "prefisso": "0967",
      "provincia": "Catanzaro",
      "email": "comune@comunetorrediruggiero.it",
      "pec": "areademograficatorrediruggiero@asmepec.it",
      "telefono": "+39 096793112",
      "fax": "+39 096793679",
      "coordinate": {
        "lat": 38.63333511352539,
        "lng": 16.366666793823242
      }
    },
    {
      "codice": "079151",
      "nome": "Vallefiorita",
      "nomeStraniero": null,
      "codiceCatastale": "I322",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "vallefiorita.demo@libero.it",
      "pec": "ragioneria.vallefiorita@asmepec.it",
      "telefono": "+39 0961919023",
      "fax": "+39 0961919019",
      "coordinate": {
        "lat": 38.78333282470703,
        "lng": 16.46666717529297
      }
    },
    {
      "codice": "079157",
      "nome": "Zagarise",
      "nomeStraniero": null,
      "codiceCatastale": "M140",
      "cap": "88050",
      "prefisso": "0961",
      "provincia": "Catanzaro",
      "email": "elettorale.zagarise@libero.it",
      "pec": "comune@pec.zagarise.org",
      "telefono": "+39 0961937014",
      "fax": "+39 0961937067",
      "coordinate": {
        "lat": 39,
        "lng": 16.66666603088379
      }
    }
  ],
  "Reggio nell'Emilia": [
    {
      "codice": "035001",
      "nome": "Albinea",
      "nomeStraniero": null,
      "codiceCatastale": "A162",
      "cap": "42020",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.albinea.re.it",
      "pec": "albinea@cert.provincia.re.it",
      "telefono": "+39 0522 590211",
      "fax": "+39 0522 590236",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "035002",
      "nome": "Bagnolo in Piano",
      "nomeStraniero": null,
      "codiceCatastale": "A573",
      "cap": "42011",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segreteria@comune.bagnolo.re.it",
      "pec": "bagnolo@cert.provincia.re.it",
      "telefono": "+39 0522.957411",
      "fax": "+39 0522.951037",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 10.683333396911621
      }
    },
    {
      "codice": "035003",
      "nome": "Baiso",
      "nomeStraniero": null,
      "codiceCatastale": "A586",
      "cap": "42031",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "anagrafe@comune.baiso.re.it",
      "pec": "info@cert.comune.baiso.re.it",
      "telefono": "+39 0522 993511-993502 -",
      "fax": "+39 0522 843980",
      "coordinate": {
        "lat": 44.50194549560547,
        "lng": 10.604722023010254
      }
    },
    {
      "codice": "035004",
      "nome": "Bibbiano",
      "nomeStraniero": null,
      "codiceCatastale": "A850",
      "cap": "42021",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "ced@comune.bibbiano.re.it",
      "pec": "bibbiano@cert.provincia.re.it",
      "telefono": "+39 0522 253211",
      "fax": "+39 0522 882354",
      "coordinate": {
        "lat": 44.66292190551758,
        "lng": 10.473910331726074
      }
    },
    {
      "codice": "035005",
      "nome": "Boretto",
      "nomeStraniero": null,
      "codiceCatastale": "A988",
      "cap": "42022",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.boretto.re.it",
      "pec": "protocolloboretto@legalmail.it",
      "telefono": "+39 0522963700",
      "fax": "+39 0522964693",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 10.550000190734863
      }
    },
    {
      "codice": "035006",
      "nome": "Brescello",
      "nomeStraniero": null,
      "codiceCatastale": "B156",
      "cap": "42041",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "sindaco@comune.brescello.re.it",
      "pec": "comune.brescello@postecert.it",
      "telefono": "+39 0522 482511",
      "fax": "+39 0522 684422",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 10.516666412353516
      }
    },
    {
      "codice": "035008",
      "nome": "Cadelbosco di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "B328",
      "cap": "42023",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "ced@cadelbosco.net",
      "pec": "cadelbosco@legalmail.it",
      "telefono": "+39 0522 918511",
      "fax": "+39 0522 917302",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "035009",
      "nome": "Campagnola Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "B499",
      "cap": "42012",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "gtirelli@comune.campagnola-emilia.re.it",
      "pec": "campagnolaemilia@cert.provincia.re.it",
      "telefono": "+39 0522/750722",
      "fax": "+39 0522/669197",
      "coordinate": {
        "lat": 44.83611297607422,
        "lng": 10.754722595214844
      }
    },
    {
      "codice": "035010",
      "nome": "Campegine",
      "nomeStraniero": null,
      "codiceCatastale": "B502",
      "cap": "42040",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segretario@comune.campegine.re.it",
      "pec": "campegine@cert.provincia.re.it",
      "telefono": "+39 0522 676521",
      "fax": "+39 0522 676314",
      "coordinate": {
        "lat": 44.78444290161133,
        "lng": 10.532777786254883
      }
    },
    {
      "codice": "035018",
      "nome": "Canossa",
      "nomeStraniero": null,
      "codiceCatastale": "C669",
      "cap": "42026",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "demografici@comune.canossa.re.it",
      "pec": "protocollo.comune.canossa@postecert.it",
      "telefono": "+39 0522248411",
      "fax": "+39 0522248450",
      "coordinate": {
        "lat": 44.57355499267578,
        "lng": 10.455307006835938
      }
    },
    {
      "codice": "035011",
      "nome": "Carpineti",
      "nomeStraniero": null,
      "codiceCatastale": "B825",
      "cap": "42033",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "v.cavalletti@comune.carpineti.re.it",
      "pec": "comune.carpineti@legalmail.it",
      "telefono": "+39 0522 618263",
      "fax": "+39 0522 718014",
      "coordinate": {
        "lat": 44.4566650390625,
        "lng": 10.52055549621582
      }
    },
    {
      "codice": "035012",
      "nome": "Casalgrande",
      "nomeStraniero": null,
      "codiceCatastale": "B893",
      "cap": "42013",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.casalgrande.re.it",
      "pec": "casalgrande@cert.provincia.re.it",
      "telefono": "+39 0522-998511",
      "fax": "+39 0522-841039",
      "coordinate": {
        "lat": 44.58974838256836,
        "lng": 10.739419937133789
      }
    },
    {
      "codice": "035013",
      "nome": "Casina",
      "nomeStraniero": null,
      "codiceCatastale": "B967",
      "cap": "42034",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "anagrafe@comune.casina.re.it",
      "pec": "casina@cert.provincia.re.it",
      "telefono": "+39 0522-604711",
      "fax": "+39 0522-609464",
      "coordinate": {
        "lat": 44.511112213134766,
        "lng": 10.5024995803833
      }
    },
    {
      "codice": "035014",
      "nome": "Castellarano",
      "nomeStraniero": null,
      "codiceCatastale": "C141",
      "cap": "42014",
      "prefisso": "0536",
      "provincia": "Reggio nell'Emilia",
      "email": "agostino.toni@comune.castellarano.re.it",
      "pec": "egov.castellarano@cert.poliscomuneamico.net",
      "telefono": "+39 0536 850114",
      "fax": "+39 0536 850629",
      "coordinate": {
        "lat": 44.51388931274414,
        "lng": 10.733888626098633
      }
    },
    {
      "codice": "035015",
      "nome": "Castelnovo di Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "C218",
      "cap": "42024",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.castelnovo-di-sotto.re.it",
      "pec": "info@pec.comune.castelnovo-di-sotto.re.it",
      "telefono": "+39 0522 485711",
      "fax": "+39 0522 683603",
      "coordinate": {
        "lat": 44.81666564941406,
        "lng": 10.566666603088379
      }
    },
    {
      "codice": "035016",
      "nome": "Castelnovo ne' Monti",
      "nomeStraniero": null,
      "codiceCatastale": "C219",
      "cap": "42035",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "municipio@comune.castelnovo-nemonti.re.it",
      "pec": "castelnovonemonti@cert.provincia.re.it",
      "telefono": "+39 0522 610111",
      "fax": "+39 0522 810947",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "035017",
      "nome": "Cavriago",
      "nomeStraniero": null,
      "codiceCatastale": "C405",
      "cap": "42025",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "comuneinforma@comune.cavriago.re.it",
      "pec": "comune.cavriago@legalmail.it",
      "telefono": "+39 0522 373411",
      "fax": "+39 0522 575537",
      "coordinate": {
        "lat": 44.695640563964844,
        "lng": 10.52737045288086
      }
    },
    {
      "codice": "035020",
      "nome": "Correggio",
      "nomeStraniero": null,
      "codiceCatastale": "D037",
      "cap": "42015",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "comune@comune.correggio.re.it",
      "pec": "correggio@cert.provincia.re.it",
      "telefono": "+39 0522.630711",
      "fax": "+39 0522 694772",
      "coordinate": {
        "lat": 44.77166748046875,
        "lng": 10.780555725097656
      }
    },
    {
      "codice": "035021",
      "nome": "Fabbrico",
      "nomeStraniero": null,
      "codiceCatastale": "D450",
      "cap": "42042",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.fabbrico.re.it",
      "pec": "fabbrico@cert.provincia.re.it",
      "telefono": "+39 0522 751911",
      "fax": "+39 0522 665368",
      "coordinate": {
        "lat": 44.86666488647461,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "035022",
      "nome": "Gattatico",
      "nomeStraniero": null,
      "codiceCatastale": "D934",
      "cap": "42043",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "anagrafe@comune.gattatico.re.it",
      "pec": "comune.gattatico@pec.it",
      "telefono": "+39 0522 477912",
      "fax": "+39 0522 678794",
      "coordinate": {
        "lat": 44.79999923706055,
        "lng": 10.466666221618652
      }
    },
    {
      "codice": "035023",
      "nome": "Gualtieri",
      "nomeStraniero": null,
      "codiceCatastale": "E232",
      "cap": "42044",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.gualtieri.re.it",
      "pec": "comune.gualtieri@postecert.it",
      "telefono": "+39 0522 221811",
      "fax": "+39 0522 828444",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "035024",
      "nome": "Guastalla",
      "nomeStraniero": null,
      "codiceCatastale": "E253",
      "cap": "42016",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.guastalla.re.it",
      "pec": "guastalla@cert.provincia.re.it",
      "telefono": "+39 0522839711",
      "fax": "+39 0522824834",
      "coordinate": {
        "lat": 44.921390533447266,
        "lng": 10.654166221618652
      }
    },
    {
      "codice": "035026",
      "nome": "Luzzara",
      "nomeStraniero": null,
      "codiceCatastale": "E772",
      "cap": "42045",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segreteria@comune.luzzara.re",
      "pec": "segreteria@comune.luzzara.re.legalmail.it",
      "telefono": "+39 0522 223811",
      "fax": "+39 0522 223800",
      "coordinate": {
        "lat": 44.96666717529297,
        "lng": 10.683333396911621
      }
    },
    {
      "codice": "035027",
      "nome": "Montecchio Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "F463",
      "cap": "42027",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segretario.comunale@comune.montecchio-emilia.re.it",
      "pec": "montecchio-emilia@cert.provincia.re.it",
      "telefono": "+39 0522861811",
      "fax": "+39 0522861869",
      "coordinate": {
        "lat": 44.698612213134766,
        "lng": 10.44861125946045
      }
    },
    {
      "codice": "035028",
      "nome": "Novellara",
      "nomeStraniero": null,
      "codiceCatastale": "F960",
      "cap": "42017",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "assistenza-it@comune.novellara.re.it",
      "pec": "novellara@cert.provincia.re.it",
      "telefono": "+39 0522655410",
      "fax": "+39 0522652057",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 10.733333587646484
      }
    },
    {
      "codice": "035029",
      "nome": "Poviglio",
      "nomeStraniero": null,
      "codiceCatastale": "G947",
      "cap": "42028",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "i.dallasta@comune.poviglio.re.it",
      "pec": "poviglio@cert.provincia.re.it",
      "telefono": "+39 0522966811",
      "fax": "+39 0522960152",
      "coordinate": {
        "lat": 44.83333206176758,
        "lng": 10.550000190734863
      }
    },
    {
      "codice": "035030",
      "nome": "Quattro Castella",
      "nomeStraniero": null,
      "codiceCatastale": "H122",
      "cap": "42020",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "sistemi.informatici@comune.quattro-castella.re.it",
      "pec": "quattrocastella@cert.provincia.re.it",
      "telefono": "+39 0522 249211",
      "fax": "+39 0522 249298",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 10.466666221618652
      }
    },
    {
      "codice": "035033",
      "nome": "Reggio nell'Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "H223",
      "cap": "42121",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "irene.spaggiari@comune.re.it",
      "pec": "comune.reggioemilia@pec.municipio.re.it",
      "telefono": "+39 0522 585256",
      "fax": "+39 0522 456074",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "035032",
      "nome": "Reggiolo",
      "nomeStraniero": null,
      "codiceCatastale": "H225",
      "cap": "42046",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.reggiolo.re.it",
      "pec": "comunereggiolo@postecert.it",
      "telefono": "+39 0522 213700",
      "fax": null,
      "coordinate": {
        "lat": 44.91666793823242,
        "lng": 10.816666603088379
      }
    },
    {
      "codice": "035034",
      "nome": "Rio Saliceto",
      "nomeStraniero": null,
      "codiceCatastale": "H298",
      "cap": "42010",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segreteria@comune.riosaliceto.re.it",
      "pec": "riosaliceto@cert.provincia.re.it",
      "telefono": "+39 0522 647811",
      "fax": "+39 0522 647822",
      "coordinate": {
        "lat": 44.81666564941406,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "035035",
      "nome": "Rolo",
      "nomeStraniero": null,
      "codiceCatastale": "H500",
      "cap": "42047",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.rolo.re.it",
      "pec": "rolo@cert.provincia.re.it",
      "telefono": "+39 0522 658011",
      "fax": "+39 0522 666953",
      "coordinate": {
        "lat": 44.88333511352539,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "035036",
      "nome": "Rubiera",
      "nomeStraniero": null,
      "codiceCatastale": "H628",
      "cap": "42048",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segreteria@comune.rubiera.re.it",
      "pec": "comune.rubiera@postecert.it",
      "telefono": "+39 0522.622211",
      "fax": "+39 0522.628978",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 10.783333778381348
      }
    },
    {
      "codice": "035037",
      "nome": "San Martino in Rio",
      "nomeStraniero": null,
      "codiceCatastale": "I011",
      "cap": "42018",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "sindaco@comune.sanmartinoinrio.re.it",
      "pec": "sanmartinoinrio@cert.provincia.re.it",
      "telefono": "+39 0522 636711",
      "fax": "+39 0522 695986",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 10.783333778381348
      }
    },
    {
      "codice": "035038",
      "nome": "San Polo d'Enza",
      "nomeStraniero": null,
      "codiceCatastale": "I123",
      "cap": "42020",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "urp@comune.sanpolodenza.re.it",
      "pec": "sanpolodenza@cert.provincia.re.it",
      "telefono": "+39 0522.241711-77",
      "fax": "+39 0522.874867",
      "coordinate": {
        "lat": 44.63333511352539,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "035039",
      "nome": "Sant'Ilario d'Enza",
      "nomeStraniero": null,
      "codiceCatastale": "I342",
      "cap": "42049",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "civico@comune.santilariodenza.re.it",
      "pec": "santilariodenza@cert.provincia.re.it",
      "telefono": "+39 0522.902811",
      "fax": "+39 0522.902890",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 10.449999809265137
      }
    },
    {
      "codice": "035040",
      "nome": "Scandiano",
      "nomeStraniero": null,
      "codiceCatastale": "I496",
      "cap": "42019",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "segreteria.generale@comune.scandiano.re.it",
      "pec": "scandiano@cert.provincia.re.it",
      "telefono": "+39 0522-764211",
      "fax": "+39 0522-857592",
      "coordinate": {
        "lat": 44.592498779296875,
        "lng": 10.687777519226074
      }
    },
    {
      "codice": "035041",
      "nome": "Toano",
      "nomeStraniero": null,
      "codiceCatastale": "L184",
      "cap": "42010",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "anagrafe@comune.toano.re.it",
      "pec": "comune.toano@legalmail.it",
      "telefono": "+39 0522805110",
      "fax": "+39 0522805445",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 10.566666603088379
      }
    },
    {
      "codice": "035046",
      "nome": "Ventasso",
      "nomeStraniero": null,
      "codiceCatastale": "M364",
      "cap": "42032",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.ventasso.re.it",
      "pec": "comune.ventasso@legalmail.it",
      "telefono": "+39 0522 891120",
      "fax": "+39 0522 891520",
      "coordinate": {
        "lat": 44.36666488647461,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "035042",
      "nome": "Vetto",
      "nomeStraniero": null,
      "codiceCatastale": "L815",
      "cap": "42020",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.vetto.re.it",
      "pec": "comune.vetto@legalmail.it",
      "telefono": "+39 0522 815221",
      "fax": "+39 0522 815694",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 10.333333015441895
      }
    },
    {
      "codice": "035043",
      "nome": "Vezzano sul Crostolo",
      "nomeStraniero": null,
      "codiceCatastale": "L820",
      "cap": "42030",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.vezzano-sul-crostolo.re.it",
      "pec": "vezzanosulcrostolo@cert.provincia.re.it",
      "telefono": "+39 0522 601911",
      "fax": "+39 0522 601947",
      "coordinate": {
        "lat": 44.599998474121094,
        "lng": 10.550000190734863
      }
    },
    {
      "codice": "035044",
      "nome": "Viano",
      "nomeStraniero": null,
      "codiceCatastale": "L831",
      "cap": "42030",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "prot@comune.viano.re.it",
      "pec": "viano@cert.provincia.re.it",
      "telefono": "+39 0522-988321",
      "fax": "+39 0522-988497",
      "coordinate": {
        "lat": 44.54372787475586,
        "lng": 10.62161922454834
      }
    },
    {
      "codice": "035045",
      "nome": "Villa Minozzo",
      "nomeStraniero": null,
      "codiceCatastale": "L969",
      "cap": "42030",
      "prefisso": "0522",
      "provincia": "Reggio nell'Emilia",
      "email": "info@comune.villa-minozzo.re.it",
      "pec": "comune.villaminozzo@legalmail.it",
      "telefono": "+39 0522/801122",
      "fax": "+39 0522/801359",
      "coordinate": {
        "lat": 44.36666488647461,
        "lng": 10.449999809265137
      }
    }
  ],
  "Trapani": [
    {
      "codice": "081001",
      "nome": "Alcamo",
      "nomeStraniero": null,
      "codiceCatastale": "A176",
      "cap": "91011",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "ufficioelettorale@comune.alcamo.tp.it",
      "pec": "comunedialcamo.protocollo@pec.it",
      "telefono": "+39 0924/590111",
      "fax": null,
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "081002",
      "nome": "Buseto Palizzolo",
      "nomeStraniero": null,
      "codiceCatastale": "B288",
      "cap": "91012",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "busetopalizzolo@libero.it",
      "pec": "comunebusetopalizzolo@legalmailpa.it",
      "telefono": "+39 0923/852200",
      "fax": "+39 0923/851088",
      "coordinate": {
        "lat": 38.016666412353516,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "081003",
      "nome": "Calatafimi-Segesta",
      "nomeStraniero": null,
      "codiceCatastale": "B385",
      "cap": "91013",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "info@comune.calatafimisegesta.tp.it",
      "pec": "urp@pec.comune.calatafimisegesta.tp.it",
      "telefono": "+39 0924 950500",
      "fax": "+39 0924 950867",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "081004",
      "nome": "Campobello di Mazara",
      "nomeStraniero": null,
      "codiceCatastale": "B521",
      "cap": "91021",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "protocollo@comune.campobellodimazara.tp.it",
      "pec": "protocollo.campobellodimazara@pec.it",
      "telefono": "+39 0924/933111",
      "fax": "+39 0924/47000",
      "coordinate": {
        "lat": 37.63333511352539,
        "lng": 12.75
      }
    },
    {
      "codice": "081005",
      "nome": "Castellammare del Golfo",
      "nomeStraniero": null,
      "codiceCatastale": "C130",
      "cap": "91014",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": null,
      "pec": "comune.castellammare.tp@pec.it",
      "telefono": "+39 0924/592111",
      "fax": "+39 0924/592310",
      "coordinate": {
        "lat": 38.026390075683594,
        "lng": 12.880555152893066
      }
    },
    {
      "codice": "081006",
      "nome": "Castelvetrano",
      "nomeStraniero": null,
      "codiceCatastale": "C286",
      "cap": "91022",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "sindaco@comune.castelvetrano.tp.it",
      "pec": "protocollo@pec.comune.castelvetrano.tp.it",
      "telefono": "+39 0924/909111",
      "fax": "+39 0924/905688",
      "coordinate": {
        "lat": 37.678611755371094,
        "lng": 12.791666984558105
      }
    },
    {
      "codice": "081007",
      "nome": "Custonaci",
      "nomeStraniero": null,
      "codiceCatastale": "D234",
      "cap": "91015",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "urp@comunecustonaci.it",
      "pec": "protocollo@pec.comunecustonaci.it",
      "telefono": "+39 0923 976711",
      "fax": "+39 0923 973422",
      "coordinate": {
        "lat": 38.078826904296875,
        "lng": 12.675125122070312
      }
    },
    {
      "codice": "081008",
      "nome": "Erice",
      "nomeStraniero": null,
      "codiceCatastale": "D423",
      "cap": "91016",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "leonardo.dibenedetto@comune.erice.tp.it",
      "pec": "protocollo@pec.comune.erice.tp.it",
      "telefono": "+39 0923/502149",
      "fax": "+39 0923/869728",
      "coordinate": {
        "lat": 38.037498474121094,
        "lng": 12.587499618530273
      }
    },
    {
      "codice": "081009",
      "nome": "Favignana",
      "nomeStraniero": null,
      "codiceCatastale": "D518",
      "cap": "91023",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "sanna53@virgilio.it",
      "pec": "comune.favignana.tp@postecert.it",
      "telefono": "+39 0923/920016 - +39 0923/920017",
      "fax": "+39 0923/921156",
      "coordinate": {
        "lat": 37.93333435058594,
        "lng": 12.333333015441895
      }
    },
    {
      "codice": "081010",
      "nome": "Gibellina",
      "nomeStraniero": null,
      "codiceCatastale": "E023",
      "cap": "91024",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "urp.gibellina@libero.it",
      "pec": "protocollo@pec.gibellina.gov.it",
      "telefono": "+39 0924/985111",
      "fax": "+39 0924/69908",
      "coordinate": {
        "lat": 37.81666564941406,
        "lng": 12.866666793823242
      }
    },
    {
      "codice": "081011",
      "nome": "Marsala",
      "nomeStraniero": null,
      "codiceCatastale": "E974",
      "cap": "91025",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "adamo.matilde@comune.marsala.tp.it",
      "pec": "certificati@pec.comune.marsala.tp.it",
      "telefono": "+39 0923 993111",
      "fax": "+39 0923 953402",
      "coordinate": {
        "lat": 37.79805374145508,
        "lng": 12.43416690826416
      }
    },
    {
      "codice": "081012",
      "nome": "Mazara del Vallo",
      "nomeStraniero": null,
      "codiceCatastale": "F061",
      "cap": "91020",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "sindaco@comune.mazaradelvallo.tp.it",
      "pec": "protocollo@pec.comune.mazaradelvallo.tp.it",
      "telefono": "+39 0923671111",
      "fax": "+39 0923671413",
      "coordinate": {
        "lat": 37.65166091918945,
        "lng": 12.587510108947754
      }
    },
    {
      "codice": "081025",
      "nome": "Misiliscemi",
      "nomeStraniero": null,
      "codiceCatastale": "M432",
      "cap": "91031",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": null,
      "pec": null,
      "telefono": null,
      "fax": null,
      "coordinate": {
        "lat": 37.88515090942383,
        "lng": 12.635307312011719
      }
    },
    {
      "codice": "081013",
      "nome": "Paceco",
      "nomeStraniero": null,
      "codiceCatastale": "G208",
      "cap": "91027",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "sindaco@comune.paceco.tp.it",
      "pec": "protocollo@pec.comune.paceco.tp.it",
      "telefono": "+39 0923.401111",
      "fax": "+39 0923 401222",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 12.550000190734863
      }
    },
    {
      "codice": "081014",
      "nome": "Pantelleria",
      "nomeStraniero": null,
      "codiceCatastale": "G315",
      "cap": "91017",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "sbelvisi@comunepantelleria.it",
      "pec": "protocollo@pec.comunepantelleria.it",
      "telefono": "+39 0923/695036",
      "fax": "+39 0923/911981",
      "coordinate": {
        "lat": 36.787498474121094,
        "lng": 11.992500305175781
      }
    },
    {
      "codice": "081015",
      "nome": "Partanna",
      "nomeStraniero": null,
      "codiceCatastale": "G347",
      "cap": "91028",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "armando.restivo@unionebelice.it",
      "pec": "partanna@pec.it",
      "telefono": "+39 0924/923252",
      "fax": "+39 0924/923899",
      "coordinate": {
        "lat": 37.72888946533203,
        "lng": 12.889444351196289
      }
    },
    {
      "codice": "081024",
      "nome": "Petrosino",
      "nomeStraniero": null,
      "codiceCatastale": "M281",
      "cap": "91020",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "info@comune.petrosino.tp.it",
      "pec": "protocollo@pec.comune.petrosino.tp.it",
      "telefono": "+39 0923 731711",
      "fax": "+39 0923 985400",
      "coordinate": {
        "lat": 37.712711334228516,
        "lng": 12.499640464782715
      }
    },
    {
      "codice": "081016",
      "nome": "Poggioreale",
      "nomeStraniero": null,
      "codiceCatastale": "G767",
      "cap": "91020",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "sindaco@comune.poggioreale.tp.it",
      "pec": "comunepoggioreale@pec.it",
      "telefono": "+39 0924 75777",
      "fax": "+39 0924 71121",
      "coordinate": {
        "lat": 37.76499938964844,
        "lng": 13.036666870117188
      }
    },
    {
      "codice": "081017",
      "nome": "Salaparuta",
      "nomeStraniero": null,
      "codiceCatastale": "H688",
      "cap": "91020",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "finanze.salaparuta@libero.it",
      "pec": "protocollo.salaparuta@cert.legalmail.it",
      "telefono": "+39 0924/71263",
      "fax": "+39 0924/75722",
      "coordinate": {
        "lat": 37.75,
        "lng": 13
      }
    },
    {
      "codice": "081018",
      "nome": "Salemi",
      "nomeStraniero": null,
      "codiceCatastale": "H700",
      "cap": "91018",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "protocollo@cittadisalemi.it",
      "pec": "protocollo@pec.cittadisalemi.it",
      "telefono": "+39 0924 991111",
      "fax": "+39 0924/981663",
      "coordinate": {
        "lat": 37.81666564941406,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "081020",
      "nome": "San Vito Lo Capo",
      "nomeStraniero": null,
      "codiceCatastale": "I407",
      "cap": "91010",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "tributigrammatico@libero.it",
      "pec": "protocollocomunesanvitolocapo@postacert.it",
      "telefono": "+39 0923/621282",
      "fax": "+39 0923/621205",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 12.75
      }
    },
    {
      "codice": "081019",
      "nome": "Santa Ninfa",
      "nomeStraniero": null,
      "codiceCatastale": "I291",
      "cap": "91029",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "segretario@comunesantaninfa.it",
      "pec": "protocollo@pec.comune.santaninfa.tp.it",
      "telefono": "+39 0924/992201",
      "fax": "+39 0924/62100",
      "coordinate": {
        "lat": 37.766666412353516,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "081021",
      "nome": "Trapani",
      "nomeStraniero": null,
      "codiceCatastale": "L331",
      "cap": "91100",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "maria.biagioni@comune.trapani.it",
      "pec": "sindaco@pec.comune.trapani.it",
      "telefono": "+39 0923/590541",
      "fax": "+39 0923/593361",
      "coordinate": {
        "lat": 38.01750183105469,
        "lng": 12.515000343322754
      }
    },
    {
      "codice": "081022",
      "nome": "Valderice",
      "nomeStraniero": null,
      "codiceCatastale": "G319",
      "cap": "91019",
      "prefisso": "0923",
      "provincia": "Trapani",
      "email": "sindaco@comune.valderice.tp.it",
      "pec": "protocollo.comunevalderice@postecert.it",
      "telefono": "+39 0923/892011",
      "fax": "+39 0923/891452",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "081023",
      "nome": "Vita",
      "nomeStraniero": null,
      "codiceCatastale": "M081",
      "cap": "91010",
      "prefisso": "0924",
      "provincia": "Trapani",
      "email": "sindaco@comune.vita.tp.it",
      "pec": "protocollo@pec.comunedivita.it",
      "telefono": "+39 0924/955277",
      "fax": "+39 0924/955277",
      "coordinate": {
        "lat": 37.86666488647461,
        "lng": 12.816666603088379
      }
    }
  ],
  "Bolzano": [
    {
      "codice": "021001",
      "nome": "Aldino",
      "nomeStraniero": "Aldein",
      "codiceCatastale": "A179",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.aldino.bz.it",
      "pec": "aldein.aldino@legalmail.it",
      "telefono": "+39 0471 886823",
      "fax": "+39 0471 886632",
      "coordinate": {
        "lat": 46.36666488647461,
        "lng": 11.350000381469727
      }
    },
    {
      "codice": "021002",
      "nome": "Andriano",
      "nomeStraniero": "Andrian",
      "codiceCatastale": "A286",
      "cap": "39010",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.andriano.bz.it",
      "pec": "andrian.andriano@legalmail.it",
      "telefono": "+39 0471 510105",
      "fax": "+39 0471 510281",
      "coordinate": {
        "lat": 46.517616271972656,
        "lng": 11.231372833251953
      }
    },
    {
      "codice": "021003",
      "nome": "Anterivo",
      "nomeStraniero": "Altrei",
      "codiceCatastale": "A306",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.anterivo.bz.it",
      "pec": "altrei.anterivo@legalmail.it",
      "telefono": "+39 0471 882021",
      "fax": "+39 0471 882600",
      "coordinate": {
        "lat": 46.278099060058594,
        "lng": 11.366649627685547
      }
    },
    {
      "codice": "021004",
      "nome": "Appiano sulla strada del vino",
      "nomeStraniero": "Eppan an der Weinstraße",
      "codiceCatastale": "A332",
      "cap": "39057",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@appiano.eu",
      "pec": "eppan.appiano@legalmail.it",
      "telefono": "+39 0471 667511",
      "fax": "+39 0471 667577",
      "coordinate": {
        "lat": 46.4553337097168,
        "lng": 11.259049415588379
      }
    },
    {
      "codice": "021005",
      "nome": "Avelengo",
      "nomeStraniero": "Hafling",
      "codiceCatastale": "A507",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.avelengo.bz.it",
      "pec": "hafling.avelengo@legalmail.it",
      "telefono": "+39 0473 279421",
      "fax": "+39 0473 279555",
      "coordinate": {
        "lat": 46.644615173339844,
        "lng": 11.224384307861328
      }
    },
    {
      "codice": "021006",
      "nome": "Badia",
      "nomeStraniero": "Abtei",
      "codiceCatastale": "A537",
      "cap": "39036",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.badia.bz.it",
      "pec": "abtei.badia@legalmail.it",
      "telefono": "+39 0471 839642",
      "fax": "+39 0471 839899",
      "coordinate": {
        "lat": 46.61012268066406,
        "lng": 11.893486976623535
      }
    },
    {
      "codice": "021007",
      "nome": "Barbiano",
      "nomeStraniero": "Barbian",
      "codiceCatastale": "A635",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@barbiano.eu",
      "pec": "barbian.barbiano@legalmail.it",
      "telefono": "+39 0471 654164",
      "fax": "+39 0471 654528",
      "coordinate": {
        "lat": 46.60325622558594,
        "lng": 11.519816398620605
      }
    },
    {
      "codice": "021008",
      "nome": "Bolzano",
      "nomeStraniero": "Bozen",
      "codiceCatastale": "A952",
      "cap": "39100",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "urp@comune.bolzano.it",
      "pec": "bolzano@legalmail.it",
      "telefono": "+39 0471 997111",
      "fax": "+39 0471 997 404",
      "coordinate": {
        "lat": 46.498111724853516,
        "lng": 11.354780197143555
      }
    },
    {
      "codice": "021009",
      "nome": "Braies",
      "nomeStraniero": "Prags",
      "codiceCatastale": "B116",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.braies.bz.it",
      "pec": "prags.braies@legalmail.it",
      "telefono": "+39 0474 748675",
      "fax": "+39 0474 748730",
      "coordinate": {
        "lat": 46.72035217285156,
        "lng": 12.13288402557373
      }
    },
    {
      "codice": "021010",
      "nome": "Brennero",
      "nomeStraniero": "Brenner",
      "codiceCatastale": "B145",
      "cap": "39041",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comunebrennero.eu",
      "pec": "brenner.brennero@legalmail.it",
      "telefono": "+39 0472 632369",
      "fax": "+39 0472 632416",
      "coordinate": {
        "lat": 46.938907623291016,
        "lng": 11.445172309875488
      }
    },
    {
      "codice": "021011",
      "nome": "Bressanone",
      "nomeStraniero": "Brixen",
      "codiceCatastale": "B160",
      "cap": "39042",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@brixen.it",
      "pec": "brixen.bressanone@legalmail.it",
      "telefono": "+39 0472 062000",
      "fax": "+39 0472 062022",
      "coordinate": {
        "lat": 46.716548919677734,
        "lng": 11.657851219177246
      }
    },
    {
      "codice": "021012",
      "nome": "Bronzolo",
      "nomeStraniero": "Branzoll",
      "codiceCatastale": "B203",
      "cap": "39051",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.bronzolo.bz.it",
      "pec": "branzoll.bronzolo@legalmail.it",
      "telefono": "+39 0471 597410",
      "fax": "+39 0471 597413",
      "coordinate": {
        "lat": 46.40446090698242,
        "lng": 11.319510459899902
      }
    },
    {
      "codice": "021013",
      "nome": "Brunico",
      "nomeStraniero": "Bruneck",
      "codiceCatastale": "B220",
      "cap": "39031",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.brunico.bz.it",
      "pec": "bruneck.brunico@legalmail.it",
      "telefono": "+39 0474 545 454",
      "fax": "+39 0474 545455",
      "coordinate": {
        "lat": 46.79631805419922,
        "lng": 11.935511589050293
      }
    },
    {
      "codice": "021014",
      "nome": "Caines",
      "nomeStraniero": "Kuens",
      "codiceCatastale": "B364",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "caines@comune.caines.bz.it",
      "pec": "kuens.caines@legalmail.it",
      "telefono": "+39 0473 241163",
      "fax": "+39 0473 241354",
      "coordinate": {
        "lat": 46.69947814941406,
        "lng": 11.169486999511719
      }
    },
    {
      "codice": "021015",
      "nome": "Caldaro sulla strada del vino",
      "nomeStraniero": "Kaltern an der Weinstraße",
      "codiceCatastale": "B397",
      "cap": "39052",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@caldaro.eu",
      "pec": "kaltern.caldaro@legalmail.it",
      "telefono": "+39 0471 968811",
      "fax": "+39 0471 968896",
      "coordinate": {
        "lat": 46.41325759887695,
        "lng": 11.246203422546387
      }
    },
    {
      "codice": "021016",
      "nome": "Campo di Trens",
      "nomeStraniero": "Freienfeld",
      "codiceCatastale": "B529",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.campoditrens.bz.it",
      "pec": "freienfeld.campoditrens@legalmail.it",
      "telefono": "+39 0472 647115",
      "fax": "+39 0472 647502",
      "coordinate": {
        "lat": 46.876136779785156,
        "lng": 11.48560619354248
      }
    },
    {
      "codice": "021017",
      "nome": "Campo Tures",
      "nomeStraniero": "Sand in Taufers",
      "codiceCatastale": "B570",
      "cap": "39032",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@campotures.eu",
      "pec": "sandintaufers.campotures@legalmail.it",
      "telefono": "+39 0474 677555",
      "fax": "+39 0474 677540",
      "coordinate": {
        "lat": 46.91923522949219,
        "lng": 11.955182075500488
      }
    },
    {
      "codice": "021018",
      "nome": "Castelbello-Ciardes",
      "nomeStraniero": "Kastelbell-Tschars",
      "codiceCatastale": "C062",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@gemeinde.kastelbell-tschars.bz.it",
      "pec": "kastelbell.castelbello@legalmail.it",
      "telefono": "+39 0473 624110",
      "fax": "+39 0473 624112",
      "coordinate": {
        "lat": 46.6283073425293,
        "lng": 10.90176010131836
      }
    },
    {
      "codice": "021019",
      "nome": "Castelrotto",
      "nomeStraniero": "Kastelruth",
      "codiceCatastale": "C254",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.castelrotto.bz.it",
      "pec": "kastelruth.castelrotto@legalmail.it",
      "telefono": "+39 0471 711 500",
      "fax": "+39 0471 707 184",
      "coordinate": {
        "lat": 46.56784439086914,
        "lng": 11.559684753417969
      }
    },
    {
      "codice": "021020",
      "nome": "Cermes",
      "nomeStraniero": "Tscherms",
      "codiceCatastale": "A022",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.cermes.bz.it",
      "pec": "tscherms.cermes@legalmail.it",
      "telefono": "+39 0473 562727",
      "fax": "+39 0473 561716",
      "coordinate": {
        "lat": 46.634620666503906,
        "lng": 11.146563529968262
      }
    },
    {
      "codice": "021021",
      "nome": "Chienes",
      "nomeStraniero": "Kiens",
      "codiceCatastale": "C625",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.chienes.bz.it",
      "pec": "kiens.chienes@legalmail.it",
      "telefono": "+39 0474 565315",
      "fax": "+39 0474 565604",
      "coordinate": {
        "lat": 46.80937957763672,
        "lng": 11.840871810913086
      }
    },
    {
      "codice": "021022",
      "nome": "Chiusa",
      "nomeStraniero": "Klausen",
      "codiceCatastale": "C652",
      "cap": "39043",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.chiusa.bz.it",
      "pec": "klausen.chiusa@legalmail.it",
      "telefono": "+39 0472 858222",
      "fax": "+39 0472 847775",
      "coordinate": {
        "lat": 46.639923095703125,
        "lng": 11.566349983215332
      }
    },
    {
      "codice": "021023",
      "nome": "Cornedo all'Isarco",
      "nomeStraniero": "Karneid",
      "codiceCatastale": "B799",
      "cap": "39053",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.cornedo.bz.it",
      "pec": "karneid.cornedo@legalmail.it",
      "telefono": "+39 0471 361300",
      "fax": "+39 0471 361399",
      "coordinate": {
        "lat": 46.48991394042969,
        "lng": 11.408388137817383
      }
    },
    {
      "codice": "021024",
      "nome": "Cortaccia sulla strada del vino",
      "nomeStraniero": "Kurtatsch an der Weinstraße",
      "codiceCatastale": "D048",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.cortaccia.bz.it",
      "pec": "kurtatschcortaccia@legalmail.it",
      "telefono": "+39 0471 880118",
      "fax": "+39 0471 880136",
      "coordinate": {
        "lat": 46.31401062011719,
        "lng": 11.22387981414795
      }
    },
    {
      "codice": "021025",
      "nome": "Cortina sulla strada del vino",
      "nomeStraniero": "Kurtinig an der Weinstraße",
      "codiceCatastale": "D075",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.cortina.bz.it",
      "pec": "kurtinig.cortina@legalmail.it",
      "telefono": "+39 0471817141",
      "fax": "+39 0471818035",
      "coordinate": {
        "lat": 46.2694091796875,
        "lng": 11.221879959106445
      }
    },
    {
      "codice": "021026",
      "nome": "Corvara in Badia",
      "nomeStraniero": "Corvara",
      "codiceCatastale": "D079",
      "cap": "39033",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@corvara.eu",
      "pec": "corvara@legalmail.it",
      "telefono": "+39 0471 836184",
      "fax": "+39 0471 836480",
      "coordinate": {
        "lat": 46.54957580566406,
        "lng": 11.87403392791748
      }
    },
    {
      "codice": "021027",
      "nome": "Curon Venosta",
      "nomeStraniero": "Graun im Vinschgau",
      "codiceCatastale": "D222",
      "cap": "39027",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@graun.eu",
      "pec": "graun.curon@legalmail.it",
      "telefono": "+39 0473 633127",
      "fax": "+39 0473 633488",
      "coordinate": {
        "lat": 46.80865478515625,
        "lng": 10.5414400100708
      }
    },
    {
      "codice": "021028",
      "nome": "Dobbiaco",
      "nomeStraniero": "Toblach",
      "codiceCatastale": "D311",
      "cap": "39034",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@dobbiaco.eu",
      "pec": "toblach.dobbiaco@legalmail.it",
      "telefono": "+39 0474 970500",
      "fax": "+39 0474 972844",
      "coordinate": {
        "lat": 46.73542404174805,
        "lng": 12.22428035736084
      }
    },
    {
      "codice": "021029",
      "nome": "Egna",
      "nomeStraniero": "Neumarkt",
      "codiceCatastale": "D392",
      "cap": "39044",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.egna.bz.it",
      "pec": "neumarkt.egna@legalmail.it",
      "telefono": "+39 0471829111",
      "fax": "+39 0471829100",
      "coordinate": {
        "lat": 46.31776809692383,
        "lng": 11.273369789123535
      }
    },
    {
      "codice": "021030",
      "nome": "Falzes",
      "nomeStraniero": "Pfalzen",
      "codiceCatastale": "D484",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.falzes.bz.it",
      "pec": "pfalzen.falzes@legalmail.it",
      "telefono": "+39 0474 528139",
      "fax": "+39 0474 528030",
      "coordinate": {
        "lat": 46.811981201171875,
        "lng": 11.884005546569824
      }
    },
    {
      "codice": "021031",
      "nome": "Fiè allo Sciliar",
      "nomeStraniero": "Völs am Schlern",
      "codiceCatastale": "D571",
      "cap": "39050",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.fie.bz.it",
      "pec": "voels.fie@legalmail.it",
      "telefono": "+39 0471 725010",
      "fax": "+39 0471 725031",
      "coordinate": {
        "lat": 46.51776885986328,
        "lng": 11.502776145935059
      }
    },
    {
      "codice": "021032",
      "nome": "Fortezza",
      "nomeStraniero": "Franzensfeste",
      "codiceCatastale": "D731",
      "cap": "39045",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comunefortezza.eu",
      "pec": "franzensfeste.fortezza@legalmail.it",
      "telefono": "+39 0472 458631",
      "fax": "+39 0472 458906",
      "coordinate": {
        "lat": 46.78684616088867,
        "lng": 11.612449645996094
      }
    },
    {
      "codice": "021033",
      "nome": "Funes",
      "nomeStraniero": "Villnöß",
      "codiceCatastale": "D821",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@villnoess.eu",
      "pec": "villnoess.funes@legalmail.it",
      "telefono": "+39 0472 840121",
      "fax": "+39 0472 840398",
      "coordinate": {
        "lat": 46.64286422729492,
        "lng": 11.681374549865723
      }
    },
    {
      "codice": "021034",
      "nome": "Gais",
      "nomeStraniero": "Gais",
      "codiceCatastale": "D860",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@gais.eu",
      "pec": "gais@legalmail.it",
      "telefono": "+39 0474 504127",
      "fax": "+39 0474 504470",
      "coordinate": {
        "lat": 46.83315658569336,
        "lng": 11.947484016418457
      }
    },
    {
      "codice": "021035",
      "nome": "Gargazzone",
      "nomeStraniero": "Gargazon",
      "codiceCatastale": "D923",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.gargazzone.bz.it",
      "pec": "gargazon.gargazone@legalmail.it",
      "telefono": "+39 0473 292334",
      "fax": "+39 0473 291255",
      "coordinate": {
        "lat": 46.584781646728516,
        "lng": 11.202730178833008
      }
    },
    {
      "codice": "021036",
      "nome": "Glorenza",
      "nomeStraniero": "Glurns",
      "codiceCatastale": "E069",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.glorenza.bz.it",
      "pec": "glurns.glorenza@legalmail.it",
      "telefono": "+39 0473 831209",
      "fax": "+39 0473 830350",
      "coordinate": {
        "lat": 46.67149353027344,
        "lng": 10.553913116455078
      }
    },
    {
      "codice": "021117",
      "nome": "La Valle",
      "nomeStraniero": "Wengen",
      "codiceCatastale": "E491",
      "cap": "39030",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@laval.it",
      "pec": "wengen.lavalle@legalmail.it",
      "telefono": "+39 0471 843139",
      "fax": "+39 0471 843248",
      "coordinate": {
        "lat": 46.657718658447266,
        "lng": 11.924325942993164
      }
    },
    {
      "codice": "021037",
      "nome": "Laces",
      "nomeStraniero": "Latsch",
      "codiceCatastale": "E398",
      "cap": "39021",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.laces.bz.it",
      "pec": "latsch.laces@legalmail.it",
      "telefono": "+39 0473 623113",
      "fax": "+39 0473 623683",
      "coordinate": {
        "lat": 46.61734390258789,
        "lng": 10.859539031982422
      }
    },
    {
      "codice": "021038",
      "nome": "Lagundo",
      "nomeStraniero": "Algund",
      "codiceCatastale": "E412",
      "cap": "39022",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@algund.eu",
      "pec": "algund.lagundo@legalmail.it",
      "telefono": "+39 0473262300",
      "fax": "+39 0473262329",
      "coordinate": {
        "lat": 46.682518005371094,
        "lng": 11.123068809509277
      }
    },
    {
      "codice": "021039",
      "nome": "Laion",
      "nomeStraniero": "Lajen",
      "codiceCatastale": "E420",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@laion.eu",
      "pec": "lajen.laion@legalmail.it",
      "telefono": "+39 0471 655613",
      "fax": "+39 0471 655811",
      "coordinate": {
        "lat": 46.609230041503906,
        "lng": 11.564263343811035
      }
    },
    {
      "codice": "021040",
      "nome": "Laives",
      "nomeStraniero": "Leifers",
      "codiceCatastale": "E421",
      "cap": "39055",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.laives.bz.it",
      "pec": "leifers.laives@legalmail.it",
      "telefono": "+39 0471 595700",
      "fax": "+39 0471 595777",
      "coordinate": {
        "lat": 46.42281723022461,
        "lng": 11.334771156311035
      }
    },
    {
      "codice": "021041",
      "nome": "Lana",
      "nomeStraniero": "Lana",
      "codiceCatastale": "E434",
      "cap": "39011",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@gemeinde.lana.bz.it",
      "pec": "lana@legalmail.it",
      "telefono": "+39 0473 567756",
      "fax": "+39 0473 567777",
      "coordinate": {
        "lat": 46.61602020263672,
        "lng": 11.144859313964844
      }
    },
    {
      "codice": "021042",
      "nome": "Lasa",
      "nomeStraniero": "Laas",
      "codiceCatastale": "E457",
      "cap": "39023",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.lasa.bz.it",
      "pec": "laas.lasa@legalmail.it",
      "telefono": "+39 0473 626512",
      "fax": "+39 0473 626133",
      "coordinate": {
        "lat": 46.616615295410156,
        "lng": 10.700159072875977
      }
    },
    {
      "codice": "021043",
      "nome": "Lauregno",
      "nomeStraniero": "Laurein",
      "codiceCatastale": "E481",
      "cap": "39040",
      "prefisso": "0463",
      "provincia": "Bolzano",
      "email": "info@comune.lauregno.bz.it",
      "pec": "laurein.lauregno@legalmail.it",
      "telefono": "+39 0463 530108",
      "fax": "+39 0463 532107",
      "coordinate": {
        "lat": 46.45441436767578,
        "lng": 11.06201457977295
      }
    },
    {
      "codice": "021044",
      "nome": "Luson",
      "nomeStraniero": "Lüsen",
      "codiceCatastale": "E764",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.luson.bz.it",
      "pec": "luesen.luson@legalmail.it",
      "telefono": "+39 0472 413723",
      "fax": "+39 0472 413806",
      "coordinate": {
        "lat": 46.74646759033203,
        "lng": 11.761083602905273
      }
    },
    {
      "codice": "021045",
      "nome": "Magrè sulla strada del vino",
      "nomeStraniero": "Margreid an der Weinstraße",
      "codiceCatastale": "E829",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.magre.bz.it",
      "pec": "margreid.magre@legalmail.it",
      "telefono": "+39 0471 817251",
      "fax": "+39 0471 817788",
      "coordinate": {
        "lat": 46.28791046142578,
        "lng": 11.21068000793457
      }
    },
    {
      "codice": "021046",
      "nome": "Malles Venosta",
      "nomeStraniero": "Mals",
      "codiceCatastale": "E862",
      "cap": "39024",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.malles.bz.it",
      "pec": "mals.malles@legalmail.it",
      "telefono": "+39 0473 831117",
      "fax": "+39 0473 830310",
      "coordinate": {
        "lat": 46.68791961669922,
        "lng": 10.546548843383789
      }
    },
    {
      "codice": "021047",
      "nome": "Marebbe",
      "nomeStraniero": "Enneberg",
      "codiceCatastale": "E938",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.marebbe.bz.it",
      "pec": "enneberg.marebbe@legalmail.it",
      "telefono": "+39 0474 501023",
      "fax": "+39 0474 501644",
      "coordinate": {
        "lat": 46.698001861572266,
        "lng": 11.931229591369629
      }
    },
    {
      "codice": "021048",
      "nome": "Marlengo",
      "nomeStraniero": "Marling",
      "codiceCatastale": "E959",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@gemeinde.marling.bz.it",
      "pec": "marling.marlengo@legalmail.it",
      "telefono": "+39 0473 060111",
      "fax": "+39 0473 447769",
      "coordinate": {
        "lat": 46.65563201904297,
        "lng": 11.140534400939941
      }
    },
    {
      "codice": "021049",
      "nome": "Martello",
      "nomeStraniero": "Martell",
      "codiceCatastale": "E981",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.martello.bz.it",
      "pec": "martell.martello@legalmail.it",
      "telefono": "+39 0473744581",
      "fax": "+39 0473744676",
      "coordinate": {
        "lat": 46.56559371948242,
        "lng": 10.7830171585083
      }
    },
    {
      "codice": "021050",
      "nome": "Meltina",
      "nomeStraniero": "Mölten",
      "codiceCatastale": "F118",
      "cap": "39010",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@meltina.eu",
      "pec": "moelten.meltina@legalmail.it",
      "telefono": "+39 0471 668001",
      "fax": "+39 0471 668201",
      "coordinate": {
        "lat": 46.58639144897461,
        "lng": 11.256155967712402
      }
    },
    {
      "codice": "021051",
      "nome": "Merano",
      "nomeStraniero": "Meran",
      "codiceCatastale": "F132",
      "cap": "39012",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.merano.bz.it",
      "pec": "meran.merano@legalmail.it",
      "telefono": "+39 0473 250111",
      "fax": "+39 0473 237690",
      "coordinate": {
        "lat": 46.6695556640625,
        "lng": 11.159419059753418
      }
    },
    {
      "codice": "021052",
      "nome": "Monguelfo-Tesido",
      "nomeStraniero": "Welsberg-Taisten",
      "codiceCatastale": "F371",
      "cap": "39035",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.monguelfo-tesido.bz.it",
      "pec": "welsberg.monguelfo@legalmail.it",
      "telefono": "+39 0474 946012",
      "fax": "+39 0474 944595",
      "coordinate": {
        "lat": 46.75689697265625,
        "lng": 12.104352951049805
      }
    },
    {
      "codice": "021053",
      "nome": "Montagna",
      "nomeStraniero": "Montan",
      "codiceCatastale": "F392",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.montagna.bz.it",
      "pec": "montan.montagna@legalmail.it",
      "telefono": "+39 0471 819774",
      "fax": "+39 0471 819996",
      "coordinate": {
        "lat": 46.33032989501953,
        "lng": 11.300270080566406
      }
    },
    {
      "codice": "021054",
      "nome": "Moso in Passiria",
      "nomeStraniero": "Moos in Passeier",
      "codiceCatastale": "F766",
      "cap": "39013",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.mosoinpassiria.bz.it",
      "pec": "moos.moso@legalmail.it",
      "telefono": "+39 0473 643535",
      "fax": "+39 0473 643855",
      "coordinate": {
        "lat": 46.83183288574219,
        "lng": 11.167292594909668
      }
    },
    {
      "codice": "021055",
      "nome": "Nalles",
      "nomeStraniero": "Nals",
      "codiceCatastale": "F836",
      "cap": "39010",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@nalles.eu",
      "pec": "nals.nalles@legalmail.it",
      "telefono": "+39 0471 675811",
      "fax": "+39 0471 675840",
      "coordinate": {
        "lat": 46.543487548828125,
        "lng": 11.20462703704834
      }
    },
    {
      "codice": "021056",
      "nome": "Naturno",
      "nomeStraniero": "Naturns",
      "codiceCatastale": "F849",
      "cap": "39025",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.naturno.bz.it",
      "pec": "naturns.naturno@legalmail.it",
      "telefono": "+39 0473 671211",
      "fax": "+39 0473 671212",
      "coordinate": {
        "lat": 46.64988708496094,
        "lng": 11.004195213317871
      }
    },
    {
      "codice": "021057",
      "nome": "Naz-Sciaves",
      "nomeStraniero": "Natz-Schabs",
      "codiceCatastale": "F856",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.naz-sciaves.bz.it",
      "pec": "natzschabs.nazsciaves@legalmail.it",
      "telefono": "+39 0472 412131",
      "fax": "+39 0472 412048",
      "coordinate": {
        "lat": 46.7695198059082,
        "lng": 11.66591739654541
      }
    },
    {
      "codice": "021058",
      "nome": "Nova Levante",
      "nomeStraniero": "Welschnofen",
      "codiceCatastale": "F949",
      "cap": "39056",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@nova-levante.eu",
      "pec": "welschnofen.novalevante@legalmail.it",
      "telefono": "+39 0471 613114",
      "fax": "+39 0471 613285",
      "coordinate": {
        "lat": 46.430233001708984,
        "lng": 11.53895092010498
      }
    },
    {
      "codice": "021059",
      "nome": "Nova Ponente",
      "nomeStraniero": "Deutschnofen",
      "codiceCatastale": "F950",
      "cap": "39050",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@nova-ponente.eu",
      "pec": "deutschnofen.novaponente@legalmail.it",
      "telefono": "+39 0471 617500",
      "fax": "+39 0471 617555",
      "coordinate": {
        "lat": 46.41399383544922,
        "lng": 11.42518138885498
      }
    },
    {
      "codice": "021060",
      "nome": "Ora",
      "nomeStraniero": "Auer",
      "codiceCatastale": "G083",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.ora.bz.it",
      "pec": "auer.ora@legalmail.it",
      "telefono": "+39 0471 089000",
      "fax": "+39 0471 811101",
      "coordinate": {
        "lat": 46.34687042236328,
        "lng": 11.297470092773438
      }
    },
    {
      "codice": "021061",
      "nome": "Ortisei",
      "nomeStraniero": "St. Ulrich",
      "codiceCatastale": "G140",
      "cap": "39046",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.ortisei.bz.it",
      "pec": "stulrich.ortisei@legalmail.it",
      "telefono": "+39 0471 782030",
      "fax": null,
      "coordinate": {
        "lat": 46.5752067565918,
        "lng": 11.672138214111328
      }
    },
    {
      "codice": "021062",
      "nome": "Parcines",
      "nomeStraniero": "Partschins",
      "codiceCatastale": "G328",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.parcines.bz.it",
      "pec": "partschins.parcines@legalmail.it",
      "telefono": "+39 0473 966200",
      "fax": "+39 0473 966201",
      "coordinate": {
        "lat": 46.6838493347168,
        "lng": 11.073519706726074
      }
    },
    {
      "codice": "021063",
      "nome": "Perca",
      "nomeStraniero": "Percha",
      "codiceCatastale": "G443",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@gemeinde.percha.bz.it",
      "pec": "percha.perca@legalmail.it",
      "telefono": "+39 0474 401150",
      "fax": "+39 0474 401373",
      "coordinate": {
        "lat": 46.794307708740234,
        "lng": 11.982382774353027
      }
    },
    {
      "codice": "021064",
      "nome": "Plaus",
      "nomeStraniero": "Plaus",
      "codiceCatastale": "G299",
      "cap": "39025",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.plaus.bz.it",
      "pec": "plaus@legalmail.it",
      "telefono": "+39 0473 660075",
      "fax": "+39 0473 660120",
      "coordinate": {
        "lat": 46.65657424926758,
        "lng": 11.041498184204102
      }
    },
    {
      "codice": "021065",
      "nome": "Ponte Gardena",
      "nomeStraniero": "Waidbruck",
      "codiceCatastale": "G830",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@pontegardena.it",
      "pec": "waidbruck.pontegardena@legalmail.it",
      "telefono": "+39 0471 654129",
      "fax": "+39 0471 654125",
      "coordinate": {
        "lat": 46.59821701049805,
        "lng": 11.532509803771973
      }
    },
    {
      "codice": "021066",
      "nome": "Postal",
      "nomeStraniero": "Burgstall",
      "codiceCatastale": "G936",
      "cap": "39014",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.postal.bz.it",
      "pec": "burgstall.postal@legalmail.it",
      "telefono": "+39 0473 291121",
      "fax": "+39 0473 292110",
      "coordinate": {
        "lat": 46.607582092285156,
        "lng": 11.193324089050293
      }
    },
    {
      "codice": "021067",
      "nome": "Prato allo Stelvio",
      "nomeStraniero": "Prad am Stilfserjoch",
      "codiceCatastale": "H004",
      "cap": "39026",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.prato.bz.it",
      "pec": "prad.prato@legalmail.it",
      "telefono": "+39 0473 616064",
      "fax": "+39 0473 616722",
      "coordinate": {
        "lat": 46.61708450317383,
        "lng": 10.591662406921387
      }
    },
    {
      "codice": "021068",
      "nome": "Predoi",
      "nomeStraniero": "Prettau",
      "codiceCatastale": "H019",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@predoi.org",
      "pec": "prettau.predoi@legalmail.it",
      "telefono": "+39 0474 654123",
      "fax": "+39 0474 654614",
      "coordinate": {
        "lat": 47.040740966796875,
        "lng": 12.105891227722168
      }
    },
    {
      "codice": "021069",
      "nome": "Proves",
      "nomeStraniero": "Proveis",
      "codiceCatastale": "H081",
      "cap": "39040",
      "prefisso": "0463",
      "provincia": "Bolzano",
      "email": "info@comune.proves.bz.it",
      "pec": "proveis.proves@legalmail.it",
      "telefono": "+39 0463 530106",
      "fax": "+39 0463 401019",
      "coordinate": {
        "lat": 46.47742462158203,
        "lng": 11.02243709564209
      }
    },
    {
      "codice": "021070",
      "nome": "Racines",
      "nomeStraniero": "Ratschings",
      "codiceCatastale": "H152",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@racines.eu",
      "pec": "ratschings.racines@legalmail.it",
      "telefono": "+39 0472 756722",
      "fax": "+39 0472 756974",
      "coordinate": {
        "lat": 46.880126953125,
        "lng": 11.376055717468262
      }
    },
    {
      "codice": "021071",
      "nome": "Rasun-Anterselva",
      "nomeStraniero": "Rasen-Antholz",
      "codiceCatastale": "H189",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.rasunanterselva.bz.it",
      "pec": "rasenantholz.rasunanterselva@legalmail.it",
      "telefono": "+39 0474 496158",
      "fax": "+39 0474 498145",
      "coordinate": {
        "lat": 46.78189468383789,
        "lng": 12.048893928527832
      }
    },
    {
      "codice": "021072",
      "nome": "Renon",
      "nomeStraniero": "Ritten",
      "codiceCatastale": "H236",
      "cap": "39054",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@renon.eu",
      "pec": "ritten.renon@legalmail.it",
      "telefono": "+39 0471 356132",
      "fax": "+39 0471 357080",
      "coordinate": {
        "lat": 46.54039001464844,
        "lng": 11.457027435302734
      }
    },
    {
      "codice": "021073",
      "nome": "Rifiano",
      "nomeStraniero": "Riffian",
      "codiceCatastale": "H284",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.rifiano.bz.it",
      "pec": "riffian.rifiano@legalmail.it",
      "telefono": "+39 0473 241163",
      "fax": "+39 0473 241354",
      "coordinate": {
        "lat": 46.70363998413086,
        "lng": 11.181443214416504
      }
    },
    {
      "codice": "021074",
      "nome": "Rio di Pusteria",
      "nomeStraniero": "Mühlbach",
      "codiceCatastale": "H299",
      "cap": "39037",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.riodipusteria.bz.it",
      "pec": "muehlbach.riodipusteria@legalmail.it",
      "telefono": "+39 0472 849446",
      "fax": "+39 0472 849633",
      "coordinate": {
        "lat": 46.79594421386719,
        "lng": 11.666827201843262
      }
    },
    {
      "codice": "021075",
      "nome": "Rodengo",
      "nomeStraniero": "Rodeneck",
      "codiceCatastale": "H475",
      "cap": "39037",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.rodengo.bz.it",
      "pec": "rodeneck.rodengo@legalmail.it",
      "telefono": "+39 0472454009",
      "fax": "+39 0472454089",
      "coordinate": {
        "lat": 46.777427673339844,
        "lng": 11.690596580505371
      }
    },
    {
      "codice": "021076",
      "nome": "Salorno sulla strada del vino",
      "nomeStraniero": "Salurn an der Weinstraße",
      "codiceCatastale": "H719",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.salorno.bz.it",
      "pec": "salurn.salorno@legalmail.it",
      "telefono": "+39 0471 888811",
      "fax": "+39 0471 885066",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "021077",
      "nome": "San Candido",
      "nomeStraniero": "Innichen",
      "codiceCatastale": "H786",
      "cap": "39038",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@sancandido.eu",
      "pec": "innichen.sancandido@legalmail.it",
      "telefono": "+39 0474 912543",
      "fax": "+39 0474 914099",
      "coordinate": {
        "lat": 46.732872009277344,
        "lng": 12.279167175292969
      }
    },
    {
      "codice": "021079",
      "nome": "San Genesio Atesino",
      "nomeStraniero": "Jenesien",
      "codiceCatastale": "H858",
      "cap": "39050",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.sangenesioatesino.bz.it",
      "pec": "jenesien.sangenesio@legalmail.it",
      "telefono": "+39 0471 354124",
      "fax": "+39 0471 355466",
      "coordinate": {
        "lat": 46.5350341796875,
        "lng": 11.33138370513916
      }
    },
    {
      "codice": "021080",
      "nome": "San Leonardo in Passiria",
      "nomeStraniero": "St. Leonhard in Passeier",
      "codiceCatastale": "H952",
      "cap": "39015",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@san-leonardo.eu",
      "pec": "stleonhard.sleonardo@legalmail.it",
      "telefono": "+39 0473 656 113",
      "fax": "+39 0473 656 650",
      "coordinate": {
        "lat": 46.811893463134766,
        "lng": 11.247136116027832
      }
    },
    {
      "codice": "021081",
      "nome": "San Lorenzo di Sebato",
      "nomeStraniero": "St. Lorenzen",
      "codiceCatastale": "H956",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@stlorenzen.eu",
      "pec": "stlorenzen.slorenzo@legalmail.it",
      "telefono": "+39 0474 470510",
      "fax": "+39 0474 470590",
      "coordinate": {
        "lat": 46.78345489501953,
        "lng": 11.903071403503418
      }
    },
    {
      "codice": "021082",
      "nome": "San Martino in Badia",
      "nomeStraniero": "St. Martin in Thurn",
      "codiceCatastale": "H988",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.sanmartinoinbadia.bz.it",
      "pec": "stmartinthurn.smartinobadia@legalmail.it",
      "telefono": "+39 0474 523125",
      "fax": "+39 0474 523475",
      "coordinate": {
        "lat": 46.68198776245117,
        "lng": 11.898346900939941
      }
    },
    {
      "codice": "021083",
      "nome": "San Martino in Passiria",
      "nomeStraniero": "St. Martin in Passeier",
      "codiceCatastale": "H989",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@stmp.it",
      "pec": "stmartinpasseier.smartinopassiria@legalmail.it",
      "telefono": "+39 0473 499300",
      "fax": "+39 0473 499320",
      "coordinate": {
        "lat": 46.783939361572266,
        "lng": 11.226799011230469
      }
    },
    {
      "codice": "021084",
      "nome": "San Pancrazio",
      "nomeStraniero": "St. Pankraz",
      "codiceCatastale": "I065",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.sanpancrazio.bz.it",
      "pec": "stpankraz.spancrazio@legalmail.it",
      "telefono": "+39 0473 787133",
      "fax": "+39 0473 787330",
      "coordinate": {
        "lat": 46.58651351928711,
        "lng": 11.086360931396484
      }
    },
    {
      "codice": "021085",
      "nome": "Santa Cristina Valgardena",
      "nomeStraniero": "St. Christina in Gröden",
      "codiceCatastale": "I173",
      "cap": "39047",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.santacristina.bz.it",
      "pec": "stchristina.scristina@legalmail.it",
      "telefono": "+39 0471 792032",
      "fax": "+39 0471 793755",
      "coordinate": {
        "lat": 46.5590705871582,
        "lng": 11.71549129486084
      }
    },
    {
      "codice": "021086",
      "nome": "Sarentino",
      "nomeStraniero": "Sarntal",
      "codiceCatastale": "I431",
      "cap": "39058",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@gemeinde.sarntal.bz.it",
      "pec": "sarntal.sarentino@legalmail.it",
      "telefono": "+39 0471 623121",
      "fax": "+39 0471 622280",
      "coordinate": {
        "lat": 46.64299011230469,
        "lng": 11.356620788574219
      }
    },
    {
      "codice": "021087",
      "nome": "Scena",
      "nomeStraniero": "Schenna",
      "codiceCatastale": "I519",
      "cap": "39017",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.scena.bz.it",
      "pec": "schenna.scena@legalmail.it",
      "telefono": "+39 0473 945621",
      "fax": "+39 0473 945521",
      "coordinate": {
        "lat": 46.69004440307617,
        "lng": 11.186562538146973
      }
    },
    {
      "codice": "021088",
      "nome": "Selva dei Molini",
      "nomeStraniero": "Mühlwald",
      "codiceCatastale": "I593",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@selvadeimolini.eu",
      "pec": "muehlwald.selvadeimolini@legalmail.it",
      "telefono": "+39 0474 653135",
      "fax": "+39 0474 653320",
      "coordinate": {
        "lat": 46.8907470703125,
        "lng": 11.856659889221191
      }
    },
    {
      "codice": "021089",
      "nome": "Selva di Val Gardena",
      "nomeStraniero": "Wolkenstein in Gröden",
      "codiceCatastale": "I591",
      "cap": "39048",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@selva.eu",
      "pec": "wolkenstein.selvadivalgardena@legalmail.it",
      "telefono": "+39 0471 772111",
      "fax": "+39 0471 772100",
      "coordinate": {
        "lat": 46.55575180053711,
        "lng": 11.760563850402832
      }
    },
    {
      "codice": "021118",
      "nome": "Senale-San Felice",
      "nomeStraniero": "Unsere Liebe Frau im Walde-St. Felix",
      "codiceCatastale": "I603",
      "cap": "39010",
      "prefisso": "0463",
      "provincia": "Bolzano",
      "email": "info@senalesanfelice.eu",
      "pec": "ulfraustfelix.senalesanfelice@legalmail.it",
      "telefono": "+39 0463 886103",
      "fax": "+39 0463 886103",
      "coordinate": {
        "lat": 46.4923095703125,
        "lng": 11.130207061767578
      }
    },
    {
      "codice": "021091",
      "nome": "Senales",
      "nomeStraniero": "Schnals",
      "codiceCatastale": "I604",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.senales.bz.it",
      "pec": "schnals.senales@legalmail.it",
      "telefono": "+39 0473 679124",
      "fax": "+39 0473 679288",
      "coordinate": {
        "lat": 46.70453643798828,
        "lng": 10.91061782836914
      }
    },
    {
      "codice": "021092",
      "nome": "Sesto",
      "nomeStraniero": "Sexten",
      "codiceCatastale": "I687",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@sesto.eu",
      "pec": "sexten.sesto@legalmail.it",
      "telefono": "+39 0474 710323",
      "fax": "+39 0474 710105",
      "coordinate": {
        "lat": 46.70274353027344,
        "lng": 12.350543975830078
      }
    },
    {
      "codice": "021093",
      "nome": "Silandro",
      "nomeStraniero": "Schlanders",
      "codiceCatastale": "I729",
      "cap": "39028",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@silandro.it",
      "pec": "schlanders.silandro@legalmail.it",
      "telefono": "+39 0473 / 737 740",
      "fax": "+39 0473 / 737 700",
      "coordinate": {
        "lat": 46.62807083129883,
        "lng": 10.773476600646973
      }
    },
    {
      "codice": "021094",
      "nome": "Sluderno",
      "nomeStraniero": "Schluderns",
      "codiceCatastale": "I771",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.sluderno.bz.it",
      "pec": "schluderns.sluderno@legalmail.it",
      "telefono": "+39 0473 614312",
      "fax": "+39 0473 615147",
      "coordinate": {
        "lat": 46.6645393371582,
        "lng": 10.584816932678223
      }
    },
    {
      "codice": "021095",
      "nome": "Stelvio",
      "nomeStraniero": "Stilfs",
      "codiceCatastale": "I948",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.stelvio.bz.it",
      "pec": "stilfs.stelvio@legalmail.it",
      "telefono": "+39 0473 611739",
      "fax": "+39 0473 611570",
      "coordinate": {
        "lat": 46.598060607910156,
        "lng": 10.545822143554688
      }
    },
    {
      "codice": "021096",
      "nome": "Terento",
      "nomeStraniero": "Terenten",
      "codiceCatastale": "L106",
      "cap": "39030",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.terento.bz.it",
      "pec": "terenten.terento@legalmail.it",
      "telefono": "+39 0472 546110",
      "fax": "+39 0472 546393",
      "coordinate": {
        "lat": 46.828800201416016,
        "lng": 11.776016235351562
      }
    },
    {
      "codice": "021097",
      "nome": "Terlano",
      "nomeStraniero": "Terlan",
      "codiceCatastale": "L108",
      "cap": "39018",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.terlano.bz.it",
      "pec": "terlan.terlano@legalmail.it",
      "telefono": "+39 0471 257131",
      "fax": "+39 0471 257442",
      "coordinate": {
        "lat": 46.529300689697266,
        "lng": 11.248678207397461
      }
    },
    {
      "codice": "021098",
      "nome": "Termeno sulla strada del vino",
      "nomeStraniero": "Tramin an der Weinstraße",
      "codiceCatastale": "L111",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@gemeinde.tramin.bz.it",
      "pec": "tramin.termeno@legalmail.it",
      "telefono": "+39 0471864400",
      "fax": "+39 0471860848",
      "coordinate": {
        "lat": 46.34151077270508,
        "lng": 11.242280006408691
      }
    },
    {
      "codice": "021099",
      "nome": "Tesimo",
      "nomeStraniero": "Tisens",
      "codiceCatastale": "L149",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.tesimo.bz.it",
      "pec": "tisens.tesimo@legalmail.it",
      "telefono": "+39 0473 920922",
      "fax": "+39 0473 920954",
      "coordinate": {
        "lat": 46.56389617919922,
        "lng": 11.169496536254883
      }
    },
    {
      "codice": "021100",
      "nome": "Tires",
      "nomeStraniero": "Tiers",
      "codiceCatastale": "L176",
      "cap": "39050",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.tires.bz.it",
      "pec": "tiers.tires@legalmail.it",
      "telefono": "+39 0471 640055",
      "fax": "+39 0471 642088",
      "coordinate": {
        "lat": 46.46734619140625,
        "lng": 11.527480125427246
      }
    },
    {
      "codice": "021101",
      "nome": "Tirolo",
      "nomeStraniero": "Tirol",
      "codiceCatastale": "L178",
      "cap": "39019",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.tirolo.bz.it",
      "pec": "tirol.tirolo@legalmail.it",
      "telefono": "+39 0473 92 32 22",
      "fax": "+39 0473 92 34 48",
      "coordinate": {
        "lat": 46.69110107421875,
        "lng": 11.154223442077637
      }
    },
    {
      "codice": "021102",
      "nome": "Trodena nel parco naturale",
      "nomeStraniero": "Truden im Naturpark",
      "codiceCatastale": "L444",
      "cap": "39040",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.trodena.bz.it",
      "pec": "truden.trodena@legalmail.it",
      "telefono": "+39 0471 869033",
      "fax": "+39 0471 869213",
      "coordinate": {
        "lat": 46.32181167602539,
        "lng": 11.349820137023926
      }
    },
    {
      "codice": "021103",
      "nome": "Tubre",
      "nomeStraniero": "Taufers im Münstertal",
      "codiceCatastale": "L455",
      "cap": "39020",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.tubre.bz.it",
      "pec": "taufers.tubre@legalmail.it",
      "telefono": "+39 0473 832164",
      "fax": "+39 0473 832350",
      "coordinate": {
        "lat": 46.64561080932617,
        "lng": 10.464153289794922
      }
    },
    {
      "codice": "021104",
      "nome": "Ultimo",
      "nomeStraniero": "Ulten",
      "codiceCatastale": "L490",
      "cap": "39016",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.ultimo.bz.it",
      "pec": "ulten.ultimo@legalmail.it",
      "telefono": "+39 0473 795321",
      "fax": "+39 0473 795036",
      "coordinate": {
        "lat": 46.548583984375,
        "lng": 11.004207611083984
      }
    },
    {
      "codice": "021105",
      "nome": "Vadena",
      "nomeStraniero": "Pfatten",
      "codiceCatastale": "L527",
      "cap": "39051",
      "prefisso": "0471",
      "provincia": "Bolzano",
      "email": "info@comune.vadena.bz.it",
      "pec": "pfatten.vadena@legalmail.it",
      "telefono": "+39 0471 954333",
      "fax": "+39 0471 955258",
      "coordinate": {
        "lat": 46.41399383544922,
        "lng": 11.305076599121094
      }
    },
    {
      "codice": "021107",
      "nome": "Val di Vizze",
      "nomeStraniero": "Pfitsch",
      "codiceCatastale": "L564",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.valdivizze.bz.it",
      "pec": "pfitsch.valdivizze@legalmail.it",
      "telefono": "+39 0472 765 104",
      "fax": "+39 0472 767 250",
      "coordinate": {
        "lat": 46.899993896484375,
        "lng": 11.459300994873047
      }
    },
    {
      "codice": "021106",
      "nome": "Valdaora",
      "nomeStraniero": "Olang",
      "codiceCatastale": "L552",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@comune.valdaora.bz.it",
      "pec": "olang.valdaora@legalmail.it",
      "telefono": "+39 0474 496121",
      "fax": "+39 0474 498292",
      "coordinate": {
        "lat": 46.76118469238281,
        "lng": 12.030410766601562
      }
    },
    {
      "codice": "021108",
      "nome": "Valle Aurina",
      "nomeStraniero": "Ahrntal",
      "codiceCatastale": "L595",
      "cap": "39030",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@valleaurina.eu",
      "pec": "ahrntal.valleaurina@legalmail.it",
      "telefono": "+39 0474 651500",
      "fax": "+39 0474 651565",
      "coordinate": {
        "lat": 46.996009826660156,
        "lng": 11.981242179870605
      }
    },
    {
      "codice": "021109",
      "nome": "Valle di Casies",
      "nomeStraniero": "Gsies",
      "codiceCatastale": "L601",
      "cap": "39035",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@casies.eu",
      "pec": "gsies.valledicasies@legalmail.it",
      "telefono": "+39 0474 978232",
      "fax": "+39 0474 978230",
      "coordinate": {
        "lat": 46.81201934814453,
        "lng": 12.228650093078613
      }
    },
    {
      "codice": "021110",
      "nome": "Vandoies",
      "nomeStraniero": "Vintl",
      "codiceCatastale": "L660",
      "cap": "39030",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.vandoies.bz.it",
      "pec": "vintl.vandoies@legalmail.it",
      "telefono": "+39 0472 869326",
      "fax": "+39 0472 869294",
      "coordinate": {
        "lat": 46.815181732177734,
        "lng": 11.720782279968262
      }
    },
    {
      "codice": "021111",
      "nome": "Varna",
      "nomeStraniero": "Vahrn",
      "codiceCatastale": "L687",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@varna.eu",
      "pec": "vahrn.varna@legalmail.it",
      "telefono": "+39 0472976900",
      "fax": "+39 0472976940",
      "coordinate": {
        "lat": 46.740386962890625,
        "lng": 11.63456916809082
      }
    },
    {
      "codice": "021116",
      "nome": "Velturno",
      "nomeStraniero": "Feldthurns",
      "codiceCatastale": "L724",
      "cap": "39040",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@comune.velturno.bz.it",
      "pec": "feldthurns.velturno@legalmail.it",
      "telefono": "+39 0472 855223",
      "fax": "+39 0472 855131",
      "coordinate": {
        "lat": 46.66867446899414,
        "lng": 11.59843921661377
      }
    },
    {
      "codice": "021112",
      "nome": "Verano",
      "nomeStraniero": "Vöran",
      "codiceCatastale": "L745",
      "cap": "39010",
      "prefisso": "0473",
      "provincia": "Bolzano",
      "email": "info@comune.verano.bz.it",
      "pec": "voeran.verano@legalmail.it",
      "telefono": "+39 0473 278181",
      "fax": "+39 0473 278248",
      "coordinate": {
        "lat": 46.60536575317383,
        "lng": 11.22627067565918
      }
    },
    {
      "codice": "021113",
      "nome": "Villabassa",
      "nomeStraniero": "Niederdorf",
      "codiceCatastale": "L915",
      "cap": "39039",
      "prefisso": "0474",
      "provincia": "Bolzano",
      "email": "info@villabassa.eu",
      "pec": "niederdorf.villabassa@legalmail.it",
      "telefono": "+39 0474 745133",
      "fax": "+39 0474 745305",
      "coordinate": {
        "lat": 46.73823928833008,
        "lng": 12.17130184173584
      }
    },
    {
      "codice": "021114",
      "nome": "Villandro",
      "nomeStraniero": "Villanders",
      "codiceCatastale": "L971",
      "cap": "39043",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@villanders.eu",
      "pec": "villanders.villandro@legalmail.it",
      "telefono": "+39 0472 843219",
      "fax": "+39 0472 843346",
      "coordinate": {
        "lat": 46.6329345703125,
        "lng": 11.540101051330566
      }
    },
    {
      "codice": "021115",
      "nome": "Vipiteno",
      "nomeStraniero": "Sterzing",
      "codiceCatastale": "M067",
      "cap": "39049",
      "prefisso": "0472",
      "provincia": "Bolzano",
      "email": "info@vipiteno.eu",
      "pec": "sterzing.vipiteno@legalmail.it",
      "telefono": "+39 0472 723740",
      "fax": "+39 0472 723749",
      "coordinate": {
        "lat": 46.896324157714844,
        "lng": 11.431940078735352
      }
    }
  ],
  "Lecce": [
    {
      "codice": "075002",
      "nome": "Alessano",
      "nomeStraniero": null,
      "codiceCatastale": "A184",
      "cap": "73031",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.alessano.lecce.it",
      "pec": "comune.alessano.le@pec.rupar.puglia.it",
      "telefono": "+39 0833/782731",
      "fax": "+39 0833/782748",
      "coordinate": {
        "lat": 39.88333511352539,
        "lng": 18.33333396911621
      }
    },
    {
      "codice": "075003",
      "nome": "Alezio",
      "nomeStraniero": null,
      "codiceCatastale": "A185",
      "cap": "73011",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "anagrafe@comune.alezio.le.it",
      "pec": "protocollo.comune.alezio@pec.rupar.puglia.it",
      "telefono": "0833281020",
      "fax": "0833282340",
      "coordinate": {
        "lat": 40.06666564941406,
        "lng": 18.049999237060547
      }
    },
    {
      "codice": "075004",
      "nome": "Alliste",
      "nomeStraniero": null,
      "codiceCatastale": "A208",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.alliste.le.it",
      "pec": "affarigenerali.comune.alliste@pec.rupar.puglia.it",
      "telefono": "+39 0833/902721",
      "fax": "+39 0833/583771",
      "coordinate": {
        "lat": 39.95000076293945,
        "lng": 18.08333396911621
      }
    },
    {
      "codice": "075005",
      "nome": "Andrano",
      "nomeStraniero": null,
      "codiceCatastale": "A281",
      "cap": "73032",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "comunediandrano@mail.clio.it",
      "pec": "segreteria.comune.andrano@pec.rupar.puglia.it",
      "telefono": "+39 0836/929222",
      "fax": "+39 0832/926032",
      "coordinate": {
        "lat": 39.983333587646484,
        "lng": 18.383333206176758
      }
    },
    {
      "codice": "075006",
      "nome": "Aradeo",
      "nomeStraniero": null,
      "codiceCatastale": "A350",
      "cap": "73040",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "elettorale@comune.aradeo.le.it",
      "pec": "protocollo.comune.aradeo@pec.rupar.puglia.it",
      "telefono": "+39 0836/552583",
      "fax": "+39 0836/554008",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 18.133333206176758
      }
    },
    {
      "codice": "075007",
      "nome": "Arnesano",
      "nomeStraniero": null,
      "codiceCatastale": "A425",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "elettorale@comune.arnesano.le.it",
      "pec": "protocollo.comunearnesano@pec.rupar.puglia.it",
      "telefono": "+39 0832/323813",
      "fax": "+39 0832/323283",
      "coordinate": {
        "lat": 40.33333206176758,
        "lng": 18.100000381469727
      }
    },
    {
      "codice": "075008",
      "nome": "Bagnolo del Salento",
      "nomeStraniero": null,
      "codiceCatastale": "A572",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.bagnolodelsalento.le.it",
      "pec": "segreteria.bagnolodelsalento@pec.rupar.puglia.it",
      "telefono": "+39 0836/318005",
      "fax": null,
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 18.350000381469727
      }
    },
    {
      "codice": "075009",
      "nome": "Botrugno",
      "nomeStraniero": null,
      "codiceCatastale": "B086",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "comune.botrugno@libero.it",
      "pec": "protocollo.comune.botrugno@pec.rupar.puglia.it",
      "telefono": "+39 0836/992213",
      "fax": "+39 0836/997205",
      "coordinate": {
        "lat": 40.06666564941406,
        "lng": 18.316667556762695
      }
    },
    {
      "codice": "075010",
      "nome": "Calimera",
      "nomeStraniero": null,
      "codiceCatastale": "B413",
      "cap": "73021",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "suap@comune.calimera.le.it",
      "pec": "protocollocalimera@pec.rupar.puglia.it",
      "telefono": "+39 0832/870202",
      "fax": "+39 0832/872266",
      "coordinate": {
        "lat": 40.25,
        "lng": 18.28333282470703
      }
    },
    {
      "codice": "075011",
      "nome": "Campi Salentina",
      "nomeStraniero": null,
      "codiceCatastale": "B506",
      "cap": "73012",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "protocollo@comune.campi-salentina.le.it",
      "pec": "protocollo.comune.campisalentina@pec.rupar.puglia.it",
      "telefono": "+39 0832  720612",
      "fax": "+39 0832  720616",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 18.016666412353516
      }
    },
    {
      "codice": "075012",
      "nome": "Cannole",
      "nomeStraniero": null,
      "codiceCatastale": "B616",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreterira@comune.cannole.le.it",
      "pec": "segreteria.comune.cannole@pec.rupar.puglia.it",
      "telefono": "+39 0836/318007",
      "fax": "+39 0836/318954",
      "coordinate": {
        "lat": 40.16611099243164,
        "lng": 18.365278244018555
      }
    },
    {
      "codice": "075013",
      "nome": "Caprarica di Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "B690",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "tecnico@comune.caprarica.le.it",
      "pec": "uff_protocollo.comune.caprarica.le@pec.rupar.puglia.it",
      "telefono": "+39 0832/825489",
      "fax": "+39 0832/825561",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 18.25
      }
    },
    {
      "codice": "075014",
      "nome": "Carmiano",
      "nomeStraniero": null,
      "codiceCatastale": "B792",
      "cap": "73041",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "ufficioelettorale@comune.carmiano.le.it",
      "pec": "protocollo.comunecarmiano@pec.rupar.puglia.it",
      "telefono": "+39 0832/600111",
      "fax": "+39 0832/602718",
      "coordinate": {
        "lat": 40.346099853515625,
        "lng": 18.046100616455078
      }
    },
    {
      "codice": "075015",
      "nome": "Carpignano Salentino",
      "nomeStraniero": null,
      "codiceCatastale": "B822",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "amm@comune.carpignanosalentino.le.it",
      "pec": "protocollo.comune.carpignanosalentino@pec.rupar.puglia.it",
      "telefono": "+39 0836/586017",
      "fax": null,
      "coordinate": {
        "lat": 40.197044372558594,
        "lng": 18.33841323852539
      }
    },
    {
      "codice": "075016",
      "nome": "Casarano",
      "nomeStraniero": null,
      "codiceCatastale": "B936",
      "cap": "73042",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ufficiosegreteria@comune.casarano.le.it",
      "pec": "comune.casarano.le@pec.rupar.puglia.it",
      "telefono": "+39 0833/514111",
      "fax": "+39 0833/512103",
      "coordinate": {
        "lat": 40.016666412353516,
        "lng": 18.16666603088379
      }
    },
    {
      "codice": "075017",
      "nome": "Castri di Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "C334",
      "cap": "73020",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "segreteria@comune.castridilecce.le.it",
      "pec": "comunecastridilecce@pec.rupar.puglia.it",
      "telefono": "+39 0832/826451",
      "fax": "+39 0832/826510",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 18.266666412353516
      }
    },
    {
      "codice": "075018",
      "nome": "Castrignano de' Greci",
      "nomeStraniero": null,
      "codiceCatastale": "C335",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ufficiotecnico@comune.castrignanodeigreci.le.it",
      "pec": "segreteria.castrignano.greci@pec.rupar.puglia.it",
      "telefono": "+39 0836/583216",
      "fax": "+39 0836/583007",
      "coordinate": {
        "lat": 40.20000076293945,
        "lng": 18.299999237060547
      }
    },
    {
      "codice": "075019",
      "nome": "Castrignano del Capo",
      "nomeStraniero": null,
      "codiceCatastale": "C336",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "castrcapo.elet@libero.it",
      "pec": "protocollo.castrignanodelcapo@pec.rupar.puglia.it",
      "telefono": "+39 0833/751068",
      "fax": "+39 0833/530544",
      "coordinate": {
        "lat": 39.83333206176758,
        "lng": 18.350000381469727
      }
    },
    {
      "codice": "075096",
      "nome": "Castro",
      "nomeStraniero": null,
      "codiceCatastale": "M261",
      "cap": "73030",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "servizidemografici@comune.castro.le.it",
      "pec": "protocollo.comune.castro@pec.rupar.puglia.it",
      "telefono": "+39 0836/947005",
      "fax": "+39 0836/943896",
      "coordinate": {
        "lat": 40.007022857666016,
        "lng": 18.42573356628418
      }
    },
    {
      "codice": "075020",
      "nome": "Cavallino",
      "nomeStraniero": null,
      "codiceCatastale": "C377",
      "cap": "73020",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "servizi.demografici@comune.cavallino.le.it",
      "pec": "protocollo.comune.cavallino@pec.rupar.puglia.it",
      "telefono": "+39 0832/617320",
      "fax": "+39 0832/617331",
      "coordinate": {
        "lat": 40.31019973754883,
        "lng": 18.20220947265625
      }
    },
    {
      "codice": "075021",
      "nome": "Collepasso",
      "nomeStraniero": null,
      "codiceCatastale": "C865",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ragioneria@comune.collepasso.le.it",
      "pec": "comunecollepasso@pec.rupar.puglia.it",
      "telefono": "+39 0833/346836",
      "fax": "+39 0833/349000",
      "coordinate": {
        "lat": 40.06666564941406,
        "lng": 18.16666603088379
      }
    },
    {
      "codice": "075022",
      "nome": "Copertino",
      "nomeStraniero": null,
      "codiceCatastale": "C978",
      "cap": "73043",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "info@comune.copertino.le.it",
      "pec": "comunecopertino@pec.rupar.puglia.it",
      "telefono": "+39 0832/938344",
      "fax": "+39 0832/933522",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 18.049999237060547
      }
    },
    {
      "codice": "075023",
      "nome": "Corigliano d'Otranto",
      "nomeStraniero": null,
      "codiceCatastale": "D006",
      "cap": "73022",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "garganese.ragioneria@comune.corigliano.le.it",
      "pec": "servizidemografici.corigliano@pec.rupar.puglia.it",
      "telefono": "+39 0836/320712",
      "fax": "+39 0836/320710",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 18.25
      }
    },
    {
      "codice": "075024",
      "nome": "Corsano",
      "nomeStraniero": null,
      "codiceCatastale": "D044",
      "cap": "73033",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "amministr.corsano@libero.it",
      "pec": "comune.corsano@pec.rupar.puglia.it",
      "telefono": "+39 0833/531170",
      "fax": "+39 0833/531122",
      "coordinate": {
        "lat": 39.88911056518555,
        "lng": 18.367469787597656
      }
    },
    {
      "codice": "075025",
      "nome": "Cursi",
      "nomeStraniero": null,
      "codiceCatastale": "D223",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ragioneria@comune.cursi.le.it",
      "pec": "aagg.comune.cursi.le@pec.rupar.puglia.it",
      "telefono": "+39 0836/332014",
      "fax": "+39 0836/434014",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 18.316667556762695
      }
    },
    {
      "codice": "075026",
      "nome": "Cutrofiano",
      "nomeStraniero": null,
      "codiceCatastale": "D237",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "elettorale@comune.cutrofiano.le.it",
      "pec": "settoreamministrativo.comune.cutrofiano@pec.rupar.puglia.it",
      "telefono": "+39 0836/542230",
      "fax": "+39 0836/542176",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 18.200000762939453
      }
    },
    {
      "codice": "075027",
      "nome": "Diso",
      "nomeStraniero": null,
      "codiceCatastale": "D305",
      "cap": "73030",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comunediso.it",
      "pec": "segreteria.comune.diso@pec.rupar.puglia.it",
      "telefono": "+39 0836 920724",
      "fax": "+39 0836 922189",
      "coordinate": {
        "lat": 40.016666412353516,
        "lng": 18.399999618530273
      }
    },
    {
      "codice": "075028",
      "nome": "Gagliano del Capo",
      "nomeStraniero": null,
      "codiceCatastale": "D851",
      "cap": "73034",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "servizio.informatico@comune.gaglianodelcapo.le.it",
      "pec": "ambitosociale.gaglianodelcapo@pec.rupar.puglia.it",
      "telefono": "+39 0833/798311",
      "fax": "+39 0833/798330",
      "coordinate": {
        "lat": 39.849998474121094,
        "lng": 18.366666793823242
      }
    },
    {
      "codice": "075029",
      "nome": "Galatina",
      "nomeStraniero": null,
      "codiceCatastale": "D862",
      "cap": "73013",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "protocollo@comune.galatina.le.it",
      "pec": "protocollo@cert.comune.galatina.le.it",
      "telefono": "+39 0836/633111",
      "fax": "+39 0836/561543",
      "coordinate": {
        "lat": 40.16666793823242,
        "lng": 18.16666603088379
      }
    },
    {
      "codice": "075030",
      "nome": "Galatone",
      "nomeStraniero": null,
      "codiceCatastale": "D863",
      "cap": "73044",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "info@comune.galatone.le.it",
      "pec": "segreteria.comune.galatone@pec.rupar.puglia.it",
      "telefono": "+39 0833/864911",
      "fax": "+39 0883865053",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 18.066667556762695
      }
    },
    {
      "codice": "075031",
      "nome": "Gallipoli",
      "nomeStraniero": null,
      "codiceCatastale": "D883",
      "cap": "73014",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ced@comune.gallipoli.le.it",
      "pec": "servizidemografici.comunegallipoli@pec.rupar.puglia.it",
      "telefono": "+39 0833/275506",
      "fax": "+39 0833/275517",
      "coordinate": {
        "lat": 40.05555725097656,
        "lng": 17.991666793823242
      }
    },
    {
      "codice": "075032",
      "nome": "Giuggianello",
      "nomeStraniero": null,
      "codiceCatastale": "E053",
      "cap": "73030",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.giuggianello.le.it",
      "pec": "segreteria.comune.giuggianello@pec.rupar.puglia.it",
      "telefono": "+39 0836/444920",
      "fax": "+39 0836/444924",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 18.366666793823242
      }
    },
    {
      "codice": "075033",
      "nome": "Giurdignano",
      "nomeStraniero": null,
      "codiceCatastale": "E061",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.giurdignano.le.it",
      "pec": "segreteria.comune.giurdignano.le@pec.rupar.puglia.it",
      "telefono": "+39 0836/813003",
      "fax": "+39 0836/813503",
      "coordinate": {
        "lat": 40.121524810791016,
        "lng": 18.43273162841797
      }
    },
    {
      "codice": "075034",
      "nome": "Guagnano",
      "nomeStraniero": null,
      "codiceCatastale": "E227",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "ufficiosegreteria@comune.guagnano.le.it",
      "pec": "protocollo.comuneguagnano@pec.rupar.puglia.it",
      "telefono": "+39 0832 704021",
      "fax": "+39 0832 704646",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 17.950000762939453
      }
    },
    {
      "codice": "075035",
      "nome": "Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "E506",
      "cap": "73100",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "gianni.tarantino@comune.lecce.it",
      "pec": "protocollo@pec.comune.lecce.it",
      "telefono": "+39 0832/682520",
      "fax": "+39 0832/682572",
      "coordinate": {
        "lat": 40.352012634277344,
        "lng": 18.169137954711914
      }
    },
    {
      "codice": "075036",
      "nome": "Lequile",
      "nomeStraniero": null,
      "codiceCatastale": "E538",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "protocollo@comune.lequile.le.it",
      "pec": "protocollo@pec.comune.lequile.le.it",
      "telefono": "+39 0832/639112",
      "fax": "+39 0832/638903",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 18.133333206176758
      }
    },
    {
      "codice": "075037",
      "nome": "Leverano",
      "nomeStraniero": null,
      "codiceCatastale": "E563",
      "cap": "73045",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "servizidemografici@comune.leverano.le.it",
      "pec": "protocollo.comune.leverano@pec.rupar.puglia.it",
      "telefono": "+39 0832/923428",
      "fax": "+39 0832/925315",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 18.08333396911621
      }
    },
    {
      "codice": "075038",
      "nome": "Lizzanello",
      "nomeStraniero": null,
      "codiceCatastale": "E629",
      "cap": "73023",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "ufficio.anagrafe@comune.lizzanello.le.it",
      "pec": "ufficio.ragioneria.lizzanello@pec.rupar.puglia.it",
      "telefono": "+39 0832/651145",
      "fax": "+39 0832/651145",
      "coordinate": {
        "lat": 40.30474853515625,
        "lng": 18.222829818725586
      }
    },
    {
      "codice": "075039",
      "nome": "Maglie",
      "nomeStraniero": null,
      "codiceCatastale": "E815",
      "cap": "73024",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "fpezzuto@comune.maglie.le.it",
      "pec": "comunemaglie@pec.rupar.puglia.it",
      "telefono": "+39 0836/489111",
      "fax": "+39 0836/484256",
      "coordinate": {
        "lat": 40.11666488647461,
        "lng": 18.299999237060547
      }
    },
    {
      "codice": "075040",
      "nome": "Martano",
      "nomeStraniero": null,
      "codiceCatastale": "E979",
      "cap": "73025",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ifficioced@comune.martano.le.it",
      "pec": "comune.martano@pec.rupar.puglia.it",
      "telefono": "+39 0836/575272",
      "fax": "+39 0836/572347",
      "coordinate": {
        "lat": 40.20000076293945,
        "lng": 18.299999237060547
      }
    },
    {
      "codice": "075041",
      "nome": "Martignano",
      "nomeStraniero": null,
      "codiceCatastale": "E984",
      "cap": "73020",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "anagrafe@comune.martignano.le.it",
      "pec": "segreteria.comune.martignano@pec.rupar.puglia.it",
      "telefono": "+39 0832/801254",
      "fax": "+39 0832/801077",
      "coordinate": {
        "lat": 40.23820877075195,
        "lng": 18.256019592285156
      }
    },
    {
      "codice": "075042",
      "nome": "Matino",
      "nomeStraniero": null,
      "codiceCatastale": "F054",
      "cap": "73046",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "comunematino@comune.matino.le.it",
      "pec": "comunematino@pec.rupar.puglia.it",
      "telefono": "+39 0833.519515",
      "fax": "+39 0833.519531",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 18.133333206176758
      }
    },
    {
      "codice": "075043",
      "nome": "Melendugno",
      "nomeStraniero": null,
      "codiceCatastale": "F101",
      "cap": "73026",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.melendugno.le.it",
      "pec": "comune.melendugno@legalmail.it",
      "telefono": "+39 0832/832111",
      "fax": "+39 0832/832545",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 18.33333396911621
      }
    },
    {
      "codice": "075044",
      "nome": "Melissano",
      "nomeStraniero": null,
      "codiceCatastale": "F109",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ufficioelettorale@comune.melissano.le.it",
      "pec": "elettorale.comunemelissano@pec.rupar.puglia.it",
      "telefono": "+39 0833/586218",
      "fax": "+39 0833/586244",
      "coordinate": {
        "lat": 39.96666717529297,
        "lng": 18.133333206176758
      }
    },
    {
      "codice": "075045",
      "nome": "Melpignano",
      "nomeStraniero": null,
      "codiceCatastale": "F117",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ragioneria@comune.melpignano.le.it",
      "pec": "protocollo.comune.melpignano@pec.rupar.puglia.it",
      "telefono": "+39 0836/332161",
      "fax": "+39 0836/434035",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 18.299999237060547
      }
    },
    {
      "codice": "075046",
      "nome": "Miggiano",
      "nomeStraniero": null,
      "codiceCatastale": "F194",
      "cap": "73035",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ufficioragioneria@comune.miggiano.le.it",
      "pec": "ufficioprotocollomiggiano@pec.rupar.puglia.it",
      "telefono": "+39 0833/761143",
      "fax": "+39 0833/764920",
      "coordinate": {
        "lat": 39.96666717529297,
        "lng": 18.316667556762695
      }
    },
    {
      "codice": "075047",
      "nome": "Minervino di Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "F221",
      "cap": "73027",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.minervino.le.it",
      "pec": "affarigenerali.comuneminervino.le@pec.rupar.puglia.it",
      "telefono": "+39 0836/891063",
      "fax": "+39 0836/818534",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 18.433332443237305
      }
    },
    {
      "codice": "075048",
      "nome": "Monteroni di Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "F604",
      "cap": "73047",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "segretaria@comune.monteroni.le.it",
      "pec": "protocollogenerale.comune.monteroni@pec.rupar.puglia.it",
      "telefono": "+39 0832/326674",
      "fax": "+39 0832/322613",
      "coordinate": {
        "lat": 40.33333206176758,
        "lng": 18.100000381469727
      }
    },
    {
      "codice": "075049",
      "nome": "Montesano Salentino",
      "nomeStraniero": null,
      "codiceCatastale": "F623",
      "cap": "73030",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "segretario@comune.montesanosalentino.le.it",
      "pec": "protocollo.comune.montesanosalentino@pec.rupar.puglia.it",
      "telefono": "+39 0833.763611",
      "fax": "+39 0833.763602",
      "coordinate": {
        "lat": 39.97578430175781,
        "lng": 18.32261085510254
      }
    },
    {
      "codice": "075050",
      "nome": "Morciano di Leuca",
      "nomeStraniero": null,
      "codiceCatastale": "F716",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "segreteria@comune.morcianodileuca.le.it",
      "pec": "segreteria.morcianodileuca.le@pec.rupar.puglia.it",
      "telefono": "+39 0833 743100",
      "fax": "+39 0833 743204",
      "coordinate": {
        "lat": 39.849998474121094,
        "lng": 18.316667556762695
      }
    },
    {
      "codice": "075051",
      "nome": "Muro Leccese",
      "nomeStraniero": null,
      "codiceCatastale": "F816",
      "cap": "73036",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "info@comune.muroleccese.le.it",
      "pec": "segreteriamuro@pec.rupar.puglia.it",
      "telefono": "+39 0836/443211",
      "fax": "+39 0836/443226",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 18.33333396911621
      }
    },
    {
      "codice": "075052",
      "nome": "Nardò",
      "nomeStraniero": null,
      "codiceCatastale": "F842",
      "cap": "73048",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "costanza.derocco@comune.nardo.le.it",
      "pec": "protocollo@pecnardo.it",
      "telefono": "+39 0833/838813",
      "fax": "+39 0833/567238",
      "coordinate": {
        "lat": 40.17972183227539,
        "lng": 18.03333282470703
      }
    },
    {
      "codice": "075053",
      "nome": "Neviano",
      "nomeStraniero": null,
      "codiceCatastale": "F881",
      "cap": "73040",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "protocollo@comune.neviano.le.it",
      "pec": "protocolloneviano@pec.rupar.puglia.it",
      "telefono": "+39 0836/610711",
      "fax": "+39 0836/610799",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 18.116666793823242
      }
    },
    {
      "codice": "075054",
      "nome": "Nociglia",
      "nomeStraniero": null,
      "codiceCatastale": "F916",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.nociglia.le.it",
      "pec": "protocollo.comune.nociglia@pec.rupar.puglia.it",
      "telefono": "+39 0836/936008",
      "fax": "+39 0836/938053",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 18.33333396911621
      }
    },
    {
      "codice": "075055",
      "nome": "Novoli",
      "nomeStraniero": null,
      "codiceCatastale": "F970",
      "cap": "73051",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "protocollo@comune.novoli.le.it",
      "pec": "protocollo.comune.novoli@pec.rupar.puglia.it",
      "telefono": "+39 0832 711371",
      "fax": "+39 0832 712005",
      "coordinate": {
        "lat": 40.38333511352539,
        "lng": 18.049999237060547
      }
    },
    {
      "codice": "075056",
      "nome": "Ortelle",
      "nomeStraniero": null,
      "codiceCatastale": "G136",
      "cap": "73030",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ragioneria@comune.ortelle.le.it",
      "pec": "sindaco.comune.ortelle@pec.rupar.puglia.it",
      "telefono": "+39 0836/958014",
      "fax": null,
      "coordinate": {
        "lat": 40.03444290161133,
        "lng": 18.393054962158203
      }
    },
    {
      "codice": "075057",
      "nome": "Otranto",
      "nomeStraniero": null,
      "codiceCatastale": "G188",
      "cap": "73028",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ced@comune.otranto.le.it",
      "pec": "segreteria.comune.otranto@pec.rupar.puglia.it",
      "telefono": "+39 0836/871311",
      "fax": "+39 0836/801683",
      "coordinate": {
        "lat": 40.147823333740234,
        "lng": 18.485933303833008
      }
    },
    {
      "codice": "075058",
      "nome": "Palmariggi",
      "nomeStraniero": null,
      "codiceCatastale": "G285",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "anagrafepalm@libero.it",
      "pec": "segreteriapalmariggi@pec.rupar.puglia.it",
      "telefono": "+39 0836/354014",
      "fax": "+39 0836/354241",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 18.383333206176758
      }
    },
    {
      "codice": "075059",
      "nome": "Parabita",
      "nomeStraniero": null,
      "codiceCatastale": "G325",
      "cap": "73052",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "elettorale@comune.parabita.le.it",
      "pec": "info.comune.parabita@pec.rupar.puglia.it",
      "telefono": "+39 0833 392325",
      "fax": null,
      "coordinate": {
        "lat": 40.04999923706055,
        "lng": 18.133333206176758
      }
    },
    {
      "codice": "075060",
      "nome": "Patù",
      "nomeStraniero": null,
      "codiceCatastale": "G378",
      "cap": "73053",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "segreteria@comune.patu.le.it",
      "pec": "segreteria.patu.le@pec.rupar.puglia.it",
      "telefono": "+39 0833 752061",
      "fax": "+39 0833 765707",
      "coordinate": {
        "lat": 39.849998474121094,
        "lng": 18.33333396911621
      }
    },
    {
      "codice": "075061",
      "nome": "Poggiardo",
      "nomeStraniero": null,
      "codiceCatastale": "G751",
      "cap": "73037",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.poggiardo.le.it",
      "pec": "affarigenerali.comune.poggiardo@pec.rupar.puglia.it",
      "telefono": "+39 0836/909812",
      "fax": "+39 0836/909863",
      "coordinate": {
        "lat": 40.04999923706055,
        "lng": 18.383333206176758
      }
    },
    {
      "codice": "075097",
      "nome": "Porto Cesareo",
      "nomeStraniero": null,
      "codiceCatastale": "M263",
      "cap": "73010",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "demografici@comune.portocesareo.le.it",
      "pec": "protocollo.comune.portocesareo@pec.rupar.puglia.it",
      "telefono": "+39 0833/858103",
      "fax": "+39 0833/858151",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 17.899999618530273
      }
    },
    {
      "codice": "075098",
      "nome": "Presicce-Acquarica",
      "nomeStraniero": null,
      "codiceCatastale": "M428",
      "cap": "73054",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": null,
      "pec": "protocollo@cert.comune.presicceacquarica.le.it",
      "telefono": "+39 0833 726405",
      "fax": "+39 0833 726170",
      "coordinate": {
        "lat": 39.903228759765625,
        "lng": 18.254606246948242
      }
    },
    {
      "codice": "075063",
      "nome": "Racale",
      "nomeStraniero": null,
      "codiceCatastale": "H147",
      "cap": "73055",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.racale.le.it",
      "pec": "affarigenerali.comune.racale@pec.rupar.puglia.it",
      "telefono": "+39 0833/902324",
      "fax": "+39 0833/902321",
      "coordinate": {
        "lat": 39.96666717529297,
        "lng": 18.100000381469727
      }
    },
    {
      "codice": "075064",
      "nome": "Ruffano",
      "nomeStraniero": null,
      "codiceCatastale": "H632",
      "cap": "73049",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "segreteria@comune.ruffano.le.it",
      "pec": "segreteria.comune.ruffano@pec.rupar.puglia.it",
      "telefono": "+39 0833  695511",
      "fax": "+39 0833  695537",
      "coordinate": {
        "lat": 39.983333587646484,
        "lng": 18.25
      }
    },
    {
      "codice": "075065",
      "nome": "Salice Salentino",
      "nomeStraniero": null,
      "codiceCatastale": "H708",
      "cap": "73015",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "affarigenerali@comune.salicesalentino.le.it",
      "pec": "protocollo.comunesalicesalentino@pec.rupar.puglia.it",
      "telefono": "+39 0832/723333",
      "fax": "+39 0832/723333",
      "coordinate": {
        "lat": 40.38333511352539,
        "lng": 17.96666717529297
      }
    },
    {
      "codice": "075066",
      "nome": "Salve",
      "nomeStraniero": null,
      "codiceCatastale": "H729",
      "cap": "73050",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "elettorale.salve@libero.it",
      "pec": "comunedisalve@pec.rupar.puglia.it",
      "telefono": "+39 0833741224",
      "fax": "+39 0833/528084",
      "coordinate": {
        "lat": 39.86666488647461,
        "lng": 18.299999237060547
      }
    },
    {
      "codice": "075095",
      "nome": "San Cassiano",
      "nomeStraniero": null,
      "codiceCatastale": "M264",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.sancassiano.le.it",
      "pec": "anagrafe.comune.sancassiano@pec.rupar.puglia.it",
      "telefono": "+39 0836/992100",
      "fax": "+39 0836/992397",
      "coordinate": {
        "lat": 40.05630874633789,
        "lng": 18.333919525146484
      }
    },
    {
      "codice": "075068",
      "nome": "San Cesario di Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "H793",
      "cap": "73016",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "ragioneria@comune.sancesariodilecce.le.it",
      "pec": "protocollo.comune.sancesariodilecce@pec.rupar.puglia.it",
      "telefono": "+39 0832/205366",
      "fax": "+39 0833/202741",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 18.16666603088379
      }
    },
    {
      "codice": "075069",
      "nome": "San Donato di Lecce",
      "nomeStraniero": null,
      "codiceCatastale": "H826",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "ufficiotecnico@comune.sandonatodilecce.le.it",
      "pec": "comunesandonatodilecce@pec.rupar.puglia.it",
      "telefono": "+39 0832/657503",
      "fax": "+39 0832/657504",
      "coordinate": {
        "lat": 40.266666412353516,
        "lng": 18.183332443237305
      }
    },
    {
      "codice": "075071",
      "nome": "San Pietro in Lama",
      "nomeStraniero": null,
      "codiceCatastale": "I115",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "ufficio.segreteria@comune.sanpietroinlama.le.it",
      "pec": "comunesanpietroinlama@pec.rupar.puglia.it",
      "telefono": "+39 0832/631114",
      "fax": "+39 0832/633157",
      "coordinate": {
        "lat": 40.29999923706055,
        "lng": 18.116666793823242
      }
    },
    {
      "codice": "075067",
      "nome": "Sanarica",
      "nomeStraniero": null,
      "codiceCatastale": "H757",
      "cap": "73030",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "info@comune.sanarica.le.it",
      "pec": "utc.comune.sanarica@pec.rupar.puglia.it",
      "telefono": "+39 0836.343218",
      "fax": "+39 0836.341085",
      "coordinate": {
        "lat": 40.08333206176758,
        "lng": 18.350000381469727
      }
    },
    {
      "codice": "075070",
      "nome": "Sannicola",
      "nomeStraniero": null,
      "codiceCatastale": "I059",
      "cap": "73017",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ufficio.elettorale@comune.sannicola.le.it",
      "pec": "ufficio.elettorale.sannicola.le@pec.rupar.puglia.it",
      "telefono": "+39 0833/231430",
      "fax": "+39 0833/233713",
      "coordinate": {
        "lat": 40.099998474121094,
        "lng": 18.066667556762695
      }
    },
    {
      "codice": "075072",
      "nome": "Santa Cesarea Terme",
      "nomeStraniero": null,
      "codiceCatastale": "I172",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "affarigeneralisct@libero.it",
      "pec": "economicofinanziario.comune.santacesareaterme@pec.rupar.puglia.it",
      "telefono": "+39 0836949811",
      "fax": "+39 0836  949806",
      "coordinate": {
        "lat": 40.03632354736328,
        "lng": 18.45683479309082
      }
    },
    {
      "codice": "075073",
      "nome": "Scorrano",
      "nomeStraniero": null,
      "codiceCatastale": "I549",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "settoresegreteria@comune.scorrano.le.it",
      "pec": "ufficioprotocollo.comune.scorrano@pec.rupar.puglia.it",
      "telefono": "+39 0836/463311",
      "fax": "+39 0836/463335",
      "coordinate": {
        "lat": 40.090179443359375,
        "lng": 18.299930572509766
      }
    },
    {
      "codice": "075074",
      "nome": "Seclì",
      "nomeStraniero": null,
      "codiceCatastale": "I559",
      "cap": "73050",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ufficiosegreteria@comune.secli.le",
      "pec": "ufficiosegreteria.comune.secli.le@pec.rupar.puglia.it",
      "telefono": "+39 0836 55.42.13",
      "fax": "+39 0836 55.46.86",
      "coordinate": {
        "lat": 40.13333511352539,
        "lng": 18.100000381469727
      }
    },
    {
      "codice": "075075",
      "nome": "Sogliano Cavour",
      "nomeStraniero": null,
      "codiceCatastale": "I780",
      "cap": "73010",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "affarigenerali@comunesoglianocavour.le.it",
      "pec": "affarigenerali.comunesoglianoc@pec.rupar.puglia.it",
      "telefono": "+39 0836/543084",
      "fax": "+39 0836/543092",
      "coordinate": {
        "lat": 40.150001525878906,
        "lng": 18.200000762939453
      }
    },
    {
      "codice": "075076",
      "nome": "Soleto",
      "nomeStraniero": null,
      "codiceCatastale": "I800",
      "cap": "73010",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.soleto.le.it",
      "pec": "protocollo.comune.soleto@pec.rupar.puglia.it",
      "telefono": "+39 0836 667014",
      "fax": "+39 0836 667427",
      "coordinate": {
        "lat": 40.187721252441406,
        "lng": 18.207361221313477
      }
    },
    {
      "codice": "075077",
      "nome": "Specchia",
      "nomeStraniero": null,
      "codiceCatastale": "I887",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "segreteria@comune.specchia.lecce.it",
      "pec": "segreteria.comune.specchia.le@pec.rupar.puglia.it",
      "telefono": "+39 0833/536293",
      "fax": "+39 0833/639769",
      "coordinate": {
        "lat": 39.9380989074707,
        "lng": 18.29840087890625
      }
    },
    {
      "codice": "075078",
      "nome": "Spongano",
      "nomeStraniero": null,
      "codiceCatastale": "I923",
      "cap": "73038",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "ufficiotecnico@comune.spongano.le.it",
      "pec": "protocollospongano@pec.rupar.puglia.it",
      "telefono": "+39 0836/945015",
      "fax": "+39 0836/940821",
      "coordinate": {
        "lat": 40.016666412353516,
        "lng": 18.366666793823242
      }
    },
    {
      "codice": "075079",
      "nome": "Squinzano",
      "nomeStraniero": null,
      "codiceCatastale": "I930",
      "cap": "73018",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "info@comune.squinzano.le.it",
      "pec": "comunesquinzano@pec.rupar.puglia.it",
      "telefono": "+39 0832/1778900",
      "fax": "+39 0832/785412",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 18.049999237060547
      }
    },
    {
      "codice": "075080",
      "nome": "Sternatia",
      "nomeStraniero": null,
      "codiceCatastale": "I950",
      "cap": "73010",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "demografici@comune.sternatia.le.it",
      "pec": "demografici.comune.sternatia@pec.rupar.puglia.it",
      "telefono": "+39 0836/666001",
      "fax": "+39 0836/666356",
      "coordinate": {
        "lat": 40.21666717529297,
        "lng": 18.233333587646484
      }
    },
    {
      "codice": "075081",
      "nome": "Supersano",
      "nomeStraniero": null,
      "codiceCatastale": "L008",
      "cap": "73040",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "anagrafe@comune.supersano.le.it",
      "pec": "segreteria.comune.supersano@pec.rupar.puglia.it",
      "telefono": "+39 0833/632514",
      "fax": "+39 0833/632579",
      "coordinate": {
        "lat": 40.016666412353516,
        "lng": 18.25
      }
    },
    {
      "codice": "075082",
      "nome": "Surano",
      "nomeStraniero": null,
      "codiceCatastale": "L010",
      "cap": "73030",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "segreteria@comune.surano.le.it",
      "pec": "protocollosurano@pec.rupar.puglia.it",
      "telefono": "+39 0836/939002",
      "fax": "+39 0836/938338",
      "coordinate": {
        "lat": 40.03333282470703,
        "lng": 18.350000381469727
      }
    },
    {
      "codice": "075083",
      "nome": "Surbo",
      "nomeStraniero": null,
      "codiceCatastale": "L011",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "sindaco@comune.surbo.le.it",
      "pec": "comunesurbo@pec.it",
      "telefono": "+39 0832/360811",
      "fax": "+39 082/360821",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 18.133333206176758
      }
    },
    {
      "codice": "075084",
      "nome": "Taurisano",
      "nomeStraniero": null,
      "codiceCatastale": "L064",
      "cap": "73056",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "info@comune.taurisano.le.it",
      "pec": "comune.taurisano.le@pec.rupar.puglia.it",
      "telefono": "+39 0833/626411",
      "fax": "+39 0833/622442",
      "coordinate": {
        "lat": 39.95000076293945,
        "lng": 18.16666603088379
      }
    },
    {
      "codice": "075085",
      "nome": "Taviano",
      "nomeStraniero": null,
      "codiceCatastale": "L074",
      "cap": "73057",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "comunicazione@comune.taviano.le.it",
      "pec": "demografici.comune.taviano@pec.rupar.puglia.it",
      "telefono": "+39 0833/916111",
      "fax": "+39 0833/914365",
      "coordinate": {
        "lat": 39.983333587646484,
        "lng": 18.08333396911621
      }
    },
    {
      "codice": "075086",
      "nome": "Tiggiano",
      "nomeStraniero": null,
      "codiceCatastale": "L166",
      "cap": "73030",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "info.comune.tiggiano@pec.rupar.puglia.it",
      "pec": "servizidemografici.comune.tiggiano@pec.rupar.puglia.it",
      "telefono": "+39 0833/531351",
      "fax": "+39 0833/533288",
      "coordinate": {
        "lat": 39.900001525878906,
        "lng": 18.366666793823242
      }
    },
    {
      "codice": "075087",
      "nome": "Trepuzzi",
      "nomeStraniero": null,
      "codiceCatastale": "L383",
      "cap": "73019",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "sindaco@comune.trepuzzi.le.it",
      "pec": "segreteria.comune.trepuzzi@pec.rupar.puglia.it",
      "telefono": "+39 0832/754111",
      "fax": "+39 0832/753087",
      "coordinate": {
        "lat": 40.400001525878906,
        "lng": 18.066667556762695
      }
    },
    {
      "codice": "075088",
      "nome": "Tricase",
      "nomeStraniero": null,
      "codiceCatastale": "L419",
      "cap": "73039",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "sindaco@comune.tricase.le.it",
      "pec": "protocollo.comune.tricase@pec.rupar.puglia.it",
      "telefono": "+39 0833777111",
      "fax": "+39 0833 777246",
      "coordinate": {
        "lat": 39.93333435058594,
        "lng": 18.366666793823242
      }
    },
    {
      "codice": "075089",
      "nome": "Tuglie",
      "nomeStraniero": null,
      "codiceCatastale": "L462",
      "cap": "73058",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "elettorale@comune.tuglie.le.it",
      "pec": "protocollo.tuglie@pec.rupar.puglia.it",
      "telefono": "+39 0833/596521",
      "fax": "+39 0833/597124",
      "coordinate": {
        "lat": 40.07345962524414,
        "lng": 18.09872055053711
      }
    },
    {
      "codice": "075090",
      "nome": "Ugento",
      "nomeStraniero": null,
      "codiceCatastale": "L484",
      "cap": "73059",
      "prefisso": "0833",
      "provincia": "Lecce",
      "email": "ufficioprotocollo@comune.ugento.le.it",
      "pec": "elettorale.comune.ugento@pec.rupar.puglia.it",
      "telefono": "+39 0833/557109",
      "fax": "+39 0833/556496 - 0833555099",
      "coordinate": {
        "lat": 39.93333435058594,
        "lng": 18.16666603088379
      }
    },
    {
      "codice": "075091",
      "nome": "Uggiano la Chiesa",
      "nomeStraniero": null,
      "codiceCatastale": "L485",
      "cap": "73020",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "info@comuneuggianolachiesa.it",
      "pec": "ufficioprotocollo.uggianolachiesa.le@pec.rupar.puglia.it",
      "telefono": "+39 0836/812008",
      "fax": "+39 0836/812106",
      "coordinate": {
        "lat": 40.101722717285156,
        "lng": 18.44933319091797
      }
    },
    {
      "codice": "075092",
      "nome": "Veglie",
      "nomeStraniero": null,
      "codiceCatastale": "L711",
      "cap": "73010",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "comune.veglie@clio.it",
      "pec": "protocollo.comuneveglie@pec.rupar.puglia.it",
      "telefono": "+39 0832  969597",
      "fax": "+39 0832  966236",
      "coordinate": {
        "lat": 40.33333206176758,
        "lng": 17.96666717529297
      }
    },
    {
      "codice": "075093",
      "nome": "Vernole",
      "nomeStraniero": null,
      "codiceCatastale": "L776",
      "cap": "73029",
      "prefisso": "0832",
      "provincia": "Lecce",
      "email": "info@comune.vernole.le.it",
      "pec": "elettorale.comune.vernole@pec.rupar.puglia.it",
      "telefono": "+39 0832/899111",
      "fax": "+39 0832/892522",
      "coordinate": {
        "lat": 40.28333282470703,
        "lng": 18.299999237060547
      }
    },
    {
      "codice": "075094",
      "nome": "Zollino",
      "nomeStraniero": null,
      "codiceCatastale": "M187",
      "cap": "73010",
      "prefisso": "0836",
      "provincia": "Lecce",
      "email": "protocollo@comune.zollino.le.it",
      "pec": "protocollo.comune.zollino@pec.rupar.puglia.it",
      "telefono": "+39 0836/600003",
      "fax": "+39 0836/600645",
      "coordinate": {
        "lat": 40.20000076293945,
        "lng": 18.25
      }
    }
  ],
  "Ravenna": [
    {
      "codice": "039001",
      "nome": "Alfonsine",
      "nomeStraniero": null,
      "codiceCatastale": "A191",
      "cap": "48011",
      "prefisso": "0544",
      "provincia": "Ravenna",
      "email": "rastellis@comune.alfonsine.ra.it",
      "pec": "pg.comune.alfonsine.ra.it@legalmail.it",
      "telefono": "+39 0544 866611",
      "fax": "+39 0545 38137",
      "coordinate": {
        "lat": 44.50611114501953,
        "lng": 12.04111099243164
      }
    },
    {
      "codice": "039002",
      "nome": "Bagnacavallo",
      "nomeStraniero": null,
      "codiceCatastale": "A547",
      "cap": "48012",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": null,
      "pec": "pg.comune.bagnacavallo.ra.it@legalmail.it",
      "telefono": "+39 0545 280811",
      "fax": "+39 0545 61224",
      "coordinate": {
        "lat": 44.41686248779297,
        "lng": 11.97606372833252
      }
    },
    {
      "codice": "039003",
      "nome": "Bagnara di Romagna",
      "nomeStraniero": null,
      "codiceCatastale": "A551",
      "cap": "48010",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "anagrafe@comune.bagnaradiromagna.ra.it",
      "pec": "pg.comune.bagnara.ra.it@legalmail.it",
      "telefono": "+39 0545 905503",
      "fax": "+39 0545 76636",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "039004",
      "nome": "Brisighella",
      "nomeStraniero": null,
      "codiceCatastale": "B188",
      "cap": "48013",
      "prefisso": "0546",
      "provincia": "Ravenna",
      "email": "annas@comune.brisighella.ra.it",
      "pec": "comune.brisighella@cert.provincia.ra.it",
      "telefono": "+39 0546 994411",
      "fax": "+39 0546 80295",
      "coordinate": {
        "lat": 44.21666717529297,
        "lng": 11.766666412353516
      }
    },
    {
      "codice": "039005",
      "nome": "Casola Valsenio",
      "nomeStraniero": null,
      "codiceCatastale": "B982",
      "cap": "48032",
      "prefisso": "0546",
      "provincia": "Ravenna",
      "email": "segreteria@comune.casolavalsenio.ra.it",
      "pec": "comune.casolavalsenio@cert.provincia.ra.it",
      "telefono": "+39 0546 976515",
      "fax": "+39 0546 691039",
      "coordinate": {
        "lat": 44.21666717529297,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "039006",
      "nome": "Castel Bolognese",
      "nomeStraniero": null,
      "codiceCatastale": "C065",
      "cap": "48014",
      "prefisso": "0546",
      "provincia": "Ravenna",
      "email": "protocollo@comune.castelbolognse.ra.it",
      "pec": "comune.castelbolognese@cert.provincia.ra.it",
      "telefono": "+39 0546 655811",
      "fax": "+39 0546 55973",
      "coordinate": {
        "lat": 44.31666564941406,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "039007",
      "nome": "Cervia",
      "nomeStraniero": null,
      "codiceCatastale": "C553",
      "cap": "48015",
      "prefisso": "0544",
      "provincia": "Ravenna",
      "email": "protocollo@comunecervia.it",
      "pec": "comune.cervia@legalmail.it",
      "telefono": "+39 0544 979111",
      "fax": "+39 0544 72340",
      "coordinate": {
        "lat": 44.25,
        "lng": 12.366666793823242
      }
    },
    {
      "codice": "039008",
      "nome": "Conselice",
      "nomeStraniero": null,
      "codiceCatastale": "C963",
      "cap": "48017",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "segreteria@comune.conselice.ra.it",
      "pec": "pg.comune.conselice.ra.it@legalmail.it",
      "telefono": "+39 0545 986941",
      "fax": "+39 0545 38113",
      "coordinate": {
        "lat": 44.516666412353516,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "039009",
      "nome": "Cotignola",
      "nomeStraniero": null,
      "codiceCatastale": "D121",
      "cap": "48010",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "anagrafe@comune.cotignola.ra.it",
      "pec": "demografico.cotignola@legalmail.it",
      "telefono": "+39 0545 908860",
      "fax": "+39 0545 41282",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "039010",
      "nome": "Faenza",
      "nomeStraniero": null,
      "codiceCatastale": "D458",
      "cap": "48018",
      "prefisso": "0546",
      "provincia": "Ravenna",
      "email": "sindaco@comune.faenza.ra.it",
      "pec": "comune.faenza@cert.provincia.ra.it",
      "telefono": "+39 0546691111",
      "fax": "+39 0546691499",
      "coordinate": {
        "lat": 44.28555679321289,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "039011",
      "nome": "Fusignano",
      "nomeStraniero": null,
      "codiceCatastale": "D829",
      "cap": "48010",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "comunefusignano@provincia.ra.it",
      "pec": "pg.comune.fusignano.ra.it@legalmail.it",
      "telefono": "+39 0545 955656",
      "fax": "+39 0545 38108",
      "coordinate": {
        "lat": 44.46666717529297,
        "lng": 11.949999809265137
      }
    },
    {
      "codice": "039012",
      "nome": "Lugo",
      "nomeStraniero": null,
      "codiceCatastale": "E730",
      "cap": "48022",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "urp@comune.lugo.ra.it",
      "pec": "pg.comune.lugo.ra.it@cert.legalmail.it",
      "telefono": "+39 0545 38444",
      "fax": "+39 0545 38377",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "039013",
      "nome": "Massa Lombarda",
      "nomeStraniero": null,
      "codiceCatastale": "F029",
      "cap": "48024",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "Sindaco@comune.massalombarda.ra.it",
      "pec": "pg.comune.massalombarda.ra.it@legalmail.it",
      "telefono": "+39 0545 985811",
      "fax": "+39 0545 82759",
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 11.816666603088379
      }
    },
    {
      "codice": "039014",
      "nome": "Ravenna",
      "nomeStraniero": null,
      "codiceCatastale": "H199",
      "cap": "48121",
      "prefisso": "0544",
      "provincia": "Ravenna",
      "email": "gabsindaco@comune.ra.it",
      "pec": "comune.ravenna@legalmail.it",
      "telefono": "+39 0544 482111",
      "fax": "+39 0544 482390",
      "coordinate": {
        "lat": 44.41611099243164,
        "lng": 12.201666831970215
      }
    },
    {
      "codice": "039015",
      "nome": "Riolo Terme",
      "nomeStraniero": null,
      "codiceCatastale": "H302",
      "cap": "48025",
      "prefisso": "0546",
      "provincia": "Ravenna",
      "email": "demografici@comune.rioloterme.ra.it",
      "pec": "comune.rioloterme@cert.provincia.ra.it",
      "telefono": "+39 0546 77411",
      "fax": "+39 0546 70842",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 11.733333587646484
      }
    },
    {
      "codice": "039016",
      "nome": "Russi",
      "nomeStraniero": null,
      "codiceCatastale": "H642",
      "cap": "48026",
      "prefisso": "0544",
      "provincia": "Ravenna",
      "email": "urp@comune.russi.ra.it",
      "pec": "pg.comune.russi.ra.it@legalmail.it",
      "telefono": "+39 0544 587611",
      "fax": "+39 0544 582126",
      "coordinate": {
        "lat": 44.37644958496094,
        "lng": 12.033349990844727
      }
    },
    {
      "codice": "039017",
      "nome": "Sant'Agata sul Santerno",
      "nomeStraniero": null,
      "codiceCatastale": "I196",
      "cap": "48020",
      "prefisso": "0545",
      "provincia": "Ravenna",
      "email": "sindaco@comune.santagatasulsanterno.ra.it",
      "pec": "pg.comune.santagata.ra.it@legalmail.it",
      "telefono": "+39 0545 919900",
      "fax": "+39 0545 38134",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "039018",
      "nome": "Solarolo",
      "nomeStraniero": null,
      "codiceCatastale": "I787",
      "cap": "48027",
      "prefisso": "0546",
      "provincia": "Ravenna",
      "email": "URP@comune.Solarolo.ra.it",
      "pec": "comune.solarolo@cert.provincia.ra.it",
      "telefono": "+39 0546 618451",
      "fax": "+39 0546 691009",
      "coordinate": {
        "lat": 44.36666488647461,
        "lng": 11.850000381469727
      }
    }
  ],
  "Palermo": [
    {
      "codice": "082001",
      "nome": "Alia",
      "nomeStraniero": null,
      "codiceCatastale": "A195",
      "cap": "90021",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "demografici.alia@virgilio.it",
      "pec": "anagrafealia@pec.it",
      "telefono": "+39 091/8214309",
      "fax": "+39 091/8214309",
      "coordinate": {
        "lat": 37.78333282470703,
        "lng": 13.716666221618652
      }
    },
    {
      "codice": "082002",
      "nome": "Alimena",
      "nomeStraniero": null,
      "codiceCatastale": "A202",
      "cap": "90020",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "anagrafealimena@tiscali.it",
      "pec": "protocollo.comunealimena@sicurezzapostale.it",
      "telefono": "+39 0921/568012",
      "fax": "+39 0921/568160",
      "coordinate": {
        "lat": 37.70000076293945,
        "lng": 14.116666793823242
      }
    },
    {
      "codice": "082003",
      "nome": "Aliminusa",
      "nomeStraniero": null,
      "codiceCatastale": "A203",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "urp@comunedialiminusa.it",
      "pec": "comunedialiminusa@pec.it",
      "telefono": "+39 091/8999180",
      "fax": "+39 091/8997122",
      "coordinate": {
        "lat": 37.864437103271484,
        "lng": 13.781389236450195
      }
    },
    {
      "codice": "082004",
      "nome": "Altavilla Milicia",
      "nomeStraniero": null,
      "codiceCatastale": "A229",
      "cap": "90010",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "segreteria@altavillamilicia.eu",
      "pec": "info@pec.altavillamilicia.eu",
      "telefono": "+39 091915409",
      "fax": "+39 091951826",
      "coordinate": {
        "lat": 38.04222106933594,
        "lng": 13.550277709960938
      }
    },
    {
      "codice": "082005",
      "nome": "Altofonte",
      "nomeStraniero": null,
      "codiceCatastale": "A239",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "protocollo@comune.altofonte.pa.it",
      "pec": "comune.altofonte@gigapec.it",
      "telefono": "+39 091 6648111",
      "fax": "+39 091 6640257",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 13.300000190734863
      }
    },
    {
      "codice": "082006",
      "nome": "Bagheria",
      "nomeStraniero": null,
      "codiceCatastale": "A546",
      "cap": "90011",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "dirigente.affarigenerali@comune.bagheria.pa.it",
      "pec": "direttoregenerale@postecert.it",
      "telefono": "+39 091 943300",
      "fax": "+39 091 943389",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 13.5
      }
    },
    {
      "codice": "082007",
      "nome": "Balestrate",
      "nomeStraniero": null,
      "codiceCatastale": "A592",
      "cap": "90041",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe.balestrate@libero.it",
      "pec": "comunebalestrate@pec.it",
      "telefono": "+39 091.8980011",
      "fax": "+39 091.8980079",
      "coordinate": {
        "lat": 38.05125045776367,
        "lng": 13.007240295410156
      }
    },
    {
      "codice": "082008",
      "nome": "Baucina",
      "nomeStraniero": null,
      "codiceCatastale": "A719",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "info@comune.baucina.pa.it",
      "pec": "protocollo@pec.comune.baucina.pa.it",
      "telefono": "+39 0918202295",
      "fax": "+39 0918202868",
      "coordinate": {
        "lat": 37.93333435058594,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "082009",
      "nome": "Belmonte Mezzagno",
      "nomeStraniero": null,
      "codiceCatastale": "A764",
      "cap": "90031",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "proto@comune.belmonte.pa.it",
      "pec": "protocollo.belmontemezzagno@pec.it",
      "telefono": "+39 0918738002",
      "fax": "+39 0918729857",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 13.383333206176758
      }
    },
    {
      "codice": "082010",
      "nome": "Bisacquino",
      "nomeStraniero": null,
      "codiceCatastale": "A882",
      "cap": "90032",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "protocollo@comune.bisacquino.pa.it",
      "pec": "protocollo@pec.comune.bisacquino.pa.it",
      "telefono": "+39 0918351933",
      "fax": "+39 0918309135",
      "coordinate": {
        "lat": 37.70500183105469,
        "lng": 13.260000228881836
      }
    },
    {
      "codice": "082082",
      "nome": "Blufi",
      "nomeStraniero": null,
      "codiceCatastale": "M268",
      "cap": "90020",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "info@comune.blufi.pa.it",
      "pec": "info@pec.comune.blufi.pa.it",
      "telefono": "+39 0921648911",
      "fax": "+39 0921648924",
      "coordinate": {
        "lat": 37.75194549560547,
        "lng": 14.074166297912598
      }
    },
    {
      "codice": "082011",
      "nome": "Bolognetta",
      "nomeStraniero": null,
      "codiceCatastale": "A946",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe.bolognetta@virgilio.it",
      "pec": "prot.bol@pec.comunebolognetta.it",
      "telefono": "+39 0918724037",
      "fax": "+39 0918724377",
      "coordinate": {
        "lat": 37.96455383300781,
        "lng": 13.456049919128418
      }
    },
    {
      "codice": "082012",
      "nome": "Bompietro",
      "nomeStraniero": null,
      "codiceCatastale": "A958",
      "cap": "90020",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "demografici@comune.bompietro.pa.it",
      "pec": "protocollo@pec.comune.bompietro.pa.it",
      "telefono": "+39 0921561011",
      "fax": "+39 0921647798",
      "coordinate": {
        "lat": 37.75,
        "lng": 14.100000381469727
      }
    },
    {
      "codice": "082013",
      "nome": "Borgetto",
      "nomeStraniero": null,
      "codiceCatastale": "A991",
      "cap": "90042",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "ufficio.elettorale@comune.borgetto.pa.it",
      "pec": "ufficioprotocollo@pec.it",
      "telefono": "+39 0918981093",
      "fax": null,
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 13.149999618530273
      }
    },
    {
      "codice": "082014",
      "nome": "Caccamo",
      "nomeStraniero": null,
      "codiceCatastale": "B315",
      "cap": "90012",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "elettorale@comune.caccamo.pa.it",
      "pec": "anagrafe.comunecaccamo@postecert.it",
      "telefono": "+39 0918103244",
      "fax": "+39 0918121864",
      "coordinate": {
        "lat": 37.93333435058594,
        "lng": 13.666666984558105
      }
    },
    {
      "codice": "082015",
      "nome": "Caltavuturo",
      "nomeStraniero": null,
      "codiceCatastale": "B430",
      "cap": "90022",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "elettorale@comune.caltavuturo.pa.it",
      "pec": "protocollo.comunedicaltavuturo@pec.it",
      "telefono": "+39 0921547311",
      "fax": "+39 0921541585",
      "coordinate": {
        "lat": 37.81666564941406,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "082016",
      "nome": "Campofelice di Fitalia",
      "nomeStraniero": null,
      "codiceCatastale": "B533",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "servizioelettorale@interfree.it",
      "pec": "campofelicedifitalia@pec.it",
      "telefono": "+39 0918200010",
      "fax": "+39 0918200090",
      "coordinate": {
        "lat": 37.83333206176758,
        "lng": 13.483333587646484
      }
    },
    {
      "codice": "082017",
      "nome": "Campofelice di Roccella",
      "nomeStraniero": null,
      "codiceCatastale": "B532",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "anagrafe@comune.campofelicediroccella.pa.it",
      "pec": "protocollo.campofelicediroccella@pec.it",
      "telefono": "+39 0921939116",
      "fax": "+39 0921934606",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 13.883333206176758
      }
    },
    {
      "codice": "082018",
      "nome": "Campofiorito",
      "nomeStraniero": null,
      "codiceCatastale": "B535",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "comune.campofiorito@libero.it",
      "pec": "comune.campofiorito@pec.it",
      "telefono": "+39 0918466212",
      "fax": "+39 0918466429",
      "coordinate": {
        "lat": 37.75,
        "lng": 13.266666412353516
      }
    },
    {
      "codice": "082019",
      "nome": "Camporeale",
      "nomeStraniero": null,
      "codiceCatastale": "B556",
      "cap": "90043",
      "prefisso": "0924",
      "provincia": "Palermo",
      "email": "protocollo@comune.camporeale.pa.it",
      "pec": "protocollo.comunedicamporeale.pa@pec.it",
      "telefono": "0924 - 581210",
      "fax": null,
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "082020",
      "nome": "Capaci",
      "nomeStraniero": null,
      "codiceCatastale": "B645",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "elettorale@comune.capaci.pa.it",
      "pec": "elettorale.comunecapaci@pec.it",
      "telefono": "+39 0918673103-312",
      "fax": "+39 091 8696458",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "082021",
      "nome": "Carini",
      "nomeStraniero": null,
      "codiceCatastale": "B780",
      "cap": "90044",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "rip1.elettorale@comune.carini.pa.it",
      "pec": "protocollo@pec.comune.carini.pa.it",
      "telefono": "+39 0918611346",
      "fax": "+39 0918668336",
      "coordinate": {
        "lat": 38.13333511352539,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "082022",
      "nome": "Castelbuono",
      "nomeStraniero": null,
      "codiceCatastale": "C067",
      "cap": "90013",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "info@comune.castelbuono.pa.it",
      "pec": "comune.castelbuono@pec.it",
      "telefono": "+39 0921 671013",
      "fax": "+39 0921 671032",
      "coordinate": {
        "lat": 37.93055725097656,
        "lng": 14.088333129882812
      }
    },
    {
      "codice": "082023",
      "nome": "Casteldaccia",
      "nomeStraniero": null,
      "codiceCatastale": "C074",
      "cap": "90014",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe.casteldaccia@virgilio.it",
      "pec": "protocollo@pec.comune.casteldaccia.pa.it",
      "telefono": "+39 091949515",
      "fax": "+39 091942355",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "082024",
      "nome": "Castellana Sicula",
      "nomeStraniero": null,
      "codiceCatastale": "C135",
      "cap": "90020",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "ccappuzzo@comune.castellana-sicula.pa.it",
      "pec": "protocollo@pec.comune.castellana-sicula.pa.it",
      "telefono": "+39 0921558212",
      "fax": "+39 0921558218",
      "coordinate": {
        "lat": 37.78333282470703,
        "lng": 14.050000190734863
      }
    },
    {
      "codice": "082025",
      "nome": "Castronovo di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "C344",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafedicastronuovodis@virgilio.it",
      "pec": "protocollo@pec.comune.castronovodisicilia.pa.it",
      "telefono": "+39 091 8218852",
      "fax": "+39 091 8218837",
      "coordinate": {
        "lat": 37.66666793823242,
        "lng": 13.600000381469727
      }
    },
    {
      "codice": "082026",
      "nome": "Cefalà Diana",
      "nomeStraniero": null,
      "codiceCatastale": "C420",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "comunecefaladiana.demog@virgilio.it",
      "pec": "sindaco@pec.comune.cefaladiana.pa.it",
      "telefono": "+39 0918270028",
      "fax": "+39 0918291603",
      "coordinate": {
        "lat": 37.91666793823242,
        "lng": 13.466666221618652
      }
    },
    {
      "codice": "082027",
      "nome": "Cefalù",
      "nomeStraniero": null,
      "codiceCatastale": "C421",
      "cap": "90015",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "cie-elettorale@comune.cefalu.pa.it",
      "pec": "protocollo@pec.comune.cefalu.pa.it",
      "telefono": "+39 0921 924138",
      "fax": "+39 0921 421810",
      "coordinate": {
        "lat": 38.03948211669922,
        "lng": 14.022061347961426
      }
    },
    {
      "codice": "082028",
      "nome": "Cerda",
      "nomeStraniero": null,
      "codiceCatastale": "C496",
      "cap": "90010",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "demografici@comune.cerda.pa.it",
      "pec": "affarigenerali@pec.comune.cerda.pa.it",
      "telefono": "+39 0918999711",
      "fax": "+39 0918999773",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "082029",
      "nome": "Chiusa Sclafani",
      "nomeStraniero": null,
      "codiceCatastale": "C654",
      "cap": "90033",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "protocollo@comune.chiusasclafani.pa.it",
      "pec": "protocollo.chiusasclafani@pec.it",
      "telefono": "+39 091 8353243",
      "fax": "+39 091 8353494",
      "coordinate": {
        "lat": 37.68333435058594,
        "lng": 13.266666412353516
      }
    },
    {
      "codice": "082030",
      "nome": "Ciminna",
      "nomeStraniero": null,
      "codiceCatastale": "C696",
      "cap": "90023",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "servizidemografici@comune.ciminna.pa.it",
      "pec": "comune.ciminna@pec.halleyconsulting.it",
      "telefono": "+39 0918204220",
      "fax": "+39 0918293300",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 13.566666603088379
      }
    },
    {
      "codice": "082031",
      "nome": "Cinisi",
      "nomeStraniero": null,
      "codiceCatastale": "C708",
      "cap": "90045",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe@comune.cinisi.pa.it",
      "pec": "cinisi@sicurezzapostale.it",
      "telefono": "+39 0918610209",
      "fax": "+39 0918689519",
      "coordinate": {
        "lat": 38.16666793823242,
        "lng": 13.100000381469727
      }
    },
    {
      "codice": "082032",
      "nome": "Collesano",
      "nomeStraniero": null,
      "codiceCatastale": "C871",
      "cap": "90016",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "info@comune.collesano.pa.it",
      "pec": "protocollo.comunecollesano@pec.it",
      "telefono": "+39 0921.661158",
      "fax": "+39 0921 661205",
      "coordinate": {
        "lat": 37.91666793823242,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "082033",
      "nome": "Contessa Entellina",
      "nomeStraniero": null,
      "codiceCatastale": "C968",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "affari.generali@comune.contessaentellina.pa.it",
      "pec": "comunecontessaentellina@pecsicilia.it",
      "telefono": "+39 0918302152",
      "fax": "+39 0918355711",
      "coordinate": {
        "lat": 37.733333587646484,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "082034",
      "nome": "Corleone",
      "nomeStraniero": null,
      "codiceCatastale": "D009",
      "cap": "90034",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "ufficioelettoralecorleone@virgilio.it",
      "pec": "affariistituzionalicorleone@postecert.it",
      "telefono": "+39 09184524234",
      "fax": "+39 09184524223",
      "coordinate": {
        "lat": 37.81666564941406,
        "lng": 13.300000190734863
      }
    },
    {
      "codice": "082035",
      "nome": "Ficarazzi",
      "nomeStraniero": null,
      "codiceCatastale": "D567",
      "cap": "90010",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "maggiore@comune.ficarazzi.pa.it",
      "pec": "segreteriagenerale@cert.comune.ficarazzi.pa.it",
      "telefono": "+39 0916362742",
      "fax": "+39 0916362749",
      "coordinate": {
        "lat": 38.092288970947266,
        "lng": 13.463899612426758
      }
    },
    {
      "codice": "082036",
      "nome": "Gangi",
      "nomeStraniero": null,
      "codiceCatastale": "D907",
      "cap": "90024",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "servizi.demografici@comune.gangi.pa.it",
      "pec": "ufficioprotocollo@pec.comune.gangi.pa.it",
      "telefono": "+39 0921644015",
      "fax": "+39 0921644015",
      "coordinate": {
        "lat": 37.79999923706055,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "082037",
      "nome": "Geraci Siculo",
      "nomeStraniero": null,
      "codiceCatastale": "D977",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "comunegeracianagrafe@libero.it",
      "pec": "settoreamministrativo@pec.comune.geracisiculo.pa.it",
      "telefono": "+39 0921649080",
      "fax": "+39 0921643619",
      "coordinate": {
        "lat": 37.86666488647461,
        "lng": 14.149999618530273
      }
    },
    {
      "codice": "082038",
      "nome": "Giardinello",
      "nomeStraniero": null,
      "codiceCatastale": "E013",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "comunegiardinello@libero.it",
      "pec": "comunegiardinello@pec.it",
      "telefono": "+39 0918784003",
      "fax": "+39 0918984828",
      "coordinate": {
        "lat": 38.087059020996094,
        "lng": 13.156479835510254
      }
    },
    {
      "codice": "082039",
      "nome": "Giuliana",
      "nomeStraniero": null,
      "codiceCatastale": "E055",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "comunedigiuliana@alice.it",
      "pec": "comunedigiuliana@pec.it",
      "telefono": "0918356357",
      "fax": "0918356077",
      "coordinate": {
        "lat": 37.66666793823242,
        "lng": 13.233333587646484
      }
    },
    {
      "codice": "082040",
      "nome": "Godrano",
      "nomeStraniero": null,
      "codiceCatastale": "E074",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "servizisociali@comune.godrano.pa.it",
      "pec": null,
      "telefono": "+39 0918208034",
      "fax": "+39 0918208193",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 13.433333396911621
      }
    },
    {
      "codice": "082041",
      "nome": "Gratteri",
      "nomeStraniero": null,
      "codiceCatastale": "E149",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "anagrafegratteri@virgilio.it",
      "pec": "protocollo.comunegratteri@sicurezzapostale.it",
      "telefono": "+39 0921429214",
      "fax": "+39 0921429592",
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 13.966666221618652
      }
    },
    {
      "codice": "082042",
      "nome": "Isnello",
      "nomeStraniero": null,
      "codiceCatastale": "E337",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "comuneisnello@libero.it",
      "pec": "protocolloisnello@pec.it",
      "telefono": "+39 0921662032",
      "fax": "+39 0921662685",
      "coordinate": {
        "lat": 37.94404602050781,
        "lng": 14.007006645202637
      }
    },
    {
      "codice": "082043",
      "nome": "Isola delle Femmine",
      "nomeStraniero": null,
      "codiceCatastale": "E350",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "statocivile@comune.isoladellefemmine.pa.it",
      "pec": "anagrafe@pec.comune.isoladellefemmine.pa.it",
      "telefono": "+39 0918679245",
      "fax": null,
      "coordinate": {
        "lat": 38.20000076293945,
        "lng": 13.25
      }
    },
    {
      "codice": "082044",
      "nome": "Lascari",
      "nomeStraniero": null,
      "codiceCatastale": "E459",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "anagrafe@comune.lascari.pa.it",
      "pec": "comune@pec.lascari.gov.it",
      "telefono": "+39 0921427767",
      "fax": "+39 0921427767",
      "coordinate": {
        "lat": 38,
        "lng": 13.933333396911621
      }
    },
    {
      "codice": "082045",
      "nome": "Lercara Friddi",
      "nomeStraniero": null,
      "codiceCatastale": "E541",
      "cap": "90025",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "info@comune.lercarafriddi.pa.it",
      "pec": "comune.lercarafriddi@pec.it",
      "telefono": "+39 0918247111",
      "fax": "+39 091 8252643",
      "coordinate": {
        "lat": 37.75,
        "lng": 13.600000381469727
      }
    },
    {
      "codice": "082046",
      "nome": "Marineo",
      "nomeStraniero": null,
      "codiceCatastale": "E957",
      "cap": "90035",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "info@comune.marineo.pa.it",
      "pec": "comune@pec.comune.marineo.pa.it",
      "telefono": "+39 091/8725193",
      "fax": "+39 091/8727445",
      "coordinate": {
        "lat": 37.95000076293945,
        "lng": 13.416666984558105
      }
    },
    {
      "codice": "082047",
      "nome": "Mezzojuso",
      "nomeStraniero": null,
      "codiceCatastale": "F184",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "demograficimezzojuso@libero.it",
      "pec": "comunemezzojuso@pec.it",
      "telefono": "+39 0918203237",
      "fax": "+39 0918207242",
      "coordinate": {
        "lat": 37.86666488647461,
        "lng": 13.466666221618652
      }
    },
    {
      "codice": "082048",
      "nome": "Misilmeri",
      "nomeStraniero": null,
      "codiceCatastale": "F246",
      "cap": "90036",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "sf.romano@comune.misilmeri.pa.it",
      "pec": "elettorale@pec.comune.misilmeri.pa.it",
      "telefono": "+39 0918711300",
      "fax": "+39 0918733384",
      "coordinate": {
        "lat": 38.03333282470703,
        "lng": 13.449999809265137
      }
    },
    {
      "codice": "082049",
      "nome": "Monreale",
      "nomeStraniero": null,
      "codiceCatastale": "F377",
      "cap": "90046",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "gabinetto.sindaco@monreale.gov.it",
      "pec": "sindaco.monreale@pec.it",
      "telefono": "+39 0916564111",
      "fax": "+39 096407254",
      "coordinate": {
        "lat": 38.0816650390625,
        "lng": 13.288888931274414
      }
    },
    {
      "codice": "082050",
      "nome": "Montelepre",
      "nomeStraniero": null,
      "codiceCatastale": "F544",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe@montelepre.gov.it",
      "pec": "comune@pec.montelepre.gov.it",
      "telefono": "+39 091 8940111",
      "fax": "+39 091 8940416",
      "coordinate": {
        "lat": 38.099998474121094,
        "lng": 13.166666984558105
      }
    },
    {
      "codice": "082051",
      "nome": "Montemaggiore Belsito",
      "nomeStraniero": null,
      "codiceCatastale": "F553",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "servizio.affarigenerali@comunemontemaggiorebelsito.pa.it",
      "pec": "protocollo@pec.comune.montemaggiorebelsito.pa.it",
      "telefono": "+39 0918996211",
      "fax": "+39 0918993680",
      "coordinate": {
        "lat": 37.849998474121094,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "082052",
      "nome": "Palazzo Adriano",
      "nomeStraniero": null,
      "codiceCatastale": "G263",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "demografici@comune.palazzoadriano.pa.it",
      "pec": "protocollo@pec.comune.palazzoadriano.pa.it",
      "telefono": "+39 091 8349901",
      "fax": null,
      "coordinate": {
        "lat": 37.68149185180664,
        "lng": 13.37906265258789
      }
    },
    {
      "codice": "082053",
      "nome": "Palermo",
      "nomeStraniero": null,
      "codiceCatastale": "G273",
      "cap": "90144",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "g.morelli@comune.palermo.it",
      "pec": "protocollo@cert.comune.palermo.it",
      "telefono": "+39 091 7401111",
      "fax": "+39 091 7402206",
      "coordinate": {
        "lat": 38.115657806396484,
        "lng": 13.361262321472168
      }
    },
    {
      "codice": "082054",
      "nome": "Partinico",
      "nomeStraniero": null,
      "codiceCatastale": "G348",
      "cap": "90047",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "affarigeneraliepersonale@comune.partinico.pa.it",
      "pec": "sindaco@pec.comune.partinico.pa.it",
      "telefono": "+39 091 8913200",
      "fax": "+39 091 8781807",
      "coordinate": {
        "lat": 38.04999923706055,
        "lng": 13.116666793823242
      }
    },
    {
      "codice": "082055",
      "nome": "Petralia Soprana",
      "nomeStraniero": null,
      "codiceCatastale": "G510",
      "cap": "90026",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "protocollo@comune.petraliasoprana.pa.it",
      "pec": "protocollo.petraliasoprana@sicurezzapostale.it",
      "telefono": "+39 0921 684111",
      "fax": "+39 0921 684110",
      "coordinate": {
        "lat": 37.79999923706055,
        "lng": 14.100000381469727
      }
    },
    {
      "codice": "082056",
      "nome": "Petralia Sottana",
      "nomeStraniero": null,
      "codiceCatastale": "G511",
      "cap": "90027",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "luigi.lupinacci@comune.petraliasottana.pa.it",
      "pec": "comune.petraliasottana@anutel.it",
      "telefono": "+39 0921684318",
      "fax": "+39 0921680214",
      "coordinate": {
        "lat": 37.79999923706055,
        "lng": 14.083333015441895
      }
    },
    {
      "codice": "082057",
      "nome": "Piana degli Albanesi",
      "nomeStraniero": null,
      "codiceCatastale": "G543",
      "cap": "90037",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": null,
      "pec": "protocollo@pec.pianalbanesi.it",
      "telefono": "+39 0918574144",
      "fax": null,
      "coordinate": {
        "lat": 38,
        "lng": 13.283333778381348
      }
    },
    {
      "codice": "082058",
      "nome": "Polizzi Generosa",
      "nomeStraniero": null,
      "codiceCatastale": "G792",
      "cap": "90028",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "demospolizzi@tiscali.it",
      "pec": "demografici.polizzi@pec.it",
      "telefono": "+39 0921551607",
      "fax": "+39 0921688136",
      "coordinate": {
        "lat": 37.81194305419922,
        "lng": 13.999166488647461
      }
    },
    {
      "codice": "082059",
      "nome": "Pollina",
      "nomeStraniero": null,
      "codiceCatastale": "G797",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "servizidemografici@comune.pollina.pa.it",
      "pec": "anagrafepollina@pec.it",
      "telefono": "+39 0921425009",
      "fax": "+39 0921425426",
      "coordinate": {
        "lat": 38,
        "lng": 14.149999618530273
      }
    },
    {
      "codice": "082060",
      "nome": "Prizzi",
      "nomeStraniero": null,
      "codiceCatastale": "H070",
      "cap": "90038",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "protocollo@comunediprizzi.gov.it",
      "pec": "comunediprizzi.protocollo@certificata.com",
      "telefono": "+39 091 8344611",
      "fax": "+39 091 8344630",
      "coordinate": {
        "lat": 37.72152328491211,
        "lng": 13.431163787841797
      }
    },
    {
      "codice": "082061",
      "nome": "Roccamena",
      "nomeStraniero": null,
      "codiceCatastale": "H422",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe@comunediroccamena.it",
      "pec": "comune.roccamena@anutel.it",
      "telefono": "+39 0918469011",
      "fax": "+39 0918469027",
      "coordinate": {
        "lat": 37.83527755737305,
        "lng": 13.155555725097656
      }
    },
    {
      "codice": "082062",
      "nome": "Roccapalumba",
      "nomeStraniero": null,
      "codiceCatastale": "H428",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "protocollo@comune.roccapalumba.pa.it",
      "pec": "protocollo@pec.comune.roccapalumba.pa.it",
      "telefono": "+39 091 8215523",
      "fax": "+39 091 8215153",
      "coordinate": {
        "lat": 37.79999923706055,
        "lng": 13.633333206176758
      }
    },
    {
      "codice": "082063",
      "nome": "San Cipirello",
      "nomeStraniero": null,
      "codiceCatastale": "H797",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "demografico@comune.sancipirello.pa.it",
      "pec": "protocollo@pec.comune.sancipirello.pa.it",
      "telefono": "+39 0918581008",
      "fax": "+39 0918572305",
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "082064",
      "nome": "San Giuseppe Jato",
      "nomeStraniero": null,
      "codiceCatastale": "H933",
      "cap": "90048",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "anagrafe@comune.sangiuseppejato.pa.it",
      "pec": "comunesangiuseppejato@pec.it",
      "telefono": "+39 0918580263",
      "fax": null,
      "coordinate": {
        "lat": 37.96666717529297,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "082065",
      "nome": "San Mauro Castelverde",
      "nomeStraniero": null,
      "codiceCatastale": "I028",
      "cap": "90010",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "comune@comune.sanmaurocastelverde.pa.it",
      "pec": "comune.sanmaurocastelverde.pa@pec.it",
      "telefono": "+39 0921 674083",
      "fax": "+39 0921 674386",
      "coordinate": {
        "lat": 37.91444396972656,
        "lng": 14.190555572509766
      }
    },
    {
      "codice": "082066",
      "nome": "Santa Cristina Gela",
      "nomeStraniero": null,
      "codiceCatastale": "I174",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "Protocollo@comunesantacristinagela.pa.it",
      "pec": "santa_cristina_gela@pec.it",
      "telefono": "+39 0918570248",
      "fax": "+39 0918570502",
      "coordinate": {
        "lat": 37.9847526550293,
        "lng": 13.328062057495117
      }
    },
    {
      "codice": "082067",
      "nome": "Santa Flavia",
      "nomeStraniero": null,
      "codiceCatastale": "I188",
      "cap": "90017",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "uffdemogr@comune.santaflavia.pa.it",
      "pec": "protr@pec.comune.santaflavia.pa.it",
      "telefono": "091906902",
      "fax": "091906958",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "082068",
      "nome": "Sciara",
      "nomeStraniero": null,
      "codiceCatastale": "I534",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "ufficioanagrafe@comunesciara.it",
      "pec": "protocollo@pec.comune.sciara.pa.it",
      "telefono": "+39 0918994005",
      "fax": "+39 0918994475",
      "coordinate": {
        "lat": 37.91666793823242,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "082081",
      "nome": "Scillato",
      "nomeStraniero": null,
      "codiceCatastale": "I538",
      "cap": "90020",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "anagrafe@comunescillato.pa.it",
      "pec": "protocollo.scillato@pec.it",
      "telefono": "+39 092166025",
      "fax": "+39 0921663196",
      "coordinate": {
        "lat": 37.849998474121094,
        "lng": 13.899999618530273
      }
    },
    {
      "codice": "082069",
      "nome": "Sclafani Bagni",
      "nomeStraniero": null,
      "codiceCatastale": "I541",
      "cap": "90020",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "info@comune.sclafani-bagni.pa.it",
      "pec": "protocollo.comune.sclafanibagni.pa@pec.it",
      "telefono": "+39 0921541097",
      "fax": "+39 0921547054",
      "coordinate": {
        "lat": 37.81666564941406,
        "lng": 13.850000381469727
      }
    },
    {
      "codice": "082070",
      "nome": "Termini Imerese",
      "nomeStraniero": null,
      "codiceCatastale": "L112",
      "cap": "90018",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "protocollo@comune.termini-imerese.pa.it",
      "pec": "protocollo@pec.comuneterminiimerese.pa.it",
      "telefono": "+39 091 8128111",
      "fax": "+39 091 8128476",
      "coordinate": {
        "lat": 37.983333587646484,
        "lng": 13.699999809265137
      }
    },
    {
      "codice": "082071",
      "nome": "Terrasini",
      "nomeStraniero": null,
      "codiceCatastale": "L131",
      "cap": "90049",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "sindaco@comune.terrasini.pa.it",
      "pec": "protocolloterrasini@pec.it",
      "telefono": "+39 0918619000",
      "fax": null,
      "coordinate": {
        "lat": 38.150001525878906,
        "lng": 13.083333015441895
      }
    },
    {
      "codice": "082072",
      "nome": "Torretta",
      "nomeStraniero": null,
      "codiceCatastale": "L282",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "servizidemo.torretta@libero.it",
      "pec": "servizidemografici@pec.comune.torretta.pa.it",
      "telefono": "+39 0918670231",
      "fax": "+39 0918670764",
      "coordinate": {
        "lat": 38.1303596496582,
        "lng": 13.235489845275879
      }
    },
    {
      "codice": "082073",
      "nome": "Trabia",
      "nomeStraniero": null,
      "codiceCatastale": "L317",
      "cap": "90019",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "comuneditrabia@libero.it",
      "pec": "protocollo@pec.comunetrabia.gov.it",
      "telefono": "+39 0918146035",
      "fax": "+39 0918124345",
      "coordinate": {
        "lat": 38,
        "lng": 13.649999618530273
      }
    },
    {
      "codice": "082074",
      "nome": "Trappeto",
      "nomeStraniero": null,
      "codiceCatastale": "L332",
      "cap": "90040",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "trappeto@libero.it",
      "pec": "comunetrappeto@pec.it",
      "telefono": "+39 091/8788341",
      "fax": "+39 091/8978009",
      "coordinate": {
        "lat": 38.06666564941406,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "082075",
      "nome": "Ustica",
      "nomeStraniero": null,
      "codiceCatastale": "L519",
      "cap": "90051",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "info@comune.ustica.pa.it",
      "pec": "comune@pec.comune.ustica.pa.it",
      "telefono": "+39 091 8449237",
      "fax": "+39 091 8449194",
      "coordinate": {
        "lat": 38.71666717529297,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "082076",
      "nome": "Valledolmo",
      "nomeStraniero": null,
      "codiceCatastale": "L603",
      "cap": "90029",
      "prefisso": "0921",
      "provincia": "Palermo",
      "email": "protocollo@comune.valledolmo.pa.it",
      "pec": "protocollo@pec.comune.valledolmo.pa.it",
      "telefono": "+39 0921544300",
      "fax": "+39 0921544340",
      "coordinate": {
        "lat": 37.75,
        "lng": 13.833333015441895
      }
    },
    {
      "codice": "082077",
      "nome": "Ventimiglia di Sicilia",
      "nomeStraniero": null,
      "codiceCatastale": "L740",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "urp@comune.ventimigliadisicilia.pa.it",
      "pec": "urp@pec.comune.ventimigliadisicilia.pa.it",
      "telefono": "+39 091.8209242",
      "fax": "+39 091.8209311",
      "coordinate": {
        "lat": 37.91666793823242,
        "lng": 13.566666603088379
      }
    },
    {
      "codice": "082078",
      "nome": "Vicari",
      "nomeStraniero": null,
      "codiceCatastale": "L837",
      "cap": "90020",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "info@comune.vicari.pa.it",
      "pec": "comunevicari@pec.it",
      "telefono": "+39 0918216061",
      "fax": "+39 0918216090",
      "coordinate": {
        "lat": 37.83333206176758,
        "lng": 13.566666603088379
      }
    },
    {
      "codice": "082079",
      "nome": "Villabate",
      "nomeStraniero": null,
      "codiceCatastale": "L916",
      "cap": "90039",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "skyleo@tin.it",
      "pec": "affarigenerali.villabate@pec.it",
      "telefono": "+39 0916143522",
      "fax": "+39 0916143860",
      "coordinate": {
        "lat": 38.08333206176758,
        "lng": 13.449999809265137
      }
    },
    {
      "codice": "082080",
      "nome": "Villafrati",
      "nomeStraniero": null,
      "codiceCatastale": "L951",
      "cap": "90030",
      "prefisso": "091",
      "provincia": "Palermo",
      "email": "uff.elettorale@comune.villafrati.pa.it",
      "pec": "protocollo@pec.comune.villafrati.pa.it",
      "telefono": "+39 0918291833",
      "fax": "+39 0918201258",
      "coordinate": {
        "lat": 37.900001525878906,
        "lng": 13.483333587646484
      }
    }
  ],
  "Valle d'Aosta": [
    {
      "codice": "007001",
      "nome": "Allein",
      "nomeStraniero": null,
      "codiceCatastale": "A205",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.allein.ao.it",
      "pec": "protocollo@pec.comune.allein.ao.it",
      "telefono": "+39 016578266",
      "fax": "+39 016578484",
      "coordinate": {
        "lat": 45.80833435058594,
        "lng": 7.272500038146973
      }
    },
    {
      "codice": "007002",
      "nome": "Antey-Saint-André",
      "nomeStraniero": null,
      "codiceCatastale": "A305",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.antey-st-andre.ao.it",
      "pec": "protocollo@pec.comune.antey-st-andre.ao.it",
      "telefono": "+39 0166-548209",
      "fax": "+39 0166-548577",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 7.599999904632568
      }
    },
    {
      "codice": "007003",
      "nome": "Aosta",
      "nomeStraniero": null,
      "codiceCatastale": "A326",
      "cap": "11100",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "protocollo@comune.aosta.it",
      "pec": "protocollo@pec.comune.aosta.it",
      "telefono": "+39 01653001",
      "fax": "+39 016545603",
      "coordinate": {
        "lat": 45.737220764160156,
        "lng": 7.320555686950684
      }
    },
    {
      "codice": "007004",
      "nome": "Arnad",
      "nomeStraniero": null,
      "codiceCatastale": "A424",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.arnad.ao.it",
      "pec": "protocollo@pec.comune.arnad.ao.it",
      "telefono": "+39 0125/966121",
      "fax": "+39 0125 966306",
      "coordinate": {
        "lat": 45.6442985534668,
        "lng": 7.7221999168396
      }
    },
    {
      "codice": "007005",
      "nome": "Arvier",
      "nomeStraniero": null,
      "codiceCatastale": "A452",
      "cap": "11011",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "comune@comune.arvier.ao.it",
      "pec": "protocollo@pec.comune.arvier.ao.it",
      "telefono": "+39 0165 929001",
      "fax": "+39 0165 929003",
      "coordinate": {
        "lat": 45.70305633544922,
        "lng": 7.166666507720947
      }
    },
    {
      "codice": "007006",
      "nome": "Avise",
      "nomeStraniero": null,
      "codiceCatastale": "A521",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.avise.ao.it",
      "pec": "protocollo@pec.comune.avise.ao.it",
      "telefono": "+39 0165/91113",
      "fax": "+39 0165/91233",
      "coordinate": {
        "lat": 45.70869827270508,
        "lng": 7.140500068664551
      }
    },
    {
      "codice": "007007",
      "nome": "Ayas",
      "nomeStraniero": null,
      "codiceCatastale": "A094",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.ayas.ao.it",
      "pec": "protocollo@pec.comune.ayas.ao.it",
      "telefono": "+39 0125 306632",
      "fax": "+39 0125 306788",
      "coordinate": {
        "lat": 45.81132125854492,
        "lng": 7.686649799346924
      }
    },
    {
      "codice": "007008",
      "nome": "Aymavilles",
      "nomeStraniero": null,
      "codiceCatastale": "A108",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.aymavilles.ao.it",
      "pec": "protocollo@pec.comune.aymavilles.ao.it",
      "telefono": "+39 0165922819",
      "fax": "+39 0165922821",
      "coordinate": {
        "lat": 45.70119857788086,
        "lng": 7.240300178527832
      }
    },
    {
      "codice": "007009",
      "nome": "Bard",
      "nomeStraniero": null,
      "codiceCatastale": "A643",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.bard.ao.it",
      "pec": "protocollo@pec.comune.bard.ao.it",
      "telefono": "+39 0125803134",
      "fax": "+39 0125803603",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 7.75
      }
    },
    {
      "codice": "007010",
      "nome": "Bionaz",
      "nomeStraniero": null,
      "codiceCatastale": "A877",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "segreteria@comune.bionaz.ao.it",
      "pec": "protocollo@pec.comune.bionaz.ao.it",
      "telefono": "+39 0165/730106",
      "fax": "+39 0165/730107",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 7.416666507720947
      }
    },
    {
      "codice": "007011",
      "nome": "Brissogne",
      "nomeStraniero": null,
      "codiceCatastale": "B192",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.brissogne.ao.it",
      "pec": "protocollo@pec.comune.brissogne.ao.it",
      "telefono": "+39 0165/762611",
      "fax": "+39 0165/762638",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 7.400000095367432
      }
    },
    {
      "codice": "007012",
      "nome": "Brusson",
      "nomeStraniero": null,
      "codiceCatastale": "B230",
      "cap": "11022",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.brusson.ao.it",
      "pec": "protocollo@pec.comune.brusson.ao.it",
      "telefono": "+39 0125/300132",
      "fax": "+39 0125/300647",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "007013",
      "nome": "Challand-Saint-Anselme",
      "nomeStraniero": null,
      "codiceCatastale": "C593",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.challand-st-anselme.ao.it",
      "pec": "protocollo@pec.comune.challand-st-anselme.ao.it",
      "telefono": "+39 0125/965214",
      "fax": "+39 0125/965566",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "007014",
      "nome": "Challand-Saint-Victor",
      "nomeStraniero": null,
      "codiceCatastale": "C594",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "segreteria@comune.challand-st-victor.ao.it",
      "pec": "protocollo@pec.comune.challand-st-victor.ao.it",
      "telefono": "+39 0125/945001",
      "fax": "+39 0125/945907",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 7.699999809265137
      }
    },
    {
      "codice": "007015",
      "nome": "Chambave",
      "nomeStraniero": null,
      "codiceCatastale": "C595",
      "cap": "11023",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.chambave.ao.it",
      "pec": "protocollo@pec.comune.chambave.ao.it",
      "telefono": "+39 0166/46106",
      "fax": "+39 0166/46491",
      "coordinate": {
        "lat": 45.74452590942383,
        "lng": 7.549853801727295
      }
    },
    {
      "codice": "007016",
      "nome": "Chamois",
      "nomeStraniero": null,
      "codiceCatastale": "B491",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.chamois.ao.it",
      "pec": "protocollo@pec.comune.chamois.ao.it",
      "telefono": "+39 0166 47134",
      "fax": "+39 0166 47151",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "007017",
      "nome": "Champdepraz",
      "nomeStraniero": null,
      "codiceCatastale": "C596",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.champdepraz.ao.it",
      "pec": "protocollo@pec.comune.champdepraz.ao.it",
      "telefono": "+39 0125 960437",
      "fax": null,
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 7.650000095367432
      }
    },
    {
      "codice": "007018",
      "nome": "Champorcher",
      "nomeStraniero": null,
      "codiceCatastale": "B540",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.champorcher.ao.it",
      "pec": "protocollo@pec.comune.champorcher.ao.it",
      "telefono": "+39 0125/37106",
      "fax": "+39 0125/37278",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "007019",
      "nome": "Charvensod",
      "nomeStraniero": null,
      "codiceCatastale": "C598",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.charvensod.ao.it",
      "pec": "protocollo@pec.comune.charvensod.ao.it",
      "telefono": "+39 0165/279711",
      "fax": "+39 0165/279771",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "007020",
      "nome": "Châtillon",
      "nomeStraniero": null,
      "codiceCatastale": "C294",
      "cap": "11024",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.chatillon.ao.it",
      "pec": "protocollo@pec.comune.chatillon.ao.it",
      "telefono": "+39 0166 560611",
      "fax": "+39 0166 560647",
      "coordinate": {
        "lat": 45.75,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "007021",
      "nome": "Cogne",
      "nomeStraniero": null,
      "codiceCatastale": "C821",
      "cap": "11012",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.cogne.ao.it",
      "pec": "protocollo@pec.comune.cogne.ao.it",
      "telefono": "+39 0165753831",
      "fax": "+39 0165753821",
      "coordinate": {
        "lat": 45.608741760253906,
        "lng": 7.356022834777832
      }
    },
    {
      "codice": "007022",
      "nome": "Courmayeur",
      "nomeStraniero": null,
      "codiceCatastale": "D012",
      "cap": "11013",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.courmayeur.ao.it",
      "pec": "protocollo@pec.comune.courmayeur.ao.it",
      "telefono": "+39 0165/831311",
      "fax": "+39 0165/831312",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 6.9666666984558105
      }
    },
    {
      "codice": "007023",
      "nome": "Donnas",
      "nomeStraniero": null,
      "codiceCatastale": "D338",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.donnas.ao.it",
      "pec": "protocollo@pec.comune.donnas.ao.it",
      "telefono": "+39 0125807051",
      "fax": "+39 0125/805000",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 7.766666889190674
      }
    },
    {
      "codice": "007024",
      "nome": "Doues",
      "nomeStraniero": null,
      "codiceCatastale": "D356",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.doues.ao.it",
      "pec": "protocollo@pec.comune.doues.ao.it",
      "telefono": "+39 0165/738042",
      "fax": "+39 0165/738137",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 7.300000190734863
      }
    },
    {
      "codice": "007025",
      "nome": "Emarèse",
      "nomeStraniero": null,
      "codiceCatastale": "D402",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.emarese.ao.it",
      "pec": "protocollo@pec.comune.emarese.ao.it",
      "telefono": "+39 0166/519103",
      "fax": "+39 0166/519128",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 7.699999809265137
      }
    },
    {
      "codice": "007026",
      "nome": "Etroubles",
      "nomeStraniero": null,
      "codiceCatastale": "D444",
      "cap": "11014",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.etroubles.ao.i",
      "pec": "protocollo@pec.comune.etroubles.ao.it",
      "telefono": "+39 0165/789101",
      "fax": "+39 0165/789102",
      "coordinate": {
        "lat": 45.82149887084961,
        "lng": 7.230400085449219
      }
    },
    {
      "codice": "007027",
      "nome": "Fénis",
      "nomeStraniero": null,
      "codiceCatastale": "D537",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.fenis.ao.it",
      "pec": "protocollo@pec.comune.fenis.ao.it",
      "telefono": "+39 0165 764635",
      "fax": "+39 0165 763954",
      "coordinate": {
        "lat": 45.73601150512695,
        "lng": 7.494370460510254
      }
    },
    {
      "codice": "007028",
      "nome": "Fontainemore",
      "nomeStraniero": null,
      "codiceCatastale": "D666",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.fontainemore.ao.it",
      "pec": "protocollo@pec.comune.fontainemore.ao.it",
      "telefono": "+39 0125 832121",
      "fax": "+39 0125 832166",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 7.866666793823242
      }
    },
    {
      "codice": "007029",
      "nome": "Gaby",
      "nomeStraniero": null,
      "codiceCatastale": "D839",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.gaby.ao.it",
      "pec": "protocollo@pec.comune.gaby.ao.it",
      "telefono": "+39 0125345932",
      "fax": "+39 0125345078",
      "coordinate": {
        "lat": 45.704444885253906,
        "lng": 7.8838887214660645
      }
    },
    {
      "codice": "007030",
      "nome": "Gignod",
      "nomeStraniero": null,
      "codiceCatastale": "E029",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.gignod.ao.it",
      "pec": "protocollo@pec.comune.gignod.ao.it",
      "telefono": "+39 01652562100",
      "fax": "+39 01652562118",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 7.300000190734863
      }
    },
    {
      "codice": "007031",
      "nome": "Gressan",
      "nomeStraniero": null,
      "codiceCatastale": "E165",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "amministrazione@comune.gressan.ao.it",
      "pec": "protocollo@pec.comune.gressan.ao.it",
      "telefono": "+39 0165/250113",
      "fax": "+39 0165/250918",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 7.2833333015441895
      }
    },
    {
      "codice": "007032",
      "nome": "Gressoney-La-Trinité",
      "nomeStraniero": null,
      "codiceCatastale": "E167",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.gressoneylatrinite.ao.it",
      "pec": "protocollo@pec.comune.gressoneylatrinite.ao.it",
      "telefono": "+39 0125/366137",
      "fax": null,
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 7.833333492279053
      }
    },
    {
      "codice": "007033",
      "nome": "Gressoney-Saint-Jean",
      "nomeStraniero": null,
      "codiceCatastale": "E168",
      "cap": "11025",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.gressoneystjean.ao.it",
      "pec": "protocollo@pec.comune.gressoneystjean.ao.it",
      "telefono": "+39 0125/355192",
      "fax": "+39 0125/355673",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "007034",
      "nome": "Hône",
      "nomeStraniero": null,
      "codiceCatastale": "E273",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.hone.ao.it",
      "pec": "protocollo@pec.comune.hone.ao.it",
      "telefono": "+39 0125.803132",
      "fax": "+39 0125.803038",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 7.733333110809326
      }
    },
    {
      "codice": "007035",
      "nome": "Introd",
      "nomeStraniero": null,
      "codiceCatastale": "E306",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.introd.ao.it",
      "pec": "protocollo@pec.comune.introd.ao.it",
      "telefono": "+39 0165/900052",
      "fax": "+39 0165/900015",
      "coordinate": {
        "lat": 45.68996810913086,
        "lng": 7.1876220703125
      }
    },
    {
      "codice": "007036",
      "nome": "Issime",
      "nomeStraniero": null,
      "codiceCatastale": "E369",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.issime.ao.it",
      "pec": "protocollo@pec.comune.issime.ao.it",
      "telefono": "+39 0125344033",
      "fax": "+39 0125344116",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 7.849999904632568
      }
    },
    {
      "codice": "007037",
      "nome": "Issogne",
      "nomeStraniero": null,
      "codiceCatastale": "E371",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.issogne.ao.it",
      "pec": "protocollo@pec.comune.issogne.ao.it",
      "telefono": "+39 0125929332",
      "fax": "+39 0125920621",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 7.683333396911621
      }
    },
    {
      "codice": "007038",
      "nome": "Jovençan",
      "nomeStraniero": null,
      "codiceCatastale": "E391",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.jovencan.ao.it",
      "pec": "protocollo@pec.comune.jovencan.ao.it",
      "telefono": "+39 0165/250101",
      "fax": "+39 0165/250925",
      "coordinate": {
        "lat": 45.71555709838867,
        "lng": 7.273611068725586
      }
    },
    {
      "codice": "007039",
      "nome": "La Magdeleine",
      "nomeStraniero": null,
      "codiceCatastale": "A308",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "sindaco@comune.la-magdeleine.ao.it",
      "pec": "protocollo@pec.comune.la-magdeleine.ao.it",
      "telefono": "+39 0166/548274",
      "fax": "+39 0166/548989",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "007040",
      "nome": "La Salle",
      "nomeStraniero": null,
      "codiceCatastale": "E458",
      "cap": "11015",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.lasalle.ao.it",
      "pec": "protocollo@pec.comune.lasalle.ao.it",
      "telefono": "+39 0165861908",
      "fax": "+39 0165861676",
      "coordinate": {
        "lat": 45.746917724609375,
        "lng": 7.072666645050049
      }
    },
    {
      "codice": "007041",
      "nome": "La Thuile",
      "nomeStraniero": null,
      "codiceCatastale": "E470",
      "cap": "11016",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "o.oca@comune.la-thuile.ao.it",
      "pec": "protocollo@pec.comune.la-thuile.ao.it",
      "telefono": "+39 0165884108",
      "fax": "+39 0165/884641",
      "coordinate": {
        "lat": 45.715999603271484,
        "lng": 6.949999809265137
      }
    },
    {
      "codice": "007042",
      "nome": "Lillianes",
      "nomeStraniero": null,
      "codiceCatastale": "E587",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.lillianes.ao.it",
      "pec": "protocollo@pec.comune.lillianes.ao.it",
      "telefono": "+39 0125832110",
      "fax": "+39 0125832219",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 7.849999904632568
      }
    },
    {
      "codice": "007043",
      "nome": "Montjovet",
      "nomeStraniero": null,
      "codiceCatastale": "F367",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.montjovet.ao.it",
      "pec": "protocollo@pec.comune.montjovet.ao.it",
      "telefono": "+39 016679131",
      "fax": null,
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 7.683333396911621
      }
    },
    {
      "codice": "007044",
      "nome": "Morgex",
      "nomeStraniero": null,
      "codiceCatastale": "F726",
      "cap": "11017",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.morgex.ao.it",
      "pec": "protocollo@pec.comune.morgex.ao.it",
      "telefono": "+39 0165801711",
      "fax": "+39 0165801740",
      "coordinate": {
        "lat": 45.75,
        "lng": 7.0333333015441895
      }
    },
    {
      "codice": "007045",
      "nome": "Nus",
      "nomeStraniero": null,
      "codiceCatastale": "F987",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.nus.ao.it",
      "pec": "protocollo@pec.comune.nus.ao.it",
      "telefono": "+39 0165763763",
      "fax": "+39 0165763719",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 7.4666666984558105
      }
    },
    {
      "codice": "007046",
      "nome": "Ollomont",
      "nomeStraniero": null,
      "codiceCatastale": "G045",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "segreteria@comune.ollomont.ao.it",
      "pec": "protocollo@pec.comune.ollomont.ao.it",
      "telefono": "+39 0165/73243",
      "fax": "+39 0165/73343",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "007047",
      "nome": "Oyace",
      "nomeStraniero": null,
      "codiceCatastale": "G012",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.oyace.ao.it",
      "pec": "protocollo@pec.comune.oyace.ao.it",
      "telefono": "+39 0165/730013",
      "fax": "+39 0165/730914",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 7.383333206176758
      }
    },
    {
      "codice": "007048",
      "nome": "Perloz",
      "nomeStraniero": null,
      "codiceCatastale": "G459",
      "cap": "11002",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.perloz.ao.it",
      "pec": "protocollo@pec.comune.perloz.ao.it",
      "telefono": "+39 0125 807974",
      "fax": "+39 0125 806269",
      "coordinate": {
        "lat": 45.61666488647461,
        "lng": 7.816666603088379
      }
    },
    {
      "codice": "007049",
      "nome": "Pollein",
      "nomeStraniero": null,
      "codiceCatastale": "G794",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.pollein.ao.it",
      "pec": "protocollo@pec.comune.pollein.ao.it",
      "telefono": "+39 0165254911",
      "fax": "+39 0165/53476",
      "coordinate": {
        "lat": 45.72800064086914,
        "lng": 7.35699987411499
      }
    },
    {
      "codice": "007052",
      "nome": "Pont-Saint-Martin",
      "nomeStraniero": null,
      "codiceCatastale": "G854",
      "cap": "11026",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "ne.cerise@comune.pontsaintmartin.ao.it",
      "pec": "protocollo@pec.comune.pontsainmartin.ao.it",
      "telefono": "+39 0125830613",
      "fax": "+39 0125830642",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 7.800000190734863
      }
    },
    {
      "codice": "007050",
      "nome": "Pontboset",
      "nomeStraniero": null,
      "codiceCatastale": "G545",
      "cap": "11020",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.pontboset.ao.it",
      "pec": "protocollo@pec.comune.pontboset.ao.it",
      "telefono": "0125 806912",
      "fax": null,
      "coordinate": {
        "lat": 45.60761260986328,
        "lng": 7.6863789558410645
      }
    },
    {
      "codice": "007051",
      "nome": "Pontey",
      "nomeStraniero": null,
      "codiceCatastale": "G860",
      "cap": "11024",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "segreteria@comune.pontey.ao.it",
      "pec": "protocollo@pec.comune.pontey.ao.it",
      "telefono": "+39 0166 30131",
      "fax": "+39 0166 30317",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 7.583333492279053
      }
    },
    {
      "codice": "007053",
      "nome": "Pré-Saint-Didier",
      "nomeStraniero": null,
      "codiceCatastale": "H042",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.pre-saint-didier.ao.it",
      "pec": "protocollo@pec.comune.pre-saint-didier.ao.it",
      "telefono": "+39 0165/868711",
      "fax": "+39 0165/87612",
      "coordinate": {
        "lat": 45.76388931274414,
        "lng": 6.985833168029785
      }
    },
    {
      "codice": "007054",
      "nome": "Quart",
      "nomeStraniero": null,
      "codiceCatastale": "H110",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.quart.ao.it",
      "pec": "protocollo@pec.comune.quart.ao.it",
      "telefono": "+39 0165761803",
      "fax": "+39 0165762628",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 7.416666507720947
      }
    },
    {
      "codice": "007055",
      "nome": "Rhêmes-Notre-Dame",
      "nomeStraniero": null,
      "codiceCatastale": "H262",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.rhemes-notre-dame.ao.it",
      "pec": "protocollo@pec.comune.rhemes-notre-dame.ao.it",
      "telefono": "+39 0165.936114",
      "fax": "+39 0165.936162",
      "coordinate": {
        "lat": 45.56964874267578,
        "lng": 7.11870002746582
      }
    },
    {
      "codice": "007056",
      "nome": "Rhêmes-Saint-Georges",
      "nomeStraniero": null,
      "codiceCatastale": "H263",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.rhemes-st-georges.ao.it",
      "pec": "protocollo@pec.comune.rhemes-st-georges.ao.it",
      "telefono": "+39 0165 907634",
      "fax": "+39 0165 907664",
      "coordinate": {
        "lat": 45.654170989990234,
        "lng": 7.155338287353516
      }
    },
    {
      "codice": "007057",
      "nome": "Roisan",
      "nomeStraniero": null,
      "codiceCatastale": "H497",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "anagrafe@comune.roisan.ao.it",
      "pec": "protocollo@pec.comune.roisan.ao.it",
      "telefono": "+39 0165 50050",
      "fax": "+39 0165 50028",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 7.316666603088379
      }
    },
    {
      "codice": "007058",
      "nome": "Saint-Christophe",
      "nomeStraniero": null,
      "codiceCatastale": "H669",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saint-christophe.ao.it",
      "pec": "protocollo@pec.comune.saint-christophe.ao.it",
      "telefono": "+39 0165 269800",
      "fax": "+39 0165 269836",
      "coordinate": {
        "lat": 45.75,
        "lng": 7.349999904632568
      }
    },
    {
      "codice": "007059",
      "nome": "Saint-Denis",
      "nomeStraniero": null,
      "codiceCatastale": "H670",
      "cap": "11023",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saintdenis.ao.it",
      "pec": "protocollo@pec.comune.saintdenis.ao.it",
      "telefono": "+39 0166/546055",
      "fax": "+39 0166/546044",
      "coordinate": {
        "lat": 45.75,
        "lng": 7.550000190734863
      }
    },
    {
      "codice": "007060",
      "nome": "Saint-Marcel",
      "nomeStraniero": null,
      "codiceCatastale": "H671",
      "cap": "11020",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saintmarcel.ao.it",
      "pec": "protocollo@pec.comune.saintmarcel.ao.it",
      "telefono": "+39 0165 768801",
      "fax": "+39 0165 768771",
      "coordinate": {
        "lat": 45.73146057128906,
        "lng": 7.448200225830078
      }
    },
    {
      "codice": "007061",
      "nome": "Saint-Nicolas",
      "nomeStraniero": null,
      "codiceCatastale": "H672",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saint-nicolas.ao.it",
      "pec": "protocollo@pec.comune.saint-nicolas.ao.it",
      "telefono": "+39 0165/908814",
      "fax": "+39 0165/908932",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 7.1666669845581055
      }
    },
    {
      "codice": "007062",
      "nome": "Saint-Oyen",
      "nomeStraniero": null,
      "codiceCatastale": "H673",
      "cap": "11014",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "a.desandre@comune.saintoyen.ao.it",
      "pec": "protocollo@pec.comune.saintoyen.ao.it",
      "telefono": "+39 016578522-78523",
      "fax": "+39 0165/78478",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 7.2166666984558105
      }
    },
    {
      "codice": "007063",
      "nome": "Saint-Pierre",
      "nomeStraniero": null,
      "codiceCatastale": "H674",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saint-pierre.ao.it",
      "pec": "protocollo@pec.comune.saint-pierre.ao.it",
      "telefono": "+39 0165 927800",
      "fax": "+39 0165 927821",
      "coordinate": {
        "lat": 45.709999084472656,
        "lng": 7.22599983215332
      }
    },
    {
      "codice": "007064",
      "nome": "Saint-Rhémy-en-Bosses",
      "nomeStraniero": null,
      "codiceCatastale": "H675",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saintrhemyenbosses.ao.it",
      "pec": "protocollo@pec.comune.saintrhemyenbosses.ao.it",
      "telefono": "+39 0165/780821",
      "fax": "+39 0165/780034",
      "coordinate": {
        "lat": 45.82374572753906,
        "lng": 7.182188987731934
      }
    },
    {
      "codice": "007065",
      "nome": "Saint-Vincent",
      "nomeStraniero": null,
      "codiceCatastale": "H676",
      "cap": "11027",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.saint-vincent.ao.it",
      "pec": "protocollo@pec.comune.saint-vincent.ao.it",
      "telefono": "+39 0166/525111",
      "fax": "+39 0166/525101",
      "coordinate": {
        "lat": 45.75,
        "lng": 7.650000095367432
      }
    },
    {
      "codice": "007066",
      "nome": "Sarre",
      "nomeStraniero": null,
      "codiceCatastale": "I442",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.sarre.ao.it",
      "pec": "protocollo@pec.comune.sarre.ao.it",
      "telefono": "+39 0165/215611",
      "fax": "+39 0165/215656",
      "coordinate": {
        "lat": 45.717803955078125,
        "lng": 7.257500171661377
      }
    },
    {
      "codice": "007067",
      "nome": "Torgnon",
      "nomeStraniero": null,
      "codiceCatastale": "L217",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "segreteria@comune.torgnon.ao.it",
      "pec": "protocollo@pec.comune.torgnon.ao.it",
      "telefono": "+39 0166 540213",
      "fax": "+39 0166 540679",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 7.566666603088379
      }
    },
    {
      "codice": "007068",
      "nome": "Valgrisenche",
      "nomeStraniero": null,
      "codiceCatastale": "L582",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.valgrisenche.ao.it",
      "pec": "protocollo@pec.comune.valgrisenche.ao.it",
      "telefono": "+39 0165 97105",
      "fax": "+39 0165 97186",
      "coordinate": {
        "lat": 45.630001068115234,
        "lng": 7.064499855041504
      }
    },
    {
      "codice": "007069",
      "nome": "Valpelline",
      "nomeStraniero": null,
      "codiceCatastale": "L643",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.valpelline.ao.it",
      "pec": "protocollo@pec.comune.valpelline.ao.it",
      "telefono": "+39 0165/73234",
      "fax": "+39 0165/73290",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 7.333333492279053
      }
    },
    {
      "codice": "007070",
      "nome": "Valsavarenche",
      "nomeStraniero": null,
      "codiceCatastale": "L647",
      "cap": "11010",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.valsavarenche.ao.it",
      "pec": "protocollo@pec.comune.valsavarenche.ao.it",
      "telefono": "+39 0165 905703",
      "fax": "+39 0165 905742",
      "coordinate": {
        "lat": 45.592201232910156,
        "lng": 7.207799911499023
      }
    },
    {
      "codice": "007071",
      "nome": "Valtournenche",
      "nomeStraniero": null,
      "codiceCatastale": "L654",
      "cap": "11028",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.valtournenche.ao.it",
      "pec": "protocollo@pec.comune.valtournenche.ao.it",
      "telefono": "+39 0166926811",
      "fax": "+39 0166926829",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 7.616666793823242
      }
    },
    {
      "codice": "007072",
      "nome": "Verrayes",
      "nomeStraniero": null,
      "codiceCatastale": "L783",
      "cap": "11020",
      "prefisso": "0166",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.verryaes.ao.it",
      "pec": "protocollo@pec.comune.verrayes.ao.it",
      "telefono": "+39 0166/43106",
      "fax": "+39 0166/43393",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 7.5333333015441895
      }
    },
    {
      "codice": "007073",
      "nome": "Verrès",
      "nomeStraniero": null,
      "codiceCatastale": "C282",
      "cap": "11029",
      "prefisso": "0125",
      "provincia": "Valle d'Aosta",
      "email": "segreteria@comune.verres.ao.it",
      "pec": "protocollo@pec.comune.verres.ao.it",
      "telefono": "+39 0125929324",
      "fax": "+39 0125920614",
      "coordinate": {
        "lat": 45.666561126708984,
        "lng": 7.689109802246094
      }
    },
    {
      "codice": "007074",
      "nome": "Villeneuve",
      "nomeStraniero": null,
      "codiceCatastale": "L981",
      "cap": "11018",
      "prefisso": "0165",
      "provincia": "Valle d'Aosta",
      "email": "info@comune.villeneuve.ao.it",
      "pec": "protocollo@pec.comune.villeneuve.ao.it",
      "telefono": "+39 016595058",
      "fax": "+39 016595271",
      "coordinate": {
        "lat": 45.70243453979492,
        "lng": 7.207591533660889
      }
    }
  ],
  "Fermo": [
    {
      "codice": "109001",
      "nome": "Altidona",
      "nomeStraniero": null,
      "codiceCatastale": "A233",
      "cap": "63824",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@altidona.net",
      "pec": "sindaco@pec.altidona.net",
      "telefono": "+39 0734 936353",
      "fax": "+39 0734 936418",
      "coordinate": {
        "lat": 43.10683822631836,
        "lng": 13.793999671936035
      }
    },
    {
      "codice": "109002",
      "nome": "Amandola",
      "nomeStraniero": null,
      "codiceCatastale": "A252",
      "cap": "63857",
      "prefisso": "0736",
      "provincia": "Fermo",
      "email": "protocollo@comune.amandola.fm.it",
      "pec": "comune.amandola@emarche.it",
      "telefono": "+39 0736 84071",
      "fax": "+39 0736 848037",
      "coordinate": {
        "lat": 42.980098724365234,
        "lng": 13.357500076293945
      }
    },
    {
      "codice": "109003",
      "nome": "Belmonte Piceno",
      "nomeStraniero": null,
      "codiceCatastale": "A760",
      "cap": "63838",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comunebelmontepiceno.it",
      "pec": "comune.belmontepiceno@pec.it",
      "telefono": "+39 0734771100",
      "fax": "+39 0734771291",
      "coordinate": {
        "lat": 43.09149932861328,
        "lng": 13.538880348205566
      }
    },
    {
      "codice": "109004",
      "nome": "Campofilone",
      "nomeStraniero": null,
      "codiceCatastale": "B534",
      "cap": "63828",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "campofilone@ucvaldaso.it",
      "pec": "campofilone@pec.ucvaldaso.it",
      "telefono": "+39 0734 932951",
      "fax": "+39 0734 931824",
      "coordinate": {
        "lat": 43.07917022705078,
        "lng": 13.814530372619629
      }
    },
    {
      "codice": "109005",
      "nome": "Falerone",
      "nomeStraniero": null,
      "codiceCatastale": "D477",
      "cap": "63837",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comunefalerone.it",
      "pec": "protocollo.comune.falerone@emarche.it",
      "telefono": "+39 0734 710750",
      "fax": "+39 0734 710487",
      "coordinate": {
        "lat": 43.10770034790039,
        "lng": 13.473299980163574
      }
    },
    {
      "codice": "109006",
      "nome": "Fermo",
      "nomeStraniero": null,
      "codiceCatastale": "D542",
      "cap": "63900",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "urp@comune.fermo.it",
      "pec": "comune.fermo@pec.comune.fermo.it",
      "telefono": "+39 07342841",
      "fax": "+39 0734224170",
      "coordinate": {
        "lat": 43.16041946411133,
        "lng": 13.718099594116211
      }
    },
    {
      "codice": "109007",
      "nome": "Francavilla d'Ete",
      "nomeStraniero": null,
      "codiceCatastale": "D760",
      "cap": "63816",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comunefrancavilladete.it",
      "pec": "pecomune.francavilladete@emarche.it",
      "telefono": "+39 0734966131",
      "fax": "+39 0734966047",
      "coordinate": {
        "lat": 43.19098663330078,
        "lng": 13.540413856506348
      }
    },
    {
      "codice": "109008",
      "nome": "Grottazzolina",
      "nomeStraniero": null,
      "codiceCatastale": "E208",
      "cap": "63844",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "demografici@comune-grottazzolina.it",
      "pec": "comune.grottazzolina@emarche.it",
      "telefono": "+39 0734 631443",
      "fax": "+39 0734 632615",
      "coordinate": {
        "lat": 43.11103057861328,
        "lng": 13.60093879699707
      }
    },
    {
      "codice": "109009",
      "nome": "Lapedona",
      "nomeStraniero": null,
      "codiceCatastale": "E447",
      "cap": "63823",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "lapedona@ucvaldaso.it",
      "pec": "lapedona@pec.ucvaldaso.it",
      "telefono": "+39 0734 936321",
      "fax": "+39 0734 936322",
      "coordinate": {
        "lat": 43.10976791381836,
        "lng": 13.77275562286377
      }
    },
    {
      "codice": "109010",
      "nome": "Magliano di Tenna",
      "nomeStraniero": null,
      "codiceCatastale": "E807",
      "cap": "63832",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "anagrafe@comune.maglianoditenna.fm.it",
      "pec": "info@pec.comune.maglianoditenna.fm.it",
      "telefono": "+39 0734/632172",
      "fax": "+39 0734/631751",
      "coordinate": {
        "lat": 43.13835144042969,
        "lng": 13.58656120300293
      }
    },
    {
      "codice": "109011",
      "nome": "Massa Fermana",
      "nomeStraniero": null,
      "codiceCatastale": "F021",
      "cap": "63834",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comunemassafermana.it",
      "pec": "protocollo.massafermana@pec.it",
      "telefono": "+39 0734 760127",
      "fax": "+39 0734 760258",
      "coordinate": {
        "lat": 43.15031051635742,
        "lng": 13.47558879852295
      }
    },
    {
      "codice": "109012",
      "nome": "Monsampietro Morico",
      "nomeStraniero": null,
      "codiceCatastale": "F379",
      "cap": "63842",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "comune@monsampietromorico.net",
      "pec": "comune@pec.monsampietromorico.net",
      "telefono": "+39 0734-773140",
      "fax": "+39 0734-773632",
      "coordinate": {
        "lat": 43.06760025024414,
        "lng": 13.556099891662598
      }
    },
    {
      "codice": "109013",
      "nome": "Montappone",
      "nomeStraniero": null,
      "codiceCatastale": "F428",
      "cap": "63835",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comunemontappone.it",
      "pec": "certificata@pec.comune.montappone.fm.it",
      "telefono": "+39 0734 760426",
      "fax": "+39 0734 760709",
      "coordinate": {
        "lat": 43.13740158081055,
        "lng": 13.469099998474121
      }
    },
    {
      "codice": "109016",
      "nome": "Monte Giberto",
      "nomeStraniero": null,
      "codiceCatastale": "F517",
      "cap": "63846",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "comune.montegiberto@tiscalinet.it",
      "pec": "info@pec.montegiberto.net",
      "telefono": "+39 0734 630047",
      "fax": "+39 0734 630452",
      "coordinate": {
        "lat": 43.09130096435547,
        "lng": 13.630800247192383
      }
    },
    {
      "codice": "109021",
      "nome": "Monte Rinaldo",
      "nomeStraniero": null,
      "codiceCatastale": "F599",
      "cap": "63852",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "com.monterinaldo@provincia.fm.it",
      "pec": "comune.monterinaldo@emarche.it",
      "telefono": "+39 0734777121",
      "fax": "+39 0734777132",
      "coordinate": {
        "lat": 43.02838897705078,
        "lng": 13.580780982971191
      }
    },
    {
      "codice": "109023",
      "nome": "Monte San Pietrangeli",
      "nomeStraniero": null,
      "codiceCatastale": "F626",
      "cap": "63815",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "comune.mspietrangeli@virgilio.it",
      "pec": "comune.montesanpietrangeli@emarche.it",
      "telefono": "+39 0734/969125",
      "fax": "+39 0734/969271",
      "coordinate": {
        "lat": 43.192142486572266,
        "lng": 13.578580856323242
      }
    },
    {
      "codice": "109024",
      "nome": "Monte Urano",
      "nomeStraniero": null,
      "codiceCatastale": "F653",
      "cap": "63813",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "sindaco@comune.monteurano.fm.it",
      "pec": "comune.monteurano@emarche.it",
      "telefono": "+39 0734848728",
      "fax": "+39 0734848730",
      "coordinate": {
        "lat": 43.20595932006836,
        "lng": 13.672050476074219
      }
    },
    {
      "codice": "109025",
      "nome": "Monte Vidon Combatte",
      "nomeStraniero": null,
      "codiceCatastale": "F664",
      "cap": "63847",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "protocollo@comune.montevidoncombatte.fm.it",
      "pec": "comune.montevidoncombatte@emarche.it",
      "telefono": "+39 0734 656103",
      "fax": "+39 0734 656103",
      "coordinate": {
        "lat": 43.04978942871094,
        "lng": 13.630319595336914
      }
    },
    {
      "codice": "109026",
      "nome": "Monte Vidon Corrado",
      "nomeStraniero": null,
      "codiceCatastale": "F665",
      "cap": "63836",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comune.montevidoncorrado.fm.it",
      "pec": "certificata@pec.comune.montevidoncorrado.fm.it",
      "telefono": "+39 0734 759348",
      "fax": "+39 0734 759350",
      "coordinate": {
        "lat": 43.12186813354492,
        "lng": 13.484819412231445
      }
    },
    {
      "codice": "109014",
      "nome": "Montefalcone Appennino",
      "nomeStraniero": null,
      "codiceCatastale": "F493",
      "cap": "63855",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "comune@montefalcone.gov.it",
      "pec": "comune@pec.montefalcone.gov.it",
      "telefono": "+39 0734 79111",
      "fax": "+39 0734 79120",
      "coordinate": {
        "lat": 42.98822021484375,
        "lng": 13.458919525146484
      }
    },
    {
      "codice": "109015",
      "nome": "Montefortino",
      "nomeStraniero": null,
      "codiceCatastale": "F509",
      "cap": "63858",
      "prefisso": "0736",
      "provincia": "Fermo",
      "email": "com.montefortino@provincia.ap.it",
      "pec": "comune.montefortino@pec.it",
      "telefono": "+39 0736 859101",
      "fax": "+39 0736 850019",
      "coordinate": {
        "lat": 42.9426383972168,
        "lng": 13.341230392456055
      }
    },
    {
      "codice": "109017",
      "nome": "Montegiorgio",
      "nomeStraniero": null,
      "codiceCatastale": "F520",
      "cap": "63833",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "protocollo@comune.montegiorgio.fm.it",
      "pec": "comune.montegiorgio@emarche.it",
      "telefono": "+39 0734 952011",
      "fax": "+39 0734 952070",
      "coordinate": {
        "lat": 43.130699157714844,
        "lng": 13.537080764770508
      }
    },
    {
      "codice": "109018",
      "nome": "Montegranaro",
      "nomeStraniero": null,
      "codiceCatastale": "F522",
      "cap": "63812",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "segreteria@comune.montegranaro.fm.it",
      "pec": "segreteria@pec.comune.montegranaro.fm.it",
      "telefono": "+39 0734/897931",
      "fax": "+39 0734/889991",
      "coordinate": {
        "lat": 43.233219146728516,
        "lng": 13.632200241088867
      }
    },
    {
      "codice": "109019",
      "nome": "Monteleone di Fermo",
      "nomeStraniero": null,
      "codiceCatastale": "F536",
      "cap": "63841",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "angrafe.monteleonedifermo.fm@gmail.com",
      "pec": "monteleonedifermo@pec.it",
      "telefono": "+39 0734 773521",
      "fax": "+39 0734 773521",
      "coordinate": {
        "lat": 43.047420501708984,
        "lng": 13.52869987487793
      }
    },
    {
      "codice": "109020",
      "nome": "Montelparo",
      "nomeStraniero": null,
      "codiceCatastale": "F549",
      "cap": "63853",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "anagrafe@comune.montelparo.fm.it",
      "pec": "comune.montelparo@emarche.it",
      "telefono": "+39 0734 780141",
      "fax": "+39 0734 780167",
      "coordinate": {
        "lat": 43.01803207397461,
        "lng": 13.53534984588623
      }
    },
    {
      "codice": "109022",
      "nome": "Monterubbiano",
      "nomeStraniero": null,
      "codiceCatastale": "F614",
      "cap": "63825",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "anagrafe.monterubbiano@ucvaldaso.it",
      "pec": "monterubbiano@pec.ucvaldaso.it",
      "telefono": "+39 0734 259980",
      "fax": "+39 0734 59877",
      "coordinate": {
        "lat": 43.085350036621094,
        "lng": 13.71679973602295
      }
    },
    {
      "codice": "109027",
      "nome": "Montottone",
      "nomeStraniero": null,
      "codiceCatastale": "F697",
      "cap": "63843",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "segretario.comunale@montottone.eu",
      "pec": "comune@pec.montottone.eu",
      "telefono": "+39 0734 775135",
      "fax": "+39 0734 775256",
      "coordinate": {
        "lat": 43.062049865722656,
        "lng": 13.58471965789795
      }
    },
    {
      "codice": "109028",
      "nome": "Moresco",
      "nomeStraniero": null,
      "codiceCatastale": "F722",
      "cap": "63826",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "moresco@ucvaldaso.it",
      "pec": "moresco@pec.it",
      "telefono": "+39 0734 259983",
      "fax": null,
      "coordinate": {
        "lat": 43.085819244384766,
        "lng": 13.73205280303955
      }
    },
    {
      "codice": "109029",
      "nome": "Ortezzano",
      "nomeStraniero": null,
      "codiceCatastale": "G137",
      "cap": "63851",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "protocollo@comune.ortezzano.fm.it",
      "pec": "comune.ortezzano@emarche.it",
      "telefono": "+39 0734779181",
      "fax": "+39 0734779309",
      "coordinate": {
        "lat": 43.031490325927734,
        "lng": 13.607569694519043
      }
    },
    {
      "codice": "109030",
      "nome": "Pedaso",
      "nomeStraniero": null,
      "codiceCatastale": "G403",
      "cap": "63827",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "urp@comunedipedaso.it",
      "pec": "protocollo@pec.comunedipedaso.it",
      "telefono": "+39 0734 931319",
      "fax": "+39 0734 931768",
      "coordinate": {
        "lat": 43.09737014770508,
        "lng": 13.840930938720703
      }
    },
    {
      "codice": "109031",
      "nome": "Petritoli",
      "nomeStraniero": null,
      "codiceCatastale": "G516",
      "cap": "63848",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "anagrafe@petritoli.net",
      "pec": "comune.petritoli.sindaco@emarche.it",
      "telefono": "+39 0734 658141",
      "fax": "+39 0734 658880",
      "coordinate": {
        "lat": 43.067100524902344,
        "lng": 13.656049728393555
      }
    },
    {
      "codice": "109032",
      "nome": "Ponzano di Fermo",
      "nomeStraniero": null,
      "codiceCatastale": "G873",
      "cap": "63845",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "comune@ponzanodifermo.org",
      "pec": "comune@pec.ponzanodifermo.org",
      "telefono": "+39 0734 630108",
      "fax": "+39 0734 630461",
      "coordinate": {
        "lat": 43.1015625,
        "lng": 13.65820026397705
      }
    },
    {
      "codice": "109033",
      "nome": "Porto San Giorgio",
      "nomeStraniero": null,
      "codiceCatastale": "G920",
      "cap": "63822",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "segretario@comune-psg.org",
      "pec": "protocollo@pec-comune.portosangiorgio.fm.it",
      "telefono": "+39 0734 6801",
      "fax": "+39 0734 680234",
      "coordinate": {
        "lat": 43.18484878540039,
        "lng": 13.795489311218262
      }
    },
    {
      "codice": "109034",
      "nome": "Porto Sant'Elpidio",
      "nomeStraniero": null,
      "codiceCatastale": "G921",
      "cap": "63821",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "psefraia@elpinet.it",
      "pec": "pseprotocollo@postecert.elpinet.it",
      "telefono": "+39 0734 9081",
      "fax": "+39 0734 908286",
      "coordinate": {
        "lat": 43.25864028930664,
        "lng": 13.758530616760254
      }
    },
    {
      "codice": "109035",
      "nome": "Rapagnano",
      "nomeStraniero": null,
      "codiceCatastale": "H182",
      "cap": "63831",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comune.rapagnano.fm.it",
      "pec": "comune.rapagnano@pec.it",
      "telefono": "+39 0734 510404",
      "fax": "+39 0734 510879",
      "coordinate": {
        "lat": 43.161109924316406,
        "lng": 13.592910766601562
      }
    },
    {
      "codice": "109037",
      "nome": "Sant'Elpidio a Mare",
      "nomeStraniero": null,
      "codiceCatastale": "I324",
      "cap": "63811",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@santelpidioamare.it",
      "pec": "protocollo@pec.santelpidioamare.it",
      "telefono": "+39 073481961",
      "fax": "+39 07348196229",
      "coordinate": {
        "lat": 43.229488372802734,
        "lng": 13.686124801635742
      }
    },
    {
      "codice": "109036",
      "nome": "Santa Vittoria in Matenano",
      "nomeStraniero": null,
      "codiceCatastale": "I315",
      "cap": "63854",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "comunesvm@libero.it",
      "pec": "comunesvittoria@pec.it",
      "telefono": "+39 0734780111",
      "fax": "+39 0734780561",
      "coordinate": {
        "lat": 43.019649505615234,
        "lng": 13.496519088745117
      }
    },
    {
      "codice": "109038",
      "nome": "Servigliano",
      "nomeStraniero": null,
      "codiceCatastale": "C070",
      "cap": "63839",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "com.servigliano@provincia.ap.it",
      "pec": "comune@pec.comune.servigliano.fm.it",
      "telefono": "+39 0734 750583",
      "fax": "+39 0734 710618",
      "coordinate": {
        "lat": 43.080867767333984,
        "lng": 13.49202823638916
      }
    },
    {
      "codice": "109039",
      "nome": "Smerillo",
      "nomeStraniero": null,
      "codiceCatastale": "I774",
      "cap": "63856",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "protocollo@comune.smerillo.fm.it",
      "pec": "comune.smerillo@emarche.it",
      "telefono": "+39 0734 79124",
      "fax": "+39 0734 79454",
      "coordinate": {
        "lat": 43.00436019897461,
        "lng": 13.445619583129883
      }
    },
    {
      "codice": "109040",
      "nome": "Torre San Patrizio",
      "nomeStraniero": null,
      "codiceCatastale": "L279",
      "cap": "63814",
      "prefisso": "0734",
      "provincia": "Fermo",
      "email": "info@comune.torresanpatrizio.fm.it",
      "pec": "comune.torresanpatrizio@postcert.it",
      "telefono": "+39 0734 510151",
      "fax": "+39 0734510763",
      "coordinate": {
        "lat": 43.18396759033203,
        "lng": 13.611000061035156
      }
    }
  ],
  "Chieti": [
    {
      "codice": "069001",
      "nome": "Altino",
      "nomeStraniero": null,
      "codiceCatastale": "A235",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "affari.generali@comune.altino.ch.it",
      "pec": "protocollo@pec.comune.altino.ch.it",
      "telefono": "+39 0872/985835",
      "fax": "+39 0872/985835",
      "coordinate": {
        "lat": 42.099998474121094,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "069002",
      "nome": "Archi",
      "nomeStraniero": null,
      "codiceCatastale": "A367",
      "cap": "66044",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "sindaco@comunediarchi.it",
      "pec": "affarigenerali.comunediarchi@legalmail.it",
      "telefono": "+39 0872 898112",
      "fax": "+39 0872 898688",
      "coordinate": {
        "lat": 42.08333206176758,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "069003",
      "nome": "Ari",
      "nomeStraniero": null,
      "codiceCatastale": "A398",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "ufficio.tecnico.ari@katamail.com",
      "pec": "protocollo.ari@pec.it",
      "telefono": "+39 0871 / 718643",
      "fax": "+39 0871 / 718122",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "069004",
      "nome": "Arielli",
      "nomeStraniero": null,
      "codiceCatastale": "A402",
      "cap": "66030",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "demografici@comune.arielli.ch.it",
      "pec": "comunearielli.segreteria@servercertificato.it",
      "telefono": "+39 0871930111",
      "fax": "+39 0871930783",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "069005",
      "nome": "Atessa",
      "nomeStraniero": null,
      "codiceCatastale": "A485",
      "cap": "66041",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comunediatessa.it",
      "pec": "comunediatessa@pec.it",
      "telefono": "+39 0872/850421",
      "fax": "+39 0872/840413",
      "coordinate": {
        "lat": 42.06666564941406,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "069006",
      "nome": "Bomba",
      "nomeStraniero": null,
      "codiceCatastale": "A956",
      "cap": "66042",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "angelamaria@comunedibomba.it",
      "pec": "comunedibomba@pec.it",
      "telefono": "+39 0872/860116",
      "fax": "+39 0872/860440",
      "coordinate": {
        "lat": 42.03333282470703,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "069007",
      "nome": "Borrello",
      "nomeStraniero": null,
      "codiceCatastale": "B057",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comune.borrello@libero.it",
      "pec": "municipioborrello@postecert.it",
      "telefono": "+39 0872/945124",
      "fax": "+39 0872/945549",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "069008",
      "nome": "Bucchianico",
      "nomeStraniero": null,
      "codiceCatastale": "B238",
      "cap": "66011",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "segreteria@comune.bucchianico.ch.it",
      "pec": "segreterio.bucchianico@pec.it",
      "telefono": "+39 0871/382532",
      "fax": "+39 0871/382550",
      "coordinate": {
        "lat": 42.304359436035156,
        "lng": 14.180561065673828
      }
    },
    {
      "codice": "069010",
      "nome": "Canosa Sannita",
      "nomeStraniero": null,
      "codiceCatastale": "B620",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "demografico@comunecanosasannita.it",
      "pec": "comunecanosa@pec.comunecanosasannita.it",
      "telefono": "+39 0871/93212",
      "fax": "+39 0871/93406",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "069011",
      "nome": "Carpineto Sinello",
      "nomeStraniero": null,
      "codiceCatastale": "B826",
      "cap": "66030",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunedicarpinetosinello@tin.it",
      "pec": "comune.carpinetosinello@pec.it",
      "telefono": "+39 0872/869135",
      "fax": "+39 0872/869951",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 14.5
      }
    },
    {
      "codice": "069012",
      "nome": "Carunchio",
      "nomeStraniero": null,
      "codiceCatastale": "B853",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comune.carunchio@tin.it",
      "pec": "demografici.carunchio@legalmail.it",
      "telefono": "+39 0873/954392",
      "fax": "+39 0873/953241",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "069013",
      "nome": "Casacanditella",
      "nomeStraniero": null,
      "codiceCatastale": "B859",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "casacanditella@excite.it",
      "pec": "protocollo@pec.comune.casacanditella.ch.it",
      "telefono": "+39 0871/807131",
      "fax": "+39 0871/807250",
      "coordinate": {
        "lat": 42.24750518798828,
        "lng": 14.199930191040039
      }
    },
    {
      "codice": "069014",
      "nome": "Casalanguida",
      "nomeStraniero": null,
      "codiceCatastale": "B861",
      "cap": "66031",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comune.casalanguida@libero.it",
      "pec": "comunedicasalanguida.ch@pec-mail.it",
      "telefono": "+39 0872867135",
      "fax": "+39 0872867072",
      "coordinate": {
        "lat": 42.03333282470703,
        "lng": 14.5
      }
    },
    {
      "codice": "069015",
      "nome": "Casalbordino",
      "nomeStraniero": null,
      "codiceCatastale": "B865",
      "cap": "66021",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "demograficicasalbordino2021@gmail.com",
      "pec": "protocollo.comunecasalbordino@legalmail.it",
      "telefono": "+39 0873/921902",
      "fax": "+39 0873/9219233",
      "coordinate": {
        "lat": 42.150001525878906,
        "lng": 14.583333015441895
      }
    },
    {
      "codice": "069016",
      "nome": "Casalincontrada",
      "nomeStraniero": null,
      "codiceCatastale": "B896",
      "cap": "66012",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "casalincontrada@postecert.it",
      "pec": "comunecasalincontrada@postecert.it",
      "telefono": "+39 0871/370130",
      "fax": "+39 0871/342666",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 14.133333206176758
      }
    },
    {
      "codice": "069017",
      "nome": "Casoli",
      "nomeStraniero": null,
      "codiceCatastale": "B985",
      "cap": "66043",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "protocollo@comune.casoli.ch.it",
      "pec": "comune.casoli.ch@halleycert.it",
      "telefono": "+39 0872/99281",
      "fax": "+39 0872/981616",
      "coordinate": {
        "lat": 42.1151123046875,
        "lng": 14.290605545043945
      }
    },
    {
      "codice": "069018",
      "nome": "Castel Frentano",
      "nomeStraniero": null,
      "codiceCatastale": "C114",
      "cap": "66032",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "infocomune@comunedicastelfrentano.it",
      "pec": "protocollo@pec.comunedicastelfrentano.it",
      "telefono": "+39 0872/55931",
      "fax": "+39 0872/672009",
      "coordinate": {
        "lat": 42.20000076293945,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "069019",
      "nome": "Castelguidone",
      "nomeStraniero": null,
      "codiceCatastale": "C123",
      "cap": "66040",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunecastelguidone@libero.it",
      "pec": "comunedicastelguidone@pec.it",
      "telefono": "+39 0873 977348",
      "fax": "+39 0873 977348",
      "coordinate": {
        "lat": 41.81666564941406,
        "lng": 14.516666412353516
      }
    },
    {
      "codice": "069020",
      "nome": "Castiglione Messer Marino",
      "nomeStraniero": null,
      "codiceCatastale": "C298",
      "cap": "66033",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "castiglionemm@libero.it",
      "pec": "servizigenerali@comunecastiglionemessermarino.legalmailpa.it",
      "telefono": "+39 0873/978149",
      "fax": "+39 0873/978149",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "069021",
      "nome": "Celenza sul Trigno",
      "nomeStraniero": null,
      "codiceCatastale": "C428",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "anagrafe@celenzasultrigno.com",
      "pec": "demografici@pec.celenzasultrigno.com",
      "telefono": "+39 0873/958131",
      "fax": "+39 0873/958702",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "069022",
      "nome": "Chieti",
      "nomeStraniero": null,
      "codiceCatastale": "C632",
      "cap": "66100",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comune.chieti.it",
      "pec": "protocollo@pec.comune.chieti.it",
      "telefono": "+39 0871 3411",
      "fax": "+39 0871/341246",
      "coordinate": {
        "lat": 42.351112365722656,
        "lng": 14.167428016662598
      }
    },
    {
      "codice": "069023",
      "nome": "Civitaluparella",
      "nomeStraniero": null,
      "codiceCatastale": "C768",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunecivitaluparella@virgilio.it",
      "pec": "comunecivitaluparella@pec.it",
      "telefono": "+39 0872/945135",
      "fax": "+39 0872/941057",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.300000190734863
      }
    },
    {
      "codice": "069024",
      "nome": "Civitella Messer Raimondo",
      "nomeStraniero": null,
      "codiceCatastale": "C776",
      "cap": "66010",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "anagrafe@civitellamesserraimondo.net",
      "pec": "info@pec.civitellamesserraimondo.net",
      "telefono": "+39 0872 980162",
      "fax": "+39 0872 994846",
      "coordinate": {
        "lat": 42.08333206176758,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "069025",
      "nome": "Colledimacine",
      "nomeStraniero": null,
      "codiceCatastale": "C855",
      "cap": "66010",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "ser.demo.colle@tiscali.it",
      "pec": "comune.colledimacine@pec.it",
      "telefono": "+39 0872/969453",
      "fax": "+39 0872/966979",
      "coordinate": {
        "lat": 42,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "069026",
      "nome": "Colledimezzo",
      "nomeStraniero": null,
      "codiceCatastale": "C856",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunedicolledimezzo@tin.it",
      "pec": "comunecolledimezzo@pec.it",
      "telefono": "+39 0872/949099",
      "fax": "+39 0872/949099",
      "coordinate": {
        "lat": 41.983333587646484,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "069027",
      "nome": "Crecchio",
      "nomeStraniero": null,
      "codiceCatastale": "D137",
      "cap": "66014",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "anagrafe@comune.crecchio.ch.it",
      "pec": "comune.crecchio.ch@legalmail.it",
      "telefono": "+39 0871/941662",
      "fax": "+39 0871/941662",
      "coordinate": {
        "lat": 42.29666519165039,
        "lng": 14.32611083984375
      }
    },
    {
      "codice": "069028",
      "nome": "Cupello",
      "nomeStraniero": null,
      "codiceCatastale": "D209",
      "cap": "66051",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "demografici@comune.cupello.ch.it",
      "pec": "affarigenerali@pec.comunedicupello.it",
      "telefono": "+39 0873316821",
      "fax": "+39 0873318307",
      "coordinate": {
        "lat": 42.06666564941406,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "069029",
      "nome": "Dogliola",
      "nomeStraniero": null,
      "codiceCatastale": "D315",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comune.dogliola@gmail.com",
      "pec": "comunedogliola@legalmail.it",
      "telefono": "+39 0873/956147",
      "fax": "+39 0873/950030",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.633333206176758
      }
    },
    {
      "codice": "069104",
      "nome": "Fallo",
      "nomeStraniero": null,
      "codiceCatastale": "D480",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "anagrafe.fallo@libero.it",
      "pec": "anagrafe.fallo@legalmail.it",
      "telefono": "+39 0872940002",
      "fax": "+39 0872940215",
      "coordinate": {
        "lat": 41.93333435058594,
        "lng": 14.316666603088379
      }
    },
    {
      "codice": "069030",
      "nome": "Fara Filiorum Petri",
      "nomeStraniero": null,
      "codiceCatastale": "D494",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "anagrafe@comunefarafiliorumpetri.it",
      "pec": "pec@pec.comuneffp.it",
      "telefono": "+39 0871/70112",
      "fax": "+39 0871/70133",
      "coordinate": {
        "lat": 42.24833297729492,
        "lng": 14.185832977294922
      }
    },
    {
      "codice": "069031",
      "nome": "Fara San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "D495",
      "cap": "66015",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.farasanmartino.ch.it",
      "pec": "comune.farasanmartino@halleycert.it",
      "telefono": "+39 0872/980155",
      "fax": "+39 0872/980920",
      "coordinate": {
        "lat": 42.090999603271484,
        "lng": 14.206199645996094
      }
    },
    {
      "codice": "069032",
      "nome": "Filetto",
      "nomeStraniero": null,
      "codiceCatastale": "D592",
      "cap": "66030",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comune.filetto.ch.it",
      "pec": "filetto@pec.comune.filetto.ch.it",
      "telefono": "+39 0871/891309",
      "fax": "+39 0871/891167",
      "coordinate": {
        "lat": 42.22930908203125,
        "lng": 14.245010375976562
      }
    },
    {
      "codice": "069033",
      "nome": "Fossacesia",
      "nomeStraniero": null,
      "codiceCatastale": "D738",
      "cap": "66022",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.fossacesia.ch.it",
      "pec": "comune@pec.fossacesia.org",
      "telefono": "+39 0872622225",
      "fax": "+39 0872/622237",
      "coordinate": {
        "lat": 42.25,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "069034",
      "nome": "Fraine",
      "nomeStraniero": null,
      "codiceCatastale": "D757",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunefraine@libero.it",
      "pec": "comune.fraine.ch@legalmail.it",
      "telefono": "+39 0873953246",
      "fax": "+39 0873957916",
      "coordinate": {
        "lat": 41.90657043457031,
        "lng": 14.487199783325195
      }
    },
    {
      "codice": "069035",
      "nome": "Francavilla al Mare",
      "nomeStraniero": null,
      "codiceCatastale": "D763",
      "cap": "66023",
      "prefisso": "085",
      "provincia": "Chieti",
      "email": "protocollo@pec.comune.francavilla.ch.it",
      "pec": "protocollo@pec.comune.francavilla.ch.it",
      "telefono": "+39 085/49201",
      "fax": "+39 085/4920213",
      "coordinate": {
        "lat": 42.41805648803711,
        "lng": 14.291908264160156
      }
    },
    {
      "codice": "069036",
      "nome": "Fresagrandinaria",
      "nomeStraniero": null,
      "codiceCatastale": "D796",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comune.fresagran@tiscali.it",
      "pec": "comunefresagrandinaria@legalmail.it",
      "telefono": "+39 0873.321136",
      "fax": "+39 0873.322803",
      "coordinate": {
        "lat": 41.983333587646484,
        "lng": 14.666666984558105
      }
    },
    {
      "codice": "069037",
      "nome": "Frisa",
      "nomeStraniero": null,
      "codiceCatastale": "D803",
      "cap": "66030",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comune.frisa@tin.it",
      "pec": "protocollo@pec.comune.frisa.ch.it",
      "telefono": "+39 0872588056",
      "fax": "+39 0872588623",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "069038",
      "nome": "Furci",
      "nomeStraniero": null,
      "codiceCatastale": "D823",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunedifurci@virgilio.it",
      "pec": "comune.furci@pec.it",
      "telefono": "+39 0873/939132",
      "fax": "+39 0873/938965",
      "coordinate": {
        "lat": 42,
        "lng": 14.583333015441895
      }
    },
    {
      "codice": "069039",
      "nome": "Gamberale",
      "nomeStraniero": null,
      "codiceCatastale": "D898",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.gamberale.ch.it",
      "pec": "comune.gamberale@pec.it",
      "telefono": "+39 0872/946764",
      "fax": "+39 0872/941300",
      "coordinate": {
        "lat": 41.900001525878906,
        "lng": 14.216666221618652
      }
    },
    {
      "codice": "069040",
      "nome": "Gessopalena",
      "nomeStraniero": null,
      "codiceCatastale": "D996",
      "cap": "66010",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "servizidemografici@comune.gessopalena.ch.it",
      "pec": "prot@pec.comune.gessopalena.ch.it",
      "telefono": "+39 0872.988112",
      "fax": "+39 0872.988109",
      "coordinate": {
        "lat": 42.04999923706055,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "069041",
      "nome": "Gissi",
      "nomeStraniero": null,
      "codiceCatastale": "E052",
      "cap": "66052",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "protocollo@comune.gissi.ch.it",
      "pec": "comune.gissi@legalmail.it",
      "telefono": "+39 0873/93412",
      "fax": "+39 0873/937471",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 14.550000190734863
      }
    },
    {
      "codice": "069042",
      "nome": "Giuliano Teatino",
      "nomeStraniero": null,
      "codiceCatastale": "E056",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comune.giulianoteatino.ch.it",
      "pec": "comune.giulianoteatino@pec.it",
      "telefono": "+39 0871718261",
      "fax": "+39 0871/710583",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "069043",
      "nome": "Guardiagrele",
      "nomeStraniero": null,
      "codiceCatastale": "E243",
      "cap": "66016",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "protocollo@guardiagrele.gov.it",
      "pec": "comune.guardiagrele@pec.it",
      "telefono": "+39 0871/80861",
      "fax": "+39 0871/8086240",
      "coordinate": {
        "lat": 42.1892204284668,
        "lng": 14.22159194946289
      }
    },
    {
      "codice": "069044",
      "nome": "Guilmi",
      "nomeStraniero": null,
      "codiceCatastale": "E266",
      "cap": "66050",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunediguilmi@libero.it",
      "pec": "comune.guilmi@pec.it",
      "telefono": "+39 0872/869146",
      "fax": "+39 0872/869214",
      "coordinate": {
        "lat": 42,
        "lng": 14.483333587646484
      }
    },
    {
      "codice": "069045",
      "nome": "Lama dei Peligni",
      "nomeStraniero": null,
      "codiceCatastale": "E424",
      "cap": "66010",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "ufficioprotocollo@comunelamadeipeligni.it",
      "pec": "comunelamadeipeligni@pec.it",
      "telefono": "+39 0872/91221",
      "fax": "+39 0872/916184",
      "coordinate": {
        "lat": 42.04999923706055,
        "lng": 14.183333396911621
      }
    },
    {
      "codice": "069046",
      "nome": "Lanciano",
      "nomeStraniero": null,
      "codiceCatastale": "E435",
      "cap": "66034",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "elettorale@lanciano.eu",
      "pec": "comune.lanciano.chieti@legalmail.it",
      "telefono": "+39 0872 7071",
      "fax": "+39 0872/40443",
      "coordinate": {
        "lat": 42.2312126159668,
        "lng": 14.390469551086426
      }
    },
    {
      "codice": "069047",
      "nome": "Lentella",
      "nomeStraniero": null,
      "codiceCatastale": "E531",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comune.lentella.ch@halleycert.it",
      "pec": "comune.lentella.ch@halleycert.it",
      "telefono": "+39 0873/321117",
      "fax": "+39 0873/321052",
      "coordinate": {
        "lat": 42,
        "lng": 14.683333396911621
      }
    },
    {
      "codice": "069048",
      "nome": "Lettopalena",
      "nomeStraniero": null,
      "codiceCatastale": "E559",
      "cap": "66010",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "anagrafe@comunelettopalena.net",
      "pec": "comune.lettopalena@legalmail.it",
      "telefono": "+39 0872918471",
      "fax": "+39 0872918471",
      "coordinate": {
        "lat": 42,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "069049",
      "nome": "Liscia",
      "nomeStraniero": null,
      "codiceCatastale": "E611",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunediliscia@libero.it",
      "pec": "comune.liscia@pec.it",
      "telefono": "+39 0873/930100",
      "fax": "+39 0873/930100",
      "coordinate": {
        "lat": 41.955238342285156,
        "lng": 14.555319786071777
      }
    },
    {
      "codice": "069050",
      "nome": "Miglianico",
      "nomeStraniero": null,
      "codiceCatastale": "F196",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "protocollo@miglianico.gov.it",
      "pec": "miglianico@pec.it",
      "telefono": "+39 0871 951125",
      "fax": "+39 0871 950271",
      "coordinate": {
        "lat": 42.35938262939453,
        "lng": 14.292522430419922
      }
    },
    {
      "codice": "069051",
      "nome": "Montazzoli",
      "nomeStraniero": null,
      "codiceCatastale": "F433",
      "cap": "66030",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "montazzoli@hotmail.it",
      "pec": "montazzoli@raccomandata.eu",
      "telefono": "+39 0872947126",
      "fax": "+39 0872947131",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.433333396911621
      }
    },
    {
      "codice": "069009",
      "nome": "Montebello sul Sangro",
      "nomeStraniero": null,
      "codiceCatastale": "B268",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comune.montebello@virgilio.it",
      "pec": "comunemontebellosulsangro@emailcertificatapec.it",
      "telefono": "+39 0872/940297",
      "fax": "+39 0872/944697",
      "coordinate": {
        "lat": 41.983333587646484,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "069052",
      "nome": "Monteferrante",
      "nomeStraniero": null,
      "codiceCatastale": "F498",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunemonteferrante@inwind.it",
      "pec": "comune.monteferrante@pec.it",
      "telefono": "+39 0872/940354",
      "fax": "+39 0872/940354",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.383333206176758
      }
    },
    {
      "codice": "069053",
      "nome": "Montelapiano",
      "nomeStraniero": null,
      "codiceCatastale": "F535",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comunemontelapiano.ch.it",
      "pec": "comunemontelapiano@pec.it",
      "telefono": "+39 0872944482",
      "fax": "+39 0872944482",
      "coordinate": {
        "lat": 41.96666717529297,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "069054",
      "nome": "Montenerodomo",
      "nomeStraniero": null,
      "codiceCatastale": "F578",
      "cap": "66010",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "commontenerodomo.ch@halleycert.it",
      "pec": "comune.montenerodomo.ch@halleycert.it",
      "telefono": "+39 0872/960109",
      "fax": "+39 0872/960058",
      "coordinate": {
        "lat": 41.983333587646484,
        "lng": 14.25
      }
    },
    {
      "codice": "069055",
      "nome": "Monteodorisio",
      "nomeStraniero": null,
      "codiceCatastale": "F582",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunemonteodorisio@alice.it",
      "pec": "servizidemografici@comunemonteodorisio.legalmail.it",
      "telefono": "+39 0873/316131",
      "fax": "+39 0873/316450",
      "coordinate": {
        "lat": 42.08333206176758,
        "lng": 14.649999618530273
      }
    },
    {
      "codice": "069056",
      "nome": "Mozzagrogna",
      "nomeStraniero": null,
      "codiceCatastale": "F785",
      "cap": "66030",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comunemozzagrogna.it",
      "pec": "comune.mozzagrogna@legalmail.it",
      "telefono": "+39 0872/600111",
      "fax": "+39 0872/578583",
      "coordinate": {
        "lat": 42.211387634277344,
        "lng": 14.445833206176758
      }
    },
    {
      "codice": "069057",
      "nome": "Orsogna",
      "nomeStraniero": null,
      "codiceCatastale": "G128",
      "cap": "66036",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "sindaco@orsogna.net",
      "pec": "info@pec.comune.orsogna.chieti.it",
      "telefono": "+39 0871/869765",
      "fax": "+39 0871/867080",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 14.283333778381348
      }
    },
    {
      "codice": "069058",
      "nome": "Ortona",
      "nomeStraniero": null,
      "codiceCatastale": "G141",
      "cap": "66026",
      "prefisso": "085",
      "provincia": "Chieti",
      "email": "m.dibartolomeo@pec.comuneortona.ch.it",
      "pec": "protocollo@pec.comuneortona.ch.it",
      "telefono": "+39 085/90571",
      "fax": "+39 085/9066037",
      "coordinate": {
        "lat": 42.35565948486328,
        "lng": 14.403594017028809
      }
    },
    {
      "codice": "069059",
      "nome": "Paglieta",
      "nomeStraniero": null,
      "codiceCatastale": "G237",
      "cap": "66020",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "sindaco@comune.paglieta.ch.it",
      "pec": "protocollo@pec.comune.paglieta.ch.it",
      "telefono": "+39 0872/808201",
      "fax": "+39 0872/80531",
      "coordinate": {
        "lat": 42.16666793823242,
        "lng": 14.5
      }
    },
    {
      "codice": "069060",
      "nome": "Palena",
      "nomeStraniero": null,
      "codiceCatastale": "G271",
      "cap": "66017",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comunedipalena.it",
      "pec": "comunedipalena@pec.it",
      "telefono": "+39 0872/918112",
      "fax": "+39 0872/918447",
      "coordinate": {
        "lat": 41.981666564941406,
        "lng": 14.134166717529297
      }
    },
    {
      "codice": "069061",
      "nome": "Palmoli",
      "nomeStraniero": null,
      "codiceCatastale": "G290",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunedipalmoli@libero.it",
      "pec": "comune.palmoli@pec.it",
      "telefono": "+39 0873 955121",
      "fax": "+39 0873 955121",
      "coordinate": {
        "lat": 41.93944549560547,
        "lng": 14.581388473510742
      }
    },
    {
      "codice": "069062",
      "nome": "Palombaro",
      "nomeStraniero": null,
      "codiceCatastale": "G294",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "sindaco@comune.palombaro.ch.it",
      "pec": "comunepalombaro@servercertificato.it",
      "telefono": "+39 0871/895131",
      "fax": "+39 0871/895519",
      "coordinate": {
        "lat": 42.12322998046875,
        "lng": 14.229889869689941
      }
    },
    {
      "codice": "069063",
      "nome": "Pennadomo",
      "nomeStraniero": null,
      "codiceCatastale": "G434",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunepennadomo@libero.it",
      "pec": "servizidemografici@legalmail.it",
      "telefono": "+39 0872/968131",
      "fax": "+39 0872/968340",
      "coordinate": {
        "lat": 42,
        "lng": 14.316666603088379
      }
    },
    {
      "codice": "069064",
      "nome": "Pennapiedimonte",
      "nomeStraniero": null,
      "codiceCatastale": "G435",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comune.pennapiedimonte.ch.it",
      "pec": "comune.pennapiedimonte@pec.it",
      "telefono": "+39 0871897130",
      "fax": "+39 0871897251",
      "coordinate": {
        "lat": 42.15209197998047,
        "lng": 14.194774627685547
      }
    },
    {
      "codice": "069065",
      "nome": "Perano",
      "nomeStraniero": null,
      "codiceCatastale": "G441",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "anagrafe@comune.perano.ch.it",
      "pec": "info@pec.comune.perano.ch.it",
      "telefono": "+39 0872/898114",
      "fax": "+39 0872/898504",
      "coordinate": {
        "lat": 42.099998474121094,
        "lng": 14.399999618530273
      }
    },
    {
      "codice": "069103",
      "nome": "Pietraferrazzana",
      "nomeStraniero": null,
      "codiceCatastale": "G613",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.pietraferrazzana.ch.it",
      "pec": "comune.pietraferrazzana@pec.it",
      "telefono": "+39 0872944470",
      "fax": "+39 0872944470",
      "coordinate": {
        "lat": 41.96666717529297,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "069066",
      "nome": "Pizzoferrato",
      "nomeStraniero": null,
      "codiceCatastale": "G724",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunedipizzofer_anag@virgilio.it",
      "pec": "pizzoferrato.anagrafe@legalmail.it",
      "telefono": "+39 0872 946114",
      "fax": "+39 0872 946819",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "069067",
      "nome": "Poggiofiorito",
      "nomeStraniero": null,
      "codiceCatastale": "G760",
      "cap": "66030",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "com.poggiofiorito@libero.it",
      "pec": "info@pec.comune.poggiofiorito.ch.it",
      "telefono": "+39 0871/930125",
      "fax": "+39 0871/938337",
      "coordinate": {
        "lat": 42.25,
        "lng": 14.316666603088379
      }
    },
    {
      "codice": "069068",
      "nome": "Pollutri",
      "nomeStraniero": null,
      "codiceCatastale": "G799",
      "cap": "66020",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "info@comunedipollutri.it",
      "pec": "comunedipollutri@legalmail.it",
      "telefono": "+39 0873/907359",
      "fax": "+39 0873/900300",
      "coordinate": {
        "lat": 42.13333511352539,
        "lng": 14.600000381469727
      }
    },
    {
      "codice": "069069",
      "nome": "Pretoro",
      "nomeStraniero": null,
      "codiceCatastale": "H052",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comune.pretoro.ch.it",
      "pec": "comunepretoro@pec.it",
      "telefono": "+39 0871/898001",
      "fax": "+39 0871/898001",
      "coordinate": {
        "lat": 42.21630096435547,
        "lng": 14.143038749694824
      }
    },
    {
      "codice": "069070",
      "nome": "Quadri",
      "nomeStraniero": null,
      "codiceCatastale": "H098",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comune.quadri@libero.it",
      "pec": "casella@comune.quadri.ch.it",
      "telefono": "+39 0872945112",
      "fax": "+39 0872/945170",
      "coordinate": {
        "lat": 41.92401885986328,
        "lng": 14.287679672241211
      }
    },
    {
      "codice": "069071",
      "nome": "Rapino",
      "nomeStraniero": null,
      "codiceCatastale": "H184",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@rapino.net",
      "pec": "comunedirapino@pec.it",
      "telefono": "+39 0871/34431",
      "fax": "+39 0871/84205",
      "coordinate": {
        "lat": 42.21103286743164,
        "lng": 14.187357902526855
      }
    },
    {
      "codice": "069072",
      "nome": "Ripa Teatina",
      "nomeStraniero": null,
      "codiceCatastale": "H320",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comune.ripateatina.ch.it",
      "pec": "comune.ripateatina.ch@pec.it",
      "telefono": "+39 0871/391543",
      "fax": "+39 0871/391550",
      "coordinate": {
        "lat": 42.349998474121094,
        "lng": 14.233333587646484
      }
    },
    {
      "codice": "069074",
      "nome": "Rocca San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "H439",
      "cap": "66020",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "protocollo@comuneroccasangiovanni.it",
      "pec": "comune.roccasangiovanni@pec.it",
      "telefono": "+39 0872/60121",
      "fax": "+39 0872/620247",
      "coordinate": {
        "lat": 42.25,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "069073",
      "nome": "Roccamontepiano",
      "nomeStraniero": null,
      "codiceCatastale": "H424",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "anagraferocca@tin.it",
      "pec": "protocollo@pec.comune.roccamontepiano.ch.it",
      "telefono": "+39 0871/77470",
      "fax": "+39 0871/77130",
      "coordinate": {
        "lat": 42.244728088378906,
        "lng": 14.127325057983398
      }
    },
    {
      "codice": "069075",
      "nome": "Roccascalegna",
      "nomeStraniero": null,
      "codiceCatastale": "H442",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "anagrafe@comunediroccascalegna.it",
      "pec": "pec@comuneroccascalegna.it",
      "telefono": "+39 0872987111",
      "fax": "+39 0872/987547",
      "coordinate": {
        "lat": 42.062171936035156,
        "lng": 14.306525230407715
      }
    },
    {
      "codice": "069076",
      "nome": "Roccaspinalveti",
      "nomeStraniero": null,
      "codiceCatastale": "H448",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "roccaspinalveti@tin.it",
      "pec": "comune@pec.comuneroccaspinalveti.it",
      "telefono": "+39 0873/959131",
      "fax": "+39 0873/959488",
      "coordinate": {
        "lat": 41.93333435058594,
        "lng": 14.466666221618652
      }
    },
    {
      "codice": "069077",
      "nome": "Roio del Sangro",
      "nomeStraniero": null,
      "codiceCatastale": "H495",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "comunediroiodelsangro@virgilio.it",
      "pec": "comuneroio@pec.mediosangro.it",
      "telefono": "+39 0872948142",
      "fax": "+39 0872948566",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.366666793823242
      }
    },
    {
      "codice": "069078",
      "nome": "Rosello",
      "nomeStraniero": null,
      "codiceCatastale": "H566",
      "cap": "66040",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.rosello.ch.it",
      "pec": "rosello.comune@larpec.com",
      "telefono": "+39 0872/948131",
      "fax": "+39 0872/948093",
      "coordinate": {
        "lat": 41.900001525878906,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "069079",
      "nome": "San Buono",
      "nomeStraniero": null,
      "codiceCatastale": "H784",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "csbuono@tin.it",
      "pec": "comune.sanbuono@legalmail.it",
      "telefono": "+39 0873/930145",
      "fax": "+39 0873930612",
      "coordinate": {
        "lat": 41.983333587646484,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "069080",
      "nome": "San Giovanni Lipioni",
      "nomeStraniero": null,
      "codiceCatastale": "H923",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunesangiovannilipioni@tin.it",
      "pec": "comune.sangiovannilipioni.ragioneria@legalmail.it",
      "telefono": "+39 0873952231",
      "fax": "+39 0873952244",
      "coordinate": {
        "lat": 41.849998474121094,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "069081",
      "nome": "San Giovanni Teatino",
      "nomeStraniero": null,
      "codiceCatastale": "D690",
      "cap": "66020",
      "prefisso": "085",
      "provincia": "Chieti",
      "email": "ufficio.anagrafe@comune.sangiovanniteatino.ch.it",
      "pec": "comunesgt@pec.it",
      "telefono": "+39 085/44446203",
      "fax": "+39 085/4461383",
      "coordinate": {
        "lat": 42.42222213745117,
        "lng": 14.186666488647461
      }
    },
    {
      "codice": "069082",
      "nome": "San Martino sulla Marrucina",
      "nomeStraniero": null,
      "codiceCatastale": "H991",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "segretario@sanmartinosm.it",
      "pec": "protocollo@pec.sanmartinosm.it",
      "telefono": "+39 0871/82515",
      "fax": "+39 0871/800131",
      "coordinate": {
        "lat": 42.22499084472656,
        "lng": 14.214310646057129
      }
    },
    {
      "codice": "069083",
      "nome": "San Salvo",
      "nomeStraniero": null,
      "codiceCatastale": "I148",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "wturilli@comune.sansalvo.ch.it",
      "pec": "serviziosegreteria@comunesansalvo.legalmail.it",
      "telefono": "+39 0873 340219",
      "fax": "+39 0873/547712",
      "coordinate": {
        "lat": 42.0455207824707,
        "lng": 14.731522560119629
      }
    },
    {
      "codice": "069086",
      "nome": "San Vito Chietino",
      "nomeStraniero": null,
      "codiceCatastale": "I394",
      "cap": "66038",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.sanvitochietino.ch.it",
      "pec": "anagrafesanvitochietino@pec.it",
      "telefono": "+39 0872/587167",
      "fax": "+39 0872/619150",
      "coordinate": {
        "lat": 42.29999923706055,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "069085",
      "nome": "Sant'Eusanio del Sangro",
      "nomeStraniero": null,
      "codiceCatastale": "I335",
      "cap": "66037",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "sindaco@comune.santeusaniodelsangro.chieti.it",
      "pec": "comune.santeusaniodelsangro@legalmailpa.it",
      "telefono": "+39 0872/757121/",
      "fax": "+39 0872/757140",
      "coordinate": {
        "lat": 42.16666793823242,
        "lng": 14.333333015441895
      }
    },
    {
      "codice": "069084",
      "nome": "Santa Maria Imbaro",
      "nomeStraniero": null,
      "codiceCatastale": "I244",
      "cap": "66030",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.santamariaimbaro.ch.it",
      "pec": "comune.smimbaro@legalmail.it",
      "telefono": "+39 0872/579025",
      "fax": "+39 0872/578774",
      "coordinate": {
        "lat": 42.21666717529297,
        "lng": 14.449999809265137
      }
    },
    {
      "codice": "069087",
      "nome": "Scerni",
      "nomeStraniero": null,
      "codiceCatastale": "I520",
      "cap": "66020",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comunediscreni@comunediscerni.com",
      "pec": "protocollo@comunediscerni.legalmail.it",
      "telefono": "+39 0873/919125",
      "fax": "+39 0873/919044",
      "coordinate": {
        "lat": 42.11666488647461,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "069088",
      "nome": "Schiavi di Abruzzo",
      "nomeStraniero": null,
      "codiceCatastale": "I526",
      "cap": "66045",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "schiavi.abruzzo@virgilio.it",
      "pec": "servizigenerali@comuneschiavidiabruzzo.legalmailpa.it",
      "telefono": "+39 0873/970121",
      "fax": "+39 0873-979366",
      "coordinate": {
        "lat": 41.816036224365234,
        "lng": 14.484783172607422
      }
    },
    {
      "codice": "069089",
      "nome": "Taranta Peligna",
      "nomeStraniero": null,
      "codiceCatastale": "L047",
      "cap": "66018",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.tarantapeligna.ch.it",
      "pec": "protocollo@pec.comune.tarantapeligna.ch.it",
      "telefono": "+39 0872910118",
      "fax": "+39 0872910118",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 14.166666984558105
      }
    },
    {
      "codice": "069090",
      "nome": "Tollo",
      "nomeStraniero": null,
      "codiceCatastale": "L194",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "comuneditollo@virgilio.it",
      "pec": "comune.tollo@legalmail.it",
      "telefono": "+39 0871/962622",
      "fax": "+39 0871/962650",
      "coordinate": {
        "lat": 42.345420837402344,
        "lng": 14.323599815368652
      }
    },
    {
      "codice": "069091",
      "nome": "Torino di Sangro",
      "nomeStraniero": null,
      "codiceCatastale": "L218",
      "cap": "66020",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "protocollo@comune.torinodisangro.ch.it",
      "pec": "protocollo@comunetorinodisangro.legalmail.it",
      "telefono": "+39 0873913121",
      "fax": "+39 0873913175",
      "coordinate": {
        "lat": 42.18333435058594,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "069092",
      "nome": "Tornareccio",
      "nomeStraniero": null,
      "codiceCatastale": "L224",
      "cap": "66046",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.tornareccio.ch.it",
      "pec": "protocollo@pec.comune.tornareccio.ch.it",
      "telefono": "+39 0872 868139",
      "fax": "+39 0872 868884",
      "coordinate": {
        "lat": 42.03333282470703,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "069093",
      "nome": "Torrebruna",
      "nomeStraniero": null,
      "codiceCatastale": "L253",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "comune.torrebruna@libero.it",
      "pec": "comune.torrebruna@legalmail.it",
      "telefono": "+39 0873/952131",
      "fax": "+39 0873/952473",
      "coordinate": {
        "lat": 41.86666488647461,
        "lng": 14.533333778381348
      }
    },
    {
      "codice": "069094",
      "nome": "Torrevecchia Teatina",
      "nomeStraniero": null,
      "codiceCatastale": "L284",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "info@comunetorrevecchiateatina.it",
      "pec": "info@pec.comunetorrevecchiateatina.it",
      "telefono": "+39 0871360137",
      "fax": "+39 0871361331",
      "coordinate": {
        "lat": 42.38333511352539,
        "lng": 14.199999809265137
      }
    },
    {
      "codice": "069095",
      "nome": "Torricella Peligna",
      "nomeStraniero": null,
      "codiceCatastale": "L291",
      "cap": "66019",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "info@comune.torricellapeligna.ch.it",
      "pec": "protocollo@pec.comune.torricellapeligna.ch.it",
      "telefono": "+39 0872969412",
      "fax": "+39 0872969681",
      "coordinate": {
        "lat": 42.016666412353516,
        "lng": 14.266666412353516
      }
    },
    {
      "codice": "069096",
      "nome": "Treglio",
      "nomeStraniero": null,
      "codiceCatastale": "L363",
      "cap": "66030",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "treglio@tin.it",
      "pec": "treglio@legalmail.it",
      "telefono": "+39 0872/54132",
      "fax": "+39 0872/546052",
      "coordinate": {
        "lat": 42.266666412353516,
        "lng": 14.416666984558105
      }
    },
    {
      "codice": "069097",
      "nome": "Tufillo",
      "nomeStraniero": null,
      "codiceCatastale": "L459",
      "cap": "66050",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "info@comune.tufillo.ch.it",
      "pec": "comune.tufillo.anagrafe@legalmail.it",
      "telefono": "+39 0873/956131",
      "fax": "+39 0873/956276",
      "coordinate": {
        "lat": 41.91666793823242,
        "lng": 14.616666793823242
      }
    },
    {
      "codice": "069098",
      "nome": "Vacri",
      "nomeStraniero": null,
      "codiceCatastale": "L526",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "demografici@comune.vacri.ch.it",
      "pec": "comune.vacri.ch@legalmail.it",
      "telefono": "+39 0871/71500 - 1",
      "fax": "+39 0871/718416",
      "coordinate": {
        "lat": 42.2978401184082,
        "lng": 14.23270034790039
      }
    },
    {
      "codice": "069099",
      "nome": "Vasto",
      "nomeStraniero": null,
      "codiceCatastale": "E372",
      "cap": "66054",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "n.paganelli@comune.vasto.ch.it",
      "pec": "comune.vasto@legalmail.it",
      "telefono": "+39 0873-3091",
      "fax": "+39 0873-309286",
      "coordinate": {
        "lat": 42.11158752441406,
        "lng": 14.708219528198242
      }
    },
    {
      "codice": "069102",
      "nome": "Villa Santa Maria",
      "nomeStraniero": null,
      "codiceCatastale": "M022",
      "cap": "66047",
      "prefisso": "0872",
      "provincia": "Chieti",
      "email": "sindaco@villasantamaria.eu",
      "pec": "comune@pec.villassantamaria.eu",
      "telefono": "+39 0872944416",
      "fax": "+39 0872940052",
      "coordinate": {
        "lat": 41.95000076293945,
        "lng": 14.350000381469727
      }
    },
    {
      "codice": "069100",
      "nome": "Villalfonsina",
      "nomeStraniero": null,
      "codiceCatastale": "L961",
      "cap": "66020",
      "prefisso": "0873",
      "provincia": "Chieti",
      "email": "anagrafe@comune.villalfonsina.ch.it",
      "pec": "comunevillalfonsina@postecert.it",
      "telefono": "+39 0873/900280",
      "fax": "+39 0873/909063",
      "coordinate": {
        "lat": 42.16666793823242,
        "lng": 14.566666603088379
      }
    },
    {
      "codice": "069101",
      "nome": "Villamagna",
      "nomeStraniero": null,
      "codiceCatastale": "L964",
      "cap": "66010",
      "prefisso": "0871",
      "provincia": "Chieti",
      "email": "anagrafe@comunedivillamagna.it",
      "pec": "anagrafe@pec.comunedivillamagna.it",
      "telefono": "+39 0871/300110",
      "fax": "+39 0871/300795",
      "coordinate": {
        "lat": 42.32984161376953,
        "lng": 14.236889839172363
      }
    }
  ],
  "Treviso": [
    {
      "codice": "026001",
      "nome": "Altivole",
      "nomeStraniero": null,
      "codiceCatastale": "A237",
      "cap": "31030",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "ufficio.tecnico@comune.altivole.tv.it",
      "pec": "comunealtivole@legalmail.it",
      "telefono": "+39 0423 918343",
      "fax": "+39 0423 918383",
      "coordinate": {
        "lat": 45.75,
        "lng": 11.949999809265137
      }
    },
    {
      "codice": "026002",
      "nome": "Arcade",
      "nomeStraniero": null,
      "codiceCatastale": "A360",
      "cap": "31030",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.arcade.tv.it",
      "pec": "protocollo.comune.arcade.tv@pecveneto.it",
      "telefono": "+39 0422.874073",
      "fax": "+39 0422.874038",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 12.216666221618652
      }
    },
    {
      "codice": "026003",
      "nome": "Asolo",
      "nomeStraniero": null,
      "codiceCatastale": "A471",
      "cap": "31011",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "uffici@comune.asolo.tv.it",
      "pec": "comune.asolo.tv@pecveneto.it",
      "telefono": "+39 0423.524616",
      "fax": "+39 0423.950130",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "026004",
      "nome": "Borso del Grappa",
      "nomeStraniero": null,
      "codiceCatastale": "B061",
      "cap": "31030",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "protocollo@comune.borsodelgrappa.tv.it",
      "pec": "protocollo.comune.borsodelgrappa.tv@pecveneto.it",
      "telefono": "+39 0423 561106",
      "fax": "+39 0423 542273",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "026005",
      "nome": "Breda di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "B128",
      "cap": "31030",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "protocollo@comunebreda.it",
      "pec": "protocollo.comune.bredadipiave.tv@pecveneto.it",
      "telefono": "+39 0422 600153",
      "fax": "+39 0422 600187",
      "coordinate": {
        "lat": 45.72454833984375,
        "lng": 12.331021308898926
      }
    },
    {
      "codice": "026006",
      "nome": "Caerano di San Marco",
      "nomeStraniero": null,
      "codiceCatastale": "B349",
      "cap": "31031",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "servizi.generali@comune-caerano.it",
      "pec": "protocollo.comune.caerano.tv@pecveneto.it",
      "telefono": "+39 0423 659810",
      "fax": "+39 0423 859269",
      "coordinate": {
        "lat": 45.7852783203125,
        "lng": 12.004166603088379
      }
    },
    {
      "codice": "026007",
      "nome": "Cappella Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "B678",
      "cap": "31012",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "demografici@comune.cappellamaggiore.tv.it",
      "pec": "protocollo.comune.cappellamaggiore.tv@pecveneto.it",
      "telefono": "+39 0438 932212",
      "fax": "+39 0438 580333",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 12.366666793823242
      }
    },
    {
      "codice": "026008",
      "nome": "Carbonera",
      "nomeStraniero": null,
      "codiceCatastale": "B744",
      "cap": "31030",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@carbonera-tv.it",
      "pec": "comune.carbonera.tv@pecveneto.it",
      "telefono": "+39 0422 691156",
      "fax": "+39 0422 691165",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 12.283333778381348
      }
    },
    {
      "codice": "026009",
      "nome": "Casale sul Sile",
      "nomeStraniero": null,
      "codiceCatastale": "B879",
      "cap": "31032",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comunecasale.tv.it",
      "pec": "protocollo@comune.casalesulsile.legalmailpa.it",
      "telefono": "+39 0422 784511",
      "fax": "+39 0422 784509",
      "coordinate": {
        "lat": 45.59880065917969,
        "lng": 12.324299812316895
      }
    },
    {
      "codice": "026010",
      "nome": "Casier",
      "nomeStraniero": null,
      "codiceCatastale": "B965",
      "cap": "31030",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comunecasier.it",
      "pec": "comunecasier@pec.it",
      "telefono": "+39 0422380033",
      "fax": "+39 0422490060",
      "coordinate": {
        "lat": 45.643611907958984,
        "lng": 12.29527759552002
      }
    },
    {
      "codice": "026011",
      "nome": "Castelcucco",
      "nomeStraniero": null,
      "codiceCatastale": "C073",
      "cap": "31030",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "ragioneria@comune.castelcucco.tv.it",
      "pec": "comune.castelcucco.tv@pecveneto.it",
      "telefono": "+39 0423 962069",
      "fax": "+39 0423 563495",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "026012",
      "nome": "Castelfranco Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "C111",
      "cap": "31033",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "comune@comune.castelfrancoveneto.tv.it",
      "pec": "comune.castelfrancoveneto.tv@pecveneto.it",
      "telefono": "+39 0423 7354",
      "fax": "+39 0423 735580",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "026013",
      "nome": "Castello di Godego",
      "nomeStraniero": null,
      "codiceCatastale": "C190",
      "cap": "31030",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "demografia@comune.castellodigodego.tv.it",
      "pec": "protocollo.comune.castellodigodego.tv@pecveneto.it",
      "telefono": "+39 0423 761127",
      "fax": "+39 0423 761133",
      "coordinate": {
        "lat": 45.70000076293945,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "026014",
      "nome": "Cavaso del Tomba",
      "nomeStraniero": null,
      "codiceCatastale": "C384",
      "cap": "31034",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "ragioneria@comune.cavaso.tv.it",
      "pec": "comune.cavasodeltomba.tv@pecit.it",
      "telefono": "+39 0423 9423111",
      "fax": "+39 0423 543288",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "026015",
      "nome": "Cessalto",
      "nomeStraniero": null,
      "codiceCatastale": "C580",
      "cap": "31040",
      "prefisso": "0421",
      "provincia": "Treviso",
      "email": "ced@comune.cessalto.tv.it",
      "pec": "protocollo.comune.cessalto.tv@pecveneto.it",
      "telefono": "+39 0421 327110",
      "fax": "+39 0421 327753",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "026016",
      "nome": "Chiarano",
      "nomeStraniero": null,
      "codiceCatastale": "C614",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.chiarano.tv.it",
      "pec": "comune.chiarano.tv@pecveneto.it",
      "telefono": "+39 0422746010",
      "fax": "+39 0422746862",
      "coordinate": {
        "lat": 45.73004150390625,
        "lng": 12.581278800964355
      }
    },
    {
      "codice": "026017",
      "nome": "Cimadolmo",
      "nomeStraniero": null,
      "codiceCatastale": "C689",
      "cap": "31010",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.cimadolmo.tv.it",
      "pec": "protocollo.comune.cimadolmo.tv@pecveneto.it",
      "telefono": "+39 0422 1836200",
      "fax": "+39 0422 1836201",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 12.366666793823242
      }
    },
    {
      "codice": "026018",
      "nome": "Cison di Valmarino",
      "nomeStraniero": null,
      "codiceCatastale": "C735",
      "cap": "31030",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "ragioneria@comune.cisondivalmarino.tv.it",
      "pec": "info@pec.comune.cisondivalmarino.tv.it",
      "telefono": "+39 0438 977620",
      "fax": "+39 0438 977602",
      "coordinate": {
        "lat": 45.97027587890625,
        "lng": 12.14305591583252
      }
    },
    {
      "codice": "026019",
      "nome": "Codognè",
      "nomeStraniero": null,
      "codiceCatastale": "C815",
      "cap": "31013",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "comune@comune.codogne.tv.it",
      "pec": "info.comune.codogne.tv@pecveneto.it",
      "telefono": "+39 0438 7931",
      "fax": "+39 0438 793201",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 12.433333396911621
      }
    },
    {
      "codice": "026020",
      "nome": "Colle Umberto",
      "nomeStraniero": null,
      "codiceCatastale": "C848",
      "cap": "31014",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "municipio@comune.colle-umberto.tv.it",
      "pec": "protocollo.comune.colleumberto.tv@pecveneto.it",
      "telefono": "+39 0438 39775",
      "fax": "+39 0438 394519",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 12.350000381469727
      }
    },
    {
      "codice": "026021",
      "nome": "Conegliano",
      "nomeStraniero": null,
      "codiceCatastale": "C957",
      "cap": "31015",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "protocollo@comune.conegliano.tv.it",
      "pec": "pec@comuneconegliano.legalmail.it",
      "telefono": "+39 0438 413341",
      "fax": "+39 0438 413560",
      "coordinate": {
        "lat": 45.88722229003906,
        "lng": 12.296944618225098
      }
    },
    {
      "codice": "026022",
      "nome": "Cordignano",
      "nomeStraniero": null,
      "codiceCatastale": "C992",
      "cap": "31016",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "ragioneria@comune.cordignano.tv.it",
      "pec": "comune.cordignano@halleycert.it",
      "telefono": "+39 0438 779711",
      "fax": "+39 0438 995445",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 12.416666984558105
      }
    },
    {
      "codice": "026023",
      "nome": "Cornuda",
      "nomeStraniero": null,
      "codiceCatastale": "D030",
      "cap": "31041",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "anagrafe@comune.cornuda.tv.it",
      "pec": "protocollo.comune.cornuda.tv@pecveneto.it",
      "telefono": "+39 0423 040420",
      "fax": "+39 0423 839522",
      "coordinate": {
        "lat": 45.83222198486328,
        "lng": 12.008055686950684
      }
    },
    {
      "codice": "026025",
      "nome": "Crocetta del Montello",
      "nomeStraniero": null,
      "codiceCatastale": "C670",
      "cap": "31035",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "anagrafe@comune.crocetta.tv.it",
      "pec": "protocollo.comune.crocetta.tv@pecveneto.it",
      "telefono": "+39 0423 666622",
      "fax": "+39 0423 868808",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "026026",
      "nome": "Farra di Soligo",
      "nomeStraniero": null,
      "codiceCatastale": "D505",
      "cap": "31010",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "ragioneria@farra.it",
      "pec": "comune.farradisoligo.tv@pecveneto.it",
      "telefono": "+39 0438 901502",
      "fax": "+39 0438 900235",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "026027",
      "nome": "Follina",
      "nomeStraniero": null,
      "codiceCatastale": "D654",
      "cap": "31051",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "segreteria@comune.follina.tv.it",
      "pec": "segreteria@comunefollina.legalmail.it",
      "telefono": "+39 0438 9733204",
      "fax": "+39 0438 970008",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "026028",
      "nome": "Fontanelle",
      "nomeStraniero": null,
      "codiceCatastale": "D674",
      "cap": "31043",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.fontanelle.tv.it",
      "pec": "posta@pec.comune.fontanelle.tv.it",
      "telefono": "+39 0422 809157",
      "fax": "+39 0422 809138",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 12.466666221618652
      }
    },
    {
      "codice": "026029",
      "nome": "Fonte",
      "nomeStraniero": null,
      "codiceCatastale": "D680",
      "cap": "31010",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "tecnico@comune.fonte.tv.it",
      "pec": "protocollo.comune.fonte.tv@pecveneto.it",
      "telefono": "+39 0423 948278",
      "fax": "+39 0423 948561",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "026030",
      "nome": "Fregona",
      "nomeStraniero": null,
      "codiceCatastale": "D794",
      "cap": "31010",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "info@comune.fregona.tv.it",
      "pec": "info.comune.fregona.tv@pecveneto.it",
      "telefono": "+39 0438 916 810",
      "fax": "+39 0438 916 850",
      "coordinate": {
        "lat": 46.00021743774414,
        "lng": 12.33896255493164
      }
    },
    {
      "codice": "026031",
      "nome": "Gaiarine",
      "nomeStraniero": null,
      "codiceCatastale": "D854",
      "cap": "31018",
      "prefisso": "0434",
      "provincia": "Treviso",
      "email": "info@comune.gaiarine.tv.it",
      "pec": "gaiarine.tv@legalmail.it",
      "telefono": "+39 0434 756511",
      "fax": "+39 0434 756540",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 12.483333587646484
      }
    },
    {
      "codice": "026032",
      "nome": "Giavera del Montello",
      "nomeStraniero": null,
      "codiceCatastale": "E021",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ced@comune.giavera.tv.it",
      "pec": "segreteria@pec.comune.giavera.tv.it",
      "telefono": "+39 0422.775726",
      "fax": "+39 0422.775740",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 12.166666984558105
      }
    },
    {
      "codice": "026033",
      "nome": "Godega di Sant'Urbano",
      "nomeStraniero": null,
      "codiceCatastale": "E071",
      "cap": "31010",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "urbanistica@comunegodega.tv.it",
      "pec": "comunegodega@pec.it",
      "telefono": "+39 0438.430390",
      "fax": "+39 0438.430090",
      "coordinate": {
        "lat": 45.9285888671875,
        "lng": 12.396869659423828
      }
    },
    {
      "codice": "026034",
      "nome": "Gorgo al Monticano",
      "nomeStraniero": null,
      "codiceCatastale": "E092",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.gorgoalmonticano.tv.it",
      "pec": "comune.gorgoalmonticano.tv@pecveneto.it",
      "telefono": "+39 0422.800043",
      "fax": "+39 0422.800066",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 12.583333015441895
      }
    },
    {
      "codice": "026035",
      "nome": "Istrana",
      "nomeStraniero": null,
      "codiceCatastale": "E373",
      "cap": "31036",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.istrana.tv.it",
      "pec": "istrana@pec.comune.istrana.tv.it",
      "telefono": "+39 0422.831825",
      "fax": "+39 0422.831890",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "026036",
      "nome": "Loria",
      "nomeStraniero": null,
      "codiceCatastale": "E692",
      "cap": "31037",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "mariateresa.zen@comuneloria.it",
      "pec": "info@comuneloria.legalmail.it",
      "telefono": "+39 0423.456723",
      "fax": "+39 0423.456735",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 11.866666793823242
      }
    },
    {
      "codice": "026037",
      "nome": "Mansuè",
      "nomeStraniero": null,
      "codiceCatastale": "E893",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "protocollo@comune.mansue.tv.it",
      "pec": "comune.mansue.tv@pecveneto.it",
      "telefono": "+39 0422.204921",
      "fax": "+39 0422.741218",
      "coordinate": {
        "lat": 45.82320022583008,
        "lng": 12.535599708557129
      }
    },
    {
      "codice": "026038",
      "nome": "Mareno di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "E940",
      "cap": "31010",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "ufficiotecnico@comune.marenodipiave.tv.it",
      "pec": "comune.marenodipiave.tv@pecveneto.it",
      "telefono": "+39 0438.498834",
      "fax": "+39 0438.492190",
      "coordinate": {
        "lat": 45.84088134765625,
        "lng": 12.35200023651123
      }
    },
    {
      "codice": "026039",
      "nome": "Maser",
      "nomeStraniero": null,
      "codiceCatastale": "F009",
      "cap": "31010",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "ragioneria@comune.maser.tv.it",
      "pec": "comune.maser.tv@pecveneto.it",
      "telefono": "+39 0423.923174",
      "fax": "+39 0423.546155",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 11.983333587646484
      }
    },
    {
      "codice": "026040",
      "nome": "Maserada sul Piave",
      "nomeStraniero": null,
      "codiceCatastale": "F012",
      "cap": "31052",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.maserada.tv.it",
      "pec": "segreteria.comune.maserada.tv@pecveneto.it",
      "telefono": "+39 0422 778222",
      "fax": "+39 0422 877185",
      "coordinate": {
        "lat": 45.75,
        "lng": 12.316666603088379
      }
    },
    {
      "codice": "026041",
      "nome": "Meduna di Livenza",
      "nomeStraniero": null,
      "codiceCatastale": "F088",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.medunadilivenza.tv.it",
      "pec": "demografici.comune.medunadilivenza.tv@pecveneto.it",
      "telefono": "+39 0422.767001-767495",
      "fax": "+39 0422.767728",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "026042",
      "nome": "Miane",
      "nomeStraniero": null,
      "codiceCatastale": "F190",
      "cap": "31050",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "demografici@comunedimiane.it",
      "pec": "segreteria@comunedimiane.legalmail.it",
      "telefono": "+39 0438.899316",
      "fax": "+39 0438.960038",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "026043",
      "nome": "Mogliano Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "F269",
      "cap": "31021",
      "prefisso": "041",
      "provincia": "Treviso",
      "email": "protocollo@comune.mogliano-veneto.tv.it",
      "pec": "protocollo.comune.mogliano-veneto.tv@pecveneto.it",
      "telefono": "+390415930111",
      "fax": "+390415930299",
      "coordinate": {
        "lat": 45.56194305419922,
        "lng": 12.23638916015625
      }
    },
    {
      "codice": "026044",
      "nome": "Monastier di Treviso",
      "nomeStraniero": null,
      "codiceCatastale": "F332",
      "cap": "31050",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.monastier.tv.it",
      "pec": "ragioneria@comune.monastier.legalmail.it",
      "telefono": "+39 0422.798525",
      "fax": "+39 0422.791045",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 12.433333396911621
      }
    },
    {
      "codice": "026045",
      "nome": "Monfumo",
      "nomeStraniero": null,
      "codiceCatastale": "F360",
      "cap": "31010",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "anagrafe@comune.monfumo.tv.it",
      "pec": "comune.monfumo.tv@pecveneto.it",
      "telefono": "+39 0423.545068",
      "fax": "+39 0423.545060",
      "coordinate": {
        "lat": 45.83169937133789,
        "lng": 11.921299934387207
      }
    },
    {
      "codice": "026046",
      "nome": "Montebelluna",
      "nomeStraniero": null,
      "codiceCatastale": "F443",
      "cap": "31044",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "protocollo@comune.montebelluna.tv.it",
      "pec": "protocollo.comune.montebelluna.tv@pecveneto.it",
      "telefono": "+39 0423.617305",
      "fax": "+39 0423.617250",
      "coordinate": {
        "lat": 45.77527618408203,
        "lng": 12.038888931274414
      }
    },
    {
      "codice": "026047",
      "nome": "Morgano",
      "nomeStraniero": null,
      "codiceCatastale": "F725",
      "cap": "31050",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.morgano.tv.it",
      "pec": "segreteria.comune.morgano.tv@pecveneto.it",
      "telefono": "+39 0422.8377",
      "fax": "+39 0422.739573",
      "coordinate": {
        "lat": 45.633201599121094,
        "lng": 12.086700439453125
      }
    },
    {
      "codice": "026048",
      "nome": "Moriago della Battaglia",
      "nomeStraniero": null,
      "codiceCatastale": "F729",
      "cap": "31010",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "comune@comune.moriago.tv.it",
      "pec": "comune.moriago.tv@pecveneto.it",
      "telefono": "+39 0438.890841",
      "fax": "+39 0438.892803",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "026049",
      "nome": "Motta di Livenza",
      "nomeStraniero": null,
      "codiceCatastale": "F770",
      "cap": "31045",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ced@mottadilivenza.net",
      "pec": "comune.mottadilivenza.tv@pecveneto.it",
      "telefono": "+39 0422.761551",
      "fax": "+39 0422.861409",
      "coordinate": {
        "lat": 45.77972412109375,
        "lng": 12.608611106872559
      }
    },
    {
      "codice": "026050",
      "nome": "Nervesa della Battaglia",
      "nomeStraniero": null,
      "codiceCatastale": "F872",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.nervesa.tv.it",
      "pec": "segreteria.comune.nervesa.tv@pecveneto.it",
      "telefono": "+39 0422.886200",
      "fax": "+39 0422.773371",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 12.216666221618652
      }
    },
    {
      "codice": "026051",
      "nome": "Oderzo",
      "nomeStraniero": null,
      "codiceCatastale": "F999",
      "cap": "31046",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ced@comune.oderzo.tv.it",
      "pec": "protocollo.comune.oderzo.tv@pecveneto.it",
      "telefono": "+39 0422.812243",
      "fax": "+39 0422.815208",
      "coordinate": {
        "lat": 45.78083419799805,
        "lng": 12.492777824401855
      }
    },
    {
      "codice": "026052",
      "nome": "Ormelle",
      "nomeStraniero": null,
      "codiceCatastale": "G115",
      "cap": "31024",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.ormelle.tv.it",
      "pec": "amministrativo@pec.comune.ormelle.tv.it",
      "telefono": "+39 0422.745017",
      "fax": "+39 0422.805229",
      "coordinate": {
        "lat": 45.780277252197266,
        "lng": 12.419722557067871
      }
    },
    {
      "codice": "026053",
      "nome": "Orsago",
      "nomeStraniero": null,
      "codiceCatastale": "G123",
      "cap": "31010",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "info.orsago@comune.orsago.tv.it",
      "pec": "info.orsago@postecert.it",
      "telefono": "+39 0438993524",
      "fax": "+39 0438993570",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 12.416666984558105
      }
    },
    {
      "codice": "026055",
      "nome": "Paese",
      "nomeStraniero": null,
      "codiceCatastale": "G229",
      "cap": "31038",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ced@comunedipaese.it",
      "pec": "posta.comune.paese.tv@pecveneto.it",
      "telefono": "+39 0422.457711",
      "fax": "+39 0422.457710",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 12.149999618530273
      }
    },
    {
      "codice": "026056",
      "nome": "Pederobba",
      "nomeStraniero": null,
      "codiceCatastale": "G408",
      "cap": "31040",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "demografici@comune.pederobba.tv.it",
      "pec": "protocollo.comune.pederobba@bepec.it",
      "telefono": "+39 0423.680902",
      "fax": "+39 0423.64185",
      "coordinate": {
        "lat": 45.8738899230957,
        "lng": 11.947500228881836
      }
    },
    {
      "codice": "026096",
      "nome": "Pieve del Grappa",
      "nomeStraniero": null,
      "codiceCatastale": "M422",
      "cap": "31017",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": null,
      "pec": "comune.pievedelgrappa.tv@pecveneto.it",
      "telefono": "0423/0789",
      "fax": "0423/078999",
      "coordinate": {
        "lat": 45.83505630493164,
        "lng": 11.848472595214844
      }
    },
    {
      "codice": "026057",
      "nome": "Pieve di Soligo",
      "nomeStraniero": null,
      "codiceCatastale": "G645",
      "cap": "31053",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "ced@comunepievedisoligo.it",
      "pec": "segreteria@pec.comune.pievedisoligo.tv.it",
      "telefono": "+39 0438.985321",
      "fax": "+39 0438.985300",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 12.166666984558105
      }
    },
    {
      "codice": "026058",
      "nome": "Ponte di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "G846",
      "cap": "31047",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "demografici@pontedipiave.com",
      "pec": "protocollo.comune.pontedipiave.tv@pecveneto.it",
      "telefono": "+39 0422.858906",
      "fax": "+39 0422.858921",
      "coordinate": {
        "lat": 45.71666717529297,
        "lng": 12.466666221618652
      }
    },
    {
      "codice": "026059",
      "nome": "Ponzano Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "G875",
      "cap": "31050",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.ponzanoveneto.tv.it",
      "pec": "comune.ponzanoveneto.tv@pecveneto.it",
      "telefono": "+39 0422.960300",
      "fax": "+39 0422.960341",
      "coordinate": {
        "lat": 45.72385025024414,
        "lng": 12.194217681884766
      }
    },
    {
      "codice": "026060",
      "nome": "Portobuffolè",
      "nomeStraniero": null,
      "codiceCatastale": "G909",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "portobuffole@tin.it",
      "pec": "protocollo.comune.portobuffole.tv@pecveneto.it",
      "telefono": "+39 0422 850020",
      "fax": "+39 0422 850267",
      "coordinate": {
        "lat": 45.85361099243164,
        "lng": 12.53833293914795
      }
    },
    {
      "codice": "026061",
      "nome": "Possagno",
      "nomeStraniero": null,
      "codiceCatastale": "G933",
      "cap": "31054",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "segreteria@comune.possagno.tv.it",
      "pec": "comune.possagno.tv@pecveneto.it",
      "telefono": "+39 0423.922711",
      "fax": "+39 0423.922799",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "026062",
      "nome": "Povegliano",
      "nomeStraniero": null,
      "codiceCatastale": "G944",
      "cap": "31050",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "protocollo@comune.povegliano.tv.it",
      "pec": "protocollo.comune.povegliano.tv@pecveneto.it",
      "telefono": "+39 0422.871111",
      "fax": "+39 0422.871116",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 12.199999809265137
      }
    },
    {
      "codice": "026063",
      "nome": "Preganziol",
      "nomeStraniero": null,
      "codiceCatastale": "H022",
      "cap": "31022",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ced@comune.preganziol.tv.it",
      "pec": "protocollo.comune.preganziol.tv@pecveneto.it",
      "telefono": "+39 0422.632200",
      "fax": "+39 0422.632274",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 12.233333587646484
      }
    },
    {
      "codice": "026064",
      "nome": "Quinto di Treviso",
      "nomeStraniero": null,
      "codiceCatastale": "H131",
      "cap": "31055",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ragioneria@comune.quintoditreviso.tv.it",
      "pec": "comune.quintoditreviso.tv@pecveneto.it",
      "telefono": "+39 0422.472388",
      "fax": "+39 0422.472347",
      "coordinate": {
        "lat": 45.650001525878906,
        "lng": 12.166666984558105
      }
    },
    {
      "codice": "026065",
      "nome": "Refrontolo",
      "nomeStraniero": null,
      "codiceCatastale": "H220",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "tecnico@comunedirefrontolo.it",
      "pec": "protocollo.comune.refrontolo.tv@pecveneto.it",
      "telefono": "+39 0438.978103",
      "fax": "+39 0438.978126",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 12.199999809265137
      }
    },
    {
      "codice": "026066",
      "nome": "Resana",
      "nomeStraniero": null,
      "codiceCatastale": "H238",
      "cap": "31023",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "comune@comune.resana.tv.it",
      "pec": "protocollo.comune.resana.tv@pecveneto.it",
      "telefono": "+39 0423.717311",
      "fax": "+39 0423.717355",
      "coordinate": {
        "lat": 45.63333511352539,
        "lng": 11.949999809265137
      }
    },
    {
      "codice": "026067",
      "nome": "Revine Lago",
      "nomeStraniero": null,
      "codiceCatastale": "H253",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "demografici@comune.revine-lago.tv.it",
      "pec": "comune.revine-lago.tv@pecveneto.it",
      "telefono": "+39 0438.929720",
      "fax": "+39 0438.929065",
      "coordinate": {
        "lat": 46.001033782958984,
        "lng": 12.22554874420166
      }
    },
    {
      "codice": "026068",
      "nome": "Riese Pio X",
      "nomeStraniero": null,
      "codiceCatastale": "H280",
      "cap": "31039",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "demografici@comune.riesepiox.tv.it",
      "pec": "anagrafe.comune.riesepiox.tv@pecveneto.it",
      "telefono": "+39 0423.753130",
      "fax": "+39 0423.753139",
      "coordinate": {
        "lat": 45.72990036010742,
        "lng": 11.920000076293945
      }
    },
    {
      "codice": "026069",
      "nome": "Roncade",
      "nomeStraniero": null,
      "codiceCatastale": "H523",
      "cap": "31056",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.roncade.tv.it",
      "pec": "roncade@comune.roncade.legalmail.it",
      "telefono": "+39 0422.8461",
      "fax": "+39 0422.840597",
      "coordinate": {
        "lat": 45.62459945678711,
        "lng": 12.37660026550293
      }
    },
    {
      "codice": "026070",
      "nome": "Salgareda",
      "nomeStraniero": null,
      "codiceCatastale": "H706",
      "cap": "31040",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.salgareda.tv.it",
      "pec": "comune.salgareda.tv@pecveneto.it",
      "telefono": "+39 0422747034",
      "fax": "+39 0422.807761",
      "coordinate": {
        "lat": 45.70610046386719,
        "lng": 12.491299629211426
      }
    },
    {
      "codice": "026071",
      "nome": "San Biagio di Callalta",
      "nomeStraniero": null,
      "codiceCatastale": "H781",
      "cap": "31048",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "ced@comune.sanbiagio.tv.it",
      "pec": "protocollo.comune.sanbiagio.tv@pecveneto.it",
      "telefono": "+39 0422.894381",
      "fax": "+39 0422.894309",
      "coordinate": {
        "lat": 45.68669891357422,
        "lng": 12.381500244140625
      }
    },
    {
      "codice": "026072",
      "nome": "San Fior",
      "nomeStraniero": null,
      "codiceCatastale": "H843",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "poliziamunicipale@comune.san-fior.tv.it",
      "pec": "comune.sanfior.tv@pecveneto.it",
      "telefono": "+39 0438.260276",
      "fax": "+39 0438.260308",
      "coordinate": {
        "lat": 45.92219161987305,
        "lng": 12.361370086669922
      }
    },
    {
      "codice": "026073",
      "nome": "San Pietro di Feletto",
      "nomeStraniero": null,
      "codiceCatastale": "I103",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "statocivile@comune.sanpietrodifeletto.tv.it",
      "pec": "segreteria.comune.sanpietrodifeletto.tv@pecveneto.it",
      "telefono": "+39 0438.486531",
      "fax": "+39 0438.486009",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 12.25
      }
    },
    {
      "codice": "026074",
      "nome": "San Polo di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "I124",
      "cap": "31020",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "info@comune.san-polo-di-piave.tv.it",
      "pec": "comune.sanpolodipiave@legalmail.it",
      "telefono": "+39 0422.855106",
      "fax": "+39 0422.855760",
      "coordinate": {
        "lat": 45.79055404663086,
        "lng": 12.394721984863281
      }
    },
    {
      "codice": "026076",
      "nome": "San Vendemiano",
      "nomeStraniero": null,
      "codiceCatastale": "I382",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "info@comune.sanvendemiano.tv.it",
      "pec": "comune.sanvendemiano.tv@pecveneto.it",
      "telefono": "+39 0438.401741",
      "fax": "+39 0438.401780",
      "coordinate": {
        "lat": 45.891387939453125,
        "lng": 12.338889122009277
      }
    },
    {
      "codice": "026077",
      "nome": "San Zenone degli Ezzelini",
      "nomeStraniero": null,
      "codiceCatastale": "I417",
      "cap": "31020",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "segretario@comune.san-zenone.tv.it",
      "pec": "protocollo.comune.sanzenonede.tv@pecveneto.it",
      "telefono": "+39 0423968478",
      "fax": "+39 0423567840",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "026075",
      "nome": "Santa Lucia di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "I221",
      "cap": "31025",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "ced@comunesantalucia.it",
      "pec": "comune.santaluciadipiave.tv@pecveneto.it",
      "telefono": "+39 0438.466154",
      "fax": "+39 0438.466190",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 12.283333778381348
      }
    },
    {
      "codice": "026078",
      "nome": "Sarmede",
      "nomeStraniero": null,
      "codiceCatastale": "I435",
      "cap": "31026",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "urbanistica@comune.sarmede.tv.it",
      "pec": "comune.sarmede@pec.it",
      "telefono": "+39 0438.582720",
      "fax": "+39 0438.582780",
      "coordinate": {
        "lat": 45.977500915527344,
        "lng": 12.38527774810791
      }
    },
    {
      "codice": "026079",
      "nome": "Segusino",
      "nomeStraniero": null,
      "codiceCatastale": "I578",
      "cap": "31040",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "urp@comune.segusino.tv.it",
      "pec": "comune.segusino@halleypec.it",
      "telefono": "+39 0423-984411",
      "fax": "+39 0423-984444",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 11.949999809265137
      }
    },
    {
      "codice": "026080",
      "nome": "Sernaglia della Battaglia",
      "nomeStraniero": null,
      "codiceCatastale": "I635",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "lavoripubblici@comune.sernaglia.tv.it",
      "pec": "sernaglia@cert.legalmail.it",
      "telefono": "+39 0438.965346",
      "fax": "+39 0438.965363",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 12.133333206176758
      }
    },
    {
      "codice": "026081",
      "nome": "Silea",
      "nomeStraniero": null,
      "codiceCatastale": "F116",
      "cap": "31057",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.silea.tv.it",
      "pec": "protocollo@comune.silea.legalmail.it",
      "telefono": "+39 0422.365721",
      "fax": "+39 0422.460483",
      "coordinate": {
        "lat": 45.65472412109375,
        "lng": 12.296667098999023
      }
    },
    {
      "codice": "026082",
      "nome": "Spresiano",
      "nomeStraniero": null,
      "codiceCatastale": "I927",
      "cap": "31027",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comune.spresiano.tv.it",
      "pec": "comune.spresiano.tv@pecveneto.it",
      "telefono": "+39 0422.723417",
      "fax": "+39 0422.881885",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 12.25
      }
    },
    {
      "codice": "026083",
      "nome": "Susegana",
      "nomeStraniero": null,
      "codiceCatastale": "L014",
      "cap": "31058",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "protocollo@comune.susegana.tv.it",
      "pec": "comune.susegana.tv@pecveneto.it",
      "telefono": "+39 0438.437430",
      "fax": "+39 0438.73749",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 12.25
      }
    },
    {
      "codice": "026084",
      "nome": "Tarzo",
      "nomeStraniero": null,
      "codiceCatastale": "L058",
      "cap": "31020",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "segreteria@comune.tarzo.tv.it",
      "pec": "protocollo.comune.tarzo.tv@pecveneto.it",
      "telefono": "+39 0438.9264218",
      "fax": "+39 0438.9264000",
      "coordinate": {
        "lat": 45.97249984741211,
        "lng": 12.233888626098633
      }
    },
    {
      "codice": "026085",
      "nome": "Trevignano",
      "nomeStraniero": null,
      "codiceCatastale": "L402",
      "cap": "31040",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "mario.damian@comune.trevignano.tv.it",
      "pec": "protocollo.trevignano@legalmail.it",
      "telefono": "+39 0423.672835",
      "fax": "+39 0423.672836",
      "coordinate": {
        "lat": 45.73350143432617,
        "lng": 12.094016075134277
      }
    },
    {
      "codice": "026086",
      "nome": "Treviso",
      "nomeStraniero": null,
      "codiceCatastale": "L407",
      "cap": "31100",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "protocollo@comune.treviso.it",
      "pec": "postacertificata@cert.comune.treviso.it",
      "telefono": "+39 0422.6581",
      "fax": "+39 0422.658201",
      "coordinate": {
        "lat": 45.672218322753906,
        "lng": 12.242218971252441
      }
    },
    {
      "codice": "026087",
      "nome": "Valdobbiadene",
      "nomeStraniero": null,
      "codiceCatastale": "L565",
      "cap": "31049",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "ced@comune.valdobbiadene.tv.it",
      "pec": "comune.valdobbiadene.tv@pecveneto.it",
      "telefono": "+39 0423.976810",
      "fax": "+39 0423.976888",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "026088",
      "nome": "Vazzola",
      "nomeStraniero": null,
      "codiceCatastale": "L700",
      "cap": "31028",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "info@comune.vazzola.tv.it",
      "pec": "comune.vazzola.tv@pecveneto.it",
      "telefono": "+39 0438.440853",
      "fax": "+39 0438.440812",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 12.383333206176758
      }
    },
    {
      "codice": "026089",
      "nome": "Vedelago",
      "nomeStraniero": null,
      "codiceCatastale": "L706",
      "cap": "31050",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "ced@comune.vedelago.tv.it",
      "pec": "comune.vedelago.tv@pecveneto.it",
      "telefono": "+39 0423.702875",
      "fax": "+39 0423.401242",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 12.016666412353516
      }
    },
    {
      "codice": "026090",
      "nome": "Vidor",
      "nomeStraniero": null,
      "codiceCatastale": "L856",
      "cap": "31020",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "demografici@comune.vidor.tv.it",
      "pec": "comunevidor.tv@legalmail.it",
      "telefono": "+39 0423.986411",
      "fax": "+39 0423.986415",
      "coordinate": {
        "lat": 45.866668701171875,
        "lng": 12.033332824707031
      }
    },
    {
      "codice": "026091",
      "nome": "Villorba",
      "nomeStraniero": null,
      "codiceCatastale": "M048",
      "cap": "31020",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "mail@comune.villorba.tv.it",
      "pec": "protocollo.comune.villorba.tv@pecveneto.it",
      "telefono": "+39 0422.6178",
      "fax": "+39 0422.61799",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 12.233333587646484
      }
    },
    {
      "codice": "026092",
      "nome": "Vittorio Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "M089",
      "cap": "31029",
      "prefisso": "0438",
      "provincia": "Treviso",
      "email": "rgava@comune.vittorio-veneto.tv.it",
      "pec": "pec.comune.vittorioveneto.tv@pecveneto.it",
      "telefono": "+39 0438.569229",
      "fax": "+39 0438.569402",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "026093",
      "nome": "Volpago del Montello",
      "nomeStraniero": null,
      "codiceCatastale": "M118",
      "cap": "31040",
      "prefisso": "0423",
      "provincia": "Treviso",
      "email": "anagrafe@comune.volpago-del-montello.tv.it",
      "pec": "volpago@pec.comunevolpago.it",
      "telefono": "+39 0423.873410",
      "fax": "+39 0423.621482",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "026094",
      "nome": "Zenson di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "M163",
      "cap": "31050",
      "prefisso": "0421",
      "provincia": "Treviso",
      "email": "segreteria@comune.zensondipiave.tv.it",
      "pec": "comune.zensondipiave.tv@pecveneto.it",
      "telefono": "+39 0421.344164",
      "fax": "+39 0421.344490",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 12.5
      }
    },
    {
      "codice": "026095",
      "nome": "Zero Branco",
      "nomeStraniero": null,
      "codiceCatastale": "M171",
      "cap": "31059",
      "prefisso": "0422",
      "provincia": "Treviso",
      "email": "segreteria@comunezerobranco.it",
      "pec": "legalmail@pec.comunezerobranco.it",
      "telefono": "+39 0422 485455",
      "fax": "+39 0422 485434",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 12.166666984558105
      }
    }
  ],
  "Bologna": [
    {
      "codice": "037062",
      "nome": "Alto Reno Terme",
      "nomeStraniero": null,
      "codiceCatastale": "M369",
      "cap": "40046",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": null,
      "pec": "comune.altorenoterme@cert.cittametropolitana.bo.it",
      "telefono": "+39 0534 521111",
      "fax": "+39 0534 24440",
      "coordinate": {
        "lat": 44.1602783203125,
        "lng": 10.973333358764648
      }
    },
    {
      "codice": "037001",
      "nome": "Anzola dell'Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "A324",
      "cap": "40011",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "comune@comune.anzoladellemilia.bo.it",
      "pec": "comune.anzoladellemilia@cert.provincia.bo.it",
      "telefono": "+39 051/6502111",
      "fax": "+39 051/731598",
      "coordinate": {
        "lat": 44.54722213745117,
        "lng": 11.195555686950684
      }
    },
    {
      "codice": "037002",
      "nome": "Argelato",
      "nomeStraniero": null,
      "codiceCatastale": "A392",
      "cap": "40050",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": null,
      "pec": "comune.argelato@pec.renogalliera.it",
      "telefono": "+39 051/6634611",
      "fax": "+39 051/893510",
      "coordinate": {
        "lat": 44.64250183105469,
        "lng": 11.348610877990723
      }
    },
    {
      "codice": "037003",
      "nome": "Baricella",
      "nomeStraniero": null,
      "codiceCatastale": "A665",
      "cap": "40052",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "claudia.melloni@comune.baricella.bo.it",
      "pec": "comune.baricella@cert.provincia.bo.it",
      "telefono": "+39 051/6622411",
      "fax": "+39 051 873399",
      "coordinate": {
        "lat": 44.647499084472656,
        "lng": 11.535277366638184
      }
    },
    {
      "codice": "037005",
      "nome": "Bentivoglio",
      "nomeStraniero": null,
      "codiceCatastale": "A785",
      "cap": "40010",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "e.tonelli@renogalliera.it",
      "pec": "comune.bentivoglio@cert.provincia.bo.it",
      "telefono": "+39 051/6634642",
      "fax": "+39 051/7417283",
      "coordinate": {
        "lat": 44.635276794433594,
        "lng": 11.418889045715332
      }
    },
    {
      "codice": "037006",
      "nome": "Bologna",
      "nomeStraniero": null,
      "codiceCatastale": "A944",
      "cap": "40131",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "marco.zaniboni@comune.bologna.it",
      "pec": "protocollogenerale@pec.comune.bologna.it",
      "telefono": "+39 051/2193546",
      "fax": "+39 051/2193737",
      "coordinate": {
        "lat": 44.49388885498047,
        "lng": 11.342778205871582
      }
    },
    {
      "codice": "037007",
      "nome": "Borgo Tossignano",
      "nomeStraniero": null,
      "codiceCatastale": "B044",
      "cap": "40021",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "urp@comune.borgotossignano.bo.it",
      "pec": "comune.borgotossignano@cert.provincia.bo.it",
      "telefono": "+39 0542/91111",
      "fax": "+39 0542/94066",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 11.583333015441895
      }
    },
    {
      "codice": "037008",
      "nome": "Budrio",
      "nomeStraniero": null,
      "codiceCatastale": "B249",
      "cap": "40054",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "ced@comune.budrio.bo.it",
      "pec": "comune.budrio@cert.provincia.bo.it",
      "telefono": "+39 051/6928247",
      "fax": "+39 051/808106",
      "coordinate": {
        "lat": 44.53744888305664,
        "lng": 11.534390449523926
      }
    },
    {
      "codice": "037009",
      "nome": "Calderara di Reno",
      "nomeStraniero": null,
      "codiceCatastale": "B399",
      "cap": "40012",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "comune.calderara@cert.provincia.bo.it",
      "pec": "comune.calderara@cert.provincia.bo.it",
      "telefono": "+39 051/6461111",
      "fax": "+39 051/722186",
      "coordinate": {
        "lat": 44.56388854980469,
        "lng": 11.271944046020508
      }
    },
    {
      "codice": "037010",
      "nome": "Camugnano",
      "nomeStraniero": null,
      "codiceCatastale": "B572",
      "cap": "40032",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": "ut@comune.camugnano.bo.it",
      "pec": "comune.camugnano@cert.provincia.bo.it",
      "telefono": "+39 0534/41710",
      "fax": "+39 0534/46037",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "037011",
      "nome": "Casalecchio di Reno",
      "nomeStraniero": null,
      "codiceCatastale": "B880",
      "cap": "40033",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "amarchetti@comune.casalecchio.bo.it",
      "pec": "comune.casalecchio@cert.provincia.bo.it",
      "telefono": "+39 051/598168",
      "fax": "+39 051/598200",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "037012",
      "nome": "Casalfiumanese",
      "nomeStraniero": null,
      "codiceCatastale": "B892",
      "cap": "40020",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "anagrafe@casalfiumanese.provincia.bologna.it",
      "pec": "comune.casalfiumanese@cert.provincia.bo.it",
      "telefono": "+39 0542/668073",
      "fax": "+39 0542/666251",
      "coordinate": {
        "lat": 44.29999923706055,
        "lng": 11.633333206176758
      }
    },
    {
      "codice": "037013",
      "nome": "Castel d'Aiano",
      "nomeStraniero": null,
      "codiceCatastale": "C075",
      "cap": "40034",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "segreteria@comune.casteldaiano.bo.it",
      "pec": "comune.casteldaiano@cert.provincia.bo.it",
      "telefono": "+39 051/6735721/22/11",
      "fax": "+39 051/914818",
      "coordinate": {
        "lat": 44.276668548583984,
        "lng": 11.000277519226074
      }
    },
    {
      "codice": "037014",
      "nome": "Castel del Rio",
      "nomeStraniero": null,
      "codiceCatastale": "C086",
      "cap": "40022",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "urp@casteldelrio.provincia.bologna.it",
      "pec": "comune.casteldelrio@cert.provincia.bo.it",
      "telefono": "+39 0542/95906",
      "fax": "+39 0542/95313",
      "coordinate": {
        "lat": 44.213890075683594,
        "lng": 11.504166603088379
      }
    },
    {
      "codice": "037015",
      "nome": "Castel di Casio",
      "nomeStraniero": null,
      "codiceCatastale": "B969",
      "cap": "40030",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": "segret.casio@cosea.bo.it",
      "pec": "comune.casteldicasio@cert.provincia.bo.it",
      "telefono": "+39 0534/44133",
      "fax": "+39 0534/44286",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "037016",
      "nome": "Castel Guelfo di Bologna",
      "nomeStraniero": null,
      "codiceCatastale": "C121",
      "cap": "40023",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "urp@comune.castelguelfo.bo.it",
      "pec": "comune.castelguelfo@cert.provincia.bo.it",
      "telefono": "+39 0542/639211",
      "fax": "+39 0542/639222",
      "coordinate": {
        "lat": 44.43333435058594,
        "lng": 11.683333396911621
      }
    },
    {
      "codice": "037019",
      "nome": "Castel Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C204",
      "cap": "40013",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "protocollo@comune.castel-maggiore.bo.it",
      "pec": "comune.castelmaggiore@cert.provincia.bo.it",
      "telefono": "+39 051/6386771",
      "fax": "+39 051/715532",
      "coordinate": {
        "lat": 44.57777786254883,
        "lng": 11.361666679382324
      }
    },
    {
      "codice": "037020",
      "nome": "Castel San Pietro Terme",
      "nomeStraniero": null,
      "codiceCatastale": "C265",
      "cap": "40024",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "protocollo@comune.castelsanpietroterme.bo.it",
      "pec": "comune.castelsanpietro@cert.provincia.bo.it",
      "telefono": "+39 051/6954154",
      "fax": "+39 051/6954141",
      "coordinate": {
        "lat": 44.39777755737305,
        "lng": 11.589444160461426
      }
    },
    {
      "codice": "037017",
      "nome": "Castello d'Argile",
      "nomeStraniero": null,
      "codiceCatastale": "C185",
      "cap": "40050",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "e.tonelli@renogalliera.it",
      "pec": "comune.castello-d-argile@cert.provincia.bo.it",
      "telefono": "+39 051/6634642",
      "fax": "+39 051/976452",
      "coordinate": {
        "lat": 44.68111038208008,
        "lng": 11.296667098999023
      }
    },
    {
      "codice": "037021",
      "nome": "Castenaso",
      "nomeStraniero": null,
      "codiceCatastale": "C292",
      "cap": "40050",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "info@comune.castenaso.bo.it",
      "pec": "comune.castenaso@cert.provincia.bo.it",
      "telefono": "+39 051/6059111",
      "fax": "+39 051/789417-6059292",
      "coordinate": {
        "lat": 44.50972366333008,
        "lng": 11.470555305480957
      }
    },
    {
      "codice": "037022",
      "nome": "Castiglione dei Pepoli",
      "nomeStraniero": null,
      "codiceCatastale": "C296",
      "cap": "40035",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": "protocollo@comune.castiglionedeipepoli.bo.it",
      "pec": "comune.castiglionedeipepoli@cert.provincia.bo.it",
      "telefono": "+39 0534/801611",
      "fax": "+39 0534/801700",
      "coordinate": {
        "lat": 44.150001525878906,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "037024",
      "nome": "Crevalcore",
      "nomeStraniero": null,
      "codiceCatastale": "D166",
      "cap": "40014",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "urp@comune.crevalcore.bo.it",
      "pec": "comune.crevalcore@cert.provincia.bo.it",
      "telefono": "+39 051/988311",
      "fax": "+39 051/980938",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "037025",
      "nome": "Dozza",
      "nomeStraniero": null,
      "codiceCatastale": "D360",
      "cap": "40060",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "elettorale@comune.dozza.bo.it",
      "pec": "comune.dozza@cert.provincia.bo.it",
      "telefono": "+39 0542/672408",
      "fax": "+39 0542/672293",
      "coordinate": {
        "lat": 44.358985900878906,
        "lng": 11.627690315246582
      }
    },
    {
      "codice": "037026",
      "nome": "Fontanelice",
      "nomeStraniero": null,
      "codiceCatastale": "D668",
      "cap": "40025",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "urp@fontanelice.provincia.bologna.it",
      "pec": "comune.fontanelice@cert.provincia.bo.it",
      "telefono": "+39 054292566",
      "fax": "+39 054292276",
      "coordinate": {
        "lat": 44.266666412353516,
        "lng": 11.566666603088379
      }
    },
    {
      "codice": "037027",
      "nome": "Gaggio Montano",
      "nomeStraniero": null,
      "codiceCatastale": "D847",
      "cap": "40041",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": "demogr.gaggio@cosea.bo.it",
      "pec": "comune.gaggiomontano@cert.provincia.bo.it",
      "telefono": "+39 0534/37439",
      "fax": "+39 0534/38514",
      "coordinate": {
        "lat": 44.20000076293945,
        "lng": 10.933333396911621
      }
    },
    {
      "codice": "037028",
      "nome": "Galliera",
      "nomeStraniero": null,
      "codiceCatastale": "D878",
      "cap": "40015",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "informatica@renogalliera.it",
      "pec": "comune.galliera@cert.provincia.bo.it",
      "telefono": "+39 051/6634642",
      "fax": "+39 051/7417283",
      "coordinate": {
        "lat": 44.75,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "037030",
      "nome": "Granarolo dell'Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "E136",
      "cap": "40057",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "ced@comune.granarolo-dellemilia.bo.it",
      "pec": "comune.granarolodellemilia@cert.provincia.bo.it",
      "telefono": "+39 051/6004101",
      "fax": "+39 051/6004333",
      "coordinate": {
        "lat": 44.55416488647461,
        "lng": 11.443888664245605
      }
    },
    {
      "codice": "037031",
      "nome": "Grizzana Morandi",
      "nomeStraniero": null,
      "codiceCatastale": "E187",
      "cap": "40030",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "lavoripubblici@comune.grizzanamorandi.bo.it",
      "pec": "comune.grizzanamorandi@cert.provincia.bo.it",
      "telefono": "+39 051/6730315",
      "fax": "+39 051/913014",
      "coordinate": {
        "lat": 44.25,
        "lng": 11.149999618530273
      }
    },
    {
      "codice": "037032",
      "nome": "Imola",
      "nomeStraniero": null,
      "codiceCatastale": "E289",
      "cap": "40026",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "laghi.w@comune.imola.bo.it",
      "pec": "comune.imola@cert.provincia.bo.it",
      "telefono": "+39 0542/602247",
      "fax": "+39 0542/602289",
      "coordinate": {
        "lat": 44.35305404663086,
        "lng": 11.714722633361816
      }
    },
    {
      "codice": "037033",
      "nome": "Lizzano in Belvedere",
      "nomeStraniero": null,
      "codiceCatastale": "A771",
      "cap": "40042",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": "sindaco@comune.lizzano.bo.it",
      "pec": "comune.lizzanoinbelvedere@cert.provincia.bo.it",
      "telefono": "+39 0534 51024",
      "fax": "+39 0534 51536",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 10.899999618530273
      }
    },
    {
      "codice": "037034",
      "nome": "Loiano",
      "nomeStraniero": null,
      "codiceCatastale": "E655",
      "cap": "40050",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "nassetti@comune.loiano.bologna.it",
      "pec": "comune.loiano@cert.provincia.bo.it",
      "telefono": "+39 051/6543615",
      "fax": "+39 051/6545246",
      "coordinate": {
        "lat": 44.266666412353516,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "037035",
      "nome": "Malalbergo",
      "nomeStraniero": null,
      "codiceCatastale": "E844",
      "cap": "40051",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "irene.tugnoli@comune.malalbergo.bo.it",
      "pec": "comune.malalbergo@cert.provincia.bo.it",
      "telefono": "+39 051/6620244",
      "fax": "+39 051/6620243",
      "coordinate": {
        "lat": 44.719444274902344,
        "lng": 11.533055305480957
      }
    },
    {
      "codice": "037036",
      "nome": "Marzabotto",
      "nomeStraniero": null,
      "codiceCatastale": "B689",
      "cap": "40043",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "ambiente@comune.marzabotto.bo.it",
      "pec": "comune.marzabotto@cert.provincia.bo.it",
      "telefono": "+39 051/6780540",
      "fax": "+39 051/931350",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "037037",
      "nome": "Medicina",
      "nomeStraniero": null,
      "codiceCatastale": "F083",
      "cap": "40059",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "urp@comune.medicina.bo.it",
      "pec": "comune.medicina@cert.provincia.bo.it",
      "telefono": "+39 0516979111",
      "fax": "+39 0516979222",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 11.633333206176758
      }
    },
    {
      "codice": "037038",
      "nome": "Minerbio",
      "nomeStraniero": null,
      "codiceCatastale": "F219",
      "cap": "40061",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "urp@comune.minerbio.bo.it",
      "pec": "comune.minerbio@cert.provincia.bo.it",
      "telefono": "+39 051/6611739",
      "fax": "+39 051/6612152",
      "coordinate": {
        "lat": 44.61750030517578,
        "lng": 11.47166633605957
      }
    },
    {
      "codice": "037039",
      "nome": "Molinella",
      "nomeStraniero": null,
      "codiceCatastale": "F288",
      "cap": "40062",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "elettorale@comune.molinella.bo.it",
      "pec": "comune.molinella@cert.provincia.bo.it",
      "telefono": "+39 051/6906845",
      "fax": "+39 051/6906847",
      "coordinate": {
        "lat": 44.61666488647461,
        "lng": 11.666666984558105
      }
    },
    {
      "codice": "037040",
      "nome": "Monghidoro",
      "nomeStraniero": null,
      "codiceCatastale": "F363",
      "cap": "40063",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "sindaco@comune.monghidoro.bo.it",
      "pec": "comune.monghidoro@cert.provincia.bo.it",
      "telefono": "+39 051 6555639",
      "fax": "+39 0516555520",
      "coordinate": {
        "lat": 44.21666717529297,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "037042",
      "nome": "Monte San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "F627",
      "cap": "40050",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "demografici@comune.montesanpietro.bo.it",
      "pec": "comune.montesanpietro@cert.provincia.bo.it",
      "telefono": "+39 051/6764415",
      "fax": "+39 051/6764455",
      "coordinate": {
        "lat": 44.457847595214844,
        "lng": 11.199030876159668
      }
    },
    {
      "codice": "037041",
      "nome": "Monterenzio",
      "nomeStraniero": null,
      "codiceCatastale": "F597",
      "cap": "40050",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "elettorale@comune.monterenzio.bologna.it",
      "pec": "comune.monterenzio@cert.provincia.bo.it",
      "telefono": "+39 051/929327",
      "fax": "+39 051/6548407",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "037044",
      "nome": "Monzuno",
      "nomeStraniero": null,
      "codiceCatastale": "F706",
      "cap": "40036",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "mmilani@comune.monzuno.bologna.it",
      "pec": "comune.monzuno@cert.provincia.bo.it",
      "telefono": "+39 051/6773315",
      "fax": "+39 051/6771685",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 11.266666412353516
      }
    },
    {
      "codice": "037045",
      "nome": "Mordano",
      "nomeStraniero": null,
      "codiceCatastale": "F718",
      "cap": "40027",
      "prefisso": "0542",
      "provincia": "Bologna",
      "email": "l.valentini@mordano.provincia.bologna.it",
      "pec": "comune.mordano@cert.provincia.bo.it",
      "telefono": "+39 0542/56929",
      "fax": "+39 0542/56900",
      "coordinate": {
        "lat": 44.400001525878906,
        "lng": 11.816666603088379
      }
    },
    {
      "codice": "037046",
      "nome": "Ozzano dell'Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "G205",
      "cap": "8604",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "alessandro.antico@comune.ozzano.bo.it",
      "pec": "comune.ozzano@cert.provincia.bo.it",
      "telefono": "+39 051/791313",
      "fax": "+39 051/797951",
      "coordinate": {
        "lat": 44.45000076293945,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "037047",
      "nome": "Pianoro",
      "nomeStraniero": null,
      "codiceCatastale": "G570",
      "cap": "40060",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "segreteria@comune.pianoro.bo.it",
      "pec": "comune.pianoro@cert.provincia.bo.it",
      "telefono": "+39 051/6529111",
      "fax": "+39 051/774299",
      "coordinate": {
        "lat": 44.38333511352539,
        "lng": 11.333333015441895
      }
    },
    {
      "codice": "037048",
      "nome": "Pieve di Cento",
      "nomeStraniero": null,
      "codiceCatastale": "G643",
      "cap": "40066",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "comune.pievedicento@cert.provincia.bo.it",
      "pec": "comune.pievedicento@cert.provincia.bo.it",
      "telefono": "+39 051 6862 611",
      "fax": "+39 051 974308",
      "coordinate": {
        "lat": 44.71305465698242,
        "lng": 11.308333396911621
      }
    },
    {
      "codice": "037050",
      "nome": "Sala Bolognese",
      "nomeStraniero": null,
      "codiceCatastale": "H678",
      "cap": "40010",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "comune@comune.sala-bolognese.bo.it",
      "pec": "comune.salabolognese@cert.provincia.bo.it",
      "telefono": "+39 051/6822511",
      "fax": "+39 051/829182",
      "coordinate": {
        "lat": 44.630279541015625,
        "lng": 11.276110649108887
      }
    },
    {
      "codice": "037051",
      "nome": "San Benedetto Val di Sambro",
      "nomeStraniero": null,
      "codiceCatastale": "G566",
      "cap": "40048",
      "prefisso": "0534",
      "provincia": "Bologna",
      "email": "santarini@comune.sanbenedettovaldisambro.bo.it",
      "pec": "ccomune.sanbenedettovaldisambro@cert.provincia.bo.it",
      "telefono": "+39 0534/95026",
      "fax": "+39 0534/95595",
      "coordinate": {
        "lat": 44.21666717529297,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "037052",
      "nome": "San Giorgio di Piano",
      "nomeStraniero": null,
      "codiceCatastale": "H896",
      "cap": "40016",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "informatica@renogalliera.it",
      "pec": "comune.san-giorgio-di-piano@cert.provincia.bo.it",
      "telefono": "+39 051/6638511",
      "fax": "+39 051/892188",
      "coordinate": {
        "lat": 44.650001525878906,
        "lng": 11.383333206176758
      }
    },
    {
      "codice": "037053",
      "nome": "San Giovanni in Persiceto",
      "nomeStraniero": null,
      "codiceCatastale": "G467",
      "cap": "40017",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "moira.landi@comunepersiceto.it",
      "pec": "comune.persiceto@cert.provincia.bo.it",
      "telefono": "+39 051/6812750",
      "fax": "+39 051/6812759",
      "coordinate": {
        "lat": 44.64083480834961,
        "lng": 11.1850004196167
      }
    },
    {
      "codice": "037054",
      "nome": "San Lazzaro di Savena",
      "nomeStraniero": null,
      "codiceCatastale": "H945",
      "cap": "40068",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "elettorale@comune.sanlazzaro.bo.it",
      "pec": "comune.sanlazzaro@cert.provincia.bo.it",
      "telefono": "+39 051/6228155",
      "fax": "+39 051/6228287",
      "coordinate": {
        "lat": 44.47156524658203,
        "lng": 11.40485954284668
      }
    },
    {
      "codice": "037055",
      "nome": "San Pietro in Casale",
      "nomeStraniero": null,
      "codiceCatastale": "I110",
      "cap": "40018",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "e.tonelli@renogalliera.it",
      "pec": "comune.sanpietroincasale@cert.provincia.bo.it",
      "telefono": "+39 051/6634642",
      "fax": "+39 051/817984",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 11.399999618530273
      }
    },
    {
      "codice": "037056",
      "nome": "Sant'Agata Bolognese",
      "nomeStraniero": null,
      "codiceCatastale": "I191",
      "cap": "40019",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "filomena.iocca@comune.santagatabolognese.bo.it",
      "pec": "comune.santagatabolognese@cert.provincia.bo.it",
      "telefono": "+39 051/6818920",
      "fax": "+39 051/6818950",
      "coordinate": {
        "lat": 44.66666793823242,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "037057",
      "nome": "Sasso Marconi",
      "nomeStraniero": null,
      "codiceCatastale": "G972",
      "cap": "40037",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "clamonica@smarconi.provincia.bologna.it",
      "pec": "comune.sassomarconi@cert.provincia.bo.it",
      "telefono": "+39 051/843515",
      "fax": "+39 051/840802",
      "coordinate": {
        "lat": 44.400001525878906,
        "lng": 11.25
      }
    },
    {
      "codice": "037061",
      "nome": "Valsamoggia",
      "nomeStraniero": null,
      "codiceCatastale": "M320",
      "cap": "40053",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "info@comune.valsamoggia.bo.it",
      "pec": "comune.valsamoggia@cert.provincia.bo.it",
      "telefono": "+39 051 836411",
      "fax": "+39 051836440",
      "coordinate": {
        "lat": 44.50774002075195,
        "lng": 11.098250389099121
      }
    },
    {
      "codice": "037059",
      "nome": "Vergato",
      "nomeStraniero": null,
      "codiceCatastale": "L762",
      "cap": "40038",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "elettorale@comune.vergato.bo.it",
      "pec": "comune.vergato@cert.provincia.bo.it",
      "telefono": "+39 051/6746733",
      "fax": "+39 051/912034",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "037060",
      "nome": "Zola Predosa",
      "nomeStraniero": null,
      "codiceCatastale": "M185",
      "cap": "40069",
      "prefisso": "051",
      "provincia": "Bologna",
      "email": "ced@comune.zolapredosa.bo.it",
      "pec": "comune.zolapredosa@cert.provincia.bo.it",
      "telefono": "+39 051/6161615",
      "fax": "+39 051/6161711",
      "coordinate": {
        "lat": 44.48833465576172,
        "lng": 11.218055725097656
      }
    }
  ],
  "Lucca": [
    {
      "codice": "046001",
      "nome": "Altopascio",
      "nomeStraniero": null,
      "codiceCatastale": "A241",
      "cap": "55011",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "informa@comune.altopascio.lu.it",
      "pec": "comune.altopascio@postacert.toscana.it",
      "telefono": "+39 0583-216455",
      "fax": "+39 0583-216206",
      "coordinate": {
        "lat": 43.81666564941406,
        "lng": 10.674722671508789
      }
    },
    {
      "codice": "046002",
      "nome": "Bagni di Lucca",
      "nomeStraniero": null,
      "codiceCatastale": "A560",
      "cap": "55022",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "segreteria@comunebagnidilucca.it",
      "pec": "comunebagnidilucca@postacert.toscana.it",
      "telefono": "+39 0583 809911",
      "fax": "+39 0583 809937",
      "coordinate": {
        "lat": 44.00944519042969,
        "lng": 10.579444885253906
      }
    },
    {
      "codice": "046003",
      "nome": "Barga",
      "nomeStraniero": null,
      "codiceCatastale": "A657",
      "cap": "55051",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "urp@comunedibarga.it",
      "pec": "comune.barga@postacert.toscana.it",
      "telefono": "+39 0583 72471",
      "fax": "+39 0583 724780",
      "coordinate": {
        "lat": 44.07500076293945,
        "lng": 10.481666564941406
      }
    },
    {
      "codice": "046004",
      "nome": "Borgo a Mozzano",
      "nomeStraniero": null,
      "codiceCatastale": "B007",
      "cap": "55023",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "barsotti@comune.borgoamozzano.lucca.it",
      "pec": "comune.borgoamozzano@postacert.toscana.it",
      "telefono": "+39 0583.82041",
      "fax": "+39 0583.820459",
      "coordinate": {
        "lat": 43.97972106933594,
        "lng": 10.546667098999023
      }
    },
    {
      "codice": "046005",
      "nome": "Camaiore",
      "nomeStraniero": null,
      "codiceCatastale": "B455",
      "cap": "55041",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "urp@comune.camaiore.lu.it",
      "pec": "comune.camaiore@cert.legalmail.it",
      "telefono": "+39 0584 9861",
      "fax": "+39 0584 986264",
      "coordinate": {
        "lat": 43.93333435058594,
        "lng": 10.300000190734863
      }
    },
    {
      "codice": "046006",
      "nome": "Camporgiano",
      "nomeStraniero": null,
      "codiceCatastale": "B557",
      "cap": "55031",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "sindaco@comune.camporgiano.lu.it",
      "pec": "comune.camporgiano@postacert.toscana.it",
      "telefono": "+39 0583/618888",
      "fax": "+39 0583/618433",
      "coordinate": {
        "lat": 44.160831451416016,
        "lng": 10.334444046020508
      }
    },
    {
      "codice": "046007",
      "nome": "Capannori",
      "nomeStraniero": null,
      "codiceCatastale": "B648",
      "cap": "55012",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "ced@comune.capannori.lu.it",
      "pec": "pg.comune.capannori.lu.it@cert.legalmail.it",
      "telefono": "+39 0583 4281",
      "fax": "+39 0583 428399",
      "coordinate": {
        "lat": 43.87555694580078,
        "lng": 10.57361125946045
      }
    },
    {
      "codice": "046008",
      "nome": "Careggine",
      "nomeStraniero": null,
      "codiceCatastale": "B760",
      "cap": "55030",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demografici@comune.careggine.lu.it",
      "pec": "comune.careggine@postacert.toscana.it",
      "telefono": "+39 0583 661061",
      "fax": "+39 0583 661062",
      "coordinate": {
        "lat": 44.12083435058594,
        "lng": 10.32638931274414
      }
    },
    {
      "codice": "046009",
      "nome": "Castelnuovo di Garfagnana",
      "nomeStraniero": null,
      "codiceCatastale": "C236",
      "cap": "55032",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "l.dini@comune.castelnuovodigarfagnana.lu.it",
      "pec": "comune.castelnuovodigarfagnana@postacert.toscana.it",
      "telefono": "+39 0583 6448314",
      "fax": "+39 0583 644133",
      "coordinate": {
        "lat": 44.121944427490234,
        "lng": 10.405555725097656
      }
    },
    {
      "codice": "046010",
      "nome": "Castiglione di Garfagnana",
      "nomeStraniero": null,
      "codiceCatastale": "C303",
      "cap": "55033",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demografici@comune.castiglionedigarfagnana.lu.it",
      "pec": "comune.castiglionedigarfagnana@postacert.toscana.it",
      "telefono": "+39 0583699111",
      "fax": "+39 058369969",
      "coordinate": {
        "lat": 44.15083312988281,
        "lng": 10.411666870117188
      }
    },
    {
      "codice": "046011",
      "nome": "Coreglia Antelminelli",
      "nomeStraniero": null,
      "codiceCatastale": "C996",
      "cap": "55025",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "info@comune.coreglia.lu.it",
      "pec": "comune.coreglia.ant@postacert.toscana.it",
      "telefono": "+39 058378152",
      "fax": "+39 058378419",
      "coordinate": {
        "lat": 44.06444549560547,
        "lng": 10.526389122009277
      }
    },
    {
      "codice": "046036",
      "nome": "Fabbriche di Vergemoli",
      "nomeStraniero": null,
      "codiceCatastale": "M319",
      "cap": "55021",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "info@comune.fabbrichedivergemoli.lu.it",
      "pec": "comune.fabbrichedivergemoli@postacert.toscana.it",
      "telefono": "+39 0583 761944",
      "fax": "+39 0583 761762",
      "coordinate": {
        "lat": 44.014835357666016,
        "lng": 10.39105224609375
      }
    },
    {
      "codice": "046013",
      "nome": "Forte dei Marmi",
      "nomeStraniero": null,
      "codiceCatastale": "D730",
      "cap": "55042",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "comune.fortedeimarmi@postacert.toscana.it",
      "pec": "comune.fortedeimarmi@postacert.toscana.it",
      "telefono": "+39 0584 2801",
      "fax": "+39 0584 280246",
      "coordinate": {
        "lat": 43.95000076293945,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "046014",
      "nome": "Fosciandora",
      "nomeStraniero": null,
      "codiceCatastale": "D734",
      "cap": "55020",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demografici@comune.fosciandora.lu.it",
      "pec": "comune.fosciandora@postacert.toscana.it",
      "telefono": "+39 0583 662040",
      "fax": "+39 0583 662326",
      "coordinate": {
        "lat": 44.1158332824707,
        "lng": 10.459444046020508
      }
    },
    {
      "codice": "046015",
      "nome": "Gallicano",
      "nomeStraniero": null,
      "codiceCatastale": "D874",
      "cap": "55027",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "info@comune.gallicano.lu.it",
      "pec": "comunegallicano@postacert.toscana.it",
      "telefono": "+39 0583 73071",
      "fax": "+39 058374448",
      "coordinate": {
        "lat": 44.06666564941406,
        "lng": 10.433333396911621
      }
    },
    {
      "codice": "046017",
      "nome": "Lucca",
      "nomeStraniero": null,
      "codiceCatastale": "E715",
      "cap": "55100",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "elettorale@comune.lucca.it",
      "pec": "comune.lucca@postacert.toscana.it",
      "telefono": "+39 0583 442106",
      "fax": null,
      "coordinate": {
        "lat": 43.849998474121094,
        "lng": 10.516666412353516
      }
    },
    {
      "codice": "046018",
      "nome": "Massarosa",
      "nomeStraniero": null,
      "codiceCatastale": "F035",
      "cap": "55054",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "cdan@comune.massarosa.lu.it",
      "pec": "comune.massarosa@postacert.toscana.it",
      "telefono": "+39 0584.97901",
      "fax": "+39 0584.979261",
      "coordinate": {
        "lat": 43.86666488647461,
        "lng": 10.333333015441895
      }
    },
    {
      "codice": "046019",
      "nome": "Minucciano",
      "nomeStraniero": null,
      "codiceCatastale": "F225",
      "cap": "55034",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "segreteria@comunediminucciano.it",
      "pec": "comune.minucciano@postacert.toscana.it",
      "telefono": "+39 0583 610391",
      "fax": "+39 0583 610394",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 10.216666221618652
      }
    },
    {
      "codice": "046020",
      "nome": "Molazzana",
      "nomeStraniero": null,
      "codiceCatastale": "F283",
      "cap": "55020",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "info@comunedimolazzana.it",
      "pec": "comune.molazzana@postacert.toscana.it",
      "telefono": "+39 0583 760151",
      "fax": "+39 0583 760330",
      "coordinate": {
        "lat": 44.06666564941406,
        "lng": 10.416666984558105
      }
    },
    {
      "codice": "046021",
      "nome": "Montecarlo",
      "nomeStraniero": null,
      "codiceCatastale": "F452",
      "cap": "55015",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "anagrafe.montecarlo@virgilio.it",
      "pec": "comune.montecarlo@postacert.toscana.it",
      "telefono": "+39 0583.22971",
      "fax": "+39 0583.228920",
      "coordinate": {
        "lat": 43.850399017333984,
        "lng": 10.668600082397461
      }
    },
    {
      "codice": "046022",
      "nome": "Pescaglia",
      "nomeStraniero": null,
      "codiceCatastale": "G480",
      "cap": "55064",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demograficipescaglia@virgilio.it",
      "pec": "comune.pescaglia@postacert.toscana.it",
      "telefono": "+39 0583 35401",
      "fax": "+39 0583 3540215",
      "coordinate": {
        "lat": 43.96666717529297,
        "lng": 10.416666984558105
      }
    },
    {
      "codice": "046023",
      "nome": "Piazza al Serchio",
      "nomeStraniero": null,
      "codiceCatastale": "G582",
      "cap": "55035",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demografici@comune.piazzaalserchio.lu.it",
      "pec": "comune.piazzaalserchio@postacert.toscana.it",
      "telefono": "+39 0583 696200",
      "fax": "+39 0583 60438",
      "coordinate": {
        "lat": 44.18333435058594,
        "lng": 10.300000190734863
      }
    },
    {
      "codice": "046024",
      "nome": "Pietrasanta",
      "nomeStraniero": null,
      "codiceCatastale": "G628",
      "cap": "55045",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "anagrafe@comune.pietrasanta.lu.it",
      "pec": "comune.pietrasanta@postacert.toscana.it",
      "telefono": "+39 0584 795234",
      "fax": "+39 0584 795280",
      "coordinate": {
        "lat": 43.9451904296875,
        "lng": 10.218270301818848
      }
    },
    {
      "codice": "046025",
      "nome": "Pieve Fosciana",
      "nomeStraniero": null,
      "codiceCatastale": "G648",
      "cap": "55036",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "a.fanani@comune.pievefosciana.lu.it",
      "pec": "comune.pievefosciana@postacert.toscana.it",
      "telefono": "+39 058366821",
      "fax": "+39 0583668221",
      "coordinate": {
        "lat": 44.13277816772461,
        "lng": 10.411944389343262
      }
    },
    {
      "codice": "046026",
      "nome": "Porcari",
      "nomeStraniero": null,
      "codiceCatastale": "G882",
      "cap": "55016",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "a.micalizzi@comune.porcari.it",
      "pec": "comune.porcari@postacert.toscana.it",
      "telefono": "+39 0583-21181",
      "fax": "+39 0583-297564",
      "coordinate": {
        "lat": 43.84151840209961,
        "lng": 10.61631965637207
      }
    },
    {
      "codice": "046027",
      "nome": "San Romano in Garfagnana",
      "nomeStraniero": null,
      "codiceCatastale": "I142",
      "cap": "55038",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "info@comunesanromano.it",
      "pec": "comune.sanromanogarfagnana@postacert.toscana.it",
      "telefono": "+39 0583613181",
      "fax": "+39 0583613306",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 10.350000381469727
      }
    },
    {
      "codice": "046028",
      "nome": "Seravezza",
      "nomeStraniero": null,
      "codiceCatastale": "I622",
      "cap": "55047",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "cedinterno@comune.seravezza.lucca.it",
      "pec": "protocollo.seravezza@postacert.toscana.it",
      "telefono": "+39 0584 757711",
      "fax": null,
      "coordinate": {
        "lat": 44,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "046037",
      "nome": "Sillano Giuncugnano",
      "nomeStraniero": null,
      "codiceCatastale": "M347",
      "cap": "55039",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demografici@comune.sillanogiuncugnano.lu.it",
      "pec": "comune.sillanogiuncugnano@postacert.toscana.it",
      "telefono": "+39 0583 616056",
      "fax": "+39 0583 616245",
      "coordinate": {
        "lat": 44.233333587646484,
        "lng": 10.300000190734863
      }
    },
    {
      "codice": "046030",
      "nome": "Stazzema",
      "nomeStraniero": null,
      "codiceCatastale": "I942",
      "cap": "55040",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "guidienzo@comune.stazzema.lu.it",
      "pec": "comune.stazzema@postacert.toscana.it",
      "telefono": "+39 058477521",
      "fax": "+39 058477259",
      "coordinate": {
        "lat": 43.99305725097656,
        "lng": 10.310555458068848
      }
    },
    {
      "codice": "046031",
      "nome": "Vagli Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "L533",
      "cap": "55030",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "anagrafe@comune.vagli-sotto.lu.it",
      "pec": "comune.vaglisotto@postacert.toscana.it",
      "telefono": "+39 0583.664053",
      "fax": "+39 0583 664343",
      "coordinate": {
        "lat": 44.11666488647461,
        "lng": 10.283333778381348
      }
    },
    {
      "codice": "046033",
      "nome": "Viareggio",
      "nomeStraniero": null,
      "codiceCatastale": "L833",
      "cap": "55049",
      "prefisso": "0584",
      "provincia": "Lucca",
      "email": "urp@comune.viareggio.lu.it",
      "pec": "comune.viareggio@postacert.toscana.it",
      "telefono": "+39 0584-9661",
      "fax": "+39 0584 966822",
      "coordinate": {
        "lat": 43.86724090576172,
        "lng": 10.250605583190918
      }
    },
    {
      "codice": "046034",
      "nome": "Villa Basilica",
      "nomeStraniero": null,
      "codiceCatastale": "L913",
      "cap": "55019",
      "prefisso": "0572",
      "provincia": "Lucca",
      "email": "tecnico@comunevillabasilica.it",
      "pec": "comunevillabasilica@pcert.it",
      "telefono": "+39 0572/46161",
      "fax": "+39 0572/43514",
      "coordinate": {
        "lat": 43.91666793823242,
        "lng": 10.649999618530273
      }
    },
    {
      "codice": "046035",
      "nome": "Villa Collemandina",
      "nomeStraniero": null,
      "codiceCatastale": "L926",
      "cap": "55030",
      "prefisso": "0583",
      "provincia": "Lucca",
      "email": "demografici@comune.villacollemandina.lu.it",
      "pec": "comune.villacollemandina@postacert.toscana.it",
      "telefono": "+39 0583-68046",
      "fax": "+39 0583-68472",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 10.399999618530273
      }
    }
  ],
  "La Spezia": [
    {
      "codice": "011001",
      "nome": "Ameglia",
      "nomeStraniero": null,
      "codiceCatastale": "A261",
      "cap": "19031",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "info@comune.ameglia.sp.it",
      "pec": "anagrafe@pec.comune.ameglia.sp.it",
      "telefono": "+39 0187/609278",
      "fax": "+39 0187/609044",
      "coordinate": {
        "lat": 44.067264556884766,
        "lng": 9.957541465759277
      }
    },
    {
      "codice": "011002",
      "nome": "Arcola",
      "nomeStraniero": null,
      "codiceCatastale": "A373",
      "cap": "19021",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "sindaco@comune.arcola.sp.it",
      "pec": "comune.arcola@legalmail.it",
      "telefono": "+39 0187/986014",
      "fax": "+39 0187/986628",
      "coordinate": {
        "lat": 44.11455154418945,
        "lng": 9.905863761901855
      }
    },
    {
      "codice": "011003",
      "nome": "Beverino",
      "nomeStraniero": null,
      "codiceCatastale": "A836",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "comunebeverino@libero.it",
      "pec": "comune.beverino@legalmail.it",
      "telefono": "+39 0187883026",
      "fax": "+39 0187883766",
      "coordinate": {
        "lat": 44.195926666259766,
        "lng": 9.768694877624512
      }
    },
    {
      "codice": "011004",
      "nome": "Bolano",
      "nomeStraniero": null,
      "codiceCatastale": "A932",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "info@comune.bolano.sp.it",
      "pec": "comune.bolano@anutel.it",
      "telefono": "+39 0187/941728",
      "fax": "+39 0187/941740",
      "coordinate": {
        "lat": 44.1890983581543,
        "lng": 9.895360946655273
      }
    },
    {
      "codice": "011005",
      "nome": "Bonassola",
      "nomeStraniero": null,
      "codiceCatastale": "A961",
      "cap": "19011",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "affarigenerali@comune.bonassola.sp.it",
      "pec": "comune.bonassola.sp@halleycert.it",
      "telefono": "0187813829",
      "fax": "0187813830",
      "coordinate": {
        "lat": 44.18343734741211,
        "lng": 9.584092140197754
      }
    },
    {
      "codice": "011006",
      "nome": "Borghetto di Vara",
      "nomeStraniero": null,
      "codiceCatastale": "A992",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "info@comuneborghettodivara.191.it",
      "pec": "borghettodivara@legalmail.it",
      "telefono": "+39 0187/894121",
      "fax": "+39 0187/897141",
      "coordinate": {
        "lat": 44.2237548828125,
        "lng": 9.721405982971191
      }
    },
    {
      "codice": "011007",
      "nome": "Brugnato",
      "nomeStraniero": null,
      "codiceCatastale": "B214",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "brugnatocom@libero.it",
      "pec": "comune.brugnato@legalmail.it",
      "telefono": "+39 0187/894110",
      "fax": "+39 0187/897098",
      "coordinate": {
        "lat": 44.23667526245117,
        "lng": 9.725238800048828
      }
    },
    {
      "codice": "011008",
      "nome": "Calice al Cornoviglio",
      "nomeStraniero": null,
      "codiceCatastale": "B410",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "comcal@libero.it",
      "pec": "comunecalice.sp@legalmail.it",
      "telefono": "+39 01879355644",
      "fax": "+39 0187/935580",
      "coordinate": {
        "lat": 44.24305725097656,
        "lng": 9.83638858795166
      }
    },
    {
      "codice": "011009",
      "nome": "Carro",
      "nomeStraniero": null,
      "codiceCatastale": "B838",
      "cap": "19012",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "demografici-finanziari@comune-di-carro.it",
      "pec": "comunecarro.sp@legalmail.it",
      "telefono": "+39 0187/861209",
      "fax": "+39 0187/861914",
      "coordinate": {
        "lat": 44.266666412353516,
        "lng": 9.600000381469727
      }
    },
    {
      "codice": "011010",
      "nome": "Carrodano",
      "nomeStraniero": null,
      "codiceCatastale": "B839",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "carrodano@libero.it",
      "pec": "comunedicarrodano@pec.it",
      "telefono": "+39 0187/893324",
      "fax": "+39 0187/893900",
      "coordinate": {
        "lat": 44.24184799194336,
        "lng": 9.656174659729004
      }
    },
    {
      "codice": "011011",
      "nome": "Castelnuovo Magra",
      "nomeStraniero": null,
      "codiceCatastale": "C240",
      "cap": "19033",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "servizidemografici@castelnuovomagra.com",
      "pec": "protocollo@pec.castelnuovomagra.com",
      "telefono": "+39 0187/693813",
      "fax": "+39 0187/693852",
      "coordinate": {
        "lat": 44.099365234375,
        "lng": 10.017786026000977
      }
    },
    {
      "codice": "011012",
      "nome": "Deiva Marina",
      "nomeStraniero": null,
      "codiceCatastale": "D265",
      "cap": "19013",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "servizidemografici@comune.deivamarina.sp.it",
      "pec": "comunedeivamarina@halleycert.it",
      "telefono": "+39 0187/826121",
      "fax": "+39 0187/826199",
      "coordinate": {
        "lat": 44.21666717529297,
        "lng": 9.516667366027832
      }
    },
    {
      "codice": "011013",
      "nome": "Follo",
      "nomeStraniero": null,
      "codiceCatastale": "D655",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "elettorale@comunefollo.it",
      "pec": "comunefollo@legalmail.it",
      "telefono": "+39 0187/599922",
      "fax": "+39 0187/558149",
      "coordinate": {
        "lat": 44.16041946411133,
        "lng": 9.864885330200195
      }
    },
    {
      "codice": "011014",
      "nome": "Framura",
      "nomeStraniero": null,
      "codiceCatastale": "D758",
      "cap": "19014",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "info@comune.framura.sp.it",
      "pec": "comuneframura@legalmail.it",
      "telefono": "+39 0187/810020",
      "fax": "+39 0187/823071",
      "coordinate": {
        "lat": 44.20866012573242,
        "lng": 9.554422378540039
      }
    },
    {
      "codice": "011015",
      "nome": "La Spezia",
      "nomeStraniero": null,
      "codiceCatastale": "E463",
      "cap": "19121",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "urp@comune.sp.it",
      "pec": "protocollo.comune.laspezia@legalmail.it",
      "telefono": "+39 0187/727225",
      "fax": "+39 0187/727332",
      "coordinate": {
        "lat": 44.1080436706543,
        "lng": 9.828880310058594
      }
    },
    {
      "codice": "011016",
      "nome": "Lerici",
      "nomeStraniero": null,
      "codiceCatastale": "E542",
      "cap": "19030",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "mussi@comune.lerici.sp.it",
      "pec": "comunedilerici@postecert.it",
      "telefono": "+39 0187/960218",
      "fax": "+39 0187/960215",
      "coordinate": {
        "lat": 44.07625961303711,
        "lng": 9.911100387573242
      }
    },
    {
      "codice": "011017",
      "nome": "Levanto",
      "nomeStraniero": null,
      "codiceCatastale": "E560",
      "cap": "19015",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "anagrafe.levanto@libero.it",
      "pec": "comune.levanto.sp@legalmail.it",
      "telefono": "+39 0187/802240",
      "fax": "+39 0187/802279",
      "coordinate": {
        "lat": 44.169681549072266,
        "lng": 9.61223316192627
      }
    },
    {
      "codice": "011020",
      "nome": "Luni",
      "nomeStraniero": null,
      "codiceCatastale": "G143",
      "cap": "19034",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "felett@comune.luni.sp.it",
      "pec": "segreteria@pec.comune.luni.sp.it",
      "telefono": "+39 0187/690147",
      "fax": "+39 0187/638698",
      "coordinate": {
        "lat": 44.084842681884766,
        "lng": 10.042027473449707
      }
    },
    {
      "codice": "011018",
      "nome": "Maissana",
      "nomeStraniero": null,
      "codiceCatastale": "E842",
      "cap": "19010",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "comunedimaissana@libero.it",
      "pec": "comunedimaissana@pec.it",
      "telefono": "+39 0187/845617",
      "fax": "+39 0187/845606",
      "coordinate": {
        "lat": 44.33628463745117,
        "lng": 9.534385681152344
      }
    },
    {
      "codice": "011019",
      "nome": "Monterosso al Mare",
      "nomeStraniero": null,
      "codiceCatastale": "F609",
      "cap": "19016",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "protocollo@comune.monterosso.sp.it",
      "pec": "protocollo@pec.comune.monterosso.sp.it",
      "telefono": "0187817525",
      "fax": "+39 0187/817430",
      "coordinate": {
        "lat": 44.14628601074219,
        "lng": 9.655550003051758
      }
    },
    {
      "codice": "011021",
      "nome": "Pignone",
      "nomeStraniero": null,
      "codiceCatastale": "G664",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "anagrafe@comune.pignone.sp.it",
      "pec": "comune.pignone@legalmail.it",
      "telefono": "+39 0187/887002",
      "fax": "+39 0187/887003",
      "coordinate": {
        "lat": 44.179012298583984,
        "lng": 9.724113464355469
      }
    },
    {
      "codice": "011022",
      "nome": "Portovenere",
      "nomeStraniero": null,
      "codiceCatastale": "G925",
      "cap": "19025",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": null,
      "pec": "protocollo@pec.comune.portovenere.sp.it",
      "telefono": "+39 0187794800",
      "fax": "+39 0187777740",
      "coordinate": {
        "lat": 44.04999923706055,
        "lng": 9.833333015441895
      }
    },
    {
      "codice": "011023",
      "nome": "Riccò del Golfo di Spezia",
      "nomeStraniero": null,
      "codiceCatastale": "H275",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "protocollo@comune.riccodelgolfo.sp.it",
      "pec": "riccodelgolfo@pec.comune.riccodelgolfo.sp.it",
      "telefono": "+39 0187/925106",
      "fax": "+39 0187/926114",
      "coordinate": {
        "lat": 44.15305709838867,
        "lng": 9.76388931274414
      }
    },
    {
      "codice": "011024",
      "nome": "Riomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "H304",
      "cap": "19017",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "urp@comune.riomaggiore.sp.it",
      "pec": "segreteria@pec-comunediriomaggiore.it",
      "telefono": "0187760219",
      "fax": "0187920866",
      "coordinate": {
        "lat": 44.09928512573242,
        "lng": 9.737449645996094
      }
    },
    {
      "codice": "011025",
      "nome": "Rocchetta di Vara",
      "nomeStraniero": null,
      "codiceCatastale": "H461",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "protocollo@comune.rocchettadivara.sp.it",
      "pec": "protocollo@pec.comune.rocchettadivara.sp.it",
      "telefono": "+39 0187868752",
      "fax": "+39 0187868759",
      "coordinate": {
        "lat": 44.25102615356445,
        "lng": 9.757200241088867
      }
    },
    {
      "codice": "011026",
      "nome": "Santo Stefano di Magra",
      "nomeStraniero": null,
      "codiceCatastale": "I363",
      "cap": "19037",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "protocollo@comune.santostefanodimagra.sp.it",
      "pec": "protocollo@pec.comune.santostefanodimagra.sp.it",
      "telefono": "+39 0187/967127",
      "fax": "+39 0187/699769",
      "coordinate": {
        "lat": 44.162479400634766,
        "lng": 9.915133476257324
      }
    },
    {
      "codice": "011027",
      "nome": "Sarzana",
      "nomeStraniero": null,
      "codiceCatastale": "I449",
      "cap": "19038",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "urp@comunesarzana.gov.it",
      "pec": "protocollo.comune.sarzana@postecert.it",
      "telefono": "+39 01876142",
      "fax": "+39 0187614252",
      "coordinate": {
        "lat": 44.113609313964844,
        "lng": 9.95995807647705
      }
    },
    {
      "codice": "011028",
      "nome": "Sesta Godano",
      "nomeStraniero": null,
      "codiceCatastale": "E070",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "demografici@comune.sestagodano.sp.it",
      "pec": "comune.sestagodano@legalmail.it",
      "telefono": "+39 0187/891525",
      "fax": "+39 0187/870921",
      "coordinate": {
        "lat": 44.293731689453125,
        "lng": 9.675427436828613
      }
    },
    {
      "codice": "011029",
      "nome": "Varese Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "L681",
      "cap": "19028",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "comune.vareseligure.ist@liguriainrete.it",
      "pec": "protocollo.vareseligure@legalmail.it",
      "telefono": "+39 0187/842930",
      "fax": "+39 0187/842352",
      "coordinate": {
        "lat": 44.37812805175781,
        "lng": 9.5958251953125
      }
    },
    {
      "codice": "011030",
      "nome": "Vernazza",
      "nomeStraniero": null,
      "codiceCatastale": "L774",
      "cap": "19018",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "comune@comune.vernazza.sp.it",
      "pec": "protocollo.comune.vernazza@pec.it",
      "telefono": "+39 0187/821247",
      "fax": "+39 0187/812212",
      "coordinate": {
        "lat": 44.135162353515625,
        "lng": 9.683853149414062
      }
    },
    {
      "codice": "011031",
      "nome": "Vezzano Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "L819",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "sindaco@comune.vezzanoligure.sp.it",
      "pec": "comune.vezzano.sp@halleycert.it",
      "telefono": "+39 0187/993128",
      "fax": "+39 0187/993142",
      "coordinate": {
        "lat": 44.14118576049805,
        "lng": 9.88492202758789
      }
    },
    {
      "codice": "011032",
      "nome": "Zignago",
      "nomeStraniero": null,
      "codiceCatastale": "M177",
      "cap": "19020",
      "prefisso": "0187",
      "provincia": "La Spezia",
      "email": "anagrafe@comunezignago.it",
      "pec": "comune.zignago@legalmail.it",
      "telefono": "+39 0187/865075",
      "fax": "+39 0187/865267",
      "coordinate": {
        "lat": 44.277610778808594,
        "lng": 9.744633674621582
      }
    }
  ],
  "Pordenone": [
    {
      "codice": "093001",
      "nome": "Andreis",
      "nomeStraniero": null,
      "codiceCatastale": "A283",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "servizi@comune.andreis.pn.it",
      "pec": "comune.andreis@certgov.fvg.it",
      "telefono": "+39 0427/76007",
      "fax": "+39 0427/76304",
      "coordinate": {
        "lat": 46.20000076293945,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "093002",
      "nome": "Arba",
      "nomeStraniero": null,
      "codiceCatastale": "A354",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "antonio.ferrarin@com-arba.regione.fvg.it",
      "pec": "comune.arba@certgov.fvg.it",
      "telefono": "+39 0427/93032",
      "fax": "+39 0427/938878",
      "coordinate": {
        "lat": 46.150001525878906,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "093004",
      "nome": "Aviano",
      "nomeStraniero": null,
      "codiceCatastale": "A516",
      "cap": "33081",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "protocollo@comune.aviano.pn.it",
      "pec": "comune.aviano@certgov.fvg.it",
      "telefono": "+39 0434/666505",
      "fax": "+39 0434/666590",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 12.583333015441895
      }
    },
    {
      "codice": "093005",
      "nome": "Azzano Decimo",
      "nomeStraniero": null,
      "codiceCatastale": "A530",
      "cap": "33082",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@com-azzano-decimo.regione.fvg.it",
      "pec": "comune.azzanodecimo@certgov.fvg.it",
      "telefono": "+39 0434 636711",
      "fax": "+39 0434 640182",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "093006",
      "nome": "Barcis",
      "nomeStraniero": null,
      "codiceCatastale": "A640",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.barcis.pn.it",
      "pec": "comune.barcis@certgov.fvg.it",
      "telefono": "+39 042776014",
      "fax": "+39 042776340",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 12.566666603088379
      }
    },
    {
      "codice": "093007",
      "nome": "Brugnera",
      "nomeStraniero": null,
      "codiceCatastale": "B215",
      "cap": "33070",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@comune.brugnera.pn.it",
      "pec": "comune.brugnera@certgov.fvg.it",
      "telefono": "+39 0434/616711",
      "fax": "+39 0434/624559",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 12.533333778381348
      }
    },
    {
      "codice": "093008",
      "nome": "Budoia",
      "nomeStraniero": null,
      "codiceCatastale": "B247",
      "cap": "33070",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "sindaco.com-budoia@regione.fvg.it",
      "pec": "protocollo@postacert.comune.budoia.pn.it",
      "telefono": "+39 0434 671911",
      "fax": "+39 0434 654961",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 12.533333778381348
      }
    },
    {
      "codice": "093009",
      "nome": "Caneva",
      "nomeStraniero": null,
      "codiceCatastale": "B598",
      "cap": "33070",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@comune.caneva.pn.it",
      "pec": "comune.caneva@certgov.fvg.it",
      "telefono": "+39 0434/797411",
      "fax": "+39 0434/797115",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 12.449999809265137
      }
    },
    {
      "codice": "093010",
      "nome": "Casarsa della Delizia",
      "nomeStraniero": null,
      "codiceCatastale": "B940",
      "cap": "33072",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "servizi.demografici@com-casarsa-della-delizia.regione.fvg.it",
      "pec": "comune.casarsadelladelizia@certgov.fvg.it",
      "telefono": "+39 0434/873931",
      "fax": "+39 0434/873910",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "093011",
      "nome": "Castelnovo del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "C217",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "protocollo@com-castelnovo-del-friuli.regione.fvg.it",
      "pec": "comune.castelnovodelfriuli@certgov.fvg.it",
      "telefono": "+39 0427 90040",
      "fax": "+39 0427 907840",
      "coordinate": {
        "lat": 46.200199127197266,
        "lng": 12.903523445129395
      }
    },
    {
      "codice": "093012",
      "nome": "Cavasso Nuovo",
      "nomeStraniero": null,
      "codiceCatastale": "C385",
      "cap": "33092",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "segreteria@comune.cavassonuovo.pn.it",
      "pec": "comune.cavassonuovo@certgov.fvg.it",
      "telefono": "+39 0427/77014",
      "fax": "+39 0427/778000",
      "coordinate": {
        "lat": 46.20000076293945,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "093013",
      "nome": "Chions",
      "nomeStraniero": null,
      "codiceCatastale": "C640",
      "cap": "33083",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "anagrafe@com-chions.regione.fvg.it",
      "pec": "comune.chions@certgov.fvg.it",
      "telefono": "+39 0434/630790",
      "fax": "+39 0434/639798",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "093014",
      "nome": "Cimolais",
      "nomeStraniero": null,
      "codiceCatastale": "C699",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.cimolais.pn.it",
      "pec": "comune.cimolais@certgov.fvg.it",
      "telefono": "+39 0427/87019",
      "fax": "+39 0427/87020",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 12.433333396911621
      }
    },
    {
      "codice": "093015",
      "nome": "Claut",
      "nomeStraniero": null,
      "codiceCatastale": "C790",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.claut.pn.it",
      "pec": "comune.claut@certgov.fvg.it",
      "telefono": "+39 0427/878040",
      "fax": "+39 0427/878454",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 12.516666412353516
      }
    },
    {
      "codice": "093016",
      "nome": "Clauzetto",
      "nomeStraniero": null,
      "codiceCatastale": "C791",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.clauzetto.pn.it",
      "pec": "comune.clauzetto@certgov.fvg.it",
      "telefono": "+39 0427/80323",
      "fax": "+39 0427/80516",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 12.916666984558105
      }
    },
    {
      "codice": "093017",
      "nome": "Cordenons",
      "nomeStraniero": null,
      "codiceCatastale": "C991",
      "cap": "33084",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "sett_segret@comune.cordenons.pn.it",
      "pec": "comune.cordenons@legalmail.it",
      "telefono": "+39 0434/596911",
      "fax": "+39 0434/932548",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 12.699999809265137
      }
    },
    {
      "codice": "093018",
      "nome": "Cordovado",
      "nomeStraniero": null,
      "codiceCatastale": "C993",
      "cap": "33075",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@comune.cordovado.pn.it",
      "pec": "comune.cordovado@certgov.fvg.it",
      "telefono": "+39 0434 1689807",
      "fax": "+39 0434 68319",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "093019",
      "nome": "Erto e Casso",
      "nomeStraniero": null,
      "codiceCatastale": "D426",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.ertoecasso.pn.it",
      "pec": "comune.ertoecasso@certgov.fvg.it",
      "telefono": "+39 0427/879001",
      "fax": "+39 0427/879100",
      "coordinate": {
        "lat": 46.2744255065918,
        "lng": 12.372087478637695
      }
    },
    {
      "codice": "093020",
      "nome": "Fanna",
      "nomeStraniero": null,
      "codiceCatastale": "D487",
      "cap": "33092",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "info@comune.fanna.pn.it",
      "pec": "comune.fanna@certgov.fvg.it",
      "telefono": "+39 0427/77036",
      "fax": "+39 0427/778067",
      "coordinate": {
        "lat": 46.18333435058594,
        "lng": 12.75
      }
    },
    {
      "codice": "093021",
      "nome": "Fiume Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "D621",
      "cap": "33080",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "protocollo@comune.fiumeveneto.pn.it",
      "pec": "comune.fiumeveneto@certgov.fvg.it",
      "telefono": "+39 0434.562205",
      "fax": "+39 0434.562290",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "093022",
      "nome": "Fontanafredda",
      "nomeStraniero": null,
      "codiceCatastale": "D670",
      "cap": "33074",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "info@comune.fontanafredda.pn.it",
      "pec": "comune.fontanafredda@certgov.fvg.it",
      "telefono": "+39 0434/567611",
      "fax": "+39 0434/567667",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 12.566666603088379
      }
    },
    {
      "codice": "093024",
      "nome": "Frisanco",
      "nomeStraniero": null,
      "codiceCatastale": "D804",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.frisanco.pn.it",
      "pec": "comune.frisanco@certgov.fvg.it",
      "telefono": "+39 0427 78061",
      "fax": "+39 0427 78062",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "093025",
      "nome": "Maniago",
      "nomeStraniero": null,
      "codiceCatastale": "E889",
      "cap": "33085",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "segreteria@maniago.it",
      "pec": "comune.maniago@certgov.fvg.it",
      "telefono": "+39 0427/707111",
      "fax": "+39 0427/707200",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "093026",
      "nome": "Meduno",
      "nomeStraniero": null,
      "codiceCatastale": "F089",
      "cap": "33092",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "affari.generali@com-meduno.regione.fvg.it",
      "pec": "comune.meduno@certgov.fvg.it",
      "telefono": "+39 0427 86130",
      "fax": "+39 0427 85000",
      "coordinate": {
        "lat": 46.21666717529297,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "093027",
      "nome": "Montereale Valcellina",
      "nomeStraniero": null,
      "codiceCatastale": "F596",
      "cap": "33086",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@com-montereale-valcellina.regione.fvg.it",
      "pec": "comune.montereale@cert.amministrazionefuturo.com",
      "telefono": "+39 0427/799728",
      "fax": "+39 0427/799373",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "093028",
      "nome": "Morsano al Tagliamento",
      "nomeStraniero": null,
      "codiceCatastale": "F750",
      "cap": "33075",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "protocollo@com-morsano-al-tagliamento.regione.fvg.it",
      "pec": "comune.morsanoaltagliamento@certgov.fvg.it",
      "telefono": "+39 0434/697117",
      "fax": "+39 0434/696028",
      "coordinate": {
        "lat": 45.858333587646484,
        "lng": 12.927778244018555
      }
    },
    {
      "codice": "093029",
      "nome": "Pasiano di Pordenone",
      "nomeStraniero": null,
      "codiceCatastale": "G353",
      "cap": "33087",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "protocollo@comune.pasianodipordenone.pn.it",
      "pec": "comune.pasianodipordenone@certgov.fvg.it",
      "telefono": "+39 0434/614311",
      "fax": "+39 0434/614312",
      "coordinate": {
        "lat": 45.86861038208008,
        "lng": 12.644439697265625
      }
    },
    {
      "codice": "093030",
      "nome": "Pinzano al Tagliamento",
      "nomeStraniero": null,
      "codiceCatastale": "G680",
      "cap": "33094",
      "prefisso": "0432",
      "provincia": "Pordenone",
      "email": "protocollo@comune.pinzanoaltagliamento.pn.it",
      "pec": "comune.pinzanoaltagliamento@certgov.fvg.it",
      "telefono": "+39 0432 950005",
      "fax": "+39 0432 950129",
      "coordinate": {
        "lat": 46.18305587768555,
        "lng": 12.94527816772461
      }
    },
    {
      "codice": "093031",
      "nome": "Polcenigo",
      "nomeStraniero": null,
      "codiceCatastale": "G780",
      "cap": "33070",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "info@comune.polcenigo.pn.it",
      "pec": "comune.polcenigo@certgov.fvg.it",
      "telefono": "+39 0434 74001",
      "fax": "+39 0434 748977",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 12.5
      }
    },
    {
      "codice": "093032",
      "nome": "Porcia",
      "nomeStraniero": null,
      "codiceCatastale": "G886",
      "cap": "33080",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "urp@comune.porcia.pn.it",
      "pec": "comune.porcia@certgov.fvg.it",
      "telefono": "+39 0434/596911",
      "fax": "+39 0434/921610",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "093033",
      "nome": "Pordenone",
      "nomeStraniero": null,
      "codiceCatastale": "G888",
      "cap": "33170",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria.sindaco@comune.pordenone.it",
      "pec": "comune.pordenone@certgov.fvg.it",
      "telefono": "+39 0434/392111",
      "fax": "+39 0434/392418",
      "coordinate": {
        "lat": 45.96260070800781,
        "lng": 12.656299591064453
      }
    },
    {
      "codice": "093034",
      "nome": "Prata di Pordenone",
      "nomeStraniero": null,
      "codiceCatastale": "G994",
      "cap": "33080",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@comune.prata.pn.it",
      "pec": "comune.pratadipordenone@certgov.fvg.it",
      "telefono": "+39 0434/425111",
      "fax": "+39 0434/610457",
      "coordinate": {
        "lat": 45.89444351196289,
        "lng": 12.588610649108887
      }
    },
    {
      "codice": "093035",
      "nome": "Pravisdomini",
      "nomeStraniero": null,
      "codiceCatastale": "H010",
      "cap": "33076",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.pravisdomini.pn.it",
      "pec": "comune.pravisdomini@certgov.fvg.it",
      "telefono": "+39 0434/645086",
      "fax": "+39 0434/644858",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "093036",
      "nome": "Roveredo in Piano",
      "nomeStraniero": null,
      "codiceCatastale": "H609",
      "cap": "33080",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "sindaco@comune.roveredo.pn.it",
      "pec": "comune.roveredoinpiano@certgov.fvg.it",
      "telefono": "+39 0434/388611",
      "fax": "+39 0434/94207",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "093037",
      "nome": "Sacile",
      "nomeStraniero": null,
      "codiceCatastale": "H657",
      "cap": "33077",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "affari.generali@com-sacile.regione.fvg.it",
      "pec": "comune.sacile@certgov.fvg.it",
      "telefono": "+39 0434/787111",
      "fax": "+39 0434/780694",
      "coordinate": {
        "lat": 45.95412063598633,
        "lng": 12.502739906311035
      }
    },
    {
      "codice": "093038",
      "nome": "San Giorgio della Richinvelda",
      "nomeStraniero": null,
      "codiceCatastale": "H891",
      "cap": "33095",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@com-san-giorgio-della-richinvelda.regione.fvg.it",
      "pec": "comune.sangiorgiodellarichinvelda@certgov.fvg.it",
      "telefono": "+39 0427/968611",
      "fax": "+39 0427/968625",
      "coordinate": {
        "lat": 46.04999923706055,
        "lng": 12.866666793823242
      }
    },
    {
      "codice": "093039",
      "nome": "San Martino al Tagliamento",
      "nomeStraniero": null,
      "codiceCatastale": "H999",
      "cap": "33098",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "amministrazione@comune.sanmartinoaltagliamento.pn.it",
      "pec": "comune.sanmartinoaltagliamento@certgov.fvg.it",
      "telefono": "+39 0434/88008",
      "fax": "+39 0434/88307",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 12.866666793823242
      }
    },
    {
      "codice": "093040",
      "nome": "San Quirino",
      "nomeStraniero": null,
      "codiceCatastale": "I136",
      "cap": "33080",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@comune.sanquirino.pn.it",
      "pec": "comune.sanquirino@certgov.fvg.it",
      "telefono": "+39 0434/916511",
      "fax": "+39 0434/916519",
      "coordinate": {
        "lat": 46.03411102294922,
        "lng": 12.678460121154785
      }
    },
    {
      "codice": "093041",
      "nome": "San Vito al Tagliamento",
      "nomeStraniero": null,
      "codiceCatastale": "I403",
      "cap": "33078",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "anagrafe@sanvitoaltagliamento.fvg.it",
      "pec": "comune.sanvitoaltagliamento@certgov.fvg.it",
      "telefono": "+39 0434/842954",
      "fax": "+39 0434/842981",
      "coordinate": {
        "lat": 45.915279388427734,
        "lng": 12.855555534362793
      }
    },
    {
      "codice": "093042",
      "nome": "Sequals",
      "nomeStraniero": null,
      "codiceCatastale": "I621",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "segretario@com-sequals.regione.fvg.it",
      "pec": "comune.sequals@certgov.fvg.it",
      "telefono": "+39 0427/789111",
      "fax": "+39 0427/938855",
      "coordinate": {
        "lat": 46.16666793823242,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "093043",
      "nome": "Sesto al Reghena",
      "nomeStraniero": null,
      "codiceCatastale": "I686",
      "cap": "33079",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@com-sesto-al-reghena.regione.fvg.it",
      "pec": "comune.sestoalreghena@certgov.fvg.it",
      "telefono": "+39 0434/693910",
      "fax": "+39 0434/699500",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 12.816666603088379
      }
    },
    {
      "codice": "093044",
      "nome": "Spilimbergo",
      "nomeStraniero": null,
      "codiceCatastale": "I904",
      "cap": "33097",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "protocollo@comune.spilimbergo.pn.it",
      "pec": "comune.spilimbergo@certgov.fvg.it",
      "telefono": "+39 0427/591111",
      "fax": "+39 0427/591112",
      "coordinate": {
        "lat": 46.11138916015625,
        "lng": 12.901666641235352
      }
    },
    {
      "codice": "093045",
      "nome": "Tramonti di Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "L324",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@comune.tramontidisopra.pn.it",
      "pec": "comune.tramontidisopra@certgov.fvg.it",
      "telefono": "+39 0427/869012",
      "fax": "+39 0427/869263",
      "coordinate": {
        "lat": 46.31666564941406,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "093046",
      "nome": "Tramonti di Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "L325",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@com-tramonti-di-sotto.regione.fvg.it",
      "pec": "comune.tramontidisotto@certgov.fvg.it",
      "telefono": "+39 0427/869017",
      "fax": "+39 0427/869010",
      "coordinate": {
        "lat": 46.28333282470703,
        "lng": 12.800000190734863
      }
    },
    {
      "codice": "093047",
      "nome": "Travesio",
      "nomeStraniero": null,
      "codiceCatastale": "L347",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "segreteria@com-travesio.regione.fvg.it",
      "pec": "comune.travesio@certgov.fvg.it",
      "telefono": "+39 0427/90235",
      "fax": "+39 0427/907985",
      "coordinate": {
        "lat": 46.197898864746094,
        "lng": 12.869999885559082
      }
    },
    {
      "codice": "093052",
      "nome": "Vajont",
      "nomeStraniero": null,
      "codiceCatastale": "M265",
      "cap": "33080",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "anagrafe@com-vajont.regione.fvg.it",
      "pec": "comune.vajont@certgov.fvg.it",
      "telefono": "+39 0427/71395",
      "fax": "+39 0427/701244",
      "coordinate": {
        "lat": 46.147098541259766,
        "lng": 12.696200370788574
      }
    },
    {
      "codice": "093053",
      "nome": "Valvasone Arzene",
      "nomeStraniero": null,
      "codiceCatastale": "M346",
      "cap": "33098",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "segreteria@valvasonearzene.it",
      "pec": "comune.valvasonearzene@certgov.fvg.it",
      "telefono": "+39 043489081",
      "fax": "+39 0434899220",
      "coordinate": {
        "lat": 46.002201080322266,
        "lng": 12.847700119018555
      }
    },
    {
      "codice": "093049",
      "nome": "Vito d'Asio",
      "nomeStraniero": null,
      "codiceCatastale": "M085",
      "cap": "33090",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "affari.generali@comune.vitodasio.pn.it",
      "pec": "comune.vitodasio@certgov.fvg.it",
      "telefono": "+39 0427/80136",
      "fax": "+39 0427/80692",
      "coordinate": {
        "lat": 46.233333587646484,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "093050",
      "nome": "Vivaro",
      "nomeStraniero": null,
      "codiceCatastale": "M096",
      "cap": "33099",
      "prefisso": "0427",
      "provincia": "Pordenone",
      "email": "protocollo@comune.vivaro.pn.it",
      "pec": "comune.vivaro@certgov.fvg.it",
      "telefono": "+39 0427 97015",
      "fax": "+39 0427 97510",
      "coordinate": {
        "lat": 46.076560974121094,
        "lng": 12.779120445251465
      }
    },
    {
      "codice": "093051",
      "nome": "Zoppola",
      "nomeStraniero": null,
      "codiceCatastale": "M190",
      "cap": "33080",
      "prefisso": "0434",
      "provincia": "Pordenone",
      "email": "protocollo@com-zoppola.regione.fvg.it",
      "pec": "comune.zoppola@certgov.fvg.it",
      "telefono": "+39 0434/577555",
      "fax": "+39 0434/574025",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 12.766666412353516
      }
    }
  ],
  "Barletta-Andria-Trani": [
    {
      "codice": "110001",
      "nome": "Andria",
      "nomeStraniero": null,
      "codiceCatastale": "A285",
      "cap": "76123",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "staffsindaco@comune.andria.bt.it",
      "pec": "protocollo@cert.comune.andria.bt.it",
      "telefono": "+39 0883290111",
      "fax": "+39 0883290225",
      "coordinate": {
        "lat": 41.231666564941406,
        "lng": 16.308332443237305
      }
    },
    {
      "codice": "110002",
      "nome": "Barletta",
      "nomeStraniero": null,
      "codiceCatastale": "A669",
      "cap": "76121",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "info@comune.barletta.bt.it",
      "pec": "protocollo@cert.comune.barletta.bt.it",
      "telefono": "+39 0883 578111",
      "fax": "+39 0883 332505",
      "coordinate": {
        "lat": 41.31666564941406,
        "lng": 16.28333282470703
      }
    },
    {
      "codice": "110003",
      "nome": "Bisceglie",
      "nomeStraniero": null,
      "codiceCatastale": "A883",
      "cap": "76011",
      "prefisso": "080",
      "provincia": "Barletta-Andria-Trani",
      "email": "segreteriagenerale@comune.bisceglie.bt.it",
      "pec": "protocollogenerale@cert.comune.bisceglie.bt.it",
      "telefono": "+39 0803950111",
      "fax": "+39 0803950205",
      "coordinate": {
        "lat": 41.24093246459961,
        "lng": 16.50206184387207
      }
    },
    {
      "codice": "110004",
      "nome": "Canosa di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "B619",
      "cap": "76012",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "anagrafe@comune.canosa.bt.it",
      "pec": "protocollo@pec.comune.canosa.bt.it",
      "telefono": "+39 0883 610111",
      "fax": "+39 0883661005",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "110005",
      "nome": "Margherita di Savoia",
      "nomeStraniero": null,
      "codiceCatastale": "E946",
      "cap": "76016",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "anagrafe@comune.margheritadisavoia.it",
      "pec": "ufficio.protocollo@comunemargheritadisavoia.it",
      "telefono": "+39 0883 659111",
      "fax": "+39 0883654016",
      "coordinate": {
        "lat": 41.36666488647461,
        "lng": 16.149999618530273
      }
    },
    {
      "codice": "110006",
      "nome": "Minervino Murge",
      "nomeStraniero": null,
      "codiceCatastale": "F220",
      "cap": "76013",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "comune.minervinomurge@legpec.it",
      "pec": "comune.minervinomurge@legpec.it",
      "telefono": "+39 0883 366212",
      "fax": null,
      "coordinate": {
        "lat": 41.099998474121094,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "110007",
      "nome": "San Ferdinando di Puglia",
      "nomeStraniero": null,
      "codiceCatastale": "H839",
      "cap": "76017",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "segretariogenerale@comune.sanferdinandodipuglia.bt.it",
      "pec": "protocollo@pec.comune.sanferdinandodipuglia.bt.it",
      "telefono": "+39 0883626111",
      "fax": "+39 0883626248",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 16.066667556762695
      }
    },
    {
      "codice": "110008",
      "nome": "Spinazzola",
      "nomeStraniero": null,
      "codiceCatastale": "I907",
      "cap": "76014",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "segreteria@comune.spinazzola.bt.it",
      "pec": "comune.spinazzola@pec.it",
      "telefono": "+39 0883 683811",
      "fax": "+39 0883683292",
      "coordinate": {
        "lat": 40.96666717529297,
        "lng": 16.08333396911621
      }
    },
    {
      "codice": "110009",
      "nome": "Trani",
      "nomeStraniero": null,
      "codiceCatastale": "L328",
      "cap": "76125",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "gabinetto.sindaco@comune.trani.bt.it",
      "pec": "protocollo@cert.comune.trani.bt.it",
      "telefono": "+39 0883 581111",
      "fax": null,
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 16.41666603088379
      }
    },
    {
      "codice": "110010",
      "nome": "Trinitapoli",
      "nomeStraniero": null,
      "codiceCatastale": "B915",
      "cap": "76015",
      "prefisso": "0883",
      "provincia": "Barletta-Andria-Trani",
      "email": "segretario@comune.trinitapoli.bt.it",
      "pec": "protocollo.comuneditrinitapoli@pec.it",
      "telefono": "+39 088363631",
      "fax": null,
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 16.100000381469727
      }
    }
  ],
  "Arezzo": [
    {
      "codice": "051001",
      "nome": "Anghiari",
      "nomeStraniero": null,
      "codiceCatastale": "A291",
      "cap": "52031",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "protocollo@comune.anghiari.ar.it",
      "pec": "comune.anghiari.ar@postacert.toscana.it",
      "telefono": "+39 0575/789522",
      "fax": "+39 0575/789947",
      "coordinate": {
        "lat": 43.54166793823242,
        "lng": 12.055000305175781
      }
    },
    {
      "codice": "051002",
      "nome": "Arezzo",
      "nomeStraniero": null,
      "codiceCatastale": "A390",
      "cap": "52100",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "protocollo@comune.arezzo.it",
      "pec": "comune.arezzo@postacert.toscana.it",
      "telefono": "+39 0575377603",
      "fax": "+39 0575377656",
      "coordinate": {
        "lat": 43.46305465698242,
        "lng": 11.878055572509766
      }
    },
    {
      "codice": "051003",
      "nome": "Badia Tedalda",
      "nomeStraniero": null,
      "codiceCatastale": "A541",
      "cap": "53032",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "ragioneria@comunebadia.it",
      "pec": "anagrafe@pec.comunebadia.it",
      "telefono": "+39 0575/714020",
      "fax": "+39 0575/714135",
      "coordinate": {
        "lat": 43.70805740356445,
        "lng": 12.187222480773926
      }
    },
    {
      "codice": "051004",
      "nome": "Bibbiena",
      "nomeStraniero": null,
      "codiceCatastale": "A851",
      "cap": "52011",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "anagrafe@comunedibibbiena.gov.it",
      "pec": "bibbiena@postacert.toscana.it",
      "telefono": "+39 0575/530601",
      "fax": "+39 0575/530667",
      "coordinate": {
        "lat": 43.6974983215332,
        "lng": 11.814444541931152
      }
    },
    {
      "codice": "051005",
      "nome": "Bucine",
      "nomeStraniero": null,
      "codiceCatastale": "B243",
      "cap": "52021",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "anagrafe@comune.bucine.ar.it",
      "pec": "info@pec.comune.bucine.ar.it",
      "telefono": "+39 055/991271",
      "fax": "+39 055/9912778",
      "coordinate": {
        "lat": 43.477500915527344,
        "lng": 11.615833282470703
      }
    },
    {
      "codice": "051006",
      "nome": "Capolona",
      "nomeStraniero": null,
      "codiceCatastale": "B670",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "info@comune.capolona.ar.it",
      "pec": "c.capolona@postacert.toscana.it",
      "telefono": "+39 0575/421317",
      "fax": "+39 0575/420456",
      "coordinate": {
        "lat": 43.567779541015625,
        "lng": 11.86472225189209
      }
    },
    {
      "codice": "051007",
      "nome": "Caprese Michelangelo",
      "nomeStraniero": null,
      "codiceCatastale": "B693",
      "cap": "52033",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "sindaco@capresemichelangelo.net",
      "pec": "sindaco.capresemichelangelo@pec.it",
      "telefono": "+39 0575/793912",
      "fax": "+39 0575/793912",
      "coordinate": {
        "lat": 43.64083480834961,
        "lng": 11.985833168029785
      }
    },
    {
      "codice": "051008",
      "nome": "Castel Focognano",
      "nomeStraniero": null,
      "codiceCatastale": "C102",
      "cap": "52016",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "castelfocognano@casentino.toscana.it",
      "pec": "comune.castelfocognano@postacert.toscana.it",
      "telefono": "+39 057551541",
      "fax": "+39 05755154327",
      "coordinate": {
        "lat": 43.65336227416992,
        "lng": 11.788000106811523
      }
    },
    {
      "codice": "051010",
      "nome": "Castel San Niccolò",
      "nomeStraniero": null,
      "codiceCatastale": "C263",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "castelsanniccol@casentino.toscana.it",
      "pec": "comune.castelsanniccolo@postacert.toscana.it",
      "telefono": "+39 0575 571001",
      "fax": "+39 0575 571026",
      "coordinate": {
        "lat": 43.745277404785156,
        "lng": 11.710000038146973
      }
    },
    {
      "codice": "051040",
      "nome": "Castelfranco Piandiscò",
      "nomeStraniero": null,
      "codiceCatastale": "M322",
      "cap": "52026",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "info@castelfrancopiandisco.it",
      "pec": "protocollo@pec.comune.castelfranco-piandisco.ar.it",
      "telefono": "+39 0559147711-9631200",
      "fax": "+39 0559149096 9631290",
      "coordinate": {
        "lat": 43.62126541137695,
        "lng": 11.55439281463623
      }
    },
    {
      "codice": "051011",
      "nome": "Castiglion Fibocchi",
      "nomeStraniero": null,
      "codiceCatastale": "C318",
      "cap": "52029",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "affarigenerali@comune.castiglionfibocchi.ar.it",
      "pec": "c.castiglionfibocchi@postacert.toscana.it",
      "telefono": "+39 057547484",
      "fax": "+39 057547516",
      "coordinate": {
        "lat": 43.52888870239258,
        "lng": 11.76361083984375
      }
    },
    {
      "codice": "051012",
      "nome": "Castiglion Fiorentino",
      "nomeStraniero": null,
      "codiceCatastale": "C319",
      "cap": "52043",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "info@comune.castiglionfiorentino.ar.it",
      "pec": "comune.castiglionfiorentino@legalmail.it",
      "telefono": "+39 0575/65641",
      "fax": "+39 0575/680103",
      "coordinate": {
        "lat": 43.34388732910156,
        "lng": 11.918889045715332
      }
    },
    {
      "codice": "051013",
      "nome": "Cavriglia",
      "nomeStraniero": null,
      "codiceCatastale": "C407",
      "cap": "52022",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "anagrafe@comune.cavriglia.ar.it",
      "pec": "comune.cavriglia@postacert.toscana.it",
      "telefono": "+39 055 966971",
      "fax": "+39 055 966503",
      "coordinate": {
        "lat": 43.521568298339844,
        "lng": 11.486435890197754
      }
    },
    {
      "codice": "051014",
      "nome": "Chitignano",
      "nomeStraniero": null,
      "codiceCatastale": "C648",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "chitignano@casentino.toscana.it",
      "pec": "chitignano@postacert.toscana.it",
      "telefono": "+39 0575-596713",
      "fax": "+39 0575-596244",
      "coordinate": {
        "lat": 43.663333892822266,
        "lng": 11.882222175598145
      }
    },
    {
      "codice": "051015",
      "nome": "Chiusi della Verna",
      "nomeStraniero": null,
      "codiceCatastale": "C663",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "chiusidellaverna@casentino.toscana.it",
      "pec": "comune.chiusidellaverna@postacert.toscana.it",
      "telefono": "+39 0575 599611",
      "fax": "+39 0575 599645",
      "coordinate": {
        "lat": 43.698333740234375,
        "lng": 11.936111450195312
      }
    },
    {
      "codice": "051016",
      "nome": "Civitella in Val di Chiana",
      "nomeStraniero": null,
      "codiceCatastale": "C774",
      "cap": "52041",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "comune@civichiana.it",
      "pec": "civichiana@postacert.toscana.it",
      "telefono": "+39 05754451",
      "fax": "+39 0575445350",
      "coordinate": {
        "lat": 43.405277252197266,
        "lng": 11.77055549621582
      }
    },
    {
      "codice": "051017",
      "nome": "Cortona",
      "nomeStraniero": null,
      "codiceCatastale": "D077",
      "cap": "52044",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "info@comunedicortona.it",
      "pec": "protocollo@pec.comune.cortona.ar.it",
      "telefono": "+39 05756371",
      "fax": "+39 0575637280",
      "coordinate": {
        "lat": 43.27555465698242,
        "lng": 11.988055229187012
      }
    },
    {
      "codice": "051018",
      "nome": "Foiano della Chiana",
      "nomeStraniero": null,
      "codiceCatastale": "D649",
      "cap": "52045",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "elettorale@comune.foiano.ar.it",
      "pec": "comunefoiano@legalmail.it",
      "telefono": "+39 0575 6431",
      "fax": "+39 0575/643205",
      "coordinate": {
        "lat": 43.25666809082031,
        "lng": 11.816389083862305
      }
    },
    {
      "codice": "051042",
      "nome": "Laterina Pergine Valdarno",
      "nomeStraniero": null,
      "codiceCatastale": "M392",
      "cap": "52019",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "servizidemografici@laterinaperginevaldarno.it",
      "pec": "comune.laterinaperginevaldarno@postacert.toscana.it",
      "telefono": "+39 0575806161",
      "fax": "+39 0575894626",
      "coordinate": {
        "lat": 43.47858428955078,
        "lng": 11.684989929199219
      }
    },
    {
      "codice": "051020",
      "nome": "Loro Ciuffenna",
      "nomeStraniero": null,
      "codiceCatastale": "E693",
      "cap": "52024",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "demografico@comune.loro-ciuffenna.ar.it",
      "pec": "comune.loro-ciuffenna@postacert.toscana.it",
      "telefono": "+39 0559170135",
      "fax": "+39 0559172977",
      "coordinate": {
        "lat": 43.586666107177734,
        "lng": 11.62861156463623
      }
    },
    {
      "codice": "051021",
      "nome": "Lucignano",
      "nomeStraniero": null,
      "codiceCatastale": "E718",
      "cap": "52046",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "protocollo@comune.lucignano.ar.it",
      "pec": "comune.lucignano@postacert.toscana.it",
      "telefono": "+39 0575 83801",
      "fax": "+39 0575 838026",
      "coordinate": {
        "lat": 43.27452850341797,
        "lng": 11.746013641357422
      }
    },
    {
      "codice": "051022",
      "nome": "Marciano della Chiana",
      "nomeStraniero": null,
      "codiceCatastale": "E933",
      "cap": "52047",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "marciano.info@comune.marcianodellachiana.ar.it",
      "pec": "comunemarcianodellachiana@pec.it",
      "telefono": "+39 0575 84081",
      "fax": "+39 0575 845432",
      "coordinate": {
        "lat": 43.30583190917969,
        "lng": 11.787500381469727
      }
    },
    {
      "codice": "051025",
      "nome": "Monte San Savino",
      "nomeStraniero": null,
      "codiceCatastale": "F628",
      "cap": "52048",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "comune@citymonte.it",
      "pec": "comune@pec.citymonte.it",
      "telefono": "+39 0575 81771",
      "fax": "+39 0575 843076",
      "coordinate": {
        "lat": 43.33027648925781,
        "lng": 11.730833053588867
      }
    },
    {
      "codice": "051023",
      "nome": "Montemignaio",
      "nomeStraniero": null,
      "codiceCatastale": "F565",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "sindaco.montemignaio@casentino.toscana.it",
      "pec": "comune.montemignaio@postacert.toscana.it",
      "telefono": "+39 0575-542013",
      "fax": "+39 0575-542382",
      "coordinate": {
        "lat": 43.74000930786133,
        "lng": 11.620129585266113
      }
    },
    {
      "codice": "051024",
      "nome": "Monterchi",
      "nomeStraniero": null,
      "codiceCatastale": "F594",
      "cap": "52035",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "servizidemografici@comune.monterchi.ar.it",
      "pec": "comune.monterchi@postacert.toscana.it",
      "telefono": "+39 0575 70092",
      "fax": "+39 0575 70332",
      "coordinate": {
        "lat": 43.489166259765625,
        "lng": 12.112222671508789
      }
    },
    {
      "codice": "051026",
      "nome": "Montevarchi",
      "nomeStraniero": null,
      "codiceCatastale": "F656",
      "cap": "52025",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "urp@comune.montevarchi.ar.it",
      "pec": "comune.montevarchi@postacert.toscana.it",
      "telefono": "+39 055-91081",
      "fax": "+39 055-9108246",
      "coordinate": {
        "lat": 43.52339553833008,
        "lng": 11.567866325378418
      }
    },
    {
      "codice": "051027",
      "nome": "Ortignano Raggiolo",
      "nomeStraniero": null,
      "codiceCatastale": "G139",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "ortignanoraggiolo@casentino.toscana.it",
      "pec": "comune.ortignanoraggiolo@postacert.toscana.it",
      "telefono": "+39 0575-539214",
      "fax": "+39 0575-539369.",
      "coordinate": {
        "lat": 43.68083190917969,
        "lng": 11.749722480773926
      }
    },
    {
      "codice": "051030",
      "nome": "Pieve Santo Stefano",
      "nomeStraniero": null,
      "codiceCatastale": "G653",
      "cap": "52036",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "info@pievesantostefano.net",
      "pec": "protocollo.pievesantostefano@postacert.toscana.it",
      "telefono": "+39 0575 79771",
      "fax": "+39 0575 797912",
      "coordinate": {
        "lat": 43.67250061035156,
        "lng": 12.042222023010254
      }
    },
    {
      "codice": "051031",
      "nome": "Poppi",
      "nomeStraniero": null,
      "codiceCatastale": "G879",
      "cap": "52014",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "poppi@casentino.toscana.it",
      "pec": "c.poppi@postacert.toscana.it",
      "telefono": "+39 05755021",
      "fax": "+39 0575502222",
      "coordinate": {
        "lat": 43.73583221435547,
        "lng": 11.761666297912598
      }
    },
    {
      "codice": "051041",
      "nome": "Pratovecchio Stia",
      "nomeStraniero": null,
      "codiceCatastale": "M329",
      "cap": "52015",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "stia@casentino.toscana.it",
      "pec": "c.pratovecchiostia@postacert.toscana.it",
      "telefono": "+39 0575/504002",
      "fax": "+39 0575/503890",
      "coordinate": {
        "lat": 43.801536560058594,
        "lng": 11.706734657287598
      }
    },
    {
      "codice": "051033",
      "nome": "San Giovanni Valdarno",
      "nomeStraniero": null,
      "codiceCatastale": "H901",
      "cap": "52027",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "elettorale@comunesgv.it",
      "pec": "protocollo@pec.comunesgv.it",
      "telefono": "+39 055 91261",
      "fax": "+39 055 9121424",
      "coordinate": {
        "lat": 43.56444549560547,
        "lng": 11.532777786254883
      }
    },
    {
      "codice": "051034",
      "nome": "Sansepolcro",
      "nomeStraniero": null,
      "codiceCatastale": "I155",
      "cap": "52037",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "demografici@comune.sansepolcro.ar.it",
      "pec": "protocollo.comunesansepolcro@legalmail.it",
      "telefono": "+39 05757321",
      "fax": "+39 0575732263",
      "coordinate": {
        "lat": 43.57555389404297,
        "lng": 12.143888473510742
      }
    },
    {
      "codice": "051035",
      "nome": "Sestino",
      "nomeStraniero": null,
      "codiceCatastale": "I681",
      "cap": "52038",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "info@comunedisestino.it",
      "pec": "comune.sestino@postacert.toscana.it",
      "telefono": "+39 0575.772718",
      "fax": "+39 0575.772615",
      "coordinate": {
        "lat": 43.71055603027344,
        "lng": 12.29888916015625
      }
    },
    {
      "codice": "051037",
      "nome": "Subbiano",
      "nomeStraniero": null,
      "codiceCatastale": "I991",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "demografici@comune.subbiano.ar.it",
      "pec": "c.subbiano@postacert.toscana.it",
      "telefono": "+39 0575 421723",
      "fax": "+39 0575 421742",
      "coordinate": {
        "lat": 43.58000183105469,
        "lng": 11.872221946716309
      }
    },
    {
      "codice": "051038",
      "nome": "Talla",
      "nomeStraniero": null,
      "codiceCatastale": "L038",
      "cap": "52010",
      "prefisso": "0575",
      "provincia": "Arezzo",
      "email": "talla@casentino.toscana.it",
      "pec": "c.talla@postacert.toscana.it",
      "telefono": "+39 0575 597512",
      "fax": "+39 0575 597693",
      "coordinate": {
        "lat": 43.602779388427734,
        "lng": 11.788055419921875
      }
    },
    {
      "codice": "051039",
      "nome": "Terranuova Bracciolini",
      "nomeStraniero": null,
      "codiceCatastale": "L123",
      "cap": "52028",
      "prefisso": "055",
      "provincia": "Arezzo",
      "email": "terranuova@val.it",
      "pec": "protocollo.terranuovabracciolini@cert.legalmail.it",
      "telefono": "+39 055 919471",
      "fax": "+39 055 9199656",
      "coordinate": {
        "lat": 43.55305480957031,
        "lng": 11.589444160461426
      }
    }
  ],
  "Venezia": [
    {
      "codice": "027001",
      "nome": "Annone Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "A302",
      "cap": "30020",
      "prefisso": "0422",
      "provincia": "Venezia",
      "email": "segretario@comune.annoneveneto.ve.it",
      "pec": "comuneannoneveneto.ve@legalmail.it",
      "telefono": "+39 0422769702",
      "fax": "+39 0422769949",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "027002",
      "nome": "Campagna Lupia",
      "nomeStraniero": null,
      "codiceCatastale": "B493",
      "cap": "30010",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "anagrafe@comune.campagnalupia.ve.it",
      "pec": "anagrafe.comune.campagnalupia.ve@pecveneto.it",
      "telefono": "+39 041 5145913",
      "fax": "+39 041 5145940",
      "coordinate": {
        "lat": 45.349998474121094,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "027003",
      "nome": "Campolongo Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "B546",
      "cap": "30010",
      "prefisso": "049",
      "provincia": "Venezia",
      "email": "robertino.bazzetto@comune.campolongo.ve.it",
      "pec": "comune.campolongo.ve@pecveneto.it",
      "telefono": "+39 0495849129",
      "fax": "+39 0495849151",
      "coordinate": {
        "lat": 45.330535888671875,
        "lng": 12.04831314086914
      }
    },
    {
      "codice": "027004",
      "nome": "Camponogara",
      "nomeStraniero": null,
      "codiceCatastale": "B554",
      "cap": "30010",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "segretario@comune.camponogara.ve.it",
      "pec": "comune.camponogara@pec.tuni.it",
      "telefono": "+39 041 5139917",
      "fax": "+39 041 5139938",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 12.066666603088379
      }
    },
    {
      "codice": "027005",
      "nome": "Caorle",
      "nomeStraniero": null,
      "codiceCatastale": "B642",
      "cap": "30021",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "administrator@comune.caorle.ve.it",
      "pec": "comune.caorle.ve@pecveneto.it",
      "telefono": "+39 0421219298",
      "fax": "+39 0421219300",
      "coordinate": {
        "lat": 45.60026168823242,
        "lng": 12.887575149536133
      }
    },
    {
      "codice": "027044",
      "nome": "Cavallino-Treporti",
      "nomeStraniero": null,
      "codiceCatastale": "M308",
      "cap": "30010",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "segreteria@comunecavallinotreporti.it",
      "pec": "protocollo.comune.cavallinotreporti.ve@pecveneto.it",
      "telefono": "+39 041 2909711",
      "fax": "+39 041 5301039",
      "coordinate": {
        "lat": 45.48249816894531,
        "lng": 12.562899589538574
      }
    },
    {
      "codice": "027006",
      "nome": "Cavarzere",
      "nomeStraniero": null,
      "codiceCatastale": "C383",
      "cap": "30014",
      "prefisso": "0426",
      "provincia": "Venezia",
      "email": "comune@pec.comune.cavarzere.ve.it",
      "pec": "comune@pec.comune.cavarzere.ve.it",
      "telefono": "+39 0426 317111",
      "fax": "+39 0426 317185",
      "coordinate": {
        "lat": 45.13701248168945,
        "lng": 12.081713676452637
      }
    },
    {
      "codice": "027007",
      "nome": "Ceggia",
      "nomeStraniero": null,
      "codiceCatastale": "C422",
      "cap": "30022",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "comuneceggia@comune.ceggia.ve.it",
      "pec": "protocollo.comune.ceggia.ve@pecveneto.it",
      "telefono": "+39 0421 329621",
      "fax": "+39 0421 329703",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 12.633333206176758
      }
    },
    {
      "codice": "027008",
      "nome": "Chioggia",
      "nomeStraniero": null,
      "codiceCatastale": "C638",
      "cap": "30015",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "protocollo@chioggia.org",
      "pec": "chioggia@pec.chioggia.org",
      "telefono": "+39 041 5534811",
      "fax": "+39 041 401839",
      "coordinate": {
        "lat": 45.218910217285156,
        "lng": 12.278539657592773
      }
    },
    {
      "codice": "027009",
      "nome": "Cinto Caomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "C714",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "protocollo.cm.cintocao.ve@pecveneto.it",
      "pec": "comune.cintocao.ve@pecveneto.it",
      "telefono": "+39 0421 209534",
      "fax": "+39 0421 241030",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "027010",
      "nome": "Cona",
      "nomeStraniero": null,
      "codiceCatastale": "C938",
      "cap": "30010",
      "prefisso": "0426",
      "provincia": "Venezia",
      "email": "protocollo@comune.cona.ve.it",
      "pec": "comune.cona@pec.it",
      "telefono": "+39 0426 308911",
      "fax": "+39 0426 308030",
      "coordinate": {
        "lat": 45.20000076293945,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "027011",
      "nome": "Concordia Sagittaria",
      "nomeStraniero": null,
      "codiceCatastale": "C950",
      "cap": "30023",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "comune@comune.concordiasagittaria.ve.it",
      "pec": "comune.concordiasagittaria.ve@pecveneto.it",
      "telefono": "+39 0421270360",
      "fax": "+39 0421270216",
      "coordinate": {
        "lat": 45.766666412353516,
        "lng": 12.850000381469727
      }
    },
    {
      "codice": "027012",
      "nome": "Dolo",
      "nomeStraniero": null,
      "codiceCatastale": "D325",
      "cap": "30031",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "affari.generali@comune.dolo.ve.it",
      "pec": "protocollo.comune.dolo.ve@pecveneto.it",
      "telefono": "+39 041 5121911",
      "fax": "+39 041 410665",
      "coordinate": {
        "lat": 45.42488098144531,
        "lng": 12.075810432434082
      }
    },
    {
      "codice": "027013",
      "nome": "Eraclea",
      "nomeStraniero": null,
      "codiceCatastale": "D415",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "ced@comune.eraclea.ve.it",
      "pec": "protocollo.comune.eraclea.ve@pecveneto.it",
      "telefono": "+39 0421 234272",
      "fax": "+39 0421 234150",
      "coordinate": {
        "lat": 45.58333206176758,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "027014",
      "nome": "Fiesso d'Artico",
      "nomeStraniero": null,
      "codiceCatastale": "D578",
      "cap": "30032",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "fiessodartico@comune.fiessodartico.ve.it",
      "pec": "comunefiessodartico.ve@legalmail.it",
      "telefono": "+39 0415137118",
      "fax": "+39 0415160281",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 12.033333778381348
      }
    },
    {
      "codice": "027015",
      "nome": "Fossalta di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "D740",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "info@comunefossaltadipiave.it",
      "pec": "protocollo@pec.comunefossaltadipiave.it",
      "telefono": "+39 0421 679644",
      "fax": "+39 0421 679697",
      "coordinate": {
        "lat": 45.647499084472656,
        "lng": 12.512299537658691
      }
    },
    {
      "codice": "027016",
      "nome": "Fossalta di Portogruaro",
      "nomeStraniero": null,
      "codiceCatastale": "D741",
      "cap": "30025",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "info@comune.fossaltadiportogruaro.ve.it",
      "pec": "protocol.comune.fossaltadiportogruaro.ve@pecveneto.it",
      "telefono": "+39 0421 249511",
      "fax": "+39 0421 249579",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 12.916666984558105
      }
    },
    {
      "codice": "027017",
      "nome": "Fossò",
      "nomeStraniero": null,
      "codiceCatastale": "D748",
      "cap": "30030",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "urp@comune.fosso.ve.it",
      "pec": "amministrazione@pec.comune.fosso.ve.it",
      "telefono": "+39 0415172311",
      "fax": "+39 0415172310",
      "coordinate": {
        "lat": 45.38333511352539,
        "lng": 12.050000190734863
      }
    },
    {
      "codice": "027018",
      "nome": "Gruaro",
      "nomeStraniero": null,
      "codiceCatastale": "E215",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "area.amministrativa@comune.gruaro.ve.it",
      "pec": "comune.gruaro.ve@pecveneto.it",
      "telefono": "+39 0421/206371",
      "fax": "+39 0421/208413",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "027019",
      "nome": "Jesolo",
      "nomeStraniero": null,
      "codiceCatastale": "C388",
      "cap": "30016",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "comunicazione@comune.jesolo.ve.it",
      "pec": "comune.jesolo@legalmail.it",
      "telefono": "+39 0421 359111",
      "fax": "+39 0421 359360",
      "coordinate": {
        "lat": 45.533111572265625,
        "lng": 12.644749641418457
      }
    },
    {
      "codice": "027020",
      "nome": "Marcon",
      "nomeStraniero": null,
      "codiceCatastale": "E936",
      "cap": "30020",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "segreteria@comune.marcon.ve.it",
      "pec": "protocollo.comune.marcon.ve@pecveneto.it",
      "telefono": "+39 0415997222",
      "fax": "+39 0414567143",
      "coordinate": {
        "lat": 45.55432891845703,
        "lng": 12.299382209777832
      }
    },
    {
      "codice": "027021",
      "nome": "Martellago",
      "nomeStraniero": null,
      "codiceCatastale": "E980",
      "cap": "30030",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "ced@comune.martellago.ve.it",
      "pec": "comune.martellago@pecveneto.it",
      "telefono": "+39 0415403221",
      "fax": "+39 0415402363",
      "coordinate": {
        "lat": 45.54666519165039,
        "lng": 12.157500267028809
      }
    },
    {
      "codice": "027022",
      "nome": "Meolo",
      "nomeStraniero": null,
      "codiceCatastale": "F130",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "meolo@comune.meolo.ve.it",
      "pec": "comune.meolo.ve@pecveneto.it",
      "telefono": "+39 0421 61283",
      "fax": "+39 0421 618706",
      "coordinate": {
        "lat": 45.62030029296875,
        "lng": 12.455900192260742
      }
    },
    {
      "codice": "027023",
      "nome": "Mira",
      "nomeStraniero": null,
      "codiceCatastale": "F229",
      "cap": "30034",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "info@comune.mira.ve.it",
      "pec": "comune.mira.ve@pecveneto.it",
      "telefono": "+39 041 5628211",
      "fax": "+39 041 422023",
      "coordinate": {
        "lat": 45.4375114440918,
        "lng": 12.13290023803711
      }
    },
    {
      "codice": "027024",
      "nome": "Mirano",
      "nomeStraniero": null,
      "codiceCatastale": "F241",
      "cap": "30035",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "sportello.unico@comune.mirano.ve.it",
      "pec": "protocollo.comune.mirano.ve@pecveneto.it",
      "telefono": "+39 041 5798311",
      "fax": "+39 041 5798329",
      "coordinate": {
        "lat": 45.5,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "027025",
      "nome": "Musile di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "F826",
      "cap": "30024",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "giovanni.angellotti@comune.musile.ve.it",
      "pec": "comune.musiledipiave.ve@pecveneto.it",
      "telefono": "+39 0421 592210",
      "fax": "+39 0421 592243",
      "coordinate": {
        "lat": 45.617801666259766,
        "lng": 12.5649995803833
      }
    },
    {
      "codice": "027026",
      "nome": "Noale",
      "nomeStraniero": null,
      "codiceCatastale": "F904",
      "cap": "30033",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "noale@comune.noale.ve.it",
      "pec": "comune.noale.ve@legalmail.it",
      "telefono": "+39 0415897211",
      "fax": "+39 0415897242",
      "coordinate": {
        "lat": 45.55012130737305,
        "lng": 12.070899963378906
      }
    },
    {
      "codice": "027027",
      "nome": "Noventa di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "F963",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "protocollo@comune.noventadipiave.ve.it",
      "pec": "comune.noventadipiave.ve@pecveneto.it",
      "telefono": "+39 0421 65212",
      "fax": "+39 0421 658604",
      "coordinate": {
        "lat": 45.66666793823242,
        "lng": 12.533333778381348
      }
    },
    {
      "codice": "027028",
      "nome": "Pianiga",
      "nomeStraniero": null,
      "codiceCatastale": "G565",
      "cap": "30030",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "ragioneria@comune.pianiga.ve.it",
      "pec": "segreteria@comune.pianiga.ve.legalmail.it",
      "telefono": "+39 041 5196229",
      "fax": "+39 041 469460",
      "coordinate": {
        "lat": 45.45830154418945,
        "lng": 12.008000373840332
      }
    },
    {
      "codice": "027029",
      "nome": "Portogruaro",
      "nomeStraniero": null,
      "codiceCatastale": "G914",
      "cap": "30026",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "massimo.zanelli@comune.portogruaro.ve.it",
      "pec": "comune.portogruaro.ve@pecveneto.it",
      "telefono": "+39 0421/277287",
      "fax": "+39 0421/71217",
      "coordinate": {
        "lat": 45.78333282470703,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "027030",
      "nome": "Pramaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "G981",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "ced@comune.pramaggiore.ve.it",
      "pec": "protocollo.comune.pramaggiore.ve@pecveneto.it",
      "telefono": "+39 0421 203682",
      "fax": "+39 0421 203685",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "027031",
      "nome": "Quarto d'Altino",
      "nomeStraniero": null,
      "codiceCatastale": "H117",
      "cap": "30020",
      "prefisso": "0422",
      "provincia": "Venezia",
      "email": "servizi.informatici@comunequartodaltino.it",
      "pec": "comune.quartodaltino.ve@pecveneto.it",
      "telefono": "+39 0422 826230",
      "fax": "+39 0422 826241",
      "coordinate": {
        "lat": 45.5786018371582,
        "lng": 12.372699737548828
      }
    },
    {
      "codice": "027032",
      "nome": "Salzano",
      "nomeStraniero": null,
      "codiceCatastale": "H735",
      "cap": "30030",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "info@comune.salzano.ve.it",
      "pec": "comune.salzano.ve@pecveneto.it",
      "telefono": "+39 041 5709711",
      "fax": "+39 041 437311",
      "coordinate": {
        "lat": 45.53333282470703,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "027033",
      "nome": "San Donà di Piave",
      "nomeStraniero": null,
      "codiceCatastale": "H823",
      "cap": "30027",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "carlo.botter@sandonadipiave.net",
      "pec": "protocollo.comune.sandonadipiave.ve@pecveneto.it",
      "telefono": "+39 0421 590761",
      "fax": "+39 0421 50961",
      "coordinate": {
        "lat": 45.629756927490234,
        "lng": 12.564142227172852
      }
    },
    {
      "codice": "027034",
      "nome": "San Michele al Tagliamento",
      "nomeStraniero": null,
      "codiceCatastale": "I040",
      "cap": "30028",
      "prefisso": "0431",
      "provincia": "Venezia",
      "email": "anagrafe@comunesanmichele.it",
      "pec": "comune.sanmichelealtagliamento.ve@pecveneto.it",
      "telefono": "+39 0431 516311",
      "fax": "+39 0431 516312",
      "coordinate": {
        "lat": 45.76361083984375,
        "lng": 12.995277404785156
      }
    },
    {
      "codice": "027036",
      "nome": "San Stino di Livenza",
      "nomeStraniero": null,
      "codiceCatastale": "I373",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "info@sanstino.it",
      "pec": "protocollo.comune.sanstinodilivenza.ve@pecveneto.it",
      "telefono": "+39 0421  473919",
      "fax": "+39 0421  473955",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 12.683333396911621
      }
    },
    {
      "codice": "027035",
      "nome": "Santa Maria di Sala",
      "nomeStraniero": null,
      "codiceCatastale": "I242",
      "cap": "30036",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "info@comune-santamariadisala.it",
      "pec": "santamariadisala@cert.legalmail.it",
      "telefono": "+39 041 486788",
      "fax": "+39 041 487379",
      "coordinate": {
        "lat": 45.5088996887207,
        "lng": 12.036299705505371
      }
    },
    {
      "codice": "027037",
      "nome": "Scorzè",
      "nomeStraniero": null,
      "codiceCatastale": "I551",
      "cap": "30037",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "carla.berto@comune.scorze.ve.it",
      "pec": "comune.scorze.ve@pecveneto.it",
      "telefono": "+39 041 5848259",
      "fax": "+39 041 5848250",
      "coordinate": {
        "lat": 45.57194519042969,
        "lng": 12.108888626098633
      }
    },
    {
      "codice": "027038",
      "nome": "Spinea",
      "nomeStraniero": null,
      "codiceCatastale": "I908",
      "cap": "30038",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "protocollo.comune.spinea.ve.it@pecveneto.it",
      "pec": "protocollo.comune.spinea.ve.it@pecveneto.it",
      "telefono": "+39 041 5071111",
      "fax": null,
      "coordinate": {
        "lat": 45.49306106567383,
        "lng": 12.16055965423584
      }
    },
    {
      "codice": "027039",
      "nome": "Stra",
      "nomeStraniero": null,
      "codiceCatastale": "I965",
      "cap": "30039",
      "prefisso": "049",
      "provincia": "Venezia",
      "email": "stra@comune.stra.ve.it",
      "pec": "protocollo.comune.stra.ve@pecveneto.it",
      "telefono": "+39 049 9804011",
      "fax": "+39 049 504975",
      "coordinate": {
        "lat": 45.41666793823242,
        "lng": 12.25
      }
    },
    {
      "codice": "027040",
      "nome": "Teglio Veneto",
      "nomeStraniero": null,
      "codiceCatastale": "L085",
      "cap": "30025",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "protocollo@comune.teglioveneto.ve.it",
      "pec": "comune.teglioveneto.ve@pecveneto.it",
      "telefono": "+39 0421706025",
      "fax": "+39 0421706802",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 12.883333206176758
      }
    },
    {
      "codice": "027041",
      "nome": "Torre di Mosto",
      "nomeStraniero": null,
      "codiceCatastale": "L267",
      "cap": "30020",
      "prefisso": "0421",
      "provincia": "Venezia",
      "email": "protocollo@comune.torredimosto.ve.it",
      "pec": "protocollo.comune.torredimosto.ve@pecveneto.it",
      "telefono": "+39 0421.324191-324440",
      "fax": "+39 0421 324397",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 12.716666221618652
      }
    },
    {
      "codice": "027042",
      "nome": "Venezia",
      "nomeStraniero": null,
      "codiceCatastale": "L736",
      "cap": "30121",
      "prefisso": "041",
      "provincia": "Venezia",
      "email": "segreteria.sindaco@comune.venezia.it",
      "pec": "protocolloinformatico@pec.comune.venezia.it",
      "telefono": "+39 041/2748720",
      "fax": "+39 041/2748964",
      "coordinate": {
        "lat": 45.43972396850586,
        "lng": 12.331944465637207
      }
    },
    {
      "codice": "027043",
      "nome": "Vigonovo",
      "nomeStraniero": null,
      "codiceCatastale": "L899",
      "cap": "30030",
      "prefisso": "049",
      "provincia": "Venezia",
      "email": "area.tecnica@comune.vigonovo.ve.it",
      "pec": "protocollo.comune.vigonovo.ve@pecveneto.it",
      "telefono": "+39 049/9834903",
      "fax": "+39 049/9834922",
      "coordinate": {
        "lat": 45.385250091552734,
        "lng": 12.007360458374023
      }
    }
  ],
  "Verbano-Cusio-Ossola": [
    {
      "codice": "103001",
      "nome": "Antrona Schieranco",
      "nomeStraniero": null,
      "codiceCatastale": "A317",
      "cap": "28030",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.antronaschieranco.vb.it",
      "pec": "comune.antronaschieranco.vb@legalmail.it",
      "telefono": "+39 0324 51805",
      "fax": "+39 0324 575953",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 8.116666793823242
      }
    },
    {
      "codice": "103002",
      "nome": "Anzola d'Ossola",
      "nomeStraniero": null,
      "codiceCatastale": "A325",
      "cap": "28877",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.anzoladossola.vb.it",
      "pec": "anzola@pec.it",
      "telefono": "+39 0323/83909",
      "fax": "+39 0323/83962",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "103003",
      "nome": "Arizzano",
      "nomeStraniero": null,
      "codiceCatastale": "A409",
      "cap": "28811",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "arizzano@reteunitaria.piemonte.it",
      "pec": "arizzano@pec.it",
      "telefono": "+39 0323 551939",
      "fax": "+39 0323 551588",
      "coordinate": {
        "lat": 45.95527648925781,
        "lng": 8.584444046020508
      }
    },
    {
      "codice": "103004",
      "nome": "Arola",
      "nomeStraniero": null,
      "codiceCatastale": "A427",
      "cap": "28010",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "arola@reteunitaria.piemonte.it",
      "pec": "arola@cert.ruparpiemonte.it",
      "telefono": "+39 0323/821128",
      "fax": "+39 0323/821900",
      "coordinate": {
        "lat": 45.80833435058594,
        "lng": 8.35777759552002
      }
    },
    {
      "codice": "103005",
      "nome": "Aurano",
      "nomeStraniero": null,
      "codiceCatastale": "A497",
      "cap": "28812",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "aurano@tin.it",
      "pec": "aurano@pec.it",
      "telefono": "+39 0323 409315",
      "fax": "+39 0323 409411",
      "coordinate": {
        "lat": 46.00038146972656,
        "lng": 8.58821964263916
      }
    },
    {
      "codice": "103006",
      "nome": "Baceno",
      "nomeStraniero": null,
      "codiceCatastale": "A534",
      "cap": "28861",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "comune.baceno@reteunitaria.piemonte.it",
      "pec": "baceno@pec.it",
      "telefono": "+39 0324/62174",
      "fax": "+39 0324/62581",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 8.316666603088379
      }
    },
    {
      "codice": "103007",
      "nome": "Bannio Anzino",
      "nomeStraniero": null,
      "codiceCatastale": "A610",
      "cap": "28071",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "bannio.anzino.@ruparpiemonte.it",
      "pec": "comune.bannioanzino@legalmail.it",
      "telefono": "+39 0324 89104",
      "fax": "+39 0324 89657",
      "coordinate": {
        "lat": 45.98472213745117,
        "lng": 8.146111488342285
      }
    },
    {
      "codice": "103008",
      "nome": "Baveno",
      "nomeStraniero": null,
      "codiceCatastale": "A725",
      "cap": "28831",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.baveno.vb.it",
      "pec": "baveno@pec.it",
      "telefono": "+39 0323/912311",
      "fax": "+39 0323/925210",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 8.5
      }
    },
    {
      "codice": "103009",
      "nome": "Bee",
      "nomeStraniero": null,
      "codiceCatastale": "A733",
      "cap": "28813",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "anagrafe.bee@reteunitaria.piemonte.it",
      "pec": "bee@cert.ruparpiemonte.it",
      "telefono": "+39 0323/56100",
      "fax": "+39 0323/56467",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 8.583333015441895
      }
    },
    {
      "codice": "103010",
      "nome": "Belgirate",
      "nomeStraniero": null,
      "codiceCatastale": "A742",
      "cap": "28832",
      "prefisso": "0322",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "anagrafe.belgirate@reteunitaria.piemonte.it",
      "pec": "belgirate@cert.ruparpiemonte.it",
      "telefono": "+39 0322 7244",
      "fax": "+39 0322 77751",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "103011",
      "nome": "Beura-Cardezza",
      "nomeStraniero": null,
      "codiceCatastale": "A834",
      "cap": "28851",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.beuracardezza.vb.it",
      "pec": "tributi.beuracardezza@anutel.it",
      "telefono": "+39 0324/36101",
      "fax": "+39 0324/36208",
      "coordinate": {
        "lat": 46.08333206176758,
        "lng": 8.300000190734863
      }
    },
    {
      "codice": "103012",
      "nome": "Bognanco",
      "nomeStraniero": null,
      "codiceCatastale": "A925",
      "cap": "28842",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.bognanco.vb.it",
      "pec": "comune.bognanco@pecentilocali.it",
      "telefono": "+39 0324234116",
      "fax": "+39 0324234119",
      "coordinate": {
        "lat": 46.1261100769043,
        "lng": 8.199999809265137
      }
    },
    {
      "codice": "103078",
      "nome": "Borgomezzavalle",
      "nomeStraniero": null,
      "codiceCatastale": "M370",
      "cap": "28841",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "info@comune.borgomezzavalle.vb.it",
      "pec": "comune.borgomezzavalle.vb@legalmail.it",
      "telefono": "+39 0324 56002",
      "fax": "+39 0324 56348",
      "coordinate": {
        "lat": 46.057777404785156,
        "lng": 8.21583366394043
      }
    },
    {
      "codice": "103013",
      "nome": "Brovello-Carpugnino",
      "nomeStraniero": null,
      "codiceCatastale": "B207",
      "cap": "28833",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.brovellocarpugnino.vb.it",
      "pec": "brovello.carpugnino@cert.ruparpiemonte.it",
      "telefono": "+39 0323 929404",
      "fax": "+39 0323 929519",
      "coordinate": {
        "lat": 45.84527587890625,
        "lng": 8.533611297607422
      }
    },
    {
      "codice": "103014",
      "nome": "Calasca-Castiglione",
      "nomeStraniero": null,
      "codiceCatastale": "B380",
      "cap": "28873",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "comune@comune.calascacastiglione.vb.it",
      "pec": "comune.calascacastiglione@legalmail.it",
      "telefono": "+39 032481007",
      "fax": "+39 032481251",
      "coordinate": {
        "lat": 46.00339126586914,
        "lng": 8.169089317321777
      }
    },
    {
      "codice": "103015",
      "nome": "Cambiasca",
      "nomeStraniero": null,
      "codiceCatastale": "B463",
      "cap": "28814",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "cambiasca@ruparpiemonte.it",
      "pec": "comune.cambiasca@legalmail.it",
      "telefono": "+39 0323 553777",
      "fax": "+39 0323 553711",
      "coordinate": {
        "lat": 45.963890075683594,
        "lng": 8.540555953979492
      }
    },
    {
      "codice": "103016",
      "nome": "Cannero Riviera",
      "nomeStraniero": null,
      "codiceCatastale": "B610",
      "cap": "28021",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "canneroriviera@libero.it",
      "pec": "canneroriviera@mailcertificata.net",
      "telefono": "+39 0323788091",
      "fax": "+39 0323788127",
      "coordinate": {
        "lat": 46.016666412353516,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "103017",
      "nome": "Cannobio",
      "nomeStraniero": null,
      "codiceCatastale": "B615",
      "cap": "28822",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "comunecannobio@cannobio.net",
      "pec": "protocollo@comunecannobiopec.it",
      "telefono": "+39 0323/738210",
      "fax": "+39 0323/738218",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 8.699999809265137
      }
    },
    {
      "codice": "103018",
      "nome": "Caprezzo",
      "nomeStraniero": null,
      "codiceCatastale": "B694",
      "cap": "28815",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "caprezzo@tin.it",
      "pec": "caprezzo@pec.it",
      "telefono": "+39 0323 559135",
      "fax": "+39 0323 559907",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 8.566666603088379
      }
    },
    {
      "codice": "103019",
      "nome": "Casale Corte Cerro",
      "nomeStraniero": null,
      "codiceCatastale": "B876",
      "cap": "28881",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.casalecortecerro.vb.it",
      "pec": "casalecortecerro@pec.it",
      "telefono": "+39 0323692111",
      "fax": "+39 032360373",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 8.416666984558105
      }
    },
    {
      "codice": "103021",
      "nome": "Ceppo Morelli",
      "nomeStraniero": null,
      "codiceCatastale": "C478",
      "cap": "28875",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "ceppo.morelli@ruparpiemonte.it",
      "pec": "ceppo.morelli@cert.ruparpiemonte.it",
      "telefono": "+39 0324 890106",
      "fax": "+39 0324 890270",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 8.066666603088379
      }
    },
    {
      "codice": "103022",
      "nome": "Cesara",
      "nomeStraniero": null,
      "codiceCatastale": "C567",
      "cap": "28891",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "cesara@ruparpiemonte.it",
      "pec": "cesara@cert.ruparpiemonte.it",
      "telefono": "+39 0323/827115",
      "fax": "+39 0323/827069",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 8.366666793823242
      }
    },
    {
      "codice": "103023",
      "nome": "Cossogno",
      "nomeStraniero": null,
      "codiceCatastale": "D099",
      "cap": "28801",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "cossogno@reteunitaria.piemonte.it",
      "pec": "comunecossogno@mailcertificata.net",
      "telefono": "+39 0323/468108",
      "fax": "+39 0323/468186",
      "coordinate": {
        "lat": 45.9636116027832,
        "lng": 8.509166717529297
      }
    },
    {
      "codice": "103024",
      "nome": "Craveggia",
      "nomeStraniero": null,
      "codiceCatastale": "D134",
      "cap": "28852",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici@comune.craveggia.vb.it",
      "pec": "comune.craveggia@legalmail.it",
      "telefono": "+39 0324/98033",
      "fax": "+39 0324/98440",
      "coordinate": {
        "lat": 46.14055633544922,
        "lng": 8.48972225189209
      }
    },
    {
      "codice": "103025",
      "nome": "Crevoladossola",
      "nomeStraniero": null,
      "codiceCatastale": "D168",
      "cap": "28865",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo.crevoladossola@cert.ruparpiemonte.it",
      "pec": "protocollo.crevoladossola@cert.ruparpiemonte.it",
      "telefono": "+39 0324 239100",
      "fax": "+39 0324 239123",
      "coordinate": {
        "lat": 46.147499084472656,
        "lng": 8.296111106872559
      }
    },
    {
      "codice": "103026",
      "nome": "Crodo",
      "nomeStraniero": null,
      "codiceCatastale": "D177",
      "cap": "28862",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.crodo.vb.it",
      "pec": "comune.crodo.vb@cert.legalmail.it",
      "telefono": "+39 0324/61003",
      "fax": "+39 0324/61684",
      "coordinate": {
        "lat": 46.22249984741211,
        "lng": 8.324166297912598
      }
    },
    {
      "codice": "103028",
      "nome": "Domodossola",
      "nomeStraniero": null,
      "codiceCatastale": "D332",
      "cap": "28845",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici@comune.domodossola.vb.it",
      "pec": "protocollo@pec.comune.domodossola.vb.it",
      "telefono": "+39 0324 492232",
      "fax": "+39 0324 492239",
      "coordinate": {
        "lat": 46.116111755371094,
        "lng": 8.29111099243164
      }
    },
    {
      "codice": "103029",
      "nome": "Druogno",
      "nomeStraniero": null,
      "codiceCatastale": "D374",
      "cap": "28853",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici.druogno@ruparpiemonte.it",
      "pec": "druogno@anutel.it",
      "telefono": "+39 0324/93071",
      "fax": "+39 0324/93272",
      "coordinate": {
        "lat": 46.13472366333008,
        "lng": 8.433055877685547
      }
    },
    {
      "codice": "103031",
      "nome": "Formazza",
      "nomeStraniero": null,
      "codiceCatastale": "D706",
      "cap": "28863",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.formazza.vb.it",
      "pec": "formazza@postemailcertificata.it",
      "telefono": "+39 0324 63017",
      "fax": null,
      "coordinate": {
        "lat": 46.37694549560547,
        "lng": 8.425555229187012
      }
    },
    {
      "codice": "103032",
      "nome": "Germagno",
      "nomeStraniero": null,
      "codiceCatastale": "D984",
      "cap": "28887",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "germagno@reteunitaria.piemonte.it",
      "pec": "germagno@cert.ruparpiemonte.it",
      "telefono": "+39 0323 866836",
      "fax": null,
      "coordinate": {
        "lat": 45.89250183105469,
        "lng": 8.388333320617676
      }
    },
    {
      "codice": "103033",
      "nome": "Ghiffa",
      "nomeStraniero": null,
      "codiceCatastale": "E003",
      "cap": "28823",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.ghiffa.vb.it",
      "pec": "comuneghiffa@pcert.it",
      "telefono": "+39 0323 59110",
      "fax": "+39 0323 59441",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 8.616666793823242
      }
    },
    {
      "codice": "103034",
      "nome": "Gignese",
      "nomeStraniero": null,
      "codiceCatastale": "E028",
      "cap": "28836",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.gignese.vb.it",
      "pec": "gignese@cert.ruparpiemonte.it",
      "telefono": "+39 0323  20067",
      "fax": "+39 0323 20772",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 8.516666412353516
      }
    },
    {
      "codice": "103035",
      "nome": "Gravellona Toce",
      "nomeStraniero": null,
      "codiceCatastale": "E153",
      "cap": "28883",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "segreteria@comune.gravellonatoce.vb.it",
      "pec": "gravellonatoce@pec.it",
      "telefono": "+39 0323 848386",
      "fax": "+39 0323 864168",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "103036",
      "nome": "Gurro",
      "nomeStraniero": null,
      "codiceCatastale": "E269",
      "cap": "28828",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "comune.gurro@ruparpiemonte.it",
      "pec": "protocollo.comune.gurro@pec.it",
      "telefono": "+39 0323 76100",
      "fax": "+39 0323 736280",
      "coordinate": {
        "lat": 46.08527755737305,
        "lng": 8.569722175598145
      }
    },
    {
      "codice": "103037",
      "nome": "Intragna",
      "nomeStraniero": null,
      "codiceCatastale": "E304",
      "cap": "28050",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "intragna@ruparpiemonte.it",
      "pec": "intragna@pec.it",
      "telefono": "+39 0323 409100",
      "fax": "+39 0323 409900",
      "coordinate": {
        "lat": 45.99388885498047,
        "lng": 8.57277774810791
      }
    },
    {
      "codice": "103038",
      "nome": "Loreglia",
      "nomeStraniero": null,
      "codiceCatastale": "E685",
      "cap": "28020",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "loreglia@ruparpiemonte.it",
      "pec": "comune.loreglia@legalmail.it",
      "telefono": "+39 0323 866767",
      "fax": null,
      "coordinate": {
        "lat": 45.90692138671875,
        "lng": 8.36839485168457
      }
    },
    {
      "codice": "103039",
      "nome": "Macugnaga",
      "nomeStraniero": null,
      "codiceCatastale": "E790",
      "cap": "28876",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici@comune.macugnaga.vb.it",
      "pec": "comune.macugnaga.vb@cert.legalmail.it",
      "telefono": "+39 0324 65009",
      "fax": "+39 0324 65817",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 7.9666666984558105
      }
    },
    {
      "codice": "103040",
      "nome": "Madonna del Sasso",
      "nomeStraniero": null,
      "codiceCatastale": "E795",
      "cap": "28010",
      "prefisso": "0163",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.madonnadelsasso.vb.it",
      "pec": "madonna.del.sasso@cert.ruparpiemonte.it",
      "telefono": "+39 0322 981177",
      "fax": "+39 0322 981900",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 8.366666793823242
      }
    },
    {
      "codice": "103041",
      "nome": "Malesco",
      "nomeStraniero": null,
      "codiceCatastale": "E853",
      "cap": "28854",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "info@comune.malesco.vb.it",
      "pec": "malesco@pec.malesco.eu",
      "telefono": "+39 0324 92261",
      "fax": "+39 0324 92645",
      "coordinate": {
        "lat": 46.12666702270508,
        "lng": 8.500555992126465
      }
    },
    {
      "codice": "103042",
      "nome": "Masera",
      "nomeStraniero": null,
      "codiceCatastale": "F010",
      "cap": "28855",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.masera.vb.it",
      "pec": "municipio@pec.comune.masera.vb.it",
      "telefono": "+39 0324 35252",
      "fax": "+39 0324 35993",
      "coordinate": {
        "lat": 46.13666534423828,
        "lng": 8.32638931274414
      }
    },
    {
      "codice": "103043",
      "nome": "Massiola",
      "nomeStraniero": null,
      "codiceCatastale": "F048",
      "cap": "28895",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "mrmassiola@libero.it",
      "pec": "comune.massiola.vb@legalmail.it",
      "telefono": "+39 0323 887922",
      "fax": "+39 0323 879928",
      "coordinate": {
        "lat": 45.91222381591797,
        "lng": 8.320555686950684
      }
    },
    {
      "codice": "103044",
      "nome": "Mergozzo",
      "nomeStraniero": null,
      "codiceCatastale": "F146",
      "cap": "28802",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.mergozzo.vb.it",
      "pec": "mergozzo@pcert.it",
      "telefono": "+39 0323 80101",
      "fax": "+39 0323 80738",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 8.433333396911621
      }
    },
    {
      "codice": "103045",
      "nome": "Miazzina",
      "nomeStraniero": null,
      "codiceCatastale": "F192",
      "cap": "28056",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "miazzina@ruparpiemonte.it",
      "pec": "protocollo.miazzina@cert.ruparpiemonte.it",
      "telefono": "+39 0323 494101",
      "fax": "+39 0323 494928",
      "coordinate": {
        "lat": 45.975276947021484,
        "lng": 8.52388858795166
      }
    },
    {
      "codice": "103046",
      "nome": "Montecrestese",
      "nomeStraniero": null,
      "codiceCatastale": "F483",
      "cap": "28864",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.montecrestese.vb.it",
      "pec": "montecrestese@cert.ruparpiemonte.it",
      "telefono": "+39 0324 35106",
      "fax": "+39 0324 35097",
      "coordinate": {
        "lat": 46.149723052978516,
        "lng": 8.32027816772461
      }
    },
    {
      "codice": "103047",
      "nome": "Montescheno",
      "nomeStraniero": null,
      "codiceCatastale": "F639",
      "cap": "28843",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "montescheno@libero.it",
      "pec": "comune.montescheno.vb@legalmail.it",
      "telefono": "+39 0324 576002",
      "fax": "+39 0324 576002",
      "coordinate": {
        "lat": 46.06666564941406,
        "lng": 8.233333587646484
      }
    },
    {
      "codice": "103048",
      "nome": "Nonio",
      "nomeStraniero": null,
      "codiceCatastale": "F932",
      "cap": "28891",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici.nonio@reteunitaria.piemonte.it",
      "pec": "comune.nonio.vb@legalmail.it",
      "telefono": "+39 0323 889001",
      "fax": "+39 0323 889330",
      "coordinate": {
        "lat": 45.84611129760742,
        "lng": 8.3774995803833
      }
    },
    {
      "codice": "103049",
      "nome": "Oggebbio",
      "nomeStraniero": null,
      "codiceCatastale": "G007",
      "cap": "28824",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comuneoggebbio.com",
      "pec": "comune.oggebbio.vb@legalmail.it",
      "telefono": "+39 0323 48123",
      "fax": "+39 0323  491921",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 8.649999618530273
      }
    },
    {
      "codice": "103050",
      "nome": "Omegna",
      "nomeStraniero": null,
      "codiceCatastale": "G062",
      "cap": "28887",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "ced@comune.omegna.vb.it",
      "pec": "comune.omegna@legalmail.it",
      "telefono": "+39 0323868411",
      "fax": "+39 0323 643569",
      "coordinate": {
        "lat": 45.878055572509766,
        "lng": 8.406944274902344
      }
    },
    {
      "codice": "103051",
      "nome": "Ornavasso",
      "nomeStraniero": null,
      "codiceCatastale": "G117",
      "cap": "28877",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "segreteria@ornavasso.it",
      "pec": "comune.ornavasso@legalmail.it",
      "telefono": "+39 0323 838361",
      "fax": "+39 0323 836494",
      "coordinate": {
        "lat": 45.96666717529297,
        "lng": 8.399999618530273
      }
    },
    {
      "codice": "103052",
      "nome": "Pallanzeno",
      "nomeStraniero": null,
      "codiceCatastale": "G280",
      "cap": "28884",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "segreteria@comune.pallanzeno.vb.it",
      "pec": "pallanzeno@pcert.it",
      "telefono": "+39 0324 51195",
      "fax": "+39 0324 53454",
      "coordinate": {
        "lat": 46.04166793823242,
        "lng": 8.258610725402832
      }
    },
    {
      "codice": "103053",
      "nome": "Piedimulera",
      "nomeStraniero": null,
      "codiceCatastale": "G600",
      "cap": "28885",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.piedimulera.vb.it",
      "pec": "piedimulera@postemailcertificata.it",
      "telefono": "+39 0324 83107",
      "fax": "+39 0324 83579",
      "coordinate": {
        "lat": 46.02361297607422,
        "lng": 8.259166717529297
      }
    },
    {
      "codice": "103054",
      "nome": "Pieve Vergonte",
      "nomeStraniero": null,
      "codiceCatastale": "G658",
      "cap": "28886",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici@comunepievevergonte.it",
      "pec": "pievevergonte@pec.it",
      "telefono": "+39 0324 86122",
      "fax": "+39 0324 86265",
      "coordinate": {
        "lat": 46.00583267211914,
        "lng": 8.269166946411133
      }
    },
    {
      "codice": "103055",
      "nome": "Premeno",
      "nomeStraniero": null,
      "codiceCatastale": "H030",
      "cap": "28818",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "premeno@reteunitaria.piemonte.it",
      "pec": "comune.premeno@legalmail.it",
      "telefono": "+39 0323 587043",
      "fax": "+39 0323 587720",
      "coordinate": {
        "lat": 45.98040008544922,
        "lng": 8.604999542236328
      }
    },
    {
      "codice": "103056",
      "nome": "Premia",
      "nomeStraniero": null,
      "codiceCatastale": "H033",
      "cap": "28866",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "premia@ruparpiemonte.it",
      "pec": "comune.premia.vb@legalmail.it",
      "telefono": "+39 0324 62021",
      "fax": "+39 0324 602928",
      "coordinate": {
        "lat": 46.266666412353516,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "103057",
      "nome": "Premosello-Chiovenda",
      "nomeStraniero": null,
      "codiceCatastale": "H037",
      "cap": "28803",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.premosello.vb.it",
      "pec": "premosello@pec.it",
      "telefono": "+39 0324.88104",
      "fax": "+39 0324.88411",
      "coordinate": {
        "lat": 46,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "103058",
      "nome": "Quarna Sopra",
      "nomeStraniero": null,
      "codiceCatastale": "H106",
      "cap": "28898",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "quarna.sopra@ruparpiemonte.it",
      "pec": "quarnasopra@pcert.it",
      "telefono": "+39 0323 826115",
      "fax": "+39 0323 826391",
      "coordinate": {
        "lat": 45.8738899230957,
        "lng": 8.373055458068848
      }
    },
    {
      "codice": "103059",
      "nome": "Quarna Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "H107",
      "cap": "28896",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.quarnasotto.vb.it",
      "pec": "quarnasotto@pcert.it",
      "telefono": "+39 0323 826117",
      "fax": "+39 0323 826393",
      "coordinate": {
        "lat": 45.86944580078125,
        "lng": 8.363333702087402
      }
    },
    {
      "codice": "103060",
      "nome": "Re",
      "nomeStraniero": null,
      "codiceCatastale": "H203",
      "cap": "28030",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "mariapia.fabri@tiscali.it",
      "pec": "segreteria@pec.comune.re.vb.it",
      "telefono": "3756688984",
      "fax": "+39 0324 97389",
      "coordinate": {
        "lat": 46.13055419921875,
        "lng": 8.544166564941406
      }
    },
    {
      "codice": "103061",
      "nome": "San Bernardino Verbano",
      "nomeStraniero": null,
      "codiceCatastale": "H777",
      "cap": "28804",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "servizidemografici.sbv@ruparpiemonte.it",
      "pec": "san.bernardino.verbano@cert.ruparpiemonte.it",
      "telefono": "+39 0323 571503",
      "fax": "+39 023 553506",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 8.516666412353516
      }
    },
    {
      "codice": "103062",
      "nome": "Santa Maria Maggiore",
      "nomeStraniero": null,
      "codiceCatastale": "I249",
      "cap": "28857",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.santamariamaggiore.vb.it",
      "pec": "comune@pec.santamariamaggiore.eu",
      "telefono": "+39 0324 94213",
      "fax": "+39 0324 94993",
      "coordinate": {
        "lat": 46.13333511352539,
        "lng": 8.466666221618652
      }
    },
    {
      "codice": "103064",
      "nome": "Stresa",
      "nomeStraniero": null,
      "codiceCatastale": "I976",
      "cap": "28838",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "info@comune.stresa.vb.it",
      "pec": "tedeschi@cert.comunestresa.it",
      "telefono": "+39 0323 939111",
      "fax": "+39 0323 939222",
      "coordinate": {
        "lat": 45.883609771728516,
        "lng": 8.539443969726562
      }
    },
    {
      "codice": "103065",
      "nome": "Toceno",
      "nomeStraniero": null,
      "codiceCatastale": "L187",
      "cap": "28030",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "demografici@comune.toceno.vb.it",
      "pec": "comune.toceno@legalmail.it",
      "telefono": "+39 0324/98035",
      "fax": "+39 0324/988813",
      "coordinate": {
        "lat": 46.143611907958984,
        "lng": 8.467222213745117
      }
    },
    {
      "codice": "103066",
      "nome": "Trarego Viggiona",
      "nomeStraniero": null,
      "codiceCatastale": "L333",
      "cap": "28826",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "traregoviggiona@tiscali.it",
      "pec": "comunetraregoviggiona.vb@legalmail.it",
      "telefono": "+39 0323797886",
      "fax": "+39 0323 739914",
      "coordinate": {
        "lat": 46.032100677490234,
        "lng": 8.6908597946167
      }
    },
    {
      "codice": "103067",
      "nome": "Trasquera",
      "nomeStraniero": null,
      "codiceCatastale": "L336",
      "cap": "28868",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.trasquera.vb.it",
      "pec": "comune.trasquera@legalmail.it",
      "telefono": "+39 0324 79120",
      "fax": "+39 0324 793900",
      "coordinate": {
        "lat": 46.21277618408203,
        "lng": 8.214166641235352
      }
    },
    {
      "codice": "103068",
      "nome": "Trontano",
      "nomeStraniero": null,
      "codiceCatastale": "L450",
      "cap": "28859",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.trontano.vb.it",
      "pec": "trontano@cert.ruparpiemonte.it",
      "telefono": "+39 0324 37021",
      "fax": "+39 0324 37138",
      "coordinate": {
        "lat": 46.122222900390625,
        "lng": 8.333333015441895
      }
    },
    {
      "codice": "103079",
      "nome": "Valle Cannobina",
      "nomeStraniero": null,
      "codiceCatastale": "M404",
      "cap": "28827",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "comune@comune.vallecannobina.vb.it",
      "pec": "comunevallecannobina@mailcertificata.net",
      "telefono": "032377102",
      "fax": "032377102",
      "coordinate": {
        "lat": 46.0716667175293,
        "lng": 8.607222557067871
      }
    },
    {
      "codice": "103069",
      "nome": "Valstrona",
      "nomeStraniero": null,
      "codiceCatastale": "L651",
      "cap": "28897",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.valstrona.vb.it",
      "pec": "comune.valstrona@legalmail.it",
      "telefono": "+39 0323 87117",
      "fax": "+39 0323 87265",
      "coordinate": {
        "lat": 45.906944274902344,
        "lng": 8.34000015258789
      }
    },
    {
      "codice": "103070",
      "nome": "Vanzone con San Carlo",
      "nomeStraniero": null,
      "codiceCatastale": "L666",
      "cap": "28879",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "info@comune.vanzoneconsancarlo.vb.it",
      "pec": "comune.vanzoneconsancarlo@legalmail.it",
      "telefono": "+39 0324 828804",
      "fax": "+39 0324 828804",
      "coordinate": {
        "lat": 45.98801040649414,
        "lng": 8.13148021697998
      }
    },
    {
      "codice": "103071",
      "nome": "Varzo",
      "nomeStraniero": null,
      "codiceCatastale": "L691",
      "cap": "28868",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.varzo.vb.it",
      "pec": "comune.varzo@pcert.it",
      "telefono": "+39 0324 7001",
      "fax": "+39 0324 73047",
      "coordinate": {
        "lat": 46.20750045776367,
        "lng": 8.25333309173584
      }
    },
    {
      "codice": "103072",
      "nome": "Verbania",
      "nomeStraniero": null,
      "codiceCatastale": "L746",
      "cap": "28900",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "alberto.cerni@comune.verbania.it",
      "pec": "istituzionale.verbania@legalmail.it",
      "telefono": "+39 0323 542220",
      "fax": "+39 0323 542258",
      "coordinate": {
        "lat": 45.92277908325195,
        "lng": 8.551944732666016
      }
    },
    {
      "codice": "103074",
      "nome": "Vignone",
      "nomeStraniero": null,
      "codiceCatastale": "L889",
      "cap": "28819",
      "prefisso": "0323",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "protocollo@comune.vignone.vb.it",
      "pec": "vignone@cert.ruparpiemonte.it",
      "telefono": "+39 0323551070",
      "fax": "+39 0323 550428",
      "coordinate": {
        "lat": 45.95930099487305,
        "lng": 8.564299583435059
      }
    },
    {
      "codice": "103075",
      "nome": "Villadossola",
      "nomeStraniero": null,
      "codiceCatastale": "L906",
      "cap": "28844",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "segreteria@comune.villadossola.vb.it",
      "pec": "comunedivilladossola@postecert.it",
      "telefono": "+39 0324 501400",
      "fax": "+39 0324 575097",
      "coordinate": {
        "lat": 46.073612213134766,
        "lng": 8.266944885253906
      }
    },
    {
      "codice": "103076",
      "nome": "Villette",
      "nomeStraniero": null,
      "codiceCatastale": "M042",
      "cap": "28856",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "villette@ruparpiemonte.it",
      "pec": "villette@postemailcertificata.it",
      "telefono": "+39 0324 97029",
      "fax": "+39 0324 97029",
      "coordinate": {
        "lat": 46.13277816772461,
        "lng": 8.535277366638184
      }
    },
    {
      "codice": "103077",
      "nome": "Vogogna",
      "nomeStraniero": null,
      "codiceCatastale": "M111",
      "cap": "28805",
      "prefisso": "0324",
      "provincia": "Verbano-Cusio-Ossola",
      "email": "municipio@comune.vogogna.vb.it",
      "pec": "comune.vogogna@legalmail.it",
      "telefono": "+39 032487042",
      "fax": "+39 0324 87663",
      "coordinate": {
        "lat": 46.00777816772461,
        "lng": 8.293611526489258
      }
    }
  ],
  "Macerata": [
    {
      "codice": "043002",
      "nome": "Apiro",
      "nomeStraniero": null,
      "codiceCatastale": "A329",
      "cap": "62021",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "elettorale@apiro.sinp.net",
      "pec": "comune.apiro.mc@legalmail.it",
      "telefono": "+39 0733/611131",
      "fax": "+39 0733611835",
      "coordinate": {
        "lat": 43.393062591552734,
        "lng": 13.130269050598145
      }
    },
    {
      "codice": "043003",
      "nome": "Appignano",
      "nomeStraniero": null,
      "codiceCatastale": "A334",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.appignano.mc.it",
      "pec": "comune.appignano@emarche.it",
      "telefono": "+39 0733/57521",
      "fax": "+39 0733/579169",
      "coordinate": {
        "lat": 43.364131927490234,
        "lng": 13.346419334411621
      }
    },
    {
      "codice": "043004",
      "nome": "Belforte del Chienti",
      "nomeStraniero": null,
      "codiceCatastale": "A739",
      "cap": "62031",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.belfortedelchienti.mc.it",
      "pec": "comune.belforte.mc@legalmail.it",
      "telefono": "+39 0733/951010",
      "fax": "+39 0733/951011",
      "coordinate": {
        "lat": 43.16416931152344,
        "lng": 13.23746395111084
      }
    },
    {
      "codice": "043005",
      "nome": "Bolognola",
      "nomeStraniero": null,
      "codiceCatastale": "A947",
      "cap": "62035",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "info@comune.bolognola.mc.it",
      "pec": "comune@pec.comune.bolognola.mc.it",
      "telefono": "0737520225",
      "fax": "+39 0737/520225",
      "coordinate": {
        "lat": 42.99340057373047,
        "lng": 13.228799819946289
      }
    },
    {
      "codice": "043006",
      "nome": "Caldarola",
      "nomeStraniero": null,
      "codiceCatastale": "B398",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "comune@caldarola.sinp.net",
      "pec": "comune.caldarola.mc@legalmail.it",
      "telefono": "+39 0733/903715",
      "fax": "+39 0733/905808",
      "coordinate": {
        "lat": 43.13816833496094,
        "lng": 13.225138664245605
      }
    },
    {
      "codice": "043007",
      "nome": "Camerino",
      "nomeStraniero": null,
      "codiceCatastale": "B474",
      "cap": "62032",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "elettorale@comune.camerino.mc.it",
      "pec": "protocollo@pec.comune.camerino.mc.it",
      "telefono": "+39 0737/632440",
      "fax": null,
      "coordinate": {
        "lat": 43.13333511352539,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "043008",
      "nome": "Camporotondo di Fiastrone",
      "nomeStraniero": null,
      "codiceCatastale": "B562",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "comune@camporotondo.sinp.net",
      "pec": "comune.camporotondodifiastrone@legalmail.it",
      "telefono": "+39 0733/907153",
      "fax": "+39 0733/907359",
      "coordinate": {
        "lat": 43.13169860839844,
        "lng": 13.265889167785645
      }
    },
    {
      "codice": "043009",
      "nome": "Castelraimondo",
      "nomeStraniero": null,
      "codiceCatastale": "C251",
      "cap": "62022",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "info@comune.castelraimondo.mc.it",
      "pec": "comunecastelraimondo@pec.it",
      "telefono": "+39 0737/640732",
      "fax": "+39 0737/640096",
      "coordinate": {
        "lat": 43.20909881591797,
        "lng": 13.05459976196289
      }
    },
    {
      "codice": "043010",
      "nome": "Castelsantangelo sul Nera",
      "nomeStraniero": null,
      "codiceCatastale": "C267",
      "cap": "62039",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "ragioneria@castelsantangelo.sinp.net",
      "pec": "comune.castelsantangelo.mc@legalmail.it",
      "telefono": "+39 0737/970039",
      "fax": "+39 0737/970041",
      "coordinate": {
        "lat": 42.89461898803711,
        "lng": 13.153310775756836
      }
    },
    {
      "codice": "043011",
      "nome": "Cessapalombo",
      "nomeStraniero": null,
      "codiceCatastale": "C582",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "comune@cessapalombo.sinp.net",
      "pec": "comune.cessapalombo.mc@legalmail.it",
      "telefono": "+39 0733/907132",
      "fax": "+39 0733/907096",
      "coordinate": {
        "lat": 43.1090202331543,
        "lng": 13.258369445800781
      }
    },
    {
      "codice": "043012",
      "nome": "Cingoli",
      "nomeStraniero": null,
      "codiceCatastale": "C704",
      "cap": "62011",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.cingoli.mc.it",
      "pec": "protocollo@pec.comune.cingoli.mc.it",
      "telefono": "+39 0733/601911",
      "fax": "+39 0733/602961",
      "coordinate": {
        "lat": 43.374149322509766,
        "lng": 13.216355323791504
      }
    },
    {
      "codice": "043013",
      "nome": "Civitanova Marche",
      "nomeStraniero": null,
      "codiceCatastale": "C770",
      "cap": "62012",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "paola.recchi@comune.civitanova.mc.it",
      "pec": "comune.civitanovamarche@pec.it",
      "telefono": "+39 0733/822250",
      "fax": "+39 0733/822228",
      "coordinate": {
        "lat": 43.3067512512207,
        "lng": 13.728599548339844
      }
    },
    {
      "codice": "043014",
      "nome": "Colmurano",
      "nomeStraniero": null,
      "codiceCatastale": "C886",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@colmurano.sinp.net",
      "pec": "comune.colmurano.mc@legalmail.it",
      "telefono": "+39 0733/508907",
      "fax": "+39 0733/508112",
      "coordinate": {
        "lat": 43.16400146484375,
        "lng": 13.357000350952148
      }
    },
    {
      "codice": "043015",
      "nome": "Corridonia",
      "nomeStraniero": null,
      "codiceCatastale": "D042",
      "cap": "62014",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@comune.corridonia.mc.it",
      "pec": "comunecorridonia@pec.it",
      "telefono": "+39 0733/439910",
      "fax": "+39 0733/434221",
      "coordinate": {
        "lat": 43.24821853637695,
        "lng": 13.507530212402344
      }
    },
    {
      "codice": "043016",
      "nome": "Esanatoglia",
      "nomeStraniero": null,
      "codiceCatastale": "D429",
      "cap": "62023",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "info@comune.esanatoglia.mc.it",
      "pec": "comune.esanatoglia@emarche.it",
      "telefono": "+39 0737/889132",
      "fax": "+39 0737/889327",
      "coordinate": {
        "lat": 43.25135040283203,
        "lng": 12.94588851928711
      }
    },
    {
      "codice": "043017",
      "nome": "Fiastra",
      "nomeStraniero": null,
      "codiceCatastale": "D564",
      "cap": "62035",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "anagrafe@fiastra.sinp.net",
      "pec": "comune.fiastra.mc@legalmail.it",
      "telefono": "+39 0737/52112",
      "fax": "+39 0737/52570",
      "coordinate": {
        "lat": 43.0359001159668,
        "lng": 13.156200408935547
      }
    },
    {
      "codice": "043019",
      "nome": "Fiuminata",
      "nomeStraniero": null,
      "codiceCatastale": "D628",
      "cap": "62025",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "anagrafe@comune.fiuminata.mc.it",
      "pec": "comune.fiuminata.mc@legalmail.it",
      "telefono": "+39 0737/54122",
      "fax": "+39 0737/54738",
      "coordinate": {
        "lat": 43.1884880065918,
        "lng": 12.931689262390137
      }
    },
    {
      "codice": "043020",
      "nome": "Gagliole",
      "nomeStraniero": null,
      "codiceCatastale": "D853",
      "cap": "62022",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "comune@gagliole.sinp.net",
      "pec": "protocollo@pec.comune.gagliole.mc.it",
      "telefono": "+39 0737/641184",
      "fax": "+39 0737/642434",
      "coordinate": {
        "lat": 43.23908233642578,
        "lng": 13.06779956817627
      }
    },
    {
      "codice": "043021",
      "nome": "Gualdo",
      "nomeStraniero": null,
      "codiceCatastale": "E228",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@gualdo.sinp.net",
      "pec": "comune.gualdo.mc@legalmail.it",
      "telefono": "+39 0733/668122",
      "fax": "+39 0733/668427",
      "coordinate": {
        "lat": 43.06694412231445,
        "lng": 13.338055610656738
      }
    },
    {
      "codice": "043022",
      "nome": "Loro Piceno",
      "nomeStraniero": null,
      "codiceCatastale": "E694",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "ufficioanagrafe@comune.loropiceno.mc.it",
      "pec": "comune.loropiceno.mc@legalmail.it",
      "telefono": "+39 0733/509112",
      "fax": "+39 0733/509785",
      "coordinate": {
        "lat": 43.166259765625,
        "lng": 13.416099548339844
      }
    },
    {
      "codice": "043023",
      "nome": "Macerata",
      "nomeStraniero": null,
      "codiceCatastale": "E783",
      "cap": "62100",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "demografici@comune.macerata.it",
      "pec": "comune.macerata.demografici@legalmail.it",
      "telefono": "+39 0733/256288",
      "fax": "+39 0733/256435",
      "coordinate": {
        "lat": 43.30027770996094,
        "lng": 13.453332901000977
      }
    },
    {
      "codice": "043024",
      "nome": "Matelica",
      "nomeStraniero": null,
      "codiceCatastale": "F051",
      "cap": "62024",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "daniela.dolce@comune.matelica.mc.it",
      "pec": "protocollo.comunematelica@pec.it",
      "telefono": "+39 0737/781825",
      "fax": "+39 0737/781823",
      "coordinate": {
        "lat": 43.256587982177734,
        "lng": 13.009594917297363
      }
    },
    {
      "codice": "043025",
      "nome": "Mogliano",
      "nomeStraniero": null,
      "codiceCatastale": "F268",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.mogliano.mc.it",
      "pec": "info@pec.comune.mogliano.mc.it",
      "telefono": "+39 0733/557771",
      "fax": null,
      "coordinate": {
        "lat": 43.1859016418457,
        "lng": 13.480799674987793
      }
    },
    {
      "codice": "043027",
      "nome": "Monte Cavallo",
      "nomeStraniero": null,
      "codiceCatastale": "F460",
      "cap": "62030",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "comune@montecavallo.sinp.net",
      "pec": "comune.montecavallo@pec.it",
      "telefono": "+39 0737/519618",
      "fax": "+39 0737/519728",
      "coordinate": {
        "lat": 42.992919921875,
        "lng": 13.001069068908691
      }
    },
    {
      "codice": "043031",
      "nome": "Monte San Giusto",
      "nomeStraniero": null,
      "codiceCatastale": "F621",
      "cap": "62015",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "stato.civile@montesangiusto.org",
      "pec": "protocollo@pec.comune.montesangiusto.mc.it",
      "telefono": "+39 0733/839008",
      "fax": "+39 0733/837373",
      "coordinate": {
        "lat": 43.23789978027344,
        "lng": 13.594650268554688
      }
    },
    {
      "codice": "043032",
      "nome": "Monte San Martino",
      "nomeStraniero": null,
      "codiceCatastale": "F622",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.montesanmartino.mc.it",
      "pec": "montesanmartino@pec.it",
      "telefono": "+39 0733/660107",
      "fax": "+39 0733/699135",
      "coordinate": {
        "lat": 43.032901763916016,
        "lng": 13.439599990844727
      }
    },
    {
      "codice": "043026",
      "nome": "Montecassiano",
      "nomeStraniero": null,
      "codiceCatastale": "F454",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "ufficio.anagrafe@comune.montecassiano.mc.it",
      "pec": "comune.montecassiano@emarche.it",
      "telefono": "+39 0733/299821",
      "fax": "+39 0733/299820",
      "coordinate": {
        "lat": 43.36370086669922,
        "lng": 13.435850143432617
      }
    },
    {
      "codice": "043028",
      "nome": "Montecosaro",
      "nomeStraniero": null,
      "codiceCatastale": "F482",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "servizi.demografici@comune.montecosaro.mc.it",
      "pec": "comunedimontecosaro.segreteria.mc@pa.postacertificata.gov.it",
      "telefono": "+39 0733/560719",
      "fax": "+39 0733/860930",
      "coordinate": {
        "lat": 43.31690979003906,
        "lng": 13.635369300842285
      }
    },
    {
      "codice": "043029",
      "nome": "Montefano",
      "nomeStraniero": null,
      "codiceCatastale": "F496",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "m.quatrini@montefano.sinp.net",
      "pec": "comune@pec.comune.montefano.mc.it",
      "telefono": "+39 0733/852930",
      "fax": "+39 0733/851227",
      "coordinate": {
        "lat": 43.411598205566406,
        "lng": 13.438680648803711
      }
    },
    {
      "codice": "043030",
      "nome": "Montelupone",
      "nomeStraniero": null,
      "codiceCatastale": "F552",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "protocollo@comune.montelupone.mc.it",
      "pec": "comune.montelupone.mc@legalmail.it",
      "telefono": "0733224911",
      "fax": "0733226042",
      "coordinate": {
        "lat": 43.34428024291992,
        "lng": 13.567619323730469
      }
    },
    {
      "codice": "043033",
      "nome": "Morrovalle",
      "nomeStraniero": null,
      "codiceCatastale": "F749",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "comune@morrovalle.org",
      "pec": "comune.morrovalle.mc@legalmail.it",
      "telefono": "+39 0733/223124",
      "fax": "+39 0733/223117",
      "coordinate": {
        "lat": 43.31460189819336,
        "lng": 13.580599784851074
      }
    },
    {
      "codice": "043034",
      "nome": "Muccia",
      "nomeStraniero": null,
      "codiceCatastale": "F793",
      "cap": "62034",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "info@comune.muccia.mc.it",
      "pec": "protocollo@pec.comune.muccia.mc.it",
      "telefono": "+39 0737/646135",
      "fax": "+39 0737/646135",
      "coordinate": {
        "lat": 43.08190155029297,
        "lng": 13.04403018951416
      }
    },
    {
      "codice": "043035",
      "nome": "Penna San Giovanni",
      "nomeStraniero": null,
      "codiceCatastale": "G436",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@pennasangiovanni.sinp.net",
      "pec": "pennasangiovanni@pec.it",
      "telefono": "+39 0733/669119",
      "fax": "+39 0733/669514",
      "coordinate": {
        "lat": 43.05596923828125,
        "lng": 13.423250198364258
      }
    },
    {
      "codice": "043036",
      "nome": "Petriolo",
      "nomeStraniero": null,
      "codiceCatastale": "G515",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@comune.petriolo.mc.it",
      "pec": "anagrafe@pec.comune.petriolo.mc.it",
      "telefono": "+39 0733/550601",
      "fax": "+39 0733/550419",
      "coordinate": {
        "lat": 43.22140884399414,
        "lng": 13.461730003356934
      }
    },
    {
      "codice": "043038",
      "nome": "Pieve Torina",
      "nomeStraniero": null,
      "codiceCatastale": "G657",
      "cap": "62036",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "anagrafe@pievetorina.sinp.net",
      "pec": "comune.pievetorina.mc@legalmail.it",
      "telefono": "+39 0737/518022",
      "fax": "+39 0737/51202",
      "coordinate": {
        "lat": 43.0444450378418,
        "lng": 13.050277709960938
      }
    },
    {
      "codice": "043039",
      "nome": "Pioraco",
      "nomeStraniero": null,
      "codiceCatastale": "G690",
      "cap": "62025",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "info@comune.pioraco.mc.it",
      "pec": "comune.pioraco.mc@legalmail.it",
      "telefono": "+39 0737/42142",
      "fax": "+39 0737/42485",
      "coordinate": {
        "lat": 43.17893600463867,
        "lng": 12.984930992126465
      }
    },
    {
      "codice": "043040",
      "nome": "Poggio San Vicino",
      "nomeStraniero": null,
      "codiceCatastale": "D566",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "comune@poggiosanvicino.sinp.net",
      "pec": "comune.poggiosanvicino@emarche.it",
      "telefono": "+39 0733/619109",
      "fax": "+39 0733/619124",
      "coordinate": {
        "lat": 43.37461853027344,
        "lng": 13.079099655151367
      }
    },
    {
      "codice": "043041",
      "nome": "Pollenza",
      "nomeStraniero": null,
      "codiceCatastale": "F567",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@pollenza.org",
      "pec": "comune.pollenza.mc@legalmail.it",
      "telefono": "+39 0733/548709",
      "fax": "+39 0733/548704",
      "coordinate": {
        "lat": 43.26777648925781,
        "lng": 13.348138809204102
      }
    },
    {
      "codice": "043042",
      "nome": "Porto Recanati",
      "nomeStraniero": null,
      "codiceCatastale": "G919",
      "cap": "62017",
      "prefisso": "071",
      "provincia": "Macerata",
      "email": "elettorale@comune.porto-recanati.mc.it",
      "pec": "comune.portorecanati.mc@legalmail.it",
      "telefono": "+39 071/7599725",
      "fax": "+39 071/7599724",
      "coordinate": {
        "lat": 43.42986297607422,
        "lng": 13.664889335632324
      }
    },
    {
      "codice": "043043",
      "nome": "Potenza Picena",
      "nomeStraniero": null,
      "codiceCatastale": "F632",
      "cap": "62018",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "elettorale@comune.potenza-picena.mc.it",
      "pec": "comune.potenzapicena@emarche.it",
      "telefono": "+39 0733/679232",
      "fax": "+39 0733/679233",
      "coordinate": {
        "lat": 43.366249084472656,
        "lng": 13.620338439941406
      }
    },
    {
      "codice": "043044",
      "nome": "Recanati",
      "nomeStraniero": null,
      "codiceCatastale": "H211",
      "cap": "62019",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "elettorale@comune.recanati.mc.it",
      "pec": "comune.recanati@emarche.it",
      "telefono": "+39 071/7587233",
      "fax": null,
      "coordinate": {
        "lat": 43.3985481262207,
        "lng": 13.552530288696289
      }
    },
    {
      "codice": "043045",
      "nome": "Ripe San Ginesio",
      "nomeStraniero": null,
      "codiceCatastale": "H323",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "comune@ripesanginesio.sinp.net",
      "pec": "comune.ripesanginesio.mc@legalmail.it",
      "telefono": "+39 0733 500102",
      "fax": "+39 0733 500374",
      "coordinate": {
        "lat": 43.142730712890625,
        "lng": 13.36715030670166
      }
    },
    {
      "codice": "043046",
      "nome": "San Ginesio",
      "nomeStraniero": null,
      "codiceCatastale": "H876",
      "cap": "62026",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@sanginesio.sinp.net",
      "pec": "comune.sanginesio.mc@legalmail.it",
      "telefono": "+39 0733 656022",
      "fax": "+39 0733/652057",
      "coordinate": {
        "lat": 43.107845306396484,
        "lng": 13.316666603088379
      }
    },
    {
      "codice": "043047",
      "nome": "San Severino Marche",
      "nomeStraniero": null,
      "codiceCatastale": "I156",
      "cap": "62027",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.sanseverinomarche.mc.it",
      "pec": "protocollo.comune.sanseverinomarche@pec.it",
      "telefono": "+39 0733 6411",
      "fax": "+39 0733 641240",
      "coordinate": {
        "lat": 43.22885513305664,
        "lng": 13.177103042602539
      }
    },
    {
      "codice": "043048",
      "nome": "Sant'Angelo in Pontano",
      "nomeStraniero": null,
      "codiceCatastale": "I286",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "INFO@COMUNE.SANTANGELOINPONTANO.MC.IT",
      "pec": "comune.santangeloinpontano@pec.it",
      "telefono": "0733661602",
      "fax": "+39 0733/661603",
      "coordinate": {
        "lat": 43.099281311035156,
        "lng": 13.395318984985352
      }
    },
    {
      "codice": "043049",
      "nome": "Sarnano",
      "nomeStraniero": null,
      "codiceCatastale": "I436",
      "cap": "62028",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "info@comune.sarnano.mc.it",
      "pec": "comune.sarnano@pec.it",
      "telefono": "+39 0733/659911",
      "fax": "+39 0733/659940",
      "coordinate": {
        "lat": 43.035614013671875,
        "lng": 13.30073070526123
      }
    },
    {
      "codice": "043050",
      "nome": "Sefro",
      "nomeStraniero": null,
      "codiceCatastale": "I569",
      "cap": "62030",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "comune@sefro.sinp.net",
      "pec": "comune.sefro.anagrafe@emarche.it",
      "telefono": "+39 0737/45118",
      "fax": "+39 0737/45513",
      "coordinate": {
        "lat": 43.146400451660156,
        "lng": 12.949230194091797
      }
    },
    {
      "codice": "043051",
      "nome": "Serrapetrona",
      "nomeStraniero": null,
      "codiceCatastale": "I651",
      "cap": "62020",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "anagrafe@comune.serrapetrona.mc.it",
      "pec": "comune.serrapetrona.mc@legalmail.it",
      "telefono": "+39 0733/908321",
      "fax": "+39 0733/908322",
      "coordinate": {
        "lat": 43.17660140991211,
        "lng": 13.190549850463867
      }
    },
    {
      "codice": "043052",
      "nome": "Serravalle di Chienti",
      "nomeStraniero": null,
      "codiceCatastale": "I661",
      "cap": "62038",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "ufficioelettorale@serravalle.sinp.net",
      "pec": "comune.serravalledichienti@emarche.it",
      "telefono": "+39 0737/53121",
      "fax": "+39 0737/53430",
      "coordinate": {
        "lat": 43.072181701660156,
        "lng": 12.951861381530762
      }
    },
    {
      "codice": "043053",
      "nome": "Tolentino",
      "nomeStraniero": null,
      "codiceCatastale": "L191",
      "cap": "62029",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "elettorale@comune.tolentino.mc.it",
      "pec": "comune.tolentino.mc@legalmail.it",
      "telefono": "+39 0733/901255",
      "fax": "+39 0733/901370",
      "coordinate": {
        "lat": 43.20861053466797,
        "lng": 13.284099578857422
      }
    },
    {
      "codice": "043054",
      "nome": "Treia",
      "nomeStraniero": null,
      "codiceCatastale": "L366",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "liliana.palmieri@comune.treia.mc.it",
      "pec": "servizidemografici@pec.comune.treia.mc.it",
      "telefono": "+39 0733/218726",
      "fax": "+39 0733/218734",
      "coordinate": {
        "lat": 43.311370849609375,
        "lng": 13.313053131103516
      }
    },
    {
      "codice": "043055",
      "nome": "Urbisaglia",
      "nomeStraniero": null,
      "codiceCatastale": "L501",
      "cap": "62010",
      "prefisso": "0733",
      "provincia": "Macerata",
      "email": "ufficioanagrafe@comune.urbisaglia.mc.it",
      "pec": "comune.urbisaglia.mc@legalmail.it",
      "telefono": "+39 0733/512626",
      "fax": "+39 0733/50367",
      "coordinate": {
        "lat": 43.197261810302734,
        "lng": 13.377339363098145
      }
    },
    {
      "codice": "043056",
      "nome": "Ussita",
      "nomeStraniero": null,
      "codiceCatastale": "L517",
      "cap": "62039",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "anagrafe@comune.ussita.mc.it",
      "pec": "anagrafe@pec.comune.ussita.mc.it",
      "telefono": "+39 0737 971211",
      "fax": "+39 0737 99643",
      "coordinate": {
        "lat": 42.944129943847656,
        "lng": 13.142950057983398
      }
    },
    {
      "codice": "043058",
      "nome": "Valfornace",
      "nomeStraniero": null,
      "codiceCatastale": "M382",
      "cap": "62031",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "protocollo@comune.valfornace.mc.it",
      "pec": "comune.valfornace@emarche.it",
      "telefono": "+39 073744126",
      "fax": "+39 073744098",
      "coordinate": {
        "lat": 43.06216812133789,
        "lng": 13.084419250488281
      }
    },
    {
      "codice": "043057",
      "nome": "Visso",
      "nomeStraniero": null,
      "codiceCatastale": "M078",
      "cap": "62039",
      "prefisso": "0737",
      "provincia": "Macerata",
      "email": "anagrafe@visso.sinp.net",
      "pec": "comune.visso.mc@legalmail.it",
      "telefono": "+39 0737/95421",
      "fax": "+39 0737/95422",
      "coordinate": {
        "lat": 42.93088150024414,
        "lng": 13.08845043182373
      }
    }
  ],
  "Latina": [
    {
      "codice": "059001",
      "nome": "Aprilia",
      "nomeStraniero": null,
      "codiceCatastale": "A341",
      "cap": "04011",
      "prefisso": "06",
      "provincia": "Latina",
      "email": "elettorale@comune.aprilia.lt.it",
      "pec": "elettorale@pec.comune.aprilia.lt.it",
      "telefono": "+39 06.92018213",
      "fax": "+39 06.9276032",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 12.649999618530273
      }
    },
    {
      "codice": "059002",
      "nome": "Bassiano",
      "nomeStraniero": null,
      "codiceCatastale": "A707",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "anagrafe@comune.bassiano.lt.it",
      "pec": "comunebassiano@pec.comune.bassiano.lt.it",
      "telefono": "+39 0773.355226",
      "fax": "+39 0773.355013",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "059003",
      "nome": "Campodimele",
      "nomeStraniero": null,
      "codiceCatastale": "B527",
      "cap": "04020",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "comune.campodimele@tin.it",
      "pec": "info@pec.comune.campodimele.lt.it",
      "telefono": "+39 0771.598013",
      "fax": "+39 0771.598126",
      "coordinate": {
        "lat": 41.38333511352539,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "059004",
      "nome": "Castelforte",
      "nomeStraniero": null,
      "codiceCatastale": "C104",
      "cap": "04021",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "demografico@comune.castelforte.lt.it",
      "pec": "protocollocastelforte@pec.it",
      "telefono": "+39 0771.607931",
      "fax": "+39 0771.607947",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 13.833333015441895
      }
    },
    {
      "codice": "059005",
      "nome": "Cisterna di Latina",
      "nomeStraniero": null,
      "codiceCatastale": "C740",
      "cap": "04012",
      "prefisso": "06",
      "provincia": "Latina",
      "email": "i.maroso@comune.cisterna.latina.it",
      "pec": "mail@pec.comune.cisterna.latina.it",
      "telefono": "+39 06.96834214",
      "fax": "+39 06.96834302",
      "coordinate": {
        "lat": 41.599998474121094,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "059006",
      "nome": "Cori",
      "nomeStraniero": null,
      "codiceCatastale": "D003",
      "cap": "04010",
      "prefisso": "06",
      "provincia": "Latina",
      "email": "anagrafe@comune.cori.lt.it",
      "pec": "statocivilecomunedicori@pec.it",
      "telefono": "+39 06.96617204",
      "fax": "+39 06.96617204",
      "coordinate": {
        "lat": 41.650001525878906,
        "lng": 12.916666984558105
      }
    },
    {
      "codice": "059007",
      "nome": "Fondi",
      "nomeStraniero": null,
      "codiceCatastale": "D662",
      "cap": "04022",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "segreteriagenerale@comunedifondi.it",
      "pec": "comune.fondi@pecaziendale.it",
      "telefono": "+39 0771.507331",
      "fax": "+39 0771.504262",
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 13.416666984558105
      }
    },
    {
      "codice": "059008",
      "nome": "Formia",
      "nomeStraniero": null,
      "codiceCatastale": "D708",
      "cap": "04023",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "adinitto@comune.formia.lt.it",
      "pec": "protocollo@pec.cittadiformia.it",
      "telefono": "+39 0771.7781",
      "fax": "+39 0771.771680",
      "coordinate": {
        "lat": 41.25638961791992,
        "lng": 13.60694408416748
      }
    },
    {
      "codice": "059009",
      "nome": "Gaeta",
      "nomeStraniero": null,
      "codiceCatastale": "D843",
      "cap": "04024",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "ufficioelettorale@comune.gaeta.lt.it",
      "pec": "comunedigaeta@pec.gedea.it",
      "telefono": "+39 0771.4691",
      "fax": "+39 0771.462540",
      "coordinate": {
        "lat": 41.21666717529297,
        "lng": 13.566666603088379
      }
    },
    {
      "codice": "059010",
      "nome": "Itri",
      "nomeStraniero": null,
      "codiceCatastale": "E375",
      "cap": "04020",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "segreteria@comune.itri.lt.it",
      "pec": "comune.itri@postecert.it",
      "telefono": "+39 0771.7321",
      "fax": "+39 0771.721108",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "059011",
      "nome": "Latina",
      "nomeStraniero": null,
      "codiceCatastale": "E472",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "elettorale@comune.latina",
      "pec": "elettorale@pec.comune.latina.it",
      "telefono": "+39 0773.652660",
      "fax": null,
      "coordinate": {
        "lat": 41.46722412109375,
        "lng": 12.903611183166504
      }
    },
    {
      "codice": "059012",
      "nome": "Lenola",
      "nomeStraniero": null,
      "codiceCatastale": "E527",
      "cap": "04025",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "ragioneria@comune.lenola.lt.it",
      "pec": "protocollo@pec.comune.lenola.lt.it",
      "telefono": "+39 0771.595836",
      "fax": "+39 0771.588181",
      "coordinate": {
        "lat": 41.4091682434082,
        "lng": 13.462499618530273
      }
    },
    {
      "codice": "059013",
      "nome": "Maenza",
      "nomeStraniero": null,
      "codiceCatastale": "E798",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "info@comunedimaenza.it",
      "pec": "maenza@postecert.it",
      "telefono": "+39 0773 951310",
      "fax": "+39 0773 951188",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "059014",
      "nome": "Minturno",
      "nomeStraniero": null,
      "codiceCatastale": "F224",
      "cap": "04026",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "cfrasca.elettorale.minturno@gmail.com",
      "pec": "minturno@legalmail.it",
      "telefono": "+39 0771.6608207",
      "fax": "+39 0771.6608205",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 13.75
      }
    },
    {
      "codice": "059015",
      "nome": "Monte San Biagio",
      "nomeStraniero": null,
      "codiceCatastale": "F616",
      "cap": "04020",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "comune@comune.montesanbiagio.lt.it",
      "pec": "comune.montesanbiagio@pec.it",
      "telefono": "+39 0771.5689200",
      "fax": null,
      "coordinate": {
        "lat": 41.349998474121094,
        "lng": 13.350000381469727
      }
    },
    {
      "codice": "059016",
      "nome": "Norma",
      "nomeStraniero": null,
      "codiceCatastale": "F937",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "protocollo@comune.norma.lt.it",
      "pec": "comunedinorma@postecert.it",
      "telefono": "+39 0773352801",
      "fax": "+39 0773354186",
      "coordinate": {
        "lat": 41.58333206176758,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "059017",
      "nome": "Pontinia",
      "nomeStraniero": null,
      "codiceCatastale": "G865",
      "cap": "04014",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "ufficiostatocivile@comune.pontinia.lt.it",
      "pec": "comune@pec.comune.pontinia.lt.it",
      "telefono": "+39 0773.841211",
      "fax": "+39 0773.841215",
      "coordinate": {
        "lat": 41.40829849243164,
        "lng": 13.044340133666992
      }
    },
    {
      "codice": "059018",
      "nome": "Ponza",
      "nomeStraniero": null,
      "codiceCatastale": "G871",
      "cap": "04027",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "sindaco@comune.ponza.lt.it",
      "pec": "ufficiosegreteriaponza@pec.it",
      "telefono": "+39 0771 80108",
      "fax": "+39 0771 820573",
      "coordinate": {
        "lat": 40.900001525878906,
        "lng": 12.966666221618652
      }
    },
    {
      "codice": "059019",
      "nome": "Priverno",
      "nomeStraniero": null,
      "codiceCatastale": "G698",
      "cap": "04015",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "elettorale@comune.priverno.latina.lt",
      "pec": "demografici.priverno@pec.it",
      "telefono": "+39 0773.912500",
      "fax": "+39 0773.904782",
      "coordinate": {
        "lat": 41.46666717529297,
        "lng": 13.183333396911621
      }
    },
    {
      "codice": "059020",
      "nome": "Prossedi",
      "nomeStraniero": null,
      "codiceCatastale": "H076",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "anagrafe.prossedi@libero.it",
      "pec": "comune.prossedi@postecert.it",
      "telefono": "+39 0773 957016",
      "fax": "+39 0773 956026",
      "coordinate": {
        "lat": 41.516666412353516,
        "lng": 13.266666412353516
      }
    },
    {
      "codice": "059022",
      "nome": "Rocca Massima",
      "nomeStraniero": null,
      "codiceCatastale": "H421",
      "cap": "04010",
      "prefisso": "06",
      "provincia": "Latina",
      "email": "info@comuneroccamassima.it",
      "pec": "protocollo@pec.comuneroccamassima.it",
      "telefono": "+39 06.96690001",
      "fax": "+39 06.9669222",
      "coordinate": {
        "lat": 41.67832946777344,
        "lng": 12.92063045501709
      }
    },
    {
      "codice": "059021",
      "nome": "Roccagorga",
      "nomeStraniero": null,
      "codiceCatastale": "H413",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "servizidemografici@comuneroccagorga.lt.it",
      "pec": "servizidemografici@comuneroccagorga.telecompost.it",
      "telefono": "+39 0773.960931",
      "fax": "+39 0773.960170",
      "coordinate": {
        "lat": 41.53333282470703,
        "lng": 13.149999618530273
      }
    },
    {
      "codice": "059023",
      "nome": "Roccasecca dei Volsci",
      "nomeStraniero": null,
      "codiceCatastale": "H444",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "servizidemografici23@libero.it",
      "pec": "roccaseccadeivolsci@postecert.it",
      "telefono": "+39 0773.920326",
      "fax": "+39 0773.920325",
      "coordinate": {
        "lat": 41.483333587646484,
        "lng": 13.216666221618652
      }
    },
    {
      "codice": "059024",
      "nome": "Sabaudia",
      "nomeStraniero": null,
      "codiceCatastale": "H647",
      "cap": "04016",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "ufficio.protocollo@comune.sabaudia.latina.it",
      "pec": "comunesabaudia@legalmail.it",
      "telefono": "+39 0773 5141",
      "fax": "+39 0773 513166",
      "coordinate": {
        "lat": 41.29985046386719,
        "lng": 13.02476978302002
      }
    },
    {
      "codice": "059025",
      "nome": "San Felice Circeo",
      "nomeStraniero": null,
      "codiceCatastale": "H836",
      "cap": "04017",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "info@comune.sanfelicecirceo.lt.it",
      "pec": "info@pec.comune.sanfelicecirceo.lt.it",
      "telefono": "+39 0773.5221",
      "fax": "+39 0773.546043",
      "coordinate": {
        "lat": 41.23527908325195,
        "lng": 13.095555305480957
      }
    },
    {
      "codice": "059026",
      "nome": "Santi Cosma e Damiano",
      "nomeStraniero": null,
      "codiceCatastale": "I339",
      "cap": "04020",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "mario.delgiudice@comune.santicosmaedamiano.lt.it",
      "pec": "protocollo@pec.comune.santicosmaedamiano.latina.it",
      "telefono": "+39 0771.607851",
      "fax": "+39 0771.607850",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 13.816666603088379
      }
    },
    {
      "codice": "059027",
      "nome": "Sermoneta",
      "nomeStraniero": null,
      "codiceCatastale": "I634",
      "cap": "04013",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "anagrafe@comunedisermoneta.it",
      "pec": "amministrazionecomunale@pec.comune.sermoneta.latina.it",
      "telefono": "+39 0773.30151",
      "fax": "+39 0773.30010",
      "coordinate": {
        "lat": 41.54999923706055,
        "lng": 12.983333587646484
      }
    },
    {
      "codice": "059028",
      "nome": "Sezze",
      "nomeStraniero": null,
      "codiceCatastale": "I712",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "protocollo@comune.sezze.lt.it",
      "pec": "protocollo@pec.comune.sezze.lt.it",
      "telefono": "+39 0773.8081",
      "fax": "+39 0773.803809",
      "coordinate": {
        "lat": 41.5,
        "lng": 13.066666603088379
      }
    },
    {
      "codice": "059029",
      "nome": "Sonnino",
      "nomeStraniero": null,
      "codiceCatastale": "I832",
      "cap": "04010",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "sindaco@comune.sonnino.latina.it",
      "pec": "info@pec.comune.sonnino.latina.it",
      "telefono": "0773 1719660",
      "fax": "",
      "coordinate": {
        "lat": 41.414459228515625,
        "lng": 13.2413969039917
      }
    },
    {
      "codice": "059030",
      "nome": "Sperlonga",
      "nomeStraniero": null,
      "codiceCatastale": "I892",
      "cap": "04029",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "demografici@comune.sperlonga.lt.it",
      "pec": "protocollo@pec.comune.sperlonga.lt.it",
      "telefono": "+39 0771.557841",
      "fax": "+39 0771.548568",
      "coordinate": {
        "lat": 41.266666412353516,
        "lng": 13.433333396911621
      }
    },
    {
      "codice": "059031",
      "nome": "Spigno Saturnia",
      "nomeStraniero": null,
      "codiceCatastale": "I902",
      "cap": "04020",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "info@comune.spignosaturnia.lt.it",
      "pec": "comune.spignosaturnia@pec.it",
      "telefono": "+39 0771.64624",
      "fax": "+39 0771.64697",
      "coordinate": {
        "lat": 41.29999923706055,
        "lng": 13.733333587646484
      }
    },
    {
      "codice": "059032",
      "nome": "Terracina",
      "nomeStraniero": null,
      "codiceCatastale": "L120",
      "cap": "04019",
      "prefisso": "0773",
      "provincia": "Latina",
      "email": "ufficio.elettorale@comune.terracina.lt.it",
      "pec": "posta@pec.comune.terracina.lt.it",
      "telefono": "+39 0773.707505",
      "fax": "+39 0773.707502",
      "coordinate": {
        "lat": 41.28333282470703,
        "lng": 13.25
      }
    },
    {
      "codice": "059033",
      "nome": "Ventotene",
      "nomeStraniero": null,
      "codiceCatastale": "L742",
      "cap": "04020",
      "prefisso": "0771",
      "provincia": "Latina",
      "email": "elettorale@comune.ventotene.lt.it",
      "pec": "elettorale@pec.comune.ventotene.lt.it",
      "telefono": "+39 0771.85014",
      "fax": "+39 0771.85265",
      "coordinate": {
        "lat": 40.79999923706055,
        "lng": 13.433333396911621
      }
    }
  ],
  "Sud Sardegna": [
    {
      "codice": "111001",
      "nome": "Arbus",
      "nomeStraniero": null,
      "codiceCatastale": "A359",
      "cap": "09031",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.arbus.ca.it",
      "pec": "protocollo.arbus@pec.comunas.it",
      "telefono": "+39 070 975691",
      "fax": "+39 070 97569229",
      "coordinate": {
        "lat": 39.52714920043945,
        "lng": 8.599247932434082
      }
    },
    {
      "codice": "111002",
      "nome": "Armungia",
      "nomeStraniero": null,
      "codiceCatastale": "A419",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "amministrativo@comune.armungia.ca.it",
      "pec": "protocollo@pec.comune.armungia.ca.it",
      "telefono": "+39 070958123",
      "fax": "+39 070958076",
      "coordinate": {
        "lat": 39.521854400634766,
        "lng": 9.381208419799805
      }
    },
    {
      "codice": "111003",
      "nome": "Ballao",
      "nomeStraniero": null,
      "codiceCatastale": "A597",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "anagrafe@comune.ballao.ca.it",
      "pec": "protocolloballao@servizipostacert.it",
      "telefono": "+39 070 957319",
      "fax": "+39 070 957187",
      "coordinate": {
        "lat": 39.54902648925781,
        "lng": 9.362754821777344
      }
    },
    {
      "codice": "111004",
      "nome": "Barrali",
      "nomeStraniero": null,
      "codiceCatastale": "A677",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "barrali@tiscali.it",
      "pec": "protocollo.barrali@pec.comunas.it",
      "telefono": "+39 070 9802631",
      "fax": "+39 0709802535",
      "coordinate": {
        "lat": 39.47652053833008,
        "lng": 9.100177764892578
      }
    },
    {
      "codice": "111005",
      "nome": "Barumini",
      "nomeStraniero": null,
      "codiceCatastale": "A681",
      "cap": "09021",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "affarigenerali@comune.barumini.ca.it",
      "pec": "protocollo.barumini@pec.comunas.it",
      "telefono": "+39 0709368024",
      "fax": "+39 0709368033",
      "coordinate": {
        "lat": 39.70375442504883,
        "lng": 9.003639221191406
      }
    },
    {
      "codice": "111006",
      "nome": "Buggerru",
      "nomeStraniero": null,
      "codiceCatastale": "B250",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comunebuggerru.it",
      "pec": "comune.buggerru@pec.it",
      "telefono": "078154303",
      "fax": "078154424",
      "coordinate": {
        "lat": 39.397499084472656,
        "lng": 8.40250015258789
      }
    },
    {
      "codice": "111007",
      "nome": "Burcei",
      "nomeStraniero": null,
      "codiceCatastale": "B274",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "demografici@comune.burcei.ca.it",
      "pec": "protocollo.burcei@pec.it",
      "telefono": "+39 070 737021",
      "fax": "+39 070 738846",
      "coordinate": {
        "lat": 39.3444709777832,
        "lng": 9.359922409057617
      }
    },
    {
      "codice": "111008",
      "nome": "Calasetta",
      "nomeStraniero": null,
      "codiceCatastale": "B383",
      "cap": "09011",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "bib.calasetta@tiscali.it",
      "pec": "protocollo@pcert.comune.calasetta.ci.it",
      "telefono": "+39 0781 88780",
      "fax": "+39 0781 899149",
      "coordinate": {
        "lat": 39.11051559448242,
        "lng": 8.368229866027832
      }
    },
    {
      "codice": "111009",
      "nome": "Carbonia",
      "nomeStraniero": null,
      "codiceCatastale": "B745",
      "cap": "09013",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "comcarbonia@comune.carbonia.ca.it",
      "pec": "comcarbonia@pec.comcarbonia.org",
      "telefono": "+39 0781 6941",
      "fax": "+39 0781/64039",
      "coordinate": {
        "lat": 39.16680145263672,
        "lng": 8.521957397460938
      }
    },
    {
      "codice": "111010",
      "nome": "Carloforte",
      "nomeStraniero": null,
      "codiceCatastale": "B789",
      "cap": "09014",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "segretario@comune.carloforte.ca.it",
      "pec": "protocollo@pec.comune.carloforte.ca.it",
      "telefono": "+39 0781 8589216",
      "fax": "+39 0781 855808",
      "coordinate": {
        "lat": 39.14500427246094,
        "lng": 8.305449485778809
      }
    },
    {
      "codice": "111011",
      "nome": "Castiadas",
      "nomeStraniero": null,
      "codiceCatastale": "M288",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "castiadas@tin.it",
      "pec": "castiadas.amministrativo@halleycert.it",
      "telefono": "+39 070994501",
      "fax": "+39 0709948000",
      "coordinate": {
        "lat": 39.236358642578125,
        "lng": 9.49917221069336
      }
    },
    {
      "codice": "111012",
      "nome": "Collinas",
      "nomeStraniero": null,
      "codiceCatastale": "C882",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.collinas.vs.it",
      "pec": "info@pec.comune.collinas.vs.it",
      "telefono": "+39 070 9304003",
      "fax": "+39 070 9304242",
      "coordinate": {
        "lat": 39.640464782714844,
        "lng": 8.840045928955078
      }
    },
    {
      "codice": "111013",
      "nome": "Decimoputzu",
      "nomeStraniero": null,
      "codiceCatastale": "D260",
      "cap": "09010",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "anagrafe@comune.decimoputzu.ca.it",
      "pec": "protocollo.decimoputzu@legalmail.it",
      "telefono": "+39 070 96329200",
      "fax": "+39 070 9633052",
      "coordinate": {
        "lat": 39.33536148071289,
        "lng": 8.91489028930664
      }
    },
    {
      "codice": "111014",
      "nome": "Dolianova",
      "nomeStraniero": null,
      "codiceCatastale": "D323",
      "cap": "09041",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "ing.sardu@comune.dolianova.ca.it",
      "pec": "comunedidolianova@halleycert.it",
      "telefono": "+39 0707449324",
      "fax": "+39 0707449325",
      "coordinate": {
        "lat": 39.37849807739258,
        "lng": 9.178390502929688
      }
    },
    {
      "codice": "111015",
      "nome": "Domus de Maria",
      "nomeStraniero": null,
      "codiceCatastale": "D333",
      "cap": "09010",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.domusdemaria.ca.it",
      "pec": "domus.protocollo@globalcert.it",
      "telefono": "+39 0709235015",
      "fax": "+39 0709235014",
      "coordinate": {
        "lat": 38.94355773925781,
        "lng": 8.862876892089844
      }
    },
    {
      "codice": "111016",
      "nome": "Domusnovas",
      "nomeStraniero": null,
      "codiceCatastale": "D334",
      "cap": "09015",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "info@domusnovas.net",
      "pec": "comune.domusnovas@pec.it",
      "telefono": "+39 0781 70771",
      "fax": "+39 0781 72368",
      "coordinate": {
        "lat": 39.321109771728516,
        "lng": 8.653055191040039
      }
    },
    {
      "codice": "111017",
      "nome": "Donori",
      "nomeStraniero": null,
      "codiceCatastale": "D344",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.donori.ca.it",
      "pec": "protocollodonori@pec.it",
      "telefono": "+39 070981020",
      "fax": "+39 070981542",
      "coordinate": {
        "lat": 39.43171691894531,
        "lng": 9.126677513122559
      }
    },
    {
      "codice": "111018",
      "nome": "Escalaplano",
      "nomeStraniero": null,
      "codiceCatastale": "D430",
      "cap": "09051",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "sindaco@comune.scalaplano.ca.it",
      "pec": "protocollo@pec.comune.escalaplano.ca.it",
      "telefono": "+39 070 954101",
      "fax": "+39 070 9541020",
      "coordinate": {
        "lat": 39.62472915649414,
        "lng": 9.351940155029297
      }
    },
    {
      "codice": "111019",
      "nome": "Escolca",
      "nomeStraniero": null,
      "codiceCatastale": "D431",
      "cap": "09052",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "segreteria.escolca@tiscali.it",
      "pec": "protocollo.escolca@pec.comunas.it",
      "telefono": "+39 0782808303",
      "fax": "+39 0782808516",
      "coordinate": {
        "lat": 39.698768615722656,
        "lng": 9.122214317321777
      }
    },
    {
      "codice": "111020",
      "nome": "Esterzili",
      "nomeStraniero": null,
      "codiceCatastale": "D443",
      "cap": "09053",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "amministrativo@comune.esterzili.ca.it",
      "pec": "amministrativo@pec.comune.esterzili.ca.it",
      "telefono": "+39 0782 55323",
      "fax": "+39 0782 562156",
      "coordinate": {
        "lat": 39.780277252197266,
        "lng": 9.285555839538574
      }
    },
    {
      "codice": "111021",
      "nome": "Fluminimaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "D639",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "info@comune.fluminimaggiore.ca.it",
      "pec": "protocollo@pec.comune.fluminimaggiore.ca.it",
      "telefono": "+39 0781 5850200",
      "fax": "+39 0781 580519",
      "coordinate": {
        "lat": 39.43857955932617,
        "lng": 8.498611450195312
      }
    },
    {
      "codice": "111022",
      "nome": "Furtei",
      "nomeStraniero": null,
      "codiceCatastale": "D827",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "amministrativo@comune.furtei.ca.it",
      "pec": "protocollo@pec.comune.furtei.ca.it",
      "telefono": "+39 070 9303723",
      "fax": "+39 070 9305035",
      "coordinate": {
        "lat": 39.56242752075195,
        "lng": 8.947677612304688
      }
    },
    {
      "codice": "111023",
      "nome": "Genoni",
      "nomeStraniero": null,
      "codiceCatastale": "D968",
      "cap": "09054",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "polizialocale@comune.genoni.su.it",
      "pec": "polizialocale@pec.comune.genoni.su.it",
      "telefono": "+39 0782 810023",
      "fax": "+39 0782 811163",
      "coordinate": {
        "lat": 39.79389572143555,
        "lng": 9.006986618041992
      }
    },
    {
      "codice": "111024",
      "nome": "Genuri",
      "nomeStraniero": null,
      "codiceCatastale": "D970",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "amministrativo@comune.genuri.vs.it",
      "pec": "protocollo.genuri@pec.comunas.it",
      "telefono": "+39 070 9365128",
      "fax": "+39 070 9365010",
      "coordinate": {
        "lat": 39.74408721923828,
        "lng": 8.923730850219727
      }
    },
    {
      "codice": "111025",
      "nome": "Gergei",
      "nomeStraniero": null,
      "codiceCatastale": "D982",
      "cap": "09055",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "gergei@tiscali.it",
      "pec": "comune.gergei@legalmail.it",
      "telefono": "+39 0782 808923",
      "fax": "+39 0782 808949",
      "coordinate": {
        "lat": 39.699344635009766,
        "lng": 9.100703239440918
      }
    },
    {
      "codice": "111026",
      "nome": "Gesico",
      "nomeStraniero": null,
      "codiceCatastale": "D994",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.gesico.su.it",
      "pec": "protocollo.gesico@pec.comunas.it",
      "telefono": "+39 070987043",
      "fax": "+39 070987239",
      "coordinate": {
        "lat": 39.61557388305664,
        "lng": 9.107451438903809
      }
    },
    {
      "codice": "111027",
      "nome": "Gesturi",
      "nomeStraniero": null,
      "codiceCatastale": "D997",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comunegesturi@inwind.it",
      "pec": "amministrazione.gesturi@pec.it",
      "telefono": "+39 070 9360029",
      "fax": "+39 070 9369092",
      "coordinate": {
        "lat": 39.733131408691406,
        "lng": 9.020333290100098
      }
    },
    {
      "codice": "111028",
      "nome": "Giba",
      "nomeStraniero": null,
      "codiceCatastale": "E022",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "comunedigiba@tiscali.it",
      "pec": "amministrativo.giba@legalmail.it",
      "telefono": "+39 0781 964023",
      "fax": "+39 0781 964470",
      "coordinate": {
        "lat": 39.072113037109375,
        "lng": 8.635554313659668
      }
    },
    {
      "codice": "111029",
      "nome": "Goni",
      "nomeStraniero": null,
      "codiceCatastale": "E084",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comunegoni@tiscali.it",
      "pec": "protocollo.goni@servizipostacert.it",
      "telefono": "+39 070 982114",
      "fax": "+39 070 982035",
      "coordinate": {
        "lat": 39.57853698730469,
        "lng": 9.285421371459961
      }
    },
    {
      "codice": "111030",
      "nome": "Gonnesa",
      "nomeStraniero": null,
      "codiceCatastale": "E086",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.gonnesa.ca.it",
      "pec": "protocollo@pec.comune.gonnesa.ca.it",
      "telefono": "+39 0781 46801",
      "fax": "+39 0781 4680350",
      "coordinate": {
        "lat": 39.266483306884766,
        "lng": 8.471746444702148
      }
    },
    {
      "codice": "111031",
      "nome": "Gonnosfanadiga",
      "nomeStraniero": null,
      "codiceCatastale": "E085",
      "cap": "09035",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comune@comune.gonnosfanadiga.su.it",
      "pec": "protocollo@pec.comunegonnosfanadiga.it",
      "telefono": "+39 070 2049923",
      "fax": null,
      "coordinate": {
        "lat": 39.49331283569336,
        "lng": 8.662891387939453
      }
    },
    {
      "codice": "111032",
      "nome": "Guamaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "E234",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "amministrativo@comune.guamaggiore.ca.it",
      "pec": "protocollo.guamaggiore@servizipostacert.it",
      "telefono": "+39 070985904",
      "fax": "+39 070 985979",
      "coordinate": {
        "lat": 39.56844711303711,
        "lng": 9.07419204711914
      }
    },
    {
      "codice": "111033",
      "nome": "Guasila",
      "nomeStraniero": null,
      "codiceCatastale": "E252",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "statocivile@comune.guasila.ca.it",
      "pec": "protocollo@pec.comune.guasila.ca.it",
      "telefono": "+39 0709837928",
      "fax": null,
      "coordinate": {
        "lat": 39.560176849365234,
        "lng": 9.044666290283203
      }
    },
    {
      "codice": "111034",
      "nome": "Guspini",
      "nomeStraniero": null,
      "codiceCatastale": "E270",
      "cap": "09036",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "demografici@comune.guspini.su.it",
      "pec": "protocollo@pec.comune.guspini.vs.it",
      "telefono": "+39 070 97601",
      "fax": "+39 070 971682",
      "coordinate": {
        "lat": 39.53995895385742,
        "lng": 8.626670837402344
      }
    },
    {
      "codice": "111035",
      "nome": "Iglesias",
      "nomeStraniero": null,
      "codiceCatastale": "E281",
      "cap": "09016",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "segrgene@comune.iglesias.ca.it",
      "pec": "protocollo.comune.iglesias@pec.it",
      "telefono": "+39 0781 2741",
      "fax": "+39 0781.274201",
      "coordinate": {
        "lat": 39.31026077270508,
        "lng": 8.537235260009766
      }
    },
    {
      "codice": "111036",
      "nome": "Isili",
      "nomeStraniero": null,
      "codiceCatastale": "E336",
      "cap": "09056",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "anagrafe@comune.isili.ca.it",
      "pec": "protocollo.isili@pec.comunas.it",
      "telefono": "+39 0782 802013",
      "fax": "+39 0782 804469",
      "coordinate": {
        "lat": 39.74150085449219,
        "lng": 9.109722137451172
      }
    },
    {
      "codice": "111037",
      "nome": "Las Plassas",
      "nomeStraniero": null,
      "codiceCatastale": "E464",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comunedilasplassas@libero.it",
      "pec": "protocollo.lasplassas@pec.comunas.it",
      "telefono": "+39 070 9364006",
      "fax": "+39 070 9364606",
      "coordinate": {
        "lat": 39.6800422668457,
        "lng": 8.98511028289795
      }
    },
    {
      "codice": "111038",
      "nome": "Lunamatrona",
      "nomeStraniero": null,
      "codiceCatastale": "E742",
      "cap": "09022",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.lunamatrona.ca.it",
      "pec": "protocollo@pec.comune.lunamatrona.ca.it",
      "telefono": "+39 070 939026",
      "fax": "+39 070 939678",
      "coordinate": {
        "lat": 39.64693832397461,
        "lng": 8.900299072265625
      }
    },
    {
      "codice": "111039",
      "nome": "Mandas",
      "nomeStraniero": null,
      "codiceCatastale": "E877",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segretariocomunale@comune.mandas.ca.it",
      "pec": "comune.mandas@cert.legalmail.it",
      "telefono": "+39 070 987801",
      "fax": "+39 070 98780226",
      "coordinate": {
        "lat": 39.655399322509766,
        "lng": 9.129810333251953
      }
    },
    {
      "codice": "111040",
      "nome": "Masainas",
      "nomeStraniero": null,
      "codiceCatastale": "M270",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "segreteria.protocollo@comune.masainas.ci.it",
      "pec": "protocollo.masainas@pec.comunas.it",
      "telefono": "+39 0781 96111",
      "fax": "+39 0781 9611222",
      "coordinate": {
        "lat": 39.0465202331543,
        "lng": 8.631413459777832
      }
    },
    {
      "codice": "111041",
      "nome": "Monastir",
      "nomeStraniero": null,
      "codiceCatastale": "F333",
      "cap": "09023",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.monastir.ca.it",
      "pec": "protocollo.monastir@legalmail.it",
      "telefono": "+39 070916701",
      "fax": "+39 07091670227",
      "coordinate": {
        "lat": 39.38446807861328,
        "lng": 9.04449462890625
      }
    },
    {
      "codice": "111042",
      "nome": "Muravera",
      "nomeStraniero": null,
      "codiceCatastale": "F808",
      "cap": "09043",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.muravera.ca.it",
      "pec": "protocollo@comunemuravera.it",
      "telefono": "+39 070 990001",
      "fax": "+39 070 9931204",
      "coordinate": {
        "lat": 39.41961669921875,
        "lng": 9.576301574707031
      }
    },
    {
      "codice": "111043",
      "nome": "Musei",
      "nomeStraniero": null,
      "codiceCatastale": "F822",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "sindaco@comune.musei.ci.it",
      "pec": "comune.musei@legalmail.it",
      "telefono": "+39 0781 7281",
      "fax": "+39 0781 72229",
      "coordinate": {
        "lat": 39.302024841308594,
        "lng": 8.667526245117188
      }
    },
    {
      "codice": "111044",
      "nome": "Narcao",
      "nomeStraniero": null,
      "codiceCatastale": "F841",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "ufficio.anagrafe@comune.narcao.ci.it",
      "pec": "protocollo@pec.comune.narcao.ci.it",
      "telefono": "+39 0781 959023",
      "fax": "00000",
      "coordinate": {
        "lat": 39.16666793823242,
        "lng": 8.683333396911621
      }
    },
    {
      "codice": "111045",
      "nome": "Nuragus",
      "nomeStraniero": null,
      "codiceCatastale": "F981",
      "cap": "09057",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "segreteria@comune.nuragus.ca.it",
      "pec": "comunicazioni@pec.comune.nuragus.ca.it",
      "telefono": "+39 0782 8210",
      "fax": "+39 0782 818063",
      "coordinate": {
        "lat": 39.77743148803711,
        "lng": 9.03670597076416
      }
    },
    {
      "codice": "111046",
      "nome": "Nurallao",
      "nomeStraniero": null,
      "codiceCatastale": "F982",
      "cap": "09058",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.nurallao.ca.it",
      "pec": "comune.nurallao@legalmail.it",
      "telefono": "+39 0782815004",
      "fax": "+39 0782815328",
      "coordinate": {
        "lat": 39.7910270690918,
        "lng": 9.080286026000977
      }
    },
    {
      "codice": "111047",
      "nome": "Nuraminis",
      "nomeStraniero": null,
      "codiceCatastale": "F983",
      "cap": "09024",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.nuraminis.ca.it",
      "pec": "protocollo.nuraminis@legalmail.it",
      "telefono": "+39 0707058022",
      "fax": null,
      "coordinate": {
        "lat": 39.442291259765625,
        "lng": 9.013252258300781
      }
    },
    {
      "codice": "111048",
      "nome": "Nurri",
      "nomeStraniero": null,
      "codiceCatastale": "F986",
      "cap": "09059",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "info@comune.nurri.ca.it",
      "pec": "protocollo@pec.comune.nurri.ca.it",
      "telefono": "+39 0782 849005",
      "fax": "+39 0782 849597",
      "coordinate": {
        "lat": 39.711280822753906,
        "lng": 9.230403900146484
      }
    },
    {
      "codice": "111049",
      "nome": "Nuxis",
      "nomeStraniero": null,
      "codiceCatastale": "F991",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "serviziamministrativi@comune.nuxis.ci.it",
      "pec": "protocollo.nuxis@pec.comunas.it",
      "telefono": "+39 0781 95791",
      "fax": "+39 0781 957484",
      "coordinate": {
        "lat": 39.15402603149414,
        "lng": 8.7396240234375
      }
    },
    {
      "codice": "111050",
      "nome": "Orroli",
      "nomeStraniero": null,
      "codiceCatastale": "G122",
      "cap": "09061",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.orroli.ca.it",
      "pec": "comunediorroli@postemailcertificata.it",
      "telefono": "+39 0782 847006",
      "fax": "+39 0782 847624",
      "coordinate": {
        "lat": 39.6922607421875,
        "lng": 9.250316619873047
      }
    },
    {
      "codice": "111051",
      "nome": "Ortacesus",
      "nomeStraniero": null,
      "codiceCatastale": "G133",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "demografici@comune.ortacesus.ca.it",
      "pec": "protocollo.ortacesus@servizipostacert.it",
      "telefono": "+39 070 9804214",
      "fax": "+39 070 9804217",
      "coordinate": {
        "lat": 39.53846740722656,
        "lng": 9.084148406982422
      }
    },
    {
      "codice": "111052",
      "nome": "Pabillonis",
      "nomeStraniero": null,
      "codiceCatastale": "G207",
      "cap": "09030",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.pabillonis.su.it",
      "pec": "protocollo@pec.comune.pabillonis.su.it",
      "telefono": "+39 070 93529200",
      "fax": "+39 070 9353260",
      "coordinate": {
        "lat": 39.592369079589844,
        "lng": 8.721722602844238
      }
    },
    {
      "codice": "111053",
      "nome": "Pauli Arbarei",
      "nomeStraniero": null,
      "codiceCatastale": "G382",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.pauliarbarei.ca.it",
      "pec": "comune.pauliarbarei@pec.it",
      "telefono": "+39 070 939039",
      "fax": "+39 070 939955",
      "coordinate": {
        "lat": 39.66098403930664,
        "lng": 8.921842575073242
      }
    },
    {
      "codice": "111054",
      "nome": "Perdaxius",
      "nomeStraniero": null,
      "codiceCatastale": "G446",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "segreteria@comune.perdaxius.ci.it",
      "pec": "protocollo@pec.comune.perdaxius.ci.it",
      "telefono": "+39 0781 952007",
      "fax": "+39 0781 952170",
      "coordinate": {
        "lat": 39.16107940673828,
        "lng": 8.612380027770996
      }
    },
    {
      "codice": "111055",
      "nome": "Pimentel",
      "nomeStraniero": null,
      "codiceCatastale": "G669",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "demografici@comune.pimentel.ca.it",
      "pec": "protocollo@pec.comune.pimentel.ca.it",
      "telefono": "+39 07098004109",
      "fax": null,
      "coordinate": {
        "lat": 39.48655319213867,
        "lng": 9.065608978271484
      }
    },
    {
      "codice": "111056",
      "nome": "Piscinas",
      "nomeStraniero": null,
      "codiceCatastale": "M291",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "anagrafe.piscinas@tiscali.it",
      "pec": "protocollo@pec.comune.piscinas.ci.it",
      "telefono": "+39 0781963111",
      "fax": "+39 0781964754",
      "coordinate": {
        "lat": 39.074459075927734,
        "lng": 8.666046142578125
      }
    },
    {
      "codice": "111057",
      "nome": "Portoscuso",
      "nomeStraniero": null,
      "codiceCatastale": "G922",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "sindaco@comune.portoscuso.ci.it",
      "pec": "comune.portoscuso@pec.comune.portoscuso.ci.it",
      "telefono": "+39 0781 51111",
      "fax": "+39 0781 508728",
      "coordinate": {
        "lat": 39.204856872558594,
        "lng": 8.380508422851562
      }
    },
    {
      "codice": "111058",
      "nome": "Sadali",
      "nomeStraniero": null,
      "codiceCatastale": "H659",
      "cap": "09062",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.sadali.ca.it",
      "pec": "protocollo@pec.comune.sadali.nu.it",
      "telefono": "+39 0782 599012",
      "fax": "+39 0782 59300",
      "coordinate": {
        "lat": 39.81367874145508,
        "lng": 9.273834228515625
      }
    },
    {
      "codice": "111059",
      "nome": "Samassi",
      "nomeStraniero": null,
      "codiceCatastale": "H738",
      "cap": "09030",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "uff.segreteria@comune.samassi.ca.it",
      "pec": "protocollo@pec.comune.samassi.ca.it",
      "telefono": "+39 070 938101",
      "fax": "+39 070938962",
      "coordinate": {
        "lat": 39.48152160644531,
        "lng": 8.905277252197266
      }
    },
    {
      "codice": "111060",
      "nome": "Samatzai",
      "nomeStraniero": null,
      "codiceCatastale": "H739",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.samatzai.ca.it",
      "pec": "protocollo.samatzai@pec.comunas.it",
      "telefono": "+39 070910023",
      "fax": "+39 070910635",
      "coordinate": {
        "lat": 39.48112487792969,
        "lng": 9.036855697631836
      }
    },
    {
      "codice": "111061",
      "nome": "San Basilio",
      "nomeStraniero": null,
      "codiceCatastale": "H766",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segreteria@comune.sanbasilio.ca.it",
      "pec": "protocollo@pec.comune.sanbasilio.ca.it",
      "telefono": "+39 0709805536",
      "fax": "+39 0709805284",
      "coordinate": {
        "lat": 39.53784942626953,
        "lng": 9.197529792785645
      }
    },
    {
      "codice": "111062",
      "nome": "San Gavino Monreale",
      "nomeStraniero": null,
      "codiceCatastale": "H856",
      "cap": "09037",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "anna.corrias@comune.sangavinomonreale.su.it",
      "pec": "protocollo.sangavino@pec.comunas.it",
      "telefono": "+39 07093749232",
      "fax": null,
      "coordinate": {
        "lat": 39.54985427856445,
        "lng": 8.791637420654297
      }
    },
    {
      "codice": "111063",
      "nome": "San Giovanni Suergiu",
      "nomeStraniero": null,
      "codiceCatastale": "G287",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "ufficio.protocollo@comune.sangiovannisuergiu.ci.it",
      "pec": "ufficio.protocollo@pec.comune.sangiovannisuergiu.ci.it",
      "telefono": "+39 0781 69991",
      "fax": "+39 0781 6999313",
      "coordinate": {
        "lat": 39.1109504699707,
        "lng": 8.522515296936035
      }
    },
    {
      "codice": "111064",
      "nome": "San Nicolò Gerrei",
      "nomeStraniero": null,
      "codiceCatastale": "G383",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "amministrativo@comune.sannicologerrei.ca.it",
      "pec": "sngerreiufficioprotocollo@pec.it",
      "telefono": "+39 070 950026",
      "fax": "+39 070 9509128",
      "coordinate": {
        "lat": 39.49725341796875,
        "lng": 9.305849075317383
      }
    },
    {
      "codice": "111065",
      "nome": "San Sperate",
      "nomeStraniero": null,
      "codiceCatastale": "I166",
      "cap": "09026",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comune@sansperate.net",
      "pec": "protocollo@pec.comune.sansperate.ca.it",
      "telefono": "+39 07096040209",
      "fax": null,
      "coordinate": {
        "lat": 39.35752868652344,
        "lng": 9.00827407836914
      }
    },
    {
      "codice": "111066",
      "nome": "San Vito",
      "nomeStraniero": null,
      "codiceCatastale": "I402",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "sanpriamo@tiscali.it",
      "pec": "servizio.affarigenerali@pec.comune.sanvito.ca.it",
      "telefono": "+39 0709928929",
      "fax": "+39 0709928312",
      "coordinate": {
        "lat": 39.44189453125,
        "lng": 9.540424346923828
      }
    },
    {
      "codice": "111067",
      "nome": "Sanluri",
      "nomeStraniero": null,
      "codiceCatastale": "H974",
      "cap": "09025",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segreteria@comune.sanluri.su.it",
      "pec": "protocollo@pec.comune.sanluri.su.it",
      "telefono": "+39 070 9383225",
      "fax": "+39 070 9383226",
      "coordinate": {
        "lat": 39.56110382080078,
        "lng": 8.899955749511719
      }
    },
    {
      "codice": "111069",
      "nome": "Sant'Andrea Frius",
      "nomeStraniero": null,
      "codiceCatastale": "I271",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "affarigenerali@comune.santandreafrius.ca.it",
      "pec": "affarigenerali.comune.s.andrea.frius@pec.it",
      "telefono": "+39 070 9803191",
      "fax": "+39 070 9803537",
      "coordinate": {
        "lat": 39.478275299072266,
        "lng": 9.169464111328125
      }
    },
    {
      "codice": "111070",
      "nome": "Sant'Anna Arresi",
      "nomeStraniero": null,
      "codiceCatastale": "M209",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "info@comune.santannaarresi.ca.it",
      "pec": "protocollo@pec.comune.santannaarresi.ca.it",
      "telefono": "+39 0781 96691",
      "fax": "+39 0781 96691",
      "coordinate": {
        "lat": 39.00384521484375,
        "lng": 8.64349365234375
      }
    },
    {
      "codice": "111071",
      "nome": "Sant'Antioco",
      "nomeStraniero": null,
      "codiceCatastale": "I294",
      "cap": "09017",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "info@comune.santantioco.ca.it",
      "pec": "protocollo@comune.santantioco.legalmail.it",
      "telefono": "+39 0781 80301",
      "fax": "+39 0781 8030275",
      "coordinate": {
        "lat": 39.03499984741211,
        "lng": 8.412500381469727
      }
    },
    {
      "codice": "111068",
      "nome": "Santadi",
      "nomeStraniero": null,
      "codiceCatastale": "I182",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "areaamministrativa@comune.santadi.ci.it",
      "pec": "protocollo@pec.comunesantadi.it",
      "telefono": "+39 0781 94201",
      "fax": "+39 0781 941000",
      "coordinate": {
        "lat": 39.09369659423828,
        "lng": 8.71490478515625
      }
    },
    {
      "codice": "111072",
      "nome": "Sardara",
      "nomeStraniero": null,
      "codiceCatastale": "I428",
      "cap": "09030",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "settore.affarigenerali@comune.sardara.vs.it",
      "pec": "affarigenerali@pec.comune.sardara.vs.it",
      "telefono": "+39 070 93450200",
      "fax": "+39 070 9386111",
      "coordinate": {
        "lat": 39.613887786865234,
        "lng": 8.82150650024414
      }
    },
    {
      "codice": "111073",
      "nome": "Segariu",
      "nomeStraniero": null,
      "codiceCatastale": "I570",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "com.segariu@tiscali.it",
      "pec": "protocollo@pec.comune.segariu.ca.it",
      "telefono": "+39 0709305011",
      "fax": "+39 0709302306",
      "coordinate": {
        "lat": 39.562957763671875,
        "lng": 8.982868194580078
      }
    },
    {
      "codice": "111074",
      "nome": "Selegas",
      "nomeStraniero": null,
      "codiceCatastale": "I582",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.selegas.ca.it",
      "pec": "protocollo@pec.comune.selegas.ca.it",
      "telefono": "+39 070 985828",
      "fax": "+39 070 985785",
      "coordinate": {
        "lat": 39.56732177734375,
        "lng": 9.104061126708984
      }
    },
    {
      "codice": "111075",
      "nome": "Senorbì",
      "nomeStraniero": null,
      "codiceCatastale": "I615",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.senorbi.ca.it",
      "pec": "protocollo@pec.comune.senorbi.ca.it",
      "telefono": "+39 070 980121",
      "fax": "+39 070 98012345",
      "coordinate": {
        "lat": 39.53396987915039,
        "lng": 9.131011962890625
      }
    },
    {
      "codice": "111076",
      "nome": "Serdiana",
      "nomeStraniero": null,
      "codiceCatastale": "I624",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.serdiana.ca.it",
      "pec": "comune.serdiana@pec.it",
      "telefono": "+39 070744121",
      "fax": "+39 070743233",
      "coordinate": {
        "lat": 39.374481201171875,
        "lng": 9.158992767333984
      }
    },
    {
      "codice": "111077",
      "nome": "Serramanna",
      "nomeStraniero": null,
      "codiceCatastale": "I647",
      "cap": "09038",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "serramanna@comune.serramanna.ca.it",
      "pec": "protocollo@pec.comune.serramanna.ca.it",
      "telefono": "+39 070 9132001",
      "fax": "+39 070 9137270",
      "coordinate": {
        "lat": 39.42277908325195,
        "lng": 8.921667098999023
      }
    },
    {
      "codice": "111078",
      "nome": "Serrenti",
      "nomeStraniero": null,
      "codiceCatastale": "I667",
      "cap": "09027",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comune.serrenti@tiscali.it",
      "pec": "protocollo.serrenti@pec.comunas.it",
      "telefono": "+39 070 91519201",
      "fax": "+39 070 9159791",
      "coordinate": {
        "lat": 39.492515563964844,
        "lng": 8.976516723632812
      }
    },
    {
      "codice": "111079",
      "nome": "Serri",
      "nomeStraniero": null,
      "codiceCatastale": "I668",
      "cap": "09063",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "comune.serri@tiscali.it",
      "pec": "protocollo@pec.comune.serri.ca.it",
      "telefono": "+39 0782 806081",
      "fax": "+39 0782 806023",
      "coordinate": {
        "lat": 39.70140838623047,
        "lng": 9.144701957702637
      }
    },
    {
      "codice": "111080",
      "nome": "Setzu",
      "nomeStraniero": null,
      "codiceCatastale": "I705",
      "cap": "09029",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "infoprotocollo@comune.setzu.vs.it",
      "pec": "protocollo@pec.comune.setzu.vs.it",
      "telefono": "+39 070 9364012",
      "fax": "+39 070 9364615",
      "coordinate": {
        "lat": 39.72249984741211,
        "lng": 8.938416481018066
      }
    },
    {
      "codice": "111081",
      "nome": "Seui",
      "nomeStraniero": null,
      "codiceCatastale": "I706",
      "cap": "09064",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "info@comune.seui.og.it",
      "pec": "ufficiostatocivile.seui@pec.comunas.it",
      "telefono": "+39 0782 54611",
      "fax": "+39 0782 54363",
      "coordinate": {
        "lat": 39.83859634399414,
        "lng": 9.324302673339844
      }
    },
    {
      "codice": "111082",
      "nome": "Seulo",
      "nomeStraniero": null,
      "codiceCatastale": "I707",
      "cap": "09065",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.seulo.ca.it",
      "pec": "protocollo@pec.comune.seulo.ca.it",
      "telefono": "+39 0782 58109",
      "fax": "+39 0782 58330",
      "coordinate": {
        "lat": 39.86962890625,
        "lng": 9.23675537109375
      }
    },
    {
      "codice": "111083",
      "nome": "Siddi",
      "nomeStraniero": null,
      "codiceCatastale": "I724",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.siddi.su.it",
      "pec": "protocollo.siddi@pec.it",
      "telefono": "+39 070 939800",
      "fax": "+39 070 939116",
      "coordinate": {
        "lat": 39.67277908325195,
        "lng": 8.888278007507324
      }
    },
    {
      "codice": "111084",
      "nome": "Siliqua",
      "nomeStraniero": null,
      "codiceCatastale": "I734",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "info@comune.siliqua.ca.it",
      "pec": "protocollo@pec.comune.siliqua.ca.it",
      "telefono": "+39 07817801202",
      "fax": null,
      "coordinate": {
        "lat": 39.30109786987305,
        "lng": 8.807945251464844
      }
    },
    {
      "codice": "111085",
      "nome": "Silius",
      "nomeStraniero": null,
      "codiceCatastale": "I735",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.silius.ca.it",
      "pec": "protocollo@pec.comune.silius.ca.it",
      "telefono": "+39 070 959006",
      "fax": "+39 070 959287",
      "coordinate": {
        "lat": 39.51688766479492,
        "lng": 9.293832778930664
      }
    },
    {
      "codice": "111086",
      "nome": "Siurgus Donigala",
      "nomeStraniero": null,
      "codiceCatastale": "I765",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "comunesiurgus.d@tiscali.it",
      "pec": "protocollo@pec.comune.siurgusdonigala.ca.it",
      "telefono": "+39 070 989736",
      "fax": "+39 070 989111",
      "coordinate": {
        "lat": 39.60000228881836,
        "lng": 9.18783187866211
      }
    },
    {
      "codice": "111087",
      "nome": "Soleminis",
      "nomeStraniero": null,
      "codiceCatastale": "I797",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "servizitecnici@comune.soleminis.ca.it",
      "pec": "protocollo.soleminis@pec.comunas.it",
      "telefono": "+39 0707499026",
      "fax": "+39 070749050",
      "coordinate": {
        "lat": 39.34720993041992,
        "lng": 9.181351661682129
      }
    },
    {
      "codice": "111088",
      "nome": "Suelli",
      "nomeStraniero": null,
      "codiceCatastale": "I995",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "ufficiosindacosuelli@gmail.com",
      "pec": "protocollo.suelli@legalmail.it",
      "telefono": "+39 070/988015",
      "fax": "+39 070/988353",
      "coordinate": {
        "lat": 39.5616340637207,
        "lng": 9.13187026977539
      }
    },
    {
      "codice": "111089",
      "nome": "Teulada",
      "nomeStraniero": null,
      "codiceCatastale": "L154",
      "cap": "09019",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.teulada.ca.it",
      "pec": "protocollo@pec.comune.teulada.ca.it",
      "telefono": "+39 070 9270029",
      "fax": "+39 070 92720049",
      "coordinate": {
        "lat": 38.967952728271484,
        "lng": 8.77232551574707
      }
    },
    {
      "codice": "111090",
      "nome": "Tratalias",
      "nomeStraniero": null,
      "codiceCatastale": "L337",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "segreteria@comune.tratalias.ca.it",
      "pec": "protocollo@pec.comune.tratalias.ca.it",
      "telefono": "+39 0781 697023",
      "fax": "+39 0781 688283",
      "coordinate": {
        "lat": 39.102622985839844,
        "lng": 8.579120635986328
      }
    },
    {
      "codice": "111091",
      "nome": "Tuili",
      "nomeStraniero": null,
      "codiceCatastale": "L463",
      "cap": "09029",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "sindacotuili@tiscali.it",
      "pec": "protocollo.tuili@pec.comunas.it",
      "telefono": "+39 070 9364481",
      "fax": "+39 070 9364320",
      "coordinate": {
        "lat": 39.71474838256836,
        "lng": 8.959822654724121
      }
    },
    {
      "codice": "111092",
      "nome": "Turri",
      "nomeStraniero": null,
      "codiceCatastale": "L473",
      "cap": "09020",
      "prefisso": "0783",
      "provincia": "Sud Sardegna",
      "email": "anagrafe.turri@tiscali.it",
      "pec": "turri.unione@legalmail.it",
      "telefono": "+39 0783 95026",
      "fax": "+39 0783 95373",
      "coordinate": {
        "lat": 39.7047119140625,
        "lng": 8.916349411010742
      }
    },
    {
      "codice": "111093",
      "nome": "Ussana",
      "nomeStraniero": null,
      "codiceCatastale": "L512",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.ussana.ca.it",
      "pec": "protocollo@pec.comune.ussana.ca.it",
      "telefono": "+39 070 918941",
      "fax": "+39 070 91894325",
      "coordinate": {
        "lat": 39.39375305175781,
        "lng": 9.07470703125
      }
    },
    {
      "codice": "111094",
      "nome": "Ussaramanna",
      "nomeStraniero": null,
      "codiceCatastale": "L513",
      "cap": "09020",
      "prefisso": "0783",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.ussaramanna.vs.it",
      "pec": "protocollo@pec.comune.ussaramanna.vs.it",
      "telefono": "+39 0783 95015",
      "fax": "+39 0783 95382",
      "coordinate": {
        "lat": 39.69176483154297,
        "lng": 8.908624649047852
      }
    },
    {
      "codice": "111095",
      "nome": "Vallermosa",
      "nomeStraniero": null,
      "codiceCatastale": "L613",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "demografici@comune.vallermosa.su.it",
      "pec": "protocollo.vallermosa@servizipostacert.it",
      "telefono": "+39 07811870323",
      "fax": null,
      "coordinate": {
        "lat": 39.363433837890625,
        "lng": 8.796272277832031
      }
    },
    {
      "codice": "111096",
      "nome": "Villacidro",
      "nomeStraniero": null,
      "codiceCatastale": "L924",
      "cap": "09039",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "sindaco@comune.villacidro.vs.it",
      "pec": "protocollo.villacidro@pec.it",
      "telefono": "+39 070 93442255",
      "fax": null,
      "coordinate": {
        "lat": 39.457801818847656,
        "lng": 8.74237060546875
      }
    },
    {
      "codice": "111097",
      "nome": "Villamar",
      "nomeStraniero": null,
      "codiceCatastale": "L966",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segreteria.villamar@tiscali.it",
      "pec": "segreteria.villamar@legalmail.it",
      "telefono": "+39 070 9306901",
      "fax": "+39 070 9306017",
      "coordinate": {
        "lat": 39.619972229003906,
        "lng": 8.960037231445312
      }
    },
    {
      "codice": "111098",
      "nome": "Villamassargia",
      "nomeStraniero": null,
      "codiceCatastale": "L968",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "comunevillamassargi@tiscalinet.it",
      "pec": "comunevillamassargia@legalmail.it",
      "telefono": "+39 0781 75801",
      "fax": "+39 0781 74880",
      "coordinate": {
        "lat": 39.275718688964844,
        "lng": 8.640575408935547
      }
    },
    {
      "codice": "111099",
      "nome": "Villanova Tulo",
      "nomeStraniero": null,
      "codiceCatastale": "L992",
      "cap": "09066",
      "prefisso": "0782",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.villanovatulo.ca.it",
      "pec": "protocollo.villanovatulo@legalmail.it",
      "telefono": "+39 0782 813023",
      "fax": "+39 0782 813028",
      "coordinate": {
        "lat": 39.780277252197266,
        "lng": 9.21328067779541
      }
    },
    {
      "codice": "111100",
      "nome": "Villanovaforru",
      "nomeStraniero": null,
      "codiceCatastale": "L986",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "info@comune.villanovaforru.ca.it",
      "pec": "protocollo.villanovaforru@pec.comunas.it",
      "telefono": "+39 070934511",
      "fax": "+39 07093451250",
      "coordinate": {
        "lat": 39.632266998291016,
        "lng": 8.869056701660156
      }
    },
    {
      "codice": "111101",
      "nome": "Villanovafranca",
      "nomeStraniero": null,
      "codiceCatastale": "L987",
      "cap": "09020",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "prot.villanovafranca@tiscali.it",
      "pec": "protocollo@pec.comune.villanovafranca.ca.it",
      "telefono": "+39 0709307592",
      "fax": "+39 0709367253",
      "coordinate": {
        "lat": 39.643898010253906,
        "lng": 9.0032958984375
      }
    },
    {
      "codice": "111102",
      "nome": "Villaperuccio",
      "nomeStraniero": null,
      "codiceCatastale": "M278",
      "cap": "09010",
      "prefisso": "0781",
      "provincia": "Sud Sardegna",
      "email": "anagrafe@comune.villaperuccio.ci.it",
      "pec": "protocollo@pec.comune.villaperuccio.ci.it",
      "telefono": "+39 0781950046",
      "fax": "+39 0781950304",
      "coordinate": {
        "lat": 39.11179733276367,
        "lng": 8.670251846313477
      }
    },
    {
      "codice": "111103",
      "nome": "Villaputzu",
      "nomeStraniero": null,
      "codiceCatastale": "L998",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "protocollo@comune.villaputzu.ca.it",
      "pec": "protocollo@pec.comune.villaputzu.ca.it",
      "telefono": "+39 070 997013",
      "fax": "+39 070 997075",
      "coordinate": {
        "lat": 39.43916702270508,
        "lng": 9.57611083984375
      }
    },
    {
      "codice": "111104",
      "nome": "Villasalto",
      "nomeStraniero": null,
      "codiceCatastale": "M016",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segreteria@comune.villasalto.ca.it",
      "pec": "protocollo.villasalto@pec.comunas.it",
      "telefono": "+39 07095690205",
      "fax": "+39 07095690230",
      "coordinate": {
        "lat": 39.493412017822266,
        "lng": 9.39004898071289
      }
    },
    {
      "codice": "111105",
      "nome": "Villasimius",
      "nomeStraniero": null,
      "codiceCatastale": "B738",
      "cap": "09049",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segretario@comune.villasimius.ca.it",
      "pec": "protocollo.comunevillasimius@legalmail.it",
      "telefono": "+39 070.79301",
      "fax": "+39 070.7928041",
      "coordinate": {
        "lat": 39.1422233581543,
        "lng": 9.52055549621582
      }
    },
    {
      "codice": "111106",
      "nome": "Villasor",
      "nomeStraniero": null,
      "codiceCatastale": "M025",
      "cap": "09034",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "segreteriavillasor@legalmail.it",
      "pec": "segreteriavillasor@legalmail.it",
      "telefono": "+39 070 9648023",
      "fax": "+39 070 9647331",
      "coordinate": {
        "lat": 39.38088607788086,
        "lng": 8.941326141357422
      }
    },
    {
      "codice": "111107",
      "nome": "Villaspeciosa",
      "nomeStraniero": null,
      "codiceCatastale": "M026",
      "cap": "09010",
      "prefisso": "070",
      "provincia": "Sud Sardegna",
      "email": "demografici@comune.villaspeciosa.ca.it",
      "pec": "comune.villaspeciosa@legalmail.it",
      "telefono": "+39 0709639039",
      "fax": "+39 0709639540",
      "coordinate": {
        "lat": 39.311458587646484,
        "lng": 8.926563262939453
      }
    }
  ],
  "Grosseto": [
    {
      "codice": "053001",
      "nome": "Arcidosso",
      "nomeStraniero": null,
      "codiceCatastale": "A369",
      "cap": "58031",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "segreteria@comune.arcidosso.gr.it",
      "pec": "protocollo@pec.comune.arcidosso.gr.it",
      "telefono": "+39 0564/965050",
      "fax": "+39 0564/966010",
      "coordinate": {
        "lat": 42.872222900390625,
        "lng": 11.537500381469727
      }
    },
    {
      "codice": "053002",
      "nome": "Campagnatico",
      "nomeStraniero": null,
      "codiceCatastale": "B497",
      "cap": "58042",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "ufficiosociale@comune.campagnatico.gr.it",
      "pec": "comune.campagnatico@postacert.toscana.it",
      "telefono": "+39 0564996711",
      "fax": "+39 0564996727",
      "coordinate": {
        "lat": 42.88444519042969,
        "lng": 11.27138900756836
      }
    },
    {
      "codice": "053003",
      "nome": "Capalbio",
      "nomeStraniero": null,
      "codiceCatastale": "B646",
      "cap": "58011",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "ced@comune.capalbio.gr.it",
      "pec": "info@pec.comune.capalbio.gr.it",
      "telefono": "+39 0564/897738",
      "fax": "+39 0564/897744",
      "coordinate": {
        "lat": 42.454166412353516,
        "lng": 11.422499656677246
      }
    },
    {
      "codice": "053004",
      "nome": "Castel del Piano",
      "nomeStraniero": null,
      "codiceCatastale": "C085",
      "cap": "58033",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "a.barzagli@comune.casteldelpiano.gr.it",
      "pec": "comune.casteldelpiano@postacert.toscana.it",
      "telefono": "+39 0564/965451",
      "fax": "+39 0564/957155",
      "coordinate": {
        "lat": 42.89083480834961,
        "lng": 11.539443969726562
      }
    },
    {
      "codice": "053005",
      "nome": "Castell'Azzara",
      "nomeStraniero": null,
      "codiceCatastale": "C147",
      "cap": "58034",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "protocollo@comune.castellazzara.gr.it",
      "pec": "comune.castellazzara@postacert.toscana.it",
      "telefono": "+39 0564/951038",
      "fax": "+39 0564951463",
      "coordinate": {
        "lat": 42.772499084472656,
        "lng": 11.69861125946045
      }
    },
    {
      "codice": "053006",
      "nome": "Castiglione della Pescaia",
      "nomeStraniero": null,
      "codiceCatastale": "C310",
      "cap": "58043",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "g.giannini@comune.castiglionedellapescaia.gr.it",
      "pec": "comune.castiglione.pescaia@legalmail.it",
      "telefono": "+39 0564/927222",
      "fax": "+39 0564/927219",
      "coordinate": {
        "lat": 42.76555633544922,
        "lng": 10.880833625793457
      }
    },
    {
      "codice": "053007",
      "nome": "Cinigiano",
      "nomeStraniero": null,
      "codiceCatastale": "C705",
      "cap": "58044",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "f.ranieri@comune.cinigiano.gr.it",
      "pec": "comune.cinigiano@postacert.toscana.it",
      "telefono": "+39 0564/994460",
      "fax": "+39 0564/994103",
      "coordinate": {
        "lat": 42.89055633544922,
        "lng": 11.392499923706055
      }
    },
    {
      "codice": "053008",
      "nome": "Civitella Paganico",
      "nomeStraniero": null,
      "codiceCatastale": "C782",
      "cap": "58045",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "protocollo@comune.civitellapaganico.gr.it",
      "pec": "comune.civitellapaganico@postacert.toscana.it",
      "telefono": "+39 0564/900411",
      "fax": "+39 0564/900414",
      "coordinate": {
        "lat": 42.994998931884766,
        "lng": 11.28166675567627
      }
    },
    {
      "codice": "053009",
      "nome": "Follonica",
      "nomeStraniero": null,
      "codiceCatastale": "D656",
      "cap": "58022",
      "prefisso": "0566",
      "provincia": "Grosseto",
      "email": "dfortunato@comune.follonica.gr.it",
      "pec": "follonica@postacert.toscana.it",
      "telefono": "+39 0566/59248",
      "fax": "+39 0566/59288",
      "coordinate": {
        "lat": 42.918888092041016,
        "lng": 10.761388778686523
      }
    },
    {
      "codice": "053010",
      "nome": "Gavorrano",
      "nomeStraniero": null,
      "codiceCatastale": "D948",
      "cap": "58023",
      "prefisso": "0566",
      "provincia": "Grosseto",
      "email": "f.tosi@comune.gavorrano.gr.it",
      "pec": "comune.gavorrano@postecert.it",
      "telefono": "+39 0566/843222",
      "fax": "+39 0566/843252",
      "coordinate": {
        "lat": 42.92499923706055,
        "lng": 10.908333778381348
      }
    },
    {
      "codice": "053011",
      "nome": "Grosseto",
      "nomeStraniero": null,
      "codiceCatastale": "E202",
      "cap": "58100",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "ezio.paglia@comune.grosseto.it",
      "pec": "comune.grosseto@postacert.toscana.it",
      "telefono": "+39 0564/488706",
      "fax": "+39 0564/21139",
      "coordinate": {
        "lat": 42.772220611572266,
        "lng": 11.108888626098633
      }
    },
    {
      "codice": "053012",
      "nome": "Isola del Giglio",
      "nomeStraniero": null,
      "codiceCatastale": "E348",
      "cap": "58010",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "a.bartoletti@comune.isoladelgiglio.gr.it",
      "pec": "comuneisoladelgiglio@pcert.it",
      "telefono": "+39 0564/806064",
      "fax": "+39 0564/806089",
      "coordinate": {
        "lat": 42.35499954223633,
        "lng": 10.904999732971191
      }
    },
    {
      "codice": "053013",
      "nome": "Magliano in Toscana",
      "nomeStraniero": null,
      "codiceCatastale": "E810",
      "cap": "58051",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "info@comune.maglianointoscana.gr.it",
      "pec": "comune.maglianointoscana@postemailcertificata.it",
      "telefono": "+39 0564/59341",
      "fax": "+39 0564/592517",
      "coordinate": {
        "lat": 42.5988883972168,
        "lng": 11.293055534362793
      }
    },
    {
      "codice": "053014",
      "nome": "Manciano",
      "nomeStraniero": null,
      "codiceCatastale": "E875",
      "cap": "58014",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "manciano@comune.manciano.gr.it",
      "pec": "comune.manciano@postacert.toscana.it",
      "telefono": "+39 0564/625341",
      "fax": "+39 0564/620496",
      "coordinate": {
        "lat": 42.587501525878906,
        "lng": 11.515000343322754
      }
    },
    {
      "codice": "053015",
      "nome": "Massa Marittima",
      "nomeStraniero": null,
      "codiceCatastale": "F032",
      "cap": "58024",
      "prefisso": "0566",
      "provincia": "Grosseto",
      "email": "c.cagnani@comune.massamarittima.gr.it",
      "pec": "comune.massamarittima@postacert.toscana.it",
      "telefono": "+39 0566/913710",
      "fax": "+39 0566/902052",
      "coordinate": {
        "lat": 43.04999923706055,
        "lng": 10.893610954284668
      }
    },
    {
      "codice": "053016",
      "nome": "Monte Argentario",
      "nomeStraniero": null,
      "codiceCatastale": "F437",
      "cap": "58019",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "urp@comune.monteargentario.gr.it",
      "pec": "argentario@pec.comune.monteargentario.gr.it",
      "telefono": "+39 0564/811911",
      "fax": "+39 0564/812044",
      "coordinate": {
        "lat": 42.43527603149414,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "053027",
      "nome": "Monterotondo Marittimo",
      "nomeStraniero": null,
      "codiceCatastale": "F612",
      "cap": "58025",
      "prefisso": "0566",
      "provincia": "Grosseto",
      "email": "demografici@comune.monterotondomarittimo.gr.it",
      "pec": "monterotondomarittimo@postacert.toscana.it",
      "telefono": "+39 0566/906350",
      "fax": "+39 0566/916390",
      "coordinate": {
        "lat": 43.14611053466797,
        "lng": 10.855555534362793
      }
    },
    {
      "codice": "053017",
      "nome": "Montieri",
      "nomeStraniero": null,
      "codiceCatastale": "F677",
      "cap": "58026",
      "prefisso": "0566",
      "provincia": "Grosseto",
      "email": "m.damico@comune.montieri.gr.it",
      "pec": "comune.montieri@postacert.toscana.it",
      "telefono": "+39 0566/906313",
      "fax": "+39 0566/997800",
      "coordinate": {
        "lat": 43.13111114501953,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "053018",
      "nome": "Orbetello",
      "nomeStraniero": null,
      "codiceCatastale": "G088",
      "cap": "58010",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "michele.scotto@comune.orbetello.gr.it",
      "pec": "protocollo@pec.comuneorbetello.it",
      "telefono": "+39 0564/861111",
      "fax": "+39 0564867252",
      "coordinate": {
        "lat": 42.43944549560547,
        "lng": 11.212499618530273
      }
    },
    {
      "codice": "053019",
      "nome": "Pitigliano",
      "nomeStraniero": null,
      "codiceCatastale": "G716",
      "cap": "58017",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "sindaco@comune.pitigliano.gr.it",
      "pec": "comune.pitigliano@postacert.toscana.it",
      "telefono": "+39 0564/616322",
      "fax": "+39 0564/616738",
      "coordinate": {
        "lat": 42.63444519042969,
        "lng": 11.668333053588867
      }
    },
    {
      "codice": "053020",
      "nome": "Roccalbegna",
      "nomeStraniero": null,
      "codiceCatastale": "H417",
      "cap": "58053",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "p.flamini@comune.roccalbegna.gr.it",
      "pec": "comune.roccalbegna@postacert.toscana.it",
      "telefono": "0564965031",
      "fax": "0564989222",
      "coordinate": {
        "lat": 42.78694534301758,
        "lng": 11.510000228881836
      }
    },
    {
      "codice": "053021",
      "nome": "Roccastrada",
      "nomeStraniero": null,
      "codiceCatastale": "H449",
      "cap": "58036",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "g.vellutini@comune.roccastrada.gr.it",
      "pec": "comune.roccastrada@postacert.toscana.it",
      "telefono": "0564561243",
      "fax": "0564561205",
      "coordinate": {
        "lat": 43.00972366333008,
        "lng": 11.168333053588867
      }
    },
    {
      "codice": "053022",
      "nome": "Santa Fiora",
      "nomeStraniero": null,
      "codiceCatastale": "I187",
      "cap": "58037",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "protocollo@comune.santafiora.gr.it",
      "pec": "comune.santafiora@postacert.toscana.it",
      "telefono": "0564965303",
      "fax": "0564979621",
      "coordinate": {
        "lat": 42.83194351196289,
        "lng": 11.585277557373047
      }
    },
    {
      "codice": "053023",
      "nome": "Scansano",
      "nomeStraniero": null,
      "codiceCatastale": "I504",
      "cap": "58054",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "info@comune.scansano.gr.it",
      "pec": "comune.scansano@postacert.toscana.it",
      "telefono": "+39 0564/509411",
      "fax": "+39 0564/509425",
      "coordinate": {
        "lat": 42.68972396850586,
        "lng": 11.334722518920898
      }
    },
    {
      "codice": "053024",
      "nome": "Scarlino",
      "nomeStraniero": null,
      "codiceCatastale": "I510",
      "cap": "58020",
      "prefisso": "0566",
      "provincia": "Grosseto",
      "email": "s.radi@comune.scarlino.gr.it",
      "pec": "comunescarlino.protocollo@postecert.it",
      "telefono": "+39 0566/38509",
      "fax": "+39 0566/37401",
      "coordinate": {
        "lat": 42.90805435180664,
        "lng": 10.85083293914795
      }
    },
    {
      "codice": "053025",
      "nome": "Seggiano",
      "nomeStraniero": null,
      "codiceCatastale": "I571",
      "cap": "58038",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "patrizia.netti@comune.seggiano.gr.it",
      "pec": "comune.seggiano@postacert.toscana.it",
      "telefono": "+39 0564/950925",
      "fax": "+39 0564/950976",
      "coordinate": {
        "lat": 42.92916488647461,
        "lng": 11.558333396911621
      }
    },
    {
      "codice": "053028",
      "nome": "Semproniano",
      "nomeStraniero": null,
      "codiceCatastale": "I601",
      "cap": "58055",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "comsemp@tiscali.it",
      "pec": "comune.semproniano.gr@postacert.toscana.it",
      "telefono": "+39 0564/987199",
      "fax": "+39 0564/986262",
      "coordinate": {
        "lat": 42.72999954223633,
        "lng": 11.54111099243164
      }
    },
    {
      "codice": "053026",
      "nome": "Sorano",
      "nomeStraniero": null,
      "codiceCatastale": "I841",
      "cap": "58010",
      "prefisso": "0564",
      "provincia": "Grosseto",
      "email": "l.biagi@comune.sorano.gr.it",
      "pec": "comune.sorano@postacert.toscana.it",
      "telefono": "+39 0564/633023",
      "fax": "+39 0564/633033",
      "coordinate": {
        "lat": 42.68194580078125,
        "lng": 11.714166641235352
      }
    }
  ],
  "Genova": [
    {
      "codice": "010001",
      "nome": "Arenzano",
      "nomeStraniero": null,
      "codiceCatastale": "A388",
      "cap": "16011",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "info@comune.arenzano.ge.it",
      "pec": "info@pec.comune.arenzano.ge.it",
      "telefono": "+39 010/91381",
      "fax": "+39 010/9138222",
      "coordinate": {
        "lat": 44.403526306152344,
        "lng": 8.682710647583008
      }
    },
    {
      "codice": "010002",
      "nome": "Avegno",
      "nomeStraniero": null,
      "codiceCatastale": "A506",
      "cap": "16036",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "segreteria@comune.avegno.ge.it",
      "pec": "comuneavegno@pec.it",
      "telefono": "+39 0185/79991",
      "fax": "+39 0185/799933",
      "coordinate": {
        "lat": 44.38233184814453,
        "lng": 9.158647537231445
      }
    },
    {
      "codice": "010003",
      "nome": "Bargagli",
      "nomeStraniero": null,
      "codiceCatastale": "A658",
      "cap": "16021",
      "prefisso": "010",
      "provincia": "Genova",
      "email": null,
      "pec": "comune.bargagli@halleypec.it",
      "telefono": null,
      "fax": null,
      "coordinate": {
        "lat": 44.44448471069336,
        "lng": 9.08857250213623
      }
    },
    {
      "codice": "010004",
      "nome": "Bogliasco",
      "nomeStraniero": null,
      "codiceCatastale": "A922",
      "cap": "16031",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "serviziamministrativi@comune.bogliasco.ge.it",
      "pec": "comune.bogliasco@halleycert.it",
      "telefono": "+39 010/374721",
      "fax": "+39 010/3470633",
      "coordinate": {
        "lat": 44.37824249267578,
        "lng": 9.069788932800293
      }
    },
    {
      "codice": "010005",
      "nome": "Borzonasca",
      "nomeStraniero": null,
      "codiceCatastale": "B067",
      "cap": "16041",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "amministrativo@comune.borzonasca.ge.it",
      "pec": "comune.borzonasca@pec.it",
      "telefono": "+39 0185/340003",
      "fax": "+39 0185/340489",
      "coordinate": {
        "lat": 44.4221305847168,
        "lng": 9.387063980102539
      }
    },
    {
      "codice": "010006",
      "nome": "Busalla",
      "nomeStraniero": null,
      "codiceCatastale": "B282",
      "cap": "16012",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segretario@comune.busalla.ge.it",
      "pec": "postacertificata@comune.busalla.ge.it",
      "telefono": "+39 010/97683",
      "fax": "+39 010/9640838",
      "coordinate": {
        "lat": 44.571044921875,
        "lng": 8.945343971252441
      }
    },
    {
      "codice": "010007",
      "nome": "Camogli",
      "nomeStraniero": null,
      "codiceCatastale": "B490",
      "cap": "16032",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "protocollo@comune.camogli.ge.it",
      "pec": "comune.camogli@halleypec.it",
      "telefono": "+39 0185/72901",
      "fax": "+39 0185/773504",
      "coordinate": {
        "lat": 44.34839630126953,
        "lng": 9.155757904052734
      }
    },
    {
      "codice": "010008",
      "nome": "Campo Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "B538",
      "cap": "16013",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "info@comune.campo-ligure.ge.it",
      "pec": "info@pec.comune.campo-ligure.ge.it",
      "telefono": "+39 010921003",
      "fax": "+39 010920963",
      "coordinate": {
        "lat": 44.538089752197266,
        "lng": 8.697792053222656
      }
    },
    {
      "codice": "010009",
      "nome": "Campomorone",
      "nomeStraniero": null,
      "codiceCatastale": "B551",
      "cap": "16014",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "areaamministrativa@comune.campomorone.ge.it",
      "pec": "protocollo@pec.comune.campomorone.ge.it",
      "telefono": "+39 010/72241",
      "fax": "+39 010/7224357",
      "coordinate": {
        "lat": 44.50688552856445,
        "lng": 8.891799926757812
      }
    },
    {
      "codice": "010010",
      "nome": "Carasco",
      "nomeStraniero": null,
      "codiceCatastale": "B726",
      "cap": "16042",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "comunecarasco@libero.it",
      "pec": "comunecarasco@actaliscertymail.it",
      "telefono": "+39 0185/350793",
      "fax": "+39 0185/351023",
      "coordinate": {
        "lat": 44.351715087890625,
        "lng": 9.344121932983398
      }
    },
    {
      "codice": "010011",
      "nome": "Casarza Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "B939",
      "cap": "16030",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "segreteria@comune.casarza-ligure.ge.it",
      "pec": "protocollo.casarza-ligure@pec.it",
      "telefono": "+39 0185/46981",
      "fax": "+39 0185/46236",
      "coordinate": {
        "lat": 44.274723052978516,
        "lng": 9.45341682434082
      }
    },
    {
      "codice": "010012",
      "nome": "Casella",
      "nomeStraniero": null,
      "codiceCatastale": "B956",
      "cap": "16015",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "anagrafe@comune.casella.ge.it",
      "pec": "protocollo@pec.comune.casella.ge.it",
      "telefono": "+39 010/9687728",
      "fax": "+39 010/9670211",
      "coordinate": {
        "lat": 44.53476333618164,
        "lng": 8.998855590820312
      }
    },
    {
      "codice": "010013",
      "nome": "Castiglione Chiavarese",
      "nomeStraniero": null,
      "codiceCatastale": "C302",
      "cap": "16030",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "castiglione.chiav@comune.castiglione-chiavarese.ge.it",
      "pec": "castiglione.chiavarese@pec.it",
      "telefono": "+39 0185/408016",
      "fax": "+39 0185/408522",
      "coordinate": {
        "lat": 44.274513244628906,
        "lng": 9.513635635375977
      }
    },
    {
      "codice": "010014",
      "nome": "Ceranesi",
      "nomeStraniero": null,
      "codiceCatastale": "C481",
      "cap": "16014",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segreteria@comune.ceranesi.ge.it",
      "pec": "protocollo@pec.comune.ceranesi.ge.it",
      "telefono": "+39 010/785961",
      "fax": null,
      "coordinate": {
        "lat": 44.50835418701172,
        "lng": 8.883960723876953
      }
    },
    {
      "codice": "010015",
      "nome": "Chiavari",
      "nomeStraniero": null,
      "codiceCatastale": "C621",
      "cap": "16043",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "segreteria@comune.chiavari.ge.it",
      "pec": "comune.chiavari@cert.legalmail.it",
      "telefono": "+39 0185 3651",
      "fax": "+39 0185/308511",
      "coordinate": {
        "lat": 44.316410064697266,
        "lng": 9.323736190795898
      }
    },
    {
      "codice": "010016",
      "nome": "Cicagna",
      "nomeStraniero": null,
      "codiceCatastale": "C673",
      "cap": "16044",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "anagrafe@comune.cicagna.ge.it",
      "pec": "comune.cicagna@anutel.it",
      "telefono": "+39 018592103",
      "fax": "+39 0185929954",
      "coordinate": {
        "lat": 44.408538818359375,
        "lng": 9.236332893371582
      }
    },
    {
      "codice": "010017",
      "nome": "Cogoleto",
      "nomeStraniero": null,
      "codiceCatastale": "C823",
      "cap": "16016",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "elettorale@comune.cogoleto.ge.it",
      "pec": "protocollo@pec.comune.cogoleto.ge.it",
      "telefono": "+39 0109170212",
      "fax": "+39 010/9170225",
      "coordinate": {
        "lat": 44.38956832885742,
        "lng": 8.646183013916016
      }
    },
    {
      "codice": "010018",
      "nome": "Cogorno",
      "nomeStraniero": null,
      "codiceCatastale": "C826",
      "cap": "16030",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "protocollo@comune.cogorno.ge.it",
      "pec": "protocollo.comune.cogorno.ge.it@legalmail.it",
      "telefono": "+39 0185/38571",
      "fax": "+39 0185/380825",
      "coordinate": {
        "lat": 44.33308029174805,
        "lng": 9.372461318969727
      }
    },
    {
      "codice": "010019",
      "nome": "Coreglia Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "C995",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "anagrafe@comune.coreglialigure.ge.it",
      "pec": "protocollo@pec.comune.coreglialigure.ge.it",
      "telefono": "+39 0185/334077",
      "fax": "+39 0185/334077",
      "coordinate": {
        "lat": 44.391876220703125,
        "lng": 9.267505645751953
      }
    },
    {
      "codice": "010020",
      "nome": "Crocefieschi",
      "nomeStraniero": null,
      "codiceCatastale": "D175",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segreteria@comune.crocefieschi.ge.it",
      "pec": "comune.crocefieschi@pec.it",
      "telefono": "+39 010/931215",
      "fax": "+39 010/931628",
      "coordinate": {
        "lat": 44.58462905883789,
        "lng": 9.023467063903809
      }
    },
    {
      "codice": "010021",
      "nome": "Davagna",
      "nomeStraniero": null,
      "codiceCatastale": "D255",
      "cap": "16022",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "info.davagna@comunedavagna.it",
      "pec": "comune.davagna@pec.net",
      "telefono": "+39 010/907107",
      "fax": "+39 010/907195",
      "coordinate": {
        "lat": 44.46693420410156,
        "lng": 9.089863777160645
      }
    },
    {
      "codice": "010022",
      "nome": "Fascia",
      "nomeStraniero": null,
      "codiceCatastale": "D509",
      "cap": "16020",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "comunedifascia@tin.it",
      "pec": "comune.fascia@pec.cstliguria.it",
      "telefono": "+39 010/95978",
      "fax": "+39 010/95978",
      "coordinate": {
        "lat": 44.56589889526367,
        "lng": 9.237600326538086
      }
    },
    {
      "codice": "010023",
      "nome": "Favale di Malvaro",
      "nomeStraniero": null,
      "codiceCatastale": "D512",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "comunedifavale@libero.it",
      "pec": "comune.favaledimalvaro@pec.it",
      "telefono": "+39 0185975195",
      "fax": "+39 0185975067",
      "coordinate": {
        "lat": 44.451995849609375,
        "lng": 9.25790786743164
      }
    },
    {
      "codice": "010024",
      "nome": "Fontanigorda",
      "nomeStraniero": null,
      "codiceCatastale": "D677",
      "cap": "16023",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "fontanigorda@virgilio.it",
      "pec": "comune.fontanigorda@pec.cstliguria.it",
      "telefono": "+39 010/952017",
      "fax": "+39 010/9527000",
      "coordinate": {
        "lat": 44.546573638916016,
        "lng": 9.304744720458984
      }
    },
    {
      "codice": "010025",
      "nome": "Genova",
      "nomeStraniero": null,
      "codiceCatastale": "D969",
      "cap": "16121",
      "prefisso": "010",
      "provincia": "Genova",
      "email": null,
      "pec": "comunegenova@postemailcertificata.it",
      "telefono": "0101010",
      "fax": null,
      "coordinate": {
        "lat": 44.40718460083008,
        "lng": 8.93398380279541
      }
    },
    {
      "codice": "010026",
      "nome": "Gorreto",
      "nomeStraniero": null,
      "codiceCatastale": "E109",
      "cap": "16020",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "comunedigorreto@libero.it",
      "pec": "comune.gorreto@actaliscertymail.it",
      "telefono": "+39 010/9543022",
      "fax": "+39 010/9543607",
      "coordinate": {
        "lat": 44.6060676574707,
        "lng": 9.292433738708496
      }
    },
    {
      "codice": "010027",
      "nome": "Isola del Cantone",
      "nomeStraniero": null,
      "codiceCatastale": "E341",
      "cap": "16017",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "demografici@comune.isoladelcantone.ge.it",
      "pec": "protocollo@pec.comune.isoladelcantone.ge.it",
      "telefono": "+39 010/9636116",
      "fax": "+39 010/9636018",
      "coordinate": {
        "lat": 44.646141052246094,
        "lng": 8.95686149597168
      }
    },
    {
      "codice": "010028",
      "nome": "Lavagna",
      "nomeStraniero": null,
      "codiceCatastale": "E488",
      "cap": "16033",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "chioino.anagrafe@comune.lavagna.ge.it",
      "pec": "postacertificata@pec.comune.lavagna.ge.it",
      "telefono": "+39 0185 3671",
      "fax": "+39 0185/395087",
      "coordinate": {
        "lat": 44.31215286254883,
        "lng": 9.341706275939941
      }
    },
    {
      "codice": "010029",
      "nome": "Leivi",
      "nomeStraniero": null,
      "codiceCatastale": "E519",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "ufficioanagrafe@comune.leivi.ge.it",
      "pec": "protocollo@pec.comune.leivi.ge.it",
      "telefono": "+39 0185/319033",
      "fax": "+39 0185/319569",
      "coordinate": {
        "lat": 44.345706939697266,
        "lng": 9.319010734558105
      }
    },
    {
      "codice": "010030",
      "nome": "Lorsica",
      "nomeStraniero": null,
      "codiceCatastale": "E695",
      "cap": "16045",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "comunelorsica@libero.it",
      "pec": "comunelorsica@actaliscertymail.it",
      "telefono": "+39 0185/95019",
      "fax": "+39 0185/95180",
      "coordinate": {
        "lat": 44.42951202392578,
        "lng": 9.267861366271973
      }
    },
    {
      "codice": "010031",
      "nome": "Lumarzo",
      "nomeStraniero": null,
      "codiceCatastale": "E737",
      "cap": "16024",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "info@comune.lumarzo.genova.it",
      "pec": "comune.lumarzo.ge@pec.it",
      "telefono": "+39 0185/96135",
      "fax": "+39 0185/964010",
      "coordinate": {
        "lat": 44.44352722167969,
        "lng": 9.137238502502441
      }
    },
    {
      "codice": "010032",
      "nome": "Masone",
      "nomeStraniero": null,
      "codiceCatastale": "F020",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "info@comune.masone.ge.it",
      "pec": "protocollo@pec.comune.masone.ge.it",
      "telefono": "+39 010926003",
      "fax": "+39 010926658",
      "coordinate": {
        "lat": 44.503562927246094,
        "lng": 8.711953163146973
      }
    },
    {
      "codice": "010033",
      "nome": "Mele",
      "nomeStraniero": null,
      "codiceCatastale": "F098",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segreteria@comune.mele.ge.it",
      "pec": "protocollo@pec.comune.mele.ge.it",
      "telefono": "+39 010/6319042",
      "fax": "+39 010/6319202",
      "coordinate": {
        "lat": 44.44549560546875,
        "lng": 8.747941970825195
      }
    },
    {
      "codice": "010034",
      "nome": "Mezzanego",
      "nomeStraniero": null,
      "codiceCatastale": "F173",
      "cap": "16046",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "demografia@comunemezzanego.it",
      "pec": "comunemezzanego@pec.it",
      "telefono": "+39 0185/336085",
      "fax": "+39 0185/336398",
      "coordinate": {
        "lat": 44.383689880371094,
        "lng": 9.377288818359375
      }
    },
    {
      "codice": "010035",
      "nome": "Mignanego",
      "nomeStraniero": null,
      "codiceCatastale": "F202",
      "cap": "16018",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segreteria@comunedimignanego.it",
      "pec": "comunedimignanego@actaliscertymail.it",
      "telefono": "+39 010/7720381",
      "fax": "+39 010/7720425",
      "coordinate": {
        "lat": 44.52782440185547,
        "lng": 8.915185928344727
      }
    },
    {
      "codice": "010036",
      "nome": "Moconesi",
      "nomeStraniero": null,
      "codiceCatastale": "F256",
      "cap": "16047",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "demograficimoconesi@libero.it",
      "pec": "demograficimoconesi@legalmail.it",
      "telefono": "+39 0185/939001",
      "fax": "+39 0185/939039",
      "coordinate": {
        "lat": 44.419837951660156,
        "lng": 9.212358474731445
      }
    },
    {
      "codice": "010037",
      "nome": "Moneglia",
      "nomeStraniero": null,
      "codiceCatastale": "F354",
      "cap": "16030",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "info@comune.moneglia.ge.it",
      "pec": "comune.moneglia.ge@legalmail.it",
      "telefono": "+39 0185/490811",
      "fax": "+39 0185/491335",
      "coordinate": {
        "lat": 44.23862075805664,
        "lng": 9.490275382995605
      }
    },
    {
      "codice": "010038",
      "nome": "Montebruno",
      "nomeStraniero": null,
      "codiceCatastale": "F445",
      "cap": "16025",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "anagrafe@comune.montebruno.ge.it",
      "pec": "comunemontebruno@pec.it",
      "telefono": "+39 010/95181",
      "fax": "+39 010/95010",
      "coordinate": {
        "lat": 44.52597427368164,
        "lng": 9.249013900756836
      }
    },
    {
      "codice": "010039",
      "nome": "Montoggio",
      "nomeStraniero": null,
      "codiceCatastale": "F682",
      "cap": "16026",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segreteria@comune.montoggio.ge.it",
      "pec": "comunemontoggio@actaliscertymail.it",
      "telefono": "+39 010/937931",
      "fax": "+39 010/938248",
      "coordinate": {
        "lat": 44.515140533447266,
        "lng": 9.043608665466309
      }
    },
    {
      "codice": "010040",
      "nome": "Ne",
      "nomeStraniero": null,
      "codiceCatastale": "F858",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "demografici@comune.ne.ge.it",
      "pec": "info@pec.comune.ne.ge.it",
      "telefono": "+39 0185/337090",
      "fax": "+39 0185/337530",
      "coordinate": {
        "lat": 44.344520568847656,
        "lng": 9.398547172546387
      }
    },
    {
      "codice": "010041",
      "nome": "Neirone",
      "nomeStraniero": null,
      "codiceCatastale": "F862",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "sindaco@comune.neirone.ge.it",
      "pec": "protocollo@pec.comune.neirone.ge.it",
      "telefono": "+39 0185/936003",
      "fax": "+39 0185/936002",
      "coordinate": {
        "lat": 44.45429611206055,
        "lng": 9.190855979919434
      }
    },
    {
      "codice": "010042",
      "nome": "Orero",
      "nomeStraniero": null,
      "codiceCatastale": "G093",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "servizi.demografici@comune.orero.ge.it",
      "pec": "comuneorero@legalmail.it",
      "telefono": "+39 0185/354988",
      "fax": "+39 0185/354993",
      "coordinate": {
        "lat": 44.40684127807617,
        "lng": 9.274149894714355
      }
    },
    {
      "codice": "010043",
      "nome": "Pieve Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "G646",
      "cap": "16031",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "sindaco@comune.pieveligure.ge.it",
      "pec": "comune.pieveligure@pec.it",
      "telefono": "+39 010/3462247",
      "fax": "+39 010/3460861",
      "coordinate": {
        "lat": 44.37480926513672,
        "lng": 9.086597442626953
      }
    },
    {
      "codice": "010044",
      "nome": "Portofino",
      "nomeStraniero": null,
      "codiceCatastale": "G913",
      "cap": "16034",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "protocollo@comune.portofino.genova.it",
      "pec": "protocollo@pec.comune.portofino.genova.it",
      "telefono": "+39 0185 267710",
      "fax": "+39 0185/269646",
      "coordinate": {
        "lat": 44.30424118041992,
        "lng": 9.207160949707031
      }
    },
    {
      "codice": "010045",
      "nome": "Propata",
      "nomeStraniero": null,
      "codiceCatastale": "H073",
      "cap": "16027",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "segreteria@comune.propata.ge.it",
      "pec": "protocollo@pec.comune.propata.ge.it",
      "telefono": "+39 010/945910",
      "fax": "+39 010/945910",
      "coordinate": {
        "lat": 44.564701080322266,
        "lng": 9.184880256652832
      }
    },
    {
      "codice": "010046",
      "nome": "Rapallo",
      "nomeStraniero": null,
      "codiceCatastale": "H183",
      "cap": "16035",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "protocollo@pec.comune.rapallo.ge.it",
      "pec": "protocollo@pec.comune.rapallo.ge.it",
      "telefono": "+39 0185 6801",
      "fax": "+39 0185/680238",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 9.233333587646484
      }
    },
    {
      "codice": "010047",
      "nome": "Recco",
      "nomeStraniero": null,
      "codiceCatastale": "H212",
      "cap": "16036",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "segreteria@comune.recco.ge.it",
      "pec": "segreteria@pec.comune.recco.ge.it",
      "telefono": "+39 01857291212",
      "fax": "+39 01857291228",
      "coordinate": {
        "lat": 44.36208724975586,
        "lng": 9.143474578857422
      }
    },
    {
      "codice": "010048",
      "nome": "Rezzoaglio",
      "nomeStraniero": null,
      "codiceCatastale": "H258",
      "cap": "16048",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "anagrafe@comune.rezzoaglio.ge.it",
      "pec": "protocollo@pec.comune.rezzoaglio.ge.it",
      "telefono": "+39 0185/870015",
      "fax": "+39 0185/870048",
      "coordinate": {
        "lat": 44.52626037597656,
        "lng": 9.386652946472168
      }
    },
    {
      "codice": "010049",
      "nome": "Ronco Scrivia",
      "nomeStraniero": null,
      "codiceCatastale": "H536",
      "cap": "16019",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "anagrafica@comune.roncoscrivia.ge.it",
      "pec": "comune.roncoscrivia@pec.it",
      "telefono": "+39 010/9659028",
      "fax": "+39 010/9356343",
      "coordinate": {
        "lat": 44.60820388793945,
        "lng": 8.955166816711426
      }
    },
    {
      "codice": "010050",
      "nome": "Rondanina",
      "nomeStraniero": null,
      "codiceCatastale": "H546",
      "cap": "16025",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "anagrafe@comune.rondanina.ge.it",
      "pec": "comunerondanina@pec.it",
      "telefono": "+39 01095854",
      "fax": "+39 01095854",
      "coordinate": {
        "lat": 44.56239700317383,
        "lng": 9.21773910522461
      }
    },
    {
      "codice": "010051",
      "nome": "Rossiglione",
      "nomeStraniero": null,
      "codiceCatastale": "H581",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "comune.rossiglione.@tin.it",
      "pec": "comune.rossiglione@postecert.it",
      "telefono": "+39 010/925008",
      "fax": "+39 010/924424",
      "coordinate": {
        "lat": 44.56666564941406,
        "lng": 8.666666984558105
      }
    },
    {
      "codice": "010052",
      "nome": "Rovegno",
      "nomeStraniero": null,
      "codiceCatastale": "H599",
      "cap": "16028",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "comune@comune.rovegno.ge.it",
      "pec": "comune.rovegno@legalmail.it",
      "telefono": "+39 010/955033",
      "fax": "+39 010/955033",
      "coordinate": {
        "lat": 44.575870513916016,
        "lng": 9.278839111328125
      }
    },
    {
      "codice": "010053",
      "nome": "San Colombano Certenoli",
      "nomeStraniero": null,
      "codiceCatastale": "H802",
      "cap": "16040",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "amministrativo@comune.sancolombanocertenoli.ge.it",
      "pec": "comune.sancolombanocertenoli@legalmail.it",
      "telefono": "+39 0185/358060",
      "fax": "+39 0185/358695",
      "coordinate": {
        "lat": 44.37175750732422,
        "lng": 9.311624526977539
      }
    },
    {
      "codice": "010055",
      "nome": "Sant'Olcese",
      "nomeStraniero": null,
      "codiceCatastale": "I346",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "servizio.demografico@comune.santolcese.ge.it",
      "pec": "protocollo@pec.comune.santolcese.ge.it",
      "telefono": "+39 010/7267111",
      "fax": "+39 010/7092428",
      "coordinate": {
        "lat": 44.4833869934082,
        "lng": 8.935230255126953
      }
    },
    {
      "codice": "010054",
      "nome": "Santa Margherita Ligure",
      "nomeStraniero": null,
      "codiceCatastale": "I225",
      "cap": "16038",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "protocollo@comunesml.it",
      "pec": "protocollo@pec.comunesml.it",
      "telefono": "+39 0185 2051",
      "fax": "+39 0185/280982",
      "coordinate": {
        "lat": 44.33493423461914,
        "lng": 9.210122108459473
      }
    },
    {
      "codice": "010056",
      "nome": "Santo Stefano d'Aveto",
      "nomeStraniero": null,
      "codiceCatastale": "I368",
      "cap": "16049",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "anagrafe@comune.santostefanodaveto.ge.it",
      "pec": "protocollo@pec.comune.santostefanodaveto.ge.it",
      "telefono": "+39 0185/88007",
      "fax": "+39 0185/887007",
      "coordinate": {
        "lat": 44.5477180480957,
        "lng": 9.450733184814453
      }
    },
    {
      "codice": "010057",
      "nome": "Savignone",
      "nomeStraniero": null,
      "codiceCatastale": "I475",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "demografici@comune.savignone.ge.it",
      "pec": "comune.savignone@pec.it",
      "telefono": "+39 010/9360103",
      "fax": "+39 010/936157",
      "coordinate": {
        "lat": 44.563926696777344,
        "lng": 8.9886474609375
      }
    },
    {
      "codice": "010058",
      "nome": "Serra Riccò",
      "nomeStraniero": null,
      "codiceCatastale": "I640",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "info@comune.serraricco.ge.it",
      "pec": "comune.serraricco.ge@halleycert.it",
      "telefono": "+39 010/726731",
      "fax": "+39 010/752275",
      "coordinate": {
        "lat": 44.50775909423828,
        "lng": 8.93595027923584
      }
    },
    {
      "codice": "010059",
      "nome": "Sestri Levante",
      "nomeStraniero": null,
      "codiceCatastale": "I693",
      "cap": "16039",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "info@comune.sestri-levante.ge.it",
      "pec": "informatica@pec.comune.sestri-levante.ge.it",
      "telefono": "+39 0185 4781",
      "fax": "+39 0185/478319",
      "coordinate": {
        "lat": 44.27333450317383,
        "lng": 9.39323902130127
      }
    },
    {
      "codice": "010060",
      "nome": "Sori",
      "nomeStraniero": null,
      "codiceCatastale": "I852",
      "cap": "16031",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "anagaire@comune.sori.ge.it",
      "pec": "comune.sori.ge@legalmail.it",
      "telefono": "+39 0185 7331",
      "fax": "+39 0185/733205",
      "coordinate": {
        "lat": 44.372684478759766,
        "lng": 9.104233741760254
      }
    },
    {
      "codice": "010061",
      "nome": "Tiglieto",
      "nomeStraniero": null,
      "codiceCatastale": "L167",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "comune.tiglieto@tiscali.it",
      "pec": "comune.tiglieto@pec.it",
      "telefono": "+39 010/929001",
      "fax": "+39 010/929356",
      "coordinate": {
        "lat": 44.52496337890625,
        "lng": 8.619811058044434
      }
    },
    {
      "codice": "010062",
      "nome": "Torriglia",
      "nomeStraniero": null,
      "codiceCatastale": "L298",
      "cap": "16029",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "anagrafe@comune.torriglia.ge.it",
      "pec": "protocollogenerale.comunetorriglia@pec.it",
      "telefono": "+39 010/944038",
      "fax": "+39 010/943297",
      "coordinate": {
        "lat": 44.518733978271484,
        "lng": 9.157978057861328
      }
    },
    {
      "codice": "010063",
      "nome": "Tribogna",
      "nomeStraniero": null,
      "codiceCatastale": "L416",
      "cap": "16030",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "areademografica@comune.tribogna.ge.it",
      "pec": "comune.tribogna@pec.it",
      "telefono": "+39 0185/935017",
      "fax": "+39 0185/933075",
      "coordinate": {
        "lat": 44.41636276245117,
        "lng": 9.195905685424805
      }
    },
    {
      "codice": "010064",
      "nome": "Uscio",
      "nomeStraniero": null,
      "codiceCatastale": "L507",
      "cap": "16036",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "info@comune.uscio.ge.it",
      "pec": "comune.uscio.ge@halleycert.it",
      "telefono": "+39 0185/919401",
      "fax": "+39 0185/919413",
      "coordinate": {
        "lat": 44.413204193115234,
        "lng": 9.162958145141602
      }
    },
    {
      "codice": "010065",
      "nome": "Valbrevenna",
      "nomeStraniero": null,
      "codiceCatastale": "L546",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "info@comune.valbrevenna.ge.it",
      "pec": "protocollo@pec.comune.valbrevenna.ge.it",
      "telefono": "+39 010/9390014",
      "fax": "+39 010/9390297",
      "coordinate": {
        "lat": 44.560577392578125,
        "lng": 9.09160327911377
      }
    },
    {
      "codice": "010066",
      "nome": "Vobbia",
      "nomeStraniero": null,
      "codiceCatastale": "M105",
      "cap": "16010",
      "prefisso": "010",
      "provincia": "Genova",
      "email": "serviziosegreteria@comunedivobbia.com",
      "pec": "comune.vobbia@pec.it",
      "telefono": "+39 010/939311",
      "fax": "+39 010/931543",
      "coordinate": {
        "lat": 44.5993766784668,
        "lng": 9.040027618408203
      }
    },
    {
      "codice": "010067",
      "nome": "Zoagli",
      "nomeStraniero": null,
      "codiceCatastale": "M182",
      "cap": "16035",
      "prefisso": "0185",
      "provincia": "Genova",
      "email": "info@comune.zoagli.ge.it",
      "pec": "comunezoagli@halleycert.it",
      "telefono": "+39 0185 25051",
      "fax": "+39 0185/258487",
      "coordinate": {
        "lat": 44.336971282958984,
        "lng": 9.267091751098633
      }
    }
  ],
  "Ferrara": [
    {
      "codice": "038001",
      "nome": "Argenta",
      "nomeStraniero": null,
      "codiceCatastale": "A393",
      "cap": "44040",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "segrsindaco@comune.argenta.fe.it",
      "pec": "municipio@pec.comune.argenta.fe.it",
      "telefono": "+39 0532/330111",
      "fax": "+39 0532/330244",
      "coordinate": {
        "lat": 44.61305618286133,
        "lng": 11.83638858795166
      }
    },
    {
      "codice": "038003",
      "nome": "Bondeno",
      "nomeStraniero": null,
      "codiceCatastale": "A965",
      "cap": "44012",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "sindaco@comune.bondeno.fe.it",
      "pec": "comune.bondeno@cert.comune.bondeno.fe.it",
      "telefono": "+39 0532/899111",
      "fax": "+39 0532/899280",
      "coordinate": {
        "lat": 44.88943862915039,
        "lng": 11.415419578552246
      }
    },
    {
      "codice": "038004",
      "nome": "Cento",
      "nomeStraniero": null,
      "codiceCatastale": "C469",
      "cap": "44042",
      "prefisso": "051",
      "provincia": "Ferrara",
      "email": "segreteria@comune.cento.fe.it",
      "pec": "comune.cento@cert.comune.cento.fe.it",
      "telefono": "+39 051 6843111",
      "fax": "+39 051 6843120",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 11.283333778381348
      }
    },
    {
      "codice": "038005",
      "nome": "Codigoro",
      "nomeStraniero": null,
      "codiceCatastale": "C814",
      "cap": "44021",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "enrico.rossi@comune.codigoro.fe.it",
      "pec": "comune.codigoro@cert.comune.codigoro.fe.it",
      "telefono": "+39 0533/729556",
      "fax": "+39 0533/729557",
      "coordinate": {
        "lat": 44.83333206176758,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "038006",
      "nome": "Comacchio",
      "nomeStraniero": null,
      "codiceCatastale": "C912",
      "cap": "44022",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "urp@comune.comacchio.fe.it",
      "pec": "comune.comacchio@cert.comune.comacchio.fe.it",
      "telefono": "+39 0533/310111",
      "fax": "+39 0533/310116",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 12.183333396911621
      }
    },
    {
      "codice": "038007",
      "nome": "Copparo",
      "nomeStraniero": null,
      "codiceCatastale": "C980",
      "cap": "44034",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "protocollo@comune.copparo.fe.it",
      "pec": "comune.copparo@cert.comune.copparo.fe.it",
      "telefono": "+39 0532/864511",
      "fax": "+39 0532/864660",
      "coordinate": {
        "lat": 44.900001525878906,
        "lng": 11.833333015441895
      }
    },
    {
      "codice": "038008",
      "nome": "Ferrara",
      "nomeStraniero": null,
      "codiceCatastale": "D548",
      "cap": "44121",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "segr.sindaco@comune.fe.it",
      "pec": "comune.ferrara@cert.comune.fe.it",
      "telefono": "+39 0532/419111",
      "fax": "+39 0532/419389",
      "coordinate": {
        "lat": 44.835296630859375,
        "lng": 11.619865417480469
      }
    },
    {
      "codice": "038027",
      "nome": "Fiscaglia",
      "nomeStraniero": null,
      "codiceCatastale": "M323",
      "cap": "44027",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "bruna.pagliarani@comune.fiscaglia.fe.it",
      "pec": "comune.fiscaglia@cert.comune.fiscaglia.fe.it",
      "telefono": "+39 0533 654150",
      "fax": "+39 0533 654772",
      "coordinate": {
        "lat": 44.77083206176758,
        "lng": 11.934721946716309
      }
    },
    {
      "codice": "038025",
      "nome": "Goro",
      "nomeStraniero": null,
      "codiceCatastale": "E107",
      "cap": "44020",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "protocollo@comune.goro.fe.it",
      "pec": "comune.goro@cert.comune.goro.fe.it",
      "telefono": "+39 0533/792911",
      "fax": "+39 0533/995179",
      "coordinate": {
        "lat": 44.85179901123047,
        "lng": 12.295900344848633
      }
    },
    {
      "codice": "038010",
      "nome": "Jolanda di Savoia",
      "nomeStraniero": null,
      "codiceCatastale": "E320",
      "cap": "44037",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "segreteria@comune.jolandadisavoia.fe.it",
      "pec": "pec@cert-comune-jolandadisavoia-fe.it",
      "telefono": "+39 0532 396502",
      "fax": "+39 0532 396522",
      "coordinate": {
        "lat": 44.88333511352539,
        "lng": 11.983333587646484
      }
    },
    {
      "codice": "038011",
      "nome": "Lagosanto",
      "nomeStraniero": null,
      "codiceCatastale": "E410",
      "cap": "44023",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "segreteria@comune.lagosanto.fe.it",
      "pec": "comune.lagosanto@cert.comune.lagosanto.fe.it",
      "telefono": "+39 0533/909511",
      "fax": "+39 0533/909536",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 12.133333206176758
      }
    },
    {
      "codice": "038012",
      "nome": "Masi Torello",
      "nomeStraniero": null,
      "codiceCatastale": "F016",
      "cap": "44020",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "protocollo@comune.masitorello.fe.it",
      "pec": "comunemasitorello@mailsicura.info",
      "telefono": "+39 0532/819113",
      "fax": "+39 0532/816677",
      "coordinate": {
        "lat": 44.794578552246094,
        "lng": 11.801819801330566
      }
    },
    {
      "codice": "038014",
      "nome": "Mesola",
      "nomeStraniero": null,
      "codiceCatastale": "F156",
      "cap": "44026",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "vicesegretario@comune.mesola.fe.it",
      "pec": "comune.mesola@cert.comune.mesola.fe.it",
      "telefono": "+39 0533/993719",
      "fax": "+39 0533/993662",
      "coordinate": {
        "lat": 44.920555114746094,
        "lng": 12.229999542236328
      }
    },
    {
      "codice": "038017",
      "nome": "Ostellato",
      "nomeStraniero": null,
      "codiceCatastale": "G184",
      "cap": "44020",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "affarigenerali@comune.ostellato.fe.it",
      "pec": "comune.ostellato@cert.comune.ostellato.fe.it",
      "telefono": "+39 0533/683911",
      "fax": "+39 0533/681056",
      "coordinate": {
        "lat": 44.75,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "038018",
      "nome": "Poggio Renatico",
      "nomeStraniero": null,
      "codiceCatastale": "G768",
      "cap": "44028",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "protocollo@comune.poggiorenatico.fe.it",
      "pec": "comune.poggiorenatico@cert.comune.poggiorenatico.fe.it",
      "telefono": "+39 0532/824511",
      "fax": "+39 0532/824580",
      "coordinate": {
        "lat": 44.76499938964844,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "038019",
      "nome": "Portomaggiore",
      "nomeStraniero": null,
      "codiceCatastale": "G916",
      "cap": "44015",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "segreteriagenerale@comune.portomaggiore.fe.it",
      "pec": "comune.portomaggiore@legalmail.it",
      "telefono": "+39 0532/323224",
      "fax": "+39 0532/810326",
      "coordinate": {
        "lat": 44.70000076293945,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "038029",
      "nome": "Riva del Po",
      "nomeStraniero": null,
      "codiceCatastale": "M410",
      "cap": "44033",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "mpollastri@comune.rivadelpo.fe.it",
      "pec": "comune.rivadelpo@cert.comune.rivadelpo.fe.it",
      "telefono": "0532390012",
      "fax": "0532832259",
      "coordinate": {
        "lat": 44.96382522583008,
        "lng": 11.909866333007812
      }
    },
    {
      "codice": "038028",
      "nome": "Terre del Reno",
      "nomeStraniero": null,
      "codiceCatastale": "M381",
      "cap": "44047",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "demografici@comune.terredelreno.fe.it",
      "pec": "comune.terredelreno@pec.it",
      "telefono": "+39 053284411",
      "fax": null,
      "coordinate": {
        "lat": 44.82722091674805,
        "lng": 11.461111068725586
      }
    },
    {
      "codice": "038030",
      "nome": "Tresignana",
      "nomeStraniero": null,
      "codiceCatastale": "M409",
      "cap": "44039",
      "prefisso": "0533",
      "provincia": "Ferrara",
      "email": "affarigenerali@comune.tresignana.fe.it",
      "pec": "comune.tresignana@cert.comune.tresignana.fe.it",
      "telefono": "053359012",
      "fax": "0533607710",
      "coordinate": {
        "lat": 44.81597900390625,
        "lng": 11.894719123840332
      }
    },
    {
      "codice": "038022",
      "nome": "Vigarano Mainarda",
      "nomeStraniero": null,
      "codiceCatastale": "L868",
      "cap": "44049",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "segreteria@comune.vigarano.fe.it",
      "pec": "comune.vigarano@cert.comune.vigarano.fe.it",
      "telefono": "+39 0532/436923",
      "fax": "+39 0532/436563",
      "coordinate": {
        "lat": 44.849998474121094,
        "lng": 11.5
      }
    },
    {
      "codice": "038023",
      "nome": "Voghiera",
      "nomeStraniero": null,
      "codiceCatastale": "M110",
      "cap": "44019",
      "prefisso": "0532",
      "provincia": "Ferrara",
      "email": "protocollo@comune.voghiera.fe.it",
      "pec": "comune.voghiera.fe@legalmail.it",
      "telefono": "+39 0532/328511",
      "fax": "+39 0532/818030",
      "coordinate": {
        "lat": 44.766666412353516,
        "lng": 11.75
      }
    }
  ],
  "Nuoro": [
    {
      "codice": "091001",
      "nome": "Aritzo",
      "nomeStraniero": null,
      "codiceCatastale": "A407",
      "cap": "08031",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.aritzo.nu.it",
      "pec": "protocollo@pec.comune.aritzo.nu.it",
      "telefono": "+39 0784 627236",
      "fax": "+39 0784 629293",
      "coordinate": {
        "lat": 39.95646286010742,
        "lng": 9.196758270263672
      }
    },
    {
      "codice": "091002",
      "nome": "Arzana",
      "nomeStraniero": null,
      "codiceCatastale": "A454",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "resp.amministrativo@comunediarzana.it",
      "pec": "comunearzana@pec.it",
      "telefono": "+39 0782 37350",
      "fax": null,
      "coordinate": {
        "lat": 39.91776657104492,
        "lng": 9.528751373291016
      }
    },
    {
      "codice": "091003",
      "nome": "Atzara",
      "nomeStraniero": null,
      "codiceCatastale": "A492",
      "cap": "08030",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "ragioneria@comune.atzara.nu.it",
      "pec": "sindaco@pec.comune.atzara.nu.it",
      "telefono": "+39 0784/65205",
      "fax": "+39 0784/65380",
      "coordinate": {
        "lat": 39.99148178100586,
        "lng": 9.076541900634766
      }
    },
    {
      "codice": "091004",
      "nome": "Austis",
      "nomeStraniero": null,
      "codiceCatastale": "A503",
      "cap": "08030",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "austis.comune@gmail.com",
      "pec": "austis.comune@cert.ollsys.it",
      "telefono": "+39 0784/67213",
      "fax": "+39 0784/67058",
      "coordinate": {
        "lat": 40.071388244628906,
        "lng": 9.088889122009277
      }
    },
    {
      "codice": "091005",
      "nome": "Bari Sardo",
      "nomeStraniero": null,
      "codiceCatastale": "A663",
      "cap": "08042",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "segreteria.aagg.@comunedibarisardo.gov.it",
      "pec": "protocollo@pec.comunedibarisardo.gov.it",
      "telefono": "+39 0782 29523",
      "fax": "+39 0782 28065",
      "coordinate": {
        "lat": 39.84136199951172,
        "lng": 9.647197723388672
      }
    },
    {
      "codice": "091006",
      "nome": "Baunei",
      "nomeStraniero": null,
      "codiceCatastale": "A722",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "info@comunedibaunei.it",
      "pec": "protocollo@pec.comunedibaunei.it",
      "telefono": "+39 0782 610823",
      "fax": "+39 0782 610385",
      "coordinate": {
        "lat": 40.03093338012695,
        "lng": 9.664320945739746
      }
    },
    {
      "codice": "091007",
      "nome": "Belvì",
      "nomeStraniero": null,
      "codiceCatastale": "A776",
      "cap": "08030",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "aagg@comune.belvi.nu.it",
      "pec": "comune.belvi@legalmail.it",
      "telefono": "+39 0784/629216",
      "fax": null,
      "coordinate": {
        "lat": 39.960609436035156,
        "lng": 9.184141159057617
      }
    },
    {
      "codice": "091008",
      "nome": "Birori",
      "nomeStraniero": null,
      "codiceCatastale": "A880",
      "cap": "08010",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "aagg.birori@virgilio.it",
      "pec": "protocollo@pec.comune.birori.nu.it",
      "telefono": "+39 0785/72002",
      "fax": "+39 0785/72990",
      "coordinate": {
        "lat": 40.26407241821289,
        "lng": 8.816699981689453
      }
    },
    {
      "codice": "091009",
      "nome": "Bitti",
      "nomeStraniero": null,
      "codiceCatastale": "A895",
      "cap": "08021",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.bitti.nu.it",
      "pec": "protocollo@pec.comune.bitti.nu.it",
      "telefono": "+39 0784/418023",
      "fax": "+39 0784/414308",
      "coordinate": {
        "lat": 40.474998474121094,
        "lng": 9.381667137145996
      }
    },
    {
      "codice": "091010",
      "nome": "Bolotana",
      "nomeStraniero": null,
      "codiceCatastale": "A948",
      "cap": "08011",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "info@comune.bolotana.nu.it",
      "pec": "protocollo@pec.comune.bolotana.nu.it",
      "telefono": "+39 0785/450205",
      "fax": "+39 0785/450215",
      "coordinate": {
        "lat": 40.328277587890625,
        "lng": 8.959305763244629
      }
    },
    {
      "codice": "091011",
      "nome": "Borore",
      "nomeStraniero": null,
      "codiceCatastale": "B056",
      "cap": "08016",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "info@comune.borore.nu.it",
      "pec": "protocollo@pec.comune.borore.nu.it",
      "telefono": "+39 0785/790010",
      "fax": "0785890032",
      "coordinate": {
        "lat": 40.21519470214844,
        "lng": 8.803138732910156
      }
    },
    {
      "codice": "091012",
      "nome": "Bortigali",
      "nomeStraniero": null,
      "codiceCatastale": "B062",
      "cap": "08012",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "susindigu@comune.bortigali.nu.it",
      "pec": "protocollo@pec.comune.bortigali.nu.it",
      "telefono": "+39 0785 80530",
      "fax": "+39 0785 80131",
      "coordinate": {
        "lat": 40.281490325927734,
        "lng": 8.83798599243164
      }
    },
    {
      "codice": "091103",
      "nome": "Cardedu",
      "nomeStraniero": null,
      "codiceCatastale": "M285",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "demografici@comunedicardedu.it",
      "pec": "protocollo@pec.comune.cardedu.nu.it",
      "telefono": "+39 0782 75740",
      "fax": "+39 0782 769031",
      "coordinate": {
        "lat": 39.79653549194336,
        "lng": 9.62677001953125
      }
    },
    {
      "codice": "091016",
      "nome": "Desulo",
      "nomeStraniero": null,
      "codiceCatastale": "D287",
      "cap": "08032",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "servdemdesulo@tiscali.it",
      "pec": "demografici.desulo@pec.it",
      "telefono": "+39 0784/619211",
      "fax": "+39 0784/619855",
      "coordinate": {
        "lat": 40.00796127319336,
        "lng": 9.224996566772461
      }
    },
    {
      "codice": "091017",
      "nome": "Dorgali",
      "nomeStraniero": null,
      "codiceCatastale": "D345",
      "cap": "08022",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "ufficio.elettorale@comune.dorgali.nu.it",
      "pec": "protocollo@pec.comune.dorgali.nu.it",
      "telefono": "+39 0784 927210",
      "fax": "+39 0784 94893",
      "coordinate": {
        "lat": 40.29157257080078,
        "lng": 9.587631225585938
      }
    },
    {
      "codice": "091018",
      "nome": "Dualchi",
      "nomeStraniero": null,
      "codiceCatastale": "D376",
      "cap": "08010",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "protocollo@comune.dualchi.nu.it",
      "pec": "protocollo@pec.comune.dualchi.nu.it",
      "telefono": "+39 0785 44723",
      "fax": "+39 0785 44902",
      "coordinate": {
        "lat": 40.230037689208984,
        "lng": 8.89841079711914
      }
    },
    {
      "codice": "091019",
      "nome": "Elini",
      "nomeStraniero": null,
      "codiceCatastale": "D395",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "comunedielini@tiscali.it",
      "pec": "protocollo@pec.comunedielini.gov.it",
      "telefono": "+39 0782 33069",
      "fax": "+39 0782 33733",
      "coordinate": {
        "lat": 39.899497985839844,
        "lng": 9.530725479125977
      }
    },
    {
      "codice": "091024",
      "nome": "Fonni",
      "nomeStraniero": null,
      "codiceCatastale": "D665",
      "cap": "08023",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comunedifonni@tiscali.it",
      "pec": "serviziamministrativi.fonni@legalmail.it",
      "telefono": "+39 0784/591321",
      "fax": "+39 0784/591319",
      "coordinate": {
        "lat": 40.118778228759766,
        "lng": 9.253578186035156
      }
    },
    {
      "codice": "091025",
      "nome": "Gadoni",
      "nomeStraniero": null,
      "codiceCatastale": "D842",
      "cap": "08030",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.gadoni.nu.it",
      "pec": "protocollo@pec.comune.gadoni.nu.it",
      "telefono": "+39 0784/627001",
      "fax": "+39 0784/625933",
      "coordinate": {
        "lat": 39.91304016113281,
        "lng": 9.18397045135498
      }
    },
    {
      "codice": "091026",
      "nome": "Gairo",
      "nomeStraniero": null,
      "codiceCatastale": "D859",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "protocollo@comune.gairo.og.it",
      "pec": "protocollo@pec.comune.gairo.og.it",
      "telefono": "+39 0782 760000",
      "fax": "+39 0782 73420",
      "coordinate": {
        "lat": 39.84646224975586,
        "lng": 9.506670951843262
      }
    },
    {
      "codice": "091027",
      "nome": "Galtellì",
      "nomeStraniero": null,
      "codiceCatastale": "D888",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "protocollo@comune.galtelli.nu.it",
      "pec": "protocollo@pec.comune.galtelli.nu.it",
      "telefono": "+39 0784 90005/90095",
      "fax": "+39 0784 90572",
      "coordinate": {
        "lat": 40.385128021240234,
        "lng": 9.611921310424805
      }
    },
    {
      "codice": "091028",
      "nome": "Gavoi",
      "nomeStraniero": null,
      "codiceCatastale": "D947",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comune.gavoi@tiscali.it",
      "pec": "protocollo@pec.comune.gavoi.nu.it",
      "telefono": "+39 0784/53389",
      "fax": "+39 0784/53263",
      "coordinate": {
        "lat": 40.160377502441406,
        "lng": 9.194698333740234
      }
    },
    {
      "codice": "091031",
      "nome": "Girasole",
      "nomeStraniero": null,
      "codiceCatastale": "E049",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": null,
      "pec": "protocollo.girasole@pec.comunas.it",
      "telefono": "+39 0782 623143",
      "fax": null,
      "coordinate": {
        "lat": 39.952049255371094,
        "lng": 9.660980224609375
      }
    },
    {
      "codice": "091032",
      "nome": "Ilbono",
      "nomeStraniero": null,
      "codiceCatastale": "E283",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "info@comune.ilbono.og.it",
      "pec": "protocollo@pec.comune.ilbono.og.it",
      "telefono": "+39 0782 33016",
      "fax": "+39 0782 33760",
      "coordinate": {
        "lat": 39.89221954345703,
        "lng": 9.54660415649414
      }
    },
    {
      "codice": "091033",
      "nome": "Irgoli",
      "nomeStraniero": null,
      "codiceCatastale": "E323",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "anagrafe@comune.irgoli.nu.it",
      "pec": "demografici.irgoli@legalmail.it",
      "telefono": "+39 0784/1826014",
      "fax": null,
      "coordinate": {
        "lat": 40.41035842895508,
        "lng": 9.630374908447266
      }
    },
    {
      "codice": "091035",
      "nome": "Jerzu",
      "nomeStraniero": null,
      "codiceCatastale": "E387",
      "cap": "08044",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "protocollo@comune.jerzu.og.it",
      "pec": "protocollo@pec.comune.jerzu.og.it",
      "telefono": "+39 0782 7608",
      "fax": "+39 0782 70681",
      "coordinate": {
        "lat": 39.79241180419922,
        "lng": 9.517679214477539
      }
    },
    {
      "codice": "091037",
      "nome": "Lanusei",
      "nomeStraniero": null,
      "codiceCatastale": "E441",
      "cap": "08045",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "comunedilanusei@tiscali.it",
      "pec": "protocollo.lanusei@pec.comunas.it",
      "telefono": "+39 07824731",
      "fax": null,
      "coordinate": {
        "lat": 39.87867736816406,
        "lng": 9.541454315185547
      }
    },
    {
      "codice": "091038",
      "nome": "Lei",
      "nomeStraniero": null,
      "codiceCatastale": "E517",
      "cap": "08010",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "comune.lei@tiscali.it",
      "pec": "protocollo@pec.comune.lei.nu.it",
      "telefono": "+39 0785/40805",
      "fax": "+39 0785/40703",
      "coordinate": {
        "lat": 40.30555725097656,
        "lng": 8.918889045715332
      }
    },
    {
      "codice": "091039",
      "nome": "Loceri",
      "nomeStraniero": null,
      "codiceCatastale": "E644",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "info@comuneloceri.it",
      "pec": "protocollo@pec.comuneloceri.it",
      "telefono": "+39 0782 77051",
      "fax": "+39 0782 77382",
      "coordinate": {
        "lat": 39.857574462890625,
        "lng": 9.582996368408203
      }
    },
    {
      "codice": "091040",
      "nome": "Loculi",
      "nomeStraniero": null,
      "codiceCatastale": "E646",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.loculi.nu.it",
      "pec": "protocollo@pec.comune.loculi.nu.it",
      "telefono": "+39 0784 97452",
      "fax": null,
      "coordinate": {
        "lat": 40.40617752075195,
        "lng": 9.609603881835938
      }
    },
    {
      "codice": "091041",
      "nome": "Lodè",
      "nomeStraniero": null,
      "codiceCatastale": "E647",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "agosanna@tiscali.it",
      "pec": "protocollo@pec.comunedilode.it",
      "telefono": "+39 0784/898018",
      "fax": "+39 0784/899627",
      "coordinate": {
        "lat": 40.59127426147461,
        "lng": 9.539566040039062
      }
    },
    {
      "codice": "091104",
      "nome": "Lodine",
      "nomeStraniero": null,
      "codiceCatastale": "E649",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comunelodine.gov.it",
      "pec": "affarigenerali@pec.comunelodine.gov.it",
      "telefono": "+39 0784 53492",
      "fax": "+39 0784 53432",
      "coordinate": {
        "lat": 40.14873504638672,
        "lng": 9.21928882598877
      }
    },
    {
      "codice": "091042",
      "nome": "Lotzorai",
      "nomeStraniero": null,
      "codiceCatastale": "E700",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": null,
      "pec": "protocollo.lotzorai@pec.comunas.it",
      "telefono": "+39 0782 669423",
      "fax": null,
      "coordinate": {
        "lat": 39.96962356567383,
        "lng": 9.662947654724121
      }
    },
    {
      "codice": "091043",
      "nome": "Lula",
      "nomeStraniero": null,
      "codiceCatastale": "E736",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "sindaco@comune.lula.nu.it",
      "pec": "protocollo@pec.comune.lula.nu.it",
      "telefono": "+39 078441791",
      "fax": "+39 0784416427",
      "coordinate": {
        "lat": 40.47019577026367,
        "lng": 9.48617935180664
      }
    },
    {
      "codice": "091044",
      "nome": "Macomer",
      "nomeStraniero": null,
      "codiceCatastale": "E788",
      "cap": "08015",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "anagrafe@pec.comune.macomer.nu.it",
      "pec": "protocollo@pec.comune.macomer.nu.it",
      "telefono": "+39 0785/790800",
      "fax": null,
      "coordinate": {
        "lat": 40.26435852050781,
        "lng": 8.77506160736084
      }
    },
    {
      "codice": "091046",
      "nome": "Mamoiada",
      "nomeStraniero": null,
      "codiceCatastale": "E874",
      "cap": "08024",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.mamoiada.nu.it",
      "pec": "protocollo.mamoiada@pec.comunas.it",
      "telefono": "+39 0784 56023",
      "fax": "+39 0784 56700",
      "coordinate": {
        "lat": 40.214168548583984,
        "lng": 9.283611297607422
      }
    },
    {
      "codice": "091047",
      "nome": "Meana Sardo",
      "nomeStraniero": null,
      "codiceCatastale": "F073",
      "cap": "08030",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "m.sardo@tiscali.it",
      "pec": "meana@pec.comune.meanasardo.nu.it",
      "telefono": "+39 0784 64362",
      "fax": "+39 0784 64203",
      "coordinate": {
        "lat": 39.946109771728516,
        "lng": 9.07361125946045
      }
    },
    {
      "codice": "091050",
      "nome": "Noragugume",
      "nomeStraniero": null,
      "codiceCatastale": "F933",
      "cap": "08010",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "info@conmune.noragugume.nu.it",
      "pec": "protocollo@pec.comune.noragugume.nu.it",
      "telefono": "+39 0785/44743",
      "fax": "+39 0785/44799",
      "coordinate": {
        "lat": 40.22416687011719,
        "lng": 8.920833587646484
      }
    },
    {
      "codice": "091051",
      "nome": "Nuoro",
      "nomeStraniero": null,
      "codiceCatastale": "F979",
      "cap": "08100",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "posta@comune.nuoro.it",
      "pec": "protocollo@pec.comune.nuoro.it",
      "telefono": "+39 0784 216836",
      "fax": null,
      "coordinate": {
        "lat": 40.32006072998047,
        "lng": 9.328079223632812
      }
    },
    {
      "codice": "091055",
      "nome": "Oliena",
      "nomeStraniero": null,
      "codiceCatastale": "G031",
      "cap": "08025",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "mesina.michela@comune.oliena.nu.it",
      "pec": "protocollo@pec.comune.oliena.nu.it",
      "telefono": "+39 0784/280209",
      "fax": "+39 0784/280256",
      "coordinate": {
        "lat": 40.27101135253906,
        "lng": 9.402751922607422
      }
    },
    {
      "codice": "091056",
      "nome": "Ollolai",
      "nomeStraniero": null,
      "codiceCatastale": "G044",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comunediollolai@tiscali.it",
      "pec": "protocollo.ollolai@pec.it",
      "telefono": "+39 0784 51051",
      "fax": "+39 0784 51499",
      "coordinate": {
        "lat": 40.16804885864258,
        "lng": 9.177760124206543
      }
    },
    {
      "codice": "091057",
      "nome": "Olzai",
      "nomeStraniero": null,
      "codiceCatastale": "G058",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.olzai.nu.it",
      "pec": "protocollo@pec.comune.olzai.nu.it",
      "telefono": "+39 0784/55001",
      "fax": null,
      "coordinate": {
        "lat": 40.182498931884766,
        "lng": 9.147777557373047
      }
    },
    {
      "codice": "091058",
      "nome": "Onanì",
      "nomeStraniero": null,
      "codiceCatastale": "G064",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "protocollo@comune.onani.nu.it",
      "pec": "protocollo@pec.comune.onani.nu.it",
      "telefono": "+39 0784/410023",
      "fax": "+39 0784/410101",
      "coordinate": {
        "lat": 40.48508071899414,
        "lng": 9.442405700683594
      }
    },
    {
      "codice": "091059",
      "nome": "Onifai",
      "nomeStraniero": null,
      "codiceCatastale": "G070",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "ragioneria@comune.onifai.nu.it",
      "pec": "amministrativo@pec.comune.onifai.nu.it",
      "telefono": "+39 0784/97418",
      "fax": "+39 0784/97278",
      "coordinate": {
        "lat": 40.40777587890625,
        "lng": 9.652222633361816
      }
    },
    {
      "codice": "091060",
      "nome": "Oniferi",
      "nomeStraniero": null,
      "codiceCatastale": "G071",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "segreteria.oniferi@tiscali.it",
      "pec": "protocollo@pec.comuneoniferi.nu.it",
      "telefono": "+39 0784/70051",
      "fax": "+39 0784 70204",
      "coordinate": {
        "lat": 40.27166748046875,
        "lng": 9.17027759552002
      }
    },
    {
      "codice": "091061",
      "nome": "Orani",
      "nomeStraniero": null,
      "codiceCatastale": "G084",
      "cap": "08026",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.orani.nu.it",
      "pec": "protocollo@pec.comune.orani.nu.it",
      "telefono": "+39 0784/74123",
      "fax": "+39 0784/74868",
      "coordinate": {
        "lat": 40.24944305419922,
        "lng": 9.178333282470703
      }
    },
    {
      "codice": "091062",
      "nome": "Orgosolo",
      "nomeStraniero": null,
      "codiceCatastale": "G097",
      "cap": "08027",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "utc.orgosolo@tiscali.it",
      "pec": "protocollo.orgosolo@pec.comunas.it",
      "telefono": "+39 0784 400910",
      "fax": "+39 0784 403143",
      "coordinate": {
        "lat": 40.20470428466797,
        "lng": 9.35262680053711
      }
    },
    {
      "codice": "091063",
      "nome": "Orosei",
      "nomeStraniero": null,
      "codiceCatastale": "G119",
      "cap": "08028",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "segreteria@comuneorosei.it",
      "pec": "protocollo@pec.comuneorosei.it",
      "telefono": "+39 0784 996900",
      "fax": "+39 0784 999189",
      "coordinate": {
        "lat": 40.379722595214844,
        "lng": 9.694167137145996
      }
    },
    {
      "codice": "091064",
      "nome": "Orotelli",
      "nomeStraniero": null,
      "codiceCatastale": "G120",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "affarigenerali@comune.orotelli.nu.it",
      "pec": "protocollo@pec.comune.orotelli.nu.it",
      "telefono": "+39 0784/79820",
      "fax": "+39 0784/79261",
      "coordinate": {
        "lat": 40.303550720214844,
        "lng": 9.112558364868164
      }
    },
    {
      "codice": "091066",
      "nome": "Ortueri",
      "nomeStraniero": null,
      "codiceCatastale": "G146",
      "cap": "08036",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "protocollo@comune.ortueri.nu.it",
      "pec": "protocollo@pec.comune.ortueri.nu.it",
      "telefono": "0784/66223",
      "fax": "+39 0784/66406",
      "coordinate": {
        "lat": 40.035369873046875,
        "lng": 8.986129760742188
      }
    },
    {
      "codice": "091067",
      "nome": "Orune",
      "nomeStraniero": null,
      "codiceCatastale": "G147",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "servizifinanziari@comune.orune.nu.it",
      "pec": "protocollo@pec.comune.orune.nu.it",
      "telefono": "+39 0784276823",
      "fax": "+39 0784278027",
      "coordinate": {
        "lat": 40.406700134277344,
        "lng": 9.369277954101562
      }
    },
    {
      "codice": "091068",
      "nome": "Osidda",
      "nomeStraniero": null,
      "codiceCatastale": "G154",
      "cap": "08020",
      "prefisso": "079",
      "provincia": "Nuoro",
      "email": "amministrativo@comune.osidda.nu.it",
      "pec": "protocollo@pec.comune.osidda.nu.it",
      "telefono": "+39 079 712623",
      "fax": "+39 079 712635",
      "coordinate": {
        "lat": 40.52423095703125,
        "lng": 9.218109130859375
      }
    },
    {
      "codice": "091069",
      "nome": "Osini",
      "nomeStraniero": null,
      "codiceCatastale": "G158",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "comune.osini@tiscali.it",
      "pec": "protocollo@pec.comune.osini.nu.it",
      "telefono": "+39 0782 79031",
      "fax": "+39 0782 787004",
      "coordinate": {
        "lat": 39.822444915771484,
        "lng": 9.49669361114502
      }
    },
    {
      "codice": "091070",
      "nome": "Ottana",
      "nomeStraniero": null,
      "codiceCatastale": "G191",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": null,
      "pec": "protocollo@pec.comune.ottana.nu.it",
      "telefono": "+39 0784 75623",
      "fax": null,
      "coordinate": {
        "lat": 40.23416519165039,
        "lng": 9.042499542236328
      }
    },
    {
      "codice": "091071",
      "nome": "Ovodda",
      "nomeStraniero": null,
      "codiceCatastale": "G201",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comovodda@tiscali.it",
      "pec": "protocollo.ovodda@pec.comunas.it",
      "telefono": "+39 0784/54023",
      "fax": "+39 0784/54475",
      "coordinate": {
        "lat": 40.095340728759766,
        "lng": 9.160494804382324
      }
    },
    {
      "codice": "091072",
      "nome": "Perdasdefogu",
      "nomeStraniero": null,
      "codiceCatastale": "G445",
      "cap": "08046",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "protocollo@comunediperdasdefogu.com",
      "pec": "protocollo@pec.comunediperdasdefogu.com",
      "telefono": "+39 0782 94614",
      "fax": "+39 0782 94190",
      "coordinate": {
        "lat": 39.67997741699219,
        "lng": 9.440174102783203
      }
    },
    {
      "codice": "091073",
      "nome": "Posada",
      "nomeStraniero": null,
      "codiceCatastale": "G929",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "aagg@comune.posada.nu.it",
      "pec": "protocollo@pec.comune.posada.nu.it",
      "telefono": "+39 0784/870518",
      "fax": "+39 0784/854084",
      "coordinate": {
        "lat": 40.631282806396484,
        "lng": 9.719810485839844
      }
    },
    {
      "codice": "091077",
      "nome": "Sarule",
      "nomeStraniero": null,
      "codiceCatastale": "I448",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "demografici@comunesarule.nu.it",
      "pec": "protocollo.sarule@pec.comunas.it",
      "telefono": "+39 0784 76017",
      "fax": null,
      "coordinate": {
        "lat": 40.22916793823242,
        "lng": 9.164722442626953
      }
    },
    {
      "codice": "091083",
      "nome": "Silanus",
      "nomeStraniero": null,
      "codiceCatastale": "I730",
      "cap": "08017",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "comunesilanus@tiscali.it",
      "pec": "protocollo.silanus@pec.comunas.it",
      "telefono": "+39 0785/840001",
      "fax": "+39 0785/84273",
      "coordinate": {
        "lat": 40.287776947021484,
        "lng": 8.890277862548828
      }
    },
    {
      "codice": "091084",
      "nome": "Sindia",
      "nomeStraniero": null,
      "codiceCatastale": "I748",
      "cap": "08018",
      "prefisso": "0785",
      "provincia": "Nuoro",
      "email": "anagrafe@comune.sindia.nu.it",
      "pec": "protocollo@pec.comune.sindia.nu.it",
      "telefono": "+39 0785/479228",
      "fax": "+39 0785/41298",
      "coordinate": {
        "lat": 40.29484558105469,
        "lng": 8.657398223876953
      }
    },
    {
      "codice": "091085",
      "nome": "Siniscola",
      "nomeStraniero": null,
      "codiceCatastale": "I751",
      "cap": "08029",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "affarigenerali@comune.siniscola.nu.it",
      "pec": "protocollo@pec.comune.siniscola.nu.it",
      "telefono": "+39 0784/870811",
      "fax": "+39 0784/878300",
      "coordinate": {
        "lat": 40.57432556152344,
        "lng": 9.696292877197266
      }
    },
    {
      "codice": "091086",
      "nome": "Sorgono",
      "nomeStraniero": null,
      "codiceCatastale": "I851",
      "cap": "08038",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comunesorgono@comune.sorgono.nu.it",
      "pec": "comune.sorgono@pec.it",
      "telefono": "+39 0784 622520",
      "fax": "+39 0784 60701",
      "coordinate": {
        "lat": 40.02649688720703,
        "lng": 9.102730751037598
      }
    },
    {
      "codice": "091088",
      "nome": "Talana",
      "nomeStraniero": null,
      "codiceCatastale": "L036",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "anagrafe@comune.talana.og.it",
      "pec": "protocollo@pec.comune.talana.og.it",
      "telefono": "+39 0782 646603",
      "fax": "+39 0782 646682",
      "coordinate": {
        "lat": 40.04246520996094,
        "lng": 9.496822357177734
      }
    },
    {
      "codice": "091089",
      "nome": "Tertenia",
      "nomeStraniero": null,
      "codiceCatastale": "L140",
      "cap": "08047",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "protocollo@comunetertenia.it",
      "pec": "protocollo@pec.comunetertenia.it",
      "telefono": "+39 0782 908017",
      "fax": "+39 0782 93009",
      "coordinate": {
        "lat": 39.69635772705078,
        "lng": 9.578876495361328
      }
    },
    {
      "codice": "091090",
      "nome": "Teti",
      "nomeStraniero": null,
      "codiceCatastale": "L153",
      "cap": "08030",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comune.teti@tiscali.it",
      "pec": "protocollo@pec.comune.teti.nu.it",
      "telefono": "+39 0784/68023",
      "fax": "+39 0784/68229",
      "coordinate": {
        "lat": 40.09514617919922,
        "lng": 9.118995666503906
      }
    },
    {
      "codice": "091091",
      "nome": "Tiana",
      "nomeStraniero": null,
      "codiceCatastale": "L160",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "info@comune.tiana.nu.it",
      "pec": "protocollo@pec.comune.tiana.nu.it",
      "telefono": "+39 0784 69089",
      "fax": "+39 0784 69200",
      "coordinate": {
        "lat": 40.06805419921875,
        "lng": 9.147191047668457
      }
    },
    {
      "codice": "091093",
      "nome": "Tonara",
      "nomeStraniero": null,
      "codiceCatastale": "L202",
      "cap": "08039",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "protocollo@comunetonara.it",
      "pec": "protocollo@pec.comunetonara.it",
      "telefono": "+39 07848/63823",
      "fax": "+39 0784/63246",
      "coordinate": {
        "lat": 40.0236701965332,
        "lng": 9.171524047851562
      }
    },
    {
      "codice": "091094",
      "nome": "Torpè",
      "nomeStraniero": null,
      "codiceCatastale": "L231",
      "cap": "08020",
      "prefisso": "0784",
      "provincia": "Nuoro",
      "email": "comune.torpe@tiscali.it",
      "pec": "demografici@pec.comune.torpe.nu.it",
      "telefono": "+39 0784/829296",
      "fax": "+39 0784/829008",
      "coordinate": {
        "lat": 40.62773132324219,
        "lng": 9.67822551727295
      }
    },
    {
      "codice": "091095",
      "nome": "Tortolì",
      "nomeStraniero": null,
      "codiceCatastale": "A355",
      "cap": "08048",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "segreteria@comuneditortoli.it",
      "pec": "protocollo@pec.comuneditortoli.it",
      "telefono": "+39 0782 600700",
      "fax": null,
      "coordinate": {
        "lat": 39.93333435058594,
        "lng": 9.649999618530273
      }
    },
    {
      "codice": "091097",
      "nome": "Triei",
      "nomeStraniero": null,
      "codiceCatastale": "L423",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "info@comunetriei.gov.it",
      "pec": "protocollo@pec.comune.triei.ogliastra.it",
      "telefono": "+39 0782 618023",
      "fax": "+39 0782 618131",
      "coordinate": {
        "lat": 40.03602600097656,
        "lng": 9.640674591064453
      }
    },
    {
      "codice": "091098",
      "nome": "Ulassai",
      "nomeStraniero": null,
      "codiceCatastale": "L489",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "info@comune.ulassai.og.it",
      "pec": "protocollo@pec.comunediulassai.it",
      "telefono": "+39 0782 79123",
      "fax": "+39 0782 787142",
      "coordinate": {
        "lat": 39.81169891357422,
        "lng": 9.498968124389648
      }
    },
    {
      "codice": "091099",
      "nome": "Urzulei",
      "nomeStraniero": null,
      "codiceCatastale": "L506",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "protocollo@comuneurzulei.it",
      "pec": "protocollo.urzulei@pec.comunas.it",
      "telefono": "+39 0782 649023",
      "fax": null,
      "coordinate": {
        "lat": 40.09294509887695,
        "lng": 9.507594108581543
      }
    },
    {
      "codice": "091100",
      "nome": "Ussassai",
      "nomeStraniero": null,
      "codiceCatastale": "L514",
      "cap": "08040",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": null,
      "pec": "protocollo@pec.comune.ussassai.og.it",
      "telefono": "+39 0782 55710",
      "fax": "+39 0781 55890",
      "coordinate": {
        "lat": 39.81011962890625,
        "lng": 9.394855499267578
      }
    },
    {
      "codice": "091101",
      "nome": "Villagrande Strisaili",
      "nomeStraniero": null,
      "codiceCatastale": "L953",
      "cap": "08049",
      "prefisso": "0782",
      "provincia": "Nuoro",
      "email": "smrubiu@comune.villagrandestrisaili.og.it",
      "pec": "protocollo@pec.comune.villagrandestrisaili.og.it",
      "telefono": "+39 0782 32014",
      "fax": "+39 0782 32571",
      "coordinate": {
        "lat": 39.95853805541992,
        "lng": 9.507980346679688
      }
    }
  ],
  "Cagliari": [
    {
      "codice": "092003",
      "nome": "Assemini",
      "nomeStraniero": null,
      "codiceCatastale": "A474",
      "cap": "09032",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "amministrativa@comune.assemini.ca.it",
      "pec": "amministrativa@pec.comune.assemini.ca.it",
      "telefono": "+39 0709491",
      "fax": "+39 070 / 940978",
      "coordinate": {
        "lat": 39.2896728515625,
        "lng": 9.004840850830078
      }
    },
    {
      "codice": "092009",
      "nome": "Cagliari",
      "nomeStraniero": null,
      "codiceCatastale": "B354",
      "cap": "09134",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "urp@comune.cagliari.it",
      "pec": "protocollogenerale@comune.cagliari.legalmail.it",
      "telefono": "+39 070 6771",
      "fax": "+39 070 6777263",
      "coordinate": {
        "lat": 39.21666717529297,
        "lng": 9.116666793823242
      }
    },
    {
      "codice": "092011",
      "nome": "Capoterra",
      "nomeStraniero": null,
      "codiceCatastale": "B675",
      "cap": "09012",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "comunecapoterra@gmail.com",
      "pec": "comune.capoterra@legalmail.it",
      "telefono": "+39 070 72391",
      "fax": "+39 070 7239206",
      "coordinate": {
        "lat": 39.17518615722656,
        "lng": 8.97085189819336
      }
    },
    {
      "codice": "092015",
      "nome": "Decimomannu",
      "nomeStraniero": null,
      "codiceCatastale": "D259",
      "cap": "09033",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "servizi.demografici@comune.decimomannu.ca.it",
      "pec": "protocollo@pec.comune.decimomannu.ca.it",
      "telefono": "+39 070 966701",
      "fax": "+39 070 962078",
      "coordinate": {
        "lat": 39.31119155883789,
        "lng": 8.9703369140625
      }
    },
    {
      "codice": "092108",
      "nome": "Elmas",
      "nomeStraniero": null,
      "codiceCatastale": "D399",
      "cap": "09067",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "segretario@comune.elmas.ca.it",
      "pec": "protocollo@pec.comune.elmas.ca.it",
      "telefono": "+39 070 2192200",
      "fax": "+39 070 243105",
      "coordinate": {
        "lat": 39.267879486083984,
        "lng": 9.04998779296875
      }
    },
    {
      "codice": "092037",
      "nome": "Maracalagonis",
      "nomeStraniero": null,
      "codiceCatastale": "E903",
      "cap": "09040",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "info@comune.maracalagonis.ca.it",
      "pec": "protocollo@pec.comune.maracalagonis.ca.it",
      "telefono": "+39 070 78501",
      "fax": "+39 070 785143",
      "coordinate": {
        "lat": 39.286216735839844,
        "lng": 9.228858947753906
      }
    },
    {
      "codice": "092109",
      "nome": "Monserrato",
      "nomeStraniero": null,
      "codiceCatastale": "F383",
      "cap": "09042",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "servizidemografici@comune.monserrato.ca.it",
      "pec": "protocollo@pec.comune.monserrato.ca.it",
      "telefono": "+39 070 57921",
      "fax": "+39 070 5792262",
      "coordinate": {
        "lat": 39.2568473815918,
        "lng": 9.138736724853516
      }
    },
    {
      "codice": "092050",
      "nome": "Pula",
      "nomeStraniero": null,
      "codiceCatastale": "H088",
      "cap": "09050",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "anagrafepula@gmail.com",
      "pec": "demografici.comune.pula@pec.it",
      "telefono": "+39 070 92440213",
      "fax": "+39 070 9245647",
      "coordinate": {
        "lat": 39.00944519042969,
        "lng": 9.00054931640625
      }
    },
    {
      "codice": "092051",
      "nome": "Quartu Sant'Elena",
      "nomeStraniero": null,
      "codiceCatastale": "H118",
      "cap": "09045",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "urp@comune.quartusantelena.ca.it",
      "pec": "protocollo@pec.comune.quartusantelena.ca.it",
      "telefono": "+39 070 86012605",
      "fax": "+39 070 86012325",
      "coordinate": {
        "lat": 39.229061126708984,
        "lng": 9.264907836914062
      }
    },
    {
      "codice": "092105",
      "nome": "Quartucciu",
      "nomeStraniero": null,
      "codiceCatastale": "H119",
      "cap": "09044",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "affarigenerali@comune.quartucciu.ca.it",
      "pec": "protocollo.quartucciu@legalmail.it",
      "telefono": "+39 07085954302",
      "fax": "+39 07085954247",
      "coordinate": {
        "lat": 39.25286102294922,
        "lng": 9.176158905029297
      }
    },
    {
      "codice": "092066",
      "nome": "Sarroch",
      "nomeStraniero": null,
      "codiceCatastale": "I443",
      "cap": "09018",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "segretario@comune.sarroch.ca.it",
      "pec": "protocollosarroch@pec.it",
      "telefono": "+39 070.90926200",
      "fax": "+39 070 90926204",
      "coordinate": {
        "lat": 39.06584930419922,
        "lng": 9.010162353515625
      }
    },
    {
      "codice": "092068",
      "nome": "Selargius",
      "nomeStraniero": null,
      "codiceCatastale": "I580",
      "cap": "09047",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "marcialis@comune.selargius.ca.it",
      "pec": "protocollo@pec.comune.selargius.ca.it",
      "telefono": "+39 0708592239",
      "fax": "+39 0708592249",
      "coordinate": {
        "lat": 39.253658294677734,
        "lng": 9.160623550415039
      }
    },
    {
      "codice": "092074",
      "nome": "Sestu",
      "nomeStraniero": null,
      "codiceCatastale": "I695",
      "cap": "09028",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "affari.generali@comune.sestu.ca.it",
      "pec": "protocollo.sestu@pec.it",
      "telefono": "+39 070-23601",
      "fax": "+39 070-2360211",
      "coordinate": {
        "lat": 39.299434661865234,
        "lng": 9.091830253601074
      }
    },
    {
      "codice": "092075",
      "nome": "Settimo San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "I699",
      "cap": "09060",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "demografici@comune.settimosanpietro.ca.it",
      "pec": "protocollo@pec.comune.settimosanpietro.ca.it",
      "telefono": "+39 070 7691222",
      "fax": "+39 070 765687",
      "coordinate": {
        "lat": 39.29100036621094,
        "lng": 9.185256958007812
      }
    },
    {
      "codice": "092080",
      "nome": "Sinnai",
      "nomeStraniero": null,
      "codiceCatastale": "I752",
      "cap": "09048",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "servizioinformatico@comune.sinnai.ca.it",
      "pec": "protocollo@pec.comune.sinnai.ca.it",
      "telefono": "+39 0707690303",
      "fax": "+39 070781412",
      "coordinate": {
        "lat": 39.30255889892578,
        "lng": 9.203109741210938
      }
    },
    {
      "codice": "092090",
      "nome": "Uta",
      "nomeStraniero": null,
      "codiceCatastale": "L521",
      "cap": "09010",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "protocollo@comune.uta.ca.it",
      "pec": "comune.uta@legalmail.it",
      "telefono": "+39 070 96660201",
      "fax": "+39 070 96660217",
      "coordinate": {
        "lat": 39.2883415222168,
        "lng": 8.957633972167969
      }
    },
    {
      "codice": "092099",
      "nome": "Villa San Pietro",
      "nomeStraniero": null,
      "codiceCatastale": "I118",
      "cap": "09050",
      "prefisso": "070",
      "provincia": "Cagliari",
      "email": "segreteria@comune.villasanpietro.ca.it",
      "pec": "segreteria@comunevillasanpietro.postecert.it",
      "telefono": "+39 070907701",
      "fax": "+39 070907419",
      "coordinate": {
        "lat": 39.03554153442383,
        "lng": 8.996950149536133
      }
    }
  ],
  "Perugia": [
    {
      "codice": "054001",
      "nome": "Assisi",
      "nomeStraniero": null,
      "codiceCatastale": "A475",
      "cap": "06081",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "alessandro.bagaglia@comune.assisi.pg.it",
      "pec": "comune.assisi@postacert.umbria.it",
      "telefono": "+39 075/8138658",
      "fax": "+39 075/8138652",
      "coordinate": {
        "lat": 43.06999969482422,
        "lng": 12.617500305175781
      }
    },
    {
      "codice": "054002",
      "nome": "Bastia Umbra",
      "nomeStraniero": null,
      "codiceCatastale": "A710",
      "cap": "06083",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "paola.sorci@comune.bastia.pg.it",
      "pec": "comune.bastiaumbra@postacert.umbria.it",
      "telefono": "+39 075/8018256",
      "fax": "+39 075/8018282",
      "coordinate": {
        "lat": 43.06666564941406,
        "lng": 12.550000190734863
      }
    },
    {
      "codice": "054003",
      "nome": "Bettona",
      "nomeStraniero": null,
      "codiceCatastale": "A832",
      "cap": "06084",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "comune.bettona@gmail.com",
      "pec": "segreteria@pec.comune.bettona.pg.it",
      "telefono": "+39 075/988571",
      "fax": "+39 975/9869115",
      "coordinate": {
        "lat": 43.016666412353516,
        "lng": 12.483333587646484
      }
    },
    {
      "codice": "054004",
      "nome": "Bevagna",
      "nomeStraniero": null,
      "codiceCatastale": "A835",
      "cap": "06031",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "info@comune.bevagna.pg.it",
      "pec": "comune.bevagna@postacert.umbria.it",
      "telefono": "+39 0742/368130",
      "fax": "+39 0742/361647",
      "coordinate": {
        "lat": 42.93333435058594,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "054005",
      "nome": "Campello sul Clitunno",
      "nomeStraniero": null,
      "codiceCatastale": "B504",
      "cap": "06042",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "area.finanziaria@comune.campello.pg.it",
      "pec": "comune.campellosulclitunno@postacert.umbria.it",
      "telefono": "+39 0743271926",
      "fax": "+39 0743271952",
      "coordinate": {
        "lat": 42.81666564941406,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "054006",
      "nome": "Cannara",
      "nomeStraniero": null,
      "codiceCatastale": "B609",
      "cap": "06033",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "segreteria@comune.cannara.pg.it",
      "pec": "comune.cannara@postacert.umbria.it",
      "telefono": "+39 0742/731811",
      "fax": "+39 0742/731824",
      "coordinate": {
        "lat": 43,
        "lng": 12.583333015441895
      }
    },
    {
      "codice": "054007",
      "nome": "Cascia",
      "nomeStraniero": null,
      "codiceCatastale": "B948",
      "cap": "06043",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "info@comune.cascia.it",
      "pec": "comune.cascia@postacert.umbria.it",
      "telefono": "+39 0743/751338",
      "fax": "+39 0743/751344",
      "coordinate": {
        "lat": 42.71666717529297,
        "lng": 13.016666412353516
      }
    },
    {
      "codice": "054008",
      "nome": "Castel Ritaldi",
      "nomeStraniero": null,
      "codiceCatastale": "C252",
      "cap": "06044",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "segreteria@comune.castel-ritaldi.pg.it",
      "pec": "comune.castelritaldi@postacert.umbria.it",
      "telefono": "+39 0743/252846",
      "fax": "+39 0743/252005",
      "coordinate": {
        "lat": 42.823333740234375,
        "lng": 12.672222137451172
      }
    },
    {
      "codice": "054009",
      "nome": "Castiglione del Lago",
      "nomeStraniero": null,
      "codiceCatastale": "C309",
      "cap": "06061",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "demografici@comune.castiglione-del-lago.pg.it",
      "pec": "comune.castiglionedellago@postacert.umbria.it",
      "telefono": "+39 075/96581",
      "fax": "+39 075/9658200",
      "coordinate": {
        "lat": 43.127140045166016,
        "lng": 12.045249938964844
      }
    },
    {
      "codice": "054010",
      "nome": "Cerreto di Spoleto",
      "nomeStraniero": null,
      "codiceCatastale": "C527",
      "cap": "06040",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "info@comune.cerretodispoleto.pg.it",
      "pec": "comune.cerretodispoleto@postacert.umbria.it",
      "telefono": "+39 0743/91231",
      "fax": "+39 0743/91412",
      "coordinate": {
        "lat": 42.81666564941406,
        "lng": 12.916666984558105
      }
    },
    {
      "codice": "054011",
      "nome": "Citerna",
      "nomeStraniero": null,
      "codiceCatastale": "C742",
      "cap": "06010",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "comune@citerna.net",
      "pec": "comune.citerna@postacert.umbria.it",
      "telefono": "+39 075/8592119",
      "fax": "+39 075/8593313",
      "coordinate": {
        "lat": 43.5,
        "lng": 12.116666793823242
      }
    },
    {
      "codice": "054012",
      "nome": "Città della Pieve",
      "nomeStraniero": null,
      "codiceCatastale": "C744",
      "cap": "06062",
      "prefisso": "0578",
      "provincia": "Perugia",
      "email": "sindaco@cittadellapieve.org",
      "pec": "comune.cittadellapieve@postacert.umbria.it",
      "telefono": "+39 0578/291213",
      "fax": "+39 0578/291291",
      "coordinate": {
        "lat": 42.953887939453125,
        "lng": 12.00777816772461
      }
    },
    {
      "codice": "054013",
      "nome": "Città di Castello",
      "nomeStraniero": null,
      "codiceCatastale": "C745",
      "cap": "06018",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "l.baldacci@cdcnet.net",
      "pec": "comune.cittadicastello@postacert.umbria.it",
      "telefono": "+39 075/8529282",
      "fax": "+39 075/8529216",
      "coordinate": {
        "lat": 43.457420349121094,
        "lng": 12.240311622619629
      }
    },
    {
      "codice": "054014",
      "nome": "Collazzone",
      "nomeStraniero": null,
      "codiceCatastale": "C845",
      "cap": "06050",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "ufficioragioneria@comune.collazzone.pg.it",
      "pec": "comune.collazzone@postacert.umbria.it",
      "telefono": "+39 075/8781702",
      "fax": "+39 075/8781732",
      "coordinate": {
        "lat": 42.900001525878906,
        "lng": 12.433333396911621
      }
    },
    {
      "codice": "054015",
      "nome": "Corciano",
      "nomeStraniero": null,
      "codiceCatastale": "C990",
      "cap": "06073",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "a.pignatta@comune.corciano.pg.it",
      "pec": "comune.corciano@postacert.umbria.it",
      "telefono": "+39 075/5188312",
      "fax": "+39 075/5188237",
      "coordinate": {
        "lat": 43.1290283203125,
        "lng": 12.287717819213867
      }
    },
    {
      "codice": "054016",
      "nome": "Costacciaro",
      "nomeStraniero": null,
      "codiceCatastale": "D108",
      "cap": "06021",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info.segreteria@comunecostacciaro.it",
      "pec": "comune.costacciaro@postacert.umbria.it",
      "telefono": "+39 075/917271",
      "fax": "+39 075/9170647",
      "coordinate": {
        "lat": 43.360408782958984,
        "lng": 12.71237850189209
      }
    },
    {
      "codice": "054017",
      "nome": "Deruta",
      "nomeStraniero": null,
      "codiceCatastale": "D279",
      "cap": "06053",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@comune.deruta.pg.it",
      "pec": "comune.deruta@postacert.umbria.it",
      "telefono": "+39 075 9728640",
      "fax": "+39 075 9728639",
      "coordinate": {
        "lat": 42.983333587646484,
        "lng": 12.416666984558105
      }
    },
    {
      "codice": "054018",
      "nome": "Foligno",
      "nomeStraniero": null,
      "codiceCatastale": "D653",
      "cap": "06034",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "protocollo@comune.foligno.pg.it",
      "pec": "comune.foligno@postacert.umbria.it",
      "telefono": "+39 0742 3301",
      "fax": "+39 0742/330345",
      "coordinate": {
        "lat": 42.956111907958984,
        "lng": 12.703332901000977
      }
    },
    {
      "codice": "054019",
      "nome": "Fossato di Vico",
      "nomeStraniero": null,
      "codiceCatastale": "D745",
      "cap": "06022",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "comfossato@tiscali.it",
      "pec": "comune.fossatodivico@postacert.umbria.it",
      "telefono": "+39 075/9149520",
      "fax": "+39 075/9149588",
      "coordinate": {
        "lat": 43.29999923706055,
        "lng": 12.766666412353516
      }
    },
    {
      "codice": "054020",
      "nome": "Fratta Todina",
      "nomeStraniero": null,
      "codiceCatastale": "D787",
      "cap": "06054",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@comune.frattatodina.pg.it",
      "pec": "comune.frattatodina@postacert.umbria.it",
      "telefono": "+39 075/8745304",
      "fax": "+39 075/8745356",
      "coordinate": {
        "lat": 42.849998474121094,
        "lng": 12.366666793823242
      }
    },
    {
      "codice": "054021",
      "nome": "Giano dell'Umbria",
      "nomeStraniero": null,
      "codiceCatastale": "E012",
      "cap": "06030",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "comune@giano.umbria.it",
      "pec": "comune.gianodellumbria@postacert.umbria.it",
      "telefono": "+39 0742/93191",
      "fax": "+39 0742/90137",
      "coordinate": {
        "lat": 42.83333206176758,
        "lng": 12.583333015441895
      }
    },
    {
      "codice": "054022",
      "nome": "Gualdo Cattaneo",
      "nomeStraniero": null,
      "codiceCatastale": "E229",
      "cap": "06035",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "info@comune.gualdocattaneo.pg.it",
      "pec": "comune.gualdocattaneo@postacert.umbria.it",
      "telefono": "+39 0742/92941",
      "fax": "+39 0742/91886",
      "coordinate": {
        "lat": 42.91666793823242,
        "lng": 12.550000190734863
      }
    },
    {
      "codice": "054023",
      "nome": "Gualdo Tadino",
      "nomeStraniero": null,
      "codiceCatastale": "E230",
      "cap": "06023",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "sindaco@tadino.it",
      "pec": "gualdotadino@letterecertificate.it",
      "telefono": "+39 075/9150223",
      "fax": "+39 075/916461",
      "coordinate": {
        "lat": 43.233333587646484,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "054024",
      "nome": "Gubbio",
      "nomeStraniero": null,
      "codiceCatastale": "E256",
      "cap": "06020",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@comune.gubbio.pg.it",
      "pec": "comune.gubbio@postacert.umbria.it",
      "telefono": "+39 075/92371",
      "fax": "+39 075/9275378",
      "coordinate": {
        "lat": 43.35178756713867,
        "lng": 12.57726764678955
      }
    },
    {
      "codice": "054025",
      "nome": "Lisciano Niccone",
      "nomeStraniero": null,
      "codiceCatastale": "E613",
      "cap": "06060",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "anagrafe@lisciano.org",
      "pec": "comune.liscianoniccone@postacert.umbria.it",
      "telefono": "+39 075/844132",
      "fax": "+39 075/844139",
      "coordinate": {
        "lat": 43.25,
        "lng": 12.149999618530273
      }
    },
    {
      "codice": "054026",
      "nome": "Magione",
      "nomeStraniero": null,
      "codiceCatastale": "E805",
      "cap": "06063",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "area.finanziaria@comune.magione.pg.it",
      "pec": "comune.magione@postacert.umbria.it",
      "telefono": "+39 075/8477023",
      "fax": "+39 075/8477041",
      "coordinate": {
        "lat": 43.150001525878906,
        "lng": 12.199999809265137
      }
    },
    {
      "codice": "054027",
      "nome": "Marsciano",
      "nomeStraniero": null,
      "codiceCatastale": "E975",
      "cap": "06055",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "l.padiglioni@comune.marsciano.pg.it",
      "pec": "comune.marsciano@postacert.umbria.it",
      "telefono": "+39 075/8747228",
      "fax": "+39 075/8747288",
      "coordinate": {
        "lat": 42.91666793823242,
        "lng": 12.333333015441895
      }
    },
    {
      "codice": "054028",
      "nome": "Massa Martana",
      "nomeStraniero": null,
      "codiceCatastale": "F024",
      "cap": "06056",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "anagrafe@comune.massamartana.pg.it",
      "pec": "comune.massamartana@postacert.umbria.it",
      "telefono": "+39 075/8951723",
      "fax": "+39 075/889109",
      "coordinate": {
        "lat": 42.78333282470703,
        "lng": 12.516666412353516
      }
    },
    {
      "codice": "054029",
      "nome": "Monte Castello di Vibio",
      "nomeStraniero": null,
      "codiceCatastale": "F456",
      "cap": "06057",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "ragioneria@comune.montecastellodivibio.pg.it",
      "pec": "comune.montecastellodivibio@postacert.umbria.it",
      "telefono": "+39 075/8780217",
      "fax": "+39 075/8796135",
      "coordinate": {
        "lat": 42.83333206176758,
        "lng": 12.350000381469727
      }
    },
    {
      "codice": "054032",
      "nome": "Monte Santa Maria Tiberina",
      "nomeStraniero": null,
      "codiceCatastale": "F629",
      "cap": "06010",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@montesantamariatiberina.org",
      "pec": "comune.montesantamariatiberina@postacert.umbria.it",
      "telefono": "+39 0758571003",
      "fax": "+39 0758571070",
      "coordinate": {
        "lat": 43.43722152709961,
        "lng": 12.162500381469727
      }
    },
    {
      "codice": "054030",
      "nome": "Montefalco",
      "nomeStraniero": null,
      "codiceCatastale": "F492",
      "cap": "06036",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "segreteria@comunemontefalco.it",
      "pec": "comune.montefalco@postacert.umbria.it",
      "telefono": "+39 0742/616127",
      "fax": "+39 0742/379852",
      "coordinate": {
        "lat": 42.890838623046875,
        "lng": 12.648269653320312
      }
    },
    {
      "codice": "054031",
      "nome": "Monteleone di Spoleto",
      "nomeStraniero": null,
      "codiceCatastale": "F540",
      "cap": "06045",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "monteleonedispoleto@poste.it",
      "pec": "comune.monteleonedispoleto@postacert.umbria.it",
      "telefono": "+39 0743/70421",
      "fax": "+39 0743/70422",
      "coordinate": {
        "lat": 42.65135955810547,
        "lng": 12.951669692993164
      }
    },
    {
      "codice": "054033",
      "nome": "Montone",
      "nomeStraniero": null,
      "codiceCatastale": "F685",
      "cap": "06014",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "anagrafe@montone.org",
      "pec": "comune.montone@postacert.umbria.it",
      "telefono": "+39 075/9306427",
      "fax": "+39 075/9307121",
      "coordinate": {
        "lat": 43.362220764160156,
        "lng": 12.327221870422363
      }
    },
    {
      "codice": "054034",
      "nome": "Nocera Umbra",
      "nomeStraniero": null,
      "codiceCatastale": "F911",
      "cap": "06025",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "info@comune.noceraumbra.pg.it",
      "pec": "comune.noceraumbra@postacert.umbria.it",
      "telefono": "+39 0742/834011",
      "fax": "+39 0742/812133",
      "coordinate": {
        "lat": 43.11666488647461,
        "lng": 12.783333778381348
      }
    },
    {
      "codice": "054035",
      "nome": "Norcia",
      "nomeStraniero": null,
      "codiceCatastale": "F935",
      "cap": "06046",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "info@comune.norcia.pg.it",
      "pec": "comune.norcia@postacert.umbria.it",
      "telefono": "0743828711",
      "fax": "+39 0743824021",
      "coordinate": {
        "lat": 42.7933349609375,
        "lng": 13.093889236450195
      }
    },
    {
      "codice": "054036",
      "nome": "Paciano",
      "nomeStraniero": null,
      "codiceCatastale": "G212",
      "cap": "06060",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@comune.paciano.pg.it",
      "pec": "comune.paciano@postacert.umbria.it",
      "telefono": "+39 075/830186",
      "fax": null,
      "coordinate": {
        "lat": 43.016666412353516,
        "lng": 12.066666603088379
      }
    },
    {
      "codice": "054037",
      "nome": "Panicale",
      "nomeStraniero": null,
      "codiceCatastale": "G308",
      "cap": "06064",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "informatica@comune.panicale.pg.it",
      "pec": "comune.panicale@postacert.umbria.it",
      "telefono": "+39 075/837951",
      "fax": "+39 075/837637",
      "coordinate": {
        "lat": 43.03333282470703,
        "lng": 12.100000381469727
      }
    },
    {
      "codice": "054038",
      "nome": "Passignano sul Trasimeno",
      "nomeStraniero": null,
      "codiceCatastale": "G359",
      "cap": "06065",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "municipio@comune.passignano-sul-trasimeno.pg.it",
      "pec": "comune.passignanosultrasimeno@postacert.umbria.it",
      "telefono": "+39 075/829801",
      "fax": "+39 075/829101",
      "coordinate": {
        "lat": 43.18333435058594,
        "lng": 12.133333206176758
      }
    },
    {
      "codice": "054039",
      "nome": "Perugia",
      "nomeStraniero": null,
      "codiceCatastale": "G478",
      "cap": "06121",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@comune.perugia.it",
      "pec": "comune.perugia@postacert.umbria.it",
      "telefono": "+39 075 5771",
      "fax": "+39 075/5838493",
      "coordinate": {
        "lat": 43.112098693847656,
        "lng": 12.388799667358398
      }
    },
    {
      "codice": "054040",
      "nome": "Piegaro",
      "nomeStraniero": null,
      "codiceCatastale": "G601",
      "cap": "06066",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "protocollo@comune.piegaro.pg.it",
      "pec": "comune.piegaro@postacert.umbria.it",
      "telefono": "+39 075/8358932",
      "fax": "+39 075/8358290",
      "coordinate": {
        "lat": 42.96666717529297,
        "lng": 12.083333015441895
      }
    },
    {
      "codice": "054041",
      "nome": "Pietralunga",
      "nomeStraniero": null,
      "codiceCatastale": "G618",
      "cap": "06026",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "comune@pietralunga.it",
      "pec": "comune.pietralunga@postacert.umbria.it",
      "telefono": "+39 075/9460296",
      "fax": "+39 075/9460722",
      "coordinate": {
        "lat": 43.45000076293945,
        "lng": 12.433333396911621
      }
    },
    {
      "codice": "054042",
      "nome": "Poggiodomo",
      "nomeStraniero": null,
      "codiceCatastale": "G758",
      "cap": "06040",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "comunepoggiodomo@libero.it",
      "pec": "comune.poggiodomo@postacert.umbria.it",
      "telefono": "+39 0743.759001",
      "fax": "+39 0743.759200",
      "coordinate": {
        "lat": 42.71666717529297,
        "lng": 12.933333396911621
      }
    },
    {
      "codice": "054043",
      "nome": "Preci",
      "nomeStraniero": null,
      "codiceCatastale": "H015",
      "cap": "06047",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "sindaco@comune.preci.pg.it",
      "pec": "comune.preci@postacert.umbria.it",
      "telefono": "+39 0743/93781",
      "fax": "+39 0743/937827",
      "coordinate": {
        "lat": 42.88333511352539,
        "lng": 13.033333778381348
      }
    },
    {
      "codice": "054044",
      "nome": "San Giustino",
      "nomeStraniero": null,
      "codiceCatastale": "H935",
      "cap": "06016",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "sindaco@comune.sangiustino.pg.it",
      "pec": "comune.sangiustino@postacert.umbria.it",
      "telefono": "+39 075/86184411",
      "fax": "+39 075/86184400",
      "coordinate": {
        "lat": 43.54999923706055,
        "lng": 12.183333396911621
      }
    },
    {
      "codice": "054045",
      "nome": "Sant'Anatolia di Narco",
      "nomeStraniero": null,
      "codiceCatastale": "I263",
      "cap": "06040",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "anatolia.narco@tin.it",
      "pec": "comune.santanatoliadinarco@postacert.umbria.it",
      "telefono": "+39 0743/613149",
      "fax": "+39 0743/613148",
      "coordinate": {
        "lat": 42.733333587646484,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "054046",
      "nome": "Scheggia e Pascelupo",
      "nomeStraniero": null,
      "codiceCatastale": "I522",
      "cap": "06027",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "info@comunescheggiaepascelupo.it",
      "pec": "comune.scheggiaepascelupo@postacert.umbria.it",
      "telefono": "+39 075/9259722",
      "fax": "+39 075/9259724",
      "coordinate": {
        "lat": 43.40388870239258,
        "lng": 12.66611099243164
      }
    },
    {
      "codice": "054047",
      "nome": "Scheggino",
      "nomeStraniero": null,
      "codiceCatastale": "I523",
      "cap": "06040",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "ufficioamministrativo@comune.scheggino.pg.it",
      "pec": "comune.scheggino@postacert.umbria.it",
      "telefono": "+39 0743/613232",
      "fax": "+39 0743/619084",
      "coordinate": {
        "lat": 42.71666717529297,
        "lng": 12.833333015441895
      }
    },
    {
      "codice": "054048",
      "nome": "Sellano",
      "nomeStraniero": null,
      "codiceCatastale": "I585",
      "cap": "06030",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "info@comune.sellano.pg.it",
      "pec": "comune.sellano@postacert.umbria.it",
      "telefono": "+39 0743/926600",
      "fax": "+39 0743/96218",
      "coordinate": {
        "lat": 42.88734817504883,
        "lng": 12.923110008239746
      }
    },
    {
      "codice": "054049",
      "nome": "Sigillo",
      "nomeStraniero": null,
      "codiceCatastale": "I727",
      "cap": "06028",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "cesare.carletti@comune.sigillo.pg.it",
      "pec": "comune.sigillo@postacert.umbria.it",
      "telefono": "+39 075/9178711",
      "fax": "+39 075/9178750",
      "coordinate": {
        "lat": 43.331111907958984,
        "lng": 12.74222183227539
      }
    },
    {
      "codice": "054050",
      "nome": "Spello",
      "nomeStraniero": null,
      "codiceCatastale": "I888",
      "cap": "06038",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "cpetrucci@comune.spello.pg.it",
      "pec": "comune.spello@postacert.umbria.it",
      "telefono": "+39 0742/300046",
      "fax": "+39 0742/300035",
      "coordinate": {
        "lat": 42.983333587646484,
        "lng": 12.666666984558105
      }
    },
    {
      "codice": "054051",
      "nome": "Spoleto",
      "nomeStraniero": null,
      "codiceCatastale": "I921",
      "cap": "06049",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "luca.ventura@comune.spoleto.pg.it",
      "pec": "comune.spoleto@postacert.umbria.it",
      "telefono": "+39 0743 2181",
      "fax": "+39 0743/218246",
      "coordinate": {
        "lat": 42.733333587646484,
        "lng": 12.733333587646484
      }
    },
    {
      "codice": "054052",
      "nome": "Todi",
      "nomeStraniero": null,
      "codiceCatastale": "L188",
      "cap": "06059",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "paolo.orazi@comune.todi.pg.it",
      "pec": "comune.todi@postacert.umbria.it",
      "telefono": "+39 075/8956206",
      "fax": "+39 075/8943862",
      "coordinate": {
        "lat": 42.78333282470703,
        "lng": 12.416666984558105
      }
    },
    {
      "codice": "054053",
      "nome": "Torgiano",
      "nomeStraniero": null,
      "codiceCatastale": "L216",
      "cap": "06089",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "area-amministrativa@comune.torgiano.pg.it",
      "pec": "comune.torgiano@postacert.umbria.it",
      "telefono": "+39 075/9886026",
      "fax": "+39 075/982128",
      "coordinate": {
        "lat": 43.02555465698242,
        "lng": 12.43416690826416
      }
    },
    {
      "codice": "054054",
      "nome": "Trevi",
      "nomeStraniero": null,
      "codiceCatastale": "L397",
      "cap": "06039",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "info@comune.trevi.pg.it",
      "pec": "comune.trevi@postacert.umbria.it",
      "telefono": "+39 0742 3321",
      "fax": "+39 0742 332237",
      "coordinate": {
        "lat": 42.893333435058594,
        "lng": 12.761666297912598
      }
    },
    {
      "codice": "054055",
      "nome": "Tuoro sul Trasimeno",
      "nomeStraniero": null,
      "codiceCatastale": "L466",
      "cap": "06069",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "raniero.belardinelli@comune.tuoro-sul-trasimeno.pg.it",
      "pec": "comune.tuorosultrasimeno@postacert.umbria.it",
      "telefono": "+39 075/82599337",
      "fax": "+39 075/82599338",
      "coordinate": {
        "lat": 43.20684814453125,
        "lng": 12.074614524841309
      }
    },
    {
      "codice": "054056",
      "nome": "Umbertide",
      "nomeStraniero": null,
      "codiceCatastale": "D786",
      "cap": "06019",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "segreteria@comune.umbertide.pg.it",
      "pec": "comune.umbertide@postacert.umbria.it",
      "telefono": "+39 075/9419204",
      "fax": "+39 075/9419240",
      "coordinate": {
        "lat": 43.30563735961914,
        "lng": 12.33662223815918
      }
    },
    {
      "codice": "054057",
      "nome": "Valfabbrica",
      "nomeStraniero": null,
      "codiceCatastale": "L573",
      "cap": "06029",
      "prefisso": "075",
      "provincia": "Perugia",
      "email": "marco.pettinelli@comune.valfabbrica.pg.it",
      "pec": "comune.valfabbrica@postacert.umbria.it",
      "telefono": "+39 075/9029832",
      "fax": "+39 075/9029838",
      "coordinate": {
        "lat": 43.16666793823242,
        "lng": 12.600000381469727
      }
    },
    {
      "codice": "054058",
      "nome": "Vallo di Nera",
      "nomeStraniero": null,
      "codiceCatastale": "L627",
      "cap": "06040",
      "prefisso": "0743",
      "provincia": "Perugia",
      "email": "giampiero.quarantini@comune.vallodinera.pg.it",
      "pec": "comune.vallodinera@postacert.umbria.it",
      "telefono": "+39 0743/616143",
      "fax": "+39 0743/617242",
      "coordinate": {
        "lat": 42.75539779663086,
        "lng": 12.864381790161133
      }
    },
    {
      "codice": "054059",
      "nome": "Valtopina",
      "nomeStraniero": null,
      "codiceCatastale": "L653",
      "cap": "06030",
      "prefisso": "0742",
      "provincia": "Perugia",
      "email": "servizi.tributi@comune.valtopina.pg.it",
      "pec": "comune.valtopina@postacert.umbria.it",
      "telefono": "+39 0742/74625",
      "fax": "+39 0742/74146",
      "coordinate": {
        "lat": 43.06666564941406,
        "lng": 12.75
      }
    }
  ],
  "Siracusa": [
    {
      "codice": "089001",
      "nome": "Augusta",
      "nomeStraniero": null,
      "codiceCatastale": "A494",
      "cap": "96011",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "info@comunediaugusta.it",
      "pec": "protocollocomunediaugusta@pointpec.it",
      "telefono": "+39 0931 980111",
      "fax": "+39 0931 991655",
      "coordinate": {
        "lat": 37.23027801513672,
        "lng": 15.219444274902344
      }
    },
    {
      "codice": "089002",
      "nome": "Avola",
      "nomeStraniero": null,
      "codiceCatastale": "A522",
      "cap": "96012",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "urp@comune.avola.sr.it",
      "pec": "protocollo@pec.comune.avola.sr.it",
      "telefono": "+39 0931-583111",
      "fax": "+39 0931-583187",
      "coordinate": {
        "lat": 36.91666793823242,
        "lng": 15.133333206176758
      }
    },
    {
      "codice": "089003",
      "nome": "Buccheri",
      "nomeStraniero": null,
      "codiceCatastale": "B237",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "aagg@comune.buccheri.sr.it",
      "pec": "protocollo@pec.comune.buccheri.sr.it",
      "telefono": "+39 0931 1931266",
      "fax": "+39 0931-880559",
      "coordinate": {
        "lat": 37.125,
        "lng": 14.851943969726562
      }
    },
    {
      "codice": "089004",
      "nome": "Buscemi",
      "nomeStraniero": null,
      "codiceCatastale": "B287",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "sindaco.buscemi@pec.it",
      "pec": "protocollo.buscemi@pec.it",
      "telefono": "+39 0931 878932",
      "fax": null,
      "coordinate": {
        "lat": 37.08333206176758,
        "lng": 14.866666793823242
      }
    },
    {
      "codice": "089005",
      "nome": "Canicattini Bagni",
      "nomeStraniero": null,
      "codiceCatastale": "B603",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "info@comune.canicattinibagni.sr.it",
      "pec": "sviluppoeconomico.canicattinibagni@pec.it",
      "telefono": "+39 0931 540111",
      "fax": "+39 0931 540207",
      "coordinate": {
        "lat": 37.03333282470703,
        "lng": 15.066666603088379
      }
    },
    {
      "codice": "089006",
      "nome": "Carlentini",
      "nomeStraniero": null,
      "codiceCatastale": "B787",
      "cap": "96013",
      "prefisso": "095",
      "provincia": "Siracusa",
      "email": "ced@comune.carlentini.sr.it",
      "pec": "affarigenerali@pec.comune.carlentini.sr.it",
      "telefono": "+39 095 7858111",
      "fax": "+39 095 991156",
      "coordinate": {
        "lat": 37.28333282470703,
        "lng": 15.016666412353516
      }
    },
    {
      "codice": "089007",
      "nome": "Cassaro",
      "nomeStraniero": null,
      "codiceCatastale": "C006",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "sindaco@comune.cassaro.sr.it",
      "pec": "protocollo.cassaro@pec.it",
      "telefono": "+39 0931 877611",
      "fax": "+39 0931 877188",
      "coordinate": {
        "lat": 37.099998474121094,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "089008",
      "nome": "Ferla",
      "nomeStraniero": null,
      "codiceCatastale": "D540",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "dirigente.amministrativo@comune.ferla.sr.it",
      "pec": "protocollo@pec.comune.ferla.sr.it",
      "telefono": "+39 0931 870136",
      "fax": "+39 0931 870136",
      "coordinate": {
        "lat": 37.11666488647461,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "089009",
      "nome": "Floridia",
      "nomeStraniero": null,
      "codiceCatastale": "D636",
      "cap": "96014",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "bilancio@comune.floridia.sr.it",
      "pec": "cfloridia@pec.comune.floridia.sr.it",
      "telefono": "+39 0931 920111",
      "fax": "+39 0931 920259",
      "coordinate": {
        "lat": 37.08333206176758,
        "lng": 15.149999618530273
      }
    },
    {
      "codice": "089010",
      "nome": "Francofonte",
      "nomeStraniero": null,
      "codiceCatastale": "D768",
      "cap": "96015",
      "prefisso": "095",
      "provincia": "Siracusa",
      "email": "comunedifrancofonte@tiscali.it",
      "pec": "protocollo.generale@pec.comune.francofonte.sr.it",
      "telefono": "+39 095 7887111",
      "fax": "+39 095 949941",
      "coordinate": {
        "lat": 37.233333587646484,
        "lng": 14.883333206176758
      }
    },
    {
      "codice": "089011",
      "nome": "Lentini",
      "nomeStraniero": null,
      "codiceCatastale": "E532",
      "cap": "96016",
      "prefisso": "095",
      "provincia": "Siracusa",
      "email": "ufficio.personale@comune.lentini.sr.it",
      "pec": "protocollo@pec.comune.lentini.sr.it",
      "telefono": "+39 095 900111",
      "fax": "+39 095 900181",
      "coordinate": {
        "lat": 37.28333282470703,
        "lng": 15
      }
    },
    {
      "codice": "089012",
      "nome": "Melilli",
      "nomeStraniero": null,
      "codiceCatastale": "F107",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "anagrafelettorale@comune.melilli.sr.it",
      "pec": "elettorale@pec.comune.melilli.sr.it",
      "telefono": "+39 0931 552111",
      "fax": "+39 0931 552107",
      "coordinate": {
        "lat": 37.18333435058594,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "089013",
      "nome": "Noto",
      "nomeStraniero": null,
      "codiceCatastale": "F943",
      "cap": "96017",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "elettorale@comune.noto.sr.it",
      "pec": "protocollo@comunenoto.legalmail.it",
      "telefono": "+39 0931 830052",
      "fax": null,
      "coordinate": {
        "lat": 36.88333511352539,
        "lng": 15.083333015441895
      }
    },
    {
      "codice": "089014",
      "nome": "Pachino",
      "nomeStraniero": null,
      "codiceCatastale": "G211",
      "cap": "96018",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "urp@comune.pachino.sr.it",
      "pec": "statocivile.pachino@arubapec.it",
      "telefono": "+39 0931 803111",
      "fax": "+39 0931 803123",
      "coordinate": {
        "lat": 36.71666717529297,
        "lng": 15.100000381469727
      }
    },
    {
      "codice": "089015",
      "nome": "Palazzolo Acreide",
      "nomeStraniero": null,
      "codiceCatastale": "G267",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "vicesegretario@comune.palazzoloacreide.sr.it",
      "pec": "ufficioelettorale@pec.comune.palazzoloacreide.sr.it",
      "telefono": "+39 0931.871111",
      "fax": "+39 0931 875685",
      "coordinate": {
        "lat": 37.061668395996094,
        "lng": 14.902777671813965
      }
    },
    {
      "codice": "089020",
      "nome": "Portopalo di Capo Passero",
      "nomeStraniero": null,
      "codiceCatastale": "M257",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "uffsegreteria.portopalo@virgilio.it",
      "pec": "comunecp.segreteria@pec.it",
      "telefono": "+39 0931 848021",
      "fax": "+39 0931 842879",
      "coordinate": {
        "lat": 36.68611145019531,
        "lng": 15.13611125946045
      }
    },
    {
      "codice": "089021",
      "nome": "Priolo Gargallo",
      "nomeStraniero": null,
      "codiceCatastale": "M279",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "ufficioanagrafe@comune.priologargallo.sr.it",
      "pec": "ufficio.protocollo@pec.comune.priologargallo.sr.it",
      "telefono": "+39 0931 779267",
      "fax": "+39 0931 779299",
      "coordinate": {
        "lat": 37.16666793823242,
        "lng": 15.183333396911621
      }
    },
    {
      "codice": "089016",
      "nome": "Rosolini",
      "nomeStraniero": null,
      "codiceCatastale": "H574",
      "cap": "96019",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "comune.rosolini@gmail.com",
      "pec": "affarigenerali@pec.comune.rosolini.sr.it",
      "telefono": "+39 0931.500111",
      "fax": "+39 0931.501563",
      "coordinate": {
        "lat": 36.81666564941406,
        "lng": 14.949999809265137
      }
    },
    {
      "codice": "089017",
      "nome": "Siracusa",
      "nomeStraniero": null,
      "codiceCatastale": "I754",
      "cap": "96100",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "alessandra.scimo@comune.siracusa.it",
      "pec": "sportellocittadino@pec.comune.siracusa.it",
      "telefono": "+39 0931 451111",
      "fax": "+39 0931 412854",
      "coordinate": {
        "lat": 37.06916809082031,
        "lng": 15.287500381469727
      }
    },
    {
      "codice": "089018",
      "nome": "Solarino",
      "nomeStraniero": null,
      "codiceCatastale": "I785",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "sindaco@comune-solarino.gov.it",
      "pec": "comune@solarino-pec.it",
      "telefono": "+39 0931 921380",
      "fax": "+39 0931 921253",
      "coordinate": {
        "lat": 37.099998474121094,
        "lng": 15.116666793823242
      }
    },
    {
      "codice": "089019",
      "nome": "Sortino",
      "nomeStraniero": null,
      "codiceCatastale": "I864",
      "cap": "96010",
      "prefisso": "0931",
      "provincia": "Siracusa",
      "email": "protocollo@comune.sortino.sr.it",
      "pec": "protocollo@pec.comune.sortino.sr.it",
      "telefono": "+39 0931-917000",
      "fax": "+39 0931.917425",
      "coordinate": {
        "lat": 37.16666793823242,
        "lng": 15.033333778381348
      }
    }
  ],
  "Massa-Carrara": [
    {
      "codice": "045001",
      "nome": "Aulla",
      "nomeStraniero": null,
      "codiceCatastale": "A496",
      "cap": "54011",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "anagrafe@comune.aulla.ms.it",
      "pec": "comune.aulla@postacert.toscana.it",
      "telefono": "+39 0187/40001",
      "fax": "+39 0187/400218",
      "coordinate": {
        "lat": 44.21666717529297,
        "lng": 9.966666221618652
      }
    },
    {
      "codice": "045002",
      "nome": "Bagnone",
      "nomeStraniero": null,
      "codiceCatastale": "A576",
      "cap": "54021",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "anagrafe@comune.bagnone.ms.it",
      "pec": "comune.bagnone@postacert.toscana.it",
      "telefono": "+39 0187/427826",
      "fax": "+39 0187/429210",
      "coordinate": {
        "lat": 44.31473922729492,
        "lng": 9.99465560913086
      }
    },
    {
      "codice": "045003",
      "nome": "Carrara",
      "nomeStraniero": null,
      "codiceCatastale": "B832",
      "cap": "54033",
      "prefisso": "0585",
      "provincia": "Massa-Carrara",
      "email": "emiliano.mosti@comune.carrara.ms.it",
      "pec": "comune.carrara@postecert.it",
      "telefono": "+39 0585 6411",
      "fax": "+39 0585/641381",
      "coordinate": {
        "lat": 44.08333206176758,
        "lng": 10.100000381469727
      }
    },
    {
      "codice": "045004",
      "nome": "Casola in Lunigiana",
      "nomeStraniero": null,
      "codiceCatastale": "B979",
      "cap": "54014",
      "prefisso": "0585",
      "provincia": "Massa-Carrara",
      "email": "comune.casola@lunigiana.ms.it",
      "pec": "comune.casolainlunigiana@postacert.toscana.it",
      "telefono": "+39 0585/90013",
      "fax": "+39 0585/90150",
      "coordinate": {
        "lat": 44.20000076293945,
        "lng": 10.183333396911621
      }
    },
    {
      "codice": "045005",
      "nome": "Comano",
      "nomeStraniero": null,
      "codiceCatastale": "C914",
      "cap": "54015",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "anagrafe@comune.comano.ms.it",
      "pec": "comune.comano@postacert.toscana.it",
      "telefono": "+39 0187/484205",
      "fax": "+39 0187/484108",
      "coordinate": {
        "lat": 44.28333282470703,
        "lng": 10.133333206176758
      }
    },
    {
      "codice": "045006",
      "nome": "Filattiera",
      "nomeStraniero": null,
      "codiceCatastale": "D590",
      "cap": "54023",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "ufficio.anagrafe@comune.filattiera.ms.it",
      "pec": "comune.filattiera@postacert.toscana.it",
      "telefono": "+39 0187/457311",
      "fax": "+39 0187/458407",
      "coordinate": {
        "lat": 44.33333206176758,
        "lng": 9.933333396911621
      }
    },
    {
      "codice": "045007",
      "nome": "Fivizzano",
      "nomeStraniero": null,
      "codiceCatastale": "D629",
      "cap": "54013",
      "prefisso": "0585",
      "provincia": "Massa-Carrara",
      "email": "anagrafe@comune.fivizzano.ms.it",
      "pec": "comune.fivizzano@postacert.toscana.it",
      "telefono": "+39 0585 94211",
      "fax": "+39 0585 926615",
      "coordinate": {
        "lat": 44.233333587646484,
        "lng": 10.116666793823242
      }
    },
    {
      "codice": "045008",
      "nome": "Fosdinovo",
      "nomeStraniero": null,
      "codiceCatastale": "D735",
      "cap": "54035",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "dinunzio@comune.fosdinovo.ms.it",
      "pec": "comune.fosdinovo@postacert.toscana.it",
      "telefono": "+39 0187/6807210",
      "fax": "+39 0187/6807235",
      "coordinate": {
        "lat": 44.13333511352539,
        "lng": 10.016666412353516
      }
    },
    {
      "codice": "045009",
      "nome": "Licciana Nardi",
      "nomeStraniero": null,
      "codiceCatastale": "E574",
      "cap": "54016",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "s.bertoloni@comune.licciananardi.ms.it",
      "pec": "comune.licciananardi@legalmail.it",
      "telefono": "+39 0187474923",
      "fax": "+39 0187475503",
      "coordinate": {
        "lat": 44.266666412353516,
        "lng": 10.033333778381348
      }
    },
    {
      "codice": "045010",
      "nome": "Massa",
      "nomeStraniero": null,
      "codiceCatastale": "F023",
      "cap": "54100",
      "prefisso": "0585",
      "provincia": "Massa-Carrara",
      "email": "uffgab@comune.massa.ms.it",
      "pec": "comune.massa@postacert.toscana.it",
      "telefono": "+39 0585 4901",
      "fax": "+39 0585/41245",
      "coordinate": {
        "lat": 44.03333282470703,
        "lng": 10.133333206176758
      }
    },
    {
      "codice": "045011",
      "nome": "Montignoso",
      "nomeStraniero": null,
      "codiceCatastale": "F679",
      "cap": "54038",
      "prefisso": "0585",
      "provincia": "Massa-Carrara",
      "email": "urp@comune.montignoso.ms.it",
      "pec": "protocollo@pec.comune.montignoso.ms.it",
      "telefono": "+39 0585 8271205",
      "fax": "+39 0585 348197",
      "coordinate": {
        "lat": 44.016666412353516,
        "lng": 10.166666984558105
      }
    },
    {
      "codice": "045012",
      "nome": "Mulazzo",
      "nomeStraniero": null,
      "codiceCatastale": "F802",
      "cap": "54026",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "anagrafe@comune.mulazzo.ms.it",
      "pec": "comune.mulazzo.ms@halleycert.it",
      "telefono": "+39 0187/43901",
      "fax": "+39 0187/437756",
      "coordinate": {
        "lat": 44.31694412231445,
        "lng": 9.890555381774902
      }
    },
    {
      "codice": "045013",
      "nome": "Podenzana",
      "nomeStraniero": null,
      "codiceCatastale": "G746",
      "cap": "54010",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "segreteria@comune.podenzana.ms.it",
      "pec": "comune@pec.comune.podenzana.ms.it",
      "telefono": "+39 0187 410024",
      "fax": "+39 0187 410088",
      "coordinate": {
        "lat": 44.20000076293945,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "045014",
      "nome": "Pontremoli",
      "nomeStraniero": null,
      "codiceCatastale": "G870",
      "cap": "54027",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "protocollo@comune.pontremoli.ms.it",
      "pec": "protocollo@pec.comune.pontremoli.ms.it",
      "telefono": "+39 01874601210",
      "fax": "+39 0187/833289",
      "coordinate": {
        "lat": 44.3761100769043,
        "lng": 9.879899978637695
      }
    },
    {
      "codice": "045015",
      "nome": "Tresana",
      "nomeStraniero": null,
      "codiceCatastale": "L386",
      "cap": "54012",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "servizidemografici@comune.tresana.ms.it",
      "pec": "comune.tresana@postacert.toscana.it",
      "telefono": "+39 0187/474112",
      "fax": "+39 0187/477449",
      "coordinate": {
        "lat": 44.233333587646484,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "045016",
      "nome": "Villafranca in Lunigiana",
      "nomeStraniero": null,
      "codiceCatastale": "L946",
      "cap": "54028",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "demografici@comune.villafranca.ms.itlunigiana.it",
      "pec": "comune.villafrancainlunigiana@legalmail.it",
      "telefono": "+39 0187 4988212",
      "fax": null,
      "coordinate": {
        "lat": 44.295555114746094,
        "lng": 9.949999809265137
      }
    },
    {
      "codice": "045017",
      "nome": "Zeri",
      "nomeStraniero": null,
      "codiceCatastale": "M169",
      "cap": "54029",
      "prefisso": "0187",
      "provincia": "Massa-Carrara",
      "email": "anagrafe@comune.zeri.ms.it",
      "pec": "comune.zeri@postacert.toscana.it",
      "telefono": "+39 0187/447127",
      "fax": "+39 0187/447484",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 9.766666412353516
      }
    }
  ],
  "Taranto": [
    {
      "codice": "073001",
      "nome": "Avetrana",
      "nomeStraniero": null,
      "codiceCatastale": "A514",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "elettoraleavetrana@libero.it",
      "pec": "prot.comune.avetrana@pec.rupar.puglia.it",
      "telefono": "+39 0999703078",
      "fax": "+39 0999704336",
      "coordinate": {
        "lat": 40.35388946533203,
        "lng": 17.7358341217041
      }
    },
    {
      "codice": "073002",
      "nome": "Carosino",
      "nomeStraniero": null,
      "codiceCatastale": "B808",
      "cap": "74021",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "comune.carosino@libero.it",
      "pec": "comune.carosino@legalmail.it",
      "telefono": "+39 099 5919162",
      "fax": "+39 099 5921028",
      "coordinate": {
        "lat": 40.46666717529297,
        "lng": 17.399999618530273
      }
    },
    {
      "codice": "073003",
      "nome": "Castellaneta",
      "nomeStraniero": null,
      "codiceCatastale": "C136",
      "cap": "74011",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "sindaco@castellaneta.gov.it",
      "pec": "comunecastellanetaprotocollo@postecert.it",
      "telefono": "+39 0998497257",
      "fax": "+39 0998442048",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 16.933332443237305
      }
    },
    {
      "codice": "073004",
      "nome": "Crispiano",
      "nomeStraniero": null,
      "codiceCatastale": "D171",
      "cap": "74012",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "info@comune.crispiano.ta.it",
      "pec": "elettorale.comune.crispiano@pec.rupar.puglia.it",
      "telefono": "+39 099 8117111",
      "fax": "+39 099 613033",
      "coordinate": {
        "lat": 40.599998474121094,
        "lng": 17.233333587646484
      }
    },
    {
      "codice": "073005",
      "nome": "Faggiano",
      "nomeStraniero": null,
      "codiceCatastale": "D463",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "segretariofaggiano@pec.rupar.puglia.it",
      "pec": "segreteria.comunefaggiano@pec.rupar.puglia.it",
      "telefono": "+39 099 5912292",
      "fax": "+39 099 5912465",
      "coordinate": {
        "lat": 40.416160583496094,
        "lng": 17.38861083984375
      }
    },
    {
      "codice": "073006",
      "nome": "Fragagnano",
      "nomeStraniero": null,
      "codiceCatastale": "D754",
      "cap": "74022",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "segreteria.fragagnano@virgilio.it",
      "pec": "demografici.fragagnano@pec.it",
      "telefono": "+39 099 9561884",
      "fax": "+39 099 9564117",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 17.46666717529297
      }
    },
    {
      "codice": "073007",
      "nome": "Ginosa",
      "nomeStraniero": null,
      "codiceCatastale": "E036",
      "cap": "74013",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "segretario.generale@comune.ginosa.ta.it",
      "pec": "comune.ginosa@pec.rupar.puglia.it",
      "telefono": "+39 0998290211",
      "fax": "+39 0998244826",
      "coordinate": {
        "lat": 40.5,
        "lng": 16.75
      }
    },
    {
      "codice": "073008",
      "nome": "Grottaglie",
      "nomeStraniero": null,
      "codiceCatastale": "E205",
      "cap": "74023",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "ced@comune.grottaglie.ta.it",
      "pec": "comunegrottaglie@pec.rupar.puglia.it",
      "telefono": "+39 099 5620300",
      "fax": "+39 099 5620260",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 17.433332443237305
      }
    },
    {
      "codice": "073009",
      "nome": "Laterza",
      "nomeStraniero": null,
      "codiceCatastale": "E469",
      "cap": "74014",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "comune@comune.laterza.ta.it",
      "pec": "comunelaterza@pec.rupar.puglia.it",
      "telefono": "+39 0998297911",
      "fax": "+39 0998296211",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 16.799999237060547
      }
    },
    {
      "codice": "073010",
      "nome": "Leporano",
      "nomeStraniero": null,
      "codiceCatastale": "E537",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "segreteria@comuneleporano.it",
      "pec": "affarigenerali.comuneleporano@postecert.it",
      "telefono": "+39 099 5316423",
      "fax": "+39 0995316429",
      "coordinate": {
        "lat": 40.382179260253906,
        "lng": 17.334260940551758
      }
    },
    {
      "codice": "073011",
      "nome": "Lizzano",
      "nomeStraniero": null,
      "codiceCatastale": "E630",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "statocivile@comune.lizzano.ta.it",
      "pec": "statocivile.comunelizzano.ta@pec.rupar.puglia.it",
      "telefono": "+39 0999558617",
      "fax": "+39 099 9558633",
      "coordinate": {
        "lat": 40.391944885253906,
        "lng": 17.448333740234375
      }
    },
    {
      "codice": "073012",
      "nome": "Manduria",
      "nomeStraniero": null,
      "codiceCatastale": "E882",
      "cap": "74024",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "v.segretariomanduria@libero.it",
      "pec": "ufficiosegreteria.comunemanduria@pec.rupar.puglia.it",
      "telefono": "+39 0999702263",
      "fax": "+39 0999702263",
      "coordinate": {
        "lat": 40.40277862548828,
        "lng": 17.634166717529297
      }
    },
    {
      "codice": "073013",
      "nome": "Martina Franca",
      "nomeStraniero": null,
      "codiceCatastale": "E986",
      "cap": "74015",
      "prefisso": "080",
      "provincia": "Taranto",
      "email": "protocollo.comunemartinafranca@pec.rupar.puglia.it",
      "pec": "protocollo.comunemartinafranca@pec.rupar.puglia.it",
      "telefono": "+39 0804836235",
      "fax": null,
      "coordinate": {
        "lat": 40.70000076293945,
        "lng": 17.33333396911621
      }
    },
    {
      "codice": "073014",
      "nome": "Maruggio",
      "nomeStraniero": null,
      "codiceCatastale": "E995",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "infor@comune.maruggio.ta.it",
      "pec": "protocollo.comune.maruggio@pec.rupar.puglia.it",
      "telefono": "+39 099 9701211",
      "fax": "+39 099 676296",
      "coordinate": {
        "lat": 40.322776794433594,
        "lng": 17.573610305786133
      }
    },
    {
      "codice": "073015",
      "nome": "Massafra",
      "nomeStraniero": null,
      "codiceCatastale": "F027",
      "cap": "74016",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "sindaco@comunedimassafra.it",
      "pec": "protocollo@pec.comunedimassafra.it",
      "telefono": "+39 099 8858111",
      "fax": "+39 099 8801611",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 17.116666793823242
      }
    },
    {
      "codice": "073016",
      "nome": "Monteiasi",
      "nomeStraniero": null,
      "codiceCatastale": "F531",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "servizioaffarigenerali@comune.monteiasi.ta.it",
      "pec": "sociali.comune.monteiasi@pec.rupar.puglia.it",
      "telefono": "+39 099 5908811",
      "fax": "+39 099 5901148",
      "coordinate": {
        "lat": 40.5,
        "lng": 17.383333206176758
      }
    },
    {
      "codice": "073017",
      "nome": "Montemesola",
      "nomeStraniero": null,
      "codiceCatastale": "F563",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "sindaco@comune.montemesola.ta.it",
      "pec": "sindaco.comunemontemesola@pec.rupar.puglia.it",
      "telefono": "+39 099-5664018",
      "fax": "+39 099-5664438",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 17.33333396911621
      }
    },
    {
      "codice": "073018",
      "nome": "Monteparano",
      "nomeStraniero": null,
      "codiceCatastale": "F587",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "statocivile@comune.monteparano.ta.it",
      "pec": "servizigenerali.comune.monteparano@pec.rupar.puglia.it",
      "telefono": "+39 099 5993038",
      "fax": "+39 099 5993697",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 17.41666603088379
      }
    },
    {
      "codice": "073019",
      "nome": "Mottola",
      "nomeStraniero": null,
      "codiceCatastale": "F784",
      "cap": "74017",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "sindaco@comune.mottola.ta.it",
      "pec": "segreteria@pec.comune.mottola.ta.it",
      "telefono": "+39 099 8866911",
      "fax": "+39 099 8867913",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 17.03333282470703
      }
    },
    {
      "codice": "073020",
      "nome": "Palagianello",
      "nomeStraniero": null,
      "codiceCatastale": "G251",
      "cap": "74018",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "sindaco@comune.palagianello.ta.it",
      "pec": "comune.palagianello.ta.it@pec.it",
      "telefono": "+39 099 8434211",
      "fax": "+39 099 8444607",
      "coordinate": {
        "lat": 40.61666488647461,
        "lng": 16.96666717529297
      }
    },
    {
      "codice": "073021",
      "nome": "Palagiano",
      "nomeStraniero": null,
      "codiceCatastale": "G252",
      "cap": "74019",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "ragioneria.palagiano@libero.it",
      "pec": "comunepalagiano.aagg@postecert.it",
      "telefono": "+39 099 8886611",
      "fax": "+39 099 8886632",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 17.049999237060547
      }
    },
    {
      "codice": "073022",
      "nome": "Pulsano",
      "nomeStraniero": null,
      "codiceCatastale": "H090",
      "cap": "74026",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "urp.pulsano@tin.it",
      "pec": "protocollo@mailcert.comune.pulsano.ta.it",
      "telefono": "+39 0995312200",
      "fax": "+39 0995337332",
      "coordinate": {
        "lat": 40.3841667175293,
        "lng": 17.35472297668457
      }
    },
    {
      "codice": "073023",
      "nome": "Roccaforzata",
      "nomeStraniero": null,
      "codiceCatastale": "H409",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "segretario.comuneroccaforzata@pec.rupar.puglia.it",
      "pec": "demografici.comuneroccaforzata@pec.rupar.puglia.it",
      "telefono": "+39 0995929243",
      "fax": "+39 0995916940",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 17.399999618530273
      }
    },
    {
      "codice": "073024",
      "nome": "San Giorgio Ionico",
      "nomeStraniero": null,
      "codiceCatastale": "H882",
      "cap": "74027",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "segreteria@comunesangiorgioionico.it",
      "pec": "segreteria@pec.comunesangiorgioionico.it",
      "telefono": "+39 099 5915218",
      "fax": "+39 099 5915272",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 17.366666793823242
      }
    },
    {
      "codice": "073025",
      "nome": "San Marzano di San Giuseppe",
      "nomeStraniero": null,
      "codiceCatastale": "I018",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "sindaco@comunesanmarzano.ta.it",
      "pec": "protocollo@pec.comunesanmarzano.ta.it",
      "telefono": "+39 099 9577711",
      "fax": "+39 099 9577721",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 17.5
      }
    },
    {
      "codice": "073026",
      "nome": "Sava",
      "nomeStraniero": null,
      "codiceCatastale": "I467",
      "cap": "74028",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "anagrafeleva@comune.sava.ta.it",
      "pec": "comune.sava@pec.rupar.puglia.it",
      "telefono": "+39 0999727915",
      "fax": "+39 0999727929",
      "coordinate": {
        "lat": 40.40027618408203,
        "lng": 17.566667556762695
      }
    },
    {
      "codice": "073029",
      "nome": "Statte",
      "nomeStraniero": null,
      "codiceCatastale": "M298",
      "cap": "74010",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "informatica@comne.statte.ta.it",
      "pec": "aoo1.comunestatte@pec.rupar.puglia.it",
      "telefono": "+39 0994742831",
      "fax": "+39 0994742802",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 17.200000762939453
      }
    },
    {
      "codice": "073027",
      "nome": "Taranto",
      "nomeStraniero": null,
      "codiceCatastale": "L049",
      "cap": "74121",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "g.gugliersi@comune.taranto.it",
      "pec": "protocollo.comunetaranto@pec.rupar.puglia.it",
      "telefono": "+39 0994581944",
      "fax": "+39 0994581456",
      "coordinate": {
        "lat": 40.41805648803711,
        "lng": 17.240833282470703
      }
    },
    {
      "codice": "073028",
      "nome": "Torricella",
      "nomeStraniero": null,
      "codiceCatastale": "L294",
      "cap": "74020",
      "prefisso": "099",
      "provincia": "Taranto",
      "email": "anagrafetorricella@libero.it",
      "pec": "info@pec.comune.torricella.ta.it",
      "telefono": "+39 099 9573007",
      "fax": "+39 099 9573366",
      "coordinate": {
        "lat": 40.349998474121094,
        "lng": 17.5
      }
    }
  ],
  "Firenze": [
    {
      "codice": "048001",
      "nome": "Bagno a Ripoli",
      "nomeStraniero": null,
      "codiceCatastale": "A564",
      "cap": "50012",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.bagno-a-ripoli.fi.it",
      "pec": "comune.bagno-a-ripoli@postacert.toscana.it",
      "telefono": "+39 055/63901",
      "fax": "+39 055/6390267",
      "coordinate": {
        "lat": 43.75,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "048002",
      "nome": "Barberino di Mugello",
      "nomeStraniero": null,
      "codiceCatastale": "A632",
      "cap": "50031",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.barberino-di-mugello.fi.it",
      "pec": "barberino-di-mugello@postacert.toscana.it",
      "telefono": "+39 055/84771",
      "fax": "+39 055/8477299",
      "coordinate": {
        "lat": 44,
        "lng": 11.233333587646484
      }
    },
    {
      "codice": "048054",
      "nome": "Barberino Tavarnelle",
      "nomeStraniero": null,
      "codiceCatastale": "M408",
      "cap": "50028",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@barberinotavarnelle.it",
      "pec": "barberinotavarnelle@postacert.toscana.it",
      "telefono": "055055",
      "fax": "055-8076657",
      "coordinate": {
        "lat": 43.5520133972168,
        "lng": 11.173097610473633
      }
    },
    {
      "codice": "048004",
      "nome": "Borgo San Lorenzo",
      "nomeStraniero": null,
      "codiceCatastale": "B036",
      "cap": "50032",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.borgo-san-lorenzo.fi.it",
      "pec": "comune.borgo-san-lorenzo@postacert.toscana.it",
      "telefono": "+39 055/849661",
      "fax": "+39 055/8456782",
      "coordinate": {
        "lat": 43.95547866821289,
        "lng": 11.38560962677002
      }
    },
    {
      "codice": "048005",
      "nome": "Calenzano",
      "nomeStraniero": null,
      "codiceCatastale": "B406",
      "cap": "50041",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.calenzano.fi.it",
      "pec": "calenzano.protocollo@postacert.toscana.it",
      "telefono": "+39 055/88331",
      "fax": "+39 055/8833232",
      "coordinate": {
        "lat": 43.856666564941406,
        "lng": 11.16361141204834
      }
    },
    {
      "codice": "048006",
      "nome": "Campi Bisenzio",
      "nomeStraniero": null,
      "codiceCatastale": "B507",
      "cap": "50013",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "sindaco@comune.campi-bisenzio.fi.it",
      "pec": "comune.campi-bisenzio@postacert.toscana.it",
      "telefono": "+39 055/89591",
      "fax": "+39 055891965",
      "coordinate": {
        "lat": 43.82555389404297,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "048008",
      "nome": "Capraia e Limite",
      "nomeStraniero": null,
      "codiceCatastale": "B684",
      "cap": "50050",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "info@comune.capraia-e-limite.fi.it",
      "pec": "comune.capraia-e-limite@postacert.toscana.it",
      "telefono": "+39 0571 9781",
      "fax": "+39 0571/979503",
      "coordinate": {
        "lat": 43.75,
        "lng": 10.983333587646484
      }
    },
    {
      "codice": "048010",
      "nome": "Castelfiorentino",
      "nomeStraniero": null,
      "codiceCatastale": "C101",
      "cap": "50051",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "anagrafe@comune.castelfiorentino.fi.it",
      "pec": "comune.castelfiorentino@postacert.toscana.it",
      "telefono": "+39 0571/686360",
      "fax": "+39 0571/629355",
      "coordinate": {
        "lat": 43.61083221435547,
        "lng": 10.970000267028809
      }
    },
    {
      "codice": "048011",
      "nome": "Cerreto Guidi",
      "nomeStraniero": null,
      "codiceCatastale": "C529",
      "cap": "50050",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "urp@comune.cerreto-guidi.fi.it",
      "pec": "comune.cerreto-guidi@postacert.toscana.it",
      "telefono": "+39 0571/906203",
      "fax": "+39 0571/558878",
      "coordinate": {
        "lat": 43.76169967651367,
        "lng": 10.877099990844727
      }
    },
    {
      "codice": "048012",
      "nome": "Certaldo",
      "nomeStraniero": null,
      "codiceCatastale": "C540",
      "cap": "50052",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "sindaco@comune.certaldo.fi.it",
      "pec": "comune.certaldo@postacert.toscana.it",
      "telefono": "+39 0571 6611",
      "fax": "+39 0571/661201",
      "coordinate": {
        "lat": 43.54777908325195,
        "lng": 11.04111099243164
      }
    },
    {
      "codice": "048013",
      "nome": "Dicomano",
      "nomeStraniero": null,
      "codiceCatastale": "D299",
      "cap": "50062",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "protocollo@comune.dicomano.fi.it",
      "pec": "comune.dicomano@postacert.toscana.it",
      "telefono": "+39 055/838541",
      "fax": "+39 055/8385423",
      "coordinate": {
        "lat": 43.900001525878906,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "048014",
      "nome": "Empoli",
      "nomeStraniero": null,
      "codiceCatastale": "D403",
      "cap": "50053",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "elettorale@comune.empoli.fi.it",
      "pec": "comune.empoli@postacert.toscana.it",
      "telefono": "+39 0571757774",
      "fax": "+39 0571757633",
      "coordinate": {
        "lat": 43.71666717529297,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "048015",
      "nome": "Fiesole",
      "nomeStraniero": null,
      "codiceCatastale": "D575",
      "cap": "50014",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "segreteria.sindaco@comune.fiesole.fi.it",
      "pec": "comune.fiesole@postacert.toscana.it",
      "telefono": "+39 055/59611",
      "fax": "+39 055/5961278",
      "coordinate": {
        "lat": 43.79999923706055,
        "lng": 11.300000190734863
      }
    },
    {
      "codice": "048052",
      "nome": "Figline e Incisa Valdarno",
      "nomeStraniero": null,
      "codiceCatastale": "M321",
      "cap": "50063",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "sindaco@comunefiv.it",
      "pec": "comune.figlineincisa@postacert.toscana.it",
      "telefono": "+39 055 91251",
      "fax": "+39 055 952682",
      "coordinate": {
        "lat": 43.630794525146484,
        "lng": 11.462945938110352
      }
    },
    {
      "codice": "048017",
      "nome": "Firenze",
      "nomeStraniero": null,
      "codiceCatastale": "D612",
      "cap": "50100",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "comunefirenze@comune.fi.it",
      "pec": "protocollo@pec.comune.fi.it",
      "telefono": "+39 055 27681",
      "fax": "+39 055/2768402",
      "coordinate": {
        "lat": 43.77138900756836,
        "lng": 11.254166603088379
      }
    },
    {
      "codice": "048018",
      "nome": "Firenzuola",
      "nomeStraniero": null,
      "codiceCatastale": "D613",
      "cap": "50033",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "protocollo@comune.firenzuola.fi.it",
      "pec": "comune.firenzuola@postacert.toscana.it",
      "telefono": "+39 055/819941",
      "fax": "+39 055/819366",
      "coordinate": {
        "lat": 44.11967849731445,
        "lng": 11.381850242614746
      }
    },
    {
      "codice": "048019",
      "nome": "Fucecchio",
      "nomeStraniero": null,
      "codiceCatastale": "D815",
      "cap": "50054",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "comune@comune.fucecchio.fi.it",
      "pec": "comune.fucecchio@postacert.toscana.it",
      "telefono": "+39 0571 2681",
      "fax": "+39 0571/268246",
      "coordinate": {
        "lat": 43.733333587646484,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "048020",
      "nome": "Gambassi Terme",
      "nomeStraniero": null,
      "codiceCatastale": "D895",
      "cap": "50050",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "info@comune.gambassi-terme.fi.it",
      "pec": "comune.gambassi@postacert.toscana.it",
      "telefono": "+39 0571/638224",
      "fax": "+39 0571/638633",
      "coordinate": {
        "lat": 43.53333282470703,
        "lng": 10.949999809265137
      }
    },
    {
      "codice": "048021",
      "nome": "Greve in Chianti",
      "nomeStraniero": null,
      "codiceCatastale": "E169",
      "cap": "50022",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "demografici@comune.greve-in-chianti.fi.it",
      "pec": "comune.greve-in-chianti@postacert.toscana.it",
      "telefono": "+39 0558545205",
      "fax": "+39 0558544654",
      "coordinate": {
        "lat": 43.58333206176758,
        "lng": 11.316666603088379
      }
    },
    {
      "codice": "048022",
      "nome": "Impruneta",
      "nomeStraniero": null,
      "codiceCatastale": "E291",
      "cap": "50023",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.impruneta.fi.it",
      "pec": "comune.impruneta@postacert.toscana.it",
      "telefono": "+39 055/2036441",
      "fax": "+39 055/2036438",
      "coordinate": {
        "lat": 43.6854248046875,
        "lng": 11.25439453125
      }
    },
    {
      "codice": "048024",
      "nome": "Lastra a Signa",
      "nomeStraniero": null,
      "codiceCatastale": "E466",
      "cap": "50055",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.lastra-a-signa.fi.it",
      "pec": "comune.lastra-a-signa@pec.it",
      "telefono": "800882299",
      "fax": "+39 0558722946",
      "coordinate": {
        "lat": 43.766666412353516,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "048025",
      "nome": "Londa",
      "nomeStraniero": null,
      "codiceCatastale": "E668",
      "cap": "50060",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "finanziari@comune.londa.fi.it",
      "pec": "londa@postacert.toscana.it",
      "telefono": "+39 055/835251",
      "fax": "+39 055/8351163",
      "coordinate": {
        "lat": 43.86666488647461,
        "lng": 11.566666603088379
      }
    },
    {
      "codice": "048026",
      "nome": "Marradi",
      "nomeStraniero": null,
      "codiceCatastale": "E971",
      "cap": "50034",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "segreteria@comune.marradi.fi.it",
      "pec": "comune.marradi@postacert.toscana.it",
      "telefono": "+39 055/8045005",
      "fax": "+39 055/8045803",
      "coordinate": {
        "lat": 44.08333206176758,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "048027",
      "nome": "Montaione",
      "nomeStraniero": null,
      "codiceCatastale": "F398",
      "cap": "50050",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "info@comune.montaione.fi.it",
      "pec": "comune.montaione@postacert.toscana.it",
      "telefono": "+39 0571 6991",
      "fax": "+39 0571/699256",
      "coordinate": {
        "lat": 43.5533332824707,
        "lng": 10.911666870117188
      }
    },
    {
      "codice": "048028",
      "nome": "Montelupo Fiorentino",
      "nomeStraniero": null,
      "codiceCatastale": "F551",
      "cap": "50056",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "urp@comune.montelupo-fiorentino.fi.it",
      "pec": "comune.montelupo-fiorentino@postacert.toscana.it",
      "telefono": "+39 0571 9174",
      "fax": "+39 0571/917528",
      "coordinate": {
        "lat": 43.733333587646484,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "048030",
      "nome": "Montespertoli",
      "nomeStraniero": null,
      "codiceCatastale": "F648",
      "cap": "50025",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "urp@comune.montespertoli.fi.it",
      "pec": "comune.montespertoli@postacert.toscana.it",
      "telefono": "+39 0571 6001",
      "fax": "+39 0571/609760",
      "coordinate": {
        "lat": 43.650001525878906,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "048031",
      "nome": "Palazzuolo sul Senio",
      "nomeStraniero": null,
      "codiceCatastale": "G270",
      "cap": "50035",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "protocollo@comune.palazzuolo-sul-senio.fi.it",
      "pec": "comune.palazzuolo-sul-senio@postacert.toscana.it",
      "telefono": "+39 055/8046008",
      "fax": "+39 055/8046461",
      "coordinate": {
        "lat": 44.11666488647461,
        "lng": 11.550000190734863
      }
    },
    {
      "codice": "048032",
      "nome": "Pelago",
      "nomeStraniero": null,
      "codiceCatastale": "G420",
      "cap": "50060",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "affarigenerali@comune.pelago.fi.it",
      "pec": "comune.pelago@postacert.toscana.it",
      "telefono": "+39 055/8327301",
      "fax": "+39 055/8326839",
      "coordinate": {
        "lat": 43.773887634277344,
        "lng": 11.504722595214844
      }
    },
    {
      "codice": "048033",
      "nome": "Pontassieve",
      "nomeStraniero": null,
      "codiceCatastale": "G825",
      "cap": "50060",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "demografici@comune.pontassieve.fi.it",
      "pec": "comune.pontassieve@postacert.toscana.it",
      "telefono": "+39 055/8360366",
      "fax": "+39 055/8360285",
      "coordinate": {
        "lat": 43.775001525878906,
        "lng": 11.4375
      }
    },
    {
      "codice": "048035",
      "nome": "Reggello",
      "nomeStraniero": null,
      "codiceCatastale": "H222",
      "cap": "50066",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "comune.reggello@postacert.toscana.it",
      "pec": "comune.reggello@postacert.toscana.it",
      "telefono": "+39 055/86691",
      "fax": "+39 055/8669266",
      "coordinate": {
        "lat": 43.68333435058594,
        "lng": 11.533333778381348
      }
    },
    {
      "codice": "048036",
      "nome": "Rignano sull'Arno",
      "nomeStraniero": null,
      "codiceCatastale": "H286",
      "cap": "50067",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "ced@comune.rignano-sullarno.fi.it",
      "pec": "comune.rignano@postacert.toscana.it",
      "telefono": "+39 055834781",
      "fax": "+39 0558348787",
      "coordinate": {
        "lat": 43.72370147705078,
        "lng": 11.450699806213379
      }
    },
    {
      "codice": "048037",
      "nome": "Rufina",
      "nomeStraniero": null,
      "codiceCatastale": "H635",
      "cap": "50068",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.rufina.fi.it",
      "pec": "comune.rufina@postacert.toscana.it",
      "telefono": "+39 055/839651",
      "fax": "+39 055/8397082",
      "coordinate": {
        "lat": 43.81666564941406,
        "lng": 11.483333587646484
      }
    },
    {
      "codice": "048038",
      "nome": "San Casciano in Val di Pesa",
      "nomeStraniero": null,
      "codiceCatastale": "H791",
      "cap": "50026",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "info@comune.san-casciano-in-val-di-pesa.fi.it",
      "pec": "comune.sancascianovp@postacert.toscana.it",
      "telefono": "+39 055/82561",
      "fax": "+39 055/828316",
      "coordinate": {
        "lat": 43.656944274902344,
        "lng": 11.185832977294922
      }
    },
    {
      "codice": "048039",
      "nome": "San Godenzo",
      "nomeStraniero": null,
      "codiceCatastale": "H937",
      "cap": "50060",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "info@comune.san-godenzo.it",
      "pec": "comune.sangodenzo@postacert.toscana.it",
      "telefono": "+39 055/8374381",
      "fax": "+39 055/8374118",
      "coordinate": {
        "lat": 43.91666793823242,
        "lng": 11.616666793823242
      }
    },
    {
      "codice": "048041",
      "nome": "Scandicci",
      "nomeStraniero": null,
      "codiceCatastale": "B962",
      "cap": "50018",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "puntocomune@comune.scandicci.fi.it",
      "pec": "comune.scandicci@postacert.toscana.it",
      "telefono": "+39 055 75911",
      "fax": "+39 055.7591.320",
      "coordinate": {
        "lat": 43.75444412231445,
        "lng": 11.189444541931152
      }
    },
    {
      "codice": "048053",
      "nome": "Scarperia e San Piero",
      "nomeStraniero": null,
      "codiceCatastale": "M326",
      "cap": "50037",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "anagrafe@comune.scarperiaesanpiero.fi.it",
      "pec": "comune.scarperiaesanpiero@postacert.toscana.it",
      "telefono": "+39 055 843161",
      "fax": "+39 055 846509",
      "coordinate": {
        "lat": 44.010963439941406,
        "lng": 11.33134937286377
      }
    },
    {
      "codice": "048043",
      "nome": "Sesto Fiorentino",
      "nomeStraniero": null,
      "codiceCatastale": "I684",
      "cap": "50019",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "urp@comune.sesto-fiorentino.fi.it",
      "pec": "protocollo@pec.sesto-fiorentino.net",
      "telefono": "+39 055 44961",
      "fax": "+39 055 4496362",
      "coordinate": {
        "lat": 43.83333206176758,
        "lng": 11.199999809265137
      }
    },
    {
      "codice": "048044",
      "nome": "Signa",
      "nomeStraniero": null,
      "codiceCatastale": "I728",
      "cap": "50058",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "comuni@comune.signa.fi.it",
      "pec": "comune.signa@postacert.toscana.it",
      "telefono": "+39 05587941",
      "fax": "+39 05532316",
      "coordinate": {
        "lat": 43.78333282470703,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "048046",
      "nome": "Vaglia",
      "nomeStraniero": null,
      "codiceCatastale": "L529",
      "cap": "50036",
      "prefisso": "055",
      "provincia": "Firenze",
      "email": "affarigenerali@comune.vaglia.firenze.it",
      "pec": "comune.vaglia@postacert.toscana.it",
      "telefono": "+39 055/500241",
      "fax": "+39 055/407545",
      "coordinate": {
        "lat": 43.910701751708984,
        "lng": 11.279970169067383
      }
    },
    {
      "codice": "048049",
      "nome": "Vicchio",
      "nomeStraniero": null,
      "codiceCatastale": "L838",
      "cap": "50039",
      "prefisso": "05584",
      "provincia": "Firenze",
      "email": "comune@comune.vicchio.fi.it",
      "pec": "comune.vicchio@postacert.toscana.it",
      "telefono": "+39 055 843921",
      "fax": "+39 055 844275",
      "coordinate": {
        "lat": 43.93333435058594,
        "lng": 11.466666221618652
      }
    },
    {
      "codice": "048050",
      "nome": "Vinci",
      "nomeStraniero": null,
      "codiceCatastale": "M059",
      "cap": "50059",
      "prefisso": "0571",
      "provincia": "Firenze",
      "email": "vinci@comune.vinci.fi.it",
      "pec": "comune.vinci@postacert.toscana.it",
      "telefono": "+39 0571 9331",
      "fax": "+39 0571/56388",
      "coordinate": {
        "lat": 43.78333282470703,
        "lng": 10.916666984558105
      }
    }
  ],
  "Forlì-Cesena": [
    {
      "codice": "040001",
      "nome": "Bagno di Romagna",
      "nomeStraniero": null,
      "codiceCatastale": "A565",
      "cap": "47021",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "anagrafe@comune.bagnodiromagna.fc.it",
      "pec": "comune.bagnodiromagna@cert.provincia.fc.it",
      "telefono": "+39 0543/900411",
      "fax": "+39 0543/903032",
      "coordinate": {
        "lat": 43.83333206176758,
        "lng": 11.966666221618652
      }
    },
    {
      "codice": "040003",
      "nome": "Bertinoro",
      "nomeStraniero": null,
      "codiceCatastale": "A809",
      "cap": "47032",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "protocollo@comune.bertinoro.fc.it",
      "pec": "protocollo@pec.comune.bertinoro.fc.it",
      "telefono": "+39 0543/469111",
      "fax": "+39 0543/444486",
      "coordinate": {
        "lat": 44.150001525878906,
        "lng": 12.133333206176758
      }
    },
    {
      "codice": "040004",
      "nome": "Borghi",
      "nomeStraniero": null,
      "codiceCatastale": "B001",
      "cap": "47030",
      "prefisso": "0541",
      "provincia": "Forlì-Cesena",
      "email": "protocollo@comune.borghi.fc.it",
      "pec": "comune.borghi@cert.provincia.fc.it",
      "telefono": "+39 0541947405",
      "fax": "+39 0541947612",
      "coordinate": {
        "lat": 44.032230377197266,
        "lng": 12.356180191040039
      }
    },
    {
      "codice": "040005",
      "nome": "Castrocaro Terme e Terra del Sole",
      "nomeStraniero": null,
      "codiceCatastale": "C339",
      "cap": "47011",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "segreteria-protocollo@comune.castrocarotermeeterradelsole.fc.it",
      "pec": "protocollo@pec.comune.castrocarotermeeterradelsole.fc.it",
      "telefono": "+39 0543/767101",
      "fax": "+39 0543/769326",
      "coordinate": {
        "lat": 44.184165954589844,
        "lng": 11.943888664245605
      }
    },
    {
      "codice": "040007",
      "nome": "Cesena",
      "nomeStraniero": null,
      "codiceCatastale": "C573",
      "cap": "47521",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "demografici@comune.cesena.fc.it",
      "pec": "protocollo@pec.comune.cesena.fc.it",
      "telefono": "+39 0547/356111",
      "fax": "+39 0547/356485",
      "coordinate": {
        "lat": 44.13333511352539,
        "lng": 12.233333587646484
      }
    },
    {
      "codice": "040008",
      "nome": "Cesenatico",
      "nomeStraniero": null,
      "codiceCatastale": "C574",
      "cap": "47042",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "segreteria-sindaco@comune.cesenatico.fo.it",
      "pec": "cesenatico@cert.provincia.fc.it",
      "telefono": "+39 0547/79111",
      "fax": "+39 0547/83820",
      "coordinate": {
        "lat": 44.20119094848633,
        "lng": 12.400689125061035
      }
    },
    {
      "codice": "040009",
      "nome": "Civitella di Romagna",
      "nomeStraniero": null,
      "codiceCatastale": "C777",
      "cap": "47012",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "sindaco@comune.civitella-di-romagna.fc.it",
      "pec": "comune.civitella@cert.provincia.fc.it",
      "telefono": "+39 0543/984311",
      "fax": "+39 0543/983810",
      "coordinate": {
        "lat": 44,
        "lng": 11.933333396911621
      }
    },
    {
      "codice": "040011",
      "nome": "Dovadola",
      "nomeStraniero": null,
      "codiceCatastale": "D357",
      "cap": "47013",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "r.difazio@comune.dovadola.fc.it",
      "pec": "dovadola@cert.provincia.fc.it",
      "telefono": "+39 0543/934764",
      "fax": "+39 0543/934703",
      "coordinate": {
        "lat": 44.11666488647461,
        "lng": 11.883333206176758
      }
    },
    {
      "codice": "040012",
      "nome": "Forlì",
      "nomeStraniero": null,
      "codiceCatastale": "D704",
      "cap": "47121",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "urp@comune.forli.fc.it",
      "pec": "comune.forli@pec.comune.forli.fc.it",
      "telefono": "+39 0543712111",
      "fax": "+39 054330892",
      "coordinate": {
        "lat": 44.22249984741211,
        "lng": 12.040833473205566
      }
    },
    {
      "codice": "040013",
      "nome": "Forlimpopoli",
      "nomeStraniero": null,
      "codiceCatastale": "D705",
      "cap": "47034",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "segreteria@comune.forlimpopoli.fc.it",
      "pec": "protocollo@pec.comune.forlimpopoli.fc.it",
      "telefono": "+39 0543/749111",
      "fax": "+39 0543/749228",
      "coordinate": {
        "lat": 44.18333435058594,
        "lng": 12.133333206176758
      }
    },
    {
      "codice": "040014",
      "nome": "Galeata",
      "nomeStraniero": null,
      "codiceCatastale": "D867",
      "cap": "47010",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "protocollo@comune.galeata.fc.it",
      "pec": "galeata@cert.provincia.fc.it",
      "telefono": "+39 0543/975411",
      "fax": "+39 0543/981021",
      "coordinate": {
        "lat": 44,
        "lng": 11.916666984558105
      }
    },
    {
      "codice": "040015",
      "nome": "Gambettola",
      "nomeStraniero": null,
      "codiceCatastale": "D899",
      "cap": "47035",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "laura.maestri@comune.gambettola.fc.it",
      "pec": "comune.gambettola@cert.provincia.fc.it",
      "telefono": "0547 45306",
      "fax": null,
      "coordinate": {
        "lat": 44.11666488647461,
        "lng": 12.333333015441895
      }
    },
    {
      "codice": "040016",
      "nome": "Gatteo",
      "nomeStraniero": null,
      "codiceCatastale": "D935",
      "cap": "47043",
      "prefisso": "0541",
      "provincia": "Forlì-Cesena",
      "email": "cono.manzolillo@comune.gatteo.fc.it",
      "pec": "gatteo@cert.provincia.fc.it",
      "telefono": "+39 0541/935507",
      "fax": "+39 0541/933344",
      "coordinate": {
        "lat": 44.099998474121094,
        "lng": 12.383333206176758
      }
    },
    {
      "codice": "040018",
      "nome": "Longiano",
      "nomeStraniero": null,
      "codiceCatastale": "E675",
      "cap": "47020",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "anagrafe@comune.longiano.fc.it",
      "pec": "comune.longiano@cert.provincia.fc.it",
      "telefono": "+39 0547666411",
      "fax": "+39 0547665860",
      "coordinate": {
        "lat": 44.08333206176758,
        "lng": 12.333333015441895
      }
    },
    {
      "codice": "040019",
      "nome": "Meldola",
      "nomeStraniero": null,
      "codiceCatastale": "F097",
      "cap": "47014",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "segreteria@comune.meldola.fo.it",
      "pec": "comune.meldola@cert.provincia.fc.it",
      "telefono": "+39 0543/499411",
      "fax": "+39 0543/490353",
      "coordinate": {
        "lat": 44.13333511352539,
        "lng": 12.066666603088379
      }
    },
    {
      "codice": "040020",
      "nome": "Mercato Saraceno",
      "nomeStraniero": null,
      "codiceCatastale": "F139",
      "cap": "47025",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "anagrafe@comune.mercatosaraceno.fc.it",
      "pec": "mercatosaraceno@cert.provincia.fc.it",
      "telefono": "+39 0547/699711",
      "fax": "+39 0547/90141",
      "coordinate": {
        "lat": 43.95000076293945,
        "lng": 12.199999809265137
      }
    },
    {
      "codice": "040022",
      "nome": "Modigliana",
      "nomeStraniero": null,
      "codiceCatastale": "F259",
      "cap": "47015",
      "prefisso": "0546",
      "provincia": "Forlì-Cesena",
      "email": "camilla.pesci@comune.modigliana.fc.it",
      "pec": "modigliana@cert.provincia.fc.it",
      "telefono": "+39 0546949534",
      "fax": "+39 0546949540",
      "coordinate": {
        "lat": 44.16666793823242,
        "lng": 11.800000190734863
      }
    },
    {
      "codice": "040028",
      "nome": "Montiano",
      "nomeStraniero": null,
      "codiceCatastale": "F668",
      "cap": "47020",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "protocollo@comune.montiano.fc.it",
      "pec": "montiano@pec.unionevallesavio.it",
      "telefono": "+39 0547/51032",
      "fax": "+39 0547/51160",
      "coordinate": {
        "lat": 44.08333206176758,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "040031",
      "nome": "Portico e San Benedetto",
      "nomeStraniero": null,
      "codiceCatastale": "G904",
      "cap": "47010",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "serena.verdi@comune.portico-e-san-benedetto.fc.it",
      "pec": "porticoesanbenedetto@cert.provincia.fc.it",
      "telefono": "+39 0543/967047",
      "fax": "+39 0543/967243",
      "coordinate": {
        "lat": 44.03333282470703,
        "lng": 11.783333778381348
      }
    },
    {
      "codice": "040032",
      "nome": "Predappio",
      "nomeStraniero": null,
      "codiceCatastale": "H017",
      "cap": "47016",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "sindaco@comune.predappio.fo.it",
      "pec": "comune.predappio@cert.provincia.fc.it",
      "telefono": "+39 0543/921711",
      "fax": "+39 0543/923417",
      "coordinate": {
        "lat": 44.10416793823242,
        "lng": 11.984999656677246
      }
    },
    {
      "codice": "040033",
      "nome": "Premilcuore",
      "nomeStraniero": null,
      "codiceCatastale": "H034",
      "cap": "47010",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "proto@comune.premilcuore.fc.it",
      "pec": "comune.premilcuore@cert.provincia.fc.it",
      "telefono": "+39 0543/956945",
      "fax": "+39 0543/956557",
      "coordinate": {
        "lat": 43.9827766418457,
        "lng": 11.784600257873535
      }
    },
    {
      "codice": "040036",
      "nome": "Rocca San Casciano",
      "nomeStraniero": null,
      "codiceCatastale": "H437",
      "cap": "47017",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "marina.frassineti@comune.roccasancasciano.fc.it",
      "pec": "comune.roccasancasciano@cert.provincia.fc.it",
      "telefono": "+39 0543960124",
      "fax": "+39 0543951336",
      "coordinate": {
        "lat": 44.06666564941406,
        "lng": 11.850000381469727
      }
    },
    {
      "codice": "040037",
      "nome": "Roncofreddo",
      "nomeStraniero": null,
      "codiceCatastale": "H542",
      "cap": "47020",
      "prefisso": "0541",
      "provincia": "Forlì-Cesena",
      "email": "sindaco@comune.roncofreddo.fc.it",
      "pec": "roncofreddo@cert.provincia.fc.it",
      "telefono": "+39 0541/949211",
      "fax": "+39 0541/949444",
      "coordinate": {
        "lat": 44.042301177978516,
        "lng": 12.318699836730957
      }
    },
    {
      "codice": "040041",
      "nome": "San Mauro Pascoli",
      "nomeStraniero": null,
      "codiceCatastale": "I027",
      "cap": "47030",
      "prefisso": "0541",
      "provincia": "Forlì-Cesena",
      "email": "iglis.bellavista@comune.sanmauropascoli.fc.it",
      "pec": "comune.sanmauropascoli@cert.provincia.fc.it",
      "telefono": "+39 0541/936009",
      "fax": "+39 0541/933350",
      "coordinate": {
        "lat": 44.099998474121094,
        "lng": 12.416666984558105
      }
    },
    {
      "codice": "040043",
      "nome": "Santa Sofia",
      "nomeStraniero": null,
      "codiceCatastale": "I310",
      "cap": "47018",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "betti.t@comune.santa-sofia.fc.it",
      "pec": "comune.santa-sofia@cert.provincia.fc.it",
      "telefono": "+39 0543/974517",
      "fax": "+39 0543/970345",
      "coordinate": {
        "lat": 43.95000076293945,
        "lng": 11.899999618530273
      }
    },
    {
      "codice": "040044",
      "nome": "Sarsina",
      "nomeStraniero": null,
      "codiceCatastale": "I444",
      "cap": "47027",
      "prefisso": "0547",
      "provincia": "Forlì-Cesena",
      "email": "info@comune.sarsina.fc.it",
      "pec": "sarsina@pec.unionevallesavio.it",
      "telefono": "+39 054794901",
      "fax": "+39 054795384",
      "coordinate": {
        "lat": 43.91666793823242,
        "lng": 12.149999618530273
      }
    },
    {
      "codice": "040045",
      "nome": "Savignano sul Rubicone",
      "nomeStraniero": null,
      "codiceCatastale": "I472",
      "cap": "47039",
      "prefisso": "0541",
      "provincia": "Forlì-Cesena",
      "email": "segretario@comune.savignano-sul-rubicone.fc.it",
      "pec": "savignano@cert.provincia.fc.it",
      "telefono": "+39 0541809611",
      "fax": "+39 0541941052",
      "coordinate": {
        "lat": 44.08805465698242,
        "lng": 12.393333435058594
      }
    },
    {
      "codice": "040046",
      "nome": "Sogliano al Rubicone",
      "nomeStraniero": null,
      "codiceCatastale": "I779",
      "cap": "47030",
      "prefisso": "0541",
      "provincia": "Forlì-Cesena",
      "email": "segreteria@comune.sogliano.fc.it",
      "pec": "comune.sogliano@cert.provincia.fc.it",
      "telefono": "+39 0541/817311",
      "fax": "+39 0541/948866",
      "coordinate": {
        "lat": 44,
        "lng": 12.300000190734863
      }
    },
    {
      "codice": "040049",
      "nome": "Tredozio",
      "nomeStraniero": null,
      "codiceCatastale": "L361",
      "cap": "47019",
      "prefisso": "0546",
      "provincia": "Forlì-Cesena",
      "email": "segreteria@comune.tredozio.fc.it",
      "pec": "tredozio@cert.provincia.fc.it",
      "telefono": "+39 0546/943937",
      "fax": "+39 0546/943921",
      "coordinate": {
        "lat": 44.08333206176758,
        "lng": 11.75
      }
    },
    {
      "codice": "040050",
      "nome": "Verghereto",
      "nomeStraniero": null,
      "codiceCatastale": "L764",
      "cap": "47028",
      "prefisso": "0543",
      "provincia": "Forlì-Cesena",
      "email": "segreteria@comune.verghereto.fc.it",
      "pec": "verghereto@pec.unionevallesavio.it",
      "telefono": "+39 0543/902313",
      "fax": "+39 0543/902377",
      "coordinate": {
        "lat": 43.79999923706055,
        "lng": 11.966666221618652
      }
    }
  ],
  "Modena": [
    {
      "codice": "036001",
      "nome": "Bastiglia",
      "nomeStraniero": null,
      "codiceCatastale": "A713",
      "cap": "41030",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "anagrafe@comune.bastiglia.mo.it",
      "pec": "comunedibastiglia@cert.comune.bastiglia.mo.it",
      "telefono": "+39 059/800911",
      "fax": "+39 059/815132",
      "coordinate": {
        "lat": 44.72697067260742,
        "lng": 10.998600006103516
      }
    },
    {
      "codice": "036002",
      "nome": "Bomporto",
      "nomeStraniero": null,
      "codiceCatastale": "A959",
      "cap": "41030",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "anagrafe@comune.bomporto.mo.it",
      "pec": "comunedibomporto@cert.comune.bomporto.mo.it",
      "telefono": "+39 059/800711",
      "fax": "+39 059/800742",
      "coordinate": {
        "lat": 44.733333587646484,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "036003",
      "nome": "Campogalliano",
      "nomeStraniero": null,
      "codiceCatastale": "B539",
      "cap": "41011",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "protocollo@comune.campogalliano.mo.it",
      "pec": "protocollo@cert.comune.campogalliano.mo.it",
      "telefono": "+39 059899411",
      "fax": "+39 059899430",
      "coordinate": {
        "lat": 44.69037628173828,
        "lng": 10.838896751403809
      }
    },
    {
      "codice": "036004",
      "nome": "Camposanto",
      "nomeStraniero": null,
      "codiceCatastale": "B566",
      "cap": "41031",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "ced@unioneareanord.mo.it",
      "pec": "comunecamposanto@cert.comune.camposanto.mo.it",
      "telefono": "+39 0535 29730",
      "fax": "+39 0535 29744",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 11.133333206176758
      }
    },
    {
      "codice": "036005",
      "nome": "Carpi",
      "nomeStraniero": null,
      "codiceCatastale": "B819",
      "cap": "41012",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "patrizia.mantovani@comune.carpi.mo.it",
      "pec": "direttore.generale@pec.comune.carpi.mo.it",
      "telefono": "+39 059/649851",
      "fax": "+39 059/649751",
      "coordinate": {
        "lat": 44.78333282470703,
        "lng": 10.885000228881836
      }
    },
    {
      "codice": "036006",
      "nome": "Castelfranco Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "C107",
      "cap": "41013",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "sirico.corrado@comune.castelfranco-emilia.mo.it",
      "pec": "comunecastelfrancoemilia@cert.comune.castelfranco-emilia.mo.it",
      "telefono": "+39 059/959291",
      "fax": "+39 059/920560",
      "coordinate": {
        "lat": 44.5966682434082,
        "lng": 11.052778244018555
      }
    },
    {
      "codice": "036007",
      "nome": "Castelnuovo Rangone",
      "nomeStraniero": null,
      "codiceCatastale": "C242",
      "cap": "41051",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "v.valentini@comune.castelnuovo-rangone.mo.it",
      "pec": "comune.castelnuovo@cert.unione.terredicastelli.mo.it",
      "telefono": "+39 059/534804",
      "fax": "+39 059/534900",
      "coordinate": {
        "lat": 44.551944732666016,
        "lng": 10.935832977294922
      }
    },
    {
      "codice": "036008",
      "nome": "Castelvetro di Modena",
      "nomeStraniero": null,
      "codiceCatastale": "C287",
      "cap": "41014",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "giorgia.baccolini@terredicastelli.modena.it",
      "pec": "comune.castelvetro@cert.unione.terredicastelli.mo.it",
      "telefono": "+39 3292103756",
      "fax": "+39 059/758848",
      "coordinate": {
        "lat": 44.50666809082031,
        "lng": 10.947221755981445
      }
    },
    {
      "codice": "036009",
      "nome": "Cavezzo",
      "nomeStraniero": null,
      "codiceCatastale": "C398",
      "cap": "41032",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "mirko.franciosi@comune.mirandola.mo.it",
      "pec": "comunecavezzo@cert.comune.cavezzo.mo.it",
      "telefono": "+39 053529730",
      "fax": "+39 0535/29744",
      "coordinate": {
        "lat": 44.83702850341797,
        "lng": 11.030710220336914
      }
    },
    {
      "codice": "036010",
      "nome": "Concordia sulla Secchia",
      "nomeStraniero": null,
      "codiceCatastale": "C951",
      "cap": "41033",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": null,
      "pec": "comuneconcordia@cert.comune.concordia.mo.it",
      "telefono": "+39 0535412911",
      "fax": "+39 0535412912",
      "coordinate": {
        "lat": 44.91238021850586,
        "lng": 10.982560157775879
      }
    },
    {
      "codice": "036011",
      "nome": "Fanano",
      "nomeStraniero": null,
      "codiceCatastale": "D486",
      "cap": "41021",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "anagrafe@comune.fanano.mo.it",
      "pec": "comune@cert.comune.fanano.mo.it",
      "telefono": "+39 0536 68803",
      "fax": "+39 0536 68954",
      "coordinate": {
        "lat": 44.21244812011719,
        "lng": 10.796600341796875
      }
    },
    {
      "codice": "036012",
      "nome": "Finale Emilia",
      "nomeStraniero": null,
      "codiceCatastale": "D599",
      "cap": "41034",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "comune@comune.finale-emilia.mo.it",
      "pec": "comunefinale@cert.comune.finale-emilia.mo.it",
      "telefono": "+39 0535/788111",
      "fax": "+39 0535/788130",
      "coordinate": {
        "lat": 44.83184814453125,
        "lng": 11.295740127563477
      }
    },
    {
      "codice": "036013",
      "nome": "Fiorano Modenese",
      "nomeStraniero": null,
      "codiceCatastale": "D607",
      "cap": "41042",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "info@comune.fiorano-modenese.mo.it",
      "pec": "comunefiorano@cert.fiorano.it",
      "telefono": "+39 0536/833111",
      "fax": "+39 0536/833210",
      "coordinate": {
        "lat": 44.539188385009766,
        "lng": 10.81173038482666
      }
    },
    {
      "codice": "036014",
      "nome": "Fiumalbo",
      "nomeStraniero": null,
      "codiceCatastale": "D617",
      "cap": "41022",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "info@comune.fiumalbo.mo.it",
      "pec": "comune@cert.comune.fiumalbo.mo.it",
      "telefono": "+39 0536 73922",
      "fax": "+39 0536 73074",
      "coordinate": {
        "lat": 44.17995071411133,
        "lng": 10.647732734680176
      }
    },
    {
      "codice": "036015",
      "nome": "Formigine",
      "nomeStraniero": null,
      "codiceCatastale": "D711",
      "cap": "41043",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "urp@comune.formigine.mo.it",
      "pec": "comune.formigine@cert.comune.formigine.mo.it",
      "telefono": "+39 059/416111",
      "fax": "+39 059/416226",
      "coordinate": {
        "lat": 44.573890686035156,
        "lng": 10.847777366638184
      }
    },
    {
      "codice": "036016",
      "nome": "Frassinoro",
      "nomeStraniero": null,
      "codiceCatastale": "D783",
      "cap": "41040",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "alba.bianchi@comune.frassinoro.mo.it",
      "pec": "comune.frassinoro@pec.it",
      "telefono": "+39 0536971811",
      "fax": "+39 0536971002",
      "coordinate": {
        "lat": 44.296390533447266,
        "lng": 10.571110725402832
      }
    },
    {
      "codice": "036017",
      "nome": "Guiglia",
      "nomeStraniero": null,
      "codiceCatastale": "E264",
      "cap": "41052",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "guiglia@comune.guiglia.mo.it",
      "pec": "comuneguiglia@cert.comune.guiglia.mo.it",
      "telefono": "+39 059/709911",
      "fax": "+39 059/709911",
      "coordinate": {
        "lat": 44.42499923706055,
        "lng": 10.963055610656738
      }
    },
    {
      "codice": "036018",
      "nome": "Lama Mocogno",
      "nomeStraniero": null,
      "codiceCatastale": "E426",
      "cap": "41023",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "segreteria@comune.lamamocogno.mo.it",
      "pec": "comune@cert.comune.lamamocogno.mo.it",
      "telefono": "+39 0536 44003",
      "fax": "+39 0536 44890",
      "coordinate": {
        "lat": 44.3072624206543,
        "lng": 10.73177719116211
      }
    },
    {
      "codice": "036019",
      "nome": "Maranello",
      "nomeStraniero": null,
      "codiceCatastale": "E904",
      "cap": "41053",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": null,
      "pec": "comune.maranello@cert.comune.maranello.mo.it",
      "telefono": "+39 0536/240011",
      "fax": "+39 0536/942263",
      "coordinate": {
        "lat": 44.52555465698242,
        "lng": 10.866389274597168
      }
    },
    {
      "codice": "036020",
      "nome": "Marano sul Panaro",
      "nomeStraniero": null,
      "codiceCatastale": "E905",
      "cap": "41054",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "elisabetta.manzini@comune.marano.mo.it",
      "pec": "comune.marano@cert.comune.marano-sul-panaro.mo.it",
      "telefono": "+39 059 705711",
      "fax": "+39 059 705158",
      "coordinate": {
        "lat": 44.45722198486328,
        "lng": 10.971944808959961
      }
    },
    {
      "codice": "036021",
      "nome": "Medolla",
      "nomeStraniero": null,
      "codiceCatastale": "F087",
      "cap": "41036",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "mirko.franciosi",
      "pec": "comunemedolla@cert.comune.medolla.mo.it",
      "telefono": "+39 053529730",
      "fax": "+39 0535/29744",
      "coordinate": {
        "lat": 44.848838806152344,
        "lng": 11.071359634399414
      }
    },
    {
      "codice": "036022",
      "nome": "Mirandola",
      "nomeStraniero": null,
      "codiceCatastale": "F240",
      "cap": "41037",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "ced@unioneareanord.mo.it",
      "pec": "comune.mirandola@cert.comune.mirandola.mo.it",
      "telefono": "+39 053529750",
      "fax": "+39 0535-29538",
      "coordinate": {
        "lat": 44.887271881103516,
        "lng": 11.06602954864502
      }
    },
    {
      "codice": "036023",
      "nome": "Modena",
      "nomeStraniero": null,
      "codiceCatastale": "F257",
      "cap": "41121",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "segreteria.particolare@comune.modena.it",
      "pec": "comune.modena@cert.comune.modena.it",
      "telefono": "+39 059/2032635",
      "fax": "+39 059/2032560",
      "coordinate": {
        "lat": 44.64582061767578,
        "lng": 10.92572021484375
      }
    },
    {
      "codice": "036024",
      "nome": "Montecreto",
      "nomeStraniero": null,
      "codiceCatastale": "F484",
      "cap": "41025",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": null,
      "pec": "comune@cert.comune.montecreto.mo.it",
      "telefono": "0536/63722",
      "fax": "+39 0536/63470",
      "coordinate": {
        "lat": 44.25,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "036025",
      "nome": "Montefiorino",
      "nomeStraniero": null,
      "codiceCatastale": "F503",
      "cap": "41045",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "segreteria@comune.montefiorino.mo.it",
      "pec": "comune.montefiorino@pec.it",
      "telefono": "+39 0536 962811",
      "fax": "+39 0536 965535",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 10.616666793823242
      }
    },
    {
      "codice": "036026",
      "nome": "Montese",
      "nomeStraniero": null,
      "codiceCatastale": "F642",
      "cap": "41055",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "bernardoni.g@comune.montese.mo.it",
      "pec": "comunemontese@cert.comune.montese.mo.it",
      "telefono": "+39 059/971108",
      "fax": "+39 059/971100",
      "coordinate": {
        "lat": 44.26944351196289,
        "lng": 10.945833206176758
      }
    },
    {
      "codice": "036027",
      "nome": "Nonantola",
      "nomeStraniero": null,
      "codiceCatastale": "F930",
      "cap": "41015",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "info@comune.nonantola.mo.it",
      "pec": "comune.nonantola@cert.comune.nonantola.mo.it",
      "telefono": "+39 059/896511",
      "fax": "+39 059/896590",
      "coordinate": {
        "lat": 44.677669525146484,
        "lng": 11.043006896972656
      }
    },
    {
      "codice": "036028",
      "nome": "Novi di Modena",
      "nomeStraniero": null,
      "codiceCatastale": "F966",
      "cap": "41016",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "affarigenerali@comune.novi.mo.it",
      "pec": "comune.novidimodena@cert.comune.novi.mo.it",
      "telefono": "+39 059/6789211",
      "fax": "+39 059/6789290",
      "coordinate": {
        "lat": 44.89336013793945,
        "lng": 10.901009559631348
      }
    },
    {
      "codice": "036029",
      "nome": "Palagano",
      "nomeStraniero": null,
      "codiceCatastale": "G250",
      "cap": "41046",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "emanuela.ferrarini@comune.palagano.mo.it",
      "pec": "comune.palagano@pec.it",
      "telefono": "+39 0536/970912",
      "fax": "+39 0536/970901",
      "coordinate": {
        "lat": 44.3216667175293,
        "lng": 10.646944046020508
      }
    },
    {
      "codice": "036030",
      "nome": "Pavullo nel Frignano",
      "nomeStraniero": null,
      "codiceCatastale": "G393",
      "cap": "41026",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "mc.gandolfi@comune.pavullo-nel-frignano.mo.it",
      "pec": "comune.pavullo@cert.comune.pavullo-nel-frignano.mo.it",
      "telefono": "+39 0536 29918",
      "fax": "+39 0536/20125",
      "coordinate": {
        "lat": 44.33270263671875,
        "lng": 10.833118438720703
      }
    },
    {
      "codice": "036031",
      "nome": "Pievepelago",
      "nomeStraniero": null,
      "codiceCatastale": "G649",
      "cap": "41027",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "servizidemografici@comune.pievepelago.mo.it",
      "pec": "comune@cert.comune.pievepelago.mo.it",
      "telefono": "+39 0536/71322",
      "fax": "+39 0536/72025",
      "coordinate": {
        "lat": 44.212562561035156,
        "lng": 10.617389678955078
      }
    },
    {
      "codice": "036032",
      "nome": "Polinago",
      "nomeStraniero": null,
      "codiceCatastale": "G789",
      "cap": "41040",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "servizio.finanziario@comune.polinago.mo.it",
      "pec": "comune@cert.comune.polinago.mo.it",
      "telefono": "+39 0536/47000",
      "fax": "+39 0536/47672",
      "coordinate": {
        "lat": 44.349998474121094,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "036033",
      "nome": "Prignano sulla Secchia",
      "nomeStraniero": null,
      "codiceCatastale": "H061",
      "cap": "41048",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "demografici@comune.prignano.mo.it",
      "pec": "comune.prignano@pec.it",
      "telefono": "+39 0536/892906",
      "fax": "+39 0536/893227",
      "coordinate": {
        "lat": 44.43694305419922,
        "lng": 10.69083309173584
      }
    },
    {
      "codice": "036034",
      "nome": "Ravarino",
      "nomeStraniero": null,
      "codiceCatastale": "H195",
      "cap": "41017",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "marialetizia.oliva@comune.ravarino.mo.it",
      "pec": "comunediravarino@cert.comune.ravarino.mo.it",
      "telefono": "+39 059 059 800800",
      "fax": "+39 059 900200",
      "coordinate": {
        "lat": 44.71666717529297,
        "lng": 11.100000381469727
      }
    },
    {
      "codice": "036035",
      "nome": "Riolunato",
      "nomeStraniero": null,
      "codiceCatastale": "H303",
      "cap": "41020",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "segreteria@comune.riolunato.mo.it",
      "pec": "comune@cert.comune.riolunato.mo.it",
      "telefono": "+39 0536/75119",
      "fax": "+39 0536/75324",
      "coordinate": {
        "lat": 44.233333587646484,
        "lng": 10.649999618530273
      }
    },
    {
      "codice": "036036",
      "nome": "San Cesario sul Panaro",
      "nomeStraniero": null,
      "codiceCatastale": "H794",
      "cap": "41018",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "info@comune.sancesariosulpanaro.mo.it",
      "pec": "pec@cert.comune.sancesariosulpanaro.mo.it",
      "telefono": "+39 059 936 711",
      "fax": "+39 059 936 706",
      "coordinate": {
        "lat": 44.5613899230957,
        "lng": 11.033888816833496
      }
    },
    {
      "codice": "036037",
      "nome": "San Felice sul Panaro",
      "nomeStraniero": null,
      "codiceCatastale": "H835",
      "cap": "41038",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "ced@unioneareanord.mo.it",
      "pec": "comunesanfelice@cert.comunesanfelice.net",
      "telefono": "+39 0535 29730",
      "fax": "+39 0535/29744",
      "coordinate": {
        "lat": 44.83933639526367,
        "lng": 11.141311645507812
      }
    },
    {
      "codice": "036038",
      "nome": "San Possidonio",
      "nomeStraniero": null,
      "codiceCatastale": "I128",
      "cap": "41039",
      "prefisso": "0535",
      "provincia": "Modena",
      "email": "info@comune.sanpossidonio.mo.it",
      "pec": "comunesanpossidonio@cert.comune.sanpossidonio.mo.it",
      "telefono": "+39 0535 417911",
      "fax": "+39 0535 417955",
      "coordinate": {
        "lat": 44.891666412353516,
        "lng": 10.99638843536377
      }
    },
    {
      "codice": "036039",
      "nome": "San Prospero",
      "nomeStraniero": null,
      "codiceCatastale": "I133",
      "cap": "41030",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "urp@comune.sanprospero.mo.it",
      "pec": "comunesanprospero@cert.comune.sanprospero.mo.it",
      "telefono": "+39 059/809722",
      "fax": "+39 059/906450",
      "coordinate": {
        "lat": 44.78792953491211,
        "lng": 11.022749900817871
      }
    },
    {
      "codice": "036040",
      "nome": "Sassuolo",
      "nomeStraniero": null,
      "codiceCatastale": "I462",
      "cap": "41049",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "cannovi@comune.sassuolo.mo.it",
      "pec": "comune.sassuolo@cert.comune.sassuolo.mo.it",
      "telefono": "+39 0536/1844752",
      "fax": "+39 0536/1844921",
      "coordinate": {
        "lat": 44.551666259765625,
        "lng": 10.785555839538574
      }
    },
    {
      "codice": "036041",
      "nome": "Savignano sul Panaro",
      "nomeStraniero": null,
      "codiceCatastale": "I473",
      "cap": "41056",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "giulia.macchi@terredicastelli.modena.it",
      "pec": "comune.savignano@cert.unione.terredicastelli.mo.it",
      "telefono": "+39 059/777516",
      "fax": "+39 059/730160",
      "coordinate": {
        "lat": 44.483333587646484,
        "lng": 11.033333778381348
      }
    },
    {
      "codice": "036042",
      "nome": "Serramazzoni",
      "nomeStraniero": null,
      "codiceCatastale": "F357",
      "cap": "41028",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "ufficio.informatica@comune.serramazzoni.mo.it",
      "pec": "comune@cert.comune.serramazzoni.mo.it",
      "telefono": "+39 0536/952199",
      "fax": "+39 0536/954665",
      "coordinate": {
        "lat": 44.41666793823242,
        "lng": 10.800000190734863
      }
    },
    {
      "codice": "036043",
      "nome": "Sestola",
      "nomeStraniero": null,
      "codiceCatastale": "I689",
      "cap": "41029",
      "prefisso": "0536",
      "provincia": "Modena",
      "email": "patrimonio@comune.sestola.mo.it",
      "pec": "comune@cert.comune.sestola.mo.it",
      "telefono": "+39 3332946226",
      "fax": "+39 0536/61330",
      "coordinate": {
        "lat": 44.233333587646484,
        "lng": 10.766666412353516
      }
    },
    {
      "codice": "036044",
      "nome": "Soliera",
      "nomeStraniero": null,
      "codiceCatastale": "I802",
      "cap": "41019",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "info@comune.soliera.mo.it",
      "pec": "protocollo@pec.comune.soliera.mo.it",
      "telefono": "+39 059/568511",
      "fax": "+39 059/565562",
      "coordinate": {
        "lat": 44.73805618286133,
        "lng": 10.924545288085938
      }
    },
    {
      "codice": "036045",
      "nome": "Spilamberto",
      "nomeStraniero": null,
      "codiceCatastale": "I903",
      "cap": "41057",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "giorgia.baccolini@comune.spilamberto.mo.it",
      "pec": "comune.spilamberto@cert.unione.terredicastelli.mo.it",
      "telefono": "+39 059/789911",
      "fax": "+39 059/781174",
      "coordinate": {
        "lat": 44.53333282470703,
        "lng": 11.016666412353516
      }
    },
    {
      "codice": "036046",
      "nome": "Vignola",
      "nomeStraniero": null,
      "codiceCatastale": "L885",
      "cap": "41058",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "info@comune.vignola.mo.it",
      "pec": "comune.vignola@cert.unione.terredicastelli.mo.it",
      "telefono": "+39 059 777511",
      "fax": "+39 059 764129",
      "coordinate": {
        "lat": 44.4808349609375,
        "lng": 11.002222061157227
      }
    },
    {
      "codice": "036047",
      "nome": "Zocca",
      "nomeStraniero": null,
      "codiceCatastale": "M183",
      "cap": "41059",
      "prefisso": "059",
      "provincia": "Modena",
      "email": "segretario@comune.zocca.mo.it",
      "pec": "comunezocca@cert.comune.zocca.mo.it",
      "telefono": "+39 059/985740",
      "fax": "+39 059/985700",
      "coordinate": {
        "lat": 44.34726333618164,
        "lng": 10.99040699005127
      }
    }
  ],
  "Rimini": [
    {
      "codice": "099001",
      "nome": "Bellaria-Igea Marina",
      "nomeStraniero": null,
      "codiceCatastale": "A747",
      "cap": "47814",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "urp@comune.bellaria-igea-marina.rn.it",
      "pec": "pec@pec.comune.bellaria-igea-marina.rn.it",
      "telefono": "+39 0541/343711",
      "fax": "+39 0541/345844",
      "coordinate": {
        "lat": 44.150001525878906,
        "lng": 12.449999809265137
      }
    },
    {
      "codice": "099021",
      "nome": "Casteldelci",
      "nomeStraniero": null,
      "codiceCatastale": "C080",
      "cap": "47861",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "anagrafe@comune.casteldelci.rn.it",
      "pec": "protocollo.comune.casteldelci@pec.it",
      "telefono": "+39 0541 915423",
      "fax": "+39 0541 925300",
      "coordinate": {
        "lat": 43.7912712097168,
        "lng": 12.155180931091309
      }
    },
    {
      "codice": "099002",
      "nome": "Cattolica",
      "nomeStraniero": null,
      "codiceCatastale": "C357",
      "cap": "47841",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "info@cattolica.net",
      "pec": "protocollo@comunecattolica.legalmailpa.it",
      "telefono": "+39 0541/966511",
      "fax": "+39 0541/966793",
      "coordinate": {
        "lat": 43.95844268798828,
        "lng": 12.738611221313477
      }
    },
    {
      "codice": "099003",
      "nome": "Coriano",
      "nomeStraniero": null,
      "codiceCatastale": "D004",
      "cap": "47853",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "segreteria@comune.coriano.rn.it",
      "pec": "comune.coriano@legalmail.it",
      "telefono": "+39 0541/659812",
      "fax": "+39 0514689603",
      "coordinate": {
        "lat": 43.969058990478516,
        "lng": 12.600549697875977
      }
    },
    {
      "codice": "099004",
      "nome": "Gemmano",
      "nomeStraniero": null,
      "codiceCatastale": "D961",
      "cap": "47855",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "info@comune.gemmano.rn.it",
      "pec": "comune.gemmano@legalmail.it",
      "telefono": "+39 0541/854060",
      "fax": "+39 0541/854060",
      "coordinate": {
        "lat": 43.900001525878906,
        "lng": 12.583333015441895
      }
    },
    {
      "codice": "099022",
      "nome": "Maiolo",
      "nomeStraniero": null,
      "codiceCatastale": "E838",
      "cap": "47862",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "ragioneria@comunemaiolo.it",
      "pec": "ragioneria@pec.comunemaiolo.it",
      "telefono": "+39 0541 920012",
      "fax": "+39 0541 922777",
      "coordinate": {
        "lat": 43.87403869628906,
        "lng": 12.309389114379883
      }
    },
    {
      "codice": "099005",
      "nome": "Misano Adriatico",
      "nomeStraniero": null,
      "codiceCatastale": "F244",
      "cap": "47843",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "info@comune.misano-adriatico.rn.it",
      "pec": "comune.misanoadriatico@legalmail.it",
      "telefono": "+39 0541/618411",
      "fax": "+39 0541/613774",
      "coordinate": {
        "lat": 43.96666717529297,
        "lng": 12.699999809265137
      }
    },
    {
      "codice": "099006",
      "nome": "Mondaino",
      "nomeStraniero": null,
      "codiceCatastale": "F346",
      "cap": "47836",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "segreteria@mondaino.com",
      "pec": "comune.mondaino@legalmail.it",
      "telefono": "+39 0541/981674",
      "fax": "+39 0541/982060",
      "coordinate": {
        "lat": 43.855899810791016,
        "lng": 12.671480178833008
      }
    },
    {
      "codice": "099030",
      "nome": "Montecopiolo",
      "nomeStraniero": null,
      "codiceCatastale": "F478",
      "cap": "47868",
      "prefisso": "0722",
      "provincia": "Rimini",
      "email": "comune.montecopiolo@provincia.ps.it",
      "pec": "comune.montecopiolo@emarche.it",
      "telefono": "+39 0722/78106",
      "fax": "+39 0722/78544",
      "coordinate": {
        "lat": 43.841209411621094,
        "lng": 12.359700202941895
      }
    },
    {
      "codice": "099008",
      "nome": "Montefiore Conca",
      "nomeStraniero": null,
      "codiceCatastale": "F502",
      "cap": "47834",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "segreteria@comune.montefiore-conca.rn.it",
      "pec": "comune.montefiore-conca@legalmail.it",
      "telefono": "+39 0541/980035",
      "fax": "+39 0541/980206",
      "coordinate": {
        "lat": 43.88333511352539,
        "lng": 12.616666793823242
      }
    },
    {
      "codice": "099009",
      "nome": "Montegridolfo",
      "nomeStraniero": null,
      "codiceCatastale": "F523",
      "cap": "47837",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "comune@montegridolfo.eu",
      "pec": "comune.montegridolfo@legalmail.it",
      "telefono": "+39 0541/855054",
      "fax": "+39 0541/855042",
      "coordinate": {
        "lat": 43.858917236328125,
        "lng": 12.689087867736816
      }
    },
    {
      "codice": "099029",
      "nome": "Montescudo-Monte Colombo",
      "nomeStraniero": null,
      "codiceCatastale": "M368",
      "cap": "47854",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": null,
      "pec": "comune.montescudo-montecolombo@legalmail.it",
      "telefono": "+39 0541 984214",
      "fax": "+39 0541 984705",
      "coordinate": {
        "lat": 43.91666793823242,
        "lng": 12.550000190734863
      }
    },
    {
      "codice": "099011",
      "nome": "Morciano di Romagna",
      "nomeStraniero": null,
      "codiceCatastale": "F715",
      "cap": "47833",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "protocollo@morciano.it",
      "pec": "protocollo@pec.comune.morcianodiromagna.rn.it",
      "telefono": "+39 0541/851911",
      "fax": "+39 0541/987581",
      "coordinate": {
        "lat": 43.91666793823242,
        "lng": 12.649999618530273
      }
    },
    {
      "codice": "099023",
      "nome": "Novafeltria",
      "nomeStraniero": null,
      "codiceCatastale": "F137",
      "cap": "47863",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "municipio@comune.novafeltria.rn.it",
      "pec": "pec.comune.novafeltria@legalmail.it",
      "telefono": "+39 0541 845611",
      "fax": "+39 0541 845601",
      "coordinate": {
        "lat": 43.89537048339844,
        "lng": 12.290419578552246
      }
    },
    {
      "codice": "099024",
      "nome": "Pennabilli",
      "nomeStraniero": null,
      "codiceCatastale": "G433",
      "cap": "47864",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "info@comune.pennabilli.rn.it",
      "pec": "comune.pennabilli.rn@pec.it",
      "telefono": "+39 0541 928411",
      "fax": "+39 0541 928362",
      "coordinate": {
        "lat": 43.819000244140625,
        "lng": 12.266599655151367
      }
    },
    {
      "codice": "099028",
      "nome": "Poggio Torriana",
      "nomeStraniero": null,
      "codiceCatastale": "M324",
      "cap": "47824",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "urp@comune.poggiotorriana.rn.it",
      "pec": "comune.poggiotorriana@legalmail.it",
      "telefono": "+39 0541/629701",
      "fax": "+39 0541/629158",
      "coordinate": {
        "lat": 43.999847412109375,
        "lng": 12.392660140991211
      }
    },
    {
      "codice": "099013",
      "nome": "Riccione",
      "nomeStraniero": null,
      "codiceCatastale": "H274",
      "cap": "47838",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "protocollo@comune.riccione.rn.it",
      "pec": "comune.riccione@legalmail.it",
      "telefono": "+39 0541/608111",
      "fax": "+39 0541/601962",
      "coordinate": {
        "lat": 44,
        "lng": 12.649999618530273
      }
    },
    {
      "codice": "099014",
      "nome": "Rimini",
      "nomeStraniero": null,
      "codiceCatastale": "H294",
      "cap": "47921",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "urp@comune.rimini.it",
      "pec": "protocollo.generale@pec.comune.rimini.it",
      "telefono": "+39 0541704111",
      "fax": "+39 0541704773",
      "coordinate": {
        "lat": 44.059444427490234,
        "lng": 12.568333625793457
      }
    },
    {
      "codice": "099015",
      "nome": "Saludecio",
      "nomeStraniero": null,
      "codiceCatastale": "H724",
      "cap": "47835",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "info@comunesaludecio.it",
      "pec": "comune.saludecio@legalmail.it",
      "telefono": "+39 0541/869701",
      "fax": "+39 0541/981624",
      "coordinate": {
        "lat": 43.87277603149414,
        "lng": 12.668611526489258
      }
    },
    {
      "codice": "099016",
      "nome": "San Clemente",
      "nomeStraniero": null,
      "codiceCatastale": "H801",
      "cap": "47832",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "protocollo@sanclemente.it",
      "pec": "comune.sanclemente@legalmail.it",
      "telefono": "+39 0541/862411",
      "fax": "+39 0541/489941",
      "coordinate": {
        "lat": 43.93388748168945,
        "lng": 12.626944541931152
      }
    },
    {
      "codice": "099017",
      "nome": "San Giovanni in Marignano",
      "nomeStraniero": null,
      "codiceCatastale": "H921",
      "cap": "47842",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "info@marignano.net",
      "pec": "comune.sangiovanniinmarignano@legalmail.it",
      "telefono": "+39 0541/828111",
      "fax": "+39 0541/828182",
      "coordinate": {
        "lat": 43.939300537109375,
        "lng": 12.713700294494629
      }
    },
    {
      "codice": "099025",
      "nome": "San Leo",
      "nomeStraniero": null,
      "codiceCatastale": "H949",
      "cap": "47865",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "anagrafe@comune.san-leo.rn.it",
      "pec": "comune.san-leo@pec.it",
      "telefono": "+39 0541 916211",
      "fax": "+39 0541 916184",
      "coordinate": {
        "lat": 43.89635467529297,
        "lng": 12.343330383300781
      }
    },
    {
      "codice": "099026",
      "nome": "Sant'Agata Feltria",
      "nomeStraniero": null,
      "codiceCatastale": "I201",
      "cap": "47866",
      "prefisso": "0547",
      "provincia": "Rimini",
      "email": "info@comune.santagatafeltria.rn.it",
      "pec": "comunesantagatafeltria@legalmail.it",
      "telefono": "+39 0541 929613",
      "fax": "+39 0541 848591",
      "coordinate": {
        "lat": 43.864009857177734,
        "lng": 12.208480834960938
      }
    },
    {
      "codice": "099018",
      "nome": "Santarcangelo di Romagna",
      "nomeStraniero": null,
      "codiceCatastale": "I304",
      "cap": "47822",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "protocollo@comune.santarcangelo.rn.it",
      "pec": "pec@pec.comune.santarcangelo.rn.it",
      "telefono": "+39 0541/356111",
      "fax": "+39 0541/626464",
      "coordinate": {
        "lat": 44.06325912475586,
        "lng": 12.44657039642334
      }
    },
    {
      "codice": "099031",
      "nome": "Sassofeltrio",
      "nomeStraniero": null,
      "codiceCatastale": "I460",
      "cap": "47869",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "comune.sassofeltrio@provincia.ps.it",
      "pec": "comune.sassofeltrio@emarche.it",
      "telefono": "+39 0541/974130",
      "fax": "+39 0541/974000",
      "coordinate": {
        "lat": 43.89191818237305,
        "lng": 12.509181022644043
      }
    },
    {
      "codice": "099027",
      "nome": "Talamello",
      "nomeStraniero": null,
      "codiceCatastale": "L034",
      "cap": "47867",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "anagrafe@comune.talamello.rn.it",
      "pec": "comune.talamello.rn@pec.it",
      "telefono": "+39 0541 920036",
      "fax": "+39 0541 920736",
      "coordinate": {
        "lat": 43.904850006103516,
        "lng": 12.286355972290039
      }
    },
    {
      "codice": "099020",
      "nome": "Verucchio",
      "nomeStraniero": null,
      "codiceCatastale": "L797",
      "cap": "47826",
      "prefisso": "0541",
      "provincia": "Rimini",
      "email": "urp@comune.verucchio.rn.it",
      "pec": "pec@pec.comune.verucchio.rn.it",
      "telefono": "+39 0541/673911",
      "fax": "+39 0541/679570",
      "coordinate": {
        "lat": 43.983333587646484,
        "lng": 12.421500205993652
      }
    }
  ],
  "Crotone": [
    {
      "codice": "101001",
      "nome": "Belvedere di Spinello",
      "nomeStraniero": null,
      "codiceCatastale": "A772",
      "cap": "88824",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "protocollo.belvederespinello@asmepec.it",
      "pec": "protocollo.belvederespinello@asmepec.it",
      "telefono": "+39 0962/52032",
      "fax": "+39 0962/52468",
      "coordinate": {
        "lat": 39.20674133300781,
        "lng": 16.892330169677734
      }
    },
    {
      "codice": "101002",
      "nome": "Caccuri",
      "nomeStraniero": null,
      "codiceCatastale": "B319",
      "cap": "88833",
      "prefisso": "0984",
      "provincia": "Crotone",
      "email": "affarigenerali@comune.caccuri.kr.it",
      "pec": "protocollo.caccuri@asmepec.it",
      "telefono": "+39 0984 998040",
      "fax": "+39 0984 998555",
      "coordinate": {
        "lat": 39.233333587646484,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "101003",
      "nome": "Carfizzi",
      "nomeStraniero": null,
      "codiceCatastale": "B771",
      "cap": "88817",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "c.abate@asmepec.it",
      "pec": "c.abate@asmepec.it",
      "telefono": "+39 0962/87041",
      "fax": "+39 0962/87298",
      "coordinate": {
        "lat": 39.306495666503906,
        "lng": 16.97592544555664
      }
    },
    {
      "codice": "101004",
      "nome": "Casabona",
      "nomeStraniero": null,
      "codiceCatastale": "B857",
      "cap": "88822",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "anagrafe.casabona@asmepec.it",
      "pec": "anagrafe.casabona@asmepec.it",
      "telefono": "+39 0962/1903361",
      "fax": "+39 0962/888830",
      "coordinate": {
        "lat": 39.25,
        "lng": 16.950000762939453
      }
    },
    {
      "codice": "101005",
      "nome": "Castelsilano",
      "nomeStraniero": null,
      "codiceCatastale": "B968",
      "cap": "88834",
      "prefisso": "0984",
      "provincia": "Crotone",
      "email": "info@comune.castelsilano.kr.it",
      "pec": "segreteria.castelsilano@asmepec.it",
      "telefono": "+39 0984/994025",
      "fax": "+39 0984/994407",
      "coordinate": {
        "lat": 39.266666412353516,
        "lng": 16.766666412353516
      }
    },
    {
      "codice": "101006",
      "nome": "Cerenzia",
      "nomeStraniero": null,
      "codiceCatastale": "C501",
      "cap": "88833",
      "prefisso": "0984",
      "provincia": "Crotone",
      "email": "utc@comune.cerenzia.kr.it",
      "pec": "affgenerali.cerenzia@asmepec.it",
      "telefono": "+39 0984 995035",
      "fax": "+39 0984 995300",
      "coordinate": {
        "lat": 39.25,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "101007",
      "nome": "Cirò",
      "nomeStraniero": null,
      "codiceCatastale": "C725",
      "cap": "88813",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "figoli@comune.ciro.kr.it",
      "pec": "demografici.ciro@asmepec.it",
      "telefono": "+39 096232023",
      "fax": "+39 096232948",
      "coordinate": {
        "lat": 39.38333511352539,
        "lng": 17.066667556762695
      }
    },
    {
      "codice": "101008",
      "nome": "Cirò Marina",
      "nomeStraniero": null,
      "codiceCatastale": "C726",
      "cap": "88811",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "sindaco@comune.ciromarina.kr.it",
      "pec": "comune.ciromarina@asmepec.it",
      "telefono": "+39 0962 375111",
      "fax": "+39 0962 31266",
      "coordinate": {
        "lat": 39.36666488647461,
        "lng": 17.116666793823242
      }
    },
    {
      "codice": "101009",
      "nome": "Cotronei",
      "nomeStraniero": null,
      "codiceCatastale": "D123",
      "cap": "88836",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "protocollo@pec.comunecotronei.it",
      "pec": "protocollo@pec.comunecotronei.it",
      "telefono": "+39 096244202",
      "fax": "+39 0962491656",
      "coordinate": {
        "lat": 39.16666793823242,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "101010",
      "nome": "Crotone",
      "nomeStraniero": null,
      "codiceCatastale": "D122",
      "cap": "88900",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "ced@comune.crotone.it",
      "pec": "protocollocomune@pec.comune.crotone.it",
      "telefono": "+39 0962/921407",
      "fax": "+39 0962/921413",
      "coordinate": {
        "lat": 39.08333206176758,
        "lng": 17.116666793823242
      }
    },
    {
      "codice": "101011",
      "nome": "Crucoli",
      "nomeStraniero": null,
      "codiceCatastale": "D189",
      "cap": "88812",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "demografici@comune.crucoli.kr.it",
      "pec": "demiografici.comunecrucoli@pcert.postecert.it",
      "telefono": "+39 0962/33274",
      "fax": "+39 0962/33090",
      "coordinate": {
        "lat": 39.42361068725586,
        "lng": 17.003334045410156
      }
    },
    {
      "codice": "101012",
      "nome": "Cutro",
      "nomeStraniero": null,
      "codiceCatastale": "D236",
      "cap": "88842",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "info@comune.cutro.kr.it",
      "pec": "segreteria.cutro@asmepec.it",
      "telefono": "+39 0962 7771570",
      "fax": "+39 0962 7771527",
      "coordinate": {
        "lat": 39.0341682434082,
        "lng": 16.981945037841797
      }
    },
    {
      "codice": "101013",
      "nome": "Isola di Capo Rizzuto",
      "nomeStraniero": null,
      "codiceCatastale": "E339",
      "cap": "88841",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "mariapaolairitale@isolacr.it",
      "pec": "comune@pec.isolacr.it",
      "telefono": "+39 0962/797909",
      "fax": "+39 0962/797956",
      "coordinate": {
        "lat": 38.96666717529297,
        "lng": 17.100000381469727
      }
    },
    {
      "codice": "101014",
      "nome": "Melissa",
      "nomeStraniero": null,
      "codiceCatastale": "F108",
      "cap": "88814",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "segreteria@comune.melissa.kr.it",
      "pec": "legale.melissa@asmepec.it",
      "telefono": "+39 0962 835801",
      "fax": "+39 0962 835907",
      "coordinate": {
        "lat": 39.29999923706055,
        "lng": 17.016666412353516
      }
    },
    {
      "codice": "101015",
      "nome": "Mesoraca",
      "nomeStraniero": null,
      "codiceCatastale": "F157",
      "cap": "88838",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "serviziofinanziario@comune.mesoraca.kr.it",
      "pec": "comune.mesoraca@anutel.it",
      "telefono": "+39 0962489434",
      "fax": "+39 096245049",
      "coordinate": {
        "lat": 39.08333206176758,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "101016",
      "nome": "Pallagorio",
      "nomeStraniero": null,
      "codiceCatastale": "G278",
      "cap": "88818",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "info@comune.pallagorio.kr.it",
      "pec": "comune.pallagorio@asmepec.it",
      "telefono": "+39 0962/761037",
      "fax": "+39 0962/761444",
      "coordinate": {
        "lat": 39.29999923706055,
        "lng": 16.899999618530273
      }
    },
    {
      "codice": "101017",
      "nome": "Petilia Policastro",
      "nomeStraniero": null,
      "codiceCatastale": "G508",
      "cap": "88837",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "vrocca@comunepetiliapolicastro.it",
      "pec": "comune.petiliapolicastro@asmecert.it",
      "telefono": "+39 0962/4338211",
      "fax": "+39 0962/433299",
      "coordinate": {
        "lat": 39.11666488647461,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "101019",
      "nome": "Rocca di Neto",
      "nomeStraniero": null,
      "codiceCatastale": "H403",
      "cap": "88821",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "protocollo@comune.roccadineto.kr.it",
      "pec": "protocollo-albopretorio.roccadineto@asmepec.it",
      "telefono": "+39 0962 886511",
      "fax": "+39 0962 84158",
      "coordinate": {
        "lat": 39.188331604003906,
        "lng": 17.004167556762695
      }
    },
    {
      "codice": "101018",
      "nome": "Roccabernarda",
      "nomeStraniero": null,
      "codiceCatastale": "H383",
      "cap": "88835",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "coroccabernarda@libero.it",
      "pec": "segreteria.roccabernarda@asmepec.it",
      "telefono": "+39 0962/56072",
      "fax": "+39 0962/57921",
      "coordinate": {
        "lat": 39.13333511352539,
        "lng": 16.866666793823242
      }
    },
    {
      "codice": "101020",
      "nome": "San Mauro Marchesato",
      "nomeStraniero": null,
      "codiceCatastale": "I026",
      "cap": "88831",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "comune@comune.sanmauromarchesato.kr.it",
      "pec": "anagrafe.sanmauromarchesato@asmepec.it",
      "telefono": "+39 0962 53764",
      "fax": "+39 0962 53018",
      "coordinate": {
        "lat": 39.099998474121094,
        "lng": 16.91666603088379
      }
    },
    {
      "codice": "101021",
      "nome": "San Nicola dell'Alto",
      "nomeStraniero": null,
      "codiceCatastale": "I057",
      "cap": "88817",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "sannicolaalto@tiscali.it",
      "pec": "anagrafe.sannicoladellalto@asmepec.it",
      "telefono": "+39 0962/85042",
      "fax": "+39 0962/85435",
      "coordinate": {
        "lat": 39.29999923706055,
        "lng": 16.96666717529297
      }
    },
    {
      "codice": "101022",
      "nome": "Santa Severina",
      "nomeStraniero": null,
      "codiceCatastale": "I308",
      "cap": "88832",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "protocollo@comune.santaseverina.kr.it",
      "pec": "protocollo@pec.comune.santaseverina.kr.it",
      "telefono": "+39 096251062",
      "fax": "+39 0962553956",
      "coordinate": {
        "lat": 39.150001525878906,
        "lng": 16.91666603088379
      }
    },
    {
      "codice": "101023",
      "nome": "Savelli",
      "nomeStraniero": null,
      "codiceCatastale": "I468",
      "cap": "88825",
      "prefisso": "0984",
      "provincia": "Crotone",
      "email": "comunesavelli@comune.savelli.kr.it",
      "pec": "ufficiotributi.savelli@asmepec.it",
      "telefono": "+39 0984/996003",
      "fax": "+39 0984/655455",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.78333282470703
      }
    },
    {
      "codice": "101024",
      "nome": "Scandale",
      "nomeStraniero": null,
      "codiceCatastale": "I494",
      "cap": "88831",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "ufficioanagrafe@comune.scandale.kr.it",
      "pec": "servizidemografici.scandale@asmepec.it",
      "telefono": "+39 096254017",
      "fax": "+39 0962558970",
      "coordinate": {
        "lat": 39.11666488647461,
        "lng": 16.96666717529297
      }
    },
    {
      "codice": "101025",
      "nome": "Strongoli",
      "nomeStraniero": null,
      "codiceCatastale": "I982",
      "cap": "88816",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "info@comunedistrongoli.it",
      "pec": "ufficioamministrativo@pec.comunedistrongoli.it",
      "telefono": "+39 0962 81051",
      "fax": "+39 0962 89366",
      "coordinate": {
        "lat": 39.266666412353516,
        "lng": 17.066667556762695
      }
    },
    {
      "codice": "101026",
      "nome": "Umbriatico",
      "nomeStraniero": null,
      "codiceCatastale": "L492",
      "cap": "88823",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "demografici@comune.umbriatico.kr.it",
      "pec": "demografici.umbriatico@asmepec.it",
      "telefono": "+39 0962/765803",
      "fax": "+39 0962/765928",
      "coordinate": {
        "lat": 39.35323715209961,
        "lng": 16.917999267578125
      }
    },
    {
      "codice": "101027",
      "nome": "Verzino",
      "nomeStraniero": null,
      "codiceCatastale": "L802",
      "cap": "88819",
      "prefisso": "0962",
      "provincia": "Crotone",
      "email": "suap@comune.verzino.kr.it",
      "pec": "suap.verzino@asmepec.it",
      "telefono": "+39 0962/763044",
      "fax": "+39 0962/763749",
      "coordinate": {
        "lat": 39.31666564941406,
        "lng": 16.866666793823242
      }
    }
  ],
  "Livorno": [
    {
      "codice": "049001",
      "nome": "Bibbona",
      "nomeStraniero": null,
      "codiceCatastale": "A852",
      "cap": "57020",
      "prefisso": "0586",
      "provincia": "Livorno",
      "email": "info@comune.bibbona.li.it",
      "pec": "comune.bibbona@pec.it",
      "telefono": "+39 0586/672111",
      "fax": "+39 0586/670363",
      "coordinate": {
        "lat": 43.26944351196289,
        "lng": 10.594444274902344
      }
    },
    {
      "codice": "049002",
      "nome": "Campiglia Marittima",
      "nomeStraniero": null,
      "codiceCatastale": "B509",
      "cap": "57021",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "segreteria@comune.campigliamarittima.li.it",
      "pec": "comune.campigliamarittima@postacert.toscana.it",
      "telefono": "+39 0565/839223",
      "fax": "+39 0565/839259",
      "coordinate": {
        "lat": 43.06666564941406,
        "lng": 10.616666793823242
      }
    },
    {
      "codice": "049003",
      "nome": "Campo nell'Elba",
      "nomeStraniero": null,
      "codiceCatastale": "B553",
      "cap": "57034",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "s.landi@comune.camponellelba.li.it",
      "pec": "comune.camponellelba.li@postacert.toscana.it",
      "telefono": "+39 0565/979311",
      "fax": "+39 0565/976044",
      "coordinate": {
        "lat": 42.75,
        "lng": 10.233333587646484
      }
    },
    {
      "codice": "049004",
      "nome": "Capoliveri",
      "nomeStraniero": null,
      "codiceCatastale": "B669",
      "cap": "57031",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "a.baldoni@capoliveri.net",
      "pec": "comunecapoliveri@pcert.it",
      "telefono": "+39 0565/967611",
      "fax": "+39 0565/968060",
      "coordinate": {
        "lat": 42.75,
        "lng": 10.383333206176758
      }
    },
    {
      "codice": "049005",
      "nome": "Capraia Isola",
      "nomeStraniero": null,
      "codiceCatastale": "B685",
      "cap": "57032",
      "prefisso": "0586",
      "provincia": "Livorno",
      "email": "comunecapraiaisola@tin.it",
      "pec": "comunecapraiaisola@postacert.toscana.it",
      "telefono": "+39 0586/905025",
      "fax": "+39 0586/905113",
      "coordinate": {
        "lat": 43.04999923706055,
        "lng": 9.850000381469727
      }
    },
    {
      "codice": "049006",
      "nome": "Castagneto Carducci",
      "nomeStraniero": null,
      "codiceCatastale": "C044",
      "cap": "57022",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "urp@comune.castagneto-carducci.li.it",
      "pec": "mail@comune.castagneto.legalmailpa.it",
      "telefono": "+39 0565/778111",
      "fax": "+39 0565/763845",
      "coordinate": {
        "lat": 43.16666793823242,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "049007",
      "nome": "Cecina",
      "nomeStraniero": null,
      "codiceCatastale": "C415",
      "cap": "57023",
      "prefisso": "0586",
      "provincia": "Livorno",
      "email": "g.bibbiani@comune.cecina.li.it",
      "pec": "protocollo@cert.comune.cecina.li.it",
      "telefono": "+39 0586611703",
      "fax": "+39 0586611208",
      "coordinate": {
        "lat": 43.31388854980469,
        "lng": 10.524999618530273
      }
    },
    {
      "codice": "049008",
      "nome": "Collesalvetti",
      "nomeStraniero": null,
      "codiceCatastale": "C869",
      "cap": "57014",
      "prefisso": "0586",
      "provincia": "Livorno",
      "email": "segreteriasindaco@comune.collesalvetti.li.it",
      "pec": "comune.collesalvetti@postacert.toscana.it",
      "telefono": "+39 0586/980253",
      "fax": "+39 0586/980254",
      "coordinate": {
        "lat": 43.599998474121094,
        "lng": 10.483333587646484
      }
    },
    {
      "codice": "049009",
      "nome": "Livorno",
      "nomeStraniero": null,
      "codiceCatastale": "E625",
      "cap": "57121",
      "prefisso": "0586",
      "provincia": "Livorno",
      "email": "urp@comune.livorno.it",
      "pec": "comune.livorno@postacert.toscana.it",
      "telefono": "+39 0586 820111",
      "fax": "+39 0586/820444",
      "coordinate": {
        "lat": 43.54999923706055,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "049010",
      "nome": "Marciana",
      "nomeStraniero": null,
      "codiceCatastale": "E930",
      "cap": "57030",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "info@comune.marciana.li.it",
      "pec": "protocollo@pec.comune.marciana.li.it",
      "telefono": "+39 0565/901215",
      "fax": "+39 0565/901076",
      "coordinate": {
        "lat": 42.78333282470703,
        "lng": 10.166666984558105
      }
    },
    {
      "codice": "049011",
      "nome": "Marciana Marina",
      "nomeStraniero": null,
      "codiceCatastale": "E931",
      "cap": "57033",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "l.lupi@comune.marcianamarina.li.it",
      "pec": "comune.marcianamarina@postacert.toscana.it",
      "telefono": "+39 0565/99368",
      "fax": "+39 0565/904321",
      "coordinate": {
        "lat": 42.79999923706055,
        "lng": 10.199999809265137
      }
    },
    {
      "codice": "049012",
      "nome": "Piombino",
      "nomeStraniero": null,
      "codiceCatastale": "G687",
      "cap": "57020",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "sindaco@comune.piombino.li.it",
      "pec": "comunepiombino@postacert.toscana.it",
      "telefono": "+39 0565/63111",
      "fax": "+39 0565/63290",
      "coordinate": {
        "lat": 42.9348258972168,
        "lng": 10.522120475769043
      }
    },
    {
      "codice": "049013",
      "nome": "Porto Azzurro",
      "nomeStraniero": null,
      "codiceCatastale": "E680",
      "cap": "57036",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "anagrafe@comuneportoazzurro.li.it",
      "pec": "comuneportoazzurro@pcert.it",
      "telefono": "+39 0565/921634",
      "fax": "+39 0565/921641",
      "coordinate": {
        "lat": 42.766666412353516,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "049014",
      "nome": "Portoferraio",
      "nomeStraniero": null,
      "codiceCatastale": "G912",
      "cap": "57037",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "anagrafe@comune.portoferraio.li.it",
      "pec": "comune.portoferraio@postacert.toscana.it",
      "telefono": "+39 0565/937111",
      "fax": "+39 0565/916391",
      "coordinate": {
        "lat": 42.81666564941406,
        "lng": 10.316666603088379
      }
    },
    {
      "codice": "049021",
      "nome": "Rio",
      "nomeStraniero": null,
      "codiceCatastale": "M391",
      "cap": "57038",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "info@comunie.rio.li.it",
      "pec": "protocollo@pec.comune.rio.li.it",
      "telefono": "0565925511",
      "fax": null,
      "coordinate": {
        "lat": 42.813446044921875,
        "lng": 10.41736125946045
      }
    },
    {
      "codice": "049017",
      "nome": "Rosignano Marittimo",
      "nomeStraniero": null,
      "codiceCatastale": "H570",
      "cap": "57016",
      "prefisso": "0586",
      "provincia": "Livorno",
      "email": "a.gabriellini@comune.rosignano.li.it",
      "pec": "protocollo.generale@cert.comune.rosignano.li.it",
      "telefono": "+39 0586/724263",
      "fax": "+39 0586/724276",
      "coordinate": {
        "lat": 43.400001525878906,
        "lng": 10.466666221618652
      }
    },
    {
      "codice": "049018",
      "nome": "San Vincenzo",
      "nomeStraniero": null,
      "codiceCatastale": "I390",
      "cap": "57027",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "webmail@comune.sanvincenzo.li.it",
      "pec": "comunesanvincenzo@postacert.toscana.it",
      "telefono": "+39 0565/707111",
      "fax": "+39 0565/707299",
      "coordinate": {
        "lat": 43.1006965637207,
        "lng": 10.53879165649414
      }
    },
    {
      "codice": "049019",
      "nome": "Sassetta",
      "nomeStraniero": null,
      "codiceCatastale": "I454",
      "cap": "57020",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "l.dagostino@comune.sanvincenzo.it",
      "pec": "comunesassetta@postacert.toscana.it",
      "telefono": "+39 0565/794223",
      "fax": "+39 0565/794203",
      "coordinate": {
        "lat": 43.13333511352539,
        "lng": 10.649999618530273
      }
    },
    {
      "codice": "049020",
      "nome": "Suvereto",
      "nomeStraniero": null,
      "codiceCatastale": "L019",
      "cap": "57028",
      "prefisso": "0565",
      "provincia": "Livorno",
      "email": "suvereto@comune.suvereto.li.it",
      "pec": "comune.suvereto@postacert.toscana.it",
      "telefono": "+39 0565/829923",
      "fax": "+39 0565/828201",
      "coordinate": {
        "lat": 43.08333206176758,
        "lng": 10.683333396911621
      }
    }
  ],
  "Pisa": [
    {
      "codice": "050001",
      "nome": "Bientina",
      "nomeStraniero": null,
      "codiceCatastale": "A864",
      "cap": "56031",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "protocollo@comune.bientina.pi.it",
      "pec": "comune.bientina.pi.it@cert.legalmail.it",
      "telefono": "+39 0587/758411",
      "fax": "+39 0587/758428",
      "coordinate": {
        "lat": 43.70722198486328,
        "lng": 10.620555877685547
      }
    },
    {
      "codice": "050002",
      "nome": "Buti",
      "nomeStraniero": null,
      "codiceCatastale": "B303",
      "cap": "56032",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "s.gennai@comune.buti.pi.it",
      "pec": "comune.buti.pi@postacert.toscana.it",
      "telefono": "+39 0587/722511",
      "fax": "+39 0587/723784",
      "coordinate": {
        "lat": 43.72972106933594,
        "lng": 10.58666706085205
      }
    },
    {
      "codice": "050003",
      "nome": "Calci",
      "nomeStraniero": null,
      "codiceCatastale": "B390",
      "cap": "56011",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "segreteria@comune.calci.pi.it",
      "pec": "servizidemografici@pec.comune.calci.pi.it",
      "telefono": "+39 050/939531",
      "fax": "+39 050/938202",
      "coordinate": {
        "lat": 43.72444534301758,
        "lng": 10.519166946411133
      }
    },
    {
      "codice": "050004",
      "nome": "Calcinaia",
      "nomeStraniero": null,
      "codiceCatastale": "B392",
      "cap": "56012",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "demografici@comune.calcinaia.pi.it",
      "pec": "comune.calcinaia@postacert.toscana.it",
      "telefono": "+39 0587/265405",
      "fax": "+39 0587/265409",
      "coordinate": {
        "lat": 43.68352127075195,
        "lng": 10.616530418395996
      }
    },
    {
      "codice": "050005",
      "nome": "Capannoli",
      "nomeStraniero": null,
      "codiceCatastale": "B647",
      "cap": "56033",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "c.orlandini@comune.capannoli.pisa.it",
      "pec": "comune.capannoli@postacert.toscana.it",
      "telefono": "+39 0587/606603",
      "fax": "+39 0587/606690",
      "coordinate": {
        "lat": 43.59000015258789,
        "lng": 10.669722557067871
      }
    },
    {
      "codice": "050006",
      "nome": "Casale Marittimo",
      "nomeStraniero": null,
      "codiceCatastale": "B878",
      "cap": "56040",
      "prefisso": "0586",
      "provincia": "Pisa",
      "email": "mail@comune.casale-marittimo.pi.it",
      "pec": "comunecasale@postacert.toscana.it",
      "telefono": "+39 0586/653411",
      "fax": "+39 0586/653444",
      "coordinate": {
        "lat": 43.29750061035156,
        "lng": 10.616666793823242
      }
    },
    {
      "codice": "050040",
      "nome": "Casciana Terme Lari",
      "nomeStraniero": null,
      "codiceCatastale": "M327",
      "cap": "56034",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "sindaco@comune.lari.pi.it",
      "pec": "comune.cascianatermelari@postacert.toscana.it",
      "telefono": "+39 0587 687511",
      "fax": "+39 0587 687575",
      "coordinate": {
        "lat": 43.56888961791992,
        "lng": 10.593610763549805
      }
    },
    {
      "codice": "050008",
      "nome": "Cascina",
      "nomeStraniero": null,
      "codiceCatastale": "B950",
      "cap": "56021",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "achiavaccini@comune.cascina.pi.it",
      "pec": "protocollo@pec.comune.cascina.pi.it",
      "telefono": "+39 050/719337",
      "fax": "+39 050/719336",
      "coordinate": {
        "lat": 43.68000030517578,
        "lng": 10.500277519226074
      }
    },
    {
      "codice": "050009",
      "nome": "Castelfranco di Sotto",
      "nomeStraniero": null,
      "codiceCatastale": "C113",
      "cap": "56022",
      "prefisso": "0571",
      "provincia": "Pisa",
      "email": "d.romiti@comune.castelfranco.pi.it",
      "pec": "comune.castelfrancodisotto@postacert.toscana.it",
      "telefono": "+39 0571 4871",
      "fax": "+39 0571/489580",
      "coordinate": {
        "lat": 43.70000076293945,
        "lng": 10.75
      }
    },
    {
      "codice": "050010",
      "nome": "Castellina Marittima",
      "nomeStraniero": null,
      "codiceCatastale": "C174",
      "cap": "56040",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "anagrafe@comune.castellina.pi.it",
      "pec": "comune.castellina@postacert.toscana.it",
      "telefono": "+39 050/694111",
      "fax": "+39 050/694112",
      "coordinate": {
        "lat": 43.412776947021484,
        "lng": 10.577221870422363
      }
    },
    {
      "codice": "050011",
      "nome": "Castelnuovo di Val di Cecina",
      "nomeStraniero": null,
      "codiceCatastale": "C244",
      "cap": "56041",
      "prefisso": "0588",
      "provincia": "Pisa",
      "email": "e.bassanello@tin.it",
      "pec": "segreteria.castelnuovo@postacert.toscana.it",
      "telefono": "+39 0588//62085",
      "fax": "+39 0588/62085",
      "coordinate": {
        "lat": 43.21666717529297,
        "lng": 10.899999618530273
      }
    },
    {
      "codice": "050012",
      "nome": "Chianni",
      "nomeStraniero": null,
      "codiceCatastale": "C609",
      "cap": "56034",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "sociale@comune.chianni.pisa.it",
      "pec": "comune.chianni@postacert.toscana.it",
      "telefono": "+39 0587/647282",
      "fax": "+39 0587/647377",
      "coordinate": {
        "lat": 43.4819450378418,
        "lng": 10.642777442932129
      }
    },
    {
      "codice": "050041",
      "nome": "Crespina Lorenzana",
      "nomeStraniero": null,
      "codiceCatastale": "M328",
      "cap": "56043",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "urp@comune.crespinalorenzana.pi.it",
      "pec": "comune.crespinalorenzana.pi@postacert.toscana.it",
      "telefono": "+39 050 634711",
      "fax": "+39 050 634740",
      "coordinate": {
        "lat": 43.573890686035156,
        "lng": 10.565278053283691
      }
    },
    {
      "codice": "050014",
      "nome": "Fauglia",
      "nomeStraniero": null,
      "codiceCatastale": "D510",
      "cap": "56043",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "a.",
      "pec": "comune.fauglia@postacert.toscana.it",
      "telefono": "+39 050/657311",
      "fax": "+39 050/657330",
      "coordinate": {
        "lat": 43.56666564941406,
        "lng": 10.516666412353516
      }
    },
    {
      "codice": "050015",
      "nome": "Guardistallo",
      "nomeStraniero": null,
      "codiceCatastale": "E250",
      "cap": "56040",
      "prefisso": "0586",
      "provincia": "Pisa",
      "email": "f.agostini@comune.guardistallo.pi.it",
      "pec": "comune.guardistallo@postacert.toscana.it",
      "telefono": "+39 0586/651520",
      "fax": "+39 0586/651533",
      "coordinate": {
        "lat": 43.3136100769043,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "050016",
      "nome": "Lajatico",
      "nomeStraniero": null,
      "codiceCatastale": "E413",
      "cap": "56030",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "protocollo@comune.lajatico.pi.it",
      "pec": "comune.lajatico@postacert.toscana.it",
      "telefono": "+39 0587/643121",
      "fax": "+39 0587/640895",
      "coordinate": {
        "lat": 43.47222137451172,
        "lng": 10.72944450378418
      }
    },
    {
      "codice": "050019",
      "nome": "Montecatini Val di Cecina",
      "nomeStraniero": null,
      "codiceCatastale": "F458",
      "cap": "56040",
      "prefisso": "0588",
      "provincia": "Pisa",
      "email": "info@comune.montecatini.pi.it",
      "pec": "comune.montecatini.vdc@postacert.toscana.it",
      "telefono": "+39 058831611",
      "fax": "+39 0588/31615",
      "coordinate": {
        "lat": 43.393611907958984,
        "lng": 10.749722480773926
      }
    },
    {
      "codice": "050020",
      "nome": "Montescudaio",
      "nomeStraniero": null,
      "codiceCatastale": "F640",
      "cap": "56040",
      "prefisso": "0586",
      "provincia": "Pisa",
      "email": "t.nanni@comune.montescudaio.pi.it",
      "pec": "comune.montescudaio@postacert.toscana.it",
      "telefono": "+39 0586/651623",
      "fax": "+39 0586/651660",
      "coordinate": {
        "lat": 43.33333206176758,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "050021",
      "nome": "Monteverdi Marittimo",
      "nomeStraniero": null,
      "codiceCatastale": "F661",
      "cap": "56040",
      "prefisso": "0565",
      "provincia": "Pisa",
      "email": "comune.monteverdi@comune.monteverdi.pi.it",
      "pec": "comune.monteverdi.m@postacert.toscana.it",
      "telefono": "+39 0565 78511",
      "fax": "+39 0565 784410",
      "coordinate": {
        "lat": 43.18333435058594,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "050022",
      "nome": "Montopoli in Val d'Arno",
      "nomeStraniero": null,
      "codiceCatastale": "F686",
      "cap": "56020",
      "prefisso": "0571",
      "provincia": "Pisa",
      "email": "info@comune.montopoli.pi.it",
      "pec": "comune.montopoli@legalmail.it",
      "telefono": "+39 0571/443811",
      "fax": "+39 0571/466327",
      "coordinate": {
        "lat": 43.67444610595703,
        "lng": 10.750277519226074
      }
    },
    {
      "codice": "050023",
      "nome": "Orciano Pisano",
      "nomeStraniero": null,
      "codiceCatastale": "G090",
      "cap": "56040",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "tizianofavilli@gmail.com",
      "pec": "comune.orciano@postacert.toscana.it",
      "telefono": "050683018",
      "fax": null,
      "coordinate": {
        "lat": 43.5,
        "lng": 10.516666412353516
      }
    },
    {
      "codice": "050024",
      "nome": "Palaia",
      "nomeStraniero": null,
      "codiceCatastale": "G254",
      "cap": "56036",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "prot@comune.palaia.pisa.it",
      "pec": "comune.palaia@postacert.toscana.it",
      "telefono": "+39 0587/62141",
      "fax": "+39 0587/622539",
      "coordinate": {
        "lat": 43.6058349609375,
        "lng": 10.772777557373047
      }
    },
    {
      "codice": "050025",
      "nome": "Peccioli",
      "nomeStraniero": null,
      "codiceCatastale": "G395",
      "cap": "56037",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "info@comune.peccioli.pi.it",
      "pec": "comune.peccioli@postacert.toscana.it",
      "telefono": "+39 0587/67261",
      "fax": "+39 0587/672666",
      "coordinate": {
        "lat": 43.54999923706055,
        "lng": 10.716666221618652
      }
    },
    {
      "codice": "050026",
      "nome": "Pisa",
      "nomeStraniero": null,
      "codiceCatastale": "G702",
      "cap": "56100",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "g.lelli@comune.pisa.it",
      "pec": "comune.pisa@postacert.toscana.it",
      "telefono": "+39 050/910350",
      "fax": "+39 050/910933",
      "coordinate": {
        "lat": 43.71666717529297,
        "lng": 10.399999618530273
      }
    },
    {
      "codice": "050027",
      "nome": "Pomarance",
      "nomeStraniero": null,
      "codiceCatastale": "G804",
      "cap": "56045",
      "prefisso": "0588",
      "provincia": "Pisa",
      "email": "sindaco@comune.pomarance.pi.it",
      "pec": "comune.pomarance@postacert.toscana.it",
      "telefono": "+39 0588 6231",
      "fax": "+39 0588/65470",
      "coordinate": {
        "lat": 43.299720764160156,
        "lng": 10.873611450195312
      }
    },
    {
      "codice": "050028",
      "nome": "Ponsacco",
      "nomeStraniero": null,
      "codiceCatastale": "G822",
      "cap": "56038",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "marinari@comune.ponsacco.pi.it",
      "pec": "comune.ponsacco@postacert.toscana.it",
      "telefono": "+39 0587/738111",
      "fax": "+39 0587/733871",
      "coordinate": {
        "lat": 43.61666488647461,
        "lng": 10.633333206176758
      }
    },
    {
      "codice": "050029",
      "nome": "Pontedera",
      "nomeStraniero": null,
      "codiceCatastale": "G843",
      "cap": "56025",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "info@comune.pontedera.pi.it",
      "pec": "pontedera@postacert.toscana.it",
      "telefono": "+39 0587/299258",
      "fax": "+39 0587/53292",
      "coordinate": {
        "lat": 43.662498474121094,
        "lng": 10.63277816772461
      }
    },
    {
      "codice": "050030",
      "nome": "Riparbella",
      "nomeStraniero": null,
      "codiceCatastale": "H319",
      "cap": "56046",
      "prefisso": "0586",
      "provincia": "Pisa",
      "email": "m.pistacchi@comune.riparbella.pi.it",
      "pec": "comuneriparbella.pi.protocollo@pa.postacertificata.gov.it",
      "telefono": "+39 0586697111",
      "fax": "+39 0586/697327",
      "coordinate": {
        "lat": 43.36666488647461,
        "lng": 10.600000381469727
      }
    },
    {
      "codice": "050031",
      "nome": "San Giuliano Terme",
      "nomeStraniero": null,
      "codiceCatastale": "A562",
      "cap": "56017",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "info@comune.sangiulianoterme.pisa.it",
      "pec": "comune.sangiulianoterme@postacert.toscana.it",
      "telefono": "+39 050/819256",
      "fax": "+39 050/819220",
      "coordinate": {
        "lat": 43.76250076293945,
        "lng": 10.441389083862305
      }
    },
    {
      "codice": "050032",
      "nome": "San Miniato",
      "nomeStraniero": null,
      "codiceCatastale": "I046",
      "cap": "56028",
      "prefisso": "0571",
      "provincia": "Pisa",
      "email": "achesi@comune.san-miniato.pi.it",
      "pec": "comune.sanminiato.pi@postacert.toscana.it",
      "telefono": "+39 0571/406381",
      "fax": "+39 0587/418309",
      "coordinate": {
        "lat": 43.68333435058594,
        "lng": 10.850000381469727
      }
    },
    {
      "codice": "050033",
      "nome": "Santa Croce sull'Arno",
      "nomeStraniero": null,
      "codiceCatastale": "I177",
      "cap": "56029",
      "prefisso": "0571",
      "provincia": "Pisa",
      "email": "info@comune.santacroce.pi.it",
      "pec": "comune.santacroce@postacert.toscana.it",
      "telefono": "+39 0571/389920",
      "fax": "+39 0571/30898",
      "coordinate": {
        "lat": 43.72019958496094,
        "lng": 10.772700309753418
      }
    },
    {
      "codice": "050034",
      "nome": "Santa Luce",
      "nomeStraniero": null,
      "codiceCatastale": "I217",
      "cap": "56040",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "anagrafe@comune.santaluce.pi.it",
      "pec": "pec.comune.santaluce@legismail.it",
      "telefono": "+39 050/684932",
      "fax": "+39 050/685771",
      "coordinate": {
        "lat": 43.471431732177734,
        "lng": 10.562689781188965
      }
    },
    {
      "codice": "050035",
      "nome": "Santa Maria a Monte",
      "nomeStraniero": null,
      "codiceCatastale": "I232",
      "cap": "56020",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "urp@comune.santamariaamonte.pi.it",
      "pec": "comune.santamariaamonte@postacert.toscana.it",
      "telefono": "+39 0587/261611",
      "fax": "+39 0587/705117",
      "coordinate": {
        "lat": 43.70000076293945,
        "lng": 10.683333396911621
      }
    },
    {
      "codice": "050036",
      "nome": "Terricciola",
      "nomeStraniero": null,
      "codiceCatastale": "L138",
      "cap": "56030",
      "prefisso": "0587",
      "provincia": "Pisa",
      "email": "info@comune.terricciola.pi.it",
      "pec": "protocollo.terricciola@cert.saga.it",
      "telefono": "+39 0587 65651",
      "fax": "+39 0587 655205",
      "coordinate": {
        "lat": 43.52555465698242,
        "lng": 10.680832862854004
      }
    },
    {
      "codice": "050037",
      "nome": "Vecchiano",
      "nomeStraniero": null,
      "codiceCatastale": "L702",
      "cap": "56019",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "sindaco@comune.vecchiano.pisa.it",
      "pec": "comune.vecchiano@postacert.toscana.it",
      "telefono": "+39 050/859635",
      "fax": "+39 050/868778",
      "coordinate": {
        "lat": 43.78333282470703,
        "lng": 10.383333206176758
      }
    },
    {
      "codice": "050038",
      "nome": "Vicopisano",
      "nomeStraniero": null,
      "codiceCatastale": "L850",
      "cap": "56010",
      "prefisso": "050",
      "provincia": "Pisa",
      "email": "sic@comune.vicopisano.pi.it",
      "pec": "comune.vicopisano@postacert.toscana.it",
      "telefono": "+39 050/796539",
      "fax": "+39 050/796540",
      "coordinate": {
        "lat": 43.69913864135742,
        "lng": 10.583110809326172
      }
    },
    {
      "codice": "050039",
      "nome": "Volterra",
      "nomeStraniero": null,
      "codiceCatastale": "M126",
      "cap": "56048",
      "prefisso": "0588",
      "provincia": "Pisa",
      "email": "sindaco@comune.volterra.pi.it",
      "pec": "comune.volterra@postacert.toscana.it",
      "telefono": "+39 0588/86050",
      "fax": "+39 0588/80035",
      "coordinate": {
        "lat": 43.400001525878906,
        "lng": 10.866666793823242
      }
    }
  ],
  "Brindisi": [
    {
      "codice": "074001",
      "nome": "Brindisi",
      "nomeStraniero": null,
      "codiceCatastale": "B180",
      "cap": "72100",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "urp@comune.brindisi.it",
      "pec": "ufficioprotocollo@pec.comune.brindisi.it",
      "telefono": "+39 0831229111",
      "fax": "+39 0831560716",
      "coordinate": {
        "lat": 40.63833236694336,
        "lng": 17.945833206176758
      }
    },
    {
      "codice": "074002",
      "nome": "Carovigno",
      "nomeStraniero": null,
      "codiceCatastale": "B809",
      "cap": "72012",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "info@comune.carovigno.br.it",
      "pec": "protocollo.comune.carovigno@pec.rupar.puglia.it",
      "telefono": "+39 0831-997111",
      "fax": "+39 0831-992020",
      "coordinate": {
        "lat": 40.70722198486328,
        "lng": 17.65944480895996
      }
    },
    {
      "codice": "074003",
      "nome": "Ceglie Messapica",
      "nomeStraniero": null,
      "codiceCatastale": "C424",
      "cap": "72013",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "ceglie@ceglie.org",
      "pec": "protocollo@pec.comune.ceglie-messapica.br.it",
      "telefono": "+39 0831/387237",
      "fax": "+39 0831/377164",
      "coordinate": {
        "lat": 40.650001525878906,
        "lng": 17.516666412353516
      }
    },
    {
      "codice": "074004",
      "nome": "Cellino San Marco",
      "nomeStraniero": null,
      "codiceCatastale": "C448",
      "cap": "72020",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "csm@comune.cellinosanmarco.br.it",
      "pec": "affari.generali.comune.cellinosanmarco@pec.rupar.puglia.it",
      "telefono": "+39 0831/615215",
      "fax": "+39 0831/619691",
      "coordinate": {
        "lat": 40.46666717529297,
        "lng": 17.96666717529297
      }
    },
    {
      "codice": "074005",
      "nome": "Cisternino",
      "nomeStraniero": null,
      "codiceCatastale": "C741",
      "cap": "72014",
      "prefisso": "080",
      "provincia": "Brindisi",
      "email": "comune@pec.comune.cisternino.br.it",
      "pec": "comune@pec.comune.cisternino.br.it",
      "telefono": "+39 080/4445211",
      "fax": "+39 080/4445236",
      "coordinate": {
        "lat": 40.75,
        "lng": 17.41666603088379
      }
    },
    {
      "codice": "074006",
      "nome": "Erchie",
      "nomeStraniero": null,
      "codiceCatastale": "D422",
      "cap": "72020",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": null,
      "pec": "protocollo.comune.erchie@pec.rupar.puglia.it",
      "telefono": "+39 0831/768300",
      "fax": "+39 0831/768399",
      "coordinate": {
        "lat": 40.43333435058594,
        "lng": 17.733333587646484
      }
    },
    {
      "codice": "074007",
      "nome": "Fasano",
      "nomeStraniero": null,
      "codiceCatastale": "D508",
      "cap": "72015",
      "prefisso": "080",
      "provincia": "Brindisi",
      "email": "sindaco@comune.fasano.br.it",
      "pec": "comunefasano@pec.rupar.puglia.it",
      "telefono": "+39 080/4394240",
      "fax": "+39 080/4425218",
      "coordinate": {
        "lat": 40.83333206176758,
        "lng": 17.366666793823242
      }
    },
    {
      "codice": "074008",
      "nome": "Francavilla Fontana",
      "nomeStraniero": null,
      "codiceCatastale": "D761",
      "cap": "72021",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "info@comune.francavillafontana.br.it",
      "pec": "comune.francavillafontana@pec.it",
      "telefono": "+39 0831/820111",
      "fax": "+39 0831/815687",
      "coordinate": {
        "lat": 40.53138732910156,
        "lng": 17.585832595825195
      }
    },
    {
      "codice": "074009",
      "nome": "Latiano",
      "nomeStraniero": null,
      "codiceCatastale": "E471",
      "cap": "72022",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "segreteria@latiano.gov.it",
      "pec": "segreteria@pec.latiano.gov.it",
      "telefono": "+39 0831/7217209",
      "fax": "+39 0831/727328",
      "coordinate": {
        "lat": 40.53333282470703,
        "lng": 17.71666717529297
      }
    },
    {
      "codice": "074010",
      "nome": "Mesagne",
      "nomeStraniero": null,
      "codiceCatastale": "F152",
      "cap": "72023",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "segreteria@comune.mesagne.br.it",
      "pec": "info@pec.comune.mesagne.br.it",
      "telefono": "+39 0831732111",
      "fax": "+39 0831/777403",
      "coordinate": {
        "lat": 40.56666564941406,
        "lng": 17.799999237060547
      }
    },
    {
      "codice": "074011",
      "nome": "Oria",
      "nomeStraniero": null,
      "codiceCatastale": "G098",
      "cap": "72024",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "protocollo@comune.oria.br.it",
      "pec": "protocollo.comune.oria@pec.rupar.puglia.it",
      "telefono": "+39 0831 846511",
      "fax": "+39 0831 847958",
      "coordinate": {
        "lat": 40.5,
        "lng": 17.633333206176758
      }
    },
    {
      "codice": "074012",
      "nome": "Ostuni",
      "nomeStraniero": null,
      "codiceCatastale": "G187",
      "cap": "72017",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "protocollo@cert.comune.ostuni.br.it",
      "pec": "protocollo@cert.comune.ostuni.br.it",
      "telefono": "+39 0831 3071",
      "fax": "+39 0831/339056",
      "coordinate": {
        "lat": 40.73222351074219,
        "lng": 17.577777862548828
      }
    },
    {
      "codice": "074013",
      "nome": "San Donaci",
      "nomeStraniero": null,
      "codiceCatastale": "H822",
      "cap": "72025",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "info@sandonaci.net",
      "pec": "info@pec.sandonaci.net",
      "telefono": "+39 0831/631200",
      "fax": "+39 0831/631212",
      "coordinate": {
        "lat": 40.45000076293945,
        "lng": 17.91666603088379
      }
    },
    {
      "codice": "074014",
      "nome": "San Michele Salentino",
      "nomeStraniero": null,
      "codiceCatastale": "I045",
      "cap": "72018",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "segreteria@comune.sanmichelesal.br.it",
      "pec": "segreteria.comune.sanmichelesal.br@pec.rupar.puglia.it",
      "telefono": "+39 0831/966026",
      "fax": "+39 0831/964670",
      "coordinate": {
        "lat": 40.63333511352539,
        "lng": 17.633333206176758
      }
    },
    {
      "codice": "074015",
      "nome": "San Pancrazio Salentino",
      "nomeStraniero": null,
      "codiceCatastale": "I066",
      "cap": "72026",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "segretario.comunale@sanpancraziosalentino.gov.it",
      "pec": "segreteriacomunesanpancraziosalentino@pec.rupar.puglia.it",
      "telefono": "+39 0831/667117",
      "fax": "+39 0831 666047",
      "coordinate": {
        "lat": 40.41666793823242,
        "lng": 17.83333396911621
      }
    },
    {
      "codice": "074016",
      "nome": "San Pietro Vernotico",
      "nomeStraniero": null,
      "codiceCatastale": "I119",
      "cap": "72027",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "comune@spv.br.it",
      "pec": "protocollo@pec.spv.br.it",
      "telefono": "+39 0831/654741",
      "fax": "+39 0831653763",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 18.049999237060547
      }
    },
    {
      "codice": "074017",
      "nome": "San Vito dei Normanni",
      "nomeStraniero": null,
      "codiceCatastale": "I396",
      "cap": "72019",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "gabinettosindaco@comune.sanvitodeinormanni.br.it",
      "pec": "demograficisanvitodeinormanni@pec.rupar.puglia.it",
      "telefono": "+39 0831/955256",
      "fax": "+39 0831/955264",
      "coordinate": {
        "lat": 40.655555725097656,
        "lng": 17.703611373901367
      }
    },
    {
      "codice": "074018",
      "nome": "Torchiarolo",
      "nomeStraniero": null,
      "codiceCatastale": "L213",
      "cap": "72020",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "elettorale@torchiarolo.gov.it",
      "pec": "demografici@pec.torchiarolo.gov.it",
      "telefono": "+39 0831/622085",
      "fax": "+39 0831/623863",
      "coordinate": {
        "lat": 40.483333587646484,
        "lng": 18.049999237060547
      }
    },
    {
      "codice": "074019",
      "nome": "Torre Santa Susanna",
      "nomeStraniero": null,
      "codiceCatastale": "L280",
      "cap": "72028",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "demografici@comune.torresantasusanna.br.it",
      "pec": "segreteria.comune.torresantasusanna@pec.rupar.puglia.it",
      "telefono": "+39 0831/741211",
      "fax": "+39 0831/740479",
      "coordinate": {
        "lat": 40.46666717529297,
        "lng": 17.733333587646484
      }
    },
    {
      "codice": "074020",
      "nome": "Villa Castelli",
      "nomeStraniero": null,
      "codiceCatastale": "L920",
      "cap": "72029",
      "prefisso": "0831",
      "provincia": "Brindisi",
      "email": "protocollo@comune.villacastelli.br.it",
      "pec": "comune.villacastelli@pec.rupar.puglia.it",
      "telefono": "+39 0831869204",
      "fax": "+39 0831860130",
      "coordinate": {
        "lat": 40.58333206176758,
        "lng": 17.483333587646484
      }
    }
  ],
  "Prato": [
    {
      "codice": "100001",
      "nome": "Cantagallo",
      "nomeStraniero": null,
      "codiceCatastale": "B626",
      "cap": "59025",
      "prefisso": "0574",
      "provincia": "Prato",
      "email": "cantagallo@comune.cantagallo.po.it",
      "pec": "comune.cantagallo@postacert.toscana.it",
      "telefono": "+39 0574 95681",
      "fax": "+39 0574 956838",
      "coordinate": {
        "lat": 44.016666412353516,
        "lng": 11.083333015441895
      }
    },
    {
      "codice": "100002",
      "nome": "Carmignano",
      "nomeStraniero": null,
      "codiceCatastale": "B794",
      "cap": "59015",
      "prefisso": "055",
      "provincia": "Prato",
      "email": "segreteriagenerale@comune.carmignano.po.it",
      "pec": "comune.carmignano@postacert.toscana.it",
      "telefono": "+39 055 875011",
      "fax": "+39 055 8750301",
      "coordinate": {
        "lat": 43.81026840209961,
        "lng": 11.01494026184082
      }
    },
    {
      "codice": "100003",
      "nome": "Montemurlo",
      "nomeStraniero": null,
      "codiceCatastale": "F572",
      "cap": "59013",
      "prefisso": "0574",
      "provincia": "Prato",
      "email": "sindaco@comune.montemurlo.po.it",
      "pec": "comune.montemurlo@postacert.toscana.it",
      "telefono": "+39 0574 5581",
      "fax": "+39 0574 682363",
      "coordinate": {
        "lat": 43.92777633666992,
        "lng": 11.039999961853027
      }
    },
    {
      "codice": "100004",
      "nome": "Poggio a Caiano",
      "nomeStraniero": null,
      "codiceCatastale": "G754",
      "cap": "59016",
      "prefisso": "055",
      "provincia": "Prato",
      "email": "info@comune.poggio-a-caiano.po.it",
      "pec": "comune.poggioacaiano@postacert.toscana.it",
      "telefono": "+39 055 87011",
      "fax": "+39 055 8779807",
      "coordinate": {
        "lat": 43.81666564941406,
        "lng": 11.066666603088379
      }
    },
    {
      "codice": "100005",
      "nome": "Prato",
      "nomeStraniero": null,
      "codiceCatastale": "G999",
      "cap": "59100",
      "prefisso": "0574",
      "provincia": "Prato",
      "email": "gabinetto.sindaco@comune.prato.it",
      "pec": "comune.prato@postacert.toscana.it",
      "telefono": "+39 0574 18361",
      "fax": "+39 0574 1836245",
      "coordinate": {
        "lat": 43.88081359863281,
        "lng": 11.096561431884766
      }
    },
    {
      "codice": "100006",
      "nome": "Vaiano",
      "nomeStraniero": null,
      "codiceCatastale": "L537",
      "cap": "59021",
      "prefisso": "0574",
      "provincia": "Prato",
      "email": "vaiano@comune.vaiano.po.it",
      "pec": "comune.vaiano@postecert.it",
      "telefono": "+39 0574 94241",
      "fax": "+39 0574 942439",
      "coordinate": {
        "lat": 43.96666717529297,
        "lng": 11.116666793823242
      }
    },
    {
      "codice": "100007",
      "nome": "Vernio",
      "nomeStraniero": null,
      "codiceCatastale": "L775",
      "cap": "59026",
      "prefisso": "0574",
      "provincia": "Prato",
      "email": "comune@comune.vernio.po.it",
      "pec": "comune.vernio@postacert.toscana.it",
      "telefono": "+39 0574 931011",
      "fax": "+39 0574 931051",
      "coordinate": {
        "lat": 44.04999923706055,
        "lng": 11.149999618530273
      }
    }
  ],
  "Gorizia": [
    {
      "codice": "031001",
      "nome": "Capriva del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "B712",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@comune.caprivadelfriuli.go.it",
      "pec": "comune.caprivadelfriuli@certgov.fvg.it",
      "telefono": "+39 048180032",
      "fax": "+39 048180121",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 13.516666412353516
      }
    },
    {
      "codice": "031002",
      "nome": "Cormons",
      "nomeStraniero": null,
      "codiceCatastale": "D014",
      "cap": "34071",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@com-cormons.regione.fvg.it",
      "pec": "comune.cormons@certgov.fvg.it",
      "telefono": "+39 0481637103",
      "fax": "+39 0481637112",
      "coordinate": {
        "lat": 45.95000076293945,
        "lng": 13.466666221618652
      }
    },
    {
      "codice": "031003",
      "nome": "Doberdò del Lago-Doberdob",
      "nomeStraniero": null,
      "codiceCatastale": "D312",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@com-doberdo-del-lago.regione.fvg.it",
      "pec": "comune.doberdodellago@certgov.fvg.it",
      "telefono": "+39 048178108",
      "fax": "+39 048178160",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "031004",
      "nome": "Dolegna del Collio",
      "nomeStraniero": null,
      "codiceCatastale": "D321",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@com-dolegna-del-collio.regione.fvg.it",
      "pec": "comune.dolegnadelcollio@certgov.fvg.it",
      "telefono": "+39 048160346",
      "fax": "+39 0481639885",
      "coordinate": {
        "lat": 46.03333282470703,
        "lng": 13.483333587646484
      }
    },
    {
      "codice": "031005",
      "nome": "Farra d'Isonzo",
      "nomeStraniero": null,
      "codiceCatastale": "D504",
      "cap": "34072",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "segreteria@comune.farradisonzo.go.it",
      "pec": "comune.farradisonzo@certgov.fvg.it",
      "telefono": "+39 0481888002",
      "fax": "+39 0481888609",
      "coordinate": {
        "lat": 45.900001525878906,
        "lng": 13.516666412353516
      }
    },
    {
      "codice": "031006",
      "nome": "Fogliano Redipuglia",
      "nomeStraniero": null,
      "codiceCatastale": "D645",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "servizi.demografici@com-fogliano-redipuglia.regione.fvg.it",
      "pec": "comune.foglianoredipuglia@certgov.fvg.it",
      "telefono": "+39 0481489177",
      "fax": "+39 0481488509",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 13.483333587646484
      }
    },
    {
      "codice": "031007",
      "nome": "Gorizia",
      "nomeStraniero": null,
      "codiceCatastale": "E098",
      "cap": "34170",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "roberto.cadeddu@comune.gorizia.it",
      "pec": "comune.gorizia@certgov.fvg.it",
      "telefono": "+39 0481383111",
      "fax": "+39 0481536184",
      "coordinate": {
        "lat": 45.93519973754883,
        "lng": 13.61929988861084
      }
    },
    {
      "codice": "031008",
      "nome": "Gradisca d'Isonzo",
      "nomeStraniero": null,
      "codiceCatastale": "E124",
      "cap": "34072",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "elisabetta.anaglo@comune.gradiscadisonzo.go.it",
      "pec": "comune.gradiscadisonzo@certgov.fvg.it",
      "telefono": "+39 0481967925",
      "fax": "+39 0481960622",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 13.5
      }
    },
    {
      "codice": "031009",
      "nome": "Grado",
      "nomeStraniero": null,
      "codiceCatastale": "E125",
      "cap": "34073",
      "prefisso": "0431",
      "provincia": "Gorizia",
      "email": "protocollo@comunegrado.it",
      "pec": "comune.grado@certgov.fvg.it",
      "telefono": "+39 0431898111",
      "fax": "+39 043185212",
      "coordinate": {
        "lat": 45.68333435058594,
        "lng": 13.399999618530273
      }
    },
    {
      "codice": "031010",
      "nome": "Mariano del Friuli",
      "nomeStraniero": null,
      "codiceCatastale": "E952",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@comune.marianodelfriuli.regione.fvg.it",
      "pec": "comune.marianodelfriuli@certgov.fvg.it",
      "telefono": "+39 048169391",
      "fax": "+39 048169527",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 13.466666221618652
      }
    },
    {
      "codice": "031011",
      "nome": "Medea",
      "nomeStraniero": null,
      "codiceCatastale": "F081",
      "cap": "34076",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@com-medea.regione.fvg.it",
      "pec": "comune.medea@certgov.fvg.it",
      "telefono": "+39 048167012",
      "fax": "+39 048167325",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 13.416666984558105
      }
    },
    {
      "codice": "031012",
      "nome": "Monfalcone",
      "nomeStraniero": null,
      "codiceCatastale": "F356",
      "cap": "34074",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@comune.monfalcone.go.it",
      "pec": "comune.monfalcone@certgov.fvg.it",
      "telefono": "+39 0481494111",
      "fax": "+39 048145889",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "031013",
      "nome": "Moraro",
      "nomeStraniero": null,
      "codiceCatastale": "F710",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@com-moraro.regione.fvg.it",
      "pec": "comune.moraro@certgov.fvg.it",
      "telefono": "+39 048180046",
      "fax": "+39 048180185",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 13.5
      }
    },
    {
      "codice": "031014",
      "nome": "Mossa",
      "nomeStraniero": null,
      "codiceCatastale": "F767",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "elena.zandomeni@comune.mossa.go.it",
      "pec": "comune.mossa@certgov.fvg.it",
      "telefono": "+39 048180009",
      "fax": "+39 048180339",
      "coordinate": {
        "lat": 45.93790054321289,
        "lng": 13.553199768066406
      }
    },
    {
      "codice": "031015",
      "nome": "Romans d'Isonzo",
      "nomeStraniero": null,
      "codiceCatastale": "H514",
      "cap": "34076",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@comune.romans.go.it",
      "pec": "comune.romansdisonzo@certgov.fvg.it",
      "telefono": "+39 0481966911",
      "fax": "+39 0481909282",
      "coordinate": {
        "lat": 45.88333511352539,
        "lng": 13.433333396911621
      }
    },
    {
      "codice": "031016",
      "nome": "Ronchi dei Legionari",
      "nomeStraniero": null,
      "codiceCatastale": "H531",
      "cap": "34077",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "info@com-ronchi-dei-legionari.regione.fvg.it",
      "pec": "comune.ronchideilegionari@certgov.fvg.it",
      "telefono": "+39 0481477111",
      "fax": "+39 0481477231",
      "coordinate": {
        "lat": 45.83333206176758,
        "lng": 13.5
      }
    },
    {
      "codice": "031017",
      "nome": "Sagrado",
      "nomeStraniero": null,
      "codiceCatastale": "H665",
      "cap": "34078",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "segreteria.generale@com-sagrado.regione.fvg.it",
      "pec": "comune.sagrado@certgov.fvg.it",
      "telefono": "+39 0481 93308",
      "fax": "+39 0481 99222",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 13.483333587646484
      }
    },
    {
      "codice": "031018",
      "nome": "San Canzian d'Isonzo",
      "nomeStraniero": null,
      "codiceCatastale": "H787",
      "cap": "34075",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "info@comune.sancanziandisonzo.go.it",
      "pec": "comune.sancanziandisonzo@certgov.fvg.it",
      "telefono": "+39 0481472311",
      "fax": "+39 0481472334",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.466666221618652
      }
    },
    {
      "codice": "031019",
      "nome": "San Floriano del Collio-Števerjan",
      "nomeStraniero": null,
      "codiceCatastale": "H845",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "segreteria@com-san-floriano-del-collio.regione.fvg.it",
      "pec": "comune.sanflorianodelcollio@certgov.fvg.it",
      "telefono": "+39 0481884135",
      "fax": "+39 0481884221",
      "coordinate": {
        "lat": 45.983333587646484,
        "lng": 13.583333015441895
      }
    },
    {
      "codice": "031020",
      "nome": "San Lorenzo Isontino",
      "nomeStraniero": null,
      "codiceCatastale": "H964",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "affari.generali@com-san-lorenzo-isontino.regione.fvg.it",
      "pec": "comunedisanlorenzoisontino@legalmail.it",
      "telefono": "+39 048180026",
      "fax": "+39 0481809864",
      "coordinate": {
        "lat": 45.93333435058594,
        "lng": 13.533333778381348
      }
    },
    {
      "codice": "031021",
      "nome": "San Pier d'Isonzo",
      "nomeStraniero": null,
      "codiceCatastale": "I082",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "servizi.demografici@comune.sanpierdisonzo.go.it",
      "pec": "comune.sanpierdisonzo@certgov.fvg.it",
      "telefono": "+39 0481708032",
      "fax": "+39 048170376",
      "coordinate": {
        "lat": 45.849998474121094,
        "lng": 13.466666221618652
      }
    },
    {
      "codice": "031022",
      "nome": "Savogna d'Isonzo-Sovodnje ob Soči",
      "nomeStraniero": null,
      "codiceCatastale": "I479",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "segreteria@comune.savognadisonzo.go.it",
      "pec": "comune.savognadisonzo@certgov.fvg.it",
      "telefono": "+39 0481882001",
      "fax": "+39 0481882447",
      "coordinate": {
        "lat": 45.91666793823242,
        "lng": 13.583333015441895
      }
    },
    {
      "codice": "031023",
      "nome": "Staranzano",
      "nomeStraniero": null,
      "codiceCatastale": "I939",
      "cap": "34079",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "postmaster@com-staranzano.regione.fvg.it",
      "pec": "comune.staranzano@certgov.fvg.it",
      "telefono": "+39 0481716930",
      "fax": "+39 0481716919",
      "coordinate": {
        "lat": 45.79999923706055,
        "lng": 13.5
      }
    },
    {
      "codice": "031024",
      "nome": "Turriaco",
      "nomeStraniero": null,
      "codiceCatastale": "L474",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@com-turriaco.regione.fvg.it",
      "pec": "comune.turriaco@certgov.fvg.it",
      "telefono": "+39 0481472720",
      "fax": "+39 0481767266",
      "coordinate": {
        "lat": 45.81666564941406,
        "lng": 13.449999809265137
      }
    },
    {
      "codice": "031025",
      "nome": "Villesse",
      "nomeStraniero": null,
      "codiceCatastale": "M043",
      "cap": "34070",
      "prefisso": "0481",
      "provincia": "Gorizia",
      "email": "protocollo@comune.villesse.go.it",
      "pec": "comune.villesse@certgov.fvg.it",
      "telefono": "+39 048191026",
      "fax": "+39 048191390",
      "coordinate": {
        "lat": 45.86666488647461,
        "lng": 13.433333396911621
      }
    }
  ],
  "Trieste": [
    {
      "codice": "032001",
      "nome": "Duino Aurisina-Devin Nabrežina",
      "nomeStraniero": null,
      "codiceCatastale": "D383",
      "cap": "34011",
      "prefisso": "040",
      "provincia": "Trieste",
      "email": "protocollo@comune.duino-aurisina.ts.it",
      "pec": "comune.duinoaurisina@certgov.fvg.it",
      "telefono": "+39 040 2017111",
      "fax": "+39 040 200245",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 13.75
      }
    },
    {
      "codice": "032002",
      "nome": "Monrupino-Repentabor",
      "nomeStraniero": null,
      "codiceCatastale": "F378",
      "cap": "34016",
      "prefisso": "040",
      "provincia": "Trieste",
      "email": "segreteria@com-monrupino.regione.fvg.it",
      "pec": "comune.monrupino@certgov.fvg.it",
      "telefono": "+39 040 327122",
      "fax": "+39 040 327511",
      "coordinate": {
        "lat": 45.72283935546875,
        "lng": 13.790980339050293
      }
    },
    {
      "codice": "032003",
      "nome": "Muggia",
      "nomeStraniero": null,
      "codiceCatastale": "F795",
      "cap": "34015",
      "prefisso": "040",
      "provincia": "Trieste",
      "email": "protocollo@comunedimuggia.ts.it",
      "pec": "comune.muggia@certgov.fvg.it",
      "telefono": "+39 0403360111",
      "fax": "+39 040330202",
      "coordinate": {
        "lat": 45.599998474121094,
        "lng": 13.766666412353516
      }
    },
    {
      "codice": "032004",
      "nome": "San Dorligo della Valle-Dolina",
      "nomeStraniero": null,
      "codiceCatastale": "D324",
      "cap": "34018",
      "prefisso": "040",
      "provincia": "Trieste",
      "email": "protocollo-protokol@sandorligo-dolina.it",
      "pec": "comune-obcina.sandorligodellavalle-dolina@certgov.fvg.it",
      "telefono": "+39 0408329111",
      "fax": "+39 040228874",
      "coordinate": {
        "lat": 45.622501373291016,
        "lng": 13.85777759552002
      }
    },
    {
      "codice": "032005",
      "nome": "Sgonico-Zgonik",
      "nomeStraniero": null,
      "codiceCatastale": "I715",
      "cap": "34010",
      "prefisso": "040",
      "provincia": "Trieste",
      "email": "segreteria@com-sgonico.regione.fvg.it",
      "pec": "comune-obcina.sgonico-zgonik@certgov.fvg.it",
      "telefono": "+39 040 229150",
      "fax": "+39 040 229422",
      "coordinate": {
        "lat": 45.733333587646484,
        "lng": 13.75
      }
    },
    {
      "codice": "032006",
      "nome": "Trieste",
      "nomeStraniero": null,
      "codiceCatastale": "L424",
      "cap": "34136",
      "prefisso": "040",
      "provincia": "Trieste",
      "email": "elettorale@comune.trieste.it",
      "pec": "comune.trieste@certgov.fvg.it",
      "telefono": "+39 040 6751",
      "fax": "+39 040 6756060",
      "coordinate": {
        "lat": 45.65027618408203,
        "lng": 13.770277976989746
      }
    }
  ]
};

export default italyCities;
