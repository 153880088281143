import {Button, Card, CardContent, Container, Grid, TextField, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";
import React from "react";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import {Link} from "react-router-dom";

const SearchForm = () => {
    const [searchText, setSearchText] = React.useState<string>('');

    return <Card sx={{width: '100%', backgroundColor:'#f0cb00'}} elevation={3}>
        <CardContent >
            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign:'center' }} gutterBottom>
                Sfoglia prodotti e servizi
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign:'center' }} gutterBottom>
                Digita il nome di un prodotto o servizio per avviare la ricerca tra le risorse offerte dai partner associati
            </Typography>
            <Container maxWidth='md'>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}>
                    <ResponsiveGridItem md={10}>
                        <TextField
                            variant="standard"
                            onChange={event => setSearchText(event.currentTarget.value)}
                            fullWidth
                            sx={{backgroundColor:'white'}}
                        />
                    </ResponsiveGridItem>
                    <ResponsiveGridItem md={2}>
                        <Button
                            component={Link}
                            variant="contained"
                            fullWidth
                            state={{searchText}}
                            to="/products"
                            color="secondary"
                            size="small">
                            <Search/> Ricerca
                        </Button>
                    </ResponsiveGridItem>
            </Grid>
            </Container>
        </CardContent>
    </Card>
}

export default SearchForm;
