import { Box } from "@mui/material";
import React from 'react';
import { useLocation } from 'react-router-dom';
import DemandsTable from "../../components/admin/demands/DemandsTable";
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';
import AdminLayout from "../../layouts/admin/AdminLayout";
import AuthContext from '../../route/AuthContext';

const Demands = () => {

  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);

  return (
    <AdminLayout
      breadcrumb={contentBreadcrumb}
      title="Fabbisogni | Piattaforma Innovation ReSearch"
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1
      }}>
        <DemandsTable />
      </Box>
    </AdminLayout>
  );
};

export default Demands;
