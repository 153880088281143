import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import ParameterDocumentFormatDTO from "../model/dto/ParameterDocumentFormatDTO";
import ParameterReminderDTO from "../model/dto/ParameterReminderDTO";
import http from "../utils/HttpCommon";

const getReminderParameters = async (context: LoggedUserContext) => {
	try {
		const response = await http.privateClient(context).get<any>('/parameters/reminder');

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const getDocumentFormatParameters = async (context: LoggedUserContext) => {
	try {
		const response = await http.privateClient(context).get<any>('/parameters/productFormat');

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const saveReminderParameters = async (context: LoggedUserContext, reminderParamenters: ParameterReminderDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/parameters/reminder', reminderParamenters);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const saveDocumentFormat = async (context: LoggedUserContext, documentFormatParameters: ParameterDocumentFormatDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/parameters/productFormat', documentFormatParameters);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response.status, undefined];
	}
}

const ParameterService = {
	getReminderParameters,
    getDocumentFormatParameters,
    saveDocumentFormat,
    saveReminderParameters
}

export default ParameterService;