import { DialogContentText, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import { HttpStatusCode } from "axios";
import { useFormik } from "formik";
import * as React from 'react';
import { SweetAlertOptions } from "sweetalert2";
import * as Yup from "yup";
import AuthContext from "../../route/AuthContext";
import UserService from "../../services/UserService";
import CustomSweetalert from "../../theme/sweetalert";
import CircularLoading from "./CircularLoading";
import CustomDialog from "./CustomDialog";

interface InviteModalProps {
    open: boolean,
    handleClose: () => void
}

const InviteModal = ({ open, handleClose }: InviteModalProps) => {

    const authContext = React.useContext(AuthContext);
    const handleResetAndClose = () => {
        if (!formik.isSubmitting) {
            formik.resetForm();
            handleClose();
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string().email("Inserisci un'email valida").required("L'email e' un campo obbligatorio"),
        }),
        onSubmit: async (values) => {
            let swOptions: SweetAlertOptions = {
                title: 'Completato',
                text: "E' stata inviata una mail di invito all'email indicata.",
                icon: 'success'
            };

            const [status, response] = await UserService.inviteNewUser(authContext, values.email);
            if (status && response && status === HttpStatusCode.Ok) {
                switch (response) {
                    case 'SENT':
                        break;
                    case 'ALREADY_EXISTS':
                        swOptions.title = 'Errore';
                        swOptions.text = "Esiste gia' un invito rispetto la email indicata inviato dalla tua utenza.";
                        swOptions.icon = 'error';
                        break;
                    case 'EMAIL_ALREADY_EXISTS':
                        swOptions.title = 'Errore';
                        swOptions.text = "Esiste gia' un utente nella piattaforma con l'email indiicata.";
                        swOptions.icon = 'error';
                        break;
                    case 'EMPTY':
                        swOptions.title = 'Errore';
                        swOptions.text = "La mail non può essere vuota";
                        swOptions.icon = 'error';
                        break;
                    default:
                        swOptions.title = 'Errore';
                        swOptions.text = 'Errore durante la comunicazione con il server.';
                        swOptions.icon = 'error';
                        break;
                }
            } else {
                swOptions.title = 'Errore';
                swOptions.text = 'Errore durante la comunicazione con il server.';
                swOptions.icon = 'error';
            }

            CustomSweetalert(swOptions);
        },
    });

    return (
        <CustomDialog
            open={open ? open : false}
            handleClose={handleResetAndClose}
            title="Invita nuovo utente"
            content={
                <>
                    <DialogContentText>
                        Inserisci la mail a cui inviare invito di iscrizione alla piattaforma
                    </DialogContentText>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        id="email"
                        name="email"
                        type="email"
                        inputProps={{ style: { textTransform: 'lowercase' } }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </>
            }
            actions={
                <>
                    <Button color="inherit" disabled={formik.isSubmitting} onClick={handleResetAndClose}>
                        Chiudi
                    </Button>
                    {open && <Button variant="contained" disabled={!formik.isValid} onClick={formik.submitForm} autoFocus>
                        Invia
                        {formik.isSubmitting && <CircularLoading />}
                    </Button>}
                </>
            }
        />
    );
}

export default InviteModal;