import React from "react";

const LogoUnisalentoMari = (props: React.ComponentPropsWithoutRef<any>) => (
    <img
        alt="Logo Unisalento"
        width="100%"
        height="auto"
        src={props.src ?? "/static/Uni2Mari.png" }
        {...props}
    />
);

export default LogoUnisalentoMari;
