import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {Button, Card, CardContent, Stack, Typography} from "@mui/material";
import KeywordService from "../../../services/KeywordService";
import {HttpStatusCode} from "axios";
import Keyword from "../../../model/database/Keyword";

interface FilterCardProps {
  handleSelection?: (selected: string[]) => void
}

const FilterCard = ({handleSelection}: FilterCardProps) => {
  const [checked, setChecked] = React.useState<string[]>([]);
  const [keywords, setKeywords] = React.useState<Keyword[]>([]);

  const loadData = async () => {
    let [status, response] = await KeywordService.getAllPublic();
    if (status && response && status === HttpStatusCode.Ok) {
      setKeywords((response as any)._embedded.keywords);
    }
  }

  React.useEffect(() => {
    loadData();
  }, [])

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    handleSelection?.(newChecked);
  };

  const handleReset = () => {
    setChecked([]);
    handleSelection?.([]);
  }

  return (
    <Card sx={{width: '100%'}}>
      <CardContent>
        <Stack direction={{md: 'row', sm: 'column', xs: 'column'}} justifyContent="space-between">
          <Typography variant="h6">Metadati</Typography>
          <Button variant="outlined" color="secondary" onClick={handleReset}>Reset</Button>
        </Stack>
        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {keywords.map((value) => {
            return (
              <ListItem
                key={value.id}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    color="secondary"
                    onChange={handleToggle(value.id ?? '')}
                    checked={checked.indexOf(value.id ?? '') !== -1}
                    inputProps={{ 'aria-labelledby': value.id }}
                  />
                }
                disablePadding
              >
                <ListItemButton onClick={handleToggle(value.id ?? '')}>
                  <ListItemText id={value.id} primary={value.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}

export default FilterCard;
