import React from "react";

const LogoPNR = (props: React.ComponentPropsWithoutRef<any>) => (
    <img
        alt="Logo IStore"
        width="100%"
        height="auto"
        src={props.src ?? "/static/pnr.png" }
        {...props}
    />
);

export default LogoPNR;
