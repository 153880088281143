import AdminLayout from "../../layouts/admin/AdminLayout";
import React from "react";
import PaymentsTable from "../../components/admin/payments/PaymentsTable";
import AuthContext from "../../route/AuthContext";
import {useLocation} from "react-router-dom";
import BreadcrumbGenerator from "../../components/common/BreadcrumbGenerator";

const Payments = () => {

  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);

  return (
    <AdminLayout
      breadcrumb={contentBreadcrumb}
      title="Gestione parole chiave | Piattaforma Innovation ReSearch"
    >
      <PaymentsTable />
    </AdminLayout>
  );
};

export default Payments;
