import {Grid, useMediaQuery, useTheme} from "@mui/material";
import LogoUnisalento from "../../common/logo/LogoUnisalento";
import LogoInnovationResearch from "../../common/logo/LogoInnovationResearch";
import LogoUnisalentoMari from '../../common/logo/LogoUnisalentoMari';
import LogoIStore from '../../common/logo/LogoIStore';
import LogoPNR from "../../common/logo/LogoPnr";


const Logos = () => {

    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));

    return <Grid container display="flex" alignItems="center" justifyContent="space-evenly">
        <Grid item container xs={12} md={6} p={3}>
            <Grid item xs={8} md={6}>
                <LogoInnovationResearch />
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} spacing={3} p={3} display="flex" alignItems="center" sx={{backgroundColor: upMd ? 'inherit': theme.palette.background.default}}>
            <Grid item xs={4} p={1}>
                <LogoUnisalentoMari />
            </Grid>
            <Grid item xs={4} p={1}>
                <LogoIStore />
            </Grid>
            <Grid item xs={4} p={1}>
                <LogoPNR />
            </Grid>
        </Grid>

    </Grid>
}

export default Logos;