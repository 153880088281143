import { Box, Card, CardContent } from "@mui/material";
import { GridSelectionModel, itIT } from "@mui/x-data-grid";
import { HttpStatusCode } from "axios";
import React from "react";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Keyword from "../../../model/database/Keyword";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchProduct from "../../../model/database/ResearchProduct";
import ResearchProductDTO from "../../../model/dto/ResearchProductDTO";
import ProductsFilters from "../../../model/filters/ProductsFilters";
import ProductsTableColumns from "../../../model/table/column/ProductsTableColumns";
import { ProductRow } from "../../../model/table/row/ProductRow";
import demandSortFields from "../../../model/table/sort/ProductSortFields";
import AuthContext from "../../../route/AuthContext";
import KeywordService from "../../../services/KeywordService";
import ProductService from "../../../services/ProductService";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import ModalState from "../../common/table/ModalState";
import { StyledTable } from "../../common/table/StyledTable";
import ProductModal from "./ProductModal";
import ProductsTableToolbar from "./ProductsTableToolbar";

const ProductsTable = () => {

    const authContext = React.useContext(AuthContext);

    const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
    const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
    });
    const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
        page: 0,
        size: 10,
        sort: demandSortFields[0].value + ',asc'
    });
    const [filterRequest, setFilterRequest] = React.useState<ProductsFilters>(
        authContext.user?.role === 'RESEARCH_PRODUCER' ? {
        producer: {
            label: 'Produttore',
            value: authContext.user?.idParent ? authContext.user?.idParent : authContext.user?.id
        }
    } : {});

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = React.useState<ProductRow[]>([]);
    const [researchProducts, setResearchProducts] = React.useState<ResearchProduct[]>([]);
    const [researchProducers, setResearchProducers] = React.useState<ResearchProducer[]>([]);
    const [keywords, setKeywords] = React.useState<Keyword[]>([]);

    const [modalState, setModalState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModal = () => setModalState(() => ({data: undefined, open: false, edit:false}));

    const handleShow = (id: string) => {
        setModalState({
            open: true,
            data: researchProducts.filter(value => value.product?.id === id)[0],
            edit: false
        });
    };

    const handleEdit = (id: string) => {
        setModalState({
            open: true,
            data: researchProducts.filter(value => value.product?.id === id)[0],
            edit: true
        });
    };

    const handleClone = (id: string) => {
        const product = researchProducts.find(value => value.product?.id === id);
        setModalState({
            open: true,
            data: {...product, producer: undefined, product: {...product?.product, id: undefined, keywords: []}},
            edit: true
        });
    };

    const handleSave = (productToSave: ResearchProductDTO) => {
        return new Promise<boolean>(async resolve => {
            let [status, response] = [undefined, undefined];
            if (!modalState.data || !modalState.data?.product?.id) {
                [status, response] = await ProductService.saveResearchProduct(authContext, productToSave);
            } else {
                [status, response] = await ProductService.updateResearchProduct(authContext, productToSave, modalState.data.id);
            }

            if (status && response && status === HttpStatusCode.Ok) {
                let swOptions: SweetAlertOptions = {
                    title: 'Completato',
                    text: 'Prodotto di ricerca aggiunto correttamente.',
                    icon: 'success'
                };

                switch (response) {
                    case 'SAVED':
                        resolve(true);
                        handleCloseModal();
                        break;
                    case 'UPDATED':
                        resolve(true);
                        swOptions.text = 'Prodotto di ricerca aggiornato correttamente.';
                        handleCloseModal();
                        break;
                    default:
                        resolve(false);
                        swOptions.title = 'Errore';
                        swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                        swOptions.icon = 'error';
                        break;
                }
                CustomSweetalert(swOptions).then(() => {
                    reloadData();
                });
            }else{
                resolve(false);
                CustomSweetalert({
                    title: 'Errore',
                    text: 'Si è verificato un problema durante il salvataggio.',
                    icon: 'error'
                });
            }

        });
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Elimina',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.deleteById(authContext, id);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Prodotto di ricerca eliminato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'DELETED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile eliminare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        })
    };

    const handleApprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler approvare il prodotto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Attiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.changeApprovedById(authContext, id, true);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Prodotto di ricerca approvato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        case 'NOT_UPDATED':
                            swOptions.title = 'Errore';
                            swOptions.text = 'L\'elemento selezionato ha parole chiave associate non approvate.';
                            swOptions.icon = 'error';
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile approvare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleDisapprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler disapprovare il prodotto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Disattiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await ProductService.changeApprovedById(authContext, id, false);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Approvazione rimossa correettamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile rimuovere l\'approvazione all\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile rimuovere l\'approvazione all\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const applyFilters = (filters: ProductsFilters) => {
        setFilterRequest(filters);
    }

    const applySort = (value: string) => {
        if (pageInfo.sort !== value) {
            setPageInfo((prevState) => ({...prevState, sort: value}));
        }
    }

    const loadResearchProducers = async () => {
        const [status, response] = await UserService.getAllProducers(authContext, {page: 0, size: -1, sort: 'id,asc'});
        setResearchProducers(response ? response._embedded.researchProducers : []);
    }

    const loadKeywords = async () => {
        const [status, response] = await KeywordService.getAllActive(authContext, {page: 0, size: -1, sort: 'id,asc'});
        setKeywords(response ? response._embedded.keywords : []);
    }

    const mapResponseToRows = (products: ResearchProduct[]) => {
        return products.map(value => ({
            id: value.product?.id,
            approved: value.product?.approved,
            data: value
        }));
    }

    const reloadData = async () => {
        setLoading(true);
        let [status, response] = [undefined, undefined];
        if (Object.keys(filterRequest).length === 0)
            [status, response] = await ProductService.getAllResearchProduct(authContext, pageInfo);
        else
            [status, response] = await ProductService.getAllResearchProductFiltered(authContext, pageInfo, filterRequest);

        await loadResearchProducers();
        await loadKeywords();

        if (status && response && status === HttpStatusCode.Ok) {
            setPageInfoResponse((response as any).page);
            const products: ResearchProduct[] = (response as any)._embedded ? (response as any)._embedded.researchProducts.map((value: any) => ({
                ...value,
                product: {
                    ...value.product,
                    user: value.producer
                }
            })) : [];
            setResearchProducts(products);
            const productsRows: ProductRow[] = mapResponseToRows(products);
            setRows(productsRows);
            //setRows(authContext.user?.role !== 'RESEARCH_PRODUCER' ? productsRows : productsRows.filter(value => value.data?.producer?.user?.id === authContext.user?.id));
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }

        setLoading(false);
    };

    React.useEffect(() => {
        reloadData();
    }, [pageInfo, filterRequest]);

    return (
        <Card sx={{minWidth: 275, mt: 2, mb: 2}}>
            <CardContent>
                <ProductsTableToolbar
                    loggedUser={authContext.user}
                    numSelected={idSelected.length}
                    producers={researchProducers}
                    keywords={keywords}
                    handleNew={() => setModalState({open: true, data: undefined, edit: false})}
                    applyFilters={applyFilters}
                    applySort={applySort}
                />
                <Box sx={{height: 'auto', width: '100%'}}>
                    <StyledTable
                        loading={loading}
                        paginationMode="server"
                        rows={rows}
                        columns={ProductsTableColumns({
                            loggedUser: authContext.user,
                            showCallback: handleShow,
                            editCallback: handleEdit,
                            cloneCallback: handleClone,
                            deleteCallback: handleDelete,
                            approveCallback: handleApprove,
                            disapproveCallback: handleDisapprove,
                            showApproved: authContext.user?.role !== 'RESEARCH_CONSUMER'
                        })}
                        rowsPerPageOptions={[10, 15, 20]}
                        pageSize={pageInfo.size}
                        onPageSizeChange={newPageSize => setPageInfo((prevState) => ({
                            ...prevState,
                            size: newPageSize
                        }))}
                        onPageChange={newPage => setPageInfo((prevState) => ({...prevState, page: newPage}))}
                        rowCount={pageInfoResponse.totalElements}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
                        // checkboxSelection
                        getRowHeight={() => 'auto'}
                        disableSelectionOnClick
                        autoHeight
                        experimentalFeatures={{newEditingApi: false}}
                    />
                </Box>
            </CardContent>
            <ProductModal
                open={modalState.open}
                edit={modalState.edit}
                researchProduct={modalState.data}
                keywords={keywords}
                producers={researchProducers}
                loggedUser={authContext.user}
                handleClose={handleCloseModal}
                handleSave={handleSave}
            />
        </Card>
    )
}

export default ProductsTable;
