import { CheckCircle, Delete, Edit, Unpublished, Visibility } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DemandCard from "../../../components/common/table/DemandCard";
import i18n from "../../../i18n/i18n";
import LoggedUser from "../../auth/LoggedUser";
import { CommonColumnProps } from "../CommonColumnProps";

interface ProductsTableColumnsProps {
    loggedUser?: LoggedUser,
    showCallback?: (id: string) => any,
    editCallback?: (id: string) => any,
    deleteCallback?: (id: string) => any,
    approveCallback?: (id: string) => any,
    disapproveCallback?: (id: string) => any,
    showApproved?: boolean
}

const DemandsTableColumns = ({
                                  loggedUser,
                                  showCallback,
                                  editCallback,
                                  deleteCallback,
                                  approveCallback,
                                  disapproveCallback,
                                  showApproved
                              }: ProductsTableColumnsProps): GridColDef[] => [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        ...CommonColumnProps
    },
    {
        field: 'data',
        headerName: "Fabbisogno",
        renderCell: (params) => {
            return (
            <Box my={2} mx={1} width="100%">
                <DemandCard key={params.row.id} demand={params.row.data}
                             showState={(showApproved ?? false) && (loggedUser?.id === params.row.data.product.user.id || loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT')}/>
            </Box>
        )},
        ...CommonColumnProps,
        minWidth: 100,
        align: 'center',
        flex: 10,
    },
    {
        field: "action",
        headerName: i18n.t('table.operations') as string,
        hide: !showCallback && !deleteCallback,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
            return <Stack direction="row" spacing={0} alignItems="flex-end">
                <Tooltip title="Visualizza">
                    <IconButton color="secondary" onClick={() => showCallback ? showCallback(params.id as string) : ''}>
                        <Visibility/>
                    </IconButton>
                </Tooltip>
                {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT' ||
                        (loggedUser.role === 'RESEARCH_CONSUMER' && loggedUser?.id === params.row.data.product.user.id )
                    ) &&
                    <Tooltip title="Modifica">
                        <IconButton color="secondary"
                                    onClick={() => editCallback ? editCallback(params.id as string) : ''}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>}
                {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') &&
                    <Tooltip title={params.row.approved ? 'Disapprova' : 'Approva'}>
                        {!params.row.approved ? <IconButton color="secondary"
                                                            onClick={() => approveCallback ? approveCallback(params.id as string) : ''}>
                            <CheckCircle/>
                        </IconButton> : <IconButton color="secondary"
                                                    onClick={() => disapproveCallback ? disapproveCallback(params.id as string) : ''}>
                            <Unpublished/>
                        </IconButton>}
                    </Tooltip>}
                {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT' ||
                        (loggedUser.role === 'RESEARCH_CONSUMER' && loggedUser?.id === params.row.data.product.user.id)
                    ) &&
                    <Tooltip title="Rimuovi">
                        <IconButton color="error"
                                    onClick={() => deleteCallback ? deleteCallback(params.id as string) : ''}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>}
            </Stack>;
        },
        ...CommonColumnProps
    },
];

export default DemandsTableColumns;
