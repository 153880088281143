import {LabelValue} from "../../filters/LabelValue";

const paymentSortFields: LabelValue[] = [
  {
    label: 'Pagato',
    value: 'paid'
  },
  {
    label: 'Anno di riferimento',
    value: 'year'
  },
  {
    label: 'Utente',
    value: 'researchConsumer.businessName'
  }
]

export default paymentSortFields;
