import {Paper, Stack, Typography} from "@mui/material";
import * as React from 'react';
import researchProducerType from "../../constants/researchProducerType";
import StringGeneratorUtil from "./StringGeneratorUtil";

interface InterestedUserProps {
  userInfo?: any
}

const InterestedUser = ({ userInfo }: InterestedUserProps) => {

  const [info, setInfo] = React.useState<any>(userInfo);

  React.useEffect(() => {
    setInfo(userInfo);
  }, [userInfo]);

  return <>
    <Stack direction={{ md: "row", xs: "column", sm: "column" }} alignItems="stretch" spacing={1} mb={1}>
      <Paper sx={{ p: 2, flex: 1 }} elevation={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Stack direction="column" alignItems="start" justifyContent="space-between">
            {info?.user?.role?.id === 'RESEARCH_PRODUCER' && <Typography gutterBottom variant="h6">{StringGeneratorUtil.generateProducerInterestedLabel(userInfo.user)[0]}</Typography>}
            {info?.user?.role?.id === 'RESEARCH_PRODUCER' && <Typography gutterBottom variant="subtitle1" mb={1}>{StringGeneratorUtil.generateProducerInterestedLabel(userInfo.user)[1]}</Typography>}
            {info?.user?.role?.id === 'RESEARCH_CONSUMER' && <Typography gutterBottom variant="h6">{StringGeneratorUtil.generateConsumerInterestedLabel(userInfo.user)[0]}</Typography>}
            {info?.user?.role?.id === 'RESEARCH_CONSUMER' && <Typography gutterBottom variant="subtitle1" mb={1}>{StringGeneratorUtil.generateConsumerInterestedLabel(userInfo.user)[1]}</Typography>}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  </>
}

export default InterestedUser;
