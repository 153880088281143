import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import ResearchProductDTO from "../model/dto/ResearchProductDTO";
import ResearchServiceDTO from "../model/dto/ResearchServiceDTO";
import ProductsFilters from "../model/filters/ProductsFilters";
import http from '../utils/HttpCommon';

const changeApprovedById = async (context: LoggedUserContext, id: string, state: boolean) => {
	try {
		const response = await http.privateClient(context).put<any>(`/products/${id}/approved/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllResearchProduct = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/researchProducts/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllProductsPublic = async (pageInfo: PageInfoRequest, text: string, keywords?: string[]) => {
	try {
		const response = await http.publicClient.get<any>('/products/public/search', { params: {...pageInfo, text, keywords: keywords !== undefined ? keywords.join(',') : ''} });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllResearchServices = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/researchServices/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllResearchProductFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: ProductsFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = key === 'approved' ? lblValue.value === 'APPROVED' : lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/researchProducts/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllResearchServiceFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: ProductsFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = key === 'approved' ? lblValue.value === 'APPROVED' : lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/researchServices/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getDocumentResearchProductById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/researchProducts/${id}/document`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getDocumentResearchServiceById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/researchServices/${id}/document`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const saveResearchProduct = async (context: LoggedUserContext, rpDto: ResearchProductDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/researchProducts', { 
			...rpDto
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const updateResearchProduct = async (context: LoggedUserContext, rpDto: ResearchProductDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/researchProducts/${id}`, rpDto);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const saveResearchService = async (context: LoggedUserContext, rsDTO: ResearchServiceDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/researchServices', rsDTO);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const updateResearchService = async (context: LoggedUserContext, rsDTO: ResearchServiceDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/researchServices/${id}`, rsDTO);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/products/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const ProductService = {
	changeApprovedById,
	getAllResearchProduct,
	getAllResearchServices,
	getAllResearchProductFiltered,
	getAllResearchServiceFiltered,
	getDocumentResearchProductById,
	getDocumentResearchServiceById,
	saveResearchProduct, 
	updateResearchProduct,
	saveResearchService,
	updateResearchService,
	deleteById,
	getAllProductsPublic
}

export default ProductService;