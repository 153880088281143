const formatDate = (date:string) => {
    const splitted = date.split("-");
    const yyyymmdd = splitted[0];
    return yyyymmdd.substring(0, 4) + "-" + yyyymmdd.substring(4, 6) + "-" + yyyymmdd.substring(6); 
}

const formatDate4View = (date:string) => {
    if(!date)
        return '';
    const splitted = date.split("-");
    return splitted[2] + "/" + splitted[1] + "/" + splitted[0];
}

const DateUtil = {
    formatDate,
    formatDate4View
}
export default DateUtil;