import {LabelValue} from "../model/filters/LabelValue";

const researchServiceTypes: LabelValue[] = [
    {
        label: 'Seminario sincrono',
        value: 'SYNC_SEMINAR',
    },
    {
        label: 'Seminario asincrono',
        value: 'ASYNC_SEMINAR',
    },
    {
        label: 'Pillola',
        value: 'PILL',
    },
    {
        label: 'Workshop',
        value: 'WORKSHOP',
    },
    {
        label: 'Conferenza',
        value: 'CONFERENCE',
    },
];

export default researchServiceTypes;