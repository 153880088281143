import {LabelValue} from "../../filters/LabelValue";

const challengeSortFields: LabelValue[] = [
  {
    label: 'Approvata',
    value: 'product.approved'
  },
  {
    label: 'Stato',
    value: 'product.state'
  },
  {
    label: 'Nome',
    value: 'product.name'
  }
]

export default challengeSortFields;
