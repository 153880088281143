import {LabelValue} from "../../filters/LabelValue";

const keywordSortFields: LabelValue[] = [
  {
    label: 'Nome',
    value: 'name'
  },
  {
    label: 'Categoria',
    value: 'sdg.name'
  }
]

export default keywordSortFields;
