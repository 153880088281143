import { Box, Grid } from "@mui/material";
import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ResponsiveGridItem from "../../components/common/ResponsiveGridItem";
import FilterCard from "../../components/public/products/FilterCard";
import ProductListCard from "../../components/public/products/ProductListCard";
import SearchCard from "../../components/public/products/SearchCard";
import MainLayout from "../../layouts/public/MainLayout";

const Products = () => {
    const location = useLocation();

    const [searchText, setSearchText] = React.useState<string>(location.state?.searchText ?? '');
    const [keywords, setKeywords] = React.useState<string[]>([]);

    const handleKeywords = (ids: string[]) => {
        setKeywords(ids);
    }

    React.useEffect(() => {
        setSearchText(location.state?.searchText ?? '');
    }, [location.state]);

    return (
        <MainLayout showLogo>
            <Helmet>
                <title>Risultati prodotti e servizi | Piattaforma Innovation ReSearch</title>
            </Helmet>
            <Box m={2}>
                <Grid container spacing={2}>
                    <ResponsiveGridItem>
                        <SearchCard searchText={searchText} handleSearch={(value: string) => {
                            setSearchText(value);
                        }}/>
                    </ResponsiveGridItem>
                    <ResponsiveGridItem md={4} lg={3}>
                        <FilterCard handleSelection={handleKeywords}/>
                    </ResponsiveGridItem>
                    <ResponsiveGridItem md={8} lg={9}>
                        <ProductListCard
                            searchText={searchText}
                            keywords={keywords}
                        />
                    </ResponsiveGridItem>
                </Grid>
            </Box>
        </MainLayout>
    );
};

export default Products;
