import {CheckCircle, Delete, Edit, Unpublished} from "@mui/icons-material";
import {Chip, IconButton, Stack, Tooltip} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {CommonColumnProps} from "../CommonColumnProps";
import React from "react";

interface KeywordsTableColumnsProps {
    editCallback: (id: string) => any,
    deleteCallback: (id: string) => any,
    approveCallback: (id: string, status: string) => any,
    disapproveCallback: (id: string, status: string) => any,
}

const KeywordsTableColumns = ({
                                  editCallback,
                                  approveCallback,
                                  disapproveCallback,
                                  deleteCallback
                              }: KeywordsTableColumnsProps): GridColDef[] => [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        ...CommonColumnProps
    },
    {
        field: 'state',
        headerName: 'Stato',
        ...CommonColumnProps,
        renderCell: (params) => (<Chip color={params.row.state === 'ACTIVE' ? 'success' : 'error'}
                                       label={params.row.state === 'ACTIVE' ? 'Approvata' : 'Non approvata'}/>)
    },
    {
        field: 'name',
        headerName: 'Nome',
        ...CommonColumnProps,
    },
    {
        field: 'description',
        headerName: 'Descrizione',
        ...CommonColumnProps,
        flex: 2
    },
    {
        field: 'sdgNames',
        headerName: 'Categorie',
        ...CommonColumnProps,
        renderCell: (params) => <ul>{params.row.sdgNames.map((name: string) => <li>{name}</li>)}</ul>
    },
    {
        field: "action",
        headerName: "Operazioni",
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
            return <Stack direction="row" spacing={1}>
                <Tooltip title="Modifica">
                    <IconButton color="secondary" onClick={() => editCallback(params.id as string)}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={params.row.state === 'INACTIVE' ? 'Approva' : 'Disapprova'}>
                    {!params.row.state || params.row.state === 'INACTIVE' ?
                        <IconButton color="secondary" onClick={() => approveCallback(params.id as string, 'ACTIVE')}>
                            <CheckCircle/>
                        </IconButton> :
                        <IconButton color="secondary"
                                    onClick={() => disapproveCallback(params.id as string, 'INACTIVE')}>
                            <Unpublished/>
                        </IconButton>}
                </Tooltip>
                <Tooltip title="Rimuovi">
                    <IconButton color="error" onClick={() => deleteCallback(params.id as string)}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </Stack>;
        },
        ...CommonColumnProps
    },
];

export default KeywordsTableColumns;
