import {Box, Container, Typography} from '@mui/material';
import React from 'react';
import LogoUnisalento from "../../common/logo/LogoUnisalento";
import LogoInnovationResearch from "../../common/logo/LogoInnovationResearch";
import {NavLink} from "react-router-dom";

const LoginRegister = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        boxShadow: 4,
        backgroundColor: 'primary.main'
      }}
    >
      <Container maxWidth="sm" sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <NavLink to="/" style={{
          width: '50%'
        }}>
          <LogoUnisalento style={{
            width: '100%'
          }}/>
        </NavLink>
        <LogoInnovationResearch variant="h4" height={64} width="auto"/>
        <Typography variant="subtitle1" mt={8}>Non sei un utente registrato?</Typography>
        <NavLink
          to="/registration"
          style={{
            color: 'black'
          }}
        >
          Registrati
        </NavLink>
      </Container>
    </Box>
  );
};

export default LoginRegister;
