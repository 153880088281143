import {LabelValue} from "../model/filters/LabelValue";

const devStages: LabelValue[] = [
    {
        label: 'Idea',
        value: 'IDEA',
    },
    {
        label: 'Valutazione fattibilità',
        value: 'FEASIBILITY',
    },
    {
        label: 'Prototipo',
        value: 'PROTOTYPE',
    },
    {
        label: 'Industrializzazione',
        value: 'INDUSTRY',
    },
    {
        label: 'Tesi',
        value: 'THESIS',
    },
];

export default devStages;