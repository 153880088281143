import {Alert, AlertColor, Box, Button, Container, TextField, Typography} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {Formik, FormikValues} from 'formik';
import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import AuthContext from '../../../route/AuthContext';
import AuthService from '../../../services/AuthService';
import CircularLoading from '../../common/CircularLoading';

interface AlertState {
  show: boolean,
  type: AlertColor,
  message: string
}

const LoginForm = (props: React.ComponentPropsWithoutRef<any>) => {

  const navigate = useNavigate();
  const { setUser } = React.useContext(AuthContext);

  const [alert, setAlert] = React.useState<AlertState>({
    show: false,
    type: 'error',
    message: 'Si è verificato un errore durante la connessione con il server.'
  });

  return (
    <Box
      sx={{
        boxShadow: 5,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: { lg: '100%' },
        justifyContent: 'center',
        p: { lg: 0, md: 2, sm: 2, xs: 2 },
        borderRadius: { lg: 0, md: 10, sm: 10, xs: 10 }
      }}
      {...props}
    >
      <Container>
        <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(255).required('Lo username è un campo obbligatorio e non può essere vuoto'),
            password: Yup.string().max(255).required('La password è un campo obbligatorio e non può essere vuoto')
          })}
          onSubmit={async (values: FormikValues) => {
            const [status, user] = await AuthService.login(values.username, values.password);
            if (user && setUser) {
              localStorage.setItem('user', JSON.stringify(user));
              setUser(user);
              if (user.role === 'ADMINISTRATOR' || user.role === 'COMMITTEE_MANAGEMENT') {
                navigate('/app/statistics', { replace: true });
              } else {
                navigate('/app/products', { replace: true });
              }
            } else {
              let newAlert: AlertState = {
                show: true,
                type: 'error',
                message: 'Si è verificato un errore durante la connessione con il server.'
              };

              switch (status) {
                case HttpStatusCode.Unauthorized:
                  newAlert.message = 'Le credenziali utilizzate non sono valide.';
                  newAlert.type = 'warning';
                  break;
                case HttpStatusCode.InternalServerError:
                  newAlert.message = 'Si è verificato un errore nel server remoto. Contatta l\'amministratore.';
                  break;
              }

              setAlert(newAlert);
              setTimeout(() => {
                setAlert({...newAlert, show: false});
              }, 5000);

            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="h4">Accedi</Typography>
              {alert.show && <Alert variant="outlined" severity={alert.type}>
                {alert.message}
              </Alert>}
              <TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                disabled={isSubmitting}
                value={values.username}
                required
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                required
                disabled={isSubmitting}
                value={values.password}
                variant="outlined"
              />
              <NavLink
                  to="/forgot-password"
                  style={{color: 'black'}}
              >
                Password dimenticata?
              </NavLink>
              <Box sx={{ py: 2 }}>
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Conferma
                    {isSubmitting && <CircularLoading />}
                  </Button>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default LoginForm;
