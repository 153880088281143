import axios, {AxiosInstance} from 'axios';
import LoggedUserContext from '../model/auth/LoggedUserContext';

const BASE_URL = (window as any).env.API_URL;

const publicClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json"
    }
});

const privateClient = (context: LoggedUserContext): AxiosInstance => {
    const instance = axios.create({
        baseURL: BASE_URL,
        headers: {
            "Content-type": "application/json"
        }
    });;
    instance.interceptors.request.use((request) => {
        request.headers.Authorization = context.user?.token;
        return request;
    }, (error) => Promise.reject(error));

    instance.interceptors.response.use((response) => {
        if (!response) {
            if(context.setUser){
                context.setUser({});
                localStorage.setItem('user', '{}');
            }
        }
        return response;
    }, (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            if(context.setUser){
                context.setUser({});
                localStorage.setItem('user', '{}');
            }
        } else if (!error.response) {
            if(context.setUser){
                context.setUser({});
                localStorage.setItem('user', '{}');
            }
            return error;
        }
        return error.response;
    });

    return instance;
}

const HttpCommon = {
    publicClient,
    privateClient
}

export default HttpCommon;