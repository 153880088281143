import { Box, Card, CardContent } from "@mui/material";
import { Formik, FormikValues } from "formik";
import * as React from 'react';
import * as Yup from "yup";
import researchConsumerType from "../../../../constants/researchConsumerType";
import researchProducerType from "../../../../constants/researchProducerType";
import RegexUtil from "../../../../utils/RegexUtil";
import RegistrationStepButtons from "../RegistrationStepButtons";
import RegistrationStepProps from "../props/RegistrationStepProps";
import RegistrationOptionalSection from "../section/RegistrationOptionalSection";

const RegistrationOptionalStep = ({userData, handleUpdateData, handleBack, activeStep}: RegistrationStepProps) => {
  const isProducer = userData && researchProducerType.map(value => value.id).includes(userData.type);
  const isConsumer = userData && researchConsumerType.map(value => value.id).includes(userData.type);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          type: userData?.type ?? '',
          website: isProducer ? userData?.producer?.website : (isConsumer ? userData?.consumer?.website : ''),
          description: isProducer ? userData?.producer?.description : (isConsumer ? userData?.consumer?.description : ''),
          phoneNumber: isProducer ? userData?.producer?.phoneNumber : (isConsumer ? userData?.consumer?.phoneNumber : ''),
          logo: isProducer ? userData?.producer?.logo : (isConsumer ? userData?.consumer?.logo : ''),
        }}
        validationSchema={Yup.object().shape({
          website: Yup.string().max(1024, 'Il valore massimo consentito è di 1024 caratteri')
              .matches(RegexUtil.webSiteRegex,
              'Sito web non valido'),
          description: Yup.string().max(4096, 'Il valore massimo consentito è di 4096 caratteri'),
          phoneNumber: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
              .matches(/^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[5-90]|36[680]|33[1-90]|32[7890])\d{7}|(0[0-9]{1,4}[-.\s]?[0-9]{5,10})$/, 'Il recapito di telefono non è valido')
            ,
        })}
        onSubmit={(values: FormikValues) => {
          if (handleUpdateData) {
            handleUpdateData(values);
          }
        }}
      >
        {({
            handleSubmit,
          }) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{minWidth: 275, mt: 2}}>
              <CardContent>
                <Box mt={2}>
                  <RegistrationOptionalSection readOnly={false} />
                </Box>
              </CardContent>
            </Card>
            <RegistrationStepButtons handleNext={handleSubmit} handleBack={handleBack} activeStep={activeStep}/>
          </form>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default RegistrationOptionalStep;
