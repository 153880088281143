import {Box, Card, CardContent} from "@mui/material";
import {GridSelectionModel, itIT} from "@mui/x-data-grid";
import {HttpStatusCode} from "axios";
import React from "react";
import Swal, {SweetAlertOptions} from 'sweetalert2';
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Payment from "../../../model/database/Payment";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import PaymentDTO from "../../../model/dto/PaymentDTO";
import PaymentsFilters from "../../../model/filters/PaymentsFilters";
import PaymentsTableColumns from "../../../model/table/column/PaymentsTableColumns";
import {PaymentRow} from "../../../model/table/row/PaymentRow";
import paymentSortFields from "../../../model/table/sort/PaymentSortFields";
import AuthContext from "../../../route/AuthContext";
import PaymentService from "../../../services/PaymentService";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import {StyledTable} from "../../common/table/StyledTable";
import PaymentModal from "./PaymentModal";
import PaymentsTableToolbar from "./PaymentsTableToolbar";


const PaymentsTable = () => {

  const authContext = React.useContext(AuthContext);

  const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
  const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  });
  const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
    page: 0,
    size: 10,
    sort: paymentSortFields[0].value + ',asc'
  });
  const [filterRequest, setFilterRequest] = React.useState<PaymentsFilters>({});

  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<PaymentRow[]>([]);
  const [payments, setPayments] = React.useState<Payment[]>([]);
  const [researchConsumers, setResearchConsumers] = React.useState<ResearchConsumer[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<Payment | undefined>(undefined);

  const handleEdit = (id: string) => {
    setPayment(payments.filter(value => value.id === id)[0]);
    setOpenModal(true);
  }

  const handleSave = (paymentToSave: PaymentDTO) => {
    return new Promise<boolean>(async resolve => {

      let [status, response] = [undefined, undefined];
      if (!payment) {
        [status, response] = await PaymentService.save(authContext, paymentToSave);
      } else {
        [status, response] = await PaymentService.update(authContext, paymentToSave, payment?.id ?? '');
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Contributo aggiunto correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Contributo aggiornato correttamente.';
            handleCloseModal();
            break;
          case 'INVALID_USER':
            resolve(false);
            swOptions.title = 'Attenzione';
            swOptions.text = 'Utente selezionato non è valido.';
            swOptions.icon = 'warning';
            break;
          case 'PAYMENT_EXISTS':
            resolve(false);
            swOptions.title = 'Attenzione';
            swOptions.text = 'Il contributo esiste già.';
            swOptions.icon = 'warning';
            break;
          case 'ID_NOT_FOUND':
            resolve(false);
            swOptions.title = 'Attenzione';
            swOptions.text = 'Utente non esiste.';
            swOptions.icon = 'warning';
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      }
    });
  }

  const handleCloseModal = () => setOpenModal(false);

  const handleDelete = (id: string) => {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await PaymentService.deleteById(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Pagamento eliminato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'DELETED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile eliminare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    })
  };

  const applyFilters = (filters: PaymentsFilters) => {
    setFilterRequest(filters);
  }

  const applySort = (value: string) => {
    if (pageInfo.sort !== value) {
      setPageInfo((prevState) => ({ ...prevState, sort: value }));
    }
  }

  const mapResponseToRows = (response: any) => {
    return [...response._embedded.payments].map(value => {
      let row: PaymentRow = {
        id: value.id,
        paid: value.paid ? 'PAID' : 'NOT_PAID',
        user: { ...value.researchConsumer, name: value.researchConsumer.businessName },
        year: value.year
      };
      return row;
    });
  }

  const loadResearchConsumers = async () => {
    const [status, response] = await UserService.getAllConsumers(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setResearchConsumers(response ? response._embedded.researchConsumers.map((value: any) => ({
      ...value,
      name: value.businessName
    })) : []);
  }

  const reloadData = async () => {
    setLoading(true);
    let [status, response] = [undefined, undefined];
    if (Object.keys(filterRequest).length === 0)
      [status, response] = await PaymentService.getAll(authContext, pageInfo);
    else
      [status, response] = await PaymentService.getAllFiltered(authContext, pageInfo, filterRequest);

    await loadResearchConsumers();

    if (status && response && status === HttpStatusCode.Ok) {
      setPageInfoResponse((response as any).page);
      setPayments((response as any)._embedded.payments);
      setRows(mapResponseToRows(response));
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    reloadData();
  }, [pageInfo, filterRequest]);

  return (
    <Card sx={{ minWidth: 275, mt: 2, mb: 2 }}>
      <CardContent>
        <PaymentsTableToolbar
          handleNew={() => {
            setPayment(undefined);
            setOpenModal(true);
          }}
          numSelected={idSelected.length}
          researchConsumers={researchConsumers}
          applyFilters={applyFilters}
          applySort={applySort}
        />
        <Box sx={{ height: 'auto', width: '100%' }}>
          <StyledTable
            loading={loading}
            paginationMode="server"
            rows={rows}
            columns={PaymentsTableColumns({
              editCallback: handleEdit,
              deleteCallback: handleDelete
            })}
            autoHeight
            rowsPerPageOptions={[10, 15, 20]}
            pageSize={pageInfo.size}
            onPageSizeChange={newPageSize => setPageInfo((prevState) => ({ ...prevState, size: newPageSize }))}
            onPageChange={newPage => setPageInfo((prevState) => ({ ...prevState, page: newPage }))}
            rowCount={pageInfoResponse.totalElements}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
            // checkboxSelection
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: false }}
          />
        </Box>
      </CardContent>
      <PaymentModal
        open={openModal}
        payment={payment}
        researchConsumers={researchConsumers}
        handleClose={handleCloseModal}
        handleSave={handleSave}
      />
    </Card>
  )
}

export default PaymentsTable;
