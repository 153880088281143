import {Alert, AlertColor, Box, Button, Container, TextField, Typography} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {Formik, FormikHelpers, FormikValues} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AuthService from '../../../services/AuthService';
import CircularLoading from '../../common/CircularLoading';
import {useSearchParams} from "react-router-dom";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";

interface AlertState {
    show: boolean,
    type: AlertColor,
    message: string
}

const PasswordResetForm = (props: React.ComponentPropsWithoutRef<any>) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [alert, setAlert] = React.useState<AlertState>({
        show: false,
        type: 'error',
        message: 'Si è verificato un errore durante la connessione con il server.'
    });

    return (
        <Box
            sx={{
                boxShadow: 5,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                height: {lg: '100%'},
                justifyContent: 'center',
                p: {lg: 2, md: 2, sm: 2, xs: 2},
                borderRadius: {lg: 0, md: 10, sm: 10, xs: 10}
            }}
            {...props}
        >
            <Container>
                <Formik
                    enableReinitialize
                    initialValues={{
                        password: '',
                        confirmPassword: ''
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string().min(8, 'La password deve essere di almeno 8 caratteri')
                            .max(255, 'Il valore massimo consentito è di 255 caratteri')
                            // .matches(
                            //   /^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/,
                            //   'E\' necessario almeno un carattere speciale',
                            // )
                            .required('La password è obbligatoria e non può essere vuota'),
                        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono corrispondere')
                            .required('La conferma della password è obbligatoria e non può essere vuota.'),
                    })}
                    onSubmit={async (values: FormikValues, {resetForm}: FormikHelpers<any>) => {
                        const [status, response] = await AuthService.resetPassword({
                            token: searchParams.get('token') ?? '',
                            password: values.password
                        });
                        let newAlert: AlertState = {
                            show: true,
                            type: 'error',
                            message: 'Si è verificato un\'errore durante l\'esecuzione della richiesta.'
                        };
                        if (status && status === HttpStatusCode.Ok) {
                            if (response && response.toUpperCase() === 'EXPIRED')
                                newAlert = {
                                    show: true,
                                    type: 'warning',
                                    message: 'Il link per il reset della password non è più valido.'
                                };
                            else
                                newAlert = {
                                    show: true,
                                    type: 'success',
                                    message: 'Riceverai un\'email di conferma inerente l\'aggiornamento richiesto'
                                };
                            resetForm();
                        }

                        setAlert(newAlert);
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Typography variant="h4">Reset password</Typography>
                            {alert.show && <Alert variant="outlined" sx={{my: 2}} severity={alert.type}>
                                {alert.message}
                            </Alert>}
                            <ResponsiveGridItem md={12}>
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={(touched.password && errors.password) as React.ReactNode}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    disabled={isSubmitting}
                                    value={values.password}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={12}>
                                <TextField
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    fullWidth
                                    helperText={(touched.confirmPassword && errors.confirmPassword) as React.ReactNode}
                                    label="Conferma password"
                                    margin="normal"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    disabled={isSubmitting}
                                    value={values.confirmPassword}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <Box sx={{py: 2, float: 'right'}}>
                                <div>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Conferma
                                        {isSubmitting && <CircularLoading/>}
                                    </Button>
                                </div>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    );
};

export default PasswordResetForm;
