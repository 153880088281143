import { MoreVert } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import MenuButtonItem from "./MenuButtonItem";

interface MenuButtonProps {
    rowId: string,
    title?: string,
    children?: MenuButtonItem[]
}

const MenuButton = ({rowId, title, children}: MenuButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title={title ?? "Altre azioni"}>
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVert/>
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {children?.map((value, index) => (
                    <MenuItem
                        key={rowId + "_" + index}
                        disabled={value.disabled ?? false}
                        onClick={() => {
                            value.handleClick(rowId);
                            handleClose();
                        }}>
                        <ListItemIcon>
                            {value.icon}
                        </ListItemIcon>
                        <ListItemText>{value.text}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default MenuButton;