import {Autocomplete, Button, Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import KeywordFilters from "../../../model/filters/KeywordFilters";
import CustomDialog from "../../common/CustomDialog";
import SustainableDevelopmentGoal from "../../../model/database/SustainableDevelopmentGoal";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";

interface KeywordsFiltersModalProps {
  open: boolean,
  filters: KeywordFilters,
  sdgs: SustainableDevelopmentGoal[],
  handleClose: () => any,
  handleClean: () => any,
  handleConfirm: (newStateFilters: KeywordFilters) => any,
}

const KeywordsFiltersModal = ({open, filters, sdgs, handleClose, handleClean, handleConfirm}: KeywordsFiltersModalProps) => {
  const [state, setState] = React.useState<KeywordFilters>(filters);

  const handleChange = (name: string, value: string) => {
    const newFilters = {...state};
    switch (name) {
      case 'name':
        newFilters.name = {label: '', value: ''};
        newFilters.name.label = "Nome";
        newFilters.name.value = value;
        break;
      case 'type':
        newFilters.type = {label: '', value: ''};
        newFilters.type.label = "Categoria";
        newFilters.type.value = value;

        const c = sdgs.find(value1 => value1.id === value);
        newFilters.type.valueDisplay = c?.name;
        break;
      case 'state':
          newFilters.state = {label: '', value: ''};
          newFilters.state.label = "Stato approvazione";
          newFilters.state.value = value;
          switch(value){
              case 'ACTIVE':
                  newFilters.state.valueDisplay = 'Approvato';
                  break;
              case 'INACTIVE':
                  newFilters.state.valueDisplay = 'Non approvato';
                  break;
          }
          break;
    }
    setState(newFilters);
  }

  React.useEffect(() => {
    setState(filters);
  }, [filters]);

  return <CustomDialog
    open={open ? open : false}
    handleClose={handleClose}
    title="Filtri"
    content={
      <Grid container spacing={2}>
        <ResponsiveGridItem>
          <TextField
            fullWidth
            onChange={(event) => handleChange('name', event.target.value)}
            value={state && state.name ? state.name.value : '' }
            label="Nome"
            margin="dense"
            name="name"
            variant="outlined"
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem>
          <Autocomplete
              options={sdgs ?? []}
              fullWidth
              getOptionLabel={(option) => option.name ?? ''}
              value={sdgs.find(value => value.id === state.type?.value) ?? null}
              onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                handleChange('type', newValue.id);
              }}
              renderInput={(params) => <TextField
                  {...params}
                  variant="outlined"
                  name="type"
                  margin="dense"
                  label="Categoria"
              />
              }
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem>
                    <TextField
                        fullWidth
                        label="Stato approvazione"
                        margin="dense"
                        name="state"
                        onChange={(event) => handleChange('state', event.target.value)}
                        value={state && state.state ? state.state.value : ''}
                        select
                        variant="outlined"
                    >
                        {[['Approvato', 'ACTIVE'], ['Non approvato', 'INACTIVE']].map(value => <MenuItem
                            key={value[1]} value={value[1]}>{value[0]}</MenuItem>)}
                    </TextField>
                </ResponsiveGridItem>
      </Grid>
    }
    actions={
      <>
        <Button color="inherit" onClick={() => {
          handleClose();
        }}>
          Chiudi
        </Button>
        <Button color="inherit" onClick={() => {
          handleClean();
          handleClose();
        }}>
          Reset
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={() => {
          handleConfirm(state);
          handleClose();
        }}>
          Applica
        </Button>
      </>
    }
  />
}

export default KeywordsFiltersModal;
