import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import italyProvinces from "../../../__data__/italy/italy-province";
import {useFormikContext} from "formik";
import italyCities from "../../../__data__/italy/italy-cities";

interface CityAutocompleteProps {
    provinceSelected: string
    value: string,
    fieldName: string,
    readOnly?: boolean
}

const CityAutocomplete = ({provinceSelected, value, fieldName, readOnly}: CityAutocompleteProps) => {
    const {
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        touched
    } = useFormikContext<any>();

    return (
        <Autocomplete
            options={provinceSelected ? italyCities[provinceSelected] : []}
            fullWidth
            onBlur={handleBlur}
            disabled={isSubmitting}
            value={provinceSelected ? (italyCities[provinceSelected].find((value1: any) => value1.nome === value) ?? null) : null}
            getOptionLabel={option => option.nome}
            readOnly={readOnly ? readOnly : false}
            onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                setFieldValue(fieldName, newValue.nome);
            }}
            renderInput={(params) => <TextField
                {...params}
                required
                variant="outlined"
                name={fieldName}
                margin="normal"
                label="Comune"
                error={Boolean(touched[fieldName] && errors[fieldName])}
                helperText={(touched[fieldName] && errors[fieldName]) as React.ReactNode}
            />
            }
        />
    )
}

export default CityAutocomplete;
