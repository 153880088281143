import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Theme,
    useTheme
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import callTypes from "../../../constants/callTypes";
import researchConsumerType from "../../../constants/researchConsumerType";
import researchProducerType from "../../../constants/researchProducerType";
import Roles from "../../../constants/roles";
import targetTypes from "../../../constants/targetTypes";
import LoggedUser from "../../../model/auth/LoggedUser";
import Call from "../../../model/database/Call";
import Keyword from "../../../model/database/Keyword";
import CallDTO from "../../../model/dto/CallDTO";
import RegexUtil from "../../../utils/RegexUtil";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";

interface CallModalProps {
    open: boolean,
    call?: Call,
    loggedUser?: LoggedUser,
    handleClose: () => void,
    handleSave: (callToSave: CallDTO) => Promise<boolean>,
    keywords: Keyword[],
}

function getStyles(name: string, users: string[], theme: Theme) {
    return {
        fontWeight:
            users.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const CallModal = ({
                       open,
                       call,
                       loggedUser,
                       keywords,
                       handleSave,
                       handleClose
                   }: CallModalProps) => {

    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>('Crea nuovo bando');

    React.useEffect(() => {
        if (editMode && call)
            setTitle('Modifica bando');
        else if (call)
            setTitle('Dettagli bando');
        else
            setTitle('Crea nuovo bando');
    }, [editMode, call]);

    const resetAndClose = () => {
        setEditMode(false);
        handleClose();
    }

    const theme = useTheme();

    return <Formik
        enableReinitialize
        initialValues={{
            name: call?.product?.name ?? '',
            budget: call?.budget ?? '',
            description: call?.product?.description ?? '',
            website: call?.website ?? '',
            endDate: call?.product?.endDate ?? '',
            type: call?.type ?? '',
            keywords: call?.product?.keywords?.map(value => value.id) ?? [],
            targets: call?.targets ?? []
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('L\'identificativo del bando è obbligatorio e non può essere vuoto.'),
            description: Yup.string().max(8192, 'Il valore massimo consentito è di 8192 caratteri'),
            budget: Yup.number().integer().min(1, 'Il valore minimo consentito è 1'),
            endDate: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La data di scadenza è obbligatoria e non può essere vuota.'),
            website: Yup.string().max(1024, 'Il valore massimo consentito è di 1024 caratteri')
                .matches(RegexUtil.webSiteRegex,
                    'Sito web non valido')
                .required('Il sito web è obbligatorio e non può essere vuoto.'),
            keywords: Yup.array().of(Yup.string()).min(1, 'Almeno 1 parola chiave deve essere selezionata')
                .max(5, 'Si possono selezionare al massimo 5 parole chiave')
                .required('Le parole chiave sono obbligatorie e non possono essere vuote.'),
            type: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatoria e non può essere vuota.'),
            targets: Yup.array().of(Yup.string()).min(1, 'Almeno una tipologia di destinatari deve essere selezionata')
                .required('La tipologia di destinatari è obbligatoria e non può essere vuota.'),
        })}
        onSubmit={async (values, {resetForm}) => {
            let result = await handleSave({
                name: values.name,
                description: values.description,
                endDate: values.endDate,
                budget: +values.budget,
                website: values.website,
                type: values.type,
                targets: JSON.stringify(values.targets),
                keywords: values.keywords as string[]
            });
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              setFieldValue,
              values
          }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            {loggedUser && call && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT') &&
                                <ResponsiveGridItem>
                                    <TextField
                                        fullWidth
                                        label="Creato da"
                                        margin="normal"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        disabled={editMode}
                                        required
                                        variant="outlined"
                                        value={call.product?.user?.role?.id === Roles[0].id ?
                                            `[${researchProducerType.find(value1 => value1.id === call.product?.user?.researchProducer?.type)?.label}] ${call.product?.user?.name} ${call.product?.user?.surname} ${call.product?.user?.researchProducer?.name !== '' ? `(${call.product?.user?.researchProducer?.name})` : ''}`
                                            : (call.product?.user?.role?.id === Roles[1].id ? `[${researchConsumerType.find(value1 => value1.id === call.product?.user?.researchConsumer?.type)?.name}] ${call.product?.user?.name} ${call.product?.user?.surname} ${call.product?.user?.researchConsumer?.businessName !== '' ? `(${call.product?.user?.researchConsumer?.businessName})` : ''}`
                                            : `${call.product?.user?.name} ${call.product?.user?.surname}`)
                                        }
                                    />
                                </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label="Identificativo bando"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                    disabled={isSubmitting || (editMode && call !== undefined)}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.budget && errors.budget)}
                                    fullWidth
                                    helperText={touched.budget && errors.budget}
                                    label="Budget"
                                    margin="normal"
                                    name="budget"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.budget}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    fullWidth
                                    helperText={touched.endDate && errors.endDate}
                                    label="Data di scadenza"
                                    margin="normal"
                                    name="endDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                    type="date"
                                    disabled={isSubmitting}
                                    value={values.endDate}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Autocomplete
                                    options={keywords ?? []}
                                    fullWidth
                                    multiple
                                    getOptionLabel={option => option.name!}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    value={keywords.filter(value => values.keywords?.includes(value.id))}
                                    readOnly={!editMode && call !== undefined}
                                    onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                                        setFieldValue('keywords', newValue.map((v: any) => v.id));
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        name="keywords"
                                        margin="normal"
                                        label="Parole chiave"
                                        error={Boolean(touched.keywords && errors.keywords)}
                                        helperText={(touched.keywords && errors.keywords) as React.ReactNode}
                                    />
                                    }
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.targets && errors.targets)}
                                    fullWidth
                                    helperText={touched.targets && errors.targets}
                                    label="Tipologia destinatari"
                                    margin="normal"
                                    name="targets"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    SelectProps={{
                                        multiple: true,
                                        displayEmpty: true,
                                        renderValue: value => {
                                            const selected = value as string[];
                                            return <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                                                {selected.map((id) => {
                                                    const valFil = targetTypes.filter((val) => val.value === id)[0];
                                                    if (valFil)
                                                        return <Chip key={id}
                                                                     label={`${valFil.label}`}
                                                                     sx={{m: '2px'}}/>;
                                                    else
                                                        return '';
                                                })}
                                            </Box>;
                                        }
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                    select
                                    disabled={isSubmitting}
                                    value={values.targets}
                                    required
                                    variant="outlined"
                                >
                                    {targetTypes.map(value => <MenuItem key={value.value}
                                                                        sx={{my: 1}}
                                                                        value={value.value}
                                                                        style={getStyles(value.value, values.targets, theme)}
                                    >{value.label}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.type && errors.type)}
                                    fullWidth
                                    helperText={touched.type && errors.type}
                                    label="Tipologia bando"
                                    margin="normal"
                                    name="type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.type}
                                    required
                                    variant="outlined"
                                >
                                    {callTypes.map(value => <MenuItem key={value.value}
                                                                      value={value.value}>{value.label}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.website && errors.website)}
                                    fullWidth
                                    helperText={touched.website && errors.website}
                                    label="Sito web"
                                    margin="normal"
                                    name="website"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.website}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.description && errors.description)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={(touched.description && errors.description) as React.ReactNode}
                                    label="Descrizione"
                                    margin="normal"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.description}
                                    variant="outlined"
                                    inputProps={{
                                        readOnly: !editMode && call !== undefined
                                    }}
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            Chiudi
                        </Button>
                        {open && loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT' || call === undefined || loggedUser.id === call.product?.user?.id) && (!editMode && call !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                Modifica
                            </Button> :
                            open && <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                Conferma
                                {isSubmitting && <CircularLoading/>}
                            </Button>)}
                    </>
                }
            />
        )}
    </Formik>
        ;
}

export default CallModal;
