import * as React from 'react';
import {Chip, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import ResponsiveGridItem from "../ResponsiveGridItem";
import Challenge from '../../../model/database/Challenge';
import LoggedUser from "../../../model/auth/LoggedUser";
import Roles from "../../../constants/roles";
import KeywordsBadges from "./KeywordsBadges";

interface ChallengeCardProps {
    challenge?: Challenge,
    user?: LoggedUser,
    state?: string,
    showState?: boolean
}

const ChallengeCard = ({challenge, user, state, showState}: ChallengeCardProps) => {

    const theme = useTheme();
    const matchMedia = useMediaQuery(theme.breakpoints.down('md'))

    const producerSubmitted = challenge?.researchProducerChallenges?.find((val: any) => val.state === 'SUBMITTED' && val.researchProducer.user.id === user?.id) ?? false

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}>
            <ResponsiveGridItem>
                <Stack direction={matchMedia ? 'column' : 'row'} spacing={1}>
                    {showState && <Chip color={challenge?.product?.approved ? 'success' : 'error'}
                                        label={challenge?.product?.approved ? 'Approvata' : 'Non approvata'}/>}
                    {user?.role === Roles[0].id && producerSubmitted &&
                        <Chip color="info" label="Proposta sottomessa"/>}
                    {challenge?.product?.approved && <Chip color={state === 'ACTIVE' ? 'success' : 'error'}
                                                           label={state === 'ACTIVE' ? 'Attiva' : 'Scaduta'}/>}
                    <Typography variant="h6">{challenge?.product?.name}</Typography>
                </Stack>
                {(user?.role === Roles[2].id || user?.role === Roles[3].id) && <Typography variant="subtitle1">Numero di
                    partecipanti: {challenge?.producers?.length ?? 0}</Typography>}
                {(user?.role === Roles[2].id || user?.role === Roles[3].id) && <Typography variant="subtitle1">Numero di
                    proposte
                    sottomesse: {challenge?.researchProducerChallenges?.filter((val: any) => val.state === 'SUBMITTED').length ?? 0}</Typography>}
                {user?.role === Roles[1].id && <Typography variant="subtitle1">Numero di proposte
                    sottomesse: {challenge?.researchProducerChallenges?.filter((val: any) => val.state === 'SUBMITTED' && val.visible).length ?? 0}</Typography>}
                <Typography variant="body2" textAlign="justify" sx={{whiteSpace: 'pre-line'}}>{challenge?.product?.description?.length! > 200 ? challenge?.product?.description?.substring(0, 200) : challenge?.product?.description}</Typography>
            </ResponsiveGridItem>
            <ResponsiveGridItem>
                <KeywordsBadges keywords={challenge?.product?.keywords!} matchMedia={matchMedia} />
            </ResponsiveGridItem>
        </Grid>
    );
}

export default ChallengeCard;
