import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import PaymentDTO from "../model/dto/PaymentDTO";
import PaymentsFilters from "../model/filters/PaymentsFilters";
import http from '../utils/HttpCommon';

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/payments/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: PaymentsFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = key === 'paid' ? lblValue.value === 'PAID' : lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/payments/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const save = async (context: LoggedUserContext, payment: PaymentDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/payments', { 
			year: payment.year,
			paid: payment.paid,
			paymentDate: payment.paymentDate,
			user: payment.user
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const update = async (context: LoggedUserContext, payment: PaymentDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/payments/${id}`, { 
			year: payment.year,
			paid: payment.paid,
			paymentDate: payment.paymentDate,
			user: payment.user
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/payments/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteAll = async (context: LoggedUserContext) => {}
const exportSelected = async (context: LoggedUserContext) => {}

const PaymentService = {
	getAll,
	getAllFiltered,
	save,
	update,
	deleteById,
	deleteAll,
	exportSelected
}

export default PaymentService;