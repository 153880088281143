import {Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme} from "@mui/material";
import React from "react";

interface CustomDialogProps {
  open: boolean,
  handleClose: () => any,
  title: string,
  content: React.ReactNode,
  actions: React.ReactNode
  [x: string]: any
}

const CustomDialog = ({open, handleClose, title, content, actions, ...rest}: CustomDialogProps) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return <Dialog
    open={open ? open : false}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
    fullScreen={!upMd}
    {...rest}
  >
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      {content}
    </DialogContent>
    <DialogActions>
      {actions}
    </DialogActions>
  </Dialog>;
}

export default CustomDialog;
