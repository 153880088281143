import {LabelValue} from "../../filters/LabelValue";

const serviceSortFields: LabelValue[] = [
  {
    label: 'Approvato',
    value: 'product.approved'
  },
  {
    label: 'Nome',
    value: 'product.name'
  },
  {
    label: 'Produttore',
    value: 'producer.name'
  },   
]

export default serviceSortFields;
