import {IconButton, Tooltip} from "@mui/material";
import {AddCircle, CheckCircle, Unpublished} from "@mui/icons-material";
import React from "react";
import ChallengesFilters from "../../../model/filters/ChallengesFilters";
import TableToolbar from "../../common/table/TableToolbar";
import challengeSortFields from "../../../model/table/sort/ChallengeSortFields";
import ChallengesFiltersModal from "./ChallengesFiltersModal";
import LoggedUser from "../../../model/auth/LoggedUser";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import Keyword from "../../../model/database/Keyword";

interface ChallengesTableToolbarProps {
    numSelected: number,
    loggedUser?: LoggedUser,
    producers: ResearchProducer[],
    consumers: ResearchConsumer[],
    keywords: Keyword[],
    handleNew: () => void,
    applyFilters: (filters: ChallengesFilters) => void
    applySort: (sort: string) => void
}

const ChallengesTableToolbar = ({numSelected, loggedUser, consumers, producers, keywords, handleNew, applySort, applyFilters}: ChallengesTableToolbarProps) => {

    const [filters, setFilters] = React.useState<ChallengesFilters>({});

    const handleConfirm = (newStateFilters: ChallengesFilters) => {
        setFilters(newStateFilters);
        applyFilters(newStateFilters);
    }

    const handleClean = () => {
        setFilters({});
        applyFilters({});
    }

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <TableToolbar
                numSelected={numSelected}
                title="Sfide"
                fields={challengeSortFields}
                filters={Object.values(filters)}
                defaultField={challengeSortFields[0].value}
                defaultOrder={'asc'}
                handleOpenFiltersModal={handleOpenModal}
                handleCleanFilters={handleClean}
                handleSort={applySort}
                handleExport={() => alert('export selection')}
                handleDelete={loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'RESEARCH_CONSUMER') ? () => alert('delete selection') : undefined}
                extendedActions={
                    <>
                        {loggedUser && loggedUser.role === 'RESEARCH_CONSUMER' && <Tooltip title="Crea nuova sfida">
                            <IconButton color="secondary" onClick={handleNew}>
                                <AddCircle/>
                            </IconButton>
                        </Tooltip>}
                    </>
                }
                extendedActionsOnSelection={
                    loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT') && <>
                        <Tooltip title="Approva">
                            <IconButton color="secondary">
                                <CheckCircle/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rimuovi approvazione">
                            <IconButton color="secondary">
                                <Unpublished/>
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <ChallengesFiltersModal
                open={openModal}
                filters={filters}
                loggedUser={loggedUser}
                producers={producers}
                consumers={consumers}
                keywords={keywords}
                handleClose={handleCloseModal}
                handleClean={handleClean}
                handleConfirm={handleConfirm}
            />
        </>
    );
}

export default ChallengesTableToolbar;
