import Role from "../model/database/Role";

const roles: Role[] = [
  {
    id: 'RESEARCH_PRODUCER',
    name: 'Produttore di ricerca'
  },
  {
    id: 'RESEARCH_CONSUMER',
    name: 'Consumatore di ricerca'
  },
  {
    id: 'ADMINISTRATOR',
    name: 'Amministratori di sistema'
  },
  {
    id: 'COMMITTEE_MANAGEMENT',
    name: 'Comitato di indirizzo e gestione'
  }
];

export default roles;
