import { Button, CardActions, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { HttpStatusCode } from "axios";
import { Formik, FormikValues } from "formik";
import * as React from "react";
import { SweetAlertOptions } from "sweetalert2";
import * as Yup from "yup";
import researchProductTypes from "../../../constants/researchProductTypes";
import ParameterDocumentFormatDTO from "../../../model/dto/ParameterDocumentFormatDTO";
import AuthContext from "../../../route/AuthContext";
import ParameterService from "../../../services/ParameterService";
import CustomSweetalert from "../../../theme/sweetalert";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import UploadDocumentField from "../../common/UploadDocumentField";

const ProductFormatSetting = () => {
  const authContext = React.useContext(AuthContext);
  const [parameters, setParameters] = React.useState<ParameterDocumentFormatDTO | undefined>(undefined);
  const [checkFileSize, setCheckFileSize] = React.useState<boolean>(false);

  const reloadData = async () => {
    let [status, response] = await ParameterService.getDocumentFormatParameters(authContext);
    if (status && response && status === HttpStatusCode.Ok) {
      setParameters(response);
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }
  };

  React.useEffect(() => {
    reloadData();
  }, []);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          projectFile: parameters && parameters.projectFile ? parameters.projectFile : null,
          patentFile: parameters && parameters.patentFile ? parameters.patentFile : null,
          publicationFile: parameters && parameters.publicationFile ? parameters.publicationFile : null,
          internshipFile: parameters && parameters.internshipFile ? parameters.internshipFile : null,
          thesisFile: parameters && parameters.thesisFile ? parameters.thesisFile : null
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values: FormikValues) => {
          let [status, response] = await ParameterService.saveDocumentFormat(authContext, values as ParameterDocumentFormatDTO);

          if (status && response && status === HttpStatusCode.Ok) {
            let swOptions: SweetAlertOptions = {
              title: 'Completato',
              text: 'Format documenti salvati correttamente.',
              icon: 'success'
            };

            switch (response) {
              case 'SAVED':
                break;
              default:
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                break;
            }
            CustomSweetalert(swOptions).then(() => {
              reloadData();
            });
          }
        }}
      >
        {({
          handleSubmit,
          submitForm
        }) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{ width: '100%', mt: 2 }}>
              <CardContent>
                <ResponsiveGridItem container spacing={1}>
                  <ResponsiveGridItem>
                    <Typography variant="h5">Format documenti prodotti</Typography>
                  </ResponsiveGridItem>
                  {researchProductTypes.map((value) => (
                    <ResponsiveGridItem key={value.value}>
                      <ResponsiveGridItem>
                        <Typography variant="h6">{value.label}</Typography>
                      </ResponsiveGridItem>
                      <ResponsiveGridItem>
                        <UploadDocumentField propertyId={value.value.toLowerCase() + "File"} editMode fileTypes=".pdf,.doc,.docx" />
                      </ResponsiveGridItem>
                    </ResponsiveGridItem>
                  ))}
                </ResponsiveGridItem>
              </CardContent>
              <CardActions>
                <Stack alignItems="flex-end" display="flex" flex={1}>
                  <Button variant="contained" onClick={() => submitForm()}>Salva</Button>
                </Stack>
              </CardActions>
            </Card>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default ProductFormatSetting;
