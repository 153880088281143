import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import React from "react";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import ChallengesFilters from "../../../model/filters/ChallengesFilters";
import LoggedUser from "../../../model/auth/LoggedUser";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import Keyword from "../../../model/database/Keyword";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";

interface ChallengesFiltersModalProps {
    open: boolean,
    filters: ChallengesFilters,
    loggedUser?: LoggedUser,
    producers: ResearchProducer[],
    consumers: ResearchConsumer[],
    keywords: Keyword[],
    handleClose: () => any,
    handleClean: () => any,
    handleConfirm: (newStateFilters: ChallengesFilters) => any,
}

const ChallengesFiltersModal = ({
                                    open,
                                    filters,
                                    producers,
                                    consumers,
                                    loggedUser,
                                    handleClean,
                                    keywords,
                                    handleClose,
                                    handleConfirm
                                }: ChallengesFiltersModalProps) => {
    const [state, setState] = React.useState<ChallengesFilters>(filters);

    const handleChange = (name: string, value: string) => {
        const newFilters = {...state};
        switch (name) {
            case 'name':
                newFilters.name = {label: '', value: ''};
                newFilters.name.label = "Nome";
                newFilters.name.value = value;
                break;
            case 'keywordId':
                newFilters.keywordId = {label: '', value: ''};
                newFilters.keywordId.label = "Parola chiave";
                newFilters.keywordId.value = value;
                const k = keywords.find(value1 => value1.id === value);
                newFilters.keywordId.valueDisplay = k?.name;
                break;
            case 'consumerId':
                newFilters.consumerId = {label: '', value: ''};
                newFilters.consumerId.label = "Consumatore di ricerca";
                newFilters.consumerId.value = value;
                const rc = consumers.find(value1 => value1.user?.id === value);
                newFilters.consumerId.valueDisplay = StringGeneratorUtil.generateConsumerLabel(rc!);
                break;
            case 'producerId':
                newFilters.producerId = {label: '', value: ''};
                newFilters.producerId.label = "Produttore di ricerca";
                newFilters.producerId.value = value;
                const rp = producers.find(value1 => value1.user?.id === value);
                newFilters.producerId.valueDisplay = StringGeneratorUtil.generateProducerLabel(rp!);
                break;
            case 'startDate':
                newFilters.startDate = {label: '', value: ''};
                newFilters.startDate.label = "Data di inizio";
                newFilters.startDate.value = value;
                break;
            case 'endDate':
                newFilters.endDate = {label: '', value: ''};
                newFilters.endDate.label = "Data di fine";
                newFilters.endDate.value = value;
                break;
            case 'budget':
                newFilters.budget = {label: '', value: ''};
                newFilters.budget.label = "Budget";
                newFilters.budget.value = value;
                break;
        }
        setState(newFilters);
    }

    React.useEffect(() => {
        setState(filters);
    }, [filters]);

    return <CustomDialog
        open={open ? open : false}
        handleClose={handleClose}
        title="Filtri"
        content={
            <Grid container spacing={2}>
                <ResponsiveGridItem>
                    <TextField
                        fullWidth
                        onChange={(event) => handleChange('name', event.target.value)}
                        value={state && state.name ? state.name.value : ''}
                        label="Nome"
                        margin="dense"
                        name="name"
                        variant="outlined"
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={consumers ?? []}
                        fullWidth
                        getOptionLabel={option => StringGeneratorUtil.generateConsumerLabel(option)}
                        value={consumers.find(value => value.user?.id === state.consumerId?.value) ?? null}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('consumerId', newValue.user?.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            required
                            variant="outlined"
                            name="consumerId"
                            margin="dense"
                            label="Consumatore di ricerca"
                        />
                        }
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={producers ?? []}
                        fullWidth
                        getOptionLabel={option => StringGeneratorUtil.generateProducerLabel(option)}
                        value={producers.find(value => value.user?.id === state.producerId?.value) ?? null}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('producerId', newValue.user?.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            required
                            variant="outlined"
                            name="producerId"
                            margin="dense"
                            label="Produttore di ricerca"
                        />
                        }
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={keywords ?? []}
                        fullWidth
                        getOptionLabel={(option) => option.name!}
                        value={keywords.find(value => value.id === state.keywordId?.value) ?? null}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('keywordId', newValue.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            required
                            variant="outlined"
                            name="keywordId"
                            margin="dense"
                            label="Parola chiave"
                        />
                        }
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Budget maggiore/uguale di"
                        margin="dense"
                        name="budget"
                        onChange={(event) => handleChange('budget', event.target.value)}
                        value={state && state.budget ? state.budget.value : ''}
                        variant="outlined"
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Data inizio maggiore/uguale di>"
                        margin="dense"
                        name="submissionDate"
                        onChange={(event) => handleChange('startDate', event.target.value)}
                        value={state && state.startDate ? state.startDate.value : ''}
                        type="date"
                        inputProps={{
                            max: state.endDate?.value
                        }}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Data fine minore/uguale di>"
                        margin="dense"
                        name="endDate"
                        onChange={(event) => handleChange('endDate', event.target.value)}
                        value={state && state.endDate ? state.endDate.value : ''}
                        type="date"
                        inputProps={{
                            min: state.startDate?.value
                        }}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                    />
                </ResponsiveGridItem>
            </Grid>
        }
        actions={
            <>
                <Button color="inherit" onClick={() => {
                    handleClose();
                }}>
                    Chiudi
                </Button>
                <Button color="inherit" onClick={() => {
                    handleClean();
                    handleClose();
                }}>
                    Reset
                </Button>
                <Button color="primary" variant="contained" autoFocus onClick={() => {
                    handleConfirm(state);
                    handleClose();
                }}>
                    Applica
                </Button>
            </>
        }
    />
}

export default ChallengesFiltersModal;
