import {Chip, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ResearchProduct from "../../../model/database/ResearchProduct";
import ResponsiveGridItem from "../ResponsiveGridItem";
import StringGeneratorUtil from "../StringGeneratorUtil";
import KeywordsBadges from "./KeywordsBadges";

interface ProductCardProps {
  useCard?: boolean,
  researchProduct?: ResearchProduct
  showState?: boolean
}

const ProductCard = ({useCard, researchProduct, showState}: ProductCardProps) => {

  const theme = useTheme();
  const matchMedia = useMediaQuery(theme.breakpoints.down('md'))

  const content = (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <ResponsiveGridItem>
        <Stack direction={matchMedia ? 'column': 'row'} spacing={1}>
          {showState && <Chip color={researchProduct?.product?.approved ? 'success' : 'error'}
                              label={researchProduct?.product?.approved ? 'Approvato' : 'Non approvato'}/>}
          <Typography variant="h6">{researchProduct?.product?.name}</Typography>
        </Stack>
        <Typography
          variant="subtitle1">{StringGeneratorUtil.generateProducerLabel(researchProduct?.producer!, true)}</Typography>
        <Typography variant="body2" textAlign="justify" sx={{whiteSpace: 'pre-line'}}>{researchProduct?.product?.description?.length! > 200 ? researchProduct?.product?.description?.substring(0, 200) + "..." : (researchProduct?.product?.description ?? '')}</Typography>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <KeywordsBadges keywords={researchProduct?.product?.keywords!} matchMedia={matchMedia} />
      </ResponsiveGridItem>
    </Grid>
  );

  return (
    useCard ? <Card sx={{
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText'
      }
    }}>
      <CardContent>
        {content}
      </CardContent>
    </Card> : content
  );
}

export default ProductCard;
