import MainNavbar from './MainNavbar';
import MainLayoutRoot from "./MainLayoutRoot";
import MainLayoutWrapper from "./MainLayoutWrapper";
import MainLayoutContainer from "./MainLayoutContainer";
import MainLayoutContent from "./MainLayoutContent";

interface MainLayoutProps {
  children?: React.ReactNode,
  background?: boolean,
  elevation?: number,
  showLogo?: boolean
}

const MainLayout = ({ children, background, elevation, showLogo }: MainLayoutProps) => (
  <MainLayoutRoot>
    <MainNavbar elevation={elevation} showLogo={showLogo} />
    <MainLayoutWrapper>
      <MainLayoutContainer>
        <MainLayoutContent sx={background ? {
          display: 'flex',
          backgroundImage: 'url("/static/background_new-02.webp")',
          backgroundSize: "cover"
        } : {}}>
          {children}
        </MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutWrapper>
  </MainLayoutRoot>
);

export default MainLayout;
