import { Box, Card, CardContent } from "@mui/material";
import { GridSelectionModel, itIT } from "@mui/x-data-grid";
import { HttpStatusCode } from "axios";
import React from "react";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import researchConsumerType from "../../../constants/researchConsumerType";
import researchProducerType from "../../../constants/researchProducerType";
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import ResearchInstitution from "../../../model/database/ResearchInstitution";
import User from "../../../model/database/User";
import UserConsumerRegistrationDTO from "../../../model/dto/UserConsumerRegistrationDTO";
import UserProducerRegistrationDTO from "../../../model/dto/UserProducerRegistrationDTO";
import UsersFilters from "../../../model/filters/UsersFilters";
import UsersTableColumns from "../../../model/table/column/UsersTableColumns";
import { UserRow } from "../../../model/table/row/UserRow";
import userSortFields from "../../../model/table/sort/UserSortFields";
import AuthContext from "../../../route/AuthContext";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import ModalState from "../../common/table/ModalState";
import { StyledTable } from "../../common/table/StyledTable";
import ResearchConsumerModal from "./ResearchConsumerModal";
import ResearchProducerModal from "./ResearchProducerModal";
import UserModal from "./UserModal";
import UsersTableToolbar from "./UsersTableToolbar";

const UsersTable = () => {

  const authContext = React.useContext(AuthContext);

  const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
  const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  });
  const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
    page: 0,
    size: 10,
    sort: userSortFields[0].value + ',desc'
  });
  const [filterRequest, setFilterRequest] = React.useState<UsersFilters>({});

  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<UserRow[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);
  const [institutions, setInstitutions] = React.useState<ResearchInstitution[]>([]);

  const [userModalState, setUserModalState] = React.useState<ModalState>({
    open: false,
    data: undefined,
    edit: false
  });
  const handleCloseUserModal = () => setUserModalState(() => ({data:undefined, open: false, edit: false}));

  const [researchProducerModalState, setResearchProducerModalState] = React.useState<ModalState>({
    open: false,
    data: undefined,
    edit: false
  });
  const handleCloseResearchProducerModal = () => setResearchProducerModalState(() => ({data: undefined, open: false, edit: false}));

  const [researchConsumerModalState, setResearchConsumerModalState] = React.useState<ModalState>({
    open: false,
    data: undefined,
    edit: false
  });
  const handleCloseResearchConsumerModal = () => setResearchConsumerModalState(() => ({data: undefined, open: false, edit: false}));

  const handleSaveUser = (userToSave: User, edit: boolean) => {
    return new Promise<boolean>(async resolve => {
      let [status, response] = [undefined, undefined];
      if (!userModalState.data) {
        [status, response] = await UserService.save(authContext, userToSave);
      } else {
        [status, response] = await UserService.update(authContext, userToSave, userModalState.data.id);
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Utente aggiunto correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseUserModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Utente aggiornato correttamente.';
            handleCloseUserModal();
            break;
          case 'INVALID_USER':
            resolve(false);
            swOptions.title = 'Attenzione';
            swOptions.text = 'Lo username inserito è già in uso nel sistema.';
            swOptions.icon = 'warning';
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      } else{
        CustomSweetalert({
          title: 'Attenzione',
          text: 'Non è stato possibile salvare l\'utente selezionato.',
          icon: 'error'
        })
        resolve(false);
      } 
      
    })
  }

  const handleSaveResearchProducer = (userToSave: UserProducerRegistrationDTO) => {
    
    return new Promise<boolean>(async resolve => {
      let [status, response] = [undefined, undefined];
      if (!researchProducerModalState.data) {
        //[status, response] = await UserService.saveResearchProducer(userToSave);
      } else {
        [status, response] = await UserService.updateResearchProducer(authContext, userToSave, researchProducerModalState.data.id);
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Utente aggiunto correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseResearchProducerModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Utente aggiornato correttamente.';
            handleCloseResearchProducerModal();
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      } else{
        CustomSweetalert({
          title: 'Attenzione',
          text: 'Non è stato possibile salvare l\'utente selezionato.',
          icon: 'error'
        })
        resolve(false);
      } 
    })
  }

  const handleSaveResearchConsumer = (userToSave: UserConsumerRegistrationDTO) => {
    return new Promise<boolean>(async resolve => {
      let [status, response] = [undefined, undefined];
      if (!researchConsumerModalState.data) {
        //[status, response] = await UserService.saveResearchConsumer(userToSave);
      } else {
        [status, response] = await UserService.updateResearchConsumer(authContext, userToSave, researchConsumerModalState.data.id);
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Utente aggiunto correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseResearchConsumerModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Utente aggiornato correttamente.';
            handleCloseResearchConsumerModal();
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      } else{
        CustomSweetalert({
          title: 'Attenzione',
          text: 'Non è stato possibile salvare l\'utente selezionato.',
          icon: 'error'
        })
        resolve(false);
      } 
    })
  }

  const handleShow = (row: UserRow) => {
    switch (row.roleId) {
      case 'COMMITTEE_MANAGEMENT':
      case 'ADMINISTRATOR':
        setUserModalState({
          open: true,
          data: users.filter(value => value.id === row.id)[0],
          edit: false
        });
        break;
      case 'RESEARCH_CONSUMER':
        setResearchConsumerModalState({
          open: true,
          data: users.filter(value => value.id === row.id)[0],
          edit: false
        });
        break;
      case 'RESEARCH_PRODUCER':
        setResearchProducerModalState({
          open: true,
          data: users.filter(value => value.id === row.id)[0],
          edit: false
        });
        break;
    }
  };

  const handleActivate = (id: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: "Confermi di voler attivare l'utente?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2e7d32',
      confirmButtonText: 'Attiva',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await UserService.changeStateById(authContext, id,'ACTIVE');
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Utente attivato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'UPDATED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile attivare l\'utente selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile attivare l\'utente selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const handleDeactivate = (id: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: "Confermi di voler disattivare l'utente?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Disattiva',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await UserService.changeStateById(authContext, id, 'INACTIVE');
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Utente disattivato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'UPDATED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile disattivare l\'utente selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile disattivare l\'utente selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const handleDelete = (id: string) => {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Completato',
          'Utente eliminato correttamente.',
          'success'
        )
      }
      if (result.isConfirmed) {
        const [status, response] = await UserService.deleteById(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Utente eliminato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'DELETED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile eliminare l\'utente selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile eliminare l\'utente selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const applyFilters = (filters: UsersFilters) => {
    setFilterRequest(filters);
  }

  const applySort = (value: string) => {
    if (pageInfo.sort !== value) {
      setPageInfo((prevState) => ({ ...prevState, sort: value }));
    }
  }

  const loadResearchInstitution = async () => {
    const [status, response] = await UserService.getAllResearchInstitutions( undefined, {page: 0, size: -1, sort: 'id,asc'});
    setInstitutions(response ? response._embedded.researchInstitutions : []);
  }

  const mapResponseToRows = (userTmp: User[]) => {
    return userTmp.map(value => {
      let type = '';
      if(value.role?.id === 'RESEARCH_CONSUMER') {
        type = researchConsumerType.find(value1 => value1.id === value.researchConsumer?.type)?.name ?? ''
      }

      if(value.role?.id === 'RESEARCH_PRODUCER') {
        type = researchProducerType.find(value1 => value1.id === value.researchProducer?.type)?.label ?? ''
      }

      return {
        id: value.id,
        name: value.name,
        surname: value.surname,
        email: value.email,
        state: value.state,
        role: value.role?.name,
        roleId: value.role?.id,
        type: type,
        data: value
      }
    });
  }

  const reloadData = async () => {
    setLoading(true);
    let [status, response] = [undefined, undefined];
    if (Object.keys(filterRequest).length === 0)
      [status, response] = await UserService.getAll(authContext, pageInfo);
    else
      [status, response] = await UserService.getAllFilteredByUsersFilter(authContext, pageInfo, filterRequest);

    await loadResearchInstitution();

    if (status && response && status === HttpStatusCode.Ok) {
      setPageInfoResponse((response as any).page);
      const usersTmp: User[] = (response as any)._embedded.users.map((value: any) => ({
        ...value,
        researchConsumer: {
          name:value.researchConsumer?.businessName ?? '',
          ...value.researchConsumer
        }
      }));
      setUsers(usersTmp);
      const usersRow: UserRow[] = mapResponseToRows(usersTmp);
      setRows(usersRow);
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    reloadData();
  }, [pageInfo, filterRequest]);

  return (
    <Card sx={{minWidth: 275, mt: 2, mb: 2}}>
      <CardContent>
        <UsersTableToolbar
          numSelected={idSelected.length}
          loggedUser={authContext.user}
          handleNew={() => {
            setUserModalState({open: true, data: undefined, edit: false});
          }}
          applyFilters={applyFilters}
          applySort={applySort}
        />
        <Box sx={{height: 'auto', width: '100%'}}>
          <StyledTable
            loading={loading}
            paginationMode="server"
            rows={rows}
            columns={UsersTableColumns({
              loggedUser: authContext.user,
              showCallback: handleShow,
              deleteCallback: handleDelete,
              activateCallback: handleActivate,
              deactivateCallback: handleDeactivate,
            })}
            rowsPerPageOptions={[10, 15, 20]}
            pageSize={pageInfo.size}
            onPageSizeChange={newPageSize => setPageInfo((prevState) => ({ ...prevState, size: newPageSize }))}
            onPageChange={newPage => setPageInfo((prevState) => ({ ...prevState, page: newPage }))}
            rowCount={pageInfoResponse.totalElements}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
            // checkboxSelection
            autoHeight
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
            experimentalFeatures={{newEditingApi: false}}
          />
        </Box>
      </CardContent>
      <UserModal
        open={userModalState.open}
        user={userModalState.data}
        editEnable={authContext.user?.role === 'ADMINISTRATOR'}
        handleClose={handleCloseUserModal}
        handleSave={handleSaveUser}
      />
      <ResearchProducerModal
        open={researchProducerModalState.open}
        user={researchProducerModalState.data}
        institutions={institutions}
        editEnable={authContext.user?.role === 'ADMINISTRATOR'}
        handleClose={handleCloseResearchProducerModal}
        handleSave={handleSaveResearchProducer}
      />
      <ResearchConsumerModal
        open={researchConsumerModalState.open}
        user={researchConsumerModalState.data}
        editEnable={authContext.user?.role === 'ADMINISTRATOR'}
        handleClose={handleCloseResearchConsumerModal}
        handleSave={handleSaveResearchConsumer}
      />
    </Card>
  )
}

export default UsersTable;
