import {Autocomplete, Button, Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import LoggedUser from "../../../model/auth/LoggedUser";
import Challenge from "../../../model/database/Challenge";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchProduct from "../../../model/database/ResearchProduct";
import ResearchService from "../../../model/database/ResearchService";
import DiscussionsFilters from "../../../model/filters/DiscussionsFilters";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import Call from "../../../model/database/Call";
import productTypes from "../../../constants/productTypes";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";

interface DiscussionsFiltersModalProps {
    open: boolean,
    filters: DiscussionsFilters,
    loggedUser?: LoggedUser,
    handleClose: () => any,
    handleClean: () => any,
    handleConfirm: (newStateFilters: DiscussionsFilters) => any,
    producers: ResearchProducer[],
    consumers: ResearchConsumer[],
    products: ResearchProduct[],
    services: ResearchService[],
    challenges: Challenge[],
    calls: Call[],
}

const DiscussionsFiltersModal = ({
                                     open,
                                     filters,
                                     loggedUser,
                                     producers,
                                     consumers,
                                     calls,
                                     products,
                                     services,
                                     challenges,
                                     handleClose,
                                     handleClean,
                                     handleConfirm
                                 }: DiscussionsFiltersModalProps) => {
    const [state, setState] = React.useState<DiscussionsFilters>(filters);
    const [productsAc, setProductsAc] = React.useState<any[]>([]);
    const [usersAc, setUsersAc] = React.useState<any[]>([]);

    React.useEffect(() => {
        let grouped = [];
        if (products) {
            grouped.push(...products.map((value => ({...value, type: productTypes[0]}))));
        }

        if (services) {
            grouped.push(...services.map((value => ({...value, type: productTypes[1]}))));
        }

        if (challenges) {
            grouped.push(...challenges.map((value => ({...value, type: productTypes[2]}))));
        }

        if (calls) {
            grouped.push(...calls.map((value => ({...value, type: productTypes[3]}))));
        }

        setProductsAc(grouped);
    }, [products, services, challenges, calls]);

    React.useEffect(() => {
        let grouped = [];
        if (producers) {
            grouped.push(...producers);
        }

        if (consumers) {
            grouped.push(...consumers);
        }

        setUsersAc(grouped);
    }, [producers, consumers]);

    const handleChange = (name: string, value: string) => {
        const newFilters = {...state};
        switch (name) {
            case 'name':
                newFilters.name = {label: '', value: ''};
                newFilters.name.label = "Nominativo";
                newFilters.name.value = value;
                break;
            case 'productId':
                newFilters.productId = {label: '', value: ''};
                newFilters.productId.label = "Argomento";
                newFilters.productId.value = value;

                const p = productsAc.find(value1 => value1.value === value);
                newFilters.productId.valueDisplay = `${p?.name!} (${p?.approved ? 'Approvato' : 'Non approvato'})`;

                break;
            case 'userId':
                newFilters.userId = {label: '', value: ''};
                newFilters.userId.label = "Partecipante";
                newFilters.userId.value = value;

                const u = usersAc.find(value1 => value1.user.id === value);
                newFilters.userId.valueDisplay = StringGeneratorUtil.generateUserLabel(u!);
                break;
            case 'state':
                newFilters.state = {label: '', value: ''};
                newFilters.state.label = "Stato";
                newFilters.state.value = value;
                switch(value){
                    case 'ACTIVE':
                        newFilters.state.valueDisplay = 'Attivo';
                        break;
                    case 'CLOSED':
                        newFilters.state.valueDisplay = 'Chiuso';
                        break;
                }
                break;
        }
        setState(newFilters);
    }

    React.useEffect(() => {
        setState(filters);
    }, [filters]);

    return <CustomDialog
        open={open ? open : false}
        handleClose={handleClose}
        title="Filtri"
        content={
            <Grid container spacing={2}>
                <ResponsiveGridItem
                    md={loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') ? 6 : 12}>
                    <TextField
                        fullWidth
                        onChange={(event) => handleChange('name', event.target.value)}
                        value={state && state.name ? state.name.value : ''}
                        label="Identificativo tavolo"
                        margin="dense"
                        name="name"
                        variant="outlined"
                    />
                </ResponsiveGridItem>
                <ResponsiveGridItem md={6}>
                    <Autocomplete
                        options={productsAc ?? []}
                        fullWidth
                        getOptionLabel={(option) => `${option.product?.name!} (${option.product?.approved ? 'Approvato' : 'Non approvato'})`}
                        value={productsAc.find(value => value.product.id === state.productId?.value) ?? null}
                        groupBy={(option) => option.type.label}
                        onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                            handleChange('productId', newValue.product.id);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            required
                            variant="outlined"
                            name="productId"
                            margin="dense"
                            label="Argomento"
                        />
                        }
                    />
                </ResponsiveGridItem>
                {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') &&
                    <ResponsiveGridItem md={6}>
                        <Autocomplete
                            options={usersAc ?? []}
                            fullWidth
                            value={usersAc.find(v => v.user.id === state.userId?.value) ?? null}
                            getOptionLabel={option => StringGeneratorUtil.generateUserLabel(option)}
                            groupBy={(option) => option.user.role.name}
                            onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                                handleChange('userId', newValue.user.id);
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                required
                                variant="outlined"
                                name="userId"
                                margin="dense"
                                label="Partecipante"
                            />
                            }
                        />
                    </ResponsiveGridItem>}
                <ResponsiveGridItem md={6}>
                    <TextField
                        fullWidth
                        label="Stato"
                        margin="dense"
                        name="state"
                        onChange={(event) => handleChange('state', event.target.value)}
                        value={state && state.state ? state.state.value : ''}
                        select
                        variant="outlined"
                    >
                        <MenuItem value="ACTIVE">Attivo</MenuItem>
                        <MenuItem value="CLOSED">Chiuso</MenuItem>
                    </TextField>
                </ResponsiveGridItem>
            </Grid>
        }
        actions={
            <>
                <Button color="inherit" onClick={() => {
                    handleClose();
                }}>
                    Chiudi
                </Button>
                <Button color="inherit" onClick={() => {
                    handleClean();
                    handleClose();
                }}>
                    Reset
                </Button>
                <Button color="primary" variant="contained" autoFocus onClick={() => {
                    handleConfirm(state);
                    handleClose();
                }}>
                    Applica
                </Button>
            </>
        }
    />
}

export default DiscussionsFiltersModal;
