import {AddCircle} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";
import React from "react";
import keywordSortFields from "../../../model/table/sort/KeywordSortFields";
import TableToolbar from "../../common/table/TableToolbar";
import KeywordFiltersModal from "./KeywordFiltersModal";
import KeywordFilters from "../../../model/filters/KeywordFilters";
import SustainableDevelopmentGoal from "../../../model/database/SustainableDevelopmentGoal";

interface KeywordsTableToolbarProps {
  numSelected: number,
  handleNew: () => void,
  sdgs: SustainableDevelopmentGoal[],
  applyFilters: (filters: KeywordFilters) => void
  applySort: (sort: string) => void
}

const KeywordsTableToolbar = ({numSelected, handleNew, sdgs, applyFilters, applySort}: KeywordsTableToolbarProps) => {

  const [filters, setFilters] = React.useState<KeywordFilters>({});

  const handleConfirm = (newStateFilters: KeywordFilters) => {
    setFilters(newStateFilters);
    applyFilters(newStateFilters);
  }

  const handleClean = () => {
    setFilters({});
    applyFilters({});
  }

  const [openFilterModal, setOpenFilterModal] = React.useState<boolean>(false);
  const handleOpenFilterModal = () => setOpenFilterModal(true);
  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  return (
    <>
      <TableToolbar
        numSelected={numSelected}
        title="Parole chiave"
        fields={keywordSortFields}
        filters={Object.values(filters)}
        defaultField={keywordSortFields[0].value}
        defaultOrder={'asc'}
        handleOpenFiltersModal={handleOpenFilterModal}
        handleCleanFilters={handleClean}
        handleExport={() => alert('export selection')}
        handleDelete={() => alert('delete selection')}
        handleSort={applySort}
        extendedActions={
          <>
            <Tooltip title="Aggiungi nuova parola chiave">
              <IconButton color="secondary" onClick={handleNew}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <KeywordFiltersModal
        open={openFilterModal}
        filters={filters}
        sdgs={sdgs}
        handleClose={handleCloseFilterModal}
        handleClean={handleClean}
        handleConfirm={handleConfirm}
      />
    </>
  );
}

export default KeywordsTableToolbar;
