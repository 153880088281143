import { Box, Card, CardContent, FormControlLabel, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import * as React from 'react';
import PrivacyPolicyText from '../../../common/PrivacyPolicyText';
import ResponsiveGridItem from "../../../common/ResponsiveGridItem";
import RegistrationStepButtons from "../RegistrationStepButtons";
import RegistrationStepProps from "../props/RegistrationStepProps";

const RegistrationPrivacyStep = ({
    handleBack,
    handleNext,
    activeStep
}: RegistrationStepProps) => {
    const [accepted, setAccepted] = React.useState(false);

    return (
        <React.Fragment>
            <Card sx={{ minWidth: 275, mt: 2 }}>
                <CardContent>
                    <Box mt={2}>
                        <ResponsiveGridItem container spacing={1}>
                            <ResponsiveGridItem>
                                <Typography variant="h5">Informativa privacy</Typography>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem sx={{ maxHeight: 500, overflowY: 'auto' }}>
                                <PrivacyPolicyText />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <FormControlLabel
                                    control={<Checkbox onChange={() => setAccepted(!accepted)} checked={accepted} sx={{
                                        '&.Mui-checked svg': {
                                            backgroundColor: 'secondary.main',
                                            borderColor: 'secondary.main',
                                            borderRadius: 1
                                        }
                                    }} />} label="Spuntando la casella si accetta l'informativa privacy" />
                            </ResponsiveGridItem>
                        </ResponsiveGridItem>
                    </Box>
                </CardContent>
            </Card>
            <RegistrationStepButtons handleNext={handleNext} handleBack={handleBack} activeStep={activeStep}
                disabled={!accepted} />
        </React.Fragment>
    )
}

export default RegistrationPrivacyStep;
