import {Alert, AlertColor, Box, Button, Container, TextField, Typography} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {Formik, FormikHelpers, FormikValues} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AuthService from '../../../services/AuthService';
import CircularLoading from '../../common/CircularLoading';

interface AlertState {
    show: boolean,
    type: AlertColor,
    message: string
}

const EmailForm = (props: React.ComponentPropsWithoutRef<any>) => {

    const [alert, setAlert] = React.useState<AlertState>({
        show: false,
        type: 'error',
        message: 'Si è verificato un errore durante la connessione con il server.'
    });

    return (
        <Box
            sx={{
                boxShadow: 5,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                height: {lg: '100%'},
                justifyContent: 'center',
                p: {lg: 2, md: 2, sm: 2, xs: 2},
                borderRadius: {lg: 0, md: 10, sm: 10, xs: 10}
            }}
            {...props}
        >
            <Container>
                <Formik
                    enableReinitialize
                    initialValues={{
                        username: ''
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().max(255).required('Lo username è un campo obbligatorio e non può essere vuoto')
                    })}
                    onSubmit={async (values: FormikValues, {resetForm}: FormikHelpers<any>) => {
                        const [status, response] = await AuthService.restorePassword(values.username);
                        let newAlert: AlertState = {
                            show: true,
                            type: 'error',
                            message: 'Si è verificato un\'errore durante l\'esecuzione della richiesta.'
                        };
                        if (status && status === HttpStatusCode.Ok) {
                            newAlert = {
                                show: true,
                                type: 'success',
                                message: 'Riceverai un\'email con le istruzioni da seguire per il reset della password.'
                            };
                            resetForm();
                        }

                        setAlert(newAlert);
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Typography variant="h4">Password dimenticata</Typography>
                            <Typography variant="h6">Inserisci il tuo username nel seguente campo per richiedere il
                                ripristino della password</Typography>
                            {alert.show && <Alert variant="outlined" sx={{my: 2}} severity={alert.type}>
                                {alert.message}
                            </Alert>}
                            <TextField
                                error={Boolean(touched.username && errors.username)}
                                fullWidth
                                helperText={touched.username && errors.username}
                                label="Username"
                                margin="normal"
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                disabled={isSubmitting}
                                value={values.username}
                                required
                                variant="outlined"
                            />
                            <Box sx={{py: 2, float: 'right'}}>
                                <div>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Conferma
                                        {isSubmitting && <CircularLoading/>}
                                    </Button>
                                </div>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    );
};

export default EmailForm;
