import {Button, Grid, MenuItem, TextField} from "@mui/material";
import {Formik} from "formik";
import React, {ReactNode} from "react";
import * as Yup from "yup";
import Keyword from "../../../model/database/Keyword";
import SustainableDevelopmentGoal from "../../../model/database/SustainableDevelopmentGoal";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";

interface CategoryModalProps {
  open: boolean,
  category?: SustainableDevelopmentGoal,
  handleClose: () => void,
  handleSave: (toSave: SustainableDevelopmentGoal) => Promise<boolean>
}

const CategoriesModal = ({ open, category, handleSave, handleClose }: CategoryModalProps) => {

  const formikRef = React.useRef<any>(undefined);

  const handleResetAndClose = () => {
    if (formikRef.current)
      formikRef.current.resetForm();
    handleClose();
  }

  return <Formik
    innerRef={formikRef}
    enableReinitialize
    initialValues={{
      name: category ? category.name : '',
      description: category ? category.description : ''
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Il nome è obbligatorio e non può essere vuoto.'),
      description: Yup.string().max(4096, 'Il valore massimo consentito è di 4096 caratteri'),
    })}
    onSubmit={async (values, { resetForm }) => {
      let result = await handleSave({
        id: category ? category.id : undefined,
        name: values.name,
        description: values.description,
      });

      if (result)
        resetForm();
    }}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      touched,
      submitForm,
      values
    }) => (
      <CustomDialog
        open={open ? open : false}
        handleClose={() => {
          if (!isSubmitting) {
            handleResetAndClose();
          }
        }}
        title={category ? 'Modifica categoria' : 'Aggiungi categoria'}
        content={
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <ResponsiveGridItem>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nome"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  disabled={isSubmitting}
                  value={values.name}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  multiline
                  rows={4}
                  helperText={(touched.description && errors.description) as ReactNode}
                  label="Descrizione"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  value={values.description}
                  variant="outlined"
                />
              </ResponsiveGridItem>
            </Grid>
          </form>
        }
        actions={
          <>
            <Button color="inherit" disabled={isSubmitting} onClick={handleResetAndClose}>
              Chiudi
            </Button>
            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
              Conferma
              {isSubmitting && <CircularLoading />}
            </Button>
          </>
        }
      />
    )}
  </Formik>;
}

export default CategoriesModal;
