import {colors, createTheme} from '@mui/material';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        plain: true;
    }
}

const palette = {
    background: {
        default: '#F4F6F8',
        paper: colors.common.white
    },
    primary: {
        contrastText: '#000000',
        main: '#f0cb00',
        mainHalf: '#fae25f'
    },
    secondary: {
        contrastText: colors.common.white,
        main: '#213740',
        mainHalf: 'rgba(33,55,64,0.58)'
    }
};

const theme = createTheme({
    palette,
    typography: {
        fontFamily: 'Titillium Web'
    },
    components: {
        MuiStack: {
            defaultProps: {
                flexWrap: 'wrap',
                gap: 1
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    color: palette.primary.contrastText
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-completed': {
                        background: palette.primary.contrastText,
                        borderRadius: '50%'
                    }
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: 'plain'},
                    style: {
                        color: palette.primary.contrastText,
                    },
                }
            ],
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: palette.secondary.main,
                        color: colors.common.white
                    }
                },
                containedPrimary: {
                    backgroundColor: palette.primary.main,
                    color: palette.primary.contrastText,
                },
                colorInherit: {
                    '&:hover': {
                        backgroundColor: colors.grey[500],
                        color: colors.common.white
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    marginTop: 8,
                    marginBottom: 8,
                    '&[aria-selected=true]': {
                        backgroundColor: `${palette.secondary.main} !important`,
                        color: palette.secondary.contrastText,
                    },
                    '&[aria-selected=true]:hover': {
                        backgroundColor: palette.secondary.main,
                        color: palette.secondary.contrastText,
                    },
                    '&:hover': {
                        backgroundColor: palette.secondary.mainHalf,
                        color: palette.secondary.contrastText,
                    },
                    '&.Mui-focused:hover': {
                        backgroundColor: palette.secondary.mainHalf,
                        color: palette.secondary.contrastText,
                    },
                    '&.Mui-focused:hover:hover': {
                        backgroundColor: palette.secondary.mainHalf,
                        color: palette.secondary.contrastText,
                    }
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: `${palette.secondary.main} !important`,
                        color: palette.secondary.contrastText,
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: palette.secondary.main,
                        color: palette.secondary.contrastText,
                    },
                    '&:hover': {
                        backgroundColor: palette.secondary.mainHalf,
                        color: palette.secondary.contrastText,
                    }
                },
                gutters: {
                    '&.Mui-selected': {
                        backgroundColor: palette.secondary.main,
                        color: palette.secondary.contrastText,
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: palette.secondary.main,
                        color: palette.secondary.contrastText,
                    },
                    '&:hover': {
                        backgroundColor: palette.secondary.mainHalf,
                        color: palette.secondary.contrastText,
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: palette.primary.contrastText
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: palette.primary.contrastText,
                        },
                    }
                }
            }
        }
    }
});

export default theme;

// .css-235su-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.Mui-focusVisible