import {Autocomplete, Box, Button, Chip, Grid, MenuItem, TextField, Theme, useTheme} from "@mui/material";
import {Formik} from "formik";
import React from "react";
import * as Yup from "yup";
import LoggedUser from "../../../model/auth/LoggedUser";
import Challenge from "../../../model/database/Challenge";
import Discussion from "../../../model/database/Discussion";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchProduct from "../../../model/database/ResearchProduct";
import ResearchService from "../../../model/database/ResearchService";
import DiscussionTableDTO from "../../../model/dto/DiscussionTableDTO";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import Call from "../../../model/database/Call";
import researchProducerType from "../../../constants/researchProducerType";
import researchConsumerType from "../../../constants/researchConsumerType";
import StyledRating from "../../common/StyledRating";
import productTypes from "../../../constants/productTypes";

interface DiscussionModalProps {
    open: boolean,
    discussion?: Discussion,
    loggedUser?: LoggedUser,
    handleClose: () => void,
    handleSave: (discussionToSave: DiscussionTableDTO) => Promise<boolean>,
    producers: ResearchProducer[],
    consumers: ResearchConsumer[],
    products: ResearchProduct[],
    services: ResearchService[],
    challenges: Challenge[],
    calls: Call[],
}

function getStyles(name: string, users: string[], theme: Theme) {
    return {
        fontWeight:
            users.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function getStylesRating(name: string, users: string[], theme: Theme) {
    return {
        ml: 2,
        '& .MuiRating-iconEmpty': {
            color: users.indexOf(name) === -1
                ? theme.palette.secondary.main
                : theme.palette.common.white,
        },
        '& .MuiRating-iconFilled': {
            color: users.indexOf(name) === -1
                ? theme.palette.secondary.main
                : theme.palette.common.white,
        }
    };
}

const DiscussionModal = ({
                             open,
                             discussion,
                             producers,
                             consumers,
                             products,
                             services,
                             challenges,
                             calls,
                             loggedUser,
                             handleSave,
                             handleClose
                         }: DiscussionModalProps) => {

    const formikRef = React.useRef<any>(undefined);

    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [productsAc, setProductsAc] = React.useState<any[]>([]);
    const [usersAc, setUsersAc] = React.useState<any[]>([]);
    const [title, setTitle] = React.useState<string>('Crea nuovo tavolo di discussione');

    React.useEffect(() => {
        if (editMode && discussion)
            setTitle('Modifica tavolo di discussione');
        else if (discussion)
            setTitle('Dettagli tavolo di discussione');
        else
            setTitle('Crea nuovo tavolo di discussione');
    }, [editMode, discussion]);

    React.useEffect(() => {
        let grouped = [];
        if (products) {
            grouped.push(...products.map((value => ({...value, type: productTypes[0]}))));
        }

        if (services) {
            grouped.push(...services.map((value => ({...value, type: productTypes[1]}))));
        }

        if (challenges) {
            grouped.push(...challenges.map((value => ({...value, type: productTypes[2]}))));
        }

        if (calls) {
            grouped.push(...calls.map((value => ({...value, type: productTypes[3]}))));
        }

        setProductsAc(grouped);
    }, [products, services, challenges, calls]);

    React.useEffect(() => {
        let grouped = [];
        if (producers) {
            grouped.push(...producers);
        }

        if (consumers) {
            grouped.push(...consumers);
        }

        setUsersAc(grouped);
    }, [producers, consumers]);

    const resetAndClose = () => {
        setEditMode(false);
        if (formikRef.current)
            formikRef.current.resetForm();
        handleClose();
    }

    const theme = useTheme();

    return <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
            name: discussion?.name ?? '',
            description: discussion?.description ?? '',
            link: discussion?.link ?? '',
            state: discussion?.state ?? 'ACTIVE',
            product: discussion?.product?.id ?? '',
            users: discussion?.users?.map(value => value.user?.id) ?? [],
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('L\'identificativo del tavollo è obbligatorio e non può essere vuoto.'),
            description: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri'),
            link: Yup.string().max(1024, 'Il valore massimo consentito è di 1024 caratteri')
                .required('Il link è obbligatorio e non può essere vuoto.'),
            product: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('L\'argomento è obbligatorio e non può essere vuoto.'),
            users: Yup.array().of(Yup.string()).min(2, 'Il valore minimo di partecipanti è di 2 utenti')
                .required('I partecipanti sono obbligatori e non possono essere vuoti.'),
        })}
        onSubmit={async (values) => {
            let result = await handleSave({
                name: values.name,
                description: values.description,
                link: values.link,
                state: values.state,
                users: values.users.map(value => value ? value : ''),
                product: values.product
            });
            if (result) {
                resetAndClose();
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              values,
              setFieldValue
          }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <ResponsiveGridItem md={9}>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label="Identificativo tavolo"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && discussion !== undefined
                                    }}
                                    disabled={isSubmitting || (editMode && discussion !== undefined)}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={3}>
                                <TextField
                                    error={Boolean(touched.state && errors.state)}
                                    fullWidth
                                    helperText={touched.state && errors.state}
                                    label="Stato"
                                    margin="normal"
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode && discussion !== undefined
                                    }}
                                    select
                                    disabled={isSubmitting}
                                    value={values.state}
                                    required
                                    variant="outlined"
                                >
                                    <MenuItem value="ACTIVE">Attivo</MenuItem>
                                    <MenuItem value="CLOSED">Chiuso</MenuItem>
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Autocomplete
                                    options={productsAc ?? []}
                                    fullWidth
                                    getOptionLabel={(option) => `${option.product?.name!} (${option.product?.approved ? 'Approvato' : 'Non approvato'})`}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting || (editMode && discussion !== undefined)}
                                    value={productsAc.find(value => value.product.id === values.product) ?? null}
                                    readOnly={!editMode && discussion !== undefined}
                                    groupBy={(option) => option.type.label}
                                    onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                                        setFieldValue('product', newValue.product.id);
                                        if (newValue.type.value === productTypes[3].value) {
                                            setFieldValue('users', newValue.userCalls?.filter((uc: any) => uc.state === 'INTERESTED').map(((uc: any) => uc.user.id)));
                                        }

                                        if (newValue.type.value === productTypes[2].value) {
                                            setFieldValue('users', newValue.researchProducerChallenges?.map(((uc: any) => uc.researchProducer.id)));
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        name="product"
                                        margin="normal"
                                        label="Argomento"
                                        error={Boolean(touched.product && errors.product)}
                                        helperText={(touched.product && errors.product) as React.ReactNode}
                                    />
                                    }
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.link && errors.link)}
                                    fullWidth
                                    helperText={touched.link && errors.link}
                                    label="Link"
                                    margin="normal"
                                    name="link"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && discussion !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.link}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Autocomplete
                                    options={usersAc ?? []}
                                    multiple
                                    fullWidth
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    value={usersAc.filter(value => values.users?.includes(value.user.id))}
                                    getOptionLabel={option =>
                                        `[${[...researchProducerType, ...researchConsumerType].find(value1 => value1.id === option.type)?.name}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}`
                                    }
                                    readOnly={!editMode && discussion !== undefined}
                                    groupBy={(option) => option.user.role.name}
                                    onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                                        setFieldValue('users', newValue.map((v: any) => v.user.id));
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            [{[...researchProducerType, ...researchConsumerType].find(value1 => value1.id === option.type)?.name}] {option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}
                                            <StyledRating value={option.user?.score} name="read-only" readOnly
                                                          sx={getStylesRating(option.user?.id as string, values.users as string[], theme)}/>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        name="users"
                                        margin="normal"
                                        label="Partecipanti"
                                        error={Boolean(touched.product && errors.product)}
                                        helperText={(touched.product && errors.product) as React.ReactNode}
                                    />
                                    }
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.description && errors.description)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={(touched.description && errors.description) as React.ReactNode}
                                    label="Descrizione"
                                    margin="normal"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.description}
                                    variant="outlined"
                                    inputProps={{
                                        readOnly: !editMode && discussion !== undefined
                                    }}
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            Chiudi
                        </Button>
                        {loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') && (!editMode && discussion !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                Modifica
                            </Button> :
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                Conferma
                                {isSubmitting && <CircularLoading/>}
                            </Button>)}
                    </>
                }
            />
        )}
    </Formik>
        ;
}

export default DiscussionModal;
