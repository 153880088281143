import {Box, Card, CardContent} from "@mui/material";
import {GridSelectionModel, itIT} from "@mui/x-data-grid";
import {HttpStatusCode} from "axios";
import React from "react";
import Swal, {SweetAlertOptions} from 'sweetalert2';
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Keyword from "../../../model/database/Keyword";
import SustainableDevelopmentGoal from "../../../model/database/SustainableDevelopmentGoal";
import KeywordFilters from "../../../model/filters/KeywordFilters";
import KeywordsTableColumns from "../../../model/table/column/KeywordsTableColumns";
import {KeywordRow} from "../../../model/table/row/KeywordRow";
import keywordSortFields from "../../../model/table/sort/KeywordSortFields";
import AuthContext from "../../../route/AuthContext";
import KeywordService from "../../../services/KeywordService";
import CustomSweetalert from "../../../theme/sweetalert";
import {StyledTable} from "../../common/table/StyledTable";
import KeywordModal from "./KeywordModal";
import KeywordsTableToolbar from "./KeywordsTableToolbar";
import KeywordDTO from "../../../model/dto/KeywordDTO";


const KeywordsTable = () => {

  const authContext = React.useContext(AuthContext);

  const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
  const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  });
  const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
    page: 0,
    size: 10,
    sort: keywordSortFields[0].value + ',asc'
  });
  const [filterRequest, setFilterRequest] = React.useState<KeywordFilters>({});

  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<KeywordRow[]>([]);
  const [keywords, setKeywords] = React.useState<Keyword[]>([]);
  const [sdgs, setSdgs] = React.useState<SustainableDevelopmentGoal[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [keyword, setKeyword] = React.useState<Keyword | undefined>(undefined);

  const handleEdit = async (id: string) => {
    setKeyword(keywords.filter(value => value.id === id)[0]);
    setOpenModal(true);
  }

  const handleSave = (keywordToSave: KeywordDTO) => {
    return new Promise<boolean>(async resolve => {

      let [status, response] = [undefined, undefined];
      if (!keyword) {
        [status, response] = await KeywordService.save(authContext, keywordToSave);
      } else {
        [status, response] = await KeywordService.update(authContext, keyword?.id!, keywordToSave);
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Parola chiave aggiunta correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Parola chiave aggiornata correttamente.';
            handleCloseModal();
            break;
          case 'NAME_EXISTS':
            resolve(false);
            swOptions.title = 'Attenzione';
            swOptions.text = 'Il nome inserito esiste già.';
            swOptions.icon = 'warning';
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      }
    });
  }

  const handleCloseModal = () => setOpenModal(false);

  const handleDelete = (id: string) => {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await KeywordService.deleteById(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Parola chiave eliminata correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'DELETED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile eliminare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const handleStatusKeyword = (id: string, state: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: state === 'INACTIVE' ? "Confermi di voler rimuovere approvazione della parola chiave?" : "Confermi di voler approvare la parola chiave?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: state === 'INACTIVE' ? '#d32f2f' : '#2e7d32',
      confirmButtonText: state === 'INACTIVE' ? 'Disapprova' : 'Approva',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await KeywordService.changeStateById(authContext, id, state);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: status === 'INACTIVE' ? 'Parola chiave rimossa approvazione correttamente' : 'Parola chiave approvata correttamente',
            icon: 'success'
          };

          switch (response) {
            case 'UPDATED':
              break;
            case 'PRODUCT_CONNECTED':
              swOptions.title = 'Attenzione';
              swOptions.text = 'Non è stato possibile disattivare la parola chiave selezionata poiché esistono dei prodotti collegati.';
              swOptions.icon = 'warning';
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile completare l\'operazione.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile completare l\'operazione.',
            icon: 'error'
          })
      }
    });
  };

  const mapResponseToRows = (response: any) => {
    return [...response._embedded.keywords].map(value => {
      let row: KeywordRow = {
        id: value.id,
        name: value.name,
        state: value.state,
        description: value.description.length > 100 ? value.description.substring(100) + '...' : value.description,
        sdgNames: value.sdgs?.map((v: any) => v.name)
      };
      return row;
    });
  }

  const applyFilters = (filters: KeywordFilters) => {
    setFilterRequest(filters);
  }

  const applySort = (value: string) => {
    if (pageInfo.sort !== value) {
      setPageInfo((prevState) => ({ ...prevState, sort: value }));
    }
  }

  const loadSdgs = async () => {
    const [status, response] = await KeywordService.getAllSdgs(authContext);
    setSdgs(response ? response._embedded.sdgs.map((value: any) => {
      return {
        id: value.id,
        name: value.name,
        description: value.description,
        subjects: [value.subject]
      }
    }) : []);
  }

  const reloadData = async () => {
    setLoading(true);
    let [status, response] = [undefined, undefined];
    if (Object.keys(filterRequest).length === 0)
      [status, response] = await KeywordService.getAll(authContext, pageInfo);
    else
      [status, response] = await KeywordService.getAllFiltered(authContext, pageInfo, filterRequest);

    await loadSdgs();

    if (status && response && status === HttpStatusCode.Ok) {
      setPageInfoResponse((response as any).page);
      setKeywords((response as any)._embedded.keywords);
      setRows(mapResponseToRows(response));
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    reloadData();
  }, [pageInfo, filterRequest]);

  return (
    <Card sx={{ minWidth: 275, mt: 2, mb: 2 }}>
      <CardContent>
        <KeywordsTableToolbar
          handleNew={() => {
            setKeyword(undefined);
            setOpenModal(true);
          }}
          sdgs={sdgs}
          numSelected={idSelected.length}
          applyFilters={applyFilters}
          applySort={applySort}
        />
        <Box sx={{ height: 'auto', width: '100%' }}>
          <StyledTable
            loading={loading}
            paginationMode="server"
            rows={rows}
            columns={KeywordsTableColumns({
              editCallback: handleEdit,
              approveCallback: handleStatusKeyword,
              disapproveCallback: handleStatusKeyword,
              deleteCallback: handleDelete
            })}
            autoHeight
            rowsPerPageOptions={[10, 15, 20]}
            pageSize={pageInfo.size}
            onPageSizeChange={newPageSize => setPageInfo((prevState) => ({ ...prevState, size: newPageSize }))}
            onPageChange={newPage => setPageInfo((prevState) => ({ ...prevState, page: newPage }))}
            rowCount={pageInfoResponse.totalElements}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
            // checkboxSelection
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: false }}
          />
        </Box>
      </CardContent>
      <KeywordModal
        open={openModal}
        keyword={keyword}
        sdgs={sdgs}
        handleClose={handleCloseModal}
        handleSave={handleSave}
      />
    </Card>
  )
}

export default KeywordsTable;
