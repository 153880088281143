import { Autocomplete, Chip, TextField, Theme, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import researchProducerType from "../../../constants/researchProducerType";
import ResearchProducer from "../../../model/database/ResearchProducer";
import StyledRating from "../StyledRating";

interface ProducersAutocompleteProps {
    producers: ResearchProducer[],
    fieldName: string,
    readOnly: boolean,
    label: string,
    suggestedProducers?: string[],
    selectedProducers?: string[],
    showRating?: boolean,
    showMail?: boolean,
    multiValues?: boolean
}

function getStylesRating(id: string, users: string[], suggestedUsers: string[], theme: Theme) {
    let style = {}
    if (users.indexOf(id) === -1 && suggestedUsers.indexOf(id) !== -1) {
        // se è solo suggerito ma non confermato
        style = {
            color: users.indexOf(id) === -1
                ? theme.palette.primary.main
                : theme.palette.common.white,
        }
    } else {
        style = {
            color: users.indexOf(id) === -1
                ? theme.palette.secondary.main
                : theme.palette.common.white,
        }
    }

    return {
        ml: 2,
        '& .MuiRating-iconEmpty': {
            color: style,
        },
        '& .MuiRating-iconFilled': {
            color: style,
        }
    };
}

const ProducersAutocomplete = ({
                                   producers,
                                   readOnly,
                                   fieldName,
                                   label,
                                   showRating,
                                   showMail,
                                   multiValues,
                                   selectedProducers,
                                   suggestedProducers
                               }: ProducersAutocompleteProps) => {
    const {
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        touched,
        values
    } = useFormikContext<any>();
    const theme = useTheme();

    return (
        <>
            <Autocomplete
                options={producers ? producers : []}
                fullWidth
                onBlur={handleBlur}
                disabled={isSubmitting}
                multiple={multiValues}
                value={multiValues ? (producers.filter(value => values.researchProducers?.includes(value?.user?.id)) ?? []) : (producers.find(value1 => value1.id === values[fieldName]) ?? null)}
                getOptionLabel={option =>
                    `[${[...researchProducerType].find(value1 => value1.id === option.type)?.label}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}`
                }
                readOnly={readOnly ? readOnly : false}
                onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                    setFieldValue(fieldName, multiValues ? newValue.map((v: any) => v.user.id) : newValue.user.id);
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        const lbl = `[${[...researchProducerType].find(value1 => value1.id === option.type)?.label}] ${option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}`;
                        return <Chip {...getTagProps({index})}
                                     label={lbl}
                                     variant="filled"
                                     color={suggestedProducers?.includes(option.user?.id ?? '') && !selectedProducers?.includes(option.user?.id ?? '') ? "primary" : "default"}/>
                    });
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        [{researchProducerType.find(value1 => value1.id === option.type)?.label}] {option.type === 'RESEARCHER' ? `${option.user?.name} ${option.user?.surname}` : `${option.name} (${option.user?.name} ${option.user?.surname})`}
                        {showRating && <StyledRating value={option.user?.score} name="read-only" readOnly
                                                     sx={getStylesRating(option.user?.id as string, values[fieldName] as string[], suggestedProducers ?? [], theme)}/>}
                    </li>
                )}
                renderInput={(params) => <TextField
                    {...params}
                    required
                    variant="outlined"
                    name={fieldName}
                    margin="normal"
                    label={label}
                    error={Boolean(touched[fieldName] && errors[fieldName])}
                    helperText={(touched[fieldName] && errors[fieldName]) as React.ReactNode}
                />
                }
            />
            {showMail && values[fieldName] && < Typography variant="subtitle2">Recapito email: <strong>{producers.find(value1 => value1.id === values[fieldName])?.user?.email}</strong></Typography>}
        </>
    )

};
export default ProducersAutocomplete;