import { Box, Typography, useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { itIT } from "@mui/x-data-grid";
import { HttpStatusCode } from "axios";
import * as React from 'react';
import Swal from "sweetalert2";
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import ProductsPublicTableColumns from "../../../model/table/column/ProductsPublicTableColumns";
import { ProductPublicRow } from "../../../model/table/row/ProductPublicRow";
import demandSortFields from "../../../model/table/sort/ProductSortFields";
import ProductService from "../../../services/ProductService";
import CustomSweetalert from "../../../theme/sweetalert";
import { StyledTable } from "../../common/table/StyledTable";

interface ProductListCardProps {
    searchText: string,
    keywords?: string[]
}

const ProductListCard = ({keywords, searchText}: ProductListCardProps) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = React.useState<ProductPublicRow[]>([]);
    const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
    });
    const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
        page: 0,
        size: 10,
        sort: demandSortFields[1].value + ',asc'
    });

    const theme = useTheme();

    const mapResponseToRows = (products: any) => {
        return products.map((value: any) => ({
            id: value.id,
            type: value.researchProduct === null ? 'SERVICE' : 'PRODUCT',
            data: value
        }));
    }

    const reloadData = async () => {
        setLoading(true);
        const [status, response] = await ProductService.getAllProductsPublic(pageInfo, searchText, keywords);

        if (status && response && status === HttpStatusCode.Ok) {
            setPageInfoResponse((response as any).page);
            const productsRows: ProductPublicRow[] = mapResponseToRows((response as any)._embedded ? (response as any)._embedded.products : []);
            setRows(productsRows);
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }

        setLoading(false);
    };

    React.useEffect(() => {
        reloadData();
    }, [keywords, searchText]);

    const handleRowClick = () => {
        Swal.fire({
            text: "Per poter visualizzare i dettagli del prodotto/servizio selezionato è necessario essere un utente accreditato.",
            icon: 'info',
            confirmButtonColor: theme.palette.secondary.main
        })
    }

    return (
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography variant="h6">Risultati</Typography>
                <Box sx={{height: 'auto', width: '100%', mt: 2}}>
                    <StyledTable
                        sx={{
                            '& .MuiDataGrid-cell': {
                                cursor: 'pointer'
                            },
                        }}
                        paginationMode="server"
                        loading={loading}
                        rows={rows ?? []}
                        columns={ProductsPublicTableColumns({})}
                        rowsPerPageOptions={[10, 15, 20]}
                        pageSize={pageInfo.size}
                        onPageSizeChange={newPageSize => setPageInfo((prevState) => ({
                            ...prevState,
                            size: newPageSize
                        }))}
                        onPageChange={newPage => setPageInfo((prevState) => ({...prevState, page: newPage}))}
                        rowCount={pageInfoResponse.totalElements}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        getRowHeight={() => 'auto'}
                        disableSelectionOnClick
                        onRowClick={() => handleRowClick()}
                        autoHeight
                        experimentalFeatures={{newEditingApi: false}}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default ProductListCard;
