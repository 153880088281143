import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import User from "../model/database/User";
import PaymentsFilters from "../model/filters/PaymentsFilters";
import http from '../utils/HttpCommon';
import UsersFilters from "../model/filters/UsersFilters";
import UserProducerRegistrationDTO from "../model/dto/UserProducerRegistrationDTO";
import UserConsumerRegistrationDTO from "../model/dto/UserConsumerRegistrationDTO";

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/users/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).get<any>(`/users/${id}?projection=UserWithNestedObjects`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllResearchInstitutions = async (context?: LoggedUserContext, pageInfo?: PageInfoRequest) => {
	try {
		let response;
		if(context)
			response = await http.privateClient(context).get<any>('/researchInstitutions', { params: pageInfo });
		else
			response = await http.publicClient.get<any>('/researchInstitutions', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllConsumers = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/researchConsumers/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllProducers = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/researchProducers/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFilteredByUsersFilter = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: UsersFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/users/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: PaymentsFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/users/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const save = async (context: LoggedUserContext, user: User) => {

	try {
		const response = await http.privateClient(context).post<any>('/users', { 
			...user
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}

}

const update = async (context: LoggedUserContext, user: User, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/users/${id}`, user);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
 }

 const saveResearchProducer = async (researchProducer: UserProducerRegistrationDTO) => {

	try {
		const response = await http.publicClient.post<any>('/researchProducers', { 
			...researchProducer
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}

}

const updateResearchProducer = async (context: LoggedUserContext, researchProducer: UserProducerRegistrationDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/researchProducers/${id}`, researchProducer);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
 }

 const saveResearchConsumer = async (researchConsumer: UserConsumerRegistrationDTO) => {

	try {
		const response = await http.publicClient.post<any>('/researchConsumers', { 
			...researchConsumer
		 });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}

}

const updateResearchConsumer = async (context: LoggedUserContext, researchConsumer: UserConsumerRegistrationDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/researchConsumers/${id}`, researchConsumer);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
 }

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/users/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
 }

const deleteAll = async (context: LoggedUserContext) => { }
const exportSelected = async (context: LoggedUserContext) => { }

const changeStateById = async (context: LoggedUserContext, id: string, state: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/users/${id}/state/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const inviteNewUser = async (context: LoggedUserContext, email: string) => {
	try {
		const response = await http.privateClient(context).post<any>('/users/invite', { email });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllLaboratoryByInstitution = async (id: string) => {
	try {
		const response = await http.publicClient.get<any>(`/researchProducers/search/findAllLaboratoryByInstitution/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const UserService = {
	getAll,
	getById,
	getAllConsumers,
	getAllProducers,
	getAllFiltered,
	getAllFilteredByUsersFilter,
	getAllResearchInstitutions,
	save,
	update,
	saveResearchConsumer,
	updateResearchConsumer,
	saveResearchProducer,
	updateResearchProducer,
	deleteById,
	deleteAll,
	exportSelected,
	changeStateById,
	inviteNewUser,
	getAllLaboratoryByInstitution
}

export default UserService;