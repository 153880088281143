import { Departments } from "./departments";

const unisalento: Departments = {
    parentId: '7501',
    departments: [
        "BENI CULTURALI",
        "INGEGNERIA DELL'INNOVAZIONE",
        "MATEMATICA E FISICA",
        "MEDICINA SPERIMENTALE",
        "SCIENZE DELL’ECONOMIA",
        "SCIENZE E TECNOLOGIE BIOLOGICHE ED AMBIENTALI",
        "SCIENZE GIURIDICHE",
        "SCIENZE UMANE E SOCIALI",
        "STUDI UMANISTICI"
    ]
}
export default unisalento;