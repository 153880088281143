import {styled} from '@mui/material';

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 32
});

export default MainLayoutWrapper;
