const researchProducerType = [
  {
    id: "RESEARCH_CENTER",
    name: "Responsabile Laboratorio Centro di ricerca",
    label: "Laboratorio Centro di ricerca"
  },
  {
    id: "LABORATORY",
    name: "Responsabile Laboratorio Universitario",
    label: "Laboratorio Universitario"
  },
  {
    id: "SPINOFF",
    name: "Spin-off",
    label: "Spin-off"
  },
  {
    id: "RESEARCHER",
    name: "Ricercatore/Collaboratore afferente ad un Laboratorio",
    label: "Ricercatore/Collaboratore afferente ad un Laboratorio"
  }
];


export default researchProducerType;
