import {HttpStatusCode} from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import http from '../utils/HttpCommon';

const getPendent = async (context: LoggedUserContext) => {
    try {
        const response = await http.privateClient(context).get<any>('/stats/pendentEvents');

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getDataForCharts = async (context: LoggedUserContext) => {
    try {
        const response = await http.privateClient(context).get<any>('/stats/dataForCharts');

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getTotalPublic = async () => {
    try {
        const response = await http.publicClient.get<any>('/stats/totalProducts');

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const StatisticService = {
    getPendent,
    getDataForCharts,
    getTotalPublic
}

export default StatisticService;