import * as React from 'react';
import RegistrationStepProps from "../props/RegistrationStepProps";
import RegistrationStepButtons from "../RegistrationStepButtons";
import {Formik, FormikValues} from "formik";
import {Box, Card, CardContent} from "@mui/material";
import researchProducerType from "../../../../constants/researchProducerType";
import researchConsumerType from "../../../../constants/researchConsumerType";
import RegistrationProducerSection from "../section/RegistrationProducerSection";
import RegistrationConsumerSection from "../section/RegistrationConsumerSection";
import RegistrationUserSection from "../section/RegistrationUserSection";
import RegistrationOptionalSection from "../section/RegistrationOptionalSection";

const RegistrationReviewStep = ({
                                    handleUpdateData,
                                    userData,
                                    handleBack,
                                    handleNext,
                                    activeStep,
                                    institutions
                                }: RegistrationStepProps) => {
    const isProducer = userData && researchProducerType.map(value => value.id).includes(userData.type);
    const isConsumer = userData && researchConsumerType.map(value => value.id).includes(userData.type);

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    name: userData ? userData.name : '',
                    surname: userData ? userData.surname : '',
                    username: userData ? userData.username : '',
                    email: userData ? userData.email : '',
                    type: userData ? userData.type : '',
                    password: '',
                    confirmPassword: '',
                    producerName: userData && userData.producer ? userData.producer.name : '',
                    producerVatNumber: userData && userData.producer ? userData.producer.vatNumber : '',
                    producerAddress: userData && userData.producer ? userData.producer.address : '',
                    producerCity: userData && userData.producer ? userData.producer.city : '',
                    producerProvince: userData && userData.producer ? userData.producer.province : '',
                    producerRegion: userData && userData.producer ? userData.producer.region : '',
                    producerPostalCode: userData && userData.producer ? userData.producer.postalCode : '',
                    producerInstitution: userData && userData.producer ? userData.producer.institution : '',
                    producerDepartment: userData && userData.producer ? userData.producer.department : '',
                    producerLab: userData && userData.producer ? userData.producer.parent : '',
                    consumerName: userData && userData.consumer ? userData.consumer.name : '',
                    consumerVatNumber: userData && userData.consumer ? userData.consumer.vatNumber : '',
                    consumerAddress: userData && userData.consumer ? userData.consumer.address : '',
                    consumerCity: userData && userData.consumer ? userData.consumer.city : '',
                    consumerProvince: userData && userData.consumer ? userData.consumer.province : '',
                    consumerRegion: userData && userData.consumer ? userData.consumer.region : '',
                    consumerPostalCode: userData && userData.consumer ? userData.consumer.postalCode : '',
                    website: isProducer ? userData?.producer?.website : (isConsumer ? userData?.consumer?.website : ''),
                    description: isProducer ? userData?.producer?.description : (isConsumer ? userData?.consumer?.description : ''),
                    phoneNumber: isProducer ? userData?.producer?.phoneNumber : (isConsumer ? userData?.consumer?.phoneNumber : ''),
                    logo: isProducer ? userData?.producer?.logo : (isConsumer ? userData?.consumer?.logo : ''),
                }}
                onSubmit={(values: FormikValues) => {
                    if (handleUpdateData) {
                        handleUpdateData(values);
                    }
                }}
            >
                {({
                      handleSubmit,
                      values
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Card sx={{minWidth: 275, mt: 2}}>
                            <CardContent>
                                <Box mt={2}>
                                    <RegistrationUserSection readOnly={true}/>
                                    {researchProducerType.map(value => value.id).includes(values.type) &&
                                        <RegistrationProducerSection readOnly={true} institutions={institutions}/>}
                                    {researchConsumerType.map(value => value.id).includes(values.type) &&
                                        <RegistrationConsumerSection readOnly={true}/>}
                                    <RegistrationOptionalSection readOnly={true}/>
                                </Box>
                            </CardContent>
                        </Card>
                        <RegistrationStepButtons handleNext={handleNext} handleBack={handleBack}
                                                 activeStep={activeStep}/>
                    </form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default RegistrationReviewStep;
