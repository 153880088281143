import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import RegistrationUserStep from "./step/RegistrationUserStep";
import {Theme, useMediaQuery} from "@mui/material";
import RegistrationCompletedStep from "./step/RegistrationCompletedStep";
import {UserData} from "./props/StepsData";
import {FormikValues} from "formik";
import researchProducerType from "../../../constants/researchProducerType";
import researchConsumerType from "../../../constants/researchConsumerType";
import RegistrationOptionalStep from "./step/RegistrationOptionalStep";
import RegistrationReviewStep from "./step/RegistrationReviewStep";
import RegistrationPrivacyStep from "./step/RegistrationPrivacyStep";
import UserService from '../../../services/UserService';
import {HttpStatusCode} from 'axios';
import CustomSweetalert from "../../../theme/sweetalert";
import ResearchInstitution from "../../../model/database/ResearchInstitution";

const steps = ['Registrazione utente', 'Informazioni aggiuntive', 'Riepilogo informazioni', 'Informativa privacy'];

const RegistrationStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepsData, setStepsData] = React.useState<UserData>({
    name: '',
    surname: '',
    username: '',
    email: '',
    type: '',
    password: '',
    producer: {
      name: '',
      address: '',
      city: '',
      province: '',
      department: '',
      description: '',
      logo: '',
      institution: '',
      phoneNumber: '',
      postalCode: '',
      region: '',
      vatNumber: '',
      website: '',
    },
    consumer: {
      name: '',
      address: '',
      city: '',
      province: '',
      description: '',
      logo: '',
      phoneNumber: '',
      postalCode: '',
      region: '',
      vatNumber: '',
      website: ''
    }
  });
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [institutions, setInstitutions] = React.useState<ResearchInstitution[]>([]);

  const handleUpdateData = (data: FormikValues | null) => {
    const newState = {...stepsData};

    switch (activeStep) {
      case 0:
        newState.name = data?.name ?? '';
        newState.surname = data?.surname ?? '';
        newState.username = data?.username ?? '';
        newState.email = data?.email ?? '';
        newState.password = data?.password ?? '';
        newState.type = data?.type ?? '';

        if (researchProducerType.map(value => value.id).includes(data?.type)) {
          if(newState.producer !== undefined){
            newState.producer = {
              ...newState.producer,
              name: data?.producerName ?? '',
              address: data?.producerAddress ?? '',
              city: data?.producerCity ?? '',
              province: data?.producerProvince ?? '',
              department: data?.producerDepartment ?? '',
              institution: data?.producerInstitution ?? '',
              postalCode: data?.producerPostalCode ?? '',
              region: data?.producerRegion ?? '',
              vatNumber: data?.producerVatNumber ?? '',
              parent: data?.producerLab ?? '',
            }
          }
        }

        if (researchConsumerType.map(value => value.id).includes(data?.type)) {
          if(newState.consumer !== undefined){
            newState.consumer = {
              ...newState.consumer,
              name: data?.consumerName ?? '',
              address: data?.consumerAddress ?? '',
              city: data?.consumerCity ?? '',
              province: data?.consumerProvince ?? '',
              postalCode: data?.consumerPostalCode ?? '',
              region: data?.consumerRegion ?? '',
              vatNumber: data?.consumerVatNumber ?? '',
            }
          }
        }
        break;
      case 1:
        if (researchProducerType.map(value => value.id).includes(data?.type)) {
          if (newState.producer !== undefined) {
            newState.producer.description = data?.description ?? '';
            newState.producer.phoneNumber = data?.phoneNumber ?? '';
            newState.producer.website = data?.website ?? '';
            newState.producer.logo = data?.logo ?? '';
          }
        }

        if (researchConsumerType.map(value => value.id).includes(data?.type)) {
          if (newState.consumer !== undefined) {
            newState.consumer.description = data?.description ?? '';
            newState.consumer.phoneNumber = data?.phoneNumber ?? '';
            newState.consumer.website = data?.website ?? '';
            newState.consumer.logo = data?.logo ?? '';
          }
        }
        break;
    }
    setStepsData(newState);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = async () => {
    if(activeStep !== 3)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    else {
      let [status, response] = [undefined, undefined];
        if (researchConsumerType.map(value => value.id).includes(stepsData?.type)) {
          [status, response] = await UserService.saveResearchConsumer({
            name: stepsData?.name,
            surname: stepsData?.surname,
            username: stepsData?.username,
            email: stepsData?.email,
            password: stepsData?.password,
            state: 'INACTIVE',
            type: 'RESEARCH_CONSUMER',
            researchConsumerDTO: {
              type: stepsData.type,
              ...stepsData.consumer
            }});
        } else if (researchProducerType.map(value => value.id).includes(stepsData?.type)) {
          [status, response] = await UserService.saveResearchProducer({
            name: stepsData?.name,
            surname: stepsData?.surname,
            username: stepsData?.username,
            email: stepsData?.email,
            password: stepsData?.password,
            state: 'INACTIVE',
            type: 'RESEARCH_PRODUCER',
            researchProducerDTO: {
              type: stepsData.type,
              ...stepsData.producer
            }});
        }
        
        if (status && response && status === HttpStatusCode.Ok) {
          switch (response) {
            case 'SAVED':
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              break;
            case 'USERNAME_EXISTS':
              CustomSweetalert({
                title: 'Attenzione',
                text: 'Username già esistente.',
                icon: 'error'
              })
              break;
            default:
              CustomSweetalert({
                title: 'Attenzione',
                text: 'Non è stato possibile completare la registrazione.',
                icon: 'error'
              })
              break;
          }
        } else{
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile completare la registrazione.',
            icon: 'error'
          })
        }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const loadResearchInstitution = async () => {
    const [status, response] = await UserService.getAllResearchInstitutions( undefined, {page: 0, size: -1, sort: 'id,asc'});
    setInstitutions(response ? response._embedded.researchInstitutions : []);
  }

  const loadData = async () => {
    await loadResearchInstitution();
  }

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <Box sx={{width: '100%'}}>
      <Stepper activeStep={activeStep} orientation={matches ? 'horizontal' : 'vertical'}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 &&
          <RegistrationUserStep userData={stepsData} handleUpdateData={handleUpdateData} institutions={institutions}
                                handleBack={handleBack} activeStep={activeStep}/>}
      {activeStep === 1 &&
          <RegistrationOptionalStep
              userData={stepsData}
              handleUpdateData={handleUpdateData}
              handleBack={handleBack} activeStep={activeStep}/>}
      {activeStep === 2 && <RegistrationReviewStep userData={stepsData} handleNext={handleNext} handleBack={handleBack}
                                                   activeStep={activeStep} institutions={institutions}/>}
      {activeStep === 3 && <RegistrationPrivacyStep handleNext={handleNext} handleBack={handleBack}
                                                    activeStep={activeStep} />}
      {activeStep === 4 && <RegistrationCompletedStep/>}
    </Box>
  );
}

export default RegistrationStepper;
