import React from 'react';
import {Container} from "@mui/material";
import MainLayout from "../../layouts/public/MainLayout";
import {Helmet} from "react-helmet";
import PasswordResetForm from "../../components/public/login/PasswordResetForm";

const RestorePassword = () => {
  return (
      <MainLayout showLogo>
        <Helmet>
          <title>Ripristina password | Piattaforma Innovation ReSearch</title>
        </Helmet>
        <Container sx={{mt: 4}}>
            <PasswordResetForm />
        </Container>
      </MainLayout>
  );
};

export default RestorePassword;
