import {Link as RouterLink} from 'react-router-dom';
import {AppBar, Box, Button, Toolbar, useMediaQuery, useTheme} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoInnovationResearch from "../../components/common/logo/LogoInnovationResearch";
import React from "react";

interface MainNavbarProps {
  showLogo?: boolean,
  [x:string]: any
}

const buttonStyle = {
  height: '100%', 
  borderRadius: 0, 
  boxShadow: 'none', 
  textTransform: 'capitalize'
}

function formatDateItalian(date: Date) {
  const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
  const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

  const dayName = days[date.getDay()];
  const dayNumber = date.getDate();
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${dayName} ${dayNumber} ${monthName} ${year} ore ${hours}:${minutes}`;
}

const MainNavbar = (props: MainNavbarProps) => {

  const theme = useTheme();
  const isMdOrMore = useMediaQuery(theme.breakpoints.up('md'));

  const [date, setDate] = React.useState<Date>(new Date());
  React.useEffect(() => {
    const interval = setInterval(args => setDate(new Date()), 30000);
    return () => clearInterval(interval);
  });

  return <AppBar
      color="primary"
      elevation={props.elevation ? props.elevation : 0}
      {...props}
  >
    <Toolbar sx={{height: 32, minHeight: "32px !important"}}>
      {props.showLogo && <RouterLink to="/">
        <LogoInnovationResearch height={24} width="auto"/>
      </RouterLink>}
      {!props.showLogo && <span>{formatDateItalian(date)}</span>}
      <Box sx={{flexGrow: 1}}/>
      {isMdOrMore &&
          <Button color="secondary" variant="text" href="/registration" sx={buttonStyle}>
            Registrati
          </Button>
      }
      {isMdOrMore &&
          <Button color="secondary" variant="contained" href="/login" sx={buttonStyle}>
            <PersonOutlineOutlinedIcon style={{fontSize: 'large'}} color="primary"/> Accedi
          </Button>
      }
      {!isMdOrMore &&
          <Button color="secondary" variant="contained" href="/login" sx={buttonStyle}>
            <PersonOutlineOutlinedIcon style={{fontSize: 'large'}} color="primary"/>
          </Button>
      }
    </Toolbar>
  </AppBar>
};

export default MainNavbar;
