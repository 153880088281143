import {Pie} from 'react-chartjs-2';
import ItemVIew from "../../common/Item";
import {Typography} from "@mui/material";
import {TooltipItem} from "chart.js";
import React from "react";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: false
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label(tooltipItem: TooltipItem<'pie'>): string | string[] {
                    return `  ${tooltipItem.label}: ${tooltipItem.parsed.toFixed(2)} %`;
                }
            }
        }
    },
};

interface PieChartProps {
    title: string,
    labels: string[],
    datasets: any[]
}

const PieChart = ({title, labels, datasets}: PieChartProps) => {
    const [data, setData] = React.useState({
        labels: [] as any[],
        datasets: [] as any[]
    });

    React.useEffect(() => {
        setData({
            labels: labels,
            datasets: datasets
        });
    }, [labels, datasets]);

    return <ItemVIew>
        <Typography variant="h6" gutterBottom align="left" mb={4}>{title}</Typography>
        {data.labels && data.labels.length > 0 && data.datasets && data.datasets.length > 0 &&
            <Pie options={options} redraw data={{
                labels: labels,
                datasets: datasets
            }}/>}
    </ItemVIew>
}

export default PieChart;
