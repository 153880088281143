import {LabelValue} from "../../filters/LabelValue";

const callSortFields: LabelValue[] = [
    {
        label: 'Approvato',
        value: 'product.approved'
    },
    {
        label: 'Stato',
        value: 'product.state'
    },
    {
        label: 'Nome',
        value: 'product.name'
    },
    {
        label: 'Data di scadenza',
        value: 'product.endDate'
    },
    {
        label: 'Tipologia',
        value: 'type'
    },
]

export default callSortFields;
