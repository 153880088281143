import {GridColDef} from "@mui/x-data-grid";
import {CommonColumnProps} from "../CommonColumnProps";
import {Chip, IconButton, Stack, Tooltip} from "@mui/material";
import React from "react";
import {Delete, Edit} from "@mui/icons-material";

interface PaymentsTableColumnsProps {
  editCallback: (id: string) => any,
  deleteCallback: (id: string) => any,
}

const PaymentsTableColumns = ({editCallback, deleteCallback }: PaymentsTableColumnsProps) : GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    ...CommonColumnProps
  },
  {
    field: 'user',
    headerName: 'Utente',
    ...CommonColumnProps,
    renderCell: (params) => {
      return `${params.row.user.name} (${params.row.user.user?.username})`
    }
  },
  {
    field: 'year',
    headerName: 'Anno di riferimento',
    ...CommonColumnProps
  },
  {
    field: 'paid',
    headerName: 'Pagato',
    ...CommonColumnProps,
    renderCell: (params) => {
      return <Chip color={params.row.paid === 'PAID' ? 'success' : 'error'}
      label={params.row.paid === 'PAID' ? 'Pagato' : 'Non pagato'}/> 
    }
  },
  {
    field: "action",
    headerName: "Operazioni",
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => {
      return <Stack direction="row" spacing={1}>
        <Tooltip title="Modifica">
          <IconButton color="secondary" onClick={() => editCallback(params.id as string)} >
            <Edit/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Rimuovi">
          <IconButton color="error" onClick={() => deleteCallback(params.id as string)} >
            <Delete/>
          </IconButton>
        </Tooltip>
      </Stack>;
    },
    ...CommonColumnProps
  },
];

export default PaymentsTableColumns;
