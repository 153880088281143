import {LabelValue} from "../model/filters/LabelValue";

const callTypes: LabelValue[] = [
    {
        label: 'Regionale',
        value: 'REGIONAL',
    },
    {
        label: 'Interregionale',
        value: 'INTERREGIONAL',
    },
    {
        label: 'Nazionale',
        value: 'NATIONAL',
    },
    {
        label: 'Internazionale',
        value: 'INTERNATIONAL',
    }
];

export default callTypes;