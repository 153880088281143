import {AddCircle, CheckCircle, Unpublished} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";
import React from "react";
import LoggedUser from "../../../model/auth/LoggedUser";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ServicesFilters from "../../../model/filters/ServicesFilters";
import serviceSortFields from "../../../model/table/sort/ServiceSortFields";
import TableToolbar from "../../common/table/TableToolbar";
import ServicesFiltersModal from "./ServicesFiltersModal";
import StringGeneratorUtil from "../../common/StringGeneratorUtil";
import Keyword from "../../../model/database/Keyword";

interface ServicesTableToolbarProps {
    loggedUser?: LoggedUser,
    numSelected: number,
    producers: ResearchProducer[],
    keywords: Keyword[],
    handleNew: () => void,
    applyFilters: (filters: ServicesFilters) => void
    applySort: (sort: string) => void
}

const ServicesTableToolbar = ({
                                  numSelected,
                                  loggedUser,
                                  producers,
                                  keywords,
                                  handleNew,
                                  applyFilters,
                                  applySort
                              }: ServicesTableToolbarProps) => {

    const [updated, setUpdated] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState<ServicesFilters>(
        (loggedUser?.role === 'RESEARCH_PRODUCER') ? {
            user: {
                label: 'Produttore',
                value: loggedUser?.idParent ?? loggedUser.id,
                valueDisplay: StringGeneratorUtil.generateProducerLabel(producers.find(value1 => !loggedUser.idParent ? value1.user?.id === loggedUser?.id : value1.user?.id === loggedUser.idParent)!)
            }
        } : {}
    );

    const handleConfirm = (newStateFilters: ServicesFilters) => {
        setFilters(newStateFilters);
        applyFilters(newStateFilters);
        setUpdated(true);
    }

    const handleClean = () => {
        setFilters({});
        applyFilters({});
        setUpdated(true);
    }

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    React.useEffect(() => {
        if(!updated)
            setFilters(loggedUser?.role === 'RESEARCH_PRODUCER' ? {
                user: {
                    label: 'Produttore',
                    value: loggedUser?.idParent ?? loggedUser.id,
                    valueDisplay: StringGeneratorUtil.generateProducerLabel(producers.find(value1 => !loggedUser.idParent ? value1.user?.id === loggedUser?.id : value1.user?.id === loggedUser.idParent)!)
                }
            } : {});
    }, [producers])

    return (
        <>
            <TableToolbar
                numSelected={numSelected}
                title="Servizi"
                fields={serviceSortFields}
                filters={Object.values(filters)}
                defaultField={serviceSortFields[0].value}
                defaultOrder={'asc'}
                handleSort={applySort}
                handleOpenFiltersModal={handleOpenModal}
                handleCleanFilters={handleClean}
                handleExport={() => alert('export selection')}
                handleDelete={loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'RESEARCH_PRODUCER') ? () => alert('delete selection') : undefined}
                extendedActions={
                    <>
                        {loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'RESEARCH_PRODUCER') &&
                            <Tooltip title="Crea nuovo servizio">
                                <IconButton color="secondary" onClick={handleNew}>
                                    <AddCircle/>
                                </IconButton>
                            </Tooltip>}
                    </>
                }
                extendedActionsOnSelection={
                    loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT') && <>
                        <Tooltip title="Approva">
                            <IconButton color="secondary">
                                <CheckCircle/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Disapprova">
                            <IconButton color="secondary">
                                <Unpublished/>
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <ServicesFiltersModal
                open={openModal}
                filters={filters}
                producers={producers}
                loggedUser={loggedUser}
                keywords={keywords}
                handleClose={handleCloseModal}
                handleClean={handleClean}
                handleConfirm={handleConfirm}
            />
        </>
    );
}

export default ServicesTableToolbar;
  