import {Autocomplete, Button, Grid, InputAdornment, MenuItem, TextField, Theme, useTheme} from "@mui/material";
import {Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import LoggedUser from "../../../model/auth/LoggedUser";
import Challenge from "../../../model/database/Challenge";
import researchProductTypes from "../../../constants/researchProductTypes";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import Keyword from "../../../model/database/Keyword";
import ChallengeDTO from "../../../model/dto/ChallengeDTO";
import ProducersAutocomplete from "../../common/select/ProducersAutocomplete";

interface ChallengeModalProps {
    open: boolean,
    challenge?: Challenge,
    suggestedProducers: string[],
    producers: ResearchProducer[],
    consumers: ResearchConsumer[],
    keywords: Keyword[],
    loggedUser?: LoggedUser,
    handleDownload?: (id: string) => void,
    handleClose: () => void,
    handleSave: (challengeDTO: ChallengeDTO) => Promise<boolean>,
    handleSendReminder: (id: string) => void
}

function getStyles(id: string, ids: string[], theme: Theme) {
    return {
        fontWeight:
            ids.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function getStylesRating(name: string, users: string[], theme: Theme) {
    return {
        ml: 2,
        '& .MuiRating-iconEmpty': {
            color: users.indexOf(name) === -1
                ? theme.palette.secondary.main
                : theme.palette.common.white,
        },
        '& .MuiRating-iconFilled': {
            color: users.indexOf(name) === -1
                ? theme.palette.secondary.main
                : theme.palette.common.white,
        }
    };
}

const ChallengeModal = ({
                            open,
                            challenge,
                            loggedUser,
                            suggestedProducers,
                            producers,
                            consumers,
                            keywords,
                            handleSave,
                            handleDownload,
                            handleClose,
                            handleSendReminder
                        }: ChallengeModalProps) => {

    const formikRef = React.useRef<any>(undefined);

    const {t} = useTranslation();
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>(t('challenge.title.new') as string);

    React.useEffect(() => {
        if (editMode && challenge)
            setTitle(t('challenge.title.edit') as string);
        else if (challenge)
            setTitle(t('challenge.title.details') as string);
        else
            setTitle(t('challenge.title.new') as string);
    }, [editMode, challenge]);

    const resetAndClose = () => {
        setEditMode(false);
        if (formikRef.current)
            formikRef.current.resetForm();
        handleClose();
    }

    const theme = useTheme();

    return <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
            name: challenge?.product?.name ?? '',
            budget: challenge?.budget ?? '',
            description: challenge?.product?.description ?? '',
            startDate: challenge?.product?.startDate ?? '',
            endDate: challenge?.product?.endDate ?? '',
            requestBy: challenge ? challenge?.product?.user?.id : (loggedUser?.role === 'RESEARCH_CONSUMER' ? loggedUser.id : ''),
            type: challenge?.type ?? '',
            keywords: challenge?.product?.keywords?.map(value => value.id) ?? [],
            researchProducers: challenge?.producers && challenge?.producers.length > 0 ? (challenge?.producers?.map(value => value.user?.id) ?? []) : suggestedProducers,
        }}
        validationSchema={Yup.object().shape({
            requestBy: Yup.string().required(),
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il nome è obbligatorio e non può essere vuoto.'),
            description: Yup.string().max(8192, 'Il valore massimo consentito è di 8192 caratteri')
                .required('La descrizione è obbligatorio e non può essere vuoto.'),
            budget: Yup.number().integer().min(1, 'Il valore minimo consentito è 1')
                .required('Il budget è obbligatorio e non può essere vuoto.'),
            startDate: Yup.date()
                .when('endDate', {
                    is: (endDate: any) => endDate !== undefined && !isNaN(endDate.getTime()),
                    then: Yup.date().max(Yup.ref('endDate'), 'La data di inizio deve essere minore della data di scadenza')
                        .required('La data di inizio è obbligatorio e non può essere vuoto.'),
                    otherwise: Yup.date().required('La data di inizio è obbligatorio e non può essere vuoto.')
                }),
            endDate: Yup.date()
                .min(Yup.ref('startDate'), 'La data di scadenza deve essere maggiore della data di inizio')
                .required('La data di scadenza è obbligatoria e non può essere vuota.'),
            type: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatoria e non può essere vuota.'),
            keywords: Yup.array().of(Yup.string()).min(1, 'Almeno 1 parola chiave deve essere selezionata')
                .max(5, 'Si possono selezionare al massimo 5 parole chiave')
                .required('Le parole chiave sono obbligatorie e non possono essere vuote.'),
        })}
        onSubmit={async (values, {resetForm}) => {
            let result = await handleSave(values as ChallengeDTO);
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              setFieldValue,
              values
          }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            {loggedUser && loggedUser?.role !== 'RESEARCH_CONSUMER' && <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.requestBy && errors.requestBy)}
                                    fullWidth
                                    helperText={touched.requestBy && errors.requestBy}
                                    label={t('challenge.applicant')}
                                    margin="normal"
                                    name="requestBy"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined
                                    }}
                                    select
                                    disabled={isSubmitting || (editMode && challenge !== undefined)}
                                    value={values.requestBy}
                                    required
                                    variant="outlined"
                                >
                                    <MenuItem disabled>Consumatori di ricerca</MenuItem>
                                    {consumers.map(value => <MenuItem key={"user" in value ? value.user?.id : ''}
                                                                      value={"user" in value ? value.user?.id : ''}

                                    >{value.name} ({"user" in value ? value.user?.username : ''})</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label={t('challenge.name')}
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.budget && errors.budget)}
                                    fullWidth
                                    helperText={touched.budget && errors.budget}
                                    label={t('challenge.budget')}
                                    margin="normal"
                                    name="budget"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.budget}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.type && errors.type)}
                                    fullWidth
                                    helperText={touched.type && errors.type}
                                    label={t('challenge.type')}
                                    margin="normal"
                                    name="type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.type}
                                    required
                                    variant="outlined"
                                >
                                    {researchProductTypes.map(value => <MenuItem key={value.value}
                                                                                 value={value.value}>{value.label}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.startDate && errors.startDate)}
                                    fullWidth
                                    helperText={touched.startDate && errors.startDate}
                                    label={t('challenge.startDate')}
                                    margin="normal"
                                    name="startDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined,
                                        max: values.endDate
                                    }}
                                    type="date"
                                    disabled={isSubmitting}
                                    value={values.startDate}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    fullWidth
                                    helperText={touched.endDate && errors.endDate}
                                    label={t('challenge.endDate')}
                                    margin="normal"
                                    name="endDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined,
                                        min: values.startDate
                                    }}
                                    type="date"
                                    disabled={isSubmitting}
                                    value={values.endDate}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.description && errors.description)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={(touched.description && errors.description) as React.ReactNode}
                                    label={t('challenge.description')}
                                    margin="normal"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.description}
                                    variant="outlined"
                                    required
                                    inputProps={{
                                        readOnly: !editMode && challenge !== undefined
                                    }}
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <Autocomplete
                                    options={keywords ?? []}
                                    fullWidth
                                    multiple
                                    getOptionLabel={option => option.name!}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    value={keywords.filter(value => values.keywords?.includes(value.id))}
                                    readOnly={!editMode && challenge !== undefined}
                                    onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                                        setFieldValue('keywords', newValue.map((v: any) => v.id));
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        name="keywords"
                                        margin="normal"
                                        label="Parole chiave"
                                        error={Boolean(touched.keywords && errors.keywords)}
                                        helperText={(touched.keywords && errors.keywords) as React.ReactNode}
                                    />
                                    }
                                />
                            </ResponsiveGridItem>
                            {loggedUser && (loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT') &&
                                <ResponsiveGridItem>
                                    <ProducersAutocomplete
                                        producers={producers}
                                        fieldName="researchProducers"
                                        multiValues={true}
                                        readOnly={!editMode && challenge !== undefined}
                                        label="Produttori invitati"
                                        showRating={true}
                                        suggestedProducers={suggestedProducers}
                                        selectedProducers={(challenge?.producers?.map(value => value.user?.id) as string[] ?? [])}
                                    />
                                </ResponsiveGridItem>
                            }
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            {t('modal.button.close')}
                        </Button>
                        {loggedUser && loggedUser.role === 'RESEARCH_PRODUCER' && challenge?.product?.state !== 'EXPIRED' &&
                            <Button variant="contained"
                                    onClick={() => handleDownload && handleDownload(challenge?.product?.id ?? '')}
                                    autoFocus>
                                {t('modal.button.downloadModel')}
                            </Button>
                        }
                        {open && loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') && !editMode && challenge !== undefined &&
                            <Button variant="contained" onClick={() => handleSendReminder(challenge?.product?.id ?? '')} autoFocus disabled={challenge?.product?.state === 'EXPIRED' || !challenge?.product?.approved}>
                                Invia reminder
                            </Button>}
                        {open && loggedUser && (loggedUser.role === 'RESEARCH_CONSUMER' || loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT') && (!editMode && challenge !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                {t('modal.button.edit')}
                            </Button> :
                            open && <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                {t('modal.button.confirm')}
                                {isSubmitting && <CircularLoading/>}
                            </Button>)}
                    </>
                }
            />
        )}
    </Formik>
        ;
}

export default ChallengeModal;
