import {Breadcrumbs, Typography} from "@mui/material";
import React from "react";
import {NavigateNext} from "@mui/icons-material";

interface BreadcrumbProps {
    items: string[]
}

const Breadcrumb = ({items}: BreadcrumbProps) => (
  <Breadcrumbs
    aria-label="breadcrumb"
    separator={<NavigateNext fontSize="small" />}>
    {items.map((value, index) => {
      return <Typography key={value} color="text.primary">{value}</Typography>;
    })}
  </Breadcrumbs>
);

export default Breadcrumb;
