import React from "react";

const LogoInnovationResearch = (props: React.ComponentPropsWithoutRef<any>) => (
    <img
        alt="Logo Innovation Research"
        width="100%"
        height="auto"
        src={props.src ?? "/static/logo-innovation-research-DEF-orizzontale.png" }
        {...props}
    />
);

export default LogoInnovationResearch;
