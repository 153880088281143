import { Button, Grid, TextField, Theme, useTheme } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import LoggedUser from "../../../model/auth/LoggedUser";
import Demand from "../../../model/database/Demand";
import Keyword from "../../../model/database/Keyword";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import DemandDTO from "../../../model/dto/DemandDTO";
import AuthContext from "../../../route/AuthContext";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import ConsumersAutocomplete from "../../common/select/ConsumersAutocomplete";
import KeywordsAutocompleteFreesolo from "../../common/select/KeywordsAutocompleteFreesolo";


interface DemandModalProps {
    open: boolean,
    edit: boolean,
    demand?: Demand,
    loggedUser?: LoggedUser,
    keywords: Keyword[],
    consumers: ResearchConsumer[],
    handleClose: () => void,
    handleSave: (demandToSave: DemandDTO) => Promise<boolean>
}

function getStyles(id: string, ids: string[], theme: Theme) {
    return {
        fontWeight:
            ids.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const DemandModal = ({
                          open,
                          edit,
                          loggedUser,
                          demand,
                          consumers,
                          keywords,
                          handleSave,
                          handleClose
                      }: DemandModalProps) => {

    const {t} = useTranslation();
    const authContext = React.useContext(AuthContext);
    const formikRef = React.useRef<any>(undefined);

    const [editMode, setEditMode] = React.useState<boolean>(edit);
    const [title, setTitle] = React.useState<string>(t('demand.title.new') as string);

    React.useEffect(() => {
        if (!open)
            resetAndClose();
    }, [open]);

    React.useEffect(() => {
        setEditMode(edit);
    }, [edit]);

    React.useEffect(() => {
        if (editMode && demand)
            setTitle(t('demand.title.edit') as string);
        else if (demand)
            setTitle(t('demand.title.details') as string);
        else
            setTitle(t('demand.title.new') as string);
    }, [editMode, demand]);

    const resetAndClose = () => {
        setEditMode(false);
        if (formikRef.current)
            formikRef.current.resetForm();
        handleClose();
    }

    const theme = useTheme();

    return <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
            name: demand ? demand.product?.name : '',
            description: demand ? demand.product?.description : '',
            startDate: demand ? demand.product?.startDate : '',
            endDate: demand ? demand.product?.endDate : '',
            user: demand ? demand.product?.user?.id : (loggedUser?.role === 'RESEARCH_CONSUMER' ? loggedUser.id : ''),
            keywords: demand ? demand.product?.keywords?.map(value => value.id) : [],
            suggested: []
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il titolo è obbligatorio e non può essere vuoto.'),
            description: Yup.string().max(8192, 'Il valore massimo consentito è di 8192 caratteri')
                .required('La descrizione è obbligatorio e non può essere vuoto.'),
            user: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il produttore è obbligatorio e non può essere vuoto.'),
            keywords: Yup.array().of(Yup.string()).min(1, 'Almeno 1 parola chiave deve essere selezionata')
                .max(5, 'Si possono selezionare al massimo 5 parole chiave')
                .required('Le parole chiave sono obbligatorie e non possono essere vuote.'),
            startDate: Yup.date()
                .when('endDate', {
                    is: (endDate: any) => endDate !== undefined && !isNaN(endDate.getTime()),
                    then: Yup.date().nullable().max(Yup.ref('endDate'), 'La data di inizio deve essere minore della data di fine'),
                    otherwise: Yup.date().nullable()
                })
                .nullable(),
            endDate: Yup.date()
                .nullable()
                .when('startDate', {
                    is: (startDate: any) => startDate !== undefined && !isNaN(startDate.getTime()),
                    then: Yup.date().nullable().min(Yup.ref('startDate'), 'La data di fine deve essere maggiore della data di inizio'),
                    otherwise: Yup.date().nullable()
                })
                .nullable()
        }, [['startDate', 'endDate']])}
        onSubmit={async (values, {resetForm}) => {
            let result = await handleSave({
                ...values as DemandDTO,
                suggested: values.suggested.filter((v: any) => values.keywords?.includes(v.id)).map((v: any) => ({name: v.name, state: v.state, description: '', sdgs: []}))
            });
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              values
          }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            {loggedUser && (loggedUser?.role !== 'RESEARCH_CONSUMER' || (demand && loggedUser.id !== demand?.product?.user?.id)) &&
                                <ResponsiveGridItem>
                                    <ConsumersAutocomplete consumers={consumers} fieldName="user"
                                                           readOnly={!editMode && demand !== undefined}
                                                           showMail={loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT'}
                                                           label="Ente proponente"/>
                                </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label="Titolo fabbisogno"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && demand !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <KeywordsAutocompleteFreesolo
                                    keywords={demand ? [...keywords, ...demand.product?.keywords?.filter(k => k.state === 'INACTIVE') ?? []] : keywords}
                                    fieldName="keywords"
                                    suggestedFieldName="suggested"
                                    readOnly={!editMode && demand !== undefined}
                                    label={t('product.keyword')} />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.description && errors.description)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={(touched.description && errors.description) as React.ReactNode}
                                    label={t('product.description')}
                                    margin="normal"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.description}
                                    required
                                    variant="outlined"
                                    inputProps={{
                                        readOnly: !editMode && demand !== undefined
                                    }}
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.startDate && errors.startDate)}
                                    fullWidth
                                    helperText={touched.startDate && errors.startDate}
                                    label={t('product.startDate')}
                                    margin="normal"
                                    name="startDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && demand !== undefined,
                                        max: values.endDate
                                    }}
                                    type="date"
                                    disabled={isSubmitting}
                                    value={values.startDate ?? ''}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    fullWidth
                                    helperText={touched.endDate && errors.endDate}
                                    label={t('product.endDate')}
                                    margin="normal"
                                    name="endDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && demand !== undefined,
                                        min: values.startDate
                                    }}
                                    disabled={isSubmitting}
                                    value={values.endDate}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            {t('modal.button.close')}
                        </Button>
                        {loggedUser && (
                            (loggedUser.role === 'RESEARCH_CONSUMER' && loggedUser.id === demand?.product?.user?.id) || loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT'
                        ) && (!editMode && demand !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                {t('modal.button.edit')}
                            </Button> :
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                {t('modal.button.confirm')}
                                {isSubmitting && <CircularLoading/>}
                            </Button>)}
                        {loggedUser &&
                            (loggedUser.role === 'RESEARCH_CONSUMER') && (!demand) &&
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                {t('modal.button.confirm')}
                                {isSubmitting && <CircularLoading/>}
                            </Button>}
                    </>
                }
            />
        )}
    </Formik>;
}

export default DemandModal;
