import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import italyRegions from "../../../__data__/italy/italy-region";
import {useFormikContext} from "formik";

interface RegionAutocompleteProps {
    value: string,
    fieldName: string,
    readOnly?: boolean
}

const RegionAutocomplete = ({value, readOnly, fieldName}: RegionAutocompleteProps) => {
    const {
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        touched
    } = useFormikContext<any>();

    return (
        <Autocomplete
            options={italyRegions.regions}
            fullWidth
            onBlur={handleBlur}
            disabled={isSubmitting}
            value={italyRegions.regions.find(value1 => value1.name === value) ?? null}
            getOptionLabel={option => option.name}
            readOnly={readOnly ? readOnly : false}
            onChange={(e: React.ChangeEvent<any>, newValue: any) => {
                setFieldValue(fieldName, newValue.name);
            }}
            renderInput={(params) => <TextField
                {...params}
                required
                variant="outlined"
                name={fieldName}
                margin="normal"
                label="Regione"
                error={Boolean(touched[fieldName] && errors[fieldName])}
                helperText={(touched[fieldName] && errors[fieldName]) as React.ReactNode}
            />
            }
        />
    )
}

export default RegionAutocomplete;
