const en = {
    translation: {
        'sidebar.role.admin': 'Administration',
        'sidebar.role.producer': 'Research producers',
        'sidebar.role.consumer': 'Research consumers',
        'sidebar.role.management': 'Management and guidance committee',
        'sidebar.statistics': 'Statistics',
        'sidebar.products': 'Products',
        'sidebar.services': 'Services',
        'sidebar.users': 'Users',
        'sidebar.discussionTables': 'Discussion tables',
        'sidebar.calls': 'Calls',
        'sidebar.challenges': 'Challenges',
        'sidebar.payments': 'Taxes',
        'sidebar.keywords': 'Keywords',
        'sidebar.categories': 'Categories',
        'sidebar.settings': 'Settings',
        'table.products': 'Products',
        'table.challenges': 'Challenges',
        'table.operations': 'Operations',
        'modal.button.close': 'Close',
        'modal.button.confirm': 'Confirm',
        'modal.button.edit': 'Edit',
        'modal.button.downloadModel': 'Download proposal template',
        'file.select': 'Select file',  
        'file.download': 'Download',  
        'file.empty': 'No document is available.',
        'file.available': 'Document is available.',
        'product.title.new': 'Create product',
        'product.title.edit': 'Edit product',
        'product.title.details': 'Product details',
        'product.producer': 'Producer',
        'product.name': 'Product name',
        'product.keyword': 'Keywords',
        'product.description': 'Description',
        'product.startDate': 'Start date',
        'product.duration': 'Duration (expressed in days)',
        'product.endDate': 'End date',
        'product.trl': 'TRL',
        'product.financeType': 'Financing type',
        'product.productType': 'Product type',
        'product.website': 'Website',
        'challenge.title.new': 'Create new challenge',
        'challenge.title.edit': 'Edit challenge',
        'challenge.title.details': 'Challenge details',
        'challenge.name': 'Name',
        'challenge.budget': 'Budget',
        'challenge.type': 'Type',
        'challenge.startDate': 'Start date',
        'challenge.endDate': 'End date',
        'challenge.description': 'Description',
        'challenge.keyword': 'Keyword',
        'challenge.producers': 'Research producers',
        'challenge.applicant': 'Applicant',
    }
};

export {en};