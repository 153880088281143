import * as React from 'react';
import {Grid, TextField, Typography} from "@mui/material";
import {useFormikContext} from "formik";
import ResponsiveGridItem from "../../../common/ResponsiveGridItem";
import SectionProps from "./SectionProps";
import UploadFileField from "../../../common/UploadFileField";

const RegistrationConsumerSection = ({readOnly}: SectionProps) => {

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    touched,
    values
  } = useFormikContext<any>();

  return <Grid container spacing={1}>
    <ResponsiveGridItem mt={2}>
      <Typography variant="h5">Informazioni aggiuntive</Typography>
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <TextField
        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
        fullWidth
        helperText={(touched.phoneNumber && errors.phoneNumber) as React.ReactNode}
        label="Recapito di telefono"
        margin="normal"
        name="phoneNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.phoneNumber}
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    <ResponsiveGridItem md={6}>
      <TextField
        error={Boolean(touched.website && errors.website)}
        fullWidth
        helperText={(touched.website && errors.website) as React.ReactNode}
        label="Sito web"
        margin="normal"
        name="website"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        disabled={isSubmitting}
        value={values.website}
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
    {values.type !== 'RESEARCHER' &&
        <ResponsiveGridItem>
            <UploadFileField propertyId="logo" editMode={!readOnly}/>
        </ResponsiveGridItem>}
    <ResponsiveGridItem>
      <TextField
        error={Boolean(touched.description && errors.description)}
        fullWidth
        multiline
        rows={4}
        maxRows={4}
        helperText={(touched.description && errors.description) as React.ReactNode}
        label="Descrizione"
        margin="normal"
        name="description"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isSubmitting}
        value={values.description}
        variant="outlined"
        inputProps={{
          readOnly: readOnly
        }}
      />
    </ResponsiveGridItem>
  </Grid>

};

export default RegistrationConsumerSection;
