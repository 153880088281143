import React from "react";

const LogoIStore = (props: React.ComponentPropsWithoutRef<any>) => (
    <img
        alt="Logo IStore"
        width="100%"
        height="auto"
        src={props.src ?? "/static/iStore.png" }
        {...props}
    />
);

export default LogoIStore;
